import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountSnapshot } from '@avispon/user/dist/models';

import { partialTranslate } from '@libs/common';
import {
  DictionarySelectField,
  GridLayout,
  InputMode,
  Section,
  SwitchField,
  TextInputField,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { useElementVisibility } from '@libs/permission';
import { UserDictionaryEntryNameEnum } from '@libs/user/common';
import { UserUIElementEnum } from '@libs/user/config';

function AdditionalInformationSection() {
  const [t] = useTranslation();
  const getFieldLabel = partialTranslate('user:field');
  const { formMode } = useFormV2Context();
  const { checkIsElementVisible } = useElementVisibility();

  const { viewMode } = useViewModesV2(formMode);

  const inputMode = useMemo(() => {
    if (viewMode) {
      return InputMode.VIEW;
    }

    return InputMode.FORM;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMode]);

  return (
    <Section title={t('user:section.additionalInformation')} isCollapsable>
      <GridLayout itemProps={{ xs: 12 }}>
        {viewMode && (
          <DictionarySelectField
            inputMode={InputMode.VIEW}
            name={typedNameV2<AccountSnapshot>('status')}
            label={getFieldLabel('status')}
            dictionaryName={UserDictionaryEntryNameEnum.USER_STATUS}
            stringValue
          />
        )}
        {viewMode && checkIsElementVisible(UserUIElementEnum.ACCOUNT_LOGGED_IN_VIEW) ? (
          <SwitchField
            name={typedNameV2<AccountSnapshot>('loggedIn')}
            label={getFieldLabel('loggedIn')}
            inputMode={inputMode}
          />
        ) : null}
        <TextInputField
          inputMode={inputMode}
          name={typedNameV2<AccountSnapshot>('additionalInformation')}
          label={getFieldLabel('additionalInformation')}
          lines={3}
        />
      </GridLayout>
    </Section>
  );
}

export default AdditionalInformationSection;
