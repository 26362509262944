import { useMutation, useQueryCache } from '@libs/common/v2/api';

import { API, endpointsConfig, ReportQueryKeysEnum } from '..';

const removeReport = async (reportId: string) => {
  const { data } = await API.report.deleteReport(reportId, {
    ...endpointsConfig.deleteReport
  });
  return data;
};

export default function useRemoveReportMutation({ onSuccess, onError }) {
  const queryCache = useQueryCache();
  return useMutation(removeReport, {
    onSuccess: () => {
      queryCache.invalidateQueries(ReportQueryKeysEnum.REPORTS);
      onSuccess();
    },
    onError
  });
}
