import { useMultipleSelectColumn, useTableLayoutProps } from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';

import { DomainDictionaryEntry, DomainUIElementEnum } from '../../../config';
import { useFoldersToTransferQuery } from '../../api';
import FoldersTransferSelectColumnButton from '../../components/list/folders-to-transfer/FoldersTransferSelectColumnButton';
import { useFoldersToTransferColumns } from '../columns';

function useFoldersToTransferTable() {
  const { columns, mappedFilterFields, mappedSortFields } = useFoldersToTransferColumns();
  const { checkIsElementVisible } = useElementVisibility();

  const selectRowPlugin = useMultipleSelectColumn(FoldersTransferSelectColumnButton, true);

  return useTableLayoutProps({
    tableHookQuery: useFoldersToTransferQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tableOptions: {
        initialState: {
          filters: [{ id: 'status', value: [{ value: DomainDictionaryEntry.FOLDER_STATUS.EXPIRED_TO_TRANSFER }] }]
        }
      },
      tablePlugins: [
        checkIsElementVisible(
          DomainUIElementEnum.ROAD_TRANSPORT_FOLDERS_TO_ASSIGN_HANDED_OVER_GENERATE_DOCUMENT_BUTTON
        ) && selectRowPlugin
      ]
    }
  });
}

export default useFoldersToTransferTable;
