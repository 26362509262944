import { convertDatesToRangeTime } from '@libs/common/v2/utils';

export * from './userDictionaryEntryNameEnum';
export * from './registeredFormEnums';

export const groupListMappedFilterField = {
  name: 'query',
  created: dates => convertDatesToRangeTime(dates, ['createdFrom', 'createdTo']),
  modified: dates => convertDatesToRangeTime(dates, ['modifiedFrom', 'modifiedTo']),
  permissions: {
    name: 'query'
  },
  permissionsNames: 'permissionsNames'
};
