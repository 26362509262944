import { useCallback } from 'react';

import { useDialog } from '@libs/common/v2';

import { GeoportalMapContainer } from '../components/map';

function useGeoportalMapModal() {
  const { openDialog } = useDialog();

  const showMap = useCallback(
    (city: string, street: string, propertyNumber: string, onClose?: () => void) => {
      openDialog(({ closeDialog }) => (
        <GeoportalMapContainer
          city={city}
          street={street}
          propertyNumber={propertyNumber}
          closeDialog={() => {
            closeDialog();
            onClose?.();
          }}
        />
      ));
    },
    [openDialog]
  );

  return {
    showMap
  };
}

export default useGeoportalMapModal;
