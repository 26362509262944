import { InputMode, useDialog } from '@libs/common/v2';

import {
  FinancialSecurityApplicationExchangeDialog,
  IFinancialSecurityApplicationExchangeDialogProps
} from '../../components/section/dialog/FinancialSecurityApplicationExchangeSummaryDialog';

type OpenDialogParams = Omit<IFinancialSecurityApplicationExchangeDialogProps, 'inputMode' | 'closeDialog'>;

export function useFinancialSecurityApplicationExchangeSummaryDialog() {
  const { openDialog } = useDialog();

  return {
    openFinancialSecurityApplicationExchangeSummaryDialog: (props: OpenDialogParams) =>
      openDialog(({ closeDialog }) => (
        <FinancialSecurityApplicationExchangeDialog {...props} inputMode={InputMode.FORM} closeDialog={closeDialog} />
      ))
  };
}
