import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateBulkNotificationCommand } from '@avispon/message/dist/models';

import { NUMBER_OF_AUTOCOMPLETE_RESULTS, SelectWithFetchedOptionsField, typedNameV2 } from '@libs/common/v2';

import { API } from '@libs/notification/api';

interface IProps {
  isDisabled: boolean;
  isRequired: boolean;
}

function GroupsAutocompleteField({ isDisabled, isRequired }: IProps) {
  const [t] = useTranslation();

  const getGroupsList = useCallback((text: string) => {
    return API.group.find({ size: NUMBER_OF_AUTOCOMPLETE_RESULTS, query: text });
  }, []);

  return (
    <SelectWithFetchedOptionsField
      fetchFunction={getGroupsList}
      getOptionLabel={user => user.name}
      label={t('messages:field.allInSelectedPermissionGroup')}
      name={typedNameV2<CreateBulkNotificationCommand>('recipients.accountSelector.groupIds')}
      isDisabled={isDisabled}
      isRequired={isRequired}
      isClearable={false}
      isMultiple
    />
  );
}

export default GroupsAutocompleteField;
