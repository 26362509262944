import { PermissionElementMap } from '@libs/permission';

import { PermissionEnum } from '@libs/domain/config/permission/PermissionEnum';

import AlertUIElementEnum from './AlertUIElementEnum';

const AlertPermissionElementMapper: PermissionElementMap<AlertUIElementEnum> = {
  [AlertUIElementEnum.NOTIFICATIONS_CONFIGURATION_LIST_TAB]: {
    anyOfPermission: [PermissionEnum.ALERT_VIEW]
  },
  [AlertUIElementEnum.NOTIFICATIONS_CONFIGURATION_ADD_BUTTON]: {
    allOfPermissions: [PermissionEnum.ALERT_VIEW, PermissionEnum.ALERT_ADMIN]
  },
  [AlertUIElementEnum.NOTIFICATIONS_CONFIGURATION_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.ALERT_VIEW]
  },
  [AlertUIElementEnum.NOTIFICATIONS_CONFIGURATION_EDIT_BUTTON]: {
    allOfPermissions: [PermissionEnum.ALERT_VIEW, PermissionEnum.ALERT_ADMIN]
  },
  [AlertUIElementEnum.NOTIFICATIONS_CONFIGURATION_DELETE_BUTTON]: {
    allOfPermissions: [PermissionEnum.ALERT_VIEW, PermissionEnum.ALERT_ADMIN]
  },
  [AlertUIElementEnum.NOTIFICATIONS_CONFIGURATION_EDIT_CONFIGURATION_STATUS_BUTTON]: {
    allOfPermissions: [PermissionEnum.ALERT_VIEW, PermissionEnum.ALERT_ADMIN]
  },
  [AlertUIElementEnum.NOTIFICATION_CHANEL_BELL_OPTION]: {
    allOfPermissions: [PermissionEnum.USER_VIEW, PermissionEnum.GROUP_VIEW, PermissionEnum.ORGANIZATION_STRUCTURE_VIEW]
  }
};

export default AlertPermissionElementMapper;
