import { useMetaFormContext } from '@libs/meta-form/context';

import { FieldValidationMapConfig } from '../validation/validation-registry-model';

/**
 *  Hook tworzy mapowanie fieldId na typ Fielda, aby obsłużyć generowanie walidacji dodatkowych fieldów
 *  które zostały utworzone na podstawie fieldów zdefiniowanych w meta-form, lecz przez to że są przekazane przez props
 *  additionalFields mają niestandardowy typeKey
 */
function useMapAdditionalFieldsValidationType(fieldId, config: FieldValidationMapConfig) {
  const { fieldsValidationTypeMap: customFieldsValidationTypeMap } = useMetaFormContext();
  customFieldsValidationTypeMap.current[fieldId] = config;
}
export default useMapAdditionalFieldsValidationType;
