import { ArchiveApplicationDetails } from '@ibtm/domain';

import { convertCalendarDate } from '@libs/common/v2/utils';

import { ApplicationToArchiveSnapshot } from '../model';

const parseArchiveApplication = (element: ArchiveApplicationDetails): ApplicationToArchiveSnapshot => {
  return {
    id: element.id,
    number: element.number,
    warning: element.archiveNote,
    archiveNumber: element?.archiveNumber,
    classificationKey: element.jrwaClassificationKey,
    paperNumber: element.paperNumber,
    organizationUnit: element.departmentKey,
    categoryKey: element.categoryKey,
    typeKey: element.typeKey,
    entryDate: element.created,
    author: element.author,
    folder: element.folder,
    subject: element.subject,
    statusKey: element.statusKey,
    archiveDate: convertCalendarDate(element.archiveDate),
    archived: element.archived,
    anonymized: element.anonymized,
    version: element.version,
    year: element.year
  };
};

export const parseArchiveApplicationList = (
  data: Array<ArchiveApplicationDetails>
): Array<ApplicationToArchiveSnapshot> => {
  return data.map(item => parseArchiveApplication(item));
};
