export const noteInitialFormValues = {
  content: '',
  author: '',
  created: '',
  modified: '',
  modifier: ''
};

export interface NoteSnapshot {
  author: {
    id: string;
    name: string;
  };
  created: string;
  modifier: {
    id: string;
    name: string;
  };
  modified: string;
}

export interface INoteData {
  content: string;
  isProceeding: boolean;
}
