import { useTranslation } from 'react-i18next';

import { WarningInformation } from '@libs/common/v2/components/warning';

import { useHasAnyNegativeControlResultQuery } from '@libs/domain/exploitation-base';

interface IProps {
  folderId: string;
}

function NegativeControlInformation({ folderId }: IProps) {
  const [t] = useTranslation();

  const { data: hasNegativeControlResult } = useHasAnyNegativeControlResultQuery(folderId, {
    enabled: Boolean(folderId)
  });

  return hasNegativeControlResult ? (
    <div className="mt-10">
      <WarningInformation content={t('exploitationBase:details.message.baseHasNgativeControlResult')} />
    </div>
  ) : null;
}

export default NegativeControlInformation;
