import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { getLocalStorageItem } from '@libs/common/v2/utils';

import { useAutoLogout } from '../hooks';
import { AutoLogoutStorageItemEnum } from '../models';
import { AuthService } from '../services';

const initialTimeInSeconds = 30;

function AutoLogoutWarning({ onClose }: { onClose: () => void }) {
  const classes = useStyles();
  const [t] = useTranslation();

  const endTime = useRef(
    Number(getLocalStorageItem(AutoLogoutStorageItemEnum.IDLE_START_TIME)) +
      AuthService.sessionTimeInMinutes * 60 * 1000
  );

  const [timeLeft, setTimeLeft] = useState(initialTimeInSeconds * 1000);
  const { setOnAutoLogoutReset, handleExtendSession, setCloseWarning } = useAutoLogout();

  useEffect(() => {
    setOnAutoLogoutReset(() => {
      handleExtendSession();
      onClose();
    });
    return () => {
      setOnAutoLogoutReset(() => {});
    };
  }, [setOnAutoLogoutReset, handleExtendSession, onClose]);

  useEffect(() => {
    setCloseWarning(onClose);
    return () => {
      setCloseWarning(() => {});
    };
  }, [setCloseWarning, onClose]);

  useEffect(() => {
    const countDown = () => {
      setTimeLeft(endTime.current - Date.now());
    };
    const interval = setInterval(() => countDown(), 100);
    return () => clearInterval(interval);
  }, [timeLeft]);

  const countdownStartValue = AuthService.secondsBeforeSessionEndingWarningDisplay;
  const timeLeftInSeconds = Math.max(+(timeLeft / 1000).toFixed(0), 0);

  return (
    <div>
      {/* @ts-ignore mechanizm typowania tłumaczeń aktualnie nie obsługuje rozpoznawania wersji mnogiej. */}
      {t('message.autoLogoutWarning.autoLogout', {
        count: countdownStartValue < timeLeftInSeconds ? countdownStartValue : timeLeftInSeconds
      })}
      <span className={classes.action} onClick={handleExtendSession}>
        {t('action.extendSession')}
      </span>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  action: {
    marginLeft: 4,
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}));

export default AutoLogoutWarning;
