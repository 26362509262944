import { PemheartOperation, SignFileStage } from '../models';

export const PEMHEART_MAX_TIMEOUT = 60000;
export const PEMHEART_INTERVAL_TIMEOUT = 100;
export const PEMHEART_OFFSET = 500000;
const PEMHEART_INTERFACE = '2';

export const phCreateContext = method => {
  const message = buildPhCreateContextMessage();

  return method(message);
};

const buildPhCreateContextMessage = () => {
  return {
    interface: PEMHEART_INTERFACE,
    operation: PemheartOperation.CREATE_CONTEXT,
    args: {}
  };
};

export const phDeleteContext = (method, id) => {
  const message = buildPhDeleteContextMessage(id);

  return method(message);
};

const buildPhDeleteContextMessage = (id: string) => {
  return {
    interface: PEMHEART_INTERFACE,
    operation: PemheartOperation.DELETE_CONTEXT,
    args: {
      id
    }
  };
};

export const phAddPKCS11Library = (method, id, filepath) => {
  const message = buildPhAddPKCS11LibraryMessage(id, filepath);

  return method(message);
};

const buildPhAddPKCS11LibraryMessage = (id: string, filepath: string) => {
  return {
    interface: PEMHEART_INTERFACE,
    operation: PemheartOperation.ADD_PKCS11_LIBRARY,
    args: {
      id,
      filepath
    }
  };
};

export const phGetAvailableCertificates = (method, id, filter) => {
  const message = buildPhGetAvailableCertificatesMessage(id, filter);

  return method(message);
};

const buildPhGetAvailableCertificatesMessage = (id: string, filter: string) => {
  return {
    interface: PEMHEART_INTERFACE,
    operation: PemheartOperation.GET_AVAILABLE_CERTIFICATES,
    args: {
      id,
      filter
    }
  };
};

export const phUploadRAMFile = (method, id, filepath, address, data) => {
  const message = buildPhUploadRAMFileMessage(id, filepath, address, data);

  return method(message, SignFileStage.UPLOAD);
};

const buildPhUploadRAMFileMessage = (id: string, filepath: string, address: string, data: string) => {
  return {
    interface: PEMHEART_INTERFACE,
    operation: PemheartOperation.UPLOAD_RAM_FILE,
    args: {
      id,
      filepath,
      address,
      data
    }
  };
};

export const phSignDetached = (method, id, type, filepath, fileSignature, certId, pin) => {
  const message = buildPhSignDetachedMessage(id, type, filepath, fileSignature, certId, pin);

  return method(message, SignFileStage.SIGN);
};

export const buildPhSignDetachedMessage = (
  id: string,
  type: string,
  filepath: string,
  fileSignature: string,
  certId: string,
  pin: string
) => {
  return {
    interface: PEMHEART_INTERFACE,
    operation: PemheartOperation.SIGN_DETACHED,
    args: {
      id,
      type,
      filepath,
      fileSignature,
      certSel: {
        certId
      },
      pin
    }
  };
};

export const phSign = (method, id, type, filepath, fileSignature, certId, pin) => {
  const message = buildPhSignMessage(id, type, filepath, fileSignature, certId, pin);

  return method(message, SignFileStage.SIGN);
};

const buildPhSignMessage = (
  id: string,
  type: string,
  filepath: string,
  fileSignature: string,
  certId: string,
  pin: string
) => {
  return {
    interface: PEMHEART_INTERFACE,
    operation: PemheartOperation.SIGN,
    args: {
      id,
      type,
      filepath,
      fileSignature,
      certSel: {
        certId
      },
      pin
    }
  };
};

export const phGetRAMFileSize = (method, id, filepath) => {
  const message = buildPhGetRAMFileSizeMessage(id, filepath);

  return method(message, SignFileStage.GET_SIZE);
};

const buildPhGetRAMFileSizeMessage = (id: string, filepath: string) => {
  return {
    interface: PEMHEART_INTERFACE,
    operation: PemheartOperation.GET_RAM_FILE_SIZE,
    args: {
      id,
      filepath
    }
  };
};

export const phDownloadRAMFile = (method, id, filepath, address, size) => {
  const message = buildPhDownloadRAMFileMessage(id, filepath, address, size);

  return method(message, SignFileStage.DOWNLOAD);
};

const buildPhDownloadRAMFileMessage = (id: string, filepath: string, address: string, size: string) => {
  return {
    interface: PEMHEART_INTERFACE,
    operation: PemheartOperation.DOWNLOAD_RAM_FILE,
    args: {
      id,
      filepath,
      address,
      size
    }
  };
};

export const phDeleteRAMFile = (method, id, filepath) => {
  const message = buildPhDeleteRAMFileMessage(id, filepath);

  return method(message, SignFileStage.DELETE);
};

const buildPhDeleteRAMFileMessage = (id: string, filepath: string) => {
  return {
    interface: PEMHEART_INTERFACE,
    operation: PemheartOperation.DELETE_RAM_FILE,
    args: {
      id,
      filepath
    }
  };
};
