import { useEffect, useRef } from 'react';
import PerfectScrollbar, { ScrollBarProps } from 'react-perfect-scrollbar';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Theme } from '@libs/common/v2/theme';

interface IProps extends ScrollBarProps {
  isScrollbarEnabled?: boolean;
  isDefaultStyles?: boolean;
  isMobile?: boolean;
}

function Scrollbar({
  className,
  children,
  isScrollbarEnabled = true,
  isDefaultStyles,
  isMobile,
  ...forwardProps
}: IProps) {
  const classes = useStyles({ isScrollbarEnabled });
  const scrollbarRef = useRef<any | null>(null);
  const handleWindowResize = () => {
    if (scrollbarRef?.current) {
      scrollbarRef.current.updateScroll();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  if (isMobile) {
    return <div className={classes.mobileRoot}>{children}</div>;
  }

  return isScrollbarEnabled ? (
    <PerfectScrollbar
      ref={ref => {
        scrollbarRef.current = ref;
      }}
      className={isDefaultStyles ? null : clsx(classes.root, 'flex-1', className)}
      {...forwardProps}
    >
      {children}
    </PerfectScrollbar>
  ) : (
    <div className={clsx(classes.root, className)}>{children}</div>
  );
}

const useStyles = makeStyles<Theme, Pick<IProps, 'isScrollbarEnabled'>>(() => ({
  root: ({ isScrollbarEnabled }) => ({
    position: 'relative',
    height: '100%',
    width: '100%',
    zIndex: isScrollbarEnabled ? 0 : 1
  }),
  mobileRoot: {
    height: '100%',
    width: '100%',
    overflowY: 'auto'
  }
}));

export default Scrollbar;
