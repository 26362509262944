import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { PluginHook, Row, SortingRule } from 'react-table';
import { useSnackbar } from '@enigma/fe-ui';

import { TableLayout } from '@libs/common/v2';
import { getCalendarDate } from '@libs/common/v2/utils';

import { API } from '@libs/domain/api';

import { useUpdateDriverCertificateMutation } from '../api';
import { useDriverCertificatesExtendedTable, useDriverCertificatesExtendedValidation } from '../hooks';
import { DriverCertificatesColumnsEnum, DriverCertificateSnapshotClient } from '../model';
import { parseDriverCertificatesList } from '../parsers';

interface IProps {
  applicationId?: string;
  folderId?: string;
  editableColumns?: DriverCertificatesColumnsEnum[];
  defaultFilters?: {
    id: DriverCertificatesColumnsEnum;
    value: any;
  }[];
  headerActions?: JSX.Element;
  rowActions?: (row: Row<DriverCertificateSnapshotClient>, isTableMutable?: boolean) => ReactNode;
  tablePlugins?: Array<PluginHook<DriverCertificateSnapshotClient>>;
  isTableMutable?: boolean;
  actionsColumnWidth?: number;
  defaultSort?: SortingRule<DriverCertificateSnapshotClient>[];
}

function DriverCertificatesExtendedTable({
  applicationId,
  folderId,
  editableColumns,
  defaultFilters,
  rowActions,
  headerActions,
  isTableMutable,
  tablePlugins = [],
  actionsColumnWidth,
  defaultSort
}: IProps) {
  const [t] = useTranslation();
  const { mutate: updateDriversCertificate } = useUpdateDriverCertificateMutation();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbar();

  const tableProps = useDriverCertificatesExtendedTable(
    applicationId,
    folderId,
    defaultFilters,
    tablePlugins,
    editableColumns,
    defaultSort
  );
  const { validationScheme } = useDriverCertificatesExtendedValidation(editableColumns);

  const onRowEdit = (data: DriverCertificateSnapshotClient, { onSuccess, onError }) => {
    const { validTo, version, validFrom, id, driverCertificateNumber } = data;
    return updateDriversCertificate(
      {
        id,
        number: driverCertificateNumber,
        validFrom: getCalendarDate(validFrom),
        validTo: getCalendarDate(validTo),
        version
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('success.save'));
          onSuccess();
        },
        onError: () => {
          showErrorSnackbar(t('global:error.dataSaveFailure'));
          onError();
        }
      }
    );
  };

  return (
    <TableLayout<DriverCertificateSnapshotClient, DriverCertificateSnapshotClient>
      {...tableProps}
      pageTitle={t('driverCertificate:title')}
      xlsxDownload={{
        fileName: t('driverCertificate:title'),
        pathToXLSXTranslation: 'driverCertificate:fields',
        apiRequest: params =>
          API.driverCertificate
            .getDriverCertificatesDetailsExtendedPage(params)
            .then(res => ({ ...res, data: { ...res.data, content: parseDriverCertificatesList(res.data.content) } }))
      }}
      headerActions={headerActions}
      rowActions={row => rowActions(row, isTableMutable)}
      isTableMutable
      isActionColumnEnabled={Boolean(rowActions)}
      actionsColumnWidth={actionsColumnWidth}
      isSection
      onRowEditCreateConfirm={onRowEdit}
      editFormYupResolverSchema={validationScheme}
      isCollapsable
      isRefreshEnabled
    />
  );
}

export default DriverCertificatesExtendedTable;
