import { useMemo } from 'react';
import { FolderToTransferSearchFilter } from '@ibtm/domain';

import { SelectOption, TableFilter, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO, getCalendarDate } from '@libs/common/v2/utils';

import { useDictionaryEntryValues } from '@libs/dictionary';
import { UserFilterV2 } from '@libs/user';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '../../../config';
import { IFoldersToTransferClient } from '../../models';

const useFoldersToTransferColumns = () => {
  const { createColumns } = useCreateColumns<IFoldersToTransferClient>({
    pathToTranslate: 'roadTransport:fields'
  });

  const folderStatusDictionaryEntries = useDictionaryEntryValues(DomainDictionaryEnum.FOLDER_STATUS);

  const filteredFolderStatusDictionaryEntries = useMemo(
    () =>
      folderStatusDictionaryEntries.filter(item =>
        [
          DomainDictionaryEntry.FOLDER_STATUS.EXPIRED_ASSIGNED_TO_TRANSFER,
          DomainDictionaryEntry.FOLDER_STATUS.EXPIRED_TRANSFERRED,
          DomainDictionaryEntry.FOLDER_STATUS.EXPIRED_TO_TRANSFER
        ].includes(item.value)
      ),
    [folderStatusDictionaryEntries]
  );

  const columns = createColumns([
    {
      accessor: 'folderNumber',
      type: 'TEXT'
    },
    {
      accessor: 'subjectName',
      type: 'TEXT'
    },
    {
      accessor: 'subjectNip',
      type: 'TEXT'
    },
    {
      accessor: 'status',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: 'FOLDER_STATUS',
      filter: props => (
        <TableFilter.DictionaryMultiSelect
          {...props}
          dictionary={DomainDictionaryEnum.FOLDER_STATUS}
          options={filteredFolderStatusDictionaryEntries}
        />
      )
    },
    {
      header: 'transferredToAuthority',
      accessor: 'transferredToSta',
      type: 'TEXT'
    },
    {
      header: 'transferFolderDate',
      accessor: 'transferDate',
      type: 'DATE'
    },
    {
      accessor: 'referentName',
      type: 'TEXT',
      filter: UserFilterV2
    }
  ]);

  const mappedFilterFields: TableFilterMapper<IFoldersToTransferClient, FolderToTransferSearchFilter> = {
    folderNumber: (folderNumber: string) => ({ numberContains: folderNumber }),
    subjectName: (subjectName: string) => ({ subjectNameContains: subjectName }),
    subjectNip: (subjectNip: string) => ({ subjectNipContains: subjectNip }),
    transferredToSta: (transferredToSta: string) => ({ transferredToStaContains: transferredToSta }),
    transferDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      transferDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      transferDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    referentName: (referentNames: SelectOption<string>[]) => ({
      assignedToIdIn: referentNames.map(referentName => referentName.value)
    }),
    status: (statuses: SelectOption<string>[] | SelectOption<string>) => ({
      statusKeyIn: Array.isArray(statuses) ? statuses.map(status => status.value) : [statuses.value]
    })
  };

  const mappedSortFields: TableSortMapper<IFoldersToTransferClient> = {
    subjectName: 'subject.name',
    subjectNip: 'subject.nip',
    status: 'statusKey',
    referentName: 'referent.name'
  };

  return { columns, mappedFilterFields, mappedSortFields };
};

export default useFoldersToTransferColumns;
