import { useCallback } from 'react';

import { useDialog } from '@libs/common/v2';

import ApplicationWarningDialog from '../../components/common/ApplicationWarningDialog';

function useUpdateApplicationWarningDialog() {
  const { openDialog } = useDialog();

  const showDialog = useCallback(
    (id: string, refetch: () => void, version?: number, note?: string) => {
      openDialog(({ closeDialog }) => (
        <ApplicationWarningDialog id={id} closeDialog={closeDialog} refetch={refetch} version={version} note={note} />
      ));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog]
  );

  const openUpdateApplicationWarningDialog = (id: string, refetch: () => void, version?: number, note?: string) => {
    showDialog(id, refetch, version, note);
  };

  return {
    openUpdateApplicationWarningDialog
  };
}

export default useUpdateApplicationWarningDialog;
