import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PaginatedQueryResult } from 'react-query';
import { useSnackbar } from '@enigma/fe-ui';
import { InvoiceSnapshot } from '@ibtm/domain';

import { IPaginatedModel, TableLayout, useConfirmDialog, useRouter, useTableRowActions } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { UIElementNameEnum } from '@libs/permission';

import { API } from '@libs/domain/api';
import { DomainDictionaryEntry } from '@libs/domain/config';
import { DomainUIElementEnum } from '@libs/domain/config/permission';

import { ReleaseDocumentsQueryKeysEnum, useChangeReleaseDocumentStatusMutation } from '../api';
import { useReleaseDocumentsTable } from '../hooks';
import useDownloadReleaseDocumentsDialog from '../hooks/useDownloadReleaseDocumentsDialog';
import { parseReleaseDocumentsSuspensionPayload } from '../utils/api-request.utils';

function ReleaseDocumentsTablePage({
  isSection = false,
  tableHookQuery,
  suspensionId,
  folderId,
  tableActionKeys
}: {
  isSection?: boolean;
  tableHookQuery?: (params, options, queryKey) => PaginatedQueryResult<IPaginatedModel<InvoiceSnapshot>>;
  suspensionId?: string;
  folderId?: string;
  tableActionKeys?: UIElementNameEnum[];
}) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const tableProps = useReleaseDocumentsTable(tableHookQuery, suspensionId);
  const { mutateAsync: changeStatus } = useChangeReleaseDocumentStatusMutation();
  const { showSuccessSnackbar } = useSnackbar();
  const [confirm] = useConfirmDialog();
  const { openDownloadDialog } = useDownloadReleaseDocumentsDialog();
  const { routes } = useRouter();

  const { renderTableActions } = useTableRowActions<InvoiceSnapshot>([
    {
      id: DomainUIElementEnum.SUSPENSIONS_RELEASE_DOCUMENTS_DETAILS_BUTTON,
      label: 'action.openDetails',
      icon: 'ArrowIcon',
      link: ({ original }) => routes.releaseDocumentOnFolderSuspensionView(folderId, suspensionId, original.id)
    },
    {
      id: DomainUIElementEnum.SUSPENSIONS_RELEASE_DOCUMENTS_DOWNLOAD_BUTTON,
      label: 'action.download',
      tooltip: 'action.download',
      icon: 'DownloadIcon',
      onClick: ({
        row: {
          original: { id, fileId }
        }
      }) => openDownloadDialog({ invoiceId: id, fileId })
    },
    {
      id: DomainUIElementEnum.SUSPENSIONS_CORRECTION_CREATE,
      label: 'releaseDocuments:actions.addCorrection',
      icon: 'AddIcon',
      isDisabled: ({
        row: {
          original: { hasCorrectionInvoice, statusKey, isCorrection }
        }
      }) => (hasCorrectionInvoice && isCorrection) || statusKey === DomainDictionaryEntry.INVOICE_STATUS.CANCELED,
      tooltip: ({ original }) => {
        if (original?.hasCorrectionInvoice && original?.isCorrection)
          return 'releaseDocuments:tooltip.addDisabledIfDocumentHasCorrection';
        if (original?.statusKey === DomainDictionaryEntry.INVOICE_STATUS.CANCELED)
          return 'releaseDocuments:tooltip.addDisabledIfDocumentIsCanceled';
        return null;
      },
      link: ({ original }) => routes.releaseDocumentOnFolderSuspensionCreate(folderId, suspensionId, original.id)
    },

    {
      id: DomainUIElementEnum.SUSPENSIONS_CORRECTION_UPDATE,
      label: 'action.edit',
      icon: 'EditIcon',
      isHidden: ({
        row: {
          original: { isCorrection }
        }
      }) => !isCorrection,
      link: ({ original }) => routes.releaseDocumentOnFolderSuspensionView(folderId, suspensionId, original.id)
    },
    {
      id: DomainUIElementEnum.SUSPENSIONS_CORRECTION_CANCEL,
      label: 'releaseDocuments:actions.cancelCorrection',
      icon: 'RejectIcon',
      isHidden: ({
        row: {
          original: { isCorrection, statusKey, hasCorrectionInvoice }
        }
      }) =>
        !isCorrection ||
        (isCorrection && statusKey === DomainDictionaryEntry.INVOICE_STATUS.CANCELED) ||
        !!hasCorrectionInvoice,
      onClick: ({
        row: {
          original: { id, version }
        }
      }) => {
        confirm({
          title: t('releaseDocuments:cancel.title'),
          message: t('releaseDocuments:messages.cancelDocumentConfirmation'),
          onConfirm: (setLoadingState, close) => {
            setLoadingState(true);
            changeStatus(
              { statusKey: DomainDictionaryEntry.INVOICE_STATUS.CANCELED, version, id },
              {
                onSuccess: () => {
                  queryCache.invalidateQueries([ReleaseDocumentsQueryKeysEnum.RELEASE_DOCUMENTS_LIST]);
                  showSuccessSnackbar(t('releaseDocuments:messages.cancelSuccess'));
                },
                onSettled: () => {
                  close();
                  setLoadingState(false);
                }
              }
            );
          },
          confirmType: 'danger'
        });
      }
    },

    /// ///////////////// dla glownego modulu
    {
      id: DomainUIElementEnum.RELEASE_DOCUMENT_DETAILS_BUTTON,
      label: 'action.openDetails',
      icon: 'ArrowIcon',
      link: ({ original }) => routes.releaseDocumentView(original.id)
    },
    {
      id: DomainUIElementEnum.RELEASE_DOCUMENT_DOWNLOAD_DOCUMENT_BUTTON,
      label: 'action.download',
      tooltip: 'action.download',
      icon: 'DownloadIcon',
      onClick: ({
        row: {
          original: { id, fileId }
        }
      }) => openDownloadDialog({ invoiceId: id, fileId })
    },
    {
      id: DomainUIElementEnum.RELEASE_DOCUMENT_ADD_CORRECTION_BUTTON,
      label: 'releaseDocuments:actions.addCorrection',
      icon: 'AddIcon',
      isDisabled: ({
        row: {
          original: { hasCorrectionInvoice, statusKey }
        }
      }) => hasCorrectionInvoice || statusKey === DomainDictionaryEntry.INVOICE_STATUS.CANCELED,
      tooltip: ({ original }) => {
        if (original?.hasCorrectionInvoice) return 'releaseDocuments:tooltip.addDisabledIfDocumentHasCorrection';
        if (original?.statusKey === DomainDictionaryEntry.INVOICE_STATUS.CANCELED)
          return 'releaseDocuments:tooltip.addDisabledIfDocumentIsCanceled';
        return null;
      },
      link: ({ original }) => routes.releaseDocumentCreate(original.id)
    },
    {
      id: DomainUIElementEnum.RELEASE_DOCUMENT_EDIT_BUTTON,
      label: 'action.edit',
      icon: 'EditIcon',
      isHidden: ({
        row: {
          original: { isCorrection }
        }
      }) => !isCorrection,
      link: ({ original }) => routes.releaseDocumentEdit(original.id)
    },
    {
      id: DomainUIElementEnum.RELEASE_DOCUMENT_CANCEL_BUTTON,
      label: 'releaseDocuments:actions.cancelCorrection',
      icon: 'RejectIcon',
      isHidden: ({
        row: {
          original: { isCorrection, statusKey, hasCorrectionInvoice }
        }
      }) =>
        !isCorrection ||
        (isCorrection && statusKey === DomainDictionaryEntry.INVOICE_STATUS.CANCELED) ||
        !!hasCorrectionInvoice,
      onClick: ({
        row: {
          original: { id, version }
        }
      }) => {
        confirm({
          title: t('releaseDocuments:cancel.title'),
          message: t('releaseDocuments:messages.cancelDocumentConfirmation'),
          onConfirm: (setLoadingState, close) => {
            setLoadingState(true);
            changeStatus(
              { statusKey: DomainDictionaryEntry.INVOICE_STATUS.CANCELED, version, id },
              {
                onSuccess: () => {
                  queryCache.invalidateQueries(ReleaseDocumentsQueryKeysEnum.RELEASE_DOCUMENTS_LIST);
                  showSuccessSnackbar(t('releaseDocuments:messages.cancelSuccess'));
                },
                onSettled: () => {
                  close();
                  setLoadingState(false);
                }
              }
            );
          },
          confirmType: 'danger'
        });
      }
    }
  ]);

  const apiRequest = useCallback(
    params => {
      if (tableHookQuery) {
        const { suspensionId: id, queryParams } = parseReleaseDocumentsSuspensionPayload(params);
        return API.suspensionReleaseDocuments.getInvoiceSnapshotPage(id, queryParams);
      }

      return API.releaseDocuments.getInvoicesSnapshotPage(params);
    },
    [tableHookQuery]
  );

  return (
    <TableLayout
      {...tableProps}
      isSection={isSection}
      pageTitle={t('releaseDocuments:titles.list')}
      xlsxDownload={{
        fileName: t('releaseDocuments:titles.list'),
        pathToXLSXTranslation: 'releaseDocuments:fields',
        apiRequest
      }}
      rowActions={renderTableActions(
        suspensionId
          ? tableActionKeys
          : [
              DomainUIElementEnum.RELEASE_DOCUMENT_DETAILS_BUTTON,
              DomainUIElementEnum.RELEASE_DOCUMENT_ADD_CORRECTION_BUTTON,
              DomainUIElementEnum.RELEASE_DOCUMENT_EDIT_BUTTON,
              DomainUIElementEnum.RELEASE_DOCUMENT_CANCEL_BUTTON,
              DomainUIElementEnum.RELEASE_DOCUMENT_DOWNLOAD_DOCUMENT_BUTTON
            ]
      )}
    />
  );
}

export default ReleaseDocumentsTablePage;
