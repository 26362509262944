import { QueryConfig, useQuery } from 'react-query';
import { TransitFrequenciesList } from '@ibtm/domain/dist/models';

import { API } from '@libs/domain/api';

import { ApplicationQueryKeysEnum } from '../../../application';
import { endpointsConfig } from '../endpoints-config';

const getTransitFrequencies = async (_, transitScheduleId: string) => {
  const { data } = await API.transitSchedule.getTransitFrequencies(transitScheduleId, {
    ...endpointsConfig.getTransitDates
  });
  return data;
};

const useTransitFrequenciesQuery = (
  transitScheduleId: string,
  queryConfig?: QueryConfig<TransitFrequenciesList, unknown>
) => {
  return useQuery(
    [ApplicationQueryKeysEnum.TRANSIT_FREQUENCIES, transitScheduleId],
    getTransitFrequencies,
    queryConfig
  );
};
export default useTransitFrequenciesQuery;
