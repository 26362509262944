import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GridLayout,
  LoaderCircular,
  typedNameV2,
  Typography,
  useFormV2Context,
  WarningInformation,
  WarningThemeType
} from '@libs/common/v2';

import { useFoldersQuery } from '@libs/domain/folder';

import { VehicleFormData } from '../../../model';

interface IProps {
  existingVehicleMessage: string;
  isExistingVehicle: boolean;
  isActiveInFolder: boolean;
}

function ExistingVehicleDialogContent({ existingVehicleMessage, isExistingVehicle, isActiveInFolder }: IProps) {
  const [t] = useTranslation();
  const { watch } = useFormV2Context();
  const [vehicleActiveInFolderMessage, setVehicleActiveInFolderMessage] = useState(null);

  const currentVin = watch(typedNameV2<VehicleFormData>('vin'));
  const currentPlateNumber = watch(typedNameV2<VehicleFormData>('plateNumber'));

  const { data: vehicleActiveFolders, isLoading: isExistingVehicleFoldersLoading } = useFoldersQuery(
    { activeVehiclePlateOrVinNumberEquals: currentVin || currentPlateNumber },
    { enabled: isExistingVehicle }
  );

  useEffect(() => {
    if (vehicleActiveFolders?.content?.length > 0) {
      const folders = vehicleActiveFolders?.content?.map(item => item.number).join(', ');
      setVehicleActiveInFolderMessage(
        t(
          vehicleActiveFolders?.content?.length === 1
            ? 'vehicle:dialog.existingVehicle.activeInOtherFolder'
            : 'vehicle:dialog.existingVehicle.activeInOtherFolders',
          {
            name: folders
          }
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleActiveFolders]);

  return (
    <GridLayout itemProps={{ xs: 12 }}>
      {isExistingVehicleFoldersLoading ? (
        <LoaderCircular isLoading isAbsolute={false} className="flex justify-center p-10" />
      ) : (
        <GridLayout itemProps={{ xs: 12 }}>
          <Typography>{t('vehicle:dialog.existingVehicle.message')}</Typography>
          {isActiveInFolder && !vehicleActiveInFolderMessage && (
            <WarningInformation
              icon="WarningIcon"
              content={existingVehicleMessage}
              colorVersion={WarningThemeType.WARNING}
            />
          )}
          {vehicleActiveInFolderMessage && (
            <WarningInformation
              icon="WarningIcon"
              content={vehicleActiveInFolderMessage}
              colorVersion={WarningThemeType.WARNING}
            />
          )}
        </GridLayout>
      )}
    </GridLayout>
  );
}

export default ExistingVehicleDialogContent;
