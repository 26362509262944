import { useTranslation } from 'react-i18next';
import { FolderDetails } from '@ibtm/domain';

import { GridLayout, Section, Value } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { BorrowFolderArchiveStatusEnum, BorrowFolderSnapshot } from '../../../model';

export function FolderSection({ row }: { row: FolderDetails | BorrowFolderSnapshot }) {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  return (
    <Section title={t('archive:borrowFolder.folder')} isModalSection>
      <GridLayout itemProps={{ xs: 12, sm: 6 }}>
        <Value label={t('archive:borrowFolder.field.typeKey')}>
          {translate(DomainDictionaryEnum.FOLDER_TYPE, row?.typeKey, t('emptyMark'))}
        </Value>
        <Value label={t('archive:borrowFolder.field.subjectNip')}>{row?.subject.nip}</Value>
        <Value label={t('archive:borrowFolder.field.subjectName')}>{row?.subject.name}</Value>
        <Value label={t('archive:borrowFolder.field.archiveStatus')}>
          {(row as BorrowFolderSnapshot)?.returnDate
            ? t(BorrowFolderArchiveStatusEnum.ARCHIVED)
            : t(BorrowFolderArchiveStatusEnum.BORROWED)}
        </Value>
      </GridLayout>
    </Section>
  );
}
