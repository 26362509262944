import { useTranslation } from 'react-i18next';
import { useQueryCache } from 'react-query';
import { useSnackbar } from '@enigma/fe-ui';

import { TableIconButton } from '@libs/common/v2';

import { useAddMoneyTransfersToApplication } from '../api';
import { PricingQueryKeysEnum } from '../api/query/PricingQueryKeysEnum';

interface IProps {
  onSuccess?: () => void;
  applicationId: string;
  moneyTransferId: string;
  linkedMoneyTransfers: string[];
}

function MoneyTransferAddButton({ onSuccess, applicationId, moneyTransferId, linkedMoneyTransfers }: IProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: addMoneyTransferToApplication, isLoading } = useAddMoneyTransfersToApplication();
  const queryCache = useQueryCache();

  const isDisabled = linkedMoneyTransfers?.includes(moneyTransferId);

  const handleAddSubmit = (id: string) => {
    addMoneyTransferToApplication(
      {
        applicationId,
        formData: {
          moneyTransfersIds: [id]
        }
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('pricing:moneyTransfers.action.search.successMessage'));
          queryCache.invalidateQueries(PricingQueryKeysEnum.MONEY_TRANSFERS);
          onSuccess?.();
        }
      }
    );
  };

  return (
    <TableIconButton
      icon="AddIcon"
      isButtonLoading={isLoading}
      isDisabled={isDisabled}
      onClick={() => {
        handleAddSubmit(moneyTransferId);
      }}
      tooltipTitle={!isDisabled && t('pricing:moneyTransfers.action.search.submitTitle')}
    />
  );
}

export default MoneyTransferAddButton;
