import { InvoiceItemDetails } from '@ibtm/domain';
import { isNil, round } from 'lodash';

import { ICorrectionItems } from '../model';
import { OrganizationFragment } from '../model/OrganizationFromDocumentNumberFragment';

// mock dla starych dokumentów wydania (przed poprawka na BE)
const mockMonths = 60;

export const getRoundedValue = (value: number) => round(value, 2);

export const getNumberOrZero = value => {
  if (isNil(value)) {
    return 0;
  }

  return getRoundedValue(parseFloat(value));
};

export const getAllNumbersOrZero = (values: ICorrectionItems): ICorrectionItems => {
  const { newSum, sumDiffrence, id, name, correctedQuantity, correctedUnitCost, quantity, unitCost } = values;

  return {
    correctedUnitCost: getNumberOrZero(correctedUnitCost),
    correctedQuantity: getNumberOrZero(correctedQuantity),
    quantity: getNumberOrZero(quantity),
    unitCost: getNumberOrZero(unitCost),
    newSum: getNumberOrZero(newSum),
    sumDiffrence: getNumberOrZero(sumDiffrence),
    id,
    name
  };
};

export const getAllValidNumbersItem = (values: InvoiceItemDetails) => {
  return {
    quantity: getNumberOrZero(values?.quantity),
    unitCost: getNumberOrZero(values?.unitCost),
    validityMonths: isNil(values?.validityMonths) ? mockMonths : values.validityMonths
  };
};
export const isWZZ = (value = '') => {
  if (OrganizationFragment.WZZ.some(item => value.includes(item))) {
    return true;
  }
  return false;
};

export const getDocumentTemplateName = (
  templateNames: Record<string, string>,
  isCorrection?: boolean,
  isWZZ?: boolean,
  isWWU?: boolean
) => {
  if (isCorrection) {
    if (isWZZ) {
      return templateNames.WZZCorrection;
    }
    if (isWWU) {
      return templateNames.WWUCorrection;
    }
    return templateNames.defaultCorrection;
  }
  return templateNames.releaseDocumentsConfirm;
};
