import { FilesListClientItem } from '../models/file.model';

export const filesListParser = (files: FilesListClientItem[]): FilesListClientItem[] =>
  files.map(file => ({
    id: file.id,
    fileId: file.fileId,
    fileName: file.fileName,
    created: file?.created,
    version: file?.version,
    fileExtension: file?.fileExtension,
    author: {
      id: file?.author?.id,
      name: file?.author?.name
    }
  }));
