import { useEffect } from 'react';
import { useWindowSize } from 'react-use';
import { Close } from '@mui/icons-material';
import { IconButton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

interface IAddress {
  city: string;
  street: string;
  number: string;
}

interface Marker {
  title?: string;
  content?: string;
  // pokazanie się automatycznie dymka, po utworzeniu markera
  show?: boolean;
  // Czas po którym zostanie usunięty marker z mapy; brak oznacza brak automatycznego usunięcia markera
  deleteTime: number;
  // Identyfikator markera. Służy on do usunięcia konkretnego markera z mapy. Jednak obecna wersja pozwala ustawienie jednego markera na mapie, dlatego ten parametr może na razie być pomijany.
  id?: string;
}

interface Selection {
  // true - włącza tryb wyświetlania mapy z selekcja bez możliwości jej zmiany poprzez klikanie na mapie
  onlyPreview?: boolean;
  // to tablica zmienna typu string, a dokĹadnie identyfikatorów; jest wartość pola typu oidField, np. OBJECTID na warstwie; jedna zmienna, może być grupą wartości wymienionych po przecinku np.; "ids":["1,2,3,4,5,6,7,8,9,10"]
  ids?: Array<string>;
  // identyfikator warstwy pod którą w aplikacji zostanie zaĹadowana warstwa z selekcjami
  id?: string;
  // nazwa pola będącego identyfikatorem obiektu na warstwie
  oidField?: string;
  // określa typ pola będącego identyfikatorem na warstwie
  oidFieldType?: 'string' | 'number';
  // url do serwisu z warstwa do selekcji(z niej sa pobierane obiekty podane w ids)
  url?: string;
  // point/poligon/polyline; typ geometrii warstwy
  geometryType?: string;
  // true - oznacza że jeżeli zaznaczymy obszar to mapa się do niego przybliży
  zoomToSelect?: boolean;
}

interface Extent {
  xmin: number;
  ymin: number;
  xmax: number;
  ymax: number;
}

interface InitCfg {
  // identyfikator(atrybut id) elementu na stronie html, na której odbywa się osadzania imapLite.
  divId: string;
  width?: number | string;
  height?: number | string;
  // lista dostępnych kompozycji mapowych - tablica identyfikatorów kompozycji odnoszących się do kompozycji zdefiniowanych w elemencie gpmaps w pliku konfiguracyjnym aplikacji (cfg.json)
  activeGpMaps?: Array<string>;
  // identyfikator kompozycji która ładuje się jako pierwsza(startowa kompozycja) - odnosi się do wybranej kompozycji zdefiniowanej w elemencie gpmaps w pliku konfiguracyjnym aplikacji (cfg.json)
  activeGpMapId?: string;
  // lista dostępnych akcji - tablica identyfikatorów akcji odnoszących się do akcji zdefiniowanych w elemencie gpactions w pliku konfiguracyjnym aplikacji (cfg.json)
  activeGpActions?: Array<string>;
  // startowy poziom skalowy mapy
  scale?: number;
  // startowy punkt do którego centruje się mapa. Przykład poniżej. Zamiast pojedynczego markera można podać tablicę takich obiektów. Wtedy na mapie pojawi się więcej niż jeden marker(jeżeli tablica będzie zawierała więcej niż jeden obiekt). Obiekt zawiera opcje dymku.
  marker?: Marker | Array<Marker>;
  // Extent startowy mapy
  initialExtent?: Extent;
  // false = wyłączy pokazywanie menu na mapce; domyśnie true;
  useMenu?: boolean;
  // Obiekt do ustawiania selekcji po inicjalizacji mapy.
  selection?: Array<Selection>;
  // Jeśli chcemy dołączyć jakiś plugin, a nie mamy go dołączonego w konfiguracji iMapLite, to możemy go dodać podczas inicjonowania mapy, właśnie poprzez podanei tablicy pluginów.
  gpplugins?: Array<string>;
}

interface GeoportalClient {
  /**
   * Metoda która tworzy widok aplikacji z mapy w elemencie div strony html, o podanym identyfikatorze.
   * @param {Object} initCfg - Obiekt json z opcjami startowymi aplikacji
   * @param {callback} [callback] - Jest to funkcja javascript, która będzie wykonana po załadowaniu API oraz wyświetleniu mapy.
   * @example <caption>Przykładowy definicji markera</caption>
   * "center" : {
   *       "x" : 8437470,
   *       "y" : 5881868,
   *       "scale" : 10000,
   *       "title" : "Tytuł dymka",
   *       "content" : "Opis dymka",
   *       "show" : true
   *      }
   * @example <caption>Przykładowy definicji startowego extentu mapy</caption>
   * "initialExtent" : {
   *       "xmin" : 8437470,
   *       "ymin" : 5881868,
   *       "xmax" : 8449579,
   *       "ymax" : 5895526
   *      }
   */
  init(iCfg: InitCfg, callback: () => void): void;
  /**
   * Wyszukuje adres lub listę adresów spełniających zadane parametry
   * wyszukiwania. W przypadku istnienia jednego wyniku następuje wstawienia
   * markera/pinezki w wyszukanym punkcie. W przypadku istnienia wielu
   * wyników, konieczny jest wybór rezultatu z listy. W wybranej lokalizacji
   * następuje wstawienie markera/pinezki.
   * @param {String|Object} address - Parametr podawany w postaci pełnego tekstu lub w postaci obiektu. Np: Wrocław Legnicka 22A lub {"city" : "Wrocław", "street" : "Legnicka", "number" : "22A"}
   * @param {Object} [options] - Ustawienia dymka
   *
   */
  searchAddress(address: string | IAddress, options?: Marker): void;
}

declare const ILITEAPI: GeoportalClient;

interface Props {
  city: string;
  street: string;
  propertyNumber: string;
  closeDialog: () => void;
}

function GeoportalMapContainer({ city, street, propertyNumber, closeDialog }: Props) {
  const classes = useStyles();

  const { width } = useWindowSize();

  const onMapLoaded = () => {
    ILITEAPI.searchAddress({
      city,
      street,
      number: propertyNumber
    });
  };

  useEffect(() => {
    ILITEAPI.init(
      {
        divId: 'iapi',
        width: width < 864 ? width - 64 : 800,
        height: 600,
        activeGpMapId: 'gp0',
        activeGpMaps: ['gp0', 'gp1'],
        gpplugins: ['clusterLayer'],
        activeGpActions: [],
        scale: 2000
      },
      onMapLoaded
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="iapi" className={classes.mapContainer}>
      <div className={classes.closeButtonContainer}>
        <IconButton aria-label="close" onClick={closeDialog} className={classes.button}>
          <Close className={clsx(classes.icon)} />
        </IconButton>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  mapContainer: {
    position: 'relative',
    overflow: 'hidden'
  },
  closeButtonContainer: {
    position: 'absolute',
    right: 10,
    top: 10,
    backgroundColor: theme.palette.common?.white
  },
  button: {
    padding: 6
  },
  icon: {
    fontSize: '1.2rem'
  },
  content: {
    padding: '1.5rem 2.4rem',
    overflow: 'auto'
  }
}));

export default GeoportalMapContainer;
