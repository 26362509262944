import { VersionedRequest } from '@ibtm/domain/dist/models';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export interface IActivateDriverRequestData {
  driverId: string;
  versionedRequest: VersionedRequest;
}

function activate(requestData: IActivateDriverRequestData) {
  return API.drivers.activateDriver(requestData.driverId, requestData.versionedRequest, {
    ...endpointsConfig.activateDriverCertificate
  });
}

function useActivateMutation() {
  return useMutation(activate);
}

export default useActivateMutation;
