import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SubjectSnapshotExtended } from '@ibtm/domain';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import _ from 'lodash';

import { Chip, Icon, IconButton, LoaderCircular, Typography, useRouter, useValueStyles } from '@libs/common/v2';
import { convertCalendarDate, getValue } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEntry, DomainDictionaryEnum, useDomainConfigContext } from '@libs/domain/config';
import { FolderDetailsClient } from '@libs/domain/folder';

import FolderSliderSection from '../FolderSliderSection';

interface Props {
  folderDetails: FolderDetailsClient;
  isLoading?: boolean;
  isGotoFolderHidden?: boolean;
  isCopyNIPActionVisible?: boolean;
  isCopyREGONActionVisible?: boolean;
  isFolderRedirectActionVisible?: boolean;
}

function SubjectBlock({
  folderDetails,
  isLoading,
  isGotoFolderHidden,
  isCopyNIPActionVisible,
  isCopyREGONActionVisible,
  isFolderRedirectActionVisible
}: Props) {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();
  const globalClasses = useValueStyles({});
  const classes = useStyles();
  const { routes } = useRouter();
  const { isOperatorPortal } = useDomainConfigContext();

  const name = useMemo(() => {
    if (!folderDetails?.subject?.legalFormKey) {
      return t('emptyMark');
    }
    if (
      folderDetails?.subject?.legalFormKey === DomainDictionaryEntry.LEGAL_NATURE_KEY.NATURAL_PERSON &&
      !folderDetails?.subject?.name
    ) {
      return `${folderDetails?.subject?.givenName ?? ''} ${folderDetails?.subject?.surname || ''}`;
    }
    return folderDetails?.subject.name;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderDetails]);

  const nip = useMemo(() => {
    if (folderDetails?.subject) {
      return folderDetails?.subject.nip;
    }
    return t('emptyMark');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderDetails]);

  const isFolderTransferred = useMemo(
    () => DomainDictionaryEntry.FOLDER_STATUS.EXPIRED_TRANSFERRED === folderDetails?.statusKey,
    [folderDetails?.statusKey]
  );

  const isLegalFormKeyCivilPartnership = useMemo(
    () => DomainDictionaryEntry.LEGAL_NATURE_KEY.CIVIL_PARTNERSHIP === folderDetails?.subject?.legalFormKey,
    [folderDetails?.subject?.legalFormKey]
  );

  const getTooltip = (key: string): string => t<any>(`folder:sidebar.tooltip.${key}`);

  const isInPartnership = useMemo(() => {
    return !_.isEmpty(folderDetails?.subject?.partnerships);
  }, [folderDetails?.subject?.partnerships]);

  return (
    <FolderSliderSection title={t('folder:tab.subject')}>
      <LoaderCircular isLoading={isLoading} size={25}>
        <div className="flex justify-between items-center">
          {/* Nazwa */}
          <div className={clsx(classes.row, 'flex items-center')}>
            <Typography
              component="div"
              themeVariant="textSm.medium"
              className={clsx(globalClasses.labelWrapper, 'pr-5 mb-0')}
            >
              {t('folder:sidebar.field.subjectName')}
            </Typography>
            <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
              {name}
            </Typography>
          </div>
          <div>
            {!isGotoFolderHidden && folderDetails?.id && isFolderRedirectActionVisible && (
              <IconButton
                width={18}
                height={18}
                icon="FolderIcon"
                tooltipTitle={t('folder:sidebar.tooltip.goToFolder')}
                link={routes.folderDetails(folderDetails.id)}
              />
            )}
          </div>
        </div>
        {/* Forma Prawna */}
        <div className="flex flex-col">
          <div className={clsx(classes.row, 'flex items-center')}>
            <Typography
              component="div"
              themeVariant="textSm.medium"
              className={clsx(globalClasses.labelWrapper, 'pr-5 mb-0')}
            >
              {t('folder:sidebar.field.subjectType')}
            </Typography>
            <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
              {getValue(translate(DomainDictionaryEnum.LEGAL_NATURE, folderDetails?.subject?.legalFormKey))}
            </Typography>
          </div>
          <div className={clsx(classes.row, 'flex items-center')}>
            <Typography
              component="div"
              themeVariant="textSm.medium"
              className={clsx(globalClasses.labelWrapper, 'pr-5 mb-0')}
            >
              {t('folder:sidebar.field.folderStatus')}
            </Typography>
            {/* statusu podmiotu */}
            {folderDetails?.statusKey ? (
              <Chip title={getValue(translate(DomainDictionaryEnum.FOLDER_STATUS, folderDetails?.statusKey))} />
            ) : (
              <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
                {t('emptyMark')}
              </Typography>
            )}
          </div>
          <div className={clsx(classes.row, 'flex items-center')}>
            <Typography
              component="div"
              themeVariant="textSm.medium"
              className={clsx(globalClasses.labelWrapper, 'pr-5 mb-0')}
            >
              {t('folder:sidebar.field.localization')}
            </Typography>
            <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
              {getValue(translate(DomainDictionaryEnum.FOLDER_LOCALIZATION, folderDetails?.folderLocalizationKey))}
            </Typography>
          </div>
          {isFolderTransferred && (
            <>
              <div className={clsx(classes.row, 'flex items-center')}>
                <Typography
                  component="div"
                  themeVariant="textSm.medium"
                  className={clsx(globalClasses.labelWrapper, 'pr-5 mb-0')}
                >
                  {t('folder:field.transferDate')}
                </Typography>
                {/* Data przekazania teczki */}
                <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
                  {getValue(convertCalendarDate(folderDetails?.transferDate))}
                </Typography>
              </div>
              <div className={clsx(classes.row, 'flex items-center')}>
                <Typography
                  component="div"
                  themeVariant="textSm.medium"
                  className={clsx(globalClasses.labelWrapper, 'pr-5 mb-0')}
                >
                  {t('folder:field.transferAuthority')}
                </Typography>
                {/* Organ przekazania teczki */}
                <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
                  {getValue(folderDetails?.transferredToSta)}
                </Typography>
              </div>
            </>
          )}
        </div>
        {/* NIP i REGON */}
        <div className="flex flex-col">
          <div className={clsx(classes.row, 'flex items-center')}>
            <Typography
              component="div"
              themeVariant="textSm.medium"
              className={clsx(globalClasses.labelWrapper, 'pr-5 mb-0')}
            >
              {t('folder:field.subjectNip')}
            </Typography>
            <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
              {nip}
            </Typography>
            {!!folderDetails?.subject && isCopyNIPActionVisible && (
              <IconButton
                icon="CopyIcon"
                className={clsx('ml-5', classes.iconButton)}
                tooltipTitle={t('folder:sidebar.tooltip.copyNip')}
                onClick={() => copy(nip)}
              />
            )}
          </div>
          <div className={clsx(classes.row, 'flex items-center')}>
            <Typography
              component="div"
              themeVariant="textSm.medium"
              className={clsx(globalClasses.labelWrapper, 'pr-5 mb-0')}
            >
              {t('folder:details.field.regon').toUpperCase()}
            </Typography>
            <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
              {getValue(folderDetails?.subject?.regon?.toString())}
            </Typography>
            {!!folderDetails?.subject && isCopyREGONActionVisible && (
              <IconButton
                icon="CopyIcon"
                className={clsx('ml-5', classes.iconButton)}
                tooltipTitle={t('folder:sidebar.tooltip.copyRegon')}
                onClick={() => copy(folderDetails?.subject.regon.toString())}
              />
            )}
          </div>
        </div>
        {/* Stowarzyszenie */}
        {folderDetails?.association && (
          <div className={clsx(classes.row, 'flex items-center')}>
            <Typography
              component="div"
              themeVariant="textSm.medium"
              className={clsx(globalClasses.labelWrapper, 'pr-5 mb-0')}
            >
              {getTooltip('association')}
            </Typography>
            <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
              {folderDetails?.association?.folderNumber}
            </Typography>
          </div>
        )}

        {/* Wspólnik spółki */}
        {isInPartnership && (
          <div className={clsx(classes.row, 'flex items-center')}>
            <Typography
              component="div"
              themeVariant="textSm.medium"
              className={clsx(globalClasses.labelWrapper, 'pr-5 mb-0')}
            >
              {getTooltip('inPartnership')}
            </Typography>
            <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
              {isOperatorPortal
                ? folderDetails.subject.partnerships.map((partnership, index, partnerships) => {
                    const folderNumber = (partnership as SubjectSnapshotExtended)?.folder?.number;
                    const folderId = (partnership as SubjectSnapshotExtended)?.folder?.id;
                    const isNotLastElement = index < partnerships.length - 1;
                    return (
                      <>
                        <Tooltip title={getTooltip('goToPartnershipDetails')}>
                          <Link to={routes.folderDetails(folderId)}>{folderNumber}</Link>
                        </Tooltip>
                        {isNotLastElement && ', '}
                      </>
                    );
                  })
                : folderDetails.subject.partnerships
                    .map(partnership => (partnership as SubjectSnapshotExtended)?.folder?.number)
                    .filter(Boolean)
                    .join(', ')}
            </Typography>
          </div>
        )}
        {/* Liczba wspólników */}
        {isLegalFormKeyCivilPartnership && (
          <div className={clsx(classes.row, 'flex items-center')}>
            <Icon
              className="pr-4 pl-4"
              iconWrapper="ml-4 flex py-5 justify-center items-center"
              icon="PeoplesIcon"
              height={18}
              width={18}
              tooltipTitle={getTooltip('partnershipCount')}
            >
              <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
                {folderDetails?.partnershipSubjectsNumber?.toString()}
              </Typography>
            </Icon>
          </div>
        )}
      </LoaderCircular>
    </FolderSliderSection>
  );
}

const useStyles = makeStyles(() => ({
  row: {
    minHeight: 48
  },
  iconButton: {
    height: 20,
    width: 20
  }
}));

export default SubjectBlock;
