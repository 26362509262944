import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AccountsApiRecoverPasswordRequest } from '@avispon/user';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import {
  Animate,
  Button,
  FormMode,
  FormV2Context,
  GridLayout,
  TextInputField,
  Theme,
  typedNameV2,
  Typography,
  useQueryParams,
  useRouter
} from '@libs/common/v2';

import { useRecoverPasswordMutation } from '@libs/user/api';
import { useAnonymousSetPasswordValidation } from '@libs/user/hooks';

export interface AnonymousSetPasswordPageValues {
  password: string;
  repeatPassword: string;
}

const initialValues: AnonymousSetPasswordPageValues = {
  password: '',
  repeatPassword: ''
};

type QueryParams = {
  code: string;
  token: string;
};

const logoImageUrl = `${process.env.PUBLIC_URL}/assets/images/logos/logo.png`;

function AnonymousSetPasswordPage() {
  const [t] = useTranslation();

  const classes = useStyles();

  const { anonymousSetPasswordFormValidation } = useAnonymousSetPasswordValidation();
  const { goToPage, routes } = useRouter();
  const { code, token } = useQueryParams<QueryParams>();
  const { mutate: recoverPassword, isLoading: isRecoverPasswordLoading } = useRecoverPasswordMutation();
  const { showSuccessSnackbar } = useSnackbar();

  const onSubmit = (values: Partial<AnonymousSetPasswordPageValues>) => {
    const request: AccountsApiRecoverPasswordRequest = {
      command: {
        code,
        token,
        password1: values.password,
        password2: values.repeatPassword
      }
    };

    recoverPassword(request, {
      onSuccess: () => {
        goToPage(routes.login());
        showSuccessSnackbar(t('user:anonymousUser.message.passwordSet'));
      }
    });
  };

  const form = useForm<Partial<AnonymousSetPasswordPageValues>>({
    defaultValues: initialValues,
    resolver: yupResolver(anonymousSetPasswordFormValidation),
    mode: 'onBlur',
    criteriaMode: 'all'
  });

  const formDataProvider = useMemo(() => {
    return { ...form, formMode: FormMode.CREATE };
  }, [form]);

  return (
    <div className={classes.root}>
      <Animate animation="transition.expandIn">
        <Card className={classes.card}>
          <div className="p-5">
            <CardContent className={classes.cardContent}>
              <img className={classes.logo} src={logoImageUrl} alt={t('global:mainLogo')} />
              <Typography themeVariant="displaySm.semibold" customStyles={{ margin: '12px 0px 32px 0px' }}>
                {t('user:anonymousUser.title')}
              </Typography>
              <FormV2Context.Provider value={formDataProvider}>
                <GridLayout itemProps={{ xs: 12 }}>
                  <TextInputField
                    name={typedNameV2<AnonymousSetPasswordPageValues>('password')}
                    label={t('user:field.password')}
                    inputProps={{ type: 'password' }}
                    isRequired
                  />
                  <TextInputField
                    name={typedNameV2<AnonymousSetPasswordPageValues>('repeatPassword')}
                    label={t('user:field.repeatPassword')}
                    inputProps={{ type: 'password' }}
                    isRequired
                  />
                  <Button
                    isLoading={isRecoverPasswordLoading}
                    onClick={form.handleSubmit(onSubmit)}
                    label={t('action.confirm')}
                    className={clsx(classes.sendButton)}
                    isNoMargin
                    fullWidth
                    isPrimary
                  />
                </GridLayout>
              </FormV2Context.Provider>
            </CardContent>
          </div>
        </Card>
      </Animate>
    </div>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    color: theme.palette.primary?.contrastText,
    '& button': {
      display: 'block'
    },
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  card: {
    backgroundColor: 'white',
    width: 400
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  logo: {
    height: 130,
    width: 130
  },
  sendButton: {
    width: '100%'
  }
}));

export default AnonymousSetPasswordPage;
