import { DefinitionsApiFactory, MediatorDataSourcesApiFactory } from '@stack/report';
import axios from 'axios';

interface ReportCoreApi {
  mediatorDataSources: ReturnType<typeof MediatorDataSourcesApiFactory>;
  definitions: ReturnType<typeof DefinitionsApiFactory>;
}

const reportCoreApiFactoryFallback: any = () => {
  throw new Error('Report api has not been set');
};

const API: ReportCoreApi = {
  mediatorDataSources: reportCoreApiFactoryFallback,
  definitions: reportCoreApiFactoryFallback
};

export function setReportCoreApi(API_PORTAL_URL: string) {
  API.mediatorDataSources = MediatorDataSourcesApiFactory({ isJsonMime: () => true }, API_PORTAL_URL, axios as never);
  API.definitions = DefinitionsApiFactory({ isJsonMime: () => true }, API_PORTAL_URL, axios as never);
}

export { API };
