import { ElementType, ReactNode } from 'react';
import { ListItem as MuiListItem, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Icon, IconType } from '@libs/common/v2';
import { PaletteOption, PaletteOptions, Theme } from '@libs/common/v2/theme';

interface IProps {
  badgeClassName?: string;
  component?: ElementType;
  children?: ReactNode;
  badge?: { bgColor?: string; color?: string; title?: ReactNode };
  padding?: string | number;
  onClick?: () => void;
  label?: string;
  className?: string;
  icon?: IconType;
  iconHeight?: number;
  iconWidth?: number;
  iconClassName?: string;
  iconColor?: keyof PaletteOptions;
  iconColorWeight?: keyof PaletteOption | number;
  iconWrapperClassName?: string;
  url?: string;
  textClassName?: string;
  isButton?: true;
  isExact?: boolean;
  isGutterEnabled?: boolean;
}

function ListItem({
  badgeClassName,
  component,
  padding,
  onClick,
  label,
  url,
  className,
  badge,
  children,
  icon,
  iconHeight = 24,
  iconWidth = 24,
  iconClassName,
  iconColor,
  iconColorWeight,
  iconWrapperClassName,
  textClassName,
  isButton,
  isExact,
  isGutterEnabled = false
}: IProps) {
  const classes = useStyles({ padding });

  return (
    <MuiListItem
      button={isButton}
      component={component}
      to={url}
      className={clsx(classes.root, 'list-item-wrapper', className)}
      onClick={onClick}
      exact={isExact}
      disableGutters={!isGutterEnabled}
    >
      {icon && (
        <div className={clsx('list-item-icon-wrapper', iconWrapperClassName)}>
          <Icon
            icon={icon}
            height={iconHeight}
            width={iconWidth}
            className={iconClassName}
            color={iconColor}
            colorWeight={iconColorWeight}
          />
        </div>
      )}
      {label && (
        <ListItemText
          className={clsx('list-item-text whitespace-pre-wrap', classes.text, textClassName)}
          primary={label}
          classes={{ primary: 'list-item-text-primary' }}
        />
      )}
      {badge && <div className={clsx(classes.bagdeRoot, 'list-item-badge', badgeClassName)}>{badge.title}</div>}
      {children}
    </MuiListItem>
  );
}

const useStyles = makeStyles<Theme, IProps>(theme => ({
  bagdeRoot: {
    padding: '0 7px',
    ...theme.typography.textXs.semibold,
    height: 20,
    minWidth: 20,
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: ({ badge }) => badge?.bgColor || theme.palette.grey[50],
    color: ({ badge }) => badge?.color || theme.palette.grey[700]
  },
  root: {
    padding: ({ padding }) => padding
  },
  text: {
    paddingLeft: 12
  }
}));

export default ListItem;
