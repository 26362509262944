import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SuspensionSnapshot } from '@ibtm/domain';

import { TableLayout, useRouter, useTableRowActions } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';
import { DomainDictionaryEntry, DomainUIElementEnum } from '@libs/domain/config';
import { SuspensionQueryKeysEnum, useSuspensionActions, useSuspensionsTable } from '@libs/domain/suspensions';

function SuspensionsTablePage() {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { routes } = useRouter();
  const [data, setData] = useState<SuspensionSnapshot>();
  const tableProps = useSuspensionsTable([
    'number',
    'caseNumber',
    'created',
    'subjectNip',
    'subjectName',
    'authorName',
    'modified',
    'dateOfIssue',
    'dateFrom',
    'dateTo',
    'whole',
    'statusKey'
  ]);

  const { startCloseProcess, startDeletingProcess } = useSuspensionActions(
    data?.id,
    () => queryCache.invalidateQueries(SuspensionQueryKeysEnum.SUSPENSION_LIST),
    data?.version
  );

  const { renderTableActions } = useTableRowActions<SuspensionSnapshot>([
    {
      id: 'SUSPENSIONS_VIEW_BUTTON',
      label: 'suspensions:details.action.preview',
      link: ({ original }) => ({
        pathname: routes.suspensionDetails(original?.folder?.id, original?.id),
        state: { from: routes.suspensionsList() }
      }),
      icon: 'ArrowIcon'
    },
    {
      id: 'SUSPENSIONS_EDIT_BUTTON',
      label: 'action.edit',
      link: ({ original }) => ({
        pathname: routes.suspensionEdit(original?.folder?.id, original?.id),
        state: { from: routes.suspensionsList() }
      }),
      icon: 'EditIcon'
    },
    {
      id: 'SUSPENSIONS_CLOSE_BUTTON',
      label: 'suspensions:details.action.close',
      onClick: ({ row: { original } }) => {
        setData(original);
        startCloseProcess(original.id, original.version);
      },
      isHidden: ({ row: { original } }) => original?.statusKey !== DomainDictionaryEntry.SUSPENSION_STATUS.CONSIDERED,
      icon: 'CrossIcon'
    },
    {
      id: 'SUSPENSIONS_DELETE_BUTTON',
      label: 'action.delete',
      onClick: ({ row: { original } }) => {
        setData(original);
        startDeletingProcess(original.id);
      },
      icon: 'TrashIcon'
    }
  ]);

  return (
    <TableLayout
      {...tableProps}
      xlsxDownload={{
        fileName: t('suspensions:list.title'),
        pathToXLSXTranslation: 'suspensions:table',
        apiRequest: API.suspensions.getSuspensionsPage
      }}
      pageTitle={t('suspensions:list.title')}
      rowActions={renderTableActions([
        DomainUIElementEnum.SUSPENSIONS_VIEW_BUTTON,
        DomainUIElementEnum.SUSPENSIONS_EDIT_BUTTON,
        DomainUIElementEnum.SUSPENSIONS_CLOSE_BUTTON,
        DomainUIElementEnum.SUSPENSIONS_DELETE_BUTTON
      ])}
    />
  );
}

export default SuspensionsTablePage;
