import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { Dialog, FormMode, FormV2Context, GridLayout, InputMode, Section, useValidationBuilder } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { PermissionTableEnum } from '@libs/domain/application';

import usePermissionDialogConfiguration from '../hooks/dialog/usePermissionDialogConfiguration';
import usePermissionFields from '../hooks/usePermissionFields';
import { PermissionFields } from '../model';

interface IProps {
  applicationId: string;
  onCloseDialog: () => void;
  onSuccess: () => void;
  permissionType: PermissionTableEnum;
  permissionId: string;
  formMode: FormMode;
}

function PermissionDialog({ applicationId, onCloseDialog, onSuccess, permissionType, permissionId, formMode }: IProps) {
  const [t] = useTranslation();
  const { validationBuilderFunctions, validationScheme } = useValidationBuilder();
  const formValues = useForm({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationScheme)
  });

  const values = useMemo(
    () => ({
      ...formValues,
      formMode,
      isSubmitting: formValues.formState.isSubmitting,
      validationBuilderFunctions
    }),
    [formMode, formValues, validationBuilderFunctions]
  );

  const { fields, isLoading, submit, isConfirmDisabled } = usePermissionDialogConfiguration({
    applicationId,
    permissionType,
    onCloseDialog,
    onSuccess,
    formMode,
    formValues: values
  });

  const { reset, handleSubmit, watch, setValue } = formValues;

  const { renderField, isDataLoading } = usePermissionFields({ reset, permissionId, watch, setValue });
  const submitHandler = (values: PermissionFields) => submit(values, permissionId);
  const { createMode, editMode, viewMode } = useViewModesV2(formMode);

  const getTitle = () => {
    switch (true) {
      case createMode:
        return t('permission:action.create.dialogTitle');
      case editMode:
        return t('permission:action.edit.dialogTitle');
      default:
        return t('permission:action.details.dialogTitle');
    }
  };

  useEffect(() => {
    if (createMode) {
      values.setValue('applyToPartnership', true);
      values.setValue('validIndefinitely', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormV2Context.Provider value={values}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Dialog
          title={getTitle()}
          confirmText={t('action.save')}
          cancelText={viewMode ? t('action.close') : t('action.cancel')}
          isConfirmLoading={isDataLoading || isLoading}
          onConfirm={handleSubmit(submitHandler)}
          onCancel={onCloseDialog}
          confirmButtonTooltipText={{ disabled: t('loading') }}
          dialogSize="medium"
          isConfirmButtonDisabled={isConfirmDisabled}
          isConfirmButtonVisible={!viewMode}
          isOpen
        >
          <Section isHeaderVisible={false} isCollapsable isModalSection>
            <GridLayout itemProps={{ xs: 6 }}>
              {fields?.map((name, index) =>
                typeof name === 'string'
                  ? renderField(name as keyof PermissionFields, {
                      key: index
                    })
                  : name
              )}
            </GridLayout>
          </Section>
          {!createMode && (
            <Section title={t('permission:section.generalInformation')} isModalSection>
              <GridLayout itemProps={{ xs: 6 }}>
                {renderField('created', { inputMode: InputMode.VIEW })}
                {renderField('author', { inputMode: InputMode.VIEW })}
                {renderField('modified', { inputMode: InputMode.VIEW })}
                {renderField('modifier', { inputMode: InputMode.VIEW })}
              </GridLayout>
            </Section>
          )}
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
}

export default PermissionDialog;
