import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PluginHook } from 'react-table';
import { ExtendedFinancialSecuritySearchFilter as ExtendedFinancialSecuritySearchFilterClient } from '@ibtm/client-domain';
import { ExtendedFinancialSecuritySearchFilter, FinancialSecurityDetails } from '@ibtm/domain';

import {
  Button,
  CamelCasePath,
  TableButton,
  TableButtonMore,
  TableButtonView,
  TableContext,
  TableLayout
} from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { FinancialSecuritySearchButton } from '@libs/domain/financial-security';

import {
  useFinancialSecurityCreateDialog,
  useFinancialSecurityDeleteAction,
  useFinancialSecurityDetailsDialog,
  useFinancialSecurityEditDialog,
  useFinancialSecurityExpireAction,
  useFinancialSecurityRestoreAction,
  useFinancialSecurityTable
} from '../hooks';
import { useFinancialSecuritySearch } from '../hooks/action';
import { FinancialSecurityActions, FinancialSecurityStatusEnum } from '../model';

export interface FinancialSecurityPermissionsObj {
  create?: UIElementNameEnum;
  edit?: UIElementNameEnum;
  expire?: UIElementNameEnum;
  restore?: UIElementNameEnum;
  delete?: UIElementNameEnum;
  search?: UIElementNameEnum;
  details?: UIElementNameEnum;
}

interface IProps {
  folderTypeKey: string;
  children?: ReactNode;
  folderId?: string;
  transferredFoldersIds?: string[];
  applicationId?: string;
  /**
   * Ukryte pola w oknie dodawania edycji i szczegółach
   */
  hiddenFields?: Array<string>;
  visibleColumns?: Array<CamelCasePath<FinancialSecurityDetails>>;
  actions?: Array<FinancialSecurityActions>;
  tablePlugins?: PluginHook<Record<string, string>>[];
  queryParams?: ExtendedFinancialSecuritySearchFilterClient | ExtendedFinancialSecuritySearchFilter;
  isSection?: boolean;
  isHeaderHidden?: boolean;
  isActionColumnEnabled?: boolean;
  permissionsKeys?: FinancialSecurityPermissionsObj;
  isCollapsable?: boolean;
  isFolderView?: boolean;
  isHeaderActionsVisible?: boolean;
  selectionColumnWidth?: number;
  subjectId?: string;
  customOnSuccess?: () => void;
  isHiddenSelectRowHeader?: boolean;
  exchangeRateFrom?: string;
  exchangeRateTo?: string;
}

function FinancialSecurityTable({
  folderId,
  transferredFoldersIds,
  applicationId,
  visibleColumns,
  actions,
  tablePlugins,
  queryParams,
  children,
  isSection = true,
  isHeaderHidden,
  isActionColumnEnabled = true,
  permissionsKeys,
  folderTypeKey,
  isCollapsable = false,
  isFolderView,
  isHeaderActionsVisible,
  selectionColumnWidth,
  subjectId,
  customOnSuccess,
  isHiddenSelectRowHeader,
  exchangeRateFrom,
  exchangeRateTo
}: IProps) {
  const [t] = useTranslation();
  const { getQuery } = useGetApiQueryByPerspective();

  const tableProps = useFinancialSecurityTable({
    visibleColumns,
    tablePlugins,
    queryParams,
    folderTypeKey,
    applicationId
  });

  const { showFinancialSecurityCreateDialog } = useFinancialSecurityCreateDialog({
    folderId,
    applicationId,
    folderTypeKey,
    subjectId,
    exchangeRateFrom,
    exchangeRateTo
  });
  const { openFinancialSecuritySearchModal } = useFinancialSecuritySearch({
    folderId,
    transferredFoldersIds,
    applicationId,
    folderTypeKey,
    customOnSuccess
  });
  const { showFinancialSecurityDetailsDialog } = useFinancialSecurityDetailsDialog();
  const { showFinancialSecurityEditDialog } = useFinancialSecurityEditDialog();
  const { handleFinancialSecurityDelete } = useFinancialSecurityDeleteAction();
  const { handleFinancialSecurityExpire } = useFinancialSecurityExpireAction();
  const { handleFinancialSecurityRestore } = useFinancialSecurityRestoreAction();

  const showAction = (action: FinancialSecurityActions) => actions?.includes(action);

  const xlsxDownloadApi = useCallback(
    params => {
      const operatorPortalFinancialSecurityRequestParams = {
        ...params,
        ...(folderId && isFolderView && { folderIdIn: [folderId, ...(transferredFoldersIds || [])] }),
        ...(applicationId && { applicationIdIn: [applicationId] })
      };

      const apiMethod = getQuery(
        () =>
          applicationId
            ? API.client.financialSecurity.getFinancialSecuritiesSnapshotPageForApplication(
                '',
                '',
                applicationId,
                params
              )
            : API.client.financialSecurity.getFolderFinancialSecuritiesSnapshotPage('', '', params),
        () =>
          applicationId
            ? API.financialSecurity.getFinancialSecuritiesSnapshotPageForApplication(
                applicationId,
                operatorPortalFinancialSecurityRequestParams
              )
            : API.financialSecurity.getFinancialSecuritiesSnapshotPage(operatorPortalFinancialSecurityRequestParams)
      );

      return apiMethod();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [applicationId]
  );

  const isSearchActionVisible = showAction('search');
  const isCreateActionVisible = showAction('create');

  const mobileHeaderActions = [
    {
      isHidden: !isSearchActionVisible,
      onClick: openFinancialSecuritySearchModal,
      label: t('financialSecurity:action.search.title'),
      actionKey: permissionsKeys?.search
    },
    {
      isHidden: !isCreateActionVisible,
      onClick: showFinancialSecurityCreateDialog,
      label: t('action.add'),
      actionKey: permissionsKeys?.create
    }
  ];

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('financialSecurity:folderTitle')}
      isSection={isSection}
      xlsxDownload={{
        fileName: t('financialSecurity:listTitle'),
        pathToXLSXTranslation: 'financialSecurity:field',
        apiRequest: xlsxDownloadApi
      }}
      mobileHeaderActions={mobileHeaderActions}
      headerActions={
        isHeaderActionsVisible && (
          <>
            {isSearchActionVisible && (
              <FinancialSecuritySearchButton
                applicationId={applicationId}
                folderId={folderId}
                transferredFoldersIds={transferredFoldersIds}
                folderTypeKey={folderTypeKey}
                actionKey={permissionsKeys?.search}
                customOnSuccess={customOnSuccess}
              />
            )}
            {isCreateActionVisible && (
              <Button
                label={t('action.add')}
                actionKey={permissionsKeys?.create}
                onClick={showFinancialSecurityCreateDialog}
                variant="outlined"
                classNameWrapper="ml-16"
                isNoMargin
              />
            )}
          </>
        )
      }
      rowActions={({ original }) => {
        const isExpireButtonVisible = showAction('expire') && original.statusKey === FinancialSecurityStatusEnum.ACTIVE;
        const isRestoreButtonVisible =
          showAction('restore') && original.statusKey === FinancialSecurityStatusEnum.EXPIRED;
        const isEditButtonVisible = showAction('edit');
        const isDeleteButtonVisible = showAction('delete');

        return [
          (isExpireButtonVisible || isRestoreButtonVisible || isEditButtonVisible || isDeleteButtonVisible) && (
            <TableButtonMore key={0}>
              {close => (
                <>
                  {isExpireButtonVisible && (
                    <TableContext.Consumer>
                      {({ refetch }) => (
                        <TableButton
                          actionKey={permissionsKeys?.expire}
                          label={t('financialSecurity:action.expire.title')}
                          onClick={() => {
                            handleFinancialSecurityExpire({
                              financialSecurityId: original.id,
                              version: original.version,
                              expirationDate: original.expirationDate,
                              onSuccess: () => {
                                refetch();
                              },
                              onSettled: () => {
                                close();
                              }
                            });
                          }}
                        />
                      )}
                    </TableContext.Consumer>
                  )}
                  {isRestoreButtonVisible && (
                    <TableContext.Consumer>
                      {({ refetch }) => (
                        <TableButton
                          label={t('financialSecurity:action.restore.title')}
                          actionKey={permissionsKeys?.restore}
                          onClick={() => {
                            handleFinancialSecurityRestore({
                              financialSecurityId: original.id,
                              version: original.version,
                              expirationDate: original.expirationDate,
                              onSuccess: () => {
                                refetch();
                              },
                              onSettled: () => {
                                close();
                              }
                            });
                          }}
                        />
                      )}
                    </TableContext.Consumer>
                  )}
                  {isEditButtonVisible && (
                    <TableButton
                      label={t('action.edit')}
                      actionKey={permissionsKeys?.edit}
                      onClick={() => {
                        showFinancialSecurityEditDialog({
                          folderTypeKey,
                          financialSecurityId: original.id,
                          initialData: original,
                          exchangeRateFrom,
                          exchangeRateTo
                        });
                        close();
                      }}
                    />
                  )}
                  {isDeleteButtonVisible && (
                    <TableButton
                      actionKey={permissionsKeys?.delete}
                      label={t('action.delete')}
                      onClick={() => {
                        handleFinancialSecurityDelete({
                          financialSecurityId: original.id,
                          expirationDate: original.expirationDate,
                          onSuccess: () => {
                            customOnSuccess?.();
                          }
                        });
                        close();
                      }}
                    />
                  )}
                </>
              )}
            </TableButtonMore>
          ),
          showAction('details') && (
            <TableButtonView
              actionKey={permissionsKeys?.details}
              onClick={() =>
                showFinancialSecurityDetailsDialog(
                  folderTypeKey,
                  original.id,
                  exchangeRateFrom,
                  exchangeRateTo,
                  original
                )
              }
            />
          )
        ];
      }}
      isCollapsable={isCollapsable}
      isHeaderHidden={isHeaderHidden}
      isActionColumnEnabled={isActionColumnEnabled}
      selectionColumnWidth={selectionColumnWidth}
      isHiddenSelectRowHeader={isHiddenSelectRowHeader}
    >
      {children}
    </TableLayout>
  );
}

export default FinancialSecurityTable;
