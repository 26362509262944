import { SuspensionUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function updateSuspension({ id, request }: { id: string; request: SuspensionUpdateRequest }) {
  return API.suspensions.updateSuspension(id, request, {
    ...endpointsConfig.updateSuspension
  });
}

function useUpdateSuspensionMutation() {
  return useMutation(updateSuspension);
}

export default useUpdateSuspensionMutation;
