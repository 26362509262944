import { Cancel } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Theme } from '@libs/common/v2/theme';
import { hexToRgba } from '@libs/common/v2/utils';

const chipStyles = `
  .css-1dsbpcp { 
    position: relative!important; 
  }
`;

export function StyledChip({ ...forwardProps }) {
  const classes = useStyles();
  return (
    <>
      <style>{chipStyles}</style>
      <Chip deleteIcon={<Cancel fontSize="small" />} {...forwardProps} className={classes.root} />
    </>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    marginRight: `6px!important`,
    marginTop: `6px!important`,
    marginBottom: `6px!important`,
    padding: `2px!important`,
    background: `${hexToRgba(theme.palette.primary?.main, 0.15)}!important`,
    border: `1px solid ${theme.palette.primary?.main}!important`,
    borderRadius: `16px!important`,
    color: `${theme.palette.primary?.main}!important`,
    fontSize: `${theme.typography?.getFontSize(12)}!important`,
    height: 'auto!important',
    '& span': {
      whiteSpace: 'normal!important'
    },
    '& svg': {
      color: `${theme.palette.primary?.main}!important`,
      opacity: '.9!important'
    },
    '& svg:hover, & svg:focus': {
      color: `${theme.palette.primary?.main}!important`,
      opacity: `1!important`
    }
  }
}));

export default StyledChip;
