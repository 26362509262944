import { useTranslation } from 'react-i18next';
import { queryCache } from 'react-query';
import { useAppConfig } from '@libs/app-config';

import { IconButton, TableLayout, useRouter, useTableRowActions } from '@libs/common/v2';

import { useGenerateReport } from '@libs/report/hooks';

import { API } from '@libs/domain/api';
import { DomainUIElementEnum, FolderElementsPermissionsEnum, useFolderElementsVisibility } from '@libs/domain/config';

import { ArchiveQueryKeysEnum } from '../../../common';
import { useFolderArchivedTable, useSendFolderFromArchiveDialog, useUpdateFolderWarningDialog } from '../../hooks';
import { FolderArchiveSnapshot } from '../../model';
import { parseFolderArchiveList } from '../../utils';

function FolderArchivedTab() {
  const [t] = useTranslation();

  const { checkFolderElementVisibility } = useFolderElementsVisibility();

  const tableProps = useFolderArchivedTable();
  const { openUpdateFolderWarningDialog } = useUpdateFolderWarningDialog();
  const { openSendFolderFromArchiveDialog } = useSendFolderFromArchiveDialog();
  const { routes } = useRouter();
  const { reportTypes } = useAppConfig();
  const [generateReport] = useGenerateReport(reportTypes?.companyArchiveDeliveryAndSummaryListOfFiles?.reportTypeName);

  const { renderTableActions } = useTableRowActions<FolderArchiveSnapshot>([
    {
      id: DomainUIElementEnum.ARCHIVE_FOLDER_ARCHIVED_ADD_NOTE_BUTTON,
      label: 'action.openDetails',
      link: ({ original }) => routes.folderDetails(original.id),
      icon: 'ArrowIcon',
      isHidden: ({ row }) =>
        !checkFolderElementVisibility({
          folderTypeKey: row.original.typeKey,
          permisson: FolderElementsPermissionsEnum.FOLDER_VIEW_PERMISSIONS
        })
    },
    {
      id: DomainUIElementEnum.ARCHIVE_FOLDER_ARCHIVED_UNDO_FROM_ARCHIVE_BUTTON,
      label: 'archive:action.restoreFromArchive',
      onClick: ({ row: { original } }) => openSendFolderFromArchiveDialog([original.id]),
      icon: 'ReturnIcon'
    },
    {
      id: DomainUIElementEnum.ARCHIVE_FOLDER_ARCHIVED_ADD_NOTE_BUTTON,
      label: 'archive:application.field.warning',
      onClick: ({ row: { original } }) =>
        openUpdateFolderWarningDialog(
          original.id,
          () => queryCache.invalidateQueries(ArchiveQueryKeysEnum.FOLDERS_TO_ARCHIVE),
          original?.version,
          original?.warning
        ),
      icon: 'NoteIcon'
    }
  ]);

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('archive:folder.companyArchive')}
      xlsxDownload={{
        fileName: t('archive:folder.companyArchive'),
        pathToXLSXTranslation: 'archive:folder.field',
        apiRequest: params => {
          return API.archiveFolders.getArchiveFolders(params).then(res => ({
            ...res,
            data: {
              ...res.data,
              content: parseFolderArchiveList(res.data.content)
            }
          }));
        }
      }}
      rowActions={renderTableActions([
        DomainUIElementEnum.ARCHIVE_FOLDER_ARCHIVED_SHOW_DETAILS_BUTTON,
        DomainUIElementEnum.ARCHIVE_FOLDER_ARCHIVED_UNDO_FROM_ARCHIVE_BUTTON,
        DomainUIElementEnum.ARCHIVE_FOLDER_ARCHIVED_ADD_NOTE_BUTTON
      ])}
      isSectionFullTab
      headerActions={
        <IconButton
          icon="FileGenerateIcon"
          onClick={generateReport}
          tooltipTitle={t('archive:folder.action.generateCasesList')}
          isBackgroundTransparent
          actionKey={DomainUIElementEnum.ARCHIVE_FOLDER_ARCHIVED_GENERATE_CASE_LIST_BUTTON}
        />
      }
    />
  );
}

export default FolderArchivedTab;
