import { useTableLayoutProps } from '@libs/common/v2';

import { TransportManagerDetailsClient } from '../model';

import { useTransportManagerReputationColumns } from '.';

function useTransportManagerReputationsTable(data: TransportManagerDetailsClient['reputationInquires']) {
  const { columns } = useTransportManagerReputationColumns();

  return useTableLayoutProps({
    tableQuery: data || [],
    tableHookOptions: {
      columns
    }
  });
}

export default useTransportManagerReputationsTable;
