import { PaletteMode } from '@mui/material';
import { lightBlue, red } from '@mui/material/colors';

const commonDark = {
  50: '#ECECEE',
  100: '#DAE1FB',
  200: '#B7C3F7',
  300: '#8D9EE8',
  400: '#6B7CD2',
  500: '#3F51B5',
  600: '#2E3D9B',
  700: '#1F2B82',
  800: '#141C68',
  900: '#0C1256',
  A100: '#C5C6CB',
  A200: '#9EA1A9',
  A400: '#5C616F',
  A700: '#2D323E'
};

type LayoutTheme = 'default' | 'mainThemeLight' | 'mainThemeDark';

interface ILayoutConfigSection {
  display?: boolean;
}

interface ILayoutConfig {
  layout: {
    style: any;
    config: {
      navbar?: ILayoutConfigSection;
      topBar?: ILayoutConfigSection;
      footer?: ILayoutConfigSection;
      leftSidePanel?: ILayoutConfigSection;
      rightSidePanel?: ILayoutConfigSection;
    };
  };
  customScrollbars: boolean;
  theme: {
    main?: LayoutTheme;
    navBar?: LayoutTheme;
    topBar?: LayoutTheme;
    footer?: LayoutTheme;
  };
}

export const CommonDefaultLayoutConfig: ILayoutConfig = {
  layout: {
    style: 'layout',
    config: {}
  },
  customScrollbars: true,
  theme: {
    main: 'default',
    navBar: 'mainThemeLight',
    topBar: 'mainThemeLight',
    footer: 'mainThemeDark'
  }
};

export const CommonDefaultThemeOptions = {
  typography: {
    fontFamily: ['Muli', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    useNextVariants: true,
    suppressDeprecationWarnings: true
  }
};

export const CommonMustHaveThemeOptions = {
  typography: {
    htmlFontSize: 10,
    body1: {
      fontSize: '1.4rem'
    },
    body2: {
      fontSize: '1.4rem'
    },
    h4: {
      fontSize: '2.2rem',
      fontWeight: 500
    }
  }
};

export const CommonDefaultThemesConfig = {
  default: {
    palette: {
      mode: 'light' as PaletteMode,
      primary: commonDark,
      secondary: {
        light: lightBlue[400],
        main: lightBlue[600],
        dark: lightBlue[700]
      },
      error: red
    },
    status: {
      danger: 'orange'
    }
  },
  defaultDark: {
    palette: {
      mode: 'dark' as PaletteMode,
      primary: commonDark,
      secondary: {
        light: lightBlue[400],
        main: lightBlue[600],
        dark: lightBlue[700]
      },
      error: red
    },
    status: {
      danger: 'orange'
    }
  }
};

export const themesConfig = {
  default: {
    palette: {
      mode: 'light' as PaletteMode,
      primary: {
        dark: '#2C40AF',
        main: '#3F51B5'
      },
      secondary: {
        light: '#768b94',
        main: '#546e7a',
        dark: '#3a4d55'
      },
      text: {
        primary: '#263238',
        secondary: '#546E7A'
      },
      error: red
    },
    status: {
      danger: 'orange'
    }
  },
  defaultDark: {
    palette: {
      mode: 'dark' as PaletteMode,
      primary: commonDark,
      secondary: {
        light: lightBlue[400],
        main: lightBlue[600],
        dark: lightBlue[700]
      },
      error: red
    },
    status: {
      danger: 'orange'
    }
  },
  sunset: {
    palette: {
      mode: 'light' as PaletteMode,
      primary: {
        light: '#FF908B',
        main: '#D0605E',
        dark: '#9B3134'
      },
      secondary: {
        light: '#C76A1D',
        main: '#FF994C',
        dark: '#FFCA7B',
        contrastText: '#FFF',
        shadow: '#2D323E'
      },
      error: red
    },
    status: {
      danger: 'orange'
    }
  }
};
