import { AttachmentVerificationElementDetails } from '@ibtm/domain';

import { IAttachmentListItemClient, RequiredFileEnum } from '../model';
import { IAttachmentStatus } from '../model/common.model';

// eslint-disable-next-line import/prefer-default-export
export const parseAttachmentsList = (
  attachments: AttachmentVerificationElementDetails[]
): IAttachmentListItemClient[] =>
  attachments.map((attachment, index) => ({
    id: attachment.id,
    name: attachment.name as RequiredFileEnum,
    notes: attachment.notes,
    status: attachment.statusKey as IAttachmentStatus,
    order: index + 1
  }));
