import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Section, Value } from '@libs/common/v2';
import { getValue } from '@libs/common/v2/utils';

import { PermissionTableEnum, useApplicationDetailsQuery, useVisiblePermissionTables } from '@libs/domain/application';
import { useDomainConfigContext } from '@libs/domain/config';
import { useTravelFormsQuery } from '@libs/domain/folder';

import { IApplicationDetailsPageParams } from '../model';

function ApplicationTravelSections() {
  const { applicationId } = useParams<IApplicationDetailsPageParams>();
  const { data } = useApplicationDetailsQuery(applicationId, { refetchOnMount: false });
  const [t] = useTranslation();
  const blankNumber = t('prints:field.detailsTablesFields.blankNumbersFully');
  const { isOperatorPortal } = useDomainConfigContext();
  const { data: travelFormData } = useTravelFormsQuery({ applicationId }, { enabled: isOperatorPortal });

  const interbusFormNumbers = travelFormData?.travelForms
    ?.filter(item => item?.interbusForm)
    ?.map(item => item?.interbusForm.fullNumber)
    ?.join(', ');

  const travelFormNumbers = travelFormData?.travelForms
    ?.filter(item => item?.travelFormsBookForm)
    ?.map(item => item?.travelFormsBookForm.fullNumber)
    ?.join(', ');

  const { checkIsVisible } = useVisiblePermissionTables();
  const isVisible = checkIsVisible(data);

  const { TRAVEL_FORM, INTERBUS } = PermissionTableEnum;
  return (
    <>
      {isVisible[INTERBUS] && (
        <Section title={t('prints:field.detailsFields.interbus')} isCollapsable>
          <Value label={blankNumber} value={getValue(interbusFormNumbers)} />
        </Section>
      )}
      {isVisible[TRAVEL_FORM] && (
        <Section title={t('prints:field.detailsFields.travelForm')} isCollapsable>
          <Value label={blankNumber} value={getValue(travelFormNumbers)} />
        </Section>
      )}
    </>
  );
}
export default ApplicationTravelSections;
