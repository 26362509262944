import { useMemo } from 'react';

import { useGetContextValidators, useValidationBuilder } from '@libs/common/v2';

import { useMetaFormContext } from '@libs/meta-form/context';
import {
  getFieldsWithValidation,
  metaFormValidationSchemaBuilder
} from '@libs/meta-form/validation/validation-builder';
import createValidationRegistry from '@libs/meta-form/validation/validation-registry';

import { Field } from '../models';

interface IParams {
  validationTypeKey: string;
  fieldIds: Array<string>;
}

function useMetaFormYupValidation({ validationTypeKey, fieldIds }: IParams) {
  const {
    validationSchemeRegistry,
    fields,
    fieldsValidationTypeMap: customFieldsValidationTypeMap
  } = useMetaFormContext();
  const { validationBuilderFunctions, validationScheme } = useValidationBuilder(
    validationSchemeRegistry?.[validationTypeKey]
  );

  const contextValidators = useGetContextValidators();

  const validation = useMemo(
    () =>
      // tworzenie walidacji przekazanej w properties "yupValidation" w JSON
      metaFormValidationSchemaBuilder({
        fields: getFieldsWithValidation({ fieldIds, fields }) as Field[],
        validators: createValidationRegistry(contextValidators),
        ...(validationScheme && { extendYupScheme: validationScheme }),
        customFieldsValidationTypeMap: customFieldsValidationTypeMap.current
      }),
    [fieldIds, fields, contextValidators, validationScheme, customFieldsValidationTypeMap]
  );

  return { validationBuilderFunctions, validation };
}

export default useMetaFormYupValidation;
