import { useMutation } from 'react-query';
import { FinancialSecurityUpdateRequest } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function updateFinancialSecurity({
  financialSecurityId,
  updateRequestData
}: {
  financialSecurityId: string;
  updateRequestData: FinancialSecurityUpdateRequest;
}) {
  return API.financialSecurity.updateFinancialSecurity(financialSecurityId, updateRequestData, {
    ...endpointsConfig.updateFinancialSecurity
  });
}

function useFinancialSecurityUpdateMutation() {
  return useMutation(updateFinancialSecurity);
}

export default useFinancialSecurityUpdateMutation;
