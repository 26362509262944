import { QueryConfig, useQuery } from 'react-query';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import { ExploitationBaseQueryKeysEnum } from './ExploitationBaseQueryKeysEnum';

const hasAnyNegativeControlResult = async (_: any, folderId: string) => {
  const { data } = await API.folder.containsBasesWithNegativeInspections(folderId, {
    ...endpointsConfig.containsBasesWithNegativeInspections
  });
  return data.areThereNegativeInspections;
};

export default function useHasAnyNegativeControlResultQuery(
  folderId: string,
  queryConfig: QueryConfig<any, unknown> = {}
) {
  return useQuery<boolean>(
    [ExploitationBaseQueryKeysEnum.HAS_NEGATIVE_CONTROL_RESULT, folderId],
    hasAnyNegativeControlResult,
    queryConfig
  );
}
