import { AccountsApiUnlockAccountRequest } from '@avispon/user';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '..';

const resumeUser = (params: AccountsApiUnlockAccountRequest) => {
  return API.user.accountController.unlockAccount({ ...params }, { ...endpointsConfig.unlockAccount });
};

function useResumeUserMutation() {
  return useMutation(resumeUser);
}

export default useResumeUserMutation;
