import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DropdownListButton, useTableAdapter } from '@libs/common/v2';

import useSendCarryOnArchiveFolderToArchiveDialog from '../../folder/hooks/useSendCarryOnArchiveFolderToArchiveDialog';
import { FolderArchiveSnapshot } from '../../folder/model';

interface IProps {
  selected: string[];
  handleClose: () => void;
}

function ArchiveTableSelectAction({ selected, handleClose }: IProps) {
  const [t] = useTranslation();
  const { openSendCarryOnArchiveFolderToArchiveDialog } = useSendCarryOnArchiveFolderToArchiveDialog();
  const { toggleAllRowsSelected, selectedFlatRows } = useTableAdapter();

  const handleIconClick = () => {
    openSendCarryOnArchiveFolderToArchiveDialog(selected);
    toggleAllRowsSelected(false);
    handleClose();
  };

  const selectedRows = useMemo<FolderArchiveSnapshot[]>(
    () => selectedFlatRows.map(item => item.original),
    [selectedFlatRows]
  );

  const isSendToArchiveDisabled = useMemo(() => selectedRows.some(i => i.activeApplicationOnFolder), [selectedRows]);

  return (
    <DropdownListButton
      label={t('archive:action.sendToArchive')}
      tooltipTitle={isSendToArchiveDisabled ? t('archive:folder.message.folderHasActiveApplicationTableAction') : null}
      onClick={handleIconClick}
      isDisabled={isSendToArchiveDisabled}
    />
  );
}

export function FolderInBranchArchiveSelectRowActionRender(selected: string[], handleClose: () => void) {
  return <ArchiveTableSelectAction selected={selected} handleClose={handleClose} />;
}
