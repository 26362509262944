import React, { Context, useContext, useState } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { InputBaseProps } from '@mui/material';

import {
  ComponentErrorBoundary,
  FormErrorType,
  FormV2Context,
  FormV2ContextState,
  getInputMode,
  InputMode,
  TextInput,
  Value
} from '@libs/common/v2';
import { getValue } from '@libs/common/v2/utils';

import { FieldTypeEnum, NumberFieldValidation, useFieldValidationHandler } from '../../validation';

export interface NumberInputFieldProps {
  name: FieldPath<FieldValues>;
  label?: string;
  formContext?: Context<FormV2ContextState>;
  inputProps?: InputBaseProps['inputProps'];
  placeholder?: string;
  valueClassName?: string;
  className?: string;
  min?: number;
  max?: number;
  step?: number;
  validation?: NumberFieldValidation;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputMode?: InputMode;
  isDisabled?: boolean;
  isClearable?: boolean;
  isRequired?: boolean;
  tooltip?: string;
  decimalPrecision?: number;
  isOnlyPositiveIntegers?: boolean;
  onBlur?: () => void;
  hasErrorTooltip?: boolean;
}

function NumberInputField({
  name,
  formContext = FormV2Context,
  isDisabled,
  placeholder,
  className,
  step,
  min = 0,
  max,
  label,
  isClearable,
  valueClassName,
  isRequired,
  inputProps,
  inputMode,
  validation,
  tooltip,
  onChange: handleChange,
  decimalPrecision,
  isOnlyPositiveIntegers,
  onBlur: handleBlur,
  hasErrorTooltip
}: NumberInputFieldProps) {
  const { control, formMode, loading, isSubmitting } = useContext<FormV2ContextState>(formContext);
  const mode = getInputMode(formMode, inputMode);

  const [inputValue, setInputValue] = useState<string>();

  const parseValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (isClearable && value === '') {
      return null;
    }
    let result = value;
    const numberValue = parseFloat(result);

    if (decimalPrecision) {
      const valueParts = result.split('.');
      const decimalPart = valueParts[1]?.slice(0, decimalPrecision);
      result = decimalPart ? `${valueParts[0]}.${decimalPart}` : valueParts[0];
    }

    if (numberValue > max) result = max.toString();
    if (numberValue < min) result = min.toString();

    return result;
  };

  const onPositiveNumberChange = (e, value) => {
    if (e.nativeEvent instanceof InputEvent && e.nativeEvent.data === '.') {
      // eslint-disable-next-line no-param-reassign
      e.target.value = value || inputValue;
    }
    // eslint-disable-next-line no-param-reassign
    e.target.value = e.target.value.replace(/\./g, '').trim();
    setInputValue(e.target.value);
  };

  useFieldValidationHandler({
    fieldName: name,
    validation,
    fieldType: FieldTypeEnum.NUMBER
  });

  return (
    <ComponentErrorBoundary componentName={label || placeholder || name || 'NumberInputField'}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => {
          return mode === InputMode.FORM ? (
            <TextInput
              name={name}
              label={label}
              className={className}
              value={value >= min ? value : ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChange(parseValue(e));
                const parsedEvent = { ...e };
                parsedEvent.target.value = String(parseValue(e));
                handleChange?.(parsedEvent);
              }}
              onBlur={() => {
                onBlur?.();
                handleBlur?.();
              }}
              required={isRequired || Boolean(validation?.required) || false}
              error={!!error && error?.type !== FormErrorType.WARNING}
              isWarning={error?.type === FormErrorType.WARNING}
              helperText={error?.message}
              disabled={isDisabled || isSubmitting}
              placeholder={placeholder}
              type="number"
              tooltip={tooltip}
              inputProps={{
                ...inputProps,
                ...(step && { step }),
                ...(min && { min }),
                ...(max && { max })
              }}
              {...(isOnlyPositiveIntegers ? { onInput: e => onPositiveNumberChange(e, value) } : {})}
              hasErrorTooltip={hasErrorTooltip}
              fullWidth
            />
          ) : (
            <Value
              label={label}
              isLoading={loading}
              className={valueClassName}
              isError={!!error && error?.type !== FormErrorType.WARNING}
              isWarning={error?.type === FormErrorType.WARNING}
              helperText={error?.message}
            >
              {!loading && getValue(value as number)}
            </Value>
          );
        }}
      />
    </ComponentErrorBoundary>
  );
}

export default NumberInputField;
