import { useTranslation } from 'react-i18next';
import { AlertTypeSnapshot } from '@stack/report';

import { GridLayout, InputMode, Section, TextInputField, TextValueField, typedNameV2 } from '@libs/common/v2';
import { convertDateToDateTimeFormat } from '@libs/common/v2/utils';

function RegisterAlertSection() {
  const [t] = useTranslation();

  return (
    <Section title={t('alerts:section.register')} isCollapsable>
      <GridLayout itemProps={{ xs: 12 }}>
        <TextValueField name="created" label={t('alerts:field.created')}>
          {convertDateToDateTimeFormat}
        </TextValueField>
        <TextInputField
          inputMode={InputMode.VIEW}
          name={typedNameV2<AlertTypeSnapshot>('createdBy.name')}
          label={t('alerts:field.createdBy')}
        />
        <TextInputField
          inputMode={InputMode.VIEW}
          name={typedNameV2<AlertTypeSnapshot>('authorUnit.name')}
          label={t('alerts:field.authorUnit')}
        />
      </GridLayout>
    </Section>
  );
}

export default RegisterAlertSection;
