import { useTranslation } from 'react-i18next';

import { DropdownListButton, useTableAdapter } from '@libs/common/v2';

import { useSendApplicationsFromArchiveDialog } from '../../application/hooks/dialogs';

interface Props {
  selected: string[];
  handleClose: () => void;
}

function SelectAction({ selected, handleClose }: Props) {
  const [t] = useTranslation();
  const { openSendApplicationsFromArchiveDialog } = useSendApplicationsFromArchiveDialog();
  const { toggleAllRowsSelected } = useTableAdapter();

  const handleRestore = () => {
    openSendApplicationsFromArchiveDialog(selected);
    toggleAllRowsSelected(false);
    handleClose();
  };

  return <DropdownListButton label={t('archive:action.restoreFromArchive')} onClick={handleRestore} />;
}

export default function ArchivedApplicationsSelectRowActionRender(selected: string[], handleClose: () => void) {
  return <SelectAction selected={selected} handleClose={handleClose} />;
}
