import { useCallback, useState } from 'react';
import { Column, OnFilterChange } from 'react-table';
import { makeStyles } from '@mui/styles';

import { SEARCH_TEXT_DEBOUNCE_TIME, TimepickerInput, useDebounce, useHandleClearFilters } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

interface IProps {
  column: Column & { filterValue?: string; setFilter: (value: string) => void };
  onFilterChange?: OnFilterChange;
}

function TimepickerFilter({ column: { filterValue, setFilter }, onFilterChange }: IProps) {
  const [value, setValue] = useState(filterValue ?? '');
  const onChangeDebounced = useDebounce(v => {
    setFilter(v);
    onFilterChange?.();
  }, SEARCH_TEXT_DEBOUNCE_TIME);
  const classes = useStyles();

  const handleClearInput = useCallback(() => {
    setValue('');
  }, []);

  useHandleClearFilters(handleClearInput);

  const handleChange = inputValue => {
    setValue(inputValue);
    onChangeDebounced(inputValue);
  };

  return <TimepickerInput value={value} textInputClassName={classes.root} onChange={handleChange} />;
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    '& > div > div ': {
      padding: important(0),
      marginTop: important(0),
      '& > input': {
        padding: important('8px 0 8px 8px'),
        fontSize: important(theme.typography.textXs.normal.fontSize),
        fontWeight: important(theme.typography.textXs.normal.fontWeight),
        lineHeight: important(theme.typography.textXs.normal.lineHeight)
      }
    }
  }
}));

export default TimepickerFilter;
