import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProceedingsApiUpdateProceedingRequest } from '@ibtm/domain';
import * as Yup from 'yup';

import { Dialog, DictionarySelectField, FormV2Context, GridLayout, typedNameV2 } from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { useUpdateProceedingMutation } from '../../api';
import { IChangeProceedingStatusFormFields, ProceedingSnapshotClient } from '../../model';

interface IProps {
  proceedingData: ProceedingSnapshotClient;
  closeDialog: () => void;
  onSuccess?: () => void;
}

function ProceedingChangeStatusDialog({ proceedingData, onSuccess, closeDialog }: IProps) {
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();

  const { mutate: updateProceeding, isLoading } = useUpdateProceedingMutation();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    reset
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        status: Yup.object().nullable().required()
      })
    ),
    defaultValues: {
      status: null
    }
  });

  useEffect(() => {
    reset({
      status: {
        value: proceedingData.status
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    const requestData: ProceedingsApiUpdateProceedingRequest = {
      proceedingId: proceedingData.id,
      proceedingUpdateRequest: {
        assigned: proceedingData.conductingPerson?.id,
        categoryKey: proceedingData.proceedingCategory,
        typeKey: proceedingData.proceedingType,
        version: proceedingData.version
      }
    };

    updateProceeding(requestData, {
      onSuccess: () => {
        onSuccess?.();

        showSnackbar('success', t('proceeding:messages.changeStatusSuccess'));
        closeDialog();
      }
    });
  };

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('proceeding:dialog.changeStatusDialogTitle')}
      confirmText={t('action.change')}
      cancelText={t('action.cancel')}
      onConfirm={() => handleSubmit(onSubmit)()}
      onCancel={closeDialog}
      maxWidth="md"
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <form>
          <GridLayout itemProps={{ xs: 12 }}>
            <DictionarySelectField
              name={typedNameV2<IChangeProceedingStatusFormFields>('status')}
              label={t('proceeding:fields.status')}
              dictionaryName={DomainDictionaryEnum.PROCEEDING_STATUS}
            />
          </GridLayout>
        </form>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default ProceedingChangeStatusDialog;
