import { ForeignPermissionSnapshot } from '@ibtm/client-domain';

import { ClientForeignPermissions } from '../model';

export const initialParamsMapper = {
  year: ({ value }: { value: string }) => ({ resourceTypeYearEquals: value })
};

export const parseClientForeignPermissionsList = (
  foreignPermissions: ForeignPermissionSnapshot[]
): ClientForeignPermissions[] =>
  foreignPermissions.map(foreignPermission => ({
    id: foreignPermission.id,
    countryCode: foreignPermission.issuingCountryCodeKey,
    name: foreignPermission.name,
    state: foreignPermission?.form?.stateKey,
    formNumber: foreignPermission?.form?.number,
    subjectName: foreignPermission?.subject?.name,
    codeKey: foreignPermission?.codeKey,
    type: foreignPermission?.typeKey,
    resourceTypeId: foreignPermission?.form?.type?.id
  }));
