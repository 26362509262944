import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import { AxiosPromise } from 'axios';

import { DatepickerField, GridLayout, Section, SelectWithFetchedOptionsField, useFormV2Context } from '@libs/common/v2';

import { BorrowFolderFormEnum, BorrowFolderSnapshot } from '../../../model';

interface ReturnSectionProps {
  row: BorrowFolderSnapshot;
  fetchFunction: (searchText: string) => AxiosPromise<any>;
  getOptionLabel: (option) => string;
  renderOption: (props: Record<string, any>, option: any, state: AutocompleteRenderOptionState) => React.ReactNode;
}

export function ReturnSection({ row, fetchFunction, getOptionLabel, renderOption }: ReturnSectionProps) {
  const [t] = useTranslation();

  const { setValue } = useFormV2Context();

  useEffect(() => {
    if (row.borrowDate) {
      setValue(BorrowFolderFormEnum.BORROW_DATE, row?.borrowDate);
    }
  }, [row, setValue]);

  const borrowDate = useMemo(() => {
    return new Date(row?.borrowDate);
  }, [row?.borrowDate]);

  return (
    <Section title={t('archive:borrowFolder.returnTitleSection')} isModalSection>
      <GridLayout itemProps={{ xs: 12, sm: 6 }}>
        <DatepickerField
          label={t('archive:borrowFolder.field.returnDate')}
          name={BorrowFolderFormEnum.RETURN_DATE}
          minDate={borrowDate}
          isRequired
        />
        <SelectWithFetchedOptionsField
          fetchFunction={fetchFunction}
          name={BorrowFolderFormEnum.RETURN_PERSON}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          label={t('archive:borrowFolder.field.returnPerson')}
          isRequired
        />
      </GridLayout>
    </Section>
  );
}
