import { MutableRefObject, useCallback, useMemo, useRef, useState } from 'react';
import { Table as MuiTable } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import {
  ISpecialColumnEnum,
  LoaderCircular,
  LoaderLinear,
  MultipleSelectColumnPlugin,
  TableClassNames,
  TableFiltersPanel,
  TableSelectActionsRow,
  TableSelectRowsContextProvider,
  useGetScrollPosition,
  useTableAdapter,
  useTableContext
} from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { calc, hexToRgba, important } from '@libs/common/v2/utils';

import TableBody, { ITableBodyProps } from './ui/TableBody';
import TableHeadColumns, { ITableHeadColumnsProps } from './ui/TableHeadColumns';
import TablePagination, { ITablePaginationProps } from './ui/TablePagination';

interface IProps<T extends Record<string, any>, K extends Record<string, any> = T> {
  bodyProps?: ITableBodyProps<T, K>;
  paginationProps?: ITablePaginationProps;
  headerRowProps?: ITableHeadColumnsProps;
  scrollWrapperClassName?: string;
  disabledRows?: string[];
  disabledRowTooltipText?: string;
  isTableGrey?: boolean;
  isSection?: boolean;
  useOnlyCircleLoader?: boolean;
  isHiddenSelectRowHeader?: boolean;
  headerRef?: MutableRefObject<HTMLDivElement>;
}

function Table<T extends Record<string, any>, K extends Record<string, any> = T>({
  bodyProps: { onRowClick = null, ...restBodyProps },
  paginationProps = null,
  headerRowProps,
  scrollWrapperClassName,
  disabledRows,
  disabledRowTooltipText,
  isSection,
  isTableGrey,
  useOnlyCircleLoader = false,
  isHiddenSelectRowHeader,
  headerRef
}: IProps<T, K>) {
  const {
    scrollXEndReached,
    scrollXStartReached,
    refs: { setInnerScrollElementRef, setScrollElementRef }
  } = useGetScrollPosition();
  const tableAdapter = useTableAdapter();
  const {
    page,
    data,
    plugins,
    headerGroups,
    columns,
    isHiddenSelectRowHeader: isHiddenSelectRowHeaderFromAdapter
  } = tableAdapter;

  const scrollbarRef = useRef<HTMLElement | null>(null);
  const tableContext = useTableContext();
  const { isLoading, isFetching, isFilterEnabled } = tableContext;
  const classes = useStyles({
    pageCount: page.length,
    dataLength: data.length,
    isXScrollEnd: scrollXEndReached,
    isXScrollStart: scrollXStartReached,
    isSection,
    yScroll: scrollbarRef?.current?.className?.includes('ps--active-y') || page.length >= 11 || isSection,
    yScrollVisible: scrollbarRef?.current?.className?.includes('ps--active-y'),
    headerHeight: headerRef?.current?.offsetHeight
  });

  const [headCells, setHeadCells] = useState<{ name: string; ref: MutableRefObject<HTMLElement> }[]>([]);

  const scrollToColumn = useCallback(
    (columnName: string) => {
      const column = headCells?.find(c => c.name === columnName);
      if (column) {
        column?.ref?.current?.scrollIntoView?.({ behavior: 'auto', block: 'nearest', inline: 'center' });
      }
    },
    [headCells]
  );

  const isCircularLoadingInUse = useMemo(() => {
    if (useOnlyCircleLoader) {
      return isLoading || isFetching;
    }

    return isLoading;
  }, [useOnlyCircleLoader, isLoading, isFetching]);

  const hasSelectColumn = headerGroups?.[0]?.headers.some(column => column.id === ISpecialColumnEnum.SELECTION);
  const hasActionColumn = headerGroups?.[0]?.headers.some(column => column.id === ISpecialColumnEnum.ACTION);
  const columnsWidth = columns.map(column => column.width).toString();

  const TableHeader = useMemo(() => {
    const multipleSelectPluginName = plugins.find(item =>
      [
        MultipleSelectColumnPlugin.useMultipleSelectColumn,
        MultipleSelectColumnPlugin.useMultipleSelectColumnWithSelectRowContext
      ].includes(item.pluginName as MultipleSelectColumnPlugin)
    )?.pluginName as MultipleSelectColumnPlugin;

    return (
      <>
        <TableHeadColumns
          {...headerRowProps}
          setHeadCells={setHeadCells}
          tableAdapter={tableAdapter}
          tableContext={tableContext}
          headerRowRef={setInnerScrollElementRef}
        />
        <div className={classes.tableBodyHeader}>
          {isFilterEnabled && (
            <TableFiltersPanel isTableGrey={isTableGrey} tableContext={tableContext} tableAdapter={tableAdapter} />
          )}
          {multipleSelectPluginName && (
            <TableSelectActionsRow
              isTableGrey={isTableGrey}
              pluginName={multipleSelectPluginName}
              isHiddenSelectRowHeader={isHiddenSelectRowHeader || isHiddenSelectRowHeaderFromAdapter}
            />
          )}
        </div>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnsWidth,
    columns.length,
    headerRowProps.isPadded,
    headerRowProps.isWhiteBackground,
    headerRowProps.isWhiteBackground,
    headerRowProps.className,
    hasSelectColumn,
    hasActionColumn,
    isFilterEnabled,
    tableAdapter.state.filtersPanelOpen,
    tableAdapter.data.length
  ]);

  return (
    <TableSelectRowsContextProvider>
      <MuiTable component="div" classes={{ root: classes.muiTable }}>
        <div className={clsx(classes.scrollWrapper, scrollWrapperClassName, 'relative')}>
          <LoaderLinear
            className={clsx(classes.linearLoader)}
            isLoading={!useOnlyCircleLoader && isFetching && !isLoading}
            isDisabledDefaultStyles
          />
          <div ref={setScrollElementRef} className={clsx(classes.scrollbar, 'flex-1', 'w-auto', 'overflow-auto')}>
            {TableHeader}
            {isCircularLoadingInUse ? (
              <LoaderCircular
                isAbsolute={!isSection}
                className={clsx(isSection && 'flex justify-center h-full', useOnlyCircleLoader && 'mt-24')}
                isLoading
              />
            ) : (
              <TableBody
                onRowClick={onRowClick as any}
                {...(restBodyProps as ITableBodyProps<any>)}
                disabledRows={disabledRows}
                scrollToColumn={scrollToColumn}
                disabledRowTooltipText={disabledRowTooltipText}
              />
            )}
          </div>
        </div>
        {!isLoading && !!page.length && paginationProps && <TablePagination {...paginationProps} />}
      </MuiTable>
    </TableSelectRowsContextProvider>
  );
}
const useStyles = makeStyles<
  Theme,
  {
    pageCount?: number;
    dataLength?: number;
    yScroll: boolean;
    isSection?: boolean;
    yScrollVisible?: boolean;
    isXScrollEnd?: boolean;
    isXScrollStart?: boolean;
    headerHeight?: number;
  }
>(theme => ({
  scrollbar: {
    height: '100%',
    width: '100%',
    '& .ps__rail-x': {
      width: important('100%'),
      opacity: 0.6
    },
    '& .ps__rail-y': {
      zIndex: important(1198),
      opacity: 0.6
    },
    position: 'relative',
    borderRadius: theme.borderRadiusBase
  },
  scrollWrapper: {
    height: ({ yScroll, dataLength }) => {
      if (!dataLength) {
        return important('auto');
      }
      return yScroll ? calc('100% - 54px') : important('min-content');
    },
    flexGrow: ({ pageCount, yScroll, isSection }) => {
      if (!yScroll || isSection) {
        return 0;
      }
      return pageCount <= 11 && pageCount > 0 ? 1 : 0;
    },
    '& .scrollbar-container': {
      height: '100%'
    },
    width: '100%'
  },
  muiTable: {
    display: 'flex',
    flexDirection: 'column',
    height: ({ isSection, headerHeight }) => {
      if (isSection) {
        return '100%';
      }

      return calc(`100% - ${headerHeight || '88'}px`);
    },
    [`& .${TableClassNames.STICKY_RIGHT_COLUMN}`]: {
      boxShadow: ({ isXScrollEnd }) =>
        !isXScrollEnd ? `-3px 0 4px -1px ${hexToRgba(theme.palette.grey[900], 0.1)}` : 'none'
    },
    [`& .${TableClassNames.STICKY_LEFT_COLUMN}`]: {
      boxShadow: ({ isXScrollStart }) =>
        !isXScrollStart ? `3px 0 4px -1px ${hexToRgba(theme.palette.grey[900], 0.1)}` : 'none'
    }
  },
  linearLoader: {
    width: ({ yScrollVisible }) => {
      if (yScrollVisible) {
        return calc('100% - 10px');
      }
      return '100%';
    },
    position: 'absolute',
    top: '52px',
    zIndex: 1000
  },
  tableBodyHeader: {
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    top: 52,
    zIndex: 100
  }
}));

export default Table;
