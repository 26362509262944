import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { VehiclesSearchFilter as VehiclesSearchFilterClient } from '@ibtm/client-domain';
import { EuroClassDetails, VehiclesSearchFilter } from '@ibtm/domain';
import moment from 'moment';

import { partialTranslate } from '@libs/common';
import {
  AutocompleteLazyFetchSelectField,
  AutocompleteSelectField,
  Button,
  DatepickerField,
  DictionarySelectField,
  getBooleanOptions,
  GridItem,
  GridLayout,
  InputMode,
  QuickChangeableDictionarySelectField,
  Section,
  SelectOption,
  SelectOptionPartial,
  SwitchField,
  TextInputField,
  typedNameV2,
  useFormV2Context,
  useValidatorUtil,
  ValidatorEnums,
  WarningInformation,
  WarningThemeType
} from '@libs/common/v2';
import { useFormV2Watch, useViewModesV2 } from '@libs/common/v2/form';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import {
  DomainDictionaryEntry,
  DomainDictionaryEnum,
  DomainQuickchangeableDictionaryEnum,
  useDomainConfigContext
} from '@libs/domain/config';
import {
  IVehicleActionKeys,
  IVehicleFieldsRequiredByCategory,
  useCepikDataQuery,
  useTranslateEuroClass,
  VEHICLE_3_5T_WEIGHT,
  VehicleCepikDetails,
  VehicleFormData,
  VehicleQueryKeysEnum,
  VehicleSectionFields
} from '@libs/domain/vehicle';

const getLabel = partialTranslate('vehicle:field');

interface Props {
  addManuallyVisible: boolean;
  folderType: any;
  folderId?: string;
  transferredFoldersIds?: string[];
  isWithdrawnAlteredVehicleFieldVisible?: boolean;
  isVehicleSaleDate?: boolean;
  hiddenFieldsAddVehicle?: string[];
  checkCepikInformationFolderType?: boolean;
  setCepikData: (data: VehicleCepikDetails) => void;
  setAddManuallyVisible?: (boolean: boolean) => void;
  fieldsMode?: { [key in keyof VehicleFormData]?: InputMode };
  fieldsRequiredByCategory: IVehicleFieldsRequiredByCategory;
  actionKeys?: IVehicleActionKeys;
}

function VehicleSection({
  addManuallyVisible,
  folderType,
  folderId,
  transferredFoldersIds,
  isWithdrawnAlteredVehicleFieldVisible,
  isVehicleSaleDate,
  hiddenFieldsAddVehicle,
  checkCepikInformationFolderType = false,
  fieldsMode,
  setAddManuallyVisible,
  setCepikData,
  fieldsRequiredByCategory,
  actionKeys
}: Props) {
  const [t] = useTranslation();
  const { showSnackbar, showSuccessSnackbar } = useSnackbar();
  const { getQuery } = useGetApiQueryByPerspective();
  const vinValidator = useValidatorUtil(ValidatorEnums.VIN);
  const plateNumberValidator = useValidatorUtil(ValidatorEnums.VEHICLE_PLATE_NUMBER);
  const previousBrandKey = useRef<string>(null);

  const { getEuroClassByYear } = useTranslateEuroClass();
  const [isVinValid, setIsVinValid] = useState<boolean>(true);
  const [isPlateNumberValid, setIsPlateNumberValid] = useState<boolean>(true);
  const [isVinEnabled, setIsVinEnabled] = useState<boolean>(false);
  const [registrationNumber, setRegistrationNumber] = useState<string>(null);
  const [isCepikDownloading, setIsCepikDownloading] = useState<boolean>(false);

  const { getValues, formMode, setValue, clearErrors, setError } = useFormV2Context();
  const { viewMode, editMode, createMode } = useViewModesV2(formMode);
  const { isOperatorPortal } = useDomainConfigContext();

  const typeKey = useFormV2Watch<SelectOptionPartial<string>>({ name: typedNameV2<VehicleFormData>('typeKey') });
  const brandKey = useFormV2Watch<SelectOptionPartial<string>>({ name: typedNameV2<VehicleFormData>('brandKey') });
  const currentVin = useFormV2Watch<string>({ name: typedNameV2<VehicleFormData>('vin') });
  const currentPlateNumber = useFormV2Watch<string>({ name: typedNameV2<VehicleFormData>('plateNumber') });
  const dmc = useFormV2Watch<string>({ name: typedNameV2<VehicleFormData>('dmc') });
  const belowThreeAndHalfTons = useFormV2Watch<SelectOptionPartial<boolean>>({
    name: typedNameV2<VehicleFormData>('belowThreeAndHalfTons')
  });

  const timelyRightOfDisposal = useFormV2Watch<SelectOptionPartial<boolean>>({
    name: typedNameV2<VehicleFormData>('temporaryRightOfDisposition')
  });
  const addManually = useFormV2Watch<boolean>({ name: typedNameV2<VehicleFormData>('addManually') });

  const folderTypeTpOrKsOrPa = [
    DomainDictionaryEntry.FOLDER_TYPE.TP,
    DomainDictionaryEntry.FOLDER_TYPE.KS,
    DomainDictionaryEntry.FOLDER_TYPE.PA
  ].includes(folderType);

  const folderTypeOpOrPa = [DomainDictionaryEntry.FOLDER_TYPE.OP, DomainDictionaryEntry.FOLDER_TYPE.PA].includes(
    folderType
  );

  useEffect(() => {
    if (!viewMode && currentPlateNumber && !plateNumberValidator[0]?.test(String(currentPlateNumber))) {
      setIsPlateNumberValid(false);
    } else {
      setIsPlateNumberValid(true);
    }
  }, [currentPlateNumber, plateNumberValidator, viewMode]);

  useEffect(() => {
    if (!viewMode && currentVin && !vinValidator[0]?.test(String(currentVin))) {
      setIsVinValid(false);
    } else {
      setIsVinValid(true);
    }
  }, [currentVin, viewMode, vinValidator]);

  useEffect(() => {
    if (folderType !== DomainDictionaryEntry.FOLDER_TYPE.TP || !addManually) {
      const dmcNumber = Number(dmc);
      if (dmcNumber) {
        setValue(typedNameV2<VehicleFormData>('belowThreeAndHalfTons'), { value: dmcNumber <= VEHICLE_3_5T_WEIGHT });
      } else {
        setValue(typedNameV2<VehicleFormData>('belowThreeAndHalfTons'), null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dmc, folderType, addManually]);

  const onlyEditDateOfSale = useMemo(() => {
    return isVehicleSaleDate && editMode;
  }, [editMode, isVehicleSaleDate]);

  const isCepikInformationVisible = useMemo(() => {
    if (viewMode || editMode) {
      return false;
    }

    if (!checkCepikInformationFolderType) {
      return true;
    }

    return folderType === DomainDictionaryEntry.FOLDER_TYPE.KS;
  }, [checkCepikInformationFolderType, folderType, viewMode, editMode]);

  const clearWithdrawnAlteredVehicleOnBelowThreeAndHalfTonsChange = (selectedValue: SelectOption | SelectOption[]) => {
    const withdrawnAlteredVehicleRegistrationNumber = getValues(
      typedNameV2<VehicleFormData>('withdrawnAlteredVehicleRegistrationNumber')
    ) as SelectOptionPartial<{ belowThreeAndHalfTons: boolean }>;
    const newValue = (selectedValue as SelectOption)?.value;
    if (
      isWithdrawnAlteredVehicleFieldVisible &&
      withdrawnAlteredVehicleRegistrationNumber?.value?.belowThreeAndHalfTons !== newValue
    ) {
      setValue(typedNameV2<VehicleFormData>('withdrawnAlteredVehicleRegistrationNumber'), null);
    }
  };

  const typeKeyOptionsFilterOP = (element: SelectOption) => {
    return element.value === DomainDictionaryEntry.VEHICLE_TYPE.BUS;
  };

  const typeKeyOptionsFilterNotPa = (element: SelectOption) => {
    return element.value !== DomainDictionaryEntry.VEHICLE_TYPE.BUS;
  };

  const getTypeKeyFilterFunction = () => {
    if (folderType === DomainDictionaryEntry.FOLDER_TYPE.OP) {
      return typeKeyOptionsFilterOP;
    }
    if (folderType === DomainDictionaryEntry.FOLDER_TYPE.PA) {
      return null;
    }
    return typeKeyOptionsFilterNotPa;
  };

  const BooleanOptions = getBooleanOptions();
  const getIsFieldHidden = (fieldName: string) => {
    return hiddenFieldsAddVehicle?.includes(fieldName);
  };

  const {
    data: cepikData,
    isLoading,
    refetch
  } = useCepikDataQuery(
    { vin: currentVin ? String(currentVin) : null, registrationNumber },
    {
      enabled: isCepikDownloading && (isVinEnabled || registrationNumber),
      retry(failureCount, error) {
        return !(error || failureCount > 0);
      },
      onSettled(data, error) {
        if (!data?.vin && !error) {
          if (!data?.message) {
            showSnackbar('info', t('vehicle:details.message.cepikError'));
          }

          setIsCepikDownloading(false);
          setIsVinEnabled(false);
        }
      },
      onError() {
        setIsCepikDownloading(false);
      }
    }
  );

  const downloadCepikData = async () => {
    setAddManuallyVisible(true);
    const isRegistrationNumber = getValues().plateNumber as string;
    setIsVinEnabled(true);

    if (!currentVin && !isRegistrationNumber) {
      showSnackbar('error', t('vehicle:details.message.vinOrRegistrationNumberRequired'));
      return;
    }

    setIsCepikDownloading(true);
    setRegistrationNumber(getValues().plateNumber);
    if (cepikData) {
      refetch();
    }
  };

  useEffect(() => {
    if (cepikData?.vin) {
      setCepikData(cepikData);
      showSuccessSnackbar(t('vehicle:details.message.cepikSuccess'));
      setIsVinEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cepikData]);

  useEffect(() => {
    if (!brandKey || (previousBrandKey.current !== brandKey?.value && previousBrandKey.current)) {
      setValue(typedNameV2<VehicleFormData>('modelKey'), null);
    }
    previousBrandKey.current = brandKey?.value;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandKey]);

  const getOptionLabel = option => {
    return option.plateNumber;
  };

  const handleChangeManufactureYear = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target?.value;
    const manufactureYearNumber = parseInt(value, 10);
    let newEuroClassValue: EuroClassDetails;
    if (manufactureYearNumber > 1000) {
      newEuroClassValue = getEuroClassByYear(manufactureYearNumber);
    }
    if (newEuroClassValue) {
      setValue(typedNameV2<VehicleFormData>('euroClassKey'), { value: newEuroClassValue?.euroClassKey });

      if (!isOperatorPortal) {
        clearErrors(typedNameV2<VehicleFormData>('euroClassKey'));
      }
    } else {
      setValue(typedNameV2<VehicleFormData>('euroClassKey'), null);

      if (!isOperatorPortal) {
        setError(typedNameV2<VehicleFormData>('euroClassKey'), {
          message: t('vehicle:details.validation.missingEuroClass')
        });
      }
    }
  };
  const inputMode = onlyEditDateOfSale ? InputMode.VIEW : undefined;

  const getVehicles = useCallback(
    (searchText: string, params: { page: number; size: number }) => {
      const belowThreeAndHalfTonsOption = getValues(
        typedNameV2<VehicleFormData>('belowThreeAndHalfTons')
      ) as SelectOption<boolean>;

      const apiParams: VehiclesSearchFilter | VehiclesSearchFilterClient = {
        ...params,
        plateNumberContains: searchText,
        folderIdIn: [folderId, ...(transferredFoldersIds || [])],
        statusKeyIn: [DomainDictionaryEntry.VEHICLE_STATUS.ACTIVE],
        belowTreeAndHalfTons: belowThreeAndHalfTonsOption?.value ?? null
      };

      const apiMethod = getQuery(
        () => API.client.vehicle.getVehiclesSearchFilter('', '', apiParams),
        () => API.vehicle.getVehiclesSearchFilter(apiParams)
      );

      return apiMethod();
    },
    [folderId, getQuery, getValues, transferredFoldersIds]
  );

  return (
    <Section
      title={t('vehicle:details.section.vehicle')}
      isModalSection
      headerContent={
        !viewMode &&
        isOperatorPortal && (
          <Button
            actionKey={actionKeys?.downloadFromCEPIK}
            variant="outlined"
            onClick={downloadCepikData}
            label={t('vehicle:details.button.downloadCepikData')}
            isLoading={isLoading}
          />
        )
      }
    >
      <GridLayout itemProps={{ xs: 12, sm: 6 }}>
        {isCepikInformationVisible && isOperatorPortal && (
          <GridItem xs={12}>
            <WarningInformation
              icon="InfoIcon"
              content={t('vehicle:details.message.vinOrRegistrationNumberRequired')}
              colorVersion={WarningThemeType.INFORMATIVE}
            />
          </GridItem>
        )}
        {!getIsFieldHidden(VehicleSectionFields.VIN) && (
          <TextInputField
            name={typedNameV2<VehicleFormData>('vin')}
            label={getLabel('vin')}
            inputProps={{ maxLength: 30 }}
            isRequired
            inputMode={inputMode}
            helperText={!isVinValid && t('vehicle:details.message.invalidVin')}
            isHelperTextWarning={!isVinValid}
          />
        )}
        {!getIsFieldHidden(VehicleSectionFields.PLATE) && (
          <TextInputField
            name={typedNameV2<VehicleFormData>('plateNumber')}
            label={getLabel('registrationNumber')}
            inputProps={{ maxLength: 30 }}
            inputMode={inputMode}
            isRequired
            helperText={!isPlateNumberValid && t('vehicle:details.message.invalidPlateNumber')}
            isHelperTextWarning={!isPlateNumberValid}
          />
        )}
        {addManuallyVisible && createMode && isOperatorPortal && (
          <GridItem xs={12} sm={12}>
            <SwitchField name="addManually" label={getLabel('addManually')} />
          </GridItem>
        )}
        {!getIsFieldHidden(VehicleSectionFields.BRAND) &&
          ((editMode || createMode) && inputMode !== InputMode.VIEW ? (
            <QuickChangeableDictionarySelectField
              name={typedNameV2<VehicleFormData>('brandKey')}
              label={getLabel('brandKey')}
              dictionaryName={DomainQuickchangeableDictionaryEnum.VEHICLE_BRAND}
              isRequired
              loadDictionaryOnStart
              isDisabled={!addManually}
              isClearable
              tooltip={!addManually && getLabel('tooltipAddManually')}
              hasSearchByExactValue
            />
          ) : (
            <TextInputField
              name={typedNameV2<VehicleFormData>('brandKeyTranslation')}
              label={getLabel('brandKey')}
              inputMode={inputMode}
            />
          ))}
        {!getIsFieldHidden(VehicleSectionFields.MODEL) &&
          ((editMode || createMode) && inputMode !== InputMode.VIEW ? (
            <QuickChangeableDictionarySelectField
              name={typedNameV2<VehicleFormData>('modelKey')}
              label={getLabel('model')}
              dictionaryName={DomainQuickchangeableDictionaryEnum.VEHICLE_MODEL}
              {...(!viewMode ? { parentKey: brandKey?.value } : {})}
              isDisabled={!brandKey || !addManually}
              isRequired={fieldsRequiredByCategory.model}
              loadDictionaryOnStart
              isClearable
              tooltip={!addManually ? getLabel('tooltipAddManually') : !brandKey && getLabel('tooltipModel')}
            />
          ) : (
            <TextInputField
              name={typedNameV2<VehicleFormData>('modelKeyTranslation')}
              label={getLabel('model')}
              inputMode={inputMode}
            />
          ))}
        {!getIsFieldHidden(VehicleSectionFields.MANUFACTURE_YEAR) && (
          <TextInputField
            name={typedNameV2<VehicleFormData>('manufactureYear')}
            label={getLabel('manufactureYear')}
            inputProps={{ maxLength: 4 }}
            isOnlyPositiveIntegers
            isRequired
            inputMode={inputMode}
            isDisabled={!addManually}
            tooltip={!addManually && getLabel('tooltipAddManually')}
            customHandleChange={handleChangeManufactureYear}
          />
        )}
        {!getIsFieldHidden(VehicleSectionFields.TYPE) && (
          <DictionarySelectField
            name={typedNameV2<VehicleFormData>('typeKey')}
            label={getLabel('typeKey')}
            dictionaryName={DomainDictionaryEnum.VEHICLE_TYPE}
            {...{ optionsFilter: getTypeKeyFilterFunction() }}
            isRequired
            isDisabled={!addManually}
            inputMode={inputMode || (folderType === DomainDictionaryEntry.FOLDER_TYPE.OP && InputMode.VIEW)}
            tooltip={!addManually && getLabel('tooltipAddManually')}
          />
        )}
        {!getIsFieldHidden(VehicleSectionFields.SEATS) && (
          <TextInputField
            name={typedNameV2<VehicleFormData>('seatsNumber')}
            label={getLabel('seatsNumber')}
            inputProps={{ maxLength: 3 }}
            isOnlyPositiveIntegers
            isRequired={
              typeKey?.value === DomainDictionaryEntry.VEHICLE_TYPE.BUS ||
              folderType === DomainDictionaryEntry.FOLDER_TYPE.OP
            }
            isDisabled={!addManually || !folderTypeOpOrPa}
            inputMode={inputMode}
            tooltip={
              !addManually ? getLabel('tooltipAddManually') : !folderTypeOpOrPa && getLabel('tooltipNotOpPaFolderField')
            }
          />
        )}
        {!getIsFieldHidden(VehicleSectionFields.ACCEPTABLE_CAPACITY) && (
          <TextInputField
            name={typedNameV2<VehicleFormData>('acceptableCapacity')}
            label={getLabel('acceptableCapacity')}
            isOnlyPositiveIntegers
            isDisabled={!folderTypeTpOrKsOrPa || !addManually}
            inputMode={inputMode}
            isRequired={
              ![
                DomainDictionaryEntry.VEHICLE_TYPE.BUS,
                DomainDictionaryEntry.VEHICLE_TYPE.CAR,
                DomainDictionaryEntry.VEHICLE_TYPE.TRUCK_TRACTOR
              ].includes(typeKey?.value) && folderTypeTpOrKsOrPa
            }
            tooltip={
              !addManually
                ? getLabel('tooltipAddManually')
                : !folderTypeTpOrKsOrPa && getLabel('tooltipNotTpKsPaFolderField')
            }
          />
        )}
        {!getIsFieldHidden(VehicleSectionFields.DMC) && (
          <TextInputField
            name={typedNameV2<VehicleFormData>('dmc')}
            label={getLabel('dmc')}
            isDisabled={!folderTypeTpOrKsOrPa || !addManually}
            inputMode={inputMode}
            isOnlyPositiveIntegers
            isRequired={folderTypeTpOrKsOrPa && typeKey && typeKey?.value !== DomainDictionaryEntry.VEHICLE_TYPE.BUS}
            tooltip={
              !addManually
                ? getLabel('tooltipAddManually')
                : !folderTypeTpOrKsOrPa && getLabel('tooltipNotTpKsPaFolderField')
            }
          />
        )}
        {!getIsFieldHidden(VehicleSectionFields.EURO_CLASS_KEY) && (
          <DictionarySelectField
            dictionaryName={DomainDictionaryEnum.EURO_CLASS}
            name={typedNameV2<VehicleFormData>('euroClassKey')}
            label={t('vehicle:field.euroClassKey')}
            isDisabled={!addManually}
            inputMode={inputMode || fieldsMode?.euroClassKey}
            tooltip={!addManually && getLabel('tooltipAddManually')}
            isRequired={fieldsRequiredByCategory.euroClassKey}
          />
        )}
        {!getIsFieldHidden(VehicleSectionFields.BELOW_THREE_AND_HALF_TONS) && (
          <AutocompleteSelectField
            name={typedNameV2<VehicleFormData>('belowThreeAndHalfTons')}
            label={getLabel('belowThreeAndHalfTons')}
            options={BooleanOptions}
            isDisabled={folderType !== DomainDictionaryEntry.FOLDER_TYPE.TP || !addManually}
            inputMode={inputMode}
            isRequired={folderType === DomainDictionaryEntry.FOLDER_TYPE.TP}
            tooltip={
              !addManually
                ? getLabel('tooltipAddManually')
                : folderType !== DomainDictionaryEntry.FOLDER_TYPE.TP && getLabel('tooltipNotTpFolderField')
            }
            onChangeCustom={clearWithdrawnAlteredVehicleOnBelowThreeAndHalfTonsChange}
          />
        )}
        {!getIsFieldHidden(VehicleSectionFields.OWNERSHIP_TYPE) && (
          <DictionarySelectField
            name={typedNameV2<VehicleFormData>('ownershipTypeKey')}
            label={getLabel('disposeRightKey')}
            dictionaryName={DomainDictionaryEnum.VEHICLE_OWNERSHIP_TYPE}
            isRequired
            isDisabled={!addManually}
            inputMode={inputMode}
            tooltip={!addManually && getLabel('tooltipAddManually')}
          />
        )}
        {!getIsFieldHidden(VehicleSectionFields.TEMPORARY_RIGHT_OF_DISPOSITION) && (
          <AutocompleteSelectField
            name={typedNameV2<VehicleFormData>('temporaryRightOfDisposition')}
            label={getLabel('timelyRightOfDisposal')}
            isRequired
            options={BooleanOptions}
            isDisabled={!addManually}
            inputMode={inputMode}
            onChangeCustom={option => {
              if (!Array.isArray(option) && !option.value) {
                setValue(typedNameV2<VehicleFormData>('rightOfDispositionDate'), '');
                clearErrors(typedNameV2<VehicleFormData>('rightOfDispositionDate'));
              }
            }}
            tooltip={!addManually && getLabel('tooltipAddManually')}
          />
        )}
        {!getIsFieldHidden(VehicleSectionFields.RIGHT_OF_DISPOSITION_DATE) && (
          <DatepickerField
            name={typedNameV2<VehicleFormData>('rightOfDispositionDate')}
            label={getLabel('rightOfDisposalDate')}
            isRequired={timelyRightOfDisposal?.value}
            isDisabled={!addManually || !timelyRightOfDisposal?.value}
            inputMode={inputMode}
            minDate={moment().add(1, 'day')}
            tooltip={
              (!addManually && getLabel('tooltipAddManually')) ||
              (!timelyRightOfDisposal?.value && getLabel('tooltipRightOfDispositionDate'))
            }
          />
        )}
        {!getIsFieldHidden(VehicleSectionFields.COMPATIBILITY_CERTYFICATE_NUMBER) && (
          <TextInputField
            name={typedNameV2<VehicleFormData>('manufacturerCertificate')}
            label={getLabel('compatibilityCertificateNumber')}
            isDisabled={!addManually}
            inputMode={inputMode}
            tooltip={!addManually && getLabel('tooltipAddManually')}
          />
        )}
        {!getIsFieldHidden(VehicleSectionFields.ENGINE_NUMBER) && (
          <TextInputField
            name={typedNameV2<VehicleFormData>('engineNumber')}
            label={getLabel('engineNumber')}
            isDisabled={!addManually}
            isRequired={fieldsRequiredByCategory.engineNumber}
            inputMode={inputMode}
            tooltip={!addManually && getLabel('tooltipAddManually')}
          />
        )}
        {!getIsFieldHidden(VehicleSectionFields.ENGINE_TYPE) && (
          <TextInputField
            name={typedNameV2<VehicleFormData>('engineType')}
            label={getLabel('engineType')}
            isDisabled={!addManually}
            isRequired={fieldsRequiredByCategory.engineType}
            inputMode={inputMode}
            tooltip={!addManually && getLabel('tooltipAddManually')}
          />
        )}
        {!getIsFieldHidden(VehicleSectionFields.FIRST_REGISTRATION_DATE) && (
          <DatepickerField
            name={typedNameV2<VehicleFormData>('firstRegistrationDate')}
            label={getLabel('firstRegistrationDate')}
            isDisabled={!addManually}
            inputMode={inputMode}
            isRequired={fieldsRequiredByCategory.firstRegistrationDate}
            tooltip={!addManually && getLabel('tooltipAddManually')}
          />
        )}
        {isWithdrawnAlteredVehicleFieldVisible && (
          <AutocompleteLazyFetchSelectField
            name={typedNameV2<VehicleFormData>('withdrawnAlteredVehicleRegistrationNumber')}
            queryKeyParams={{ belowThreeAndHalfTons: belowThreeAndHalfTons?.value ?? null }}
            queryKey={VehicleQueryKeysEnum.VEHICLE_LIST}
            optionLabelParser={getOptionLabel}
            api={{ FETCH: getVehicles }}
            label={getLabel('withdrawnAlteredVehicleRegistrationNumber') as KeyType}
            isDisabled={!addManually}
            inputMode={inputMode}
            tooltip={!addManually && getLabel('tooltipAddManually')}
          />
        )}
        {isVehicleSaleDate && !createMode && (
          <DatepickerField
            name={typedNameV2<VehicleFormData>('dateOfSale')}
            label={getLabel('dateOfSale') as KeyType}
            maxDate={moment().add(12, 'M')}
            tooltip={!addManually && getLabel('tooltipAddManually')}
            isRequired={fieldsRequiredByCategory.dateOfSale}
          />
        )}
      </GridLayout>
    </Section>
  );
}

export default React.memo(VehicleSection);
