import { DomainDictionaryEntry } from '@libs/domain/config';

const documentTemplateGroupByProcess = {
  [DomainDictionaryEntry.APPLICATION_TYPE.PB1A]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR1A],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB1B]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR1B],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB2A]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR9],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB2B]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR9],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB3A]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR8],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB3B]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR11],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB4A]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR4A],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB4B]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR4B],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB4C]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR2],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB4D]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR2],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB4E]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR2],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB5]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR3],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB6A]: [
    DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR5A,
    DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR13A
  ],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB6B]: [
    DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR5B,
    DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR13B
  ],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB7A]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR6A],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB7B]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR6B],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB8A]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR7A],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB8B]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR7B],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB9A]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR12A],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB9B]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR12B],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB10A]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.OB],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB10B]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LR10],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB11A]: [
    DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.IZDP,
    DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.pismoA
  ],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB11B]: [
    DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.IZDP,
    DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.pismoA
  ],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB11C]: [
    DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.IZDP,
    DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.pismoA
  ],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB11D]: [
    DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.IZDP,
    DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.pismoA
  ],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB12]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.IZPL],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB14]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.sprA],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB26]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LPO],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB27]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LPO8],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB28]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LPO7],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB29A]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LPO5],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB29B]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LPO5],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB30]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LPO6],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB31]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LPO3],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB32A]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LPO2],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB32B]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.LPO4],
  [DomainDictionaryEntry.APPLICATION_TYPE.PB33]: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.sprB]
};

export default documentTemplateGroupByProcess;
