import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

async function deleteTransportManagerClient(id: string) {
  const { data } = await API.client.transportManager.deleteTransportManager(id, '', '', {
    ...endpointsConfig.deleteTransportManager
  });
  return data;
}

async function deleteTransportManager(id: string) {
  const { data } = await API.transportManager.deleteTransportManager(id, {
    ...endpointsConfig.deleteTransportManager
  });
  return data;
}

function useDeleteTransportManagerMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(deleteTransportManagerClient, deleteTransportManager));
}

export default useDeleteTransportManagerMutation;
