import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function deletePermission({ id }: { id: string }) {
  return API.permissions.deletePermission(id, {
    ...endpointsConfig.deletePermission
  });
}

function useDeletePermissionMutation() {
  return useMutation(deletePermission);
}

export default useDeletePermissionMutation;
