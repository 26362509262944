enum AdditionalSectionLicenseOrPermissionType {
  LICENSE = 'LICENSE',
  PERMISSION = 'PERMISSION',
  DRIVER_CERTIFICATE = 'DRIVER_CERTIFICATE',
  PERMISSION_IF_NOT_PPO = 'PERMISSION_IF_NOT_PPO'
}

export enum AdditionalSectionLicenseOrPermissionShortType {
  LICENSE = 'L',
  PERMISSION = 'Z'
}

export default AdditionalSectionLicenseOrPermissionType;
