import { TableEvent } from '../context';

import { useSubscribeTableEvent } from './useSubscribeTableEvent';

/**
 * @param handleClearFilter funkcja obsługująca czyszczenie filtra powinna być w useCallback
 */
export const useHandleClearFilters = (handleClearFilter: () => void) => {
  useSubscribeTableEvent({ event: TableEvent.ON_FILTER_CLEAR, onEvent: handleClearFilter });
};
