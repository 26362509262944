import { useTranslation } from 'react-i18next';
import { PartnerApplicantDetails, PartnerApplicantSearchFilter } from '@ibtm/domain';

import { ColumnTypesEnum, SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';

import { DictionaryValue } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

const usePartnerApplicantsColumns = () => {
  const [t] = useTranslation();
  const { createColumns } = useCreateColumns<PartnerApplicantDetails>({
    pathToTranslate: 'partnerApplicants:field'
  });

  const mappedSortFields: TableSortMapper<PartnerApplicantDetails> = {
    subjectName: 'subjectName',
    roleKey: 'roleKey'
  };

  const mappedFilterFields: TableFilterMapper<PartnerApplicantDetails, PartnerApplicantSearchFilter> = {
    subjectName: (subjectName: string) => ({ subjectName }),
    roleKey: (role: SelectOption<string>) => ({ roleKey: role?.value }),
    lp: (lp: number) => ({ lp })
  };

  const columns = createColumns([
    {
      accessor: 'lp',
      type: 'NUMBER'
    },
    {
      id: 'folderNumber',
      type: 'TEXT',
      isSortable: false,
      isFilterable: false
    },
    {
      id: 'roleKey',
      type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
      dictionaryName: DomainDictionaryEnum.PARTNER_APPLICANT,
      customAccessor: row => (
        <DictionaryValue dictionaryEntryType={DomainDictionaryEnum.PARTNER_APPLICANT} value={row.roleKey} />
      )
    },
    {
      id: 'validCopy',
      type: 'BOOLEAN',
      tooltipTitle: ({ validCopy }) => (validCopy ? t('action.yes') : t('action.no')),
      isSortable: false,
      isFilterable: false
    },
    {
      id: 'foreignLicence',
      type: 'BOOLEAN',
      tooltipTitle: ({ foreignLicence }) => (foreignLicence ? t('action.yes') : t('action.no')),
      isSortable: false,
      isFilterable: false
    },
    {
      id: 'subjectName',
      type: 'TEXT'
    },
    {
      id: 'correspondenceAddress',
      header: 'partnerApplicants:field.mainAddress',
      type: 'BOOLEAN',
      tooltipTitle: ({ correspondenceAddress }) => (correspondenceAddress ? t('action.yes') : t('action.no')),
      isSortable: false,
      isFilterable: false
    }
  ]);

  return { columns, mappedFilterFields, mappedSortFields };
};

export default usePartnerApplicantsColumns;
