import { useRef } from 'react';

function useCorrectionForm() {
  const correctionFormRef = useRef(null);

  const submitCorrectionForm = () => {
    if (correctionFormRef) {
      correctionFormRef.current?.submit();
    }
  };

  return { correctionFormRef, submitCorrectionForm };
}

export default useCorrectionForm;
