import { useTranslation } from 'react-i18next';

import { ITableLayoutColumn } from '@libs/common/v2';

export interface ICorrectionItemsView {
  name: string;
  quantity: number;
  unitCost: number;
}

export default function useDocumentTableItemsColumns(): ITableLayoutColumn<ICorrectionItemsView>[] {
  const [t] = useTranslation();

  const getLabel = (name: string): string => {
    return t<any>(`releaseDocuments:fields.fields.${name}`);
  };
  return [
    {
      id: 'name',
      Header: getLabel('permissionName'),
      accessor: row => row.name,
      canFilter: false,
      canSort: false,
      width: 400
    },
    {
      id: 'quantity',
      Header: getLabel('issuedPermissionsNumber'),
      accessor: row => row?.quantity,
      canFilter: false,
      canSort: false,
      width: 150
    },
    {
      id: 'unitCost',
      Header: getLabel('unitCost'),
      accessor: row => row?.unitCost,
      canFilter: false,
      canSort: false,
      width: 150
    },
    {
      id: 'sum',
      Header: getLabel('sum'),
      accessor: row => (row?.quantity || 0) * (row?.unitCost || 0),
      canFilter: false,
      canSort: false,
      width: 150
    }
  ];
}
