import { MisspellApplicationDataDriverCertificateDetails } from '@ibtm/domain';

import { useTableLayoutProps } from '@libs/common/v2';

import { useMisspellApplicationDriverCertificateColumn } from './useMisspellApplicationDriverCertificateColumn';

export function useMisspellApplicationDriverCertificateTable(data: MisspellApplicationDataDriverCertificateDetails[]) {
  const { columns } = useMisspellApplicationDriverCertificateColumn();

  return useTableLayoutProps<
    MisspellApplicationDataDriverCertificateDetails,
    MisspellApplicationDataDriverCertificateDetails
  >({
    tableQuery: data,
    tableHookOptions: {
      columns
    }
  });
}
