import { usePaginatedQuery } from 'react-query';
import { SCertificatesSearchFilter as SCertificatesSearchFilterClient } from '@ibtm/client-domain';
import { SCertificatesSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { SCertificatesSnapshot } from '../../model';
import { parseSCertificateList } from '../../utils';
import { endpointsConfig } from '../endpoints-config';

import { SCertificatesKeysEnum } from './SCertificatesQueryKeysEnum';

const getSCertificates = async (_, params: SCertificatesSearchFilter) => {
  const { data } = await API.sCertificates.getSCertificatesSnapshotPage(params, {
    ...endpointsConfig.getSCertificatesSnapshotPage
  });
  return { ...data, content: parseSCertificateList(data.content) };
};

const getSCertificatesClient = async (_, params: SCertificatesSearchFilterClient) => {
  const { data } = await API.client.sCertificates.getSCertificatesSnapshotPage('', '', params, {
    ...endpointsConfig.getSCertificatesSnapshotPage
  });
  return { ...data, content: parseSCertificateList(data.content) };
};

const useSCertificatesQuery = (
  params: SCertificatesSearchFilter | SCertificatesSearchFilterClient,
  queryConfig = {}
) => {
  const { getQuery } = useGetApiQueryByPerspective();
  return usePaginatedQuery<IPaginatedModel<SCertificatesSnapshot>>(
    [SCertificatesKeysEnum.CERTIFICATES_S_LIST, params],
    getQuery(getSCertificatesClient, getSCertificates),
    queryConfig
  );
};
export default useSCertificatesQuery;
