import React, { ReactNode, useEffect } from 'react';
import { Tabs as TabsMui } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

import { Theme } from '@libs/common/v2/theme';

import { useElementVisibility } from '@libs/permission';

interface ITabProps<T> {
  value: T;
  isConditionalVisibility?: boolean;
}

interface IProps<T> {
  onChange: (value: T) => void;
  children: React.ReactElement<any, any>[] | ReactNode;
  className?: string;
  width?: number;
  value?: T;
  orientation?: 'vertical' | 'horizontal';
  variant?: 'fullWidth' | 'scrollable' | 'standard';
  isTabIndicatorVisible?: boolean;
}

function Tabs<T>({
  onChange,
  children,
  value,
  className,
  width = 280,
  orientation = 'vertical',
  variant = 'scrollable',
  isTabIndicatorVisible = false
}: IProps<T>) {
  const classes = useMuiTabsStyles({ width, orientation });
  const { checkIsElementVisible } = useElementVisibility();

  const handleChange = (_event: React.ChangeEvent<unknown>, value: T) => {
    onChange(value);
  };

  const activeChildren = [];
  React.Children.forEach(_.compact(React.Children.toArray(children)), child => {
    if (React.isValidElement(child)) {
      if (
        (!Object.hasOwnProperty.call(child.props, 'isConditionalVisibility') || child.props?.isConditionalVisibility) &&
        (!Object.hasOwnProperty.call(child.props, 'viewName') ||
          !child.props?.viewName ||
          (child.props?.viewName && checkIsElementVisible(child.props?.viewName)))
      ) {
        activeChildren.push(React.cloneElement(child as React.ReactElement));
      }
    }
  });

  const childrenWithProps = React.Children.map(_.compact(activeChildren), (child: React.ReactNode) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, child.props);
    }
    return null;
  });

  useEffect(() => {
    if (
      value &&
      childrenWithProps?.length &&
      !childrenWithProps.find((child: React.ReactElement) => child.props.value === value)
    ) {
      onChange((childrenWithProps[0].props as ITabProps<T>)?.value);
    }
  }, [value, childrenWithProps, onChange]);

  return (
    <TabsMui
      variant={variant}
      classes={classes}
      className={className}
      orientation={orientation}
      value={value}
      visibleScrollbar
      scrollButtons={false}
      onChange={handleChange}
      TabIndicatorProps={!isTabIndicatorVisible && { style: { display: 'none' } }}
    >
      {childrenWithProps}
    </TabsMui>
  );
}

const useMuiTabsStyles = makeStyles<Theme, { width?: number; orientation?: 'vertical' | 'horizontal' }>(
  (theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.grey[50],
      width: ({ width, orientation }) => (orientation === 'vertical' ? width : '100%'),
      height: ({ orientation }) => (orientation === 'horizontal' ? 'auto' : '100%'),
      '& .MuiTab-root[aria-selected=false]:hover': {
        backgroundColor: theme.palette.contrastIndicators.hover
      },
      '& .MuiTab-root[aria-selected=true]:hover': {
        backgroundColor: theme.palette.blue['50']
      }
    },
    flexContainer: {
      height: '100%'
    },
    indicator: {
      backgroundColor: ({ orientation }) => (orientation === 'horizontal' ? theme.palette.blue[800] : 'unset')
    }
  })
);

export default Tabs;
