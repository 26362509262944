import { useSingleSelectColumn, useTableLayoutProps } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';
import { FolderQueryKeysEnum, useFolderColumns, useFoldersListQuery } from '@libs/domain/folder';

function useSelectAssociationTable() {
  const { columns } = useFolderColumns(['subjectName', 'number']);

  return useTableLayoutProps({
    tableHookQuery: useFoldersListQuery,
    tableHookOptions: {
      columns,
      tablePlugins: [useSingleSelectColumn],
      tableOptions: {
        initialState: {
          pageSize: 5
        }
      }
    },
    tableHookQueryInitialParams: { typeKeyIn: [DomainDictionaryEntry.FOLDER_TYPE.ZZ], includeDeleted: false },
    tableHookQueryKey: FolderQueryKeysEnum.ASSOCIATION_LIST
  });
}

export default useSelectAssociationTable;
