import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Dialog, FormV2Context } from '@libs/common/v2';

import { ArchiveInitialFormValuesTypes, ArchiveWarningForm } from '../../../common';
import { useCreateArchiveFolderNoteMutation } from '../../api';

interface IFolderWarningDialog {
  id: string;
  closeDialog: () => void;
  refetch: () => void;
  version?: number;
  note?: string;
}

const FolderWarningDialog = ({ id, closeDialog, refetch, version, note }: IFolderWarningDialog) => {
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();

  const { mutateAsync: createArchiveFolderNote, isLoading } = useCreateArchiveFolderNoteMutation();

  const validationSchema = Yup.object({
    warning: Yup.string().max(100, t('archive:maxMessage')).nullable().required()
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    watch,
    formState: { errors, isSubmitting }
  } = useForm<Partial<ArchiveInitialFormValuesTypes>>({
    defaultValues: { warning: note },
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    criteriaMode: 'all'
  });

  const values = useMemo(
    () => ({
      control,
      setValue,
      watch,
      errors,
      isSubmitting,
      getValues,
      handleSubmit,
      reset
    }),
    [control, errors, getValues, handleSubmit, isSubmitting, reset, setValue, watch]
  );

  return (
    <FormV2Context.Provider value={values}>
      <Dialog
        isOpen
        onConfirm={handleSubmit(async ({ warning }) => {
          await createArchiveFolderNote(
            {
              id,
              note: warning,
              version
            },
            {
              onSuccess: () => {
                closeDialog();
                refetch();
                showSnackbar('success', t('archive:folder.message.warningAdded'));
              }
            }
          );
        })}
        confirmText={t('action.save')}
        title={t('archive:addWarning')}
        cancelText={t('action.cancel')}
        onCancel={closeDialog}
        onClose={closeDialog}
        isConfirmLoading={isLoading}
      >
        <ArchiveWarningForm />
      </Dialog>
    </FormV2Context.Provider>
  );
};

export default FolderWarningDialog;
