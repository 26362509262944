import { TransportManagerUpdateRequestAllOf as TransportManagerUpdateClient } from '@ibtm/client-domain';
import { TransportManagerLite, TransportManagerUpdateRequestAllOf, VersionedRequest } from '@ibtm/domain';

export interface TransportManagerListItemClient {
  id?: string;
  version?: number;
  certificate?: {
    id?: string;
    number?: string;
    validFrom?: string;
    validTo?: string;
  };
  reputationKey?: string;
  periodOfValidity?: string;
  managementPeriodFrom?: string;
  managementPeriodTo?: string;
  deleted?: boolean;
  typeKey?: string;
  name?: string;
  surname?: string;
  statusKey?: string;
  modified?: string;
  modifier?: {
    id?: string;
    name?: string;
  };
}

export interface TransportManagerDetailsClient {
  id?: string;
  personalData?: {
    name: string;
    surname: string;
    birthDate: string;
    birthPlace: string;
    version: number;
  };
  linkedTransportManager?: TransportManagerLite;
  cancelledTransportManager?: TransportManagerLite;
  address?: {
    countryCode?: string;
    postCode: string;
    postCity: string;
    city: string;
    street: string;
    propertyNumber: string;
    apartmentNumber?: string;
    county?: string;
    voivodeship: string;
    commune?: string;
    version?: number;
    addressTypeKey?: string;
  };
  typeKey?: string;
  statusKey?: string;
  managementPeriodFrom?: string;
  managementPeriodTo?: string;
  expirationDate?: string;
  certificate?: {
    id?: string;
    typeKey?: string;
    dateOfIssue?: string;
    issuingAuthority?: string;
    issuingCountryKey?: string;
    number?: string;
    validFrom?: string;
    validTo?: string;
    // Braki danych
    isValidIndefinitely?: boolean;
  };
  convicted?: boolean;
  art7c?: boolean;
  applicationId?: string;
  created?: string;
  author?: {
    id?: string;
    name?: string;
  };
  modified?: string;
  modifier?: {
    id?: string;
    name?: string;
  };
  reputationInquires?: Array<{
    id?: string;
    inquiryDate?: string;
    inquiredBy?: {
      id?: string;
      name?: string;
    };
    aggregatedStatusKey?: string;
    reputationStatusKey?: string;
  }>;
  numberOtherSubjectsRegisteredAsManager?: number;
  numberOtherSubjectsAppointedAsManager?: number;
  numberOtherSubjectVehiclesRegisteredAsManager?: number;
  numberOtherSubjectVehiclesAppointedAsManager?: number;
  version: number;
}

export type TransportManagerDetailsClientFormData = Omit<
  TransportManagerDetailsClient,
  'personalData' | 'certificate' | 'address' | 'version'
> & {
  personalData?: Omit<TransportManagerDetailsClient['personalData'], 'birthDate' | 'version'> & { birthDate?: Date };
  certificate?: Omit<TransportManagerDetailsClient['certificate'], 'dateOfIssue' | 'validFrom' | 'validTo'> & {
    dateOfIssue?: Date;
    validFrom?: Date;
    validTo?: Date;
  };
  address?: Partial<TransportManagerDetailsClient['address']>;
};

export enum TransportManagersColumnsEnum {
  NAME = 'name',
  SURNAME = 'surname',
  CERTIFICATE_NUMBER = 'certificateNumber',
  PERIOD_OF_VALIDITY = 'periodOfValidity',
  REPUTATION_KEY = 'reputationKey',
  MANAGEMENT_PERIOD = 'managementPeriodFrom',
  TYPE_KEY = 'typeKey',
  STATUS_KEY = 'statusKey',
  MODIFIED = 'modified',
  MODIFIER = 'modifierName'
}

export enum TransportManagersActionButtonsEnum {
  PREVIEW = 'PREVIEW',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  ADD = 'ADD',
  EXPIRE = 'EXPIRE',
  RESTORE = 'RESTORE',
  SEARCH = 'SEARCH',
  SHOW_REPUTATION = 'SHOW_REPUTATION',
  CHECK_REPUTATION = 'CHECK_REPUTATION'
}

export interface TransportManagerUpdateRequestPartial extends TransportManagerUpdateRequestAllOf {
  personalData: TransportManagerUpdateRequestAllOf['personalData'] & VersionedRequest;
}

export type TransportManagerUpdateRequestCustom = TransportManagerUpdateRequestPartial &
  VersionedRequest &
  Partial<
    Pick<
      TransportManagerUpdateClient,
      | 'numberOtherSubjectVehiclesRegisteredAsManager'
      | 'numberOtherSubjectVehiclesAppointedAsManager'
      | 'numberOtherSubjectsRegisteredAsManager'
      | 'numberOtherSubjectsAppointedAsManager'
    >
  >;

export enum TransportManagerBlockedByLimitsError {
  TRANSPORT_MANAGER_BLOCKED_BY_LIMITS = 'TRANSPORT_MANAGER_BLOCKED_BY_LIMITS'
}
