import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ResourceObjectExternalReleaseCreateRequest } from '@ibtm/domain';
import * as Yup from 'yup';

import { partialTranslate } from '@libs/common';
import {
  AutocompleteLazyFetchSelectField,
  Dialog,
  DictionarySelectField,
  FormV2Context,
  GridLayout,
  NumberInputField,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { WarningInformation } from '@libs/common/v2/components/warning';

import { useContextSelector, useDictionaryTranslations } from '@libs/dictionary';

import { API } from '@libs/domain/api';
import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import {
  getResourceObjectSinglePoolAvailability,
  ResourceQueryKeysEnum,
  useExternalReleaseMutation,
  useResourceObjectPoolsAvailabilityQuery
} from '../../../api';
import { ExternalReleaseFields, IExternalReleaseFormFields } from '../../../model';
import { handlePoolsChange } from '../../../utils';

const getLabel = partialTranslate('resource:fields');
const initialValues: ExternalReleaseFields = {
  formType: null,
  numberFrom: null,
  numberTo: null,
  amount: null,
  releaseType: null,
  address: ''
};

interface IProps {
  depotId: string;
  closeDialog: () => void;
}

function ExternalReleaseForm({ depotId, closeDialog }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();
  const { showSnackbar } = useSnackbar();
  const { mutate: externalRelease, isLoading } = useExternalReleaseMutation();

  const [isSinglePoolInvalid, setIsSinglePoolInvalid] = useState(false);
  const [isSinglePoolChecking, setIsSinglePoolChecking] = useState(false);

  const dictionaryData = useContextSelector(({ context }) => context.context.data.dictionary);
  const resourceFormNameDictionaryEntries = useMemo(() => {
    return dictionaryData[DomainDictionaryEnum.RESOURCE_FORM_NAME]?.entries || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    reset,
    clearErrors
  } = useForm<Partial<ExternalReleaseFields>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        formType: Yup.object().nullable().required(),
        numberFrom: Yup.number()
          .transform(value => (Number.isNaN(value) ? undefined : value))
          .required(),
        numberTo: Yup.number()
          .transform(value => (Number.isNaN(value) ? undefined : value))
          .required(),
        amount: Yup.number()
          .transform(value => (Number.isNaN(value) ? undefined : value))
          .required(),
        releaseType: Yup.object().nullable().required(),
        address: Yup.string().required()
      }).test('is-range-valid', '', function checkIsRangeValid(item) {
        if (!poolsAvailability?.single) {
          return true;
        }

        if (item.numberFrom < poolsAvailability?.numberFrom || item.numberFrom > poolsAvailability?.numberTo) {
          // eslint-disable-next-line react/no-this-in-sfc
          return this.createError({
            path: 'numberFrom',
            message: t('resource:messages.rangeValidatorMessage', {
              numberFrom: poolsAvailability?.numberFrom,
              numberTo: poolsAvailability?.numberTo
            })
          });
        }

        if (item.numberTo > poolsAvailability?.numberTo || item.numberTo < poolsAvailability?.numberFrom) {
          // eslint-disable-next-line react/no-this-in-sfc
          return this.createError({
            path: 'numberTo',
            message: t('resource:messages.rangeValidatorMessage', {
              numberFrom: poolsAvailability?.numberFrom,
              numberTo: poolsAvailability?.numberTo
            })
          });
        }

        return true;
      })
    ),
    defaultValues: initialValues
  });

  const formTypeWatcher = watch('formType');

  const { data: poolsAvailability } = useResourceObjectPoolsAvailabilityQuery(
    { depotId, resourceTypeId: formTypeWatcher?.id },
    { enabled: !!formTypeWatcher?.id }
  );

  useEffect(() => {
    const values = getValues();
    setIsSinglePoolInvalid(false);
    clearErrors();

    if (poolsAvailability) {
      reset({
        ...values,
        ...poolsAvailability
      });
    } else {
      reset({
        ...values,
        numberFrom: undefined,
        numberTo: undefined,
        amount: undefined
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolsAvailability]);

  const onSubmit = async (formData: Partial<ExternalReleaseFields>) => {
    setIsSinglePoolChecking(true);

    const requestData: ResourceObjectExternalReleaseCreateRequest = {
      resourceTypeId: formData.formType.id,
      numberFrom: formData.numberFrom,
      numberTo: formData.numberTo,
      amount: formData.amount,
      releaseTypeKey: formData.releaseType.value,
      address: formData.address
    };

    const singlePoolsAvailability = await getResourceObjectSinglePoolAvailability({
      depotId,
      numberFrom: requestData.numberFrom,
      numberTo: requestData.numberTo,
      amount: requestData.amount,
      resourceTypeId: requestData.resourceTypeId
    });

    setIsSinglePoolChecking(false);

    if (singlePoolsAvailability.isValid) {
      externalRelease(requestData, {
        onSuccess: () => {
          showSnackbar('success', t('resource:messages.externalReleaseSuccess'));
          queryCache.invalidateQueries(ResourceQueryKeysEnum.RESOURCE_OBJECT_POOLS);
          closeDialog();
        }
      });
    } else {
      setIsSinglePoolInvalid(true);
    }
  };

  const onNumberFieldsChange = (event: React.ChangeEvent<HTMLInputElement>, name) => {
    const { numberFrom, numberTo, amount } = getValues();
    const { value } = event.target;

    handlePoolsChange[name](value, { numberFrom, numberTo, amount }, null, setValue);
  };

  const getOptionLabel = option => {
    return translate(DomainDictionaryEnum.RESOURCE_FORM_NAME, option.nameKey);
  };

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('resource:dialog.externalReleaseDialogTitle')}
      confirmText={t('resource:actions.confirmExternalRelease')}
      cancelText={t('action.cancel')}
      onConfirm={() => handleSubmit(data => onSubmit(data))()}
      onCancel={closeDialog}
      isConfirmLoading={isLoading || isSinglePoolChecking}
      dialogSize="small"
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <GridLayout itemProps={{ xs: 12 }}>
          <AutocompleteLazyFetchSelectField
            name={typedNameV2<IExternalReleaseFormFields>('formType')}
            queryKey={ResourceQueryKeysEnum.RESOURCE_TYPES_FIELD}
            optionLabelParser={getOptionLabel}
            isRequired
            api={{
              FETCH: (searchText: string, params: { page: number; size: number }) =>
                API.resourceTypes.getAvailableResourceTypes({
                  ...params,
                  depotIdIn: [depotId],
                  groupKeyIn: [DomainDictionaryEntry.RESOURCE_FORM_GROUP.POLISH_FOREIGN_PERMIT],
                  nameKeyIn: searchText
                    ? resourceFormNameDictionaryEntries
                        .filter(entry => entry.name.toLowerCase().includes(searchText.toLowerCase()))
                        .map(entry => entry.value)
                    : []
                })
            }}
            label={getLabel('formType')}
          />

          {isSinglePoolInvalid && <WarningInformation content={t('resource:messages.invalidRange')} />}

          <NumberInputField
            name={typedNameV2<IExternalReleaseFormFields>('numberFrom')}
            label={getLabel('numberFrom')}
            inputProps={{
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => onNumberFieldsChange(event, 'numberFrom')
            }}
            min={0}
            isClearable
            isRequired
          />

          <NumberInputField
            name={typedNameV2<IExternalReleaseFormFields>('numberTo')}
            label={getLabel('numberTo')}
            inputProps={{
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => onNumberFieldsChange(event, 'numberTo')
            }}
            min={0}
            isClearable
            isRequired
          />

          <NumberInputField
            name={typedNameV2<IExternalReleaseFormFields>('amount')}
            label={getLabel('amount')}
            inputProps={{
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => onNumberFieldsChange(event, 'amount')
            }}
            min={1}
            isClearable
            isRequired
          />

          <DictionarySelectField
            name={typedNameV2<IExternalReleaseFormFields>('releaseType')}
            label={getLabel('releaseType')}
            dictionaryName={DomainDictionaryEnum.POLISH_RECIPIENTS_DELIVERY_METHOD}
            isRequired
          />

          <TextInputField
            name={typedNameV2<IExternalReleaseFormFields>('address')}
            label={getLabel('address')}
            isRequired
          />
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default ExternalReleaseForm;
