import { useRowSelect } from 'react-table';

import { useMultipleSelectColumn, useTableLayoutProps } from '@libs/common/v2';

import { useMyMessagesQuery } from '@libs/notification/api';
import { MyMessageTableSelectAction } from '@libs/notification/components';
import { useMyMessagesColumns } from '@libs/notification/hooks';

function useMyMessagesTable() {
  const { columns } = useMyMessagesColumns();

  return useTableLayoutProps({
    tableHookQuery: useMyMessagesQuery,
    tableHookOptions: {
      columns,
      tablePlugins: [useRowSelect, useMultipleSelectColumn(MyMessageTableSelectAction)],
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'created', desc: true }]
        }
      }
    },
    tableHookQueryInitialParams: {
      body: {
        deleted: false
      }
    }
  });
}

export default useMyMessagesTable;
