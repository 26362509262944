import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupSnapshotExternal } from '@avispon/group/dist/models';

import { TableLayout, useFormV2Context } from '@libs/common/v2';

import { UserGroupCreateFormHeaderActions, UserGroupCreateFormRowActions } from '@libs/user/components';
import { useUserCreatePermissionsGroupTable } from '@libs/user/hooks';

import { UserGroupSnapshot } from '../../hooks/useUserCreatePermissionsGroupTable';

import AddUserToGroupModal from './AddUserToGroupModal';

interface UserGroupCreateFormProps {
  canAddGroup: boolean;
}

function UserGroupCreateForm({ canAddGroup }: UserGroupCreateFormProps) {
  const [t] = useTranslation();
  const { watch, setValue } = useFormV2Context();
  const [isModalOpen, setModalIsOpen] = useState<boolean>(false);
  const values = watch('groups');

  const tableProps = useUserCreatePermissionsGroupTable(values || {});

  const handleAddUserToGroup = useCallback(
    (modalValues, selection) =>
      new Promise<void>(resolve => {
        const groups = selection.map((group: GroupSnapshotExternal) => ({
          ...group,
          groupId: group.id,
          includeSubordinates: modalValues.includeSubordinates
        }));

        setValue('groups', values?.concat(groups) ?? groups);
        resolve();
        setModalIsOpen(false);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values]
  );

  return (
    <>
      <TableLayout<GroupSnapshotExternal, UserGroupSnapshot>
        {...tableProps}
        pageTitle={t('user:table.permissionsGroup')}
        headerActions={<UserGroupCreateFormHeaderActions setModalIsOpen={setModalIsOpen} canAddGroup={canAddGroup} />}
        rowActions={({ original }) => <UserGroupCreateFormRowActions original={original} />}
        isSection
      />
      {isModalOpen && (
        <AddUserToGroupModal
          open={isModalOpen}
          setIsOpen={setModalIsOpen}
          onSave={handleAddUserToGroup}
          selectedGroups={values}
          hideUnitSelect
        />
      )}
    </>
  );
}

export default UserGroupCreateForm;
