import { SingleUseSubmissionItemSnapshot } from '@ibtm/domain';

import { CamelCasePath, ColumnTypesEnum, useCreateColumns } from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';

export const useSingleUseSubmissionItemsColumns = (
  visibleColumns: Array<CamelCasePath<SingleUseSubmissionItemSnapshot>>
) => {
  const { createColumns } = useCreateColumns<SingleUseSubmissionItemSnapshot>({
    pathToTranslate: 'foreignPermits:tabs.socialCommission.tabs.socialCommissionDisposablePermits.fields'
  });

  const columns = createColumns(
    [
      {
        id: 'resourceFormNameKey',
        type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
        dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_NAME,
        isSortable: false,
        width: 300
      },
      {
        id: 'receiptDateFrom',
        type: ColumnTypesEnum.DATE,
        width: 150,
        isSortable: false
      },
      {
        id: 'receiptDateTo',
        type: ColumnTypesEnum.DATE,
        width: 150,
        isSortable: false
      },
      {
        id: 'quantity',
        type: ColumnTypesEnum.TEXT,
        isSortable: false
      },
      {
        id: 'limit',
        type: ColumnTypesEnum.TEXT,
        isSortable: false
      },
      {
        id: 'used',
        type: ColumnTypesEnum.TEXT,
        isSortable: false
      },
      {
        id: 'returned',
        type: ColumnTypesEnum.TEXT,
        isSortable: false
      },
      {
        id: 'shouldReturn',
        type: ColumnTypesEnum.TEXT,
        isSortable: false
      },
      {
        id: 'decision',
        type: ColumnTypesEnum.TEXT,
        isSortable: false
      },
      {
        id: 'vehicles',
        type: ColumnTypesEnum.TEXT,
        isSortable: false
      },
      {
        id: 'notReturned',
        type: ColumnTypesEnum.TEXT,
        isSortable: false
      }
    ],
    visibleColumns
  );

  return { columns };
};
