import { StartApplicationRequest as StartApplicationRequestClient } from '@ibtm/client-domain';
import { StartApplicationRequest } from '@ibtm/domain';

import { useMutation, useQueryCache } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';
import { ApplicationQueryKeysEnum } from '../../queries';

function createProcess(formData: StartApplicationRequest) {
  return API.applicationDefinitions.startApplication(formData, {
    ...endpointsConfig.startApplication
  });
}
function createProcessClient(formData: StartApplicationRequestClient) {
  return API.client.application.startApplicationDraft(
    '',
    '',
    {
      applicationDefinitionId: formData.applicationDefinitionId
    },
    {
      ...endpointsConfig.startApplication
    }
  );
}

function useStartApplicationProcessMutation() {
  const queryCache = useQueryCache();
  const { getQuery } = useGetApiQueryByPerspective();

  return useMutation(getQuery(createProcessClient, createProcess), {
    onSuccess: () => {
      queryCache.invalidateQueries(ApplicationQueryKeysEnum.APPLICATION_LIST);
    }
  });
}

export default useStartApplicationProcessMutation;
