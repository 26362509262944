import { MutationConfig } from 'react-query';
import { ApplicationPrintingItemPermissionDataUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const updateApplicationPrintingItemPermissionData = async ({
  applicationId,
  applicationPrintingItemPermissionDataUpdateRequest
}: {
  applicationId: string;
  applicationPrintingItemPermissionDataUpdateRequest: ApplicationPrintingItemPermissionDataUpdateRequest;
}) => {
  const data = await API.application.updateApplicationPrintingItemPermissionData(
    applicationId,
    applicationPrintingItemPermissionDataUpdateRequest,
    {
      ...endpointsConfig.generatePermissions
    }
  );
  return data;
};

export function useUpdateApplicationPrintingItem(
  config: MutationConfig<Awaited<ReturnType<typeof updateApplicationPrintingItemPermissionData>>>
) {
  return useMutation(updateApplicationPrintingItemPermissionData, config);
}
