import { createTheme as createMuiTheme } from '@mui/material/styles';

import { CustomTheme, PaletteOptions, ThemeFontSizeEnum } from '@libs/common/v2/theme';

import customTheme from './customTheme';

/**
 * Funkcja tworząca Customowy theme.
 * @param palette Obiekt zawierający paletę kolorów
 * @param currentFontSize aktualny mnożnik wielkości fontu na stronie
 * @returns Custom Theme
 */
export default function createTheme(palette: PaletteOptions, currentFontSize: ThemeFontSizeEnum = 1): CustomTheme {
  return createMuiTheme({
    ...customTheme(palette, currentFontSize)
  }) as CustomTheme;
}
