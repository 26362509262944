import { useCallback, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';

interface FileFetchHookResult {
  fetch: (fetchQuery: () => Promise<AxiosResponse>) => Promise<AxiosResponse>;
  isLoading?: boolean;
}

function useFileFetch(): FileFetchHookResult {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetch = useCallback(async (fetchQuery: () => Promise<AxiosResponse>) => {
    setIsLoading(true);
    try {
      return await fetchQuery();
    } finally {
      setIsLoading(false);
    }
  }, []);

  return useMemo(
    () => ({
      fetch,
      isLoading
    }),
    [fetch, isLoading]
  );
}

export default useFileFetch;
