import React, { CSSProperties, ReactNode, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { useNavbar } from '@libs/common/layout/hooks';
import { Animate, IPropsDetail, IPropsItem, LoaderCircular, PageHeader, Scrollbar } from '@libs/common/v2';
import { PaletteOptions, Theme, useThemeValuesContext } from '@libs/common/v2/theme';
import { calc } from '@libs/common/v2/utils';

import PageSidebar from './sidebar/PageSidebar';

interface IProps {
  classes?: Record<string, string>;
  /**
   * Header lewego rozwijanego sidebar'a
   */
  leftSidebarHeader?: ReactNode;
  /**
   * Content lewego rozwijanego sidebar'a. Może to być komponent lub jeżeli zachodzi taka potrzeba,
   * funkcja w której mamy dostęp do akcji zamknięcia sidebar'a
   */
  leftSidebarContent?: ((toggle: () => void) => ReactNode) | ReactNode;
  /**
   * Content lewego  sidebar'a jeżeli chcemy wyświetlać w stanie 'zwinietym'.
   */
  leftSidebarContentNotExpanded?: ((toggle: () => void) => ReactNode) | ReactNode;
  /**
   * Header prawego rozwijanego sidebar'a
   */
  rightSidebarHeader?: ReactNode;
  /**
   * Content prawego rozwijanego sidebar'a. Może to być komponent lub jeżeli zachodzi taka potrzeba,
   * funkcja w której mamy dostęp do akcji zamknięcia sidebar'a
   */
  rightSidebarContent?: ((toggle: () => void) => ReactNode) | ReactNode;
  /**
   * Customowy header całego page, domyślnie header to komponent PageHeader.
   * Przekazując inny komponent należy zweryfikować style w tworzonym Page i ewentualnie je poprawić.
   */
  header?: ReactNode;
  /**
   * Czy fixed header dla contetu jest ukryty
   */
  isContentHeaderHidden?: boolean;
  /**
   * Fixed header dla contentu
   */
  contentHeader?: ReactNode;
  /**
   * Głowna część Page, content renderowany na środku.
   */
  content?: ReactNode;
  /**
   * Dodatkowe style do wrapper'a renderowanego content'u.
   */
  contentClassName?: string;
  /**
   * Tytuł strony tworzonego Page. Przekazana do domyślnego header ( PageHeader ).
   */
  title?: string;
  /**
   * `Okruszki` strony tworzonego Page. Przekazane do domyślnego header ( PageHeader ).
   */
  breadcrumbs?: IPropsItem[];
  /**
   * Tablica szczegółów wyświetlanej strony tworzonego Page. Przekazana do domyślnego header ( PageHeader ).
   */
  headerDetails?: IPropsDetail[];
  /**
   * Komponent z akcjami strony tworzonego Page. Przekazany do domyślnego header ( PageHeader ).
   */
  headerRightSideContent?: ReactNode;
  /**
   * Dodatkowe style dla wrapper'a domyślnego headera strony tworzonego Page.
   * Przy zmianie należy zweryfikować czy cały layout dalej dobrze się 'rysuje'.
   */
  headerWrapperClassName?: string;
  /**
   * Dodatkowe style tytułu strony tworzonego Page.
   * Przekazane do domyślnego header ( PageHeader ).
   * Przy zmianie należy zweryfikować czy cały layout dalej dobrze się 'rysuje'.
   */
  headerTitleCustomStyles?: CSSProperties & { [_: string]: unknown };
  /**
   * Propsy do statusu wyświetlanego w domyślnym header strony.
   */
  headerStatusData?: { color?: keyof PaletteOptions; title?: string };
  /**
   * Dodatkowe style dla wrapper'a całego content'u.
   * Przy zmianie należy zweryfikować czy cały layout dalej dobrze się 'rysuje'.
   */
  contentWrapperClassName?: string;
  /**
   * Dodatkowe style przekazane do header'a lewego sidebar'a.
   */
  leftSidebarHeaderClassName?: string;
  /**
   * Dodatkowe style przekazane do content'u lewego sidebar'a.
   */
  leftSidebarContentClassName?: string;
  /**
   * Wariant lewego sidebara. Domyślna wartość `permament`.
   */
  leftVariant?: 'temporary' | 'permanent';
  /**
   * Wariant prawego sidebara. Domyślna wartość `permament`.
   */
  rightVariant?: 'temporary' | 'permanent';
  /**
   * Dodatkowe style przekazane do header'a prawego sidebar'a.
   */
  rightSidebarHeaderClassName?: string;
  /**
   * Dodatkowe style przekazane do content'u prawego sidebar'a.
   */
  rightSidebarContentClassName?: string;
  /**
   * Dodatkowe style przekazane do wrapper'a lewego sidebar'a.
   */
  leftSidebarClassName?: string;
  /**
   * Dodatkowe style przekazane do wrapper'a lewego sidebar'a.
   */
  rightSidebarClassName?: string;
  /**
   * Dodatkowe style przekazane do wrapper'a całego Page,
   * z wyłączeniem prawego sidebar'a w trybie pełnej wysokości.
   */
  pageWrapperClassName?: string;
  /**
   * Pozytywna wartość chowa header tworzonego komponentu Page.
   * Domyślnie header jest komponentem PageHeader
   */
  isHeaderHidden?: boolean;
  /**
   * Wartość na podstawie której jest wyświetlany stan ładowania dla content'u.
   */
  isLoading?: boolean;
  /**
   * Wartość na podstawie której jest wyświetlany divider w domyślnym header komponentu Page ( PageHeader ).
   * Domyślnie `true`
   */
  isHeaderDividerShown?: boolean;
  /**
   * Wartość decyduje o dodaniu domyślnego scroll'a dla content'u lewego sidebar'a.
   */
  isLeftSidebarScrollEnabled?: boolean;
  /**
   * Wartość decyduje o dodaniu domyślnego scroll'a dla content'u prawego sidebar'a.
   */
  isRightSidebarScrollEnabled?: boolean;
  /**
   * Wartość decyduje czy lewy sidebar ma mieć białe tło.
   */
  isLeftSidebarWhite?: boolean;
  /**
   * Wartość decyduje czy prawy sidebar ma mieć białe tło.
   * Domyślnie `true`
   */
  isRightSidebarWhite?: boolean;
  /**
   * Wartość decyduje o dodaniu domyślnego scroll'a dla content'u Page.
   */
  isContentScrollEnabled?: boolean;
  /**
   * Wartość decyduje o tym czy prawy sidebar jest pełnej wysokości czy ma taką samą wysokość co lewy sidebar.
   */
  isRightSidebarFullPage?: boolean;
  /**
   * Wartość decyduje czy lewy sidebar jest domyślnie otwarty
   */
  isLeftSidebarOpen?: boolean;
  /**
   * Wartość decyduje czy prawy sidebar jest domyślnie otwarty
   */
  isRightSidebarOpen?: boolean;
  /**
   * Wartość dostosowuje style gdy tabela jest renderowana na całą stronę z poziomymi tabami.
   */
  isTable?: boolean;
  /**
   * Wartość decyduje czy content Page ma nie mieć domyślnego margin.
   */
  isNoContentMargin?: boolean;
  /**
   * Wartość decyduje czy content ma mieć ustawioną wysokość na 100%.
   */
  isContentFullHeight?: boolean;
  /**
   * Wartość decyduje czy prawy sidebar ma być widoczny.
   */
  isRightSidebarVisible?: boolean;
  /**
   * Wartość decyduje czy scrollbar w content'cie jest widoczny gdy header jest ukryty.
   */
  allowScrollbarInContentWithHiddenHeader?: boolean;
  /**
   * Minimalna szerokość lewego sidebar'a
   */
  leftSidebarMinWidth?: string;
  /**
   * Minimalna szerokość prawego sidebar'a
   */
  rightSidebarMinWidth?: string;
}

function Page({
  isContentScrollEnabled,
  isRightSidebarScrollEnabled,
  isLeftSidebarScrollEnabled,
  header,
  isHeaderHidden,
  leftSidebarHeaderClassName,
  leftSidebarContentClassName,
  rightSidebarHeaderClassName,
  rightSidebarContentClassName,
  leftVariant,
  rightVariant,
  title,
  isHeaderDividerShown,
  breadcrumbs,
  headerDetails,
  headerRightSideContent,
  isLeftSidebarWhite,
  isRightSidebarWhite = true,
  headerWrapperClassName,
  headerTitleCustomStyles,
  headerStatusData,
  leftSidebarHeader,
  leftSidebarContent,
  leftSidebarContentNotExpanded,
  isLoading,
  isRightSidebarFullPage = true,
  content,
  contentWrapperClassName,
  isContentHeaderHidden,
  contentHeader,
  contentClassName,
  pageWrapperClassName,
  rightSidebarContent,
  rightSidebarHeader,
  leftSidebarClassName,
  rightSidebarClassName,
  isLeftSidebarOpen: isLeftSidebarDrawerOpen = false,
  isRightSidebarOpen: isRightSidebarDrawerOpen = false,
  classes: forwardClasses,
  isTable,
  isNoContentMargin,
  isContentFullHeight = isTable,
  isRightSidebarVisible = true,
  allowScrollbarInContentWithHiddenHeader = false,
  leftSidebarMinWidth,
  rightSidebarMinWidth
}: IProps) {
  const rootRef = useRef(null);
  const { width } = useWindowSize();
  const globalTheme = useThemeValuesContext();
  const isSmallScreen = width < globalTheme?.breakpoints?.values?.md;
  const [isLeftSidebarOpenState, setIsLeftSidebarOpenState] = useState(isLeftSidebarDrawerOpen);
  const [isRightSidebarOpenState, setIsRightSidebarOpenState] = useState(isRightSidebarDrawerOpen);
  const isLeftSidebarOpen = isSmallScreen ? false : isLeftSidebarOpenState;
  const isRightSidebarOpen = isSmallScreen ? false : isRightSidebarOpenState;
  const { isFolded } = useNavbar();
  const classes = useStyles({
    forwardClasses,
    isLeftSidebarOpen,
    isTable,
    leftSidebarHeader,
    leftSidebarContent,
    rightSidebarHeader,
    rightSidebarContent,
    isNoContentMargin,
    isFolded,
    isContentHeaderHidden,
    contentHeader: !!contentHeader
  });

  const handleLeftToggleDrawer = () => {
    setIsLeftSidebarOpenState(prev => !prev);
  };

  const handleRightToggleDrawer = () => {
    setIsRightSidebarOpenState(prev => !prev);
  };
  const renderHeader = () => {
    if (isHeaderHidden) {
      return null;
    }
    if (header) {
      return header;
    }
    return (
      <PageHeader
        title={title}
        breadcrumbs={breadcrumbs}
        details={headerDetails}
        isDividerShown={isHeaderDividerShown}
        rightSideContent={headerRightSideContent}
        statusData={headerStatusData}
        wrapperClassName={headerWrapperClassName}
        titleCustomStyles={headerTitleCustomStyles}
      />
    );
  };

  return (
    <div className={clsx(classes.root)} ref={rootRef}>
      <div className={clsx('flex flex-auto flex-col container h-full', classes.pageWrapper, pageWrapperClassName)}>
        {renderHeader()}
        <div
          className={clsx(
            'wrapperWithHeader',
            classes.wrapper,
            (!isHeaderHidden || allowScrollbarInContentWithHiddenHeader) && classes.wrapperWithHeader
          )}
        >
          {(leftSidebarHeader || leftSidebarContent) && (
            <PageSidebar
              position="left"
              header={leftSidebarHeader}
              sidebarClassName={leftSidebarClassName}
              contentClassName={leftSidebarContentClassName}
              headerClassName={leftSidebarHeaderClassName}
              isWhite={isLeftSidebarWhite}
              variant={leftVariant}
              handleToggleDrawer={handleLeftToggleDrawer}
              isToggleDrawerVisible={!isSmallScreen}
              isSidebarOpen={isLeftSidebarOpen}
              content={leftSidebarContent}
              contentNotExpanded={leftSidebarContentNotExpanded}
              isInnerScrollEnabled={isLeftSidebarScrollEnabled}
              minWidth={leftSidebarMinWidth}
              rootRef={rootRef}
            />
          )}
          <div className={clsx(classes.contentWrapper, 'flex flex-col', contentWrapperClassName)}>
            {!isContentHeaderHidden && <div className={classes.contentHeader}>{contentHeader}</div>}
            <Scrollbar isScrollbarEnabled={isContentScrollEnabled} isMobile={!isContentScrollEnabled && isSmallScreen}>
              <Animate animation="transition.fadeIn" delay={30}>
                <LoaderCircular isLoading={isLoading}>
                  {content && (
                    <div
                      className={clsx(
                        classes.content,
                        'flex flex-auto',
                        isContentFullHeight && 'h-full mb-0',
                        contentClassName
                      )}
                    >
                      {content}
                    </div>
                  )}
                </LoaderCircular>
              </Animate>
            </Scrollbar>
          </div>
          {!isRightSidebarFullPage && isRightSidebarVisible && (rightSidebarHeader || rightSidebarContent) && (
            <PageSidebar
              position="right"
              header={rightSidebarHeader}
              content={rightSidebarContent}
              variant={rightVariant}
              sidebarClassName={rightSidebarClassName}
              contentClassName={rightSidebarContentClassName}
              headerClassName={rightSidebarHeaderClassName}
              isWhite={isRightSidebarWhite}
              handleToggleDrawer={handleRightToggleDrawer}
              isSidebarOpen={isRightSidebarOpen}
              isInnerScrollEnabled={isRightSidebarScrollEnabled}
              minWidth={rightSidebarMinWidth}
              rootRef={rootRef}
            />
          )}
        </div>
      </div>
      {isRightSidebarFullPage &&
        isRightSidebarVisible &&
        (rightSidebarHeader || rightSidebarContent) &&
        !isSmallScreen && (
          <PageSidebar
            position="right"
            header={rightSidebarHeader}
            content={rightSidebarContent}
            variant={rightVariant}
            isSidebarOpen={isRightSidebarOpen}
            handleToggleDrawer={handleRightToggleDrawer}
            sidebarClassName={rightSidebarClassName}
            contentClassName={rightSidebarContentClassName}
            headerClassName={rightSidebarHeaderClassName}
            isWhite={isRightSidebarWhite}
            isInnerScrollEnabled={isRightSidebarScrollEnabled}
            minWidth={rightSidebarMinWidth}
            rootRef={rootRef}
          />
        )}
    </div>
  );
}

export default React.memo(Page);

const useStyles = makeStyles<Theme, Record<string, any>>(theme => ({
  root: {
    display: 'flex',
    minHeight: '100%',
    width: '100%',
    position: 'relative',
    flex: '1 0 auto',
    height: '100%',
    backgroundColor: theme.palette.grey[50],
    ...(theme.typography.textMd.normal as CSSProperties)
  },
  pageWrapper: {
    width: ({ isFolded }) => (isFolded ? '800px' : '488px')
  },
  [theme.breakpoints.down('md')]: {
    pageWrapper: { width: '100% !important' }
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 auto',
    zIndex: 2,
    maxWidth: '100%',
    minWidth: 0,
    flexGrow: 1
  },
  wrapperWithHeader: {
    height: calc('100% - 162px'),
    overflow: 'hidden'
  },
  toolbar: {
    height: 64,
    minHeight: 80,
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    flex: '1 0 auto',
    [theme.breakpoints.down('md')]: {
      margin: ({ isNoContentMargin }) => !isNoContentMargin && '0 16px 0px 16px'
    },
    margin: ({ isNoContentMargin }) => !isNoContentMargin && '0 32px 32px 32px',
    height: ({ isTable }) => isTable && 'calc(100% - 94px)'
  },
  contentHeader: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0
  },
  contentWrapper: {
    height: ({ isTable, isContentHeaderHidden, contentHeader }) => {
      if (isTable) {
        if (!isContentHeaderHidden && contentHeader) {
          return 'calc(100% - 76px)';
        }
        return '100%';
      }
      if (!isContentHeaderHidden && contentHeader) {
        return 'calc(100% - 81px)';
      }
      return 'calc(100% - 5px)';
    },
    position: ({ isContentHeaderHidden }) => {
      return !isContentHeaderHidden ? 'relative' : 'initial';
    },
    paddingTop: ({ isContentHeaderHidden, contentHeader }) => {
      if (contentHeader) {
        return isContentHeaderHidden ? 0 : 76;
      }
      return 0;
    },
    width: ({ isLeftSidebarOpen, leftSidebarHeader, leftSidebarContent, rightSidebarHeader, rightSidebarContent }) => {
      if (!leftSidebarHeader && !leftSidebarContent && !rightSidebarHeader && !rightSidebarContent) {
        return '100%';
      }
      if (isLeftSidebarOpen) {
        return 'calc(100% - 306px)';
      }

      return theme.breakpoints.up('xMd') ? 'calc(100% - 55px)' : 'calc(100% - 84px)';
    }
  }
}));
