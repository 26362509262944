import { useTranslation } from 'react-i18next';

import { Button, useRouter } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

interface IProps {
  actionKey: UIElementNameEnum;
  applicationId: string;
}

function ExploitationBaseCreateButton({ applicationId, actionKey }: IProps) {
  const [t] = useTranslation();
  const { routes, goToPage } = useRouter();

  return (
    <Button
      type="button"
      variant="outlined"
      onClick={() => applicationId && goToPage(routes.exploitationBaseApplicationCreate(applicationId))}
      label={t('action.add')}
      actionKey={actionKey}
    />
  );
}

export default ExploitationBaseCreateButton;
