import { useCallback } from 'react';

import { useDomainConfigContext } from '@libs/domain/config';

const useGetApiQueryByPerspective = () => {
  const { isClientPortal } = useDomainConfigContext();
  /**
   *
   * @param clientPortal clientPortalQuery
   * @param operatorPortal operatorPortalQuery
   * @returns query
   */
  const getQuery = useCallback(
    <T, K>(clientPortal: T, operatorPortal: K): T | K => {
      if (isClientPortal) {
        return clientPortal;
      }

      return operatorPortal;
    },
    [isClientPortal]
  );

  return {
    getQuery
  };
};

export default useGetApiQueryByPerspective;
