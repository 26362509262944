import React, { useCallback, useRef, useState } from 'react';
import { Row } from 'react-table';
import { MenuItem, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { IconType } from '@libs/common/v2/icons';

import { UIElementNameEnum } from '@libs/permission';

import { Theme, useTheme } from '../../../../theme';

import TableIconButton from './TableIconButton';

interface IActionProps {
  label?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  isDisabled?: boolean;
}

interface IProps {
  actions: IActionProps[];
  onClick?: React.MouseEventHandler;
  tooltipTitle?: string;
  icon: IconType;
  row?: Row<Record<string, any>>;
  className?: string;
  children?: React.ReactNode;
  iconClassName?: string;
  isDisabled?: boolean;
  isBackgroundTransparent?: boolean;
  isButtonLoading?: boolean;
  actionKey?: UIElementNameEnum;
}

function TableDropdownIconButton({
  actions,
  onClick,
  tooltipTitle,
  icon,
  row,
  className,
  children,
  iconClassName,
  isDisabled,
  isBackgroundTransparent,
  isButtonLoading,
  actionKey
}: IProps) {
  const { contrast } = useTheme();
  const classes = useStyles({ contrast });
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const buttonIconRef = useRef(null);

  const handleClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const handleOnClick = useCallback(
    (event: React.MouseEvent<any, MouseEvent>) => {
      onClick?.(event);
      handleOpen();
    },
    [onClick, handleOpen]
  );

  return (
    <>
      <TableIconButton
        ref={buttonIconRef}
        onClick={handleOnClick}
        tooltipTitle={tooltipTitle}
        row={row}
        icon={icon}
        className={className}
        iconClassName={iconClassName}
        isDisabled={isDisabled}
        isBackgroundTransparent={isBackgroundTransparent}
        isButtonLoading={isButtonLoading}
        actionKey={actionKey}
      >
        {children}
      </TableIconButton>

      <Popover
        open={menuOpen}
        anchorEl={buttonIconRef.current}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {actions?.map(action => (
          <MenuItem
            onClick={action.onClick}
            className={clsx('mt-8 py-8', classes.label)}
            tabIndex={0}
            disabled={action.isDisabled}
            key={action.label}
            onFocus={({ target }) => target.classList.add('focus')}
            onBlur={({ target }) => target.classList.remove('focus')}
          >
            {action.children}
            {action.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}

const useStyles = makeStyles<Theme, { contrast?: boolean }>(theme => ({
  label: {
    fontWeight: theme.typography.textMd.normal.fontWeight,
    fontSize: theme.typography.getFontSize(14),
    color: ({ contrast }) => contrast && theme.palette.grey[900]
  },
  paper: {
    '& li': {
      marginTop: 0
    },
    backgroundColor: ({ contrast }) => contrast && theme.palette.grey[100]
  }
}));

export default TableDropdownIconButton;
