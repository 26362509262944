import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SortingRule } from 'react-table';
import { Table } from '@enigma/fe-ui';
import { HeaderActions } from '@enigma/fe-ui/lib/display/table';
import { PermissionSearchFilter, PermissionTypeCode } from '@ibtm/domain';
import { useAppConfig } from '@libs/app-config';

import { partialTranslate, TranslationFrom } from '@libs/common';

import { useElementVisibility } from '@libs/permission';

import { API } from '@libs/domain/api';
import { endpointsConfig } from '@libs/domain/permission/api/endpoints-config';

import { DomainUIElementEnum } from '../../config';
import { LocksSummaryData } from '../../print/components/print-details/dialog/LocksSummaryDialog';
import { usePrintsDetailsDataContext } from '../../print/context';
import { usePrintActionHandler } from '../../print/hooks';
import { useOpenLocksSummaryDialog } from '../../print/hooks/useOpenLocksSummaryDialog';
import { usePrintsRegenerateAction } from '../../print/hooks/usePrintsRegenerateAction';
import { PermissionsQueryKeysEnum } from '../api';
import { usePermissionEditDialog } from '../hooks';
import { PERMISSIONS_DEFAULT_COLUMNS, usePermissionsColumnsV2 } from '../hooks/column/usePermissionsColumnsV2';
import { PermissionsColumnsEnum, PermissionSnapshotClient, PermissionTypeEnum } from '../model';
import { parsePermissionsList } from '../parsers';

export interface IPermissionsTable {
  titleKey: TranslationFrom<'permission:title.permission'>;
  visibleColumns?: Array<PermissionsColumnsEnum>;
  headerActions?: HeaderActions<PermissionSnapshotClient>;
  initialParams?: Omit<PermissionSearchFilter, 'typeKeyIn'> & { typeKeyIn?: Array<PermissionTypeEnum> };
  // Props do ustawienia filtrowania pola NIP w formularzu zezwolenia
  subjectNameColumnHeader?: string;
  permissionNumberColumnHeader?: string;
  validFromColumnHeader?: string;
  defaultSort: SortingRule<PermissionSnapshotClient>[];
  maxRowActionNumber: number;
  applicationId: string;
  permissionTypeCode: PermissionTypeCode;
  isMultipleFormNumberToRegenerateInRow?: boolean;
  isPrintDisabled?: (rowData) => boolean;
  isLocksSummaryVisible?: boolean;
  isFormsNumbersVisibleInLockSummary?: boolean;
  isEditEnabled?: boolean;
  dateFromAndToWithoutChips?: boolean;
}

function PermissionsTableV2({
  titleKey,
  visibleColumns = PERMISSIONS_DEFAULT_COLUMNS,
  headerActions,
  initialParams,
  subjectNameColumnHeader,
  permissionNumberColumnHeader,
  validFromColumnHeader,
  defaultSort,
  maxRowActionNumber,
  applicationId,
  permissionTypeCode,
  isMultipleFormNumberToRegenerateInRow,
  isPrintDisabled,
  isLocksSummaryVisible,
  isFormsNumbersVisibleInLockSummary,
  isEditEnabled = true,
  dateFromAndToWithoutChips
}: IPermissionsTable) {
  const [t] = useTranslation();
  const getTitle = partialTranslate('permission:title.permission');
  const { checkIsElementVisible } = useElementVisibility();

  const { columns, mappedFilterFields, mappedSortFields } = usePermissionsColumnsV2({
    visibleColumns,
    permissionNumberColumnHeader,
    subjectNameColumnHeader,
    validFromColumnHeader,
    dateFromAndToWithoutChips
  });

  const { handlePrintRegenerate } = usePrintsRegenerateAction();
  const { print } = usePrintActionHandler();
  const { openLocksSummaryDialog } = useOpenLocksSummaryDialog(isFormsNumbersVisibleInLockSummary);

  const title = useMemo(() => getTitle(titleKey), [getTitle, titleKey]);

  const {
    printDetailsData: {
      depotMPD: { id: depotMPDId },
      notDepotMPDTooltip
    }
  } = usePrintsDetailsDataContext();

  const isRegenerateDisabled = !depotMPDId;

  const {
    raw: {
      prints: { showLocksSummaryAction }
    }
  } = useAppConfig();

  const { openEditPermissionDialog } = usePermissionEditDialog();

  const editPermission = useCallback(
    ({ rowData, refetch }: { rowData: PermissionSnapshotClient; refetch?: () => void }) => {
      openEditPermissionDialog(
        {
          id: rowData.id,
          formNumber: rowData.formNumber,
          validFrom: rowData.validFrom,
          permissionNumber: rowData.permissionNumber,
          printDate: rowData.printDate,
          validTo: rowData.validTo,
          permissionValidityPeriodKey: rowData.permissionValidityPeriodKey,
          version: rowData.version
        },
        refetch,
        titleKey
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Table
      tableTitle={title}
      columns={columns}
      query={{
        request: async params => {
          const { data } = await API.permissions.getPermissionsPage(
            {
              ...params,
              ...initialParams
            },
            {
              ...endpointsConfig.getPermissionsPage
            }
          );
          return {
            data: {
              ...data,
              content: parsePermissionsList(data.content)
            }
          };
        },
        queryKey: PermissionsQueryKeysEnum.PERMISSIONS_LIST,
        filterParamsConverter: mappedFilterFields,
        sortParamConverter: mappedSortFields,
        initParams: initialParams
      }}
      initialTableState={{
        sorting: defaultSort
      }}
      xlsxDownload={{
        fileName: title,
        apiRequest: async params => {
          const { data } = await API.permissions.getPermissionsPage(
            {
              ...params,
              ...initialParams
            },
            {
              ...endpointsConfig.getPermissionsPage
            }
          );
          return {
            data: {
              ...data,
              content: parsePermissionsList(data.content)
            }
          };
        }
      }}
      maxNumberOfIconsInActionRow={maxRowActionNumber}
      headerActions={headerActions}
      rowActions={[
        {
          label: isRegenerateDisabled ? notDepotMPDTooltip : t('prints:actions.regenerate'),
          tooltip: isRegenerateDisabled ? notDepotMPDTooltip : t('prints:actions.regenerate'),
          isDisabled: isRegenerateDisabled || !applicationId,
          onClick: ({ rowData, refetch }) => {
            handlePrintRegenerate({
              applicationId,
              data: rowData,
              onSuccess: refetch,
              isMultipleFormNumberToRegenerateInRow,
              permissionTypeCode
            });
          },
          icon: 'CalculatorIcon'
        },
        {
          label: t('prints:locksSummary.title'),
          icon: 'FileFindIcon',
          onClick: ({ rowData }) => {
            openLocksSummaryDialog(rowData as LocksSummaryData);
          },
          isHidden: !isLocksSummaryVisible || !showLocksSummaryAction
        },
        {
          label: t('action.print'),
          tooltip: ({ rowData }) =>
            isPrintDisabled?.(rowData) ? t('prints:messages.printActionDisabled') : t('action.print'),
          isDisabled: ({ rowData }) => isPrintDisabled?.(rowData),
          onClick: ({ rowData, setLoading, refetch }) => {
            setLoading(true);
            print(
              { permissionTypeCode, permissionIds: [rowData.id] },
              {
                onSettled: () => {
                  setLoading(false);
                  refetch();
                }
              }
            );
          },
          icon: 'PrintIcon'
        },
        {
          label: t('action.edit'),
          onClick: editPermission,
          icon: 'EditIcon',
          isHidden: !isEditEnabled || !checkIsElementVisible(DomainUIElementEnum.PRINT_DATA_UPDATE_BUTTON)
        }
      ]}
    />
  );
}

export default PermissionsTableV2;
