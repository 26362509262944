import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TFunction } from '@libs/common';

const getProcessWarningConfigs = (translate: TFunction) => [
  {
    messageKeys: ['verifyApplicationUserTask', 'sid-37F4578D-0EE5-4D9D-BC73-D1F76F14D06A'],
    title: translate('processes:message.noContactDatasWarning'),
    condition: data => !data?.contact?.email && !data?.contact?.phoneNumber
  }
];

const useProcessWarningConfig = () => {
  const [t] = useTranslation();

  const config = useMemo(() => getProcessWarningConfigs(t), [t]);

  const findConfig = (messageKey: string) => {
    return config.find(configElement => configElement.messageKeys.includes(messageKey));
  };

  return {
    processWarningConfig: {
      find: findConfig
    }
  };
};

export default useProcessWarningConfig;
