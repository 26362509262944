import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function deleteProceeding(proceedingId: string) {
  return API.proceeding.deleteProceeding(proceedingId, { ...endpointsConfig.deleteProceeding });
}

function useDeleteProceedingMutation() {
  return useMutation(deleteProceeding);
}

export default useDeleteProceedingMutation;
