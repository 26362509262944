import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import { LoaderCircular, useFormV2Context } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';
import { FolderDetailsParams } from '@libs/domain/folder';

import FolderSelectField from '../../../application/components/application-create/FolderSelectField';
import { useFolderDetailsQuery } from '../../../folder/api';
import useFetchFolderDialogContent from '../hooks/useFetchFolderDialogContent';
import { BorrowFolderDialogTypeEnum, BorrowFolderFormEnum } from '../model';

import { BorrowFolderDialogContent } from './common/BorrowFolderDialogContent';
import { useBorrowFoldersContext } from './common/BorrowFolderProvider';

function BorrowFoldersDialogContentWrapper() {
  const { row } = useBorrowFoldersContext();

  if (row) {
    return <BorrowFolderDialogContent row={row} />;
  }
  return <BorrowFolderFetchFolderDialogContent />;
}

function BorrowFolderFetchFolderDialogContent() {
  const [t] = useTranslation();
  const { folderId } = useParams<FolderDetailsParams>();
  const { setValue } = useFormV2Context();
  const { contentType, selectedFolder, open } = useBorrowFoldersContext();
  const { isLoading } = useFetchFolderDialogContent();
  const classes = useStyles();
  const { data } = useFolderDetailsQuery(folderId, { enabled: !!folderId });

  useEffect(() => {
    if (selectedFolder && open) {
      setValue(BorrowFolderFormEnum.BORROW_PICKED_FOLDER, { label: selectedFolder.label, value: selectedFolder.value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setValue(BorrowFolderFormEnum.BORROW_PICKED_FOLDER, {
        id: data?.id,
        value: data?.id,
        name: data?.number,
        typeKey: data?.typeKey,
        number: data?.number,
        nip: data?.subject.nip,
        label: data?.number
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, folderId]);

  const isContentTypeBorrowFolder = contentType === BorrowFolderDialogTypeEnum.BORROW_FOLDER;

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <LoaderCircular isLoading />
      </div>
    );
  }
  return (
    <FolderSelectField
      isArchive
      fieldName={BorrowFolderFormEnum.BORROW_PICKED_FOLDER}
      {...(isContentTypeBorrowFolder
        ? {
            queryKey: BorrowFolderDialogTypeEnum.BORROW_FOLDER,
            archiveFoldersQueryParams: {
              localizationKeyIn: [DomainDictionaryEntry.FOLDER_LOCALIZATION.CARRY_ON_ARCHIVE],
              isCarryOnArchived: true
            }
          }
        : {
            queryKey: BorrowFolderDialogTypeEnum.RETURN_FOLDER,
            archiveFoldersQueryParams: {
              localizationKeyIn: [DomainDictionaryEntry.FOLDER_LOCALIZATION.BORROWED],
              isCarryOnArchived: true
            },
            isReturnFolderAction: true
          })}
      label={t('applications:field.folderNumberAndNIP')}
    />
  );
}

const useStyles = makeStyles({
  loader: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80px'
  }
});

export default BorrowFoldersDialogContentWrapper;
