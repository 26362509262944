import { PaginatedQueryConfig, usePaginatedQuery } from 'react-query';
import { FolderLimitTransferDetails, FolderLimitTransferFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { ForeignPermissionQueryEnum } from '../../model';

type Params = FolderLimitTransferFilter & { folderId: string };

const getLimitTransfer = async (_, { folderId, ...rest }: Params) => {
  const { data } = await API.folderLimits.getFolderLimitsTransferDetailsPage(folderId, rest);
  return data;
};

const getLimitTransferClient = async (_, params: FolderLimitTransferFilter) => {
  const { data } = await API.client.folderLimits.getFolderLimitsTransferDetailsPage('', '', params);
  return data;
};

function useLimitTransferQuery(
  params: Params | FolderLimitTransferFilter,
  queryConfig: PaginatedQueryConfig<unknown> = {}
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return usePaginatedQuery<IPaginatedModel<FolderLimitTransferDetails>>(
    [ForeignPermissionQueryEnum.LIMIT_TRANSFER, params],
    getQuery(getLimitTransferClient, getLimitTransfer),
    queryConfig
  );
}
export default useLimitTransferQuery;
