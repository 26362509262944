import { InputMode } from '@libs/common/v2';

import {
  AdditionalSectionLicenseOrPermissionType,
  ApplicationMetaFormDefinition
} from '@libs/domain/application/model';

import { DomainDictionaryEntry } from '../../../../config';

const definition: ApplicationMetaFormDefinition = {
  fields: [
    {
      typeKey: 'DATETIME',
      fieldId: 'application-submission-date',
      properties: {
        label: 'Data wpływu',
        yupValidation: {
          required: {},
          dependent: {
            dependentFieldId: 'application-created-date',
            isCheckingMax: true,
            message: 'Data nie może być późniejsza, niż data wypełniania wniosku'
          }
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'submissionReceiptDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'submissionReceiptDate'
          }
        }
      }
    },
    {
      typeKey: 'LINE_TYPE_DICTIONARY_FIELD',
      fieldId: 'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-lineTypeKey',
      properties: {
        visibleOptions: [DomainDictionaryEntry.TRANSIT_LINE_TYPE.REGULAR_LINE_UE],
        label: 'Typ linii',
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.permissionApplicationDetails.wprApplicationDetails.lineTypeKey'
          }
        }
      }
    },
    {
      typeKey: 'BOOLEAN',
      fieldId: 'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-specialLine',
      properties: {
        label: 'Linia specjalna',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.permissionUpdateRequest.wprApplicationUpdateRequest.specialLine'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.permissionApplicationDetails.wprApplicationDetails.specialLine'
          }
        }
      }
    },
    {
      typeKey: 'SCHEDULE_TYPE_KEY_DICTIONARY_FIELD',
      fieldId: 'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-scheduleTypeKey',
      properties: {
        label: 'Rozkład',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.permissionUpdateRequest.wprApplicationUpdateRequest.scheduleTypeKey',
            formAccessor: 'value',
            typeKey: 'DICTIONARY'
          },
          get: {
            accessor: 'anyTransitStopWithManyDepartures'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId:
        'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-permissionValidityPeriodKey',
      properties: {
        label: 'Okres obowiązywania',
        dictionaryName: 'DURATION_OF_PERMIT',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.permissionUpdateRequest.permissionValidityPeriodKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.permissionApplicationDetails.permissionValidityPeriodKey'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId:
        'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-permissionValidityPeriodKey-view',
      properties: {
        label: 'Okres obowiązywania',
        dictionaryName: 'DURATION_OF_PERMIT',
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.permissionApplicationDetails.permissionValidityPeriodKey'
          },
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.permissionUpdateRequest.permissionValidityPeriodKey'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'APPLICATION_VALID_FROM_FIELD',
      fieldId: 'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-validFrom',
      properties: {
        label: 'Data rozpoczęcia obowiązywania',
        concernPermissionFieldId:
          'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-concernPermission',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.permissionUpdateRequest.validFrom'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.permissionApplicationDetails.validFrom'
          }
        }
      }
    },
    {
      typeKey: 'BOOLEAN',
      fieldId: 'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-transitFromOutsideEu',
      properties: {
        label: 'Wniosek dotyczy przejazdu zawierającego tranzyt przez kraj spoza UE',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.permissionUpdateRequest.wprApplicationUpdateRequest.transitFromOutsideEu'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor:
              'permissionApplicationDetails.permissionApplicationDetails.wprApplicationDetails.transitFromOutsideEu'
          }
        }
      }
    },
    {
      typeKey: 'APPLICATION_TERM_INPUT',
      fieldId: 'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-term',
      properties: {
        label: 'Data terminu',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.permissionUpdateRequest.term'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.permissionApplicationDetails.term'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-modificationTypeKeys',
      properties: {
        label: 'Rodzaj zmiany',
        yupValidation: {
          required: true
        },
        dictionaryName: 'PERMIT_MODIFICATION_TYPE_UE_WB_OUTSIDE_UE',
        multiple: true,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.permissionUpdateRequest.modificationTypeKeys'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.permissionApplicationDetails.modificationTypeKeys'
          }
        }
      }
    },
    {
      typeKey: 'NUMBER',
      fieldId: 'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-copies',
      properties: {
        label: 'Liczba wypisów',
        yupValidation: {
          required: true,
          min: 0
        },
        defaultInitialValue: 0,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.permissionUpdateRequest.copies'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.permissionApplicationDetails.copies'
          }
        }
      }
    },
    {
      typeKey: 'AUTOCOMPLETE',
      fieldId: 'licenseApplicationDetails-permissionApplicationDetails-permissionCopyDuplicates',
      properties: {
        label: 'Duplikat wypisu z zezwolenia',
        yupValidation: {
          required: true
        },
        api: {
          fetch: {
            requestKey: 'GET_PERMISSIONS_TO_DUPLICATE_COPY_SPO_UE',
            accessor: 'content',
            optionTextMatch: '[permissionNumber] - [number]',
            backendFilterParamName: 'permissionNumberOrCopyNumberContains'
          },
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.permissionUpdateRequest.duplicateCopyId',
            formAccessor: 'id'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.permissionApplicationDetails.duplicateCopy'
          }
        }
      }
    },
    {
      typeKey: 'AUTOCOMPLETE',
      fieldId: 'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-concernPermission',
      properties: {
        label: 'Numer zezwolenia',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.permissionUpdateRequest.concernPermission',
            formAccessor: 'id'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.permissionApplicationDetails.concernPermission'
          },
          fetch: {
            requestKey: 'GET_PERMISSIONS_SPO',
            accessor: null,
            optionTextMatch: '[number]',
            backendFilterParamName: 'numberContains',
            backendParams: { typeKeyIn: ['ibtm-domain.permission.type.spo-ue'] }
          }
        }
      }
    }
  ],
  layout: [
    {
      typeKey: 'TAB_NAVIGATION',
      properties: {
        visibility: {
          elementVisibilityKeys: ['TAB_VISIBILITY']
        },
        defaultTabId: 'GENERAL',
        title: 'Sekcje',
        tabs: [
          {
            id: 'GENERAL',
            title: 'Dane podstawowe',
            icon: 'InfoIcon'
          },
          {
            id: 'SUBJECT',
            title: 'Podmiot',
            icon: 'FacadeIcon'
          },
          {
            id: 'ADDRESSES',
            title: 'Adresy',
            icon: 'MailboxIcon'
          },
          {
            id: 'PERMISSIONS',
            title: 'Uprawnienia',
            icon: 'KeyIcon'
          },
          {
            id: 'PARTNER_APPLICANTS',
            title: 'Podwykonawcy/Partnerzy',
            icon: 'GroupOfPeopleIcon'
          },
          {
            id: 'PROXIES',
            title: 'Pełnomocnicy',
            icon: 'GroupOfPeopleIcon'
          },
          {
            id: 'TRANSIT_SCHEDULES',
            title: 'Rozkład jazdy',
            icon: 'CalendarIcon'
          },
          {
            id: 'CABOTAGE',
            title: 'Kabotaż',
            icon: 'TruckIcon'
          },
          {
            id: 'QUOTATION',
            title: 'Wycena',
            icon: 'CoinsIcon'
          },
          {
            id: 'OUTGOING_DOCUMENTS',
            title: 'Dokumenty wychodzące',
            icon: 'DocumentsIssuedIcon'
          },
          {
            id: 'NOTE',
            title: 'Notatka',
            icon: 'NoteIcon'
          },
          {
            id: 'FILES',
            title: 'Pliki',
            icon: 'FileEmptyIcon'
          },
          {
            id: 'HISTORY',
            title: 'Historia',
            icon: 'HistoryIcon'
          }
        ]
      },
      nodes: [
        {
          typeKey: 'TABS_CONTENT',
          nodes: [
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-spo-general-tab',
                additionalNodes: {
                  'application-section-after-sumission-date': [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId:
                          'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-lineTypeKey'
                      }
                    },
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId:
                          'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-specialLine'
                      }
                    },
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId:
                          'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-scheduleTypeKey'
                      }
                    },
                    {
                      typeKey: 'CONDITIONALLY_VISIBLE',
                      properties: {
                        dependendFieldId: 'application-statusKey',
                        value: {
                          notIncludes: ['ibtm-domain.application.status.issued']
                        }
                      },
                      nodes: [
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId:
                              'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-permissionValidityPeriodKey',
                            yupValidation: {
                              required: true
                            }
                          }
                        }
                      ]
                    },
                    {
                      typeKey: 'CONDITIONALLY_VISIBLE',
                      properties: {
                        dependendFieldId: 'application-statusKey',
                        value: {
                          includes: ['ibtm-domain.application.status.issued']
                        }
                      },
                      nodes: [
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId:
                              'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-permissionValidityPeriodKey-view'
                          }
                        }
                      ]
                    },
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId:
                          'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-validFrom'
                      }
                    },
                    {
                      typeKey: 'CONDITIONALLY_VISIBLE',
                      properties: {
                        dependendFieldId: 'application-typeKey',
                        value: {
                          notIncludes: ['ibtm-domain.application.type.spo-ue-launch']
                        }
                      },
                      nodes: [
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId:
                              'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-concernPermission'
                          }
                        }
                      ]
                    }
                  ],
                  'application-section-end': [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId:
                          'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-transitFromOutsideEu'
                      }
                    },
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-term'
                      }
                    },
                    {
                      typeKey: 'CONDITIONALLY_VISIBLE',
                      properties: {
                        dependendFieldId: 'application-typeKey',
                        value: {
                          includes: [
                            'ibtm-domain.application.type.spo-ue-modification-without-printing',
                            'ibtm-domain.application.type.spo-ue-modification'
                          ]
                        }
                      },
                      nodes: [
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId:
                              'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-modificationTypeKeys'
                          }
                        }
                      ]
                    },
                    {
                      typeKey: 'CONDITIONALLY_VISIBLE',
                      properties: {
                        dependendFieldId: 'application-typeKey',
                        value: {
                          includes: ['ibtm-domain.application.type.spo-ue-extend']
                        }
                      },
                      nodes: [
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'application-extended-spo-applications'
                          }
                        }
                      ]
                    },
                    {
                      typeKey: 'CONDITIONALLY_VISIBLE',
                      properties: {
                        dependendFieldId: 'application-typeKey',
                        value: {
                          includes: ['ibtm-domain.application.type.spo-ue-duplicate-of-extract']
                        }
                      },
                      nodes: [
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'licenseApplicationDetails-permissionApplicationDetails-permissionCopyDuplicates'
                          }
                        }
                      ]
                    }
                  ],
                  'application-terms-section': [
                    {
                      typeKey: 'CONDITIONALLY_VISIBLE',
                      properties: {
                        dependendFieldId: 'application-statusKey',
                        value: {
                          notIncludes: ['ibtm-domain.application.status.issued']
                        }
                      },
                      nodes: [
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId:
                              'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-permissionValidityPeriodKey',
                            label: 'Ważność zezwolenia (w latach)',
                            yupValidation: {
                              required: true
                            }
                          }
                        }
                      ]
                    },
                    {
                      typeKey: 'CONDITIONALLY_VISIBLE',
                      properties: {
                        dependendFieldId: 'application-statusKey',
                        value: {
                          includes: ['ibtm-domain.application.status.issued']
                        }
                      },
                      nodes: [
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId:
                              'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-permissionValidityPeriodKey-view',
                            label: 'Ważność zezwolenia (w latach)'
                          }
                        }
                      ]
                    },
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId:
                          'permissionApplicationDetails-permissionApplicationDetails-wprApplicationDetails-copies'
                      }
                    },
                    {
                      typeKey: 'CONDITIONALLY_VISIBLE',
                      properties: {
                        dependendFieldId: 'application-typeKey',
                        value: {
                          includes: [
                            'ibtm-domain.application.type.spo-ue-duplicate-of-extract',
                            'ibtm-domain.application.type.spo-ue-duplicate-permit'
                          ]
                        }
                      },
                      nodes: [
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'application-permissionDuplicate-causeDependentOnSubjectKey'
                          }
                        }
                      ]
                    }
                  ],
                  'application-associated-applications-section': [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'application-linked-spo-applications'
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-subject-tab',
                additionalNodes: {
                  'application-section-end': [
                    {
                      typeKey: 'COMPONENT',
                      properties: {
                        componentId: 'application-subject-print-data-permission-section'
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-addresses-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-partner-applicants-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-proxies-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-transit-schedule-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-cabotage-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-quotation-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-note-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-files-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-history-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-permissions-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-outgoing-documents-tab',
                additionalNodes: {
                  'outgoing-documents-additional-section': [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'application-outgoing-documents-additional-section-license-or-permission',
                        sectionTypes: [
                          AdditionalSectionLicenseOrPermissionType.PERMISSION,
                          AdditionalSectionLicenseOrPermissionType.LICENSE,
                          AdditionalSectionLicenseOrPermissionType.DRIVER_CERTIFICATE
                        ]
                      }
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ]
};

export default definition;
