import { InsurancePolicySearchFilter as InsurancePolicySearchClientFilter } from '@ibtm/client-domain';
import { InsurancePolicySearchFilter } from '@ibtm/domain';

import { useTableLayoutProps } from '@libs/common/v2';

import { useInsurancePoliciesQuery } from '@libs/domain/folder';

import useInsurancePoliciesColumns from './useInsurancePoliciesColumn';

const useInsurancePoliciesTable = (queryParams: InsurancePolicySearchFilter | InsurancePolicySearchClientFilter) => {
  const columns = useInsurancePoliciesColumns();
  return useTableLayoutProps({
    tableHookQuery: useInsurancePoliciesQuery,
    tableHookOptions: {
      columns
    },
    tableHookQueryInitialParams: {
      ...queryParams
    }
  });
};

export default useInsurancePoliciesTable;
