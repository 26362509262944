import { FocusEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener, InputAdornmentProps, TextFieldProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker, DateTimePicker, DateView, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import moment from 'moment';

import {
  ALTERNATIVE_DATE_FORMAT,
  DATE_TIME_WITH_COMMA_MASK,
  TextInput,
  Theme,
  useDetectTouchScreen
} from '@libs/common/v2';

import { useDateLayoutStyles } from '../styles/useDateLayoutStyles';

import datepickerTranslations from './datepickerTranslations';
import InputButtons from './InputButtons';

export interface IProps {
  onChange?: (date: string | moment.Moment) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  label?: string;
  tooltip?: string;
  minDate?: any;
  maxDate?: any;
  /*
   * dokumentacja formatów
   * https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
   */
  format?: string;
  displayFormat?: string;
  inputClassName?: string;
  textInputClassName?: string;
  views?: DateView[];
  value?: string | moment.Moment;
  helperText?: string;
  isClearable?: boolean;
  isFullWidth?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  isWarning?: boolean;
  isFreeToFill?: boolean;
  isDateTimePicker?: boolean;
  hasErrorTooltip?: boolean;
}

function DatepickerInput({
  onChange,
  onBlur,
  label,
  tooltip,
  minDate,
  maxDate,
  format,
  displayFormat,
  inputClassName,
  textInputClassName,
  value,
  helperText,
  isClearable,
  isFullWidth = true,
  isRequired,
  isDisabled,
  views,
  isError,
  isWarning,
  isFreeToFill,
  isDateTimePicker,
  hasErrorTooltip
}: IProps) {
  const classes = useStyles();
  const [t] = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [isErrorActive, setIsErrorActive] = useState<boolean>(isError);
  const [currentHelperText, setCurrentHelperText] = useState<string>(helperText);
  const { isTouchScreen } = useDetectTouchScreen();
  const layoutProps = useDateLayoutStyles();

  useEffect(() => {
    setIsErrorActive(isError);
  }, [isError]);

  useEffect(() => {
    setCurrentHelperText(helperText);
  }, [helperText]);

  const inputFormat = format || ALTERNATIVE_DATE_FORMAT;
  const inputMask = inputFormat.replace(/[a-zA-Z]/g, '_');

  const datepickerInputProps = useMemo(() => {
    if (!views) {
      return {
        format: displayFormat || inputFormat,
        mask: isDateTimePicker ? DATE_TIME_WITH_COMMA_MASK : inputMask
      };
    }
    if (views && views.includes('month')) {
      return { format: 'M', mask: '__' };
    }

    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [views]);

  const handleOnChange = date => {
    if (!date) {
      return onChange(null);
    }

    if (views?.includes('month')) {
      const parsedNumber = parseInt(date._i);
      if (parsedNumber > 12) {
        return onChange(value);
      }
    }

    const parsedDate = moment(date);

    if (views) {
      return onChange(parsedDate);
    }

    if (isDateTimePicker) {
      return onChange(moment(parsedDate.toISOString()).format(inputFormat));
    }

    return onChange(parsedDate.format(inputFormat));
  };

  const DateComponent = useMemo(() => (isDateTimePicker ? DateTimePicker : DatePicker), [isDateTimePicker]);

  const translations = useMemo(() => datepickerTranslations(t, isDateTimePicker), [t, isDateTimePicker]);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
          <DateComponent
            localeText={translations}
            className={clsx(classes.root, inputClassName)}
            views={views}
            onChange={handleOnChange}
            onClose={() => setOpen(false)}
            value={value && typeof value !== 'object' ? moment(value) : null}
            label={label}
            {...(minDate && { minDate: moment(minDate) })}
            {...(maxDate && { maxDate: moment(maxDate) })}
            open={isOpen}
            disabled={isDisabled || !isFreeToFill}
            {...datepickerInputProps}
            slots={{ textField: TextInput }}
            reduceAnimations
            slotProps={{
              layout: layoutProps,
              // @ts-ignore niepoprawnie bierze typ z biblioteki @enigma/fe-ui
              textField: props =>
                ({
                  ...props,
                  tooltip,
                  fullWidth: isFullWidth,
                  required: isRequired,
                  className: textInputClassName,
                  error: isErrorActive,
                  isWarning,
                  helperText: currentHelperText,
                  onBlur: e => {
                    if (!isTouchScreen) {
                      setOpen(false);
                    }
                    onBlur?.(e);
                  },
                  onClick: e => {
                    if (isTouchScreen) {
                      setOpen(true);
                    }
                    e.preventDefault();
                  },
                  onChange: (val, context) => {
                    if (context?.validationError) {
                      setIsErrorActive(true);
                      if (context?.validationError === 'invalidDate') {
                        setCurrentHelperText(t('validation:dateTypeError'));
                      }
                    } else {
                      setCurrentHelperText('');
                      setIsErrorActive(false);
                      handleOnChange(val?._d);
                    }
                  },
                  hasErrorTooltip
                } as unknown as Partial<TextFieldProps>),
              inputAdornment: {
                position: 'end',
                component: () => (
                  <InputButtons
                    onClearClick={() => onChange(null)}
                    onOpenClick={() => setOpen(true)}
                    isDisabled={isDisabled || !isFreeToFill}
                    showClearButton={isClearable && !isEmpty(value)}
                  />
                )
              } as Partial<InputAdornmentProps>
            }}
          />
        </LocalizationProvider>
      </div>
    </ClickAwayListener>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    borderRadius: theme.borderRadiusBase,
    '& .MuiDateTimePickerTabs-root': {
      backgroundColor: 'transparent'
    },
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent'
    },
    '& .MuiPickersCalendarHeader-monthText': {
      marginRight: theme.marginBase
    },
    '& .MuiPickersDay-today:not(.Mui-selected)': {
      border: `1px solid ${theme.palette.grey[300]}`
    }
  }
}));

export default DatepickerInput;
