import { PricingItemDetails } from '@ibtm/domain';

import { useDialog } from '@libs/common/v2';

import { PricingItemEditDialog } from '../components';

function usePricingItemEditDialog() {
  const { openDialog } = useDialog();

  const showDialog = ({
    pricingId,
    data,
    onSuccess
  }: {
    pricingId: string;
    data: PricingItemDetails;
    onSuccess: () => void;
  }) => {
    openDialog(({ closeDialog }) => (
      <PricingItemEditDialog
        onCloseDialog={closeDialog}
        onSuccess={onSuccess}
        pricingId={pricingId}
        data={data}
        isOpen
      />
    ));
  };

  return { showPricingItemEditDialog: showDialog };
}

export default usePricingItemEditDialog;
