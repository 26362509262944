import { QueryConfig, useQuery } from 'react-query';
import { ProceedingDetails } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig, ProceedingQueryKeysEnum } from '..';

const getProceedingDetails = async (_, proceedingId: string) => {
  const { data } = await API.proceeding.getProceeding(proceedingId, { ...endpointsConfig.getProceeding });
  return data;
};

const useProceedingDetailsQuery = (proceedingId: string, queryConfig: QueryConfig<any, unknown> = {}) => {
  return useQuery<ProceedingDetails>(
    [ProceedingQueryKeysEnum.PROCEEDING_DETAILS, proceedingId],
    getProceedingDetails,
    queryConfig
  );
};

export default useProceedingDetailsQuery;
