import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { Dialog, useRouter } from '@libs/common/v2';

import { useSendToForeignPermissionsDepartmentMutation } from '@libs/domain/application';

import {
  PrintActionType,
  usePrintsActionsContext,
  usePrintsActionsDataContext,
  usePrintsDetailsDataContext
} from '../../../context';

function SendForForeignPermissionDepartmentDialog() {
  const [t] = useTranslation();
  const { actionType, actionData } = usePrintsActionsDataContext();
  const { finishAction } = usePrintsActionsContext();
  const { printDetailsData } = usePrintsDetailsDataContext();
  const { mutate, isLoading } = useSendToForeignPermissionsDepartmentMutation();
  const { showSuccessSnackbar } = useSnackbar();
  const { goToPage, routes } = useRouter();
  return (
    <Dialog
      title={t('prints:messages.sendToForeignPermissionsDepartment')}
      confirmText={t('action.confirm')}
      cancelText={t('action.cancel')}
      contentText={t('prints:messages.sendToForeignPermissionsDepartmentDescription')}
      onConfirm={() => {
        mutate(printDetailsData.applicationId, {
          onSuccess: () => {
            actionData.onSuccess();
            goToPage(routes.printsList());
            showSuccessSnackbar(t('prints:messages.sendToForeignDepartmentSuccess'));
            finishAction();
          }
        });
      }}
      onCancel={finishAction}
      isConfirmLoading={isLoading}
      isOpen={actionType === PrintActionType.SendToForeignPermissionDepartment}
    />
  );
}

export default SendForForeignPermissionDepartmentDialog;
