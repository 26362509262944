import { QueryCache } from 'react-query';
import { NavigateFunction } from 'react-router-dom';
import { AccessTokenCreateRequest, AccessTokenDetailsExternal } from '@avispon/auth/dist/models';
import { DictionaryEntryDetails, DictionaryEntrySnapshotLanguage } from '@avispon/dictionary/dist/models';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import moment from 'moment';
import { Dispatch } from 'redux';

import { i18n } from '@libs/common';
import { TSystemNotificationContext } from '@libs/common/v2';
import { AxiosErrorResponseType } from '@libs/common/v2/models';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '@libs/common/v2/utils';
import { EventEmitter } from '@libs/common/v2/utils/emitter.utils';

import { AuthDictionaryEntryNameEnum, AuthLocalStorageItem, AutoLogoutStorageItemEnum } from '@libs/auth/models';
import { API as DictionaryApi, DictionaryEntryNameStatusEnum } from '@libs/dictionary';

import { API } from '../api';
import { LOGIN_SSO_URL } from '../configs';
import { AuthEventsEnum } from '../models/auth-events.model';
import { ErrorCodesEnum } from '../models/error-codes.model';

import AuthStorageService from './AuthStorageService';

type ToastrProps = (message: string, duration?: number) => void;
interface ErrorViolation {
  field: string;
  message: string;
  type: string;
}

interface CustomAxiosResponse<T = any> extends AxiosResponse<T> {
  config: CustomAxiosRequestConfig;
}

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  translationKey?: string;
}

class AuthService extends EventEmitter {
  private logoutWarningIntervalID: NodeJS.Timeout;

  private errorDictionary: Record<string, string> | DictionaryEntryDetails;

  private disableAutoLogout: boolean;

  private violationDictionary: any;

  private dispatch: Dispatch;

  public isTokenRefreshing: boolean;

  public ignoredCodes: string[];

  public autologin?: boolean;

  public alreadyLogout?: boolean;

  private systemNotificationContext?: TSystemNotificationContext;

  private platform: string;

  private readonly _tokenExpirationDate: string;

  private readonly _authTokenPayload: AccessTokenDetailsExternal;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private showErrorToastr?: ToastrProps;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private showWarningToastr?: ToastrProps;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private showInfoToastr?: ToastrProps;

  private navigate?: NavigateFunction;

  private brodcastLogout?: BroadcastChannel;

  private _sessionTimeInMinutes: number;

  private _secondsBeforeSessionEndingWarningDisplay = 30;

  constructor() {
    super();
    this.logoutWarningIntervalID = undefined;
    this.errorDictionary = null;
    this.isTokenRefreshing = false;
    this.dispatch = null;
    this.ignoredCodes = [];
    this.autologin = true;
    this.alreadyLogout = false;
    this.platform = null;
    this.showErrorToastr = null;
    this.showWarningToastr = null;
    this.showInfoToastr = null;
    this.disableAutoLogout = false;
    this.navigate = null;
    this._tokenExpirationDate = AuthStorageService.getTokenExpirationDate();
    this._authTokenPayload = AuthStorageService.getAuthTokenPayload();
  }

  init(
    dispatch: Dispatch,
    platform: string,
    autoLogin?: boolean,
    ignoredCodes?: string[],
    systemNotificationContext?: TSystemNotificationContext,
    showErrorToastr?: ToastrProps,
    showWarningToastr?: ToastrProps,
    showInfoToastr?: ToastrProps,
    disableAutoLogout?: boolean,
    navigate?: NavigateFunction
  ) {
    this.dispatch = dispatch;
    this.ignoredCodes = ignoredCodes;
    this.disableAutoLogout = disableAutoLogout;
    this.systemNotificationContext = systemNotificationContext;
    this.platform = platform;
    this.showErrorToastr = showErrorToastr;
    this.showWarningToastr = showWarningToastr;
    this.showInfoToastr = showInfoToastr;
    this.setInterceptors();
    this.initErrorDictionaries();
    this.handleAuthentication(autoLogin);
    this.navigate = navigate;
    this.brodcastLogout = new BroadcastChannel('logout');
  }

  get sessionTimeInMinutes(): number {
    return this._sessionTimeInMinutes;
  }

  get secondsBeforeSessionEndingWarningDisplay(): number {
    return this._secondsBeforeSessionEndingWarningDisplay;
  }

  setLogoutBroadcast = (onmessage: (e: MessageEvent<any>) => void) => {
    if (!this.brodcastLogout) {
      this.brodcastLogout = new BroadcastChannel('logout');
    }

    this.brodcastLogout.addEventListener('message', onmessage);
  };

  sendLogoutBroadcast = () => {
    this.brodcastLogout.postMessage(sessionStorage.getItem('sessionId'));
  };

  clearSessionStorage = () => {
    const sessionToken = sessionStorage.getItem('sessionId');
    sessionStorage.clear();
    sessionStorage.setItem('sessionId', sessionToken);
  };

  get tokenExpirationDate(): string {
    return this._tokenExpirationDate;
  }

  get authTokenPayload(): AccessTokenDetailsExternal {
    return this._authTokenPayload;
  }

  setInterceptors = () => {
    axios.interceptors.response.use((response: CustomAxiosResponse): any => {
      const message = response?.data?.message;

      if (message) {
        this.handle202Error(message);
      }
      return response;
    }, this.handleResponseError);
  };

  handleAuthentication = autoLogin => {
    if (autoLogin === false) {
      this.emit(AuthEventsEnum.LOGIN);
    } else {
      const authTokenPayload = AuthStorageService.getAuthTokenPayload();

      if (!authTokenPayload.access_token) {
        this.emit(AuthEventsEnum.NO_ACCESS_TOKEN);

        return;
      }

      if (this.isAuthTokenValid()) {
        this.setSession(authTokenPayload, true);
        this.emit(AuthEventsEnum.AUTO_LOGIN, true);
      } else {
        this.handleTokenRefresh(authTokenPayload);
      }
    }
  };

  handleTokenRefresh = async (authTokenPayload: AccessTokenDetailsExternal) => {
    if (this.isTokenRefreshing) {
      return;
    }
    if (AuthService.refreshTokenExists(authTokenPayload)) {
      this.isTokenRefreshing = true;
      try {
        await this.tryRefreshToken(authTokenPayload.refresh_token);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      this.isTokenRefreshing = false;
    } else if (!window.location?.pathname?.endsWith('/login')) {
      this.emit(AuthEventsEnum.AUTO_LOGOUT);
      clearInterval(this.logoutWarningIntervalID);
    }
  };

  setAutoLoginWarning = (sessionTimeInMinutes: number) => {
    clearInterval(this.logoutWarningIntervalID);
    this._sessionTimeInMinutes = sessionTimeInMinutes;
    const renderWarningDelayInSeconds = 1;
    const sessionExpirationDateInSeconds = sessionTimeInMinutes * 60;
    const warningTime =
      Number(getLocalStorageItem(AutoLogoutStorageItemEnum.IDLE_START_TIME)) +
      (sessionExpirationDateInSeconds - this._secondsBeforeSessionEndingWarningDisplay - renderWarningDelayInSeconds) *
        1000;

    if (sessionExpirationDateInSeconds > this._secondsBeforeSessionEndingWarningDisplay) {
      this.logoutWarningIntervalID = setInterval(() => {
        const isSessionExpired =
          Date.now() >=
          Number(getLocalStorageItem(AutoLogoutStorageItemEnum.IDLE_START_TIME)) +
            sessionExpirationDateInSeconds * 1000;

        if (Date.now() >= warningTime && !isSessionExpired) {
          clearInterval(this.logoutWarningIntervalID);
          const authTokenPayload = AuthStorageService.getAuthTokenPayload();
          if (AuthService.refreshTokenExists(authTokenPayload)) {
            this.emit(AuthEventsEnum.AUTO_LOGOUT_WARNING);
          }
        }
      }, 100);
    }
  };

  handleLoginSuccess = (
    accessTokenDetails: AxiosResponse<AccessTokenDetailsExternal>,
    resolve: (value: unknown) => void
  ) => {
    this.setSession(accessTokenDetails.data, true);
    this.alreadyLogout = false;
    resolve(accessTokenDetails);
    this.emit(AuthEventsEnum.LOGIN);
  };

  handleLoginError = (error: Error, reject: (reason?: Error) => void) => {
    // eslint-disable-next-line no-console
    console.error('Login error:', error);
    reject(new Error('Failed to login with token.'));
  };

  loginSSO = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
    return new Promise(resolve => {
      API.auth
        .loginSSO()
        .then(response => {
          this.handleLoginSuccess(response, resolve);
          onSuccess?.();
        })
        .catch(() => {});
    });
  };

  login = (credentials: AccessTokenCreateRequest) => {
    return new Promise((resolve, reject) => {
      API.auth.default
        .createToken({ body: credentials })
        .then(response => {
          this.handleLoginSuccess(response, resolve);
        })
        .catch(error => {
          this.handleLoginError(error, reject);
        });
    });
  };

  logout = (queryCache: QueryCache, redirectOptions = null) => {
    const accessToken = AuthStorageService.getAuthTokenPayload()?.access_token ?? '';

    this.setSession(null);

    this.navigate?.('/login', { state: redirectOptions });
    if (accessToken) {
      return new Promise((resolve, reject) => {
        API.auth.default
          .revoke({ authorization: accessToken })
          .then(response => {
            this.emit(AuthEventsEnum.LOGOUT);
            clearInterval(this.logoutWarningIntervalID);
            queryCache.clear();
            resolve(response);
          })
          .catch(() => {
            reject(new Error('Failed to delete token.'));
          });
      });
    }
    return Promise.resolve();
  };

  tryRefreshToken = (refreshToken: string) => {
    return API.auth.default
      .refresh(
        { body: { refreshToken } },
        {
          headers: {
            Authorization: ''
          }
        }
      )
      .then(response => {
        this.setSession(response.data, true);
        this.emit(AuthEventsEnum.AUTO_LOGIN, true);
      })
      .catch(() => {
        if (!this.alreadyLogout) {
          this.alreadyLogout = true;
          this.emit(AuthEventsEnum.AUTO_LOGOUT, i18n.t('login:messages.error.sessionExpired'));
          setLocalStorageItem(AuthLocalStorageItem.SSO_LOGIN_PROCES_STARTED, null);
          clearInterval(this.logoutWarningIntervalID);
        }
      });
  };

  setSession = (authTokenPayload: AccessTokenDetailsExternal, saveToLocalStorage?: boolean) => {
    if (authTokenPayload && authTokenPayload.access_token) {
      if (saveToLocalStorage) {
        AuthStorageService.setAuthTokenPayload(authTokenPayload);
      }
      axios.defaults.headers.common['Authorization'] = `Bearer ${authTokenPayload.access_token}`;
      axios.defaults.headers.common['platform'] = this.platform;
    } else {
      this.destroySession();
    }
  };

  destroySession() {
    AuthStorageService.removeAuthTokenPayload();
    delete axios.defaults.headers.common['Authorization'];

    axios.defaults.headers.common['platform'] = this.platform;
    removeLocalStorageItem(AutoLogoutStorageItemEnum.OVERRIDDEN_TIME);
  }

  isAuthTokenValid = (): boolean => {
    if (this.tokenExpirationDate) {
      return moment().isBefore(moment(this.tokenExpirationDate));
    }

    return false;
  };

  static refreshTokenExists = (authTokenPayload: AccessTokenDetailsExternal): boolean => {
    return Boolean(authTokenPayload.refresh_token);
  };

  initErrorDictionaries = () => {
    DictionaryApi.dictionary
      .getTranslationDetails({
        dictionary: AuthDictionaryEntryNameEnum.ERROR,
        language: DictionaryEntrySnapshotLanguage.pl,
        status: DictionaryEntryNameStatusEnum.ALL
      })
      .then(response => {
        this.errorDictionary = response.data.content.reduce(
          (obj, item) => Object.assign(obj, { [item.key]: item.value }),
          {}
        );
      })
      .catch(() => {
        this.errorDictionary = null;
      });

    DictionaryApi.dictionary
      .getTranslationDetails({
        dictionary: AuthDictionaryEntryNameEnum.VIOLATION,
        language: DictionaryEntrySnapshotLanguage.pl,
        status: DictionaryEntryNameStatusEnum.ALL
      })
      .then(response => {
        this.violationDictionary = response.data.content.reduce((acc, { key, value }) => {
          const dictionaryEntryDetails: DictionaryEntryDetails = acc;
          dictionaryEntryDetails[key] = value;
          return acc;
        });
      })
      .catch(() => {
        this.violationDictionary = null;
      });
  };

  handleResponseError = async (error: AxiosErrorResponseType) => {
    const originalRequest = error.config;
    const statusCode = error?.response?.status;
    const codes = error?.response?.data?.codes as string[];
    if (codes) {
      if (codes.some(code => this.ignoredCodes.includes(code))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ error, codes });
      }
    }

    if (statusCode) {
      switch (statusCode) {
        case 401: {
          if (originalRequest.url.endsWith('/tokens/refreshes')) {
            break;
          } else if (!this.isTokenRefreshing) {
            if (!this.disableAutoLogout) {
              await this.handleUnauthorizedError();
            } else {
              this.destroySession();
            }
          }
          break;
        }
        case 422: {
          this.handle422Error({
            errorViolations: error?.response?.data?.violations as ErrorViolation[],
            url: originalRequest.url,
            message: error?.response?.data?.message
          });
          break;
        }
        case 413: {
          this.handle413Error();
          break;
        }
        default: {
          this.handleDefaultError(statusCode, error?.response?.data?.codes as string[], originalRequest.url);
        }
      }
    } else if (error?.config?.url !== LOGIN_SSO_URL) {
      this.showErrorToastr(i18n.t('error.serverNotReachable'));
    }

    return Promise.reject(error);
  };

  handleUnauthorizedError = async () => {
    await this.handleTokenRefresh(AuthStorageService.getAuthTokenPayload());
  };

  handle422Error = ({
    errorViolations,
    url,
    message
  }: {
    errorViolations: Array<ErrorViolation>;
    url: string;
    message: string;
  }): void => {
    const getViolation = (type: string): string => this.violationDictionary[type] ?? type;
    const messages = (errorViolations || [])
      ?.map(el => {
        return `- Pole: ${el.field} - błąd: ${getViolation(el.type)}`;
      })
      .join('\n');
    const error = {
      statusCode: '422',
      url
    };

    if (messages) {
      this.showWarningToastr(i18n.t('error.constraintsError', { ...error, fields: messages }), 20000);
    } else if (message) {
      this.showWarningToastr(message, 20000);
    }
    this.emit(AuthEventsEnum.ERROR_MESSAGE, { ...error, message: messages });
  };

  handle413Error = (): void => {
    this.showWarningToastr(i18n.t('error.uploadedObjectTooLarge'), 20000);
  };

  handle202Error = (message: string): void => {
    this.showInfoToastr(message, 20000);
  };

  handleDefaultError = (statusCode: string | number, codes: string[], url: string): void => {
    if (codes && codes.length && this.errorDictionary) {
      if (codes.includes(ErrorCodesEnum.ACCESS_DENIED)) {
        this.showErrorToastr(this.errorDictionary[ErrorCodesEnum.ACCESS_DENIED]);
        return this.emit(ErrorCodesEnum.ACCESS_DENIED);
      }
      codes.forEach((code: string): void => {
        if (code === ErrorCodesEnum.ACCESS_DENIED) {
          return;
        }

        this.showErrorToastr(this.errorDictionary[code] ?? i18n.t('error.default', { statusCode, code, url }));

        this.emit(code);
      });
      const message = codes
        .map(code => this.errorDictionary[code] ?? i18n.t('error.default', { statusCode, code, url }))
        .join('\n');
      return this.emit(AuthEventsEnum.ERROR_MESSAGE, {
        statusCode,
        url,
        message
      });
    }
    this.showErrorToastr(i18n.t('error.defaultServerError', { statusCode, url }));
    return this.emit(AuthEventsEnum.ERROR_MESSAGE, {
      statusCode,
      url,
      message: i18n.t('error.defaultServerError', { statusCode, url })
    });
  };

  getAuthorizationHeader = (): string => {
    const accessToken = AuthStorageService.getAuthTokenPayload().access_token;

    return `Bearer ${accessToken}`;
  };
}

const instance = new AuthService();

export default instance;
