import { QueryConfig, useQuery } from 'react-query';

import { API } from '@libs/domain/api';

import { IAssociationOrderDetailsClient } from '../../../model';
import { parseResourceObjectOrder } from '../../../utils';
import { endpointsConfig } from '../../endpoints-config';
import { ResourceQueryKeysEnum } from '..';

export const getResourceObjectOrder = async (resourceObjectOrderId: string) => {
  const { data } = await API.resourceObjectsOrders.getResourceObjectOrder(resourceObjectOrderId, {
    ...endpointsConfig.getResourceObjectOrder
  });

  return parseResourceObjectOrder(data);
};

const useResourceObjectsOrdersQuery = (orderId: string, queryConfig?: QueryConfig<any, unknown>) =>
  useQuery<IAssociationOrderDetailsClient>(
    [ResourceQueryKeysEnum.ASSOCIATION_ORDER_DETAILS, orderId],
    () => getResourceObjectOrder(orderId),
    queryConfig
  );

export default useResourceObjectsOrdersQuery;
