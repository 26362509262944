import { Path } from '@libs/common/v2';

const splitedId = <T extends Record<string, any>>(id: Path<T>): string | string[] => {
  if (!id.includes('.')) {
    return id;
  }
  return id.split('.');
};

/**
 * Funkcja służy do wydobycia wartości z wiersza do wyrenderowania na podstawie przekazanej ścieżki w modelu.
 * Wykorzystana w utility createTableColumn.
 */
export const getPropertyFromRow = <T extends Record<string, any>>(row, id: Path<T>) => {
  const pathToProp = splitedId(id);
  let property = row;
  if (typeof pathToProp === 'string') {
    return property[pathToProp];
  }
  while (pathToProp.length > 0) {
    const currPropPath = pathToProp.shift();
    property = property?.[currPropPath];
  }
  return property;
};
