import React, { FocusEventHandler, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, OnFilterChange } from 'react-table';
import { makeStyles } from '@mui/styles';

import { AutocompleteSelect, Chip, SelectOption, useHandleClearFilters } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

interface ICustomInputStyle {
  paddingRight?: string;
  paddingRightWhenValue?: string;
  marginRight?: string;
}

interface IProps {
  column: Column & { filterValue?: SelectOption | SelectOption[]; setFilter: (value: string) => void };
  options: SelectOption[];
  onInputChange?: (value) => void;
  isMultiple?: boolean;
  isLoading?: boolean;
  onFocus?: FocusEventHandler<HTMLDivElement>;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  hasClearIcon?: boolean;
  getOptionSelected?: (option: Record<string, any>, value: Record<string, any>) => boolean;
  shouldCheckVisibility?: boolean;
  isFetchedDynamically?: boolean;
  onFilterChange: OnFilterChange;
  customInputStyle?: ICustomInputStyle;
  renderOption?: (props: Record<string, any>, option: any) => React.ReactNode;
}

function SelectFilter({
  column: { filterValue, setFilter },
  onInputChange,
  isMultiple = true,
  hasClearIcon = true,
  getOptionSelected,
  shouldCheckVisibility,
  isFetchedDynamically,
  onFilterChange,
  customInputStyle,
  ...restProps
}: IProps) {
  const [t] = useTranslation();
  const initialValue = () => {
    if (!filterValue) {
      return isMultiple ? [] : null;
    }
    if (Array.isArray(filterValue)) {
      const filteredValue = filterValue.map(item => restProps.options.find(el => el.value === item.value));
      return isMultiple ? filteredValue : filteredValue[0];
    }
    return restProps.options.find(item => item.value === filterValue.value);
  };
  const [value, setValue] = useState(initialValue);
  const onChange = v => {
    setFilter(v);
    onFilterChange?.();
  };
  const classes = useStyles({ value, isMultiple, customInputStyle });

  const handleClearInput = useCallback(() => {
    setValue(isMultiple ? [] : null);
  }, [isMultiple]);

  useHandleClearFilters(handleClearInput);

  const handleChange = (e, newValue) => {
    setValue(newValue);
    onChange(newValue || null);
  };

  // eslint-disable-next-line consistent-return
  const renderTags = (valueToRender, getTagProps) => {
    if (Array.isArray(valueToRender)) {
      if (valueToRender.length > 1) {
        return (
          <>
            <Chip themeVariant="textXs.medium" title={valueToRender[0]?.name || ''} {...getTagProps({ index: 0 })} />
            <p className="hidden-selected-values">...</p>
          </>
        );
      }
      return <Chip themeVariant="textXs.medium" title={valueToRender[0]?.name || ''} {...getTagProps({ index: 0 })} />;
    }
  };

  const handleOnDelete = () => {
    const emptyValue = isMultiple ? [] : null;
    onInputChange?.('');
    setValue(emptyValue);

    setTimeout(() => {
      setFilter(emptyValue);
      onFilterChange?.();
    }, 0);
  };

  const selectProps = onInputChange
    ? {
        onInputChange: (e, val) => onInputChange?.(val),
        onChange: handleChange
      }
    : { onChange: handleChange };

  const getTextFieldPlaceholder = () => {
    if (Array.isArray(value)) {
      return value.length ? '' : t('action.select');
    }
    return value ? '' : t('action.select');
  };

  return (
    <AutocompleteSelect
      onChange={handleChange}
      value={value}
      className={classes.root}
      adornmentClassName={classes.adornment}
      TextFieldProps={{
        placeholder: getTextFieldPlaceholder()
      }}
      renderTags={renderTags as any}
      isMultiple={isMultiple}
      isClearable={hasClearIcon}
      onDelete={handleOnDelete}
      getOptionSelected={getOptionSelected}
      shouldCheckVisibility={shouldCheckVisibility}
      isFetchedDynamically={isFetchedDynamically}
      isSmallPopupIcon
      {...selectProps}
      {...restProps}
    />
  );
}

const useStyles = makeStyles<
  Theme,
  { customInputStyle?: ICustomInputStyle; value: SelectOption[] | SelectOption | string; isMultiple: boolean }
>(theme => ({
  root: {
    '& .MuiAutocomplete-clearIndicator, &.Mui-focused .MuiAutocomplete-clearIndicator': {
      visibility: important('visible'),
      height: important('20px'),
      width: important('20px'),
      marginRight: '3px',
      top: '1px'
    },
    '& .MuiAutocomplete-endAdornment': {
      right: important('10px'),
      top: 'unset'
    },
    '& .MuiAutocomplete-popupIndicatorOpen, & .MuiAutocomplete-popupIndicator': {
      padding: 0,
      marginRight: 0
    },
    '& > label > div:nth-child(2) > div ': {
      padding: important(0),
      marginTop: important(0),
      minHeight: 'unset',
      flexWrap: 'nowrap',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      '& .MuiAutocomplete-tag': {
        maxWidth: '60%',
        '& > span': {
          width: '80%',
          paddingLeft: 10,
          paddingRight: 0,
          marginRight: 10
        }
      },
      '& .hidden-selected-values': {
        margin: 0,
        ...theme.typography.textXs.medium
      },
      '& > input': {
        height: '100%',
        minWidth: important(0),
        padding: ({ isMultiple, value, customInputStyle }) => {
          const paddingRight = customInputStyle?.paddingRight;
          const paddingRightWhenValue = customInputStyle?.paddingRightWhenValue;
          if (!Array.isArray(value) && value) {
            return important(`9.5px ${paddingRightWhenValue || '0'} 9.5px 8px`);
          }
          if (Array.isArray(value) && value?.length) {
            return important(`0px ${paddingRightWhenValue || '0px'} 0px 0px`);
          }
          return isMultiple
            ? important(`9.5px ${paddingRight || '40px'} 9.5px 8px`)
            : important(`9.5px  ${paddingRight || '0'} 9.5px 8px`);
        },
        fontSize: important(theme.typography.textXs.normal.fontSize),
        fontWeight: important(theme.typography.textXs.normal.fontWeight),
        lineHeight: important(theme.typography.textXs.normal.lineHeight),
        marginRight: ({ customInputStyle }) => customInputStyle?.marginRight || undefined
      }
    }
  },
  adornment: {
    position: 'absolute',
    top: 18,
    right: 0,
    transform: 'none'
  }
}));

export default React.memo(SelectFilter);
