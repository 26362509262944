import { FocusEventHandler, useContext } from 'react';
import { Controller, ControllerFieldState } from 'react-hook-form';
import { PageAccountLite } from '@avispon/user';
import { AxiosResponse } from 'axios';

import { AutocompleteLazyFetchSelect, FormV2Context, SelectOption } from '@libs/common/v2';

interface IProps {
  name: string;
  label: string;
  getOptionLabel: (option: SelectOption) => string;
  fetchNameOptionsFunction: (params: string, page: number) => Promise<AxiosResponse<PageAccountLite>>;
  fetchedDataSelectParser: (response: { data: PageAccountLite }) => SelectOption[];
  queryKey: string;
}

function AutocompleteLazyFetchSelectField({
  name,
  getOptionLabel,
  label,
  fetchNameOptionsFunction,
  fetchedDataSelectParser,
  queryKey
}: IProps) {
  const { control, setValue } = useContext(FormV2Context);

  const onSelectedUserChange = (_, value) => {
    setValue(name, value);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onBlur },
        fieldState
      }: {
        // eslint-disable-next-line react/no-unused-prop-types
        field: { value: SelectOption | SelectOption[] | string; onBlur: FocusEventHandler<HTMLDivElement> };
        // eslint-disable-next-line react/no-unused-prop-types
        fieldState: ControllerFieldState;
      }) => (
        <AutocompleteLazyFetchSelect
          fetchFunctionResolverWithPage={fetchNameOptionsFunction}
          fetchedDataSelectParser={fetchedDataSelectParser}
          queryKey={queryKey}
          name={name}
          getOptionLabel={getOptionLabel}
          renderOption={getOptionLabel}
          label={label}
          value={value}
          onBlur={onBlur}
          isError={!!fieldState.error}
          helperText={fieldState.error?.message}
          onChange={(e, v) => {
            onSelectedUserChange(e, v);
          }}
          isRequired
        />
      )}
    />
  );
}

export default AutocompleteLazyFetchSelectField;
