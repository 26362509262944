import { useMemo } from 'react';
import { Tab as TabMui, Tooltip } from '@mui/material';
import { TabProps } from '@mui/material/Tab';
import { makeStyles } from '@mui/styles';

import { Icon } from '@libs/common/v2';
import { IconType } from '@libs/common/v2/icons';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import { UIElementNameEnum, useElementVisibility } from '@libs/permission';

interface IProps extends TabProps {
  isDisabled?: boolean;
  icon?: IconType;
  tooltip?: string;
  width?: number;
  horizontalWidth?: number;
  isHorizontalTab?: boolean;
  viewName?: UIElementNameEnum;
  isIconTab?: boolean;
}

function Tab({
  isDisabled,
  icon,
  width,
  horizontalWidth = 280,
  isHorizontalTab,
  viewName,
  isIconTab,
  tooltip,
  ...props
}: IProps) {
  const classes = useMuiTabStyles({ width, horizontalWidth, isHorizontalTab, isIconTab });
  const { checkIsElementVisible } = useElementVisibility();
  const isItemVisible = useMemo(() => {
    return !viewName || checkIsElementVisible(viewName);
  }, [checkIsElementVisible, viewName]);

  if (isItemVisible) {
    return tooltip ? (
      <Tooltip title={tooltip} placement="bottom-start">
        <TabMui disabled={isDisabled} classes={classes} {...props} icon={icon && <Icon icon={icon} />} />
      </Tooltip>
    ) : (
      <TabMui disabled={isDisabled} classes={classes} {...props} icon={icon && <Icon icon={icon} />} />
    );
  }
  return null;
}

const useMuiTabStyles = makeStyles<
  Theme,
  { width?: number; horizontalWidth?: number; isHorizontalTab?: boolean; isIconTab?: boolean }
>((theme: Theme) => ({
  root: {
    ...theme.typography.textSm.medium,
    textAlign: 'start',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: ({ isHorizontalTab }) => (isHorizontalTab ? 'center' : 'start'),
    minHeight: 40,
    color: theme.palette.grey[700],
    borderRadius: ({ isIconTab }) => !isIconTab && theme.borderRadiusBase * 0.75,
    minWidth: ({ width, isHorizontalTab }) => (width || isHorizontalTab ? 'max(280px, auto)' : '100%'),
    maxWidth: ({ width, isHorizontalTab, horizontalWidth }) => (width || isHorizontalTab ? horizontalWidth : '100%'),
    opacity: 1,
    '& svg': {
      width: important('24px'),
      height: important('24px'),
      minWidth: important('24px'),
      minHeight: important('24px'),
      margin: important(`0 ${theme.marginBase * 2.33}px 0 0`)
    },
    padding: ({ isIconTab }) => (isIconTab ? '8px 12px 8px 16px' : '8px 12px 8px 20px'),
    '&:hover': {
      backgroundColor: theme.palette.grey[100]
    },
    '&$selected': {
      color: theme.palette.blue[800],
      backgroundColor: theme.palette.blue[50],
      borderRadius: theme.borderRadiusBase / 4
    }
  },
  selected: {
    '& svg': {
      color: theme.palette.blue[800]
    }
  },
  wrapper: {
    alignItems: 'center',
    justifyContent: ({ isHorizontalTab }) => (isHorizontalTab ? 'center' : 'start'),
    textAlign: 'start',
    wordBreak: 'break-word',
    width: '100%',
    minHeight: 24,
    '& > span': {
      marginBottom: important(0),
      display: 'flex',
      alignItems: 'center'
    }
  }
}));

export default Tab;
