import { useTranslation } from 'react-i18next';
import { ColumnTypesEnum, TableFilterMapper, TableSortMapper, useCreateColumns, Value } from '@enigma/fe-ui';
import { PermissionCopySearchFilter } from '@ibtm/domain';

import { SelectOption } from '@libs/common/v2';
import { getCalendarDate } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '../../../config';
import { PermissionCopiesColumnsEnum, PermissionCopySnapshotClient } from '../../model';

const usePermissionCopiesColumnsV2 = ({
  visibleColumns,
  validFromColumnHeader
}: {
  visibleColumns: Array<PermissionCopiesColumnsEnum>;
  validFromColumnHeader?: string;
}) => {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  const { createColumns } = useCreateColumns<PermissionCopySnapshotClient>();

  const columns = createColumns(
    [
      {
        type: ColumnTypesEnum.TEXT,
        id: 'licenseNumber',
        accessor: 'licenseNumber',
        header: t('permission:field.licenseNumber')
      },
      {
        type: ColumnTypesEnum.TEXT,
        id: 'certificateNumber',
        accessor: 'certificateNumber',
        header: t('permission:field.certificateNumber'),
        isFilterable: true,
        isSortable: true
      },
      {
        type: ColumnTypesEnum.TEXT,
        id: 'applicationNumber',
        accessor: 'applicationNumber',
        header: t('permission:field.applicationNumber'),
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'cancellationReason',
        accessor: 'cancellationReason',
        header: t('permission:field.cancellationReason'),
        type: ColumnTypesEnum.DICTIONARY,
        dictionaryKey: DomainDictionaryEnum.PERMISSION_CANCELLATION_REASON,
        columnFormatter: value => (
          <Value
            variant="dictionary"
            dictionaryKey={DomainDictionaryEnum.PERMISSION_CANCELLATION_REASON}
            value={value}
          />
        ),
        columnXLSXFormatter: cancellationReason => {
          return translate(DomainDictionaryEnum.PERMISSION_CANCELLATION_REASON, cancellationReason, t('emptyMark'));
        },
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'cancelledBy',
        accessor: 'cancelledBy',
        header: t('permission:field.cancelledBy'),
        type: ColumnTypesEnum.TEXT,
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'cancellationDate',
        accessor: 'cancellationDate',
        header: t('permission:field.cancellationDate'),
        type: ColumnTypesEnum.DATE,
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'subjectName',
        accessor: 'subjectName',
        header: t('permission:field.subjectName'),
        type: ColumnTypesEnum.TEXT,
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'subjectAddress',
        accessor: 'subjectAddress',
        header: t('permission:field.subjectAddress'),
        type: ColumnTypesEnum.TEXT,
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'permissionNumber',
        accessor: 'permissionNumber',
        header: t('permission:field.permissionNumber'),
        type: ColumnTypesEnum.TEXT,
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'number',
        accessor: 'number',
        header: t('permission:field.permissionCopyNumber'),
        type: ColumnTypesEnum.TEXT,
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'permissionCopyNumber',
        accessor: 'permissionCopyNumber',
        header: t('permission:field.permissionCopyNumber'),
        type: ColumnTypesEnum.TEXT,
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'formNumber',
        accessor: 'formNumber',
        header: t('permission:field.formNumber'),
        type: ColumnTypesEnum.TEXT,
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'printOfDate',
        accessor: 'printOfDate',
        header: t('permission:field.printDate'),
        type: ColumnTypesEnum.DATE
      },
      {
        id: 'dateOfIssue',
        accessor: 'dateOfIssue',
        header: t('permission:field.dateOfIssue'),
        type: ColumnTypesEnum.DATE,
        filter: 'DateRangePicker',
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'dateOfIssueExternal',
        accessor: 'dateOfIssueExternal',
        // TODO
        // accessor:  row => (row.dateOfIssueExternal ? row.dateOfIssueExternal : row.dateOfIssue),
        header: t('permission:field.dateOfIssueExternal'),
        type: ColumnTypesEnum.DATE,
        filter: 'DateRangePicker',
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'validFrom',
        accessor: 'validFrom',
        header: validFromColumnHeader || t('permission:field.validFrom'),
        type: ColumnTypesEnum.DATE,
        filter: 'DateRangePicker',
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'validTo',
        accessor: 'validTo',
        header: t('permission:field.validTo'),
        type: ColumnTypesEnum.DATE,
        filter: 'DateRangePicker',
        isFilterable: true,
        isSortable: true
      },
      // {
      //   id: 'signerName',
      //   accessor: 'signerName',
      //   header: t('permission:field.signerName'),
      //   type: ColumnTypesEnum.MULTI_SELECT,
      //   filter: UserFilterV2
      // },
      {
        id: 'status',
        accessor: 'status',
        header: t('permission:field.status'),
        type: ColumnTypesEnum.DICTIONARY,
        dictionaryKey: DomainDictionaryEnum.PERMISSION_STATUS,
        columnFormatter: value => (
          <Value variant="dictionary" dictionaryKey={DomainDictionaryEnum.PERMISSION_STATUS} value={value} />
        ),
        columnXLSXFormatter: status => {
          return translate(DomainDictionaryEnum.PERMISSION_STATUS, status, t('emptyMark'));
        },
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'transitType',
        accessor: 'transitType',
        header: t('permission:field.transitType'),
        type: ColumnTypesEnum.DICTIONARY,
        dictionaryKey: DomainDictionaryEnum.TRANSIT_TYPE,
        columnFormatter: value => (
          <Value variant="dictionary" dictionaryKey={DomainDictionaryEnum.TRANSIT_TYPE} value={value} />
        ),
        columnXLSXFormatter: transitType => {
          return translate(DomainDictionaryEnum.TRANSIT_TYPE, transitType, t('emptyMark'));
        }
      },
      {
        id: 'printDate',
        accessor: 'validFrom',
        header: t('permission:field.printDate'),
        type: ColumnTypesEnum.DATE,
        filter: 'DateRangePicker'
      }
    ],
    Object.values(PermissionCopiesColumnsEnum).filter(column => !Object.values(visibleColumns).includes(column))
  );

  const mappedFilterFields: TableFilterMapper<PermissionCopySnapshotClient, PermissionCopySearchFilter> = {
    applicationNumber: (applicationNumber: string) => ({
      applicationNumberContains: applicationNumber
    }),
    permissionNumber: (permissionNumber: string) => ({ permissionNumberOrCopyNumberContains: permissionNumber }),
    permissionCopyNumber: (permissionCopyNumber: string) => ({
      permissionNumberOrCopyNumberContains: permissionCopyNumber
    }),
    formNumber: (formNumber: string) => ({ formNumberContains: formNumber }),
    subjectName: (subjectName: string) => ({ subjectNameContains: subjectName }),
    certificateNumber: (certificateNumber: string) => ({ numberContains: certificateNumber }),
    dateOfIssue: ({ from, to }) => ({
      dateOfIssueGreaterThanOrEqual: getCalendarDate(from),
      dateOfIssueLessThanOrEqual: getCalendarDate(to)
    }),
    validFrom: ({ from, to }) => ({
      validFromGreaterThanOrEqual: getCalendarDate(from),
      validFromLessThanOrEqual: getCalendarDate(to)
    }),
    validTo: ({ from, to }) => ({
      validToGreaterThanOrEqual: getCalendarDate(from),
      validToLessThanOrEqual: getCalendarDate(to)
    }),
    cancellationReason: (cancellationReasons: SelectOption<string>[]) => ({
      cancellationReasonKeyIn: cancellationReasons.map(cancellationReason => cancellationReason.value)
    }),
    cancelledBy: (cancelledBy: string) => ({ cancelledByNameContains: cancelledBy }),
    cancellationDate: ({ from, to }) => ({
      cancellationDateGreaterThanOrEqual: getCalendarDate(from),
      cancellationDateLessThanOrEqual: getCalendarDate(to)
    }),
    signerName: (signers: SelectOption<string>[]) => ({ signerIdIn: signers.map(signer => signer.value) }),
    status: (statuses: SelectOption<string> | SelectOption<string>[]) => ({
      statusKeyIn: Array.isArray(statuses) ? statuses.map(status => status.value) : [statuses.value]
    }),
    transitType: (transitTypes: SelectOption<string>[]) => ({
      typeKeyIn: transitTypes.map(transitType => transitType.value)
    }),
    subjectAddress: (subjectAddress: string) => ({ subjectAddressContains: subjectAddress }),
    number: (number: string) => ({ numberContains: number }),
    printDate: ({ from, to }) => ({
      validFromGreaterThanOrEqual: getCalendarDate(from),
      validFromLessThanOrEqual: getCalendarDate(to)
    })
  };

  const mappedSortFields: TableSortMapper<PermissionCopySnapshotClient> = {
    permissionCopyNumber: 'number',
    formNumber: 'form.number',
    signerName: 'signer.name',
    subjectName: 'folder.subject.name',
    status: 'statusKey',
    cancellationReason: 'cancellationReasonKey',
    certificateNumber: 'number',
    transitType: 'transitTypeKey',
    subjectAddress: 'application.subject.mainAddress.shortAddress',
    dateOfIssueExternal: 'dateOfIssue',
    printOfDate: 'printDate',
    printDate: 'validFrom'
  };

  return {
    columns,
    mappedFilterFields,
    mappedSortFields
  };
};

export default usePermissionCopiesColumnsV2;
