import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProceedingKreptdCertificateDetails } from '@ibtm/domain';
import * as Yup from 'yup';

import {
  DatepickerField,
  Dialog,
  FormV2Context,
  GridLayout,
  InputMode,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';

interface IProps {
  initialData: Row<ProceedingKreptdCertificateDetails>;
  onSubmitEdit: (data: ProceedingKreptdCertificateDetails) => void;
  closeDialog: () => void;
}

function CertificatesEditForm({ initialData, closeDialog, onSubmitEdit }: IProps) {
  const [t] = useTranslation();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    reset
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        returnDate: Yup.date().nullable(),
        returnDateNotificationDateOfIts: Yup.date().nullable(),
        sendingDate: Yup.date().nullable(),
        sendDateNotificationDateOfIts: Yup.date().nullable()
      })
    )
  });
  useEffect(() => {
    reset(initialData.original);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);

  return (
    <Dialog
      title={t('roadTransport:dialog.certificateEditTitle')}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={handleSubmit(data => {
        onSubmitEdit(data);
        closeDialog();
      })}
      onCancel={closeDialog}
      isOpen
    >
      <FormV2Context.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }}
      >
        <GridLayout itemProps={{ xs: 12 }}>
          <TextInputField
            name={typedNameV2<ProceedingKreptdCertificateDetails>('number')}
            label={t('roadTransport:fields.certificateNumber')}
            inputMode={InputMode.VIEW}
          />
          <TextInputField
            name={typedNameV2<ProceedingKreptdCertificateDetails>('caseNumber95a')}
            label={t('roadTransport:fields.caseNumber95a')}
            inputMode={InputMode.VIEW}
          />
          <DatepickerField
            name={typedNameV2<ProceedingKreptdCertificateDetails>('returnDate')}
            label={t('roadTransport:fields.returnDate')}
          />
          <DatepickerField
            name={typedNameV2<ProceedingKreptdCertificateDetails>('returnDateNotificationDateOfIts')}
            label={t('roadTransport:fields.returnDateNotificationDateOfIts')}
          />
          <DatepickerField
            name={typedNameV2<ProceedingKreptdCertificateDetails>('sendingDate')}
            label={t('roadTransport:fields.sendingDate')}
          />
          <DatepickerField
            name={typedNameV2<ProceedingKreptdCertificateDetails>('sendDateNotificationDateOfIts')}
            label={t('roadTransport:fields.sendDateNotificationDateOfIts')}
          />
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default CertificatesEditForm;
