import { DriverCertificateSnapshot } from '@ibtm/client-domain';
import { DriverCertificateDetailsExtended } from '@ibtm/domain';

import { parseAddressString } from '@libs/domain/license';

import { DriverCertificateSnapshotClient } from '../model';

export const parseDriverCertificatesList = (
  driverCertificates: DriverCertificateDetailsExtended[] | DriverCertificateSnapshot[]
): DriverCertificateSnapshotClient[] =>
  driverCertificates.map(
    (driverCertificate): DriverCertificateSnapshotClient => ({
      id: driverCertificate.id,
      driverCertificateNumber: driverCertificate.number,
      formNumber: driverCertificate.form?.fullNumber,
      applicationNumber: driverCertificate.application?.number,
      driverName: driverCertificate.driver?.name,
      driverSurname: driverCertificate.driver?.surname,
      driverID: driverCertificate.driver?.id,
      printDate: driverCertificate.printDate,
      validFrom: driverCertificate.validFrom,
      validTo: driverCertificate.validTo,
      dateOfIssueExternal: driverCertificate.dateOfIssueExternal,
      resourceAssignmentRegenerationNeeded: driverCertificate.resourceAssignmentRegenerationNeeded,
      signedBy: driverCertificate.signer?.name,
      status: driverCertificate.statusKey,
      cancellationDate: driverCertificate.cancellationDate,
      cancellationReason: driverCertificate.cancellationReasonKey,
      cancelledBy: driverCertificate.cancelledBy?.name,
      subjectName: driverCertificate.subject?.name,
      subjectAddress: parseAddressString(driverCertificate.subjectAddress),
      driver: driverCertificate.driver,
      version: driverCertificate.version,
      form: driverCertificate.form,
      pendingProceedings: driverCertificate.pendingProceedings
    })
  );
