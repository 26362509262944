import { MoneyTransferApplicationLinkRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

type Params = {
  applicationId: string;
  pricingId: string;
  formData?: MoneyTransferApplicationLinkRequest;
};

function linkMoneyTransfersToApplication({ applicationId, pricingId, formData }: Params) {
  return API.moneyTransfer.linkMoneyTransfersToApplication(applicationId, pricingId, formData, {
    ...endpointsConfig.linkMoneyTransfersToApplication
  });
}

function useLinkMoneyTransfersToApplicationMutation() {
  return useMutation(linkMoneyTransfersToApplication);
}

export default useLinkMoneyTransfersToApplicationMutation;
