import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover, PopoverOrigin } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _ from 'lodash';

import { Button, IconButton, IconType } from '@libs/common/v2';

import { UIElementNameEnum, useElementVisibility } from '@libs/permission';

import { important } from '../../utils';
import Icon from '../icon/Icon';

export interface IButtonAction {
  onClick?: React.MouseEventHandler;
  tooltipTitle?: string;
  label?: string;
  icon?: IconType;
  className?: string;
  classNameWrapper?: string;
  iconClassName?: string;
  isButtonLoading?: boolean;
  isDisabled?: boolean;
  isHidden?: boolean;
  actionKey?: UIElementNameEnum;
  customButton?: (onClose?: () => void) => React.ReactNode;
}

interface IParams {
  handleClose: () => void;
}
interface IProps {
  title?: string;
  buttonType?: 'icon' | 'button';
  isLoading?: boolean;
  actionButtons?: IButtonAction[];
  children?: (params: IParams) => React.ReactNode;
  isNoMargin?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  // Don't if items are empty
  forceRender?: boolean;
  classNames?: {
    iconButton?: string;
    button?: string;
  };
  startIcon?: React.ReactNode;
  icon?: IconType;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  isPrimary?: boolean;
  isSecondary?: boolean;
}

function DropdownButton({
  actionButtons,
  children,
  title,
  buttonType = 'icon',
  variant = 'contained',
  isLoading,
  isNoMargin = true,
  forceRender = false,
  classNames,
  icon = 'MoreVerticalIcon',
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right'
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right'
  },
  isPrimary,
  isSecondary,
  startIcon
}: IProps) {
  const [t] = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles({});
  const { checkIsElementVisible } = useElementVisibility();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const availableActions = useMemo(() => {
    return actionButtons
      ?.filter(({ actionKey }) => !actionKey || checkIsElementVisible(actionKey))
      ?.filter(({ isHidden }) => !isHidden);
  }, [actionButtons, checkIsElementVisible]);

  const shouldRender = useMemo(() => {
    if (forceRender) return true;

    const childrenResult = children?.({ handleClose }) || children;

    if (_.isArray(childrenResult)) {
      return (
        childrenResult.filter(
          button => button && (!button?.props?.actionKey || checkIsElementVisible(button.props.actionKey))
        ).length > 0 || !_.isEmpty(availableActions)
      );
    }

    return children || !_.isEmpty(availableActions);
  }, [availableActions, children, forceRender, checkIsElementVisible]);

  const actionRenderer = (action: IButtonAction, index: number) => {
    if (action.isHidden) {
      return null;
    }

    if (action.customButton) {
      return action.customButton(handleClose);
    }

    return (
      <Button
        key={action.label || action.tooltipTitle || index}
        tooltipTitle={action.tooltipTitle}
        onClick={e => {
          action.onClick(e);
          handleClose();
        }}
        classNameWrapper={action.classNameWrapper}
        iconClassName={clsx(classes.icon, action.iconClassName)}
        className={clsx(classes.button, action.className)}
        icon={action.icon}
        iconHeight={24}
        iconWidth={24}
        isLoading={action.isButtonLoading}
        disabled={action.isDisabled || action.isButtonLoading}
        label={action.label}
        variant="text"
        size="small"
        type="button"
        fullWidth
        isNoMargin
      />
    );
  };

  return (
    shouldRender && (
      <>
        {buttonType === 'icon' ? (
          <div>
            <IconButton
              className={clsx(classes.iconButton, classNames?.iconButton)}
              icon={icon}
              title={title || t('action.more')}
              onClick={handleOpen}
              isBackgroundTransparent
            />
          </div>
        ) : (
          <Button
            label={title}
            onClick={handleOpen}
            startIcon={startIcon}
            endIcon={
              !isLoading ? (
                <Icon
                  className={classes?.endButtonIcon}
                  icon="ChevronDownIcon"
                  color={variant !== 'outlined' ? 'white' : null}
                />
              ) : null
            }
            isLoading={isLoading}
            isNoMargin={isNoMargin}
            className={classNames?.button}
            variant={variant}
            size="small"
            isPrimary={isPrimary}
            isSecondary={isSecondary}
          />
        )}
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          classes={{ paper: classes.paper }}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          <div className="flex flex-col">
            {children?.({ handleClose })}
            {availableActions?.map(actionRenderer)}
          </div>
        </Popover>
      </>
    )
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    '& svg': {
      marginRight: 12
    }
  },
  iconButton: {
    [theme.breakpoints.down('md')]: {
      padding: important('0px')
    }
  },
  button: {
    padding: '12px 53px 12px 16px',
    width: '100%',
    justifyContent: 'flex-start',
    borderRadius: 0
  },
  icon: {
    marginRight: 12,
    marginLeft: 0
  },
  endButtonIcon: {
    paddingTop: '4px'
  }
}));

export default DropdownButton;
