import { FormMode, TabContent, TabsContent, useErrorsHandlerV2 } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { ProceedingDetailsTabEnum, RegisteredProceedingFormEnum } from '@libs/domain/proceeding';

import FilesTab from './tabs/files/FilesTab';
import ProceedingDocumentsTable from './tabs/ProceedingDocumentsTable';
import { FormOperationsTab, NoteTab, ProcedureDetails } from './tabs';

function ProceedingDetailsContent({
  formMode,
  activeTab,
  setActiveTab,
  finalDecision
}: {
  formMode: FormMode;
  activeTab: ProceedingDetailsTabEnum;
  setActiveTab: (tab: ProceedingDetailsTabEnum) => void;
  finalDecision?: {
    haveFinalDecision: boolean;
    finalDecisionProcessingDate?: string;
  };
}) {
  const { saveFieldList } = useErrorsHandlerV2<ProceedingDetailsTabEnum, RegisteredProceedingFormEnum>(
    setActiveTab,
    RegisteredProceedingFormEnum.PROCEEDING_FORM
  );

  const { createMode } = useViewModesV2(formMode);

  return createMode ? (
    <ProcedureDetails />
  ) : (
    <TabsContent
      value={activeTab}
      saveFieldList={saveFieldList}
      scrollerPrefixId={RegisteredProceedingFormEnum.PROCEEDING_FORM}
    >
      <TabContent value={ProceedingDetailsTabEnum.PROCEDURE_DETAILS}>
        <ProcedureDetails />
      </TabContent>
      <TabContent value={ProceedingDetailsTabEnum.OUTGOING_DOCUMENTS}>
        <ProceedingDocumentsTable finalDecision={finalDecision} />
      </TabContent>
      <TabContent value={ProceedingDetailsTabEnum.FORM_OPERATIONS}>
        <FormOperationsTab />
      </TabContent>
      <TabContent value={ProceedingDetailsTabEnum.NOTE}>
        <NoteTab formMode={formMode} />
      </TabContent>
      <TabContent value={ProceedingDetailsTabEnum.FILES}>
        <FilesTab />
      </TabContent>
    </TabsContent>
  );
}

export default ProceedingDetailsContent;
