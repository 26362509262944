import { GeneratePermissionsRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export const generatePermissions = async ({
  applicationId,
  generatePermissionRequest
}: {
  applicationId: string;
  generatePermissionRequest: GeneratePermissionsRequest;
}) => {
  const data = await API.application.generatePermissions(applicationId, generatePermissionRequest, {
    ...endpointsConfig.generatePermissions
  });
  return data;
};

function useGeneratePrintMutation() {
  return useMutation(generatePermissions);
}

export default useGeneratePrintMutation;
