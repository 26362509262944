import { DefaultApiAcceptNotificationRequest } from '@avispon/message/api/default-api';
import { AxiosRequestConfig } from 'axios';

import { useMutation } from '@libs/common/v2/api';

import API from '../endpoints';
import { endpointsConfig } from '../endpoints-config';

const acceptNotification = async (requestData: DefaultApiAcceptNotificationRequest) => {
  const { data } = await API.message.acceptNotification(requestData, {
    ...endpointsConfig.create
  } as AxiosRequestConfig);

  return data;
};

export const useAcceptNotificationMutation = () => {
  return useMutation(acceptNotification);
};
