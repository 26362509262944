import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface IParams {
  applicationId: string;
  content: string;
  version: number;
}

async function editApplicationNote({ applicationId, content, version }: IParams) {
  const { data } = await API.applicationNote.updateApplicationNote(
    applicationId,
    { content, version },
    { ...endpointsConfig.updateApplicationNote }
  );

  return data;
}

function useEditApplicationNoteMutation() {
  return useMutation(editApplicationNote);
}

export default useEditApplicationNoteMutation;
