import { useTableLayoutProps } from '@libs/common/v2';

import { ReleasedPermitsSnapshot, SettlementCurrentPermitsData } from '../../model';
import useSettlementCurrentPermitsColumns from '../columns/useSettlementCurrentPermitsColumns';

function useSettlementCurrentPermitsTable(
  data: SettlementCurrentPermitsData[] = [],
  visibleColumns: Array<keyof ReleasedPermitsSnapshot>
) {
  const { columns } = useSettlementCurrentPermitsColumns(visibleColumns);

  return useTableLayoutProps({
    tableQuery: data,
    tableHookOptions: {
      columns,
      tableOptions: {
        manualPagination: false
      }
    }
  });
}

export default useSettlementCurrentPermitsTable;
