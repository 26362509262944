import React from 'react';

import { PermissionEnum } from '@libs/domain/config/permission/PermissionEnum';

const ReportsPage = React.lazy(() => import('./ReportsPage'));
const ReportDetailsPage = React.lazy(() => import('./ReportDetailsPage'));
const ReportTypeEditPage = React.lazy(() => import('./ReportTypeEditPage'));
const ReportTypeCreatePage = React.lazy(() => import('./ReportTypeCreatePage'));

export const ReportsConfig = {
  routes: [
    {
      path: '/reports/report-types/create',
      auth: () => [PermissionEnum.REPORT_REPORT_TYPE_VIEW],
      element: <ReportTypeCreatePage />
    },
    {
      path: '/reports/report-types/:id/edit',
      auth: () => [PermissionEnum.REPORT_REPORT_TYPE_VIEW],
      element: <ReportTypeEditPage />
    },
    {
      path: '/reports/generated/:id/details',
      auth: () => [PermissionEnum.REPORT_REPORT_TYPE_VIEW],
      element: <ReportDetailsPage />
    },
    {
      path: '/reports',
      auth: () => [PermissionEnum.REPORT_REPORT_TYPE_VIEW],
      element: <ReportsPage />
    }
  ]
};
