import { AddressUpdateRequest } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import { GridLayout, InputMode, TextInputField } from '@libs/common/v2';

const getLabel = partialTranslate('address:fields');

interface IProps {
  inputProps?: {
    inputMode?: InputMode;
  };
  getFieldName: (fieldName: keyof AddressUpdateRequest) => string;
  checkIsHiddenField: (fieldName: keyof AddressUpdateRequest) => boolean;
}

function ForeignAddressSection({ inputProps, getFieldName, checkIsHiddenField }: IProps) {
  return (
    <GridLayout itemProps={{ xs: 12, sm: 6 }}>
      {checkIsHiddenField('firstLine') && (
        <TextInputField name={getFieldName('firstLine')} label={getLabel('firstLine')} {...inputProps} />
      )}
      {checkIsHiddenField('secondLine') && (
        <TextInputField name={getFieldName('secondLine')} label={getLabel('secondLine')} {...inputProps} />
      )}
      {checkIsHiddenField('postCode') && (
        <TextInputField name={getFieldName('postCode')} label={getLabel('pnu')} {...inputProps} />
      )}
      {checkIsHiddenField('city') && (
        <TextInputField
          name={getFieldName('city')}
          label={getLabel('city')}
          inputProps={{ maxLength: 50 }}
          {...inputProps}
        />
      )}
    </GridLayout>
  );
}

export default ForeignAddressSection;
