import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { SingleUsePermissionSubmissionDetails } from '@ibtm/domain';

import {
  AdditionalFilterEnum,
  Button,
  TableLayout,
  useConfirmDialog,
  useRouter,
  useTableRowActions
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';
import { DomainUIElementEnum } from '@libs/domain/config';
import {
  ExportCommitteeFileButton,
  ImportComitteeDecisionButton,
  SocialCommissionQueryKeysEnum,
  useCommissionApplicationTable,
  useDeleteSingleUseSubmissionMutation
} from '@libs/domain/social-commission';

import { DisposablePermitsTableEnum } from '../../common/model/social-comission.model';
import { IntialYearType } from '../../SocialCommissionPage';

function SocialCommissionDisposablePermitsTable({
  headerActions,
  globalFilters,
  initialYear
}: {
  tab: string;
  headerActions?: React.ReactNode;
  globalFilters: (name: string) => {
    type: AdditionalFilterEnum;
    name: string;
    customFilter: JSX.Element;
  }[];
  initialYear: IntialYearType;
}) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { routes } = useRouter();
  const [confirm] = useConfirmDialog();
  const { showSuccessSnackbar } = useSnackbar();
  const tableProps = useCommissionApplicationTable(initialYear);
  const { mutate: deleteListItem } = useDeleteSingleUseSubmissionMutation();

  const deleteDialog = (original: SingleUsePermissionSubmissionDetails) => {
    confirm({
      title: t('foreignPermits:other.deleteApplicationTitle'),
      message: t('foreignPermits:other.deleteApplication'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        deleteListItem(original?.id, {
          onSuccess: () => {
            showSuccessSnackbar(t('dialog.deleteSuccess'));
            queryCache.invalidateQueries(SocialCommissionQueryKeysEnum.SINGLE_USE_SUBMISSION_DETAILS);
            closeDialog();
          }
        });
      },
      confirmType: 'danger'
    });
  };

  const { renderTableActions } = useTableRowActions<SingleUsePermissionSubmissionDetails>([
    {
      id: DomainUIElementEnum.COMMISSION_DISPOSABLE_VIEW,
      label: 'action.openDetails',
      link: ({ original }) => routes.socialComissionDisposablePermitDetails(original.id),
      icon: 'ArrowIcon'
    },
    {
      id: DomainUIElementEnum.COMMISSION_DISPOSABLE_EDIT_BUTTON,
      label: 'action.edit',
      icon: 'EditIcon',
      link: ({ original }) => routes.socialComissionDisposablePermitEdit(original.id),
      isDisabled: ({ row: { original } }) => {
        return original?.considered;
      },
      tooltip: ({ original }) =>
        original?.considered
          ? 'foreignPermits:tabs.socialCommission.tabs.socialCommissionDisposablePermits.tooltip.editDipsosablePermitDisabled'
          : null
    },
    {
      id: DomainUIElementEnum.COMMISSION_DISPOSABLE_DELETE_BUTTON,
      label: 'action.delete',
      icon: 'TrashIcon',
      onClick: ({ row: { original } }) => deleteDialog(original),
      isDisabled: ({ row: { original } }) => original?.considered,
      tooltip: ({ original }) =>
        original?.considered
          ? 'foreignPermits:tabs.socialCommission.tabs.socialCommissionDisposablePermits.tooltip.deleteDipsosablePermitDisabled'
          : null
    }
  ]);

  return (
    <TableLayout
      {...tableProps}
      xlsxDownload={{
        fileName: t('foreignPermits:tabs.socialCommission.tabs.socialCommissionDisposablePermits.title'),
        pathToXLSXTranslation: 'foreignPermits:tabs.socialCommission.tabs.socialCommissionDisposablePermits.fields',
        apiRequest: API.civicBoard.getSingleUsePermissionSubmissionSnapshotPage
      }}
      isSectionFullTab
      sectionProps={{ titleHeaderContent: headerActions }}
      headerActions={
        <>
          <ExportCommitteeFileButton actionKey={DomainUIElementEnum.COMISSION_DISPOSABLE_EXPORT} />
          <ImportComitteeDecisionButton actionKey={DomainUIElementEnum.COMISSION_DISPOSABLE_IMPORT} />
          <Button
            label={t('global:action.add')}
            link={routes.socialComissionDisposablePermitCreate()}
            isPrimary
            variant="contained"
            actionKey={DomainUIElementEnum.COMMISSION_DISPOSABLE_ADD_BUTTON}
          />
        </>
      }
      rowActions={renderTableActions([
        DomainUIElementEnum.COMMISSION_DISPOSABLE_VIEW,
        DomainUIElementEnum.COMMISSION_DISPOSABLE_EDIT_BUTTON,
        DomainUIElementEnum.COMMISSION_DISPOSABLE_DELETE_BUTTON
      ])}
      globalFilters={globalFilters(DisposablePermitsTableEnum.YEAR)}
    />
  );
}

export default SocialCommissionDisposablePermitsTable;
