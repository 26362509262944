import { FoldersApiUpdateFolderStatusRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function changeStatus(requestData: FoldersApiUpdateFolderStatusRequest) {
  return API.folder.updateFolderStatus(requestData.folderId, requestData.folderStatusUpdateRequest, {
    ...endpointsConfig.updateFolderStatus
  });
}

function useChangeFolderStatusMutation() {
  return useMutation(changeStatus);
}

export default useChangeFolderStatusMutation;
