import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';

import {
  AutocompleteSelectField,
  Button,
  FormV2Context,
  SelectOption,
  TextInputField,
  useRouter
} from '@libs/common/v2';
import { Theme, useTheme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import { HasPermission } from '@libs/permission';

import { DomainUIElementEnum } from '@libs/domain/config';

import { getResourcesInitialYear, getYearOptions } from '../../../utils';

type SearchFormsFields = {
  year: SelectOption<number>;
  formNumber: number;
};

function SearchFormsForm() {
  const [t] = useTranslation();
  const { contrast } = useTheme();
  const classes = useStyles({ contrast });
  const resourceSearchPermission = DomainUIElementEnum.RESOURCES_OBJECT_SEARCH;
  const { goToPage, routes } = useRouter();
  const yearOptions = useMemo(() => getYearOptions(), []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Partial<SearchFormsFields>>({
    mode: 'onBlur',
    resolver: yupResolver(Yup.object({})),
    defaultValues: {
      year: yearOptions.find(item => item.value === getResourcesInitialYear()),
      formNumber: null
    }
  });

  const onSubmit = (data: Partial<SearchFormsFields>) => {
    const params = new URLSearchParams({
      ...(data?.year ? { year: String(data?.year.value) } : {}),
      ...(data?.formNumber ? { number: String(data?.formNumber) } : {})
    });
    goToPage(`${routes.resourceObjectsList()}?${params.toString()}`);
  };

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <HasPermission actionKeys={[resourceSearchPermission]}>
      <FormV2Context.Provider value={values}>
        <AutocompleteSelectField name="year" options={yearOptions} className={classes.yearField} />
        <TextInputField
          name="formNumber"
          placeholder={t('resource:fields.formNumber')}
          className={classes.formNumberField}
        />
        <Button
          isSecondary
          variant="outlined"
          onClick={handleSubmit(data => onSubmit(data))}
          label={t('action.search')}
        />
      </FormV2Context.Provider>
    </HasPermission>
  );
}
const useStyles = makeStyles<Theme, { contrast?: boolean }>(theme => ({
  yearField: {
    width: 160,
    margin: 8,
    '& > label > div > div': {
      height: 40,
      backgroundColor: 'white',
      margin: important(0)
    }
  },
  formNumberField: {
    width: 170,
    '& > div > div': {
      height: 40,
      backgroundColor: ({ contrast }) => (contrast ? 'none' : 'white'),
      margin: important(0),
      '& input::placeholder': {
        color: ({ contrast }) =>
          contrast ? important(theme.palette.contrastIndicators.background) : important(theme.palette.grey[600]),
        opacity: ({ contrast }) => (contrast ? 1 : 0.42)
      }
    }
  }
}));

export default SearchFormsForm;
