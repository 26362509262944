import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormHelperText } from '@mui/material';
import clsx from 'clsx';

import { Icon, useInputStyles, useValueStyles, ValueSkeleton } from '@libs/common/v2';
import { useTheme } from '@libs/common/v2/theme';

interface IProps {
  value: boolean;
  label?: string;
  className?: string;
  valueTrueText?: string;
  valueFalseText?: string;
  helperText?: string;
  isLoading?: boolean;
  isError?: boolean;
  isWarning?: boolean;
  isTableCell?: boolean;
}

function BooleanValue({
  value,
  label,
  isLoading,
  className,
  valueTrueText,
  valueFalseText,
  helperText,
  isError,
  isWarning,
  isTableCell
}: IProps) {
  const classes = useValueStyles({ booleanValue: value, isTableCell, islabel: !!label });
  const inputClasses = useInputStyles({ isError, isWarning });
  const [t] = useTranslation();
  const { contrast } = useTheme();

  const displayValue = () => {
    if (value === null || value === undefined) {
      return t('emptyMark');
    }
    if (value) {
      return (
        <>
          <Icon icon={contrast ? 'CheckInCircleContrastIcon' : 'CheckInCircleIcon'} className="flex" />
          <span>{valueTrueText || t('global:action.yes')}</span>
        </>
      );
    }
    return (
      <>
        <Icon icon={contrast ? 'CrossInCircleContrastIcon' : 'CrossInCircleIcon'} className="flex" />
        <span>{valueFalseText || t('global:action.no')}</span>
      </>
    );
  };

  return (
    <div className={className}>
      {isLoading ? (
        <ValueSkeleton />
      ) : (
        <>
          {label && <div className={classes.labelWrapper}>{label}</div>}
          <div className={clsx('field-boolean-value', classes.booleanValue)}>{displayValue()}</div>
          {helperText && (
            <FormHelperText className={clsx(inputClasses.formHelperText)} error={isError}>
              {helperText}
            </FormHelperText>
          )}
        </>
      )}
    </div>
  );
}

export default React.memo(BooleanValue);
