import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { SingleUseSubmissionItemSnapshot, SingleUseSubmissionUpdateDecisionRequest } from '@ibtm/domain';

import { useDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import useAddSingleUseDecisionMutation from '../api/mutations/useAddSingleUseDecisionMutation';
import AddDecisionDialog from '../components/common/AddDecisionDialog';
import { SocialCommissionQueryKeysEnum } from '../config';

function useAddDecisionPermitModal({ singleUsePermissionId }: { singleUsePermissionId: string }) {
  const [t] = useTranslation();

  const { openDialog } = useDialog();
  const { showSuccessSnackbar } = useSnackbar();
  const queryCache = useQueryCache();

  const { mutateAsync: addDecision } = useAddSingleUseDecisionMutation({
    onSuccess: () => {
      showSuccessSnackbar(t('success.save'));
    }
  });

  const onSubmit = (
    values: SingleUseSubmissionUpdateDecisionRequest & { singleUseSubmissionItemId: string },
    closeDialog: () => void
  ) => {
    return addDecision(values, {
      onSuccess: () => {
        showSuccessSnackbar(t('success.save'));
      },
      onSettled: () => {
        queryCache.invalidateQueries([
          SocialCommissionQueryKeysEnum.PERMANENT_PERMISSION_SUBMISSION_DETAILS,
          singleUsePermissionId
        ]);
        closeDialog();
      }
    });
  };

  const showAddDecisionPermitModal = (row: SingleUseSubmissionItemSnapshot) => {
    openDialog(({ closeDialog }) => <AddDecisionDialog closeDialog={closeDialog} onSubmit={onSubmit} data={row} />);
  };

  return { showAddDecisionPermitModal };
}

export default useAddDecisionPermitModal;
