import { useTranslation } from 'react-i18next';

import { DictionarySelectField, InputMode, SectionWithFields, TextInputField, typedNameV2 } from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { FolderDetailsClient } from '@libs/domain/folder';

interface IProps {
  hasSubjectName?: boolean;
  isNaturalPerson?: boolean;
}

function SubjectDataSectionDetails({ hasSubjectName, isNaturalPerson }: IProps) {
  const [t] = useTranslation();

  const getLabel = (name: string): string => t<any>(`folder:details.field.${name}`);

  return (
    <SectionWithFields
      title={t('folder:details.section.legalNature')}
      isCollapsable
      fields={[
        <DictionarySelectField
          name={typedNameV2<FolderDetailsClient>('subject.subjectTypeKey')}
          label={t('folder:details.field.subjectType')}
          dictionaryName={DomainDictionaryEnum.SUBJECT_TYPE}
          key="details.field.subjectType"
          stringValue
          inputMode={InputMode.VIEW}
        />,
        <DictionarySelectField
          dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
          name={typedNameV2<FolderDetailsClient>('subject.countryOfOrigin')}
          label={getLabel('countryOfOrigin')}
          stringValue
          inputMode={InputMode.VIEW}
          key="legalNature-countryOfOrigin"
        />,
        <TextInputField
          inputMode={InputMode.VIEW}
          name={typedNameV2<FolderDetailsClient>('subject.nip')}
          label={t('folder:field.subjectNip')}
          key="legalNature-subject.nip"
        />,
        <TextInputField
          inputMode={InputMode.VIEW}
          name={typedNameV2<FolderDetailsClient>('subject.krs')}
          label={getLabel('krs')}
          key="legalNature-krs"
        />,
        <TextInputField
          inputMode={InputMode.VIEW}
          name={typedNameV2<FolderDetailsClient>('subject.regon')}
          label={getLabel('regon')}
          key="legalNature-regon"
        />,
        <DictionarySelectField
          dictionaryName={DomainDictionaryEnum.LEGAL_NATURE}
          name={typedNameV2<FolderDetailsClient>('subject.legalFormKey')}
          label={getLabel('legalFormKey')}
          inputMode={InputMode.VIEW}
          stringValue
          key="legalNature-legalFormKey"
        />,
        hasSubjectName && (
          <TextInputField
            inputMode={InputMode.VIEW}
            name={typedNameV2<FolderDetailsClient>('subject.name')}
            label={getLabel('name')}
            key="legalNature-name"
          />
        ),
        isNaturalPerson && (
          <TextInputField
            inputMode={InputMode.VIEW}
            name={typedNameV2<FolderDetailsClient>('subject.givenName')}
            label={getLabel('subjectName')}
            key="legalNature-givenName"
          />
        ),
        isNaturalPerson && (
          <TextInputField
            inputMode={InputMode.VIEW}
            name={typedNameV2<FolderDetailsClient>('subject.surname')}
            label={getLabel('subjectSurname')}
            key="legalNature-subjectSurname"
          />
        )
      ]}
    />
  );
}

export default SubjectDataSectionDetails;
