import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { FormV2Context, GridLayout, WarningInformation } from '@libs/common/v2';

import { DomainDictionaryEntry, DomainUIElementEnum } from '@libs/domain/config';
import { LicenseCopiesTable } from '@libs/domain/license';

import { IProceedingDetailsClient } from '../../../models';
import { DecisionSection, LicenseCopiesReturn, SecondTierSection, StartSection } from '../sections';

function DischargeAndSuspensionOfCertificatesTab() {
  const [t] = useTranslation();
  const classes = useStyles();
  const { getValues } = useContext(FormV2Context);

  const { id, folderId, fineImposedAvailable } = getValues() as IProceedingDetailsClient;

  return (
    <div className="overflow-hidden">
      {fineImposedAvailable && (
        <WarningInformation
          content={t('roadTransport:messages.fineImposedInformationForDischargeAndSuspension')}
          paperClassName={classes.warning}
          isIconVisible
        />
      )}
      <GridLayout itemProps={{ xs: 12 }}>
        {folderId && (
          <LicenseCopiesTable
            folderId={folderId}
            proceedingId={id}
            actionKey={DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_LICENSE_EXCERPTS}
            defaultFilters={[
              {
                id: 'status',
                value: [
                  { value: DomainDictionaryEntry.PERMISSION_STATUS.ACTIVE },
                  { value: DomainDictionaryEntry.PERMISSION_STATUS.REVOKED }
                ]
              }
            ]}
          />
        )}
        <StartSection hiddenFields={['startRehabilitationResource']} />
        <DecisionSection hiddenFields={['decisionPermissionSuspensionFrom', 'decisionPermissionSuspensionTo']} />
        <SecondTierSection />
        <LicenseCopiesReturn />
      </GridLayout>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  warning: {
    marginTop: 12
  }
}));

export default DischargeAndSuspensionOfCertificatesTab;
