import { DepotTransferChangeStatusCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function changeDepotTransferStatus(requestData: DepotTransferChangeStatusCreateRequest) {
  return API.depotTransfer.changeStatusDepotTransfer(requestData, {
    ...endpointsConfig.changeStatusDepotTransfer
  });
}

function useChangeDepotTransferStatusMutation() {
  return useMutation(changeDepotTransferStatus);
}

export default useChangeDepotTransferStatusMutation;
