import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ForeignPermissionsPrintsSnapshot } from '@ibtm/domain';

import { DictionarySelectField, GridLayout, Radio, SelectOption, typedNameV2, useFormV2Context } from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { ForeignPermissionRegenerateForm } from '@libs/domain/print/model';
import { PoolsForm } from '@libs/domain/resource';

import { RegeneratePermissionDialogLayout } from './common/RegeneratePermissionDialogLayout';
import { ForeignPermissionsNumberSelectField } from './ForeignPermissionsNumberSelectField';

export const getInitialValues = (
  name: string,
  reasonsForReprinting?: SelectOption<string>
): ForeignPermissionRegenerateForm => ({
  pools: [
    {
      name,
      numberFrom: null,
      numberTo: null,
      amount: null
    }
  ],
  selectedNumbers: [],
  reasonsForReprinting
});

interface IRegenerateForeignPermissionContentProps {
  foreignPermissionsPrintsSnapshot: ForeignPermissionsPrintsSnapshot;
}

export function RegenerateForeignPermissionDialogContent({
  foreignPermissionsPrintsSnapshot
}: IRegenerateForeignPermissionContentProps) {
  const [t] = useTranslation();
  const { firstNumber, formAmount, formNameKey, lastNumber } = foreignPermissionsPrintsSnapshot ?? {};
  const [isPoolVisible, setIsPoolVisible] = useState(true);
  const { reset, getValues } = useFormV2Context();

  const handleRadioClick = (value: boolean) => {
    setIsPoolVisible(value);
    const reasonsForReprinting = getValues('reasonsForReprinting') as SelectOption<string>;
    reset(getInitialValues(formNameKey, reasonsForReprinting));
  };
  return (
    <RegeneratePermissionDialogLayout
      formNumbersSection={
        <GridLayout itemProps={{ xs: 12 }}>
          <div className="flex flex-wrap">
            <Radio
              label={t('prints:formField.selectPoolFormNumbers')}
              isChecked={isPoolVisible}
              onChange={() => handleRadioClick(true)}
            />
            <Radio
              label={t('prints:formField.selectSingleFormNumbers')}
              isChecked={!isPoolVisible}
              onChange={() => handleRadioClick(false)}
            />
          </div>

          {isPoolVisible ? (
            <PoolsForm
              inputsProps={{
                numberFrom: { min: firstNumber, max: lastNumber },
                numberTo: { min: firstNumber, max: lastNumber },
                amount: { min: 0, max: formAmount }
              }}
              withWrapper={false}
            />
          ) : (
            <ForeignPermissionsNumberSelectField />
          )}
        </GridLayout>
      }
      reprintSection={
        <DictionarySelectField
          name={typedNameV2<ForeignPermissionRegenerateForm>('reasonsForReprinting')}
          dictionaryName={DomainDictionaryEnum.REASONS_FOR_REPRINTING}
          label={t('prints:formField.rePrintReason')}
          validationSingleSelect={{ required: [] }}
          isRequired
        />
      }
    />
  );
}
