import { TabHeader } from '@libs/common/v2';

import { useFormDirtyContext, useMetaFormContext, useTabContext } from '@libs/meta-form/context';

function MetaFormTabHeader() {
  const { formRef } = useMetaFormContext();
  const { activeTabTitle } = useTabContext();

  return (
    <TabHeader
      activeTabTitle={activeTabTitle}
      handleSubmit={() => formRef?.current?.onSubmit()}
      handleDiscardChanges={() => formRef?.current?.onReset()}
      useFormDirty={useFormDirtyContext}
    />
  );
}

export default MetaFormTabHeader;
