import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FinancialSecurityDetails } from '@ibtm/domain';

import { Dialog, FormMode, FormV2Context, GridItem, GridLayout, Section, useIsSmallScreen } from '@libs/common/v2';
import { RecursivePartial } from '@libs/common/v2/utils';

import { useFinancialSecurityFields } from '../hooks';
import { FinancialSecurityFields } from '../model';

interface IProps {
  financialSecurityId: string;
  onCloseDialog: () => void;
  isOpen: boolean;
  initialData?: RecursivePartial<FinancialSecurityDetails>;
  folderTypeKey: string;
  exchangeRateFrom?: string;
  exchangeRateTo?: string;
}

function FinancialSecurityDetailsDialog({
  isOpen,
  onCloseDialog,
  financialSecurityId,
  initialData,
  folderTypeKey,
  exchangeRateFrom,
  exchangeRateTo
}: IProps) {
  const [t] = useTranslation();
  const { isSmallScreen } = useIsSmallScreen();
  const {
    control,
    register,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    reset
  } = useForm<FinancialSecurityFields>();

  const releaseDate = watch('releaseDate');

  const formValues = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      formMode: FormMode.VIEW
    }),
    [control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting]
  );

  const { renderField } = useFinancialSecurityFields({
    financialSecurityId,
    initialData,
    watch,
    setValue,
    reset,
    trigger,
    folderTypeKey,
    exchangeRateFrom: exchangeRateFrom || releaseDate,
    exchangeRateTo: exchangeRateTo || releaseDate
  });

  return (
    <FormV2Context.Provider value={formValues}>
      <Dialog
        title={t('financialSecurity:action.details.dialogTitle')}
        cancelText={t('action.close')}
        onCancel={onCloseDialog}
        dialogSize="medium"
        isOpen={isOpen}
        isFullScreen={isSmallScreen}
      >
        <Section isCollapsable isModalSection isHeaderVisible={false}>
          <GridLayout itemProps={{ xs: 12, md: 6 }}>
            <GridItem xs={12}>{renderField('typeKey')}</GridItem>
            {renderField('releaseDate')}
            {renderField('expirationDate')}
            {renderField('currencyKey')}
            {renderField('exchangeCurrency')}
            {renderField('amount')}
            {renderField('exchangeAmount')}
            <GridItem xs={12}>{renderField('notes')}</GridItem>
          </GridLayout>
        </Section>
        <Section title={t('financialSecurity:section.exchangeRates')} isCollapsable isModalSection>
          <GridLayout itemProps={{ xs: 12, md: 6 }}>
            {renderField('exchangeRate')}
            {renderField('exchangeRateFromDate')}
          </GridLayout>
        </Section>
        <Section title={t('financialSecurity:section.generalInformation')} isCollapsable isModalSection>
          <GridLayout itemProps={{ xs: 12, md: 6 }}>
            {renderField('created')}
            {renderField('author')}
            {renderField('modified')}
            {renderField('modifier')}
          </GridLayout>
        </Section>
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default FinancialSecurityDetailsDialog;
