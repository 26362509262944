import { DefaultApi as GroupDefaultApi } from '@avispon/group';
import axios from 'axios';

interface GroupApi {
  group: GroupDefaultApi;
}

const groupApiFactoryFallback = () => {
  throw new Error('Group api has not been set');
};

const API: GroupApi = {
  group: groupApiFactoryFallback as never
};

export function setGroupApi(API_PORTAL_URL: string) {
  API.group = new GroupDefaultApi({ isJsonMime: () => true }, API_PORTAL_URL, axios);
}

export default API;
