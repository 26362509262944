import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { DropdownListButton, useConfirmDialog, useTableAdapter, useTableContext } from '@libs/common/v2';

import { useDeleteMessagesByUserMutation } from '@libs/notification/api';

interface IProps {
  selected: string[];
  handleClose: () => void;
}

function MyMessageTableSelectAction({ selected, handleClose }: IProps) {
  const [t] = useTranslation();
  const { toggleAllRowsSelected } = useTableAdapter();
  const { refetch } = useTableContext();
  const [confirm] = useConfirmDialog();
  const { mutate: deleteMessagesByUser } = useDeleteMessagesByUserMutation();
  const { showSuccessSnackbar } = useSnackbar();

  const deleteMessages = useCallback(() => {
    confirm({
      title: t('messages:dialog.deleteItems'),
      message: t('messages:dialog.messagesDeleteConfirm'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        deleteMessagesByUser(selected, {
          onSuccess: () => {
            showSuccessSnackbar(t('dialog.deleteSuccess'));
            toggleAllRowsSelected(false);
            refetch();
            closeDialog();
            handleClose();
          },
          onSettled: () => {
            setConfirmLoading(false);
          }
        });
      },
      confirmType: 'danger'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return <DropdownListButton label={t('action.delete')} onClick={deleteMessages} />;
}

function MySelectionActionRender(selected: string[], handleClose: () => void) {
  return <MyMessageTableSelectAction selected={selected} handleClose={handleClose} />;
}

export default MySelectionActionRender;
