import { createContext, useMemo, useState } from 'react';

import { createCommonPalette, createTheme, PrimaryPalette } from '@libs/common/v2/theme';
import { ThemeFontSizeEnum } from '@libs/common/v2/theme/model';

interface IContext {
  fontSizeZoom: ThemeFontSizeEnum;
  getFontSizeZoom: (fontSize: ThemeFontSizeEnum) => void;
  contrast: boolean;
  setContrast: () => void;
}

export const ThemeContext = createContext<IContext>({
  fontSizeZoom: ThemeFontSizeEnum.NORMAL_FONT,
  getFontSizeZoom: null,
  contrast: false,
  setContrast: null
});

export function useThemeContext(primaryPalette: PrimaryPalette) {
  const [themeContrast, setThemeContrast] = useState<boolean>(false);
  const [themeFontSizeZoom, setThemeFontSizeZoom] = useState<ThemeFontSizeEnum>(ThemeFontSizeEnum.NORMAL_FONT);

  const setContrast = () => setThemeContrast(prev => !prev);
  const setNewThemeFontSizeZoom = (fontSizeZoom: ThemeFontSizeEnum) => setThemeFontSizeZoom(fontSizeZoom);

  const contextValue = {
    contrast: themeContrast,
    setContrast,
    fontSizeZoom: themeFontSizeZoom,
    getFontSizeZoom: setNewThemeFontSizeZoom
  };

  const globalTheme = useMemo(
    () => {
      const palette = createCommonPalette(primaryPalette, themeContrast);

      return createTheme(palette, themeFontSizeZoom);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [themeContrast, themeFontSizeZoom]
  );

  return {
    contextValue,
    globalTheme
  };
}
