import { AxiosRequestConfig } from 'axios';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import {
  MisspellApplicationDataDriverCertificateFormValues,
  PermissionUpdateRequest
} from '../../model/misspell-application.model';
import { parseMisspellDriverCertificateRequest } from '../../utils/application-misspell.util';
import { endpointsConfig } from '../endpoints-config';

const updateApplicationDriverCertificate = async ({
  applicationId,
  permissionUpdateRequest
}: PermissionUpdateRequest<MisspellApplicationDataDriverCertificateFormValues>) => {
  const { data } = await API.applicationMisspellData.updateApplicationDriverCertificate(
    applicationId,
    parseMisspellDriverCertificateRequest(permissionUpdateRequest),
    { ...endpointsConfig.updateApplicationPermission } as AxiosRequestConfig
  );
  return data;
};

export function useUpdateApplicationMisspellDriverCertificateMutation() {
  return useMutation(updateApplicationDriverCertificate);
}
