import { array as YupArray, boolean as YupBoolean, object as YupObject, string as YupString } from 'yup';

import { i18n } from '@libs/common';
import { FormMode, SelectOption, useValidatorUtil, ValidatorEnums } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DomainDictionaryEntry } from '@libs/domain/config';
import { FolderFormDataAddressTypeEnum } from '@libs/domain/folder';

const addresEntryRule = () =>
  YupString()
    .nullable()
    .when('sameAsMainAddress', {
      is: value => !value,
      then: YupString().required()
    });

const addressCorrespondenceEntryRule = () =>
  YupString()
    .nullable()
    .when('sameAsMainAddress', {
      is: value => !value,
      then: YupString()
        .nullable()
        .when('typeKey', (typeKey: SelectOption<string>) => {
          if (typeKey?.value === DomainDictionaryEntry.ADDRESS_TYPE.CORRESPONDENCE) {
            return YupString().nullable();
          }
          return YupString().nullable().required();
        })
    });

const createAddressValidation = (postCodeValidator: [RegExp, string]) => {
  return YupObject().shape({
    sameAsMainAddress: YupBoolean().nullable(),
    postCode: YupString()
      .nullable()
      .when('sameAsMainAddress', {
        is: value => !value,
        then: YupString()
          .required()
          .matches(...postCodeValidator)
      }),
    voivodeshipKey: YupObject()
      .nullable()
      .when('sameAsMainAddress', {
        is: value => !value,
        then: YupObject()
          .nullable()
          .when('typeKey', (typeKey: SelectOption<string>) => {
            if (typeKey?.value === DomainDictionaryEntry.ADDRESS_TYPE.CORRESPONDENCE) {
              return YupObject().nullable();
            }
            return YupObject().nullable().required();
          })
      }),
    county: addressCorrespondenceEntryRule(),
    commune: addressCorrespondenceEntryRule(),
    postCity: addresEntryRule(),
    city: addresEntryRule(),
    street: YupString().nullable(),
    propertyNumber: addresEntryRule(),
    apartmentNumber: YupString()
  });
};

function useFolderValidationSchema(formMode: FormMode) {
  const { createMode } = useViewModesV2(formMode);

  const useValidate = (validator: ValidatorEnums): [RegExp, string] => useValidatorUtil(validator);
  const emailValidator = useValidate(ValidatorEnums.EMAIL);
  const postCodeValidator = useValidate(ValidatorEnums.ADDRESS_POSTAL_CODE);

  const addresses = YupObject({
    [FolderFormDataAddressTypeEnum.MAIN]: createAddressValidation(postCodeValidator),
    [FolderFormDataAddressTypeEnum.HQ]: createAddressValidation(postCodeValidator),
    [FolderFormDataAddressTypeEnum.CORRESPONDENCE]: createAddressValidation(postCodeValidator)
  });
  const getSubjectSchema = (isCountryRequired = false) => {
    return YupObject({
      legalFormKey: YupString().nullable().required(),
      ...(isCountryRequired ? { countryOfOrigin: YupString().nullable().required() } : {}),
      regon: YupString()
        .nullable()
        .test('required', i18n.t('validation:required'), function checkIsRequired(value) {
          if (!this?.parent?.countryOfOrigin) {
            return value?.length;
          }
          return (
            (this?.parent?.countryOfOrigin !== DomainDictionaryEntry.COUNTRY_CODE.POLAND && !value?.length) ||
            value?.length
          );
        })
        .test('length', i18n.t('validation:fieldLength', { length: 9 }), function checkIsLength(value: string) {
          return (
            (this?.parent?.countryOfOrigin !== DomainDictionaryEntry.COUNTRY_CODE.POLAND && !value?.length) ||
            value?.length === 9
          );
        }),
      nip: YupString()
        .nullable()
        .test('required', i18n.t('validation:required'), function checkIsRequired(value) {
          if (!this?.parent?.countryOfOrigin) {
            return value?.length;
          }
          return (
            (this?.parent?.countryOfOrigi !== DomainDictionaryEntry.COUNTRY_CODE.POLAND && !value?.length) ||
            value?.length
          );
        })
        .test('length', i18n.t('validation:fieldLength', { length: 10 }), function checkIsLength(value: string) {
          return (
            (this?.parent?.countryOfOrigin !== DomainDictionaryEntry.COUNTRY_CODE.POLAND && !value?.length) ||
            value?.length === 10
          );
        })
        .test('match', i18n.t('folder:validation.valueCannotStartWith0'), function checkIsMatch(value: string) {
          return (
            (this?.parent?.countryOfOrigin !== DomainDictionaryEntry.COUNTRY_CODE.POLAND && !value?.length) ||
            /^([^0].*$)/.test(value)
          );
        }),
      krs: YupString()
        .nullable()
        .test('required', i18n.t('validation:required'), function checkIsRequired(value) {
          return (
            (!(
              !this?.parent?.countryOfOrigin ||
              (this?.parent?.countryOfOrigin === DomainDictionaryEntry.COUNTRY_CODE.POLAND &&
                this?.parent?.legalFormKey !== DomainDictionaryEntry.LEGAL_NATURE_KEY.NATURAL_PERSON)
            ) &&
              !value?.length) ||
            value?.length
          );
        })
        .test('length', i18n.t('validation:fieldLength', { length: 10 }), function checkIsLength(value: string) {
          return (
            (!(
              !this?.parent?.countryOfOrigin ||
              (this?.parent?.countryOfOrigin === DomainDictionaryEntry.COUNTRY_CODE.POLAND &&
                this?.parent?.legalFormKey !== DomainDictionaryEntry.LEGAL_NATURE_KEY.NATURAL_PERSON)
            ) &&
              !value?.length) ||
            value?.length === 10
          );
        }),
      name: YupString().max(500).required(),
      givenName: YupString().when('legalFormKey', (legalFormKey: string) => {
        if (legalFormKey === DomainDictionaryEntry.LEGAL_NATURE_KEY.NATURAL_PERSON) {
          return YupString().required().max(50);
        }
        return YupString().max(50);
      }),
      surname: YupString().when('legalFormKey', (legalFormKey: string) => {
        if (legalFormKey === DomainDictionaryEntry.LEGAL_NATURE_KEY.NATURAL_PERSON) {
          return YupString().required().max(80);
        }
        return YupString().max(80);
      }),
      contactAddresses: YupArray().of(
        YupObject().shape({
          phone: YupString()
            .nullable()
            .test(
              'lengthNotRequired',
              i18n.t('validation:fieldLength', { length: 9 }),
              (val: string) => !val || val.length === 9
            ),
          email: YupString()
            .nullable()
            .matches(...emailValidator)
        })
      )
    });
  };

  return createMode
    ? YupObject({
        typeKey: YupObject().nullable().required(),
        subject: YupObject().when('typeKey', {
          is: (typeKey: SelectOption<string>) => typeKey?.value === DomainDictionaryEntry.FOLDER_TYPE.MZ,
          then: getSubjectSchema(true),
          otherwise: getSubjectSchema()
        }),
        addresses: YupObject().when('subject.countryOfOrigin', {
          is: (countryOfOrigin: string) => countryOfOrigin === DomainDictionaryEntry.COUNTRY_CODE.POLAND,
          then: addresses
        })
      })
    : YupObject({
        subject: YupObject({
          contact: YupObject({
            email: YupString()
              .nullable()
              .matches(...emailValidator),
            phoneNumber: YupString()
              .nullable()
              .test(
                'lengthNotRequired',
                i18n.t('validation:fieldLength', { length: 9 }),
                (val: string) => !val || val.length === 9
              )
          })
        }),
        addresses: YupObject().when('subject.countryOfOrigin', {
          is: countryOfOrigin => countryOfOrigin === DomainDictionaryEntry.COUNTRY_CODE.POLAND,
          then: addresses
        })
      });
}

export default useFolderValidationSchema;
