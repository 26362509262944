import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function restoreFinancialSecurity({ financialSecurityId, version }: { financialSecurityId: string; version: number }) {
  return API.financialSecurity.restoreFinancialSecurity(
    financialSecurityId,
    { version },
    {
      ...endpointsConfig.restoreFinancialSecurity
    }
  );
}

function useFinancialSecurityRestoreMutation() {
  return useMutation(restoreFinancialSecurity);
}

export default useFinancialSecurityRestoreMutation;
