import { SingleUsePermissionSubmissionUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function updateTableRow(submitData: {
  singleUsePermissionId: string;
  requestData: SingleUsePermissionSubmissionUpdateRequest;
}) {
  return API.civicBoard.updateSingleUsePermissionSubmission(submitData.singleUsePermissionId, submitData.requestData, {
    ...endpointsConfig.updateSingleUsePermissionSubmission
  });
}

function useUpdateSingleUseSubmissionMutation() {
  return useMutation(updateTableRow);
}

export default useUpdateSingleUseSubmissionMutation;
