import { EkmtCarnetSettlementDetails } from '@ibtm/domain';

import { ColumnTypesEnum, useCreateColumns } from '@libs/common/v2';
import { getValue } from '@libs/common/v2/utils';

const usePermitEkmtItemsColumns = () => {
  const { createColumns } = useCreateColumns<EkmtCarnetSettlementDetails>({
    pathToTranslate: 'foreignPermits:tabs.releasedPermits.ekmtDetails.settlementEKMT.formFields'
  });

  const columns = createColumns([
    {
      id: 'numberFrom',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      width: 200
    },
    {
      id: 'numberTo',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      width: 200
    },
    {
      id: 'returnDate',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      width: 200
    },
    {
      id: 'ridesCount',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      width: 200
    },
    {
      id: 'ridesWithoutCargoCount',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      width: 200
    },
    {
      id: 'bilateralRidesCount',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      width: 200
    },
    {
      id: 'multilateralRidesCount',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      width: 200
    },
    {
      id: 'ridesBetweenEuCount',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      width: 200
    },
    {
      id: 'ridesBetweenAndOutsideEuCount',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      width: 200
    },
    {
      id: 'ridesOutsideEuCount',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      width: 200
    },
    {
      id: 'ridesBetweenRuAndPlCount',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      width: 200
    },
    {
      id: 'ridesBetweenRuAndOtherCount',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      width: 200
    },
    {
      id: 'ridesInAndOutPlCount',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      width: 200
    },
    {
      id: 'ridesBetweenUkAndOtherCount',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      width: 200
    },
    {
      id: 'ridesBetweenUkAndPlCount',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      width: 200
    },
    {
      id: 'missingPageNumbers',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      customAccessor: ({ missingPageNumbers }) => getValue(missingPageNumbers?.toString()),
      width: 200
    }
  ]);

  return { columns };
};

export default usePermitEkmtItemsColumns;
