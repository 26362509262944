import { SubjectLiteExtended } from '@ibtm/client-domain';

import { TableSortMapper, useCreateColumns } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

import PrartnershipTypeChip from '../PrartnershipTypeChip';

const usePartnershipColumns = () => {
  const { translate } = useDictionaryTranslations();

  const { createColumns } = useCreateColumns<SubjectLiteExtended>({
    pathToTranslate: 'user:field.partnership'
  });

  const mappedSortFields: TableSortMapper<SubjectLiteExtended> = {
    name: 'subjectName',
    nip: 'subjectNip',
    folderNumber: 'subject.folderNumber'
  };

  const columns = createColumns([
    {
      type: 'TEXT',
      accessor: 'folder.number'
    },
    {
      type: 'TEXT',
      accessor: 'name'
    },
    {
      type: 'TEXT',
      accessor: 'nip',
      width: 120
    },
    {
      type: 'TEXT',
      accessor: 'folder.typeKey',
      customAccessor: ({ folder }) => <PrartnershipTypeChip folderTypeKey={folder?.typeKey} />,
      tooltipTitle(row) {
        return translate(DomainDictionaryEnum.FOLDER_TYPE, row?.folder?.typeKey);
      },
      width: 200
    }
  ]);

  return { columns, mappedSortFields };
};

export default usePartnershipColumns;
