import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MutationResultPair } from 'react-query';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  SubjectContactAddressUpdateRequest as SubjectContactAddressUpdateRequestClient,
  SubjectDetails as SubjectDetailsClient
} from '@ibtm/client-domain';
import { SubjectContactAddressUpdateRequest, SubjectDetails } from '@ibtm/domain';
import { AxiosResponse } from 'axios';
import { object as YupObject, string as YupString } from 'yup';

import { partialTranslate } from '@libs/common';
import {
  Dialog,
  FormMode,
  FormV2Context,
  GridLayout,
  TextInputField,
  typedNameV2,
  useValidatorUtil,
  ValidatorEnums
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { SubjectQueryKeysEnum } from '@libs/domain/subject';

type ContactData = {
  phoneNumber?: string;
  email?: string;
};

interface IProps {
  subjectData: {
    id?: string;
    version?: number;
    contact?: ContactData;
  };
  updateQuery: () => MutationResultPair<
    AxiosResponse<SubjectDetailsClient | SubjectDetails>,
    unknown,
    { subjectId: string; formData: SubjectContactAddressUpdateRequestClient | SubjectContactAddressUpdateRequest },
    unknown
  >;
  onClose: () => void;
  onSuccess: () => void;
}

function ContactDataEditDialog({ subjectData, updateQuery, onClose, onSuccess }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const getLabel = partialTranslate('subject:contactDataUpdateModal.field');

  const [updateSubjectContactData, { isLoading }] = updateQuery();

  const useValidate = (validator: ValidatorEnums): [RegExp, string] => useValidatorUtil(validator);

  const validationSchema = YupObject<ContactData>({
    phoneNumber: YupString().required(),
    email: YupString()
      .required()
      .matches(...useValidate(ValidatorEnums.EMAIL))
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    reset,
    getValues,
    trigger,
    unregister
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: {
      phoneNumber: subjectData?.contact?.phoneNumber,
      email: subjectData?.contact?.email
    },
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (values: Partial<ContactData>) => {
    updateSubjectContactData(
      {
        subjectId: subjectData?.id,
        formData: {
          phone: values.phoneNumber,
          email: values.email,
          version: subjectData?.version
        }
      },
      {
        onSuccess: ({ data }) => {
          showSuccessSnackbar(t('subject:contactDataUpdateModal.message.contactDataUpdated'));
          onSuccess();
          onClose();
          queryCache.setQueryData([SubjectQueryKeysEnum.SUBJECT_EXTENDED, data.id], data);
        }
      }
    );
  };

  const values = useMemo(
    () => ({
      formMode: FormMode.EDIT,
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      reset,
      handleSubmit
    }),
    [control, errors, getValues, handleSubmit, isSubmitting, register, reset, setValue, trigger, unregister, watch]
  );

  return (
    <FormV2Context.Provider value={values}>
      <Dialog
        title={t('subject:contactDataUpdateModal.title')}
        confirmText={t('action.save')}
        cancelText={t('action.close')}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={onClose}
        dialogSize="small"
        isConfirmLoading={isLoading}
        isConfirmButtonDisabled={isLoading}
        isOpen
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <GridLayout itemProps={{ xs: 12 }}>
            <TextInputField name={typedNameV2<ContactData>('phoneNumber')} label={getLabel('phoneNumber')} isRequired />
            <TextInputField
              name={typedNameV2<ContactData>('email')}
              label={getLabel('email')}
              isRequired
              validation={{ contextValidator: [ValidatorEnums.EMAIL] }}
            />
          </GridLayout>
        </form>
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default ContactDataEditDialog;
