import { useTranslation } from 'react-i18next';
import { VehicleDetails } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  DictionarySelectField,
  GridLayout,
  InputMode,
  Section,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';
import { convertDateToDateTimeFormat } from '@libs/common/v2/utils';

import { DomainDictionaryEnum } from '@libs/domain/config';

const getLabel = partialTranslate('vehicle:field');

function GeneralInformationSection() {
  const [t] = useTranslation();
  const { VIEW } = InputMode;
  return (
    <Section title={t('vehicle:details.section.generalInformation')} isModalSection>
      <GridLayout itemProps={{ xs: 12, sm: 6 }}>
        <DatepickerField
          name={typedNameV2<VehicleDetails>('created')}
          label={getLabel('created')}
          viewModeDateParser={convertDateToDateTimeFormat}
          inputMode={VIEW}
        />

        <TextInputField name={typedNameV2<VehicleDetails>('author.name')} label={getLabel('author')} inputMode={VIEW} />

        <DatepickerField
          name={typedNameV2<VehicleDetails>('modified')}
          label={getLabel('modified')}
          viewModeDateParser={convertDateToDateTimeFormat}
          inputMode={VIEW}
        />

        <TextInputField
          name={typedNameV2<VehicleDetails>('modifier.name')}
          label={getLabel('modifier')}
          inputMode={VIEW}
        />

        <DictionarySelectField
          dictionaryName={DomainDictionaryEnum.VEHICLE_STATUS}
          name={typedNameV2<VehicleDetails>('statusKey')}
          label={getLabel('statusKey')}
          inputMode={VIEW}
        />
      </GridLayout>
    </Section>
  );
}

export default GeneralInformationSection;
