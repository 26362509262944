import { useMemo } from 'react';

import { DictionaryEntryNameEnum, useContextSelector } from '@libs/dictionary';

function useDictionaryTranslations() {
  const dictionaryData = useContextSelector(store => store.context.context.data.dictionary);

  return useMemo(
    () => ({
      // Zwraca gotowe tłumaczenia na podstawie nazwy słownika i klucza elementu tego słownika
      translate: (dictionaryEntryType: DictionaryEntryNameEnum, dictionaryItemKey: string, defaultValue = '') => {
        return dictionaryData?.[dictionaryEntryType]?.translations?.[dictionaryItemKey] ?? defaultValue;
      },
      checkTranslationAvailable: (dictionaryEntryType: DictionaryEntryNameEnum, dictionaryItemKey: string) => {
        return Boolean(dictionaryData?.[dictionaryEntryType]?.translations?.[dictionaryItemKey]);
      },
      // Zwraca obiekt z tłumaczeniami z danego słownika
      getDictionaryTranslator: (dictionaryEntryType: DictionaryEntryNameEnum) => {
        return dictionaryData?.[dictionaryEntryType]?.translations ?? {};
      }
    }),
    [dictionaryData]
  );
}

export default useDictionaryTranslations;
