import {
  CreateBulkNotificationCommandChannels,
  PullNotificationSnapshotReason,
  PullNotificationSnapshotStatus,
  PullNotificationSnapshotType
} from '@avispon/message';

export enum RecipientType {
  USER = 'user',
  ALL_IN_SELECTED_PERMISSION_GROUP = 'allInSelectedPermissionGroup'
}

export enum StatusColorType {
  RED,
  YELLOW,
  GREY,
  LIGHTGREEN,
  GREEN
}

export const MESSAGE_STATUS_COLOR_CONFIG = [
  { status: PullNotificationSnapshotStatus.READ, color: StatusColorType.GREY },
  { status: PullNotificationSnapshotStatus.ACCEPTED, color: StatusColorType.LIGHTGREEN },
  { status: PullNotificationSnapshotStatus.ACTIVE, color: StatusColorType.GREEN },
  { status: PullNotificationSnapshotStatus.INACTIVE, color: StatusColorType.YELLOW }
];

export const DEFAULT_GET_NOTIFICATIONS_PAGE = 0;
export const DEFAULT_GET_NOTIFICATIONS_SIZE = 10;

export type AdminMessageParams = {
  id: string;
};

export const INITIAL_VALUES = {
  id: '',
  bulkId: '',
  subject: '',
  message: '',
  status: '' as PullNotificationSnapshotStatus,
  created: '',
  validUntil: null,
  blocking: false,
  channels: [CreateBulkNotificationCommandChannels.PULL],
  recipients: {
    accountSelector: {
      recipient: 'users',
      accountIds: [],
      groupIds: []
    },
    emails: []
  },
  reason: PullNotificationSnapshotReason.TECHNICAL,
  type: PullNotificationSnapshotType.NOTIFICATION
};
