import { usePaginatedQuery } from 'react-query';
import {
  SubjectHistoryDetails as SubjectHistoryDetailsClient,
  SubjectHistorySearchFilter as SubjectHistorySearchFilterClient
} from '@ibtm/client-domain';
import { SubjectHistoryDetails, SubjectHistorySearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { FolderQueryKeysEnum } from '@libs/domain/folder';

import { endpointsConfig } from '../endpoints-config';

interface GetSubjectHistoryParams extends SubjectHistorySearchFilter {
  subjectId: string;
}

const getSubjectHistory = async (_, { subjectId, ...params }: GetSubjectHistoryParams) => {
  const { data } = await API.subject.getSubjectHistoryPage(subjectId, params, {
    ...endpointsConfig.getSubjectHistoryPage
  });
  return data;
};

const getSubjectHistoryClient = async (_, params: SubjectHistorySearchFilterClient) => {
  const { data } = await API.client.subject.getSubjectHistoryPage('', '', params, {
    ...endpointsConfig.getSubjectHistoryPage
  });
  return data;
};

export default function useSubjectHistoryQuery(params: GetSubjectHistoryParams) {
  const { getQuery } = useGetApiQueryByPerspective();
  return usePaginatedQuery<IPaginatedModel<SubjectHistoryDetails | SubjectHistoryDetailsClient>>(
    [FolderQueryKeysEnum.SUBJECT_HISTORY_LIST, params],
    getQuery(getSubjectHistoryClient, getSubjectHistory)
  );
}
