import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

async function createReputation(params: { id: string }) {
  const { data } = await API.transportManager.createReputationRequest(params.id, {
    ...endpointsConfig.createReputationRequest
  });
  return data;
}

function useCreateReputationRequestMutation() {
  return useMutation(createReputation);
}

export default useCreateReputationRequestMutation;
