import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { CivicBoardCalculateFreeDisposalLimitRequest } from '@ibtm/domain';

import { useDialog } from '@libs/common/v2';

import useCalculateLimitForSpecificFolderMutation from '../api/mutations/useCalculateLimitForSpecificFolderMutation';
import useCalculateLimitFreeDisposalForFolderMutation from '../api/mutations/useCalculateLimitFreeDisposalForFolderMutation';
import DialogSelectFolderForCalculation from '../components/pages/social-commission-basic-rules/DialogSelectFolderForCalculation';

function useDialogSelectFolderForCalculation(
  rule: {
    year: number;
    resourceTypeId: string;
  },
  isTradeRules = false
) {
  const [t] = useTranslation();
  const { openDialog } = useDialog();
  const { showSuccessSnackbar } = useSnackbar();

  const { mutateAsync: calculateForFolder, isLoading } = useCalculateLimitForSpecificFolderMutation();
  const { mutateAsync: calculateTradeRulesLimitForFolder, isLoading: isLoadingRules } =
    useCalculateLimitFreeDisposalForFolderMutation();

  const submitCalculateForFolder = (
    formData: Partial<CivicBoardCalculateFreeDisposalLimitRequest & { folder: { id: string } }>,
    closeDialog
  ) => {
    if (!isTradeRules) {
      return calculateForFolder(
        {
          folderId: formData?.folder?.id,
          resourceTypeId: rule?.resourceTypeId
        },
        {
          onSuccess: () => {
            closeDialog?.();
            showSuccessSnackbar(
              t('foreignPermits:tabs.socialCommission.tabs.SocialCommissionBasicRules.messages.added')
            );
          }
        }
      );
    }
    return calculateTradeRulesLimitForFolder(
      {
        folderId: formData?.folder?.id,
        resourceTypeId: rule?.resourceTypeId,
        quantityRequested: formData?.quantityRequested
      },
      {
        onSuccess: () => {
          closeDialog?.();
          showSuccessSnackbar(t('foreignPermits:tabs.socialCommission.tabs.SocialCommissionBasicRules.messages.added'));
        }
      }
    );
  };

  const showDialog = () => {
    openDialog(({ closeDialog }) => (
      <DialogSelectFolderForCalculation
        closeDialog={closeDialog}
        submitCalculateForFolder={submitCalculateForFolder}
        isTradeRules={isTradeRules}
        isLoading={isLoading || isLoadingRules}
      />
    ));
  };

  return { showDialog };
}

export default useDialogSelectFolderForCalculation;
