import { useTranslation } from 'react-i18next';

import { Button, FormMode } from '@libs/common/v2';

import { useDriverDialog } from '@libs/domain/drivers/hooks';

interface IProps {
  driverId: string;
}

function SelectDriverToAddButton({ driverId }: IProps) {
  const [t] = useTranslation();
  const { open } = useDriverDialog();

  const handleSelectDriver = () => {
    open({ data: { driverId }, formMode: FormMode.EDIT });
  };

  return <Button onClick={handleSelectDriver} label={t('drivers:actions.selectDriver')} isSecondary />;
}

export default SelectDriverToAddButton;
