import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '@enigma/fe-ui';
import {
  DocumentsApiCancelDocumentRequest,
  DocumentsApiUpdateDocumentFinalDecisionRequest,
  DocumentSearchFilter,
  DocumentSnapshot,
  DocumentSnapshotsPage
} from '@ibtm/domain';
import { AxiosPromise, AxiosResponse } from 'axios';
import moment from 'moment';

import { ALTERNATIVE_DATE_FORMAT, Button, TableLayout, useConfirmDialog, useTableRowActions } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useFileDownload } from '@libs/file';

import { API } from '@libs/domain/api';
import { DomainDictionaryEntry, DomainUIElementEnum } from '@libs/domain/config';
import {
  DocumentsSnapshotClient,
  parseDocumentsList,
  useCancelDocumentMutation,
  useDeliveryDateDialog,
  useDownloadDocumentQuery,
  useFinalDecisionDocumentMutation,
  useForwardToShipmentMutation,
  useGenerateApplicationDocumentDialog,
  useUpdateDocumentDialog
} from '@libs/domain/documents';
import {
  ProceedingDetailsParams,
  ProceedingQueryKeysEnum,
  useEditProceedingDocumentMutation,
  useGenerateProceedingDocumentMutation,
  useProceedingDocumentsTable
} from '@libs/domain/proceeding';

type ProceedingDocumentsSnapshot = DocumentsSnapshotClient & DocumentSnapshot;

function ProceedingDocumentsTable({
  finalDecision
}: {
  finalDecision?: {
    haveFinalDecision: boolean;
    finalDecisionProcessingDate?: string;
  };
}) {
  const queryCache = useQueryCache();
  const { id } = useParams<ProceedingDetailsParams>();
  const [t] = useTranslation();

  const { mutate: cancelDocument } = useCancelDocumentMutation();
  const { mutate: forwardToShipment } = useForwardToShipmentMutation();
  const { mutate: finalDecisionDocument } = useFinalDecisionDocumentMutation();
  const [downloadId, setDownloadId] = useState<string>();
  const { showSnackbar } = useSnackbar();

  const downloadQuery = useDownloadDocumentQuery({ fileId: downloadId }, { enabled: Boolean(downloadId) });
  const { download } = useFileDownload();
  const [confirm] = useConfirmDialog();

  const { openUpdateDocumentDialog } = useUpdateDocumentDialog(
    ProceedingQueryKeysEnum.PROCEEDING_DOCUMENTS,
    useEditProceedingDocumentMutation,
    id
  );
  const { openEnterDeliveryDateDialog } = useDeliveryDateDialog({
    listQueryKey: ProceedingQueryKeysEnum.PROCEEDING_DOCUMENTS
  });
  const { openGenerateDocumentDialog } = useGenerateApplicationDocumentDialog({
    generateDocumentMutation: useGenerateProceedingDocumentMutation,
    apiParams: {
      proceedingId: id
    },
    listQueryKey: ProceedingQueryKeysEnum.PROCEEDING_DOCUMENTS
  });
  const tableProps = useProceedingDocumentsTable(id);
  const queryKey = ProceedingQueryKeysEnum.PROCEEDING_DOCUMENTS;
  const apiRequest = API.proceeding.getProceedingDocumentsPage;

  const xlsxApiRequestParser = (res: AxiosResponse<DocumentSnapshotsPage>) => {
    return {
      ...res,
      data: { ...res?.data, content: parseDocumentsList(res?.data?.content) }
    };
  };
  const handleGenerateDocument = (title: string, groupKey?: string) => {
    openGenerateDocumentDialog({
      title,
      groupKey
    });
  };

  const isUploadDocumentDisabled = (original: ProceedingDocumentsSnapshot) => {
    return (
      ![DomainDictionaryEntry.DOCUMENT_STATUS.GENERATED, DomainDictionaryEntry.DOCUMENT_STATUS.UPLOADED].includes(
        original?.statusKey
      ) && original?.statusKey !== null
    );
  };

  const isCancelDocumentDisabled = (original: ProceedingDocumentsSnapshot) => {
    return [
      DomainDictionaryEntry.DOCUMENT_STATUS.CANCELED,
      DomainDictionaryEntry.DOCUMENT_STATUS.RELEASE_DELIVERED,
      DomainDictionaryEntry.DOCUMENT_STATUS.RELEASE_PENDING_FOR_DELIVERY
    ].includes(original?.statusKey);
  };

  const isForwardToShipmentDisabled = (original: ProceedingDocumentsSnapshot) => {
    return [
      DomainDictionaryEntry.DOCUMENT_STATUS.RELEASE_DELIVERED,
      DomainDictionaryEntry.DOCUMENT_STATUS.RELEASE_PENDING_FOR_DELIVERY,
      DomainDictionaryEntry.DOCUMENT_STATUS.READY_FOR_RELEASE
    ].includes(original?.statusKey);
  };

  const isAddDeliveryDateButtonDisabled = (original: ProceedingDocumentsSnapshot) => {
    return original?.statusKey !== DomainDictionaryEntry.DOCUMENT_STATUS.RELEASE_PENDING_FOR_DELIVERY;
  };

  const handleCancel = (data: DocumentsSnapshotClient) => {
    confirm({
      title: t('document:dialog.cancelDocumentTitle'),
      shortTitle: t('document:dialog.cancelDocumentShortTitle'),
      message: t('document:dialog.cancelDocumentConfirm'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        const requestData: DocumentsApiCancelDocumentRequest = {
          documentId: data?.id,
          versionedRequest: {
            version: data?.version
          }
        };

        cancelDocument(requestData, {
          onSuccess: () => {
            queryCache.invalidateQueries(queryKey);
            showSnackbar('success', t('document:message.cancelDocumentSuccess'));
            closeDialog();
          },
          onSettled: () => {
            setConfirmLoading(false);
          }
        });
      },
      confirmType: 'danger'
    });
  };

  const handleForwardToShipment = (data: DocumentsSnapshotClient) => {
    const requestData = {
      documentId: data?.id,
      versionedRequest: {
        version: data?.version
      }
    };
    forwardToShipment(requestData, {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKey);
        showSnackbar('success', t('document:message.forwardToShipmentSuccess'));
      }
    });
  };

  const handlePublishDocument = data => {
    openUpdateDocumentDialog(data, () => {});
  };

  const handleEnterDeliveryDate = (data: DocumentsSnapshotClient) => {
    openEnterDeliveryDateDialog(data, () => {});
  };

  useEffect(() => {
    if (downloadQuery.data) {
      download(
        () =>
          new Promise(res => {
            res(downloadQuery.data);
          })
      );
      setDownloadId(null);
    }
  }, [download, downloadQuery]);

  const generateMarkAsFinalDecisionMessage = () => {
    if (finalDecision.haveFinalDecision) {
      return t('document:dialog.markAsFinalDecisionDocumentInProceedingWithFinalDecision');
    }
    return t('document:dialog.markAsFinalDecisionDocumentConfirm');
  };

  const handleMarkFinalDecisionDocument = (document: DocumentSnapshot) => {
    const { id: documentId, isFinalDecision, version } = document;
    const data: DocumentsApiUpdateDocumentFinalDecisionRequest = {
      documentId,
      documentFinalDecisionUpdateRequest: {
        isFinalDecision: !isFinalDecision,
        version
      }
    };

    confirm({
      title: isFinalDecision
        ? t('document:dialog.unmarkAsFinalDecisionDocumentTitle')
        : t('document:dialog.markAsFinalDecisionDocumentTitle'),
      message: isFinalDecision
        ? t('document:dialog.unmarkAsFinalDecisionDocumentConfirm')
        : generateMarkAsFinalDecisionMessage(),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        finalDecisionDocument(data, {
          onSuccess: () => {
            showSnackbar(
              'success',
              data.documentFinalDecisionUpdateRequest.isFinalDecision
                ? t('document:message.unmarkAsFinalDecisionDocumentSuccess')
                : t('document:message.markAsFinalDecisionDocumentSuccess')
            );
            closeDialog();
            queryCache.invalidateQueries(queryKey);
            queryCache.invalidateQueries(ProceedingQueryKeysEnum.PROCEEDING_DETAILS);
          },
          onSettled: () => {
            setConfirmLoading(false);
          }
        });
      }
    });
  };

  const { renderTableActions } = useTableRowActions<ProceedingDocumentsSnapshot>(
    [
      {
        id: 'DOCUMENTS_ISSUED_DOCUMENTS_DOWNLOAD_BUTTON',
        label: 'action.download',
        onClick: ({
          row: {
            original: { documentFileId }
          }
        }) => setDownloadId(documentFileId),
        icon: 'DownloadIcon'
      },
      {
        id: 'PROCEEDING_DOCUMENTS_UPLOAD_BUTTON',
        label: 'document:action.uploadDocument',
        onClick: ({ row: { original } }) => handlePublishDocument(original),
        isHidden: ({ row: { original } }) => isUploadDocumentDisabled(original),
        icon: 'UploadIcon'
      },
      {
        id: 'PROCEEDING_DOCUMENTS_CANCEL_BUTTON',
        label: 'proceeding:administrative.tab.outgoingDocuments.action.cancel',
        onClick: ({ row: { original } }) => handleCancel(original),
        isHidden: ({ row: { original } }) => isCancelDocumentDisabled(original),
        icon: 'CrossIcon'
      },
      {
        id: 'DOCUMENTS_ISSUED_DOCUMENTS_FORWARD_TO_SHIPMENT_BUTTON',
        label: 'document:action.forwardToShipment',
        onClick: ({ row: { original } }) => handleForwardToShipment(original),
        isHidden: ({ row: { original } }) => isForwardToShipmentDisabled(original),
        icon: 'SendIcon'
      },
      {
        id: 'PROCEEDING_DOCUMENTS_ADD_DELIVERY_DATE_BUTTON',
        label: 'document:action.enterDeliveryDate',
        onClick: ({ row: { original } }) => handleEnterDeliveryDate(original),
        isDisabled: ({ row: { original } }) => isAddDeliveryDateButtonDisabled(original),
        isDisabledLabel: 'document:message.changeReceiveDateTooltip',
        icon: 'CalendarIcon'
      },
      {
        id: 'PROCEEDING_DOCUMENTS_CHECK_FINAL_DECISION_BUTTON',
        label: 'document:action.markAsFinalDecision',
        onClick: ({ row: { original } }) => handleMarkFinalDecisionDocument(original),
        isDisabled:
          finalDecision?.finalDecisionProcessingDate &&
          moment().isAfter(moment(finalDecision.finalDecisionProcessingDate).format(ALTERNATIVE_DATE_FORMAT)),
        isDisabledLabel: 'document:message.isFinalDecisionWasProcess',
        isHidden: ({ row: { original } }) => original?.isFinalDecision,
        icon: 'LockIcon'
      },
      {
        id: 'PROCEEDING_DOCUMENTS_UNCHECK_FINAL_DECISION_BUTTON',
        label: 'document:action.unmarkAsFinalDecision',
        onClick: ({ row: { original } }) => handleMarkFinalDecisionDocument(original),
        isDisabled:
          finalDecision?.finalDecisionProcessingDate &&
          moment().isAfter(moment(finalDecision.finalDecisionProcessingDate).format(ALTERNATIVE_DATE_FORMAT)),
        isDisabledLabel: 'document:message.isFinalDecisionWasProcess',
        isHidden: ({ row: { original } }) => !original?.isFinalDecision,
        icon: 'UnlockIcon'
      }
    ],
    [finalDecision.finalDecisionProcessingDate, finalDecision.haveFinalDecision]
  );

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('document:listTitle')}
      xlsxDownload={{
        fileName: t('document:listTitle'),
        pathToXLSXTranslation: 'document:fields',
        apiRequest: params => {
          if (apiRequest.length === 2) {
            return (
              apiRequest as (
                documentSearchFilter: DocumentSearchFilter,
                options?: any
              ) => AxiosPromise<DocumentSnapshotsPage>
            )(params).then(xlsxApiRequestParser);
          }
          return apiRequest(id, params).then(xlsxApiRequestParser);
        }
      }}
      headerActions={
        <Button
          label={t('document:action.generateDocument')}
          onClick={() => handleGenerateDocument(t('document:dialog.generateDocumentTitle', { templateGroup: '' }))}
          variant="outlined"
          classNameWrapper="ml-16"
          actionKey={DomainUIElementEnum.DOCUMENTS_ISSUED_GENERATE_DOCUMENT_BUTTON}
          isNoMargin
        />
      }
      rowActions={renderTableActions([
        DomainUIElementEnum.PROCEEDING_DOCUMENTS_UPLOAD_BUTTON,
        DomainUIElementEnum.DOCUMENTS_ISSUED_DOCUMENTS_DOWNLOAD_BUTTON,
        DomainUIElementEnum.PROCEEDING_DOCUMENTS_CANCEL_BUTTON,
        DomainUIElementEnum.DOCUMENTS_ISSUED_DOCUMENTS_FORWARD_TO_SHIPMENT_BUTTON,
        DomainUIElementEnum.PROCEEDING_DOCUMENTS_ADD_DELIVERY_DATE_BUTTON,
        DomainUIElementEnum.PROCEEDING_DOCUMENTS_CHECK_FINAL_DECISION_BUTTON,
        DomainUIElementEnum.PROCEEDING_DOCUMENTS_UNCHECK_FINAL_DECISION_BUTTON
      ])}
      isSection
      isRefreshEnabled
      isCollapsable
    />
  );
}

export default ProceedingDocumentsTable;
