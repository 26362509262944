import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { Dialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { DomainDictionaryEntry } from '../../../../config';
import { ArchiveQueryKeysEnum } from '../../../common';
import { useCarryOnArchiveFolder } from '../../api';

interface ICarryOnArchiveFolderToArchiveDialog {
  ids: string[];
  closeDialog: () => void;
}

const CarryOnArchiveFolderToArchiveDialog = ({ ids, closeDialog }: ICarryOnArchiveFolderToArchiveDialog) => {
  const [t] = useTranslation();
  const queryCache = useQueryCache();
  const { showSnackbar } = useSnackbar();

  const { mutateAsync: carryOnArchiveFolder, isLoading } = useCarryOnArchiveFolder();

  return (
    <Dialog
      isOpen
      onConfirm={async () => {
        await carryOnArchiveFolder(
          {
            ids,
            archiveTypeKey: DomainDictionaryEntry.ARCHIVE_TYPE.DEPARTAMENTAL
          },
          {
            onSuccess: () => {
              closeDialog();
              queryCache.invalidateQueries(ArchiveQueryKeysEnum.FOLDERS_TO_ARCHIVE);

              showSnackbar(
                'success',
                t<any>('archive:folder.message.infoPlural.sendedToArchive', { count: ids.length })
              );
            }
          }
        );
      }}
      confirmText={t('action.confirm')}
      title={t('archive:sendToArchiveTitle')}
      cancelText={t('action.cancel')}
      onCancel={closeDialog}
      onClose={closeDialog}
      isConfirmLoading={isLoading}
    >
      {t<any>('archive:folder.message.toArchivePlural.toArchiveDialogQuestion', {
        count: ids.length
      })}
    </Dialog>
  );
};

export default CarryOnArchiveFolderToArchiveDialog;
