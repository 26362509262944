import { createContext, ReactNode, useMemo } from 'react';

interface IPermissionContext {
  userPermissions: string[];
}

export const PermissionContext = createContext<IPermissionContext>({
  userPermissions: []
});

interface IProps {
  children: ReactNode;
  userPermissions?: any[];
}

function PermissionContextProvider({ children, userPermissions = [] }: IProps) {
  const value = useMemo(() => ({ userPermissions }), [userPermissions]);
  return <PermissionContext.Provider value={value}>{children}</PermissionContext.Provider>;
}

export default PermissionContextProvider;
