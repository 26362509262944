import { useDialog } from '@libs/common/v2';

import { FinancialSecurityCreateDialog } from '../../components';

interface IProps {
  applicationId?: string;
  folderId?: string;
  folderTypeKey: string;
  subjectId?: string;
  customOnSuccess?: () => void;
  exchangeRateFrom?: string;
  exchangeRateTo?: string;
}

function useFinancialSecurityCreateDialog({
  applicationId,
  folderId,
  folderTypeKey,
  subjectId,
  customOnSuccess,
  exchangeRateFrom,
  exchangeRateTo
}: IProps) {
  const { openDialog } = useDialog();

  const showFinancialSecurityCreateDialog = () => {
    openDialog(({ closeDialog }) => (
      <FinancialSecurityCreateDialog
        onCloseDialog={closeDialog}
        folderId={folderId}
        applicationId={applicationId}
        folderTypeKey={folderTypeKey}
        subjectId={subjectId}
        customOnSuccess={customOnSuccess}
        exchangeRateFrom={exchangeRateFrom}
        exchangeRateTo={exchangeRateTo}
        isOpen
      />
    ));
  };

  return { showFinancialSecurityCreateDialog };
}

export default useFinancialSecurityCreateDialog;
