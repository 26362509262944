import { PermanentPermissionSubmissionSearchFilter, PermanentPermissionSubmissionSnapshot } from '@ibtm/domain';

import { ColumnTypesEnum, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO, getCalendarDate } from '@libs/common/v2/utils';

export const useSocialCommissionEKMTColumns = () => {
  const { createColumns } = useCreateColumns<PermanentPermissionSubmissionSnapshot>({
    pathToTranslate: 'foreignPermits:tabs.socialCommission.tabs.socialCommissionList.fields'
  });

  const mappedSortFields: TableSortMapper<PermanentPermissionSubmissionSnapshot> = {
    subjectName: 'folder.subject.name',
    civicBoardBoardDate: 'civicBoard.boardDate'
  };

  const mappedFilterFields: TableFilterMapper<
    PermanentPermissionSubmissionSnapshot,
    PermanentPermissionSubmissionSearchFilter
  > = {
    folderNumber: (folder: string) => ({ folderNumberContains: folder }),
    subjectName: (subject: string) => ({ subjectNameContains: subject }),
    civicBoardBoardDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      civicBoardDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      civicBoardDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    number: (number: number) => ({ number })
  };

  const columns = createColumns([
    {
      type: ColumnTypesEnum.NUMBER,
      accessor: 'number',
      width: 200
    },
    {
      type: ColumnTypesEnum.TEXT,
      accessor: 'folder.number',
      width: 200
    },
    {
      type: ColumnTypesEnum.TEXT,
      accessor: 'subject.name',
      width: 400
    },
    {
      type: ColumnTypesEnum.DATE,
      accessor: 'civicBoard.boardDate'
    }
  ]);

  return { columns, mappedFilterFields, mappedSortFields };
};
