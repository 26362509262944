import { usePaginatedQuery } from 'react-query';
import { AttachmentVerificationListDetails } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { parseAttachmentsList } from '../../utils';
import { AttachmentsQueryKeysEnum } from '..';

export const getAttachments = async (_, params: { applicationId: string }) => {
  const { applicationId, ...restParams } = params;
  const { data } = await API.applicationAttachments.getApplicationAttachmentsVerificationList(
    params.applicationId,
    restParams
  );

  return {
    ...data,
    content: parseAttachmentsList(data.verificationElements)
  };
};

export const getAttachmentsClient = async (_, params: { applicationId: string }) => {
  const { applicationId, ...restParams } = params;

  const { data } = await API.client.applicationAttachments.getApplicationAttachmentsVerificationList(
    '',
    '',
    params.applicationId,
    restParams
  );

  return {
    ...data,
    content: parseAttachmentsList(data.verificationElements)
  };
};

export default function useAttachmentsQuery(params: any, queryConfig = {}) {
  const { getQuery } = useGetApiQueryByPerspective();

  return usePaginatedQuery<AttachmentVerificationListDetails>(
    [AttachmentsQueryKeysEnum.ATTACHMENTS_LIST, params],
    getQuery(getAttachmentsClient, getAttachments),
    queryConfig
  );
}
