import { InvoiceItemDetails } from '@ibtm/domain';
import { v4 as uuidv4 } from 'uuid';

import { ReturnItemRange } from '../model/ReturnModels';

export const convertItemToReturnRanges = (
  item: InvoiceItemDetails,
  returnWithRangeInName?: boolean
): ReturnItemRange[] => {
  const forms = item?.forms.sort((a, b) => (a?.number || 0) - (b?.number || 0));
  const ranges: ReturnItemRange[] = [];
  let start = forms[0]?.number;
  let end = forms[0]?.number;

  for (let i = 1; i < forms.length; i++) {
    if (forms[i]?.number === end + 1) {
      // The current object is in the next range
      end = forms[i]?.number;
    } else {
      // The current object is not in the next range
      ranges.push({
        id: uuidv4(),
        name: returnWithRangeInName ? `${item.name} (${start} - ${end})` : item.name,
        positionId: item.id,
        min: start,
        max: end,
        from: start,
        to: end,
        reasonKey: null,
        sum: end - start + 1
      });
      start = forms[i]?.number;
      end = forms[i]?.number;
    }
  }

  // Add the last range
  ranges.push({
    id: uuidv4(),
    name: returnWithRangeInName ? `${item.name} (${start} - ${end})` : item.name,
    positionId: item.id,
    min: start,
    max: end,
    from: start,
    to: end,
    reasonKey: null,
    sum: end - start + 1
  });

  return ranges;
};
