import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { Dialog, useFormV2Context, useRouter } from '@libs/common/v2';

import { ProcessVariableKeyEnum, useProcessConfirmTaskMutation } from '@libs/domain/application';
import { useTasksQuery } from '@libs/domain/application-definitions';

import {
  PrintActionType,
  usePrintsActionsContext,
  usePrintsActionsDataContext,
  usePrintsDetailsDataContext
} from '../../../context';

function SendForReleaseConfirmationModal() {
  const { actionType, actionData } = usePrintsActionsDataContext();
  const { finishAction } = usePrintsActionsContext();
  const { showSuccessSnackbar } = useSnackbar();
  const { isDirty } = useFormV2Context();
  const [t] = useTranslation();
  const { printDetailsData } = usePrintsDetailsDataContext();
  const { goBack } = useRouter();

  const { data: taskData } = useTasksQuery(
    {
      size: 100,
      processVariables: {
        key: ProcessVariableKeyEnum.APPLICATION_ENTITY_ID,
        value: printDetailsData.applicationId
      }
    },
    { enabled: Boolean(printDetailsData.applicationId), refetchOnMount: false }
  );

  const { mutate: confirmTask, isLoading } = useProcessConfirmTaskMutation();
  const confirmHandler = async () => {
    if (isDirty) await actionData?.onSuccess();
    confirmTask(taskData?.content[0]?.taskId, {
      onSuccess: () => {
        actionData?.onSuccess();
        showSuccessSnackbar(t('prints:messages.sendForReleaseSuccessMessage'));
        goBack();
      },
      onSettled: () => {
        finishAction();
      }
    });
  };

  return (
    <Dialog
      title={t('prints:messages.sendForRelease')}
      confirmText={t('action.confirm')}
      cancelText={t('action.cancel')}
      contentText={t('prints:messages.sendForReleaseDescription')}
      onConfirm={confirmHandler}
      onCancel={finishAction}
      isConfirmLoading={isLoading}
      isOpen={actionType === PrintActionType.SendForRelease}
    />
  );
}

export default SendForReleaseConfirmationModal;
