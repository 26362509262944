import React, { useEffect, useMemo, useState } from 'react';
import { Dialog as MuiDialog } from '@mui/material';

interface DialogContextProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setContent: React.Dispatch<React.SetStateAction<React.ReactElement>>;
}

interface IProps {
  children?: React.ReactNode;
  isLoggedOut: boolean;
}

export const DialogContext = React.createContext<Partial<DialogContextProps>>({});

function DialogProvider({ children, isLoggedOut }: IProps) {
  const [content, setContent] = useState<React.ReactElement>(null);
  const [open, setOpen] = useState(false);

  const dialogContextValues = useMemo(
    () => ({
      setContent,
      setOpen
    }),
    []
  );

  useEffect(() => {
    setOpen(false);
  }, [isLoggedOut]);

  return (
    <DialogContext.Provider value={dialogContextValues}>
      <MuiDialog open={open} maxWidth={false}>
        {content || null}
      </MuiDialog>
      {children}
    </DialogContext.Provider>
  );
}

export default DialogProvider;
