export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  cancelDocument: { translationKey: 'documents:api.cancelDocument' },
  updateProceedingKreptdDocumentReceiptAckDate: {
    translationKey: 'documents:api.updateProceedingKreptdDocumentReceiptAckDate'
  },
  updateDocumentReceiptAckDate: { translationKey: 'documents:api.updateDocumentReceiptAckDate' },
  updateDocumentFinalDecision: { translationKey: 'documents:api.updateDocumentFinalDecision' },
  updateDocumentOutgoingNumber: { translationKey: 'documents:api.updateDocumentOutgoingNumber' },
  updateDocument: { translationKey: 'documents:api.updateDocument' },
  getDocumentsSnapshotPage: { translationKey: 'documents:api.getDocumentsSnapshotPage' },
  findTemplates: { translationKey: 'documents:api.findTemplates' },
  download: { translationKey: 'documents:api.download' }
};
