import { Column, PluginHook, Row, SortByReturn, TableInstance } from 'react-table';
import { ClassNameMap } from '@mui/material';

import { FormV2ContextState, InputMode, ITableContext, SelectOption } from '@libs/common/v2';

import { DictionaryEntryNameEnum, DictionaryQuickChangeableEntryNameEnum } from '@libs/dictionary';

import { filterTypes } from '../constants/filter-types.constants';
import { EditableInputsClasses } from '../ui/editable-inputs/useEditableInputsStyles';

// Opcje startowe dla tabeli, w której dane pobierane są w paginacji z serwera
// (filtrowanie, sortowanie i paginacja po stronie serwera)
export const INITIAL_PAGE_SIZE = 25;

export const ManualTableOptions = {
  manualPagination: true,
  manualFilters: true,
  manualSortBy: true,
  initialState: {
    pageSize: INITIAL_PAGE_SIZE,
    pageIndex: 0
  },
  filterTypes
};

export type CustomEditInputClasses = ClassNameMap<EditableInputsClasses>;
export enum TableClassNames {
  STICKY_RIGHT_COLUMN = 'stickyRightColumn',
  STICKY_LEFT_COLUMN = 'stickyLeftColumn'
}
export enum ISpecialColumnEnum {
  SELECTION = 'selection',
  ACTION = 'action'
}

export interface IExtendedColumn<T extends Record<string, any>, K extends Record<string, any> = any> extends Column<T> {
  xlsxFormatter?: (row: K) => any;
  xlsxCorrectFormat?: boolean;
  xlsxSkip?: boolean;
}

export interface IPaginatedModel<T> {
  totalElements?: number;
  totalPages?: number;
  last?: boolean;
  sort?: Record<string, any>;
  first?: boolean;
  numberOfElements?: number;
  size?: number;
  content?: Array<T>;
  number?: number;
  version?: number;
}

export interface IPaginationRequestParams {
  page?: number;
  size?: number;
  sort?: Array<any>;
}

export enum NewRowPlacementEnum {
  BEFORE = 'before',
  AFTER = 'after'
}

export enum ColumnTypesEnum {
  TEXT = 'TEXT',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  DICTIONARY_MULTI_SELECT = 'DICTIONARY_MULTI_SELECT',
  DICTIONARY_SINGLE_SELECT = 'DICTIONARY_SINGLE_SELECT',
  NUMBER = 'NUMBER',
  QUICK_DICTIONARY_MULTI_SELECT = 'QUICK_DICTIONARY_MULTI_SELECT',
  QUICK_DICTIONARY_SINGLE_SELECT = 'QUICK_DICTIONARY_SINGLE_SELECT',
  BOOLEAN = 'BOOLEAN',
  SINGLE_SELECT_FETCH = 'SINGLE_SELECT_FETCH',
  MULTI_SELECT_FETCH = 'MULTI_SELECT_FETCH',
  NUMBER_RANGE = 'NUMBER_RANGE',
  CUSTOM_COLUMN = 'CUSTOM_COLUMN',
  ARRAY_COLUMN = 'ARRAY_COLUMN',
  TIME = 'TIME',
  MONTH_DATEPICKER = 'MONTH_DATEPICKER',
  YEAR_DATEPICKER = 'YEAR_DATEPICKER'
}

type TColumnBooleanOrFunctionParam<T extends Record<string, any>, K extends Record<string, any> = T> =
  | ((params: {
      row: Row<T>;
      formValues: Record<string, any>;
      tableInstance: ITableAdapter<T, K>;
      tableContext: ITableContext<T, K>;
    }) => boolean)
  | boolean;

/**
 * @type T - model Frontend/Backend.
 * @type K - opcjonalny model Backend. Wykorzystywany przy mappowaniu wartości do pliku xlsx
 * @konwencja typ `T`to model zawierający definicje danych do tabeli. Może być modelem backend'u,
 * jeżeli na nim jest oparta implementacja gui, lub jeżeli dane są parsowane i dostosowane go modelu
 * frontend'owego, który jest rozbieżny z modelem backend wtedy do typu `T` przypisujemy model frontend,
 * natomiast do typu `K` przypisujemy model backend. W ten sposób przy mappowaniu wartości do pliku xlsx
 * mamy dostęp do atrybutów z modelu backend.
 */
export interface ITableLayoutColumn<T extends Record<string, any>, K extends Record<string, any> = T>
  extends IExtendedColumn<T, K> {
  field?: {
    name: string;
    type: ColumnTypesEnum;
    isCreatable?: TColumnBooleanOrFunctionParam<T, K>;
    isEditable?: TColumnBooleanOrFunctionParam<T, K>;
    isDisabled?: TColumnBooleanOrFunctionParam<T, K>;
    dictionaryName?: DictionaryEntryNameEnum;
    quickDictionaryName?: DictionaryQuickChangeableEntryNameEnum;
    defaultValue?:
      | ((params: {
          row: T;
          clickedRow?: T;
          tableInstance: ITableAdapter<T, K>;
          tableContext: ITableContext<T, K>;
        }) => unknown)
      | unknown;
    inputProps?: any;
  };
  originalId?: CamelCasePath<T>;
}

type TNestedArraySingleColumn<T extends Record<string, any>, D extends Record<string, any> = T> = Omit<
  ISingleColumn<T, D>,
  'id'
> & {
  id: string;
};

export interface ICustomMutableTableCellRenderer<T> {
  (inputMode: InputMode, rowOriginal: T, inputName: string, classes?: CustomEditInputClasses): JSX.Element;
}
/**
 * Model do tworzenia nowej kolumny przy użyciu hook useCreateColums
 */
export interface ISingleColumn<T extends Record<string, any>, D extends Record<string, any> = T> {
  /**
   * Unikalna nazwa kolumny
   * @implementacja 'folderNumber`
   */
  id?: CamelCasePath<T>;
  /**
   * Ścieżka w modelu do wartości którą chcemy wyświetlić w komórce
   * @implementacja 'folder.number`
   */
  accessor?: Path<T>;
  /**
   * Jeżeli tłumaczenie w pliku jest pod inną nazwą niż
   * ścieżka przekazana w id możemy przekazać ją tutaj
   */
  header?: string;
  /**
   * Funkcja jest wykorzystana przy plugin dodawania kolumn do tabeli. Możemy przekazać gdzie chcemy
   * index w id. Np. domyślnie dla id `document.created` index będzie dodany na końcu `document.created.0`,
   * jeżeli chcemy aby index był w innym miejscu ( co wpłynie na mapowanie do form ) możemy tutaj przekazać
   * fn która będzie użyta do wstawienia tego indexu w wybrane miejsce.
   * @implementacja (index)=>`document.${index}.create`
   */
  idIndexing?: (index: number) => string;
  /**
   * Jeżeli chcemy spersonalizować komórkę kolumny,
   * możemyprzekazać tą logikę tutaj.
   */
  customAccessor?: (row: T) => React.ReactNode;
  /**
   * Jeżeli chcemy spersonalizować input komórki kolumny,
   * możemy przekazać tutaj customowy input.
   */
  customEdit?: ICustomMutableTableCellRenderer<T>;
  /**
   * Jeżeli chcemy spersonalizować komórkę w trybie view w tabeli mutowalnej,
   * możemy przekazać tutaj customowy komponent.
   */
  customView?: ICustomMutableTableCellRenderer<T>;
  /**
   * Tooltip komórki,
   */
  tooltipTitle?: (row: T) => string;
  /**
   * Typ kolumny.
   */
  type: keyof typeof ColumnTypesEnum;
  /**
   * Props jest używany gdy type=ColumnTypesEnum.CUSTOM_COLUMN,
   * oraz tabela jest mutowalna
   */
  editType?: ColumnTypesEnum;
  /**
   * Props jest potrzebny gdy deklarujemy kolumnę z tablicy.
   * Ten typ zostanie przypisany do każdej zmapowanej kolumny
   */
  arrayColType?: ColumnTypesEnum;
  /**
   * Customowy filtr
   */
  filter?: (props) => React.ReactNode;
  /**
   * Wartość `boolean` decyduje o możliwości filtrowania kolumny
   */
  isFilterable?: boolean;
  /**
   * Wartość `boolean` decyduje o możliwości sortowania kolumny
   */
  isSortable?: boolean;
  /**
   * Funkcja do formatowania daty
   */
  dateFormatFn?: (date: string) => string;
  /**
   * Funkcja decyduje o możliwości edycji komórek kolumny gdy tabela jest mutowalna.
   * @params - obiekt z zawierający dane wiersza, dane z formContext, instancję tabeli oraz jej kontekst
   */
  isEditable?: Pick<ITableLayoutColumn<T, D>['field'], 'isEditable'>['isEditable'];
  /**
   * ścieżka modelu do wartości jaką chcemy przekazać do XLSX.
   * Można pominąć jeżeli nie ma różnicy między modelem frontendu i backendu.
   */
  xlsxAccessor?: Path<D>;
  /**
   * Jeżeli chcemy komórkę kolumny przekazać  w zależności od wartości wiersza,
   * możemy tutaj tą logikę przekazać.
   */
  customXlsxAccessor?: (row: D) => string;
  /**
   * Funkcja decyduje o możliwości dodawania wartości komórki kolumny
   * gdy dodajemy nowy wiersz w tabeli mutowalnej
   * @params - obiekt z zawierający dane wiersza, dane z formContext, instancję tabeli oraz jej kontekst
   */
  isCreatable?: Pick<ITableLayoutColumn<T, D>['field'], 'isCreatable'>['isCreatable'];
  /**
   * Funkcja decyduje czy input jest zablokowany
   * @params - obiekt z zawierający dane wiersza, dane z formContext, instancję tabeli oraz jej kontekst
   */
  isDisabled?: Pick<ITableLayoutColumn<T, D>['field'], 'isDisabled'>['isDisabled'];
  /**
   * Słownik z którego korzysta kolumna
   */
  dictionaryName?: DictionaryEntryNameEnum;
  /**
   * Czy widoczne są klucze nieaktywne dla pola słownikowego
   */
  areInactiveEntriesVisible?: boolean;
  /**
   * Tablica zawierająca opcje, które powinny zostać ukryte w selectach
   */
  hiddenSelectOptionsValues?: string[];
  /**
   * Słownik szybkozmienny z którego korzysta kolumna
   */
  quickDictionaryName?: DictionaryQuickChangeableEntryNameEnum;
  /**
   * Domyślna wartość wstawiona do komórki podczas dodawania nowego wiersza w tabeli mutowalnej
   */
  defaultValue?: Pick<ITableLayoutColumn<T, D>['field'], 'defaultValue'>['defaultValue'];
  /**
   * Dodatkowe props do inputów wyświetlanych przy dodawaniu lub edycji wiersza w tabeli mutowalnej
   */
  mutableTableInputProps?:
    | Record<string, unknown>
    | ((row: T, rowContext: FormV2ContextState) => Record<string, unknown>);
  /**
   * Funkcja asynchroniczna która zwraca sparsowany array opcji do AutocompleteSelect
   */
  fetchFunctionResolver?: (params: any) => Promise<any>;
  /**
   *
   */
  fetchedDataSelectParser?: (data: any) => SelectOption[];
  /**
   * Szerokość kolumny
   */
  width?: number;
  /**
   * Tablica opcji do zwykłych selectów
   */
  selectOptions?: SelectOption[];
  /**
   * Funckcja którą używamy do renderowania kolumn z tablicy obiektów, dla każdego obiektu zostanie
   * ona wywołana. Mamy w niej dostęp do index, oraz jeżeli jest inny atrybut kolejności to od samej tablicy
   * Atrybutu kolejności (np w/w index) używamy do ustawienia ścieżki wartości (id) np `departures.${index}.arrival`
   */
  nestedArrayColumns?: (index: number, mapper: Record<string, any>) => TNestedArraySingleColumn<T, D>[];
  /**
   * Długość prezentowanego tekstu
   */
  truncateLength?: number;
  /**
   * Funkcja do sortowania kolumny
   */
  sortType?: (rowA: Row<T>, rowB: Row<T>) => SortByReturn;
  /**
   * Wartość decyduje czy do select'a można wprowadzić własną wartość
   */
  isFreeSolo?: boolean;
  /**
   *
   * Czy label header ma być wyświetlany po prawej stronie
   *
   * */
  isCellAlignRight?: boolean;
  /**
   * Czy kolumna tabeli (w przypadku tabeli mutowalnej) posiada pola, które są wymagane do uzupełnienia przed zapisem danych
   */
  isRequired?: boolean;
  /**
   * Czy tooltip ma być niewidoczny
   */
  isTooltipHidden?: boolean;
}

export type ITableAdapter<T extends Record<string, any>, K extends Record<string, any> = T> = Omit<
  TableInstance<any>,
  'columns' | 'plugins'
> & {
  columns: ITableLayoutColumn<T, K>[];
  plugins?: Array<PluginHook<T>>;
};

export type FilterElement<F extends Record<string, any>> = {
  [key in Path<F>]: string;
};

export type FilterMapper<T extends Record<string, any>, F extends Record<string, any>> = {
  [id in Path<T>]: FilterElement<F>;
};

// Typowanie z react hook form, wyciągnięte tutaj na wypadek zmiany paczki do formularzy.
// Poprawnie typuje ścieżkę z przekazanego modelu.
// Wykorzystane w hook useCreateColumn
type Primitive = null | undefined | string | number | boolean | symbol | bigint;
type IsTuple<T extends ReadonlyArray<any>> = number extends T['length'] ? false : true;
type TupleKey<T extends ReadonlyArray<any>> = Exclude<keyof T, keyof any[]>;
type PathImpl<K extends string | number, V> = V extends Primitive ? `${K}` : `${K}` | `${K}.${Path<V>}`;
export type Path<T> = T extends ReadonlyArray<infer V>
  ? IsTuple<T> extends true
    ? {
        [K in TupleKey<T>]-?: PathImpl<K & string, T[K]>;
      }[TupleKey<T>]
    : PathImpl<number, V>
  : {
      [K in keyof T]-?: PathImpl<K & string, T[K]>;
    }[keyof T];

type CamelImpl<K extends string | number, V> = V extends Primitive
  ? `${K}`
  : `${K}` | `${K}${Capitalize<CamelCasePath<V>>}`;

export type CamelCasePath<T> = T extends ReadonlyArray<infer V>
  ? IsTuple<T> extends true
    ? {
        [K in TupleKey<T>]-?: CamelImpl<K & string, T[K]>;
      }[TupleKey<T>]
    : CamelImpl<number, V>
  : {
      [K in keyof T]-?: CamelImpl<K & string, T[K]>;
    }[keyof T];

export type TableFilterMapper<S, F> = Partial<{
  [key in CamelCasePath<S>]: (value: any) => { [value in keyof Partial<F>]: F[value] };
}>;

export type TableSortMapper<S> = Partial<{
  [key in CamelCasePath<S>]: string | string[];
}>;

export type TMutableTableFormContextProvider = Required<
  Omit<
    FormV2ContextState,
    | 'handleSubmit'
    | 'loading'
    | 'additionalFields'
    | 'initialValues'
    | 'formState'
    | 'isSubmitted'
    | 'isPermissionKeys'
    | 'validationBuilderFunctions'
  >
>;

export type ColumnDefinition<T extends object> = {
  [key in CamelCasePath<T>]?: Omit<IExtendedColumn<T>, 'id'> & { id: key };
};

export enum MultipleSelectColumnPlugin {
  useMultipleSelectColumn = 'useMultipleSelectColumn',
  useMultipleSelectColumnWithSelectRowContext = 'useMultipleSelectColumnWithSelectRowContext'
}
