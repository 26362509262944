import { QueryConfig, useQuery } from 'react-query';
import { VehicleActiveInObject } from '@ibtm/domain';

import { API } from '@libs/domain/api';
import { VehicleQueryKeysEnum } from '@libs/domain/vehicle';

import { endpointsConfig } from '../endpoints-config';

const getVehicleInAnotherFolders = async (params: { vehicleId: string; folderId: string }) => {
  const { data } = await API.vehicle.isVehicleActive(params?.vehicleId, params?.folderId, {
    ...endpointsConfig.isVehicleActive
  });

  return data;
};

export default function useVehicleInAnotherFoldersQuery(
  params,
  queryConfig: QueryConfig<VehicleActiveInObject, unknown> = {}
) {
  return useQuery<VehicleActiveInObject>(
    [VehicleQueryKeysEnum.VEHICLE_IN_ANOTHER_FOLDERS, params],
    getVehicleInAnotherFolders,
    queryConfig
  );
}
