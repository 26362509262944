import { usePaginatedQuery } from 'react-query';
import { AlertApiGetAlertTransitionsHistoryRequest, AlertTransitionDetailsSnapshot } from '@stack/report';

import { IPaginatedModel } from '@libs/common/v2';

import { AlertQueryKeysEnum, API, endpointsConfig } from '@libs/alert/api';

const getAlertsHistory = async (_, params: AlertApiGetAlertTransitionsHistoryRequest) => {
  const { page, size, sort, alertIds, createdFrom, createdTo, currentStatusKeys, previousStatusKeys } = params;
  const { data } = await API.alert.getAlertTransitionsHistory(
    page,
    size,
    sort,
    alertIds,
    createdFrom,
    createdTo,
    currentStatusKeys,
    previousStatusKeys,
    { ...endpointsConfig.getAlertTransitionsHistory }
  );

  return data;
};

export default function useAlertHistoryQuery(params: AlertApiGetAlertTransitionsHistoryRequest) {
  return usePaginatedQuery<IPaginatedModel<AlertTransitionDetailsSnapshot>>(
    [AlertQueryKeysEnum.ALERT_HISTORY, params],
    getAlertsHistory
  );
}
