import { useMemo } from 'react';

import { MisspellApplicationCertificateTable } from '@libs/domain/application-misspell';
import { DomainDictionaryEntry } from '@libs/domain/config';
import { VehicleSectionFields } from '@libs/domain/vehicle';

import { ApplicationVehiclesTable, CertificateVehiclesTable } from './components';
import { CertificateVehicleTabContext, useCertificateTable, useCertificateVehicleTable } from './hooks';

export interface ICertificateSTabProps {
  folderId: string;
  transferredFoldersIds?: string[];
  applicationId: string;
  folderType: string;
  applicationType: string;
  applicationCategory: string;
  hiddenVehicleDetailsFields?: VehicleSectionFields[];
  visibleSections?: CertificateSSectionsEnum[];
  applicationStatusKey?: string;
}

export enum CertificateSSectionsEnum {
  FOLDER_VEHICLES = 'FOLDER_VEHICLES',
  VEHICLES = 'VEHICLES'
}

function CertificateSTab({
  folderId,
  folderType,
  transferredFoldersIds,
  applicationId,
  applicationType,
  applicationCategory,
  hiddenVehicleDetailsFields = [],
  visibleSections = [CertificateSSectionsEnum.FOLDER_VEHICLES, CertificateSSectionsEnum.VEHICLES],
  applicationStatusKey
}: ICertificateSTabProps) {
  const checkIsSectionVisible = (name: CertificateSSectionsEnum) => visibleSections.includes(name);

  const values = useMemo(
    () => ({
      folderId,
      transferredFoldersIds,
      applicationId,
      folderType,
      issuingCertificate: applicationType === DomainDictionaryEntry.APPLICATION_TYPE.S_CERTIFICATE_ISSUING_DOCUMENT,
      applicationStatusKey
    }),
    [applicationId, applicationType, folderId, folderType, applicationStatusKey, transferredFoldersIds]
  );

  const isApplicationTypeReprint = applicationType === DomainDictionaryEntry.APPLICATION_TYPE.sCertificateReprintBtm;

  const useTable = values.issuingCertificate ? useCertificateVehicleTable : useCertificateTable;

  return (
    <CertificateVehicleTabContext.Provider value={values}>
      {isApplicationTypeReprint ? (
        <MisspellApplicationCertificateTable applicationId={applicationId} />
      ) : (
        <>
          {checkIsSectionVisible(CertificateSSectionsEnum.FOLDER_VEHICLES) && (
            <CertificateVehiclesTable useTable={useTable} />
          )}
          {checkIsSectionVisible(CertificateSSectionsEnum.VEHICLES) && (
            <ApplicationVehiclesTable
              applicationType={applicationType}
              applicationCategory={applicationCategory}
              hiddenFieldsAddVehicle={[
                VehicleSectionFields.DMC,
                VehicleSectionFields.ACCEPTABLE_CAPACITY,
                VehicleSectionFields.SEATS,
                VehicleSectionFields.MANUFACTURE_YEAR,
                VehicleSectionFields.BELOW_THREE_AND_HALF_TONS,
                VehicleSectionFields.OWNERSHIP_TYPE,
                VehicleSectionFields.RIGHT_OF_DISPOSITION_DATE,
                VehicleSectionFields.TEMPORARY_RIGHT_OF_DISPOSITION,
                ...(Array.isArray(hiddenVehicleDetailsFields) ? hiddenVehicleDetailsFields : [])
              ]}
              isPreserveHiddenRightOfDisposition
            />
          )}
        </>
      )}
      <div className="mb-32" />
    </CertificateVehicleTabContext.Provider>
  );
}

export default CertificateSTab;
