import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '@enigma/fe-ui';
import { ApplicationDetails } from '@ibtm/domain';

import { GridLayout, Page, PageHeader, useRouter } from '@libs/common/v2';

import { useApplicationDetailsQuery } from '@libs/domain/application';

import { DomainDictionaryEntry } from '../../../config';
import { PrintActionType, usePrintsActionsDataContext, usePrintsDetailsDataContext } from '../../context';
import { IPrintsDetailsParams } from '../../model/print-model';

import { RegenerateMultiplePermissionDialog, RegenerateTravelFormDialog } from './dialog/regenerate-permission-dialog';
import { RegenerateForeignPermissionDialog } from './dialog/regenerate-permission-dialog/RegenerateForeignPermissionDialog';
import SendForForeignPermissionDepartmentDialog from './dialog/SendForForeignPermissionDepartmentDialog';
import { PrintDetailsHeaderActions } from './actions';
import { RegenerateConfirmationDialog, ReturnApplicationConfirmationDialog } from './dialog';
import {
  ApplicationDetailsSection,
  DepotSection,
  DetailsTablesSection,
  SendForReleaseConfirmationModal,
  SignatoryDataSection,
  SubjectDataSection
} from '.';

function PrintDetailsPageContent() {
  const [t] = useTranslation();
  const { goToPage } = useRouter();
  const { showSnackbar } = useSnackbar();
  const { actionType } = usePrintsActionsDataContext();
  const { showPrintDetails } = usePrintsDetailsDataContext();
  const { id: applicationId } = useParams<IPrintsDetailsParams>();

  const { isLoading } = useApplicationDetailsQuery(applicationId, {
    enabled: Boolean(applicationId),
    onSuccess: data => {
      if (data.statusKey !== DomainDictionaryEntry.APPLICATION_STATUS.PENDING_PRINT) {
        showSnackbar('info', t('prints:messages.applicationIsNotInPrinted'));
        return goToPage('/');
      }
      return showPrintDetails({
        applicationId: data?.id,
        folderId: data?.folder.id,
        applicationSnapshot: data as ApplicationDetails
      });
    },
    onError: () => {
      goToPage('/prints');
    }
  });

  return (
    <Page
      header={
        <PageHeader title={t('prints:positionsToPrint.title')} rightSideContent={<PrintDetailsHeaderActions />} />
      }
      isLoading={isLoading}
      content={
        <form className="w-full">
          <GridLayout
            containerProps={{
              classes: {
                container: 'w-full'
              }
            }}
            itemProps={{ xs: 12 }}
          >
            <GridLayout itemProps={{ xs: 12, sm: 6 }}>
              <GridLayout itemProps={{ xs: 12 }}>
                <DepotSection />
                <ApplicationDetailsSection />
                <SignatoryDataSection />
              </GridLayout>
              <GridLayout itemProps={{ xs: 12 }}>
                <SubjectDataSection />
              </GridLayout>
            </GridLayout>
            <DetailsTablesSection />
          </GridLayout>
          {actionType === PrintActionType.Regenerate && <RegenerateConfirmationDialog />}
          {actionType === PrintActionType.ReturnApplication && <ReturnApplicationConfirmationDialog />}
          {actionType === PrintActionType.SendForRelease && <SendForReleaseConfirmationModal />}
          {actionType === PrintActionType.RegenerateTravelForm && <RegenerateTravelFormDialog />}
          {actionType === PrintActionType.RegenerateForeignPermissionNumber && <RegenerateForeignPermissionDialog />}
          {actionType === PrintActionType.SendToForeignPermissionDepartment && (
            <SendForForeignPermissionDepartmentDialog />
          )}
          {actionType === PrintActionType.RegenerateMultipleFormNumbers && <RegenerateMultiplePermissionDialog />}
        </form>
      }
    />
  );
}

export default PrintDetailsPageContent;
