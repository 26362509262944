import { QueryConfig, useQuery } from 'react-query';
import { ApplicationExistenceRequest } from '@ibtm/client-domain';
import { ApplicationCountSearchFilter } from '@ibtm/domain';
import { first } from 'lodash';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import ApplicationQueryKeysEnum from './ApplicationQueryKeysEnum';

export const getApplicationCount = async (_, params: ApplicationCountSearchFilter) => {
  const { data } = await API.application.getApplicationsCount(params);
  return data;
};

export const getApplicationCountClient = async (_, params) => {
  const param: ApplicationExistenceRequest = { typeKey: first(params.typeKeyIn) };
  const { data } = await API.client.application.getApplicationExistenceForFolder('', '', param);

  return data;
};

export default function useApplicationCountQuery(
  params: ApplicationCountSearchFilter,
  queryConfig: QueryConfig<boolean | number, unknown> = {}
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery(
    [ApplicationQueryKeysEnum.APPLICATION_COUNT, params],
    getQuery(getApplicationCountClient, getApplicationCount),
    queryConfig
  );
}
