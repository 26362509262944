import { TravelFormPrintsSnapshot } from '@ibtm/domain';

import { useCreateColumns } from '@libs/common/v2';

const useTravelFormColumns = () => {
  const { createColumns } = useCreateColumns<TravelFormPrintsSnapshot>({
    pathToTranslate: 'prints:travelFormsSections.table.columns'
  });

  const columns = createColumns([
    {
      type: 'TEXT',
      accessor: 'requestedAmount',
      isSortable: false
    },
    {
      type: 'TEXT',
      accessor: 'firstFormNumber',
      isSortable: false
    },
    {
      type: 'TEXT',
      accessor: 'lastFormNumber',
      isSortable: false
    },
    {
      type: 'TEXT',
      accessor: 'formsAmount',
      isSortable: false
    }
  ]);

  return { columns };
};

export default useTravelFormColumns;
