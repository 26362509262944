import { useTranslation } from 'react-i18next';
import { Notifications, NotificationsOff } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import { useDialog, useMuteNotifications } from '@libs/common/v2';

import MuteNotificationDialog from './MuteNotificationDialog';

function MuteNotificationsIcon() {
  const [t] = useTranslation();
  const { openDialog } = useDialog();
  const { isMuted, setData } = useMuteNotifications();

  const confirm = () => {
    openDialog(({ closeDialog }) => <MuteNotificationDialog closeDialog={closeDialog} setData={setData} />);
  };
  return (
    <Tooltip title={isMuted ? t('notifications:mute.tooltip.unmute') : t('notifications:mute.tooltip.mute')}>
      <IconButton size="small">
        {isMuted ? (
          <Notifications
            onClick={() => {
              setData(null);
            }}
          />
        ) : (
          <NotificationsOff
            onClick={() => {
              confirm();
            }}
          />
        )}
      </IconButton>
    </Tooltip>
  );
}

export default MuteNotificationsIcon;
