import { QueryConfig, useQuery } from 'react-query';
import { TravelFormDetailsList, TravelFormsSearchFilter } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';
import { FolderQueryKeysEnum } from '../QueryKeysEnum';

export const getTravelForms = async (_, params: TravelFormsSearchFilter) => {
  const { data } = await API.travelForm.getTravelFormDetailsList(params, {
    ...endpointsConfig.getTravelFormDetailsList
  });
  return data;
};

export default function useTravelFormsQuery(
  params: TravelFormsSearchFilter,
  queryConfig: QueryConfig<any, unknown> = {}
) {
  return useQuery<TravelFormDetailsList>([FolderQueryKeysEnum.TRAVEL_FORM_LIST, params], getTravelForms, queryConfig);
}
