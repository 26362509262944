import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '@libs/report/api';

const exportSingleReportType = async (reportTypeId: string) => {
  const { data } = await API.reportTypes._export(reportTypeId, {
    ...endpointsConfig._export
  });

  return data;
};

function useExportSingleReportTypeMutation() {
  return useMutation(exportSingleReportType);
}

export default useExportSingleReportTypeMutation;
