import { useDialog } from '@libs/common/v2';

import { ProcessFormDialog } from '../components';

interface IShowProcessFormDialogProps {
  messageKey?: string;
  applicationId?: string;
  applicationVersion?: number;
  formConfig?: {
    messageKey: string;
    title: string;
    uiDefinition: any;
  };
  onFinish: () => void;
}

function useProcessFormDialog() {
  const { openDialog } = useDialog();

  const showProcessFormDialog = ({
    messageKey,
    applicationId,
    applicationVersion,
    formConfig,
    onFinish
  }: IShowProcessFormDialogProps) => {
    openDialog(({ closeDialog }) => (
      <ProcessFormDialog
        onCloseDialog={closeDialog}
        isOpen
        messageKey={messageKey}
        applicationId={applicationId}
        applicationVersion={applicationVersion}
        formConfig={formConfig}
        onFinish={onFinish}
      />
    ));
  };

  return { showProcessFormDialog };
}

export default useProcessFormDialog;
