const LOGIN_ERROR = 'LOGIN_ERROR';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

const initialState = {
  success: false,
  error: {
    username: null,
    password: null,
    status: null
  }
};
export type InitialLoginState = typeof initialState;

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      return {
        ...initialState,
        success: true
      };
    }
    case LOGIN_ERROR: {
      return {
        success: false,
        error: action.payload as object
      };
    }
    default: {
      return state;
    }
  }
};

export default login;
