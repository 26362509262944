import React, { createRef, ForwardedRef, useCallback, useContext, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Location } from 'history';
import MobileDetect from 'mobile-detect';
import PerfectScrollbar from 'perfect-scrollbar';

import { LayoutContext, TypeLayoutContext } from '@libs/common/layout/context/LayoutContextProvider';

import 'perfect-scrollbar/css/perfect-scrollbar.css';

const md = new MobileDetect(window.navigator.userAgent);
const isMobile = md.mobile();

const handlerNameByEvent = {
  'ps-scroll-y': 'onScrollY',
  'ps-scroll-x': 'onScrollX',
  'ps-scroll-up': 'onScrollUp',
  'ps-scroll-down': 'onScrollDown',
  'ps-scroll-left': 'onScrollLeft',
  'ps-scroll-right': 'onScrollRight',
  'ps-y-reach-start': 'onYReachStart',
  'ps-y-reach-end': 'onYReachEnd',
  'ps-x-reach-start': 'onXReachStart',
  'ps-x-reach-end': 'onXReachEnd'
};
Object.freeze(handlerNameByEvent);

const useStyles = makeStyles({
  root: {}
});

interface ScrollbarsProps {
  children?: React.ReactNode;
  location?: Location;
  id?: string;
  className?: string;
  option?: {
    wheelPropagation: boolean;
    suppressScrollX: boolean;
  };
  scrollToTopOnRouteChange?: boolean;
  scrollToTopOnChildChange?: boolean;
}

const Scrollbars = React.forwardRef((props: ScrollbarsProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { current } = useContext<TypeLayoutContext>(LayoutContext);
  const { customScrollbars } = current;

  const localRef = ref || createRef();
  const ps = useRef(null);
  const handlerByEvent = useRef(new Map());
  const classes = useStyles(props);

  const hookUpEvents = useCallback(() => {
    Object.keys(handlerNameByEvent).forEach(key => {
      const callback = props[handlerNameByEvent[key]];
      if (typeof localRef !== 'function' && callback) {
        const handler = () => callback(localRef.current);
        handlerByEvent.current.set(key, handler);
        localRef.current.addEventListener(key, handler, false);
      }
    });
    // eslint-disable-next-line
  }, [localRef]);

  const unHookUpEvents = useCallback(() => {
    handlerByEvent.current.forEach((value, key) => {
      if (typeof localRef !== 'function') {
        localRef.current.removeEventListener(key, value, false);
      }
    });
    handlerByEvent.current.clear();
  }, [localRef]);

  const destroyPs = useCallback(() => {
    unHookUpEvents();

    if (!ps.current) {
      return;
    }
    ps.current.destroy();
    ps.current = null;
  }, [unHookUpEvents]);

  const createPs = useCallback(() => {
    // console.info("create::ps");

    if (isMobile || !localRef || ps.current) {
      return;
    }

    if (typeof localRef !== 'function') {
      ps.current = new PerfectScrollbar(localRef.current, props.option);
    }

    hookUpEvents();
  }, [hookUpEvents, props.option, localRef]);

  useEffect(() => {
    function updatePs() {
      if (!ps.current) {
        return;
      }
      ps.current.update();
    }

    updatePs();
  });

  useEffect(() => {
    if (customScrollbars) {
      createPs();
    } else {
      destroyPs();
    }
  }, [createPs, customScrollbars, destroyPs]);

  const scrollToTop = useCallback(() => {
    if (localRef && typeof localRef !== 'function') {
      localRef.current.scrollTop = 0;
    }
  }, [localRef]);

  useEffect(() => {
    if (props.scrollToTopOnChildChange) {
      scrollToTop();
    }
  }, [scrollToTop, props.children, props.scrollToTopOnChildChange]);

  useEffect(() => {
    if (props.scrollToTopOnRouteChange) {
      scrollToTop();
    }
  }, [scrollToTop, props.location, props.scrollToTopOnRouteChange]);

  useEffect(() => {
    return () => {
      destroyPs();
    };
  }, [destroyPs]);

  return (
    <div
      id={props.id}
      className={clsx(classes.root, props.className)}
      style={
        customScrollbars && !isMobile
          ? {
              position: 'relative',
              overflow: 'hidden'
            }
          : {}
      }
      ref={localRef}
    >
      {props.children}
    </div>
  );
});

export default Scrollbars;
