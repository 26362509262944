import { useTranslation } from 'react-i18next';

import { Section } from '@libs/common/v2';

interface IProps {
  formNumbersSection: JSX.Element;
  reprintSection: JSX.Element;
}

export function RegeneratePermissionDialogLayout({ formNumbersSection, reprintSection }: IProps) {
  const [t] = useTranslation();
  return (
    <>
      <Section title={t('prints:messages.formNumbersToRegenerate')} isModalSection>
        {formNumbersSection}
      </Section>
      <Section title={t('prints:section.reprint')} isModalSection>
        {reprintSection}
      </Section>
    </>
  );
}
