import { number as YupNumber, object as YupObject, string as YupString } from 'yup';

import { SelectOption as SelectOptionCommon } from '@libs/common/v2';

import { CountryEnum } from '../../exploitation-base';

type SelectOption = Partial<SelectOptionCommon<string>>;

export const validationSchema = () =>
  YupObject<PartnerApplicantFields>({
    lp: YupNumber().nullable().required(),
    roleKey: YupObject().required().nullable(),
    address: YupObject({
      countryCodeKey: YupObject().nullable().required(),
      postCode: YupString().when('countryCodeKey', {
        is: countryCodeKey => countryCodeKey?.value === CountryEnum.POLAND,
        then: YupString().required().nullable(),
        otherwise: YupString().nullable()
      }),
      voivodeshipKey: YupObject().when('countryCodeKey', {
        is: countryCodeKey => countryCodeKey?.value === CountryEnum.POLAND,
        then: YupObject().required().nullable(),
        otherwise: YupObject().nullable()
      }),
      postCity: YupString().when('countryCodeKey', {
        is: countryCodeKey => countryCodeKey?.value === CountryEnum.POLAND,
        then: YupString().required().nullable(),
        otherwise: YupString().nullable()
      }),
      city: YupString().when('countryCodeKey', {
        is: countryCodeKey => countryCodeKey?.value === CountryEnum.POLAND,
        then: YupString().required().nullable(),
        otherwise: YupString().nullable()
      }),
      street: YupString().nullable(),
      propertyNumber: YupString().when('countryCodeKey', {
        is: countryCodeKey => countryCodeKey?.value === CountryEnum.POLAND,
        then: YupString().required().nullable(),
        otherwise: YupString().nullable()
      }),

      commune: YupString().when('countryCodeKey', {
        is: countryCodeKey => countryCodeKey?.value === CountryEnum.POLAND,
        then: YupString().required().nullable(),
        otherwise: YupString().nullable()
      }),
      county: YupString().when('countryCodeKey', {
        is: countryCodeKey => countryCodeKey?.value === CountryEnum.POLAND,
        then: YupString().required().nullable(),
        otherwise: YupString().nullable()
      }),
      firstLine: YupString().nullable(),
      secondLine: YupString().nullable()
    })
  });

export type PartnerApplicantFields = {
  lp: number;
  roleKey: SelectOption;
  sameAsMainAddress?: boolean;
  address: {
    postCode: string;
    postCity: string;
    city: string;
    street: string;
    propertyNumber: string;
    sameAsMainAddress?: boolean;
    apartmentNumber?: string;
    name?: string;
    county?: string;
    commune?: string;
    voivodeshipKey: SelectOption;
    countryCodeKey: SelectOption;
    firstLine?: string;
    secondLine?: string;
  };
};
