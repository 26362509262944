import { QueryConfig, useQuery } from 'react-query';
import { QueryParameterResultPage, QueryParameterValuesFilter } from '@stack/report';
import { AxiosResponse } from 'axios';

import { API, endpointsConfig } from '@libs/report/api';

export const getReportAllParametersValues = async (
  _,
  params: {
    reportTypeId: string;
    queryParameterValuesFilter: QueryParameterValuesFilter;
    page?: number;
    size?: number;
    sort?: string[];
    isUsedInFetchAutocomplete?: boolean;
  }
) => {
  const { reportTypeId, queryParameterValuesFilter, isUsedInFetchAutocomplete, page, size, sort } = params;
  const response = await API.reportTypes.postParameterValues(
    reportTypeId,
    queryParameterValuesFilter,
    page,
    size,
    sort,
    { ...endpointsConfig.updateParameterValues }
  );

  if (isUsedInFetchAutocomplete) {
    return response as AxiosResponse<QueryParameterResultPage>;
  }

  return response.data;
};

function useReportAllParametersValuesQuery(
  params: {
    queryKey: string;
    reportTypeId: string;
    displayName: string;
    queryParameterValuesFilter: QueryParameterValuesFilter;
    isUsedInFetchAutocomplete?: boolean;
  },
  queryConfig?: QueryConfig<QueryParameterResultPage | AxiosResponse<QueryParameterResultPage>>
) {
  const { queryKey, reportTypeId, displayName, queryParameterValuesFilter, isUsedInFetchAutocomplete } = params;
  return useQuery<QueryParameterResultPage | AxiosResponse<QueryParameterResultPage>>(
    [queryKey, { reportTypeId, displayName, queryParameterValuesFilter, isUsedInFetchAutocomplete }],
    getReportAllParametersValues,
    queryConfig
  );
}

export default useReportAllParametersValuesQuery;
