import { useTranslation } from 'react-i18next';

import { partialTranslate } from '@libs/common';
import { GridLayout, InputMode, Section, TextInputField, typedNameV2 } from '@libs/common/v2';

import { FieldsType } from '../../model';

function BuyerSection({ isWithoutCorrection }: { isWithoutCorrection: boolean }) {
  const [t] = useTranslation();
  const getLabel = partialTranslate('releaseDocuments:fields');

  return (
    <Section
      title={
        isWithoutCorrection
          ? t('releaseDocuments:create.sections.subject')
          : t('releaseDocuments:create.sections.buyerCorrection')
      }
      isCollapsable
    >
      <GridLayout itemProps={{ xs: 6 }}>
        <TextInputField
          name={typedNameV2<FieldsType>('buyer.name')}
          label={getLabel('buyer.subjectName')}
          inputMode={InputMode.VIEW}
          inputProps={{ maxLength: 500 }}
          isRequired
        />
        <TextInputField
          name={typedNameV2<FieldsType>('buyer.nip')}
          label={getLabel('buyer.NIP')}
          inputMode={InputMode.VIEW}
          inputProps={{ maxLength: 10 }}
          isOnlyPositiveIntegers
          isRequired
        />
        <TextInputField
          name={typedNameV2<FieldsType>('buyer.postCode')}
          label={getLabel('buyer.zipCode')}
          inputMode={InputMode.VIEW}
          isRequired
        />
        <TextInputField
          name={typedNameV2<FieldsType>('buyer.postCity')}
          label={getLabel('buyer.zipCodeCity')}
          inputMode={InputMode.VIEW}
          inputProps={{ maxLength: 50 }}
          isRequired
        />
        <TextInputField
          name={typedNameV2<FieldsType>('buyer.city')}
          label={getLabel('buyer.city')}
          inputMode={InputMode.VIEW}
          inputProps={{ maxLength: 50 }}
          isRequired
        />
        <TextInputField
          name={typedNameV2<FieldsType>('buyer.street')}
          label={getLabel('buyer.street')}
          inputMode={InputMode.VIEW}
          inputProps={{ maxLength: 50 }}
        />
        <TextInputField
          name={typedNameV2<FieldsType>('buyer.propertyNumber')}
          label={getLabel('buyer.buildingNumber')}
          inputMode={InputMode.VIEW}
          inputProps={{ maxLength: 10 }}
          isRequired
        />
        <TextInputField
          name={typedNameV2<FieldsType>('buyer.apartmentNumber')}
          label={getLabel('buyer.apartmentNumber')}
          inputMode={InputMode.VIEW}
          inputProps={{ maxLength: 5 }}
        />
      </GridLayout>
    </Section>
  );
}

export default BuyerSection;
