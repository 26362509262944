import { useTableLayoutProps } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';
import { useClientForeignPermissionsColumns, useForeignPermissionsClientQuery } from '@libs/domain/foreign-permission';

interface TableParams {
  folderId?: string;
  associationFolderId?: string;
  isZZFolder?: boolean;
}

function useClientForeignPermissionsTable(params: TableParams) {
  const { columns, mappedFilterFields, mappedSortFields } = useClientForeignPermissionsColumns();

  return useTableLayoutProps({
    tableHookQuery: useForeignPermissionsClientQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'name', desc: false }]
        }
      }
    },
    tableHookQueryInitialParams: !params?.isZZFolder
      ? {
          folderIdIn: params?.folderId ? [params.folderId] : undefined,
          associationFolderIdIn: params?.associationFolderId ? [params?.associationFolderId] : undefined,
          formStateKeyIn: [DomainDictionaryEntry.RESOURCE_FORM_STATE.ISSUED_TO_ENTREPRENEURS]
        }
      : {
          associationFolderIdIn: params?.folderId ? [params.folderId] : [params?.associationFolderId],
          formStateKeyIn: [DomainDictionaryEntry.RESOURCE_FORM_STATE.ISSUED_TO_ENTREPRENEURS]
        }
  });
}

export default useClientForeignPermissionsTable;
