import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const useDocumentTitle = () => {
  const [t] = useTranslation();
  const setTitle = (title: string | ReactNode) => {
    if (typeof title === 'string') {
      document.title = title;
    } else if (title) {
      document.title = (Object(title)?.props?.title ?? t('app:title')) as string;
    } else t('app:title');
  };
  return { setTitle };
};

export default useDocumentTitle;
