import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Typography, useValueStyles } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { getValue } from '@libs/common/v2/utils';

import { UIElementNameEnum, useElementVisibility } from '@libs/permission';

interface Props {
  folderDetails: {
    number: string;
    typeKey: string;
  };
  actionKey?: UIElementNameEnum;
}

type FolderTypeTranslationType = 'folder:type';

function SliderHeader({ folderDetails, actionKey }: Props) {
  const [t] = useTranslation();

  const ref = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const globalClasses = useValueStyles({});
  const { checkIsElementVisible } = useElementVisibility();

  const [isTooltipAvailable, setIsTooltipAvailable] = useState<boolean>(false);

  const type = useMemo(() => {
    if (folderDetails?.typeKey) {
      return t(`folder:type.${folderDetails.typeKey.split('.').pop().toUpperCase()}` as FolderTypeTranslationType);
    }
    return t('emptyMark');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderDetails]);

  const isElementVisible = useMemo(() => {
    return actionKey ? checkIsElementVisible(actionKey) : true;
  }, [checkIsElementVisible, actionKey]);

  if (isElementVisible) {
    return (
      <div className={clsx(classes.header)}>
        {folderDetails?.number && (
          <div className={classes.folderNo}>
            <Typography themeVariant="textSm.medium" className={classes.folderNoText}>
              {getValue(folderDetails?.number)}
            </Typography>
          </div>
        )}
        {type && (
          <div
            ref={ref}
            onMouseEnter={() => {
              if (ref?.current?.offsetWidth > 150) {
                setIsTooltipAvailable(true);
              }
            }}
          >
            <Tooltip
              title={type}
              disableHoverListener={!isTooltipAvailable}
              disableFocusListener={!isTooltipAvailable}
              disableInteractive={!isTooltipAvailable}
              disableTouchListener={!isTooltipAvailable}
            >
              <div style={{ width: '100%' }}>
                <Typography
                  component="div"
                  className={clsx(globalClasses.value, classes.folderTypeContent, 'field-value truncate')}
                >
                  {type}
                </Typography>
              </div>
            </Tooltip>
            <div className="opacity-0 h-0 w-full">{type}</div>
          </div>
        )}
      </div>
    );
  }
  return null;
}

export default SliderHeader;

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
    flexGrow: 1
  },
  folderNoText: { padding: '2px 10px' },
  folderNo: {
    backgroundColor: theme.palette.grey[100],
    marginLeft: 12,
    marginRight: 16,
    color: theme.palette.grey[800],
    borderRadius: '4px',
    display: 'flex',
    height: theme.typography.textSm.medium.lineHeight as string,
    justifyContent: 'center',
    alignItems: 'center'
  },
  folderTypeContent: {
    fontWeight: theme.typography.textSm.bold.fontWeight,
    maxWidth: 135,
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  }
}));
