export enum ResourceQueryKeysEnum {
  RESOURCES = 'RESOURCES',
  DEPOT_TRANSFERS_LIST = 'DEPOT_TRANSFERS_LIST',
  RESOURCES_DETAILS = 'RESOURCES_DETAILS',
  RESOURCES_SEARCH = 'RESOURCES_SEARCH',
  ASSOCIATION_ORDERS_LIST = 'ASSOCIATION_ORDERS_LIST',
  DEFECTIVE_FORM_LIST = 'DEFECTIVE_FORM_LIST',
  RESOURCE_TYPES_LIST = 'RESOURCE_TYPES_LIST',
  RESOURCE_TYPE = 'RESOURCE_TYPE',
  AVAILABLE_RESOURCE_TYPES_LIST = 'AVAILABLE_RESOURCE_TYPES_LIST',
  DEPOTS_LIST = 'DEPOTS_LIST',
  DEPOT_DETAILS = 'DEPOT_DETAILS',
  DISPOSALS_LIST = 'DISPOSALS_LIST',
  RESOURCE_OBJECT_POOLS = 'RESOURCE_OBJECT_POOLS',
  RESOURCE_OBJECT_ASSOCIATION_POOLS = 'RESOURCE_OBJECT_ASSOCIATION_POOLS',
  RESOURCE_OBJECT_POOLS_AVAILABILITY = 'RESOURCE_OBJECT_POOLS_AVAILABILITY',
  RESOURCE_OBJECT__SINGLE_POOL_AVAILABILITY = 'RESOURCE_OBJECT__SINGLE_POOL_AVAILABILITY',
  RESOURCE_TRANSFORM_TYPE_OBJECT_POOLS = 'RESOURCE_TRANSFORM_TYPE_OBJECT_POOLS',
  RESOURCE_OBJECT_CONTENT_POOLS_SOURCE = 'RESOURCE_OBJECT_CONTENT_POOLS_SOURCE',
  RESOURCE_OBJECT_CONTENT_POOLS_TARGET = 'RESOURCE_OBJECT_CONTENT_POOLS_TARGET',
  DEPOTS_MANAGERS_LIST = 'DEPOTS_MANAGERS_LIST',
  DEPOTS_MANAGERS = 'DEPOTS_MANAGERS',
  DEPOTS_MANAGERS_LITE = 'DEPOTS_MANAGERS_LITE',
  RESOURCE_TYPES_FIELD = 'RESOURCE_TYPES_FIELD',
  RESOURCE_TYPES_FIELD_EXTERNAL_RECEIVES = 'RESOURCE_TYPES_FIELD_EXTERNAL_RECEIVES',
  ASSOCIATION_ORDER_DETAILS = 'ASSOCIATION_ORDER_DETAILS',
  AVAILABLE_RESOURCES_TO_ORDER = 'AVAILABLE_RESOURCES_TO_ORDER'
}
