import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { ResourceTypeSearchFilter } from '@ibtm/domain';
import { makeStyles } from '@mui/styles';

import { TableButtonDelete, TableLayout, Theme } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { important } from '@libs/common/v2/utils';

import { API } from '@libs/domain/api';

import { ResourceQueryKeysEnum, useDeleteResourceTypeMutation } from '../../../api';
import { useResourceTypesTable } from '../../../hooks';
import { parseResourceTypesList } from '../../../utils';

interface IProps {
  initialParams?: ResourceTypeSearchFilter;
}

function ResourceTypesTable({ initialParams = {} }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const { mutate: deleteFormTypes, isLoading } = useDeleteResourceTypeMutation();
  const tableProps = useResourceTypesTable(initialParams);

  const handleDeleteResourceType = (id: string) => {
    deleteFormTypes(id, {
      onSuccess: () => {
        queryCache.invalidateQueries(ResourceQueryKeysEnum.RESOURCE_TYPES_LIST);
        showSnackbar('success', t('resource:messages.deleteFormTypesSuccess'));
      }
    });
  };

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('resource:list.resourceTypesListTitle')}
      selectionColumnWidth={140}
      isTableGrey={false}
      sectionClassName={classes.section}
      rowActions={({ original }) => (
        <TableButtonDelete
          onClick={() => handleDeleteResourceType(original.id)}
          isDisabled={isLoading}
          isButtonLoading={isLoading}
        />
      )}
      xlsxDownload={{
        fileName: t('resource:list.resourceTypesListTitle'),
        pathToXLSXTranslation: 'resource:fields',
        apiRequest: params =>
          API.resourceTypes
            .getResourceTypes(params)
            .then(res => ({ ...res, data: { ...res.data, content: parseResourceTypesList(res.data.content) } }))
      }}
      isRefreshEnabled
      isSection
    />
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  section: {
    backgroundColor: important(theme.palette.white)
  }
}));

export default ResourceTypesTable;
