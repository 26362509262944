import React from 'react';
import { useTranslation } from 'react-i18next';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import TableIconButton from './TableIconButton';

interface IProps {
  onClick: () => void;
  tooltipTitle?: string;
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  actionKey?: UIElementNameEnum;
}

function TableButtonDownload({ isDisabled, isLoading, tooltipTitle, className, onClick, actionKey }: IProps) {
  const [t] = useTranslation();

  return (
    <TableIconButton
      tooltipTitle={tooltipTitle || t('action.download')}
      className={className}
      icon="DownloadIcon"
      onClick={onClick}
      isDisabled={isDisabled}
      isButtonLoading={isLoading}
      actionKey={actionKey}
    />
  );
}

export default React.memo(TableButtonDownload);
