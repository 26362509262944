import { useMemo } from 'react';
import { FolderSearchFilter } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import { AdditionalFilterEnum, IAdditionalFilter } from '@libs/common/v2';

interface IAdditionalFolderFilter {
  type: AdditionalFilterEnum;
  name: keyof FolderSearchFilter;
  inputProps?: Record<string, unknown>;
}

function useFolderAdditionalFilters() {
  const getLabel = partialTranslate('folder:additionalFilters.fields');
  const additionalFilters: Array<IAdditionalFilter> = useMemo((): IAdditionalFolderFilter[] => {
    return [
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'applicationNumberContains',
        inputProps: {
          label: getLabel('number')
        }
      },
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'applicationArchiveNumberContains',
        inputProps: {
          label: getLabel('archiveApplicationNumber')
        }
      },
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'carPlateNumberContains',
        inputProps: {
          label: getLabel('registration')
        }
      },
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'carVinNumberContains',
        inputProps: {
          label: getLabel('vin')
        }
      },
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'transportManagerCertificateNumberContains',
        inputProps: {
          label: getLabel('certificateNumber')
        }
      },
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'exploitationBasePostCodeContains',
        inputProps: {
          label: getLabel('postCode')
        }
      },
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'licenseNumberContains',
        inputProps: {
          label: getLabel('licenseNumber')
        }
      },
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'licenseFormNumberContains',
        inputProps: {
          label: getLabel('formLicenseNumber')
        }
      },
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'roadCarrierPermissionNumberContains',
        inputProps: {
          label: getLabel('permitionNumber')
        }
      },
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'roadCarrierPermissionFormNumberContains',
        inputProps: {
          label: getLabel('formPermitionNumber')
        }
      },
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'driverCertificateNumberContains',
        inputProps: {
          label: getLabel('certificateDriverNumber')
        }
      },
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'driverNameAndLastNameContains',
        inputProps: {
          label: getLabel('nameSurname')
        }
      },
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'numberRpwContains',
        inputProps: {
          label: getLabel('rpw')
        }
      },
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'regularLinesPermissionNumberContains',
        inputProps: {
          label: getLabel('regularPermitionNumber')
        }
      },
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'ownNeedsPermissionNumberContains',
        inputProps: {
          label: getLabel('ownNeedsCertificateNumber')
        }
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { additionalFilters };
}

export default useFolderAdditionalFilters;
