import { useTranslation } from 'react-i18next';

import { partialTranslate } from '@libs/common';
import {
  GridItem,
  GridLayout,
  InputMode,
  Section,
  TextInputField,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { FieldsType } from '../../model';

function ReceiverSection() {
  const [t] = useTranslation();
  const { formMode } = useFormV2Context();
  const getLabel = partialTranslate('releaseDocuments:fields');

  const { editMode, createMode } = useViewModesV2(formMode);
  const inputMode = editMode || createMode ? InputMode.FORM : InputMode.VIEW;

  return (
    <Section title={t('releaseDocuments:create.sections.receiver')} isCollapsable>
      <GridLayout itemProps={{ xs: 12 }}>
        <GridItem xs={6}>
          <TextInputField
            name={typedNameV2<FieldsType>('recipientName')}
            label={getLabel('receiver.name')}
            inputMode={inputMode}
            inputProps={{ maxLength: 50 }}
          />
        </GridItem>
        <GridItem xs={6}>
          <TextInputField
            name={typedNameV2<FieldsType>('recipientSurname')}
            label={getLabel('receiver.surname')}
            inputMode={inputMode}
            inputProps={{ maxLength: 80 }}
          />
        </GridItem>
      </GridLayout>
    </Section>
  );
}

export default ReceiverSection;
