import { DocumentTemplateDetailsExternal } from '@avispon/document-generator';
import {
  DataSourceMediatorDetailsSnapshot,
  ParameterExtras,
  QueryColumnProperties,
  QueryCondition,
  QueryConditionFilter,
  QueryOrder,
  QueryParameter,
  ReportDataSetDefinitionDetailsSnapshot,
  ReportTypeDetailsSnapshot
} from '@stack/report/dist/models';

import { SelectOption } from '@libs/common/v2';

export type ReportType = ReportTypeDetailsSnapshot & { sourceName: string };

export type ReportTypeForm = {
  name: string;
  description: string;
  cron: string;
  permissionKeys?: Array<{ name: string; value: string }>;
  documentTemplate: { id: string; name: string };
  source: DataSourceMediatorDetailsSnapshot;
  sourceModified: Array<boolean>;
  dataSetDefinitions: Array<DataSetDefinition>;
  queryResults: { columns: string; rows: any[][] }[];
  parameterExtras: ParameterExtras[];
};

export type DataSetDefinition = ReportDataSetDefinitionDetailsSnapshot & {
  queryParameters: Array<QueryParameter>;
  dataFileFormatKey: SelectOption<string>;
  queryDefinition: {
    columns: Array<QueryColumnProperties>;
    groups: string[];
    distinct?: boolean;
    orders?: QueryOrder[];
  };
  dataSetId: string;
  queryConditions: QueryCondition | ReportFormQueryCondition | null;
};

export type QueryOrderSettings = Omit<QueryOrder, 'column'>;

export type ReportTypeDetails = ReportTypeDetailsSnapshot & Pick<ReportTypeForm, 'permissionKeys'>;

export type DocumentTemplateSnapshot = {
  id: string;
  name: string;
  value?: DocumentTemplateDetailsExternal;
};

export interface ReportFormNamedObject {
  id?: ReportFormNamedObject;
  value?: ReportFormNamedObject;
  name?: ReportFormNamedObject;
}

export interface ReportFormQueryConditionFilter extends Omit<QueryConditionFilter, 'column' | 'operator'> {
  column?: ReportFormNamedObject;
  operator?: ReportFormNamedObject;
}

export interface ReportFormQueryCondition extends ReportFormQueryConditionFilter {
  filter?: ReportFormQueryConditionFilter;
  group?: Array<ReportFormQueryCondition>;
}

export enum DataFileFormatEnum {
  TABLE = 'reportdataset.result-file-format.table'
}
