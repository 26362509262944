import { FormMode, InputMode } from '@libs/common/v2';

import { CertificateSSectionsEnum } from '@libs/domain/application/components/tabs/certificate-s/CertificateSTab';
import { ApplicationMetaFormDefinition } from '@libs/domain/application/model';
import { VehicleSectionFields } from '@libs/domain/vehicle/';

/** w tym pliku nadpisujemy fieldy/komponenty zdefiniowane w common dla portalu klienta */
const definition: ApplicationMetaFormDefinition = {
  fields: [
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-submission-channel-key',
      properties: {
        label: 'Sposób wpływu',
        dictionaryName: 'APPLICATION_CHANNEL',
        inputMode: InputMode.VIEW,
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'applicationChannelKey'
          }
        }
      }
    },
    {
      typeKey: 'VEHICLES_COLLECTION',
      fieldId: 'application-vehicles-collection',
      properties: {
        hiddenDetailsFields: [VehicleSectionFields.COMPATIBILITY_CERTYFICATE_NUMBER],
        detailsFieldsMode: { euroClassKey: InputMode.VIEW }
      }
    },
    {
      typeKey: 'SCERTIFICATES_COLLECTION',
      fieldId: 'application-scertificates-collection',
      properties: {
        visibleSections: [CertificateSSectionsEnum.FOLDER_VEHICLES, CertificateSSectionsEnum.VEHICLES],
        api: {
          submit: {
            requestKey: 'UPDATE_SCERTIFICATES'
          }
        }
      }
    },
    {
      typeKey: 'CORRESPONDENCE_ADDRESS_BUTTON',
      fieldId: 'application-general-tab-correspondence-address-button'
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-receipt-type-key',
      properties: {
        label: 'Sposób odbioru',
        dictionaryName: 'RECEIPT_TYPE_PK',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'receiptTypeKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'receiptTypeKey'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-receipt-type-key-requested-permits',
      properties: {
        label: 'Sposób odbioru',
        dictionaryName: 'RECEIPT_TYPE_PK',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_PERMISSION_MATRIX',
            accessor: 'applicationUpdateRequest.receiptTypeKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'receiptTypeKey'
          }
        }
      }
    },
    {
      typeKey: 'FINANCIAL_SECURITY_EXCHANGE_COLLECTION',
      fieldId: 'application-financial-security-exchange-fields',
      properties: {
        formMode: FormMode.VIEW
      }
    },
    {
      typeKey: 'ADDRESSES_TAB_CONTENT',
      fieldId: 'application-addresses-tab',
      properties: {
        inputMode: {
          mainAddress: InputMode.VIEW,
          businessAddress: InputMode.VIEW,
          correspondenceAddress: InputMode.FORM
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION_ADDRESS',
            accessor: 'addresses'
          }
        }
      }
    },
    {
      typeKey: 'TRANSIT_SCHEDULE',
      fieldId: 'application-transit-schedule-collection',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_TRANSIT_SCHEDULE_FREQUENCIES'
          }
        },
        visibleSections: { isTransitScheduleVisible: true }
      }
    },
    {
      typeKey: 'DATETIME',
      fieldId: 'application-submission-date',
      properties: {
        label: 'Data wpływu',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'submissionReceiptDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'submissionReceiptDate'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-receipt-type-key-scertificate',
      properties: {
        label: 'Sposób odbioru',
        dictionaryName: 'RECEIPT_TYPE_PK',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_SCERTIFICATES',
            accessor: 'receiptTypeKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'receiptTypeKey'
          }
        }
      }
    }
  ],
  components: [
    {
      componentId: 'application-quotation-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'QUOTATION'
        },
        nodes: [
          {
            typeKey: 'SECTION',
            properties: {
              title: 'Pełnomocnicy',
              visibility: {
                elementVisibilityAsyncKeys: ['IS_PROXIES_QUOTATION_SECTION_VISIBLE']
              }
            },
            nodes: [
              {
                typeKey: 'GRID',
                properties: {
                  gridItemProps: {
                    sm: 6,
                    xs: 12
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-proxies-count'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-proxy-paid'
                    }
                  }
                ]
              }
            ]
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-quotations-collection'
            }
          }
        ]
      }
    },
    {
      componentId: 'application-subject-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'SUBJECT'
        },
        nodes: [
          {
            typeKey: 'GRID',
            properties: {
              gridContainerProps: {
                spacing: 0
              },
              gridItemProps: {
                xs: 12
              }
            },
            nodes: [
              {
                typeKey: 'SECTION',
                properties: {
                  title: 'Dane podstawowe'
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-subject-version'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-folder-typeKey'
                    }
                  },
                  {
                    typeKey: 'GRID',
                    properties: {
                      gridItemProps: {
                        xs: 12,
                        sm: 6
                      }
                    },
                    nodes: [
                      {
                        typeKey: 'GRID',
                        properties: {
                          gridContainerProps: {
                            xs: 12,
                            item: true
                          },
                          gridItemProps: {
                            xs: 6
                          }
                        },
                        nodes: [
                          {
                            typeKey: 'FIELD',
                            properties: {
                              fieldId: 'application-subject-type-key'
                            }
                          }
                        ]
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-subject-nip'
                        }
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-subject-name'
                        }
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-subject-regon'
                        }
                      },
                      {
                        typeKey: 'CONDITIONALLY_VISIBLE',
                        properties: {
                          dependendFieldId: 'application-subject-legal-form-key',
                          value: {
                            notIncludes: ['ibtm-domain.legal-nature.7']
                          }
                        },
                        nodes: [
                          {
                            typeKey: 'FIELD',
                            properties: {
                              fieldId: 'application-subject-krs-required'
                            }
                          }
                        ]
                      },
                      {
                        typeKey: 'CONDITIONALLY_VISIBLE',
                        properties: {
                          dependendFieldId: 'application-subject-legal-form-key',
                          value: {
                            includes: ['ibtm-domain.legal-nature.7']
                          }
                        },
                        nodes: [
                          {
                            typeKey: 'FIELD',
                            properties: {
                              fieldId: 'application-subject-krs'
                            }
                          }
                        ]
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-subject-legal-form-key'
                        }
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-subject-given-name'
                        }
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-subject-surname'
                        }
                      }
                    ]
                  }
                ]
              },
              {
                typeKey: 'SECTION',
                properties: {
                  title: 'Dane kontaktowe'
                },
                nodes: [
                  {
                    typeKey: 'GRID',
                    properties: {
                      gridItemProps: {
                        xs: 12,
                        sm: 6
                      }
                    },
                    nodes: [
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-subject-phone-number'
                        }
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-subject-email'
                        }
                      }
                    ]
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-subject-legal-form-key',
                  value: {
                    includes: ['ibtm-domain.legal-nature.6', 'ibtm-domain.legal-nature.d']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-entrepreneurs-collection'
                    }
                  }
                ]
              },
              {
                slotId: 'application-section-end'
              }
            ]
          }
        ]
      }
    },
    {
      componentId: 'application-general-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'GENERAL'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-warnings'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-version'
            }
          },
          {
            typeKey: 'SECTION',
            properties: {
              title: 'Wniosek'
            },
            nodes: [
              {
                typeKey: 'GRID',
                properties: {
                  gridItemProps: {
                    xs: 12,
                    sm: 6
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-submission-date'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-submission-channel-key'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-created-date'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-author'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-concern-license'
                    }
                  },
                  {
                    slotId: 'application-section'
                  }
                ]
              }
            ]
          },
          {
            typeKey: 'COMPONENT',
            properties: {
              componentId: 'application-general-tab-delivery-channel-section'
            }
          },
          {
            slotId: 'additional-section'
          }
        ]
      }
    },
    {
      componentId: 'application-general-tab-delivery-channel-section',
      node: {
        typeKey: 'SECTION',
        properties: {
          title: 'Sposób odbioru'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-send-to-address-flag'
            }
          },
          {
            typeKey: 'GRID',
            properties: {
              gridItemProps: {
                xs: 12,
                md: 6
              }
            },
            nodes: [
              {
                typeKey: 'FIELD',
                properties: {
                  fieldId: 'application-receipt-type-key'
                }
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-send-to-address-flag',
                  value: {
                    includes: ['PK', 'PO_PK']
                  }
                },
                nodes: [
                  {
                    typeKey: 'CONDITIONALLY_VISIBLE',
                    properties: {
                      dependendFieldId: 'application-receipt-type-key',
                      value: {
                        notIncludes: ['ibtm-domain.receipt.type.pickup-in-person', 'ibtm-domain.receipt.type.e-mail']
                      }
                    },
                    nodes: [
                      {
                        typeKey: 'GRID',
                        properties: {
                          gridItemProps: {
                            xs: 12,
                            sm: 6
                          }
                        },
                        nodes: [
                          {
                            typeKey: 'FIELD',
                            properties: {
                              fieldId: 'application-send-to-address-required',
                              gridItemProps: {
                                xs: 9,
                                md: 10
                              }
                            }
                          },
                          {
                            typeKey: 'FIELD',
                            properties: {
                              fieldId: 'application-general-tab-correspondence-address-button',
                              gridItemProps: {
                                xs: 3,
                                md: 2
                              }
                            }
                          }
                        ]
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-send-to-address-type-key',
                          gridItemProps: {
                            xs: 12,
                            sm: 6
                          }
                        }
                      }
                    ]
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-send-to-address-flag',
                  value: {
                    notIncludes: ['PK', 'PO_PK']
                  }
                },
                nodes: [
                  {
                    typeKey: 'CONDITIONALLY_VISIBLE',
                    properties: {
                      dependendFieldId: 'application-receipt-type-key',
                      value: {
                        notIncludes: ['ibtm-domain.receipt.type.pickup-in-person', 'ibtm-domain.receipt.type.e-mail']
                      }
                    },
                    nodes: [
                      {
                        typeKey: 'GRID',
                        properties: {
                          gridItemProps: {
                            xs: 12,
                            sm: 6
                          }
                        },
                        nodes: [
                          {
                            typeKey: 'FIELD',
                            properties: {
                              fieldId: 'application-send-to-address-notRequired',
                              gridItemProps: {
                                xs: 9,
                                md: 10
                              }
                            }
                          },
                          {
                            typeKey: 'FIELD',
                            properties: {
                              fieldId: 'application-general-tab-correspondence-address-button',
                              gridItemProps: {
                                xs: 3,
                                md: 2
                              }
                            }
                          },
                          {
                            typeKey: 'FIELD',
                            properties: {
                              fieldId: 'application-send-to-address-type-key'
                            }
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key',
                  value: {
                    includes: ['ibtm-domain.receipt.type.pickup-in-person']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-send-to-address-name'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key',
                  value: {
                    includes: ['ibtm-domain.receipt.type.pickup-in-person']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-send-to-address-surname'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key',
                  value: {
                    includes: ['ibtm-domain.receipt.type.e-mail']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-subject-email-view'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key',
                  value: {
                    includes: ['ibtm-domain.receipt.type.courier']
                  }
                },
                nodes: [
                  {
                    typeKey: 'WARNING',
                    properties: {
                      content: 'Wysyłka realizowana na koszt przedsiębiorcy.'
                    }
                  }
                ]
              },
              {
                slotId: 'additional-field'
              }
            ]
          }
        ]
      }
    },
    {
      componentId: 'application-general-tab-delivery-channel-section-requested-permits',
      node: {
        typeKey: 'SECTION',
        properties: {
          title: 'Sposób odbioru'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-send-to-address-flag'
            }
          },
          {
            typeKey: 'GRID',
            properties: {
              gridItemProps: {
                xs: 12,
                md: 6
              }
            },
            nodes: [
              {
                typeKey: 'FIELD',
                properties: {
                  fieldId: 'application-receipt-type-key-requested-permits'
                }
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-send-to-address-flag',
                  value: {
                    includes: ['PK', 'PO_PK']
                  }
                },
                nodes: [
                  {
                    typeKey: 'CONDITIONALLY_VISIBLE',
                    properties: {
                      dependendFieldId: 'application-receipt-type-key-requested-permits',
                      value: {
                        notIncludes: ['ibtm-domain.receipt.type.pickup-in-person', 'ibtm-domain.receipt.type.e-mail']
                      }
                    },
                    nodes: [
                      {
                        typeKey: 'GRID',
                        properties: {
                          gridItemProps: {
                            xs: 12,
                            sm: 6
                          }
                        },
                        nodes: [
                          {
                            typeKey: 'FIELD',
                            properties: {
                              fieldId: 'application-send-to-address-required-requested-permits',
                              gridItemProps: {
                                xs: 9,
                                md: 10
                              }
                            }
                          },
                          {
                            typeKey: 'FIELD',
                            properties: {
                              fieldId: 'application-general-tab-correspondence-address-button',
                              gridItemProps: {
                                xs: 3,
                                md: 2
                              }
                            }
                          },
                          {
                            typeKey: 'FIELD',
                            properties: {
                              fieldId: 'application-send-to-address-type-key-requested-permits'
                            }
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-send-to-address-flag',
                  value: {
                    notIncludes: ['PK', 'PO_PK']
                  }
                },
                nodes: [
                  {
                    typeKey: 'CONDITIONALLY_VISIBLE',
                    properties: {
                      dependendFieldId: 'application-send-to-address-required-requested-permits',
                      value: {
                        notIncludes: ['ibtm-domain.receipt.type.pickup-in-person', 'ibtm-domain.receipt.type.e-mail']
                      }
                    },
                    nodes: [
                      {
                        typeKey: 'GRID',
                        properties: {
                          gridItemProps: {
                            xs: 12,
                            sm: 6
                          }
                        },
                        nodes: [
                          {
                            typeKey: 'FIELD',
                            properties: {
                              fieldId: 'application-send-to-address-notRequired-requested-permits',
                              gridItemProps: {
                                xs: 9,
                                md: 10
                              }
                            }
                          },
                          {
                            typeKey: 'FIELD',
                            properties: {
                              fieldId: 'application-general-tab-correspondence-address-button',
                              gridItemProps: {
                                xs: 3,
                                md: 2
                              }
                            }
                          },
                          {
                            typeKey: 'FIELD',
                            properties: {
                              fieldId: 'application-send-to-address-type-key-requested-permits'
                            }
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key-requested-permits',
                  value: {
                    includes: ['ibtm-domain.receipt.type.pickup-in-person']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-send-to-address-name-requested-permits'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key-requested-permits',
                  value: {
                    includes: ['ibtm-domain.receipt.type.pickup-in-person']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-send-to-address-surname-requested-permits'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key-requested-permits',
                  value: {
                    includes: ['ibtm-domain.receipt.type.e-mail']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-subject-email-view'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key-requested-permits',
                  value: {
                    includes: ['ibtm-domain.receipt.type.courier']
                  }
                },
                nodes: [
                  {
                    typeKey: 'WARNING',
                    properties: {
                      content: 'Wysyłka realizowana na koszt przedsiębiorcy.'
                    }
                  }
                ]
              },
              {
                slotId: 'additional-field'
              }
            ]
          }
        ]
      }
    },
    {
      componentId: 'application-general-tab-delivery-channel-section-scertificate',
      node: {
        typeKey: 'SECTION',
        properties: {
          title: 'Sposób odbioru'
        },
        nodes: [
          {
            typeKey: 'GRID',
            properties: {
              gridItemProps: {
                xs: 12,
                md: 6
              }
            },
            nodes: [
              {
                typeKey: 'FIELD',
                properties: {
                  fieldId: 'application-receipt-type-key-scertificate'
                }
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key-scertificate',
                  value: {
                    notIncludes: ['ibtm-domain.receipt.type.pickup-in-person', 'ibtm-domain.receipt.type.e-mail']
                  }
                },
                nodes: [
                  {
                    typeKey: 'GRID',
                    properties: {
                      gridItemProps: {
                        xs: 12,
                        sm: 6
                      }
                    },
                    nodes: [
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-send-to-address-required-scertificate',
                          gridItemProps: {
                            xs: 9,
                            md: 10
                          }
                        }
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-general-tab-correspondence-address-button',
                          gridItemProps: {
                            xs: 3,
                            md: 2
                          }
                        }
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-send-to-address-type-key-scertificate'
                        }
                      }
                    ]
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key-scertificate',
                  value: {
                    includes: ['ibtm-domain.receipt.type.pickup-in-person']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-send-to-address-name-scertificate'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key-scertificate',
                  value: {
                    includes: ['ibtm-domain.receipt.type.pickup-in-person']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-send-to-address-surname-scertificate'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key-scertificate',
                  value: {
                    includes: ['ibtm-domain.receipt.type.e-mail']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-subject-email-view'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key-scertificate',
                  value: {
                    includes: ['ibtm-domain.receipt.type.courier']
                  }
                },
                nodes: [
                  {
                    typeKey: 'WARNING',
                    properties: {
                      content: 'Wysyłka realizowana na koszt przedsiębiorcy.'
                    }
                  }
                ]
              },
              {
                slotId: 'additional-field'
              }
            ]
          }
        ]
      }
    },
    {
      componentId: 'application-requested-permits-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'REQUESTED_PERMITS'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-warnings'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-version-requested-permits'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-submission-date-hidden-requested-permits'
            }
          },
          {
            typeKey: 'COMPONENT',
            properties: {
              componentId: 'application-general-tab-delivery-channel-section-requested-permits'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'foreignPermissionApplicationDetails-typeKey'
            }
          },
          {
            typeKey: 'CONDITIONALLY_VISIBLE',
            properties: {
              dependendFieldId: 'foreignPermissionApplicationDetails-typeKey',
              value: {
                includes: ['ibtm-domain.foreign-permit-type.ekmt-permits']
              }
            },
            nodes: [
              {
                typeKey: 'FIELD',
                properties: {
                  fieldId: 'application-permissions-matrix-collection',
                  resourceType: 'ibtm-domain.resource.form-type.single-permit'
                }
              }
            ]
          },
          {
            typeKey: 'CONDITIONALLY_VISIBLE',
            properties: {
              dependendFieldId: 'foreignPermissionApplicationDetails-typeKey',
              value: {
                includes: ['ibtm-domain.foreign-permit-type.single-permits']
              }
            },
            nodes: [
              {
                typeKey: 'FIELD',
                properties: {
                  fieldId: 'application-permissions-matrix-collection',
                  resourceType: 'ibtm-domain.resource.form-type.single-permit'
                }
              }
            ]
          }
        ]
      }
    },
    {
      componentId: 'application-financial-security-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'FINANCIAL_SECURITY'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-folder-typeKey'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-financial-security-collection'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-financial-security-exchange-fields'
            }
          },
          {
            typeKey: 'GRID',
            properties: {
              gridItemProps: {
                sm: 12,
                xs: 12
              }
            },
            nodes: [
              {
                typeKey: 'FIELD',
                properties: {
                  fieldId: 'application-financial-security-summary-present-amount-pln',
                  hidden: true
                }
              }
            ]
          }
        ]
      }
    }
  ],
  layout: []
};
export default definition;
