import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs as MuiBreadcrumbs, Link as MuiLink, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

export interface IBreadcrumbsItem {
  to?: string;
  text: string;
  loading?: boolean;
}

interface IProps {
  items: IBreadcrumbsItem[];
  className?: string;
}

function BreadcrumbSkeleton() {
  return <Skeleton variant="text" height={20} animation="wave" width={256} />;
}

function Breadcrumbs({ items, className }: IProps) {
  const classes = useStyles();

  const renderBreadcrumbs = () =>
    items.map((item, index) => {
      const keyIndex = item?.text ? `${item.text}_${index}` : `breadcrumb-loading-${index}`;
      if (item?.loading || !item?.text) {
        return <BreadcrumbSkeleton key={keyIndex} />;
      }
      return index < items.length - 1 ? (
        <Link key={keyIndex} style={{ textDecoration: 'none', color: 'unset' }} to={item?.to ? item.to : undefined}>
          <MuiLink className={classes.link}>{item?.text}</MuiLink>
        </Link>
      ) : (
        <span key={keyIndex}>{item?.text}</span>
      );
    });

  return <MuiBreadcrumbs className={clsx(classes.breadcrumbs, className)}>{renderBreadcrumbs()}</MuiBreadcrumbs>;
}

const useStyles = makeStyles<Theme>(theme => ({
  breadcrumbs: {
    ...theme.typography.textSm.normal,
    fontSize: important(theme.typography.textSm.normal.fontSize),
    fontWeight: important(theme.typography.textSm.semibold.fontWeight) as unknown as number,
    color: theme.palette.grey[600]
  },
  link: {
    cursor: 'pointer'
  }
}));

export default Breadcrumbs;
