import { useMutation } from 'react-query';
import { TransitDatesCreateRequest } from '@ibtm/domain/dist/models';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export function createTransitDate({
  transitScheduleId,
  transitDatesCreateRequest
}: {
  transitScheduleId: string;
  transitDatesCreateRequest: TransitDatesCreateRequest;
}) {
  return API.transitSchedule.addTransitDates(transitScheduleId, transitDatesCreateRequest, {
    ...endpointsConfig.createTransitDate
  });
}

function useCreateTransitDateMutation() {
  return useMutation(createTransitDate);
}

export default useCreateTransitDateMutation;
