import { FolderRentalDetails } from '@ibtm/domain';

import { convertCalendarDate } from '@libs/common/v2/utils';

import { BorrowFolderSnapshot } from '../model';

export const borrowFolderListParser = (data: FolderRentalDetails[]): BorrowFolderSnapshot[] =>
  data.map(item => borrowFolderParser(item));

export const borrowFolderParser = (item: FolderRentalDetails): BorrowFolderSnapshot => ({
  id: item.id,
  folder: {
    id: item.folderId,
    number: item.folderNumber
  },
  subject: {
    nip: item.nip,
    name: item.subjectName
  },
  typeKey: item.folderTypeKey,
  archiveIssuer: item.releasedBy.name,
  archiveReceiver: item.receivedBy?.name,
  borrowDate: convertCalendarDate(item.borrowDate),
  borrowPurpose: item.purposeKey,
  borrower: item.borrowedBy.name as never,
  returnDate: convertCalendarDate(item.returnDate),
  returnPerson: item.returnedBy?.name,
  version: item.version,
  localizationKey: item.folderLocalizationKey
});
