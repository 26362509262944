import { GroupPermissionExternal, ReplacementDetailsExternal } from '@avispon/group/dist/models';
import { AccountCreateCommand, AccountCreateCommandRole, UpdateAccountDataRequest } from '@avispon/user';

import { IGroupPermissionClient, UserFormValues } from '@libs/user/models';
import { ReplacementsSnapshot } from '@libs/user/models/replacements.model';

export const updateAccountRequestParser = (values: UserFormValues): UpdateAccountDataRequest => ({
  additionalInformation: values.additionalInformation,
  name: values.personalData?.name,
  serviceNumber: values.serviceNumber,
  surname: values.personalData?.surname,
  personalNumber: values.personalData?.personalNumber,
  version: values.version,
  email: values.email,
  code: values.code || null,
  positionKey: values.positionKey || null,
  workSystem: {
    typeKey: values.workSystem?.typeKey || null,
    workHours: values.workSystem?.workHours || null
  }
});

export const createAccountRequestParser = (
  values: UserFormValues,
  role: AccountCreateCommandRole = AccountCreateCommandRole.OPERATOR
): AccountCreateCommand & { personalNumber?: string; code?: string; includeSubordinates?: boolean } => {
  const arrayGroups = Array.from(values?.groups ?? []);

  const groups = arrayGroups.map(group => {
    return {
      groupId: group.groupId,
      includeSubordinates: group.includeSubordinates
    };
  });

  return {
    additionalInformation: values.additionalInformation,
    name: values.personalData?.name,
    // W przypadku gdy pole `serviceNumber` nie jest używane należy przekazać do API wartość `null`
    serviceNumber: values.serviceNumber || null,
    surname: values.personalData?.surname,
    unitId: values.organizationUnit?.id,
    password: values.password?.length ? values.password : null,
    email: values.email,
    personalNumber: values.personalData?.personalNumber,
    code: values.code || null,
    positionKey: values.positionKey || null,
    role,
    groups,
    workSystem: {
      typeKey: values.workSystem?.typeKey || null,
      workHours: values.workSystem?.workHours || null
    },
    includeSubordinates: true
  };
};

export const parseUserReplacements = (data: ReplacementDetailsExternal[]): ReplacementsSnapshot[] =>
  data.map(obj => obj);

export const parsePermissionGroups = (data: Array<GroupPermissionExternal>): Array<IGroupPermissionClient> => {
  return data.map((group, index) => parsePermissionGroup(group, index));
};

const parsePermissionGroup = (group: GroupPermissionExternal, index: number): IGroupPermissionClient => {
  return {
    ...group,
    id: index
  };
};
