import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderSnapshot } from '@ibtm/domain';

import { AutocompleteSelectField, typedNameV2 } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';

import { useApplicationCategoriesQuery } from '@libs/domain/application-definitions';
import { DomainDictionaryEnum } from '@libs/domain/config';

import { ApplicationCreateFormValues } from '../../model';

function ApplicationCategorySelectField({
  folderData,
  onChange
}: {
  folderData: FolderSnapshot;
  onChange?: () => void;
}) {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();
  const folderTypeKey = useMemo(() => folderData?.typeKey, [folderData]);

  const { data, isLoading } = useApplicationCategoriesQuery(
    { folderTypeKeyIn: [folderTypeKey] },
    { enabled: Boolean(folderTypeKey), staleTime: 1000 * 60 }
  );

  const isDisabled = !folderData;

  const options = useMemo(() => {
    return data?.content?.map(item => ({
      name: translate(DomainDictionaryEnum.APPLICATION_CATEGORY, item),
      value: item
    }));
  }, [data?.content, translate]);

  return (
    <AutocompleteSelectField
      options={options}
      tooltip={isDisabled && t('applications:create.folderIsRequiredForCategoryField')}
      name={typedNameV2<ApplicationCreateFormValues>('categoryKey')}
      label={t('applications:field.categoryKey')}
      onChangeCustom={onChange}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isRequired
    />
  );
}

export default ApplicationCategorySelectField;
