import { isUndefined } from 'lodash';

import { IRegonData, RegonDataModel } from '../model/regon.model';

export const parseRegonData = (data: IRegonData): RegonDataModel => ({
  name: data?.name,
  shortenName: data?.shortenName,
  basicLegalForm: {
    symbol: data?.basicLegalForm?.symbol,
    name: data?.basicLegalForm?.name
  },
  detailedLegalForm: {
    symbol: data?.detailedLegalForm?.symbol,
    name: data?.detailedLegalForm?.name
  },
  address: {
    postCode: data?.address?.postCode,
    postCitySymbol: data?.address?.postCitySymbol,
    postCityName: data?.address?.postCityName,
    citySymbol: data?.address?.citySymbol,
    cityName: data?.address?.cityName,
    streetSymbol: data?.address?.streetSymbol,
    streetName: data?.address?.streetName,
    propertyNumber: data?.address?.propertyNumber,
    apartmentNumber: data?.address?.apartmentNumber,
    countrySymbol: data?.address?.countrySymbol,
    countryName: data?.address?.countryName,
    voivodeshipSymbol: data?.address?.voivodeshipSymbol,
    voivodeshipName: data?.address?.voivodeshipName,
    districtSymbol: data?.address?.districtSymbol,
    districtName: data?.address?.districtName,
    communitySymbol: data?.address?.communitySymbol,
    communityName: data?.address?.communityName,
    unusualLocation: data?.address?.unusualLocation,
    entityName: data?.address?.entityName
  },
  regon: data?.regon,
  nip: data?.nip,
  businessStartDate: data?.businessStartDate,
  businessSuspensionDate: data?.businessSuspensionDate,
  businessEndDate: data?.businessEndDate,
  businessRenewalDate: data?.businessRenewalDate
});

export const addressParse = (data: RegonDataModel) => ({
  postCity: data?.address?.postCityName,
  postCode: data?.address?.postCode,
  voivodeshipKey: !isUndefined(data?.address?.voivodeshipSymbol)
    ? `PL-${data?.address?.voivodeshipSymbol}`
    : data?.address?.voivodeshipName,
  street: data?.address?.streetName,
  city: data?.address?.cityName,
  apartmentNumber: data?.address?.apartmentNumber,
  propertyNumber: data?.address?.propertyNumber,
  commune: data?.address?.communityName
});
