import { ResourceObjectExternalReleaseCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function externalRelease(requestData: ResourceObjectExternalReleaseCreateRequest) {
  return API.resourceObjects.externalReleaseResourceObject(requestData, {
    ...endpointsConfig.externalReleaseResourceObject
  });
}

function useExternalReleaseMutation() {
  return useMutation(externalRelease);
}

export default useExternalReleaseMutation;
