import { CivicBoardRuleCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function tradeRulesCreate(civicBoardRuleCreateRequest: CivicBoardRuleCreateRequest) {
  return API.civicBoard.createCivicBoardRule(civicBoardRuleCreateRequest, {
    ...endpointsConfig.createCivicBoardRule
  });
}

function useCreateTradeRulesMutation(config) {
  return useMutation(tradeRulesCreate, config);
}

export default useCreateTradeRulesMutation;
