import { DepotManagerUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function addDepotAdmins(params: DepotManagerUpdateRequest) {
  return API.depotManagers.addNewDepotManagers(params, {
    ...endpointsConfig.createDepotManager
  });
}

function useAddDepotAdminsMutation() {
  return useMutation(addDepotAdmins);
}

export default useAddDepotAdminsMutation;
