import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { RenounceCopySnapshot } from '@ibtm/domain';

import { partialTranslate, TranslationFrom } from '@libs/common';
import { CamelCasePath, TableButtonDelete, useConfirmDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { ApplicationQueryKeysEnum } from '@libs/domain/application';
import { parseLicenseCopiesList } from '@libs/domain/license';
import {
  parsePermissionCopiesList,
  PermissionCopiesColumnsEnum,
  PermissionCopiesTable,
  PermissionCopySnapshotClient
} from '@libs/domain/permission';

import { useDeleteRenounceCopyMutation } from './api';

function DischargeCopyTable({
  applicationId,
  titleKey,
  data,
  isDataLoading
}: {
  applicationId: string;
  titleKey: TranslationFrom<'permission:title.permissionCopies'>;
  data?: RenounceCopySnapshot;
  isDataLoading?: boolean;
}) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();

  const [confirm] = useConfirmDialog();
  const getLabel = partialTranslate('permission:action.deleteCopies');

  const [tableData, setTableData] = useState(null);

  useEffect(() => {
    if (data) {
      setTableData(
        titleKey === 'licenseCopies'
          ? parseLicenseCopiesList(data.licenseCopies)
          : parsePermissionCopiesList(data.permissionCopies)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const visibleColumns: Array<CamelCasePath<PermissionCopySnapshotClient>> = [
    PermissionCopiesColumnsEnum.LICENSE_NUMBER,
    PermissionCopiesColumnsEnum.FORM_NUMBER,
    PermissionCopiesColumnsEnum.VALID_FROM,
    PermissionCopiesColumnsEnum.VALID_TO,
    PermissionCopiesColumnsEnum.PRINT_OF_DATE,
    PermissionCopiesColumnsEnum.DATE_OF_ISSUE,
    PermissionCopiesColumnsEnum.SIGNER_NAME,
    PermissionCopiesColumnsEnum.STATUS
  ];

  const { mutate: deleteMutation } = useDeleteRenounceCopyMutation({
    onSuccess: () => {
      showSuccessSnackbar(t('global:success.save'));
    }
  });

  const handleDelete = (original: PermissionCopySnapshotClient) => {
    const deleteData =
      titleKey === 'licenseCopies'
        ? {
            licenseIds: [original.id],
            permissionIds: [],
            version: data.version
          }
        : {
            licenseIds: [],
            permissionIds: [original.id],
            version: data.version
          };

    confirm({
      title: getLabel('dialogTitle'),
      message: getLabel('confirmationText'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);

        deleteMutation(
          {
            applicationId,
            renounceCopyDeleteRequest: deleteData
          },
          {
            onSuccess: () => {
              showSuccessSnackbar(t('global:success.delete'));
              queryCache.invalidateQueries(ApplicationQueryKeysEnum.RENOUNCE_COPY);
              closeDialog();
            },
            onSettled: () => {
              setConfirmLoading(false);
              closeDialog();
            }
          }
        );
      },
      confirmType: 'danger'
    });
  };

  return (
    <PermissionCopiesTable
      applicationId={applicationId}
      isRefreshEnabled={false}
      isLoading={isDataLoading}
      titleKey={titleKey}
      rowActions={row => <TableButtonDelete onClick={() => handleDelete(row.original)} />}
      tableData={tableData ?? {}}
      visibleColumns={visibleColumns}
    />
  );
}

export default DischargeCopyTable;
