enum TaskKeyEnum {
  UPDATE_APPLICATION_DATA_USER_TASK = 'updateApplicationDataUserTask',
  VERIFY_APPLICATION_COMPLETENESS_USER_TASK = 'verifyApplicationCompletenessUserTask',
  VERIFY_APPLICATION_USER_TASK = 'verifyApplicationUserTask',
  ACCEPT_DECISION_USER_TASK = 'acceptDecisionUserTask',
  CONFIRM_DECISION_DOCUMENTS_SIGNED_USER_TASK = 'confirmDecisionDocumentsSignedUserTask',
  CONFIRM_DOCUMENTS_ISSUED_USER_TASK = 'confirmDocumentsIssuedUserTask',
  FILL_DOCUMENTS_RECEIPT_ACK_DATE_USER_TASK = 'fillDocumentsReceiptAckDateUserTask'
}

export default TaskKeyEnum;
