import { usePaginatedQuery } from 'react-query';
import { DefaultApiListMyGroupMembershipsRequest } from '@avispon/group';
import { PageGroupMembershipDetailsExternal } from '@avispon/group/dist/models';

import { API, endpointsConfig, UserQueryKeysEnum } from '..';

const getMyGroupMemberships = async (_, params: DefaultApiListMyGroupMembershipsRequest) => {
  const { data } = await API.group.listMyGroupMemberships({ ...params }, { ...endpointsConfig.listMyGroupMemberships });
  return data;
};

export default function useGroupListQuery(params: DefaultApiListMyGroupMembershipsRequest, queryConfig = {}) {
  return usePaginatedQuery<PageGroupMembershipDetailsExternal>(
    [UserQueryKeysEnum.PROFILE_GROUP_PERMISSIONS, params],
    getMyGroupMemberships,
    queryConfig
  );
}
