import { useState } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';

import { AutocompleteSelectField, InputMode, useQuickChangeDictionary } from '@libs/common/v2';

import { DictionaryQuickChangeableEntryNameEnum } from '@libs/dictionary';

interface IProps {
  name: FieldPath<FieldValues>;
  isMultiple?: boolean;
  parentKey?: string;
  valueClassName?: string;
  inputMode?: InputMode;
  dictionary: DictionaryQuickChangeableEntryNameEnum;
  isDisabled?: boolean;
  className?: string;
  hasErrorTooltip?: boolean;
}

function QuickChangeDictionarySelectField({
  parentKey,
  dictionary,
  name,
  isMultiple = true,
  hasErrorTooltip,
  ...forwardProps
}: IProps) {
  const [value, setValue] = useState('');
  const { isLoading, data } = useQuickChangeDictionary(dictionary, parentKey, value);

  const handleOnInputChange = (_, inputValue) => {
    setValue(inputValue);
  };

  return (
    <AutocompleteSelectField
      name={name}
      options={data}
      isLoading={isLoading}
      onInputChange={handleOnInputChange}
      isMultiple={isMultiple}
      hasErrorTooltip={hasErrorTooltip}
      {...forwardProps}
    />
  );
}

export default QuickChangeDictionarySelectField;
