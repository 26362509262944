import { MutateConfig } from 'react-query';
import { AccountSnapshot, UpdateAccountDataRequest } from '@avispon/user/dist/models';
import { AxiosError } from 'axios';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '..';

const editUser = async ({ accountId, request }: { accountId: string; request: UpdateAccountDataRequest }) => {
  const { data } = await API.user.accountController.updateAccountData(
    { accountId, body: request },
    {
      ...endpointsConfig.updateAccountData
    }
  );
  return data;
};

export default function useEditUserMutation(config: MutateConfig<AccountSnapshot, AxiosError>) {
  return useMutation(editUser, config);
}
