import { SuspensionCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function createSuspension(suspensionCreateRequest: SuspensionCreateRequest) {
  return API.suspensions.createSuspension(suspensionCreateRequest, {
    ...endpointsConfig.createSuspension
  });
}

function useCreateSuspensionMutation() {
  return useMutation(createSuspension);
}

export default useCreateSuspensionMutation;
