import { ResourceTypeCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function createResourceType(requestData: ResourceTypeCreateRequest) {
  return API.resourceTypes.createResourceType(requestData, {
    ...endpointsConfig.createResourceType
  });
}

function useCreateResourceTypeMutation() {
  return useMutation(createResourceType);
}

export default useCreateResourceTypeMutation;
