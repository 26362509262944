import { useTranslation } from 'react-i18next';

import { useRegenerateResourceAssignmentMutation } from '@libs/domain/print/api';
import {
  usePrintsActionsContext,
  usePrintsActionsDataContext,
  usePrintsDetailsDataContext
} from '@libs/domain/print/context';
import { MultiplePermissionRegenerateForm } from '@libs/domain/print/model';

import { RegeneratePermissionDialogBase } from './common/RegeneratePermissionDialogBase';
import { RegenerateMultiplePermissionDialogContent } from './RegenerateMultiplePermissionDialogContent';

const formInitialValues: MultiplePermissionRegenerateForm = {
  selectedForms: [],
  areAllNumberSelected: true,
  reasonsForReprinting: null
};

export function RegenerateMultiplePermissionDialog() {
  const [t] = useTranslation();
  const { printDetailsData } = usePrintsDetailsDataContext();
  const {
    depotMPD: { id: depotId }
  } = printDetailsData;
  const {
    actionData: { resourcesReassignments, onSuccess }
  } = usePrintsActionsDataContext() ?? {};
  const { finishAction } = usePrintsActionsContext() ?? {};

  const { mutateAsync: regenerate } = useRegenerateResourceAssignmentMutation();

  const queryConfig = {
    onSuccess: () => {
      onSuccess();
      finishAction();
    }
  };

  return (
    <RegeneratePermissionDialogBase
      title={t('prints:actions.regenerate')}
      confirmText={t('prints:actions.regenerate')}
      defaultValues={formInitialValues}
      onSubmit={async formData => {
        const { reasonsForReprinting, selectedForms, areAllNumberSelected } = formData;
        return regenerate(
          {
            depotId,
            assignment: (areAllNumberSelected
              ? resourcesReassignments.resources
              : selectedForms.map(item => item.value)
            ).map(item => ({ ...item, reprintReasonKey: reasonsForReprinting.value }))
          },
          queryConfig
        );
      }}
    >
      <RegenerateMultiplePermissionDialogContent />
    </RegeneratePermissionDialogBase>
  );
}
