import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Property } from 'csstype';

import { FooterLayout, useNavbar, VersionText } from '@libs/common/layout';
import { Theme, useRouter } from '@libs/common/v2';
import { hexToRgba, important } from '@libs/common/v2/utils';

import { useAuth } from '@libs/auth/hooks';

import { ILink } from './footer.model';
import MobileFooterLayout from './MobileFooterLayout';

interface IProps {
  isVersionPrefixHidden?: boolean;
  appOwnerName?: string;
  version?: string;
  isBuildVersionVisible?: boolean;
  isMobile?: boolean;
}

function Footer({ isVersionPrefixHidden, appOwnerName, version, isBuildVersionVisible, isMobile }: IProps) {
  const { isFolded, foldedAndOpened } = useNavbar();
  const { isLoggedIn, user } = useAuth();
  const { location } = useRouter();

  const classes = useStyles({ isLoggedIn, location });
  const showNavigationFooter = !isFolded || foldedAndOpened;

  const links: ILink[] = [
    {
      label: 'menu.loginPage',
      url: '/login',
      isHiddenAfterLogin: user?.role && location?.pathname !== '/login'
    },
    {
      label: 'menu.signature',
      url: '/signature'
    },
    {
      label: 'menu.privacyPolicy',
      url: '/privacy-policy'
    },
    {
      label: 'menu.cookiesPolicy',
      url: '/cookies-policy'
    },

    {
      label: 'menu.rodo',
      url: '/rodo'
    },
    {
      label: 'menu.sitemap',
      url: '/sitemap'
    },

    {
      label: 'menu.contact',
      url: '/contact'
    },
    {
      label: 'menu.clientPortalStatute',
      url: 'https://www.gov.pl/web/gitd/regulamin-portalu-klienta',
      target: '_blank',
      isFullWidth: true
    },
    {
      label: 'menu.importantAdministrationDecisions.title',
      url: '/administrative-decisions',
      isFullWidth: true
    },
    {
      label: 'menu.serviceAccessDeclaration',
      url: 'https://www.gov.pl/web/gitd/deklaracja-dostepnosci-ibtm-glownego-inspektoratu-transportu-drogowego',
      target: '_blank',
      isFullWidth: true
    }
  ];

  if (isMobile)
    return (
      <MobileFooterLayout
        links={links}
        version={
          <VersionText
            version={version}
            isVersionPrefixHidden={isVersionPrefixHidden}
            appOwnerName={appOwnerName}
            showVersionInNavigation={showNavigationFooter}
            isUserLogged={isLoggedIn}
            isBuildVersionVisible={isBuildVersionVisible}
            align="center"
          />
        }
      />
    );

  return (
    <FooterLayout
      wrapperClassName={clsx(classes.footer)}
      leftText={
        <VersionText
          version={version}
          isVersionPrefixHidden={isVersionPrefixHidden}
          appOwnerName={appOwnerName}
          showVersionInNavigation={showNavigationFooter}
          isUserLogged={isLoggedIn}
          isBuildVersionVisible={isBuildVersionVisible}
        />
      }
      links={links}
    />
  );
}

const useStyles = makeStyles<Theme, { isLoggedIn: boolean; location: any }>(theme => ({
  footer: ({ isLoggedIn, location }) => ({
    zIndex: 100,
    backgroundColor: 'white',
    position: isLoggedIn || location?.pathname !== '/login' ? 'fixed' : 'relative',
    bottom: 0,
    height: 'auto',
    borderColor: hexToRgba(theme.palette.grey[500], 0.12),
    [theme.breakpoints.down('md')]: {
      position: important('relative') as Property.Position
    }
  })
}));

export default Footer;
