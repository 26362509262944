import { SCertificateDetails } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  DictionarySelectField,
  GridLayout,
  SwitchField,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';
import { convertDateToDateFormat } from '@libs/common/v2/utils';

import { DomainDictionaryEnum } from '@libs/domain/config';

export function CertificateDetails() {
  const getLabel = partialTranslate(`sCertificates:field`);

  return (
    <GridLayout itemProps={{ md: 6, xs: 12 }}>
      <TextInputField
        name={typedNameV2<SCertificateDetails>('compatibilityCertificateNumber')}
        label={getLabel('compatibilityCertificateNumber')}
      />
      <TextInputField label={getLabel('number')} name={typedNameV2<SCertificateDetails>('number')} />
      <TextInputField
        label={getLabel('applicationNumber')}
        name={typedNameV2<SCertificateDetails>('applicationNumber')}
      />
      <TextInputField label={getLabel('folderNumber')} name={typedNameV2<SCertificateDetails>('folder.number')} />
      <TextInputField label={getLabel('archiveNumber')} name={typedNameV2<SCertificateDetails>('archiveNumber')} />
      <DictionarySelectField
        dictionaryName={DomainDictionaryEnum.CERTIFICATE_S_STATUS}
        name={typedNameV2<SCertificateDetails>('statusKey')}
        label={getLabel('status')}
      />
      <DatepickerField
        name={typedNameV2<SCertificateDetails>('applicationSubmissionReceiptDate')}
        label={getLabel('applicationReceivedDate')}
        viewModeDateParser={convertDateToDateFormat}
      />
      <DatepickerField
        name={typedNameV2<SCertificateDetails>('applicationDate')}
        label={getLabel('applicationDate')}
        viewModeDateParser={convertDateToDateFormat}
      />
      <DatepickerField
        name={typedNameV2<SCertificateDetails>('printDate')}
        label={getLabel('printDate')}
        viewModeDateParser={convertDateToDateFormat}
      />
      <DatepickerField
        name={typedNameV2<SCertificateDetails>('expirationDate')}
        label={getLabel('expirationDate')}
        viewModeDateParser={convertDateToDateFormat}
      />
      <TextInputField label={getLabel('issuerName')} name={typedNameV2<SCertificateDetails>('signer.name')} />
      <TextInputField label={getLabel('subjectName')} name={typedNameV2<SCertificateDetails>('subject.name')} />
      <SwitchField label={getLabel('WITD')} name={typedNameV2<SCertificateDetails>('witd')} />
    </GridLayout>
  );
}
