import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import { Button, FormMode, useConfirmDialog, useFormV2Context, useRouter, useViewModesV2 } from '@libs/common/v2';

import { useDeleteAlertMutation } from '@libs/alert/api';

interface IProps {
  id: string;
  formMode: FormMode;
  isLoading: boolean;
  handleSubmitAlert: () => void;
}

function AlertDetailsPageFormHeader({ id, formMode, isLoading, handleSubmitAlert }: IProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { getValues } = useFormV2Context();
  const { viewMode, createMode } = useViewModesV2(formMode);

  const [confirm] = useConfirmDialog();
  const { mutate: deleteAlert } = useDeleteAlertMutation();
  const alertName = getValues('name');
  const { goToPage, routes, goBack } = useRouter();

  const handleDeleteAlert = useCallback(() => {
    confirm({
      title: t('alerts:message.alertDeleteTitle'),
      message: t('alerts:message.alertDeleteRequest', { name: alertName }),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        deleteAlert(id, {
          onSuccess: () => {
            setConfirmLoading(false);
            closeDialog();
            showSuccessSnackbar(t('alerts:message.alertDeleteSuccess'));
            goToPage(routes.notificationsConfigurationList());
          }
        });
      },
      confirmType: 'danger'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertName]);

  if (viewMode) {
    return (
      <>
        <Button
          label={t('action.delete')}
          onClick={handleDeleteAlert}
          variant="outlined"
          actionKey={UIElementNameEnum.NOTIFICATIONS_CONFIGURATION_DELETE_BUTTON}
        />
        <Button
          label={t('action.edit')}
          link={routes.notificationConfigurationEdit(id)}
          actionKey={UIElementNameEnum.NOTIFICATIONS_CONFIGURATION_EDIT_BUTTON}
          isPrimary
        />
      </>
    );
  }
  if (createMode) {
    return (
      <>
        <Button label={t('action.cancel')} onClick={goBack} variant="outlined" />
        <Button label={t('action.save')} onClick={handleSubmitAlert} isLoading={isLoading} isPrimary isNoMargin />
      </>
    );
  }
  return (
    <>
      <Button label={t('action.cancel')} link={routes.notificationsConfigurationList()} variant="outlined" />
      <Button label={t('action.save')} onClick={handleSubmitAlert} isLoading={isLoading} isPrimary isNoMargin />
    </>
  );
}

export default AlertDetailsPageFormHeader;
