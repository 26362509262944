import { MisspellApplicationDataCertificateDetails } from '@ibtm/domain';

import { useTableLayoutProps } from '@libs/common/v2';

import useMisspellApplicationCertificateColumn from './useMisspellApplicationCertificateColumn';

function useMispellApplicationCertificateTable(data: MisspellApplicationDataCertificateDetails[]) {
  const { columns } = useMisspellApplicationCertificateColumn();

  return useTableLayoutProps<MisspellApplicationDataCertificateDetails, MisspellApplicationDataCertificateDetails>({
    tableQuery: data,
    tableHookOptions: {
      columns
    }
  });
}

export default useMispellApplicationCertificateTable;
