import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderDetails } from '@ibtm/domain';

import {
  BackButton,
  Button,
  ButtonsGroup,
  IPropsItem,
  PageHeader,
  useBreadcrumbsConverter,
  useBreadcrumbTextByFormMode,
  useFormV2Context,
  useRouter
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';
import { getHeaderDetails } from '@libs/domain/folder';

interface IProps {
  onCancel: () => void;
  onSave: () => void;
  folderData: FolderDetails;
  isSubmitLoading: boolean;
}

function AssociationPageHeader({ folderData, onCancel, onSave, isSubmitLoading }: IProps) {
  const { number, id, typeKey, subject = {}, modifier = {} } = folderData ?? {};
  const { formMode } = useFormV2Context();
  const { createMode, viewMode, editMode } = useViewModesV2(formMode);
  const [t] = useTranslation();
  const { routes } = useRouter();
  const { translate } = useDictionaryTranslations();
  const { convertBreadcrumbToText } = useBreadcrumbsConverter();

  const breadcrumbText = useBreadcrumbTextByFormMode({
    createValue: t('associations:newAssociation'),
    editValue: t('associations:editAssociation'),
    viewValue: t('associations:associationDetails')
  });

  const breadcrumbs = useMemo(
    (): Array<IPropsItem> => [
      { text: t('associations:list.title'), to: '/associations' },
      { text: convertBreadcrumbToText(breadcrumbText) }
    ],
    [t, convertBreadcrumbToText, breadcrumbText]
  );

  const title = useMemo(() => {
    if (createMode) {
      return t('associations:newAssociation');
    }
    if (editMode) {
      return t('associations:editAssociation');
    }
    return t('associations:associationDetails');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createMode, editMode]);

  return (
    <PageHeader
      title={title}
      breadcrumbs={breadcrumbs}
      details={
        !createMode &&
        getHeaderDetails(
          translate(DomainDictionaryEnum.FOLDER_TYPE, typeKey),
          number,
          subject?.modified,
          modifier?.name
        )
      }
      rightSideContent={
        <ButtonsGroup>
          {viewMode ? (
            <>
              <BackButton link={routes.associationsList()} />
              <Button
                label={t('action.edit')}
                type="button"
                actionKey={DomainUIElementEnum.ASSOCIATIONS_EDIT_BUTTON}
                isPrimary
                link={routes.associationEdit(id)}
              />
            </>
          ) : (
            <>
              <Button onClick={onCancel} label={t('action.cancel')} type="button" variant="outlined" isPrimary />
              <Button onClick={onSave} label={t('action.save')} isPrimary isLoading={isSubmitLoading} />
            </>
          )}
        </ButtonsGroup>
      }
    />
  );
}
export default AssociationPageHeader;
