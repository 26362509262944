import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { isNil } from 'lodash';
import * as Yup from 'yup';

import {
  AutocompleteSelectField,
  Dialog,
  DialogContentContainer,
  DictionarySelectField,
  FormMode,
  FormV2Context,
  GridLayout,
  NumberInputField,
  typedNameV2,
  useViewModesV2
} from '@libs/common/v2';

import { DomainDictionaryEnum } from '../../config';
import { IHandleAddProps } from '../hooks/useHandleAddItemToReturnTable';
import { ReturnItemRange } from '../model/ReturnModels';

interface OptionsValue {
  value: string;
  positionId: string;
  name: string;
  max: number;
  min: number;
}

interface FormValues {
  name: OptionsValue;
  reasonKey: { name: string; value: string };
  from: number;
  to: number;
  sum: number;
  setResonToAllItems: boolean;
}
interface IProps {
  closeDialog: () => void;
  itemsAvailableToAdd: ReturnItemRange[];
  formMode?: FormMode;
  item?: ReturnItemRange;
}

function ReturnTableItemDialog({
  closeDialog,
  itemsAvailableToAdd,
  formMode = FormMode.CREATE,
  handleSubmit: handleSubmitItem,
  item
}: IHandleAddProps & IProps) {
  const [t] = useTranslation();
  const { createMode, editMode } = useViewModesV2(formMode);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    reset
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        name: createMode ? Yup.object().nullable().required() : Yup.string().nullable().required(),
        reasonKey: Yup.object().nullable().required(),
        from: Yup.number().nullable().required(),
        to: Yup.number().nullable().required()
      })
    ),
    defaultValues: {
      name: null,
      reasonKey: null,
      from: 0,
      to: 0,
      sum: 0
    }
  });

  const numberFrom = watch(typedNameV2<ReturnItemRange>('from'));
  const numberTo = watch(typedNameV2<ReturnItemRange>('to'));
  const name = watch(typedNameV2<ReturnItemRange>('name'));

  useEffect(() => {
    if (!isNil(item) && editMode) reset({ ...item });
  }, [item, reset, editMode]);

  useEffect(() => {
    const from = createMode ? name?.min : item?.from;
    const to = createMode ? name?.max : item?.to;
    setValue(typedNameV2<ReturnItemRange>('from'), from);
    setValue(typedNameV2<ReturnItemRange>('to'), to);
    setValue(typedNameV2<ReturnItemRange>('sum'), from - to + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    if (!isNil(numberFrom) && !isNil(numberTo)) {
      const amount = numberTo - numberFrom + 1;
      setValue(typedNameV2<ReturnItemRange>('sum'), amount);
    }
  }, [numberFrom, numberTo, setValue]);

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting, formMode }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch, formMode]
  );

  const selectOptions = useMemo(() => {
    return itemsAvailableToAdd.map(
      element =>
        ({
          value: element?.id,
          positionId: element?.positionId,
          name: element?.name,
          max: element?.max,
          min: element?.min
        } as OptionsValue)
    );
  }, [itemsAvailableToAdd]);

  const onSubmit = async (formData: FormValues) => {
    const newItem: ReturnItemRange = {
      positionId: createMode ? formData?.name.positionId : item?.positionId,
      name: createMode ? formData?.name?.name : item?.name,
      reasonKey: formData?.reasonKey?.value,
      from: formData?.from,
      to: formData?.to,
      max: createMode ? formData?.name?.max : item?.max,
      sum: formData?.sum,
      id: createMode ? formData?.name?.value : item?.id,
      min: createMode ? formData?.name?.min : item?.min
    };
    handleSubmitItem(newItem, createMode);
    closeDialog();
  };

  return (
    <Dialog
      title={t('releaseDocuments:return.dialogAddItems')}
      confirmText={createMode ? t('action.add') : t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={() => {
        handleSubmit(formData => {
          onSubmit(formData as FormValues);
        })();
      }}
      onCancel={closeDialog}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <DialogContentContainer>
          <GridLayout itemProps={{ xs: 12 }}>
            <AutocompleteSelectField
              options={selectOptions}
              name={typedNameV2<ReturnItemRange>('name')}
              isRequired
              renderOption={(props, option) => (
                <li {...props}>
                  {option.name} {option.min} - {option.max}
                </li>
              )}
              isDisabled={!createMode}
              label={t('releaseDocuments:return.columns.name')}
            />
            <DictionarySelectField
              name={typedNameV2<ReturnItemRange>('reasonKey')}
              isRequired
              dictionaryName={DomainDictionaryEnum.INVOICE_CORRECTION_REASON}
              label={t('releaseDocuments:return.columns.reasonKey')}
            />
            <NumberInputField
              name={typedNameV2<ReturnItemRange>('from')}
              label={t('releaseDocuments:return.columns.numberFrom')}
              min={(createMode ? name?.min || 0 : item?.min) || 0}
              max={numberTo || 0}
              isRequired
              isDisabled={name === null || name === undefined}
            />
            <NumberInputField
              name={typedNameV2<ReturnItemRange>('to')}
              label={t('releaseDocuments:return.columns.numberTo')}
              min={numberFrom || 0}
              isRequired
              isDisabled={name === null || name === undefined}
              max={(createMode ? name?.max || 0 : item?.max) || 0}
            />
            <NumberInputField
              isDisabled
              name={typedNameV2<ReturnItemRange>('sum')}
              label={t('releaseDocuments:return.columns.quantity')}
            />
          </GridLayout>
        </DialogContentContainer>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default ReturnTableItemDialog;
