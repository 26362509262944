import { useTranslation } from 'react-i18next';
import { DefaultApiFindPullNotificationsRequest, PullNotificationSnapshot } from '@avispon/message';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import { Button, TableLayout, useRouter } from '@libs/common/v2';

import { API } from '@libs/notification/api';
import { AdminMessagesTableRowActions } from '@libs/notification/components';
import { useAdminMessagesTable } from '@libs/notification/hooks';
import { useElementVisibility } from '@libs/permission';

import { DomainUIElementEnum } from '@libs/domain/config';

import { parseAdminMessagesTablePayload } from '../../utils';

function AdminMessagesTable() {
  const [t] = useTranslation();
  const { routes } = useRouter();
  const tableProps = useAdminMessagesTable();
  const { checkIsElementVisible } = useElementVisibility();

  const hasNoneOfPermissions =
    !checkIsElementVisible(DomainUIElementEnum.ADMIN_MESSAGE_USERS_SELECT) &&
    !checkIsElementVisible(DomainUIElementEnum.ADMIN_MESSAGE_GROUPS_SELECT);

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('messages:administrationTitle')}
      headerActions={
        !hasNoneOfPermissions && (
          <Button
            isPrimary
            label={t('messages:action.create')}
            link={routes.adminMessageCreate()}
            actionKey={UIElementNameEnum.ANNOUNCEMENT_ADD_BUTTON}
          />
        )
      }
      rowActions={({ original }: { original: PullNotificationSnapshot }) => (
        <AdminMessagesTableRowActions notification={original} />
      )}
      xlsxDownload={{
        fileName: t('messages:administrationTitle'),
        pathToXLSXTranslation: 'messages:field',
        apiRequest: (params: DefaultApiFindPullNotificationsRequest) => {
          const queryParams = parseAdminMessagesTablePayload(params);
          return API.message.findPullNotifications(queryParams);
        }
      }}
    />
  );
}

export default AdminMessagesTable;
