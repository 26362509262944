import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { DepotTransferCreateRequest } from '@ibtm/domain';
import * as Yup from 'yup';

import { Dialog, FormV2Context } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { WarningInformation } from '@libs/common/v2/components/warning';

import { useAuth } from '@libs/auth/hooks';

import { ResourceQueryKeysEnum, useDepotManagersListQuery, useDepotTransferMutation } from '../../../api';
import { usePoolsValidationSchema } from '../../../hooks';
import { FormTransferFields, ListTypeEnum, ResourceObjectContentPoolClient } from '../../../model';
import { DepotTransferState } from '../../../pages/ResourceTransferPage';
import { PoolsForm } from '../common';

interface IProps {
  selectedRows: ResourceObjectContentPoolClient[];
  closeDialog: () => void;
  listType: ListTypeEnum;
  depots: DepotTransferState;
}

function FormTransferForm({ selectedRows, listType, depots, closeDialog }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();

  const { user } = useAuth();
  const { data } = useDepotManagersListQuery({ userId: user?.accountData?.id }, { enabled: !!user?.accountData?.id });
  const isAdminSource = useMemo(() => data?.depotIds?.some(depotId => depotId === depots?.SOURCE?.id), [data, depots]);

  const isAdminTarget = useMemo(() => data?.depotIds?.some(depotId => depotId === depots?.TARGET?.id), [data, depots]);

  const { mutate: depotTransfer, isLoading } = useDepotTransferMutation();

  const poolsValidationSchema = usePoolsValidationSchema(selectedRows);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Partial<FormTransferFields>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        pools: poolsValidationSchema
      })
    ),
    defaultValues: {
      pools: selectedRows
    }
  });

  const onSubmit = (formData: Partial<FormTransferFields>) => {
    const requestData: DepotTransferCreateRequest = {
      sourceDepotId: listType === ListTypeEnum.SOURCE ? depots.SOURCE.id : depots.TARGET.id,
      targetDepotId: listType === ListTypeEnum.SOURCE ? depots.TARGET.id : depots.SOURCE.id,
      sourceElements: formData.pools.map(item => ({
        numberFrom: item.numberFrom,
        numberTo: item.numberTo,
        amount: item.amount,
        resourceTypeId: item.resourceType.id
      })),
      targetElements: []
    };

    depotTransfer(requestData, {
      onSuccess: () => {
        if (isAdminSource && isAdminTarget) {
          showSnackbar('success', t('resource:messages.depotTransferSuccess'));
        } else {
          showSnackbar('success', t('resource:messages.depotTransferNeedsApproveSuccess'));
        }
        queryCache.invalidateQueries(ResourceQueryKeysEnum.RESOURCE_OBJECT_CONTENT_POOLS_SOURCE);
        queryCache.invalidateQueries(ResourceQueryKeysEnum.RESOURCE_OBJECT_CONTENT_POOLS_TARGET);

        closeDialog();
      }
    });
  };

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('resource:dialog.formTransferTitle')}
      confirmText={t('action.confirm')}
      cancelText={t('action.cancel')}
      titleSupportingText={t('resource:dialog.transferFormsSupportingText', {
        source: listType === ListTypeEnum.SOURCE ? depots.SOURCE.translation : depots.TARGET.translation,
        target: listType === ListTypeEnum.SOURCE ? depots.TARGET.translation : depots.SOURCE.translation
      })}
      onCancel={closeDialog}
      onConfirm={() => handleSubmit(onSubmit)()}
      dialogSize="large"
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        {!(isAdminSource && isAdminTarget) && (
          <WarningInformation content={t('resource:messages.formsNeedsApproveInformation')} />
        )}

        <PoolsForm />
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default FormTransferForm;
