import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

interface IProps {
  userId: string;
  subjects: string[];
}

const addEntrepreneurToUser = async ({ userId, subjects }: IProps) => {
  const subjectIdList = {
    subjectIds: subjects
  };

  const { data } = await API.usersAvailableSubjects.addUsersAvailableSubjects(userId, subjectIdList, {
    ...endpointsConfig.addUsersAvailableSubjects
  });
  return data;
};

function useAddEntrepreneurToUser() {
  return useMutation(addEntrepreneurToUser);
}

export default useAddEntrepreneurToUser;
