import { TransitStopUpdateRequest as TransitStopUpdateRequestClient } from '@ibtm/client-domain';
import { TransitStopUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function editTransitScheduleStop({
  transitStopId,
  formData
}: {
  transitStopId: string;
  formData: TransitStopUpdateRequest;
}) {
  return API.transitSchedule.updateTransitStop(transitStopId, formData, {
    ...endpointsConfig.updateTransitSchedule
  });
}

function editClientTransitScheduleStop({
  transitStopId,
  formData
}: {
  transitStopId: string;
  formData: TransitStopUpdateRequestClient;
}) {
  return API.client.transitSchedule.updateTransitStop(transitStopId, '', formData, {
    ...endpointsConfig.updateTransitSchedule
  });
}

export function useEditTransitScheduleStopMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(editClientTransitScheduleStop, editTransitScheduleStop));
}
