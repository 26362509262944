import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { FormMode, IconButton, LoaderCircular, Typography, useRouter, useValueStyles } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { useElementVisibility } from '@libs/permission';

import { DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import { FolderDetailsClient, FolderDetailsTabEnum } from '@libs/domain/folder';

import FolderSliderSection from '../FolderSliderSection';

interface Props {
  folderDetails: FolderDetailsClient;
  emptyMode?: boolean;
  isLoading?: boolean;
  formMode?: FormMode;
  isCopyBlockadeListRedirectActionVisible?: boolean;
}

function MiscInfoBlock({
  emptyMode,
  folderDetails,
  formMode,
  isLoading,
  isCopyBlockadeListRedirectActionVisible
}: Props) {
  const [t] = useTranslation();
  const { checkIsElementVisible } = useElementVisibility();
  const { viewMode } = useViewModesV2(formMode);
  const globalClasses = useValueStyles({});
  const classes = useStyles();
  const { routes } = useRouter();

  const { isOperatorPortal } = useDomainConfigContext();

  return (
    <FolderSliderSection title={t('folder:sidebar.section.other')} isCollapsable>
      <LoaderCircular isLoading={isLoading} size={25}>
        {emptyMode ? (
          <div className={clsx(classes.row, 'flex items-center')}>
            <p>{t('error.noData')}</p>
          </div>
        ) : (
          <div className="flex flex-col">
            {checkIsElementVisible(DomainUIElementEnum.FOLDER_LOCK_VIEW) && isOperatorPortal ? (
              <div className={clsx(classes.row, 'flex items-center')}>
                <Typography
                  component="div"
                  themeVariant="textSm.medium"
                  className={clsx(globalClasses.labelWrapper, 'pr-5', 'mb-0')}
                >
                  {t('folder:field.locked')}
                </Typography>
                {folderDetails?.locked ? (
                  <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
                    {t('folder:sidebar.section.blockade')}
                  </Typography>
                ) : (
                  <Typography component="div" className={clsx(globalClasses.value, 'field-value pr-5')}>
                    {t('emptyMark')}
                  </Typography>
                )}
                {isCopyBlockadeListRedirectActionVisible && (
                  <IconButton
                    className={clsx(classes.iconButton)}
                    icon="LockIcon"
                    tooltipTitle={t('folder:sidebar.tooltip.goToBlockadeList')}
                    link={{
                      pathname: viewMode
                        ? routes.folderDetails(folderDetails?.id)
                        : routes.folderEdit(folderDetails?.id),
                      search: `tab=${FolderDetailsTabEnum.LOCKS}`
                    }}
                  />
                )}
              </div>
            ) : null}
            <div className={clsx(classes.row, 'flex items-center')}>
              <Typography
                component="div"
                themeVariant="textSm.medium"
                className={clsx(globalClasses.labelWrapper, 'pr-5 mb-0')}
              >
                {t('folder:sidebar.tooltip.activeDriverTestimonials')}
              </Typography>
              <Typography component="div" className={clsx(globalClasses.value, 'field-value whitespace-nowrap')}>
                {folderDetails?.driverCertificatesNumber?.toString() || 0}
              </Typography>
            </div>
            {isOperatorPortal ? (
              <div className={clsx(classes.row, 'flex items-center')}>
                <Typography
                  component="div"
                  themeVariant="textSm.medium"
                  className={clsx(globalClasses.labelWrapper, 'pr-5 mb-0 w-full')}
                >
                  {t('folder:sidebar.tooltip.activeCars')}
                </Typography>
                <Typography component="div" className={clsx(globalClasses.value, 'field-value whitespace-nowrap')}>
                  {folderDetails?.activeVehiclesNumber?.toString() || 0}
                </Typography>
              </div>
            ) : null}
            <div className={clsx(classes.row, 'flex items-center')}>
              <Typography
                component="div"
                themeVariant="textSm.medium"
                className={clsx(globalClasses.labelWrapper, 'pr-5 mb-0')}
              >
                {t('folder:sidebar.tooltip.administrativeProceedings')}
              </Typography>
              <Typography component="div" className={clsx(globalClasses.value, 'field-value whitespace-nowrap')}>
                {folderDetails?.activeProceedingsNumber?.toString() || 0}
              </Typography>
            </div>
            {isOperatorPortal ? (
              <div className={clsx(classes.row, 'flex items-center')}>
                <Typography
                  component="div"
                  themeVariant="textSm.medium"
                  className={clsx(globalClasses.labelWrapper, 'pr-5 mb-0')}
                >
                  {t('folder:sidebar.tooltip.borrower')}
                </Typography>
                <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
                  {folderDetails?.borrowedBy?.name || t('emptyMark')}
                </Typography>
              </div>
            ) : null}
          </div>
        )}
      </LoaderCircular>
    </FolderSliderSection>
  );
}

const useStyles = makeStyles(() => ({
  row: {
    minHeight: 48
  },
  iconButton: {
    height: 20,
    width: 20,
    marginLeft: '10px'
  }
}));

export default MiscInfoBlock;
