import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { TableButton, TableButtonMore, TableButtonView, TableContext, useRouter } from '@libs/common/v2';

import { DomainUIElementEnum } from '@libs/domain/config';

import { BorrowFolderDialogTypeEnum, BorrowFolderSnapshot } from '../model';

import { useBorrowFoldersContext } from './common/BorrowFolderProvider';

interface IProps {
  row: BorrowFolderSnapshot;
  hideOpenFolderButton?: boolean;
}

function BorrowFolderRowActions({ row, hideOpenFolderButton = false }: IProps) {
  const [t] = useTranslation();
  const { onOpen } = useBorrowFoldersContext();
  const { routes } = useRouter();
  const { refetch } = useContext(TableContext);

  return (
    <>
      {!row.returnDate && (
        <TableButtonMore>
          {close => [
            <TableButton
              label={t('archive:borrowFolder.action.returnFolder')}
              onClick={() => {
                close();
                onOpen(BorrowFolderDialogTypeEnum.RETURN_FOLDER, row, refetch);
              }}
              actionKey={DomainUIElementEnum.ARCHIVE_BORROWED_RETURN_BUTTON}
              key="returnFolderAction"
            />,
            <TableButton
              label={t('archive:borrowFolder.action.changeBorrower')}
              onClick={() => {
                close();
                onOpen(BorrowFolderDialogTypeEnum.CHANGE_BORROWER, row, refetch);
              }}
              actionKey={DomainUIElementEnum.ARCHIVE_BORROWED_CHANGE_BORROWER_BUTTON}
              key="changeBorrowerAction"
            />
          ]}
        </TableButtonMore>
      )}
      {!hideOpenFolderButton && (
        <TableButtonView
          tooltipTitle={t('action.openDetails')}
          actionKey={DomainUIElementEnum.FOLDER_RENTAL_VIEW}
          link={routes.folderDetails(row.folder.id)}
        />
      )}
    </>
  );
}

export default BorrowFolderRowActions;
