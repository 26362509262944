import { QueryConfig, useQuery } from 'react-query';
import { ProxyDetails } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import { ProxyQueryKeysEnum } from './QueryKeysEnum';

const getProxy = async (_: any, id: string) => {
  const { data } = await API.proxy.getSubjectProxyDetails(id, {
    ...endpointsConfig.getSubjectProxyDetails
  });
  return data;
};

const getProxyClient = async (_: any, id: string) => {
  const { data } = await API.client.proxy.getProxySnapshot(id, '', '', {
    ...endpointsConfig.getSubjectProxyDetails
  });
  return data;
};

export default function useProxyDetailsQuery(id: string, queryConfig: QueryConfig<any, unknown> = {}) {
  const { getQuery } = useGetApiQueryByPerspective();

  return useQuery<ProxyDetails>(
    [ProxyQueryKeysEnum.PROXY_DETAILS, id],
    getQuery(getProxyClient, getProxy),
    queryConfig
  );
}
