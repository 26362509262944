import { QueryConfig, usePaginatedQuery } from 'react-query';
import { MoneyTransferSearchFilter, MoneyTransferSnapshot } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import { PricingQueryKeysEnum } from './PricingQueryKeysEnum';

async function getMoneyTransfers(_: any, params: MoneyTransferSearchFilter) {
  const { data } = await API.moneyTransfer.getMoneyTransfersSnapshotPage(params, {
    ...endpointsConfig.getMoneyTransfersSnapshotPage
  });
  return data;
}

function useMoneyTransfersQuery(
  params: MoneyTransferSearchFilter,
  queryConfig: QueryConfig<IPaginatedModel<MoneyTransferSnapshot>, unknown> = {}
) {
  return usePaginatedQuery([PricingQueryKeysEnum.MONEY_TRANSFERS, params], getMoneyTransfers, queryConfig);
}

export default useMoneyTransfersQuery;
