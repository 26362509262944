import { AccountCreateCommand, AccountSnapshot, PersonalDataSnapshot, UpdateAccountDataRequest } from '@avispon/user';
import { SubjectSnapshotAllOf } from '@ibtm/domain';
import * as Yup from 'yup';

import { IconType, SelectOptionPartial } from '@libs/common/v2';

import { UserDictionaryEntryNameEnum } from '@libs/user/common';

import { UserUIElementEnum } from '../config';

import UserStatusKeysEnum from './UserStatusKeysEnum';

export interface WorkHoursSnapshot {
  dayKey: UserDictionaryEntryNameEnum;
  shiftTypeKey: UserDictionaryEntryNameEnum;
  timeFrom: string;
  timeTo: string;
}

export interface IUsersTab {
  label: string;
  value: UserDetailsTab;
  icon?: IconType;
  isVisible?: boolean;
  viewName?: UserUIElementEnum;
}

export enum StatusColorTypes {
  RED,
  YELLOW,
  GREY,
  LIGHTGREEN,
  GREEN
}

export enum UserDetailsTab {
  GENERAL = 'GENERAL',
  PERMISSIONS_GROUP = 'PERMISSIONS_GROUP'
}

export enum UserStatus {
  DELETED = 'DELETED'
}

export enum AnonymizationType {
  OFF = 'OFF',
  REVERSIBLE = 'REVERSIBLE',
  IRREVERSIBLE = 'IRREVERSIBLE'
}

export type UsersTableColumnName = keyof AccountSnapshot | keyof PersonalDataSnapshot;

export type ChangeUserPasswordValidation = Yup.ObjectSchema<{ newPassword1: string; newPassword2: string }>;

export interface AdditionalUserFormFields {
  repeatPassword: string;
}

export type CreateUserValues = Omit<AccountCreateCommand, 'workSystem'> & AdditionalUserFormFields;
export type EditUserValues = Omit<UpdateAccountDataRequest, 'name' | 'surname' | 'workSystem'> &
  AdditionalUserFormFields;
export type UserValues = AccountSnapshot;
export type UserFormValues = CreateUserValues &
  EditUserValues &
  Omit<UserValues, 'workSystem' | 'organizationUnit' | 'positionKey' | 'role'> & {
    workSystem?: { typeKey?: string; workHours?: string };
    organizationUnit?: { id?: string; name?: string };
    positionKey?: SelectOptionPartial;
    entrepreneurs?: Array<UserSubjectLite>;
  };

export const USER_STATUS_COLOR_CONFIG = [
  { status: UserStatusKeysEnum.VERIFIED, color: StatusColorTypes.GREEN },
  { status: UserStatusKeysEnum.EXPIRED, color: StatusColorTypes.GREY },
  { status: UserStatusKeysEnum.UNCONFIRMED, color: StatusColorTypes.GREY },
  { status: UserStatusKeysEnum.UNVERIFIED, color: StatusColorTypes.GREY },
  { status: UserStatusKeysEnum.SUSPENDED, color: StatusColorTypes.YELLOW },
  { status: UserStatusKeysEnum.BLOCKED, color: StatusColorTypes.RED },
  { status: UserStatusKeysEnum.DELETED, color: StatusColorTypes.RED },
  { status: UserStatusKeysEnum.DISABLED_MIGRATED, color: StatusColorTypes.RED }
];
export const suspendInitialValues = {
  suspendedFrom: '',
  suspendedTo: ''
};

export interface SuspendFormValues {
  suspendedFrom: string;
  suspendedTo: string;
}

export interface ExtendedPersonalData extends PersonalDataSnapshot {
  displayName: string;
  email: string;
  serviceNumber: string;
}

export type AccountsSearchRoles = 'OPERATOR' | 'CLIENT';

export type UserSubjectLite = Pick<SubjectSnapshotAllOf, 'id' | 'name' | 'nip'>;

export type IUserClientListParams = {
  id: string;
};

export enum permissionsIgnoredTransportManagerLimits {
  WL_Naczelnik = 'WL_Naczelnik',
  WWU_Naczelnik = 'WWU_Naczelnik',
  WSK_Naczelnik = 'WSK_Naczelnik',
  WPO_Naczelnik = 'WPO_Naczelnik'
}

export enum ActionButtonsEnum {
  USER_ADD = 'add',
  USER_DETAILS = 'details',
  USER_EDIT = 'edit',
  USER_DELETE = 'delete',
  USER_CHANGE_PASSWORD = 'changePassword',
  UNLOCK = 'unlock',
  LOCK = 'lock',
  RESTORE = 'restore',
  REJECT = 'reject',
  VERIFY = 'verify',
  SHOW_USERS_FLAG = 'showUsersFlag',
  ANONYMIZE = 'anonymize',
  RESET_PASSWORD = 'resetPassword',
  RESUME = 'resume',
  SUSPEND = 'suspend',
  ADD_ENTERPRISE = 'addEnterprise',
  DELETE_ENTERPRISE = 'deleteEnterprise',
  RESEND_EMAIL_CONFIRMATION = 'resendEmailConfirmation',
  SEND_EMAIL_TO_RESET_PASSWORD = 'sendEmailToResetPassword'
}

export interface IPermissionsObject {
  [ActionButtonsEnum.USER_ADD]?: UserUIElementEnum;
  [ActionButtonsEnum.USER_DETAILS]?: UserUIElementEnum;
  [ActionButtonsEnum.USER_EDIT]?: UserUIElementEnum;
  [ActionButtonsEnum.USER_DELETE]?: UserUIElementEnum;
  [ActionButtonsEnum.USER_CHANGE_PASSWORD]?: UserUIElementEnum;
  [ActionButtonsEnum.UNLOCK]?: UserUIElementEnum;
  [ActionButtonsEnum.LOCK]?: UserUIElementEnum;
  [ActionButtonsEnum.RESTORE]?: UserUIElementEnum;
  [ActionButtonsEnum.REJECT]?: UserUIElementEnum;
  [ActionButtonsEnum.VERIFY]?: UserUIElementEnum;
  [ActionButtonsEnum.SHOW_USERS_FLAG]?: UserUIElementEnum;
  [ActionButtonsEnum.ANONYMIZE]?: UserUIElementEnum;
  [ActionButtonsEnum.RESET_PASSWORD]?: UserUIElementEnum;
  [ActionButtonsEnum.RESUME]?: UserUIElementEnum;
  [ActionButtonsEnum.SUSPEND]?: UserUIElementEnum;
  [ActionButtonsEnum.ADD_ENTERPRISE]?: UserUIElementEnum;
  [ActionButtonsEnum.DELETE_ENTERPRISE]?: UserUIElementEnum;
  [ActionButtonsEnum.RESEND_EMAIL_CONFIRMATION]?: UserUIElementEnum;
  [ActionButtonsEnum.SEND_EMAIL_TO_RESET_PASSWORD]?: UserUIElementEnum;
}
