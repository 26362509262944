import { GridLayout } from '@libs/common/v2';

import {
  AdditionalInformationSection,
  OrganizationDataSection,
  PersonalDataSection,
  WorkSystemSection
} from '@libs/user/components';

function ProfileGeneralTab() {
  return (
    <GridLayout itemProps={{ xs: 6 }}>
      <PersonalDataSection />
      <WorkSystemSection />
      <OrganizationDataSection profilePage />
      <AdditionalInformationSection />
    </GridLayout>
  );
}

export default ProfileGeneralTab;
