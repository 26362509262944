import { QueryConfig, useQuery } from 'react-query';
import { ResourceObjectSinglePoolRequest } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';
import { ResourceQueryKeysEnum } from '..';

export const getResourceObjectSinglePoolAvailability = async (params: ResourceObjectSinglePoolRequest) => {
  const { data } = await API.resourceObjectPools.getValidateAvailableSinglePool(params, {
    ...endpointsConfig.getValidateAvailableSinglePool
  });

  return data;
};

export default function useResourceObjectSinglePoolAvailabilityQuery(
  params: ResourceObjectSinglePoolRequest,
  queryConfig: QueryConfig<any, unknown> = {}
) {
  return useQuery(
    [ResourceQueryKeysEnum.RESOURCE_OBJECT__SINGLE_POOL_AVAILABILITY, params],
    () => getResourceObjectSinglePoolAvailability(params),
    queryConfig
  );
}
