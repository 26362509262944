import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { useConfirmDialog } from '@libs/common/v2';

import { useDeletePartnerApplicantMutation } from '../../api';

function usePartnerApplicantsDeleteAction() {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: deletePartnerApplicant } = useDeletePartnerApplicantMutation();

  const [confirm] = useConfirmDialog();

  const handlePartnerApplicantDelete = ({
    partnerApplicantId,
    onSuccess
  }: {
    partnerApplicantId: string;
    onSuccess?: () => void;
  }) => {
    confirm({
      title: t('partnerApplicants:action.delete.dialogTitle'),
      shortTitle: t('global:dialog.deleteConfirmTitle'),
      message: t('partnerApplicants:action.delete.confirmationText'),
      confirmType: 'danger',
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        deletePartnerApplicant(
          { partnerApplicantId },
          {
            onSuccess: () => {
              showSuccessSnackbar(t('partnerApplicants:action.delete.successMessage'));
              onSuccess?.();
              closeDialog();
            },
            onSettled: () => {
              setConfirmLoading(false);
            }
          }
        );
      }
    });
  };

  return { handlePartnerApplicantDelete };
}

export default usePartnerApplicantsDeleteAction;
