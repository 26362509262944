import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Typography } from '@libs/common/v2';

import { useTableAdapter, useTableContext } from './useTableContext';

const useEmptyState = (emptyStateText: string) => {
  const [t] = useTranslation();
  const {
    state: { filters, page },
    rows
  } = useTableAdapter();
  const { isLoading, isFetching } = useTableContext();
  const isDataEmpty = !isLoading && !isFetching && !rows.length && _.isEmpty(filters) && !page?.length;
  const isCurrentSearchDataEmpty = !isLoading && !isFetching && !rows.length && !_.isEmpty(filters);

  const renderEmptyState = () => (
    <Typography themeVariant="textSm.normal" className=" flex justify-center p-48" align="center">
      {emptyStateText || t('error.noData')}
    </Typography>
  );

  return { renderEmptyState, isDataEmpty, isCurrentSearchDataEmpty };
};

export default useEmptyState;
