import { useMutation } from 'react-query';
import { DocumentCreateRequest } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export interface IProceedingDocumentCreateParams {
  proceedingId: string;
  data: DocumentCreateRequest;
}

function generateDocument({ proceedingId, data }: IProceedingDocumentCreateParams) {
  return API.proceeding.addProceedingDocument(proceedingId, data, { ...endpointsConfig.addProceeding });
}

export function useGenerateProceedingDocumentMutation() {
  return useMutation(generateDocument);
}
