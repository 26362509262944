import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ApplicationDetails } from '@ibtm/domain';
import { isEmpty } from 'lodash';

import {
  AutocompleteSelectField,
  Dialog,
  DictionarySelectField,
  FormV2Context,
  GridItem,
  GridLayout,
  SelectOption,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useDictionaryTranslations } from '@libs/dictionary';

import { SubjectAddressDetailsUnion } from '@libs/domain/address';
import {
  ApplicationQueryKeysEnum,
  useApplicationAddressesQuery,
  useApplicationDetailsQuery,
  useEditApplicationMutation
} from '@libs/domain/application';
import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import { useReciptTypeValidationSchema } from '../../../hooks';

function ApplicationReciptTypeDialog({
  closeDialog,
  applicationId
}: {
  closeDialog: () => void;
  applicationId: string;
}) {
  const queryCache = useQueryCache();
  const { data: applicationData } = useApplicationDetailsQuery(applicationId, {
    refetchOnMount: false
  });

  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { translate } = useDictionaryTranslations();
  const { validationSchema } = useReciptTypeValidationSchema();

  const [editApplication, { isLoading }] = useEditApplicationMutation();
  const { data: addressesResponse } = useApplicationAddressesQuery(
    { applicationIdIn: [applicationId] },
    {
      enabled: Boolean(applicationId)
    }
  );

  const applicationAddresses: SubjectAddressDetailsUnion[] = addressesResponse
    ? ('content' in addressesResponse && addressesResponse?.content) ||
      ('addresses' in addressesResponse && addressesResponse?.addresses)
    : [];

  const parseAddressName = address =>
    `${address.city}, ${address.street} ${address.propertyNumber} - ${translate(
      DomainDictionaryEnum.ADDRESS_TYPE,
      address.typeKey
    )}`;

  const parseAddress = (address: SubjectAddressDetailsUnion) =>
    !isEmpty(address)
      ? [
          {
            value: address,
            name: parseAddressName(address),
            id: address.id
          }
        ]
      : [];

  const parseInitialAddress = address => {
    const addressFromApplication = applicationAddresses.filter(
      applicationAddress => applicationAddress.addressId === address.id
    );

    const addressMachingAddressFromApplication =
      addressFromApplication.length > 1
        ? addressFromApplication.filter(addres => addres.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.MAIN)[0]
        : addressFromApplication[0];

    return parseAddressName(addressMachingAddressFromApplication || address);
  };

  const initialValues = {
    receiptTypeKey: { value: applicationData?.receiptTypeKey },
    recipientName: applicationData?.recipientName,
    recipientSurname: applicationData?.recipientSurname,
    sendToAddress: applicationData?.sendToAddress && {
      name: applicationData?.sendToAddress && parseInitialAddress(applicationData?.sendToAddress),
      value: applicationData?.sendToAddress?.id
    },
    version: applicationData?.version
  };

  const form = useForm<Record<string, any>>({
    defaultValues: initialValues,
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema())
  });

  const parsedAddresses = useMemo(() => {
    return [
      ...parseAddress(
        applicationAddresses?.find(address => address.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.MAIN)
      ),
      ...parseAddress(applicationAddresses?.find(address => address.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.HQ)),
      ...parseAddress(
        applicationAddresses?.find(address => address.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.CORRESPONDENCE)
      ),
      ...parseAddress(
        applicationAddresses?.find(address => address.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.ASSOCIATION)
      )
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressesResponse, translate]);

  const receiptTypeKey = form.watch(typedNameV2<ApplicationDetails>('receiptTypeKey')) as SelectOption;

  const isPickupInPerson = receiptTypeKey?.value === DomainDictionaryEntry.RECEIPT_TYPE.PICKUP_IN_PERSON;
  const isEPUAP = receiptTypeKey?.value === DomainDictionaryEntry.RECEIPT_TYPE.EPUAP;
  const isEmail = receiptTypeKey?.value === DomainDictionaryEntry.RECEIPT_TYPE.E_MAIL;

  const isSendToAddressFieldVisible = receiptTypeKey?.value && !isPickupInPerson && !isEPUAP && !isEmail;

  const onSubmit = (formData: {
    receiptTypeKey?: SelectOption<string>;
    sendToAddress?: SelectOption<string>;
    recipientName?: string;
    recipientSurname?: string;
  }) => {
    return editApplication(
      {
        applicationId: applicationData?.id,
        formData: {
          version: applicationData?.version,
          receiptTypeKey: formData.receiptTypeKey?.value,
          ...(isSendToAddressFieldVisible ? { sendToAddress: formData.sendToAddress?.value } : {}),
          ...(isPickupInPerson
            ? { recipientName: formData.recipientName, recipientSurname: formData.recipientSurname }
            : {}),
          submissionReceiptDate: applicationData?.submissionReceiptDate
        }
      },
      {
        onSuccess: ({ data }) => {
          showSuccessSnackbar(t('success.save'));
          queryCache.setQueryData([ApplicationQueryKeysEnum.APPLICATION, applicationId], data);

          closeDialog();
        }
      }
    );
  };

  const formDataProvider = useMemo(() => {
    return { ...form };
  }, [form]);

  return (
    <FormV2Context.Provider value={formDataProvider}>
      <Dialog
        title={t('prints:printsDetailsBlocks.reciptTypeChange')}
        confirmText={t('action.save')}
        onConfirm={() => form.handleSubmit(onSubmit)()}
        onCancel={closeDialog}
        onClose={closeDialog}
        isConfirmLoading={isLoading}
        isOpen
      >
        <GridLayout itemProps={{ md: 6, xs: 12 }}>
          <GridItem xs={12}>
            <DictionarySelectField
              name={typedNameV2<ApplicationDetails>('receiptTypeKey')}
              label={t('prints:formField.deliveryType')}
              dictionaryName={DomainDictionaryEnum.RECEIPT_TYPE}
              isRequired
            />
          </GridItem>
          {isSendToAddressFieldVisible && (
            <GridItem xs={12}>
              <AutocompleteSelectField
                name={typedNameV2<ApplicationDetails>('sendToAddress')}
                label={t('prints:formField.sendToAddress')}
                options={parsedAddresses}
                isRequired
              />
            </GridItem>
          )}
          {isPickupInPerson && (
            <TextInputField
              name={typedNameV2<ApplicationDetails>('recipientName')}
              label={t('prints:formField.recipientName')}
            />
          )}
          {isPickupInPerson && (
            <TextInputField
              name={typedNameV2<ApplicationDetails>('recipientSurname')}
              label={t('prints:formField.recipientSurname')}
            />
          )}
        </GridLayout>
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default ApplicationReciptTypeDialog;
