import { DomainDictionaryEntry } from '@libs/domain/config';

import { FinancialSecurityActions } from '../model';

export const filterFinancialSecurityActions = (
  allActions: FinancialSecurityActions[],
  financialSecurityModificationTypeKey: string
) => {
  if (
    financialSecurityModificationTypeKey ===
    DomainDictionaryEntry.APPLICATION_FINANCIAL_SECURITY_MODIFICATION_TYPE.MODIFICATION
  ) {
    return allActions.filter(action => action !== 'create');
  }
  if (
    financialSecurityModificationTypeKey === DomainDictionaryEntry.APPLICATION_FINANCIAL_SECURITY_MODIFICATION_TYPE.ADD
  ) {
    return allActions.filter(action => action !== 'edit');
  }

  return allActions;
};
