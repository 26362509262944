import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import { useFormV2Context } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DomainDictionaryEntry, DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import { FolderDetailsParams } from '@libs/domain/folder';
import { FolderDetailsTabEnum } from '@libs/domain/folder/model';
import { ProceedingsActionsEnum, ProceedingsTable } from '@libs/domain/proceeding';
import { ProceedingsKreptdTable } from '@libs/domain/road-transport-kreptd/components/list';

function ProceedingList() {
  const [t] = useTranslation();

  const { folderId: id } = useParams<FolderDetailsParams>();
  const { formMode } = useFormV2Context();
  const { isOperatorPortal } = useDomainConfigContext();
  const { viewMode } = useViewModesV2(formMode);

  const rowActions = useMemo(
    () => [
      ProceedingsActionsEnum.SHOW_DETAILS,
      isOperatorPortal && ProceedingsActionsEnum.CREATE,
      isOperatorPortal && ProceedingsActionsEnum.EDIT,
      isOperatorPortal && ProceedingsActionsEnum.DELETE
    ],
    [isOperatorPortal]
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ProceedingsTable
          title={t('proceeding:titles.WWUproceedings')}
          detailsLink={(proceedingId, folderId, type) => {
            const proceedingTypeKey =
              type === DomainDictionaryEntry.PROCEEDING_TYPE.ADMINISTRATIVE ? 'ADMINISTRATION' : 'VERIFICATION';

            return `/proceeding/${proceedingTypeKey}/${proceedingId}/details/folder/${folderId}/details`;
          }}
          editLink={(proceedingId, folderId, type) => {
            const proceedingTypeKey =
              type === DomainDictionaryEntry.PROCEEDING_TYPE.ADMINISTRATIVE ? 'ADMINISTRATION' : 'VERIFICATION';

            return `/proceeding/${proceedingTypeKey}/${proceedingId}/edit/folder/${folderId}/details`;
          }}
          previousLink={folderId =>
            `/folders/${folderId}/${viewMode ? 'details' : 'edit'}?tab=${FolderDetailsTabEnum.PROCEEDINGS}`
          }
          folderId={id}
          visibleColumns={[
            'proceedingNumber',
            'caseNumber',
            'created',
            'conductingPersonName',
            'modified',
            'proceedingCategory',
            'modified',
            'proceedingCategory',
            'status',
            'proceedingType',
            'isDecision',
            'isReviewed',
            'isSecondTierDecision',
            'isWSAComplaint',
            'isNSAComplaint'
          ]}
          visibleActions={rowActions}
          tableOptions={{
            initialState: {
              sortBy: [{ id: 'created', desc: true }]
            }
          }}
          initialParams={{
            folderIdIn: [id]
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <div>
          <ProceedingsKreptdTable
            title={t('proceeding:titles.KREPTDproceedings')}
            isCollapsable
            isSection
            visibleColumns={['caseNumber', 'category', 'status', 'assignedName']}
            enabledActions={[DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_BUTTON]}
            isModificationDisabled
            initialParams={{ folderIdIn: [id] }}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default ProceedingList;
