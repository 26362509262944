import { useState } from 'react';
import { ApplicationDetails, DocumentSnapshot } from '@ibtm/domain';

import { useQueryCache } from '@libs/common/v2/api';

import { useMetaFormContext } from '@libs/meta-form/context';
import { UIElementNameEnum } from '@libs/permission';

import { IPermissionsKeys } from '@libs/domain/config';
import {
  DocumentsQueryKeysEnum,
  DocumentsSnapshotClient,
  DocumentsTable,
  isReceiveDateDocumentActionDisabled,
  useCancelDocumentMutation,
  useDeliveryDateDialog,
  useDocumentsTable,
  useForwardToShipmentMutation,
  useGenerateApplicationDocumentDialog,
  useUpdateDocumentDialog
} from '@libs/domain/documents';

import { API } from '../../../api';
import {
  ApplicationQueryKeysEnum,
  IApplicationDocumentCreateParams,
  useGenerateApplicationDocumentMutation
} from '../../api';

interface IProps {
  applicationId?: string;
  generateDocumentParams: IApplicationDocumentCreateParams;
  applicationType: string;
  permissionsKeys: IPermissionsKeys;
  tableActionKeys: Array<UIElementNameEnum>;
}

function DocumentsList({
  applicationId,
  applicationType,
  generateDocumentParams,
  permissionsKeys,
  tableActionKeys
}: IProps) {
  const queryCache = useQueryCache();
  const { mode } = useMetaFormContext();

  const [groupKeyIn, setGroupKeyIn] = useState<boolean>();
  const [groupKeyNotIn, setGroupKeyNotIn] = useState<boolean>();

  const data: ApplicationDetails & DocumentSnapshot = queryCache.getQueryData([
    ApplicationQueryKeysEnum.APPLICATION,
    applicationId
  ]);

  const { mutateAsync: cancelDocument } = useCancelDocumentMutation();
  const { mutateAsync: forwardToShipment } = useForwardToShipmentMutation();

  const tableProps = useDocumentsTable({
    applicationId,
    groupKeyIn,
    groupKeyNotIn,
    includeConfirmationsOfIssuingDocuments: true
  });
  const { openGenerateDocumentDialog } = useGenerateApplicationDocumentDialog({
    generateDocumentMutation: useGenerateApplicationDocumentMutation,
    apiParams: generateDocumentParams,
    listQueryKey: DocumentsQueryKeysEnum.DOCUMENTS_LIST,
    applicationType
  });
  const { openUpdateDocumentDialog } = useUpdateDocumentDialog();
  const { openEnterDeliveryDateDialog } = useDeliveryDateDialog({});

  const isCancelActionHidden = (record: DocumentsSnapshotClient) => {
    return record.isPermission;
  };

  const isForwardToShipmentActionHidden = (record: DocumentsSnapshotClient) => {
    return record.isPermission;
  };

  const isEnterDeliveryDateActionHidden = (record: DocumentsSnapshotClient) => {
    return record.isPermission;
  };

  return (
    <DocumentsTable
      parentId={applicationId}
      applicationStatus={data?.statusKey}
      cancelDocument={cancelDocument}
      forwardToShipment={forwardToShipment}
      tableProps={tableProps}
      apiRequest={API.documents.getDocumentsSnapshotPage}
      openGenerateDocumentDialog={params => openGenerateDocumentDialog(params)}
      openUpdateDocumentDialog={openUpdateDocumentDialog}
      openEnterDeliveryDateDialog={openEnterDeliveryDateDialog}
      permissionsKeys={permissionsKeys}
      tableActionKeys={tableActionKeys}
      mode={mode}
      isCancelActionHidden={isCancelActionHidden}
      isForwardToShipmentActionHidden={isForwardToShipmentActionHidden}
      isEnterDeliveryDateActionHidden={isEnterDeliveryDateActionHidden}
      isReceiveDateDocumentActionDisabled={isReceiveDateDocumentActionDisabled}
      customDisabledLabelKey="document:message.changeReceiveDateTooltip"
      setGroupKeyIn={setGroupKeyIn}
      groupKeyIn={groupKeyIn}
      setGroupKeyNotIn={setGroupKeyNotIn}
      groupKeyNotIn={groupKeyNotIn}
    />
  );
}

export default DocumentsList;
