import { RoutesElementMapper } from '@libs/config';

import { setRoutesElementMapper } from '@libs/common/v2/router';

import { setAuthApi } from '@libs/auth/api';
import { setDictionaryApi } from '@libs/dictionary';
import { setPermissionElementMapper } from '@libs/permission';
import { setReportApi } from '@libs/report/api';
import { setUserApi } from '@libs/user/api';

import { PermissionElementMapper } from './configs/PermissionElementMapper';

const bootstrap = () => {
  const config = {
    API_PORTAL_URL: process.env.REACT_APP_API_PREFIX,
    API_ANALYSER_URL: `${process.env.REACT_APP_API_PREFIX}/analyser`
  };

  setAuthApi(config.API_PORTAL_URL);
  setReportApi(config.API_PORTAL_URL);
  setDictionaryApi(config.API_PORTAL_URL, 'OPERATOR_WEB_APP');
  setUserApi(config.API_PORTAL_URL);
  setRoutesElementMapper(RoutesElementMapper);
  setPermissionElementMapper(PermissionElementMapper);
};

export { bootstrap };
