import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { useConfirmDialog } from '@libs/common/v2';

import { useResetPasswordMutation } from '../api';

export function useSendEmailToResetPassword() {
  const [t] = useTranslation();
  const [confirm] = useConfirmDialog();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: resetPassword } = useResetPasswordMutation();

  const sendEmailToResetPassword = (id: string, login: string) => {
    confirm({
      title: t('user:dialog.sendEmailToResetPassword.title'),
      shortTitle: t('user:dialog.sendEmailToResetPassword.shortTitle'),
      message: t('user:dialog.sendEmailToResetPassword.content', {
        login
      }),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        resetPassword(id, {
          onSuccess: () => {
            showSuccessSnackbar(t('user:dialog.sendEmailToResetPassword.successMessage'));
            closeDialog();
          },
          onSettled: () => {
            setConfirmLoading(false);
          }
        });
      },
      confirmType: 'danger'
    });
  };

  return { sendEmailToResetPassword };
}
