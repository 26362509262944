import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DataSourceMediatorColumn, QueryDefinition, QueryOrder } from '@stack/report/dist/models';
import clsx from 'clsx';
import _ from 'lodash';

import {
  IconButton,
  SectionTitle,
  Typography,
  useConfirmDialog,
  useFormV2Context,
  useFormV2Watch
} from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { unescapeValue } from '@libs/common/v2/utils';

import { DataSetContext } from '@libs/report/context';
import { useSortingSettingsDialog } from '@libs/report/hooks';
import { QueryOrderSettings } from '@libs/report/model/form.model';
import { useReportDataSourceColumns } from '@libs/report-core/hooks';
import { DataSource } from '@libs/report-core/hooks/useReportDataSourceColumns';

import ReportSourceColumnsField from './ReportSourceColumnsField';
import SortingDirectionIcon from './SortingDirectionIcon';

const ReportTypeBasicColumns = React.memo(() => {
  const [t] = useTranslation();

  const { getValues, setValue, watch } = useFormV2Context();
  const { dataSetIndex } = useContext(DataSetContext);
  const sourceColumns = useReportDataSourceColumns(
    _.get(getValues(), `dataSetDefinitions[${dataSetIndex}].source`) as DataSource
  );
  const snackbar = useSnackbar();
  const [confirm] = useConfirmDialog();

  const sourceId = watch(`dataSetDefinitions[${dataSetIndex}].source.id`);
  const queryDefinition = useFormV2Watch({
    name: `dataSetDefinitions[${dataSetIndex}].queryDefinition`
  }) as QueryDefinition;

  const classes = useStyles({ columnsListRowHeight: 'auto' });

  const [selectedColumns, setSelectedColumns] = useState<Array<DataSourceMediatorColumn>>(
    sourceColumns.filter(({ name }) => queryDefinition.columns?.some(column => column.name === name))
  );
  const { openSortingSettingsDialog } = useSortingSettingsDialog();

  useEffect(() => {
    setSelectedColumns(
      sourceColumns.filter(({ name }) => queryDefinition.columns?.some(column => column.name === name))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceId]);

  const handleChange = (options: DataSourceMediatorColumn[]) => {
    setSelectedColumns(options);

    setValue(
      `dataSetDefinitions[${dataSetIndex}].queryDefinition.columns`,
      options.map(({ name }) => ({ name })),
      { shouldDirty: true }
    );
  };

  const getMissingIndex = (orders: QueryOrder[]): number => {
    const ordersIndexes = orders.map(order => order.index);
    for (let n = 1; n <= ordersIndexes.length + 1; n += 1) {
      if (ordersIndexes.indexOf(n) === -1) return n;
    }

    return -1;
  };

  const handleDeleteColumn = (column: DataSourceMediatorColumn) => {
    const filtredOrders = queryDefinition?.orders?.filter((order: QueryOrder) => order.column !== column.name) || [];
    const missingIndex = getMissingIndex(filtredOrders);
    const name = column.displayName || column.name;
    confirm({
      message: t('reports:message.deleteReportTypeColumnConfirmation', unescapeValue({ name })),
      title: t('reports:dialog.deleteColumn'),
      shortTitle: t('global:dialog.deleteConfirmTitle'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        handleChange(selectedColumns.filter(_column => _column !== column));
        setValue(
          `dataSetDefinitions[${dataSetIndex}].queryDefinition.orders`,
          filtredOrders.map((order: QueryOrder) => {
            if (order.index > missingIndex) {
              return { ...order, index: order.index - 1 };
            }
            return order;
          }),
          { shouldDirty: true }
        );
        snackbar.showSnackbar('success', t('reports:message.deleteReportTypeColumnSuccess', unescapeValue({ name })));
        closeDialog();
      },
      confirmType: 'danger'
    });
  };

  const getColumnSortSettingsValues = (columnName: string): QueryOrder => {
    const columnOrder = queryDefinition.orders?.find((order: QueryOrder) => {
      return order.column === columnName;
    });
    return columnOrder || null;
  };

  const getColumnOrderValue = (columnName: string, values: QueryOrderSettings): QueryOrder[] => {
    if (queryDefinition.orders) {
      const isNewSortingSettings = !queryDefinition.orders?.find((order: QueryOrder) => order.column === columnName);
      const ordersWithChangedIndex = queryDefinition.orders?.map((order: QueryOrder) => {
        if (order.index === values.index) {
          if (isNewSortingSettings) {
            return { ...order, index: queryDefinition.orders.length + 1 };
          }
          return { ...order, index: getColumnSortSettingsValues(columnName)?.index };
        }
        return order;
      }) as QueryOrder[];
      const isUsedIndex = queryDefinition.orders?.map((order: QueryOrder) => order.index).includes(values.index);
      const queryDefinitionOrders = isUsedIndex ? ordersWithChangedIndex : queryDefinition.orders;

      if (isNewSortingSettings) {
        return [...queryDefinitionOrders, { ...values, column: columnName }];
      }
      return queryDefinitionOrders.map((order: QueryOrder) => {
        if (order.column === columnName) {
          return { ...values, column: columnName };
        }
        return order;
      });
    }
    return [{ ...values, column: columnName }];
  };

  return !sourceColumns.length ? (
    <Skeleton variant="rectangular" height={56} className="mb-16" />
  ) : (
    <div className={clsx('pb-16 flex flex-col flex-grow', classes.basicColumnsContainer)}>
      <SectionTitle className="pr-0" title={t('reports:reportTypes.columnList')} isRequired />
      <div className="pr-24">
        <ReportSourceColumnsField
          columns={sourceColumns}
          selectedColumns={selectedColumns}
          onChange={handleChange}
          dataSetIndex={dataSetIndex}
        />
      </div>
      <div className={classes.columnsList}>
        {selectedColumns.map(column => (
          <div key={column.name} className="pl-4 py-8 pr-16 flex justify-between items-center">
            {getColumnSortSettingsValues(column.name)?.direction && (
              <SortingDirectionIcon
                sortState={getColumnSortSettingsValues(column.name)?.direction}
                countValue={getColumnSortSettingsValues(column.name)?.index}
              />
            )}
            <div
              className="flex flex-col justify-start w-full"
              style={{
                marginLeft:
                  queryDefinition.orders?.length &&
                  !queryDefinition.orders.find((order: QueryOrder) => order.column === column.name)
                    ? 55
                    : 0
              }}
            >
              <div className="flex w-full">
                <div>
                  <Typography color="textSecondary" style={{ fontWeight: 600 }}>
                    {column.displayName}
                  </Typography>
                  <Typography variant="caption">{`${column.name}: ${column.type}`}</Typography>
                </div>
              </div>
            </div>
            <IconButton
              title={t('reports:reportTypes.columnSettings.advancedParameters')}
              onClick={() =>
                openSortingSettingsDialog(
                  (values: QueryOrderSettings) => {
                    const columnsOrderValue = getColumnOrderValue(column.name, values).filter(
                      order => order.index !== null
                    );
                    const missingIndex = getMissingIndex(columnsOrderValue);
                    setValue(
                      `dataSetDefinitions[${dataSetIndex}].queryDefinition.orders`,
                      columnsOrderValue.map((order: QueryOrder) => {
                        if (order.index > missingIndex) {
                          return { ...order, index: order.index - 1 };
                        }
                        return order;
                      }),
                      { shouldDirty: true }
                    );
                  },
                  queryDefinition.orders || [],
                  getColumnSortSettingsValues(column.name)
                )
              }
              icon="SortingSettingsIcon"
            />
            <IconButton title={t('global:action.delete')} onClick={() => handleDeleteColumn(column)} icon="TrashIcon" />
          </div>
        ))}
      </div>
    </div>
  );
});

const useStyles = makeStyles<Theme>(theme => ({
  chip: {
    backgroundColor: theme.palette.white,
    border: `1px solid ${theme.palette.blue[500]}`
  },
  basicColumnsContainer: {
    minHeight: 500
  },
  columnsList: {
    width: '100%',
    '& > div': {
      height: ({ columnsListRowHeight }: any) => columnsListRowHeight
    },
    maxHeight: 500,
    paddingRight: 24
  }
}));

export default ReportTypeBasicColumns;
