import { SyntheticEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Row } from 'react-table';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { AssociationSubjectSnapshot as AssociationSubjectSnapshotClient } from '@ibtm/client-domain';
import { AssociationSubjectSnapshot } from '@ibtm/domain';
import { object } from 'yup';

import { Button, CamelCasePath, TableButtonDelete, TableContext, TableLayout, useConfirmDialog } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { useDomainConfigContext } from '@libs/domain/config';
import { useAssociationSubjectTable } from '@libs/domain/entrepreneurs';

import { useDeleteAssociationSubjectMutation } from '../../associations';
import { EditFolderParams } from '../../folder';
import { DEFAULT_COLUMNS } from '../hooks';

import AddEntrepreneuerDialog from './AddEntrepreneuerDialog';

export enum EntrepreneursHiddenActions {
  DELETE = 'DELETE',
  ADD = 'ADD'
}

function EntrepreneursSection({
  hiddenActions = [],
  visibleColumns = DEFAULT_COLUMNS,
  isCollapsable
}: {
  hiddenActions?: EntrepreneursHiddenActions[];
  visibleColumns?: Array<CamelCasePath<AssociationSubjectSnapshot>>;
  isCollapsable?: boolean;
}) {
  const { id } = useParams<EditFolderParams>();
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { isClientPortal } = useDomainConfigContext();
  const tableProps = useAssociationSubjectTable(id, visibleColumns);
  const { mutateAsync: deleteAssociationSubject } = useDeleteAssociationSubjectMutation();
  const [isOpen, setIsOpen] = useState(false);

  const [confirm] = useConfirmDialog();
  const getIsVisible = (action: EntrepreneursHiddenActions) => !hiddenActions.includes(action);

  const handleDelete = (subjectId: string, refetch: () => void) => {
    confirm({
      title: t('associations:action.deleteEntrepreneur'),
      message: t('associations:messages.deleteEntrepreneur'),
      onConfirm: (setLoading, closeDialog) => {
        setLoading(true);
        deleteAssociationSubject({ associationId: id, subjectId }).then(() => {
          showSuccessSnackbar(t('associations:messages.entrepreneurDeleteSuccess'));
          refetch();
          setLoading(false);
          closeDialog();
        });
      },
      confirmType: 'danger'
    });
  };

  useForm({
    mode: 'onBlur',
    resolver: yupResolver(
      object({
        entrepreneurSelect: object().required()
      })
    )
  });

  const openAddDialog = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsOpen(true);
  };
  const rowActions = (row: Row<AssociationSubjectSnapshot | AssociationSubjectSnapshotClient>) => {
    return (
      <TableContext.Consumer>
        {({ refetch }) => (
          <TableButtonDelete
            onClick={() => handleDelete(row?.original?.subject?.id, refetch)}
            tooltipTitle={t('associations:action.deleteEntrepreneur')}
          />
        )}
      </TableContext.Consumer>
    );
  };

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('associations:entrepreneursListTitle')}
      headerActions={
        getIsVisible(EntrepreneursHiddenActions.ADD) && (
          <Button isPrimary label={t('action.add')} onClick={openAddDialog} />
        )
      }
      rowActions={getIsVisible(EntrepreneursHiddenActions.DELETE) && rowActions}
      xlsxDownload={{
        fileName: t('associations:entrepreneursListTitle'),
        pathToXLSXTranslation: 'associations:field',
        apiRequest: params => {
          const { associationId, ...restParams } = params;
          if (isClientPortal) {
            return API.client.association.getAssociationSubjectSnapshotPage('', '', { ...restParams });
          }

          return API.association.getAssociationSubjectSnapshotPage(associationId, { ...restParams });
        }
      }}
      isSection
      isCollapsable={isCollapsable}
    >
      {isOpen && <AddEntrepreneuerDialog open={isOpen} setIsOpen={setIsOpen} />}
    </TableLayout>
  );
}

export default EntrepreneursSection;
