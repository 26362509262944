import React from 'react';
import { useTranslation } from 'react-i18next';
import { FolderDetails } from '@ibtm/domain';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import { AxiosPromise } from 'axios';

import {
  DatepickerField,
  DictionarySelectField,
  GridLayout,
  Section,
  SelectWithFetchedOptionsField,
  Value
} from '@libs/common/v2';
import { convertDateToDateFormat } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { BorrowFolderDialogTypeEnum, BorrowFolderFormEnum, BorrowFolderSnapshot } from '../../../model';

interface BorrowSectionProps {
  row: FolderDetails | BorrowFolderSnapshot;
  contentType: BorrowFolderDialogTypeEnum;
  fetchFunction: (searchText: string) => AxiosPromise<any>;
  getOptionLabel: (option) => string;
  renderOption: (props: Record<string, any>, option: any, state: AutocompleteRenderOptionState) => React.ReactNode;
}

export function BorrowSection({ row, contentType, fetchFunction, getOptionLabel, renderOption }: BorrowSectionProps) {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  return (
    <Section title={t('archive:borrowFolder.borrowTitleSection')} isModalSection>
      <GridLayout itemProps={{ xs: 12, sm: 6 }}>
        {contentType === BorrowFolderDialogTypeEnum.BORROW_FOLDER ? (
          <DatepickerField
            label={t('archive:borrowFolder.field.borrowDate')}
            name={BorrowFolderFormEnum.BORROW_BORROW_DATE}
            isRequired
          />
        ) : (
          <Value label={t('archive:borrowFolder.field.borrowDate')}>
            {convertDateToDateFormat((row as BorrowFolderSnapshot)?.borrowDate)}
          </Value>
        )}

        {contentType !== BorrowFolderDialogTypeEnum.RETURN_FOLDER ? (
          <SelectWithFetchedOptionsField
            fetchFunction={fetchFunction}
            name={BorrowFolderFormEnum.BORROW_BORROWER}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            label={t('archive:borrowFolder.field.borrower')}
            isRequired
          />
        ) : (
          <Value label={t('archive:borrowFolder.field.borrower')}>{(row as BorrowFolderSnapshot)?.borrower}</Value>
        )}
        {contentType !== BorrowFolderDialogTypeEnum.RETURN_FOLDER ? (
          <DictionarySelectField
            label={t('archive:borrowFolder.field.borrowPurpose')}
            dictionaryName={DomainDictionaryEnum.FOLDER_BORROW_PURPOSE}
            name={BorrowFolderFormEnum.BORROW_BORROW_PURPOSE}
            isRequired={contentType === BorrowFolderDialogTypeEnum.CHANGE_BORROWER}
          />
        ) : (
          <Value label={t('archive:borrowFolder.field.borrowPurpose')}>
            {translate(
              DomainDictionaryEnum.FOLDER_BORROW_PURPOSE,
              (row as BorrowFolderSnapshot)?.borrowPurpose,
              t('emptyMark')
            )}
          </Value>
        )}
      </GridLayout>
    </Section>
  );
}
