import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _ from 'lodash';

import { LoaderCircular, Typography, useValueStyles } from '@libs/common/v2';

import { checkIsPartnership, FolderDetailsClient, useFolderPermissionsQuery } from '@libs/domain/folder';
import { usePartnershipPartnersQuery } from '@libs/domain/subject';

import FolderSliderSection from '../FolderSliderSection';

import Licence from './Licence';
import LicencePartnerDetails from './LicencePartnerDetails';

interface Props {
  folderDetails: FolderDetailsClient;
  folderId: string;
  isLoading?: boolean;
}

function LicenceBlockContent({ folderDetails, isLoading, folderId }: Props) {
  const [t] = useTranslation();
  const globalClasses = useValueStyles({});
  const classes = useStyles();

  const { data: folderPermissionsData, isLoading: isPermissionLoading } = useFolderPermissionsQuery(folderId);

  const isPartnership = useMemo(() => {
    return checkIsPartnership(folderDetails?.subject.legalFormKey);
  }, [folderDetails]);

  const subjectId = folderDetails?.subject?.id;

  const { data: partnershipPartnersData } = usePartnershipPartnersQuery(
    { subjectId },
    {
      enabled: Boolean(subjectId),
      refetchOnMount: false
    }
  );

  const partnershipPartners = useMemo(() => {
    return partnershipPartnersData?.partnershipPartners;
  }, [partnershipPartnersData]);

  const renderPartnershipPartnersLicences = useMemo(() => {
    return isPartnership && _.size(partnershipPartners);
  }, [isPartnership, partnershipPartners]);

  return (
    <LoaderCircular isLoading={isLoading || isPermissionLoading} size={25}>
      {_.size(folderPermissionsData) ? (
        folderPermissionsData.map(licence => <Licence licence={licence} key={licence.id} />)
      ) : (
        <div className={clsx(classes.row, 'flex items-center')}>
          <p>{t('error.noData')}</p>
        </div>
      )}
      {renderPartnershipPartnersLicences && (
        <>
          <Typography
            component="div"
            themeVariant="textSm.medium"
            className={clsx(globalClasses.labelWrapper, 'pr-5 mb-0')}
          >
            {t('folder:sidebar.tooltip.partners')}
          </Typography>
          {partnershipPartners?.map(({ id, name }) => (
            <FolderSliderSection key={id} title={name} isCollapsable isCollapsed>
              <LicencePartnerDetails subjectId={id} />
            </FolderSliderSection>
          ))}
        </>
      )}
    </LoaderCircular>
  );
}

const useStyles = makeStyles(() => ({
  row: {
    minHeight: 48
  }
}));

export default LicenceBlockContent;
