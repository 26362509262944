import { ReactNode } from 'react';

import { GridItem, useIsSmallScreen } from '@libs/common/v2';

import { FieldProperties, IGridProps } from '@libs/meta-form/models';

import FieldWrapper from '../fields/FieldWrapper';

type Props = {
  gridItemProps?: IGridProps;
  renderNodes: (children: ReactNode, components: any) => JSX.Element | JSX.Element[];
  smHidden?: boolean;
} & FieldProperties;

function Field({ renderNodes, gridItemProps, smHidden, ...forwardProps }: Props) {
  const { isSmallScreen } = useIsSmallScreen();
  if (isSmallScreen && smHidden) {
    return null;
  }

  return gridItemProps ? (
    <GridItem {...gridItemProps}>
      <FieldWrapper {...forwardProps} />
    </GridItem>
  ) : (
    <FieldWrapper {...forwardProps} />
  );
}

export default Field;
