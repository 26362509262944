import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { useDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { ResourceQueryKeysEnum, useResourceTypeDetailsQuery, useUpdateResourceTypeMutation } from '../../../api';

import {
  fetchResourceTypesDuplicates,
  parseResourceFormFieldsToResourceUpdateRequest,
  useParseResourceTypeToResourceTypeFormData
} from './form-type.utils';
import { ResourceTypeDialog, ResourceTypeDialogProps } from './ResourceTypeDialog';

const ResourceTypeEditDialog = ({
  resourceId,
  closeDialog
}: Pick<ResourceTypeDialogProps, 'closeDialog'> & { resourceId: string }) => {
  const { data, isLoading } = useResourceTypeDetailsQuery(resourceId);
  const { mutate: createResourceType } = useUpdateResourceTypeMutation();
  const { showSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  const { parseResourceTypeToResourceTypeFormData } = useParseResourceTypeToResourceTypeFormData();
  const [t] = useTranslation();
  return (
    <ResourceTypeDialog
      key={data?.id}
      title={t('resource:edit.title')}
      closeDialog={closeDialog}
      isLoading={isLoading}
      handleCheckDuplicateResourceTypes={async params => {
        const results = await fetchResourceTypesDuplicates(params);
        const isOnlyCurrentEdittedResource = results.length === 1 && results[0].id === resourceId;
        return { hasDuplicate: !isOnlyCurrentEdittedResource };
      }}
      freeSaleDefaultValues={{ chargePermissions: Boolean(data?.cargoTransportationExclusions) }}
      defaultValues={data ? parseResourceTypeToResourceTypeFormData(data) : undefined}
      onConfirm={({ formData, setIsLoading }) => {
        setIsLoading(true);
        createResourceType(
          { id: resourceId, data: parseResourceFormFieldsToResourceUpdateRequest(formData) },
          {
            onSuccess: () => {
              showSnackbar('success', t('resource:messages.formEditSuccess'));
              queryCache.invalidateQueries(ResourceQueryKeysEnum.RESOURCE_TYPES_LIST);
              queryCache.invalidateQueries(ResourceQueryKeysEnum.RESOURCE_TYPE);
              closeDialog();
            },
            onSettled: () => {
              setIsLoading(false);
            }
          }
        );
      }}
    />
  );
};

export const useResourceTypeEditDialog = () => {
  const { openDialog } = useDialog();

  const openResourceTypeEditDialog = ({ id }: { id: string }) => {
    openDialog(({ closeDialog }) => <ResourceTypeEditDialog resourceId={id} closeDialog={closeDialog} />);
  };

  return {
    openResourceTypeEditDialog
  };
};
