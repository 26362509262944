import { useTranslation } from 'react-i18next';

import { FormActionEnum, useConfirmDialog } from '@libs/common/v2';

function useFormActionModal() {
  const [confirm] = useConfirmDialog();
  const [t] = useTranslation();

  return ({
    formAction,
    onConfirm,
    onDiscardChanges,
    onCancel
  }: {
    formAction: FormActionEnum;
    onConfirm?: () => void;
    onDiscardChanges?: () => void;
    onCancel?: () => void;
  }) => {
    switch (formAction) {
      case FormActionEnum.SAVE_CHANGES:
        return confirm({
          onConfirm: (_, closeDialog) => {
            onConfirm?.();
            closeDialog();
          },
          onCancel: () => {
            onDiscardChanges?.();
            onCancel?.();
          },
          message: t('message.unsavedChangesMessage'),
          title: t('dialog.unsavedChanges'),
          confirmText: t('action.save'),
          cancelText: t('action.discardChanges')
        });
      case FormActionEnum.DISCARD_CHANGES:
        return confirm({
          onConfirm: (_, closeDialog) => {
            onDiscardChanges?.();
            closeDialog();
          },
          onCancel,
          message: t('message.discardFormChanges'),
          title: t('action.discardChanges'),
          confirmText: t('action.reject')
        });
      default:
        return null;
    }
  };
}

export default useFormActionModal;
