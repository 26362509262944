import { QueryConfig, usePaginatedQuery } from 'react-query';
import { MoneyTransferSearchFilter, MoneyTransferSnapshotResponse } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import { PricingQueryKeysEnum } from './PricingQueryKeysEnum';

export type MoneyTransferSearchParams = {
  applicationId: string;
} & MoneyTransferSearchFilter;

async function getMoneyTransfersForApplication({ applicationId, ...params }: MoneyTransferSearchParams) {
  const { data } = await API.moneyTransfer.getMoneyTransfersForApplication(applicationId, params, {
    ...endpointsConfig.getMoneyTransfersForApplication
  });
  return data;
}

function useMoneyTransfersForApplicationQuery(
  params: MoneyTransferSearchParams,
  queryConfig: QueryConfig<MoneyTransferSnapshotResponse, unknown> = {}
) {
  return usePaginatedQuery(
    [PricingQueryKeysEnum.APPLICATION_MONEY_TRANSFERS, params],
    () => getMoneyTransfersForApplication(params),
    queryConfig
  );
}

export default useMoneyTransfersForApplicationQuery;
