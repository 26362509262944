import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { number as YupNumber, object as YupObject, string as YupString } from 'yup';

import { FinancialSecurityFields } from '../model';

function useFinancialSecurityValidationSchema() {
  const [t] = useTranslation();
  const validationSchema = YupObject<FinancialSecurityFields>({
    typeKey: YupObject<{ key: string; value: string }>().nullable().required(),
    releaseDate: YupString()
      .nullable()
      .test(
        'isBeforeValidTo',
        t('financialSecurity:validation.releaseDate'),
        function checkIsBeforeValidTo(releaseDate) {
          const { expirationDate } = this.parent as FinancialSecurityFields;
          return !expirationDate || moment(expirationDate).isAfter(releaseDate);
        }
      ),
    expirationDate: YupString()
      .nullable()
      .required()
      .test(
        'isAfterValidFrom',
        t('financialSecurity:validation.expirationDate'),
        function checkIsAfterValidFrom(expirationDate) {
          const { releaseDate } = this.parent as FinancialSecurityFields;
          return !releaseDate || moment(releaseDate).isSameOrBefore(expirationDate);
        }
      ),
    currencyKey: YupObject<{ key: string; value: string }>().nullable().required(),
    amount: YupNumber().nullable().required(),
    notes: YupString().nullable()
  });

  return { validationSchema };
}

export default useFinancialSecurityValidationSchema;
