import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { OrganizationUnitLiteExternal } from '@avispon/group/dist/models';
import {
  OrganizationUnitSnapshotExternal,
  PageOrganizationUnitSnapshotExternal
} from '@avispon/organization-structure/dist/models';
import { AutocompleteProps } from '@mui/material';
import { AxiosResponse } from 'axios';

import {
  AutocompleteLazyFetchSelect,
  FormErrorType,
  NUMBER_OF_AUTOCOMPLETE_RESULTS,
  SelectOption,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';

import { OrganizationQueryKeysEnum } from '@libs/organization-structure';
import { API } from '@libs/user/api';

interface OrganizationUnitAutocompleteFieldProps {
  name: string;
  label?: string;
  optionChanged?: (option: SelectOption) => void;
  excludeIds?: Array<string>;
  renderOption?: (item) => string;
  permissionNames?: string[];
  anyPermissionNames?: string[];
  includeReplacements?: boolean;
  isRequired?: boolean;
  isClearable?: boolean;
}

type CustomAutocompleteProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> = {
  autoCompleteProps?: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>;
};

function OrganizationUnitAutocompleteField<T>(
  props: OrganizationUnitAutocompleteFieldProps &
    Partial<CustomAutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined>>
) {
  const {
    name,
    label,
    optionChanged,
    excludeIds,
    renderOption,
    permissionNames,
    anyPermissionNames,
    includeReplacements,
    isRequired,
    isClearable
  } = props;
  const { control, watch, setValue, clearErrors } = useFormV2Context();
  const unit = watch('unitId');

  useEffect(() => {
    optionChanged(unit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit]);

  useEffect(() => {
    setValue(name, null);
    clearErrors(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFunctionResolverWithPage = (searchText: string, page: number) => {
    if (permissionNames && anyPermissionNames) {
      return API.group.findMyOrganizationUnits({
        page: 1 * page,
        size: NUMBER_OF_AUTOCOMPLETE_RESULTS,
        permissionNames,
        anyPermissionNames,
        sort: [typedNameV2<OrganizationUnitLiteExternal>('name')],
        includeReplacements,
        ...(searchText ? { unitNameFragment: searchText } : {})
      });
    }

    return API.organizationStructure.findOrganizationUnit({
      page: 1 * page,
      size: NUMBER_OF_AUTOCOMPLETE_RESULTS,
      sort: [typedNameV2<OrganizationUnitSnapshotExternal>('name')],
      ...(excludeIds && { unitIds: excludeIds }),
      ...(searchText ? { nameFragment: searchText } : {})
    });
  };

  const fetchedDataSelectParser = (res: AxiosResponse<PageOrganizationUnitSnapshotExternal>) => {
    return res.data.content?.map(item => ({
      id: item.id,
      name: item.name,
      value: item.name,
      inputValue: item.name
    }));
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <AutocompleteLazyFetchSelect
          name={name}
          label={label}
          renderOption={renderOption}
          value={value}
          fetchFunctionResolverWithPage={fetchFunctionResolverWithPage}
          fetchedDataSelectParser={fetchedDataSelectParser}
          queryKey={OrganizationQueryKeysEnum.ORGANIZATION_UNIT_LIST}
          onChange={(_event, selectValue) => {
            onChange(selectValue);
          }}
          isError={!!error && error?.type !== FormErrorType.WARNING}
          helperText={error?.message}
          isRequired={isRequired}
          isDisableClearable={!isClearable}
        />
      )}
    />
  );
}

export default OrganizationUnitAutocompleteField;
