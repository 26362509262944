import { QueryConfig, useQuery } from 'react-query';
import { DataSourceMediatorDetailsSnapshot } from '@stack/report';

import { API, endpointsConfig } from '..';

import { ReportCoreQueryKeysEnum } from './QueryKeysEnum';

const getReportSourceDetails = async (_, params: { sourceId: string }) => {
  const { data } = await API.mediatorDataSources.getReportSource(params.sourceId, {
    ...endpointsConfig.getReportSource
  });
  return data;
};

export default function useReportSourceDetailsQuery(
  params: { sourceId: string },
  queryParams?: QueryConfig<DataSourceMediatorDetailsSnapshot>
) {
  return useQuery([ReportCoreQueryKeysEnum.REPORT_SOURCE_DETAILS, params], getReportSourceDetails, {
    enabled: Boolean(params.sourceId),
    ...queryParams
  });
}
