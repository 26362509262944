import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { LoaderCircular, Typography, useValueStyles } from '@libs/common/v2';

import { FolderDetailsClient } from '@libs/domain/folder';

import FolderSliderSection from '../FolderSliderSection';

interface Props {
  folderDetails: FolderDetailsClient;
  emptyMode?: boolean;
  isLoading?: boolean;
}

function NotesSection({ folderDetails, emptyMode, isLoading }: Props) {
  const [t] = useTranslation();
  const [noteToRender, setNoteToRender] = useState<string>('');
  const globalClasses = useValueStyles({});
  const classes = useStyles();

  const note = useMemo(() => {
    return folderDetails?.note;
  }, [folderDetails]);

  useEffect(() => {
    if (note?.content) {
      if (note.content.length > 250) {
        const truncatedNote = `${note.content.substring(0, 250)}(...)`;
        setNoteToRender(truncatedNote);
      } else setNoteToRender(note.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderDetails]);

  return (
    <FolderSliderSection title={t('folder:sidebar.section.notes')} isCollapsable>
      <LoaderCircular isLoading={isLoading} size={25}>
        {emptyMode ? (
          <div className={clsx(classes.row, 'flex items-center')}>
            <p>{t('error.noData')}</p>
          </div>
        ) : (
          <div className={clsx(classes.row, 'flex items-center')}>
            <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
              {noteToRender || t('folder:sidebar.message.noNote')}
            </Typography>
          </div>
        )}
      </LoaderCircular>
    </FolderSliderSection>
  );
}

const useStyles = makeStyles(() => ({
  row: {
    minHeight: 48
  }
}));

export default NotesSection;
