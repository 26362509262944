import { useDialog, useRouter } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { CreateProceedingDialog } from '../components/proceedings-dialogs';

const useCreateProceedingAction = (previousLink: (folderId?: string) => string) => {
  const { openDialog } = useDialog();
  const { goToPage, routes } = useRouter();

  const onSubmitRedirect = (proceedingTypeKey: string, folderId: string) => {
    goToPage(
      {
        pathname:
          proceedingTypeKey === DomainDictionaryEntry.PROCEEDING_TYPE.ADMINISTRATIVE
            ? routes.proceedingAdministrationCreate(folderId)
            : routes.proceedingVerificationCreate(folderId)
      },
      {
        state: {
          from: previousLink(folderId)
        }
      }
    );
  };

  const open = (folderId: string) => {
    openDialog(({ closeDialog }) => (
      <CreateProceedingDialog folderId={folderId} closeDialog={closeDialog} onSubmitRedirect={onSubmitRedirect} />
    ));
  };

  return {
    openCreateProceedingDialog: open
  };
};

export default useCreateProceedingAction;
