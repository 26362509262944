import React from 'react';
import { VelocityComponent } from 'velocity-react';

import 'velocity-animate/velocity.ui';

interface AnimateProps {
  children: React.ReactElement & React.HTMLAttributes<React.ReactElement>;
  paragraph?: string;
  animation?: any;
  runOnMount?: boolean;
  targetQuerySelector?: any;
  interruptBehavior?: string;
  visibility?: string;
  duration?: number;
  delay?: number;
  easing?: Array<number>;
  display?: string;
  setRef?: any;
}

const Animate = React.forwardRef((props: AnimateProps, ref: React.Ref<HTMLDivElement>) => {
  const children = React.cloneElement(props.children, {
    style: {
      ...props.children.style,
      visibility: 'hidden'
    }
  });
  return (
    <VelocityComponent ref={ref} {...props}>
      {children}
    </VelocityComponent>
  );
});

Animate.defaultProps = {
  animation: 'transition.fadeIn',
  runOnMount: true,
  targetQuerySelector: null,
  interruptBehavior: 'stop',
  visibility: 'visible',
  duration: 300,
  delay: 50,
  easing: [0.4, 0.0, 0.2, 1],
  display: null,
  setRef: undefined
} as Partial<AnimateProps>;

export default React.memo(Animate);
