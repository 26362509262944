import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Row } from 'react-table';
import { useSnackbar } from '@enigma/fe-ui';
import { ProceedingKreptdCertificateDetails } from '@ibtm/domain';
import { AxiosResponse } from 'axios';

import {
  Button,
  FormMode,
  TableButtonDelete,
  TableButtonEdit,
  TableEvent,
  TableLayout,
  useConfirmDialog,
  useFormV2Context,
  useTableEventsRef,
  useViewModesV2
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import useTransportManagerQuery from '../../../../transport-manager/api/queries/useTransportManagerQuery';
import { RoadTransportQueryKeysEnum, useDeleteProceedingsKreptdCertificateMutation } from '../../../api';
import { useCertificatesTable } from '../../../hooks';
import { useAddCertificateDialog } from '../../../hooks/dialogs';
import useEditCertificateDialog from '../../../hooks/dialogs/useEditCertificateDialog';
import { parseFormData } from '../../../utils';

interface IProps {
  data: ProceedingKreptdCertificateDetails[];
}

function CertificatesTable({ data = [] }: IProps) {
  const [t] = useTranslation();
  const queryCache = useQueryCache();
  const { proceedingId } = useParams();

  const { formMode, setValue, getValues } = useFormV2Context();
  const formModeRef = useRef(null);
  formModeRef.current = formMode;
  const { editMode, viewMode } = useViewModesV2(formMode);
  const tableProps = useCertificatesTable(data);
  const [confirm] = useConfirmDialog();
  const { showSuccessSnackbar } = useSnackbar();
  const { openEditCertificateDialog } = useEditCertificateDialog();
  const { openAddCertificateDialog } = useAddCertificateDialog();
  const { mutate: deleteCertificate, isLoading } = useDeleteProceedingsKreptdCertificateMutation();
  const transportManagerId = getValues('transportManagerId') as string;
  const { data: transportManagerData } = useTransportManagerQuery(transportManagerId, {
    enabled: Boolean(transportManagerId)
  });

  const { tableEventsRef } = useTableEventsRef();

  const transportManagerCertificateId = useMemo(
    () => transportManagerData?.certificate?.id as string,
    [transportManagerData]
  );

  useEffect(() => {
    tableEventsRef.current?.emitEvent(TableEvent.RERENDER_ROW_ACTIONS);
    // eslint-disable-next-line no-console
  }, [tableEventsRef, formMode]);

  const onEditClick = (row: Row<ProceedingKreptdCertificateDetails>) => {
    openEditCertificateDialog(row, onSubmitEdit);
  };

  const onCreateClick = useCallback(
    () => openAddCertificateDialog(proceedingId),
    [openAddCertificateDialog, proceedingId]
  );

  const handleDeleteCertificate = useCallback(
    (certificateId: string, certificateNumber: string) => {
      confirm({
        title: t('roadTransport:dialog.deleteCertificateFromProceedingKreptdTitle'),
        message: t('roadTransport:messages.deleteCertificateFromProceedingKreptdConfirm', {
          certificateNumber,
          interpolation: { escapeValue: false }
        }),
        onConfirm: (setConfirmLoading, closeDialog) => {
          setConfirmLoading(true);
          deleteCertificate(
            {
              certificateId,
              proceedingId
            },
            {
              onSuccess: async () => {
                await queryCache.invalidateQueries([RoadTransportQueryKeysEnum.PROCEEDING_DETAILS, proceedingId]);
                closeDialog();
                showSuccessSnackbar(t('roadTransport:messages.deleteCertificateFromProceedingKreptdMessage'));
              },
              onSettled: () => setConfirmLoading(false)
            }
          );
        },
        confirmType: 'danger'
      });
    },
    [t, confirm, deleteCertificate, proceedingId, queryCache, showSuccessSnackbar]
  );

  const onSubmitEdit = (formData: ProceedingKreptdCertificateDetails) => {
    const formDataParsed = parseFormData(formData);

    setValue(
      'certificates',
      getValues('certificates').map((item: any) => (item.id === formDataParsed.id ? { ...formDataParsed } : item))
    );

    showSuccessSnackbar(t('roadTransport:messages.certificateEditSuccess'));
  };

  return (
    <TableLayout
      {...tableProps}
      tableEventsRef={tableEventsRef}
      pageTitle={t('roadTransport:list.certificates')}
      xlsxDownload={{
        fileName: t('roadTransport:list.certificates'),
        pathToXLSXTranslation: 'roadTransport:fields',
        apiRequest: async () => ({ data: { content: data } } as AxiosResponse | PromiseLike<AxiosResponse>)
      }}
      headerActions={viewMode && <Button label={t('action.add')} onClick={onCreateClick} variant="outlined" />}
      rowActions={(row: Row<ProceedingKreptdCertificateDetails>) => {
        const { original } = row;
        const isCertificateTransportManager = original.id === transportManagerCertificateId;
        if (formModeRef.current === FormMode.VIEW) {
          return (
            <TableButtonDelete
              onClick={() => handleDeleteCertificate(original.id, original.number)}
              isButtonLoading={isLoading}
              isDisabled={isCertificateTransportManager}
              tooltipTitle={
                isCertificateTransportManager && t('roadTransport:messages.unableToDeleteTransportManagerCertificate')
              }
            />
          );
        }
        if (formModeRef.current === FormMode.EDIT) {
          return (
            <TableButtonEdit
              tooltipTitle={t('roadTransport:actions.editReturnCertificate')}
              onClick={() => onEditClick(row)}
            />
          );
        }
        return null;
      }}
      isFilterEnabled={false}
      isRefreshEnabled={false}
      actionsColumnWidth={editMode || viewMode ? 70 : 0}
      isSection
      isCollapsable
    />
  );
}

export default CertificatesTable;
