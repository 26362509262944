import { ResourceObjectTypeTransformCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function transformType(requestData: ResourceObjectTypeTransformCreateRequest) {
  return API.resourceObjects.transformResourceObjectType(requestData, {
    ...endpointsConfig.transformResourceObjectType
  });
}

function useTransformTypeMutation() {
  return useMutation(transformType);
}

export default useTransformTypeMutation;
