import { CivicBoardCalculateFreeDisposalLimitRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function calculateForFolder(params: CivicBoardCalculateFreeDisposalLimitRequest) {
  return API.civicBoard.calculateFreeDisposalLimit(params, {
    ...endpointsConfig.calculateFolderLimit
  });
}

function useCalculateLimitFreeDisposalForFolderMutation() {
  return useMutation(calculateForFolder);
}

export default useCalculateLimitFreeDisposalForFolderMutation;
