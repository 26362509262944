import { useTranslation } from 'react-i18next';

import { convertDateToDateTimeFormat } from '@libs/common/v2/utils';

import { DictionaryEntryNameEnum, useDictionaryTranslations } from '@libs/dictionary';
import { ColumnType } from '@libs/report-core/config';

import { ParameterData } from '../model';

export const ParameterValue = ({ value, data }: { value: any; data: ParameterData }) => {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  if (!value) {
    return t('emptyMark');
  }

  switch (data?.type) {
    case ColumnType.CHARACTER:
      return data.dictionaryName ? translate(data.dictionaryName as DictionaryEntryNameEnum, value as string) : value;
    case ColumnType.BOOLEAN: {
      return value === true ? t('action.yes') : t('action.no');
    }
    case ColumnType.DATE:
    case ColumnType.TIMESTAMP_TZ:
    case ColumnType.TIMESTAMP:
    case ColumnType.TIME:
      return convertDateToDateTimeFormat(value as string);
    case ColumnType.DOUBLE:
    case ColumnType.LONG:
    case ColumnType.GUID:
    default:
      return value;
  }
};
