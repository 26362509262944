import { useTranslation } from 'react-i18next';
import { DefaultApiListMyGroupMembershipsRequest } from '@avispon/group';
import { GroupMembershipDetailsExternal } from '@avispon/group/dist/models';

import { BooleanValue, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';

import { OrganizationUnitFilterV2 } from '@libs/user/components';

function usePermissionGroupColumns() {
  const [t] = useTranslation();
  const { createColumns } = useCreateColumns<GroupMembershipDetailsExternal>({
    pathToTranslate: 'user:groupField'
  });

  const columns = createColumns([
    {
      header: 'group',
      accessor: 'group.name',
      type: 'TEXT',
      width: 200
    },
    {
      accessor: 'unit.name',
      header: 'unit',
      type: 'CUSTOM_COLUMN',
      filter: OrganizationUnitFilterV2,
      width: 300
    },
    {
      id: 'withSubordinates',
      tooltipTitle: ({ withSubordinates }) => (withSubordinates ? t('action.yes') : t('action.no')),
      customAccessor: ({ withSubordinates }) => (
        <BooleanValue value={withSubordinates} valueTrueText={t('action.yes')} valueFalseText={t('action.no')} />
      ),
      customXlsxAccessor: ({ withSubordinates }) => (withSubordinates ? t('action.yes') : t('action.no')),
      header: 'includeSubordinates',
      type: 'BOOLEAN',
      width: 200,
      isSortable: false
    },
    {
      accessor: 'replacement.replaced.name',
      header: 'replacement',
      type: 'TEXT',
      width: 200,
      isFilterable: false
    }
  ]);

  const mappedFilterFields: TableFilterMapper<GroupMembershipDetailsExternal, DefaultApiListMyGroupMembershipsRequest> =
    {
      groupName: value => ({ groupName: value }),
      withSubordinates: ({ value }) => ({ withSubordinates: value }),
      unitName: values => ({ unitIds: values.map(({ value }) => value) })
    };

  const mappedSortFields: TableSortMapper<GroupMembershipDetailsExternal> = {
    group: 'groupName',
    unit: 'unit',
    replacement: 'replacement'
  };

  return { columns, mappedFilterFields, mappedSortFields };
}

export default usePermissionGroupColumns;
