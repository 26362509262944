import { Dispatch, SetStateAction, useMemo } from 'react';
import { SingleUsePermissionSubmissionDetails, SingleUseSubmissionItemSnapshot } from '@ibtm/domain';
import { FolderExtendedLite } from '@ibtm/domain/dist/models/folder-extended-lite';
import moment from 'moment';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  FormMode,
  GridLayout,
  InputMode,
  Section,
  SelectOption,
  TextInputField,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';
import { NumberInputField, useViewModesV2 } from '@libs/common/v2/form';
import { convertDateToDateTimeFormat } from '@libs/common/v2/utils';

import { FolderSelectField } from '@libs/domain/application/components';
import { SingleUseSubmissionItemsTable } from '@libs/domain/social-commission';

interface IProps {
  formMode: FormMode;
  singleUseSubmissionItems: SingleUseSubmissionItemSnapshot[];
  setSingleUseSubmissionItems: Dispatch<SetStateAction<SingleUseSubmissionItemSnapshot[]>>;
}

function SocialCommissionDisposablePermitsEditForm({
  formMode,
  singleUseSubmissionItems,
  setSingleUseSubmissionItems
}: IProps) {
  const { setValue } = useFormV2Context();
  const { editMode, createMode, viewMode } = useViewModesV2(formMode);

  const getLabel = partialTranslate('foreignPermits:tabs.socialCommission.tabs.socialCommissionDisposablePermits');
  const inputMode = useMemo(() => (editMode || createMode ? InputMode.FORM : InputMode.VIEW), [createMode, editMode]);

  const handleSetFolder = (folder: SelectOption<FolderExtendedLite>) => {
    setValue('folder', { number: folder.value?.number });
    setValue('subject', { name: folder.value?.subject?.name });
  };

  return (
    <>
      <Section title={getLabel('sections.details')}>
        <GridLayout itemProps={{ md: 6, xs: 12 }}>
          {!createMode && (
            <TextInputField
              name={typedNameV2<SingleUsePermissionSubmissionDetails>('number')}
              inputMode={InputMode.VIEW}
              label={getLabel('fields.number')}
              isRequired
            />
          )}
          {!createMode && <div />}
          <DatepickerField
            name={typedNameV2<SingleUsePermissionSubmissionDetails>('year')}
            views={['year']}
            minDate={moment().subtract(1, 'year')}
            maxDate={moment().add(1, 'year')}
            label={getLabel('fields.year')}
            inputMode={editMode && InputMode.VIEW}
            isRequired
          />
          <NumberInputField
            name={typedNameV2<SingleUsePermissionSubmissionDetails>('month')}
            inputMode={inputMode}
            min={1}
            max={12}
            label={getLabel('fields.month')}
            isRequired
          />
          {createMode ? (
            <FolderSelectField
              fieldName={typedNameV2<SingleUsePermissionSubmissionDetails>('folder.number')}
              label={getLabel('fields.folderNumber')}
              customSetValue={handleSetFolder}
              isRequired
            />
          ) : (
            <TextInputField
              name={typedNameV2<SingleUsePermissionSubmissionDetails>('folder.number')}
              inputMode={InputMode.VIEW}
              label={getLabel('fields.folderNumber')}
            />
          )}
          <TextInputField
            name={typedNameV2<SingleUsePermissionSubmissionDetails>('subject.name')}
            inputMode={InputMode.VIEW}
            label={getLabel('fields.subject:name')}
          />
        </GridLayout>
      </Section>
      {viewMode && (
        <Section title={getLabel('sections.generalData')}>
          <GridLayout itemProps={{ xs: 12, sm: 6 }}>
            <DatepickerField
              name={typedNameV2<SingleUsePermissionSubmissionDetails>('created')}
              viewModeDateParser={convertDateToDateTimeFormat}
              label={getLabel('fields.createdDate')}
            />
            <TextInputField
              name={typedNameV2<SingleUsePermissionSubmissionDetails>('author.name')}
              inputMode={InputMode.VIEW}
              label={getLabel('fields.author')}
            />
            <DatepickerField
              name={typedNameV2<SingleUsePermissionSubmissionDetails>('modified')}
              viewModeDateParser={convertDateToDateTimeFormat}
              label={getLabel('fields.editedDate')}
            />
            <TextInputField
              name={typedNameV2<SingleUsePermissionSubmissionDetails>('modifier.name')}
              label={getLabel('fields.editor')}
            />
          </GridLayout>
        </Section>
      )}
      <SingleUseSubmissionItemsTable
        formMode={formMode}
        singleUseSubmissionItems={singleUseSubmissionItems}
        setSingleUseSubmissionItems={setSingleUseSubmissionItems}
      />
    </>
  );
}

export default SocialCommissionDisposablePermitsEditForm;
