import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { MoneyTransferSnapshot } from '@ibtm/domain';
import { makeStyles } from '@mui/styles';

import { TableLayout } from '@libs/common/v2';
import { important } from '@libs/common/v2/utils';

import { useMoneyTransfersForApplicationQuery } from '../api';
import { useMoneyTransfersSearchTable } from '../hooks';

import MoneyTransferAddButton from './MoneyTransferAddButton';

interface IProps {
  applicationId: string;
  onSuccess?: () => void;
  isSection?: boolean;
}

function MoneyTransfersSearchTable({ applicationId, isSection = true, onSuccess }: IProps) {
  const [t] = useTranslation();
  const classes = useStyles();
  const { data: moneyTransferData } = useMoneyTransfersForApplicationQuery({ applicationId });
  const linkedMoneyTransfers = useMemo(() => moneyTransferData?.content.map(({ id }) => id) ?? [], [moneyTransferData]);

  const tableProps = useMoneyTransfersSearchTable(applicationId, [
    'paymentDate',
    'transactionDescription',
    'senderAccountNumber',
    'senderName',
    'amount',
    'availableAmount'
  ]);

  const TableAction = useCallback(
    ({ original }: Row<MoneyTransferSnapshot>) => (
      <MoneyTransferAddButton
        applicationId={applicationId}
        linkedMoneyTransfers={linkedMoneyTransfers}
        onSuccess={onSuccess}
        moneyTransferId={original.id}
      />
    ),
    [applicationId, linkedMoneyTransfers, onSuccess]
  );

  return (
    <TableLayout
      {...tableProps}
      sectionClassName={classes.table}
      sectionContentClassName={classes.tableContent}
      pageTitle={t('pricing:moneyTransfers.action.search.tableTitle')}
      rowActions={TableAction}
      disabledRows={linkedMoneyTransfers}
      disabledRowTooltipText={t('pricing:moneyTransfers.action.search.moneyTransferAlreadyLinked')}
      isSection={isSection}
      isFilterEnabled
    />
  );
}

const useStyles = makeStyles(() => ({
  table: {
    height: important('60vh')
  },
  tableContent: {
    height: important('50vh')
  }
}));

export default MoneyTransfersSearchTable;
