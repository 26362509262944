import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormMode, FormV2Context } from '@libs/common/v2';

import { ErrorContext } from '@libs/domain/suspensions/context';

import { useSuspensionDetailsValidation } from '../../hooks';

import SuspensionDetails from './SuspensionDetails';

interface IProps {
  formMode: FormMode;
}

function SuspensionDetailsWrapper({ formMode }: IProps) {
  const [localSubmitting, setLocalSubmitting] = useState<boolean>(false);
  const [isCaseNumberError, setIsCaseNumberError] = useState<boolean>(false);
  const value = useMemo(() => ({ isCaseNumberError, setIsCaseNumberError }), [isCaseNumberError, setIsCaseNumberError]);
  const { validationSchema } = useSuspensionDetailsValidation(isCaseNumberError);

  const form = useForm<Record<string, any>>({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema)
  });

  const formDataProvider = useMemo(() => {
    return {
      ...form,
      formMode,
      isDirty: form.formState.isDirty,
      isSubmitting: form.formState.isSubmitting || localSubmitting
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, form.formState, form.formState.errors]);

  return (
    <ErrorContext.Provider value={value}>
      <FormV2Context.Provider value={formDataProvider}>
        <SuspensionDetails setSubmitting={setLocalSubmitting} />
      </FormV2Context.Provider>
    </ErrorContext.Provider>
  );
}

export default SuspensionDetailsWrapper;
