import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormHelperText,
  InputLabel,
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { KeyType } from '@libs/common';
import {
  DEFAULT_TOGGLE_BUTTONS_OPTIONS,
  IToggleButtonOptions,
  ToggleButtonInitialValue,
  useInputStyles
} from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';

interface IProps {
  fieldName: FieldPath<FieldValues>;
  fieldValue: any;
  helperText?: string;
  label?: string;
  initialValue?: ToggleButtonInitialValue;
  options?: Array<IToggleButtonOptions>;
  orientation?: 'horizontal' | 'vertical';
  size?: 'small' | 'medium' | 'large';
  onChange: (value: any) => void;
  isRequired?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  isWarning?: boolean;
  isExclusive?: boolean;
}

function ToggleButton({
  fieldName,
  fieldValue,
  helperText,
  label,
  options,
  initialValue,
  orientation,
  size,
  onChange,
  isRequired,
  isDisabled,
  isError,
  isWarning,
  isExclusive = true
}: IProps) {
  const [t] = useTranslation();
  const inputStyles = useInputStyles({ isRequired, isDisabled, isError, isWarning });
  const classes = useStyle();
  const [alignment, setAlignment] = useState<ToggleButtonInitialValue>(initialValue);

  const hasCustomOptions = useMemo(() => options && options.length > 0, [options]);
  const renderOptions = useMemo(
    () =>
      (hasCustomOptions ? options : DEFAULT_TOGGLE_BUTTONS_OPTIONS).map(({ value, name }) => (
        <MuiToggleButton key={name} value={value} name={fieldName}>
          {t(name as KeyType)}
        </MuiToggleButton>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasCustomOptions, options]
  );

  const handleChange = (e: MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      onChange(newAlignment);
    }
  };

  useEffect(() => {
    if (fieldValue === undefined && initialValue !== null) {
      setAlignment(initialValue);
    } else {
      setAlignment(fieldValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue]);

  return (
    <div className={classes.group}>
      {label && (
        <InputLabel className={clsx(classes.root)}>
          <span>
            {label} {isRequired && <span className={inputStyles.asterisk}>&nbsp;*</span>}
          </span>
        </InputLabel>
      )}
      <MuiToggleButtonGroup
        value={alignment}
        className={classes.toggleButton}
        onChange={handleChange}
        orientation={orientation}
        size={size}
        exclusive={isExclusive}
      >
        {renderOptions}
      </MuiToggleButtonGroup>
      {helperText && (
        <FormHelperText className={clsx(inputStyles.formHelperText)} error={isError}>
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
}

const useStyle = makeStyles<Theme>(theme => ({
  group: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  root: {
    ...theme.typography.textSm.medium,
    padding: 0,
    color: theme.palette.grey[700],
    borderColor: 'red'
  },
  toggleButton: {
    '&.MuiToggleButtonGroup-root': {
      marginTop: theme.marginBase,
      '& .MuiToggleButton-root': {
        ...theme.typography.textSm.medium,
        color: theme.palette.grey[700],
        borderColor: theme.palette.grey[300],
        textTransform: 'initial',
        borderRadius: 0,
        '&:hover': {
          backgroundColor: theme.palette.grey[50]
        },
        '&:first-of-type': {
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8
        },
        '&:last-of-type': {
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8
        },
        '&.Mui-selected': {
          backgroundColor: theme.palette.blue[500],
          color: theme.palette.white
        }
      }
    }
  }
}));

export default ToggleButton;
