import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useDialog } from '@libs/common/v2';

import { DocumentsQueryKeysEnum } from '../../api';
import EnterDeliveryDateDialog from '../../components/delivery-date/EnterDeliveryDateDialog';
import { DocumentListParams, DocumentsSnapshotClient } from '../../model';

interface IProps {
  listQueryKey?: string;
  isKREPTProceeding?: boolean;
}

const useDeliveryDateDialog = ({
  listQueryKey = DocumentsQueryKeysEnum.DOCUMENTS_LIST,
  isKREPTProceeding = false
}: IProps) => {
  const { openDialog } = useDialog();
  const { proceedingId } = useParams<DocumentListParams>();

  const open = useCallback(
    (documentData: DocumentsSnapshotClient, onSuccess: () => void) => {
      openDialog(({ closeDialog }) => (
        <EnterDeliveryDateDialog
          closeDialog={closeDialog}
          documentData={documentData}
          onSuccess={onSuccess}
          listQueryKey={listQueryKey}
          isKREPTProceeding={isKREPTProceeding}
          proceedingId={proceedingId}
        />
      ));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog, listQueryKey]
  );

  return {
    openEnterDeliveryDateDialog: open
  };
};

export default useDeliveryDateDialog;
