import { useTranslation } from 'react-i18next';

import { SocialCommissionTabEnum } from '../components/common/model/social-comission.model';

const useSocialCommissionBreadcrumps = (modePath?, mode?) => {
  const [t] = useTranslation();
  const applicationDetails = [
    { text: t('foreignPermits:title'), to: '/released-permits' },
    {
      text: t('foreignPermits:tabs.socialCommission.tabs.socialCommissionList.title'),
      to: `/social-commission?tab=${SocialCommissionTabEnum.PETITIONS}`
    },
    {
      text: t<any>(`${modePath}.${mode}.title`),
      to: '/social-commission?tab=list'
    }
  ];
  const disposablePermits = [
    { text: t('foreignPermits:title'), to: '/released-permits' },
    {
      text: t('foreignPermits:tabs.socialCommission.tabs.socialCommissionList.title'),
      to: `/social-commission?tab=${SocialCommissionTabEnum.PETITIONS}`
    },
    {
      text: t<any>(`${modePath}.${mode}.title`),
      to: '/social-commission?tab=list'
    }
  ];
  const permitEkmt = [
    { text: t('foreignPermits:tabs.releasedPermits.title'), to: '/released-permits' },
    {
      text: t<any>(`${modePath}.${mode}.title`),
      to: '/released-permits'
    }
  ];
  const tradeRulesDetails = [
    { text: t('foreignPermits:title'), to: '/released-permits' },
    {
      text: t('foreignPermits:tabs.socialCommission.title'),
      to: '/social-commission'
    },
    {
      text: t('foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.title'),
      to: '/social-commission?tab=trade-rules'
    }
  ];
  return { applicationDetails, tradeRulesDetails, disposablePermits, permitEkmt };
};

export default useSocialCommissionBreadcrumps;
