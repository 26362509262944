import { MutationConfig, useMutation } from 'react-query';
import { SubjectDetails as OperatorSubjectDetails } from '@ibtm/domain';
import { AxiosResponse } from 'axios';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { SubjectUpdateRequestUnion } from '../../model';
import { endpointsConfig } from '../endpoints-config';

export function editSubject({ subjectId, formData }: { subjectId: string; formData: SubjectUpdateRequestUnion }) {
  return API.subject.updateSubject(subjectId, formData, {
    ...endpointsConfig.updateSubject
  });
}

export function editSubjectClient({ subjectId, formData }: { subjectId: string; formData: SubjectUpdateRequestUnion }) {
  return API.client.subject.updateSubject(subjectId, '', '', formData, {
    ...endpointsConfig.updateSubject
  });
}

function useEditSubjectMutation(config?: MutationConfig<AxiosResponse<OperatorSubjectDetails>>) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(editSubjectClient, editSubject), config);
}

export default useEditSubjectMutation;
