import { createContext, useContext } from 'react';

import { CustomTheme } from './model';

const ThemeValuesContext = createContext<CustomTheme>(null);

export const useThemeValuesContext = () => useContext(ThemeValuesContext);

export const ThemeValuesProvider = ({ children, value }: { value: CustomTheme; children: React.ReactNode }) => {
  return <ThemeValuesContext.Provider value={value}>{children}</ThemeValuesContext.Provider>;
};
