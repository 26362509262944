import { makeStyles } from '@mui/styles';

import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

export default makeStyles<Theme, { isEmpty?: boolean; contrast?: boolean }>(theme => ({
  cell: {
    width: '100%',
    height: '100%',
    '& :hover': {
      backgroundColor: ({ contrast }) => !contrast && important(theme.palette.grey[100])
    }
  },
  header: {
    '& >label': {
      paddingRight: 10
    }
  },
  action: {
    position: 'absolute',
    display: ({ isEmpty }) => !isEmpty && 'none',
    left: '34px'
  },
  checkbox: {
    padding: 10
  },
  button: {
    padding: '6px 16px',
    height: '35px',
    minWidth: 'auto',
    '&:hover': {
      backgroundColor: important(theme.palette.grey[100]),
      border: `2px solid  ${theme.palette.primary[500]}`,
      color: ({ contrast }) => contrast && important(theme.palette.primary[950])
    },
    '&:focus': {
      border: `2px solid  ${theme.palette.primary[500]}`,
      backgroundColor: important('transparent'),
      color: ({ contrast }) => contrast && important(theme.palette.primary[950])
    }
  }
}));
