import { useCallback } from 'react';
import { NoteUpdateRequest } from '@ibtm/domain';

import { useCreateApplicationNoteMutation, useEditApplicationNoteMutation } from '@libs/domain/application';
import { useCreateFolderNoteMutation, useEditFolderNoteMutation } from '@libs/domain/folder';

interface IParams {
  folderId?: string;
  applicationId?: string;
  successCallback?: () => void;
}

function useNoteApiActions({ folderId, applicationId, successCallback }: IParams) {
  const { mutate: createFolderNote, isLoading: isCreateFolderNoteLoading } = useCreateFolderNoteMutation();
  const { mutate: createApplicationNote, isLoading: isCreateApplicationNoteLoading } =
    useCreateApplicationNoteMutation();
  const { mutate: editFolderNote, isLoading: isEditFolderNoteLoading } = useEditFolderNoteMutation();
  const { mutate: editApplicationNote, isLoading: isEditApplicationNoteLoading } = useEditApplicationNoteMutation();

  const createNewFolderNote = useCallback(
    (value: string) => {
      createFolderNote(
        { folderId, content: value },
        {
          onSuccess: () => successCallback()
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateFolderNote = useCallback(
    (values: NoteUpdateRequest) => {
      const updateData = {
        folderId,
        content: values.content,
        version: values.version
      };
      editFolderNote(updateData, {
        onSuccess: () => successCallback()
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const createNewApplicationNote = useCallback(
    (value: string) => {
      createApplicationNote(
        { applicationId, content: value },
        {
          onSuccess: () => successCallback()
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateApplicationNote = useCallback(
    (values: NoteUpdateRequest) => {
      const updateData = {
        applicationId,
        content: values.content,
        version: values.version
      };
      editApplicationNote(updateData, {
        onSuccess: () => successCallback()
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    createNewFolderNote,
    isCreateFolderNoteLoading,
    updateFolderNote,
    isEditFolderNoteLoading,
    createNewApplicationNote,
    isCreateApplicationNoteLoading,
    updateApplicationNote,
    isEditApplicationNoteLoading
  };
}

export default useNoteApiActions;
