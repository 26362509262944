enum UserUIElementEnum {
  ACCOUNT_SHOW_PERMISSIONS_BUTTON = 'ACCOUNT_SHOW_PERMISSIONS_BUTTON',
  ACCOUNT_LOGGED_IN_VIEW = 'ACCOUNT_LOGGED_IN_VIEW',

  USERS_ADD_BUTTON = 'USERS_ADD_BUTTON',
  USERS_EDIT_BUTTON = 'USERS_EDIT_BUTTON',
  USERS_DETAILS_BUTTON = 'USERS_DETAILS_BUTTON',
  USERS_DELETE_BUTTON = 'USERS_DELETE_BUTTON',
  USERS_UNLOCK_BUTTON = 'USERS_UNLOCK_BUTTON',
  USERS_LOCK_BUTTON = 'USERS_LOCK_BUTTON',
  USERS_RESTORE_BUTTON = 'USERS_RESTORE_BUTTON',
  USERS_REJECT_BUTTON = 'USERS_REJECT_BUTTON',
  USERS_VERIFY_BUTTON = 'USERS_VERIFY_BUTTON',
  USERS_RESET_PASSWORD_BUTTON = 'USERS_RESET_PASSWORD_BUTTON',
  USERS_ANONYMIZE_BUTTON = 'USERS_ANONYMIZE_BUTTON',
  USERS_RESUME_BUTTON = 'USERS_RESUME_BUTTON',
  USERS_SUSPEND_BUTTON = 'USERS_SUSPEND_BUTTON',
  USERS_LIST_TAB = 'USERS_LIST_TAB',
  USERS_CHANGE_PASSWORD_BUTTON = 'USERS_CHANGE_PASSWORD_BUTTON',
  USERS_GROUP_USERS_ADD_BUTTON = 'USERS_GROUP_USERS_ADD_BUTTON',
  USERS_GROUP_USERS_DELETE = 'USERS_GROUP_USERS_DELETE',
  USERS_GROUPS_UPDATE = 'USERS_GROUPS_UPDATE',
  USERS_SHOW_USERS_FLAG = 'USERS_SHOW_USERS_FLAG',

  CLIENT_USERS_LIST_TAB = 'CLIENT_USERS_LIST_TAB',
  CLIENT_USERS_DETAILS_BUTTON = 'CLIENT_USERS_DETAILS_BUTTON',
  CLIENT_USERS_ADD_BUTTON = 'CLIENT_USERS_ADD_BUTTON',
  CLIENT_USERS_EDIT_BUTTON = 'CLIENT_USERS_EDIT_BUTTON',
  CLIENT_USERS_CHANGE_PASSWORD_BUTTON = 'CLIENT_USERS_CHANGE_PASSWORD_BUTTON',
  CLIENT_USERS_USER_SUBJECT_ADD_BUTTON = 'CLIENT_USERS_USER_SUBJECT_ADD_BUTTON',
  CLIENT_USERS_USER_SUBJECT_DELETE_BUTTON = 'CLIENT_USERS_USER_SUBJECT_DELETE_BUTTON',
  CLIENT_USERS_UNLOCK_BUTTON = 'CLIENT_USERS_UNLOCK_BUTTON',
  CLIENT_USERS_LOCK_BUTTON = 'CLIENT_USERS_LOCK_BUTTON',
  CLIENT_USERS_RESEND_EMAIL_CONFIRMATION = 'CLIENT_USERS_RESEND_EMAIL_CONFIRMATION',
  CLIENT_USERS_SEND_EMAIL_TO_RESET_PASSWORD = 'CLIENT_USERS_SEND_EMAIL_TO_RESET_PASSWORD',

  PROFILE_VIEW_BUTTON = 'PROFILE_VIEW_BUTTON',
  PROFILE_PASSWORD_CHANGE_BUTTON = 'PROFILE_PASSWORD_CHANGE_BUTTON',
  PROFILE_GROUP_VIEW_TAB = 'PROFILE_GROUP_VIEW_TAB',
  PROFILE_REPLACEMENT_VIEW_TAB = 'PROFILE_REPLACEMENT_VIEW_TAB',
  PROFILE_REPLACEMENT_ADD_BUTTON = 'PROFILE_REPLACEMENT_ADD_BUTTON'
}

export default UserUIElementEnum;
