import { FolderDetailsExtended } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import { GridItem, GridLayout, InputMode, Section, TextInputField, typedNameV2 } from '@libs/common/v2';

function PrintingsSettingsAddressSection() {
  const getLabel = partialTranslate('folder:address');
  const getSectionTitle = partialTranslate('folder:details.section');

  return (
    <Section title={getSectionTitle('printingsSettingsAddress')} isCollapsable>
      <GridLayout itemProps={{ xs: 12, md: 6 }}>
        <GridItem xs={12}>
          <TextInputField
            name={typedNameV2<FolderDetailsExtended>('printingSettings.displayAddress')}
            label={getLabel('printAddress')}
            inputMode={InputMode.VIEW}
          />
        </GridItem>
        <TextInputField
          name={typedNameV2<FolderDetailsExtended>('printingSettings.ibtmAddress')}
          label={getLabel('ibtmAddress')}
          inputMode={InputMode.VIEW}
        />
        <TextInputField
          name={typedNameV2<FolderDetailsExtended>('printingSettings.transbitAddress')}
          label={getLabel('transbitAddress')}
          inputMode={InputMode.VIEW}
        />
      </GridLayout>
    </Section>
  );
}

export default PrintingsSettingsAddressSection;
