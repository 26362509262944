import { useQuery } from 'react-query';
import { FolderRentalDetails } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { ArchiveQueryKeysEnum } from '../../../common';
import { endpointsConfig } from '../endpoints-config';

const getBorrowFolderSingleData = async (_, folderNumber: string) => {
  const { data } = await API.borrowFolders.getFolderCurrentRental(folderNumber, {
    ...endpointsConfig.getFolderCurrentRental
  });

  return data;
};

const useBorrowFolderSingleQuery = (folderNumber: string, queryConfig = {}) => {
  return useQuery<FolderRentalDetails>(
    [ArchiveQueryKeysEnum.BORROW_FOLDER_DETAILS, folderNumber],
    getBorrowFolderSingleData,
    queryConfig
  );
};

export default useBorrowFolderSingleQuery;
