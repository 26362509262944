import { i18n, partialTranslate } from '@libs/common';
import { FormMode } from '@libs/common/v2';
import { getLastBreadcrumbText } from '@libs/common/v2/utils';

interface Params {
  formMode: FormMode;
  serviceNumber: string;
  email: string;
  login: string;
  modulePath: string;
  isLoading: boolean;
  isUsingServiceNumber: boolean;
  isClientPortal?: boolean;
}

export const getDetailsBreadcrumb = ({
  formMode,
  serviceNumber,
  email,
  login,
  modulePath,
  isLoading,
  isUsingServiceNumber,
  isClientPortal
}: Params) => {
  const getTranslate = partialTranslate('user', ':');
  const breadcrumbText = getLastBreadcrumbText(formMode, [
    'userDetailsBreadcrumbs',
    isClientPortal ? 'newClientPortalUser' : 'newUser',
    'editUserBreadcrumbs'
  ]);
  const translatedBreadcrumbText =
    breadcrumbText &&
    getTranslate(breadcrumbText, {
      serviceNumber: isUsingServiceNumber ? serviceNumber : email || login
    });

  const firstBreadcrumb = {
    text: isClientPortal ? i18n.t('user:usersListClientPortalViewTitle') : i18n.t('user:usersListViewTitle'),
    to: `/administration/${modulePath}`
  };
  const secondBreadcrumb = formMode === FormMode.CREATE ? { text: translatedBreadcrumbText, loading: false } : null;
  const thirdBreadcrumb = (formMode === FormMode.VIEW || formMode === FormMode.EDIT) && {
    text: translatedBreadcrumbText,
    loading: isLoading
  };

  return secondBreadcrumb ? [firstBreadcrumb, secondBreadcrumb] : [firstBreadcrumb, thirdBreadcrumb];
};
