import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { ChangeResourceObjectOrderStatusRequest } from '@ibtm/domain';

import { Dialog, TableButtonView, TableIconButton, useConfirmDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { DomainDictionaryEntry, DomainUIElementEnum } from '@libs/domain/config';

import { ResourceQueryKeysEnum, useAssociationOrderStatusChangeMutation } from '../../../api';
import { useResourceDialog } from '../../../hooks';
import { IAssociationOrdersClient } from '../../../model';
import { AssociationOrderReturnConfirmForm } from '../../forms';
import { AssociationOrderRangesTable } from '..';

interface IProps {
  data: IAssociationOrdersClient;
}

export default function AssociationOrdersTableRowActions({ data }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();

  const { showSnackbar } = useSnackbar();
  const { openDialog } = useResourceDialog();
  const [openConfirmDialog] = useConfirmDialog();
  const { mutate: changeOrderStatus } = useAssociationOrderStatusChangeMutation();

  const handleConfirm = () => {
    if (data?.direction === DomainDictionaryEntry.RESOURCE_FORM_ORDER_DIRECTION.RETURNS) {
      openDialog(AssociationOrderReturnConfirmForm, {
        orderId: data.id
      });
    } else {
      openConfirmDialog({
        title: t('resource:dialog.associations.confirmTitle'),
        message: t('resource:dialog.associations.confirmMessage'),
        onConfirm: (setConfirmLoading, closeDialog) => {
          setConfirmLoading(true);

          const requestData: ChangeResourceObjectOrderStatusRequest = {
            orderId: data.id,
            operationKey: DomainDictionaryEntry.RESOURCE_OBJECT_ORDER_OPERATION.CONFIRM
          };

          changeOrderStatus(requestData, {
            onSuccess: () => {
              queryCache.invalidateQueries(ResourceQueryKeysEnum.ASSOCIATION_ORDERS_LIST);
              showSnackbar('success', t('resource:messages.associationOrderConfirmSuccess'));
            },
            onSettled: () => {
              setConfirmLoading(false);
              closeDialog();
            }
          });
        }
      });
    }
  };

  const handleReject = () => {
    openConfirmDialog({
      title: t('resource:dialog.associations.rejectTitle'),
      message: t('resource:dialog.associations.rejectMessage'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        const requestData: ChangeResourceObjectOrderStatusRequest = {
          orderId: data.id,
          operationKey: DomainDictionaryEntry.RESOURCE_OBJECT_ORDER_OPERATION.REJECT
        };

        changeOrderStatus(requestData, {
          onSuccess: () => {
            queryCache.invalidateQueries(ResourceQueryKeysEnum.ASSOCIATION_ORDERS_LIST);
            showSnackbar('success', t('resource:messages.associationOrderRejectSuccess'));
          },
          onSettled: () => {
            setConfirmLoading(false);
            closeDialog();
          }
        });
      },
      confirmText: t('action.reject'),
      confirmType: 'danger'
    });
  };

  const handleAssociationOrderDetails = () => {
    openDialog(
      ({ closeDialog }) => (
        <Dialog
          title={t('resource:dialog.associationOrderRanges')}
          cancelText={t('action.close')}
          onCancel={closeDialog}
          dialogSize="large"
          isOpen
        >
          <AssociationOrderRangesTable data={data.ranges} />
        </Dialog>
      ),
      {}
    );
  };

  return (
    !!data.ranges?.length && (
      <>
        {data?.status === DomainDictionaryEntry.RESOURCE_FORM_ORDER_STATUS.PENDING && (
          <>
            <TableIconButton
              icon="ApproveIcon"
              tooltipTitle={t('resource:dialog.associations.tooltipConfirm')}
              onClick={handleConfirm}
              actionKey={DomainUIElementEnum.RESOURCES_OBJECT_CONFIRM}
            />

            <TableIconButton
              icon="RejectIcon"
              tooltipTitle={t('resource:dialog.associations.tooltipReject')}
              onClick={handleReject}
              actionKey={DomainUIElementEnum.RESOURCES_OBJECT_CONFIRM}
            />
          </>
        )}

        <TableButtonView tooltipTitle={t('action.openDetails')} onClick={handleAssociationOrderDetails} />
      </>
    )
  );
}
