import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { makeStyles } from '@mui/styles';

import { TableLayout, useConfirmDialog, useTableRowActions } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { calc, important } from '@libs/common/v2/utils';

import { API } from '@libs/domain/api';
import { DomainUIElementEnum } from '@libs/domain/config';
import { IResourceTypesClient } from '@libs/domain/resource';

import { ResourceQueryKeysEnum, useDeleteResourceTypeMutation } from '../../../api';
import { useResourceFormTypesTable } from '../../../hooks';
import { parseResourceTypesList } from '../../../utils';
import { useResourceTypeEditDialog } from '../../forms/resource-type-dialog/useResourceTypeEditDialog';

export function ResourceFormTypesTable() {
  const [t] = useTranslation();
  const queryCache = useQueryCache();
  const { showSuccessSnackbar } = useSnackbar();
  const tableProps = useResourceFormTypesTable();
  const classes = useStyles();
  const [openConfirmDialog] = useConfirmDialog();

  const { openResourceTypeEditDialog } = useResourceTypeEditDialog();
  const { mutate: deleteFormTypes } = useDeleteResourceTypeMutation();

  const handleDeleteResourceType = (id: string) => {
    openConfirmDialog({
      title: t('resource:dialog.deleteFormTypeTitle'),
      message: t('resource:messages.resourceTypeDeleteConfirmationMessage'),
      confirmType: 'danger',
      confirmText: t('action.delete'),
      onConfirm: (setLoading, closeDialog) => {
        setLoading(true);
        deleteFormTypes(id, {
          onSuccess: () => {
            queryCache.invalidateQueries(ResourceQueryKeysEnum.RESOURCE_TYPES_LIST);
            showSuccessSnackbar(t('resource:messages.deleteFormTypesSuccess'));
            closeDialog();
          },
          onSettled: () => {
            setLoading(false);
          }
        });
      }
    });
  };

  const { renderTableActions } = useTableRowActions<IResourceTypesClient>([
    {
      id: DomainUIElementEnum.RESOURCES_FORM_TYPES_EDIT_BUTTON,
      label: 'action.edit',
      onClick: ({ row: { original } }) => openResourceTypeEditDialog({ id: original.id }),
      icon: 'EditIcon'
    },
    {
      id: DomainUIElementEnum.RESOURCES_FORM_TYPES_DELETE_BUTTON,
      onClick: ({ row: { original } }) => handleDeleteResourceType(original.id),
      icon: 'TrashIcon',
      label: 'action.delete'
    }
  ]);

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('resource:list.resourceTypeConfigurationList')}
      xlsxDownload={{
        fileName: t('resource:list.resourceTypeConfigurationList'),
        pathToXLSXTranslation: 'resource:fields',
        apiRequest: params =>
          API.resourceTypes
            .getResourceTypes(params)
            .then(res => ({ ...res, data: { ...res.data, content: parseResourceTypesList(res.data.content) } }))
      }}
      rowActions={renderTableActions([
        DomainUIElementEnum.RESOURCES_FORM_TYPES_EDIT_BUTTON,
        DomainUIElementEnum.RESOURCES_FORM_TYPES_DELETE_BUTTON
      ])}
      sectionContentClassName={classes.sectionContent}
      isSection
    />
  );
}

const useStyles = makeStyles({
  sectionContent: {
    height: important(calc('100% - 100px'))
  }
});
