import { useTranslation } from 'react-i18next';
import { Column, OnFilterChange } from 'react-table';

import { FetchSelectFilter, NUMBER_OF_AUTOCOMPLETE_RESULTS } from '@libs/common/v2';

import { API, UserQueryKeysEnum } from '@libs/user/api';

import { UserStatusKeysEnum } from '../../models';

interface IProps {
  column?: Column & { filterValue?: string; setFilter: (value: string | Array<string>) => void };
  onFilterChange?: OnFilterChange;
}

function UserFilter({ column, onFilterChange, ...forwardProps }: IProps) {
  const [t] = useTranslation();

  const fetchFunction = params => {
    return API.user.accountController.searchLiteAccounts({
      ...params,
      page: 0,
      size: NUMBER_OF_AUTOCOMPLETE_RESULTS,
      query: params,
      sort: [],
      statuses: Object.values(UserStatusKeysEnum).filter(value => value !== UserStatusKeysEnum.SUSPENDED)
    });
  };

  const dataParser = data => {
    const text = t('foundItems', { count: data.data.content.length, totalElements: data.data.totalElements });

    return [
      ...data.data.content,
      {
        name: text,
        id: text,
        infoText: text,
        isDisabled: true
      }
    ].map((row: { id?: string; name: string; surname: string; isDisabled?: boolean; infoText: string }) => ({
      name: row?.infoText || `${row?.name} ${row?.surname}`,
      value: row?.id,
      id: row?.id,
      isDisabled: row?.isDisabled
    }));
  };

  return (
    <FetchSelectFilter
      queryKey={UserQueryKeysEnum.USERS_LIST_FILTER}
      fetchFunctionResolver={fetchFunction}
      column={column}
      fetchedDataSelectParser={dataParser}
      getOptionSelected={(option, value) => option.name === value.name}
      shouldCheckVisibility
      onFilterChange={onFilterChange}
      {...forwardProps}
    />
  );
}

export default UserFilter;
