import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { omit } from 'lodash';

import { partialTranslate } from '@libs/common';
import { TableLayout } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { useDomainConfigContext } from '../../../../config';
import { FolderDetailsParams } from '../../../../folder';
import useLimitTransferTable from '../../../../social-commission/hooks/tables/useLimitTransferTable';

const translate = partialTranslate('folder:details.tab.singleAuthorization.section.limitTransfer');

interface IProps {
  titleHeaderContent?: React.ReactNode;
}

function LimitTransferTable({ titleHeaderContent }: IProps) {
  const { folderId } = useParams<FolderDetailsParams>();
  const { isClientPortal } = useDomainConfigContext();

  const tableProps = useLimitTransferTable(folderId);

  const apiRequest = useCallback(
    params => {
      const queryOperatorParams = omit(params, ['folderId']);
      return isClientPortal
        ? API.client.folderLimits.getFolderLimitsTransferDetailsPage('', '', params)
        : API.folderLimits.getFolderLimitsTransferDetailsPage(folderId, queryOperatorParams);
    },
    [folderId, isClientPortal]
  );

  return (
    <TableLayout
      {...tableProps}
      xlsxDownload={{
        fileName: translate('title'),
        pathToXLSXTranslation: 'folder:details.tab.singleAuthorization.section.limitTransfer.field',
        apiRequest: params => apiRequest(params)
      }}
      sectionProps={{ titleHeaderContent }}
      isCollapsable
      isSection
    />
  );
}

export default LimitTransferTable;
