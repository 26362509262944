import {
  QuickChangeableDictionarySelectField as QuickChangeableDictionarySelectFieldCommon,
  useFormV2Context
} from '@libs/common/v2';

import { useMetaFormContext } from '@libs/meta-form/context';
import { FieldProps, FieldTypes, QuickChangeableDictionaryFieldProperties } from '@libs/meta-form/models';
import { useGetQuery, useMapAdditionalFieldsValidationType } from '@libs/meta-form/services';

function QuickChangeableDictionarySelectField({
  fieldId,
  label,
  dictionaryName,
  api,
  multiple,
  inputMode,
  defaultInitialValue,
  optionsFilter,
  disabled,
  yupValidation
}: FieldProps<QuickChangeableDictionaryFieldProperties>) {
  const { setValue } = useFormV2Context();
  const { apiRegistry, additionalSelectOptionsFilters } = useMetaFormContext();
  useMapAdditionalFieldsValidationType(fieldId, { type: FieldTypes.DICTIONARY_QUICK_CHANGEABLE });

  useGetQuery({
    requestKey: api.get.requestKey,
    accessor: api.get.accessor,
    apiRegistry,
    params: api?.get?.backendParams,
    setValue: (newValue: string | string[]) => {
      if (!newValue && !defaultInitialValue) {
        return setValue(fieldId, undefined);
      }
      return setValue(
        fieldId,
        multiple && Array.isArray(newValue)
          ? newValue?.map((value: string) => ({ value })) ??
              (Array.isArray(defaultInitialValue) && defaultInitialValue?.map((value: string) => ({ value })))
          : { value: newValue ?? defaultInitialValue }
      );
    },
    fieldId
  });

  return (
    <QuickChangeableDictionarySelectFieldCommon
      name={fieldId}
      dictionaryName={dictionaryName}
      label={label}
      optionsFilter={additionalSelectOptionsFilters?.[optionsFilter]}
      isMultiple={multiple}
      inputMode={inputMode}
      isDisabled={disabled}
      isRequired={yupValidation?.required}
    />
  );
}

export default QuickChangeableDictionarySelectField;
