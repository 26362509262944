import { ITableLayoutProps, useCustomTableQuery, useQueryTable } from '@libs/common/v2';

import TableWithoutHook from './TableWithoutHook';

function QueryTable<T extends Record<string, any>, K extends Record<string, any>>({
  tableQuery,
  tableHookOptions: { tablePlugins = [], filterConverter, sortByConverter, ...restTableHookOptions },
  tableHookQueryConfig,
  tableHookQueryInitialParams = {},
  tableHookQueryKey,
  xlsxDownload,
  rowActions,
  isActionColumnEnabled = rowActions && true,
  isTableMutable,
  isFetching,
  refetch,
  isLoading: tableLoading,
  ...forwardTableProps
}: Omit<ITableLayoutProps<T, K>, 'tableHookQuery'>) {
  const {
    table,
    tableQuery: queryResult,
    additionalData: { isLoading, newRowScheme },
    setTableQueryParams,
    tableQueryParams
  } = useCustomTableQuery({
    query: tableQuery,
    tableHookOptions: { tablePlugins, filterConverter, sortByConverter, ...restTableHookOptions },
    dataHookQueryConfig: tableHookQueryConfig,
    isActionColumnEnabled,
    isTableMutable,
    tableQuery: useQueryTable,
    tableHookQueryInitialParams,
    tableHookQueryKey
  });

  return (
    <TableWithoutHook
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      table={table as any}
      tableHookQueryInitialParams={tableHookQueryInitialParams}
      isLoading={(isLoading || !!tableLoading) as boolean}
      newRowScheme={newRowScheme as T}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      queryResult={{
        ...(queryResult as any),
        isFetching: isFetching || queryResult.isFetching,
        isLoading: (isLoading || !!tableLoading) as boolean,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        refetch: (typeof tableQuery === 'function' ? queryResult.refetch : refetch) as any
      }}
      xlsxDownload={!!xlsxDownload && { ...xlsxDownload, filterConverter, sortByConverter }}
      isTableMutable={isTableMutable}
      setTableQueryParams={setTableQueryParams}
      tableQueryParams={tableQueryParams}
      rowActions={rowActions}
      {...forwardTableProps}
    />
  );
}

export default QueryTable;
