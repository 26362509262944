import { usePaginatedQuery } from 'react-query';
import { DefaultApiFindMyReplacementsRequest } from '@avispon/group';
import { ReplacementDetailsExternal } from '@avispon/group/dist/models';

import { IPaginatedModel } from '@libs/common/v2';

import { API, endpointsConfig, UserQueryKeysEnum } from '@libs/user';
import { ReplacementsSnapshot } from '@libs/user/models/replacements.model';

const parseUserReplacement = (userReplacement: ReplacementDetailsExternal): ReplacementsSnapshot => {
  return userReplacement;
};

export const parseUserReplacementsData = (data: ReplacementDetailsExternal[]): ReplacementsSnapshot[] => {
  return data.map(obj => parseUserReplacement(obj));
};

const getReplacements = async (_, params: DefaultApiFindMyReplacementsRequest) => {
  const { data } = await API.group.findMyReplacements(params, { ...endpointsConfig.findMyReplacements });
  return { ...data, content: parseUserReplacementsData(data.content) };
};

export default function useReplacementsQuery(params: any) {
  return usePaginatedQuery<IPaginatedModel<ReplacementsSnapshot>>(
    [UserQueryKeysEnum.REPLACEMENTS, params],
    getReplacements
  );
}
