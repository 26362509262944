import { MutationConfig } from 'react-query';

import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface IDeleteParams {
  exploitationBaseId: string;
}

const deleteExploitationBaseClient = async ({ exploitationBaseId }: IDeleteParams) => {
  const { data } = await API.client.exploitationBase.deleteExploitationBase(exploitationBaseId, '', '', {
    ...endpointsConfig.deleteExploitationBase
  });
  return data;
};

const deleteExploitationBase = async ({ exploitationBaseId }: IDeleteParams) => {
  const { data } = await API.exploitationBase.deleteExploitationBase(exploitationBaseId, {
    ...endpointsConfig.deleteExploitationBase
  });
  return data;
};

function useExploitationBaseDeleteMutation(config?: MutationConfig<void, unknown, unknown>) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(deleteExploitationBaseClient, deleteExploitationBase), config);
}

export default useExploitationBaseDeleteMutation;
