import { SCertificatesList } from './list';

interface IProps {
  areHeaderActionsHidden: boolean;
}

function SCertificatesTab({ areHeaderActionsHidden }: IProps) {
  return <SCertificatesList areHeaderActionsHidden={areHeaderActionsHidden} />;
}

export default SCertificatesTab;
