import { createContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LoaderCircular, Typography } from '@libs/common/v2';

import { useApplicationTransitScheduleQuery } from '../api';
import { TransitDatesTable, TransitScheduleMatrix, TransitScheduleTable } from '../components';
import { ScheduleApplicationGroupsEnum } from '../model';

export const TransitScheduleContext = createContext({
  applicationId: '',
  applicationTypeKey: '',
  transitScheduleId: '',
  transitScheduleVersion: null as number,
  fieldId: null
});

export interface ITransitScheduleSections {
  isTransitScheduleVisible?: boolean;
  isTransitFrequencyVisible?: boolean;
  isTransitCalendarVisible?: boolean;
}

interface IProps {
  applicationData: { id: string; typeKey: string; categoryKey: string };
  fieldId: string;
  visibleSections?: ITransitScheduleSections;
}

function TransitScheduleTab({
  applicationData,
  fieldId,
  visibleSections = { isTransitCalendarVisible: true, isTransitFrequencyVisible: true, isTransitScheduleVisible: true }
}: IProps) {
  const { id, typeKey } = applicationData;
  const [t] = useTranslation();
  const { isTransitCalendarVisible, isTransitFrequencyVisible, isTransitScheduleVisible } = visibleSections;
  const { data, isLoading, isError } = useApplicationTransitScheduleQuery(id);

  const values = useMemo(
    () => ({
      applicationId: id,
      fieldId,
      applicationTypeKey: typeKey,
      transitScheduleId: data?.id,
      transitScheduleVersion: data?.version
    }),
    [data?.id, data?.version, fieldId, id, typeKey]
  );

  if (isError) {
    return (
      <Typography align="center" themeVariant="displayXs.normal">
        {t('error.noDataToShow')}
      </Typography>
    );
  }

  if (isLoading) {
    return <LoaderCircular isLoading />;
  }

  return (
    <TransitScheduleContext.Provider value={values}>
      {isTransitScheduleVisible && <TransitScheduleTable />}
      {isTransitFrequencyVisible &&
        (typeKey.includes(ScheduleApplicationGroupsEnum.SPO_OPINION) ||
          typeKey.includes(ScheduleApplicationGroupsEnum.SPO_UE) ||
          typeKey.includes(ScheduleApplicationGroupsEnum.SPO_OUTSIDE_UE) ||
          typeKey.includes(ScheduleApplicationGroupsEnum.SPO_TRANSIT_RP)) && (
          <TransitScheduleMatrix transitDataId={data.id} />
        )}
      {((isTransitCalendarVisible && typeKey.includes(ScheduleApplicationGroupsEnum.SPO_WZO)) ||
        typeKey.includes(ScheduleApplicationGroupsEnum.SPO_WZW)) && (
        <TransitDatesTable transitDataId={data.id} applicationData={applicationData} />
      )}
    </TransitScheduleContext.Provider>
  );
}

export default TransitScheduleTab;
