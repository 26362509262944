import { useMutation } from 'react-query';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export function deleteTransitDates({ transitScheduleId, datesIds }: { transitScheduleId: string; datesIds: string[] }) {
  return API.transitSchedule.deleteTransitDates(
    transitScheduleId,
    { datesIds },
    {
      ...endpointsConfig.deleteTransitDates
    }
  );
}

function useDeleteTransitDatesMutation() {
  return useMutation(deleteTransitDates);
}

export default useDeleteTransitDatesMutation;
