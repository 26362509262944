import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { Dialog, useDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { TransportManagerQueryKeysEnum, useCopyTransportManagerMutation } from '@libs/domain/transport-manager';

function useConfirmAddTransportManagerDialog() {
  const queryCache = useQueryCache();
  const { openDialog } = useDialog();

  const [t] = useTranslation();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { mutate: copyFinancialSecurity } = useCopyTransportManagerMutation();
  const openConfirmAddTransportManagerDialog = (transportManagerIds: string[], applicationId: string) =>
    openDialog(({ closeDialog }) => (
      <Dialog
        title={t('transportManager:details.title.create')}
        confirmText={t('action.add')}
        cancelText={t('action.cancel')}
        contentText={
          transportManagerIds.length > 1
            ? t('transportManager:add.dialogContentPlural')
            : t('transportManager:add.dialogContent')
        }
        onConfirm={setIsLoading => {
          setIsLoading(true);
          copyFinancialSecurity(
            {
              applicationId,
              transportManagerCopyRequest: {
                transportManagerIds
              }
            },
            {
              onSuccess: () => {
                showSuccessSnackbar(
                  transportManagerIds.length > 1
                    ? t('transportManager:add.response.successMessagePlural')
                    : t('transportManager:add.response.successMessage')
                );
                queryCache.invalidateQueries([TransportManagerQueryKeysEnum.TRANSPORT_MANAGER_LIST]);
              },
              onError: () => {
                showErrorSnackbar(
                  transportManagerIds.length > 1
                    ? t('transportManager:add.response.failureMessagePlural')
                    : t('transportManager:add.response.failureMessage')
                );
              },
              onSettled: () => {
                closeDialog();
                setIsLoading(false);
              }
            }
          );
        }}
        onCancel={closeDialog}
        isOpen
      />
    ));

  return { openConfirmAddTransportManagerDialog };
}

export default useConfirmAddTransportManagerDialog;
