import React, { useContext, useMemo } from 'react';
import { Skeleton } from '@mui/material';
import { DataSourceMediatorColumn } from '@stack/report/dist/models';
import _ from 'lodash';

import { FormV2Context, useFormV2Context, useFormV2Watch, useViewModesV2 } from '@libs/common/v2';

import { DataSetContext } from '@libs/report/context/DataSetContext';
import useReportDataSourceColumns, { DataSource } from '@libs/report-core/hooks/useReportDataSourceColumns';

import { ReportTypeConditionsContent } from './ReportTypeConditionsContent';

function ReportConditionsSectionContainer() {
  const { loading } = useContext(FormV2Context);

  const { getValues, formMode } = useFormV2Context();
  const { createMode } = useViewModesV2(formMode);

  const { dataSetIndex } = useContext(DataSetContext);
  const source = _.get(getValues(), `dataSetDefinitions[${dataSetIndex}].source`) as DataSource;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const values = useMemo(() => getValues(), []);

  const sourceColumns: DataSourceMediatorColumn[] = useReportDataSourceColumns(source);
  const selectedColumns = useFormV2Watch({ name: `dataSetDefinitions[${dataSetIndex}].queryDefinition.columns` });

  const columns = useMemo(
    () =>
      sourceColumns.filter(value => value).filter(({ name }) => selectedColumns?.some(column => column?.name === name)),
    [sourceColumns, selectedColumns]
  );
  const selectedDataSourceId = source?.id || '';

  if (loading) {
    return (
      <div className="p-16">
        <Skeleton variant="text" height={36} width={68} />
        <Skeleton variant="text" height={60} />
      </div>
    );
  }

  return (
    <ReportTypeConditionsContent
      name={`dataSetDefinitions.${dataSetIndex}.queryConditions.${0}`}
      queryName={`dataSetDefinitions[${dataSetIndex}].query`}
      dataSetIndex={dataSetIndex}
      columns={columns}
      dataSourceId={selectedDataSourceId}
      initialValues={
        selectedDataSourceId === values?.dataSetDefinitions?.[dataSetIndex]?.source?.id &&
        !createMode &&
        !_.get(getValues(), `sourceModified[${dataSetIndex}]`)
          ? values?.dataSetDefinitions?.[dataSetIndex]
          : null
      }
      parameterizable
    />
  );
}

export default React.memo(ReportConditionsSectionContainer);
