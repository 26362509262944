import { PluginHook } from 'react-table';

import { CamelCasePath, useTableLayoutProps } from '@libs/common/v2';

import { useDriverCertificatesQuery } from '../../api/queries';
import { DriverCertificateSnapshotClient } from '../../model';
import { useDriverCertificatesColumns } from '..';

const useDriverCertificatesTable = (
  visibleColumns: Array<CamelCasePath<DriverCertificateSnapshotClient>>,
  applicationId?: string,
  folderId?: string,
  defaultFilters = [],
  tablePlugins?: Array<PluginHook<DriverCertificateSnapshotClient>>,
  includePartnershipPermissions?: boolean,
  isTableDataVisible = true
) => {
  const { columns, mappedFilterFields, mappedSortFields } = useDriverCertificatesColumns(visibleColumns);

  return useTableLayoutProps({
    tableHookQuery: isTableDataVisible && useDriverCertificatesQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tablePlugins,
      tableOptions: {
        initialState: {
          filters: defaultFilters
        }
      }
    },
    tableHookQueryInitialParams: {
      applicationIdIn: applicationId ? [applicationId] : undefined,
      folderIdIn: folderId ? [folderId] : undefined,
      includePartnershipPermissions: includePartnershipPermissions ? true : undefined
    }
  });
};

export default useDriverCertificatesTable;
