import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogContent } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import * as Yup from 'yup';

import { i18n } from '@libs/common';
import {
  Dialog,
  FormMode,
  FormV2Context,
  GridLayout,
  TextInputField,
  typedNameV2,
  useIsSmallScreen
} from '@libs/common/v2';

import { ChangePasswordValues, ChangeProfilePasswordValidation } from '@libs/user/models';

export const VALIDATION_SCHEMA = Yup.object({
  oldPassword: Yup.string().required(),
  newPassword1: Yup.string().min(14).required(),
  newPassword2: Yup.string()
    .required()
    .oneOf([Yup.ref('newPassword1'), null], i18n.t('validation:passwordMatch'))
});

export const INITIAL_VALUES: ChangePasswordValues = {
  oldPassword: '',
  newPassword1: '',
  newPassword2: ''
};

interface IProps {
  open: boolean;
  onCancel: () => any;
  onConfirm: (data: any) => any;
  changePasswordSchema?: ChangeProfilePasswordValidation;
}

function ChangePasswordDialog({ open, onCancel, onConfirm, changePasswordSchema }: IProps & Partial<DialogProps>) {
  const [t] = useTranslation();
  const { isSmallScreen } = useIsSmallScreen();

  const form = useForm<Record<string, any>>({
    defaultValues: INITIAL_VALUES,
    resolver: yupResolver(changePasswordSchema ?? VALIDATION_SCHEMA),
    mode: 'all'
  });

  const formDataProvider = useMemo(() => {
    return { ...form, formMode: FormMode.EDIT };
  }, [form]);

  const onSubmit = formValues => {
    onConfirm(formValues);
  };

  return (
    <Dialog
      title={t('user:dialog.changePassword.title')}
      confirmText={t('action.confirm')}
      cancelText={t('action.cancel')}
      onConfirm={form.handleSubmit(onSubmit)}
      onCancel={onCancel}
      isFullScreen={isSmallScreen}
      isOpen={open}
    >
      <FormV2Context.Provider value={formDataProvider}>
        <DialogContent className="overflow-visible">
          <GridLayout itemProps={{ xs: 12 }}>
            <TextInputField
              name={typedNameV2<ChangePasswordValues>('oldPassword')}
              label={t('user:field.oldPassword')}
              inputProps={{ type: 'password' }}
              isRequired
            />
            <TextInputField
              name={typedNameV2<ChangePasswordValues>('newPassword1')}
              label={t('user:field.newPassword1')}
              inputProps={{ type: 'password' }}
              isRequired
            />
            <TextInputField
              name={typedNameV2<ChangePasswordValues>('newPassword2')}
              label={t('user:field.newPassword2')}
              inputProps={{ type: 'password' }}
              isRequired
            />
          </GridLayout>
        </DialogContent>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default ChangePasswordDialog;
