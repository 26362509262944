import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { TableLayout } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { FolderDetailsParams } from '@libs/domain/folder';
import { useSuspensionsSummaryTable } from '@libs/domain/suspensions/hooks/useSuspensionsSummaryTable';

export function SuspensionSummaryTable() {
  const { folderId } = useParams<FolderDetailsParams>();
  const [t] = useTranslation();

  const tableProps = useSuspensionsSummaryTable({ folderId });

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('suspensions:summaryTable.title')}
      xlsxDownload={{
        fileName: t('suspensions:summaryTable.title'),
        pathToXLSXTranslation: 'suspensions:summaryTable.columns',
        apiRequest: params => API.suspensions.getSuspensionFolderSummaryPage(params)
      }}
      isCollapsable
      isSection
    />
  );
}
