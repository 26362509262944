import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { TableHeaderButton, useTableContext } from '@libs/common/v2';

import { DomainUIElementEnum } from '@libs/domain/config';

import { BorrowFolderDialogTypeEnum } from '../model';

import { useBorrowFoldersContext } from './common/BorrowFolderProvider';

function BorrowFolderHeaderActions({
  headerBorrowButtonTooltip = undefined,
  isHeaderReturnButtonVisible = true,
  isHeaderBorrowButtonDisabled = false
}: {
  headerBorrowButtonTooltip?: string;
  isHeaderReturnButtonVisible?: boolean;
  isHeaderBorrowButtonDisabled?: boolean;
}) {
  const [t] = useTranslation();
  const { onOpen, row } = useBorrowFoldersContext();
  const { refetch } = useTableContext();
  const classes = useStyles();

  const handleBorrowFolderModalOpen = useCallback(() => {
    onOpen(BorrowFolderDialogTypeEnum.BORROW_FOLDER, null, refetch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen]);

  const handleReturnFolderModalOpen = useCallback(() => {
    onOpen(BorrowFolderDialogTypeEnum.RETURN_FOLDER, row, refetch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen]);

  return (
    <>
      {isHeaderReturnButtonVisible && (
        <TableHeaderButton
          label={t('archive:borrowFolder.action.returnFolder')}
          onClick={handleReturnFolderModalOpen}
          isSecondary
          className={classes.button}
          actionKey={DomainUIElementEnum.ARCHIVE_BORROWED_RETURN_BUTTON}
          variant="outlined"
        />
      )}
      <TableHeaderButton
        label={t('archive:borrowFolder.action.borrowFolder')}
        onClick={handleBorrowFolderModalOpen}
        actionKey={DomainUIElementEnum.ARCHIVE_BORROWED_BORROW_FOLDER_BUTTON}
        className={classes.button}
        variant="contained"
        tooltipTitle={headerBorrowButtonTooltip}
        isDisabled={isHeaderBorrowButtonDisabled}
        isPrimary
      />
    </>
  );
}
const useStyles = makeStyles({
  button: {
    whiteSpace: 'nowrap'
  }
});

export default BorrowFolderHeaderActions;
