import { useApplicationTableColumns } from '@libs/domain/application';

const useApplicationOperatorPortalVisibleColumns = () => {
  const columns = useApplicationTableColumns();
  return [
    columns.number,
    columns.paperNumber,
    columns.categoryKey,
    columns.typeKey,
    columns.folderNumber,
    columns.statusKey,
    columns.submissionReceiptDate,
    columns.applicationChannelKey,
    columns.created,
    columns.authorName,
    columns.subjectName,
    columns.subjectNip,
    columns.modified,
    columns.modifierName,
    columns.editorName,
    columns.expectedCompletionDate
  ];
};
export const useApplicationClientPortalVisibleColumns = () => {
  const columns = useApplicationTableColumns();
  return [
    columns.number,
    columns.paperNumber,
    columns.categoryKey,
    columns.typeKey,
    columns.folderNumber,
    columns.statusKey,
    columns.parcelNumbers,
    columns.submissionReceiptDate,
    columns.applicationChannelKey,
    columns.created,
    columns.subjectName,
    columns.subjectNip,
    columns.expectedCompletionDate
  ];
};

export default useApplicationOperatorPortalVisibleColumns;
