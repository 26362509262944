import { QueryConfig, useQuery } from 'react-query';
import { VehicleDetails as VehicleDetailsClient } from '@ibtm/client-domain';
import { VehicleDetails } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { VehicleQueryKeysEnum } from '@libs/domain/vehicle';

import { endpointsConfig } from '../endpoints-config';

const getVehicle = async (_: any, id: string) => {
  const { data } = await API.vehicle.getVehicleDetails(id, {
    ...endpointsConfig.getVehicleDetails
  });
  return data;
};

const getVehicleClient = async (_: any, id: string) => {
  const { data } = await API.client.vehicle.getVehicleDetails('', '', id, {
    ...endpointsConfig.getVehicleDetails
  });
  return data;
};

export default function useVehicleDetailsQuery(
  id: string,
  queryConfig: QueryConfig<VehicleDetails | VehicleDetailsClient, unknown> = {}
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery<VehicleDetails | VehicleDetailsClient>(
    [VehicleQueryKeysEnum.VEHICLE, id],
    getQuery(getVehicleClient, getVehicle),
    queryConfig
  );
}
