import { SubjectAddressUpdateRequestWithId } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function updateAddresses({
  subjectId,
  addresses
}: {
  subjectId: string;
  addresses: Array<SubjectAddressUpdateRequestWithId>;
}) {
  return API.address.updateAddresses(
    { subjectId, subjectAddressUpdateRequests: addresses },
    {
      ...endpointsConfig.updateAddresses
    }
  );
}

function useUpdateAddressesMutation() {
  return useMutation(updateAddresses);
}

export default useUpdateAddressesMutation;
