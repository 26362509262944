import { QueryConfig, useQuery } from 'react-query';
import { InvoiceDetails } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export const getReleaseDocumentsDetails = async (_, id: string) => {
  const data = await API.releaseDocuments.getInvoice(id, {
    ...endpointsConfig.getInvoice
  });
  return data.data;
};

export default function useReleaseDocumentsDetailsQuery(
  id: string,
  queryConfig: QueryConfig<any, unknown> = {},
  queryKey = 'RELEASE_DOCUMENTS_DETAILS'
) {
  return useQuery<InvoiceDetails>([queryKey, id], getReleaseDocumentsDetails, queryConfig);
}
