import { DefaultApi } from '@avispon/dictionary';
import axios from 'axios';

import { forceArray } from '@libs/common/v2/utils';

interface DictionaryApi {
  dictionary: DefaultApi;
  dictionaryCategories: string[];
}

const API: DictionaryApi = {
  dictionaryCategories: [],
  dictionary: (() => {
    throw new Error('Dictionary api has not been set');
  }) as unknown as DefaultApi
};

export function setDictionaryApi(API_PORTAL_URL: string, dictionaryCategories: string[] | string) {
  API.dictionaryCategories = forceArray(dictionaryCategories);
  API.dictionary = new DefaultApi({ isJsonMime: () => true }, API_PORTAL_URL, axios);
}

export default API;
