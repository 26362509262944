import { QueryConfig, useQuery } from 'react-query';
import { RenounceCopySnapshot } from '@ibtm/domain';

import { API } from '@libs/domain/api';
import { ApplicationQueryKeysEnum } from '@libs/domain/application';

export const getRenounceCopy = async (applicationId: string) => {
  const { data } = await API.renounceCopies.getRenounceCopy(applicationId);
  return data;
};

export default function useRenounceCopiesyQuery(
  applicationtId: string,
  queryConfig: QueryConfig<RenounceCopySnapshot, unknown> = {}
) {
  return useQuery<RenounceCopySnapshot>(
    [ApplicationQueryKeysEnum.RENOUNCE_COPY, applicationtId],
    () => getRenounceCopy(applicationtId),
    queryConfig
  );
}
