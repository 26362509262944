import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CivicBoardRuleCreateRequest,
  CivicBoardRuleDetails,
  CivicBoardRuleItemConfig,
  CivicBoardRuleUpdateRequest,
  RuleItemCodes
} from '@ibtm/domain';
import { isNull, isNumber, isString, isUndefined } from 'lodash';
import moment from 'moment';
import { object as YupObject } from 'yup';

import { partialTranslate } from '@libs/common';
import {
  AutocompleteSelectField,
  Button,
  ButtonsGroup,
  DatepickerField,
  Dialog,
  DictionarySelectField,
  FormMode,
  FormV2Context,
  GridLayout,
  InputMode,
  Section,
  SwitchField,
  typedNameV2,
  useDialog,
  useFormV2Context
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { WarningInformation } from '@libs/common/v2/components/warning';
import { useViewModesV2 } from '@libs/common/v2/form';
import { convertDateToDateTimeFormat } from '@libs/common/v2/utils';

import { DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';
import { TUpdateTradeRulesRequest, useEditTradeRulesMutation } from '@libs/domain/social-commission/api';
import { SocialCommissionQueryKeysEnum } from '@libs/domain/social-commission/config';

import CalculateButton from '../../social-commission-basic-rules/CalculateButton';
import TableButtonDelete from '../TableButtonDelete';
import { DEFAULT_SOCIAL_COMMISSION_RULES_SETLED_FIELDS } from '../utils/social-commision.util';

import { RuleItemForms } from './TradeRuleItemForms';

interface IRequestItem {
  from?: string;
  to?: string;
  permissionAmount?: number;
  vehicleAmount?: number;
  amount?: number;
  maxLimit?: number;
  decreaseAmount?: number;
  shouldCountLastYearContingent?: boolean;
  shouldCountMissing?: boolean;
  shouldCountNotReturned?: boolean;
  shouldCountReturnedNotUsed?: boolean;
  treatNoLimitsAsProportional?: string;
  maxSettlementReturnDate?: string;
  limitFactor?: number;
  multiplier?: number;
  numberOfMonths?: number;
}

const getRequestItemFromForm = (items: Record<string, string>, type: string): IRequestItem | undefined => {
  if (type === RuleItemCodes.PERMISSION_FOR_VEHICLE_RULE) {
    return {
      from: items['from'],
      to: items['to'],
      permissionAmount: parseInt(items['permissionAmount'], 10),
      vehicleAmount: parseInt(items['vehicleAmount'], 10)
    };
  }
  if (type === RuleItemCodes.LIMITED_VALUE_RULE) {
    return {
      maxLimit: parseInt(items['maxLimit'], 10)
    };
  }
  if (type === RuleItemCodes.NOT_USED_PERMISSION_RULE) {
    return {
      decreaseAmount: parseInt(items['decreaseAmount'], 10),
      numberOfMonths:
        isNumber(items?.['numberOfMonths']) || isString(items?.['numberOfMonths'])
          ? parseInt(items?.['numberOfMonths'], 10)
          : undefined,
      shouldCountLastYearContingent: !!items['shouldCountLastYearContingent'],
      shouldCountMissing: !!items['shouldCountMissing'],
      shouldCountNotReturned: !!items['shouldCountNotReturned'],
      shouldCountReturnedNotUsed: !!items['shouldCountReturnedNotUsed']
    };
  }
  if (type === RuleItemCodes.MONTHLY_PROPORTIONAL_USAGE_RULE) {
    return {
      treatNoLimitsAsProportional: items['treatNoLimitsAsProportional']
    };
  }
  if (type === RuleItemCodes.FOLDER_LIMIT_RULE) {
    return {
      maxSettlementReturnDate: items['maxSettlementReturnDate'],
      limitFactor: parseInt(items['limitFactor'], 10),
      multiplier: parseInt(items['multiplier'], 10)
    };
  }
  if (type === RuleItemCodes.LIMITED_VALUE_BY_VEHICLES_RULE) {
    return {
      amount: parseInt(items['amount'], 10)
    };
  }

  return undefined;
};

function TradeRulesPage({
  formMode,
  moveUp,
  moveDown,
  setCancel,
  setEditMode,
  setViewMode,
  unSelectAll
}: {
  formMode: FormMode;
  moveUp: (index: number) => void;
  moveDown: (index: number) => void;
  setCancel: () => void;
  setEditMode: () => void;
  setViewMode: () => void;
  unSelectAll: () => void;
}) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { openDialog } = useDialog();
  const [rulesList, setRulesList] = useState<RuleItemCodes[]>([]);

  const { setValue, getValues, watch, handleSubmit } = useFormV2Context();
  const { editMode, viewMode } = useViewModesV2(formMode);
  const { showSuccessSnackbar } = useSnackbar();

  const { mutate: editApplication } = useEditTradeRulesMutation({
    onSuccess: () => {
      showSuccessSnackbar(t('global:success.save'));
      queryCache.invalidateQueries(SocialCommissionQueryKeysEnum.TRADE_RULES_DETAILS);
      setViewMode();
    }
  });

  const items = watch('items') as Partial<CivicBoardRuleItemConfig & { type?: string }>[];
  const id = watch(typedNameV2<CivicBoardRuleDetails>('id')) as string;
  const year = watch(typedNameV2<CivicBoardRuleDetails>('year')) as number;
  const resourceTypeId = watch(typedNameV2<CivicBoardRuleDetails>('resourceTypeId')) as string;

  const deleteIndexFromList = (index: number) => {
    const values = getValues() as CivicBoardRuleDetails;
    const items = values.items.filter((item, i) => i !== index);
    setValue('items', items);
    setRulesList(old => old.filter((_, i) => i !== index));
  };

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(
      YupObject({
        type: YupObject().nullable().required()
      })
    )
  });

  const formDataProvider = useMemo(() => {
    return { ...form };
  }, [form]);

  useEffect(() => {
    if (editMode) {
      items?.forEach((item, index) => {
        const fields = DEFAULT_SOCIAL_COMMISSION_RULES_SETLED_FIELDS?.[item.ruleItemCode] as {
          [key: string]: number;
        };
        Object?.keys(fields)?.forEach(key => {
          if (isUndefined(item?.[key]) || isNull(item?.[key])) {
            setValue(`items[${index}].${key}`, fields?.[key]);
          }
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rulesList?.length, setValue]);

  const generateKey = (rule: string): string => {
    return `${rule}_${new Date().getTime()}`;
  };

  useEffect(() => {
    if (viewMode || editMode) {
      setRulesList(items?.map(item => item?.ruleItemCode).filter(item => !!item) as RuleItemCodes[]);
    }
  }, [editMode, items, viewMode]);

  const getLabel = partialTranslate('foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules');

  const submitSave = (formData: Partial<CivicBoardRuleUpdateRequest & { id: string }>) => {
    const submitData: TUpdateTradeRulesRequest = {
      civicBoardRuleId: formData.id,
      civicBoardRuleUpdateRequest: {
        validFrom: formData?.validFrom,
        validTo: formData?.validTo,
        active: formData?.active,
        resourceTypes: [],
        version: formData.version,
        recalculateOnUpdate: formData.recalculateOnUpdate,
        items: items?.map(item => ({
          ruleConfiguration: {
            ruleItemCode: (item.ruleItemCode ? item.ruleItemCode : item.type) as RuleItemCodes,
            ...getRequestItemFromForm(item, item.ruleItemCode ? item.ruleItemCode : item.type)
          }
        }))
      }
    };

    editApplication(submitData);
  };

  const values = useMemo(() => ({ ...formDataProvider, formMode }), [formDataProvider, formMode]);

  const confirm = () => {
    openDialog(({ closeDialog }) => (
      <Dialog
        title={getLabel('modalTitle')}
        onCancel={closeDialog}
        confirmText={t('action.add')}
        cancelText={t('action.close')}
        onConfirm={() => {
          if (form.getValues('type')) {
            setValue(`items[${[rulesList.length]}].ruleItemCode`, form.getValues('type').id);
            setRulesList(old => [...old, form.getValues('type').id]);
            formDataProvider.reset();
            closeDialog();
          } else {
            form.trigger('type');
          }
        }}
        isOpen
      >
        <GridLayout itemProps={{ xs: 12 }}>
          <FormV2Context.Provider value={values}>
            <AutocompleteSelectField
              name="type"
              label={getLabel('formFields.selectType')}
              options={[
                {
                  id: RuleItemCodes.PERMISSION_FOR_VEHICLE_RULE,
                  value: RuleItemCodes.PERMISSION_FOR_VEHICLE_RULE,
                  name: getLabel('ruleType.permissionForVehicleRule')
                },
                {
                  id: RuleItemCodes.LIMITED_VALUE_RULE,
                  value: RuleItemCodes.LIMITED_VALUE_RULE,
                  name: getLabel('ruleType.limitedValueRule')
                },
                {
                  id: RuleItemCodes.NOT_USED_PERMISSION_RULE,
                  value: RuleItemCodes.NOT_USED_PERMISSION_RULE,
                  name: getLabel('ruleType.notUsedPermissionRule')
                },
                {
                  id: RuleItemCodes.MONTHLY_PROPORTIONAL_USAGE_RULE,
                  value: RuleItemCodes.MONTHLY_PROPORTIONAL_USAGE_RULE,
                  name: getLabel('ruleType.monthlyProportionalUsageRule')
                },
                {
                  id: RuleItemCodes.LIMITED_VALUE_BY_VEHICLES_RULE,
                  value: RuleItemCodes.LIMITED_VALUE_BY_VEHICLES_RULE,
                  name: getLabel('ruleType.limitedValueByVehiclesRule')
                }
              ]}
              isRequired
            />
          </FormV2Context.Provider>
        </GridLayout>
      </Dialog>
    ));
  };

  const getLabelBasicData = partialTranslate(
    'foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.formFields'
  );

  return (
    <GridLayout containerProps={{ xs: 12 }} itemProps={{ xs: 12 }}>
      <Section
        title={t('foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.sections.basicData')}
        headerContent={
          <ButtonsGroup>
            <CalculateButton
              actionKey={DomainUIElementEnum.COMMISSION_TRADE_RULES_RECALCULATE}
              isNormalButton
              rule={{
                year: year as unknown as number,
                resourceTypeId
              }}
            />

            <TableButtonDelete
              id={id}
              unSelectAll={unSelectAll}
              actionKey={DomainUIElementEnum.COMMISSION_TRADE_RULES_DELETE}
            />

            {viewMode && (
              <Button
                label={t('action.edit')}
                actionKey={DomainUIElementEnum.COMMISSION_TRADE_RULES_EDIT}
                onClick={setEditMode}
                isSecondary
                variant="outlined"
              />
            )}
            {!viewMode && (
              <>
                <Button label={t('action.cancel')} onClick={setCancel} isSecondary variant="outlined" />
                <Button label={t('action.save')} onClick={() => handleSubmit(submitSave)()} />
              </>
            )}
          </ButtonsGroup>
        }
      >
        <GridLayout itemProps={{ md: 6, xs: 12 }}>
          <SwitchField label={getLabelBasicData('isActive')} name="active" />
          <DatepickerField
            name={typedNameV2<CivicBoardRuleDetails>('year')}
            label={getLabelBasicData('year')}
            views={['year']}
            isFullWidth={false}
            minDate={moment().subtract(10, 'year').toDate()}
            maxDate={moment().add(10, 'year').toDate()}
            isRequired
            inputMode={InputMode.VIEW}
          />
          <DictionarySelectField
            name={typedNameV2<CivicBoardRuleDetails>('countryCodeKey')}
            label={getLabelBasicData('country')}
            dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
            stringValue
            isRequired
            inputMode={InputMode.VIEW}
          />
          <DictionarySelectField
            name={typedNameV2<CivicBoardRuleDetails>('resourceFormNameKey')}
            label={getLabelBasicData('name')}
            dictionaryName={DomainDictionaryEnum.RESOURCE_FORM_NAME}
            stringValue
            isRequired
            inputMode={InputMode.VIEW}
          />
          <DatepickerField
            name={typedNameV2<CivicBoardRuleCreateRequest>('validFrom')}
            label={getLabelBasicData('effectiveDateFrom')}
            viewModeDateParser={convertDateToDateTimeFormat}
            isRequired
          />
          <DatepickerField
            name={typedNameV2<CivicBoardRuleCreateRequest>('validTo')}
            label={getLabelBasicData('effectiveDateTo')}
            viewModeDateParser={convertDateToDateTimeFormat}
            isRequired
          />
          <SwitchField
            label={getLabelBasicData('recalculateOnUpdate')}
            name={typedNameV2<CivicBoardRuleCreateRequest>('recalculateOnUpdate')}
          />
        </GridLayout>
      </Section>
      <Section title={getLabel('sections.rules')}>
        {rulesList?.length > 0 ? (
          rulesList?.map((rule, index) => (
            <RuleItemForms
              type={rule}
              index={index}
              moveUp={moveUp}
              moveDown={moveDown}
              length={rulesList.length}
              deleteIndexFromList={deleteIndexFromList}
              // eslint-disable-next-line react/no-array-index-key
              key={`${index}-${generateKey(rule)}`}
              inputMode={viewMode ? InputMode.VIEW : InputMode.FORM}
              generateKey={generateKey}
            />
          ))
        ) : (
          <WarningInformation
            content={t('foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.messages.emptyRules')}
          />
        )}
        {!viewMode && (
          <div className="mt-8 flex">
            <Button
              label={getLabel('button.add')}
              onClick={() => confirm()}
              isPrimary
              classNameWrapper="ml-auto"
              isSecondary
              variant="outlined"
            />
          </div>
        )}
      </Section>
    </GridLayout>
  );
}

export default TradeRulesPage;
