import { ReportDetailsLite } from '@stack/report';

import { useDialog } from '@libs/common/v2';

import { ReportTypeGenerationDialog } from '@libs/report/components/report-type-form';

interface TargetParameter {
  name: string;
  value: string;
}

export default function useReportGeneration(
  globalReportName?: string,
  globalTargetParameters?: TargetParameter[],
  initialValues?: { parameters: Record<string, string>; targetTypes: string[] },
  isTechnical?: boolean
) {
  const { openDialog } = useDialog();

  const generate = (
    reportName?: string,
    targetParameters?: TargetParameter[],
    onSuccess?: (data: ReportDetailsLite) => void
  ) => {
    openDialog(({ closeDialog }) => {
      return (
        <ReportTypeGenerationDialog
          targetParameters={globalTargetParameters || targetParameters}
          initialValues={initialValues}
          isTechnical={isTechnical}
          reportName={globalReportName || reportName}
          onSuccess={onSuccess}
          onClose={closeDialog}
        />
      );
    });
  };

  return [generate];
}
