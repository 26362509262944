import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import _ from 'lodash';

import { FormV2Context, usePreviousProps } from '@libs/common/v2';

const replaceDots = (value: string): string => {
  return _.replace(value, /\./g, '\\.');
};

const makeSelector = (value: string): string => {
  return `*[name=${replaceDots(value)}]`;
};

const scrollToError = (container, targetName: string) => {
  let target = container.querySelector(makeSelector(targetName));
  const containerTemp = container;

  if (target) {
    if (target.getAttribute('type') === 'hidden') {
      target = target.parentElement;
    }
    target.scrollIntoView();
    containerTemp.scrollTop -= 24;
  }
};

const useErrorsHandler = <T extends string | number | symbol, DataType = any>(
  onTabChange?: (tab: T) => void,
  scrollerIdPrefix?: DataType,
  formContext = FormV2Context
) => {
  const { isSubmitting, formState } = useContext(formContext);
  const containerRef = useRef<HTMLDivElement>(null);

  const [tabsFieldsMap, setTabsFieldsMap] = useState({});
  const [viewFields, setViewFields] = useState<string[]>([]);

  const prevIsSubmitting = usePreviousProps(isSubmitting);

  useEffect(() => {
    if (containerRef.current) {
      setTimeout(() => {
        const nodesName = _.compact(
          Array.from(containerRef.current?.querySelectorAll('input') || []).map((node: any) =>
            node.getAttribute('name')
          )
        );
        if (nodesName.length) {
          setViewFields(nodesName);
        }
        // Czas pozwalający na wyrenderowanie warunkowych części formularzy
      }, 1000);
    }
  }, []);

  const hasTabErrors = (tab: string) => {
    if (!_.isEmpty(formState.errors) && tabsFieldsMap[tab]) {
      return _.some(tabsFieldsMap[tab], (field: string) => _.get(formState.errors, field, null));
    }
    return false;
  };

  const getTabsWithErrors = (): T[] => {
    if (!_.isEmpty(tabsFieldsMap)) {
      return Object.keys(tabsFieldsMap).reduce((acc, key) => {
        return hasTabErrors(key) ? acc.concat(key) : acc;
      }, []);
    }
    return [];
  };

  const tabsWithErrors = getTabsWithErrors();

  const handleTabChange = useCallback((): Promise<any> => {
    const firstTabWithError = _.head(tabsWithErrors);
    // setActiveTab(firstTabWithError);
    if (onTabChange && firstTabWithError) {
      onTabChange(firstTabWithError);
    }
    return Promise.resolve(firstTabWithError);
  }, [onTabChange, tabsWithErrors]);

  useEffect(() => {
    if (prevIsSubmitting && !isSubmitting && !_.isEmpty(formState.errors)) {
      if (viewFields.length && containerRef.current) {
        const targetName = _.find<string>(viewFields, (item: string) => _.get(formState.errors, item, null));
        if (targetName) {
          const element = document.querySelector(makeSelector(targetName));

          if (element) {
            element.scrollIntoView();
          }
        }
      } else {
        handleTabChange().then(tabWithError => {
          const container = document.getElementById(`${scrollerIdPrefix}-${tabWithError}`);
          const targetName = _.find(tabsFieldsMap[tabWithError], (item: string) =>
            _.get(formState.errors, item, null)
          ) as string;
          if (container) {
            scrollToError(container, targetName);
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [prevIsSubmitting, isSubmitting]);

  const saveFieldList = (tab: T, fields: string[]) => {
    setTabsFieldsMap(prev => {
      return _.merge(prev, { [tab]: fields });
    });
  };

  return {
    saveFieldList,
    hasTabErrors,
    tabsWithErrors,
    containerRef,
    setTabsFieldsMap
  };
};

export default useErrorsHandler;
