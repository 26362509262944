import {
  array as YupArray,
  boolean as YupBoolean,
  number as YupNumber,
  object as YupObject,
  string as YupString
} from 'yup';

import createValidationRegistry from '../registry/validation-registry';

import FieldTypeEnum from './FieldTypeEnum';

export const validation = createValidationRegistry();

export type ValidationRegistryType = typeof validation;

type WithoutBase<T> = Omit<T, '_base'>;

// text-field typy
const stringInstance = YupString();
type TextFieldValidationProperties = keyof typeof stringInstance;
type CustomTextFieldValidationProperties = keyof WithoutBase<(typeof validation)[FieldTypeEnum.TEXT]>;

export type TextFieldValidation = {
  [key in TextFieldValidationProperties]?: Parameters<(typeof stringInstance)[key]>;
} & {
  [key in CustomTextFieldValidationProperties]?: Parameters<(typeof validation)[FieldTypeEnum.TEXT][key]>;
};

// select fieldy typy

const objectInstance = YupObject();
type SelectValidationProperties = keyof Omit<typeof objectInstance, 'fields'>;
type CustomSelectValidationProperties = keyof WithoutBase<(typeof validation)[FieldTypeEnum.AUTOCOMPLETE]>;

export type SelectFieldValidation = {
  [key in SelectValidationProperties]?: Parameters<(typeof objectInstance)[key]>;
} & {
  [key in CustomSelectValidationProperties]?: Parameters<(typeof validation)[FieldTypeEnum.AUTOCOMPLETE][key]>;
};

// select fieldy tryb MULTIPLE typy
const arrayInstance = YupArray();
type SelectMultipleValidationProperties = keyof typeof arrayInstance;
type CustomSelectMultipleValidationProperties = keyof WithoutBase<(typeof validation)[FieldTypeEnum.AUTOCOMPLETE]>;

export type SelectMultipleFieldValidation = {
  [key in SelectMultipleValidationProperties]?: Parameters<(typeof arrayInstance)[key]>;
} & {
  [key in CustomSelectMultipleValidationProperties]?: Parameters<(typeof validation)[FieldTypeEnum.AUTOCOMPLETE][key]>;
};

// number field  typy
const numberInstance = YupNumber();
type NumberValidationProperties = keyof typeof numberInstance;
type CustomNumberValidationProperties = keyof WithoutBase<(typeof validation)[FieldTypeEnum.NUMBER]>;

export type NumberFieldValidation = {
  [key in NumberValidationProperties]?: Parameters<(typeof numberInstance)[key]>;
} & {
  [key in CustomNumberValidationProperties]?: Parameters<(typeof validation)[FieldTypeEnum.NUMBER][key]>;
};

// boolean field  typy
const booleanInstance = YupBoolean();
type BooleanValidationProperties = keyof typeof booleanInstance;
type CustomBooleanValidationProperties = keyof WithoutBase<(typeof validation)[FieldTypeEnum.BOOLEAN]>;

export type BooleanFieldValidation = {
  [key in BooleanValidationProperties]?: Parameters<(typeof booleanInstance)[key]>;
} & {
  [key in CustomBooleanValidationProperties]?: Parameters<(typeof validation)[FieldTypeEnum.BOOLEAN][key]>;
};

// datepicker field  typy
const dateInstance = YupString();
type DatePickerValidationProperties = keyof typeof dateInstance;
type CustomDatePickerValidationProperties = keyof WithoutBase<(typeof validation)[FieldTypeEnum.DATETIME]>;

export type DatePickerFieldValidation = {
  [key in DatePickerValidationProperties]?: Parameters<(typeof dateInstance)[key]>;
} & {
  [key in CustomDatePickerValidationProperties]?: Parameters<(typeof validation)[FieldTypeEnum.DATETIME][key]>;
};

export interface ValidatorDetails {
  regex: string;
  placeholder: string | null;
  message: string;
  description: string | null;
  required: boolean;
}
