import { useTranslation } from 'react-i18next';

import { Button } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

import { DomainDictionaryEntry } from '../../../config';

import { useVehicleSearchAction } from './useVehicleSearchAction';

interface IProps {
  folderId?: string;
  transferredFoldersIds?: string[];
  certificateStatusList?: string[];
  applicationId: string;
  applicationType: string;
  applicationCategory: string;
  actionKey: UIElementNameEnum;
  hasIpwzWarningInformation?: boolean;
  statusKeys?: Array<string>;
  vehicleModificationConcernsKey?: string;
  isDirty?: boolean;
  onSuccess?: () => void;
  isAddSelectedActionButtonHidden?: boolean;
}

function VehicleSearchActionButton({
  folderId,
  transferredFoldersIds,
  certificateStatusList,
  applicationId,
  applicationCategory,
  applicationType,
  actionKey,
  hasIpwzWarningInformation,
  statusKeys,
  vehicleModificationConcernsKey,
  isDirty,
  onSuccess,
  isAddSelectedActionButtonHidden
}: IProps) {
  const [t] = useTranslation();

  const { openVehicleSearchDialog } = useVehicleSearchAction({
    folderId,
    transferredFoldersIds,
    certificateStatusList,
    applicationId,
    applicationCategory,
    applicationType,
    hasIpwzWarningInformation,
    statusKeys,
    vehicleModificationConcernsKey,
    isDirty,
    onSuccess,
    isAddSelectedActionButtonHidden
  });

  const isDisabled =
    [
      DomainDictionaryEntry.APPLICATION_TYPE.PB11A,
      DomainDictionaryEntry.APPLICATION_TYPE.PB11B,
      DomainDictionaryEntry.APPLICATION_TYPE.PB11C,
      DomainDictionaryEntry.APPLICATION_TYPE.PB11D,
      DomainDictionaryEntry.APPLICATION_TYPE.PB12
    ].includes(applicationType) && !vehicleModificationConcernsKey;

  return (
    <Button
      type="button"
      variant="outlined"
      onClick={openVehicleSearchDialog}
      label={t('vehicle:details.button.searchVehicle')}
      actionKey={actionKey}
      tooltipTitle={isDisabled && t('vehicle:details.button.searchVehicleTooltipDisabled')}
      disabled={isDisabled}
    />
  );
}

export default VehicleSearchActionButton;
