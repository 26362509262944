import { useCallback } from 'react';

import { useElementVisibility } from '@libs/permission';

import FOLDER_ELEMENTS_PERMISSIONS from './FolderElementsPermissions';
import FolderElementsPermissionsEnum from './FolderElementsPermissionsEnum';

function useFolderElementsVisibility() {
  const { checkIsElementVisible } = useElementVisibility();

  const checkFolderElementVisibility = useCallback(
    ({ folderTypeKey, permisson }: { folderTypeKey: string; permisson: FolderElementsPermissionsEnum }) => {
      const folderPermission = FOLDER_ELEMENTS_PERMISSIONS[permisson][folderTypeKey];
      return !folderPermission || checkIsElementVisible(folderPermission);
    },
    [checkIsElementVisible]
  );

  return { checkFolderElementVisibility };
}

export default useFolderElementsVisibility;
