import { useEffect } from 'react';
import { FieldPath, FieldValues, useWatch } from 'react-hook-form';
import { AddressDetails, AddressUpdateRequest } from '@ibtm/domain';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import { partialTranslate } from '@libs/common';
import {
  DictionarySelectField,
  GridItem,
  GridLayout,
  InputMode,
  SelectWithFetchedOptionsField,
  SwitchField,
  typedNameV2,
  useFormV2Context,
  Value
} from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import { useSetInitialAddressType } from '../../hooks';
import { AddressSection } from '../address-content';

const getLabel = partialTranslate('address:fields');

interface IProps {
  applicationId?: string;
  formGroupName: string | FieldPath<FieldValues>;
  addressFormGroupName?: string | FieldPath<FieldValues>;
  hideProxyFields?: boolean;
  hideSameAsMainField?: boolean;
  hideAddressTypeField?: boolean;
  hiddenFields?: Array<keyof AddressUpdateRequest>;
  inputMode?: InputMode;
  isCountryFieldAvaliable: boolean;
}

function CorrespondenceAddress({
  applicationId,
  formGroupName,
  addressFormGroupName = formGroupName,
  hideProxyFields,
  hideSameAsMainField,
  hideAddressTypeField,
  hiddenFields,
  inputMode,
  isCountryFieldAvaliable
}: IProps) {
  const { getQuery } = useGetApiQueryByPerspective();
  const { control, setValue, clearErrors } = useFormV2Context();
  const { checkIsElementVisible } = useElementVisibility();

  const getFieldName = (fieldName: any) => typedNameV2<any>(`${formGroupName}.${fieldName}`);
  const hasSearchProxyPermissions = checkIsElementVisible(UIElementNameEnum.APPLICATION_PROXY_VIEW);

  const sameAsMainAddressValue = useWatch({
    control,
    name: `${formGroupName}.sameAsMainAddress` as FieldPath<FieldValues>
  }) as boolean;
  const sameAsProxyAddressValue = useWatch({
    control,
    name: `${formGroupName}.sameAsProxyAddress` as FieldPath<FieldValues>
  }) as boolean;

  const addressSourceProxyFormValue = useWatch({
    control,
    name: `${formGroupName}.addressSourceProxy` as FieldPath<FieldValues>
  });

  const city = useWatch({
    control,
    name: `${formGroupName}.city` as FieldPath<FieldValues>
  }) as boolean;

  const propertyNumber = useWatch({
    control,
    name: `${formGroupName}.propertyNumber` as FieldPath<FieldValues>
  }) as boolean;

  const street = useWatch({
    control,
    name: `${formGroupName}.street` as FieldPath<FieldValues>
  }) as boolean;

  useEffect(() => {
    if (addressSourceProxyFormValue?.id) {
      if (!sameAsMainAddressValue) {
        setValue(`${formGroupName}.sameAsProxyAddress` as FieldPath<FieldValues>, true);
      }

      setValue(`${formGroupName}.addressSourceProxy` as FieldPath<FieldValues>, { id: addressSourceProxyFormValue.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressSourceProxyFormValue?.id]);

  const hideAddressFields = sameAsMainAddressValue || sameAsProxyAddressValue;

  useEffect(() => {
    if (sameAsMainAddressValue) {
      setValue(`${formGroupName}.sameAsProxyAddress` as FieldPath<FieldValues>, false);
      setValue(`${formGroupName}.addressSourceProxy` as FieldPath<FieldValues>, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sameAsMainAddressValue]);

  useEffect(() => {
    if (sameAsProxyAddressValue) {
      setValue(`${formGroupName}.sameAsMainAddress` as FieldPath<FieldValues>, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sameAsProxyAddressValue]);

  const typeKey = getFieldName('typeKey');

  useSetInitialAddressType(typeKey, DomainDictionaryEntry.ADDRESS_TYPE.CORRESPONDENCE);

  const getProxies = getQuery(
    () => API.client.proxy.getProxiesSnapshotPage('', '', {}),
    () =>
      API.proxy.getProxiesSnapshotPage({
        applicationIdIn: applicationId ? [applicationId] : []
      })
  );

  return (
    <GridLayout itemProps={{ xs: 12, sm: 6 }}>
      {!hideSameAsMainField && (
        <SwitchField
          name={getFieldName('sameAsMainAddress')}
          label={getLabel('sameAsMainAddress')}
          {...(inputMode && { inputMode })}
        />
      )}

      {!hideProxyFields && (
        <SwitchField
          name={getFieldName('sameAsProxyAddress')}
          label={getLabel('sameAsProxyAddress')}
          {...(inputMode && { inputMode })}
          onCustomChange={() => {
            clearErrors(getFieldName('addressSourceProxy'));
          }}
        />
      )}

      {!hideProxyFields && hasSearchProxyPermissions && (
        <SelectWithFetchedOptionsField
          name={getFieldName('addressSourceProxy')}
          label={getLabel('addressSourceProxy')}
          fetchFunction={getProxies}
          getOptionLabel={(proxy: AddressDetails) =>
            proxy?.city ? `${proxy.city}, ${proxy.street} ${proxy.propertyNumber}` : ''
          }
          renderOption={(props, option: { value: AddressDetails }) => (
            <li {...props}>
              {option.value.city}, {option.value.street} {option.value.propertyNumber}
            </li>
          )}
          isRequired={sameAsProxyAddressValue}
          validationSingleSelect={sameAsProxyAddressValue && inputMode !== InputMode.VIEW ? { required: [] } : null}
          isDisabled={!sameAsProxyAddressValue}
          getOptionSelected={(option, value) => option.id === value.id}
          isClearable
          {...(inputMode && { inputMode })}
        />
      )}
      {!hideProxyFields && !hasSearchProxyPermissions && (
        <Value
          label={getLabel('addressSourceProxy')}
          value={sameAsProxyAddressValue ? `${city}, ${street} ${propertyNumber}` : ''}
        />
      )}

      {!hideAddressFields && (
        <GridItem xs={12}>
          <AddressSection
            formGroupName={addressFormGroupName}
            hiddenFields={hiddenFields}
            isCountryFieldAvaliable={isCountryFieldAvaliable}
            {...(inputMode && { inputProps: { inputMode } })}
            isValidationEnabled={inputMode !== InputMode.VIEW}
            isCommuneValidated={false}
            isCountyValidated={false}
            isVoivodeshipValidated={false}
          />
        </GridItem>
      )}

      {!hideAddressTypeField && (
        <DictionarySelectField
          name={getFieldName('typeKey')}
          label={getLabel('type')}
          dictionaryName={DomainDictionaryEnum.ADDRESS_TYPE}
          inputMode={InputMode.VIEW}
        />
      )}
    </GridLayout>
  );
}

export default CorrespondenceAddress;
