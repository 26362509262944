import { ForwardedRef, forwardRef, MutableRefObject, ReactNode, useImperativeHandle, useMemo } from 'react';
import { DeepPartial, useForm, UseFormHandleSubmit } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormMode, FormV2Context, FormV2ContextState, useValidationBuilder } from '@libs/common/v2';

export type MisspellFormRef<T> = MutableRefObject<UseFormHandleSubmit<T>>;

interface IProps<T> {
  permissionData: DeepPartial<T>;
  formMode: FormMode;
  children: ReactNode;
}

export const MisspellApplicationFormProvider = forwardRef(
  <T,>({ formMode, permissionData, children }: IProps<T>, ref: ForwardedRef<UseFormHandleSubmit<T>>) => {
    const { validationBuilderFunctions, validationScheme } = useValidationBuilder();
    const formMethods = useForm<T>({
      defaultValues: permissionData,
      resolver: yupResolver(validationScheme),
      mode: 'onBlur',
      reValidateMode: 'onBlur'
    });

    useImperativeHandle(ref, () => formMethods.handleSubmit, [formMethods.handleSubmit]);

    const values = useMemo(
      () => ({ ...formMethods, formMode, validationBuilderFunctions }),
      [formMethods, formMode, validationBuilderFunctions]
    );

    return <FormV2Context.Provider value={values as unknown as FormV2ContextState}>{children}</FormV2Context.Provider>;
  }
) as <T>(props: IProps<T> & { ref: ForwardedRef<UseFormHandleSubmit<T>> }) => JSX.Element;
