import { createContext, useContext } from 'react';

import { SystemParametersContextValue } from './SystemParametersContext';

export type OverrideSystemParametersContextValue = {
  overrideSystemParameters: (value: Partial<SystemParametersContextValue>) => void;
  clearOverriddenParameters: () => void;
};

export const OverrideSystemParametersContext = createContext<OverrideSystemParametersContextValue>({
  overrideSystemParameters: () => {},
  clearOverriddenParameters: () => {}
});

export function useOverrideSystemParameters() {
  const context = useContext(OverrideSystemParametersContext);
  if (!context) {
    throw new Error('useOverrideSystemParameters must be used within a OverrideSystemParametersProvider');
  }
  return context;
}
