import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { ReportDetailsSnapshot } from '@stack/report/dist/models';

import { DropdownButton, DropdownListButton, useDevMode } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';
import { useFileDownload } from '@libs/file';
import { ReportStatusDictionaryEnum } from '@libs/report';
import { API } from '@libs/report/api';
import { ReportDictionaryEntryNameEnum } from '@libs/report-core';

function ReportDownloadButton({
  report,
  isLoading,
  handleDownloadReportDataFiles
}: {
  report: ReportDetailsSnapshot;
  isLoading: boolean;
  handleDownloadReportDataFiles: () => void;
}) {
  const [downloadingFileId, setDownloadingFileId] = useState('');
  const { download, isLoading: isDownloading } = useFileDownload();
  const { translate } = useDictionaryTranslations();
  const [t] = useTranslation();
  const { isDevMode } = useDevMode();

  const isReportGenerated = useMemo(() => report?.statusKey === ReportStatusDictionaryEnum.COMPLETED, [report]);

  const menuOptions = Object.entries(report?.resultFiles ?? {}).map(([mime, fileId]) => ({ mime, fileId }));

  const handleDownloadSingleFile = async (fileId: string) => {
    setDownloadingFileId(fileId);
    await download(() => API.file.download(fileId, '', undefined, { responseType: 'blob' }));
  };

  return (
    <div className="m-8">
      {isDownloading && <CircularProgress size={24} className="absolute" />}
      <DropdownButton title={t('global:action.download')} buttonType="button">
        {() => (
          <>
            {isReportGenerated &&
              menuOptions?.map(({ mime, fileId }) => (
                <DropdownListButton
                  key={fileId}
                  label={translate(ReportDictionaryEntryNameEnum.DOCUMENT_TARGET_MIME_TYPE, mime)}
                  isLoading={fileId === downloadingFileId && isDownloading}
                  onClick={() => handleDownloadSingleFile(fileId)}
                />
              ))}
            {isDevMode && (
              <DropdownListButton
                label={t('reports:action.downloadReportData')}
                onClick={handleDownloadReportDataFiles}
                isLoading={isLoading || isDownloading}
              />
            )}
          </>
        )}
      </DropdownButton>
    </div>
  );
}

export default React.memo(ReportDownloadButton);
