import { useTranslation } from 'react-i18next';

import { TableIconButton, TableLayout, useTableRowActions } from '@libs/common/v2';

import { DomainUIElementEnum } from '@libs/domain/config';

import { useDepotAdminsTable } from '../../../hooks';

interface IProps {
  depotId?: string;
  onDelete: (string) => void;
  loadingRemoveAdminId?: string;
}

function DepotAdminsTable({ depotId, onDelete, loadingRemoveAdminId }: IProps) {
  const [t] = useTranslation();
  const tableProps = useDepotAdminsTable(depotId);

  const { renderTableActions } = useTableRowActions(
    [
      {
        id: DomainUIElementEnum.RESOURCES_DEPOT_DELETE_ADMIN,
        customButton: ({ original }) => (
          <TableIconButton
            tooltipTitle={t('action.delete')}
            icon="TrashIcon"
            onClick={() => onDelete(original?.depot?.user?.id)}
            isButtonLoading={loadingRemoveAdminId === original?.depot?.user?.id}
            actionKey={DomainUIElementEnum.RESOURCES_DEPOT_DELETE_ADMIN}
          />
        )
      }
    ],
    [loadingRemoveAdminId]
  );

  return (
    <TableLayout
      {...tableProps}
      isFilterEnabled={false}
      isSectionFullTab
      isHeaderHidden
      rowActions={(row, table) => renderTableActions([DomainUIElementEnum.RESOURCES_DEPOT_DELETE_ADMIN])(row, table)}
    />
  );
}

export default DepotAdminsTable;
