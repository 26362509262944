import { useAuthSelector } from '@libs/auth/store/reducers';

import { usePrintsGetUnitSignerQuery } from '../api';

function usePrintsGetUnitSigner({ isQueryEnabled, unitId }: { isQueryEnabled?: boolean; unitId?: string } = {}) {
  const { organizationUnit } = useAuthSelector(({ auth }) => auth.user);
  const unit = organizationUnit;
  const id = unitId || unit?.id;
  const { data, isLoading } = usePrintsGetUnitSignerQuery(id, {
    enabled: Boolean((isQueryEnabled ?? true) && id),
    refetchOnMount: false
  });
  return { unitId: unit?.id, signerDetails: data, isLoading };
}

export default usePrintsGetUnitSigner;
