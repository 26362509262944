import { PluginHook, TableOptions } from 'react-table';
import { VehiclesSearchFilter as VehiclesSearchFilterClient } from '@ibtm/client-domain';
import { VehicleSnapshot, VehiclesSearchFilter } from '@ibtm/domain';

import { CamelCasePath, useTableLayoutProps } from '@libs/common/v2';

import { useVehiclesQuery } from '@libs/domain/vehicle';

import useVehicleColumns from './useVehicleColumns';

function useVehiclesTable({
  tablePlugins,
  visibleColumns,
  queryParams,
  tableOptions,
  translateEuroClass,
  tableHookQueryConfig
}: {
  tablePlugins: Array<PluginHook<VehicleSnapshot>>;
  visibleColumns: Array<CamelCasePath<VehicleSnapshot>>;
  queryParams: VehiclesSearchFilter | VehiclesSearchFilterClient;
  tableOptions: Partial<TableOptions<VehicleSnapshot>>;
  translateEuroClass?: (year: number, euroClassKey: string) => string;
  tableHookQueryConfig?: Record<string, any>;
}) {
  const { columns, mappedFilterFields } = useVehicleColumns(visibleColumns, translateEuroClass);

  return useTableLayoutProps({
    tableHookQuery: useVehiclesQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      tablePlugins,
      tableOptions
    },
    tableHookQueryInitialParams: queryParams as Record<string, unknown>,
    tableHookQueryConfig
  });
}

export default useVehiclesTable;
