import {
  AdditionalSectionLicenseOrPermissionType,
  ApplicationMetaFormDefinition
} from '@libs/domain/application/model';

const definition: ApplicationMetaFormDefinition = {
  fields: [
    {
      typeKey: 'MISSPELL_APPLICATIONS_COLLECTION',
      fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-applications-reprint'
    },
    {
      typeKey: 'TEXT',
      fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-description-reprint',
      properties: {
        label: 'Opis popełnionego błędu',
        yupValidation: {
          required: true
        },
        lines: 5,
        api: {
          submit: {
            requestKey: 'UPDATE_SCERTIFICATES_REPRINT',
            accessor: 'misspellApplicationDataUpdateRequest.description'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'misspellApplicationDataDetails.description'
          }
        }
      }
    },
    {
      typeKey: 'DATETIME',
      fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-dateOfIssue-reprint',
      properties: {
        label: 'Data wpływu',
        yupValidation: {
          required: {}
        },
        api: {
          submit: {
            requestKey: 'UPDATE_SCERTIFICATES_REPRINT',
            accessor: 'misspellApplicationDataUpdateRequest.dateOfIssue'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'misspellApplicationDataDetails.dateOfIssue'
          }
        }
      }
    },
    {
      typeKey: 'SCERTIFICATES_COLLECTION',
      fieldId: 'application-scertificates-reprint-collection',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_SCERTIFICATES_REPRINT'
          }
        }
      }
    },
    {
      typeKey: 'SCERTIFICATES_APPLICATION_SECTION',
      fieldId: 'application-scertificates-reprint-application-section',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_SCERTIFICATES_REPRINT',
            accessor: 'scertificates'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-receipt-type-key-scertificate-reprint',
      properties: {
        label: 'Sposób odbioru',
        dictionaryName: 'RECEIPT_TYPE',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_SCERTIFICATES_REPRINT',
            accessor: 'receiptTypeKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'receiptTypeKey'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-send-to-address-type-key-scertificate-reprint',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_SCERTIFICATES_REPRINT',
            accessor: 'sendToAddressTypeKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'sendToAddressTypeKey'
          }
        },
        hidden: true
      }
    },
    {
      typeKey: 'SELECT_ADDRESS_TYPE_FIELD',
      fieldId: 'application-send-to-address-required-scertificate-reprint',
      properties: {
        label: 'Adres do wysyłki',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_SCERTIFICATES_REPRINT',
            accessor: 'newSendToAddressTypeKey',
            formAccessor: 'value.typeKey'
          },
          fetch: {
            requestKey: 'GET_APPLICATION_ADDRESSES'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'sendToAddress'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-send-to-address-name-scertificate-reprint',
      properties: {
        label: 'Imię odbierającego',
        api: {
          submit: {
            requestKey: 'UPDATE_SCERTIFICATES_REPRINT',
            accessor: 'recipientName'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'recipientName'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-send-to-address-surname-scertificate-reprint',
      properties: {
        label: 'Nazwisko odbierającego',
        api: {
          submit: {
            requestKey: 'UPDATE_SCERTIFICATES_REPRINT',
            accessor: 'recipientSurname'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'recipientSurname'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-version-reprint',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_SCERTIFICATES_REPRINT',
            accessor: 'version'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'version'
          }
        },
        hidden: true
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-submission-date-hidden-reprint',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_SCERTIFICATES_REPRINT',
            accessor: 'submissionReceiptDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'submissionReceiptDate'
          }
        },
        hidden: true
      }
    }
  ],
  components: [
    {
      componentId: 'application-scertificates-reprint-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'SCERTIFICATES'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-warnings'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-scertificates-reprint-application-section'
            }
          },
          {
            typeKey: 'COMPONENT',
            properties: {
              componentId: 'application-general-tab-delivery-channel-section-scertificate-reprint'
            }
          },
          {
            slotId: 'additional-section'
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-scertificates-reprint-collection'
            }
          }
        ]
      }
    },
    {
      componentId: 'application-general-tab-delivery-channel-section-scertificate-reprint',
      node: {
        typeKey: 'SECTION',
        properties: {
          title: 'Sposób odbioru'
        },
        nodes: [
          {
            typeKey: 'GRID',
            properties: {
              gridItemProps: {
                xs: 12,
                md: 6
              }
            },
            nodes: [
              {
                typeKey: 'FIELD',
                properties: {
                  fieldId: 'application-receipt-type-key-scertificate-reprint'
                }
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key-scertificate-reprint',
                  value: {
                    notIncludes: ['ibtm-domain.receipt.type.pickup-in-person', 'ibtm-domain.receipt.type.e-mail']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-send-to-address-type-key-scertificate-reprint'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-send-to-address-required-scertificate-reprint'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key-scertificate-reprint',
                  value: {
                    includes: ['ibtm-domain.receipt.type.pickup-in-person']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-send-to-address-name-scertificate-reprint'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key-scertificate-reprint',
                  value: {
                    includes: ['ibtm-domain.receipt.type.pickup-in-person']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-send-to-address-surname-scertificate-reprint'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key-scertificate-reprint',
                  value: {
                    includes: ['ibtm-domain.receipt.type.e-mail']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-subject-email-view'
                    }
                  }
                ]
              },
              {
                slotId: 'additional-field'
              }
            ]
          }
        ]
      }
    }
  ],
  layout: [
    {
      typeKey: 'TAB_NAVIGATION',
      properties: {
        visibility: {
          elementVisibilityKeys: ['TAB_VISIBILITY']
        },
        defaultTabId: 'SCERTIFICATES',
        title: 'Sekcje',
        tabs: [
          {
            id: 'SCERTIFICATES',
            title: 'Certyfikaty S',
            icon: 'SCertificatesIcon'
          },
          {
            id: 'SUBJECT',
            title: 'Podmiot',
            icon: 'FacadeIcon'
          },
          {
            id: 'ADDRESSES',
            title: 'Adresy',
            icon: 'MailboxIcon'
          },
          {
            id: 'PROXIES',
            title: 'Pełnomocnicy',
            icon: 'GroupOfPeopleIcon'
          },
          {
            id: 'ATTACHMENTS',
            title: 'Załączniki',
            icon: 'PaperclipIcon'
          },
          {
            id: 'QUOTATION',
            title: 'Wycena',
            icon: 'CoinsIcon'
          },
          {
            id: 'OUTGOING_DOCUMENTS',
            title: 'Dokumenty wychodzące',
            icon: 'DocumentsIssuedIcon'
          },
          {
            id: 'NOTE',
            title: 'Notatka',
            icon: 'NoteIcon'
          },
          {
            id: 'FILES',
            title: 'Pliki',
            icon: 'FileEmptyIcon'
          },
          {
            id: 'HISTORY',
            title: 'Historia',
            icon: 'HistoryIcon'
          }
        ]
      },
      nodes: [
        {
          typeKey: 'TABS_CONTENT',
          nodes: [
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-scertificates-reprint-tab',
                additionalNodes: {
                  'additional-section': [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-applications-reprint'
                      }
                    },
                    {
                      typeKey: 'SECTION',
                      properties: {
                        isCollapsable: false
                      },
                      nodes: [
                        {
                          typeKey: 'GRID',
                          properties: {
                            gridItemProps: {
                              xs: 12,
                              sm: 6
                            }
                          },
                          nodes: [
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-description-reprint'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-dateOfIssue-reprint'
                              }
                            }
                          ]
                        }
                      ]
                    },
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'application-submission-date-hidden-reprint'
                      }
                    },
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'application-version-reprint'
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-subject-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-addresses-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-proxies-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-attachments-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-quotation-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-outgoing-documents-tab',
                additionalNodes: {
                  'outgoing-documents-additional-section': [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'application-outgoing-documents-additional-section-license-or-permission',
                        sectionTypes: [
                          AdditionalSectionLicenseOrPermissionType.PERMISSION,
                          AdditionalSectionLicenseOrPermissionType.LICENSE
                        ]
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-note-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-files-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-history-tab'
              }
            }
          ]
        }
      ]
    }
  ]
};

export default definition;
