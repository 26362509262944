import { useAppConfig } from '@libs/app-config';

function useNotificationConfig() {
  const { raw: appConfig } = useAppConfig();

  const { useCreateButton } = appConfig?.notification?.table || {};
  return { useCreateButton };
}

export default useNotificationConfig;
