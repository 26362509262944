import { useTranslation } from 'react-i18next';
import { Column, Hooks } from 'react-table';

import { Radio, useTableAdapter, useTableContext } from '@libs/common/v2';

import { ISpecialColumnEnum } from '../../../../model';

const SingleSelectColumn: Column = {
  id: ISpecialColumnEnum.SELECTION,
  Cell: ({ row: { isSelected, toggleRowSelected }, toggleAllRowsSelected }: any) => {
    const [t] = useTranslation();
    const table = useTableAdapter();
    const { checkDisabled, isError, isLoading, isFetching } = useTableContext();

    return (
      <Radio
        isChecked={isSelected}
        isDisabled={isLoading || isFetching || checkDisabled(table) || isError}
        inputProps={{
          'aria-label': t('other:component.table.body.checkRow')
        }}
        onChange={() => {
          toggleAllRowsSelected(false);
          toggleRowSelected(true);
        }}
        wrapperClassName="pl-2"
      />
    );
  },
  disableSortBy: true,
  width: 48
};
const getSingleSelectColumn = (handleOnClick: (arg: any) => void): Column => {
  return {
    id: ISpecialColumnEnum.SELECTION,
    Cell: ({ row: { isSelected, toggleRowSelected, ...rest }, toggleAllRowsSelected }: any) => {
      const [t] = useTranslation();
      const table = useTableAdapter();
      const { checkDisabled, isError, isLoading, isFetching } = useTableContext();

      return (
        <Radio
          isChecked={isSelected}
          isDisabled={isLoading || isFetching || checkDisabled(table) || isError}
          inputProps={{
            'aria-label': t('other:component.table.body.checkRow')
          }}
          onChange={() => {
            toggleAllRowsSelected(false);
            toggleRowSelected(true);
            handleOnClick?.(rest);
          }}
          wrapperClassName="pl-2"
        />
      );
    },
    disableSortBy: true,
    width: 48
  };
};

function useSingleSelectColumn<T extends Record<string, any> = Record<string, any>>(hooks: Hooks<T>) {
  hooks.visibleColumns.push(columns => [SingleSelectColumn, ...columns] as any);
}
export function useSingleSelectColumnWithHandleClick<T extends Record<string, any> = Record<string, any>>(
  handleClick: (row: any) => void,
  hooks: Hooks<T>
) {
  hooks.visibleColumns.push(columns => [getSingleSelectColumn(handleClick), ...columns] as any);
}

// Hook Factory (z możliwością definiowania własnej kolumny):
// const useSingleSelectColumn = createUseSingleSelectColumn(CustomColumn)
export const createUseSingleSelectColumn =
  <T extends Record<string, any> = Record<string, any>>(singleSelectColumn: Column) =>
  (hooks: Hooks<T>) => {
    hooks.visibleColumns.push(columns => [singleSelectColumn, ...columns] as any);
  };

useSingleSelectColumn.pluginName = 'useSingleSelectColumn';

export default useSingleSelectColumn;
