import { useTranslation } from 'react-i18next';
import { FolderListSnapshot, FolderSearchFilter, FolderSnapshot } from '@ibtm/domain';

import { CamelCasePath, SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO, getValue } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';
import { UserFilterV2 } from '@libs/user';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { FolderTypeChip } from '@libs/domain/folder';

import { AssociationFilter } from '../components/filter';

export const useFolderColumns = (visibleColumnIds?: Array<CamelCasePath<FolderSnapshot>>) => {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  const { createColumns } = useCreateColumns<FolderSnapshot & FolderListSnapshot>({
    pathToTranslate: 'folder:field'
  });

  const mappedFilterFields: TableFilterMapper<FolderListSnapshot, FolderSearchFilter> = {
    number: (number: string) => ({ numberContains: number }),
    typeKey: (typeKeys: SelectOption<string>[]) => ({ typeKeyIn: typeKeys.map(({ value }) => value) }),
    statusKey: (statusKeys: SelectOption<string>[]) => ({
      statusFilter: {
        values: statusKeys.map(({ value }) => value)
      }
    }),
    created: ({ dateFrom, dateTo }: DateRangeISO) => ({
      createdGreaterThanOrEqual: dateFrom,
      createdLessThanOrEqual: dateTo
    }),
    subjectNip: (nip: string) => ({ subjectNipContains: nip }),
    transferredToSta: (transferredToSta: string) => ({ transferredToStaContains: transferredToSta }),
    association: (associations: SelectOption<string>[]) => ({
      associationIdIn: associations.map(({ value }) => value)
    }),
    fullAddress: (address: string) => ({ fullAddress: address }),
    currentVerifierName: (verifier: string) => ({ verifierNameContains: verifier }),
    currentBorrowerName: (borrower: string) => ({ borrowerNameContains: borrower }),
    subjectName: (subjectNameFragment: string) => ({ subjectNameContains: subjectNameFragment }),
    authorName: (authorIdIn: SelectOption<string>[]) => ({ authorIdIn: authorIdIn.map(({ id }) => id) }),
    modified: ({ dateFrom, dateTo }: DateRangeISO) => ({
      modifiedGreaterThanOrEqual: dateFrom,
      modifiedLessThanOrEqual: dateTo
    }),
    modifierName: (modifierIdIn: SelectOption<string>[]) => ({ modifierIdIn: modifierIdIn.map(({ id }) => id) }),
    locked: (locks: SelectOption<boolean>) => ({ isLocked: locks.value })
  };

  const mappedSortFields: TableSortMapper<FolderSnapshot> = {
    currentBorrowerName: 'currentBorrower.name',
    currentVerifierName: 'currentVerifier.name',
    association: 'associationFolderNumber',
    primaryAddress: 'subject.mainAddress',
    author: 'author.name',
    modifierName: 'modifier.name',
    locked: 'locked',
    authorName: 'author.name'
  };

  const columns = createColumns(
    [
      {
        accessor: 'number',
        type: 'TEXT'
      },
      {
        id: 'typeKey',
        tooltipTitle: ({ typeKey }) => translate(DomainDictionaryEnum.FOLDER_TYPE, typeKey),
        customAccessor: ({ typeKey }) => <FolderTypeChip typeKey={typeKey ?? t('emptyMark')} />,
        customXlsxAccessor: ({ typeKey }) => translate(DomainDictionaryEnum.FOLDER_TYPE, typeKey),
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.FOLDER_TYPE
      },
      {
        accessor: 'subjectName',
        customAccessor: row => getValue(row?.subjectName || row?.subject?.name),
        type: 'TEXT',
        customXlsxAccessor: row => getValue(row?.subjectName || row?.subject?.name)
      },
      {
        accessor: 'subjectNip',
        customAccessor: row => getValue(row?.subjectNip || row?.subject?.nip),
        type: 'TEXT',
        customXlsxAccessor: row => getValue(row?.subjectNip || row?.subject?.nip)
      },
      {
        accessor: 'statusKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.FOLDER_STATUS
      },
      {
        accessor: 'locked',
        type: 'BOOLEAN'
      },
      {
        accessor: 'currentBorrower.name',
        type: 'TEXT'
      },
      {
        accessor: 'currentVerifier.name',
        type: 'TEXT'
      },
      {
        accessor: 'transferredToSta',
        type: 'TEXT'
      },
      {
        id: 'association',
        accessor: 'association.folderNumber',
        type: 'MULTI_SELECT',
        filter: AssociationFilter
      },
      {
        accessor: 'fullAddress',
        header: 'primaryAddress',
        type: 'TEXT'
      },
      {
        accessor: 'created',
        type: 'DATE'
      },
      {
        accessor: 'author.name',
        type: 'MULTI_SELECT',
        filter: UserFilterV2
      },
      {
        accessor: 'modified',
        type: 'DATE'
      },
      {
        accessor: 'modifier.name',
        type: 'MULTI_SELECT',
        filter: UserFilterV2
      }
    ],
    visibleColumnIds
  );

  return { columns, mappedFilterFields, mappedSortFields };
};
