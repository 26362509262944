import React, { useMemo } from 'react';

import { TabContent, TabsContent } from '@libs/common/v2';

import { ReportQueryKeysEnum } from '@libs/report/api';
import { DataSetContext } from '@libs/report/context/DataSetContext';

import ReportTypeDataSetContent from './ReportTypeDataSetContent';

interface IProps {
  tab: number;
  saveFieldList?: (tab: number, fields: string[]) => void;
}

const ReportTypeDataTabContent = React.memo(({ tab, saveFieldList }: IProps) => {
  const value = useMemo(() => ({ dataSetIndex: tab }), [tab]);
  return (
    <TabsContent
      value={tab}
      saveFieldList={saveFieldList}
      scrollerPrefixId={ReportQueryKeysEnum.REPORT_TYPE_FORM}
      isRelative={false}
    >
      <TabContent key={tab} value={tab} isAbsolute={false} isKeepMounted>
        <DataSetContext.Provider value={value}>
          <ReportTypeDataSetContent index={tab} />
        </DataSetContext.Provider>
      </TabContent>
    </TabsContent>
  );
});

export default ReportTypeDataTabContent;
