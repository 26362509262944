import * as Yup from 'yup';

export enum ProfileSectionEnum {
  GENERAL = 'general',
  PERMISSIONS = 'permissions',
  REPLACEMENTS = 'replacements'
}

export type ChangeProfilePasswordValidation = Yup.ObjectSchema<{
  oldPassword: string;
  newPassword1: string;
  newPassword2: string;
}>;
