import { InputMode } from '@libs/common/v2';

import {
  AdditionalSectionLicenseOrPermissionType,
  ApplicationMetaFormDefinition
} from '@libs/domain/application/model';

import { DomainDictionaryEntry } from '../../../../config';

const definition: ApplicationMetaFormDefinition = {
  fields: [
    {
      typeKey: 'DATETIME',
      fieldId: 'application-submission-date',
      properties: {
        label: 'Data wpływu',
        yupValidation: {
          required: {},
          dependent: {
            dependentFieldId: 'application-created-date',
            isCheckingMax: true,
            message: 'Data nie może być późniejsza, niż data wypełniania wniosku'
          }
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'submissionReceiptDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'submissionReceiptDate'
          }
        }
      }
    },
    {
      typeKey: 'LINE_TYPE_DICTIONARY_FIELD',
      fieldId: 'permissionApplicationDetails-personalNeedsApplicationDetails-lineTypeKey',
      properties: {
        visibleOptions: [DomainDictionaryEntry.TRANSIT_LINE_TYPE.PWR1],
        label: 'Typ linii'
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'permissionApplicationDetails-personalNeedsApplicationDetails-transportTypeKey',
      properties: {
        label: 'Rodzaj przewozu',
        dictionaryName: 'TRANSPORT_TYPE',
        yupValidation: {
          required: true
        },
        inputMode: InputMode.VIEW,
        defaultInitialValue: 'ibtm-domain.personal-needs-permission.transport-type.freight',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.personalNeedsDataUpdateRequest.transportTypeKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.personalNeedsApplicationDetails.transportTypeKey'
          }
        }
      }
    },
    {
      typeKey: 'NUMBER',
      fieldId: 'permissionApplicationDetails-personalNeedsApplicationDetails-vehicles',
      properties: {
        label: 'Liczba pojazdów/Liczba wypisów z zaświadczenia',
        yupValidation: {
          required: true
        },
        min: 0,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.personalNeedsDataUpdateRequest.vehicles'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.personalNeedsApplicationDetails.vehicles'
          }
        }
      }
    },
    {
      typeKey: 'BOOLEAN',
      fieldId: 'permissionApplicationDetails-personalNeedsApplicationDetails-employmentStatement',
      properties: {
        label: 'Zaświadczenie o zatrudnieniu',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.personalNeedsDataUpdateRequest.employmentStatement'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.personalNeedsApplicationDetails.employmentStatement'
          }
        },
        defaultInitialValue: false
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'permissionApplicationDetails-personalNeedsApplicationDetails-validityPeriodKey',
      properties: {
        label: 'Czas obowiązywania',
        dictionaryName: 'VALIDITY_PERIOD_FOR_OWN_NEEDS',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.personalNeedsDataUpdateRequest.validityPeriodKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.personalNeedsApplicationDetails.validityPeriodKey'
          }
        }
      }
    },
    {
      typeKey: 'SELECT_DATA_OF_APPLICANT',
      fieldId: 'permissionApplicationDetails-personalNeedsApplicationDetails-issuer',
      properties: {
        label: 'Dane składającego',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.personalNeedsDataUpdateRequest.issuer',
            formAccessor: 'id'
          },
          fetch: {
            requestKey: 'GET_COMPANY_PROXIES',
            accessor: null,
            optionTextMatch: '[name] [surname]'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.personalNeedsApplicationDetails.issuer'
          }
        }
      }
    }
  ],
  components: [],
  layout: [
    {
      typeKey: 'TAB_NAVIGATION',
      properties: {
        visibility: {
          elementVisibilityKeys: ['TAB_VISIBILITY']
        },
        defaultTabId: 'GENERAL',
        title: 'Sekcje',
        tabs: [
          {
            id: 'GENERAL',
            title: 'Dane podstawowe',
            icon: 'InfoIcon'
          },
          {
            id: 'SUBJECT',
            title: 'Podmiot',
            icon: 'FacadeIcon'
          },
          {
            id: 'ADDRESSES',
            title: 'Adresy',
            icon: 'MailboxIcon'
          },
          {
            id: 'PERMISSIONS',
            title: 'Uprawnienia',
            icon: 'KeyIcon'
          },
          {
            id: 'ATTACHMENTS',
            title: 'Załączniki',
            icon: 'PaperclipIcon'
          },
          {
            id: 'VEHICLES',
            title: 'Pojazdy',
            icon: 'TruckIcon'
          },
          {
            id: 'QUOTATION',
            title: 'Wycena',
            icon: 'CoinsIcon'
          },
          {
            id: 'OUTGOING_DOCUMENTS',
            title: 'Dokumenty wychodzące',
            icon: 'DocumentsIssuedIcon'
          },
          {
            id: 'NOTE',
            title: 'Notatka',
            icon: 'NoteIcon'
          }
        ]
      },
      nodes: [
        {
          typeKey: 'TABS_CONTENT',
          nodes: [
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-spo-own-needs-general-tab',
                additionalNodes: {
                  'application-section-after-submission-date': [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'permissionApplicationDetails-personalNeedsApplicationDetails-lineTypeKey'
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-subject-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-addresses-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-permissions-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-attachments-matrix-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-vehicles-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-quotation-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-outgoing-documents-tab',
                additionalNodes: {
                  'outgoing-documents-additional-section': [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'application-outgoing-documents-additional-section-license-or-permission',
                        sectionTypes: [
                          AdditionalSectionLicenseOrPermissionType.PERMISSION,
                          AdditionalSectionLicenseOrPermissionType.LICENSE,
                          AdditionalSectionLicenseOrPermissionType.DRIVER_CERTIFICATE
                        ]
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-note-tab'
              }
            }
          ]
        }
      ]
    }
  ]
};

export default definition;
