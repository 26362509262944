import { DocumentUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';
import { endpointsConfig } from '@libs/domain/documents';

const updateKreptdDocument = ({
  proceedingId,
  documentId,
  documentUpdateRequest
}: {
  proceedingId: string;
  documentId: string;
  documentUpdateRequest: DocumentUpdateRequest;
}) => {
  return API.proceedingsKreptd.updateProceedingKreptdDocument(proceedingId, documentId, documentUpdateRequest, {
    ...endpointsConfig.updateDocument
  });
};

function useUpdateKreptdDocumentMutation() {
  return useMutation(updateKreptdDocument);
}

export default useUpdateKreptdDocumentMutation;
