import { useTranslation } from 'react-i18next';
import { AddressDetails } from '@ibtm/domain';

import { BooleanValue, Button, GridLayout, Section, SectionTitle, Value } from '@libs/common/v2';

import { parsePostCode } from '@libs/domain/address';
import { checkIsPartnership, useFolderDetailsQuery } from '@libs/domain/folder';

import { useFetchPrintsApplicationDetails, usePrintTablesConfiguration } from '../../hooks';
import { useSubjectDataChangeDialog } from '../../hooks/useSubjectDataChangeDialog';

interface IProps {
  subjectName: string;
  subjectNip?: string;
  address: AddressDetails;
  withAsCivilPartnershipData?: boolean;
  isSameAsMainAddress?: boolean;
  isNipFieldVisible?: boolean;
  isLoading?: boolean;
}

function SubjectDataElement({
  subjectName,
  subjectNip,
  withAsCivilPartnershipData = false,
  address,
  isSameAsMainAddress,
  isLoading,
  isNipFieldVisible
}: IProps) {
  const [t] = useTranslation();

  return (
    <>
      {withAsCivilPartnershipData && (
        <GridLayout itemProps={{ xs: 12, sm: 6 }} containerProps={{ className: 'mb-4' }}>
          <BooleanValue label={t('address:fields.sameAsMainAddressPartnership')} value={isSameAsMainAddress} />
          {isSameAsMainAddress && isNipFieldVisible && (
            <Value label={t('prints:field.subjectNip')} isLoading={!subjectNip && isLoading}>
              {subjectNip}
            </Value>
          )}
        </GridLayout>
      )}
      {(!isSameAsMainAddress || !withAsCivilPartnershipData) && (
        <GridLayout itemProps={{ xs: 12, sm: 6 }}>
          <Value label={t('prints:field.detailsFields.subjectName')} isLoading={!subjectName && isLoading}>
            {subjectName}
          </Value>

          {isNipFieldVisible && (
            <Value label={t('prints:field.subjectNip')} isLoading={!subjectNip && isLoading}>
              {subjectNip}
            </Value>
          )}

          <Value label={t('prints:field.detailsFields.city')} isLoading={isLoading}>
            {address?.city}
          </Value>

          <Value label={t('prints:field.detailsFields.street')} isLoading={isLoading}>
            {address?.street}
          </Value>

          <Value label={t('prints:field.detailsFields.postCode')} isLoading={isLoading}>
            {parsePostCode(address?.postCode)}
          </Value>

          <Value label={t('prints:field.detailsFields.buildingNumber')} isLoading={isLoading}>
            {address?.propertyNumber}
          </Value>

          <Value label={t('prints:field.detailsFields.post')} isLoading={isLoading}>
            {address?.postCity}
          </Value>

          <Value label={t('prints:field.detailsFields.apartmentNumber')} isLoading={isLoading}>
            {address?.apartmentNumber}
          </Value>
        </GridLayout>
      )}
    </>
  );
}

function SubjectDataSection() {
  const { applicationData, printDetailsData, applicationSnapshot, query } = useFetchPrintsApplicationDetails();
  const { openSubjectDataChangeDialog } = useSubjectDataChangeDialog();

  const folderId = applicationSnapshot?.folder?.id || printDetailsData.folderId;
  const { data: folderData } = useFolderDetailsQuery(folderId, {
    enabled: Boolean(folderId),
    refetchOnMount: false
  });
  const [t] = useTranslation();
  const isCivilPartnership = checkIsPartnership(folderData?.subject?.legalFormKey);
  const config = usePrintTablesConfiguration();
  const { isLicense, isPermission } = config?.visiblePrintedPermissions ?? {};

  const isChangeDataButtonVisible = isCivilPartnership && (isLicense || isPermission);

  return (
    <Section
      title={t('prints:printsDetailsBlocks.subjectData')}
      isCollapsable
      headerActions={
        isChangeDataButtonVisible && (
          <Button
            label={t('prints:printsDetailsBlocks.subjectDataButtonChange')}
            variant="outlined"
            onClick={() => {
              openSubjectDataChangeDialog({
                isLicense,
                isPermission,
                licenseAddress: applicationData?.licenseAddress,
                permissionAddress: applicationData?.permissionAddress,
                licenseSubjectName: applicationData?.licenseSubjectName,
                licenseSubjectNip: applicationData?.licenseSubjectNip,
                permissionSubjectName: applicationData?.permissionSubjectName,
                permissionSubjectNip: applicationData?.permissionSubjectNip,
                licenseIsSameAsMainAddress: applicationData?.mainAddressOnLicense,
                permissionIsSameAsMainAddress: applicationData?.mainAddressOnPermission,
                applicationId: applicationSnapshot?.id
              });
            }}
          />
        )
      }
    >
      <GridLayout itemProps={{ xs: 12 }}>
        <SubjectDataElement
          subjectName={applicationSnapshot?.subject?.name}
          address={applicationData?.mainAddress}
          isLoading={query.isLoading}
        />
        {isCivilPartnership && isLicense && (
          <>
            <SectionTitle title={t('prints:printsDetailsBlocks.printLicenseData')} />
            <SubjectDataElement
              subjectName={applicationData?.licenseSubjectName}
              subjectNip={applicationData?.licenseSubjectNip}
              address={applicationData?.licenseAddress}
              isSameAsMainAddress={applicationData?.mainAddressOnLicense}
              isLoading={query.isLoading}
              withAsCivilPartnershipData
            />
          </>
        )}
        {isCivilPartnership && isPermission && (
          <>
            <SectionTitle title={t('prints:printsDetailsBlocks.printPermitData')} />
            <SubjectDataElement
              subjectName={applicationData?.permissionSubjectName}
              subjectNip={applicationData?.permissionSubjectNip}
              address={applicationData?.permissionAddress}
              isSameAsMainAddress={applicationData?.mainAddressOnPermission}
              isLoading={query.isLoading}
              withAsCivilPartnershipData
              isNipFieldVisible
            />
          </>
        )}
      </GridLayout>
    </Section>
  );
}

export default SubjectDataSection;
