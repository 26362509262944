import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { SingleUseSubmissionItemSnapshot } from '@ibtm/domain';
import moment from 'moment';

import {
  DatepickerField,
  Dialog,
  DialogContentContainer,
  GridLayout,
  typedNameV2,
  useDialog,
  useFormV2Context,
  useFormV2Watch
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useUpdateSingleUsePermissionSubmissionReceiptDateMutation } from '../../../api';
import { SocialCommissionQueryKeysEnum } from '../../../config/SocialCommissionFormConfig';

import { DateOfCollectionForm, DateOfCollectionFormProvider } from './DateOfCollectionFormProvider';

type TDialogProps = {
  closeDialog: () => void;
};

function DateOfCollectionDialog({ closeDialog }: TDialogProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const queryCache = useQueryCache();

  const { mutateAsync: updateDecision, isLoading } = useUpdateSingleUsePermissionSubmissionReceiptDateMutation();
  const { handleSubmit } = useFormV2Context();

  const onSubmit = handleSubmit(formData => {
    const { singleUseSubmissionItemId, receiptDateTo, receiptDateFrom } = formData as DateOfCollectionForm;
    return updateDecision(
      {
        singleUseSubmissionItemId,
        singleUseSubmissionUpdateReceiptDateRequest: {
          receiptDateFrom,
          receiptDateTo
        }
      },
      {
        onSuccess: () => {
          queryCache.invalidateQueries([SocialCommissionQueryKeysEnum.PERMANENT_PERMISSION_SUBMISSION_DETAILS]);
          showSuccessSnackbar(
            t(
              'foreignPermits:tabs.socialCommission.tabs.socialCommissionDisposablePermits.messages.dateOfCollectionUpdatedSuccess'
            )
          );
        },
        onSettled: () => {
          closeDialog();
        }
      }
    );
  });

  const receiptDateFrom = useFormV2Watch({
    name: typedNameV2<DateOfCollectionForm>('receiptDateFrom')
  }) as string;

  return (
    <Dialog
      title={t(
        'foreignPermits:tabs.socialCommission.tabs.socialCommissionDisposablePermits.dialog.editDateOfCollectionTitle'
      )}
      confirmText={t('action.save')}
      dialogSize="small"
      cancelText={t('action.cancel')}
      onConfirm={onSubmit}
      isConfirmLoading={isLoading}
      onCancel={closeDialog}
      isOpen
    >
      <DialogContentContainer>
        <GridLayout itemProps={{ xs: 12 }}>
          <DatepickerField
            name={typedNameV2<DateOfCollectionForm>('receiptDateFrom')}
            label={t(
              'foreignPermits:tabs.socialCommission.tabs.socialCommissionDisposablePermits.fields.receiptDateFrom'
            )}
            validation={{ required: [] }}
            isRequired
          />
          <DatepickerField
            name={typedNameV2<DateOfCollectionForm>('receiptDateTo')}
            label={t(
              'foreignPermits:tabs.socialCommission.tabs.socialCommissionDisposablePermits.fields.receiptDateTo'
            )}
            minDate={receiptDateFrom}
            validation={{
              required: [],
              test: [
                useMemo(
                  () => ({
                    name: 'isDateToAfterFromTo',
                    message: t(
                      'foreignPermits:tabs.socialCommission.tabs.socialCommissionDisposablePermits.validation.validTo'
                    ),
                    test: function checkIsDateToAfterFromToValidTo(validTo) {
                      // eslint-disable-next-line react/no-this-in-sfc
                      const dateForm = this.parent?.receiptDateFrom;
                      return !dateForm || moment(dateForm).isSameOrBefore(validTo);
                    }
                  }),
                  [t]
                )
              ]
            }}
            isRequired
          />
        </GridLayout>
      </DialogContentContainer>
    </Dialog>
  );
}

export function useDateOfCollectionDialog() {
  const { openDialog } = useDialog();

  const openDateOfCollectionDialog = (row: SingleUseSubmissionItemSnapshot) => {
    openDialog(({ closeDialog }) => (
      <DateOfCollectionFormProvider
        defaultValues={{
          singleUseSubmissionItemId: row.id,
          receiptDateFrom: row.receiptDateFrom,
          receiptDateTo: row.receiptDateTo
        }}
      >
        <DateOfCollectionDialog closeDialog={closeDialog} />
      </DateOfCollectionFormProvider>
    ));
  };

  return { openDateOfCollectionDialog };
}
