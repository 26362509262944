import { QueryConfig, useQuery } from 'react-query';
import { AdditionalServicesResponse } from '@ibtm/domain';

import { API } from '@libs/domain/api';
import { DocumentsIssuedQueryEnum } from '@libs/domain/documents-issued/model';

import { endpointsConfig } from '../endpoints-config';

const getGlsServices = async (_, postCode: string) => {
  const { data } = await API.gls.getAdditionalServices(
    { postCode },
    {
      ...endpointsConfig.getAdditionalServices
    }
  );

  return data;
};

export default function useGetGlsServicesListQuery(
  postCode: string,
  queryConfig?: QueryConfig<AdditionalServicesResponse, unknown>
) {
  return useQuery([DocumentsIssuedQueryEnum.GLS_SERVICES_LIST, postCode], getGlsServices, queryConfig);
}
