import { z } from 'zod';

enum EnvironmentNameEnum {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production'
}

const defaultConfigScheme = z.object({
  env: z.object({
    name: z.string()
  }),
  api: z.object({
    baseUrl: z.string().url()
  }),
  app: z.object({
    captchaKey: z.string(),
    showCaptcha: z.boolean(),
    mainPage: z.string(),
    disableAutoLogout: z.boolean().optional(),
    autoLogoutMinutesTime: z.number().optional(),
    areEkmtLimitsHidden: z.boolean().optional(),
    areSingleAuthorizationLimitsHidden: z.boolean().optional(),
    generateApplicationPrintouts: z.boolean().optional()
  }),
  administration: z
    .object({
      resendEmailConfirmationVisible: z.boolean().optional()
    })
    .optional(),
  mockUser: z
    .object({
      login: z.string().optional(),
      password: z.string().optional()
    })
    .optional(),
  common: z.object({
    table: z.object({
      hideColumnPicker: z.boolean(),
      hideCompactToggle: z.boolean()
    }),
    topBar: z.object({
      showNotifications: z.boolean(),
      showSystemNotifications: z.boolean()
    }),
    footer: z.object({
      hideVersionPrefix: z.boolean().optional(),
      hidePortalOwner: z.boolean().optional(),
      isBuildVersionVisible: z.boolean().optional(),
      showFooterAfterLogin: z.boolean().optional()
    })
  })
});

const operatorPortalConfigScheme = z.object({
  api: z.object({
    baseUrl: z.string().url(),
    webSocketUrl: z.string().url()
  }),
  app: z.object({
    captchaKey: z.string(),
    showCaptcha: z.boolean(),
    showDictionaryKeys: z.boolean(),
    showReactQueryDevtools: z.boolean().optional(),
    mainPage: z.string(),
    disableAutoLogout: z.boolean().optional(),
    autoLogoutMinutesTime: z.number().optional(),
    notificationsUpdateSeconds: z.number().optional(),
    isPermissionKeys: z.boolean().optional(),
    defaultDevMode: z.boolean().optional(),
    superusers: z.string().array().optional(),
    shouldFetchNotificationsFromWebsockets: z.boolean().optional(),
    shouldFetchNotificationsOutsideWebsockets: z.boolean().optional()
  }),
  common: z.object({
    table: z.object({
      hideColumnPicker: z.boolean(),
      hideCompactToggle: z.boolean()
    }),
    topBar: z.object({
      showNotifications: z.boolean(),
      showSystemNotifications: z.boolean(),
      showDevModeSwitch: z.boolean()
    }),
    footer: z.object({
      hideVersionPrefix: z.boolean().optional(),
      hidePortalOwner: z.boolean().optional(),
      isBuildVersionVisible: z.boolean().optional()
    })
  }),
  report: z
    .object({
      isReportTypeManagementEnable: z.boolean(),
      reportTypes: z.object({
        // Wydanie ŚK
        issuingSk: z.object({
          reportTypeName: z.string(),
          targetParameters: z.object({}).optional()
        }),
        // Ważne ŚK
        upToDateSk: z.object({ reportTypeName: z.string(), targetParameters: z.object({}) }),
        // Ważne ŚK firma
        upToDateSkCompany: z.object({
          reportTypeName: z.string(),
          targetParameters: z
            .object({
              folderId: z.string().optional(),
              nip: z.string().optional(),
              company: z.string().optional()
            })
            .optional()
        }),
        // Wydanie ŚK
        issuingSkCompany: z.object({
          reportTypeName: z.string(),
          targetParameters: z
            .object({
              folderId: z.string().optional(),
              nip: z.string().optional(),
              company: z.string().optional()
            })
            .optional()
        }),
        // Zestawienie stanów magazynowych
        resourcesStateOverview: z.object({
          reportTypeName: z.string(),
          targetParameters: z
            .object({
              resource: z.string().optional()
            })
            .optional()
        }),
        // Zestawienie z danymi z rozliczenia zezwoleń ekmt
        ekmtDataSummary: z.object({
          reportTypeName: z.string(),
          targetParameters: z.object({
            year: z.string().optional()
          })
        }),
        // Spis zdawczo-zbiorczy akt
        deliveryAndSummaryListOfFiles: z.object({ reportTypeName: z.string(), targetParameters: z.object({}) }),
        // Spis spraw
        listOfCases: z.object({ reportTypeName: z.string(), targetParameters: z.object({}) }),
        // Zestawienie przedsiębiorców występujących jako partner/podwykonawca
        listOfPartners: z.object({ reportTypeName: z.string(), targetParameters: z.object({}) }),
        // Raport o pojazdach
        aboutVehicles: z.object({
          reportTypeName: z.string(),
          targetParameters: z
            .object({
              folderId: z.string().optional(),
              nip: z.string().optional(),
              company: z.string().optional()
            })
            .optional()
        }),
        // Zwrócone i niezwrócone zezwolenia
        returnedAndUnreturnedPermits: z.object({ reportTypeName: z.string(), targetParameters: z.object({}) }),
        companyArchiveDeliveryAndSummaryListOfFiles: z
          .object({
            reportTypeName: z.string(),
            targetParameters: z.object({})
          })
          .optional()
      })
    })
    .optional(),
  external: z.object({
    auditServiceUrl: z.string().url(),
    processesServiceUrl: z.string().url()
  }),
  notification: z
    .object({
      table: z.object({
        useCreateButton: z.boolean()
      }),
      notificationsUpdateSeconds: z.number().positive().optional()
    })
    .optional(),
  prints: z
    .object({
      defaultDepotNumber: z.number().positive().optional(),
      showLocksSummaryAction: z.boolean().optional(),
      showFormsNumbersInLocksSummary: z.boolean().optional()
    })
    .optional(),
  templates: z
    .object({
      names: z.object({
        expirationsGitd: z.string(),
        courierList: z.string(),
        transferFolder: z.string(),
        WZZCorrection: z.string(),
        WWUCorrection: z.string(),
        defaultCorrection: z.string(),
        releaseDocumentsConfirm: z.string(),
        vehicleList: z.string(),
        transitSchedulesDates: z.string(),
        transitSchedules: z.string()
      })
    })
    .optional()
});

const extendedConfigScheme = defaultConfigScheme.merge(operatorPortalConfigScheme);

export type AppConfigScheme = z.infer<typeof defaultConfigScheme & typeof extendedConfigScheme>;

const parseAppConfigScheme = (data, isClientPortalConfigScheme): string | null => {
  const result = isClientPortalConfigScheme
    ? defaultConfigScheme.safeParse(data)
    : extendedConfigScheme.safeParse(data);
  // Trzeba bezpośrednio sprawdzić bool'a inaczej zod traci otypowanie `error`
  if (result.success === true) {
    return null;
  }

  const formattedIssues = result.error.issues.reduce((acc, issue, idx) => {
    const index = idx + 1;
    const path = issue.path.join('.');
    let suffix = '?';

    if (issue.code === 'invalid_type') {
      suffix = issue.received;
    }

    return `${acc}\n ${index}. [${issue.message}] ${path} -> ${suffix}`;
  }, '');

  const errorMessage = `Failed to parse app config: ${formattedIssues}`;
  // eslint-disable-next-line no-console
  console.error(errorMessage);

  return errorMessage;
};

export { parseAppConfigScheme, EnvironmentNameEnum };
