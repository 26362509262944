import { ResourceObjectDisposalSearchFilter } from '@ibtm/domain';

import { SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO, getCalendarDate, TAmountRange } from '@libs/common/v2/utils';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { IDisposalClient } from '../../model';

const useDisposalsColumns = () => {
  const { createColumns } = useCreateColumns<IDisposalClient>({
    pathToTranslate: 'resource:fields'
  });

  const mappedSortFields: TableSortMapper<IDisposalClient> = {
    name: 'type.nameKey',
    country: 'type.countryKey',
    disposalReason: 'disposalReasonKey',
    number: 'number',
    disposalDate: 'disposalDate'
  };

  const mappedFilterFields: TableFilterMapper<IDisposalClient, ResourceObjectDisposalSearchFilter> = {
    year: (yearRange: TAmountRange) => ({
      yearGreaterThanOrEqual: yearRange.min,
      yearLessThanOrEqual: yearRange.max
    }),
    disposalDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      disposalDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      disposalDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    country: (countries: SelectOption<string>[]) => ({ countryKeyIn: countries.map(country => country.value) }),
    name: (names: SelectOption<string>[]) => ({ nameKeyIn: names.map(name => name.value) }),
    disposalReason: (disposalReasons: SelectOption<string>[]) => ({
      disposalReasonKeyIn: disposalReasons.map(disposalReason => disposalReason.value)
    }),
    number: number => ({ numberContains: number })
  };

  const columns = createColumns([
    {
      id: 'year',
      type: 'NUMBER_RANGE'
    },
    {
      id: 'country',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.COUNTRY_CODE
    },
    {
      id: 'name',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_NAME,
      width: 300
    },
    { id: 'number', type: 'TEXT', header: 'formNumber' },
    {
      id: 'disposalDate',
      type: 'DATE'
    },
    {
      id: 'disposalReason',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_DISPOSAL_REASON,
      width: 250
    }
  ]);

  return { columns, mappedFilterFields, mappedSortFields };
};

export default useDisposalsColumns;
