import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { partialTranslate } from '@libs/common';
import { DropdownButton, SelectOption, typedNameV2, useFormV2Context } from '@libs/common/v2';
import { IButtonAction } from '@libs/common/v2/components/button/DropdownButton';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DomainDictionaryEntry, DomainUIElementEnum } from '@libs/domain/config';
import {
  ProceedingDetailsClient,
  ProceedingDetailsParams,
  ProceedingTypeKeyEnum,
  useProceedingDetailsQuery
} from '@libs/domain/proceeding';

import { useDropdownActions } from './hooks/useDropdownActions';

function HeaderContent() {
  const { id, proceedingTypeKey } = useParams<ProceedingDetailsParams>();
  const { data, refetch } = useProceedingDetailsQuery(id, { enabled: !!id });
  const { watch, formMode } = useFormV2Context();
  const { editMode } = useViewModesV2(formMode);
  const getButtonLabel = partialTranslate('proceeding:administrative.tab.procedureDetails.action');

  const { handleDelete, handleFinish, handleCancel, handleAddAdministrativeProceeding } = useDropdownActions(refetch);

  const statusKey = watch(typedNameV2<ProceedingDetailsClient>('statusKey')) as SelectOption<string>;

  const categoryKey = useMemo(() => {
    return data?.categoryKey;
  }, [data]);

  const addAdministrativeProceedingButtonVisible = useMemo(() => {
    return (
      proceedingTypeKey === ProceedingTypeKeyEnum.VERIFICATION &&
      statusKey?.value === DomainDictionaryEntry.PROCEEDING_STATUS.VERIFIED
    );
  }, [proceedingTypeKey, statusKey]);

  const actionButtons: IButtonAction[] = useMemo(() => {
    return [
      {
        label: getButtonLabel('delete'),
        onClick: handleDelete,
        actionKey: DomainUIElementEnum.PROCEEDING_EDIT_DELETE_BUTTON
      },
      {
        label: getButtonLabel('finish'),
        onClick: handleFinish,
        actionKey: DomainUIElementEnum.PROCEEDING_EDIT_END_BUTTON,
        isHidden: !editMode || statusKey?.value === DomainDictionaryEntry.PROCEEDING_STATUS.VERIFIED
      },
      {
        label: getButtonLabel('cancel'),
        onClick: handleCancel,
        actionKey: DomainUIElementEnum.PROCEEDING_EDIT_CANCEL_BUTTON,
        isHidden: statusKey?.value === DomainDictionaryEntry.PROCEEDING_STATUS.CANCELLED
      },
      {
        label: getButtonLabel('addAdministrativeProceeding'),
        onClick: () => handleAddAdministrativeProceeding(categoryKey),
        isHidden: !addAdministrativeProceedingButtonVisible
      }
    ];
  }, [
    addAdministrativeProceedingButtonVisible,
    categoryKey,
    editMode,
    handleAddAdministrativeProceeding,
    handleCancel,
    handleDelete,
    handleFinish,
    statusKey?.value,
    getButtonLabel
  ]);

  return <DropdownButton actionButtons={actionButtons} />;
}

export default HeaderContent;
