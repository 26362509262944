import React from 'react';
import { Theme, Typography as MuiTypography, TypographyProps as MuiTypographyProps, useTheme } from '@mui/material';
import { styled } from '@mui/styles';

import { Theme as CustomTheme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import { ThemeVariant } from '../themeVariants';

interface IProps extends MuiTypographyProps {
  children?: string | React.ReactNode;
  themeVariant?: ThemeVariant;
  customStyles?: React.CSSProperties & { [_: string]: unknown };
  component?: React.ElementType;
  typographyClassName?: string;
  isWhiteText?: boolean;
}

function TypographyFactory(props: IProps) {
  const {
    themeVariant,
    customStyles,
    children,
    component = 'h1',
    isWhiteText,
    typographyClassName,
    ...forwardProps
  } = props;

  const customTheme = useTheme<CustomTheme & Theme>();
  const [version, type] = themeVariant!.split('.');
  const CustomTypography = styled(MuiTypography)(({ theme }: { theme: CustomTheme }) => ({
    color: isWhiteText ? theme.palette.white : theme.palette.grey[700],
    visibility: important('visible'),
    ...theme.typography[version][type],
    ...(customStyles ?? null)
  })) as React.ElementType;

  return (
    <CustomTypography className={typographyClassName} component={component} theme={customTheme} {...forwardProps}>
      {children}
    </CustomTypography>
  );
}

export default function Typography(props: IProps) {
  const { themeVariant, children, ...forwardProps } = props;

  if (themeVariant) return <TypographyFactory {...props} />;
  return <MuiTypography {...forwardProps}>{children}</MuiTypography>;
}
