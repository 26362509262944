enum ReportUIElementEnum {
  REPORT_DOWNLOAD_BUTTON = 'REPORT_DOWNLOAD_BUTTON',
  REPORT_EDIT_BUTTON = 'REPORT_EDIT_BUTTON',
  REPORT_DELETE_BUTTON = 'REPORT_DELETE_BUTTON',
  REPORT_GENERATE_BUTTON = 'REPORT_GENERATE_BUTTON',
  REPORT_DETAILS_BUTTON = 'REPORT_DETAILS_BUTTON',
  REPORT_LIST = 'REPORT_LIST',
  REPORT_TYPE_SAVE_BUTTON = 'REPORT_TYPE_SAVE_BUTTON',
  REPORT_TYPE_DELETE_BUTTON = 'REPORT_TYPE_DELETE_BUTTON',
  REPORT_LIST_GENERATED_VIEW = 'REPORT_LIST_GENERATED_VIEW',
  REPORT_GENERATE_AGAIN_BUTTON = 'REPORT_GENERATE_AGAIN_BUTTON'
}

export default ReportUIElementEnum;
