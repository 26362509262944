import React from 'react';

import { ConditionOperator } from '@libs/report-core';

interface IProps {
  value: ConditionOperator;
  keepLowerCase?: boolean;
  showSelectedFirst?: boolean;
  readonly?: boolean;
  onChange: (value: string) => void;
  children: JSX.Element | JSX.Element[];
}

function ButtonSwitchGroup(props: IProps) {
  const { children, keepLowerCase, showSelectedFirst, readonly, value, onChange } = props;
  const buttons = React.Children.map(children, child =>
    React.cloneElement(child, {
      keepLowerCase,
      isSelected: child.props.value === value,
      readonly,
      onClick: () => onChange(child.props.value)
    })
  );
  const sortedButtons = showSelectedFirst ? buttons.sort(child1 => (child1.props.isSelected ? -1 : 1)) : buttons;
  return <div>{sortedButtons}</div>;
}

export default ButtonSwitchGroup;
