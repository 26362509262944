import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ResourceObjectClientReceiveRequest } from '@ibtm/client-domain';
import * as Yup from 'yup';

import { Dialog, FormV2Context, useIsSmallScreen } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { ResourceQueryKeysEnum, useReceiveMutation } from '../../../api';
import { usePoolsValidationSchema } from '../../../hooks';
import { FormTransferFields, IResourceObjectAssociationPoolClient } from '../../../model';
import { PoolsForm } from '../common';

interface IProps {
  resourceObjects: IResourceObjectAssociationPoolClient[];
  closeDialog: () => void;
}

function ReceiveForm({ resourceObjects, closeDialog }: IProps) {
  const { isSmallScreen } = useIsSmallScreen();
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { mutate: receive, isLoading } = useReceiveMutation();

  const poolsValidationSchema = usePoolsValidationSchema(resourceObjects);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Partial<FormTransferFields>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        pools: poolsValidationSchema
      })
    ),
    defaultValues: {
      pools: resourceObjects
    }
  });

  const onSubmit = (formData: Partial<FormTransferFields>) => {
    const requestData: ResourceObjectClientReceiveRequest = {
      resources: formData.pools.map((item, index) => ({
        numberFrom: item.numberFrom,
        numberTo: item.numberTo,
        amount: item.amount,
        resourceTypeId: resourceObjects[index].resourceType?.id
      }))
    };

    receive(requestData, {
      onSuccess: () => {
        showSnackbar('success', t('resource:messages.receiveSuccess'));
        queryCache.invalidateQueries(ResourceQueryKeysEnum.RESOURCE_OBJECT_ASSOCIATION_POOLS);
        closeDialog();
      }
    });
  };

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('resource:dialog.receiveDialogTitle')}
      confirmText={t(isSmallScreen ? 'resource:actions.confirmReceiveMobile' : 'resource:actions.confirmReceive')}
      cancelText={t('action.cancel')}
      onConfirm={() => handleSubmit(onSubmit)()}
      onCancel={closeDialog}
      isConfirmLoading={isLoading}
      dialogSize="large"
      isFullScreen={isSmallScreen}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <PoolsForm />
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default ReceiveForm;
