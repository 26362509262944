import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DICTIONARY_IBTM_DOMAIN_PREFIX } from '@libs/config/constants';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  DictionarySelectField,
  GridItem,
  GridLayout,
  Section,
  SwitchField,
  TextInputField,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { IDriverForm } from '@libs/domain/drivers/model';

const getLabel = partialTranslate('drivers:fields');

function DriverLicense() {
  const [t] = useTranslation();
  const { watch, setValue, formMode } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);

  const issuingCountryKey = watch(typedNameV2<IDriverForm>('driverLicense.issuingCountryKey')) as string;
  const indefinite = watch(typedNameV2<IDriverForm>('driverLicense.indefinite')) as boolean;
  const qualifications = watch(typedNameV2<IDriverForm>('driverLicense.qualifications')) as boolean;

  useEffect(() => {
    setValue('driverLicense.issuingAuthority', issuingCountryKey);
  }, [issuingCountryKey, setValue]);

  useEffect(() => {
    if (indefinite) {
      setValue('driverLicense.expirationDate', null);
    }
  }, [indefinite, setValue]);

  return (
    <Section title={t('drivers:sections.driverLicense')} isCollapsable isModalSection>
      <GridLayout itemProps={{ xs: 12, sm: 6 }}>
        {viewMode &&
        typeof issuingCountryKey === 'string' &&
        !issuingCountryKey?.startsWith(DICTIONARY_IBTM_DOMAIN_PREFIX) ? (
          <TextInputField
            name={typedNameV2<IDriverForm>('driverLicense.issuingCountryKey')}
            label={getLabel('driverLicense.issuingCountryKey')}
            isRequired
          />
        ) : (
          <DictionarySelectField
            name={typedNameV2<IDriverForm>('driverLicense.issuingCountryKey')}
            label={getLabel('driverLicense.issuingCountryKey')}
            dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
            isRequired
          />
        )}
        {viewMode &&
        typeof issuingCountryKey === 'string' &&
        !issuingCountryKey?.startsWith(DICTIONARY_IBTM_DOMAIN_PREFIX) ? (
          <TextInputField
            name={typedNameV2<IDriverForm>('driverLicense.issuingAuthority')}
            label={getLabel('driverLicense.issuingPlaceKey')}
            isRequired
          />
        ) : (
          <DictionarySelectField
            name={typedNameV2<IDriverForm>('driverLicense.issuingAuthority')}
            label={getLabel('driverLicense.issuingPlaceKey')}
            dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
            isRequired
            isDisabled
          />
        )}
        <TextInputField
          inputProps={{ maxLength: 20 }}
          name={typedNameV2<IDriverForm>('driverLicense.seriesNumber')}
          label={getLabel('driverLicense.seriesNumber')}
          isRequired
        />
        <DatepickerField
          name={typedNameV2<IDriverForm>('driverLicense.issuingDate')}
          label={getLabel('driverLicense.issuingDate')}
          isRequired
        />
        <SwitchField
          name={typedNameV2<IDriverForm>('driverLicense.indefinite')}
          label={getLabel('driverLicense.indefinite')}
        />
        <DatepickerField
          name={typedNameV2<IDriverForm>('driverLicense.expirationDate')}
          label={getLabel('driverLicense.expirationDate')}
          isDisabled={indefinite}
          isRequired={!indefinite}
        />
        <SwitchField
          name={typedNameV2<IDriverForm>('driverLicense.qualifications')}
          label={getLabel('driverLicense.qualifications')}
        />
        {qualifications && (
          <DictionarySelectField
            name={typedNameV2<IDriverForm>('driverLicense.code')}
            label={getLabel('driverLicense.code')}
            dictionaryName={DomainDictionaryEnum.COMPETENCE}
            isRequired={qualifications}
          />
        )}
        {qualifications && (
          <GridItem xs={12} className="flex" justifyContent="flex-end">
            <GridItem className="w-1/2 pl-12">
              <DatepickerField
                name={typedNameV2<IDriverForm>('driverLicense.expiryDateCompetenceCard')}
                label={getLabel('driverLicense.expiryDateCompetenceCard')}
                isDisabled={!qualifications}
                isClearable
              />
            </GridItem>
          </GridItem>
        )}
      </GridLayout>
    </Section>
  );
}

export default DriverLicense;
