import { useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { FormHelperText } from '@mui/material';

import { ComponentErrorBoundary, useFormV2Context } from '@libs/common/v2';

import { useInputStyles } from '../../../components';

interface IProps {
  name: string;
  captchaKey: string;
  setRecaptchaField?: (ref: ReCAPTCHA) => void;
}

function ReCaptchaField({ name, captchaKey, setRecaptchaField }: IProps) {
  const recaptchaRef = useRef(null);
  const { control } = useFormV2Context();
  const classes = useInputStyles({ isError: true });

  useEffect(() => {
    if (recaptchaRef?.current) {
      setRecaptchaField?.(recaptchaRef.current);
    }
  }, [setRecaptchaField, recaptchaRef]);

  return (
    <ComponentErrorBoundary componentName={name || 'ReCaptchaField'}>
      <Controller
        control={control}
        name={name as FieldPath<FieldValues>}
        render={({ field: { onChange }, fieldState: { error } }) => {
          return (
            <div>
              <ReCAPTCHA ref={recaptchaRef} sitekey={captchaKey} onChange={onChange} hl="pl" />
              <FormHelperText className={classes.formHelperText} error={!!error?.message}>
                {error?.message}
              </FormHelperText>
            </div>
          );
        }}
      />
    </ComponentErrorBoundary>
  );
}

export default ReCaptchaField;
