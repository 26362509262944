import moment, { Moment } from 'moment';

const addMissingZeroTimezone = (value: string) => {
  const hasZeroTimezone = value?.at(-1) === 'Z';
  if (hasZeroTimezone) {
    return value;
  }

  return `${value}Z`;
};

export const dateWithoutOffset = (date?: Moment, isEndOfDay?: boolean) => {
  const formattedDate: string = moment(date).toISOString(true);

  if (!date || !formattedDate) {
    return null;
  }

  let offsetDate;
  if (isEndOfDay) {
    offsetDate = moment(date).endOf('day').toISOString()?.split?.('+')?.[0];
  } else {
    offsetDate = moment(date).startOf('day').toISOString()?.split?.('+')?.[0];
  }

  return addMissingZeroTimezone(offsetDate);
};
