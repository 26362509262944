import React from 'react';
import { SubjectLiteExtended } from '@ibtm/client-domain';

export interface SelectPartnershipRoutingGuardContextState {
  selectedPartnership: SubjectLiteExtended;
  setSelectedPartnership: (data: SubjectLiteExtended) => void;
}

const PartnershipSelectionRoutingGuardContext = React.createContext<SelectPartnershipRoutingGuardContextState>({
  selectedPartnership: null,
  setSelectedPartnership: null
});

export default PartnershipSelectionRoutingGuardContext;
