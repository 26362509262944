import { useSelector } from 'react-redux';
import { combineReducers } from 'redux';

import { TypedSelector } from '@libs/common/v2/utils';

import login, { InitialLoginState } from './login.reducer';
import user, { InitialUserState } from './user.reducer';

export const useAuthSelector = useSelector as TypedSelector<{
  auth: {
    user: InitialUserState;
    login: InitialLoginState;
  };
}>;

const authReducers = combineReducers({
  user,
  login
});

export default authReducers;
