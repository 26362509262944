import { DocumentTemplateDetailsExternal, DocumentTemplateEditRequest } from '@avispon/document-generator/dist/models';

import { DocumentTemplateForm } from '@libs/document-template/model';

export const DocumentTemplateEditConverter = {
  apiToForm: (documentTemplate: DocumentTemplateDetailsExternal): DocumentTemplateForm => ({
    name: documentTemplate.name,
    description: documentTemplate.description,
    groupKeys: Array.from(documentTemplate?.groupKeys),
    targetMimeType: documentTemplate.targetMimeType,
    templateFile: null,
    typeKey: documentTemplate.typeKey,
    key: documentTemplate.key,
    externalVisibility: documentTemplate.externalVisibility
  }),

  formToApi: (formValues: DocumentTemplateForm, version: number): DocumentTemplateEditRequest => ({
    ...formValues,
    version
  })
};
