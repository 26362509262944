import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleSnapshot } from '@ibtm/domain';

import { TableLayout, useRouter, useTableRowActions } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DomainUIElementEnum } from '@libs/domain/config';
import { SCertificatesSnapshot } from '@libs/domain/s-certificates';

import { useCertificateVehicleTabContext } from '../../hooks';
import { MoveVehicleButton } from '../buttons';

interface IProps {
  useTable: (folderId: string, transferredFoldersIds?: string[], viewMode?: boolean) => any;
}

function CertificateVehiclesTable({ useTable }: IProps) {
  const [t] = useTranslation();
  const { viewMode } = useViewModesV2();
  const { folderId, issuingCertificate, transferredFoldersIds } = useCertificateVehicleTabContext();
  const tableProps = useTable(folderId, transferredFoldersIds, viewMode);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const visibleButtons = [
    DomainUIElementEnum.APPLICATION_S_CERTIFICATES_SHOW_DETAILS_BUTTON,
    DomainUIElementEnum.APPLICATION_S_CERTIFICATES_ADD_VEHICLE_FROM_FOLDER_TO_APPLCIATION_BUTTON
  ];

  const { routes } = useRouter();

  const onVehicleMoved = () => {
    setIsLoading(false);
  };

  const onMoveVehicleButtonClick = () => {
    setIsLoading(true);
  };

  const { renderTableActions: vehicleQueryTableActions } = useTableRowActions<VehicleSnapshot>(
    [
      {
        id: DomainUIElementEnum.APPLICATION_S_CERTIFICATES_SHOW_DETAILS_BUTTON,
        label: 'action.openDetails',
        link: ({ original }) => routes.sCertificateDetails(original.certificateSId),
        icon: 'ArrowIcon',
        isDisabled: ({ row }) => !row.original.certificateSId,
        isDisabledLabel: 'applications:certificates.disabledDetails'
      },
      {
        id: DomainUIElementEnum.APPLICATION_S_CERTIFICATES_ADD_VEHICLE_FROM_FOLDER_TO_APPLCIATION_BUTTON,
        customButton: ({ original }) =>
          viewMode ? null : (
            <MoveVehicleButton
              onActionStart={onMoveVehicleButtonClick}
              onSettled={onVehicleMoved}
              selectedIds={[original.id]}
              isLoading={isLoading}
              isIconButton
            />
          )
      }
    ],
    [isLoading]
  );

  const { renderTableActions: certificateQueryTableActions } = useTableRowActions<SCertificatesSnapshot>(
    [
      {
        id: DomainUIElementEnum.APPLICATION_S_CERTIFICATES_SHOW_DETAILS_BUTTON,
        label: 'action.openDetails',
        link: ({ original }) => routes.sCertificateDetails(original.id),
        icon: 'ArrowIcon',
        isDisabled: ({ row }) => !row.original.id,
        isDisabledLabel: 'applications:certificates.disabledDetails'
      },
      {
        id: DomainUIElementEnum.APPLICATION_S_CERTIFICATES_ADD_VEHICLE_FROM_FOLDER_TO_APPLCIATION_BUTTON,
        customButton: ({ original }) =>
          viewMode ? null : (
            <MoveVehicleButton
              onActionStart={onMoveVehicleButtonClick}
              onSettled={onVehicleMoved}
              selectedIds={[original?.vehicleId]}
              isLoading={isLoading}
              isIconButton
            />
          )
      }
    ],
    [isLoading]
  );

  return (
    <TableLayout
      {...tableProps}
      pageTitle={
        issuingCertificate
          ? t('applications:certificates.title')
          : t('applications:certificates.certifiactesInFolderTitle')
      }
      rowActions={
        issuingCertificate ? vehicleQueryTableActions(visibleButtons) : certificateQueryTableActions(visibleButtons)
      }
      paginationProps={{ isSelectRowsCountDisabled: true }}
      isSection
    />
  );
}

export default CertificateVehiclesTable;
