import { useCallback } from 'react';
import { FolderPermissionSnapshot } from '@ibtm/domain';
import { isEmpty } from 'lodash';

import { ColumnTypesEnum, useCreateColumns } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

function useFormOperationActivePermissionsColumns() {
  const { translate } = useDictionaryTranslations();
  const { createColumns } = useCreateColumns<FolderPermissionSnapshot>({
    pathToTranslate: 'proceeding:administrative.tab.formOperations.field'
  });

  const getPermissionLicenseColumnAccessor = useCallback(
    (value: string) => {
      const licenseTypeKey = translate(DomainDictionaryEnum.LICENSE_TYPE, value);

      if (isEmpty(licenseTypeKey)) {
        return translate(DomainDictionaryEnum.PERMISSION_TYPE, value);
      }

      return licenseTypeKey;
    },
    [translate]
  );

  const columns = createColumns(
    [
      {
        type: ColumnTypesEnum.TEXT,
        accessor: 'formNumber',
        isFilterable: false
      },
      {
        header: 'typeKey',
        type: ColumnTypesEnum.CUSTOM_COLUMN,
        customAccessor: ({ typeKey }) => getPermissionLicenseColumnAccessor(typeKey),
        isFilterable: false
      }
    ],
    null
  );

  return { columns };
}

export default useFormOperationActivePermissionsColumns;
