import { CardContentProps } from '@mui/material/CardContent';

import { Section } from '../section';

import CardContent from './CardContent';

interface IProps {
  text?: string;
  isSection?: boolean;
}

function EmptyCardContent({ text, isSection }: IProps & CardContentProps) {
  return isSection ? (
    <Section>
      <CardContent text={text} />
    </Section>
  ) : (
    <CardContent text={text} />
  );
}

export default EmptyCardContent;
