import { i18n, KeyType } from '@libs/common';
import { DatepickerField, GridItem, GridLayout, InputMode, Section, useFormV2Context } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DropdownDateInput, SocialCommissionAddMeetingTypeConfig } from '@libs/domain/social-commission';
import {
  SocialCommissionAddMeetingFormConfig,
  SocialCommissionAddMeetingFormDropdownConfig
} from '@libs/domain/social-commission/config/SocialCommissionAddMeetingFormConfig';

function AddMeetingForm() {
  const { formMode } = useFormV2Context();
  const { editMode, createMode } = useViewModesV2(formMode);
  const inputMode = editMode || createMode ? InputMode.FORM : InputMode.VIEW;

  return (
    <Section isModalSection>
      <GridLayout containerProps={{ marginBottom: '12px' }}>
        {SocialCommissionAddMeetingFormDropdownConfig.map(
          (item: SocialCommissionAddMeetingTypeConfig, index: number) => {
            const keyIndex = `${item?.name}_${index}`;
            return (
              <GridItem xs={6} key={keyIndex}>
                <DropdownDateInput {...item} isRequired />
              </GridItem>
            );
          }
        )}
      </GridLayout>
      <GridLayout>
        {SocialCommissionAddMeetingFormConfig.map(
          ({ name, label, required, isDisabled }: SocialCommissionAddMeetingTypeConfig, index: number) => {
            const keyIndex = `${name}_${index}`;
            return (
              <GridItem xs={6} key={keyIndex}>
                <DatepickerField
                  name={name}
                  inputMode={inputMode}
                  label={i18n.t(label as KeyType)}
                  isRequired={required}
                  isDisabled={isDisabled}
                />
              </GridItem>
            );
          }
        )}
      </GridLayout>
    </Section>
  );
}

export default AddMeetingForm;
