import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { partialTranslate } from '@libs/common';
import {
  DictionarySelectField,
  GridItem,
  GridLayout,
  InputMode,
  Section,
  SwitchField,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { FieldsType } from '../../model';
import { ChangeReturnModeContext } from '../../pages/ReleaseDocumentsWrapper';
import { isWZZ } from '../../utils';

function ReasonSection() {
  const [t] = useTranslation();
  const getLabel = partialTranslate('releaseDocuments:fields');
  const { formMode, watch } = useFormV2Context();
  const { items: returnedItems, setReasonKey } = useContext(ChangeReturnModeContext);
  const { editMode, createMode, viewMode } = useViewModesV2(formMode);
  const concernForeignPermission = watch('concernForeignPermission') as boolean;
  const number = watch('number') as string;
  const inputMode = createMode ? InputMode.FORM : InputMode.VIEW;

  return (
    <Section title={t('releaseDocuments:create.sections.reason')} isCollapsable>
      <GridLayout>
        <GridItem md={6} xs={12}>
          <DictionarySelectField
            name={typedNameV2<FieldsType>('correctionReasonKey')}
            label={getLabel('reason.reason')}
            inputMode={inputMode}
            dictionaryName={DomainDictionaryEnum.INVOICE_CORRECTION_REASON}
            isDisabled={!createMode}
            isRequired
            onChange={value => {
              setReasonKey(value);
            }}
            isClearable={false}
          />
        </GridItem>
        <GridItem md={6} xs={12} style={{ height: 'fit-content' }}>
          <SwitchField
            inputMode={viewMode || editMode || isWZZ(number) ? InputMode.VIEW : InputMode.FORM}
            name={typedNameV2<FieldsType>('zeroing')}
            label={getLabel('reason.zeroing')}
            isDisabled={concernForeignPermission || returnedItems.length > 0}
          />
        </GridItem>
      </GridLayout>
    </Section>
  );
}
export default ReasonSection;
