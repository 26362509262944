import { ReportCreateRequest } from '@stack/report';

import { useMutation, useQueryCache } from '@libs/common/v2/api';

import { API, endpointsConfig, ReportQueryKeysEnum } from '..';

const createReport = async (params: ReportCreateRequest) => {
  const { data } = await API.report.createReport(params, {
    ...endpointsConfig.createReport
  });
  return data;
};

export default function useCreateReportMutation() {
  const queryCache = useQueryCache();
  return useMutation(createReport, {
    onSuccess: () => {
      queryCache.invalidateQueries(ReportQueryKeysEnum.REPORTS);
    }
  });
}
