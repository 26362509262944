import { useRef } from 'react';

function useForm() {
  const formRef = useRef(null);

  const submitForm = () => {
    if (formRef) {
      formRef.current?.submit();
    }
  };

  return { formRef, submitForm };
}

export default useForm;
