import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FinancialSecuritySummarySnapshot } from '@ibtm/domain/dist/models';
import { GridProps, GridTypeMap } from '@mui/material';

import { partialTranslate } from '@libs/common';
import { GridLayout, InputMode, NumberInputField, useFormV2Context, useViewModesV2, Value } from '@libs/common/v2';
import { getNumberValue } from '@libs/common/v2/utils';

import { DomainDictionaryEntry } from '@libs/domain/config';
import {
  CurrencyTypeEnum,
  useCurrencyExchange,
  useFinancialSecurityApplicationSummaryQuery
} from '@libs/domain/financial-security';

type FieldNames =
  | 'presentedCollateral'
  | 'requireAmountEur'
  | 'requireAmountPln'
  | 'requiredCollateral'
  | 'requiredCollateral'
  | 'requireAmountEurInput'
  | 'requireAmountPlnInput';
export interface IFinancialSecurityApplicationExchangeFields {
  fieldId?: string;
  folderType?: string;
  created?: string;
  inputMode?: InputMode;
  applicationId: string;
  visibleFields?: Array<FieldNames>;
  gridItemProps?: GridProps<GridTypeMap['defaultComponent']>;
}

const translate = partialTranslate('financialSecurity:section.summary');

function FinancialSecurityApplicationExchangeFields({
  fieldId,
  folderType,
  created,
  inputMode,
  applicationId,
  visibleFields = [
    'presentedCollateral',
    'requireAmountEur',
    'requireAmountPln',
    'requiredCollateral',
    'requiredCollateral'
  ],
  gridItemProps = { sm: 4, xs: 12 }
}: IFinancialSecurityApplicationExchangeFields) {
  const [t] = useTranslation();

  const { setValue, formMode } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);

  const isFolderPPO = useMemo(() => folderType === DomainDictionaryEntry.FOLDER_TYPE.PPO, [folderType]);

  const setInputName = useCallback(
    (name: keyof FinancialSecuritySummarySnapshot): string => {
      return [fieldId, name].filter(Boolean).join('.');
    },
    [fieldId]
  );

  const setFinancialSecuritySummaryValues = useCallback(
    (data: FinancialSecuritySummarySnapshot) => {
      setValue(setInputName('requireAmountPln'), data?.requireAmountPln ?? null);
      setValue(setInputName('requireAmountEur'), data?.requireAmountEur ?? null);
      setValue(setInputName('presentAmountPln'), data?.presentAmountPln ?? null);
      setValue(setInputName('presentAmountEur'), data?.presentAmountEur ?? null);
      setValue(setInputName('differenceAmountPln'), data?.differenceAmountPln ?? null);
      setValue(setInputName('differenceAmountEur'), data?.differenceAmountEur ?? null);
      setValue(setInputName('version'), data?.version);
    },
    [setValue, setInputName]
  );

  const { data, isFetching } = useFinancialSecurityApplicationSummaryQuery(applicationId);

  useEffect(() => {
    setFinancialSecuritySummaryValues(data);
  }, [data, setFinancialSecuritySummaryValues]);

  const { currencyExchangeRate } = useCurrencyExchange({
    currencyKey: CurrencyTypeEnum.EUR,
    exchangeRateFrom: created,
    exchangeRateTo: null
  });

  const handleCurrencyPlnExchange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (currencyExchangeRate?.exchangeRate) {
      const exchangeEurValue = parseFloat(value) / currencyExchangeRate.exchangeRate;
      setValue(setInputName('requireAmountEur'), exchangeEurValue.toFixed(2));
    }
  };

  const handleCurrencyEurExchange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (currencyExchangeRate?.exchangeRate) {
      const exchangePlnValue = parseFloat(value) * currencyExchangeRate.exchangeRate;
      setValue(setInputName('requireAmountPln'), exchangePlnValue.toFixed(2));
    }
  };
  const isFieldVisible = (fieldName: FieldNames) => visibleFields.includes(fieldName);
  return (
    <GridLayout itemProps={gridItemProps}>
      {isFieldVisible('presentedCollateral') && (
        <Value label={translate('field.presentedCollateral')} key="presentedCollateral" isLoading={isFetching}>
          {!isFolderPPO && `${getNumberValue(data?.presentAmountEur, 2)} ${t('currency.EURO')}`}
          <br />
          {getNumberValue(data?.presentAmountPln, 2)} {t('currency.PLN')}
        </Value>
      )}
      <GridLayout itemProps={{ xs: 12 }}>
        {(isFieldVisible('requireAmountPln') || isFieldVisible('requireAmountEur')) && (
          <Value label={t('financialSecurity:field.requiredAmount')}>
            {!isFolderPPO &&
              isFieldVisible('requireAmountEur') &&
              `${getNumberValue(data?.requireAmountEur, 2)} ${t('currency.EURO')}`}
            <br />
            {isFieldVisible('requireAmountPln') && `${getNumberValue(data?.requireAmountPln, 2)} ${t('currency.PLN')}`}
          </Value>
        )}

        {!viewMode && !isFolderPPO && isFieldVisible('requireAmountEurInput') && (
          <NumberInputField
            label={t('financialSecurity:field.requiredAmountEur')}
            name={setInputName('requireAmountEur')}
            onChange={handleCurrencyEurExchange}
            inputMode={inputMode}
            decimalPrecision={2}
            validation={{ required: [] }}
          />
        )}
        {!viewMode && isFieldVisible('requireAmountPlnInput') && (
          <NumberInputField
            label={t('financialSecurity:field.requiredAmountPln')}
            name={setInputName('requireAmountPln')}
            onChange={handleCurrencyPlnExchange}
            inputMode={inputMode}
            decimalPrecision={2}
            validation={{ required: [] }}
          />
        )}
      </GridLayout>
      {isFieldVisible('requiredCollateral') && isFetching && (
        <Value label={translate('field.requiredCollateral')} key="requiredCollateral" isLoading={isFetching}>
          {getNumberValue(data?.requireAmountEur, 2)} {t('currency.EURO')}
          <br />
          {getNumberValue(data?.requireAmountPln, 2)} {t('currency.PLN')}
        </Value>
      )}
      {isFieldVisible('requiredCollateral') && (
        <Value
          label={translate('field.missingOrRedundantCollateral')}
          key="missingOrRedundantCollateral"
          isLoading={isFetching}
        >
          {!isFolderPPO && `${getNumberValue(data?.differenceAmountEur, 2)} ${t('currency.EURO')}`}
          <br />
          {getNumberValue(data?.differenceAmountPln, 2)} {t('currency.PLN')}
        </Value>
      )}
    </GridLayout>
  );
}

export default FinancialSecurityApplicationExchangeFields;
