import { useTableLayoutProps } from '@libs/common/v2';

import { useResourceTransformTypeObjectPoolsQuery } from '../../api';
import { useResourceTransformTypeObjectPoolsColumns } from '../columns';

function useResourceTransformTypeObjectPoolsTable({
  depotId,
  sourceTypeId
}: {
  depotId: string;
  sourceTypeId: string;
}) {
  const { columns, mappedFilterFields, mappedSortFields } = useResourceTransformTypeObjectPoolsColumns();

  return useTableLayoutProps({
    tableHookQuery: useResourceTransformTypeObjectPoolsQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    },
    tableHookQueryInitialParams: {
      depotIdIn: [depotId],
      resourceTypeIn: [sourceTypeId]
    }
  });
}

export default useResourceTransformTypeObjectPoolsTable;
