import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationSnapshot } from '@ibtm/domain';

import { Button, IconType, TableIconButton } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum, DomainUIElementEnum } from '../../../config';
import { ApplicationQueryKeysEnum } from '../../api';
import useAssignApplicationEditorMutation from '../../api/mutations/useAssignApplicationEditorMutation';
import { useApplicationPermission, useIsApplicationEditEnabled } from '../../hooks';
import { statusesWithBlockedEdit } from '../../utils';
import ApplicationAssignEditorDialog from '../ApplicationAssignEditorDialog';

interface IProps {
  application: ApplicationSnapshot;
  onEdit?: { link: string; state?: any } | (() => void);
  isTableRowButton?: boolean;
  onEditText?: string;
  onEditIcon?: IconType;
  permissionsKey: 'application' | 'print';
  assignButtonActionKey?: DomainUIElementEnum;
  editButtonActionKey?: DomainUIElementEnum;
}

function ApplicationEditOrAssignButton({
  application,
  onEdit,
  isTableRowButton,
  onEditIcon,
  onEditText,
  assignButtonActionKey,
  editButtonActionKey,
  permissionsKey
}: IProps) {
  const { hasPermissions } = useApplicationPermission();
  const permissions: { [key in IProps['permissionsKey']]: boolean } = {
    application: hasPermissions({
      action: 'update',
      applicationType: application.typeKey,
      isLoading: !application.typeKey
    }),
    print: true
  };
  const hasPermission = permissions[permissionsKey];
  const { translate } = useDictionaryTranslations();
  const queryCache = useQueryCache();
  const { id, version, archived, statusKey } = application ?? {};
  const isArchived = Boolean(archived);
  const { isEditEnabled, errorMessage } = useIsApplicationEditEnabled(application as ApplicationSnapshot);
  const [t] = useTranslation();
  const { mutate: assignEditor, isLoading } = useAssignApplicationEditorMutation();
  const [isDialogOpen, setSetIsDialogOpen] = useState(false);
  const icon: IconType = onEditIcon || 'EditIcon';
  const onEditLabel = onEditText || t('applications:action.editApplication');
  const assignEditorHandler = async () => {
    assignEditor(
      { applications: [{ id, version }] },
      {
        onSuccess: () => {
          if (isTableRowButton) {
            queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION_LIST]);
            queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, application.id]);
          } else {
            queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, application.id]);
          }
        }
      }
    );
  };

  const handleOnEdit = () => {
    if (typeof onEdit === 'function') {
      onEdit();
    }
  };

  const handleAssignEditor = () => {
    if (isBlocker) {
      setSetIsDialogOpen(true);
    } else {
      assignEditorHandler();
    }
  };
  const isAssignLoading = !isDialogOpen && isLoading;
  const isBlocker = application?.editor?.id;

  const editIsBlocked = useMemo(() => statusesWithBlockedEdit.some(status => status === statusKey), [statusKey]);

  const getEditButton = () => {
    if (!hasPermission) {
      return null;
    }
    return isTableRowButton ? (
      <TableIconButton
        icon={icon}
        tooltipTitle={onEditLabel}
        onClick={handleOnEdit}
        {...(typeof onEdit === 'object' &&
          onEdit?.link && {
            link: { pathname: onEdit.link, state: onEdit?.state }
          })}
        {...(editIsBlocked && {
          tooltipTitle: t('applications:tooltip.statusBlockEdit', {
            status: translate(DomainDictionaryEnum.APPLICATION_STATUS, statusKey, t('emptyMark'))
          })
        })}
        isDisabled={editIsBlocked}
        actionKey={editButtonActionKey}
      />
    ) : (
      <Button
        label={t('action.edit')}
        variant="outlined"
        onClick={handleOnEdit}
        actionKey={editButtonActionKey}
        {...(typeof onEdit === 'object' &&
          onEdit?.link && {
            link: { pathname: onEdit.link, state: onEdit?.state }
          })}
        icon={icon}
        iconHeight={16}
        iconWidth={16}
        {...(isArchived && { tooltipTitle: t('applications:tooltip.archived') })}
        {...(editIsBlocked && {
          tooltipTitle: t('applications:tooltip.statusBlockEdit', {
            status: translate(DomainDictionaryEnum.APPLICATION_STATUS, statusKey, t('emptyMark'))
          })
        })}
        disabled={isArchived || editIsBlocked}
      />
    );
  };

  const getAssignButton = () => {
    if (!hasPermission) {
      return null;
    }
    return (
      <>
        {isDialogOpen && (
          <ApplicationAssignEditorDialog
            onClose={() => {
              setSetIsDialogOpen(false);
            }}
            onConfirm={assignEditorHandler}
            message={errorMessage}
          />
        )}
        {isTableRowButton ? (
          <TableIconButton
            icon="AccountCheckIcon"
            tooltipTitle={t('prints:actions.assignToMe')}
            onClick={handleAssignEditor}
            isButtonLoading={isAssignLoading}
            actionKey={assignButtonActionKey}
          />
        ) : (
          <Button
            label={t('prints:actions.assignToMe')}
            {...(isBlocker && { tooltipTitle: errorMessage })}
            isLoading={isAssignLoading}
            onClick={handleAssignEditor}
            actionKey={assignButtonActionKey}
            variant="outlined"
          />
        )}
      </>
    );
  };

  return isEditEnabled || isArchived ? getEditButton() : getAssignButton();
}

export default ApplicationEditOrAssignButton;
