import { DefaultApi as DocumentGeneratorApi } from '@avispon/document-generator';
import { FilesApiFactory } from '@stack/file';
import axios from 'axios';

const API = {
  documentGenerator: null as DocumentGeneratorApi,
  file: null as ReturnType<typeof FilesApiFactory>
};

export function setDocumentTemplateApi(API_PORTAL_URL: string) {
  API.documentGenerator = new DocumentGeneratorApi(null, API_PORTAL_URL, axios as any);
  API.file = FilesApiFactory({ isJsonMime: () => true }, API_PORTAL_URL, axios);
}

export default API;
