import { ForeignPermissionSnapshot } from '@ibtm/domain';

import { ColumnTypesEnum, useCreateColumns } from '@libs/common/v2';

import { DomainDictionaryEnum } from '../../../config';
import { SettlementCurrentPermitsData } from '../../model';

function useSettlementCurrentPermitsColumns(visibleColumns: Array<keyof SettlementCurrentPermitsData>) {
  const { createColumns } = useCreateColumns<SettlementCurrentPermitsData, ForeignPermissionSnapshot>({
    pathToTranslate: 'foreignPermits:settlementDisposablePermits.formFields'
  });

  const columns = createColumns(
    [
      {
        accessor: 'year',
        type: ColumnTypesEnum.TEXT,
        isFilterable: false,
        isSortable: false
      },
      {
        header: 'name',
        accessor: 'nameKey',
        type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
        dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_NAME,
        width: 240,
        isFilterable: false,
        isSortable: false
      },
      {
        accessor: 'name',
        type: ColumnTypesEnum.TEXT,
        width: 240,
        isFilterable: false,
        isSortable: false
      },
      {
        header: 'formFullNumber',
        accessor: 'form.fullNumber',
        type: ColumnTypesEnum.TEXT,
        isFilterable: false,
        isSortable: false
      },
      {
        header: 'formNumberFrom',
        id: 'formNumberFrom',
        accessor: 'form.number',
        type: ColumnTypesEnum.TEXT,
        isFilterable: false,
        isSortable: false
      },
      {
        header: 'formNumberTo',
        id: 'formNumberTo',
        accessor: 'form.number',
        type: ColumnTypesEnum.TEXT,
        isFilterable: false,
        isSortable: false
      },
      {
        header: 'formNumber',
        id: 'formNumber',
        accessor: 'formSumNumber',
        type: ColumnTypesEnum.TEXT,
        isFilterable: false,
        isSortable: false
      }
    ],
    visibleColumns
  );

  return { columns };
}

export default useSettlementCurrentPermitsColumns;
