import { useTranslation } from 'react-i18next';

import { FormMode, Section, typedNameV2, useFormV2Watch, useViewModesV2 } from '@libs/common/v2';

import { ExploitationBaseFormData, ExploitationBaseInspectionSnapshot } from '@libs/domain/exploitation-base';

import InspectionSectionFields from './InspectionSectionFields';
import InspectionTable from './InspectionTable';

interface IProps {
  inspections: ExploitationBaseInspectionSnapshot[];
  initialExploitationBaseData: ExploitationBaseFormData;
  exploitationBaseId: string;
  formMode: FormMode;
  isInspectionTable: boolean;
  isFetching: boolean;
}

function InspectionSection({
  inspections,
  initialExploitationBaseData,
  exploitationBaseId,
  formMode,
  isInspectionTable,
  isFetching
}: IProps) {
  const { viewMode } = useViewModesV2(formMode);
  const [t] = useTranslation();

  const isInspectionResult = Boolean(
    useFormV2Watch({
      name: typedNameV2<ExploitationBaseFormData>('inspection.inspectionResultKey')
    })
  );

  return isInspectionTable ? (
    <InspectionTable
      inspections={inspections}
      initialExploitationBaseData={initialExploitationBaseData}
      isFetching={isFetching}
      exploitationBaseId={exploitationBaseId}
      title={t('exploitationBase:details.section.controlBase')}
      isAddAndRemoveAvaliable={!viewMode}
    />
  ) : (
    <Section title={t('exploitationBase:details.section.controlBase')}>
      <InspectionSectionFields isDateRequired={isInspectionResult} isRemarksRequired={isInspectionResult} />
    </Section>
  );
}

export default InspectionSection;
