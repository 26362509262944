import { useDialog } from '@libs/common/v2';

import { CorrespondenceAddressDialog } from '../components/address-dialog';

const useEditCorrespondenceAddress = () => {
  const { openDialog } = useDialog();

  const open = (applicationId: string) => {
    openDialog(({ closeDialog }) => {
      return <CorrespondenceAddressDialog applicationId={applicationId} closeDialog={closeDialog} />;
    });
  };

  return { open };
};

export default useEditCorrespondenceAddress;
