import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupSnapshotExternal } from '@avispon/group/dist/models';

import { TableButtonDelete, useConfirmDialog, useFormV2Context } from '@libs/common/v2';

interface IProps {
  original: GroupSnapshotExternal;
}

function UserGroupCreateFormRowActions({ original }: IProps) {
  const [t] = useTranslation();
  const { watch, setValue } = useFormV2Context();
  const [confirm] = useConfirmDialog();
  const values = watch('groups');

  const handleGroupDelete = useCallback(
    (group: GroupSnapshotExternal) => {
      confirm({
        title: t('user:dialog.deleteGroupMembershipTitle'),
        shortTitle: t('global:dialog.deleteConfirmTitle'),
        message: t('user:dialog.deleteGroupMembershipConfirm', { name: group.name }),
        onConfirm: (setConfirmLoading, closeDialog) => {
          const groups = values.filter(({ id }) => id !== group.id);
          setValue('groups', groups);
          closeDialog();
        },
        confirmType: 'danger'
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values]
  );

  return <TableButtonDelete onClick={() => handleGroupDelete(original)} />;
}

export default UserGroupCreateFormRowActions;
