import { MutableRefObject, useEffect, useRef } from 'react';

type RefValue = string | number | boolean | Record<string, unknown>;

function usePreviousProps(value: RefValue) {
  const ref: MutableRefObject<RefValue> = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export default usePreviousProps;
