import { createElement, useEffect, useMemo } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import clsx from 'clsx';

import { InputMode, useFormV2Context } from '@libs/common/v2';

import { useMetaFormContext } from '@libs/meta-form/context';
import { FieldRegistry } from '@libs/meta-form/fields';
import { FieldProperties } from '@libs/meta-form/models';
import { useCheckElementVisibility, useCustomValidation } from '@libs/meta-form/services';
import usePermissions from '@libs/permission/hooks/usePermissions';

type FieldProps = { fieldId?: string } & FieldProperties;

function FieldWrapper({ fieldId, ...forwardProps }: FieldProps) {
  const { additionalFields, fields } = useMetaFormContext();
  const { formMode } = useFormV2Context();
  const field = fields.find(el => el.fieldId === fieldId);
  const { hasPermission: checkPermissions } = usePermissions();
  const formPermission = field?.properties?.permission?.form;
  const viewPermission = field?.properties?.permission?.view;
  const hasFormModePermission = formPermission ? checkPermissions(formPermission) : true;
  const hasViewPermission = viewPermission ? checkPermissions(viewPermission) : true;
  const { isVisible } = useCheckElementVisibility(field?.properties?.visibility);

  useEffect(() => {
    // TODO - dodane w useEffect aby nie spamować w konsoli co rerender
    // dodane aby łatwiej weryfikować czy wymagane pola są nadpisane
    const fieldsFound = fields.filter(el => el.fieldId === fieldId);
    if (fieldsFound.length > 1) {
      // eslint-disable-next-line no-console
      console.info(`Metaform field overridden`, {
        fieldId,
        ...(field.properties?.label && { label: field.properties.label })
      });
    }
    if (!field) {
      // eslint-disable-next-line no-console
      console.error(`Metaform field not found`, { fieldId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldsRegistry = { ...FieldRegistry, ...additionalFields };
  const fieldName = fieldId as FieldPath<FieldValues>;
  useCustomValidation({
    api: field?.properties?.api,
    validation: field?.properties?.validation,
    fieldId: fieldName
  });

  const fieldMemo = useMemo(() => {
    const { typeKey, properties } = field ?? {};
    return fieldsRegistry[typeKey] !== undefined && isVisible && hasViewPermission
      ? createElement(fieldsRegistry[typeKey], {
          fieldId,
          ...properties,
          ...forwardProps,
          ...(!hasFormModePermission && { inputMode: InputMode.VIEW })
        })
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldId, field, isVisible, formMode, hasViewPermission, hasFormModePermission]);

  if (field) {
    return <div className={clsx(field?.properties?.containerClassName)}>{fieldMemo}</div>;
  }

  return null;
}

export default FieldWrapper;
