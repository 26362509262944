import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { KeyType } from '@libs/common';
import { Button, useConfirmDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { UIElementNameEnum } from '@libs/permission';

import { SocialCommissionQueryKeysEnum, useDeleteTradeRuleMutation } from '@libs/domain/social-commission';

function TableButtonDelete({
  id,
  unSelectAll,
  actionKey
}: {
  id: string;
  unSelectAll: () => void;
  actionKey?: UIElementNameEnum;
}) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const [confirm] = useConfirmDialog();
  const { showSuccessSnackbar } = useSnackbar();

  const { mutate: deleteListItem } = useDeleteTradeRuleMutation();

  const deleteDialog = () => {
    const civicBoardRuleId = id;
    confirm({
      title: t('foreignPermits:other.deleteRuleTitle' as KeyType),
      message: t('foreignPermits:other.deleteRule' as KeyType),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        unSelectAll?.();
        deleteListItem(civicBoardRuleId, {
          onSuccess: () => {
            showSuccessSnackbar(t('global:dialog.deleteSuccess'));
            queryCache.invalidateQueries(SocialCommissionQueryKeysEnum.TRADE_RULES_DETAILS);
            closeDialog();
          },
          onSettled: () => {
            setConfirmLoading(false);
            closeDialog();
          }
        });
      },
      confirmType: 'danger'
    });
  };

  return (
    <Button
      actionKey={actionKey}
      label={t('global:action.delete')}
      onClick={() => deleteDialog()}
      isSecondary
      variant="outlined"
    />
  );
}

export default TableButtonDelete;
