import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { FolderSnapshot } from '@ibtm/domain';
import { makeStyles } from '@mui/styles';
import { isObject } from 'lodash';

import { IconButton, TableLayout } from '@libs/common/v2';
import { calc } from '@libs/common/v2/utils';

import { useSelectPartnerTable } from '../hooks';

function PartnerSelectTable({ setSelectedSubject }: { setSelectedSubject: Dispatch<any> }) {
  const [t] = useTranslation();
  const classes = useStyles();
  const { showErrorSnackbar } = useSnackbar();

  const tableProps = useSelectPartnerTable();

  const handleSelectSubject = (selectedRow: FolderSnapshot) => {
    if (isObject(selectedRow)) {
      setSelectedSubject(selectedRow);
    } else {
      showErrorSnackbar(t('partnerApplicants:message.notSelected'));
    }
  };

  return (
    <div className={classes.tableContainer}>
      <div className={classes.table}>
        <TableLayout
          {...tableProps}
          rowActions={({ original }) => (
            <IconButton
              onClick={() => handleSelectSubject(original)}
              height={20}
              width={20}
              icon="AddIcon"
              tooltipTitle={t('action.add')}
              isBackgroundTransparent
            />
          )}
          isSection
          isActionColumnEnabled
        />
      </div>
    </div>
  );
}
const useStyles = makeStyles({
  tableContainer: {
    maxHeight: '600px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  table: {
    height: calc('100% - 60px')
  }
});

export default PartnerSelectTable;
