import { MoneyTransferUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

type UpdateMoneyTransferParams = {
  moneyTransferId: string;
  formData?: MoneyTransferUpdateRequest;
};

export function updateMoneyTransfer({ moneyTransferId, formData }: UpdateMoneyTransferParams) {
  return API.moneyTransfer.updateMoneyTransfer(moneyTransferId, formData, {
    ...endpointsConfig.updateMoneyTransfer
  });
}

function useUpdateMoneyTransferMutation() {
  return useMutation(updateMoneyTransfer);
}

export default useUpdateMoneyTransferMutation;
