enum UserUIElementEnum {
  GROUPS_EDIT_BUTTON = 'GROUPS_EDIT_BUTTON',
  GROUPS_DELETE_BUTTON = 'GROUPS_DELETE_BUTTON',
  GROUPS_DUPLICATE_BUTTON = 'GROUPS_DUPLICATE_BUTTON',
  GROUPS_DETAILS_BUTTON = 'GROUPS_DETAILS_BUTTON',
  GROUPS_LIST_TAB = 'GROUPS_LIST_TAB',
  GROUPS_EXTRA_TABLE_FILTER = 'GROUPS_EXTRA_TABLE_FILTER',
  GROUPS_CREATE_BUTTON = 'GROUPS_CREATE_BUTTON'
}

export default UserUIElementEnum;
