import useFetchPrintsApplicationDetails from './useFetchPrintsApplicationDetails';

export function useSPOApplicationForeignPermissionsFlags() {
  const { applicationData } = useFetchPrintsApplicationDetails();
  const { spoComplete, hasSpoForeignPermissions } = applicationData || {};
  return {
    isSendToForeignPermissionDepartmentAvailable: hasSpoForeignPermissions && !spoComplete,
    isPassedToForeignPermissionDepartment: hasSpoForeignPermissions && spoComplete
  };
}
