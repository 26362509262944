import { useQuery } from 'react-query';
import axios from 'axios';

import { AppConfigScheme } from '../../scheme';
import { endpointsConfig } from '../endpoints-config';

const getAppConfig = async () => {
  const response = await axios.get(`${process.env.PUBLIC_URL}/config/config.json`, {
    baseURL: '',
    ...endpointsConfig.getAppConfig
  });

  return response.data;
};

export function useAppConfigQuery() {
  return useQuery<AppConfigScheme>('APP_CONFIG', getAppConfig, {
    keepPreviousData: false
  });
}
