import { SelectOption, useTableLayoutProps } from '@libs/common/v2';

import { useDomainConfigContext } from '@libs/domain/config';
import { useSingleAuthorizationsLimitsQuery } from '@libs/domain/foreign-permission';
import { BasicLimitTableEnum } from '@libs/domain/social-commission/model';

import useSingleAuthorizationLimitsColumns from '../columns/useSingleAuthorizationLimitsColumns';

function useSingleAuthorizationLimitsTable(
  id: string,
  queryInitialParams: { typeKeyIn: string[]; folderId?: string },
  isYearFilter?: boolean
) {
  const { columns } = useSingleAuthorizationLimitsColumns();
  const { yearFilterContextValues } = useDomainConfigContext();

  const initialParamsMapper = {
    [BasicLimitTableEnum.YEAR]: (year: SelectOption<string>) => ({ [BasicLimitTableEnum.YEAR]: year.value })
  };

  const initalParams = isYearFilter
    ? {
        id,
        ...queryInitialParams,
        [BasicLimitTableEnum.YEAR]: yearFilterContextValues?.value
      }
    : {
        id,
        ...queryInitialParams
      };

  return useTableLayoutProps({
    tableHookQuery: useSingleAuthorizationsLimitsQuery,
    tableHookOptions: {
      columns,
      initialParamsConverter: initialParamsMapper
    },
    tableHookQueryInitialParams: initalParams,
    delayTable: false
  });
}

export default useSingleAuthorizationLimitsTable;
