import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { Theme } from '@libs/common/v2';

export const DownloadFileButton = ({ onClick }: { onClick: () => void }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <div>
      {t('youCanDownloadFile')}{' '}
      <button type="button" className={classes.button} onClick={onClick}>
        {t('here')}
      </button>
      .
    </div>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  button: () => ({
    backgroundColor: 'unset',
    height: 'auto',
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: 0,
    margin: 0,
    fontWeight: 'inherit',
    fontSize: 'inherit',
    fontFamily: 'inherit',
    color: theme.palette.blue[800]
  })
}));
