import { useContext } from 'react';

import { LayoutContext } from '../context/LayoutContextProvider';

export default function useNavbar() {
  const { isFolded, foldNavbar, isMouseInside, setMouseInside, isMobileOpen, openMobile, position, setNavbarPosition } =
    useContext(LayoutContext);

  const foldedAndClosed = isFolded && !isMouseInside;
  const foldedAndOpened = isFolded && isMouseInside;

  return {
    isFolded,
    foldNavbar,
    isMouseInside,
    setMouseInside,
    isMobileOpen,
    openMobile,
    position,
    setNavbarPosition,
    foldedAndClosed,
    foldedAndOpened
  };
}
