import React, { forwardRef } from 'react';
import { Calendar as ReactCalendar } from 'react-calendar';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import moment from 'moment';

import { Icon } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { hexToRgba, important } from '@libs/common/v2/utils';

import './Calendar.css';

/**
 * Użyta paczka https://www.npmjs.com/package/react-calendar
 */

type CalendarType = 'Arabic' | 'Hebrew' | 'ISO 8601' | 'US';

export enum CalendarViewEnum {
  MONTH = 'month',
  YEAR = 'year',
  DECADE = 'decade',
  CENTURY = 'century'
}

type ViewType = `${CalendarViewEnum}`;

type DateOrNull = Date | null;
export type StringOrDateOrNull = string | Date | null;
export type ValueType = DateOrNull | [DateOrNull, DateOrNull];
export type LoseValueType = StringOrDateOrNull | [StringOrDateOrNull, StringOrDateOrNull];

export interface ICalendarProps {
  className?: string;
  /**
   * Jaki widok kalendarza jest renderowany na początek.
   * Domyślnie jest widok miesiąca
   */
  view?: ViewType;
  /**
   * Najmniej dokładny dozwolony widok kalendarza
   */
  minDetail?: ViewType;
  /**
   * Najdokładniejszy dozwolony widok kalendarza
   */
  maxDetail?: ViewType;
  /**
   * Minimalna dozwolona data do zaznaczenia
   */
  minDate?: Date;
  /**
   * Maksymalna dozwolona data do zaznaczenia
   */
  maxDate?: Date;
  /**
   * Wartość decyduje czy użytkownik może zaznaczyć jedną datę czy okres od-do.
   */
  isSelectRange?: boolean;
  /**
   * Wartość decyduje o wyświetleniu numerów tygodni.
   */
  isWeekNumberShown?: boolean;
  /**
   * Wartość decyduje o wyświetleniu przycisku do przejścia do następengo miesiąca
   * Domyślna wartość `true`
   */
  isNextMonthButtonEnabled?: boolean;
  /**
   * Wartość decyduje o wyświetleniu przycisku do przeskoczenia do wyświetlanego miesiąca w następnym roku
   * Domyślna wartość `false`
   */
  isNextYearButtonEnabled?: boolean;
  /**
   * Wartość decyduje o wyświetleniu przycisku do przejścia do poprzedniego miesiąca
   * Domyślna wartość `true`
   */
  isPrevMonthButtonEnabled?: boolean;
  /**
   * Wartość decyduje o wyświetleniu przycisku do przeskoczenia do wyświetlanego miesiąca w poprzednim roku
   * Domyślna wartość `false`
   */
  isPrevYearButtonEnabled?: boolean;
  /**
   * Wartość decyduje czy ma wyświetlić dwa następujące po sobie miesiące obok siebie.
   * Widok jak popup Datepickera
   */
  isDoubleViewEnabled?: boolean;
  /**
   * Wartość decyduje o wypełnieniu w widoku miesiąca dni z sąsiednich miesięcy
   * Domyślna wartość `true`
   */
  isNeighboringMonthShowEnabled?: boolean;
  /**
   * Funkcja jest wykorzystana do ustawiania styli `active`/`inactive` wybranych dni.
   * Wykorzystujemy jeżeli chcemy wybrać różne dni ( nie następujące po sobie ).
   * Funkcja jest wywoływana dla każdego dnia

   */
  isTileActive?: (params: { date: Date; view: ViewType }) => boolean;
  /**
   * Funkcja decyduje czy dany dzień ma być zablokowany.
   * Funkcja jest wywoływana dla każdego dnia
   */
  isDayTileDisabled?: (params: { activeStartDate: Date; date: Date; view: ViewType }) => boolean;
  /**
   * Aktualna wartość kalendarza gdy chcemy żeby był kontrolowany.
   * Jeżeli użytkownik może wybrać jedną datę to będzie `string`.
   * Jeżeli jest dozwolony zakres dat wtedy wartością będzie tablica `[data początkowa, data końcowa]`.
   * Jeżeli chcemy aby użytkownik mógł wybrać różne daty nie przekazujemy tutaj nic,
   * a stan zaznaczonych dni trzymamy wyżej w rodzicu.
   */
  value?: LoseValueType;
  /**
   * Funkcja wywołana przy najdokładniejszym widoku i wybraniu widocznego na nim `kafelka`.
   * Np. jeżeli najdokładniejszy widok to miesiąc to funkcja zostanie wywołana po kliknięciu dnia,
   * jeżeli najdokładniejszy widok to rok to funkcja zostanie wywołana po kliknięciu w miesiąc itd.
   */
  onChange?: (value: ValueType, event: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * Funkcja wywołana przy kliknięciu w `kafelek` dnia.
   */
  onClickDay?: (value: ValueType, event: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * Funkcja wywołana przy kliknięciu w `kafelek` numberu tygodnia
   */
  onClickWeekNumber?: (weekNumber: number, date: Date, event: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * Funkcja wywołana przy kliknięciu w `kafelek` miesiąca
   */
  onClickMonth?: (value: ValueType, event: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * Funkcja wywołana przy kliknięciu w `kafelek` roku
   */
  onClickYear?: (value: ValueType, event: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * Funkcja wywołana przy przejściu na dokładniejszy widok
   */
  onDrillDown?: (params: { activeStartDate: Date; view: ViewType }) => void;
  /**
   * Funkcja wywołana przy przejściu na 'dalszy' widok. Z widoku miesiąca na widok roku
   */
  onDrillUp?: (params: { activeStartDate: Date; view: ViewType }) => void;
  /**
   * Funkcja wywołana przy kliknięciu w `kafelek` dekady
   */
  onClickDecade?: (value: ValueType, event: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * Początkowa data w aktualnym widoku.
   */
  setCurrentViewStartDate?: (activeStartDate: Date, view: CalendarViewEnum) => void;
  /**
   * Format wyświetlania kalendarza, decyduje o wyświetlaniu weekendów i kolejności dni tygodnia.
   */
  calendarType?: CalendarType;
}

function Calendar(
  {
    isSelectRange,
    isWeekNumberShown,
    isDoubleViewEnabled,
    isNextMonthButtonEnabled = true,
    isNextYearButtonEnabled = false,
    isPrevMonthButtonEnabled = true,
    isPrevYearButtonEnabled = false,
    isNeighboringMonthShowEnabled = true,
    isDayTileDisabled,
    calendarType = 'ISO 8601',
    className,
    value,
    setCurrentViewStartDate,
    isTileActive,
    minDetail = CalendarViewEnum.DECADE,
    ...restProps
  }: ICalendarProps,
  ref: React.Ref<HTMLDivElement>
) {
  const [t] = useTranslation();
  const classes = useStyles({ isDoubleViewEnabled, isTileActive, isSelectRange, value });

  const handleTileActive = params => {
    const day = moment(params.date).day();
    if (isTileActive && params.view === CalendarViewEnum.YEAR) {
      return isTileActive?.(params) ? clsx(classes.active) : clsx(classes.inactive);
    }
    if (isTileActive && params.view === CalendarViewEnum.MONTH) {
      return isTileActive?.(params)
        ? clsx(classes.active, {
            [classes.weekendActive]: day === 0 || day === 6
          })
        : clsx(classes.inactive, {
            [classes.weekendInactive]: day === 0 || day === 6
          });
    }
    if (!isTileActive && isSelectRange && params.view === CalendarViewEnum.MONTH) {
      if (day === 0) return classes.lastRightTile;
      if (day === 1) return classes.firstLeftTile;
      return null;
    }
    return null;
  };

  const setCurrentStartDate = params => {
    setCurrentViewStartDate?.(params.activeStartDate, params.view);
  };

  return (
    <ReactCalendar
      nextAriaLabel={t('action.nextMonth')}
      next2AriaLabel={t('action.nextYear')}
      prevAriaLabel={t('action.previousMonth')}
      prev2AriaLabel={t('action.previousYear')}
      value={value}
      inputRef={ref}
      calendarType={calendarType}
      minDetail={minDetail}
      className={clsx(classes.root, className)}
      selectRange={isSelectRange}
      showWeekNumbers={isWeekNumberShown}
      showDoubleView={isDoubleViewEnabled}
      showNeighboringMonth={isNeighboringMonthShowEnabled}
      nextLabel={isNextMonthButtonEnabled ? <Icon icon="ArrowIcon" /> : null}
      next2Label={isNextYearButtonEnabled ? <Icon icon="ChevronsRightIcon" /> : null}
      prevLabel={isPrevMonthButtonEnabled ? <Icon icon="ArrowLeftIcon" /> : null}
      prev2Label={isPrevYearButtonEnabled ? <Icon className={classes.prevIcon} icon="ChevronsRightIcon" /> : null}
      tileDisabled={isDayTileDisabled}
      onViewChange={setCurrentStartDate}
      onActiveStartDateChange={setCurrentStartDate}
      tileClassName={handleTileActive}
      {...restProps}
    />
  );
}

const useStyles = makeStyles<Theme, Partial<ICalendarProps>>(theme => ({
  root: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '20px',
    width: ({ isDoubleViewEnabled }) => (isDoubleViewEnabled ? '858px' : '416px'),
    padding: 10,
    background: theme.palette.white,
    fontFamily: theme.typography.textSm.normal.fontFamily,

    '& .react-calendar__month-view__weekdays': {
      ...theme.typography.textXs.bold
    },
    '& .react-calendar__navigation__label': {
      padding: 0,
      borderRadius: '20px'
    },
    '& .react-calendar__navigation__label:hover, .react-calendar__navigation__label:focus': {
      backgroundColor: hexToRgba(theme.palette.grey[700], 0.12)
    },
    '& .react-calendar__tile, .react-calendar__month-view__days__day': {
      background: theme.palette.white
    },
    '& .react-calendar__navigation__label__labelText': {
      ...theme.typography.textMd.semibold,
      color: theme.palette.grey[700]
    },
    '& .react-calendar__navigation button:disabled,.react-calendar__tile:disabled ': {
      backgroundColor: hexToRgba(theme.palette.grey[500], 0.12)
    },
    '& .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus, .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus  ':
      {
        backgroundColor: ({ isTileActive }) => !isTileActive && hexToRgba(theme.palette.grey[700], 0.12)
      },
    '& .react-calendar__navigation__arrow:hover, .react-calendar__navigation__arrow:focus': {
      backgroundColor: hexToRgba(theme.palette.grey[700], 0.12)
    },
    '& .react-calendar__navigation__arrow': {
      padding: 0,
      borderRadius: '20px'
    },
    '& > span, abbr': {
      fontFamily: theme.typography.textSm.normal.fontFamily
    },
    '& .react-calendar__month-view__days__day, .react-calendar__month-view__weekdays__weekday': {
      flex: important('1 0 20%'),
      maxWidth: '56px',
      color: ({ isTileActive, isSelectRange, value }) => {
        if (isTileActive) {
          return null;
        }
        return isSelectRange && value && Array.isArray(value) ? null : theme.palette.grey[700];
      }
    },
    '& .react-calendar__month-view__days__day--weekend': {
      color: ({ isTileActive }) => !isTileActive && theme.palette.error[700]
    },
    '& .react-calendar__month-view__days__day--neighboringMonth': {
      color: ({ isTileActive }) => !isTileActive && hexToRgba(theme.palette.grey[900], 0.6)
    },
    '& .react-calendar__tile': {
      height: '56px',
      padding: '7px 7px',
      borderRadius: '40px',
      ...theme.typography.textSm.bold
    },
    '& .react-calendar__tile--now': {
      border: `2px solid ${theme.palette.grey[500]}`
    },
    '& .react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus': {
      border: `2px solid ${theme.palette.grey[300]}`
    },
    '& .react-calendar__tile--hasActive': {
      background: ({ isTileActive }) => !isTileActive && theme.palette.blueLight[700],
      color: ({ isTileActive }) => !isTileActive && theme.palette.white
    },
    '& .react-calendar__tile--hasActive:enabled:hover, .react-calendar__tile--hasActive:enabled:focus': {
      background: ({ isTileActive }) => !isTileActive && theme.palette.blueLight[200]
    },
    '& .react-calendar__tile--rangeStart': {
      background: ({ isTileActive }) => !isTileActive && important(theme.palette.blueLight[700]),
      borderTopLeftRadius: important('40px'),
      borderBottomLeftRadius: important('40px'),
      color: ({ isTileActive }) => !isTileActive && important(theme.palette.white)
    },
    '& .react-calendar__tile--rangeEnd': {
      background: ({ isTileActive }) => !isTileActive && important(theme.palette.blueLight[700]),
      borderTopRightRadius: important('40px'),
      borderBottomRightRadius: important('40px'),
      color: ({ isTileActive }) => !isTileActive && important(theme.palette.white)
    },
    '& .react-calendar__tile--range': {
      background: ({ isTileActive }) => !isTileActive && theme.palette.blueLight[200],
      borderRadius: 0,
      color: ({ isTileActive }) => !isTileActive && theme.palette.blue[700]
    },
    '& .react-calendar--selectRange, .react-calendar__tile--hover': {
      backgroundColor: theme.palette.blueLight[200]
    },
    '& .react-calendar__decade-view__years__year': {
      background: theme.palette.white,
      color: theme.palette.grey[700],
      '&:hover, &:focus': {
        backgroundColor: hexToRgba(theme.palette.grey[700], 0.12)
      }
    },
    '& .react-calendar__tile--active': {
      background: ({ isTileActive, isSelectRange }) => !isTileActive && !isSelectRange && theme.palette.blueLight[700],
      color: ({ isTileActive, isSelectRange }) => !isTileActive && !isSelectRange && theme.palette.white
    },
    '&.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus': {
      background: ({ isTileActive }) => !isTileActive && theme.palette.blueLight[700],
      color: ({ isTileActive }) => !isTileActive && theme.palette.white
    }
  },
  prevIcon: {
    transform: 'rotate(180deg)'
  },
  active: {
    background: important(hexToRgba(theme.palette.blueLight[700], 1)),
    color: theme.palette.white,
    '&:hover, &:focus': {
      background: theme.palette.blueLight[700]
    }
  },
  inactive: {
    background: theme.palette.white,
    color: theme.palette.grey[700],
    '&:hover, &:focus': {
      backgroundColor: hexToRgba(theme.palette.grey[700], 0.12)
    }
  },
  weekendInactive: {
    color: theme.palette.error[700]
  },
  weekendActive: {
    color: theme.palette.error[100]
  },
  lastRightTile: {
    borderTopRightRadius: important('40px'),
    borderBottomRightRadius: important('40px')
  },
  firstLeftTile: {
    borderTopLeftRadius: important('40px'),
    borderBottomLeftRadius: important('40px')
  }
}));

export default forwardRef(Calendar);
