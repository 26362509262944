import { DocumentsApiUpdateDocumentReceiptAckDateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface IMutation extends DocumentsApiUpdateDocumentReceiptAckDateRequest {
  isKREPTProceeding?: boolean;
  proceedingId?: string;
}

function enterDeliveryDate(requestData: IMutation) {
  return requestData?.isKREPTProceeding
    ? API.proceedingsKreptd.updateProceedingKreptdDocumentReceiptAckDate(
        requestData.proceedingId,
        requestData.documentId,
        requestData.documentReceiptAckDateUpdateRequest,
        { ...endpointsConfig.updateProceedingKreptdDocumentReceiptAckDate }
      )
    : API.documents.updateDocumentReceiptAckDate(
        requestData.documentId,
        requestData.documentReceiptAckDateUpdateRequest,
        { ...endpointsConfig.updateDocumentReceiptAckDate }
      );
}

function useEnterDeliveryDateMutation() {
  return useMutation(enterDeliveryDate);
}

export default useEnterDeliveryDateMutation;
