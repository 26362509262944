import { useTranslation } from 'react-i18next';
import { ProceedingIdentityDetails } from '@ibtm/domain';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { isEmpty } from 'lodash';

import { Theme } from '@libs/common/v2';
import { getValue } from '@libs/common/v2/utils';

interface IProps {
  formNumber?: string;
  pendingProceedings?: ProceedingIdentityDetails[];
}

function ProceedingCell({ formNumber, pendingProceedings }: IProps) {
  const [t] = useTranslation();
  const classes = useStyles();

  const hasActiveProceeding = !isEmpty(pendingProceedings);

  return (
    <div className="flex items-center">
      {getValue(formNumber)}
      {hasActiveProceeding && (
        <Tooltip title={t('drivers:messages.hasPendingProceeding')}>
          <span className={clsx('material-icons', classes.highPriorityIcon)}>priority_high</span>
        </Tooltip>
      )}
    </div>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  highPriorityIcon: {
    color: theme.palette.error[500]
  }
}));

export { ProceedingCell };
