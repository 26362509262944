import { ViolationAssigneeUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function changeAssignedPerson(requestData: ViolationAssigneeUpdateRequest) {
  return API.violations.updateViolationAssignedTo(requestData, {
    ...endpointsConfig.updateMultipleProceedingsAssignee
  });
}

function useViolationAssignedPersonChangeMutation() {
  return useMutation(changeAssignedPerson);
}

export default useViolationAssignedPersonChangeMutation;
