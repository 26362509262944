import React, { createContext } from 'react';
import { PaginatedQueryResult } from 'react-query';
import { PluginHook, Row } from 'react-table';

import { KeyType } from '@libs/common';
import { IPaginatedModel, ISingleColumn, ITableAdapter, TMutableTableFormContextProvider } from '@libs/common/v2';

export interface ITableContext<T extends Record<string, any>, K extends Record<string, any> = T>
  extends Partial<Omit<PaginatedQueryResult<IPaginatedModel<T>>, 'refetch' | 'isError'>> {
  isLoading: boolean;
  tableLoading: boolean;
  setTableLoading: (value: boolean) => void;
  isFetching?: boolean;
  isTableMutable: boolean;
  isFilterEnabled?: boolean;
  isError?: boolean;
  maxInsertedColumnNumber: number;
  columnsToInsert: {
    pathToTranslate: KeyType | null;
    columns: ISingleColumn<T, K>[];
  };
  checkDisabled?: (table?: ITableAdapter<T, K>, row?: Row<T>) => boolean;
  initiallyChecked?: (table?: ITableAdapter<T, K>, row?: Row<T>) => boolean;
  refetch?: () => void;
  onRowEditCreateConfirm?: (
    row: T,
    context?: {
      isCreated: boolean;
      isEdited: boolean;
      tableContext: ITableContext<T, K>;
      tableInstance: ITableAdapter<T, K>;
      formContext: TMutableTableFormContextProvider;
      onSuccess: () => void;
      onError: () => void;
    }
  ) => Promise<any> | void;
  newRowScheme?: T;
  actionsColumnWidth: number;
  selectionColumnWidth: number;
  rows?: Record<string, unknown>;
  plugins?: Array<PluginHook<T>>;
  isHiddenSelectRowHeader?: boolean;
}

export const TableInstanceContext = React.createContext<ITableAdapter<any>>(null);

export const TableContext = createContext<ITableContext<any, any>>({
  isLoading: false,
  tableLoading: false,
  setTableLoading: null,
  isFetching: false,
  newRowScheme: {},
  isTableMutable: false,
  isFilterEnabled: true,
  isError: false,
  maxInsertedColumnNumber: 0,
  checkDisabled: null,
  initiallyChecked: null,
  refetch: null,
  columnsToInsert: {
    pathToTranslate: null,
    columns: []
  },
  clear: null,
  data: null,
  error: null,
  failureCount: null,
  isFetched: false,
  isFetchedAfterMount: false,
  isIdle: false,
  isInitialData: false,
  isPlaceholderData: false,
  isPreviousData: false,
  isStale: false,
  isSuccess: false,
  latestData: null,
  remove: null,
  resolvedData: null,
  status: null,
  updatedAt: null,
  onRowEditCreateConfirm: null,
  actionsColumnWidth: 150,
  selectionColumnWidth: 48,
  rows: null,
  plugins: null,
  isHiddenSelectRowHeader: false
});
