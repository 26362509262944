export enum FolderQueryKeysEnum {
  APPLICATIONS_LIST = 'APPLICATIONS_LIST',
  FOLDERS_LIST = 'FOLDERS_LIST',
  FOLDERS_LIST_FILTER = 'FOLDERS_LIST_FILTER',
  FOLDER = 'FOLDER',
  FOLDER_NOTES = 'FOLDER_NOTES',
  FOLDER_EXTENDED = 'FOLDER_EXTENDED',
  SUBJECT_PERMISSION_LIST = 'SUBJECT_PERMISSION_LIST',
  SUBJECT_EXTENDED = 'SUBJECT_EXTENDED',
  PROXIES_LIST = 'PROXIES_LIST',
  SINGLE_AUTHORIZATION_LIMIT_LIST = 'SINGLE_AUTHORIZATION_LIMIT_LIST',
  SINGLE_AUTHORIZATIONS = 'SINGLE_AUTHORIZATIONS',
  FOLDER_LIMIT_LIST = 'FOLDER_LIMIT_LIST',
  EKMT = 'EKMT',
  ORDER_LIST = 'ORDER_LIST',
  PERMISSION_LIST = 'PERMISSION_LIST',
  PERMIT_GITD_LIST = 'PERMIT_GITD_LIST',
  LICENCE_LIST = 'LICENCE_LIST',
  FINANCIAL_SECURTITY = 'FINANCIAL_SECURTITY',
  FINANCIAL_SECURTITY_LIST = 'FINANCIAL_SECURTITY_LIST',
  CURRENCY_RATE_LIST = 'CURRENCY_RATE_LIST',
  FINANCIAL_SECURTITY_SUMMARY = 'FINANCIAL_SECURTITY_SUMMARY',
  REPUTATION = 'REPUTATION',
  REPUTATIONS_LIST = 'REPUTATIONS_LIST',
  SUBJECT_HISTORY_LIST = 'SUBJECT_HISTORY_LIST',
  ADDRESS_HISTORY_LIST = 'ADDRESS_HISTORY_LIST',
  ENTREPRENEURS_LIST = 'ENTREPRENEURS_LIST',
  INSPECTION_LIST = 'INSPECTION_LIST',
  SUSPENSION_LIST = 'SUSPENSION_LIST',
  PROCEEDING_LIST = 'PROCEEDING_LIST',
  PROCEEDING = 'PROCEEDING',
  AVAILABLE_FORM_LIST = 'AVAILABLE_FORM_LIST',
  LOCK = 'LOCK',
  LOCK_LIST = 'LOCK_LIST',
  HISTORY_LIST = 'FOLDER_HISTORY_LIST',
  PROCEEDING_DOCUMENT_LIST = 'PROCEEDING_DOCUMENT_LIST',
  FORM_OPERATION_LIST = 'FORM_OPERATION_LIST',
  INSURANCE_POLICIES = 'INSURANCE_POLICIES',
  TRANSPORT_MANAGER_LIST = 'TRANSPORT_MANAGER_LIST',
  TRANSPORT_MANAGER = 'TRANSPORT_MANAGER',
  NOTES = 'NOTES',
  ASSOCIATION_LIST = 'ASSOCIATION_LIST',
  EURO_CLASSES = 'EURO_CLASSES',
  TRAVEL_FORM_LIST = 'TRAVEL_FORM_LIST',
  ACTIVE_VEHICLES_COUNT = 'ACTIVE_VEHICLES_COUNT',
  FOLDER_CLIENT_ADDRESSES = 'FOLDER_CLIENT_ADDRESSES',
  FOLDER_PERMISSIONS = 'FOLDER_PERMISSIONS'
}
