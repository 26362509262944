import { useDialog } from '@libs/common/v2';

import { CertificatesCreateForm } from '../../components/form';

const useAddCertificateDialog = () => {
  const { openDialog } = useDialog();

  const open = (proceedingId: string) => {
    openDialog(({ closeDialog }) => <CertificatesCreateForm closeDialog={closeDialog} proceedingId={proceedingId} />);
  };

  return {
    openAddCertificateDialog: open
  };
};

export default useAddCertificateDialog;
