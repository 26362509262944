import { useDialog } from '@libs/common/v2';

import LocksSummaryDialog, { LocksSummaryData } from '../components/print-details/dialog/LocksSummaryDialog';

export function useOpenLocksSummaryDialog(showFormsNumbers = false) {
  const { openDialog } = useDialog();

  const open = (license: LocksSummaryData) => {
    openDialog(({ closeDialog }) => (
      <LocksSummaryDialog data={license} onClose={closeDialog} showFormsNumbers={showFormsNumbers} />
    ));
  };

  return { openLocksSummaryDialog: open };
}
