import { usePaginatedQuery } from 'react-query';
import { DriverCertificateSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { DriverCertificateSnapshotClient } from '../../model';
import { parseDriverCertificatesList } from '../../parsers';
import { endpointsConfig } from '../endpoints-config';

import { DriverCertificatesQueryKeysEnum } from '.';

export const getDriverCertificatesExtended = async (_, params: DriverCertificateSearchFilter) => {
  const { data } = await API.driverCertificate.getDriverCertificatesDetailsExtendedPage(params, {
    ...endpointsConfig.getDriverCertificatesDetailsExtendedPage
  });

  return {
    ...data,
    content: parseDriverCertificatesList(data.content)
  };
};

export default function useDriverCertificatesExtendedQuery(params: DriverCertificateSearchFilter, queryConfig = {}) {
  return usePaginatedQuery<IPaginatedModel<DriverCertificateSnapshotClient>>(
    [DriverCertificatesQueryKeysEnum.DRIVER_CERTIFICATES_EXTENDED_LIST, params],
    getDriverCertificatesExtended,
    queryConfig
  );
}
