import { useTranslation } from 'react-i18next';
import { FinancialSecurityDetails, FinancialSecuritySearchFilter } from '@ibtm/domain';
import { Tooltip } from '@mui/material';
import moment from 'moment';

import {
  CamelCasePath,
  Icon,
  SelectOptionPartial,
  TableFilter,
  TableFilterMapper,
  TableSortMapper,
  useCreateColumns
} from '@libs/common/v2';
import {
  convertCalendarDate,
  convertDateToDateFormat,
  DateRangeISO,
  getCalendarDate,
  getNumberValue
} from '@libs/common/v2/utils';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

interface IExpirationDate {
  date: string;
}

function ExpirationDate({ date }: IExpirationDate) {
  const [t] = useTranslation();
  const dateConverted = convertDateToDateFormat(date);

  return moment().isAfter(date, 'day') ? (
    <Tooltip title={t('financialSecurity:validation.expiredFinancialSecurity')}>
      <div className="flex items-center">
        {dateConverted}
        <Icon icon="ArchiveIcon" color="error" iconWrapper="ml-8" />
      </div>
    </Tooltip>
  ) : (
    <>{dateConverted}</>
  );
}

const useFinancialSecurityColumns = (
  visibleColumns: Array<CamelCasePath<FinancialSecurityDetails>>,
  folderTypeKey: string
) => {
  const { createColumns } = useCreateColumns<FinancialSecurityDetails>({
    pathToTranslate: 'financialSecurity:field'
  });

  const mappedFilterFields: TableFilterMapper<FinancialSecurityDetails, FinancialSecuritySearchFilter> = {
    typeKey: (typeKeys: SelectOptionPartial<string>[]) => ({ typeKeys: typeKeys.map(({ value }) => value) }),
    expirationDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      expireDateFrom: getCalendarDate(dateFrom),
      expireDateTo: getCalendarDate(dateTo)
    }),
    amountPln: ({ min, max }: { min: number; max: number }) => ({ amountPlnFrom: min, amountPlnTo: max }),
    amountEur: ({ min, max }: { min: number; max: number }) => ({ amountEurFrom: min, amountEurTo: max }),
    notes: (remarks: string) => ({ remarks }),
    statusKey: (statusKeys: SelectOptionPartial<string>[]) => ({ statusKeys: statusKeys.map(({ value }) => value) }),
    currencyExchangeRateExchangeRateFrom: ({ dateFrom, dateTo }: DateRangeISO) => ({
      operativeExchangeDayFrom: getCalendarDate(dateFrom),
      operativeExchangeDayTo: getCalendarDate(dateTo)
    }),
    currencyExchangeRateExchangeRate: ({ min, max }: { min: number; max: number }) => ({
      currencyExchangeRateFrom: min,
      currencyExchangeRateTo: max
    })
  };

  const mappedSortFields: TableSortMapper<FinancialSecurityDetails> = {
    currencyExchangeRateExchangeRate: 'currencyExchangeRate.rate',
    currencyExchangeRateExchangeRateFrom: 'releaseDate',
    amountPln: 'amountPln',
    amountEur: 'amountEur'
  };

  const getTypeKeyDictionary = () => {
    if (folderTypeKey === DomainDictionaryEntry.FOLDER_TYPE.PPO) {
      return DomainDictionaryEnum.FINANCE_SECURITY_KIND_PPO;
    }
    return DomainDictionaryEnum.FINANCE_SECURITY_KIND_TP_AND_OP;
  };

  const columns = createColumns(
    [
      {
        accessor: 'typeKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: getTypeKeyDictionary()
      },
      {
        id: 'expirationDate',
        type: 'CUSTOM_COLUMN',
        customAccessor: row => <ExpirationDate date={convertCalendarDate(row?.expirationDate)} />,
        filter: TableFilter.Datepicker,
        width: 200,
        customXlsxAccessor: row => convertCalendarDate(row.expirationDate)
      },
      {
        id: 'amountPln',
        type: 'CUSTOM_COLUMN',
        customAccessor: row => getNumberValue(row?.amountPln, 2),
        filter: TableFilter.NumberRange
      },
      {
        id: 'amountEur',
        type: 'CUSTOM_COLUMN',
        customAccessor: row => getNumberValue(row?.amountEur, 2),
        filter: TableFilter.NumberRange
      },
      {
        id: 'currencyExchangeRateExchangeRate',
        header: 'exchangeRate',
        type: 'CUSTOM_COLUMN',
        customAccessor: row => getNumberValue(row?.currencyExchangeRate?.exchangeRate, 4),
        filter: TableFilter.NumberRange
      },
      {
        header: 'validFrom',
        accessor: 'currencyExchangeRate.exchangeRateFrom',
        type: 'DATE'
      },
      {
        accessor: 'subject.name',
        header: 'entrepreneur',
        type: 'TEXT',
        isFilterable: false,
        isSortable: false
      },
      {
        accessor: 'notes',
        type: 'TEXT',
        truncateLength: 35,
        width: 350
      },
      {
        accessor: 'statusKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: 'FINANCE_SECURITY_STATUS'
      },
      {
        accessor: 'modified',
        type: 'DATE',
        isFilterable: false
      },
      {
        accessor: 'modifier.name',
        header: 'modifier',
        type: 'TEXT',
        isFilterable: false
      }
    ],
    visibleColumns
  );
  return { columns, mappedFilterFields, mappedSortFields };
};

export default useFinancialSecurityColumns;
