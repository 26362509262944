import { useTranslation } from 'react-i18next';
import { AccountSnapshot } from '@avispon/user';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import { DropdownListButton, TableButton, TableButtonDelete } from '@libs/common/v2';

import { useUserStatusActionsDefinition } from '@libs/user/hooks';

interface OpenDeleteUserPopUpProps {
  userDetails: AccountSnapshot;
  onAccept: () => void;
  isInRow?: boolean;
  isTableButton?: boolean;
  actionKey?: UIElementNameEnum;
}

function DeleteUserPopUp({
  userDetails,
  onAccept,
  isTableButton = false,
  isInRow = false,
  actionKey
}: OpenDeleteUserPopUpProps) {
  const [t] = useTranslation();
  const { handleChangeUserStatusToDeleted } = useUserStatusActionsDefinition({
    userDetails,
    refetchUserDetails: onAccept
  });

  const getTableButton = () => {
    return isInRow ? (
      <TableButton
        label={t('user:action.deleteUser')}
        onClick={handleChangeUserStatusToDeleted}
        actionKey={actionKey}
      />
    ) : (
      <TableButtonDelete onClick={handleChangeUserStatusToDeleted} actionKey={actionKey} />
    );
  };

  return isTableButton ? (
    getTableButton()
  ) : (
    <DropdownListButton
      label={t('user:action.deleteUser')}
      onClick={handleChangeUserStatusToDeleted}
      actionKey={actionKey}
    />
  );
}

export default DeleteUserPopUp;
