import {
  FolderCreateRequest,
  FolderDetails,
  FolderDetailsExtended,
  SubjectAddressDetails,
  SubjectDetailsExtended,
  SubjectPartnershipPartnerSnapshot,
  SubjectSnapshot
} from '@ibtm/domain';

import { DomainDictionaryEntry } from '@libs/domain/config';

export interface SubjectDetailsClient extends SubjectDetailsExtended {
  idR?: string;
}

export enum FolderFormDataAddressTypeEnum {
  HQ = 'HQ',
  MAIN = 'MAIN',
  CORRESPONDENCE = 'CORRESPONDENCE'
}

export interface FolderDetailsClient extends Omit<FolderDetailsExtended, 'subject'> {
  id?: string;
  idR?: string;
  subject: SubjectDetailsClient;
  travelFormId?: string;
  travelFormVersion?: number;
  travelFormsBook?: boolean;
  interbus?: boolean;
  subjectMainAddress?: SubjectAddressDetails;
}

export interface ParsedFolderDetailsClient extends Omit<FolderDetailsExtended, 'subject' | 'typeKey' | 'addresses'> {
  idR?: string;
  subject: ParsedSubjectDetails;
  travelFormId?: string;
  travelFormVersion?: number;
  travelFormsBook?: boolean;
  interbus?: boolean;
  typeKey?: {
    value?: string;
  };
  addresses: { [key in FolderFormDataAddressTypeEnum]: ParsedAddress };
  isMainAddressEditable?: boolean;
}

export interface ParsedAddress extends Omit<SubjectAddressDetails, 'typeKey' | 'voivodeshipKey'> {
  typeKey?:
    | string
    | {
        value: string;
      };
  voivodeshipKey?: string | { value: string };
}

interface ParsedSubjectDetails extends Omit<SubjectDetailsClient, 'legalFormKey' | 'subjectTypeKey'> {
  legalFormKey?: {
    value?: string;
  };
  subjectTypeKey?: string;
}

export enum FolderDetailsTabEnum {
  APPLICATIONS = 'APPLICATIONS',
  FOLDER = 'FOLDER',
  SUBJECT = 'SUBJECT',
  ADDRESSES = 'ADDRESSES',
  BASIC_DATA = 'BASIC_DATA',
  PROXIES = 'PROXIES',
  CERTIFICATE_S = 'CERTIFICATE_S',
  SINGLE_AUTHORIZATION = 'SINGLE_AUTHORIZATION',
  EKMT = 'EKMT',
  ORDERS = 'ORDERS',
  PERMISSIONS = 'PERMISSIONS',
  FINANCIAL_SECURITY = 'FINANCIAL_SECURITY',
  DRIVERS = 'DRIVERS',
  VEHICLES = 'VEHICLES',
  INSURANCE_POLICIES = 'INSURANCE_POLICIES',
  EXPLOATATION_BASES = 'EXPLOATATION_BASES',
  SUSPENSIONS = 'SUSPENSIONS',
  PROCEEDINGS = 'PROCEEDINGS',
  RENTALS = 'RENTALS',
  LOCKS = 'LOCKS',
  HISTORY = 'HISTORY',
  TRANSPORT_MANAGER = 'TRANSPORT_MANAGER',
  NOTE = 'NOTE',
  ENTERPRENEURS = 'ENTERPRENEURS',
  FOREIGN_PERMISSIONS = 'FOREIGN_PERMISSIONS'
}

export enum FolderTypeColorTypes {
  GREEN,
  INDIGO,
  YELLOW,
  BLUE,
  RED,
  PURPLE,
  ORANGE,
  PINK
}

export const FOLDER_TYPE_COLOR_CONFIG = [
  { typeKey: DomainDictionaryEntry.FOLDER_TYPE.KS, color: FolderTypeColorTypes.GREEN },
  { typeKey: DomainDictionaryEntry.FOLDER_TYPE.MZ, color: FolderTypeColorTypes.INDIGO },
  { typeKey: DomainDictionaryEntry.FOLDER_TYPE.PPO, color: FolderTypeColorTypes.YELLOW },
  { typeKey: DomainDictionaryEntry.FOLDER_TYPE.OP, color: FolderTypeColorTypes.BLUE },
  { typeKey: DomainDictionaryEntry.FOLDER_TYPE.TP, color: FolderTypeColorTypes.RED },
  { typeKey: DomainDictionaryEntry.FOLDER_TYPE.ZZ, color: FolderTypeColorTypes.PURPLE },
  { typeKey: DomainDictionaryEntry.FOLDER_TYPE.WITD, color: FolderTypeColorTypes.ORANGE },
  { typeKey: DomainDictionaryEntry.FOLDER_TYPE.PA, color: FolderTypeColorTypes.PINK }
];

export enum RegisteredFolderFormEnum {
  FOLDER_FORM = 'FOLDER_FORM'
}

export const AddressInitialValues = {
  postCode: '',
  voivodeshipKey: '',
  county: '',
  commune: '',
  postCity: '',
  city: '',
  street: '',
  propertyNumber: '',
  apartmentNumber: '',
  countryCodeKey: DomainDictionaryEntry.COUNTRY_CODE.POLAND
};

export const InitialCreateFormValues: FolderCreateRequest = {
  typeKey: '',
  subject: {
    name: '',
    shortName: '',
    nip: '',
    regon: '',
    krs: '',
    legalFormKey: '',
    countryOfOrigin: '',
    businessEndDate: null,
    givenName: '',
    surname: '',
    addresses: [
      {
        sameAsMainAddress: false,
        typeKey: DomainDictionaryEntry.ADDRESS_TYPE.MAIN,
        address: {}
      },
      {
        sameAsMainAddress: false,
        typeKey: DomainDictionaryEntry.ADDRESS_TYPE.HQ,
        address: {}
      },
      {
        sameAsMainAddress: false,
        typeKey: DomainDictionaryEntry.ADDRESS_TYPE.CORRESPONDENCE,
        address: {}
      }
    ],
    contactAddresses: [
      {
        typeKey: DomainDictionaryEntry.ADDRESS_TYPE.MAIN,
        phone: null,
        email: null,
        fax: null
      }
    ]
  }
};

export const InitialEditFormValues = {
  ...InitialCreateFormValues,
  travelFormId: null,
  travelFormVersion: '',
  travelFormsBook: false,
  interbus: false
};

export interface UserDetails {
  id: string;
  name: string;
}

export interface AddressDetails {
  countryCode?: string;
  postCode?: string;
  postCity?: string;
  city?: string;
  street?: string;
  propertyNumber?: string;
  apartmentNumber?: string;
}

export interface FolderDetailsSnapshot {
  id: string;
  number: string;
  typeKey: string;
  subject: SubjectSnapshot;
  nip: string;
  statusKey: string;
  blocked: boolean;
  borrower: UserDetails;
  verifier: UserDetails;
  transmissionToSTA: string;
  association: string;
  address: AddressDetails;
  created: string;
  introducer: string;
  modified: string;
  modifier: UserDetails;
  author: UserDetails;
  version?: number;
}

export interface FolderDetailsSnapshotDTO {
  id?: string;
  author?: UserDetails;
  modifier?: UserDetails;
  created?: string;
  modified?: string;
  version?: number;
  number?: string;
  typeKey?: string;
  statusKey?: string;
  subject?: {
    id?: string;
    name?: string;
    nip?: number;
  };
  nip?: string;
  blocked?: boolean;
  address?: AddressDetails;
  transmissionToSTA?: string;
  association?: string;
  borrower?: UserDetails;
  verifier?: UserDetails;
}

export interface Entrepreneur extends FolderDetails {
  customId: string;
}

export interface EntrepreneurDetailsSnapshot extends SubjectPartnershipPartnerSnapshot {
  statusKey?: string;
  customId?: string;
  folderNumber?: string;
}

export enum FolderSliderSectionEnum {
  SUBJECT = 'SUBJECT',
  CONTACT = 'CONTACT',
  PERMISSIONS = 'PERMISSIONS',
  OTHER = 'OTHER',
  NOTES = 'NOTES',
  BASIC_DATA = 'BASIC_DATA',
  TRANSFER = 'TRANSFER'
}

export interface IFolderDetailsParams {
  folderId: string;
  [key: string]: string | undefined;
}

export enum FolderMainAddressEditorPermissionGroup {
  WZZ_NACZELNIK = 'WZZ_Naczelnik'
}
