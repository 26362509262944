export * from './dictionary.model';
export * from './report-type.model';
export * from './field-type.model';

export * from './form.model';

export * from './routes.model';

export enum HiddenReportParameterColumnEnum {
  HIDDEN_USER_ID = 'hidden_unit_id',
  HIDDEN_UNIT_ID = 'hidden_unit_id'
}

export enum WidthType {
  MM = 'report.column-width-type.mm',
  PERCENT = 'report.column-width-type.percent'
}

export type ParameterData = { type?: string; dictionaryName?: string; required?: boolean; columnKey?: string };
export type ParametersData = { [_: string]: ParameterData };

export const defaultGenerateReportInitialValues = {
  reportNumber: '',
  organizationUnitId: '',
  targetTypes: [] as string[] | { id: string; value: string; name: string }[],
  dataSets: [],
  commonDynamicParametersValues: {} as { [key: string]: string }
};

export type GenerateReportFormValues = typeof defaultGenerateReportInitialValues;

export const REFETCH_INTERVAL_MS = 5000;
