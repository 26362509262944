import { ForeignAuthorityPermissionCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

async function createForeignAuthorityPermission(formData: ForeignAuthorityPermissionCreateRequest) {
  const { data } = await API.permissions.createForeignAuthorityPermission(formData, {
    ...endpointsConfig.createForeignAuthorityPermission
  });
  return data;
}

function useCreateForeignAuthorityPermissionMutation() {
  return useMutation(createForeignAuthorityPermission);
}

export default useCreateForeignAuthorityPermissionMutation;
