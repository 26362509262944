import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { GridLayout, Tab, TabContent, Tabs, TabsContent, useFormV2Context, useTab } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import { AssociationTabEnum } from '../../model';

import { AssociationAddressSection, AssociationDataSection, EntrepreneursSection } from '.';

function AssociationFormContent({ isAddressCountryFieldAvaliable }: { isAddressCountryFieldAvaliable: boolean }) {
  const classes = useStyles();
  const [t] = useTranslation();
  const { formMode } = useFormV2Context();
  const { createMode } = useViewModesV2(formMode);
  const [activeTab, setActiveTab] = useTab<AssociationTabEnum>(AssociationTabEnum.BASIC_DATA);

  return createMode ? (
    <GridLayout itemProps={{ lg: 6 }}>
      <AssociationDataSection />
      <AssociationAddressSection isAddressCountryFieldAvaliable={isAddressCountryFieldAvaliable} />
    </GridLayout>
  ) : (
    <>
      <Tabs
        value={activeTab}
        onChange={setActiveTab}
        variant="scrollable"
        orientation="horizontal"
        className={classes.tabsWrapper}
      >
        <Tab
          label={<div style={{ textAlign: 'center', margin: 'auto' }}>{t('associations:tab.basicData')}</div>}
          value={AssociationTabEnum.BASIC_DATA}
          isHorizontalTab
          key={`${AssociationTabEnum.BASIC_DATA}-tab`}
        />

        <Tab
          label={<div style={{ textAlign: 'center', margin: 'auto' }}>{t('associations:tab.entrepreneurs')}</div>}
          value={AssociationTabEnum.ENTREPRENEURS}
          isHorizontalTab
          key={`${AssociationTabEnum.ENTREPRENEURS}-tab`}
        />
      </Tabs>
      <TabsContent value={activeTab} className={classes.tabsContent}>
        <TabContent value={AssociationTabEnum.BASIC_DATA} key={AssociationTabEnum.BASIC_DATA}>
          <GridLayout itemProps={{ md: 6, xs: 12 }}>
            <AssociationDataSection />
            <AssociationAddressSection isAddressCountryFieldAvaliable={isAddressCountryFieldAvaliable} />
          </GridLayout>
        </TabContent>
        <TabContent value={AssociationTabEnum.ENTREPRENEURS} key={AssociationTabEnum.ENTREPRENEURS}>
          <EntrepreneursSection />
        </TabContent>
      </TabsContent>
    </>
  );
}
const useStyles = makeStyles<Theme>(theme => ({
  tabsContent: {
    overflow: important('hidden'),
    width: '100%'
  },
  tabsWrapper: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    width: '100%'
  }
}));

export default AssociationFormContent;
