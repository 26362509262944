import { UseFormWatch } from 'react-hook-form';
import { useEffectOnce } from 'react-use';
import { debounce, isUndefined, omitBy } from 'lodash';

import { AdditionalFilterEnum, IAdditionalFilter } from '@libs/common/v2/components';
import { SEARCH_TEXT_DEBOUNCE_TIME_LONG } from '@libs/common/v2/configs';

const useApplyAdditionalFilters = (
  watch: UseFormWatch<Record<string, any>>,
  errors,
  setTableQueryParams,
  additionalFilters?: IAdditionalFilter[]
) => {
  // sprawdzanie czy sa w dodatkowych filtrach dictionary selecty
  // funkcja rejestruje zmiany w przekazanych filtrach globalnych oraz dodatkowych i odświeża tabelę
  const setDebouceValue = debounce(data => {
    let filteredObj: Record<string, any> = omitBy(data, isUndefined);
    const dictionarySelects =
      additionalFilters?.filter(item => item.type === AdditionalFilterEnum.DICTIONARY_SELECT) || [];

    // konwersja multiselectow slownikowych
    if (dictionarySelects.length) {
      const dictionaryKeys = dictionarySelects.map(item => item.name);
      Object.keys(filteredObj).forEach((key: string) => {
        if (dictionaryKeys.includes(key)) {
          filteredObj = {
            ...filteredObj,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            [key]: filteredObj[key].map((item: { value: string | number | boolean }) => item.value)
          };
        }
      });
    }

    return setTableQueryParams(omitBy(filteredObj, isUndefined));
  }, SEARCH_TEXT_DEBOUNCE_TIME_LONG);

  useEffectOnce(() => {
    const subscription = watch(data => {
      if (!Object.values(errors).length) {
        setDebouceValue(data);
      }
    });
    return () => subscription.unsubscribe();
  });
};

export default useApplyAdditionalFilters;
