import { QueryConfig, useQuery } from 'react-query';
import { DocumentNoteDetailsList } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { DocumentsIssuedQueryEnum } from '../../model';
import { endpointsConfig } from '../endpoints-config';

const getParcelSummary = async (_, documentId: string) => {
  const { data } = await API.documents.getDocumentNotes(documentId, endpointsConfig.getParcelNotes);
  return data;
};

export function useGetParcelNotesQuery(documentId: string, config?: QueryConfig<DocumentNoteDetailsList>) {
  return useQuery([DocumentsIssuedQueryEnum.PARCEL_NOTE, documentId], getParcelSummary, config);
}
