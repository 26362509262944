import { i18n } from '@libs/common';
import { BooleanValue, ColumnTypesEnum, ISingleColumn } from '@libs/common/v2';
import { convertDateToDateFormat } from '@libs/common/v2/utils';

import { UserFilterV2 } from '@libs/user';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { LicenseCopiesColumnsEnum, LicenseCopySnapshotClient } from '../model/license.model';

export const DEFAULT_COLUMNS = [
  LicenseCopiesColumnsEnum.APPLICATION_NUMBER,
  LicenseCopiesColumnsEnum.LICENSE_NUMBER,
  LicenseCopiesColumnsEnum.FORM_NUMBER,
  LicenseCopiesColumnsEnum.PRINT_DATE,
  LicenseCopiesColumnsEnum.VALID_FROM,
  LicenseCopiesColumnsEnum.VALID_TO,
  LicenseCopiesColumnsEnum.DATE_OF_ISSUE_EXTERNAL,
  LicenseCopiesColumnsEnum.SIGNER_NAME,
  LicenseCopiesColumnsEnum.BELOW_THREE_AND_HALF_TONS,
  LicenseCopiesColumnsEnum.STATUS
];

const columnDefaultProperties = { isFilterable: true, isSortable: true };
const LICENSE_COPY_COLUMN: ISingleColumn<LicenseCopySnapshotClient>[] = [
  { id: 'applicationNumber', type: ColumnTypesEnum.TEXT },
  {
    id: 'cancellationReason',
    type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
    dictionaryName: DomainDictionaryEnum.LICENSE_CANCELLATION_REASON,
    ...columnDefaultProperties
  },
  {
    id: 'cancelledBy',
    type: ColumnTypesEnum.TEXT,
    filter: UserFilterV2,
    ...columnDefaultProperties
  },
  {
    id: 'cancellationDate',
    type: ColumnTypesEnum.DATE,
    dateFormatFn: convertDateToDateFormat,
    ...columnDefaultProperties
  },
  {
    id: 'subjectName',
    type: ColumnTypesEnum.TEXT,
    ...columnDefaultProperties
  },
  {
    id: 'subjectAddress',
    type: ColumnTypesEnum.TEXT,
    ...columnDefaultProperties
  },
  {
    id: 'licenseNumber',
    type: ColumnTypesEnum.TEXT,
    ...columnDefaultProperties
  },
  {
    id: 'formNumber',
    type: ColumnTypesEnum.TEXT,
    ...columnDefaultProperties
  },
  {
    id: 'printDate',
    type: ColumnTypesEnum.DATE,
    dateFormatFn: convertDateToDateFormat,
    ...columnDefaultProperties
  },
  {
    id: 'validTo',
    type: ColumnTypesEnum.DATE,
    dateFormatFn: convertDateToDateFormat,
    ...columnDefaultProperties
  },
  {
    id: 'validFrom',
    type: ColumnTypesEnum.DATE,
    dateFormatFn: convertDateToDateFormat,
    ...columnDefaultProperties
  },
  {
    id: 'dateOfIssueExternal',
    type: ColumnTypesEnum.DATE,
    dateFormatFn: convertDateToDateFormat,
    ...columnDefaultProperties
  },
  {
    id: 'signerName',
    type: ColumnTypesEnum.MULTI_SELECT,
    filter: UserFilterV2,
    ...columnDefaultProperties
  },
  {
    id: 'belowThreeAndHalfTons',
    type: ColumnTypesEnum.BOOLEAN,
    customAccessor: row => {
      return <BooleanValue value={row?.belowThreeAndHalfTons} isTableCell />;
    },
    customXlsxAccessor: row => (row?.belowThreeAndHalfTons ? i18n.t('action.yes') : i18n.t('action.no')),
    ...columnDefaultProperties
  },
  {
    id: 'status',
    type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
    dictionaryName: DomainDictionaryEnum.PERMISSION_STATUS,
    ...columnDefaultProperties
  }
];

export default LICENSE_COPY_COLUMN;
