import { FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AutocompleteSelectField, SelectOption } from '@libs/common/v2';

import { useReportTypeOperatorsQuery } from '../../../api';

interface IProps {
  fieldName: FieldPath<FieldValues>;
  isDisabled?: boolean;
}

function ConditionOperatorSelect({ fieldName, isDisabled }: IProps) {
  const [t] = useTranslation();
  const { data: operators, isLoading } = useReportTypeOperatorsQuery();
  const options: SelectOption[] = operators?.items.map(condition => ({
    id: condition.operator,
    value: condition.operator,
    name: condition.operator,
    paramNumber: condition.paramNumber
  }));

  return (
    <AutocompleteSelectField
      name={`${fieldName}.operator`}
      label={t('alerts:condition.operator')}
      isLoading={isLoading}
      options={options}
      validationSingleSelect={{ required: [] }}
      isDisabled={!options?.length || isDisabled}
      isRequired
    />
  );
}

export default ConditionOperatorSelect;
