import { TableOptions } from 'react-table';
import { ProceedingSnapshot } from '@ibtm/domain';

import { CamelCasePath, useTableLayoutProps } from '@libs/common/v2';

import { useProceedingsQuery } from '../api';
import { ProceedingSnapshotClient } from '../model';

import useProceedingsColumns from './useProceedingsColumns';

const useProceedingsTable = (
  visibleColumns: Array<CamelCasePath<ProceedingSnapshotClient>>,
  initialParams = {},
  tableOptions: Partial<TableOptions<any>> = {},
  showAllFilters = false,
  trimTextFilters = false
) => {
  const { columns, mappedFilterFields, mappedSortFields } = useProceedingsColumns(
    showAllFilters,
    trimTextFilters,
    visibleColumns
  );

  return useTableLayoutProps<ProceedingSnapshotClient, ProceedingSnapshot>({
    tableHookQuery: useProceedingsQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tableOptions
    },
    tableHookQueryInitialParams: initialParams
  });
};

export default useProceedingsTable;
