import { useEffect, useMemo, useState } from 'react';
import { useAppConfig } from '@libs/app-config';
import { Grid } from '@mui/material';

import { partialTranslate } from '@libs/common';
import {
  InputMode,
  SectionWithFields,
  SelectOption,
  SwitchField,
  typedNameV2,
  useFormV2Context,
  YearFilterContext,
  YearSelectField
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { useElementVisibility } from '@libs/permission';

import { DomainDictionaryEntry, DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import { FolderDetailsClient } from '@libs/domain/folder';
import { BasicLimitTabEnum, BasicLimitTable } from '@libs/domain/foreign-permission';
import BasicLimitRadioButtons from '@libs/domain/foreign-permission/components/disposable-permits/basic-limit/BasicLimitRadioButtons';
import LimitTransferTable from '@libs/domain/foreign-permission/components/disposable-permits/limit-transfer/LimitTransferTable';

import { SingleAuthorizationBasicTable } from './singleAuthorizationBasic';

const getLabel = partialTranslate('folder:details.tab.singleAuthorization.section.general.field');

function SingleAuthorizationTab() {
  const { yearFilterContextValues, setYearFilterContextValues } = useDomainConfigContext();
  const { formMode, watch, setValue } = useFormV2Context();
  const { areSingleAuthorizationLimitsHidden } = useAppConfig();
  const { viewMode } = useViewModesV2(formMode);
  const { checkIsElementVisible } = useElementVisibility();
  const [tab, setTab] = useState<BasicLimitTabEnum>(BasicLimitTabEnum.LIST);

  const arePermitsBlocked = watch('arePermitsBlocked') as boolean;
  const inputYear = watch('selectedYear') as SelectOption<string>;

  useEffect(() => {
    setValue('selectedYear', yearFilterContextValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // TODO: Integracja z backend blokady wydawania zezwoleń GITDDEV-10513
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arePermitsBlocked]);

  const values = useMemo(
    () => ({ maxYear: inputYear?.value || yearFilterContextValues?.value }),
    [inputYear?.value, yearFilterContextValues?.value]
  );

  const radioButtons = <BasicLimitRadioButtons tab={tab} tabChange={setTab} />;

  return (
    <>
      <div className="flex justify-end mt-5">
        <YearSelectField
          inputMode={InputMode.FORM}
          name="selectedYear"
          key="selectedYear"
          setGlobalYearSelect={setYearFilterContextValues}
        />
      </div>
      <SectionWithFields
        className="mt-5"
        fields={[
          ...(checkIsElementVisible(DomainUIElementEnum.FOLDER_BASIC_LIMIT_BLOCK_PERMITS_VIEW)
            ? [
                <SwitchField
                  label={getLabel('arePermitsBlocked')}
                  name={typedNameV2<FolderDetailsClient>('permissionIssuingBlocked')}
                  isDisabled={viewMode}
                  inputMode={
                    !viewMode &&
                    checkIsElementVisible(DomainUIElementEnum.FOLDER_BASIC_LIMIT_BLOCK_PERMITS_EDIT) &&
                    InputMode.FORM
                  }
                  key="arePermitsBlocked"
                />
              ]
            : [])
        ]}
      />

      <YearFilterContext.Provider value={values}>
        {!areSingleAuthorizationLimitsHidden && (
          <Grid xs={12} item>
            {checkIsElementVisible(DomainUIElementEnum.FOLDER_BASIC_LIMIT_VIEW) && (
              <div>
                {tab === BasicLimitTabEnum.LIST && (
                  <BasicLimitTable
                    queryInitialParams={{ typeKeyIn: [DomainDictionaryEntry.FOREIGN_PERMIT_TYPE.SINGLE] }}
                    titleHeaderContent={radioButtons}
                    isCollapsable
                  />
                )}
                {tab === BasicLimitTabEnum.HISTORY && <LimitTransferTable titleHeaderContent={radioButtons} />}
              </div>
            )}
          </Grid>
        )}
        <Grid xs={12} item>
          {checkIsElementVisible(DomainUIElementEnum.FOLDER_BASIC_LIMIT_RELEASED_OR_ISSUED_TABLE_VIEW) ? (
            <SingleAuthorizationBasicTable
              permissionType={DomainDictionaryEntry.FOREIGN_PERMIT_TYPE.SINGLE}
              isCollapsable
            />
          ) : null}
        </Grid>
      </YearFilterContext.Provider>
    </>
  );
}

export default SingleAuthorizationTab;
