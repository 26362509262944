import { queryCache } from 'react-query';
import { ApplicationDetails as ApplicationDetailsClient } from '@ibtm/client-domain';
import { ApplicationDetailsExtended } from '@ibtm/domain';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import { FormMode, InputMode } from '@libs/common/v2';

import { AdditionalAddress, CorrespondenceAddressButton } from '@libs/domain/address';
import {
  ApplicationHistoryTable,
  ApplicationNoteSection,
  ApplicationTravelSections,
  ApplicationValidFromField,
  ApplicationValidFromFieldProps
} from '@libs/domain/application/components';
import {
  ApplicationFilesList,
  DocumentsList,
  EntrepreneursTable,
  FinancialSecurityList
} from '@libs/domain/application/components/lists';
import {
  AdditionalSectionLicenseOrPermission,
  AddressTab,
  CertificateSTab,
  DriverCertificateSelectField,
  DriverTab,
  ExploitationBaseTab,
  FolderDetailsFields,
  IDriverCertificateSelectFieldProps,
  IDriverTabProps,
  IssuingAuthorityField,
  LicensePermissionsDischargeSection,
  ScheduleTypeKeyDictionaryField,
  SPOApplicationsSelectField,
  VehicleTab
} from '@libs/domain/application/components/tabs';
import { AddressesInputMode } from '@libs/domain/application/components/tabs/AddressTab';
import { CertificateSSectionsEnum } from '@libs/domain/application/components/tabs/certificate-s/CertificateSTab';
import useVisiblePermissionTables from '@libs/domain/application/hooks/useVisiblePermissionTables';
import { AdditionalSectionLicenseOrPermissionType, Application } from '@libs/domain/application/model';
import {
  AttachmentsMatrixWithWrapper,
  AttachmentsSectionWithWrapper,
  PermissionMatrix
} from '@libs/domain/application/shared';
import {
  IMisspellApplicationsTableProps,
  MisspellApplicationPermissionTable,
  MisspellApplicationPermissionTypeEnum,
  MisspellApplicationsTable
} from '@libs/domain/application-misspell';
import { SinglePermitTable } from '@libs/domain/application-single-permit';
import { ApplicationWarningsList } from '@libs/domain/application-warnings';
import { DomainDictionaryEntry, DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import { DriverCertificatesTable } from '@libs/domain/driver-certificate';
import { filterFinancialSecurityActions, FinancialSecuritySummarySection } from '@libs/domain/financial-security';
import { ForeignPermitsTable } from '@libs/domain/foreign-permission';
import { LicenseCopiesTable, LicensesTable } from '@libs/domain/license';
import { PartnerApplicantsList } from '@libs/domain/partner-applicants';
import { PermissionCopiesTable, PermissionsTable } from '@libs/domain/permission';
import { MoneyTransfersForApplicationTable, PricingItemsTable } from '@libs/domain/pricing';
import { ProxyTable } from '@libs/domain/proxy';
import { ReputationSection } from '@libs/domain/subject';
import { ITransitScheduleSections, TransitScheduleTab } from '@libs/domain/transit-schedule';
import {
  TransportManagersActionButtonsEnum,
  TransportManagersColumnsEnum,
  TransportManagersTable
} from '@libs/domain/transport-manager';
import {
  VehicleFormData,
  VehicleSectionFields,
  VehicleTableEditionVisibleValidator,
  VehicleTableRowActionEnum
} from '@libs/domain/vehicle';

import { ApplicationQueryKeysEnum } from '../../application';
import { IEntrepreneursTable } from '../../application/components/lists/EntrepreneursTable';
import { CabotageCountryDictionaryField } from '../../application/components/tabs/cabotage';
import CabotageRemarksTextField from '../../application/components/tabs/cabotage/CabotageRemarksTextField';
import { CertificateSApplicationSection } from '../../application/components/tabs/certificate-s';
import {
  FolderSelectFieldProps,
  FoldersSelectField,
  ILineTypeDictionaryFieldProps,
  LastSupplementReceiptDate,
  LineTypeDictionaryField,
  SelectDataOfApplicant
} from '../../application/components/tabs/general';
import ApplicationTermField from '../../application/components/tabs/general/ApplicationTermField';
import { IFolderDetailsFields } from '../../application/components/tabs/general/FolderDetailsFields';
import { SelectAddressTypeSelectField } from '../../application/components/tabs/general/SelectAddressTypeSelectField';
import { IAttachmentsSection } from '../../application/shared/attachments/components/AttachmentsSection';
import { MisspellApplicationDriverCertificateTable } from '../../application-misspell/components/MisspellApplicationDriverCertificateTable';
import { ISinglePermitTable } from '../../application-single-permit/components/SinglePermitTable';
import { IForeignPermitsTable } from '../../foreign-permission/components/released-permits/ForeignPermitsTable';
import { IPermissionCopiesTable } from '../../permission/components/PermissionCopiesTable';
import { IPermissionsTable } from '../../permission/components/PermissionsTable';
import { IMoneyTransfersForApplicationTable } from '../../pricing/components/MoneyTransfersForApplicationTable';

type CreateRegistryParams = {
  applicationData: ApplicationDetailsExtended | ApplicationDetailsClient;
  applicationId: string;
  isVisible: ReturnType<typeof useVisiblePermissionTables>['isVisible'];
  isOperatorPortal: boolean;
};

export const createViewFieldsRegistry = ({
  applicationId,
  applicationData,
  isVisible,
  isOperatorPortal
}: CreateRegistryParams) => ({
  DOCUMENTS_COLLECTION: () => (
    <DocumentsList
      generateDocumentParams={{ applicationId, receiptType: applicationData?.receiptTypeKey }}
      applicationId={applicationId}
      applicationType={applicationData?.typeKey}
      permissionsKeys={{
        generateDocument: DomainUIElementEnum.DOCUMENTS_ISSUED_GENERATE_DOCUMENT_BUTTON,
        generateCallToFillGaps: DomainUIElementEnum.DOCUMENTS_ISSUED__DOCUMENTS_GENERATE_CALL_TO_FILL_GAPS_BUTTON,
        generateSubmissionConfirmation:
          DomainUIElementEnum.APPLICATION_OUTGOING_DOCUMENTS_GENERATE_SUBMISSION_CONFIRMATION_BUTTON
      }}
      tableActionKeys={[
        DomainUIElementEnum.APPLICATION_OUTGOING_DOCUMENTS_UPLOAD_DOCUMENT_BUTTON,
        DomainUIElementEnum.DOCUMENTS_ISSUED_DOCUMENTS_DOWNLOAD_BUTTON,
        DomainUIElementEnum.DOCUMENTS_ISSUED_DOCUMENTS_FORWARD_TO_SHIPMENT_BUTTON,
        DomainUIElementEnum.APPLICATION_OUTGOING_DOCUMENTS_ENTER_DELIVERY_DATE_BUTTON,
        DomainUIElementEnum.APPLICATION_OUTGOING_DOCUMENTS_CANCEL_BUTTON
      ]}
    />
  ),
  FILES_COLLECTION: () => (
    <ApplicationFilesList tableActionKeys={[DomainUIElementEnum.APPLICATION_DOWNLOAD_FILE_BUTTON]} />
  ),
  LICENSES_COLLECTION: () =>
    isVisible.LISENSE ? (
      <LicensesTable
        applicationId={applicationId}
        isTableDataVisible={isOperatorPortal}
        isHeaderActions={isOperatorPortal}
      />
    ) : null,
  LICENSE_COPIES_COLLECTION: () =>
    isVisible.LICENSE_COPY ? (
      <LicenseCopiesTable
        applicationId={applicationId}
        isTableDataVisible={isOperatorPortal}
        isHeaderActions={isOperatorPortal}
        isWarningEnabled={isOperatorPortal}
      />
    ) : null,
  DRIVER_CERTIFICATES_COLLECTION: () =>
    isVisible.DRIVER_CERTIFICATE ? (
      <DriverCertificatesTable
        applicationId={applicationId}
        isTableDataVisible={isOperatorPortal}
        isHeaderActions={isOperatorPortal}
      />
    ) : null,
  APPLICATION_HISTORY_COLLECTION: () => (
    <ApplicationHistoryTable id={applicationId} actionKey={DomainUIElementEnum.APPLICATION_HISTORY_TABLE} />
  ),
  DRIVERS_COLLECTION: ({
    visibleActions,
    searchDriverDialogSearchParams
  }: Pick<IDriverTabProps, 'visibleActions' | 'searchDriverDialogSearchParams'>) => (
    <DriverTab
      applicationId={applicationId}
      applicationTypeKey={applicationData.typeKey}
      folderId={applicationData.folder?.id}
      transferredFoldersIds={applicationData?.licenseApplicationDetails?.transferApplicationDataDetails?.folders?.map(
        folder => folder.id
      )}
      visibleActions={visibleActions}
      subjectNip={applicationData?.subject?.nip}
      searchDriverDialogSearchParams={searchDriverDialogSearchParams}
      permissionsKeysObject={{
        ADD_DRIVER: DomainUIElementEnum.APPLICATION_DRIVERS_ADD_DRIVER_BUTTON,
        SEARCH: DomainUIElementEnum.APPLICATION_DRIVERS_SEARCH_DRIVER_BUTTON,
        EDIT: DomainUIElementEnum.APPLICATION_DRIVERS_EDIT_DRIVER_BUTTON,
        PREVIEW: DomainUIElementEnum.APPLICATION_DRIVERS_SHOW_DETAILS_BUTTON,
        DELETE: DomainUIElementEnum.APPLICATION_DRIVERS_DELETE_DRIVER_BUTTON,
        GO_TO_PROCEEDING: DomainUIElementEnum.APPLICATION_DRIVERS_GO_TO_PROCEEDING
      }}
    />
  ),
  PERMISSIONS_COLLECTION: ({
    permissionType,
    initialParams,
    titleKey,
    rowActionsEnabled,
    ...props
  }: IPermissionsTable) => {
    const rowActionsEnabledIfStatusAccomplished = rowActionsEnabled?.filter(item => !['edit', 'delete'].includes(item));
    return isVisible[permissionType] ? (
      <PermissionsTable
        {...props}
        titleKey={titleKey}
        permissionType={permissionType}
        applicationId={applicationId}
        rowActionsEnabled={
          applicationData?.statusKey === DomainDictionaryEntry.APPLICATION_STATUS.ACCOMPLISHED
            ? rowActionsEnabledIfStatusAccomplished
            : rowActionsEnabled
        }
        initialParams={{
          ...initialParams,
          applicationIdIn: applicationId ? [applicationId] : undefined
        }}
        isWarningEnabled={isOperatorPortal}
        isTableDataVisible={isOperatorPortal}
        isHeaderActions={isOperatorPortal}
      />
    ) : null;
  },
  PERMISSIONS_FOREIGN_COLLECTION: ({ title, ...props }: IForeignPermitsTable) =>
    isVisible.FOREIGN_PERMISSION ? (
      <ForeignPermitsTable
        {...props}
        title={title}
        applicationId={applicationId}
        isTableDataVisible={isOperatorPortal}
        isHeaderActions={isOperatorPortal}
      />
    ) : null,
  PERMISSION_COPIES_COLLECTION: ({ permissionType, titleKey, ...props }: IPermissionCopiesTable) =>
    isVisible[permissionType] ? (
      <PermissionCopiesTable
        titleKey={titleKey}
        applicationId={applicationId}
        {...props}
        isTableDataVisible={isOperatorPortal}
        isHeaderActions={isOperatorPortal}
      />
    ) : null,
  FINANCIAL_SECURITY_COLLECTION: () => {
    const submissionReceiptDate = applicationData.submissionReceiptDate as unknown as string;
    return (
      <FinancialSecurityList
        subjectId={applicationData?.subject?.id}
        applicationId={applicationId}
        folderId={applicationData?.folder?.id}
        transferredFoldersIds={applicationData?.licenseApplicationDetails?.transferApplicationDataDetails?.folders?.map(
          folder => folder.id
        )}
        actions={['details']}
        permissionsKeys={{
          details: DomainUIElementEnum.APPLICATION_FINACIAL_SECURITY_SHOW_DETAILS_BUTTON
        }}
        folderTypeKey={applicationData?.folder?.typeKey}
        exchangeRateFrom={submissionReceiptDate}
        exchangeRateTo={submissionReceiptDate}
        visibleColumns={[
          'typeKey',
          'expirationDate',
          'amountPln',
          'amountEur',
          'currencyExchangeRateExchangeRate',
          'currencyExchangeRateExchangeRateFrom',
          'subjectName',
          'notes',
          'statusKey'
        ]}
        isSection
      />
    );
  },
  VEHICLES_COLLECTION: ({
    hiddenActions,
    hiddenDetailsFields,
    editValidator = VehicleTableEditionVisibleValidator.ALWAYS,
    detailsFieldsMode
  }: {
    hiddenActions?: VehicleTableRowActionEnum[];
    editValidator?: VehicleTableEditionVisibleValidator;
    hiddenDetailsFields?: VehicleSectionFields[];
    detailsFieldsMode?: { [key in keyof VehicleFormData]?: InputMode };
  }) => (
    <VehicleTab
      applicationId={applicationId}
      folderTypeKey={applicationData?.folder?.typeKey}
      folderNumber={applicationData?.folder?.number}
      folderId={applicationData?.folder?.id}
      transferredFoldersIds={applicationData?.licenseApplicationDetails?.transferApplicationDataDetails?.folders?.map(
        folder => folder.id
      )}
      actionKeys={{
        details: DomainUIElementEnum.APPLICATION_VEHICLES_SHOW_DETAILS_BUTTON,
        download: DomainUIElementEnum.APPLICATION_VEHICLES_DOWNLOAD_VEHICLES_BUTTON,
        generateRaport: DomainUIElementEnum.APPLICATION_VEHICLES_GENERATE_RAPORT_BUTTON,
        downloadFromCEPIK: DomainUIElementEnum.APPLICATION_VEHICLES_DOWNLOAD_FROM_CEPIK_BUTTON,
        downloadXLSX: DomainUIElementEnum.APPLICATION_VEHICLES_DOWNLOAD_XLSX
      }}
      tableActionKeys={[DomainUIElementEnum.APPLICATION_VEHICLES_SHOW_DETAILS_BUTTON]}
      hiddenActions={hiddenActions}
      applicationType={applicationData?.typeKey}
      applicationCategory={applicationData?.categoryKey}
      editValidator={editValidator}
      hiddenDetailsFields={hiddenDetailsFields}
      detailsFieldsMode={detailsFieldsMode}
      isSection
      onVehicleSearchSuccess={() => applicationId && queryCache.invalidateQueries(ApplicationQueryKeysEnum.APPLICATION)}
      vehicleModificationConcernsKey={applicationData?.licenseApplicationDetails?.vehicleModificationConcernsKey}
    />
  ),
  PROXIES_COLLECTION: () => {
    const isSpoUE = applicationData?.categoryKey === DomainDictionaryEntry.APPLICATION_CATEGORY.SPO_UE;
    return (
      <ProxyTable
        applicationId={applicationData.id}
        actions={['details']}
        actionKeys={{
          details: DomainUIElementEnum.APPLICATION_PROXY_SHOW_DETAILS_BUTTON
        }}
        visibleColumns={[
          'name',
          'surname',
          'typeKey',
          'postCode',
          'postCity',
          'city',
          'street',
          'paid',
          'archiveNumber'
        ]}
        hiddenFieldIds={['validUntil']}
        isCitizenshipFieldVisible={isSpoUE}
        tableActionKeys={[DomainUIElementEnum.APPLICATION_PROXY_SHOW_DETAILS_BUTTON]}
        isSection
      />
    );
  },
  QUOTATIONS_COLLECTION: () => (
    <PricingItemsTable
      tableActionKeys={[DomainUIElementEnum.APPLICATION_PRICING_CHANGE_DATA_FROM_PRICING_BUTTON]}
      applicationId={applicationId}
      witd={
        applicationData &&
        'sCertificateApplicationDetails' in applicationData &&
        applicationData?.sCertificateApplicationDetails?.witd
      }
      isSection
    />
  ),
  MONEY_TRANSFER_COLLECTION: ({ fieldId }: IMoneyTransfersForApplicationTable) => {
    const areUnconfirmedTableActionsVisible = ![
      DomainDictionaryEntry.APPLICATION_STATUS.ISSUED,
      DomainDictionaryEntry.APPLICATION_STATUS.RELEASED_WAITING_FOR_ZPO
    ].includes(applicationData?.statusKey);

    const isForeignPermit = applicationData?.categoryKey === DomainDictionaryEntry.APPLICATION_CATEGORY.FOREIGN_PERMIT;
    const isTravelForm = applicationData?.categoryKey === DomainDictionaryEntry.APPLICATION_CATEGORY.TRAVEL_FORM;

    return (
      <MoneyTransfersForApplicationTable
        fieldId={fieldId}
        applicationId={applicationId}
        areUnconfirmedTableActionsVisible={areUnconfirmedTableActionsVisible}
        isDropdownVisible={isForeignPermit || isTravelForm}
        areSearchLinkUnlinkMoneyTransferButtonsVisible={isForeignPermit}
        permissionsKeys={{
          generateDocument: DomainUIElementEnum.APPLICATION_PRICING_GENERATE_RELEASE_DOCUMENT_BUTTON,
          link: DomainUIElementEnum.APPLICATION_PRICING_LINK_TRANSFERS_WITH_APPLICATION_BUTTON,
          unlink: DomainUIElementEnum.APPLICATION_PRICING_UNLINK_TRANSFERS_WITH_APPLICATION_BUTTON,
          delete: DomainUIElementEnum.APPLICATION_PRICING_DELETE_TRANSFER_BUTTON,
          search: DomainUIElementEnum.APPLICATION_PRICING_SEARCH_TRANSFER_BUTTON
        }}
        tableActionKeys={[]}
        isSection
      />
    );
  },
  REPUTATION_COLLECTION: () => (
    <ReputationSection
      subjectData={applicationData.subject}
      actionKey={DomainUIElementEnum.APPLICATION_SUBJECT_REPUTATION_SECTION}
    />
  ),
  ATTACHMENTS_COLLECTION: ({ fieldId }: IAttachmentsSection) => (
    <AttachmentsSectionWithWrapper
      applicationId={applicationId}
      fieldId={fieldId}
      applicationType={applicationData?.typeKey}
    />
  ),
  ATTACHMENTS_MATRIX_COLLECTION: props => <AttachmentsMatrixWithWrapper applicationId={applicationId} {...props} />,
  PERMISSIONS_MATRIX_COLLECTION: props => (
    <PermissionMatrix
      applicationId={applicationId}
      folderType={applicationData?.folder?.typeKey}
      folderId={applicationData?.folder?.id}
      {...props}
    />
  ),
  EXPLOITATION_BASES_COLLECTION: () => {
    const transferredFoldersIds =
      applicationData?.licenseApplicationDetails?.transferApplicationDataDetails?.folders?.map(item => item.id);

    return (
      <ExploitationBaseTab
        folderId={applicationData.folder?.id}
        tableActionKeys={[
          DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_SHOW_DETAILS_BUTTON,
          DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_SHOW_GEOPORTAL_BUTTON
        ]}
        transferredFoldersIds={transferredFoldersIds}
      />
    );
  },
  TRANSPORT_MANAGERS_COLLECTION: () => (
    <TransportManagersTable
      actionKey={UIElementNameEnum.APPLICATION_TRANSPORT_MANAGERS_TABLE}
      applicationId={applicationData.id}
      folderId={applicationData?.folder?.id}
      folderType={applicationData?.folder?.typeKey}
      transferredFoldersIds={applicationData?.licenseApplicationDetails?.transferApplicationDataDetails?.folders?.map(
        folder => folder.id
      )}
      queryParams={{ applicationIdIn: [applicationData.id] }}
      visibleColumns={[
        TransportManagersColumnsEnum.NAME,
        TransportManagersColumnsEnum.SURNAME,
        TransportManagersColumnsEnum.CERTIFICATE_NUMBER,
        TransportManagersColumnsEnum.PERIOD_OF_VALIDITY,
        TransportManagersColumnsEnum.REPUTATION_KEY,
        TransportManagersColumnsEnum.MANAGEMENT_PERIOD,
        TransportManagersColumnsEnum.TYPE_KEY
      ]}
      visibleActionButtons={[TransportManagersActionButtonsEnum.PREVIEW]}
      permissionsKeysObject={{
        PREVIEW: DomainUIElementEnum.APPLICATION_TRANSPORT_MANAGERS_SHOW_DETAILS_BUTTON,
        CHECK_REPUTATION: DomainUIElementEnum.APPLICATION_TRANSPORT_MANAGERS_CHECK_IN_KREPTD_BUTTON,
        SHOW_REPUTATION: DomainUIElementEnum.APPLICATION_TRANSPORT_MANAGERS_SHOW_REPUTATION_BUTTON
      }}
    />
  ),
  ADDRESSES_TAB_CONTENT: ({ inputMode }: { inputMode?: AddressesInputMode }) => (
    <AddressTab
      applicationId={applicationId}
      actionKey={DomainUIElementEnum.APPLICATION_ADDRESSES_UPDATE}
      isCountryFieldAvaliable={applicationData?.folder?.typeKey === DomainDictionaryEntry.FOLDER_TYPE.MZ}
      inputMode={inputMode}
    />
  ),
  ADDRESS_FIELDS: ({ fieldId, isSubjectNipFieldVisible }: { fieldId?: string; isSubjectNipFieldVisible: boolean }) => (
    <AdditionalAddress
      subjectId={applicationData?.subject?.id}
      formGroupName={fieldId ?? 'address'}
      isCountryFieldAvaliable={applicationData?.folder?.typeKey === DomainDictionaryEntry.FOLDER_TYPE.MZ}
      isSubjectNipFieldVisible={isSubjectNipFieldVisible}
    />
  ),
  ENTREPRENEURS_COLLECTION: ({ rowActionsEnabled }: IEntrepreneursTable) => (
    <EntrepreneursTable
      subjectId={applicationData?.subject?.id}
      actionKey={DomainUIElementEnum.APPLICATION_SUBJECT_ENTREPRENEURS_TABLE}
      rowActionsEnabled={rowActionsEnabled}
    />
  ),
  NOTE_COLLECTION: () => (
    <ApplicationNoteSection
      applicationId={applicationId}
      formMode={FormMode.VIEW}
      editButtonActionKey={DomainUIElementEnum.NOTE_UPDATE}
    />
  ),
  TRANSFER_FOLDER_SUBJECT_DATA: ({ visibleFields }: IFolderDetailsFields) => (
    <FolderDetailsFields
      transferFolder={applicationData?.licenseApplicationDetails?.transferApplicationDataDetails?.folders[0]}
      visibleFields={visibleFields}
      folderTypeKeys={[applicationData?.folder?.typeKey]}
      isFolderRequired={[
        DomainDictionaryEntry.APPLICATION_TYPE.PB7A,
        DomainDictionaryEntry.APPLICATION_TYPE.PB7B,
        DomainDictionaryEntry.APPLICATION_TYPE.PB30
      ].includes(applicationData?.typeKey)}
    />
  ),
  SCERTIFICATES_COLLECTION: ({
    hiddenVehicleDetailsFields,
    visibleSections
  }: {
    hiddenVehicleDetailsFields?: VehicleSectionFields[];
    visibleSections?: CertificateSSectionsEnum[];
  }) => (
    <CertificateSTab
      applicationId={applicationId}
      folderId={applicationData.folder?.id}
      folderType={applicationData?.folder?.typeKey}
      transferredFoldersIds={applicationData?.licenseApplicationDetails?.transferApplicationDataDetails?.folders?.map(
        folder => folder.id
      )}
      applicationType={applicationData?.typeKey}
      applicationCategory={applicationData?.categoryKey}
      hiddenVehicleDetailsFields={hiddenVehicleDetailsFields}
      visibleSections={visibleSections}
    />
  ),
  SCERTIFICATES_APPLICATION_SECTION: ({ fieldId }: { fieldId: string }) => (
    <CertificateSApplicationSection
      witd={
        applicationData &&
        'sCertificateApplicationDetails' in applicationData &&
        applicationData?.sCertificateApplicationDetails?.witd
      }
      applicationNumber={applicationData?.number}
      fieldId={fieldId}
    />
  ),
  TRANSIT_SCHEDULE: ({ fieldId, visibleSections }: { fieldId: string; visibleSections?: ITransitScheduleSections }) => (
    <TransitScheduleTab
      visibleSections={visibleSections}
      applicationData={{
        id: applicationData.id,
        typeKey: applicationData.typeKey,
        categoryKey: applicationData.categoryKey
      }}
      fieldId={fieldId}
    />
  ),
  LICENSE_PERMISSIONS_DISCHARGE_SECTION: () => (
    <LicensePermissionsDischargeSection
      applicationId={applicationId}
      applicationTypeKey={applicationData.typeKey}
      folderId={applicationData?.folder.id}
      transferredFoldersIds={applicationData?.licenseApplicationDetails?.transferApplicationDataDetails?.folders?.map(
        folder => folder.id
      )}
    />
  ),
  SCHEDULE_TYPE_KEY_DICTIONARY_FIELD: props => <ScheduleTypeKeyDictionaryField {...props} />,
  LINE_TYPE_DICTIONARY_FIELD: (props: ILineTypeDictionaryFieldProps) => <LineTypeDictionaryField {...props} />,
  PARTNER_APPLICANTS_COLLECTION: () => {
    return (
      <PartnerApplicantsList
        applicationId={applicationId}
        subjectId={applicationData?.subject?.id}
        applicationStatusKey={applicationData?.statusKey}
      />
    );
  },
  MISSPELL_APPLICATIONS_COLLECTION: (props: Omit<IMisspellApplicationsTableProps, 'applicationId'>) => (
    <MisspellApplicationsTable applicationId={applicationData.id} {...props} />
  ),
  MISSPELL_APPLICATIONS_DRIVER_CERTIFICATE_COLLECTION: () => (
    <MisspellApplicationDriverCertificateTable applicationId={applicationData.id} />
  ),
  MISSPELL_APPLICATION_PERMISSION_COLLECTION: ({
    permissionType
  }: {
    permissionType: MisspellApplicationPermissionTypeEnum;
  }) => <MisspellApplicationPermissionTable applicationId={applicationId} permissionType={permissionType} />,
  SPO_APPLICATIONS_SELECT: props => <SPOApplicationsSelectField {...props} />,
  ISSUING_AUTHORITY_INPUT: props => <IssuingAuthorityField {...props} />,
  APPLICATION_TERM_INPUT: props => <ApplicationTermField {...props} />,
  SINGLE_PERMIT_COLLECTION: ({ fieldId }: ISinglePermitTable) => (
    <SinglePermitTable applicationCreatedAt={applicationData?.created} fieldId={fieldId} />
  ),
  FINANCIAL_SECURITY_EXCHANGE_COLLECTION: ({ fieldId, formMode }: { fieldId: string; formMode: FormMode }) => (
    <FinancialSecuritySummarySection
      formMode={formMode}
      fieldId={fieldId}
      folderType={applicationData?.folder?.typeKey}
      applicationId={applicationId}
      sectionActionKey={DomainUIElementEnum.APPLICATION_FINACIAL_SECURITY_SUMMARY_SECTION}
    />
  ),
  APPLICATION_VALID_FROM_FIELD: (props: ApplicationValidFromFieldProps) => <ApplicationValidFromField {...props} />,
  OUTGOIN_DOCUMENTS_ADDITIONAL_SECTION_LICENSE_OR_PERMISSION: ({
    fieldId,
    sectionTypes
  }: {
    fieldId?: string;
    sectionTypes?: AdditionalSectionLicenseOrPermissionType[];
  }) => (
    <AdditionalSectionLicenseOrPermission
      sectionTypes={sectionTypes}
      fieldId={fieldId}
      applicationData={applicationData}
    />
  ),
  TRAVEL_FORM_COLLECTION: () => <ApplicationTravelSections />,
  WARNINGS_COLLECTION: () => (
    <ApplicationWarningsList applicationData={applicationData} applicationId={applicationId} />
  ),
  CORRESPONDENCE_ADDRESS_BUTTON: () => <CorrespondenceAddressButton applicationId={applicationId} />,
  DRIVER_CERTIFICATE_SELECT: (props: IDriverCertificateSelectFieldProps) => <DriverCertificateSelectField {...props} />,
  FOLDER_SELECT_FIELD: (props: FolderSelectFieldProps) => (
    <FoldersSelectField
      hasActiveLicense={'hasActiveLicense' in applicationData && applicationData?.hasActiveLicense}
      folderTypeKey={applicationData?.folder?.typeKey}
      {...props}
    />
  ),
  LAST_SUPPLEMENT_RECEIPT_DATE: props => (
    <LastSupplementReceiptDate
      {...props}
      statusKey={applicationData?.statusKey}
      wasIncomplete={applicationData && 'wasIncomplete' in applicationData && applicationData?.wasIncomplete}
    />
  ),
  CABOTAGE_COUNTRY_FIELD: props => <CabotageCountryDictionaryField {...props} />,
  CABOTAGE_REMARKS_FIELD: props => <CabotageRemarksTextField {...props} />,
  SELECT_ADDRESS_TYPE_FIELD: props => <SelectAddressTypeSelectField {...props} />,
  SELECT_DATA_OF_APPLICANT: props => <SelectDataOfApplicant {...props} />
});

export const createFormFieldsRegistry = ({
  applicationId,
  applicationData,
  isVisible,
  isOperatorPortal
}: CreateRegistryParams) => ({
  DOCUMENTS_COLLECTION: () => (
    <DocumentsList
      generateDocumentParams={{ applicationId, receiptType: applicationData?.receiptTypeKey }}
      applicationId={applicationId}
      applicationType={applicationData?.typeKey}
      permissionsKeys={{
        generateDocument: DomainUIElementEnum.DOCUMENTS_ISSUED_GENERATE_DOCUMENT_BUTTON,
        generateCallToFillGaps: DomainUIElementEnum.DOCUMENTS_ISSUED__DOCUMENTS_GENERATE_CALL_TO_FILL_GAPS_BUTTON,
        generateSubmissionConfirmation:
          DomainUIElementEnum.APPLICATION_OUTGOING_DOCUMENTS_GENERATE_SUBMISSION_CONFIRMATION_BUTTON
      }}
      tableActionKeys={[
        DomainUIElementEnum.APPLICATION_OUTGOING_DOCUMENTS_UPLOAD_DOCUMENT_BUTTON,
        DomainUIElementEnum.DOCUMENTS_ISSUED_DOCUMENTS_DOWNLOAD_BUTTON,
        DomainUIElementEnum.DOCUMENTS_ISSUED_DOCUMENTS_FORWARD_TO_SHIPMENT_BUTTON,
        DomainUIElementEnum.APPLICATION_OUTGOING_DOCUMENTS_ENTER_DELIVERY_DATE_BUTTON,
        DomainUIElementEnum.APPLICATION_OUTGOING_DOCUMENTS_CANCEL_BUTTON
      ]}
    />
  ),
  FILES_COLLECTION: () => (
    <ApplicationFilesList
      actionKey={DomainUIElementEnum.APPLICATION_UPLOAD_FILE_BUTTON}
      tableActionKeys={[
        DomainUIElementEnum.APPLICATION_DELETE_FILE_BUTTON,
        DomainUIElementEnum.APPLICATION_SIGN_FILE_BUTTON,
        DomainUIElementEnum.APPLICATION_DOWNLOAD_FILE_BUTTON
      ]}
    />
  ),
  LICENSES_COLLECTION: () =>
    isVisible.LISENSE ? (
      <LicensesTable
        applicationId={applicationId}
        isTableDataVisible={isOperatorPortal}
        isHeaderActions={isOperatorPortal}
      />
    ) : null,
  LICENSE_COPIES_COLLECTION: () =>
    isVisible.LICENSE_COPY ? (
      <LicenseCopiesTable
        applicationId={applicationId}
        isTableDataVisible={isOperatorPortal}
        isHeaderActions={isOperatorPortal}
        isWarningEnabled={isOperatorPortal}
      />
    ) : null,
  DRIVER_CERTIFICATES_COLLECTION: () =>
    isVisible.DRIVER_CERTIFICATE ? (
      <DriverCertificatesTable
        applicationId={applicationId}
        isTableDataVisible={isOperatorPortal}
        isHeaderActions={isOperatorPortal}
      />
    ) : null,
  DRIVERS_COLLECTION: ({
    visibleActions,
    searchDriverDialogSearchParams
  }: Pick<IDriverTabProps, 'visibleActions' | 'searchDriverDialogSearchParams'>) => (
    <DriverTab
      applicationId={applicationId}
      applicationTypeKey={applicationData.typeKey}
      folderId={applicationData.folder?.id}
      transferredFoldersIds={applicationData?.licenseApplicationDetails?.transferApplicationDataDetails?.folders?.map(
        folder => folder.id
      )}
      visibleActions={visibleActions}
      subjectNip={applicationData?.subject?.nip}
      searchDriverDialogSearchParams={searchDriverDialogSearchParams}
      permissionsKeysObject={{
        ADD_DRIVER: DomainUIElementEnum.APPLICATION_DRIVERS_ADD_DRIVER_BUTTON,
        SEARCH: DomainUIElementEnum.APPLICATION_DRIVERS_SEARCH_DRIVER_BUTTON,
        EDIT: DomainUIElementEnum.APPLICATION_DRIVERS_EDIT_DRIVER_BUTTON,
        PREVIEW: DomainUIElementEnum.APPLICATION_DRIVERS_SHOW_DETAILS_BUTTON,
        DELETE: DomainUIElementEnum.APPLICATION_DRIVERS_DELETE_DRIVER_BUTTON,
        GO_TO_PROCEEDING: DomainUIElementEnum.APPLICATION_DRIVERS_GO_TO_PROCEEDING
      }}
    />
  ),
  APPLICATION_HISTORY_COLLECTION: () => (
    <ApplicationHistoryTable id={applicationId} actionKey={DomainUIElementEnum.APPLICATION_HISTORY_TABLE} />
  ),
  PERMISSIONS_COLLECTION: ({
    permissionType,
    initialParams,
    titleKey,
    rowActionsEnabled,
    ...props
  }: IPermissionsTable) => {
    const rowActionsEnabledIfStatusAccomplished = rowActionsEnabled?.filter(item => !['edit', 'delete'].includes(item));
    return isVisible[permissionType] ? (
      <PermissionsTable
        {...props}
        titleKey={titleKey}
        permissionType={permissionType}
        applicationId={applicationData?.id}
        rowActionsEnabled={
          applicationData?.statusKey === DomainDictionaryEntry.APPLICATION_STATUS.ACCOMPLISHED
            ? rowActionsEnabledIfStatusAccomplished
            : rowActionsEnabled
        }
        initialParams={{
          ...initialParams,
          applicationIdIn: applicationId ? [applicationId] : undefined
        }}
        isWarningEnabled={isOperatorPortal}
        isTableDataVisible={isOperatorPortal}
        isHeaderActions={isOperatorPortal}
      />
    ) : null;
  },
  PERMISSIONS_FOREIGN_COLLECTION: ({ title, ...props }: IForeignPermitsTable) =>
    isVisible.FOREIGN_PERMISSION ? (
      <ForeignPermitsTable
        {...props}
        title={title}
        applicationId={applicationId}
        isTableDataVisible={isOperatorPortal}
        isHeaderActions={isOperatorPortal}
      />
    ) : null,
  PERMISSION_COPIES_COLLECTION: ({ permissionType, titleKey, ...props }: IPermissionCopiesTable) =>
    isVisible[permissionType] ? (
      <PermissionCopiesTable
        titleKey={titleKey}
        applicationId={applicationId}
        {...props}
        isTableDataVisible={isOperatorPortal}
        isHeaderActions={isOperatorPortal}
      />
    ) : null,
  FINANCIAL_SECURITY_COLLECTION: () => {
    const submissionReceiptDate = applicationData.submissionReceiptDate as unknown as string;
    return (
      <FinancialSecurityList
        subjectId={applicationData?.subject?.id}
        applicationId={applicationId}
        folderId={applicationData?.folder?.id}
        transferredFoldersIds={applicationData?.licenseApplicationDetails?.transferApplicationDataDetails?.folders?.map(
          folder => folder.id
        )}
        actions={filterFinancialSecurityActions(
          ['details', 'create', 'edit', 'delete', 'search', 'expire', 'restore'],
          applicationData?.financialSecurityModificationTypeKey
        )}
        permissionsKeys={{
          create: DomainUIElementEnum.APPLICATION_FINACIAL_SECURITY_ADD_SECURITY_BUTTON,
          search: DomainUIElementEnum.APPLICATION_FINACIAL_SECURITY_SEARCH_SECURITY_BUTTON,
          edit: DomainUIElementEnum.APPLICATION_FINACIAL_SECURITY_EDIT_SECURITY_BUTTON,
          delete: DomainUIElementEnum.APPLICATION_FINACIAL_SECURITY_DELETE_SECURITY_BUTTON,
          details: DomainUIElementEnum.APPLICATION_FINACIAL_SECURITY_SHOW_DETAILS_BUTTON,
          expire: DomainUIElementEnum.APPLICATION_FINACIAL_SECURITY_EXPIRE_BUTTON,
          restore: DomainUIElementEnum.APPLICATION_FINACIAL_SECURITY_RESTORE_BUTTON
        }}
        folderTypeKey={applicationData?.folder?.typeKey}
        exchangeRateFrom={submissionReceiptDate}
        exchangeRateTo={submissionReceiptDate}
        visibleColumns={[
          'typeKey',
          'expirationDate',
          'amountPln',
          'amountEur',
          'currencyExchangeRateExchangeRate',
          'currencyExchangeRateExchangeRateFrom',
          'subjectName',
          'notes',
          'statusKey'
        ]}
        isSection
      />
    );
  },
  VEHICLES_COLLECTION: ({
    hiddenActions,
    hiddenDetailsFields,
    editValidator = VehicleTableEditionVisibleValidator.ALWAYS,
    detailsFieldsMode
  }: {
    hiddenActions?: VehicleTableRowActionEnum[];
    editValidator?: VehicleTableEditionVisibleValidator;
    hiddenDetailsFields?: VehicleSectionFields[];
    detailsFieldsMode?: { [key in keyof VehicleFormData]?: InputMode };
  }) => (
    <VehicleTab
      applicationStatusKey={applicationData?.statusKey}
      applicationId={applicationId}
      folderTypeKey={applicationData?.folder?.typeKey}
      folderId={applicationData?.folder?.id}
      folderNumber={applicationData?.folder?.number}
      transferredFoldersIds={applicationData?.licenseApplicationDetails?.transferApplicationDataDetails?.folders?.map(
        folder => folder.id
      )}
      actionKeys={{
        add: DomainUIElementEnum.APPLICATION_VEHICLES_ADD_VEHICLE_BUTTON,
        edit: DomainUIElementEnum.APPLICATION_VEHICLES_EDIT_VEHICLE_BUTTON,
        details: DomainUIElementEnum.APPLICATION_VEHICLES_SHOW_DETAILS_BUTTON,
        delete: DomainUIElementEnum.APPLICATION_VEHICLES_DELETE_VEHICLE_BUTTON,
        search: DomainUIElementEnum.APPLICATION_VEHICLES_SEARCH_VEHICLE_BUTTON,
        download: DomainUIElementEnum.APPLICATION_VEHICLES_DOWNLOAD_VEHICLES_BUTTON,
        clear: DomainUIElementEnum.APPLICATION_VEHICLES_CLEAR_VEHICLES_BUTTON,
        generateRaport: DomainUIElementEnum.APPLICATION_VEHICLES_GENERATE_RAPORT_BUTTON,
        downloadFromCEPIK: DomainUIElementEnum.APPLICATION_VEHICLES_DOWNLOAD_FROM_CEPIK_BUTTON,
        downloadXLSX: DomainUIElementEnum.APPLICATION_VEHICLES_DOWNLOAD_XLSX
      }}
      tableActionKeys={[
        DomainUIElementEnum.APPLICATION_VEHICLES_EDIT_VEHICLE_BUTTON,
        DomainUIElementEnum.APPLICATION_VEHICLES_DELETE_VEHICLE_BUTTON,
        DomainUIElementEnum.APPLICATION_VEHICLES_SHOW_DETAILS_BUTTON
      ]}
      hiddenActions={hiddenActions}
      applicationType={applicationData?.typeKey}
      applicationCategory={applicationData?.categoryKey}
      editValidator={editValidator}
      hiddenDetailsFields={hiddenDetailsFields}
      detailsFieldsMode={detailsFieldsMode}
      isSection
      onVehicleSearchSuccess={() => applicationId && queryCache.invalidateQueries(ApplicationQueryKeysEnum.APPLICATION)}
      vehicleModificationConcernsKey={applicationData?.licenseApplicationDetails?.vehicleModificationConcernsKey}
    />
  ),
  PROXIES_COLLECTION: () => {
    const isSpoUE = applicationData?.categoryKey === DomainDictionaryEntry.APPLICATION_CATEGORY.SPO_UE;
    return (
      <ProxyTable
        applicationId={applicationData.id}
        actions={['details', 'create', 'edit', 'delete']}
        visibleColumns={[
          'name',
          'surname',
          'typeKey',
          'postCode',
          'postCity',
          'city',
          'street',
          'paid',
          'archiveNumber'
        ]}
        hiddenFieldIds={['validUntil']}
        actionKeys={{
          add: DomainUIElementEnum.APPLICATION_PROXY_ADD_PROXY_BUTTON,
          edit: DomainUIElementEnum.APPLICATION_PROXY_EDIT_PROXY_BUTTON,
          delete: DomainUIElementEnum.APPLICATION_PROXY_DELETE_PROXY_BUTTON,
          details: DomainUIElementEnum.APPLICATION_PROXY_SHOW_DETAILS_BUTTON
        }}
        tableActionKeys={[
          DomainUIElementEnum.APPLICATION_PROXY_EDIT_PROXY_BUTTON,
          DomainUIElementEnum.APPLICATION_PROXY_DELETE_PROXY_BUTTON,
          DomainUIElementEnum.APPLICATION_PROXY_SHOW_DETAILS_BUTTON
        ]}
        isCitizenshipFieldVisible={isSpoUE}
        isSection
      />
    );
  },
  QUOTATIONS_COLLECTION: () => (
    <PricingItemsTable
      tableActionKeys={[DomainUIElementEnum.APPLICATION_PRICING_CHANGE_DATA_FROM_PRICING_BUTTON]}
      applicationId={applicationId}
      witd={
        applicationData &&
        'sCertificateApplicationDetails' in applicationData &&
        applicationData?.sCertificateApplicationDetails?.witd
      }
      isSection
    />
  ),
  MONEY_TRANSFER_COLLECTION: ({ fieldId }: IMoneyTransfersForApplicationTable) => {
    const areUnconfirmedTableActionsVisible = ![
      DomainDictionaryEntry.APPLICATION_STATUS.ISSUED,
      DomainDictionaryEntry.APPLICATION_STATUS.RELEASED_WAITING_FOR_ZPO
    ].includes(applicationData?.statusKey);

    const isForeignPermit = applicationData?.categoryKey === DomainDictionaryEntry.APPLICATION_CATEGORY.FOREIGN_PERMIT;
    const isTravelForm = applicationData?.categoryKey === DomainDictionaryEntry.APPLICATION_CATEGORY.TRAVEL_FORM;

    return (
      <MoneyTransfersForApplicationTable
        applicationId={applicationId}
        areUnconfirmedTableActionsVisible={areUnconfirmedTableActionsVisible}
        isDropdownVisible={isForeignPermit || isTravelForm}
        fieldId={fieldId}
        permissionsKeys={{
          search: DomainUIElementEnum.APPLICATION_PRICING_SEARCH_TRANSFER_BUTTON,
          delete: DomainUIElementEnum.APPLICATION_PRICING_DELETE_TRANSFER_BUTTON,
          link: DomainUIElementEnum.APPLICATION_PRICING_LINK_TRANSFERS_WITH_APPLICATION_BUTTON,
          unlink: DomainUIElementEnum.APPLICATION_PRICING_UNLINK_TRANSFERS_WITH_APPLICATION_BUTTON,
          generateDocument: DomainUIElementEnum.APPLICATION_PRICING_GENERATE_RELEASE_DOCUMENT_BUTTON
        }}
        tableActionKeys={[
          DomainUIElementEnum.APPLICATION_PRICING_DELETE_TRANSFER_BUTTON,
          DomainUIElementEnum.APPLICATION_PRICING_EDIT_TRANSFER_BUTTON
        ]}
        isSection
      />
    );
  },
  REPUTATION_COLLECTION: () => (
    <ReputationSection
      subjectData={applicationData.subject}
      actionKey={DomainUIElementEnum.APPLICATION_SUBJECT_REPUTATION_SECTION}
    />
  ),
  ATTACHMENTS_COLLECTION: ({ fieldId }: IAttachmentsSection) => (
    <AttachmentsSectionWithWrapper
      applicationId={applicationId}
      fieldId={fieldId}
      applicationType={applicationData?.typeKey}
    />
  ),
  ATTACHMENTS_MATRIX_COLLECTION: props => <AttachmentsMatrixWithWrapper applicationId={applicationId} {...props} />,
  PERMISSIONS_MATRIX_COLLECTION: props => (
    <PermissionMatrix
      applicationId={applicationId}
      folderType={applicationData?.folder?.typeKey}
      folderId={applicationData?.folder?.id}
      {...props}
    />
  ),
  EXPLOITATION_BASES_COLLECTION: () => {
    const transferredFoldersIds =
      applicationData?.licenseApplicationDetails?.transferApplicationDataDetails?.folders?.map(item => item.id);

    return (
      <ExploitationBaseTab
        folderId={applicationData.folder?.id}
        permissionsKeys={{
          search: DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_SEARCH_BASES_BUTTON,
          add: DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_ADD_BASE_BUTTON
        }}
        tableActionKeys={[
          DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_SHOW_DETAILS_BUTTON,
          DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_SHOW_GEOPORTAL_BUTTON,
          DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_EDIT_BASE_BUTTON,
          DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_DELETE_BASE_BUTTON
        ]}
        transferredFoldersIds={transferredFoldersIds}
      />
    );
  },
  TRANSPORT_MANAGERS_COLLECTION: () => (
    <TransportManagersTable
      applicationId={applicationData.id}
      folderId={applicationData?.folder?.id}
      folderType={applicationData?.folder?.typeKey}
      transferredFoldersIds={applicationData?.licenseApplicationDetails?.transferApplicationDataDetails?.folders?.map(
        folder => folder.id
      )}
      queryParams={{ applicationIdIn: [applicationData.id] }}
      visibleColumns={[
        TransportManagersColumnsEnum.NAME,
        TransportManagersColumnsEnum.SURNAME,
        TransportManagersColumnsEnum.CERTIFICATE_NUMBER,
        TransportManagersColumnsEnum.PERIOD_OF_VALIDITY,
        TransportManagersColumnsEnum.REPUTATION_KEY,
        TransportManagersColumnsEnum.MANAGEMENT_PERIOD,
        TransportManagersColumnsEnum.TYPE_KEY
      ]}
      permissionsKeysObject={{
        ADD: DomainUIElementEnum.APPLICATION_TRANSPORT_MANAGERS_ADD_MANAGER_BUTTON,
        DELETE: DomainUIElementEnum.APPLICATION_TRANSPORT_MANAGERS_DELETE_MANAGER_BUTTON,
        EDIT: DomainUIElementEnum.APPLICATION_TRANSPORT_MANAGERS_EDIT_MANAGER_BUTTON,
        PREVIEW: DomainUIElementEnum.APPLICATION_TRANSPORT_MANAGERS_SHOW_DETAILS_BUTTON,
        SEARCH: DomainUIElementEnum.APPLICATION_TRANSPORT_MANAGERS_SEARCH_MANAGER_BUTTON,
        CHECK_REPUTATION: DomainUIElementEnum.APPLICATION_TRANSPORT_MANAGERS_CHECK_IN_KREPTD_BUTTON,
        SHOW_REPUTATION: DomainUIElementEnum.APPLICATION_TRANSPORT_MANAGERS_SHOW_REPUTATION_BUTTON
      }}
      visibleActionButtons={[
        TransportManagersActionButtonsEnum.PREVIEW,
        TransportManagersActionButtonsEnum.ADD,
        TransportManagersActionButtonsEnum.SEARCH,
        TransportManagersActionButtonsEnum.EDIT,
        TransportManagersActionButtonsEnum.DELETE
      ]}
    />
  ),
  ENTREPRENEURS_COLLECTION: ({ rowActionsEnabled }: IEntrepreneursTable) => (
    <EntrepreneursTable
      subjectId={applicationData?.subject?.id}
      actionKey={DomainUIElementEnum.APPLICATION_SUBJECT_ENTREPRENEURS_TABLE}
      rowActionsEnabled={rowActionsEnabled}
    />
  ),
  NOTE_COLLECTION: () => (
    <ApplicationNoteSection
      applicationId={applicationId}
      formMode={FormMode.EDIT}
      editButtonActionKey={DomainUIElementEnum.NOTE_UPDATE}
      isEditModeForced
    />
  ),
  TRANSFER_FOLDER_SUBJECT_DATA: ({ visibleFields }: IFolderDetailsFields) => (
    <FolderDetailsFields
      transferFolder={applicationData?.licenseApplicationDetails?.transferApplicationDataDetails?.folders[0]}
      visibleFields={visibleFields}
      folderTypeKeys={[applicationData?.folder?.typeKey]}
      isFolderRequired={[
        DomainDictionaryEntry.APPLICATION_TYPE.PB6C,
        DomainDictionaryEntry.APPLICATION_TYPE.PB6D,
        DomainDictionaryEntry.APPLICATION_TYPE.PB6F,
        DomainDictionaryEntry.APPLICATION_TYPE.PB7A,
        DomainDictionaryEntry.APPLICATION_TYPE.PB7B,
        DomainDictionaryEntry.APPLICATION_TYPE.PB7C,
        DomainDictionaryEntry.APPLICATION_TYPE.PB29B,
        DomainDictionaryEntry.APPLICATION_TYPE.PB30
      ].includes(applicationData?.typeKey)}
    />
  ),
  ADDRESSES_TAB_CONTENT: ({ inputMode }: { inputMode?: AddressesInputMode }) => (
    <AddressTab
      applicationId={applicationId}
      actionKey={DomainUIElementEnum.APPLICATION_ADDRESSES_UPDATE}
      isCountryFieldAvaliable={applicationData?.folder?.typeKey === DomainDictionaryEntry.FOLDER_TYPE.MZ}
      inputMode={inputMode}
    />
  ),
  ADDRESS_FIELDS: ({ fieldId, isSubjectNipFieldVisible }: { fieldId?: string; isSubjectNipFieldVisible?: boolean }) => (
    <AdditionalAddress
      subjectId={applicationData?.subject?.id}
      formGroupName={fieldId ?? 'address'}
      isCountryFieldAvaliable={applicationData?.folder?.typeKey === DomainDictionaryEntry.FOLDER_TYPE.MZ}
      isSubjectNipFieldVisible={isSubjectNipFieldVisible}
    />
  ),
  SCERTIFICATES_COLLECTION: ({
    hiddenVehicleDetailsFields,
    visibleSections
  }: {
    hiddenVehicleDetailsFields?: VehicleSectionFields[];
    visibleSections?: CertificateSSectionsEnum[];
  }) => (
    <CertificateSTab
      applicationId={applicationId}
      folderId={applicationData.folder?.id}
      folderType={applicationData?.folder?.typeKey}
      transferredFoldersIds={applicationData?.licenseApplicationDetails?.transferApplicationDataDetails?.folders?.map(
        folder => folder.id
      )}
      applicationType={applicationData?.typeKey}
      applicationCategory={applicationData?.categoryKey}
      hiddenVehicleDetailsFields={hiddenVehicleDetailsFields}
      visibleSections={visibleSections}
    />
  ),
  SCERTIFICATES_APPLICATION_SECTION: ({ fieldId }: { fieldId: string }) => (
    <CertificateSApplicationSection
      witd={
        applicationData &&
        'sCertificateApplicationDetails' in applicationData &&
        applicationData?.sCertificateApplicationDetails?.witd
      }
      applicationNumber={applicationData?.number}
      fieldId={fieldId}
    />
  ),
  TRANSIT_SCHEDULE: ({ fieldId, visibleSections }: { fieldId: string; visibleSections?: ITransitScheduleSections }) => (
    <TransitScheduleTab
      visibleSections={visibleSections}
      applicationData={{
        id: applicationData.id,
        typeKey: applicationData.typeKey,
        categoryKey: applicationData.categoryKey
      }}
      fieldId={fieldId}
    />
  ),
  LICENSE_PERMISSIONS_DISCHARGE_SECTION: () => (
    <LicensePermissionsDischargeSection
      applicationId={applicationId}
      applicationTypeKey={applicationData.typeKey}
      folderId={applicationData?.folder.id}
      transferredFoldersIds={applicationData?.licenseApplicationDetails?.transferApplicationDataDetails?.folders?.map(
        folder => folder.id
      )}
    />
  ),
  SCHEDULE_TYPE_KEY_DICTIONARY_FIELD: props => <ScheduleTypeKeyDictionaryField {...props} />,
  LINE_TYPE_DICTIONARY_FIELD: (props: ILineTypeDictionaryFieldProps) => <LineTypeDictionaryField {...props} />,
  PARTNER_APPLICANTS_COLLECTION: () => {
    return (
      <PartnerApplicantsList
        applicationId={applicationId}
        subjectId={applicationData?.subject?.id}
        applicationStatusKey={applicationData?.statusKey}
      />
    );
  },
  MISSPELL_APPLICATIONS_COLLECTION: (props: Omit<IMisspellApplicationsTableProps, 'applicationId'>) => (
    <MisspellApplicationsTable applicationId={applicationData.id} {...props} />
  ),
  MISSPELL_APPLICATIONS_DRIVER_CERTIFICATE_COLLECTION: () => (
    <MisspellApplicationDriverCertificateTable applicationId={applicationData.id} />
  ),
  MISSPELL_APPLICATION_PERMISSION_COLLECTION: ({
    permissionType
  }: {
    permissionType: MisspellApplicationPermissionTypeEnum;
  }) => <MisspellApplicationPermissionTable applicationId={applicationId} permissionType={permissionType} />,

  SPO_APPLICATIONS_SELECT: props => <SPOApplicationsSelectField {...props} />,
  ISSUING_AUTHORITY_INPUT: props => <IssuingAuthorityField {...props} />,
  APPLICATION_TERM_INPUT: props => <ApplicationTermField {...props} />,
  SINGLE_PERMIT_COLLECTION: ({ fieldId }: ISinglePermitTable) => (
    <SinglePermitTable fieldId={fieldId} applicationCreatedAt={applicationData?.created} />
  ),
  FINANCIAL_SECURITY_EXCHANGE_COLLECTION: ({ fieldId, formMode }: { fieldId: string; formMode: FormMode }) => (
    <FinancialSecuritySummarySection
      formMode={formMode}
      fieldId={fieldId}
      folderType={applicationData?.folder?.typeKey}
      applicationId={applicationId}
      sectionActionKey={DomainUIElementEnum.APPLICATION_FINACIAL_SECURITY_SUMMARY_SECTION}
    />
  ),
  APPLICATION_VALID_FROM_FIELD: (props: ApplicationValidFromFieldProps) => <ApplicationValidFromField {...props} />,
  OUTGOIN_DOCUMENTS_ADDITIONAL_SECTION_LICENSE_OR_PERMISSION: ({
    fieldId,
    sectionTypes
  }: {
    fieldId?: string;
    sectionTypes?: AdditionalSectionLicenseOrPermissionType[];
  }) => (
    <AdditionalSectionLicenseOrPermission
      sectionTypes={sectionTypes}
      fieldId={fieldId}
      applicationData={applicationData}
    />
  ),
  TRAVEL_FORM_COLLECTION: () => <ApplicationTravelSections />,
  WARNINGS_COLLECTION: () => (
    <ApplicationWarningsList applicationData={applicationData} applicationId={applicationId} />
  ),
  CORRESPONDENCE_ADDRESS_BUTTON: () => <CorrespondenceAddressButton applicationId={applicationId} />,
  DRIVER_CERTIFICATE_SELECT: (props: IDriverCertificateSelectFieldProps) => <DriverCertificateSelectField {...props} />,
  FOLDER_SELECT_FIELD: (props: FolderSelectFieldProps) => (
    <FoldersSelectField
      hasActiveLicense={'hasActiveLicense' in applicationData && applicationData?.hasActiveLicense}
      folderTypeKey={applicationData?.folder?.typeKey}
      {...props}
    />
  ),
  LAST_SUPPLEMENT_RECEIPT_DATE: props => (
    <LastSupplementReceiptDate
      {...props}
      statusKey={applicationData?.statusKey}
      wasIncomplete={applicationData && 'wasIncomplete' in applicationData && applicationData?.wasIncomplete}
    />
  ),
  CABOTAGE_COUNTRY_FIELD: props => <CabotageCountryDictionaryField {...props} />,
  CABOTAGE_REMARKS_FIELD: props => <CabotageRemarksTextField {...props} />,
  SELECT_ADDRESS_TYPE_FIELD: props => <SelectAddressTypeSelectField {...props} />,
  SELECT_DATA_OF_APPLICANT: props => <SelectDataOfApplicant {...props} />
});

function useApplicationFieldRegistry(applicationData: Application | ApplicationDetailsClient) {
  const { isVisible } = useVisiblePermissionTables(applicationData);
  const applicationId = applicationData?.id;
  const { isOperatorPortal } = useDomainConfigContext();

  const viewFieldsRegistry = createViewFieldsRegistry({ applicationData, applicationId, isVisible, isOperatorPortal });
  const formFieldsRegistry = createFormFieldsRegistry({ applicationData, applicationId, isVisible, isOperatorPortal });

  return { viewFieldsRegistry, formFieldsRegistry };
}

export default useApplicationFieldRegistry;
