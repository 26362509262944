import { usePaginatedQuery } from 'react-query';
import { ApplicationHistoryDetails, ApplicationHistorySearchFilter } from '@ibtm/domain';
import { omit } from 'lodash';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { ApplicationQueryKeysEnum } from './index';

const getApplicationHistoryQuery = async (_, params: ApplicationHistorySearchFilter & { id: string }) => {
  const fixedParams = omit(params, 'id');
  const { data } = await API.application.getApplicationHistoryDetailsPage(params.id, fixedParams);

  return data;
};

function useApplicationHistoryQuery(params) {
  return usePaginatedQuery<IPaginatedModel<ApplicationHistoryDetails>>(
    [ApplicationQueryKeysEnum.APPLICATION_HISTORY, params],
    getApplicationHistoryQuery
  );
}

export default useApplicationHistoryQuery;
