import { MutationConfig } from 'react-query';
import { ExploitationBaseCreateRequest, ExploitationBaseDetails } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const createExploitationBase = async (exploitationBaseCreateRequest: ExploitationBaseCreateRequest) => {
  const { data } = await API.exploitationBase.createExploitationBase(exploitationBaseCreateRequest, {
    ...endpointsConfig.createExploitationBase
  });
  return data;
};

const createExploitationBaseClient = async (exploitationBaseCreateRequest: ExploitationBaseCreateRequest) => {
  const { data } = await API.client.exploitationBase.createExploitationBase('', '', exploitationBaseCreateRequest, {
    ...endpointsConfig.createExploitationBase
  });
  return data;
};

function useExploitationBaseCreateMutation(
  config?: MutationConfig<ExploitationBaseDetails, unknown, ExploitationBaseCreateRequest, unknown>
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(createExploitationBaseClient, createExploitationBase), config);
}

export default useExploitationBaseCreateMutation;
