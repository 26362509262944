import { useTableLayoutProps } from '@libs/common/v2';

import { useReputationResultsQuery } from '../api';
import useReputationQueryResultColumns from '../config/useReputationQueryResultColumns';

function useReputationQueryResultsTable(id: string) {
  const { columns } = useReputationQueryResultColumns();
  return useTableLayoutProps({
    tableHookQuery: useReputationResultsQuery,
    tableHookOptions: {
      columns
    },
    tableHookQueryInitialParams: {
      id
    }
  });
}

export default useReputationQueryResultsTable;
