import { ForeignPermissionWithSettlementDetails } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import { DatepickerField, GridLayout, InputMode, Section, TextInputField, typedNameV2 } from '@libs/common/v2';
import { DictionarySelectField, useFormV2Watch } from '@libs/common/v2/form';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import PermitEkmtItemsTable from './PermitEkmtItemsTable';

function PermitEkmtDetailsEditForm() {
  const getLabel = partialTranslate('foreignPermits:tabs.releasedPermits.ekmtDetails');
  const getFieldLabel = partialTranslate('foreignPermits:tabs.releasedPermits.ekmtDetails.settlementEKMT.formFields');

  const concernPermissionNumber = useFormV2Watch<string>({
    name: typedNameV2<ForeignPermissionWithSettlementDetails>('concernForeignPermission.number')
  });

  const ekmtCategoryKey = useFormV2Watch<string>({
    name: typedNameV2<ForeignPermissionWithSettlementDetails>('form.type.ekmtCategoryKey')
  });
  const isReservePermission = [
    DomainDictionaryEntry.EKMT_CATEGORY.ANNUAL_PROVISION,
    DomainDictionaryEntry.EKMT_CATEGORY.ANNUAL_PROVISION_BASIC
  ].includes(ekmtCategoryKey);

  return (
    <>
      <Section title={getLabel('sections.details')}>
        <GridLayout itemProps={{ md: 6, xs: 12 }}>
          <TextInputField
            name={typedNameV2<ForeignPermissionWithSettlementDetails>('application.number')}
            inputMode={InputMode.VIEW}
            label={getFieldLabel('applicationNumber')}
            isRequired
          />
          <TextInputField
            name={typedNameV2<ForeignPermissionWithSettlementDetails>('folder.number')}
            inputMode={InputMode.VIEW}
            label={getFieldLabel('folderNumber')}
          />
          <TextInputField
            name={typedNameV2<ForeignPermissionWithSettlementDetails>('subject.name')}
            inputMode={InputMode.VIEW}
            label={getFieldLabel('subjectName')}
          />
          <TextInputField
            name={typedNameV2<ForeignPermissionWithSettlementDetails>('form.number')}
            inputMode={InputMode.VIEW}
            label={getFieldLabel('formNumber')}
          />
          <TextInputField
            name={typedNameV2<ForeignPermissionWithSettlementDetails>('name')}
            inputMode={InputMode.VIEW}
            label={getFieldLabel('name')}
          />
          <DictionarySelectField
            name={typedNameV2<ForeignPermissionWithSettlementDetails>('form.stateKey')}
            dictionaryName={DomainDictionaryEnum.RESOURCE_FORM_STATE}
            inputMode={InputMode.VIEW}
            label={getFieldLabel('status')}
          />
          <DatepickerField
            name={typedNameV2<ForeignPermissionWithSettlementDetails>('dateOfIssue')}
            inputMode={InputMode.VIEW}
            label={getFieldLabel('dateOfIssue')}
          />
          <DatepickerField
            name={typedNameV2<ForeignPermissionWithSettlementDetails>('validDateRange.from')}
            inputMode={InputMode.VIEW}
            label={getFieldLabel('dateFrom')}
          />
          <DatepickerField
            name={typedNameV2<ForeignPermissionWithSettlementDetails>('validDateRange.to')}
            inputMode={InputMode.VIEW}
            label={getFieldLabel('dateTo')}
          />
          <DictionarySelectField
            name={typedNameV2<ForeignPermissionWithSettlementDetails>('form.type.countryKey')}
            inputMode={InputMode.VIEW}
            label={getFieldLabel('country')}
            dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
          />
          <DictionarySelectField
            name={typedNameV2<ForeignPermissionWithSettlementDetails>('issuingCountryCodeKey')}
            inputMode={InputMode.VIEW}
            label={getFieldLabel('issuingCountry')}
            dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
          />
          <DictionarySelectField
            name={typedNameV2<ForeignPermissionWithSettlementDetails>('settlement.codeKey')}
            dictionaryName={DomainDictionaryEnum.RETURNS_OF_FOREIGN_LICENSES}
            inputMode={InputMode.VIEW}
            label={getFieldLabel('settlementCode')}
          />
          <DictionarySelectField
            name={typedNameV2<ForeignPermissionWithSettlementDetails>('document.typeKey')}
            dictionaryName={DomainDictionaryEnum.DOCUMENT_TYPE}
            inputMode={InputMode.VIEW}
            label={getFieldLabel('releaseDocument')}
          />
          {concernPermissionNumber && (
            <TextInputField
              name={typedNameV2<ForeignPermissionWithSettlementDetails>('concernForeignPermission.number')}
              inputMode={InputMode.VIEW}
              label={getFieldLabel(!isReservePermission ? 'concernPermissionReserve' : 'concernPermission')}
            />
          )}
        </GridLayout>
      </Section>

      <PermitEkmtItemsTable />
    </>
  );
}

export default PermitEkmtDetailsEditForm;
