export enum ApplicationTabIdEnum {
  /** Podmiot */
  SUBJECT = 'SUBJECT',
  /** Adresy */
  ADDRESSES = 'ADDRESSES',
  /** Uprawnienia */
  PERMISSIONS = 'PERMISSIONS',
  /** Pełnomocnicy */
  PROXIES = 'PROXIES',
  /** Załączniki */
  ATTACHMENTS = 'ATTACHMENTS',
  /** Kierowcy */
  DRIVERS = 'DRIVERS',
  /** Wycena */
  QUOTATION = 'QUOTATION',
  /** Dokumenty wychodzące */
  OUTGOING_DOCUMENTS = 'OUTGOING_DOCUMENTS',
  /** Notatka */
  NOTE = 'NOTE',
  /** Pliki */
  FILES = 'FILES',
  /** Historia */
  HISTORY = 'HISTORY',
  /** Dane podstawowe */
  GENERAL = 'GENERAL',
  /** Wnioskowane zezwolenia */
  REQUESTED_PERMITS = 'REQUESTED_PERMITS',
  /** Interbus/Formularze Podróży */
  INTERBUS = 'INTERBUS',
  /** Zabezpieczenia finansowe */
  FINANCIAL_SECURITY = 'FINANCIAL_SECURITY',
  /** Zarządzający transportem */
  TRANSPORT_MANAGERS = 'TRANSPORT_MANAGERS',
  /** Pojazdy */
  VEHICLES = 'VEHICLES',
  /** Bazy eksploatacyjne */
  EXPLOITATION_BASE = 'EXPLOITATION_BASE',
  /** Certyfikaty S */
  SCERTIFICATES = 'SCERTIFICATES',
  /** Opłaty */
  PAYMENTS = 'PAYMENTS',
  /** Podwykonawcy/Partnerzy */
  PARTNER_APPLICANTS = 'PARTNER_APPLICANTS',
  /** Rozkład jazdy */
  TRANSIT_SCHEDULES = 'TRANSIT_SCHEDULES',
  /** Kabotaż */
  CABOTAGE = 'CABOTAGE',
  /** Zezwolenia jednorazowe */
  FOREIGN_PERMISSIONS_SINGLE = 'FOREIGN_PERMISSIONS_SINGLE'
}

export const clientPortalVisibleTabs = [
  ApplicationTabIdEnum.GENERAL,
  ApplicationTabIdEnum.SUBJECT,
  ApplicationTabIdEnum.ADDRESSES,
  ApplicationTabIdEnum.FINANCIAL_SECURITY,
  ApplicationTabIdEnum.VEHICLES,
  ApplicationTabIdEnum.DRIVERS,
  ApplicationTabIdEnum.EXPLOITATION_BASE,
  ApplicationTabIdEnum.TRANSPORT_MANAGERS,
  ApplicationTabIdEnum.QUOTATION,
  ApplicationTabIdEnum.ATTACHMENTS,
  ApplicationTabIdEnum.SCERTIFICATES,
  ApplicationTabIdEnum.PARTNER_APPLICANTS,
  ApplicationTabIdEnum.TRANSIT_SCHEDULES,
  ApplicationTabIdEnum.CABOTAGE,
  ApplicationTabIdEnum.FILES,
  ApplicationTabIdEnum.FOREIGN_PERMISSIONS_SINGLE,
  ApplicationTabIdEnum.INTERBUS,
  ApplicationTabIdEnum.PAYMENTS,
  ApplicationTabIdEnum.REQUESTED_PERMITS,
  ApplicationTabIdEnum.PROXIES,
  ApplicationTabIdEnum.PERMISSIONS
];
