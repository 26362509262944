import { PermissionElementMap } from '@libs/permission';

import DomainUIElementEnum from './DomainUIElementEnum';
import { PermissionEnum } from './PermissionEnum';

const DomainPermissionElementMapper: PermissionElementMap<DomainUIElementEnum> = {
  [DomainUIElementEnum.FOLDER_DETAILS_BUTTON]: {
    anyOfPermission: []
  },
  [DomainUIElementEnum.FOLDER_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_UPDATE]
  },
  [DomainUIElementEnum.FOLDER_KS_EDIT_BUTTON]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_FOLDER_UPDATE, PermissionEnum.IBTM_DOMAIN_FOLDER_KS_UPDATE]
  },
  [DomainUIElementEnum.FOLDER_MZ_EDIT_BUTTON]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_FOLDER_UPDATE, PermissionEnum.IBTM_DOMAIN_FOLDER_MZ_UPDATE]
  },
  [DomainUIElementEnum.FOLDER_OP_EDIT_BUTTON]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_FOLDER_UPDATE, PermissionEnum.IBTM_DOMAIN_FOLDER_OP_UPDATE]
  },
  [DomainUIElementEnum.FOLDER_PA_EDIT_BUTTON]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_FOLDER_UPDATE, PermissionEnum.IBTM_DOMAIN_FOLDER_PA_UPDATE]
  },
  [DomainUIElementEnum.FOLDER_PPO_EDIT_BUTTON]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_FOLDER_UPDATE, PermissionEnum.IBTM_DOMAIN_FOLDER_PPO_UPDATE]
  },
  [DomainUIElementEnum.FOLDER_TP_EDIT_BUTTON]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_FOLDER_UPDATE, PermissionEnum.IBTM_DOMAIN_FOLDER_TP_UPDATE]
  },
  [DomainUIElementEnum.FOLDER_WITD_EDIT_BUTTON]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_FOLDER_UPDATE, PermissionEnum.IBTM_DOMAIN_FOLDER_WITD_UPDATE]
  },
  [DomainUIElementEnum.FOLDER_ZZ_EDIT_BUTTON]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_FOLDER_UPDATE, PermissionEnum.IBTM_DOMAIN_FOLDER_ZZ_UPDATE]
  },
  [DomainUIElementEnum.FOLDER_KS_VIEW_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_KS_DETAILS_VIEW]
  },
  [DomainUIElementEnum.FOLDER_MZ_VIEW_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_MZ_DETAILS_VIEW]
  },
  [DomainUIElementEnum.FOLDER_OP_VIEW_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_OP_DETAILS_VIEW]
  },
  [DomainUIElementEnum.FOLDER_PA_VIEW_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_PA_DETAILS_VIEW]
  },
  [DomainUIElementEnum.FOLDER_PPO_VIEW_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_PPO_DETAILS_VIEW]
  },
  [DomainUIElementEnum.FOLDER_TP_VIEW_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_TP_DETAILS_VIEW]
  },
  [DomainUIElementEnum.FOLDER_WITD_VIEW_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_WITD_DETAILS_VIEW]
  },
  [DomainUIElementEnum.FOLDER_ZZ_VIEW_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_ZZ_DETAILS_VIEW]
  },
  [DomainUIElementEnum.FOLDER_KS_BASIC_DATA_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_KS_BASIC_INFORMATION_VIEW]
  },
  [DomainUIElementEnum.FOLDER_ZZ_BASIC_DATA_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_ZZ_BASIC_INFORMATION_VIEW]
  },
  [DomainUIElementEnum.FOLDER_WITD_BASIC_DATA_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_WITD_BASIC_INFORMATION_VIEW]
  },
  [DomainUIElementEnum.FOLDER_MZ_BASIC_DATA_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_MZ_BASIC_INFORMATION_VIEW]
  },
  [DomainUIElementEnum.FOLDER_MZ_PERMISSION_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_MTD_PERMISSION_VIEW]
  },
  [DomainUIElementEnum.FOLDER_ACTIVATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_EXPIRATION_DISABLE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_EXPIRE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_EXPIRATION_ENABLE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_CREATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.FOLDER_ADMIN, PermissionEnum.FOLDER_CREATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_ADD_BUTTON]: {
    nestedAnyOfPermissions: [
      [PermissionEnum.IBTM_DOMAIN_FOLDER_CREATE, PermissionEnum.IBTM_DOMAIN_FOLDER_TP_CREATE],
      [PermissionEnum.IBTM_DOMAIN_FOLDER_CREATE, PermissionEnum.IBTM_DOMAIN_FOLDER_OP_CREATE],
      [PermissionEnum.IBTM_DOMAIN_FOLDER_CREATE, PermissionEnum.IBTM_DOMAIN_FOLDER_PPO_CREATE],
      [PermissionEnum.IBTM_DOMAIN_FOLDER_CREATE, PermissionEnum.IBTM_DOMAIN_FOLDER_MZ_CREATE],
      [PermissionEnum.IBTM_DOMAIN_FOLDER_CREATE, PermissionEnum.IBTM_DOMAIN_FOLDER_PA_CREATE],
      [PermissionEnum.IBTM_DOMAIN_FOLDER_CREATE, PermissionEnum.IBTM_DOMAIN_FOLDER_KS_CREATE],
      [PermissionEnum.IBTM_DOMAIN_FOLDER_CREATE, PermissionEnum.IBTM_DOMAIN_FOLDER_WITD_CREATE],
      [PermissionEnum.IBTM_DOMAIN_FOLDER_CREATE, PermissionEnum.IBTM_DOMAIN_FOLDER_ZZ_CREATE]
    ]
  },
  [DomainUIElementEnum.FOLDER_APPLICATION_CREATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_CREATE]
  },
  [DomainUIElementEnum.APPLICATION_INPUT_DATA_COPY]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_INPUT_DATA_COPY],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_LIST]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_LIST_VIEW]
  },
  [DomainUIElementEnum.FOLDER_SLIDER]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_SLIDER_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_SLIDER_PERMISSION_SECTION]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_PERMISSION_LIST_VIEW]
  },
  [DomainUIElementEnum.FOLDER_COURIER_PARCEL_BLOCK_FIELD]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_COURIER_PARCEL_BLOCK],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SYSTEM_PARAMETERS_LIST_TAB]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_SYSTEM_PARAMETER_LIST_VIEW]
  },
  [DomainUIElementEnum.SYSTEM_PARAMETERS_EDIT_BUTTON]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_SYSTEM_PARAMETER_UPDATE]
  },
  [DomainUIElementEnum.FOLDER_GENERATE_LABEL]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_LABEL_CREATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_CHECK_IN_REGON_DATA_BUTTON]: {
    anyOfPermission: [PermissionEnum.REGON_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_EDIT_ASSOCIATION_MEMBERSHIP]: {
    anyOfPermission: [PermissionEnum.FOLDER_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_ENTER_OR_EDIT_DATE_OF_DEATH]: {
    anyOfPermission: [PermissionEnum.FOLDER_ADMIN, PermissionEnum.FOLDER_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_BASIC_DATA_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_BASIC_INFORMATION_VIEW]
  },
  [DomainUIElementEnum.FOLDER_BASIC_DATA_EDIT]: {
    anyOfPermission: [PermissionEnum.FOLDER_ADMIN, PermissionEnum.FOLDER_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_SUBJECT_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_SUBJECT_VIEW]
  },
  [DomainUIElementEnum.FOLDER_SLIDER_SUBJECT_SECTION]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_SUBJECT_VIEW]
  },
  [DomainUIElementEnum.FOLDER_SUBJECT_EDIT]: {
    anyOfPermission: [PermissionEnum.SUBJECT_ADMIN, PermissionEnum.SUBJECT_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_SUBJECT_CHECK_DATA_IN_KREPTD_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_KREPTD_REPUTATION_IMPORT],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_SUBJECT_REPUTATION_VIEW]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_KREPTD_ADMIN, PermissionEnum.PROCEEDING_KREPTD_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_ADDRESS_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_ADDRESS_VIEW]
  },
  [DomainUIElementEnum.FOLDER_MAIN_ADDRESS_KS_UPDATE]: {
    anyOfPermission: [PermissionEnum.FOLDER_MAIN_ADDRESS_KS_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_MAIN_ADDRESS_WITD_UPDATE]: {
    anyOfPermission: [PermissionEnum.FOLDER_MAIN_ADDRESS_WITD_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_MAIN_ADDRESS_ZZ_UPDATE]: {
    anyOfPermission: [PermissionEnum.FOLDER_MAIN_ADDRESS_ZZ_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PROXY_VIEW]: {
    anyOfPermission: []
  },
  [DomainUIElementEnum.FOLDER_PROXY_ADD_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_PROXY_CREATE]
  },
  [DomainUIElementEnum.FOLDER_PROXY_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_PROXY_UPDATE]
  },
  [DomainUIElementEnum.FOLDER_PROXY_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.PROXY_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.S_CERTIFICATE_LIST]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_S_CERTIFICATE_LIST_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_CERTIFICATE_S_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_S_CERTIFICATE_LIST_VIEW]
  },
  [DomainUIElementEnum.FOLDER_CERTIFICATE_S_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.S_CERTIFICATE_ADMIN, PermissionEnum.S_CERTIFICATE_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_CERTIFICATE_S_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.S_CERTIFICATE_ADMIN, PermissionEnum.S_CERTIFICATE_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_BASIC_LIMIT_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_FOREIGN_PERMISSION_SINGLE_VIEW]
  },
  [DomainUIElementEnum.FOLDER_BASIC_LIMIT_BLOCK_PERMITS_VIEW]: {
    anyOfPermission: [PermissionEnum.FOLDER_LIMIT_ADMIN, PermissionEnum.FOLDER_LIMIT_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_BASIC_LIMIT_BLOCK_PERMITS_EDIT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOREIGN_PERMISSION_RELEASING_BLOCK],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_BASIC_LIMIT_SUSPEND_OR_POSTPONE_LIMIT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOREIGN_PERMISSION_BASIC_LIMIT_SUSPEND],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_BASIC_LIMIT_EDIT_OR_TRANSFER_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOREIGN_PERMISSION_BASIC_LIMIT_TRANSFER],
    allOfPermissions: []
  },

  [DomainUIElementEnum.FOLDER_BASIC_LIMIT_RELEASED_OR_ISSUED_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.FOREIGN_PERMISSION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_BASIC_LIMIT_RELEASED_OR_ISSUED_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.FOREIGN_PERMISSION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_EKMT_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_FOREIGN_PERMISSION_EKMT_VIEW]
  },
  [DomainUIElementEnum.FOLDER_EKMT_BLOCK_PERMITS_VIEW]: {
    anyOfPermission: [PermissionEnum.FOLDER_LIMIT_ADMIN, PermissionEnum.FOLDER_LIMIT_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_EKMT_BLOCK_PERMITS_EDIT]: {
    anyOfPermission: [PermissionEnum.FOLDER_LIMIT_ADMIN, PermissionEnum.FOLDER_LIMIT_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_EKMT_DETAILS]: {
    anyOfPermission: [PermissionEnum.FOLDER_LIMIT_ADMIN, PermissionEnum.FOLDER_LIMIT_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_EKMT_LIMIT_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.FOLDER_LIMIT_ADMIN, PermissionEnum.FOLDER_LIMIT_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_EKMT_RELEASED_OR_ISSUED_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.FOREIGN_PERMISSION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_EKMT_RELEASED_OR_ISSUED_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.FOREIGN_PERMISSION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_ORDER_DETAILS]: {
    anyOfPermission: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_PERMISSION_LIST_VIEW]
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_LICENCE_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.LICENSE_ADMIN, PermissionEnum.LICENSE_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_GITD_PERMITS_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_PERMISSION_LIST_WZPD]
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_FORWARD_TO_STAROSTY_BUTTON]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_BACK_TO_GITD_BUTTON]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_PERMITS_AND_NATIONAL_LICENCES_FROM_EXTERNAL_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_PERMISSION_LIST_WZPD_EXTERNAL]
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_VIEW_BUTTON]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_LICENCE_EXTRACTS_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.LICENSE_ADMIN, PermissionEnum.LICENSE_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_EXTRACTS_FROM_PERMITS_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_PERMITS_FOR_REGULAR_SERVICE_FROM_UE_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_EXTRACTS_FOR_REGULAR_SERVICE_WITHIN_UE_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_PERMITS_FOR_REGULAR_SERVICE_OUTSIDE_UE_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_EXTRACTS_FOR_REGULAR_SERVICE_OUTSIDE_UE_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_SHUTTLE_PERMITS_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_EXTRACTS_FROM_PERMIT_FOR_SHUTTLE_SERVICE_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_PERMITS_FOR_OCCASIONAL_SERVICE_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_EXCLUSIONS_FROM_PERMIT_FOR_OCCASIONAL_SERVICE_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_PERMITS_FOR_REGULAR_TRANSIT_IN_POLAND_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_EXTRACTS_FROM_PERMIT_FOR_REGULAR_TRANSIT_IN_POLAND_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_FOREIGN_PERMITS_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_CERTIFICATES_FOR_OWN_ACCOUNT_TRANSPORT_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_EXTRACTS_TO_CERTIFICATE_FOR_OWN_ACCOUNT_TRANSPORT_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_FOREIGN_AUTHORITY_PERMISSION_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_FOREIGN_PERMISSION_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_APPLICATION_VIEW]: {
    anyOfPermission: []
  },
  [DomainUIElementEnum.FOLDER_APPLICATION_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.APPLICATION_ADMIN, PermissionEnum.APPLICATION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_APPLICATION_SHOW_SHORTAGE]: {
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_APPLICATION_SHOW_DRIVER_DETAILS]: {
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_APPLICATION_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.APPLICATION_ADMIN, PermissionEnum.APPLICATION_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_FINANCIAL_SECURITY_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_FINANCIAL_SECURITY_VIEW]
  },
  [DomainUIElementEnum.FOLDER_FINANCIAL_SECURITY_CREATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_FINANCIAL_SECURITY_CREATE]
  },
  [DomainUIElementEnum.FOLDER_FINANCIAL_SECURITY_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_FINANCIAL_SECURITY_DELETE]
  },
  [DomainUIElementEnum.FOLDER_FINANCIAL_SECURITY_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_FINANCIAL_SECURITY_UPDATE]
  },
  [DomainUIElementEnum.FOLDER_FINANCIAL_SECURITY_WITHDRAW_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_FINANCIAL_SECURITY_WITHDRAW]
  },
  [DomainUIElementEnum.FOLDER_FINANCIAL_SECURITY_RESTORE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_FINANCIAL_SECURITY_RESTORE]
  },
  [DomainUIElementEnum.FOLDER_INSURANCE_POLICY_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_INSURACNCE_POLICY_VIEW]
  },
  [DomainUIElementEnum.FOLDER_INSURANCE_POLICY_CREATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_INSURACNCE_POLICY_CREATE]
  },
  [DomainUIElementEnum.FOLDER_INSURANCE_POLICY_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_INSURACNCE_POLICY_UPDATE]
  },
  [DomainUIElementEnum.FOLDER_INSURANCE_POLICY_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_INSURACNCE_POLICY_DELETE]
  },
  [DomainUIElementEnum.FOLDER_TRANSPORT_MANAGER_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_TRANSPORT_MANAGER_VIEW]
  },
  [DomainUIElementEnum.FOLDER_TRANSPORT_MANAGER_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_TRANSPORT_MANAGER_UPDATE]
  },
  [DomainUIElementEnum.FOLDER_TRANSPORT_MANAGER_EXPIRE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_TRANSPORT_MANAGER_WITHDRAW]
  },
  [DomainUIElementEnum.FOLDER_TRANSPORT_MANAGER_RESTORE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_TRANSPORT_MANAGER_RESTORE]
  },
  [DomainUIElementEnum.FOLDER_DRIVER_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_DRIVER_LIST_VIEW]
  },
  [DomainUIElementEnum.FOLDER_DRIVER_EXPIRE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DRIVER_DRIVER_CERTIFICATE_REVOKE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_DRIVER_ACTIVATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DRIVER_DRIVER_CERTIFICATE_RESUME],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_DRIVER_CERTIFICATES_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_DRIVER_GENERATE_UP_TO_DATE_REPORT_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.REPORT_WSK_GENERATE,
      PermissionEnum.REPORT_WSK_WAZNE_SK_FIRMA_GENERATE,
      PermissionEnum.REPORT_GENERATE
    ]
  },
  [DomainUIElementEnum.FOLDER_DRIVER_GENERATE_ISSUED_REPORT_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.REPORT_WSK_GENERATE,
      PermissionEnum.REPORT_WSK_WYDANE_SK_FIRMA_GENERATE,
      PermissionEnum.REPORT_GENERATE
    ]
  },
  [DomainUIElementEnum.FOLDER_DRIVER_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.DRIVER_ADMIN, PermissionEnum.DRIVER_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_VEHICLE_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_VEHICLE_VIEW]
  },
  [DomainUIElementEnum.FOLDER_VEHICLE_SHOW_DETAILS_BUTTON]: {
    anyOfPermission: []
  },
  [DomainUIElementEnum.FOLDER_VEHICLE_CREATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_VEHICLE_CREATE]
  },
  [DomainUIElementEnum.FOLDER_VEHICLE_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_VEHICLE_DELETE]
  },
  [DomainUIElementEnum.FOLDER_VEHICLE_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_VEHICLE_UPDATE]
  },
  [DomainUIElementEnum.FOLDER_VEHICLE_EXPIRE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_VEHICLE_WITHDRAW]
  },
  [DomainUIElementEnum.FOLDER_VEHICLE_RESTORE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_VEHICLE_RESTORE]
  },
  [DomainUIElementEnum.FOLDER_EXPLOITATION_BASE_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_EXPLOITATION_BASE_VIEW]
  },
  [DomainUIElementEnum.FOLDER_EXPLOITATION_BASE_SHOW_DETAILS_BUTTON]: {
    anyOfPermission: []
  },
  [DomainUIElementEnum.FOLDER_EXPLOITATION_BASE_SHOW_GEOPORTAL_BUTTON]: {
    anyOfPermission: []
  },
  [DomainUIElementEnum.FOLDER_EXPLOITATION_BASE_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_EXPLOITATION_BASE_UPDATE]
  },
  [DomainUIElementEnum.FOLDER_EXPLOITATION_BASE_EXPIRE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_EXPLOITATION_BASE_WITHDRAW]
  },
  [DomainUIElementEnum.FOLDER_EXPLOITATION_BASE_RESTORE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_EXPLOITATION_BASE_RESTORE]
  },
  [DomainUIElementEnum.FOLDER_TRANSPORT_MANAGERS_CHECK_IN_KREPTD_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_TRANSPORT_MANAGER_KREPTD_REPUTATION_IMPORT]
  },
  [DomainUIElementEnum.FOLDER_TRANSPORT_MANAGERS_SHOW_REPUTATION_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_KREPTD_REPUTATION_VIEW]
  },
  [DomainUIElementEnum.FOLDER_PROCEEDING_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_PROCEEDING_VIEW]
  },
  [DomainUIElementEnum.FOLDER_PROCEEDING_CREATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_ADMIN, PermissionEnum.PROCEEDING_CREATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PROCEEDING_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_ADMIN, PermissionEnum.PROCEEDING_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_PROCEEDING_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_SUSPENSION_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_SUSPENSION_VIEW]
  },
  [DomainUIElementEnum.FOLDER_SUSPENSION_CREATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_SUSPENSION_CREATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_RENTAL_VIEW]: {
    anyOfPermission: []
  },
  [DomainUIElementEnum.FOLDER_DETAILS_VIEW]: {
    anyOfPermission: [PermissionEnum.FOLDER_ADMIN, PermissionEnum.FOLDER_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_RENTAL_CREATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.FOLDER_RENTAL_ADMIN, PermissionEnum.FOLDER_RENTAL_CREATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_RENTAL_RETURN_BUTTON]: {
    anyOfPermission: [PermissionEnum.FOLDER_RENTAL_ADMIN, PermissionEnum.FOLDER_RENTAL_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_RENTAL_CHANGE_BORROWER_BUTTON]: {
    anyOfPermission: [PermissionEnum.FOLDER_RENTAL_ADMIN, PermissionEnum.FOLDER_RENTAL_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_LOCK_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_LOCK_VIEW]
  },
  [DomainUIElementEnum.FOLDER_LOCK_CREATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_LOCK_CREATE]
  },
  [DomainUIElementEnum.FOLDER_LOCK_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_LOCK_REMOVE]
  },
  [DomainUIElementEnum.FOLDER_UNBLOCK_PROCEEDING_DRIVER_CERTIFICATE_DELETE_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_FOLDER_LOCK_REMOVE,
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_DRIVER_CERTIFICATE_UNBLOCK
    ]
  },
  [DomainUIElementEnum.FOLDER_NOTE_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_NOTE_VIEW]
  },
  [DomainUIElementEnum.FOLDER_NOTE_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_NOTE_UPDATE]
  },
  [DomainUIElementEnum.NOTE_UPDATE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_NOTE_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_HISTORY_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_HISTORY_VIEW]
  },
  [DomainUIElementEnum.FOLDER_MIGRATION_CONFIRM_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_MIGRATION_CONFIRM],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_DATE_OF_DEATH_SET_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_DATE_OF_DEATH_SET],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOLDER_DATE_OF_DEATH_UPDATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_DATE_OF_DEATH_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.ASSOCIATIONS_LIST]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_ASSOCIATION_LIST_VIEW]
  },
  [DomainUIElementEnum.ASSOCIATIONS_ADD_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_ASSOCIATION_CREATE]
  },
  [DomainUIElementEnum.ASSOCIATIONS_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_ASSOCIATION_DELETE]
  },
  [DomainUIElementEnum.ASSOCIATIONS_SHOW_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_ASSOCIATION_DETAILS_VIEW]
  },
  [DomainUIElementEnum.ASSOCIATIONS_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_ASSOCIATION_UPDATE]
  },
  [DomainUIElementEnum.ASSOCIATIONS_DETAILS_ENTREPRENEURS_ADD_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_ASSOCIATION_SUBJECT_ADD]
  },
  [DomainUIElementEnum.ASSOCIATIONS_DETAILS_ENTREPRENEURS_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_ASSOCIATION_SUBJECT_DELETE]
  },
  [DomainUIElementEnum.EZD_DOCUMENT_LIST]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_EZD_DOCUMENT_LIST_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.EZD_DOCUMENT_ADD_APPLICATION_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_EZD_DOCUMENT_APPLICATION_CREATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.EZD_DOCUMENT_INCLUDE_DOCUMENT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_EZD_DOCUMENT_APPLICATION_BIND],
    allOfPermissions: []
  },
  [DomainUIElementEnum.EZD_DOCUMENT_REJECT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_EZD_DOCUMENT_REJECT],
    allOfPermissions: []
  },
  [DomainUIElementEnum.EZD_DOCUMENT_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_EZD_DOCUMENT_DETAILS_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.EZD_DOCUMENT_FILE_LIST_VIEW]: {
    anyOfPermission: [PermissionEnum.EZD_DOCUMENT_ADMIN, PermissionEnum.EZD_DOCUMENT_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.EZD_DOCUMENT_FILE_UPDATE]: {
    anyOfPermission: [PermissionEnum.EZD_DOCUMENT_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_LIST]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_LIST_VIEW]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_SEND_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_SEND]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_EPUAP_SEND_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_EPUAP_SEND]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_E_MAIL_SEND_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_EMAIL_SEND]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_E_MAIL_SEND_OPTION]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_EMAIL_SEND]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_POST_SEND_OPTION]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_POST_PARCEL_SEND]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_EPUAP_SEND_OPTION]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_EPUAP_SEND]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_COURIER_SEND_OPTION]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_COURIER_PARCEL_SEND]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_PICKUP_IN_PERSON_SEND_OPTION]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_PERSONAL_RELEASE]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_PICKUP_IN_PERSON_ISSUE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_PERSONAL_RELEASE]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_POST_SEND_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_POST_PARCEL_SEND]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_COURIER_SEND_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_COURIER_PARCEL_SEND]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_ADDICTIONAL_SERVICES_BUTTON]: {
    anyOfPermission: [PermissionEnum.DOCUMENT_ADMIN],
    allOfPermissions: [PermissionEnum.DOCUMENT_VIEW, PermissionEnum.DOCUMENT_UPDATE]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_CHANGE_ADDRESS_BUTTON]: {
    anyOfPermission: [PermissionEnum.DOCUMENT_ADMIN],
    allOfPermissions: [PermissionEnum.DOCUMENT_VIEW, PermissionEnum.DOCUMENT_UPDATE]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_EDIT_PARCEL_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_PARCEL_DATA_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_VIEW_NOTES_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_NOTE_CREATE, PermissionEnum.IBTM_DOMAIN_DOCUMENT_NOTE_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_CREATE_NOTE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_NOTE_CREATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_CHANGE_SEND_OPTION_BUTTON]: {
    anyOfPermission: [PermissionEnum.DOCUMENT_ADMIN],
    allOfPermissions: [PermissionEnum.DOCUMENT_VIEW, PermissionEnum.DOCUMENT_UPDATE]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_CANCEL_SENDING_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_PARCEL_WITHDRAW]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_CHANGE_DELIVERY_DATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.DOCUMENT_ADMIN],
    allOfPermissions: [PermissionEnum.DOCUMENT_VIEW, PermissionEnum.DOCUMENT_UPDATE]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_SET_RETURN_DATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_COURIER_PARCEL_RETURN]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_CREATE_COMPLAINT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_PARCEL_COMPLAINT_CREATE]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_GENERATE_LABEL_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_PARCEL_LABEL_GENERATE]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_GENERATE_BILLING_NOTE_BUTTON]: {
    anyOfPermission: [PermissionEnum.DOCUMENT_ADMIN, PermissionEnum.DOCUMENT_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_SCAN_PACKAGES_BUTTON]: {
    anyOfPermission: [PermissionEnum.DOCUMENT_ADMIN, PermissionEnum.DOCUMENT_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_GENERATE_LIST_FOR_COURIER_BUTTON]: {
    anyOfPermission: [PermissionEnum.DOCUMENT_ADMIN, PermissionEnum.DOCUMENT_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_PARCEL_SUMMARY_BUTTON]: {
    anyOfPermission: [PermissionEnum.DOCUMENT_VIEW, PermissionEnum.DOCUMENT_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.S_CERTIFICATE_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.S_CERTIFICATE_VIEW, PermissionEnum.S_CERTIFICATE_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.S_CERTIFICATE_APPLICATION_CREATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.APPLICATION_VIEW, PermissionEnum.APPLICATION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.S_CERTIFICATE_FOLDER_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.FOLDER_VIEW, PermissionEnum.FOLDER_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.ARCHIVE_MENU]: {
    nestedAnyOfPermissions: [
      [PermissionEnum.IBTM_DOMAIN_ARCHIVE_MENU_VIEW, PermissionEnum.IBTM_DOMAIN_ARCHIVE_BORROWED_FOLDERS_VIEW],
      [PermissionEnum.IBTM_DOMAIN_ARCHIVE_MENU_VIEW, PermissionEnum.IBTM_DOMAIN_ARCHIVE_APPLICATION_ARCHIVED_VIEW],
      [PermissionEnum.IBTM_DOMAIN_ARCHIVE_MENU_VIEW, PermissionEnum.IBTM_DOMAIN_ARCHIVE_APPLICATION_TO_ARCHIVE_VIEW],
      [PermissionEnum.IBTM_DOMAIN_ARCHIVE_MENU_VIEW, PermissionEnum.IBTM_DOMAIN_ARCHIVE_FOLDER_TO_ARCHIVE_VIEW],
      [PermissionEnum.IBTM_DOMAIN_ARCHIVE_MENU_VIEW, PermissionEnum.IBTM_DOMAIN_ARCHIVE_FOLDER_ARCHIVED_VIEW]
    ]
  },
  [DomainUIElementEnum.ARCHIVE_APPLICATIONS_LIST]: {
    anyOfPermission: [
      PermissionEnum.IBTM_DOMAIN_ARCHIVE_APPLICATION_TO_ARCHIVE_VIEW,
      PermissionEnum.IBTM_DOMAIN_ARCHIVE_APPLICATION_ARCHIVED_VIEW
    ]
  },
  [DomainUIElementEnum.ARCHIVE_APPLICATIONS_ARCHIVED_GENERATE_CASE_LIST_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.REPORT_INNE_GENERATE,
      PermissionEnum.REPORT_INNE_SPIS_SPRAW_GENERATE,
      PermissionEnum.REPORT_GENERATE
    ]
  },
  [DomainUIElementEnum.ARCHIVE_FOLDER_ARCHIVED_GENERATE_CASE_LIST_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.REPORT_INNE_GENERATE,
      PermissionEnum.REPORT_INNE_SPIS_ZDAWCZO_ODBIORCZY_AKT_GENERATE,
      PermissionEnum.REPORT_GENERATE
    ]
  },
  [DomainUIElementEnum.ARCHIVE_FOLDER_LIST]: {
    anyOfPermission: [
      PermissionEnum.IBTM_DOMAIN_ARCHIVE_FOLDER_ARCHIVED_VIEW,
      PermissionEnum.IBTM_DOMAIN_ARCHIVE_FOLDER_TO_ARCHIVE_VIEW
    ]
  },
  [DomainUIElementEnum.ARCHIVE_BORROWED_LIST]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_ARCHIVE_BORROWED_FOLDERS_VIEW]
  },
  [DomainUIElementEnum.ARCHIVE_APPLICATIONS_TO_ARCHIVE_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_ARCHIVE_APPLICATION_TO_ARCHIVE_VIEW]
  },
  [DomainUIElementEnum.ARCHIVE_APPLICATIONS_FORWARD_TO_ARCHIVE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_ARCHIVE_APPLICATION_TO_REPOSITORY_TRANSFER]
  },
  [DomainUIElementEnum.ARCHIVE_APPLICATIONS_FORWARD_TO_ARCHIVE_MANY_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_ARCHIVE_APPLICATION_TO_REPOSITORY_TRANSFER_MANY]
  },
  [DomainUIElementEnum.ARCHIVE_APPLICATIONS_TO_ARCHIVE_ADD_NOTE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_ARCHIVE_APPLICATION_NOTE_CREATE]
  },
  [DomainUIElementEnum.ARCHIVE_APPLICATIONS_ARCHIVED_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_ARCHIVE_APPLICATION_ARCHIVED_VIEW]
  },
  [DomainUIElementEnum.ARCHIVE_APPLICATIONS_ARCHIVED_SHOW_DETAILS_BUTTON]: {
    anyOfPermission: []
  },
  [DomainUIElementEnum.ARCHIVE_APPLICATIONS_ARCHIVED_UNDO_FROM_ARCHIVE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_ARCHIVE_APPLICATION_TO_REPOSITORY_WITHDRAW]
  },
  [DomainUIElementEnum.ARCHIVE_APPLICATIONS_ARCHIVED_ADD_NOTE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_ARCHIVE_APPLICATION_NOTE_CREATE]
  },
  [DomainUIElementEnum.ARCHIVE_FOLDER_TO_ARCHIVE_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_ARCHIVE_FOLDER_TO_ARCHIVE_VIEW]
  },
  [DomainUIElementEnum.ARCHIVE_FOLDER_TO_ARCHIVE_SHOW_DETAILS_BUTTON]: {
    anyOfPermission: []
  },
  [DomainUIElementEnum.ARCHIVE_APPLICATIONS_TO_ARCHIVE_SHOW_DETAILS_BUTTON]: {
    anyOfPermission: []
  },
  [DomainUIElementEnum.ARCHIVE_FOLDER_TO_ARCHIVE_FORWARD_TO_ARCHIVE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_ARCHIVE_FOLDER_TO_REPOSITORY_TRANSFER]
  },
  [DomainUIElementEnum.ARCHIVE_FOLDER_TO_ARCHIVE_GENERATE_GENERATE_CASE_LIST_BUTTON]: {
    anyOfPermission: []
  },
  [DomainUIElementEnum.ARCHIVE_FOLDER_TO_ARCHIVE_ADD_NOTE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_ARCHIVE_FOLDER_NOTE_CREATE]
  },
  [DomainUIElementEnum.ARCHIVE_FOLDER_ARCHIVED_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_ARCHIVE_FOLDER_ARCHIVED_VIEW]
  },
  [DomainUIElementEnum.ARCHIVE_FOLDER_ARCHIVED_SHOW_DETAILS_BUTTON]: {
    anyOfPermission: []
  },
  [DomainUIElementEnum.ARCHIVE_FOLDER_ARCHIVED_UNDO_FROM_ARCHIVE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_ARCHIVE_FOLDER_TO_REPOSITORY_WITHDRAW]
  },
  [DomainUIElementEnum.ARCHIVE_FOLDER_ARCHIVED_ADD_NOTE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_ARCHIVE_FOLDER_NOTE_CREATE]
  },
  [DomainUIElementEnum.ARCHIVE_BORROWED_SHOW_DETAILS_BUTTON]: {
    anyOfPermission: []
  },
  [DomainUIElementEnum.ARCHIVE_BORROWED_BORROW_FOLDER_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_ARCHIVE_FOLDER_BORROW]
  },
  [DomainUIElementEnum.ARCHIVE_BORROWED_RETURN_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_ARCHIVE_FOLDER_RETURN]
  },
  [DomainUIElementEnum.ARCHIVE_BORROWED_CHANGE_BORROWER_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_ARCHIVE_BORROWER_UPDATE]
  },
  [DomainUIElementEnum.SUSPENSIONS_LIST]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_SUSPENSION_LIST_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SUSPENSIONS_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_SUSPENSION_UPDATE]
  },
  [DomainUIElementEnum.SUSPENSIONS_CLOSE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_SUSPENSION_STATUS_TERMINATE]
  },
  [DomainUIElementEnum.SUSPENSIONS_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_SUSPENSION_DELETE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SUSPENSIONS_VIEW_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_SUSPENSION_DETAILS_VIEW]
  },
  [DomainUIElementEnum.SUSPENSIONS_EDIT_SAVE_BUTTON]: {
    anyOfPermission: [PermissionEnum.SUSPENSION_ADMIN],
    allOfPermissions: [PermissionEnum.SUSPENSION_UPDATE, PermissionEnum.SUSPENSION_VIEW]
  },
  [DomainUIElementEnum.SUSPENSIONS_EDIT_END_BUTTON]: {
    anyOfPermission: [PermissionEnum.SUSPENSION_ADMIN],
    allOfPermissions: [PermissionEnum.SUSPENSION_UPDATE, PermissionEnum.SUSPENSION_VIEW]
  },
  [DomainUIElementEnum.SUSPENSIONS_EDIT_CANCEL_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_SUSPENSION_STATUS_CANCEL]
  },
  [DomainUIElementEnum.SUSPENSIONS_EDIT_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.SUSPENSION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SUSPENSIONS_DETAILS_VIEW]: {
    anyOfPermission: [PermissionEnum.SUSPENSION_ADMIN, PermissionEnum.SUSPENSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SUSPENSIONS_FORM_OPERATIONS_VIEW]: {
    anyOfPermission: [PermissionEnum.SUSPENSION_ADMIN, PermissionEnum.SUSPENSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SUSPENSIONS_FORM_OPERATIONS_ADD_BUTTON]: {
    nestedAnyOfPermissions: [
      [PermissionEnum.SUSPENSION_ADMIN, PermissionEnum.FOLDER_ADMIN, PermissionEnum.FORM_OPERATION_CREATE],
      [PermissionEnum.SUSPENSION_ADMIN, PermissionEnum.FOLDER_ADMIN, PermissionEnum.FORM_OPERATION_ADMIN],
      [PermissionEnum.SUSPENSION_ADMIN, PermissionEnum.FOLDER_VIEW, PermissionEnum.FORM_OPERATION_CREATE],
      [PermissionEnum.SUSPENSION_ADMIN, PermissionEnum.FOLDER_VIEW, PermissionEnum.FORM_OPERATION_ADMIN]
    ]
  },
  [DomainUIElementEnum.SUSPENSIONS_FORM_OPERATIONS_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.SUSPENSION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SUSPENSIONS_FORM_OPERATIONS_RESUME_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_SUSPENSION_RESOURCE_RELEASE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SUSPENSIONS_FORM_OPERATIONS_SUSPEND_BUTTON]: {
    anyOfPermission: [PermissionEnum.FOLDER_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SUSPENSIONS_FORM_OPERATIONS_SHOW_ACTIVE_PERMISSIONS_BUTTON]: {
    anyOfPermission: [PermissionEnum.FOLDER_VIEW, PermissionEnum.FOLDER_ADMIN]
  },
  [DomainUIElementEnum.SUSPENSIONS_RELEASE_DOCUMENTS_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_INVOICE_DETAILS_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SUSPENSIONS_RELEASE_DOCUMENTS_DOWNLOAD_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_INVOICE_CONTENT_DOWNLOAD],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SUSPENSIONS_NOTE_VIEW]: {
    anyOfPermission: [PermissionEnum.SUSPENSION_ADMIN, PermissionEnum.SUSPENSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SUSPENSIONS_NOTE_SAVE_BUTTON]: {
    anyOfPermission: [PermissionEnum.SUSPENSION_ADMIN],
    allOfPermissions: [PermissionEnum.SUSPENSION_CREATE, PermissionEnum.SUSPENSION_VIEW]
  },
  [DomainUIElementEnum.SUSPENSIONS_NOTE_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.SUSPENSION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SUSPENSIONS_NOTE_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.SUSPENSION_ADMIN],
    allOfPermissions: [PermissionEnum.SUSPENSION_UPDATE, PermissionEnum.SUSPENSION_VIEW]
  },
  [DomainUIElementEnum.SUSPENSIONS_DOCUMENTS_VIEW]: {
    anyOfPermission: [PermissionEnum.SUSPENSION_ADMIN, PermissionEnum.SUSPENSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SUSPENSIONS_DOCUMENTS_UPLOAD_BUTTON]: {
    anyOfPermission: [PermissionEnum.SUSPENSION_ADMIN],
    allOfPermissions: [PermissionEnum.SUSPENSION_CREATE, PermissionEnum.SUSPENSION_VIEW]
  },
  [DomainUIElementEnum.SUSPENSIONS_DOCUMENTS_ADD_DELIVERY_DATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.DOCUMENT_ADMIN],
    allOfPermissions: [PermissionEnum.DOCUMENT_CREATE, PermissionEnum.DOCUMENT_VIEW]
  },
  [DomainUIElementEnum.SUSPENSIONS_DOCUMENTS_CANCEL_BUTTON]: {
    anyOfPermission: [PermissionEnum.APPLICATION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SUSPENSIONS_FILES_VIEW]: {
    anyOfPermission: [PermissionEnum.SUSPENSION_VIEW, PermissionEnum.SUSPENSION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SUSPENSIONS_FILES_UPLOAD_BUTTON]: {
    anyOfPermission: [PermissionEnum.SUSPENSION_ADMIN],
    allOfPermissions: [PermissionEnum.SUSPENSION_VIEW, PermissionEnum.SUSPENSION_CREATE]
  },
  [DomainUIElementEnum.SUSPENSIONS_FILES_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.SUSPENSION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SUSPENSIONS_FILES_DOWNLOAD_BUTTON]: {
    anyOfPermission: [PermissionEnum.SUSPENSION_VIEW, PermissionEnum.SUSPENSION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SUSPENSIONS_CORRECTION_CREATE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_SUSPENSION_CORRECTION_CREATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SUSPENSIONS_CORRECTION_LIST]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_SUSPENSION_INVOICE_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SUSPENSIONS_CORRECTION_UPDATE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_SUSPENSION_CORRECTION_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SUSPENSIONS_CORRECTION_CANCEL]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_SUSPENSION_CORRECTION_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.CARRY_ON_ARCHIVED_FOLDER_SHOW_DETAILS_BUTTON]: {
    anyOfPermission: []
  },
  [DomainUIElementEnum.RESOURCES_LIST]: {
    anyOfPermission: [],
    nestedAnyOfPermissions: [
      [PermissionEnum.DEPOT_VIEW, PermissionEnum.DEPOT_MANAGER_VIEW, PermissionEnum.RESOURCE_OBJECT_VIEW],
      [PermissionEnum.DEPOT_VIEW, PermissionEnum.DEPOT_MANAGER_ADMIN, PermissionEnum.RESOURCE_OBJECT_VIEW],
      [PermissionEnum.DEPOT_VIEW, PermissionEnum.DEPOT_MANAGER_ADMIN, PermissionEnum.RESOURCE_OBJECT_ADMIN],
      [PermissionEnum.DEPOT_ADMIN, PermissionEnum.RESOURCE_OBJECT_VIEW],
      [PermissionEnum.DEPOT_ADMIN, PermissionEnum.RESOURCE_OBJECT_ADMIN]
    ]
  },
  [DomainUIElementEnum.RESOURCES_DELETE_RESOURCE_TYPE_BUTTON]: {
    anyOfPermission: [PermissionEnum.RESOURCE_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.RESOURCES_TRANSFERS_CONFIRM_RESOURCE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_RESOURCE_OBJECT_TRANSFER_CONFIRM],
    allOfPermissions: []
  },
  [DomainUIElementEnum.RESOURCES_TRANSFERS_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.DEPOT_TRANSFER_ADMIN, PermissionEnum.DEPOT_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.RESOURCES_FORM_TYPES_EDIT_BUTTON]: {
    anyOfPermission: [],
    allOfPermissions: []
  },
  [DomainUIElementEnum.RESOURCES_FORM_TYPES_DELETE_BUTTON]: {
    anyOfPermission: [],
    allOfPermissions: []
  },
  [DomainUIElementEnum.RESOURCES_DETAILS_SAVE_BUTTON]: {
    anyOfPermission: [],
    allOfPermissions: []
  },
  [DomainUIElementEnum.RESOURCES_DETAILS_REJECT_BUTTON]: {
    anyOfPermission: [PermissionEnum.RESOURCE_TYPE_ADMIN, PermissionEnum.RESOURCE_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.RESOURCES_DETAILS_BATCH_DISPOSALS_BUTTON]: {
    anyOfPermission: [PermissionEnum.DISPOSAL_CREATE, PermissionEnum.DISPOSAL_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.RESOURCES_SEARCH_SETTLEMENT_BUTTON]: {
    nestedAnyOfPermissions: [
      [
        PermissionEnum.DEPOT_VIEW,
        PermissionEnum.DEPOT_MANAGER_VIEW,
        PermissionEnum.DEPOT_CONTENT_VIEW,
        PermissionEnum.RESOURCE_OBJECT_VIEW,
        PermissionEnum.RESOURCE_ROLLBACK_RETURNS_CREATE
      ],
      [
        PermissionEnum.DEPOT_ADMIN,
        PermissionEnum.DEPOT_MANAGER_VIEW,
        PermissionEnum.DEPOT_CONTENT_VIEW,
        PermissionEnum.RESOURCE_OBJECT_VIEW,
        PermissionEnum.RESOURCE_ROLLBACK_RETURNS_CREATE
      ],
      [
        PermissionEnum.DEPOT_VIEW,
        PermissionEnum.DEPOT_MANAGER_ADMIN,
        PermissionEnum.DEPOT_CONTENT_VIEW,
        PermissionEnum.RESOURCE_OBJECT_VIEW,
        PermissionEnum.RESOURCE_ROLLBACK_RETURNS_CREATE
      ],
      [
        PermissionEnum.DEPOT_VIEW,
        PermissionEnum.DEPOT_MANAGER_VIEW,
        PermissionEnum.DEPOT_CONTENT_ADMIN,
        PermissionEnum.RESOURCE_OBJECT_VIEW,
        PermissionEnum.RESOURCE_ROLLBACK_RETURNS_CREATE
      ],
      [
        PermissionEnum.DEPOT_ADMIN,
        PermissionEnum.DEPOT_MANAGER_ADMIN,
        PermissionEnum.DEPOT_CONTENT_VIEW,
        PermissionEnum.RESOURCE_OBJECT_VIEW,
        PermissionEnum.RESOURCE_ROLLBACK_RETURNS_CREATE
      ],
      [
        PermissionEnum.DEPOT_ADMIN,
        PermissionEnum.DEPOT_MANAGER_VIEW,
        PermissionEnum.DEPOT_CONTENT_ADMIN,
        PermissionEnum.RESOURCE_OBJECT_VIEW,
        PermissionEnum.RESOURCE_ROLLBACK_RETURNS_CREATE
      ],
      [
        PermissionEnum.DEPOT_VIEW,
        PermissionEnum.DEPOT_MANAGER_ADMIN,
        PermissionEnum.DEPOT_CONTENT_ADMIN,
        PermissionEnum.RESOURCE_OBJECT_VIEW,
        PermissionEnum.RESOURCE_ROLLBACK_RETURNS_CREATE
      ],
      [
        PermissionEnum.DEPOT_ADMIN,
        PermissionEnum.DEPOT_MANAGER_ADMIN,
        PermissionEnum.DEPOT_CONTENT_ADMIN,
        PermissionEnum.RESOURCE_OBJECT_VIEW,
        PermissionEnum.RESOURCE_ROLLBACK_RETURNS_CREATE
      ],
      [
        PermissionEnum.DEPOT_VIEW,
        PermissionEnum.DEPOT_MANAGER_VIEW,
        PermissionEnum.DEPOT_CONTENT_VIEW,
        PermissionEnum.RESOURCE_OBJECT_ADMIN,
        PermissionEnum.RESOURCE_ROLLBACK_RETURNS_CREATE
      ],
      [
        PermissionEnum.DEPOT_ADMIN,
        PermissionEnum.DEPOT_MANAGER_VIEW,
        PermissionEnum.DEPOT_CONTENT_VIEW,
        PermissionEnum.RESOURCE_OBJECT_ADMIN,
        PermissionEnum.RESOURCE_ROLLBACK_RETURNS_CREATE
      ],
      [
        PermissionEnum.DEPOT_VIEW,
        PermissionEnum.DEPOT_MANAGER_ADMIN,
        PermissionEnum.DEPOT_CONTENT_VIEW,
        PermissionEnum.RESOURCE_OBJECT_ADMIN,
        PermissionEnum.RESOURCE_ROLLBACK_RETURNS_CREATE
      ],
      [
        PermissionEnum.DEPOT_VIEW,
        PermissionEnum.DEPOT_MANAGER_VIEW,
        PermissionEnum.DEPOT_CONTENT_ADMIN,
        PermissionEnum.RESOURCE_OBJECT_ADMIN,
        PermissionEnum.RESOURCE_ROLLBACK_RETURNS_CREATE
      ],
      [
        PermissionEnum.DEPOT_ADMIN,
        PermissionEnum.DEPOT_MANAGER_ADMIN,
        PermissionEnum.DEPOT_CONTENT_VIEW,
        PermissionEnum.RESOURCE_OBJECT_ADMIN,
        PermissionEnum.RESOURCE_ROLLBACK_RETURNS_CREATE
      ],
      [
        PermissionEnum.DEPOT_ADMIN,
        PermissionEnum.DEPOT_MANAGER_VIEW,
        PermissionEnum.DEPOT_CONTENT_ADMIN,
        PermissionEnum.RESOURCE_OBJECT_ADMIN,
        PermissionEnum.RESOURCE_ROLLBACK_RETURNS_CREATE
      ],
      [
        PermissionEnum.DEPOT_VIEW,
        PermissionEnum.DEPOT_MANAGER_ADMIN,
        PermissionEnum.DEPOT_CONTENT_ADMIN,
        PermissionEnum.RESOURCE_OBJECT_ADMIN,
        PermissionEnum.RESOURCE_ROLLBACK_RETURNS_CREATE
      ],
      [
        PermissionEnum.DEPOT_ADMIN,
        PermissionEnum.DEPOT_MANAGER_ADMIN,
        PermissionEnum.DEPOT_CONTENT_ADMIN,
        PermissionEnum.RESOURCE_OBJECT_ADMIN,
        PermissionEnum.RESOURCE_ROLLBACK_RETURNS_CREATE
      ]
    ]
  },
  [DomainUIElementEnum.ARCHIVE_FOLDER_CARRY_ON_ARCHIVE_FOLDER]: {
    anyOfPermission: [PermissionEnum.ARCHIVE_ADMIN]
  },
  [DomainUIElementEnum.RELEASE_DOCUMENT_LIST]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_INVOICE_LIST_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.RELEASE_DOCUMENT_ADD_CORRECTION_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_INVOICE_CORRECTION_CREATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.RELEASE_DOCUMENT_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_INVOICE_CORRECTION_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.RELEASE_DOCUMENT_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_INVOICE_DETAILS_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.RELEASE_DOCUMENT_CANCEL_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_INVOICE_CORRECTION_CANCEL],
    allOfPermissions: []
  },
  [DomainUIElementEnum.RELEASE_DOCUMENT_DOWNLOAD_DOCUMENT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_INVOICE_CONTENT_DOWNLOAD],
    allOfPermissions: []
  },
  [DomainUIElementEnum.RELEASE_DOCUMENT_CONFIRM_BUTTON]: {
    anyOfPermission: [PermissionEnum.INVOICE_ADMIN, PermissionEnum.INVOICE_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.DRIVER_GO_TO_PROCEEDING]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_DRIVERS_GO_TO_PROCEEDING]: {
    allOfPermissions: [PermissionEnum.PROCEEDING_VIEW]
  },
  [DomainUIElementEnum.FOLDER_DRIVERS_GO_TO_PROCEEDING]: {
    allOfPermissions: [PermissionEnum.PROCEEDING_VIEW]
  },
  [DomainUIElementEnum.RELEASE_DOCUMENT_CREATE_CORRECTION_BUTTON]: {
    anyOfPermission: [PermissionEnum.INVOICE_ADMIN, PermissionEnum.INVOICE_CREATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.DRIVER_LIST]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DRIVER_LIST_VIEW]
  },
  [DomainUIElementEnum.DRIVER_EXPIRE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DRIVER_DRIVER_CERTIFICATE_REVOKE]
  },
  [DomainUIElementEnum.DRIVER_ACTIVATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DRIVER_DRIVER_CERTIFICATE_RESUME]
  },
  [DomainUIElementEnum.DRIVER_GENERATE_UP_TO_DATE_REPORT_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.REPORT_WSK_GENERATE,
      PermissionEnum.REPORT_WSK_WAZNE_SK_FIRMA_GENERATE,
      PermissionEnum.REPORT_GENERATE
    ]
  },
  [DomainUIElementEnum.DRIVER_GENERATE_ISSUED_REPORT_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.REPORT_WSK_GENERATE,
      PermissionEnum.REPORT_WSK_WYDANE_SK_FIRMA_GENERATE,
      PermissionEnum.REPORT_GENERATE
    ]
  },
  [DomainUIElementEnum.DRIVER_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_DRIVER_DETAILS_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.DRIVER_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.DRIVER_ADMIN, PermissionEnum.DRIVER_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_LIST]: {
    anyOfPermission: [
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_LIST_VIEW,
      PermissionEnum.ASSIGN_TO_TRANSFER_FOLDER,
      PermissionEnum.TRANSFER_FOLDER
    ]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_VIEW_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_DETAILS_VIEW]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_CHANGE_STATUS_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_STATUS_UPDATE]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_TRANSFER_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_ASSIGNED_TO_UPDATE]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_START_PROCEEDING_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_CREATE_FROM_VIOLATION]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_ADD_TO_PROCEEDING_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_VIOLATION_PROCEEDING_BIND]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_LIST_VIEW]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_DETAILS_VIEW]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_CHANGE_STATUS_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_STATUS_UPDATE]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_VIEW]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_KREPTD_VIEW, PermissionEnum.PROCEEDING_KREPTD_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_START_SECTION]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_KREPTD_VIEW, PermissionEnum.PROCEEDING_KREPTD_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_DECISION_SECTION]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_KREPTD_VIEW, PermissionEnum.PROCEEDING_KREPTD_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_LICENSE_EXCERPTS]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_KREPTD_VIEW, PermissionEnum.PROCEEDING_KREPTD_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_SECOND_TIER_SECTION]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_KREPTD_VIEW, PermissionEnum.PROCEEDING_KREPTD_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_CHANGE_FOLDER_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_FOLDER_SWAP]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_CHANGE_STATUS_BUTTON]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_KREPTD_UPDATE, PermissionEnum.PROCEEDING_KREPTD_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_SAVE_BUTTON]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_KREPTD_ADMIN],
    allOfPermissions: [PermissionEnum.PROCEEDING_KREPTD_UPDATE, PermissionEnum.PROCEEDING_KREPTD_VIEW]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_GOOD_REPUTATION_EDIT_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_GOOD_REPUTATION_UPDATE,
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_UPDATE
    ]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_DECLARATION_OF_INABILITY_EDIT_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_DECLARATION_OF_INABILITY_UPDATE,
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_UPDATE
    ]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_DISCHARGE_AND_SUSPENSION_OF_CERTIFICATES_EDIT_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_DISCHARGE_AND_SUSPENSION_OF_CERTIFICATES_UPDATE,
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_UPDATE
    ]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_IMPOSING_PENALTY_EDIT_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_IMPOSING_PENALTY_UPDATE,
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_UPDATE
    ]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_PERMIT_SUSPENSION_EDIT_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_PERMIT_SUSPENSION_UPDATE,
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_UPDATE
    ]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_RESTORE_OF_ABILITIES_EDIT_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_RESTORE_OF_ABILITIES_UPDATE,
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_UPDATE
    ]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_DOCUMENTS_VIEW]: {
    nestedAnyOfPermissions: [
      [PermissionEnum.DOCUMENT_VIEW, PermissionEnum.PROCEEDING_KREPTD_VIEW],
      [PermissionEnum.DOCUMENT_ADMIN, PermissionEnum.PROCEEDING_KREPTD_ADMIN]
    ]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_GENERATE_CONFIRM_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_SUBMISSION_CONFIRMATION_GENERATE]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_GENERATE_RELEASE_DOCUMENTS_BUTTON]: {
    nestedAnyOfPermissions: [
      [
        PermissionEnum.DOCUMENT_CREATE,
        PermissionEnum.PROCEEDING_KREPTD_ADMIN,
        PermissionEnum.DOCUMENT_VIEW,
        PermissionEnum.PROCEEDING_KREPTD_VIEW
      ],
      [PermissionEnum.DOCUMENT_ADMIN, PermissionEnum.PROCEEDING_KREPTD_ADMIN]
    ]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_UPLOAD_DOCUMENT_BUTTON]: {
    nestedAnyOfPermissions: [
      [PermissionEnum.DOCUMENT_UPDATE, PermissionEnum.PROCEEDING_KREPTD_VIEW],
      [PermissionEnum.DOCUMENT_ADMIN, PermissionEnum.PROCEEDING_KREPTD_ADMIN]
    ]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_ADD_RECIEVE_DATE_BUTTON]: {
    nestedAnyOfPermissions: [
      [PermissionEnum.DOCUMENT_UPDATE, PermissionEnum.PROCEEDING_KREPTD_VIEW],
      [PermissionEnum.DOCUMENT_ADMIN, PermissionEnum.PROCEEDING_KREPTD_ADMIN]
    ]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_CANCEL_DOCUMENT_BUTTON]: {
    nestedAnyOfPermissions: [
      [PermissionEnum.APPLICATION_ADMIN, PermissionEnum.PROCEEDING_KREPTD_VIEW],
      [PermissionEnum.APPLICATION_ADMIN, PermissionEnum.PROCEEDING_KREPTD_ADMIN]
    ]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_FINAL_DECISION]: {
    anyOfPermission: [PermissionEnum.DOCUMENT_UPDATE, PermissionEnum.DOCUMENT_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_CASH_PENALTY_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_IMPOSING_PENALTY_CREATE]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_DECISION_SUSPENSION_SECTION]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_KREPTD_VIEW, PermissionEnum.PROCEEDING_KREPTD_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_CERTIFICATES_RETURN_SECTION]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_KREPTD_VIEW, PermissionEnum.PROCEEDING_KREPTD_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_RESTORATION_PROCEEDING_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_RESTORE_OF_ABILITIES_CREATE]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_VIOLATIONS_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_VIOLATION_ASSIGNED_LIST_VIEW]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_VIOLATIONS_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.VIOLATION_VIEW, PermissionEnum.VIOLATION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_VIOLATIONS_CHANGE_STATUS_BUTTON]: {
    anyOfPermission: [PermissionEnum.VIOLATION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_TRANSPORT_MANAGERS_VIEW]: {
    anyOfPermission: [PermissionEnum.TRANSPORT_MANAGER_VIEW, PermissionEnum.TRANSPORT_MANAGER_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_CHECK_KREPTD_BUTTON]: {
    nestedAnyOfPermissions: [
      [
        PermissionEnum.PROCEEDING_KREPTD_VIEW,
        PermissionEnum.TRANSPORT_MANAGER_VIEW,
        PermissionEnum.REPUTATION_REQUEST_CREATE
      ],
      [
        PermissionEnum.PROCEEDING_KREPTD_ADMIN,
        PermissionEnum.TRANSPORT_MANAGER_VIEW,
        PermissionEnum.REPUTATION_REQUEST_CREATE
      ],
      [
        PermissionEnum.PROCEEDING_KREPTD_VIEW,
        PermissionEnum.TRANSPORT_MANAGER_ADMIN,
        PermissionEnum.REPUTATION_REQUEST_CREATE
      ],
      [
        PermissionEnum.PROCEEDING_KREPTD_ADMIN,
        PermissionEnum.TRANSPORT_MANAGER_ADMIN,
        PermissionEnum.REPUTATION_REQUEST_CREATE
      ]
    ]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_SHOW_REPUTATION_BUTTON]: {
    nestedAnyOfPermissions: [
      [PermissionEnum.TRANSPORT_MANAGER_VIEW, PermissionEnum.REPUTATION_REQUEST_VIEW],
      [PermissionEnum.TRANSPORT_MANAGER_ADMIN, PermissionEnum.REPUTATION_REQUEST_VIEW]
    ]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_FOLDERS_TO_HANDED_OVER_VIEW]: {
    anyOfPermission: [PermissionEnum.ASSIGN_TO_TRANSFER_FOLDER, PermissionEnum.TRANSFER_FOLDER]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_VIOLATIONS_VIEW]: {
    anyOfPermission: [
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_VIOLATION_LIST_VIEW,
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_VIOLATION_ASSIGNED_LIST_VIEW
    ]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_FOLDERS_TO_HANDED_OVER_TRANSFER_BUTTON]: {
    anyOfPermission: [PermissionEnum.FOLDER_VIEW, PermissionEnum.FOLDER_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_FOLDERS_TO_HANDED_OVER_CHANGE_STATUS_BUTTON]: {
    anyOfPermission: [PermissionEnum.FOLDER_ADMIN],
    allOfPermissions: [PermissionEnum.FOLDER_UPDATE, PermissionEnum.FOLDER_VIEW]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_FOLDERS_TO_HANDED_OVER_GENERATE_DOCUMENT_BUTTON]: {
    anyOfPermission: [PermissionEnum.TRANSFER_FOLDER]
  },
  [DomainUIElementEnum.ROAD_TRANSPORT_FOLDERS_TO_ASSIGN_HANDED_OVER_GENERATE_DOCUMENT_BUTTON]: {
    anyOfPermission: [PermissionEnum.ASSIGN_TO_TRANSFER_FOLDER]
  },
  [DomainUIElementEnum.DRIVER_GENERATE_EXPIRE_REPORT_BUTTON]: {
    allOfPermissions: []
  },
  [DomainUIElementEnum.PERMITS_RELEASED_VIEW]: {
    anyOfPermission: [PermissionEnum.FOREIGN_PERMISSION_ADMIN, PermissionEnum.FOREIGN_PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PERMITS_RELEASED_EDIT]: {
    anyOfPermission: [PermissionEnum.FOREIGN_PERMISSION_ADMIN, PermissionEnum.FOREIGN_PERMISSION_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PERMITS_RELEASED_SETTLEMENT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOREIGN_PERMISSION_RETURN_ACCOUNT]
  },
  [DomainUIElementEnum.PRINT_REPORT_CONFIRMATION]: {
    allOfPermissions: [
      PermissionEnum.REPORT_WZZ_GENERATE,
      PermissionEnum.REPORT_WZZ_ZWROCONE_I_NIEZWROCONE_ZEZWOLENIA_GENERATE,
      PermissionEnum.REPORT_GENERATE
    ]
  },
  [DomainUIElementEnum.PERMITS_DISPOSABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.FOREIGN_PERMISSION_ADMIN, PermissionEnum.FOREIGN_PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PERMITS_DISPOSABLE_SHOW_DETAILS_VIEW]: {
    anyOfPermission: [PermissionEnum.FOLDER_LIMIT_ADMIN, PermissionEnum.FOLDER_LIMIT_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PERMITS_DISPOSABLE_REPORT_GENERATE_BUTTON]: {
    allOfPermissions: [PermissionEnum.REPORT_GENERATE, PermissionEnum.REPORT_WZZ_GENERATE]
  },
  [DomainUIElementEnum.PERMITS_EKMT_VIEW]: {
    anyOfPermission: [PermissionEnum.FOLDER_LIMIT_ADMIN, PermissionEnum.FOLDER_LIMIT_VIEW],
    allOfPermissions: []
  },

  [DomainUIElementEnum.SOCIAL_COMMITTEE_DATE_OF_MEETINGS_TAB]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_LIST_VIEW]
  },
  [DomainUIElementEnum.SOCIAL_COMMITTEE_FREE_DISPOSAL_RULE_LIST_VIEW]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_FREE_DISPOSAL_RULE_LIST_VIEW]
  },
  [DomainUIElementEnum.SOCIAL_COMMITTEE_LIMIT_LIST_VIEW]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_BASIC_LIMIT_LIST_VIEW]
  },

  [DomainUIElementEnum.SOCIAL_COMMITTEE_APPLICATIONS]: {
    anyOfPermission: [
      PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_LIST_VIEW,
      PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_LIST_VIEW
    ]
  },
  [DomainUIElementEnum.SOCIAL_COMMITTEE_SINGLE_PERMIT_APPLICATION]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_LIST_VIEW]
  },
  [DomainUIElementEnum.SOCIAL_COMMITTEE_EKMT_APPLICATION]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_LIST_VIEW]
  },

  [DomainUIElementEnum.COMMISSION_EKMT_LIST_VIEW]: {
    anyOfPermission: [PermissionEnum.CIVIC_BOARD_ADMIN, PermissionEnum.CIVIC_BOARD_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.COMMISSION_DEADLINES_VIEW]: {
    anyOfPermission: [PermissionEnum.CIVIC_BOARD_ADMIN, PermissionEnum.CIVIC_BOARD_VIEW],
    allOfPermissions: []
  },
  // Edycja wniosku (EKMT)
  [DomainUIElementEnum.COMMISSION_EKMT_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_UPDATE]
  },
  // Usunięcie wniosku (EKMT)
  [DomainUIElementEnum.COMMISSION_EKMT_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_DELETE]
  },
  // Dodanie wniosku (EKMT)
  [DomainUIElementEnum.COMMISSION_EKMT_ADD_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_CREATE]
  },
  // Wyświetlenie listy wniosków na komisje
  [DomainUIElementEnum.COMMISSION_PETITION_TAB]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_LIST_VIEW]
  },
  // Wyświetlenie szczegółów wniosku (Zezwolenie jednorazowe)
  [DomainUIElementEnum.COMMISSION_DISPOSABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_DETAILS_VIEW]
  },
  // Dodanie wniosku (Zezwolenie jednorazowe)
  [DomainUIElementEnum.COMMISSION_DISPOSABLE_ADD_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_CREATE]
  },
  // Usunięcie wniosku (Zezwolenie jednorazowe)
  [DomainUIElementEnum.COMMISSION_DISPOSABLE_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_DELETE]
  },
  // Edycja wniosku (Zezwolenie jednorazowe)
  [DomainUIElementEnum.COMMISSION_DISPOSABLE_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_UPDATE]
  },
  [DomainUIElementEnum.COMMISSION_TRADE_RULES_VIEW]: {
    anyOfPermission: [PermissionEnum.CIVIC_BOARD_ADMIN, PermissionEnum.CIVIC_BOARD_VIEW]
  },
  // Eksport pliku na komisję (EKMT)
  [DomainUIElementEnum.COMISSION_DISPOSABLE_EXPORT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_FILE_EXPORT]
  },
  // Import pliku z decyzją komisji (EKMT)
  [DomainUIElementEnum.COMISSION_DISPOSABLE_IMPORT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_FILE_IMPORT]
  },
  // Eksport pliku na komisję (EKMT)
  [DomainUIElementEnum.COMISSION_EKMT_EXPORT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_EXPORT]
  },
  // Import pliku z decyzją komisji (EKMT)
  [DomainUIElementEnum.COMISSION_EKMT_IMPORT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_IMPORT]
  },
  // Wyświetlenie szczegółów podania (EKMT)
  [DomainUIElementEnum.COMISSION_EKMT_DETAILS]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_DETAILS_VIEW]
  },
  // Dodanie decyzji (Zezwolenia jednorazowe)
  [DomainUIElementEnum.COMMISSION_DISPOSABLE_DECISION_ACTION_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_DECISION_MAKE]
  },
  // Aktualizacja decyzji (Zezwolenia jednorazowe)
  [DomainUIElementEnum.COMMISSION_DECISION_UPDATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_DECISION_RECEIPT_DATE_UPDATE]
  },
  // Terminy posiedzeń - Dodaj
  [DomainUIElementEnum.COMMISSION_DEADLINES_ADD]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_CREATE]
  },
  // Terminy posiedzeń - Edytuj
  [DomainUIElementEnum.COMMISSION_DEADLINES_EDIT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_UPDATE]
  },
  // Terminy posiedzeń - Usuń
  [DomainUIElementEnum.COMMISSION_DEADLINES_DELETE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_DELETE]
  },
  // Przelicz - Reguły wolnej sprzedaży
  [DomainUIElementEnum.COMMISSION_TRADE_RULES_RECALCULATE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_FREE_DISPOSAL_RULE_RECALCULATE]
  },
  // Edycja reguły wolnej sprzedaży
  [DomainUIElementEnum.COMMISSION_TRADE_RULES_EDIT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_FREE_DISPOSAL_RULE_UPDATE]
  },
  // Dodaj regułę wolnej sprzedaży
  [DomainUIElementEnum.COMMISSION_TRADE_RULES_ADD]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_FREE_DISPOSAL_RULE_CREATE]
  },
  // Usuń regułę wolnej sprzedaży
  [DomainUIElementEnum.COMMISSION_TRADE_RULES_DELETE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_FREE_DISPOSAL_RULE_DELETE]
  },
  // Przelicz (tabela) - Limity podstawowe
  [DomainUIElementEnum.COMMISSION_BASIC_RULES_RECALCULATE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_BASIC_LIMIT_RULE_RECALCULATE]
  },
  // Dodaj - Limity podstawowe
  [DomainUIElementEnum.COMMISSION_BASIC_RULES_ADD]: {
    anyOfPermission: []
  },
  // Usuń - Limity podstawowe
  [DomainUIElementEnum.COMMISSION_BASIC_RULES_DELETE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_BASIC_LIMIT_RULE_DELETE]
  },
  // Szczegóły - Limity podstawowe
  [DomainUIElementEnum.COMMISSION_BASIC_RULES_DETAILS]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_BASIC_LIMIT_DETAILS_VIEW]
  },
  // Edytuj - Limity podstawowe
  [DomainUIElementEnum.COMMISSION_BASIC_RULES_EDIT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_BASIC_LIMIT_RULE_UPDATE]
  },
  // Wyświetlenie listy wniosków z uprawnieniami do druku (Wydruki)
  [DomainUIElementEnum.PRINT_LIST]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PRINTS_LIST_VIEW]
  },
  [DomainUIElementEnum.PRINT_SET_PRINTING_PARAMETERS_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_PRINT_SETTINGS_SET],
    allOfPermissions: []
  },

  // Przyjęcie zewnętrzne
  [DomainUIElementEnum.RESOURCES_EXTERNAL_RECEIVE]: {
    anyOfPermission: [],
    allOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_RESOURCE_OBJECT_EXTERNAL_RECEIVE,
      PermissionEnum.IBTM_DOMAIN_DEPOT_UPDATE
    ]
  },
  // Przesunięcie blankietów pomiędzy magazynami
  [DomainUIElementEnum.RESOURCES_OBJECT_TRANSFER]: {
    anyOfPermission: [],
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_RESOURCE_OBJECT_TRANSFER, PermissionEnum.IBTM_DOMAIN_DEPOT_UPDATE]
  },
  // Typy blankietów
  [DomainUIElementEnum.RESOURCES_FORM_TYPES]: {
    anyOfPermission: [],
    allOfPermissions: []
  },
  // Wyszukanie blankietu
  [DomainUIElementEnum.RESOURCES_OBJECT_SEARCH]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_RESOURCE_OBJECT_SEARCH],
    allOfPermissions: []
  },
  // Przeglądanie szczegółów magazynu
  [DomainUIElementEnum.RESOURCES_DEPOT_DETAILS_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DEPOT_DETAILS_VIEW],
    allOfPermissions: []
  },
  // Zmiana administratorów
  [DomainUIElementEnum.RESOURCES_DEPOT_CHANGE_ADMIN]: {
    anyOfPermission: [],
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_DEPOT_ADMINISTRATORS_UPDATE, PermissionEnum.IBTM_DOMAIN_DEPOT_UPDATE]
  },
  // Dodanie administratora
  [DomainUIElementEnum.RESOURCES_DEPOT_ADD_ADMIN]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DEPOT_ADMINISTRATORS_ADD],
    allOfPermissions: []
  },
  // Usunięcie administratora
  [DomainUIElementEnum.RESOURCES_DEPOT_DELETE_ADMIN]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DEPOT_ADMINISTRATORS_DELETE],
    allOfPermissions: []
  },
  // Wydanie zewnętrzne
  [DomainUIElementEnum.RESOURCES_EXTERNAL_RELEASE]: {
    anyOfPermission: [],
    allOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_RESOURCE_OBJECT_EXTERNAL_RELEASE,
      PermissionEnum.IBTM_DOMAIN_DEPOT_UPDATE
    ]
  },
  // Wyświetlenie listy magazynów
  [DomainUIElementEnum.RESOURCES_DEPOT_LIST]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DEPOT_LIST_VIEW],
    allOfPermissions: []
  },

  // Edycja magazynu
  [DomainUIElementEnum.RESOURCES_DEPOT_EDIT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DEPOT_UPDATE],
    allOfPermissions: []
  },

  // Utworzenie nowego typu blankietu
  [DomainUIElementEnum.RESOURCES_DEPOT_CREATE_OBJECT_TYPE]: {
    anyOfPermission: [],
    allOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_RESOURCE_OBJECT_RESOURCE_TYPE_CREATE,
      PermissionEnum.IBTM_DOMAIN_DEPOT_UPDATE
    ]
  },
  // Brakowanie
  [DomainUIElementEnum.RESOURCES_DEFECTIVE_CHANGE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_RESOURCE_OBJECT_DEFECTIVE_CHANGE],
    allOfPermissions: []
  },
  // Zarządzanie typami blankietów
  [DomainUIElementEnum.RESOURCES_OBJECT_TYPE_MANAGEMENT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_RESOURCE_OBJECT_RESOURCE_TYPE_MANAGE],
    allOfPermissions: []
  },
  // Zainicjowanie przesunięcia
  [DomainUIElementEnum.RESOURCES_OBJECT_TRANSFER_INIT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_RESOURCE_OBJECT_TRANSFER_FORM_VIEW],
    allOfPermissions: []
  },
  // Usunięcie typu blankietu
  [DomainUIElementEnum.RESOURCES_OBJECT_TYPE_DELETE]: {
    anyOfPermission: [],
    allOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_RESOURCE_OBJECT_RESOURCE_TYPE_DELETE,
      PermissionEnum.IBTM_DOMAIN_DEPOT_UPDATE
    ]
  },
  // Wywołanie raportu Zestawienie stanów magazynowych)
  [DomainUIElementEnum.RESOURCES_DEPOT_STATE_REPORT]: {
    allOfPermissions: [
      PermissionEnum.REPORT_WZZ_GENERATE,
      PermissionEnum.REPORT_WZZ_ZESTAWIENIE_STANOW_MAGAZYNOWYCH_GENERATE,
      PermissionEnum.REPORT_GENERATE
    ]
  },
  // Potwierdzenie otrzymania blankietów
  [DomainUIElementEnum.RESOURCES_OBJECT_CONFIRM]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_RESOURCE_OBJECT_TRANSFER_CONFIRM],
    allOfPermissions: []
  },
  // // Zwracanie zezwolenia
  [DomainUIElementEnum.RESOURCES_OBJECT_RETURN]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOREIGN_PERMISSION_RESOURCE_OBJECT_RETURN],
    allOfPermissions: []
  },
  // // Cofanie zwrotu zezwolenia
  [DomainUIElementEnum.RESOURCES_OBJECT_RETURN_ROLLBACK]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOREIGN_PERMISSION_RESOURCE_OBJECT_UNDO_RETURN],
    allOfPermissions: []
  },

  [DomainUIElementEnum.PRINT_GO_TO_RESOURCE_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.DEPOT_MANAGER_ADMIN, PermissionEnum.DEPOT_MANAGER_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.APPLICATION_PRINT_ASSIGN_TO_ME_BUTTON]: {
    anyOfPermission: [PermissionEnum.SIGNER_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PRINT_ASSIGN_TO_ME_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_PRINT_USER_ASSIGN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PRINT_SHOW_EDIT_VIEW_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_PRINT_ITEMS_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PRINT_GENERATE_INSTRUCTION_BUTTON]: {
    anyOfPermission: [PermissionEnum.SIGNER_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.LICENCE_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.LICENSE_ADMIN, PermissionEnum.LICENSE_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.LICENCE_COPY_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PERMIT_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PERMIT_COPY_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.DRIVER_CERTIFICATE_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.REGULAR_UE_TRANSPORT_PERMISSIONS_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.REGULAR_UE_TRANSPORT_PERMISSIONS_COPY_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.REGULAR_TRANSIT_THROUGH_RP_PERMISSIONS_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.REGULAR_BEYOND_UE_TRANSPORT_PERMISSIONS_EXTRACT_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.REGULAR_TRANSIT_THROUGH_RP_PERMISSIONS_EXTRACT_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SHUTTLE_SERVICES_PERMISISONS_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SHUTTLE_SERVICES_PERMISISONS_EXTRACT_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.OCCASIONAL_TRANSPORT_PERMISSIONS_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.OCCASIONAL_TRANSPORT_PERMISSIONS_EXTRACT_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.OWN_TRANSPORT_CERTIFICATES_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.OWN_TRANSPORT_CERTIFICATES_EXTRACT_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.REGULAR_TRANSIT_OUTSIDE_EU_PERMISSIONS_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOREIGN_PERMISSIONS_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN, PermissionEnum.PERMISSION_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.FOREIGN_PERMISSIONS_REGENERATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PRINTS_RESOURCE_OBJECT_NUMBER_DELETE]
  },
  [DomainUIElementEnum.FOREIGN_PERMISSIONS_PRINT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_RESOURCE_OBJECT_PRINT],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PRINT_FOR_FOREIGN_PERMISSIONS]: {
    allOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_DOCUMENT_RESOURCE_OBJECT_PRINT,
      PermissionEnum.IBTM_DOMAIN_FOREIGN_PERMISSION_RESOURCE_OBJECT_PRINT
    ]
  },
  [DomainUIElementEnum.EKMT_CARNETS_TABLE_VIEW]: {
    anyOfPermission: [PermissionEnum.RESOURCE_ASSIGNMENT_ADMIN, PermissionEnum.RESOURCE_ASSIGNMENT_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.EKMT_CARNETS_REGENERATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PRINTS_RESOURCE_OBJECT_NUMBER_DELETE]
  },
  [DomainUIElementEnum.PRINT_UPDATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_RECEIPT_TYPE_CHOOSE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PRINTS_PERMISSION_GENERATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PRINT_GENERATE_PARTNER_LIST_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.REPORT_WPO_GENERATE,
      PermissionEnum.REPORT_WPO_ZESTAWIENIE_PRZEDSIEBIORCOW_WYSTEPUJACYCH_JAKO_PARTNER_PODWYKONAWCA_GENERATE,
      PermissionEnum.REPORT_GENERATE
    ]
  },
  [DomainUIElementEnum.PRINT_GENERATE_TIMETABLE_BUTTON]: {
    anyOfPermission: [PermissionEnum.TRANSIT_SCHEDULE_ADMIN, PermissionEnum.TRANSIT_SCHEDULE_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PRINT_VEHICLE_LIST_BUTTON]: {
    anyOfPermission: [PermissionEnum.VEHICLE_ADMIN, PermissionEnum.VEHICLE_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PRINT_LIST_OF_ARRIVAL_DATES_BUTTON]: {
    anyOfPermission: [PermissionEnum.TRANSIT_SCHEDULE_ADMIN, PermissionEnum.TRANSIT_SCHEDULE_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PRINT_REGENERATE_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_PRINTS_PERMISSION_REGENERATE,
      PermissionEnum.IBTM_DOMAIN_PRINTS_RESOURCE_OBJECT_NUMBER_DELETE
    ]
  },
  [DomainUIElementEnum.GENERATE_LIST_OF_VEHICLES]: {
    allOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_DOCUMENT_TEMPLATE_VIEW,
      PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_UPDATE,
      PermissionEnum.IBTM_DOMAIN_DOCUMENT_FROM_TEMPLATE_GENERATE
    ]
  },
  [DomainUIElementEnum.GENERATE_TRANSIT_SCHEDULES_DATES]: {
    allOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_DOCUMENT_TEMPLATE_VIEW,
      PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_UPDATE,
      PermissionEnum.IBTM_DOMAIN_DOCUMENT_FROM_TEMPLATE_GENERATE
    ]
  },
  [DomainUIElementEnum.PRINT_SEND_FOR_RELEASE_BUTTON_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_ISSUE_TRANSFER]
  },
  [DomainUIElementEnum.PRINT_RETURN_APPLICATION_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_RETURNING_TO_VERIFY_UPDATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PERMISSION_ALL_PRINT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PRINTS_PERMISSION_ALL_PRINT],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PERMISSION_PRINT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PRINTS_PERMISSION_PRINT],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PRINT_GENERATE_PERMISSION_COPY_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PRINTS_PERMISSION_COPY_GENERATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PRINT_DATA_UPDATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_PRINT_DATA_UPDATE]
  },
  [DomainUIElementEnum.PROCEEDING_LIST]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_LIST_VIEW]
  },
  [DomainUIElementEnum.PROCEEDING_VERIFICATION_SELECT_OPTION]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_VERIFICATION_CREATE]
  },
  [DomainUIElementEnum.PROCEEDING_ADMINISTRATIVE_SELECT_OPTION]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_ADMINISTRATIVE_CREATE]
  },

  // Dodanie postępowania
  [DomainUIElementEnum.PROCEEDING_CREATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_CREATE]
  },
  [DomainUIElementEnum.PROCEEDING_VERIFICATION_EDIT_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_VERIFICATION_UPDATE,
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_UPDATE
    ]
  },
  [DomainUIElementEnum.PROCEEDING_ADMINISTRATIVE_EDIT_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_ADMINISTRATIVE_UPDATE,
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_UPDATE
    ]
  },
  [DomainUIElementEnum.PROCEEDING_CLOSE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_STATUS_TERMINATE]
  },
  [DomainUIElementEnum.PROCEEDING_ADMINISTRATIVE_DELETE_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_ADMINISTRATIVE_DELETE,
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_DELETE
    ]
  },
  [DomainUIElementEnum.PROCEEDING_VERIFICATION_DELETE_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_VERIFICATION_DELETE,
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_DELETE
    ]
  },
  [DomainUIElementEnum.PROCEEDING_ADMINISTRATIVE_VIEW_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_ADMINISTRATIVE_DETAILS_VIEW,
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_DETAILS_VIEW
    ]
  },
  [DomainUIElementEnum.PROCEEDING_VERIFICATION_VIEW_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_VERIFICATION_DETAILS_VIEW,
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_DETAILS_VIEW
    ]
  },
  [DomainUIElementEnum.PROCEEDING_SAVE_PROGRESS_BUTTON]: {
    anyOfPermission: [],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PROCEEDING_EDIT_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_DELETE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PROCEEDING_EDIT_END_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_STATUS_TERMINATE]
  },
  [DomainUIElementEnum.PROCEEDING_EDIT_CANCEL_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_STATUS_CANCEL]
  },
  [DomainUIElementEnum.PROCEEDING_EDIT_CHANGE_CONDUCTING_PERSON_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_ASSIGNED_TO_UPDATE]
  },
  [DomainUIElementEnum.PROCEEDING_DOCUMENTS_VIEW]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_VIEW, PermissionEnum.PROCEEDING_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_GENERATE_DOCUMENT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_FROM_TEMPLATE_GENERATE]
  },
  [DomainUIElementEnum.PROCEEDING_DOCUMENTS_DOWNLOAD_BUTTON]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_VIEW, PermissionEnum.PROCEEDING_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PROCEEDING_DOCUMENTS_UPLOAD_BUTTON]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_ADMIN],
    allOfPermissions: [PermissionEnum.PROCEEDING_VIEW, PermissionEnum.PROCEEDING_CREATE]
  },
  [DomainUIElementEnum.PROCEEDING_DOCUMENTS_CANCEL_BUTTON]: {
    anyOfPermission: [PermissionEnum.APPLICATION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PROCEEDING_DOCUMENTS_ADD_DELIVERY_DATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.DOCUMENT_ADMIN],
    allOfPermissions: [PermissionEnum.DOCUMENT_VIEW, PermissionEnum.DOCUMENT_CREATE]
  },
  [DomainUIElementEnum.PROCEEDING_DOCUMENTS_CHECK_FINAL_DECISION_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_FINAL_DECISION_CHECK]
  },
  [DomainUIElementEnum.PROCEEDING_DOCUMENTS_UNCHECK_FINAL_DECISION_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_FINAL_DECISION_UNCHECK]
  },
  [DomainUIElementEnum.PROCEEDING_DOCUMENTS_REVOKE_FINAL_DECISION_BUTTON]: {
    anyOfPermission: [PermissionEnum.DOCUMENT_ADMIN],
    allOfPermissions: [PermissionEnum.DOCUMENT_VIEW, PermissionEnum.DOCUMENT_UPDATE]
  },
  [DomainUIElementEnum.PROCEEDING_FORM_OPERATIONS_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_RESOURCE_OBJECT_VIEW]
  },
  [DomainUIElementEnum.PROCEEDING_FORM_OPERATIONS_ADD_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_RESOURCE_OBJECT_ADD],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PROCEEDING_FORM_OPERATIONS_SHOW_ACTIVE_PERMISSIONS_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_PERMISSIONS_ACTIVE_VIEW]
  },
  [DomainUIElementEnum.PROCEEDING_FORM_OPERATIONS_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_RESOURCE_OBJECT_DELETE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PROCEEDING_FORM_OPERATIONS_EXPIRE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_RESOURCE_OBJECT_EXPIRE]
  },
  [DomainUIElementEnum.PROCEEDING_FORM_OPERATIONS_UNDO_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_RESOURCE_OBJECT_REVOKE]
  },
  [DomainUIElementEnum.PROCEEDING_FORM_OPERATIONS_CHANGE_ACTIVE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_RESOURCE_OBJECT_STATUS_ACTIVE_UPDATE]
  },
  [DomainUIElementEnum.PROCEEDING_FORM_OPERATIONS_SUSPEND_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_RESOURCE_OBJECT_SUSPEND]
  },
  [DomainUIElementEnum.PROCEEDING_FORM_OPERATIONS_SUSPEND_ISSUING_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_DRIVER_CERTIFICATE_MANAGE]
  },
  [DomainUIElementEnum.PROCEEDING_FORM_OPERATIONS_EDIT_ISSUING_BUTTON]: {
    anyOfPermission: [PermissionEnum.FOLDER_VIEW, PermissionEnum.FOLDER_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PROCEEDING_FORM_OPERATIONS_UNSUSPEND_ISSUING_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_DRIVER_CERTIFICATE_MANAGE]
  },
  [DomainUIElementEnum.PROCEEDING_NOTE_VIEW]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_ADMIN, PermissionEnum.PROCEEDING_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PROCEEDING_NOTE_SAVE_BUTTON]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_ADMIN],
    allOfPermissions: [PermissionEnum.PROCEEDING_CREATE, PermissionEnum.PROCEEDING_VIEW]
  },
  [DomainUIElementEnum.PROCEEDING_NOTE_CANCEL_BUTTON]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PROCEEDING_FILES_VIEW]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_ADMIN, PermissionEnum.PROCEEDING_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PROCEEDING_FILES_UPLOAD_BUTTON]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_ADMIN],
    allOfPermissions: [PermissionEnum.PROCEEDING_CREATE, PermissionEnum.PROCEEDING_VIEW]
  },
  [DomainUIElementEnum.PROCEEDING_FILES_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.PROCEEDING_FILES_DOWNLOAD_BUTTON]: {
    anyOfPermission: [PermissionEnum.PROCEEDING_ADMIN, PermissionEnum.PROCEEDING_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.APPLICATION_LIST]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_LIST_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_FOLDER_SLIDER_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_FOLDER_SLIDER_VIEW],
    allOfPermissions: []
  },
  [DomainUIElementEnum.APPLICATION_FOLDER_LOCK_REDIRECT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_FOLDER_LOCK_REDIRECT],
    allOfPermissions: []
  },
  [DomainUIElementEnum.APPLICATION_FOLDER_REDIRECT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_FOLDER_REDIRECT],
    allOfPermissions: []
  },
  [DomainUIElementEnum.APPLICATION_SUBJECT_NIP_COPY_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_NIP_COPY],
    allOfPermissions: []
  },
  [DomainUIElementEnum.APPLICATION_SUBJECT_REGON_COPY_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_REGON_COPY],
    allOfPermissions: []
  },
  [DomainUIElementEnum.APPLICATION_SUBJECT_EMAIL_COPY_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_EMAIL_COPY],
    allOfPermissions: []
  },
  [DomainUIElementEnum.APPLICATION_SHOW_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.APPLICATION_VIEW, PermissionEnum.APPLICATION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.APPLICATION_GENERATE_LABEL]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_LABEL_CREATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.APPLICATION_VERIFY_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_INPUT_DATA_VERIFY]
  },
  [DomainUIElementEnum.APPLICATION_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.APPLICATION_ADMIN],
    allOfPermissions: [PermissionEnum.APPLICATION_VIEW, PermissionEnum.APPLICATION_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_ADD_APPLICATION_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_CREATE]
  },
  [DomainUIElementEnum.APPLICATION_UPLOAD_FILE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_FILE_UPLOAD]
  },
  [DomainUIElementEnum.APPLICATION_DELETE_FILE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_FILE_DELETE]
  },
  [DomainUIElementEnum.APPLICATION_DOWNLOAD_FILE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_FILE_DOWNLOAD]
  },
  [DomainUIElementEnum.APPLICATION_SIGN_FILE_BUTTON]: {
    anyOfPermission: [],
    allOfPermissions: []
  },
  [DomainUIElementEnum.APPLICATION_OUTGOING_DOCUMENTS_UPLOAD_DOCUMENT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_FILE_UPLOAD]
  },
  [DomainUIElementEnum.APPLICATION_OUTGOING_DOCUMENTS_GROUP_SUBMISSION_CONFIRMATION]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_DOCUMENT_SUBMISSION_CONFIRMATION_GENERATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.APPLICATION_OUTGOING_DOCUMENTS_GENERATE_ISSUE_CONFIRMATION_BUTTON]: {
    nestedAnyOfPermissions: [
      [PermissionEnum.APPLICATION_ADMIN, PermissionEnum.DOCUMENT_VIEW, PermissionEnum.DOCUMENT_CREATE],
      [PermissionEnum.APPLICATION_ADMIN, PermissionEnum.DOCUMENT_ADMIN]
    ]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED__DOCUMENTS_GENERATE_CALL_TO_FILL_GAPS_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_REQUEST_FOR_SUPPLEMENT_GENERATE]
  },
  [DomainUIElementEnum.APPLICATION_OUTGOING_DOCUMENTS_GENERATE_SUBMISSION_CONFIRMATION_BUTTON]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_DOCUMENT_SUBMISSION_CONFIRMATION_GENERATE]
  },
  [DomainUIElementEnum.APPLICATION_OUTGOING_DOCUMENTS_CAUTION_GENERATE]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_INSTRUCTION_GENERATE]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_DOCUMENTS_DOWNLOAD_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_FILE_DOWNLOAD]
  },
  [DomainUIElementEnum.APPLICATION_OUTGOING_DOCUMENTS_CANCEL_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_FILE_DELETE]
  },
  [DomainUIElementEnum.DOCUMENTS_ISSUED_DOCUMENTS_FORWARD_TO_SHIPMENT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_SHIPMENT_TRANSFER]
  },
  [DomainUIElementEnum.APPLICATION_OUTGOING_DOCUMENTS_ENTER_DELIVERY_DATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.DOCUMENT_ADMIN],
    allOfPermissions: [PermissionEnum.DOCUMENT_VIEW, PermissionEnum.DOCUMENT_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_LICENSE_TABLE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_LICENSE_LIST_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_ITEM_PERMISSION_EXTERNAL_CREATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_PERMISSION_EXTERNAL_CREATE]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_ITEM_PERMISSION_EXTERNAL_UPDATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_PERMISSION_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_ITEM_PERMISSION_EXTERNAL_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_PERMISSION_DELETE]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_ITEM_PERMISSION_EXTERNAL_SHOW_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_PERMISSION_EXTERNAL_DETAILS_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_PERMISSION_LIST_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_LICENSE_COPY_TABLE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_LICENSE_COPY_LIST_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_DRIVER_CERTIFICATES_TABLE]: {
    anyOfPermission: [],
    allOfPermissions: []
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_FOREIGN_PERMITS_TABLE]: {
    anyOfPermission: [PermissionEnum.PERMISSION_VIEW, PermissionEnum.PERMISSION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_EXCLUSIONS_FROM_PERMIT_FOR_OCCASIONAL_SERVICE_TABLE]: {
    anyOfPermission: [PermissionEnum.PERMISSION_VIEW, PermissionEnum.PERMISSION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.APPLICATION_HISTORY_TABLE]: {
    anyOfPermission: [PermissionEnum.APPLICATION_VIEW, PermissionEnum.APPLICATION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.APPLICATION_HISTORY_DOCUMENT_LINK_DOWNLOAD]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_HISTORY_DOCUMENT_LINK_DOWNLOAD]
  },
  [DomainUIElementEnum.APPLICATION_FINACIAL_SECURITY_VIEW]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_FINANCIAL_SECURITY_LIST_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_FINACIAL_SECURITY_ADD_SECURITY_BUTTON]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_FINANCIAL_SECURITY_CREATE]
  },
  [DomainUIElementEnum.APPLICATION_FINACIAL_SECURITY_SEARCH_SECURITY_BUTTON]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_FINANCIAL_SECURITY_SEARCH]
  },
  [DomainUIElementEnum.APPLICATION_FINACIAL_SECURITY_SUMMARY_SECTION]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_FINANCIAL_SECURITY_SUM]
  },
  [DomainUIElementEnum.APPLICATION_FINACIAL_SECURITY_EDIT_SECURITY_BUTTON]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_FINANCIAL_SECURITY_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_FINACIAL_SECURITY_DELETE_SECURITY_BUTTON]: {
    allOfPermissions: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_FINANCIAL_SECURITY_DELETE]
  },
  [DomainUIElementEnum.APPLICATION_FINACIAL_SECURITY_SHOW_DETAILS_BUTTON]: {
    anyOfPermission: []
  },
  [DomainUIElementEnum.APPLICATION_FINACIAL_SECURITY_EXPIRE_BUTTON]: {
    anyOfPermission: []
  },
  [DomainUIElementEnum.APPLICATION_FINACIAL_SECURITY_RESTORE_BUTTON]: {
    anyOfPermission: []
  },
  [DomainUIElementEnum.APPLICATION_VEHICLES_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_VEHICLE_LIST_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_VEHICLES_ADD_VEHICLE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_VEHICLE_CREATE]
  },
  [DomainUIElementEnum.APPLICATION_S_CERTIFICATE_ADD_VEHICLE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_S_CERTIFICATE_VEHICLE_CREATE]
  },
  [DomainUIElementEnum.APPLICATION_VEHICLES_SEARCH_VEHICLE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_FOLDER_VEHICLE_SEARCH]
  },
  [DomainUIElementEnum.APPLICATION_VEHICLES_DELETE_VEHICLE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_VEHICLE_DELETE]
  },
  [DomainUIElementEnum.APPLICATION_S_CERTIFICATE_DELETE_VEHICLE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_S_CERTIFICATE_CERTIFICATE_DELETE]
  },
  [DomainUIElementEnum.APPLICATION_VEHICLES_EDIT_VEHICLE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_VEHICLE_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_VEHICLES_SHOW_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_VEHICLE_DETAILS_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_VEHICLES_DOWNLOAD_VEHICLES_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_FOLDER_VEHICLE_LIST_IMPORT]
  },
  [DomainUIElementEnum.APPLICATION_VEHICLES_CLEAR_VEHICLES_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_VEHICLE_LIST_CLEAR]
  },
  [DomainUIElementEnum.APPLICATION_VEHICLES_GENERATE_RAPORT_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.REPORT_GENERATE,
      PermissionEnum.REPORT_WL_GENERATE,
      PermissionEnum.REPORT_WL_RAPORT_O_POJAZDACH_GENERATE
    ]
  },
  [DomainUIElementEnum.APPLICATION_VEHICLES_DOWNLOAD_FROM_CEPIK_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_VEHICLE_CEPIK_DATA_IMPORT]
  },
  [DomainUIElementEnum.APPLICATION_VEHICLES_DOWNLOAD_XLSX]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_VEHICLE_LIST_XLSX_EXPORT]
  },
  [DomainUIElementEnum.APPLICATION_GENERAL_DATA_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_BASIC_INFORMATION_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_LAST_SUPPLEMENT_RECEIPT_DATE_INPUT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_LAST_SUPPLEMENT_DATE_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_PROXY_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_PROXY_LIST_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_PROXY_ADD_PROXY_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_PROXY_CREATE]
  },
  [DomainUIElementEnum.APPLICATION_PROXY_EDIT_PROXY_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_PROXY_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_PROXY_DELETE_PROXY_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_PROXY_DELETE]
  },
  [DomainUIElementEnum.APPLICATION_PROXY_SHOW_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_PROXY_DETAILS_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_PRICING_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_PRICING_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_PRICING_CHANGE_DATA_FROM_PRICING_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_PRICING_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_PRICING_SEARCH_TRANSFER_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_MONEY_TRANSFER_SEARCH]
  },
  [DomainUIElementEnum.APPLICATION_PRICING_EDIT_TRANSFER_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_PRICING_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_PRICING_DELETE_TRANSFER_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_MONEY_TRANSFER_DELETE]
  },
  [DomainUIElementEnum.APPLICATION_PRICING_LINK_TRANSFERS_WITH_APPLICATION_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_MONEY_TRANSFER_BIND]
  },
  [DomainUIElementEnum.APPLICATION_PRICING_UNLINK_TRANSFERS_WITH_APPLICATION_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_MONEY_TRANSFER_UNBIND]
  },
  [DomainUIElementEnum.APPLICATION_PRICING_GENERATE_RELEASE_DOCUMENT_BUTTON]: {
    nestedAnyOfPermissions: [
      [PermissionEnum.MONEY_TRANSFER_ADMIN, PermissionEnum.APPLICATION_ADMIN, PermissionEnum.DOCUMENT_CREATE],
      [PermissionEnum.MONEY_TRANSFER_ADMIN, PermissionEnum.APPLICATION_ADMIN, PermissionEnum.DOCUMENT_CREATE],
      [PermissionEnum.MONEY_TRANSFER_ADMIN, PermissionEnum.APPLICATION_ADMIN, PermissionEnum.DOCUMENT_ADMIN]
    ]
  },
  [DomainUIElementEnum.APPLICATION_ATTACHMENTS_TABLE]: {
    anyOfPermission: [PermissionEnum.APPLICATION_VIEW, PermissionEnum.APPLICATION_ADMIN]
  },
  [DomainUIElementEnum.APPLICATION_ATTACHMENTS_EDIT_NOTE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_VERIFICATION_ELEMENT_NOTE_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_ATTACHMENTS_EDIT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_VERIFICATION_ELEMENT_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_EXPLOITATION_BASE_LIST_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_ADD_BASE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_EXPLOITATION_BASE_CREATE]
  },
  [DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_SEARCH_BASES_BUTTON]: {},
  [DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_SHOW_GEOPORTAL_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_EXPLOITATION_BASE_GEOPORTAL_LOCATION_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_DELETE_BASE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_EXPLOITATION_BASE_DELETE]
  },
  [DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_EDIT_BASE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_EXPLOITATION_BASE_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_SHOW_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_EXPLOITATION_BASE_DETAILS_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_CHECK_BASE_CONTROL_BUTTON]: {
    anyOfPermission: [PermissionEnum.EXPLOITATION_BASE_VIEW, PermissionEnum.EXPLOITATION_BASE_ADMIN]
  },
  [DomainUIElementEnum.APPLICATION_TRANSPORT_MANAGERS_ADD_MANAGER_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_TRANSPORT_MANAGER_CREATE]
  },
  [DomainUIElementEnum.APPLICATION_TRANSPORT_MANAGERS_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_TRANSPORT_MANAGER_LIST_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_TRANSPORT_MANAGERS_TABLE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_TRANSPORT_MANAGER_LIST_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_TRANSPORT_MANAGERS_SEARCH_MANAGER_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_TRANSPORT_MANAGER_SEARCH]
  },
  [DomainUIElementEnum.APPLICATION_TRANSPORT_MANAGERS_DELETE_MANAGER_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_TRANSPORT_MANAGER_DELETE]
  },
  [DomainUIElementEnum.APPLICATION_TRANSPORT_MANAGERS_EDIT_MANAGER_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_TRANSPORT_MANAGER_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_TRANSPORT_MANAGERS_SHOW_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_TRANSPORT_MANAGER_DETAILS_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_TRANSPORT_MANAGERS_CHECK_IN_KREPTD_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_TRANSPORT_MANAGER_KREPTD_REPUTATION_IMPORT]
  },
  [DomainUIElementEnum.APPLICATION_TRANSPORT_MANAGERS_SHOW_REPUTATION_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_KREPTD_REPUTATION_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_TRANSPORT_MANAGERS_ADD_TO_APPLICATION_BUTTON]: {},
  [DomainUIElementEnum.APPLICATION_ADDRESSES_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_ADDRESS_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_ADDRESSES_UPDATE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_ADDRESS_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_SUBJECT_ENTREPRENEURS_TABLE]: {
    anyOfPermission: [PermissionEnum.SUBJECT_VIEW, PermissionEnum.SUBJECT_ADMIN]
  },
  [DomainUIElementEnum.APPLICATION_S_CERTIFICATES_ADD_VEHICLE_FROM_FOLDER_TO_APPLCIATION_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_S_CERTIFICATE_CERTIFICATE_CREATE]
  },
  [DomainUIElementEnum.APPLICATION_S_CERTIFICATES_SHOW_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_S_CERTIFICATE_CERTIFICATE_DETAILS_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_S_CERTIFICATES_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_S_CERTIFICATE_VEHICLE_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_DRIVERS_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_DRIVER_LIST_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_DRIVERS_ADD_DRIVER_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_DRIVER_CREATE]
  },
  [DomainUIElementEnum.APPLICATION_DRIVERS_SEARCH_DRIVER_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_DRIVER_SEARCH]
  },
  [DomainUIElementEnum.APPLICATION_DRIVERS_DELETE_DRIVER_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_DRIVER_DELETE]
  },
  [DomainUIElementEnum.APPLICATION_DRIVERS_EDIT_DRIVER_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_DRIVER_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_DRIVERS_SHOW_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_DRIVER_DETAILS_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_GITD_TABLE]: {
    anyOfPermission: [PermissionEnum.PERMISSION_VIEW, PermissionEnum.PERMISSION_ADMIN]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_PERMISSION_TABLE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_PERMISSION_COPY_LIST_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_PERMITS_FOR_REGULAR_SERVICE_FROM_UE_TABLE]: {
    anyOfPermission: [PermissionEnum.PERMISSION_VIEW, PermissionEnum.PERMISSION_ADMIN]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_PERMITS_FOR_REGULAR_SERVICE_OUTSIDE_UE_TABLE]: {
    anyOfPermission: [PermissionEnum.PERMISSION_VIEW, PermissionEnum.PERMISSION_ADMIN]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_EXTRACTS_FOR_REGULAR_SERVICE_OUTSIDE_UE_TABLE]: {
    anyOfPermission: [PermissionEnum.PERMISSION_VIEW, PermissionEnum.PERMISSION_ADMIN]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_COUNTRY_TABLE]: {
    anyOfPermission: [PermissionEnum.PERMISSION_VIEW, PermissionEnum.PERMISSION_ADMIN]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_EXTRACTS_FOR_REGULAR_SERVICE_WITNIN_UE_TABLE]: {
    anyOfPermission: [PermissionEnum.PERMISSION_VIEW, PermissionEnum.PERMISSION_ADMIN]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_SHUTTLE_PERMITS_TABLE]: {
    anyOfPermission: [PermissionEnum.PERMISSION_VIEW, PermissionEnum.PERMISSION_ADMIN]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_EXTRACTS_FROM_PERMIT_FOR_SHUTTLE_SERVICE_TABLE]: {
    anyOfPermission: [PermissionEnum.PERMISSION_VIEW, PermissionEnum.PERMISSION_ADMIN]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_PERMITS_FOR_OCCASIONAL_SERVICE_TABLE]: {
    anyOfPermission: [PermissionEnum.PERMISSION_VIEW, PermissionEnum.PERMISSION_ADMIN]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_PERMITS_FOR_RREGULAR_TRANSIT_IN_POLAND_TABLE]: {
    anyOfPermission: [PermissionEnum.PERMISSION_VIEW, PermissionEnum.PERMISSION_ADMIN]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_EXTRACTS_FROM_PERMIT_FOR_RREGULAR_TRANSIT_IN_POLAND_TABLE]: {
    anyOfPermission: [PermissionEnum.PERMISSION_VIEW, PermissionEnum.PERMISSION_ADMIN]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_CERTIFICATES_FOR_OWN_ACCOUNT_TRANSPORT_TABLE]: {
    anyOfPermission: [PermissionEnum.PERMISSION_VIEW, PermissionEnum.PERMISSION_ADMIN]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_EXTRACTS_TO_CERTIFICATE_FOR_OWN_ACCOUNT_TRANSPORT_TABLE]: {
    anyOfPermission: [PermissionEnum.PERMISSION_VIEW, PermissionEnum.PERMISSION_ADMIN]
  },
  [DomainUIElementEnum.APPLICATION_PERMISSIONS_FOREIGN_PERMISSIONS_TABLE]: {
    anyOfPermission: [PermissionEnum.PERMISSION_VIEW, PermissionEnum.PERMISSION_ADMIN]
  },
  [DomainUIElementEnum.APPLICATION_SCHEDULE_NEW_SCHEDULE_BUTTON]: {
    anyOfPermission: [PermissionEnum.TRANSIT_SCHEDULE_ADMIN],
    allOfPermissions: [PermissionEnum.TRANSIT_SCHEDULE_VIEW, PermissionEnum.TRANSIT_SCHEDULE_CREATE]
  },
  [DomainUIElementEnum.APPLICATION_SCHEDULE_MOVE_TO_UP_BUTTON]: {
    anyOfPermission: [PermissionEnum.TRANSIT_SCHEDULE_ADMIN],
    allOfPermissions: [PermissionEnum.TRANSIT_SCHEDULE_VIEW, PermissionEnum.TRANSIT_SCHEDULE_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_SCHEDULE_MOVE_DOWN_BUTTON]: {
    anyOfPermission: [PermissionEnum.TRANSIT_SCHEDULE_ADMIN],
    allOfPermissions: [PermissionEnum.TRANSIT_SCHEDULE_VIEW, PermissionEnum.TRANSIT_SCHEDULE_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_SCHEDULE_DELETE_SCHEDULE_BUTTON]: {
    anyOfPermission: [PermissionEnum.PERMISSION_ADMIN]
  },
  [DomainUIElementEnum.APPLICATION_SCHEDULE_SAVE_SCHEDULE_BUTTON]: {
    anyOfPermission: [PermissionEnum.TRANSIT_SCHEDULE_ADMIN],
    allOfPermissions: [PermissionEnum.TRANSIT_SCHEDULE_VIEW, PermissionEnum.TRANSIT_SCHEDULE_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_SCHEDULE_EDIT_SCHEDULE_BUTTON]: {
    anyOfPermission: [PermissionEnum.TRANSIT_SCHEDULE_ADMIN],
    allOfPermissions: [PermissionEnum.TRANSIT_SCHEDULE_VIEW, PermissionEnum.TRANSIT_SCHEDULE_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_SCHEDULE_CLEAR_FREQUENCY_BUTTON]: {
    anyOfPermission: [PermissionEnum.TRANSIT_SCHEDULE_ADMIN],
    allOfPermissions: [PermissionEnum.TRANSIT_SCHEDULE_VIEW, PermissionEnum.TRANSIT_SCHEDULE_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_PAYMENT_ADD_PAYMENT_BUTTON]: {
    anyOfPermission: [PermissionEnum.PAYMENT_ADMIN],
    allOfPermissions: [PermissionEnum.PAYMENT_VIEW, PermissionEnum.PAYMENT_CREATE]
  },
  [DomainUIElementEnum.APPLICATION_PAYMENT_EDIT_PAYMENT_BUTTON]: {
    anyOfPermission: [PermissionEnum.PAYMENT_ADMIN],
    allOfPermissions: [PermissionEnum.PAYMENT_VIEW, PermissionEnum.PAYMENT_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_FOREIGN_PERMISSIONS_SINGLE_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_FOREIGN_PERMISSION_SINGLE_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_FOREIGN_PERMISSIONS_SINGLE_ADD_NEW_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_FOREIGN_PERMISSION_SINGLE_CREATE]
  },
  [DomainUIElementEnum.APPLICATION_FOREIGN_PERMISSIONS_SINGLE_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_FOREIGN_PERMISSION_SINGLE_DELETE]
  },
  [DomainUIElementEnum.APPLICATION_FOREIGN_PERMISSIONS_SINGLE_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_FOREIGN_PERMISSION_SINGLE_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_DOWNLOAD_REGON_ACCEPT_DATA_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_REGON_DATA_ACCEPT]
  },
  [DomainUIElementEnum.APPLICATION_DOWNLOAD_REGON_ACCEPT_ADDRESS_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_ADDRESS_REGON_DATA_ACCEPT]
  },
  [DomainUIElementEnum.APPLICATION_DOWNLOAD_REGON_DATA_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_REGON_DATA_IMPORT]
  },
  [DomainUIElementEnum.APPLICATION_SUBJECT_KREPTD_REPUTATION_IMPORT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_KREPTD_REPUTATION_IMPORT]
  },
  [DomainUIElementEnum.APPLICATION_SUBJECT_REPUTATION_SECTION]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_KREPTD_REPUTATION_VIEW]
  },
  [DomainUIElementEnum.FOLDER_SUBJECT_REPUTATION_SECTION]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_KREPTD_REPUTATION_VIEW]
  },
  [DomainUIElementEnum.AUTHORIZATION_VERIFICATION_MENU]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_SUSPENSION_LIST_VIEW, PermissionEnum.IBTM_DOMAIN_PROCEEDING_LIST_VIEW]
  },
  [DomainUIElementEnum.FOREIGN_PERMITS_LIST_TAB]: {
    nestedAnyOfPermissions: [
      [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_LIST_VIEW],
      [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_LIST_VIEW],
      [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_LIST_VIEW],
      [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_LIST_VIEW],
      [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_BASIC_LIMIT_LIST_VIEW],
      [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_FREE_DISPOSAL_RULE_LIST_VIEW],
      [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_LIST_VIEW],
      [PermissionEnum.IBTM_DOMAIN_FOLDER_FOREIGN_PERMISSION_SINGLE_VIEW],
      [PermissionEnum.IBTM_DOMAIN_FOLDER_FOREIGN_PERMISSION_EKMT_VIEW],
      [PermissionEnum.IBTM_DOMAIN_FOLDER_FOREIGN_PERMISSION_SINGLE_VIEW],
      [PermissionEnum.IBTM_DOMAIN_FOLDER_FOREIGN_PERMISSION_EKMT_VIEW]
    ]
  },
  [DomainUIElementEnum.RELEASED_PERMITS_LIST_TAB]: {
    anyOfPermission: [
      PermissionEnum.IBTM_DOMAIN_FOLDER_FOREIGN_PERMISSION_SINGLE_VIEW,
      PermissionEnum.IBTM_DOMAIN_FOLDER_FOREIGN_PERMISSION_EKMT_VIEW
    ]
  },
  [DomainUIElementEnum.LIMITS_LIST_TAB]: {
    anyOfPermission: [
      PermissionEnum.IBTM_DOMAIN_FOLDER_FOREIGN_PERMISSION_SINGLE_VIEW,
      PermissionEnum.IBTM_DOMAIN_FOLDER_FOREIGN_PERMISSION_EKMT_VIEW
    ],
    allOfPermissions: []
  },
  [DomainUIElementEnum.DISPOSABLE_PERMITS_LIST_TAB]: {
    nestedAnyOfPermissions: [
      [PermissionEnum.FOREIGN_PERMISSION_VIEW, PermissionEnum.IBTM_DOMAIN_FOLDER_LIST_VIEW],
      [PermissionEnum.FOREIGN_PERMISSION_ADMIN, PermissionEnum.IBTM_DOMAIN_FOLDER_LIST_VIEW]
    ]
  },
  [DomainUIElementEnum.EKMT_LIST_TAB]: {
    anyOfPermission: [PermissionEnum.FOREIGN_PERMISSION_VIEW, PermissionEnum.FOREIGN_PERMISSION_ADMIN],
    allOfPermissions: []
  },
  [DomainUIElementEnum.SOCIAL_COMMISION_LIST_TAB]: {
    anyOfPermission: [
      PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_LIST_VIEW,
      PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_LIST_VIEW,
      PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_BASIC_LIMIT_LIST_VIEW,
      PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_FREE_DISPOSAL_RULE_LIST_VIEW,
      PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_LIST_VIEW
    ]
  },
  [DomainUIElementEnum.APPLICATION_FEES_LIST_TAB]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_PRICE_LIST_LAST_IMPORT_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_FEES_EXPORT_FILE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_PRICE_LIST_EXPORT]
  },
  [DomainUIElementEnum.APPLICATION_FEES_IMPORT_FILE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_PRICE_LIST_IMPORT]
  },
  [DomainUIElementEnum.AUDIT_LOG_LIST_TAB]: {
    anyOfPermission: [PermissionEnum.AUDIT_VIEW]
  },
  [DomainUIElementEnum.PROCESSES_LIST_TAB]: {
    anyOfPermission: [PermissionEnum.MISCELLANEOUS_FLOWABLE_UI_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_SCHEDULE_SHOW_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.TRANSIT_SCHEDULE_VIEW, PermissionEnum.TRANSIT_SCHEDULE_ADMIN]
  },
  [DomainUIElementEnum.APPLICATION_TRANSIT_SCHEDULE_FREQUENCY]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_TRANSIT_FREQUENCY_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_TRANSIT_DATE_UPDATE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_TRANSIT_DATE_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_TRANSIT_SCHEDULE_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_TRANSIT_SCHEDULE_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_SCHEDULE_EDIT_STOP_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_TRANSIT_STOP_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_SCHEDULE_DELETE_STOP_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_TRANSIT_STOP_DELETE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.APPLICATION_SCHEDULE_ADD_STOP_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_TRANSIT_STOP_CREATE],
    allOfPermissions: []
  },
  [DomainUIElementEnum.APPLICATION_SCHEDULE_STOP_ADD_HOURS]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_TRANSIT_STOP_ADD_HOURS]
  },
  [DomainUIElementEnum.NOTIFICATION_SHORT_LIST]: {
    anyOfPermission: []
  },
  [DomainUIElementEnum.NOTIFICATION_FULL_LIST]: {
    anyOfPermission: []
  },
  [DomainUIElementEnum.FOLDER_TP_CREATE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_TP_CREATE]
  },
  [DomainUIElementEnum.FOLDER_OP_CREATE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_OP_CREATE]
  },
  [DomainUIElementEnum.FOLDER_PPO_CREATE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_PPO_CREATE]
  },
  [DomainUIElementEnum.FOLDER_MZ_CREATE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_MZ_CREATE]
  },
  [DomainUIElementEnum.FOLDER_PA_CREATE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_PA_CREATE]
  },
  [DomainUIElementEnum.FOLDER_KS_CREATE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_KS_CREATE]
  },
  [DomainUIElementEnum.FOLDER_WITD_CREATE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_WITD_CREATE]
  },
  [DomainUIElementEnum.FOLDER_ZZ_CREATE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_ZZ_CREATE]
  },
  [DomainUIElementEnum.APPLICATION_CABOTAGE_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_CABOTAGE_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_CABOTAGE_EDIT_NOTE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_CABOTAGE_NOTE_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_CABOTAGE_EDIT_COUNTRY]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_CABOTAGE_COUNTRY_UPDATE]
  },
  [DomainUIElementEnum.FOLDER_ADD_ENTREPRENEURS]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_CIVIL_PARTNERSHIP_PARTNER_ADD]
  },
  [DomainUIElementEnum.FOLDER_CIVIL_PARTNERSHIP]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_CIVIL_PARTNERSHIP_CREATE]
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_LIST_ACTIVE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_PERMISSION_LIST_ACTIVE]
  },
  [DomainUIElementEnum.FOLDER_PERMISSION_LIST_UNACTIVE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_FOLDER_PERMISSION_LIST_UNACTIVE]
  },
  [DomainUIElementEnum.APPLICATION_DETAILS_GENERAL_DATA_LINKED_APPLICATIONS_INPUT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_LINKED_APPLICATION_ADD]
  },
  [DomainUIElementEnum.APPLICATION_DETAILS_GENERAL_DATA_LINKED_APPLICATIONS_OPEN_LINKED_APPLICATIONS]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_LINKED_APPLICATION_OPEN]
  },
  [DomainUIElementEnum.APPLICATION_PARTNER_APPLICANT_SUBJECT_LIST_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_PARTNER_APPLICANT_SUBJECT_LIST_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_ITEM_PARTNER_APPLICANT_SUBJECT_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_PARTNER_APPLICANT_SUBJECT_DELETE]
  },
  [DomainUIElementEnum.APPLICATION_ITEM_PARTNER_APPLICANT_SUBJECT_UPDATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_PARTNER_APPLICANT_SUBJECT_UPDATE]
  },
  [DomainUIElementEnum.APPLICATION_ITEM_PARTNER_APPLICANT_SUBJECT_CREATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_PARTNER_APPLICANT_SUBJECT_CREATE]
  },
  [DomainUIElementEnum.APPLICATION_VERIFICATION_ELEMENT_LIST_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_VERIFICATION_ELEMENT_LIST_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_HISTORY_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_HISTORY_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_DOCUMENT_LIST_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_DOCUMENT_LIST_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_NOTE_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_NOTE_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_FILE_LIST_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_FILE_LIST_VIEW]
  },
  [DomainUIElementEnum.APPLLICATION_SUBJECT_VIEW]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_VIEW]
  },
  [DomainUIElementEnum.APPLICATION_CREATE_BILLING_NOTE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_PAYMENT_PRINT]
  },
  [DomainUIElementEnum.REPORT_GENERATE_CASES_LIST_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.REPORT_GENERATE,
      PermissionEnum.REPORT_INNE_GENERATE,
      PermissionEnum.REPORT_INNE_SPIS_SPRAW_GENERATE
    ]
  },
  [DomainUIElementEnum.ADMINISTRATION_VIEW]: {
    nestedAnyOfPermissions: [
      [PermissionEnum.ORGANIZATION_STRUCTURE_VIEW],
      [PermissionEnum.GROUP_VIEW],
      [PermissionEnum.DICTIONARY_VIEW],
      [PermissionEnum.IBTM_DOMAIN_DOCUMENT_TEMPLATE_VIEW],
      [PermissionEnum.ALERT_VIEW],
      [PermissionEnum.MESSAGE_VIEW],
      [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_PRICE_LIST_LAST_IMPORT_VIEW],
      [PermissionEnum.IBTM_DOMAIN_SYSTEM_PARAMETER_LIST_VIEW],
      [PermissionEnum.AUDIT_VIEW],
      [PermissionEnum.MISCELLANEOUS_FLOWABLE_UI_VIEW],
      [PermissionEnum.USER_ADMIN, PermissionEnum.ACCOUNT_OPERATOR_ADMIN],
      [PermissionEnum.USER_ADMIN, PermissionEnum.USER_VIEW, PermissionEnum.IBTM_DOMAIN_CLIENT_PORTAL_USER_LIST_VIEW]
    ]
  },
  [DomainUIElementEnum.ADMIN_MESSAGE_USERS_SELECT]: {
    anyOfPermission: [PermissionEnum.USER_VIEW]
  },
  [DomainUIElementEnum.ADMIN_MESSAGE_GROUPS_SELECT]: {
    anyOfPermission: [PermissionEnum.GROUP_VIEW]
  }
};

export default DomainPermissionElementMapper;
