import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DocumentTemplateDetailsExternal } from '@avispon/document-generator';
import { useSnackbar } from '@enigma/fe-ui';

import { FormMode, FormV2Context } from '@libs/common/v2';

import { useEditDocumentTemplateMutation } from '@libs/document-template';
import { UploadButton } from '@libs/file';

interface IProps {
  documentTemplate: DocumentTemplateDetailsExternal;
  isDisabled: boolean;
}

const InitialFormValues = {
  templateFile: null
};

export function UploadTemplateButton({ documentTemplate, isDisabled }: IProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: modifyDocumentTemplate, isLoading } = useEditDocumentTemplateMutation({
    onSuccess: () => {},
    onError: () => {}
  });

  const form = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: InitialFormValues
  });

  const formDataProvider = useMemo(() => {
    return { ...form, formMode: FormMode.CREATE };
  }, [form]);

  const submitUpdateFile = values => {
    modifyDocumentTemplate(
      {
        documentTemplateId: documentTemplate.id,
        body: {
          name: documentTemplate.name,
          description: documentTemplate.description,
          targetMimeType: documentTemplate.targetMimeType,
          version: documentTemplate.version,
          typeKey: documentTemplate.typeKey,
          ...values
        }
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('documentTemplates:message.newFileUploadSuccess'));
        }
      }
    );
  };

  return (
    <FormV2Context.Provider value={formDataProvider}>
      <UploadButton
        isLoading={isLoading}
        isDisabled={isDisabled}
        onSuccessLoad={form.handleSubmit(submitUpdateFile)}
        name="templateFile"
        label={t('reports:reportTypes.action.updateTemplate')}
        variant="outlined"
      />
    </FormV2Context.Provider>
  );
}
