import { useTranslation } from 'react-i18next';

import { ColumnTypesEnum, useCreateColumns } from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { FolderLockListItemClient } from '@libs/domain/folder';

function useLockColumns() {
  const [t] = useTranslation();
  const { createColumns } = useCreateColumns<FolderLockListItemClient>({
    pathToTranslate: 'folder:details.tab.locks.field'
  });

  const columns = createColumns([
    {
      accessor: 'typeKey',
      type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
      dictionaryName: DomainDictionaryEnum.FOLDER_LOCK_TYPE,
      isSortable: false
    },
    {
      accessor: 'created',
      type: 'DATE',
      isSortable: false
    },
    {
      accessor: 'validTo',
      type: 'DATE',
      isSortable: false
    },
    {
      header: 'author',
      accessor: 'author.name',
      type: 'TEXT',
      isSortable: false
    },
    {
      accessor: 'notes',
      type: 'TEXT',
      isSortable: false
    },
    {
      accessor: 'unlocked',
      type: 'DATE',
      isSortable: false
    },
    {
      type: 'TEXT',
      header: 'unlocker',
      accessor: 'unlocker.name',
      isSortable: false
    },
    {
      accessor: 'isLocked',
      type: 'BOOLEAN',
      tooltipTitle: ({ isLocked }) => (isLocked ? t('field.active') : t('field.inactive')),
      customAccessor: ({ isLocked }) => (isLocked ? t('field.active') : t('field.inactive')),
      isSortable: false
    }
  ]);

  return { columns };
}

export default useLockColumns;
