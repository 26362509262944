import { AssociationSubjectSnapshot as ClientAssociationSubjectSnapshot } from '@ibtm/client-domain';
import { AssociationSubjectSearchFilter, AssociationSubjectSnapshot } from '@ibtm/domain';

import { CamelCasePath, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO, getCalendarDate } from '@libs/common/v2/utils';

import { UserFilterV2 } from '@libs/user';

export const useAssociationSubjectColumns = (
  visibleColumns: Array<CamelCasePath<AssociationSubjectSnapshot | ClientAssociationSubjectSnapshot>>
) => {
  const { createColumns } = useCreateColumns<AssociationSubjectSnapshot | ClientAssociationSubjectSnapshot>({
    pathToTranslate: 'associations:field'
  });

  const mappedSortFields: TableSortMapper<AssociationSubjectSnapshot> = {
    subjectName: 'folder.subject.name',
    subjectNip: 'folder.subject.nip',
    subjectRegon: 'folder.subject.regon',
    subjectContactEmail: 'folder.subject.contactAddresses.contactAddress.email',
    subjectContactPhoneNumber: 'folder.subject.contactAddresses.contactAddress.phoneNumber'
  };

  const mappedFilterFields: TableFilterMapper<AssociationSubjectSnapshot, AssociationSubjectSearchFilter> = {
    subjectName: (subjectName: string) => ({ subjectNameContains: subjectName }),
    joinDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      joinDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      joinDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    authorName: (authors: Array<{ value: string }>) => ({ authorIdIn: authors.map(({ value }) => value) }),
    folders: (folderNumber: string) => ({ folderNumberContains: folderNumber }),
    subjectNip: (nip: string) => ({ nipContains: nip }),
    subjectRegon: (regon: string) => ({ subjectRegonContains: regon }),
    primaryAddress: (address: string) => ({ addressContains: address }),
    subjectContactEmail: (email: string) => ({ emailContains: email }),
    subjectContactPhoneNumber: (phoneNumber: string) => ({ phoneNumberContains: phoneNumber })
  };

  const columns = createColumns(
    [
      {
        header: 'name',
        type: 'TEXT',
        accessor: 'subject.name'
      },
      {
        header: 'created',
        type: 'DATE',
        accessor: 'joinDate',
        isSortable: false
      },
      {
        header: 'creator',
        type: 'CUSTOM_COLUMN',
        accessor: 'author.name',
        filter: UserFilterV2,
        isSortable: false
      },
      {
        header: 'folderIds',
        type: 'ARRAY_COLUMN',
        accessor: 'folders',
        isSortable: false
      },
      {
        header: 'nip',
        type: 'TEXT',
        accessor: 'subject.nip'
      },
      {
        header: 'regon',
        type: 'TEXT',
        accessor: 'subject.regon'
      },
      {
        header: 'adress',
        accessor: 'primaryAddress.city',
        type: 'TEXT'
      },
      {
        header: 'email',
        type: 'TEXT',
        accessor: 'subject.contact.email'
      },
      {
        header: 'phoneNumber',
        type: 'TEXT',
        accessor: 'subject.contact.phoneNumber'
      }
    ],
    visibleColumns
  );

  return { columns, mappedSortFields, mappedFilterFields };
};

export const DEFAULT_COLUMNS: Array<CamelCasePath<AssociationSubjectSnapshot>> = [
  'joinDate',
  'subjectName',
  'authorName',
  'folders',
  'subjectNip',
  'subjectRegon',
  'primaryAddress',
  'subjectContactEmail',
  'subjectContactPhoneNumber'
];
