import { useDialog } from '@libs/common/v2';

import PostponeDialog from '@libs/domain/suspensions/components/details/tabs/formOperations/dialog/PostponeDialog';

function useFormOperationsDialog(suspensionId: string, refreshTable?: () => void) {
  const { openDialog } = useDialog();

  const postponeConfirm = (dateFrom: string, dateTo: string, version: number) => {
    openDialog(({ closeDialog }) => (
      <PostponeDialog
        suspensionId={suspensionId}
        dateFrom={dateFrom}
        dateTo={dateTo}
        version={version}
        refresh={refreshTable}
        onConfirm={() => {
          closeDialog();
        }}
        onCancel={() => {
          closeDialog();
        }}
      />
    ));
  };

  return { postponeConfirm };
}

export default useFormOperationsDialog;
