import { useTranslation } from 'react-i18next';
import { PaginatedQueryResult, QueryResult } from 'react-query';

import {
  ButtonsGroup,
  IPaginatedModel,
  ITableAdapter,
  TableWithoutHook,
  useRouter,
  useTableRowActions
} from '@libs/common/v2';

import { API } from '@libs/domain/api';
import {
  DomainDictionaryEntry,
  DomainUIElementEnum,
  FolderElementsPermissionsEnum,
  useFolderElementsVisibility
} from '@libs/domain/config';

import { useBorrowFoldersColumns } from '../hooks';
import { BorrowFolderDialogTypeEnum, BorrowFolderSnapshot } from '../model';

import { useBorrowFoldersContext } from './common/BorrowFolderProvider';
import { BorrowFolderHeaderActions } from '.';

interface BorrowFolderTable {
  table: ITableAdapter<any>;
  queryResult: PaginatedQueryResult<IPaginatedModel<any>> | QueryResult<any[], unknown>;
  loadingInfo: {
    isLoading: boolean;
  };
}

function BorrowFoldersTable({ table, queryResult, loadingInfo }: BorrowFolderTable) {
  const [t] = useTranslation();

  const { checkFolderElementVisibility } = useFolderElementsVisibility();

  const { onOpen } = useBorrowFoldersContext();
  const { routes } = useRouter();
  const { mappedFilterFields, mappedSortFields } = useBorrowFoldersColumns();

  const { renderTableActions } = useTableRowActions<BorrowFolderSnapshot>([
    {
      id: 'FOLDER_RENTAL_VIEW',
      label: 'action.openDetails',
      link: ({ original }) => routes.folderDetails(original.folder.id),
      icon: 'ArrowIcon',
      isHidden: ({ row }) =>
        !checkFolderElementVisibility({
          folderTypeKey: row.original.typeKey,
          permisson: FolderElementsPermissionsEnum.FOLDER_VIEW_PERMISSIONS
        })
    },
    {
      id: 'ARCHIVE_BORROWED_RETURN_BUTTON',
      label: 'archive:borrowFolder.action.returnFolder',
      icon: 'ReturnIcon',
      isHidden: ({ row: { original } }) => original?.folder?.statusKey !== DomainDictionaryEntry.FOLDER_STATUS.BORROWED,
      onClick: ({ row: { original }, tableContext: { refetch } }) =>
        onOpen(BorrowFolderDialogTypeEnum.RETURN_FOLDER, original, refetch)
    },
    {
      id: 'ARCHIVE_BORROWED_CHANGE_BORROWER_BUTTON',
      label: 'archive:borrowFolder.action.changeBorrower',
      icon: 'ManageAccountsOutlined',
      isHidden: ({ row: { original } }) => !!original.returnDate,
      onClick: ({ row: { original }, tableContext: { refetch } }) =>
        onOpen(BorrowFolderDialogTypeEnum.CHANGE_BORROWER, original, refetch)
    }
  ]);

  return (
    <TableWithoutHook
      {...loadingInfo}
      table={table}
      xlsxDownload={{
        apiRequest: API.borrowFolders.getFolderRentalDetailsPage,
        fileName: t('archive:borrowFolder.title'),
        pathToXLSXTranslation: 'archive:borrowFolder.field',
        filterConverter: mappedFilterFields,
        sortByConverter: mappedSortFields
      }}
      pageTitle={t('archive:borrowFolder.title')}
      queryResult={queryResult}
      setTableQueryParams={null}
      headerActions={
        <ButtonsGroup>
          <BorrowFolderHeaderActions />
        </ButtonsGroup>
      }
      rowActions={renderTableActions([
        DomainUIElementEnum.FOLDER_RENTAL_VIEW,
        DomainUIElementEnum.ARCHIVE_BORROWED_RETURN_BUTTON,
        DomainUIElementEnum.ARCHIVE_BORROWED_CHANGE_BORROWER_BUTTON
      ])}
    />
  );
}

export default BorrowFoldersTable;
