import {
  DocumentSnapshot,
  FolderToTransferDetails,
  ProceedingKreptdCertificateDetails,
  ProceedingKreptdDetails,
  ProceedingKreptdSnapshot,
  ProceedingsKreptdApiUpdateProceedingKreptdRequest,
  ViolationSnapshot
} from '@ibtm/domain';
import { CalendarDate } from 'calendar-date';
import { omit } from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { ALTERNATIVE_DATE_FORMAT } from '@libs/common/v2';
import { convertCalendarDate, convertDateToStringWithoutTimezone, getCalendarDate } from '@libs/common/v2/utils';

import {
  CertificatesClient,
  CertificatesServer,
  IFoldersToTransferClient,
  IProceedingClient,
  IProceedingDetailsClient,
  IProceedingDocumentClient,
  IViolationClient
} from '../models';

export const parseViolationsListResults = (data: ViolationSnapshot[]): IViolationClient[] => {
  return data.map(parseViolationItem);
};

const parseViolationItem = (item: ViolationSnapshot): IViolationClient => {
  return {
    id: item.id,
    referenceNumber: item.number,
    caseNumber: item.caseNumber,
    transferDate: convertCalendarDate(item.transferDate),
    transportManagerCertificateNumber: item.transportManagerCertificateNumber,
    subjectName: item.subject?.name,
    nip: item.nip,
    code: item.code,
    status: item.statusKey,
    assignedTo: {
      id: item.assignedTo?.id,
      name: item.assignedTo?.name
    },
    manager: item.transportManagerName,
    pendingProceeding: item.pendingProceeding,
    rwaCaseNumber: item.rwaCaseNumber,
    folderId: item.folder?.id,
    proceedingsKreptd: item.proceedingsKreptd
  };
};

export const parseProceedingsListResults = (data: ProceedingKreptdSnapshot[]): IProceedingClient[] =>
  data.map(item => parseProceedingItem(item));

const parseProceedingItem = (item: ProceedingKreptdSnapshot): IProceedingClient => {
  return {
    id: item.id,
    subjectName: item.subjectName,
    subjectNip: item.subjectNip,
    transportManagerName: item.transportManagerName,
    transportManagerCertificateNumber: item.transportManagerCertificateNumber,
    assignedName: item.assignedName,
    caseNumber: item.caseNumber,
    category: item.categoryKey,
    status: item.statusKey,
    statusTransitions: item.statusTransitions,
    version: item.version
  };
};

export const parseFoldersToTransferListResults = (data: FolderToTransferDetails[]): IFoldersToTransferClient[] => {
  return data.map(parseFoldersToTransferItem);
};

const parseFoldersToTransferItem = (item: FolderToTransferDetails): IFoldersToTransferClient => {
  return {
    id: item.id,
    folderNumber: item.folderNumber,
    subjectName: item.subject?.name,
    subjectNip: item.subject?.nip,
    status: item.statusKey,
    transferredToSta: item.transferredToSta,
    transferDate: convertCalendarDate(item.transferDate),
    referentName: item.referent?.name,
    referentId: item.referent?.id
  };
};

export const parseDetailsResults = (data: ProceedingKreptdDetails): IProceedingDetailsClient => ({
  id: data?.id,
  caseNumber: data?.caseNumber,
  assignedName: data?.assignedName,
  status: data?.statusKey,
  subjectNip: data?.nip,
  subjectName: data?.subjectName,
  transportManagerName: data?.transportManagerName,
  transportManagerCertificateNumberFromViolation: data?.transportManagerCertificateNumberFromViolation,
  transportManagerNameFromViolation: data?.transportManagerNameFromViolation,
  transportManagerSurnameFromViolation: data?.transportManagerSurnameFromViolation,
  transportManagerCertificateNumber: data?.transportManagerCertificateNumber,
  transportManagerId: data?.transportManagerId,
  fineImposed: data?.fine?.imposed || false,
  fineImposedAvailable: data?.fineImposedAvailable,
  category: data?.categoryKey,
  folderId: data?.folderId,
  version: data?.version,
  startDate: convertCalendarDate(data?.start?.date),
  startDecisionDeliveryDate: convertCalendarDate(data?.start?.decisionDeliveryDate),
  startStartedBy: data?.start?.startedBy?.name,
  startRehabilitationResource: data?.start?.rehabilitationResource,
  startInactivityReason: data?.start?.inactivityReasonKey,
  startOtherInactivityReason: data?.start?.inactivityReason,
  decisionType: data?.decision?.key,
  decisionDate: convertCalendarDate(data?.decision?.date),
  decisionNumber: data?.decision?.number,
  decisionDeliveryDate: convertCalendarDate(data?.decision?.deliveryDate),
  decisionReviewApplicationDeliveryDate: convertCalendarDate(data?.decision?.reviewApplicationDeliveryDate),
  decisionWsaComplaintDeliveryDate: convertCalendarDate(data?.decision?.wsaComplaintDeliveryDate),
  decisionPermissionSuspensionFrom: convertCalendarDate(data?.decision?.permissionSuspensionFrom),
  decisionPermissionSuspensionTo: convertCalendarDate(data?.decision?.permissionSuspensionTo),
  decisionFinalDate: convertCalendarDate(data?.decision?.finalDate),
  secondTierNsaExecutionSuspensionDecisionDate: convertCalendarDate(
    data?.secondTier?.nsaExecutionSuspensionDecisionDate
  ),
  secondTierBpDeliveryDate: convertCalendarDate(data?.secondTier?.bpDeliveryDate),
  secondTierBpCaseNumber: data?.secondTier?.bpCaseNumber,
  secondTierBpDecisionNumber: data?.secondTier?.bpDecisionNumber,
  secondTierBpDecisionDate: convertCalendarDate(data?.secondTier?.bpDecisionDate),
  secondTierBpDecision: data?.secondTier?.bpDecisionKey,
  secondTierBpComplaintDeliveryDate: convertCalendarDate(data?.secondTier?.bpComplaintDeliveryDate),
  secondTierBpExecutionSuspensionDecisionDate: convertCalendarDate(data?.secondTier?.bpExecutionSuspensionDecisionDate),
  secondTierWsaSignature: data?.secondTier?.wsaSignature,
  secondTierWsaDecisionDate: convertCalendarDate(data?.secondTier?.wsaDecisionDate),
  secondTierWsaDecision: data?.secondTier?.wsaDecisionKey,
  secondTierWsaDecisionFinalDate: convertCalendarDate(data?.secondTier?.wsaDecisionFinalDate),
  secondTierCassation: data?.secondTier?.cassation || false,
  secondTierNsaDecisionDate: convertCalendarDate(data?.secondTier?.nsaDecisionDate),
  secondTierNsaDecision: data?.secondTier?.nsaDecisionKey,
  suspensionDecisionDate: convertCalendarDate(data?.suspension?.decisionDate),
  suspensionRenewalDate: convertCalendarDate(data?.suspension?.renewalDate),
  statusTransitions: data?.statusTransitions,
  certificatesReturnTimeToReturn: convertCalendarDate(data?.timeToReturnCertificates),
  certificates: data?.certificatesReturnList?.certificates?.map(certificate => ({
    id: uuidv4(),
    ...certificate
  })),
  licenseCopiesReturnDate: convertCalendarDate(data?.licenseCopiesReturn?.licenseCopiesReturnDate),
  timeToReturnLicenseCopies: convertCalendarDate(data?.timeToReturnLicenseCopies),
  proceedingEntityType: data?.proceedingEntityType
});

export const parseCertificatesListResults = (data: CertificatesServer[]): CertificatesClient[] =>
  data.map(item => ({
    id: item.id,
    number: item.number,
    returnDate: item.returnDate,
    returnNotificationDateITS: item.returnNotificationDateITS,
    cerificateDispatchDate: item.cerificateDispatchDate,
    dispatchNotificationDateITS: item.dispatchNotificationDateITS,
    caseNumber: item.caseNumber
  }));

export const parseProceedingFormToUpdateRequest = (
  proceedingId: string,
  formData: IProceedingDetailsClient
): ProceedingsKreptdApiUpdateProceedingKreptdRequest => ({
  proceedingId,
  proceedingKreptdUpdateRequest: {
    start: {
      date: getCalendarDate(formData?.startDate),
      decisionDeliveryDate: getCalendarDate(convertDateToStringWithoutTimezone(formData?.startDecisionDeliveryDate)),
      inactivityReason: formData?.startOtherInactivityReason,
      inactivityReasonKey: formData?.startInactivityReason,
      rehabilitationResource: formData?.startRehabilitationResource || false
    },
    decision: {
      date: getCalendarDate(convertDateToStringWithoutTimezone(formData?.decisionDate)),
      deliveryDate: getCalendarDate(convertDateToStringWithoutTimezone(formData?.decisionDeliveryDate)),
      key: formData?.decisionType,
      number: formData?.decisionNumber,
      reviewApplicationDeliveryDate: getCalendarDate(
        convertDateToStringWithoutTimezone(formData?.decisionReviewApplicationDeliveryDate)
      ),
      wsaComplaintDeliveryDate: getCalendarDate(
        convertDateToStringWithoutTimezone(formData?.decisionWsaComplaintDeliveryDate)
      ),
      permissionSuspensionFrom: getCalendarDate(formData?.decisionPermissionSuspensionFrom),
      permissionSuspensionTo: getCalendarDate(formData?.decisionPermissionSuspensionTo)
    },
    secondTier: {
      bpCaseNumber: formData?.secondTierBpCaseNumber,
      bpComplaintDeliveryDate: getCalendarDate(
        convertDateToStringWithoutTimezone(formData?.secondTierBpComplaintDeliveryDate)
      ),
      bpDecisionDate: getCalendarDate(convertDateToStringWithoutTimezone(formData?.secondTierBpDecisionDate)),
      bpDecisionKey: formData?.secondTierBpDecision,
      bpDecisionNumber: formData?.secondTierBpDecisionNumber,
      bpDeliveryDate: getCalendarDate(convertDateToStringWithoutTimezone(formData?.secondTierBpDeliveryDate)),
      bpExecutionSuspensionDecisionDate: getCalendarDate(
        convertDateToStringWithoutTimezone(formData?.secondTierBpExecutionSuspensionDecisionDate)
      ),
      nsaExecutionSuspensionDecisionDate: getCalendarDate(
        convertDateToStringWithoutTimezone(formData?.secondTierNsaExecutionSuspensionDecisionDate)
      ),
      cassation: formData?.secondTierCassation || false,
      nsaDecisionDate: getCalendarDate(convertDateToStringWithoutTimezone(formData?.secondTierNsaDecisionDate)),
      nsaDecisionKey: formData?.secondTierNsaDecision,
      wsaDecisionDate: getCalendarDate(convertDateToStringWithoutTimezone(formData?.secondTierWsaDecisionDate)),
      wsaDecisionFinalDate: getCalendarDate(
        convertDateToStringWithoutTimezone(formData?.secondTierWsaDecisionFinalDate)
      ),
      wsaDecisionKey: formData?.secondTierWsaDecision,
      wsaSignature: formData?.secondTierWsaSignature
    },
    ...(formData?.suspensionDecisionDate
      ? {
          suspension: {
            decisionDate: getCalendarDate(convertDateToStringWithoutTimezone(formData?.suspensionDecisionDate)),
            renewalDate: getCalendarDate(convertDateToStringWithoutTimezone(formData?.suspensionRenewalDate))
          }
        }
      : {}),

    certificatesToUpdate: {
      certificates: formData.certificates?.map(certificate => {
        return {
          ...omit(certificate, ['id']),
          certificateId: certificate?.id
        };
      })
    },

    ...(formData?.licenseCopiesReturnDate
      ? {
          licenseCopiesReturn: {
            licenseCopiesReturnDate: getCalendarDate(
              convertDateToStringWithoutTimezone(formData?.licenseCopiesReturnDate)
            )
          }
        }
      : {}),

    version: formData?.version
  }
});

export const isFieldVisible = (fieldName: string, hiddenFields: string[]) => {
  return !hiddenFields.includes(fieldName);
};

export const parseProceedingDocumentsListResults = (documents: DocumentSnapshot[]): IProceedingDocumentClient[] =>
  documents.map(document => ({
    id: document.id,
    generatedDocumentName: document.typeKey, // Mapowanie zgodnie z GITDDEV-9705
    generatedBy: document.author?.name,
    generatedDate: document.created,
    documentFileId: document.file.id,
    documentName: document.generatedFileName,
    addedBy: document.modifier?.name,
    statusKey: document.statusKey,
    passedToSentDate: convertCalendarDate(document.parcel?.passedToSentDate),
    packageNumber: document.parcel?.number,
    sendDate: convertCalendarDate(document.parcel?.sendDate),
    receiptAckDate: convertCalendarDate(document.parcel?.receiptAckDate),
    cancellationDate: convertCalendarDate(document.cancellationDate),
    cancelledBy: document.cancelledBy?.name,
    inputDate: document.inputDate,
    subject: {
      id: document.subject?.id,
      name: document.subject?.name
    },
    address: {
      id: document.address?.id,
      version: document.address?.version
    },
    parcel: {
      number: document.parcel?.number,
      passedToSentDate: convertCalendarDate(document.parcel?.passedToSentDate),
      receiptAckDate: convertCalendarDate(document.parcel?.receiptAckDate),
      sendDate: convertCalendarDate(document.parcel?.sendDate)
    },
    isFinalDecision: document.isFinalDecision,
    isDecision: document.isDecision,
    version: document.version
  }));

export const parseFormData = (formData: ProceedingKreptdCertificateDetails): ProceedingKreptdCertificateDetails => {
  const isValid = (tmp: CalendarDate) => moment(tmp).isValid();
  const parseDate = (date: CalendarDate) => getCalendarDate(moment(date).format(ALTERNATIVE_DATE_FORMAT));

  return {
    ...formData,
    returnDate: isValid(formData.returnDate) ? parseDate(formData.returnDate) : null,
    returnDateNotificationDateOfIts: isValid(formData.returnDateNotificationDateOfIts)
      ? parseDate(formData.returnDateNotificationDateOfIts)
      : null,
    sendingDate: isValid(formData.sendingDate) ? parseDate(formData.sendingDate) : null,
    sendDateNotificationDateOfIts: isValid(formData.sendDateNotificationDateOfIts)
      ? parseDate(formData.sendDateNotificationDateOfIts)
      : null
  };
};
