const checkIsFieldSingleSelect = fieldValue => {
  return typeof fieldValue === 'object' && !Array.isArray(fieldValue) && fieldValue !== null;
};

const checkSingleSelectFieldChange = (initialFieldValue, currentFieldValue) => {
  return JSON.stringify(initialFieldValue?.value) !== JSON.stringify(currentFieldValue?.value);
};

const checkMultipleSelectFieldChange = (initialFieldValue, currentFieldValue) => {
  return initialFieldValue === null && currentFieldValue.length === 0
    ? false
    : String(initialFieldValue?.map(item => item?.id)) !== String(currentFieldValue?.map(item => item?.id));
};

const checkArrayValuesFieldChange = (initialFieldValue, currentFieldValue) => {
  return initialFieldValue === null && currentFieldValue.length === 0
    ? false
    : String(initialFieldValue) !== String(currentFieldValue);
};

const checkStringOrNumberOrBooleanFieldChange = (initialFieldValue, currentFieldValue) => {
  return String(initialFieldValue) !== String(currentFieldValue);
};

export const checkMetaformFieldChanged = (initialFieldValue, currentFieldValue) => {
  const isSingleSelectField =
    checkIsFieldSingleSelect(currentFieldValue) || checkIsFieldSingleSelect(initialFieldValue);

  const isArrayValuesField = Array.isArray(currentFieldValue) || Array.isArray(initialFieldValue);

  const isMultipleSelectField = isArrayValuesField && (currentFieldValue?.[0]?.id || initialFieldValue?.[0]?.id);

  if (isSingleSelectField) {
    return checkSingleSelectFieldChange(initialFieldValue, currentFieldValue);
  }

  if (isMultipleSelectField) {
    return checkMultipleSelectFieldChange(initialFieldValue, currentFieldValue);
  }

  if (isArrayValuesField) {
    return checkArrayValuesFieldChange(initialFieldValue, currentFieldValue);
  }

  return checkStringOrNumberOrBooleanFieldChange(initialFieldValue, currentFieldValue);
};
