import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { AxiosResponse } from 'axios';
import moment from 'moment';

import { blobToBase64 } from '@libs/common/utils';
import { SelectOption } from '@libs/common/v2';
import { useMutation } from '@libs/common/v2/api';

import { parseCertificate } from '../parsers/pemheart-certificate';
import {
  PEMHEART_INTERVAL_TIMEOUT,
  PEMHEART_MAX_TIMEOUT,
  PEMHEART_OFFSET,
  phAddPKCS11Library,
  phCreateContext,
  phDeleteContext,
  phDeleteRAMFile,
  phDownloadRAMFile,
  phGetAvailableCertificates,
  phGetRAMFileSize,
  phSign,
  phSignDetached,
  phUploadRAMFile
} from '../services/pemheart-service';
import {
  AddFileRequestData,
  CertificateType,
  Extension,
  FileAddress,
  FilePath,
  PemheartEventEnum,
  SignAlgorithmType,
  SignatureMetadata,
  SignFileStage,
  SigningFileData,
  useUploadFileMutation
} from '..';

const useSignFile = (
  apiCreateQuery: (formValues: AddFileRequestData) => Promise<AxiosResponse>,
  closeDialog: () => void
) => {
  const [t] = useTranslation();
  const { mutateAsync: uploadFile } = useUploadFileMutation();
  const { mutateAsync: addFile } = useMutation(apiCreateQuery);
  const SIGN_FILE_TIME_FORMAT = 'yyyyMMDD_HHmmss';
  const SIGN_FILE_SUFFIX = '_podpisany_';
  const SIGN_FILE_TYPE = 'application/pdf';

  const buildSignatureData = (): SignatureMetadata => {
    return {
      id: '',
      pin: '',
      certificates: [
        {
          certId: '',
          certificate: {
            base64: '',
            issuerDN: '',
            sn: '',
            subjectDN: '',
            validFrom: '',
            validTo: ''
          }
        }
      ],
      size: '',
      data: ''
    };
  };

  const [currentFileContext, _setCurrentFileContext] = useState<SigningFileData>({
    contextId: null,
    signatureMetadata: buildSignatureData(),
    pemHeartResponseProcessedFlag: false,
    certificateType: CertificateType.CENCERT,
    currentStage: {},
    signingFileStages: [],
    forceCancelSigning: false
  });

  const [isBlocked, setBlocked] = useState<boolean>(false);
  const [areCertificatesLoading, setCertificatesLoading] = useState<boolean>(true);
  const [isFileSigning, setFileSigning] = useState<boolean>(false);
  const { showErrorSnackbar } = useSnackbar();

  const myStateRef = useRef(currentFileContext);

  const setCurrentFileContext = (data: SigningFileData) => {
    myStateRef.current = data;
    _setCurrentFileContext(data);
  };

  const setForceCancelSigning = (state: boolean) => {
    setCurrentFileContext({
      ...myStateRef.current,
      forceCancelSigning: state
    });
  };

  const [certificateType, setCertificateType] = useState<CertificateType>(CertificateType.CENCERT);
  const [certificates, setCertificates] = useState<SelectOption[]>([]);
  const downloadOffset = PEMHEART_OFFSET * 0.75;

  useEffect(() => {
    const recreateContext = async (certificateType: CertificateType) => {
      setCertificatesLoading(true);
      setCertificates([]);
      setBlocked(true);

      setCurrentFileContext({
        ...myStateRef?.current,
        certificateType
      });

      if (myStateRef?.current?.contextId) {
        await phDeleteContext(phSendCommand, myStateRef?.current?.contextId);

        setCurrentFileContext({
          ...myStateRef?.current,
          contextId: null
        });
      }

      await phCreateContext(phSendCommand);
      setBlocked(false);
    };
    if (certificateType) {
      recreateContext(certificateType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificateType]);

  useEffect(() => {
    const getCertificates = async (certType: CertificateType) => {
      await phAddPKCS11Library(phSendCommand, myStateRef?.current?.contextId, getFilePath(certType));
      await phGetAvailableCertificates(phSendCommand, myStateRef?.current?.contextId, '{}');
      const certs = parseCertificates(certType);
      setCertificates(certs);
      setCertificatesLoading(false);
    };
    if (myStateRef?.current?.contextId && !isBlocked) {
      getCertificates(currentFileContext?.certificateType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBlocked, myStateRef?.current?.contextId]);

  const analyzeStage = (signFileStage: SignFileStage) => {
    const stage = myStateRef?.current?.currentStage?.stage;
    if (stage === signFileStage) {
      if (
        myStateRef?.current?.signingFileStages[
          myStateRef?.current?.signingFileStages?.findIndex(fileSign => fileSign.stage === signFileStage)
        ]?.count === myStateRef?.current?.currentStage?.count
      ) {
        nextStage(stage);
      } else {
        myStateRef.current.currentStage.count += 1;
      }
    }
  };

  const phResponseEventListener = useCallback(
    event => {
      const parsedDetails = JSON.parse(event.detail);
      const returnedObject = parsedDetails?.results;

      if (parsedDetails?.error) {
        const status = parsedDetails?.status;
        const errorMessage = status?.split('error:')[1]?.trim() || status || t('error.unknownError');
        showErrorSnackbar(errorMessage);
        closeDialog();
        return;
      }

      if (returnedObject) {
        const dataKey = Object.keys(returnedObject)[0];

        setCurrentFileContext({
          ...myStateRef.current,
          ...(dataKey === 'id' ? { contextId: returnedObject[dataKey] } : {}),
          signatureMetadata: buildSignatureMetadata(dataKey, returnedObject[dataKey]),
          pemHeartResponseProcessedFlag: true
        });
      } else if (parsedDetails?.size) {
        setCurrentFileContext({
          ...myStateRef?.current,
          signatureMetadata: {
            ...myStateRef?.current?.signatureMetadata,
            size: parsedDetails.size
          },
          pemHeartResponseProcessedFlag: true
        });
      } else if (parsedDetails?.data) {
        setCurrentFileContext({
          ...myStateRef.current,
          signatureMetadata: {
            ...myStateRef?.current?.signatureMetadata,
            data: parsedDetails.data
          },
          pemHeartResponseProcessedFlag: true
        });
      } else {
        setCurrentFileContext({
          ...myStateRef?.current,
          pemHeartResponseProcessedFlag: true
        });
      }

      const stage = myStateRef?.current?.currentStage?.stage;
      if (stage) {
        analyzeStage(myStateRef.current.currentStage.stage);
        if (stage === SignFileStage.DONE) {
          setCurrentFileContext({
            ...myStateRef?.current,
            currentStage: null,
            signingFileStages: []
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const nextStage = (stage: SignFileStage): void => {
    myStateRef.current.currentStage.count = 1;
    switch (stage) {
      case SignFileStage.UPLOAD:
        setCurrentFileContext({
          ...myStateRef?.current,
          currentStage: {
            ...myStateRef?.current?.currentStage,
            stage: SignFileStage.SIGN
          }
        });
        break;
      case SignFileStage.SIGN:
        setCurrentFileContext({
          ...myStateRef?.current,
          currentStage: {
            ...myStateRef?.current?.currentStage,
            stage: SignFileStage.GET_SIZE
          }
        });
        break;
      case SignFileStage.GET_SIZE:
        setCurrentFileContext({
          ...myStateRef?.current,
          currentStage: {
            ...myStateRef?.current?.currentStage,
            stage: SignFileStage.DOWNLOAD
          }
        });
        break;
      case SignFileStage.DOWNLOAD:
        setCurrentFileContext({
          ...myStateRef?.current,
          currentStage: {
            ...myStateRef?.current?.currentStage,
            stage: SignFileStage.DELETE
          }
        });
        break;
      case SignFileStage.DELETE:
        setCurrentFileContext({
          ...myStateRef?.current,
          currentStage: {
            ...myStateRef?.current?.currentStage,
            stage: SignFileStage.DONE
          }
        });
        break;
    }
  };

  const buildSignatureMetadata = <T extends string | number | symbol, U>(dataKey: T, value: U) => {
    return {
      [dataKey]: value
    };
  };

  useEffect(() => {
    window.addEventListener(PemheartEventEnum.RESPONSE, phResponseEventListener);
    return () => {
      setForceCancelSigning(true);
      window.removeEventListener(PemheartEventEnum.RESPONSE, phResponseEventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myStateRef]);

  const onExtensionResponseTimeout = (interval?: NodeJS.Timeout) => {
    if (interval) {
      clearInterval(interval);
    }
    setCertificatesLoading(false);
    if (!myStateRef?.current?.forceCancelSigning) {
      showErrorSnackbar(t('document:message.signFileExtensionResponseTimeoutMessage'));
    }
  };

  const phSendCommand = (message: string, maxTimeout = PEMHEART_MAX_TIMEOUT) => {
    return new Promise<string>(resolve => {
      const event = new CustomEvent(PemheartEventEnum.REQUEST, { detail: message });
      window.dispatchEvent(event);
      const timeout = PEMHEART_INTERVAL_TIMEOUT;
      let currentTime = 0;
      const pemInterval = setInterval(() => {
        currentTime += timeout;
        if (myStateRef?.current?.pemHeartResponseProcessedFlag === true) {
          setCurrentFileContext({
            ...myStateRef?.current,
            pemHeartResponseProcessedFlag: false
          });
          clearInterval(pemInterval);
          resolve('');
        } else if (currentTime > maxTimeout) {
          onExtensionResponseTimeout(pemInterval);
        } else if (myStateRef?.current?.forceCancelSigning) {
          clearInterval(pemInterval);
        }
      }, timeout);

      // eslint-disable-next-line no-promise-executor-return
      return () => clearInterval(pemInterval);
    });
  };

  const phSendFragmentCommand = (message: string, stage, maxTimeout = PEMHEART_MAX_TIMEOUT) => {
    const event = new CustomEvent(PemheartEventEnum.REQUEST, { detail: message });
    window.dispatchEvent(event);
    const timeout = PEMHEART_INTERVAL_TIMEOUT;
    let currentTime = 0;
    return new Promise<string>(resolve => {
      const waitOnResponse = (): void => {
        currentTime += timeout;
        if (currentTime > maxTimeout) {
          onExtensionResponseTimeout();
          return;
        }
        if (
          (stage && myStateRef?.current?.currentStage?.stage !== stage) ||
          myStateRef?.current?.pemHeartResponseProcessedFlag === false
        ) {
          if (myStateRef?.current?.forceCancelSigning) {
            return;
          }
          setTimeout(() => waitOnResponse(), timeout);
        } else {
          resolve('');
        }
      };

      waitOnResponse();
    });
  };

  const getFilePath = (certType: CertificateType) => {
    switch (certType) {
      case CertificateType.CENCERT:
        return FilePath.CENCERT;
      case CertificateType.SIGILLUM:
        return FilePath.SIGILLUM;
      case CertificateType.ASSECO:
        return FilePath.ASSECO;
      default:
        return FilePath.CENCERT;
    }
  };

  const base64toBlob = (base64Data, contentType: string): Blob => {
    const byteString = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i += 1) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: contentType });
  };

  const signFile = async ({
    fileBlob,
    fileName,
    certificateId,
    pin
  }: {
    fileBlob: Blob;
    fileName: string;
    certificateId: string;
    pin: string;
  }) => {
    if (fileBlob) {
      setFileSigning(true);
      cleanSigningFileStages();
      const fileCacheLocation = `\\\\ram\\${fileName}`;
      const b64 = await blobToBase64(fileBlob);
      await uploadPemHeartFile(
        b64,
        fileName,
        myStateRef?.current?.contextId,
        certificateId,
        pin,
        fileCacheLocation,
        fileBlob
      );
    }
  };

  const convertToHex = (value: number): string => {
    return `0x${value.toString(16)}`;
  };

  const uploadPemHeartFile = async (
    b64: string,
    fileName: string,
    contextId: string,
    certificateId: string,
    pin: string,
    fileCacheLocation: string,
    fileBlob: Blob
  ) => {
    const b64Parts = b64.match(/.{1,500000}/g) as string[];
    const addresses: FileAddress[] = [];
    let totalLength = 0;

    if (b64Parts?.length > 0) {
      b64Parts.forEach(b64Part => {
        const blobSize = base64toBlob(b64Part, '').size;
        addresses.push({
          from: totalLength.toString(),
          fromHex: convertToHex(totalLength),
          to: (totalLength + blobSize).toString(),
          size: blobSize.toString()
        });
        totalLength += blobSize;
      });

      let addressesUploaded = 0;
      const stageToChangeIndex = myStateRef?.current?.signingFileStages?.findIndex(
        file => file.stage === SignFileStage.UPLOAD
      );
      const changedFileStage = {
        ...myStateRef?.current?.signingFileStages[stageToChangeIndex],
        count: addresses.length
      };
      const signingFileStages = myStateRef?.current.signingFileStages.map((signingFileStage, index) => {
        if (index === stageToChangeIndex) {
          return changedFileStage;
        }
        return signingFileStage;
      });

      setCurrentFileContext({
        ...myStateRef?.current,
        currentStage: {
          stage: SignFileStage.UPLOAD,
          count: 0
        },
        signingFileStages
      });

      // eslint-disable-next-line no-restricted-syntax
      for (const address of addresses) {
        setCurrentFileContext({
          ...myStateRef?.current,
          pemHeartResponseProcessedFlag: false
        });
        await phUploadRAMFile(
          phSendFragmentCommand,
          contextId,
          fileCacheLocation,
          address.fromHex,
          b64Parts[addresses.indexOf(address)]
        );
        ++addressesUploaded;

        let algorithm = SignAlgorithmType.XAdES;
        if (fileName.endsWith(Extension.PDF)) {
          algorithm = SignAlgorithmType.PAdES;
        } else if (fileName.endsWith(Extension.XML)) {
          algorithm = SignAlgorithmType.XAdESEnveloping;
        }

        if (addressesUploaded === addresses.length) {
          await signPermHeartFile(contextId, algorithm, fileCacheLocation, certificateId, pin, fileName, fileBlob);
        }
      }
    } else {
      showErrorSnackbar(t('document:message.signFileDoesNotContainDataMessage'));
    }
  };

  const signPermHeartFile = async (
    contextId: string,
    algorithm: string,
    fileCacheLocation: string,
    certificateId: string,
    pin: string,
    fileName: string,
    fileBlob: Blob
  ) => {
    setCurrentFileContext({
      ...myStateRef?.current,
      pemHeartResponseProcessedFlag: false
    });
    if (SignAlgorithmType.XAdES === algorithm) {
      await phSignDetached(
        phSendFragmentCommand,
        contextId,
        algorithm,
        fileCacheLocation,
        fileCacheLocation,
        certificateId,
        pin
      );
    } else {
      await phSign(
        phSendFragmentCommand,
        contextId,
        algorithm,
        fileCacheLocation,
        fileCacheLocation,
        certificateId,
        pin
      );
    }
    setCurrentFileContext({
      ...myStateRef?.current,
      pemHeartResponseProcessedFlag: false
    });
    await downloadPermHeartSignFile(contextId, algorithm, fileCacheLocation, fileName, fileBlob);
  };

  const downloadPermHeartSignFile = async (
    contextId: string,
    algorithm: string,
    fileNameRam: string,
    fileName: string,
    value: Blob
  ) => {
    let signedFileName = fileName;
    setCurrentFileContext({
      ...myStateRef?.current,
      pemHeartResponseProcessedFlag: false
    });
    await phGetRAMFileSize(phSendFragmentCommand, contextId, fileNameRam);
    const fragments: Array<FileAddress> = [];
    const iterations = Math.floor(Number(myStateRef?.current?.signatureMetadata?.size) / downloadOffset);
    const lastFragmentSize = Number(myStateRef?.current?.signatureMetadata?.size) - downloadOffset * iterations;
    for (let i = 0; i <= iterations; i += 1) {
      const isLastItem = i === iterations;

      const size = isLastItem ? lastFragmentSize : downloadOffset;
      fragments.push({
        from: (i * downloadOffset).toString(),
        fromHex: convertToHex(i * downloadOffset),
        to: convertToHex(downloadOffset * (i + 1) + lastFragmentSize * Number(isLastItem)),
        size: convertToHex(size)
      });
    }

    const stageToChangeIndex = myStateRef?.current?.signingFileStages?.findIndex(
      item => item.stage === SignFileStage.DOWNLOAD
    );
    const changedFileStage = { ...myStateRef?.current?.signingFileStages[stageToChangeIndex], count: fragments.length };
    const signingFileStages = myStateRef?.current?.signingFileStages.map((signingFileStage, index) => {
      if (index === stageToChangeIndex) {
        return changedFileStage;
      }
      return signingFileStage;
    });

    setCurrentFileContext({
      ...myStateRef?.current,
      signingFileStages
    });

    const downloadedFragments = Array<string>(fragments.length);

    // eslint-disable-next-line no-restricted-syntax
    for (const fragment of fragments) {
      setCurrentFileContext({
        ...myStateRef?.current,
        pemHeartResponseProcessedFlag: false
      });
      await phDownloadRAMFile(phSendFragmentCommand, contextId, fileNameRam, fragment.fromHex, fragment.size);
      downloadedFragments[fragments.indexOf(fragment)] = myStateRef?.current?.signatureMetadata?.data;
    }

    if (algorithm !== SignAlgorithmType.PAdES && !fileName.endsWith(Extension.XADES)) {
      signedFileName += Extension.XADES;
    }

    const blob = base64toBlob(downloadedFragments.join(''), '');

    if (isSigned(value, blob, algorithm)) {
      const currentDate = moment(new Date())?.format(SIGN_FILE_TIME_FORMAT);
      const signedFileNameParts = signedFileName.split(Extension.PDF);
      signedFileNameParts?.pop();
      const file = new File(
        [blob],
        `${signedFileNameParts?.toString()}${SIGN_FILE_SUFFIX}${currentDate}${Extension.PDF}`,
        { type: SIGN_FILE_TYPE }
      );
      const uploadedFile = await uploadFile({ file });
      await addFile(
        { fileId: uploadedFile?.data?.id },
        {
          onSettled: async (_, error) => {
            await phDeleteRAMFile(phSendFragmentCommand, contextId, fileNameRam);
            setFileSigning(false);
            if (error) {
              throw new Error();
            }
          }
        }
      );
    } else {
      await phDeleteRAMFile(phSendFragmentCommand, contextId, fileNameRam);
      setFileSigning(false);
    }
  };

  const isSigned = (original: Blob, signed: Blob, algorithm: string) => {
    if (SignAlgorithmType.XAdES !== algorithm) {
      return original.size < signed.size;
    }
    return signed.size > 0;
  };

  const parseCertificates = (certType: CertificateType) => {
    let certs = myStateRef?.current?.signatureMetadata?.certificates;
    if (certificateType !== CertificateType.CENCERT) {
      certs = certs.filter(certificate =>
        certificate?.certificate?.issuerDN.toLocaleLowerCase().includes(certType?.toLocaleLowerCase())
      );
    }
    return certs?.map(certificate => parseCertificate(certificate));
  };

  const cleanSigningFileStages = () => {
    setCurrentFileContext({
      ...myStateRef?.current,
      signingFileStages: [
        {
          stage: SignFileStage.UPLOAD,
          count: 0
        },
        {
          stage: SignFileStage.SIGN,
          count: 1
        },
        {
          stage: SignFileStage.GET_SIZE,
          count: 1
        },
        {
          stage: SignFileStage.DOWNLOAD,
          count: 0
        },
        {
          stage: SignFileStage.DELETE,
          count: 1
        }
      ]
    });
  };

  return {
    signFile,
    certificates,
    setCertificateType,
    areCertificatesLoading,
    isFileSigning,
    setForceCancelSigning
  };
};

export default useSignFile;
