import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  DictionarySelectField,
  GridItem,
  GridLayout,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';

import { DomainDictionaryEnum } from '../../../../config';
import { ExploitationBaseFormData } from '../../../model';

function InspectionSectionFields({
  isInspectionResultRequired,
  isDateRequired,
  isRemarksRequired
}: {
  isInspectionResultRequired?: boolean;
  isDateRequired?: boolean;
  isRemarksRequired?: boolean;
}) {
  const getLabel = partialTranslate('exploitationBase:field');

  return (
    <GridLayout itemProps={{ xs: 12, sm: 6 }}>
      <DictionarySelectField
        name={typedNameV2<ExploitationBaseFormData>('inspection.inspectionResultKey')}
        dictionaryName={DomainDictionaryEnum.EXPLOITATION_BASE_INSPECTION_RESULT}
        label={getLabel('controlResult')}
        isRequired={isInspectionResultRequired}
        stringValue
      />
      <DatepickerField
        name={typedNameV2<ExploitationBaseFormData>('inspection.inspectionDate')}
        label={getLabel('controlDate')}
        maxDate={Date.now()}
        isRequired={isDateRequired}
      />
      <GridItem xs={12}>
        <TextInputField
          name={typedNameV2<ExploitationBaseFormData>('inspection.remarks')}
          lines={5}
          label={getLabel('remarks')}
          inputProps={{ maxLength: 250 }}
          isRequired={isRemarksRequired}
        />
      </GridItem>
    </GridLayout>
  );
}

export default InspectionSectionFields;
