import { ArchiveFolderDetails } from '@ibtm/domain';

import { convertCalendarDate } from '@libs/common/v2/utils';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { FolderArchiveSnapshot } from '../model';

const parseFolderArchiveItem = (element: ArchiveFolderDetails): FolderArchiveSnapshot => ({
  id: element.id,
  primaryAddress: element.addresses.find(address => address.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.MAIN),
  number: element.number,
  classificationKey: element.jrwaCategory?.category,
  typeKey: element.typeKey,
  created: element.created,
  subject: element.subject,
  expiredDate: convertCalendarDate(element.expirationDate),
  warning: element.archiveNote,
  version: element.version,
  statusKey: element.statusKey,
  folderLocalizationKey: element.folderLocalizationKey,
  passedToDepartmentalArchiveDate: convertCalendarDate(element.passedToDepartmentalArchiveDate),
  activeApplicationOnFolder: element.activeApplicationOnFolder,
  fullAddress: element.fullAddress
});

export const parseFolderArchiveList = (data: ArchiveFolderDetails[]): FolderArchiveSnapshot[] =>
  data.map(item => parseFolderArchiveItem(item));
