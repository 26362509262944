import { useRef } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MisspellApplicationDataRecordUpdateRequest } from '@ibtm/domain';

import { FormMode, GridLayout, Section } from '@libs/common/v2';

import { useUpdateApplicationMisspellPermissionMutation } from '../../api';
import { MisspellApplicationPermissionTypeEnum } from '../../model/misspell-application.model';
import { MisspellApplicationPermissionFormFields } from '../form/MisspellApplicationPermissionFormFields';

import { IPermissionDialogProps, PermissionDialog } from './PermissionDialog';

interface IProps
  extends Pick<
    IPermissionDialogProps<MisspellApplicationDataRecordUpdateRequest>,
    'permissionData' | 'updatePermissionData' | 'closeDialog' | 'mode' | 'applicationId'
  > {
  permissionType: MisspellApplicationPermissionTypeEnum;
}

function MisspellApplicationPermissionDialog({
  mode,
  permissionData,
  updatePermissionData,
  applicationId,
  permissionType,
  closeDialog
}: IProps) {
  const [t] = useTranslation();
  const handleSubmit = useRef<UseFormHandleSubmit<MisspellApplicationDataRecordUpdateRequest>>(null);

  return (
    <PermissionDialog
      formRef={handleSubmit}
      applicationId={applicationId}
      closeDialog={closeDialog}
      mode={mode}
      permissionData={permissionData}
      mutation={useUpdateApplicationMisspellPermissionMutation}
      updatePermissionData={updatePermissionData}
      editTitle={t('applications:misspellApplication.dialog.permissionEdit')}
      compareTitle={t('applications:misspellApplication.dialog.changesComparition')}
      permissionType={permissionType}
      compareContentElement={
        <GridLayout itemProps={{ xs: 6 }}>
          <Section title={t('applications:misspellApplication.dialog.permissionData')} isModalSection>
            <MisspellApplicationPermissionFormFields
              formMode={FormMode.VIEW}
              permissionData={permissionData}
              permissionType={permissionType}
            />
          </Section>
          <Section title={t('applications:misspellApplication.dialog.updatedPermissionData')} isModalSection>
            <MisspellApplicationPermissionFormFields
              formMode={FormMode.VIEW}
              permissionData={updatePermissionData}
              permissionType={permissionType}
            />
          </Section>
        </GridLayout>
      }
      editContentElement={
        <MisspellApplicationPermissionFormFields
          formMode={FormMode.EDIT}
          permissionData={updatePermissionData}
          ref={handleSubmit}
          permissionType={permissionType}
        />
      }
    />
  );
}

export default MisspellApplicationPermissionDialog;
