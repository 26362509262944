import { PaginatedQueryConfig, usePaginatedQuery } from 'react-query';
import { ResourceTypeSearchFilter } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { parseResourceTypesList } from '../../../utils';
import { endpointsConfig } from '../../endpoints-config';
import { ResourceQueryKeysEnum } from '..';

export const getResourceTypes = async (params: ResourceTypeSearchFilter) => {
  const { data } = await API.resourceTypes.getResourceTypes(params, {
    ...endpointsConfig.getResourceTypes
  });

  return {
    ...data,
    content: parseResourceTypesList(data.content)
  };
};

export default function useResourceTypesQuery(
  params: ResourceTypeSearchFilter,
  queryConfig?: PaginatedQueryConfig<Awaited<ReturnType<typeof getResourceTypes>>, unknown>
) {
  return usePaginatedQuery(
    [ResourceQueryKeysEnum.RESOURCE_TYPES_LIST, params],
    () => getResourceTypes(params),
    queryConfig
  );
}
