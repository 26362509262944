import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '@enigma/fe-ui';
import { SuspensionSnapshot } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import { typedNameV2, useConfirmDialog, useFormV2Context } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import {
  ISuspensionDetailsParams,
  SuspensionQueryKeysEnum,
  useFormOperationsDeleteMutation,
  useSuspendFormOperationsMutation
} from '@libs/domain/suspensions';

import useFormOperationsDialog from './useFormOperationsDialog';

export function useFormOperationsActions() {
  const queryCache = useQueryCache();
  const { id } = useParams<ISuspensionDetailsParams>();
  const { showSuccessSnackbar } = useSnackbar();
  const [confirm] = useConfirmDialog();
  const { getValues } = useFormV2Context();

  const getMessage = partialTranslate('suspensions:formOperations.confirm');
  const getSuccesLabel = partialTranslate('suspensions:formOperations.success');
  const getTitle = partialTranslate('suspensions:formOperations.dialogTitle');

  const { mutate: suspendFormOperations } = useSuspendFormOperationsMutation();
  const { mutate: deleteFormOperation } = useFormOperationsDeleteMutation();

  const refreshView = () => {
    queryCache.invalidateQueries(SuspensionQueryKeysEnum.SUSPENSION_FORM_OPERATIONS);
    queryCache.invalidateQueries(SuspensionQueryKeysEnum.SUSPENSION_DETAILS);
  };

  const { postponeConfirm } = useFormOperationsDialog(id, refreshView);

  const submitCleanup = (message: string) => {
    showSuccessSnackbar(message);
    refreshView();
  };

  const handleDelete = useCallback(
    (formOperationId: string) => {
      confirm({
        title: getTitle('delete'),
        message: getMessage('delete'),
        confirmType: 'danger',
        onConfirm: async (setConfirmLoading, closeDialog) => {
          setConfirmLoading(true);
          deleteFormOperation(
            { suspensionId: id, formOperationId },
            {
              onSuccess: () => {
                showSuccessSnackbar(getSuccesLabel('delete'));
                refreshView();
                closeDialog();
              },
              onSettled: () => {
                setConfirmLoading(false);
              }
            }
          );
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshView]
  );

  const handlePostpone = useCallback(() => {
    const [dateFrom, dateTo, version] = getValues([
      typedNameV2<SuspensionSnapshot>('dateFrom'),
      typedNameV2<SuspensionSnapshot>('dateTo'),
      typedNameV2<SuspensionSnapshot>('version')
    ]) as [string, string, number];
    postponeConfirm(dateFrom, dateTo, version);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSuspend = useCallback(() => {
    confirm({
      message: getMessage('suspend'),
      title: getTitle('suspend'),
      onConfirm: async (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        suspendFormOperations(
          {
            suspensionId: id,
            version: getValues('version') as number
          },
          {
            onSuccess: () => {
              submitCleanup(getSuccesLabel('suspend'));
              closeDialog();
            },
            onSettled: () => {
              setConfirmLoading(false);
            }
          }
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    handlePostpone,
    handleDelete,
    handleSuspend
  };
}
