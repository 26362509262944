import { ApplicationSnapshot } from '@ibtm/domain';

import { ITableLayoutColumn } from '@libs/common/v2';

import { useApplicationTableColumns } from '@libs/domain/application';

const usePrintsVisibleColumns = (numberHeader?: string): ITableLayoutColumn<ApplicationSnapshot>[] => {
  const columns = useApplicationTableColumns(null, numberHeader);
  return [
    columns.number,
    columns.paperNumber,
    columns.categoryKey,
    columns.typeKey,
    columns.folderNumber,
    { ...columns.statusKey, Filter: null },
    columns.submissionReceiptDate,
    columns.created,
    columns.authorName,
    columns.subjectName,
    columns.subjectNip,
    columns.modified,
    columns.modifierName,
    columns.expectedCompletionDate,
    columns.editorName
  ];
};

export default usePrintsVisibleColumns;
