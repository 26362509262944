import { PaginatedQueryConfig, usePaginatedQuery } from 'react-query';
import { ForeignPermissionSearchFilter, ForeignPermissionSnapshot } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { ForeignPermissionQueryEnum, ReleasedPermitsSnapshot } from '../../model';
import { releasedPermitsParser } from '../../utils';
import { endpointsConfig } from '../endpoints-config';

export const getPermits = async (params: ForeignPermissionSearchFilter) => {
  const { data } = await API.foreignPermissions.getForeignPermissionSnapshotPage(params, {
    ...endpointsConfig.getForeignPermissionSnapshotPage
  });

  return { ...data, content: releasedPermitsParser(data.content) };
};

export const getPermitsClient = async params => {
  const { data } = await API.client.foreignPermissions.getForeignPermissionSnapshotPage('', '', params, {
    ...endpointsConfig.getForeignPermissionSnapshotPage
  });
  return { ...data, content: releasedPermitsParser(data.content as Array<ForeignPermissionSnapshot>) };
};

function usePermitsQuery(params: ForeignPermissionSearchFilter, queryConfig: PaginatedQueryConfig<unknown> = {}) {
  const { getQuery } = useGetApiQueryByPerspective();
  return usePaginatedQuery<IPaginatedModel<ReleasedPermitsSnapshot>>(
    [ForeignPermissionQueryEnum.FOREIGN_PERMISSION_LIST, params],
    getQuery(
      () => getPermitsClient(params),
      () => getPermits(params)
    ),
    queryConfig
  );
}
export default usePermitsQuery;
