import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface IParams {
  applicationId: string;
  content: string;
}

async function createApplicationNote({ applicationId, content }: IParams) {
  const { data } = await API.applicationNote.createApplicationNote(
    applicationId,
    { content },
    {
      ...endpointsConfig.createApplicationNote
    }
  );

  return data;
}

function useCreateApplicationNoteMutation() {
  return useMutation(createApplicationNote);
}

export default useCreateApplicationNoteMutation;
