import React from 'react';
import { useTranslation } from 'react-i18next';

import TableIconButton from './TableIconButton';

interface IProps {
  onClick: () => void;
  tooltipTitle?: string;
  className?: string;
  isDisabled?: boolean;
}

function TableButtonPrint({ isDisabled, tooltipTitle, className, onClick }: IProps) {
  const [t] = useTranslation();

  return (
    <TableIconButton
      tooltipTitle={tooltipTitle || t('action.print')}
      className={className}
      icon="PrintIcon"
      onClick={onClick}
      isDisabled={isDisabled}
    />
  );
}

export default React.memo(TableButtonPrint);
