import { ResourceObjectPoolSearchFilter } from '@ibtm/domain';

import {
  CamelCasePath,
  SelectOption,
  TableFilter,
  TableFilterMapper,
  TableSortMapper,
  useCreateColumns
} from '@libs/common/v2';
import { DateRangeISO, getCalendarDate, TAmountRange } from '@libs/common/v2/utils';

import { useDictionaryEntryValues, useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import { ResourceObjectGapAndCorrectPoolClient, ResourceObjectPoolClient } from '../../model';
import useFilteredDictionary from '../useFilteredDictionary';

const useResourceObjectPoolsColumns = (
  visibleColumns: Array<CamelCasePath<ResourceObjectPoolClient>>,
  excludedStateKeyIn?: string[]
) => {
  const { createColumns } = useCreateColumns<ResourceObjectPoolClient | ResourceObjectGapAndCorrectPoolClient>({
    pathToTranslate: 'resource:fields'
  });
  const resourceFormStateValues = useDictionaryEntryValues(DomainDictionaryEnum.RESOURCE_FORM_STATE);
  const { translate } = useDictionaryTranslations();

  const { entries: filteredGroupDictionaryEntries } = useFilteredDictionary(DomainDictionaryEnum.RESOURCE_FORM_GROUP, [
    DomainDictionaryEntry.RESOURCE_FORM_GROUP.S_CERTIFICATE
  ]);

  const mappedSortFields: TableSortMapper<ResourceObjectPoolClient | ResourceObjectGapAndCorrectPoolClient> = {
    group: 'resourceType.groupKey',
    country: 'resourceType.countryKey',
    name: 'resourceType.nameKey',
    amount: 'size'
  };

  const mappedFilterFields: TableFilterMapper<
    ResourceObjectPoolClient | ResourceObjectGapAndCorrectPoolClient,
    ResourceObjectPoolSearchFilter
  > = {
    group: (groups: SelectOption<string>[]) => ({ groupKeyIn: groups.map(group => group.value) }),
    country: (countries: SelectOption<string>[]) => ({ countryCodeKeyIn: countries.map(country => country.value) }),
    name: (names: SelectOption<string>[]) => ({ nameKeyIn: names.map(name => name.value) }),
    numberFrom: (amountRange: TAmountRange) => ({
      numberFromGreaterThanOrEqual: amountRange.min,
      numberFromLessThanOrEqual: amountRange.max
    }),
    numberTo: (amountRange: TAmountRange) => ({
      numberToGreaterThanOrEqual: amountRange.min,
      numberToLessThanOrEqual: amountRange.max
    }),
    amount: (amountRange: TAmountRange) => ({
      amountToGreaterThanOrEqual: amountRange.min,
      amountToLessThanOrEqual: amountRange.max
    }),
    stateKey: states => ({ stateKeyIn: states.map(state => state.value) }),
    admissionDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      admissionDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      admissionDateLessThanOrEqual: getCalendarDate(dateTo)
    })
  };

  const columns = createColumns(
    [
      {
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_GROUP,
        accessor: 'group',
        filter: props => (
          <TableFilter.DictionaryMultiSelect
            {...props}
            dictionary={DomainDictionaryEnum.RESOURCE_FORM_GROUP}
            options={filteredGroupDictionaryEntries}
          />
        ),
        width: 300
      },
      {
        id: 'stateKey',
        type: 'MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_STATE,
        customAccessor: row => {
          return translate(DomainDictionaryEnum.RESOURCE_FORM_STATE, row.stateKey);
        },
        width: 200,
        selectOptions: resourceFormStateValues
          .map(({ name, value }) => ({ name, value }))
          .filter(({ value }) => !excludedStateKeyIn.includes(value))
      },
      {
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.COUNTRY_CODE,
        accessor: 'country',
        width: 200
      },
      {
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_NAME,
        accessor: 'name',
        width: 300
      },
      {
        type: 'NUMBER_RANGE',
        accessor: 'numberFrom',
        width: 200
      },
      {
        type: 'NUMBER_RANGE',
        accessor: 'numberTo',
        width: 200
      },
      {
        type: 'NUMBER_RANGE',
        accessor: 'amount',
        width: 200
      },
      {
        type: 'DATE',
        accessor: 'admissionDate',
        width: 255
      }
    ],
    visibleColumns
  );

  return { columns, mappedSortFields, mappedFilterFields };
};

export default useResourceObjectPoolsColumns;
