export enum UserQueryKeysEnum {
  USERS_LIST = 'USERS_LIST',
  USERS_LIST_FILTER = 'USERS_LIST_FILTER',
  USER_ACCOUNT_CONTROLLER_FIELD = 'USER_ACCOUNT_CONTROLLER_FIELD',
  PROFILE_GROUP_PERMISSIONS = 'PROFILE_GROUP_PERMISSIONS',
  PROFILE_GROUP_PERMISSIONS_CONTROLLER_FIELD = 'PROFILE_GROUP_PERMISSIONS_CONTROLLER_FIELD',
  WORKING_HOURS = 'WORKING_HOURS',
  TRAINING_AND_EXAMINATIONS = 'TRAINING_AND_EXAMINATIONS',
  USER_DETAILS = 'USER_DETAILS',
  GROUPS = 'GROUPS',
  GROUPS_EDIT = 'GROUPS_EDIT',
  REPLACEMENTS = 'REPLACEMENTS',
  ORGANIZATION_UNITS_FILTER = 'ORGANIZATION_UNITS_FILTER',
  LIST_PERMISSIONS_FOR_GROUP = 'LIST_PERMISSIONS_FOR_GROUP'
}
