import moment from 'moment';

import {
  ALTERNATIVE_DATE_FORMAT,
  ALTERNATIVE_DATE_TIME_FORMAT,
  DatepickerField,
  useFormV2Context
} from '@libs/common/v2';

import { useMetaFormContext } from '@libs/meta-form/context';
import { DatetimeFieldProperties, FieldProps, FieldTypes } from '@libs/meta-form/models';
import { useGetQuery, useMapAdditionalFieldsValidationType } from '@libs/meta-form/services';

function DatetimeField({
  fieldId,
  label,
  minDate,
  maxDate,
  disabled,
  format,
  clearable,
  inputMode,
  defaultInitialValue,
  withTime = false,
  api,
  yupValidation
}: FieldProps<DatetimeFieldProperties>) {
  const { setValue } = useFormV2Context();
  useMapAdditionalFieldsValidationType(fieldId, { type: FieldTypes.DATETIME });

  const { apiRegistry } = useMetaFormContext();

  const formatViewValue = (date: string) => {
    if (date) {
      if (format) {
        return moment(date).format(format);
      }
      if (withTime) {
        return moment(date).format(ALTERNATIVE_DATE_TIME_FORMAT);
      }
      return moment(date).format(ALTERNATIVE_DATE_FORMAT);
    }

    return null;
  };

  useGetQuery({
    requestKey: api?.get?.requestKey,
    accessor: api?.get?.accessor,
    apiRegistry,
    params: api?.get?.backendParams,
    setValue: newValue => setValue(fieldId, newValue ?? defaultInitialValue ?? null),
    fieldId
  });

  return (
    <DatepickerField
      name={fieldId}
      label={label}
      isRequired={Boolean(yupValidation?.required)}
      minDate={minDate}
      maxDate={maxDate}
      isDisabled={disabled}
      format={format || (withTime ? ALTERNATIVE_DATE_TIME_FORMAT : ALTERNATIVE_DATE_FORMAT)}
      isClearable={clearable}
      inputMode={inputMode}
      viewModeDateParser={formatViewValue}
    />
  );
}

export default DatetimeField;
