import { NodeTypes } from '../models';

import {
  Column,
  ConditionallyVisible,
  Field,
  Form,
  Grid,
  Row,
  Section,
  TabContent,
  TabNavigation,
  TabsContent,
  Warning
} from '.';

const NodeRegistry = {
  [NodeTypes.ROW]: Row,
  [NodeTypes.COLUMN]: Column,
  [NodeTypes.FIELD]: Field,
  [NodeTypes.TAB_NAVIGATION]: TabNavigation,
  [NodeTypes.TAB_CONTENT]: TabContent,
  [NodeTypes.TABS_CONTENT]: TabsContent,
  [NodeTypes.SECTION]: Section,
  [NodeTypes.GRID]: Grid,
  [NodeTypes.FORM]: Form,
  [NodeTypes.CONDITIONALLY_VISIBLE]: ConditionallyVisible,
  [NodeTypes.WARNING]: Warning
};
export default NodeRegistry;
