import React, { useCallback } from 'react';
import { Resolver } from 'react-hook-form';
import { PaginatedQueryResult, QueryResult } from 'react-query';
import { Row } from 'react-table';

import { KeyType } from '@libs/common';
import {
  IPaginatedModel,
  ISingleColumn,
  ITableAdapter,
  ITableBodyProps,
  ITableContext,
  ITablePaginationProps,
  MIN_TABLE_ELEMENTS,
  Table,
  TMutableTableFormContextProvider
} from '@libs/common/v2';

interface IProps<T extends Record<string, any>, K extends Record<string, any> = T> {
  pageHeader?: React.ReactNode;
  table: ITableAdapter<T, K>;
  refetch: () => void;
  queryResult:
    | Omit<PaginatedQueryResult<IPaginatedModel<T>>, 'refetch' | 'isError'>
    | Omit<QueryResult<T[], unknown>, 'refetch' | 'isError'>;
  /**
   * szerokość kolumny akcji - zostawione do ewentualnych edycji, ustawione na 132 zgodnie z makietą
   */
  actionsColumnWidth?: number;
  selectionColumnWidth?: number;
  rowActions?: (
    row: Row<T>,
    table: { tableInstance: ITableAdapter<T, K>; tableContext: ITableContext<T, K> }
  ) => React.ReactNode;
  editFormYupResolverSchema?: Resolver<Record<string, any>, Record<string, any>>;
  bodyProps?: Omit<ITableBodyProps<T, K>, 'emptyTableText' | 'rowActions'>;
  paginationProps?: ITablePaginationProps;
  emptyTableText?: string;
  tableClassName?: string;
  children?: React.ReactNode;
  paginationClassName?: string;
  scrollbarOffset?: number;
  checkDisabled?: (table?: ITableAdapter<T, K>, row?: Row<T>) => boolean;
  initiallyChecked?: (table?: ITableAdapter<T, K>, row?: Row<T>) => boolean;
  onRowEditCreateConfirm?: (
    row: T,
    context?: {
      isCreated: boolean;
      isEdited: boolean;
      tableContext: ITableContext<T, K>;
      tableInstance: ITableAdapter<T, K>;
      formContext: TMutableTableFormContextProvider;
      onSuccess: () => void;
      onError: () => void;
    }
  ) => Promise<any> | void;
  newRowScheme?: T;
  columnsToInsert?: {
    pathToTranslate: KeyType;
    columns: ISingleColumn<T, K>[];
  };
  maxInsertedColumnNumber?: number;
  isPaginated?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  isHeaderRowWhiteBackground?: boolean;
  isTableGrey?: boolean;
  isTableMutable?: boolean;
  isFilterEnabled?: boolean;
  isPageTabContent?: boolean;
  isHiddenSelectRowHeader?: boolean;
  headerRef?: React.MutableRefObject<HTMLDivElement>;
}

/**
 * Komponent renderuje content tabeli na podstawie danych przekazanych z TableWithoutHook.
 */
function TableWithoutHookContent<T extends Record<string, any>, K extends Record<string, any> = T>({
  table,
  refetch,
  pageHeader,
  queryResult,
  bodyProps,
  rowActions,
  emptyTableText,
  isPaginated,
  selectionColumnWidth,
  actionsColumnWidth,
  tableClassName,
  paginationProps = {},
  columnsToInsert,
  isHeaderRowWhiteBackground,
  paginationClassName,
  editFormYupResolverSchema,
  checkDisabled,
  initiallyChecked,
  onRowEditCreateConfirm,
  newRowScheme,
  scrollbarOffset,
  isLoading,
  isFilterEnabled,
  isError,
  isTableMutable,
  isTableGrey,
  maxInsertedColumnNumber,
  isPageTabContent,
  children,
  isHiddenSelectRowHeader,
  headerRef
}: IProps<T, K>) {
  const isPaginationVisible = useCallback(tableQueryResult => {
    return (
      tableQueryResult?.data?.totalPages > 1 ||
      tableQueryResult?.data?.numberOfElements >= MIN_TABLE_ELEMENTS ||
      tableQueryResult?.data?.length >= MIN_TABLE_ELEMENTS
    );
  }, []);

  return (
    <Table
      isLoading={isLoading}
      isError={isError}
      newRowScheme={newRowScheme}
      checkDisabled={checkDisabled}
      initiallyChecked={initiallyChecked}
      onRowEditCreateConfirm={onRowEditCreateConfirm}
      table={table}
      isTableMutable={isTableMutable}
      isFilterEnabled={isFilterEnabled}
      maxInsertedColumnNumber={maxInsertedColumnNumber}
      refetch={refetch}
      columnsToInsert={columnsToInsert}
      isPageTabContent={isPageTabContent}
      editFormYupResolverSchema={editFormYupResolverSchema}
      restQueryResult={queryResult}
      selectionColumnWidth={selectionColumnWidth}
      actionsColumnWidth={actionsColumnWidth}
      headerContent={pageHeader}
      className={tableClassName}
      bodyProps={{ ...bodyProps, rowActions, emptyTableText }}
      paginationProps={
        isPaginationVisible(queryResult) && isPaginated ? { className: paginationClassName, ...paginationProps } : null
      }
      headerRowProps={{
        isWhiteBackground: isHeaderRowWhiteBackground
      }}
      scrollbarOffset={scrollbarOffset}
      isTableGrey={isTableGrey}
      isHiddenSelectRowHeader={isHiddenSelectRowHeader}
      headerRef={headerRef}
    >
      {children}
    </Table>
  );
}

export default TableWithoutHookContent;
