import { useDialog } from '@libs/common/v2';

import { ShortageDialog } from '../components/shortage-dialog';

export function useShowShortageDialog() {
  const { openDialog } = useDialog();

  const showShortageDialog = (applicationId: string) => {
    openDialog(({ closeDialog }) => {
      return <ShortageDialog applicationId={applicationId} closeDialog={closeDialog} />;
    });
  };

  return { showShortageDialog };
}
