import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { FolderSnapshotsPage } from '@ibtm/domain';
import { makeStyles } from '@mui/styles';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { object } from 'yup';

import {
  AutocompleteLazyFetchSelect,
  Dialog,
  FormV2Context,
  NUMBER_OF_AUTOCOMPLETE_RESULTS,
  SelectOption,
  SelectOptionPartial,
  useTableContext
} from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';

import { API } from '@libs/domain/api';
import { DomainDictionaryEntry } from '@libs/domain/config';
import {
  AddEntrepreneuerForm,
  AssociationFormEnum,
  useAddAssociationSubjectMutation
} from '@libs/domain/entrepreneurs';
import { EditFolderParams, FolderQueryKeysEnum, useFolderDetailsQuery } from '@libs/domain/folder';

interface Props {
  open: boolean;
  setIsOpen: (param: boolean) => void;
}

function AddEntrepreneuerDialog({ open, setIsOpen }: Props) {
  const { id } = useParams<EditFolderParams>();
  const [t] = useTranslation();
  const { refetch } = useTableContext();
  const { mutateAsync: addAssociationSubject, isLoading } = useAddAssociationSubjectMutation();
  const { data: folderData } = useFolderDetailsQuery(id, { enabled: Boolean(id) });

  const [inputValue, setInputValue] = useState<SelectOption>(null);

  const classes = useStyles();

  const { control, setValue, getValues, reset } = useForm<Partial<AddEntrepreneuerForm>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(
      object({
        entrepreneurSelect: object().required()
      })
    )
  });

  const handleAdd = () => {
    const data = getValues() as { entrepreneurSelect: SelectOptionPartial; id: string };
    addAssociationSubject({
      subjectId: data?.id,
      associationId: id,
      version: folderData?.version
    }).then(() => {
      reset();
      refetch();
      setIsOpen(false);
    });
  };

  const handleInputChange = (event, selectedOption: SelectOption) => {
    if (event && !_.isEqual(selectedOption, inputValue)) {
      setInputValue(selectedOption);
      setValue(AssociationFormEnum.ENTREPRENEUR_SELECT, selectedOption);
    }
  };

  const values = useMemo(() => ({ control, setValue, getValues }), [control, setValue, getValues]);

  return (
    <FormV2Context.Provider value={values}>
      <Dialog
        title={t('associations:addEntrepreneurModalTitle')}
        confirmText={t('action.add')}
        cancelText={t('action.cancel')}
        onConfirm={() => folderData && handleAdd()}
        onCancel={() => setIsOpen(false)}
        dialogSize="small"
        isConfirmLoading={isLoading}
        isOpen={open}
      >
        <AutocompleteLazyFetchSelect
          name={AssociationFormEnum.ENTREPRENEUR_SELECT}
          label={t('associations:label.entrepreneurSelect')}
          fetchFunctionResolverWithPage={(text: string, page) => {
            return API.folder.getFoldersSnapshotPage({
              page,
              size: NUMBER_OF_AUTOCOMPLETE_RESULTS,
              subjectNameContains: text,
              excludeAssociationId: id,
              statusKeyNotIn: [DomainDictionaryEntry.FOLDER_STATUS.ARCHIVED]
            });
          }}
          queryKey={FolderQueryKeysEnum.FOLDERS_LIST_FILTER}
          fetchedDataSelectParser={(res: AxiosResponse<FolderSnapshotsPage>) => {
            const items = res.data.content?.map(folder => ({
              id: folder.subject.id,
              name: folder.subject.name,
              nip: folder.subject.nip
            }));

            return items as (SelectOption & { nip?: string })[];
          }}
          value={inputValue}
          renderOption={(props: SelectOption & { nip?: string }) => (
            <>
              {props?.name}
              <div className={classes.nip}>NIP: {props?.nip}</div>
            </>
          )}
          onChange={handleInputChange}
          isRequired
        />
      </Dialog>
    </FormV2Context.Provider>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  nip: {
    fontSize: theme.typography?.getFontSize(12),
    color: theme.palette.grey['A200'] as string
  }
}));

export default AddEntrepreneuerDialog;
