import { useTranslation } from 'react-i18next';
import { ApplicationDetails } from '@ibtm/domain';

import {
  Button,
  DictionarySelectField,
  GridLayout,
  InputMode,
  Section,
  SelectOption,
  TextInputField,
  typedNameV2,
  useFormV2Context,
  Value
} from '@libs/common/v2';

import { DictionaryValue } from '@libs/dictionary';

import { DomainDictionaryEntry, DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';

import { useChangeApplicationReciptType, useFetchPrintsApplicationDetails } from '../../hooks';

function ApplicationDetailsSection() {
  const { applicationSnapshot } = useFetchPrintsApplicationDetails();
  const [t] = useTranslation();
  const { isSubmitting, watch } = useFormV2Context();

  const { changeApplicationReciptType } = useChangeApplicationReciptType(applicationSnapshot?.id);

  const receiptTypeKey = watch(typedNameV2<ApplicationDetails>('receiptTypeKey')) as SelectOption;

  const isPickupInPerson = receiptTypeKey?.value === DomainDictionaryEntry.RECEIPT_TYPE.PICKUP_IN_PERSON;

  return (
    <Section
      title={t('prints:printsDetailsBlocks.applicationDetails')}
      isCollapsable
      headerContent={
        <Button
          type="button"
          isPrimary
          variant="outlined"
          actionKey={DomainUIElementEnum.PRINT_UPDATE_BUTTON}
          label={t('prints:actions.editApplicationDetails')}
          onClick={changeApplicationReciptType}
          isNoMargin
        />
      }
    >
      <GridLayout itemProps={{ xs: 12, sm: 6 }}>
        <Value label={t('prints:field.folderNumber')}>{applicationSnapshot?.folder?.number}</Value>

        <Value label={t('prints:field.detailsFields.folderType')}>
          <DictionaryValue
            dictionaryEntryType={DomainDictionaryEnum.FOLDER_TYPE}
            value={applicationSnapshot?.folder?.typeKey}
          />
        </Value>

        <Value label={t('prints:field.detailsFields.applicationNumber')}>{applicationSnapshot?.number}</Value>

        <Value label={t('prints:field.detailsFields.typeKey')}>
          <DictionaryValue
            dictionaryEntryType={DomainDictionaryEnum.APPLICATION_TYPE}
            value={applicationSnapshot?.typeKey}
          />
        </Value>

        <DictionarySelectField
          isDisabled={isSubmitting}
          name={typedNameV2<ApplicationDetails>('receiptTypeKey')}
          label={t('prints:formField.deliveryType')}
          dictionaryName={DomainDictionaryEnum.RECEIPT_TYPE}
          inputMode={InputMode.VIEW}
        />
        {!isPickupInPerson && (
          <TextInputField
            name={typedNameV2<ApplicationDetails>('sendToAddress')}
            label={t('prints:formField.sendToAddress')}
            inputMode={InputMode.VIEW}
          />
        )}
        {isPickupInPerson && (
          <TextInputField
            name={typedNameV2<ApplicationDetails>('recipientName')}
            label={t('prints:formField.recipientName')}
            inputMode={InputMode.VIEW}
          />
        )}
        {isPickupInPerson && (
          <TextInputField
            name={typedNameV2<ApplicationDetails>('recipientSurname')}
            label={t('prints:formField.recipientSurname')}
            inputMode={InputMode.VIEW}
          />
        )}
        <Value label={t('prints:field.orgAttendant')}>{applicationSnapshot?.organizationUnit?.name}</Value>
      </GridLayout>
    </Section>
  );
}
export default ApplicationDetailsSection;
