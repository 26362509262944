import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'lodash';

import {
  InputMatrixField,
  InputMode,
  MatrixCellInputTypeEnum,
  Scrollbar,
  Section,
  useFormV2Context
} from '@libs/common/v2';
import { IRowInput, TMatrixInputCell } from '@libs/common/v2/form/components/InputMatrix/model';

import { useContextSelector } from '@libs/dictionary';
import { useElementVisibility } from '@libs/permission';

import { DomainDictionaryEntry, DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';

import useTransitFrequenciesQuery from '../api/queries/useTransitFrequenciesQuery';
import { TransitScheduleContext } from '../pages';
import {
  NUMBER_OF_ALL_CHECKBOXES,
  NUMBER_OF_ALMOST_FULL_WEEK_DAYS,
  ONE_WAY_MATRIX_TITLE,
  RETURN_MATRIX_TITLE,
  ROUND_TRIP_MATRIX_TITLE
} from '../utils';

interface IProps {
  transitDataId: string;
}

export interface IFrequencies {
  frequencies?: {
    transitScheduleId?: string;
    transitScheduleVersion?: number;
  };
}

function TransitScheduleMatrix({ transitDataId }: IProps) {
  const [t] = useTranslation();
  const { reset } = useFormV2Context();
  const { transitScheduleId, transitScheduleVersion, fieldId } = useContext(TransitScheduleContext);
  const { checkIsElementVisible } = useElementVisibility();

  const { data: transitFrequencyItems } = useTransitFrequenciesQuery(transitDataId);

  const dictionaryEntries = useContextSelector(({ context }) => context.context.data.dictionary);
  const dictionaryDirections = dictionaryEntries?.[DomainDictionaryEnum.TRANSIT_DIRECTION]?.entries;

  const frequencyTypes = useMemo(
    () => [
      DomainDictionaryEntry.FREQUENCY_TYPE.DAILY,
      DomainDictionaryEntry.FREQUENCY_TYPE.MONDAY,
      DomainDictionaryEntry.FREQUENCY_TYPE.TUESDAY,
      DomainDictionaryEntry.FREQUENCY_TYPE.WEDNESDAY,
      DomainDictionaryEntry.FREQUENCY_TYPE.THURSDAY,
      DomainDictionaryEntry.FREQUENCY_TYPE.FRIDAY,
      DomainDictionaryEntry.FREQUENCY_TYPE.SATURDAY,
      DomainDictionaryEntry.FREQUENCY_TYPE.SUNDAY
    ],
    []
  );
  const transitFrequencyItemsPath = 'application-transit-schedule-collection.frequencies';

  // Dane z backend wstawione do form
  useEffect(() => {
    if (transitFrequencyItems?.frequencies?.find(item => item.frequencyItems?.length > 0)) {
      const data = transitFrequencyItems?.frequencies?.reduce(
        (acc, item) => ({
          ...acc,
          [item.directionTypeKey?.split('.').slice(-1)[0]]: item.frequencyItems
        }),
        {}
      );
      reset(
        { [fieldId]: { frequencies: { ...data, transitScheduleId, transitScheduleVersion } } },
        { keepDirty: true }
      );
    } else {
      reset({ [fieldId]: { frequencies: { transitScheduleId, transitScheduleVersion } } }, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transitFrequencyItems]);

  const headers = [
    {
      name: { title: DomainDictionaryEntry.FREQUENCY_TYPE.DAILY },
      width: 100
    },
    {
      name: { title: DomainDictionaryEntry.FREQUENCY_TYPE.MONDAY },
      width: 100
    },
    {
      name: { title: DomainDictionaryEntry.FREQUENCY_TYPE.TUESDAY },
      width: 100
    },
    {
      name: { title: DomainDictionaryEntry.FREQUENCY_TYPE.WEDNESDAY },
      width: 100
    },
    {
      name: { title: DomainDictionaryEntry.FREQUENCY_TYPE.THURSDAY },
      width: 100
    },
    {
      name: { title: DomainDictionaryEntry.FREQUENCY_TYPE.FRIDAY },
      width: 100
    },
    {
      name: { title: DomainDictionaryEntry.FREQUENCY_TYPE.SATURDAY },
      width: 100
    },
    {
      name: { title: DomainDictionaryEntry.FREQUENCY_TYPE.SUNDAY },
      width: 100
    }
  ];

  const rowTitles =
    dictionaryDirections?.map(entry => ({
      title: entry.value,
      id: entry.value.split('.').slice(-1)[0]
    })) ?? [];

  const rowInput = useCallback(
    ({ rowTitle, rowData, allData }: IRowInput): TMatrixInputCell[] => {
      const roundTripRowData = allData?.[ROUND_TRIP_MATRIX_TITLE] as string[];
      const returnRowData = allData?.[RETURN_MATRIX_TITLE] as string[];
      const oneWayRowData = allData?.[ONE_WAY_MATRIX_TITLE] as string[];

      const hasRoundTripRowData = !isNil(roundTripRowData) && !isEmpty(roundTripRowData);
      const hasReturnRowData = !isNil(returnRowData) && !isEmpty(returnRowData);
      const hasOneWayRowData = !isNil(oneWayRowData) && !isEmpty(oneWayRowData);

      const isRoundTripDailyCellDisabled = () => {
        return hasReturnRowData || hasOneWayRowData;
      };

      const areRoundTripRestCellsDisabled = (item: string) => {
        return (
          (hasReturnRowData && returnRowData?.includes(item)) || (hasOneWayRowData && oneWayRowData?.includes(item))
        );
      };

      const isReturnOrOneWayDailyCellDisabled = () => {
        return hasRoundTripRowData;
      };

      const areReturnOrOneWayRestCellsDisabled = (item: string) => {
        return hasRoundTripRowData && roundTripRowData?.includes(item);
      };

      return frequencyTypes?.map(item => {
        if (item === DomainDictionaryEntry.FREQUENCY_TYPE.DAILY) {
          return {
            type: MatrixCellInputTypeEnum.CHECKBOX,
            value: [item],
            isDisabled:
              rowTitle?.title === DomainDictionaryEntry.TRANSIT_DIRECTION.ROUND_TRIP
                ? isRoundTripDailyCellDisabled()
                : isReturnOrOneWayDailyCellDisabled(),
            inputProps: {
              inputMode: checkIsElementVisible(DomainUIElementEnum.APPLICATION_TRANSIT_SCHEDULE_FREQUENCY)
                ? null
                : InputMode.VIEW,
              getOnChangeValues: () => {
                return rowData?.length === NUMBER_OF_ALL_CHECKBOXES ? [] : frequencyTypes;
              }
            }
          };
        }

        return {
          type: MatrixCellInputTypeEnum.CHECKBOX,
          value: [item],
          isDisabled:
            rowTitle?.title === DomainDictionaryEntry.TRANSIT_DIRECTION.ROUND_TRIP
              ? areRoundTripRestCellsDisabled(item)
              : areReturnOrOneWayRestCellsDisabled(item),
          inputProps: {
            inputMode: checkIsElementVisible(DomainUIElementEnum.APPLICATION_TRANSIT_SCHEDULE_FREQUENCY)
              ? null
              : InputMode.VIEW,

            ...(rowData?.length === NUMBER_OF_ALL_CHECKBOXES &&
              rowData?.includes(DomainDictionaryEntry.FREQUENCY_TYPE.DAILY) && {
                getOnChangeValues: () => {
                  rowData?.filter((rowDataItem: string) => {
                    return rowDataItem !== DomainDictionaryEntry.FREQUENCY_TYPE.DAILY && item !== rowDataItem;
                  });
                }
              }),
            ...(rowData?.length === NUMBER_OF_ALMOST_FULL_WEEK_DAYS &&
              !rowData?.includes(DomainDictionaryEntry.FREQUENCY_TYPE.DAILY) && {
                getOnChangeValues: () => {
                  return frequencyTypes;
                }
              })
          }
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [frequencyTypes]
  );

  return (
    <Section title={t('applications:transitSchedule.blocks.frequencies')} isPadded={false}>
      <Scrollbar options={{ suppressScrollY: true }}>
        <InputMatrixField
          headers={headers}
          rowTitles={rowTitles}
          headerDictionary={DomainDictionaryEnum.FREQUENCY_TYPE}
          rowDictionary={DomainDictionaryEnum.TRANSIT_DIRECTION}
          rowInputs={rowInput}
          formPrefix={transitFrequencyItemsPath}
          formValuesPath={transitFrequencyItemsPath}
          isWhiteBackground
        />
      </Scrollbar>
    </Section>
  );
}

export default TransitScheduleMatrix;
