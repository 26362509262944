import { PermissionTypeCode, ResourceObjectLite } from '@ibtm/domain';

import { PrintActionType, usePrintsActionsContext } from '../context';
import { PrintablePermissionsUnion, ReassignmentSelectValue } from '../model';

interface IParams {
  data: PrintablePermissionsUnion;
  isMultipleFormNumberToRegenerateInRow?: boolean;
  permissionTypeCode: PermissionTypeCode;
  applicationId: string;
  onSuccess: () => void;
}

export function usePrintsRegenerateAction() {
  const { setAction } = usePrintsActionsContext();

  const handlePrintRegenerate = ({
    isMultipleFormNumberToRegenerateInRow,
    data,
    permissionTypeCode,
    applicationId,
    onSuccess
  }: IParams) => {
    const parseResourceSelectValue = (resource: ResourceObjectLite): ReassignmentSelectValue => ({
      applicationId,
      resourceObjectId: resource?.id
    });

    if (isMultipleFormNumberToRegenerateInRow) {
      const resourceList = data.forms || [];
      setAction(PrintActionType.RegenerateMultipleFormNumbers, {
        resourcesReassignments: {
          permissionTypeCode,
          resources: resourceList.map(parseResourceSelectValue)
        },
        resourcesReassignmentsSelectOptions: resourceList.map(resource => ({
          name: resource.number.toString(),
          value: parseResourceSelectValue(resource)
        })),
        onSuccess
      });
    } else {
      setAction(PrintActionType.Regenerate, {
        resourcesReassignments: {
          permissionTypeCode,
          resources: [parseResourceSelectValue(data.form)]
        },
        onSuccess
      });
    }
  };
  return { handlePrintRegenerate };
}
