import { useMemo } from 'react';

import { useFormV2Context } from '../context';

function useFormV2DirtyWatch() {
  const { isDirty: isFormDirty, formState } = useFormV2Context();

  const hasDirtyFields = formState?.dirtyFields ? !!Object.keys(formState?.dirtyFields).length : false;
  const isDirty = useMemo(() => {
    return isFormDirty && hasDirtyFields;
  }, [isFormDirty, hasDirtyFields]);

  return { isDirty };
}

export default useFormV2DirtyWatch;
