import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';
import { Box, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ChangeFontSizeButton, Divider, Form, ToggleThemeButton, Typography } from '@libs/common/v2';
import { Theme, useThemeValuesContext } from '@libs/common/v2/theme';
import { hexToRgba, important } from '@libs/common/v2/utils';

import { Footer } from '../../../../layout';
import { LoginPageValues } from '../LoginPage';

interface IProps {
  logoImageUrl: string;
  applicationWelcomeText: string;
  applicationWelcomeTextSecond: string;
  applicationDescription: string;
  customInitialValuesTest: LoginPageValues;
  isLoading: boolean;
  showForgotPasswordButton: boolean;
  showWebContentAccessibilityGuidelines: boolean;
  backgroundImageUrl: string;
  handleSubmit: (values) => void;
  customForgotPasswordLabel?: string;
  setRecaptchaField?: (ref: ReCAPTCHA) => void;
  isLoginFailed?: boolean;
  logo?: React.ReactElement<React.FunctionComponent<React.SVGProps<SVGSVGElement>>>;
  additionalButton?: React.ReactElement;
  isLoggedIn?: boolean;
}

function SidebarView({
  logoImageUrl,
  applicationWelcomeText,
  applicationWelcomeTextSecond,
  applicationDescription,
  customInitialValuesTest,
  isLoading,
  showForgotPasswordButton,
  showWebContentAccessibilityGuidelines,
  backgroundImageUrl,
  handleSubmit,
  customForgotPasswordLabel,
  setRecaptchaField,
  isLoginFailed,
  logo,
  additionalButton,
  isLoggedIn
}: IProps) {
  const classes = useStyles({ backgroundImageUrl, isLoggedIn });
  const [t] = useTranslation();
  const { width } = useWindowSize();
  const globalTheme = useThemeValuesContext();
  const isMobile = width < globalTheme?.breakpoints?.values?.md;

  return (
    <div className={classes.root}>
      <Grid container spacing={0} className="h-full">
        <Grid item xs={false} sm={false} md={9} lg={9} display={{ xs: 'none', md: 'block' }}>
          <div className={classes.background}>
            <div className={classes.backgroundBlur}>
              <div className={classes.welcomeSection}>
                <div className={classes.welcomeLogoSection}>
                  <img src={logoImageUrl} alt={t('global:mainLogo')} className={classes.welcomeLogo} />
                </div>
                <div className={classes.welcomeText}>
                  <Typography
                    themeVariant="displayMd.semibold"
                    customStyles={{
                      marginBottom: '20px'
                    }}
                    isWhiteText
                  >
                    {applicationWelcomeText}
                    <br />
                    {applicationWelcomeTextSecond}
                  </Typography>
                  <Typography
                    component="h2"
                    themeVariant="displayXs.medium"
                    customStyles={{
                      textAlign: 'left',
                      maxWidth: 860
                    }}
                    isWhiteText
                  >
                    {applicationDescription}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={false} display={{ xs: 'block', md: 'none' }} padding={2}>
          <Typography
            component="h2"
            themeVariant="displayMd.bold"
            style={{ marginBottom: 0, marginTop: 0, fontSize: '2.1rem', lineHeight: '2.1rem' }}
          >
            Portal Klienta iBTM
          </Typography>
          <Typography
            component="h5"
            themeVariant="displayXs.normal"
            style={{ marginTop: '6px', marginBottom: '12px', fontSize: '1.4rem', lineHeight: '1.6rem' }}
          >
            Główny Inspektorat Transportu Drogowego
          </Typography>
          <Divider />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          component={Paper}
          elevation={isMobile ? 0 : 1}
          className={classes.loginSection}
          square
        >
          <Box
            sx={{
              my: 5,
              mx: 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <div className={classes.logoIcon}>{logo}</div>
            <Typography themeVariant="displaySm.semibold" customStyles={{ textAlign: 'center', marginBottom: '12px' }}>
              {t('login:form.signIn')}
            </Typography>
            <Typography themeVariant="textMd.normal" customStyles={{ textAlign: 'center', marginBottom: '32px' }}>
              {t('login:form.signInHelperText')}
            </Typography>
            <Form
              customInitialValuesTest={customInitialValuesTest}
              isLoading={isLoading}
              showForgotPasswordButton={showForgotPasswordButton}
              handleSubmit={handleSubmit}
              customForgotPasswordLabel={customForgotPasswordLabel}
              additionalButton={additionalButton}
              setRecaptchaField={setRecaptchaField}
              isLoginFailed={isLoginFailed}
            />
            {showWebContentAccessibilityGuidelines && (
              <div className={classes.webContentAccessibilityGuidelines}>
                <ToggleThemeButton />
                <ChangeFontSizeButton />
              </div>
            )}
          </Box>
        </Grid>

        {isMobile && <Footer isMobile />}
      </Grid>
    </div>
  );
}

const useStyles = makeStyles<Theme, { backgroundImageUrl: any; isLoggedIn: boolean }>(theme => ({
  root: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%'
  },
  background: {
    width: '100%',
    height: '100%',
    backgroundImage: ({ backgroundImageUrl }) => `url(${backgroundImageUrl})`,
    backgroundPosition: 'stretch',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundBlendMode: 'multiply',
    position: 'relative',
    zIndex: 1
  },
  backgroundBlur: {
    width: '100%',
    height: '100%',
    background: hexToRgba(theme.palette.grey[800], 0.6),
    backdropFilter: 'blur(1px)'
  },
  logoIcon: {
    '& > svg': {
      width: 58,
      height: 58,
      justifySelf: 'center',
      paddingBottom: '20px'
    }
  },
  welcomeSection: {
    display: 'flex',
    padding: 25,
    boxSizing: 'border-box',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
    paddingBottom: ({ isLoggedIn }) => (isLoggedIn ? 0 : 62),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: important(25)
    }
  },
  welcomeLogoSection: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      height: 'auto'
    }
  },
  welcomeLogo: {
    padding: 25,
    height: '200px',
    width: '200px'
  },
  welcomeText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  webContentAccessibilityGuidelines: {
    display: 'flex',
    marginTop: 25
  },
  loginSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: ({ isLoggedIn }) => (isLoggedIn ? '0' : 62),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: important(0)
    }
  }
}));

export default SidebarView;
