import { PermanentPermissionSubmissionUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function applicationEdit(requestData: {
  permanentSubmissionId: string;
  requestData: PermanentPermissionSubmissionUpdateRequest;
}) {
  return API.civicBoard.updatePermanentPermissionSubmission(
    requestData.permanentSubmissionId,
    requestData.requestData,
    { ...endpointsConfig.updatePermanentPermissionSubmission }
  );
}

function useEditApplicationEKMTMutation(config) {
  return useMutation(applicationEdit, config);
}

export default useEditApplicationEKMTMutation;
