import { useTranslation } from 'react-i18next';
import { DefaultApiUpdateReplacementRequest } from '@avispon/group';
import { useSnackbar } from '@enigma/fe-ui';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '@libs/user/api';

interface IMutationParams {
  setIsOpen: (value: boolean) => void;
  refetch: () => void;
}

function editReplacement(params: DefaultApiUpdateReplacementRequest) {
  return API.group.updateReplacement({ ...params }, { ...endpointsConfig.updateReplacement });
}

function useEditReplacementsMutation({ setIsOpen, refetch }: IMutationParams) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  return useMutation(editReplacement, {
    onSuccess: () => {
      showSuccessSnackbar(t('user:message.editReplacement'));
      setIsOpen(false);
      refetch();
    }
  });
}

export default useEditReplacementsMutation;
