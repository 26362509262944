import { ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PluginHook } from 'react-table';
import { TransportManagersSearchFilter as TransportManagersSearchFilterClient } from '@ibtm/client-domain';
import { TransportManagersSearchFilter } from '@ibtm/domain';
import { useAppConfig } from '@libs/app-config';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import { Button, CamelCasePath, TableLayout } from '@libs/common/v2';

import { UiMode } from '@libs/meta-form';
import { useMetaFormContext } from '@libs/meta-form/context';
import { useElementVisibility } from '@libs/permission';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { checkIsPB1CApplicationAndTPOrOPFolder, useApplicationDetailsQuery } from '@libs/domain/application';
import { DomainDictionaryEntry, DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import { TransportManagerListItemClient, TransportManagersActionButtonsEnum } from '@libs/domain/transport-manager';

import {
  useTransportManagerDetailsDialog,
  useTransportManagerSearchAction,
  useTransportManagersTable
} from '../../hooks';
import useTransportManagersTableRowActions from '../../hooks/useTransportManagersTableRowActions';

import TransportManagersSearchButton from './TransportManagersSearchButton';

interface IPermissionsObject {
  [TransportManagersActionButtonsEnum.ADD]?: DomainUIElementEnum;
  [TransportManagersActionButtonsEnum.SEARCH]?: DomainUIElementEnum;
  [TransportManagersActionButtonsEnum.EDIT]?: DomainUIElementEnum;
  [TransportManagersActionButtonsEnum.DELETE]?: DomainUIElementEnum;
  [TransportManagersActionButtonsEnum.EXPIRE]?: DomainUIElementEnum;
  [TransportManagersActionButtonsEnum.PREVIEW]?: DomainUIElementEnum;
  [TransportManagersActionButtonsEnum.RESTORE]?: DomainUIElementEnum;
  [TransportManagersActionButtonsEnum.SHOW_REPUTATION]?: DomainUIElementEnum;
  [TransportManagersActionButtonsEnum.CHECK_REPUTATION]?: DomainUIElementEnum;
}

type IProps = {
  folderId?: string;
  transferredFoldersIds?: string[];
  children?: ReactNode;
  applicationId?: string;
  visibleActionButtons?: TransportManagersActionButtonsEnum[];
  tablePlugins?: PluginHook<Record<string, any>>[];
  tableData?: PluginHook<Record<string, any>>[];
  queryParams?: TransportManagersSearchFilter | TransportManagersSearchFilterClient;
  visibleColumns: Array<CamelCasePath<TransportManagerListItemClient>>;
  isHeaderHidden?: boolean;
  isActionColumnEnabled?: boolean;
  permissionsKeysObject?: IPermissionsObject;
  folderType?: string;
  isCollapsable?: boolean;
  selectionColumnWidth?: number;
  actionKey?: UIElementNameEnum;
  isHiddenSelectRowHeader?: boolean;
};

function TransportManagersTable({
  applicationId,
  folderId,
  transferredFoldersIds,
  visibleActionButtons,
  visibleColumns,
  isHeaderHidden,
  queryParams,
  tablePlugins,
  tableData,
  isActionColumnEnabled = true,
  children,
  folderType,
  permissionsKeysObject,
  isCollapsable,
  selectionColumnWidth,
  actionKey,
  isHiddenSelectRowHeader
}: IProps) {
  const { data } = useApplicationDetailsQuery(applicationId, {
    enabled: Boolean(applicationId)
  });
  const { isClientPortal } = useDomainConfigContext();

  const { generateApplicationPrintouts } = useAppConfig();
  const isVehiclesAndSubjectsManagementSectionVisible =
    !!data && isClientPortal && checkIsPB1CApplicationAndTPOrOPFolder(data) && generateApplicationPrintouts;
  const managerModificationTypeKey = data?.licenseApplicationDetails?.transportManagerModificationTypeKey;
  const [t] = useTranslation();
  const { getQuery } = useGetApiQueryByPerspective();
  const { openDetailsDialog } = useTransportManagerDetailsDialog();

  const { openTransportManagersSearchModal } = useTransportManagerSearchAction({
    folderId,
    applicationId,
    transferredFoldersIds
  });

  const tableProps = useTransportManagersTable({ visibleColumns, queryParams, tablePlugins, tableData });
  const { checkIsElementVisible } = useElementVisibility();
  const { visibleButtons } = useTransportManagersTableRowActions({
    applicationId,
    folderId,
    managerModificationTypeKey,
    permissionsKeysObject,
    transferredFoldersIds,
    visibleActionButtons,
    isVehiclesAndSubjectsManagementSectionVisible
  });

  const { mode } = useMetaFormContext();

  const openDetails = () =>
    openDetailsDialog({
      applicationId,
      folderId,
      folderType,
      transferredFoldersIds,
      managerModificationTypeKey,
      isVehiclesAndSubjectsManagementSectionVisible,
      permissionsKeysObject: {
        CHECK_REPUTATION: permissionsKeysObject?.[TransportManagersActionButtonsEnum.CHECK_REPUTATION],
        SHOW_REPUTATION: permissionsKeysObject?.[TransportManagersActionButtonsEnum.SHOW_REPUTATION]
      }
    });

  const isButtonVisible = (buttonName: TransportManagersActionButtonsEnum) =>
    visibleActionButtons?.includes(buttonName);

  const isSearchButtonVisible = useMemo(() => {
    const hasPermissions = permissionsKeysObject?.[TransportManagersActionButtonsEnum.SEARCH]
      ? checkIsElementVisible(permissionsKeysObject?.[TransportManagersActionButtonsEnum.SEARCH])
      : true;
    return hasPermissions && mode !== UiMode.VIEW && isButtonVisible(TransportManagersActionButtonsEnum.SEARCH);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkIsElementVisible, permissionsKeysObject, mode]);

  const isAddButtonVisible = mode !== UiMode.VIEW && isButtonVisible(TransportManagersActionButtonsEnum.ADD);

  const xlsxDownloadApi = useCallback(
    params => {
      const apiMethod = getQuery(
        () => API.client.transportManager.getTransportManagersSnapshotPage('', '', params),
        () => API.transportManager.getTransportMangersSnapshotPage(params)
      );

      return apiMethod();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isSearchButtonDisabled =
    data?.typeKey === DomainDictionaryEntry.APPLICATION_TYPE.PB10B &&
    managerModificationTypeKey !== DomainDictionaryEntry.APPLICATION_TRANSPORT_MANAGER_MODIFICATION_TYPE.SUBMISSION;

  const mobileHeaderActions = [
    {
      label: t('transportManager:search.title'),
      isHidden: !isSearchButtonVisible || isSearchButtonDisabled,
      onClick: openTransportManagersSearchModal,
      actionKey: permissionsKeysObject?.[TransportManagersActionButtonsEnum.SEARCH]
    },
    {
      label: t('action.add'),
      actionKey: permissionsKeysObject?.[TransportManagersActionButtonsEnum.ADD],
      onClick: openDetails,
      isHidden: !isAddButtonVisible
    }
  ];

  return (
    <div>
      <TableLayout
        {...tableProps}
        isSection
        actionKey={actionKey}
        xlsxDownload={{
          fileName: t('transportManager:list.title'),
          pathToXLSXTranslation: 'transportManager:field',
          apiRequest: xlsxDownloadApi
        }}
        rowActions={visibleButtons}
        pageTitle={t('transportManager:title')}
        mobileHeaderActions={mobileHeaderActions}
        headerActions={
          <>
            {isSearchButtonVisible && (
              <TransportManagersSearchButton
                applicationId={applicationId}
                folderId={folderId}
                actionKey={permissionsKeysObject?.[TransportManagersActionButtonsEnum.SEARCH]}
                transferredFoldersIds={transferredFoldersIds}
                isDisabled={isSearchButtonDisabled}
                disabledTooltip={t('transportManager:details.tooltips.searchTransportManagerButtonDisabled')}
              />
            )}
            {isAddButtonVisible && (
              <Button
                onClick={openDetails}
                label={t('action.add')}
                variant="outlined"
                actionKey={permissionsKeysObject?.[TransportManagersActionButtonsEnum.ADD]}
                isNoMargin
                classNameWrapper="ml-16"
              />
            )}
          </>
        }
        isActionColumnEnabled={isActionColumnEnabled}
        isHeaderHidden={isHeaderHidden}
        isCollapsable={isCollapsable}
        selectionColumnWidth={selectionColumnWidth}
        isRefreshEnabled
        isHiddenSelectRowHeader={isHiddenSelectRowHeader}
      >
        {children}
      </TableLayout>
    </div>
  );
}

export default TransportManagersTable;
