import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ProceedingDetails } from '@ibtm/domain';
import { CalendarDate } from 'calendar-date';
import { isEmpty, isNil } from 'lodash';

import { partialTranslate } from '@libs/common';
import {
  Button,
  DatepickerField,
  DictionarySelectField,
  GridLayout,
  InputMode,
  SectionWithFields,
  TextInputField,
  typedNameV2,
  useFormV2Context,
  useFormV2Watch
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';
import { convertDateToDateFormat } from '@libs/common/v2/utils';

import { DomainDictionaryEntry, DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';
import { ProceedingDetailsParams, ProceedingTypeKeyEnum } from '@libs/domain/proceeding';

import { useDropdownActions } from './Header/hooks/useDropdownActions';

const getLabel = partialTranslate('proceeding:administrative.tab.procedureDetails.field');

export default function ProcedureDetails() {
  const [t] = useTranslation();
  const { formMode, setValue, clearErrors } = useFormV2Context();
  const { viewMode, createMode } = useViewModesV2(formMode);

  const reviewApplicationNumberValue = useFormV2Watch<string>({
    name: typedNameV2<ProceedingDetails>('reviewApplicationNumber')
  });
  const reviewApplicationDateValue = useFormV2Watch<CalendarDate>({
    name: typedNameV2<ProceedingDetails>('reviewApplicationDate')
  });

  const { proceedingTypeKey } = useParams<ProceedingDetailsParams>();

  const { handleChangeConductingPerson } = useDropdownActions();

  const isAdministrativeProceeding = useMemo(() => {
    return proceedingTypeKey === ProceedingTypeKeyEnum.ADMINISTRATION;
  }, [proceedingTypeKey]);

  const hasReviewApplicationNumberValue = useMemo(
    () => !isEmpty(reviewApplicationNumberValue),
    [reviewApplicationNumberValue]
  );
  const hasReviewApplicationDateValue = useMemo(
    () => !isNil(reviewApplicationDateValue) && !isEmpty(reviewApplicationDateValue),
    [reviewApplicationDateValue]
  );

  useEffect(() => {
    if (!hasReviewApplicationDateValue && !hasReviewApplicationNumberValue) {
      clearErrors('reviewApplicationNumber');
      clearErrors('reviewApplicationDate');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasReviewApplicationDateValue, hasReviewApplicationNumberValue]);

  useEffect(() => {
    if (createMode && proceedingTypeKey === ProceedingTypeKeyEnum.ADMINISTRATION) {
      setValue('categoryKey', DomainDictionaryEntry.PROCEEDING_TYPE.ADMINISTRATIVE);
    }

    if (createMode && proceedingTypeKey === ProceedingTypeKeyEnum.VERIFICATION) {
      setValue('categoryKey', DomainDictionaryEntry.PROCEEDING_TYPE.VERIFICATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proceedingTypeKey, createMode]);

  return (
    <SectionWithFields
      title={t('proceeding:administrative.tab.procedureDetails.title')}
      headerContent={
        !viewMode && (
          <Button
            variant="outlined"
            label={t('proceeding:administrative.tab.procedureDetails.action.changeConductingPerson')}
            actionKey={DomainUIElementEnum.PROCEEDING_EDIT_CHANGE_CONDUCTING_PERSON_BUTTON}
            onClick={handleChangeConductingPerson}
            isSecondary
            isNoMargin
          />
        )
      }
      fields={[
        <TextInputField
          name={typedNameV2<ProceedingDetails>('caseNumber')}
          label={getLabel('caseNumber')}
          inputMode={InputMode.VIEW}
          key="caseNumber"
        />,
        <TextInputField
          name={typedNameV2<ProceedingDetails>('number')}
          label={getLabel('number')}
          inputMode={InputMode.VIEW}
          key="number"
        />,
        <DatepickerField
          name={typedNameV2<ProceedingDetails>('created')}
          label={getLabel('created')}
          inputMode={InputMode.VIEW}
          viewModeDateParser={convertDateToDateFormat}
          key="created"
        />,
        <DictionarySelectField
          dictionaryName={DomainDictionaryEnum.PROCEEDING_STATUS}
          name={typedNameV2<ProceedingDetails>('statusKey')}
          label={getLabel('statusKey')}
          inputMode={InputMode.VIEW}
          key="statusKey"
        />,
        <TextInputField
          name={typedNameV2<ProceedingDetails>('assignedTo')}
          label={getLabel('conductingPerson')}
          inputMode={InputMode.VIEW}
          getValueFormat={assignedTo => assignedTo?.name}
          key="assignedTo"
        />,
        <DatepickerField
          name={typedNameV2<ProceedingDetails>('modified')}
          label={getLabel('modified')}
          viewModeDateParser={convertDateToDateFormat}
          inputMode={InputMode.VIEW}
          key="modified"
        />,
        <DictionarySelectField
          dictionaryName={DomainDictionaryEnum.TYPES_OF_PROCEEDINGS}
          name={typedNameV2<ProceedingDetails>('typeKey')}
          label={getLabel('typeKey')}
          isRequired
          key="typeKey"
          stringValue
        />,
        <DictionarySelectField
          dictionaryName={DomainDictionaryEnum.LEGAL_GROUNDS_FOR_PROCEEDINGS}
          name={typedNameV2<ProceedingDetails>('decisionTypeKey')}
          label={getLabel('decisionKey')}
          key="decisionKey"
          stringValue
        />,
        ...(createMode
          ? [<div key="emptyField" />]
          : [
              <DatepickerField
                name={typedNameV2<ProceedingDetails>('startDecisionDelivered')}
                label={getLabel('startDecisionDelivered')}
                key="startDecisionDelivered"
              />
            ]),
        ...(isAdministrativeProceeding
          ? [
              <GridLayout key="reviewGroup" containerProps={{ className: 'grid' }}>
                <TextInputField
                  name={typedNameV2<ProceedingDetails>('reviewApplicationNumber')}
                  label={getLabel('rehearingNumber')}
                  inputProps={{ maxLength: 20 }}
                  key="rehearingNumber"
                  isRequired={hasReviewApplicationDateValue}
                />
                <DatepickerField
                  name={typedNameV2<ProceedingDetails>('reviewApplicationDate')}
                  label={getLabel('rehearingData')}
                  isFullWidth
                  key="rehearingData"
                  isRequired={hasReviewApplicationNumberValue}
                />
              </GridLayout>,
              <TextInputField
                name={typedNameV2<ProceedingDetails>('legalOfficeDecision')}
                label={getLabel('bpDecision')}
                lines={4}
                key="bpDecision"
              />,
              <DatepickerField
                name={typedNameV2<ProceedingDetails>('legalOfficeDecisionDate')}
                label={getLabel('bpDecisionDate')}
                key="bpDecisionDate"
              />,
              <TextInputField
                name={typedNameV2<ProceedingDetails>('wsaComplaint.signatureNumber')}
                label={getLabel('WSAComplaintNumber')}
                inputProps={{ maxLength: 20 }}
                key="WSAComplaintNumber"
              />,
              <DictionarySelectField
                dictionaryName={DomainDictionaryEnum.WSA_DECISION}
                name={typedNameV2<ProceedingDetails>('wsaComplaint.resultKey')}
                label={getLabel('WSAComplaintSettlement')}
                key="WSAComplaintSettlement"
                stringValue
              />,
              <DatepickerField
                name={typedNameV2<ProceedingDetails>('wsaComplaint.complaintDate')}
                label={getLabel('WSAComplaintDate')}
                key="WSAComplaintDate"
              />,
              <TextInputField
                name={typedNameV2<ProceedingDetails>('nsaComplaint.signatureNumber')}
                label={getLabel('NSAComplaintNumber')}
                inputProps={{ maxLength: 20 }}
                key="NSAComplaintNumber"
              />,
              <DictionarySelectField
                dictionaryName={DomainDictionaryEnum.NSA_DECISION}
                name={typedNameV2<ProceedingDetails>('nsaComplaint.resultKey')}
                label={getLabel('NSAComplaintSettlement')}
                key="NSAComplaintSettlement"
                stringValue
              />,
              <DatepickerField
                name={typedNameV2<ProceedingDetails>('nsaComplaint.complaintDate')}
                label={getLabel('NSAComplaintDate')}
                key="NSAComplaintDate"
              />
            ]
          : [])
      ]}
    />
  );
}
