import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '..';

const removeReportType = (reportTypeId: string) => {
  return API.reportTypes.deleteReportType(reportTypeId, {
    ...endpointsConfig.deleteReportType
  });
};

export default function useRemoveReportTypeMutation() {
  return useMutation(removeReportType);
}
