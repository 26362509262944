import { ColumnTypesEnum, useCreateColumns } from '@libs/common/v2';

import { TransitDateDetailsWithId } from '../model';

const useTransitDatesColumns = () => {
  const { createColumns } = useCreateColumns<TransitDateDetailsWithId>({
    pathToTranslate: 'applications:transitSchedule.fields'
  });

  const columns = createColumns([
    {
      id: 'departure',
      header: 'departureDate',
      type: ColumnTypesEnum.DATE,
      isSortable: false
    },
    {
      id: 'arrival',
      header: 'arrivalDate',
      type: ColumnTypesEnum.DATE,
      isSortable: false
    }
  ]);

  return { columns };
};

export default useTransitDatesColumns;
