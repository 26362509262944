import { useCallback } from 'react';

import { useDialog } from '@libs/common/v2';

import { ApplicationsToArchiveDialog } from '../../components/common';

function useSendApplicationsToArchiveDialog() {
  const { openDialog } = useDialog();

  const showDialog = useCallback(
    (ids: string[]) => {
      openDialog(({ closeDialog }) => <ApplicationsToArchiveDialog ids={ids} closeDialog={closeDialog} />);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog]
  );

  const openSendApplicationsToArchiveDialog = (ids: string[]) => {
    showDialog(ids);
  };

  return {
    openSendApplicationsToArchiveDialog
  };
}

export default useSendApplicationsToArchiveDialog;
