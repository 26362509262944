import { useTranslation } from 'react-i18next';
import { useAppConfig } from '@libs/app-config';

import { TableButtonColumnPicker, TableIconButton, TableLayout, useTableRowActions } from '@libs/common/v2';

import { API } from '@libs/user/api';
import { useGroupPermissionDetailsDialog, usePermissionGroupsTable } from '@libs/user/hooks';

import { UserUIElementEnum } from '../../../config/permission';
import { parsePermissionGroupsPayload } from '../../../utils';

function PermissionGroupsTab() {
  const [t] = useTranslation();

  const tableProps = usePermissionGroupsTable();

  const { hideColumnPicker } = useAppConfig();
  const { showGroupPermissionDetailsDialog } = useGroupPermissionDetailsDialog();

  const { renderTableActions } = useTableRowActions([
    {
      id: 'ACCOUNT_SHOW_PERMISSIONS_BUTTON',
      customButton: ({ original }) => (
        <TableIconButton
          icon="ArrowIcon"
          tooltipTitle={t('user:action.groupPermission', { groupName: original?.group?.name })}
          onClick={() => {
            showGroupPermissionDetailsDialog(original?.group?.name, original?.group?.id);
          }}
        />
      )
    }
  ]);

  return (
    <div>
      <TableLayout
        {...tableProps}
        pageTitle={t('user:table.permissionsGroup')}
        headerActions={!hideColumnPicker && <TableButtonColumnPicker />}
        xlsxDownload={{
          fileName: t('user:table.permissionsGroup'),
          pathToXLSXTranslation: 'user:groupField',
          apiRequest: params => {
            const queryParams = parsePermissionGroupsPayload(params);
            return API.group.listMyGroupMemberships(queryParams);
          }
        }}
        rowActions={renderTableActions([UserUIElementEnum.ACCOUNT_SHOW_PERMISSIONS_BUTTON])}
        isSection
      />
    </div>
  );
}

export default PermissionGroupsTab;
