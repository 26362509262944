import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  BackButton,
  Button,
  Dialog,
  FormMode,
  FormV2Context,
  GridLayout,
  IPropsItem,
  Page,
  PageHeader,
  Section,
  useBreadcrumbsConverter,
  useBreadcrumbTextByFormMode,
  useRouter
} from '@libs/common/v2';
import { ButtonsGroup } from '@libs/common/v2/components/buttonsGroup';

import { FolderElementsPermissionsEnum, useFolderElementsVisibility } from '../../config';
import { useSCertificatesDetailsQuery } from '../api';
import { SCertificateRouteParams } from '../model/routes';

import { CertificateDetails, VehicleDetails } from './details';

export interface SCertificatesDetailsProps {
  formMode: FormMode;
  isDialogContent?: boolean;
  id?: string;
  closeDialog?: () => void;
}

export function SCertificatesDetails({ formMode, isDialogContent, id, closeDialog }: SCertificatesDetailsProps) {
  const { id: paramsId } = useParams<SCertificateRouteParams>();
  const [t] = useTranslation();
  const { data } = useSCertificatesDetailsQuery(isDialogContent ? id : paramsId);
  const { routes, goBack } = useRouter();
  const { convertBreadcrumbToText } = useBreadcrumbsConverter();
  const { checkFolderElementVisibility } = useFolderElementsVisibility();
  const {
    register,
    formState: { errors, isSubmitting, submitCount },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    control,
    reset
  } = useForm<Record<string, any>>({});

  useEffect(() => {
    if (data) {
      reset(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const breadcrumbText = useBreadcrumbTextByFormMode({
    viewValue: t('sCertificates:details.viewCertificate')
  });

  const breadcrumbs = useMemo(
    (): Array<IPropsItem> => [
      {
        text: t('sCertificates:list.title'),
        to: '/s-certificates'
      },
      { text: convertBreadcrumbToText(breadcrumbText) }
    ],
    [t, convertBreadcrumbToText, breadcrumbText]
  );

  const values = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      formMode,
      submitCount
    }),
    [control, errors, formMode, getValues, isSubmitting, register, setValue, submitCount, trigger, unregister, watch]
  );

  if (isDialogContent) {
    return (
      <Dialog title={t('sCertificates:details.viewCertificate')} isOpen onCancel={closeDialog}>
        <FormV2Context.Provider value={values}>
          <Section title={t('sCertificates:details.certificateData')} isCollapsable isModalSection>
            <CertificateDetails />
          </Section>
          <Section title={t('sCertificates:details.vehicleData')} isCollapsable isModalSection>
            <VehicleDetails />
          </Section>
        </FormV2Context.Provider>
      </Dialog>
    );
  }

  return (
    <FormV2Context.Provider value={values}>
      <Page
        header={
          <PageHeader
            title={t('sCertificates:details.viewCertificate')}
            breadcrumbs={breadcrumbs}
            rightSideContent={
              <ButtonsGroup>
                <BackButton onClick={goBack} />
                {checkFolderElementVisibility({
                  folderTypeKey: data?.folder?.typeKey,
                  permisson: FolderElementsPermissionsEnum.FOLDER_VIEW_PERMISSIONS
                }) && (
                  <Button
                    isSecondary
                    variant="outlined"
                    link={routes.folderDetails(data?.folder?.id)}
                    label={t('sCertificates:showFolder')}
                    isNoMargin
                  />
                )}
              </ButtonsGroup>
            }
          />
        }
        content={
          <GridLayout itemProps={{ xs: 12, lg: 6 }}>
            <Section title={t('sCertificates:details.certificateData')} isCollapsable>
              <CertificateDetails />
            </Section>
            <Section title={t('sCertificates:details.vehicleData')} isCollapsable>
              <VehicleDetails />
            </Section>
          </GridLayout>
        }
      />
    </FormV2Context.Provider>
  );
}
