import { OrganizationUnitLiteExternal } from '@avispon/group/dist/models';
import { AccountSnapshotStatus } from '@avispon/user';

import { PersonalData } from '@libs/auth/models/personal-data.model';
import { PermissionEnum } from '@libs/permission';

import * as Actions from '../actions/user.actions';

interface OrganizationUnit extends OrganizationUnitLiteExternal {
  id: string;
}

export interface UserState {
  role: string;
  personalData: PersonalData;
  organizationUnit: OrganizationUnit;
  permissions: PermissionEnum[];
  accountData?: {
    id?: string;
    role?: string;
    login?: string;
    personalData?: {
      name?: string;
      surname?: string;
    };
    status?: AccountSnapshotStatus;
    settings?: Record<string, unknown>;
  };
  settings?: Record<string, unknown>;
}

const initialState: UserState = {
  role: null,
  personalData: null,
  organizationUnit: null,
  permissions: null
};

export type InitialUserState = typeof initialState;

const user = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_USER_DATA: {
      return {
        ...state,
        ...action.payload
      };
    }
    case Actions.USER_LOGGED_OUT: {
      return {
        ...initialState
      };
    }
    default: {
      return state;
    }
  }
};

export default user;
