import { DepotTransferCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function depotTransfer(params: DepotTransferCreateRequest) {
  return API.depotTransfer.createDepotTransfer(params, {
    ...endpointsConfig.createDepotTransfer
  });
}

function useDepotTransferMutation() {
  return useMutation(depotTransfer);
}

export default useDepotTransferMutation;
