import { AccountLite } from '@avispon/user';
import { FolderDetails } from '@ibtm/domain';

import { NUMBER_OF_AUTOCOMPLETE_RESULTS } from '@libs/common/v2';

import { API as UserAPI } from '@libs/user';

import { MixedAccountsSearchRoles } from '../../../../models';
import { BorrowFolderDialogTypeEnum, BorrowFolderSnapshot } from '../../model';

import { useBorrowFoldersContext } from './BorrowFolderProvider';
import { BorrowSection, FolderSection, ReturnSection } from './dialogContent';

interface BorrowFolderDialogContentProps {
  row: FolderDetails | BorrowFolderSnapshot;
}

export function BorrowFolderDialogContent({ row }: BorrowFolderDialogContentProps) {
  const { contentType } = useBorrowFoldersContext();

  const fetchFunction = (searchText: string) => {
    const filteredRoles: MixedAccountsSearchRoles[] = ['OPERATOR', 'OPERATOR_MANAGER', 'OPERATOR_TECHNICAL', 'ADMIN'];

    return UserAPI.user.accountController.searchLiteAccounts({
      page: 0,
      size: NUMBER_OF_AUTOCOMPLETE_RESULTS,
      query: searchText,
      roles: filteredRoles,
      sort: [],
      statuses: ['VERIFIED']
    });
  };

  const getOptionLabel = (option: AccountLite & string) => {
    return option?.name || option?.surname ? `${option?.name} ${option?.surname}` : option;
  };

  const renderOption = (props: Record<string, any>, option: AccountLite) => {
    return <li {...props}>{option.name}</li>;
  };

  return (
    <>
      <FolderSection row={row} />
      <BorrowSection
        row={row}
        contentType={contentType}
        fetchFunction={fetchFunction}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
      />
      {contentType === BorrowFolderDialogTypeEnum.RETURN_FOLDER && (
        <ReturnSection
          row={row as BorrowFolderSnapshot}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          fetchFunction={fetchFunction}
        />
      )}
    </>
  );
}
