enum DocumentTemplateUIElementEnum {
  MESSAGE_ADD_BUTTON = 'MESSAGE_ADD_BUTTON',
  MESSAGE_LIST = 'MESSAGE_LIST',
  ANNOUNCEMENT_LIST_TAB = 'ANNOUNCEMENT_LIST_TAB',
  ANNOUNCEMENT_DETAILS_BUTTON = 'ANNOUNCEMENT_DETAILS_BUTTON',
  ANNOUNCEMENT_ADD_BUTTON = 'ANNOUNCEMENT_ADD_BUTTON',
  ANNOUNCEMENT_DELETE_BUTTON = 'ANNOUNCEMENT_DELETE_BUTTON'
}

export default DocumentTemplateUIElementEnum;
