import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosResponse } from 'axios';
import * as Yup from 'yup';

import { Dialog, FormV2Context, GridLayout } from '@libs/common/v2';
import { useMutation } from '@libs/common/v2/api';

import { IAddFileFormData, UpdateFileRequestData } from '../../models';
import UploadFileField from '../form/UploadFileField/UploadFileField';

interface FileDialogProps {
  apiEditQuery: (formValues: UpdateFileRequestData) => Promise<AxiosResponse<any>>;
  onSuccess: () => void;
  closeDialog: () => void;
  attachmentId: string;
  version: number;
}

function FileEditDialog({ apiEditQuery, onSuccess, closeDialog, attachmentId, version }: FileDialogProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();

  const { mutate: addFile, isLoading } = useMutation(apiEditQuery);

  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Partial<IAddFileFormData>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        file: Yup.object().nullable().required()
      })
    ),
    defaultValues: {
      file: null
    }
  });

  const onSubmit = (formData: Partial<IAddFileFormData>) => {
    const requestData: UpdateFileRequestData = {
      recordId: attachmentId,
      version,
      fileId: formData.file.fileId
    };

    addFile(requestData, {
      onSuccess: () => {
        onSuccess?.();
        closeDialog();

        showSuccessSnackbar(t('success.add'));
      }
    });
  };

  const values = useMemo(
    () => ({
      control,
      errors,
      register,
      setError,
      clearErrors,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting
    }),
    [clearErrors, control, errors, getValues, isSubmitting, register, setError, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('dialog.editFileTitle')}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={() => handleSubmit(onSubmit)()}
      onCancel={closeDialog}
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <GridLayout itemProps={{ xs: 12 }}>
          <UploadFileField name="file" isRequired />
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default FileEditDialog;
