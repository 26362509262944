import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardActions, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import * as Yup from 'yup';

import {
  Animate,
  Button,
  FormMode,
  FormV2Context,
  GridLayout,
  IconButton,
  TextInputField,
  typedNameV2,
  Typography,
  useRouter,
  useValidatorUtil,
  ValidatorEnums
} from '@libs/common/v2';

import { useResetPasswordMutation } from '@libs/user/api';

interface IFormField {
  email: string;
}

function ResetPasswordPage() {
  const [t] = useTranslation();

  const classes = useStyles();
  const logoImageUrl = `${process.env.PUBLIC_URL}/assets/images/logos/logo.png`;
  const useValidate = (validator: ValidatorEnums): [RegExp, string] => useValidatorUtil(validator);

  const { goToPage, routes } = useRouter();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: resetPassword, isLoading: isResetPasswordLoading } = useResetPasswordMutation();

  const VALIDATION_SCHEMA = Yup.object({
    email: Yup.string()
      .required()
      .nullable()
      .matches(...useValidate(ValidatorEnums.EMAIL))
  });

  const INITIAL_VALUES = {
    email: ''
  };

  const onSubmit = (values: Partial<IFormField>) => {
    resetPassword(values.email, {
      onSuccess: () => {
        goToPage(routes.login());
        showSuccessSnackbar(t('user:anonymousUser.message.emailSendSuccess'));
      }
    });
  };

  const form = useForm<Partial<IFormField>>({
    defaultValues: INITIAL_VALUES,
    resolver: yupResolver(VALIDATION_SCHEMA),
    mode: 'onBlur'
  });

  const formDataProvider = useMemo(() => {
    return { ...form, formMode: FormMode.CREATE };
  }, [form]);

  return (
    <div className={classes.root}>
      <Animate animation="transition.expandIn">
        <Card className={classes.card}>
          <CardActions>
            <IconButton icon="ArrowLeftIcon" onClick={() => goToPage(routes.login())} isBackgroundTransparent />
          </CardActions>
          <div className="p-5">
            <CardContent className={classes.cardContent}>
              <img className={classes.logo} src={logoImageUrl} alt={t('global:mainLogo')} />
              <Typography themeVariant="displaySm.semibold" customStyles={{ margin: '12px 0px' }}>
                {t('user:passwordReset')}
              </Typography>
              <Typography themeVariant="textMd.normal" customStyles={{ marginBottom: '32px', textAlign: 'center' }}>
                {t('user:passwordResetHelperText')}
              </Typography>
              <FormV2Context.Provider value={formDataProvider}>
                <GridLayout itemProps={{ xs: 12 }}>
                  <TextInputField
                    name={typedNameV2<IFormField>('email')}
                    inputProps={{ inputProps: { 'aria-label': t('user:field.email') } }}
                    label={t('user:field.email')}
                    validation={{ contextValidator: [ValidatorEnums.EMAIL] }}
                  />
                  <Button
                    isPrimary
                    isLoading={isResetPasswordLoading}
                    onClick={form.handleSubmit(onSubmit)}
                    label={t('action.send')}
                    className={clsx(classes.sendButton)}
                    isNoMargin
                    fullWidth
                  />
                </GridLayout>
              </FormV2Context.Provider>
            </CardContent>
          </div>
        </Card>
      </Animate>
    </div>
  );
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    background: theme.palette.background?.dark,
    color: theme.palette.primary?.contrastText,
    '& button': {
      display: 'block'
    },
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  card: {
    backgroundColor: 'white',
    width: 400
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  logo: {
    height: 130,
    width: 130
  },
  sendButton: {
    width: '100%'
  }
}));

export default ResetPasswordPage;
