import { ForeignPermissionSearchFilter, ForeignPermissionSnapshot } from '@ibtm/domain';

import {
  CamelCasePath,
  ColumnTypesEnum,
  SelectOption,
  TableFilterMapper,
  TableSortMapper,
  useCreateColumns
} from '@libs/common/v2';
import { DateRangeISO, getCalendarDate, getValue } from '@libs/common/v2/utils';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { ReleasedPermitsSnapshot } from '@libs/domain/foreign-permission';

import { AssociationFilter } from '../../../folder/components/filter';

function useForeignPermissionsColumns(visibleColumnIds?: Array<CamelCasePath<ReleasedPermitsSnapshot>>) {
  const { createColumns } = useCreateColumns<ReleasedPermitsSnapshot, ForeignPermissionSnapshot>({
    pathToTranslate: 'foreignPermits:tabs.releasedPermits.fields'
  });

  const mappedFilterFields: TableFilterMapper<ReleasedPermitsSnapshot, ForeignPermissionSearchFilter> = {
    applicationNumber: (applicationNumber: string) => ({ applicationNumberContains: applicationNumber }),
    folderNumber: (folderNumber: string) => ({ folderNumberContains: folderNumber }),
    dateOfIssue: ({ dateFrom, dateTo }: DateRangeISO) => ({
      dateOfIssueGreaterThanOrEqual: getCalendarDate(dateFrom),
      dateOfIssueLessThanOrEqual: getCalendarDate(dateTo)
    }),
    validDateRangeTo: (year: number) => ({
      yearLessThanOrEqual: year
    }),
    validDateRangeFrom: (year: number) => ({
      yearOfIssueGreaterThanOrEqual: year
    }),
    formTypeCountryKey: (countries: SelectOption<string>[]) => ({
      countryCodeKeyIn: countries?.map(({ value }) => value)
    }),
    issuingCountryCodeKey: (countries: SelectOption<string>[]) => ({
      issuingCountryCodeKeyIn: countries?.map(({ value }) => value)
    }),
    nameKey: (names: SelectOption<string>[]) => ({ nameKeyIn: names?.map(({ value }) => value) }),
    formStateKey: (statuses: SelectOption<string>[]) => ({ formStateKeyIn: statuses?.map(({ value }) => value) }),
    subjectName: (subjectName: string) => ({ subjectNameContains: subjectName }),
    invoiceNumber: (invoiceNumber: string) => ({ invoiceNumberContains: invoiceNumber }),
    codeKey: (settlementNumber: SelectOption<string>) => ({ codeKeyIn: [settlementNumber?.value] }),
    formNumber: (formNumber: string) => ({ formNumberContains: formNumber }),
    standingAssociationNumber: (associations: SelectOption<string>[]) => ({
      standingAssociationIdIn: associations?.map(({ value }) => value)
    })
  };

  const mappedSortByFields: TableSortMapper<ReleasedPermitsSnapshot> = {
    applicationNumber: 'application.number',
    folderNumber: 'folder.number',
    formStateKey: 'form.stateKey',
    formNumber: 'form.number',
    subjectName: 'folder.subject.name',
    codeKey: 'settlement.codeKey',
    invoiceNumber: 'invoice.number',
    formTypeCountryKey: 'form.type.countryKey',
    validDateRangeTo: 'year',
    validDateRangeFrom: 'dateOfIssue',
    standingAssociationNumber: 'standingAssociation.number'
  };

  const columns = createColumns(
    [
      {
        accessor: 'application.number',
        type: ColumnTypesEnum.TEXT
      },
      {
        id: 'folderNumber',
        header: 'folderNumber',
        type: ColumnTypesEnum.TEXT,
        customAccessor: row => getValue(row?.folder?.number || row?.standingFolder?.number),
        customXlsxAccessor: row => getValue(row?.folder?.number || row?.standingFolder?.number)
      },
      {
        header: 'company',
        accessor: 'subject.name',
        type: ColumnTypesEnum.TEXT
      },
      {
        header: 'standingAssociation',
        accessor: 'standingAssociation.number',
        type: ColumnTypesEnum.MULTI_SELECT,
        filter: AssociationFilter
      },
      {
        accessor: 'form.number',
        type: ColumnTypesEnum.TEXT
      },
      {
        header: 'name',
        accessor: 'nameKey',
        type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
        dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_NAME,
        areInactiveEntriesVisible: false,
        width: 240
      },
      {
        header: 'status',
        accessor: 'form.stateKey',
        type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
        dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_STATE
      },
      {
        accessor: 'dateOfIssue',
        type: ColumnTypesEnum.DATE
      },
      {
        header: 'invoice',
        accessor: 'invoice.number',
        type: ColumnTypesEnum.TEXT
      },
      {
        accessor: 'validDateRange.from',
        type: ColumnTypesEnum.YEAR_DATEPICKER
      },
      {
        accessor: 'validDateRange.to',
        type: ColumnTypesEnum.YEAR_DATEPICKER
      },
      {
        header: 'country',
        accessor: 'form.type.countryKey',
        type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
        dictionaryName: DomainDictionaryEnum.COUNTRY_CODE
      },
      {
        accessor: 'issuingCountryCodeKey',
        type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
        dictionaryName: DomainDictionaryEnum.COUNTRY_CODE
      },
      {
        accessor: 'codeKey',
        type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
        dictionaryName: DomainDictionaryEnum.RETURNS_OF_FOREIGN_LICENSES
      }
    ],
    visibleColumnIds
  );

  return { columns, mappedFilterFields, mappedSortByFields };
}

export default useForeignPermissionsColumns;
