import { useCallback } from 'react';

import { useDialog } from '@libs/common/v2';

import { EditLicenseCopyDialog, IUpdateLicenseRequest } from '../../../components/EditLicenseCopyDialog';

export function useEditLicenseCopyDialog() {
  const { openDialog } = useDialog();

  const showDialog = useCallback(
    (initialData: IUpdateLicenseRequest, onSuccess: () => void) => {
      openDialog(({ closeDialog }) => (
        <EditLicenseCopyDialog closeDialog={closeDialog} initialData={initialData} onSuccess={onSuccess} />
      ));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog]
  );

  const openEditLicenseCopyDialog = (initialData: IUpdateLicenseRequest, onSuccess: () => void) => {
    showDialog(initialData, onSuccess);
  };

  return {
    openEditLicenseCopyDialog
  };
}
