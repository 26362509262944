import { useTableLayoutProps } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { useFolderToArchiveQuery } from '../api';

import { useFolderArchiveColumns } from '.';

function useFolderArchivedTable() {
  const { columns, mappedFilterFields, mappedSortFields } = useFolderArchiveColumns({
    hiddenColumns: ['statusKey'],
    hiddenLocalizatinDictionaryOptions: [
      DomainDictionaryEntry.FOLDER_LOCALIZATION.CARRY_ON_ARCHIVE,
      DomainDictionaryEntry.FOLDER_LOCALIZATION.BORROWED
    ]
  });

  return useTableLayoutProps({
    tableHookQuery: useFolderToArchiveQuery,
    tableHookOptions: {
      columns,
      sortByConverter: mappedSortFields,
      filterConverter: mappedFilterFields,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'number', desc: true }]
        }
      }
    },
    tableHookQueryInitialParams: {
      isDepartmentalArchived: true
    },
    sectionClassName: 'mx-10'
  });
}

export default useFolderArchivedTable;
