import { ReactNode } from 'react';
import { makeStyles } from '@mui/styles';

import { TabContent, Tabs, TabsContent, TabsGroup, useFormV2Context, useViewModesV2 } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import { UserGeneralTab, UserPermissionsGroupTab } from '@libs/user/components';
import { UserDetailsTab } from '@libs/user/models';

interface IProps {
  activeTab: UserDetailsTab;
  setActiveTab: (tab: UserDetailsTab) => void;
  visibleTabHeaders: ReactNode[];
  requireFields?: Record<string, boolean>;
  isTabsHide?: boolean;
  isDisabled: boolean;
  isClientPortal?: boolean;
}

function UserTabsContent({
  activeTab,
  setActiveTab,
  visibleTabHeaders,
  requireFields,
  isTabsHide,
  isDisabled,
  isClientPortal
}: IProps) {
  const { formMode } = useFormV2Context();
  const { viewMode, editMode, createMode } = useViewModesV2(formMode);

  const classes = useStyles({ viewMode });

  return editMode || isTabsHide ? (
    <UserGeneralTab requiredFields={requireFields} isClientPortal={isClientPortal} />
  ) : (
    <TabsGroup>
      <Tabs value={activeTab} onChange={setActiveTab} orientation="horizontal" className={classes.tabsWrapper}>
        {visibleTabHeaders}
      </Tabs>
      <TabsContent value={activeTab} className={classes.tabsContent}>
        <TabContent value={UserDetailsTab.GENERAL} isInnerScroll={false}>
          <UserGeneralTab requiredFields={requireFields} isClientPortal={isClientPortal} />
        </TabContent>
        <TabContent value={UserDetailsTab.PERMISSIONS_GROUP} isInnerScroll={false}>
          {!createMode && <UserPermissionsGroupTab isDisabled={isDisabled} />}
          {createMode && <UserPermissionsGroupTab isDisabled={isDisabled} />}
        </TabContent>
      </TabsContent>
    </TabsGroup>
  );
}

const useStyles = makeStyles<Theme, { viewMode?: boolean }>(theme => ({
  tabsContent: {
    paddingBottom: 24,
    overflow: important('hidden')
  },
  tabsWrapper: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`
  }
}));

export default UserTabsContent;
