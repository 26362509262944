import { QueryConfig, useQuery } from 'react-query';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { TransportManagerDetailsClient } from '../../model';
import { parseTransportManagerDetails } from '../../utils';
import { endpointsConfig } from '../endpoints-config';

import { TransportManagerQueryKeysEnum } from './TransportManagerQueryKeysEnum';

const getTransportManagerClient = async (id: string) => {
  const { data } = await API.client.transportManager.getTransportManagerApplicationDetails(id, '', '', {
    ...endpointsConfig.getTransportManagerDetails
  });
  return parseTransportManagerDetails(data);
};

const getTransportManager = async (id: string) => {
  const { data } = await API.transportManager.getTransportManagerDetails(id, {
    ...endpointsConfig.getTransportManagerDetails
  });
  return parseTransportManagerDetails(data);
};

export default function useTransportManagerQuery(id: string, queryConfig: QueryConfig<any, unknown> = {}) {
  const { getQuery } = useGetApiQueryByPerspective();

  return useQuery<TransportManagerDetailsClient>(
    [TransportManagerQueryKeysEnum.TRANSPORT_MANAGER, id],
    getQuery(
      () => getTransportManagerClient(id),
      () => getTransportManager(id)
    ),
    queryConfig
  );
}
