import { useTableLayoutProps } from '@libs/common/v2';

import { DomainDictionaryEntry } from '../../config';
import { useAttachmentsQuery } from '../shared/attachments/api';

import { useAttachmentColumns } from './useAttachmentColumns';

export function useAttachmentsTable(applicationId: string) {
  const { columns } = useAttachmentColumns();

  return useTableLayoutProps({
    tableHookQuery: useAttachmentsQuery,
    tableHookOptions: {
      columns
    },
    tableHookQueryInitialParams: {
      statusKeyIn: [DomainDictionaryEntry.REQUIRED_FILE_STATUS.MISSING],
      applicationId
    }
  });
}
