import { QueryConfig, usePaginatedQuery } from 'react-query';
import { DefaultApiFindSentMessagesRequest, SentNotificationSnapshot } from '@avispon/message';
import { AxiosRequestConfig } from 'axios';

import { IPaginatedModel } from '@libs/common/v2';

import { API, endpointsConfig } from '@libs/notification/api';

import { MessageQueryKeysEnum } from './QueryKeysEnum';

export const getMessages = async (_, params: DefaultApiFindSentMessagesRequest) => {
  const { data } = await API.message.findSentMessages(params, {
    ...endpointsConfig.findSentMessages
  } as AxiosRequestConfig);

  return data;
};

export default function useMessagesQuery(
  params: DefaultApiFindSentMessagesRequest,
  queryConfig: QueryConfig<IPaginatedModel<SentNotificationSnapshot>> = {}
) {
  return usePaginatedQuery<IPaginatedModel<SentNotificationSnapshot>>(
    [MessageQueryKeysEnum.MESSAGES_LIST, params],
    getMessages,
    queryConfig
  );
}
