import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { useConfirmDialog } from '@libs/common/v2';

import { useResendEmailConfirmationLinkMutation } from '../api';

export function useResendEmailConfirmation() {
  const [t] = useTranslation();
  const [confirm] = useConfirmDialog();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: resendEmailConfirmationLink } = useResendEmailConfirmationLinkMutation();

  const resendEmailConfirmation = (id: string, login: string) => {
    confirm({
      title: t('user:dialog.resendEmailConfirmation.title'),
      shortTitle: t('user:dialog.resendEmailConfirmation.shortTitle'),
      message: t('user:dialog.resendEmailConfirmation.content', {
        login
      }),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        resendEmailConfirmationLink(id, {
          onSuccess: () => {
            showSuccessSnackbar(t('user:dialog.resendEmailConfirmation.successMessage'));
            closeDialog();
          },
          onSettled: () => {
            setConfirmLoading(false);
          }
        });
      },
      confirmType: 'danger'
    });
  };

  return { resendEmailConfirmation };
}
