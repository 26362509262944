import { makeStyles } from '@mui/styles';

import { IconButton, useFormV2Context, useTableAdapter, useTableContext } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';

interface IProps {
  id: string;
}
/**
 * Komponent stworzony do użycia z pluginem useAdditionalColumns.
 * Daje możliwość usuwania dodanych kolumn.
 */
function TableColumnHeaderDeleteIcon({ id }: IProps) {
  const table = useTableAdapter();
  const { deleteAddedColumns } = table;
  const { setValue } = useFormV2Context();
  const { isLoading, tableLoading, isFetching, isError, setTableLoading } = useTableContext();
  const classes = useStyles();

  const handleOnClick = () => {
    setTableLoading(true);
    deleteAddedColumns(id, setValue);
    setTableLoading(false);
  };
  return (
    <IconButton
      className={classes.icon}
      height={15}
      width={15}
      iconColor="error"
      icon="TrashIcon"
      onClick={handleOnClick}
      isLoading={tableLoading}
      isDisabled={isLoading || tableLoading || isFetching || isError}
      isBackgroundTransparent
    />
  );
}

const useStyles = makeStyles<Theme>(() => ({
  icon: {
    zIndex: 3
  }
}));

export default TableColumnHeaderDeleteIcon;
