import { useTranslation } from 'react-i18next';

import { TableButton, TableButtonEdit } from '@libs/common/v2';

import { useProceedingChangeStatusAction } from '../../hooks';
import { ProceedingSnapshotClient } from '../../model';

interface IProps {
  proceedingData: ProceedingSnapshotClient;
  onSuccess?: () => void;
  isWithoutIcon: boolean;
  close?: () => void;
}

function ChangeProceedingStatusButton({ proceedingData, onSuccess, isWithoutIcon, close }: IProps) {
  const [t] = useTranslation();

  const { openProceedingChangeStatusDialog } = useProceedingChangeStatusAction();

  if (!isWithoutIcon)
    return (
      <TableButtonEdit
        tooltipTitle={t('proceeding:action.changeStatus')}
        onClick={() => {
          close?.();
          openProceedingChangeStatusDialog(proceedingData, onSuccess);
        }}
      />
    );
  return (
    <TableButton
      label={t('proceeding:action.changeStatus')}
      onClick={() => {
        close?.();
        openProceedingChangeStatusDialog(proceedingData, onSuccess);
      }}
    />
  );
}

export default ChangeProceedingStatusButton;
