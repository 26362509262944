import React from 'react';
import { GridProps } from '@mui/material';

import { GridLayout } from '../grid';

import Section, { ISectionProps } from './Section';

interface ISectionWithFields extends ISectionProps {
  fields: JSX.Element[];
  itemProps?: GridProps;
}

function SectionWithFields({ fields, itemProps = { xs: 4 }, ...forwardProps }: ISectionWithFields) {
  return (
    <Section {...forwardProps}>
      <GridLayout itemProps={itemProps}>{[...fields.filter(field => React.isValidElement(field))]}</GridLayout>
    </Section>
  );
}

export default SectionWithFields;
