import { Context, useContext } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';

import {
  ComponentErrorBoundary,
  FormErrorType,
  FormV2Context,
  FormV2ContextState,
  getInputMode,
  InputMode,
  TimepickerInput,
  Value
} from '@libs/common/v2';
import { getValue } from '@libs/common/v2/utils';

import { DatePickerFieldValidation, FieldTypeEnum, useFieldValidationHandler } from '../../validation';

interface IProps {
  name: FieldPath<FieldValues>;
  label?: string;
  minTime?: any;
  maxTime?: any;
  formContext?: Context<FormV2ContextState>;
  inputClassName?: string;
  valueClassName?: string;
  textInputClassName?: string;
  inputMode?: InputMode;
  isFullWidth?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isClearable?: boolean;
  validation?: DatePickerFieldValidation;
  hasErrorTooltip?: boolean;
}

function DatepickerField({
  name,
  label,
  minTime,
  maxTime,
  inputClassName,
  valueClassName,
  inputMode,
  textInputClassName,
  formContext = FormV2Context,
  isDisabled,
  isRequired,
  isClearable,
  isFullWidth = true,
  validation,
  hasErrorTooltip
}: IProps) {
  const { control, isSubmitting, formMode, loading, trigger } = useContext<FormV2ContextState>(formContext);
  const mode = getInputMode(formMode, inputMode);

  const validate = async () => {
    await trigger(name);
  };

  useFieldValidationHandler({
    fieldName: name,
    validation,
    fieldType: FieldTypeEnum.DATETIME
  });

  return (
    <ComponentErrorBoundary componentName={label || name || 'TimepickerField'}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { error } }) =>
          mode === InputMode.FORM ? (
            <TimepickerInput
              onBlur={validate}
              value={value}
              inputClassName={inputClassName}
              onChange={newDate => {
                onChange(newDate);
                validate();
              }}
              maxTime={maxTime}
              textInputClassName={textInputClassName}
              minTime={minTime}
              isClearable={isClearable}
              label={label}
              isError={!!error && error?.type !== FormErrorType.WARNING}
              isWarning={error?.type === FormErrorType.WARNING}
              helperText={error?.message}
              isFullWidth={isFullWidth}
              isRequired={isRequired}
              isDisabled={isDisabled || isSubmitting}
              hasErrorTooltip={hasErrorTooltip}
            />
          ) : (
            <Value
              label={label}
              isLoading={loading}
              className={valueClassName}
              isError={!!error && error?.type !== FormErrorType.WARNING}
              isWarning={error?.type === FormErrorType.WARNING}
              helperText={error?.message}
            >
              {!loading && getValue(value as string | number)}
            </Value>
          )
        }
      />
    </ComponentErrorBoundary>
  );
}

export default DatepickerField;
