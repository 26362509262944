import { SuspensionSnapshot } from '@ibtm/domain';

import { CamelCasePath, useTableLayoutProps } from '@libs/common/v2';

import { useSuspensionsColumns, useSuspensionsQuery } from '@libs/domain/suspensions';

function useSuspensionsTable(visibleColumns: Array<CamelCasePath<SuspensionSnapshot>>, folderId?: string) {
  const { columns, mappedFilterFields, mappedSortFields } = useSuspensionsColumns(visibleColumns);

  return useTableLayoutProps({
    tableHookQuery: useSuspensionsQuery,
    tableHookOptions: {
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      columns,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'created', desc: true }]
        }
      }
    },
    ...(folderId
      ? {
          tableHookQueryInitialParams: {
            folderIdIn: [folderId]
          }
        }
      : {})
  });
}

export default useSuspensionsTable;
