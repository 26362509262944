import { useDialog } from '@libs/common/v2';

import { ProceedingStatusChangeForm } from '../../components/form';
import { IProceedingClient, IProceedingDetailsClient } from '../../models';

const useProceedingStatusChangeDialog = () => {
  const { openDialog } = useDialog();

  const open = (data: IProceedingClient | IProceedingDetailsClient, { onSuccess }: { onSuccess?: () => void }) => {
    openDialog(({ closeDialog }) => {
      return <ProceedingStatusChangeForm initialData={data} onSuccess={onSuccess} closeDialog={closeDialog} />;
    });
  };

  return {
    openProceedingStatusChangeDialog: open
  };
};

export default useProceedingStatusChangeDialog;
