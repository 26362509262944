import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function deletePartnerApplicant({ partnerApplicantId }: { partnerApplicantId: string }) {
  return API.partnerApplicants.deletePartnerApplicant(partnerApplicantId, {
    ...endpointsConfig.deletePartnerApplicant
  });
}

function useDeletePartnerApplicantMutation() {
  return useMutation(deletePartnerApplicant);
}

export default useDeletePartnerApplicantMutation;
