import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Dialog, FormV2Context, GridLayout, useDialog } from '@libs/common/v2';

import { UploadFileField } from '@libs/file';

import { UploadFileDialogCustomActions } from '@libs/domain/social-commission';

const DEFAULT_VALUES = {
  file: null
};

function useUploadFileDialog({ isEkmtCommittee }: { isEkmtCommittee?: boolean }) {
  const [t] = useTranslation();
  const { openDialog } = useDialog();

  const {
    control,
    register,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        file: Yup.object().nullable().required()
      })
    ),
    defaultValues: DEFAULT_VALUES
  });

  const values = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      setError,
      clearErrors,
      handleSubmit,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      loading: isSubmitting,
      initialValues: DEFAULT_VALUES
    }),
    [
      clearErrors,
      control,
      errors,
      getValues,
      handleSubmit,
      isSubmitting,
      register,
      setError,
      setValue,
      trigger,
      unregister,
      watch
    ]
  );

  const openUploadFileDialog = useCallback(() => {
    openDialog(({ closeDialog }) => (
      <FormV2Context.Provider value={values}>
        <Dialog
          title={t('global:action.importFile')}
          cancelText={t('action.cancel')}
          onConfirm={closeDialog}
          onCancel={closeDialog}
          dialogSize="small"
          customActions={<UploadFileDialogCustomActions handleClose={closeDialog} isEkmtCommittee={isEkmtCommittee} />}
          isConfirmLoading={isSubmitting}
          isOpen
        >
          <GridLayout itemProps={{ xs: 12 }}>
            <UploadFileField name="file" isRequired />
          </GridLayout>
        </Dialog>
      </FormV2Context.Provider>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog, t]);

  return {
    openUploadFileDialog
  };
}

export default useUploadFileDialog;
