import { useEffect, useMemo } from 'react';
import { RefetchOptions } from 'react-query/types/core/query';
import { FolderCreateRequest } from '@ibtm/domain';
import { isNil } from 'lodash';

import {
  GridLayout,
  InputMode,
  SelectOption,
  typedNameV2,
  useErrorsHandlerV2,
  useFormV2Context
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { useElementVisibility } from '@libs/permission';

import { DomainDictionaryEntry, DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import { checkIsPartnership, FolderDetailsClient, RegisteredFolderFormEnum } from '@libs/domain/folder';
import { ReputationSection } from '@libs/domain/subject';

import EntrepreneursSection from './sections/entrepreneurs/EntrepreneursSection';
import HistoricalDataSection from './sections/historicalData/HistoricalDataSection';
import LicenceDataSectionDetails from './sections/licence/LicenceDataSection';
import PermitDataSectionDetails from './sections/permit/PermitDataSection';
import ContactDataSection from './sections/subjectData/ContactDataSection';
import SubjectDataSection from './sections/subjectData/SubjectDataSection';
import SubjectDataSectionDetails from './sections/subjectData/SubjectDataSectionDetails';

interface IProps {
  refetch: (options?: RefetchOptions) => void;
}

function SubjectTab({ refetch }: IProps) {
  const { isClientPortal } = useDomainConfigContext();
  const { watch, formMode, setValue } = useFormV2Context();
  const { createMode, viewMode, editMode } = useViewModesV2(formMode);

  const { containerRef } = useErrorsHandlerV2(null, RegisteredFolderFormEnum.FOLDER_FORM);

  const typeKey = watch(typedNameV2<FolderCreateRequest>('typeKey')) as SelectOption<string>;
  const subjectName = watch(typedNameV2<FolderDetailsClient>('subject.name')) as string;
  const legalFormKey = watch(typedNameV2<FolderCreateRequest>('subject.legalFormKey')) as string;
  const { checkIsElementVisible } = useElementVisibility();

  const isFolderTypeKeyPPO = useMemo(() => typeKey?.value === DomainDictionaryEntry.FOLDER_TYPE.PPO, [typeKey?.value]);
  const isPartnership = checkIsPartnership(legalFormKey);
  const isNaturalPerson = legalFormKey === DomainDictionaryEntry.LEGAL_NATURE_KEY.NATURAL_PERSON;
  const isReputationBlockVisible = useMemo(
    () =>
      !createMode &&
      !isClientPortal &&
      [DomainDictionaryEntry.FOLDER_TYPE.TP, DomainDictionaryEntry.FOLDER_TYPE.OP].includes(typeKey?.value),
    [createMode, isClientPortal, typeKey?.value]
  );

  useEffect(() => {
    if (createMode && typeKey?.value) {
      setValue(typedNameV2<FolderCreateRequest>('subject.subjectTypeKey'), {
        value:
          typeKey?.value === DomainDictionaryEntry.FOLDER_TYPE.ZZ
            ? DomainDictionaryEntry.SUBJECT_TYPE.ASSOCIATION
            : DomainDictionaryEntry.SUBJECT_TYPE.ENTREPRENEUR
      });
    }
  }, [createMode, setValue, typeKey?.value]);

  const isEditModeAllowed = useMemo(
    () => checkIsElementVisible(DomainUIElementEnum.FOLDER_SUBJECT_EDIT),
    [checkIsElementVisible]
  );
  const hasSubjectName = useMemo(() => !isNil(subjectName), [subjectName]);

  return (
    <GridLayout itemProps={{ xs: 12 }} ref={containerRef}>
      {createMode ? (
        <SubjectDataSection />
      ) : (
        <SubjectDataSectionDetails hasSubjectName={hasSubjectName} isNaturalPerson={isNaturalPerson} />
      )}
      <ContactDataSection refetch={refetch} {...(editMode && !isEditModeAllowed && { inputMode: InputMode.VIEW })} />
      {isPartnership && <EntrepreneursSection />}
      {isReputationBlockVisible && (
        <ReputationSection actionKey={DomainUIElementEnum.FOLDER_SUBJECT_REPUTATION_SECTION} />
      )}
      {viewMode && !isFolderTypeKeyPPO && isPartnership && <LicenceDataSectionDetails />}
      {viewMode && !isFolderTypeKeyPPO && isPartnership && <PermitDataSectionDetails />}
      {!createMode && <HistoricalDataSection isCollapsable />}
    </GridLayout>
  );
}

export default SubjectTab;
