import { DomainDictionaryEntry } from '@libs/domain/config';

export const getProcessList = () => {
  const {
    // License
    PB2A,
    PB2B,
    PB5,
    PB10A,
    PB10B,
    PB11A,
    PB11B,
    PB11C,
    PB11D,
    PB12,
    PB14,
    PB26,
    PB27,
    PB28,
    PB29A,
    PB29B,
    PB30,
    PB31,
    PB32A,
    PB32B,
    PB33,
    // Driver
    changeOfDriverCertificate,
    inputMistake,
    issueOfDriverCertificate,
    issuingDuplicateDriverCertificate,
    restoreOfDriverCertificate,
    // S-Certificate
    sCertificateChangeOfDocument,
    sCertificateIssuingDocument,
    sCertificateIssuingDuplicate,
    sCertificateResumingIssueOfDocument,
    sCertificateReprintBtm,
    // Foreign-Permits
    foreignPermitEkmt,
    foreignPermitSingleOp,
    foreignPermitSingleTp,
    // SPO
    // SPO - spoUe
    spoUeLaunch,
    spoUeDecisionToChangeTerms,
    spoUeExtend,
    spoUeAdditionalExcerpt,
    spoUeModification,
    spoUeModificationWithoutPrinting,
    spoUeDuplicateOfExtract,
    spoUeFreeReprint,
    // SPO - spoOutsideUe
    spoOutsideUeLaunch,
    spoOutsideUeDecisionToChangeTerms,
    spoOutsideUeExtend,
    spoOutsideUeAdditionalExcerpt,
    spoOutsideUeModification,
    spoOutsideUeModificationWithoutPrinting,
    spoOutsideUeDuplicateOfExtract,
    spoOutsideUeFreeReprint,
    // SPO - spoWzo
    spoWzoLaunch,
    spoWzoDecisionToChangeTerms,
    spoWzoAdditionalExcerpt,
    spoWzoModification,
    spoWzoModificationWithoutPrinting,
    spoWzoDuplicateOfExtract,
    spoWzoFreeReprint,
    // SPO - spoWzw
    spoWzwLaunch,
    spoWzwDecisionToChangeTerms,
    spoWzwAdditionalExcerpt,
    spoWzwModification,
    spoWzwDuplicateOfExtract,
    spoWzwReprintedAtFaultBtm,
    // SPO - spoTransitRp
    spoTransitRpLaunch,
    spoTransitRpDecisionToChangeTerms,
    spoTransitRpExtend,
    spoTransitRpAdditionalExcerpt,
    spoTransitRpDuplicateOfExtract,
    spoTransitRpReprintedAtFaultBtm,
    // SPO - spoPositionMind
    spoPositionMindLaunch,
    spoPositionMindExtend,
    spoPositionMindModification,
    // SPO-OWN-NEEDS
    spoOwnNeedsPwr1,
    spoOwnNeedsPwr2,
    spoOwnNeedsPwr3,
    spoOwnNeedsIpwz,
    spoOwnNeedsPwr3Reprint,
    spoOwnNeedsIpwzInfo,
    TRAVEL_FORM
  } = DomainDictionaryEntry.APPLICATION_TYPE;

  return {
    PB2A: [PB2A],
    PB2B: [PB2B],
    PB5: [PB5],
    PB10: [PB10A, PB10B],
    PB11: [PB11A, PB11B, PB11C, PB11D],
    PB12: [PB12],
    PB14: [PB14],
    PB26: [PB26],
    PB27: [PB27],
    PB28: [PB28],
    PB29: [PB29A, PB29B],
    PB30: [PB30],
    PB31: [PB31],
    PB32: [PB32A, PB32B],
    PB33: [PB33],
    DRIVER_CERTIFICATE: [
      changeOfDriverCertificate,
      inputMistake,
      issueOfDriverCertificate,
      issuingDuplicateDriverCertificate,
      restoreOfDriverCertificate
    ],
    FOREIGN_PERMITS: [foreignPermitEkmt, foreignPermitSingleOp, foreignPermitSingleTp],
    EKMT_CARNETS: [foreignPermitEkmt],
    SPO_OUTSIDE_UE: [
      spoOutsideUeLaunch,
      spoOutsideUeDecisionToChangeTerms,
      spoOutsideUeExtend,
      spoOutsideUeModification,
      spoOutsideUeModificationWithoutPrinting,
      spoOutsideUeFreeReprint
    ],
    SPO_OUTSIDE_UE_ONLY_EXTRACT: [spoOutsideUeDuplicateOfExtract, spoOutsideUeAdditionalExcerpt],
    SPO_UE: [
      spoUeLaunch,
      spoUeDecisionToChangeTerms,
      spoUeExtend,
      spoUeModification,
      spoUeModificationWithoutPrinting,
      spoUeFreeReprint
    ],
    SPO_UE_ONLY_EXTRACT: [spoUeDuplicateOfExtract, spoUeAdditionalExcerpt],
    SPO_WZO: [
      spoWzoLaunch,
      spoWzoDecisionToChangeTerms,
      spoWzoModification,
      spoWzoModificationWithoutPrinting,
      spoWzoFreeReprint
    ],
    SPO_WZO_ONLY_EXTRACT: [spoWzoDuplicateOfExtract, spoWzoAdditionalExcerpt],
    SPO_WZW: [spoWzwLaunch, spoWzwDecisionToChangeTerms, spoWzwModification, spoWzwReprintedAtFaultBtm],
    SPO_WZW_ONLY_EXTRACT: [spoWzwDuplicateOfExtract, spoWzwAdditionalExcerpt],
    SPO_TRANSIT_RP: [
      spoTransitRpLaunch,
      spoTransitRpDecisionToChangeTerms,
      spoTransitRpExtend,
      spoTransitRpReprintedAtFaultBtm
    ],
    SPO_TRANSIT_RP_ONLY_EXTRACT: [spoTransitRpDuplicateOfExtract, spoTransitRpAdditionalExcerpt],
    SPO_POSITION_MIND: [spoPositionMindLaunch, spoPositionMindExtend, spoPositionMindModification],
    SPO_OWN_NEEDS: [spoOwnNeedsPwr1, spoOwnNeedsPwr3, spoOwnNeedsIpwz, spoOwnNeedsIpwzInfo, spoOwnNeedsPwr3Reprint],
    SPO_OWN_NEEDS_ONLY_EXTRACT: [spoOwnNeedsPwr2],
    S_CERTIFICATE: [
      sCertificateChangeOfDocument,
      sCertificateIssuingDocument,
      sCertificateIssuingDuplicate,
      sCertificateResumingIssueOfDocument,
      sCertificateReprintBtm
    ],
    TRAVEL_FORM: [TRAVEL_FORM]
  };
};
