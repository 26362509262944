import { useCallback, useEffect, useMemo } from 'react';

import { partialTranslate } from '@libs/common';
import {
  DictionarySelectField,
  GridItem,
  GridLayout,
  InputMode,
  SelectOption,
  SwitchField,
  typedNameV2,
  useFormV2Context,
  useFormV2Watch
} from '@libs/common/v2';
import { HintsAutocompleteField, NumberInputField, useViewModesV2 } from '@libs/common/v2/form';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import { StopFormData, StopSectionFields, WzwStopTypeOptions } from '../../../model';

const getLabel = partialTranslate('applications:transitSchedule.fields');
const getTooltip = partialTranslate('applications:transitSchedule.tooltips');

function StopSection({
  hiddenFields,
  isTypeKeySelectNotNextStop,
  isCityNotRequiredForStartEndStopType,
  isApplicationGroupSpoWzw
}: {
  hiddenFields: string[];
  isTypeKeySelectNotNextStop?: boolean;
  isCityNotRequiredForStartEndStopType?: boolean;
  isApplicationGroupSpoWzw?: boolean;
}) {
  const { formMode, resetField, clearErrors } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);
  const inputMode = viewMode ? InputMode.VIEW : InputMode.FORM;

  const borderCrossingStopTypes = useMemo(() => {
    if (isApplicationGroupSpoWzw) {
      return [
        DomainDictionaryEntry.STOP_TYPE.ALTERNATIVE_BORDER_CROSSING,
        DomainDictionaryEntry.STOP_TYPE.BORDER_CROSSING,
        DomainDictionaryEntry.STOP_TYPE.END_OF_TOUR
      ];
    }

    return [
      DomainDictionaryEntry.STOP_TYPE.ALTERNATIVE_BORDER_CROSSING,
      DomainDictionaryEntry.STOP_TYPE.BORDER_CROSSING
    ];
  }, [isApplicationGroupSpoWzw]);

  const getIsFieldHidden = (fieldName: string) => hiddenFields.includes(fieldName);

  const typeKey = useFormV2Watch({ name: StopSectionFields.TYPE_KEY }) as SelectOption<string>;
  const countryKey = useFormV2Watch({ name: typedNameV2<StopFormData>('countryKey') }) as SelectOption<string>;

  const isBorderCrossingSelected = borderCrossingStopTypes.includes(typeKey?.value);

  const isStopAgreementNotRequired =
    countryKey?.value !== DomainDictionaryEntry.COUNTRY_CODE.POLAND || isBorderCrossingSelected;

  const isStopTypeStartDisabled = isApplicationGroupSpoWzw
    ? DomainDictionaryEntry.STOP_TYPE.END_OF_TOUR === typeKey?.value
    : [DomainDictionaryEntry.STOP_TYPE.START_OF_TOUR, DomainDictionaryEntry.STOP_TYPE.END_OF_TOUR].includes(
        typeKey?.value
      );

  const freeSoloOptionFilter = useCallback((value: string): boolean => {
    const specialChars = /^\s+|\s+$/;
    return !specialChars.test(value);
  }, []);

  useEffect(() => {
    if (!borderCrossingStopTypes.includes(typeKey?.value)) {
      resetField(typedNameV2<StopFormData>('city2'));
      resetField(typedNameV2<StopFormData>('countryKey2'));
    }
  }, [borderCrossingStopTypes, resetField, typeKey]);

  useEffect(() => {
    if (isApplicationGroupSpoWzw && typeKey?.value === DomainDictionaryEntry.STOP_TYPE.END_OF_TOUR) {
      resetField(typedNameV2<StopFormData>('city'));
      resetField(typedNameV2<StopFormData>('countryKey'));
    }
  }, [isApplicationGroupSpoWzw, resetField, typeKey]);

  return (
    <GridLayout itemProps={{ xs: 12, sm: 6 }}>
      {!getIsFieldHidden(StopSectionFields.TYPE_KEY) && (
        <GridItem xs={12} md={getIsFieldHidden(StopSectionFields.NAME) ? 12 : 6}>
          <DictionarySelectField
            dictionaryName={DomainDictionaryEnum.STOP_TYPE}
            name={typedNameV2<StopFormData>('typeKey')}
            label={getLabel('typeKey')}
            inputMode={inputMode}
            onChange={value => {
              if (isApplicationGroupSpoWzw) {
                clearErrors();
              } else if (borderCrossingStopTypes.includes(value?.value)) {
                clearErrors(typedNameV2<StopFormData>('name'));
              }
            }}
            isRequired
            optionsFilter={option => {
              if (isTypeKeySelectNotNextStop) {
                return option.value !== DomainDictionaryEntry.STOP_TYPE.NEXT_STOP;
              }
              if (isApplicationGroupSpoWzw) {
                return WzwStopTypeOptions.includes(option.value);
              }
              return true;
            }}
          />
        </GridItem>
      )}
      {!getIsFieldHidden(StopSectionFields.DIRECTION_KEY) && (
        <DictionarySelectField
          dictionaryName={DomainDictionaryEnum.TRANSIT_DIRECTION}
          name={typedNameV2<StopFormData>('directionKey')}
          label={getLabel('directionKey')}
          inputMode={inputMode}
          isRequired
        />
      )}
      {!getIsFieldHidden(StopSectionFields.BRANCH_ROUTE) && (
        <SwitchField name={typedNameV2<StopFormData>('branchRoute')} label={getLabel('branchRoute')} />
      )}
      {!getIsFieldHidden(StopSectionFields.CITY) && (
        <DictionarySelectField
          dictionaryName={DomainDictionaryEnum.TRANSIT_LOCATION}
          name={typedNameV2<StopFormData>('city')}
          label={getLabel('city')}
          freeSoloOptionFilter={freeSoloOptionFilter}
          inputMode={inputMode}
          isFreeSolo
          isRequired={
            isApplicationGroupSpoWzw
              ? !isStopTypeStartDisabled
              : !(isStopTypeStartDisabled && isCityNotRequiredForStartEndStopType)
          }
          {...(isApplicationGroupSpoWzw &&
            isStopTypeStartDisabled && {
              isDisabled: isStopTypeStartDisabled,
              tooltip: getTooltip('cityAndCountryKeyWzwDisableMessage')
            })}
        />
      )}
      {!getIsFieldHidden(StopSectionFields.COUNTRY_KEY) && (
        <DictionarySelectField
          dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
          name={typedNameV2<StopFormData>('countryKey')}
          label={getLabel('countryKey')}
          inputMode={inputMode}
          isRequired={isApplicationGroupSpoWzw ? !isStopTypeStartDisabled : true}
          {...(isApplicationGroupSpoWzw &&
            isStopTypeStartDisabled && {
              isDisabled: isStopTypeStartDisabled,
              tooltip: getTooltip('cityAndCountryKeyWzwDisableMessage')
            })}
        />
      )}
      {!getIsFieldHidden(StopSectionFields.NAME) && (
        <HintsAutocompleteField
          dictionaryName={DomainDictionaryEnum.STOP_ADDRESS}
          name={typedNameV2<StopFormData>('name')}
          label={getLabel('address')}
          required={!isBorderCrossingSelected}
        />
      )}
      {!getIsFieldHidden(StopSectionFields.AGREEMENT_TYPE_KEY) && (
        <DictionarySelectField
          dictionaryName={DomainDictionaryEnum.STOP_AGREEMENT_TYPE}
          name={typedNameV2<StopFormData>('agreementTypeKey')}
          label={getLabel('stopAgreementType')}
          inputMode={inputMode}
          isRequired={!isStopAgreementNotRequired}
          validationSingleSelect={
            !isStopAgreementNotRequired && {
              required: []
            }
          }
        />
      )}
      {!getIsFieldHidden(StopSectionFields.CITY2) && (
        <DictionarySelectField
          dictionaryName={DomainDictionaryEnum.TRANSIT_LOCATION}
          name={typedNameV2<StopFormData>('city2')}
          label={getLabel('cityKey2')}
          inputMode={inputMode}
          isFreeSolo
          isDisabled={!isBorderCrossingSelected}
          isRequired={isBorderCrossingSelected}
          tooltip={
            !isBorderCrossingSelected
              ? getTooltip(
                  isApplicationGroupSpoWzw
                    ? 'cityAndCountryBorderKeyWzwDisableMessage'
                    : 'cityAndCountryKeyDisableMessage'
                )
              : null
          }
          freeSoloOptionFilter={freeSoloOptionFilter}
        />
      )}
      {!getIsFieldHidden(StopSectionFields.COUNTRY_KEY2) && (
        <DictionarySelectField
          dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
          name={typedNameV2<StopFormData>('countryKey2')}
          label={getLabel('countryKey2')}
          inputMode={inputMode}
          isDisabled={!isBorderCrossingSelected}
          isRequired={isBorderCrossingSelected}
          tooltip={
            !isBorderCrossingSelected
              ? getTooltip(
                  isApplicationGroupSpoWzw
                    ? 'cityAndCountryBorderKeyWzwDisableMessage'
                    : 'cityAndCountryKeyDisableMessage'
                )
              : null
          }
        />
      )}
      {!getIsFieldHidden(StopSectionFields.KILOMETERS) && (
        <NumberInputField
          name={typedNameV2<StopFormData>('kilometers')}
          label={getLabel('kilometers')}
          inputMode={inputMode}
          isOnlyPositiveIntegers
          isRequired
        />
      )}
    </GridLayout>
  );
}

export default StopSection;
