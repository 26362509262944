import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { number, object } from 'yup';

import { Dialog, FormV2Context, GridLayout, NumberInputField } from '@libs/common/v2';

import { FolderSelectField } from '@libs/domain/application';

function DialogSelectFolderForCalculation({
  closeDialog,
  submitCalculateForFolder,
  isTradeRules = false,
  isLoading = false
}: {
  closeDialog: () => void;
  submitCalculateForFolder: (formData: any, closeDialog: () => void) => void;
  isTradeRules?: boolean;
  isLoading?: boolean;
}) {
  const [t] = useTranslation();
  const validationSchema = object({
    folder: object().required(),
    ...(isTradeRules && {
      quantityRequested: number().required()
    })
  });
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema)
  });

  const formValues = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting
    }),
    [control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting]
  );

  return (
    <FormV2Context.Provider value={formValues}>
      <Dialog
        title={t('foreignPermits:tabs.socialCommission.tabs.SocialCommissionBasicRules.action.calculate')}
        confirmText={t('action.confirm')}
        onConfirm={handleSubmit(formData => submitCalculateForFolder(formData, closeDialog))}
        onClose={() => closeDialog()}
        onCancel={() => closeDialog()}
        isLoading={isLoading}
        isConfirmLoading={isSubmitting}
        isOpen
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            if (isSubmitting) return;
            handleSubmit(formData => submitCalculateForFolder(formData, closeDialog))();
          }}
        >
          <input type="submit" hidden />
          <GridLayout itemProps={{ xs: 12 }}>
            <FolderSelectField
              label={t('foreignPermits:tabs.socialCommission.tabs.SocialCommissionBasicRules.fields.selectFolder')}
              fieldName="folder"
              isRequired
            />
            {isTradeRules && (
              <NumberInputField
                label={t(
                  'foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.fields.quantityRequested'
                )}
                name="quantityRequested"
                min={0}
                step={1}
                isRequired
              />
            )}
          </GridLayout>
        </form>
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default DialogSelectFolderForCalculation;
