import { VehicleSnapshot, VehiclesSearchFilter } from '@ibtm/domain';

import { ColumnTypesEnum, SelectOption, TableFilterMapper, useCreateColumns } from '@libs/common/v2';

import { DomainDictionaryEnum, DomainQuickchangeableDictionaryEnum } from '@libs/domain/config';

function useCertificateVehicleColumns() {
  const { createColumns } = useCreateColumns<VehicleSnapshot>({ pathToTranslate: 'sCertificates:field' });

  const mappedFilterFields: TableFilterMapper<VehicleSnapshot, VehiclesSearchFilter> = {
    certificateSStatusKey: (certificateSStatusKeys: SelectOption<string>[]) => ({
      statusKeyIn: certificateSStatusKeys.map(({ value }) => value)
    }),
    plateNumber: plateNumber => ({ plateNumberContains: plateNumber }),
    certificateSNumber: certificateSNumber => ({ sCertificateNumberContains: certificateSNumber }),
    brandKey: (brandKey: SelectOption<string>) => ({ brandKeyIn: [brandKey?.value] }),
    euroClassKey: (euroClassKeys: SelectOption<string>[]) => ({
      euroClassKeyIn: euroClassKeys.map(({ value }) => value)
    }),
    typeKey: (typeKey: SelectOption<string>) => ({ typeKeyIn: [typeKey?.value] }),
    certificateSApplicationNumber: certificateSApplicationNumber => ({
      sCertificateApplicationNumberContains: certificateSApplicationNumber
    })
  };

  const columns = createColumns([
    {
      id: 'certificateSStatusKey',
      header: 'status',
      type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
      dictionaryName: DomainDictionaryEnum.CERTIFICATE_S_STATUS
    },
    {
      id: 'plateNumber',
      header: 'vehicleRegistrationNumber',
      type: ColumnTypesEnum.TEXT
    },
    {
      id: 'certificateSNumber',
      header: 'number',
      type: ColumnTypesEnum.TEXT
    },
    {
      id: 'brandKey',
      header: 'vehicleBrand',
      accessor: 'brandKeyTranslation',
      type: ColumnTypesEnum.QUICK_DICTIONARY_SINGLE_SELECT,
      quickDictionaryName: DomainQuickchangeableDictionaryEnum.VEHICLE_BRAND
    },
    {
      id: 'euroClassKey',
      header: 'euro',
      dictionaryName: DomainDictionaryEnum.EURO_CLASS,
      type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT
    },
    {
      id: 'typeKey',
      header: 'vehicleType',
      type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
      dictionaryName: DomainDictionaryEnum.VEHICLE_TYPE
    },
    {
      id: 'certificateSApplicationNumber',
      header: 'applicationNumber',
      type: ColumnTypesEnum.TEXT
    }
  ]);

  return { columns, mappedFilterFields };
}

export default useCertificateVehicleColumns;
