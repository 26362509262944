import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { useTableAdapter } from '../hooks';

type RowOriginal = Record<string, any>;
export type TableSelectRowsContextType = {
  toggleSelectedRow: (rowOriginal: RowOriginal, selected: boolean) => void;
  toggleSelectedRows: (originalRows: RowOriginal[], selected: boolean) => void;
  isRowSelected: (rowOriginal: RowOriginal) => boolean;
  isSelectAllIndeterminate: (rowsCount: number) => boolean;
  isSelectAllChecked: (rowsCount: number) => boolean;
  clearSelectedRows: () => void;
  selectedRows: Record<string, any>;
};

const ctxInitialState = {
  toggleSelectedRow: () => {},
  toggleSelectedRows: () => {},
  selectedRows: {},
  isRowSelected: () => false,
  isSelectAllIndeterminate: () => false,
  isSelectAllChecked: () => false,
  clearSelectedRows: () => {}
};
const TableSelectRowsContext = createContext<TableSelectRowsContextType>(ctxInitialState);
interface IProps {
  children: React.ReactNode;
}

export const TableSelectRowsContextProvider = ({ children }: React.PropsWithRef<IProps>) => {
  const [selectedRows, setSelectedRows] = useState<Record<string, any>>({});
  const { data, getRowId: getRowIdContextFn } = useTableAdapter();

  const getRowId = useCallback(
    (rowOriginal: RowOriginal) => (getRowIdContextFn ? getRowIdContextFn(rowOriginal) : rowOriginal.id),
    [getRowIdContextFn]
  );

  const selectRow = useCallback(
    (row: RowOriginal) => {
      setSelectedRows(prev => ({ ...prev, [getRowId(row)]: row }));
    },
    [getRowId]
  );

  const unselectRow = useCallback(
    (row: RowOriginal) => {
      setSelectedRows(prev => {
        const newSelectedRows = { ...prev };
        delete newSelectedRows[getRowId(row)];
        return newSelectedRows;
      });
    },
    [getRowId]
  );

  const selectRowsContextValue = useMemo(() => {
    const currentSelectedRows = Object.fromEntries(
      data
        .map(row => {
          const rowId = getRowId(row);
          return [rowId, selectedRows[rowId]];
        })
        .filter(([, value]) => Boolean(value))
    );

    return {
      toggleSelectedRow: (row: RowOriginal, selected: boolean) => {
        if (selected) {
          selectRow(row);
        } else {
          unselectRow(row);
        }
      },
      toggleSelectedRows: (rows: RowOriginal[], selected: boolean) => {
        if (selected) {
          rows.forEach(row => selectRow(row));
        } else {
          rows.forEach(row => unselectRow(row));
        }
      },
      isRowSelected: (rowOriginal: RowOriginal) => Boolean(selectedRows[getRowId(rowOriginal)]),
      isSelectAllChecked: (rowsCount: number) => rowsCount === Object.keys(currentSelectedRows).length,
      isSelectAllIndeterminate: (rowsCount: number) =>
        !selectRowsContextValue.isSelectAllChecked(rowsCount) && Object.keys(currentSelectedRows).length > 0,
      selectedRows,
      clearSelectedRows: () => {
        setSelectedRows({});
      }
    };
  }, [data, selectedRows, getRowId, selectRow, unselectRow]);

  return <TableSelectRowsContext.Provider value={selectRowsContextValue}>{children}</TableSelectRowsContext.Provider>;
};

export const useTableSelectRowsContext = () => useContext(TableSelectRowsContext);
