enum ApplicationQueryKeysEnum {
  APPLICATION_LIST = 'APPLICATION_LIST',
  APPLICATION_COUNT = 'APPLICATION_COUNT',
  APPLICATION_FLAGS = 'APPLICATION_FLAGS',
  APPLICATION = 'APPLICATION',
  APPLICATION_MISPELL = 'APPLICATION_MISPELL',
  APPLICATION_CABOTAGE = 'APPLICATION_CABOTAGE',
  APPLICATION_NOTES = 'APPLICATION_NOTES',
  APPLICATION_VERIFY = 'APPLICATION_VERIFY',
  APPLICATIONS_FOREIGN_SPO_PERMISSION_LIST = 'APPLICATIONS_FOREIGN_SPO_PERMISSION_LIST',
  TASKS = 'TASKS',
  TASK = 'TASK',
  PROCESS_DEFINITIONS = 'PROCESS_DEFINITIONS',
  PAYMENTS = 'PAYMENTS',
  PARTNER_APPLICANTS = 'PARTNER_APPLICANTS',
  OUTGOING_DOCUMENTS = 'OUTGOING_DOCUMENTS',
  DOWNLOAD_FILE = 'DOWNLOAD_FILE',
  APPLICATION_SUBJECT = 'APPLICATION_SUBJECT',
  SUBJECT = 'SUBJECT',
  SUBJECT_EXTENDED = 'SUBJECT_EXTENDED',
  APPLICATION_DEFINITIONS = 'APPLICATION_DEFINITIONS',
  APPLICATION_HISTORY = 'APPLICATION_HISTORY',
  APPLICATION_ADDRESSES = 'APPLICATION_ADDRESSES',
  APPLICATION_DECISION = 'APPLICATION_DECISION',
  APPLICATION_UI_DEFINITION = 'APPLICATION_UI_DEFINITION',
  APPLICATION_UI_DEFINITION_COMMON = 'APPLICATION_UI_DEFINITION_COMMON',
  TRANSIT_SCHEDULE_DETAILS = 'TRANSIT_SCHEDULE_DETAILS',
  TRANSIT_SCHEDULE_DETAILS_STOPS = 'TRANSIT_SCHEDULE_DETAILS_STOPS',
  TRANSIT_DATES = 'TRANSIT_DATES',
  TRANSIT_FREQUENCIES = 'TRANSIT_FREQUENCIES',
  GENERATE_LABEL = 'GENERATE_LABEL',
  RENOUNCE_COPY = 'RENOUNCE_COPY',
  CATEGORY = 'CATEGORY'
}

export default ApplicationQueryKeysEnum;
