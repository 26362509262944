import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormMode, TableLayout } from '@libs/common/v2';

import { API, parseUserReplacementsData } from '@libs/user/api';
import { ReplacementsHeaderActions, ReplacementsRowActions } from '@libs/user/components';
import { useReplacementsTable } from '@libs/user/hooks';

function ReplacementsTab() {
  const [t] = useTranslation();
  const tableProps = useReplacementsTable();

  return (
    <div>
      <TableLayout
        {...tableProps}
        pageTitle={t('user:tab.replacements')}
        xlsxDownload={{
          fileName: t('user:tab.replacements'),
          pathToXLSXTranslation: 'user:replacements.field',
          apiRequest: params =>
            API.group.findMyReplacements(params).then(res => {
              return { ...res, data: { ...res.data, content: parseUserReplacementsData(res.data.content) } };
            })
        }}
        rowActions={({ original }) => <ReplacementsRowActions original={original} />}
        headerActions={<ReplacementsHeaderActions formMode={FormMode.CREATE} />}
        isSection
      />
    </div>
  );
}

export default ReplacementsTab;
