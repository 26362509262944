import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

async function createReputation(params: { id: string }) {
  const { data } = await API.subject.createSubjectReputationRequest(params.id, {
    ...endpointsConfig.createSubjectReputationRequest
  });
  return data;
}

function useCreateSubjectReputationRequestMutation() {
  return useMutation(createReputation);
}

export default useCreateSubjectReputationRequestMutation;
