import { AxiosResponse } from 'axios';
import _ from 'lodash';

import {
  DropdownButton,
  IPaginatedModel,
  TableButton,
  TableButtonFilters,
  TableButtonRefetch,
  TableXLSXDownloader
} from '../..';

interface IProps<T extends Record<string, any>> {
  isFilterEnabled?: boolean;
  isRefreshEnabled?: boolean;
  xlsxDownload?: {
    filterConverter?: Record<string, (value: unknown) => Record<string, any>>;
    sortByConverter?: Partial<Record<keyof T, string>>;
    initialParamsConverter?: Record<string, (value: unknown) => Record<string, any>>;
    additionalParams?: Record<string, any>;
    apiRequest?: (parameters: {
      page: number;
      size: number;
      sort?: any;
      [_: string]: any;
    }) => Promise<AxiosResponse<IPaginatedModel<T>>>;
    fileName: string;
    pathToXLSXTranslation: string;
  };
  tableQueryParams?: Record<string, unknown>;
  additionalActions?: Array<{
    label: string;
    onClick: (e: any) => void;
    actionKey?: string;
    isHidden?: boolean;
  }>;
}

export function TableHeaderMobile<T>({
  additionalActions,
  isFilterEnabled,
  isRefreshEnabled,
  xlsxDownload,
  tableQueryParams
}: IProps<T>) {
  const isVisible = !_.isEmpty(additionalActions) || isFilterEnabled || isRefreshEnabled || xlsxDownload;
  if (!isVisible) {
    return null;
  }
  return (
    <DropdownButton>
      {({ handleClose }) => (
        <>
          {additionalActions
            ?.filter(({ isHidden }) => !isHidden)
            ?.map(({ actionKey, label, onClick }) => (
              <TableButton
                key={label}
                label={label}
                onClick={e => {
                  handleClose();
                  onClick(e);
                }}
                actionKey={actionKey}
              />
            ))}
          {isFilterEnabled && <TableButtonFilters onClick={handleClose} isMobile />}
          {isRefreshEnabled && <TableButtonRefetch onBeforeReset={handleClose} isMobile />}
          {xlsxDownload && (
            <TableXLSXDownloader
              {...xlsxDownload}
              mappedFilterFields={xlsxDownload.filterConverter}
              mappedSortFields={xlsxDownload.sortByConverter}
              mappedParamsFields={xlsxDownload.initialParamsConverter}
              tableQueryParams={tableQueryParams}
              onFileGenerated={() => {
                handleClose();
              }}
              isMobile
            />
          )}
        </>
      )}
    </DropdownButton>
  );
}
