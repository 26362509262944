import { ReactNode, useMemo } from 'react';
import { FieldPath, FieldValues, useFieldArray } from 'react-hook-form';
import _ from 'lodash';

import { FormMode, useFormV2Context } from '@libs/common/v2';

import { ConditionOperator, SingleConditionEmptyValue } from '../../../model';

import { Condition } from './Condition';
import { ConditionGroup } from './condition-group';

interface IProps {
  fieldName: FieldPath<FieldValues>;
  formMode: FormMode;
  children: ReactNode | ((formName: string, index: number, onConditionRemove: (index: number) => void) => ReactNode);
  index?: number;
  onChildRemove?: (childIndex: number) => void;
}

function ConditionBuilder({ fieldName, onChildRemove, formMode, children, index }: IProps) {
  const { control, setValue, watch, trigger } = useFormV2Context();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${fieldName}.group` as FieldPath<FieldValues>
  });

  const operator = watch(`${fieldName}.operator`);

  const handleAddGroup = () => {
    trigger('query');
    append({ filter: null, group: [], operator: ConditionOperator.AND });
  };

  const handleAddCondition = () => {
    trigger('query');
    append({ filter: SingleConditionEmptyValue, group: null, operator: null });
  };

  const handleRemoveChildByIndex = (childIndex: number) => {
    remove(childIndex);
  };

  const onConditionRemove = (index: number) => {
    remove(index);
  };

  const handleRemoveGroup = () => {
    if (!_.isNil(index)) {
      onChildRemove(index);
    }
  };

  function renderSingleFieldContent(formName: string, index: number, onConditionRemove: (index: number) => void) {
    return typeof children === 'function' ? children(formName, index, onConditionRemove) : children;
  }

  const operatorParsedValue = useMemo(() => {
    if (operator) {
      return _.isString(operator) ? operator : operator.value;
    }
    return ConditionOperator.AND;
  }, [operator]);

  return (
    <ConditionGroup
      operator={operatorParsedValue}
      onAddGroup={handleAddGroup}
      onAddCondition={handleAddCondition}
      onOperatorChange={operator => {
        setValue(`${fieldName}.operator`, operator, { shouldDirty: true });
      }}
      onDelete={() => handleRemoveGroup()}
      index={index}
    >
      {fields.map((field, i) => {
        // @ts-ignore
        return field?.group ? (
          <ConditionBuilder
            key={field.id}
            fieldName={`${fieldName}.group.${i}`}
            onChildRemove={handleRemoveChildByIndex}
            formMode={formMode}
            index={i}
          >
            {children}
          </ConditionBuilder>
        ) : (
          <Condition key={field.id}>
            {renderSingleFieldContent(`${fieldName}.group.${i}.filter`, i, onConditionRemove)}
          </Condition>
        );
      })}
    </ConditionGroup>
  );
}

export default ConditionBuilder;
