import { ForeignPermissionApplicationAddRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function addForeignPermissionApplicationRecord({
  applicationId,
  foreignPermissionApplicationAddRequest
}: {
  applicationId: string;
  foreignPermissionApplicationAddRequest: ForeignPermissionApplicationAddRequest;
}) {
  return API.applicationForeignPermissions.add(applicationId, foreignPermissionApplicationAddRequest, {
    ...endpointsConfig.addForeignPermission
  });
}

export function useAddForeignPermissionApplicationRecordsMutation() {
  return useMutation(addForeignPermissionApplicationRecord);
}
