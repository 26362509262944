import { DomainUIElementEnum } from '../../config';
import { ApplicationTabIdEnum } from '../model';

export const TAB_PERMISSIONS: Record<string, DomainUIElementEnum> = {
  [ApplicationTabIdEnum.TRANSPORT_MANAGERS]: DomainUIElementEnum.APPLICATION_TRANSPORT_MANAGERS_VIEW,
  [ApplicationTabIdEnum.TRANSIT_SCHEDULES]: DomainUIElementEnum.APPLICATION_TRANSIT_SCHEDULE_VIEW,
  [ApplicationTabIdEnum.PARTNER_APPLICANTS]: DomainUIElementEnum.APPLICATION_PARTNER_APPLICANT_SUBJECT_LIST_VIEW,
  [ApplicationTabIdEnum.DRIVERS]: DomainUIElementEnum.APPLICATION_DRIVERS_VIEW,
  [ApplicationTabIdEnum.QUOTATION]: DomainUIElementEnum.APPLICATION_PRICING_VIEW,
  [ApplicationTabIdEnum.CABOTAGE]: DomainUIElementEnum.APPLICATION_CABOTAGE_VIEW,
  [ApplicationTabIdEnum.GENERAL]: DomainUIElementEnum.APPLICATION_GENERAL_DATA_VIEW,
  [ApplicationTabIdEnum.VEHICLES]: DomainUIElementEnum.APPLICATION_VEHICLES_VIEW,
  [ApplicationTabIdEnum.PROXIES]: DomainUIElementEnum.APPLICATION_PROXY_VIEW,
  [ApplicationTabIdEnum.ATTACHMENTS]: DomainUIElementEnum.APPLICATION_VERIFICATION_ELEMENT_LIST_VIEW,
  [ApplicationTabIdEnum.HISTORY]: DomainUIElementEnum.APPLICATION_HISTORY_VIEW,
  [ApplicationTabIdEnum.OUTGOING_DOCUMENTS]: DomainUIElementEnum.APPLICATION_DOCUMENT_LIST_VIEW,
  [ApplicationTabIdEnum.NOTE]: DomainUIElementEnum.APPLICATION_NOTE_VIEW,
  [ApplicationTabIdEnum.FILES]: DomainUIElementEnum.APPLICATION_FILE_LIST_VIEW,
  [ApplicationTabIdEnum.SUBJECT]: DomainUIElementEnum.APPLLICATION_SUBJECT_VIEW,
  [ApplicationTabIdEnum.EXPLOITATION_BASE]: DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_VIEW,
  [ApplicationTabIdEnum.FOREIGN_PERMISSIONS_SINGLE]: DomainUIElementEnum.APPLICATION_FOREIGN_PERMISSIONS_SINGLE_VIEW,
  [ApplicationTabIdEnum.ADDRESSES]: DomainUIElementEnum.APPLICATION_ADDRESSES_VIEW,
  [ApplicationTabIdEnum.FINANCIAL_SECURITY]: DomainUIElementEnum.APPLICATION_FINACIAL_SECURITY_VIEW,
  [ApplicationTabIdEnum.PERMISSIONS]: DomainUIElementEnum.APPLICATION_PERMISSIONS_VIEW,
  [ApplicationTabIdEnum.SCERTIFICATES]: DomainUIElementEnum.APPLICATION_S_CERTIFICATES_SHOW_DETAILS_BUTTON
};
