import { ResourceTypeUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

type ResourceTypeUpdateParams = { id: string; data: ResourceTypeUpdateRequest };

function updateResourceType({ id, data }: ResourceTypeUpdateParams) {
  return API.resourceTypes.updateResourceType(id, data, {
    ...endpointsConfig.updateResourceType
  });
}

export function useUpdateResourceTypeMutation() {
  return useMutation(updateResourceType);
}
