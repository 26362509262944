import { useMemo } from 'react';

import { SelectOption, useFormV2Context, useFormV2Watch } from '@libs/common/v2';

import { AutocompleteLazyFetchField } from '@libs/meta-form/fields';
import { AutocompleteFieldProperties, FieldProps } from '@libs/meta-form/models';

import { DomainDictionaryEntry } from '@libs/domain/config';

export type FolderSelectFieldProps = {
  hasActiveLicense?: boolean;
  folderTypeKey?: string;
} & FieldProps<AutocompleteFieldProperties>;

function FoldersSelectField({ hasActiveLicense, folderTypeKey, ...rest }: FolderSelectFieldProps) {
  const { clearErrors } = useFormV2Context();
  const { fieldId } = rest;

  const transferType = useFormV2Watch<SelectOption<string>>({
    name: 'licenseApplicationDetails-transferApplicationDataDetails-transferTypeKey'
  });

  const isMultipleTransfer = useFormV2Watch<SelectOption<boolean>>({
    name: 'licenseApplicationDetails-transferApplicationDataDetails-folders-boolean'
  });

  const isRequiredByTransferType = useMemo(
    () =>
      (!hasActiveLicense && transferType?.value !== DomainDictionaryEntry.LICENSE_TRANSFER_TYPE.TRANSFORMATION) ||
      isMultipleTransfer,
    [hasActiveLicense, transferType?.value, isMultipleTransfer]
  );

  const isRequired = useMemo(() => {
    clearErrors(fieldId);
    return Boolean(isRequiredByTransferType);
  }, [clearErrors, fieldId, isRequiredByTransferType]);

  return <AutocompleteLazyFetchField required={isRequired} {...rest} />;
}

export default FoldersSelectField;
