import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DictionarySelectField,
  GridLayout,
  SelectWithFetchedOptionsField,
  TextInputField,
  useFormV2Context
} from '@libs/common/v2';

import { API } from '@libs/report/api';
import { ReportDictionaryEntryNameEnum } from '@libs/report-core';

interface IProps {
  index?: number;
  isEditMode: boolean;
  closeDialog: () => void;
  onSubmit: (formValues, index) => void;
}

function NewReportTypeDataSectionDialog({ index, closeDialog, onSubmit, isEditMode }: IProps) {
  const [t] = useTranslation();
  const { handleSubmit, isSubmitting } = useFormV2Context();

  const onFormSubmit = useCallback(
    values => {
      onSubmit(values, index);
      closeDialog();
    },
    [index, closeDialog, onSubmit]
  );

  return (
    <Dialog
      title={isEditMode ? t('reports:reportTypes.editDataSetTitle') : t('reports:reportTypes.addNewDataSetTitle')}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onCancel={() => closeDialog()}
      onConfirm={handleSubmit(onFormSubmit)}
      isConfirmLoading={isSubmitting}
      dialogSize="small"
      isOpen
    >
      <GridLayout itemProps={{ xs: 12 }}>
        <TextInputField name="name" label={t('reports:field.dataSetName')} isRequired />
        <SelectWithFetchedOptionsField
          fetchFunction={text => API.mediatorDataSources.getReportSources(0, 10, null, null, null, text)}
          getOptionLabel={source => source.name}
          label={t('reports:field.reportSource')}
          name="source"
          isClearable={false}
          isRequired
        />
        <DictionarySelectField
          name="dataFileFormatKey"
          label={t('reports:reportTypes.dataFileFormatKey')}
          dictionaryName={ReportDictionaryEntryNameEnum.REPORT_DATASET_RESULT_FILE_FORMAT}
        />
      </GridLayout>
    </Dialog>
  );
}

export default NewReportTypeDataSectionDialog;
