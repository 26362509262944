import { useState } from 'react';

import { TErrorMessage, TNewError } from '@libs/common/v2';

/**
 * Tego hook należy użyć tylko raz przy tworzeniu context komunikatów systemowych w App.
 * Niżej należy uzywać `useSystemNotificationContext` hook.
 */
function useSystemNotification() {
  const [snackbarMessages, setSnackbarMessages] = useState<TErrorMessage[]>([]);

  const addSystemMessages = (message: TNewError & { id: string }) => {
    setSnackbarMessages(prev => [message, ...prev]);
  };

  const clearAllSystemMessages = () => {
    setSnackbarMessages([]);
  };

  const deleteSystemMessage = (id: string) => {
    setSnackbarMessages(prev => prev.filter(item => item.id !== id));
  };

  return { snackbarMessages, addSystemMessages, clearAllSystemMessages, deleteSystemMessage };
}

export default useSystemNotification;
