import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const deleteOutgoingDocument = (params: { proceedingId: string; documentId: string }) => {
  const { proceedingId, documentId } = params;
  return API.proceeding.deleteProceedingsDocument(proceedingId, documentId, {
    ...endpointsConfig.deleteProceedingsDocument
  });
};

export const useProceedingDocumentDeleteMutation = () => useMutation(deleteOutgoingDocument);
