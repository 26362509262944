import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { TransportManagerUpdateRequestCustom } from '../../model';
import { endpointsConfig } from '../endpoints-config';

async function updateTransportManager(params: {
  id: string;
  transportManagerUpdateRequest: TransportManagerUpdateRequestCustom;
}) {
  const { data } = await API.transportManager.updateTransportManager(params.id, params.transportManagerUpdateRequest, {
    ...endpointsConfig.updateTransportManager
  });
  return data;
}

async function updateTransportManagerClient({
  id,
  transportManagerUpdateRequest: {
    numberOtherSubjectVehiclesRegisteredAsManager,
    numberOtherSubjectVehiclesAppointedAsManager,
    numberOtherSubjectsRegisteredAsManager,
    numberOtherSubjectsAppointedAsManager,
    ...transportManagerUpdateRequestRest
  }
}: {
  id: string;
  transportManagerUpdateRequest: TransportManagerUpdateRequestCustom;
}) {
  const { data } = await API.client.transportManager.updateTransportManager(
    id,
    '',
    '',
    {
      numberOtherSubjectVehiclesRegisteredAsManager,
      numberOtherSubjectVehiclesAppointedAsManager,
      numberOtherSubjectsRegisteredAsManager,
      numberOtherSubjectsAppointedAsManager,
      ...transportManagerUpdateRequestRest
    },
    {
      ...endpointsConfig.updateTransportManager
    }
  );

  return data;
}

function useUpdateTransportManagerMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(updateTransportManagerClient, updateTransportManager));
}

export default useUpdateTransportManagerMutation;
