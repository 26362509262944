import { useTranslation } from 'react-i18next';
import { SuspensionSnapshot } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  InputMode,
  SectionWithFields,
  SwitchField,
  TextInputField,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import useErrorContext from '@libs/domain/suspensions/context/ErrorContext';

export default function SuspensionDetailsTab() {
  const [t] = useTranslation();

  const getLabel = partialTranslate('suspensions:details.field');
  const { watch, formMode } = useFormV2Context();
  const { editMode } = useViewModesV2(formMode);
  const { isCaseNumberError } = useErrorContext();
  const hasSuspendedForms = watch(typedNameV2<SuspensionSnapshot>('hasSuspendedForms')) as boolean;

  return (
    <SectionWithFields
      title={t('suspensions:details.title')}
      fields={[
        <TextInputField
          name={typedNameV2<SuspensionSnapshot>('number')}
          label={getLabel('number')}
          inputMode={InputMode.VIEW}
          key="number"
          isDisabled={hasSuspendedForms}
        />,
        <TextInputField
          name={typedNameV2<SuspensionSnapshot>('caseNumber')}
          label={getLabel('caseNumber')}
          key="caseNumber"
          placeholder={isCaseNumberError && t('suspensions:details.message.addCaseNumber')}
          isRequired={isCaseNumberError}
          isDisabled={!isCaseNumberError || hasSuspendedForms}
        />,
        <DatepickerField
          name={typedNameV2<SuspensionSnapshot>('dateFrom')}
          label={getLabel('dateFrom')}
          key="dateFrom"
          isRequired
          isDisabled={hasSuspendedForms}
        />,
        <DatepickerField
          name={typedNameV2<SuspensionSnapshot>('dateTo')}
          label={getLabel('dateTo')}
          key="dateTo"
          isRequired
          isDisabled={hasSuspendedForms}
        />,
        <DatepickerField
          name={typedNameV2<SuspensionSnapshot>('dateOfIssue')}
          label={getLabel('applicationDate')}
          key="applicationDate"
          isRequired
          isDisabled={hasSuspendedForms}
        />,
        <TextInputField
          name={typedNameV2<SuspensionSnapshot>('months')}
          label={getLabel('months')}
          inputMode={InputMode.VIEW}
          key="months"
          isDisabled={hasSuspendedForms}
        />,
        <SwitchField
          name={typedNameV2<SuspensionSnapshot>('whole')}
          label={getLabel('whole')}
          {...(editMode
            ? {
                inputMode: InputMode.VIEW
              }
            : {})}
          key="whole"
          isDisabled={hasSuspendedForms}
        />
      ]}
    />
  );
}
