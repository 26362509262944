import { useTranslation } from 'react-i18next';

import { Button } from '@libs/common/v2';

import { useFileDownload } from '@libs/file';
import { UIElementNameEnum } from '@libs/permission';

import useUploadFileDialog from './useUploadFileDialog';

function ImportComitteeDecisionButton({
  isEkmtCommittee,
  actionKey
}: {
  isEkmtCommittee?: boolean;
  actionKey?: UIElementNameEnum;
}) {
  const [t] = useTranslation();

  const { isLoading: isFileDownloading } = useFileDownload();
  const { openUploadFileDialog } = useUploadFileDialog({ isEkmtCommittee });

  return (
    <Button
      variant="outlined"
      onClick={openUploadFileDialog}
      label={t('foreignPermits:other.importFile')}
      actionKey={actionKey}
      isLoading={isFileDownloading}
    />
  );
}

export default ImportComitteeDecisionButton;
