import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, OnFilterChange } from 'react-table';
import { makeStyles } from '@mui/styles';

import NumberFilter from './NumberFilter';

export interface NumberRange {
  min?: number;
  max?: number;
  step?: number;
}

interface IProps extends NumberRange {
  column: Column & {
    filterValue?: { min?: number; max?: number };
    setFilter: (value: { min?: number; max?: number }) => void;
  };
  onFilterChange: OnFilterChange;
}

function NumberRangeFilter({
  column: { setFilter, filterValue },
  min: filterMin,
  max: filterMax,
  step,
  onFilterChange
}: IProps) {
  const [t] = useTranslation();
  const value = useRef(filterValue ?? { min: null, max: null });
  const classes = useStyles();

  const setMinValue = (newMin: number | string) => {
    setFilter({
      min: newMin,
      max: value.current?.max
    });
  };
  const setMaxValue = (newMax: number | string) => {
    setFilter({
      min: value.current?.min,
      max: newMax
    });
  };

  return (
    <div className="flex">
      <div className={classes.inputWrapper}>
        <NumberFilter
          placeholder={t('from')}
          min={filterMin}
          max={filterValue?.max || filterMax}
          step={step}
          column={{ filterValue: filterValue?.min, setFilter: setMinValue }}
          onFilterChange={onFilterChange}
          onChange={newMin => {
            value.current.min = newMin;
          }}
          isClearable
        />
      </div>
      <NumberFilter
        placeholder={t('to')}
        min={filterValue?.min || filterMin}
        max={filterMax}
        step={step}
        column={{ filterValue: filterValue?.max, setFilter: setMaxValue }}
        onFilterChange={onFilterChange}
        onChange={newMax => {
          value.current.max = newMax;
        }}
        isHalfWidth
        isClearable
      />
    </div>
  );
}

const useStyles = makeStyles({
  inputWrapper: {
    width: '50%',
    '& label:first-child': {
      marginRight: 6
    }
  }
});

export default NumberRangeFilter;
