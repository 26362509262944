import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { FormActionEnum, TableIconButton, useFormActionModal } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useFormDirtyContext, useMetaFormContext } from '@libs/meta-form/context';
import { UIElementNameEnum } from '@libs/permission';

import { ApplicationQueryKeysEnum } from '@libs/domain/application';
import { useDeleteVehicleMutation, VehicleQueryKeysEnum } from '@libs/domain/vehicle';

interface IProps {
  vehicleId: string;
  actionKey?: UIElementNameEnum;
}

function RemoveVehicleButton({ vehicleId, actionKey }: IProps) {
  const { isDirty } = useFormDirtyContext();
  const { formRef } = useMetaFormContext();
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const { mutate: deleteVehicle } = useDeleteVehicleMutation();
  const saveTabAction = useFormActionModal();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  const handleSaveTabFormChanges = async () => {
    try {
      await formRef?.current?.onSubmit();
      handleDelete();
    } catch {
      showErrorSnackbar(t('error.discardChangesOrCorrectFormDataToChangeVehicle'));
    }
  };

  const handleDiscardTabFormChanges = () => {
    formRef?.current?.onReset();
    handleDelete();
  };

  const handleOnClick = () => {
    if (isDirty) {
      saveTabAction({
        formAction: FormActionEnum.SAVE_CHANGES,
        onConfirm: () => handleSaveTabFormChanges(),
        onCancel: () => handleDiscardTabFormChanges()
      });
    } else {
      handleDelete();
    }
  };

  const handleDelete = () => {
    setLoading(true);
    deleteVehicle(
      { vehicleId },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('success.save'));
          queryCache.invalidateQueries(VehicleQueryKeysEnum.VEHICLE_LIST);
          queryCache.invalidateQueries(ApplicationQueryKeysEnum.APPLICATION);
          setLoading(false);
        }
      }
    );
  };

  return <TableIconButton isButtonLoading={loading} icon="TrashIcon" onClick={handleOnClick} actionKey={actionKey} />;
}

export default RemoveVehicleButton;
