import { PluginHook } from 'react-table';
import { DriversSearchFilter as DriversSearchFilterClient } from '@ibtm/client-domain';
import { DriverDetails, DriversSearchFilter } from '@ibtm/domain';

import { CamelCasePath, useTableLayoutProps } from '@libs/common/v2';

import { DriverCertificateStatusEnum } from '@libs/domain/driver-certificate/model';
import { useDriversListQuery } from '@libs/domain/drivers/api';
import { useDriversColumns } from '@libs/domain/drivers/hooks';

export interface IDriverTableOptions {
  initialState?: {
    filters?: (
      | { id?: string; value?: { value?: DriverCertificateStatusEnum }[] }
      | { id: string; value: string }
      | { id: string; value: { from: string; to: string } }
    )[];
  };
}
interface Props {
  visibleColumns: Array<CamelCasePath<DriverDetails>>;
  tableOptions: IDriverTableOptions;
  tablePlugins: PluginHook<any>[];
  queryParams?: DriversSearchFilter | DriversSearchFilterClient;
}

function useDriversTable(
  { visibleColumns, tableOptions, tablePlugins, queryParams }: Props,
  isSortingDisabled?: boolean
) {
  const { columns, mappedSortFields, mappedFilterFields } = useDriversColumns(isSortingDisabled, visibleColumns);

  return useTableLayoutProps({
    tableHookQuery: useDriversListQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tablePlugins,
      tableOptions
    },
    tableHookQueryInitialParams: { ...queryParams }
  });
}

export default useDriversTable;
