import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { Dialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { ArchiveQueryKeysEnum } from '../../../common';
import { useRestoreArchivedApplicationMutation } from '../../api';

interface IApplicationsFromArchiveDialog {
  ids: string[];
  closeDialog: () => void;
}

const ApplicationsFromArchiveDialog = ({ ids, closeDialog }: IApplicationsFromArchiveDialog) => {
  const [t] = useTranslation();
  const queryCache = useQueryCache();
  const { showSnackbar } = useSnackbar();

  const { mutateAsync: restoreArchivedApplication, isLoading } = useRestoreArchivedApplicationMutation();

  return (
    <Dialog
      isOpen
      onConfirm={async () => {
        await restoreArchivedApplication(
          {
            ids
          },
          {
            onSuccess: () => {
              closeDialog();
              queryCache.invalidateQueries(ArchiveQueryKeysEnum.ARCHIVED_APPLICATIONS);

              showSnackbar('success', t('archive:application.message.restoredFromArchive'));
            }
          }
        );
      }}
      confirmText={t('action.restore')}
      title={t('archive:restoringFromArchive')}
      cancelText={t('action.cancel')}
      onCancel={closeDialog}
      onClose={closeDialog}
      isConfirmLoading={isLoading}
    >
      {t('archive:application.message.fromArchiveDialogQuestion')}
    </Dialog>
  );
};

export default ApplicationsFromArchiveDialog;
