export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  updateInvoiceStatus: { translationKey: 'releaseDocuments:api.updateInvoiceStatus' },
  createInvoice: { translationKey: 'releaseDocuments:api.createInvoice' },
  generateInvoiceDocument: { translationKey: 'releaseDocuments:api.generateInvoiceDocument' },
  updateInvoiceRecipient: { translationKey: 'releaseDocuments:api.updateInvoiceRecipient' },
  createSuspensionInvoice: { translationKey: 'releaseDocuments:api.createSuspensionInvoice' },
  updateSuspensionInvoice: { translationKey: 'releaseDocuments:api.updateSuspensionInvoice' },
  updateInvoice: { translationKey: 'releaseDocuments:api.updateInvoice' },
  getInvoiceSnapshotPage: { translationKey: 'releaseDocuments:api.getInvoiceSnapshotPage' },
  getSuspensionInvoices: { translationKey: 'releaseDocuments:api.getSuspensionInvoices' },
  getInvoicesSnapshotPage: { translationKey: 'releaseDocuments:api.getInvoicesSnapshotPage' },
  getInvoice: { translationKey: 'releaseDocuments:api.getInvoice' }
};
