import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import {
  ForeignPermissionApplicationRecordWithLimitDetails,
  ForeignPermissionApplicationRecordWithLimitList
} from '@ibtm/domain';

import {
  CamelCasePath,
  FormMode,
  TableHeaderButton,
  TableIconButton,
  TableLayout,
  useDialog,
  useFormV2Context
} from '@libs/common/v2';

import useGetForeignPermissionApplicationRecords, {
  ForeignPermissionApplicationRecordsQueryParams
} from '@libs/domain/application/api/queries/useGetForeignPermissionApplicationRecords';

import { parseConcernPermissionAsSelectOption } from '../utils/foreignPermissionApplicationUtils';

import { DeleteApplicationForeignPermissionButton } from './DeleteApplicationForeignPermissionButton';
import EkmtTableEditFormDialog from './EkmtTableEditFormDialog';
import { EkmtColumns } from './useEkmtColumns';
import useEkmtTable from './useEkmtTable';

interface IProps {
  applicationId: string;
  folderId: string;
  title: string;
  visibleColumns: EkmtColumns;
  editableColumns: EkmtColumns;
  viewMode: boolean;
  addButtonVisible?: boolean;
  isDeleteButtonVisible?: boolean;
  ekmtCategoryKeys?: Array<string>;
  queryParams?: Omit<ForeignPermissionApplicationRecordsQueryParams, 'applicationId'>;
  validateUniqueResourceTypeInList?: boolean;
}

const isEkmtColumnEditable = (
  columnName: CamelCasePath<ForeignPermissionApplicationRecordWithLimitDetails>,
  editableColumns: EkmtColumns
) => editableColumns.includes(columnName);

function EkmtTable({
  applicationId,
  title,
  viewMode,
  folderId,
  editableColumns,
  visibleColumns,
  addButtonVisible,
  ekmtCategoryKeys,
  isDeleteButtonVisible,
  queryParams,
  validateUniqueResourceTypeInList
}: IProps) {
  const { data, isFetching: isLoading } = useGetForeignPermissionApplicationRecords({ applicationId, ...queryParams });
  const { openDialog } = useDialog();

  const isColumnEditable = (columnName: CamelCasePath<ForeignPermissionApplicationRecordWithLimitDetails>) =>
    isEkmtColumnEditable(columnName, editableColumns);
  const dataWithIds = useMemo(
    () =>
      (data as ForeignPermissionApplicationRecordWithLimitList)?.content?.map(item => ({
        ...item,
        concernForeignPermission: parseConcernPermissionAsSelectOption(item.concernForeignPermission),
        id: item.id || item.resourceType.id
      })),
    [data]
  );
  const [t] = useTranslation();
  const tableProps = useEkmtTable({
    dataWithIds: dataWithIds || [],
    visibleColumns
  });
  const { getValues } = useFormV2Context();

  const rowActions = (row: Row<ForeignPermissionApplicationRecordWithLimitDetails>) => {
    return (
      <>
        <TableIconButton
          tooltipTitle={t('other:component.table.edit')}
          icon="EditIcon"
          onClick={() =>
            openDialog(({ closeDialog }) => (
              <EkmtTableEditFormDialog
                editFormData={row.original}
                getParentFormValues={getValues}
                applicationId={applicationId}
                folderId={folderId}
                closeDialog={closeDialog}
                formMode={FormMode.EDIT}
                isFieldEditable={isColumnEditable}
                validateUniqueResourceTypeInList={validateUniqueResourceTypeInList}
                isNoteFieldVisible={visibleColumns.some(column => column === 'notes')}
                ekmtCategoryKeys={ekmtCategoryKeys}
                tableRowsData={tableProps?.tableQuery}
              />
            ))
          }
        />
        {isDeleteButtonVisible && (
          <DeleteApplicationForeignPermissionButton
            applicationId={applicationId}
            foreignPermissionRecordId={row?.original?.id}
          />
        )}
      </>
    );
  };

  return (
    <TableLayout<ForeignPermissionApplicationRecordWithLimitDetails, ForeignPermissionApplicationRecordWithLimitDetails>
      {...tableProps}
      pageTitle={title}
      rowActions={rowActions}
      isActionColumnEnabled={!viewMode}
      isLoading={isLoading}
      isRefreshEnabled={false}
      isFilterEnabled={false}
      isPaginated={false}
      headerActions={
        !viewMode &&
        addButtonVisible && (
          <span>
            <TableHeaderButton
              variant="outlined"
              label={t('action.add')}
              onClick={() =>
                openDialog(({ closeDialog }) => (
                  <EkmtTableEditFormDialog
                    getParentFormValues={getValues}
                    applicationId={applicationId}
                    folderId={folderId}
                    closeDialog={closeDialog}
                    formMode={FormMode.CREATE}
                    isFieldEditable={isColumnEditable}
                    validateUniqueResourceTypeInList={validateUniqueResourceTypeInList}
                    isNoteFieldVisible={visibleColumns.some(column => column === 'notes')}
                    ekmtCategoryKeys={ekmtCategoryKeys}
                    tableRowsData={tableProps?.tableQuery}
                  />
                ))
              }
            />
          </span>
        )
      }
      isCollapsable
      isSection
    />
  );
}

export default EkmtTable;
