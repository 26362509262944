import { useTranslation } from 'react-i18next';

import { Chip } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';

function PrartnershipTypeChip({ folderTypeKey }) {
  const [t] = useTranslation();

  const isAssociation = folderTypeKey === DomainDictionaryEntry.FOLDER_TYPE.ZZ;

  return (
    <Chip
      title={isAssociation ? t('folder:subjectType.association') : t('folder:subjectType.enterprise')}
      color={isAssociation ? 'purple' : 'blue'}
      colorWeight="700"
    />
  );
}

export default PrartnershipTypeChip;
