import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { queryCache } from 'react-query';
import { DropdownButton, useSnackbar } from '@enigma/fe-ui';
import { PermissionTypeCode } from '@ibtm/domain';
import { useAppConfig } from '@libs/app-config';
import _ from 'lodash';

import { TableContext } from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';
import useGenerateReport from '@libs/report/hooks/useGenerateReport';

import { DomainDictionaryEntry, DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';
import { DocumentsQueryKeysEnum, useGenerateApplicationDocumentDialog } from '@libs/domain/documents';
import { DriverCertificatesColumnsEnum, DriverCertificatesExtendedTable } from '@libs/domain/driver-certificate';
import {
  LicenseCopiesColumnsEnum,
  LicenseCopiesTableV2,
  LicensesColumnsEnum,
  LicensesTableV2
} from '@libs/domain/license';
import { PermissionTypeEnum } from '@libs/domain/permission';
import { PermissionCopiesColumnsEnum, PermissionsColumnsEnum } from '@libs/domain/permission/model/permission.model';
import { PrintCertificatesColumnsEnum, SCertificatesTableV2 } from '@libs/domain/s-certificates';

import { useGenerateApplicationDocumentMutation } from '../../../application';
import { PermissionCopiesTableV2 } from '../../../permission/components';
import PermissionsTableV2 from '../../../permission/components/PermissionsTableV2';
import { useGenerateEkmtCarnetMutation } from '../../api';
import PrintsQueryKeysEnum from '../../api/queries/PrintsQueryKeysEnum';
import { usePrintsDetailsDataContext } from '../../context';
import useFetchPrintsApplicationDetails from '../../hooks/useFetchPrintsApplicationDetails';
import useGetGenerateButtonTitle from '../../hooks/useGetGenerateButtonTitle';
import usePrintTablesConfiguration from '../../hooks/usePrintTablesConfiguration';
import { useSPOApplicationForeignPermissionsFlags } from '../../hooks/useSPOApplicationForeignPermissionsFlags';
import { DEFAULT_PRINT_TABLE_SORTING } from '../../model';
import { PrintForeignPermissionColumnsEnum } from '../../model/print-model';

import { useAmountOfIcons } from './actions/PermissionsTablesRowActions';
import { GeneratePermissionButton, PrintAllPermissionsButton, rowActionRenderer } from './actions';
import { PrintForeignPermissionEkmtTable } from './PrintForeignPermissionEkmtTable';
import { PrintForeignPermissionTable } from './PrintForeignPermissionTable';
import { TravelFormTable } from '.';

const TRANSPORT_PERMISSIONS_COLUMNS: Array<PermissionsColumnsEnum> = [
  PermissionsColumnsEnum.PERMISSION_NUMBER,
  PermissionsColumnsEnum.FORM_NUMBER,
  PermissionsColumnsEnum.SUBJECT_NAME,
  PermissionsColumnsEnum.PRINT_DATE,
  PermissionsColumnsEnum.VALID_FROM,
  PermissionsColumnsEnum.VALID_TO,
  PermissionsColumnsEnum.PERMISSION_VALIDITY_PERIOD_KEY
];

const TRANSPORT_PERMISSIONS_COPIES_COLUMNS: Array<PermissionCopiesColumnsEnum> = [
  PermissionCopiesColumnsEnum.PERMISSION_COPY_NUMBER,
  PermissionCopiesColumnsEnum.FORM_NUMBER,
  PermissionCopiesColumnsEnum.PRINT_DATE,
  PermissionCopiesColumnsEnum.VALID_FROM,
  PermissionCopiesColumnsEnum.VALID_TO
];

const CERTIFICATES_S_COLUMNS: Array<PrintCertificatesColumnsEnum> = [
  PrintCertificatesColumnsEnum.VEHICLE_PLATE_NUMBER,
  PrintCertificatesColumnsEnum.NUMBER,
  PrintCertificatesColumnsEnum.VEHICLE_BRAND_KEY_TRANSLATION,
  PrintCertificatesColumnsEnum.VEHICLE_TYPE,
  PrintCertificatesColumnsEnum.VEHICLE_VIN,
  PrintCertificatesColumnsEnum.COMPATIBILITY_CERTIFICATE_NUMBER,
  PrintCertificatesColumnsEnum.NEXT_INSPECTION_DATE,
  PrintCertificatesColumnsEnum.PRINT_DATE,
  PrintCertificatesColumnsEnum.VALID_FROM
];

const wzoApplicationTypePattern = /ibtm-domain.application\.type\.spo-wzo/;
const spoApplicationTypePattern = /ibtm-domain.application\.type\.spo/;

function DetailsTablesSection() {
  const { printDetailsData } = usePrintsDetailsDataContext();
  const { getWidthOfActionColumnWidth } = useAmountOfIcons();
  const {
    depotMPD: { id: depotMPDId },
    depotMP: { id: depotMPId },
    notDepotMPDTooltip,
    notDepotMPTooltip,
    moreThanOneDepotMPDTooltip,
    moreThanOneDepotMPTooltip,
    applicationSnapshot: applicationData
  } = printDetailsData;
  const { getButtonTitle, getDialogTitle } = useGetGenerateButtonTitle();
  const applicationId = applicationData?.id;
  const folderType = applicationData?.folder.typeKey;
  const applicationIdIn = applicationId ? { applicationIdIn: [applicationId] } : {};
  const { checkIsElementVisible } = useElementVisibility();
  const { reportTypes } = useAppConfig();
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();

  const { mutateAsync: generateEkmtCarnet, isLoading: isLoadingGenerateEkmtCarnet } = useGenerateEkmtCarnetMutation();

  const [generateListOfPartnersReport, isReportTypesLoading] = useGenerateReport(
    reportTypes?.listOfPartners?.reportTypeName
  );

  const { openGenerateDocumentDialog } = useGenerateApplicationDocumentDialog({
    generateDocumentMutation: useGenerateApplicationDocumentMutation,
    listQueryKey: DocumentsQueryKeysEnum.DOCUMENTS_LIST
  });
  const appConfig = useAppConfig();
  const vehicleListTemplateName = appConfig?.templateNames?.vehicleList;
  const transitSchedulesDatesTemplateName = appConfig?.templateNames?.transitSchedulesDates;
  const transitSchedulesTemplateName = appConfig?.templateNames?.transitSchedules;

  const isGenerateListOfVehiclesButtonVisible = checkIsElementVisible(DomainUIElementEnum.GENERATE_LIST_OF_VEHICLES);
  const isGenerateListOfTravelTimesButtonVisible = checkIsElementVisible(
    DomainUIElementEnum.GENERATE_TRANSIT_SCHEDULES_DATES
  );

  const handleGenerateDocument = ({
    documentTemplateName,
    groupKey,
    label
  }: {
    documentTemplateName: string;
    groupKey: string;
    label: Parameters<typeof getDialogTitle>[0];
  }) => {
    openGenerateDocumentDialog({
      params: { applicationId },
      isDocumentDownloadingAfterGenerate: true,
      groupKey: DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP[groupKey],
      isFieldsViewOnly: true,
      title: getDialogTitle(label),
      documentTemplateName
    });
  };

  const config = usePrintTablesConfiguration();

  const { applicationData: applicationPrintingItem } = useFetchPrintsApplicationDetails();
  const isWzoApplication = applicationPrintingItem?.applicationTypeKey?.match(wzoApplicationTypePattern);
  const isSpoApplication = applicationPrintingItem?.applicationTypeKey?.match(spoApplicationTypePattern);

  const isForeignPermissionsTableVisible = isWzoApplication
    ? applicationPrintingItem?.hasSpoForeignPermissions
    : config?.FOREIGN_PERMISSION.isVisible;

  const { isPassedToForeignPermissionDepartment } = useSPOApplicationForeignPermissionsFlags();

  const isTableVisible = useMemo(() => {
    return {
      LICENSE: checkIsElementVisible(DomainUIElementEnum.LICENCE_TABLE_VIEW),
      LICENSE_COPY: checkIsElementVisible(DomainUIElementEnum.LICENCE_COPY_TABLE_VIEW),
      PERMIT: checkIsElementVisible(DomainUIElementEnum.PERMIT_TABLE_VIEW),
      PERMIT_COPY: checkIsElementVisible(DomainUIElementEnum.PERMIT_COPY_TABLE_VIEW),
      DRIVER_CERTIFICATE: checkIsElementVisible(DomainUIElementEnum.DRIVER_CERTIFICATE_TABLE_VIEW),
      REGULAR_UE_TRANSPORT_PERMISSIONS: checkIsElementVisible(
        DomainUIElementEnum.REGULAR_UE_TRANSPORT_PERMISSIONS_TABLE_VIEW
      ),
      REGULAR_UE_TRANSPORT_PERMISSIONS_COPY: checkIsElementVisible(
        DomainUIElementEnum.REGULAR_UE_TRANSPORT_PERMISSIONS_COPY_TABLE_VIEW
      ),
      REGULAR_TRANSIT_THROUGH_RP_PERMISSIONS: checkIsElementVisible(
        DomainUIElementEnum.REGULAR_TRANSIT_THROUGH_RP_PERMISSIONS_TABLE_VIEW
      ),
      REGULAR_BEYOND_UE_TRANSPORT_PERMISSIONS_EXTRACT: checkIsElementVisible(
        DomainUIElementEnum.REGULAR_BEYOND_UE_TRANSPORT_PERMISSIONS_EXTRACT_TABLE_VIEW
      ),
      REGULAR_TRANSIT_OUTSIDE_EU_PERMISSIONS: checkIsElementVisible(
        DomainUIElementEnum.REGULAR_TRANSIT_OUTSIDE_EU_PERMISSIONS_TABLE_VIEW
      ),
      REGULAR_TRANSIT_THROUGH_RP_PERMISSIONS_EXTRACT: checkIsElementVisible(
        DomainUIElementEnum.REGULAR_TRANSIT_THROUGH_RP_PERMISSIONS_EXTRACT_TABLE_VIEW
      ),
      SHUTTLE_SERVICES_PERMISISONS: checkIsElementVisible(DomainUIElementEnum.SHUTTLE_SERVICES_PERMISISONS_TABLE_VIEW),
      SHUTTLE_SERVICES_PERMISISONS_EXTRACT: checkIsElementVisible(
        DomainUIElementEnum.SHUTTLE_SERVICES_PERMISISONS_EXTRACT_TABLE_VIEW
      ),
      OCCASIONAL_TRANSPORT_PERMISSIONS: checkIsElementVisible(
        DomainUIElementEnum.OCCASIONAL_TRANSPORT_PERMISSIONS_TABLE_VIEW
      ),
      OCCASIONAL_TRANSPORT_PERMISSIONS_EXTRACT: checkIsElementVisible(
        DomainUIElementEnum.OCCASIONAL_TRANSPORT_PERMISSIONS_EXTRACT_TABLE_VIEW
      ),
      OWN_TRANSPORT_CERTIFICATES: checkIsElementVisible(DomainUIElementEnum.OWN_TRANSPORT_CERTIFICATES_TABLE_VIEW),
      OWN_TRANSPORT_CERTIFICATES_EXTRACT: checkIsElementVisible(
        DomainUIElementEnum.OWN_TRANSPORT_CERTIFICATES_EXTRACT_TABLE_VIEW
      ),
      FOREIGN_PERMISSIONS: checkIsElementVisible(DomainUIElementEnum.FOREIGN_PERMISSIONS_TABLE_VIEW),
      EKMT_CARNETS: checkIsElementVisible(DomainUIElementEnum.EKMT_CARNETS_TABLE_VIEW)
    };
  }, [checkIsElementVisible]);

  const {
    DRIVER_CERTIFICATE,
    LICENSE,
    LICENSE_COPY,
    PERMISSION,
    PERMISSION_COPY,
    S_CERTIFICATE,
    FOREIGN_PERMISSION,
    INTERBUS,
    TRAVEL_FORM,
    OWN_NEEDS_PERMISSION,
    OWN_NEEDS_PERMISSION_COPY
  } = DomainDictionaryEntry.GENERATOR_ASSIGNMENT_TYPE;

  return (
    <div style={{ position: 'relative', minHeight: '300px' }}>
      {config?.LISENSE.isVisible && isTableVisible.LICENSE && (
        <LicensesTableV2
          applicationId={applicationId}
          visibleColumns={[
            LicensesColumnsEnum.LICENSE_NUMBER,
            LicensesColumnsEnum.FORM_NUMBER,
            LicensesColumnsEnum.PRINT_DATE,
            LicensesColumnsEnum.LICENSE_VALIDITY_YEARS_KEY,
            LicensesColumnsEnum.VALID_FROM,
            LicensesColumnsEnum.VALID_TO
          ]}
          headerActions={({ refetch }) => [
            <GeneratePermissionButton
              key={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
              actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
              title={getButtonTitle('license')}
              assignmentTypeKey={LICENSE}
              permissionType="license"
              onSuccess={() => {
                refetch();
                queryCache.invalidateQueries(PrintsQueryKeysEnum.PRINTS_LAST_PRINTED_PERMISSIONS);
              }}
              depotId={depotMPDId}
              depotTooltip={moreThanOneDepotMPDTooltip || notDepotMPDTooltip}
            />
          ]}
          licenseValidityYearsDictionary={
            folderType === DomainDictionaryEntry.FOLDER_TYPE.PPO
              ? DomainDictionaryEnum.LICENSE_VALIDITY_YEARS_PPO
              : DomainDictionaryEnum.LICENSE_VALIDITY_YEARS_TP_OP
          }
          defaultSort={DEFAULT_PRINT_TABLE_SORTING}
          maxRowActionNumber={4}
        />
      )}
      {config?.LICENSE_COPY.isVisible && isTableVisible.LICENSE_COPY && (
        <LicenseCopiesTableV2
          applicationId={applicationId}
          visibleColumns={[
            LicenseCopiesColumnsEnum.FORM_NUMBER,
            ...(folderType === DomainDictionaryEntry.FOLDER_TYPE.TP
              ? [LicenseCopiesColumnsEnum.BELOW_THREE_AND_HALF_TONS]
              : []),
            LicenseCopiesColumnsEnum.PRINT_DATE,
            LicenseCopiesColumnsEnum.VALID_FROM,
            LicenseCopiesColumnsEnum.VALID_TO
          ]}
          headerActions={({ permissionIds, refetch }) => [
            <PrintAllPermissionsButton
              key="PRINT_ALL"
              permissionTypeCode={PermissionTypeCode.LICENSE_COPY}
              isDisabled={_.isEmpty(permissionIds)}
            />,
            <GeneratePermissionButton
              key="GENERATE_PERMISSION"
              actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
              title={getButtonTitle('licenseCopy')}
              assignmentTypeKey={LICENSE_COPY}
              permissionType="licenseCopy"
              depotId={depotMPDId}
              onSuccess={() => {
                refetch();
                queryCache.invalidateQueries(PrintsQueryKeysEnum.PRINTS_LAST_PRINTED_PERMISSIONS);
              }}
              depotTooltip={moreThanOneDepotMPDTooltip || notDepotMPDTooltip}
            />
          ]}
          defaultSort={DEFAULT_PRINT_TABLE_SORTING}
          maxRowActionNumber={3}
        />
      )}
      {config?.GITD.isVisible && isTableVisible.PERMIT && (
        <PermissionsTableV2
          applicationId={applicationId}
          titleKey="permissionGitd"
          visibleColumns={[
            PermissionsColumnsEnum.PERMISSION_NUMBER,
            PermissionsColumnsEnum.FORM_NUMBER,
            PermissionsColumnsEnum.VALID_FROM
          ]}
          validFromColumnHeader={t('permission:field.dateOfIssueGitd')}
          dateFromAndToWithoutChips
          initialParams={{
            ...applicationIdIn,
            isIssuedByExternalAuthority: false
          }}
          headerActions={({ refetch }) => [
            <GeneratePermissionButton
              key={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
              actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
              title={getButtonTitle('permission')}
              assignmentTypeKey={PERMISSION}
              permissionType="permission"
              onSuccess={() => {
                refetch();
                queryCache.invalidateQueries(PrintsQueryKeysEnum.PRINTS_LAST_PRINTED_PERMISSIONS);
              }}
              depotId={depotMPDId}
              depotTooltip={moreThanOneDepotMPDTooltip || notDepotMPDTooltip}
            />
          ]}
          isLocksSummaryVisible
          permissionTypeCode={PermissionTypeCode.PERMISSION}
          defaultSort={DEFAULT_PRINT_TABLE_SORTING}
          maxRowActionNumber={4}
        />
      )}
      {config?.PERMISSION_COPY.isVisible && isTableVisible.PERMIT_COPY && (
        <PermissionCopiesTableV2
          applicationId={applicationId}
          titleKey="permissionGitdCopy"
          visibleColumns={[
            PermissionCopiesColumnsEnum.NUMBER,
            PermissionCopiesColumnsEnum.FORM_NUMBER,
            PermissionCopiesColumnsEnum.VALID_FROM
          ]}
          validFromColumnHeader={t('permission:field.dateOfIssueGitd')}
          headerActions={({ refetch }) => [
            <GeneratePermissionButton
              key={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_COPY_BUTTON}
              actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_COPY_BUTTON}
              title={getButtonTitle('permissionCopy')}
              assignmentTypeKey={PERMISSION_COPY}
              permissionType="permissionCopy"
              onSuccess={() => {
                refetch();
                queryCache.invalidateQueries(PrintsQueryKeysEnum.PRINTS_LAST_PRINTED_PERMISSIONS);
              }}
              depotId={depotMPDId}
              depotTooltip={moreThanOneDepotMPDTooltip || notDepotMPDTooltip}
            />
          ]}
          permissionTypeCode={PermissionTypeCode?.PERMISSION_COPY}
          isMultipleFormNumberToRegenerateInRow={false}
          maxRowActionNumber={3}
        />
      )}
      {config?.DRIVER_CERTIFICATE.isVisible && isTableVisible.DRIVER_CERTIFICATE && (
        <DriverCertificatesExtendedTable
          actionsColumnWidth={getWidthOfActionColumnWidth({
            isEditVisible: true
          })}
          applicationId={applicationId}
          headerActions={
            <TableContext.Consumer>
              {({ refetch }) => (
                <GeneratePermissionButton
                  actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
                  title={getButtonTitle('certificates')}
                  assignmentTypeKey={DRIVER_CERTIFICATE}
                  permissionType="driverCertificate"
                  depotId={depotMPDId}
                  onSuccess={() => {
                    refetch();
                    queryCache.invalidateQueries(PrintsQueryKeysEnum.PRINTS_LAST_PRINTED_PERMISSIONS);
                  }}
                  depotTooltip={moreThanOneDepotMPDTooltip || notDepotMPDTooltip}
                />
              )}
            </TableContext.Consumer>
          }
          rowActions={row =>
            rowActionRenderer({
              row,
              isEditVisible: true,
              permissionTypeCode: PermissionTypeCode.DRIVER_CERTIFICATE
            })
          }
          isTableMutable
          editableColumns={[
            DriverCertificatesColumnsEnum.DRIVER_CERTIFICATE_NUMBER,
            DriverCertificatesColumnsEnum.VALID_FROM,
            DriverCertificatesColumnsEnum.VALID_TO
          ]}
          defaultSort={DEFAULT_PRINT_TABLE_SORTING}
        />
      )}
      {config?.SPO_UE.isVisible && isTableVisible.REGULAR_UE_TRANSPORT_PERMISSIONS && (
        <PermissionsTableV2
          applicationId={applicationId}
          titleKey="spo-eu"
          visibleColumns={TRANSPORT_PERMISSIONS_COLUMNS}
          subjectNameColumnHeader={t('permission:field.ownTransportCertificatesPermissionNumber')}
          permissionNumberColumnHeader={t('permission:field.ownTransportCertificatesPermissionNumber')}
          initialParams={{
            ...applicationIdIn,
            typeKeyIn: [PermissionTypeEnum.SPO_UE]
          }}
          headerActions={({ refetch }) => [
            <DropdownButton
              key="dropdown"
              actionButtons={[
                {
                  title: getButtonTitle('listOfPartners'),
                  onClick: () => generateListOfPartnersReport(),
                  isDisabled: isReportTypesLoading
                },
                {
                  title: getButtonTitle('schedule'),
                  onClick: () => {
                    handleGenerateDocument({
                      label: 'schedule',
                      documentTemplateName: transitSchedulesTemplateName,
                      groupKey: 'STATEMENTS'
                    });
                  }
                }
              ]}
            />,
            <GeneratePermissionButton
              key={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
              actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
              title={getButtonTitle('permission')}
              assignmentTypeKey={PERMISSION}
              permissionType="permission"
              onSuccess={() => {
                refetch();
                queryCache.invalidateQueries(PrintsQueryKeysEnum.PRINTS_LAST_PRINTED_PERMISSIONS);
              }}
              depotId={depotMPDId}
              depotTooltip={moreThanOneDepotMPDTooltip || notDepotMPDTooltip}
            />
          ]}
          isLocksSummaryVisible
          isFormsNumbersVisibleInLockSummary
          isMultipleFormNumberToRegenerateInRow
          isPrintDisabled={rowData => !(rowData.validFrom && rowData.validTo)}
          permissionTypeCode={PermissionTypeCode.PERMISSION}
          maxRowActionNumber={4}
          defaultSort={DEFAULT_PRINT_TABLE_SORTING}
        />
      )}
      {config?.SPO_UE_COPY.isVisible && isTableVisible.REGULAR_UE_TRANSPORT_PERMISSIONS_COPY && (
        <PermissionCopiesTableV2
          applicationId={applicationId}
          titleKey="spo-eu"
          typeKeys={[PermissionTypeEnum.SPO_UE]}
          visibleColumns={TRANSPORT_PERMISSIONS_COPIES_COLUMNS}
          headerActions={({ refetch }) => [
            <PrintAllPermissionsButton
              key={DomainUIElementEnum.PERMISSION_ALL_PRINT_BUTTON}
              permissionTypeCode={PermissionTypeCode.PERMISSION_COPY}
            />,
            <GeneratePermissionButton
              key={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_COPY_BUTTON}
              actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_COPY_BUTTON}
              title={getButtonTitle('permissionCopy')}
              assignmentTypeKey={PERMISSION_COPY}
              onSuccess={() => {
                refetch();
                queryCache.invalidateQueries(PrintsQueryKeysEnum.PRINTS_LAST_PRINTED_PERMISSIONS);
              }}
              permissionType="permissionCopy"
              depotId={depotMPDId}
              depotTooltip={moreThanOneDepotMPDTooltip || notDepotMPDTooltip}
            />
          ]}
          isPrintDisabled={rowData => !(rowData.validFrom && rowData.validTo)}
          permissionTypeCode={PermissionTypeCode.PERMISSION_COPY}
          isMultipleFormNumberToRegenerateInRow
          maxRowActionNumber={3}
        />
      )}
      {config?.SPO_OUTSIDE_UE.isVisible && isTableVisible.REGULAR_TRANSIT_OUTSIDE_EU_PERMISSIONS && (
        <PermissionsTableV2
          applicationId={applicationId}
          titleKey="spo-outside-eu"
          visibleColumns={TRANSPORT_PERMISSIONS_COLUMNS}
          initialParams={{
            ...applicationIdIn,
            typeKeyIn: [PermissionTypeEnum.SPO_OUTSIDE_UE]
          }}
          headerActions={({ refetch }) => [
            <GeneratePermissionButton
              key={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
              actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
              title={getButtonTitle('permission')}
              assignmentTypeKey={PERMISSION}
              permissionType="permission"
              onSuccess={() => {
                refetch();
                queryCache.invalidateQueries(PrintsQueryKeysEnum.PRINTS_LAST_PRINTED_PERMISSIONS);
              }}
              depotId={depotMPDId}
              depotTooltip={moreThanOneDepotMPDTooltip || notDepotMPDTooltip}
            />
          ]}
          isLocksSummaryVisible
          isFormsNumbersVisibleInLockSummary
          isMultipleFormNumberToRegenerateInRow
          permissionTypeCode={PermissionTypeCode.PERMISSION}
          isPrintDisabled={rowData => !(rowData.validFrom && rowData.validTo)}
          defaultSort={DEFAULT_PRINT_TABLE_SORTING}
          maxRowActionNumber={4}
        />
      )}
      {config?.SPO_OUTSIDE_UE_COPY.isVisible && isTableVisible.REGULAR_BEYOND_UE_TRANSPORT_PERMISSIONS_EXTRACT && (
        <PermissionCopiesTableV2
          applicationId={applicationId}
          titleKey="spo-outside-eu"
          typeKeys={[PermissionTypeEnum.SPO_OUTSIDE_UE]}
          visibleColumns={TRANSPORT_PERMISSIONS_COPIES_COLUMNS}
          headerActions={({ refetch }) => [
            <PrintAllPermissionsButton
              key={DomainUIElementEnum.PERMISSION_ALL_PRINT_BUTTON}
              permissionTypeCode={PermissionTypeCode.PERMISSION_COPY}
            />,
            <GeneratePermissionButton
              key={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_COPY_BUTTON}
              actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_COPY_BUTTON}
              title={getButtonTitle('permissionCopy')}
              assignmentTypeKey={PERMISSION_COPY}
              onSuccess={() => {
                refetch();
                queryCache.invalidateQueries(PrintsQueryKeysEnum.PRINTS_LAST_PRINTED_PERMISSIONS);
              }}
              permissionType="permissionCopy"
              depotId={depotMPDId}
              depotTooltip={moreThanOneDepotMPDTooltip || notDepotMPDTooltip}
            />
          ]}
          isPrintDisabled={rowData => !(rowData.validFrom && rowData.validTo)}
          permissionTypeCode={PermissionTypeCode.PERMISSION_COPY}
          isMultipleFormNumberToRegenerateInRow
          maxRowActionNumber={3}
        />
      )}
      {config?.SPO_TRANSIT.isVisible && isTableVisible.REGULAR_TRANSIT_THROUGH_RP_PERMISSIONS && (
        <PermissionsTableV2
          applicationId={applicationId}
          titleKey="spo-transit"
          visibleColumns={TRANSPORT_PERMISSIONS_COLUMNS}
          initialParams={{
            ...applicationIdIn,
            typeKeyIn: [PermissionTypeEnum.SPO_TRANSIT]
          }}
          headerActions={({ refetch }) => [
            <GeneratePermissionButton
              key={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
              actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
              title={getButtonTitle('permission')}
              assignmentTypeKey={PERMISSION}
              permissionType="permission"
              onSuccess={() => {
                refetch();
                queryCache.invalidateQueries(PrintsQueryKeysEnum.PRINTS_LAST_PRINTED_PERMISSIONS);
              }}
              depotId={depotMPDId}
              depotTooltip={moreThanOneDepotMPDTooltip || notDepotMPDTooltip}
            />
          ]}
          isLocksSummaryVisible
          isFormsNumbersVisibleInLockSummary
          isMultipleFormNumberToRegenerateInRow
          isPrintDisabled={rowData => !(rowData.validFrom && rowData.validTo)}
          permissionTypeCode={PermissionTypeCode.PERMISSION}
          defaultSort={DEFAULT_PRINT_TABLE_SORTING}
          maxRowActionNumber={4}
        />
      )}
      {config?.SPO_TRANSIT_COPY.isVisible && isTableVisible.REGULAR_TRANSIT_THROUGH_RP_PERMISSIONS_EXTRACT && (
        <PermissionCopiesTableV2
          applicationId={applicationId}
          titleKey="spo-transit"
          typeKeys={[PermissionTypeEnum.SPO_TRANSIT]}
          visibleColumns={TRANSPORT_PERMISSIONS_COPIES_COLUMNS}
          headerActions={({ refetch }) => [
            <PrintAllPermissionsButton
              key={DomainUIElementEnum.PERMISSION_ALL_PRINT_BUTTON}
              permissionTypeCode={PermissionTypeCode.PERMISSION_COPY}
            />,
            <GeneratePermissionButton
              key={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_COPY_BUTTON}
              actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_COPY_BUTTON}
              title={getButtonTitle('permissionCopy')}
              assignmentTypeKey={PERMISSION_COPY}
              permissionType="permissionCopy"
              onSuccess={() => {
                refetch();
                queryCache.invalidateQueries(PrintsQueryKeysEnum.PRINTS_LAST_PRINTED_PERMISSIONS);
              }}
              depotId={depotMPDId}
              depotTooltip={moreThanOneDepotMPDTooltip || notDepotMPDTooltip}
            />
          ]}
          isPrintDisabled={rowData => !(rowData.validFrom && rowData.validTo)}
          permissionTypeCode={PermissionTypeCode.PERMISSION_COPY}
          isMultipleFormNumberToRegenerateInRow
          maxRowActionNumber={3}
        />
      )}
      {config?.SPO_SHUTTLE.isVisible && isTableVisible.SHUTTLE_SERVICES_PERMISISONS && (
        <PermissionsTableV2
          applicationId={applicationId}
          titleKey="spo-shuttle"
          visibleColumns={TRANSPORT_PERMISSIONS_COLUMNS}
          initialParams={{
            ...applicationIdIn,
            typeKeyIn: [PermissionTypeEnum.SPO_SHUTTLE]
          }}
          headerActions={({ refetch }) => [
            (isGenerateListOfVehiclesButtonVisible || isGenerateListOfTravelTimesButtonVisible) && (
              <DropdownButton
                key="dropdown"
                actionButtons={[
                  {
                    title: getButtonTitle('listOfVehicles'),
                    isVisible: isGenerateListOfVehiclesButtonVisible,
                    onClick: () => {
                      handleGenerateDocument({
                        label: 'listOfVehicles',
                        documentTemplateName: vehicleListTemplateName,
                        groupKey: 'STATEMENTS'
                      });
                    }
                  },
                  {
                    title: getButtonTitle('listOfTravelTimes'),
                    isVisible: isGenerateListOfTravelTimesButtonVisible,
                    onClick: () => {
                      handleGenerateDocument({
                        label: 'listOfTravelTimes',
                        documentTemplateName: transitSchedulesDatesTemplateName,
                        groupKey: 'STATEMENTS'
                      });
                    }
                  }
                ]}
              />
            ),
            <GeneratePermissionButton
              key={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
              actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
              title={getButtonTitle('permission')}
              assignmentTypeKey={PERMISSION}
              permissionType="permission"
              onSuccess={() => {
                refetch();
                queryCache.invalidateQueries(PrintsQueryKeysEnum.PRINTS_LAST_PRINTED_PERMISSIONS);
              }}
              depotId={depotMPDId}
              depotTooltip={moreThanOneDepotMPDTooltip || notDepotMPDTooltip}
            />
          ]}
          isLocksSummaryVisible
          isFormsNumbersVisibleInLockSummary
          isMultipleFormNumberToRegenerateInRow
          isPrintDisabled={rowData => !(rowData.validFrom && rowData.validTo)}
          permissionTypeCode={PermissionTypeCode.PERMISSION}
          defaultSort={DEFAULT_PRINT_TABLE_SORTING}
          maxRowActionNumber={4}
        />
      )}
      {config?.SPO_SHUTTLE_COPY.isVisible && isTableVisible.SHUTTLE_SERVICES_PERMISISONS_EXTRACT && (
        <PermissionCopiesTableV2
          applicationId={applicationId}
          titleKey="spo-shuttle"
          typeKeys={[PermissionTypeEnum.SPO_SHUTTLE]}
          visibleColumns={TRANSPORT_PERMISSIONS_COPIES_COLUMNS}
          headerActions={({ refetch }) => [
            (isGenerateListOfVehiclesButtonVisible || isGenerateListOfTravelTimesButtonVisible) && (
              <DropdownButton
                key="dropdown"
                actionButtons={[
                  {
                    title: getButtonTitle('listOfVehicles'),
                    isVisible: isGenerateListOfVehiclesButtonVisible,
                    onClick: () => {
                      handleGenerateDocument({
                        label: 'listOfVehicles',
                        documentTemplateName: vehicleListTemplateName,
                        groupKey: 'STATEMENTS'
                      });
                    }
                  },
                  {
                    title: getButtonTitle('listOfTravelTimes'),
                    isVisible: isGenerateListOfTravelTimesButtonVisible,
                    onClick: () => {
                      handleGenerateDocument({
                        label: 'listOfTravelTimes',
                        documentTemplateName: transitSchedulesDatesTemplateName,
                        groupKey: 'STATEMENTS'
                      });
                    }
                  }
                ]}
              />
            ),
            <PrintAllPermissionsButton
              key={DomainUIElementEnum.PERMISSION_ALL_PRINT_BUTTON}
              permissionTypeCode={PermissionTypeCode.PERMISSION_COPY}
            />,
            <GeneratePermissionButton
              key={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_COPY_BUTTON}
              title={getButtonTitle('permissionCopy')}
              actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_COPY_BUTTON}
              assignmentTypeKey={PERMISSION_COPY}
              onSuccess={() => {
                refetch();
                queryCache.invalidateQueries(PrintsQueryKeysEnum.PRINTS_LAST_PRINTED_PERMISSIONS);
              }}
              permissionType="permissionCopy"
              depotId={depotMPDId}
              depotTooltip={moreThanOneDepotMPDTooltip || notDepotMPDTooltip}
            />
          ]}
          isPrintDisabled={rowData => !(rowData.validFrom && rowData.validTo)}
          permissionTypeCode={PermissionTypeCode.PERMISSION_COPY}
          isMultipleFormNumberToRegenerateInRow
          maxRowActionNumber={3}
        />
      )}
      {config?.SPO_OCCASIONAL.isVisible && isTableVisible.OCCASIONAL_TRANSPORT_PERMISSIONS && (
        <PermissionsTableV2
          applicationId={applicationId}
          titleKey="spo-occasional"
          visibleColumns={TRANSPORT_PERMISSIONS_COLUMNS}
          initialParams={{
            ...applicationIdIn,
            typeKeyIn: [PermissionTypeEnum.SPO_OCCASIONAL]
          }}
          headerActions={({ refetch }) => [
            (isGenerateListOfVehiclesButtonVisible || isGenerateListOfTravelTimesButtonVisible) && (
              <DropdownButton
                key="dropdown"
                actionButtons={[
                  {
                    title: getButtonTitle('listOfVehicles'),
                    isVisible: isGenerateListOfVehiclesButtonVisible,
                    onClick: () => {
                      handleGenerateDocument({
                        label: 'listOfVehicles',
                        documentTemplateName: vehicleListTemplateName,
                        groupKey: 'STATEMENTS'
                      });
                    }
                  },
                  {
                    title: getButtonTitle('listOfTravelTimes'),
                    isVisible: isGenerateListOfTravelTimesButtonVisible,
                    onClick: () => {
                      handleGenerateDocument({
                        label: 'listOfTravelTimes',
                        documentTemplateName: transitSchedulesDatesTemplateName,
                        groupKey: 'STATEMENTS'
                      });
                    }
                  }
                ]}
              />
            ),
            <GeneratePermissionButton
              key={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
              actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
              title={getButtonTitle('permission')}
              assignmentTypeKey={PERMISSION}
              permissionType="permission"
              onSuccess={() => {
                refetch();
                queryCache.invalidateQueries(PrintsQueryKeysEnum.PRINTS_LAST_PRINTED_PERMISSIONS);
              }}
              depotId={depotMPDId}
              depotTooltip={moreThanOneDepotMPDTooltip || notDepotMPDTooltip}
            />
          ]}
          isLocksSummaryVisible
          isFormsNumbersVisibleInLockSummary
          isMultipleFormNumberToRegenerateInRow
          isPrintDisabled={rowData => !(rowData.validFrom && rowData.validTo)}
          permissionTypeCode={PermissionTypeCode.PERMISSION}
          defaultSort={DEFAULT_PRINT_TABLE_SORTING}
          maxRowActionNumber={4}
        />
      )}
      {config?.SPO_OCCASIONAL_COPY.isVisible && isTableVisible.OCCASIONAL_TRANSPORT_PERMISSIONS_EXTRACT && (
        <PermissionCopiesTableV2
          applicationId={applicationId}
          titleKey="spo-occasional"
          typeKeys={[PermissionTypeEnum.SPO_OCCASIONAL]}
          visibleColumns={TRANSPORT_PERMISSIONS_COPIES_COLUMNS}
          headerActions={({ refetch }) => [
            (isGenerateListOfVehiclesButtonVisible || isGenerateListOfTravelTimesButtonVisible) && (
              <DropdownButton
                key="dropdown"
                actionButtons={[
                  {
                    title: getButtonTitle('listOfVehicles'),
                    isVisible: isGenerateListOfVehiclesButtonVisible,
                    onClick: () => {
                      handleGenerateDocument({
                        label: 'listOfVehicles',
                        documentTemplateName: vehicleListTemplateName,
                        groupKey: 'STATEMENTS'
                      });
                    }
                  },
                  {
                    title: getButtonTitle('listOfTravelTimes'),
                    isVisible: isGenerateListOfTravelTimesButtonVisible,
                    onClick: () => {
                      handleGenerateDocument({
                        label: 'listOfTravelTimes',
                        documentTemplateName: transitSchedulesDatesTemplateName,
                        groupKey: 'STATEMENTS'
                      });
                    }
                  }
                ]}
              />
            ),
            <PrintAllPermissionsButton
              key={DomainUIElementEnum.PERMISSION_ALL_PRINT_BUTTON}
              permissionTypeCode={PermissionTypeCode.PERMISSION_COPY}
            />,
            <GeneratePermissionButton
              key={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_COPY_BUTTON}
              title={getButtonTitle('permissionCopy')}
              actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_COPY_BUTTON}
              assignmentTypeKey={PERMISSION_COPY}
              onSuccess={() => {
                refetch();
                queryCache.invalidateQueries(PrintsQueryKeysEnum.PRINTS_LAST_PRINTED_PERMISSIONS);
              }}
              permissionType="permissionCopy"
              depotId={depotMPDId}
              depotTooltip={moreThanOneDepotMPDTooltip || notDepotMPDTooltip}
            />
          ]}
          isPrintDisabled={rowData => !(rowData.validFrom && rowData.validTo)}
          permissionTypeCode={PermissionTypeCode.PERMISSION_COPY}
          isMultipleFormNumberToRegenerateInRow
          maxRowActionNumber={3}
        />
      )}
      {config?.OWN_NEEDS.isVisible && isTableVisible.OWN_TRANSPORT_CERTIFICATES && (
        <PermissionsTableV2
          applicationId={applicationId}
          titleKey="own-needs"
          visibleColumns={[
            PermissionsColumnsEnum.PERMISSION_NUMBER,
            PermissionsColumnsEnum.SUBJECT_NAME,
            PermissionsColumnsEnum.PRINT_DATE
          ]}
          subjectNameColumnHeader={t('permission:field.ownTransportCertificatesSubjectName')}
          permissionNumberColumnHeader={t('permission:field.ownTransportCertificatesPermissionNumber')}
          initialParams={{
            ...applicationIdIn,
            typeKeyIn: [PermissionTypeEnum.OWN_NEEDS_GOODS_CARRIAGE, PermissionTypeEnum.OWN_NEEDS_PASSENGER_CARRIAGE]
          }}
          headerActions={({ refetch }) => [
            <GeneratePermissionButton
              key={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
              actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
              title={getButtonTitle('permission')}
              assignmentTypeKey={OWN_NEEDS_PERMISSION}
              permissionType="permission"
              onSuccess={() => {
                refetch();
                queryCache.invalidateQueries(PrintsQueryKeysEnum.PRINTS_LAST_PRINTED_PERMISSIONS);
              }}
              depotId={depotMPDId}
              depotTooltip={moreThanOneDepotMPDTooltip || notDepotMPDTooltip}
            />
          ]}
          isLocksSummaryVisible
          permissionTypeCode={PermissionTypeCode.PERMISSION}
          defaultSort={DEFAULT_PRINT_TABLE_SORTING}
          maxRowActionNumber={4}
          isEditEnabled={false}
        />
      )}
      {config?.OWN_NEEDS_COPY.isVisible && isTableVisible.OWN_TRANSPORT_CERTIFICATES_EXTRACT && (
        <PermissionCopiesTableV2
          applicationId={applicationId}
          titleKey="own-needs"
          typeKeys={[PermissionTypeEnum.OWN_NEEDS_GOODS_CARRIAGE, PermissionTypeEnum.OWN_NEEDS_PASSENGER_CARRIAGE]}
          visibleColumns={[PermissionCopiesColumnsEnum.PERMISSION_COPY_NUMBER, PermissionCopiesColumnsEnum.PRINT_DATE]}
          headerActions={({ refetch }) => [
            <PrintAllPermissionsButton
              key={DomainUIElementEnum.PERMISSION_ALL_PRINT_BUTTON}
              permissionTypeCode={PermissionTypeCode.PERMISSION_COPY}
            />,
            <GeneratePermissionButton
              key={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_COPY_BUTTON}
              title={getButtonTitle('permissionCopy')}
              actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_COPY_BUTTON}
              assignmentTypeKey={OWN_NEEDS_PERMISSION_COPY}
              permissionType="permissionCopy"
              onSuccess={() => {
                refetch();
                queryCache.invalidateQueries(PrintsQueryKeysEnum.PRINTS_LAST_PRINTED_PERMISSIONS);
              }}
              depotId={depotMPDId}
              depotTooltip={moreThanOneDepotMPDTooltip || notDepotMPDTooltip}
            />
          ]}
          permissionTypeCode={PermissionTypeCode.PERMISSION_COPY}
          isMultipleFormNumberToRegenerateInRow
          maxRowActionNumber={3}
        />
      )}
      {isForeignPermissionsTableVisible && isTableVisible.FOREIGN_PERMISSIONS && (
        <PrintForeignPermissionTable
          applicationId={applicationId}
          headerActions={({ refetch }) => [
            (isSpoApplication && applicationPrintingItem?.hasSpoForeignPermissions
              ? isPassedToForeignPermissionDepartment
              : true) && (
              <GeneratePermissionButton
                actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
                title={
                  config?.EKMT_CARNETS.isVisible
                    ? t('prints:actions.assignBlanket')
                    : getButtonTitle('foreignPermission')
                }
                assignmentTypeKey={FOREIGN_PERMISSION}
                permissionType="foreignPermission"
                depotId={depotMPId}
                onSuccess={() => {
                  refetch();
                  queryCache.invalidateQueries(PrintsQueryKeysEnum.PRINTS_LAST_PRINTED_PERMISSIONS);
                }}
                depotTooltip={moreThanOneDepotMPTooltip || notDepotMPTooltip}
                {...(config?.EKMT_CARNETS.isVisible && {
                  permissionGeneratedTooltip: t('prints:messages.formsAlreadyAssigned')
                })}
              />
            )
          ]}
        />
      )}
      {isForeignPermissionsTableVisible && config?.EKMT_CARNETS.isVisible && (
        <PrintForeignPermissionEkmtTable
          applicationId={applicationId}
          visibleColumns={[
            PrintForeignPermissionColumnsEnum.FORM_NAME_KEY,
            PrintForeignPermissionColumnsEnum.FIRST_NUMBER,
            PrintForeignPermissionColumnsEnum.LAST_NUMBER,
            PrintForeignPermissionColumnsEnum.FORM_AMOUNT
          ]}
          headerActions={({ refetch }) => [
            (isSpoApplication && applicationPrintingItem?.hasSpoForeignPermissions
              ? isPassedToForeignPermissionDepartment
              : true) && (
              <GeneratePermissionButton
                actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
                title={getButtonTitle('ekmtCarnets')}
                customGenerateAction={() => {
                  generateEkmtCarnet(
                    {
                      generatePermissionRequest: {
                        applicationId: applicationData.id,
                        version: applicationData.version
                      }
                    },
                    {
                      onSuccess: () => {
                        showSuccessSnackbar(t('prints:messages.ekmtCarnetsGenerated'));
                        refetch();
                        queryCache.invalidateQueries(PrintsQueryKeysEnum.PRINTS_EKMT_CARNETS_LIST);
                      }
                    }
                  );
                }}
                isCustomActionLoading={isLoadingGenerateEkmtCarnet}
                depotId={depotMPId}
                depotTooltip={moreThanOneDepotMPTooltip || notDepotMPTooltip}
              />
            )
          ]}
        />
      )}
      {config?.S_CERTIFICATE.isVisible && (
        <SCertificatesTableV2
          permissionTypeCode={PermissionTypeCode.S_CERTIFICATE}
          headerActions={({ refetch }) => [
            <PrintAllPermissionsButton
              key={DomainUIElementEnum.PERMISSION_ALL_PRINT_BUTTON}
              permissionTypeCode={PermissionTypeCode.S_CERTIFICATE}
            />,
            <GeneratePermissionButton
              key={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
              title={getButtonTitle('SCertificate')}
              assignmentTypeKey={S_CERTIFICATE}
              permissionType="sCertificate"
              depotId={depotMPDId}
              onSuccess={() => {
                refetch();
              }}
              depotTooltip={moreThanOneDepotMPDTooltip || notDepotMPDTooltip}
              actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
            />
          ]}
          visibleColumns={CERTIFICATES_S_COLUMNS}
          initialParams={{
            ...applicationIdIn
          }}
        />
      )}
      {config?.INTERBUS.isVisible && (
        <TravelFormTable
          headerActions={
            <GeneratePermissionButton
              title={t('prints:travelFormsSections.table.action.assignForms')}
              assignmentTypeKey={INTERBUS}
              permissionType="interbus"
              onSuccess={() =>
                queryCache.invalidateQueries([PrintsQueryKeysEnum.PRINTS_TRAVEL_FORM_LIST, applicationId])
              }
              depotId={depotMPId}
              depotTooltip={moreThanOneDepotMPTooltip || notDepotMPTooltip}
              actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
            />
          }
          applicationId={applicationId}
          isInterbus
        />
      )}
      {config?.TRAVEL_FORM.isVisible && (
        <TravelFormTable
          headerActions={
            <GeneratePermissionButton
              title={t('prints:travelFormsSections.table.action.assignForms')}
              assignmentTypeKey={TRAVEL_FORM}
              permissionType="travelForm"
              onSuccess={() =>
                queryCache.invalidateQueries([PrintsQueryKeysEnum.PRINTS_TRAVEL_FORM_LIST, applicationId])
              }
              depotId={depotMPId}
              depotTooltip={moreThanOneDepotMPTooltip || notDepotMPTooltip}
              actionKey={DomainUIElementEnum.PRINT_GENERATE_PERMISSION_BUTTON}
            />
          }
          applicationId={applicationId}
          isInterbus={false}
        />
      )}
    </div>
  );
}

export default React.memo(DetailsTablesSection);
