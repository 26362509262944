import { DictionaryEntry, DictionaryEntryValue } from '@libs/dictionary';

export function getDictionaryValues(entry?: DictionaryEntry): DictionaryEntryValue[] {
  return entry ? entry.entries : [];
}

export function findDictionaryEntryName(
  entry: DictionaryEntry | undefined,
  value: string,
  undefinedIfNoExists?: boolean
): string | undefined {
  const entryValue = getDictionaryValues(entry)?.find(entryValue => entryValue.value === value);
  if (entryValue) {
    return entryValue.name;
  }
  if (!entryValue && undefinedIfNoExists) {
    return undefined;
  }
  return value;
}
