import { useTranslation } from 'react-i18next';
import { TransportManagersSearchFilter } from '@ibtm/domain';

import { CamelCasePath, SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { convertDateToDateFormat, DateRangeISO, getCalendarDate, getFormattedDateValue } from '@libs/common/v2/utils';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { PeriodOfValidityField } from '../components';
import { TransportManagerListItemClient } from '../model';

const useTransportManagersTableColumns = (visibleColumns: Array<CamelCasePath<TransportManagerListItemClient>>) => {
  const [t] = useTranslation();

  const { createColumns } = useCreateColumns<TransportManagerListItemClient>({
    pathToTranslate: 'transportManager:field'
  });

  const mappedSortFields: TableSortMapper<TransportManagerListItemClient> = {
    name: 'personalData.name',
    surname: 'personalData.surname',
    periodOfValidity: ['certificate.validFrom', 'certificate.validTo'],
    managementPeriodFrom: ['managementPeriodFrom', 'managementPeriodTo']
  };

  const mappedFilterFields: TableFilterMapper<TransportManagerListItemClient, TransportManagersSearchFilter> = {
    name: (name: string) => ({ nameContains: name }),
    surname: (surname: string) => ({ surnameContains: surname }),
    certificateNumber: (certificateNumber: string) => ({ certificateNumberContains: certificateNumber }),
    periodOfValidity: ({ dateFrom, dateTo }: DateRangeISO) => ({
      certificateValidFromGreaterThanOrEqual: getCalendarDate(dateFrom),
      certificateValidToLessThanOrEqual: getCalendarDate(dateTo)
    }),
    reputationKey: (reputations: SelectOption<string>[]) => ({
      reputationKeyIn: reputations.map(reputation => reputation.value)
    }),
    managementPeriodFrom: ({ dateFrom, dateTo }: DateRangeISO) => ({
      managementPeriodFromGreaterThanOrEqual: getCalendarDate(dateFrom),
      managementPeriodToLessThanOrEqual: getCalendarDate(dateTo)
    }),
    typeKey: (types: SelectOption<string>[]) => ({ typeKeyIn: types.map(type => type.value) }),
    statusKey: (statusKey: SelectOption<string>) => ({
      statusKeyIn: [statusKey.value]
    }),
    modifierName: (modifierName: SelectOption<string>[]) => ({ modifierIdIn: modifierName.map(({ value }) => value) })
  };

  const columns = createColumns(
    [
      {
        accessor: 'name',
        type: 'TEXT'
      },
      {
        accessor: 'surname',
        type: 'TEXT'
      },
      {
        accessor: 'certificate.number',
        type: 'TEXT',
        width: 250
      },
      {
        accessor: 'periodOfValidity',
        type: 'DATE',
        customAccessor: ({ certificate }) => <PeriodOfValidityField certificate={certificate} />,
        customXlsxAccessor: ({ certificate }) =>
          `${t('from').toLowerCase()} ${convertDateToDateFormat(certificate.validFrom)} ${t(
            'to'
          ).toLowerCase()} ${convertDateToDateFormat(certificate.validTo)}`,
        width: 300
      },
      {
        accessor: 'reputationKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.REPUTATION
      },
      {
        id: 'managementPeriodFrom',
        header: 'managementPeriod',
        type: 'DATE',
        customAccessor: ({ managementPeriodFrom, managementPeriodTo }) =>
          `${t('from').toLowerCase()} ${getFormattedDateValue(managementPeriodFrom)} ${t(
            'to'
          ).toLowerCase()} ${getFormattedDateValue(managementPeriodTo)}`,
        width: 300
      },
      {
        accessor: 'typeKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.TRANSPORT_MANAGER_TYPE
      },
      {
        accessor: 'statusKey',
        type: 'DICTIONARY_SINGLE_SELECT',
        dictionaryName: DomainDictionaryEnum.TRANSPORT_MANAGER_STATUS,
        isSortable: false
      },
      {
        accessor: 'modified',
        type: 'DATE',
        isSortable: false,
        isFilterable: false
      },
      {
        header: 'modifier',
        type: 'TEXT',
        accessor: 'modifier.name',
        isSortable: false,
        isFilterable: false
      }
    ],
    visibleColumns
  );

  return { columns, mappedFilterFields, mappedSortFields };
};

export default useTransportManagersTableColumns;
