import { useMutation } from 'react-query';
import { TransportManagerCreateRequest as TransportManagerCreateRequestClient } from '@ibtm/client-domain';
import { TransportManagerCreateRequest } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

type TransportManagerCreateData = TransportManagerCreateRequest &
  Partial<
    Pick<
      TransportManagerCreateRequestClient,
      | 'numberOtherSubjectVehiclesRegisteredAsManager'
      | 'numberOtherSubjectVehiclesAppointedAsManager'
      | 'numberOtherSubjectsRegisteredAsManager'
      | 'numberOtherSubjectsAppointedAsManager'
    >
  >;

async function createTransportManagerClient({
  numberOtherSubjectsAppointedAsManager,
  numberOtherSubjectsRegisteredAsManager,
  numberOtherSubjectVehiclesAppointedAsManager,
  numberOtherSubjectVehiclesRegisteredAsManager,
  ...transportManagerCreateRequest
}: TransportManagerCreateData) {
  const { data } = await API.client.transportManager.createTransportManager(
    '',
    '',
    {
      ...transportManagerCreateRequest,
      numberOtherSubjectVehiclesRegisteredAsManager,
      numberOtherSubjectVehiclesAppointedAsManager,
      numberOtherSubjectsRegisteredAsManager,
      numberOtherSubjectsAppointedAsManager
    },
    {
      ...endpointsConfig.createTransportManager
    }
  );
  return data;
}

async function createTransportManager(transportManagerCreateRequest: TransportManagerCreateData) {
  const { data } = await API.transportManager.createTransportManager(transportManagerCreateRequest, {
    ...endpointsConfig.createTransportManager
  });
  return data;
}

function useCreateTransportManagerMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(createTransportManagerClient, createTransportManager));
}

export default useCreateTransportManagerMutation;
