import { useDialog } from '@libs/common/v2';

import { FinancialSecuritySearchDialog } from '../..';

interface IProps {
  folderId: string;
  transferredFoldersIds?: string[];
  applicationId: string;
  folderTypeKey: string;
  customOnSuccess?: () => void;
}

export function useFinancialSecuritySearch({
  folderId,
  transferredFoldersIds,
  applicationId,
  folderTypeKey,
  customOnSuccess
}: IProps) {
  const { openDialog } = useDialog();

  const openFinancialSecuritySearchModal = () => {
    openDialog(({ closeDialog }) => (
      <FinancialSecuritySearchDialog
        closeDialog={closeDialog}
        folderId={folderId}
        transferredFoldersIds={transferredFoldersIds}
        applicationId={applicationId}
        folderTypeKey={folderTypeKey}
        customOnSuccess={customOnSuccess}
      />
    ));
  };

  return { openFinancialSecuritySearchModal };
}
