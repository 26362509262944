import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { partialTranslate } from '@libs/common';
import {
  ALTERNATIVE_DATE_FORMAT,
  DatepickerField,
  DictionarySelectField,
  GridLayout,
  Section,
  SwitchField,
  TextInputField,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { TransportManagerDetailsClient } from '../../../model';
import { LinkedTransportManagerField } from '../../common';

interface IProps {
  folderId?: string;
  transferredFoldersIds?: string[];
  isLinkedTransportManagerFieldVisible?: boolean;
  initialTransportManagerData?: TransportManagerDetailsClient;
  isCreateMode?: boolean;
}

function BasicData({
  folderId,
  isLinkedTransportManagerFieldVisible,
  transferredFoldersIds,
  initialTransportManagerData,
  isCreateMode
}: IProps) {
  const [t] = useTranslation();

  const { setValue, watch, getValues } = useFormV2Context();

  const getLabel = partialTranslate('transportManager:details');

  const validIndefinitely = watch(
    typedNameV2<TransportManagerDetailsClient>('certificate.isValidIndefinitely')
  ) as boolean;

  useEffect(() => {
    if (validIndefinitely) {
      setValue(typedNameV2<TransportManagerDetailsClient>('certificate.validTo'), null);
    }
  }, [setValue, validIndefinitely]);

  const certificateValidTo = getValues(typedNameV2<TransportManagerDetailsClient>('certificate.validTo')) as string;
  const certificateNumber = getValues(typedNameV2<TransportManagerDetailsClient>('certificate.number')) as string;
  const transportManagerIsOutOfDate = moment(certificateValidTo).isBefore(moment().format(ALTERNATIVE_DATE_FORMAT));

  return (
    <Section title={getLabel('section.basicData')} isCollapsable isModalSection>
      <GridLayout itemProps={{ xs: 12, md: 6 }}>
        <TextInputField
          name={typedNameV2<TransportManagerDetailsClient>('personalData.name')}
          label={getLabel('field.name')}
          inputProps={{ maxLength: 50 }}
          isRequired
        />
        <TextInputField
          name={typedNameV2<TransportManagerDetailsClient>('personalData.surname')}
          label={getLabel('field.surname')}
          inputProps={{ maxLength: 100 }}
          isRequired
        />
        <DatepickerField
          name={typedNameV2<TransportManagerDetailsClient>('personalData.birthDate')}
          label={getLabel('field.birthDate')}
          isRequired
        />
        <TextInputField
          name={typedNameV2<TransportManagerDetailsClient>('personalData.birthPlace')}
          label={getLabel('field.birthPlace')}
          inputProps={{ maxLength: 50 }}
          isRequired
        />
        <DictionarySelectField
          name={typedNameV2<TransportManagerDetailsClient>('typeKey')}
          label={getLabel('field.typeKey')}
          dictionaryName={DomainDictionaryEnum.TRANSPORT_MANAGER_TYPE}
          isRequired
          stringValue
        />
        <SwitchField
          name={typedNameV2<TransportManagerDetailsClient>('convicted')}
          label={getLabel('field.convicted')}
        />
        {isLinkedTransportManagerFieldVisible && (
          <LinkedTransportManagerField
            folderId={folderId}
            transferredFoldersIds={transferredFoldersIds}
            initialTransportManagerData={initialTransportManagerData}
          />
        )}
        <DatepickerField
          name={typedNameV2<TransportManagerDetailsClient>('certificate.validFrom')}
          label={getLabel('field.validFrom')}
          isRequired
        />
        <SwitchField
          name={typedNameV2<TransportManagerDetailsClient>('certificate.isValidIndefinitely')}
          label={getLabel('field.validIndefinitely')}
        />
        <DatepickerField
          name={typedNameV2<TransportManagerDetailsClient>('certificate.validTo')}
          label={getLabel('field.validTo')}
          {...(transportManagerIsOutOfDate && {
            helperText: certificateNumber
              ? t('transportManager:details.message.transportManagerIsOutOfDate', {
                  certificateNumber
                })
              : t('transportManager:details.message.transportManagerIsOutOfDateWithoutCertificateNumber')
          })}
          isRequired={!validIndefinitely}
          isDisabled={validIndefinitely}
        />
        {!isCreateMode && (
          <DatepickerField
            name={typedNameV2<TransportManagerDetailsClient>('expirationDate')}
            label={getLabel('field.terminationDate')}
          />
        )}
      </GridLayout>
    </Section>
  );
}

export default BasicData;
