import {
  FileMetadataObject,
  FileMetadataObjectList,
  ForeignPermissionDetails,
  ForeignPermissionSnapshot
} from '@ibtm/domain';
import { CalendarDate } from 'calendar-date';
import i18n from 'i18next';
import moment from 'moment';
import { array as YupArray, date as YupDate, number as YupNumber, object as YupObject, string as YupString } from 'yup';

import { DomainDictionaryEntry } from '../../config';

export enum ForeignPermitsGlobalFiltersEnum {
  YEAR = 'year'
}

export enum LimitTableEnum {
  YEAR = 'yearEquals'
}
export enum DisposablePermitsTableEnum {
  YEAR_GRATER = 'createdGreaterThanOrEqual',
  YEAR_LESS = 'createdLessThanOrEqual'
}
export enum ForeignPermissionQueryEnum {
  FOREIGN_PERMISSIONS_DETAILS = 'FOREIGN_PERMISSIONS_DETAILS',
  FOREIGN_PERMISSION_LIST = 'FOREIGN_PERMISSION_LIST',
  FOREIGN_PERMISSION_CLIENT_LIST = 'FOREIGN_PERMISSION_CLIENT_LIST',
  SINGLE_AUTHORIZATION_LIMIT_LIST = 'SINGLE_AUTHORIZATION_LIMIT_LIST',
  EKMT_LIST = 'EKMT_LIST',
  DISPOSABLE_PERMITS_LIST = 'DISPOSABLE_PERMITS_LIST',
  BASIC_RULES = 'BASIC_RULES',
  FOLDER_LITE = 'FOLDER_LITE',
  PERMISSION_DETAILS_WITH_SETTLEMENT = 'PERMISSION_DETAILS_WITH_SETTLEMENT',
  LIMIT_TRANSFER = 'LIMIT_TRANSFER'
}

export type IReleasedPermitsDetailsParams = {
  id: string;
};

export enum ForeignPermissionTypeEnum {
  EKMT = 'ibtm-domain.foreign-permit-type.ekmt-permits',
  SINGLE_PERMIT = 'ibtm-domain.foreign-permit-type.single-permits'
}

export enum ForeignNameKeyBaseEnum {
  RESOURCE_FORM_NAME = 'ibtm-domain.resource.form-name.'
}

export interface ReleasedPermitsSnapshot extends ForeignPermissionSnapshot {
  folder?: {
    id?: string;
    number?: string;
  };
  id?: string;
  folderNumber?: string;
  country?: string;
  dateOfIssue?: CalendarDate;
  name?: string;
  status?: string;
  company?: string;
  code?: number;
  formFullNumber?: string;
  formNumber?: string;
  formNumberTo?: string;
  formNumberFrom?: string;
  settlementType?: string;
}

export interface ForeignPermissionDetailsForm extends Omit<ForeignPermissionDetails, 'codeKey'> {
  returnDate?: string;
  driveCount?: number;
  driveCountEmpty?: number;
  bilateralCount?: number;
  multilateralCount?: number;
  driveUe?: number;
  driveBetweenOutsideUe?: number;
  driveForeignUe?: number;
  driveRussiaPl?: number;
  driveRussiaOther?: number;
  driveToFromPoland?: number;
  driveOtherUk?: number;
  driveUkPl?: number;
  missingPages?: string;
  notes?: string;
  settlementDate?: string;
  usedDate?: string;
  relationsKey?: { name: string; value: string };
  codeKey?: string;
}

export interface ClientForeignPermissions {
  id: string;
  countryCode: string;
  name: string;
  state: string;
  formNumber: number;
  subjectName: string;
  codeKey: string;
  type: string;
  resourceTypeId: string;
}

export interface ForeignPermissionApplicationDetailsModel {
  amount: number;
  euro5amount: number;
  euro6amount: number;
  notes: string;
  limit: number;
  resourceTypeId: string;
}

export enum PermitActionTypeEnum {
  RECEIVE = 'RECEIVE',
  RETURN = 'RETURN'
}

export const ekmtPermitValidationSchema = () =>
  YupObject().shape({
    numberFrom: YupNumber().min(1).max(52).required(),
    numberTo: YupNumber()
      .required()
      .min(1)
      .max(52)
      .when('numberFrom', (numberFrom, schema) => {
        return schema.test({
          test: numberTo => numberTo >= numberFrom,
          message: i18n.t('foreignPermits:tabs.releasedPermits.ekmtDetails.settlementEKMT.validation.numberTo')
        });
      }),
    returnDate: YupString().required(),
    ridesCount: YupNumber().required(),
    ridesWithoutCargoCount: YupNumber().nullable(),
    bilateralRidesCount: YupNumber().nullable(),
    multilateralRidesCount: YupNumber().nullable(),
    ridesBetweenEuCount: YupNumber().nullable(),
    ridesBetweenAndOutsideEuCount: YupNumber().nullable(),
    ridesOutsideEuCount: YupNumber().nullable(),
    ridesBetweenRuAndPlCount: YupNumber().nullable(),
    ridesBetweenRuAndOtherCount: YupNumber().nullable(),
    ridesInAndOutPlCount: YupNumber().nullable(),
    ridesBetweenUkAndOtherCount: YupNumber().nullable(),
    ridesBetweenUkAndPlCount: YupNumber().nullable(),
    missingPageNumbers: YupArray().nullable(),
    notes: YupString().nullable()
  });

export const ekmtSettleValidationSchema = () =>
  YupObject().shape({
    returnDate: YupDate()
      .test('maxDateToday', i18n.t('validation:maxDateTodayIncluded'), value =>
        moment(value).isSameOrBefore(moment(), 'day')
      )
      .typeError(i18n.t('validation:default')),
    codeKey: YupString().required(i18n.t('validation:required')).nullable()
  });

export const singlePermitValidationSchema = () =>
  YupObject().shape({
    returnDate: YupString().typeError(i18n.t('validation:required')).required(i18n.t('validation:required')),
    codeKey: YupString().required(i18n.t('validation:required')).nullable(),
    usedDate: YupString().when('codeKey', {
      is: codeKey => {
        return [
          DomainDictionaryEntry.RETURNS_OF_FOREIGN_LICENSES.STOLEN,
          DomainDictionaryEntry.RETURNS_OF_FOREIGN_LICENSES.LOST,
          DomainDictionaryEntry.RETURNS_OF_FOREIGN_LICENSES.NOT_USED
        ].includes(codeKey);
      },
      then: YupString().nullable(),
      otherwise: YupString().required()
    }),
    relationsKey: YupObject().nullable(),
    driveCount: YupNumber().nullable()
  });

export type BasicLimitParams = {
  id: string;
};

export interface IDownloadFileParam {
  downloadFunction: (file: FileMetadataObject) => void;
  data: FileMetadataObjectList;
}

export type SettlementCurrentPermitsData = ReleasedPermitsSnapshot & { formSumNumber?: number };
