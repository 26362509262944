import React, { ReactNode } from 'react';

interface IRouteSettings {
  layout: {
    config: {
      navbar: { display: boolean };
      topBar: { display: boolean };
      footer: { display: boolean };
      leftSidePanel: { display: boolean };
      rightSidePanel: { display: boolean };
    };
  };
}

export interface IRoute {
  params?: any;
  exact?: boolean;
  path: string;
  anonymous?: boolean;
  auth?: (params: unknown) => string[];
  element?: ReactNode;
}

export interface IRedirectPaths {
  authenticatedUserRedirectPath?: string;
  defaultRedirectPath?: string;
}

export interface IConfig {
  settings?: Record<string, IRouteSettings>;
  routes: IRoute[];
}

type RouterContextType = {
  routes: IRoute[];
  hiddenRoutesPaths: string[];
  setHiddenRoutesPaths: (hiddenRoutesPaths: string[]) => void;
};

const RouterContext = React.createContext<RouterContextType>({
  routes: [],
  hiddenRoutesPaths: [],
  setHiddenRoutesPaths: null
});

export default RouterContext;
