export interface ProceedingSnapshotClient {
  id: string;
  folderId: string;
  created: string;
  modified: string;
  enterpreneur: string;
  NIP: string;
  executive: string;
  assignedTo: {
    id?: string;
    name?: string;
  };
  certificateNumber: string;
  conductingPerson: {
    id: string;
    name: string;
  };
  proceedingNumber: string;
  caseNumber: string;
  proceedingCategory: string;
  proceedingType: string;
  status: string;
  isDecision: boolean;
  isReviewed: boolean;
  isSecondTierDecision: boolean;
  isWSAComplaint: boolean;
  isNSAComplaint: boolean;
  version: number;
  typeKey: string;
  number: string;
}

export enum ProceedingsActionsEnum {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  SHOW_DETAILS = 'SHOW_DETAILS',
  CHANGE_STATUS = 'CHANGE_STATUS',
  DELETE = 'DELETE',
  END_PROCEEDING = 'END_PROCEEDING'
}

export interface IChangeProceedingStatusFormFields {
  status: string;
}

export interface ICreateProceedingFormFields {
  proceedingType: string;
}

export interface ProceedingDetailsClient {
  id?: string;
  number?: string;
  caseNumber?: string;
  created?: string;
  author?: { id?: string; name?: string };
  assignedTo?: { id?: string; name?: string };
  modified?: string;
  typeKey?: string;
  rehearingData?: string;
  rehearingNumber?: string;
  bpDecision?: string;
  bpDecisionDate?: string;
  WSAComplaintDate?: string;
  WSAComplaintNumber?: string;
  WSAComplaintSettlement?: string;
  NSAComplaintDate?: string;
  NSAComplaintNumber?: string;
  NSAComplaintSettlement?: string;
  statusKey?: string;
  note?: string;
  version?: number;
}

export interface FormOperationListItemClient {
  id: string;
  form: {
    ids: string[];
  };
  reasonKey: string;
  statusKey: FormOperationStatus;
  cancelDate: string;
  suspensionFrom: string;
  suspensionTo: string;
  typeKey: string;
  formNumber: number;
  expirationDate: string;
  postponeDate?: string;
}

export enum ProceedingTypeKeyEnum {
  ADMINISTRATION = 'ADMINISTRATION',
  VERIFICATION = 'VERIFICATION'
}

export enum ProceedingDetailsTabEnum {
  PROCEDURE_DETAILS = 'PROCEDURE_DETAILS',
  OUTGOING_DOCUMENTS = 'OUTGOING_DOCUMENTS',
  FORM_OPERATIONS = 'FORM_OPERATIONS',
  NOTE = 'NOTE',
  FILES = 'FILES'
}

export enum FormOperationStatus {
  EXPIRED = 'status.expired',
  SUSPENDED = 'status.suspended',
  RETREATING = 'status.retreating',
  ACTIVE = 'status.active',
  POSTPONE = 'status.postpone'
}

export enum RegisteredProceedingFormEnum {
  PROCEEDING_FORM = 'PROCEEDING_FORM'
}

export type ProceedingDetailsParams = {
  folderId?: string;
  proceedingTypeKey?: string;
  id?: string;
  type?: string;
  baseProceedingId?: string;
};
