import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { useConfirmDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { ProxyQueryKeysEnum, useDeleteProxyMutation } from '../../api';

function useProxyDeleteAction() {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: deleteProxy } = useDeleteProxyMutation();

  const [confirm] = useConfirmDialog();

  const handleProxyDelete = ({
    proxyId,
    name,
    surname,
    onSuccess
  }: {
    proxyId: string;
    name: string;
    surname: string;
    onSuccess: () => void;
  }) => {
    confirm({
      title: t('proxy:action.delete.dialogTitle'),
      shortTitle: t('global:dialog.deleteConfirmTitle'),
      message: t('proxy:action.delete.confirmationText', { name, surname }),
      confirmType: 'danger',
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        deleteProxy(
          { proxyId },
          {
            onSuccess: () => {
              queryCache.invalidateQueries(ProxyQueryKeysEnum.PROXIES_LIST);
              showSuccessSnackbar(t('proxy:action.delete.successMessage'));
              closeDialog();
              onSuccess();
            },
            onSettled: () => {
              setConfirmLoading(false);
            }
          }
        );
      }
    });
  };

  return { handleProxyDelete };
}

export default useProxyDeleteAction;
