import { usePaginatedQuery } from 'react-query';
import { DefaultApiGetTemplateFileRequest } from '@avispon/document-generator';
import { PageDocumentTemplateFileDetailsExternal } from '@avispon/document-generator/dist/models';
import { AxiosRequestConfig } from 'axios';

import { API, DocumentTemplateQueryKeysEnum, endpointsConfig } from '..';

const getDocumentTemplateVersions = async (
  _: DocumentTemplateQueryKeysEnum,
  params: DefaultApiGetTemplateFileRequest
) => {
  const { data } = await API.documentGenerator.getTemplateFile(params, {
    ...endpointsConfig.getTemplateFilte
  } as AxiosRequestConfig);
  return data;
};

export default function useDocumentTemplateVersionsQuery(params: DefaultApiGetTemplateFileRequest, queryConfig = {}) {
  return usePaginatedQuery<PageDocumentTemplateFileDetailsExternal>(
    [DocumentTemplateQueryKeysEnum.DOCUMENT_TEMPLATE_VERSIONS, params],
    getDocumentTemplateVersions,
    { enabled: Boolean(params.documentTemplateId), ...queryConfig }
  );
}
