import { useQuery } from 'react-query';
import { SCertificateDetails } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import { SCertificatesKeysEnum } from './SCertificatesQueryKeysEnum';

const getSCertificate = async (id: string) => {
  const { data } = await API.sCertificates.getSCertificateDetails(id, {
    ...endpointsConfig.getSCertificateDetails
  });
  return data;
};

const getSCertificateClient = async (id: string) => {
  const { data } = await API.client.sCertificates.getSCertificateDetails(id, '', '');
  return data as SCertificateDetails;
};

export const useSCertificatesDetailsQuery = (id: string, queryConfig = {}) => {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery<SCertificateDetails>(
    [SCertificatesKeysEnum.CERTIFICATE_S, id],
    getQuery(
      () => getSCertificateClient(id),
      () => getSCertificate(id)
    ),
    queryConfig
  );
};
