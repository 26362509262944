import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RefetchOptions } from 'react-query/types/core/query';
import { FolderCreateRequest, SubjectDetails } from '@ibtm/domain';

import {
  IconButton,
  InputMode,
  SectionWithFields,
  TextInputField,
  typedNameV2,
  useDialog,
  useFormV2Context,
  ValidatorEnums
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { useDomainConfigContext } from '@libs/domain/config';
import { FolderDetailsClient, useUpdateSubjectContactDataMutation } from '@libs/domain/folder';
import ContactDataEditDialog from '@libs/domain/folder/components/details/tabs/subject/sections/subjectData/modals/ContactDataEditDialog';

interface IProps {
  refetch: (options?: RefetchOptions) => void;
  inputMode?: InputMode;
}

function ContactDataSection({ refetch, inputMode }: IProps) {
  const [t] = useTranslation();
  const { openDialog } = useDialog();
  const { formMode, getValues } = useFormV2Context();
  const { createMode } = useViewModesV2(formMode);
  const { isClientPortal, isClientPortalEntrepreneurPerspective } = useDomainConfigContext();

  const phoneFormName = useMemo(() => {
    return createMode
      ? typedNameV2<FolderCreateRequest>('subject.contactAddresses.0.phone')
      : typedNameV2<FolderDetailsClient>('subject.contact.phoneNumber');
  }, [createMode]);

  const emailFormName = useMemo(() => {
    return createMode
      ? typedNameV2<FolderCreateRequest>('subject.contactAddresses.0.email')
      : typedNameV2<FolderDetailsClient>('subject.contact.email');
  }, [createMode]);

  const openUpdateContactDataModal = useCallback(() => {
    openDialog(({ closeDialog }) => (
      <ContactDataEditDialog
        updateQuery={useUpdateSubjectContactDataMutation}
        subjectData={getValues()?.subject as SubjectDetails}
        onClose={closeDialog}
        onSuccess={() => {
          refetch();
        }}
      />
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  return (
    <SectionWithFields
      title={t('folder:details.section.contactData')}
      headerContent={
        isClientPortal && isClientPortalEntrepreneurPerspective ? (
          <IconButton
            aria-label={t('global:action.edit')}
            icon="EditIcon"
            isBackgroundTransparent
            onClick={() => {
              openUpdateContactDataModal();
            }}
          />
        ) : null
      }
      fields={[
        <TextInputField
          inputProps={{ maxLength: 9 }}
          name={phoneFormName}
          label={t('folder:details.field.phoneNumber')}
          key="details.field.phoneNumber"
          isOnlyPositiveIntegers
          {...(inputMode && { inputMode })}
        />,
        <TextInputField
          name={emailFormName}
          label={t('folder:details.field.email')}
          key="details.field.email"
          validation={{ contextValidator: [ValidatorEnums.EMAIL] }}
          {...(inputMode && { inputMode })}
        />
      ]}
      isCollapsable
    />
  );
}

export default ContactDataSection;
