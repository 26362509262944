import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function restoreAbility(proceedingId: string) {
  return API.proceedingsKreptd.proceedingKreptdRestoreAbility(proceedingId, {
    ...endpointsConfig.proceedingKreptdRestoreAbility
  });
}

function useAbilityRestorationMutations() {
  return useMutation(restoreAbility);
}

export default useAbilityRestorationMutations;
