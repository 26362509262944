import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { TableIconButton, TableLayout, useTableRowActions } from '@libs/common/v2';
import { calc, important } from '@libs/common/v2/utils';

import { useAuth } from '@libs/auth/hooks';

import { API } from '@libs/domain/api';
import { DomainUIElementEnum } from '@libs/domain/config';
import { IDepotsListClient } from '@libs/domain/resource';

import { useDepotManagersListQuery } from '../../../api';
import { useDepotsTable, useResourceDialog } from '../../../hooks';
import { parseDepotsList } from '../../../utils';
import { DepotAdminsChangeForm } from '../../forms';

function DepotsTable() {
  const [t] = useTranslation();
  const { user } = useAuth();
  const { data } = useDepotManagersListQuery({ userId: user?.personalData?.id });
  const { openDialog } = useResourceDialog();

  const classes = useStyles();
  const tableProps = useDepotsTable();

  const { renderTableActions } = useTableRowActions<IDepotsListClient>(
    [
      {
        id: 'RESOURCES_DEPOT_DETAILS_VIEW',
        label: 'action.openDetails',
        link: ({ original }) => ({
          pathname: `/resource/${original?.id}/details`
        }),
        icon: 'ArrowIcon'
      },
      {
        id: 'RESOURCES_DEPOT_CHANGE_ADMIN',
        customButton: ({ original }) => (
          <TableIconButton
            icon="PeoplesIcon"
            tooltipTitle={t('action.changeAdmins')}
            onClick={() => openDialog(DepotAdminsChangeForm, { initialData: original })}
          />
        ),
        isHidden: ({
          row: {
            original: { id }
          }
        }) => !(data?.depotIds || []).includes(id)
      }
    ],
    !!data
  );

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('resource:list.listOfResources')}
      xlsxDownload={{
        fileName: t('resource:list.listOfResources'),
        pathToXLSXTranslation: 'resource:fields',
        apiRequest: params =>
          API.depots
            .getDepots(params)
            .then(res => ({ ...res, data: { ...res.data, content: parseDepotsList(res.data.content) } }))
      }}
      rowActions={renderTableActions([
        DomainUIElementEnum.RESOURCES_DEPOT_DETAILS_VIEW,
        DomainUIElementEnum.RESOURCES_DEPOT_CHANGE_ADMIN
      ])}
      sectionContentClassName={classes.sectionContent}
      actionsColumnWidth={120}
      isSection
    />
  );
}

const useStyles = makeStyles({
  sectionContent: {
    height: important(calc('100% - 100px'))
  }
});

export default DepotsTable;
