import { useTranslation } from 'react-i18next';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button';

import Button, { IButtonProps } from './Button';

const BackButton = (
  props: Omit<IButtonProps, 'iconHeight' | 'iconWidth' | 'isPrimary' | 'isSecondary' | 'icon'> &
    Omit<MuiButtonProps, 'color' | 'size'>
) => {
  const [t] = useTranslation();

  return (
    <Button
      icon="ArrowLeftIcon"
      label={t('action.goBack')}
      variant="outlined"
      iconHeight={16}
      iconWidth={16}
      isSecondary
      {...props}
    />
  );
};

export { BackButton };
