import { CivicBoardSearchFilter } from '@ibtm/domain';

import { TableFilterMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO, getCalendarDate } from '@libs/common/v2/utils';

import { CivicBoardSnapshot } from '@libs/domain/social-commission';

export const useCivicBoardColumns = () => {
  const mappedFilterFields: TableFilterMapper<CivicBoardSnapshot, CivicBoardSearchFilter> = {
    year: (value: number) => ({ yearIn: [value] }),
    month: (value: number) => ({ monthIn: [value + 1] }),
    submissionDateFrom: ({ dateFrom, dateTo }: DateRangeISO) => ({
      submissionDateFromLessThanOrEquals: getCalendarDate(dateTo),
      submissionDateFromGreaterThanOrEquals: getCalendarDate(dateFrom)
    }),
    submissionDateTo: ({ dateFrom, dateTo }: DateRangeISO) => ({
      submissionDateToLessThanOrEquals: getCalendarDate(dateTo),
      submissionDateToGreaterThanOrEquals: getCalendarDate(dateFrom)
    }),
    receiptDateFrom: ({ dateFrom, dateTo }: DateRangeISO) => ({
      receiptDateFromLessThanOrEquals: getCalendarDate(dateTo),
      receiptDateFromGreaterThanOrEquals: getCalendarDate(dateFrom)
    }),
    receiptDateTo: ({ dateFrom, dateTo }: DateRangeISO) => ({
      receiptDateToLessThanOrEquals: getCalendarDate(dateTo),
      receiptDateToGreaterThanOrEquals: getCalendarDate(dateFrom)
    }),
    boardDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      boardDateLessThanOrEquals: getCalendarDate(dateTo),
      boardDateGreaterThanOrEquals: getCalendarDate(dateFrom)
    })
  };

  const { createColumns } = useCreateColumns<CivicBoardSnapshot>({
    pathToTranslate: 'foreignPermits:tabs.socialCommission.tabs.socialCommissionDeadlines.fields'
  });

  const columns = createColumns([
    {
      type: 'YEAR_DATEPICKER',
      accessor: 'year'
    },
    {
      type: 'MONTH_DATEPICKER',
      accessor: 'month'
    },
    {
      type: 'DATE',
      accessor: 'submissionDateFrom'
    },
    {
      type: 'DATE',
      id: 'submissionDateTo',
      accessor: 'submissionDateTo'
    },
    {
      type: 'DATE',
      accessor: 'receiptDateFrom'
    },
    {
      type: 'DATE',
      accessor: 'receiptDateTo'
    },
    {
      type: 'DATE',
      accessor: 'boardDate'
    }
  ]);

  return { columns, mappedFilterFields };
};
