import { useCallback } from 'react';

import { useDialog } from '@libs/common/v2';

import { FolderWarningDialog } from '../components/common';

function useUpdateFolderWarningDialog() {
  const { openDialog } = useDialog();

  const showDialog = useCallback(
    (id: string, refetch: () => void, version?: number, note?: string) => {
      openDialog(({ closeDialog }) => (
        <FolderWarningDialog id={id} closeDialog={closeDialog} refetch={refetch} version={version} note={note} />
      ));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog]
  );

  const openUpdateFolderWarningDialog = (id: string, refetch: () => void, version?: number, note?: string) => {
    showDialog(id, refetch, version, note);
  };

  return {
    openUpdateFolderWarningDialog
  };
}

export default useUpdateFolderWarningDialog;
