import { PluginHook } from 'react-table';
import { DefaultApiFindTemplatesRequest } from '@avispon/document-generator';
import { DocumentTemplateSnapshotExternal } from '@avispon/document-generator/dist/models';

import { CamelCasePath, useTableLayoutProps } from '@libs/common/v2';

import { useDocumentTemplateColumns, useDocumentTemplatesQuery } from '@libs/document-template';

interface DocumentTemplatesTableParams {
  plugins?: PluginHook<DocumentTemplateSnapshotExternal>[];
  queryParams?: DefaultApiFindTemplatesRequest;
  additionalFilters?: { [key: string]: string };
  visibleColumns?: Array<CamelCasePath<DocumentTemplateSnapshotExternal>>;
}

function useDocumentTemplatesTable({
  plugins = [],
  additionalFilters,
  visibleColumns,
  queryParams
}: DocumentTemplatesTableParams) {
  const { columns, mappedFilterFields } = useDocumentTemplateColumns(
    visibleColumns || ['name', 'authorName', 'created', 'modified']
  );

  return useTableLayoutProps({
    tableHookQuery: useDocumentTemplatesQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'name', desc: false }]
        }
      },
      tablePlugins: [...plugins]
    },
    tableHookQueryInitialParams: queryParams as Record<string, any>,
    ...additionalFilters
  });
}

export default useDocumentTemplatesTable;
