import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AutocompleteSelectField, SelectOption } from '@libs/common/v2';

import { useApplicationsQuery } from '../api';

interface IProps {
  applicationId?: string;
}

function ApplicationNumberAutocomplete({ applicationId }: IProps) {
  const [number, setNumber] = useState<string>('');
  const [t] = useTranslation();
  const [options, setOptions] = useState<SelectOption[]>([]);

  const { data: applicationsData } = useApplicationsQuery({
    numberContains: number,
    applicationIdNotIn: applicationId ? [applicationId] : []
  });

  useEffect(() => {
    if (applicationsData) {
      setOptions(
        applicationsData.content.map(item => {
          const option = { name: item.number, value: item.id };
          return option;
        })
      );
    }
  }, [applicationsData]);

  return (
    <AutocompleteSelectField
      name="applicationNumber"
      options={options}
      onInputChange={(_, value) => setNumber(value)}
      label={t('applications:additionalField.applicationNumber')}
      isRequired
      stringValue
    />
  );
}

export default ApplicationNumberAutocomplete;
