import { ExternalAuthorityPermissionUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

async function updateExternalAuthorityPermission({
  permissionId,
  formData
}: {
  permissionId: string;
  formData: ExternalAuthorityPermissionUpdateRequest;
}) {
  const { data } = await API.permissions.updateExternalAuthorityPermission(permissionId, formData, {
    ...endpointsConfig.updateExternalAuthorityPermission
  });
  return data;
}

function useUpdateExternalAuthorityPermissionMutation() {
  return useMutation(updateExternalAuthorityPermission);
}

export default useUpdateExternalAuthorityPermissionMutation;
