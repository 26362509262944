import { PermissionElementMap } from '@libs/permission';

import { PermissionEnum } from '@libs/domain/config/permission/PermissionEnum';

import UserUIElementEnum from './UserUIElementEnum';

const UserPermissionElementMapper: PermissionElementMap<UserUIElementEnum> = {
  /** Użytkownicy Portalu Operatora */
  [UserUIElementEnum.USERS_GROUP_USERS_ADD_BUTTON]: {
    anyOfPermission: [PermissionEnum.GROUP_USERS_ADMIN]
  },
  [UserUIElementEnum.USERS_GROUP_USERS_DELETE]: {
    anyOfPermission: [PermissionEnum.GROUP_USERS_ADMIN]
  },
  [UserUIElementEnum.USERS_GROUPS_UPDATE]: {
    anyOfPermission: [PermissionEnum.GROUP_USERS_ADMIN]
  },
  [UserUIElementEnum.USERS_ANONYMIZE_BUTTON]: {
    anyOfPermission: [PermissionEnum.USER_ADMIN]
  },
  [UserUIElementEnum.USERS_CHANGE_PASSWORD_BUTTON]: {
    anyOfPermission: [PermissionEnum.USER_ADMIN]
  },
  [UserUIElementEnum.USERS_RESUME_BUTTON]: {
    anyOfPermission: [PermissionEnum.USER_ADMIN]
  },
  [UserUIElementEnum.USERS_SUSPEND_BUTTON]: {
    anyOfPermission: [PermissionEnum.USER_ADMIN]
  },
  [UserUIElementEnum.USERS_LIST_TAB]: {
    allOfPermissions: [PermissionEnum.USER_VIEW, PermissionEnum.ACCOUNT_OPERATOR_ADMIN]
  },
  [UserUIElementEnum.USERS_UNLOCK_BUTTON]: {
    anyOfPermission: [PermissionEnum.USER_ADMIN]
  },
  [UserUIElementEnum.USERS_LOCK_BUTTON]: {
    anyOfPermission: [PermissionEnum.USER_ADMIN]
  },
  [UserUIElementEnum.USERS_ADD_BUTTON]: {
    allOfPermissions: [PermissionEnum.USER_VIEW, PermissionEnum.USER_ADMIN, PermissionEnum.ACCOUNT_OPERATOR_ADMIN]
  },
  [UserUIElementEnum.USERS_EDIT_BUTTON]: {
    allOfPermissions: [PermissionEnum.USER_VIEW, PermissionEnum.USER_ADMIN, PermissionEnum.ACCOUNT_OPERATOR_ADMIN]
  },
  [UserUIElementEnum.USERS_DETAILS_BUTTON]: {
    allOfPermissions: [PermissionEnum.USER_VIEW, PermissionEnum.ACCOUNT_OPERATOR_ADMIN]
  },
  [UserUIElementEnum.USERS_RESTORE_BUTTON]: {
    anyOfPermission: []
  },
  [UserUIElementEnum.USERS_REJECT_BUTTON]: {
    anyOfPermission: []
  },
  [UserUIElementEnum.USERS_VERIFY_BUTTON]: {
    anyOfPermission: []
  },
  [UserUIElementEnum.USERS_RESET_PASSWORD_BUTTON]: {
    anyOfPermission: []
  },
  [UserUIElementEnum.USERS_DELETE_BUTTON]: {
    anyOfPermission: []
  },
  [UserUIElementEnum.USERS_SHOW_USERS_FLAG]: {
    anyOfPermission: []
  },
  /** Użytkownicy Portalu Klienta */
  [UserUIElementEnum.CLIENT_USERS_LIST_TAB]: {
    allOfPermissions: [PermissionEnum.USER_VIEW, PermissionEnum.IBTM_DOMAIN_CLIENT_PORTAL_USER_LIST_VIEW]
  },
  [UserUIElementEnum.CLIENT_USERS_DETAILS_BUTTON]: {
    allOfPermissions: [PermissionEnum.USER_VIEW, PermissionEnum.IBTM_DOMAIN_CLIENT_PORTAL_USER_DETAILS_VIEW]
  },
  [UserUIElementEnum.CLIENT_USERS_ADD_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.USER_VIEW,
      PermissionEnum.USER_ADMIN,
      PermissionEnum.IBTM_DOMAIN_CLIENT_PORTAL_USER_CREATE
    ]
  },
  [UserUIElementEnum.CLIENT_USERS_EDIT_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.USER_VIEW,
      PermissionEnum.USER_ADMIN,
      PermissionEnum.IBTM_DOMAIN_CLIENT_PORTAL_USER_UPDATE
    ]
  },
  [UserUIElementEnum.CLIENT_USERS_CHANGE_PASSWORD_BUTTON]: {
    allOfPermissions: [
      PermissionEnum.USER_VIEW,
      PermissionEnum.USER_ADMIN,
      PermissionEnum.IBTM_DOMAIN_CLIENT_PORTAL_USER_PASSWORD_CHANGE
    ]
  },
  [UserUIElementEnum.CLIENT_USERS_USER_SUBJECT_ADD_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CLIENT_PORTAL_USER_SUBJECT_ADD]
  },
  [UserUIElementEnum.CLIENT_USERS_USER_SUBJECT_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_CLIENT_PORTAL_USER_SUBJECT_DELETE]
  },
  [UserUIElementEnum.CLIENT_USERS_UNLOCK_BUTTON]: {
    anyOfPermission: []
  },
  [UserUIElementEnum.CLIENT_USERS_LOCK_BUTTON]: {
    anyOfPermission: []
  },
  [UserUIElementEnum.CLIENT_USERS_RESEND_EMAIL_CONFIRMATION]: {
    anyOfPermission: []
  },
  [UserUIElementEnum.CLIENT_USERS_SEND_EMAIL_TO_RESET_PASSWORD]: {
    anyOfPermission: []
  },
  // Profil użytkownika
  [UserUIElementEnum.PROFILE_VIEW_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROFILE_VIEW]
  },
  [UserUIElementEnum.PROFILE_PASSWORD_CHANGE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROFILE_PASSWORD_CHANGE]
  },
  [UserUIElementEnum.PROFILE_GROUP_VIEW_TAB]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROFILE_GROUP_VIEW]
  },
  [UserUIElementEnum.PROFILE_REPLACEMENT_VIEW_TAB]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROFILE_REPLACEMENT_VIEW]
  },
  [UserUIElementEnum.PROFILE_REPLACEMENT_ADD_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_PROFILE_REPLACEMENT_ADD]
  },
  [UserUIElementEnum.ACCOUNT_SHOW_PERMISSIONS_BUTTON]: {
    anyOfPermission: [],
    allOfPermissions: []
  },
  [UserUIElementEnum.ACCOUNT_LOGGED_IN_VIEW]: {
    anyOfPermission: []
  }
};

export default UserPermissionElementMapper;
