/* eslint-disable react/no-unused-prop-types */
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { FolderDetails, LabelTypeCode } from '@ibtm/domain';
import { useAppConfig } from '@libs/app-config';
import { isNull } from 'lodash';

import { partialTranslate } from '@libs/common';
import {
  DropdownListButton,
  FormMode,
  typedNameV2,
  useConfirmDialog,
  useFormV2Context,
  useFormV2Watch,
  useViewModesV2
} from '@libs/common/v2';

import { useDownloadQuery, useFileDownload } from '@libs/file';
import { useGenerateReport } from '@libs/report/hooks';

import { useGenerateLabelQuery } from '@libs/domain/application';
import {
  checkIsPartnership,
  FolderDetailsTabEnum,
  useConfirmDataMigrationMutation,
  useEditAssociationDialog,
  useUpdateDateOfDeathDialog,
  useUpdateFolderStatusDialog
} from '@libs/domain/folder';

import { DomainDictionaryEntry, DomainUIElementEnum, useDomainConfigContext } from '../../../config';
import { GenerateReportButton } from '../../../drivers/components';
import { DriverCertificateReportTypeEnum } from '../../../drivers/model';
import { useAddSubjectToPartnerShipDialog } from '../common/AddSubjectToPartnershipDialog';

const getDetailsTranslation = partialTranslate('folder:details');

interface Props {
  activeTab: FolderDetailsTabEnum;
  showEditAssociationMembership?: boolean;
  handleClose: () => void;
  formMode: FormMode;
  refetch: () => void;
  id: string;
  openRegonModal: () => void;
  isRegonDataLoading?: boolean;
}

function FolderHeaderDropdownMenu({
  handleClose,
  id,
  openRegonModal,
  activeTab,
  showEditAssociationMembership = true,
  refetch,
  isRegonDataLoading
}: Props) {
  const [t] = useTranslation();
  const { watch, getValues, setValue, reset, formMode } = useFormV2Context();
  const [confirm] = useConfirmDialog();
  const { reportTypes } = useAppConfig();
  const { isClientPortal, isOperatorPortal } = useDomainConfigContext();
  const { showEditAssociationDialog } = useEditAssociationDialog();
  const { activateFolder, expireFolder } = useUpdateFolderStatusDialog();
  const { viewMode } = useViewModesV2(formMode);

  const version = watch(typedNameV2<FolderDetails>('version')) as number;

  const dateOfDeath = watch(typedNameV2<FolderDetails>('dateOfSubjectDeath')) as string;
  const migratedFromTransbit = watch(typedNameV2<FolderDetails>('migratedFromTransbit')) as boolean;
  const transbitDataVerified = watch(typedNameV2<FolderDetails>('transbitDataVerified')) as boolean;
  const status = watch(typedNameV2<FolderDetails>('statusKey')) as string;

  const { openUpdateDateOfDeathDialog } = useUpdateDateOfDeathDialog(id, version);
  const { mutate: confirmDataMigration } = useConfirmDataMigrationMutation();
  const { showErrorSnackbar } = useSnackbar();

  const [isLabelDownloading, setIsLabelDownloading] = useState<boolean>(false);

  const [downloadFileId, setDownloadFileId] = useState<string>();
  const downloadQuery = useDownloadQuery({ fileId: downloadFileId }, { enabled: Boolean(downloadFileId) });
  const { download, isLoading } = useFileDownload();

  const { data: label, isLoading: isLabelLoading } = useGenerateLabelQuery(
    {
      objectId: id,
      labelTypeCode: LabelTypeCode.FOLDER_LABEL
    },
    {
      onSuccess: data => {
        setDownloadFileId(data?.data?.id);
      },
      onSettled: () => {
        setIsLabelDownloading(false);
      },
      enabled: Boolean(isLabelDownloading)
    }
  );

  const values = getValues() as FolderDetails;

  const [generateReport] = useGenerateReport(reportTypes?.aboutVehicles?.reportTypeName, [
    {
      name: reportTypes?.aboutVehicles?.targetParameters?.folderId,
      value: values?.number
    },
    {
      name: reportTypes?.aboutVehicles?.targetParameters?.nip,
      value: values?.subject?.nip
    },
    {
      name: reportTypes?.aboutVehicles?.targetParameters?.company,
      value: values?.subject?.name
    }
  ]);

  useEffect(() => {
    if (downloadQuery.data) {
      download(
        () =>
          new Promise(res => {
            res(downloadQuery.data);
          })
      ).then(() => {
        handleClose();
      });
      setDownloadFileId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [download, downloadQuery]);

  useEffect(() => {
    if (isLabelDownloading) {
      if (label) {
        setDownloadFileId(label?.data?.id);
      } else {
        showErrorSnackbar(t('releaseDocuments:message.noFile'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label]);

  const confirmData = () => {
    confirm({
      title: getDetailsTranslation('message.confirmData'),
      message: getDetailsTranslation('message.areTransbitDataCorrect'),
      onConfirm: (setConfirmDialog, close) => {
        handleClose();
        setConfirmDialog(true);
        confirmDataMigration(
          { applicationId: id, version },
          {
            onSuccess: res => {
              reset({ ...getValues(), version: res.data.version, transbitDataVerified: res.data.transbitDataVerified });
              close();
            },
            onSettled: () => {
              setConfirmDialog(false);
            }
          }
        );
      },
      confirmType: 'primary'
    });
  };

  const handleDownloadFile = () => {
    setIsLabelDownloading(true);
  };

  const checkInRegonData = () => {
    openRegonModal();
  };

  const expireFolderHandler = useCallback(() => {
    expireFolder(id, refetch);
    handleClose();
  }, [id, refetch, handleClose, expireFolder]);

  const activateFolderHandler = useCallback(() => {
    activateFolder(id, refetch);
    handleClose();
  }, [id, refetch, handleClose, activateFolder]);

  const onDateOfDeathUpdated = useCallback(
    (res: FolderDetails) => {
      const subjectVersion = getValues('subject.version') as number;
      const { dateOfSubjectDeath, version } = res;
      setValue(typedNameV2<FolderDetails>('subject.version'), subjectVersion + 1);
      setValue(typedNameV2<FolderDetails>('dateOfSubjectDeath'), dateOfSubjectDeath);
      setValue(typedNameV2<FolderDetails>('version'), version);
    },
    [getValues, setValue]
  );

  const editAssociation = useCallback(() => {
    handleClose();
    showEditAssociationDialog();
  }, [handleClose, showEditAssociationDialog]);

  const updateDateOfDeath = useCallback(() => {
    handleClose();
    const dateOfSubjectDeath = getValues('dateOfSubjectDeath') as string;
    openUpdateDateOfDeathDialog(dateOfSubjectDeath, onDateOfDeathUpdated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues, openUpdateDateOfDeathDialog, onDateOfDeathUpdated]);

  const { openAddSubjectToPartnershipDialog } = useAddSubjectToPartnerShipDialog();

  const subjectLegalFormKey = useFormV2Watch<string | { value: string } | undefined>({ name: 'subject.legalFormKey' });
  const subjectLegalFormKeyValue =
    typeof subjectLegalFormKey === 'string' ? subjectLegalFormKey : subjectLegalFormKey?.value;

  return (
    <>
      {migratedFromTransbit && !transbitDataVerified && !isNull(id) && (
        <DropdownListButton
          label={getDetailsTranslation('button.confirmTransbitData')}
          onClick={confirmData}
          actionKey={DomainUIElementEnum.FOLDER_MIGRATION_CONFIRM_BUTTON}
        />
      )}
      <DropdownListButton
        isLoading={isLabelLoading || isLoading || isLabelDownloading}
        label={getDetailsTranslation('button.generateLabel')}
        onClick={handleDownloadFile}
        actionKey={DomainUIElementEnum.FOLDER_GENERATE_LABEL}
      />
      <DropdownListButton
        label={getDetailsTranslation('button.checkInRegonData')}
        isLoading={isRegonDataLoading}
        onClick={checkInRegonData}
      />
      {showEditAssociationMembership && (
        <DropdownListButton
          label={getDetailsTranslation('button.editAssociationMembership')}
          onClick={editAssociation}
        />
      )}
      <DropdownListButton
        label={
          dateOfDeath
            ? getDetailsTranslation('button.editDateOfDeath')
            : getDetailsTranslation('button.enterDateOfDeath')
        }
        actionKey={
          dateOfDeath
            ? DomainUIElementEnum.FOLDER_DATE_OF_DEATH_UPDATE_BUTTON
            : DomainUIElementEnum.FOLDER_DATE_OF_DEATH_SET_BUTTON
        }
        onClick={updateDateOfDeath}
      />
      <DropdownListButton
        label={getDetailsTranslation('button.generateVehiclesReport')}
        onClick={generateReport}
        actionKey={DomainUIElementEnum.APPLICATION_VEHICLES_GENERATE_RAPORT_BUTTON}
      />
      {activeTab === FolderDetailsTabEnum.DRIVERS && (
        <GenerateReportButton
          targetParameters={[
            { name: reportTypes?.issuingSkCompany?.targetParameters?.company, value: values?.subject?.name },
            { name: reportTypes?.issuingSkCompany?.targetParameters?.nip, value: values?.subject?.nip },
            { name: reportTypes?.issuingSkCompany?.targetParameters?.folderId, value: values?.number }
          ]}
          reportType={reportTypes?.issuingSkCompany?.reportTypeName}
          reportTitle={DriverCertificateReportTypeEnum.ISSUED}
          actionKey={DomainUIElementEnum.FOLDER_DRIVER_GENERATE_ISSUED_REPORT_BUTTON}
        />
      )}
      {viewMode && (isClientPortal || (isOperatorPortal && status === DomainDictionaryEntry.FOLDER_STATUS.ACTIVE)) && (
        <DropdownListButton
          label={t('action.expire')}
          onClick={expireFolderHandler}
          actionKey={DomainUIElementEnum.FOLDER_EXPIRE_BUTTON}
        />
      )}
      {viewMode && (isClientPortal || (isOperatorPortal && status !== DomainDictionaryEntry.FOLDER_STATUS.ACTIVE)) && (
        <DropdownListButton
          label={t('action.activate')}
          onClick={activateFolderHandler}
          actionKey={DomainUIElementEnum.FOLDER_ACTIVATE_BUTTON}
        />
      )}
      {(checkIsPartnership(subjectLegalFormKeyValue) ||
        subjectLegalFormKeyValue === DomainDictionaryEntry.LEGAL_NATURE_KEY.CIVIL_PARTNERSHIP_AGREEMENT) && (
        <DropdownListButton
          label={t('folder:action.associateMembersWithFolder')}
          onClick={() => {
            const subjectId = values.subject?.id;
            if (subjectId) {
              openAddSubjectToPartnershipDialog(subjectId);
            }
          }}
        />
      )}
    </>
  );
}

export default FolderHeaderDropdownMenu;
