import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

export type DataInputNoCriminalRecordStatementKeys =
  | 'name'
  | 'surname'
  | 'city'
  | 'postCode'
  | 'postCity'
  | 'street'
  | 'propertyNumber'
  | 'apartmentNumber'
  | 'identityCardNumber'
  | 'issuingAuthority'
  | 'pesel';

export type GenerateApplicationPrintoutsData = {
  values: Array<
    Array<{
      key?: DataInputNoCriminalRecordStatementKeys;
      value?: string;
    }>
  >;
};

export function clientApplicationGeneratePrintouts(
  requestData: {
    applicationId: string;
  } & GenerateApplicationPrintoutsData
) {
  return API.client.applicationAttachments.generateApplicationPrintouts(
    requestData.applicationId,
    { values: requestData?.values },
    {
      ...endpointsConfig.generateApplicationPrintouts
    }
  );
}

export function useClientApplicationGeneratePrintoutsMutation() {
  return useMutation(clientApplicationGeneratePrintouts);
}
