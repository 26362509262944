import { useMemo } from 'react';
import { useRowSelect } from 'react-table';

import { useMultipleSelectColumn, useTableLayoutProps } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { FolderInBranchArchiveSelectRowActionRender } from '../../common';
import { useFolderToArchiveQuery } from '../api';

import { useFolderArchiveColumns } from '.';

interface IProps {
  isMassActionVisible: boolean;
}

function useCarryOnArchivedFolderTable({ isMassActionVisible }: IProps) {
  const { columns, mappedFilterFields, mappedSortFields } = useFolderArchiveColumns({
    hiddenColumns: ['passedToDepartmentalArchiveDate'],
    hiddenLocalizatinDictionaryOptions: [DomainDictionaryEntry.FOLDER_LOCALIZATION.DEPARTMENTAL_ARCHIVE]
  });
  const multipleSelectColumn = useMultipleSelectColumn(FolderInBranchArchiveSelectRowActionRender);

  const tablePlugins = useMemo(
    () => (isMassActionVisible ? [useRowSelect, multipleSelectColumn] : []),
    [isMassActionVisible, multipleSelectColumn]
  );

  return useTableLayoutProps({
    tableHookQuery: useFolderToArchiveQuery,
    tableHookOptions: {
      columns,
      tablePlugins,
      sortByConverter: mappedSortFields,
      filterConverter: mappedFilterFields,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'number', desc: true }]
        }
      }
    },
    tableHookQueryInitialParams: {
      isCarryOnArchived: true
    },
    sectionClassName: 'mx-10'
  });
}

export default useCarryOnArchivedFolderTable;
