import { FormMode } from '../form';

export function getLastBreadcrumbText<View extends string, Create extends string, Edit extends string>(
  formMode: FormMode,
  translations: [View, Create, Edit]
): View | Create | Edit | '' {
  switch (formMode) {
    case FormMode.VIEW:
      return translations[0] || '';
    case FormMode.CREATE:
      return translations[1] || '';
    case FormMode.EDIT:
      return translations[2] || '';
    default:
      return '';
  }
}
