import { CamelCasePath } from '@libs/common/v2';

/**

 * @param id       ścieżka do modelu
 * @param header   customowy id
 * @returns        Sparsowany string z formatu dot-separation na camel case
 * @zastosowanie   Funkcja służy do sparsowania wartości id i header w kolumnach tabeli,
 *                 do wsparcia aktualnego formatu tłumaczeń.
 *                 Aktualnie pliki z tłumaczeniami kolumn tabeli są w formacie camel case.
 *                 Dodatkowo ścieżka potrafi być `atribute.name`,
 *                 natomiast tłumaczenie tego pola jest pod atrybutem name.
 *                 Wtedy przekazujemy string tłumaczenia jako atrybut header do obiektu kolumny.
 */
export const tableColumnIdParser = <T extends Record<string, any>>(id: CamelCasePath<T>, header?: string) => {
  if (header) {
    return header;
  }
  return id;
};
