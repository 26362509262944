import { usePaginatedQuery } from 'react-query';
import { FolderSearchFilter } from '@ibtm/client-domain/dist/models';
import { FolderListSearchFilter, FolderSnapshot } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { FolderQueryKeysEnum } from '@libs/domain/folder';

import { endpointsConfig } from '../endpoints-config';

export const getFoldersList = async (_, params: FolderListSearchFilter) => {
  const { data } = await API.folder.getFoldersListSnapshotPage(params, {
    ...endpointsConfig.getFoldersSnapshotPage
  });
  return data;
};

export const getFoldersListClient = async (_, params: FolderSearchFilter) => {
  const { data } = await API.client.folder.getFoldersSnapshotPage('', '', params, {
    ...endpointsConfig.getFoldersSnapshotPage
  });
  return data;
};

export default function useFoldersListQuery(
  params: FolderListSearchFilter | FolderSearchFilter,
  queryConfig = {},
  queryKey?: string
) {
  const { getQuery } = useGetApiQueryByPerspective();

  return usePaginatedQuery<IPaginatedModel<FolderSnapshot>>(
    [queryKey || FolderQueryKeysEnum.FOLDERS_LIST, params],
    getQuery(getFoldersListClient, getFoldersList),
    queryConfig
  );
}
