import { usePaginatedQuery } from 'react-query';
import { DocumentSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import {
  DocumentIssuedClient,
  DocumentsIssuedQueryEnum,
  DocumentsIssuedQueryParams
} from '@libs/domain/documents-issued/model';
import { documentsIssuedTableParser, parseListFilters } from '@libs/domain/documents-issued/utils';

import { endpointsConfig } from '../endpoints-config';

const getDocumentsToSend = async (_, params: DocumentSearchFilter) => {
  const { data } = await API.documents.getDocumentsSnapshotPage(parseListFilters(params), {
    ...endpointsConfig.getDocumentsSnapshotPage
  });
  return { ...data, content: documentsIssuedTableParser(data.content) };
};

export default function useDocumentsIssuedQuery(params: DocumentsIssuedQueryParams, queryConfig = {}) {
  return usePaginatedQuery<IPaginatedModel<DocumentIssuedClient>>(
    [DocumentsIssuedQueryEnum.DOCUMENT_LIST, params],
    getDocumentsToSend,
    queryConfig
  );
}
