import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { LabelTypeCode } from '@ibtm/domain';

import { DropdownListButton } from '@libs/common/v2';

import { useDownloadQuery, useFileDownload } from '@libs/file';

import { useGenerateLabelQuery } from '@libs/domain/application';

import { DomainUIElementEnum } from '../../config';

interface IProps {
  applicationId: string;
  onSuccess?: () => void;
}

function ApplicationGenerateLabelButton({ applicationId, onSuccess }: IProps) {
  const [t] = useTranslation();

  const { showErrorSnackbar } = useSnackbar();

  const [downloadFileId, setDownloadFileId] = useState<string>();
  const downloadQuery = useDownloadQuery({ fileId: downloadFileId }, { enabled: Boolean(downloadFileId) });
  const { download, isLoading } = useFileDownload();

  const [isLabelDownloading, setIsLabelDownloading] = useState<boolean>(false);

  useEffect(() => {
    if (downloadQuery.data) {
      download(
        () =>
          new Promise(res => {
            res(downloadQuery.data);
          })
      ).then(() => {
        onSuccess();
      });
      setDownloadFileId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [download, downloadQuery]);

  const { data: label, isLoading: isLabelLoading } = useGenerateLabelQuery(
    {
      objectId: applicationId,
      labelTypeCode: LabelTypeCode.APPLICATION_LABEL
    },
    { enabled: Boolean(isLabelDownloading) }
  );

  useEffect(() => {
    if (isLabelDownloading) {
      if (label) {
        setDownloadFileId(label?.data?.id);
      } else {
        showErrorSnackbar(t('releaseDocuments:message.noFile'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label]);

  const handleDownloadFile = () => {
    setIsLabelDownloading(true);
  };

  return (
    <DropdownListButton
      label={t('applications:button.generateLabel')}
      onClick={handleDownloadFile}
      isLoading={isLoading || isLabelLoading || isLabelDownloading}
      actionKey={DomainUIElementEnum.APPLICATION_GENERATE_LABEL}
    />
  );
}

export default ApplicationGenerateLabelButton;
