import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { partialTranslate } from '@libs/common';
import { typedNameV2, useConfirmDialog, useDialog, useFormV2Context } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { FolderDetailsClient, FolderQueryKeysEnum, useDeleteAssociationForFolderMutation } from '@libs/domain/folder';

import { SelectAssociationTable } from '../../components/details/tabs';

function useEditAssociationDialog() {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { openDialog } = useDialog();
  const { getValues, watch, setValue } = useFormV2Context();
  const [confirm] = useConfirmDialog();
  const { showSuccessSnackbar } = useSnackbar();
  const association = watch(typedNameV2<FolderDetailsClient>('association')) as FolderDetailsClient['association'];
  const { mutate: deleteAssociation } = useDeleteAssociationForFolderMutation();

  const translate = partialTranslate('folder:details.editAssociationModal');

  const openSelectAssociationDialog = useCallback(() => {
    const data: Partial<FolderDetailsClient> = getValues();
    openDialog(({ closeDialog }) => (
      <SelectAssociationTable id={data.id} version={data.version} closeDialog={closeDialog} />
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues, openDialog]);

  const showAssociationInfo = () => {
    confirm({
      message: translate('associationInfo', {
        name: association?.shortName ? association?.shortName : t('emptyMark')
      }),
      title: translate('button.removeFromAssociation'),
      confirmText: translate('button.removeFromAssociation'),
      onConfirm: () => {
        showRemoveFromAssociationDialog();
      }
    });
  };

  const showRemoveFromAssociationDialog = () => {
    confirm({
      message: translate('deleteConfirmation'),
      title: translate('deleteConfirmationTitle'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        const data: Partial<FolderDetailsClient> = getValues();
        deleteAssociation(
          { folderId: data.subject.id, associationId: association?.folderAssociationId },
          {
            onSuccess: () => {
              setValue(typedNameV2<FolderDetailsClient>('association'), null);
              showSuccessSnackbar(translate('message.deleteSubjectFromAssociationSuccess'));
              closeDialog();
              const { id } = getValues();
              queryCache.invalidateQueries([FolderQueryKeysEnum.FOLDER_EXTENDED, id]);
            },
            onSettled: () => {
              setConfirmLoading(false);
            }
          }
        );
      }
    });
  };

  const showEditAssociationDialog = () => {
    if (association) {
      showAssociationInfo();
    } else {
      openSelectAssociationDialog();
    }
  };

  return { showEditAssociationDialog };
}

export default useEditAssociationDialog;
