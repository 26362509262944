import { QueryConfig, useQuery } from 'react-query';
import { SubjectAddressSnapshotList as SubjectAddressSnapshotListClient } from '@ibtm/client-domain';
import { SubjectAddressSearchFilter, SubjectAddressSnapshotList } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import ApplicationQueryKeysEnum from './ApplicationQueryKeysEnum';

export const getApplicationAddresses = async (params: SubjectAddressSearchFilter) => {
  const { data } = await API.address.getAddressesList(params, {
    ...endpointsConfig.getAddressesList
  });

  return data;
};
export const getApplicationAddressesClient = async (params: SubjectAddressSearchFilter) => {
  const { data } = await API.client.address.getAddressesList('', params, {
    ...endpointsConfig.getAddressesList
  });

  return data;
};

export default function useApplicationAddressesQuery(
  params: SubjectAddressSearchFilter,
  queryConfig?: QueryConfig<SubjectAddressSnapshotList | SubjectAddressSnapshotListClient, unknown>
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery(
    [ApplicationQueryKeysEnum.APPLICATION_ADDRESSES, params],
    getQuery(
      () => getApplicationAddressesClient(params),
      () => getApplicationAddresses(params)
    ),
    queryConfig
  );
}
