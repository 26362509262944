import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { actions, Column, Hooks } from 'react-table';
import clsx from 'clsx';
import _, { isFunction, omit } from 'lodash';

import { TFunction } from '@libs/common';
import {
  Checkbox,
  DropdownButton,
  ISpecialColumnEnum,
  MultipleSelectColumnPlugin,
  useTableAdapter,
  useTableContext
} from '@libs/common/v2';
import { useTheme } from '@libs/common/v2/theme';

import multiSelectStyles from './multiple-select-styles';

function getMultipleSelectColumn(
  selectionActionRender: (selectedIds: string[], handleClose: () => void) => React.ReactNode,
  t: TFunction,
  alwaysVisible = false,
  checkboxTooltipTitles?: ICheckboxTooltipTitles,
  initSelectedIds: string[] = [],
  hasNoActionButtons = false,
  getRowId?: (original: Record<string, any>) => string,
  isRowDisabled?: (row: Record<string, any>) => boolean
): Column {
  const { disabledCheckboxTooltipTitle, enabledCheckboxTooltipTitle, headerCheckboxTooltipTitle } =
    checkboxTooltipTitles ?? {};
  return {
    id: ISpecialColumnEnum.SELECTION,
    Header: ({ getToggleAllRowsSelectedProps, state, rows, toggleRowSelected, toggleAllRowsSelected }: any) => {
      const table = useTableAdapter();
      const { checked, indeterminate } = getToggleAllRowsSelectedProps();

      const { checkDisabled, isError, isLoading, isFetching } = useTableContext();
      const tableDataIds = table?.data?.map(item => (getRowId ? getRowId(item) : item.id));
      const selectedIds = Object.keys(state.selectedRowIds).filter(id => tableDataIds?.includes(id)) ?? initSelectedIds;
      const isEmpty = _.isEmpty(selectedIds);
      const toggleRows = (isChecked: boolean) => {
        const toggleTo = indeterminate ? !isChecked : isChecked;
        if (!isFunction(isRowDisabled)) {
          toggleAllRowsSelected?.(toggleTo);
          return;
        }
        if (toggleTo) {
          rows.forEach((row: any) => {
            if (!isRowDisabled(row)) {
              toggleRowSelected(row.id, toggleTo);
            }
          });
        } else {
          toggleAllRowsSelected?.(false);
        }
      };

      const classes = multiSelectStyles({ isEmpty: alwaysVisible || !isEmpty });

      return (
        <div className={clsx('flex items-center pl-2', classes.header)}>
          <Checkbox
            {...getToggleAllRowsSelectedProps()}
            title={headerCheckboxTooltipTitle || t('other:component.table.body.checkAllRows')}
            onClick={e => e.stopPropagation()}
            onChange={(_, checked) => {
              toggleRows(checked);
            }}
            inputProps={{
              'aria-label': t('other:component.table.body.checkAllRows')
            }}
            isChecked={checked}
            isDisabled={isLoading || isFetching || checkDisabled(table) || isError}
            isIndeterminate={indeterminate}
          />
        </div>
      );
    },
    SelectActions: ({ state }: any) => {
      const { contrast } = useTheme();
      const table = useTableAdapter();
      const tableDataIds = table?.data?.map(item => (getRowId ? getRowId(item) : item.id));
      const selectedIds = Object.keys(state.selectedRowIds).filter(id => tableDataIds?.includes(id)) ?? initSelectedIds;
      const isEmpty = _.isEmpty(selectedIds);

      const classes = multiSelectStyles({ isEmpty: alwaysVisible || !isEmpty, contrast });

      return !hasNoActionButtons ? (
        <DropdownButton
          buttonType="button"
          isNoMargin
          title={t('global:action.executeAction')}
          forceRender
          isSecondary
          variant="outlined"
          classNames={{ button: classes.button, iconButton: classes.iconButton }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          {({ handleClose }) => {
            return selectionActionRender(selectedIds, handleClose);
          }}
        </DropdownButton>
      ) : (
        selectionActionRender(selectedIds, () => null)
      );
    },
    Cell: ({ row }: any) => {
      const table = useTableAdapter();
      const { checkDisabled, initiallyChecked, isError, isLoading, isFetching } = useTableContext();
      const { contrast } = useTheme();
      const { indeterminate, checked } = row.getToggleRowSelectedProps();
      const classes = multiSelectStyles({ contrast });

      const isRowInitiallySelected = useMemo(() => initSelectedIds.includes(getRowId ? getRowId(row) : row.id), [row]);

      useEffect(() => {
        if (isRowInitiallySelected) {
          row.toggleRowSelected(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isRowInitiallySelected]);

      useEffect(() => {
        if (initiallyChecked(table, row)) {
          row.toggleRowSelected(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [table, row]);

      return (
        <div className={classes.cell}>
          <span className="flex items-center pl-2 h-full">
            <Checkbox
              {...row.getToggleRowSelectedProps()}
              title={
                checkDisabled(table, row) || isError
                  ? disabledCheckboxTooltipTitle || t('other:component.table.body.checkRowDisabled')
                  : enabledCheckboxTooltipTitle || t('other:component.table.body.checkRow')
              }
              onClick={e => e.stopPropagation()}
              inputProps={{
                'aria-label': t('other:component.table.body.checkRow')
              }}
              isChecked={checked}
              isDisabled={isLoading || isFetching || checkDisabled(table, row) || isError || isRowDisabled?.(row)}
              isIndeterminate={indeterminate}
              style={{ padding: 4 }}
            />
          </span>
        </div>
      );
    },
    disableSortBy: true
  };
}

interface ICheckboxTooltipTitles {
  headerCheckboxTooltipTitle?: string;
  enabledCheckboxTooltipTitle?: string;
  disabledCheckboxTooltipTitle?: string;
}

function useMultipleSelectColumn(
  selectionActionRender: (selectedIds: string[], handleClose: () => void) => React.ReactNode | null,
  alwaysVisible = false,
  checkboxTooltipTitles?: ICheckboxTooltipTitles,
  initSelectedIds: string[] = [],
  hasNoActionButtons = false,
  getIfRowDisabled?: (row: Record<string, any>) => boolean,
  getRowId?: (original: Record<string, any>) => string
) {
  const [t] = useTranslation();
  const multipleSelectColumn = <T extends Record<string, any> = Record<string, unknown>>(hooks: Hooks<T>) => {
    hooks.visibleColumns.push(
      columns =>
        [
          getMultipleSelectColumn(
            selectionActionRender,
            t,
            alwaysVisible,
            checkboxTooltipTitles,
            initSelectedIds,
            hasNoActionButtons,
            getRowId,
            getIfRowDisabled
          ),
          ...columns
        ] as any
    );
    hooks.stateReducers.push(reducer);
  };

  multipleSelectColumn.pluginName = MultipleSelectColumnPlugin.useMultipleSelectColumn;

  return multipleSelectColumn;
}

actions.toggleAllRowsSelected = 'toggleAllRowsSelected';
actions.toggleRowSelected = 'toggleRowSelected';
actions.resetSelectedRows = 'resetSelectedRows';
actions.init = 'init';
// eslint-disable-next-line consistent-return
const reducer = (state, action, prevState, instance) => {
  switch (action.type) {
    case actions.toggleAllRowsSelected:
      return {
        ...state,
        selectedRowIds: action?.value
          ? {
              ...state.selectedRowIds,
              ...prevState.selectedRowIds
            }
          : {}
      };
    case actions.toggleRowSelected:
      return {
        ...state,
        selectedRowIds: action?.value
          ? {
              ...state.selectedRowIds,
              [action?.id]: true
            }
          : omit(state.selectedRowIds, action?.id)
      };
    case actions.init:
      if (Object.keys(prevState?.selectedRowIds || {}).length > 0) {
        return {
          ...state,
          tableWithInitialSelectedRows: true
        };
      }
      break;
    case actions.resetSelectedRows:
      return {
        ...state,
        selectedRowIds: instance.initialState.selectedRowIds || {}
      };
      break;
  }
};

// Hook Factory (z możliwością definiowania własnej kolumny):
// const useSingleSelectColumn = createUseSingleSelectColumn(CustomColumn)
export const createUseMultipleSelectColumn =
  <T extends Record<string, any> = Record<string, unknown>>(multipleSelectColumn: Column) =>
  (hooks: Hooks<T>) => {
    hooks.visibleColumns.push(columns => [multipleSelectColumn, ...columns] as any);
  };

export default useMultipleSelectColumn;
