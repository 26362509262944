import { usePaginatedQuery } from 'react-query';
import {
  DefaultApiGetPaginatedDictionariesRequest,
  DictionarySearchFilter,
  DictionarySnapshot
} from '@avispon/dictionary';
import { AxiosRequestConfig } from 'axios';

import { IPaginatedModel } from '@libs/common/v2';

import { API, DictionaryQueryKeysEnum, endpointsConfig } from '@libs/dictionary/api';

const getPaginatedDictionaries = async (params: DefaultApiGetPaginatedDictionariesRequest & DictionarySearchFilter) => {
  const parsedParams = {
    body: {
      ...params
    },
    ...params
  };
  const { data } = await API.dictionary.getPaginatedDictionaries(parsedParams, {
    ...(endpointsConfig.getAllDictionaries as AxiosRequestConfig)
  });

  return { ...data, content: data?.content };
};

export default function usePaginatedDictionariesQuery(
  params: DefaultApiGetPaginatedDictionariesRequest & DictionarySearchFilter
) {
  return usePaginatedQuery<IPaginatedModel<DictionarySnapshot>>([DictionaryQueryKeysEnum.DICTIONARY_LIST, params], () =>
    getPaginatedDictionaries(params)
  );
}
