import { LicenseSearchFilter } from '@ibtm/domain';

import { ColumnTypesEnum, SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { convertDateToDateFormat, DateRangeISO, getCalendarDate } from '@libs/common/v2/utils';

import { UserFilterV2 } from '@libs/user';

import { DomainDictionaryEnum } from '../../../config';
import { LicensesColumnsEnum, LicenseSnapshotClient } from '../../model/license.model';

const columnDefaultProperties = { isFilterable: true, isSortable: true };

export function useLicencesTableColumns({
  visibleColumns,
  editableColumns,
  licenseValidityYearsDictionary,
  isMutableTable = false,
  hiddenStatusFilterKeys
}: {
  visibleColumns: Array<LicensesColumnsEnum>;
  editableColumns?: {
    [key in LicensesColumnsEnum]?: boolean;
  };
  licenseValidityYearsDictionary?:
    | DomainDictionaryEnum.LICENSE_VALIDITY_YEARS
    | DomainDictionaryEnum.LICENSE_VALIDITY_YEARS_PPO
    | DomainDictionaryEnum.LICENSE_VALIDITY_YEARS_TP_OP;
  isMutableTable?: boolean;
  hiddenStatusFilterKeys?: string[];
}) {
  const { createColumns } = useCreateColumns<LicenseSnapshotClient>({
    pathToTranslate: 'license:fields',
    isMutableTable
  });

  const columns = createColumns(
    [
      {
        id: 'applicationNumber',
        type: ColumnTypesEnum.TEXT,
        isSortable: false,
        isEditable: !!editableColumns?.applicationNumber,
        ...columnDefaultProperties
      },
      {
        id: 'cancellationReason',
        type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
        dictionaryName: DomainDictionaryEnum.LICENSE_CANCELLATION_REASON,
        isFilterable: true,
        isEditable: !!editableColumns?.cancellationReason,
        ...columnDefaultProperties
      },
      {
        id: 'cancelledBy',
        type: ColumnTypesEnum.TEXT,
        isEditable: !!editableColumns?.cancelledBy,
        ...columnDefaultProperties
      },
      {
        id: 'cancellationDate',
        type: ColumnTypesEnum.DATE,
        dateFormatFn: convertDateToDateFormat,
        isEditable: !!editableColumns?.cancellationDate,
        ...columnDefaultProperties
      },
      {
        id: 'subjectName',
        type: ColumnTypesEnum.TEXT,
        ...columnDefaultProperties,
        isEditable: !!editableColumns?.subjectName,
        width: 200
      },
      {
        id: 'subjectAddress',
        type: ColumnTypesEnum.TEXT,
        ...columnDefaultProperties,
        isEditable: !!editableColumns?.subjectAddress,
        width: 200
      },
      {
        id: 'licenseNumber',
        type: ColumnTypesEnum.TEXT,
        isEditable: !!editableColumns?.licenseNumber,
        ...columnDefaultProperties
      },
      {
        id: 'formNumber',
        type: ColumnTypesEnum.TEXT,
        isEditable: !!editableColumns?.formNumber,
        ...columnDefaultProperties
      },
      {
        id: 'printDate',
        type: ColumnTypesEnum.DATE,
        dateFormatFn: convertDateToDateFormat,
        isEditable: !!editableColumns?.printDate,
        ...columnDefaultProperties
      },
      {
        id: 'licenseValidityYearsKey',
        type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
        dictionaryName: licenseValidityYearsDictionary || DomainDictionaryEnum.LICENSE_VALIDITY_YEARS,
        isFilterable: true,
        isEditable: !!editableColumns?.licenseValidityYearsKey,
        ...columnDefaultProperties
      },
      {
        id: 'validFrom',
        type: ColumnTypesEnum.DATE,
        dateFormatFn: convertDateToDateFormat,
        isEditable: !!editableColumns?.validFrom,
        ...columnDefaultProperties
      },
      {
        id: 'validTo',
        type: ColumnTypesEnum.DATE,
        dateFormatFn: convertDateToDateFormat,
        isEditable: !!editableColumns?.validTo,
        ...columnDefaultProperties
      },
      {
        id: 'dateOfIssueExternal',
        type: ColumnTypesEnum.DATE,
        dateFormatFn: convertDateToDateFormat,
        isEditable: !!editableColumns?.dateOfIssueExternal,
        ...columnDefaultProperties
      },
      {
        id: 'signerName',
        type: ColumnTypesEnum.MULTI_SELECT,
        isEditable: !!editableColumns?.signerName,
        filter: UserFilterV2,
        ...columnDefaultProperties
      },
      {
        id: 'status',
        type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
        dictionaryName: DomainDictionaryEnum.PERMISSION_STATUS,
        hiddenSelectOptionsValues: hiddenStatusFilterKeys,
        isEditable: !!editableColumns?.status,
        isFilterable: true,
        ...columnDefaultProperties
      }
    ],
    visibleColumns
  );

  const mappedFilterFields: TableFilterMapper<LicenseSnapshotClient, LicenseSearchFilter> = {
    applicationNumber: (applicationNumber: string) => ({ applicationNumberContains: applicationNumber }),
    cancellationReason: (cancellationReasons: SelectOption<string>[]) => ({
      cancellationReasonKeyIn: cancellationReasons.map(cancellationReason => cancellationReason.value)
    }),
    cancelledBy: (cancelledBy: string[]) => ({ cancellationByIn: cancelledBy }),
    cancellationDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      cancellationDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      cancellationDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    subjectName: (subjectName: string) => ({ subjectNameContains: subjectName }),
    subjectAddress: (subjectAddress: string) => ({ subjectAddressContains: subjectAddress }),
    licenseNumber: (licenseNumber: string) => ({ licenseNumberContains: licenseNumber }),
    formNumber: (formNumber: string) => ({ formNumberContains: formNumber }),
    printDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      printDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      printDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    validFrom: ({ dateFrom, dateTo }: DateRangeISO) => ({
      validFromGreaterThanOrEqual: getCalendarDate(dateFrom),
      validFromLessThanOrEqual: getCalendarDate(dateTo)
    }),
    validTo: ({ dateFrom, dateTo }: DateRangeISO) => ({
      validToGreaterThanOrEqual: getCalendarDate(dateFrom),
      validToLessThanOrEqual: getCalendarDate(dateTo)
    }),
    dateOfIssueExternal: ({ dateFrom, dateTo }: DateRangeISO) => ({
      dateOfIssueExternalGreaterThanOrEqual: getCalendarDate(dateFrom),
      dateOfIssueExternalLessThanOrEqual: getCalendarDate(dateTo)
    }),
    signerName: (signers: SelectOption<string>[]) => ({ signerIn: signers.map(signer => signer.value) }),
    status: (statuses: SelectOption<string>[]) => ({ statusKeyIn: statuses.map(status => status.value) })
  };

  const mappedSortFields: TableSortMapper<LicenseSnapshotClient> = {
    applicationNumber: 'application.number',
    cancellationReason: 'cancellationReasonKey',
    cancelledBy: 'cancelledBy.name',
    subjectName: 'folder.subject.name',
    subjectAddress: 'application.subject.mainAddress.shortAddress',
    licenseNumber: 'number',
    formNumber: 'form.number',
    signerName: 'signer.name',
    status: 'statusKey'
  };

  return {
    columns,
    mappedFilterFields,
    mappedSortFields
  };
}
