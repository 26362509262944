import { useEffect } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { DICTIONARY_IBTM_DOMAIN_PREFIX } from '@libs/config/constants';
import _ from 'lodash';

import { DictionarySelectField, TextInputField, useFormV2Context } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DictionaryEntryTypeEnum } from '@libs/dictionary';

import { useApplicationDetailsQuery } from '../../../api';
import { IApplicationDetailsPageParams } from '../../../model';

interface IProps {
  fieldId: FieldPath<FieldValues>;
  label: string;
  dictionaryName?: keyof DictionaryEntryTypeEnum;
  api: {
    get: {
      accessor: string;
    };
  };
  yupValidation: {
    required: boolean;
  };
}
function IssuingAuthorityField({ fieldId, label, dictionaryName, api, yupValidation }: IProps) {
  const { watch, setValue, formMode } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);
  const { applicationId } = useParams<IApplicationDetailsPageParams>();
  const { required } = yupValidation ?? {};
  const { data } = useApplicationDetailsQuery(applicationId, { refetchOnMount: false });

  useEffect(() => {
    if (data) {
      setValue(fieldId, _.get(data, api?.get?.accessor));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const issuingAuthority = watch(fieldId);

  return viewMode &&
    typeof issuingAuthority === 'string' &&
    !issuingAuthority?.startsWith(DICTIONARY_IBTM_DOMAIN_PREFIX) ? (
    <TextInputField name={fieldId} label={label} isRequired={required} />
  ) : (
    <DictionarySelectField
      name={fieldId}
      label={label}
      dictionaryName={dictionaryName}
      isRequired={yupValidation?.required}
      validationSingleSelect={yupValidation?.required && { required: [] }}
    />
  );
}

export default IssuingAuthorityField;
