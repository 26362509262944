export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  getApplicationTransitSchedule: { translationKey: 'applications:api.getApplicationTransitSchedule' },
  getTransitScheduleStopPage: { translationKey: 'applications:api.getTransitScheduleStopPage' },
  stopsShift: { translationKey: 'applications:api.stopsShift' },
  updateTransitSchedule: { translationKey: 'applications:api.updateTransitSchedule' },
  deleteTransitScheduleStops: { translationKey: 'applications:api.deleteTransitScheduleStops' },
  createTransitScheduleStop: { translationKey: 'applications:api.createTransitScheduleStop' },
  getTransitDates: { translationKey: 'applications:api.getTransitDates' },
  createTransitDate: { translationKey: 'applications:api.createTransitDate' },
  deleteTransitDates: { translationKey: 'applications:api.deleteTransitDates' },
  editTransitFrequency: { translationKey: 'applications:api.editTransitFrequency' }
};
