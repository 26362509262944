import BooleanSelect from './BooleanSelect';
import CustomDatepicker from './CustomDatepicker';
import DatepickerFilter from './DatepickerFilter';
import DictionaryFilter from './DictionaryFilter';
import FetchSelectFilter from './FetchSelectFilter';
import NumberFilter from './NumberFilter';
import NumberRangeFilter from './NumberRangeFilter';
import QuickChangeableDictionaryFilter from './QuickChangeableDictionaryFilter';
import SelectFilter from './SelectFilter';
import TextFilter from './TextFilter';
import TimepickerFilter from './TimepickerFilter';

const TableFilter = {
  Text: props => <TextFilter {...props} />,
  DictionaryMultiSelect: props => <DictionaryFilter shouldCheckVisibility {...props} />,
  DictionarySingleSelect: props => <DictionaryFilter shouldCheckVisibility isMultiple={false} {...props} />,
  MultiSelect: props => <SelectFilter shouldCheckVisibility {...props} />,
  SingleSelect: props => <SelectFilter shouldCheckVisibility isMultiple={false} {...props} />,
  Boolean: props => <BooleanSelect {...props} />,
  Datepicker: props => <DatepickerFilter {...props} />,
  MultiSelectFetch: props => <FetchSelectFilter shouldCheckVisibility {...props} />,
  SingleSelectFetch: props => <FetchSelectFilter shouldCheckVisibility isMultiple={false} {...props} />,
  Number: props => <NumberFilter {...props} />,
  NumberRange: props => <NumberRangeFilter {...props} />,
  QuickChangeDictionaryMultiSelect: props => <QuickChangeableDictionaryFilter shouldCheckVisibility {...props} />,
  QuickChangeDictionarySingleSelect: props => (
    <QuickChangeableDictionaryFilter shouldCheckVisibility isMultiple={false} {...props} />
  ),
  Timepicker: props => <TimepickerFilter {...props} />,
  CustomDatepicker: props => <CustomDatepicker {...props} />
};

export default TableFilter;
