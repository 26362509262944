import { usePaginatedQuery } from 'react-query';
import { ResourceObjectContentPoolSearchFilter } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { ListTypeEnum, ResourceObjectContentPoolClient } from '../../../model';
import { resourceObjectContentPoolsParser } from '../../../utils';
import { endpointsConfig } from '../../endpoints-config';
import { ResourceQueryKeysEnum } from '..';

const getResourceObjectContentPools = async (params: ResourceObjectContentPoolSearchFilter) => {
  const { data } = await API.resourceObjectPools.getResourceObjectContentPoolSnapshot(params, {
    ...endpointsConfig.getResourceObjectContentPoolSnapshot
  });

  return {
    ...data,
    content: resourceObjectContentPoolsParser(data.content)
  };
};

export default function useResourceObjectContentPoolsQuery(
  params: ResourceObjectContentPoolSearchFilter & { depotIdIn?: string[]; listType: string }
) {
  return usePaginatedQuery<{ content: ResourceObjectContentPoolClient[] }>(
    [
      params.listType === ListTypeEnum.SOURCE
        ? ResourceQueryKeysEnum.RESOURCE_OBJECT_CONTENT_POOLS_SOURCE
        : ResourceQueryKeysEnum.RESOURCE_OBJECT_CONTENT_POOLS_TARGET,
      params
    ],
    () => {
      if (params?.depotIdIn && params?.depotIdIn[0] === 'empty') {
        return new Promise(res => {
          res({
            content: []
          });
        });
      }

      return getResourceObjectContentPools(params);
    },
    {
      enabled: !!params.depotIdIn?.length
    }
  );
}
