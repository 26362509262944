enum PermissionTableEnum {
  /** Licencje */
  LISENSE = 'LISENSE',
  /** Zezwolenia GITD */
  GITD = 'GITD',
  /** Zezwolenia / Licencje krajowe wydane przez organ zewnętrzny */
  EXTERNAL_AUTHORITY_PERMISSION = 'EXTERNAL_AUTHORITY_PERMISSION',
  /** Wypisy z licencji */
  LICENSE_COPY = 'LICENSE_COPY',
  /** Wypisy z zezwolenia */
  PERMISSION_COPY = 'PERMISSION_COPY',
  /** Świadectwa Kierowcy */
  DRIVER_CERTIFICATE = 'DRIVER_CERTIFICATE',
  /** Zezwolenia na przewozy regularne w ramach UE */
  SPO_UE = 'SPO_UE',
  /**  Wypisy do zezwolenia na przewozy regularne w ramach UE */
  SPO_UE_COPY = 'SPO_UE_COPY',
  /** Zezwolenia na przewozy regularne poza EU */
  SPO_OUTSIDE_UE = 'SPO_OUTSIDE_UE',
  /** Wypisy do zezwolenia na przewozy regularne poza UE */
  SPO_OUTSIDE_UE_COPY = 'SPO_OUTSIDE_UE_COPY',
  /** Zezwolenia na przewozy wahadłowe */
  SPO_SHUTTLE = 'SPO_SHUTTLE',
  /** Wypisy do zezwolenia na przewozy wahadłowe */
  SPO_SHUTTLE_COPY = 'SPO_SHUTTLE_COPY',
  /**  Zezwolenia na przewozy okazjonalne */
  SPO_OCCASIONAL = 'SPO_OCCASIONAL',
  /** Wypisy do zezwolenia na przewozy okazjonalne */
  SPO_OCCASIONAL_COPY = 'SPO_OCCASIONAL_COPY',
  /** Zezwolenia na przewozy regularne tranzytem przez teren RP */
  SPO_TRANSIT = 'SPO_TRANSIT',
  /** Wypisy do zezwolenia na przewozy regularne tranzytem przez teren RP */
  SPO_TRANSIT_COPY = 'SPO_TRANSIT_COPY',
  /** Zaświadczenia na przewozy na potrzeby własne */
  OWN_NEEDS = 'OWN_NEEDS',
  /** Wypis do zaświadczenia na przewozy na potrzeby własne */
  OWN_NEEDS_COPY = 'OWN_NEEDS_COPY',
  /** Zezwolenia / Licencje wydane przez organ zagraniczny */
  FOREIGN_AUTHORITY_PERMISSION = 'FOREIGN_AUTHORITY_PERMISSION',
  /** Zezwolenia zagraniczne */
  FOREIGN_PERMISSION = 'FOREIGN_PERMISSION',
  /** Karnety EKMT */
  EKMT_CARNETS = 'EKMT_CARNETS',
  /** Certyfikaty S */
  S_CERTIFICATE = 'S_CERTIFICATE',
  /** Interbus */
  INTERBUS = 'INTERBUS',
  /** Formularze Podróży */
  TRAVEL_FORM = 'TRAVEL_FORM'
}

export default PermissionTableEnum;
