import { useContext } from 'react';

import { AutoLogoutContext, type ShowSnackbarFn } from '../components/AutoLogoutProvider';

export function useAutoLogout({ showSnackbar }: { showSnackbar?: ShowSnackbarFn } = {}) {
  const context = useContext(AutoLogoutContext);
  if (!context) {
    throw new Error('useAutoLogout must be used within a AutoLogoutProvider');
  }
  if (showSnackbar) {
    context.setShowSnackbarFn(showSnackbar);
  }
  return context;
}
