import { useSelector } from 'react-redux';
import { DictionarySnapshot } from '@avispon/dictionary';
import { EntityDefinitionDetails } from '@stack/metaforms';
import { combineReducers } from 'redux';

import { createDataFetchSlice, DataFetchSliceState } from '@libs/common/reducerFactories/createDataFetchSlice';
import { ValidatorEnums } from '@libs/common/v2';
import { pick, PromiseState, TypedSelector } from '@libs/common/v2/utils';

import { API, DictionaryEntry, DictionaryEntryNameEnum, DictionaryEntrySnapshot } from '@libs/dictionary';

// Ta tablica pozwala zamockować słowniki w przypadku ich braku po stronie backendu
export const extraDictionaries: DictionaryEntrySnapshot[] = [];

export type DictionaryEntriesMap = {
  [dictionaryType in DictionaryEntryNameEnum]: DictionaryEntry;
};

export interface DictionaryEntriesState extends PromiseState {
  data: DictionaryEntriesMap | null;
}

export interface DictionaryInfo {
  name: string;
  id: string;
  description: string;
}

type DictionaryInfoMap = {
  [dictionaryType in DictionaryEntryNameEnum]: DictionaryInfo;
};

export interface DictionaryListState extends PromiseState {
  data: DictionaryInfoMap | null;
}

// Musi to byc w taki sposób bo inaczej zostaje ten kod ztranspilowany do JS z dodatkowymi modułami - przez co
// nie działa w web workerze
// eslint-disable-next-line @typescript-eslint/no-implied-eval
export const convertDictionariesFunction = new Function(
  `data`,
  `extraD`,
  `const listOfDictionaryKeys = data
    .concat(extraD || [])
    .reduce((acc, curr) => [...acc, ...curr.dictionaries], [])
    .filter((value, index, self) => self.indexOf(value) === index);

  const dict = {};

  listOfDictionaryKeys.forEach(curr => {
    const entries = [];
    const translations = {};

    (extraD ? [...data, ...extraD] : data).forEach(entry => {
      if (Object.prototype.toString.call(entry.dictionaries) === '[object Set]') {
        entry.dictionaries = Array.from(entry.dictionaries);
      }

      if (entry.dictionaries.includes(curr)) {
        entries.push({
          name: entry.value,
          value: entry.key,
          active: entry.active
        });

        translations[entry.key] = entry.value;
      }
    });

    entries.sort((a, b) => a?.name?.localeCompare(b?.name));

    dict[curr] = { entries, translations };
  });
  return dict;`
);

export const convertDictionaries = (data: DictionaryEntrySnapshot[], extraD?: DictionaryEntrySnapshot[]) => {
  const listOfDictionaryKeys = data
    .concat(extraD || [])
    .reduce((acc: string[], curr) => [...acc, ...curr.dictionaries], [])
    .filter((value, index, self) => self.indexOf(value) === index);

  const dict = {};

  listOfDictionaryKeys.forEach(curr => {
    const entries = [];
    const translations = {};

    (extraD ? [...data, ...extraD] : data).forEach(entry => {
      const dictionaryEntry: DictionaryEntrySnapshot = entry;
      if (Object.prototype.toString.call(entry.dictionaries) === '[object Set]') {
        dictionaryEntry.dictionaries = Array.from(entry.dictionaries);
      }

      if (dictionaryEntry.dictionaries.includes(curr)) {
        entries.push({
          name: dictionaryEntry.value,
          value: dictionaryEntry.key,
          active: dictionaryEntry.active
        });

        translations[dictionaryEntry.key] = dictionaryEntry.value;
      }
    });

    entries.sort((a, b) => a?.name?.localeCompare(b?.name));

    dict[curr] = { entries, translations };
  });
  return dict;
};

const convertDictionarySnapshotListData = (data): DictionaryInfoMap =>
  data.content.reduce(
    (acc: DictionaryInfoMap, dictionary: DictionarySnapshot) => ({
      ...acc,
      [dictionary.name]: pick(dictionary, 'name', 'id', 'description')
    }),
    {}
  );

const list = createDataFetchSlice<DictionaryInfoMap>(
  'dictionaries',
  'getDictionarySnapshotList',
  convertDictionarySnapshotListData
);

export const getDictionarySnapshotList = () => (dispatch, getState) => {
  const { status } = getState().dictionary.list as DataFetchSliceState<DictionaryInfoMap>;

  if (!status.downloaded && !status.fetching) {
    dispatch(list.actions.get(API.dictionary.getAllDictionaries({})));
  }
};

const dictionaryReducers = combineReducers({
  list: list.slice.reducer
});

export const useDictionarySelector = useSelector as TypedSelector<{
  dictionary: {
    entries: DictionaryEntriesState;
    list: DictionaryListState;
  };
}>;

type FieldDefinition = {
  id: string;
  nameKey: string;
  descriptionKey: string;
  valueType: string;
  dictionaryName: string;
  allowMultipleValue: boolean;
  defaultRawValue: string;
  validatorNames: Array<ValidatorEnums>;
};

type FormDefinition = {
  id: string;
  author: {
    id: string;
    name: string;
  };
  modifier: string;
  version: number;
  key: string;
  formVersion: number;
  nameKey: string;
  statusKey: string;
  descriptionKey: string;
  requiredPermission: string;
  fieldDefinitions: Array<FieldDefinition>;
};

export const useContextSelector = useSelector as TypedSelector<{
  context: {
    date: any;
    context: {
      data: {
        dictionary: DictionaryEntriesMap;
        validators: {
          [key in ValidatorEnums]?: {
            regex: string;
            expression: string;
            placeholder: string;
            message: string;
            description: string;
            required: boolean;
          };
        };
        formDefinitions: Array<FormDefinition>;
        entityDefinitions: Array<EntityDefinitionDetails>;
      };
      isFetching: boolean;
    };
  };
}>;

export default dictionaryReducers;
