import { QueryConfig, useQuery } from 'react-query';

import { API, endpointsConfig } from '..';

import DocumentsQueryKeysEnum from './DocumentsQueryKeysEnum';

const downloadDocument = (params: { fileId: string }) => {
  return API.file.download(params, { responseType: 'blob', ...endpointsConfig.download });
};

export default function useDownloadDocumentQuery(
  params: { fileId: string },
  queryConfig: QueryConfig<any, unknown> = {}
) {
  return useQuery([DocumentsQueryKeysEnum.DOWNLOAD_FILE, params], () => downloadDocument(params), queryConfig);
}
