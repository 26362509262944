import { useDialog } from '@libs/common/v2';

import { ProceedingChangeStatusDialog } from '../components/proceedings-dialogs';
import { ProceedingSnapshotClient } from '..';

const useProceedingChangeStatusAction = () => {
  const { openDialog } = useDialog();

  const open = (proceedingData: ProceedingSnapshotClient, onSuccess: () => void) => {
    openDialog(({ closeDialog }) => (
      <ProceedingChangeStatusDialog proceedingData={proceedingData} onSuccess={onSuccess} closeDialog={closeDialog} />
    ));
  };

  return {
    openProceedingChangeStatusDialog: open
  };
};

export default useProceedingChangeStatusAction;
