import { useTranslation } from 'react-i18next';
import { ProceedingKreptdCertificateDetails } from '@ibtm/domain';
import { makeStyles } from '@mui/styles';

import { GridLayout, useFormV2Context, WarningInformation } from '@libs/common/v2';

import {
  CertificatesReturnSection,
  DecisionSection,
  SecondTierSection,
  StartSection,
  SuspensionSection
} from '../sections';

function DeclarationOfInabilityTab() {
  const { watch } = useFormV2Context();
  const [t] = useTranslation();
  const classes = useStyles();
  const certificates = watch('certificates') as ProceedingKreptdCertificateDetails[];
  const isFineImposedAvailable = watch('fineImposedAvailable') as boolean;

  return (
    <div className="overflow-hidden" style={{ paddingBottom: 80 }}>
      {isFineImposedAvailable && (
        <WarningInformation
          content={t('roadTransport:messages.fineImposedInformationForDischargeAndSuspension')}
          paperClassName={classes.warning}
          isIconVisible
        />
      )}
      <GridLayout itemProps={{ xs: 12 }}>
        <StartSection />
        <SuspensionSection />
        <DecisionSection hiddenFields={['decisionPermissionSuspensionFrom', 'decisionPermissionSuspensionTo']} />
        <SecondTierSection />
        <CertificatesReturnSection data={certificates} isDeclarationOfInability />
      </GridLayout>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  warning: {
    marginTop: 12
  }
}));

export default DeclarationOfInabilityTab;
