import { MoneyTransferSearchFilter, MoneyTransferSnapshot } from '@ibtm/domain';

import { CamelCasePath, TableFilterMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO, getCalendarDate } from '@libs/common/v2/utils';

const useMoneyTransfersTableColumns = (visibleColumns?: Array<CamelCasePath<MoneyTransferSnapshot>>) => {
  const { createColumns } = useCreateColumns<MoneyTransferSnapshot>({
    pathToTranslate: 'pricing:moneyTransfers.field'
  });

  const mappedFilterFields: TableFilterMapper<MoneyTransferSnapshot, MoneyTransferSearchFilter> = {
    paymentDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      paymentDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      paymentDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    transactionDescription: (description: string) => ({ transactionDescriptionContains: description }),
    senderAccountNumber: (accountNumber: string) => ({ senderAccountNumberContains: accountNumber }),
    senderName: (senderName: string) => ({ senderNameContains: senderName }),
    amount: (amount: { min: number; max: number }) => ({
      amountGreaterThanOrEqual: amount.min,
      amountLessThanOrEqual: amount.max
    })
  };

  const columns = createColumns(
    [
      {
        type: 'DATE',
        accessor: 'paymentDate',
        width: 255
      },
      {
        type: 'TEXT',
        accessor: 'transactionDescription',
        isSortable: false
      },
      {
        type: 'TEXT',
        accessor: 'senderAccountNumber',
        isSortable: false
      },
      {
        type: 'TEXT',
        accessor: 'senderName',
        isSortable: false,
        width: 200
      },
      {
        type: 'NUMBER_RANGE',
        accessor: 'amount',
        isSortable: false
      },
      {
        type: 'NUMBER',
        accessor: 'currentApplicationAmountUsed',
        isSortable: false
      },
      {
        type: 'NUMBER_RANGE',
        accessor: 'availableAmount',
        isSortable: false,
        isFilterable: false,
        width: 250
      }
    ],
    visibleColumns
  );

  return { columns, mappedFilterFields };
};

export default useMoneyTransfersTableColumns;
