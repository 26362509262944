import { PartnerApplicantDetails } from '@ibtm/domain';

import { useDialog } from '@libs/common/v2';

import { PartnerApplicantsEditDialog } from '../../components';

interface IProps {
  partnerApplicantId?: string;
  refetch?: () => void;
  partnerApplicant?: PartnerApplicantDetails;
  clearSelection?: () => void;
}

function usePartnerApplicantsEditDialog({ partnerApplicantId, partnerApplicant, clearSelection }: IProps) {
  const { openDialog } = useDialog();

  const showPartnerApplicantsEditDialog = (refetch: () => void) => {
    openDialog(({ closeDialog }) => (
      <PartnerApplicantsEditDialog
        onCloseDialog={closeDialog}
        isOpen
        partnerApplicantId={partnerApplicantId}
        refetch={refetch}
        partnerApplicant={partnerApplicant}
        clearSelection={clearSelection}
      />
    ));
  };

  return { showPartnerApplicantsEditDialog };
}

export default usePartnerApplicantsEditDialog;
