import { useEffect, useMemo } from 'react';
import { FieldPath, FieldValues, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { size } from 'lodash';

import { partialTranslate } from '@libs/common';
import {
  Button,
  GridItem,
  GridLayout,
  IconButton,
  InputMode,
  Section,
  SelectOption,
  TimepickerField,
  typedNameV2,
  useFormV2Context,
  useIsSmallScreen
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DomainDictionaryEntry } from '@libs/domain/config';

const getLabel = partialTranslate('applications:transitSchedule.fields');

interface IProps {
  directionKey: SelectOption;
}

function DeparturesSection({ directionKey }: IProps) {
  const { isSmallScreen } = useIsSmallScreen();
  const [t] = useTranslation();
  const { getValues, formMode, control, clearErrors } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);
  const inputMode = viewMode ? InputMode.VIEW : InputMode.FORM;
  const classes = useStyles();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `departures` as FieldPath<FieldValues>
  });

  const arrivalDirections = useMemo(
    () => [DomainDictionaryEntry.TRANSIT_DIRECTION.ROUND_TRIP, DomainDictionaryEntry.TRANSIT_DIRECTION.RETURN],
    []
  );
  const departureDirections = useMemo(
    () => [DomainDictionaryEntry.TRANSIT_DIRECTION.ROUND_TRIP, DomainDictionaryEntry.TRANSIT_DIRECTION.ONE_WAY],
    []
  );

  const lastDepartureIndex = size(getValues('departures'));

  const handleDeleteDeparture = index => {
    remove(index);
  };

  const handleAddDeparture = () => {
    append({ ordinal: lastDepartureIndex + 1, departure: null, arrival: null });
  };

  const departureValidation = useMemo(() => {
    return {
      departureFieldValidation: departureDirections.includes(directionKey?.value),
      arrivalFieldValidation: arrivalDirections.includes(directionKey?.value)
    };
  }, [directionKey, arrivalDirections, departureDirections]);

  useEffect(() => {
    const fieldsDepartureGroup: string[] = [];
    const fieldsArrivalGroup: string[] = [];
    for (let index = 0; index <= lastDepartureIndex; index++) {
      fieldsDepartureGroup.push(`departures.${index}.departure`);
      fieldsArrivalGroup.push(`departures.${index}.arrival`);
    }

    if (departureDirections.includes(directionKey?.value)) {
      clearErrors(fieldsArrivalGroup);
    } else if (arrivalDirections.includes(directionKey?.value)) {
      clearErrors(fieldsDepartureGroup);
    } else {
      clearErrors([...fieldsDepartureGroup, ...fieldsArrivalGroup]);
    }
  }, [directionKey, arrivalDirections, departureDirections, lastDepartureIndex, clearErrors]);

  return (
    <Section
      isModalSection
      title={t('applications:transitSchedule.dialog.departuresTitle')}
      headerContent={
        !viewMode && (
          <Button
            isPrimary
            disabled={lastDepartureIndex >= 50}
            variant="outlined"
            onClick={handleAddDeparture}
            label={t(isSmallScreen ? 'action.add' : 'applications:transitSchedule.actions.addHour')}
          />
        )
      }
    >
      <GridLayout itemProps={{ xs: 12 }}>
        {fields?.map((field, i) => (
          <div key={field.id} className="flex">
            <GridItem xs={6} md={viewMode ? 6 : 5} className="pr-12">
              <TimepickerField
                name={typedNameV2<any>(`departures.${i}.departure`)}
                label={`${getLabel('departure')} ${i + 1}`}
                inputMode={inputMode}
                {...(departureValidation.departureFieldValidation && { validation: { required: [] } })}
                isRequired={departureValidation.departureFieldValidation}
              />
            </GridItem>
            <GridItem xs={6} md={viewMode ? 6 : 5} className="px-12">
              <TimepickerField
                name={typedNameV2<any>(`departures.${i}.arrival`)}
                label={`${getLabel('arrival')} ${i + 1}`}
                inputMode={inputMode}
                {...(departureValidation.arrivalFieldValidation && { validation: { required: [] } })}
                isRequired={departureValidation.arrivalFieldValidation}
              />
            </GridItem>
            {!viewMode && i !== 0 && (
              <GridItem xs={2} className="mt-8 pl-12">
                <IconButton
                  title={`${t('global:action.delete')}`}
                  placement="bottom"
                  className={classes.icon}
                  onClick={() => handleDeleteDeparture(i)}
                  icon="TrashIcon"
                />
              </GridItem>
            )}
          </div>
        ))}
      </GridLayout>
    </Section>
  );
}

const useStyles = makeStyles(() => ({
  icon: {
    marginTop: 15
  }
}));

export default DeparturesSection;
