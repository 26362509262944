import { DictionarySelectField, useFormV2Context } from '@libs/common/v2';

import { useMetaFormContext } from '@libs/meta-form/context';
import { DictionaryFieldProperties, FieldProps, FieldTypes } from '@libs/meta-form/models';
import { useGetQuery, useMapAdditionalFieldsValidationType } from '@libs/meta-form/services';

function DictionaryField({
  fieldId,
  label,
  dictionaryName,
  freeSolo,
  api,
  multiple,
  inputMode,
  defaultInitialValue,
  optionsFilter,
  disabled,
  yupValidation,
  stringValue,
  triggerFieldIdValidationOnChange
}: FieldProps<DictionaryFieldProperties>) {
  const { setValue, trigger } = useFormV2Context();
  const { apiRegistry, additionalSelectOptionsFilters } = useMetaFormContext();
  useMapAdditionalFieldsValidationType(fieldId, { type: FieldTypes.DICTIONARY });

  const getValue = (newValue: string | string[]) => {
    if (multiple && Array.isArray(newValue)) {
      return (
        newValue?.map((value: string) => ({ value })) ??
        (Array.isArray(defaultInitialValue) && defaultInitialValue?.map((value: string) => ({ value })))
      );
    }

    return { value: newValue ?? defaultInitialValue };
  };

  useGetQuery({
    requestKey: api.get.requestKey,
    accessor: api.get.accessor,
    apiRegistry,
    params: api?.get?.backendParams,
    setValue: (newValue: string | string[]) =>
      setValue(fieldId, !newValue && !defaultInitialValue ? undefined : getValue(newValue)),
    fieldId
  });

  const handleOnChange = () => {
    if (triggerFieldIdValidationOnChange?.length) {
      trigger(triggerFieldIdValidationOnChange);
    }
  };

  return (
    <DictionarySelectField
      name={fieldId}
      dictionaryName={dictionaryName}
      label={label}
      optionsFilter={additionalSelectOptionsFilters?.[optionsFilter]}
      isMultiple={multiple}
      isFreeSolo={freeSolo}
      inputMode={inputMode}
      isDisabled={disabled}
      isRequired={yupValidation?.required}
      stringValue={stringValue}
      onChange={handleOnChange}
    />
  );
}

export default DictionaryField;
