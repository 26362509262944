import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderLimitDetails } from '@ibtm/domain';
import { useAppConfig } from '@libs/app-config';
import { AxiosResponse } from 'axios';

import { Button, ForwardTableState, TableLayout, useRouter, useTableRowActions } from '@libs/common/v2';

import { useGenerateReport } from '@libs/report/hooks';

import { API } from '@libs/domain/api';
import { DomainDictionaryEntry, DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import { useEKMTTable } from '@libs/domain/foreign-permission';

function EkmtTablePage() {
  const [t] = useTranslation();
  const [content, setContent] = useState<FolderLimitDetails[]>([]);
  const tableProps = useEKMTTable({
    queryInitialParams: { typeKeyIn: [DomainDictionaryEntry.FOREIGN_PERMIT_TYPE.EKMT] }
  });
  const { routes } = useRouter();

  const { yearFilterContextValues } = useDomainConfigContext();

  const { renderTableActions } = useTableRowActions<FolderLimitDetails>([
    {
      id: DomainUIElementEnum.PERMITS_DISPOSABLE_SHOW_DETAILS_VIEW,
      label: 'foreignPermits:tabs.disposablePermits.action.details',
      icon: 'ArrowIcon',
      link: ({ original }) => routes.ekmtPermitDetails(original?.folder?.id)
    }
  ]);
  const { reportTypes } = useAppConfig();
  const [generateReport, isReportTypesLoading] = useGenerateReport(reportTypes?.ekmtDataSummary?.reportTypeName, [
    {
      name: reportTypes?.ekmtDataSummary?.targetParameters?.year,
      value: yearFilterContextValues?.value?.toString?.()
    }
  ]);
  const handleGenerateReport = () => {
    generateReport();
  };

  const requestToXlsx = async (params): Promise<AxiosResponse> => {
    const data = await API.folderLimits.getFolderLimitsPage(params);

    return {
      ...data,
      data: {
        content
      }
    };
  };

  return (
    <TableLayout
      {...tableProps}
      xlsxDownload={{
        fileName: t('foreignPermits:tabs.ekmt.title'),
        pathToXLSXTranslation: 'foreignPermits:tabs.ekmt.fields',
        apiRequest: params => requestToXlsx(params)
      }}
      pageTitle={t('foreignPermits:tabs.ekmt.title')}
      headerActions={
        <Button
          label={t('foreignPermits:tabs.ekmt.generate')}
          onClick={handleGenerateReport}
          isLoading={isReportTypesLoading}
          actionKey={DomainUIElementEnum.PERMITS_DISPOSABLE_REPORT_GENERATE_BUTTON}
        />
      }
      rowActions={renderTableActions([DomainUIElementEnum.PERMITS_DISPOSABLE_SHOW_DETAILS_VIEW])}
      actionKey={DomainUIElementEnum.PERMITS_EKMT_VIEW}
    >
      <ForwardTableState setState={setContent} tableStatePath="data" />
    </TableLayout>
  );
}

export default EkmtTablePage;
