import { useTranslation } from 'react-i18next';

import { Dialog, useDialog } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary/hooks';
import { DictionaryEntryTypeEnum } from '@libs/dictionary/models';

interface IProps {
  title?: string;
  confirmText?: string;
  dialogSize?: 'small' | 'medium' | 'large';
  isCancelButtonVisible?: boolean;
  dictionaryKey?: keyof DictionaryEntryTypeEnum;
  dictionaryItem?: string;
}

export default function useDictionaryKeyDialog() {
  const [t] = useTranslation();
  const { openDialog } = useDialog();
  const { getDictionaryTranslator } = useDictionaryTranslations();

  const open = ({
    title,
    dialogSize = 'small',
    isCancelButtonVisible = true,
    dictionaryKey,
    dictionaryItem
  }: IProps) => {
    const translatedDictionaryKey = getDictionaryTranslator(dictionaryKey);
    const translatedDictionaryValue = translatedDictionaryKey[dictionaryItem];
    const parsedTranslatedDictionaryValue = <div dangerouslySetInnerHTML={{ __html: translatedDictionaryValue }} />;

    openDialog(({ closeDialog }) => (
      <Dialog
        title={title}
        cancelText={t('action.close')}
        onCancel={() => {
          closeDialog();
        }}
        onClose={closeDialog}
        dialogSize={dialogSize}
        isCancelButtonVisible={isCancelButtonVisible}
        isOpen
      >
        {translatedDictionaryValue ? parsedTranslatedDictionaryValue : dictionaryKey}
      </Dialog>
    ));
  };

  return [open];
}
