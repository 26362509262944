import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProceedingKreptdCreateBaseOnViolationsRequest, ProceedingKreptdDetails } from '@ibtm/domain';
import * as Yup from 'yup';

import { partialTranslate } from '@libs/common';
import { AutocompleteSelectField, Dialog, FormV2Context, typedNameV2, useRouter } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { RoadTransportQueryKeysEnum, useProceedingStartMutations } from '../../../api';
import { RwaNumberValues } from '../../../models';

const getLabel = partialTranslate('roadTransport:fields');

interface IProps {
  selectedIds: string[];
  closeDialog: () => void;
}

function ViolationStartForm({ selectedIds, closeDialog }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();

  const { goToPage, routes } = useRouter();
  const { mutate: startProceeding, isLoading } = useProceedingStartMutations();

  const handleStartProceeding = (formValues: Partial<ProceedingKreptdDetails>) => {
    const requestData: ProceedingKreptdCreateBaseOnViolationsRequest = {
      violationIds: selectedIds,
      rwaNumber: formValues.rwaNumber
    };

    startProceeding(requestData, {
      onSuccess: response => {
        showSnackbar('success', t('roadTransport:messages.startProceedingSuccess'));
        if (response.data?.id) {
          goToPage(routes.roadTransportEdit(response.data?.id));
        } else {
          queryCache.invalidateQueries(RoadTransportQueryKeysEnum.VIOLATIONS_LIST);
        }
      },
      onSettled: () => {
        closeDialog();
      }
    });
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    reset
  } = useForm<Partial<ProceedingKreptdDetails>>({
    mode: 'all',
    resolver: yupResolver(
      Yup.object({
        rwaNumber: Yup.string().nullable().required()
      })
    ),
    defaultValues: {
      rwaNumber: null
    }
  });

  const formValues = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      reset
    }),
    [reset, control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  const options = useMemo(() => {
    return [
      {
        value: RwaNumberValues.RWA_570,
        name: getLabel('rwaNumberName570')
      },
      {
        value: RwaNumberValues.RWA_573,
        name: getLabel('rwaNumberName573')
      }
    ];
  }, []);

  return (
    <FormV2Context.Provider value={formValues}>
      <Dialog
        title={t('roadTransport:actions.startProceeding')}
        confirmText={t('action.save')}
        cancelText={t('action.cancel')}
        onConfirm={() => handleSubmit(handleStartProceeding)()}
        onCancel={closeDialog}
        isConfirmLoading={isLoading}
        isOpen
      >
        <AutocompleteSelectField
          name={typedNameV2<ProceedingKreptdDetails>('rwaNumber')}
          label={getLabel('rwa')}
          options={options}
          stringValue
          isRequired
          isClearable
        />
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default ViolationStartForm;
