import { DefaultApiCreateRequest } from '@avispon/message';
import { AxiosRequestConfig } from 'axios';

import { useMutation } from '@libs/common/v2/api';

import API from '../endpoints';
import { endpointsConfig } from '../endpoints-config';

const createMessage = async (request: DefaultApiCreateRequest) => {
  const { data } = await API.message.create(request, {
    ...endpointsConfig.create
  } as AxiosRequestConfig);
  return data;
};

export default function useCreateMessageMutation() {
  return useMutation(createMessage);
}
