import React from 'react';

const MessagesListPage = React.lazy(() => import('@libs/notification/pages/MessagesListPage'));

export const MessagesConfig = {
  routes: [
    {
      path: '/messages',
      element: <MessagesListPage />
    }
  ]
};
