import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { ForeignPermissionSearchFilter } from '@ibtm/domain';

import { CamelCasePath, TableLayout } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import {
  ForeignPermissionTypeEnum,
  ReleasedPermitsSnapshot,
  useForeignPermitsTable
} from '@libs/domain/foreign-permission';

import { useDomainConfigContext } from '../../../config';

export interface IForeignPermitsTable {
  title?: string;
  rowActions?: (row: Row<ReleasedPermitsSnapshot>) => ReactNode;
  initalParams?: ForeignPermissionSearchFilter;
  applicationId?: string;
  folderId?: string;
  typeKeyIn?: ForeignPermissionTypeEnum[];
  visibleColumns?: Array<CamelCasePath<ReleasedPermitsSnapshot>>;
  headerActions?: ReactNode;
  isSection?: boolean;
  isTableMutable?: boolean;
  includePartnershipPermissions?: boolean;
  isTableDataVisible?: boolean;
  isHeaderActions?: boolean;
}

function ForeignPermitsTable({
  initalParams,
  title,
  applicationId,
  folderId,
  typeKeyIn,
  visibleColumns = [
    'applicationNumber',
    'dateOfIssue',
    'validDateRangeTo',
    'issuingCountryCodeKey',
    'subjectName',
    'status'
  ],
  isSection = true,
  headerActions,
  rowActions,
  includePartnershipPermissions,
  isTableDataVisible = true,
  isHeaderActions = true
}: IForeignPermitsTable) {
  const [t] = useTranslation();
  const params: ForeignPermissionSearchFilter = {
    ...(applicationId && { applicationIdIn: [applicationId] }),
    ...(typeKeyIn && { typeKeyIn }),
    ...(folderId && { folderIdIn: [folderId] }),
    ...(includePartnershipPermissions && { includePartnershipPermissions: true })
  };
  const { isClientPortal } = useDomainConfigContext();
  const tableProps = useForeignPermitsTable({
    initalParams: initalParams || params,
    visibleColumns,
    isTableDataVisible
  });

  return (
    <TableLayout
      {...tableProps}
      pageTitle={title || t('foreignPermits:list.title')}
      isFilterEnabled={false}
      xlsxDownload={{
        apiRequest: params =>
          isClientPortal
            ? API.client.foreignPermissions.getForeignPermissionSnapshotPage('', '', params)
            : API.foreignPermissions.getForeignPermissionSnapshotPage(params),
        fileName: t('foreignPermits:list.title'),
        pathToXLSXTranslation: 'foreignPermits:tabs.disposablePermits.fields'
      }}
      rowActions={row => rowActions?.(row)}
      headerActions={isHeaderActions && headerActions}
      isActionColumnEnabled={Boolean(rowActions)}
      isSection={isSection}
      isTableMutable
    />
  );
}

export default ForeignPermitsTable;
