import React, { ElementType, useEffect } from 'react';
import { Tooltip, TooltipProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _ from 'lodash';

import {
  Animate,
  Breadcrumbs,
  Chip,
  Divider,
  IconType,
  ThemeVariant,
  Typography,
  TypographyIcon,
  useDocumentTitle
} from '@libs/common/v2';
import { PaletteOptions, Theme } from '@libs/common/v2/theme';

import { useIsSmallScreen } from '../../hooks/common/useIsSmallScreen';

export interface IPropsItem {
  to?: string;
  text: string;
  loading?: boolean;
}

export type IPropsDetail = {
  themeVariant?: ThemeVariant;
  customStyles?: React.CSSProperties & { [_: string]: unknown };
  component?: ElementType<any>;
  typographyClassName?: string;
  icon: IconType;
  iconClassName?: string;
  typographyIconClassName?: string;
  title: string;
  isWhiteText?: boolean;
  tooltipPlacement?: TooltipProps['placement'];
  tooltipTitle?: string;
};
interface IProps {
  title: string;
  breadcrumbs?: IPropsItem[];
  details?: IPropsDetail[];
  rightSideContent?: React.ReactNode;
  rightSideContentClassName?: string;
  children?: React.ReactNode;
  wrapperClassName?: string;
  titleCustomStyles?: React.CSSProperties & { [_: string]: unknown };
  statusData?: { color?: keyof PaletteOptions; title?: string };
  isDividerShown?: boolean;
  isSticky?: boolean;
  hasNoHeaderPadding?: boolean;
  isTitleColumnReverse?: boolean;
  headerRef?: React.MutableRefObject<HTMLDivElement>;
  typographyClassName?: string;
}

function PageHeader({
  title,
  breadcrumbs,
  details,
  statusData,
  rightSideContent,
  rightSideContentClassName,
  isDividerShown,
  titleCustomStyles,
  wrapperClassName,
  children,
  isSticky = true,
  hasNoHeaderPadding = false,
  isTitleColumnReverse = false,
  headerRef,
  typographyClassName
}: IProps) {
  const { setTitle } = useDocumentTitle();
  const classes = useStyles({ isSticky, hasNoHeaderPadding });
  const { isSmallScreen } = useIsSmallScreen();

  useEffect(() => {
    setTitle(title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  return (
    <div className={clsx(classes.headerWrapper, wrapperClassName)} ref={headerRef}>
      <div className={classes.wrapper}>
        {breadcrumbs && !isSmallScreen && <Breadcrumbs items={breadcrumbs} />}

        <div
          className={clsx(
            'flex justify-between items-center',
            isTitleColumnReverse ? classes.titleWrapper : `md:flex-wrap`
          )}
        >
          <Typography
            typographyClassName={typographyClassName}
            themeVariant="displaySm.medium"
            customStyles={{ marginTop: 8, ...titleCustomStyles }}
          >
            {title}
          </Typography>
          <div
            className={clsx('flex flex-row items-center flex-wrap', classes.rightContent, rightSideContentClassName)}
          >
            {rightSideContent}
          </div>
        </div>
        {(statusData || details) && (
          <Animate animation="transition.slideLeftIn" delay={30}>
            <div className={clsx('flex flex-wrap', classes.details)}>
              {statusData && <Chip {...statusData} className={classes.detail} themeVariant="textSm.medium" />}
              {details &&
                details.map(({ title: detailTitle, tooltipPlacement, tooltipTitle, ...restDetailProps }, index) => {
                  const keyIndex = `${tooltipTitle}_${index}`;
                  return (
                    <Tooltip
                      key={keyIndex}
                      disableFocusListener={!tooltipTitle}
                      disableHoverListener={!tooltipTitle}
                      placement={tooltipPlacement || 'bottom'}
                      title={tooltipTitle || ''}
                    >
                      <span className={classes.detailItem}>
                        <TypographyIcon
                          typographyIconClassName={classes.detail}
                          {..._.omit(restDetailProps, 'whiteText')}
                        >
                          {detailTitle}
                        </TypographyIcon>
                      </span>
                    </Tooltip>
                  );
                })}
            </div>
          </Animate>
        )}
        {children}
      </div>
      {isDividerShown && <Divider margin="0 32px" />}
    </div>
  );
}

const useStyles = makeStyles<Theme, { isSticky: boolean; hasNoHeaderPadding: boolean }>(theme => ({
  headerWrapper: {
    position: ({ isSticky }) => (isSticky ? 'sticky' : 'unset'),
    top: 0,
    backgroundColor: theme.palette.grey[50]
  },
  titleWrapper: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse'
    }
  },
  rightContent: {
    gap: ({ hasNoHeaderPadding }) => (hasNoHeaderPadding ? '0px' : '12px'),
    [theme.breakpoints.down('md')]: {
      gap: ({ hasNoHeaderPadding }) => (hasNoHeaderPadding ? '0px' : '6px')
    }
  },
  wrapper: {
    padding: ({ hasNoHeaderPadding }) => (hasNoHeaderPadding ? '12px 0px' : '24px 32px'),
    [theme.breakpoints.down('md')]: {
      padding: ({ hasNoHeaderPadding }) => (hasNoHeaderPadding ? '12px 0px' : '24px')
    }
  },
  detailsWrapper: {
    width: '100%',
    marginTop: 14
  },
  details: {
    alignItems: 'center'
  },
  detail: {
    marginRight: 20,
    marginTop: 12
  },
  detailItem: {
    display: 'inline-block'
  }
}));

export default PageHeader;
