import { AddFinancialSecuritiesToApplicationRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function copyFinancialSecurityClient(request: AddFinancialSecuritiesToApplicationRequest) {
  return API.client.financialSecurity.addFinancialSecuritiesToApplication('', '', request, {
    ...endpointsConfig.addFinancialSecuritiesToApplication
  });
}

function copyFinancialSecurity(request: AddFinancialSecuritiesToApplicationRequest) {
  return API.financialSecurity.addFinancialSecuritiesToApplication(request, {
    ...endpointsConfig.addFinancialSecuritiesToApplication
  });
}

function useFinancialSecurityCopyMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(copyFinancialSecurityClient, copyFinancialSecurity));
}

export default useFinancialSecurityCopyMutation;
