import { QueryConfig, useQuery } from 'react-query';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';
import { FolderQueryKeysEnum } from '..';

export const getFolderActiveVehiclesCount = async (folderId: string) => {
  const { data } = await API.folder.getActiveVehiclesCount(folderId, {
    ...endpointsConfig.getActiveVehiclesCount
  });

  return data;
};

export const getFolderActiveVehiclesCountClient = async (folderId: string) => {
  const { data } = await API.client.folder.getActiveVehiclesCount(folderId, {
    ...endpointsConfig.getActiveVehiclesCount
  });

  return data;
};

export default function useFolderActiveVehiclesCountQuery(
  folderId: string,
  queryConfig: QueryConfig<Record<string, number>, unknown> = {}
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery<Record<string, number>>(
    [FolderQueryKeysEnum.ACTIVE_VEHICLES_COUNT, folderId],
    getQuery(
      () => getFolderActiveVehiclesCountClient(folderId),
      () => getFolderActiveVehiclesCount(folderId)
    ),
    queryConfig
  );
}
