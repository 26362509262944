import { usePaginatedQuery } from 'react-query';
import { CivicBoardSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { CivicBoardSnapshot, SocialCommissionQueryKeysEnum } from '@libs/domain/social-commission';

import { endpointsConfig } from '../endpoints-config';

export const getCivicBoardDetails = async params => {
  const { data } = await API.civicBoard.getCivicBoardsDetailsPage(params, {
    ...endpointsConfig.getCivicBoardsDetailsPage
  });
  return { ...data, content: data.content };
};

function useCivicBoardListQuery(params: CivicBoardSearchFilter, queryConfig = {}) {
  return usePaginatedQuery<IPaginatedModel<CivicBoardSnapshot>>(
    [SocialCommissionQueryKeysEnum.CIVIC_BOARD_DETAILS, params],
    () => getCivicBoardDetails(params),
    queryConfig
  );
}
export default useCivicBoardListQuery;
