import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';

import { Dialog, FormV2Context, GridItem, GridLayout, Section, useIsSmallScreen } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { getCalendarDate } from '@libs/common/v2/utils';

import { getMetaFormQueryKey } from '@libs/meta-form/services/useGetQuery';

import { ApplicationQueryKeysEnum } from '../../application';
import { FinancialSecurityQueryKeysEnum, useFinancialSecurityCreateMutation } from '../api';
import { useFinancialSecurityFields, useFinancialSecurityValidationSchema } from '../hooks';
import { FinancialSecurityFields } from '../model';

interface IProps {
  folderId?: string;
  applicationId: string;
  onCloseDialog: () => void;
  isOpen: boolean;
  folderTypeKey: string;
  subjectId?: string;
  customOnSuccess?: () => void;
  exchangeRateFrom: string;
  exchangeRateTo: string;
}

function FinancialSecurityCreateDialog({
  isOpen,
  onCloseDialog,
  folderId,
  applicationId,
  folderTypeKey,
  subjectId,
  customOnSuccess,
  exchangeRateFrom,
  exchangeRateTo
}: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { isSmallScreen } = useIsSmallScreen();
  const { validationSchema } = useFinancialSecurityValidationSchema();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { mutate: createFinancialSecurity, isLoading } = useFinancialSecurityCreateMutation();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    reset
  } = useForm<FinancialSecurityFields>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema)
  });

  const releaseDate = watch('releaseDate');

  const formValues = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting
    }),
    [control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting]
  );

  const { renderField } = useFinancialSecurityFields({
    watch,
    setValue,
    reset,
    trigger,
    folderTypeKey,
    exchangeRateFrom: exchangeRateFrom || releaseDate,
    exchangeRateTo: exchangeRateTo || releaseDate
  });

  const handleCreateSubmit = (values: FinancialSecurityFields) => {
    createFinancialSecurity(
      {
        typeKey: values?.typeKey?.value,
        releaseDate: getCalendarDate(values?.releaseDate),
        expirationDate: getCalendarDate(values?.expirationDate),
        amount: values?.amount,
        exchangeAmount: values?.exchangeAmount,
        currencyKey: values?.currencyKey?.value,
        exchangeId: values?.currencyExchangeRateId,
        notes: values?.notes,
        folderId: applicationId ? undefined : folderId,
        applicationId,
        subjectId
      },
      {
        onSuccess: () => {
          queryCache.invalidateQueries(FinancialSecurityQueryKeysEnum.FINANCIAL_SECURITY_LIST);
          queryCache.invalidateQueries(FinancialSecurityQueryKeysEnum.FINANCIAL_SECURITY_SUMMARY);
          queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
          // queryKey generowany przez metaform
          queryCache.invalidateQueries(getMetaFormQueryKey('GET_FINANCIAL_SECURITY_SUMMARY'));
          customOnSuccess?.();

          showSuccessSnackbar(t('financialSecurity:action.create.successMessage'));
          onCloseDialog();
        },
        onError: () => {
          showErrorSnackbar(t('financialSecurity:action.create.failureMessage'));
        }
      }
    );
  };

  return (
    <FormV2Context.Provider value={formValues}>
      <form onSubmit={handleSubmit(handleCreateSubmit)}>
        <Dialog
          title={t('financialSecurity:action.create.dialogTitle')}
          confirmText={t('action.save')}
          cancelText={t('action.cancel')}
          onConfirm={handleSubmit(handleCreateSubmit, () => {
            showErrorSnackbar(t('error.formValidationErrors'));
          })}
          onCancel={onCloseDialog}
          dialogSize="medium"
          isConfirmLoading={isLoading}
          isOpen={isOpen}
          isFullScreen={isSmallScreen}
        >
          <Section isCollapsable isModalSection isHeaderVisible={false}>
            <GridLayout itemProps={{ xs: 12, md: 6 }}>
              <GridItem xs={12}>{renderField('typeKey')}</GridItem>
              {renderField('releaseDate')}
              {renderField('expirationDate')}
              {renderField('currencyKey')}
              {renderField('exchangeCurrency', { inputMode: 'VIEW' })}
              {renderField('amount')}
              {renderField('exchangeAmount', { inputMode: 'VIEW' })}
              <GridItem xs={12}>{renderField('notes')}</GridItem>
            </GridLayout>
          </Section>
          <Section title={t('financialSecurity:section.exchangeRates')} isCollapsable isModalSection>
            <GridLayout itemProps={{ xs: 12, md: 6 }}>
              {renderField('exchangeRate', { inputMode: 'VIEW' })}
              {renderField('exchangeRateFromDate', { inputMode: 'VIEW' })}
            </GridLayout>
          </Section>
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
}

export default FinancialSecurityCreateDialog;
