import { useMemo } from 'react';

import { partialTranslate } from '@libs/common';
import {
  DictionarySelectField,
  GridItem,
  GridLayout,
  TextInputField,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';

import { parsePostCode } from '../../address';
import { DomainDictionaryEnum } from '../../config';
import { CountryEnum } from '../../exploitation-base';
import { PartnerApplicantFields } from '../model';

const getLabel = partialTranslate('partnerApplicants:field');

interface IProps {
  customSameAsMainAddressPath?: string;
}

const AddressFieldsFormSection = ({ customSameAsMainAddressPath }: IProps) => {
  const { watch } = useFormV2Context();

  const countryValue = watch(
    typedNameV2<PartnerApplicantFields>('address.countryCodeKey')
  ) as PartnerApplicantFields['address']['countryCodeKey'];
  const sameAsMainAddress = watch(
    customSameAsMainAddressPath || typedNameV2<PartnerApplicantFields>('sameAsMainAddress')
  ) as boolean;

  const isPolandAddress = useMemo(() => countryValue?.value === CountryEnum.POLAND, [countryValue]);

  if (countryValue?.value) {
    if (isPolandAddress) {
      return (
        <GridLayout itemProps={{ xs: 6 }}>
          <DictionarySelectField
            key="voivodeshipKey"
            name={typedNameV2<PartnerApplicantFields>('address.voivodeshipKey')}
            dictionaryName={DomainDictionaryEnum.VOIVODESHIP}
            label={getLabel('voivodeshipKey')}
            isDisabled={sameAsMainAddress}
            isRequired={!sameAsMainAddress && isPolandAddress}
            tooltip={sameAsMainAddress && getLabel('tooltipSameAsMainAddress')}
          />
          <TextInputField
            key="county"
            name={typedNameV2<PartnerApplicantFields>('address.county')}
            label={getLabel('county')}
            isDisabled={sameAsMainAddress}
            isRequired={!sameAsMainAddress && isPolandAddress}
            tooltip={sameAsMainAddress && getLabel('tooltipSameAsMainAddress')}
          />
          <TextInputField
            key="commune"
            name={typedNameV2<PartnerApplicantFields>('address.commune')}
            label={getLabel('commune')}
            isDisabled={sameAsMainAddress}
            isRequired={!sameAsMainAddress}
            tooltip={sameAsMainAddress && getLabel('tooltipSameAsMainAddress')}
          />
          <TextInputField
            key="city"
            inputProps={{ maxLength: 20 }}
            name={typedNameV2<PartnerApplicantFields>('address.city')}
            label={getLabel('city')}
            isDisabled={sameAsMainAddress}
            isRequired={!sameAsMainAddress && isPolandAddress}
            tooltip={sameAsMainAddress && getLabel('tooltipSameAsMainAddress')}
          />
          <TextInputField
            key="street"
            name={typedNameV2<PartnerApplicantFields>('address.street')}
            label={getLabel('street')}
            isDisabled={sameAsMainAddress}
            tooltip={sameAsMainAddress && getLabel('tooltipSameAsMainAddress')}
          />
          <GridItem xs={3}>
            <TextInputField
              key="propertyNumber"
              name={typedNameV2<PartnerApplicantFields>('address.propertyNumber')}
              label={getLabel('propertyNumber')}
              isDisabled={sameAsMainAddress}
              isRequired={!sameAsMainAddress && isPolandAddress}
              tooltip={sameAsMainAddress && getLabel('tooltipSameAsMainAddress')}
            />
          </GridItem>
          <GridItem xs={3}>
            <TextInputField
              key="apartmentNumber"
              name={typedNameV2<PartnerApplicantFields>('address.apartmentNumber')}
              label={getLabel('apartmentNumber')}
              isDisabled={sameAsMainAddress}
              tooltip={sameAsMainAddress && getLabel('tooltipSameAsMainAddress')}
            />
          </GridItem>
          <TextInputField
            key="postCode"
            name={typedNameV2<PartnerApplicantFields>('address.postCode')}
            label={getLabel('postCode')}
            getValueFormat={postCode => {
              return parsePostCode(postCode);
            }}
            inputMask={{ mask: '99-999', alwaysShowMask: true }}
            isDisabled={sameAsMainAddress}
            isRequired={!sameAsMainAddress && isPolandAddress}
            tooltip={sameAsMainAddress && getLabel('tooltipSameAsMainAddress')}
          />
          <TextInputField
            key="postCity"
            name={typedNameV2<PartnerApplicantFields>('address.postCity')}
            label={getLabel('postCity')}
            isDisabled={sameAsMainAddress}
            isRequired={!sameAsMainAddress && isPolandAddress}
            tooltip={sameAsMainAddress && getLabel('tooltipSameAsMainAddress')}
          />
        </GridLayout>
      );
    }

    return (
      <GridLayout itemProps={{ xs: 12, sm: 6 }}>
        <TextInputField
          key="firstLine"
          name={typedNameV2<PartnerApplicantFields>('address.firstLine')}
          label={getLabel('firstLine')}
          isDisabled={sameAsMainAddress}
          tooltip={sameAsMainAddress && getLabel('tooltipSameAsMainAddress')}
        />
        <TextInputField
          key="secondLine"
          name={typedNameV2<PartnerApplicantFields>('address.secondLine')}
          label={getLabel('secondLine')}
          isDisabled={sameAsMainAddress}
          tooltip={sameAsMainAddress && getLabel('tooltipSameAsMainAddress')}
        />
        <TextInputField
          key="postCode"
          name={typedNameV2<PartnerApplicantFields>('address.postCode')}
          label={getLabel('pnu')}
          isDisabled={sameAsMainAddress}
          tooltip={sameAsMainAddress && getLabel('tooltipSameAsMainAddress')}
        />
        <TextInputField
          key="city"
          name={typedNameV2<PartnerApplicantFields>('address.city')}
          label={getLabel('city')}
          inputProps={{ maxLength: 20 }}
          isDisabled={sameAsMainAddress}
          tooltip={sameAsMainAddress && getLabel('tooltipSameAsMainAddress')}
        />
      </GridLayout>
    );
  }

  return null;
};

export default AddressFieldsFormSection;
