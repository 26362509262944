export enum ApplicationProcessMainActionEnum {
  /** Przekaż do wydania */
  MAIN_SENT_FOR_ISSUE = 'MAIN_SENT_FOR_ISSUE',
  /** Przekaż do weryfikacji */
  MAIN_SENT_TO_VERIFICATION = 'MAIN_SENT_TO_VERIFICATION',
  /** Zapisz w teczce */
  MAIN_SAVE_DATA_TO_FOLDER = 'MAIN_SAVE_DATA_TO_FOLDER',
  /** Uzupełnij braki */
  MAIN_SUPPLEMENTING_FORMAL_DEFICIENCIES = 'MAIN_SUPPLEMENTING_FORMAL_DEFICIENCIES',
  /** Przywróć status niekompletny */
  MAIN_SET_INCOMPLETE_APPLICATION_STATUS = 'MAIN_SET_INCOMPLETE_APPLICATION_STATUS',
  /** Przygotuj projekt postanowienia / decyzji */
  MAIN_PREPARE_DECISION_DRAFT = 'MAIN_PREPARE_DECISION_DRAFT',
  /** Przekaż do wydruku */
  MAIN_SEND_TO_PRINT = 'MAIN_SEND_TO_PRINT',
  /** Przekaż do akceptacji */
  MAIN_SET_APPLICATION_STATUS_AS_ACCEPTED = 'MAIN_SET_APPLICATION_STATUS_AS_ACCEPTED',
  /** Potwierdź wygenerowanie pisma o pozostawieniu wniosku bez rozpatrzenia */
  MAIN_CONFIRM_GENERATION_OF_LEAVING_APPLICATION_WITHOUT_CONSIDERATION_DOCUMENT = 'MAIN_CONFIRM_GENERATION_OF_LEAVING_APPLICATION_WITHOUT_CONSIDERATION_DOCUMENT',
  /** Potwierdzenie przygotowania wydania */
  MAIN_CONFIRM_PREPARATION_OF_ISSUE = 'MAIN_CONFIRM_PREPARATION_OF_ISSUE',
  /** Oznacz jako kompletny */
  MAIN_SET_COMPLETE_APPLICATION_STATUS = 'MAIN_SET_COMPLETE_APPLICATION_STATUS',
  /** Ponów weryfikacje formalno-merytoryczna */
  SET_PENDING_VERIFICATION_APPLICATION_STATUS = 'SET_PENDING_VERIFICATION_APPLICATION_STATUS',
  /** Wyślij do ponownego przedruku */
  SEND_FOR_REPRINT = 'SEND_FOR_REPRINT',
  /** Odmów wydania uprawnień */
  REFUSE_TO_ISSUE_PERMISSION = 'REFUSE_TO_ISSUE_PERMISSION'
}

const mainActionKeys: { [key in ApplicationProcessMainActionEnum]: Array<string> } = {
  /** Oznacz jako kompletny */
  [ApplicationProcessMainActionEnum.MAIN_SET_COMPLETE_APPLICATION_STATUS]: [
    'sid-0D39D0AE-1768-4933-8FC7-02D9E7D37516',
    'verifyApplicationUserTask',
    'sid-4081578D-0EE5-4D9D-BC73-D1F76F14D06A',
    'sid-37F4578D-0EE5-4D9D-BC73-D1F76F14D06A',
    'sid-DDD0D2AE-AAA3-404C-99F1-B422B777983B',
    'verifyApplicationCompletenessUserTask-2',
    'sid-C90FD486-987A-44D8-B3CA-4F770EA31460',
    'sid-3981578D-0EE5-4D9D-BC73-D1F76F14D06A',
    'sid-7855A53C-F116-46B1-B7AA-BB8488D018BC',
    'sid-D95DF0FC-2165-4926-8B2F-BAE6A94CBEAD',
    'sid-37F4578D-0EE5-4D9D-BC73-D1F76F14D067',
    'sid-37F4578D-0EE5-4D9D-BC73-D1F76F589POLK',
    'sid-2CD03F51-7442-4DAE-B2A3-B3D5FBD31042'
  ],
  /** Potwierdzenie przygotowania wydania */
  [ApplicationProcessMainActionEnum.MAIN_CONFIRM_PREPARATION_OF_ISSUE]: ['sid-839D1BED-9EE3-4204-A69A-D391803BE196'],
  /** Potwierdź wygenerowanie pisma o pozostawieniu wniosku bez rozpatrzenia */
  [ApplicationProcessMainActionEnum.MAIN_CONFIRM_GENERATION_OF_LEAVING_APPLICATION_WITHOUT_CONSIDERATION_DOCUMENT]: [
    'sid-a9e1b4fa-02de-4a04-8362-5f5e2e6781e2',
    'sid-3525c1d7-7bc9-4854-ba8d-58a59b8d07be',
    'sid-3ba0b92e-3bde-48ff-9e62-5a4cca0d42f3',
    'sid-dab2ed04-22d7-4c29-ab61-00e9c70c1856',
    'sid-6038f159-edf3-45c1-ab2b-c36dd9c93869',
    'sid-0724c0a8-8c5f-46f6-a012-8beb2445bb02',
    'sid-0427be7f-dfd2-4154-bfad-33b57f0c0d2f',
    'sid-13872c21-fbb0-44a9-bb48-89d43c1e5f5a',
    'sid-c0883ae7-580c-4df9-a976-4428681b3d73',
    'sid-01d50a1a-7b12-49d6-b9a9-5db8eec5f117',
    'sid-23ee89b8-e22f-4ed2-bfe0-427c0aa39a6c',
    'sid-1da46874-b62c-4554-81e2-14558b495916',
    'sid-cd8beaee-bc16-47a5-b0b6-d56248dc3584',
    'sid-32c4345f-9cf7-4176-9a6d-cffe53ba5e6b',
    'sid-17314f58-4065-454f-a8fa-9c180521c36e',
    'sid-ab8722a3-41e0-4dcb-aa24-6ad5779f768b',
    'sid-f5e6298e-d7df-4156-8ed3-0534309a6c5f',
    'sid-b2a5d6c3-ac99-458d-9265-864a314b3129',
    'sid-991558d2-83d3-4ff9-9c08-a56cf843e2f6',
    'sid-f0b33dca-1773-49e5-8da6-c8a354be6f84',
    'sid-66ed8004-d0a5-41e0-9669-398d16255b72',
    'sid-a3b09d85-64ea-4439-8891-5d4dd8196dbd',
    'sid-676fe0e9-5cad-4921-a817-588595e93cda',
    'sid-09c5570e-0dd3-45be-8465-bd68c3556713',
    'sid-7b144647-47de-428f-9936-d1b578932ff3',
    'sid-131faa34-5b53-4fd8-9469-ed91f01d4db3',
    'sid-210b24a1-54b2-4787-b8db-42e40bd5a717',
    'sid-14a3a89c-06b2-4b48-812e-1cc2ba3c7365',
    'sid-2317c3e5-e190-4bde-aa2c-8dbef8363ea3',
    'sid-53744bd4-05db-41d9-91ab-33c48e0364a9',
    'sid-a5868f9a-964c-4711-a504-60ce79a9b6e6',
    'sid-2614429f-6807-4ec6-8ac1-6c14a84d2bc3',
    'sid-42852d1f-548e-4e43-add6-1472a6c88801',
    'sid-4256e276-e1e5-4c84-8584-d6bf658d79bb'
  ],
  /** Przekaż do akceptacji */
  [ApplicationProcessMainActionEnum.MAIN_SET_APPLICATION_STATUS_AS_ACCEPTED]: [
    'sid-9D64E2F5-7E0E-4F26-A328-62B3857E09F5',
    'sid-75F54011-F86F-4749-8D25-FFAB53B06CD7',
    'sid-0C740CA5-3C67-4A13-8805-E1F7092FD706'
  ],
  /** Przekaż do druku */
  [ApplicationProcessMainActionEnum.MAIN_SEND_TO_PRINT]: [
    'sid-429199C2-2F3B-4697-B2F2-3CDAE7E720F6',
    'sid-8A6A7EE2-3560-41F6-BCF4-AF06A63469A0',
    'sid-CBAE0D6F-C056-4E51-9F09-3F69FCCAB164',
    'sid-60E5429B-2A8F-4E5B-94F7-4EECB70E0708',
    'sid-C040C43E-BB13-4E28-B985-DEE3E8B0987E',
    'sid-7CEFE5E3-EE76-4E56-828C-0E21D3B294AE',
    'sid-410919C2-2F3B-4697-B2F2-3CDAE7E720F6',
    'sid-438199C2-2F3B-4697-B2F2-3CDAE7E720F6',
    'sid-293D99C2-2F3B-5712-A1G9-3CDAE7E720F6',
    'sid-477199C2-2F3B-4697-B2F2-3CDAE7E720F6',
    'sid-8FEAFAC9-460B-40DA-BB0A-C7F1DFD7C71B',
    'sid-293D99C2-2F3B-4697-B2F2-3CDAE7E72020',
    'sid-293D99C2-2F3B-4697-B2F2-4BEBD7E720F6',
    'acceptDecisionUserTask'
  ],
  /** Przekaż do weryfikacji */
  [ApplicationProcessMainActionEnum.MAIN_SENT_TO_VERIFICATION]: ['verifyApplicationCompletenessUserTask'],
  /** Przekaż do wydania */
  [ApplicationProcessMainActionEnum.MAIN_SENT_FOR_ISSUE]: [
    'sid-6294726c-ae30-4882-9dd4-5fc13784a273',
    'sid-98fc28d1-e524-40c9-8d06-d4d24a695610',
    'sid-C13644D8-9BFA-4F35-B784-397F48171C7B',
    'sid-348244D8-9BFA-4F35-B784-397F48171C7B',
    'sid-56A4A07D-3FA6-45D7-A847-1A6552F8CFB0',
    'sid-BAF4387E-9650-4BD5-AC60-903937760787',
    'sid-28977BEC-262F-433A-B548-8EA3B0F2AAFF',
    'sid-3CB3816F-7D4E-41B2-BD68-9129F52EC955',
    'acceptDecisionUserTask-2',
    'sid-6c7fd0de-ef6f-46ba-9b03-76b7da47d596'
  ],
  /** Przygotuj projekt postanowienia / decyzji */
  [ApplicationProcessMainActionEnum.MAIN_PREPARE_DECISION_DRAFT]: [
    'sid-1256AWED-4B89-4412-B4F7-9OIUD663CA5E8',
    'sid-293D99C2-2F3B-4697-B2F2-4RETE7E720F6',
    'sid-9856IUJ5-4B89-4412-B4F7-9OIUD663CA5E8',
    'sid-85ASDF47-4B89-4412-B4F7-9OIUD663CA5E8',
    'sid-293D99C2-2F3B-4697-B2F2-3CDAE7E720F6',
    'sid-584ASDR4-4B89-4412-B4F7-9OIUD663CA5E8',
    'sid-9F3FEE4D-4B89-4412-B4F7-9OIUD663CA5E8',
    'sid-7965RTYG-4B89-4412-B4F7-9OIUD663CA5E8',
    'sid-9F3FEE4D-4B89-4412-B4F7-F9F9663CA888'
  ],
  /** Przywróć status niekompletny */
  [ApplicationProcessMainActionEnum.MAIN_SET_INCOMPLETE_APPLICATION_STATUS]: [
    'sid-4C29D6D1-7986-4D2E-8245-38ED1C54EDCC',
    'sid-5D634C9A-D340-451F-95F0-2BBBFB96F3F0'
  ],
  /** Uzupełnij braki */
  [ApplicationProcessMainActionEnum.MAIN_SUPPLEMENTING_FORMAL_DEFICIENCIES]: [
    'orderLackOfFormalRequirementsDocumentSendingUserTask',
    'supplementingFormalDeficienciesUserTask',
    'sid-B28C2494-C05E-4503-BE0F-702476241676'
  ],
  /** Zapisz w teczce */
  [ApplicationProcessMainActionEnum.MAIN_SAVE_DATA_TO_FOLDER]: [
    'sid-9F3FEE4D-4B89-4412-B4F7-F9F9663CA5E8',
    'ibtm-process.tast.main-action.saveInFolder',
    'sid-293D99C2-2F3B-4697-B2F2-3CDAE7E831G7',
    'sid-381A55C2-2F3B-4697-B2F2-3CDAE7E720G3'
  ],
  /** Ponów weryfikacje formalno-merytoryczna */
  [ApplicationProcessMainActionEnum.SET_PENDING_VERIFICATION_APPLICATION_STATUS]: [
    'sid-293D99C2-2F3B-5712-A1G9-3CDAE7E720F6'
  ],
  /** Wyślij do ponownego przedruku */
  [ApplicationProcessMainActionEnum.SEND_FOR_REPRINT]: ['sid-D201377A-2E63-4231-95FD-D5B38380E610'],
  /** Odmów wydania uprawnień */
  [ApplicationProcessMainActionEnum.REFUSE_TO_ISSUE_PERMISSION]: ['sid-3AC76B50-52C3-4D4E-9E46-21F49BAF6062']
};
export const getApplicationProcessMainActionNameByKey = (dictionaryKey: string) => {
  const [actionName] = Object.entries(mainActionKeys).find(([, value]) => value?.includes(dictionaryKey)) || [];
  return actionName;
};
