import { FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { GridLayout, SwitchField, TextAreaField } from '@libs/common/v2';

interface IProps {
  name: FieldPath<FieldValues>;
  isReadonly: boolean;
  dataSetIndex: number;
}

function ConditionAdvanced({ name, isReadonly, dataSetIndex }: IProps) {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <GridLayout itemProps={{ md: 12 }}>
      <SwitchField
        name={`dataSetDefinitions[${dataSetIndex}].dbQueryPreparation`}
        label={t('reports:fields.dbQueryPreparation')}
      />
      <TextAreaField
        name={name}
        className={classes.sqlQueryInput}
        placeholder={t('alerts:sqlField.addSqlConditions')}
        tooltip={t('reports:tip.advancedViewQueryField')}
        isDisabled={isReadonly}
        minRows={10}
        isRequired
        isLabelHidden
      />
    </GridLayout>
  );
}

const useStyles = makeStyles({
  sqlQueryInput: {
    display: 'grid',
    flex: '1 1 0%',
    height: '100%',
    '& > :first-child': {
      height: '100%',
      alignItems: 'flex-start'
    }
  }
});

export default ConditionAdvanced;
