import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { HintType, ParameterExtras } from '@stack/report';

import { BooleanValue, Chip, useCreateColumns } from '@libs/common/v2';
import { getValue, SortUtil } from '@libs/common/v2/utils';

export function ReportTypeExtrasColumns(): Column<ParameterExtras>[] {
  const [t] = useTranslation();
  const parseHint = (row: ParameterExtras) => {
    if (row?.hint?.type) {
      switch (row.hint?.type) {
        case HintType.QUERY:
          return t('reports:reportTypes.parameterExtras.fields.query');
        case HintType.COLUMN:
          return t('reports:reportTypes.parameterExtras.fields.columnKeyAndDataSetName');
        case HintType.VALUES:
          return t('reports:reportTypes.parameterExtras.fields.hintedValues');
        case HintType.DICTIONARY:
          return t('reports:reportTypes.parameterExtras.fields.dictionaryName');
        default:
          return null;
      }
    }

    return null;
  };

  const { createColumns } = useCreateColumns<ParameterExtras>({
    pathToTranslate: 'reports:reportTypes.parameterExtras.fields'
  });

  return createColumns([
    {
      type: 'TEXT',
      accessor: 'index',
      sortType: (rowA, rowB) => SortUtil.numericFalsyLast(rowA, rowB, 'index'),
      width: 150
    },
    {
      type: 'TEXT',
      accessor: 'displayName',
      width: 200
    },
    {
      type: 'TEXT',
      accessor: 'queryName',
      width: 200
    },
    {
      type: 'BOOLEAN',
      accessor: 'required',
      tooltipTitle: row => (row?.required ? t('action.yes') : t('action.no')),
      width: 250
    },
    {
      id: 'type',
      type: 'CUSTOM_COLUMN',
      customAccessor: (row: any): any => row?.type?.type || row?.type?.name,
      width: 150
    },
    {
      id: 'hint',
      type: 'CUSTOM_COLUMN',
      customAccessor: row => {
        return row.hint ? getValue(parseHint(row)) : <BooleanValue value={!!row?.hint} isTableCell />;
      },
      width: 200
    },
    {
      type: 'BOOLEAN',
      accessor: 'multiValue',
      tooltipTitle: row => (row?.multiValue ? t('action.yes') : t('action.no')),
      width: 150
    },
    {
      type: 'BOOLEAN',
      accessor: 'selectOnly',
      tooltipTitle: row => (row?.selectOnly ? t('action.yes') : t('action.no')),
      width: 150
    },
    {
      type: 'BOOLEAN',
      tooltipTitle: row => (row?.checkbox ? t('action.yes') : t('action.no')),
      accessor: 'checkbox',
      width: 150
    },
    {
      type: 'TEXT',
      accessor: 'groupName',
      width: 200
    },
    {
      type: 'BOOLEAN',
      accessor: 'requiredParamNames',
      tooltipTitle: row => (row?.requiredParamNames ? t('action.yes') : t('action.no')),
      width: 200
    },
    {
      type: 'TEXT',
      accessor: 'enabledByParamName',
      width: 200
    },
    {
      type: 'MULTI_SELECT',
      id: 'disabledByParamNames',
      customAccessor: row => row.disabledByParamNames?.map(param => <Chip key={param} title={param} />),
      width: 200
    }
  ]);
}
