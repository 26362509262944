import { usePaginatedQuery } from 'react-query';
import { FolderSnapshot, FolderSnapshotsPage, SubjectLite } from '@ibtm/domain';
import { FolderSearchFilter } from '@ibtm/domain/dist/models';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

import UserEntrepreneursQueryKeysEnum from './UserEntrepreneursQueryKeysEnum';

const createAccountEntrepreneursParser = (
  data: FolderSnapshotsPage,
  selectedValues: SubjectLite[]
): FolderSnapshotsPage => {
  const selectedIds = selectedValues.map(({ id }) => id);

  return {
    ...data,
    content: data.content.filter(({ subject }) => !selectedIds.includes(subject.id))
  };
};

export const getEntrepreneurList = async (params: FolderSearchFilter & { selectedValues?: SubjectLite[] }) => {
  const { selectedValues } = params;
  const { data } = await API.folder.getFoldersSnapshotPage(params, {
    ...endpointsConfig.getFoldersSnapshotPage
  });

  if (selectedValues && selectedValues.length) {
    return createAccountEntrepreneursParser(data, selectedValues);
  }

  return data;
};

function useGetEntrepreneurListQuery(params) {
  return usePaginatedQuery<IPaginatedModel<FolderSnapshot>>(
    [UserEntrepreneursQueryKeysEnum.USER_ENTREPRENEURS_LIST, params],
    () => getEntrepreneurList(params)
  );
}

export default useGetEntrepreneurListQuery;
