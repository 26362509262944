import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { InvoiceItemDetails } from '@ibtm/domain';
import { isNumber } from 'lodash';

import { FormMode, IconButton, TableButtonEdit, TableIconButton, TableLayout } from '@libs/common/v2';

import useReturnResourceReleaseDocuments from '../../api/mutations/useReturnResourceReleaseDocuments';
import useHandleAddItemToReturnTable from '../../hooks/useHandleAddItemToReturnTable';
import useReturnTablesColumns from '../../hooks/useReturnTablesColumns';
import { ReturnItemRange } from '../../model/ReturnModels';
import { ContextHeaderButton } from '../../pages/ReleaseDocumentsReturnPage';
import { ChangeReturnModeContext } from '../../pages/ReleaseDocumentsWrapper';
import { convertItemToReturnRanges } from '../../utils/return.util';

interface OriginalItemWithRanges extends InvoiceItemDetails {
  ranges: ReturnItemRange[];
}
interface IProps {
  originalItems: InvoiceItemDetails[];
  handleCancel: (redirectToId?: string) => void;
  invoiceId: string;
}

function ReturnSection({ originalItems = [], handleCancel = () => null, invoiceId }: IProps) {
  const { setHandleReturn, setIsAnyItemAddedAndAllHaveReason, setIsMutationLoading } = useContext(ContextHeaderButton);
  const { mutate, isLoading } = useReturnResourceReleaseDocuments();
  const { showSuccessSnackbar } = useSnackbar();

  const [t] = useTranslation();

  const { itemsColumns, returnItemsColumns } = useReturnTablesColumns();
  const [items, setItems] = useState<OriginalItemWithRanges[]>([]);
  const [options, setOptions] = useState<ReturnItemRange[]>([]);
  const [returnTable, setReturnTable] = useState<ReturnItemRange[]>([]);
  const { isFromCreation, setItems: setReturnItems } = useContext(ChangeReturnModeContext);

  const addRangesToOriginalItems = useCallback(() => {
    if (originalItems.length === 0) {
      setItems([]);
    } else {
      setItems(
        originalItems
          .filter(element => element.forms.length > 0)
          .map(item => {
            return {
              ...item,
              ranges: convertItemToReturnRanges(item)
            };
          })
      );
    }
  }, [originalItems]);
  const setOptionsForDialog = useCallback(() => {
    const newOptions: ReturnItemRange[] = [];
    items.forEach(item => {
      item.ranges.forEach(range => {
        if (!returnTable.some(element => element.id === range.id)) {
          newOptions.push(range);
        }
      });
    });
    setOptions(newOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, returnTable]);

  useEffect(() => {
    addRangesToOriginalItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalItems]);

  useEffect(() => {
    setOptionsForDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnTable, items]);

  const isAllRangesFromItemInReturnTable = useCallback(
    (itemId: InvoiceItemDetails['id']) => {
      return items.find(item => item.id === itemId)?.ranges.every(range => returnTable.includes(range)) ?? false;
    },
    [items, returnTable]
  );

  const isAllItemsAlreadyAdded = useMemo(() => {
    return items.every(item => isAllRangesFromItemInReturnTable(item.id));
  }, [isAllRangesFromItemInReturnTable, items]);

  const handleReturn = useCallback(() => {
    if (!isFromCreation) {
      mutate(
        {
          invoiceId,
          returnRequests: returnTable.map(element => {
            return {
              invoiceItemId: element.positionId,
              numberFrom: element.from,
              numberTo: element.to,
              reasonKey: element.reasonKey
            };
          })
        },
        {
          onSuccess: res => {
            showSuccessSnackbar(t('releaseDocuments:return.message.success'));
            handleCancel(res.data.correctedInvoiceId);
          }
        }
      );
    } else {
      setReturnItems(returnTable);
      handleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCancel, mutate, returnTable, showSuccessSnackbar, t, isFromCreation]);

  useEffect(() => {
    setIsAnyItemAddedAndAllHaveReason(
      returnTable.length > 0 &&
        returnTable.every(item => item.reasonKey !== null && isNumber(item?.min) && isNumber(item?.max))
    );
  }, [returnTable, setIsAnyItemAddedAndAllHaveReason]);
  useEffect(() => {
    setHandleReturn(() => handleReturn);
  }, [handleReturn, returnTable, setHandleReturn]);
  useEffect(() => {
    setIsMutationLoading(isLoading);
  }, [isLoading, setIsMutationLoading]);

  const moveItemToReturnTable = (itemId: InvoiceItemDetails['id']) => {
    const item = items.find(element => element.id === itemId);
    if (!item) return;
    setReturnTable(prev => [
      ...prev,
      ...item.ranges.filter(range => prev.every(element => element.positionId !== range.positionId))
    ]);
  };

  const addAllItemsToReturnTable = useCallback(() => {
    const newReturnTable = returnTable;
    items.forEach(item => {
      item.ranges.forEach(range => {
        if (!newReturnTable.some(element => element.positionId === range.positionId)) {
          newReturnTable.push(range);
        }
      });
    });
    setReturnTable([...newReturnTable]);
  }, [returnTable, items]);

  const deleteItemFromReturnTable = (itemId: ReturnItemRange['id']) => {
    const newReturnTable = returnTable.filter(element => element.id !== itemId);
    setReturnTable(newReturnTable);
  };

  const handleAddOrEdit = (item: ReturnItemRange, isAdd: boolean) => {
    if (isAdd) {
      setReturnTable(prev => [...prev, item]);
    } else {
      setReturnTable(prev => {
        return [
          ...prev.map(element => {
            if (element.id === item.id) {
              return item;
            }
            return element;
          })
        ];
      });
    }
  };
  const { openAddItemDialog } = useHandleAddItemToReturnTable({
    handleSubmit: handleAddOrEdit,
    itemsAvailableToAdd: options
  });

  return (
    <div className="flex flex-col">
      <div
        className="flex flex-row"
        style={{
          gap: 24
        }}
      >
        <div style={{ height: '100%', minWidth: '35%' }}>
          <TableLayout
            tableQuery={items}
            pageTitle={t('releaseDocuments:return.itemsTable')}
            isSection
            key="itemsDocumentsTable"
            isFilterEnabled={false}
            isPaginated={false}
            isRefreshEnabled={false}
            tableHookOptions={{
              columns: itemsColumns,
              filterConverter: {},
              sortByConverter: {}
            }}
            headerActions={
              <IconButton
                icon="RecordsChevronsRight"
                isBackgroundTransparent
                onClick={addAllItemsToReturnTable}
                isDisabled={isAllItemsAlreadyAdded}
                tooltipTitle={
                  isAllItemsAlreadyAdded
                    ? t('releaseDocuments:return.tooltip.allItemsAlreadyAdded')
                    : t('releaseDocuments:return.label.addAll')
                }
              />
            }
            rowActions={row => {
              const isDisabled = isAllRangesFromItemInReturnTable(row.original['id']);
              return (
                <TableIconButton
                  icon="ChevronsRightIcon"
                  isDisabled={isDisabled}
                  tooltipTitle={
                    isDisabled ? t('releaseDocuments:return.tooltip.alreadyOnReturnTable') : t('action.add')
                  }
                  onClick={() => {
                    moveItemToReturnTable(row.original['id']);
                  }}
                />
              );
            }}
          />
        </div>
        <div style={{ width: '100%', height: '100%', maxWidth: '60%' }}>
          <TableLayout
            tableQuery={returnTable}
            pageTitle={t('releaseDocuments:return.itemsReturnTable')}
            isSection
            key="itemsReturnTable"
            isFilterEnabled={false}
            isPaginated={false}
            isRefreshEnabled={false}
            tableHookOptions={{
              columns: returnItemsColumns,
              filterConverter: {},
              sortByConverter: {}
            }}
            rowActions={row => (
              <>
                <TableIconButton
                  icon="TrashIcon"
                  tooltipTitle={t('releaseDocuments:return.tooltip.deleteFromReturn')}
                  onClick={() => {
                    deleteItemFromReturnTable(row.original['id']);
                  }}
                />
                <TableButtonEdit onClick={() => openAddItemDialog(FormMode.EDIT, row.original)} />
              </>
            )}
            headerActions={
              <>
                <IconButton
                  icon="RubberIcon"
                  isBackgroundTransparent
                  tooltipTitle={t('releaseDocuments:return.label.removeAll')}
                  onClick={() => {
                    setReturnTable([]);
                  }}
                />
                <IconButton
                  icon="AddIcon"
                  isBackgroundTransparent
                  isDisabled={isAllItemsAlreadyAdded}
                  tooltipTitle={
                    isAllItemsAlreadyAdded
                      ? t('releaseDocuments:return.tooltip.allItemsAlreadyAdded')
                      : t('releaseDocuments:return.label.add')
                  }
                  onClick={() => openAddItemDialog(FormMode.CREATE)}
                />
              </>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default ReturnSection;
