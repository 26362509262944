import { useEffect } from 'react';
import { OrganizationUnitMembersAssignRequest } from '@avispon/organization-structure/dist/models';

import { typedNameV2, useFormV2Context } from '@libs/common/v2';

interface IProps {
  selected: string[];
}

function UserGroupSelectAction({ selected }: IProps) {
  const { setValue } = useFormV2Context();

  useEffect(() => {
    setValue(typedNameV2<OrganizationUnitMembersAssignRequest>('userIds'), selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);

  return null;
}

function SelectionRowActionRender(selected: string[]) {
  return <UserGroupSelectAction selected={selected} />;
}

export default SelectionRowActionRender;
