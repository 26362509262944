import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ProceedingKreptdCertificateAddRequest,
  ProceedingKreptdCertificateDetails,
  TransportManagerCertificateSnapshot
} from '@ibtm/domain';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';

import {
  AutocompleteLazyFetchSelectField,
  DatepickerField,
  Dialog,
  FormMode,
  FormV2Context,
  GridLayout,
  SelectOption,
  Theme,
  typedNameV2
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { convertCalendarDate } from '@libs/common/v2/utils';

import { API } from '@libs/domain/api';

import { DomainDictionaryEntry } from '../../../../config';
import { RoadTransportQueryKeysEnum, useAddCertificatesToProceedingKreptdMutation } from '../../../api';
import { parseFormData } from '../../../utils';

interface IProps {
  proceedingId: string;
  closeDialog: () => void;
}

type ProceedingKreptdCertificateAddRequestClient = Partial<
  ProceedingKreptdCertificateAddRequest & { id?: SelectOption<TransportManagerCertificateSnapshot> }
>;

function CertificatesCreateForm({ proceedingId, closeDialog }: IProps) {
  const [t] = useTranslation();
  const classes = useStyles();
  const queryCache = useQueryCache();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: addCertificatesToProceedingKreptd, isLoading } = useAddCertificatesToProceedingKreptdMutation();

  const form = useForm<ProceedingKreptdCertificateAddRequestClient>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        id: Yup.object().nullable().required(),
        returnDate: Yup.date().nullable(),
        returnDateNotificationDateOfIts: Yup.date().nullable(),
        sendingDate: Yup.date().nullable(),
        sendDateNotificationDateOfIts: Yup.date().nullable()
      })
    ),
    defaultValues: {
      id: null,
      returnDate: null,
      returnDateNotificationDateOfIts: null,
      sendingDate: null,
      sendDateNotificationDateOfIts: null
    }
  });

  const searchCertificates = useCallback((searchText: string, params: { page: number; size: number }) => {
    return API.transportManagerCertificates.getTransportManagersCertificatesSnapshotPage({
      ...params,
      certificateNumberContains: searchText,
      statusKeyIn: [DomainDictionaryEntry.TRANSPORT_MANAGER_CERTIFICATE_STATUS.CURRENT],
      isInKreptdProceeding: false
    });
  }, []);

  const getOptionLabel = (option: TransportManagerCertificateSnapshot) => {
    return option?.number;
  };

  const renderOption = (option: SelectOption<TransportManagerCertificateSnapshot>) => {
    return (
      <>
        {option?.name}
        <div className={classes.validFrom}>
          {t('roadTransport:fields.validFrom')}: {convertCalendarDate(option.value?.validFrom)}
        </div>
      </>
    );
  };

  const onSubmit = useCallback(
    (data: ProceedingKreptdCertificateAddRequestClient) => {
      const parseFromData = parseFormData(data);
      addCertificatesToProceedingKreptd(
        { proceedingId, proceedingKreptdCertificateAddRequest: { ...parseFromData, id: data.id?.id } },
        {
          onSuccess: async () => {
            await queryCache.invalidateQueries([RoadTransportQueryKeysEnum.PROCEEDING_DETAILS, proceedingId]);
            showSuccessSnackbar(t('roadTransport:messages.addCertificateToProceedingKreptdMessage'));
            closeDialog();
          }
        }
      );
    },
    [addCertificatesToProceedingKreptd, closeDialog, proceedingId, queryCache, showSuccessSnackbar, t]
  );

  const formValues = useMemo(() => ({ ...form, formMode: FormMode.CREATE }), [form]);

  return (
    <Dialog
      title={t('roadTransport:dialog.addCertificateToProceedingKreptdTitle')}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={form.handleSubmit(onSubmit)}
      onCancel={closeDialog}
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={formValues}>
        <GridLayout itemProps={{ xs: 12 }}>
          <AutocompleteLazyFetchSelectField
            name={typedNameV2<ProceedingKreptdCertificateDetails>('id')}
            label={t('roadTransport:fields.certificateNumber')}
            queryKey={RoadTransportQueryKeysEnum.CERTIFICATES_LIST}
            optionLabelParser={getOptionLabel}
            renderOption={renderOption}
            api={{ FETCH: searchCertificates }}
            isRequired
          />
          <DatepickerField
            name={typedNameV2<ProceedingKreptdCertificateDetails>('returnDate')}
            label={t('roadTransport:fields.returnDate')}
          />
          <DatepickerField
            name={typedNameV2<ProceedingKreptdCertificateDetails>('returnDateNotificationDateOfIts')}
            label={t('roadTransport:fields.returnDateNotificationDateOfIts')}
          />
          <DatepickerField
            name={typedNameV2<ProceedingKreptdCertificateDetails>('sendingDate')}
            label={t('roadTransport:fields.sendingDate')}
          />
          <DatepickerField
            name={typedNameV2<ProceedingKreptdCertificateDetails>('sendDateNotificationDateOfIts')}
            label={t('roadTransport:fields.sendDateNotificationDateOfIts')}
          />
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  validFrom: {
    fontSize: theme.typography?.getFontSize(12),
    color: theme.palette.grey['A200']
  }
}));

export default CertificatesCreateForm;
