import { useDialog } from '@libs/common/v2';

import MoneyTransferUnconfirmedAddDialog from '../components/MoneyTransferUnconfirmedAddDialog';

function useMoneyTransferUnconfirmedAddDialog() {
  const { openDialog } = useDialog();

  const showDialog = ({ applicationId }: { applicationId: string }) => {
    openDialog(({ closeDialog }) => (
      <MoneyTransferUnconfirmedAddDialog applicationId={applicationId} closeDialog={closeDialog} />
    ));
  };

  return { showMoneyTransferUnconfirmedAddDialog: showDialog };
}

export default useMoneyTransferUnconfirmedAddDialog;
