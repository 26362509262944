import { usePaginatedQuery } from 'react-query';
import { ProceedingFormDetails } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { parseProceedingFormOperationsListPayload } from '../../utils';
import { endpointsConfig, ProceedingQueryKeysEnum } from '..';

const getFormOperations = async (_, params: any) => {
  const { proceedingId: proceedingIdentifier, ...queryParams } = parseProceedingFormOperationsListPayload(params);
  const { data } = await API.proceeding.getProceedingFormsPage(proceedingIdentifier, queryParams, {
    ...endpointsConfig.getProceedingFormsPage
  });

  return data;
};

export default function useFormOperationsQuery(params: any, queryConfig = {}) {
  return usePaginatedQuery<IPaginatedModel<ProceedingFormDetails>>(
    [ProceedingQueryKeysEnum.FORM_OPERATION_LIST, params],
    getFormOperations,
    queryConfig
  );
}
