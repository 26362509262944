import { AccessTokenDetailsExternal, DefaultApiFactory as AuthDefaultApiFactory } from '@avispon/auth';
import { DefaultApi as GroupDefaultApi } from '@avispon/group';
import { DefaultApi as UserDefaultApi } from '@avispon/user';
import axios, { AxiosPromise } from 'axios';

import { LOGIN_SSO_URL } from '../configs';

interface AuthApi {
  auth: {
    default: ReturnType<typeof AuthDefaultApiFactory>;
    accountController: UserDefaultApi;
    loginSSO: () => AxiosPromise<AccessTokenDetailsExternal>;
  };
  user: UserDefaultApi;
  group: GroupDefaultApi;
}

const authApiFactoryFallback: any = () => {
  throw new Error('Auth api has not been set');
};

const API: AuthApi = {
  auth: {
    default: authApiFactoryFallback as ReturnType<typeof AuthDefaultApiFactory>,
    accountController: authApiFactoryFallback as UserDefaultApi,
    loginSSO: null
  },
  user: authApiFactoryFallback as UserDefaultApi,
  group: authApiFactoryFallback as GroupDefaultApi
};

export function setAuthApi(API_PORTAL_URL: string) {
  API.auth = {
    default: AuthDefaultApiFactory({ isJsonMime: () => true }, API_PORTAL_URL, axios),
    accountController: new UserDefaultApi({ isJsonMime: () => true }, API_PORTAL_URL, axios),
    loginSSO: async () => {
      return axios.get<AccessTokenDetailsExternal>(LOGIN_SSO_URL);
    }
  };
  API.user = new UserDefaultApi({ isJsonMime: () => true }, API_PORTAL_URL, axios);
  API.group = new GroupDefaultApi({ isJsonMime: () => true }, API_PORTAL_URL, axios as never);
}

export default API;
