import { QueryConfig, useQuery } from 'react-query';
import { FinancialSecuritySummarySnapshot } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import { FinancialSecurityQueryKeysEnum } from './FinancialSecurityQueryKeysEnum';

export const getApplicationSummary = async (applicationId: string) => {
  const { data } = await API.financialSecurity.getFinancialSecurityApplicationSummary(applicationId, {
    ...endpointsConfig.getFinancialSecurityApplicationSummary
  });
  return data;
};

export const getApplicationSummaryClient = async (applicationId: string) => {
  const { data } = await API.client.financialSecurity.getFinancialSecurityApplicationSummary('', '', applicationId, {
    ...endpointsConfig.getFinancialSecurityApplicationSummary
  });
  return data;
};

export default function useFinancialSecurityApplicationSummaryQuery(
  applicationId: string,
  queryConfig: QueryConfig<any, unknown> = {}
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery<FinancialSecuritySummarySnapshot>(
    [FinancialSecurityQueryKeysEnum.FINANCIAL_SECURITY_SUMMARY, applicationId],
    getQuery(
      () => getApplicationSummaryClient(applicationId),
      () => getApplicationSummary(applicationId)
    ),
    queryConfig
  );
}
