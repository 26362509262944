import { QueryConfig, useQuery } from 'react-query';
import { ApplicationDefinitionSnapshotPage } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { ApplicationQueryKeysEnum, endpointsConfig } from '@libs/domain/application/api';

export const getApplicationDefinitions = async (folderTypeKey?: string, categoryKey?: string) => {
  const { data } = await API.applicationDefinitions.getApplicationDefinitionsSnapshotPage(
    {
      folderTypeKeyIn: [folderTypeKey],
      ...(categoryKey ? { categoryKeyIn: [categoryKey] } : {}),
      // Zahardkodowany size - pobieranie wszystkich definicji dla danej kategorii - żeby nie trzeba robić lazyloading
      size: 400
    },
    { ...endpointsConfig.getApplicationDefinitionsSnapshotPage }
  );
  return data;
};

export const getApplicationDefinitionsClient = async (_folderTypeKey?: string, categoryKey?: string) => {
  const { data } = await API.client.applicationDefinitions.getApplicationDefinitionsSnapshotPage(
    '',
    '',
    {
      ...(categoryKey ? { categoryKeyIn: [categoryKey] } : {}),
      // j.w.
      size: 400
    },
    { ...endpointsConfig.getApplicationDefinitionsSnapshotPage }
  );
  return data;
};

export default function useApplicationDefinitionsQuery(
  { folderTypeKey, categoryKey }: { folderTypeKey?: string; categoryKey?: string },
  queryConfig: QueryConfig<ApplicationDefinitionSnapshotPage, unknown> = {}
) {
  const { getQuery } = useGetApiQueryByPerspective();

  return useQuery(
    [ApplicationQueryKeysEnum.APPLICATION_DEFINITIONS, { folderTypeKey, categoryKey }],
    () => getQuery(getApplicationDefinitionsClient, getApplicationDefinitions)(folderTypeKey, categoryKey),
    {
      enabled: folderTypeKey,
      ...queryConfig
    }
  );
}
