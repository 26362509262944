import { useEffect, useState } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';

import {
  AutocompleteSelectField,
  InputMode,
  SelectOption,
  useCancellablePromise,
  useFormV2Context
} from '@libs/common/v2';

interface IProps {
  /**
   * @fetchFunctionResolver - funkcja asynchroniczna która zwraca sparsowany array opcji do AutocompleteSelect
   */
  fetchFunctionResolver: (params: any) => Promise<SelectOption[]>;
  isMultiple?: boolean;
  name: FieldPath<FieldValues>;
  parentKey?: string;
  valueClassName?: string;
  inputMode?: InputMode;
  className?: string;
  hasErrorTooltip?: boolean;
}
function FetchSelectField({ fetchFunctionResolver, name, hasErrorTooltip, ...forwardProps }: IProps) {
  const [options, setOptions] = useState<SelectOption[]>(null);
  const cancellable = useCancellablePromise();
  const { getValues } = useFormV2Context();

  useEffect(() => {
    const getOptions = async () => {
      const fetchedOptions = await cancellable(fetchFunctionResolver(getValues(name)));
      return setOptions(fetchedOptions ?? []);
    };
    getOptions();
  }, [cancellable, fetchFunctionResolver, getValues, name]);

  return (
    <AutocompleteSelectField
      name={name}
      options={options ?? []}
      isLoading={!options}
      hasErrorTooltip={hasErrorTooltip}
      {...forwardProps}
    />
  );
}

export default FetchSelectField;
