import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { queryCache } from 'react-query';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Dialog, DictionarySelectField, FormV2Context, GridLayout } from '@libs/common/v2';

import { AlertQueryKeysEnum, useUpdateAlertConfigurationStatus } from '@libs/alert/api';
import { AlertDictionaryEntryNameEnum } from '@libs/alert/model';

interface IProps {
  closeDialog: () => void;
  alertId: string;
}

function UpdateAlertConfigStatusDialog({ closeDialog, alertId }: IProps) {
  const [t] = useTranslation();
  const { mutate: updateAlertConfigurationStatus, isLoading } = useUpdateAlertConfigurationStatus();
  const { showSnackbar } = useSnackbar();
  const validationSchema = Yup.object({
    statusKey: Yup.string().required().nullable()
  });

  const form = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur'
  });

  const handleConfirm = (values: { statusKey?: string }) => {
    updateAlertConfigurationStatus(
      { alertId, statusKey: { configurationStatusKey: values.statusKey } },
      {
        onSuccess: () => {
          queryCache.invalidateQueries(AlertQueryKeysEnum.ALERTS_LIST);
          showSnackbar('success', t('alerts:message.configurationStatusUpdateSuccess'));
        },
        onError: () => {
          showSnackbar('error', t('alerts:message.configurationStatusUpdateError'));
        },
        onSettled: () => {
          closeDialog();
        }
      }
    );
  };

  return (
    <FormV2Context.Provider value={form}>
      <form onSubmit={form.handleSubmit(handleConfirm)}>
        <Dialog
          title={t('alerts:dialog.UpdateAlertConfig.title')}
          dialogSize="medium"
          onCancel={closeDialog}
          onConfirm={form.handleSubmit(handleConfirm)}
          confirmText={t('action.save')}
          cancelText={t('action.cancel')}
          isConfirmLoading={isLoading}
          isOpen
        >
          <GridLayout itemProps={{ xs: 12 }}>
            <DictionarySelectField
              name="statusKey"
              label={t('alerts:field.configurationStatus')}
              stringValue
              isClearable={false}
              dictionaryName={AlertDictionaryEntryNameEnum.ALERT_CONFIGURATION_STATUS}
              isRequired
            />
          </GridLayout>
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
}

export default UpdateAlertConfigStatusDialog;
