import { useMutation } from 'react-query';
import { TransitFrequencyUpdateRequest } from '@ibtm/domain/dist/models/transit-frequency-update-request';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export function editTransitFrequency({
  transitScheduleId,
  frequencies
}: {
  transitScheduleId: string;
  frequencies: TransitFrequencyUpdateRequest[];
}) {
  return API.transitSchedule.updateTransitFrequency(
    transitScheduleId,
    { frequencies },
    {
      ...endpointsConfig.editTransitFrequency
    }
  );
}

function useEditTransitFrequencyMutation() {
  return useMutation(editTransitFrequency);
}

export default useEditTransitFrequencyMutation;
