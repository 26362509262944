import React from 'react';
import { Tooltip } from '@mui/material';
import _ from 'lodash';

import { RichTextReadonly } from '@libs/common/v2';

const isHtml = (message: string): boolean => {
  return /<[a-z/][\s\S]*>/i.test(message);
};

export function convertMessage(message: string, hide: boolean, tooltipDisabled = false): React.ReactNode {
  if (_.isNull(message) || _.isUndefined(message)) {
    return null;
  }

  if (isHtml(message)) {
    return <RichTextReadonly text={message} disabled showDots={hide && message.length >= 90} />;
  }

  return message.length >= 30 ? (
    <Tooltip title={message} PopperProps={{ style: { zIndex: 1300 } }} disableHoverListener={tooltipDisabled}>
      <span>{message}</span>
    </Tooltip>
  ) : (
    <span>{message}</span>
  );
}
