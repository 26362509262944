import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GridLayout, InputMode, LoaderCircular, Section, useFormV2Context, useViewModesV2 } from '@libs/common/v2';

import { UIElementNameEnum, useElementVisibility } from '@libs/permission';

import { BusinessAddress, CorrespondenceAddress, MainAddress, SubjectAddressDetailsUnion } from '@libs/domain/address';
import { DomainDictionaryEntry, useDomainConfigContext } from '@libs/domain/config';

import { useApplicationAddressesQuery, useApplicationFlagsQuery } from '../../api';

export type AddressesInputMode = {
  mainAddress?: InputMode;
  businessAddress?: InputMode;
  correspondenceAddress?: InputMode;
};

interface IProps {
  applicationId: string;
  actionKey: UIElementNameEnum;
  isCountryFieldAvaliable: boolean;
  inputMode?: AddressesInputMode;
}

function AddressTab({ applicationId, actionKey, isCountryFieldAvaliable, inputMode }: IProps) {
  const [t] = useTranslation();
  const { isClientPortal } = useDomainConfigContext();
  const { reset, formMode } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);
  const { data: addressData, isFetching } = useApplicationAddressesQuery(
    { applicationIdIn: [applicationId] },
    { enabled: Boolean(applicationId) }
  );
  const { checkIsElementVisible } = useElementVisibility();
  const isElementEditable = useMemo(() => {
    return !viewMode && (!actionKey || checkIsElementVisible(actionKey));
  }, [checkIsElementVisible, actionKey, viewMode]);

  const { data: applicationFlagsData } = useApplicationFlagsQuery(applicationId, {
    enabled: isClientPortal ? false : applicationId
  });

  const isMainAddressEditable = applicationFlagsData?.mainOrHqAddressEditable;

  const addressInputMode = isClientPortal
    ? inputMode
    : inputMode || {
        mainAddress: isMainAddressEditable ? InputMode.FORM : InputMode.VIEW,
        businessAddress: isMainAddressEditable ? InputMode.FORM : InputMode.VIEW,
        correspondenceAddress: InputMode.FORM
      };

  useEffect(() => {
    if (addressData) {
      const data: SubjectAddressDetailsUnion[] =
        ('content' in addressData && addressData.content) ||
        ('addresses' in addressData && addressData.addresses) ||
        [];
      reset({
        'application-addresses-tab': {
          addressInputMode,
          main: data?.find(address => address.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.MAIN),
          hq: data?.find(address => address.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.HQ),
          correspondence: data?.find(address => address.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.CORRESPONDENCE)
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressData, reset]);

  return (
    <GridLayout itemProps={{ xs: 12 }} containerProps={{ spacing: 0 }}>
      <Section title={t('applications:details.section.mainAddress')} isCollapsable>
        {isFetching ? (
          <LoaderCircular className="mt-20" size={32} isLoading />
        ) : (
          <MainAddress
            formGroupName="application-addresses-tab.main"
            isCountryFieldAvaliable={isCountryFieldAvaliable}
            inputProps={{ inputMode: isElementEditable ? addressInputMode?.mainAddress : InputMode.VIEW }}
          />
        )}
      </Section>

      <Section title={t('applications:details.section.businessAddress')} isCollapsable>
        {isFetching ? (
          <LoaderCircular className="mt-20" size={32} isLoading />
        ) : (
          <BusinessAddress
            formGroupName="application-addresses-tab.hq"
            isCountryFieldAvaliable={isCountryFieldAvaliable}
            inputMode={isElementEditable ? addressInputMode?.businessAddress : InputMode.VIEW}
          />
        )}
      </Section>

      <Section title={t('applications:details.section.correspondenceAddress')} isCollapsable>
        {isFetching ? (
          <LoaderCircular className="mt-20" size={32} isLoading />
        ) : (
          <CorrespondenceAddress
            formGroupName="application-addresses-tab.correspondence"
            applicationId={applicationId}
            isCountryFieldAvaliable={isCountryFieldAvaliable}
            inputMode={isElementEditable ? addressInputMode?.correspondenceAddress : InputMode.VIEW}
          />
        )}
      </Section>
    </GridLayout>
  );
}

export default AddressTab;
