import { useTranslation } from 'react-i18next';

import { DropdownListButton, useTableAdapter } from '@libs/common/v2';

import { useSendApplicationsToArchiveDialog } from '../../application/hooks/dialogs';

interface IProps {
  selected: string[];
  handleClose: () => void;
}

function ArchiveTableSelectAction({ selected, handleClose }: IProps) {
  const [t] = useTranslation();
  const { toggleAllRowsSelected } = useTableAdapter();
  const { openSendApplicationsToArchiveDialog } = useSendApplicationsToArchiveDialog();

  const handleIconClick = () => {
    openSendApplicationsToArchiveDialog(selected);
    toggleAllRowsSelected(false);
    handleClose();
  };

  return <DropdownListButton label={t('archive:action.sendToArchive')} onClick={handleIconClick} />;
}

export function ApplicationToArchiveSelectRowActionRenderer(selected: string[], handleClose: () => void) {
  return <ArchiveTableSelectAction selected={selected} handleClose={handleClose} />;
}
