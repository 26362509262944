import { QueryConfig, usePaginatedQuery } from 'react-query';
import { DefaultApiGetTranslationDetailsPageRequest, DictionaryEntryDetailsPage } from '@avispon/dictionary';

import { API, DictionaryQueryKeysEnum } from '@libs/dictionary';

const getDictionary = async (_, params: DefaultApiGetTranslationDetailsPageRequest) => {
  const { data } = await API.dictionary.getTranslationDetailsPage(params);
  return data;
};

export default function useDictionaryQuery(
  params: DefaultApiGetTranslationDetailsPageRequest,
  queryConfig?: QueryConfig<DictionaryEntryDetailsPage, unknown>
) {
  return usePaginatedQuery([DictionaryQueryKeysEnum.DICTIONARY_LIST, params], getDictionary, queryConfig);
}
