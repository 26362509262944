import { useTranslation } from 'react-i18next';

import { TextInputField, useFormV2Context } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

export default function AdditionalCodeInput() {
  const [t] = useTranslation();

  const { formMode } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);

  if (viewMode) return <TextInputField name="code" label={t('user:field.code')} />;

  return (
    <TextInputField
      name="code"
      label={t('user:field.code')}
      isOnlyPositiveIntegers
      inputProps={{ maxLength: 4 }}
      isRequired
    />
  );
}
