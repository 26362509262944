import { ReturnApplicationForCorrectionRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

export function returnForCorrections({
  applicationId,
  formData
}: {
  applicationId: string;
  formData: ReturnApplicationForCorrectionRequest;
}) {
  return API.application.returnApplicationForCorrection(applicationId, formData, {
    ...endpointsConfig.returnApplicationForCorrection
  });
}

function useReturnForCorrectionsMutation() {
  return useMutation(returnForCorrections);
}

export default useReturnForCorrectionsMutation;
