import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface ActivateProceedingFormsParams {
  proceedingId: string;
  proceedingFormIds: Array<string>;
  version: number;
}

function activateFormOperations({ proceedingId, proceedingFormIds, version }: ActivateProceedingFormsParams) {
  return API.proceeding.activateProceedingForms(
    proceedingId,
    {
      proceedingFormIds,
      version
    },
    { ...endpointsConfig.activateProceedingForms }
  );
}

export function useActivateFormOperationsMutation() {
  return useMutation(activateFormOperations);
}
