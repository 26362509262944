import React, { ChangeEvent, Context, useContext, useMemo } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';
import { InputBaseProps } from '@mui/material';
import clsx from 'clsx';

import {
  ComponentErrorBoundary,
  FormErrorType,
  FormV2Context,
  FormV2ContextState,
  getInputMode,
  InputMode,
  TextInput,
  Value
} from '@libs/common/v2';
import { getValue } from '@libs/common/v2/utils';

import { UIElementNameEnum, useElementVisibility } from '@libs/permission';

import { FieldTypeEnum, TextFieldValidation, useFieldValidationHandler } from '../../validation';

export interface TextInputFieldProps {
  name: FieldPath<FieldValues>;
  lines?: number;
  label?: string;
  formContext?: Context<FormV2ContextState>;
  className?: string;
  valueClassName?: string;
  isOnlyPositiveIntegers?: boolean;
  isOnLimitDemical?: boolean;
  getValueFormat?: (value: any) => string;
  inputProps?: InputBaseProps['inputProps'];
  inputMode?: InputMode;
  placeholder?: string;
  type?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  isHidden?: boolean;
  isLabelHidden?: boolean;
  onInput?: (e) => void;
  validation?: TextFieldValidation;
  actionKey?: UIElementNameEnum;
  hyperlink?: string;
  onHyperlinkClick?: React.MouseEventHandler<HTMLAnchorElement>;
  customHandleChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  tooltip?: string | React.ReactNode;
  inputMask?: InputMaskProps;
  helperText?: string;
  isHelperTextWarning?: boolean;
  hasErrorTooltip?: boolean;
}

function TextInputField({
  name,
  lines,
  formContext = FormV2Context,
  className,
  valueClassName,
  label,
  isOnlyPositiveIntegers,
  isOnLimitDemical,
  isDisabled,
  placeholder,
  isRequired,
  inputProps,
  isHidden,
  isLabelHidden,
  inputMode,
  getValueFormat,
  validation,
  actionKey,
  hyperlink,
  onHyperlinkClick,
  customHandleChange,
  onBlur: customOnBlur,
  tooltip,
  inputMask,
  helperText,
  isHelperTextWarning,
  hasErrorTooltip,
  ...forwardProps
}: TextInputFieldProps) {
  const { control, formMode, loading, isSubmitting } = useContext<FormV2ContextState>(formContext);
  const { checkIsElementVisible } = useElementVisibility();
  const mode = getInputMode(formMode, inputMode);

  const onPositiveNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-param-reassign
    e.target.value = e.target.value.replace(/\D/g, '').trim();
  };

  const onLimitDemicalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberValue = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
    const dotPosition = numberValue.indexOf('.');
    // eslint-disable-next-line no-param-reassign
    e.target.value =
      dotPosition >= 0 ? numberValue.substr(0, dotPosition) + numberValue.substr(dotPosition, 3) : numberValue;
  };

  const isElementVisible = useMemo(() => {
    return actionKey ? checkIsElementVisible(actionKey) : true;
  }, [checkIsElementVisible, actionKey]);

  useFieldValidationHandler({
    fieldName: name,
    validation,
    fieldType: FieldTypeEnum.TEXT
  });

  const getFormModeInput = (value, error, onChange, onBlur) => {
    return inputMask ? (
      <InputMask
        {...inputMask}
        name={name}
        value={value || ''}
        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          onChange(e);
          customHandleChange?.(e);
        }}
        {...(isOnlyPositiveIntegers ? { onInput: onPositiveNumberChange } : {})}
        {...(isOnLimitDemical ? { onInput: onLimitDemicalChange } : {})}
        onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
          customOnBlur?.(e);
          onBlur();
        }}
        required={isRequired || Boolean(validation?.required) || false}
        disabled={isDisabled || isSubmitting}
        placeholder={placeholder}
      >
        {props => (
          <TextInput
            label={label}
            disabled={isDisabled || isSubmitting}
            className={clsx(className, isHidden && 'hidden')}
            isLabelHidden={isLabelHidden || false}
            error={!!error && error?.type !== FormErrorType.WARNING}
            isWarning={error?.type !== FormErrorType.WARNING}
            helperText={error?.message}
            {...(lines ? { rows: lines, multiline: true } : {})}
            inputProps={inputProps}
            fullWidth
            tooltip={tooltip}
            {...props}
            {...forwardProps}
          />
        )}
      </InputMask>
    ) : (
      <TextInput
        name={name}
        label={label}
        className={clsx(className, isHidden && 'hidden')}
        value={value || ''}
        onChange={e => {
          onChange(e);
          customHandleChange?.(e);
        }}
        {...(isOnlyPositiveIntegers ? { onInput: onPositiveNumberChange } : {})}
        {...(isOnLimitDemical ? { onInput: onLimitDemicalChange } : {})}
        onBlur={e => {
          customOnBlur?.(e);
          onBlur();
        }}
        required={isRequired || Boolean(validation?.required) || false}
        isLabelHidden={isLabelHidden || false}
        error={!!error && error?.type !== FormErrorType.WARNING}
        isWarning={error?.type !== FormErrorType.WARNING || isHelperTextWarning}
        helperText={error?.message || helperText}
        disabled={isDisabled || isSubmitting}
        placeholder={placeholder}
        {...(lines ? { rows: lines, multiline: true } : {})}
        inputProps={inputProps}
        fullWidth
        tooltip={tooltip}
        hasErrorTooltip={hasErrorTooltip}
        {...forwardProps}
      />
    );
  };

  const viewModeInputContent = (hyperlink: string, value: string) => {
    if (!hyperlink && !onHyperlinkClick) {
      return !loading && !getValueFormat ? getValue(value) : getValue?.(getValueFormat?.(value));
    }
    return (
      <a href={hyperlink} onClick={onHyperlinkClick}>
        {!loading && !getValueFormat ? getValue(value) : getValue?.(getValueFormat?.(value))}
      </a>
    );
  };

  if (isElementVisible) {
    return (
      <ComponentErrorBoundary componentName={label || placeholder || name || 'TextInputField'}>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) =>
            mode === InputMode.FORM
              ? getFormModeInput(value, error, onChange, onBlur)
              : !isHidden && (
                  <Value
                    label={label}
                    className={clsx(valueClassName, 'break-words')}
                    isLoading={loading}
                    isError={!!error && error?.type !== FormErrorType.WARNING}
                    isWarning={error?.type !== FormErrorType.WARNING}
                    helperText={error?.message}
                  >
                    {viewModeInputContent(hyperlink, value as string)}
                  </Value>
                )
          }
        />
      </ComponentErrorBoundary>
    );
  }
}

export default TextInputField;
