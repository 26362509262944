import React from 'react';
import { Column, OnFilterChange } from 'react-table';

import { DictionaryEntryNameEnum, useDictionaryEntryValues } from '@libs/dictionary';

import SelectFilter from './SelectFilter';

interface IProps {
  column: Column & { filterValue?: string; setFilter: (value: string) => void };
  dictionary: DictionaryEntryNameEnum;
  isMultiple?: boolean;
  onFilterChange: OnFilterChange;
  hiddenSelectOptionsValues?: string[];
  renderOption?: (props: Record<string, any>, option: any) => React.ReactNode;
  areInactiveEntriesVisible?: boolean;
}

function DictionaryFilter({
  dictionary,
  hiddenSelectOptionsValues,
  renderOption,
  areInactiveEntriesVisible = true,
  ...restProps
}: IProps) {
  const dictionaryEntries = useDictionaryEntryValues(dictionary) ?? [];

  const allOptions = hiddenSelectOptionsValues
    ? dictionaryEntries.filter(({ value }) => !hiddenSelectOptionsValues.includes(value))
    : dictionaryEntries;

  const options = areInactiveEntriesVisible ? allOptions : allOptions.filter(({ active }) => active);
  return (
    <SelectFilter
      options={options}
      {...restProps}
      customInputStyle={{
        marginRight: '15px',
        paddingRight: '15px',
        paddingRightWhenValue: '40px'
      }}
      renderOption={(props, option) =>
        renderOption?.(props, option) || (
          <li {...props} key={option?.value || option}>
            {option.name}
          </li>
        )
      }
    />
  );
}

export default React.memo(DictionaryFilter);
