import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PluginHook, Row } from 'react-table';
import { DriverCertificateSnapshot } from '@ibtm/client-domain';

import { CamelCasePath, TableLayout } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { useDomainConfigContext } from '../../config';
import { DEFAULT_COLUMNS, useDriverCertificatesTable } from '../hooks';
import { DriverCertificatesColumnsEnum, DriverCertificateSnapshotClient } from '../model';
import { parseDriverCertificatesList } from '../parsers';

interface IProps {
  applicationId?: string;
  folderId?: string;
  visibleColumns?: Array<CamelCasePath<DriverCertificateSnapshotClient>>;
  defaultFilters?: {
    id: DriverCertificatesColumnsEnum;
    value: any;
  }[];
  headerActions?: ReactNode;
  rowActions?: (row: Row<DriverCertificateSnapshotClient>, isTableMutable?: boolean) => ReactNode;
  tablePlugins?: Array<PluginHook<DriverCertificateSnapshotClient>>;
  isTableMutable?: boolean;
  actionsColumnWidth?: number;
  includePartnershipPermissions?: boolean;
  isTableDataVisible?: boolean;
  isHeaderActions?: boolean;
}

function DriverCertificatesTable({
  applicationId,
  folderId,
  visibleColumns = DEFAULT_COLUMNS,
  defaultFilters,
  rowActions,
  headerActions,
  isTableMutable,
  tablePlugins = [],
  actionsColumnWidth,
  includePartnershipPermissions,
  isTableDataVisible = true,
  isHeaderActions = true
}: IProps) {
  const [t] = useTranslation();
  const { isClientPortal } = useDomainConfigContext();
  const tableProps = useDriverCertificatesTable(
    visibleColumns,
    applicationId,
    folderId,
    defaultFilters,
    tablePlugins,
    includePartnershipPermissions,
    isTableDataVisible
  );

  const apiRequest = useCallback(
    params => {
      if (isClientPortal) {
        return API.client.driverCertificate.getDriverCertificatesPage('', '', params);
      }

      return API.driverCertificate.getDriverCertificatesPage(params);
    },
    [isClientPortal]
  );

  return (
    <TableLayout<DriverCertificateSnapshotClient, DriverCertificateSnapshot>
      {...tableProps}
      pageTitle={t('driverCertificate:title')}
      xlsxDownload={{
        fileName: t('driverCertificate:title'),
        pathToXLSXTranslation: 'driverCertificate:fields',
        apiRequest: params =>
          apiRequest(params).then(res => ({
            ...res,
            data: { ...res.data, content: parseDriverCertificatesList(res.data.content) }
          }))
      }}
      rowActions={row => rowActions?.(row, isTableMutable)}
      actionsColumnWidth={actionsColumnWidth}
      headerActions={isHeaderActions && headerActions}
      isActionColumnEnabled={Boolean(rowActions)}
      isRefreshEnabled
      isTableMutable={isTableMutable}
      isCollapsable
      isSection
    />
  );
}

export default DriverCertificatesTable;
