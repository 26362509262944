import { Filters } from 'react-table';

/**
 * Filters an array of table filter objects to exclude those with value equal to empty array.
 */
const getNonEmptyFilters = (filters: Filters<any>): Filters<any> => {
  return filters?.filter(item => !(Array.isArray(item.value) && !item.value.length));
};

export default getNonEmptyFilters;
