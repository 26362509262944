import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export function deleteForeignPermissionApplication({
  applicationId,
  foreignPermissionRecordId
}: {
  applicationId: string;
  foreignPermissionRecordId: string;
}) {
  return API.applicationForeignPermissions.remove(applicationId, foreignPermissionRecordId, {
    ...endpointsConfig.removeForeignPermission
  });
}

export function useRemoveApplicationForeignPermissionMutation() {
  return useMutation(deleteForeignPermissionApplication);
}
