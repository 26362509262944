import { useTableLayoutProps } from '@libs/common/v2';

import { useAssociationOrdersQuery } from '../../api';
import { useAssociationOrdersColumns } from '../columns';

function useAssociationOrdersTable() {
  const { columns, mappedFilterFields, mappedSortFields } = useAssociationOrdersColumns();

  return useTableLayoutProps({
    tableHookQuery: useAssociationOrdersQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    }
  });
}

export default useAssociationOrdersTable;
