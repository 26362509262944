import { useCallback, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { FolderCreateRequest, FolderDetails, SubjectPartnershipPartnerSnapshot, SubjectSnapshot } from '@ibtm/domain';

import { Button, TableButtonDelete, typedNameV2, useConfirmDialog, useFormV2Context } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { useEntrepreneursColumns } from '@libs/domain/application';
import { EntrepreneursColumnsEnum } from '@libs/domain/application/model';
import { DomainUIElementEnum } from '@libs/domain/config';
import { Entrepreneur, parseEntrepreneurDetailsSnapshot } from '@libs/domain/folder';
import { EntrepreneursTable } from '@libs/domain/subject';

import SearchFolderModal from './SearchFolderModal';

function EntrepreneursSection() {
  const [t] = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const { control, watch, formMode } = useFormV2Context();
  const { createMode } = useViewModesV2(formMode);
  const { showSuccessSnackbar } = useSnackbar();

  const [confirm] = useConfirmDialog();

  const entrepreneurs = watch(typedNameV2<FolderCreateRequest>('partners')) as SubjectSnapshot[] &
    SubjectPartnershipPartnerSnapshot[];
  const parsedEntrepreneurs = parseEntrepreneurDetailsSnapshot(entrepreneurs);

  const visibleColumns = [
    EntrepreneursColumnsEnum.FOLDER_NUMBER,
    EntrepreneursColumnsEnum.NAME,
    EntrepreneursColumnsEnum.NIP,
    EntrepreneursColumnsEnum.ADDRESS,
    EntrepreneursColumnsEnum.STATUS_KEY,
    EntrepreneursColumnsEnum.LEGAL_FORM_KEY,
    EntrepreneursColumnsEnum.FOLDER_NUMBER,
    EntrepreneursColumnsEnum.PENDING_PROCEEDING
  ];
  const { columns } = useEntrepreneursColumns(visibleColumns, createMode);

  const { append, remove } = useFieldArray({
    control,
    name: typedNameV2<FolderCreateRequest>('partners')
  });

  const onDelete = ({ id: idToDelete }): Promise<void> => {
    const indexToDelete = parsedEntrepreneurs.findIndex(({ id }) => idToDelete === id);
    remove(indexToDelete);
    return Promise.resolve();
  };

  const openSearchFolderModal = () => {
    setModalOpen(true);
  };

  const onEntrepreneurAdded = () => {
    showSuccessSnackbar(t('folder:details.message.entrepreneurAdded'));
    setModalOpen(false);
  };

  const addEntrepreneur = (selectedEntrepreneurs: FolderDetails[]): Promise<void> => {
    selectedEntrepreneurs.forEach(entrepreneur => {
      append({
        ...entrepreneur,
        customId: entrepreneur.id
      });
    });
    onEntrepreneurAdded();
    return Promise.resolve();
  };

  const handleDelete = (entrepreneur): void => {
    confirm({
      title: t('folder:details.message.entrepreneurRemoveTitle'),
      message: t('folder:details.message.entrepreneurRemoveConfirmation'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        onDelete(entrepreneur)
          .then(() => {
            showSuccessSnackbar(t('folder:details.message.entrepreneurRemoved'));
            closeDialog();
          })
          .finally(() => {
            setConfirmLoading(false);
          });
      },
      confirmType: 'danger'
    });
  };

  const rowActions = useCallback(
    ({ original }) => createMode && <TableButtonDelete onClick={() => handleDelete(original as Entrepreneur)} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [parsedEntrepreneurs]
  );

  return (
    <>
      <EntrepreneursTable
        data={parsedEntrepreneurs}
        visibleColumns={columns}
        headerActions={
          createMode && (
            <Button
              variant="outlined"
              onClick={openSearchFolderModal}
              label={t('action.add')}
              actionKey={DomainUIElementEnum.FOLDER_ADD_ENTREPRENEURS}
            />
          )
        }
        rowActions={rowActions}
        actionsColumnWidth={80}
      />
      <SearchFolderModal
        folderType={watch(typedNameV2<FolderCreateRequest>('typeKey')) as string}
        open={isModalOpen}
        setIsOpen={setModalOpen}
        addEntrepreneur={addEntrepreneur}
        entrepreneurs={parsedEntrepreneurs}
        createMode={createMode}
      />
    </>
  );
}

export default EntrepreneursSection;
