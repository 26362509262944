import { ReactNode } from 'react';

import MetaFormContext, { MetaFormContextState } from './MetaFormContext';

interface IProps {
  children: ReactNode;
  values: MetaFormContextState;
}

function MetaFormProvider({ children, values }: IProps) {
  return <MetaFormContext.Provider value={values}>{children}</MetaFormContext.Provider>;
}

export default MetaFormProvider;
