import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

export function deleteApplicationDraft(applicationId) {
  return API.client.application.deleteDraftApplication(applicationId, '', '', {
    ...endpointsConfig.deleteDraftApplication
  });
}

function useClientDeleteApplicationDraftMutation() {
  return useMutation(deleteApplicationDraft);
}

export default useClientDeleteApplicationDraftMutation;
