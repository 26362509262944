import { useCallback } from 'react';
import { VehicleDetails as VehicleDetailsModel } from '@ibtm/domain';

import { FormMode, InputMode, useDialog } from '@libs/common/v2';

import { VehicleDetails } from '../components/details';
import { IVehicleActionKeys, VehicleFormData, VehicleSectionFields } from '../model';

function useVehicleDetailsDialog(
  hiddenFieldsAddVehicle?: VehicleSectionFields[],
  checkCepikInformationFolderType?: boolean,
  fieldsMode?: { [key in keyof VehicleFormData]?: InputMode },
  actionKeys?: IVehicleActionKeys
) {
  const { openDialog } = useDialog();

  const openDetailsDialog = useCallback(
    ({
      folderType,
      formMode,
      folderId,
      transferredFoldersIds,
      applicationId,
      applicationType,
      applicationCategory,
      id,
      initialData,
      isCreatingFromFolder
    }: {
      folderType: string;
      folderId?: string;
      transferredFoldersIds?: string[];
      formMode?: FormMode;
      applicationId?: string;
      applicationType?: string;
      applicationCategory?: string;
      id?: string;
      initialData?: Partial<VehicleDetailsModel>;
      isCreatingFromFolder?: boolean;
    }) => {
      openDialog(({ closeDialog }) => (
        <VehicleDetails
          id={id}
          closeDialog={closeDialog}
          formMode={formMode}
          folderType={folderType}
          folderId={folderId}
          transferredFoldersIds={transferredFoldersIds}
          applicationId={applicationId}
          applicationType={applicationType}
          applicationCategory={applicationCategory}
          initialData={initialData}
          hiddenFieldsAddVehicle={hiddenFieldsAddVehicle}
          isCreatingFromFolder={isCreatingFromFolder}
          checkCepikInformationFolderType={checkCepikInformationFolderType}
          fieldsMode={fieldsMode}
          actionKeys={actionKeys}
        />
      ));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog]
  );

  return {
    openDetailsDialog
  };
}

export default useVehicleDetailsDialog;
