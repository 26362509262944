import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationDetails, DepotLite } from '@ibtm/domain';
import { useAppConfig } from '@libs/app-config';

import { useRouter } from '@libs/common/v2/hooks';

import { useAuth } from '@libs/auth/hooks';

import DomainDictionaryEntry from '../../config/DomainDictionaryEntry';
import { useDepotManagersLiteQuery } from '../../resource/api';

type PrintDetailsData = {
  applicationId: string;
  folderId: string;
  applicationSnapshot: ApplicationDetails;
  depotMPD?: DepotLite;
  depotMP?: DepotLite;
  notDepotMPDTooltip?: string;
  notDepotMPTooltip?: string;
  moreThanOneDepotMPDTooltip?: string;
  moreThanOneDepotMPTooltip?: string;
};

const PrintsDetailsDataContext = createContext<{
  showPrintDetails: (dialogParams: PrintDetailsData) => void;
  printDetailsData: PrintDetailsData;
}>(null);

export function PrintsDetailsDataContextProvider({ children }) {
  const { location } = useRouter();
  const { raw: config } = useAppConfig();
  const defaultDepotNumber = config?.prints?.defaultDepotNumber;

  const applicationData: ApplicationDetails = location.state?.applicationSnapshot;

  const initialParams: PrintDetailsData = {
    applicationId: applicationData?.id,
    folderId: applicationData?.folder.id,
    applicationSnapshot: applicationData,
    depotMPD: {},
    depotMP: {}
  };

  const [printDetailsData, setPrintDetailsData] = useState<PrintDetailsData>(initialParams);
  const [t] = useTranslation();

  const showPrintDetails = useCallback(
    (dialogParams: PrintDetailsData) => {
      setPrintDetailsData(dialogParams);
    },
    [setPrintDetailsData]
  );

  const { user } = useAuth();
  const { data } = useDepotManagersLiteQuery(
    { userId: user?.personalData?.id },
    {
      refetchOnMount: false
    }
  );

  const value = useMemo(() => {
    const getDepot = (depots: DepotLite[], depotsAmount: number): DepotLite => {
      if (depotsAmount === 0) {
        return {};
      }

      if (depotsAmount === 1) {
        return depots?.[0];
      }

      return depots?.find(item => item?.number === defaultDepotNumber) || {};
    };

    const depotsMPD = data?.content?.filter(item => item.typeKey === DomainDictionaryEntry.DEPOT_TYPE.MPD);
    const depotsMPDAmount = depotsMPD?.length;
    const depotMPD = getDepot(depotsMPD, depotsMPDAmount);

    const depotsMP = data?.content?.filter(item => item.typeKey === DomainDictionaryEntry.DEPOT_TYPE.MP);
    const depotsMPAmount = depotsMP?.length;
    const depotMP = getDepot(depotsMP, depotsMPAmount);

    const notDepotMPDTooltip = `${t('prints:messages.youAreNotMPDDepotAdministrator')} ${
      depotMPD.id
        ? t('prints:messages.youAreMPDepotManagerAlready', { depotName: depotMPD.name })
        : t('prints:messages.youAreNotDepotManagerAlready')
    }`;
    const notDepotMPTooltip = `${t('prints:messages.youAreNotMPDepotAdministrator')} ${
      depotMP.id
        ? t('prints:messages.youAreMPDDepotManagerAlready', { depotName: depotMP.name })
        : t('prints:messages.youAreNotDepotManagerAlready')
    }`;

    const moreThanOneDepotMPDTooltip =
      depotsMPDAmount > 1
        ? `${t('prints:messages.moreThanOneMPDDepotAdministrator')} ${t(
            'prints:messages.youAreMPDDepotsManagerAlready',
            { depotNames: depotsMPD.map(item => item.name).join(', ') }
          )}
          `.concat(
            !depotMPD.id
              ? t('prints:messages.moreThanOneMPDDepotWithoutDefault', { depotName: defaultDepotNumber })
              : ''
          )
        : null;

    const moreThanOneDepotMPTooltip =
      depotsMPAmount > 1
        ? `${t('prints:messages.moreThanOneMPDepotAdministrator')} ${t('prints:messages.youAreMPDepotsManagerAlready', {
            depotNames: depotsMP.map(item => item.name).join(', ')
          })}
          `.concat(
            !depotMP.id ? t('prints:messages.moreThanOneMPDepotWithoutDefault', { depotName: defaultDepotNumber }) : ''
          )
        : null;

    return {
      showPrintDetails,
      printDetailsData: {
        ...printDetailsData,
        depotMPD,
        depotMP,
        notDepotMPDTooltip,
        notDepotMPTooltip,
        moreThanOneDepotMPDTooltip,
        moreThanOneDepotMPTooltip
      }
    };
  }, [data?.content, t, showPrintDetails, printDetailsData, defaultDepotNumber]);

  return <PrintsDetailsDataContext.Provider value={value}>{children}</PrintsDetailsDataContext.Provider>;
}

function usePrintsDetailsDataContext() {
  return useContext(PrintsDetailsDataContext);
}

export default usePrintsDetailsDataContext;
