// eslint-disable-next-line import/prefer-default-export
export enum RoadTransportQueryKeysEnum {
  VIOLATIONS_LIST = 'VIOLATIONS_LIST',
  PROCEEDINGS_LIST = 'PROCEEDINGS_LIST',
  PROCEEDINGS_LICENSE_COPY = 'PROCEEDINGS_LICENSE_COPY',
  PROCEEDING_DETAILS = 'PROCEEDING_DETAILS',
  CERTIFICATES_LIST = 'CERTIFICATES_LIST',
  FOLDERS_TO_TRANSFER_LIST = 'FOLDERS_TO_TRANSFER_LIST',
  FOLDER_DETAILS = 'FOLDER_DETAILS',
  DOCUMENTS_LIST = 'DOCUMENTS_LIST'
}
