import { useTableLayoutProps } from '@libs/common/v2';

import { useLocksQuery } from '@libs/domain/folder';

import useLockColumns from './useLockColumns';

function useLocksTable(folderId: string) {
  const { columns } = useLockColumns();

  return useTableLayoutProps({
    tableHookQuery: useLocksQuery,
    tableHookOptions: {
      columns,
      tableOptions: {
        initialState: {
          sortBy: [
            {
              id: 'created',
              desc: true
            }
          ]
        }
      }
    },
    tableHookQueryInitialParams: {
      folderIdIn: [folderId]
    }
  });
}

export default useLocksTable;
