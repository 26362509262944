import { ReactNode, useEffect, useMemo, useState } from 'react';
import { PluginHook, Row } from 'react-table';
import { ProceedingAvailableFormsDetails } from '@ibtm/domain';

import { SelectOption, TableLayout } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { DomainDictionaryEntry } from '@libs/domain/config';
import { useAvailableFormsTable } from '@libs/domain/folder';

import { PermissionTableEnum } from './PermissionsTab';

interface IProps {
  folderId: string;
  selectedPermissionType: PermissionTableEnum;
  permissionTypes: SelectOption[];
  columns: any;
  rowActions?: (row: Row<any>) => ReactNode;
  actionsColumnWidth?: number;
  tablePlugins?: PluginHook<ProceedingAvailableFormsDetails>[];
  includePartnershipPermissions?: boolean;
}

function AvailableFormsTable({
  actionsColumnWidth,
  folderId,
  rowActions,
  selectedPermissionType,
  permissionTypes,
  columns,
  tablePlugins,
  includePartnershipPermissions
}: IProps) {
  const [key, setKey] = useState<number>(0);

  useEffect(() => {
    if (selectedPermissionType) {
      setKey(prevKey => prevKey + 1);
    }
  }, [selectedPermissionType]);

  const permissionTypeCodes = useMemo(() => {
    switch (selectedPermissionType) {
      case PermissionTableEnum.GITD:
        return [
          DomainDictionaryEntry.PERMISSION_TYPE_CODE.PERMISSION_ROAD_CARRIER_GOODS_CARRIAGE,
          DomainDictionaryEntry.PERMISSION_TYPE_CODE.PERMISSION_ROAD_CARRIER_PASSENGER_CARRIAGE
        ];
      case PermissionTableEnum.LISENSE:
        return [DomainDictionaryEntry.PERMISSION_TYPE_CODE.LICENSE];
      case PermissionTableEnum.LICENSE_COPY:
        return [DomainDictionaryEntry.PERMISSION_TYPE_CODE.LICENSE_COPY];
      case PermissionTableEnum.ROAD_CARRIER:
        return [
          DomainDictionaryEntry.PERMISSION_TYPE_CODE.PERMISSION_ROAD_CARRIER_GOODS_CARRIAGE_ABRIDGEMENT,
          DomainDictionaryEntry.PERMISSION_TYPE_CODE.PERMISSION_ROAD_CARRIER_PASSENGER_CARRIAGE_ABRIDGEMENT
        ];
      case PermissionTableEnum.DRIVER_CERTIFICATE:
        return [DomainDictionaryEntry.PERMISSION_TYPE_CODE.DRIVER_CERTIFICATE];
      case PermissionTableEnum.SPO_UE:
        return [DomainDictionaryEntry.PERMISSION_TYPE_CODE.PERMISSION_SPO_UE];
      case PermissionTableEnum.SPO_UE_COPY:
        return [DomainDictionaryEntry.PERMISSION_TYPE_CODE.PERMISSION_SPO_UE_ABRIDGEMENT];
      case PermissionTableEnum.SPO_OUTSIDE_UE:
        return [DomainDictionaryEntry.PERMISSION_TYPE_CODE.PERMISSION_SPO_OUTSIDE_UE];
      case PermissionTableEnum.SPO_OUTSIDE_UE_COPY:
        return [DomainDictionaryEntry.PERMISSION_TYPE_CODE.PERMISSION_SPO_OUTSIDE_UE_ABRIDGEMENT];
      case PermissionTableEnum.SPO_SHUTTLE:
        return [DomainDictionaryEntry.PERMISSION_TYPE_CODE.PERMISSION_SPO_SHUTTLE];
      case PermissionTableEnum.SPO_SHUTTLE_COPY:
        return [DomainDictionaryEntry.PERMISSION_TYPE_CODE.PERMISSION_SPO_SHUTTLE_ABRIDGEMENT];
      case PermissionTableEnum.SPO_OCCASIONAL:
        return [DomainDictionaryEntry.PERMISSION_TYPE_CODE.PERMISSION_SPO_OCCASIONAL];
      case PermissionTableEnum.SPO_OCCASIONAL_COPY:
        return [DomainDictionaryEntry.PERMISSION_TYPE_CODE.PERMISSION_SPO_OCCASIONAL_ABRIDGEMENT];
      case PermissionTableEnum.SPO_TRANSIT:
        return [DomainDictionaryEntry.PERMISSION_TYPE_CODE.PERMISSION_SPO_TRANSIT];
      case PermissionTableEnum.SPO_TRANSIT_COPY:
        return [DomainDictionaryEntry.PERMISSION_TYPE_CODE.PERMISSION_SPO_TRANSIT_ABRIDGEMENT];
      case PermissionTableEnum.OWN_NEEDS:
        return [
          DomainDictionaryEntry.PERMISSION_TYPE_CODE.PERMISSION_OWN_NEEDS_PASSENGER_CARRIAGE,
          DomainDictionaryEntry.PERMISSION_TYPE_CODE.PERMISSION_OWN_NEEDS_GOODS_CARRIAGE
        ];
      case PermissionTableEnum.OWN_NEEDS_COPY:
        return [
          DomainDictionaryEntry.PERMISSION_TYPE_CODE.PERMISSION_OWN_NEEDS_PASSENGER_CARRIAGE_ABRIDGEMENT,
          DomainDictionaryEntry.PERMISSION_TYPE_CODE.PERMISSION_OWN_NEEDS_GOODS_CARRIAGE_ABRIDGEMENT
        ];
      case PermissionTableEnum.FOREIGN_PERMISSION:
        return [DomainDictionaryEntry.PERMISSION_TYPE_CODE.FOREIGN_PERMISSION];
      default:
        return [];
    }
  }, [selectedPermissionType]);

  const tableProps = useAvailableFormsTable(
    folderId,
    columns,
    permissionTypeCodes,
    tablePlugins,
    includePartnershipPermissions
  );

  const title = useMemo(() => {
    return permissionTypes.find(({ value }) => value === selectedPermissionType).name;
  }, [permissionTypes, selectedPermissionType]);

  return (
    <TableLayout
      {...tableProps}
      key={key}
      pageTitle={title}
      xlsxDownload={{
        fileName: title,
        pathToXLSXTranslation: 'folder:availableForm.field',
        apiRequest: params => API.folder.getProceedingAvailableFormsPage(folderId, params)
      }}
      rowActions={rowActions}
      isActionColumnEnabled={Boolean(rowActions)}
      actionsColumnWidth={actionsColumnWidth}
      isSection
      isCollapsable
      isRefreshEnabled
    />
  );
}

export default AvailableFormsTable;
