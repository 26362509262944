import { useTranslation } from 'react-i18next';
import { queryCache } from 'react-query';

import { TableLayout, useRouter, useTableRowActions } from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';

import { API } from '@libs/domain/api';
import {
  ApplicationToArchiveSnapshot,
  useSendApplicationsToArchiveDialog,
  useUpdateApplicationWarningDialog
} from '@libs/domain/archive';
import { DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';

import { ArchiveQueryKeysEnum } from '../../../common';
import { useApplicationToArchiveTable } from '../../hooks';

function ApplicationToArchiveTab() {
  const [t] = useTranslation();
  const { openUpdateApplicationWarningDialog } = useUpdateApplicationWarningDialog();
  const { openSendApplicationsToArchiveDialog } = useSendApplicationsToArchiveDialog();

  const { checkIsElementVisible } = useElementVisibility();
  const { routes } = useRouter();

  const tableProps = useApplicationToArchiveTable({
    isMassActionVisible: checkIsElementVisible(DomainUIElementEnum.ARCHIVE_APPLICATIONS_FORWARD_TO_ARCHIVE_MANY_BUTTON),
    classificationKeyDictionary: DomainDictionaryEnum.ARCHIVE_CATEGORY_DESCRIPTION
  });

  const { renderTableActions } = useTableRowActions<ApplicationToArchiveSnapshot>([
    {
      id: DomainUIElementEnum.ARCHIVE_APPLICATIONS_TO_ARCHIVE_SHOW_DETAILS_BUTTON,
      label: 'action.openDetails',
      link: ({ original }) => routes.archiveApplicationDetailsToArchive(original.id),
      icon: 'ArrowIcon'
    },
    {
      id: DomainUIElementEnum.ARCHIVE_APPLICATIONS_FORWARD_TO_ARCHIVE_BUTTON,
      label: 'archive:action.sendToArchive',
      onClick: ({ row: { original } }) => openSendApplicationsToArchiveDialog([original.id]),
      icon: 'TransferIcon'
    },
    {
      id: DomainUIElementEnum.ARCHIVE_APPLICATIONS_TO_ARCHIVE_ADD_NOTE_BUTTON,
      label: 'archive:application.field.warning',
      onClick: ({ row: { original } }) =>
        openUpdateApplicationWarningDialog(
          original.id,
          () => queryCache.invalidateQueries(ArchiveQueryKeysEnum.APPLICATIONS_TO_ARCHIVE),
          original.version,
          original?.warning
        ),
      icon: 'NoteIcon'
    }
  ]);

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('archive:application.applicationToArchive')}
      xlsxDownload={{
        fileName: t('archive:application.applicationToArchive'),
        pathToXLSXTranslation: 'archive:application.field',
        apiRequest: API.archiveApplications.getArchiveApplications
      }}
      rowActions={renderTableActions([
        DomainUIElementEnum.ARCHIVE_APPLICATIONS_TO_ARCHIVE_SHOW_DETAILS_BUTTON,
        DomainUIElementEnum.ARCHIVE_APPLICATIONS_FORWARD_TO_ARCHIVE_BUTTON,
        DomainUIElementEnum.ARCHIVE_APPLICATIONS_TO_ARCHIVE_ADD_NOTE_BUTTON
      ])}
      isSectionFullTab
    />
  );
}

export default ApplicationToArchiveTab;
