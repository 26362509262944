import { useTranslation } from 'react-i18next';

import { Button } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

import { useExploitationBaseSearchAction } from '../../hooks/useExploitationBaseSearchAction';

interface IProps {
  folderId: string;
  transferredFoldersIds?: string[];
  applicationId: string;
  actionKey: UIElementNameEnum;
}

function ExploitationBaseSearchButton({ folderId, transferredFoldersIds, applicationId, actionKey }: IProps) {
  const [t] = useTranslation();

  const { openExploitationBaseSearchDialog } = useExploitationBaseSearchAction({
    folderId,
    transferredFoldersIds,
    applicationId
  });

  return (
    <Button
      type="button"
      variant="outlined"
      onClick={openExploitationBaseSearchDialog}
      label={t('exploitationBase:details.button.searchBase')}
      actionKey={actionKey}
    />
  );
}

export default ExploitationBaseSearchButton;
