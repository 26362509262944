import { useMemo } from 'react';
import { FieldValues, UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ApplicationSnapshot } from '@ibtm/domain';
import { every, isString } from 'lodash';

import {
  Dialog,
  ForwardTableState,
  IExtendedColumn,
  useConfirmDialog,
  useForwardedSelectedRowsAction,
  useIsSmallScreen,
  useMultipleSelectWithDisabledRows
} from '@libs/common/v2';

import { ApplicationQueryKeysEnum, ApplicationsTable, useApplicationDetailsQuery } from '@libs/domain/application';
import { DomainDictionaryEntry } from '@libs/domain/config';

import { useUpdateApplicationMisspellApplicationMutation } from '../api';
import { useMutationConfig } from '../hooks';
import { getApplicationCategoryType } from '../utils/application-misspell.util';

interface IProps {
  applicationId: string;
  closeDialog: () => void;
  getValues?: UseFormGetValues<FieldValues>;
  visibleColumns: IExtendedColumn<ApplicationSnapshot>[];
}

function MispellApplicationSearchApplicationsDialog({
  applicationId,
  closeDialog: closeSearchDialog,
  getValues,
  visibleColumns
}: IProps) {
  const [t] = useTranslation();
  const [confirm] = useConfirmDialog();
  const { isSmallScreen } = useIsSmallScreen();
  const { data, refetch } = useApplicationDetailsQuery(
    applicationId,
    { refetchOnMount: false },
    ApplicationQueryKeysEnum.APPLICATION_MISPELL
  );
  const categoryKey = useMemo(() => {
    return getApplicationCategoryType(data?.typeKey);
  }, [data?.typeKey]);

  const misspellApplicationDataDetails = useMemo(() => {
    return data?.misspellApplicationDataDetails?.applications;
  }, [data?.misspellApplicationDataDetails?.applications]);

  const isDriverCertificatePresent = categoryKey === DomainDictionaryEntry.APPLICATION_CATEGORY.DRIVER_CERTIFICATE;

  const { mutate: addApplications, isLoading } = useUpdateApplicationMisspellApplicationMutation();
  const getMutationConfig = useMutationConfig({ getValues, categoryKey });
  const addApplicationsForCorrection = (selectedRowsIds: string[]) =>
    confirm({
      onConfirm: (setLoading, closeDialog) => {
        setLoading(true);
        const close = () => {
          closeDialog();
          refetch();
          closeSearchDialog();
        };
        addApplications(
          {
            applicationId: data?.id,
            applicationsUpdate: { applications: selectedRowsIds, applicationVersion: data?.version }
          },
          getMutationConfig({ onError: close, onSuccess: close, shouldOnlyUpdateMisspellApplications: true })
        );
      },
      message: t('applications:misspellApplication.dialog.addApplicationsForCorrectionMessage'),
      title: t('applications:misspellApplication.dialog.addApplicationsForCorrectionTitle'),
      shortTitle: t('applications:misspellApplication.dialog.addApplicationsForCorrectionShortTitle')
    });

  const selectRowPlugin = useMultipleSelectWithDisabledRows(
    () => null,
    row => {
      return misspellApplicationDataDetails?.map(application => application?.number)?.includes(row.original.number);
    }
  );

  const { forwardTableStateRef, getSelectedIds, handleDisableAction, isActionDisabled } =
    useForwardedSelectedRowsAction();

  return (
    <Dialog
      title={t('applications:misspellApplication.table.searchApplicationsForCorrection')}
      confirmText={t('action.add')}
      cancelText={t('action.cancel')}
      onConfirm={() => {
        const selectedIds = getSelectedIds();
        addApplicationsForCorrection(every(selectedIds, isString) ? (selectedIds as string[]) : []);
      }}
      onCancel={closeSearchDialog}
      isConfirmButtonDisabled={isActionDisabled}
      isConfirmLoading={isLoading}
      dialogSize={visibleColumns?.length > 2 ? 'large' : 'medium'}
      isOpen
      isFullScreen={isSmallScreen}
    >
      <ApplicationsTable
        tablePlugins={[selectRowPlugin]}
        visibleColumns={visibleColumns}
        isActionColumnEnabled={false}
        isSection
        isHeaderHidden
        queryParams={{
          folderIdIn: [
            data?.folder?.id,
            ...(data?.licenseApplicationDetails?.transferApplicationDataDetails?.folders?.map(folder => folder.id) ||
              [])
          ],
          statusKeyIn: [
            DomainDictionaryEntry.APPLICATION_STATUS.ISSUED,
            DomainDictionaryEntry.APPLICATION_STATUS.PENDING_ISSUE,
            DomainDictionaryEntry.APPLICATION_STATUS.RELEASED_WAITING_FOR_ZPO
          ],
          ...(data?.categoryKey === DomainDictionaryEntry.APPLICATION_CATEGORY.DRIVER_CERTIFICATE && {
            includePrintedDriverCertificates: true
          }),
          ...(data?.typeKey === DomainDictionaryEntry.APPLICATION_TYPE.sCertificateReprintBtm && {
            includeValidScertificates: true
          }),
          ...(categoryKey && { categoryKeyIn: [categoryKey] }),
          ...(isDriverCertificatePresent && { driverCertificatePresent: isDriverCertificatePresent })
        }}
        selectionColumnWidth={48}
        isHiddenSelectRowHeader
      >
        <ForwardTableState ref={forwardTableStateRef} setState={handleDisableAction} />
      </ApplicationsTable>
    </Dialog>
  );
}

export default MispellApplicationSearchApplicationsDialog;
