import { useCallback } from 'react';

import { FormMode, useDialog } from '@libs/common/v2';

import { ReturnTableItemDialog } from '../components';
import { ReturnItemRange } from '../model/ReturnModels';

export interface IHandleAddProps {
  handleSubmit: (item: ReturnItemRange, isAdd: boolean) => void;
  itemsAvailableToAdd: ReturnItemRange[];
}

const useHandleAddItemToReturnTable = ({ itemsAvailableToAdd, handleSubmit }: IHandleAddProps) => {
  const { openDialog } = useDialog();

  const open = useCallback(
    (formMode: FormMode, item?: ReturnItemRange) => {
      openDialog(({ closeDialog }) => (
        <ReturnTableItemDialog
          closeDialog={closeDialog}
          itemsAvailableToAdd={itemsAvailableToAdd}
          handleSubmit={handleSubmit}
          formMode={formMode}
          item={item}
        />
      ));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog]
  );

  return {
    openAddItemDialog: open
  };
};

export default useHandleAddItemToReturnTable;
