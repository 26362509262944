import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { PermanentPermissionSubmissionDetails, PermanentPermissionSubmissionItemDetails } from '@ibtm/domain';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';
import { isNil } from 'lodash';

import {
  BackButton,
  Breadcrumbs,
  Button,
  ButtonsGroup,
  CommonTabHeader,
  FormMode,
  FormV2Context,
  Page,
  useIsSmallScreen,
  useRouter
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DomainDictionaryEntry } from '@libs/domain/config';
import { getResourceTypes } from '@libs/domain/resource/api';
import {
  ApplicationFormSchema,
  modePathEkmt,
  PetitionsTypeEnum,
  SocialCommissionDetailsParams,
  useCreateApplicationEKMTMutation,
  useEditApplicationEKMTMutation,
  useEKTMFormQuery,
  useSocialCommissionBreadcrumps
} from '@libs/domain/social-commission';
import { SocialCommissionTabEnum } from '@libs/domain/social-commission/components/common/model/social-comission.model';

import SocialCommissionEditForm from './SocialCommissionEditForm';

export type PermanentPermissionSubmissionDetailsForm = Omit<PermanentPermissionSubmissionDetails, 'items'> & {
  items: Array<
    PermanentPermissionSubmissionItemDetails & {
      resourceFormNameKey: {
        value?: string;
        name?: string;
        resourceTypeId?: string;
        key: string;
        title: string;
      };
    }
  >;
};

function SocialCommissionTableFormPage({ formMode }: { formMode: FormMode }) {
  const [t] = useTranslation();

  const { id } = useParams<SocialCommissionDetailsParams>();
  const { createMode, viewMode } = useViewModesV2(formMode);
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { applicationDetails } = useSocialCommissionBreadcrumps(modePathEkmt, formMode);
  const { goToPage, routes } = useRouter();
  const { data: editData, refetch } = useEKTMFormQuery(id, { enabled: !isNil(id) });
  const { isSmallScreen } = useIsSmallScreen();

  const [resourceFormNames, setResourceFormNames] = useState(null);
  const [formData, setFormData] = useState<PermanentPermissionSubmissionDetails>(null);

  const form = useForm<Partial<PermanentPermissionSubmissionDetailsForm>>({
    resolver: yupResolver(ApplicationFormSchema())
  });

  const year = form?.watch('year');

  useEffect(() => {
    setResourceNamesFromQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  const setResourceNamesFromQuery = async () => {
    if (year) {
      const response = await getResourceTypes({
        page: 0,
        size: 999,
        sort: ['transbitName,asc'],
        groupKeyFilter: {
          values: [DomainDictionaryEntry.RESOURCE_FORM_GROUP.EKMT]
        },
        yearGreaterThanOrEqual: year,
        yearLessThanOrEqual: year,
        ekmtType: true
      });
      setResourceFormNames(response?.content?.map(item => ({ id: item.id, title: item.transbitName, key: item.name })));
    }
  };

  useEffect(() => {
    if (createMode) {
      const values = form?.getValues();
      setFormData({
        year: !formData?.year ? new Date().getFullYear() : year,
        firstAppeal: false,
        secondAppeal: false,
        items: resourceFormNames?.map(item => ({
          resourceFormNameKey: item.key,
          resourceTypeId: item?.id
        })),
        ...(values?.folder ? { folder: values.folder } : {}),
        ...(values?.subject ? { subject: values.subject } : {})
      });
    } else {
      setFormData(editData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData, createMode, resourceFormNames]);

  const { mutate: applicationEdit, isLoading: isEditLoading } = useEditApplicationEKMTMutation({
    onSuccess: params => {
      showSuccessSnackbar(t('success.save'));
      goToPage(routes.socialCommissionDetails(params?.data?.id));
      refetch();
    }
  });

  const { mutate: applicationCreate, isLoading: isCreateLoading } = useCreateApplicationEKMTMutation({
    onSuccess: params => {
      showSuccessSnackbar(t('success.add'));
      goToPage(routes.socialCommissionDetails(params?.data?.id));
    }
  });

  const isLoading = useMemo(() => isEditLoading || isCreateLoading, [isEditLoading, isCreateLoading]);

  const formDataProvider = useMemo(() => {
    return { ...form, formMode };
  }, [form, formMode]);

  useEffect(() => {
    form.reset(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const convertStringValueToNumber = useCallback((value: number) => {
    return typeof value === 'string' ? Number.parseInt(value, 10) : value;
  }, []);

  const checkIfAllFieldsHaveZeroValue = useCallback(
    (values: Array<PermanentPermissionSubmissionItemDetails>) => {
      return values?.every(({ euro5submitted, euro6submitted }) => {
        const parsedEuro5Submitted: number = convertStringValueToNumber(euro5submitted);
        const parsedEuro6Submitted: number = convertStringValueToNumber(euro6submitted);

        return parsedEuro5Submitted === 0 || parsedEuro6Submitted === 0;
      });
    },
    [convertStringValueToNumber]
  );

  const checkIfAnyFieldHasCorrectValue = useCallback((value: number) => {
    return typeof value === 'string' ? (value as string) !== '0' && (value as string) !== '' : value > 0;
  }, []);

  const checkIfAnyFieldIsFilled = useCallback(
    (values: Array<PermanentPermissionSubmissionItemDetails>) => {
      return values?.some(({ euro5submitted, euro6submitted }) => {
        const convertedEuro5Submitted: number = convertStringValueToNumber(euro5submitted);
        const convertedEuro6Submitted: number = convertStringValueToNumber(euro6submitted);

        return (
          (!isNil(convertedEuro5Submitted) || !isNil(convertedEuro6Submitted)) &&
          (checkIfAnyFieldHasCorrectValue(euro5submitted) || checkIfAnyFieldHasCorrectValue(euro6submitted))
        );
      });
    },
    [convertStringValueToNumber, checkIfAnyFieldHasCorrectValue]
  );

  const parseEuroValue = useCallback((value: number) => {
    if (typeof value === 'string' && value === '') {
      return null;
    }

    return value;
  }, []);

  const submitEdition = useCallback(
    (formValues: Partial<Partial<PermanentPermissionSubmissionDetailsForm>>) => {
      const submitData = {
        permanentSubmissionId: formValues.id ?? formValues.id,
        requestData: {
          folderId: formValues.folder?.id,
          firstAppeal: formValues?.firstAppeal,
          secondAppeal: formValues?.secondAppeal,
          version: formValues?.version,
          year: formValues?.year,
          items: formValues?.items?.map(item => ({
            resourceFormNameKey: item.resourceFormNameKey,
            euro5submitted: parseEuroValue(item.euro5submitted),
            euro5decision: item.euro5decision,
            euro6submitted: parseEuroValue(item.euro6submitted),
            euro6decision: item.euro6decision,
            id: item?.id,
            resourceTypeId: item?.resourceTypeId
          }))
        }
      };

      const formValuesItems = submitData?.requestData?.items;

      const hasFormAllFieldsWithZeroValue = checkIfAllFieldsHaveZeroValue(formValuesItems);
      const hasAnyFieldValue = checkIfAnyFieldIsFilled(formValuesItems);

      if (formValuesItems?.length > 0 && hasFormAllFieldsWithZeroValue) {
        showErrorSnackbar(
          t('foreignPermits:tabs.socialCommission.tabs.socialCommissionList.messages.resourceTypesNeedCorrectValue')
        );
        return;
      }

      if (!hasAnyFieldValue) {
        showErrorSnackbar(
          createMode
            ? t('foreignPermits:tabs.socialCommission.tabs.socialCommissionList.messages.resourceTypesRequired')
            : t('foreignPermits:tabs.socialCommission.tabs.socialCommissionList.messages.resourceTypesRequiredOnEdit')
        );
        return;
      }

      if (createMode) {
        applicationCreate(submitData.requestData);
      } else {
        applicationEdit(submitData);
      }
    },
    [
      checkIfAllFieldsHaveZeroValue,
      checkIfAnyFieldIsFilled,
      showErrorSnackbar,
      createMode,
      t,
      applicationCreate,
      applicationEdit,
      parseEuroValue
    ]
  );

  return (
    <FormV2Context.Provider value={formDataProvider}>
      <Page
        header={
          <div className="mx-10 mt-10">
            {viewMode ? (
              <>
                <Breadcrumbs items={applicationDetails} />
                <CommonTabHeader
                  actions={
                    <ButtonsGroup>
                      <BackButton
                        onClick={() =>
                          goToPage(
                            {
                              pathname: routes.socialCommissionsList(),
                              search: `?tab=${SocialCommissionTabEnum.PETITIONS}`
                            },
                            {
                              state: { type: PetitionsTypeEnum.EKMT }
                            }
                          )
                        }
                        isNoMargin
                      />
                      <Button
                        onClick={() => goToPage(routes.socialCommissionEdit(id))}
                        label={t('action.edit')}
                        isSecondary
                        variant="outlined"
                        actionKey={UIElementNameEnum.COMMISSION_EKMT_EDIT_BUTTON}
                        isNoMargin
                      />
                    </ButtonsGroup>
                  }
                  title={t(`${modePathEkmt}.${formMode}.title`)}
                />
              </>
            ) : (
              <>
                <Breadcrumbs items={applicationDetails} />
                <CommonTabHeader
                  actions={
                    <ButtonsGroup>
                      <Button
                        onClick={() =>
                          goToPage(
                            {
                              pathname: routes.socialCommissionsList(),
                              search: `?tab=${SocialCommissionTabEnum.PETITIONS}`
                            },
                            {
                              state: { type: PetitionsTypeEnum.EKMT }
                            }
                          )
                        }
                        label={t('action.cancel')}
                        variant="outlined"
                        isNoMargin
                        isSecondary
                      />
                      <Button
                        onClick={form.handleSubmit(submitEdition)}
                        label={t('action.save')}
                        isLoading={isLoading}
                      />
                    </ButtonsGroup>
                  }
                  title={t(`${modePathEkmt}.${formMode}.title`)}
                />
              </>
            )}
          </div>
        }
        contentClassName="flex-col"
        content={<SocialCommissionEditForm formMode={formMode} />}
        isContentScrollEnabled={!isSmallScreen}
      />
    </FormV2Context.Provider>
  );
}

export default SocialCommissionTableFormPage;
