import { useTranslation } from 'react-i18next';
import { CardContent as MuiCardContent } from '@mui/material';

import { Typography } from '../typography';

interface IProps {
  text?: string;
}

function CardContent({ text }: IProps) {
  const [t] = useTranslation();

  return (
    <MuiCardContent className="w-full">
      <div className="flex items-center justify-center w-full">
        <Typography>{text || t('error.noData')}</Typography>
      </div>
    </MuiCardContent>
  );
}

export default CardContent;
