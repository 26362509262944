import { useTableLayoutProps } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';
import { useFolderColumns, useFoldersListQuery } from '@libs/domain/folder';

function useSelectPartnerTable() {
  const { columns, mappedFilterFields, mappedSortFields } = useFolderColumns([
    'number',
    'subjectName',
    'subjectNip',
    'statusKey',
    'locked',
    'primaryAddress',
    'created',
    'authorName',
    'modified',
    'modifierName'
  ]);

  return useTableLayoutProps({
    tableHookQuery: useFoldersListQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tableOptions: {
        initialState: {
          sortBy: [
            {
              id: 'subjectName',
              desc: false
            }
          ]
        }
      }
    },
    tableHookQueryInitialParams: {
      typeKeyIn: [DomainDictionaryEntry.FOLDER_TYPE.MZ, DomainDictionaryEntry.FOLDER_TYPE.OP]
    }
  });
}

export default useSelectPartnerTable;
