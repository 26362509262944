import { useTranslation } from 'react-i18next';
import { GroupMembershipDetailsExternal } from '@avispon/group/dist/models';

import { FormMode } from '@libs/common/v2';

import { ReplacementsSnapshot } from '@libs/user/models';

export const useReplacementsMode = (mode: FormMode, rowValues?: ReplacementsSnapshot) => {
  const [t] = useTranslation();

  const replacementsTranslation = () => {
    switch (mode) {
      case FormMode.VIEW:
        return {
          title: t('user:replacements.details'),
          confirm: t('action.confirm'),
          cancel: t('action.close')
        };
      case FormMode.EDIT:
        return {
          title: t('user:replacements.action.editReplacement'),
          confirm: t('action.confirm'),
          cancel: t('action.cancel')
        };
      default:
        return {
          title: t('user:replacements.action.addReplacement'),
          confirm: t('action.add'),
          cancel: t('action.cancel')
        };
    }
  };

  const preparedFormData = (userGroupsData: GroupMembershipDetailsExternal[]) => {
    switch (mode) {
      case FormMode.VIEW:
        return rowValues
          ? {
              startDateTime: rowValues.startDateTime,
              endDateTime: rowValues.endDateTime,
              substituteAccountId: rowValues?.substitute?.name,
              groupMembershipsIds:
                rowValues.groupMemberships?.map(item => {
                  const searchedGroup = userGroupsData?.find(group => item.group.name === group.group.name);

                  return {
                    id: searchedGroup?.id,
                    value: searchedGroup?.group.name,
                    name: searchedGroup?.group.name
                  };
                }) || []
            }
          : {};

      case FormMode.EDIT:
      default:
        return rowValues
          ? {
              startDateTime: rowValues.startDateTime,
              endDateTime: rowValues.endDateTime,
              substituteAccountId: {
                name: rowValues.substitute.name,
                id: rowValues.substitute.id,
                value: rowValues.substitute.name
              },
              groupMembershipsIds: rowValues.groupMemberships?.map(item => {
                const searchedGroup = userGroupsData?.find(group => item.group.name === group.group.name);

                return {
                  id: searchedGroup?.id,
                  value: searchedGroup?.group.name,
                  name: searchedGroup?.group.name
                };
              })
            }
          : {};
    }
  };

  return { replacementsTranslation, preparedFormData };
};
