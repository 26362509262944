import { NoteUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function updateProceedingNote({
  proceedingId,
  noteUpdateRequest
}: {
  proceedingId: string;
  noteUpdateRequest: NoteUpdateRequest;
}) {
  return API.proceedingNote.createOrUpdateProceedingNote(proceedingId, noteUpdateRequest, {
    ...endpointsConfig.createOrUpdateProceedingNote
  });
}

function useUpdateProceedingNoteMutation() {
  return useMutation(updateProceedingNote);
}

export default useUpdateProceedingNoteMutation;
