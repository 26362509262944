import { useCallback } from 'react';

import { useDialog } from '@libs/common/v2';

import { IUpdatePermissionCopyRequest, PermissionCopyEditDialog } from '../../components/PermissionCopyEditDialog';

export function usePermissionCopyEditDialog() {
  const { openDialog } = useDialog();

  const showDialog = useCallback(
    (initialData: IUpdatePermissionCopyRequest, refetch: () => void, titleKey: string) => {
      openDialog(({ closeDialog }) => (
        <PermissionCopyEditDialog
          closeDialog={closeDialog}
          initialData={initialData}
          refetch={refetch}
          titleKey={titleKey}
        />
      ));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog]
  );

  const openEditPermissionCopyDialog = (
    initialData: IUpdatePermissionCopyRequest,
    refetch: () => void,
    titleKey: string
  ) => {
    showDialog(initialData, refetch, titleKey);
  };

  return {
    openEditPermissionCopyDialog
  };
}
