import { QueryConfig, useQuery } from 'react-query';
import { GroupDetailsExternal } from '@avispon/group/dist/models';

import API from '../endpoints';
import { endpointsConfig } from '../endpoints-config';

import { GroupQueryKeysEnum } from './QueryKeysEnum';

const getGroup = async (_, params: { id: string }) => {
  const { data } = await API.group.get(
    { groupId: params.id },
    {
      ...endpointsConfig.get
    }
  );
  return data;
};

export default function useGroupDetailsQuery(
  params: { id: string },
  queryConfig: QueryConfig<GroupDetailsExternal> = {}
) {
  return useQuery<GroupDetailsExternal>([GroupQueryKeysEnum.GROUP_DETAILS, params], getGroup, queryConfig);
}
