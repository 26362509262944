import { usePaginatedQuery } from 'react-query';
import { DefaultApiListPermissionsForGroupRequest } from '@avispon/group';

import { parsePermissionGroups } from '../../utils/users.parsers';
import { API, endpointsConfig } from '..';

import { UserQueryKeysEnum } from './QueryKeysEnum';

export const getListPermissionsForGroup = async (params: DefaultApiListPermissionsForGroupRequest) => {
  const { data } = await API.group.listPermissionsForGroup(params, { ...endpointsConfig.listPermissionsForGroup });

  return {
    ...data,
    content: parsePermissionGroups(data.content)
  };
};

export default function useListPermissionsForGroupQuery(params: DefaultApiListPermissionsForGroupRequest) {
  return usePaginatedQuery([UserQueryKeysEnum.LIST_PERMISSIONS_FOR_GROUP, params], () =>
    getListPermissionsForGroup(params)
  );
}
