import { QueryConfig, useQuery } from 'react-query';
import { OrganizationUnitSnapshotExternal } from '@avispon/organization-structure/dist/models';

import API from '../endpoints';
import { endpointsConfig, OrganizationQueryKeysEnum } from '..';

const getOrganizationUnit = async (_: any, id: string) => {
  const { data } = await API.organizationStructure.getOrganizationUnit(
    { id },
    {
      ...endpointsConfig.getOrganizationUnit
    }
  );
  return data;
};

export default function useOrganizationStructureDetailsQuery(
  params: { id: string },
  queryConfig: QueryConfig<OrganizationUnitSnapshotExternal> = {}
) {
  return useQuery<OrganizationUnitSnapshotExternal>(
    [OrganizationQueryKeysEnum.ORGANIZATION_UNIT_DETAILS, params.id],
    getOrganizationUnit,
    queryConfig
  );
}
