import { useTranslation } from 'react-i18next';
import { AccountsApiAnonymizeAccountRequest } from '@avispon/user';
import { useSnackbar } from '@enigma/fe-ui';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '..';

const anonymizeUser = (params: AccountsApiAnonymizeAccountRequest) => {
  return API.user.accountController.anonymizeAccount({ ...params }, { ...endpointsConfig.anonymizeAccount });
};
function useAnonymizeMutation(
  refetchUserDetails: () => void,
  handleClose: () => void,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
) {
  const [t] = useTranslation();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  return useMutation(anonymizeUser, {
    onSuccess: () => {
      showSuccessSnackbar(t('user:message.anonymize'));
      refetchUserDetails();
      setIsOpen(false);
      handleClose();
    },
    onError: () => {
      showErrorSnackbar(t('user:error.anonymize'));
      refetchUserDetails();
      setIsOpen(false);
      handleClose();
    }
  });
}

export default useAnonymizeMutation;
