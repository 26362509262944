import { useTranslation } from 'react-i18next';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  DictionarySelectField,
  GridLayout,
  InputMode,
  Section,
  SwitchField,
  TextInputField,
  typedNameV2,
  useFormV2Watch
} from '@libs/common/v2';
import { convertDateToDateFormat } from '@libs/common/v2/utils';

import { DomainDictionaryEntry, DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';

import { IProceedingDetailsClient } from '../../../models';
import { isFieldVisible } from '../../../utils';

interface IProps {
  hiddenFields?: Array<
    | 'startDate'
    | 'startDecisionDeliveryDate'
    | 'startStartedBy'
    | 'startInactivityReason'
    | 'startRehabilitationResource'
    | 'startOtherInactivityReason'
  >;
}

function StartSection({ hiddenFields = [] }: IProps) {
  const [t] = useTranslation();
  const getTranslation = partialTranslate('roadTransport:fields');

  const startDateValue = useFormV2Watch({ name: 'startDate' });
  const startInactivityReasonValue = useFormV2Watch({ name: 'startInactivityReason' });

  const isStartOtherInactivityReasonVisible =
    !isFieldVisible('startInactivityReason', hiddenFields) ||
    (startInactivityReasonValue === DomainDictionaryEntry.GOOD_REPUTATION_KREPTD_STATUS.INACTIVITY_REASON_OTHER &&
      isFieldVisible('startOtherInactivityReason', hiddenFields));

  return (
    <Section
      title={t('roadTransport:sections.start')}
      actionKey={DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_START_SECTION}
      isCollapsable
    >
      <GridLayout itemProps={{ xs: 6 }}>
        {isFieldVisible('startDate', hiddenFields) && (
          <DatepickerField
            name={typedNameV2<IProceedingDetailsClient>('startDate')}
            label={getTranslation('startDate')}
            viewModeDateParser={convertDateToDateFormat}
          />
        )}

        {isFieldVisible('startDecisionDeliveryDate', hiddenFields) && (
          <DatepickerField
            name={typedNameV2<IProceedingDetailsClient>('startDecisionDeliveryDate')}
            label={getTranslation('startDecisionDeliveryDate')}
            viewModeDateParser={convertDateToDateFormat}
          />
        )}
        {isFieldVisible('startStartedBy', hiddenFields) && (
          <TextInputField
            name={typedNameV2<IProceedingDetailsClient>('startStartedBy')}
            label={getTranslation('startStartedBy')}
            inputMode={InputMode.VIEW}
          />
        )}
        {isFieldVisible('startInactivityReason', hiddenFields) && (
          <DictionarySelectField
            name={typedNameV2<IProceedingDetailsClient>('startInactivityReason')}
            label={getTranslation('startInactivityReason')}
            dictionaryName={DomainDictionaryEnum.GOOD_REPUTATION_KREPTD_STATUS}
            optionsFilter={option => {
              return [
                DomainDictionaryEntry.GOOD_REPUTATION_KREPTD_STATUS.NO_PROPERTIES,
                DomainDictionaryEntry.GOOD_REPUTATION_KREPTD_STATUS.REFUND,
                DomainDictionaryEntry.GOOD_REPUTATION_KREPTD_STATUS.INCORRECT_QUALIFICATION,
                ...(isFieldVisible('startOtherInactivityReason', hiddenFields)
                  ? [DomainDictionaryEntry.GOOD_REPUTATION_KREPTD_STATUS.INACTIVITY_REASON_OTHER]
                  : [])
              ].includes(option.value);
            }}
            stringValue
            isDisabled={!!startDateValue}
            tooltip={startDateValue ? t('roadTransport:tooltip.startInactivityReasonFieldDisabled') : null}
          />
        )}

        {isFieldVisible('startRehabilitationResource', hiddenFields) && (
          <SwitchField
            name={typedNameV2<IProceedingDetailsClient>('startRehabilitationResource')}
            label={getTranslation('startRehabilitationResource')}
          />
        )}
        {isStartOtherInactivityReasonVisible && (
          <TextInputField
            name={typedNameV2<IProceedingDetailsClient>('startOtherInactivityReason')}
            label={getTranslation('startOtherInactivityReason')}
            lines={5}
          />
        )}
      </GridLayout>
    </Section>
  );
}

export default StartSection;
