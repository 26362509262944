import { useCallback, useState } from 'react';

import { TableEvent } from '../context';

import { useSubscribeTableEvent } from './useSubscribeTableEvent';

export const useRerenderOnTableEvent = ({ event, isActive = true }: { event: TableEvent; isActive?: boolean }) => {
  const [, setRerender] = useState(true);

  const onEvent = useCallback(() => setRerender(prev => !prev), []);

  useSubscribeTableEvent({ event, onEvent, isActive });
};
