import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function suspendPermit(proceedingId: string) {
  return API.proceedingsKreptd.proceedingKreptdPermitSuspension(proceedingId, {
    ...endpointsConfig.proceedingKreptdPermitSuspension
  });
}

function usePermitSuspensionMutation() {
  return useMutation(suspendPermit);
}

export default usePermitSuspensionMutation;
