import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { DriverDetails } from '@ibtm/domain';
import { useAppConfig } from '@libs/app-config';
import moment from 'moment';
import * as Yup from 'yup';

import { i18n } from '@libs/common';
import {
  ALTERNATIVE_DATE_FORMAT,
  DatepickerField,
  Dialog,
  FormV2Context,
  GridLayout,
  typedNameV2
} from '@libs/common/v2';
import { convertDateToDateFormat, getCalendarDate } from '@libs/common/v2/utils';

import { useGenerateApplicationDocumentMutation } from '@libs/domain/application';
import { DomainDictionaryEntry } from '@libs/domain/config';
import { DocumentsQueryKeysEnum, useGenerateApplicationDocumentDialog } from '@libs/domain/documents';
import { useExpireMutation } from '@libs/domain/drivers/api';

import { DriverCertificateSnapshotClient } from '../../../driver-certificate';

interface IProps {
  driverData?: DriverDetails;
  closeDialog?: () => void;
  driverApplicationId?: string;
  onSuccess?: () => void;
}

function ExpireForm({ driverData, closeDialog, driverApplicationId, onSuccess }: IProps) {
  const validation = Yup.object({
    cancellationDate: Yup.string().nullable().required(i18n.t('validation:required'))
  });

  const [t] = useTranslation();
  const { mutate: expire, isLoading: isConfirmLoading } = useExpireMutation();
  const { showSuccessSnackbar } = useSnackbar();
  const { templateNames } = useAppConfig();

  const { openGenerateDocumentDialog } = useGenerateApplicationDocumentDialog({
    generateDocumentMutation: useGenerateApplicationDocumentMutation,
    apiParams: { applicationId: driverApplicationId },
    listQueryKey: DocumentsQueryKeysEnum.DOCUMENTS_LIST
  });

  const handleGenerateExpireReport = (title: string, applicationId: string) => {
    openGenerateDocumentDialog({
      title,
      groupKey: DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.OTHER,
      documentTemplateName: templateNames?.expirationsGitd,
      isDocumentDownloadingAfterGenerate: true,
      isFieldsViewOnly: true,
      params: applicationId ? { applicationId } : {}
    });
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    reset
  } = useForm<Partial<DriverCertificateSnapshotClient>>({
    mode: 'onBlur',
    resolver: yupResolver(validation),
    defaultValues: {
      cancellationDate: moment().format(ALTERNATIVE_DATE_FORMAT)
    }
  });

  useEffect(() => {
    reset({ driver: driverData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    const { cancellationDate, driver } = getValues();

    expire(
      {
        driverId: driver.id,
        expireDriverRequest: {
          cancellationDate: getCalendarDate(cancellationDate),
          version: driver.version
        }
      },
      {
        onSuccess: driverCertificate => {
          handleGenerateExpireReport(
            t('document:dialog.generateDocumentTitle', { templateGroup: templateNames?.expirationsGitd }),
            driverCertificate?.data?.application?.id
          );
          showSuccessSnackbar(t('drivers:messages.expireSuccess'));
          onSuccess();
        }
      }
    );
  };

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('drivers:expire.title')}
      confirmText={t('action.expire')}
      cancelText={t('action.cancel')}
      onConfirm={handleSubmit(onSubmit)}
      onCancel={closeDialog}
      dialogSize="small"
      isConfirmLoading={isConfirmLoading}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <GridLayout itemProps={{ xs: 12 }}>
          <DatepickerField
            format={ALTERNATIVE_DATE_FORMAT}
            label={t('drivers:fields.driverCertificate.cancellationDate')}
            name={`${typedNameV2<DriverCertificateSnapshotClient>('cancellationDate')}`}
            viewModeDateParser={convertDateToDateFormat}
          />
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default ExpireForm;
