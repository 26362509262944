import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { useConfirmDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { convertDateToDateFormat } from '@libs/common/v2/utils';

import { getMetaFormQueryKey } from '@libs/meta-form/services/useGetQuery';

import { FinancialSecurityQueryKeysEnum, useFinancialSecurityExpireMutation } from '../../api';

function useFinancialSecurityExpireAction() {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const [confirm] = useConfirmDialog();
  const { showSuccessSnackbar } = useSnackbar();

  const { mutate: expireFinanceSecurity } = useFinancialSecurityExpireMutation();

  const handleFinancialSecurityExpire = ({
    financialSecurityId,
    version,
    expirationDate,
    onSuccess,
    onSettled
  }: {
    financialSecurityId: string;
    version: number;
    expirationDate: string;
    onSuccess: () => void;
    onSettled: () => void;
  }) => {
    confirm({
      title: t('financialSecurity:action.expire.dialogTitle'),
      shortTitle: t('financialSecurity:action.expire.dialogShortTitle'),
      message: t('financialSecurity:action.expire.confirmationText', {
        expirationDate: convertDateToDateFormat(expirationDate)
      }),
      confirmType: 'danger',
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        expireFinanceSecurity(
          { financialSecurityId, version },
          {
            onSuccess: () => {
              queryCache.invalidateQueries(FinancialSecurityQueryKeysEnum.FINANCIAL_SECURITY_LIST);
              queryCache.invalidateQueries(FinancialSecurityQueryKeysEnum.FINANCIAL_SECURITY_SUMMARY);
              // queryKey generowany przez metaform
              queryCache.invalidateQueries(getMetaFormQueryKey('GET_FINANCIAL_SECURITY_SUMMARY'));

              showSuccessSnackbar(
                t('financialSecurity:action.expire.successMessage', {
                  expirationDate: convertDateToDateFormat(expirationDate)
                })
              );
              onSuccess();
              closeDialog();
            },
            onSettled: () => {
              onSettled();
              setConfirmLoading(false);
            }
          }
        );
      }
    });
  };

  return { handleFinancialSecurityExpire };
}

export default useFinancialSecurityExpireAction;
