import { QueryConfig, useQuery } from 'react-query';

import { API, endpointsConfig, ReportQueryKeysEnum } from '..';

const getFieldTypes = async () => {
  const { data } = await API.definitions.getDefinitionFieldTypes({ ...endpointsConfig.getFieldTypes });
  const parsedData = data.items.map(fieldType => {
    return { name: fieldType.type, value: fieldType.type };
  });
  return parsedData;
};

export default function useFieldTypesQuery(_?, queryConfig?: QueryConfig<any>) {
  return useQuery<any>([ReportQueryKeysEnum.FIELD_TYPES], getFieldTypes, queryConfig);
}
