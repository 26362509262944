import { QueryConfig, useQuery } from 'react-query';
import { SubjectAddressSnapshotList } from '@ibtm/client-domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';
import { FolderQueryKeysEnum } from '..';

const getFolderAddresses = async () => {
  const { data } = await API.client.folder.getFolderAddresses('', '', {
    ...endpointsConfig.getFolderAddresses
  });

  return data;
};

export default function useClientFolderAddressesQuery(queryConfig: QueryConfig<any, unknown> = {}) {
  return useQuery<SubjectAddressSnapshotList>(
    [FolderQueryKeysEnum.FOLDER_CLIENT_ADDRESSES],
    getFolderAddresses,
    queryConfig
  );
}
