import { AccountsApiSuspendAccountRequest } from '@avispon/user';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '..';

const suspendUser = (params: AccountsApiSuspendAccountRequest) => {
  return API.user.accountController.suspendAccount({ ...params }, { ...endpointsConfig.suspendAccount });
};

function useSuspendUserMutation() {
  return useMutation(suspendUser);
}

export default useSuspendUserMutation;
