import { ArchivePackageGenerationRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export function generateArchivePackage(params: ArchivePackageGenerationRequest) {
  return API.archiveApplications.generateArchiveApplicationPackage(params, {
    ...endpointsConfig.generateArchivePackage
  });
}

export function useGenerateArchivePackageMutation() {
  return useMutation(generateArchivePackage);
}
