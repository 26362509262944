import { useTranslation } from 'react-i18next';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  DictionarySelectField,
  GridLayout,
  Section,
  SwitchField,
  TextInputField,
  typedNameV2,
  useFormV2Watch,
  useViewModesV2
} from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { MisspellApplicationDataDriverCertificateFormValues } from '../../model/misspell-application.model';

const getLabel = partialTranslate('drivers:fields');

export function DriverLicenseSection() {
  const [t] = useTranslation();
  const qualifications = Boolean(
    useFormV2Watch({
      name: typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('qualification')
    })
  );
  const { viewMode } = useViewModesV2();
  const areQualificationsVisible = viewMode || qualifications;
  return (
    <Section title={t('drivers:sections.driverLicense')} isCollapsable isModalSection>
      <GridLayout itemProps={{ xs: 12, sm: 6 }}>
        <DictionarySelectField
          name={typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('driverLicense.issuingCountryKey')}
          label={getLabel('driverLicense.issuingCountryKey')}
          dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
          validationStringValueSingleSelect={{ required: [] }}
          isRequired
          stringValue
        />
        <TextInputField
          inputProps={{ maxLength: 20 }}
          name={typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('driverLicense.seriesNumber')}
          label={getLabel('driverLicense.seriesNumber')}
          validation={{ required: [] }}
        />
        <DatepickerField
          name={typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('driverLicense.issuingDate')}
          label={getLabel('driverLicense.issuingDate')}
          validation={{ required: [] }}
        />
        <SwitchField
          name={typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('qualification')}
          label={getLabel('driverLicense.qualifications')}
        />
        {areQualificationsVisible && (
          <DictionarySelectField
            name={typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('driverCompetenceCodeKey')}
            label={getLabel('driverLicense.code')}
            dictionaryName={DomainDictionaryEnum.COMPETENCE}
            validationSingleSelect={{ required: [] }}
          />
        )}
        {areQualificationsVisible && (
          <DatepickerField
            name={typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('driverExpiryDateCompetenceCard')}
            label={getLabel('driverLicense.expiryDateCompetenceCard')}
            isDisabled={!qualifications}
            isClearable
          />
        )}
      </GridLayout>
    </Section>
  );
}
