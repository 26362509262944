import { FieldPath, FieldValues } from 'react-hook-form';
import { Props as InputMaskProps } from 'react-input-mask';

import { KeyType, partialTranslate } from '@libs/common/i18n';
import { InputMode } from '@libs/common/v2/form';
import { convertDateToDateTimeFormat } from '@libs/common/v2/utils';

import { DictionaryEntryNameEnum, DictionaryQuickChangeableEntryNameEnum } from '@libs/dictionary';

import {
  BooleanFieldValidation,
  DatePickerFieldValidation,
  NumberFieldValidation,
  SelectFieldValidation,
  SelectMultipleFieldValidation,
  TextFieldValidation
} from '../validation';
import {
  DatepickerField,
  DictionarySelectField,
  NumberInputField,
  QuickChangeableDictionarySelectField,
  SwitchField,
  TextInputField
} from '..';

type FieldCommon<T> = {
  id: keyof T;
  inputMode?: keyof typeof InputMode;
  validation?: {
    required?: boolean;
  };
  isDisabled?: boolean;
};

type TextField<T> = FieldCommon<T> & {
  type: 'TEXT';
  validation?: {
    minLength?: number;
    maxLength?: number;
  };
  /**
   * Liczba wierszy jeśli Textarea
   */
  lines?: number;
  /**
   * Walidacja yupowa, aby z niej korzystać należy użyć useValidationBuilder i przekazać zwrócone metody oraz scheme do * formV2Context
   */
  yupValidation?: TextFieldValidation;
  inputMask?: InputMaskProps;
};

type NumberField<T> = FieldCommon<T> & {
  type: 'NUMBER';
  validation?: {
    minLength?: number;
    maxLength?: number;
    /**
     * Minimalna wartość
     */
    min?: number;
    /**
     * Maksymalna wartość
     */
    max?: number;
  };
  /**
   * Walidacja yupowa, aby z niej korzystać należy użyć useValidationBuilder i przekazać zwrócone metody oraz scheme do * formV2Context
   */
  yupValidation?: NumberFieldValidation;
};

type DictionaryField<T> = FieldCommon<T> & {
  type: 'DICTIONARY';
  disabled?: boolean;
  dictionaryName: DictionaryEntryNameEnum;
  /**
   * Walidacja yupowa, aby z niej korzystać należy użyć useValidationBuilder i przekazać zwrócone metody oraz scheme do * formV2Context
   */
  yupValidationSingleSelect?: SelectFieldValidation;
  yupValidationMultipleSelect?: SelectMultipleFieldValidation;
  isMultiple?: boolean;
};

type DictionaryQuickchangeField<T> = FieldCommon<T> & {
  type: 'DICTIONARY_QUICKCHANGE';
  dictionaryName: DictionaryQuickChangeableEntryNameEnum;
};

type DatetimeField<T> = FieldCommon<T> & {
  type: 'DATETIME';
  validation?: {
    /**
     * Data minimalna
     */
    min?: Date;
    /**
     * Data maksymalna
     */
    max?: Date;
  };
  /**
   * Walidacja yupowa, aby z niej korzystać należy użyć useValidationBuilder i przekazać zwrócone metody oraz scheme do * formV2Context
   */
  yupValidation?: DatePickerFieldValidation;
};

type BooleanField<T> = FieldCommon<T> & {
  type: 'BOOLEAN';
  yupValidation?: BooleanFieldValidation;
};

type Fields<T> = Array<
  | TextField<T>
  | NumberField<T>
  | DictionaryField<T>
  | DictionaryQuickchangeField<T>
  | DatetimeField<T>
  | BooleanField<T>
>;

export default function useFields<T>(
  fields: Fields<T>,
  options: { translationPath: KeyType; hiddenFieldIds?: Array<keyof T> }
) {
  const getLabel = partialTranslate(options.translationPath);

  const renderField = (
    fieldId: keyof T,
    properties?: {
      /**
       * Wartość określająca typ input
       * 'VIEW', 'FORM' lub 'INVERTED' (odwrotny do typu określonego w FormContext)
       */
      inputMode?: keyof typeof InputMode;
      /**
       * Czy label pokazywany jest w trybie inline (po lewej stronie switch-a)
       */
      isInlineLabel?: boolean;
      key?: any;
    }
  ) => {
    const field = fields.find(field => field.id === fieldId);
    const { inputMode, isDisabled } = field ?? {};
    const { key } = properties ?? {};
    const isFieldHidden = options?.hiddenFieldIds ? options.hiddenFieldIds.includes(field?.id) : false;

    if (field && !isFieldHidden) {
      switch (field.type) {
        case 'TEXT':
          return (
            <TextInputField
              name={fieldId as FieldPath<FieldValues>}
              label={getLabel(fieldId as never)}
              inputMode={(properties?.inputMode || inputMode) as InputMode}
              isRequired={field?.validation?.required}
              inputProps={{
                minLength: field?.validation?.minLength,
                maxLength: field?.validation?.maxLength
              }}
              inputMask={field?.inputMask}
              lines={field?.lines}
              key={key}
              validation={field?.yupValidation}
              isDisabled={isDisabled}
            />
          );
        case 'NUMBER':
          return (
            <NumberInputField
              name={fieldId as FieldPath<FieldValues>}
              label={getLabel(fieldId as never)}
              inputMode={(properties?.inputMode || inputMode) as InputMode}
              isRequired={field?.validation?.required}
              min={field?.validation?.min}
              max={field?.validation?.max}
              key={key}
              validation={field?.yupValidation}
              isDisabled={isDisabled}
            />
          );
        case 'DICTIONARY_QUICKCHANGE':
          return (
            <QuickChangeableDictionarySelectField
              name={fieldId as FieldPath<FieldValues>}
              label={getLabel(fieldId as never)}
              dictionaryName={field?.dictionaryName}
              inputMode={(properties?.inputMode || inputMode) as InputMode}
              isRequired={field?.validation?.required}
              isDisabled={isDisabled}
            />
          );
        case 'DICTIONARY':
          return (
            <DictionarySelectField
              name={fieldId as FieldPath<FieldValues>}
              label={getLabel(fieldId as never)}
              dictionaryName={field?.dictionaryName}
              inputMode={(properties?.inputMode || inputMode) as InputMode}
              isRequired={field?.validation?.required}
              isDisabled={field.disabled || isDisabled}
              validationSingleSelect={field.yupValidationSingleSelect}
              validationMultipleSelect={field.yupValidationMultipleSelect}
              isMultiple={field?.isMultiple}
            />
          );
        case 'DATETIME':
          return (
            <DatepickerField
              name={fieldId as FieldPath<FieldValues>}
              label={getLabel(fieldId as never)}
              viewModeDateParser={convertDateToDateTimeFormat}
              inputMode={(properties?.inputMode || inputMode) as InputMode}
              isRequired={field?.validation?.required}
              minDate={field?.validation?.min}
              maxDate={field?.validation?.max}
              validation={field?.yupValidation}
              isDisabled={isDisabled}
            />
          );
        case 'BOOLEAN':
          return (
            <SwitchField
              name={fieldId as FieldPath<FieldValues>}
              label={!properties?.isInlineLabel ? getLabel(fieldId as never) : null}
              inlineLabel={properties?.isInlineLabel ? getLabel(fieldId as never) : null}
              inputMode={(properties?.inputMode || inputMode) as InputMode}
              isRequired={field?.validation?.required}
              validation={field?.yupValidation}
              isDisabled={isDisabled}
            />
          );
        default:
          return null;
      }
    }
    return null;
  };

  return { renderField };
}
