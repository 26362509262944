import { useCallback, useMemo, useState } from 'react';
import { ObjectSchema } from 'yup';

import { ValidationProperties } from '../model';
import createValidationRegistry from '../registry/validation-registry';
import { validationSchemaBuilder } from '../validation-builder';

import useGetContextValidators from './useGetContextValidators';

/**
 * @param extendScheme opcjonalny parametr, przekazujemy schemę jako wartość początkową,
 * która zostanie rozszerzona o walidacje przekazane w props fieldów, jeżeli przekażemy walidacje do inputa który
 * był już zdefiniowany w "extendScheme", to obe walidacje zostaną połączone
 * @returns
 * @param validationBuilderFunctions(funkcje którymi fieldy przekazują zdefiniowane walidacje do Buildera) - przekazujemy do value FormV2Context
 * @param validationScheme scheme który zostanie wygenerowany na podstawie walidaji przekazanych w props inputów i opcjonalnego     inicjującego scheme
 *
 * @example
 * const { validationScheme, validationBuilderFunctions }= useValidationBuilder(SchemeToEdxtend)
 * const formMethods = useForm<Record<string, any>>({
 *   mode: 'onBlur',
 *   criteriaMode: 'all',
 *   resolver: yupResolver(validationScheme)
 * });
 *
 * <FormV2Context.Provider value={{...formMethods,validationBuilderFunctions}}></FormV2Context.Provider>
 *
 * W fieldach mamy otypowany props validation do którego przekazujemy obiekt którego kluczami są nazwy metod walidacji z Yup, parametry )* przekazujemy w tablicy
 *
 * @example
 * <TextInputField name="surname" validation={{required:[], max:[10], min:[3]}}/>
 *
 * w TexInputField validation mamy
 * @param contextValidation do którego przekazujemy enum walidacji backendowych ValidatorEnums
 *
 * @example
 * <TextInputField name="surname" validation={{ contextValidation:[ValidatorEnums.EMAIL]}}/>
 *
 * w razie potrzeby dodatkowe funkcje walidacji można dodawać w validation-registry.ts w analogiczny sposób jak contextValidation
 *
 */

const useValidationBuilder = (extendScheme?: ObjectSchema) => {
  const [validation, setValidation] = useState<Array<ValidationProperties>>([]);
  const setFieldValidation = useCallback((fieldValidation: ValidationProperties) => {
    setValidation(prevState => [
      ...prevState.filter(item => !(item.fieldName === fieldValidation.fieldName)),
      fieldValidation
    ]);
  }, []);

  const handleUnmountField = useCallback((fieldName: string) => {
    setValidation(prevState => prevState.filter(item => !(item.fieldName === fieldName)));
  }, []);

  const validationBuilderFunctions = useMemo(
    () => ({ setFieldValidation, handleUnmountField }),
    [setFieldValidation, handleUnmountField]
  );

  const contextValidators = useGetContextValidators();

  const validationScheme = useMemo(() => {
    return validationSchemaBuilder({
      fields: validation,
      extendYupScheme: extendScheme,
      validators: createValidationRegistry(contextValidators)
    });
  }, [validation, extendScheme, contextValidators]);

  return { validationScheme, validationBuilderFunctions };
};

export default useValidationBuilder;
