import { QueryConfig, useQuery } from 'react-query';
import { SignerDetails } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import PrintsQueryKeysEnum from './PrintsQueryKeysEnum';

const getUnitSigner = async (_, unitId: string) => {
  const { data } = await API.signers.getUnitSigner(unitId, {
    ...endpointsConfig.getUnitSigner
  });
  return data;
};

const usePrintsGetUnitSignerQuery = (unitId: string, queryConfig?: QueryConfig<SignerDetails>) =>
  useQuery([PrintsQueryKeysEnum.PRINTS_SIGNER, unitId], getUnitSigner, queryConfig);

export default usePrintsGetUnitSignerQuery;
