import { ApplicationsApiCancelApplicationRequest } from '@ibtm/domain';

import { useMutation, useQueryCache } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';
import { ApplicationQueryKeysEnum } from '../../queries';

export function cancelApplicationProcess(requestData: ApplicationsApiCancelApplicationRequest) {
  return API.application.cancelApplication(requestData.applicationId, requestData.cancelApplicationRequest, {
    ...endpointsConfig.cancelApplication
  });
}

function useCancelApplicationProcessMutation() {
  const queryCache = useQueryCache();
  return useMutation(cancelApplicationProcess, {
    onSuccess: () => {
      queryCache.invalidateQueries(ApplicationQueryKeysEnum.APPLICATION_LIST);
    }
  });
}

export default useCancelApplicationProcessMutation;
