import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { WarningInformation } from '@libs/common/v2/components/warning';

import { FolderDetailsParams, useFolderActiveVehiclesCountQuery } from '@libs/domain/folder';

import { DomainDictionaryEntry } from '../../../../../config';

const vehicleSubmittedToTypes = [
  DomainDictionaryEntry.VEHICLE_SUBMITTED_TO.L,
  DomainDictionaryEntry.VEHICLE_SUBMITTED_TO.P,
  DomainDictionaryEntry.VEHICLE_SUBMITTED_TO.W,
  DomainDictionaryEntry.VEHICLE_SUBMITTED_TO.Z
];

function FolderHasNoVehiclesInformation() {
  const { folderId } = useParams<FolderDetailsParams>();
  const { data } = useFolderActiveVehiclesCountQuery(folderId);
  const [t] = useTranslation();

  const hasNoActiveVehicles = useMemo(() => {
    if (!data) {
      return false;
    }

    const activeVehicles = Object.keys(data)
      .filter(key => [...vehicleSubmittedToTypes, ''].includes(key))
      .reduce((acc, key) => acc + (data[key] || 0), 0);

    return activeVehicles === 0;
  }, [data]);

  return hasNoActiveVehicles ? (
    <div className="pt-10">
      <WarningInformation content={t('vehicle:noVehiclesInFolderMessage')} />
    </div>
  ) : null;
}

export default FolderHasNoVehiclesInformation;
