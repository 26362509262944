import { getInitialYear, useSingleSelectColumn, useTableLayoutProps } from '@libs/common/v2';

import { useTradeRulesQuery } from '@libs/domain/social-commission';
import { IntialYearType } from '@libs/domain/social-commission/components/SocialCommissionPage';

import { TradeRulesTableEnum, TradeRulesTypeEnum } from '../../components';
import { useTradeRulesColumns } from '../columns/useTradeRulesColumns';

function useTradeRulesTable(typeKeyIn: string[], initialYear: IntialYearType) {
  const { columns, mappedFilterFields, mappedSortFields } = useTradeRulesColumns();
  const year = [initialYear?.value || getInitialYear()?.value];

  return useTableLayoutProps({
    tableHookQuery: useTradeRulesQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      ...(typeKeyIn.includes(TradeRulesTypeEnum.SINGLE_PERMITS) && { tablePlugins: [useSingleSelectColumn] })
    },
    tableHookQueryInitialParams: {
      [TradeRulesTableEnum.YEAR]: year,
      typeKeyIn,
      sortBy: [{ id: 'created', desc: true }]
    }
  });
}
export default useTradeRulesTable;
