import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { ForeignPermissionSnapshot } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import { SelectOption, TableLayout, useFormV2Context, useTableRowActions } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { useDocumentTemplatesQuery } from '@libs/document-template';

import { API } from '@libs/domain/api';
import { DomainDictionaryEntry, DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import {
  FolderDetailsParams,
  parseFolderSingleAuthorizationPayload,
  useSingleBasicAuthorizationsTable
} from '@libs/domain/folder';

import { DownloadBasicLimitDetailsButton } from '.';

interface IProps {
  permissionType?: string;
  statusKeyIn?: string[];
  isCollapsable?: boolean;
}

const translate = partialTranslate('folder:details.tab.singleAuthorization.section.releasedOrIssued');

function SingleAuthorizationBasicTable({ permissionType, statusKeyIn, isCollapsable }: IProps) {
  const { folderId } = useParams<FolderDetailsParams>();
  const { isClientPortal } = useDomainConfigContext();
  const { formMode, getValues } = useFormV2Context();
  const { editMode } = useViewModesV2(formMode);

  const { data: documentTemplate } = useDocumentTemplatesQuery({
    typeKeys: [DomainDictionaryEntry.DOCUMENT_TYPE.CONFIRMATION_OF_ISSUING_DOCUMENTS]
  });

  const documentTemplateId = documentTemplate?.content?.find(
    item => item.key === DomainDictionaryEntry.DOCUMENT_TEMPLATE_KEY.CONFIRMATION_OF_ISSUING_DOCUMENTS
  )?.id;

  const tableProps = useSingleBasicAuthorizationsTable([folderId], permissionType, statusKeyIn);

  const { renderTableActions } = useTableRowActions<ForeignPermissionSnapshot>(
    [
      {
        id: 'FOLDER_BASIC_LIMIT_RELEASED_OR_ISSUED_DETAILS_BUTTON',
        label: 'folder:details.tab.singleAuthorization.section.releasedOrIssued.button.display',
        customButton: row => (
          <DownloadBasicLimitDetailsButton invoice={row?.original?.invoice} documentTemplateId={documentTemplateId} />
        )
      }
    ],
    [documentTemplateId]
  );

  const apiRequest = useCallback(
    params => {
      const yearSelect = getValues('selectedYear') as SelectOption<string>;
      const maxYear = yearSelect?.value;

      return isClientPortal
        ? API.client.foreignPermissions.getForeignPermissionSnapshotPageForFolder(
            '',
            '',
            parseFolderSingleAuthorizationPayload({ ...params, maxYear })
          )
        : API.foreignPermissions.getForeignPermissionSnapshotPage(
            parseFolderSingleAuthorizationPayload({ ...params, maxYear })
          );
    },
    [getValues, isClientPortal]
  );

  return (
    <TableLayout
      {...tableProps}
      pageTitle={translate('title')}
      selectionColumnWidth={editMode ? 100 : 0}
      xlsxDownload={{
        fileName: translate('title'),
        pathToXLSXTranslation: 'folder:details.tab.singleAuthorization.section.releasedOrIssued.field',
        apiRequest: params => apiRequest(params)
      }}
      rowActions={renderTableActions([DomainUIElementEnum.FOLDER_BASIC_LIMIT_RELEASED_OR_ISSUED_DETAILS_BUTTON])}
      isSection
      isFilterEnabled={false}
      isCollapsable={isCollapsable}
    />
  );
}

export default SingleAuthorizationBasicTable;
