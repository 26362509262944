import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DefaultApiUpdateOrganizationUnitParentUnitRequest } from '@avispon/organization-structure';
import {
  OrganizationStructureNodeSnapshotExternal,
  OrganizationUnitLiteExternal,
  OrganizationUnitUpdateParentUnitRequest
} from '@avispon/organization-structure/dist/models';
import { useSnackbar } from '@enigma/fe-ui';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';
import { CircularProgress, Grid, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Tree from 'rc-tree';
import { DataNode } from 'rc-tree/lib/interface';

import { TableButtonCollapse, TableButtonView, Typography, useConfirmDialog, useRouter } from '@libs/common/v2';
import { Theme, useTheme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import { API, useEditOrganizationUnitParentUnit } from '@libs/organization-structure/api';
import { OrganizationStructureHierarchyLevelEnum } from '@libs/organization-structure/models';
import { useElementVisibility } from '@libs/permission';

import { OrganizationStructureUIElementEnum } from '../../config';

const getTreeItem = (
  item: OrganizationStructureNodeSnapshotExternal,
  withIcon: boolean,
  openDetails: (selectedKey: string) => void,
  isVisible: boolean,
  onSelect: (selectedKey: string[]) => void,
  classes: any,
  isDisabled?: boolean,
  tooltipTitle?: string
) => {
  const moveToDetails = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    openDetails(item?.id);
  };

  return (
    <Tooltip title={isDisabled ? tooltipTitle || '' : ''}>
      <Grid
        container
        className={clsx(
          classes.treeNodes,
          clsx(
            ((withIcon && isVisible) || (!withIcon && !isDisabled)) && 'cursor-pointer',
            isDisabled && 'cursor-not-allowed'
          ),
          isDisabled && clsx(classes.disabledTreeNode, 'select-none')
        )}
        onFocus={({ target }) => target.classList.add('focus')}
        onBlur={({ target }) => target.classList.remove('focus')}
        tabIndex={withIcon ? -1 : 0}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') onSelect([item.id]);
        }}
      >
        <div className={clsx(classes.treeNodesText)}>
          <Typography themeVariant="textMd.medium" className={classes.treeNodeTypography}>
            {item?.name} ({item?.code})
          </Typography>
          {item?.description && (
            <Typography themeVariant="textMd.normal" className={classes.treeNodeTypography}>
              {item.description}
            </Typography>
          )}
        </div>
        {withIcon && isVisible && (
          <TableButtonView
            onClick={moveToDetails}
            actionKey={UIElementNameEnum.ORGANIZATION_STRUCTURE_DETAILS_BUTTON}
          />
        )}
      </Grid>
    </Tooltip>
  );
};

interface OrganizationUnitTreeProps {
  unitsData: OrganizationStructureNodeSnapshotExternal[];
  draggable: boolean;
  myUnits?: OrganizationUnitLiteExternal[];
  select?: boolean;
  userUnitSelect?: boolean;
  setValue?: UseFormSetValue<FieldValues>;
  selectedKey?: string[];
  hierarchyLevelKeySelectValue?: OrganizationStructureHierarchyLevelEnum;
  setSelectedKey?: React.Dispatch<React.SetStateAction<string[]>>;
  isDisabled?: boolean;
  isPageView?: boolean;
}

function OrganizationUnitTreeV2({
  unitsData,
  draggable,
  select,
  myUnits,
  setValue,
  selectedKey = [],
  hierarchyLevelKeySelectValue,
  setSelectedKey,
  userUnitSelect,
  isDisabled,
  isPageView
}: OrganizationUnitTreeProps) {
  const [t] = useTranslation();
  const { contrast } = useTheme();
  const classes = useStyles({ isDisabled, isPageView, contrast });
  const { checkIsElementVisible } = useElementVisibility();
  const snackbar = useSnackbar();
  const { goToPage, routes } = useRouter();
  const [treeData, setTreeData] = useState(unitsData);
  const [expandedKeys, setExpandedKeys] = useState([unitsData[0].id]);
  const [updating, setUpdating] = useState<boolean>(false);
  const [topVisible, setButtonTopVisible] = useState<boolean>(false);
  const [bottomVisible, setButtonBottomVisible] = useState<boolean>(false);
  const [confirm] = useConfirmDialog();

  const { mutateAsync: editOrganizationUnitParentUnit } = useEditOrganizationUnitParentUnit({
    onSuccess: () => {
      snackbar.showSnackbar('success', t(`administration:organizationUnit.message.unitUpdateSuccess`));
      setUpdating(false);
    },
    onError: () => setUpdating(false)
  });

  const withIcon = !select && draggable;
  const treeRef = useRef(null);
  const topContainer = useRef(null);
  const bottomContainer = useRef(null);

  useEffect(() => {
    setTreeData(unitsData);
  }, [unitsData]);

  const handleTopScroll = () => {
    treeRef.current.scroll({
      top: -250,
      behavior: 'smooth'
    });
  };

  const handleBottomScroll = () => {
    treeRef.current.scroll({
      top: 300,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    if (topContainer.current) {
      topContainer.current.addEventListener('dragenter', handleTopScroll);
    }

    if (bottomContainer.current) {
      bottomContainer.current.addEventListener('dragenter', handleBottomScroll);
    }
  }, []);

  const compare = useCallback(
    (id: string) => {
      if (myUnits && unitsData) {
        return myUnits.filter(unit => unit.organizationUnitId === id).length > 0;
      }
      if (!myUnits) {
        return false;
      }
      return true;
    },
    [myUnits, unitsData]
  );

  const checkIfTreeNodeIsDisabled = useCallback(
    (organizationUnitHierarchyLevelKey: OrganizationStructureHierarchyLevelEnum): boolean => {
      const hierarchyLevelKeyMap = [
        OrganizationStructureHierarchyLevelEnum.ORGANIZATION_STRUCTURE_HIERARCHY_LEVEL_IOKZ,
        OrganizationStructureHierarchyLevelEnum.ORGANIZATION_STRUCTURE_HIERARCHY_LEVEL_IOKP
      ];

      if (
        hierarchyLevelKeySelectValue ===
        OrganizationStructureHierarchyLevelEnum.ORGANIZATION_STRUCTURE_HIERARCHY_LEVEL_IOK
      ) {
        return (
          organizationUnitHierarchyLevelKey !==
          OrganizationStructureHierarchyLevelEnum.ORGANIZATION_STRUCTURE_HIERARCHY_LEVEL_IO
        );
      }

      if (hierarchyLevelKeyMap.includes(hierarchyLevelKeySelectValue)) {
        return (
          organizationUnitHierarchyLevelKey !==
          OrganizationStructureHierarchyLevelEnum.ORGANIZATION_STRUCTURE_HIERARCHY_LEVEL_IOK
        );
      }

      return false;
    },
    [hierarchyLevelKeySelectValue]
  );

  const onSelect = (selectedKey: string[]) => {
    if (!select && draggable) {
      if (compare(selectedKey[0])) {
        goToPage(routes.organizationUnitDetails(selectedKey[0]));
      }
    } else if (select && !draggable) {
      if (userUnitSelect) {
        setValue('organizationUnit.id', selectedKey[0]);
      } else {
        setValue('parentUnitId', selectedKey[0]);
      }
      setSelectedKey?.(selectedKey);
    }
  };

  const buildTreeData = useCallback(
    (data): DataNode[] => {
      return data.map((item: React.PropsWithChildren<OrganizationStructureNodeSnapshotExternal>) => {
        const isDisabled = checkIfTreeNodeIsDisabled(item.hierarchyLevelKey as OrganizationStructureHierarchyLevelEnum);
        return {
          title: () =>
            getTreeItem(
              item,
              withIcon,
              (selectedKey: string) => goToPage(routes.organizationUnitDetails(selectedKey)),
              compare(item.id),
              onSelect,
              classes,
              isDisabled,
              t('administration:organizationUnit.message.parentUnitNotAllowed')
            ),
          className: clsx(classes.treeNode),
          disabled: isDisabled,
          key: item.id,
          selectable: !(withIcon && !compare(item.id)),
          ...(item.children ? { children: buildTreeData(item.children) } : { isLeaf: true })
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [myUnits, unitsData]
  );

  const onDragEnter = info => {
    setExpandedKeys(info.expandedKeys);
  };

  const handleDropUnit = info => {
    const unitId = info.dragNode.props.eventKey as string;
    const dropPos = info.node.props.pos.split('-') as string;
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    if (dropPosition !== -1) {
      confirm({
        title: t('administration:organizationUnit.unitConnectionsChangeTitle'),
        message: t('administration:organizationUnit.message.unitConnectionsChangeConfirm'),
        onConfirm: (setLoading, closeDialog) => {
          setLoading(true);
          API.organizationStructure
            .getOrganizationUnit({ id: unitId })
            .then(response => {
              editOrganizationUnit(response.data, info);
              closeDialog();
            })
            .finally(() => setLoading(false));
        },
        confirmType: 'primary'
      });
    }
  };

  const editOrganizationUnit = (
    selectedUnitData: Partial<DefaultApiUpdateOrganizationUnitParentUnitRequest> &
      OrganizationUnitUpdateParentUnitRequest,
    info
  ) => {
    setUpdating(true);
    const newParentUnitId = info.node.props.eventKey as string;
    const body: OrganizationUnitUpdateParentUnitRequest = {
      parentUnitId: newParentUnitId,
      version: selectedUnitData.version
    };

    const updateUnit = editOrganizationUnitParentUnit({
      id: selectedUnitData.id,
      body
    });

    updateUnit.then(res => {
      if (res) {
        updateTree(info);
      }
    });
  };

  const updateTree = info => {
    const newParentUnitId = info.node.props.eventKey as string;
    const dragUnitId = info.dragNode.props.eventKey as string;
    const dropPos = info.node.props.pos.split('-') as string;
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const data = [...treeData];
    let dragObj: OrganizationUnitLiteExternal;

    const loop = (data, key, callback) => {
      data.forEach((item, index, arr) => {
        if (item.id === key) {
          callback(item, index, arr);
          return;
        }
        if (item.children) {
          loop(item.children, key, callback);
        }
      });
    };

    loop(data, dragUnitId, (item: OrganizationUnitLiteExternal, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      loop(data, newParentUnitId, item => {
        const children = item.children || [];
        children.push(dragObj);
      });
    } else if ((info.node.props.children || []).length > 0 && info.node.props.expanded && dropPosition === 1) {
      loop(data, newParentUnitId, item => {
        const children = item.children || [];
        children.children.unshift(dragObj);
      });
    } else {
      let ar;
      let i;
      loop(data, newParentUnitId, (item, index: number, arr: string[]) => {
        ar = arr;
        i = index;
        if (dropPosition !== -1) {
          ar.splice(i + 1, 0, dragObj);
        }
      });
    }
    setTreeData(data);
  };

  const expandCollapseBranch = (id: string): void => {
    return setExpandedKeys(prevState =>
      prevState.includes(id) ? prevState.filter(expandedKey => expandedKey !== id) : [...prevState, id]
    );
  };

  const switcherIcon = obj => {
    return obj?.isLeaf ? null : (
      <TableButtonCollapse
        onClick={() => expandCollapseBranch(obj?.eventKey)}
        className={classes.collapseIcon}
        isExpanded={obj?.expanded as boolean}
      />
    );
  };

  const onDragEnd = () => {
    setButtonTopVisible(false);
    setButtonBottomVisible(false);
  };

  const onDragOver = () => {
    const tree = treeRef?.current;
    const bottomScroll = tree.scrollHeight - tree.scrollTop < tree.clientHeight + 10;
    if (tree.scrollTop > 0) {
      setButtonTopVisible(true);
    } else {
      setButtonTopVisible(false);
    }
    if (!bottomScroll) {
      setButtonBottomVisible(true);
    } else {
      setButtonBottomVisible(false);
    }
  };

  return (
    <div className={classes.root}>
      <div ref={treeRef} className={classes.treeContainer}>
        <div
          ref={topContainer}
          className={clsx((!topVisible || updating) && 'hidden', classes.onDragScrollContainer, classes.top)}
        />
        <div
          ref={bottomContainer}
          className={clsx((!bottomVisible || updating) && 'hidden', classes.onDragScrollContainer, classes.bottom)}
        />
        {selectedKey && (
          <Tree
            expandedKeys={expandedKeys}
            draggable={
              draggable && checkIsElementVisible(OrganizationStructureUIElementEnum.ORGANIZATION_STRUCTURE_EDIT_BUTTON)
            }
            onDragEnter={onDragEnter}
            onDrop={handleDropUnit}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
            onSelect={selectedKeys => onSelect(selectedKeys as string[])}
            selectedKeys={selectedKey}
            switcherIcon={switcherIcon}
            className={clsx(classes.treeWrapper, updating && classes.loading)}
            disabled={isDisabled}
            treeData={buildTreeData(treeData)}
          />
        )}
        {updating && (
          <div className={classes.progressContainer}>
            <CircularProgress color="secondary" size={34} />
          </div>
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles<Theme, { isDisabled?: boolean; isPageView?: boolean; contrast?: boolean }>(theme => ({
  root: {
    width: '100%',
    height: ({ isPageView }) => (isPageView ? '100%' : 'unset')
  },
  treeContainer: {
    display: 'flex',
    width: '100%',
    height: ({ isPageView }) => (isPageView ? '100%' : 'unset'),
    overflow: 'auto',
    paddingBottom: '15px'
  },
  loading: {
    opacity: '0.5'
  },
  progressContainer: {
    textAlign: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(246, 244, 248, 0.5)'
  },
  onDragScrollContainer: {
    position: 'absolute',
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    height: '10%',
    width: '100%',
    opacity: '0.5',
    backgroundColor: 'rgba(0, 0, 0, 0.12)'
  },
  bottom: {
    bottom: '-20px'
  },
  top: {
    top: '-5px'
  },
  focused: {
    '&:focus': { backgroundColor: theme.palette.primary?.focus, borderRadius: 17 }
  },
  treeWrapper: {
    margin: 0,
    padding: important(0),
    width: '100%',
    '& .rc-tree-child-tree': {
      paddingLeft: theme.spacing(5),
      '& li': {
        margin: 0,
        padding: 0
      }
    }
  },
  treeNode: {
    display: 'flex',
    margin: 0,
    padding: 0,
    shadow: 'none',
    dropShadow: 'none',
    '& .rc-tree-title': {
      margin: 0,
      shadow: 'none',
      dropShadow: 'none'
    },
    '& .rc-tree-switcher': {
      margin: important(0),
      marginRight: important(theme.spacing(2.5)),
      lineHeight: important(0)
    },
    '& .rc-tree-node-content-wrapper': {
      flexGrow: 1,
      padding: important(0),
      minWidth: 'max-content',
      shadow: important('none'),
      dropShadow: important('none'),
      borderRadius: important(theme.spacing(1)),
      boxShadow: important('none')
    }
  },
  disabledTreeNode: {
    '& .MuiTypography-root': {
      color: important(theme.palette.grey[300])
    }
  },
  treeNodes: {
    margin: 0,
    backgroundColor: theme.palette.contrastIndicators.background,
    shadow: 'none',
    dropShadow: 'none',
    display: important('flex'),
    alignItems: important('center'),
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.spacing(1),
    width: '100%',
    minWidth: 'max-content',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    opacity: ({ isDisabled }) => (isDisabled ? '0.5' : 1),
    userSelect: ({ isDisabled }) => (isDisabled ? 'none' : 'inherit'),
    cursor: ({ isDisabled }) => (isDisabled ? important('not-allowed') : 'pointer')
  },
  treeNodesText: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    whiteSpace: 'break-spaces',
    [theme.breakpoints.up('lg')]: {
      width: 240
    },
    [`@media screen and (min-width: 1600px)`]: {
      width: 380
    },
    [theme.breakpoints.up('xl')]: {
      width: 500
    },
    '& > .MuiTypography-root': {
      position: 'relative',
      wordBreak: 'break-word'
    }
  },
  treeNodeTypography: {
    color: ({ contrast }) => contrast && important(theme.palette.grey[100])
  },
  collapseIcon: {
    marginRight: theme.spacing(2)
  }
}));

export default OrganizationUnitTreeV2;
