import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { ResourcePoolsReassignmentRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const regenerateResourcePoolsAssignment = async (request: ResourcePoolsReassignmentRequest) =>
  API.printouts.regenerateResourcePoolsAssignment(request, {
    ...endpointsConfig.regenerateResourceAssignment
  });

function useRegenerateResourcePoolsAssignmentMutation(onSuccess?: () => void) {
  const [t] = useTranslation();
  const { showSuccessSnackbar, showSnackbar } = useSnackbar();

  return useMutation(regenerateResourcePoolsAssignment, {
    onSuccess: () => {
      showSuccessSnackbar(t('prints:messages.generationSuccessMessage'));
      onSuccess?.();
    },
    onError: () => showSnackbar('warning', t('prints:messages.generationFailureMessage'))
  });
}

export default useRegenerateResourcePoolsAssignmentMutation;
