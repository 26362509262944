import { QueryConfig, usePaginatedQuery } from 'react-query';
import { DefaultApiFindRequest } from '@avispon/group';
import { PageGroupSnapshotExternal } from '@avispon/group/dist/models';

import { API, endpointsConfig, UserQueryKeysEnum } from '@libs/user/api';

export const findGroups = async (
  requestParameters: DefaultApiFindRequest & { unitIds?: string[]; selectedData?: string[] }
) => {
  const { unitIds, selectedData } = requestParameters;

  const { data } = await API.group.find({ ...requestParameters, unitIds }, { ...endpointsConfig.groupFind });

  if (selectedData.length) {
    return {
      ...data,
      content: data.content.filter(({ id }) => !selectedData.includes(id))
    };
  }

  return data;
};

export default function useFindGroupQuery(
  requestParameters: DefaultApiFindRequest,
  config: QueryConfig<PageGroupSnapshotExternal, unknown> = {}
) {
  return usePaginatedQuery([UserQueryKeysEnum.GROUPS, requestParameters], () => findGroups(requestParameters), config);
}
