import { useMultipleSelectColumn, useTableLayoutProps } from '@libs/common/v2';

import { ArchivedApplicationsSelectRowActionRender } from '../../../common';
import { useArchivedApplicationQuery } from '../../api';
import useApplicationArchiveColumns from '../columns/useApplicationArchiveColumns';

function useApplicationArchivedTable(
  isMultipleActionVisible?: boolean,
  additionalFilters?: { [key: string]: Array<string> }
) {
  const { columns, mappedFilterFields, mappedSortFields } = useApplicationArchiveColumns();
  const multipleAction = useMultipleSelectColumn(ArchivedApplicationsSelectRowActionRender);

  return useTableLayoutProps({
    tableHookQuery: useArchivedApplicationQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'archiveNumber', desc: true }]
        }
      },
      ...(isMultipleActionVisible && {
        tablePlugins: [multipleAction]
      })
    },
    tableHookQueryInitialParams: {
      ...additionalFilters,
      archived: true
    },
    sectionClassName: 'mx-10'
  });
}

export default useApplicationArchivedTable;
