import { useTranslation } from 'react-i18next';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import { TableIconButton, useTableAdapter, useTableContext } from '@libs/common/v2';

interface IProps {
  tooltipTitle?: string;
  isDisabled?: boolean;
  actionKey?: UIElementNameEnum;
}

function TableButtonAddNewStartingRow({ tooltipTitle, isDisabled = false, actionKey }: IProps) {
  const [t] = useTranslation();
  const tableInstance = useTableAdapter();
  const tableContext = useTableContext();

  const handleClick = () => {
    tableInstance.addNewStartRow(tableInstance, tableContext);
  };

  return (
    <TableIconButton
      icon="AddIcon"
      tooltipTitle={tooltipTitle || t('action.addNewRow')}
      onClick={handleClick}
      actionKey={actionKey}
      isDisabled={isDisabled}
    />
  );
}

export default TableButtonAddNewStartingRow;
