import { useQuery } from 'react-query';
import { DefaultApiListMyOrganizationUnitsRequest } from '@avispon/group';
import { OrganizationUnitLiteExternal } from '@avispon/group/dist/models';

import { API, endpointsConfig, OrganizationQueryKeysEnum } from '../index';

const getMyOrganizationUnitList = async (_, params: DefaultApiListMyOrganizationUnitsRequest) => {
  const { data } = await API.group.listMyOrganizationUnits(params, {
    ...endpointsConfig.listMyOrganizationUnits
  });
  return data.organizationUnits;
};

export default function useMyOrganizationListQuery(params: DefaultApiListMyOrganizationUnitsRequest, queryConfig = {}) {
  return useQuery<OrganizationUnitLiteExternal[]>(
    [OrganizationQueryKeysEnum.MY_ORGANIZATION_UNIT_LIST, params],
    getMyOrganizationUnitList,
    queryConfig
  );
}
