import { useTranslation } from 'react-i18next';
import { PermissionTypeCode } from '@ibtm/domain';
import { MenuItem, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { LoaderCircular, TableIconButton } from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';

import { DomainUIElementEnum } from '../../../../config';
import usePrintActionHandler from '../../../hooks/usePrintActionHandler';

interface IProps {
  onClose?: () => void;
  permissionTypeCode: PermissionTypeCode;
  isDisabled?: boolean;
}

function PrintAllPermissionsButton({ onClose, permissionTypeCode, isDisabled }: IProps) {
  const [t] = useTranslation();
  const { checkIsElementVisible } = useElementVisibility();
  const classes = useStyles();

  const {
    mutation: { isLoading, printMutation }
  } = usePrintActionHandler();

  const title = t('prints:actions.printAll');
  const disabledTitle = t('prints:messages.noDataToPrint');

  return (
    checkIsElementVisible(DomainUIElementEnum.PERMISSION_ALL_PRINT_BUTTON) &&
    (onClose ? (
      <LoaderCircular isLoading>
        <Tooltip disableHoverListener={!isDisabled} title={disabledTitle}>
          <span className={classes.buttonContainer}>
            <MenuItem
              disabled={isDisabled}
              onClick={() => {
                printMutation({
                  permissionTypeCode,
                  permissionIds: []
                });
                onClose?.();
              }}
            >
              {title}
            </MenuItem>
          </span>
        </Tooltip>
      </LoaderCircular>
    ) : (
      <TableIconButton
        icon="PrintIcon"
        wrapperClassName={classes.buttonContainer}
        isDisabled={isDisabled}
        isButtonLoading={isLoading}
        tooltipTitle={isDisabled ? disabledTitle : title}
        onClick={async () => {
          printMutation({
            permissionTypeCode,
            permissionIds: []
          });
        }}
        actionKey={DomainUIElementEnum.PERMISSION_ALL_PRINT_BUTTON}
      />
    ))
  );
}

const useStyles = makeStyles({
  buttonContainer: {
    display: 'block',
    margin: 'auto'
  }
});

export default PrintAllPermissionsButton;
