import { useParams } from 'react-router-dom';

import { UiMode } from '@libs/meta-form';
import { useMetaFormContext } from '@libs/meta-form/context';
import { UIElementNameEnum } from '@libs/permission';

import { IPermissionsKeys, useDomainConfigContext } from '@libs/domain/config';
import {
  ExploitationBaseActionButtonsEnum,
  ExploitationBaseRowActionButtonsEnum,
  ExploitationBaseTable,
  NegativeControlInformation
} from '@libs/domain/exploitation-base';

import { IApplicationDetailsPageParams } from '../../../model';

interface IProps {
  folderId: string;
  permissionsKeys?: IPermissionsKeys;
  tableActionKeys: Array<UIElementNameEnum>;
  transferredFoldersIds?: string[];
}

function ExploitationBaseTab({ folderId, permissionsKeys, tableActionKeys, transferredFoldersIds }: IProps) {
  const { isOperatorPortal } = useDomainConfigContext();
  const { applicationId } = useParams<IApplicationDetailsPageParams>();
  const { DELETE, EDIT, SHOW_GEOPORTAL_MAP, OPEN_DETAILS } = ExploitationBaseRowActionButtonsEnum;
  const { ADD, SEARCH } = ExploitationBaseActionButtonsEnum;
  const { mode } = useMetaFormContext();
  return (
    <>
      {isOperatorPortal && <NegativeControlInformation folderId={folderId} />}
      <ExploitationBaseTable
        mode={mode}
        visibleHeaderActions={mode === UiMode.FORM ? [ADD, SEARCH] : []}
        visibleRowActions={[...(mode === UiMode.FORM ? [DELETE, EDIT] : []), SHOW_GEOPORTAL_MAP, OPEN_DETAILS]}
        queryParams={{ applicationIdIn: [applicationId] }}
        folderId={folderId}
        applicationId={applicationId}
        permissionsKeys={permissionsKeys}
        tableActionKeys={tableActionKeys}
        transferredFoldersIds={transferredFoldersIds}
      />
    </>
  );
}

export default ExploitationBaseTab;
