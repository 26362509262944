import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { CivicBoardRuleCreateRequest, RuleType } from '@ibtm/domain';
import moment from 'moment';

import { partialTranslate } from '@libs/common';
import { DatepickerField, Dialog, FormV2Context, GridLayout, Section, SwitchField, typedNameV2 } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { convertDateToDateTimeFormat } from '@libs/common/v2/utils';

import { useCreateTradeRulesMutation } from '@libs/domain/social-commission/api';
import { SocialCommissionQueryKeysEnum } from '@libs/domain/social-commission/config';
import { getTradeRulesSchema } from '@libs/domain/social-commission/model';

import { ResourceTypesSelectField } from '../../../common';

const defaultValues: Partial<CivicBoardRuleCreateRequest> = {
  recalculateOnUpdate: true
};

function DialogCreateRule({ closeDialog }: { closeDialog: () => void }) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const getLabel = partialTranslate('foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.formFields');
  const { showSuccessSnackbar } = useSnackbar();
  const form = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues,
    resolver: yupResolver(getTradeRulesSchema(false))
  });
  const { mutate: createApplication, isLoading } = useCreateTradeRulesMutation({
    onSuccess: () => {
      queryCache.invalidateQueries(SocialCommissionQueryKeysEnum.TRADE_RULES_DETAILS);
      showSuccessSnackbar(
        t('foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.messages.ruleAddSucces')
      );
      form.reset({});
      closeDialog();
    }
  });

  const onSubmit = (
    formData: Partial<
      Omit<CivicBoardRuleCreateRequest, 'mainResourceTypeId'> & {
        mainResourceTypeId: { id: string };
      }
    >
  ) => {
    const request: CivicBoardRuleCreateRequest = {
      resourceTypes: [],
      mainResourceTypeId: formData.mainResourceTypeId?.id,
      validFrom: formData.validFrom,
      validTo: formData.validTo,
      active: formData.active ? formData.active : false,
      typeKey: RuleType.SINGLE_PERMITS,
      recalculateOnUpdate: formData.recalculateOnUpdate,
      items: []
    };
    createApplication(request);
  };
  return (
    <FormV2Context.Provider value={form}>
      <Dialog
        title={t('foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.addDialogTitle')}
        confirmText={t('action.save')}
        onConfirm={() => form.handleSubmit(onSubmit)()}
        onCancel={closeDialog}
        onClose={closeDialog}
        isConfirmLoading={isLoading}
        isOpen
      >
        <Section isModalSection>
          <GridLayout itemProps={{ md: 6, xs: 12 }}>
            <SwitchField label={getLabel('isActive')} name="active" />
            <ResourceTypesSelectField
              label={getLabel('name')}
              fieldName={typedNameV2<CivicBoardRuleCreateRequest>('mainResourceTypeId')}
              key="resourceType"
              yearLimiter={{
                yearGreaterThanOrEqual: moment().year() - 1,
                yearLessThanOrEqual: moment().add(1, 'year').year()
              }}
              isRequired
              filterForTradeRules
              queryKey={SocialCommissionQueryKeysEnum.RESOURCE_TYPE_TRADE_RULES}
              freeDisposalLimit
            />

            <DatepickerField
              name={typedNameV2<CivicBoardRuleCreateRequest>('validFrom')}
              label={getLabel('effectiveDateFrom')}
              viewModeDateParser={convertDateToDateTimeFormat}
              isRequired
            />
            <DatepickerField
              name={typedNameV2<CivicBoardRuleCreateRequest>('validTo')}
              label={getLabel('effectiveDateTo')}
              viewModeDateParser={convertDateToDateTimeFormat}
              isRequired
            />
            <SwitchField
              label={getLabel('recalculateOnUpdate')}
              name={typedNameV2<CivicBoardRuleCreateRequest>('recalculateOnUpdate')}
            />
          </GridLayout>
        </Section>
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default DialogCreateRule;
