import { useTranslation } from 'react-i18next';
import { ProceedingSearchFilter, ProceedingSnapshot } from '@ibtm/domain';

import { CamelCasePath, SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO } from '@libs/common/v2/utils';

import { ProceedingSnapshotClient } from '../model';

const useProceedingsColumns = (
  showAllFilters: boolean,
  trimTextFilters: boolean,
  visibleColumns: Array<CamelCasePath<ProceedingSnapshotClient>>
) => {
  const [t] = useTranslation();
  const { createColumns } = useCreateColumns<ProceedingSnapshotClient, ProceedingSnapshot>({
    pathToTranslate: 'proceeding:fields'
  });

  const prepareTextFilterValue = (filterValue: string): string => {
    return trimTextFilters ? filterValue.trim() : filterValue;
  };

  const mappedFilterFields: TableFilterMapper<ProceedingSnapshotClient, ProceedingSearchFilter> = {
    created: ({ dateFrom, dateTo }: DateRangeISO) => ({
      createdGreaterThanOrEqual: dateFrom,
      createdLessThanOrEqual: dateTo
    }),
    modified: ({ dateFrom, dateTo }: DateRangeISO) => ({
      modifiedGreaterThanOrEqual: dateFrom,
      modifiedLessThanOrEqual: dateTo
    }),
    enterpreneur: enterpreneur => ({ subjectNameContains: prepareTextFilterValue(enterpreneur) }),
    NIP: nip => ({ nipContains: prepareTextFilterValue(nip) }),
    conductingPersonName: conductingPerson => ({ assignedToNameContains: prepareTextFilterValue(conductingPerson) }),
    proceedingNumber: proceedingNumber => ({ numberContains: prepareTextFilterValue(proceedingNumber) }),
    caseNumber: caseNumber => ({ caseNumberContains: prepareTextFilterValue(caseNumber) }),
    proceedingCategory: (categories: SelectOption<string>[]) => ({
      categoryKeyIn: categories.map(category => category.value)
    }),
    proceedingType: (types: SelectOption<string>[]) => ({ typeKeyIn: types.map(type => type.value) }),
    status: (statuses: SelectOption<string>[]) => ({ statusKeyIn: statuses.map(status => status.value) }),
    isDecision: ({ value }: SelectOption<boolean>) => ({ isDecision: value }),
    isReviewed: ({ value }: SelectOption<boolean>) => ({ isReviewed: value }),
    isSecondTierDecision: ({ value }: SelectOption<boolean>) => ({ isSecondTierDecision: value }),
    isWSAComplaint: ({ value }: SelectOption<boolean>) => ({ isWsaComplaint: value }),
    isNSAComplaint: ({ value }: SelectOption<boolean>) => ({ isNsaComplaint: value })
  };

  const mappedSortFields: TableSortMapper<ProceedingSnapshotClient> = {
    created: 'created',
    modified: 'modified',
    enterpreneur: 'subjectName',
    NIP: 'subjectNip',
    conductingPersonName: 'assignedTo.name',
    proceedingNumber: 'number',
    caseNumber: 'caseNumber',
    proceedingCategory: 'categoryKey',
    proceedingType: 'typeKey',
    status: 'statusKey',
    isDecision: 'decision',
    isReviewed: 'reviewApplicationNumber',
    isSecondTierDecision: 'secondTierDecisionKey',
    isNSAComplaint: 'nsaComplaint',
    isWSAComplaint: 'wsaComplaint'
  };

  const columns = createColumns(
    [
      {
        accessor: 'proceedingNumber',
        type: 'TEXT'
      },
      {
        accessor: 'caseNumber',
        type: 'TEXT'
      },
      {
        accessor: 'created',
        type: 'DATE'
      },
      {
        accessor: 'NIP',
        type: 'TEXT',
        isFilterable: showAllFilters
      },
      {
        accessor: 'enterpreneur',
        header: 'enterpreneurName',
        type: 'TEXT',
        isFilterable: showAllFilters
      },
      {
        accessor: 'conductingPerson.name',
        header: 'conductingPerson',
        type: 'TEXT'
      },
      {
        accessor: 'modified',
        type: 'DATE'
      },
      {
        accessor: 'proceedingType',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: 'TYPES_OF_PROCEEDINGS'
      },
      {
        accessor: 'status',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: 'PROCEEDING_STATUS'
      },
      {
        accessor: 'proceedingCategory',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: 'PROCEEDING_TYPE'
      },
      {
        accessor: 'isDecision',
        type: 'BOOLEAN',
        tooltipTitle: ({ isDecision }) => (isDecision ? t('action.yes') : t('action.no'))
      },
      {
        accessor: 'isReviewed',
        type: 'BOOLEAN',
        tooltipTitle: ({ isReviewed }) => (isReviewed ? t('action.yes') : t('action.no'))
      },
      {
        accessor: 'isSecondTierDecision',
        type: 'BOOLEAN',
        tooltipTitle: ({ isSecondTierDecision }) => (isSecondTierDecision ? t('action.yes') : t('action.no'))
      },
      {
        accessor: 'isWSAComplaint',
        type: 'BOOLEAN',
        tooltipTitle: ({ isWSAComplaint }) => (isWSAComplaint ? t('action.yes') : t('action.no'))
      },
      {
        accessor: 'isNSAComplaint',
        type: 'BOOLEAN',
        tooltipTitle: ({ isNSAComplaint }) => (isNSAComplaint ? t('action.yes') : t('action.no'))
      }
    ],
    visibleColumns
  );

  return { columns, mappedFilterFields, mappedSortFields };
};

export default useProceedingsColumns;
