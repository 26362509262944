import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, OnFilterChange } from 'react-table';
import { InputAdornmentProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

import {
  ALTERNATIVE_DATE_FORMAT,
  dateWithoutOffset,
  SEARCH_TEXT_DEBOUNCE_TIME,
  TextInput,
  useDateLayoutStyles,
  useDebounce,
  useDetectTouchScreen,
  useHandleClearFilters,
  useOnScreen
} from '@libs/common/v2';
import InputButtons from '@libs/common/v2/components/input/datepicker/InputButtons';
import { Theme } from '@libs/common/v2/theme';
import { DateRange, DateRangeISO, important } from '@libs/common/v2/utils';

import datepickerTranslations from '../../../input/datepicker/datepickerTranslations';

interface IProps {
  column: Column & { filterValue?: DateRangeISO; setFilter: (value: DateRangeISO) => void };
  onFilterChange: OnFilterChange;
}

type OpenStateType = {
  isOpenFrom: boolean;
  isOpenTo: boolean;
};

function DatepickerFilter({ column: { filterValue, setFilter }, onFilterChange }: IProps) {
  const [t] = useTranslation();
  const { isTouchScreen } = useDetectTouchScreen();
  const [{ isOpenFrom, isOpenTo }, setOpenState] = useState<OpenStateType>({ isOpenFrom: false, isOpenTo: false });

  const [value, setValue] = useState<DateRangeISO>(filterValue ?? { dateFrom: null, dateTo: null });

  const [isVisible, elementRef] = useOnScreen({
    threshold: 1
  });

  const onChangeDebounced = useDebounce(v => {
    setFilter(v);
    onFilterChange?.();
    setValue(v);
  }, SEARCH_TEXT_DEBOUNCE_TIME);
  const classes = useStyles();
  const layoutProps = useDateLayoutStyles();

  const handleClearInput = useCallback(() => {
    setValue({ dateFrom: null, dateTo: null });
  }, []);

  useHandleClearFilters(handleClearInput);

  useEffect(() => {
    setValue(filterValue ?? { dateFrom: null, dateTo: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const convertToFilter = (valueToConvert: DateRange): DateRangeISO => {
    const dateFromWithoutOffset = dateWithoutOffset(valueToConvert?.dateFrom);
    const dateToWithoutOffset = dateWithoutOffset(valueToConvert?.dateTo, true);

    return {
      dateFrom: dateFromWithoutOffset,
      dateTo: dateToWithoutOffset
    };
  };

  const handleChange = (valueTochange: DateRangeISO) => {
    setValue(valueTochange);
    onChangeDebounced(valueTochange?.dateFrom || valueTochange?.dateTo ? valueTochange : null);
  };

  const translations = useMemo(() => datepickerTranslations(t, false), [t]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
      <div className={classes.inputsWrapper} ref={elementRef}>
        <DatePicker
          localeText={translations}
          className={classes.root}
          format={ALTERNATIVE_DATE_FORMAT}
          onChange={date => {
            const convertedValue: DateRangeISO = convertToFilter({
              dateFrom: moment(date),
              dateTo: value?.dateTo && moment(value.dateTo)
            });
            handleChange(convertedValue);

            if (isTouchScreen) {
              setOpenState({ isOpenFrom: false, isOpenTo: false });
            }
          }}
          onClose={() => setOpenState({ isOpenFrom: false, isOpenTo: false })}
          value={value?.dateFrom && typeof value?.dateFrom !== 'object' ? moment(value.dateFrom) : null}
          {...(value?.dateTo && { maxDate: moment(value.dateTo) })}
          open={isOpenFrom}
          slots={{ textField: TextInput }}
          reduceAnimations
          slotProps={{
            layout: layoutProps,
            // @ts-ignore niepoprawnie bierze typ z biblioteki @enigma/fe-ui
            textField: props => ({
              ...props,
              placeholder: t('other:component.dateFilter.helperText.dateFrom'),
              'data-testid': 'date-filter-from',
              className: classes.textInputRoot,
              isErrorIconHidden: true,
              helperText: null,
              onClick: e => {
                if (isTouchScreen) {
                  setOpenState({ isOpenFrom: true, isOpenTo: false });
                }
                e.preventDefault();
              }
            }),
            inputAdornment: {
              position: 'end',
              component: () => (
                <InputButtons smallIcon onOpenClick={() => setOpenState({ isOpenFrom: true, isOpenTo: false })} />
              )
            } as Partial<InputAdornmentProps>,
            actionBar: {
              actions: ['clear']
            },
            ...(!isVisible && { popper: { className: 'hidden' } })
          }}
        />
        <DatePicker
          localeText={translations}
          className={classes.root}
          format={ALTERNATIVE_DATE_FORMAT}
          onChange={date => {
            const convertedValue: DateRangeISO = convertToFilter({
              dateFrom: value?.dateFrom && moment(value.dateFrom),
              dateTo: moment(date)
            });
            handleChange(convertedValue);

            if (isTouchScreen) {
              setOpenState({ isOpenFrom: false, isOpenTo: false });
            }
          }}
          onClose={() => setOpenState({ isOpenFrom: false, isOpenTo: false })}
          value={value?.dateTo && typeof value?.dateTo !== 'object' ? moment(value.dateTo) : null}
          {...(value?.dateFrom && { minDate: moment(value.dateFrom) })}
          open={isOpenTo}
          slots={{ textField: TextInput }}
          reduceAnimations
          slotProps={{
            layout: layoutProps,
            // @ts-ignore niepoprawnie bierze typ z biblioteki @enigma/fe-ui
            textField: props => ({
              ...props,
              placeholder: t('other:component.dateFilter.helperText.dateTo'),
              'data-testid': 'date-filter-to',
              className: classes.textInputRoot,
              isErrorIconHidden: true,
              onClick: e => {
                if (isTouchScreen) {
                  setOpenState({ isOpenFrom: false, isOpenTo: true });
                }
                e.preventDefault();
              }
            }),
            inputAdornment: {
              position: 'end',
              component: () => (
                <InputButtons smallIcon onOpenClick={() => setOpenState({ isOpenFrom: false, isOpenTo: true })} />
              )
            } as Partial<InputAdornmentProps>,
            actionBar: {
              actions: ['clear']
            },
            ...(!isVisible && { popper: { className: 'hidden' } })
          }}
        />
      </div>
    </LocalizationProvider>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    borderRadius: theme.borderRadiusBase,
    '& .MuiDateTimePickerTabs-root': {
      backgroundColor: 'transparent'
    },
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent'
    },
    '& .MuiPickersCalendarHeader-monthText': {
      marginRight: theme.marginBase
    },
    '& .MuiPickersDay-today:not(.Mui-selected)': {
      border: `1px solid ${theme.palette.grey[300]}`
    }
  },
  inputsWrapper: {
    '& label:first-child': {
      marginRight: 6
    },
    display: 'flex'
  },
  textInputRoot: {
    '& > div > div ': {
      padding: important(0),
      marginTop: important(0),
      '& > input': {
        padding: important('8px 0 8px 8px'),
        fontSize: important(theme.typography.textXs.normal.fontSize),
        fontWeight: important(theme.typography.textXs.normal.fontWeight),
        lineHeight: important(theme.typography.textXs.normal.lineHeight)
      }
    }
  },
  icon: {
    fill: 'none'
  }
}));

export default DatepickerFilter;
