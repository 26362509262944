import { MutationConfig } from 'react-query';
import { ExploitationBaseDetails, VersionedRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface IRestoreParams {
  exploitationBaseId: string;
  versionedRequest: VersionedRequest;
}

const createExploitationBase = async ({ exploitationBaseId, versionedRequest }: IRestoreParams) => {
  const { data } = await API.exploitationBase.expireExploitationBase(exploitationBaseId, versionedRequest, {
    ...endpointsConfig.expireExploitationBase
  });
  return data;
};

function useExploitationBaseExpireMutation(config?: MutationConfig<ExploitationBaseDetails, unknown, unknown>) {
  return useMutation(createExploitationBase, config);
}

export default useExploitationBaseExpireMutation;
