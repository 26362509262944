import { DefaultApiAssignOrganizationUnitMemberRequest } from '@avispon/organization-structure';

import { useMutation, useQueryCache } from '@libs/common/v2/api';

import { API, endpointsConfig, OrganizationQueryKeysEnum } from '..';

const assignOrganizationUnitMember = async (params: DefaultApiAssignOrganizationUnitMemberRequest) => {
  const { data } = await API.organizationStructure.assignOrganizationUnitMember(params, {
    ...endpointsConfig.assignOrganizationUnitMember
  });
  return data;
};

export default function useAssignOrganizationUnitMemberMutation({
  onSuccess,
  onError
}: {
  onSuccess: (data: void, variables: DefaultApiAssignOrganizationUnitMemberRequest) => void;
  onError: () => void;
}) {
  const queryCache = useQueryCache();
  return useMutation(assignOrganizationUnitMember, {
    onSuccess: (...args) => {
      queryCache.invalidateQueries(OrganizationQueryKeysEnum.ORGANIZATION_UNIT_MEMBER_LIST);
      onSuccess(...args);
    },
    onError
  });
}
