import { DomainDictionaryEntry, DomainUIElementEnum } from '../../config';

const dictionaryPermissions = {
  [DomainDictionaryEntry.RECEIPT_TYPE.E_MAIL]: DomainUIElementEnum.DOCUMENTS_ISSUED_E_MAIL_SEND_OPTION,
  [DomainDictionaryEntry.RECEIPT_TYPE.COURIER]: DomainUIElementEnum.DOCUMENTS_ISSUED_COURIER_SEND_OPTION,
  [DomainDictionaryEntry.RECEIPT_TYPE.EPUAP]: DomainUIElementEnum.DOCUMENTS_ISSUED_EPUAP_SEND_OPTION,
  [DomainDictionaryEntry.RECEIPT_TYPE.PICKUP_IN_PERSON]:
    DomainUIElementEnum.DOCUMENTS_ISSUED_PICKUP_IN_PERSON_SEND_OPTION,
  [DomainDictionaryEntry.RECEIPT_TYPE.POST]: DomainUIElementEnum.DOCUMENTS_ISSUED_POST_SEND_OPTION
};

export { dictionaryPermissions };
