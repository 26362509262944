import { useTranslation } from 'react-i18next';

import { useElementVisibility } from '@libs/permission';
import { GeneratedReportsTable, ReportTypesTable } from '@libs/report/components';

import { ReportUIElementEnum } from '../config/permission';

export interface IReportsTab {
  label: string;
  value: ReportsTabEnum;
  content: JSX.Element;
  actionKey?: ReportUIElementEnum;
}

export enum ReportsTabEnum {
  REPORT_TYPES = 'types',
  REPORTS = 'reports'
}

function useReportsTabDefinitions(): Array<IReportsTab> {
  const [t] = useTranslation();
  const { checkIsElementVisible } = useElementVisibility();

  const reportsTabDefinitions = [
    {
      label: t('reports:tab.reportTypes'),
      value: ReportsTabEnum.REPORT_TYPES,
      content: <ReportTypesTable />
    },
    {
      label: t('reports:tab.reports'),
      value: ReportsTabEnum.REPORTS,
      content: <GeneratedReportsTable />,
      actionKey: ReportUIElementEnum.REPORT_LIST_GENERATED_VIEW
    }
  ];

  const visibleTabs = reportsTabDefinitions.filter(tab =>
    tab.actionKey ? checkIsElementVisible(tab.actionKey) : true
  );
  return visibleTabs;
}

export default useReportsTabDefinitions;
