import { ApplicationsApiUpdateAssignedToRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

export function assignApplicationToUser(requestData: ApplicationsApiUpdateAssignedToRequest) {
  return API.application.updateAssignedTo(requestData.applicationId, requestData.applicationAssigneeUpdateRequest, {
    ...endpointsConfig.updateAssignedTo
  });
}

function useAssignApplicationToUserMutation() {
  return useMutation(assignApplicationToUser);
}

export default useAssignApplicationToUserMutation;
