import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';

import { AutocompleteSelectField, typedNameV2, useFormV2Watch } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { usePartnershipPartnersQuery } from '../../subject';
import { PermissionFields } from '../model';

interface IProps {
  subjectId: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  applicationFolderTypeKey: string;
  onChangeCustom?: (options: optionType[]) => void;
}

type optionType = { name: string; value: string; subjectName: string };

function SelectEnterpreneurNIPField({
  subjectId,
  isLoading,
  isDisabled,
  applicationFolderTypeKey,
  onChangeCustom
}: IProps) {
  const [t] = useTranslation();

  const [options, setOptions] = useState<optionType[]>([]);
  const isPartnershipPermission = useFormV2Watch<boolean>({
    name: typedNameV2<PermissionFields>('applyToPartnership')
  });

  const { data: partnershipPartnersData } = usePartnershipPartnersQuery({ subjectId }, { enabled: Boolean(subjectId) });

  useEffect(() => {
    if (partnershipPartnersData?.partnershipPartners) {
      setOptions(
        partnershipPartnersData?.partnershipPartners
          .filter(
            item =>
              [DomainDictionaryEntry.FOLDER_STATUS.ACTIVE, DomainDictionaryEntry.FOLDER_STATUS.NEW].includes(
                item?.linkedFolder?.statusKey
              ) && item?.linkedFolder?.typeKey === applicationFolderTypeKey
          )
          .filter(Boolean)
          .map(
            item =>
              item?.nip && {
                name: item?.nip,
                value: item?.nip,
                subjectName: item?.name
              }
          )
      );
    }
  }, [applicationFolderTypeKey, partnershipPartnersData]);

  return (
    <AutocompleteSelectField
      name={typedNameV2<PermissionFields>('partnershipPartnersNip')}
      label={t('permission:field.subjectNip')}
      options={options}
      isLoading={isLoading}
      validationMultipleSelect={{ ...(!isPartnershipPermission && { required: [] }) }}
      isMultiple
      isDisabled={isDisabled}
      onChangeCustom={value => isArray(value) && onChangeCustom(value as optionType[])}
      {...(isDisabled && { tooltip: t('permission:tooltip.disableNipInfo') })}
    />
  );
}

export default SelectEnterpreneurNIPField;
