import { QueryConfig, useQuery } from 'react-query';
import { PermissionDetails } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import PermissionsQueryKeysEnum from './PermissionsQueryKeysEnum';

async function getPermission(_: any, id: string) {
  const { data } = await API.permissions.getPermission(id, {
    ...endpointsConfig.getPermission
  });
  return data;
}

function usePermissionDetailsQuery(id: string, queryConfig: QueryConfig<PermissionDetails, unknown> = {}) {
  return useQuery<PermissionDetails>([PermissionsQueryKeysEnum.PERMISSION_DETAILS, id], getPermission, queryConfig);
}

export default usePermissionDetailsQuery;
