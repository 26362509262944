import { DefaultApiFindRequest, GroupSnapshotExternal } from '@avispon/group';

import { TableFilterMapper, useCreateColumns } from '@libs/common/v2';

const useModalGroupsColumns = () => {
  const { createColumns } = useCreateColumns<GroupSnapshotExternal>({
    pathToTranslate: 'field'
  });

  const mappedFilterFields: TableFilterMapper<GroupSnapshotExternal, DefaultApiFindRequest> = {
    name: value => ({ query: value })
  };

  const columns = createColumns([
    {
      type: 'TEXT',
      accessor: 'name'
    }
  ]);

  return { columns, mappedFilterFields };
};

export default useModalGroupsColumns;
