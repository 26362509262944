import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { TravelFormPrintsSnapshot } from '@ibtm/domain';

import { TableIconButton, useTableContext } from '@libs/common/v2';

import { DomainUIElementEnum } from '@libs/domain/config';

import { PrintActionType, usePrintsActionsContext, usePrintsDetailsDataContext } from '../../../context';

interface IProps {
  row: Row<TravelFormPrintsSnapshot>;
}

function TravelFormRowActions({ row }: IProps) {
  const { original } = row;
  const [t] = useTranslation();
  const { refetch } = useTableContext();
  const { setAction } = usePrintsActionsContext();
  const {
    printDetailsData: {
      depotMP: { id: depotMPId },
      notDepotMPTooltip
    }
  } = usePrintsDetailsDataContext();
  const isDisabled = !depotMPId;

  return (
    <TableIconButton
      actionKey={DomainUIElementEnum.FOREIGN_PERMISSIONS_REGENERATE_BUTTON}
      icon="CalculatorIcon"
      tooltipTitle={isDisabled ? notDepotMPTooltip : t('prints:actions.regenerate')}
      onClick={() =>
        setAction(PrintActionType.RegenerateTravelForm, {
          travelFormPrintsSnapshot: original,
          onSuccess: refetch
        })
      }
      isDisabled={isDisabled}
    />
  );
}

export default TravelFormRowActions;
