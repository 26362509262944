export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  getCivicBoardRulesSnapshotPage: { translationKey: 'applications:api.getCivicBoardRulesSnapshotPage' },
  getCivicBoardRule: { translationKey: 'applications:api.getCivicBoardRule' },
  getPermanentPermissionSubmissionDetailsPage: {
    translationKey: 'applications:api.getPermanentPermissionSubmissionDetailsPage'
  },
  updateSingleUsePermissionSubmission: { translationKey: 'applications:api.updateSingleUsePermissionSubmission' },
  getSingleUseSubmissionItemDetailsPage: { translationKey: 'applications:api.getSingleUseSubmissionItemDetailsPage' },
  createCivicBoard: { translationKey: 'applications:api.createCivicBoard' },
  getCivicBoardsDetailsPage: { translationKey: 'applications:api.getCivicBoardsDetailsPage' },

  calculateFolderLimit: { translationKey: 'applications:api.calculateFolderLimit' },
  createPermanentPermissionSubmission: { translationKey: 'applications:api.createPermanentPermissionSubmission' },
  createCivicBoardRule: { translationKey: 'applications:api.createCivicBoardRule' },
  getSCertificateDetails: { translationKey: 'applications:api.getSCertificateDetails' },
  createSingleUsePermissionSubmission: { translationKey: 'applications:api.createSingleUsePermissionSubmission' },
  deletePermanentPermissionSubmission: { translationKey: 'applications:api.deletePermanentPermissionSubmission' },

  deleteCivicBoard: { translationKey: 'applications:api.deleteCivicBoard' },
  deleteSingleUsePermissionSubmissionItem: {
    translationKey: 'applications:api.deleteSingleUsePermissionSubmissionItem'
  },
  deleteCivicBoardRule: { translationKey: 'applications:api.deleteCivicBoardRule' },
  updatePermanentPermissionSubmission: { translationKey: 'applications:api.updatePermanentPermissionSubmission' },
  updateCivicBoardRule: { translationKey: 'applications:api.updateCivicBoardRule' },
  exportFileForEkmtCommittee: { translationKey: 'applications:api.exportFileForEkmtCommittee' },
  updateSingleUsePermissionSubmissionDecision: {
    translationKey: 'applications:api.updateSingleUsePermissionSubmissionDecision'
  },
  updateSingleUsePermissionSubmissionReceiptDate: {
    translationKey: 'applications:api.updateSingleUsePermissionSubmissionReceiptDate'
  },
  importFileFromEkmtCommittee: { translationKey: 'applications:api.importFileFromEkmtCommittee' }
};
