import { useTranslation } from 'react-i18next';
import {
  PullNotificationSnapshot,
  PullNotificationSnapshotStatus,
  PullNotificationSnapshotType
} from '@avispon/message';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Icon, IconButton, Typography } from '@libs/common/v2';
import { Theme, useTheme } from '@libs/common/v2/theme';
import { convertDateToDateTimeFormat, convertMessage, hexToRgba, important } from '@libs/common/v2/utils';

interface IProps {
  notification?: PullNotificationSnapshot;
  systemNotification?: any;
  handleClick?(): void;
}
enum SystemNotificationStatus {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS'
}

function MessagePopoverListItem({ notification, handleClick, systemNotification }: IProps) {
  const [t] = useTranslation();
  const { contrast } = useTheme();
  const classes = useStyles({
    status: notification?.status,
    type: notification?.type || (systemNotification?.type as SystemNotificationStatus),
    contrast
  });

  const getListItemIcon = () => {
    switch (systemNotification?.type) {
      case SystemNotificationStatus.ERROR:
        return 'ValidationErrorIcon';
      case SystemNotificationStatus.WARNING:
        return 'WarningOutlineIcon';
      case SystemNotificationStatus.SUCCESS:
        return 'CheckedCircleIcon';
      default:
        return 'InfoRoundedIcon';
    }
  };

  return notification ? (
    <Tooltip title={t('messages:action.view')} classes={{ popper: classes.tooltip }}>
      <div
        className={classes.wrapper}
        tabIndex={0}
        onKeyPress={({ key, code }) => {
          if (key === 'Enter' || code === 'Space') handleClick();
        }}
        onFocus={({ target }) => target.classList.add('focus')}
        onBlur={({ target }) => target.classList.remove('focus')}
        onClick={handleClick}
        role="button"
      >
        <div className={classes.listItem}>
          <Icon className={classes.icon} icon="InfoRoundedIcon" placement="bottom" />
          <div className={classes.listItemText}>
            <Typography themeVariant="textSm.normal">{convertDateToDateTimeFormat(notification.created)}</Typography>
            <Typography themeVariant="textSm.medium">
              {convertMessage(notification.subject || notification.message, false, true)}
            </Typography>
          </div>
        </div>
      </div>
    </Tooltip>
  ) : (
    <div className={classes.wrapper}>
      <div className={classes.listItemWithButton}>
        <div className={classes.listItemIconText}>
          <Icon className={classes.icon} icon={getListItemIcon()} placement="bottom" />
          <div className={classes.listItemText}>
            <Typography themeVariant="textSm.normal">{systemNotification.date}</Typography>
            <Typography themeVariant="textSm.medium">{systemNotification.message}</Typography>
          </div>
        </div>
        <IconButton icon="TrashIcon" onClick={handleClick} isBackgroundTransparent />
      </div>
    </div>
  );
}

const useStyles = makeStyles<
  Theme,
  {
    status?: PullNotificationSnapshotStatus;
    type?: PullNotificationSnapshotType | SystemNotificationStatus;
    contrast?: boolean;
  }
>(theme => ({
  tooltip: {
    zIndex: important(1300)
  },
  wrapper: {
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: hexToRgba(theme.palette.grey?.[300], 0.2)
    }
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`
  },
  listItemWithButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    paddingRight: 0
  },
  listItemText: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginLeft: ({ type }) => {
      if (type === SystemNotificationStatus.SUCCESS) return '1px';
      return 'unset';
    }
  },
  listItemIconText: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  icon: {
    marginRight: ({ type }) => {
      if (type === SystemNotificationStatus.SUCCESS) return '-1px';
      return 'unset';
    },
    '& path': {
      transform: ({ type }) => {
        if (type === SystemNotificationStatus.SUCCESS) return 'scale(1.1)';
        return 'unset';
      },
      stroke: ({ status, type, contrast }) => {
        if (contrast) {
          return theme.palette.grey[900];
        }
        if (type === SystemNotificationStatus.ERROR) {
          return theme.palette.error[700];
        }
        if (type === SystemNotificationStatus.WARNING) {
          return theme.palette.warning[700];
        }
        if (type === SystemNotificationStatus.SUCCESS) {
          return theme.palette.success[700];
        }
        if (status !== PullNotificationSnapshotStatus.READ) {
          return theme.palette.grey[400];
        }
        if (type === PullNotificationSnapshotType.NOTIFICATION) {
          return theme.palette.blue[700];
        }
        if (type === PullNotificationSnapshotType.INFO) {
          return theme.palette.primary[700];
        }
        if (type === PullNotificationSnapshotType.COMMAND) {
          return theme.palette.warning[700];
        }
        return theme.palette.grey[700];
      }
    }
  }
}));

export default MessagePopoverListItem;
