import { usePaginatedQuery } from 'react-query';
import { InvoiceSearchFilter, InvoiceSnapshot } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { parseReleaseDocumentsSuspensionPayload } from '../../utils/api-request.utils';
import { endpointsConfig } from '../endpoints-config';
import { ReleaseDocumentsQueryKeysEnum } from '../index';

const getSuspensionReleaseDocuments = async (_, params: InvoiceSearchFilter & { suspensionId: string }) => {
  const { suspensionId, queryParams } = parseReleaseDocumentsSuspensionPayload(params);
  const { data } = await API.suspensionReleaseDocuments.getInvoiceSnapshotPage(suspensionId, queryParams, {
    ...endpointsConfig.getInvoiceSnapshotPage
  });

  return { ...data, content: data.content };
};

export default function useSuspensionReleaseDocumentsQuery(params: InvoiceSearchFilter, queryConfig = {}) {
  return usePaginatedQuery<IPaginatedModel<InvoiceSnapshot>>(
    [ReleaseDocumentsQueryKeysEnum.RELEASE_DOCUMENTS_LIST, params],
    getSuspensionReleaseDocuments,
    queryConfig
  );
}
