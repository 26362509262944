import { FilterProps } from 'react-table';
import { ApplicationSnapshot } from '@ibtm/domain';

import { TableFilter } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

function ArchiveApplicationStatusMultiselectFilter({
  props
}: {
  props: React.PropsWithChildren<FilterProps<ApplicationSnapshot>>;
}) {
  const { translate } = useDictionaryTranslations();
  const filters = [
    'ibtm-domain.application.status.withdrawn',
    'ibtm-domain.application.status.cancelled',
    'ibtm-domain.application.status.issued',
    'ibtm-domain.application.status.accomplished-without-printing'
  ];

  const options = filters.map((value, id) => ({
    id,
    value,
    name: translate(DomainDictionaryEnum.APPLICATION_STATUS, value)
  }));

  return <TableFilter.MultiSelect options={options} {...props} />;
}

export default ArchiveApplicationStatusMultiselectFilter;
