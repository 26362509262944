import { useEffect } from 'react';
import { FieldPath, FieldValues, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddressUpdateRequest } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import {
  GridItem,
  GridLayout,
  InputMode,
  SwitchField,
  TextInputField,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';

import {
  ApplicationSubjectAddressTypeEnum,
  ApplicationSubjectAddressTypeFieldsEnum,
  parseApplicationSubjectLicenseAddressDetails,
  parseApplicationSubjectPermissionAddressDetails
} from '@libs/domain/application';
import { useSubjectDetailsExtendedQuery } from '@libs/domain/subject';

import { DomainUIElementEnum } from '../../../config';
import { AddressSection } from '../address-content';

interface IProps {
  subjectId: string;
  formGroupName: string | FieldPath<FieldValues>;
  hiddenFields?: Array<keyof AddressUpdateRequest>;
  inputMode?: InputMode;
  isSubjectNipFieldVisible?: boolean;
  isCountryFieldAvaliable: boolean;
}

function AdditionalAddress({
  subjectId,
  formGroupName,
  hiddenFields,
  inputMode,
  isCountryFieldAvaliable,
  isSubjectNipFieldVisible
}: IProps) {
  const [t] = useTranslation();
  const { control, setValue } = useFormV2Context();
  const { checkIsElementVisible } = useElementVisibility();
  const hideAddressFields = useWatch({
    control,
    name: `${formGroupName}.sameAsMainAddress` as FieldPath<FieldValues>
  }) as boolean;
  const { data } = useSubjectDetailsExtendedQuery(subjectId, {
    enabled: Boolean(subjectId) && checkIsElementVisible(DomainUIElementEnum.FOLDER_SUBJECT_VIEW),
    refetchOnMount: false
  });
  const getLabel = partialTranslate('address:fields');

  const getFieldName = (fieldName: any) => typedNameV2<any>(`${formGroupName}.${fieldName}`);

  useEffect(() => {
    if (data) {
      const { mainAddressOnPermission, mainAddressOnLicense, permissionSubjectNip, licenseSubjectNip } = data;
      if (formGroupName === ApplicationSubjectAddressTypeFieldsEnum.APPLICATION_PERMISSION_ADDRESS_FIELDS) {
        const parsedValues = parseApplicationSubjectPermissionAddressDetails(
          data,
          mainAddressOnPermission,
          ApplicationSubjectAddressTypeEnum.APPLICATION_PERMISSION_ADDRESS
        );

        setValue(formGroupName, parsedValues);
        setValue(`${formGroupName}.nip`, permissionSubjectNip);
        if (mainAddressOnPermission) {
          setValue(`${formGroupName}.sameAsMainAddress`, true);
        }
      }

      if (formGroupName === ApplicationSubjectAddressTypeFieldsEnum.APPLICATION_LICENSE_ADDRESS_FIELDS) {
        const parsedValues = parseApplicationSubjectLicenseAddressDetails(
          data,
          mainAddressOnLicense,
          ApplicationSubjectAddressTypeEnum.APPLICATION_LICENSE_ADDRESS
        );

        setValue(formGroupName, parsedValues);
        setValue(`${formGroupName}.nip`, licenseSubjectNip);
        if (mainAddressOnLicense) {
          setValue(`${formGroupName}.sameAsMainAddress`, true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <GridLayout itemProps={{ xs: 12, sm: 6 }}>
      <TextInputField
        name={getFieldName('name')}
        label={getLabel('name')}
        {...(!hideAddressFields && { validation: { required: [] } })}
        {...(inputMode && { inputMode })}
      />

      {isSubjectNipFieldVisible && (
        <TextInputField name={getFieldName('nip')} label={t('applications:field.subjectNip')} />
      )}

      <SwitchField
        name={getFieldName('sameAsMainAddress')}
        label={getLabel('sameAsMainAddressPartnership')}
        isRequired
        {...(inputMode && { inputMode })}
      />
      {!hideAddressFields && (
        <GridItem xs={12}>
          <AddressSection
            formGroupName={formGroupName}
            hiddenFields={hiddenFields}
            isCountryFieldAvaliable={isCountryFieldAvaliable}
            {...(inputMode && { inputProps: { inputMode } })}
          />
        </GridItem>
      )}
    </GridLayout>
  );
}

export default AdditionalAddress;
