import { useEffect } from 'react';
import _ from 'lodash';

import { useFormV2Context, useTableAdapter } from '@libs/common/v2';

function FormOperationPermissionsTableAction() {
  const adapter = useTableAdapter();
  const selectedRows = adapter?.selectedFlatRows?.map(row => row?.original);
  const { setValue } = useFormV2Context();

  useEffect(() => {
    if (!_.isEmpty(selectedRows)) {
      setValue('form.tempRows', selectedRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  return null;
}

export default FormOperationPermissionsTableAction;
