import { ReactNode } from 'react';
import { makeStyles } from '@mui/styles';

import { Tabs } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

export interface ITabsPageSidebarProps<T> {
  visibleTabs?: ReactNode;
  activeTab?: T;
  onChange: (tab: T) => void;
  width?: number;
}

function TabsPageSidebar<T>({ visibleTabs, activeTab, onChange, width }: ITabsPageSidebarProps<T>) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tabs value={activeTab} width={width} onChange={onChange}>
        {visibleTabs}
      </Tabs>
    </div>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    backgroundColor: important(theme.palette.grey[50]),
    height: '100%'
  }
}));

export default TabsPageSidebar;
