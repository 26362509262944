import { useEffect, useMemo } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { i18n } from '@libs/common';
import { AutocompleteSelectField, ComponentErrorBoundary, InputMode, useFormV2Context } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import { getYearOptionsToSelect } from './year-select-field.util';

interface IProps {
  name: FieldPath<FieldValues>;
  label?: string;
  TextFieldProps?: Partial<MuiTextFieldProps>;
  className?: string;
  setGlobalYearSelect?: (selectedYear: { name: string; value: string }) => void;
  inputMode?: InputMode;
  isRequired?: boolean;
  isDisabled?: boolean;
  isMultiple?: boolean;
}

function YearSelectField({ name, setGlobalYearSelect, ...forwardProps }: IProps) {
  const classes = useStyles();
  const yearOptions: any = useMemo(() => getYearOptionsToSelect(), []);

  const { watch, resetField } = useFormV2Context();
  const year = watch(name);

  useEffect(() => {
    if (year && year.value && setGlobalYearSelect) {
      setGlobalYearSelect({ value: year.value, name: year.name });
      if (resetField) {
        resetField(name, { keepDirty: false, defaultValue: year });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  return (
    <ComponentErrorBoundary componentName={forwardProps.label || name || 'YearSelectField'}>
      <AutocompleteSelectField
        className={clsx(classes.yearField)}
        options={yearOptions}
        name={name}
        isClearable={false}
        TextFieldProps={{ fullWidth: true, placeholder: i18n.t('year') }}
        {...forwardProps}
      />
    </ComponentErrorBoundary>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  yearField: {
    width: 150,
    margin: 8,
    '& > label': {
      color: theme.palette.grey[500],
      '& > div': {
        marginBottom: '4px',
        '& > div': {
          height: 40,
          backgroundColor: 'white',
          margin: important(0)
        }
      }
    }
  }
}));

export default YearSelectField;
