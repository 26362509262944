import { useTranslation } from 'react-i18next';
import { ViolationProceedingKreptd } from '@ibtm/domain';

import { TableButton, TableButtonMore, useRouter } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

interface IGoToProceedingsGroupButtonProps {
  proceedingKreptdId: string;
  proceedingsKreptd: ViolationProceedingKreptd[];
}

const GoToProceedingsGroupButton = ({ proceedingKreptdId, proceedingsKreptd }: IGoToProceedingsGroupButtonProps) => {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();
  const { routes } = useRouter();

  return (
    <TableButtonMore
      tooltipTitle={t('roadTransport:actions.goToProceedings')}
      groupHeaderTitle={t('roadTransport:actions.goToProceedings')}
    >
      {close => (
        <>
          {proceedingsKreptd?.map(button => {
            if (proceedingKreptdId !== button.proceedingKreptdId) {
              return (
                <TableButton
                  key={button?.proceedingKreptdId}
                  label={translate(DomainDictionaryEnum.PROCEEDING_TYPE_KREPTD, button.categoryKey, t('emptyMark'))}
                  link={routes.roadTransportDetails(button?.proceedingKreptdId)}
                  onClick={close}
                />
              );
            }

            return null;
          })}
        </>
      )}
    </TableButtonMore>
  );
};

export default GoToProceedingsGroupButton;
