import { Certificates } from '../models';

export const parseCertificate = (certificate: Certificates) => {
  if (hasCertificateBasicData(certificate)) {
    const subjectDN = certificate.certificate.subjectDN.split(',');
    const userBasicData = new Map<string, string>(
      subjectDN.map(subject => {
        const data = subject.split('=');
        if (data) {
          return [data[0], data[1]];
        }
        return null;
      })
    );
    const cn = userBasicData.get('CN');
    const validateTo = formatEndDayDate(certificate.certificate.validTo);
    return {
      name: `${cn} (${formatDateWithISO(validateTo)})`,
      value: certificate.certId
    };
  }
  return null;
};

const hasCertificateBasicData = (certificate: Certificates): boolean => {
  return certificate?.certId !== null && certificate?.certificate?.subjectDN !== null;
};

const formatEndDayDate = date => {
  if (!date) {
    return null;
  }

  const formattedDate = new Date(date.substr(0, 10));
  formattedDate.setUTCHours(23, 59, 59, 59);
  return formattedDate;
};

const formatDateWithISO = date => {
  let tempDate = date;

  if (!tempDate) {
    return null;
  }

  if (typeof tempDate === 'string') {
    const formattedDate = tempDate.substr(0, 10);
    tempDate = new Date(formattedDate);
  }

  if (tempDate instanceof Date) {
    return tempDate.toISOString().substr(0, 10);
  }

  return null;
};
