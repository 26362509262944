import { useContext } from 'react';

import DevModeContext from './DevModeContext';

function useDevMode() {
  const { isDevMode, setDevMode } = useContext(DevModeContext);

  return { isDevMode, setDevMode };
}

export default useDevMode;
