import { useTranslation } from 'react-i18next';

import { DatepickerField, GridLayout } from '@libs/common/v2';
import { convertDateToDateFormat } from '@libs/common/v2/utils';

function SuspendUserForm() {
  const [t] = useTranslation();

  return (
    <GridLayout itemProps={{ xs: 12 }}>
      <DatepickerField
        name="suspendedFrom"
        label={t('user:suspensions.field.suspendedFrom')}
        viewModeDateParser={convertDateToDateFormat}
        isRequired
      />
      <DatepickerField
        name="suspendedTo"
        label={t('user:suspensions.field.suspendedTo')}
        viewModeDateParser={convertDateToDateFormat}
        isRequired
      />
    </GridLayout>
  );
}
export default SuspendUserForm;
