import { AxiosRequestConfig } from 'axios';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '@libs/notification/api';

const deleteMyMessage = (notificationId: string) => {
  return API.message.deleteNotification({ notificationId }, {
    ...endpointsConfig.deleteNotification
  } as AxiosRequestConfig);
};

function useDeleteMyMessageMutation() {
  return useMutation(deleteMyMessage);
}

export default useDeleteMyMessageMutation;
