import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { InvoiceLite } from '@ibtm/domain';
import { isUndefined } from 'lodash';

import { GridItem, GridLayout, useFormV2Context } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DomainDictionaryEntry } from '@libs/domain/config';

import BuyerSection from '../../form/components/BuyerSection';
import CorrectionAmountsSection from '../../form/components/CorrectionAmountsSection';
import CorrectiveConfirmationSection from '../../form/components/CorrectiveConfirmationSection';
import InvoiceSection from '../../form/components/InvoiceSection';
import ReasonSection from '../../form/components/ReasonSection';
import ReceiverSection from '../../form/components/ReceiverSection';
import { ReleaseDocumentsItemsWrapper } from '../../form/items-table';
import { ICorrectionItems, ReleaseDocumentsDetailsPageParams } from '../../model';

import { WarningCorrectionAvailable } from './LinkToCorrection';

interface IProps {
  correctionItemsTable: any[];
  setCorrectionItemsTable: (table: ICorrectionItems[]) => void;
  baseUrl?: string;
  isDataLoading?: boolean;
}

function ReleaseDocumentsDetailsSections({
  correctionItemsTable,
  setCorrectionItemsTable,
  baseUrl,
  isDataLoading
}: IProps) {
  const { id, folderId } = useParams<ReleaseDocumentsDetailsPageParams>();
  const { watch } = useFormV2Context();

  const isCorrection = watch('isCorrection') as boolean;
  const corrections = watch('corrections') as Array<InvoiceLite & { statusKey: string }>;
  const correctionInvoice = useMemo(
    () => corrections?.filter(correction => correction.statusKey !== DomainDictionaryEntry.INVOICE_STATUS.CANCELED),
    [corrections]
  );

  const { formMode } = useFormV2Context();

  const { createMode } = useViewModesV2(formMode);

  return (
    <GridLayout
      itemProps={{ md: 6, xs: 12 }}
      containerProps={{
        classes: {
          container: 'pb-4 w-full'
        }
      }}
    >
      {isCorrection || createMode
        ? [
            ...(isUndefined(correctionInvoice) || correctionInvoice.length === 0 || isDataLoading
              ? []
              : [
                  <GridItem xs={12} md={12} key="warning">
                    <WarningCorrectionAvailable corrections={correctionInvoice} id={id} folderId={folderId} />
                  </GridItem>
                ]),
            <GridItem xs={12} md={6} key="invoiceSection" className="flex">
              <InvoiceSection baseUrl={baseUrl} />
            </GridItem>,
            <GridItem xs={12} md={6} key="buyerSection">
              <BuyerSection isWithoutCorrection={false} />
            </GridItem>,
            <GridItem xs={12} md={6} key="reasonSection">
              <ReasonSection />
            </GridItem>,
            <GridItem xs={12} md={6} key="correctiveConfirmationSection">
              <CorrectiveConfirmationSection />
            </GridItem>,
            <GridItem xs={12} md={12} key="fieldsSection">
              <ReleaseDocumentsItemsWrapper
                correctionItemsTable={correctionItemsTable}
                setCorrectionItemsTable={setCorrectionItemsTable}
                isDataLoading={isDataLoading}
              />
            </GridItem>,
            <GridItem xs={12} md={6} key="correctionAmountsSection">
              <CorrectionAmountsSection correctionItemsTable={correctionItemsTable} />
            </GridItem>,
            <GridItem xs={12} md={6} key="receiverSection">
              <ReceiverSection />
            </GridItem>
          ]
        : [
            ...(isUndefined(correctionInvoice) || correctionInvoice.length === 0 || isDataLoading
              ? []
              : [
                  <GridItem xs={12} md={12} key="warning">
                    <WarningCorrectionAvailable corrections={correctionInvoice} id={id} folderId={folderId} />
                  </GridItem>
                ]),
            <GridItem xs={12} md={6} key="invoiceSection-noCorrection" className="flex">
              <InvoiceSection />
            </GridItem>,
            <GridItem xs={12} md={6} key="buyerSection-noCorrection">
              <BuyerSection isWithoutCorrection />
            </GridItem>,
            <GridItem xs={12} md={12} key="fieldsSection-noCorrection">
              <ReleaseDocumentsItemsWrapper
                isOriginalDocument
                correctionItemsTable={correctionItemsTable}
                setCorrectionItemsTable={setCorrectionItemsTable}
                isDataLoading={isDataLoading}
              />
            </GridItem>
          ]}
    </GridLayout>
  );
}

export default ReleaseDocumentsDetailsSections;
