import { ReportTypeCreateRequest } from '@stack/report';

import { useMutation, useQueryCache } from '@libs/common/v2/api';

import { API, endpointsConfig, ReportQueryKeysEnum } from '..';

const createReportType = async (params: ReportTypeCreateRequest) => {
  const { data } = await API.reportTypes.createReportType(params, {
    ...endpointsConfig.createReportType
  });
  return data;
};

export default function useCreateReportTypeMutation({ onSuccess, onError }) {
  const queryCache = useQueryCache();
  return useMutation(createReportType, {
    onSuccess: (...args) => {
      queryCache.invalidateQueries(ReportQueryKeysEnum.REPORT_TYPES);
      onSuccess(...args);
    },
    onError
  });
}
