import {
  AddressCreateRequest,
  AddressUpdateRequest,
  ExploitationBaseCreateRequest,
  ExploitationBaseInspectionCreateRequest,
  ExploitationBaseInspectionUpdateRequest,
  ExploitationBaseUpdateRequest,
  Versioned
} from '@ibtm/domain';
import moment from 'moment';
import { array as YupArray, object as YupObject, string as YupString } from 'yup';

import { i18n } from '@libs/common';
import { SelectOption as SelectOptionCommon } from '@libs/common/v2';

type SelectOption<T = any> = Partial<SelectOptionCommon<T>>;

type AddresFormData = Omit<
  AddressUpdateRequest & AddressCreateRequest,
  'voivodeshipKey' | 'version' | 'countryCodeKey' | 'postCode' | 'postCity'
> & {
  postCode: string;
  postCity: string;
  countryCodeKey?: string;
  voivodeshipKey: SelectOption<string>;
  version?: number;
};

type BaseInspectionFormData = Omit<
  ExploitationBaseInspectionCreateRequest & ExploitationBaseInspectionUpdateRequest,
  'inspectionResultKey' | 'version' | 'remarks' | 'inspectionDate'
> & {
  remarks: string;
  inspectionResultKey: string;
  version?: number;
  inspectionDate: string;
};

type ExploitationBaseFormData = Omit<
  ExploitationBaseUpdateRequest & ExploitationBaseCreateRequest & Versioned,
  | 'typeKeys'
  | 'address'
  | 'statusKey'
  | 'cancelledExploitationBase'
  | 'applicationId'
  | 'exploitationBaseInspections'
  | 'version'
  | 'validFrom'
  | 'validTo'
> & {
  validFrom: string;
  validTo?: string;
  typeKeys: Array<SelectOption<string>>;
  address?: AddresFormData;
  statusKey?: SelectOption<string>;
  addressTypeKey?: SelectOption<string>;
  inspection?: BaseInspectionFormData;
  exploitationBaseInspections?: Array<BaseInspectionFormData>;
  cancelledExploitationBase?: SelectOption<string>;
  applicationId?: string;
  addressVersion?: number;
  version?: number;
};

export default ExploitationBaseFormData;

export const exploitationBaseValidationSchema = (isWithdrawnBaseVisible: boolean) =>
  YupObject<ExploitationBaseFormData>({
    typeKeys: YupArray().nullable().required(),
    address: YupObject({
      postCode: YupString().required().max(10),
      postCity: YupString().required().max(50),
      voivodeshipKey: YupObject().nullable().required(),
      city: YupString().required().max(50),
      street: YupString().nullable().max(50),
      propertyNumber: YupString().required().max(10)
    }),
    validFrom: YupString()
      .required()
      .nullable()
      .test(
        'isBeforeValidTo',
        i18n.t('exploitationBase:details.validation.validFrom'),
        function checkIsBeforeValidTo(validFrom) {
          const parent = this.parent as ExploitationBaseFormData;
          const { validTo } = parent;
          return !validTo || moment(validFrom).isBefore(validTo);
        }
      ),
    validTo: YupString()
      .notRequired()
      .nullable()
      .test(
        'isAfterValidFrom',
        i18n.t('exploitationBase:details.validation.validTo'),
        function checkIsAfterValidFrom(validTo) {
          const parent = this.parent as ExploitationBaseFormData;
          const { validFrom } = parent;
          return !validFrom || !validTo || moment(validFrom).isBefore(validTo);
        }
      ),
    ...(isWithdrawnBaseVisible && {
      cancelledExploitationBase: YupObject({
        value: YupString().nullable().required(),
        name: YupString().nullable().required()
      })
        .nullable()
        .required()
    }),
    inspection: YupObject({
      inspectionResultKey: YupString().nullable(),
      remarks: YupString()
        .nullable()
        .when('inspectionResultKey', {
          is: item => Boolean(item),
          then: YupString().required()
        }),
      inspectionDate: YupString()
        .nullable()
        .when('inspectionResultKey', {
          is: item => Boolean(item),
          then: YupString().required()
        })
    })
  });
