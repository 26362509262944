import { DocumentTemplateSnapshotExternal } from '@avispon/document-generator/dist/models';
import { AccountSnapshot } from '@avispon/user/dist/models';
import { FolderSnapshot, ProceedingKreptdCertificateDetails, ViolationProceedingKreptd } from '@ibtm/domain';

export type KreptdProceedingDetailsParams = {
  proceedingId?: string;
};

export interface IProceedingDetailsParams {
  proceedingId?: string;
  [key: string]: string | undefined;
}

export enum RoadTransportTabEnum {
  VIOLATIONS = 'VIOLATIONS',
  ADMINISTRATIVE_PROCEEDINGS = 'ADMINISTRATIVE_PROCEEDINGS',
  FOLDERS_TO_TRANSFER = 'FOLDERS_TO_TRANSFER'
}

export enum ProceedingDetailsTabEnum {
  GOOD_REPUTATION = 'GOOD_REPUTATION',
  CERTIFICATE_SUSPENSION = 'CERTIFICATE_SUSPENSION',
  PERMIT_SUSPENSION = 'PERMIT_SUSPENSION',
  INABILITY_DECLARATION = 'INABILITY_DECLARATION',
  ABILITY_RESTORATION = 'ABILITY_RESTORATION',
  VIOLATIONS = 'VIOLATIONS',
  TRANSPORT_MANAGER = 'TRANSPORT_MANAGER',
  DOCUMENTS = 'DOCUMENTS',
  IMPOSING_PENALTY = 'IMPOSING_PENALTY'
}

export enum ProceedingTypesEnum {
  GOOD_REPUTATION = 'GOOD_REPUTATION',
  CERTIFICATE_SUSPENSION = 'CERTIFICATE_SUSPENSION',
  PERMIT_SUSPENSION = 'PERMIT_SUSPENSION',
  INABILITY_DECLARATION = 'INABILITY_DECLARATION',
  ABILITY_RESTORATION = 'ABILITY_RESTORATION'
}

export enum DetailsSectionsEnum {
  PROCEEDING_INITIATION = 'PROCEEDING_INITIATION',
  DECISION = 'DECISION',
  SECOND_INSTANCE = 'SECOND_INSTANCE',
  LICENSE_EXCERPTS = 'LICENSE_EXCERPTS',
  SUSPENSION_PROCEEDING = 'SUSPENSION_PROCEEDING',
  CERTIFICATES_RETURN = 'CERTIFICATES_RETURN',
  CERTIFICATES = 'CERTIFICATES'
}

export enum RwaNumberValues {
  RWA_570 = '570',
  RWA_573 = '573'
}

export interface IViolationClient {
  id: string;
  referenceNumber: string;
  caseNumber: string;
  transferDate: string;
  transportManagerCertificateNumber: string;
  subjectName: string;
  nip: string;
  code: string;
  status: string;
  assignedTo: {
    id?: string;
    name?: string;
  };
  manager: string;
  pendingProceeding: boolean;
  rwaCaseNumber: string;
  folderId: string;
  proceedingsKreptd: ViolationProceedingKreptd[];
  proceedingKreptdId?: string;
}

export interface IProceedingClient {
  id: string;
  subjectName: string;
  subjectNip: string;
  transportManagerName: string;
  transportManagerCertificateNumber: string;
  assignedName: string;
  caseNumber: string;
  category: string;
  status: string;
  statusTransitions: string[];
  version: number;
}

export interface IFoldersToTransferClient {
  id: string;
  folderNumber: string;
  subjectName: string;
  subjectNip: string;
  status: string;
  transferredToSta: string;
  transferDate: string;
  referentName: string;
  referentId: string;
}
type ExtendedCertificatesDetails = ProceedingKreptdCertificateDetails & { id?: string; newNumber?: string };

export interface IProceedingDetailsClient {
  id: string;
  caseNumber: string;
  assignedName: string;
  subjectName: string;
  transportManagerName: string;
  transportManagerCertificateNumberFromViolation: string;
  transportManagerNameFromViolation: string;
  transportManagerSurnameFromViolation: string;
  transportManagerCertificateNumber: string;
  transportManagerId: string;
  status: string;
  subjectNip: string;
  fineImposed: boolean;
  fineImposedAvailable?: boolean;
  category: string;
  folderId: string;
  version: number;
  startDate: string;
  startDecisionDeliveryDate: string;
  startStartedBy: string;
  startRehabilitationResource: boolean;
  startInactivityReason: string;
  startOtherInactivityReason: string;
  decisionType: string;
  decisionDate: string;
  decisionNumber: string;
  decisionDeliveryDate: string;
  decisionReviewApplicationDeliveryDate: string;
  decisionWsaComplaintDeliveryDate: string;
  decisionPermissionSuspensionFrom: string;
  decisionPermissionSuspensionTo: string;
  decisionFinalDate: string;
  secondTierBpDeliveryDate: string;
  secondTierBpCaseNumber: string;
  secondTierBpDecisionNumber: string;
  secondTierBpDecisionDate: string;
  secondTierBpDecision: string;
  secondTierBpComplaintDeliveryDate: string;
  secondTierBpExecutionSuspensionDecisionDate: string;
  secondTierWsaSignature: string;
  secondTierWsaDecisionDate: string;
  secondTierWsaDecision: string;
  secondTierWsaDecisionFinalDate: string;
  secondTierCassation: boolean;
  secondTierNsaDecisionDate: string;
  secondTierNsaDecision: string;
  suspensionDecisionDate: string;
  suspensionRenewalDate: string;
  secondTierNsaExecutionSuspensionDecisionDate: string;
  statusTransitions: string[];
  certificatesReturnTimeToReturn: string;
  certificates: ExtendedCertificatesDetails[];
  licenseCopiesReturnDate: string;
  timeToReturnLicenseCopies: string;
  proceedingEntityType: string;
}

export const ProceedingEntityTypeEnum = { SUBJECT: 'SUBJECT', TRANSPORT_MANAGER: 'TRANSPORT_MANAGER' };

export interface ProceedingDetailsClient extends IViolationClient, IProceedingClient {
  proceedingInitiation: {
    proceedingInitiationDate: string;
    notificationDeliveryDate: string;
    employeeInitiatingProceeding: string;
    nonInitiationReasonKey: string;
    otherNonInitiationReason?: string;
    rehabilitation?: boolean;
  };
  decision: {
    decisionTypeKey: string;
    decisionDate: string;
    decisionNumber: string;
    decisionDeliveryDate: string;
    reconsiderationRequestDate: string;
    complaintDate: string;
    finalDate: string;
  };
  secondInstance: {
    transferDateToBP: string;
    caseNumberInBP: string;
    decisionNumberBP: string;
    decisionDateBP: string;
    decisionTypeBPKey: string;
    complaintDateBP: string;
    decisionDateOfSuspension: string;
    caseNumberInWSA: string;
    decisionDateWSA: string;
    decisionTypeKeyWSA: string;
    finalJudgementDate: string;
    cassationComplaint: boolean;
    decisionDateNSA: string;
    decisionTypeKeyNSA: string;
  };
  suspensionProceeding: {
    suspensionOrderDate: string;
    renewalDate: string;
  };
  certificatesReturn: {
    returnDeadline: string;
  };
}

export interface CertificatesServer {
  id: string;
  number: string;
  returnDate: string;
  returnNotificationDateITS: string;
  cerificateDispatchDate: string;
  dispatchNotificationDateITS: string;
  caseNumber: string;
}

export interface CertificatesClient {
  id: string;
  number: string;
  returnDate: string;
  returnNotificationDateITS: string;
  cerificateDispatchDate: string;
  dispatchNotificationDateITS: string;
  caseNumber: string;
}

export interface FoldersToTransferServer {
  id: string;
  folderNumber: string;
  subjectName: string;
  nip: string;
  statusKey: string;
  transferSTA: string;
  conductingPerson: string;
}

export interface FoldersToTransferClient {
  id: string;
  folderNumber: string;
  subjectName: string;
  nip: string;
  statusKey: string;
  transferSTA: string;
  conductingPerson: string;
}

export interface ChangeFolderRequestData {
  id: string;
  folderId: string;
}

export interface ChangeFolderFormFields {
  folder: {
    value: FolderSnapshot;
  };
}

export interface GenerateFormFields {
  documentTemplate: DocumentTemplateSnapshotExternal;
  recipient: AccountSnapshot;
}

export interface IProceedingDocumentClient {
  id: string;
  generatedDocumentName: string;
  generatedBy: string;
  generatedDate: string;
  documentFileId: string;
  documentName: string;
  addedBy: string;
  statusKey: string;
  passedToSentDate: string;
  packageNumber: string;
  sendDate: string;
  receiptAckDate: string;
  cancellationDate: string;
  cancelledBy: string;
  inputDate: string;
  subject: {
    id: string;
    name: string;
  };
  address: {
    id: string;
    version: number;
  };
  parcel: {
    receiptAckDate: string;
    sendDate: string;
    number: string;
    passedToSentDate: string;
  };
  isFinalDecision: boolean;
  version: number;
}
