import React from 'react';
import { DocumentTemplateCreateRequest } from '@avispon/document-generator/dist/models';

import { SelectOption } from '@libs/common/v2';

export * from './validation';
export * from './document-template.model';

export type DocumentTemplateForm = DocumentTemplateCreateRequest;

export type VisibleTabHeaders = {
  label: React.ReactNode;
  value: React.Key;
};

export type DocumentTemplateGroupKey = {
  active: boolean;
  value: string;
  name: string;
};

export type DocumentTemplateFormPayload = {
  description: string;
  name: string;
  targetMimeType: { value: string };
  templateFile: { name: string; fileId: string };
  groupKeys: Array<DocumentTemplateGroupKey>;
  version?: number;
  typeKey?: SelectOption<string>;
  key?: SelectOption<string>;
  externalVisibility?: boolean;
};

export const InitialFormValues: DocumentTemplateForm = {
  name: '',
  description: '',
  groupKeys: null,
  targetMimeType: null,
  templateFile: null,
  typeKey: null,
  externalVisibility: false
};

export enum DocumentTemplateTabEnum {
  GENERAL = 'general',
  VERSIONS = 'versions'
}

export enum DocumentTemplateFileStatusDictionaryEnum {
  DRAFT = 'document-generator.document-template-file-status.draft',
  PUBLISHED = 'document-generator.document-template-file-status.published'
}
export interface CreateTemplateFormTypes {
  name: string;
  description: string;
  targetMimeType: string;
  groupKeys: Array<string>;
  templateFile?: string;
  author?: { name: string };
  created?: string;
  modifier?: { name: string };
  modified?: string;
  typeKey?: string;
  key?: string;
  externalVisibility?: boolean;
}

export enum DocumentTemplateGroupEnum {
  DOCUMENT_TEMPLATE_GROUP = 'DOCUMENT_TEMPLATE_GROUP'
}

export { default as DocumentTemplateDictionaryEnum } from './DocumentTemplateDictionaryEnum';

export type DocumentTemplateParams = {
  id: string;
};
