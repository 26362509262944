import { DefaultApiListUserGroupMembershipsRequest } from '@avispon/group';
import { AccountsApiSearchRequest } from '@avispon/user';

import { NUMBER_OF_AUTOCOMPLETE_RESULTS } from '@libs/common/v2';

import { UserStatus } from '../models';

interface IPermissionGroupQueryParams extends Omit<DefaultApiListUserGroupMembershipsRequest, 'userId'> {
  userId?: string;
}

export const parsePermissionGroupsPayload = (
  params: IPermissionGroupQueryParams
): DefaultApiListUserGroupMembershipsRequest => {
  const { userId, unitIds, ...queryParams } = params;
  return {
    ...queryParams,
    userId,
    includeSubordinates: !unitIds,
    includeActiveReplacements: true
  };
};

export const parseUsersListPayload = (params: AccountsApiSearchRequest): AccountsApiSearchRequest => {
  const { unitIds, statuses, size, sort, page, ...queryParams } = params;

  return {
    ...queryParams,
    ...(unitIds && {
      includeUnassignedToUnit: false,
      unitIds
    }),
    statuses,
    includeDeleted: !statuses || statuses.includes(UserStatus.DELETED),
    size: size || NUMBER_OF_AUTOCOMPLETE_RESULTS,
    page: page || 0,
    sort: sort || []
  };
};
