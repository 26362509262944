import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TravelFormPrintsSnapshot } from '@ibtm/domain';

import { DictionarySelectField, GridLayout, Radio, SelectOption, typedNameV2, useFormV2Context } from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { TravelFormRegenerateRegenerateForm } from '@libs/domain/print/model';
import { PoolsForm } from '@libs/domain/resource';

import { RegeneratePermissionDialogLayout } from './common/RegeneratePermissionDialogLayout';
import { TravelFormNumberSelectField } from './TravelFormNumberSelectField';

export const getInitialTravelFormValues = (
  reasonsForReprinting?: SelectOption<string>
): TravelFormRegenerateRegenerateForm => ({
  pools: [
    {
      numberFrom: null,
      numberTo: null,
      amount: null
    }
  ],
  selectedNumbers: [],
  reasonsForReprinting
});

export function RegenerateTravelFormDialogContent({
  travelFormPrintsSnapshot
}: {
  travelFormPrintsSnapshot: TravelFormPrintsSnapshot;
}) {
  const [t] = useTranslation();
  const { firstFormNumber, lastFormNumber, formsAmount } = travelFormPrintsSnapshot ?? {};
  const [isPoolVisibile, setIsPoolVisibile] = useState(true);
  const { reset, getValues } = useFormV2Context();

  const handleRadioClick = (value: boolean) => {
    const reasonsForReprinting = getValues('reasonsForReprinting');
    setIsPoolVisibile(value);
    reset(getInitialTravelFormValues(reasonsForReprinting));
  };
  return (
    <RegeneratePermissionDialogLayout
      formNumbersSection={
        <GridLayout itemProps={{ xs: 12 }}>
          <div className="flex flex-wrap">
            <Radio
              label={t('prints:formField.selectPoolFormNumbers')}
              isChecked={isPoolVisibile}
              onChange={() => handleRadioClick(true)}
            />
            <Radio
              label={t('prints:formField.selectSingleFormNumbers')}
              isChecked={!isPoolVisibile}
              onChange={() => handleRadioClick(false)}
            />
          </div>

          {isPoolVisibile ? (
            <PoolsForm
              inputsProps={{
                numberFrom: { min: firstFormNumber, max: lastFormNumber },
                numberTo: { min: firstFormNumber, max: lastFormNumber },
                amount: { min: 0, max: formsAmount }
              }}
              withWrapper={false}
              isTravelForm
            />
          ) : (
            <TravelFormNumberSelectField />
          )}
        </GridLayout>
      }
      reprintSection={
        <GridLayout itemProps={{ xs: 12 }}>
          <DictionarySelectField
            name={typedNameV2<TravelFormRegenerateRegenerateForm>('reasonsForReprinting')}
            dictionaryName={DomainDictionaryEnum.REASONS_FOR_REPRINTING}
            label={t('prints:formField.rePrintReason')}
            validationSingleSelect={{ required: [] }}
            isRequired
          />
        </GridLayout>
      }
    />
  );
}
