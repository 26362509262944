import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, OnFilterChange } from 'react-table';
import { makeStyles } from '@mui/styles';

import { smallIconSize } from '@libs/common/config';
import {
  Icon,
  IconButton,
  SEARCH_TEXT_DEBOUNCE_TIME,
  TextInput,
  useDebounce,
  useHandleClearFilters
} from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

interface IProps {
  column: Column & { filterValue?: string; setFilter: (value: string) => void };
  onFilterChange?: OnFilterChange;
}

function TextFilter({ column: { filterValue, setFilter }, onFilterChange }: IProps) {
  const [t] = useTranslation();
  const [value, setValue] = useState(filterValue ?? '');
  const onChangeDebounced = useDebounce(value => {
    setFilter(value);
    onFilterChange?.();
  }, SEARCH_TEXT_DEBOUNCE_TIME);
  const classes = useStyles();

  const handleClearInput = useCallback(() => {
    setValue('');
  }, []);

  useHandleClearFilters(handleClearInput);

  const handleChange = e => {
    const newValue = e.target.value;
    setValue(newValue);
    onChangeDebounced(newValue);
  };

  const handleOnDelete = () => {
    const emptyValue = '';
    setValue(emptyValue);

    setTimeout(() => {
      setFilter(emptyValue);
      onFilterChange?.();
    }, 0);
  };

  return (
    <TextInput
      placeholder={t('other:component.table.textFilterCaption')}
      value={value}
      className={classes.root}
      InputProps={{
        endAdornment: value ? (
          <IconButton
            tooltipTitle={t('action.clear')}
            className="p-0"
            icon="CrossIcon"
            onClick={handleOnDelete}
            isBackgroundTransparent
          />
        ) : (
          <Icon icon="MagnifierIcon" iconWrapper="flex" width={smallIconSize} height={smallIconSize} />
        )
      }}
      onChange={handleChange}
    />
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    '& > div > div ': {
      padding: important(0),
      marginTop: important(0),
      '& > input': {
        padding: important('8px 0 8px 8px'),
        fontSize: important(theme.typography.textXs.normal.fontSize),
        fontWeight: important(theme.typography.textXs.normal.fontWeight),
        lineHeight: important(theme.typography.textXs.normal.lineHeight)
      }
    }
  }
}));

export default React.memo(TextFilter);
