import { useRef } from 'react';

function useMetaForm() {
  const formRef = useRef(null);

  const submit = () => {
    if (formRef) {
      formRef?.current.submit();
    }
  };

  return [formRef, { submit }];
}

export default useMetaForm;
