import { partialTranslate } from '@libs/common';
import { GridLayout, Section, TextValueField, typedNameV2 } from '@libs/common/v2';

import { IProceedingDetailsClient } from '../../../../road-transport-kreptd/models';

function TransportManagerFromViolation() {
  const getLabel = partialTranslate('transportManager:details');

  return (
    <Section title={getLabel('section.transportManagerFromViolation')} isCollapsable>
      <GridLayout itemProps={{ xs: 12 }}>
        <TextValueField
          label={getLabel('field.certificateManagerNumber')}
          name={typedNameV2<IProceedingDetailsClient>('transportManagerCertificateNumberFromViolation')}
        />
        <TextValueField
          label={getLabel('field.name')}
          name={typedNameV2<IProceedingDetailsClient>('transportManagerNameFromViolation')}
        />
        <TextValueField
          label={getLabel('field.surname')}
          name={typedNameV2<IProceedingDetailsClient>('transportManagerSurnameFromViolation')}
        />
      </GridLayout>
    </Section>
  );
}

export default TransportManagerFromViolation;
