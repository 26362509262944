import { MoneyTransferApplicationUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export type UpdateMoneyTransferParams = {
  applicationId: string;
  moneyTransferId: string;
  formData?: MoneyTransferApplicationUpdateRequest;
};

export function updateMoneyTransferApplication({
  applicationId,
  moneyTransferId,
  formData
}: UpdateMoneyTransferParams) {
  return API.moneyTransfer.updateMoneyTransferApplication(moneyTransferId, applicationId, formData, {
    ...endpointsConfig.updateMoneyTransferApplication
  });
}

function useUpdateMoneyTransferApplicationMutation() {
  return useMutation(updateMoneyTransferApplication);
}

export default useUpdateMoneyTransferApplicationMutation;
