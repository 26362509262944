import { QueryConfig, usePaginatedQuery } from 'react-query';
import { NoteDetails } from '@ibtm/domain';

import { API } from '@libs/domain/api';
import { FolderQueryKeysEnum } from '@libs/domain/folder';

import { endpointsConfig } from '../endpoints-config';

const getFolderNote = async (folderId: string) => {
  const { data } = await API.folderNote.getFolderNote(folderId, {
    ...endpointsConfig.getFolderNote
  });

  return data;
};

export default function useFolderNotesQuery(folderId: string, queryConfig: QueryConfig<NoteDetails, unknown> = {}) {
  return usePaginatedQuery([FolderQueryKeysEnum.FOLDER_NOTES, folderId], () => getFolderNote(folderId), queryConfig);
}
