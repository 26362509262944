import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button, TableLayout, useFormV2Context, useRouter } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { API } from '@libs/domain/api';
import { DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import { FolderDetailsParams, FolderDetailsTabEnum } from '@libs/domain/folder';
import useSuspensionRowActions from '@libs/domain/folder/hooks/useSuspensionRowActions';
import { useSuspensionsTable } from '@libs/domain/suspensions/hooks';

function SuspensionList() {
  const { folderId } = useParams<FolderDetailsParams>();
  const [t] = useTranslation();
  const { formMode } = useFormV2Context();
  const { editMode } = useViewModesV2(formMode);
  const { isOperatorPortal } = useDomainConfigContext();
  const { routes } = useRouter();
  const { renderTableActions } = useSuspensionRowActions();

  const tableProps = useSuspensionsTable(
    [
      'number',
      'caseNumber',
      'created',
      'authorName',
      'modified',
      'dateOfIssue',
      'dateFrom',
      'dateTo',
      'whole',
      'statusKey'
    ],
    folderId
  );

  return (
    <div>
      <TableLayout
        {...tableProps}
        pageTitle={t('folder:tab.suspensions')}
        headerActions={
          isOperatorPortal && (
            <Button
              variant="outlined"
              label={t('action.add')}
              actionKey={DomainUIElementEnum.FOLDER_SUSPENSION_CREATE_BUTTON}
              link={{
                pathname: routes.suspensionCreate(folderId),
                state: {
                  from: `${editMode ? routes.folderEdit(folderId) : routes.folderEdit(folderId)}?tab=${
                    FolderDetailsTabEnum.SUSPENSIONS
                  }`
                }
              }}
            />
          )
        }
        xlsxDownload={{
          fileName: t('folder:details.tab.suspensionsTab.list.title'),
          pathToXLSXTranslation: 'folder:details.tab.suspensions.field',
          apiRequest: params => API.suspensions.getSuspensionsPage(params)
        }}
        rowActions={renderTableActions([
          DomainUIElementEnum.SUSPENSIONS_VIEW_BUTTON,
          DomainUIElementEnum.SUSPENSIONS_EDIT_BUTTON,
          DomainUIElementEnum.SUSPENSIONS_DELETE_BUTTON
        ])}
        actionsColumnWidth={110}
        isCollapsable
        isSection
      />
    </div>
  );
}

export default SuspensionList;
