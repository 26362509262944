import { useTranslation } from 'react-i18next';
import { queryCache } from 'react-query';
import { useAppConfig } from '@libs/app-config';

import { TableLayout, useRouter, useTableRowActions } from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';
import useGenerateReport from '@libs/report/hooks/useGenerateReport';

import { API } from '@libs/domain/api';
import { ApplicationToArchiveSnapshot } from '@libs/domain/archive';
import { DomainUIElementEnum } from '@libs/domain/config';

import { ArchiveQueryKeysEnum } from '../../../common';
import {
  useApplicationArchivedTable,
  useSendApplicationsFromArchiveDialog,
  useUpdateApplicationWarningDialog
} from '../../hooks';

function ArchivedApplicationTab() {
  const [t] = useTranslation();
  const { checkIsElementVisible } = useElementVisibility();
  const tableProps = useApplicationArchivedTable(
    checkIsElementVisible(DomainUIElementEnum.ARCHIVE_APPLICATIONS_ARCHIVED_UNDO_FROM_ARCHIVE_BUTTON)
  );
  const { openSendApplicationsFromArchiveDialog } = useSendApplicationsFromArchiveDialog();
  const { openUpdateApplicationWarningDialog } = useUpdateApplicationWarningDialog();
  const { reportTypes } = useAppConfig();
  const [generateReport] = useGenerateReport(reportTypes?.listOfCases?.reportTypeName);
  const { routes } = useRouter();
  const handleGenerateReport = () => {
    generateReport();
  };

  const { renderTableActions } = useTableRowActions<ApplicationToArchiveSnapshot>([
    {
      id: DomainUIElementEnum.ARCHIVE_APPLICATIONS_ARCHIVED_SHOW_DETAILS_BUTTON,
      label: 'action.openDetails',
      link: ({ original }) => routes.archiveApplicationDetailsArchived(original.id),
      icon: 'ArrowIcon'
    },
    {
      id: DomainUIElementEnum.ARCHIVE_APPLICATIONS_ARCHIVED_UNDO_FROM_ARCHIVE_BUTTON,
      label: 'archive:action.restoreFromArchive',
      icon: 'ReturnIcon',
      onClick: ({
        row: {
          original: { id }
        }
      }) => openSendApplicationsFromArchiveDialog([id])
    },
    {
      id: DomainUIElementEnum.ARCHIVE_APPLICATIONS_ARCHIVED_GENERATE_CASE_LIST_BUTTON,
      label: 'archive:application.action.generateCasesList',
      icon: 'FileGenerateIcon',
      onClick: () => handleGenerateReport()
    },
    {
      id: DomainUIElementEnum.ARCHIVE_APPLICATIONS_ARCHIVED_ADD_NOTE_BUTTON,
      label: 'archive:application.action.note',
      icon: 'NoteIcon',
      onClick: ({ row: { original } }) =>
        openUpdateApplicationWarningDialog(
          original.id,
          () => queryCache.invalidateQueries(ArchiveQueryKeysEnum.ARCHIVED_APPLICATIONS),
          original?.version,
          original?.warning
        )
    }
  ]);

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('archive:application.companyArchive')}
      xlsxDownload={{
        fileName: t('archive:application.companyArchive'),
        pathToXLSXTranslation: 'archive:application.field',
        apiRequest: API.archiveApplications.getArchiveApplications
      }}
      rowActions={renderTableActions([
        DomainUIElementEnum.ARCHIVE_APPLICATIONS_ARCHIVED_SHOW_DETAILS_BUTTON,
        DomainUIElementEnum.ARCHIVE_APPLICATIONS_ARCHIVED_UNDO_FROM_ARCHIVE_BUTTON,
        DomainUIElementEnum.ARCHIVE_APPLICATIONS_ARCHIVED_GENERATE_CASE_LIST_BUTTON,
        DomainUIElementEnum.ARCHIVE_APPLICATIONS_ARCHIVED_ADD_NOTE_BUTTON
      ])}
      isSectionFullTab
    />
  );
}

export default ArchivedApplicationTab;
