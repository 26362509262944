import { usePaginatedQuery } from 'react-query';
import { TableOptions } from 'react-table';
import { AxiosResponse } from 'axios';

import { CamelCasePath, IPaginatedModel, useTableLayoutProps } from '@libs/common/v2';

import { FilesQueryKeysEnum } from '../../api/queries/QueryKeysEnum';
import { FilesListClientItem } from '../../models/file.model';
import { filesListParser } from '../../parsers/common';
import useFilesTableColumns from '../columns/useFilesTableColumns';

function useFilesTable(
  visibleColumns: Array<CamelCasePath<FilesListClientItem>>,
  apiGetQuery: (params) => Promise<AxiosResponse<IPaginatedModel<FilesListClientItem>>>,
  tableInitialState: Partial<TableOptions<FilesListClientItem>>
) {
  const { columns, mappedFilterFields } = useFilesTableColumns(visibleColumns);

  const getFiles = async (_, params) => {
    const { data } = await apiGetQuery(params);

    return {
      ...data,
      content: filesListParser(data.content)
    };
  };

  const useFilesQuery = params => {
    return usePaginatedQuery<IPaginatedModel<FilesListClientItem>>([FilesQueryKeysEnum.FILE_LIST, params], getFiles);
  };

  return useTableLayoutProps({
    tableHookQuery: useFilesQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      tableOptions: tableInitialState
    }
  });
}

export default useFilesTable;
