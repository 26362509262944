import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ApplicationLite, DriverCertificateDetailsExtended, DriverCertificateSearchFilter } from '@ibtm/domain';
import { get } from 'lodash';

import { AutocompleteLazyFetchSelectField, SelectOption, useFormV2Context } from '@libs/common/v2';
import { InputMode } from '@libs/common/v2/form';

import { useDictionaryTranslations } from '@libs/dictionary';
import { FieldTypes } from '@libs/meta-form/models';
import { useMapAdditionalFieldsValidationType } from '@libs/meta-form/services';

import { API } from '@libs/domain/api';
import { useApplicationDetailsQuery } from '@libs/domain/application';
import { DriverCertificatesQueryKeysEnum } from '@libs/domain/driver-certificate';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '../../../../config';
import { IApplicationDetailsPageParams } from '../../../model';

export interface IDriverCertificateSelectFieldProps {
  fieldId: string;
  label: string;
  multiple?: boolean;
  inputMode?: InputMode;
  api: {
    get: {
      accessor: string;
    };
  };
  yupValidation?: {
    required: boolean;
  };
  searchParams?: DriverCertificateSearchFilter;
}
export function DriverCertificateSelectField({
  fieldId,
  label,
  multiple,
  inputMode,
  api,
  yupValidation,
  searchParams
}: IDriverCertificateSelectFieldProps) {
  const { required } = yupValidation ?? {};
  const { translate } = useDictionaryTranslations();

  useMapAdditionalFieldsValidationType(fieldId, { type: FieldTypes.AUTOCOMPLETE, multiple });

  const { setValue } = useFormV2Context();

  const { applicationId } = useParams<IApplicationDetailsPageParams>();
  const { data } = useApplicationDetailsQuery(applicationId, { refetchOnMount: false });

  const getAccessorData = useCallback(
    (): ApplicationLite[] | ApplicationLite => get(data, api?.get?.accessor),
    [data, api]
  );

  useEffect(() => {
    const parseSelectOption = (item: ApplicationLite) => ({
      value: item?.id,
      name: translate(DomainDictionaryEnum.DRIVER_CERTIFICATE_STATUS, item?.statusKey)
        ? `${item?.number} - ${translate(DomainDictionaryEnum.DRIVER_CERTIFICATE_STATUS, item?.statusKey)}`
        : item?.number,
      id: item?.id
    });

    if (data) {
      const accessorValue = getAccessorData();
      const isGetValueArray = Array.isArray(accessorValue);

      if ((isGetValueArray && accessorValue.length === 0) || !accessorValue) {
        return;
      }
      const value: SelectOption[] | SelectOption = isGetValueArray
        ? accessorValue?.map(parseSelectOption)
        : parseSelectOption(accessorValue);

      setValue(fieldId, value);
    }
  }, [data, setValue, fieldId, api, getAccessorData, translate]);

  const renderOption = (props: SelectOption<DriverCertificateDetailsExtended>) => (
    <>
      {props?.value.number} - {translate(DomainDictionaryEnum.DRIVER_CERTIFICATE_STATUS, props?.value.statusKey)}
    </>
  );

  const getOptionLabel = (option: DriverCertificateDetailsExtended) => {
    return `${option.number} - ${translate(DomainDictionaryEnum.DRIVER_CERTIFICATE_STATUS, option.statusKey)}`;
  };

  return (
    <AutocompleteLazyFetchSelectField
      isMultiple={multiple}
      name={fieldId}
      inputMode={inputMode}
      isRequired={required}
      queryKey={DriverCertificatesQueryKeysEnum.DRIVER_CERTIFICATES_LIST}
      optionLabelParser={getOptionLabel}
      renderOption={renderOption}
      api={{
        FETCH: (searchText: string, params: { page: number; size: number }) => {
          return API.driverCertificate.getDriverCertificatesDetailsExtendedPage({
            ...params,
            folderIdIn: [data?.folder?.id],
            statusKeyIn: [DomainDictionaryEntry.DRIVER_CERTIFICATE_STATUS.ACTIVE],
            numberContains: searchText,
            ...searchParams
          });
        }
      }}
      label={label}
    />
  );
}
