import { ReputationResponseDetails } from '@ibtm/domain';

import { useCreateColumns } from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';

const useReputationQueryResultColumns = () => {
  const { createColumns } = useCreateColumns<ReputationResponseDetails>({
    pathToTranslate: 'folder:details.reputationDetailsModal.field'
  });

  const columns = createColumns([
    {
      type: 'DICTIONARY_SINGLE_SELECT',
      dictionaryName: DomainDictionaryEnum.COUNTRY_CODE,
      accessor: 'country',
      isFilterable: false,
      isSortable: false
    },
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.REPUTATION,
      accessor: 'statusKey',
      isFilterable: false,
      isSortable: false
    },
    // Po konsultacji pole na razie ukryte
    /* {
      id: 'numberOfEnterprises',
      accessor: 'numberOfEnterprises',
      Header: getLabel('numberOfEnterprises'),
      isFilterable: false,
      disableSortBy: true

    }, */
    {
      header: 'responseDate',
      type: 'DATE',
      accessor: 'answerDate',
      isFilterable: false,
      isSortable: false
    }
  ]);

  return { columns };
};

export default useReputationQueryResultColumns;
