import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import { DomainUIElementEnum } from '../../config';
import { SocialCommissionTabEnum } from '../components';
import SocialCommissionBasicRulesTable from '../components/pages/social-commission-basic-rules/SocialCommissionBasicRulesTable';
import SocialCommissionCivicBoardTable from '../components/pages/social-commission-civic-board/SocialCommissionCivicBoardTable';
import PetitionsSocialCommissionPage from '../components/pages/social-commission-petitions/PetitionsSocialCommissionPage';
import SocialCommissionTradeRulesTable from '../components/pages/social-commission-trade-rules/SocialCommissionTradeRulesTable';

export type SocialCommissonTabsConfigTypes = {
  name: string;
  label?: string;
  value: string;
  ComponentView?: React.FC;
  viewName?: UIElementNameEnum;
};

export const SocialCommissonTabsConfig = [
  {
    name: 'socialCommissionDeadlines',
    label: 'foreignPermits:tabs.socialCommission.tabs.socialCommissionDeadlines.title',
    value: SocialCommissionTabEnum.DEADLINES,
    viewName: DomainUIElementEnum.SOCIAL_COMMITTEE_DATE_OF_MEETINGS_TAB,
    ComponentView: SocialCommissionCivicBoardTable
  },
  {
    name: 'socialCommissionPetitions',
    label: 'foreignPermits:tabs.socialCommission.tabs.petitions.title',
    value: SocialCommissionTabEnum.PETITIONS,
    viewName: DomainUIElementEnum.SOCIAL_COMMITTEE_APPLICATIONS,
    ComponentView: PetitionsSocialCommissionPage
  },
  {
    name: 'socialCommissionTradeRules',
    label: 'foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.title',
    value: SocialCommissionTabEnum.TRADE_RULES,
    viewName: DomainUIElementEnum.SOCIAL_COMMITTEE_FREE_DISPOSAL_RULE_LIST_VIEW,
    ComponentView: SocialCommissionTradeRulesTable
  },
  {
    name: 'socialCommissionTradeRules',
    label: 'foreignPermits:tabs.socialCommission.tabs.SocialCommissionBasicRules.title',
    value: SocialCommissionTabEnum.BASIC_RULES,
    viewName: DomainUIElementEnum.SOCIAL_COMMITTEE_LIMIT_LIST_VIEW,
    ComponentView: SocialCommissionBasicRulesTable
  }
];
