import { partialTranslate } from '@libs/common';
import { GridLayout, NumberInputField, Section, typedNameV2 } from '@libs/common/v2';

import { TransportManagerDetailsClient } from '../../../model';

export function VehiclesAndSubjectsManagementSection() {
  const getLabel = partialTranslate('transportManager:details');

  return (
    <Section title={getLabel('section.VehiclesAndSubjectsManagement')} isCollapsable isModalSection>
      <GridLayout itemProps={{ xs: 12, md: 6 }}>
        <NumberInputField
          name={typedNameV2<TransportManagerDetailsClient>('numberOtherSubjectsRegisteredAsManager')}
          label={getLabel('field.numberOtherSubjectsRegisteredAsManager')}
          isOnlyPositiveIntegers
          isRequired
        />
        <NumberInputField
          name={typedNameV2<TransportManagerDetailsClient>('numberOtherSubjectsAppointedAsManager')}
          label={getLabel('field.numberOtherSubjectsAppointedAsManager')}
          isOnlyPositiveIntegers
          isRequired
        />
        <NumberInputField
          name={typedNameV2<TransportManagerDetailsClient>('numberOtherSubjectVehiclesRegisteredAsManager')}
          label={getLabel('field.numberOtherSubjectVehiclesRegisteredAsManager')}
          isOnlyPositiveIntegers
          isRequired
        />
        <NumberInputField
          name={typedNameV2<TransportManagerDetailsClient>('numberOtherSubjectVehiclesAppointedAsManager')}
          label={getLabel('field.numberOtherSubjectVehiclesAppointedAsManager')}
          isOnlyPositiveIntegers
          isRequired
        />
      </GridLayout>
    </Section>
  );
}
