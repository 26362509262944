import { usePaginatedQuery } from 'react-query';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { ArchiveQueryKeysEnum } from '../../../common';
import { BorrowFolderListQueryParams, BorrowFolderSnapshot } from '../../model';
import { borrowFolderListParser } from '../../utils';
import { endpointsConfig } from '../endpoints-config';

const getBorrowFoldersList = async (_, params: BorrowFolderListQueryParams) => {
  const { data } = await API.borrowFolders.getFolderRentalDetailsPage(params, {
    ...endpointsConfig.getFolderRentalDetailsPage
  });
  return { ...data, content: borrowFolderListParser(data.content) };
};

const useBorrowFolderListQuery = (params: BorrowFolderListQueryParams, queryConfig = {}) => {
  return usePaginatedQuery<IPaginatedModel<BorrowFolderSnapshot>>(
    [ArchiveQueryKeysEnum.BORROW_FOLDER_LIST, params],
    getBorrowFoldersList,
    queryConfig
  );
};

export default useBorrowFolderListQuery;
