import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface IProps {
  folderId: string;
  content: string;
}

async function createFolderNote({ folderId, content }: IProps) {
  const { data } = await API.folderNote.createFolderNote(
    folderId,
    { content },
    {
      ...endpointsConfig.createFolderNote
    }
  );

  return data;
}

function useCreateFolderNoteMutation() {
  return useMutation(createFolderNote);
}

export default useCreateFolderNoteMutation;
