import React, { forwardRef } from 'react';
import { Row } from 'react-table';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Button, ButtonLinkDefinition, IconType, useTableAdapter, useTableContext } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

interface IProps<T extends Record<string, any>> {
  onClick?: React.MouseEventHandler;
  row?: Row<T>;
  tooltipTitle?: string;
  label?: string;
  icon?: IconType;
  className?: string;
  classNameWrapper?: string;
  iconClassName?: string;
  isButtonLoading?: boolean;
  isDisabled?: boolean;
  loaderWithText?: boolean;
  actionKey?: UIElementNameEnum;
  link?: ButtonLinkDefinition;
}
/**
 * Komponent przystosowany do renderowania w liście akcji (TableButtonMore) w rzędzie.
 */
function TableButton<T extends Record<string, any>>(
  {
    iconClassName,
    icon,
    classNameWrapper,
    tooltipTitle,
    className,
    isDisabled,
    row,
    isButtonLoading,
    loaderWithText = false,
    label,
    onClick,
    actionKey,
    link
  }: IProps<T>,
  ref: React.Ref<HTMLButtonElement>
) {
  const table = useTableAdapter();
  const { isLoading, tableLoading, checkDisabled, isError } = useTableContext();
  const classes = useStyles();

  const loading = isLoading || tableLoading;

  return (
    <Button
      ref={ref}
      tooltipTitle={tooltipTitle}
      onClick={onClick}
      classNameWrapper={classNameWrapper}
      iconClassName={clsx(classes.icon, iconClassName)}
      className={clsx(classes.button, className)}
      icon={icon}
      iconHeight={24}
      iconWidth={24}
      isLoading={isButtonLoading}
      loaderWithText={loaderWithText}
      disabled={isDisabled || isError || checkDisabled?.(table, row) || loading}
      label={label}
      variant="text"
      size="small"
      type="button"
      fullWidth
      actionKey={actionKey}
      isNoMargin
      link={link}
    />
  );
}

const useStyles = makeStyles(() => ({
  button: {
    padding: '12px 53px 12px 16px',
    width: '100%',
    justifyContent: 'flex-start',
    borderRadius: 0
  },
  icon: {
    marginRight: 12,
    marginLeft: 0
  }
}));

export default React.memo(forwardRef(TableButton));
