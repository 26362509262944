import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AccountCreateCommand, UpdateAccountDataRequest } from '@avispon/user/dist/models';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as Yup from 'yup';

import { partialTranslate } from '@libs/common';
import { BackButton, ButtonsGroup, FormMode, FormV2Context, Page, PageHeader, Tab, useRouter } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';
import { AxiosErrorResponseType } from '@libs/common/v2/models';
import { unescapeValue } from '@libs/common/v2/utils';

import useAsyncErrorsHandlerV2 from '@libs/dictionary/hooks/useAsyncErrorsHandlerV2';
import { useElementVisibility } from '@libs/permission';
import {
  API,
  useCreateClientPortalUserMutation,
  useCreateUserMutation,
  useEditUserMutation,
  useUserDetailsQuery
} from '@libs/user/api';
import { UserDetailsHeader, UserTabsContent } from '@libs/user/components';
import { userFormInitialValues } from '@libs/user/config';
import * as UserLocalConfig from '@libs/user/config/user-local.config';
import {
  AdditionalFields,
  ConfigurationContextProvider,
  FieldNames,
  SectionVisibleConfig,
  UserCustomConfigurationContext
} from '@libs/user/context';
import { useDefaultValidation, useUsersTabDefinitions } from '@libs/user/hooks';
import {
  ChangeUserPasswordValidation,
  IPermissionsObject,
  IUserClientListParams,
  UserDetailsTab,
  UserFormValues
} from '@libs/user/models';
import {
  createAccountRequestParser as createAccountRequestParserDefault,
  getDetailsBreadcrumb,
  updateAccountRequestParser as updateAccountRequestParserDefault
} from '@libs/user/utils';

import { PermissionEnum } from '@libs/domain/config/permission/PermissionEnum';

import UserUIElementEnum from '../../config/permission/UserUIElementEnum';

interface UserDetailsPageProps<T extends UserFormValues> {
  formMode: FormMode;
  additionalFields?: AdditionalFields;
  visibleFields?: FieldNames[];
  visibleSections?: SectionVisibleConfig[];
  validationScheme: Yup.ObjectSchema<Partial<T>>;
  validationChangePasswordScheme?: ChangeUserPasswordValidation;
  modulePath?: string;
  hideTabs?: boolean;
  additionalSections?: ReactNode[];
  formInitialValues?: T;
  requireFields?: Record<string, boolean>;
  changeWithNewPassword?: boolean;
  isClientPortal?: boolean;
  updateAccountRequestParser?: (values: T) => UpdateAccountDataRequest;
  createAccountRequestParser?: (values: T) => AccountCreateCommand;
  onSubmitSuccess?: (form?: UseFormReturn<T, Record<string, any>>, userId?: string) => void;
  permissionsKeysObject?: IPermissionsObject;
}

function UserDetailsPage<T extends UserFormValues>({
  formMode,
  additionalFields,
  visibleFields,
  visibleSections,
  validationScheme,
  validationChangePasswordScheme = UserLocalConfig.localChangePasswordValidation(),
  modulePath = 'users',
  hideTabs,
  additionalSections,
  formInitialValues,
  requireFields,
  changeWithNewPassword,
  isClientPortal,
  updateAccountRequestParser = updateAccountRequestParserDefault,
  createAccountRequestParser = createAccountRequestParserDefault,
  onSubmitSuccess,
  permissionsKeysObject
}: UserDetailsPageProps<T>) {
  const [t] = useTranslation();
  const translateDialog = partialTranslate('user:dialog');
  const [activeTab, setActiveTab] = useState<UserDetailsTab>(UserDetailsTab.GENERAL);
  const { id: userId } = useParams<IUserClientListParams>();
  const { createMode, editMode, viewMode } = useViewModesV2(formMode);
  const { isUsingServiceNumber } = useContext(UserCustomConfigurationContext);
  const { checkIsElementVisible } = useElementVisibility();

  const userTabs = useUsersTabDefinitions(formMode);
  const visibleTabs = userTabs.filter(({ isVisible }) => isVisible);
  const visibleTabHeaders = visibleTabs.map(
    ({ label, value, isVisible, viewName }) =>
      isVisible && <Tab key={value} label={label} value={value} viewName={viewName} isHorizontalTab />
  );
  const defaultValidation = useDefaultValidation(formMode);

  const form = useForm<T>({
    mode: 'onBlur',
    criteriaMode: 'all',
    // @ts-expect-error workaround for known problem with types when using generic types with react-hook-form default values
    defaultValues: formInitialValues || userFormInitialValues,
    resolver: yupResolver(validationScheme || defaultValidation)
  });
  const {
    reset,
    formState: { errors },
    setError
  } = form;

  const { goToPage, routes } = useRouter();
  const [disabled, setDisabled] = useState<boolean>(false);
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbar();

  const { data: userDetails, isLoading, refetch: refetchUserDetails } = useUserDetailsQuery(userId);
  const { handleAsyncErrorsV2 } = useAsyncErrorsHandlerV2<T>();

  const { mutate: createUser, isLoading: isCreateUserLoading } = useCreateUserMutation();
  const { mutate: createClientPortalUser, isLoading: isCreateClientPortalUserLoading } =
    useCreateClientPortalUserMutation();
  const { mutate: editUser, isLoading: isEditLoading } = useEditUserMutation({
    onSuccess: () => {
      showSuccessSnackbar(t('user:dialog.userEditSuccess'));
      if (checkIsElementVisible(UserUIElementEnum.USERS_DETAILS_BUTTON)) {
        goToPage(modulePath === 'users' ? routes.userDetails(userId) : routes.userClientPortalDetails(userId));
        refetchUserDetails();
      } else {
        goToPage(routes.usersList());
      }
    },
    onError: error => {
      handleAsyncErrorsV2(error as AxiosErrorResponseType<T>, setError);
    }
  });

  const isNotVerifiedUser = useMemo(
    () => Boolean(userDetails?.unverifiedEmail) && userDetails?.email === null,
    [userDetails]
  );

  useEffect(() => {
    if (userDetails) {
      const userEmail = { email: isNotVerifiedUser ? userDetails?.unverifiedEmail : userDetails?.email };
      const parsedDetails = { ...userDetails, position: userDetails.positionKey, ...userEmail };

      // @ts-expect-error workaround for known problem with types when using generic types with react-hook-form
      reset(parsedDetails);
      if (editMode && !userDetails.isEditable) {
        showErrorSnackbar(t('user:error.userNotEditable', unescapeValue({ name: userDetails.serviceNumber })));
        goToPage(modulePath === 'users' ? routes.userDetails(userId) : routes.userClientPortalDetails(userId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  useEffect(() => {
    API.group
      .listMyOrganizationUnits({
        permissionNames: [PermissionEnum.GROUP_ADMIN]
      })
      .then(response => {
        setDisabled(response.data.organizationUnits.length === 0);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(
    data => {
      if (createMode) {
        const options = {
          onSuccess: ({ id }) => {
            showSuccessSnackbar(translateDialog('userCreateSuccess'));
            if (onSubmitSuccess) {
              onSubmitSuccess(form, id);
            } else {
              goToPage(modulePath === 'users' ? routes.userDetails(id) : routes.userClientPortalDetails(id));
            }
          }
        };
        const parsedRequestData = createAccountRequestParser(data);

        if (isClientPortal) {
          createClientPortalUser(parsedRequestData, options);
        } else {
          createUser(parsedRequestData, options);
        }
      } else {
        editUser({ accountId: userId, request: updateAccountRequestParser(data) });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createMode]
  );

  const values = useMemo(
    () => ({
      formMode,
      errors,
      loading: isLoading,
      isSubmitting: isCreateUserLoading || isCreateClientPortalUserLoading || isEditLoading,
      ...form
    }),
    [errors, form, formMode, isCreateUserLoading, isCreateClientPortalUserLoading, isEditLoading, isLoading]
  );

  const title = useMemo(() => {
    if (createMode) {
      return isClientPortal ? t('user:newClientPortalUser') : t('user:newUser');
    }

    if (editMode) {
      return isClientPortal ? t('user:editClientPortalUser') : t('user:editUser');
    }

    return isClientPortal ? t('user:userClientPortalDetailsTitle') : t('user:userDetailsTitle');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createMode, editMode, isClientPortal]);

  return (
    // @ts-expect-error workaround for known problem with types when using generic types with react-hook-form
    <FormV2Context.Provider value={values}>
      <ConfigurationContextProvider
        formMode={formMode}
        additionalFields={additionalFields}
        visibleFields={visibleFields}
        visibleSections={visibleSections}
        additionalSections={additionalSections}
      >
        <Page
          header={
            <PageHeader
              title={title}
              breadcrumbs={
                getDetailsBreadcrumb({
                  formMode,
                  serviceNumber: _.get(userDetails, 'serviceNumber'),
                  email: _.get(userDetails, 'email'),
                  isLoading,
                  login: _.get(userDetails, 'login'),
                  modulePath,
                  isUsingServiceNumber,
                  isClientPortal
                }) as never
              }
              rightSideContent={
                <ButtonsGroup>
                  {viewMode && (
                    <BackButton
                      onClick={() =>
                        isClientPortal ? goToPage(routes.userClientPortalList()) : goToPage(routes.usersList())
                      }
                    />
                  )}
                  <UserDetailsHeader
                    formMode={formMode}
                    modulePath={modulePath}
                    userId={userId}
                    validationChangePasswordScheme={validationChangePasswordScheme}
                    handleSubmit={form.handleSubmit(handleSubmit)}
                    isSubmitting={isCreateUserLoading || isEditLoading || isCreateClientPortalUserLoading}
                    isChangeWithNewPassword={changeWithNewPassword}
                    userDetails={userDetails}
                    refetchUserDetails={refetchUserDetails}
                    permissionsKeysObject={permissionsKeysObject}
                  />
                </ButtonsGroup>
              }
            />
          }
          content={
            <UserTabsContent
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              visibleTabHeaders={visibleTabHeaders}
              requireFields={requireFields}
              isDisabled={disabled}
              isTabsHide={hideTabs}
              isClientPortal={isClientPortal}
            />
          }
        />
      </ConfigurationContextProvider>
    </FormV2Context.Provider>
  );
}

export default UserDetailsPage;
