import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { DepotTransferChangeStatusCreateRequest } from '@ibtm/domain';
import { makeStyles } from '@mui/styles';

import { TableLayout, useConfirmDialog, useTableRowActions } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { calc, important } from '@libs/common/v2/utils';

import { useAuth } from '@libs/auth/hooks';

import { API } from '@libs/domain/api';
import { DomainUIElementEnum } from '@libs/domain/config';
import { IDepotTransferClient } from '@libs/domain/resource';

import { ResourceQueryKeysEnum, useChangeDepotTransferStatusMutation, useDepotManagersListQuery } from '../../../api';
import { useDepotTransfersTable } from '../../../hooks';
import { depotTransfersListParser } from '../../../utils';

function DepotTransfersTable() {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const [confirm] = useConfirmDialog();
  const { user } = useAuth();
  const { data: depotManagersList } = useDepotManagersListQuery({ userId: user?.personalData?.id });
  const { showSnackbar } = useSnackbar();
  const ordersTableProps = useDepotTransfersTable();
  const { mutate: changeDepotTransferStatus } = useChangeDepotTransferStatusMutation();
  const classes = useStyles();

  const handleApprove = (transferId: string) => {
    confirm({
      title: t('resource:messages.confirmTitle'),
      message: t('resource:messages.formReceiptConfirm'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        const requestData: DepotTransferChangeStatusCreateRequest = {
          transferId,
          operation: 'CONFIRM'
        };
        changeDepotTransferStatus(requestData, {
          onSuccess: async () => {
            await queryCache.invalidateQueries(ResourceQueryKeysEnum.DEPOT_TRANSFERS_LIST);
            setConfirmLoading(false);
            showSnackbar('success', t('resource:messages.confirmDepotTransferSuccess'));
            closeDialog();
          },
          onError: () => {
            setConfirmLoading(false);
          }
        });
      }
    });
  };

  const handleDelete = (transferId: string) => {
    confirm({
      title: t('resource:messages.deleteDepotTransferTitle'),
      message: t('resource:messages.deleteDepotTransferConfirm'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        const requestData: DepotTransferChangeStatusCreateRequest = {
          transferId,
          operation: 'REJECT'
        };

        changeDepotTransferStatus(requestData, {
          onSuccess: async () => {
            await queryCache.invalidateQueries(ResourceQueryKeysEnum.DEPOT_TRANSFERS_LIST);
            setConfirmLoading(false);
            showSnackbar('success', t('resource:messages.deleteDepotTransferSuccess'));
            closeDialog();
          },
          onError: () => {
            setConfirmLoading(false);
          }
        });
      },
      confirmType: 'danger'
    });
  };

  const { renderTableActions } = useTableRowActions<IDepotTransferClient>(
    [
      {
        id: DomainUIElementEnum.RESOURCES_TRANSFERS_CONFIRM_RESOURCE_BUTTON,
        label: 'resource:actions.confirmFormReceipt',
        onClick: ({ row: { original } }) => handleApprove(original?.id),
        icon: 'ApproveIcon',
        isHidden: ({ row: { original } }) => !(depotManagersList?.depotIds || []).includes(original?.targetDepot?.id)
      },
      {
        id: DomainUIElementEnum.RESOURCES_TRANSFERS_DELETE_BUTTON,
        onClick: ({ row: { original } }) => handleDelete(original?.id),
        icon: 'TrashIcon',
        label: 'other:component.table.deleteRecord',
        isHidden: ({ row: { original } }) =>
          !(depotManagersList?.depotIds || []).includes(original?.sourceDepot?.id) &&
          !(depotManagersList?.depotIds || []).includes(original?.targetDepot?.id)
      }
    ],
    !!depotManagersList
  );

  return (
    <TableLayout
      {...ordersTableProps}
      pageTitle={t('resource:list.depotTransfers')}
      xlsxDownload={{
        fileName: t('resource:list.depotTransfers'),
        pathToXLSXTranslation: 'resource:fields',
        apiRequest: params =>
          API.depotTransfer
            .getDepotTransferSnapshotPage(params)
            .then(res => ({ ...res, data: { ...res.data, content: depotTransfersListParser(res.data.content) } }))
      }}
      rowActions={renderTableActions([
        DomainUIElementEnum.RESOURCES_TRANSFERS_CONFIRM_RESOURCE_BUTTON,
        DomainUIElementEnum.RESOURCES_TRANSFERS_DELETE_BUTTON
      ])}
      sectionContentClassName={classes.sectionContent}
      isSection
    />
  );
}

const useStyles = makeStyles({
  sectionContent: {
    height: important(calc('100% - 100px'))
  }
});

export default DepotTransfersTable;
