export enum FinancialSecurityStatusEnum {
  ACTIVE = 'ibtm-domain.finance-security-status.current',
  EXPIRED = 'ibtm-domain.finance-security-status.expired',
  NEW = 'ibtm-domain.finance-security-status.new'
}

export enum CurrencyTypeEnum {
  PLN = 'ibtm-domain.currency-code.pln',
  EUR = 'ibtm-domain.currency-code.eur'
}

export type FinancialSecurityActions = 'create' | 'details' | 'edit' | 'expire' | 'restore' | 'delete' | 'search';

export type FinancialSecurityFieldIds =
  | 'typeKey'
  | 'expirationDate'
  | 'amountPln'
  | 'amountEur'
  | 'exchangeRate'
  | 'releaseDate'
  | 'entrepreneur'
  | 'notes'
  | 'statusKey'
  | 'modified'
  | 'modifier'
  | 'modifier';

export type VehiclesReportedForApprovalFields =
  | 'numberOfBCategoryVehicles'
  | 'numberOfCCategoryVehicles'
  | 'numberOfVehicles'
  | 'foreignPermissionVehiclesNumber'
  | 'foreignPermissionVehiclesNumberAboveThreeAndHalfTons'
  | 'foreignPermissionVehiclesNumberBelowThreeAndHalfTons';

export type FinancialSecuritySummaryFields =
  | 'presentedCollateralPLN'
  | 'presentedCollateralEURO'
  | 'requiredCollateralPLN'
  | 'requiredCollateralEURO'
  | 'missingOrRedundantCollateralPLN'
  | 'missingOrRedundantCollateralEURO';

export type FinancialSecurityFields = {
  typeKey?: {
    key: string;
    value: string;
  };
  releaseDate?: string;
  expirationDate?: string;
  currencyKey?: {
    key: string;
    value: string;
  };
  amount?: number;
  exchangeAmount?: number;
  exchangeCurrency?: string;
  notes?: string;
  exchangeRate?: number;
  exchangeRateFromDate?: string;
  statusKey?: {
    key: string;
    value: string;
  };
  created?: string;
  author?: string;
  modified?: string;
  modifier?: string;
  version?: number;
  currencyExchangeRateId?: string;
};
