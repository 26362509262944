import { type SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { useDownloadQuery, useFileDownload } from '@libs/file';

interface IFileObject {
  id?: string;
  [key: string]: any;
}

function useDownloadDocumentFile({
  onSuccess,
  successMessage
}: { onSuccess?: () => void; successMessage?: string } = {}) {
  const [fileData, setFileData] = useState<IFileObject>(null);
  const { download } = useFileDownload();
  const { showSuccessSnackbar } = useSnackbar();
  const onSuccessRef = useRef(() => {});
  const [t] = useTranslation();

  const { isFetching } = useDownloadQuery(
    { fileId: fileData?.id },
    {
      enabled: Boolean(fileData),
      onSuccess: async data => {
        await download(() => Promise.resolve(data));
        showSuccessSnackbar(successMessage ?? t('document:message.generateDocumentDownloadSuccess'));
        setFileData(null);
        onSuccess?.();
        onSuccessRef.current();
      }
    }
  );

  return {
    downloadFile: (file: SetStateAction<IFileObject>, onDownloadSuccess?: () => void) => {
      setFileData(file);
      if (onDownloadSuccess) {
        onSuccessRef.current = onDownloadSuccess;
      }
    },
    isFetching
  };
}
export default useDownloadDocumentFile;
