import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { TableButtonDelete } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useRemoveApplicationForeignPermissionMutation } from '../../../api';
import { PermissionsRecordsQueryKeysEnum } from '../../../api/queries/useGetForeignPermissionApplicationRecords';

interface IProps {
  applicationId: string;
  foreignPermissionRecordId: string;
}

export function DeleteApplicationForeignPermissionButton({ applicationId, foreignPermissionRecordId }: IProps) {
  const { isLoading, mutate } = useRemoveApplicationForeignPermissionMutation();
  const queryCache = useQueryCache();
  const { showSuccessSnackbar } = useSnackbar();
  const [t] = useTranslation();
  return (
    <TableButtonDelete
      onClick={() => {
        mutate(
          { applicationId, foreignPermissionRecordId },
          {
            onSuccess: () => {
              showSuccessSnackbar(t('success.delete'));
              queryCache.invalidateQueries([
                PermissionsRecordsQueryKeysEnum.FOREIGN_PERMISSIONS_RECORDS,
                { applicationId }
              ]);
            }
          }
        );
      }}
      isButtonLoading={isLoading}
    />
  );
}
