import { useMemo } from 'react';
import { PluginHook, TableOptions } from 'react-table';
import { ApplicationSearchFilter, ApplicationSnapshot } from '@ibtm/domain';

import { IExtendedColumn, useTableLayoutProps } from '@libs/common/v2';

import { useContextSelector } from '@libs/dictionary';

import { useApplicationsQuery } from '../api';

import { applicationSortParamsConverted } from './useApplicationTableColumns';
import { getApplicationListMappedFilterField, useApplicationOperatorPortalVisibleColumns } from '.';

const initialTableOptions: Partial<TableOptions<ApplicationSnapshot>> = {
  initialState: {
    sortBy: [{ id: 'created', desc: true }]
  }
};

const useApplicationsTable = ({
  tableOptions = initialTableOptions,
  visibleColumns,
  queryParams,
  tablePlugins,
  hiddenColumns,
  tableHookQueryConfig,
  tableData
}: {
  visibleColumns: Array<IExtendedColumn<ApplicationSnapshot>>;
  tableOptions?: Partial<TableOptions<ApplicationSnapshot>>;
  queryParams?: ApplicationSearchFilter;
  tablePlugins?: Array<PluginHook<ApplicationSnapshot>>;
  hiddenColumns?: Array<string>;
  tableHookQueryConfig?: Record<string, unknown>;
  initialParamsConverter?: Record<string, (value) => unknown>;
  tableData?: ApplicationSnapshot[];
}) => {
  const dictionaryData = useContextSelector(({ context }) => context.context.data.dictionary);
  const defaultColumns = useApplicationOperatorPortalVisibleColumns();
  const applicationListMappedFilterField = getApplicationListMappedFilterField(true);
  const columns = useMemo(
    () => {
      let resultColumns = visibleColumns || defaultColumns;
      if (hiddenColumns?.length > 0) {
        resultColumns = resultColumns.filter(column => !hiddenColumns.includes(column.id));
      }
      return resultColumns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dictionaryData]
  );

  return useTableLayoutProps({
    ...(tableData ? { tableQuery: tableData } : { tableHookQuery: useApplicationsQuery }),
    tableHookOptions: {
      columns,
      filterConverter: applicationListMappedFilterField,
      tableOptions,
      tablePlugins,
      sortByConverter: applicationSortParamsConverted
    },
    tableHookQueryConfig,
    tableHookQueryInitialParams: queryParams as Record<string, unknown>
  });
};

export default useApplicationsTable;
