/**
 * Funkcja dostosowuje string do wstawienia do fn set z lodash
 */

export default function prepareFieldName(name) {
  const splittedName = name.split('.');
  if (!Array.isArray(splittedName)) {
    return name;
  }
  return splittedName
    .map((item, index) => {
      if (Number.isInteger(item)) {
        return `[${item}]`;
      }
      if (index === 0) {
        return item;
      }
      return `.${item}`;
    })
    .join('');
}
