import React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonLinkDefinition } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

import TableIconButton from './TableIconButton';

interface IProps {
  onClick?: () => void;
  tooltipTitle?: string;
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  link?: ButtonLinkDefinition;
  actionKey?: UIElementNameEnum;
}

function TableButtonEdit({ isDisabled, onClick, className, tooltipTitle, link, isLoading, actionKey }: IProps) {
  const [t] = useTranslation();

  return (
    <TableIconButton
      tooltipTitle={tooltipTitle || t('other:component.table.edit')}
      className={className}
      onClick={onClick}
      icon="EditIcon"
      link={link}
      isDisabled={isDisabled || isLoading}
      actionKey={actionKey}
    />
  );
}

export default React.memo(TableButtonEdit);
