import { TableCell as MuiTableCell, TableRow as MuiTableRow, TableRowClassKey } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { TableButtonFilters, Theme } from '@libs/common/v2';
import { calc, important } from '@libs/common/v2/utils';

import { useTableAdapter, useTableContext } from '../hooks';
import { ISpecialColumnEnum, TableClassNames } from '../model';
import { getStickyLeftStyle, getStickyRightStyle } from '../utils';

import TableButtonClearFilters from './button/TableButtonClearFilters';

interface IProps {
  rowClasses?: Partial<Record<TableRowClassKey, string>>;
  isTableGrey?: boolean;
  tableContext: ReturnType<typeof useTableContext>;
  tableAdapter: ReturnType<typeof useTableAdapter>;
}

const FilterWrapper = ({ children }) => {
  const {
    state: { filtersPanelOpen }
  } = useTableAdapter();
  return <div {...(!filtersPanelOpen && { style: { display: 'none' } })}>{children}</div>;
};

function TableFiltersPanel({ rowClasses, isTableGrey, tableAdapter, tableContext }: IProps) {
  const table = tableAdapter;
  const {
    headerGroups,
    state: { filtersPanelOpen },
    data
  } = table;
  const { actionsColumnWidth, selectionColumnWidth, checkDisabled, isError } = tableContext;
  const hasSelectColumn = headerGroups?.[0]?.headers.some(column => column.id === ISpecialColumnEnum.SELECTION);
  const hasActionColumn = headerGroups?.[0]?.headers.some(column => column.id === ISpecialColumnEnum.ACTION);

  const classes = useStyles({
    filtersPanelOpen,
    isTableGrey,
    hasActionColumn,
    hasSelectColumn,
    hasData: data?.length ?? 0
  });

  const renderTableCell = (column, index) => {
    const cellClassName = clsx(classes.cell, { [classes.first]: index === 0 });

    switch (column.id) {
      case ISpecialColumnEnum.SELECTION:
        return (
          <MuiTableCell
            key={index}
            {...column.getHeaderProps(props => getStickyLeftStyle(props, selectionColumnWidth))}
            className={clsx(
              classes.stickyHeaderCell,
              TableClassNames.STICKY_LEFT_COLUMN,
              { [classes.isVisible]: !data?.length },
              cellClassName
            )}
            component="div"
          />
        );
      case ISpecialColumnEnum.ACTION:
        return (
          <MuiTableCell
            key={index}
            {...column.getHeaderProps(props => getStickyRightStyle(props, actionsColumnWidth))}
            className={clsx(
              TableClassNames.STICKY_RIGHT_COLUMN,
              classes.stickyHeaderCell,
              classes.actionCell,
              { [classes.isVisible]: !data?.length },
              cellClassName
            )}
            component="div"
          >
            <div className={classes.actionWrapper}>
              <TableButtonClearFilters />
              <TableButtonFilters rowActions />
            </div>
          </MuiTableCell>
        );
      default:
        return (
          <MuiTableCell
            key={index}
            {...column.getHeaderProps()}
            style={{
              boxSizing: 'border-box',
              minWidth: '0px',
              flex: `${column.width} 0 auto`,
              position: 'relative',
              width: column.width
            }}
            className={cellClassName}
            component="div"
          >
            <div
              style={{
                width: calc(`100% - ${20}px`),
                marginLeft: 10
              }}
              data-testid={`tableHeaderFilter-${column?.id}`}
            >
              {column.Filter ? column.render('Filter', { onFilterChange: table.onFilterChange }) : null}
            </div>
          </MuiTableCell>
        );
    }
  };

  return !checkDisabled(table) && !isError ? (
    <FilterWrapper>
      <div className={classes.rowWrapper}>
        <MuiTableRow
          component="div"
          classes={rowClasses}
          className={clsx(classes.root)}
          {...headerGroups?.[0]?.getHeaderGroupProps()}
        >
          {headerGroups?.[0]?.headers.map((column, index) => renderTableCell(column, index))}
          {(!hasActionColumn || !data?.length) && (
            <MuiTableCell
              className={clsx(
                classes.clearWithoutActionColumn,
                classes.stickyHeaderCell,
                classes.cell,
                classes.actionCell
              )}
              component="div"
            >
              <div className={classes.actionWrapper}>
                <TableButtonClearFilters />
                <TableButtonFilters rowActions />
              </div>
            </MuiTableCell>
          )}
        </MuiTableRow>
      </div>
    </FilterWrapper>
  ) : null;
}

const useStyles = makeStyles<
  Theme,
  {
    filtersPanelOpen: boolean;
    isTableGrey: boolean;
    hasActionColumn: boolean;
    hasSelectColumn: boolean;
    hasData: boolean;
  }
>(theme => ({
  rowWrapper: {
    width: 'auto',
    display: 'flex'
  },
  root: {
    position: 'sticky',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    top: 50,
    height: 'auto',
    opacity: ({ filtersPanelOpen }) => (filtersPanelOpen ? 1 : 0),
    transition: 'all 250ms',
    backgroundColor: ({ isTableGrey }) => (isTableGrey ? theme.palette.white : theme.palette?.grey[50]),
    display: important('inline-flex'),
    flex: '1 0 auto',
    padding: 0,
    paddingLeft: ({ hasSelectColumn, hasData }) => (!hasSelectColumn || !hasData) && 32
  },
  cell: {
    backgroundColor: 'inherit',
    display: 'flex',
    alignItems: 'center',
    height: 53,
    paddingTop: 10,
    paddingBottom: 10,
    transition: 'padding 125ms ease-out',
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  actionCell: {
    justifyContent: 'flex-end',
    transition: 'backgroundColor 125ms ease-out',
    // eslint-disable-next-line consistent-return
    backgroundColor: ({ isTableGrey, hasActionColumn }) => {
      if (!hasActionColumn) {
        return isTableGrey ? theme.palette.white : theme.palette.grey[50];
      }
      if (!isTableGrey) {
        return theme.palette.grey[50];
      }
      if (isTableGrey) {
        return theme.palette.grey[50];
      }
    }
  },
  stickyHeaderCell: {
    zIndex: 99
  },
  clearWithoutActionColumn: {
    display: 'flex',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    right: 0,
    padding: 0,
    flex: 'none'
  },
  isVisible: {
    display: important('none')
  },
  actionWrapper: {
    width: '100%',
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    marginRight: 16,
    paddingLeft: 16,
    gap: 4
  }
}));

export default TableFiltersPanel;
