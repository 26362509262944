import { ReportTypeApiGetReportTypesRequest } from '@stack/report';

import { CamelCasePath, SelectOption, TableFilterMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO } from '@libs/common/v2/utils';

import { ReportSourcesFilter } from '../components/ui';
import { ReportType } from '../model';

// @ts-ignore circular reference
function useReportTypesTableColumns(visibleColumns: Array<CamelCasePath<ReportType>>) {
  const { createColumns } = useCreateColumns<ReportType>({
    pathToTranslate: 'reports:fields'
  });

  // @ts-ignore circular reference
  const mappedFilterFields: TableFilterMapper<ReportType, ReportTypeApiGetReportTypesRequest> = {
    name: value => ({ nameFragment: value }),
    description: value => ({ descriptionFragment: value }),
    sourceName: (value: SelectOption[]) => ({ sourceIds: value.map(i => i.id) }),
    modified: ({ dateFrom, dateTo }: DateRangeISO) => ({ modifiedMin: dateFrom || '', modifiedMax: dateTo || '' }),
    created: ({ dateFrom, dateTo }: DateRangeISO) => ({ createdMin: dateFrom || '', createdMax: dateTo || '' })
  };

  const columns = createColumns(
    [
      // @ts-ignore circular reference
      {
        type: 'TEXT',
        accessor: 'name',
        width: 600
      },
      {
        type: 'TEXT',
        accessor: 'description',
        width: 250
      },
      {
        accessor: 'sourceName',
        header: 'source',
        type: 'CUSTOM_COLUMN',
        isSortable: false,
        filter: props => <ReportSourcesFilter isMultiple {...props} />,
        width: 250
      },
      {
        type: 'DATE_TIME',
        accessor: 'created'
      },
      {
        type: 'DATE_TIME',
        accessor: 'modified'
      }
    ],
    visibleColumns
  );

  // @ts-ignore circular reference
  return { columns, mappedFilterFields };
}

export default useReportTypesTableColumns;
