import { INotificationRoutes } from '@libs/notification/models';

export const NotificationRoutes: INotificationRoutes = {
  messagesList: () => '/messages',
  messageCreate: () => '/messages/create',

  adminMessagesList: () => '/administration/notifications',
  adminMessageCreate: () => '/administration/notifications/create',
  adminMessageDetails: (id: string) => `/administration/notifications/${id}`
};
