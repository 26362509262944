import { useContext, useMemo } from 'react';
import { PluginHook } from 'react-table';

import { useAdditionalColumns, useMultipleSelectColumn, useTableLayoutProps } from '@libs/common/v2';

import { useTransitScheduleStopsQuery } from '../api';
import SelectActionTableColumn from '../components/SelectActionTableColumn';
import { ScheduleApplicationGroupsEnum, TransitStopDetailsApp } from '../model';
import { TransitScheduleContext } from '../pages';

import useTransitScheduleColumns from './useTransitScheduleColumns';

function useTransitScheduleTable(viewMode: boolean): any {
  const { applicationTypeKey, transitScheduleId } = useContext(TransitScheduleContext);
  const { columns, mappedFilterFields } = useTransitScheduleColumns();
  const tablePluginSelect = useMultipleSelectColumn(SelectActionTableColumn);
  const tablePluginsWithAdditionalColumns = useMultipleSelectColumn(SelectActionTableColumn);

  const tablePlugins = useMemo(() => {
    if (viewMode) {
      return [];
    }

    if (
      applicationTypeKey.includes(ScheduleApplicationGroupsEnum.SPO_UE) ||
      applicationTypeKey.includes(ScheduleApplicationGroupsEnum.SPO_OPINION)
    ) {
      return [tablePluginsWithAdditionalColumns, useAdditionalColumns];
    }

    return [tablePluginSelect];
  }, [
    viewMode,
    tablePluginSelect,
    tablePluginsWithAdditionalColumns,
    applicationTypeKey
  ]) as PluginHook<TransitStopDetailsApp>[];

  return useTableLayoutProps({
    tableHookQuery: useTransitScheduleStopsQuery,
    tableHookQueryInitialParams: {
      transitScheduleId,
      includeDeleted: false
    },
    tableHookOptions: {
      columns,
      arrayPaths: ['departures'],
      filterConverter: mappedFilterFields,
      tablePlugins
    }
  });
}

export default useTransitScheduleTable;
