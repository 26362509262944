import { useTranslation } from 'react-i18next';

import { GridLayout, Section, Value, WarningInformation } from '@libs/common/v2';

import { DictionaryValue } from '@libs/dictionary';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import { usePrintsDetailsDataContext } from '../../context';
import { useGetDepotType } from '../../hooks';

function DepotSection() {
  const [t] = useTranslation();
  const { isDepotMP, isDepotMPD } = useGetDepotType();
  const {
    printDetailsData: {
      depotMPD: { id: depotMPDId, name: depotMPDName },
      depotMP: { id: depotMPId, name: depotMPsName },
      moreThanOneDepotMPDTooltip,
      moreThanOneDepotMPTooltip
    }
  } = usePrintsDetailsDataContext();

  const {
    DEPOT_TYPE: { MP, MPD }
  } = DomainDictionaryEntry;

  const isDataLoadedNoMpd = !depotMPDId && !depotMPId;

  return (
    <Section isCollapsable title={t('prints:field.resourceSectionTitle')}>
      <GridLayout itemProps={{ xs: 12 }}>
        {!isDataLoadedNoMpd ? (
          <>
            <Value label={isDepotMP && isDepotMPD ? t('prints:field.depotNames') : t('prints:field.depotName')}>
              <>
                {isDepotMP && depotMPId && (
                  <>
                    <DictionaryValue dictionaryEntryType={DomainDictionaryEnum.DEPOT_TYPE} value={MP} />
                    {` ${depotMPsName}`}
                  </>
                )}
                {isDepotMP && depotMPId && isDepotMPD && depotMPDId && <>{', '}</>}
                {isDepotMPD && depotMPDId && (
                  <>
                    <DictionaryValue dictionaryEntryType={DomainDictionaryEnum.DEPOT_TYPE} value={MPD} />
                    {` ${depotMPDName}`}
                  </>
                )}
              </>
            </Value>
            {isDepotMP && moreThanOneDepotMPTooltip && (
              <WarningInformation content={moreThanOneDepotMPTooltip} gridClassName="mt-24" />
            )}
            {isDepotMPD && moreThanOneDepotMPDTooltip && (
              <WarningInformation content={moreThanOneDepotMPDTooltip} gridClassName="mt-24" />
            )}
          </>
        ) : (
          <WarningInformation content={t('prints:messages.noPrintingDepotAssigned')} />
        )}
      </GridLayout>
    </Section>
  );
}

export default DepotSection;
