import { useTranslation } from 'react-i18next';

import { NewRowPlacementEnum, TableButton, TableIconButton, useTableAdapter, useTableContext } from '@libs/common/v2';

interface IProps<T extends Record<string, any>> {
  row: T;
  placement: NewRowPlacementEnum;
  isIcon?: boolean;
}

function TableButtonAddBeforeAfterRow<T extends Record<string, any>>({ row, placement, isIcon }: IProps<T>) {
  const [t] = useTranslation();
  const tableInstance = useTableAdapter();
  const tableContext = useTableContext();
  const handleClick = () => {
    tableInstance.addNewBeforeAfterRow(row, tableInstance, tableContext, placement);
  };

  if (isIcon) {
    return (
      <TableIconButton
        icon="AddIcon"
        tooltipTitle={
          placement === NewRowPlacementEnum.BEFORE ? t('action.addNewRowBefore') : t('action.addNewRowAfter')
        }
        onClick={handleClick}
      />
    );
  }

  return (
    <TableButton
      label={placement === NewRowPlacementEnum.BEFORE ? t('action.addNewRowBefore') : t('action.addNewRowAfter')}
      onClick={handleClick}
    />
  );
}

export default TableButtonAddBeforeAfterRow;
