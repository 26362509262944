import { MisspellApplicationDataRecordUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { PermissionUpdateRequest } from '../../model/misspell-application.model';
import { endpointsConfig } from '../endpoints-config';

const updateApplicationPermission = async ({
  applicationId,
  permissionUpdateRequest
}: PermissionUpdateRequest<MisspellApplicationDataRecordUpdateRequest>) => {
  const { data } = await API.applicationMisspellData.updateApplicationPermission(
    applicationId,
    permissionUpdateRequest,
    { ...endpointsConfig.updateApplicationPermission }
  );
  return data;
};

function useUpdateApplicationMisspellPermissionMutation() {
  return useMutation(updateApplicationPermission);
}

export default useUpdateApplicationMisspellPermissionMutation;
