import React from 'react';

import { useDialog } from '@libs/common/v2';

const useResourceDialog = () => {
  const { openDialog } = useDialog();

  const open = (componentReference: string | React.FunctionComponent<any> | React.ComponentClass<any, any>, props) => {
    openDialog(({ closeDialog }) => {
      return React.createElement(componentReference, { ...props, closeDialog });
    });
  };

  return {
    openDialog: open
  };
};

export default useResourceDialog;
