import { useTableLayoutProps } from '@libs/common/v2';

import { usePermissionGroupQuery } from '@libs/user/api';
import { usePermissionGroupColumns } from '@libs/user/hooks/index';

function usePermissionGroupsTable(userId?: string) {
  const { columns, mappedFilterFields, mappedSortFields } = usePermissionGroupColumns();

  return useTableLayoutProps({
    tableHookQuery: usePermissionGroupQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    },
    tableHookQueryInitialParams: {
      userId
    }
  });
}

export default usePermissionGroupsTable;
