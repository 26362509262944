import { ReputationRequestDetails } from '@ibtm/domain';

import { useTableLayoutProps } from '@libs/common/v2';

import useEntrepreneurSectionColumns from './useEntrepreneurSectionColumns';
import usePartnershipColumns from './usePartnershipColumns';

function useReputationsTable(data: ReputationRequestDetails[], isPartnership = false) {
  const { columns: columnsEntrepreneur } = useEntrepreneurSectionColumns();
  const { columns: columnsPartnership } = usePartnershipColumns();

  const columns = isPartnership ? columnsPartnership : columnsEntrepreneur;

  return useTableLayoutProps<ReputationRequestDetails, ReputationRequestDetails>({
    tableQuery: data || [],
    tableHookOptions: {
      columns,
      tableOptions: {
        manualPagination: false
      }
    }
  });
}

export default useReputationsTable;
