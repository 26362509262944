import { ForeignPermissionSearchFilter as ForeignPermissionSearchFilterClient } from '@ibtm/client-domain';
import { ForeignPermissionSearchFilter } from '@ibtm/domain';
import { omit } from 'lodash';

export interface ISingleAuthorizationQueryParams {
  ids: Array<string>;
  maxYear: number;
  statusKeyIn: Array<string>;
}

export const parseFolderSingleAuthorizationPayload = (
  queryParams: ISingleAuthorizationQueryParams
): ForeignPermissionSearchFilter | ForeignPermissionSearchFilterClient => {
  const { ids, maxYear, statusKeyIn } = queryParams;
  const params = omit(queryParams, ['ids', 'maxYear', 'statusKeyIn']);

  return {
    ...params,
    folderIdIn: ids?.length ? [...ids] : undefined,
    yearEquals: maxYear,
    statusKeyIn
  };
};
