import { NavigateOptions, To, useLocation, useNavigate } from 'react-router-dom';
import { useAppConfig } from '@libs/app-config';

import { RoutesElementMapper } from '../../router';

function useRouter() {
  const navigate = useNavigate();
  const location = useLocation();
  const routes = RoutesElementMapper;
  const { raw: appConfig } = useAppConfig();

  const goToPage = (route: To, params?: NavigateOptions) => {
    return navigate(route, {
      ...params
    });
  };

  const goBack = () => {
    if (location.key !== 'default') {
      return navigate(-1);
    }
    return navigate(appConfig?.app.mainPage, { replace: true });
  };

  return {
    routes,
    goToPage,
    goBack,
    location,
    navigate
  };
}

export default useRouter;
