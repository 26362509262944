import { AlertCreateRequest } from '@stack/report';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '@libs/alert/api';

const createAlert = async (requestData: AlertCreateRequest) => {
  const { data } = await API.alert.addAlert(requestData, {
    ...endpointsConfig.create
  });

  return data;
};

export default function useCreateAlertMutation() {
  return useMutation(createAlert);
}
