import { Grid, GridProps, GridTypeMap } from '@mui/material';

import { UIElementNameEnum } from '@libs/permission/models';

function GridItem({
  children,
  actionKey,
  ...forwardProps
}: Omit<GridProps<GridTypeMap['defaultComponent']>, 'item'> & { actionKey?: UIElementNameEnum }) {
  return (
    <Grid item {...forwardProps}>
      {children}
    </Grid>
  );
}

export default GridItem;
