import { DomainUIElementEnum } from '../../config/permission';
import { FolderDetailsTabEnum } from '../model';

export const TAB_PERMISSIONS = {
  [FolderDetailsTabEnum.ADDRESSES]: DomainUIElementEnum.FOLDER_ADDRESS_VIEW,
  [FolderDetailsTabEnum.BASIC_DATA]: DomainUIElementEnum.FOLDER_BASIC_DATA_VIEW,
  [FolderDetailsTabEnum.SUBJECT]: DomainUIElementEnum.FOLDER_SUBJECT_VIEW,
  [FolderDetailsTabEnum.CERTIFICATE_S]: DomainUIElementEnum.FOLDER_CERTIFICATE_S_VIEW,
  [FolderDetailsTabEnum.SINGLE_AUTHORIZATION]: DomainUIElementEnum.FOLDER_BASIC_LIMIT_VIEW,
  [FolderDetailsTabEnum.EKMT]: DomainUIElementEnum.FOLDER_EKMT_VIEW,
  [FolderDetailsTabEnum.ORDERS]: DomainUIElementEnum.FOLDER_ORDER_DETAILS,
  [FolderDetailsTabEnum.PERMISSIONS]: DomainUIElementEnum.FOLDER_PERMISSION_VIEW,
  [FolderDetailsTabEnum.APPLICATIONS]: DomainUIElementEnum.FOLDER_APPLICATION_VIEW,
  [FolderDetailsTabEnum.FINANCIAL_SECURITY]: DomainUIElementEnum.FOLDER_FINANCIAL_SECURITY_VIEW,
  [FolderDetailsTabEnum.TRANSPORT_MANAGER]: DomainUIElementEnum.FOLDER_TRANSPORT_MANAGER_VIEW,
  [FolderDetailsTabEnum.INSURANCE_POLICIES]: DomainUIElementEnum.FOLDER_INSURANCE_POLICY_VIEW,
  [FolderDetailsTabEnum.DRIVERS]: DomainUIElementEnum.FOLDER_DRIVER_VIEW,
  [FolderDetailsTabEnum.VEHICLES]: DomainUIElementEnum.FOLDER_VEHICLE_VIEW,
  [FolderDetailsTabEnum.EXPLOATATION_BASES]: DomainUIElementEnum.FOLDER_EXPLOITATION_BASE_VIEW,
  [FolderDetailsTabEnum.PROCEEDINGS]: DomainUIElementEnum.FOLDER_PROCEEDING_VIEW,
  [FolderDetailsTabEnum.SUSPENSIONS]: DomainUIElementEnum.FOLDER_SUSPENSION_VIEW,
  [FolderDetailsTabEnum.RENTALS]: DomainUIElementEnum.FOLDER_RENTAL_VIEW,
  [FolderDetailsTabEnum.LOCKS]: DomainUIElementEnum.FOLDER_LOCK_VIEW,
  [FolderDetailsTabEnum.NOTE]: DomainUIElementEnum.FOLDER_NOTE_VIEW,
  [FolderDetailsTabEnum.HISTORY]: DomainUIElementEnum.FOLDER_HISTORY_VIEW,
  [FolderDetailsTabEnum.PROXIES]: DomainUIElementEnum.FOLDER_PROXY_VIEW
};
