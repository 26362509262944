import { useFilters, useFlexLayout, usePagination, useResizeColumns, useRowSelect, useSortBy } from 'react-table';

import { useFiltersPanel, useTableStateHook } from './custom-plugins';

export * from './custom-plugins';
export * from './mutable-table';
export * from './table';
export { default as useForwardedSelectedRowsAction } from './useForwardedSelectedRowsAction';
export { useHandleClearFilters } from './useHandleClearFilters';
export { useRerenderOnTableEvent } from './useRerenderOnTableEvent';
export { useSubscribeTableEvent } from './useSubscribeTableEvent';
export { useTableEventsRef } from './useTableEventsRef';
export { useGetScrollPosition } from './useScrollPosition';

export const CommonTablePlugins = [
  useFilters,
  useSortBy,
  useResizeColumns,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useFiltersPanel,
  useTableStateHook
];
