import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PullNotificationSnapshot, PullNotificationSnapshotStatus } from '@avispon/message/dist/models';
import { useSnackbar } from '@enigma/fe-ui';

import {
  TableButton,
  TableButtonDelete,
  TableButtonMore,
  TableButtonView,
  useConfirmDialog,
  useTableContext
} from '@libs/common/v2';

import { useAcceptNotificationMutation, useDeleteMyMessageMutation } from '@libs/notification/api';
import { MessageDetailsDialog } from '@libs/notification/components';

interface IProps {
  notification: PullNotificationSnapshot;
}

function MyMessagesTableRowActions({ notification }: IProps) {
  const [t] = useTranslation();
  const { refetch } = useTableContext();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: deleteMyMessage } = useDeleteMyMessageMutation();
  const [confirm] = useConfirmDialog();
  const [isOpen, setIsOpen] = useState(false);
  const { mutate: acceptNotification } = useAcceptNotificationMutation();

  const handleOpenNotification = useCallback(
    () => {
      setIsOpen(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notification]
  );

  const deleteNotification = useCallback(() => {
    confirm({
      title: t('messages:dialog.deleteItem'),
      message: t('messages:dialog.messageDeleteConfirm'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        deleteMyMessage(notification.id, {
          onSuccess: () => {
            showSuccessSnackbar(t('dialog.deleteSuccess'));
            refetch();
            closeDialog();
          },
          onSettled: () => {
            setConfirmLoading(false);
          }
        });
      },
      confirmType: 'danger'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification.id]);

  const handleAcceptNotification = useCallback(
    (closePopup: () => void) => {
      acceptNotification(
        { notificationId: notification.id },
        { onSuccess: () => showSuccessSnackbar(t('messages:dialog.confirmSuccess')) }
      );
      closePopup();
      refetch();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notification.id]
  );

  const isMoreButtonsVisible = useMemo(() => {
    return notification.blocking && notification.status !== PullNotificationSnapshotStatus.ACCEPTED;
  }, [notification.blocking, notification.status]);

  return (
    <>
      {isMoreButtonsVisible && (
        <TableButtonMore>
          {close => (
            <>
              <TableButton label={t('messages:action.confirm')} onClick={() => handleAcceptNotification(close)} />
              <TableButton
                label={t('messages:action.view')}
                onClick={() => {
                  handleOpenNotification();
                  close();
                }}
              />
            </>
          )}
        </TableButtonMore>
      )}
      <TableButtonDelete onClick={deleteNotification} />
      {isOpen && <MessageDetailsDialog notification={notification} isOpen={isOpen} setIsOpen={setIsOpen} />}
      {!isMoreButtonsVisible && <TableButtonView onClick={handleOpenNotification} />}
    </>
  );
}

export default MyMessagesTableRowActions;
