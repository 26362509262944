import { InsurancePolicyDetails } from '@ibtm/domain';
import _ from 'lodash';

import { convertCalendarDate } from '@libs/common/v2/utils';

import { InsurancePolicySnapshot } from '@libs/domain/folder';

export const parseInsurancePoliciesListResults = (data: InsurancePolicyDetails[]): InsurancePolicySnapshot[] =>
  data.map(obj => ({
    ..._.pick<InsurancePolicyDetails>(obj, ['id', 'grantDate', 'value', 'name', 'version']),
    validFrom: convertCalendarDate(obj.validDateRange?.from),
    validTo: convertCalendarDate(obj.validDateRange?.to),
    grantDate: convertCalendarDate(obj.grantDate)
  }));
