import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function deleteProxy({ proxyId }: { proxyId: string }) {
  return API.proxy.deleteProxy(proxyId, {
    ...endpointsConfig.deleteProxy
  });
}

function deleteProxyClient({ proxyId }: { proxyId: string }) {
  return API.client.proxy.deleteProxy(proxyId, '', '', {
    ...endpointsConfig.deleteProxy
  });
}

function useDeleteProxyMutation() {
  const { getQuery } = useGetApiQueryByPerspective();

  return useMutation(getQuery(deleteProxyClient, deleteProxy));
}

export default useDeleteProxyMutation;
