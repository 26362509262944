import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, TableLayout } from '@libs/common/v2';
import { IButtonAction } from '@libs/common/v2/components/button/DropdownButton';

import { API } from '@libs/domain/api';

import { useResourceDialog, useResourceObjectAssociationPoolsTable } from '../../../hooks';
import { IResourceObjectAssociationPoolClient } from '../../../model';
import { resourceObjectAssociationPoolsParser } from '../../../utils';
import { ResourceObjectOrderForm } from '../../forms';

import ResourceObjectAssociationTableRowActions from './ResourceObjectAssociationTableRowActions';

function ResourceObjectAssociationPoolsTable() {
  const [t] = useTranslation();

  const tableProps = useResourceObjectAssociationPoolsTable();
  const { openDialog } = useResourceDialog();

  const proceedingsActionsRowRender = useCallback(
    ({ original }: { original: IResourceObjectAssociationPoolClient }) => (
      <ResourceObjectAssociationTableRowActions resourceObject={original} />
    ),
    []
  );

  const handleResourceObjectOrders = () => {
    openDialog(ResourceObjectOrderForm, {});
  };

  const actionButtons: IButtonAction[] = [
    {
      label: t('resource:actions.orderResourceObjects'),
      onClick: handleResourceObjectOrders
    }
  ];

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('resource:list.resourceObjectsList')}
      xlsxDownload={{
        fileName: t('resource:list.resourceTypesListTitle'),
        pathToXLSXTranslation: 'resource:fields',
        apiRequest: params =>
          API.client.resourceObjects.getResourceObjectAssociationPoolSnapshot('', params).then(res => ({
            ...res,
            data: { ...res.data, content: resourceObjectAssociationPoolsParser(res.data.content) }
          }))
      }}
      mobileHeaderActions={actionButtons}
      headerActions={
        <Button
          label={t('resource:actions.orderResourceObjects')}
          onClick={handleResourceObjectOrders}
          variant="outlined"
          classNameWrapper="ml-16"
          isNoMargin
        />
      }
      rowActions={proceedingsActionsRowRender}
    />
  );
}

export default ResourceObjectAssociationPoolsTable;
