import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRowSelect } from 'react-table';

import { CamelCasePath, DropdownListButton, useMultipleSelectColumn, useTableLayoutProps } from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';

import { DomainDictionaryEntry, DomainUIElementEnum } from '@libs/domain/config';

import { useResourceObjectPoolsQuery } from '../../api';
import useResourceObjectGapAndCorrectPoolsQuery from '../../api/queries/resource-object-pools/useResourceObjectGapAndCorrectPoolsQuery';
import { BatchDisposalsForm } from '../../components/forms';
import { DepotTypes, ResourceObjectPoolClient } from '../../model';
import { useResourceObjectPoolsColumns } from '../columns';
import { useResourceDialog } from '../dialog';

function SelectionActionRender(selected: string[], handleClose: () => void) {
  return <Actions selectedIds={selected} handleClose={handleClose} />;
}

function Actions({ selectedIds, handleClose }: { selectedIds: string[]; handleClose: () => void }) {
  const [t] = useTranslation();
  const { openDialog } = useResourceDialog();

  const deleteForms = () => {
    openDialog(BatchDisposalsForm, { selectedIds });
  };

  return (
    <DropdownListButton
      label={t('resource:actions.batchDisposals')}
      onClick={() => {
        deleteForms();
        handleClose();
      }}
      actionKey={DomainUIElementEnum.RESOURCES_DETAILS_BATCH_DISPOSALS_BUTTON}
    />
  );
}

const excludedStateKeyIn = [
  DomainDictionaryEntry.RESOURCE_FORM_STATE.RELEASED,
  DomainDictionaryEntry.RESOURCE_FORM_STATE.ISSUED_TO_ASSOCIATION,
  DomainDictionaryEntry.RESOURCE_FORM_STATE.ISSUED_TO_ENTREPRENEURS,
  DomainDictionaryEntry.RESOURCE_FORM_STATE.ADOPTED_BY_ASSOCIATION,
  DomainDictionaryEntry.RESOURCE_FORM_STATE.RETURNED_TO_GITD,
  DomainDictionaryEntry.RESOURCE_FORM_STATE.RETURNED_TO_ASSOCIATION,
  DomainDictionaryEntry.RESOURCE_FORM_STATE.PERMIT_RETURNED_TO_GITD,
  DomainDictionaryEntry.RESOURCE_FORM_STATE.EXTERNAL_RELEASED,
  DomainDictionaryEntry.RESOURCE_FORM_STATE.RETURNED
];

function useResourceObjectPoolsTable({ depotId, code }: { depotId: string; code: DepotTypes }) {
  const multiSelectColumns = [useRowSelect, useMultipleSelectColumn(SelectionActionRender)];
  const { checkIsElementVisible } = useElementVisibility();

  const allPlugins = useMemo(() => {
    if (code === DepotTypes.MGB && checkIsElementVisible(DomainUIElementEnum.RESOURCES_DEFECTIVE_CHANGE)) {
      return multiSelectColumns;
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const getColumnsForDepot = (type: DepotTypes) => {
    switch (type) {
      case DepotTypes.MPD:
        return ['group', 'stateKey', 'name', 'numberFrom', 'numberTo', 'amount', 'admissionDate'];
      case DepotTypes.MPBIK:
        return ['group', 'stateKey', 'country', 'name', 'numberFrom', 'numberTo', 'amount', 'admissionDate'];
      default:
        return ['group', 'stateKey', 'country', 'name', 'numberFrom', 'numberTo', 'amount', 'admissionDate'];
    }
  };

  const { columns, mappedFilterFields, mappedSortFields } = useResourceObjectPoolsColumns(
    getColumnsForDepot(code) as Array<CamelCasePath<ResourceObjectPoolClient>>,
    excludedStateKeyIn
  );

  return useTableLayoutProps({
    tableHookQuery: code === DepotTypes.MPBIK ? useResourceObjectGapAndCorrectPoolsQuery : useResourceObjectPoolsQuery,
    tableHookOptions: {
      columns,
      tablePlugins: allPlugins,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    },
    tableHookQueryInitialParams: {
      depotIdIn: [depotId],
      excludedStateKeyIn
    }
  });
}

export default useResourceObjectPoolsTable;
