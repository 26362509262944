import { ProceedingsApiUpdateProceedingRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function updateProceeding(requestData: ProceedingsApiUpdateProceedingRequest) {
  return API.proceeding.updateProceeding(requestData.proceedingId, requestData.proceedingUpdateRequest, {
    ...endpointsConfig.editProceeding
  });
}

function useUpdateProceedingMutation() {
  return useMutation(updateProceeding);
}

export default useUpdateProceedingMutation;
