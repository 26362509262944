import { QueryConfig, useQuery } from 'react-query';
import { AlertTypeSnapshot } from '@stack/report';

import { AlertQueryKeysEnum, API, endpointsConfig } from '@libs/alert/api';

const getAlert = async (id: string) => {
  const { data } = await API.alert.getAlert(id, {
    ...endpointsConfig.getAlert
  });

  return data;
};

export default function useAlertDetailsQuery(id: string, queryConfig: QueryConfig<AlertTypeSnapshot, unknown> = {}) {
  return useQuery<AlertTypeSnapshot>([AlertQueryKeysEnum.ALERT_ITEM, id], () => getAlert(id), queryConfig);
}
