import { DriversApiExpireDriverRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function expire(requestData: DriversApiExpireDriverRequest) {
  return API.drivers.expireDriver(requestData.driverId, requestData.expireDriverRequest, {
    ...endpointsConfig.expireDriver
  });
}

function useExpireMutation() {
  return useMutation(expire);
}

export default useExpireMutation;
