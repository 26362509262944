import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceItemDetails } from '@ibtm/domain';

import { ITableLayoutColumn, TableLayout } from '@libs/common/v2';

import useCorrectionTableItemsColumns from '../../hooks/useCorrectionTableItemsColumns';
import { ICorrectionItems } from '../../model';

import { useViewEditCorrectionCalculation } from './hooks/useViewEditCorrectionCalculation';

interface IProps {
  correctionItemsTable: ICorrectionItems[];
  setCorrectionItemsTable: (table: ICorrectionItems[]) => void;
  items: InvoiceItemDetails[];
  headerActions?: ReactNode;
  isSuspension?: boolean;
  isDataLoading?: boolean;
}

/**
 * Tabela dla widoku edit na view (bez widoku oryginalnego dokumentu wydania)
 */
export const ViewEditCorrectionItemsTable = ({
  correctionItemsTable,
  setCorrectionItemsTable,
  items,
  headerActions,
  isSuspension,
  isDataLoading
}: IProps) => {
  const [t] = useTranslation();

  const columns = useCorrectionTableItemsColumns({
    isCreateMode: false,
    isWZZ: false,
    isFullAmount: false,
    isSuspension
  });

  const { calculateItemsDetails } = useViewEditCorrectionCalculation(isSuspension);

  useEffect(() => {
    if (items) {
      const tableItems = calculateItemsDetails(items);

      setCorrectionItemsTable(tableItems);
    }
  }, [calculateItemsDetails, items, setCorrectionItemsTable]);

  return (
    <TableLayout<ICorrectionItems, ICorrectionItems>
      tableQuery={correctionItemsTable}
      pageTitle={t('releaseDocuments:create.sections.fields')}
      isSection
      key={`view-table-items${isSuspension ? '-suspension' : ''}`}
      isFilterEnabled={false}
      isPaginated={false}
      isRefreshEnabled={false}
      isLoading={isDataLoading}
      tableHookOptions={{
        columns: columns as ITableLayoutColumn<any, ICorrectionItems>[],
        filterConverter: {},
        sortByConverter: {}
      }}
      headerActions={headerActions}
    />
  );
};
