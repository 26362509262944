import { useTranslation } from 'react-i18next';
import { SCertificatesSearchFilter as SCertificatesSearchFilterClient } from '@ibtm/client-domain';
import { SCertificatesSearchFilter } from '@ibtm/domain';

import { CamelCasePath, ColumnTypesEnum, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';

import { DomainDictionaryEnum, DomainQuickchangeableDictionaryEnum } from '@libs/domain/config';

import { SCertificatesSnapshot } from '../model';

interface DictionaryFilterObject<T = string> {
  value: T;
}

export function useSCertificatesColumns(
  visibleColumns: Array<CamelCasePath<SCertificatesSnapshot>>,
  isTableMutable: boolean
) {
  const [t] = useTranslation();
  const { createColumns } = useCreateColumns<SCertificatesSnapshot, SCertificatesSnapshot>({
    pathToTranslate: 'sCertificates:field',
    isMutableTable: isTableMutable
  });

  const mappedFilterFields: TableFilterMapper<
    SCertificatesSnapshot,
    SCertificatesSearchFilter | SCertificatesSearchFilterClient
  > = {
    statusKey: (statuses: DictionaryFilterObject[]) => ({
      statusFilter: { values: statuses.map(({ value }) => value), exclude: false }
    }),
    subjectName: (subjectNameContains: string) => ({ subjectNameContains }),
    folderNumber: (folderNumber: string) => ({ folderNumberContains: folderNumber }),
    vehiclePlateNumber: (vehiclesRegistrationNumber: string) => ({
      vehiclePlateNumberContains: vehiclesRegistrationNumber
    }),
    number: (number: string) => ({ numberContains: number }),
    vehicleBrandKey: (vehicleBrands: DictionaryFilterObject) => {
      return { vehicleBrandKeyIn: [vehicleBrands.value] };
    },
    vehicleModelKey: (vehicleModels: DictionaryFilterObject) => {
      return { vehicleModelKeyIn: [vehicleModels.value] };
    },
    vehicleType: (vehicleTypes: DictionaryFilterObject[]) => ({
      vehicleTypeIn: vehicleTypes.map(({ value }) => value)
    }),
    applicationNumber: (applicationNumberContains: string) => ({ applicationNumberContains }),
    witd: (witd: DictionaryFilterObject<boolean>) => ({ includeWitd: witd.value }),
    vehicleVin: (vehicleVin: string) => ({ vinContains: vehicleVin }),
    euroClassKey: (euroClassKeys: DictionaryFilterObject[]) => ({
      euroClassKeyIn: euroClassKeys.map(({ value }) => value)
    })
  };

  const mappedSortFields: TableSortMapper<SCertificatesSnapshot> = {
    subjectName: 'application.subject.name',
    folderNumber: 'application.folder.number',
    applicationNumber: 'application.number',
    vehicleBrandKey: 'vehicle.brandKey',
    vehicleModelKey: 'vehicle.modelKey',
    vehicleType: 'vehicle.typeKey',
    vehiclePlateNumber: 'vehicle.plateNumber',
    euroClassKey: 'vehicle.euroClassKey'
  };

  const columns = createColumns(
    [
      {
        accessor: 'statusKey',
        type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
        dictionaryName: DomainDictionaryEnum.CERTIFICATE_S_STATUS,
        xlsxAccessor: 'statusKey',
        header: 'status'
      },
      {
        accessor: 'subject.name',
        type: ColumnTypesEnum.TEXT
      },
      {
        accessor: 'folder.number',
        type: ColumnTypesEnum.TEXT
      },
      {
        accessor: 'vehiclePlateNumber',
        header: 'vehicleRegistrationNumber',
        type: ColumnTypesEnum.TEXT
      },
      {
        accessor: 'vehicleVin',
        type: ColumnTypesEnum.TEXT
      },
      {
        accessor: 'number',
        type: ColumnTypesEnum.TEXT,
        header: 'number'
      },
      {
        id: 'vehicleBrandKey',
        accessor: 'vehicleBrandKeyTranslation',
        type: ColumnTypesEnum.QUICK_DICTIONARY_SINGLE_SELECT,
        quickDictionaryName: DomainQuickchangeableDictionaryEnum.VEHICLE_BRAND,
        header: 'vehicleBrand'
      },
      {
        id: 'vehicleModelKey',
        accessor: 'vehicleModelKeyTranslation',
        header: 'vehicleModel',
        type: ColumnTypesEnum.QUICK_DICTIONARY_SINGLE_SELECT,
        quickDictionaryName: 'VEHICLE_MODEL'
      },
      {
        accessor: 'vehicleType',
        type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
        dictionaryName: DomainDictionaryEnum.VEHICLE_TYPE
      },
      {
        accessor: 'applicationNumber',
        type: ColumnTypesEnum.TEXT
      },
      {
        accessor: 'witd',
        header: 'WITD',
        type: ColumnTypesEnum.BOOLEAN,
        tooltipTitle: ({ witd }) => (witd ? t('action.yes') : t('action.no'))
      },
      {
        accessor: 'euroClassKey',
        type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
        dictionaryName: DomainDictionaryEnum.EURO_CLASS,
        xlsxAccessor: 'euroClassKey',
        header: 'euro'
      },
      {
        accessor: 'validFrom',
        type: ColumnTypesEnum.DATE,
        isEditable: true
      },
      {
        accessor: 'printDate',
        type: ColumnTypesEnum.DATE
      },
      {
        accessor: 'compatibilityCertificateNumber',
        type: ColumnTypesEnum.TEXT
      },
      {
        accessor: 'nextInspectionDate',
        type: ColumnTypesEnum.TEXT
      }
    ],
    visibleColumns
  );

  return { columns, mappedFilterFields, mappedSortFields };
}
