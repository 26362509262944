import { MutationConfig, useMutation } from 'react-query';
import { DocumentCreateRequest, FileMetadataObject } from '@ibtm/domain';
import { AxiosResponse } from 'axios';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export interface IGenerateParcelComplaintParams {
  parcelId: string;
  documentCreateRequest?: Omit<DocumentCreateRequest, 'documentTemplateId'>;
  data?: { documentTemplateId: string };
}

const generateParcelComplaintDocument = async ({
  parcelId,
  documentCreateRequest,
  data
}: IGenerateParcelComplaintParams) => {
  return API.documents.generateParcelComplaintDocument(
    parcelId,
    {
      ...documentCreateRequest,
      documentTemplateId: data?.documentTemplateId
    },
    { ...endpointsConfig.generateParcelComplaintDocument }
  );
};

export default function useGenerateComplaintsMutation(
  config?: MutationConfig<AxiosResponse<FileMetadataObject>, unknown, IGenerateParcelComplaintParams, unknown>
) {
  return useMutation(generateParcelComplaintDocument, config);
}
