import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { FolderSnapshot } from '@ibtm/domain';

import { Dialog } from '@libs/common/v2';

import { useStartApplicationProcessMutation } from '../../api';
import { useCreateApplicationForm } from '../../hooks';
import { ApplicationCreateFormValues } from '../../model';

import CreateApplicationForm from './CreateApplicationForm';

interface IProps {
  open: boolean;
  onCancel: () => void;
  onClose: () => void;
  onSuccess: (id: string) => void;
  showAddFolderButton?: boolean;
  selectedFolder?: FolderSnapshot;
  categoryKey?: string;
  isFilterOnlyByFolderNumber?: boolean;
}

function CreateApplicationDialog({
  open,
  onCancel,
  onClose,
  onSuccess,
  showAddFolderButton = true,
  selectedFolder,
  categoryKey,
  isFilterOnlyByFolderNumber
}: IProps) {
  const [t] = useTranslation();
  const { createApplicationFormRef, submitCreateApplicationForm } = useCreateApplicationForm();

  const { mutate: startApplicationProcess, isLoading } = useStartApplicationProcessMutation();

  const { showSuccessSnackbar } = useSnackbar();

  const handleFormSubmit = (data: ApplicationCreateFormValues) => {
    const formData = {
      folderId: data.folder.id,
      applicationDefinitionId: data.applicationDefinition?.value.id
    };

    startApplicationProcess(formData, {
      onSuccess: ({ data: applcationData }) => {
        onSuccess(applcationData.id);
        showSuccessSnackbar(t('applications:message.createApplicationSuccess'));
      }
    });
  };

  return (
    <Dialog
      title={t('applications:dialog.createApplicationTitle')}
      confirmText={t('action.add')}
      cancelText={t('action.cancel')}
      onConfirm={submitCreateApplicationForm}
      onCancel={onCancel}
      onClose={onClose}
      dialogSize="small"
      isConfirmLoading={isLoading}
      isOpen={open}
    >
      <CreateApplicationForm
        ref={createApplicationFormRef}
        selectedFolder={selectedFolder}
        onSubmit={handleFormSubmit}
        showAddFolderButton={showAddFolderButton}
        categoryKey={categoryKey}
        isFilterOnlyByFolderNumber={isFilterOnlyByFolderNumber}
      />
    </Dialog>
  );
}

export default CreateApplicationDialog;
