import { ReactNode, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PluginHook, TableOptions } from 'react-table';
import { useSnackbar } from '@enigma/fe-ui';
import { VehiclesSearchFilter as VehiclesSearchFilterClient } from '@ibtm/client-domain';
import { VehicleSnapshot, VehiclesSearchFilter } from '@ibtm/domain';
import { useAppConfig } from '@libs/app-config';

import {
  Button,
  CamelCasePath,
  FormMode,
  IconButton,
  InputMode,
  TableContext,
  TableLayout,
  useConfirmDialog,
  useFormV2Context,
  useTableRowActions,
  useViewModesV2
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { UIElementNameEnum, useElementVisibility } from '@libs/permission';
import { useGenerateReport } from '@libs/report/hooks';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { DomainDictionaryEntry, DomainUIElementEnum } from '@libs/domain/config';
import { FolderQueryKeysEnum } from '@libs/domain/folder';
import {
  CopyFolderVehiclesActionButton,
  IVehicleActionKeys,
  useClearVehicles,
  useCopyFolderVehiclesAction,
  useDeleteVehicleMutation,
  useExpireVehicleMutation,
  useRestoreVehicleMutation,
  useVehicleDetailsDialog,
  useVehiclesTable,
  VEHICLE_DEFAULT_VISIBLE_COLUMNS,
  VEHICLE_EXTENDED_VISIBLE_COLUMNS,
  VehicleColumnNameEnum,
  VehicleFormData,
  VehicleQueryKeysEnum,
  VehicleSearchActionButton,
  VehicleSectionFields,
  VehicleTableActionEnum,
  VehicleTableEditionVisibleValidator,
  VehicleTableRowActionEnum
} from '@libs/domain/vehicle';

import { ApplicationQueryKeysEnum } from '../../application';
import { useDomainConfigContext } from '../../config';
import useTranslateEuroClass from '../hooks/useTranslateEuroClass';

import { useVehicleSearchAction } from './actions/useVehicleSearchAction';

interface IProps {
  folderTypeKey?: string;
  showDownloadVehiclesButton?: boolean;
  visibleColumns?: Array<CamelCasePath<VehicleSnapshot>>;
  queryParams?: VehiclesSearchFilter | VehiclesSearchFilterClient;
  tableOptions?: Partial<TableOptions<VehicleSnapshot>>;
  folderId?: string;
  folderNumber?: string;
  transferredFoldersIds?: string[];
  applicationId?: string;
  applicationType?: string;
  applicationCategory?: string;
  vehicleModificationConcernsKey?: string;
  visibleTableActions?: VehicleTableActionEnum[];
  visibleTableRowActions?: VehicleTableRowActionEnum[];
  tablePlugins?: Array<PluginHook<VehicleSnapshot>>;
  children?: ReactNode;
  isXlsxDownloadVisible?: boolean;
  isHeaderHidden?: boolean;
  isCreatingVehicleFromFolder?: boolean;
  isCollapsable?: boolean;
  actionKeys?: IVehicleActionKeys;
  tableActionKeys?: Array<UIElementNameEnum>;
  isSection?: boolean;
  editValidator?: VehicleTableEditionVisibleValidator;
  checkCepikInformationFolderType?: boolean;
  hiddenDetailsFields?: VehicleSectionFields[];
  detailsFieldsMode?: { [key in keyof VehicleFormData]?: InputMode };
  sectionContentClassName?: string;
  onVehicleSearchSuccess?: () => void;
  isHiddenSelectRowHeader?: boolean;
}

function VehiclesTable({
  folderTypeKey,
  visibleColumns = VEHICLE_DEFAULT_VISIBLE_COLUMNS,
  tableOptions,
  queryParams,
  children,
  folderId,
  folderNumber,
  transferredFoldersIds,
  applicationId,
  applicationType,
  applicationCategory,
  vehicleModificationConcernsKey,
  visibleTableActions = [],
  visibleTableRowActions = [],
  isCreatingVehicleFromFolder: isCreatingFromFolder,
  tablePlugins,
  isXlsxDownloadVisible = true,
  isHeaderHidden,
  isCollapsable = false,
  actionKeys,
  tableActionKeys,
  isSection = false,
  editValidator = VehicleTableEditionVisibleValidator.ALWAYS,
  checkCepikInformationFolderType,
  hiddenDetailsFields,
  detailsFieldsMode,
  sectionContentClassName,
  onVehicleSearchSuccess,
  isHiddenSelectRowHeader
}: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { checkIsElementVisible } = useElementVisibility();
  const { isOperatorPortal } = useDomainConfigContext();

  const [confirm] = useConfirmDialog();
  const { mutate: deleteVehicle } = useDeleteVehicleMutation();
  const { mutate: expireVehicle } = useExpireVehicleMutation();
  const { mutate: restoreVehicle } = useRestoreVehicleMutation();

  const isEuroClassColumn = visibleColumns.includes(VehicleColumnNameEnum.EUROCLASS_KEY);
  const { getEuroClassTranslationByYearOrEuroClassKey, data } = useTranslateEuroClass({
    enabled: isEuroClassColumn
  });
  const { clearAllVehicles } = useClearVehicles(applicationId);
  const { reportTypes } = useAppConfig();
  const [generateReport] = useGenerateReport(
    reportTypes?.aboutVehicles?.reportTypeName,
    folderNumber
      ? [
          {
            name: reportTypes?.aboutVehicles?.targetParameters?.folderId,
            value: folderNumber
          }
        ]
      : null
  );
  const { openDetailsDialog } = useVehicleDetailsDialog(
    hiddenDetailsFields,
    checkCepikInformationFolderType,
    detailsFieldsMode,
    actionKeys
  );
  const { getQuery } = useGetApiQueryByPerspective();
  const { showSuccessSnackbar } = useSnackbar();
  const { refetch } = useContext(TableContext);
  const { formMode } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);

  const { DELETE, EDIT, SHOW_DETAIL, RESTORE, EXPIRE } = VehicleTableRowActionEnum;
  const { ADD_VEHICLE, GENERATE_RAPORT, SEARCH, DELETE_ALL_VEHICLES, COPY_ALL_VEHICLES } = VehicleTableActionEnum;

  const isApplicationCategorySpoWzw = applicationCategory === DomainDictionaryEntry.APPLICATION_CATEGORY.SPO_WZW;
  const isApplicationCategorySpoWzo = applicationCategory === DomainDictionaryEntry.APPLICATION_CATEGORY.SPO_WZO;

  const isCertyficateSApplication = [
    DomainDictionaryEntry.APPLICATION_TYPE.sCertificateResumingIssueOfDocument,
    DomainDictionaryEntry.APPLICATION_TYPE.sCertificateIssuingDuplicate,
    DomainDictionaryEntry.APPLICATION_TYPE.sCertificateChangeOfDocument
  ].includes(applicationType);

  const currentVisibleColumns = useMemo(() => {
    if (isApplicationCategorySpoWzw || isApplicationCategorySpoWzo) {
      return VEHICLE_EXTENDED_VISIBLE_COLUMNS;
    }
    if (isCertyficateSApplication) {
      return [...visibleColumns, VehicleColumnNameEnum.FOLDER_NUMBER];
    }
    return visibleColumns;
  }, [isApplicationCategorySpoWzo, isApplicationCategorySpoWzw, isCertyficateSApplication, visibleColumns]);

  const tableProps = useVehiclesTable({
    queryParams: isCertyficateSApplication ? { ...queryParams, excludeApplicationVehicles: true } : queryParams,
    tablePlugins,
    visibleColumns: currentVisibleColumns,
    tableOptions,
    translateEuroClass: getEuroClassTranslationByYearOrEuroClassKey,
    tableHookQueryConfig: {
      enabled: (!!data && !!getEuroClassTranslationByYearOrEuroClassKey) || !isEuroClassColumn
    }
  });
  const isApplicationTypeIPWZ = applicationType === DomainDictionaryEntry.APPLICATION_TYPE.spoOwnNeedsIpwz;
  const { handleCopyVehicles } = useCopyFolderVehiclesAction({ applicationId, transferredFoldersIds });
  const { openVehicleSearchDialog } = useVehicleSearchAction({
    folderId,
    transferredFoldersIds,
    applicationId,
    applicationCategory,
    applicationType,
    hasIpwzWarningInformation: isApplicationTypeIPWZ,
    vehicleModificationConcernsKey,
    onSuccess: onVehicleSearchSuccess,
    isAddSelectedActionButtonHidden: true
  });

  const isActionColumn = visibleTableRowActions.length > 0;
  const isActionVisible = (name: VehicleTableActionEnum | VehicleTableRowActionEnum) => {
    return Boolean(visibleTableActions.find(item => item === name));
  };

  const isRowActionVisible = (name: VehicleTableActionEnum | VehicleTableRowActionEnum) => {
    return Boolean(visibleTableRowActions.find(item => item === name));
  };

  const numberOfRowActionButtons = visibleTableRowActions.length;

  const xlsxDownloadApi = useCallback(params => {
    const apiMethod = getQuery(
      () => API.client.vehicle.getVehiclesSearchFilter('', '', { ...params, ...queryParams }),
      () => API.vehicle.getVehiclesSearchFilter({ ...params, ...queryParams })
    );

    return apiMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshActiveVehiclesCount = () => {
    if (folderId) {
      queryCache.invalidateQueries(FolderQueryKeysEnum.ACTIVE_VEHICLES_COUNT, { stale: !!folderId });
      queryCache.invalidateQueries(VehicleQueryKeysEnum.VEHICLE_LIST);
    }
  };

  const showActionsForOnlyNewVehicle = vehicle => {
    if (
      applicationId &&
      editValidator === VehicleTableEditionVisibleValidator.ONLY_NEW &&
      vehicle?.linkedVehicleId &&
      vehicle.statusKey === DomainDictionaryEntry.VEHICLE_STATUS.NEW
    ) {
      return false;
    }
    return applicationId && editValidator === VehicleTableEditionVisibleValidator.ONLY_NEW
      ? !!vehicle?.linkedVehicleId
      : false;
  };
  const { renderTableActions } = useTableRowActions<VehicleSnapshot>([
    {
      id: actionKeys?.details,
      label: 'action.openDetails',
      onClick: ({ row: { original } }) =>
        openDetailsDialog({
          folderType: folderTypeKey,
          folderId,
          transferredFoldersIds,
          formMode: FormMode.VIEW,
          applicationType,
          applicationCategory,
          id: original?.id,
          applicationId: original?.applicationId,
          initialData: original
        }),
      icon: 'ArrowIcon',
      isHidden: !isRowActionVisible(SHOW_DETAIL)
    },
    {
      id: actionKeys?.edit,
      label: 'action.edit',
      onClick: ({ row: { original } }) =>
        openDetailsDialog({
          folderType: folderTypeKey,
          folderId,
          transferredFoldersIds,
          formMode: FormMode.EDIT,
          applicationType,
          applicationCategory,
          id: original?.id,
          applicationId: original?.applicationId,
          initialData: original
        }),
      isHidden: ({ row: { original } }) => {
        if (!isRowActionVisible(EDIT)) {
          return true;
        }

        return showActionsForOnlyNewVehicle(original);
      },
      icon: numberOfRowActionButtons < 3 ? 'EditIcon' : null
    },
    {
      id: actionKeys?.delete,
      label: 'action.delete',
      onClick: ({ row: { original } }) =>
        confirm({
          title: t('vehicle:deleteModalTitle'),
          message: t('vehicle:deleteModalContent', { registrationNumber: original?.plateNumber }),
          onConfirm: (setConfirmLoading, closeDialog) => {
            setConfirmLoading(true);
            deleteVehicle(
              { vehicleId: original?.id },
              {
                onSuccess: () => {
                  if (applicationId) {
                    queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
                  }
                  showSuccessSnackbar(t('vehicle:deleteSuccess'));
                  refreshActiveVehiclesCount();
                  refetch();
                },
                onSettled: () => {
                  setConfirmLoading(false);
                  closeDialog();
                }
              }
            );
          },
          confirmType: 'danger'
        }),
      isHidden: !isRowActionVisible(DELETE),
      icon: numberOfRowActionButtons < 3 ? 'TrashIcon' : null
    },
    {
      id: actionKeys?.expire,
      icon: 'ExpireIcon',
      label: 'action.expire',
      onClick: ({ row: { original } }) =>
        confirm({
          title: t('vehicle:expireModalTitle'),
          message: t('vehicle:expireModalContent', { registrationNumber: original?.plateNumber }),
          onConfirm: (setConfirmLoading, closeDialog) => {
            setConfirmLoading(true);
            expireVehicle(
              { vehicleId: original?.id, version: original?.version },
              {
                onSuccess: () => {
                  showSuccessSnackbar(t('vehicle:expireSuccess'));
                  refreshActiveVehiclesCount();
                  refetch();
                },
                onSettled: () => {
                  setConfirmLoading(false);
                  closeDialog();
                }
              }
            );
          },
          confirmType: 'danger'
        }),
      isHidden: !isRowActionVisible(EXPIRE)
    },
    {
      id: actionKeys?.restore,
      label: 'action.restore',
      icon: 'RestoreIcon',
      onClick: ({ row: { original } }) =>
        confirm({
          title: t('vehicle:restoreModalTitle'),
          message: t('vehicle:restoreModalContent', { registrationNumber: original?.plateNumber }),
          onConfirm: (setConfirmLoading, closeDialog) => {
            setConfirmLoading(true);
            restoreVehicle(
              { vehicleId: original?.id, version: original?.version },
              {
                onSuccess: () => {
                  showSuccessSnackbar(t('vehicle:restoreSuccess'));
                  refreshActiveVehiclesCount();
                  refetch();
                },
                onSettled: () => {
                  setConfirmLoading(false);
                  closeDialog();
                }
              }
            );
          }
        }),
      isHidden: !isRowActionVisible(RESTORE)
    }
  ]);

  const tabeleActions = (row, table): JSX.Element => {
    const actions = renderTableActions(
      tableActionKeys.filter(item => {
        if (
          item === DomainUIElementEnum.FOLDER_VEHICLE_EXPIRE_BUTTON &&
          !(row?.original?.statusKey === DomainDictionaryEntry.VEHICLE_STATUS.ACTIVE)
        ) {
          return;
        }
        if (
          item === DomainUIElementEnum.FOLDER_VEHICLE_RESTORE_BUTTON &&
          !(row?.original?.statusKey === DomainDictionaryEntry.VEHICLE_STATUS.EXPIRED)
        ) {
          return;
        }
        // eslint-disable-next-line consistent-return
        return item;
      })
    );

    return isActionColumn && tableActionKeys && actions(row, table);
  };

  const isDeleteAllVehiclesButtonVisible = !viewMode && isActionVisible(DELETE_ALL_VEHICLES);
  const isCopyAllVehiclesButtonVisible = !viewMode && isActionVisible(COPY_ALL_VEHICLES);
  const isSearchButtonVisible = !viewMode && isActionVisible(SEARCH);

  const isAddButtonVisible = isActionVisible(ADD_VEHICLE);

  const addVehicle = () => {
    openDetailsDialog({
      folderType: folderTypeKey,
      applicationId,
      applicationType,
      applicationCategory,
      folderId,
      transferredFoldersIds,
      isCreatingFromFolder,
      formMode: FormMode.CREATE
    });
  };

  const mobileHeaderActions = [
    {
      label: t('vehicle:cleanFleetVehicle.cleanAllButton'),
      onClick: clearAllVehicles,
      actionKey: actionKeys?.clear,
      isHidden: !isDeleteAllVehiclesButtonVisible
    },
    {
      label: t('vehicle:downloadVehicles'),
      onClick: handleCopyVehicles,
      actionKey: actionKeys?.download,
      isHidden: !isCopyAllVehiclesButtonVisible
    },
    {
      label: t('vehicle:details.button.searchVehicle'),
      onClick: openVehicleSearchDialog,
      actionKey: actionKeys?.search,
      isHidden: !isSearchButtonVisible
    },
    {
      label: t('action.add'),
      onClick: addVehicle,
      isHidden: !isAddButtonVisible,
      actionKey: actionKeys?.add
    }
  ];

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('vehicle:title')}
      selectionColumnWidth={96}
      xlsxDownload={
        isXlsxDownloadVisible &&
        (!actionKeys?.downloadXLSX || checkIsElementVisible(actionKeys.downloadXLSX)) && {
          fileName: t('vehicle:title'),
          pathToXLSXTranslation: 'vehicle:field',
          apiRequest: xlsxDownloadApi
        }
      }
      mobileHeaderActions={mobileHeaderActions}
      headerActions={
        <>
          {isOperatorPortal && isActionVisible(GENERATE_RAPORT) && (
            <IconButton
              icon="ArchiveIcon"
              onClick={generateReport}
              tooltipTitle={t('vehicle:generateReportButtonContent')}
              actionKey={actionKeys?.generateRaport}
              isBackgroundTransparent
            />
          )}
          {isDeleteAllVehiclesButtonVisible && (
            <IconButton
              icon="TrashIcon"
              onClick={clearAllVehicles}
              tooltipTitle={t('vehicle:cleanFleetVehicle.cleanAllButton')}
              actionKey={actionKeys?.clear}
              isBackgroundTransparent
            />
          )}
          {isCopyAllVehiclesButtonVisible && (
            <CopyFolderVehiclesActionButton
              applicationId={applicationId}
              actionKey={actionKeys?.download}
              transferredFoldersIds={transferredFoldersIds}
            />
          )}
          {isSearchButtonVisible && (
            <VehicleSearchActionButton
              folderId={folderId}
              transferredFoldersIds={transferredFoldersIds}
              applicationId={applicationId}
              actionKey={actionKeys?.search}
              applicationCategory={applicationCategory}
              applicationType={applicationType}
              hasIpwzWarningInformation={isApplicationTypeIPWZ}
              vehicleModificationConcernsKey={vehicleModificationConcernsKey}
              onSuccess={onVehicleSearchSuccess}
              isAddSelectedActionButtonHidden
            />
          )}
          {isAddButtonVisible && (
            <Button
              variant="outlined"
              type="button"
              label={t('action.add')}
              onClick={addVehicle}
              actionKey={actionKeys?.add}
            />
          )}
        </>
      }
      rowActions={(row, table) => tabeleActions(row, table)}
      isActionColumnEnabled={isActionColumn}
      isHeaderHidden={isHeaderHidden}
      isCollapsable={isCollapsable}
      isSection={isSection}
      sectionContentClassName={sectionContentClassName}
      isHiddenSelectRowHeader={isHiddenSelectRowHeader}
    >
      {children}
    </TableLayout>
  );
}

export default VehiclesTable;
