import { i18n } from '@libs/common';
import { FormMode } from '@libs/common/v2';

export const getSuspensionBreadcrumbItems = (
  formMode: FormMode,
  suspensionNumber: string,
  suspensionId: string,
  folderId: string
) => {
  const firstBreadcrumb =
    formMode === FormMode.VIEW
      ? {
          text: i18n.t('suspensions:details.title'),
          to: `/folders/${folderId}/details/proceeding/?tab=SUSPENSIONS`
        }
      : {
          text: i18n.t('suspensions:details.title'),
          to: `/folders/${folderId}/edit?tab=SUSPENSIONS`
        };
  const secondBreadcrumb =
    formMode === FormMode.CREATE
      ? { text: `${i18n.t('suspensions:details.newTitle')}` }
      : {
          text: `${i18n.t('suspensions:details.title')}: ${suspensionNumber}`,
          to: `/suspensions/VERIFICATION/${suspensionId}/details/folder/${folderId}/details`
        };
  const thirdBreadcrumb = formMode === FormMode.EDIT ? { text: i18n.t('suspensions:details.editTitle') } : null;
  return thirdBreadcrumb ? [firstBreadcrumb, secondBreadcrumb, thirdBreadcrumb] : [firstBreadcrumb, secondBreadcrumb];
};
