import React, { useEffect } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { DictionarySelectField, InputMode, useFormV2Context } from '@libs/common/v2';

import { UiMode } from '@libs/meta-form/models';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import { useApplicationDetailsQuery } from '../../../api';
import { IApplicationDetailsPageParams } from '../../../model';

interface IProps {
  fieldId: FieldPath<FieldValues>;
  label: string;
  inputMode: UiMode;
  api: {
    get: {
      accessor: string;
    };
  };
}

function ScheduleTypeKeyDictionaryField({ fieldId, label, inputMode, api }: IProps) {
  const { applicationId } = useParams<IApplicationDetailsPageParams>();
  const { data } = useApplicationDetailsQuery(applicationId, { refetchOnMount: false });
  const { setValue } = useFormV2Context();
  useEffect(() => {
    if (data) {
      // Wartość wynika z faktu dodania pola „Godzina odjazdu X” w zakładce „Rozkład Jazdy”. Jeżeli dodano to pole, to pole przyjmuje wartość „Wielokrotny”.
      //  Wartość domyślna: „Jednokrotny”
      const inputValue = _.get(data, api?.get?.accessor);
      if (inputValue) {
        setValue(fieldId, { value: DomainDictionaryEntry.SCHEDULE_TYPE.MULTIPLE });
      } else {
        setValue(fieldId, { value: DomainDictionaryEntry.SCHEDULE_TYPE.SINGLE });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <DictionarySelectField
      name={fieldId}
      dictionaryName={DomainDictionaryEnum.SCHEDULE_TYPE}
      label={label}
      inputMode={inputMode && inputMode === UiMode.VIEW ? InputMode.VIEW : InputMode.FORM}
    />
  );
}

export default ScheduleTypeKeyDictionaryField;
