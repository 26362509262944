import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProceedingDetails as IProceedingDetails } from '@ibtm/domain';
import { isEmpty, isNil } from 'lodash';
import { date as YupDate, object as YupObject, string as YupString } from 'yup';

import { FormMode, FormV2Context, typedNameV2 } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { useAuth } from '@libs/auth/hooks';

import { ProceedingDetails } from './ProceedingDetails';

const useValidationSchema = () => {
  const [t] = useTranslation();

  return YupObject().shape(
    {
      typeKey: YupString().nullable().required(),
      reviewApplicationNumber: YupString().when('reviewApplicationDate', {
        is: reviewApplicationDate => !isNil(reviewApplicationDate) || !isEmpty(reviewApplicationDate),
        then: YupString().nullable().required(t('validation:required')),
        otherwise: YupString().nullable()
      }),
      reviewApplicationDate: YupDate().when('reviewApplicationNumber', {
        is: reviewApplicationNumber => !isEmpty(reviewApplicationNumber),
        then: YupDate().nullable().required(t('validation:required')),
        otherwise: YupDate().nullable()
      })
    },
    [['reviewApplicationNumber', 'reviewApplicationDate']]
  );
};

interface IProps {
  formMode: FormMode;
}

function ProceedingDetailsWrapper({ formMode }: IProps) {
  const [localSubmitting, setLocalSubmitting] = useState<boolean>(false);

  const { user } = useAuth();
  const { createMode } = useViewModesV2(formMode);

  const form = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(useValidationSchema())
  });

  const formDataProvider = useMemo(() => {
    return {
      ...form,
      formMode,
      isDirty: form.formState.isDirty,
      isSubmitting: form.formState.isSubmitting || localSubmitting
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, form.formState, form.formState.errors, localSubmitting, formMode]);

  useEffect(() => {
    if (createMode && user?.personalData) {
      const { name, surname } = user.personalData;
      form.setValue(typedNameV2<IProceedingDetails>('assignedTo'), {
        id: user.accountData.id,
        name: `${name} ${surname}`
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, user]);

  return (
    <FormV2Context.Provider value={formDataProvider}>
      <ProceedingDetails setSubmitting={setLocalSubmitting} />
    </FormV2Context.Provider>
  );
}

export default ProceedingDetailsWrapper;
