import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { HintType, ParameterExtras } from '@stack/report';

import {
  AutocompleteSelectField,
  Dialog,
  DictionarySelectField,
  FormV2Context,
  GridLayout,
  NUMBER_OF_AUTOCOMPLETE_RESULTS,
  NumberInputField,
  RadioGroup,
  Section,
  SelectOption,
  SelectWithFetchedOptionsField,
  SwitchField,
  TextInputField,
  typedNameV2,
  useGetContextValidators
} from '@libs/common/v2';

import { useDictionariesAllQuery, useDictionaryTranslations } from '@libs/dictionary';
import { API, useFieldTypesQuery, useReportSourcesQuery } from '@libs/report/api';
import { useParameterDialogContentValidation } from '@libs/report/hooks';
import { ReportTypeForm } from '@libs/report/model/form.model';
import { ParameterExtrasTooltipEnum, ReportDictionaryEntryNameEnum } from '@libs/report-core';
import useReportDataSourceColumns, { DataSource } from '@libs/report-core/hooks/useReportDataSourceColumns';

const InitialFormValues: ParameterExtras = {
  displayName: '',
  queryName: '',
  type: null,
  index: null,
  multiValue: false,
  selectOnly: false,
  checkbox: false,
  required: false,
  groupName: null,
  requiredParamNames: null,
  enabledByParamName: null,
  disabledByParamNames: null,
  hint: null
};

interface Props {
  closeDialog: () => void;
  onConfirm: (data: ParameterExtras) => void;
  isSubmitting?: boolean;
  initialEditFormValues?: ParameterExtras;
  reportTypeValues: ReportTypeForm;
}

export function ParameterDialogContent({
  closeDialog,
  onConfirm,
  isSubmitting,
  initialEditFormValues,
  reportTypeValues
}: Props) {
  const [t] = useTranslation();
  const [dictionaryOptions, setDictionaryOptions] = useState<SelectOption[]>([]);
  const [isInitial, setIsInitial] = useState(true);
  const hintRef = useRef(null);
  const hintValueRef = useRef(null);
  const { translate } = useDictionaryTranslations();
  const { validationSchema } = useParameterDialogContentValidation({ reportTypeValues, initialEditFormValues });

  const { data: dictionaryData } = useDictionariesAllQuery({});

  const validatorData = useGetContextValidators();

  const form = useForm<Record<string, any>>({
    defaultValues: initialEditFormValues || InitialFormValues,
    resolver: yupResolver(validationSchema),
    mode: 'onBlur'
  });
  const hintType = useWatch({ control: form.control, name: 'hint.type' });
  const hintValue = useWatch({ control: form.control, name: 'hintValue' });
  const defaultValue = useWatch({ control: form.control, name: 'hint.defaultValue' });
  const dictionaryName = useWatch({ control: form.control, name: 'hint.dictionaryName' });
  const multiValue = useWatch({ control: form.control, name: 'multiValue' });

  const type = form.watch('type');
  const source = form.watch('hint.sourceId') as DataSource;
  const columnKey = form.watch('hint.columnKey');
  const requireAllParameterValues = form.watch('hint.requireAllParameterValues');

  const radioOptions: SelectOption[] = [
    { value: HintType.QUERY, name: t('reports:reportTypes.parameterExtras.fields.query') },
    {
      value: HintType.VALUES,
      name: t('reports:reportTypes.parameterExtras.fields.hintedValues'),
      active: !requireAllParameterValues,
      disabledTooltip: t('reports:reportTypes.parameterExtras.tip.disabledByRequiredAllparameterValues')
    },
    {
      value: HintType.COLUMN,
      name: t('reports:reportTypes.parameterExtras.fields.columnKeyAndDataSetName'),
      active: !requireAllParameterValues,
      disabledTooltip: t('reports:reportTypes.parameterExtras.tip.disabledByRequiredAllparameterValues')
    },
    {
      value: HintType.DICTIONARY,
      name: t('reports:reportTypes.parameterExtras.fields.dictionaryName'),
      active: !requireAllParameterValues,
      disabledTooltip: t('reports:reportTypes.parameterExtras.tip.disabledByRequiredAllparameterValues')
    }
  ];
  const sourceColumns = useReportDataSourceColumns(source);
  const { data: fieldTypeOptions, isLoading: isFieldTypesLoading } = useFieldTypesQuery();
  const { data: reportSources, isLoading: isReportSourceLoading } = useReportSourcesQuery(
    {
      dataSourceMediatorIds: [source?.id || String(source)]
    },
    {
      enabled: !!source
    }
  );

  const columnOptions: SelectOption[] = useMemo(() => {
    const columns = sourceColumns.length ? sourceColumns : reportSources?.content?.[0]?.sourceColumns;

    if (type?.value) {
      return columns
        ?.filter(col => col.type === type.value)
        .map(col => ({
          name: col.displayName,
          value: col.name,
          type: col.type
        }));
    }

    return columns?.map(col => ({
      name: col.displayName,
      value: col.name,
      type: col.type
    }));
  }, [sourceColumns, reportSources, type?.value]);

  useEffect(() => {
    const columnKey = form.getValues('hint.columnKey');
    if (reportSources?.content?.length && columnKey === initialEditFormValues?.hint?.columnKey) {
      const column = reportSources?.content?.[0].sourceColumns.find(col => col.name === columnKey || columnKey?.value);
      form.setValue(typedNameV2<ParameterExtras>('hint.columnKey'), { name: column?.displayName, value: column?.name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportSources]);

  useEffect(() => {
    if (multiValue && !Array.isArray(form.getValues('hint.dictionaryKeys'))) {
      form.setValue('hint.dictionaryKeys', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiValue]);

  const handleDictionaryNameChange = () => {
    form.setValue('hint.dictionaryKeys', null);
  };

  useEffect(() => {
    if (!hintType) {
      form.setValue(typedNameV2<ParameterExtras>('hint.type'), initialEditFormValues?.hint?.type);
    }

    const initialSourceId: any = initialEditFormValues?.hint?.sourceId;
    if (!isReportSourceLoading && !isInitial && (initialSourceId?.id || initialSourceId) !== (source?.id || source)) {
      form.setValue(typedNameV2<ParameterExtras>('hint.columnKey'), null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hintType, source]);

  useEffect(() => {
    if (type?.value) {
      if (typeof columnKey === 'object' && columnKey?.type && columnKey?.type !== type?.value) {
        form.setValue(typedNameV2<ParameterExtras>('hint.columnKey'), null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type?.value, columnKey]);

  useEffect(() => {
    if (dictionaryData) {
      const parsedData = dictionaryData.content.map(({ description, name }) => ({
        name: description,
        value: name
      }));
      setDictionaryOptions(parsedData);

      if (hintType === HintType.DICTIONARY && dictionaryOptions) {
        const dictionaryName = form.getValues('hint.dictionaryName');
        form.setValue(
          'hint.dictionaryName',
          parsedData.find(option => option.name === dictionaryName.name || option.value === dictionaryName)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dictionaryData, HintType]);

  useEffect(() => {
    if (hintType) {
      form.setValue('hintValue', true);
    }

    if (!isReportSourceLoading && isInitial) {
      form.setValue(typedNameV2<ParameterExtras>('hint.sourceId'), reportSources?.content?.[0]);
      setIsInitial(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReportSourceLoading]);

  useEffect(() => {
    if (hintType) {
      hintRef?.current?.scrollIntoView();
    }
    if (hintValue) {
      hintValueRef?.current?.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hintValue, hintType]);

  useEffect(() => {
    if (requireAllParameterValues) {
      form.setValue('hint.type', HintType.QUERY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requireAllParameterValues]);

  const paramOptions = useMemo(() => {
    const initialParamOptions = reportTypeValues.parameterExtras?.map(param => ({
      name: param.displayName,
      value: param.displayName
    }));
    const paramDisplayName = initialEditFormValues?.displayName || InitialFormValues?.displayName;
    const filtredParamOptions = initialParamOptions.filter(value => paramDisplayName !== value.name);
    return filtredParamOptions;
  }, [initialEditFormValues?.displayName, reportTypeValues.parameterExtras]);

  const validatorOptions = useMemo(
    () => Object.keys(validatorData ?? [])?.map(validator => ({ name: validator, value: validator })),
    [validatorData]
  );

  const handleConfirm = useCallback(
    (values: Record<string, any>) => {
      onConfirm(values as ParameterExtras);
      closeDialog();
    },
    [onConfirm, closeDialog]
  );

  return (
    <FormV2Context.Provider value={form}>
      <form onSubmit={form.handleSubmit(handleConfirm)}>
        <Dialog
          title={t('reports:reportTypes.parameterExtras.dialog.title')}
          dialogSize="medium"
          onCancel={closeDialog}
          onConfirm={form.handleSubmit(handleConfirm)}
          confirmText={t('action.save')}
          cancelText={t('action.cancel')}
          isConfirmLoading={isSubmitting}
          isOpen
        >
          <GridLayout itemProps={{ xs: 6 }}>
            <TextInputField
              name="displayName"
              label={t('reports:reportTypes.parameterExtras.fields.displayName')}
              isRequired
            />
            <TextInputField
              name="queryName"
              label={t('reports:reportTypes.parameterExtras.fields.queryName')}
              isRequired
            />
            <AutocompleteSelectField
              name="type"
              label={t('reports:reportTypes.parameterExtras.fields.type')}
              options={fieldTypeOptions}
              isLoading={isFieldTypesLoading}
              isRequired
              isClearable
              tooltip={translate(
                ReportDictionaryEntryNameEnum.REPORT_DEFINITION_HINTS,
                ParameterExtrasTooltipEnum.TYPE
              )}
            />
            <NumberInputField
              name="index"
              label={t('reports:reportTypes.parameterExtras.fields.index')}
              tooltip={translate(
                ReportDictionaryEntryNameEnum.REPORT_DEFINITION_HINTS,
                ParameterExtrasTooltipEnum.INDEX
              )}
            />
            <SwitchField
              name="multiValue"
              label={t('reports:reportTypes.parameterExtras.fields.multiValue')}
              tooltip={translate(
                ReportDictionaryEntryNameEnum.REPORT_DEFINITION_HINTS,
                ParameterExtrasTooltipEnum.MULTIPLE_VALUES
              )}
            />
            <SwitchField
              name="selectOnly"
              label={t('reports:reportTypes.parameterExtras.fields.selectOnly')}
              tooltip={translate(
                ReportDictionaryEntryNameEnum.REPORT_DEFINITION_HINTS,
                ParameterExtrasTooltipEnum.CHECK_FIELD
              )}
            />
            <SwitchField
              name="checkbox"
              label={t('reports:reportTypes.parameterExtras.fields.checkbox')}
              tooltip={translate(
                ReportDictionaryEntryNameEnum.REPORT_DEFINITION_HINTS,
                ParameterExtrasTooltipEnum.CHECKBOX
              )}
            />
            <SwitchField
              name="required"
              label={t('reports:reportTypes.parameterExtras.fields.required')}
              tooltip={translate(
                ReportDictionaryEntryNameEnum.REPORT_DEFINITION_HINTS,
                ParameterExtrasTooltipEnum.REQUIRED
              )}
            />
            <TextInputField
              name="groupName"
              label={t('reports:reportTypes.parameterExtras.fields.groupName')}
              tooltip={translate(
                ReportDictionaryEntryNameEnum.REPORT_DEFINITION_HINTS,
                ParameterExtrasTooltipEnum.GROUP_NAME
              )}
            />
            <AutocompleteSelectField
              name="requiredParamNames"
              label={t('reports:reportTypes.parameterExtras.fields.requiredParamNames')}
              isMultiple
              options={paramOptions}
              tooltip={translate(
                ReportDictionaryEntryNameEnum.REPORT_DEFINITION_HINTS,
                ParameterExtrasTooltipEnum.REQUIRED_PARAMETERS
              )}
            />
            <AutocompleteSelectField
              name="enabledByParamName"
              label={t('reports:reportTypes.parameterExtras.fields.enabledByParamName')}
              options={paramOptions}
              isClearable
              tooltip={translate(
                ReportDictionaryEntryNameEnum.REPORT_DEFINITION_HINTS,
                ParameterExtrasTooltipEnum.RELEASED_BY
              )}
            />
            <AutocompleteSelectField
              name="disabledByParamNames"
              label={t('reports:reportTypes.parameterExtras.fields.disabledByParamNames')}
              options={paramOptions}
              stringValue
              isMultiple
              isClearable
              tooltip={translate(
                ReportDictionaryEntryNameEnum.REPORT_DEFINITION_HINTS,
                ParameterExtrasTooltipEnum.BLOCKED_BY
              )}
            />
            <AutocompleteSelectField
              name="validator"
              label={t('reports:reportTypes.parameterExtras.fields.validator')}
              options={validatorOptions}
              isClearable
              tooltip={translate(
                ReportDictionaryEntryNameEnum.REPORT_DEFINITION_HINTS,
                ParameterExtrasTooltipEnum.VALIDATOR
              )}
            />
          </GridLayout>
          <Section title="" isModalSection>
            <GridLayout itemProps={{ xs: 12 }}>
              <GridLayout itemProps={{ xs: 6 }}>
                <SwitchField
                  name="hintValue"
                  label={t('reports:reportTypes.parameterExtras.fields.hint')}
                  tooltip={translate(
                    ReportDictionaryEntryNameEnum.REPORT_DEFINITION_HINTS,
                    ParameterExtrasTooltipEnum.SUGGESTED_VALUE
                  )}
                />
                {hintValue && (
                  <SwitchField
                    name="hint.defaultValue"
                    label={t('reports:reportTypes.parameterExtras.fields.defaultValue')}
                    tooltip={translate(
                      ReportDictionaryEntryNameEnum.REPORT_DEFINITION_HINTS,
                      ParameterExtrasTooltipEnum.DEFAULT_VALUE
                    )}
                  />
                )}
                {hintValue && (
                  <SwitchField
                    name="hint.requireAllParameterValues"
                    label={t('reports:reportTypes.parameterExtras.fields.requireAllParameterValues')}
                    tooltip={translate(
                      ReportDictionaryEntryNameEnum.REPORT_DEFINITION_HINTS,
                      ParameterExtrasTooltipEnum.REQUIRE_ALL_PARAMETER_VALUES
                    )}
                  />
                )}
                {defaultValue && hintType === HintType.DICTIONARY && (
                  <DictionarySelectField
                    name="hint.dictionaryKeys"
                    label={
                      multiValue
                        ? t('reports:reportTypes.parameterExtras.fields.defaultMultiValue')
                        : t('reports:reportTypes.parameterExtras.fields.defaultSingleValue')
                    }
                    isDisabled={!dictionaryName}
                    validationSingleSelect={{ required: [] }}
                    isMultiple={multiValue}
                    dictionaryName={dictionaryName?.value}
                    isRequired
                    {...(!dictionaryName
                      ? { tooltip: t('reports:reportTypes.parameterExtras.tip.disabledDefaultValueTootlip') }
                      : {})}
                  />
                )}
              </GridLayout>
              {hintValue && (
                <div ref={hintValueRef}>
                  <RadioGroup name="hint.type" label="" options={radioOptions} isRequired={false} />
                </div>
              )}
              {hintValue && hintType && (
                <div ref={hintRef}>
                  <GridLayout itemProps={{ xs: hintType === HintType.QUERY || hintType === HintType.VALUES ? 12 : 6 }}>
                    {hintType === HintType.QUERY && (
                      <TextInputField
                        name="hint.query"
                        label={t('reports:reportTypes.parameterExtras.fields.query')}
                        placeholder={t('reports:reportTypes.parameterExtras.tip.queryTooltip')}
                        lines={4}
                        isRequired
                      />
                    )}
                    {hintType === HintType.VALUES && (
                      <AutocompleteSelectField
                        name="hint.values"
                        label={t('reports:reportTypes.parameterExtras.fields.hintedValues')}
                        options={[]}
                        isFreeSolo
                        isMultiple
                        isRequired
                      />
                    )}
                    {hintType === HintType.COLUMN && (
                      <SelectWithFetchedOptionsField
                        fetchFunction={text =>
                          API.mediatorDataSources.getReportSources(
                            0,
                            NUMBER_OF_AUTOCOMPLETE_RESULTS,
                            null,
                            null,
                            null,
                            text
                          )
                        }
                        getOptionLabel={source => source.name}
                        label={t('reports:field.reportSource')}
                        name="hint.sourceId"
                        isClearable
                        isRequired
                      />
                    )}
                    {hintType === HintType.COLUMN && (
                      <AutocompleteSelectField
                        name="hint.columnKey"
                        label={t('reports:reportTypes.parameterExtras.fields.columnKey')}
                        options={columnOptions}
                        isDisabled={!source}
                        isRequired
                        isClearable
                      />
                    )}
                    {hintType === HintType.DICTIONARY && (
                      <AutocompleteSelectField
                        name="hint.dictionaryName"
                        label={t('reports:reportTypes.parameterExtras.fields.dictionaryName')}
                        options={dictionaryOptions}
                        onChangeCustom={handleDictionaryNameChange}
                        isRequired
                      />
                    )}
                  </GridLayout>
                </div>
              )}
            </GridLayout>
          </Section>
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
}
