import { ButtonProps } from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

import { Button, Theme } from '@libs/common/v2';
import { important } from '@libs/common/v2/utils';

interface IProps extends ButtonProps {
  value: any;
  label: string;
  isSelected?: boolean;
  isActive?: boolean;
  keepLowerCase?: boolean;
  readonly?: boolean;
}

export function SwitchButton(props: IProps) {
  const { value, isSelected, keepLowerCase, readonly, isActive = true, ...forwardProps } = props;
  const buttonClasses = useButtonStyles(props);
  return (
    <Button
      classes={buttonClasses}
      variant={isSelected ? 'contained' : 'outlined'}
      disabled={readonly || !isActive}
      {...forwardProps}
    />
  );
}

const useButtonStyles = makeStyles<Theme, IProps>(theme => ({
  root: {
    backgroundColor: ({ isSelected }) => (isSelected ? theme.palette.blue['500'] : 'white'),
    '&:hover': {
      color: important('white'),
      backgroundColor: theme.palette.blue['500'],
      borderColor: theme.palette.blue['500']
    },
    '&:focus': {
      backgroundColor: theme.palette.blue['500']
    }
  }
}));

export default SwitchButton;
