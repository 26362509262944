export const getBodyCellStyles = props => [
  props,
  {
    style: {
      display: 'flex !important',
      alignItems: 'center'
    }
  }
];

export const getStickyLeftStyle = (props: any, width?: number) => [
  props,
  {
    style: {
      ...props.style,
      display: 'flex',
      alignItems: 'center',
      position: 'sticky',
      top: 0,
      left: 0,
      flex: 'none',
      minWidth: 'auto',
      maxWidth: width ?? 'auto'
    }
  }
];

export const getStickyRightStyle = (props, width) => [
  props,
  {
    style: {
      ...props.style,
      display: 'flex',
      alignItems: 'center',
      position: 'sticky',
      top: 0,
      right: 0,
      padding: 0,
      flex: 'none',
      maxWidth: width ?? 'auto',
      ...(width && { width })
    }
  }
];
