import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProceedingsKreptdApiUpdateProceedingKreptdStatusRequest } from '@ibtm/domain';
import * as Yup from 'yup';

import { partialTranslate } from '@libs/common';
import { Dialog, DictionarySelectField, FormV2Context, typedNameV2 } from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { useProceedingStatusChangeMutations } from '../../../api';
import { IProceedingClient, IProceedingDetailsClient } from '../../../models';

const getLabel = partialTranslate('roadTransport:fields');

interface IProps {
  initialData: IProceedingClient | IProceedingDetailsClient;
  closeDialog: () => void;
  onSuccess?: () => void;
}

function ProceedingStatusChangeForm({ initialData, onSuccess, closeDialog }: IProps) {
  const [t] = useTranslation();

  const { showSnackbar } = useSnackbar();
  const { mutate: changeStatus, isLoading } = useProceedingStatusChangeMutations();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Partial<IProceedingClient>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        status: Yup.string().nullable().required()
      })
    ),
    defaultValues: {
      status: null
    }
  });

  const onSubmit = (formData: Partial<IProceedingClient>) => {
    const requestData: ProceedingsKreptdApiUpdateProceedingKreptdStatusRequest = {
      proceedingId: initialData?.id,
      proceedingKreptdStatusUpdateRequest: {
        statusKey: formData?.status,
        version: initialData?.version
      }
    };

    changeStatus(requestData, {
      onSuccess: () => {
        showSnackbar('success', t('roadTransport:messages.changeStatusSuccess'));

        onSuccess?.();
        closeDialog();
      }
    });
  };

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('roadTransport:dialog.changeStatusTitle')}
      confirmText={t('action.change')}
      cancelText={t('action.cancel')}
      onCancel={closeDialog}
      onConfirm={() => handleSubmit(onSubmit)()}
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <DictionarySelectField
          name={typedNameV2<IProceedingClient>('status')}
          label={getLabel('status')}
          dictionaryName={DomainDictionaryEnum.PROCEEDING_STATUS_KREPTD}
          optionsFilter={option => initialData.statusTransitions.includes(option?.value)}
          stringValue
          isRequired
        />
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default ProceedingStatusChangeForm;
