import { useMemo } from 'react';
import { first } from 'lodash';

import { IAppNavigation, NavigationTypeEnum } from '@libs/common/layout';

interface Params {
  navigation: Array<IAppNavigation>;
  hiddenRoutePaths?: string[];
}

function useNavigationConfig({ navigation, hiddenRoutePaths = [] }: Params) {
  const navigationConfig = useMemo(
    () =>
      navigation.filter(config => {
        if (config.type === NavigationTypeEnum.ITEM) {
          return !hiddenRoutePaths?.includes(config.url);
        }

        const excludedParent = hiddenRoutePaths?.includes(config.id);

        if (config.type === NavigationTypeEnum.COLLAPSE && !excludedParent) {
          return config.children.filter(navigationChildren => !hiddenRoutePaths?.includes(navigationChildren.url));
        }

        return false;
      }),
    [hiddenRoutePaths, navigation]
  );

  const firstPageInNavigation = useMemo(() => first(navigationConfig).url, [navigationConfig]);

  const filteredNavigation = useMemo(
    () =>
      navigation.filter(
        navigationElement => !hiddenRoutePaths.includes(navigationElement?.url || navigationElement.id)
      ),
    [navigation, hiddenRoutePaths]
  );

  return { navigationConfig, firstPageInNavigation, filteredNavigation };
}

export default useNavigationConfig;
