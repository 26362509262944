import { useTranslation } from 'react-i18next';
import { ExploitationBaseSnapshot, ExploitationBasesSearchFilter } from '@ibtm/domain';
import { Tooltip } from '@mui/material';

import {
  CamelCasePath,
  SelectOption,
  TableFilter,
  TableFilterMapper,
  TableSortMapper,
  useCreateColumns
} from '@libs/common/v2';
import {
  convertCalendarDate,
  convertDateToDateFormat,
  DateRangeISO,
  getCalendarDate,
  getValue
} from '@libs/common/v2/utils';

import { parsePostCode } from '@libs/domain/address';

const useExploitationBaseTableColumns = (visibleColumns: Array<CamelCasePath<ExploitationBaseSnapshot>>) => {
  const [t] = useTranslation();

  const { createColumns } = useCreateColumns<ExploitationBaseSnapshot>({
    pathToTranslate: 'exploitationBase:field'
  });

  const mappedSortFields: TableSortMapper<ExploitationBaseSnapshot> = {
    validFrom: ['validFrom', 'validTo']
  };

  const mappedFilterFields: TableFilterMapper<ExploitationBaseSnapshot, ExploitationBasesSearchFilter> = {
    assignedSubjects: ({ min, max }: { min: number; max: number }) => ({
      assignedSubjectsGreaterThanOrEqual: min,
      assignedSubjectsLessThanOrEqual: max
    }),
    lastInspectionResultInspectionResultKey: (controlResultKeys: SelectOption<string>[]) => ({
      inspectionResultIn: controlResultKeys.map(({ value }) => value)
    }),
    lastInspectionResultInspectionDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      inspectionDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      inspectionDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    lastInspectionRemarks: (lastInspectionRemarksContains: string) => ({ lastInspectionRemarksContains }),
    typeKeys: (typeKeys: SelectOption<string>[]) => ({ typeKeyIn: typeKeys.map(({ value }) => value) }),
    postCode: (postCode: string) => ({ postCodeContains: postCode }),
    postCity: (postCity: string) => ({ postCityContains: postCity }),
    voivodeshipKey: (voivodeshipKeys: SelectOption<string>[]) => ({
      voivodeshipKeyIn: voivodeshipKeys.map(({ value }) => value)
    }),
    city: (city: string) => ({ cityContains: city }),
    street: (street: string) => ({ streetContains: street }),
    propertyNumber: (propertyNumber: string) => ({ propertyNumberContains: propertyNumber }),
    validFrom: ({ dateFrom, dateTo }: DateRangeISO) => ({
      validFromGreaterThanOrEqual: getCalendarDate(dateFrom),
      validToLessThanOrEqual: getCalendarDate(dateTo)
    }),
    statusKey: (statusKeys: SelectOption<string>[]) => ({ statusIn: statusKeys.map(({ value }) => value) })
  };

  const columns = createColumns(
    [
      {
        type: 'DICTIONARY_MULTI_SELECT',
        accessor: 'typeKeys',
        header: 'type',
        dictionaryName: 'EXPLOITATION_BASE_TYPE',
        isSortable: false
      },
      {
        accessor: 'assignedSubjects',
        type: 'NUMBER_RANGE'
      },
      {
        accessor: 'assignedVehicles',
        type: 'TEXT',
        isSortable: false,
        isFilterable: false
      },
      {
        id: 'postCode',
        accessor: 'postCode',
        type: 'TEXT',
        customAccessor: row => `${parsePostCode(row?.postCode)}`
      },
      {
        accessor: 'postCity',
        type: 'TEXT'
      },
      {
        accessor: 'voivodeshipKey',
        header: 'voivodeship',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: 'VOIVODESHIP'
      },
      {
        accessor: 'city',
        type: 'TEXT'
      },
      {
        accessor: 'street',
        type: 'TEXT'
      },
      {
        accessor: 'propertyNumber',
        type: 'TEXT'
      },
      {
        accessor: 'validFrom',
        header: 'validityPeriod',
        type: 'CUSTOM_COLUMN',
        customAccessor: row => {
          return !row?.validTo
            ? `${t('from').toLowerCase()} ${convertDateToDateFormat(convertCalendarDate(row?.validFrom))}`
            : `${t('from').toLowerCase()} ${convertDateToDateFormat(convertCalendarDate(row?.validFrom))} 
          ${t('to').toLowerCase()} ${convertDateToDateFormat(convertCalendarDate(row?.validTo))}`;
        },
        filter: TableFilter.Datepicker
      },
      {
        id: 'lastInspectionResultInspectionResultKey',
        header: 'controlResult',
        accessor: 'lastInspectionResult.inspectionResultKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: 'EXPLOITATION_BASE_INSPECTION_RESULT'
      },
      {
        accessor: 'lastInspectionResult.inspectionDate',
        header: 'controlDate',
        type: 'DATE'
      },
      {
        id: 'lastInspectionRemarks',
        header: 'remarks',
        type: 'CUSTOM_COLUMN',
        customAccessor: row => {
          const notes = row?.lastInspectionRemarks;
          if (notes?.length > 13) {
            return (
              <Tooltip title={notes}>
                <span>{notes}</span>
              </Tooltip>
            );
          }
          return getValue(notes);
        },
        customXlsxAccessor: row => row.lastInspectionRemarks,
        filter: TableFilter.Text
      },
      {
        accessor: 'statusKey',
        header: 'status',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: 'EXPLOITATION_BASE_STATUS',
        isSortable: false
      },
      {
        accessor: 'modified',
        type: 'DATE',
        isSortable: false,
        isFilterable: false
      },
      {
        accessor: 'modifier.name',
        header: 'modifier',
        type: 'TEXT',
        isSortable: false,
        isFilterable: false
      }
    ],
    visibleColumns
  );

  return { columns, mappedFilterFields, mappedSortFields };
};

export default useExploitationBaseTableColumns;
