import { TransitStopDetails as TransitStopDetailsClient } from '@ibtm/client-domain';
import {
  TransitDateDetails,
  TransitDateUpdateRequest,
  TransitScheduleUpdateRequest,
  TransitStopCreateRequest,
  TransitStopDetails as TransitStopDetailsOperator
} from '@ibtm/domain';

import { SelectOption } from '@libs/common/v2';

import { DomainDictionaryEntry } from '../../config';

export enum StopShiftDirection {
  UP = 'UP',
  DOWN = 'DOWN'
}

export enum ScheduleApplicationGroupsEnum {
  SPO_OPINION = 'ibtm-domain.application.type.spo-position',
  SPO_OUTSIDE_UE = 'ibtm-domain.application.type.spo-outside-ue',
  SPO_TRANSIT_RP = 'ibtm-domain.application.type.spo-transit-rp',
  SPO_UE = 'ibtm-domain.application.type.spo-ue',
  SPO_WZO = 'ibtm-domain.application.type.spo-wzo',
  SPO_WZW = 'ibtm-domain.application.type.spo-wzw'
}

type Select = Partial<SelectOption>;

export type StopFormData = Omit<
  TransitStopCreateRequest & TransitScheduleUpdateRequest,
  | 'name'
  | 'typeKey'
  | 'countryKey'
  | 'city'
  | 'countryKey2'
  | 'city2'
  | 'directionKey'
  | 'agreementTypeKey'
  | 'kilometers'
  | 'mainRoute'
> & {
  name: Select | string;
  typeKey: Select;
  countryKey: Select;
  city: Select | string;
  countryKey2: Select;
  city2: Select | string;
  directionKey: Select;
  agreementTypeKey: Select;
  kilometers: number;
  branchRoute: boolean;
};

export enum StopSectionFields {
  NAME = 'name',
  TYPE_KEY = 'typeKey',
  COUNTRY_KEY = 'countryKey',
  CITY = 'city',
  COUNTRY_KEY2 = 'countryKey2',
  CITY2 = 'city2',
  DIRECTION_KEY = 'directionKey',
  AGREEMENT_TYPE_KEY = 'agreementTypeKey',
  KILOMETERS = 'kilometers',
  BRANCH_ROUTE = 'branchRoute',
  DEPARTURES = 'departures'
}

export type TUpdateTransitSchedule = Omit<
  TransitScheduleUpdateRequest,
  'frequencies' | 'transitScheduleId' | 'transitScheduleVersion' | 'dates'
> & {
  frequencies: {
    transitScheduleId: string;
    transitScheduleVersion: number;
  };
  transitScheduleId: string;
  transitScheduleVersion: number;
  dates: TransitDateUpdateRequest[] | any[];
};

export interface TransitDateDetailsWithId extends TransitDateDetails {
  id?: string;
}

export const WzwStopTypeOptions = [
  DomainDictionaryEntry.STOP_TYPE.ALTERNATIVE_BORDER_CROSSING,
  DomainDictionaryEntry.STOP_TYPE.BORDER_CROSSING,
  DomainDictionaryEntry.STOP_TYPE.START_OF_TOUR,
  DomainDictionaryEntry.STOP_TYPE.END_OF_TOUR
];

export type TransitStopDetailsApp =
  | (TransitStopDetailsClient & {
      branchRoute: boolean;
    })
  | (TransitStopDetailsOperator & { branchRoute: boolean });
