import { GroupSnapshotExternal } from '@avispon/group/dist/models';
import { GroupAssignCommand } from '@avispon/user/dist/models';

import { useTableLayoutProps } from '@libs/common/v2';

import { useCreatePermissionsGroupColumns } from './columns';

export type UserGroupSnapshot = GroupAssignCommand[] & GroupSnapshotExternal[];

function useUserCreatePermissionsGroupTable(data: UserGroupSnapshot) {
  const { columns, mappedFilterFields } = useCreatePermissionsGroupColumns();

  return useTableLayoutProps({
    tableQuery: data,
    tableHookOptions: {
      columns: columns as UserGroupSnapshot,
      filterConverter: mappedFilterFields,
      tableOptions: {
        manualPagination: false,
        manualFilters: false,
        manualSorting: false
      }
    }
  });
}

export default useUserCreatePermissionsGroupTable;
