import { useTranslation } from 'react-i18next';

import { DropdownButton, DropdownListButton } from '@libs/common/v2';

interface IProps {
  onAddGroup?: () => void;
  onAddCondition?: () => void;
}

function ConditionGroupActionButton({ onAddGroup, onAddCondition }: IProps) {
  const [t] = useTranslation();

  return (
    <DropdownButton buttonType="icon" icon="AddIcon">
      {({ handleClose }) => (
        <>
          <DropdownListButton
            onClick={() => {
              onAddGroup();
              handleClose();
            }}
            label={t('other:component.conditionBuilder.addGroup')}
            icon="AddIcon"
          />
          <DropdownListButton
            onClick={() => {
              onAddCondition();
              handleClose();
            }}
            label={t('other:component.conditionBuilder.addCondition')}
            icon="AddIcon"
          />
        </>
      )}
    </DropdownButton>
  );
}

export default ConditionGroupActionButton;
