import { ProceedingAvailableFormsDetails } from '@ibtm/domain';

import { useTableLayoutProps } from '@libs/common/v2';

import { useFormOperationColumns } from '@libs/domain/proceeding';
import { useFormOperationsQuery } from '@libs/domain/suspensions/api';

function useFormOperationsSuspensionsTable(suspensionId: string, modalTableData: ProceedingAvailableFormsDetails[]) {
  const { columns, mappedFilterFields, mappedSortFields } = useFormOperationColumns([
    'permissionTypeCodeKey',
    'formNumber',
    'validTo',
    'statusKey',
    'suspendedFrom',
    'suspendedTo'
  ]);

  return useTableLayoutProps({
    ...(modalTableData ? { tableQuery: modalTableData } : { tableHookQuery: useFormOperationsQuery }),
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    },
    tableHookQueryInitialParams: {
      suspensionId
    }
  });
}

export default useFormOperationsSuspensionsTable;
