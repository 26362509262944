import { useMemo } from 'react';

import { Chip } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';

import { ALERT_STATUS_COLOR_CONFIG, AlertDictionaryEntryNameEnum, AlertStatusColorTypes } from '../../model';

interface IProps {
  statusKey: string;
}

function AlertStatusChip({ statusKey }: IProps) {
  const { translate } = useDictionaryTranslations();

  const alertColorDefinition = (colorType: AlertStatusColorTypes) => {
    switch (colorType) {
      case AlertStatusColorTypes.RED:
        return 'error';
      case AlertStatusColorTypes.YELLOW:
        return 'warning';
      case AlertStatusColorTypes.GREEN:
        return 'success';
      default:
        return 'success';
    }
  };

  const statusDefinitionItem = useMemo(
    () => ALERT_STATUS_COLOR_CONFIG.find(definition => definition.status === statusKey),
    [statusKey]
  );

  return (
    <Chip
      title={translate(AlertDictionaryEntryNameEnum.ALERT_STATUS, statusKey)}
      color={alertColorDefinition(statusDefinitionItem?.color)}
      colorWeight={statusDefinitionItem?.color === AlertStatusColorTypes.GREEN ? '700' : '800'}
    />
  );
}

export default AlertStatusChip;
