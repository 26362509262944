import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProceedingIdentityDetails } from '@ibtm/domain';

import { TableButton, TableIconButton, useRouter } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

import { DomainDictionaryEntry } from '../../../config';

interface IProps {
  pendingProceedings: ProceedingIdentityDetails[];
  actionKey: UIElementNameEnum;
  applicationId?: string;
  folderId?: string;
  showAsIcon?: boolean;
}

const GoToProceedingButton = ({ pendingProceedings, actionKey, applicationId, folderId, showAsIcon }: IProps) => {
  const [t] = useTranslation();
  const { routes } = useRouter();

  const link = useMemo(() => {
    const proceeding = pendingProceedings[0];

    const from = () => {
      if (applicationId) {
        return routes.applicationDetails(applicationId);
      }

      if (folderId) {
        return routes.folderDetails(folderId);
      }

      return routes.driversList();
    };

    const pathname = () => {
      if (proceeding?.categoryKey === DomainDictionaryEntry.PROCEEDING_TYPE.ADMINISTRATIVE) {
        return routes.proceedingAdministrationDetails(proceeding.folderId, proceeding.proceedingId);
      }

      return routes.proceedingVerificationDetails(proceeding.folderId, proceeding.proceedingId);
    };

    return { pathname: pathname(), from: from() };
  }, [pendingProceedings, routes, applicationId, folderId]);

  if (showAsIcon) {
    return (
      <TableIconButton
        icon="ArrowIcon"
        tooltipTitle={t('drivers:actions.goToProceeding')}
        link={link}
        actionKey={actionKey}
      />
    );
  }

  return <TableButton label={t('drivers:actions.goToProceeding')} link={link} actionKey={actionKey} />;
};

export { GoToProceedingButton };
