import { partialTranslate } from '@libs/common';

import { DomainDictionaryEntry, DomainUIElementEnum } from '@libs/domain/config';

import {
  AbilityRestorationTab,
  DeclarationOfInabilityTab,
  DischargeAndSuspensionOfCertificatesTab,
  DocumentsTab,
  GoodReputationTab,
  ImposingPenaltyTab,
  PermitSuspensionTab,
  TransportManagerTab,
  ViolationsTab
} from '../components/details/tabs';
import { ProceedingDetailsTabEnum } from '../models';

export const useProceedingDetailsTabDefinitions = ({ transportManagerId }: { transportManagerId: string }) => {
  const getLabel = partialTranslate('roadTransport:tabs');

  return [
    {
      label: getLabel('goodReputation'),
      value: ProceedingDetailsTabEnum.GOOD_REPUTATION,
      key: DomainDictionaryEntry.PROCEEDING_TYPE_KREPTD.GOOD_REPUTATION,
      content: <GoodReputationTab />,
      icon: 'BoxIcon',
      viewName: DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_VIEW
    },
    {
      label: getLabel('certificateSuspension'),
      value: ProceedingDetailsTabEnum.CERTIFICATE_SUSPENSION,
      key: DomainDictionaryEntry.PROCEEDING_TYPE_KREPTD.DISCHARGE_AND_SUSPENSION_OF_CERTIFICATES,
      content: <DischargeAndSuspensionOfCertificatesTab />,
      icon: 'BoxIcon'
    },
    {
      label: getLabel('permitSuspension'),
      value: ProceedingDetailsTabEnum.PERMIT_SUSPENSION,
      key: DomainDictionaryEntry.PROCEEDING_TYPE_KREPTD.PERMIT_SUSPENSION,
      content: <PermitSuspensionTab />,
      icon: 'BoxIcon'
    },
    {
      label: getLabel('imposingPenalty'),
      value: ProceedingDetailsTabEnum.IMPOSING_PENALTY,
      key: DomainDictionaryEntry.PROCEEDING_TYPE_KREPTD.IMPOSING_PENALTY,
      content: <ImposingPenaltyTab />,
      icon: 'BoxIcon'
    },
    {
      label: getLabel('inabilityDeclaration'),
      value: ProceedingDetailsTabEnum.INABILITY_DECLARATION,
      key: DomainDictionaryEntry.PROCEEDING_TYPE_KREPTD.DECLARATION_OF_INABILITY,
      content: <DeclarationOfInabilityTab />,
      icon: 'BoxIcon'
    },
    {
      label: getLabel('abilityDeclaration'),
      value: ProceedingDetailsTabEnum.ABILITY_RESTORATION,
      key: DomainDictionaryEntry.PROCEEDING_TYPE_KREPTD.RESTORE_OF_ABILITIES,
      content: <AbilityRestorationTab />,
      icon: 'BoxIcon'
    },
    {
      label: getLabel('violations'),
      value: ProceedingDetailsTabEnum.VIOLATIONS,
      content: <ViolationsTab />,
      icon: 'NoteIcon',
      viewName: DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_VIOLATIONS_VIEW
    },
    {
      label: getLabel('transportManager'),
      value: ProceedingDetailsTabEnum.TRANSPORT_MANAGER,
      content: <TransportManagerTab transportManagerId={transportManagerId} />,
      icon: 'TieManIcon',
      viewName: DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_TRANSPORT_MANAGERS_VIEW
    },
    {
      label: getLabel('documents'),
      value: ProceedingDetailsTabEnum.DOCUMENTS,
      content: <DocumentsTab />,
      icon: 'DocumentsIssuedIcon',
      viewName: DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_DOCUMENTS_VIEW
    }
  ];
};
