import { AttachmentVerificationElementDetails } from '@ibtm/client-domain';

import { useCreateColumns } from '@libs/common/v2';

import { IAttachmentListItemClient } from '../shared/attachments/model';

export const useAttachmentColumns = () => {
  const { createColumns } = useCreateColumns<IAttachmentListItemClient, AttachmentVerificationElementDetails>({
    pathToTranslate: 'applications:attachments.fields'
  });

  const columns = createColumns([
    { id: 'order', type: 'TEXT' },
    { accessor: 'name', type: 'DICTIONARY_SINGLE_SELECT', dictionaryName: 'ATTACHMENTS_TO_APPLICATION', width: 300 },
    { accessor: 'notes', type: 'TEXT', header: 'note', width: 300 }
  ]);

  return { columns };
};
