import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function deleteInsurance(id: string) {
  return API.insurancePolicies.deleteInsurancePolicy(id, {
    ...endpointsConfig.deleteInsurancePolicy
  });
}

function useDeleteInsuranceMutation() {
  return useMutation(deleteInsurance);
}

export default useDeleteInsuranceMutation;
