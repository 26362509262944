import { useDialog } from '@libs/common/v2';

import { AssignApplicationToUserDialog } from '../components';

interface IShowProcessFormDialogProps {
  applicationId: string;
  applicationVersion: number;
  onFinish: () => void;
}

function useAssignApplicationDialog() {
  const { openDialog } = useDialog();

  const showAssignApplicationDialog = ({
    applicationId,
    applicationVersion,
    onFinish
  }: IShowProcessFormDialogProps) => {
    openDialog(({ closeDialog }) => (
      <AssignApplicationToUserDialog
        closeDialog={closeDialog}
        applicationId={applicationId}
        applicationVersion={applicationVersion}
        onFinish={onFinish}
      />
    ));
  };

  return { showAssignApplicationDialog };
}

export default useAssignApplicationDialog;
