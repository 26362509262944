export enum AuthEventsEnum {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  AUTO_LOGOUT = 'AUTO_LOGOUT',
  AUTO_LOGOUT_WARNING = 'AUTO_LOGOUT_WARNING',
  AUTO_LOGIN = 'AUTO_LOGIN',
  NO_ACCESS_TOKEN = 'NO_ACCESS_TOKEN',
  LDAP_USER_MUST_RESET_PASSWORD = 'LDAP_USER_MUST_RESET_PASSWORD',
  ERROR_MESSAGE = 'ERROR_MESSAGE'
}
