import { DataSourceMediatorDetailsSnapshot, ReportDetailsSnapshot } from '@stack/report/dist/models';
import { isEmpty } from 'lodash';

import { ReportTypeForm } from '@libs/report/model/form.model';

import { HiddenReportParameterColumnEnum, ReportStatusDictionaryEnum } from '../model';

export const isReportParameterized = ({ dataSets }: ReportDetailsSnapshot) => {
  const onlyHiddenParameters = (dynamicParametersValues: { [key: string]: any }) => {
    return (
      Object.keys(dynamicParametersValues).length <= 2 &&
      (HiddenReportParameterColumnEnum.HIDDEN_UNIT_ID in dynamicParametersValues ||
        HiddenReportParameterColumnEnum.HIDDEN_USER_ID in dynamicParametersValues)
    );
  };

  return dataSets?.some(
    dataSet =>
      dataSet?.dynamicParametersValues &&
      !isEmpty(dataSet.dynamicParametersValues) &&
      !onlyHiddenParameters(dataSet.dynamicParametersValues)
  );
};

export const isReportInProgress = (report: ReportDetailsSnapshot) => {
  return [
    ReportStatusDictionaryEnum.DATA_GENERATION_IN_PROGRESS,
    ReportStatusDictionaryEnum.PENDING,
    ReportStatusDictionaryEnum.PRE_PENDING,
    ReportStatusDictionaryEnum.DOCUMENT_GENERATION_IN_PROGRESS
  ].includes(report?.statusKey as ReportStatusDictionaryEnum);
};

const assignClickEventToDownloadConvertedData = (element: HTMLElement) => {
  const event = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true
  });

  element.dispatchEvent(event);
  element.remove();
};

export const convertDataToJsonFile = (data, name) => {
  const jsonData = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
  const link = document.createElement('a');
  link.href = jsonData;
  link.download = `${name}.json`;
  assignClickEventToDownloadConvertedData(link);
};

export const convertDataToZip = (data: Blob) => {
  const zipData = new Blob([data], { type: 'application/zip' });
  const link = document.createElement('a');
  const date = new Date().toISOString();
  link.href = window.URL.createObjectURL(zipData);
  link.download = `raporty-${date}.zip`;
  assignClickEventToDownloadConvertedData(link);
};

export const getReportTypeFormInitialValues = (): ReportTypeForm => ({
  name: '',
  description: '',
  cron: '',
  permissionKeys: [],
  documentTemplate: { id: '', name: '' },
  source: { id: '', name: '' } as DataSourceMediatorDetailsSnapshot,
  sourceModified: [false],
  dataSetDefinitions: [],
  queryResults: [],
  parameterExtras: []
});
