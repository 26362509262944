import { useTranslation } from 'react-i18next';
import { DictionarySnapshot } from '@avispon/dictionary';

import { FormMode, useViewModesV2 } from '@libs/common/v2';
import { unescapeValue } from '@libs/common/v2/utils';

import { DictionaryEntryNameEnum } from '@libs/dictionary/models';

import { useDictionaryInfo } from './useDictionaryInfo';

function useDictionaryBreadcrumbElements({
  formMode,
  dictionaryKey,
  quicklyChangeableDictionary,
  isLoading
}: {
  formMode?: FormMode;
  dictionaryKey?: DictionaryEntryNameEnum;
  quicklyChangeableDictionary?: DictionarySnapshot;
  isLoading?: boolean;
}) {
  const [t] = useTranslation();
  const currentDictionary = useDictionaryInfo(dictionaryKey as DictionaryEntryNameEnum);
  const { createMode } = useViewModesV2(formMode);

  return [
    { text: t('dictionary:title'), to: '/administration/dictionaries/' },
    {
      text: t(
        'dictionary:dictionaryDetails',
        unescapeValue({ name: currentDictionary?.description || quicklyChangeableDictionary?.description })
      ),
      to: `/administration/dictionaries/${currentDictionary.name || quicklyChangeableDictionary?.name}/details`,
      loading: isLoading
    },
    ...(formMode ? [{ text: createMode ? t('dictionary:newItem') : t('dictionary:editItem') }] : [])
  ];
}

export default useDictionaryBreadcrumbElements;
