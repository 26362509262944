import { useAppConfig } from '@libs/app-config';
import { RoutingPermissionConfig } from '@libs/config/permissions/routesPermission.config';

import { useRouter } from '@libs/common/v2';

import { TRoutingPermissionConfig } from './route-guard.model';
import { routeGuardRoutes } from './route-guard-routes';
import { useRoutePermissionCheck } from './RoutePermissionGuard';

const stringWildCard = '.*';

const useRouteConstantPermissionCheck = (isActive: boolean) => {
  const { location } = useRouter();

  // Usuwamy znak  / z końca url żeby pasował do zdefiniowanych url routingu
  const url = location?.pathname?.replace(/\/$/, '');

  const permissionsKey = (Object.keys(RoutingPermissionConfig) as Array<keyof TRoutingPermissionConfig>).find(
    routeFnName => {
      const routeFN = routeGuardRoutes[routeFnName];

      const routePattern = routeFN(stringWildCard, stringWildCard, stringWildCard);
      const routePatternRegExp = new RegExp(`^${routePattern}$`);

      // eslint-disable-next-line no-console

      return routePatternRegExp.test(url);
    }
  );

  const { permissions, anyOfPermissions, hasAllPermissions } = RoutingPermissionConfig[permissionsKey] || {};

  const { mainPage } = useAppConfig();
  return useRoutePermissionCheck(
    !isActive
      ? { checkPermissions: false }
      : {
          isSnackbarOnRedirectVisible: false,
          permissions,
          redirectTo: mainPage,
          hasAllPermissions: !!hasAllPermissions,
          anyOfPermissions
        }
  );
};

export const RoutesConstantPermissionGuard = ({ children, isActive }: { children: JSX.Element; isActive: boolean }) => {
  const { hasPermissions } = useRouteConstantPermissionCheck(isActive);
  return hasPermissions ? children : null;
};
