import { useCallback } from 'react';
import { QueryOrder } from '@stack/report';

import { useDialog } from '@libs/common/v2';

import { SortingSettingsDialogContent } from '@libs/report/components/report-type-form';

import { QueryOrderSettings } from '../model/form.model';

function useSortingSettingsDialog() {
  const { openDialog } = useDialog();

  const showDialog = useCallback(
    (
      onConfirm: (values: QueryOrderSettings) => void,
      queryDefinitionOrders: QueryOrder[],
      initialEditFormValues?: QueryOrder
    ) => {
      openDialog(({ closeDialog }) => (
        <SortingSettingsDialogContent
          closeDialog={closeDialog}
          onConfirm={onConfirm}
          initialEditFormValues={initialEditFormValues}
          queryDefinitionOrders={queryDefinitionOrders}
        />
      ));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog]
  );

  const openSortingSettingsDialog = (
    onConfirm: (values: QueryOrderSettings) => void,
    queryDefinitionOrders: QueryOrder[],
    initialEditFormValues?: QueryOrder
  ) => {
    showDialog(onConfirm, queryDefinitionOrders, initialEditFormValues);
  };

  return {
    openSortingSettingsDialog
  };
}

export default useSortingSettingsDialog;
