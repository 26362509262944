import { ResourceObjectOrderUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

export function updateResourceObjectOrder(requestData: ResourceObjectOrderUpdateRequest) {
  return API.resourceObjectsOrders.updateResourceObjectOrder(requestData, {
    ...endpointsConfig.updateResourceObjectOrder
  });
}

function useResourceObjectsOrderUpdateMutation() {
  return useMutation(updateResourceObjectOrder);
}

export default useResourceObjectsOrderUpdateMutation;
