import { useTranslation } from 'react-i18next';
import moment from 'moment';
import * as Yup from 'yup';

import { convertDateToDateFormat } from '@libs/common/v2/utils';

function useRoadTransportValidation() {
  const [t] = useTranslation();

  const validationSchema = Yup.object({
    startDecisionDeliveryDate: Yup.date()
      .nullable()
      .test(
        'isLargerThanDecisionPermissionSuspensionFrom',
        t('roadTransport:validators.dateMustBeLaterThan', {
          field: t('roadTransport:fields.startDate')
        }),
        function checkIsStartDecisionDeliveryDateCorrect(this, startDecisionDeliveryDate) {
          const { startDate } = this.parent;

          if (!startDecisionDeliveryDate || !startDate) {
            return true;
          }

          return moment(startDecisionDeliveryDate).isAfter(moment(startDate));
        }
      ),
    decisionDate: Yup.date()
      .nullable()
      .test(
        'isLargerThanDecisionPermissionSuspensionFrom',
        t('roadTransport:validators.dateMustBeLaterThan', {
          field: t('roadTransport:fields.startDecisionDeliveryDate')
        }),
        function checkIsDecisionDateCorrect(this, decisionDate) {
          const { startDecisionDeliveryDate } = this.parent;

          if (!decisionDate || !startDecisionDeliveryDate) {
            return true;
          }

          return convertDateToDateFormat(decisionDate) > convertDateToDateFormat(startDecisionDeliveryDate);
        }
      ),
    decisionDeliveryDate: Yup.date()
      .nullable()
      .test(
        'isLargerThanDecisionPermissionSuspensionFrom',
        t('roadTransport:validators.dateMustBeLaterThan', {
          field: t('roadTransport:fields.decisionDate')
        }),
        function checkIsDecisionDeliveryDateCorrect(this, decisionDeliveryDate) {
          const { decisionDate } = this.parent;

          if (!decisionDeliveryDate || !decisionDate) {
            return true;
          }

          return convertDateToDateFormat(decisionDeliveryDate) > convertDateToDateFormat(decisionDate);
        }
      ),
    decisionReviewApplicationDeliveryDate: Yup.date()
      .nullable()
      .test(
        'isLargerThanDecisionPermissionSuspensionFrom',
        t('roadTransport:validators.dateMustBeLaterThan', {
          field: t('roadTransport:fields.decisionDeliveryDate')
        }),
        function checkIsDecisionReviewApplicationDeliveryDateCorrect(this, decisionReviewApplicationDeliveryDate) {
          const { decisionDeliveryDate } = this.parent;

          if (!decisionReviewApplicationDeliveryDate || !decisionDeliveryDate) {
            return true;
          }

          return (
            convertDateToDateFormat(decisionReviewApplicationDeliveryDate) >
            convertDateToDateFormat(decisionDeliveryDate)
          );
        }
      ),
    decisionWsaComplaintDeliveryDate: Yup.date()
      .nullable()
      .test(
        'isLargerThanDecisionPermissionSuspensionFrom',
        t('roadTransport:validators.dateMustBeLaterThan', {
          field: t('roadTransport:fields.decisionDeliveryDate')
        }),
        function checkIsDecisionWsaComplaintDeliveryDateCorrect(this, decisionWsaComplaintDeliveryDate) {
          const { decisionDeliveryDate } = this.parent;

          if (!decisionWsaComplaintDeliveryDate || !decisionDeliveryDate) {
            return true;
          }

          return (
            convertDateToDateFormat(decisionWsaComplaintDeliveryDate) >
            convertDateToDateFormat(this.parent.decisionDeliveryDate)
          );
        }
      ),
    secondTierBpDeliveryDate: Yup.date()
      .nullable()
      .test(
        'isLargerThanDecisionPermissionSuspensionFrom',
        t('roadTransport:validators.dateMustBeLaterThan', {
          field: t('roadTransport:fields.decisionReviewApplicationDeliveryDate')
        }),
        function checkIsSecondTierBpExecutionSuspensionDecisionDateCorrect(this, secondTierBpDeliveryDate) {
          const { decisionReviewApplicationDeliveryDate } = this.parent;

          if (!secondTierBpDeliveryDate || !decisionReviewApplicationDeliveryDate) {
            return true;
          }

          return (
            convertDateToDateFormat(secondTierBpDeliveryDate) >
            convertDateToDateFormat(decisionReviewApplicationDeliveryDate)
          );
        }
      ),
    secondTierBpDecisionDate: Yup.date()
      .nullable()
      .test(
        'isLargerThanDecisionPermissionSuspensionFrom',
        t('roadTransport:validators.dateMustBeLaterThan', {
          field: t('roadTransport:fields.secondTierBpDeliveryDate')
        }),
        function checkIsSecondTierBpDecisionDateCorrect(this, secondTierBpDecisionDate) {
          const { secondTierBpDeliveryDate } = this.parent;

          if (!secondTierBpDecisionDate || !secondTierBpDeliveryDate) {
            return true;
          }

          return convertDateToDateFormat(secondTierBpDecisionDate) > convertDateToDateFormat(secondTierBpDeliveryDate);
        }
      ),
    secondTierBpComplaintDeliveryDate: Yup.date()
      .nullable()
      .test(
        'isLargerThanDecisionPermissionSuspensionFrom',
        t('roadTransport:validators.dateMustBeLaterThan', {
          field: t('roadTransport:fields.secondTierBpDecisionDate')
        }),
        function checkIsSecondTierBpComplaintDeliveryDateCorrect(this, secondTierBpComplaintDeliveryDate) {
          const { secondTierBpDecisionDate } = this.parent;

          if (!secondTierBpComplaintDeliveryDate || !secondTierBpDecisionDate) {
            return true;
          }

          return (
            convertDateToDateFormat(secondTierBpComplaintDeliveryDate) >
            convertDateToDateFormat(secondTierBpDecisionDate)
          );
        }
      ),
    secondTierBpExecutionSuspensionDecisionDate: Yup.date()
      .nullable()
      .test(
        'isLargerThanDecisionPermissionSuspensionFrom',
        t('roadTransport:validators.dateMustBeLaterThan', {
          field: t('roadTransport:fields.secondTierBpComplaintDeliveryDate')
        }),
        function checkIsSecondTierBpExecutionSuspensionDecisionDateCorrect(
          this,
          secondTierBpExecutionSuspensionDecisionDate
        ) {
          const { secondTierBpComplaintDeliveryDate } = this.parent;
          if (!secondTierBpExecutionSuspensionDecisionDate || !secondTierBpComplaintDeliveryDate) {
            return true;
          }

          return (
            convertDateToDateFormat(secondTierBpExecutionSuspensionDecisionDate) >
            convertDateToDateFormat(secondTierBpComplaintDeliveryDate)
          );
        }
      ),
    decisionPermissionSuspensionFrom: Yup.date()
      .nullable()
      .test(
        'isLargerThanDecisionPermissionSuspensionFrom',
        t('validation:minDate', {
          fieldName: t('roadTransport:fields.decisionFinalDate')
        }),
        function checkIsDecisionPermissionSuspensionFromCorrect(this, decisionPermissionSuspensionFrom) {
          const { decisionFinalDate } = this.parent;

          if (!decisionPermissionSuspensionFrom || !decisionFinalDate) {
            return true;
          }

          return (
            convertDateToDateFormat(decisionPermissionSuspensionFrom) >= convertDateToDateFormat(decisionFinalDate)
          );
        }
      ),
    decisionPermissionSuspensionTo: Yup.date()
      .nullable()
      .test(
        'isLargerThanDecisionPermissionSuspensionFrom',
        t('roadTransport:validators.dateMustBeLaterThan', {
          field: t('roadTransport:fields.decisionPermissionSuspensionFrom')
        }),
        function checkIsDecisionPermissionSuspensionToCorrect(this, decisionPermissionSuspensionTo) {
          const { decisionPermissionSuspensionFrom } = this.parent;

          if (!decisionPermissionSuspensionTo || !decisionPermissionSuspensionFrom) {
            return true;
          }

          return (
            convertDateToDateFormat(decisionPermissionSuspensionTo) >
            convertDateToDateFormat(decisionPermissionSuspensionFrom)
          );
        }
      ),
    secondTierNsaExecutionSuspensionDecisionDate: Yup.date().nullable(),
    licenseCopiesReturnDate: Yup.date().nullable()
  });

  return { validationSchema };
}

export default useRoadTransportValidation;
