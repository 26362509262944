export enum ParameterExtrasTooltipEnum {
  TYPE = 'report.hint.field.type',
  AVAIABLE_FUNCTIONS = 'report.hint.field.available-functions',
  RELEASED_BY = 'report.hint.field.released-by',
  CHECKBOX = 'report.hint.checkbox',
  REQUIRED = 'report.hint.required',
  BLOCKED_BY = 'report.hint.field.blocked-by',
  SUGGESTED_VALUE = 'report.hint.field.suggested-value',
  REQUIRED_PARAMETERS = 'report.hint.field.required-parameters',
  CHECK_FIELD = 'report.hint.field.check-field',
  GROUP_NAME = 'report.hint.group-name',
  MULTIPLE_VALUES = 'report.hint.field.multiple-values',
  INDEX = 'report.hint.field.index',
  DEFAULT_VALUE = 'report.hint.field.default-value',
  CRON = 'report.hint.field.cron',
  REQUIRE_ALL_PARAMETER_VALUES = 'report.hint.require-all-parameter-values',
  PERMISSION = 'report.hint.field.permission',
  VALIDATOR = 'report.hint.field.validator'
}
