import { CivicBoardCalculateFolderLimitRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function calculateFolder(params: CivicBoardCalculateFolderLimitRequest) {
  return API.civicBoard.calculateFolderLimit(params, {
    ...endpointsConfig.calculateFolderLimit
  });
}

function useCalculateLimitForSpecificFolderMutation() {
  return useMutation(calculateFolder);
}

export default useCalculateLimitForSpecificFolderMutation;
