import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { SuspensionFormRestoreRequest } from '@ibtm/domain';
import moment from 'moment';
import * as Yup from 'yup';

import { partialTranslate } from '@libs/common';
import {
  ALTERNATIVE_DATE_FORMAT,
  DatepickerField,
  Dialog,
  FormV2Context,
  GridLayout,
  typedNameV2
} from '@libs/common/v2';
import { getCalendarDate } from '@libs/common/v2/utils';

import { useRestoreFormOperationsMutation } from '@libs/domain/suspensions';

interface Props {
  onCancel: () => void;
  onConfirm: () => void;
  suspensionId: string;
  dateFrom: string;
  dateTo: string;
  version: number;
  refresh: () => void;
}

function PostponeDialog({ suspensionId, dateFrom, dateTo, onCancel, onConfirm, refresh, version }: Props) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();

  const getMessages = partialTranslate('suspensions:formOperations');
  const getAction = partialTranslate('suspensions:formOperations.action');

  const { mutate: restoreFormOperations } = useRestoreFormOperationsMutation();

  const onSubmit = (formValues: Partial<SuspensionFormRestoreRequest>, setConfirmLoading: (value: boolean) => void) => {
    setConfirmLoading(true);
    restoreFormOperations(
      {
        suspensionId,
        suspendedTo: getCalendarDate(moment(formValues.suspendedTo).format(ALTERNATIVE_DATE_FORMAT)),
        version
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(getMessages('success.postpone'));
          refresh();
          onConfirm();
        },
        onSettled: () => {
          setConfirmLoading(false);
        }
      }
    );
  };

  const form = useForm<Partial<SuspensionFormRestoreRequest>>({
    defaultValues: { suspendedTo: '' },
    resolver: yupResolver(
      Yup.object({
        suspendedTo: Yup.date()
          .min(moment(dateFrom).startOf('day').toISOString(), t('validation:minDateWithValue', { value: dateFrom }))
          .max(moment(dateTo).endOf('day').toISOString(), t('validation:maxDateWithValue', { value: dateTo }))
          .required()
          .typeError(t('validation:required'))
      })
    ),
    mode: 'onBlur'
  });

  const formDataProvider = useMemo(() => {
    return { ...form };
  }, [form]);

  return (
    <Dialog
      title={t('suspensions:formOperations.postponeDialogTitle')}
      confirmText={getAction('postpone')}
      cancelText={t('action.cancel')}
      onConfirm={setConfirmLoading => {
        form.handleSubmit(values => {
          onSubmit(values, setConfirmLoading);
        })();
      }}
      onCancel={onCancel}
      isOpen
    >
      <FormV2Context.Provider value={formDataProvider}>
        <GridLayout itemProps={{ xs: 12 }}>
          <DatepickerField
            name={typedNameV2<SuspensionFormRestoreRequest>('suspendedTo')}
            label={t('suspensions:formOperations.field.postponeDate')}
            isRequired
          />
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default PostponeDialog;
