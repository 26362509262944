import AttachmentsMatrix, { AttachmentsMatrixProps } from './AttachmentsMatrix';
import AttachmentsSection, { IAttachmentsSection } from './AttachmentsSection';
import AttachmentsWrapper from './AttachmentsWrapper';

export const AttachmentsMatrixWithWrapper = (props: AttachmentsMatrixProps) => {
  return (
    <AttachmentsWrapper
      applicationId={props?.applicationId}
      fieldId={props?.fieldId}
      isOnlyBooleanColumn={props?.isOnlyBooleanColumn}
    >
      {({ verificationElements, isLoading }) => (
        <AttachmentsMatrix isLoading={isLoading} verificationElements={verificationElements} {...props} />
      )}
    </AttachmentsWrapper>
  );
};
export const AttachmentsSectionWithWrapper = (props: IAttachmentsSection) => {
  return (
    <AttachmentsWrapper
      applicationId={props?.applicationId}
      fieldId={props?.fieldId}
      isOnlyBooleanColumn
      isSetMainComment
    >
      {({ verificationElements, isLoading }) => (
        <AttachmentsSection {...props} verificationElements={verificationElements} isLoading={isLoading} />
      )}
    </AttachmentsWrapper>
  );
};
