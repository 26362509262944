import { useTableLayoutProps } from '@libs/common/v2';

import { useApplicationHistoryQuery } from '@libs/domain/application/api';
import { useApplicationHistoryColumns } from '@libs/domain/application/hooks';

function useApplicationHistoryTable(applicationId: string) {
  const { columns, mappedFilterFields } = useApplicationHistoryColumns();

  return useTableLayoutProps({
    tableHookQuery: useApplicationHistoryQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'created', desc: true }]
        }
      }
    },
    tableHookQueryInitialParams: {
      id: applicationId
    }
  });
}

export default useApplicationHistoryTable;
