import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

interface IParams {
  proceedingId: string;
  certificateId: string;
}

function deleteProceedingsKreptdCertificate({ proceedingId, certificateId }: IParams) {
  return API.proceedingsKreptd.deleteProceedingsKreptdCertificate(proceedingId, certificateId, {
    ...endpointsConfig.deleteProceedingsKreptdCertificate
  });
}

function useDeleteProceedingsKreptdCertificateMutation() {
  return useMutation(deleteProceedingsKreptdCertificate);
}

export default useDeleteProceedingsKreptdCertificateMutation;
