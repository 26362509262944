import { useEffect, useMemo, useState } from 'react';
import { QueryResult, useQuery } from 'react-query';
import { PluginHook, Row, TableOptions as TableOptionsType } from 'react-table';
import deepmerge from 'deepmerge';
import _ from 'lodash';

import {
  filterTypes,
  getTheLongestRowToRenderColumns,
  ITableAdapter,
  ITableLayoutColumn,
  Path,
  useCreateTableInstance
} from '@libs/common/v2';
import { convertFilters, convertInitialParams, convertSortBy } from '@libs/common/v2/utils';

export interface IQueryTableOptions<T extends Record<string, any>, K extends Record<string, any> = T> {
  /**
   * @type T - model Frontend/Backend.
   * @type K - opcjonalny model Backend. Wykorzystywany przy mappowaniu wartości do pliku xlsx
   * @konwencja typ `T`to model zawierający definicje danych do tabeli. Może być modelem backend'u,
   * jeżeli na nim jest oparta implementacja gui, lub jeżeli dane są parsowane i dostosowane go modelu
   * frontend'owego, który jest rozbieżny z modelem backend wtedy do typu `T` przypisujemy model frontend,
   * natomiast do typu `K` przypisujemy model backend. W ten sposób przy mappowaniu wartości do pliku xlsx
   * mamy dostęp do atrybutów z modelu backend.
   * @implementacja Zakłada użycie którejś z funkcji hook'a useCreateColumns.
   * Tablica obiektów na podstawie których zostają wyrenderowane kolumny tabeli.
   * Lub funkcja w której dostajemy jako argument obiekt,
   * który przekazujemy dalej do fukcji tworzącej kolumny (createColumnsWithArrays)
   */
  columns: ITableLayoutColumn<T, K>[] | ((mapper: Record<string, number>) => ITableLayoutColumn<T, K>[]);
  /**
   * Ścieżki w modelu do tablic z danymi
   */
  arrayPaths?: Path<T>[];
  // Konwerter parametrów (konwersja wartości parametru na wartość przyjmowaną przez api)
  initialParamsConverter?: Record<string, (value: unknown) => Record<string, any>>;
  // Konwerter filtrów tabeli (konwersja wartości filtra na wartość przyjmowaną przez api)
  filterConverter?: Record<string, (value: unknown) => Record<string, any>>;
  // Konwerter sortowania tabeli (konwersja klucza tabeli na klucz przyjmowany przez api)
  sortByConverter?: Partial<Record<keyof T, string>>;
  // Dodatkowe opcje do tabeli
  tableOptions?: Partial<TableOptionsType<T>>;
  // Dodatkowe pluginy do tabeli
  tablePlugins?: Array<PluginHook<T>>;
  // nie zawsze w modelu mamy id, zmiana id na inny parametr
  getRowId?: (row) => string;
}

// Domyślna wartość danych - wyciągnięta poza hook żeby nie wywoływać rerenderingu
const EmptyArray = [];

/**
 * Hook przystosowany do działania z hook `useCustomTableQuery`.
 * Używanie bez niego może prowadzić do dziwnych zachowań
 */
function useQueryTable<T extends Record<string, any>, K extends Record<string, any> = T>(
  dataQuery: ((params) => Promise<T[]>) | T[],
  options: IQueryTableOptions<T, K> = { columns: EmptyArray },
  queryInitialParams?: any,
  dataHookQueryConfig = {},
  queryKey = 'queryTable'
): [ITableAdapter<T, K>, QueryResult<T[], unknown>, { isLoading: boolean; newRowScheme?: Row<T> }] {
  const {
    columns,
    tablePlugins,
    tableOptions,
    filterConverter,
    initialParamsConverter,
    sortByConverter,
    arrayPaths,
    getRowId
  } = options;

  const [params, setParams] = useState<Record<string, unknown>>(queryInitialParams);

  const initialParams = () => {
    if (initialParamsConverter) {
      const paramsToConvert =
        Object.entries(params).map(([key, value]) => {
          if (typeof value === 'object') {
            return { ...value, id: key };
          }
          return { id: key, value };
        }) ?? [];
      return convertInitialParams(paramsToConvert, initialParamsConverter);
    }
    return params;
  };

  const defaultTableOptions = {
    manualPagination: true,
    manualFilters: true,
    manualSortBy: true,
    initialState: {
      pageSize: 25,
      pageIndex: 0
    },
    filterTypes
  };

  const query = useQuery(
    [queryKey, params],
    typeof dataQuery === 'function' ? () => dataQuery(initialParams()) : null,
    { enabled: Array.isArray(params.sortBy), ...dataHookQueryConfig }
  );

  const { data, isLoading } = query;
  const longestRow = useMemo(
    () => getTheLongestRowToRenderColumns(data ?? dataQuery, arrayPaths) as Record<string, number>,
    [arrayPaths, data, dataQuery]
  );

  const tableNewRowScheme = useCreateTableInstance<T, K>({
    columns,
    getRowId: row => (getRowId ? getRowId(row) : row.id),
    longestRow,
    queryData: useMemo(() => [{ id: `newFirstRow` }], []),
    tableOptions: deepmerge(defaultTableOptions, tableOptions ?? {}),
    tablePlugins
  });

  const table = useCreateTableInstance({
    columns,
    getRowId: row => (getRowId ? getRowId(row) : row?.id || row?.value),
    longestRow,
    queryData: data || (Array.isArray(dataQuery) && dataQuery) || EmptyArray,
    tableOptions: deepmerge(defaultTableOptions, { ...tableOptions, pageCount: data?.length ?? -1 } ?? {}),
    tablePlugins
  });

  const { pageIndex, pageSize, sortBy, filters } = table.state;

  useEffect(() => {
    setParams({
      pageIndex: pageIndex ?? 0,
      pageSize,
      sortBy: sortByConverter ? convertSortBy(_.isEmpty(sortBy) ? null || sortBy : sortBy, sortByConverter) : sortBy,
      ...(filterConverter ? convertFilters(filters, filterConverter) : filters)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize, sortBy, filters]);

  return useMemo(() => {
    const getSchemeRow = () => {
      if (tableNewRowScheme?.page?.[0]) {
        tableNewRowScheme.prepareRow(tableNewRowScheme?.page?.[0]);
        return tableNewRowScheme?.page?.[0];
      }
      return tableNewRowScheme?.page?.[0];
    };
    const additionalData = {
      isLoading: typeof dataQuery === 'function' ? isLoading : false,
      newRowScheme: getSchemeRow()
    };

    return [
      table,
      typeof dataQuery === 'function' ? query : { ...query, isLoading: false, isFetching: false, data: dataQuery },
      additionalData
    ];
  }, [dataQuery, isLoading, tableNewRowScheme, table, query]);
}

export default useQueryTable;
