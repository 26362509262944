import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function deleteSuspension({ id }: { id: string }) {
  return API.suspensions.deleteSuspension(id, {
    ...endpointsConfig.deleteSuspension
  });
}

function useDeleteSuspensionMutation() {
  return useMutation(deleteSuspension);
}

export default useDeleteSuspensionMutation;
