import { MutationConfig } from 'react-query';
import { SubjectDetails } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export async function expireSubject(subjectId) {
  const { data } = await API.subject.expireSubject(subjectId, {
    ...endpointsConfig.expireSubject
  });
  return data;
}

function useExpireSubjectMutation(config?: MutationConfig<SubjectDetails>) {
  return useMutation(expireSubject, config);
}

export default useExpireSubjectMutation;
