import { CamelCasePath, SelectOption, TableFilterMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO } from '@libs/common/v2/utils';

import { UserFilterV2 } from '@libs/user';

import { FilesListClientItem } from '../../models/file.model';

const useFilesTableColumns = (visibleColumns: Array<CamelCasePath<FilesListClientItem>>) => {
  const { createColumns } = useCreateColumns<FilesListClientItem>({
    pathToTranslate: 'global:field.attachments'
  });

  const mappedFilterFields: TableFilterMapper<FilesListClientItem, any> = {
    fileName: (fileName: string) => ({ nameContains: fileName }),
    created: ({ dateFrom, dateTo }: DateRangeISO) => ({
      createdGreaterThanOrEqual: dateFrom,
      createdLessThanOrEqual: dateTo
    }),
    authorName: (author: SelectOption<string>[]) => ({ authorIdIn: author.map(({ value }) => value) })
  };

  const columns = createColumns(
    [
      {
        type: 'TEXT',
        accessor: 'fileName'
      },
      {
        type: 'DATE',
        accessor: 'created',
        width: 255
      },
      {
        header: 'author',
        type: 'CUSTOM_COLUMN',
        accessor: 'author.name',
        filter: UserFilterV2
      }
    ],
    visibleColumns
  );

  return { columns, mappedFilterFields };
};

export const DEFAULT_COLUMNS: Array<CamelCasePath<FilesListClientItem>> = ['fileName', 'created', 'authorName'];

export default useFilesTableColumns;
