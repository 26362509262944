import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { useConfirmDialog } from '@libs/common/v2';

import { ProceedingSnapshotClient, useProceedingCompleteMutation } from '@libs/domain/proceeding';

const useEndProceedingAction = () => {
  const [confirm] = useConfirmDialog();
  const { showSuccessSnackbar } = useSnackbar();
  const [t] = useTranslation();
  const { mutate: completeProceeding } = useProceedingCompleteMutation();

  const endProceeding = (proceedingData: ProceedingSnapshotClient, onSuccess: () => void) => {
    confirm({
      title: t('proceeding:dialog.endProceedingDialogTitle'),
      message: t('proceeding:dialog.endProceedingConfirmation', {
        proceedingNumber: proceedingData.proceedingNumber
      }),
      onConfirm: (setConfirmLoading, close) => {
        setConfirmLoading(true);
        completeProceeding(
          { proceedingId: proceedingData.id, version: proceedingData.version },
          {
            onSuccess: () => {
              showSuccessSnackbar(t('proceeding:messages.endProceedingSuccess'));
              onSuccess();
              close();
            },
            onSettled: () => {
              setConfirmLoading(false);
            }
          }
        );
      },
      confirmType: 'primary'
    });
  };

  return {
    endProceeding
  };
};

export default useEndProceedingAction;
