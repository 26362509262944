import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { date as YupDate, object as YupObject } from 'yup';

import { partialTranslate } from '@libs/common';

function useFormOperationValidations() {
  const [t] = useTranslation();
  const getAddOrUpdateLockLabel = partialTranslate('proceeding:addOrUpdateLockDialog.field');
  const getSuspendLabel = partialTranslate('proceeding:administrative.tab.formOperations.field');

  const addOrUpdateLockValidationSchema = YupObject({
    validFrom: YupDate().required().typeError(t('validation:default')),
    validTo: YupDate()
      .required()
      .test(
        'validFromValidation',
        t('validation:minDate', { fieldName: getAddOrUpdateLockLabel('validFrom') }),
        function checkIsValidFromValidation(validTo) {
          return !validTo || !this.parent.validFrom || !moment(validTo).isBefore(this.parent.validFrom);
        }
      )
      .typeError(t('validation:default'))
  });

  const suspendFormOperationValidationSchema = YupObject({
    suspendedFrom: YupDate().required().typeError(t('validation:default')),
    suspendedTo: YupDate()
      .required()
      .test(
        'validFromValidation',
        t('validation:minDate', { fieldName: getSuspendLabel('suspensionFrom') }),
        function checkIsValidFromValidation(suspendedTo) {
          return !suspendedTo || !this.parent.suspendedFrom || !moment(suspendedTo).isBefore(this.parent.suspendedFrom);
        }
      )
      .typeError(t('validation:default'))
  });

  return { addOrUpdateLockValidationSchema, suspendFormOperationValidationSchema };
}

export default useFormOperationValidations;
