export interface ISuspensionDetailsParams {
  id?: string;
  folderId?: string;
  [key: string]: string;
}

export interface SuspensionListItemClient {
  id?: string;
  whole?: boolean;
  number?: string;
  caseNumber?: string;
  statusKey?: string;
  dateFrom?: string;
  dateTo?: string;
  dateForm?: string;
  applicationDate?: string;
  author?: { id?: string; name?: string };
  modifier?: { id?: string; name?: string };
  created?: string;
  modified?: string;
  months?: number;
}

export enum SuspensionActionEnum {
  SAVE = 'SAVE',
  CLOSE = 'CLOSE',
  CANCEL = 'CANCEL',
  DELETE = 'DELETE'
}

export enum SuspensionDetailsTabEnum {
  SUSPENSION_DETAILS = 'SUSPENSION_DETAILS',
  FORM_OPERATIONS = 'FORM_OPERATIONS',
  SUSPENSION_DOCUMENTS = 'SUSPENSION_DOCUMENTS',
  SUSPENSION_NOTE_TAB = 'SUSPENSION_NOTE_TAB',
  SUSPENSION_RELEASE_DOCUMENTS = 'SUSPENSION_RELEASE_DOCUMENTS',
  SUSPENSION_FILES = 'SUSPENSION_FILES'
}

export enum RegisteredSuspensionFormEnum {
  SUSPENSION_FORM = 'SUSPENSION_FORM'
}
export const FAILED_NUMBER_FROM_EZD_CODE = 'FAILED_TO_RETRIEVE_CASE_NUMBER_FROM_EZD';
