import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReportDetailsSnapshot } from '@stack/report/dist/models';
import { AxiosPromise } from 'axios';

import { DropdownButton, DropdownListButton, PageHeader, useDevMode, useRouter } from '@libs/common/v2';
import { unescapeValue } from '@libs/common/v2/utils';

import { useFileDownload } from '@libs/file';
import { HasPermission } from '@libs/permission';
import { API } from '@libs/report/api';
import { ReportsTabEnum, useReportFiles } from '@libs/report/hooks';
import useGenerateReport from '@libs/report/hooks/useGenerateReport';

import { ReportUIElementEnum } from '../../config/permission';

import ReportDeleteButton from './ReportDeleteButton';
import ReportDownloadButton from './ReportDownloadButton';

function ReportDetailsPageHeader({ report }: { report: ReportDetailsSnapshot }) {
  const { download, isLoading: isDownloading } = useFileDownload();
  const [t] = useTranslation();
  const { isDevMode } = useDevMode();
  const reportFiles = useReportFiles(report);
  const { goToPage, routes } = useRouter();
  const isDownloadEnabled = Boolean(Object.values(reportFiles.files)?.length) || isDevMode;

  const [generateReport] = useGenerateReport(report?.reportTypeName, null, {
    parameters: report?.commonDynamicParametersValues,
    targetTypes: report?.targetTypes
  });

  const handleDownloadReportDataFiles = async () => {
    await download(() => API.report.getReportDataFiles(report.id, { responseType: 'blob' }) as AxiosPromise);
  };

  const backToDefinition = () => goToPage(routes.reportEdit(report?.reportTypeId));

  return (
    <PageHeader
      title={t('reports:details.title')}
      breadcrumbs={[
        { to: '/reports', text: t('reports:list.title') },
        { text: t('reports:details.breadcrumbTitle', unescapeValue({ sid: report?.sid ?? '' })) }
      ]}
      rightSideContent={
        <div className="flex flex-wrapped-padding-16">
          <div className="flex items-center">
            {isDevMode && (
              <ReportDeleteButton
                report={report}
                onDeleted={() =>
                  goToPage({
                    pathname: routes.reportsList(),
                    search: `?tab=${ReportsTabEnum.REPORTS}`
                  })
                }
              />
            )}
            {isDownloadEnabled && (
              <HasPermission actionKeys={[ReportUIElementEnum.REPORT_DOWNLOAD_BUTTON]}>
                <ReportDownloadButton
                  handleDownloadReportDataFiles={handleDownloadReportDataFiles}
                  report={report}
                  isLoading={isDownloading}
                />
              </HasPermission>
            )}
            {isDevMode && (
              <DropdownButton>
                {() => {
                  return (
                    <>
                      <DropdownListButton label={t('reports:action.editReportType')} onClick={backToDefinition} />
                      <DropdownListButton
                        label={t('reports:action.generateAgain')}
                        onClick={generateReport}
                        actionKey={ReportUIElementEnum.REPORT_GENERATE_AGAIN_BUTTON}
                      />
                    </>
                  );
                }}
              </DropdownButton>
            )}
          </div>
        </div>
      }
    />
  );
}

export default React.memo(ReportDetailsPageHeader);
