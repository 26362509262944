import { FieldPath, FieldValues } from 'react-hook-form';

export interface ChangeState {
  dependentField: FieldPath<FieldValues>;
  state: ChangeStateMode;
  attr?: string;
  acceptedValues: Array<string>;
  additionalData?: any;
}

export enum ChangeStateMode {
  VISIBLE = 'VISIBLE',
  REQUIRED = 'REQUIRED',
  DISABLED = 'DISABLED',
  CHANGE_LABEL = 'CHANGE_LABEL'
}
