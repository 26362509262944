import { useEffect, useMemo, useState } from 'react';
import { useAppConfig } from '@libs/app-config';

import DevModeContext from './DevModeContext';

interface IProps {
  children: JSX.Element;
}

function DevModeProvider({ children }: IProps) {
  const { defaultDevMode, isDevModeEnabled } = useAppConfig();

  const [isDevMode, setDevMode] = useState(defaultDevMode);

  useEffect(() => {
    if (isDevModeEnabled) {
      setDevMode(true);
    } else if (isDevMode !== defaultDevMode) {
      setDevMode(defaultDevMode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDevModeEnabled]);
  const devModeContextValue = useMemo(() => ({ isDevMode, setDevMode }), [isDevMode, setDevMode]);
  return <DevModeContext.Provider value={devModeContextValue}>{children}</DevModeContext.Provider>;
}

export default DevModeProvider;
