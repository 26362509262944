import { useApplicationNotesQuery } from '@libs/domain/application';
import { useFolderNotesQuery } from '@libs/domain/folder';

function useNoteSectionData(applicationId: string, folderId: string) {
  const appData = useApplicationNotesQuery(applicationId, { enabled: Boolean(applicationId) });
  const folderData = useFolderNotesQuery(folderId, { enabled: Boolean(folderId) });

  return { data: folderId ? folderData : appData };
}

export default useNoteSectionData;
