import { QueryConfig, useQuery } from 'react-query';
import { EuroClassDetails } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';
import { FolderQueryKeysEnum } from '../QueryKeysEnum';

const getEuroClasses = async () => {
  const { data } = await API.euroClasses.getEuroClassDetails({
    ...endpointsConfig.getEuroClassDetails
  });
  return data.content;
};

const getEuroClassesClient = async () => {
  const { data } = await API.client.euroClasses.getEuroClassDetails({
    ...endpointsConfig.getEuroClassDetails
  });
  return data.content;
};

export default function useEuroClassesQuery(queryConfig: QueryConfig<EuroClassDetails[], unknown> = {}) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery<EuroClassDetails[]>(
    [FolderQueryKeysEnum.EURO_CLASSES],
    getQuery(getEuroClassesClient, getEuroClasses),
    queryConfig
  );
}
