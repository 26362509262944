import { forwardRef, ReactElement, Ref, useImperativeHandle, useRef } from 'react';
import { FieldValues, UnpackNestedValue } from 'react-hook-form';

import { SelectOption } from '@libs/common/v2';

import { FormDirtyContextProvider, MetaFormProvider } from './context';
import { ApiRegistry, Component, Field, Layout, Properties, UiMode, ValidationRegistry } from './models';
import renderNodes from './Renderer';

export interface IPageComponentProps {
  header: React.ReactNode;
  rightSidebarHeader: React.ReactNode;
  rightSidebarContent: React.ReactNode;
  pageWrapperClassName?: string;
  rightSidebarHeaderClassName?: string;
  isHeaderInContent?: boolean;
  isHeaderHidden?: boolean;
}
interface MetaFormProps {
  /**
   * tablica definicji pól pobierana z uiDefinition.fields
   */
  fields: Array<Field>;
  /**
   * definicja kontenerów layout pobierana z uiDefinition.container
   */
  components: Array<Component>;
  /**
   * definicja layoutu pobierana z uiDefinition.layout
   */
  layout: Layout;
  /**
   * właściwości formularza pobierane z uiDefinition.properties
   */
  properties: Properties;
  /**
   * tablica kluczy endpointów API i odpowiadającym im funkcjom wywoływania API
   */
  apiRegistry?: ApiRegistry;
  /**
   * tablica kluczy dodatkowych walidatorów dla Field'ów
   */
  additionalValidators?: {
    [key: string]: (values: Array<any>) => boolean;
  };
  /**
   * tablica kluczy dodatkowych Field-ów i odpowiadającym im komponentom
   */
  additionalFields?: {
    [key: string]: (properties: any) => ReactElement<any, any>;
  };
  /**
   * tablica kluczy dodatkowych filtrów opcji w DictionarySelect
   */
  additionalSelectOptionsFilters?: {
    [key: string]: (option: SelectOption) => boolean;
  };
  validationSchemeRegistry?: ValidationRegistry;
  /**
   * funkcja nadpisująca zdarzenie zapisania danych formularza
   */
  onSubmit?: (formValues: UnpackNestedValue<FieldValues>) => Promise<void>;
  /**
   * zdarzenie wykonywane po zakończeniu zapisywania formularza

   */
  onSubmitFinish?: (response?: any) => void;
  /**
   * zdarzenie wykonywane po otrzymaniu błędów zapisywania danych formularza
   */
  onSubmitError?: (error: any) => void;
  /**
   * Propsy użyte w komponencie TabNavigation, do wyrenderowania komponentu Page
   */
  pageComponentProps?: IPageComponentProps;
  /**
   *
   */

  /**
   * Zbiór funkcji z których możemy skorzystać podając nazwę klucza w properties visibility node'ów
   * Przekazywany obiekt musi być zmemoizowany
   */
  elementVisibility?: {
    /**
     * @param  popertiesParams paramerty które możemy przekazać do funkcji z definicji formularza w properties "visibility: {params: PARAMETERS}"
     * @param componentParams parametry które możemy przekazać do funkcji z poziomu komponentu meta-forms
     * przykład w TabNavigation.tsx
     */
    [key: string]: (popertiesParams?, componentParams?) => boolean;
  };
  /**
   * Zbiór funkcji asynchronicznych ktróre wymagają np. wysłanie requestu do API z których możemy skorzystać podając nazwę klucza w properties visibility node'ów
   * Przekazywany obiekt musi być zmemoizowany
   */
  elementVisibilityAsync?: {
    /** parametry anaolgicznie jak w elementVisibility */
    [key: string]: (popertiesParams?, componentParams?) => Promise<boolean>;
  };
}

function MetaForm(
  {
    fields,
    layout,
    components,
    properties,
    apiRegistry,
    additionalFields,
    additionalValidators,
    additionalSelectOptionsFilters,
    validationSchemeRegistry,
    pageComponentProps,
    elementVisibility,
    elementVisibilityAsync,
    onSubmit,
    onSubmitFinish,
    onSubmitError
  }: MetaFormProps,
  ref: Ref<unknown>
) {
  const formRef = useRef<{ onSubmit: () => Promise<any>; onReset: () => void }>(null);
  useImperativeHandle(ref, () => formRef.current);
  const fieldsValidationTypeMap = useRef({});
  const additionalDiscardFunctions = useRef({});

  return (
    <MetaFormProvider
      values={{
        elementVisibility,
        elementVisibilityAsync,
        fields,
        additionalFields,
        additionalValidators,
        mode: properties?.mode ?? UiMode.VIEW,
        apiRegistry,
        validationSchemeRegistry,
        onSubmit,
        onSubmitFinish,
        onSubmitError,
        formRef,
        additionalSelectOptionsFilters,
        pageComponentProps,
        fieldsValidationTypeMap,
        additionalDiscardFunctions
      }}
    >
      <FormDirtyContextProvider>
        <div className="flex h-full w-full overflow-hidden"> {renderNodes(layout, components)}</div>
      </FormDirtyContextProvider>
    </MetaFormProvider>
  );
}

export default forwardRef(MetaForm);
