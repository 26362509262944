/* eslint-disable react/function-component-definition */
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import i18n from '@libs/common/i18n';
import {
  AutocompleteSelectField,
  CheckboxField,
  DatepickerField,
  DictionarySelectField,
  NumberInputField,
  QuickChangeableDictionarySelectField,
  RadioField,
  SwitchField,
  TextInputField
} from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';

import { DictionaryEntryNameEnum, useDictionaryTranslations } from '@libs/dictionary';

import { IMatrixCell, InputMatrixFieldsProps, MatrixCellInputTypeEnum, TMatrixRow } from './model';

interface IProps {
  cell: IMatrixCell;
  isHeaderCell?: boolean;
  className?: string;
  dictionary?: DictionaryEntryNameEnum;
  isLastCell?: boolean;
  formPrefix?: string;
  row?: TMatrixRow;
  isWhiteBackground?: boolean;
}

const getInputByEnum = (type: MatrixCellInputTypeEnum, inputProps?: InputMatrixFieldsProps) => {
  switch (type) {
    case MatrixCellInputTypeEnum.NUMBER:
      return props => <NumberInputField {...props} {...inputProps} />;
    case MatrixCellInputTypeEnum.DATEPICKER:
      return props => <DatepickerField {...props} {...inputProps} />;
    case MatrixCellInputTypeEnum.AUTOCOMPLETE_SELECT:
      return props => <AutocompleteSelectField {...props} {...inputProps} />;
    case MatrixCellInputTypeEnum.CHECKBOX:
      return props => <CheckboxField {...props} {...inputProps} />;
    case MatrixCellInputTypeEnum.DICTIONARY_SELECT:
      return props => <DictionarySelectField {...props} {...inputProps} />;
    case MatrixCellInputTypeEnum.QUICK_CHANGEABLE_DICTIONARY_SELECT:
      return props => <QuickChangeableDictionarySelectField {...props} {...inputProps} />;
    case MatrixCellInputTypeEnum.SWITCH:
      return props => <SwitchField {...props} {...inputProps} />;
    case MatrixCellInputTypeEnum.RADIO:
      return props => <RadioField {...props} {...inputProps} />;
    case MatrixCellInputTypeEnum.TEXT:
    default:
      return props => <TextInputField {...props} {...inputProps} />;
  }
};

function MatrixCell({
  cell: { name, width, input, type, fieldName, minWidth, isRequired, inputProps, ...restProps },
  isLastCell,
  className,
  formPrefix,
  isWhiteBackground,
  row,
  dictionary,
  isHeaderCell
}: IProps) {
  const { translate } = useDictionaryTranslations();
  const classes = useStyles({
    type,
    isLastCell,
    isWhiteBackground
  });

  // Customowy cell
  if (type === MatrixCellInputTypeEnum.CUSTOM) {
    return (
      <div
        className={clsx(
          'cell',
          classes.rootCell,
          { [classes.inputCell]: !!type, [classes.header]: isHeaderCell },
          className
        )}
        style={{ width, minWidth: minWidth || width }}
        key={name?.title ?? name?.id}
      >
        {input}
      </div>
    );
  }
  // Ustawienie nazwy field
  const getInputName = () => {
    const formattedName = fieldName || (name ? `${row?.id ?? row?.title}.${name}` : row?.id ?? row?.title);
    if (formPrefix) {
      return `${formPrefix}.${formattedName}`;
    }
    return formattedName;
  };
  // Ustalenie czy komórka jest field ( text,datepicker itd ), czy tekstowa ( tytuł wiersza, tytuł kolumny)
  const Input = type ? getInputByEnum(type, inputProps) : null;
  const mainProps = { name: getInputName(), ...restProps };
  const props =
    type === MatrixCellInputTypeEnum.DATEPICKER
      ? { ...mainProps, textInputClassName: classes.input }
      : { ...mainProps, className: classes.input };

  const getInputContent = () => {
    if (Input) return Input?.(props);
    return (
      <>
        {dictionary ? translate(dictionary, name?.title, name?.title ?? i18n.t('emptyMark')) : name?.title}
        {isRequired && <span className={classes.requiredAsterisk}>*</span>}
      </>
    );
  };

  return (
    <div
      className={clsx(
        'cell',
        classes.rootCell,
        { [classes.inputCell]: !!type, [classes.header]: isHeaderCell },
        className
      )}
      style={{ width, minWidth: minWidth || width }}
      key={name?.title ?? name?.id}
    >
      {getInputContent()}
    </div>
  );
}

const useStyles = makeStyles<
  Theme,
  {
    type: MatrixCellInputTypeEnum;
    isLastCell: boolean;
    isWhiteBackground?: boolean;
  }
>(theme => ({
  rootCell: {
    width: 200,
    color: theme.palette.grey[700],
    boxSizing: 'border-box',
    borderRight: ({ isLastCell, isWhiteBackground }) =>
      !isLastCell && `1px solid ${isWhiteBackground ? theme.palette.grey[200] : theme.palette.white}`,
    padding: 10,
    borderBottom: ({ isWhiteBackground }) =>
      `1px solid ${isWhiteBackground ? theme.palette.grey[200] : theme.palette.white}`,
    display: 'flex',
    alignItems: 'center',
    height: 'auto',
    minHeight: '64px',
    flexGrow: 1
  },
  header: {
    justifyContent: 'center',
    textAlign: 'center'
  },
  inputCell: {
    backgroundColor: theme.palette.white,
    borderBottom: ({ type }) => type && `1px solid ${theme.palette.grey[200]}`,
    borderRight: ({ type, isLastCell }) => type && !isLastCell && `1px solid ${theme.palette.grey[200]}`,
    display: 'flex',
    justifyContent: 'stretch',
    '& .MuiAutocomplete-root': {
      width: '100%'
    }
  },
  input: {
    '& .MuiOutlinedInput-root.MuiInputBase-root': {
      marginTop: 0
    }
  },
  requiredAsterisk: {
    color: theme.palette.error[500],
    marginLeft: theme.marginBase
  }
}));

export default MatrixCell;
