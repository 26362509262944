import { ProceedingFormSuspendRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface SuspendProceedingFormsParams {
  proceedingId: string;
  request: ProceedingFormSuspendRequest;
}

function suspendFormOperations({ proceedingId, request }: SuspendProceedingFormsParams) {
  return API.proceeding.suspendProceedingForms(proceedingId, request, { ...endpointsConfig.suspendProceeding });
}

export function useSuspendFormOperationsMutation() {
  return useMutation(suspendFormOperations);
}
