import { QueryConfig, useQuery } from 'react-query';
import { NoteDetails } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import { ApplicationQueryKeysEnum } from '.';

const getApplicationNote = async (applicationId: string) => {
  const { data } = await API.applicationNote.getApplicationNote(applicationId, {
    ...endpointsConfig.getApplicationNote
  });

  return data;
};

export default function useApplicationNotesQuery(
  applicationId: string,
  queryConfig: QueryConfig<NoteDetails, unknown> = {}
) {
  return useQuery(
    [ApplicationQueryKeysEnum.APPLICATION_NOTES, applicationId],
    () => getApplicationNote(applicationId),
    queryConfig
  );
}
