import { QueryConfig, useQuery } from 'react-query';
import { DictionarySnapshotList } from '@avispon/dictionary';
import { DefaultApiGetAllDictionariesRequest } from '@avispon/dictionary/api/default-api';
import { AxiosRequestConfig } from 'axios';

import { API, DictionaryQueryKeysEnum, endpointsConfig } from '@libs/dictionary/api';

async function getAllDictionaries(_, params: DefaultApiGetAllDictionariesRequest) {
  const { data } = await API.dictionary.getAllDictionaries(params, {
    ...endpointsConfig.getAllDictionaries
  } as AxiosRequestConfig);
  return data;
}

const useDictionariesAllQuery = (
  params: DefaultApiGetAllDictionariesRequest,
  queryConfig?: QueryConfig<DictionarySnapshotList, unknown>
) => {
  return useQuery([DictionaryQueryKeysEnum.DICTIONARY_LIST, params], getAllDictionaries, queryConfig);
};
export default useDictionariesAllQuery;
