import React from 'react';

interface DriverContextState {
  application?: {
    id?: string;
    folderId?: string;
    subjectNip?: string;
    applicationTypeKey?: string;
  };
}

const DriverContext = React.createContext<DriverContextState>({
  application: {
    id: null,
    folderId: null,
    subjectNip: null,
    applicationTypeKey: null
  }
});

export default DriverContext;
