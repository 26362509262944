import {
  AdditionalSectionLicenseOrPermissionType,
  ApplicationMetaFormDefinition
} from '@libs/domain/application/model';

const definition: ApplicationMetaFormDefinition = {
  fields: [
    {
      typeKey: 'NUMBER',
      fieldId: 'application-interbus-is-travel-form-book',
      properties: {
        label: 'Książka formularzy podróży',
        defaultInitialValue: 0,
        min: 0,
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION_TRAVEL',
            accessor: 'travelFormUpdateRequest.travelFormsBook'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'travelFormApplicationDetails.travelFormsBook'
          }
        }
      }
    },
    {
      typeKey: 'NUMBER',
      fieldId: 'application-interbus-is-interbus',
      properties: {
        label: 'Interbus',
        defaultInitialValue: 0,
        min: 0,
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION_TRAVEL',
            accessor: 'travelFormUpdateRequest.interbus'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'travelFormApplicationDetails.interbus'
          }
        }
      }
    },
    {
      typeKey: 'TRAVEL_FORM_COLLECTION',
      fieldId: 'application-travel-form-collection'
    }
  ],
  components: [
    {
      componentId: 'application-interbus-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'INTERBUS'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-warnings'
            }
          },

          {
            typeKey: 'SECTION',
            properties: {
              title: 'Wniosek'
            },
            nodes: [
              {
                typeKey: 'GRID',
                properties: {
                  gridItemProps: {
                    sm: 6,
                    xs: 12
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-interbus-is-travel-form-book'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-interbus-is-interbus'
                    }
                  }
                ]
              }
            ]
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-travel-form-collection'
            }
          }
        ]
      }
    }
  ],
  layout: [
    {
      typeKey: 'TAB_NAVIGATION',
      properties: {
        visibility: {
          elementVisibilityKeys: ['TAB_VISIBILITY']
        },
        defaultTabId: 'INTERBUS',
        title: 'Sekcje',
        tabs: [
          {
            id: 'INTERBUS',
            title: 'Interbus/Formularze Podróży',
            icon: 'BusIcon'
          },
          {
            id: 'SUBJECT',
            title: 'Podmiot',
            icon: 'FacadeIcon'
          },
          {
            id: 'ADDRESSES',
            title: 'Adresy',
            icon: 'MailboxIcon'
          },
          {
            id: 'QUOTATION',
            title: 'Wycena',
            icon: 'CoinsIcon'
          },
          {
            id: 'OUTGOING_DOCUMENTS',
            title: 'Dokumenty wychodzące',
            icon: 'DocumentsIssuedIcon'
          },
          {
            id: 'NOTE',
            title: 'Notatka',
            icon: 'NoteIcon'
          },
          {
            id: 'FILES',
            title: 'Pliki',
            icon: 'FileEmptyIcon'
          },
          {
            id: 'HISTORY',
            title: 'Historia',
            icon: 'HistoryIcon'
          }
        ]
      },
      nodes: [
        {
          typeKey: 'TABS_CONTENT',
          nodes: [
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-interbus-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-subject-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-addresses-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-quotation-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-outgoing-documents-tab',
                additionalNodes: {
                  'outgoing-documents-additional-section': [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'application-outgoing-documents-additional-section-license-or-permission',
                        sectionTypes: [
                          AdditionalSectionLicenseOrPermissionType.PERMISSION,
                          AdditionalSectionLicenseOrPermissionType.LICENSE,
                          AdditionalSectionLicenseOrPermissionType.DRIVER_CERTIFICATE
                        ]
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-note-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-files-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-permissions-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-history-tab'
              }
            }
          ]
        }
      ]
    }
  ]
};

export default definition;
