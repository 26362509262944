import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { IconButton, SelectWithFetchedOptionsField, useFormV2Context, useFormV2DirtyWatch } from '@libs/common/v2';
import { useFormV2Watch, useViewModesV2 } from '@libs/common/v2/form';

import { useDocumentTemplateDetailsQuery } from '@libs/document-template';
import { useDownloadDocumentFile } from '@libs/file';
import { API } from '@libs/report/api';

import { DocumentTemplateSnapshot } from '../../../model';

import { UploadTemplateButton } from './UploadTemplateButton';

function DocumentTemplateField() {
  const { formMode, watch } = useFormV2Context();
  const { isDirty } = useFormV2DirtyWatch();
  const { editMode } = useViewModesV2(formMode);
  const [t] = useTranslation();

  const documentTemplateId = watch('documentTemplateId') as string;
  const { data: documentTemplateDetails } = useDocumentTemplateDetailsQuery(documentTemplateId, {
    enabled: editMode && documentTemplateId
  });

  const { downloadFile, isFetching } = useDownloadDocumentFile({
    successMessage: t('reports:message.templateDownloadSuccess')
  });

  const classes = useStyles();

  const getDocumentTemplates = useCallback((inputText: string) => {
    return API.documentGenerator.findTemplates({ nameFragment: inputText });
  }, []);

  const documentTemplate = useFormV2Watch({ name: 'documentTemplate' }) as DocumentTemplateSnapshot;

  const isUploadButtonDisabled = useMemo(
    () => (editMode ? !documentTemplateId || isDirty : isDirty),
    [documentTemplateId, isDirty, editMode]
  );

  const handleDownloadHandle = (id: string) => {
    downloadFile({
      id
    });
  };

  return (
    <div className={classes.container}>
      <SelectWithFetchedOptionsField
        className={classes.select}
        fetchFunction={getDocumentTemplates}
        getOptionLabel={documentTemplate => documentTemplate.name}
        label={t('documentTemplates:template')}
        name="documentTemplate"
        isClearable
      />
      <UploadTemplateButton documentTemplate={documentTemplateDetails} isDisabled={!!isUploadButtonDisabled} />
      {documentTemplate?.value?.latestTemplateFileId && (
        <IconButton
          isBackgroundTransparent
          icon="DownloadIcon"
          height={20}
          width={20}
          onClick={() => handleDownloadHandle(documentTemplate?.value?.latestTemplateFileId)}
          tooltipTitle={t('reports:reportTypes.action.downloadTemplate')}
          isLoading={isFetching}
          wrapperClassName={classes.downloadButton}
        />
      )}
    </div>
  );
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: '1.6rem',
    '& > label': {
      marginLeft: 0
    }
  },
  select: {
    flex: '1 1 0%'
  },
  downloadButton: {
    alignSelf: 'end'
  }
});

export default DocumentTemplateField;
