import { useTranslation } from 'react-i18next';

import {
  GridLayout,
  Section,
  SwitchField,
  TextInputField,
  typedNameV2,
  useFormV2Context,
  ValidatorEnums
} from '@libs/common/v2';

import { SubjectUpdateRequestUnion } from '@libs/domain/application/model';

import { parsePostCode } from '../../../address';

interface IProps {
  groupName: 'permission' | 'license';
  title: string;
  isNipFieldVisible?: boolean;
}

const SubjectDataChangeForm = ({ groupName, title, isNipFieldVisible }: IProps) => {
  const [t] = useTranslation();

  const { watch } = useFormV2Context();

  const isPermission = groupName === 'permission';
  const watchIsSameAsMainAddress = watch(
    isPermission
      ? typedNameV2<SubjectUpdateRequestUnion>('permissionData.isSameAsMainAddress')
      : typedNameV2<SubjectUpdateRequestUnion>('licenseData.isSameAsMainAddress')
  ) as boolean;

  return (
    <Section title={title} isModalSection>
      <GridLayout
        itemProps={{ xs: 12, sm: 12 }}
        containerProps={{
          marginBottom: 3
        }}
      >
        <GridLayout itemProps={{ xs: 12, sm: 12 }}>
          <TextInputField
            name={
              isPermission
                ? typedNameV2<SubjectUpdateRequestUnion>('permissionData.name')
                : typedNameV2<SubjectUpdateRequestUnion>('licenseData.name')
            }
            label={t('prints:field.detailsFields.subjectName')}
            isRequired={!watchIsSameAsMainAddress}
          />
        </GridLayout>
        <GridLayout itemProps={{ xs: 12, sm: 6 }}>
          <SwitchField
            name={
              isPermission
                ? typedNameV2<SubjectUpdateRequestUnion>('permissionData.isSameAsMainAddress')
                : typedNameV2<SubjectUpdateRequestUnion>('licenseData.isSameAsMainAddress')
            }
            label={t('address:fields.sameAsMainAddressPartnership')}
            isRequired
          />
          {isNipFieldVisible && (
            <TextInputField
              name={
                isPermission
                  ? typedNameV2<SubjectUpdateRequestUnion>('permissionData.nip')
                  : typedNameV2<SubjectUpdateRequestUnion>('licenseData.nip')
              }
              label={t('permission:field.subjectNip')}
            />
          )}
        </GridLayout>

        {!watchIsSameAsMainAddress && (
          <GridLayout itemProps={{ xs: 12, sm: 6 }} containerProps={{ className: 'mt-4' }}>
            <TextInputField
              name={
                isPermission
                  ? typedNameV2<SubjectUpdateRequestUnion>('permissionData.address.city')
                  : typedNameV2<SubjectUpdateRequestUnion>('licenseData.address.city')
              }
              label={t('prints:field.detailsFields.city')}
              isRequired
            />

            <TextInputField
              name={
                isPermission
                  ? typedNameV2<SubjectUpdateRequestUnion>('permissionData.address.street')
                  : typedNameV2<SubjectUpdateRequestUnion>('licenseData.address.street')
              }
              label={t('prints:field.detailsFields.street')}
              isRequired
            />

            <TextInputField
              name={
                isPermission
                  ? typedNameV2<SubjectUpdateRequestUnion>('permissionData.address.postCode')
                  : typedNameV2<SubjectUpdateRequestUnion>('licenseData.address.postCode')
              }
              label={t('prints:field.detailsFields.postCode')}
              validation={{
                contextValidator: [ValidatorEnums.ADDRESS_POSTAL_CODE]
              }}
              getValueFormat={postCode => {
                return parsePostCode(postCode);
              }}
              inputMask={{ mask: '99-999', alwaysShowMask: true }}
              isRequired
            />

            <TextInputField
              name={
                isPermission
                  ? typedNameV2<SubjectUpdateRequestUnion>('permissionData.address.propertyNumber')
                  : typedNameV2<SubjectUpdateRequestUnion>('licenseData.address.propertyNumber')
              }
              label={t('prints:field.detailsFields.buildingNumber')}
              inputProps={{ maxLength: 10 }}
              isRequired
            />

            <TextInputField
              name={
                isPermission
                  ? typedNameV2<SubjectUpdateRequestUnion>('permissionData.address.postCity')
                  : typedNameV2<SubjectUpdateRequestUnion>('licenseData.address.postCity')
              }
              label={t('prints:field.detailsFields.post')}
              isRequired
            />

            <TextInputField
              name={
                isPermission
                  ? typedNameV2<SubjectUpdateRequestUnion>('permissionData.address.apartmentNumber')
                  : typedNameV2<SubjectUpdateRequestUnion>('licenseData.address.apartmentNumber')
              }
              label={t('prints:field.detailsFields.apartmentNumber')}
            />
          </GridLayout>
        )}
      </GridLayout>
    </Section>
  );
};

export default SubjectDataChangeForm;
