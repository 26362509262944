import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { AssociationSubjectSnapshot } from '@ibtm/domain';
import { object } from 'yup';

import { AutocompleteLazyFetchSelectField, Dialog, FormV2Context, GridLayout, useTableContext } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';
import { DomainDictionaryEntry } from '@libs/domain/config';
import { AssociationQueryKeysEnum, useAddAssociationSubjectMutation } from '@libs/domain/entrepreneurs';
import { FolderQueryKeysEnum, useFolderDetailsQuery } from '@libs/domain/folder';

import { AssociationDetailsParams, AssociationFormEnum, IAddAssociationSubject } from '../../model';

interface Props {
  open: boolean;
  setIsOpen: (param: boolean) => void;
}

function AddEntrepreneurDialog({ open, setIsOpen }: Props) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { id } = useParams<AssociationDetailsParams>();

  const { refetch } = useTableContext();
  const { data: folderData } = useFolderDetailsQuery(id, { enabled: Boolean(id) });
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbar();
  const { mutate: addAssociationSubject, isLoading } = useAddAssociationSubjectMutation();

  const { control, setValue, getValues, reset, handleSubmit } = useForm<Partial<IAddAssociationSubject>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(
      object({
        entrepreneurSelect: object().required()
      })
    )
  });

  const handleAdd = (formData: Partial<IAddAssociationSubject>) => {
    addAssociationSubject(
      {
        subjectId: formData?.entrepreneurSelect?.value?.subject?.id,
        associationId: id,
        version: folderData?.version
      },
      {
        onSuccess: () => {
          reset();
          refetch();
          queryCache.invalidateQueries(AssociationQueryKeysEnum.GET_ASSOCIATION_SUBJECT);
          setIsOpen(false);
          showSuccessSnackbar(t('associations:messages.entrepreneurAddSuccess'));
        },
        onError: () => {
          showErrorSnackbar(t('associations:messages.entrepreneurAddFailure'));
        }
      }
    );
  };

  const getOptionLabel = (option: AssociationSubjectSnapshot) => {
    return `${t('associations:field.name')}: ${option?.subject?.name}, ${t('associations:field.nip')}: ${
      option?.subject?.nip
    }`;
  };

  const values = useMemo(() => {
    return { control, setValue, getValues, handleSubmit };
  }, [control, getValues, setValue, handleSubmit]);

  return (
    <FormV2Context.Provider value={values}>
      <Dialog
        title={t('associations:addEntrepreneurModalTitle')}
        confirmText={t('action.add')}
        cancelText={t('action.cancel')}
        onConfirm={folderData && handleSubmit(handleAdd)}
        onCancel={() => setIsOpen(false)}
        dialogSize="small"
        isConfirmLoading={isLoading}
        isOpen={open}
      >
        <form onSubmit={handleSubmit(handleAdd)}>
          <GridLayout itemProps={{ xs: 12 }}>
            <AutocompleteLazyFetchSelectField
              label={t('associations:label.entrepreneurSelect')}
              api={{
                FETCH: (searchText: string, params: { page: number; size: number }) =>
                  API.folder.getFoldersLitePage({
                    subjectNipContains: searchText,
                    excludeAssociationMembers: true,
                    typeKeyNotIn: [DomainDictionaryEntry.FOLDER_TYPE.ZZ],
                    statusKeyNotIn: [DomainDictionaryEntry.FOLDER_STATUS.ARCHIVED],
                    ...params
                  })
              }}
              queryKey={FolderQueryKeysEnum.FOLDERS_LIST_FILTER}
              optionLabelParser={getOptionLabel}
              name={AssociationFormEnum.ENTREPRENEUR_SELECT}
              isRequired
            />
          </GridLayout>
        </form>
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default AddEntrepreneurDialog;
