import { MutationConfig } from 'react-query';
import { DocumentIdObject, DocumentIssuingConfirmationRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { DocumentIssuedClient } from '../../model';
import { documentsIssuedTableParser } from '../../utils';
import { endpointsConfig } from '../endpoints-config';

type DocumentIssuingConfirmationParams = Omit<DocumentIssuingConfirmationRequest, 'documents'> & {
  documents: Array<DocumentIdObject>;
};
async function updateDocumentIssuingConfirmation(formData: DocumentIssuingConfirmationParams) {
  const { data } = await API.documents.updateDocumentIssuingConfirmation(formData, {
    ...endpointsConfig.updateDocumentIssuingConfirmation
  });
  return documentsIssuedTableParser(data.documents);
}

function useUpdateDocumentIssuingConfirmation(config?: MutationConfig<DocumentIssuedClient[]>) {
  return useMutation(updateDocumentIssuingConfirmation, config);
}

export default useUpdateDocumentIssuingConfirmation;
