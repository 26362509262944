import { ProceedingFormCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const createFormOperations = (params: { parentId: string; request: ProceedingFormCreateRequest }) => {
  const { parentId, request } = params;
  return API.proceeding.addProceedingForm(parentId, request, { ...endpointsConfig.addProceeding });
};

export const useCreateProceedingFormOperationsMutation = () => {
  return useMutation(createFormOperations);
};
