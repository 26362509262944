import { usePaginatedQuery } from 'react-query';
import { ReputationResponseDetails } from '@ibtm/domain';
import { v4 as uuidv4 } from 'uuid';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';
import { FolderQueryKeysEnum } from '..';

interface IProps {
  transportManagerId?: string;
  reputationRequestId?: string;
}

const getReputationResult = async (params: IProps) => {
  const { transportManagerId, reputationRequestId } = params;
  const { data } = await API.transportManager.getReputationRequestResponses(transportManagerId, reputationRequestId, {
    ...endpointsConfig.getReputationRequestResponses
  });

  const parsedData = {
    content: data.content.map(i => ({ id: uuidv4(), ...i }))
  };

  return parsedData;
};

export default function useReputationResultsQuery(params) {
  return usePaginatedQuery<IPaginatedModel<ReputationResponseDetails>>([FolderQueryKeysEnum.REPUTATION, params], () =>
    getReputationResult(params)
  );
}
