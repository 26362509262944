import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';
import { CivicBoardSnapshot } from '@libs/domain/social-commission';

import { endpointsConfig } from '../endpoints-config';

function deleteMeeting(requestData: CivicBoardSnapshot) {
  return API.civicBoard.deleteCivicBoard(requestData.civicBoardId, {
    ...endpointsConfig.deleteCivicBoard
  });
}

function useDeleteMeetingMutation() {
  return useMutation(deleteMeeting);
}

export default useDeleteMeetingMutation;
