import { ForeignPermissionSnapshot } from '@ibtm/domain';

import { ReleasedPermitsSnapshot } from '../model';

export function releasedPermitsParser(data: ForeignPermissionSnapshot[]): ReleasedPermitsSnapshot[] {
  return data.map(item => ({
    ...item,
    id: item?.id,
    folderNumber: item?.folder?.number,
    country: item?.issuingCountryCodeKey,
    status: item?.statusKey,
    formNumber: item?.form?.fullNumber,
    company: item?.subject?.name,
    billingCode: item?.codeKey
  }));
}
