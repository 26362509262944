import { ResourceObjectAssociationPoolSearchFilter } from '@ibtm/client-domain';

import { SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { TAmountRange } from '@libs/common/v2/utils';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { IResourceObjectAssociationPoolClient } from '../../model';

const useResourceObjectAssociationPoolsColumns = () => {
  const { createColumns } = useCreateColumns<IResourceObjectAssociationPoolClient>({
    pathToTranslate: 'resource:fields'
  });

  const mappedSortFields: TableSortMapper<IResourceObjectAssociationPoolClient> = {
    country: 'countryCodeKey',
    name: 'nameKey',
    state: 'stateKey',
    limit: 'remainingLimit'
  };

  const mappedFilterFields: TableFilterMapper<
    IResourceObjectAssociationPoolClient,
    ResourceObjectAssociationPoolSearchFilter
  > = {
    country: (countries: SelectOption<string>[]) => ({ countryCodeKeyIn: countries.map(country => country.value) }),
    name: (names: SelectOption<string>[]) => ({ nameKeyIn: names.map(name => name.value) }),
    state: (states: SelectOption<string>[]) => ({ stateKeyIn: states.map(state => state.value) }),
    numberFrom: (amountRange: TAmountRange) => ({
      numberFromGreaterThanOrEqual: amountRange.min,
      numberFromLessThanOrEqual: amountRange.max
    }),
    numberTo: (amountRange: TAmountRange) => ({
      numberToGreaterThanOrEqual: amountRange.min,
      numberToLessThanOrEqual: amountRange.max
    }),
    amount: (amountRange: TAmountRange) => ({
      amountToGreaterThanOrEqual: amountRange.min,
      amountToLessThanOrEqual: amountRange.max
    }),
    limit: (amountRange: TAmountRange) => ({
      remainingLimitToGreaterThanOrEqual: amountRange.min,
      remainingLimitToLessThanOrEqual: amountRange.max
    })
  };

  const columns = createColumns([
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.COUNTRY_CODE,
      accessor: 'country',
      width: 200
    },
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_NAME,
      accessor: 'name',
      width: 300
    },
    {
      header: 'status',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_STATE,
      accessor: 'state',
      width: 200
    },
    {
      type: 'NUMBER_RANGE',
      accessor: 'numberFrom',
      width: 200
    },
    {
      type: 'NUMBER_RANGE',
      accessor: 'numberTo',
      width: 200
    },
    {
      type: 'NUMBER_RANGE',
      accessor: 'amount',
      width: 200
    },
    {
      type: 'NUMBER_RANGE',
      accessor: 'limit',
      width: 200
    }
  ]);

  return { columns, mappedSortFields, mappedFilterFields };
};

export default useResourceObjectAssociationPoolsColumns;
