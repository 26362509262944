import { useTableLayoutProps } from '@libs/common/v2';

import { useFolderHistoryQuery } from '@libs/domain/folder/api';
import { useFolderHistoryColumns } from '@libs/domain/folder/hooks';

function useFolderHistoryTable(folderId: string) {
  const { columns, mappedFilterFields } = useFolderHistoryColumns();

  return useTableLayoutProps({
    tableHookQuery: useFolderHistoryQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'created', desc: true }]
        }
      }
    },
    tableHookQueryInitialParams: {
      id: folderId
    }
  });
}

export default useFolderHistoryTable;
