export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  activateDriverCertificate: { translationKey: 'driverCertificate:api.activateDriverCertificate' },
  createDriver: { translationKey: 'drivers:api.createDriver' },
  deleteDriver: { translationKey: 'drivers:api.deleteDriver' },
  updateDriver: { translationKey: 'drivers:api.updateDriver' },
  expireDriver: { translationKey: 'drivers:api.expireDriver' },
  getDriverDetails: { translationKey: 'drivers:api.getDriverDetails' },
  getDriversDetailsPage: { translationKey: 'drivers:api.getDriversDetailsPage' },
  linkDriver: { translationKey: 'applications:api.linkDriver' }
};
