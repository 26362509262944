import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TFunction } from '@libs/common';

import { ProcessMessageKeyEnum } from '@libs/domain/application';

import {
  assignApplicationToEmployeeForDecision,
  cancelProcess,
  checkIfAuthorizationScansHaveBeenSent,
  returnForCorrectionsReason,
  transferToOtherDepartment
} from '../config/ui-definition';

const getProcessFormConfigs = (translate: TFunction) => [
  {
    messageKey: ProcessMessageKeyEnum.TRANSFER_TO_OTHER_DEPARTMENT,
    title: translate('processes:dialog.transferToOtherDepartmentConfirm'),
    uiDefinition: transferToOtherDepartment,
    isApproveRequestNeeded: false
  },
  {
    messageKey: ProcessMessageKeyEnum.CANCEL_PROCESS,
    title: translate('processes:dialog.cancelApplicationProcessConfirm'),
    uiDefinition: cancelProcess,
    isApproveRequestNeeded: true
  },
  {
    messageKey: ProcessMessageKeyEnum.FOREIGN_AUTHORITIES_NOTIFICATED_WITH_PREFIX,
    title: translate('processes:dialog.scansHaveBeenSentConfirm'),
    uiDefinition: checkIfAuthorizationScansHaveBeenSent,
    isApproveRequestNeeded: false
  },
  {
    messageKey: ProcessMessageKeyEnum.FOREIGN_AUTHORITIES_NOTIFICATED,
    title: translate('processes:dialog.scansHaveBeenSentConfirm'),
    uiDefinition: checkIfAuthorizationScansHaveBeenSent,
    isApproveRequestNeeded: false
  },
  {
    messageKey: ProcessMessageKeyEnum.RETURN_FOR_CORRECTIONS,
    title: translate('processes:dialog.returnForCorrectionsConfirm'),
    uiDefinition: returnForCorrectionsReason,
    isApproveRequestNeeded: false
  },
  {
    messageKey: ProcessMessageKeyEnum.PREPARE_DECISION_DRAFT_EMPLOYEE,
    title: translate('processes:dialog.assignApplicationToEmployeeConfirm'),
    uiDefinition: assignApplicationToEmployeeForDecision,
    isApproveRequestNeeded: false
  }
];

const useProcessFormConfig = () => {
  const [t] = useTranslation();

  const config = useMemo(() => getProcessFormConfigs(t), [t]);

  const findConfig = (messageKey: string) => {
    return config.find(configElement => configElement.messageKey === messageKey);
  };

  return {
    processFormConfig: {
      find: findConfig
    }
  };
};

export default useProcessFormConfig;
