enum FieldTypeEnum {
  TEXT = 'TEXT',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  DICTIONARY = 'DICTIONARY',
  DICTIONARY_QUICK_CHANGEABLE = 'DICTIONARY_QUICK_CHANGEABLE',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  DATETIME = 'DATETIME'
}

export default FieldTypeEnum;
