import moment from 'moment';

import { getInitialYear, useTableLayoutProps } from '@libs/common/v2';

import { useCivicBoardListQuery } from '@libs/domain/social-commission';
import { CivicBoardTableEnum } from '@libs/domain/social-commission/components/common/model/social-comission.model';
import { IntialYearType } from '@libs/domain/social-commission/components/SocialCommissionPage';

import { useCivicBoardColumns } from '../columns/useCivicBoardColumns';

function useCivicBoardTable(initialYear: IntialYearType) {
  const { columns, mappedFilterFields } = useCivicBoardColumns();

  const year = initialYear || getInitialYear();
  const initialParams = {
    [CivicBoardTableEnum.YEAR_GRATER]: moment([year.value]).format('YYYY'),
    [CivicBoardTableEnum.YEAR_LESS]: moment([year.value]).format('YYYY')
  };

  return useTableLayoutProps({
    tableHookQuery: useCivicBoardListQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'month', desc: true }]
        }
      }
    },
    tableHookQueryInitialParams: initialParams
  });
}

export default useCivicBoardTable;
