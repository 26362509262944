import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import {
  MisspellApplicationDataCertificateFormValues,
  PermissionUpdateRequest
} from '../../model/misspell-application.model';
import { parseMisspellCertificateRequest } from '../../utils/application-misspell.util';
import { endpointsConfig } from '../endpoints-config';

const updateCertificate = async ({
  applicationId,
  permissionUpdateRequest
}: PermissionUpdateRequest<MisspellApplicationDataCertificateFormValues>) => {
  const { data } = await API.applicationMisspellData.updateApplicationCertificate(
    applicationId,
    parseMisspellCertificateRequest(permissionUpdateRequest),
    {
      ...endpointsConfig.updateApplications
    }
  );
  return data;
};

function useUpdateApplicationMisspellCertificateMutation() {
  return useMutation(updateCertificate);
}

export default useUpdateApplicationMisspellCertificateMutation;
