import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '@enigma/fe-ui';
import { SuspensionSnapshot } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import { DropdownButton, FormMode, typedNameV2, useConfirmDialog, useFormV2Context, useRouter } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { IButtonAction } from '@libs/common/v2/components/button/DropdownButton';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DomainDictionaryEntry, DomainUIElementEnum } from '@libs/domain/config';
import {
  ISuspensionDetailsParams,
  SuspensionActionEnum,
  SuspensionQueryKeysEnum,
  useDeleteSuspensionMutation
} from '@libs/domain/suspensions';
import {
  useSuspensionActions,
  useSuspensionCancelMutation,
  useSuspensionCloseMutation
} from '@libs/domain/suspensions/hooks';

interface IProps {
  version: number;
  formMode: FormMode;
}

function SuspensionActions({ version, formMode }: IProps) {
  const queryCache = useQueryCache();
  const { id } = useParams<ISuspensionDetailsParams>();
  const [t] = useTranslation();
  const { viewMode } = useViewModesV2(formMode);
  const { watch } = useFormV2Context();
  const { goToPage, routes, location } = useRouter();
  const getLabel = partialTranslate('suspensions:details.action');
  const getConfirmLabel = partialTranslate('suspensions:details.confirm');
  const getSuccesLabel = partialTranslate('suspensions:details.success');
  const getFailLabel = partialTranslate('suspensions:details.fail');

  const [actionState, setActionState] = useState<SuspensionActionEnum | null>(null);

  const [confirm] = useConfirmDialog();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  const refetch = useCallback(() => {
    queryCache.invalidateQueries(SuspensionQueryKeysEnum.SUSPENSION_DETAILS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryCache]);

  const goBack = useCallback(() => {
    const previousUrl = location?.state?.from;
    goToPage(previousUrl ?? routes.suspensionsList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.from]);

  const { startDeletingProcess } = useSuspensionActions(id, goBack);

  const statusKey = watch(typedNameV2<SuspensionSnapshot>('statusKey')) as string;
  const { mutate: cancelSuspension } = useSuspensionCancelMutation();
  const { mutate: closeSuspension } = useSuspensionCloseMutation();

  const { mutate: deleteSuspension } = useDeleteSuspensionMutation();

  const confirmDialog = (payload: { id: string; action: SuspensionActionEnum }) => {
    setActionState(payload.action);

    switch (payload.action) {
      case SuspensionActionEnum.DELETE:
        confirm({
          message: getConfirmLabel(payload.action as any),
          onConfirm: (setConfirmLoading, closeDialog) => {
            setConfirmLoading(true);
            deleteSuspension(payload, {
              onSuccess: () => {
                showSuccessSnackbar(getSuccesLabel(actionState as any));
                setActionState(null);
                closeDialog();
              },
              onError: () => {
                showErrorSnackbar(getFailLabel(actionState as any));
                setActionState(null);
              },
              onSettled: () => setConfirmLoading(false)
            });
          },
          confirmType: 'danger'
        });
        break;
      case SuspensionActionEnum.CLOSE:
        confirm({
          title: t('suspensions:details.message.closeModalTitle'),
          message: getConfirmLabel(payload.action as any),
          onConfirm: (setConfirmLoading, closeDialog) => {
            setConfirmLoading(true);
            closeSuspension(
              { suspensionId: id, version },
              {
                onSuccess: () => {
                  showSuccessSnackbar(t('suspensions:details.success.CLOSE'));
                  closeDialog();
                },
                onSettled: () => {
                  setConfirmLoading(false);
                  refetch();
                  setActionState(null);
                }
              }
            );
          }
        });
        break;
      case SuspensionActionEnum.CANCEL:
        confirm({
          title: t('suspensions:details.message.cancelModalTitle'),
          message: getConfirmLabel(payload.action as any),
          onConfirm: (setConfirmLoading, closeDialog) => {
            setConfirmLoading(true);
            cancelSuspension(
              { suspensionId: id, version },
              {
                onSuccess: () => {
                  showSuccessSnackbar(t('suspensions:details.success.CANCEL'));
                  closeDialog();
                },
                onSettled: () => {
                  setConfirmLoading(false);
                  refetch();
                  setActionState(null);
                  queryCache.invalidateQueries(SuspensionQueryKeysEnum.SUSPENSION_FORM_OPERATIONS);
                }
              }
            );
          }
        });
        break;
      default:
        confirm({
          message: getConfirmLabel(payload.action as any),
          onConfirm: actionQuery => {
            actionQuery(payload);
          }
        });
        break;
    }
  };

  const actionButtons: IButtonAction[] = [
    {
      label: getLabel('close'),
      onClick: () => {
        confirmDialog({ id, action: SuspensionActionEnum.CLOSE });
      },
      actionKey: DomainUIElementEnum.SUSPENSIONS_CLOSE_BUTTON,
      isHidden: statusKey !== DomainDictionaryEntry.SUSPENSION_STATUS.CONSIDERED
    },
    {
      label: getLabel('cancel'),
      onClick: () => {
        confirmDialog({ id, action: SuspensionActionEnum.CANCEL });
      },
      actionKey: DomainUIElementEnum.SUSPENSIONS_EDIT_CANCEL_BUTTON,
      isHidden: statusKey === DomainDictionaryEntry.SUSPENSION_STATUS.CANCELLED
    },
    {
      label: getLabel('delete'),
      onClick: () => {
        startDeletingProcess();
      },
      actionKey: DomainUIElementEnum.SUSPENSIONS_DELETE_BUTTON
    }
  ];

  return viewMode && <DropdownButton actionButtons={actionButtons} />;
}

export default SuspensionActions;
