import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import { Dialog, FormMode, FormV2Context } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { useViewModesV2 } from '@libs/common/v2/form';
import { getCalendarDate } from '@libs/common/v2/utils';

import {
  CivicBoardSnapshot,
  SocialCommissionQueryKeysEnum,
  useAddCividBoardMutation,
  useUpdateCivicBoardMutation
} from '@libs/domain/social-commission';
import { AddMeetingValidationSchema } from '@libs/domain/social-commission/model/civic-board.model';

import AddMeetingForm from './AddMeetingForm';

export interface IAddMeetingModalProps {
  open?: boolean;
  onCancel?: () => void;
  onClose?: () => void;
  data?: CivicBoardSnapshot;
}

function AddMeetingModal({ open, onCancel, onClose, data }: IAddMeetingModalProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { createMode } = useViewModesV2(FormMode[data.mode]);

  const getInitialFormData = formData => {
    const dataParser = {
      ...formData,
      year: {
        name: `${formData.year}`,
        value: formData.year
      },
      month: {
        name: `${formData.month}`,
        value: formData.month
      }
    };

    const createDate = moment().add(1, 'M');

    const createYear = parseFloat(createDate.format('YYYY'));
    const createMonth = parseFloat(createDate.format('MM'));

    return createMode
      ? {
          year: { value: createYear, name: `${createYear}` },
          month: { value: createMonth, name: `${createMonth}` }
        }
      : dataParser;
  };

  useEffect(() => {
    if (data) {
      form.reset(getInitialFormData(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const resetDialog = () => {
    onClose();
    form.reset();
  };

  const { mutate: addMeeting, isLoading } = useAddCividBoardMutation({
    onSuccess: () => {
      showSuccessSnackbar(t('global:success.add'));
      resetDialog();
      queryCache.invalidateQueries(SocialCommissionQueryKeysEnum.CIVIC_BOARD_DETAILS);
    }
  });

  const { mutate: updateMeeting, isLoading: isUpdateLoading } = useUpdateCivicBoardMutation({
    onSuccess: () => {
      showSuccessSnackbar(t('global:success.save'));
      resetDialog();
      queryCache.invalidateQueries(SocialCommissionQueryKeysEnum.CIVIC_BOARD_DETAILS);
    }
  });

  const form = useForm<Record<string, any>>({
    resolver: yupResolver(AddMeetingValidationSchema()),
    mode: 'onBlur',
    criteriaMode: 'all'
  });

  const formDataProvider = useMemo(() => {
    return { ...form };
  }, [form]);

  const handleSubmitForm = formData => {
    const parsedData = {
      submissionDateFrom: getCalendarDate(formData.submissionDateFrom),
      submissionDateTo: getCalendarDate(formData.submissionDateTo),
      receiptDateFrom: getCalendarDate(formData.receiptDateFrom),
      receiptDateTo: getCalendarDate(formData.receiptDateTo),
      boardDate: getCalendarDate(formData.boardDate)
    };
    if (createMode) {
      addMeeting({ ...parsedData, year: form.getValues('year').value, month: form.getValues('month').value });
    } else {
      updateMeeting({
        ...parsedData,
        civicBoardId: data.id,
        year: form.getValues('year').value,
        month: form.getValues('month').value,
        version: formData.version
      });
    }
  };

  const onCancelAction = () => {
    form.reset();
    onCancel();
  };

  const values = useMemo(() => ({ ...formDataProvider, formMode: FormMode[data.mode] }), [data.mode, formDataProvider]);

  return (
    <FormV2Context.Provider value={values}>
      <form>
        <Dialog
          title={
            createMode ? t('foreignPermits:other.addCommissionDate') : t('foreignPermits:other.updateCommissionDate')
          }
          confirmText={createMode ? t('action.add') : t('action.save')}
          cancelText={t('action.cancel')}
          onConfirm={form.handleSubmit(handleSubmitForm)}
          onCancel={onCancelAction}
          isConfirmLoading={isLoading || isUpdateLoading}
          dialogSize="large"
          isOpen={open}
        >
          <AddMeetingForm />
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
}

export default AddMeetingModal;
