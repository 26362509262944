import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import { IconType } from '@libs/common/v2';

export enum NavigationTypeEnum {
  ITEM = 'item',
  COLLAPSE = 'collapse',
  LINK = 'link'
}

export interface IAppNavigation {
  id: string;
  title: string;
  type: NavigationTypeEnum;
  icon?: IconType;
  url?: string;
  auth?: any;
  externalUrlKey?: string;
  viewName?: UIElementNameEnum;
  children?: Array<IAppNavigation>;
}
