import { TableCell as MuiTableCell } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { TableClassNames } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';

import { getStickyRightStyle } from '../utils';

function TableActionHeadCell(props) {
  const { actionsColumnWidth, actionsTableRender, column, isWhiteBackground } = props;
  const classes = useStyles({ isWhiteBackground });

  return (
    <MuiTableCell
      {...column.getHeaderProps(props => getStickyRightStyle(props, actionsColumnWidth ?? 150))}
      classes={classes}
      className={TableClassNames.STICKY_RIGHT_COLUMN}
      component="div"
      variant="head"
    >
      {actionsTableRender?.() ?? null}
    </MuiTableCell>
  );
}

const useStyles = makeStyles<Theme, { isWhiteBackground?: boolean }>(theme => ({
  root: {
    zIndex: 3,
    backgroundColor: ({ isWhiteBackground }) => (isWhiteBackground ? theme.palette.white : theme.palette.grey[50])
  }
}));

export default TableActionHeadCell;
