import { usePaginatedQuery } from 'react-query';
import { DefaultApiFindOrganizationUnitMembersRequest } from '@avispon/organization-structure';
import {
  OrganizationUnitMemberSnapshotExternal,
  PageOrganizationUnitMemberSnapshotExternal
} from '@avispon/organization-structure/dist/models';

import { API, endpointsConfig, OrganizationQueryKeysEnum } from '..';

const parseForeignPermissionsList = (organizationMembers: OrganizationUnitMemberSnapshotExternal[]) =>
  organizationMembers.map(organizationMember => ({
    id: organizationMember.user.id,
    author: {
      id: organizationMember.author.id,
      name: organizationMember.author.name
    },
    user: {
      id: organizationMember.user.id,
      name: organizationMember.user.name
    },
    created: organizationMember.created,
    modified: organizationMember.modified,
    modifier: {
      id: organizationMember.modifier.id,
      name: organizationMember.modifier.name
    },
    unitId: organizationMember.unitId,
    deleted: organizationMember.deleted,
    version: organizationMember.version
  }));

const findOrganizationUnitMembers = async (_, params: DefaultApiFindOrganizationUnitMembersRequest) => {
  const { data } = await API.organizationStructure.findOrganizationUnitMembers(params, {
    ...endpointsConfig.findOrganizationUnitMembers
  });

  return {
    ...data,
    content: parseForeignPermissionsList(data.content)
  };
};

export default function useFindOrganizationUnitMembersQuery(
  params: DefaultApiFindOrganizationUnitMembersRequest,
  queryConfig = {}
) {
  return usePaginatedQuery<PageOrganizationUnitMemberSnapshotExternal>(
    [OrganizationQueryKeysEnum.ORGANIZATION_UNIT_MEMBER_LIST, params],
    findOrganizationUnitMembers,
    queryConfig
  );
}
