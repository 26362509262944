import { MoneyTransferSnapshot } from '@ibtm/domain';

import { useDialog } from '@libs/common/v2';

import MoneyTransferUnconfirmedEditDialog from '../components/MoneyTransferUnconfirmedEditDialog';

function useMoneyTransferUnconfirmedEditDialog() {
  const { openDialog } = useDialog();

  const showDialog = ({ original }: { original?: MoneyTransferSnapshot }) => {
    openDialog(({ closeDialog }) => (
      <MoneyTransferUnconfirmedEditDialog original={original} closeDialog={closeDialog} />
    ));
  };

  return { showMoneyTransferUnconfirmedEditDialog: showDialog };
}

export default useMoneyTransferUnconfirmedEditDialog;
