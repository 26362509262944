import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { KeyType } from '@libs/common';
import { Button, NavLinkAdapter, Theme, ThemeVariant, Typography } from '@libs/common/v2';
import { important } from '@libs/common/v2/utils';

interface ILink {
  label: KeyType;
  url: string;
  isHiddenAfterLogin?: boolean;
  target?: string;
}

interface IProps {
  links?: ILink[];
  leftText?: ReactNode;
  wrapperClassName?: string;
  footerClassName?: string;
  buttonClassName?: string;
  leftTextClassName?: string;
  themeVariant?: ThemeVariant;
}

function FooterLayout({
  links,
  leftText,
  wrapperClassName,
  footerClassName,
  buttonClassName,
  leftTextClassName,
  themeVariant = 'textSm.medium'
}: IProps) {
  const [t] = useTranslation();
  const [type] = themeVariant.split('.');
  const classes = useStyles({ type });

  return (
    <div className={clsx(classes.wrapper, wrapperClassName)}>
      <div className={clsx(classes.footer, footerClassName)}>
        <Typography themeVariant={themeVariant} className={clsx(leftTextClassName, classes.leftText)}>
          {leftText}
        </Typography>
        <div className={clsx('flex items-center flex-wrap justify-end w-full', classes.buttonWrapper)}>
          {links.map(
            ({ label, url, isHiddenAfterLogin, target }) =>
              !isHiddenAfterLogin && (
                <Button
                  className={clsx(classes.button, buttonClassName)}
                  key={label}
                  label={t(label)}
                  variant="text"
                  component={NavLinkAdapter}
                  link={url}
                  target={target}
                  size="small"
                  isNoMargin
                />
              )
          )}
        </div>
        <Typography themeVariant={themeVariant} className={clsx(leftTextClassName, classes.bottomText)}>
          {leftText}
        </Typography>
      </div>
    </div>
  );
}

const useStyles = makeStyles<Theme, { type: string }>(theme => ({
  wrapper: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    zIndex: 20,
    border: `1px solid ${theme.palette.grey[200]}`
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 15px',
    backgroundColor: theme.palette.white,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  buttonWrapper: {
    [theme.breakpoints.down('md')]: {
      width: '100% !important',
      flex: '1 1 auto',
      justifyContent: 'space-between !important'
    }
  },
  button: ({ type }) => ({
    padding: 0,
    marginLeft: 24,
    textDecoration: important('underline'),
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'white'
    },
    '@media (max-width: 1582px)': {
      justifyContent: 'flex-end'
    },
    ...theme.typography[type].medium
  }),
  leftText: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  bottomText: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      marginTop: important(5),
      display: 'none'
    }
  }
}));

export default FooterLayout;
