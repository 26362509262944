import { useTranslation } from 'react-i18next';
import { Column, OnFilterChange } from 'react-table';
import { FolderSnapshotsPageAllOf } from '@ibtm/domain';

import { FetchSelectFilter, NUMBER_OF_AUTOCOMPLETE_RESULTS } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { DomainDictionaryEntry } from '../../../config';
import { FolderQueryKeysEnum } from '../../api';

interface IProps {
  column?: Column & { filterValue?: string; setFilter: (value: string | Array<string>) => void };
  onFilterChange?: OnFilterChange;
}

function AssociationFilter({ column, onFilterChange, ...forwardProps }: IProps) {
  const [t] = useTranslation();

  const fetchFunction = (numberContains: string) => {
    return API.folder.getFoldersSnapshotPage({
      page: 0,
      size: NUMBER_OF_AUTOCOMPLETE_RESULTS,
      typeKeyIn: [DomainDictionaryEntry.FOLDER_TYPE.ZZ],
      numberContains,
      statusKeyNotIn: [DomainDictionaryEntry.FOLDER_STATUS.ARCHIVED]
    });
  };

  const dataParser = ({ data }: { data: FolderSnapshotsPageAllOf }) => {
    return data?.content?.map(item => ({
      id: item?.id,
      name: item?.number || item?.subject?.name || t('emptyMark'),
      value: item?.id
    }));
  };

  return (
    <FetchSelectFilter
      queryKey={FolderQueryKeysEnum.FOLDERS_LIST_FILTER}
      fetchFunctionResolver={fetchFunction}
      column={column}
      fetchedDataSelectParser={dataParser}
      getOptionSelected={(option, value) => option.name === value.name}
      shouldCheckVisibility
      onFilterChange={onFilterChange}
      {...forwardProps}
    />
  );
}

export default AssociationFilter;
