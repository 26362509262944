import { FieldPath, FieldValues } from 'react-hook-form';

import { SelectOptionPartial } from '@libs/common/v2';
import { useFormV2Context, useViewModesV2 } from '@libs/common/v2/form';
import { ValueOf } from '@libs/common/v2/utils';

import DomainDictionaryEntry from '../../config/DomainDictionaryEntry';

function useSetInitialAddressType(
  fieldName: FieldPath<FieldValues>,
  typeKey: ValueOf<typeof DomainDictionaryEntry.ADDRESS_TYPE>
) {
  const { formMode, getValues, setValue } = useFormV2Context();
  const { createMode } = useViewModesV2(formMode);

  if (createMode && !(getValues(fieldName) as SelectOptionPartial)?.value) {
    setValue(fieldName, { value: typeKey });
  }
}

export default useSetInitialAddressType;
