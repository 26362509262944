import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

export function confirmApplicationDraft(requestData: { applicationId: string; version: number }) {
  return API.client.application.confirmApplicationDraft(
    '',
    '',
    requestData.applicationId,
    {
      version: requestData.version
    },
    { ...endpointsConfig.confirmApplicationDraft }
  );
}

function useClientConfirmApplicationDraftMutation() {
  return useMutation(confirmApplicationDraft);
}

export default useClientConfirmApplicationDraftMutation;
