import { VehicleSnapshot } from '@ibtm/domain';

import { ColumnTypesEnum, useCreateColumns } from '@libs/common/v2';

import { DomainDictionaryEnum, DomainQuickchangeableDictionaryEnum } from '@libs/domain/config';

function useApplicationVehiclesColumns() {
  const { createColumns } = useCreateColumns<VehicleSnapshot>({
    pathToTranslate: 'sCertificates:field'
  });

  return createColumns([
    {
      id: 'certificateSStatusKey',
      header: 'status',
      type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
      dictionaryName: DomainDictionaryEnum.CERTIFICATE_S_STATUS,
      isSortable: false,
      isFilterable: false
    },
    {
      id: 'plateNumber',
      header: 'vehicleRegistrationNumber',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      isFilterable: false
    },
    {
      id: 'certificateSNumber',
      header: 'number',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      isFilterable: false
    },
    {
      id: 'brandKeyTranslation',
      header: 'brandKey',
      type: ColumnTypesEnum.QUICK_DICTIONARY_SINGLE_SELECT,
      quickDictionaryName: DomainQuickchangeableDictionaryEnum.VEHICLE_BRAND,
      isSortable: false,
      isFilterable: false
    },
    {
      id: 'modelKeyTranslation',
      header: 'modelKey',
      type: ColumnTypesEnum.QUICK_DICTIONARY_SINGLE_SELECT,
      quickDictionaryName: DomainQuickchangeableDictionaryEnum.VEHICLE_MODEL,
      isSortable: false,
      isFilterable: false
    },
    {
      id: 'euroClassKey',
      header: 'euro',
      dictionaryName: DomainDictionaryEnum.EURO_CLASS,
      type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
      isSortable: false,
      isFilterable: false
    },
    {
      id: 'typeKey',
      header: 'vehicleType',
      type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
      dictionaryName: DomainDictionaryEnum.VEHICLE_TYPE,
      isSortable: false,
      isFilterable: false
    },
    {
      id: 'certificateSApplicationNumber',
      header: 'applicationNumber',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      isFilterable: false
    },
    {
      id: 'manufacturerCertificate',
      header: 'compatibilityCertificateNumber',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      isFilterable: false
    },
    {
      id: 'vin',
      type: ColumnTypesEnum.TEXT,
      header: 'vehicleVin',
      isSortable: false,
      isFilterable: false
    },
    {
      id: 'temporaryRightOfDisposition',
      header: 'timelyRightOfDisposal',
      type: ColumnTypesEnum.BOOLEAN,
      isSortable: false,
      isFilterable: false,
      width: 240
    },
    {
      id: 'rightOfDispositionDate',
      header: 'rightOfDisposalDate',
      type: ColumnTypesEnum.DATE,
      isSortable: false,
      isFilterable: false
    },
    {
      id: 'engineType',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      isFilterable: false
    },
    {
      id: 'engineNumber',
      type: ColumnTypesEnum.TEXT,
      isSortable: false,
      isFilterable: false
    }
  ]);
}

export default useApplicationVehiclesColumns;
