import { Context, useContext, useEffect, useMemo } from 'react';
import { Controller, FieldPath, FieldValues, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _ from 'lodash';

import { CheckboxField, FormV2Context, FormV2ContextState, LoaderCircular } from '@libs/common/v2';
import {
  FieldTypeEnum,
  SelectMultipleFieldValidation,
  useFieldValidationHandler
} from '@libs/common/v2/form/validation';
import { Theme } from '@libs/common/v2/theme/model/createMuiTheme';
import { important } from '@libs/common/v2/utils';

import { SelectOption } from '../input';
import { Label } from '../label';

export interface CheckboxGroupProps {
  name: string;
  options: SelectOption<string>[];
  label: string;
  isRequired?: boolean;
  formContext?: Context<any>;
  isLoading?: boolean;
  isDisabled?: boolean;
}

function CheckboxGroup({
  formContext = FormV2Context,
  name,
  label,
  options,
  isRequired,
  isLoading,
  isDisabled
}: CheckboxGroupProps) {
  const [t] = useTranslation();
  const classes = useStyles();
  const { control, trigger } = useContext<FormV2ContextState>(formContext);
  const value = useWatch({ control, name }) as Array<string>;
  useEffect(() => {
    trigger(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const validation = useMemo(
    () =>
      isRequired
        ? {
            required: [],
            test: [
              {
                name: 'isNotEmptyArray',
                message: t('validation:oneOfOptionRequired'),
                test(value: Array<string>) {
                  return !_.isEmpty(value?.filter(Boolean));
                }
              }
            ]
          }
        : {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRequired]
  );

  useFieldValidationHandler({
    fieldName: name,
    isMultiple: true,
    validation: validation as SelectMultipleFieldValidation,
    fieldType: FieldTypeEnum.AUTOCOMPLETE
  });

  return (
    <Controller
      control={control}
      name={name as FieldPath<FieldValues>}
      render={({ field: { value }, fieldState: { error } }) => (
        <>
          {isRequired && <input type="text" name={name} value={value as Array<string>} className="hidden" required />}
          {isLoading ? (
            <LoaderCircular className={classes.loader} size={20} isLoading={isLoading} />
          ) : (
            options && (
              <fieldset className="p-0">
                <Label label={label} isRequired={isRequired} />
                {options.map((option, index) => {
                  return (
                    <CheckboxField
                      key={option.value}
                      value={option.value}
                      name={`${name}.${index}`}
                      label={option.name}
                      className={classes.checkboxWrapper}
                      checkboxClassName={clsx(classes.checkboxClassName, error && classes.errorCheckbox)}
                      wrapperClassName={classes.checkboxWrapperClassName}
                      isDisabled={isDisabled}
                      resolveOnEvent
                    />
                  );
                })}
              </fieldset>
            )
          )}
          {error && (
            <FormHelperText error className={classes.error}>
              {error.message}
            </FormHelperText>
          )}
        </>
      )}
    />
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  checkboxWrapper: {
    height: 'unset',
    display: 'block'
  },
  errorCheckbox: {
    '& svg': {
      fill: theme.palette.error[400]
    }
  },
  checkboxClassName: {
    display: 'inline-flex'
  },
  checkboxWrapperClassName: {
    height: 'unset',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: '-6px'
  },
  error: {
    color: important(theme.palette.error[800]),
    fontSize: '1.4rem',
    margin: '6px 0',
    lineHeight: '2rem'
  },
  loader: {
    position: 'relative',
    top: 10,
    left: 0
  }
}));

export default CheckboxGroup;
