import React from 'react';

import {
  DictionarySelectProps,
  ICheckboxProps,
  ICommonAutocompleteProps,
  IDatepickerFieldProps,
  IQuickChangeableDictionarySelectFieldProps,
  NumberInputFieldProps,
  RadioFieldProps,
  SelectOption,
  SwitchFieldProps,
  TextInputFieldProps
} from '@libs/common/v2';

import { DictionaryEntryNameEnum, DictionaryQuickChangeableEntryNameEnum } from '@libs/dictionary';

/**
 * Podstawowe propsy dla input'ów, dla rzadziej używanych
 * zostało dodane rozszerzenie o obiekt( Record utility)
 */
export interface IMatrixCell extends Record<string, any> {
  name?: { id?: string; title?: string };
  fieldName?: string;
  style?: string;
  width: string | number;
  minWidth?: string | number;
  dictionaryName?: DictionaryEntryNameEnum;
  quickDictionaryName?: DictionaryQuickChangeableEntryNameEnum;
  minNumber?: number;
  maxNumber?: number;
  stepNumber?: number;
  minDate?: any;
  maxDate?: any;
  selectOptions?: SelectOption[];
  type?: MatrixCellInputTypeEnum;
  input?: React.ReactNode;
  inputProps?: InputMatrixFieldsProps;
  isDefaultChecked?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isShownAsBooleanValue?: boolean;
}

export enum MatrixCellInputTypeEnum {
  TEXT = 'TEXT',
  SWITCH = 'SWITCH',
  QUICK_CHANGEABLE_DICTIONARY_SELECT = 'QUICK_CHANGEABLE_DICTIONARY_SELECT',
  NUMBER = 'NUMBER',
  DICTIONARY_SELECT = 'DICTIONARY_SELECT',
  DATEPICKER = 'DATEPICKER',
  AUTOCOMPLETE_SELECT = 'AUTOCOMPLETE_SELECT',
  CHECKBOX = 'CHECKBOX',
  CUSTOM = 'CUSTOM',
  RADIO = 'RADIO'
}

export type TMatrixRow = Pick<IMatrixCell, 'name'>['name'];
export type TMatrixHeaderCell = Pick<IMatrixCell, 'name' | 'width' | 'isRequired'>;
export type TMatrixInputCell = Omit<IMatrixCell, 'width' | 'type'> & Required<Pick<IMatrixCell, 'type'>>;

export interface IRowInput {
  rowTitle: TMatrixRow;
  rowData?: any;
  allData?: Record<string, any>;
}

export type InputMatrixFieldsProps =
  | NumberInputFieldProps
  | IDatepickerFieldProps
  | ICommonAutocompleteProps
  | ICheckboxProps
  | DictionarySelectProps
  | IQuickChangeableDictionarySelectFieldProps
  | SwitchFieldProps
  | RadioFieldProps
  | TextInputFieldProps;
