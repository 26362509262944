import { useTranslation } from 'react-i18next';

import { useRegenerateResourcePoolsAssignmentMutation } from '@libs/domain/print/api';
import {
  usePrintsActionsContext,
  usePrintsActionsDataContext,
  usePrintsDetailsDataContext
} from '@libs/domain/print/context';

import { RegeneratePermissionDialogBase } from './common/RegeneratePermissionDialogBase';
import { getInitialValues, RegenerateForeignPermissionDialogContent } from './RegenerateForeignPermissionDialogContent';

export function RegenerateForeignPermissionDialog() {
  const [t] = useTranslation();
  const { printDetailsData } = usePrintsDetailsDataContext();
  const {
    applicationId,
    depotMP: { id: depotId }
  } = printDetailsData;
  const {
    actionData: { foreignPermissionsPrintsSnapshot, onSuccess }
  } = usePrintsActionsDataContext() ?? {};
  const { finishAction } = usePrintsActionsContext() ?? {};
  const { resourceTypeId, formNameKey } = foreignPermissionsPrintsSnapshot ?? {};

  const { mutateAsync: regenerate } = useRegenerateResourcePoolsAssignmentMutation();

  const queryConfig = {
    onSuccess: () => {
      onSuccess();
      finishAction();
    }
  };

  return (
    <RegeneratePermissionDialogBase
      title={t('prints:actions.regenerate')}
      confirmText={t('prints:actions.regenerate')}
      defaultValues={getInitialValues(formNameKey)}
      onSubmit={async formData => {
        const { pools, reasonsForReprinting, selectedNumbers } = formData;
        return regenerate(
          {
            applicationId,
            depotId,
            assignment: [...pools, ...selectedNumbers.map(item => item.value)]
              .filter(item => Boolean(item.amount))
              .map(item => ({
                reprintReasonKey: reasonsForReprinting?.value,
                element: { ...item, resourceTypeId }
              }))
          },
          queryConfig
        );
      }}
    >
      <RegenerateForeignPermissionDialogContent foreignPermissionsPrintsSnapshot={foreignPermissionsPrintsSnapshot} />
    </RegeneratePermissionDialogBase>
  );
}
