import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { useQueryCache } from '@libs/common/v2/api';
import { getLocalStorageItem, setLocalStorageItem } from '@libs/common/v2/utils';

import useAuth from '../hooks/useAuth';
import { AuthLocalStorageItem, AutoLogoutStorageItemEnum } from '../models';
import { AuthService, AuthStorageService } from '../services';

const MINUTE_MILLISECONDS = 60000;
const DEFAULT_LOGOUT_TIME_MINUTES = 60;
export type ShowSnackbarFn = ReturnType<typeof useSnackbar>['showSnackbar'];
type TAutoLogoutContext = {
  setAutoLogout: (value: number) => void;
  time: number;
  setOnLogoutFn: (onLogout: () => void) => void;
  setOnAutoLogoutReset: (onAutoLogoutReset: () => void) => void;
  setShowSnackbarFn: (showSnackbar: ShowSnackbarFn) => void;
  handleExtendSession: () => void;
  setCloseWarning: (closeWarning: () => void) => void;
};

export const AutoLogoutContext = createContext<null | TAutoLogoutContext>(null);

export function AutoLogoutProvider({ children }: { children: React.ReactNode }) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { logout } = useAuth();
  const [sessionTime, setSessionTime] = useState(0);
  const isRefreshingToken = useRef(false);
  const timeout = useRef<NodeJS.Timeout>(null);
  const onLogout = useRef(() => {});
  const onAutoLogoutReset = useRef(() => {});
  const closeWarning = useRef(() => {});
  const showSnackbar = useRef<ReturnType<typeof useSnackbar>['showSnackbar']>(() => {});

  const eventTimeoutListener = useRef(null);
  const autoLogoutTime = useRef(DEFAULT_LOGOUT_TIME_MINUTES);

  const autoLogoutCleanUp = useCallback(() => {
    window.removeEventListener('click', eventTimeoutListener.current);
    window.removeEventListener('keydown', eventTimeoutListener.current);
    eventTimeoutListener.current = null;
  }, []);

  const clearTimeout = useCallback(() => {
    const timeoutToClear = timeout.current;
    if (timeoutToClear) {
      onAutoLogoutReset.current?.();
      window.clearTimeout(timeoutToClear);
      timeout.current = null;
    }
  }, []);

  const handleAutoLogout = useCallback(() => {
    const isLoggedBySSO = getLocalStorageItem(AuthLocalStorageItem.LOGGED_BY_SS0) === 'true';
    logout(queryCache).then(() => {
      showSnackbar.current?.(
        'info',
        isLoggedBySSO ? t('message.autoLogoutInfoPressF5toLogin') : t('message.autoLogoutInfo')
      );
      clearTimeout();
      autoLogoutCleanUp();
      closeWarning.current?.();
    });
    onLogout.current?.();
  }, [autoLogoutCleanUp, clearTimeout, logout, queryCache, t]);

  const logoutOnSessionExpired = useCallback(
    (sessionTimeInMinutes: number) => {
      const idleStartTime = getLocalStorageItem(AutoLogoutStorageItemEnum.IDLE_START_TIME);
      const isSessionExpired =
        idleStartTime && new Date().getTime() - Number(idleStartTime) > sessionTimeInMinutes * MINUTE_MILLISECONDS;

      if (isSessionExpired) {
        handleAutoLogout();
      }
    },
    [handleAutoLogout]
  );

  const resetTimeout = useCallback((): void => {
    setLocalStorageItem(AutoLogoutStorageItemEnum.IDLE_START_TIME, String(new Date().getTime()));

    const setLogoutTimeout = (): void => {
      const overriddenTime = Number(getLocalStorageItem(AutoLogoutStorageItemEnum.OVERRIDDEN_TIME));
      const newSessionTime = overriddenTime || autoLogoutTime.current;
      setSessionTime(newSessionTime);
      AuthService.setAutoLoginWarning(autoLogoutTime.current);
      timeout.current = setTimeout(handleAutoLogout, newSessionTime * MINUTE_MILLISECONDS);
    };

    clearTimeout();
    setLogoutTimeout();
  }, [clearTimeout, handleAutoLogout]);

  const setAutoLogout = useCallback(
    (sessionTimeInMinutes: number) => {
      autoLogoutTime.current = sessionTimeInMinutes;
      logoutOnSessionExpired(sessionTimeInMinutes);
      resetTimeout();
      autoLogoutCleanUp();
      eventTimeoutListener.current = resetTimeout;
      window.addEventListener('click', resetTimeout);
      window.addEventListener('keydown', resetTimeout);
    },
    [logoutOnSessionExpired, resetTimeout, autoLogoutCleanUp]
  );

  useEffect(() => {
    const resetTimeoutOnStorageChange = (e: StorageEvent) => {
      if (e.key === AutoLogoutStorageItemEnum.IDLE_START_TIME) {
        resetTimeout();
      }
    };
    window.addEventListener('storage', resetTimeoutOnStorageChange);
    return () => window.removeEventListener('storage', resetTimeoutOnStorageChange);
  }, [resetTimeout]);

  const handleExtendSession = useCallback(async () => {
    const isActiveTab = document.visibilityState === 'visible';
    if (isActiveTab && !isRefreshingToken.current) {
      isRefreshingToken.current = true;
      const authTokenPayload = AuthStorageService.getAuthTokenPayload();
      await AuthService.handleTokenRefresh(authTokenPayload);
      const nextRefreshTokenDelay = 3000;
      setTimeout(() => {
        isRefreshingToken.current = false;
      }, nextRefreshTokenDelay);
    }
  }, []);

  const value: TAutoLogoutContext = useMemo(
    () => ({
      setAutoLogout,
      time: sessionTime,
      setOnLogoutFn: (fn: () => void) => {
        onLogout.current = fn;
      },
      setOnAutoLogoutReset: (fn: () => void) => {
        onAutoLogoutReset.current = fn;
      },
      setShowSnackbarFn: (fn: ShowSnackbarFn) => {
        showSnackbar.current = fn;
      },
      handleExtendSession,
      setCloseWarning: (fn: () => void) => {
        closeWarning.current = fn;
      }
    }),
    [setAutoLogout, sessionTime, handleExtendSession]
  );

  return <AutoLogoutContext.Provider value={value}>{children}</AutoLogoutContext.Provider>;
}
