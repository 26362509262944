import { useCallback } from 'react';
import { MutationResultPair } from 'react-query';
import { AxiosPromise, AxiosResponse } from 'axios';

import { Path, useDialog } from '@libs/common/v2';

import { IDownloadFileParam } from '../../../foreign-permission';
import GenerateDocumentDialog from '../../components/generate-document/GenerateApplicationDocumentDialog';

type MutationFunction = () => MutationResultPair<AxiosResponse<any>, any, any, any>;

interface IParams<Mutation extends MutationFunction> {
  generateDocumentMutation: Mutation;
  apiParams?: MutationPartialParams<Mutation>;
  listQueryKey: string;
  applicationType?: string;
}

type MutationReturnValue<T extends MutationFunction> = ReturnType<ReturnType<T>[0]> extends AxiosPromise<
  infer ReturnValue
>
  ? ReturnValue
  : never;

type MutationPartialParams<T extends MutationFunction> = Partial<Parameters<ReturnType<T>[0]>[0]>;

const useGenerateApplicationDocumentDialog = <Mutation extends MutationFunction>({
  generateDocumentMutation,
  apiParams,
  listQueryKey,
  applicationType
}: IParams<Mutation>) => {
  const { openDialog } = useDialog();

  const open = useCallback(
    ({
      title,
      groupKey,
      typeKeys,
      documentTemplateName,
      isGroupFieldDisabled,
      onSuccess,
      onCancel,
      isDocumentDownloadingAfterGenerate,
      isFieldsViewOnly,
      isGroupFieldViewOnly,
      params,
      documentTemplateNameFieldDisabled,
      downloadFilePath,
      includeWithKeys,
      downloadFileAfterSettleForeignPermission,
      isForeignPermissionsUse
    }: {
      title?: string;
      groupKey?: string;
      typeKeys?: Array<string>;
      documentTemplateName?: string;
      documentTemplateId?: string;
      isGroupFieldDisabled?: boolean;
      onSuccess?: (formData?: any) => void;
      onCancel?: () => void;
      isDocumentDownloadingAfterGenerate?: boolean;
      isFieldsViewOnly?: boolean;
      isGroupFieldViewOnly?: boolean;
      params?: MutationPartialParams<Mutation>;
      downloadFileAfterSettleForeignPermission?: (params: IDownloadFileParam) => void;
      documentTemplateNameFieldDisabled?: boolean;
      /**
       * ścieżka do obiektu 'file' (FileMetadataObject) w zwróconym response mutacji generowania dokumetnu, aby wskazać plik do pobrania
       * wartość null przekazuje cały obiekt z response.data
       */
      downloadFilePath?: Path<MutationReturnValue<Mutation>> | null;
      includeWithKeys?: boolean;
      isForeignPermissionsUse?: boolean;
    } = {}) => {
      openDialog(({ closeDialog }) => (
        <GenerateDocumentDialog
          useGenerateApplicationDocumentMutation={generateDocumentMutation}
          title={title}
          apiParams={{ ...apiParams, ...params }}
          closeDialog={closeDialog}
          onSuccess={onSuccess}
          onCancel={onCancel}
          listQueryKey={listQueryKey}
          groupKey={groupKey}
          typeKeys={typeKeys}
          documentTemplateName={documentTemplateName}
          applicationType={applicationType}
          isGroupFieldDisabled={isGroupFieldDisabled}
          isDocumentDownloadingAfterGenerate={isDocumentDownloadingAfterGenerate}
          isFieldsViewOnly={isFieldsViewOnly}
          isGroupFieldViewOnly={isGroupFieldViewOnly}
          documentTemplateNameFieldDisabled={documentTemplateNameFieldDisabled}
          downloadFilePath={downloadFilePath}
          includeWithKeys={includeWithKeys}
          downloadFileAfterSettleForeignPermission={downloadFileAfterSettleForeignPermission}
          isForeignPermissionsUse={isForeignPermissionsUse}
        />
      ));
    },
    [openDialog, generateDocumentMutation, apiParams, listQueryKey, applicationType]
  );

  return {
    openGenerateDocumentDialog: open
  };
};

export default useGenerateApplicationDocumentDialog;
