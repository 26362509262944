import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { TransportManagerCreateRequest as TransportManagerCreateRequestClient } from '@ibtm/client-domain';
import { TransportManagerCreateRequest } from '@ibtm/domain';
import { omit } from 'lodash';
import moment from 'moment';
import { date as YupDate, number as YupNumber, object as YupObject, string as YupString } from 'yup';

import { i18n } from '@libs/common';
import {
  Button,
  ButtonsGroup,
  Dialog,
  Divider,
  Error,
  FormMode,
  FormV2Context,
  useIsSmallScreen,
  useValidatorUtil,
  ValidatorEnums,
  WarningInformation,
  WarningThemeType
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { useViewModesV2 } from '@libs/common/v2/form';
import { getCalendarDate } from '@libs/common/v2/utils';

import { useElementVisibility } from '@libs/permission';
import { permissionsIgnoredTransportManagerLimits, usePermissionGroupQuery } from '@libs/user';

import { ApplicationQueryKeysEnum } from '@libs/domain/application';
import { DomainDictionaryEntry, DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import {
  TransportManagerBlockedByLimitsError,
  TransportManagerDetailsClient,
  TransportManagerDetailsClientFormData,
  TransportManagerQueryKeysEnum,
  useCreateReputationRequestMutation,
  useCreateTransportManagerMutation,
  useTransportManagerQuery,
  useUpdateTransportManagerMutation
} from '@libs/domain/transport-manager';

import { checkIfLinkedTransportManagerFieldIsVisible } from '../../utils';

import { VehiclesAndSubjectsManagementSection } from './components/VehiclesAndSubjectsManagementSection';
import { AddressDataSection, BasicData, CertificateData, GeneralInformation, ReputationSection } from './components';

const ValidationSchema = ({
  isLinkedTransportManagerRequired,
  isVehiclesAndSubjectsManagementSectionVisible
}: {
  isLinkedTransportManagerRequired: boolean;
  isVehiclesAndSubjectsManagementSectionVisible: boolean;
}) => {
  const useValidate = (validator: ValidatorEnums): [RegExp, string] => useValidatorUtil(validator);
  return YupObject<TransportManagerDetailsClientFormData>({
    personalData: YupObject({
      name: YupString().required().max(50),
      surname: YupString().required().max(100),
      birthDate: YupDate().required().max(new Date(Date.now()), i18n.t('validation:maxDateToday')).nullable(),
      birthPlace: YupString()
        .required()
        .matches(...useValidate(ValidatorEnums.ADDRESS_CITY))
    }),
    cancelledTransportManager: isLinkedTransportManagerRequired
      ? YupObject().nullable().required()
      : YupObject().nullable(),
    typeKey: YupString().required().nullable(),
    certificate: YupObject({
      number: YupString().required().max(20),
      typeKey: YupString().required().nullable(),
      dateOfIssue: YupDate().required().max(new Date(), i18n.t('validation:maxDateTodayIncluded')).nullable(),
      issuingAuthority: YupString().required(),
      issuingCountryKey: YupString().required().nullable(),
      validFrom: YupDate()
        .required()
        .nullable()
        .test(
          'isBeforeValidTo',
          i18n.t('transportManager:details.validation.validFrom'),
          function checkIsBeforeValidTo(validFrom) {
            const { validTo } = this.parent as TransportManagerDetailsClient['certificate'];
            return !validTo || moment(validFrom).isBefore(validTo);
          }
        ),
      validTo: YupDate()
        .nullable()
        .test('isRequired', i18n.t('validation:required'), function checkIsRequired(validTo) {
          const { isValidIndefinitely } = this.parent as TransportManagerDetailsClient['certificate'];
          if (isValidIndefinitely === undefined) {
            return true;
          }

          return !!(isValidIndefinitely || validTo);
        })
        .test(
          'isAfterValidFrom',
          i18n.t('transportManager:details.validation.validTo'),
          function checkIsAfterValidFrom(validTo) {
            const { validFrom, isValidIndefinitely } = this.parent as TransportManagerDetailsClient['certificate'];
            if (isValidIndefinitely === undefined) {
              return true;
            }

            return isValidIndefinitely || !validFrom || moment(validFrom).isBefore(validTo);
          }
        )
    }),
    address: YupObject({
      postCode: YupString()
        .required()
        .max(6)
        .matches(...useValidate(ValidatorEnums.ADDRESS_POSTAL_CODE)),
      voivodeship: YupString().required().nullable(),
      postCity: YupString().required().max(50),
      city: YupString()
        .required()
        .matches(...useValidate(ValidatorEnums.ADDRESS_CITY)),
      street: YupString().nullable(),
      propertyNumber: YupString().required().max(10),
      apartmentNumber: YupString().max(10).nullable()
    }),
    ...(isVehiclesAndSubjectsManagementSectionVisible && {
      numberOtherSubjectsRegisteredAsManager: YupNumber().nullable().required(),
      numberOtherSubjectsAppointedAsManager: YupNumber().nullable().required(),
      numberOtherSubjectVehiclesRegisteredAsManager: YupNumber().nullable().required(),
      numberOtherSubjectVehiclesAppointedAsManager: YupNumber().nullable().required()
    })
  });
};

interface IProps {
  closeDialog: () => void;
  id: string;
  formMode: FormMode;
  applicationId?: string;
  folderId?: string;
  folderType?: string;
  transferredFoldersIds?: string[];
  managerModificationTypeKey?: string;
  isVehiclesAndSubjectsManagementSectionVisible: boolean;
  permissionsKeysObject?: {
    SHOW_REPUTATION: DomainUIElementEnum;
    CHECK_REPUTATION: DomainUIElementEnum;
  };
}

function TransportManagerDetailsDialog({
  applicationId,
  folderId,
  folderType,
  transferredFoldersIds,
  closeDialog,
  id,
  formMode,
  managerModificationTypeKey,
  permissionsKeysObject,
  isVehiclesAndSubjectsManagementSectionVisible
}: IProps) {
  const { isSmallScreen } = useIsSmallScreen();
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { checkIsElementVisible } = useElementVisibility();
  const { createMode, viewMode } = useViewModesV2(formMode);
  const { showSuccessSnackbar } = useSnackbar();
  const [ignoreLimitsMutation, setIgnoreLimitsMutation] = useState(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const initialValues: Partial<TransportManagerDetailsClient> = {
    cancelledTransportManager: null,
    certificate: {
      issuingCountryKey: DomainDictionaryEntry.COUNTRY_CODE.POLAND,
      isValidIndefinitely: false
    },
    address: {
      addressTypeKey: DomainDictionaryEntry.ADDRESS_TYPE.RESIDENT,
      city: '',
      postCity: '',
      postCode: '',
      propertyNumber: '',
      street: '',
      voivodeship: ''
    }
  };

  const { data, isLoading, refetch } = useTransportManagerQuery(id, {
    enabled: !!id
  });

  const { data: initialTransportManagerData } = useTransportManagerQuery(data?.cancelledTransportManager?.id, {
    enabled: !!data?.cancelledTransportManager?.id
  });

  const { isOperatorPortal } = useDomainConfigContext();
  const { data: userPermissionGroupData } = usePermissionGroupQuery(null, { enabled: isOperatorPortal });

  const isIgnoredTransportManagerLimits = userPermissionGroupData?.content.some(permission =>
    Object.values(permissionsIgnoredTransportManagerLimits).includes(
      permission.group.name as permissionsIgnoredTransportManagerLimits
    )
  );

  const [createTransportManager] = useCreateTransportManagerMutation();
  const { mutate: updateTransportManager } = useUpdateTransportManagerMutation();
  const { mutate: createReputation, isLoading: isCreateReputationLoading } = useCreateReputationRequestMutation();

  const isLinkedTransportManagerRequired = checkIfLinkedTransportManagerFieldIsVisible(
    folderId,
    managerModificationTypeKey
  );

  const hasPermissionToCheckReputation = useMemo(
    () => permissionsKeysObject?.CHECK_REPUTATION && checkIsElementVisible(permissionsKeysObject.CHECK_REPUTATION),
    [checkIsElementVisible, permissionsKeysObject]
  );

  const isCheckReputationVisible = useMemo(
    () =>
      hasPermissionToCheckReputation &&
      folderType !== DomainDictionaryEntry.FOLDER_TYPE.PPO &&
      !createMode &&
      isOperatorPortal,
    [hasPermissionToCheckReputation, folderType, createMode, isOperatorPortal]
  );

  const validationSchemaObject = ValidationSchema({
    isLinkedTransportManagerRequired,
    isVehiclesAndSubjectsManagementSectionVisible
  });
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    reset,
    getValues,
    trigger,
    unregister
  } = useForm<Partial<TransportManagerDetailsClient | TransportManagerDetailsClientFormData>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchemaObject),
    defaultValues: initialValues
  });

  const title = useMemo(() => {
    if (createMode) {
      return t('transportManager:details.title.create');
    }
    if (viewMode) {
      return t('transportManager:details.title.view');
    }
    return t('transportManager:details.title.edit');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createMode, viewMode]);

  const convictedFieldValue = watch('convicted');

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  const getValuesParsed = (
    values: TransportManagerDetailsClient
  ): TransportManagerCreateRequest & TransportManagerCreateRequestClient => {
    const vehiclesAndSubjectsManagementData = {
      numberOtherSubjectsAppointedAsManager: values.numberOtherSubjectsAppointedAsManager,
      numberOtherSubjectsRegisteredAsManager: values.numberOtherSubjectsRegisteredAsManager,
      numberOtherSubjectVehiclesAppointedAsManager: values.numberOtherSubjectVehiclesAppointedAsManager,
      numberOtherSubjectVehiclesRegisteredAsManager: values.numberOtherSubjectVehiclesRegisteredAsManager
    } satisfies Pick<
      TransportManagerCreateRequestClient,
      | 'numberOtherSubjectVehiclesRegisteredAsManager'
      | 'numberOtherSubjectVehiclesAppointedAsManager'
      | 'numberOtherSubjectsRegisteredAsManager'
      | 'numberOtherSubjectsAppointedAsManager'
    >;
    return {
      ...values,
      address: {
        ...values.address,
        voivodeshipKey: values.address.voivodeship
      },
      personalData: {
        ...values.personalData,
        birthDate: getCalendarDate(moment(values?.personalData.birthDate).utc(true).toISOString())
      },
      managementPeriodFrom: getCalendarDate(values.managementPeriodFrom),
      managementPeriodTo: getCalendarDate(values.managementPeriodTo),
      certificate: {
        ...values.certificate,
        issuingAuthority: values.certificate.issuingAuthority || '',
        number: values.certificate.number || '',
        issuingCountryKey: values.certificate.issuingCountryKey || '',
        typeKey: values.certificate.typeKey || '',
        validFrom: getCalendarDate(moment(values?.certificate.validFrom).utc(true).toISOString()),
        validTo: getCalendarDate(moment(values?.certificate.validTo).utc(true).toISOString()),
        dateOfIssue: getCalendarDate(moment(values?.certificate.dateOfIssue).utc(true).toISOString())
      },
      typeKey: values?.typeKey,
      ...(values?.statusKey && { statusKey: values?.statusKey }),
      applicationId: applicationId ?? values?.applicationId,
      cancelledTransportManagerId: values.cancelledTransportManager?.id,
      ...(isVehiclesAndSubjectsManagementSectionVisible && vehiclesAndSubjectsManagementData)
    };
  };

  const _handleSubmit = (values: TransportManagerDetailsClient) => {
    setIsSubmitLoading(true);

    return new Promise((resolve, reject) => {
      const parsedValues = getValuesParsed(values);
      const requestConfig = {
        onSuccess: () => {
          queryCache.invalidateQueries(TransportManagerQueryKeysEnum.TRANSPORT_MANAGER_LIST);
          if (applicationId) {
            queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
          }
          resolve(undefined);
          showSuccessSnackbar(
            createMode
              ? t('transportManager:details.message.transportManagerAdded')
              : t('transportManager:details.message.transportManagerUpdated')
          );
          reset(initialValues);
          if (data?.cancelledTransportManager?.id) {
            queryCache.removeQueries([
              TransportManagerQueryKeysEnum.TRANSPORT_MANAGER,
              data.cancelledTransportManager.id
            ]);
          }
          closeDialog();
        },
        onError: () => {
          reject();
        },
        onSettled: () => {
          setIsSubmitLoading(false);
        }
      };
      if (createMode) {
        if (isOperatorPortal) {
          createTransportManager(
            { ...parsedValues, ignoreTransportManagerLimits: false },
            {
              ...requestConfig,
              onError: (errorData: Record<string, any>) => {
                const response = errorData?.request?.response;

                if (response?.includes(TransportManagerBlockedByLimitsError.TRANSPORT_MANAGER_BLOCKED_BY_LIMITS)) {
                  if (isIgnoredTransportManagerLimits) {
                    setIgnoreLimitsMutation(
                      () => () =>
                        createTransportManager(
                          { ...parsedValues, ignoreTransportManagerLimits: true },
                          {
                            ...requestConfig,
                            onSettled: () => {
                              setIsSubmitLoading(false);
                              setIgnoreLimitsMutation(null);
                            }
                          }
                        )
                    );
                  } else {
                    reject();
                    reset(initialValues);
                    closeDialog();
                  }
                } else {
                  reject();
                }
              }
            }
          );
        } else {
          createTransportManager(parsedValues, requestConfig);
        }
      } else {
        updateTransportManager(
          {
            id,
            transportManagerUpdateRequest: {
              ...parsedValues,
              personalData: { ...parsedValues.personalData, version: data.personalData.version },
              address: {
                ...omit(parsedValues.address, ['countryCode', 'voivodeship']),
                version: data.address.version,
                countryCodeKey: data.address.countryCode
              },
              managementPeriodFrom: getCalendarDate(moment(values?.managementPeriodFrom).utc(true).toISOString()),
              managementPeriodTo: getCalendarDate(moment(values?.managementPeriodTo).utc(true).toISOString()),
              version: data.version,
              expirationDate: getCalendarDate(moment(values?.expirationDate).utc(true).toISOString())
            }
          },
          requestConfig
        );
      }
    });
  };

  const handleCreateReputation = async () => {
    await createReputation(
      { id: data.id },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('transportManager:details.message.reputationCreated'));
          refetch();
        }
      }
    );
  };

  const _handleCancel = () => {
    closeDialog();
    reset(initialValues);
    if (typeof ignoreLimitsMutation === 'function') {
      setIgnoreLimitsMutation(null);
    }
  };

  const formV2ContextValue = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      loading: isLoading,
      formMode
    }),
    [control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting, isLoading, formMode]
  );

  const isReputationSectionVisible = useMemo(
    () => permissionsKeysObject?.SHOW_REPUTATION && checkIsElementVisible(permissionsKeysObject?.SHOW_REPUTATION),
    [checkIsElementVisible, permissionsKeysObject]
  );

  return (
    <FormV2Context.Provider value={formV2ContextValue}>
      <form>
        <Dialog
          title={title}
          onCancel={_handleCancel}
          isFullScreen={isSmallScreen}
          customActions={
            <ButtonsGroup>
              {isCheckReputationVisible && (
                <>
                  <Button
                    label={t('transportManager:details.action.checkReputation')}
                    variant="outlined"
                    onClick={handleCreateReputation}
                    disabled={!data?.id}
                    actionKey={permissionsKeysObject?.CHECK_REPUTATION}
                    isLoading={isCreateReputationLoading}
                    isSecondary
                  />
                  <Divider style={{ height: 30, margin: '0px 8px' }} />
                </>
              )}
              <Button
                label={viewMode ? t('action.close') : t('action.cancel')}
                variant="outlined"
                onClick={_handleCancel}
                isSecondary
              />
              {!viewMode && (
                <Button
                  label={t('action.save')}
                  variant="contained"
                  onClick={handleSubmit(data => {
                    if (typeof ignoreLimitsMutation === 'function') {
                      return ignoreLimitsMutation();
                    }

                    return _handleSubmit(data as TransportManagerDetailsClient);
                  })}
                  isLoading={isSubmitLoading}
                  isPrimary
                />
              )}
            </ButtonsGroup>
          }
          dialogSize="medium"
          isOpen
        >
          {typeof ignoreLimitsMutation === 'function' ? (
            <WarningInformation
              icon="WarningIcon"
              content={t('transportManager:details.message.exceedingLimits')}
              colorVersion={WarningThemeType.WARNING}
            />
          ) : (
            <>
              {convictedFieldValue && (
                <Error
                  message={t('transportManager:details.message.convictedMessage')}
                  type="warning"
                  className="my-0"
                />
              )}
              <BasicData
                folderId={folderId}
                transferredFoldersIds={transferredFoldersIds}
                isLinkedTransportManagerFieldVisible={isLinkedTransportManagerRequired}
                initialTransportManagerData={initialTransportManagerData}
                isCreateMode={createMode}
              />
              <CertificateData />
              <AddressDataSection />
              {isVehiclesAndSubjectsManagementSectionVisible && <VehiclesAndSubjectsManagementSection />}
              {!createMode && (
                <>
                  {isReputationSectionVisible && (
                    <ReputationSection reputations={data?.reputationInquires || []} transportManagerId={id} />
                  )}
                  <GeneralInformation />
                </>
              )}
            </>
          )}
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
}

export default TransportManagerDetailsDialog;
