import { useTranslation } from 'react-i18next';

import { Button } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { UIElementNameEnum } from '@libs/permission';

import { PricingQueryKeysEnum } from '../../api/query/PricingQueryKeysEnum';
import { useMoneyTransferAddToApplicationDialog } from '../../hooks';

interface IProps {
  applicationId: string;
  actionKey: UIElementNameEnum;
  handleDiscardOrSaveFormChanges?: (action: () => void) => void;
}

function SearchMoneyTransferButton({ applicationId, actionKey, handleDiscardOrSaveFormChanges }: IProps) {
  const queryCache = useQueryCache();
  const { showMoneyTransferAddToApplicationDialog } = useMoneyTransferAddToApplicationDialog();
  const [t] = useTranslation();
  return (
    <Button
      label={t('pricing:moneyTransfers.action.search.buttonTitle')}
      type="button"
      variant="outlined"
      onClick={() => {
        handleDiscardOrSaveFormChanges(() =>
          showMoneyTransferAddToApplicationDialog({
            applicationId,
            onSuccess: () => {
              queryCache.invalidateQueries(PricingQueryKeysEnum.APPLICATION_MONEY_TRANSFERS);
            }
          })
        );
      }}
      actionKey={actionKey}
    />
  );
}

export default SearchMoneyTransferButton;
