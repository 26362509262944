import { MutableRefObject, useMemo } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MutationResultPair, queryCache } from 'react-query';
import { ApplicationDetails } from '@ibtm/domain';

import { Dialog, useConfirmDialog } from '@libs/common/v2';

import { ApplicationQueryKeysEnum, useApplicationDetailsQuery } from '@libs/domain/application';

import { useMutationConfig } from '../../hooks';
import {
  DialogModeEnum,
  MisspellApplicationPermissionTypeEnum,
  PermissionUpdateRequest
} from '../../model/misspell-application.model';

type MutationResult<T> = MutationResultPair<ApplicationDetails, unknown, T, unknown>;

export interface IPermissionDialogProps<T> {
  permissionData: Partial<T>;
  updatePermissionData: Partial<T>;
  mode: DialogModeEnum;
  applicationId: string;
  closeDialog: () => void;
  mutation: () => {
    mutate: (...params: Parameters<MutationResult<PermissionUpdateRequest<T>>[0]>) => void;
  } & MutationResult<PermissionUpdateRequest<T>>[1];
  compareContentElement: JSX.Element;
  editContentElement: JSX.Element;
  formRef: MutableRefObject<UseFormHandleSubmit<T>>;
  permissionType?: string;
  compareTitle: string;
  editTitle: string;
}

export function PermissionDialog<T>({
  mode,
  permissionData,
  applicationId,
  closeDialog,
  mutation: useMutation,
  compareTitle,
  editTitle,
  compareContentElement,
  editContentElement,
  formRef,
  permissionType
}: IPermissionDialogProps<T>) {
  const [t] = useTranslation();
  const { data } = useApplicationDetailsQuery(applicationId, { refetchOnMount: false });
  const isCompareMode = mode === DialogModeEnum.COMPARE;
  const { mutate: updatePermission, isLoading } = useMutation();
  const handleSubmit = formRef;

  const getMutationConfiguration = useMutationConfig({});
  const [confirm] = useConfirmDialog();

  const isLicenseCopy = useMemo(
    () => permissionType === MisspellApplicationPermissionTypeEnum.LICENSE_COPY,
    [permissionType]
  );

  const handleFormSubmit = async () => {
    if (typeof handleSubmit.current === 'function') {
      await handleSubmit.current(permissionUpdateRequest =>
        updatePermission(
          {
            applicationId,
            permissionUpdateRequest: {
              ...permissionUpdateRequest,
              reprint: !isLicenseCopy,
              applicationVersion: data.version
            }
          },
          getMutationConfiguration({
            onSuccess: () => {
              queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
              closeDialog();
            }
          })
        )
      )();
    }
  };
  const handleRestoreDefault = async () => {
    confirm({
      onConfirm: async (setConfirmLoading, closeConfirmDialog) => {
        setConfirmLoading(true);
        updatePermission(
          {
            applicationId,
            permissionUpdateRequest: {
              ...permissionData,
              reprint: false,
              applicationVersion: data.version
            } as T
          },
          getMutationConfiguration({
            onSuccess: () => {
              setConfirmLoading(false);
              closeConfirmDialog();
            }
          })
        );
      },
      message: t('applications:misspellApplication.dialog.revertChangesMessage'),
      confirmType: 'danger',
      title: t('applications:misspellApplication.dialog.revertChangesTitle')
    });
  };

  const dialogButtons = isCompareMode
    ? {
        dialogSize: 'large' as const,
        cancelText: t('action.cancel'),
        onConfirm: handleRestoreDefault,
        confirmText: t('applications:misspellApplication.action.revertChanges'),
        confirmButtonVariant: 'outlined' as const,
        isConfirmLoading: isLoading,
        title: compareTitle
      }
    : {
        dialogSize: 'medium' as const,
        onConfirm: handleFormSubmit,
        isConfirmLoading: isLoading,
        confirmText: t('action.save'),
        cancelText: t('action.cancel'),
        onCancel: closeDialog,
        title: editTitle
      };

  return (
    <Dialog isOpen {...dialogButtons} onCancel={closeDialog}>
      {mode === DialogModeEnum.COMPARE ? compareContentElement : editContentElement}
    </Dialog>
  );
}
