import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import { typedNameV2, useFormV2Context } from '@libs/common/v2';

import { DataSetContext } from '@libs/report/context/DataSetContext';
import { ConditionOperator } from '@libs/report-core';
import { useReportDataSourceColumns } from '@libs/report-core/hooks';
import { DataSource } from '@libs/report-core/hooks/useReportDataSourceColumns';

import ReportTypeBasicColumns from './ReportTypeBasicColumns';

const FieldName = {
  columns: dataSetIndex => typedNameV2<any>(`dataSetDefinitions[${dataSetIndex}].dataDefinition.columns`),
  queryDefinition: dataSetIndex => typedNameV2<any>(`dataSetDefinitions[${dataSetIndex}].queryDefinition`)
};

const EmptyConditionValue = {
  columns: [],
  conditions: [
    {
      filter: null,
      group: [],
      operator: ConditionOperator.AND
    }
  ],
  groups: []
};

function ReportTypeColumns() {
  const { getValues, setValue, loading } = useFormV2Context();
  const { dataSetIndex } = useContext(DataSetContext);

  const [previousSourceId, setPreviousSourceId] = useState<string | null>(null);

  const source = _.get(getValues(), `dataSetDefinitions[${dataSetIndex}].source`) as DataSource;
  const sourceColumns = useReportDataSourceColumns(source);

  const isColumnSectionVisible = Boolean(source?.id);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!source?.id || (previousSourceId !== null && previousSourceId !== source.id)) {
      const localValues = _.get(getValues(), `dataSetDefinitions[${dataSetIndex}]`);

      if (localValues?.dataDefinition?.columns?.length) {
        setValue(FieldName.columns(dataSetIndex), []);
      }

      if (localValues?.queryDefinition?.columns?.length) {
        setValue(FieldName.queryDefinition(dataSetIndex), EmptyConditionValue);
        setValue('sourceModified', true);
      }
    }
    setPreviousSourceId(source?.id || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSetIndex, loading, source?.id]);

  if (!isColumnSectionVisible || !sourceColumns.length) {
    return null;
  }

  return <ReportTypeBasicColumns />;
}

export default React.memo(ReportTypeColumns);
