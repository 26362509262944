import { AxiosError, AxiosResponse } from 'axios';
// Based on redux-promise-middleware implementation (https://github.com/pburtchaell/redux-promise-middleware)

function isPromise(value) {
  if (value !== null && typeof value === 'object') {
    return value && typeof value.then === 'function';
  }
  return false;
}

const REQUESTED = 'Requested';
const FAILURE = 'Failure';

/*
 * Konfiguracja danych, które zwraca promise middleware (domyślnie 'data' czyli response.data,
 * można ustawić na 'response' w celu zwrócenia całej odpowiedzi z serwera).
 * Przykładowe wywołanie z ustawioną konfiguracją:
 * dispatch({ type: 'XXX', payload: API.getData(), config: { resolve: 'response' } });
 * */
export interface PromiseMiddlewareConfig {
  resolve: 'response' | 'data';
}

const DefaultPromiseMiddlewareConfig: PromiseMiddlewareConfig = {
  resolve: 'data'
};

// eslint-disable-next-line consistent-return
export const promiseMiddleware = store => next => action => {
  if (isPromise(action.payload)) {
    const config = (action.config as PromiseMiddlewareConfig) ?? DefaultPromiseMiddlewareConfig;
    return new Promise((resolve, reject) => {
      store.dispatch({ type: `${action.type}${REQUESTED}` });
      action.payload.then(
        (response: AxiosResponse) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const resolvedValue = config.resolve === 'data' ? response.data : response;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          store.dispatch({ type: action.type, payload: resolvedValue });
          resolve(resolvedValue);
        },
        (error: AxiosError) => {
          store.dispatch({ type: `${action.type}${FAILURE}` });
          reject(error);
        }
      );
    });
  }
  next(action);
};

export default promiseMiddleware;
