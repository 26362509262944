import { useTranslation } from 'react-i18next';
import { DriverDetails, DriversSearchFilter } from '@ibtm/domain';

import { CamelCasePath, SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO, getCalendarDate } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { ProceedingCell } from '../../components/table/DriversTable/ProceedingCell';

const useDriversColumns = (isSortingDisabled?: boolean, visibleColumns?: Array<CamelCasePath<DriverDetails>>) => {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  const { createColumns } = useCreateColumns<DriverDetails>({
    pathToTranslate: 'drivers:fields'
  });

  const mappedSortFields: TableSortMapper<DriverDetails> = {
    name: 'personalData.name',
    folderNumber: 'folder.number',
    surname: 'personalData.surname',
    birthPlace: 'personalData.birthPlace',
    citizenship: 'personalData.citizenship',
    identityCardTypeKey: 'identityCard.typeKey',
    currentCertificateNumber: 'currentCertificate.number',
    identityCardSeriesNumber: 'identityCard.seriesNumber',
    driverLicenseSeriesNumber: 'driverLicense.seriesNumber',
    currentCertificateStatusKey: 'currentCertificate.statusKey',
    currentCertificateFormNumber: 'driverCertificates.form.number',
    currentCertificateFormFullNumber: 'currentCertificate.form.fullNumber',
    currentCertificateCancellationDate: 'currentCertificate.cancellationDate',
    currentCertificateApplicationNumber: 'currentCertificate.application.number',
    currentCertificateApplicationTypeKey: 'currentCertificate.application.typeKey',
    currentCertificateCancellationReasonKey: 'currentCertificate.cancellationReasonKey'
  };

  const mappedFilterFields: TableFilterMapper<DriverDetails, DriversSearchFilter> = {
    folderNumber: (value: string) => ({ folderNumberContains: value }),
    name: (value: string) => ({ nameContains: value }),
    surname: (value: string) => ({ surnameContains: value }),
    birthDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      birthDateDateRange: { from: getCalendarDate(dateFrom), to: getCalendarDate(dateTo) }
    }),
    birthPlace: (value: string) => ({ birthPlaceContains: value }),
    citizenship: (values: SelectOption<string>[]) => ({ citizenshipKeyIn: values.map(({ value }) => value) }),
    insurancePayerNip: (value: string) => ({ insuranceNumberContains: value }),
    employmentDocumentKey: (values: SelectOption<string>[]) => ({
      employmentDocumentKeyIn: values.map(({ value }) => value)
    }),
    identityCardTypeKey: (values: SelectOption<string>[]) => ({
      identityDocumentTypeKeyIn: values.map(({ value }) => value)
    }),
    identityCardSeriesNumber: (value: string) => ({ identityDocumentSeriesNumberContains: value }),
    driverLicenseSeriesNumber: (value: string) => ({ driverLicenseNumberContains: value }),
    currentCertificateNumber: (value: string) => ({ driverCertificateNumberContains: value }),
    currentCertificateFormFullNumber: (value: string) => ({ formNumberContains: value }),
    currentCertificateStatusKey: (status: SelectOption<string>[]) => ({
      driverCertificateStatusKeyIn: status.map(({ value }) => value)
    }),
    currentCertificateCancellationDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      driverCertificateCancellationDateRange: { from: getCalendarDate(dateFrom), to: getCalendarDate(dateTo) }
    }),
    currentCertificateCancellationReasonKey: (values: SelectOption<string>[]) => ({
      cancellationReasonKeyIn: values.map(({ value }) => value)
    }),
    currentCertificateApplicationNumber: (value: string) => ({ currentCertificateApplicationNumberContains: value })
  };

  const columns = createColumns(
    [
      {
        type: 'TEXT',
        header: 'applicationNumber',
        accessor: 'currentCertificate.application.number'
      },
      {
        type: 'TEXT',
        accessor: 'folderNumber',
        isSortable: !isSortingDisabled
      },
      {
        type: 'TEXT',
        accessor: 'name',
        isSortable: !isSortingDisabled
      },
      {
        type: 'TEXT',
        accessor: 'surname',
        isSortable: !isSortingDisabled
      },
      {
        type: 'DATE',
        accessor: 'birthDate',
        isSortable: !isSortingDisabled
      },
      {
        id: 'birthPlace',
        type: 'TEXT',
        customAccessor: row => translate(DomainDictionaryEnum.COUNTRY_CODE, row?.birthPlace, t('emptyMark')),
        isSortable: !isSortingDisabled
      },
      {
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.CITIZENSHIP,
        accessor: 'citizenship',
        isSortable: !isSortingDisabled
      },
      {
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.COMPETENCE,
        accessor: 'competenceCodeKey',
        isSortable: !isSortingDisabled
      },
      {
        header: 'insurancePayerNIP',
        type: 'TEXT',
        accessor: 'insurancePayerNip',
        isSortable: !isSortingDisabled
      },
      {
        header: 'periodOfIssuing',
        accessor: 'driverCertificateValidityPeriodKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.PERIOD_OF_ISSUING_DRIVERS_CERTIFICATE,
        isSortable: !isSortingDisabled
      },
      {
        header: 'employmentDocument',
        accessor: 'employmentDocumentKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.EMPLOYMENT_DOCUMENT,
        width: 220,
        isSortable: !isSortingDisabled
      },
      {
        accessor: 'identityCard.typeKey',
        header: 'identityCard.typeKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.IDENTITY_DOCUMENT_TYPE,
        isSortable: !isSortingDisabled
      },
      {
        type: 'TEXT',
        accessor: 'identityCard.seriesNumber',
        header: 'identityCard.seriesNumber',
        isSortable: !isSortingDisabled
      },
      {
        type: 'TEXT',
        accessor: 'identityCard.issuingDate',
        header: 'identityCard.issuingDate',
        isSortable: !isSortingDisabled
      },
      {
        id: 'identityCardIssuingCountryKey',
        type: 'TEXT',
        customAccessor: row =>
          translate(DomainDictionaryEnum.COUNTRY_CODE, row?.identityCard?.issuingCountryKey, t('emptyMark')),
        header: 'identityCard.issuingPlaceKey',
        isSortable: !isSortingDisabled
      },
      {
        type: 'TEXT',
        accessor: 'driverLicense.seriesNumber',
        header: 'driverLicense.seriesNumber',
        isSortable: !isSortingDisabled
      },
      {
        type: 'DATE',
        accessor: 'driverLicense.issuingDate',
        header: 'driverLicense.issuingDate',
        isSortable: !isSortingDisabled
      },
      {
        id: 'driverLicenseIssuingCountryKey',
        type: 'TEXT',
        customAccessor: row =>
          translate(DomainDictionaryEnum.COUNTRY_CODE, row?.identityCard?.issuingCountryKey, t('emptyMark')),
        header: 'driverLicense.issuingPlaceKey',
        isSortable: !isSortingDisabled
      },
      {
        accessor: 'formNumber',
        type: 'TEXT',
        header: 'driverCertificate.form.name',
        isSortable: !isSortingDisabled
      },
      {
        header: 'driverCertificate.document.application.typeKey',
        accessor: 'currentCertificate.application.typeKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.APPLICATION_TYPE,
        isSortable: !isSortingDisabled
      },
      {
        type: 'TEXT',
        accessor: 'currentCertificate.number',
        header: 'driverCertificate.number',
        isSortable: !isSortingDisabled
      },
      {
        accessor: 'currentCertificate.form.fullNumber',
        header: 'driverCertificate.form.name',
        type: 'TEXT',
        customAccessor: row => (
          <ProceedingCell
            formNumber={row?.currentCertificate?.form?.fullNumber}
            pendingProceedings={row?.currentCertificate?.pendingProceedings}
          />
        ),
        isSortable: !isSortingDisabled
      },
      {
        type: 'TEXT',
        accessor: 'applicationArchiveNumber',
        header: 'driverCertificate.document.application.archiveNumber',
        isSortable: !isSortingDisabled
      },
      {
        header: 'driverCertificate.statusKey',
        accessor: 'currentCertificate.statusKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.DRIVER_CERTIFICATE_STATUS,
        isSortable: !isSortingDisabled
      },
      {
        header: 'driverCertificate.cancellationDate',
        type: 'DATE',
        accessor: 'currentCertificate.cancellationDate',
        isSortable: !isSortingDisabled
      },
      {
        accessor: 'currentCertificate.cancellationReasonKey',
        header: 'driverCertificate.cancellationReason',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.PERMISSION_CANCELLATION_REASON,
        isSortable: !isSortingDisabled
      }
    ],
    visibleColumns
  );

  return { columns, mappedSortFields, mappedFilterFields };
};

export default useDriversColumns;
