import { InvoiceStatusUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function changeStatus(request: InvoiceStatusUpdateRequest & { id: string }) {
  const { id, ...rest } = request;
  return API.releaseDocuments.updateInvoiceStatus(id, rest, {
    ...endpointsConfig.updateInvoiceStatus
  });
}

function useChangeReleaseDocumentStatusMutation() {
  return useMutation(changeStatus);
}

export default useChangeReleaseDocumentStatusMutation;
