import { useDialog } from '@libs/common/v2';
import { ValueOf } from '@libs/common/v2/utils';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { ProxyCreateDialog } from '../../components';
import { ProxyFields } from '../../model';

interface IProps {
  applicationId?: string;
  folderId?: string;
  applicationType?: ValueOf<typeof DomainDictionaryEntry.APPLICATION_CATEGORY>;
  hiddenFieldIds?: (keyof ProxyFields)[];
  isPostCityRequired?: boolean;
  isCitizenshipFieldVisible?: boolean;
}

function useProxyCreateDialog({
  applicationId,
  folderId,
  applicationType,
  isPostCityRequired,
  hiddenFieldIds,
  isCitizenshipFieldVisible
}: IProps) {
  const { openDialog } = useDialog();

  const showProxyCreateDialog = () => {
    openDialog(({ closeDialog }) => (
      <ProxyCreateDialog
        onCloseDialog={closeDialog}
        isOpen
        folderId={folderId}
        applicationId={applicationId}
        applicationType={applicationType}
        isPostCityRequired={isPostCityRequired}
        isCitizenshipFieldVisible={isCitizenshipFieldVisible}
        hiddenFieldIds={hiddenFieldIds}
      />
    ));
  };

  return { showProxyCreateDialog };
}

export default useProxyCreateDialog;
