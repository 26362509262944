import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { useConfirmDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import {
  SuspensionQueryKeysEnum,
  useDeleteSuspensionMutation,
  useSuspensionCloseMutation
} from '@libs/domain/suspensions';

function useSuspensionActions(id?: string, onSuccess?: () => void, version?: number) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const [confirm] = useConfirmDialog();
  const { mutate: closeSuspension } = useSuspensionCloseMutation();
  const queryCache = useQueryCache();
  const { mutate: deleteSuspension, isLoading: isDeleteProcessInProgress } = useDeleteSuspensionMutation();

  const isLoading = useMemo(() => isDeleteProcessInProgress, [isDeleteProcessInProgress]);

  const startDeletingProcess = (suspensionId?: string) => {
    confirm({
      title: t('suspensions:details.message.deleteModalTitle'),
      message: t('suspensions:details.message.delete'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        deleteSuspension(
          {
            id: suspensionId ?? id
          },
          {
            onSuccess: () => {
              onSuccess?.();
              showSuccessSnackbar(t('suspensions:details.message.deleteSuccess'));
              queryCache.invalidateQueries([SuspensionQueryKeysEnum.SUSPENSION_SUMMARY_LIST]);
              closeDialog();
            },
            onSettled: () => setConfirmLoading(false)
          }
        );
      },
      confirmType: 'danger'
    });
  };

  const startCloseProcess = (suspensionId?: string, suspensionVersion?: number) => {
    confirm({
      title: t('suspensions:details.message.closeModalTitle'),
      message: t('suspensions:details.message.closeConfirmation'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        closeSuspension(
          {
            suspensionId: suspensionId ?? id,
            version: suspensionVersion ?? version
          },
          {
            onSuccess: () => {
              onSuccess?.();
              showSuccessSnackbar(t('suspensions:details.message.closeSuccess'));
              closeDialog();
            },
            onSettled: () => setConfirmLoading(false)
          }
        );
      },
      confirmType: 'danger'
    });
  };
  return { startCloseProcess, startDeletingProcess, isLoading };
}

export default useSuspensionActions;
