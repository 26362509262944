import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataSourceMediatorColumn } from '@stack/report/dist/models';
import _ from 'lodash';

import { AutocompleteSelect, Checkbox, SelectOption, useFormV2Context } from '@libs/common/v2';
import { FieldTypeEnum, useFieldValidationHandler } from '@libs/common/v2/form/validation';

interface IProps {
  columns: DataSourceMediatorColumn[];
  selectedColumns: DataSourceMediatorColumn[];
  onChange?: (columns: DataSourceMediatorColumn[]) => void;
  dataSetIndex?: number;
}
function ReportSourceColumnsField(props: IProps) {
  const { columns, selectedColumns, onChange, dataSetIndex } = props;
  const { formState, watch, clearErrors } = useFormV2Context();
  const [t] = useTranslation();
  const fieldName = _.isNil(dataSetIndex)
    ? `queryDefinition.columns`
    : `dataSetDefinitions.${dataSetIndex}.queryDefinition.columns`;
  const queryDefinitionColumns = watch(fieldName);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isRequired, setIsRequired] = useState(false);

  useFieldValidationHandler({
    fieldName,
    validation: isRequired
      ? {
          required: []
        }
      : {},
    isMultiple: true,
    fieldType: FieldTypeEnum.AUTOCOMPLETE
  });

  useEffect(() => {
    if (queryDefinitionColumns?.length === 0) {
      setIsRequired(true);
    } else {
      clearErrors(fieldName);
      setErrorMessage(null);
      setIsRequired(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryDefinitionColumns]);

  useEffect(() => {
    setErrorMessage(_.get(formState.errors, fieldName));
  }, [fieldName, formState?.errors]);

  const convertColumnListToSelectOptionList = useCallback((columns: DataSourceMediatorColumn[]): SelectOption[] => {
    return columns.map(column => {
      return convertColumnToSelectOption(column);
    });
  }, []);

  const options = useMemo(() => {
    return [null, ...convertColumnListToSelectOptionList(columns)];
  }, [columns, convertColumnListToSelectOptionList]);

  function convertColumnToSelectOption(column: DataSourceMediatorColumn): SelectOption {
    return { id: column.name, value: column, name: `${column.displayName} (${column.type})` };
  }

  function convertSelectOptionListToColumnList(selectOptions: SelectOption[]): DataSourceMediatorColumn[] {
    return selectOptions.map(option => {
      return convertSelectOptionToColumn(option);
    });
  }

  function convertSelectOptionToColumn(option: SelectOption): DataSourceMediatorColumn {
    return { ...option.value };
  }

  const handleChange = (_, selectOptions: SelectOption[]) => {
    const uniqueOptions: SelectOption[] = [];
    selectOptions.forEach(option => {
      const uniqueOptionsNames = uniqueOptions.map(uniqueOption => {
        return uniqueOption?.name;
      });
      if (!uniqueOptionsNames.includes(option?.name)) {
        uniqueOptions.push(option);
      }
    });

    if (selectOptions.includes(null) || columns.length === selectOptions.length) {
      onChange?.(selectedColumns.length === columns.length ? [] : columns);
    } else {
      onChange?.(convertSelectOptionListToColumnList(uniqueOptions.filter(Boolean)));
    }
  };

  return (
    <AutocompleteSelect
      label={t('reports:field.selectColumns')}
      options={options}
      value={convertColumnListToSelectOptionList(selectedColumns)}
      getOptionLabel={option => (typeof option === 'string' ? option : option?.name)}
      getOptionSelected={(option, value) => {
        return option?.id === value?.id;
      }}
      isError={!!errorMessage}
      helperText={errorMessage?.message.toString()}
      onChange={handleChange}
      renderTags={() => null}
      renderOption={(optionProps, option: SelectOption | null, state) => {
        return (
          <li {...optionProps}>
            <Checkbox isChecked={state.selected || (option === null && selectedColumns.length === columns.length)} />
            {option?.value?.displayName
              ? `${option.value.displayName} (${option.value.type})`
              : t('reports:reportTypes.all')}
          </li>
        );
      }}
      isRequired
      isMultiple
      isClearable
    />
  );
}

export default ReportSourceColumnsField;
