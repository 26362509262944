import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { Button } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import { BasicLimitTabEnum } from '../../../utils';

interface IProps {
  tab: BasicLimitTabEnum;
  tabChange: (tab: BasicLimitTabEnum) => void;
}

function BasicLimitRadioButtons({ tab, tabChange }: IProps) {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.buttonsWrapper}>
      <Button
        label={t('folder:details.tab.singleAuthorization.section.basicLimit.title')}
        onClick={() => tabChange(BasicLimitTabEnum.LIST)}
        className={tab === BasicLimitTabEnum.LIST && classes.buttonActive}
        isNoMargin
      />
      <Button
        label={t('folder:details.tab.singleAuthorization.section.limitTransfer.title')}
        onClick={() => tabChange(BasicLimitTabEnum.HISTORY)}
        className={tab === BasicLimitTabEnum.HISTORY && classes.buttonActive}
        isNoMargin
      />
    </div>
  );
}
const useStyles = makeStyles<Theme>(theme => ({
  buttonsWrapper: {
    margin: '1rem 0',
    marginLeft: 8,
    '& .MuiButton-root': {
      color: theme.palette.grey[700],
      height: 'auto',
      backgroundColor: 'transparent',
      borderRadius: theme.borderRadiusBase * 0.75,
      boxShadow: important('none'),
      borderBottom: '2px solid transparent',
      '&:hover': {
        backgroundColor: theme.palette.grey[200]
      }
    }
  },
  buttonActive: {
    color: important(theme.palette.blue[800]),
    borderRadius: important(0),
    backgroundColor: important(theme.palette.blue[50]),
    borderColor: important(theme.palette.blue[800])
  }
}));

export default BasicLimitRadioButtons;
