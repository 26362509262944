import { QueryConfig, usePaginatedQuery } from 'react-query';
import {
  DriversDetailsPage as DriversDetailsPageClient,
  DriversSearchFilter as DriversSearchFilterClient
} from '@ibtm/client-domain';
import { DriversDetailsPage, DriversSearchFilter } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { DriversQueryKeysEnum } from '@libs/domain/drivers/api';

import { endpointsConfig } from '../endpoints-config';

export const getDrivers = async (_, params: DriversSearchFilter) => {
  const { data } = await API.drivers.getDriversDetailsPage(params, {
    ...endpointsConfig.getDriversDetailsPage
  });
  return data;
};

export const getDriversClient = async (_, params: DriversSearchFilterClient) => {
  const { data } = await API.client.drivers.getDriversDetailsPage('', '', params, {
    ...endpointsConfig.getDriversDetailsPage
  });
  return data;
};

export default function useDriversListQuery(
  params: DriversSearchFilter | DriversSearchFilterClient,
  queryConfig: QueryConfig<DriversDetailsPage | DriversDetailsPageClient> = {}
): any {
  const { getQuery } = useGetApiQueryByPerspective();
  return usePaginatedQuery<DriversDetailsPage | DriversDetailsPageClient>(
    [DriversQueryKeysEnum.DRIVERS_LIST, params],
    getQuery(getDriversClient, getDrivers),
    queryConfig
  );
}
