import { QueryConfig, useQuery } from 'react-query';
import { TransitScheduleDetails } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { ApplicationQueryKeysEnum } from '@libs/domain/application';

import { endpointsConfig } from '../endpoints-config';

const getApplicationTransitSchedule = async (applicationId: string) => {
  const { data } = await API.application.getApplicationTransitSchedule(applicationId, {
    ...endpointsConfig.getApplicationTransitSchedule
  });
  return data;
};

const getApplicationTransitScheduleClient = async (applicationId: string) => {
  const { data } = await API.client.application.getApplicationTransitSchedule(applicationId, '', '', {
    ...endpointsConfig.getApplicationTransitSchedule
  });
  return data;
};

export default function useApplicationTransitScheduleQuery(
  applicationId: string,
  queryConfig: QueryConfig<TransitScheduleDetails, unknown> = {}
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery<TransitScheduleDetails>(
    [ApplicationQueryKeysEnum.TRANSIT_SCHEDULE_DETAILS, applicationId],
    getQuery(
      () => getApplicationTransitScheduleClient(applicationId),
      () => getApplicationTransitSchedule(applicationId)
    ),
    queryConfig
  );
}
