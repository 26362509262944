import { FolderOwnerDateOfDeathUpdateRequest } from '@ibtm/domain';
import moment from 'moment';

import { useMutation } from '@libs/common/v2/api';
import { ALTERNATIVE_DATE_FORMAT } from '@libs/common/v2/configs';
import { getCalendarDate } from '@libs/common/v2/utils';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

interface IProps {
  folderId: string;
  values: Partial<FolderOwnerDateOfDeathUpdateRequest>;
}

function updateDateOfDeath({ folderId, values }: IProps) {
  return API.folder.updateDateOfDeath(
    folderId,
    {
      ...(values as FolderOwnerDateOfDeathUpdateRequest),
      dateOfDeath: values.dateOfDeath
        ? getCalendarDate(moment(values.dateOfDeath).format(ALTERNATIVE_DATE_FORMAT))
        : null
    },
    { ...endpointsConfig.updateDateOfDeath }
  );
}

function useUpdateDateOfDeathMutation() {
  return useMutation(updateDateOfDeath);
}

export default useUpdateDateOfDeathMutation;
