import {
  ColumnTypesEnum,
  ISingleColumn,
  useCreateColumns,
  useEditableInputsStyles,
  useFormV2Context,
  useViewModesV2
} from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';
import { ResourceTypesSelectField } from '@libs/domain/social-commission';

import {
  ForeignPermissionTransportApplicationRecordUpdateRequestExtended,
  SINGLE_PERMIT_MAX_RESOURCE_REQUESTS,
  SINGLE_PERMIT_MIN_RESOURCE_ISSUED,
  SINGLE_PERMIT_MIN_RESOURCE_REQUESTS
} from '../components/SinglePermitTable';

type Column = ISingleColumn<ForeignPermissionTransportApplicationRecordUpdateRequestExtended>;

function useSinglePermitColumns(applicationYear?: number) {
  const { translate } = useDictionaryTranslations();
  const { formMode } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);
  const classes = useEditableInputsStyles();
  const { createColumns } = useCreateColumns<ForeignPermissionTransportApplicationRecordUpdateRequestExtended>({
    pathToTranslate: 'applications:singlePermits.fields',
    isMutableTable: true
  });
  const editableConfig: Partial<Column> = {
    isCreatable: true,
    isEditable: true
  };

  const hasResourceNameError = (error: any) => {
    return !!error?.nameKey;
  };

  const getResourceNameErrorMessage = (error: any) => {
    return error?.nameKey?.message;
  };

  const columns = createColumns([
    {
      id: 'resourceType',
      header: 'nameKey',
      accessor: 'resourceType.nameKey',
      type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_NAME,
      customEdit: (
        _inputMode,
        original: ForeignPermissionTransportApplicationRecordUpdateRequestExtended,
        inputName
      ) => {
        const parsedInputName = inputName.replace('.nameKey', '');
        return (
          <ResourceTypesSelectField
            fieldName={parsedInputName}
            className={classes.select}
            transitTypeKeyIn={[DomainDictionaryEntry.RESOURCE_TYPE_TRANSIT_TYPE.PASSENGER]}
            yearFilter={applicationYear}
            typeKeyFilter={{ values: [DomainDictionaryEntry.RESOURCE_FORM_TYPE.SINGLE_PERMIT] }}
            hasError={hasResourceNameError}
            getErrorMessage={getResourceNameErrorMessage}
            initialValue={{
              id: original?.resourceType?.id,
              value: original?.resourceType?.nameKey,
              nameKey: original?.resourceType?.nameKey,
              name: translate(DomainDictionaryEnum.RESOURCE_FORM_NAME, original?.resourceType?.nameKey)
            }}
            hasErrorTooltip
          />
        );
      },
      isEditable: true,
      isCreatable: true,
      isRequired: !viewMode,
      width: 200
    },
    {
      id: 'requested',
      type: ColumnTypesEnum.NUMBER,
      defaultValue: 1,
      mutableTableInputProps: {
        min: SINGLE_PERMIT_MIN_RESOURCE_REQUESTS,
        max: SINGLE_PERMIT_MAX_RESOURCE_REQUESTS
      },
      isRequired: !viewMode,
      ...editableConfig
    },
    {
      id: 'issued',
      type: ColumnTypesEnum.NUMBER,
      defaultValue: SINGLE_PERMIT_MIN_RESOURCE_ISSUED
    }
  ]);

  return { columns };
}
export default useSinglePermitColumns;
