export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  updateAddress: { translationKey: 'adresses:api.updateAddress' },
  updateAddresses: { translationKey: 'adresses:api.updateAddresses' },

  deleteAssociationSubject: { translationKey: 'associations:api.deleteAssociationSubject' },

  updateAssociation: { translationKey: 'folder:api.updateAssociation' },
  createInsurancePolicy: { translationKey: 'folder:api.createInsurancePolicy' },
  deleteInsurancePolicy: { translationKey: 'folder:api.deleteInsurancePolicy' },
  updateInsurancePolicy: { translationKey: 'folder:api.updateInsurancePolicy' },
  transferFolderLimit: { translationKey: 'folder:api.transferFolderLimit' },
  updateFolderLimit: { translationKey: 'folder:api.updateFolderLimit' },
  createFolderLock: { translationKey: 'folder:api.createFolderLock' },
  removeFolderLock: { translationKey: 'folder:api.removeFolderLock' },
  updateFolderLock: { translationKey: 'folder:api.updateFolderLock' },
  updateDateOfDeath: { translationKey: 'folder:api.updateDateOfDeath' },
  addSubjectsToPartnership: { translationKey: 'folder:api.addSubjectsToPartnership' },
  updateSubjectContactAddresses: { translationKey: 'subject:api.updateSubjectContactAddresses' },
  createSubjectReputationRequest: { translationKey: 'subject:api.createSubjectReputationRequest' },
  getSubjectAddressesHistoryPage: { translationKey: 'subject:api.getSubjectAddressesHistoryPage' },
  getSubjectFolderPermissions: { translationKey: 'subject:api.getSubjectFolderPermissions' },
  getSubjectHistoryPage: { translationKey: 'subject:api.getSubjectHistoryPage' },
  getFolderHistoryDetailsPage: { translationKey: 'subject:api.getFolderHistoryDetailsPage' },
  getSubjectReputationRequest: { translationKey: 'subject:api.getSubjectReputationRequest' },
  updateSubject: { translationKey: 'subject:api.updateSubject' },

  createTravelForm: { translationKey: 'folder:api.createTravelForm' },
  updateTravelForm: { translationKey: 'folder:api.updateTravelForm' },
  updateFolderStatus: { translationKey: 'folder:api.updateFolderStatus' },
  confirmDataMigration: { translationKey: 'folder:api.confirmDataMigration' },
  createFolderNote: { translationKey: 'folder:api.createFolderNote' },
  updateFolderNote: { translationKey: 'folder:api.updateFolderNote' },
  updateSuspended: { translationKey: 'folder:api.updateSuspended' },
  getFolderAddresses: { translationKey: 'folder:api.getFolderAddresses' },
  getFolderDetailsAssociationPerspective: { translationKey: 'folder:api.getFolderDetailsAssociationPerspective' },
  getFolderDetailsEntrepreneurPerspective: { translationKey: 'folder:api.getFolderDetailsEntrepreneurPerspective' },
  getInsurancePoliciesPage: { translationKey: 'folder:api.getInsurancePoliciesPage' },
  getInsurancePoliciesPageForFolder: { translationKey: 'folder:api.getInsurancePoliciesPageForFolder' },
  getFolderLocksDetailsPage: { translationKey: 'folder:api.getFolderLocksDetailsPage' },
  getTravelFormDetailsList: { translationKey: 'folder:api.getTravelFormDetailsList' },
  getEuroClassDetails: { translationKey: 'folder:api.getEuroClassDetails' },
  getProceedingAvailableFormsPage: { translationKey: 'folder:api.getProceedingAvailableFormsPage' },
  createFolder: { translationKey: 'folder:api.createFolder' },
  getFolderEkmtSummary: { translationKey: 'folder:api.getFolderEkmtSummary' },
  getFolderLimitsPage: { translationKey: 'folder:api.getFolderLimitsPage' },
  getFoldersSnapshotPage: { translationKey: 'folder:api.getFoldersSnapshotPage' },
  getActiveVehiclesCount: { translationKey: 'folder:api.getActiveVehiclesCount' },
  getFolderDetails: { translationKey: 'folder:api.getFolderDetails' },
  getReputationRequestResponses: { translationKey: 'folder:api.getReputationRequestResponses' },
  getForeignPermissionSnapshotPage: { translationKey: 'folder:api.getForeignPermissionSnapshotPage' },
  getFolderPermissions: { translationKey: 'folder:api.getFolderPermissions' }
};
