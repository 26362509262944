import { DepotSearchFilter } from '@ibtm/domain';

import { SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { TAmountRange } from '@libs/common/v2/utils';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { IDepotsListClient } from '../../model';

const useDepotsColumns = () => {
  const { createColumns } = useCreateColumns<IDepotsListClient>({
    pathToTranslate: 'resource:fields'
  });

  const mappedSortFields: TableSortMapper<IDepotsListClient> = {
    type: 'typeKey'
  };

  const mappedFilterFields: TableFilterMapper<IDepotsListClient, DepotSearchFilter> = {
    type: (types: SelectOption<string>[]) => ({ typeKeyIn: types.map(type => type.value) }),
    name: (name: string) => ({ nameContains: name }),
    amount: (amountRange: TAmountRange) => ({
      amountGreaterThanOrEqual: amountRange.min,
      amountLessThanOrEqual: amountRange.max
    })
  };

  const columns = createColumns([
    {
      id: 'type',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.DEPOT_TYPE
    },
    {
      id: 'name',
      header: 'number',
      type: 'TEXT'
    },
    {
      id: 'amount',
      type: 'NUMBER_RANGE'
    }
  ]);

  return { columns, mappedFilterFields, mappedSortFields };
};

export default useDepotsColumns;
