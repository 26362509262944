import {
  DriverDetails as DriverDetailsClient,
  DriverUpdateRequest as DriverUpdateRequestClient
} from '@ibtm/client-domain';
import { DriverDetails, DriversApiCreateDriverRequest, DriverUpdateRequest } from '@ibtm/domain';
import { boolean as YupBoolean, date as YupDate, object as YupObject, string as YupString } from 'yup';

import { TFunction } from '@libs/common';
import { convertCalendarDate, getCalendarDate } from '@libs/common/v2/utils';

import {
  getApplicationFlags,
  getApplicationFlagsClient
} from '@libs/domain/application/api/queries/useApplicationFlagsQuery';
import { IDriverForm } from '@libs/domain/drivers/model';

export const validation = () =>
  YupObject({
    name: YupString().required(),
    surname: YupString().required(),
    birthDate: YupDate().nullable().required(),
    birthPlace: YupObject().nullable().required(),
    citizenship: YupObject().nullable().required(),
    insurancePayerNIP: YupString().length(10).required(),
    employmentDocumentKey: YupObject().nullable().required(),
    driverCertificateValidityPeriodKey: YupObject().nullable().required(),
    identityCard: YupObject({
      typeKey: YupObject().nullable().required(),
      issuingCountryKey: YupObject().nullable().required(),
      seriesNumber: YupString().required(),
      issuingDate: YupDate().nullable().required(),
      expirationDate: YupDate().nullable().required()
    }),
    driverLicense: YupObject({
      indefinite: YupBoolean(),
      qualifications: YupBoolean(),
      issuingCountryKey: YupObject().nullable().required(),
      seriesNumber: YupString().nullable().required(),
      issuingDate: YupDate().nullable().required(),
      expirationDate: YupDate().nullable().when('indefinite', {
        is: false,
        then: YupDate().nullable().required()
      }),
      code: YupObject().nullable().when('qualifications', {
        is: true,
        then: YupObject().nullable().required()
      }),
      expiryDateCompetenceCard: YupDate().nullable()
    })
  });

export const defaultValues = {
  driverLicense: {
    indefinite: false,
    qualifications: false
  }
};

export const parseDriverDetailsResults = (data: DriverDetails | DriverDetailsClient) => ({
  id: data.id,
  version: data.version,
  name: data.name,
  surname: data.surname,
  birthDate: convertCalendarDate(data.birthDate),
  birthPlace: data.birthPlace,
  citizenship: data.citizenship,
  insurancePayerNIP: data.insurancePayerNip,
  driverCertificateValidityPeriodKey: data.driverCertificateValidityPeriodKey,
  periodOfIssuing: null,
  employmentDocumentKey: data.employmentDocumentKey,
  driverLicense: {
    seriesNumber: data.driverLicense.seriesNumber,
    expirationDate: convertCalendarDate(data.driverLicense.expirationDate),
    issuingDate: convertCalendarDate(data.driverLicense.issuingDate),
    issuingCountryKey: data.driverLicense.issuingCountryKey,
    issuingAuthority: data.driverLicense.issuingAuthority,
    issuingPlaceKey: null,
    indefinite: !data.driverLicense.expirationDate,
    qualifications: !!data.competenceCodeKey,
    code: data.competenceCodeKey,
    expiryDateCompetenceCard: convertCalendarDate(data.expiryDateCompetenceCard)
  },
  identityCard: {
    typeKey: data.identityCard.typeKey,
    seriesNumber: data.identityCard.seriesNumber,
    issuingDate: convertCalendarDate(data.identityCard.issuingDate),
    expirationDate: convertCalendarDate(data.identityCard.expirationDate),
    issuingCountryKey: data.identityCard.issuingCountryKey,
    issuingAuthority: data.identityCard.issuingAuthority,
    issuingPlaceKey: null
  },
  created: data.created,
  linkedDriverCreated: data.linkedDriverCreated
});

export const parseDriverData = (applicationId: string, formData: IDriverForm): DriversApiCreateDriverRequest => ({
  driverCreateRequest: {
    applicationId,
    driverLicense: {
      issuingDate: getCalendarDate(formData.driverLicense.issuingDate),
      seriesNumber: formData.driverLicense.seriesNumber,
      typeKey: 'ibtm-domain.identity-document.driver-license',
      expirationDate: getCalendarDate(formData.driverLicense.expirationDate),
      issuingAuthority: formData.driverLicense.issuingAuthority.value,
      issuingCountryKey: formData.driverLicense.issuingCountryKey.value
    },
    identityDocument: {
      issuingDate: getCalendarDate(formData.identityCard.issuingDate),
      seriesNumber: formData.identityCard.seriesNumber,
      typeKey: formData.identityCard.typeKey.value,
      expirationDate: getCalendarDate(formData.identityCard.expirationDate),
      issuingAuthority: formData.identityCard.issuingAuthority.value,
      issuingCountryKey: formData.identityCard.issuingCountryKey.value
    },
    personalData: {
      birthDate: getCalendarDate(formData.birthDate),
      birthPlace: formData.birthPlace?.value,
      citizenship: formData.citizenship?.value,
      name: formData.name,
      surname: formData.surname
    },
    competenceCodeKey: formData.driverLicense.qualifications ? formData.driverLicense.code.value : null,
    expiryDateCompetenceCard: formData.driverLicense.qualifications
      ? getCalendarDate(formData.driverLicense.expiryDateCompetenceCard)
      : null,
    employmentDocumentKey: formData.employmentDocumentKey.value,
    driverCertificateValidityPeriodKey: formData.driverCertificateValidityPeriodKey.value,
    insurancePayerNip: formData.insurancePayerNIP
  }
});
export const parseFormToDriverUpdateRequest = (
  formData: IDriverForm
): DriverUpdateRequest & DriverUpdateRequestClient => ({
  applicationId: formData.applicationId,
  version: formData.version,
  driverLicense: {
    issuingDate: getCalendarDate(formData.driverLicense.issuingDate),
    seriesNumber: formData.driverLicense.seriesNumber,
    typeKey: 'ibtm-domain.identity-document.driver-license',
    expirationDate: getCalendarDate(formData.driverLicense.expirationDate),
    issuingAuthority: formData.driverLicense.issuingAuthority.value,
    issuingCountryKey: formData.driverLicense.issuingCountryKey.value
  },
  identityDocument: {
    issuingDate: getCalendarDate(formData.identityCard.issuingDate),
    seriesNumber: formData.identityCard.seriesNumber,
    typeKey: formData.identityCard.typeKey.value,
    expirationDate: getCalendarDate(formData.identityCard.expirationDate),
    issuingAuthority: formData.identityCard.issuingAuthority.value,
    issuingCountryKey: formData.identityCard.issuingCountryKey.value
  },
  personalData: {
    birthDate: getCalendarDate(formData.birthDate),
    birthPlace: formData.birthPlace?.value,
    citizenship: formData.citizenship?.value,
    name: formData.name,
    surname: formData.surname,
    version: formData.version
  },
  competenceCodeKey: formData.driverLicense.qualifications ? formData.driverLicense.code.value : null,
  expiryDateCompetenceCard: formData.driverLicense.qualifications
    ? getCalendarDate(formData.driverLicense.expiryDateCompetenceCard)
    : null,
  employmentDocumentKey: formData.employmentDocumentKey.value,
  driverCertificateValidityPeriodKey: formData.driverCertificateValidityPeriodKey.value,
  insurancePayerNip: formData.insurancePayerNIP
});

export const getFilterForTable = (documentSeriesNumber: string, dateOfBirth: string) => {
  return {
    initialState: {
      filters: [
        {
          id: 'identityCardSeriesNumber',
          value: documentSeriesNumber
        },
        {
          id: 'birthDate',
          value: { from: dateOfBirth, to: dateOfBirth }
        }
      ]
    }
  };
};

export const handleAfterAddCheckFlags = async (
  applicationId: string,
  getQuery: <T, K>(clientPortal: T, operatorPortal: K) => T | K,
  showErrorSnackbar: (message: string) => void,
  t: TFunction
) => {
  const response = await getQuery(
    () => getApplicationFlagsClient(applicationId),
    () => getApplicationFlags(applicationId)
  )();
  if (response?.isDriverCurrentlyProceeding) {
    showErrorSnackbar(t('drivers:messages.driverIsProceedingInDiffrentApplication'));
  }
};
