import { FolderDetails as FolderDetailsClient } from '@ibtm/client-domain';
import {
  FolderDetailsExtended,
  FolderSnapshot,
  SubjectAddressDetails,
  SubjectDetailsExtended,
  SubjectPartnershipPartnerSnapshot
} from '@ibtm/domain';
import moment from 'moment';

import { i18n } from '@libs/common';
import { ALTERNATIVE_DATE_TIME_FORMAT, FormMode, IPropsDetail } from '@libs/common/v2';

import { stringifyAddress } from '../../address';
import DomainDictionaryEntry from '../../config/DomainDictionaryEntry';
import { EntrepreneurDetailsSnapshot, FolderFormDataAddressTypeEnum } from '../model';

const getDetailsBreadcrumb = (formMode: FormMode, number: string, id: string) => {
  const firstBreadcrumb = { text: i18n.t('folder:list.title'), to: '/folders' };
  const getSecondBreadcrumb = () => {
    if (formMode === FormMode.CREATE) {
      return { text: i18n.t('folder:newFolder') };
    }
    return number
      ? { text: i18n.t('folder:folderDetails', { number }), to: `/folders/${id}/details` }
      : { text: i18n.t('folder:folderDetails') + i18n.t('emptyMark'), to: `/folders/${id}/details` };
  };

  const thirdBreadcrumb = formMode === FormMode.EDIT ? { text: i18n.t('folder:editFolder') } : null;
  return thirdBreadcrumb
    ? [firstBreadcrumb, getSecondBreadcrumb(), thirdBreadcrumb]
    : [firstBreadcrumb, getSecondBreadcrumb()];
};

export default getDetailsBreadcrumb;

export const getClientHeaderDetails = (number: string): IPropsDetail[] => {
  return [
    {
      isWhiteText: false,
      icon: 'FileIcon',
      title: number || i18n.t('emptyMark')
    }
  ];
};

export const getHeaderDetails = (
  folderType: string,
  number: string,
  modified: string,
  modifiedBy: string
): IPropsDetail[] => {
  return [
    {
      isWhiteText: false,
      icon: 'FolderIcon',
      title: folderType || i18n.t('emptyMark'),
      tooltipTitle: i18n.t('applications:additionalField.folderType')
    },
    {
      isWhiteText: false,
      icon: 'FileIcon',
      title: number || i18n.t('emptyMark'),
      tooltipTitle: i18n.t('applications:additionalField.folderNumber')
    },
    {
      isWhiteText: false,
      icon: 'EditIcon',
      title: `${modified ? moment(modified).format(ALTERNATIVE_DATE_TIME_FORMAT) : i18n.t('emptyMark')} ${
        modifiedBy ?? i18n.t('emptyMark')
      }`
    }
  ];
};

const parseAddressItem = (address: SubjectAddressDetails) => {
  return address
    ? {
        typeKey: { value: address.typeKey },
        ...address,
        voivodeshipKey: address.voivodeshipKey ? { value: address.voivodeshipKey } : null
      }
    : null;
};

export const parseFolderDetailsFormData = (
  folderDetails: FolderDetailsExtended | FolderDetailsClient,
  subjectDetails: SubjectDetailsExtended,
  partnershipPartners: SubjectPartnershipPartnerSnapshot[]
) => ({
  idR: folderDetails.id,
  ...folderDetails,
  typeKey: { value: folderDetails.typeKey },
  subject: {
    ...(subjectDetails || folderDetails.subject),
    subjectTypeKey: subjectDetails?.subjectTypeKey,
    legalFormKey: { value: subjectDetails?.legalFormKey }
  },
  partners: partnershipPartners,
  ...('addresses' in folderDetails && {
    addresses: {
      [FolderFormDataAddressTypeEnum.MAIN]: parseAddressItem(
        folderDetails.addresses?.find(item => item.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.MAIN)
      ),
      [FolderFormDataAddressTypeEnum.CORRESPONDENCE]: parseAddressItem(
        folderDetails.addresses?.find(item => item.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.CORRESPONDENCE)
      ),
      [FolderFormDataAddressTypeEnum.HQ]: parseAddressItem(
        folderDetails.addresses?.find(item => item.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.HQ)
      )
    }
  })
});

export const parseLicenseDurationToString = (duration: string) => {
  return moment.duration(duration).humanize();
};

const parseEntrepreneur = (entrepreneur: FolderSnapshot & EntrepreneurDetailsSnapshot): EntrepreneurDetailsSnapshot => {
  return {
    nip: entrepreneur?.subject?.nip,
    folder: {
      number: entrepreneur?.number
    },
    name: entrepreneur?.subject?.name,
    legalFormKey: entrepreneur?.subject?.legalFormKey,
    address: stringifyAddress(entrepreneur?.primaryAddress),
    pendingProceeding: entrepreneur?.pendingProceeding,
    id: entrepreneur?.id,
    statusKey: entrepreneur?.statusKey,
    ...entrepreneur
  };
};

export const parseEntrepreneurDetailsSnapshot = (element: FolderSnapshot[]): EntrepreneurDetailsSnapshot[] => {
  return element?.map(entrepreneur => parseEntrepreneur(entrepreneur));
};
