import { FormMode } from '@libs/common/v2';

import { useDriverContext } from '../../context';
import { useDriverDialog } from '..';

interface IProps {
  folderId: string;
  subjectNip?: string;
  transferredFoldersIds?: string[];
}

export function useAddDriverAction({ folderId, transferredFoldersIds, subjectNip }: IProps) {
  const { application } = useDriverContext();

  const { open } = useDriverDialog(folderId, transferredFoldersIds);
  const handleOpenDialog = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    open({
      data: {
        application: {
          id: application.id,
          subjectNip
        }
      },
      formMode: FormMode.CREATE
    });
  };

  return { handleOpenDialog };
}
