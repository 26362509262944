import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useValidatorUtil, ValidatorEnums } from '@libs/common/v2';

import { AnonymousSetPasswordPageValues } from '@libs/user/anonymous/AnonymousSetPasswordPage';

const useValidate = (validator: ValidatorEnums): [RegExp, string] => useValidatorUtil(validator);

function useAnonymousSetPasswordValidation() {
  const [t] = useTranslation();
  const validatePassword = useValidate(ValidatorEnums.PASSWORD);

  const anonymousSetPasswordFormValidation = Yup.object<Partial<AnonymousSetPasswordPageValues>>({
    password: Yup.string()
      .required()
      .matches(...validatePassword),
    repeatPassword: Yup.string()
      .required()
      .test('match', t('user:validation.passwordsAreNotIdentical'), function checkIfPasswordsAreTheSame(value) {
        return this.parent.password === value;
      })
  });

  return { anonymousSetPasswordFormValidation };
}

export default useAnonymousSetPasswordValidation;
