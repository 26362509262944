import { useTranslation } from 'react-i18next';
import { every, isString } from 'lodash';

import { Dialog, useForwardedSelectedRowsAction, useIsSmallScreen, useMultipleSelectColumn } from '@libs/common/v2';
import ForwardTableState from '@libs/common/v2/components/table/ForwardTableState';

import { useDomainConfigContext } from '@libs/domain/config';
import { TransportManagersColumnsEnum, useConfirmAddTransportManagerDialog } from '@libs/domain/transport-manager';

import { TransportManagersTable } from '.';

interface IProps {
  folderId: string;
  transferredFoldersIds?: string[];
  applicationId: string;
  closeDialog: () => void;
}

function FinancialSecuritySearchDialog({ folderId, transferredFoldersIds, closeDialog, applicationId }: IProps) {
  const [t] = useTranslation();
  const { isOperatorPortal } = useDomainConfigContext();
  const { isSmallScreen } = useIsSmallScreen();

  const { openConfirmAddTransportManagerDialog } = useConfirmAddTransportManagerDialog();
  const selectRowPlugin = useMultipleSelectColumn(() => null, null, null, [], true);
  const { forwardTableStateRef, getSelectedIds, handleDisableAction, isActionDisabled } =
    useForwardedSelectedRowsAction();
  const { NAME, SURNAME, CERTIFICATE_NUMBER } = TransportManagersColumnsEnum;
  return (
    <Dialog
      title={t('transportManager:search.dialogTitle')}
      confirmText={t('action.add')}
      cancelText={t('action.close')}
      onConfirm={() => {
        const selectedIds = getSelectedIds();
        openConfirmAddTransportManagerDialog(
          every(selectedIds, isString) ? (selectedIds as string[]) : [],
          applicationId
        );
      }}
      onCancel={closeDialog}
      dialogSize="large"
      isConfirmButtonDisabled={isActionDisabled}
      isFullScreen={isSmallScreen}
      isOpen
    >
      <TransportManagersTable
        tablePlugins={[selectRowPlugin]}
        visibleColumns={[NAME, SURNAME, CERTIFICATE_NUMBER]}
        isActionColumnEnabled={false}
        queryParams={
          isOperatorPortal
            ? { folderIdIn: folderId ? [folderId, ...(transferredFoldersIds || [])] : [] }
            : { folderIdEq: folderId }
        }
        selectionColumnWidth={48}
        isHeaderHidden
        isHiddenSelectRowHeader
      >
        <ForwardTableState ref={forwardTableStateRef} setState={handleDisableAction} />
      </TransportManagersTable>
    </Dialog>
  );
}

export default FinancialSecuritySearchDialog;
