import { useMemo } from 'react';
import { isNil } from 'lodash';

import { GridLayout } from '@libs/common/v2';

import { DomainUIElementEnum } from '@libs/domain/config';
import {
  TransportManagerFromViolation,
  TransportManagersActionButtonsEnum,
  TransportManagersColumnsEnum,
  TransportManagersTable
} from '@libs/domain/transport-manager';

function TransportManagerTab({ transportManagerId }: { transportManagerId: string }) {
  const hasNoTableData = useMemo(() => isNil(transportManagerId), [transportManagerId]);

  return (
    <GridLayout itemProps={{ xs: 12 }}>
      <TransportManagersTable
        visibleColumns={[
          TransportManagersColumnsEnum.NAME,
          TransportManagersColumnsEnum.SURNAME,
          TransportManagersColumnsEnum.CERTIFICATE_NUMBER,
          TransportManagersColumnsEnum.PERIOD_OF_VALIDITY,
          TransportManagersColumnsEnum.REPUTATION_KEY,
          TransportManagersColumnsEnum.MANAGEMENT_PERIOD,
          TransportManagersColumnsEnum.TYPE_KEY
        ]}
        queryParams={{
          idIn: transportManagerId && [transportManagerId]
        }}
        tableData={hasNoTableData && []}
        visibleActionButtons={[TransportManagersActionButtonsEnum.PREVIEW]}
        permissionsKeysObject={{
          EDIT: DomainUIElementEnum.FOLDER_TRANSPORT_MANAGER_EDIT_BUTTON,
          EXPIRE: DomainUIElementEnum.FOLDER_TRANSPORT_MANAGER_EXPIRE_BUTTON,
          RESTORE: DomainUIElementEnum.FOLDER_TRANSPORT_MANAGER_RESTORE_BUTTON,
          PREVIEW: DomainUIElementEnum.FOLDER_TRANSPORT_MANAGER_VIEW
        }}
      />
      <TransportManagerFromViolation />
    </GridLayout>
  );
}

export default TransportManagerTab;
