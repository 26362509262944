import { QueryConfig, useQuery } from 'react-query';
import { DefaultApiGetTranslationDetailsRequest } from '@avispon/dictionary';
import { DictionaryEntryDetailsList } from '@avispon/dictionary/dist/models';
import { AxiosRequestConfig } from 'axios';

import { API as DictionaryApi, DictionaryQueryKeysEnum } from '@libs/dictionary';

import { endpointsConfig } from '../endpoints-config';

const getDictionaryTranslation = async (_, params: DefaultApiGetTranslationDetailsRequest) => {
  const { data } = await DictionaryApi.dictionary.getTranslationDetails(params, {
    ...endpointsConfig.getTranslationDetails
  } as AxiosRequestConfig);
  return data;
};

export default function useDictionaryTranslationDetailsQuery(
  params: DefaultApiGetTranslationDetailsRequest,
  config?: QueryConfig<DictionaryEntryDetailsList>
) {
  return useQuery<DictionaryEntryDetailsList>(
    [DictionaryQueryKeysEnum.DICTIONARY_TRANSLATION_DETAILS, params],
    getDictionaryTranslation,
    {
      enabled: Boolean(params.dictionary),
      ...config
    }
  );
}
