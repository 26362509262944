import React from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Theme } from '@libs/common/v2/theme';

interface IProps {
  isLoading?: boolean;
  children?: React.ReactNode;
  className?: string;
  size?: string | number;
  isAbsolute?: boolean;
}

function LoaderCircular({ children, isAbsolute = true, isLoading, className, size = 40 }: IProps) {
  const classes = useStyles({ isAbsolute, size });

  return isLoading ? (
    <div className={clsx(classes.loader, className)}>
      <CircularProgress size={size} />
    </div>
  ) : (
    <>{children}</>
  );
}

const useStyles = makeStyles<Theme, { isAbsolute: boolean; size: number | string }>(theme => ({
  loader: {
    position: ({ isAbsolute }) => (isAbsolute ? 'absolute' : 'initial'),
    transform: ({ isAbsolute }) => (isAbsolute ? 'translate(-50%, -50%)' : 'unset'),
    top: '50%',
    left: '50%',
    borderRadius: 8,
    zIndex: 100,
    '& svg': {
      color: theme.palette.blue[500]
    }
  }
}));

export default LoaderCircular;
