import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressDetails } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import { GridLayout, Section, Value } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { parsePostCode } from '../../address';
import { CountryEnum } from '../../exploitation-base';

import { PartnerApplicantsTable } from '.';

interface IProps {
  applicationId?: string;
  subjectId?: string;
  applicationStatusKey?: string;
}

const getLabel = partialTranslate('partnerApplicants:field');

const AddressFieldsPoland = ({ address }: { address: AddressDetails }) => {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  return (
    <GridLayout itemProps={{ xs: 6, md: 4 }}>
      <Value value={address ? address?.city : t('emptyMark')} label={getLabel('city')} />
      <Value value={address ? parsePostCode(address?.postCode) : t('emptyMark')} label={getLabel('postCode')} />
      <Value
        value={address ? translate(DomainDictionaryEnum.VOIVODESHIP, address?.voivodeshipKey) : t('emptyMark')}
        label={getLabel('voivodeshipKey')}
      />
      <Value value={address ? address?.street : t('emptyMark')} label={getLabel('street')} />
      <Value value={address ? address?.propertyNumber : t('emptyMark')} label={getLabel('propertyNumber')} />
      <Value value={address ? address?.apartmentNumber : t('emptyMark')} label={getLabel('apartmentNumber')} />
    </GridLayout>
  );
};
const AddressFieldsForeign = ({ address }: { address: AddressDetails }) => {
  const [t] = useTranslation();
  return (
    <GridLayout itemProps={{ xs: 6, md: 4 }}>
      <Value value={address ? address?.firstLine : t('emptyMark')} label={getLabel('firstLine')} />
      <Value value={address ? address?.secondLine : t('emptyMark')} label={getLabel('secondLine')} />
      <Value value={address ? address?.postCode : t('emptyMark')} label={getLabel('pnu')} />
      <Value value={address ? address?.city : t('emptyMark')} label={getLabel('city')} />
    </GridLayout>
  );
};

function PartnerApplicantsList({ applicationId, subjectId, applicationStatusKey }: IProps) {
  const [t] = useTranslation();
  const [hqAddress, setHqAddress] = useState<AddressDetails>(null);
  const [mailingAddress, setMailingAddress] = useState<AddressDetails>(null);
  const isHqAddressPoland = hqAddress?.countryCodeKey === CountryEnum.POLAND || !hqAddress?.countryCodeKey;
  const isMailingAddressPoland =
    mailingAddress?.countryCodeKey === CountryEnum.POLAND || !mailingAddress?.countryCodeKey;

  return (
    <>
      <PartnerApplicantsTable
        applicationId={applicationId}
        subjectId={subjectId}
        setHqAddress={setHqAddress}
        setMailingAddress={setMailingAddress}
        applicationStatusKey={applicationStatusKey}
      />

      <Section isCollapsable title={t('partnerApplicants:section.address')}>
        <GridLayout itemProps={{ xs: 12 }}>
          <Section isModalSection title={t('partnerApplicants:field.registeredAddress')}>
            {isHqAddressPoland ? (
              <AddressFieldsPoland address={hqAddress} />
            ) : (
              <AddressFieldsForeign address={hqAddress} />
            )}
          </Section>
          <Section isModalSection title={t('partnerApplicants:field.mailingAddress')}>
            {isMailingAddressPoland ? (
              <AddressFieldsPoland address={mailingAddress} />
            ) : (
              <AddressFieldsForeign address={mailingAddress} />
            )}
          </Section>
        </GridLayout>
      </Section>
    </>
  );
}

export default PartnerApplicantsList;
