import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { Button } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import { DomainUIElementEnum } from '../../../../config';
import { PetitionsTypeEnum } from '../../common';

interface IProps {
  tab: PetitionsTypeEnum;
  tabChange: (tab: PetitionsTypeEnum) => void;
}

function PetitionsRadioButtons({ tab, tabChange }: IProps) {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.buttonsWrapper}>
      <Button
        label={t('foreignPermits:tabs.disposablePermits.title')}
        onClick={() => tabChange(PetitionsTypeEnum.SINGLE)}
        className={tab === PetitionsTypeEnum.SINGLE && classes.buttonActive}
        actionKey={DomainUIElementEnum.SOCIAL_COMMITTEE_SINGLE_PERMIT_APPLICATION}
        isNoMargin
      />
      <Button
        label={t('foreignPermits:tabs.ekmt.title')}
        onClick={() => tabChange(PetitionsTypeEnum.EKMT)}
        className={tab === PetitionsTypeEnum.EKMT && classes.buttonActive}
        actionKey={DomainUIElementEnum.SOCIAL_COMMITTEE_EKMT_APPLICATION}
        isNoMargin
      />
    </div>
  );
}
const useStyles = makeStyles<Theme>(theme => ({
  buttonsWrapper: {
    margin: '1rem 0',
    marginLeft: 8,
    '& .MuiButton-root': {
      color: theme.palette.grey[700],
      height: 'auto',
      backgroundColor: 'transparent',
      borderRadius: theme.borderRadiusBase * 0.75,
      boxShadow: important('none'),
      borderBottom: '2px solid transparent',
      '&:hover': {
        backgroundColor: theme.palette.grey[200]
      }
    }
  },
  buttonActive: {
    color: important(theme.palette.blue[800]),
    borderRadius: important(0),
    backgroundColor: important(theme.palette.blue[50]),
    borderColor: important(theme.palette.blue[800])
  }
}));

export default PetitionsRadioButtons;
