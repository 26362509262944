import { AttachmentVerificationListUpdateRequest as AttachmentVerificationListUpdateRequestClient } from '@ibtm/client-domain';
import { AttachmentVerificationListUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

function addComment(formData: {
  applicationId: string;
  attachmentVerificationListUpdateRequest: AttachmentVerificationListUpdateRequest;
}) {
  return API.applicationAttachments.updateApplicationAttachmentsVerificationList(
    formData.applicationId,
    formData.attachmentVerificationListUpdateRequest
  );
}

function addCommentClient(formData: {
  applicationId: string;
  attachmentVerificationListUpdateRequest: AttachmentVerificationListUpdateRequestClient;
}) {
  return API.client.applicationAttachments.updateApplicationAttachmentsVerificationList(
    '',
    '',
    formData.applicationId,
    formData.attachmentVerificationListUpdateRequest
  );
}

function useAddCommentMutation() {
  const { getQuery } = useGetApiQueryByPerspective();

  return useMutation(getQuery(addCommentClient, addComment));
}

export default useAddCommentMutation;
