import React from 'react';
import { Row } from 'react-table';
import { TableCell as MuiTableCell, TableRow as MuiTableRow, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _ from 'lodash';

import {
  ITableAdapter,
  ITableContext,
  TableEditInput,
  TableEvent,
  useFormV2Context,
  useTableEventsContext
} from '@libs/common/v2';
import { contrastYellow, Theme, useTheme } from '@libs/common/v2/theme';
import { hexToRgba, important } from '@libs/common/v2/utils';

import { useRerenderOnTableEvent, useTableAdapter, useTableContext } from '../hooks';
import { ISpecialColumnEnum, TableClassNames } from '../model';
import { getBodyCellStyles, getStickyLeftStyle, getStickyRightStyle } from '../utils';

import TableActions from './TableActions';
import TableCellContainer from './TableCellContainer';

interface IProps<T extends Record<string, any>, K extends Record<string, any> = T> {
  rowActions?: (
    row: Row<T>,
    table: { tableInstance: ITableAdapter<T, K>; tableContext: ITableContext<T, K> }
  ) => React.ReactNode;
  rowStyle?: React.CSSProperties;
  cellStyle?: React.CSSProperties;
  row: Row<T>;
  onRowClick?: (params: { row: Row<T>; tableInstance: ITableAdapter<T, K>; tableContext: ITableContext<T, K> }) => void;
  disabledRowTooltipText?: string;
  isTransparent?: boolean;
  isDisabled?: boolean;
  /**
   * Funkcja pozwalająca na przewinięcie tabeli do wskazanej kolumny
   * @param columnName Nazwa kolumny
   */
  scrollToColumn?: (columnName: string) => void;
  extraBottomPadding?: boolean;
  setLastClickedRow?: (id: string) => void;
  isRowLastCLicked?: boolean;
  tableInstance: ReturnType<typeof useTableAdapter>;
  tableContext: ReturnType<typeof useTableContext>;
}

function TableRow<T extends Record<string, any> = any>({
  rowActions,
  row,
  onRowClick,
  rowStyle,
  cellStyle,
  disabledRowTooltipText,
  isTransparent,
  isDisabled,
  scrollToColumn,
  extraBottomPadding,
  setLastClickedRow,
  isRowLastCLicked,
  tableInstance,
  tableContext
}: IProps<T>) {
  const {
    state: { rowId, additionalCells, isEditing: isRowEditing, isCreating },
    data
  } = tableInstance;

  useRerenderOnTableEvent({ event: TableEvent.RERENDER_CURRENT_EDITED_ROW, isActive: isRowEditing || isCreating });
  const { emitEvent } = useTableEventsContext();
  const { errors, watch } = useFormV2Context();
  const context = useFormV2Context();
  const { actionsColumnWidth, selectionColumnWidth } = tableContext;
  const { contrast } = useTheme();

  const id = tableInstance?.getRowId ? tableInstance.getRowId(row.original) : (row.original?.id as string) || '';

  const isRowToMutate = rowId === id;
  const hasSelectColumn = row.cells.some((cell: any) => cell.column.id === ISpecialColumnEnum.SELECTION);
  const hasActionColumn = row.cells.some((cell: any) => cell.column.id === ISpecialColumnEnum.ACTION);
  const hasErrorTooltip = row.cells.some((cell: any) => cell.column.hasErrorTooltip);

  const activeMode = isRowEditing || isCreating;
  const classes = useStyles({
    isTransparent,
    hasSelectColumn,
    isRowToMutate,
    contrast,
    isDisabled,
    isRowLastCLicked,
    extraBottomPadding,
    isEditing: isRowEditing,
    hasErrorTooltip
  });

  const handleRowClick = (clickedRowToHande: Row<T>) => {
    if (isRowLastCLicked) {
      setLastClickedRow(null);
    } else {
      setLastClickedRow(clickedRowToHande.original.id);
    }

    if (onRowClick) {
      onRowClick({ row: clickedRowToHande, tableInstance, tableContext });
    }
  };

  const renderCell = cell => {
    if (cell?.column?.field) {
      const {
        isEditable,
        isCreatable,
        isDisabled,
        isRequired,
        inputProps: inputPropsWithFunction,
        ...restFieldProps
      } = cell.column.field;
      const { type, isCellAlignRight, hasErrorTooltip } = cell.column;
      const inputProps = _.isFunction(inputPropsWithFunction)
        ? inputPropsWithFunction(row?.original, context)
        : inputPropsWithFunction;

      const editableInputProps = {
        isDisabled:
          typeof isDisabled === 'function'
            ? isDisabled({ row, formValues: watch?.(), tableInstance, tableContext })
            : !!isDisabled,
        isRequired:
          typeof isRequired === 'function'
            ? isRequired({ row, formValues: watch?.(), tableInstance, tableContext })
            : !!isRequired,
        onBlur: (...params) => {
          emitEvent(TableEvent.RERENDER_CURRENT_EDITED_ROW);
          inputProps?.onBlur?.(...params);
        },
        ...inputProps
      };

      // komórka tabeli jest edytowalna
      const editable =
        typeof isEditable === 'function'
          ? isEditable?.({ row, formValues: watch(), tableInstance, tableContext })
          : isEditable;
      if (isRowToMutate && isRowEditing && editable) {
        return (
          <TableCellContainer
            className={classes.cellContainer}
            isViewMode={!activeMode}
            tooltipTitle={cell?.column?.tooltipTitle && cell?.column?.tooltipTitle(row?.original)}
            isTooltipHidden={cell?.column?.isTooltipHidden}
            isCellAlignRight={isCellAlignRight}
          >
            <TableEditInput
              row={row}
              inputProps={editableInputProps}
              {...restFieldProps}
              type={type}
              hasErrorTooltip={hasErrorTooltip}
            />
          </TableCellContainer>
        );
      }

      // komórka tabeli może być uzupełniana przy tworzeniu nowego wiersza
      const creatable =
        typeof isCreatable === 'function'
          ? isCreatable?.({ row, formValues: watch(), tableInstance, tableContext })
          : isCreatable;
      if (isRowToMutate && isCreating && creatable) {
        return (
          <TableCellContainer
            className={classes.cellContainer}
            isViewMode={!activeMode}
            tooltipTitle={cell?.column?.tooltipTitle && cell?.column?.tooltipTitle(row?.original)}
            isTooltipHidden={cell?.column?.isTooltipHidden}
            isCellAlignRight={isCellAlignRight}
          >
            <TableEditInput
              row={row}
              inputProps={editableInputProps}
              {...restFieldProps}
              type={type}
              hasErrorTooltip={hasErrorTooltip}
            />
          </TableCellContainer>
        );
      }
      return (
        <TableCellContainer
          className={classes.cellContainer}
          isViewMode={!activeMode}
          tooltipTitle={cell?.column?.tooltipTitle && cell?.column?.tooltipTitle(row?.original)}
          isTooltipHidden={cell?.column?.isTooltipHidden}
          isCellAlignRight={isCellAlignRight}
        >
          <TableEditInput
            row={row}
            inputProps={editableInputProps}
            {...restFieldProps}
            isViewMode
            type={type}
            hasErrorTooltip={hasErrorTooltip}
          />
        </TableCellContainer>
      );
    }
    return (
      <TableCellContainer
        isViewMode={!activeMode}
        width={cell.column.width}
        tooltipTitle={cell?.column?.tooltipTitle && cell?.column?.tooltipTitle(row?.original)}
        isTooltipHidden={cell?.column?.isTooltipHidden}
        isCellAlignRight={cell?.column?.isCellAlignRight}
      >
        {cell.render('Cell')}
      </TableCellContainer>
    );
  };

  const renderRow = cell => {
    const cellId = tableInstance?.getRowId ? tableInstance.getRowId(row.original) : (row.original?.id as string) || '';

    switch (cell.column.id) {
      case ISpecialColumnEnum.SELECTION:
        return (
          <MuiTableCell
            {...cell.getCellProps(props => getStickyLeftStyle(props, selectionColumnWidth))}
            key={cell.column.id}
            component="div"
            scope="row"
            className={clsx(
              'table-cell-selection',
              classes.stickyCellBodyBackground,
              classes.selectCell,
              TableClassNames.STICKY_LEFT_COLUMN,
              {
                [classes.selected]: row.isSelected,
                [classes.errorStyles]: cellId === rowId && !!Object.values(errors).length,
                [classes.selectionRowMutateBg]: isRowToMutate,
                [classes.hidden]: isRowToMutate,
                [classes.creatingFirstRow]: !data?.length
              }
            )}
            data-testid="table-row-selection"
          >
            {isRowToMutate || !data?.length ? null : cell.render('Cell')}
          </MuiTableCell>
        );
      case ISpecialColumnEnum.ACTION:
        return (
          <React.Fragment key={`${cell.row.id}.${cell.column.id}`}>
            {/* Dodane kolumny przy pomocy plugin'a */}
            {additionalCells?.last?.length &&
              additionalCells.last.map(item => (
                <MuiTableCell
                  key={item.originalId}
                  style={_.merge(cellStyle, { width: 200 }, cell.column.minHeight && { height: cell.column.minHeight })}
                  component="div"
                  scope="row"
                  className={clsx(classes.cellRoot, {
                    [classes.errorStyles]: cellId === rowId && !!Object.values(errors).length
                  })}
                >
                  {renderCell(item)}
                </MuiTableCell>
              ))}
            <MuiTableCell
              {...cell.getCellProps(props => getStickyRightStyle(props, actionsColumnWidth))}
              key={cell.column.id}
              component="div"
              scope="row"
              className={clsx(
                'table-cell-action',
                TableClassNames.STICKY_RIGHT_COLUMN,
                classes.stickyCellBodyBackground,
                classes.actionCell,
                {
                  [classes.selected]: row.isSelected,
                  [classes.errorStyles]: cellId === rowId && !!Object.values(errors ?? {}).length,
                  [classes.selectionRowMutateBg]: isRowToMutate
                }
              )}
            >
              <TableActions
                tableInstance={tableInstance}
                tableContext={tableContext}
                rowActions={rowActions as any}
                row={row}
                scrollToColumn={scrollToColumn}
              />
            </MuiTableCell>
          </React.Fragment>
        );
      default:
        return (
          <MuiTableCell
            {...cell.getCellProps(getBodyCellStyles)}
            key={cell.column.id}
            style={_.merge(
              cellStyle,
              cell.getCellProps(getBodyCellStyles).style,
              cell.column.minHeight && { height: cell.column.minHeight }
            )}
            component="div"
            scope="row"
            className={clsx('table-cell', classes.cellRoot, {
              [classes.errorStyles]: cellId === rowId && !!Object.values(errors || {}).length
            })}
            data-testid={`tableCell.${rowId}.${cell.column.id}`}
          >
            {renderCell(cell)}
          </MuiTableCell>
        );
    }
  };

  const rowProps: any = row.getRowProps();

  if (isDisabled) {
    return (
      <Tooltip title={disabledRowTooltipText}>
        <div className={classes.rowWrapper}>
          <MuiTableRow
            {...rowProps}
            style={_.merge(rowStyle, rowProps?.style)}
            component="div"
            role={onRowClick ? 'button' : 'row'}
            tabIndex={onRowClick ? 0 : -1}
            classes={{
              root: clsx(classes.row, {
                [classes.clickable]: Boolean(onRowClick)
              })
            }}
            onKeyPress={({ code, key }) => {
              if (code === 'Space' || key === 'Enter') handleRowClick(row as any);
            }}
            onClick={() => handleRowClick(row as any)}
          >
            {row.cells.map(cell => renderRow(cell))}
            {!hasActionColumn && tableContext.isFilterEnabled && (
              <MuiTableCell
                className={clsx('table-cell-action', classes.withoutActionStyle)}
                component="div"
                scope="row"
              />
            )}
          </MuiTableRow>
        </div>
      </Tooltip>
    );
  }

  return (
    <div className={classes.rowWrapper}>
      <MuiTableRow
        {...rowProps}
        style={_.merge(rowStyle, rowProps?.style)}
        component="div"
        role={onRowClick ? 'button' : 'row'}
        tabIndex={onRowClick ? 0 : -1}
        classes={{
          root: clsx(classes.row, {
            [classes.clickable]: Boolean(onRowClick)
          })
        }}
        onKeyPress={({ code, key }) => {
          if (code === 'Space' || key === 'Enter') handleRowClick(row as any);
        }}
        onClick={() => handleRowClick(row as any)}
      >
        {row.cells.map(cell => renderRow(cell))}
        {!hasActionColumn && tableContext.isFilterEnabled && (
          <MuiTableCell className={clsx('table-cell-action', classes.withoutActionStyle)} component="div" scope="row" />
        )}
      </MuiTableRow>
    </div>
  );
}

const useStyles = makeStyles<
  Theme,
  {
    isTransparent?: boolean;
    hasSelectColumn: boolean;
    isRowToMutate?: boolean;
    contrast: boolean;
    isDisabled?: boolean;
    extraBottomPadding?: boolean;
    isRowLastCLicked?: boolean;
    isEditing?: boolean;
    hasErrorTooltip?: boolean;
  }
>(theme => ({
  rowWrapper: {
    width: 'auto',
    display: 'flex',
    '&:hover': {
      backgroundColor: ({ contrast }) => (contrast ? theme.palette.primary[950] : theme.palette.grey[100]),
      '& .table-cell-action, & .table-cell-selection': {
        backgroundColor: ({ isRowToMutate, contrast, isRowLastCLicked }) => {
          if (!isRowToMutate && !isRowLastCLicked) {
            return contrast ? theme.palette.primary[950] : theme.palette.grey[100];
          }
          return null;
        },
        '&  svg': {
          color: ({ contrast, isRowToMutate }) => !isRowToMutate && contrast && important(theme.palette.white)
        }
      },
      '& .table-cell': {
        color: ({ contrast }) => contrast && theme.palette.white
      },
      '& .field-boolean-value, .field-value': {
        color: ({ contrast, isRowToMutate }) => contrast && !isRowToMutate && theme.palette.white
      },
      '& .MuiAutocomplete-endAdornment': {
        backgroundColor: ({ contrast }) => contrast && 'transparent'
      },
      '& $cellRoot': {
        color: ({ contrast }) => contrast && theme.palette.white
      }
    },
    '& .field-boolean-value, .field-value': {
      color: ({ contrast, isRowToMutate, isRowLastCLicked }) => {
        if (contrast && isRowToMutate) {
          return isRowLastCLicked ? theme.palette.white : theme.palette.grey[50];
        }
        return null;
      }
    }
  },
  row: {
    backgroundColor: ({ isTransparent, isRowToMutate, isRowLastCLicked, contrast }) => {
      if (isRowToMutate || isRowLastCLicked) {
        return contrast ? theme.palette.primary[999] : theme.palette.blue[50];
      }
      return isTransparent ? 'transparent' : 'unset';
    },
    marginBottom: ({ extraBottomPadding }) => extraBottomPadding && '10px',
    paddingLeft: ({ hasSelectColumn, isRowToMutate }) => {
      if (!hasSelectColumn && !isRowToMutate) {
        return 32;
      }
      if (isRowToMutate && !hasSelectColumn) {
        return 30;
      }
      if (hasSelectColumn) {
        return 0;
      }
      return 32;
    },
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    display: important('inline-flex'),
    flex: '1 0 auto',
    alignItems: 'center'
  },
  stickyCellBodyBackground: {
    backgroundColor: theme.palette.grey[50]
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  selected: {
    borderRight: 'none',
    borderLeft: 'none'
  },
  cellContainer: {
    width: '100%',
    padding: '10px 0'
  },
  cellRoot: {
    height: ({ isEditing }) => isEditing && '100%',
    display: 'flex',
    alignItems: 'flex-center !important',
    backgroundColor: ({ isRowToMutate }) => isRowToMutate && theme.palette.blue[50],
    '& .MuiOutlinedInput-root.MuiInputBase-root': {
      backgroundColor: ({ contrast, isRowToMutate }) => isRowToMutate && contrast && theme.palette.grey[50],
      '& input': {
        '&:hover': {
          backgroundColor: ({ contrast, isRowToMutate }) =>
            isRowToMutate && contrast && hexToRgba(theme.palette.blue[50], 0.2)
        }
      },
      '& fieldset': {
        border: ({ contrast, isRowToMutate }) => isRowToMutate && contrast && `1px solid ${theme.palette.grey[50]}`,
        // W trybie kontrastowym powinno być żółte
        color: ({ contrast, isRowToMutate }) => isRowToMutate && contrast && theme.palette.blue[50]
      },
      '&.Mui-disabled': {
        backgroundColor: ({ contrast, isRowToMutate }) => isRowToMutate && contrast && theme.palette.grey[250]
      },
      '& .MuiAutocomplete-endAdornment': {
        '& :hover': {
          backgroundColor: ({ contrast }) => contrast && hexToRgba(theme.palette.blue[50], 0.2)
        }
      }
    },
    overflow: 'hidden',
    '&:first-child': {
      paddingLeft: 14
    },
    '& > *': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    color: ({ isDisabled, isRowLastCLicked, contrast }) => {
      if (isDisabled) {
        return theme.palette.grey[400];
      }
      if (contrast && isRowLastCLicked) {
        return theme.palette.white;
      }
      return theme.palette.grey[700];
    },
    '&:hover': {
      color: ({ contrast }) => contrast && theme.palette.white
    }
  },
  actionCell: {
    zIndex: 99,
    height: '100%',
    backgroundColor: ({ isRowToMutate, isRowLastCLicked, contrast }) => {
      if (isRowToMutate || isRowLastCLicked) {
        return contrast ? theme.palette.primary[999] : theme.palette.blue[50];
      }
      return null;
    },
    '&  svg': {
      color: ({ contrast, isRowLastCLicked }) => contrast && isRowLastCLicked && important(theme.palette.white)
    }
  },
  selectCell: {
    backgroundColor: ({ isRowToMutate, isRowLastCLicked, contrast }) => {
      if (isRowToMutate || isRowLastCLicked) {
        return contrast ? theme.palette.primary[999] : theme.palette.blue[50];
      }
      return null;
    },
    '&  svg': {
      color: ({ contrast, isRowLastCLicked }) => contrast && isRowLastCLicked && important(contrastYellow)
    }
  },
  errorStyles: {
    alignItems: ({ hasErrorTooltip }) => !hasErrorTooltip && 'flex-start !important'
  },
  withoutActionStyle: {
    display: 'flex !important',
    alignItems: 'center',
    ...theme.typography.textXs.medium,
    color: theme.palette.grey[700],
    width: 116
  },
  selectionRowMutateBg: {
    backgroundColor: important(theme.palette.blue[50]),
    '&  svg': {
      color: ({ contrast }) => contrast && important(theme.palette.grey[50])
    }
  },
  hidden: {
    backgroundColor: important('transparent')
  },
  creatingFirstRow: {
    width: important('10px')
  }
}));

export default TableRow;
