import { createContext } from 'react';

import { useSystemNotification } from '@libs/common/v2';

export enum SystemNotificationType {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  INFO = 'INFO'
}

export type TErrorMessage = {
  id: string;
  message: string;
  url?: string;
  type: SystemNotificationType;
};
export type TNewError = Omit<TErrorMessage, 'id'>;

export type TSystemNotificationContext = ReturnType<typeof useSystemNotification>;

export const SystemNotificationContext = createContext<TSystemNotificationContext>({
  snackbarMessages: null,
  addSystemMessages: null,
  clearAllSystemMessages: null,
  deleteSystemMessage: null
});

interface IProps {
  children: React.ReactNode;
  value: TSystemNotificationContext;
}

function SystemNotificationProvider({ children, value }: IProps) {
  return <SystemNotificationContext.Provider value={value}>{children}</SystemNotificationContext.Provider>;
}

export default SystemNotificationProvider;
