import { useEffect, useState } from 'react';
import { ApplicationDetails as ApplicationDetailsClient } from '@ibtm/client-domain';
import { ApplicationDetails, ApplicationFlagDetails } from '@ibtm/domain';

import { WARNING_DEFINITIONS } from '../config/warnings';
import { WarningDefinition, WarningRegistry } from '../models';

import { useChecksRegistry } from '.';

export interface WarningsProps {
  applicationFlags: ApplicationFlagDetails;
  applicationData: ApplicationDetails | ApplicationDetailsClient;
}

export const useAllWarningVisibleForApplication = ({ applicationFlags, applicationData }: WarningsProps) => {
  const [warnings, setWarnings] = useState<WarningDefinition[]>([]);

  const warningsRegistry: WarningRegistry = useChecksRegistry({
    applicationFlags,
    folderId: applicationData?.folder?.id,
    applicationData
  });

  const setAllVisibleWarnings = async () => {
    const warningsOnApplication = WARNING_DEFINITIONS.filter(
      warning =>
        warning?.applicationType?.includes?.(applicationData?.typeKey) ||
        warning?.applicationTypeIncludes?.some(type => applicationData?.typeKey?.includes?.(type))
    );
    const visibleWarnings = await Promise.all(
      warningsOnApplication?.map(async warning => {
        const warningVisible = await warningsRegistry?.[warning.warning.checkKey]?.();
        return warningVisible ? warning : null;
      })
    );
    setWarnings(visibleWarnings?.filter(warning => warning !== null));
  };

  useEffect(() => {
    setAllVisibleWarnings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationData, applicationFlags]);
  // return resolved warnings or empty array

  return warnings;
};
