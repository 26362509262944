import { useSingleSelectColumn, useTableLayoutProps } from '@libs/common/v2';

import { usePartnerApplicantsQuery } from '../../api';

import usePartnerApplicantsColumns from './usePartnerApplicantsColumns';

export function usePartnerApplicantsTable({ applicationId }: { applicationId: string }) {
  const { columns, mappedFilterFields, mappedSortFields } = usePartnerApplicantsColumns();

  return useTableLayoutProps({
    tableHookQuery: usePartnerApplicantsQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tablePlugins: [useSingleSelectColumn],
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'lp', desc: false }]
        }
      }
    },
    tableHookQueryInitialParams: {
      applicationId
    }
  });
}

export default usePartnerApplicantsTable;
