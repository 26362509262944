import moment from 'moment';

import 'moment/locale/pl';

moment.locale('pl');

export const DATE_FORMAT = 'DD-MM-YYYY';
export const TIME_FORMAT = 'HH:mm';
export const TIME_WITH_SECONDS_FORMAT = 'HH:mm:ss';
export const DATE_TIME_FORMAT = `${DATE_FORMAT}, ${TIME_FORMAT}`;
export const ALTERNATIVE_DATE_FORMAT = 'YYYY-MM-DD';
export const ALTERNATIVE_DATE_TIME_FORMAT = `${ALTERNATIVE_DATE_FORMAT}, ${TIME_FORMAT}`;
export const ALTERNATIVE_DATE_TIME_FORMAT_WITHOUT_COMMA = `${ALTERNATIVE_DATE_FORMAT} ${TIME_FORMAT}`;
export const DATE_TIME_FORMAT_ISO = `${ALTERNATIVE_DATE_FORMAT}T${TIME_WITH_SECONDS_FORMAT}`;
export const DATE_TIME_WITH_COMMA_MASK = '____-__-__, __:__';
export const DATE_STANDARD_LENGTH = 10;
export const DATE_TIME_WITH_WARSAW_OFFSET = 'T22:00:00.000Z';
