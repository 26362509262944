import { useParams } from 'react-router-dom';
import moment from 'moment';

import { useFormV2Context, useFormV2Watch } from '@libs/common/v2';

import { DatetimeField } from '@libs/meta-form/fields';
import { DatetimeFieldProperties, FieldProps } from '@libs/meta-form/models';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { useApplicationDetailsQuery } from '../api';
import { IApplicationDetailsPageParams } from '../model';

export type ApplicationValidFromFieldProps = {
  concernPermissionFieldId?: string;
} & FieldProps<DatetimeFieldProperties>;

function ApplicationValidFromField({ fieldId, concernPermissionFieldId, ...rest }: ApplicationValidFromFieldProps) {
  const { applicationId } = useParams<IApplicationDetailsPageParams>();
  const { data } = useApplicationDetailsQuery(applicationId, {
    enabled: Boolean(applicationId),
    refetchOnMount: false
  });
  const { setValue, getValues } = useFormV2Context();
  const concern = useFormV2Watch<{ value?: { validTo?: string } }>({
    name: concernPermissionFieldId,
    disabled: !concernPermissionFieldId
  });

  const { spoUeExtend, spoOutsideUeExtend, spoTransitRpExtend } = DomainDictionaryEntry.APPLICATION_TYPE;
  const isExtendingPermissionApplication = [spoUeExtend, spoOutsideUeExtend, spoTransitRpExtend].includes(
    data?.typeKey
  );

  const minDate = isExtendingPermissionApplication ? concern?.value?.validTo : null;

  const currentValidFrom = getValues(fieldId);
  if (currentValidFrom && minDate && moment(currentValidFrom).isBefore(moment(minDate))) {
    setValue(fieldId, minDate);
  }

  return <DatetimeField fieldId={fieldId} minDate={minDate} {...rest} />;
}

export default ApplicationValidFromField;
