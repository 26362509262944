import { useTranslation } from 'react-i18next';
import { ColumnTypesEnum, TableFilterMapper, TableSortMapper, useCreateColumns, Value } from '@enigma/fe-ui';
import { LicenseSearchFilter } from '@ibtm/domain';

import { SelectOption } from '@libs/common/v2';
import { getCalendarDate } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '../../../config';
import { LicensesColumnsEnum, LicenseSnapshotClient } from '../../model/license.model';

export function useLicencesTableColumnsV2({
  visibleColumns,
  licenseValidityYearsDictionary
}: {
  visibleColumns: Array<LicensesColumnsEnum>;
  licenseValidityYearsDictionary?:
    | DomainDictionaryEnum.LICENSE_VALIDITY_YEARS
    | DomainDictionaryEnum.LICENSE_VALIDITY_YEARS_PPO
    | DomainDictionaryEnum.LICENSE_VALIDITY_YEARS_TP_OP;
  hiddenStatusFilterKeys?: string[];
}) {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  const { createColumns } = useCreateColumns<LicenseSnapshotClient>();

  const columns = createColumns(
    [
      {
        id: 'applicationNumber',
        accessor: 'applicationNumber',
        header: t('license:fields.applicationNumber'),
        type: ColumnTypesEnum.TEXT,
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'cancellationReason',
        accessor: 'cancellationReason',
        header: t('license:fields.cancellationReason'),
        type: ColumnTypesEnum.DICTIONARY,
        columnFormatter: value => (
          <Value variant="dictionary" dictionaryKey={DomainDictionaryEnum.LICENSE_CANCELLATION_REASON} value={value} />
        ),
        columnXLSXFormatter: value => {
          return translate(DomainDictionaryEnum.LICENSE_CANCELLATION_REASON, value, t('emptyMark'));
        },
        dictionaryKey: DomainDictionaryEnum.LICENSE_CANCELLATION_REASON,
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'cancelledBy',
        accessor: 'cancelledBy',
        header: t('license:fields.cancelledBy'),
        type: ColumnTypesEnum.TEXT,
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'cancellationDate',
        accessor: 'cancellationDate',
        header: t('license:fields.cancellationDate'),
        type: ColumnTypesEnum.DATE,
        filter: 'DateRangePicker',
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'subjectName',
        accessor: 'subjectName',
        header: t('license:fields.subjectName'),
        type: ColumnTypesEnum.TEXT,
        isFilterable: true,
        isSortable: true,
        columnWidth: 200
      },
      {
        id: 'subjectAddress',
        accessor: 'subjectAddress',
        header: t('license:fields.subjectAddress'),
        type: ColumnTypesEnum.TEXT,
        isFilterable: true,
        isSortable: true,
        columnWidth: 200
      },
      {
        id: 'licenseNumber',
        accessor: 'licenseNumber',
        header: t('license:fields.licenseNumber'),
        type: ColumnTypesEnum.TEXT,

        isFilterable: true,
        isSortable: true
      },
      {
        id: 'formNumber',
        accessor: 'formNumber',
        header: t('license:fields.formNumber'),
        type: ColumnTypesEnum.TEXT,
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'printDate',
        accessor: 'printDate',
        header: t('license:fields.printDate'),
        type: ColumnTypesEnum.DATE,
        filter: 'DateRangePicker',
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'licenseValidityYearsKey',
        accessor: 'licenseValidityYearsKey',
        header: t('license:fields.licenseValidityYearsKey'),
        type: ColumnTypesEnum.DICTIONARY,
        columnFormatter: value => (
          <Value
            variant="dictionary"
            dictionaryKey={licenseValidityYearsDictionary || DomainDictionaryEnum.LICENSE_VALIDITY_YEARS}
            value={value}
          />
        ),
        columnXLSXFormatter: value => {
          return translate(
            licenseValidityYearsDictionary || DomainDictionaryEnum.LICENSE_VALIDITY_YEARS,
            value,
            t('emptyMark')
          );
        },
        dictionaryKey: licenseValidityYearsDictionary || DomainDictionaryEnum.LICENSE_VALIDITY_YEARS,
        isFilterable: false,
        isSortable: true
      },
      {
        id: 'validFrom',
        accessor: 'validFrom',
        header: t('license:fields.validFrom'),
        type: ColumnTypesEnum.DATE,
        filter: 'DateRangePicker',
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'validTo',
        accessor: 'validTo',
        header: t('license:fields.validTo'),
        type: ColumnTypesEnum.DATE,
        filter: 'DateRangePicker',
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'dateOfIssueExternal',
        accessor: 'dateOfIssueExternal',
        header: t('license:fields.dateOfIssueExternal'),
        type: ColumnTypesEnum.DATE,
        filter: 'DateRangePicker',
        isFilterable: true,
        isSortable: true
      },
      // {
      //   id: 'signerName',
      // accessor 'signerName',
      // header  t('license:fields.signerName'),
      //   type: ColumnTypesEnum.MULTI_SELECT,

      //   filter: UserFilterV2,
      //   isFilterable: true,
      //   isSortable: true
      // },
      {
        id: 'status',
        accessor: 'status',
        header: t('license:fields.status'),
        type: ColumnTypesEnum.DICTIONARY,
        columnFormatter: value => (
          <Value variant="dictionary" dictionaryKey={DomainDictionaryEnum.PERMISSION_STATUS} value={value} />
        ),
        columnXLSXFormatter: value => {
          return translate(DomainDictionaryEnum.PERMISSION_STATUS, value, t('emptyMark'));
        },
        dictionaryKey: DomainDictionaryEnum.PERMISSION_STATUS,
        // hiddenSelectOptionsValues: hiddenStatusFilterKeys,
        isFilterable: true,
        isSortable: true
      }
    ],
    Object.values(LicensesColumnsEnum).filter(column => !Object.values(visibleColumns).includes(column))
  );

  const mappedFilterFields: TableFilterMapper<LicenseSnapshotClient, LicenseSearchFilter> = {
    applicationNumber: (applicationNumber: string) => ({ applicationNumberContains: applicationNumber }),
    cancellationReason: (cancellationReasons: SelectOption<string>[]) => ({
      cancellationReasonKeyIn: cancellationReasons.map(cancellationReason => cancellationReason.value)
    }),
    cancelledBy: (cancelledBy: string[]) => ({ cancellationByIn: cancelledBy }),
    cancellationDate: ({ from, to }) => ({
      cancellationDateGreaterThanOrEqual: getCalendarDate(from),
      cancellationDateLessThanOrEqual: getCalendarDate(to)
    }),
    subjectName: (subjectName: string) => ({ subjectNameContains: subjectName }),
    subjectAddress: (subjectAddress: string) => ({ subjectAddressContains: subjectAddress }),
    licenseNumber: (licenseNumber: string) => ({ licenseNumberContains: licenseNumber }),
    formNumber: (formNumber: string) => ({ formNumberContains: formNumber }),
    printDate: ({ from, to }) => ({
      printDateGreaterThanOrEqual: getCalendarDate(from),
      printDateLessThanOrEqual: getCalendarDate(to)
    }),
    validFrom: ({ from, to }) => ({
      validFromGreaterThanOrEqual: getCalendarDate(from),
      validFromLessThanOrEqual: getCalendarDate(to)
    }),
    validTo: ({ from, to }) => ({
      validToGreaterThanOrEqual: getCalendarDate(from),
      validToLessThanOrEqual: getCalendarDate(to)
    }),
    dateOfIssueExternal: ({ from, to }) => ({
      dateOfIssueExternalGreaterThanOrEqual: getCalendarDate(from),
      dateOfIssueExternalLessThanOrEqual: getCalendarDate(to)
    }),
    signerName: (signers: SelectOption<string>[]) => ({ signerIn: signers.map(signer => signer.value) }),
    status: (statuses: SelectOption<string>[]) => ({ statusKeyIn: statuses.map(status => status.value) })
  };

  const mappedSortFields: TableSortMapper<LicenseSnapshotClient> = {
    applicationNumber: 'application.number',
    cancellationReason: 'cancellationReasonKey',
    cancelledBy: 'cancelledBy.name',
    subjectName: 'folder.subject.name',
    subjectAddress: 'application.subject.mainAddress.shortAddress',
    licenseNumber: 'number',
    formNumber: 'form.number',
    signerName: 'signer.name',
    status: 'statusKey'
  };

  return {
    columns,
    mappedFilterFields,
    mappedSortFields
  };
}
