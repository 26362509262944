import { ValidatorEnums } from '../../form';

import useValidator from './useValidator';

// Hook - util do tworzenia schematów walidacji
// Yup.string().matches(...useValidatorUtil(ValidatorEnums.USER_FIRST_NAME)),
export default function useValidatorUtil(validator: ValidatorEnums): [RegExp, string] {
  const { regex, message } = useValidator(validator);
  return [new RegExp(regex), message];
}
