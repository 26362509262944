import { usePaginatedQuery } from 'react-query';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { FolderLockListItemClient, parseLockListResults } from '@libs/domain/folder';

import { endpointsConfig } from '../../endpoints-config';
import { FolderQueryKeysEnum } from '../QueryKeysEnum';

export const getLocks = async (_, params) => {
  const { data } = await API.folderLock.getFolderLocksDetailsPage(params, {
    ...endpointsConfig.getFolderLocksDetailsPage
  });
  return {
    ...data,
    content: parseLockListResults(data.content)
  };
};

export default function useLocksQuery(id: string) {
  return usePaginatedQuery<IPaginatedModel<FolderLockListItemClient>>([FolderQueryKeysEnum.LOCK_LIST, id], getLocks);
}
