import { useDialog } from '@libs/common/v2';

import {
  ISubjectDataChangeDialogProps,
  SubjectDataChangeDialog
} from '../components/print-details/dialog/SubjectDataChangeDialog';

export function useSubjectDataChangeDialog() {
  const { openDialog } = useDialog();

  const open = (props: Omit<ISubjectDataChangeDialogProps, 'onClose'>) => {
    return openDialog(({ closeDialog }) => <SubjectDataChangeDialog onClose={closeDialog} {...props} />);
  };

  return { openSubjectDataChangeDialog: open };
}
