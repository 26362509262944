import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryCache } from 'react-query';
import { useSnackbar } from '@enigma/fe-ui';

import { Button, TableButtonDelete, TableLayout } from '@libs/common/v2';

import {
  ExploitationBaseFormData,
  ExploitationBaseInspectionSnapshot,
  ExploitationBaseQueryKeysEnum,
  useBaseInspectionTable,
  useExploitationBaseInspectionDeleteMutation,
  useInspectionDialog
} from '@libs/domain/exploitation-base';

interface IProps {
  inspections: ExploitationBaseInspectionSnapshot[];
  initialExploitationBaseData?: ExploitationBaseFormData;
  exploitationBaseId?: string;
  isFetching?: boolean;
  title: string;
  isAddAndRemoveAvaliable?: boolean;
}

function InspectionTable({
  inspections = [],
  initialExploitationBaseData,
  exploitationBaseId,
  isFetching,
  isAddAndRemoveAvaliable,
  title
}: IProps) {
  const [t] = useTranslation();
  const queryCache = useQueryCache();
  const { showSuccessSnackbar } = useSnackbar();
  const tableProps = useBaseInspectionTable(useMemo(() => inspections, [inspections]));

  const { openDetailsDialog } = useInspectionDialog();
  const { mutate: deleteInspectionBase, isLoading } = useExploitationBaseInspectionDeleteMutation();

  const handleDelete = (id: string) => {
    deleteInspectionBase(
      { exploitationBaseId: id },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('exploitationBase:dialog.addControlBase.deleteSuccess'));
          queryCache.invalidateQueries([ExploitationBaseQueryKeysEnum.EXPLOITATION_BASE, exploitationBaseId]);
        }
      }
    );
  };

  return (
    <TableLayout
      {...tableProps}
      isFetching={isFetching}
      isActionColumnEnabled={isAddAndRemoveAvaliable}
      isFilterEnabled={false}
      isRefreshEnabled={false}
      pageTitle={title}
      isPaginated={inspections.length > 25}
      isSection
      headerActions={
        isAddAndRemoveAvaliable && (
          <Button
            isPrimary
            variant="outlined"
            onClick={() =>
              openDetailsDialog({
                initialExploitationBaseData,
                inspections,
                exploitationBaseId
              })
            }
            label={t('action.add')}
            isNoMargin
          />
        )
      }
      rowActions={row => {
        return (
          isAddAndRemoveAvaliable && (
            <TableButtonDelete onClick={() => handleDelete(row.original.id)} isButtonLoading={isLoading} />
          )
        );
      }}
    />
  );
}

export default InspectionTable;
