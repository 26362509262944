import { useMemo } from 'react';
import { GroupSnapshotExternal } from '@avispon/group/dist/models/group-snapshot-external';

import { useMultipleSelectColumn, useTableLayoutProps } from '@libs/common/v2';

import { useFindGroupQuery } from '@libs/user/api';
import { SelectionRowActionRender } from '@libs/user/components';

import { useGroupsColumns } from './columns';

interface UserPermissionGroupTableParams {
  unitIds: string[];
  data: GroupSnapshotExternal[];
  selectedRows: GroupSnapshotExternal[];
  setSelectedRows: (value: GroupSnapshotExternal[]) => void;
}

function useUserPermissionGroupTable({ unitIds, data, selectedRows, setSelectedRows }: UserPermissionGroupTableParams) {
  const { columns, mappedFilterFields } = useGroupsColumns();
  const selectedData = data?.map(({ id }) => id) ?? [];
  const selectedRowIds = useMemo(
    () => selectedRows.map(({ id }) => id).reduce((acc, key) => ({ ...acc, [key]: true }), {}),
    [selectedRows]
  );

  return useTableLayoutProps({
    tableHookQuery: useFindGroupQuery,
    tableHookOptions: {
      columns,
      tablePlugins: [useMultipleSelectColumn(() => SelectionRowActionRender(setSelectedRows), null, null, [], true)],
      filterConverter: mappedFilterFields,
      getRowId: row => row?.id,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'name', desc: false }],
          selectedRowIds: selectedRowIds as any
        }
      }
    },
    tableHookQueryInitialParams: {
      unitIds,
      selectedData
    }
  });
}

export default useUserPermissionGroupTable;
