import translations from '@libs/translation';
import { InitOptions } from 'i18next';

export const i18nConfig: InitOptions = {
  lng: 'pl',
  fallbackLng: 'pl',
  ns: [
    'app',
    'global',
    'login',
    'alerts',
    'notifications',
    'messages',
    'other',
    'search',
    'validation',
    'tables',
    'associations',
    'administration',
    'dictionary',
    'user',
    'reports',
    'documentTemplates',
    'attachments',
    'applications',
    'processes',
    'foreignPermits',
    'archive',
    'folder',
    'proceeding',
    'suspensions',
    'resource',
    'releaseDocuments',
    'prints',
    'drivers',
    'ezdDocuments',
    'sCertificates',
    'roadTransport',
    'documentsIssued',
    'systemParameters',
    'history',
    'financialSecurity',
    'proxy',
    'payments',
    'partnerApplicants',
    'exploitationBase',
    'transportManager',
    'address',
    'license',
    'permission',
    'document',
    'notes',
    'driverCertificate',
    'vehicle',
    'subject',
    'pricing',
    'attachments',
    'account',
    'group'
  ],
  defaultNS: 'global',
  resources: {
    pl: translations
  }
};
