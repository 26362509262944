import { useMemo } from 'react';
import { useForm, UseFormProps } from 'react-hook-form';

import { FormMode, FormV2Context } from '@libs/common/v2';

interface Props extends UseFormProps<any, any> {
  children: React.ReactNode;
}

function MockFormContext({ children, ...rest }: Props) {
  const form = useForm(rest);

  const values = useMemo(() => ({ formMode: FormMode.CREATE, ...form }), [form]);

  return (
    <FormV2Context.Provider value={values}>
      <div className="p-20 w-1/3" style={{ fontFamily: 'Inter' }}>
        {children}
      </div>
    </FormV2Context.Provider>
  );
}

export default MockFormContext;
