import { partialTranslate } from '@libs/common';

import { AlertDetailsTab, IAlertsTab } from '../model';

function useAlertsTabDefinitions(): Array<IAlertsTab> {
  const getLabel = partialTranslate('alerts:tab');

  return [
    {
      label: getLabel('basicData'),
      value: AlertDetailsTab.ALERT_GENERAL_DATA
    },
    {
      label: getLabel('history'),
      value: AlertDetailsTab.ALERT_HISTORY
    }
  ];
}

export default useAlertsTabDefinitions;
