import { useTranslation } from 'react-i18next';
import { TableOptions } from 'react-table';
import { useSnackbar } from '@enigma/fe-ui';
import { ProceedingSnapshot } from '@ibtm/domain';

import { CamelCasePath, TableLayout, useConfirmDialog, useTableRowActions } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';
import { DomainDictionaryEntry, DomainUIElementEnum } from '@libs/domain/config';

import { ProceedingQueryKeysEnum, useDeleteProceedingMutation } from '../../api';
import { useEndProceedingAction, useProceedingsTable } from '../../hooks';
import { ProceedingsActionsEnum, ProceedingSnapshotClient } from '../../model';
import { parseProceedingsList } from '../../parser';
import { parseProceedingsListPayload } from '../../utils';
import { CreateProceedingButton } from '../buttons';

interface IProps {
  title: string;
  visibleColumns: Array<CamelCasePath<ProceedingSnapshotClient>>;
  visibleActions?: ProceedingsActionsEnum[];
  detailsLink: (proceedingId: string, folderId: string, type: string) => string;
  previousLink: (folderId?: string) => string;
  editLink?: (proceedingId: string, folderId: string, type: string) => string;
  isSection?: boolean;
  isCollapsable?: boolean;
  folderId?: string;
  initialParams?: { [key: string]: any };
  tableOptions?: Partial<TableOptions<any>>;
  showAllFilters?: boolean;
  trimTextFilters?: boolean;
}

function ProceedingsTable({
  title,
  folderId,
  visibleColumns,
  visibleActions = [],
  detailsLink,
  editLink,
  isSection = true,
  isCollapsable = true,
  initialParams,
  tableOptions,
  showAllFilters = false,
  trimTextFilters = false,
  previousLink
}: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();
  const [confirm] = useConfirmDialog();
  const { mutate: deleteProceeding } = useDeleteProceedingMutation();
  const { endProceeding } = useEndProceedingAction();
  const tableProps = useProceedingsTable(visibleColumns, initialParams, tableOptions, showAllFilters, trimTextFilters);

  const isActionVisible = (actionType: ProceedingsActionsEnum) => visibleActions?.includes(actionType);
  const handleDeleteProceeding = (proceeding: ProceedingSnapshotClient) => {
    confirm({
      title: t('proceeding:dialog.deleteProceedingTitle'),
      message: t('proceeding:dialog.deleteProceedingConfirmation', {
        proceedingNumber: proceeding.proceedingNumber,
        interpolation: { escapeValue: false }
      }),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        deleteProceeding(proceeding.id, {
          onSuccess: () => {
            queryCache.invalidateQueries(ProceedingQueryKeysEnum.PROCEEDINGS_LIST);
            showSnackbar('success', t('proceeding:messages.deleteProceedingSuccess'));
            closeDialog();
          },
          onSettled: () => {
            setConfirmLoading(false);
          }
        });
      },
      confirmType: 'danger'
    });
  };
  const { renderTableActions } = useTableRowActions<ProceedingSnapshotClient>([
    {
      id: 'PROCEEDING_VERIFICATION_VIEW_BUTTON',
      label: 'proceeding:action.goToDetails',
      link: ({ original }) => ({
        pathname: detailsLink(original.id, folderId || original.folderId, original.proceedingCategory),
        state: { from: previousLink(folderId || original.folderId) }
      }),
      isHidden: ({ row }) => {
        if (row.original.proceedingCategory === DomainDictionaryEntry.PROCEEDING_TYPE.ADMINISTRATIVE) {
          return true;
        }
        return false;
      },
      icon: 'ArrowIcon'
    },
    {
      id: 'PROCEEDING_ADMINISTRATIVE_VIEW_BUTTON',
      label: 'proceeding:action.goToDetails',
      link: ({ original }) => ({
        pathname: detailsLink(original.id, folderId || original.folderId, original.proceedingCategory),
        state: { from: previousLink(folderId || original.folderId) }
      }),
      isHidden: ({ row }) => {
        if (row.original.proceedingCategory === DomainDictionaryEntry.PROCEEDING_TYPE.VERIFICATION) {
          return true;
        }
        return false;
      },
      icon: 'ArrowIcon'
    },
    {
      id: 'PROCEEDING_VERIFICATION_EDIT_BUTTON',
      label: 'action.edit',
      link: ({ original }) => ({
        pathname: editLink(original.id, folderId || original.folderId, original.proceedingCategory),
        state: { from: previousLink(folderId || original.folderId) }
      }),
      isHidden: ({ row }) => {
        if (row.original.proceedingCategory === DomainDictionaryEntry.PROCEEDING_TYPE.ADMINISTRATIVE) {
          return true;
        }
        return !isActionVisible(ProceedingsActionsEnum.EDIT);
      },
      icon: 'EditIcon'
    },
    {
      id: 'PROCEEDING_ADMINISTRATIVE_EDIT_BUTTON',
      label: 'action.edit',
      link: ({ original }) => ({
        pathname: editLink(original.id, folderId || original.folderId, original.proceedingCategory),
        state: { from: previousLink(folderId || original.folderId) }
      }),
      isHidden: ({ row }) => {
        if (row.original.proceedingCategory === DomainDictionaryEntry.PROCEEDING_TYPE.VERIFICATION) {
          return true;
        }
        return !isActionVisible(ProceedingsActionsEnum.EDIT);
      },
      icon: 'EditIcon'
    },
    {
      id: 'PROCEEDING_CLOSE_BUTTON',
      label: 'proceeding:action.endProceeding',
      onClick: ({ row: { original } }) =>
        endProceeding(original, () => {
          queryCache.invalidateQueries(ProceedingQueryKeysEnum.PROCEEDINGS_LIST);
        }),
      isHidden: ({ row: { original } }) =>
        !(
          original.status === DomainDictionaryEntry.PROCEEDING_STATUS.CONSIDERED &&
          isActionVisible(ProceedingsActionsEnum.END_PROCEEDING)
        ),
      icon: 'CheckedCircleIcon'
    },
    {
      id: 'PROCEEDING_ADMINISTRATIVE_DELETE_BUTTON',
      label: 'action.delete',
      onClick: ({ row: { original } }) => handleDeleteProceeding(original),
      isHidden: ({ row }) => {
        if (row.original.proceedingCategory === DomainDictionaryEntry.PROCEEDING_TYPE.VERIFICATION) {
          return true;
        }
        return !isActionVisible(ProceedingsActionsEnum.DELETE);
      },
      icon: 'TrashIcon'
    },
    {
      id: 'PROCEEDING_VERIFICATION_DELETE_BUTTON',
      label: 'action.delete',
      onClick: ({ row: { original } }) => handleDeleteProceeding(original),
      isHidden: ({ row }) => {
        if (row.original.proceedingCategory === DomainDictionaryEntry.PROCEEDING_TYPE.ADMINISTRATIVE) {
          return true;
        }
        return !isActionVisible(ProceedingsActionsEnum.EDIT);
      },
      icon: 'TrashIcon'
    }
  ]);

  return (
    <TableLayout<ProceedingSnapshotClient, ProceedingSnapshot>
      {...tableProps}
      pageTitle={title}
      xlsxDownload={{
        fileName: title,
        pathToXLSXTranslation: 'proceeding:fields',
        apiRequest: params => {
          const queryParams = parseProceedingsListPayload(params);
          return API.proceeding
            .getProceedingsSnapshotPage(queryParams)
            .then(res => ({ ...res, data: { ...res.data, content: parseProceedingsList(res.data.content) } }));
        }
      }}
      headerActions={
        isActionVisible(ProceedingsActionsEnum.CREATE) && (
          <CreateProceedingButton folderId={folderId} previousLink={previousLink} />
        )
      }
      rowActions={renderTableActions([
        DomainUIElementEnum.PROCEEDING_VERIFICATION_VIEW_BUTTON,
        DomainUIElementEnum.PROCEEDING_VERIFICATION_EDIT_BUTTON,
        DomainUIElementEnum.PROCEEDING_VERIFICATION_DELETE_BUTTON,
        DomainUIElementEnum.PROCEEDING_ADMINISTRATIVE_VIEW_BUTTON,
        DomainUIElementEnum.PROCEEDING_ADMINISTRATIVE_EDIT_BUTTON,
        DomainUIElementEnum.PROCEEDING_ADMINISTRATIVE_DELETE_BUTTON,
        DomainUIElementEnum.PROCEEDING_CLOSE_BUTTON
      ])}
      isSection={isSection}
      isCollapsable={isCollapsable}
      isRefreshEnabled
    />
  );
}

export default ProceedingsTable;
