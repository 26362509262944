import { useTranslation } from 'react-i18next';

import { Button, useRouter } from '@libs/common/v2';

import { NotificationUIElementEnum } from '../../config';

interface IProps {
  isCreateButtonEnabled: boolean;
}

function MyMessagesTableHeaderActions({ isCreateButtonEnabled }: IProps) {
  const [t] = useTranslation();
  const { routes } = useRouter();

  return (
    isCreateButtonEnabled && (
      <Button
        label={t('messages:action.create')}
        link={routes.messageCreate()}
        actionKey={NotificationUIElementEnum.MESSAGE_ADD_BUTTON}
        isPrimary
      />
    )
  );
}

export default MyMessagesTableHeaderActions;
