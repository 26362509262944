import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { TableLayout } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import {
  ClientForeignPermissions,
  parseClientForeignPermissionsList,
  useClientForeignPermissionsTable
} from '@libs/domain/foreign-permission';

import { endpointsConfig } from '../../api/endpoints-config';
import ClientForeignPermissionsRowActions from '../ClientForeignPermissionsRowActions';

interface IProps {
  folderId?: string;
  isSection?: boolean;
  isActionColumnEnabled?: boolean;
  isCollapsable?: boolean;
  associationFolderId?: string;
  isZZFolder?: boolean;
}

function ClientForeignPermissionsTable({
  folderId,
  isSection,
  isActionColumnEnabled,
  isCollapsable,
  associationFolderId,
  isZZFolder
}: IProps) {
  const [t] = useTranslation();
  const tableProps = useClientForeignPermissionsTable({ folderId, associationFolderId, isZZFolder });

  const actionsRowRender = useCallback(
    ({ original }: { original: ClientForeignPermissions }) => <ClientForeignPermissionsRowActions data={original} />,
    []
  );

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('foreignPermits:clientForeignPermissions.listTitle')}
      xlsxDownload={{
        apiRequest: params =>
          API.client.foreignPermissions
            .getForeignPermissionSnapshotPage('', '', params, { ...endpointsConfig.getForeignPermissionSnapshotPage })
            .then(res => ({
              ...res,
              data: { ...res.data, content: parseClientForeignPermissionsList(res.data.content) }
            })),
        fileName: t('foreignPermits:list.title'),
        pathToXLSXTranslation: 'foreignPermits:clientForeignPermissions.fields'
      }}
      rowActions={actionsRowRender}
      isActionColumnEnabled={isActionColumnEnabled}
      isCollapsable={isCollapsable}
      isSection={isSection}
    />
  );
}

export default ClientForeignPermissionsTable;
