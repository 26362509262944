import React from 'react';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Button, ButtonLinkDefinition, IconType, useTableAdapter, useTableContext } from '@libs/common/v2';
import { PaletteOptions } from '@libs/common/v2/theme';

import { UIElementNameEnum } from '@libs/permission';

interface IProps {
  onClick?: React.MouseEventHandler;
  tooltipTitle?: string;
  label?: string;
  icon?: IconType;
  iconColor?: keyof PaletteOptions;
  className?: string;
  classNameWrapper?: string;
  variant?: MuiButtonProps['variant'];
  iconClassName?: string;
  isDisabled?: boolean;
  isPrimary?: boolean;
  isSecondary?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  isFullWidth?: boolean;
  actionKey?: UIElementNameEnum;
  link?: ButtonLinkDefinition;
}
/**
 * Komponent przystosowany do renderowania w header tabeli.
 */
function TableHeaderButton({
  iconClassName,
  icon,
  classNameWrapper,
  tooltipTitle,
  className,
  iconColor = 'white',
  isDisabled,
  variant,
  label,
  onClick,
  isFullWidth = true,
  isLoading: isButtonLoading,
  actionKey,
  link,
  ...forwardProps
}: IProps) {
  const table = useTableAdapter();
  const { isLoading, tableLoading, checkDisabled, isError } = useTableContext();
  const classes = useStyles();

  const loading = isLoading || tableLoading;
  return (
    <Button
      tooltipTitle={tooltipTitle}
      onClick={onClick}
      classNameWrapper={classNameWrapper}
      iconClassName={iconClassName}
      className={clsx(classes.button, className)}
      icon={icon}
      iconHeight={24}
      iconWidth={24}
      actionKey={actionKey}
      isLoading={isButtonLoading}
      disabled={isDisabled || checkDisabled?.(table) || isError || loading}
      iconColor={iconColor}
      label={label}
      variant={variant}
      size="small"
      fullWidth={isFullWidth}
      isNoMargin
      link={link}
      {...forwardProps}
    />
  );
}

const useStyles = makeStyles({
  button: {
    whiteSpace: 'nowrap'
  }
});

export default React.memo(TableHeaderButton);
