import { DriversApiUpdateDriverRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function editDriver(requestData: DriversApiUpdateDriverRequest) {
  return API.drivers.updateDriver(requestData.driverId, requestData.driverUpdateRequest, {
    ...endpointsConfig.updateDriver
  });
}
function editDriverClient(requestData: DriversApiUpdateDriverRequest) {
  return API.client.drivers.updateDriver('', '', requestData.driverId, requestData.driverUpdateRequest, {
    ...endpointsConfig.updateDriver
  });
}

function useEditDriverMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(editDriverClient, editDriver));
}

export default useEditDriverMutation;
