import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AccountsApiChangePasswordRequest, AccountSnapshot, PasswordChangeCommand } from '@avispon/user';
import { useSnackbar } from '@enigma/fe-ui';

import { FormMode, FormV2Context, Page } from '@libs/common/v2';

import { API } from '@libs/user/api';
import AdditionalCodeInput from '@libs/user/components/common/AdditionalCodeInput';
import { ConfigurationContextProvider, SectionNames } from '@libs/user/context';
import { ChangeProfilePasswordValidation } from '@libs/user/models';

import ProfileDetailsContent from './ProfileDetailsContent';
import ProfilePageHeader from './ProfilePageHeader';

interface ProfilePageProps {
  getAuthorizationHeader: () => string;
  changePasswordSchema?: ChangeProfilePasswordValidation;
}

function ProfilePage({ getAuthorizationHeader, changePasswordSchema }: ProfilePageProps) {
  const { showSuccessSnackbar } = useSnackbar();
  const [t] = useTranslation();
  const [showResetPassword, setShowResetPassword] = useState(false);

  const accountData: AccountSnapshot | null = useSelector(({ auth }) => auth.user.accountData);

  const handleChangePassword = (data: PasswordChangeCommand) => {
    const requestData: AccountsApiChangePasswordRequest = {
      authorization: getAuthorizationHeader(),
      body: data
    };
    API.user.accountController
      .changePassword(requestData)
      .then(() => {
        setShowResetPassword(false);
        showSuccessSnackbar(t('user:message.passwordChanged'));
      })
      .catch(() => {});
  };
  const form = useForm<Record<string, any>>({ defaultValues: accountData });

  const formDataProvider = useMemo(() => {
    return { ...form, formMode: FormMode.VIEW };
  }, [form]);

  return (
    <ConfigurationContextProvider
      additionalFields={{ [SectionNames.ORGANIZATION_DATA_SECTION]: [<AdditionalCodeInput key={0} />] }}
    >
      <FormV2Context.Provider value={formDataProvider}>
        <Page
          header={<ProfilePageHeader setShowResetPassword={setShowResetPassword} />}
          content={
            <ProfileDetailsContent
              changePasswordSchema={changePasswordSchema}
              handleChangePassword={handleChangePassword}
              setShowResetPassword={setShowResetPassword}
              showResetPassword={showResetPassword}
            />
          }
        />
      </FormV2Context.Provider>
    </ConfigurationContextProvider>
  );
}

export default ProfilePage;
