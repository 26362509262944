import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormHelperText } from '@mui/material';
import clsx from 'clsx';

import { useInputStyles, useValueStyles, ValueSkeleton } from '@libs/common/v2';
import { useTheme } from '@libs/common/v2/theme';
import { getValue } from '@libs/common/v2/utils';

interface IProps {
  label?: string;
  children?: React.ReactNode;
  value?: React.ReactNode;
  className?: string;
  helperText?: string;
  isLineBreak?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  isWarning?: boolean;
}

function Value({ label, value, isLoading, isLineBreak, className, helperText, isError, isWarning, children }: IProps) {
  const { contrast } = useTheme();
  const classes = useValueStyles({ contrast });
  const inputClasses = useInputStyles({ isError, isWarning });
  const [t] = useTranslation();
  const content = value || children;

  return (
    <div className={className}>
      {isLoading ? (
        <ValueSkeleton />
      ) : (
        <>
          {label && <div className={classes.labelWrapper}>{label && <div>{label}</div>}</div>}
          <div className={clsx('field-value', classes.value, isLineBreak && classes.lineBreak)}>
            {typeof content === 'number' || typeof content === 'number' ? getValue(content) : content || t('emptyMark')}
          </div>
          {helperText && (
            <FormHelperText className={clsx(inputClasses.formHelperText)} error={isError}>
              {helperText}
            </FormHelperText>
          )}
        </>
      )}
    </div>
  );
}

export default memo(Value);
