import { boolean, object, object as YupObject, string } from 'yup';

export const useReleaseDocumentsValidationSchema = () => {
  const ValidationSchemaCreate = YupObject({
    correctionReasonKey: object().required().nullable(),
    recipientName: string().nullable(),
    recipientSurname: string().nullable(),
    zeroing: boolean()
  });

  const ValidationSchemaEdit = YupObject({
    recipientName: string().nullable(),
    recipientSurname: string().nullable()
  });

  return { ValidationSchemaCreate, ValidationSchemaEdit };
};
