import { FolderDetails, FolderSnapshot } from '@ibtm/domain';
import i18n from 'i18next';
import * as Yup from 'yup';

import { FolderDetailsSnapshot, UserDetails } from '@libs/domain/folder';

type ReusedColumns = Pick<FolderDetailsSnapshot, 'id'>;

export interface BorrowFolderSnapshot extends ReusedColumns {
  typeKey: string;
  subject?: {
    name: string;
    nip?: string;
  };
  folder: FolderSnapshot;
  borrowDate: string;
  borrowPurpose: string;
  returnDate: string;
  returnPerson: string;
  archiveIssuer: string;
  borrower: string & UserDetails;
  archiveReceiver: string;
  localizationKey: string;
  version: number;
}

export type BorrowFolderListQueryParams = {
  size?: number;
  sort?: Array<string>;
  page?: number;
  number?: string;
  subjectName?: string;
  nip?: string;
  borrower?: string;
  borrowDate?: string;
  borrowPurpose?: string;
  returnDate?: string;
  returnPerson?: string;
  archiveIssuer?: string;
  archiveReceiver?: string;
  archiveStatus?: boolean;
  folderNumberIdIn?: Array<string>;
};

export enum BorrowFolderDialogTypeEnum {
  BORROW_FOLDER = 'BORROW_FOLDER',
  RETURN_FOLDER = 'RETURN_FOLDER',
  CHANGE_BORROWER = 'CHANGE_BORROWER'
}

export interface BorrowFoldersContextType {
  row: BorrowFolderSnapshot;
  open: boolean;
  onClose: () => void;
  onOpen: (type: BorrowFolderDialogTypeEnum, row?: BorrowFolderSnapshot, tableRefetch?: () => void) => void;
  onOpenWithCheck: (type: BorrowFolderDialogTypeEnum, row?: BorrowFolderSnapshot & FolderDetails) => void;
  contentType: BorrowFolderDialogTypeEnum;
  handleSend: () => void;
  selectedFolder?: { label: string; value: string };
}

export const initialValues = {
  borrow: {
    pickedFolder: '',
    borrower: {
      id: '',
      name: ''
    },
    borrowDate: '',
    borrowPurpose: {
      name: '',
      value: ''
    },
    contentType: ''
  },
  returnDate: '',
  returnPerson: ''
};

export enum BorrowFolderFormEnum {
  BORROWER = 'borrower',
  BORROW_DATE = 'borrowDate',
  BORROW_PURPOSE = 'borrowPurpose',
  RETURN_DATE = 'returnDate',
  RETURN_PERSON = 'returnPerson',
  PICKED_FOLDER = 'pickedFolder',
  BORROW_OBJECT = 'borrow',
  BORROW_BORROWER = 'borrow.borrower',
  BORROW_BORROW_DATE = 'borrow.borrowDate',
  BORROW_BORROW_PURPOSE = 'borrow.borrowPurpose',
  BORROW_PICKED_FOLDER = 'borrow.pickedFolder',
  CONTENT_TYPE = 'contentType',
  BORROW_CONTENT_TYPE = 'borrow.contentType'
}

export const ValidationSchema = Yup.object({
  contentType: Yup.string(),
  borrow: Yup.object().shape({
    // pickedFolder: Yup.object().when(BorrowFolderFormEnum.BORROWER, {
    //   is: value => typeof value === 'string' ? true : !!value?.id,
    //   then: Yup.object().nullable(),
    //   otherwise: Yup.object().typeError(i18n.t('archive:borrowFolder.message.pickValidFolder')).required()
    // }),
    // i18n.t('archive:borrowFolder.message.borrowerMustBeDifferent')
    borrower: Yup.object().nullable().required(),
    borrowDate: Yup.string().when(BorrowFolderFormEnum.CONTENT_TYPE, {
      is: value => value === BorrowFolderDialogTypeEnum.BORROW_FOLDER,
      then: Yup.string().required(),
      otherwise: Yup.string()
    }),
    borrowPurpose: Yup.object()
      .nullable()
      .when(BorrowFolderFormEnum.CONTENT_TYPE, {
        is: value => value === BorrowFolderDialogTypeEnum.CHANGE_BORROWER,
        then: Yup.object().required(),
        otherwise: Yup.object()
      })
  }),
  returnDate: Yup.string().when(BorrowFolderFormEnum.BORROW_CONTENT_TYPE, {
    is: value => value !== BorrowFolderDialogTypeEnum.RETURN_FOLDER,
    then: Yup.string(),
    otherwise: Yup.string().required()
  }),
  returnPerson: Yup.object().when(BorrowFolderFormEnum.BORROW_CONTENT_TYPE, {
    is: value => value !== BorrowFolderDialogTypeEnum.RETURN_FOLDER,
    then: Yup.object().nullable(),
    otherwise: Yup.object().typeError(i18n.t('validation:required')).required()
  })
});

export enum BorrowFolderArchiveStatusEnum {
  ARCHIVED = 'archive:borrowFolder.field.archivedStatus',
  BORROWED = 'archive:borrowFolder.field.borrowedStatus'
}

export type BorrowFolderSendValues = {
  borrow: {
    borrower: {
      id: string;
    };
    borrowDate: string;
    borrowPurpose: {
      value: string;
    };
    pickedFolder: { label: string; value: string; name: string };
  };
  pickedFolder: { label: string; value: string; name: string };
  returnDate: string;
  returnPerson: {
    id: string;
  };
};

export interface BorrowFolderState {
  open: boolean;
  contentType: BorrowFolderDialogTypeEnum;
  row: FolderDetails & BorrowFolderSnapshot;
  rowToCheck: FolderDetails & BorrowFolderSnapshot;
  refetch: () => void;
}
