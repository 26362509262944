import React from 'react';
import clsx from 'clsx';

import { RegisteredFormEnums } from './TabContent';

interface IProps<T> {
  value: T;
  children: JSX.Element | JSX.Element[];
  className?: string;
  saveFieldList?: (tab: T, fields: string[]) => void;
  scrollerPrefixId?: RegisteredFormEnums;
  isRelative?: boolean;
}

function TabsContent<T>({ children, value, className, isRelative = true, saveFieldList, scrollerPrefixId }: IProps<T>) {
  return (
    <div
      className={clsx('flex-auto w-full overflow-auto', isRelative ? 'relative' : 'static', className)}
      id={`${scrollerPrefixId}-${value}`}
    >
      {React.Children.map(children, child =>
        React.cloneElement(child, { activeTab: value, saveFieldList, scrollerPrefixId })
      )}
    </div>
  );
}

export default TabsContent;
