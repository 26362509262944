import { PermanentPermissionSubmissionCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function applicationCreate(requestData: PermanentPermissionSubmissionCreateRequest) {
  return API.civicBoard.createPermanentPermissionSubmission(requestData, {
    ...endpointsConfig.createPermanentPermissionSubmission
  });
}

function useCreateApplicationEKMTMutation(config) {
  return useMutation(applicationCreate, config);
}

export default useCreateApplicationEKMTMutation;
