import _ from 'lodash';

import { EventEmitter } from '@libs/common/v2/utils/emitter.utils';

export const RESET_TAB_FIELDS = 'resetTabFields';

class TabsService extends EventEmitter {
  // eslint-disable-next-line class-methods-use-this
  findNodeNames = container => {
    return _.compact(Array.from(container.querySelectorAll('*[name]')).map((node: any) => node.getAttribute('name')));
  };

  findFields = (tabName, container, saveFieldList) => {
    if (container) {
      setTimeout(() => {
        const nodeNames = this.findNodeNames(container);
        if (nodeNames.length) {
          saveFieldList(tabName, nodeNames);
        }
      }, 1000);
    }
  };
}

const instance = new TabsService();

export default instance;
