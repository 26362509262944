import { useQuery } from 'react-query';

import { API } from '@libs/domain/api';

import { IProceedingDetailsClient } from '../../../models';
import { parseDetailsResults } from '../../../utils';
import { RoadTransportQueryKeysEnum } from '../..';
import { endpointsConfig } from '../../endpoints-config';

const getProceedingDetails = async (proceedingId: string) => {
  const { data } = await API.proceedingsKreptd.getProceedingsKreptdDetails(proceedingId, {
    ...endpointsConfig.getProceedingsKreptdDetails
  });

  return parseDetailsResults(data);
};

export default function useProceedingDetailsQuery(proceedingId: string, queryConfig = {}) {
  return useQuery<IProceedingDetailsClient>(
    [RoadTransportQueryKeysEnum.PROCEEDING_DETAILS, proceedingId],
    () => getProceedingDetails(proceedingId),
    queryConfig
  );
}
