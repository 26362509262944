import { useTableLayoutProps } from '@libs/common/v2';

import useAlertsColumns from '@libs/alert/hooks/useAlertsColumns';

import { useAlertsQuery } from '../api';

function useAlertsTable() {
  const { columns, mappedFilterFields } = useAlertsColumns();

  return useTableLayoutProps({
    tableHookQuery: useAlertsQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'modified', desc: true }]
        }
      }
    }
  });
}

export default useAlertsTable;
