import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function exportFile() {
  const data = API.civicBoard.exportFileForSingleUseCommittee({
    ...endpointsConfig.exportFileForEkmtCommittee
  });
  return data;
}

export default function useExportFileForSingleUseCommitteeMutation() {
  return useMutation(exportFile);
}
