import { useTranslation } from 'react-i18next';
import { object as YupObject, string as YupString } from 'yup';

export const useSuspensionValidation = (isSuspended: boolean) => {
  const [t] = useTranslation();

  const suspensionValidation = YupObject({
    suspendedFrom: YupString().required().typeError(t('validation:required')),
    suspendedTo: YupString()
      .test(
        'isSuspendedToAfterThanSuspendedFrom',
        t('user:error.suspendedTo'),
        function isSuspendedToAfterThanSuspendedFrom(this, value) {
          if (value) {
            return value > this.parent['suspendedFrom'];
          }

          return true;
        }
      )
      .required()
      .typeError(t('validation:required'))
  });

  return isSuspended ? YupObject({}) : suspensionValidation;
};
