import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';
import { SubjectLite, SubjectLiteExtended } from '@ibtm/client-domain';
import { makeStyles } from '@mui/styles';

import { Button, Dialog, TableIconButton, TableLayout, useThemeValuesContext } from '@libs/common/v2';
import { important } from '@libs/common/v2/utils';

import { useUser } from '@libs/user';

import usePartnershipSelectionTable from './table/usePartnershipSelectionTable';

interface IProps {
  handleChooseRow: (row: SubjectLite) => void;
  handleCancel: () => void;
}

function PartnershipSelectionDialog({ handleChooseRow, handleCancel }: IProps) {
  const [t] = useTranslation();
  const { width } = useWindowSize();
  const globalTheme = useThemeValuesContext();
  const isMobile = width < globalTheme?.breakpoints?.values?.md;

  const loggedInUserData = useUser();
  const tableProps = usePartnershipSelectionTable(loggedInUserData?.id);
  const classes = useStyles();

  return (
    <Dialog
      title={t('user:dialog.partnershipSelectTitle')}
      cancelText={t('action.logout')}
      onCancel={handleCancel}
      dialogSize="large"
      isCloseIconEnabled={false}
      isOpen
      isBackdropClickEnabled={false}
      isFullScreen={isMobile}
      isScrollbarEnabled={false}
      isYOverflow={false}
      isContentPadding={!isMobile}
      isContentFullHeight={isMobile}
    >
      <div style={{ height: '100%' }}>
        <TableLayout<SubjectLite, SubjectLiteExtended>
          {...tableProps}
          sectionClassName={classes.table}
          sectionContentClassName={classes.tableContent}
          pageTitle={t('user:table.partnerships')}
          rowActions={({ original }) =>
            isMobile ? (
              <TableIconButton icon="ArrowIcon" onClick={() => handleChooseRow(original)} />
            ) : (
              <Button
                className={classes.selectButton}
                label={t('global:action.select')}
                onClick={() => handleChooseRow(original)}
                variant="outlined"
                isNoMargin
              />
            )
          }
          actionsColumnWidth={isMobile ? 80 : 130}
          isSection
          isSectionFullWidth
          isSectionFullTab={isMobile}
          isHeaderHidden
          isFilterEnabled={false}
          isPaginated={!isMobile}
          isHiddenSelectRowHeader
        />
      </div>
    </Dialog>
  );
}

const useStyles = makeStyles(() => ({
  table: {
    height: important('65vh')
  },
  tableContent: {
    height: important('55vh')
  },
  selectButton: {
    minWidth: 'auto'
  }
}));

export default PartnershipSelectionDialog;
