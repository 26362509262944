import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ProceedingAvailableFormsDetails } from '@ibtm/domain';
import { makeStyles } from '@mui/styles';

import { Button, TableIconButton, typedNameV2, useFormV2Context } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';
import { Theme } from '@libs/common/v2/theme';

import { FormOperationListItemClient } from '@libs/domain/proceeding';

interface IProps {
  row: ProceedingAvailableFormsDetails;
  formIds: string[];
  chosenForms?: ProceedingAvailableFormsDetails[];
  setChosenForms?: (ProceedingAvailableFormsDetails) => void;
  setAddNew?: (boolean) => void;
  hasDeleteActionVisible?: boolean;
}

function PermissionTableRowActions({
  row,
  formIds,
  chosenForms,
  setChosenForms,
  setAddNew,
  hasDeleteActionVisible
}: IProps) {
  const [t] = useTranslation();
  const { setValue, formMode } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);
  const classes = useStyles();

  const onPermissionSelect = useCallback(() => {
    setValue(typedNameV2<FormOperationListItemClient>('form.ids'), formIds ? [...formIds, row.formId] : [row.formId]);
    setChosenForms([...chosenForms, row]);
    setAddNew(false);
  }, [chosenForms, formIds, row, setAddNew, setChosenForms, setValue]);

  const onPermissionDelete = useCallback(() => {
    setValue(
      typedNameV2<FormOperationListItemClient>('form.ids'),
      formIds?.filter(id => id !== row.formId)
    );
    setChosenForms(chosenForms?.filter(form => form.formId !== row.formId));
  }, [chosenForms, formIds, row.formId, setChosenForms, setValue]);

  return (
    row.formId &&
    !viewMode &&
    (hasDeleteActionVisible ? (
      <TableIconButton icon="TrashIcon" onClick={onPermissionDelete} />
    ) : (
      <Button
        label={t('action.select')}
        onClick={onPermissionSelect}
        variant="outlined"
        isNoMargin
        className={classes.selectButton}
        disabled={!!formIds?.find(id => id === row.formId)}
        tooltipTitle={
          !!formIds?.find(id => id === row.formId) && t('proceeding:administrative.tab.formOperations.chosenForm')
        }
      />
    ))
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  selectButton: {
    minWidth: 'auto',
    padding: theme.spacing(1.25)
  }
}));

export default PermissionTableRowActions;
