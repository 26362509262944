import { AlertUIElementEnum } from '@libs/alert/config';
import { DictionaryUIElementEnum } from '@libs/dictionary/configs';
import { DocumentTemplateUIElementEnum } from '@libs/document-template/config';
import { GroupUIElementEnum } from '@libs/group/config';
import { NotificationUIElementEnum } from '@libs/notification/config';
import { OrganizationStructureUIElementEnum } from '@libs/organization-structure/config';
import { ReportUIElementEnum } from '@libs/report/config';
import { UserUIElementEnum } from '@libs/user/config';

import {
  ApplicationProcessMainActionEnum,
  ApplicationProcesTransitionsEnum,
  DomainUIElementEnum
} from '@libs/domain/config';

export const UIElementNameEnum = {
  ...DomainUIElementEnum,
  ...OrganizationStructureUIElementEnum,
  ...UserUIElementEnum,
  ...GroupUIElementEnum,
  ...ReportUIElementEnum,
  ...DictionaryUIElementEnum,
  ...DocumentTemplateUIElementEnum,
  ...NotificationUIElementEnum,
  ...ApplicationProcessMainActionEnum,
  ...ApplicationProcesTransitionsEnum,
  ...AlertUIElementEnum
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type UIElementNameEnum =
  | DomainUIElementEnum
  | OrganizationStructureUIElementEnum
  | UserUIElementEnum
  | GroupUIElementEnum
  | ReportUIElementEnum
  | DictionaryUIElementEnum
  | DocumentTemplateUIElementEnum
  | ApplicationProcesTransitionsEnum
  | ApplicationProcessMainActionEnum
  | NotificationUIElementEnum
  | AlertUIElementEnum;
