import { ReactNode } from 'react';

import { TabsContent as TabsContentCommon } from '@libs/common/v2';

import { useTabContext } from '@libs/meta-form/context';

function TabsContent({
  children,
  renderNodes,
  components
}: {
  renderNodes: (children: ReactNode, components: any) => JSX.Element | JSX.Element[];
  components: any;
  children: ReactNode;
}) {
  const { activeTabId } = useTabContext();

  return <TabsContentCommon value={activeTabId}>{renderNodes(children, components)}</TabsContentCommon>;
}

export default TabsContent;
