import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountSnapshot } from '@avispon/user/dist/models';

import { Button, ButtonsGroup, DropdownButton, FormMode, useRouter, useViewModesV2 } from '@libs/common/v2';

import { ChangeUserPasswordValidation, IPermissionsObject, useUserHeaderDropdownMenu } from '@libs/user';

interface IProps {
  formMode: FormMode;
  modulePath: string;
  userId: string;
  validationChangePasswordScheme?: ChangeUserPasswordValidation;
  handleSubmit: any;
  isSubmitting: boolean;
  isChangeWithNewPassword?: boolean;
  userDetails: AccountSnapshot;
  refetchUserDetails: () => void;
  permissionsKeysObject?: IPermissionsObject;
}

function UserDetailsHeader({
  formMode,
  modulePath,
  userId,
  validationChangePasswordScheme,
  handleSubmit,
  isSubmitting,
  isChangeWithNewPassword,
  userDetails,
  refetchUserDetails,
  permissionsKeysObject
}: IProps) {
  const [t] = useTranslation();
  const { goBack } = useRouter();
  const { routes } = useRouter();
  const { viewMode, editMode, createMode } = useViewModesV2(formMode);

  const handleCancel = useCallback(() => {
    goBack();
  }, [goBack]);

  const { actionButtons } = useUserHeaderDropdownMenu({
    userDetails,
    validationChangePasswordScheme,
    isChangeWithNewPassword,
    refetchUserDetails,
    permissionsKeysObject
  });

  return (
    <>
      {viewMode && userDetails && userDetails.isEditable && permissionsKeysObject?.edit && (
        <ButtonsGroup>
          <Button
            label={t('action.edit')}
            link={modulePath === 'users' ? routes.userEdit(userId) : routes.userClientPortalEdit(userId)}
            variant="outlined"
            type="button"
            actionKey={permissionsKeysObject?.edit}
            isSecondary
          />
          <DropdownButton actionButtons={actionButtons} />
        </ButtonsGroup>
      )}

      {(createMode || editMode) && (
        <>
          <div>
            <Button label={t('action.cancel')} onClick={handleCancel} variant="outlined" type="button" isPrimary />
          </div>
          <div>
            <Button label={t('action.save')} onClick={handleSubmit} type="submit" isLoading={isSubmitting} isPrimary />
          </div>
        </>
      )}
    </>
  );
}

export default UserDetailsHeader;
