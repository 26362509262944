import { useParams } from 'react-router-dom';

import { typedNameV2, useFormV2Context } from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';

import { DomainUIElementEnum } from '@libs/domain/config';
import {
  EditFolderParams,
  FolderDetailsClient,
  FolderDetailsTabEnum,
  parseFolderDetailsFormData,
  useFolderDetailsExtendedQuery
} from '@libs/domain/folder';
import { usePartnershipPartnersQuery, useSubjectDetailsExtendedQuery } from '@libs/domain/subject';

const useDiscardFolderChanges = () => {
  const { folderId } = useParams<EditFolderParams>();
  const { reset, watch } = useFormV2Context();
  const { checkIsElementVisible } = useElementVisibility();

  const subjectId = watch(typedNameV2<FolderDetailsClient>('subject.id')) as string;

  const { data: folderDetails } = useFolderDetailsExtendedQuery(folderId, { enabled: !!folderId, staleTime: 2000 });
  const { data: subjectDetails } = useSubjectDetailsExtendedQuery(subjectId, {
    enabled: !!subjectId && checkIsElementVisible(DomainUIElementEnum.FOLDER_SUBJECT_VIEW),
    staleTime: 2000
  });
  const { data: partnershipPartnersData } = usePartnershipPartnersQuery(
    { subjectId },
    {
      enabled: !!subjectId && checkIsElementVisible(DomainUIElementEnum.FOLDER_SUBJECT_VIEW),
      staleTime: 2000
    }
  );

  const discardChanges = (activeTab: FolderDetailsTabEnum) => {
    switch (activeTab) {
      case FolderDetailsTabEnum.BASIC_DATA:
        resetFolderAndSubject();
        break;
      case FolderDetailsTabEnum.SUBJECT:
        resetFolderAndSubject();
        break;
      case FolderDetailsTabEnum.ADDRESSES:
        resetFolderAndSubject();
        break;
      case FolderDetailsTabEnum.EKMT:
        resetFolderAndSubject();
        break;
      case FolderDetailsTabEnum.SINGLE_AUTHORIZATION:
        resetFolderAndSubject();
        break;
      default:
        break;
    }
  };

  const resetFolderAndSubject = () => {
    reset(parseFolderDetailsFormData(folderDetails, subjectDetails, partnershipPartnersData?.partnershipPartners));
  };

  return { discardChanges };
};

export default useDiscardFolderChanges;
