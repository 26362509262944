import { useDialog } from '@libs/common/v2';

import TransportManagersSearchDialog from '../components/table/TransportManagersSearchDialog';

interface IProps {
  folderId: string;
  applicationId: string;
  transferredFoldersIds?: string[];
}

export function useTransportManagerSearchAction({ folderId, applicationId, transferredFoldersIds }: IProps) {
  const { openDialog } = useDialog();
  const openTransportManagersSearchModal = () => {
    openDialog(({ closeDialog }) => (
      <TransportManagersSearchDialog
        closeDialog={closeDialog}
        folderId={folderId}
        applicationId={applicationId}
        transferredFoldersIds={transferredFoldersIds}
      />
    ));
  };

  return { openTransportManagersSearchModal };
}
