import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { TableLayout, useTableRowActions } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { DomainDictionaryEntry, DomainUIElementEnum } from '@libs/domain/config';
import { useGenerateApplicationDocumentDialog } from '@libs/domain/documents';

import { RoadTransportQueryKeysEnum, useFolderDocumentGenerationMutation } from '../../../api';
import {
  useFoldersToTransferStatusChangeDialog,
  useFoldersToTransferTable,
  useFolderTransferDialog
} from '../../../hooks';
import { IFoldersToTransferClient } from '../../../models';
import { parseFoldersToTransferListResults } from '../../../utils';

interface IProps {
  isSectionFullTab?: boolean;
  isSection?: boolean;
}

function FoldersToTransferTable({ isSectionFullTab, isSection = true }: IProps) {
  const [t] = useTranslation();
  const tableProps = useFoldersToTransferTable();
  const { showSnackbar } = useSnackbar();
  const { openFoldersToTransferStatusChangeDialog } = useFoldersToTransferStatusChangeDialog();
  const { openFolderTransferDialog } = useFolderTransferDialog();

  const { openGenerateDocumentDialog } = useGenerateApplicationDocumentDialog({
    generateDocumentMutation: useFolderDocumentGenerationMutation,
    listQueryKey: RoadTransportQueryKeysEnum.FOLDERS_TO_TRANSFER_LIST
  });

  const { renderTableActions } = useTableRowActions<IFoldersToTransferClient>([
    {
      id: 'ROAD_TRANSPORT_FOLDERS_TO_HANDED_OVER_GENERATE_DOCUMENT_BUTTON',
      label: 'action.transfer',
      onClick: ({ row: { original } }) => openFolderTransferDialog(original),
      icon: 'HandOverIcon',
      isDisabled: ({ row: { original } }) =>
        original.status === DomainDictionaryEntry.FOLDER_STATUS.EXPIRED_TO_TRANSFER,
      isDisabledLabel: 'roadTransport:tooltip.notAssignedToTransfer'
    },
    {
      id: 'ROAD_TRANSPORT_FOLDERS_TO_ASSIGN_HANDED_OVER_GENERATE_DOCUMENT_BUTTON',
      label: 'action.assign',
      onClick: ({ row: { original } }) => openFolderTransferDialog(original, true),
      icon: 'HandOverIcon'
    },
    {
      id: 'ROAD_TRANSPORT_FOLDERS_TO_HANDED_OVER_TRANSFER_BUTTON',
      label: 'roadTransport:actions.generateForm',
      onClick: ({ row: { original } }) => {
        openGenerateDocumentDialog({
          title: t('roadTransport:dialog.generateFormTitle'),
          onSuccess: () => {
            showSnackbar('success', t('roadTransport:messages.generateFormSuccess'));
          },
          isDocumentDownloadingAfterGenerate: true,
          params: { folderId: original.id }
        });
      },
      icon: 'ArrowIcon'
    },
    {
      id: 'ROAD_TRANSPORT_FOLDERS_TO_HANDED_OVER_CHANGE_STATUS_BUTTON',
      label: 'roadTransport:actions.changeStatus',
      onClick: ({ row: { original } }) => openFoldersToTransferStatusChangeDialog(original),
      icon: 'ChangeStatusIcon'
    }
  ]);

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('roadTransport:list.foldersToTransfer')}
      xlsxDownload={{
        fileName: t('roadTransport:list.foldersToTransfer'),
        pathToXLSXTranslation: 'roadTransport:fields',
        apiRequest: params =>
          API.folder.getFoldersToTransfer(params).then(res => ({
            ...res,
            data: { ...res.data, content: parseFoldersToTransferListResults(res.data.content) }
          }))
      }}
      rowActions={renderTableActions([
        DomainUIElementEnum.ROAD_TRANSPORT_FOLDERS_TO_HANDED_OVER_TRANSFER_BUTTON,
        DomainUIElementEnum.ROAD_TRANSPORT_FOLDERS_TO_HANDED_OVER_CHANGE_STATUS_BUTTON,
        DomainUIElementEnum.ROAD_TRANSPORT_FOLDERS_TO_HANDED_OVER_GENERATE_DOCUMENT_BUTTON,
        DomainUIElementEnum.ROAD_TRANSPORT_FOLDERS_TO_ASSIGN_HANDED_OVER_GENERATE_DOCUMENT_BUTTON
      ])}
      actionsColumnWidth={120}
      isSection={isSection}
      isSectionFullTab={isSectionFullTab}
    />
  );
}

export default FoldersToTransferTable;
