import { ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PluginHook } from 'react-table';
import { DriversSearchFilter as DriversSearchFilterClient } from '@ibtm/client-domain';
import { DriverDetails, DriversSearchFilter } from '@ibtm/domain';

import { CamelCasePath, TableLayout } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { useDomainConfigContext } from '@libs/domain/config';
import { AddDriverButton, SearchDriverButton } from '@libs/domain/drivers/components';
import { TABLE_OPTIONS, VISIBLE_ACTION_BUTTONS, VISIBLE_COLUMNS } from '@libs/domain/drivers/config';
import { DriverProvider } from '@libs/domain/drivers/context';
import { useAddDriverAction, useDriversTable, useOpenDriverSearchDialog } from '@libs/domain/drivers/hooks';
import { DriverActionButtonsEnum, IPermissionsObject } from '@libs/domain/drivers/model';

import { IDriverTableOptions } from '../../../hooks/tables/useDriversTable';

import TableRowActions from './TableRowActions';

interface IProps {
  title?: string;
  visibleColumns: Array<CamelCasePath<DriverDetails>>;
  queryParams?: DriversSearchFilter | DriversSearchFilterClient;
  tableOptions?: IDriverTableOptions;
  visibleActionButtons: {
    view?: DriverActionButtonsEnum[];
    tableRow?: {
      visible?: DriverActionButtonsEnum[];
      more?: DriverActionButtonsEnum[];
    };
  };
  additionalData?: {
    applicationId?: string;
    folderId?: string;
    transferredFoldersIds?: string[];
    subjectNip?: string;
    applicationTypeKey?: string;
  };
  tablePlugins?: PluginHook<any>[];
  children?: ReactNode;
  isSection?: boolean;
  isHeaderHidden?: boolean;
  isActionColumnEnabled?: boolean;
  isCollapsable?: boolean;
  permissionsKeysObject?: IPermissionsObject;
  isFilterDisabled?: boolean;
  isSortingDisabled?: boolean;
  searchDriverDialogSearchParams?: DriversSearchFilter;
}

function DriversTable({
  title,
  visibleColumns = VISIBLE_COLUMNS,
  tableOptions = TABLE_OPTIONS,
  visibleActionButtons = VISIBLE_ACTION_BUTTONS,
  additionalData,
  tablePlugins,
  children,
  queryParams,
  isSection,
  isHeaderHidden,
  isActionColumnEnabled,
  isCollapsable,
  permissionsKeysObject,
  searchDriverDialogSearchParams,
  isFilterDisabled = false,
  isSortingDisabled = false
}: IProps) {
  const [t] = useTranslation();
  const { isClientPortal } = useDomainConfigContext();

  const { handleOpenDialog: handleAddDriverOpenDialog } = useAddDriverAction({
    folderId: additionalData.folderId,
    transferredFoldersIds: additionalData?.transferredFoldersIds,
    subjectNip: additionalData.subjectNip
  });

  const { openDriverSearchDialog } = useOpenDriverSearchDialog();

  const actionsRowRender = useCallback(
    ({ original }: { original: DriverDetails }) => (
      <TableRowActions
        data={original}
        visibleActionButtons={visibleActionButtons}
        permissionsKeysObject={permissionsKeysObject}
        applicationId={additionalData.applicationId}
        folderId={additionalData.folderId}
      />
    ),
    [additionalData.applicationId, additionalData.folderId, permissionsKeysObject, visibleActionButtons]
  );

  const allButtons = useMemo(
    () => ({
      [DriverActionButtonsEnum.SEARCH]: (
        <SearchDriverButton
          folderId={additionalData.folderId}
          transferredFoldersIds={additionalData?.transferredFoldersIds}
          applicationId={additionalData.applicationId}
          actionKey={permissionsKeysObject?.[DriverActionButtonsEnum.SEARCH]}
          searchParams={searchDriverDialogSearchParams}
        />
      ),
      [DriverActionButtonsEnum.ADD_DRIVER]: (
        <AddDriverButton
          subjectNip={additionalData.subjectNip}
          folderId={additionalData.folderId}
          transferredFoldersIds={additionalData?.transferredFoldersIds}
          actionKey={permissionsKeysObject?.[DriverActionButtonsEnum.ADD_DRIVER]}
        />
      )
    }),
    [
      additionalData.applicationId,
      additionalData.folderId,
      additionalData.transferredFoldersIds,
      additionalData.subjectNip,
      permissionsKeysObject,
      searchDriverDialogSearchParams
    ]
  );
  const tableProps = useDriversTable(
    {
      visibleColumns,
      tableOptions,
      tablePlugins,
      queryParams
    },
    isSortingDisabled
  );

  const mobileHeaderActions = [
    {
      key: DriverActionButtonsEnum.ADD_DRIVER,
      onClick: handleAddDriverOpenDialog,
      label: t('action.add'),
      actionKey: permissionsKeysObject?.[DriverActionButtonsEnum.ADD_DRIVER]
    },
    {
      key: DriverActionButtonsEnum.SEARCH,
      onClick: () =>
        openDriverSearchDialog({
          applicationId: additionalData.applicationId,
          folderId: additionalData.folderId,
          transferredFoldersIds: additionalData?.transferredFoldersIds,
          searchParams: searchDriverDialogSearchParams
        }),
      label: t('drivers:actions.searchDriver'),
      actionKey: permissionsKeysObject?.[DriverActionButtonsEnum.SEARCH]
    }
  ].filter(action => visibleActionButtons.view.includes(action.key));

  return (
    <DriverProvider
      values={{
        application: {
          id: additionalData?.applicationId,
          folderId: additionalData?.folderId,
          subjectNip: additionalData?.subjectNip,
          applicationTypeKey: additionalData?.applicationTypeKey
        }
      }}
    >
      <TableLayout
        {...tableProps}
        pageTitle={title ?? t('drivers:list.title')}
        xlsxDownload={{
          fileName: t('drivers:list.title'),
          pathToXLSXTranslation: 'drivers:fields',
          apiRequest: params => {
            if (isClientPortal) {
              return API.client.drivers.getDriversDetailsPage('', '', params);
            }
            return API.drivers.getDriversDetailsPage(params);
          }
        }}
        headerActions={visibleActionButtons.view.map(buttonType => allButtons[buttonType])}
        mobileHeaderActions={mobileHeaderActions}
        rowActions={actionsRowRender}
        isSection={isSection}
        isCollapsable={isCollapsable}
        isHeaderHidden={isHeaderHidden}
        isActionColumnEnabled={isActionColumnEnabled}
        isFilterEnabled={!isFilterDisabled}
      >
        {children}
      </TableLayout>
    </DriverProvider>
  );
}

export default DriversTable;
