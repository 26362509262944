import { ResourceObjectAssociationReturnCreateRequest } from '@ibtm/client-domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function returnResourceObjects(requestData: ResourceObjectAssociationReturnCreateRequest) {
  return API.client.resourceObjects.associationReturnResourceObject('', requestData, {
    ...endpointsConfig.associationReturnResourceObject
  });
}

function useAssociationReturnMutation() {
  return useMutation(returnResourceObjects);
}

export default useAssociationReturnMutation;
