import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { getNonEmptyFilters, useTableAdapter, useTableContext } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import TableIconButton from './TableIconButton';

interface IProps {
  tooltipTitle?: string;
  className?: string;
  isDisabled?: boolean;
}

function TableClearFiltersButton({ isDisabled, tooltipTitle, className }: IProps) {
  const { isFilterEnabled } = useTableContext();
  const [t] = useTranslation();
  const {
    clearFilters,
    state: { filters }
  } = useTableAdapter();

  const hasActiveFilters = Boolean(getNonEmptyFilters(filters)?.length);

  const classes = useStyles({ hasActiveFilters });

  return (
    <TableIconButton
      tooltipTitle={!hasActiveFilters ? '' : tooltipTitle || t('other:component.table.clearFilters')}
      onClick={clearFilters}
      className={className}
      icon="FilterClearIcon"
      iconClassName={classes.icon}
      isDisabled={isDisabled || !hasActiveFilters || !isFilterEnabled}
    />
  );
}

const useStyles = makeStyles<Theme, { hasActiveFilters: boolean }>(theme => ({
  icon: {
    '& > path': {
      fill: ({ hasActiveFilters }) => (hasActiveFilters ? theme.palette.grey[500] : theme.palette.grey[300]),
      stroke: important('none')
    }
  }
}));

export default TableClearFiltersButton;
