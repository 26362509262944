import { useTranslation } from 'react-i18next';
import { ApplicationSnapshot } from '@ibtm/domain';

import { useAuthSelector } from '@libs/auth/store/reducers';

function useIsApplicationEditEnabled(application?: ApplicationSnapshot) {
  const { personalData } = useAuthSelector(({ auth }) => auth.user);
  const [t] = useTranslation();
  const checkIsApplicationEditEnabled = (applicationSnapshot: ApplicationSnapshot) => {
    const isEditEnabled = Boolean(personalData?.id) && applicationSnapshot?.editor?.id === personalData.id;
    const editorName = applicationSnapshot?.editor?.name;
    return {
      isEditEnabled,
      errorMessage:
        !isEditEnabled &&
        (editorName
          ? t('applications:message.editorOfApplicationIs', { editor: editorName })
          : t('applications:message.youAreNotEditor'))
    };
  };
  return {
    ...checkIsApplicationEditEnabled(application),
    checkIsApplicationEditEnabled
  };
}
export default useIsApplicationEditEnabled;
