import { useEffect, useState } from 'react';

import { ITableLayoutProps, LoaderCircular, useCustomTableQuery, usePaginatedQueryTable } from '@libs/common/v2';

import TableWithoutHook from './TableWithoutHook';

function DelayedTable({ children, waitBeforeShow = 280 }) {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [waitBeforeShow]);

  if (isShown) {
    return children;
  }

  return <LoaderCircular isLoading />;
}

function PaginatedQueryTable<T extends Record<string, any>, K extends Record<string, any>>({
  tableHookQuery,
  tableHookOptions: {
    tablePlugins = [],
    filterConverter,
    sortByConverter,
    initialParamsConverter,
    ...restTableHookOptions
  },
  tableHookQueryConfig,
  tableHookQueryInitialParams = {},
  tableHookQueryKey,
  xlsxDownload,
  rowActions,
  isActionColumnEnabled = rowActions && true,
  isTableMutable,
  delayTable = true,
  ...forwardTableProps
}: Omit<ITableLayoutProps<T, K>, 'tableQuery' | 'isFetching' | 'isLoading' | 'refetch'>) {
  const {
    table,
    tableQuery: queryResult,
    additionalData: { newRowScheme },
    tableQueryParams,
    setTableQueryParams
  } = useCustomTableQuery({
    query: tableHookQuery,
    tableHookOptions: {
      tablePlugins,
      filterConverter,
      sortByConverter,
      initialParamsConverter,
      ...restTableHookOptions
    },
    dataHookQueryConfig: { ...tableHookQueryConfig },
    isActionColumnEnabled,
    isTableMutable,
    tableHookQuery: usePaginatedQueryTable,
    tableHookQueryInitialParams,
    tableHookQueryKey
  });

  if (delayTable) {
    return (
      <DelayedTable>
        <TableWithoutHook
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          table={table as any}
          tableHookQueryInitialParams={tableHookQueryInitialParams}
          newRowScheme={newRowScheme as T}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          queryResult={queryResult as any}
          xlsxDownload={
            !!xlsxDownload && {
              ...xlsxDownload,
              filterConverter,
              sortByConverter,
              initialParamsConverter
            }
          }
          isTableMutable={isTableMutable}
          setTableQueryParams={setTableQueryParams}
          tableQueryParams={tableQueryParams}
          rowActions={rowActions}
          {...forwardTableProps}
        />
      </DelayedTable>
    );
  }

  return (
    <TableWithoutHook
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      table={table as any}
      tableHookQueryInitialParams={tableHookQueryInitialParams}
      newRowScheme={newRowScheme as T}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      queryResult={queryResult as any}
      xlsxDownload={
        !!xlsxDownload && {
          ...xlsxDownload,
          filterConverter,
          sortByConverter,
          initialParamsConverter
        }
      }
      isTableMutable={isTableMutable}
      setTableQueryParams={setTableQueryParams}
      tableQueryParams={tableQueryParams}
      rowActions={rowActions}
      {...forwardTableProps}
    />
  );
}

export default PaginatedQueryTable;
