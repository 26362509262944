import { useMutation } from 'react-query';
import { DocumentCreateRequest } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

export interface ICreateFolderDocumentParams {
  folderId: string;
  data: DocumentCreateRequest;
}

function generateDocument(requestData: ICreateFolderDocumentParams) {
  return API.folder.createFolderDocument(
    requestData.folderId,
    {
      documentTemplateId: requestData.data.documentTemplateId
    },
    { ...endpointsConfig.documentTemplateId }
  );
}

function useFolderDocumentGenerationMutation() {
  return useMutation(generateDocument);
}

export default useFolderDocumentGenerationMutation;
