import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { ForeignPermissionDetails, ResourceObjectReturnCreateRequest } from '@ibtm/domain';
import moment from 'moment';
import * as Yup from 'yup';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  Dialog,
  DictionarySelectField,
  FormMode,
  FormV2Context,
  GridItem,
  GridLayout,
  InputMode,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';
import { getCalendarDate } from '@libs/common/v2/utils';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { ForeignPermissionDetailsForm } from '@libs/domain/foreign-permission';

import { useResourceObjectReturnsMutation } from '../../../api';
import { IResourceObjectReturnFields, IResourceObjectsClient } from '../../../model';

const getValidationSchema = () => {
  return Yup.object({
    returnDate: Yup.string().nullable().required(),
    notes: Yup.string().nullable().required()
  });
};

interface IProps {
  initialData: ForeignPermissionDetails;
  closeDialog: () => void;
  onSuccess?: () => void;
}

function ResourceObjectReturnsForms({ initialData, closeDialog, onSuccess }: IProps) {
  const getLabel = partialTranslate('resource:fields');
  const [t] = useTranslation();

  const { mutate: returnResourceObject, isLoading } = useResourceObjectReturnsMutation();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(getValidationSchema()),
    defaultValues: {
      ...initialData,
      returnDate: moment().toISOString()
    }
  });

  const onSubmit = (formData: IResourceObjectReturnFields) => {
    const requestData: ResourceObjectReturnCreateRequest = {
      resourceObjectIds: [initialData.id],
      notes: formData.notes,
      returnDate: getCalendarDate(formData.returnDate)
    };
    returnResourceObject(requestData, {
      onSuccess: () => {
        onSuccess?.();
        closeDialog();
      }
    });
  };

  const values = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      formMode: FormMode.CREATE
    }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('resource:return.title')}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={handleSubmit(data => onSubmit(data as IResourceObjectReturnFields))}
      onCancel={closeDialog}
      dialogSize="medium"
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <GridLayout itemProps={{ xs: 6 }}>
          <TextInputField
            inputMode={InputMode.VIEW}
            name={typedNameV2<ForeignPermissionDetailsForm>('year')}
            label={getLabel('year')}
          />
          <DictionarySelectField
            inputMode={InputMode.VIEW}
            name={typedNameV2<ForeignPermissionDetailsForm>('form.type.groupKey')}
            label={getLabel('formGroupType')}
            dictionaryName={DomainDictionaryEnum.RESOURCE_FORM_GROUP}
          />
          <DictionarySelectField
            inputMode={InputMode.VIEW}
            name={typedNameV2<ForeignPermissionDetailsForm>('form.type.nameKey')}
            dictionaryName={DomainDictionaryEnum.RESOURCE_FORM_NAME}
            label={getLabel('name')}
          />
          <DictionarySelectField
            inputMode={InputMode.VIEW}
            name={typedNameV2<ForeignPermissionDetailsForm>('form.stateKey')}
            label={getLabel('status')}
            dictionaryName={DomainDictionaryEnum.RESOURCE_FORM_STATE}
          />
          <TextInputField
            inputMode={InputMode.VIEW}
            name={typedNameV2<IResourceObjectsClient>('released')}
            label={getLabel('issuedTo')}
          />
          <DatepickerField
            name={typedNameV2<IResourceObjectsClient>('returnDate')}
            label={getLabel('returnDate')}
            isRequired
          />
          <GridItem xs={12}>
            <TextInputField
              name={typedNameV2<IResourceObjectsClient>('notes')}
              label={getLabel('notes')}
              lines={3}
              isRequired
            />
          </GridItem>
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default ResourceObjectReturnsForms;
