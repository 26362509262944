import { useContext } from 'react';
import { Controller } from 'react-hook-form';

import { ComponentErrorBoundary, FormV2Context, FormV2ContextState } from '@libs/common/v2';

import DictionarySelectControllerComponent, { DictionarySelectProps } from './DictionarySelectControllerComponent';

function DictionarySelect({
  name,
  label,
  tooltip,
  dictionaryName,
  optionsFilter,
  optionsDisabled,
  freeSoloOptionFilter,
  onChange: customOnChange,
  valueClassName,
  formContext = FormV2Context,
  validationSingleSelect,
  validationMultipleSelect,
  validationStringValueSingleSelect,
  isRequired,
  isDisabled,
  inputMode,
  isMultiple,
  isFreeSolo,
  className,
  isClearable = true,
  stringValue,
  customErrorMessage,
  hasErrorTooltip,
  actionKey
}: DictionarySelectProps) {
  const { control, formMode, loading, isSubmitting } = useContext<FormV2ContextState>(formContext);

  return (
    <ComponentErrorBoundary componentName={label || name || 'DictionarySelectField'}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => {
          return (
            <DictionarySelectControllerComponent
              name={name}
              label={label}
              tooltip={tooltip}
              dictionaryName={dictionaryName}
              className={className}
              onChange={onChange}
              currentValue={value}
              onBlur={onBlur}
              error={error}
              inputMode={inputMode}
              stringValue={stringValue}
              isMultiple={isMultiple}
              customOnChange={customOnChange}
              customErrorMessage={customErrorMessage}
              isRequired={isRequired}
              isDisabled={isDisabled}
              isFreeSolo={isFreeSolo}
              isClearable={isClearable}
              freeSoloOptionFilter={freeSoloOptionFilter}
              validationStringValueSingleSelect={validationStringValueSingleSelect}
              validationMultipleSelect={validationMultipleSelect}
              validationSingleSelect={validationSingleSelect}
              valueClassName={valueClassName}
              formMode={formMode}
              loading={loading}
              isSubmitting={isSubmitting}
              optionsFilter={optionsFilter}
              optionsDisabled={optionsDisabled}
              hasErrorTooltip={hasErrorTooltip}
              actionKey={actionKey}
            />
          );
        }}
      />
    </ComponentErrorBoundary>
  );
}

export default DictionarySelect;
