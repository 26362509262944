import { useMemo } from 'react';

import { AutocompleteSelectField, typedNameV2, useFormV2Watch } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';

import { useApplicationDefinitionsQuery } from '@libs/domain/application-definitions';
import { DomainDictionaryEnum } from '@libs/domain/config';

import { ApplicationCreateFormValues } from '../../model';

interface IProps {
  label: string;
  folderTypeKey?: string;
  disabledTooltip?: string;
  isRequired?: boolean;
}

function ApplicationTypeSelectField({ label, folderTypeKey, disabledTooltip, isRequired }: IProps) {
  const { value: categoryKey } =
    useFormV2Watch({
      name: typedNameV2<ApplicationCreateFormValues>('categoryKey')
    }) || {};
  const { isLoading, data } = useApplicationDefinitionsQuery(
    {
      folderTypeKey,
      categoryKey
    },
    {
      enabled: Boolean(categoryKey && folderTypeKey)
    }
  );

  const { translate } = useDictionaryTranslations();

  const options = useMemo(
    () =>
      data?.content?.map(item => ({
        name: translate(DomainDictionaryEnum.APPLICATION_TYPE, item.typeKey, item.typeKey),
        value: {
          id: item.id,
          typeKey: item.typeKey
        }
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.content]
  );

  const isDisabled = !categoryKey;

  return (
    <AutocompleteSelectField
      tooltip={isDisabled && disabledTooltip}
      options={options}
      name={typedNameV2<ApplicationCreateFormValues>('applicationDefinition')}
      label={label}
      renderOption={(props, option) => {
        return <li {...props}>{option?.name}</li>;
      }}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isRequired={isRequired}
    />
  );
}

export default ApplicationTypeSelectField;
