import { useTranslation } from 'react-i18next';
import { ColumnTypesEnum, TableFilterMapper, TableSortMapper, useCreateColumns, Value } from '@enigma/fe-ui';
import { LicenseCopySearchFilter } from '@ibtm/domain';

import { SelectOptionPartial } from '@libs/common/v2';
import { getCalendarDate } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '../../../config';
import { LicenseCopiesColumnsEnum, LicenseCopySnapshotClient } from '../../model/license.model';

export function useLicenseCopiesTableColumnsV2({
  visibleColumns
}: {
  visibleColumns: Array<LicenseCopiesColumnsEnum>;
}) {
  const [t] = useTranslation();
  const { createColumns } = useCreateColumns<LicenseCopySnapshotClient>();
  const { translate } = useDictionaryTranslations();

  const columns = createColumns(
    [
      {
        type: ColumnTypesEnum.TEXT,
        id: 'applicationNumber',
        accessor: 'applicationNumber',
        header: t('license:fields.applicationNumber')
      },

      {
        type: ColumnTypesEnum.DICTIONARY,
        id: 'cancellationReason',
        accessor: 'cancellationReason',
        header: t('license:fields.cancellationReason'),
        dictionaryKey: DomainDictionaryEnum.LICENSE_CANCELLATION_REASON,
        filter: 'AutocompleteDictionaryMultiple',
        columnFormatter: value => (
          <Value variant="dictionary" dictionaryKey={DomainDictionaryEnum.LICENSE_CANCELLATION_REASON} value={value} />
        ),
        columnXLSXFormatter: value => {
          return translate(DomainDictionaryEnum.LICENSE_CANCELLATION_REASON, value, t('emptyMark'));
        }
      },
      {
        type: ColumnTypesEnum.DATE,
        id: 'cancellationDate',
        accessor: 'cancellationDate',
        header: t('license:fields.cancellationDate')
      },
      {
        type: ColumnTypesEnum.TEXT,
        id: 'subjectName',
        accessor: 'subjectName',
        header: t('license:fields.subjectName')
      },
      {
        id: 'subjectAddress',
        accessor: 'subjectAddress',
        header: t('license:fields.subjectAddress'),
        type: ColumnTypesEnum.TEXT
      },
      {
        type: ColumnTypesEnum.TEXT,
        id: 'licenseNumber',
        accessor: 'licenseNumber',
        header: t('license:fields.licenseNumber')
      },
      {
        type: ColumnTypesEnum.TEXT,
        id: 'formNumber',
        accessor: 'formNumber',
        header: t('license:fields.formNumber'),
        minColumnWidth: 10
      },
      {
        type: ColumnTypesEnum.BOOLEAN,
        id: 'belowThreeAndHalfTons',
        accessor: 'belowThreeAndHalfTons',
        header: t('license:fields.belowThreeAndHalfTons'),
        columnFormatter: value => <Value variant="boolean" value={value} />,
        columnXLSXFormatter: value => (value ? t('action.yes') : t('action.no')),
        filter: 'Boolean',
        isFilterable: true,
        isSortable: true,
        columnWidth: 200
      },
      {
        type: ColumnTypesEnum.DATE,
        id: 'printDate',
        accessor: 'printDate',
        header: t('license:fields.printDate'),
        filter: 'DateRangePicker'
      },
      {
        type: ColumnTypesEnum.DATE,
        id: 'validFrom',
        accessor: 'validFrom',
        header: t('license:fields.validFrom'),
        filter: 'DateRangePicker'
      },
      {
        type: ColumnTypesEnum.DATE,
        id: 'validTo',
        accessor: 'validTo',
        header: t('license:fields.validTo'),
        filter: 'DateRangePicker'
      },
      {
        type: ColumnTypesEnum.DATE,
        id: 'dateOfIssueExternal',
        accessor: 'dateOfIssueExternal',
        header: t('license:fields.dateOfIssueExternal'),
        filter: 'DateRangePicker'
      },
      {
        type: ColumnTypesEnum.DICTIONARY,
        id: 'status',
        accessor: 'status',
        header: t('license:fields.status'),
        dictionaryKey: DomainDictionaryEnum.PERMISSION_STATUS,
        columnFormatter: value => (
          <Value variant="dictionary" dictionaryKey={DomainDictionaryEnum.PERMISSION_STATUS} value={value} />
        ),
        columnXLSXFormatter: value => {
          return translate(DomainDictionaryEnum.PERMISSION_STATUS, value, t('emptyMark'));
        },
        filter: 'AutocompleteDictionaryMultiple'
      }
    ],
    Object.values(LicenseCopiesColumnsEnum).filter(column => !Object.values(visibleColumns).includes(column))
  );

  const mappedFilterFields: TableFilterMapper<LicenseCopySnapshotClient, LicenseCopySearchFilter> = {
    applicationNumber: (applicationNumber: string) => ({ applicationNumberContains: applicationNumber }),
    cancellationReason: (cancellationReasons: SelectOptionPartial[]) => ({
      cancellationReasonIn: cancellationReasons.map(cancellationReason => cancellationReason.value)
    }),
    cancelledBy: (cancellationsBy: SelectOptionPartial[]) => ({
      cancellationByIn: cancellationsBy.map(({ value }) => value)
    }),
    cancellationDate: ({ from, to }) => ({
      cancellationDateGreaterThanOrEqual: getCalendarDate(from),
      cancellationDateLessThanOrEqual: getCalendarDate(to)
    }),
    subjectName: (subjectName: string) => ({ subjectNameContains: subjectName }),
    subjectAddress: (subjectAddress: string) => ({ subjectAddressContains: subjectAddress } as any),
    licenseNumber: (licenseNumber: string) => ({ licenseNumberOrCopyNumberContains: licenseNumber }),
    formNumber: (formNumber: string) => ({ formNumberContains: formNumber }),
    printDate: ({ from, to }) => ({
      printDateGreaterThanOrEqual: getCalendarDate(from),
      printDateLessThanOrEqual: getCalendarDate(to)
    }),
    validFrom: ({ from, to }) => ({
      validFromGreaterThanOrEqual: getCalendarDate(from),
      validFromLessThanOrEqual: getCalendarDate(to)
    }),
    validTo: ({ from, to }) => ({
      validToGreaterThanOrEqual: getCalendarDate(from),
      validToLessThanOrEqual: getCalendarDate(to)
    }),
    dateOfIssueExternal: ({ from, to }) => ({
      dateOfIssueExternalGreaterThanOrEqual: getCalendarDate(from),
      dateOfIssueExternalLessThanOrEqual: getCalendarDate(to)
    }),
    belowThreeAndHalfTons: (belowThreeAndHalfTons: boolean) => ({
      belowThreeAndHalfTons
    }),
    signerName: (signers: SelectOptionPartial[]) => ({ signerIn: signers.map(signer => signer.value) }),
    status: (statuses: SelectOptionPartial | SelectOptionPartial[]) => ({
      statusKeyIn: Array.isArray(statuses) ? statuses.map(status => status.value) : [statuses.value]
    })
  };

  const mappedSortFields: TableSortMapper<LicenseCopySnapshotClient> = {
    applicationNumber: 'application.number',
    cancellationReason: 'cancellationReasonKey',
    cancelledBy: 'cancelledBy.name',
    subjectName: 'folder.subject.name',
    subjectAddress: 'application.subject.mainAddress.shortAddress',
    licenseNumber: 'number',
    formNumber: 'form.number',
    signerName: 'signer.name',
    status: 'statusKey'
  };

  return {
    columns,
    mappedFilterFields,
    mappedSortFields
  };
}
