import { PermissionElementMap } from '@libs/permission';

import { PermissionEnum } from '@libs/domain/config/permission/PermissionEnum';

import ReportUIElementEnum from './ReportUIElementEnum';

const ReportPermissionElementMapper: PermissionElementMap<ReportUIElementEnum> = {
  [ReportUIElementEnum.REPORT_DOWNLOAD_BUTTON]: {
    anyOfPermission: [PermissionEnum.REPORT_CONTENT_DOWNLOAD]
  },
  [ReportUIElementEnum.REPORT_EDIT_BUTTON]: { anyOfPermission: [] },
  [ReportUIElementEnum.REPORT_DELETE_BUTTON]: { anyOfPermission: [] },
  [ReportUIElementEnum.REPORT_GENERATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.REPORT_GENERATE]
  },
  [ReportUIElementEnum.REPORT_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.REPORT_DETAILS_VIEW]
  },
  [ReportUIElementEnum.REPORT_LIST]: {
    anyOfPermission: [PermissionEnum.REPORT_REPORT_TYPE_VIEW]
  },
  [ReportUIElementEnum.REPORT_TYPE_SAVE_BUTTON]: {
    anyOfPermission: [PermissionEnum.REPORT_REPORT_TYPE_VIEW]
  },
  [ReportUIElementEnum.REPORT_TYPE_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.REPORT_REPORT_TYPE_VIEW]
  },
  [ReportUIElementEnum.REPORT_LIST_GENERATED_VIEW]: {
    anyOfPermission: [PermissionEnum.REPORT_LIST_GENERATED_VIEW]
  },
  [ReportUIElementEnum.REPORT_GENERATE_AGAIN_BUTTON]: {
    anyOfPermission: [PermissionEnum.REPORT_GENERATE]
  }
};

export default ReportPermissionElementMapper;
