import { useCallback } from 'react';

import { forceArray } from '@libs/common/v2/utils';

import { useAuthSelector } from '@libs/auth/store/reducers';

import { isPermissionCheckEnabled } from './permission.utils';

export interface PermissionEnumType {
  PermissionEnumType: unknown;
}

export type PermissionEnum = PermissionEnumType['PermissionEnumType'];

function usePermissions() {
  const userPermissions = useAuthSelector(({ auth }) => auth.user.permissions);

  const hasPermission = useCallback(
    (permissions: PermissionEnum | PermissionEnum[]) => {
      if (!isPermissionCheckEnabled()) {
        return true;
      }
      return forceArray(permissions).some(permission => userPermissions?.includes(permission));
    },
    [userPermissions]
  );

  const hasAllPermission = useCallback(
    (permissions: PermissionEnum | PermissionEnum[]) => {
      if (!isPermissionCheckEnabled()) {
        return true;
      }
      return forceArray(permissions).every(permission => userPermissions?.includes(permission));
    },
    [userPermissions]
  );

  return { hasPermission, hasAllPermission };
}

export default usePermissions;
