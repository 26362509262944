import { Row, SortByReturn } from 'react-table';
import _ from 'lodash';
import moment from 'moment';

import { DATE_TIME_FORMAT, SelectOption } from '@libs/common/v2';
import { DateRange } from '@libs/common/v2/utils';

export const FilterUtil = {
  inDateRange: (value: string, filterValue?: DateRange): boolean => {
    if (filterValue) {
      const valueDate = value.includes(',') ? moment(value, DATE_TIME_FORMAT) : moment(value);
      if (filterValue.dateFrom && filterValue.dateTo) {
        return !value || valueDate.isBetween(filterValue.dateFrom, filterValue.dateTo, 'day', '[]');
      }
      if (filterValue.dateTo) {
        return !value || valueDate.isBefore(filterValue.dateTo);
      }
    }
    return true;
  }
};

export const SortUtil: Record<any, (...args) => SortByReturn> = {
  numericFalsyLast: <TData extends Row<unknown>, TAccessor = keyof TData['original']>(
    rowA: TData,
    rowB: TData,
    accessor: TAccessor
  ) => {
    const a = rowA.original?.[accessor];
    const b = rowB.original?.[accessor];

    if (!a) {
      return -1;
    }

    if (!b) {
      return 1;
    }

    if (b < a) {
      return -1;
    }

    if (b > a) {
      return 1;
    }

    return 0;
  }
};

export function convertBooleanOptionsToApiFlag(selectedValues: SelectOption[]): null | boolean {
  if ([0, 2].includes(_.size(selectedValues))) {
    return null;
  }
  return selectedValues[0].value;
}

export function rangeDateFilter<T>(rows: T[], filterValue: DateRange, accessor: (row: T) => string) {
  return rows.filter(row => {
    const acc = accessor(row);
    return FilterUtil.inDateRange(acc, filterValue);
  });
}
