import { PaginatedQueryResult } from 'react-query';
import { InvoiceSnapshot } from '@ibtm/domain';

import { IPaginatedModel, useTableLayoutProps } from '@libs/common/v2';

import useReleaseDocumentsQuery from '../api/queries/useReleaseDocumentsQuery';

import useReleaseDocumentsColumns from './useReleaseDocumentsColumns';

function useReleaseDocumentsTable(
  tableHookQuery?: (params, options, queryKey) => PaginatedQueryResult<IPaginatedModel<InvoiceSnapshot>>,
  suspensionId?: string
) {
  const { columns, mappedFilterFields, mappedSortFields } = useReleaseDocumentsColumns();

  return useTableLayoutProps({
    tableHookQuery: tableHookQuery ?? useReleaseDocumentsQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tableOptions: {
        initialState: {
          sortBy: [
            {
              id: 'dateOfIssue',
              desc: true
            }
          ]
        }
      }
    },
    ...(suspensionId && {
      tableHookQueryInitialParams: {
        suspensionId
      }
    })
  });
}

export default useReleaseDocumentsTable;
