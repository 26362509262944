import { QueryConfig, useQuery } from 'react-query';
import { OrganizationStructureNodeSnapshotExternal } from '@avispon/organization-structure/dist/models';

import API from '../endpoints';
import { endpointsConfig } from '../endpoints-config';

import { OrganizationQueryKeysEnum } from './QueryKeysEnum';

export const getOrganizationStructure = async () => {
  const { data } = await API.organizationStructure.getOrganizationStructure({
    ...endpointsConfig.getOrganizationStructure
  });
  return data;
};

export default function useOrganizationStructureQuery(
  queryConfig: QueryConfig<OrganizationStructureNodeSnapshotExternal[]> = {}
) {
  return useQuery<OrganizationStructureNodeSnapshotExternal[]>(
    [OrganizationQueryKeysEnum.ORGANIZATION_STRUCTURE],
    getOrganizationStructure,
    queryConfig
  );
}
