import { useCallback } from 'react';
import { FolderDetails } from '@ibtm/domain';

import { useDialog } from '@libs/common/v2';

import { UpdateDateOfDeathDialog } from '../components/details/tabs/basicData';

function useUpdateDateOfDeathDialog(folderId: string, version: number) {
  const { openDialog } = useDialog();

  const openUpdateDialog = useCallback(
    (dateOfDeath: string, onSuccess: (res: FolderDetails) => void) => {
      openDialog(({ closeDialog }) => (
        <UpdateDateOfDeathDialog
          folderId={folderId}
          closeDialog={closeDialog}
          dateOfDeath={dateOfDeath}
          onSuccess={onSuccess}
          version={version}
        />
      ));
    },
    [folderId, openDialog, version]
  );

  const openUpdateDateOfDeathDialog = (dateOfDeath: string, onSuccess: (res: FolderDetails) => void) => {
    openUpdateDialog(dateOfDeath, onSuccess);
  };

  return {
    openUpdateDateOfDeathDialog
  };
}

export default useUpdateDateOfDeathDialog;
