import { useEffect, useState } from 'react';
import _ from 'lodash';

import { DictionaryEntryNameEnum, useDictionaryEntryValues } from '@libs/dictionary';

import { CheckboxGroup, CheckboxGroupProps, RadioGroup, SelectOption } from '..';

interface IProps extends Omit<CheckboxGroupProps, 'options'> {
  dictionaryName: DictionaryEntryNameEnum;
  isMulti?: boolean;
}

export function DictionaryCheckboxAndRadioGroup({ dictionaryName, isMulti, ...forwardProps }: IProps) {
  const [options, setOptions] = useState<SelectOption[]>([]);
  const dictionaryEntries = useDictionaryEntryValues(dictionaryName);

  useEffect(() => {
    if (dictionaryEntries) {
      const newOptions = dictionaryEntries.filter(({ active }) => active);
      if (!_.isEqual(options, newOptions)) {
        setOptions(newOptions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dictionaryEntries]);

  const Component = isMulti ? CheckboxGroup : RadioGroup;

  return <Component options={options} {...forwardProps} />;
}
