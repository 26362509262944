import { useTranslation } from 'react-i18next';
import { DefaultApiGetTranslationDetailsPageRequest, DictionaryEntryDetails } from '@avispon/dictionary';
import _ from 'lodash';

import { SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO } from '@libs/common/v2/utils';

import { UserFilterV2 } from '@libs/user';

import { DictionaryEntryStatusChip } from '../components/common/chips';
import { DictionaryEntryNameStatusEnum } from '../models';

import { useAllDictionariesInfo } from './useDictionaryInfo';

type DictionaryInfoMap = ReturnType<typeof useAllDictionariesInfo>;

function NestedDictionaryCell(dictionaryId: string, dictionaryInfoMap: DictionaryInfoMap) {
  const getDictionaryParameterById = (dictionaryId: string, parameterName: string): string =>
    _.chain(dictionaryInfoMap)
      .values()
      .find(({ id }) => id === dictionaryId)
      .get(parameterName, '')
      .value();

  const dictionaryName = getDictionaryParameterById(dictionaryId, 'description');

  return dictionaryName;
}

export default function useDictionaryEntriesListColumns() {
  const dictionaryInfoMap = useAllDictionariesInfo();
  const [t] = useTranslation();

  const getDictionaryEntryStatusOptions = () => [
    { value: DictionaryEntryNameStatusEnum.ACTIVE, id: DictionaryEntryNameStatusEnum.ACTIVE, name: t('field.active') },
    {
      value: DictionaryEntryNameStatusEnum.INACTIVE,
      id: DictionaryEntryNameStatusEnum.INACTIVE,
      name: t('field.inactive')
    }
  ];

  const { createColumns } = useCreateColumns<DictionaryEntryDetails>({
    pathToTranslate: 'dictionary:field'
  });

  const mappedSortFields: TableSortMapper<DictionaryEntryDetails> = {
    authorName: 'author',
    modifierName: 'modifier'
  };

  const mappedFilterFields: TableFilterMapper<DictionaryEntryDetails, DefaultApiGetTranslationDetailsPageRequest> = {
    key: (value: string[]) => ({ keyFragment: value }),
    value: (value: string[]) => ({ valueFragment: value }),
    authorName: (authorIds: SelectOption<string>[]) => ({ authorIds: authorIds.map(({ value }) => value) }),
    created: ({ dateFrom, dateTo }: DateRangeISO) => ({
      createdDateFrom: dateFrom || '',
      createdDateTo: dateTo || ''
    }),
    modifierName: (modifierIds: SelectOption<string>[]) => ({ modifierIds: modifierIds.map(({ value }) => value) }),
    modified: ({ dateFrom, dateTo }: DateRangeISO) => ({
      modifiedDateFrom: dateFrom || '',
      modifiedDateTo: dateTo || ''
    }),
    nestedDictionaryId: (value: string) => ({ nestedDictionaryDescription: value }),
    active: (status: SelectOption<string>[]) => ({
      status: status.map(({ value }) => value) as unknown as 'ACTIVE' | 'INACTIVE' | 'ALL'
    })
  };

  const columns = createColumns([
    {
      id: 'key',
      type: 'TEXT',
      width: 350
    },
    {
      id: 'value',
      type: 'TEXT',
      width: 170
    },
    {
      header: 'author',
      type: 'TEXT',
      accessor: 'author.name',
      filter: UserFilterV2,
      width: 170
    },
    {
      id: 'created',
      type: 'DATE_TIME',
      width: 300
    },
    {
      header: 'modifiedBy',
      accessor: 'modifier.name',
      type: 'TEXT',
      filter: UserFilterV2,
      width: 170
    },
    {
      id: 'modified',
      header: 'modifiedDate',
      type: 'DATE_TIME',
      width: 300
    },
    {
      id: 'nestedDictionaryId',
      type: 'TEXT',
      customAccessor: row =>
        row?.nestedDictionaryId ? NestedDictionaryCell(row?.nestedDictionaryId, dictionaryInfoMap) : t('emptyMark'),
      customXlsxAccessor: row =>
        row?.nestedDictionaryId ? NestedDictionaryCell(row?.nestedDictionaryId, dictionaryInfoMap) : t('emptyMark'),
      width: 350
    },
    {
      id: 'active',
      type: 'MULTI_SELECT',
      selectOptions: getDictionaryEntryStatusOptions(),
      customAccessor: row => <DictionaryEntryStatusChip status={row?.active} />,
      tooltipTitle(row) {
        return t(row?.active ? 'field.active' : 'field.inactive');
      }
    }
  ]);

  return { columns, mappedSortFields, mappedFilterFields };
}
