import { useMutation } from 'react-query';
import { CabotageUpdateRequest } from '@ibtm/client-domain';
import { CabotageCreateRequest } from '@ibtm/domain';
import _ from 'lodash';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export type CabotageUpdateRequestClient = CabotageUpdateRequest & { cabotageId: string };

export const createUpdateApplicationCabotage = async (formData: CabotageCreateRequest) => {
  return API.applicationCabotage.createOrUpdateApplicationCabotage(formData.applicationId, formData, {
    ...endpointsConfig.createOrUpdateApplicationCabotage
  });
};

export const updateApplicationCabotageClient = async (formData: CabotageUpdateRequestClient) => {
  return API.client.applicationCabotage.updateApplicationCabotage(
    formData.applicationId,
    formData.cabotageId,
    _.omit(formData, 'cabotageId'),
    {
      ...endpointsConfig.createOrUpdateApplicationCabotage
    }
  );
};

function useCreateUpdateApplicationCabotageMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(updateApplicationCabotageClient, createUpdateApplicationCabotage));
}
export default useCreateUpdateApplicationCabotageMutation;
