import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DICTIONARY_IBTM_DOMAIN_PREFIX } from '@libs/config/constants';
import _ from 'lodash';
import moment from 'moment';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  DictionarySelectField,
  GridLayout,
  Section,
  TextInputField,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { SetValuesForWarnings } from '@libs/domain/drivers/hooks';
import { IDriverForm } from '@libs/domain/drivers/model';

interface IProps {
  subjectNip: string;
  setValuesForWarning: SetValuesForWarnings;
  areDetailsDownloaded: boolean;
}

const getLabel = partialTranslate('drivers:fields');

function GeneralData({ areDetailsDownloaded, subjectNip, setValuesForWarning }: IProps) {
  const [t] = useTranslation();
  const { formMode, getValues, watch } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);
  const [isNipEqual, setIsNipEqual] = useState(true);

  const birthPlace = watch(typedNameV2<IDriverForm>('birthPlace')) as string;

  useEffect(() => {
    if (areDetailsDownloaded) {
      handleInsurancePayerNIPBlur({ target: { value: getValues(typedNameV2<IDriverForm>('insurancePayerNIP')) } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areDetailsDownloaded]);

  const handleInsurancePayerNIPBlur = useCallback(
    e => {
      const value = e?.target?.value;
      if (!viewMode && value && _.size(value) === 10 && value !== subjectNip) {
        setIsNipEqual(false);
      } else {
        setIsNipEqual(true);
      }
    },
    [subjectNip, viewMode]
  );

  return (
    <Section title={t('drivers:sections.generalData')} isCollapsable isModalSection>
      <GridLayout itemProps={{ xs: 12, sm: 6 }}>
        <TextInputField
          inputProps={{ maxLength: 50 }}
          name={typedNameV2<IDriverForm>('name')}
          label={getLabel('name')}
          isRequired
        />
        <TextInputField
          inputProps={{ maxLength: 100 }}
          name={typedNameV2<IDriverForm>('surname')}
          label={getLabel('surname')}
          isRequired
        />
        <DatepickerField
          name={typedNameV2<IDriverForm>('birthDate')}
          label={getLabel('birthDate')}
          maxDate={moment().toISOString()}
          onBlur={e => {
            setValuesForWarning('dateOfBirth', e.target?.value);
          }}
          isRequired
        />

        {viewMode && typeof birthPlace === 'string' && !birthPlace?.startsWith(DICTIONARY_IBTM_DOMAIN_PREFIX) ? (
          <TextInputField name={typedNameV2<IDriverForm>('birthPlace')} label={getLabel('birthPlace')} isRequired />
        ) : (
          <DictionarySelectField
            name={typedNameV2<IDriverForm>('birthPlace')}
            label={getLabel('birthPlace')}
            dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
            isRequired
          />
        )}

        <DictionarySelectField
          name={typedNameV2<IDriverForm>('citizenship')}
          label={getLabel('citizenship')}
          dictionaryName={DomainDictionaryEnum.CITIZENSHIP}
          isRequired
        />
        <TextInputField
          name={typedNameV2<IDriverForm>('insurancePayerNIP')}
          label={getLabel('insurancePayerNIP')}
          inputProps={{ maxLength: 10 }}
          isRequired
          isOnlyPositiveIntegers
          helperText={!isNipEqual && t('drivers:messages.NIPisNotEqual')}
          isHelperTextWarning={!isNipEqual}
          onBlur={handleInsurancePayerNIPBlur}
        />
        <DictionarySelectField
          name={typedNameV2<IDriverForm>('driverCertificateValidityPeriodKey')}
          label={getLabel('periodOfIssuing')}
          dictionaryName={DomainDictionaryEnum.PERIOD_OF_ISSUING_DRIVERS_CERTIFICATE}
          isRequired
        />
        <DictionarySelectField
          name={typedNameV2<IDriverForm>('employmentDocumentKey')}
          label={getLabel('employmentDocument')}
          dictionaryName={DomainDictionaryEnum.EMPLOYMENT_DOCUMENT}
          isRequired
        />
      </GridLayout>
    </Section>
  );
}

export default GeneralData;
