import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccountSnapshot } from '@avispon/user/dist/models';

import { DictionarySelectField, GridItem, GridLayout, Section, TextInputField, typedNameV2 } from '@libs/common/v2';

import { UserDictionaryEntryNameEnum } from '@libs/user/common';
import { useConfigurationContext } from '@libs/user/context';

function WorkSystemSection() {
  const [t] = useTranslation();
  const { isFieldVisible } = useConfigurationContext();

  return (
    <Section title={t('user:section.workSystem')} isCollapsable>
      <GridLayout>
        {isFieldVisible('typeKey') && (
          <GridItem xs={12}>
            <DictionarySelectField
              dictionaryName={UserDictionaryEntryNameEnum.WORK_SYSTEM_TYPE}
              name={typedNameV2<AccountSnapshot>('workSystem.typeKey')}
              label={t('user:field.workSystem')}
              stringValue
              isRequired
            />
          </GridItem>
        )}

        {isFieldVisible('workHours') && (
          <GridItem xs={12}>
            <TextInputField
              name={typedNameV2<AccountSnapshot>('workSystem.workHours')}
              label={t('user:field.workHours')}
              isRequired
            />
          </GridItem>
        )}
      </GridLayout>
    </Section>
  );
}

export default WorkSystemSection;
