import { PricingUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

type Params = {
  pricingId: string;
  formData: PricingUpdateRequest;
};

function updatePricingItem({ pricingId, formData }: Params) {
  return API.pricing.updatePricing(pricingId, formData, {
    ...endpointsConfig.updatePricing
  });
}

function useUpdatePricingItemMutation() {
  return useMutation(updatePricingItem);
}

export default useUpdatePricingItemMutation;
