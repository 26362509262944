import { usePaginatedQuery } from 'react-query';
import { TransportManagersSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { TransportManagerListItemClient } from '../../model';
import { parseTransportManagerListResults } from '../../utils';
import { endpointsConfig } from '../endpoints-config';

import { TransportManagerQueryKeysEnum } from './TransportManagerQueryKeysEnum';

const getTransportManagers = async (_, params: TransportManagersSearchFilter) => {
  const { data } = await API.transportManager.getTransportMangersSnapshotPage(params, {
    ...endpointsConfig.getTransportMangersSnapshotPage
  });
  return {
    ...data,
    content: parseTransportManagerListResults(data.content)
  };
};
const getTransportManagersClient = async (_, params: TransportManagersSearchFilter) => {
  const { data } = await API.client.transportManager.getTransportManagersSnapshotPage('', '', params, {
    ...endpointsConfig.getTransportMangersSnapshotPage
  });
  return {
    ...data,
    content: parseTransportManagerListResults(data.content)
  };
};

export default function useTransportManagersQuery(params: TransportManagersSearchFilter) {
  const { getQuery } = useGetApiQueryByPerspective();
  return usePaginatedQuery<IPaginatedModel<TransportManagerListItemClient>>(
    [TransportManagerQueryKeysEnum.TRANSPORT_MANAGER_LIST, params],
    getQuery(getTransportManagersClient, getTransportManagers)
  );
}
