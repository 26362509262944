import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { useFormV2Context } from '@libs/common/v2';

import { useUpdateProceedingNoteMutation } from '@libs/domain/proceeding';
import { useCreateSuspensionNoteMutation, useUpdateSuspensionNoteMutation } from '@libs/domain/suspensions/api';

interface IHeaderActionsParams {
  proceedingId?: string;
  suspensionId?: string;
  remarkExists?: boolean;
}

function useRemarksHeaderActions({ proceedingId, suspensionId, remarkExists }: IHeaderActionsParams) {
  const [t] = useTranslation();
  const { setValue } = useFormV2Context();
  const { showSuccessSnackbar } = useSnackbar();

  const { mutate: updateSuspensionNote, isLoading: isUpdatingSuspensionNote } = useUpdateSuspensionNoteMutation();
  const { mutate: createSuspensionNote, isLoading: isCreatingSuspensionNote } = useCreateSuspensionNoteMutation();

  const { mutate: updateProceedingNote, isLoading: isSaveProceedingNoteLoading } = useUpdateProceedingNoteMutation();

  const isButtonLoading = useMemo(() => {
    return isUpdatingSuspensionNote || isCreatingSuspensionNote || isSaveProceedingNoteLoading;
  }, [isUpdatingSuspensionNote, isCreatingSuspensionNote, isSaveProceedingNoteLoading]);

  const handleSave = useCallback(
    (
      values: { note: string; version: number },
      _event,
      onSuccess?: () => void,
      showSuccessMessageAndUpdateVersion = true
    ) => {
      if (suspensionId) {
        if (remarkExists) {
          updateSuspensionNote(
            { id: suspensionId, request: { content: values.note, version: values.version || 0 } },
            {
              onSuccess: response => {
                onSuccess?.();
                if (showSuccessMessageAndUpdateVersion) {
                  showSuccessSnackbar(t('suspensions:notes.success.edit'));
                  setValue('version', response.data.version);
                }
              }
            }
          );
        } else {
          createSuspensionNote(
            { suspensionId, request: { content: values.note } },
            {
              onSuccess: response => {
                onSuccess?.();
                if (showSuccessMessageAndUpdateVersion) {
                  showSuccessSnackbar(t('suspensions:notes.success.edit'));
                  setValue('version', response.data.version);
                }
              }
            }
          );
        }
      }

      if (proceedingId) {
        updateProceedingNote(
          { proceedingId, noteUpdateRequest: { content: values.note, version: values.version || 0 } },
          {
            onSuccess: response => {
              onSuccess?.();
              if (showSuccessMessageAndUpdateVersion) {
                showSuccessSnackbar(t('proceeding:administrative.tab.note.saveSuccess'));
                setValue('version', response.data.version);
              }
            }
          }
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [proceedingId, suspensionId, remarkExists]
  );

  return { handleSave, isButtonLoading };
}

export default useRemarksHeaderActions;
