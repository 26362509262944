import { useTranslation } from 'react-i18next';
import { queryCache } from 'react-query';

import { IconButton, TableLayout, useRouter, useTableRowActions } from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';

import { API } from '@libs/domain/api';
import { DomainUIElementEnum, FolderElementsPermissionsEnum, useFolderElementsVisibility } from '@libs/domain/config';

import { ArchiveQueryKeysEnum } from '../../../common';
import {
  useCarryOnArchivedFolderTable,
  useCreateArchivePackageDialog,
  useSendCarryOnArchiveFolderToArchiveDialog,
  useUpdateFolderWarningDialog
} from '../../hooks';
import { FolderArchiveSnapshot } from '../../model';
import { parseFolderArchiveList } from '../../utils';

function CarryOnAchivedFolderTab() {
  const [t] = useTranslation();
  const { checkIsElementVisible } = useElementVisibility();
  const { openCreateArchivePackageDialog } = useCreateArchivePackageDialog();

  const { checkFolderElementVisibility } = useFolderElementsVisibility();

  const tableProps = useCarryOnArchivedFolderTable({
    isMassActionVisible: checkIsElementVisible(DomainUIElementEnum.ARCHIVE_FOLDER_TO_ARCHIVE_FORWARD_TO_ARCHIVE_BUTTON)
  });
  const { openUpdateFolderWarningDialog } = useUpdateFolderWarningDialog();
  const { openSendCarryOnArchiveFolderToArchiveDialog } = useSendCarryOnArchiveFolderToArchiveDialog();
  const { routes } = useRouter();

  const { renderTableActions } = useTableRowActions<FolderArchiveSnapshot>([
    {
      id: DomainUIElementEnum.CARRY_ON_ARCHIVED_FOLDER_SHOW_DETAILS_BUTTON,
      label: 'action.show',
      link: ({ original }) => routes.folderDetails(original.id),
      icon: 'ArrowIcon',
      isHidden: ({ row }) =>
        !checkFolderElementVisibility({
          folderTypeKey: row.original.typeKey,
          permisson: FolderElementsPermissionsEnum.FOLDER_VIEW_PERMISSIONS
        })
    },
    {
      id: DomainUIElementEnum.ARCHIVE_FOLDER_TO_ARCHIVE_FORWARD_TO_ARCHIVE_BUTTON,
      label: 'archive:action.sendToArchive',
      isDisabled: ({ row: { original } }) => original.activeApplicationOnFolder,
      isDisabledLabel: 'archive:folder.message.folderHasActiveApplication',
      onClick: ({ row: { original } }) => openSendCarryOnArchiveFolderToArchiveDialog([original.id]),
      icon: 'TransferIcon'
    },
    {
      id: DomainUIElementEnum.ARCHIVE_FOLDER_TO_ARCHIVE_ADD_NOTE_BUTTON,
      label: 'archive:application.field.warning',
      onClick: ({ row: { original } }) =>
        openUpdateFolderWarningDialog(
          original.id,
          () => queryCache.invalidateQueries(ArchiveQueryKeysEnum.FOLDERS_TO_ARCHIVE),
          original.version,
          original.warning
        ),
      icon: 'NoteIcon'
    }
  ]);

  return (
    <TableLayout
      {...tableProps}
      xlsxDownload={{
        fileName: t('archive:folder.carryOnArchive'),
        pathToXLSXTranslation: 'archive:folder.field',
        apiRequest: params => {
          return API.archiveFolders.getArchiveFolders(params).then(res => ({
            ...res,
            data: {
              ...res.data,
              content: parseFolderArchiveList(res.data.content)
            }
          }));
        }
      }}
      pageTitle={t('archive:folder.carryOnArchive')}
      rowActions={renderTableActions([
        DomainUIElementEnum.CARRY_ON_ARCHIVED_FOLDER_SHOW_DETAILS_BUTTON,
        DomainUIElementEnum.ARCHIVE_FOLDER_TO_ARCHIVE_FORWARD_TO_ARCHIVE_BUTTON,
        DomainUIElementEnum.ARCHIVE_FOLDER_TO_ARCHIVE_ADD_NOTE_BUTTON
      ])}
      isSectionFullTab
      headerActions={
        <IconButton
          icon="ArchiveIcon"
          onClick={openCreateArchivePackageDialog}
          tooltipTitle={t('archive:folder.action.generateArchivePackage')}
          isBackgroundTransparent
        />
      }
    />
  );
}

export default CarryOnAchivedFolderTab;
