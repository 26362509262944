import { InvoiceItemDetails } from '@ibtm/domain';

import { ICorrectionItems } from '../../../model';
import { ReturnItemRange } from '../../../model/ReturnModels';
import { getAllValidNumbersItem } from '../../../utils';
import { calculateSumDiffrence } from '../utils/calculation';

export function useAfterReturnCorrecctionCalculation() {
  /**
   *  Funkcja do przeliczania pozycji korekty dla widoku po wykonaniu zwrotu za pomocą akcji "Zwróć" na ekranie tworzenia korekty
   */
  const calculateItemForCorrectionReturned = (
    items: InvoiceItemDetails[],
    itemsReturned: ReturnItemRange[]
  ): ICorrectionItems[] => {
    return items.map(item => {
      const { id: rowId, name, ordinal, settlementTypeKey } = item;
      const { quantity, unitCost } = getAllValidNumbersItem(item);

      const returnedItem = itemsReturned.find(element => element.positionId === rowId);

      const correctedQuantity = returnedItem ? quantity - (returnedItem.to - returnedItem.from + 1) : quantity;

      return {
        id: rowId,
        name,
        quantity,
        correctedQuantity,
        unitCost,
        correctedUnitCost: unitCost,
        ordinal,
        settlementTypeKey,
        sumDiffrence: 0,
        newSum: unitCost * correctedQuantity,
        sumOfCorrection: calculateSumDiffrence({
          quantity,
          unitCost,
          correctedUnitCost: unitCost,
          correctedQuantity
        })
      };
    });
  };

  return {
    calculateItemForCorrectionReturned
  };
}
