import { MutationConfig } from 'react-query';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface IDeleteParams {
  exploitationBaseId: string;
}

const deleteInspectionBase = async ({ exploitationBaseId }: IDeleteParams) => {
  const { data } = await API.exploitationBase.deleteExploitationBaseInspection(exploitationBaseId, {
    ...endpointsConfig.deleteExploitationBase
  });
  return data;
};

function useExploitationBaseInspectionDeleteMutation(config?: MutationConfig<void, unknown, unknown>) {
  return useMutation(deleteInspectionBase, config);
}

export default useExploitationBaseInspectionDeleteMutation;
