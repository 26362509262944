import { usePaginatedQuery } from 'react-query';
import { SuspensionFolderSummary, SuspensionFolderSummarySearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import { SuspensionQueryKeysEnum } from './QueryKeysEnum';

const getSuspensionsSummary = async (_, params: SuspensionFolderSummarySearchFilter) => {
  const { data } = await API.suspensions.getSuspensionFolderSummaryPage(params, {
    ...endpointsConfig.getSuspensionFolderSummaryPage
  });
  return data;
};

export function useSuspensionsSummaryQuery(params: SuspensionFolderSummarySearchFilter, queryConfig = {}) {
  return usePaginatedQuery<IPaginatedModel<SuspensionFolderSummary>>(
    [SuspensionQueryKeysEnum.SUSPENSION_SUMMARY_LIST, params],
    getSuspensionsSummary,
    queryConfig
  );
}
