import { QueryConfig, useQuery } from 'react-query';
import { DepotPossibleTransferSearchRequest, DepotPossibleTransfersLite } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';
import { ResourceQueryKeysEnum } from '..';

export const getDepotPossibleTransfer = async (request: DepotPossibleTransferSearchRequest) => {
  const { data } = await API.depots.getPossibleTransferDepots(request, {
    ...endpointsConfig.getDepot
  });

  return data;
};

const useDepotPossibleTransfersQuery = (
  request: DepotPossibleTransferSearchRequest,
  queryConfig?: QueryConfig<DepotPossibleTransfersLite, unknown>
) =>
  useQuery<DepotPossibleTransfersLite>(
    [ResourceQueryKeysEnum.DEPOT_DETAILS, request],
    () => getDepotPossibleTransfer(request),
    queryConfig
  );

export default useDepotPossibleTransfersQuery;
