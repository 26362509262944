import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AutocompleteSelectField,
  getBooleanOptions,
  GridLayout,
  InputMode,
  Section,
  TextInputField,
  useFormV2Context
} from '@libs/common/v2';

enum FormValues {
  APPLICATION_NUMBER = 'number'
}

interface IProps {
  applicationNumber: string;
  witd: boolean;
  fieldId: string;
}

function CertificateSApplicationSection({ applicationNumber, witd, fieldId }: IProps) {
  const [t] = useTranslation();
  const { setValue } = useFormV2Context();

  const witdFieldName = `${fieldId}.witd`;

  useEffect(() => {
    setValue(witdFieldName, { value: witd || false });
    setValue(FormValues.APPLICATION_NUMBER, applicationNumber);
  }, [setValue, witd, applicationNumber, witdFieldName]);

  return (
    <Section title={t('applications:group.application')}>
      <GridLayout itemProps={{ xs: 6 }}>
        <TextInputField
          name={FormValues.APPLICATION_NUMBER}
          label={t('sCertificates:field.applicationNumber')}
          inputMode={InputMode.VIEW}
        />
        <AutocompleteSelectField
          name={witdFieldName}
          label={t('sCertificates:field.WITD')}
          options={getBooleanOptions()}
          isClearable={false}
        />
      </GridLayout>
    </Section>
  );
}

export default CertificateSApplicationSection;
