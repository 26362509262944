import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { Tab, TabContent, Tabs, TabsContent } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import { UIElementNameEnum } from '@libs/permission';

import { TabType } from '../model';

type TabsContentType = {
  value: string;
  content: React.ReactNode;
  viewName?: UIElementNameEnum;
};

interface ArchiveTabsContentProps {
  activeTab: string;
  onTabChange: (tab) => void;
  tabs: TabType[];
  tabsContent: TabsContentType[];
}
type TabTitleType = 'archive:application' | 'archive:folder';

export function ArchiveTabsContent({ activeTab, onTabChange, tabs, tabsContent }: ArchiveTabsContentProps) {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Tabs value={activeTab} onChange={onTabChange} className={classes.tabsWrapper} orientation="horizontal">
        {tabs.map((tab: TabType) => (
          <Tab
            key={tab.value}
            isHorizontalTab
            label={<div style={{ textAlign: 'center', margin: 'auto' }}>{t(tab.label as TabTitleType)}</div>}
            value={tab.value}
            viewName={tab.viewName}
          />
        ))}
      </Tabs>
      <TabsContent value={activeTab} className={classes.tabsContent}>
        {tabsContent.map(tabContent => (
          <TabContent
            key={tabContent.value}
            value={tabContent.value}
            isInnerScroll={false}
            viewName={tabContent.viewName}
          >
            {tabContent.content}
          </TabContent>
        ))}
      </TabsContent>
    </>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  tabsContent: {
    overflow: important('hidden')
  },
  tabsWrapper: {
    height: 'auto',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    margin: '0 32px'
  }
}));
