import { useContext, useMemo } from 'react';

import { FormMode, FormV2Context } from '@libs/common/v2';

const useViewModesV2 = (formModeArg?: FormMode) => {
  const { formMode } = useContext(FormV2Context);

  const formModes = useMemo(
    () => ({
      viewMode: (formModeArg || formMode) === FormMode.VIEW,
      editMode: (formModeArg || formMode) === FormMode.EDIT,
      createMode: (formModeArg || formMode) === FormMode.CREATE
    }),
    [formModeArg, formMode]
  );

  return formModes;
};

export default useViewModesV2;
