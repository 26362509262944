import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GroupSnapshotExternal } from '@avispon/group/dist/models';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';

import {
  Dialog,
  FormMode,
  FormV2Context,
  GridLayout,
  SelectOption,
  SwitchField,
  TableLayout,
  typedNameV2
} from '@libs/common/v2';
import { important } from '@libs/common/v2/utils';

import { useUserPermissionGroupTable } from '@libs/user/hooks';

import { PermissionEnum } from '@libs/domain/config/permission/PermissionEnum';

import OrganizationUnitAutocompleteField from './OrganizationUnitAutocompleteField';

interface AddUserToGroupModalProps {
  open: boolean;
  setIsOpen: (param: boolean) => void;
  hideUnitSelect?: boolean;
  onSave: (values, selection) => void;
  selectedGroups?: GroupSnapshotExternal[];
  isLoading?: boolean;
}

const validationSchema = Yup.object({
  unitId: Yup.string().nullable()
});

function AddUserToGroupModal({
  open,
  setIsOpen,
  hideUnitSelect,
  onSave,
  selectedGroups,
  isLoading
}: AddUserToGroupModalProps) {
  const [t] = useTranslation();
  const [chosenUnitId, setChosenUnitId] = useState<string>(null);
  const [tableKey, setTableKey] = useState<number>(0);
  const classes = useStyles();

  const [selectedRows, setSelectedRows] = useState<GroupSnapshotExternal[]>([]);

  const tableProps = useUserPermissionGroupTable({
    unitIds: chosenUnitId ? [chosenUnitId] : [],
    data: selectedGroups,
    selectedRows,
    setSelectedRows
  });

  const initialValues = {
    unitId: '',
    withSubordinates: true
  };

  const changeUnit = (organizationUnit: SelectOption) => {
    setChosenUnitId(organizationUnit?.id);
    setTableKey(tableKey + 1);
  };

  const form = useForm<Record<string, any>>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onBlur'
  });

  const formDataProvider = useMemo(() => {
    return { ...form, formMode: FormMode.CREATE };
  }, [form]);

  const handleCancel = () => {
    setIsOpen(false);
    form.reset();
    setChosenUnitId(null);
  };

  const handleSubmitForm = async values => {
    await onSave({ unitId: chosenUnitId, includeSubordinates: values?.withSubordinates }, selectedRows);
  };

  return (
    <FormV2Context.Provider value={formDataProvider}>
      <Dialog
        title={t('user:userPermissionGroupAddTitle')}
        confirmText={t('global:action.save')}
        cancelText={t('action.cancel')}
        onConfirm={form.handleSubmit(handleSubmitForm)}
        onCancel={handleCancel}
        confirmButtonTooltipText={{ disabled: t('administration:organizationUnit.message.organizationUnitRequired') }}
        isConfirmLoading={isLoading}
        isConfirmButtonDisabled={selectedRows.length === 0}
        isOpen={open}
      >
        <GridLayout itemProps={{ xs: 12 }}>
          {!hideUnitSelect && (
            <OrganizationUnitAutocompleteField
              name="unitId"
              label={t('field.organizationUnit')}
              permissionNames={[PermissionEnum.GROUP_ADMIN]}
              optionChanged={changeUnit}
              renderOption={value => value?.name}
              isRequired
            />
          )}
          <SwitchField
            name={typedNameV2<GroupSnapshotExternal>('withSubordinates')}
            label={t('user:field.includeSubordinates')}
            className="inline-flex"
            valueTrueText={t('action.yes')}
            valueFalseText={t('action.no')}
          />
        </GridLayout>
        {(chosenUnitId || hideUnitSelect) && (
          <TableLayout
            {...tableProps}
            pageTitle={t('user:dialog.userPermissionGroupsTitle')}
            sectionClassName={classes.table}
            sectionContentClassName={classes.tableContent}
            rowActions={null}
            key={tableKey}
            isSection
            isHiddenSelectRowHeader
          />
        )}
      </Dialog>
    </FormV2Context.Provider>
  );
}

const useStyles = makeStyles(() => ({
  table: {
    maxHeight: important('60vh')
  },
  tableContent: {
    height: important('50vh')
  }
}));

export default AddUserToGroupModal;
