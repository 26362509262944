import { useCallback } from 'react';

import { FormMode, useDialog } from '@libs/common/v2';

import { FormOperationDetails } from '../components/proceeding-details/tabs';
import { ProceedingTypeKeyEnum } from '..';

function useFormOperationDetails(
  folderId: string,
  proceedingTypeKey?: ProceedingTypeKeyEnum,
  type?: ProceedingTypeKeyEnum,
  isSuspensions?: boolean,
  proceedingId?: string
) {
  const { openDialog } = useDialog();
  const openDetails = useCallback(
    (formMode: FormMode, refreshTable: () => void, id?: string, parentId?: string) => {
      openDialog(({ closeDialog }) => (
        <FormOperationDetails
          formMode={formMode}
          refreshTable={refreshTable}
          closeDialog={closeDialog}
          id={id}
          folderId={folderId}
          parentId={parentId}
          proceedingTypeKey={proceedingTypeKey}
          type={type}
          isSuspensions={isSuspensions}
          proceedingId={proceedingId}
        />
      ));
    },
    [openDialog, folderId, proceedingTypeKey, type, isSuspensions, proceedingId]
  );

  const openDetailsDialog = (formMode: FormMode, refreshTable: () => void, id?: string, objectId?: string) => {
    openDetails(formMode, refreshTable, id, objectId);
  };

  return {
    openDetailsDialog
  };
}

export default useFormOperationDetails;
