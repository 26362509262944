import { QueryConfig, useQuery } from 'react-query';
import { SuspensionSnapshot } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import { SuspensionQueryKeysEnum } from './QueryKeysEnum';

const getSuspensionDetails = async (_, suspensionId: string) => {
  const { data } = await API.suspensions.getSuspension(suspensionId, {
    ...endpointsConfig.getSuspension
  });
  return data;
};

export default function useSuspensionDetailsQuery(suspensionId: string, queryConfig: QueryConfig<any, unknown> = {}) {
  return useQuery<SuspensionSnapshot>(
    [SuspensionQueryKeysEnum.SUSPENSION_DETAILS, suspensionId],
    getSuspensionDetails,
    queryConfig
  );
}
