import { useDialog } from '@libs/common/v2';

import { PartnerApplicantsCreateDialog } from '../../components';

interface IProps {
  applicationId?: string;
  refetch?: () => void;
}

function usePartnerApplicantsCreateDialog({ applicationId }: IProps) {
  const { openDialog } = useDialog();

  const showPartnerApplicantsCreateDialog = (refetch: () => void) => {
    openDialog(({ closeDialog }) => (
      <PartnerApplicantsCreateDialog
        onCloseDialog={closeDialog}
        isOpen
        applicationId={applicationId}
        refetch={refetch}
      />
    ));
  };

  return { showPartnerApplicantsCreateDialog };
}

export default usePartnerApplicantsCreateDialog;
