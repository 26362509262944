/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, forwardRef, useCallback, useContext, useImperativeHandle, useMemo, useRef } from 'react';

const ctxInitialState = {
  subscribe: (callback: (event: TableEvent) => void) => () => {},
  emitEvent: (event: TableEvent) => {}
};
const TableEventsContext = createContext(ctxInitialState);
interface IProps {
  children: React.ReactNode;
}

export enum TableEvent {
  ON_FILTER_CLEAR,
  RERENDER_ROW_ACTIONS,
  RERENDER_CURRENT_EDITED_ROW
}
export type TableEventContext = typeof ctxInitialState;

export const TableEventsContextProvider = forwardRef(({ children }: React.PropsWithRef<IProps>, ref) => {
  const subscribers = useRef<Set<(event: TableEvent) => void>>(new Set());

  const subscribe = useCallback((callback: (event: TableEvent) => void) => {
    subscribers.current.add(callback);
    return () => {
      subscribers.current.delete(callback);
    };
  }, []);
  const emitEvent = useCallback((event: TableEvent) => {
    subscribers.current.forEach(callback => callback(event));
  }, []);

  const ctxValue = useMemo(
    () => ({
      subscribe,
      emitEvent
    }),
    [subscribe, emitEvent]
  );

  useImperativeHandle(ref, () => ctxValue, [ctxValue]);

  return <TableEventsContext.Provider value={ctxValue}>{children}</TableEventsContext.Provider>;
}) as (props: IProps & React.RefAttributes<TableEventContext>) => JSX.Element;

export const useTableEventsContext = () => useContext(TableEventsContext);
