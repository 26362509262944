import { QueryConfig, useQuery } from 'react-query';
import { ReportDetailsSnapshot } from '@stack/report/dist/models';

import { API, endpointsConfig, ReportQueryKeysEnum } from '..';

const getReportDetails = async (_, reportId: string) => {
  const { data } = await API.report.getReport(reportId, { ...endpointsConfig.getReport });
  return data;
};

export default function useReportDetailsQuery(
  params: { reportId: string },
  queryConfig?: QueryConfig<ReportDetailsSnapshot>
) {
  return useQuery<ReportDetailsSnapshot>(
    [ReportQueryKeysEnum.REPORT_DETAILS, params.reportId],
    getReportDetails,
    queryConfig
  );
}
