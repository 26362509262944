import { QueryCache } from 'react-query';
import { UserPermissionsListExternal } from '@avispon/group/dist/models';
import { AccountState, AccountStateLite, PersonalDataSnapshot } from '@avispon/user/dist/models';
import { AxiosPromise } from 'axios';

import { AuthService } from '@libs/auth/services';

import { API } from '../../api';

export const SET_USER_DATA = '[USER] SET DATA';
export const USER_LOGGED_OUT = '[USER] LOGGED OUT';

interface ExtendedPersonalData extends PersonalDataSnapshot {
  displayName?: string;
  email?: string;
  serviceNumber?: string;
}

export enum GetUserStateTypeEnum {
  DEFAULT,
  LITE
}
const getStateQuery = (stateType: GetUserStateTypeEnum) => {
  switch (stateType) {
    case GetUserStateTypeEnum.LITE:
      return API.user.getLiteState();
    case GetUserStateTypeEnum.DEFAULT:
      return API.user.getState();
    default:
      return API.user.getLiteState();
  }
};

export function getUserData(
  params?: object | string[],
  userSchema?: object,
  getUserStateType?: GetUserStateTypeEnum
): (dispatch: any) => Promise<void> {
  type AxiosRequests = [AxiosPromise<AccountState | AccountStateLite>, AxiosPromise<UserPermissionsListExternal>];
  const requests = [getStateQuery(getUserStateType), API.group.listMyPermissions({})];
  return dispatch =>
    Promise.all(requests as AxiosRequests)
      .then(([accountStateResponse, permissionsResponse]) => {
        const { data } = accountStateResponse;
        const accountData = 'account' in data ? data.account : accountStateResponse.data || {};
        const personalData: ExtendedPersonalData = {
          ...('personalData' in data ? data.personalData : data)
        };
        personalData.displayName = `${personalData?.name ?? ''} ${personalData?.surname ?? ''}`;
        personalData.serviceNumber = 'serviceNumber' in accountData ? accountData.serviceNumber : null;

        const accountPermissions = 'permissions' in accountData ? accountData.permissions : [];
        const accountPermissionsArray = Array.from(accountPermissions);

        const permissions =
          (accountPermissionsArray?.length
            ? accountPermissions
            : permissionsResponse.data.userPermissions?.map(({ permission }) => permission?.name)) || [];

        const user = userSchema || {
          personalData,
          accountData,
          organizationUnit: 'organizationUnit' in accountData ? accountData.organizationUnit || null : null,
          permissions,
          role: 'admin'
        };

        dispatch({
          type: SET_USER_DATA,
          payload: user
        });
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
}

export function logoutWKUser(queryCache: QueryCache) {
  return dispatch => {
    AuthService.logout(queryCache);
    dispatch({ type: USER_LOGGED_OUT });
    window.open(process.env.REACT_APP_WK_LOGOUT, '_self');
    AuthService.clearSessionStorage();
    return Promise.resolve();
  };
}
export function sendLogoutMsgToChannel() {
  AuthService.sendLogoutBroadcast();
}

export function logoutUser(queryCache: QueryCache, redirectOptions = null) {
  return dispatch => {
    AuthService.logout(queryCache, redirectOptions);
    dispatch({ type: USER_LOGGED_OUT });

    return Promise.resolve();
  };
}
