import { AccountSnapshot } from '@avispon/user';

import { useCreateColumns } from '@libs/common/v2';

function useUserPermissionGroupColumns() {
  const { createColumns } = useCreateColumns<AccountSnapshot>({
    pathToTranslate: 'user:field'
  });

  const columns = createColumns([
    {
      type: 'TEXT',
      header: 'name',
      accessor: 'personalData.name'
    },
    {
      type: 'TEXT',
      header: 'surname',
      accessor: 'personalData.surname'
    },
    {
      type: 'TEXT',
      accessor: 'serviceNumber'
    }
  ]);

  return { columns };
}

export default useUserPermissionGroupColumns;
