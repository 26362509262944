import { useEffect, useMemo, useState } from 'react';
import { PluginHook, TableOptions as TableOptionsType, useTable } from 'react-table';

import { CommonTablePlugins, ITableAdapter, ITableLayoutColumn } from '@libs/common/v2';

type QueryData<T> = T[] | Record<string, any>[];

interface IProps<T extends Record<string, any>, K extends Record<string, any> = T> {
  columns: ITableLayoutColumn<T, K>[] | ((mapper: Record<string, number>) => ITableLayoutColumn<T, K>[]);
  queryData: QueryData<T>;
  getRowId: (row) => string;
  tableOptions: Partial<TableOptionsType<T>>;
  tablePlugins: Array<PluginHook<T>>;
  longestRow: Record<string, number>;
}

// Domyślna wartość danych - wyciągnięta poza hook żeby nie wywoływać rerenderingu
const empty = [];

function useCreateTableInstance<T extends Record<string, any>, K extends Record<string, any> = T>({
  columns,
  queryData,
  getRowId,
  tableOptions,
  tablePlugins = [],
  longestRow
}: IProps<T, K>): ITableAdapter<T, K> {
  const [rowToFn, setRowToFn] = useState({});

  useEffect(() => {
    if (longestRow && !rowToFn) {
      setRowToFn(longestRow);
    }
  }, [longestRow, rowToFn]);

  const options = useMemo(() => tableOptions, [tableOptions]);
  const table = useTable(
    {
      columns: useMemo(() => (typeof columns === 'function' ? columns(rowToFn) : columns), [columns, rowToFn]),
      data: useMemo(() => (queryData ?? empty) as T[], [queryData]),
      getRowId,
      ...options
    },
    ...CommonTablePlugins,
    ...tablePlugins
  );

  return useMemo(() => table, [table]);
}

export default useCreateTableInstance;
