import { useMemo } from 'react';
import { useParams } from 'react-router';

import { FormMode, FormV2Context } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';
import { BasicLimitTable } from '@libs/domain/foreign-permission';

function DisposablePermitsDetailsTablePage() {
  const values = useMemo(() => ({ formMode: FormMode.VIEW }), []);
  const { id } = useParams<{ id: string }>();

  return (
    <FormV2Context.Provider value={values}>
      <BasicLimitTable
        isSection={false}
        queryInitialParams={{ typeKeyIn: [DomainDictionaryEntry.FOREIGN_PERMIT_TYPE.SINGLE], folderId: id }}
        isYearFilter
        isGoBackButtonVisible
      />
    </FormV2Context.Provider>
  );
}
export default DisposablePermitsDetailsTablePage;
