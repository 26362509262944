import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { FoldersApiTransferFolderRequest } from '@ibtm/domain';
import { FolderAssignToTransferRequest } from '@ibtm/domain/dist/models';
import { useAppConfig } from '@libs/app-config';
import * as Yup from 'yup';

import {
  AutocompleteLazyFetchSelect,
  Dialog,
  DictionarySelectField,
  FormV2Context,
  GridLayout,
  NUMBER_OF_AUTOCOMPLETE_RESULTS,
  SelectOption
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useDocumentTemplatesQuery } from '@libs/document-template';
import { useDownloadDocumentFile } from '@libs/file';
import { API, UserQueryKeysEnum } from '@libs/user/api';

import { DomainDictionaryEnum } from '@libs/domain/config';

import {
  RoadTransportQueryKeysEnum,
  useFolderDocumentGenerationMutation,
  useFolderTransferMutations
} from '../../../api';
import useFolderAssignToTransferMutations from '../../../api/mutations/folders/useFolderAssignToTransferRequest';
import { IFoldersToTransferClient } from '../../../models';

interface IProps {
  initialData: IFoldersToTransferClient | string[];
  closeDialog: () => void;
  isAssignToTransferForm?: boolean;
}

function FolderTransferForm({ initialData, closeDialog, isAssignToTransferForm }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();

  const { mutate: transferFolder, isLoading } = useFolderTransferMutations();
  const { mutate: assignToTransferFolder, isLoading: isAssignToTransferLoading } = useFolderAssignToTransferMutations();
  const [generateDocument, state] = useFolderDocumentGenerationMutation();

  const { templateNames } = useAppConfig();
  const { downloadFile, isFetching } = useDownloadDocumentFile({ onSuccess: closeDialog });

  const { data: transferFolderTemplate } = useDocumentTemplatesQuery({
    nameFragment: templateNames?.transferFolder
  });

  const isSingleFolderSelection = !Array.isArray(initialData);

  const isActionLoading = useMemo(
    () => isLoading || isAssignToTransferLoading || isFetching || state.isLoading,
    [isLoading, isAssignToTransferLoading, isFetching, state.isLoading]
  );

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        ...(isAssignToTransferForm && { referentId: Yup.object().nullable().required() }),
        ...(!isAssignToTransferForm && { transferredToSta: Yup.object().nullable().required() })
      })
    ),
    defaultValues: {
      transferredToSta: isSingleFolderSelection ? initialData?.transferredToSta || null : null,
      referentId: isSingleFolderSelection ? initialData?.referentName || null : null
    }
  });

  useEffect(() => {
    if (isSingleFolderSelection && initialData.referentName) {
      onSelectedChange(
        null,
        {
          name: initialData.referentName
        },
        'referentId'
      );
    }

    if (isSingleFolderSelection && initialData.transferredToSta) {
      onSelectedChange(
        null,
        {
          name: initialData.transferredToSta
        },
        'transferredToSta'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);

  const onSubmit = formData => {
    if (!isAssignToTransferForm && isSingleFolderSelection) {
      const requestData: FoldersApiTransferFolderRequest = {
        folderId: initialData.id,
        folderTransferRequest: {
          transferredToSta: formData.transferredToSta?.name
        }
      };

      return transferFolder(requestData, {
        onSuccess: ({ data }) => {
          showSnackbar('success', t('roadTransport:messages.transferFolderSuccess'));

          generateDocument(
            {
              folderId: data.id,
              data: { documentTemplateId: transferFolderTemplate.content[0].id }
            },
            {
              onSuccess: ({ data }) => {
                showSnackbar('success', t('roadTransport:messages.generateDocumentTransferFolder'));
                downloadFile(data.file);
              },
              onError: () => closeDialog(),
              onSettled: () => {
                queryCache.invalidateQueries(RoadTransportQueryKeysEnum.FOLDERS_TO_TRANSFER_LIST);
              }
            }
          );
        }
      });
    }

    const requestData: FolderAssignToTransferRequest = {
      folderIds: isSingleFolderSelection ? [initialData.id] : initialData,
      referentId: formData?.referentId?.id || (isSingleFolderSelection && initialData?.referentId)
    };

    return assignToTransferFolder(requestData, {
      onSuccess: () => {
        showSnackbar('success', t('roadTransport:messages.transferFolderSuccess'));
        queryCache.invalidateQueries(RoadTransportQueryKeysEnum.FOLDERS_TO_TRANSFER_LIST);

        closeDialog();
      }
    });
  };

  const getOptionLabel = (option: SelectOption) => {
    return option?.name ? `${option.name}` : t('emptyMark');
  };

  const fetchUserFunction = (searchText: string, page: number) => {
    return API.user.accountController.searchLiteAccounts({
      page,
      size: NUMBER_OF_AUTOCOMPLETE_RESULTS,
      query: searchText,
      sort: [],
      statuses: ['VERIFIED']
    });
  };

  const parseDataToSelect = (data): SelectOption[] => {
    return data?.data?.content.map(record => ({
      id: record.id,
      value: record.id,
      name: record?.name || record?.surname ? `${record?.name} ${record?.surname}` : record.serviceNumber
    }));
  };

  const onSelectedChange = (_, value, labelName) => {
    setValue(labelName, value);
  };

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={
        isAssignToTransferForm
          ? t('roadTransport:dialog.assignToTransferFolderTitle')
          : t('roadTransport:dialog.transferFolderTitle')
      }
      confirmText={isAssignToTransferForm ? t('action.assign') : t('action.transfer')}
      cancelText={t('action.cancel')}
      onConfirm={() => handleSubmit(onSubmit)()}
      onCancel={closeDialog}
      isConfirmLoading={isActionLoading}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <GridLayout itemProps={{ xs: 12 }}>
          {isAssignToTransferForm ? (
            <Controller
              control={control}
              name="referentId"
              render={({ field: { value, onBlur }, fieldState }) => (
                <AutocompleteLazyFetchSelect
                  fetchFunctionResolverWithPage={fetchUserFunction}
                  fetchedDataSelectParser={parseDataToSelect}
                  queryKey={UserQueryKeysEnum.USER_ACCOUNT_CONTROLLER_FIELD}
                  name="user"
                  getOptionLabel={getOptionLabel}
                  renderOption={getOptionLabel}
                  label={t('roadTransport:fields.referent')}
                  value={value}
                  onBlur={onBlur}
                  isError={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  onChange={(e, v) => {
                    onSelectedChange(e, v, 'referentId');
                  }}
                  key="user"
                  isRequired
                />
              )}
            />
          ) : (
            <DictionarySelectField
              dictionaryName={DomainDictionaryEnum.AUTHORITY}
              name="transferredToSta"
              label={t('roadTransport:fields.transferredToAuthority')}
              isRequired={!isAssignToTransferForm}
            />
          )}
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default FolderTransferForm;
