import { GroupUpdateRequest } from '@avispon/group/dist/models';

import { useMutation } from '@libs/common/v2/api';

import API from '../endpoints';
import { endpointsConfig } from '../endpoints-config';

const updateGroup = async ({ id, request }: { id: string; request: GroupUpdateRequest }) => {
  const { data } = await API.group.update(
    { groupId: id, body: request },
    {
      ...endpointsConfig.update
    }
  );
  return data;
};

export default function useUpdateGroupMutation() {
  return useMutation(updateGroup);
}
