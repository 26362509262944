import { FolderRentalReturnCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const returnFolder = (params: FolderRentalReturnCreateRequest) =>
  API.borrowFolders.returnRentedFolder(params, {
    ...endpointsConfig.returnRentedFolder
  });

export const useReturnFolderMutation = () => useMutation(returnFolder);
