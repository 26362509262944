import { SubjectPartnershipPartnerSnapshot } from '@ibtm/domain';

import { ITableLayoutColumn, useTableLayoutProps } from '@libs/common/v2';

function useEntrepreneurTable(
  data: SubjectPartnershipPartnerSnapshot[],
  columns: ITableLayoutColumn<SubjectPartnershipPartnerSnapshot>[]
) {
  return useTableLayoutProps({
    tableQuery: data,
    tableHookOptions: {
      columns,
      tableOptions: {
        manualPagination: false,
        manualSortBy: false,
        manualFilters: false,
        initialState: {
          pageSize: 10
        }
      }
    }
  });
}

export default useEntrepreneurTable;
