import { ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PluginHook, Row, TableOptions } from 'react-table';
import { ApplicationSearchFilter, ApplicationSnapshot } from '@ibtm/domain';
import { isNull } from 'lodash';

import {
  IAdditionalFilter,
  IExtendedColumn,
  ITableAdapter,
  TableLayout,
  useMultipleSelectColumn
} from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';

import { useApplicationAdditionalFilters, useApplicationTable, useApplicationTableButtons } from '../hooks';

import ApplicationAssignEditorSelectColumnButton from './ApplicationAssignEditorSelectColumnButton';

interface IProps {
  title?: string;
  emptyTableText?: string;
  tableOptions?: Partial<TableOptions<ApplicationSnapshot>>;
  visibleColumns?: IExtendedColumn<ApplicationSnapshot>[];
  hiddenColumns?: Array<string>;
  queryParams?: ApplicationSearchFilter;
  additionalFilters?: IAdditionalFilter[];
  headerActions?: ReactNode;
  actionsColumnWidth?: number;
  rowActions?: any;
  tablePlugins?: Array<PluginHook<ApplicationSnapshot>>;
  isCollapsable?: boolean;
  isSection?: boolean;
  checkDisabled?: (table?: ITableAdapter<ApplicationSnapshot>, row?: Row<ApplicationSnapshot>) => boolean;
  globalFilters?: IAdditionalFilter[];
  tableHookQueryConfig?: Record<string, unknown>;
  initialParamsConverter?: Record<string, (value) => unknown>;
  isActionColumnEnabled?: boolean;
  tableData?: ApplicationSnapshot[];
  isFilterEnabled?: boolean;
  isHeaderHidden?: boolean;
  children?: ReactNode;
  isAdditionalFiltersVisible?: boolean;
  isXlsxDownloadVisible?: boolean;
  isRefreshEnabled?: boolean;
  isAssignEditorActionEnabled?: boolean;
  selectionColumnWidth?: number;
  assignToMeActionKey?: DomainUIElementEnum;
  isHiddenSelectRowHeader?: boolean;
}

function ApplicationsTable({
  title,
  emptyTableText,
  tableOptions,
  queryParams,
  headerActions,
  visibleColumns,
  hiddenColumns,
  tablePlugins = [],
  checkDisabled,
  actionsColumnWidth,
  isCollapsable,
  rowActions,
  isSection,
  globalFilters,
  tableHookQueryConfig,
  initialParamsConverter,
  isActionColumnEnabled = true,
  tableData,
  isFilterEnabled,
  isHeaderHidden,
  children,
  isAdditionalFiltersVisible = true,
  isXlsxDownloadVisible = true,
  isRefreshEnabled = true,
  isAssignEditorActionEnabled,
  selectionColumnWidth,
  assignToMeActionKey = DomainUIElementEnum.APPLICATION_PRINT_ASSIGN_TO_ME_BUTTON,
  isHiddenSelectRowHeader
}: IProps) {
  const [t] = useTranslation();
  const { checkIsElementVisible } = useElementVisibility();
  const isAssignToMeVisible = useMemo(() => {
    return checkIsElementVisible(assignToMeActionKey);
  }, [assignToMeActionKey, checkIsElementVisible]);
  const { TABLE_CREATE } = useApplicationTableButtons(DomainUIElementEnum.APPLICATION_ADD_APPLICATION_BUTTON);
  const { additionalFilters } = useApplicationAdditionalFilters();
  const { getQuery } = useGetApiQueryByPerspective();
  const selectRowPlugin = useMultipleSelectColumn(ApplicationAssignEditorSelectColumnButton, true, {
    enabledCheckboxTooltipTitle: t('applications:action.assignToMe'),
    headerCheckboxTooltipTitle: t('other:component.checkboxInput.checkAll')
  });

  const tableProps = useApplicationTable({
    tableOptions,
    visibleColumns,
    queryParams,
    tablePlugins: [isAssignEditorActionEnabled && isAssignToMeVisible && selectRowPlugin, ...tablePlugins].filter(
      Boolean
    ),
    hiddenColumns,
    tableHookQueryConfig,
    initialParamsConverter,
    tableData
  });

  const { isOperatorPortal } = useDomainConfigContext();

  const memoizedHeaderActions = useMemo(() => {
    if (isOperatorPortal && !isNull(headerActions)) {
      return headerActions ?? TABLE_CREATE;
    }
    return null;
  }, [isOperatorPortal, TABLE_CREATE, headerActions]);

  const xlsxDownloadApi = useCallback(params => {
    const apiMethod = getQuery(
      () => API.client.application.getApplicationsSnapshotPageForFolder('', '', params),
      () => API.application.getApplicationsSnapshotPage(params)
    );

    return apiMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableLayout
      {...tableProps}
      pageTitle={title ?? t('applications:list.title')}
      xlsxDownload={
        isXlsxDownloadVisible && {
          fileName: title ?? t('applications:list.title'),
          pathToXLSXTranslation: 'applications:field',
          apiRequest: xlsxDownloadApi
        }
      }
      additionalFilters={isAdditionalFiltersVisible && additionalFilters}
      headerActions={memoizedHeaderActions}
      isActionColumnEnabled={isActionColumnEnabled}
      rowActions={(row, table) => rowActions?.(row, table)}
      isSection={isSection}
      isCollapsable={isCollapsable}
      globalFilters={globalFilters}
      emptyTableText={emptyTableText}
      actionsColumnWidth={actionsColumnWidth}
      checkDisabled={checkDisabled}
      isFilterEnabled={isFilterEnabled}
      isHeaderHidden={isHeaderHidden}
      isRefreshEnabled={isRefreshEnabled}
      selectionColumnWidth={selectionColumnWidth}
      isHiddenSelectRowHeader={isHiddenSelectRowHeader}
    >
      {children}
    </TableLayout>
  );
}

export default ApplicationsTable;
