import { PermissionElementMap } from '@libs/permission';

import { PermissionEnum } from '@libs/domain/config/permission/PermissionEnum';

import GroupUIElementEnum from './GroupUIElementEnum';

const GroupPermissionElementMapper: PermissionElementMap<GroupUIElementEnum> = {
  [GroupUIElementEnum.GROUPS_EDIT_BUTTON]: {
    allOfPermissions: [PermissionEnum.GROUP_ADMIN, PermissionEnum.GROUP_VIEW]
  },
  [GroupUIElementEnum.GROUPS_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.GROUP_ADMIN]
  },
  [GroupUIElementEnum.GROUPS_DUPLICATE_BUTTON]: {
    allOfPermissions: [PermissionEnum.GROUP_ADMIN, PermissionEnum.GROUP_VIEW]
  },
  [GroupUIElementEnum.GROUPS_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.GROUP_VIEW]
  },
  [GroupUIElementEnum.GROUPS_LIST_TAB]: {
    anyOfPermission: [PermissionEnum.GROUP_VIEW]
  },
  [GroupUIElementEnum.GROUPS_EXTRA_TABLE_FILTER]: {
    anyOfPermission: [PermissionEnum.GROUP_VIEW]
  },
  [GroupUIElementEnum.GROUPS_CREATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.GROUP_ADMIN]
  }
};

export default GroupPermissionElementMapper;
