import { FolderLimitTransferDetails, FolderLimitTransferFilter } from '@ibtm/domain';

import { NumberRange, SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';

function useLimitTransferColumns() {
  const { createColumns } = useCreateColumns<FolderLimitTransferDetails>({
    pathToTranslate: 'folder:details.tab.singleAuthorization.section.limitTransfer.field'
  });

  const mappedSortFields: TableSortMapper<FolderLimitTransferDetails> = {
    amount: 'correctionAmount',
    sourceFolderNumber: 'sourceFolder.number',
    targetFolderNumber: 'targetFolder.number'
  };

  const mappedFilterFields: TableFilterMapper<FolderLimitTransferDetails, FolderLimitTransferFilter> = {
    countryCodeKey: (countryCodes: SelectOption<string>[]) => ({
      countryCodeKeyIn: countryCodes.map(({ value }) => value)
    }),
    resourceFormNameKey: (resourceFormNames: SelectOption<string>[]) => ({
      resourceFormNameKeyIn: resourceFormNames.map(({ value }) => value)
    }),
    amount: ({ min, max }: NumberRange) => ({ amountGreaterThanOrEqual: min, amountLessThanOrEqual: max }),
    reasonKey: (reasonKeys: SelectOption<string>[]) => ({ reasonKeyIn: reasonKeys.map(({ value }) => value) }),
    sourceFolderNumber: (sourceFolder: string) => ({ sourceFolderNumberContains: sourceFolder }),
    targetFolderNumber: (targetFolder: string) => ({ targetFolderNumberContains: targetFolder })
  };

  const columns = createColumns([
    {
      accessor: 'countryCodeKey',
      header: 'country',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.COUNTRY_CODE
    },
    {
      accessor: 'resourceFormNameKey',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_NAME
    },
    {
      accessor: 'amount',
      type: 'NUMBER_RANGE'
    },
    {
      accessor: 'sourceFolder.number',
      header: 'sourceFolder',
      type: 'TEXT'
    },
    {
      accessor: 'targetFolder.number',
      header: 'targetFolder',
      type: 'TEXT'
    },
    {
      accessor: 'reasonKey',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.CORRECTIONS_OF_RATIONING_FOREIGN_PERMITS
    }
  ]);

  return { columns, mappedFilterFields, mappedSortFields };
}

export default useLimitTransferColumns;
