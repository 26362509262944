import { useTranslation } from 'react-i18next';
import { object as YupObject, ref as YupRef, string as YupString } from 'yup';

import { FormMode, useValidatorUtil, ValidatorEnums } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { UserFormValues } from '@libs/user/models';

export default function useDefaultValidation(formMode: FormMode) {
  const [t] = useTranslation();
  const { createMode } = useViewModesV2(formMode);

  const validatePassword = useValidatorUtil(ValidatorEnums.PASSWORD);
  return YupObject<Partial<UserFormValues>>({
    personalData: YupObject({
      name: YupString()
        .required()
        .matches(...useValidatorUtil(ValidatorEnums.USER_FIRST_NAME)),
      surname: YupString()
        .required()
        .matches(...useValidatorUtil(ValidatorEnums.USER_SURNAME)),
      personalNumber: YupString()
        .required()
        .matches(...useValidatorUtil(ValidatorEnums.PESEL))
    }),
    email: YupString()
      .required()
      .matches(...useValidatorUtil(ValidatorEnums.EMAIL)),
    serviceNumber: YupString()
      .required()
      .matches(...useValidatorUtil(ValidatorEnums.LOGIN_USERNAME)),
    ...(createMode && {
      password: YupString()
        .required()
        .matches(...validatePassword),
      repeatPassword: YupString()
        .required()
        .oneOf([YupRef('password'), null], t('validation:passwordMatch'))
    }),
    ...(createMode && {
      organizationUnit: YupObject({
        id: YupString().required(),
        name: YupString()
      })
    })
  });
}
