import { AddressSnapshot, SubjectAddressDetails } from '@ibtm/domain';

import { i18n } from '@libs/common';

import { parsePostCode, parseStreet } from '.';

export const stringifyAddress = (address: AddressSnapshot | SubjectAddressDetails): string => {
  if (address && address !== undefined) {
    const { postCode, city, street, propertyNumber, apartmentNumber } = address;

    const formattedAddress = `${parsePostCode(postCode) || ''} ${city ? `${city}` : ''}  ${
      street ? `, ${parseStreet(street)}` : ''
    } ${propertyNumber || ''}${apartmentNumber ? `/${apartmentNumber}` : ''}`;

    return formattedAddress;
  }
  return i18n.t('emptyMark');
};
