import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { isNull, isUndefined } from 'lodash';

import { useDialog } from '@libs/common/v2';

import { useDownloadQuery, useFileDownload } from '@libs/file';

import GenerateApplicationDocumentDialog from '../form/components/GenerateDocumentDialog';

const useDownloadReleaseDocumentsDialog = (isCorrection?: boolean, documentNumber?: string) => {
  const [t] = useTranslation();
  const { openDialog } = useDialog();
  const { showErrorSnackbar } = useSnackbar();

  const [downloadFileId, setDownloadFileId] = useState<string>();
  const downloadQuery = useDownloadQuery({ fileId: downloadFileId }, { enabled: Boolean(downloadFileId) });
  const { download } = useFileDownload();

  useEffect(() => {
    if (downloadQuery.data) {
      download(
        () =>
          new Promise(res => {
            res(downloadQuery.data);
          })
      );
      setDownloadFileId(null);
    }
  }, [download, downloadQuery]);

  const handleDownloadFile = (fileId: string) => {
    if (isNull(fileId)) {
      showErrorSnackbar(t('releaseDocuments:message.noFile'));
    } else {
      setDownloadFileId(fileId);
    }
  };

  const open = useCallback(
    ({ fileId, invoiceId, isWWU }: { fileId?: string; invoiceId: string; isWWU?: boolean }) => {
      if (isNull(fileId) || isUndefined(fileId)) {
        openDialog(({ closeDialog }) => (
          <GenerateApplicationDocumentDialog
            closeDialog={closeDialog}
            invoiceId={invoiceId}
            handleDownloadFile={handleDownloadFile}
            isCorrection={isCorrection}
            documentNumber={documentNumber}
            isWWU={isWWU}
          />
        ));
      } else {
        // POBIERANIE DOKUMENTU
        handleDownloadFile(fileId);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog]
  );

  return {
    openDownloadDialog: open
  };
};

export default useDownloadReleaseDocumentsDialog;
