import { useCallback } from 'react';

import { useDialog } from '@libs/common/v2';

import InspectionDialog from '../components/details/components/InspectionDialog';
import { ExploitationBaseFormData, ExploitationBaseInspectionSnapshot } from '../model';

function useInspectionDialog() {
  const { openDialog } = useDialog();

  const openDetailsDialog = useCallback(
    ({
      inspections,
      initialExploitationBaseData,
      exploitationBaseId
    }: {
      inspections: ExploitationBaseInspectionSnapshot[];
      initialExploitationBaseData: ExploitationBaseFormData;
      exploitationBaseId: string;
    }) => {
      openDialog(({ closeDialog }) => (
        <InspectionDialog
          closeDialog={closeDialog}
          inspections={inspections}
          initialExploitationBaseData={initialExploitationBaseData}
          exploitationBaseId={exploitationBaseId}
        />
      ));
    },
    [openDialog]
  );

  return {
    openDetailsDialog
  };
}

export default useInspectionDialog;
