import { useRef } from 'react';

import { TableEventContext } from '../context';

/**
 * Otypowany ref przekazujemy do propsa tableEventsRef w TableLayout
 * Co umożliwia emitowanie eventów z poza tabeli, których obsługę możemy zaimplementować wewnątrz tabeli np. w celu granularnego rerenderowania jakichś
 * komponentów tabeli
 *
 * hook umożliwia także subskrypcję eventów tabeli z poza tabeli.
 */
export const useTableEventsRef = () => {
  const tableEventsRef = useRef<TableEventContext>(null);
  return { tableEventsRef };
};
