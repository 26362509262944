import { usePaginatedQuery } from 'react-query';
import { AccountsApiSearchRequest, AccountSnapshot } from '@avispon/user';

import { IPaginatedModel } from '@libs/common/v2';

import { API, endpointsConfig, parseUsersListPayload } from '@libs/user';

import { UserQueryKeysEnum } from './QueryKeysEnum';

export const getUsers = async (_, params: any) => {
  const queryParams = parseUsersListPayload(params);
  const { data } = await API.user.accountController.search(queryParams, { ...endpointsConfig.usersList });

  return data;
};

export default function useUsersQuery(params: AccountsApiSearchRequest, queryConfig = {}) {
  return usePaginatedQuery<IPaginatedModel<AccountSnapshot>>(
    [UserQueryKeysEnum.USERS_LIST, params],
    getUsers,
    queryConfig
  );
}
