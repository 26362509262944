import { useDialog } from '@libs/common/v2';

import { PermissionPassToDistrictAuthorityDialog } from '../../components';

function usePermissionPassToDistrictAuthorityDialog() {
  const { openDialog } = useDialog();

  const showPermissionPassToDistrictAuthorityDialog = ({
    permissionId,
    onSuccess
  }: {
    permissionId: string;
    onSuccess: () => void;
  }) => {
    openDialog(({ closeDialog }) => (
      <PermissionPassToDistrictAuthorityDialog
        permissionId={permissionId}
        onCloseDialog={closeDialog}
        isOpen
        onSuccess={onSuccess}
      />
    ));
  };

  return { showPermissionPassToDistrictAuthorityDialog };
}

export default usePermissionPassToDistrictAuthorityDialog;
