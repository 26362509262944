import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function restoreVehicle({ vehicleId, version }: { vehicleId: string; version: number }) {
  return API.vehicle.restoreVehicle(
    vehicleId,
    { version },
    {
      ...endpointsConfig.restoreVehicle
    }
  );
}

function useRestoreVehicleMutation() {
  return useMutation(restoreVehicle);
}

export default useRestoreVehicleMutation;
