import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FieldValues, UnpackNestedValue, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { cloneDeep, isEqual, isNil } from 'lodash';

import { FormErrorType, FormMode, FormV2Context } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useMetaFormContext } from '@libs/meta-form/context';
import useFormDirtyContext from '@libs/meta-form/context/FormDirtyContext';
import { Component, UiMode } from '@libs/meta-form/models';
import { useMetaFormYupValidation, useSubmitMutation } from '@libs/meta-form/services';

import { useCustomIsDirtyEffect } from '../hooks';

interface IProps {
  children: ReactNode;
  renderNodes?: (children: ReactNode, components: any) => JSX.Element | JSX.Element[];
  components?: Component[];
  inputMode?: UiMode;
  validationTypeKey?: string;
  setFieldsOnMount?: boolean;
}

function Form({ children, renderNodes, components, inputMode, validationTypeKey, setFieldsOnMount }: IProps) {
  const queryCache = useQueryCache();
  const { showErrorSnackbar } = useSnackbar();
  const [t] = useTranslation();
  const { apiRegistry, fields, mode, onSubmit, onSubmitFinish, onSubmitError, additionalDiscardFunctions } =
    useMetaFormContext();

  const { setIsDirty } = useFormDirtyContext();
  const [fieldIds, setFieldIds] = useState<Array<string>>([]);
  const [isSubmittingState, setIsSubmittingState] = useState<boolean>(false);
  const { validationBuilderFunctions, validation } = useMetaFormYupValidation({ validationTypeKey, fieldIds });

  const { formRef } = useMetaFormContext();

  const formMethods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(validation)
  });

  const { watch, getValues, setValue } = formMethods;

  useEffect(() => {
    if (!setFieldsOnMount) {
      return;
    }

    const value = getValues();
    const currentFieldIds = Object.keys(value);
    if (!isEqual(fieldIds, currentFieldIds)) {
      setFieldIds(currentFieldIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Aktualizacja walidacji w przypadku warunkowego odmontowania/montowania fieldów
    const subscription = watch(value => {
      const currentFieldIds = Object.keys(value);
      if (!isEqual(fieldIds, currentFieldIds)) {
        setFieldIds(currentFieldIds);
      }
    });
    return () => subscription.unsubscribe();
  }, [fieldIds, watch]);

  const dirtyFields = useRef<string[]>([]);

  const formValuesBeforeDirty = useRef<Record<string, unknown>>(null);

  const changeDirtyFields = (newDirtyFields: string[]) => {
    dirtyFields.current = newDirtyFields;
  };

  useCustomIsDirtyEffect({ fieldIds, dirtyFields, formValuesBeforeDirty, watch, setIsDirty, changeDirtyFields });

  const { isDirty, isSubmitting, isSubmitted } = formMethods.formState;

  const { mutateAsync: submitMutation } = useSubmitMutation({
    apiRegistry,
    fields
  });

  const [getFormSnapshot, setGetFormSnapshot] = useState(false);

  useEffect(() => {
    const querySubcribe = queryCache.subscribe(cache => {
      const { isFetching } = cache;
      if (!isFetching && !getFormSnapshot) {
        setGetFormSnapshot(true);
      }
    });

    return querySubcribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues]);

  useEffect(() => {
    if (getFormSnapshot) {
      formValuesBeforeDirty.current = cloneDeep(getValues());
    }
  }, [getFormSnapshot, getValues]);

  const resetFormValues = useCallback(() => {
    if (additionalDiscardFunctions.current) {
      Object.entries(additionalDiscardFunctions.current).forEach(([fieldId, customFunction]) => {
        if (fieldId && !isNil(customFunction)) {
          customFunction();
        }
      });
    }

    if (formValuesBeforeDirty.current) {
      Object.entries(formValuesBeforeDirty.current).forEach(([fieldId, value]) => {
        setValue(fieldId, cloneDeep(value));
      });
    }

    dirtyFields.current = [];
    setIsDirty(false);
    formMethods.reset({}, { keepValues: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMethods, setValue, formValuesBeforeDirty, additionalDiscardFunctions]);

  const handleFormSubmit = useCallback(
    async (formData: UnpackNestedValue<FieldValues>) => {
      setGetFormSnapshot(false);
      if (onSubmit) {
        await onSubmit(formData);
        if (onSubmitFinish) {
          onSubmitFinish();
        }
      } else {
        try {
          const response = await submitMutation(formData);
          if (onSubmitFinish) {
            onSubmitFinish(response);
          }
        } catch (error) {
          onSubmitError?.(error);
        }
      }
      dirtyFields.current = [];
      setIsDirty(false);
      formMethods.reset({}, { keepValues: true, keepIsSubmitted: false });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [submitMutation, onSubmitFinish, onSubmitError, onSubmit, formMethods]
  );

  formRef.current = {
    onSubmit: formMethods.handleSubmit(
      () => handleFormSubmit(formMethods?.getValues()),
      async errors => {
        const isOnlyWarnings = !Object.keys(errors).some(error => errors[error]?.type !== FormErrorType.WARNING);

        if (isOnlyWarnings) {
          formMethods?.clearErrors();
          setIsSubmittingState(true);
          await handleFormSubmit(formMethods?.getValues());
          setIsSubmittingState(false);
          return Promise.resolve();
        }
        onSubmitError?.(errors);

        showErrorSnackbar(t('error.formValidationErrors'));
        return Promise.resolve();
      }
    ),
    onReset: resetFormValues
  };

  const values = useMemo(
    () => ({
      ...formMethods,
      formMode: (inputMode || mode) === UiMode.FORM ? FormMode.EDIT : FormMode.VIEW,
      isSubmitting: isSubmitting && isSubmittingState,
      isDirty,
      isSubmitted,
      validationBuilderFunctions
    }),
    [formMethods, inputMode, isDirty, isSubmitted, isSubmitting, isSubmittingState, mode, validationBuilderFunctions]
  );

  return (
    <FormV2Context.Provider value={values}>
      <form data-testid="metaform-form" className="h-full w-full" onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
        {renderNodes ? renderNodes(children, components) : children}
      </form>
    </FormV2Context.Provider>
  );
}

export default Form;
