import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Dialog, FormV2Context, GridLayout } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { UploadFileField } from '@libs/file';

import { DocumentsSnapshotClient, UploadDocumentFile } from '../../model';

interface IProps {
  documentData: DocumentsSnapshotClient;
  createRequestData?: (formData: UploadDocumentFile, documentData: DocumentsSnapshotClient) => any;
  onSuccess: () => void;
  closeDialog: () => void;
  mutation: () => any;
  parentId: string;
  listQueryKey: string;
}

function UpdateDocumentDialog({
  documentData,
  onSuccess,
  closeDialog,
  mutation,
  parentId,
  listQueryKey,
  createRequestData
}: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();

  const { mutate: updateDocument, isLoading } = mutation();

  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        file: Yup.object().nullable().required()
      })
    ),
    defaultValues: {
      file: null
    }
  });

  const onSubmit = (formData: Partial<DocumentsSnapshotClient & UploadDocumentFile>) => {
    const data = {
      parentId,
      documentId: documentData.id,
      documentUpdateRequest: {
        fileId: formData.file.fileId,
        version: documentData.version
      }
    };

    updateDocument(createRequestData ? createRequestData(formData, documentData) : data, {
      onSuccess: () => {
        onSuccess?.();
        queryCache.invalidateQueries(listQueryKey);
        showSnackbar('success', t('document:message.updateDocumentSuccess'));
        closeDialog();
      }
    });
  };

  const values = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      setError,
      clearErrors,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting
    }),
    [clearErrors, control, errors, getValues, isSubmitting, register, setError, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('document:dialog.updateDocumentTitle')}
      confirmText={t('action.add')}
      cancelText={t('action.cancel')}
      onConfirm={() => handleSubmit(onSubmit)()}
      onCancel={closeDialog}
      dialogSize="small"
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <GridLayout itemProps={{ xs: 12 }}>
          <UploadFileField name="file" isRequired />
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default UpdateDocumentDialog;
