import { useEffect } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useQuery } from 'react-query';
import _ from 'lodash';

import { useFormV2Context } from '@libs/common/v2';

import useMetaFormContext from '@libs/meta-form/context/useMetaFormContext';
import { ApiProp, ApiRegistryGet, CustomValidation } from '@libs/meta-form/models';

const useCustomValidation = ({
  api,
  validation,
  fieldId
}: {
  api: ApiProp;
  validation: CustomValidation;
  fieldId: FieldPath<FieldValues>;
}) => {
  const { setError } = useFormV2Context();

  const { apiRegistry, additionalValidators } = useMetaFormContext();
  const apiEndpoint = (apiRegistry && api?.get?.requestKey ? apiRegistry[api.get.requestKey] : null) as ApiRegistryGet;

  const getQuery = useQuery([`METAFORM_${api?.get?.requestKey}`], apiEndpoint?.request, {
    enabled: apiEndpoint && Boolean(validation)
  });

  useEffect(() => {
    const conditionValues = new Set();
    validation?.conditions?.forEach(condition => {
      const values = condition.params.map(param => _.get(getQuery.data, param, null));

      conditionValues.add(additionalValidators[condition.conditionKey](values));
    });

    if (conditionValues.has(true)) {
      setError(fieldId, { message: validation?.text, type: validation?.mode });
    }
  }, [getQuery.data, additionalValidators, validation, fieldId, setError]);
};

export default useCustomValidation;
