import _ from 'lodash';

import { useTableLayoutProps } from '@libs/common/v2';

import { useDocumentsColumns, useDocumentsQuery } from '@libs/domain/documents';

import { DomainDictionaryEntry } from '../../../config';

interface IDocumentsTableParams {
  applicationId?: string;
  groupKeyIn?: boolean;
  groupKeyNotIn?: boolean;
  includeConfirmationsOfIssuingDocuments?: boolean;
}

function useDocumentsTable({
  applicationId,
  groupKeyIn,
  groupKeyNotIn,
  includeConfirmationsOfIssuingDocuments
}: IDocumentsTableParams) {
  const { columns, mappedFilterFields, mappedSortFields } = useDocumentsColumns();

  return useTableLayoutProps({
    tableHookQuery: useDocumentsQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    },
    tableHookQueryInitialParams: {
      applicationIdIn: [applicationId],
      ...(groupKeyIn && {
        groupKeyIn: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.DOCUMENT_TO_FOREIGN_AUTHORITIES]
      }),
      ...(groupKeyNotIn && {
        groupKeyNotIn: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.DOCUMENT_TO_FOREIGN_AUTHORITIES]
      }),
      ...(!_.isNil(includeConfirmationsOfIssuingDocuments) && {
        includeConfirmationsOfIssuingDocuments
      })
    }
  });
}

export default useDocumentsTable;
