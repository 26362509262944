import { v4 as uuidv4 } from 'uuid';

import { i18n } from '@libs/common';
import { GlobalYearSelectType } from '@libs/common/v2';

export const getYearOptionsToSelect = () => {
  const currentYear = new Date().getFullYear() + 1;
  const options = Array(11)
    .fill(0)
    .map((_item, index) => ({
      id: uuidv4(),
      value: currentYear - index,
      name: i18n.t('year', { year: currentYear - index })
    }));

  return options;
};

export const getCurrentYear = (): number => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return currentDate.getMonth() < 11 ? currentYear : currentYear + 1;
};

export const getInitialYear = (): GlobalYearSelectType => {
  const currentDate = new Date();
  const year = String(currentDate.getFullYear());

  return { value: year, name: i18n.t('year', { year }) };
};
