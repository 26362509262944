import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '@enigma/fe-ui';

import { useConfirmDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import {
  ProceedingDetailsParams,
  ProceedingQueryKeysEnum,
  useFormOperationsDeleteMutation
} from '@libs/domain/proceeding';

export function useDeleteAction() {
  const queryCache = useQueryCache();
  const { id: proceedingId } = useParams<ProceedingDetailsParams>();
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const [confirm] = useConfirmDialog();
  const { mutate: deleteFormOperation } = useFormOperationsDeleteMutation();

  const refreshTable = () => {
    queryCache.invalidateQueries(ProceedingQueryKeysEnum.FORM_OPERATION_LIST);
  };

  const handleDelete = useCallback(
    (formOperationId: string) => {
      confirm({
        title: t('proceeding:action.deleteFormOperation.confirmDeleteTitle'),
        message: t('proceeding:action.deleteFormOperation.confirmMessage'),
        onConfirm: (setConfirmLoading, closeDialog) => {
          setConfirmLoading(true);
          deleteFormOperation(
            { proceedingId, formOperationId },
            {
              onSuccess: () => {
                showSuccessSnackbar(t('proceeding:action.deleteFormOperation.successMessage'));
                refreshTable();
                closeDialog();
              },
              onSettled: () => {
                setConfirmLoading(false);
              }
            }
          );
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshTable]
  );

  return {
    handleDelete
  };
}
