import { useParams } from 'react-router-dom';

import { DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import { FolderDetailsParams } from '@libs/domain/folder';
import { ProxyTable } from '@libs/domain/proxy';

interface IProps {
  folderType: string;
}

function ProxiesTab({ folderType }: IProps) {
  const { folderId } = useParams<FolderDetailsParams>();
  const { isOperatorPortal } = useDomainConfigContext();

  return (
    <div>
      <ProxyTable
        folderType={folderType}
        folderId={folderId}
        actions={isOperatorPortal ? ['create', 'edit', 'delete'] : []}
        actionKeys={{
          add: DomainUIElementEnum.FOLDER_PROXY_ADD_BUTTON,
          delete: DomainUIElementEnum.FOLDER_PROXY_DELETE_BUTTON,
          edit: DomainUIElementEnum.FOLDER_PROXY_EDIT_BUTTON
        }}
        tableActionKeys={[DomainUIElementEnum.FOLDER_PROXY_EDIT_BUTTON, DomainUIElementEnum.FOLDER_PROXY_DELETE_BUTTON]}
        visibleColumns={[
          'name',
          'surname',
          'typeKey',
          'postCode',
          'postCity',
          'city',
          'street',
          'paid',
          'authorizationKey',
          'archiveNumber',
          'validUntil'
        ]}
        isCollapsable
        isSection
      />
    </div>
  );
}

export default ProxiesTab;
