import { ForeignPermissionSearchFilter } from '@ibtm/domain';

import { CamelCasePath, useTableLayoutProps } from '@libs/common/v2';

import {
  initialParamsMapper,
  ReleasedPermitsSnapshot,
  useForeignPermissionsColumns,
  usePermitsQuery
} from '@libs/domain/foreign-permission';

function useForeignPermitsTable({
  initalParams,
  visibleColumns,
  isTableDataVisible = true
}: {
  initalParams: ForeignPermissionSearchFilter;
  visibleColumns: Array<CamelCasePath<ReleasedPermitsSnapshot>>;
  isTableDataVisible: boolean;
}) {
  const { columns, mappedFilterFields, mappedSortByFields } = useForeignPermissionsColumns(visibleColumns);

  return useTableLayoutProps({
    tableHookQuery: isTableDataVisible && usePermitsQuery,
    tableHookOptions: {
      columns,
      initialParamsConverter: initialParamsMapper,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortByFields
    },
    tableHookQueryInitialParams: initalParams as Record<string, any>
  });
}

export default useForeignPermitsTable;
