import { QueryConfig, usePaginatedQuery } from 'react-query';
import { PullNotificationSnapshot } from '@avispon/message';

import { IPaginatedModel } from '@libs/common/v2';

import { endpointsConfig } from '@libs/domain/documents-issued/api';

import { DEFAULT_GET_NOTIFICATIONS_PAGE, DEFAULT_GET_NOTIFICATIONS_SIZE } from '../../models';
import { API, MessageQueryKeysEnum } from '../index';

export const getMessagesWithUnread = async (page?: number, size?: number) => {
  const { data } = await API.message.getPaginatedNotificationsWithUnreadCount({
    page: page || DEFAULT_GET_NOTIFICATIONS_PAGE,
    size: size || DEFAULT_GET_NOTIFICATIONS_SIZE,
    ...endpointsConfig.findMessagesWithUnreadCount
  });

  return data;
};

const useMessagesWithUnreadCountQuery = (queryConfig: QueryConfig<IPaginatedModel<PullNotificationSnapshot>> = {}) => {
  return usePaginatedQuery([MessageQueryKeysEnum.MESSAGES_LIST_WITH_UNREAD_COUNT], getMessagesWithUnread, queryConfig);
};

export default useMessagesWithUnreadCountQuery;
