import { useTranslation } from 'react-i18next';
import { TableState } from 'react-table';
import { useSnackbar } from '@enigma/fe-ui';
import { FolderSnapshot } from '@ibtm/domain';
import _ from 'lodash';

import { KeyType } from '@libs/common';
import {
  Button,
  ButtonsGroup,
  Dialog,
  IPaginatedModel,
  TableContext,
  TableInstanceContext,
  TableLayout
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { FolderQueryKeysEnum, useSelectAssociationTable, useUpdateAssociationMutation } from '@libs/domain/folder';

interface IProps {
  closeDialog: () => void;
  id: string;
  version: number;
}

function SelectAssociationTable({ closeDialog, id, version }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const tableProps = useSelectAssociationTable();
  const { mutate: updateAssociation, isLoading } = useUpdateAssociationMutation();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbar();

  const getSelectedRow = (latestData: IPaginatedModel<FolderSnapshot>, state: TableState<FolderSnapshot>) => {
    const selectedRowIds = state?.selectedRowIds as Record<string, any>;

    if (!_.isEmpty(selectedRowIds)) {
      const pageRowIds = latestData?.content?.map(row => row?.id);
      const selectedRowIdInPage = Object.keys(selectedRowIds).find(key => pageRowIds?.includes(key));
      return selectedRowIdInPage;
    }

    return null;
  };

  const onSubmit = (latestData: IPaginatedModel<FolderSnapshot>, state: TableState<FolderSnapshot>) => {
    const selectedRow = getSelectedRow(latestData, state);
    if (selectedRow) {
      updateAssociation(
        { folderId: id, associationId: selectedRow, version },
        {
          onSuccess: () => {
            closeDialog();
            showSuccessSnackbar(t('folder:details.editAssociationModal.message.updateSuccess'));
            queryCache.invalidateQueries([FolderQueryKeysEnum.FOLDER_EXTENDED, id]);
          }
        }
      );
    } else {
      showErrorSnackbar(t('folder:details.editAssociationModal.message.notSelected' as KeyType));
    }
  };

  return (
    <Dialog
      title={t('folder:details.editAssociationModal.selectAssociationTitle')}
      dialogSize="large"
      onClose={closeDialog}
      isDialogActionsSectionVisibled={false}
      isOpen
    >
      <TableLayout {...tableProps} isFilterEnabled={false} isRefreshEnabled={false} isSection>
        <div className="flex justify-end py-10">
          <ButtonsGroup>
            <Button label={t('action.cancel')} onClick={closeDialog} variant="outlined" size="large" isSecondary />
            <TableContext.Consumer>
              {({ latestData }) => (
                <TableInstanceContext.Consumer>
                  {({ state }) => (
                    <Button
                      label={t('action.confirm')}
                      onClick={() => onSubmit(latestData, state)}
                      size="large"
                      disabled={!getSelectedRow(latestData, state)}
                      isLoading={isLoading}
                      tooltipTitle={
                        _.isEmpty(state.selectedRowIds) &&
                        t('folder:details.editAssociationModal.selectAssociationTitle')
                      }
                      isPrimary
                    />
                  )}
                </TableInstanceContext.Consumer>
              )}
            </TableContext.Consumer>
          </ButtonsGroup>
        </div>
      </TableLayout>
    </Dialog>
  );
}

export default SelectAssociationTable;
