import { useEffect, useState } from 'react';
import { ApplicationDetailsAllOf, MisspellApplicationDataPermissionDetails } from '@ibtm/domain';

import { useApplicationDetailsQuery } from '@libs/domain/application';

import { MisspellApplicationPermissionTypeEnum } from '../model/misspell-application.model';

interface IParams {
  applicationId: string;
}

type ConfigurationType = {
  [key in MisspellApplicationPermissionTypeEnum]: { data: MisspellApplicationDataPermissionDetails[] };
};

function useMispellApplicationPermissionTableConfiguration({ applicationId }: IParams): ConfigurationType {
  const { data } = useApplicationDetailsQuery(applicationId, { refetchOnMount: false });

  const handleMisspellData = (data: ApplicationDetailsAllOf) => {
    const mispellData = data?.misspellApplicationDataDetails ?? {};
    return {
      [MisspellApplicationPermissionTypeEnum.LICENSE]: {
        data: mispellData.licenses
      },
      [MisspellApplicationPermissionTypeEnum.LICENSE_COPY]: {
        data: mispellData.licenseCopies
      },
      [MisspellApplicationPermissionTypeEnum.PERMISSION]: { data: mispellData.permissions },
      [MisspellApplicationPermissionTypeEnum.PERMISSION_COPY]: { data: mispellData.permissionCopies }
    };
  };

  const [config, setConfig] = useState(handleMisspellData(data as ApplicationDetailsAllOf));

  useEffect(() => {
    setConfig(handleMisspellData(data as ApplicationDetailsAllOf));
  }, [data]);

  return config;
}

export default useMispellApplicationPermissionTableConfiguration;
