import { useContext } from 'react';
import { usePaginatedQuery } from 'react-query';
import { FolderLimitClientSearchFilter, FolderLimitDetails as FolderLimitDetailsClient } from '@ibtm/client-domain';
import { FolderLimitDetails, FolderLimitSearchFilter } from '@ibtm/domain';

import { IPaginatedModel, YearFilterContext } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import {
  ForeignPermissionQueryEnum,
  parseSingleAuthorizationLimitsClientPayload,
  parseSingleAuthorizationLimitsPayload
} from '@libs/domain/foreign-permission';

import { endpointsConfig } from '../endpoints-config';

const getSingleBasicAuthorizationLimits = async (_, { id, maxYear, ...params }: { id: string; maxYear: string }) => {
  const { data } = await API.folderLimits.getFolderLimitsPage(
    parseSingleAuthorizationLimitsPayload({ folderId: id, maxYear, ...params }),
    { ...endpointsConfig.getFolderLimitsPage }
  );
  return data;
};

const getSingleBasicAuthorizationLimitsClient = async (
  _,
  { id, maxYear, ...params }: { id: string; maxYear: string }
) => {
  const { data } = await API.client.folderLimits.getFolderLimitsPage(
    '',
    parseSingleAuthorizationLimitsClientPayload({ maxYear, ...params }),
    { ...endpointsConfig.getFolderLimitsPage }
  );
  return data;
};

export default function useSingleAuthorizationsLimitsQuery(
  params: FolderLimitSearchFilter | FolderLimitClientSearchFilter
) {
  const context = useContext(YearFilterContext);
  const { getQuery } = useGetApiQueryByPerspective();
  return usePaginatedQuery<IPaginatedModel<FolderLimitDetails> | IPaginatedModel<FolderLimitDetailsClient>>(
    [
      ForeignPermissionQueryEnum.SINGLE_AUTHORIZATION_LIMIT_LIST,
      { ...params, ...(context?.maxYear && { maxYear: context?.maxYear }) }
    ],
    getQuery(getSingleBasicAuthorizationLimitsClient, getSingleBasicAuthorizationLimits)
  );
}
