import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { ResourceAssignmentRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const successCodePattern = /2\d\d/;

const submitApplication = async (request: ResourceAssignmentRequest) =>
  API.printouts.generateResourceAssignment(request, {
    ...endpointsConfig.creategenerateResourceAssignmentAddress
  });

function useGenerateResourceAssignmentMutation(onSuccess?: () => void, isSuccessSnackbarVisible = true) {
  const [t] = useTranslation();
  const { showSuccessSnackbar, showSnackbar } = useSnackbar();

  return useMutation(submitApplication, {
    onSettled: response => {
      const statusToString = response?.status?.toString();
      if (statusToString?.match(successCodePattern)) {
        onSuccess?.();
        if (isSuccessSnackbarVisible) {
          showSuccessSnackbar(t('prints:messages.generationSuccessMessage'));
        }
      } else {
        showSnackbar('warning', t('prints:messages.generationFailureMessage'));
      }
    }
  });
}

export default useGenerateResourceAssignmentMutation;
