import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { object as YupObject, string as YupString } from 'yup';

function useCertificateApplicationVehicleTableSchema() {
  const [t] = useTranslation();

  const validationSchema = YupObject().shape({
    vin: YupString().required(),
    rightOfDispositionDate: YupString().when('temporaryRightOfDisposition', {
      is: temporaryRightOfDisposition => {
        return temporaryRightOfDisposition;
      },
      then: YupString()
        .nullable()
        .required()
        .test(
          'minDateToday',
          t('validation:minDateToday'),
          value => value === null || moment(value).isSameOrAfter(moment(), 'day')
        ),
      otherwise: YupString().nullable()
    })
  });

  return { validationSchema };
}

export default useCertificateApplicationVehicleTableSchema;
