import { DecisionCreateOrUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export const createUpdateApplicationDecision = async ({
  applicationId,
  decisionCreateOrUpdateRequest
}: {
  applicationId: string;
  decisionCreateOrUpdateRequest: DecisionCreateOrUpdateRequest;
}) => {
  return API.decisions.createOrUpdateApplicationDecisions(applicationId, decisionCreateOrUpdateRequest, {
    ...endpointsConfig.createOrUpdateApplicationDecisions
  });
};

function useCreateUpdateApplicationDecisionMutation() {
  return useMutation(createUpdateApplicationDecision);
}
export default useCreateUpdateApplicationDecisionMutation;
