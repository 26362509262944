import { useTranslation } from 'react-i18next';
import { AccountsApiSearchRequest } from '@avispon/user';
import { AccountSnapshot } from '@avispon/user/dist/models';

import { CamelCasePath, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';
import { UserDictionaryEntryNameEnum } from '@libs/user/common';
import { OrganizationUnitFilterV2, UserStatusChip } from '@libs/user/components';

export const useUserListColumns = (visibleColumns: Array<CamelCasePath<AccountSnapshot>>) => {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  const { createColumns } = useCreateColumns<AccountSnapshot>({
    pathToTranslate: 'user:field'
  });

  const mappedSortFields: TableSortMapper<AccountSnapshot> = {
    personalDataName: 'personalData.name',
    personalDataSurname: 'personalData.surname',
    personalDataPersonalNumber: 'personalData.personalNumber',
    organizationUnit: 'organizationUnit.name',
    workSystemTypeKey: 'workSystem.typeKey'
  };

  const mappedFilterFields: TableFilterMapper<AccountSnapshot, AccountsApiSearchRequest> = {
    personalDataName: value => ({ personalDataName: value }),
    personalDataSurname: value => ({ personalDataSurname: value }),
    serviceNumber: value => ({ serviceNumber: value }),
    personalDataPersonalNumber: value => ({ personalNumber: value }),
    status: values => ({ statuses: values.map(({ value }) => value) }),
    positionKey: value => ({ positionKeys: [value?.value] }),
    organizationUnitName: values => ({ unitIds: values.map(({ value }) => value) }),
    loggedIn: value => ({ loggedIn: value?.value }),
    code: value => ({ code: value }),
    workSystemTypeKey: values => ({ workSystemTypeKeys: values.map(({ value }) => value) })
  };

  const columns = createColumns(
    [
      {
        type: 'TEXT',
        header: 'name',
        accessor: 'personalData.name'
      },
      {
        type: 'TEXT',
        header: 'surname',
        accessor: 'personalData.surname'
      },
      {
        id: 'status',
        header: 'status',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: UserDictionaryEntryNameEnum.USER_STATUS,
        tooltipTitle: ({ status }) => translate(UserDictionaryEntryNameEnum.USER_STATUS, status, t('emptyMark')),
        customAccessor: ({ status }) => <UserStatusChip status={status} />,
        customXlsxAccessor: ({ status }) => translate(UserDictionaryEntryNameEnum.USER_STATUS, status, t('emptyMark'))
      },
      {
        type: 'BOOLEAN',
        accessor: 'loggedIn',
        tooltipTitle: ({ loggedIn }) => (loggedIn ? t('action.yes') : t('action.no'))
      },
      {
        header: 'position',
        type: 'DICTIONARY_SINGLE_SELECT',
        dictionaryName: UserDictionaryEntryNameEnum.POSITION_TYPE,
        accessor: 'positionKey'
      },
      {
        type: 'TEXT',
        accessor: 'serviceNumber'
      },
      {
        type: 'TEXT',
        accessor: 'code'
      },
      {
        type: 'TEXT',
        header: 'organizationUnit',
        accessor: 'organizationUnit.name',
        filter: OrganizationUnitFilterV2
      },
      {
        header: 'user:field.systemTypeKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: UserDictionaryEntryNameEnum.WORK_SYSTEM_TYPE,
        accessor: 'workSystem.typeKey'
      },
      {
        type: 'TEXT',
        header: 'user:field.personalNumber',
        accessor: 'personalData.personalNumber'
      }
    ],
    visibleColumns
  );

  return { columns, mappedFilterFields, mappedSortFields };
};
