import { CreateBulkNotificationCommand } from '@avispon/message';
import _ from 'lodash';

import { convertDateToStringWithTimeISO } from '@libs/common/v2/utils';

export const convertedData = (values): CreateBulkNotificationCommand => {
  const accountIds = values?.recipients?.accountSelector?.accountIds?.map(account => account.id);
  const groupIds = values?.recipients?.accountSelector?.groupIds?.map(group => group.id);
  const visibleFrom = convertDateToStringWithTimeISO(values?.visibleFrom);
  const validUntil = convertDateToStringWithTimeISO(values?.validUntil);
  const translations = {
    pl: {
      subject: values?.subject,
      message: values?.message
    }
  };
  const blocking = values?.blocking;

  return {
    ..._.omit(values, ['recipients.accountSelector.recipient', 'message', 'subject']),
    recipients: {
      accountSelector: {
        accountIds,
        groupIds
      }
    },
    visibleFrom,
    validUntil,
    translations,
    blocking
  };
};
