import { useTranslation } from 'react-i18next';
import { every, isString } from 'lodash';

import { Dialog, useIsSmallScreen, useMultipleSelectColumn } from '@libs/common/v2';
import ForwardTableState from '@libs/common/v2/components/table/ForwardTableState';
import useForwardedSelectedRowsAction from '@libs/common/v2/components/table/hooks/useForwardedSelectedRowsAction';

import {
  ExploitationBaseStatusEnum,
  ExploitationBaseTable,
  useConfirmAddExploitationBaseDialog
} from '@libs/domain/exploitation-base';

import { useDomainConfigContext } from '../../../config';

interface IProps {
  folderId: string;
  transferredFoldersIds?: string[];
  applicationId: string;
  closeDialog: () => void;
}

function ExploitationBaseSearchDialog({ folderId, transferredFoldersIds, closeDialog, applicationId }: IProps) {
  const { isSmallScreen } = useIsSmallScreen();
  const { isClientPortal } = useDomainConfigContext();
  const [t] = useTranslation();
  const { openConfirmAddBaseDialog } = useConfirmAddExploitationBaseDialog();
  const selectRowPlugin = useMultipleSelectColumn(() => null, false, undefined, [], true);
  const { forwardTableStateRef, getSelectedIds, handleDisableAction, isActionDisabled } =
    useForwardedSelectedRowsAction();

  return (
    <Dialog
      title={t('exploitationBase:dialog.searchBase.title')}
      confirmText={t('action.add')}
      cancelText={t('action.close')}
      onConfirm={() => {
        const selectedIds = getSelectedIds();
        openConfirmAddBaseDialog(every(selectedIds, isString) ? (selectedIds as string[]) : [], applicationId);
      }}
      onCancel={closeDialog}
      dialogSize="large"
      isConfirmButtonDisabled={isActionDisabled}
      isFullScreen={isSmallScreen}
      isOpen
    >
      <ExploitationBaseTable
        visibleColumns={['typeKeys', 'postCode', 'city']}
        isXlsxDownloadVisible={false}
        tableOptions={{ initialState: { filtersPanelOpen: true } }}
        tablePlugins={[selectRowPlugin]}
        isHeaderHidden
        queryParams={{
          ...(isClientPortal ? { folderIdEq: folderId } : { folderIdIn: [folderId, ...(transferredFoldersIds || [])] }),
          statusIn: [ExploitationBaseStatusEnum.CURRENT]
        }}
        selectionColumnWidth={48}
        isHiddenSelectRowHeader
      >
        <ForwardTableState ref={forwardTableStateRef} setState={handleDisableAction} />
      </ExploitationBaseTable>
    </Dialog>
  );
}

export default ExploitationBaseSearchDialog;
