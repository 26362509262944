import { useRef, useState } from 'react';
import { Row } from 'react-table';

/**
 * hook do użycia w oknie modalnym wraz z komponentem
 * @ForwardTableState "common/v2/components/table/ForwardTableState"
 * */
function useForwardedSelectedRowsAction() {
  const forwardTableStateRef = useRef<{ getStateValue: () => unknown; getSelectedRows: () => unknown }>();
  const [isActionDisabled, setIsActionDisabled] = useState(true);

  const handleDisableAction = selectedRowIds => {
    if (typeof selectedRowIds === 'object') {
      if (Object.keys(selectedRowIds).length > 0) {
        setIsActionDisabled(false);
      } else {
        setIsActionDisabled(true);
      }
    }
  };
  const getSelectedIds = (getRows = false) => {
    const stateValue = forwardTableStateRef?.current?.getStateValue();
    if (getRows) {
      return forwardTableStateRef?.current?.getSelectedRows() as Row<any>[];
    }
    if (typeof stateValue === 'object') {
      return Object.keys(stateValue);
    }
    return null;
  };

  return {
    /**
     * przekazujemy zmienną do props "ref" w ForwardTableState
     * */
    forwardTableStateRef,
    /**
     * boolean zwraca true jeżeli żaden wiersz nie jest zaznaczony
     * */
    isActionDisabled,
    /**
     * przekazujemy do props "setState" w ForwardTableState
     * */
    handleDisableAction,
    /**
     * funkcja zwracająca listę identyfikatorów zaznaczonych wierszy
     * */
    getSelectedIds
  };
}

export default useForwardedSelectedRowsAction;
