import { useContext } from 'react';

import RouterContext from './RouterContext';

function useRouterContext() {
  const { routes, hiddenRoutesPaths, setHiddenRoutesPaths } = useContext(RouterContext);

  return { routes, hiddenRoutesPaths, setHiddenRoutesPaths };
}

export default useRouterContext;
