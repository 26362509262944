import { useDialog } from '@libs/common/v2';

import { FoldersToTransferStatusChangeForm } from '../../components/form';
import { IFoldersToTransferClient } from '../../models';

const useFoldersToTransferStatusChangeDialog = () => {
  const { openDialog } = useDialog();

  const open = (data: IFoldersToTransferClient) => {
    openDialog(({ closeDialog }) => {
      return <FoldersToTransferStatusChangeForm initialData={data} closeDialog={closeDialog} />;
    });
  };

  return {
    openFoldersToTransferStatusChangeDialog: open
  };
};

export default useFoldersToTransferStatusChangeDialog;
