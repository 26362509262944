import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  NotificationStatusBulkEditCommandStatus,
  PullNotificationSnapshot,
  PullNotificationSnapshotStatus
} from '@avispon/message';
import { PersonalData } from '@avispon/user';
import { useAppConfig } from '@libs/app-config';

import {
  InfinityScroll,
  MessagePopover,
  MessagePopoverListItem,
  useDialog,
  useMessagePopoverStyles,
  useMuteNotifications,
  useRouter
} from '@libs/common/v2';

import {
  getMessagesWithUnread,
  useMessagesWithUnreadCountQuery,
  useUpdateNotificationsStatusesMutation
} from '@libs/notification/api';
import websocketService, { Channels } from '@libs/notification/services/websocket.service';

import { DomainUIElementEnum } from '@libs/domain/config';

import { MessageModal } from '../modal';

export const TABLE_DEFAULT_PAGE_SIZE = 10;

function NotificationPopover({ iconClassName }: { iconClassName?: string }) {
  const [t] = useTranslation();
  const { goToPage, routes } = useRouter();
  const { isMuted } = useMuteNotifications();
  const {
    raw: config,
    shouldFetchNotificationsFromWebsockets,
    shouldFetchNotificationsOutsideWebsockets: shouldFetchNotificationsOutsideWebsocketsConfig
  } = useAppConfig();
  const [messagesList, setMessagesList] = useState<PullNotificationSnapshot[]>([]);
  const [blockingMessagesList, setBlockingMessagesList] = useState<PullNotificationSnapshot[]>([]);
  const [messagesListPage, setMessagesListPage] = useState<number>(0);
  const [messagesListTotalPages, setMessagesListTotalPages] = useState<number>(0);
  const { openDialog } = useDialog();
  const [unreadCount, setUnreadCount] = useState<number>(null);
  const personalData = useSelector(({ auth }) => auth.user.personalData) as PersonalData & { id: string };
  const shouldFetchNotificationsOutsideWebsocket =
    shouldFetchNotificationsOutsideWebsocketsConfig && Boolean(personalData);

  const { data: messagesData, refetch } = useMessagesWithUnreadCountQuery({
    onSuccess: ({ content }) => setUnreadCount(getUnreadCount(content)),
    enabled: shouldFetchNotificationsOutsideWebsocket
  });
  const { mutateAsync: updateNotificationsStatuses } = useUpdateNotificationsStatusesMutation();

  const webSocketUrl = config?.api?.webSocketUrl;
  const messagesUpdateSeconds = config?.notification?.notificationsUpdateSeconds || 300;

  const classes = useMessagePopoverStyles({ unreadMessagesCount: unreadCount || null });

  const openMessageModal = (
    notification: PullNotificationSnapshot,
    isMuted: boolean,
    updateNotificationsStatuses?: () => void
  ) => {
    return (
      !isMuted &&
      openDialog(({ closeDialog }) => (
        <MessageModal
          notification={notification}
          onClosed={() => {
            updateNotificationsStatuses?.();
            closeDialog();
          }}
        />
      ))
    );
  };

  const getUnreadCount = (messages: Array<PullNotificationSnapshot>): number => {
    return messages.filter(({ displayed }) => displayed === undefined || displayed === null).length;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (shouldFetchNotificationsOutsideWebsocket) {
        refetch();
      }
    }, messagesUpdateSeconds * 1000);

    return () => clearInterval(interval);
  }, [shouldFetchNotificationsOutsideWebsocket, messagesUpdateSeconds, refetch]);

  useEffect(() => {
    websocketService?.subscribe(Channels.NOTIFICATIONS_CHANGED, () => {
      refetch();
    });

    websocketService?.subscribe(Channels.NOTIFICATION, () => {});

    return () => websocketService?.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websocketService]);

  useEffect(() => {
    if (messagesData?.content instanceof Array) {
      setMessagesList([...messagesData.content.slice(0, (messagesListPage + 1) * TABLE_DEFAULT_PAGE_SIZE)]);
      setBlockingMessagesList(
        messagesData?.content.filter(m => m.blocking && m.status !== PullNotificationSnapshotStatus.READ)
      );
      const arrayLength = messagesData?.totalElements || 0;
      setMessagesListTotalPages(Math.ceil(arrayLength / TABLE_DEFAULT_PAGE_SIZE));
    } else {
      setMessagesList([]);
      setBlockingMessagesList([]);
      setMessagesListPage(0);
      setMessagesListTotalPages(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesData?.content]);

  useEffect(() => {
    if (blockingMessagesList.length) {
      blockingMessagesList.forEach((message: PullNotificationSnapshot) => {
        openMessageModal(message, isMuted, () =>
          updateNotificationsStatuses({
            body: {
              notificationsIds: [message.id],
              status: NotificationStatusBulkEditCommandStatus.READ
            }
          }).then(() => refetch())
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockingMessagesList]);

  useEffect(() => {
    if (personalData?.id && shouldFetchNotificationsFromWebsockets) {
      if (!websocketService.isConnected && webSocketUrl) {
        websocketService.init(webSocketUrl, `/user-notifications/${personalData.id}`);
      }
    }
  }, [personalData, webSocketUrl, shouldFetchNotificationsFromWebsockets]);

  useEffect(() => {
    if (shouldFetchNotificationsOutsideWebsocket) {
      refetch();
    }
  }, [shouldFetchNotificationsOutsideWebsocket, refetch]);

  const handleViewAll = () => {
    goToPage(routes.messagesList());
  };

  const handleMessageClick = (notification: PullNotificationSnapshot) => {
    updateNotificationsStatuses(
      {
        body: {
          notificationsIds: [notification.id],
          status: NotificationStatusBulkEditCommandStatus.READ
        }
      },
      {
        onSuccess: () => {
          refetch();
          openMessageModal(notification, isMuted);
        }
      }
    );
  };

  const requestMessagesPage = async (page: number) => {
    getMessagesWithUnread(page).then(({ content }) => setMessagesList(prevState => [...prevState, ...content]));
    setMessagesListPage(page);
  };

  return (
    <MessagePopover
      icon="NotificationIcon"
      iconClassName={iconClassName}
      headerMessageText={t('messages:dialog.title')}
      hasMuteNotificationsButton
      hasMessages={!!messagesList?.length}
      noMessagesText={t('messages:dialog.emptyMessages')}
      bottomActionText={t('messages:action.viewAll')}
      handleBottomActionClick={handleViewAll}
      unreadMessagesCount={unreadCount || null}
      tooltipTitle={t('notifications')}
      fullListActionKey={DomainUIElementEnum.NOTIFICATION_FULL_LIST}
    >
      {!!messagesList?.length && (
        <InfinityScroll
          lastSyncedPage={messagesListPage}
          requestPage={requestMessagesPage}
          nextPage={() => (messagesListPage < messagesListTotalPages ? messagesListPage + 1 : messagesListPage)}
          offsetHeight={200}
          className={classes.scroll}
        >
          <div className={classes.listElements}>
            {messagesList?.map(notification => (
              <MessagePopoverListItem
                notification={notification}
                key={notification.id}
                handleClick={() => handleMessageClick(notification)}
              />
            ))}
          </div>
        </InfinityScroll>
      )}
    </MessagePopover>
  );
}

export default NotificationPopover;
