import { CivicBoardApiDeletePermanentPermissionSubmissionRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function deleteListItem(data: CivicBoardApiDeletePermanentPermissionSubmissionRequest) {
  return API.civicBoard.deletePermanentPermissionSubmission(data.permanentSubmissionId, {
    ...endpointsConfig.deletePermanentPermissionSubmission
  });
}

function useDeleteEKMTMutation() {
  return useMutation(deleteListItem);
}

export default useDeleteEKMTMutation;
