import { partialTranslate } from '@libs/common';

import { DocumentTemplateTabEnum } from '@libs/document-template/model';

function useDocumentTemplateTabDefinitions(): Array<any> {
  const getLabel = partialTranslate('documentTemplates:tab');

  return [
    {
      label: getLabel('general'),
      value: DocumentTemplateTabEnum.GENERAL,
      isVisible: true
    },
    {
      label: getLabel('versions'),
      value: DocumentTemplateTabEnum.VERSIONS,
      isVisible: true
    }
  ];
}

export default useDocumentTemplateTabDefinitions;
