import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DropdownListButton, useTableAdapter } from '@libs/common/v2';

import { useExportAllReportTypesMutation } from '@libs/report/api';
import { convertDataToZip } from '@libs/report/utils/reports.util';

interface Props {
  selected: string[];
  handleClose: () => void;
}

function ReportTypesSelectAction({ selected, handleClose }: Props) {
  const [t] = useTranslation();
  const { toggleAllRowsSelected } = useTableAdapter();
  const { mutate: exportAllReportTypes, isLoading } = useExportAllReportTypesMutation();

  const handleExportAllReports = useCallback(() => {
    exportAllReportTypes(selected, {
      onSuccess: (data: Blob) => {
        convertDataToZip(data);
        toggleAllRowsSelected(false);
      }
    });
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <DropdownListButton
      label={t('reports:reportTypeExport.exportManyReportTypes')}
      onClick={handleExportAllReports}
      isDisabled={isLoading}
    />
  );
}

function SelectionActionRender(selected: string[], handleClose: () => void) {
  return <ReportTypesSelectAction selected={selected} handleClose={handleClose} />;
}

export default SelectionActionRender;
