import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ForeignPermissionReturnRequest, ResourceObjectAssociationReturnCreateRequest } from '@ibtm/client-domain';
import * as Yup from 'yup';

import { Dialog, FormV2Context } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { DomainDictionaryEntry } from '@libs/domain/config';
import { useReturnForeignClientPermission } from '@libs/domain/foreign-permission';
import { ResourceQueryKeysEnum, useAssociationReturnMutation } from '@libs/domain/resource/api';
import { usePoolsValidationSchema } from '@libs/domain/resource/hooks';
import { IResourceObjectAssociationPoolClient, PoolFormData } from '@libs/domain/resource/model';

import { PoolsForm } from '../common';

interface IProps {
  resourceObjects: IResourceObjectAssociationPoolClient[];
  closeDialog: () => void;
}

function AssociationReturnForm({ resourceObjects, closeDialog }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { mutate: returnResourceObjects } = useAssociationReturnMutation();
  const { mutate: returnForeignPermission } = useReturnForeignClientPermission();

  const poolsValidationSchema = usePoolsValidationSchema(resourceObjects);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        pools: poolsValidationSchema
      })
    ),
    defaultValues: {
      pools: resourceObjects
    }
  });

  const onSubmit = async (formData: PoolFormData) => {
    const { ISSUED_TO_ENTREPRENEURS, RETURNED_TO_ASSOCIATION } = DomainDictionaryEntry.RESOURCE_FORM_STATE;
    const returnPermissionsArray: ResourceObjectAssociationReturnCreateRequest['resources'] = [];
    const returnForeignPermissionsArray: ForeignPermissionReturnRequest['content'] = [];

    formData.pools.forEach((item, index) => {
      const permissionReturnItem = {
        numberFrom: item.numberFrom,
        numberTo: item.numberTo,
        amount: item.amount,
        resourceTypeId: resourceObjects[index].resourceType?.id
      };

      if ([ISSUED_TO_ENTREPRENEURS, RETURNED_TO_ASSOCIATION].includes(item.state)) {
        returnForeignPermissionsArray.push(permissionReturnItem);
      } else {
        returnPermissionsArray.push(permissionReturnItem);
      }
    });

    setIsLoading(true);
    const queryConfig = {
      onSuccess: () => {
        queryCache.invalidateQueries(ResourceQueryKeysEnum.RESOURCE_OBJECT_ASSOCIATION_POOLS);
        showSnackbar('success', t('resource:messages.resourceObjectsReturnSuccess'));
        closeDialog();
      },
      onSettled: () => {
        setIsLoading(false);
      }
    };
    await Promise.all([
      returnPermissionsArray.length && returnResourceObjects({ resources: returnPermissionsArray }, queryConfig),
      returnForeignPermissionsArray.length &&
        returnForeignPermission({ content: returnForeignPermissionsArray }, queryConfig)
    ]);
  };

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      onCancel={closeDialog}
      onConfirm={() => handleSubmit(onSubmit as SubmitHandler<Record<string, any>>)()}
      cancelText={t('action.cancel')}
      confirmText={t('resource:actions.confirmReturn')}
      isConfirmLoading={isLoading}
      title={t('resource:dialog.associationReturnDialogTitle')}
      dialogSize="large"
      isOpen
    >
      <FormV2Context.Provider value={values}>
        {resourceObjects.length ? <PoolsForm /> : <div>{t('common.loading')}</div>}
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default AssociationReturnForm;
