import { useMemo } from 'react';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { PermissionTableEnum } from '../components/details/tabs/permissions/PermissionsTab';

const useVisiblePermissionTypes = (folderType: string): Array<string> => {
  const { PPO, OP, TP, PA, KS, MZ } = DomainDictionaryEntry.FOLDER_TYPE;
  const {
    LISENSE,
    GITD,
    COUNTRY_PERMISSION,
    LICENSE_COPY,
    ROAD_CARRIER,
    DRIVER_CERTIFICATE,
    SPO_UE,
    SPO_UE_COPY,
    SPO_OUTSIDE_UE,
    SPO_OUTSIDE_UE_COPY,
    SPO_SHUTTLE,
    SPO_SHUTTLE_COPY,
    SPO_OCCASIONAL,
    SPO_OCCASIONAL_COPY,
    SPO_TRANSIT,
    SPO_TRANSIT_COPY,
    OWN_NEEDS,
    OWN_NEEDS_COPY,
    // Do weryfikacji:
    // FOREIGN_AUTHORITY_PERMISSION,
    FOREIGN_PERMISSION
  } = PermissionTableEnum;

  const visiblePermissionTypes = useMemo(() => {
    switch (folderType) {
      case TP:
        return [
          LISENSE,
          COUNTRY_PERMISSION,
          GITD,
          LICENSE_COPY,
          ROAD_CARRIER,
          DRIVER_CERTIFICATE,
          FOREIGN_PERMISSION
          // FOREIGN_AUTHORITY_PERMISSION
        ];
      case OP:
        return [
          LISENSE,
          COUNTRY_PERMISSION,
          GITD,
          LICENSE_COPY,
          ROAD_CARRIER,
          DRIVER_CERTIFICATE,
          SPO_UE,
          SPO_UE_COPY,
          SPO_OUTSIDE_UE,
          SPO_OUTSIDE_UE_COPY,
          SPO_SHUTTLE,
          SPO_SHUTTLE_COPY,
          SPO_OCCASIONAL,
          SPO_OCCASIONAL_COPY,
          FOREIGN_PERMISSION
          // FOREIGN_AUTHORITY_PERMISSION
        ];
      case PPO:
        return [LISENSE];
      case KS:
        return [DRIVER_CERTIFICATE, FOREIGN_PERMISSION];
      case PA:
        return [DRIVER_CERTIFICATE, FOREIGN_PERMISSION, OWN_NEEDS, OWN_NEEDS_COPY];
      case MZ:
        return [SPO_OUTSIDE_UE, SPO_OUTSIDE_UE_COPY, SPO_TRANSIT, SPO_TRANSIT_COPY];
      default:
        return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderType]);

  return visiblePermissionTypes;
};

export default useVisiblePermissionTypes;
