import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';

import { Button, ButtonsGroup, Page } from '@libs/common/v2';

import { DomainUIElementEnum } from '@libs/domain/config';

import { ResourcesPageHeader, ResourcesTabs } from '../components';
import { SearchFormsForm } from '../components/forms';
import { useResourceTypeCreateDialog } from '../components/forms/resource-type-dialog';
import { useDeleteFormTypeDialog } from '../hooks';

function ResourcesPage() {
  const [t] = useTranslation();

  const { openDeleteFormTypeDialog } = useDeleteFormTypeDialog();
  const { openCreateResourceTypeDialog } = useResourceTypeCreateDialog();

  return (
    <Page
      header={
        <ResourcesPageHeader title={t('resource:list.title')}>
          <ButtonsGroup>
            <SearchFormsForm />
            <Divider orientation="vertical" style={{ height: 30, margin: '0px 8px' }} />

            <Button
              onClick={openDeleteFormTypeDialog}
              label={t('resource:actions.deleteFormType')}
              variant="outlined"
              isSecondary
              actionKey={DomainUIElementEnum.RESOURCES_DELETE_RESOURCE_TYPE_BUTTON}
            />
            <Button
              isPrimary
              onClick={openCreateResourceTypeDialog}
              label={t('resource:actions.createNewFormType')}
              actionKey={DomainUIElementEnum.RESOURCES_DEPOT_CREATE_OBJECT_TYPE}
            />
          </ButtonsGroup>
        </ResourcesPageHeader>
      }
      content={<ResourcesTabs />}
      contentClassName="flex-col px-10"
      isTable
      isNoContentMargin
    />
  );
}

export default ResourcesPage;
