import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { number, object } from 'yup';

import { Dialog, FormV2Context, NumberInputField } from '@libs/common/v2';

function MuteNotificationDialog({
  closeDialog,
  setData
}: {
  closeDialog: () => void;
  setData: (mutedUntil: string) => void;
}) {
  const [t] = useTranslation();
  const form = useForm<Partial<{ time: number }>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(
      object({
        time: number().nullable().required().min(1)
      })
    ),
    defaultValues: {
      time: 0
    }
  });
  const { handleSubmit } = form;
  const onSubmit = handleSubmit(data => {
    setData(moment().add(data.time, 'minutes').toISOString());
    closeDialog();
  });

  const value = useMemo(() => form, [form]);

  return (
    <FormV2Context.Provider value={value}>
      <form onSubmit={onSubmit}>
        <Dialog
          open
          title={t('notifications:mute.title')}
          onCancel={closeDialog}
          onConfirm={onSubmit}
          confirmText={t('action.save')}
        >
          <NumberInputField min={1} step={1} name="time" label={t('notifications:mute.field')} />
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
}

export default MuteNotificationDialog;
