import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';

import { Dialog, FormV2Context, GridItem, GridLayout, Section, useIsSmallScreen } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { ValueOf } from '@libs/common/v2/utils';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { ProxyQueryKeysEnum, useUpdateProxyMutation } from '../api';
import { useProxyFields } from '../hooks';
import { getValidationSchema, ProxyFields } from '../model';
import { proxyPayloadParser } from '../utils';

interface IProps {
  folderId: string;
  proxyId: string;
  onCloseDialog: () => void;
  isOpen: boolean;
  version: number;
  applicationId: string;
  applicationType?: ValueOf<typeof DomainDictionaryEntry.APPLICATION_CATEGORY>;
  isPostCityRequired?: boolean;
  isCitizenshipFieldVisible?: boolean;
  hiddenFieldIds?: (keyof ProxyFields)[];
}

function ProxyEditDialog({
  proxyId,
  onCloseDialog,
  isOpen,
  folderId,
  version,
  applicationId,
  applicationType,
  isPostCityRequired,
  isCitizenshipFieldVisible,
  hiddenFieldIds
}: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { isSmallScreen } = useIsSmallScreen();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { mutate: editProxy, isLoading } = useUpdateProxyMutation();

  const validationSchema = getValidationSchema(isPostCityRequired, isCitizenshipFieldVisible, true);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    reset
  } = useForm<ProxyFields>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema)
  });

  const formValues = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting
    }),
    [control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting]
  );

  const { renderField } = useProxyFields({
    proxyId,
    reset,
    folderId,
    applicationType,
    isPostCityRequired,
    isCitizenshipFieldRequired: isCitizenshipFieldVisible,
    hiddenFieldIds
  });

  const handleCreateSubmit = (values: ProxyFields) => {
    const { updateRequestData } = proxyPayloadParser(values, version, { folderId, applicationId });
    editProxy(
      { proxyId, updateRequestData },
      {
        onSuccess: () => {
          queryCache.invalidateQueries(ProxyQueryKeysEnum.PROXIES_LIST);
          showSuccessSnackbar(t('proxy:action.edit.successMessage'));
          onCloseDialog();
        },
        onError: () => {
          showErrorSnackbar(t('proxy:action.edit.failureMessage'));
        }
      }
    );
  };

  return (
    <FormV2Context.Provider value={formValues}>
      <form onSubmit={handleSubmit(handleCreateSubmit)}>
        <Dialog
          title={t('proxy:action.edit.dialogTitle')}
          confirmText={t('action.save')}
          cancelText={t('action.cancel')}
          onConfirm={handleSubmit(handleCreateSubmit, () => showErrorSnackbar(t('error.formValidationErrors')))}
          onCancel={onCloseDialog}
          dialogSize="medium"
          isOpen={isOpen}
          isConfirmLoading={isLoading}
          isFullScreen={isSmallScreen}
        >
          <Section isCollapsable isModalSection isHeaderVisible={false}>
            <GridLayout itemProps={{ xs: 6 }}>
              {renderField('name')}
              {renderField('surname')}
              {renderField('typeKey')}
              {renderField('paid')}
              {renderField('validUntil')}
              {isCitizenshipFieldVisible && renderField('citizenship')}
              {renderField('authorizations')}
              <GridItem xs={12}>{renderField('notes')}</GridItem>
            </GridLayout>
          </Section>
          <Section title={t('proxy:section.address')} isModalSection>
            <GridLayout itemProps={{ xs: 6 }}>
              {renderField('city')}
              {renderField('street')}
              {renderField('propertyNumber')}
              {renderField('apartmentNumber')}
              {renderField('postCode')}
              {renderField('postCity')}
            </GridLayout>
          </Section>
          <Section title={t('proxy:section.generalInformation')} isModalSection>
            <GridLayout itemProps={{ xs: 6 }}>
              {renderField('created', { inputMode: 'VIEW' })}
              {renderField('author', { inputMode: 'VIEW' })}
              {renderField('modified', { inputMode: 'VIEW' })}
              {renderField('modifier', { inputMode: 'VIEW' })}
            </GridLayout>
          </Section>
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
}

export default ProxyEditDialog;
