import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PartnerApplicantDetails } from '@ibtm/domain';

import { TableButtonEdit, TableContext } from '@libs/common/v2';

import { DomainUIElementEnum } from '../../config';
import { usePartnerApplicantsEditDialog } from '../hooks';

interface IProps {
  partnerApplicantId: string;
  partnerApplicant: PartnerApplicantDetails;
  clearSelection: () => void;
}

function PartnerApplicantsCreateButton({ partnerApplicantId, partnerApplicant, clearSelection }: IProps) {
  const [t] = useTranslation();
  const { showPartnerApplicantsEditDialog } = usePartnerApplicantsEditDialog({
    partnerApplicantId,
    partnerApplicant,
    clearSelection
  });

  const { refetch } = useContext(TableContext);

  return (
    <TableButtonEdit
      tooltipTitle={t('action.edit')}
      onClick={() => showPartnerApplicantsEditDialog(refetch)}
      actionKey={DomainUIElementEnum.APPLICATION_ITEM_PARTNER_APPLICANT_SUBJECT_UPDATE_BUTTON}
    />
  );
}

export default PartnerApplicantsCreateButton;
