import { PermissionElementMap } from '@libs/permission';

import { PermissionEnum } from '@libs/domain/config/permission/PermissionEnum';

import DictionaryUIElementEnum from './DictionaryUIElementEnum';

const DictionaryPermissionElementMapper: PermissionElementMap<DictionaryUIElementEnum> = {
  [DictionaryUIElementEnum.DICTIONARIES_LIST_TAB]: {
    anyOfPermission: [PermissionEnum.DICTIONARY_VIEW]
  },
  [DictionaryUIElementEnum.DICTIONARIES_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.DICTIONARY_VIEW]
  },
  [DictionaryUIElementEnum.DICTIONARIES_CREATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.DICTIONARY_ADMIN]
  },
  [DictionaryUIElementEnum.DICTIONARIES_UPDATE_BUTTON]: {
    anyOfPermission: [PermissionEnum.DICTIONARY_ADMIN]
  },
  [DictionaryUIElementEnum.DICTIONARIES_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.DICTIONARY_ADMIN]
  }
};

export default DictionaryPermissionElementMapper;
