import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Button, useRouter } from '@libs/common/v2';

import { DomainUIElementEnum } from '@libs/domain/config/permission';

import { ChangeReturnModeContext } from '../../pages/ReleaseDocumentsWrapper';

function ReturnButton() {
  const { id, folderId, releaseDocumentId } = useParams<{
    id: string;
    folderId: string;
    releaseDocumentId: string;
  }>();
  const [t] = useTranslation();
  const { goToPage, routes } = useRouter();
  const { changeMode } = useContext(ChangeReturnModeContext);
  return (
    <Button
      label={t('releaseDocuments:actions.return')}
      variant="outlined"
      isSecondary
      actionKey={DomainUIElementEnum.RELEASE_DOCUMENT_ADD_CORRECTION_BUTTON}
      onClick={() => {
        goToPage(
          !folderId
            ? routes.releaseDocumentReturn(id)
            : routes.releaseDocumentOnFolderSuspensionReturn(folderId, id, releaseDocumentId)
        );
        changeMode();
      }}
    />
  );
}

export default ReturnButton;
