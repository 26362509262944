import { PaginatedQueryConfig, usePaginatedQuery } from 'react-query';
import { ReportDetailsLite } from '@stack/report';

import { IPaginatedModel, useDevMode } from '@libs/common/v2';

import { API, endpointsConfig, ReportQueryKeysEnum } from '..';

export interface GetReportsRequestParams {
  modifiedFrom?: string;
  statusKeys?: Array<string>;
  includeDeleted?: boolean;
  createdTo?: string;
  generationEndedMax?: string;
  dataSourceNameFragment?: string;
  sidFragment?: string;
  dateRangeToMin?: string;
  generationStartedMin?: string;
  dateRangeFromMin?: string;
  reportTypeNameFragment?: string;
  createdFrom?: string;
  createdBy?: Array<string>;
  reportTypeDescriptionFragment?: string;
  reportTypeId?: Array<string>;
  ids?: Array<string>;
  organizationUnitId?: Array<string>;
  generationEndedMin?: string;
  dateRangeFromMax?: string;
  modifiedTo?: string;
  dateRangeToMax?: string;
  generationStartedMax?: string;
  includeTechnical?: boolean;
  size?: number;
  sort?: Array<string>;
  page?: number;
}

type GetReportsRequestParamsApiModel = {
  pageNumber?: number;
  pageSize?: number;
  pageSort?: Array<string>;
  createdBy?: Array<string>;
  createdFrom?: string;
  createdTo?: string;
  dataSourceNameFragment?: string;
  dateRangeFromMax?: string;
  dateRangeFromMin?: string;
  dateRangeToMax?: string;
  dateRangeToMin?: string;
  generationEndedMax?: string;
  generationEndedMin?: string;
  generationStartedMax?: string;
  generationStartedMin?: string;
  ids?: Array<string>;
  includeDeleted?: boolean;
  modifiedFrom?: string;
  modifiedTo?: string;
  organizationUnitId?: Array<string>;
  reportTypeDescriptionFragment?: string;
  reportTypeIds?: Array<string>;
  reportTypeNameFragment?: string;
  sidFragment?: string;
  statusKeys?: Array<string>;
  includeTechnical?: boolean;
};

export function modelToApi(request: GetReportsRequestParams): GetReportsRequestParamsApiModel {
  return {
    pageNumber: request.page,
    pageSize: request.size,
    pageSort: request.sort,
    createdBy: request.createdBy,
    createdFrom: request.createdFrom,
    createdTo: request.createdTo,
    dataSourceNameFragment: request.dataSourceNameFragment,
    dateRangeFromMax: request.dateRangeFromMax || '',
    dateRangeFromMin: request.dateRangeFromMin || '',
    dateRangeToMax: request.dateRangeToMax,
    dateRangeToMin: request.dateRangeToMin,
    generationEndedMax: request.generationEndedMax,
    generationEndedMin: request.generationEndedMin,
    generationStartedMax: request.generationStartedMax,
    generationStartedMin: request.generationStartedMin,
    ids: request.ids,
    includeDeleted: request.includeDeleted,
    modifiedFrom: request.modifiedFrom,
    modifiedTo: request.modifiedTo,
    organizationUnitId: request.organizationUnitId,
    reportTypeDescriptionFragment: request.reportTypeDescriptionFragment,
    reportTypeIds: request.reportTypeId,
    reportTypeNameFragment: request.reportTypeNameFragment,
    sidFragment: request.sidFragment,
    statusKeys: request.statusKeys
  };
}

export default function useReportsQuery(
  params: GetReportsRequestParams,
  options: PaginatedQueryConfig<IPaginatedModel<ReportDetailsLite>>
) {
  const { isDevMode } = useDevMode();
  const getReports = async () => {
    const apiModel = Object.fromEntries(Object.entries(modelToApi(params)).filter(([, v]) => v != null));

    const { data } = await API.report.getReports(
      apiModel.pageNumber as number,
      apiModel.pageSize as number,
      apiModel.pageSort as string[],
      apiModel.createdBy as string[],
      apiModel.createdFrom as string,
      apiModel.createdTo as string,
      apiModel.dataSourceNameFragment as string,
      apiModel.generationEndedMax as string,
      apiModel.generationEndedMin as string,
      apiModel.generationStartedMax as string,
      apiModel.generationStartedMin as string,
      apiModel.ids as string[],
      apiModel.includeDeleted as boolean,
      apiModel.modifiedFrom as string,
      apiModel.modifiedTo as string,
      apiModel.organizationUnitId as string[],
      apiModel.reportTypeDescriptionFragment as string,
      apiModel.reportTypeIds as string[],
      apiModel.reportTypeNameFragment as string,
      apiModel.sidFragment as string,
      apiModel.statusKeys as string[],
      isDevMode,
      { ...endpointsConfig.getReports }
    );
    return data;
  };
  return usePaginatedQuery<IPaginatedModel<ReportDetailsLite>>(
    [ReportQueryKeysEnum.REPORTS, params, isDevMode],
    // @ts-ignore
    getReports,
    options
  );
}
