import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { i18n } from '@libs/common';
import { BooleanValue, CamelCasePath, TableSortMapper, useCreateColumns } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { EntrepreneurDetailsSnapshot } from '@libs/domain/folder';

function useEntrepreneursColumns(
  visibleColumns: Array<CamelCasePath<EntrepreneurDetailsSnapshot>>,
  createMode?: boolean
) {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  const { createColumns } = useCreateColumns<EntrepreneurDetailsSnapshot>({
    pathToTranslate: 'folder:field'
  });

  const mappedSortFields: TableSortMapper<EntrepreneurDetailsSnapshot> = {
    folderNumber: 'subject.folderNumber',
    nip: 'subject.nip',
    name: 'subject.name',
    legalFormKey: 'subject.legalFormKey',
    pendingProceeding: 'subject.pendingProceeding'
  };

  const columns = createColumns(
    [
      {
        id: 'folderNumber',
        type: 'TEXT',
        header: 'folder:details.field.number',
        customAccessor: row => {
          const folderNumber = row?.folder?.number || row?.linkedFolder?.number || row?.folderNumber;
          const folderId = row?.folder?.id || row?.linkedFolder?.id || row?.id;

          return <Link to={`/folders/${folderId}/details`}>{folderNumber}</Link>;
        },
        isSortable: false,
        isFilterable: false
      },
      {
        type: 'TEXT',
        header: 'folder:details.field.name',
        accessor: 'name',
        isSortable: false,
        isFilterable: false
      },
      {
        type: 'TEXT',
        header: 'folder:details.field.nip',
        accessor: 'nip',
        isSortable: false,
        isFilterable: false
      },
      {
        id: 'address',
        type: 'TEXT',
        header: 'primaryAddress',
        isSortable: false,
        isFilterable: false
      },
      {
        id: 'statusKey',
        type: 'TEXT',
        header: 'folder:details.field.status',
        customAccessor: (row: EntrepreneurDetailsSnapshot) =>
          translate(
            DomainDictionaryEnum.FOLDER_STATUS,
            'statusKey' in row && (createMode ? row?.statusKey : row?.folder?.statusKey),
            t('emptyMark')
          ),
        isSortable: false,
        isFilterable: false
      },
      {
        id: 'legalFormKey',
        type: 'TEXT',
        header: 'folder:details.field.legalFormKey',
        customAccessor: (row: EntrepreneurDetailsSnapshot) =>
          translate(DomainDictionaryEnum.LEGAL_NATURE, row?.legalFormKey, t('emptyMark')),
        isSortable: false,
        isFilterable: false
      },
      {
        id: 'pendingProceeding',
        type: 'BOOLEAN',
        header: 'folder:details.field.pendingProceeding',
        tooltipTitle: row => (row?.pendingProceeding ? i18n.t('action.yes') : i18n.t('action.no')),
        customAccessor: (row: EntrepreneurDetailsSnapshot) => <BooleanValue value={Boolean(row?.pendingProceeding)} />,
        isSortable: false,
        isFilterable: false
      }
    ],
    visibleColumns
  );

  return { columns, mappedSortFields };
}
export default useEntrepreneursColumns;
