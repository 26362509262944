import { useTableLayoutProps } from '@libs/common/v2';

import { useBorrowFolderListQuery } from '../api';

import { useBorrowFoldersColumns } from '.';

export const useBorrowFoldersTableProps = (folderId?: string) => {
  const { columns, mappedFilterFields, mappedSortFields } = useBorrowFoldersColumns([
    'borrowDate',
    'borrowPurpose',
    'borrower',
    'returnDate',
    'returnPerson',
    'archiveIssuer',
    'archiveReceiver',
    'localizationKey'
  ]);

  return useTableLayoutProps({
    tableHookQuery: useBorrowFolderListQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'borrowDate', desc: true }]
        }
      }
    },
    tableHookQueryInitialParams: {
      ...(folderId ? { folderNumberIdIn: [folderId] } : {})
    }
  });
};

export default useBorrowFoldersTableProps;
