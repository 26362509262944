import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog } from '@libs/common/v2';

import { MetaForm, UiMode } from '@libs/meta-form';

import { cancelApplicationProcess } from '@libs/domain/application';

interface IProps {
  applicationId: string;
  applicationVersion: number;
  formConfig: {
    messageKey: string;
    title: string;
    uiDefinition: {
      fields: any;
      components: any;
      layout: any;
    };
  };
  onFinish: () => void;
  onCloseDialog: () => void;
  isOpen: boolean;
}

function ProcessCancellationFormDialog({
  applicationId,
  applicationVersion,
  formConfig,
  isOpen,
  onCloseDialog,
  onFinish
}: IProps) {
  const [t] = useTranslation();
  const formRef = useRef(null);

  const [isLoading, setLoading] = useState(false);

  return (
    <Dialog
      title={formConfig?.title}
      confirmText={t('action.confirm')}
      cancelText={t('action.cancel')}
      onConfirm={() => {
        setLoading(true);
        formRef?.current?.onSubmit();
      }}
      onCancel={onCloseDialog}
      dialogSize="medium"
      isConfirmLoading={isLoading}
      isOpen={isOpen}
    >
      <MetaForm
        ref={formRef}
        // eslint-disable-next-line
        fields={formConfig?.uiDefinition?.fields}
        components={[]}
        // eslint-disable-next-line
        layout={formConfig?.uiDefinition?.layout}
        properties={{ mode: UiMode.FORM }}
        onSubmitFinish={() => {
          setLoading(false);
          onCloseDialog();
          onFinish();
        }}
        onSubmitError={() => {
          setLoading(false);
        }}
        apiRegistry={{
          CANCEL_APPLICATION_PROCESS: async (data: { reasonKey?: string }) => {
            return cancelApplicationProcess({
              applicationId,
              cancelApplicationRequest: {
                reasonKey: data.reasonKey,
                version: applicationVersion
              }
            });
          }
        }}
      />
    </Dialog>
  );
}

export default ProcessCancellationFormDialog;
