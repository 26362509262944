import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataSourceMediatorDetailsSnapshot, ReportDataSetDefinitionDetailsSnapshot } from '@stack/report';
import { isEqual } from 'lodash';

import {
  ButtonsGroup,
  FormMode,
  IconButton,
  Section,
  SelectOption,
  useConfirmDialog,
  useDialog,
  useFormV2Context
} from '@libs/common/v2';

import { NewReportTypeDataSectionForm } from './NewReportTypeDataSectionForm';
import ReportTypeDataTabContent from './ReportTypeDataTabContent';

interface IProps {
  tab: number;
  onTabChange: Dispatch<SetStateAction<number>>;
  removeTab: (index: number) => void;
  updateTab: (index: number, data: any) => void;
  saveFieldList: (tab: number, fields: string[]) => void;
}

function ReportTypeDataTabContentWrapper({ tab, onTabChange, removeTab, updateTab, saveFieldList }: IProps) {
  const [t] = useTranslation();
  const { watch } = useFormV2Context();
  const [confirm] = useConfirmDialog();
  const { openDialog } = useDialog();
  const fields = watch('dataSetDefinitions') as ReportDataSetDefinitionDetailsSnapshot[];
  const [key, setKey] = useState<number>(0);
  const handleDeleteDatasetDefinition = async (index: number) => {
    confirm({
      message: t('reports:message.deleteDatasetDefinitionConfirmation', {
        name: fields[index]?.name,
        interpolation: { escapeValue: false }
      }),
      title: t('reports:dialog.deleteDatasetDefinition'),
      shortTitle: t('global:dialog.deleteConfirmTitle'),
      onConfirm: (_, closeDialog) => {
        removeTab(index);
        if (index > 0) {
          onTabChange(index - 1);
        }
        closeDialog();
      },
      confirmType: 'danger'
    });
  };

  const handleEditDataSourceInDialog = (
    values: {
      dataFileFormatKey: SelectOption<string>;
      name: string;
      source: SelectOption<DataSourceMediatorDetailsSnapshot>;
    },
    index: number
  ) => {
    const { name, source, dataFileFormatKey, query } = fields[index];

    const changedValues = {
      ...fields[index],
      ...(!isEqual(values?.name, name) && { name: values?.name }),
      ...(!isEqual(values?.source, source) && { source: values?.source }),
      ...(!isEqual(values?.dataFileFormatKey, dataFileFormatKey) && { dataFileFormatKey: values?.dataFileFormatKey }),
      query
    };
    updateTab(index, changedValues);
    setKey(key + 1);
  };

  return (
    <Section
      title={t('reports:reportTypes.title')}
      headerContent={
        <ButtonsGroup>
          <IconButton
            icon="TrashIcon"
            tooltipTitle={t('action.delete')}
            onClick={() => handleDeleteDatasetDefinition(tab)}
          />
          <IconButton
            icon="EditIcon"
            tooltipTitle={t('action.edit')}
            onClick={() =>
              openDialog(({ closeDialog }) => (
                <NewReportTypeDataSectionForm
                  tab={tab}
                  data={fields}
                  formMode={FormMode.EDIT}
                  closeDialog={closeDialog}
                  onSubmit={handleEditDataSourceInDialog}
                />
              ))
            }
          />
        </ButtonsGroup>
      }
      contentClassName="rounded-t-none"
      areTitleAndActionsInside
    >
      <ReportTypeDataTabContent tab={tab} saveFieldList={saveFieldList} key={key} />
    </Section>
  );
}

export default ReportTypeDataTabContentWrapper;
