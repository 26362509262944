import { useCallback } from 'react';

import { useDialog } from '@libs/common/v2';

import { FormOperationActivePermissionsDialog } from '../components/proceeding-details/tabs';

function useFormOperationActivePermissionsDialog(folderId: string) {
  const { openDialog } = useDialog();
  const openActivePermissions = useCallback(
    (listedPermissions: string[]) => {
      openDialog(({ closeDialog }) => (
        <FormOperationActivePermissionsDialog
          folderId={folderId}
          listedPermissions={listedPermissions}
          closeDialog={closeDialog}
        />
      ));
    },
    [folderId, openDialog]
  );

  const openActivePermissionsDialog = useCallback(
    (listedPermissions: string[]) => {
      openActivePermissions(listedPermissions);
    },
    [openActivePermissions]
  );

  return { openActivePermissionsDialog };
}

export default useFormOperationActivePermissionsDialog;
