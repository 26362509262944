import {
  AdditionalSectionLicenseOrPermissionType,
  ApplicationMetaFormDefinition
} from '@libs/domain/application/model';

import { PermissionEnum } from '../../../../config';

const definition: ApplicationMetaFormDefinition = {
  fields: [
    {
      typeKey: 'DICTIONARY',
      fieldId: 'licenseApplicationDetails-licenseValidityPeriodKey',
      properties: {
        label: 'Ważność licencji',
        dictionaryName: 'LICENSE_VALIDITY_YEARS_TP_OP',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.licenseValidityPeriodKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.licenseValidityPeriodKey'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-version',
      properties: {
        hidden: true,
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'version'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'version'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-subject-country-of-origin',
      properties: {
        label: 'Kraj pochodzenia',
        dictionaryName: 'COUNTRY_CODE',
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'countryOfOrigin'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'countryOfOrigin'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_UPDATE]
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-nip',
      properties: {
        label: 'NIP',
        maxLength: 10,
        onlyPositiveInteger: true,
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'nip'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'nip'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_UPDATE]
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-regon',
      properties: {
        label: 'REGON',
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'regon'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'regon'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_UPDATE]
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-krs',
      properties: {
        label: 'KRS',
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'krs'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'krs'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_UPDATE]
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-subject-legal-form-key',
      properties: {
        label: 'Charakter prawny',
        dictionaryName: 'LEGAL_NATURE',
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'legalFormKey'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'legalFormKey'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_UPDATE]
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-name',
      properties: {
        label: 'Nazwa',
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'name'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'name'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_UPDATE]
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-given-name',
      properties: {
        label: 'Imię',
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'givenName'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'givenName'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_UPDATE]
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-surname',
      properties: {
        label: 'Nazwisko',
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'surname'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'surname'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_UPDATE]
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-phone-number',
      properties: {
        label: 'Telefon',
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'contact.phoneNumber'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_UPDATE]
        }
      }
    },
    {
      typeKey: 'ENTREPRENEURS_COLLECTION',
      fieldId: 'application-entrepreneurs-collection'
    }
  ],
  layout: [
    {
      typeKey: 'TAB_NAVIGATION',
      properties: {
        defaultTabId: 'GENERAL',
        title: 'Sekcje',
        tabs: [
          {
            id: 'GENERAL',
            title: 'Dane podstawowe',
            icon: 'InfoIcon'
          },
          {
            id: 'SUBJECT',
            title: 'Podmiot',
            icon: 'FacadeIcon'
          },
          {
            id: 'ADDRESSES',
            title: 'Adresy',
            icon: 'MailboxIcon'
          },
          {
            id: 'PERMISSIONS',
            title: 'Uprawnienia',
            icon: 'KeyIcon'
          },
          {
            id: 'PROXIES',
            title: 'Pełnomocnicy',
            icon: 'GroupOfPeopleIcon'
          },
          {
            id: 'ATTACHMENTS',
            title: 'Załączniki',
            icon: 'PaperclipIcon'
          },
          {
            id: 'FINANCIAL_SECURITY',
            title: 'Zabezpieczenia finansowe',
            icon: 'WalletIcon'
          },
          {
            id: 'TRANSPORT_MANAGERS',
            title: 'Zarządzający transportem',
            icon: 'TieManIcon'
          },
          {
            id: 'VEHICLES',
            title: 'Pojazdy',
            icon: 'TruckIcon'
          },
          {
            id: 'EXPLOITATION_BASE',
            title: 'Bazy eksploatacyjne',
            icon: 'MarkerTruckIcon'
          },
          {
            id: 'QUOTATION',
            title: 'Wycena',
            icon: 'CoinsIcon'
          },
          {
            id: 'OUTGOING_DOCUMENTS',
            title: 'Dokumenty wychodzące',
            icon: 'DocumentsIssuedIcon'
          },
          {
            id: 'NOTE',
            title: 'Notatka',
            icon: 'NoteIcon'
          },
          {
            id: 'FILES',
            title: 'Pliki',
            icon: 'FileEmptyIcon'
          },
          {
            id: 'HISTORY',
            title: 'Historia',
            icon: 'HistoryIcon'
          }
        ]
      },
      nodes: [
        {
          typeKey: 'TABS_CONTENT',
          nodes: [
            {
              typeKey: 'TAB_CONTENT',
              properties: {
                id: 'GENERAL',
                validationTypeKey: 'CHECK_VEHICLE_SAME_AMOUNT'
              },
              nodes: [
                {
                  typeKey: 'FIELD',
                  properties: {
                    fieldId: 'application-warnings'
                  }
                },
                {
                  typeKey: 'GRID',
                  properties: {
                    gridItemProps: {
                      xs: 12
                    }
                  },
                  nodes: [
                    {
                      typeKey: 'SECTION',
                      properties: {
                        title: 'Wniosek'
                      },
                      nodes: [
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'application-version'
                          }
                        },
                        {
                          typeKey: 'GRID',
                          properties: {
                            gridItemProps: {
                              xs: 12,
                              sm: 6
                            }
                          },
                          nodes: [
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'application-submission-date'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'application-submission-channel-key'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'application-expected-completion-date'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'application-folder'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'application-created-date'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'application-author'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'application-paper-version-wwu-date'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'application-end-date'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'application-last-supplement-receipt-date'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'application-concern-license'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'application-locked-by-application'
                              }
                            }
                          ]
                        }
                      ]
                    },
                    {
                      typeKey: 'COMPONENT',
                      properties: {
                        componentId: 'application-general-tab-delivery-channel-section'
                      }
                    },
                    {
                      typeKey: 'SECTION',
                      properties: {
                        title: 'Decyzje / Wyroki sądu'
                      },
                      nodes: [
                        {
                          typeKey: 'GRID',
                          properties: {
                            gridItemProps: {
                              xs: 12,
                              sm: 6
                            }
                          },
                          nodes: [
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'application-is-review-application'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'application-second-tier-decision-key'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'application-second-tier-decision-date'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'application-wsa-complaint'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'application-wsa-decision-key'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'application-wsa-decision-date'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'application-final-decision'
                              }
                            }
                          ]
                        }
                      ]
                    },
                    {
                      typeKey: 'COMPONENT',
                      properties: {
                        componentId: 'license-application-details',
                        additionalNodes: {
                          'license-application-details-additional-fields': [
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'licenseApplicationDetails-licenseValidityPeriodKey'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'licenseApplicationDetails-emptyField',
                                smHidden: true
                              }
                            }
                          ]
                        }
                      }
                    }
                  ]
                }
              ]
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-subject-tab',
                additionalNodes: {
                  'application-section-end': [
                    {
                      typeKey: 'COMPONENT',
                      properties: {
                        componentId: 'application-subject-print-data-license-section'
                      }
                    },
                    {
                      typeKey: 'COMPONENT',
                      properties: {
                        componentId: 'application-subject-print-data-permission-section'
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-addresses-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-permissions-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-proxies-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-attachments-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-financial-security-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-transport-managers-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-vehicles-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-exploitation-base-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-quotation-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-outgoing-documents-tab',
                additionalNodes: {
                  'outgoing-documents-additional-section': [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'application-outgoing-documents-additional-section-license-or-permission',
                        sectionTypes: [AdditionalSectionLicenseOrPermissionType.PERMISSION]
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-note-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-files-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-history-tab'
              }
            }
          ]
        }
      ]
    }
  ]
};

export default definition;
