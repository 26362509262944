import { useAppConfig } from '@libs/app-config';
import clsx from 'clsx';

import { MatrixRow } from '@libs/common/v2';

import { permissionSingleMatrixHeaders } from '../utils/foreignPermissionApplicationUtils';

import { DefaultSinglePermissionsMatrixRow } from './DefaultPermissionsMatrixRow';

function SinglePermissionMatrixTable({
  rowTitles,
  classes,
  viewMode,
  mataformFieldId
}: {
  rowTitles: { amount: number; id: string }[];
  classes: Record<string, string>;
  viewMode: boolean;
  mataformFieldId: string;
}) {
  const { areSingleAuthorizationLimitsHidden } = useAppConfig();

  return (
    <div className={classes.tableContent}>
      <MatrixRow
        cells={[
          { name: { id: '', title: '' }, width: 400 },
          ...permissionSingleMatrixHeaders(areSingleAuthorizationLimitsHidden)
        ]}
        isHeader
      />
      {rowTitles.map((row, index) => {
        return (
          <div key={row?.id} className={clsx(classes.rowContainer, row?.amount <= 0 && viewMode && classes.hidden)}>
            <DefaultSinglePermissionsMatrixRow
              row={row}
              index={index}
              rowTitles={rowTitles}
              mataformFieldId={mataformFieldId}
              isLimitColumnHidden={areSingleAuthorizationLimitsHidden}
            />
          </div>
        );
      })}
    </div>
  );
}

export default SinglePermissionMatrixTable;
