import { useDialog } from '@libs/common/v2';
import { ValueOf } from '@libs/common/v2/utils';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { ProxyEditDialog } from '../../components';
import { ProxyFields } from '../../model';

interface IProps {
  folderId?: string;
  applicationId?: string;
  applicationType?: ValueOf<typeof DomainDictionaryEntry.APPLICATION_CATEGORY>;
  hiddenFieldIds?: (keyof ProxyFields)[];
  isPostCityRequired?: boolean;
  isCitizenshipFieldVisible?: boolean;
}

function useProxyEditDialog({
  folderId,
  applicationId,
  applicationType,
  isCitizenshipFieldVisible,
  isPostCityRequired,
  hiddenFieldIds
}: IProps) {
  const { openDialog } = useDialog();

  const showProxyEditDialog = (proxyId: string, version: number) => {
    openDialog(({ closeDialog }) => (
      <ProxyEditDialog
        version={version}
        folderId={folderId}
        applicationType={applicationType}
        applicationId={applicationId}
        onCloseDialog={closeDialog}
        isOpen
        proxyId={proxyId}
        isPostCityRequired={isPostCityRequired}
        isCitizenshipFieldVisible={isCitizenshipFieldVisible}
        hiddenFieldIds={hiddenFieldIds}
      />
    ));
  };

  return { showProxyEditDialog };
}

export default useProxyEditDialog;
