import { SingleUsePermissionSubmissionCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function createDisposablePermit(requestData: SingleUsePermissionSubmissionCreateRequest) {
  return API.civicBoard.createSingleUsePermissionSubmission(requestData, {
    ...endpointsConfig.createSingleUsePermissionSubmission
  });
}

function useCreateSingleUseSubmissionMutation() {
  return useMutation(createDisposablePermit);
}

export default useCreateSingleUseSubmissionMutation;
