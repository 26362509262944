import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationPaymentUpdateRequest } from '@ibtm/domain';

import { GridLayout, InputMode, Section, SwitchField, useFormV2Context, useViewModesV2, Value } from '@libs/common/v2';

import { UIElementNameEnum, useElementVisibility } from '@libs/permission';

import { IPermissionsKeys } from '@libs/domain/config';

import { useMoneyTransfersForApplicationQuery } from '../api';
import { PricingTab } from '../utils';

import MoneyTransferDropdownMenu from './MoneyTransferDropdownMenu';
import MoneyTransfersTable from './MoneyTransfersTable';
import MoneyTransfersUnconfirmedTable from './MoneyTransfersUnconfirmedTable';
import { QuotationWarning } from '.';

export interface IMoneyTransfersForApplicationTable {
  applicationId?: string;
  fieldId?: string;
  isSection?: boolean;
  permissionsKeys?: IPermissionsKeys;
  tableActionKeys?: Array<UIElementNameEnum>;
  areUnconfirmedTableActionsVisible?: boolean;
  isDropdownVisible?: boolean;
  areSearchLinkUnlinkMoneyTransferButtonsVisible?: boolean;
}

const getCurrentTabURLSearchParam = () => {
  const activeTab = new URLSearchParams(window.location.search).get('moneyTransferTab');
  return (activeTab as PricingTab) ?? PricingTab.CONFIRMED;
};

function MoneyTransfersForApplicationTable({
  applicationId,
  fieldId,
  isSection = false,
  permissionsKeys,
  tableActionKeys,
  isDropdownVisible,
  areUnconfirmedTableActionsVisible,
  areSearchLinkUnlinkMoneyTransferButtonsVisible = true
}: IMoneyTransfersForApplicationTable) {
  const [tab, setTab] = useState<PricingTab>(getCurrentTabURLSearchParam());
  const [t] = useTranslation();
  const { setValue, formMode } = useFormV2Context();
  const { checkIsElementVisible } = useElementVisibility();
  const { viewMode } = useViewModesV2(formMode);
  const hasPermissionForSearchTransfers = permissionsKeys.search ? checkIsElementVisible(permissionsKeys.search) : true;
  const hasPermissionForTransferBind = permissionsKeys.link ? checkIsElementVisible(permissionsKeys.link) : true;
  const { data: moneyTransferData } = useMoneyTransfersForApplicationQuery(
    { applicationId },
    {
      enabled: hasPermissionForSearchTransfers
    }
  );

  useEffect(() => {
    if (moneyTransferData) {
      setValue(fieldId, {
        accountingApproved: moneyTransferData?.accountingApproved
      } satisfies ApplicationPaymentUpdateRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moneyTransferData]);

  const moneyTransferDropdownMenu = <MoneyTransferDropdownMenu tab={tab} setTab={tab => setTab(tab)} />;

  const quotationWarning = hasPermissionForSearchTransfers && <QuotationWarning applicationId={applicationId} />;
  return (
    <GridLayout itemProps={{ xs: 12 }}>
      {hasPermissionForSearchTransfers && (
        <>
          {tab === PricingTab.CONFIRMED ? (
            <MoneyTransfersTable
              dropdownMenu={isDropdownVisible && moneyTransferDropdownMenu}
              applicationId={applicationId}
              isSection={isSection}
              permissionsKeys={permissionsKeys}
              tableActionKeys={tableActionKeys}
              fieldId={fieldId}
              areSearchLinkUnlinkMoneyTransferButtonsVisible={areSearchLinkUnlinkMoneyTransferButtonsVisible}
            />
          ) : (
            <MoneyTransfersUnconfirmedTable
              applicationId={applicationId}
              dropdownMenu={moneyTransferDropdownMenu}
              areActionsVisible={areUnconfirmedTableActionsVisible}
            />
          )}
        </>
      )}

      {quotationWarning}

      <Section isHeaderVisible={false} className={quotationWarning ? '' : 'mt-16'}>
        <GridLayout itemProps={{ xs: 6 }}>
          {hasPermissionForSearchTransfers ? (
            <Value
              label={t('pricing:moneyTransfers.field.transferPaymentAmount')}
              value={moneyTransferData?.transferPaymentAmount}
            />
          ) : null}
          <SwitchField
            name={`${fieldId}.accountingApproved`}
            label={t('pricing:moneyTransfers.field.accountingApproved')}
            inputMode={hasPermissionForTransferBind && !viewMode ? InputMode.FORM : InputMode.VIEW}
          />
        </GridLayout>
      </Section>
    </GridLayout>
  );
}

export default MoneyTransfersForApplicationTable;
