import { DriversApiDeleteDriverRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function deleteDriverClient(requestData: DriversApiDeleteDriverRequest) {
  return API.client.drivers.deleteDriver('', '', requestData.driverId, {
    ...endpointsConfig.deleteDriver
  });
}

function deleteDriver(requestData: DriversApiDeleteDriverRequest) {
  return API.drivers.deleteDriver(requestData.driverId, {
    ...endpointsConfig.deleteDriver
  });
}

function useDeleteDriverMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(deleteDriverClient, deleteDriver));
}

export default useDeleteDriverMutation;
