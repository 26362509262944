import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { PricingItemDetails } from '@ibtm/domain';
import { number as YupNumber, object as YupObject, ObjectSchema } from 'yup';

import { Dialog, FormV2Context, GridItem, GridLayout, InputMode, Section, useFields } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { ApplicationQueryKeysEnum } from '@libs/domain/application';
import { DomainDictionaryEnum } from '@libs/domain/config';

import { useUpdatePricingItemMutation } from '../api';

type PricingItemFields = {
  unitPrice?: number;
  amount?: number;
  permissionTypeKey?: {
    key: string;
    value: string;
  };
  price?: number;
};

interface IProps {
  pricingId: string;
  data: PricingItemDetails;
  onCloseDialog: () => void;
  onSuccess: () => void;
  isOpen: boolean;
}

function PricingItemEditDialog({ pricingId, data, onCloseDialog, onSuccess, isOpen }: IProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { mutate: editPricingItem, isLoading } = useUpdatePricingItemMutation();
  const queryCache = useQueryCache();

  const validationSchema: ObjectSchema<PricingItemFields> = YupObject({
    unitPrice: YupNumber().nullable().required()
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    reset
  } = useForm<PricingItemFields>({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema)
  });

  const formValues = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting
    }),
    [control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting]
  );

  const { renderField } = useFields<PricingItemFields>(
    [
      {
        id: 'unitPrice',
        type: 'NUMBER',
        validation: {
          required: true
        }
      },
      {
        id: 'permissionTypeKey',
        type: 'DICTIONARY',
        dictionaryName: DomainDictionaryEnum.PERMISSION_TYPE
      },
      {
        id: 'amount',
        type: 'NUMBER',
        inputMode: InputMode.VIEW
      },
      {
        id: 'price',
        type: 'NUMBER',
        inputMode: InputMode.VIEW
      }
    ],
    {
      translationPath: 'pricing:field'
    }
  );

  useEffect(() => {
    if (reset) {
      if (data) {
        reset({
          unitPrice: data?.unitPrice ?? null,
          permissionTypeKey: { value: data?.permissionTypeKey ?? null },
          amount: data?.amount ?? null,
          price: data?.price ?? null
        });
      }
    }
  }, [data, reset]);

  const handleEditSubmit = (values: PricingItemFields) => {
    editPricingItem(
      {
        pricingId,
        formData: {
          pricingItemId: data.id,
          unitPrice: values?.unitPrice,
          version: data.version
        }
      },
      {
        onSuccess: () => {
          queryCache.invalidateQueries(ApplicationQueryKeysEnum.APPLICATION);
          showSuccessSnackbar(t('pricing:action.edit.successMessage'));
          onCloseDialog();
          onSuccess();
        },
        onError: () => {
          showErrorSnackbar(t('pricing:action.edit.failureMessage'));
        }
      }
    );
  };

  return (
    <FormV2Context.Provider value={formValues}>
      <form onSubmit={handleSubmit(handleEditSubmit)}>
        <Dialog
          title={t('pricing:action.edit.dialogTitle')}
          confirmText={t('action.save')}
          cancelText={t('action.cancel')}
          onConfirm={handleSubmit(handleEditSubmit, () => {
            showErrorSnackbar(t('error.formValidationErrors'));
          })}
          onCancel={onCloseDialog}
          dialogSize="medium"
          isConfirmLoading={isLoading}
          isOpen={isOpen}
        >
          <Section isCollapsable isModalSection isHeaderVisible={false}>
            <GridLayout itemProps={{ xs: 6 }}>
              <GridItem xs={12}>{renderField('permissionTypeKey', { inputMode: 'VIEW' })}</GridItem>
              {renderField('amount', { inputMode: 'VIEW' })}
              {renderField('price', { inputMode: 'VIEW' })}
              <GridItem xs={12}>{renderField('unitPrice')}</GridItem>
            </GridLayout>
          </Section>
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
}

export default PricingItemEditDialog;
