import { useTranslation } from 'react-i18next';

import { GridLayout, Section, Value } from '@libs/common/v2';

import { usePreviewLastGenerateResourceAssignment } from '../../api';
import { usePrintsDetailsDataContext } from '../../context';
import { useFetchPrintsApplicationDetails, usePrintTablesConfiguration } from '../../hooks';

const lastGeneratedLicensesPattern = /ibtm-domain.last-generated-permission-type.license/;
const lastGeneratedPermissionsPattern = /ibtm-domain.last-generated-permission-type.permission/;
const lastGeneratedDriverCertificate = /ibtm-domain.last-generated-permission-type.driver-certificate/;
const lastGeneratedDriverCertificateCopy = /ibtm-domain.last-generated-permission-type.driver-certificate-copy/;

function SignatoryDataSection() {
  const [t] = useTranslation();

  const config = usePrintTablesConfiguration();

  const { applicationData, query } = useFetchPrintsApplicationDetails();
  const {
    printDetailsData: { applicationId }
  } = usePrintsDetailsDataContext();

  const { isLicense, isPermission, isDriverCertificate } = config?.visiblePrintedPermissions ?? {};

  const { data, isFetching } = usePreviewLastGenerateResourceAssignment(applicationId, {
    enabled: Boolean(applicationData?.id && (isPermission || isLicense || isDriverCertificate))
  });

  const getLastPrintedPermissionInType = (permission: RegExp) =>
    data?.assignedObjects?.find(item => item.permissionTypeKey.match(permission));

  const licenseData = getLastPrintedPermissionInType(lastGeneratedLicensesPattern);

  const permissionData = getLastPrintedPermissionInType(lastGeneratedPermissionsPattern);
  const driverCertificateData = getLastPrintedPermissionInType(lastGeneratedDriverCertificate);
  const driverCertificateCopyData = getLastPrintedPermissionInType(lastGeneratedDriverCertificateCopy);
  return (
    <Section title={t('prints:printsDetailsBlocks.signatory')} isCollapsable fillHeight>
      <GridLayout itemProps={{ xs: 12, sm: 6 }}>
        <Value label={t('prints:signatory.field.signatory')} isLoading={query.isLoading}>
          {applicationData?.signerName}
        </Value>
        <Value label={t('prints:field.editor')}>{applicationData?.editorName}</Value>

        {isLicense && (
          <Value label={t('prints:field.detailsFields.lastLicenceNumber')} isLoading={isFetching}>
            {licenseData?.permissionNumber}
          </Value>
        )}
        {isLicense && (
          <Value label={t('prints:field.detailsFields.lastBlankLicenseNumber')} isLoading={isFetching}>
            {licenseData?.formNumber}
          </Value>
        )}
        {isPermission && (
          <Value label={t('prints:field.detailsFields.lastPrintedPermit')} isLoading={isFetching}>
            {permissionData?.permissionNumber}
          </Value>
        )}
        {isPermission && (
          <Value label={t('prints:field.detailsFields.lastBlankPermit')} isLoading={isFetching}>
            {permissionData?.formNumber}
          </Value>
        )}
        {isDriverCertificate && (
          <Value label={t('prints:field.detailsFields.lastPrintedDriverCertificate')} isLoading={isFetching}>
            {driverCertificateData?.permissionNumber}
          </Value>
        )}
        {isDriverCertificate && (
          <Value label={t('prints:field.detailsFields.lastPrintedDriverCertificateBlankNumber')} isLoading={isFetching}>
            {driverCertificateData?.formNumber}
          </Value>
        )}
        {isDriverCertificate && (
          <Value label={t('prints:field.detailsFields.lastPrintedDriverCertificateCopy')} isLoading={isFetching}>
            {driverCertificateCopyData?.permissionNumber}
          </Value>
        )}
        {isDriverCertificate && (
          <Value
            label={t('prints:field.detailsFields.lastPrintedDriverCertificateCopyBlankNumber')}
            isLoading={isFetching}
          >
            {driverCertificateCopyData?.formNumber}
          </Value>
        )}
      </GridLayout>
    </Section>
  );
}

export default SignatoryDataSection;
