import { usePaginatedQuery } from 'react-query';
import { ViolationSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { IViolationClient } from '../../../models';
import { parseViolationsListResults } from '../../../utils';
import { RoadTransportQueryKeysEnum } from '../..';
import { endpointsConfig } from '../../endpoints-config';

const getViolations = async (params: ViolationSearchFilter) => {
  const { data } = await API.violations.getViolationsSnapshotPage(params, {
    ...endpointsConfig.getViolationsSnapshotPage
  });
  return {
    ...data,
    content: parseViolationsListResults(data.content)
  };
};

const useViolationsQuery = (params: ViolationSearchFilter) =>
  usePaginatedQuery<IPaginatedModel<IViolationClient>>([RoadTransportQueryKeysEnum.VIOLATIONS_LIST, params], () =>
    getViolations(params)
  );

export default useViolationsQuery;
