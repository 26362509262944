import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { IconButton, Scrollbar, Typography, useIsSmallScreen } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { calc } from '@libs/common/v2/utils';

interface IProps {
  header?: ReactNode;
  content?: ((toggle: () => void) => ReactNode) | ReactNode;
  contentNotExpanded?: ((toggle: () => void) => ReactNode) | ReactNode;
  variant?: string;
  isExpanded: boolean;
  customIcon?: (toggle: () => void) => ReactNode;
  handleToggleDrawer: () => void;
  contentClassName?: string;
  position: 'left' | 'right';
  headerClassName?: string;
  isInnerScrollEnabled?: boolean;
  isToggleDrawerVisible?: boolean;
}

function PageSidebarContent({
  header,
  content,
  contentNotExpanded,
  variant,
  contentClassName,
  headerClassName,
  customIcon,
  isExpanded,
  position,
  handleToggleDrawer,
  isInnerScrollEnabled,
  isToggleDrawerVisible = true
}: IProps) {
  const [t] = useTranslation();
  const classes = useStyles({ isExpanded });
  const { isSmallScreen } = useIsSmallScreen();

  const renderIcon = () => {
    if (position === 'left') {
      return isExpanded ? 'ChevronsLeftIcon' : 'ChevronsRightIcon';
    }
    return isExpanded ? 'ChevronsRightIcon' : 'ChevronsLeftIcon';
  };

  const handleOnToggleClick = () => {
    handleToggleDrawer();
  };

  const renderHeader = () => {
    if (typeof header === 'string') {
      return (
        <div className="sidebar-header flex-1 justify-center items-center m-auto">
          <Typography themeVariant="textLg.medium" className={classes.title}>
            {header}
          </Typography>
        </div>
      );
    }
    return header;
  };

  const DrawerButton = customIcon ? (
    customIcon(handleOnToggleClick)
  ) : (
    <IconButton
      title={isExpanded ? t('action.collapse') : t('action.expand')}
      onClick={handleOnToggleClick}
      icon={renderIcon()}
      iconColor="grey"
      className={clsx({
        [classes.buttonHeaderLeft]: isExpanded && position === 'left',
        [classes.buttonHeaderRight]: position === 'right'
      })}
      width={18}
      height={18}
      isBackgroundTransparent
    />
  );

  const HandleToggleDrawerButton = !isToggleDrawerVisible ? null : DrawerButton;

  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.sidebarHeader, classes.sidebarHeaderInnerSidebar, variant, {
          [classes.rightHeader]: position === 'right' && isExpanded,
          [classes.textHeader]: !isExpanded,
          [classes.leftSidebar]: isExpanded && position === 'left',
          [headerClassName]: isExpanded
        })}
      >
        {isExpanded && renderHeader()}
        {HandleToggleDrawerButton}
      </div>
      {isExpanded && (
        <Scrollbar isScrollbarEnabled={isInnerScrollEnabled} isMobile={isSmallScreen}>
          <div className={clsx(classes.sidebarContent, contentClassName)}>
            {typeof content === 'function' ? content(handleOnToggleClick) : content}
          </div>
        </Scrollbar>
      )}
      {contentNotExpanded && !isExpanded && (
        <Scrollbar isScrollbarEnabled={isInnerScrollEnabled} isMobile={isSmallScreen}>
          <div className={clsx(classes.sidebarContent, contentClassName)}>
            {typeof contentNotExpanded === 'function' ? contentNotExpanded(handleOnToggleClick) : contentNotExpanded}
          </div>
        </Scrollbar>
      )}
    </div>
  );
}

const useStyles = makeStyles<Theme, { isExpanded: boolean }>(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: calc('100% - 4px')
  },
  sidebarHeader: {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: ({ isExpanded }) => !isExpanded && 'center',
    color: theme.palette.grey[700],
    position: 'initial',
    width: '100%'
  },
  rightHeader: {
    paddingRight: 24,
    boxSizing: 'border-box'
  },
  sidebarHeaderInnerSidebar: {
    backgroundColor: 'transparent',
    color: 'inherit',
    height: 'auto',
    minHeight: 'auto'
  },
  title: {
    color: theme.palette.grey[900],
    padding: '6px 16px 6px 32px',
    width: '40%'
  },
  textHeader: {
    paddingTop: 8
  },
  leftSidebar: {
    marginTop: '15px'
  },
  buttonHeaderLeft: {
    marginRight: 8
  },
  buttonHeaderRight: {
    position: 'fixed',
    top: 2,
    right: 10
  }
}));
export default PageSidebarContent;
