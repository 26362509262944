import { InvoiceItemDetails } from '@ibtm/domain';
import { isNaN } from 'lodash';

import { ICorrectionItems } from '../../../model';
import { getAllNumbersOrZero, getAllValidNumbersItem, getRoundedValue } from '../../../utils';
import { calculateDiffrenceWithMonths, calculateSumDiffrence } from '../utils/calculation';

export function useCreateCorrectionCalculation(isSuspension?: boolean, isRestored?: boolean) {
  /**
   *  Funkcja do przeliczania pozycji korekty na widoku tworzenia korekty
   */
  const calculateItemsForCorrection = (
    items: InvoiceItemDetails[],
    suspensionMonths: number,
    changedMonthsNumber: number,
    isCorrection: boolean,
    zeroing: boolean
  ): ICorrectionItems[] => {
    return items?.map(item => {
      const { quantity, unitCost, validityMonths = 60 } = getAllValidNumbersItem(item);

      const months = suspensionMonths;
      const monthsChanged = changedMonthsNumber;

      const diffrence = zeroing
        ? 0
        : calculateDiffrenceWithMonths(unitCost, validityMonths, (!isCorrection ? months : monthsChanged) || 0);
      const restored = !isRestored
        ? 0
        : calculateDiffrenceWithMonths(item?.correctedItem?.unitCost || 0, validityMonths, monthsChanged || 0);

      const diffrenceOrRestored = getRoundedValue(isRestored ? restored : -diffrence);

      const correctedUnitCost = zeroing ? unitCost : unitCost + (isNaN(diffrenceOrRestored) ? 0 : diffrenceOrRestored);

      return !isSuspension
        ? {
            ...item,
            id: item.id,
            name: item.name,
            quantity,
            correctedQuantity: quantity,
            unitCost,
            correctedUnitCost,
            sumDiffrence: calculateSumDiffrence({
              quantity,
              unitCost,
              correctedUnitCost,
              correctedQuantity: quantity
            }),
            newSum: quantity * getRoundedValue(unitCost - diffrenceOrRestored)
          }
        : {
            ...item,
            id: item.id,
            name: item.name,
            quantity,
            correctedQuantity: quantity,
            unitCost,
            correctedUnitCost,
            sumDiffrence: diffrenceOrRestored,
            sumOfCorrection: diffrenceOrRestored * quantity
          };
    });
  };

  /**
   * Przeliczanie tabeli po edycji wiersza
   * @param row
   * Wartości na których dokonano edycji
   * @param items
   * Wszystkie wiersze tabeli
   */
  const setTableAfterEdit = (row: ICorrectionItems, items: ICorrectionItems[]): ICorrectionItems[] => {
    const { id: rowId, correctedQuantity, correctedUnitCost, unitCost, quantity } = getAllNumbersOrZero(row);

    return items.map(item => {
      if (item.id === rowId) {
        return !isSuspension
          ? {
              ...row,
              correctedQuantity,
              correctedUnitCost,
              sumDiffrence: calculateSumDiffrence({
                quantity,
                unitCost,
                correctedUnitCost,
                correctedQuantity
              }),
              newSum: getRoundedValue(correctedQuantity * correctedUnitCost)
            }
          : {
              ...row,
              correctedQuantity,
              correctedUnitCost,
              sumDiffrence: correctedUnitCost - unitCost,
              sumOfCorrection: calculateSumDiffrence({
                quantity,
                unitCost,
                correctedUnitCost,
                correctedQuantity
              })
            };
      }
      return item;
    });
  };

  return {
    calculateItemsForCorrection,
    setTableAfterEdit
  };
}
