import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProceedingAvailableFormsDetails } from '@ibtm/domain';
import { object as YupObject } from 'yup';

import { i18n } from '@libs/common';
import { Dialog, FormMode, FormV2Context, GridLayout, typedNameV2 } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { FormOperationListItemClient, ProceedingTypeKeyEnum } from '@libs/domain/proceeding';

import CustomActions from './CustomActions';
import FormOperationMainSection from './FormOperationMainSection';

const initialValues = {
  statusKey: i18n.t('proceeding:administrative.tab.formOperations.statusShortcut.active'),
  formNumber: null
};

interface IProps {
  id?: string;
  closeDialog: () => void;
  folderId: string;
  formMode: FormMode;
  refreshTable: () => void;
  parentId?: string;
  proceedingTypeKey?: ProceedingTypeKeyEnum;
  type?: ProceedingTypeKeyEnum;
  isSuspensions?: boolean;
  proceedingId?: string;
}

function FormOperationDetails({
  folderId,
  id,
  closeDialog,
  formMode,
  refreshTable,
  parentId,
  proceedingTypeKey,
  type,
  isSuspensions,
  proceedingId
}: IProps) {
  const [t] = useTranslation();
  const innerContainerRef = useRef<HTMLDivElement>();
  const { viewMode } = useViewModesV2(formMode);
  const [chosenForms, setChosenForms] = useState<ProceedingAvailableFormsDetails[] | []>([]);
  const [addNew, setAddNew] = useState(false);

  const validationSchema = YupObject({
    ...(proceedingTypeKey ? { reasonKey: YupObject().nullable().required() } : {})
  });

  const form = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema)
  });

  const formIds = form.watch(typedNameV2<FormOperationListItemClient>('form.ids')) as string[];

  useEffect(() => {
    const dialogPaperContainerRef = innerContainerRef.current?.querySelector('.MuiDialog-paper');
    if (formIds && dialogPaperContainerRef) {
      dialogPaperContainerRef.scrollTop = 0;
    }
  }, [formIds]);

  const formDataProvider = useMemo(() => {
    return { ...form, formMode };
  }, [form, formMode]);

  const title = useMemo(() => {
    if (viewMode) {
      return t('proceeding:administrative.tab.procedureDetails.activeEntitlements');
    }
    return t('proceeding:administrative.tab.formOperations.addFormsOperationTitle');
  }, [viewMode, t]);

  return (
    <FormV2Context.Provider value={formDataProvider}>
      <Dialog
        title={title}
        onCancel={closeDialog}
        ref={innerContainerRef}
        customActions={
          <CustomActions
            closeDialog={closeDialog}
            id={id}
            parentId={parentId}
            isSuspension={!proceedingTypeKey}
            refreshTable={refreshTable}
            isAddNew={addNew}
            setAddNew={setAddNew}
            chosenForms={chosenForms}
            setChosenForms={setChosenForms}
          />
        }
        isOpen
      >
        <GridLayout itemProps={{ xs: 12 }}>
          <FormOperationMainSection
            isReasonKeyFieldVisible={Boolean(proceedingTypeKey)}
            folderId={folderId}
            type={type}
            isSuspensions={isSuspensions}
            proceedingId={proceedingId}
            isAddNew={addNew}
            setAddNew={setAddNew}
            chosenForms={chosenForms}
            setChosenForms={setChosenForms}
            formMode={formMode}
          />
        </GridLayout>
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default FormOperationDetails;
