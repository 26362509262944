import { ImportFileCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function addFile({ fileId }: ImportFileCreateRequest) {
  return API.civicBoard.importFileFromSingleUseCommittee(
    { fileId },
    {
      ...endpointsConfig.importFileFromEkmtCommittee
    }
  );
}

export default function useImportSingleUseFileMutation() {
  return useMutation(addFile);
}
