import { FinancialSecurityDetails } from '@ibtm/domain';

import { useDialog } from '@libs/common/v2';
import { RecursivePartial } from '@libs/common/v2/utils';

import { FinancialSecurityDetailsDialog } from '../../components';

function useFinancialSecurityDetailsDialog() {
  const { openDialog } = useDialog();

  const showFinancialSecurityDetailsDialog = (
    folderTypeKey: string,
    financialSecurityId: string,
    exchangeRateFrom: string,
    exchangeRateTo: string,
    initialData?: RecursivePartial<FinancialSecurityDetails>
  ) => {
    openDialog(({ closeDialog }) => (
      <FinancialSecurityDetailsDialog
        folderTypeKey={folderTypeKey}
        onCloseDialog={closeDialog}
        financialSecurityId={financialSecurityId}
        initialData={initialData}
        exchangeRateFrom={exchangeRateFrom}
        exchangeRateTo={exchangeRateTo}
        isOpen
      />
    ));
  };

  return { showFinancialSecurityDetailsDialog };
}

export default useFinancialSecurityDetailsDialog;
