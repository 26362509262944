import { ProceedingsKreptdApiUpdateProceedingKreptdFineImposedRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function imposeFine(requestData: ProceedingsKreptdApiUpdateProceedingKreptdFineImposedRequest) {
  return API.proceedingsKreptd.updateProceedingKreptdFineImposed(
    requestData.proceedingId,
    requestData.proceedingKreptdFineImposedUpdateRequest,
    { ...endpointsConfig.updateProceedingKreptdFineImposed }
  );
}

function useFineImposingMutations() {
  return useMutation(imposeFine);
}

export default useFineImposingMutations;
