import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { ProceedingKreptdAddViolationsToCurrentRequest, ProceedingKreptdDetails } from '@ibtm/domain';

import { DropdownListButton, useMultipleSelectColumn, useTableAdapter, useTableLayoutProps } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useElementVisibility } from '@libs/permission';

import { DomainUIElementEnum } from '@libs/domain/config';

import { RoadTransportQueryKeysEnum, useViolationsAddingToProceedingMutation, useViolationsQuery } from '../../api';
import { AssignedPersonChangeForm } from '../../components/form';
import { IViolationClient } from '../../models';
import { useViolationColumns } from '../columns';
import { useResourceDialog, useStartProceedingDialog } from '../dialogs';

function SelectionActionRender(selectedIds: string[], handleClose: () => void) {
  return <Actions selectedIds={selectedIds} handleClose={handleClose} />;
}

function Actions({ selectedIds, handleClose }: { selectedIds: string[]; handleClose: () => void }) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const table = useTableAdapter();
  const { showSnackbar } = useSnackbar();
  const { openDialog } = useResourceDialog();

  const { open } = useStartProceedingDialog();

  const selectedRows: IViolationClient[] & ProceedingKreptdDetails[] = useMemo(
    () => table.selectedFlatRows.map(item => item.original),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table, selectedIds]
  );

  const { mutate: addViolationToProceeding } = useViolationsAddingToProceedingMutation();

  const sameSubjectNipCount = useMemo(() => {
    return selectedRows.reduce((result, currentViolation) => {
      if (currentViolation.nip === selectedRows[0].nip) {
        return result + 1;
      }

      return result;
    }, 0);
  }, [selectedRows]);

  const sameTransportManagerCount = useMemo(() => {
    return selectedRows.reduce((result, currentViolation) => {
      if (currentViolation.transportManagerCertificateNumber === selectedRows[0].transportManagerCertificateNumber) {
        return result + 1;
      }

      return result;
    }, 0);
  }, [selectedRows]);

  const noSubjectNipAndTransportManagerCount = useMemo(() => {
    return selectedRows.findIndex(row => !row.transportManagerCertificateNumber && !row.nip) > -1;
  }, [selectedRows]);

  const isLinkedWithProccedingKreptd = useMemo(() => {
    return selectedRows.findIndex(row => row.proceedingKreptdId) > -1;
  }, [selectedRows]);

  const handleStartProceeding = () => {
    open(selectedIds);
    handleClose();
  };

  const handleAssignUser = (dialogTitle?: string) => {
    openDialog(AssignedPersonChangeForm, {
      initialData: selectedRows,
      invokeLevel: 'VIOLATIONS',
      dialogTitle
    });
    handleClose();
  };

  const handleAddToProceeding = () => {
    const requestData: ProceedingKreptdAddViolationsToCurrentRequest = {
      violationIds: selectedRows.map(violation => violation.id)
    };

    addViolationToProceeding(requestData, {
      onSuccess: () => {
        table.toggleAllRowsSelected(false);

        showSnackbar('success', t('roadTransport:messages.includeViolationsSuccess'));
        queryCache.invalidateQueries(RoadTransportQueryKeysEnum.VIOLATIONS_LIST);
        handleClose();
      }
    });
  };

  const isStartProceedingDisabled = useMemo(
    () =>
      isLinkedWithProccedingKreptd ||
      noSubjectNipAndTransportManagerCount ||
      sameTransportManagerCount !== selectedRows.length ||
      sameSubjectNipCount !== selectedRows.length,
    [
      isLinkedWithProccedingKreptd,
      noSubjectNipAndTransportManagerCount,
      sameTransportManagerCount,
      selectedRows.length,
      sameSubjectNipCount
    ]
  );

  const isAddToProceedingDisabled = useMemo(
    () => !selectedRows.every(item => item.pendingProceeding === true),
    [selectedRows]
  );

  const isTransferEnabled = useMemo(() => selectedRows.every(item => !item.assignedTo?.id), [selectedRows]);

  const startProceedingDisabledMessage = useMemo(() => {
    if (isLinkedWithProccedingKreptd) {
      return t('roadTransport:messages.linkedWithProceedingDisableMessage');
    }
    if (noSubjectNipAndTransportManagerCount) {
      return t('roadTransport:messages.noNipAndFolderDisableMessage');
    }
    return t('roadTransport:messages.startProceedingDisableMessage');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLinkedWithProccedingKreptd, noSubjectNipAndTransportManagerCount]);

  return (
    <>
      {isTransferEnabled && (
        <DropdownListButton
          label={t('roadTransport:actions.transfer')}
          onClick={() => handleAssignUser(t('roadTransport:dialog.transferTitle'))}
          actionKey={DomainUIElementEnum.ROAD_TRANSPORT_TRANSFER_BUTTON}
        />
      )}
      {!isTransferEnabled && (
        <DropdownListButton
          label={t('roadTransport:actions.changeAssignedPerson')}
          onClick={() => handleAssignUser(t('roadTransport:dialog.changeAssignedPersonTitle'))}
          actionKey={DomainUIElementEnum.ROAD_TRANSPORT_TRANSFER_BUTTON}
        />
      )}
      <DropdownListButton
        label={t('roadTransport:actions.startProceeding')}
        onClick={handleStartProceeding}
        isDisabled={isStartProceedingDisabled}
        tooltipTitle={isStartProceedingDisabled ? startProceedingDisabledMessage : undefined}
        actionKey={DomainUIElementEnum.ROAD_TRANSPORT_START_PROCEEDING_BUTTON}
      />
      <DropdownListButton
        label={t('roadTransport:actions.AddToProceeding')}
        onClick={handleAddToProceeding}
        isDisabled={isAddToProceedingDisabled}
        tooltipTitle={isAddToProceedingDisabled ? t('roadTransport:messages.addToProceedingDisableMessage') : undefined}
        actionKey={DomainUIElementEnum.ROAD_TRANSPORT_ADD_TO_PROCEEDING_BUTTON}
      />
    </>
  );
}

function useViolationsTable(nip?: string, proceedingKreptdId?: string) {
  const { columns, mappedFilterFields, mappedSortFields } = useViolationColumns();
  const { checkIsElementVisible } = useElementVisibility();
  const multipleSelectColumn = useMultipleSelectColumn(SelectionActionRender);

  const isMultipleActionVisible =
    checkIsElementVisible(DomainUIElementEnum.ROAD_TRANSPORT_TRANSFER_BUTTON) ||
    checkIsElementVisible(DomainUIElementEnum.ROAD_TRANSPORT_START_PROCEEDING_BUTTON) ||
    checkIsElementVisible(DomainUIElementEnum.ROAD_TRANSPORT_ADD_TO_PROCEEDING_BUTTON);

  return useTableLayoutProps({
    tableHookQuery: useViolationsQuery,
    tableHookOptions: {
      columns,
      ...(isMultipleActionVisible && {
        tablePlugins: [multipleSelectColumn]
      }),
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    },
    tableHookQueryInitialParams: {
      ...(nip && { nipContains: nip }),
      ...(proceedingKreptdId && { proceedingKreptdIdIn: [proceedingKreptdId] })
    }
  });
}

export default useViolationsTable;
