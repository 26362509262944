import { ReactNode, useMemo, useState } from 'react';
import { Collapse, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { IconButton, LoaderCircular, SectionTitle, Theme, useTheme } from '@libs/common/v2';
import { calc, hexToRgba, important } from '@libs/common/v2/utils';

import { UIElementNameEnum, useElementVisibility } from '@libs/permission';

export interface ISectionProps {
  title?: ReactNode;
  totalCountInTitle?: number;
  icon?: ReactNode;
  children?: ReactNode;
  headerContent?: ReactNode;
  footerContent?: ReactNode;
  titleHeaderContent?: ReactNode;
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
  collapseButtonClassName?: string;
  footerClassName?: string;
  isPadded?: boolean;
  isHeaderVisible?: boolean;
  isCollapsable?: boolean;
  isLoading?: boolean;
  isLinearLoading?: boolean;
  isContentVisible?: boolean;
  isNoChildrenContentWrapper?: boolean;
  isRequiredSection?: boolean;
  isModalSection?: boolean;
  isSidebarSection?: boolean;
  isCollapsed?: boolean;
  /**
   Parent komponentu musi mieć ustawiony styl `display: flex`
   */
  fillHeight?: boolean;
  actionKey?: UIElementNameEnum;
  areTitleAndActionsInside?: boolean;
  isRounded?: boolean;
  headerActions?: ReactNode;
  /**
   * Komponent zawartość sekcji nie jest renderowana jeśli sekcja jest zwinięta (używamy w celach
   * optymalizacyjnych np. wysyłaniu requestu dopiero po rozwinięciu sekcji)
   */
  isLazy?: boolean;
}

interface ISectionContentProps {
  isCollapsable?: boolean;
  collapse?: boolean;
  isPadded?: boolean;
  className?: string;
  children?: ReactNode;
  isModalSection?: boolean;
  isSidebarSection?: boolean;
  fillHeight?: boolean;
  isLazy?: boolean;
}

function SectionContent({
  isCollapsable,
  collapse,
  isPadded,
  className,
  children,
  isModalSection,
  isSidebarSection,
  fillHeight,
  isLazy
}: ISectionContentProps) {
  const classes = useSectionStyles({ isModalSection, isSidebarSection, fillHeight });
  const content = (
    <Collapse in={collapse} className={classes.collapse}>
      <div className={clsx(isPadded && classes.padding, className)}>{children}</div>
    </Collapse>
  );

  if (isCollapsable && isLazy) {
    return collapse ? content : null;
  }

  if (isCollapsable) {
    return content;
  }

  return <div className={clsx(classes.noCollapsable, isPadded && classes.padding, className)}>{children}</div>;
}

function Section(props: ISectionProps) {
  const {
    isCollapsable,
    title,
    totalCountInTitle,
    icon,
    headerContent,
    footerContent,
    titleHeaderContent,
    children,
    isPadded = true,
    className,
    isHeaderVisible = true,
    isContentVisible = true,
    isLoading,
    isCollapsed = true,
    isLinearLoading,
    isNoChildrenContentWrapper,
    isRequiredSection,
    headerClassName,
    contentClassName,
    collapseButtonClassName,
    footerClassName,
    isModalSection,
    isSidebarSection = false,
    areTitleAndActionsInside,
    isRounded = true,
    fillHeight,
    actionKey,
    headerActions,
    isLazy
  } = props;
  const [collapse, setCollapse] = useState<boolean>(isCollapsed);
  const { contrast } = useTheme();

  const classes = useSectionStyles({ isModalSection, fillHeight, isRounded, contrast });

  const { checkIsElementVisible } = useElementVisibility();

  const isElementVisible = useMemo(() => {
    return actionKey ? checkIsElementVisible(actionKey) : true;
  }, [checkIsElementVisible, actionKey]);

  if (isElementVisible) {
    return (
      <Paper className={clsx(classes.root, className)} variant="outlined">
        <SectionTitle
          title={!areTitleAndActionsInside ? title : null}
          totalCountInTitle={totalCountInTitle}
          icon={icon}
          {...(!areTitleAndActionsInside && { headerContent })}
          titleHeaderContent={titleHeaderContent}
          isRequired={isRequiredSection}
          isHeaderVisible={isHeaderVisible}
          isModalSectionTitle={isModalSection}
          collapsableContent={
            isCollapsable && (
              <IconButton
                onClick={() => setCollapse(prev => !prev)}
                className={clsx(classes.svg, collapseButtonClassName)}
                icon={collapse ? 'ChevronUpIcon' : 'ChevronDownIcon'}
                isBackgroundTransparent
              />
            )
          }
          isLinearLoading={isLinearLoading}
          className={headerClassName}
          areTitleAndActionsInside={areTitleAndActionsInside}
          headerActions={headerActions}
        />
        <LoaderCircular isLoading={isLoading}>
          {isContentVisible && !isNoChildrenContentWrapper && (
            <SectionContent
              isCollapsable={isCollapsable}
              collapse={collapse}
              isPadded={isPadded}
              className={clsx(classes.content, contentClassName)}
              isModalSection={isModalSection}
              isSidebarSection={isSidebarSection}
              fillHeight={fillHeight}
              isLazy={isLazy}
            >
              {areTitleAndActionsInside && (
                <SectionTitle
                  title={title}
                  headerContent={headerContent}
                  isRequired={isRequiredSection}
                  areTitleAndActionsInside
                />
              )}
              {children}
            </SectionContent>
          )}
          {isContentVisible && isNoChildrenContentWrapper && children}
        </LoaderCircular>
        {footerContent && <div className={clsx(classes.footer, footerClassName)}>{footerContent}</div>}
      </Paper>
    );
  }
}

export const useSectionStyles = makeStyles<
  Theme,
  {
    isModalSection?: boolean;
    isSidebarSection?: boolean;
    fillHeight?: boolean;
    isRounded?: boolean;
    contrast?: boolean;
  }
>(theme => ({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    border: 'none',
    backgroundColor: important('unset'),
    padding: important(10)
  },
  lockIcon: {
    fontSize: theme.typography?.getFontSize(18),
    color: theme.palette.grey[500],
    marginRight: 12
  },
  content: {
    backgroundColor: theme.palette.white,
    boxShadow: ({ isModalSection }) =>
      isModalSection
        ? 'none'
        : `0px 1px 3px ${hexToRgba(theme.palette.grey[900], 0.1)}, 0px 1px 2px ${hexToRgba(
            theme.palette.grey[900],
            0.06
          )}`,
    borderRadius: ({ isRounded }) => (isRounded ? '8px' : 0),
    margin: 2,
    flex: ({ fillHeight }) => fillHeight && '1 1 0%',
    border: ({ contrast }) => (contrast ? `1px solid ${theme.palette.grey[900]}` : 'none')
  },
  footer: {
    height: 56,
    paddingVertical: '16px',
    borderTop: `1px solid ${theme.palette.primary}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  svg: {
    marginLeft: 8
  },
  padding: {
    paddingLeft: ({ isModalSection }) => (isModalSection ? 0 : '24px'),
    paddingRight: ({ isModalSection }) => (isModalSection ? 0 : '24px'),
    paddingTop: ({ isSidebarSection }) => (isSidebarSection ? 0 : '24px'),
    paddingBottom: ({ isSidebarSection }) => (isSidebarSection ? 0 : '24px')
  },
  collapse: {
    display: ({ fillHeight }) => fillHeight && 'flex',
    flex: ({ fillHeight }) => fillHeight && '1 1 0%',
    '& .MuiCollapse-wrapperInner': {
      display: ({ fillHeight }) => fillHeight && 'flex'
    },
    paddingBottom: important('4px')
  },
  noCollapsable: {
    height: important(calc('100% - 72px'))
  }
}));

export default Section;
