import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { InvoiceDetailsAllOf, InvoiceItemDetails } from '@ibtm/domain';

import { ITableLayoutColumn, TableEditRow, TableLayout, useFormV2Context } from '@libs/common/v2';

import { useSuspensionDetailsQuery } from '../../../suspensions';
import useCorrectionTableItemsColumns from '../../hooks/useCorrectionTableItemsColumns';
import { ICorrectionItems, ReleaseDocumentsDetailsPageParams } from '../../model';
import { isWZZ as getIsWZZ } from '../../utils';

import { useCreateCorrectionCalculation } from './hooks/useCreateCorrectionCalculation';

interface IProps {
  correctionItemsTable: ICorrectionItems[];
  setCorrectionItemsTable: (table: ICorrectionItems[]) => void;
  items: InvoiceItemDetails[];
  headerActions?: ReactNode;
  isSuspension?: boolean;
  isDataLoading?: boolean;
}

/**
 * Tabela dla widoku create - tworzenie korekty (ale bez tabeli po wykonaniu akcji zwroc)
 */
export const CreateCorrectionItemsTable = ({
  correctionItemsTable,
  setCorrectionItemsTable,
  items,
  headerActions,
  isSuspension,
  isDataLoading
}: IProps) => {
  const [t] = useTranslation();
  const { watch, getValues } = useFormV2Context();
  const { id, folderId } = useParams<ReleaseDocumentsDetailsPageParams>();
  const { data: suspensionData } = useSuspensionDetailsQuery(id, { enabled: !!folderId });

  const itemsForCorrection = watch('itemsForCorrection') as InvoiceItemDetails[];
  const isCorrection = watch('isCorrection') as boolean;
  const zeroing = watch('zeroing') as boolean;
  const suspensions = watch('suspensions') as InvoiceDetailsAllOf['suspensions'];

  const isWZZ = (watch('concernForeignPermission') as boolean) || getIsWZZ(getValues('number') as string);
  const columns = useCorrectionTableItemsColumns({
    isFullAmount: zeroing,
    isCreateMode: true,
    isWZZ,
    isSuspension
  });

  const { calculateItemsForCorrection, setTableAfterEdit } = useCreateCorrectionCalculation(
    isSuspension,
    !!suspensionData?.restored
  );

  useEffect(() => {
    if (items) {
      const findSuspension = suspensions?.find(suspension => suspension.id === id);
      const suspensionMonths = findSuspension
        ? findSuspension.suspensionMonths
        : suspensions?.[0]?.suspensionMonths || 0;
      const tableItems = calculateItemsForCorrection(
        itemsForCorrection,
        Number.isNaN(suspensionMonths || 0) ? 0 : suspensionMonths,
        Number.isNaN(getValues('changedMonthsNumber') || 0) ? 0 : (getValues('changedMonthsNumber') as number),
        isCorrection,
        zeroing
      );

      setCorrectionItemsTable(tableItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, zeroing, isCorrection, isSuspension, suspensionData]);

  const handleEditCorrectionItemsRow = async (row: ICorrectionItems, { onSuccess }) => {
    const newTable = setTableAfterEdit(row, correctionItemsTable);
    setCorrectionItemsTable(newTable);
    onSuccess();
  };

  return (
    <TableLayout<ICorrectionItems, ICorrectionItems>
      tableQuery={correctionItemsTable}
      isLoading={isDataLoading}
      pageTitle={t('releaseDocuments:create.sections.fields')}
      isSection
      key={isSuspension ? 'create-table-items' : 'create-table-items-suspension'}
      isFilterEnabled={false}
      isPaginated={false}
      isRefreshEnabled={false}
      onRowEditCreateConfirm={handleEditCorrectionItemsRow}
      tableHookOptions={{
        columns: columns as ITableLayoutColumn<any, ICorrectionItems>[],
        filterConverter: {},
        sortByConverter: {}
      }}
      isTableMutable
      rowActions={row => <TableEditRow isRenderedAsActionIcon rowId={row.original.id} />}
      headerActions={headerActions}
    />
  );
};
