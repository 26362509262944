import { useTranslation } from 'react-i18next';
import { DefaultApiDeleteMyReplacementRequest } from '@avispon/group';
import { useSnackbar } from '@enigma/fe-ui';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '@libs/user/api';

interface IMutationParams {
  setIsDeleteOpen: (value: boolean) => void;
  refetch: () => void;
}

function deleteReplacement({ id }: DefaultApiDeleteMyReplacementRequest) {
  return API.group.deleteMyReplacement({ id }, { ...endpointsConfig.deleteMyReplacement });
}

function useDeleteReplacementsMutation({ setIsDeleteOpen, refetch }: IMutationParams) {
  const [t] = useTranslation();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  return useMutation(deleteReplacement, {
    onSuccess: () => {
      showSuccessSnackbar(t('user:message.deleteReplacementSuccess'));
    },
    onError: () => {
      showErrorSnackbar(t('user:error.deleteReplacement'));
    },
    onSettled: () => {
      setIsDeleteOpen(false);
      refetch();
    }
  });
}

export default useDeleteReplacementsMutation;
