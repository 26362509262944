import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TablePaginationActions, {
  TablePaginationActionsProps as MuiTablePaginationActionsProps
} from '@mui/material/TablePagination/TablePaginationActions';
import { makeStyles } from '@mui/styles';

import { SEARCH_TEXT_DEBOUNCE_TIME_LONG, TextInput, Typography, useDebounce } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { hexToRgba, important } from '@libs/common/v2/utils';

import { useTableAdapter, useTableContext } from '../hooks';

function PaginationActions(props: MuiTablePaginationActionsProps) {
  const { count, rowsPerPage, page } = props;
  const [t] = useTranslation();
  const classes = useStyles();

  const table = useTableAdapter();
  const { gotoPage } = table;
  const { checkDisabled, isError, isFetching, latestData, data } = useTableContext();
  const onChangeDebounced = useDebounce(gotoPage, SEARCH_TEXT_DEBOUNCE_TIME_LONG);

  const pages = latestData?.totalPages ?? data?.totalPages ?? Math.ceil(count / rowsPerPage);
  const [inputPage, setInputPage] = useState(page);

  useEffect(() => {
    setInputPage(page);
  }, [page]);

  const handlePageInputChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = event => {
    const { value } = event.target;

    if (value > '0' || value === '') {
      const parsedValue = parseInt(value.replace(/[^\d]*/gi, ''), 10) || 0;
      setInputPage(parsedValue - 1);
      if (parsedValue > 0 && parsedValue <= pages) {
        onChangeDebounced(parsedValue - 1);
      }
    }
  };

  return (
    <div className="flex flex-row items-center pl-48">
      <div className={classes.pageInfo}>
        <Typography themeVariant="textSm.normal" component="span">
          {t('pagination.pageText')}
        </Typography>
        <TextInput
          name={t('pagination.pageText')}
          className={classes.textInput}
          value={`${inputPage + 1}`}
          onChange={handlePageInputChange}
          error={!isFetching ? inputPage < 0 || inputPage >= pages : false}
          aria-label={t('pagination.pageText')}
          disabled={checkDisabled(table) || isError}
          adornmentErrorClassName={classes.errorIcon}
        />
        <Typography themeVariant="textSm.normal" component="span">
          {t('pagination.ofText')}&nbsp;
          {pages || 1}
        </Typography>
      </div>
      <TablePaginationActions {...props} className={classes.actions} />
    </div>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  textInput: {
    fontSize: '1.4rem',
    margin: '0 6px',
    '& > div > div': {
      marginTop: important(0),
      width: 120,
      padding: important('6px 14px'),
      borderRadius: 8,
      backgroundColor: theme.palette.white
    },
    '& .MuiInputBase-input.Mui-disabled': {
      '-webkit-text-fill-color': hexToRgba(theme.palette.grey[700], 0.6)
    }
  },
  pageInfo: {
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.textSm.normal,
    letterSpacing: '0.9px'
  },
  actions: {
    marginLeft: 16,
    marginRight: 10,
    display: 'flex'
  },
  errorIcon: {
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      marginRight: '-5px'
    }
  }
}));

export default React.memo(PaginationActions);
