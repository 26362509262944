import { FolderExtendedLite, FolderLiteSearchFilter, ForeignPermissionSnapshot } from '@ibtm/domain';
import moment from 'moment';

import {
  CamelCasePath,
  ColumnTypesEnum,
  SelectOptionPartial,
  TableFilterMapper,
  TableSortMapper,
  useCreateColumns
} from '@libs/common/v2';

import { DisposablePermitsTableEnum } from '@libs/domain/foreign-permission';

function useDisposiblePermitsColumns(visibleColumnIds?: Array<CamelCasePath<FolderExtendedLite>>) {
  const { createColumns } = useCreateColumns<FolderExtendedLite, ForeignPermissionSnapshot>({
    pathToTranslate: 'foreignPermits:tabs.ekmt.fields'
  });

  const mappedFilterFields: TableFilterMapper<FolderExtendedLite, FolderLiteSearchFilter> = {
    number: (number: string) => ({ numberContains: number }),
    subjectName: (subjectName: string) => ({ subjectNameContains: subjectName })
  };

  const mappedSortFields: TableSortMapper<FolderExtendedLite> = {
    subjectName: 'subject.name'
  };

  const initialParamsMapper = {
    [DisposablePermitsTableEnum.YEAR_GRATER]: (year: SelectOptionPartial) => {
      return {
        [DisposablePermitsTableEnum.YEAR_GRATER]: moment([year.value]).startOf('year').format(),
        [DisposablePermitsTableEnum.YEAR_LESS]: moment([year.value]).endOf('year').format()
      };
    }
  };

  const columns = createColumns(
    [
      {
        accessor: 'number',
        type: ColumnTypesEnum.TEXT,
        width: 200
      },
      {
        accessor: 'subject.name',
        type: ColumnTypesEnum.TEXT
      }
    ],
    visibleColumnIds
  );

  return { columns, mappedFilterFields, mappedSortFields, initialParamsMapper };
}

export default useDisposiblePermitsColumns;
