import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ApplicationDetails as ApplicationDetailsClient } from '@ibtm/client-domain';
import { ApplicationSnapshot, SubjectDetails } from '@ibtm/domain';
import { UserTaskDetails } from '@ibtm/process-api';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { BackButton, DropdownButton, PageHeader, TypographyIcon, useIsSmallScreen } from '@libs/common/v2';
import { ButtonsGroup } from '@libs/common/v2/components/buttonsGroup';
import { PaletteOptions } from '@libs/common/v2/theme';
import { convertDateToDateTimeFormat, important } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';

import { finishedApplicationStatuses, useBackFromApplication } from '@libs/domain/application';
import { DomainDictionaryEntry, DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';
import { ProcessCancellationButton, ProcessExecuteButton } from '@libs/domain/process';
import { RegonDataModal } from '@libs/domain/subject';

import { useApplicationStatusColored } from '../../hooks';
import { Application, ApplicationDetailsPageParams } from '../../model';
import { getApplicationDetailsBreadcrumb } from '../../utils';
import { CopyDataFromApplicationButton } from '../application-copy-data-dialog';
import ApplicationVerifyButton from '../application-verify-dialog/ApplicationVerifyButton';
import ApplicationCreateBillingNoteButton from '../ApplicationCreateBillingNoteButton';
import ApplicationGenerateLabelButton from '../ApplicationGenerateLabelButton';

import ApplicationEditOrAssignButton from './ApplicationEditOrAssignButton';

interface IProps {
  title: string;
  applicationData: Application | ApplicationDetailsClient;
  subjectData: SubjectDetails;
  tasks: UserTaskDetails[];
  refetch: () => void;
  onCloseForm: () => void;
  onEditApplicationClick: () => void;
  onGoBackApplicationClick: () => void;
  isFormOpen: boolean;
  isTasksLoading: boolean;
}

function ApplicationDetailsHeader({
  title,
  applicationData,
  subjectData,
  tasks,
  refetch,
  onCloseForm,
  onEditApplicationClick,
  onGoBackApplicationClick,
  isFormOpen,
  isTasksLoading
}: IProps) {
  const { mode } = useParams<ApplicationDetailsPageParams>();
  const backFromApplication = useBackFromApplication();
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();
  const { isSmallScreen } = useIsSmallScreen();
  const classes = useStyles();

  const { title: titleStatus, color } = useApplicationStatusColored(applicationData?.statusKey);
  const [regonModalVisible, setRegonModalVisible] = useState<boolean>(false);

  const applicationArchiveNumber = useMemo(() => {
    const archive = applicationData?.archiveNumber ?? t('emptyMark');
    const secondArchiveNumber = applicationData?.secondArchiveNumber ? `, ${applicationData?.secondArchiveNumber}` : '';
    return archive + secondArchiveNumber;
  }, [applicationData, t]);

  const onGoBack = () => {
    if (isFormOpen) {
      onCloseForm();
      return;
    }
    onGoBackApplicationClick();
  };

  return (
    <PageHeader
      title={title}
      statusData={{
        title: titleStatus,
        color: color as keyof PaletteOptions
      }}
      breadcrumbs={getApplicationDetailsBreadcrumb(mode, applicationData?.number, applicationData?.id)}
      details={[
        {
          icon: 'ApplicationsIcon',
          title: applicationData?.number ?? t('emptyMark'),
          tooltipTitle: t('applications:additionalField.applicationNumber')
        },
        {
          icon: 'BoxIcon',
          title: applicationArchiveNumber,
          tooltipTitle: t('applications:additionalField.archiveNumber')
        },
        {
          icon: 'EzdDocumentsIcon',
          title: applicationData?.rpwNumber ?? t('emptyMark'),
          tooltipTitle: t('applications:additionalField.numberRPW')
        },
        'modifier' in applicationData && {
          icon: 'EditIcon',
          title: `${
            applicationData?.modified ? convertDateToDateTimeFormat(applicationData?.modified) : t('emptyMark')
          } ${applicationData?.modifier?.name ? applicationData?.modifier?.name : t('emptyMark')}`,
          tooltipTitle: t('applications:additionalField.modified')
        }
      ]}
      typographyClassName={isSmallScreen && 'w-full'}
      rightSideContent={
        <ButtonsGroup>
          <BackButton onClick={onGoBack} />
          {!isFormOpen && (
            <>
              <ApplicationEditOrAssignButton
                onEdit={onEditApplicationClick}
                application={applicationData as ApplicationSnapshot}
                permissionsKey="application"
              />
              {regonModalVisible && (
                <RegonDataModal
                  open={regonModalVisible}
                  setIsOpen={setRegonModalVisible}
                  applicationData={applicationData}
                />
              )}
              <ProcessExecuteButton
                isTasksLoading={isTasksLoading}
                tasks={tasks}
                applicationId={applicationData?.id}
                applicationVersion={applicationData?.version}
                onApplicationCancelled={backFromApplication}
                onSendMessageSuccess={refetch}
                onConfirmTaskSuccess={refetch}
                fieldsDependentWarning={{
                  contact: subjectData?.contact
                }}
                isCancelDisabled={!!applicationData?.archiveNumber}
              />
              <DropdownButton
                actionButtons={[
                  {
                    label: t('folder:details.button.downloadRegonData'),
                    onClick: () => setRegonModalVisible(true),
                    actionKey: DomainUIElementEnum.APPLICATION_DOWNLOAD_REGON_DATA_BUTTON
                  }
                ]}
              >
                {({ handleClose }) => (
                  <>
                    {!!applicationData?.statusKey &&
                      !finishedApplicationStatuses.includes(applicationData?.statusKey) && (
                        <ProcessCancellationButton
                          applicationId={applicationData?.id}
                          applicationVersion={applicationData?.version}
                          onConfirmTaskSuccess={() => {
                            refetch();
                            handleClose();
                          }}
                        />
                      )}
                    <CopyDataFromApplicationButton applicationData={applicationData} />
                    {applicationData?.categoryKey === DomainDictionaryEntry.APPLICATION_CATEGORY.FOREIGN_PERMIT ? (
                      <ApplicationCreateBillingNoteButton applicationId={applicationData?.id} />
                    ) : (
                      <ApplicationGenerateLabelButton
                        applicationId={applicationData?.id}
                        onSuccess={() => {
                          handleClose();
                        }}
                      />
                    )}
                    <ApplicationVerifyButton onClose={handleClose} />
                  </>
                )}
              </DropdownButton>
            </>
          )}
        </ButtonsGroup>
      }
      isDividerShown
    >
      <Tooltip title={t('applications:additionalField.categoryAndType')}>
        <span className={classes.detailItem}>
          <TypographyIcon icon="FileIcon" typographyIconClassName={classes.detail}>
            {`${translate(
              DomainDictionaryEnum.APPLICATION_CATEGORY,
              applicationData?.categoryKey,
              t('emptyMark')
            )} / ${translate(DomainDictionaryEnum.APPLICATION_TYPE, applicationData?.typeKey, t('emptyMark'))}`}
          </TypographyIcon>
        </span>
      </Tooltip>
    </PageHeader>
  );
}

const useStyles = makeStyles(() => ({
  detailItem: {
    display: 'inline-block',
    paddingLeft: 10,
    paddingTop: 12
  },
  detail: {
    marginRight: 20,
    justifyContent: important('start')
  }
}));

export default ApplicationDetailsHeader;
