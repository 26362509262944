import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { partialTranslate } from '@libs/common';
import { Dialog, FormV2Context, GridItem, GridLayout } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { ResourceQueryKeysEnum } from '../../../api';
import { TypeTransformFields } from '../../../model';
import { ResourceTransformTypeObjectPoolsTable } from '../../list';
import { ResourceTypeAutocompleteField } from '../resource-type-autocomplete-field/ResourceTypeAutocompleteField';

interface IProps {
  depotId: string;
  closeDialog: () => void;
}

function TypeTransformForm({ depotId, closeDialog }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const getLabel = partialTranslate('resource:fields');

  const foreignResourceTypes = [
    DomainDictionaryEntry.RESOURCE_FORM_TYPE.EKMT_PERMIT,
    DomainDictionaryEntry.RESOURCE_FORM_TYPE.SINGLE_PERMIT,
    DomainDictionaryEntry.RESOURCE_FORM_TYPE.POLISH_FORM
  ];

  const {
    control,
    register,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Partial<TypeTransformFields>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(Yup.object({})),
    defaultValues: {}
  });

  const handleCancelDialog = () => {
    queryCache.invalidateQueries(ResourceQueryKeysEnum.RESOURCE_OBJECT_POOLS);

    closeDialog();
  };

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('resource:dialog.typeTransformTitle')}
      cancelText={t('action.close')}
      onCancel={handleCancelDialog}
      dialogSize="large"
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <GridLayout itemProps={{ md: 6, xs: 12 }}>
          <ResourceTypeAutocompleteField
            label={getLabel('sourceType')}
            name="sourceType"
            searchFilter={{ typeKeyFilter: { values: foreignResourceTypes } }}
          />
          <ResourceTypeAutocompleteField
            name="targetType"
            label={getLabel('targetType')}
            searchFilter={{ typeKeyFilter: { values: foreignResourceTypes } }}
          />

          <GridItem xs={12}>
            <ResourceTransformTypeObjectPoolsTable depotId={depotId} />
          </GridItem>
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default TypeTransformForm;
