import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react';
import _ from 'lodash';

import { FormMode } from '@libs/common/v2';

import {
  AdditionalFields,
  defaultVisibleFields,
  defaultVisibleSections,
  FieldNames,
  SectionNames,
  SectionVisibleConfig
} from './ConfigurationContextTypes';

const ConfigurationContext = createContext<IConfigurationContext>(null);

export default function useConfigurationContext() {
  return useContext(ConfigurationContext);
}

interface IConfigurationContext {
  isFieldVisible: (fieldName: FieldNames) => boolean;
  isSectionVisible: (sectionName: SectionNames) => boolean;
  renderAdditionalFields?: (section: SectionNames) => ReactNode[];
  renderAdditionalSection?: () => ReactNode[];
}

interface Props {
  children?: React.ReactNode;
  formMode?: FormMode;
  additionalFields?: AdditionalFields;
  visibleFields?: FieldNames[];
  visibleSections?: SectionVisibleConfig[];
  additionalSections?: ReactNode[];
}

export function ConfigurationContextProvider({
  children,
  formMode,
  visibleFields = defaultVisibleFields,
  additionalFields = {},
  visibleSections = _.values(defaultVisibleSections),
  additionalSections = []
}: Props) {
  const isFieldVisible = useCallback((fieldName?: FieldNames) => visibleFields?.includes(fieldName), [visibleFields]);
  const isSectionVisible = useCallback(
    (sectionName: SectionNames) =>
      Boolean(visibleSections.find(section => section.name === sectionName && section.isVisible[formMode])),
    [formMode, visibleSections]
  );
  const renderAdditionalFields = useCallback((section: SectionNames) => additionalFields[section], [additionalFields]);
  const renderAdditionalSection = useCallback(() => additionalSections, [additionalSections]);

  const value = useMemo(
    () => ({
      isFieldVisible,
      isSectionVisible,
      renderAdditionalFields,
      renderAdditionalSection
    }),
    [isFieldVisible, isSectionVisible, renderAdditionalFields, renderAdditionalSection]
  );

  return <ConfigurationContext.Provider value={value}>{children}</ConfigurationContext.Provider>;
}
