import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FilesTable } from '@libs/file';

import { API } from '@libs/domain/api';
import { DomainUIElementEnum } from '@libs/domain/config';

import { ProceedingDetailsParams } from '../../../../model';

function FilesTab() {
  const [t] = useTranslation();
  const { id } = useParams<ProceedingDetailsParams>();

  return (
    <div style={{ height: 'fit-content' }}>
      <FilesTable
        title={t('applications:list.attachmentsListTitle')}
        api={{
          GET: params => API.proceedingAttachments.getProceedingsAttachmentsPage(id, params),
          CREATE: ({ fileId }) => API.proceedingAttachments.addProceedingAttachment(id, { fileId }),
          DELETE: ({ recordId }) => API.proceedingAttachments.deleteProceedingsAttachment(id, recordId)
        }}
        isSection
        isCollapsable
        isRefreshEnabled
        tableActionKeys={[
          DomainUIElementEnum.PROCEEDING_FILES_DOWNLOAD_BUTTON,
          DomainUIElementEnum.PROCEEDING_FILES_DELETE_BUTTON
        ]}
        actionKey={DomainUIElementEnum.PROCEEDING_FILES_UPLOAD_BUTTON}
        rowActionIds={{
          download: DomainUIElementEnum.PROCEEDING_FILES_DOWNLOAD_BUTTON,
          delete: DomainUIElementEnum.PROCEEDING_FILES_DELETE_BUTTON
        }}
      />
    </div>
  );
}

export default FilesTab;
