import { AxiosError } from 'axios';

const notAllowedStatusCode = 403;

const isAxiosResponseError = (error: unknown): error is AxiosError => {
  return error instanceof AxiosError;
};

const notAllowedResponseCode = (statusCode: number): boolean => {
  return statusCode === notAllowedStatusCode;
};

export const checkIfErrorHasNotAllowedStatusCode = (error: unknown): boolean => {
  if (isAxiosResponseError(error)) {
    return notAllowedResponseCode(error.response.status);
  }
  return false;
};
