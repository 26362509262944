import { DefaultApi as GroupDefaultApi } from '@avispon/group';
import { DefaultApi as MessageDefaultApi } from '@avispon/message';
import { AccountsApi as UserAccountsApi, DefaultApi as UserDefaultApi } from '@avispon/user';
import axios from 'axios';

interface MessageApi {
  user: {
    default: UserDefaultApi;
    accountController: UserAccountsApi;
  };
  group: GroupDefaultApi;
  message: MessageDefaultApi;
}

const messageApiFactoryFallback = () => {
  throw new Error('Message API has not been set');
};

const API: MessageApi = {
  user: messageApiFactoryFallback as never,
  group: messageApiFactoryFallback as never,
  message: messageApiFactoryFallback as never
};

export function setMessageApi(API_PORTAL_URL: string) {
  API.user = {
    default: new UserDefaultApi({ isJsonMime: () => true }, API_PORTAL_URL, axios),
    accountController: new UserAccountsApi({ isJsonMime: () => true }, API_PORTAL_URL, axios)
  };
  API.group = new GroupDefaultApi({ isJsonMime: () => true }, API_PORTAL_URL, axios as never);
  API.message = new MessageDefaultApi({ isJsonMime: () => true }, API_PORTAL_URL, axios);
}

export default API;
