import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderDetails, SubjectAddressHistoryDetails } from '@ibtm/domain';

import { CamelCasePath, TableLayout, typedNameV2, useFormV2Context } from '@libs/common/v2';

import { useDomainConfigContext } from '@libs/domain/config';
import { useAddressHistoryTable } from '@libs/domain/folder';

interface IProps {
  isCollapsable?: boolean;
}

function HistoricalDataSection({ isCollapsable }: IProps) {
  const [t] = useTranslation();

  const { watch } = useFormV2Context();
  const { isClientPortal } = useDomainConfigContext();

  const visibleColumns = useMemo(
    (): Array<CamelCasePath<SubjectAddressHistoryDetails>> =>
      isClientPortal
        ? ['mainAddress', 'hqAddress', 'correspondenceAddress', 'applicationNumber']
        : ['mainAddress', 'hqAddress', 'correspondenceAddress', 'created', 'applicationNumber'],
    [isClientPortal]
  );

  const subjectId = watch?.(typedNameV2<FolderDetails>('subject.id')) as string;

  const tableProps = useAddressHistoryTable(subjectId, visibleColumns);

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('folder:details.section.historicalData')}
      isCollapsable={isCollapsable}
      isFilterEnabled={false}
      isSection
    />
  );
}

export default HistoricalDataSection;
