import { DriverDetails } from '@ibtm/domain';

import { CamelCasePath } from '@libs/common/v2';

import { DriverActionButtonsEnum, DriversColumnsEnum } from '@libs/domain/drivers/model';

import { DriverCertificateStatusEnum } from '../../driver-certificate';

export const VISIBLE_COLUMNS: Array<CamelCasePath<DriverDetails>> = [
  DriversColumnsEnum.FOLDER_NUMBER,
  DriversColumnsEnum.NAME,
  DriversColumnsEnum.SURNAME,
  DriversColumnsEnum.BIRTHDATE,
  DriversColumnsEnum.BIRTHPLACE,
  DriversColumnsEnum.CITIZENSHIP,
  DriversColumnsEnum.INSURANCE_PAYER_NIP,
  DriversColumnsEnum.EMPLOYMENT_DOCUMENT,
  DriversColumnsEnum.ID_TYPE,
  DriversColumnsEnum.ID_SERIES_NUMBER,
  DriversColumnsEnum.DRIVER_LICENSE_SERIES_NUMBER,
  DriversColumnsEnum.DRIVER_CERTIFICATE_NUMBER,
  DriversColumnsEnum.DRIVER_CERTIFICATE_FORM_NUMBER,
  DriversColumnsEnum.DRIVER_CERTIFICATE_STATUS,
  DriversColumnsEnum.DRIVER_CERTIFICATE_CANCELLATION_DATE,
  DriversColumnsEnum.DRIVER_CERTIFICATE_CANCELATION_REASON
];

export const TABLE_OPTIONS = {
  initialState: {
    filters: [
      {
        id: 'currentCertificateStatusKey',
        value: [{ value: DriverCertificateStatusEnum.ACTIVE }]
      }
    ]
  }
};

export const VISIBLE_ACTION_BUTTONS = {
  view: [],
  tableRow: {
    visible: [DriverActionButtonsEnum.PREVIEW],
    more: [
      DriverActionButtonsEnum.EXPIRE,
      DriverActionButtonsEnum.ACTIVATE,
      DriverActionButtonsEnum.GENERATE_EXPIRE_REPORT,
      DriverActionButtonsEnum.GENERATE_UP_TO_DATE,
      DriverActionButtonsEnum.GENERATE_ISSUED,
      DriverActionButtonsEnum.GO_TO_PROCEEDING
    ]
  }
};
