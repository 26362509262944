import { useTranslation } from 'react-i18next';
import { every, isString } from 'lodash';

import { Dialog, useIsSmallScreen, useMultipleSelectColumn } from '@libs/common/v2';
import ForwardTableState from '@libs/common/v2/components/table/ForwardTableState';
import useForwardedSelectedRowsAction from '@libs/common/v2/components/table/hooks/useForwardedSelectedRowsAction';

import { useDomainConfigContext } from '@libs/domain/config';

import { useConfirmAddFinancialSecurityDialog } from '../hooks';
import { FinancialSecurityStatusEnum } from '../model';

import FinancialSecurityTable from './FinancialSecurityTable';

interface IProps {
  folderTypeKey: string;
  folderId: string;
  transferredFoldersIds?: string[];
  applicationId: string;
  closeDialog: () => void;
  customOnSuccess?: () => void;
}

function FinancialSecuritySearchDialog({
  folderId,
  transferredFoldersIds,
  closeDialog,
  applicationId,
  folderTypeKey,
  customOnSuccess
}: IProps) {
  const [t] = useTranslation();
  const { isSmallScreen } = useIsSmallScreen();
  const { isOperatorPortal } = useDomainConfigContext();
  const { openConfirmAddFinancialSecurityDialog } = useConfirmAddFinancialSecurityDialog(customOnSuccess);
  const selectRowPlugin = useMultipleSelectColumn(() => null);
  const { forwardTableStateRef, getSelectedIds, handleDisableAction, isActionDisabled } =
    useForwardedSelectedRowsAction();

  return (
    <Dialog
      title={t('financialSecurity:action.search.dialogTitle')}
      confirmText={t('action.add')}
      cancelText={t('action.close')}
      onConfirm={() => {
        const selectedIds = getSelectedIds();
        openConfirmAddFinancialSecurityDialog(
          every(selectedIds, isString) ? (selectedIds as string[]) : [],
          applicationId
        );
      }}
      onCancel={closeDialog}
      dialogSize="large"
      isConfirmButtonDisabled={isActionDisabled}
      isFullScreen={isSmallScreen}
      isOpen
    >
      <FinancialSecurityTable
        folderTypeKey={folderTypeKey}
        tablePlugins={[selectRowPlugin]}
        visibleColumns={[
          'typeKey',
          'expirationDate',
          'amountPln',
          'amountEur',
          'currencyExchangeRateExchangeRateFrom',
          'statusKey'
        ]}
        isActionColumnEnabled={false}
        queryParams={{
          ...(isOperatorPortal
            ? { folderIds: folderId ? [folderId, ...(transferredFoldersIds || [])] : [] }
            : { folderIdEq: folderId }),
          statusKeys: [FinancialSecurityStatusEnum.ACTIVE, FinancialSecurityStatusEnum.NEW]
        }}
        selectionColumnWidth={48}
        isHeaderHidden
        isHiddenSelectRowHeader
      >
        <ForwardTableState ref={forwardTableStateRef} setState={handleDisableAction} />
      </FinancialSecurityTable>
    </Dialog>
  );
}

export default FinancialSecuritySearchDialog;
