import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { Dialog, TableLayout } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import { useReputationQueryResultsTable } from '../../../hooks';

interface Props {
  open: boolean;
  setQueryDetailsId: (id: string) => void;
  reputationRequestId: string;
  transportManagerId: string;
}

function ReputationQuerySearchModal({ open, setQueryDetailsId, reputationRequestId, transportManagerId }: Props) {
  const [t] = useTranslation();

  const valueClasses = useValueStyles();
  const tableProps = useReputationQueryResultsTable(transportManagerId, reputationRequestId);

  return (
    <Dialog
      title={t('folder:details.reputationDetailsModal.title')}
      onCancel={() => setQueryDetailsId(null)}
      isOpen={open}
    >
      <TableLayout
        {...tableProps}
        isSection
        isFilterEnabled={false}
        isRefreshEnabled={false}
        isActionColumnEnabled={false}
        sectionContentClassName={valueClasses.headerCell}
      />
    </Dialog>
  );
}

export const useValueStyles = makeStyles<Theme>(() => ({
  headerCell: {
    '& div.MuiTableRow-head > div[role=columnheader]:nth-last-child(2) > div > div': {
      width: important('auto')
    }
  }
}));

export default ReputationQuerySearchModal;
