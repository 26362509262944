import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { useSnackbar } from '@enigma/fe-ui';
import { useAppConfig } from '@libs/app-config';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  BackButton,
  Button,
  Divider,
  PageHeader,
  TypographyIcon,
  useConfirmDialog,
  useIsSmallScreen,
  useRouter
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { Theme, useThemeValuesContext } from '@libs/common/v2/theme';
import { calc, important } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';
import { FilesQueryKeysEnum } from '@libs/file';
import { useElementVisibility } from '@libs/permission';
import usePermissions from '@libs/permission/hooks/usePermissions';

import {
  DomainDictionaryEntry,
  DomainDictionaryEnum,
  DomainUIElementEnum,
  PermissionEnum,
  useDomainConfigContext
} from '@libs/domain/config';
import { FolderDetailsTabEnum, FolderSlider } from '@libs/domain/folder';

import {
  ApplicationQueryKeysEnum,
  DataInputNoCriminalRecordStatementKeys,
  GenerateApplicationPrintoutsData,
  useApplicationDetailsQuery,
  useClientApplicationGeneratePrintoutsMutation,
  useClientConfirmApplicationDraftMutation,
  useClientDeleteApplicationDraftMutation
} from '../api';
import { ApplicationDetailsContent } from '../components';
import { ApplicationConfirmOption, useApplicationConfirmDialog } from '../components/application-confirm-dialog';
import { useDeclarationOfNoCriminalRecordDialog } from '../components/declaration-of-no-criminal-record-dialog/DeclarationOfNoCriminalRecordDialog';
import { ApplicationTabIdEnum, ApplicationUpdatePageParams } from '../model';
import { checkIsPB1CApplicationAndTPOrOPFolder } from '../utils';

/**
 * Strona edycji wniosku na Portalu Klienta
 */
function ApplicationUpdatePage() {
  const { goToPage, routes } = useRouter();
  const { folderId, applicationId } = useParams<ApplicationUpdatePageParams>();
  const [sidePanelExpanded] = useState<boolean>(false);
  const { isClientPortal } = useDomainConfigContext();
  const { showSuccessSnackbar, showWarningSnackbar } = useSnackbar();
  const { translate } = useDictionaryTranslations();
  const { checkIsElementVisible } = useElementVisibility();
  const { hasPermission } = usePermissions();
  const queryCache = useQueryCache();
  const { width } = useWindowSize();
  const globalTheme = useThemeValuesContext();
  const isExtraSmallScreen = width < globalTheme?.breakpoints?.values?.sm;
  const { generateApplicationPrintouts } = useAppConfig();

  const { isSmallScreen } = useIsSmallScreen();

  const classes = usePageDetailsStyles({ sidePanelExpanded });
  const [t] = useTranslation();

  const goToFolderDetails = () => {
    goToPage({ pathname: routes.folderDetails(folderId), search: `tab=${FolderDetailsTabEnum.APPLICATIONS}` });
  };
  const applicationQuery = useApplicationDetailsQuery(applicationId, {
    enabled: Boolean(applicationId),
    refetchOnMount: true,
    onSuccess: data => {
      if (data.statusKey !== DomainDictionaryEntry.APPLICATION_STATUS.DRAFT) {
        goToFolderDetails();
        showWarningSnackbar(t('applications:message.canNotEditApplicationInThisStatus'));
      }
    }
  });

  const { mutate: deleteApplicationDraft } = useClientDeleteApplicationDraftMutation();
  const { mutate: confirmApplicationDraftMutation } = useClientConfirmApplicationDraftMutation();
  const { mutate: generateApplicationPrintoutsMutation } = useClientApplicationGeneratePrintoutsMutation();
  const [confirm] = useConfirmDialog();

  const goToApplicationAttachments = () => {
    goToPage({
      pathname: routes.applicationClientEdit(applicationId, folderId),
      search: `tab=${ApplicationTabIdEnum.FILES}`
    });
  };

  const handleCancelApplication = () => {
    if (isClientPortal && applicationQuery?.data?.statusKey === DomainDictionaryEntry.APPLICATION_STATUS.DRAFT) {
      deleteApplicationDraft(applicationId, {
        onSuccess: () => {
          showSuccessSnackbar(t('applications:message.applicationRejectSuccess'));
          goToFolderDetails();
        }
      });
      return;
    }
    goToFolderDetails();
  };

  const confirmApplicationDraft = ({ onSettled }: { onSettled?: () => void }) => {
    confirmApplicationDraftMutation(
      {
        applicationId,
        version: applicationQuery?.data?.version
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('applications:message.applicationApproveSuccess'));
          goToFolderDetails();
        },
        onSettled
      }
    );
  };
  const { openDeclarationOfNoCriminalRecordDialog } = useDeclarationOfNoCriminalRecordDialog();
  const { openApplicationConfirmDialog } = useApplicationConfirmDialog();

  const handleConfirmApplication = () => {
    confirm({
      title: t('applications:dialog.confirmApplicationTitle'),
      shortTitle: t('applications:dialog.confirmApplicationShortTitle'),
      message: t('applications:dialog.confirmApplicationMessage'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        confirmApplicationDraft({
          onSettled: closeDialog
        });
      }
    });
  };

  const handleGenerateApplicationPrintouts = ({
    values,
    closeDialog
  }: GenerateApplicationPrintoutsData & { closeDialog: () => void }) => {
    generateApplicationPrintoutsMutation(
      { applicationId, values },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('applications:message.applicationGenerateSuccess'));
          queryCache.invalidateQueries(ApplicationQueryKeysEnum.APPLICATION);
          queryCache.invalidateQueries(FilesQueryKeysEnum.FILE_LIST);
          goToApplicationAttachments();
        },
        onSettled: () => {
          closeDialog();
        }
      }
    );
  };
  const {
    APPLICATION_TYPE: {
      /** Przedłużenie ważności licencji */
      PB1C,
      /** Dodatkowy wypis z licencji */
      PB3A,
      /** Wydanie świadectwa kierowcy */
      issueOfDriverCertificate
    }
  } = DomainDictionaryEntry;

  const handleConfirmOrGenerateApplicationPrintouts = () => {
    openApplicationConfirmDialog({
      onConfirm: ({ formData, setLoading, closeDialog }) => {
        setLoading(true);
        switch (formData) {
          case ApplicationConfirmOption.PRINTOUTS_GENERATED_SEND_APPLICATION:
            return confirmApplicationDraft({
              onSettled: closeDialog
            });
          case ApplicationConfirmOption.GENERATE_PRINTOUTS: {
            const shouldOpenDeclarationOfNoCriminalRecordDialog = checkIsPB1CApplicationAndTPOrOPFolder(
              applicationQuery.data
            );

            if (shouldOpenDeclarationOfNoCriminalRecordDialog) {
              closeDialog();
              return openDeclarationOfNoCriminalRecordDialog({
                onConfirm: ({ formData: declarationFormData, closeDialog: closeDeclarationDialog, setIsLoading }) => {
                  const { address, personalData } = declarationFormData;
                  type RequestTypeObject = { [key in DataInputNoCriminalRecordStatementKeys]: string };
                  setIsLoading(true);
                  const requestTypeValues: RequestTypeObject = {
                    name: personalData.name,
                    surname: personalData.surname,
                    city: address.city,
                    postCode: address.postCode,
                    postCity: address.postCity,
                    street: address.street,
                    propertyNumber: address.propertyNumber,
                    apartmentNumber: address.apartmentNumber,
                    identityCardNumber: personalData.identityCardNumber,
                    issuingAuthority: personalData.issuingAuthority,
                    pesel: personalData.pesel
                  };
                  const parsedValues = Object.entries(requestTypeValues)
                    .map(i => ({
                      key: i[0] as DataInputNoCriminalRecordStatementKeys,
                      value: i[1]
                    }))
                    .filter(i => Boolean(i.value));
                  handleGenerateApplicationPrintouts({ closeDialog: closeDeclarationDialog, values: [parsedValues] });
                }
              });
            }
            return handleGenerateApplicationPrintouts({ closeDialog, values: [] });
          }
          default: {
            formData satisfies never;
            return null;
          }
        }
      }
    });
  };

  const handleConfirmApplicationType = (applicationType: string) => {
    if (isClientPortal && applicationQuery?.data?.statusKey === DomainDictionaryEntry.APPLICATION_STATUS.DRAFT) {
      switch (applicationType) {
        case issueOfDriverCertificate:
        case PB3A:
        case PB1C:
          return handleConfirmOrGenerateApplicationPrintouts();
        default:
          return handleConfirmApplication();
      }
    } else {
      return goToFolderDetails();
    }
  };
  const customButtonWrapperClass = isSmallScreen ? classes.buttonWrapper : undefined;
  const customButtonClass = isSmallScreen ? classes.button : undefined;

  return (
    <ApplicationDetailsContent
      isFormOpen
      applicationData={{
        ...applicationQuery.data,
        id: applicationId
      }}
      pageComponentProps={{
        isHeaderInContent: isSmallScreen,
        isHeaderHidden: isSmallScreen,
        allowScrollbarInContentWithHiddenHeader: isSmallScreen,
        header: (
          <PageHeader
            isSticky={!isSmallScreen}
            hasNoHeaderPadding={isSmallScreen}
            typographyClassName={isExtraSmallScreen && 'w-full'}
            isTitleColumnReverse={isExtraSmallScreen}
            title={t('applications:fillApplicationDataTitle')}
            titleCustomStyles={{
              ...(isSmallScreen
                ? { fontSize: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }
                : {})
            }}
            rightSideContentClassName={classes.rightSidebar}
            rightSideContent={
              <>
                <BackButton
                  label={isSmallScreen ? '' : t('action.goBack')}
                  iconClassName={isSmallScreen ? classes.icon : undefined}
                  style={{
                    ...(isSmallScreen ? { width: 40, minWidth: 40 } : {})
                  }}
                  isNoMargin={!isSmallScreen}
                  classNameWrapper={customButtonWrapperClass}
                  link={{
                    pathname: routes.folderDetails(folderId),
                    search: `tab=${FolderDetailsTabEnum.APPLICATIONS}`
                  }}
                />
                {!isSmallScreen && <Divider />}
                <Button
                  label={t('applications:action.cancelApplication')}
                  onClick={handleCancelApplication}
                  variant="outlined"
                  isNoMargin
                  classNameWrapper={customButtonWrapperClass}
                  className={customButtonClass}
                />
                <Button
                  label={t('action.confirm')}
                  onClick={() => {
                    if (generateApplicationPrintouts) {
                      handleConfirmApplicationType(applicationQuery.data.typeKey);
                    } else {
                      handleConfirmApplication();
                    }
                  }}
                  variant="contained"
                  isNoMargin
                  classNameWrapper={customButtonWrapperClass}
                  className={customButtonClass}
                />
              </>
            }
            isDividerShown
          >
            {isClientPortal && (
              <Tooltip
                title={t('applications:additionalField.categoryAndType')}
                className={classes.applicationTypeHeader}
              >
                <span className={classes.detailItem}>
                  <TypographyIcon icon="FileIcon" typographyIconClassName={classes.detail}>
                    {`${translate(
                      DomainDictionaryEnum.APPLICATION_CATEGORY,
                      applicationQuery.data?.categoryKey,
                      t('emptyMark')
                    )} / ${translate(
                      DomainDictionaryEnum.APPLICATION_TYPE,
                      applicationQuery.data?.typeKey,
                      t('emptyMark')
                    )}`}
                  </TypographyIcon>
                </span>
              </Tooltip>
            )}
          </PageHeader>
        ),
        rightSidebarContent: (
          <FolderSlider
            folderId={applicationQuery.data?.folder?.id}
            isCopyBlockadeListRedirectActionVisible={checkIsElementVisible(
              DomainUIElementEnum.APPLICATION_FOLDER_LOCK_REDIRECT_BUTTON
            )}
            isCopyEmailActionVisible={checkIsElementVisible(DomainUIElementEnum.APPLICATION_SUBJECT_EMAIL_COPY_BUTTON)}
            isCopyNIPActionVisible={checkIsElementVisible(DomainUIElementEnum.APPLICATION_SUBJECT_NIP_COPY_BUTTON)}
            isCopyREGONActionVisible={checkIsElementVisible(DomainUIElementEnum.APPLICATION_SUBJECT_REGON_COPY_BUTTON)}
            isFolderRedirectActionVisible={checkIsElementVisible(
              DomainUIElementEnum.APPLICATION_FOLDER_REDIRECT_BUTTON
            )}
            hasSubjectDataPermission={hasPermission(PermissionEnum.IBTM_DOMAIN_FOLDER_SUBJECT_VIEW)}
          />
        ),
        isRightSidebarVisible: checkIsElementVisible(DomainUIElementEnum.APPLICATION_FOLDER_SLIDER_VIEW)
      }}
    />
  );
}

const usePageDetailsStyles = makeStyles<Theme, { sidePanelExpanded?: boolean }>(() => ({
  content: {
    width: calc('100vw - 1000px')
  },
  icon: {
    marginRight: important('0px')
  },
  button: {
    padding: '0px',
    minWidth: 'min-content'
  },
  buttonWrapper: {
    margin: '3px'
  },
  rightSidebar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  applicationTypeHeader: {
    display: 'inline-block',
    paddingTop: 12
  }
}));

export default ApplicationUpdatePage;
