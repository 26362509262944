import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { DriverDetails } from '@ibtm/domain';

import { TableButton, useConfirmDialog, useTableContext } from '@libs/common/v2';

import { useActivateMutation } from '@libs/domain/drivers/api';

import { IActivateDriverRequestData } from '../../api/mutations/useActivateMutation';

interface IProps {
  data: DriverDetails;
  actionKey?: string;
}

function ActivateDriverButton({ data, actionKey }: IProps) {
  const [t] = useTranslation();

  const { showSnackbar } = useSnackbar();
  const [confirm] = useConfirmDialog();
  const { mutate: activate, isLoading } = useActivateMutation();
  const { refetch } = useTableContext();

  const handleActivate = () => {
    confirm({
      title: t('drivers:dialogs.activateTitle'),
      message: t('drivers:dialogs.activateMessage'),
      confirmText: t('drivers:dialogs.activateConfirmText'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        const requestData: IActivateDriverRequestData = {
          driverId: data?.id,
          versionedRequest: {
            version: data?.version
          }
        };

        setConfirmLoading(true);
        activate(requestData, {
          onSuccess: () => {
            showSnackbar('success', t('drivers:messages.activateSuccess'));
            closeDialog();
            refetch();
          },
          onSettled: () => {
            setConfirmLoading(false);
          }
        });
      }
    });
  };

  return (
    <TableButton onClick={handleActivate} isDisabled={isLoading} label={t('action.activate')} actionKey={actionKey} />
  );
}

export default ActivateDriverButton;
