import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppConfig } from '@libs/app-config';
import { Grid } from '@mui/material';

import {
  InputMode,
  Section,
  SelectOption,
  useFormV2Context,
  YearFilterContext,
  YearSelectField
} from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';

import { DomainDictionaryEntry, DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';

import { SingleAuthorizationBasicTable } from '../singleAuthorization/singleAuthorizationBasic';

import { EkmtLimitsSection, EkmtMainSection } from './sections';

function EkmtTab() {
  const { watch, setValue } = useFormV2Context();
  const { areEkmtLimitsHidden } = useAppConfig();
  const inputYear = watch('selectedYear') as SelectOption<string>;
  const { checkIsElementVisible } = useElementVisibility();
  const [t] = useTranslation();
  const { yearFilterContextValues, setYearFilterContextValues } = useDomainConfigContext();

  useEffect(() => {
    setValue('selectedYear', yearFilterContextValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const values = useMemo(
    () => ({ maxYear: inputYear?.value || yearFilterContextValues?.value }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputYear?.value]
  );

  return (
    <>
      <Section>
        <YearSelectField
          inputMode={InputMode.FORM}
          name="selectedYear"
          key="selectedYear"
          label={t('folder:details.field.filterByYear')}
          setGlobalYearSelect={setYearFilterContextValues}
        />
      </Section>
      <Grid spacing={0} container>
        <YearFilterContext.Provider value={values}>
          {!areEkmtLimitsHidden && (
            <>
              <Grid xs={12} item>
                <EkmtMainSection />
              </Grid>
              <Grid xs={12} item>
                {checkIsElementVisible(DomainUIElementEnum.FOLDER_EKMT_LIMIT_TABLE_VIEW) ? (
                  <EkmtLimitsSection isSection isCollapsable />
                ) : null}
              </Grid>
            </>
          )}
          <Grid xs={12} item>
            {checkIsElementVisible(DomainUIElementEnum.FOLDER_EKMT_RELEASED_OR_ISSUED_TABLE_VIEW) ? (
              <SingleAuthorizationBasicTable permissionType={DomainDictionaryEntry.FOREIGN_PERMIT_TYPE.EKMT} />
            ) : null}
          </Grid>
        </YearFilterContext.Provider>
      </Grid>
    </>
  );
}

export default EkmtTab;
