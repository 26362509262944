import { useTableLayoutProps } from '@libs/common/v2';

import { useLimitTransferQuery } from '../../../foreign-permission';
import useLimitTransferColumns from '../columns/useLimitTransferColumns';

function useLimitTransferTable(folderId?: string) {
  const { columns, mappedFilterFields, mappedSortFields } = useLimitTransferColumns();

  return useTableLayoutProps({
    tableHookQuery: useLimitTransferQuery,
    tableHookOptions: {
      sortByConverter: mappedSortFields,
      filterConverter: mappedFilterFields,
      columns
    },
    tableHookQueryInitialParams: {
      folderId
    },
    delayTable: false
  });
}

export default useLimitTransferTable;
