import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { KeyType } from '@libs/common';
import {
  AdditionalFilterEnum,
  FormV2Context,
  Page,
  Tab,
  TabContent,
  Tabs,
  TabsContent,
  typedNameV2,
  useFormV2Context,
  useTab,
  YearSelectField
} from '@libs/common/v2';
import { ButtonsGroup } from '@libs/common/v2/components/buttonsGroup';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import { useDomainConfigContext } from '@libs/domain/config';
import {
  PetitionsSocialCommissionPage,
  SocialCommissionBasicRulesTable,
  SocialCommissionCivicBoardTable,
  SocialCommissionTradeRulesTable,
  SocialCommissonTabsConfig
} from '@libs/domain/social-commission';

import { DomainUIElementEnum } from '../../config/permission';

import { SocialCommissionTabEnum } from './common/model/social-comission.model';

export type IntialYearType = { name: string; value: string };

interface ISocialCommisionGlobalFilter {
  year: IntialYearType;
  name: string;
}

interface IYearSelectField {
  yearFilter: IntialYearType;
}

function SocialCommissionPage() {
  const [t] = useTranslation();
  const { yearFilterContextValues, setYearFilterContextValues } = useDomainConfigContext();
  const classes = useStyles();
  const [tab, onTabChange] = useTab<SocialCommissionTabEnum>(SocialCommissionTabEnum.DEADLINES);
  const form = useForm();
  const year = form.watch(typedNameV2<IYearSelectField>('yearFilter')) as IntialYearType;

  useEffect(() => {
    form.setValue('yearFilter', yearFilterContextValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const globalFilters = (name: string) => [
    {
      type: AdditionalFilterEnum.CUSTOM_FILTER,
      name: 'year',
      customFilter: <SocialCommisionGlobalFilter year={year} name={name} />
    }
  ];

  return (
    <Page
      contentClassName="flex-col px-10"
      isTable
      headerRightSideContent={
        <ButtonsGroup>
          <FormV2Context.Provider value={form}>
            <YearSelectField key="yearFilter" name="yearFilter" setGlobalYearSelect={setYearFilterContextValues} />
          </FormV2Context.Provider>
        </ButtonsGroup>
      }
      isNoContentMargin
      title={t('foreignPermits:tabs.socialCommission.title')}
      content={
        <>
          <Tabs value={tab} onChange={onTabChange} orientation="horizontal" className={classes.tabsWrapper}>
            {SocialCommissonTabsConfig.map(item => (
              <Tab
                label={<div style={{ textAlign: 'center', margin: 'auto' }}>{t(item.label as KeyType)}</div>}
                key={item.label}
                value={item.value}
                viewName={item.viewName}
                isHorizontalTab
              />
            ))}
          </Tabs>
          <TabsContent<SocialCommissionTabEnum> value={tab} className={classes.tabsContent}>
            <TabContent
              value={SocialCommissionTabEnum.DEADLINES}
              viewName={DomainUIElementEnum.SOCIAL_COMMITTEE_DATE_OF_MEETINGS_TAB}
              isInnerScroll={false}
            >
              <SocialCommissionCivicBoardTable globalFilters={globalFilters} initialYear={year} />
            </TabContent>
            <TabContent
              value={SocialCommissionTabEnum.PETITIONS}
              viewName={DomainUIElementEnum.SOCIAL_COMMITTEE_APPLICATIONS}
              isInnerScroll={false}
            >
              <PetitionsSocialCommissionPage globalFilters={globalFilters} initialYear={year} />
            </TabContent>
            <TabContent
              value={SocialCommissionTabEnum.TRADE_RULES}
              viewName={DomainUIElementEnum.SOCIAL_COMMITTEE_FREE_DISPOSAL_RULE_LIST_VIEW}
              isInnerScroll
            >
              <SocialCommissionTradeRulesTable initialYear={year} globalFilters={globalFilters} />
            </TabContent>
            <TabContent
              value={SocialCommissionTabEnum.BASIC_RULES}
              viewName={DomainUIElementEnum.SOCIAL_COMMITTEE_LIMIT_LIST_VIEW}
              isInnerScroll={false}
            >
              <SocialCommissionBasicRulesTable initialYear={year} globalFilters={globalFilters} />
            </TabContent>
          </TabsContent>
        </>
      }
    />
  );
}

function SocialCommisionGlobalFilter({ year, name }: ISocialCommisionGlobalFilter) {
  const { setValue } = useFormV2Context();
  useEffect(() => {
    setValue(name, year);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  return null;
}

const useStyles = makeStyles<Theme>(theme => ({
  tabsContent: {
    overflow: important('hidden')
  },
  tabsWrapper: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`
  }
}));

export default SocialCommissionPage;
