import React from 'react';

import { useRouter } from '../../hooks';

interface ErrorBoundaryProps {
  isLoggedIn: boolean;
  goToPage: (route: string) => void;
  children: React.ReactNode;
}

class ErrorBoundaryClass extends React.Component<ErrorBoundaryProps> {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const { goToPage, isLoggedIn } = this.props;
    // eslint-disable-next-line no-console
    console.log('ErrorBoundary:', { error, errorInfo });
    goToPage(isLoggedIn ? '/general-error-page' : '/login');
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

const ErrorBoundary = props => {
  const { goToPage } = useRouter();
  return <ErrorBoundaryClass {...props} goToPage={goToPage} />;
};

export default ErrorBoundary;
