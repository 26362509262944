import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DefaultApiReassignOrganizationUnitMemberRequest } from '@avispon/organization-structure';
import { OrganizationUnitMemberReassignRequest } from '@avispon/organization-structure/dist/models';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import * as Yup from 'yup';

import {
  Checkbox,
  Dialog,
  FormMode,
  FormV2Context,
  GridItem,
  GridLayout,
  SelectOption,
  typedNameV2
} from '@libs/common/v2';

import { API, useUserDetailsQuery } from '@libs/user/api';
import { OrganizationUnitAutocompleteField } from '@libs/user/components';

interface ChangeUnitFormProps {
  userId: string;
  unitId: string;
  unitName: string;
  open: boolean;
  setIsOpen: (open: boolean) => void;
}

interface FieldsTypes {
  unitId: string;
  transferPermissions: boolean | string;
}

const initialValues = {
  unitId: '',
  transferPermissions: true
};

function ChangeUnitForm({ unitId, unitName, userId, open, setIsOpen }: ChangeUnitFormProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const [chosenUnitId, setChosenUnitId] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { refetch } = useUserDetailsQuery(userId);

  const form = useForm<Partial<OrganizationUnitMemberReassignRequest>>({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(
      Yup.object({
        unitId: Yup.string().nullable().required(),
        transferPermission: Yup.boolean()
      })
    )
  });

  const formDataProvider = useMemo(() => {
    return { ...form, formMode: FormMode.EDIT };
  }, [form]);

  const changeUnit = (organizationUnit: SelectOption) => {
    setChosenUnitId(organizationUnit?.id);
  };

  const onSubmit = (values: Partial<OrganizationUnitMemberReassignRequest>) => {
    setLoading(true);
    const organizationUnitMemberReassignRequest: DefaultApiReassignOrganizationUnitMemberRequest = {
      userId,
      body: {
        organizationUnitId: chosenUnitId,
        transferPermissions: values.transferPermissions
      }
    };

    API.organizationStructure
      .reassignOrganizationUnitMember(organizationUnitMemberReassignRequest)
      .then(() => {
        setLoading(false);
        showSuccessSnackbar(t('user:dialog.organizationUnitUpdateSuccess'));
        refetch();
        setIsOpen(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <FormV2Context.Provider value={formDataProvider}>
      <Dialog
        title={t('user:action.changeOrganizationUnit')}
        confirmText={t('global:action.save')}
        cancelText={t('action.cancel')}
        onConfirm={form.handleSubmit(onSubmit)}
        onCancel={() => setIsOpen(false)}
        isConfirmLoading={loading}
        isOpen={open}
      >
        <GridLayout>
          <GridItem xs={12}>
            <Typography variant="caption">{t('user:dialog.currentOrganizationUnit')}</Typography>
            <Typography variant="body2" className="mt-4">
              {unitName || ''}
            </Typography>
          </GridItem>
          <GridItem xs={12}>
            <OrganizationUnitAutocompleteField
              name={typedNameV2<FieldsTypes>('unitId')}
              excludeIds={[unitId]}
              label={t('field.organizationUnit')}
              optionChanged={changeUnit}
              renderOption={value => value.name}
              isRequired
              isClearable
            />
          </GridItem>
          <GridItem xs={12} className="flex items-center">
            <Typography variant="body2" className="mt-4">
              {t('user:action.transferPermissions')}
            </Typography>
            <Controller
              control={form.control}
              name="transferPermissions"
              render={({ field: { onChange, value } }) => <Checkbox onClick={onChange} isChecked={value} />}
            />
          </GridItem>
        </GridLayout>
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default ChangeUnitForm;
