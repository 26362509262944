import { PaginatedQueryConfig, usePaginatedQuery } from 'react-query';
import { DepotSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { IDepotsListClient } from '../../../model';
import { parseDepotsList } from '../../../utils';
import { endpointsConfig } from '../../endpoints-config';
import { ResourceQueryKeysEnum } from '..';

export const getDepots = async (params: DepotSearchFilter) => {
  const { data } = await API.depots.getDepots(params, {
    ...endpointsConfig.getDepots
  });

  return {
    ...data,
    content: parseDepotsList(data.content)
  };
};

const useDepotsQuery = (
  params: DepotSearchFilter,
  queryConfig: PaginatedQueryConfig<IPaginatedModel<IDepotsListClient>, unknown> = {}
) =>
  usePaginatedQuery<IPaginatedModel<IDepotsListClient>>(
    [ResourceQueryKeysEnum.DEPOTS_LIST, params],
    () => getDepots(params),
    queryConfig
  );

export default useDepotsQuery;
