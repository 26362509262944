import { Context, useContext } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { FormV2Context, FormV2ContextState, Typography } from '@libs/common/v2';

import { DictionaryEntryNameEnum } from '@libs/dictionary';

import MatrixRow from './MatrixRow';
import { IRowInput, TMatrixHeaderCell, TMatrixInputCell, TMatrixRow } from './model';

interface IProps {
  headers: TMatrixHeaderCell[];
  rowTitles: TMatrixRow[];
  rowInputs: (rowInput: IRowInput) => TMatrixInputCell[];
  rowTitlesColumnWidth?: number;
  rowTitlesTitle?: string;
  headerDictionary?: DictionaryEntryNameEnum;
  rowDictionary?: DictionaryEntryNameEnum;
  formContext?: Context<FormV2ContextState>;
  formPrefix?: string;
  isWhiteBackground?: boolean;
  formValuesPath?: string;
  noContentMessage?: string;
}

function InputMatrix({
  headers,
  rowTitles,
  headerDictionary,
  rowDictionary,
  rowInputs,
  formPrefix,
  formContext = FormV2Context,
  isWhiteBackground,
  rowTitlesColumnWidth,
  rowTitlesTitle,
  formValuesPath,
  noContentMessage
}: IProps) {
  const [t] = useTranslation();
  const { control } = useContext<FormV2ContextState>(formContext);
  const formValues = useWatch({ control, name: formValuesPath });
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MatrixRow
        dictionary={headerDictionary}
        cells={[{ name: { id: '', title: rowTitlesTitle || '' }, width: rowTitlesColumnWidth ?? 150 }, ...headers]}
        isWhiteBackground={isWhiteBackground}
        isHeader
      />
      {rowTitles?.length ? (
        <>
          {rowTitles.map((row, index) => (
            <div key={row?.title ?? row?.id} className="flex w-full">
              <MatrixRow
                dictionary={rowDictionary}
                row={row}
                formPrefix={formPrefix}
                cells={[
                  { name: row, width: rowTitlesColumnWidth ?? 150 },
                  ...rowInputs({
                    rowTitle: row,
                    rowData: formValues?.[row?.id ?? row?.title] ?? null,
                    allData: formValues
                  }).map((input, inputIndex) => ({
                    ...input,
                    width: headers[inputIndex].width
                  }))
                ]}
                className={clsx('w-full', rowTitles?.length === index + 1 && classes.lastRow)}
                isWhiteBackground={isWhiteBackground}
              />
            </div>
          ))}
        </>
      ) : (
        <div className="flex justify-center pt-20 pb-20">
          <Typography themeVariant="textSm.normal">{noContentMessage || t('global:error.noDataToShow')}</Typography>
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
    width: '100%'
  },
  lastRow: {
    '& > .cell': {
      borderBottom: 0
    }
  }
}));

export default InputMatrix;
