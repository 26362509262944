import { AlertDictionaryEntryNameEnum } from '@libs/alert';
import { AuthDictionaryEntryNameEnum } from '@libs/auth/models';
import { DictionaryEntryNameEnum as DictionaryModuleEntryNameEnum } from '@libs/dictionary/models/dictionaryEntryNameEnum';
import { DocumentTemplateDictionaryEntryNameEnum } from '@libs/document-template/common/documentTemplateDictionaryEntryNameEnum';
import { GroupDictionaryEntryNameEnum } from '@libs/group/common/groupDictionaryEntryNameEnum';
import { MessagesDictionaryEntryNameEnum } from '@libs/notification/models/MessagesDictionaryEntryNameEnum';
import { OrganizationDictionaryEntryNameEnum } from '@libs/organization-structure/models';
import { ReportDictionaryEntryNameEnum } from '@libs/report-core';
import { UserDictionaryEntryNameEnum } from '@libs/user/common/userDictionaryEntryNameEnum';

import { DomainDictionaryEnum, DomainQuickchangeableDictionaryEnum } from '@libs/domain/config';

export const DictionaryEntryNameEnum = {
  ...DomainDictionaryEnum,
  ...DocumentTemplateDictionaryEntryNameEnum,
  ...UserDictionaryEntryNameEnum,
  ...GroupDictionaryEntryNameEnum,
  ...ReportDictionaryEntryNameEnum,
  ...AlertDictionaryEntryNameEnum,
  ...AuthDictionaryEntryNameEnum,
  ...MessagesDictionaryEntryNameEnum,
  ...OrganizationDictionaryEntryNameEnum,
  ...DictionaryModuleEntryNameEnum
};

export const QuickChangeableDictionaryEntryNameEnum = {
  ...DomainQuickchangeableDictionaryEnum
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type QuickChangeableDictionaryEntryNameEnum = DomainQuickchangeableDictionaryEnum;
