import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryCache } from 'react-query';
import { PluginHook, Row, SortingRule } from 'react-table';
import { useSnackbar } from '@enigma/fe-ui';
import { LicenseSnapshot } from '@ibtm/domain';

import { TableLayout } from '@libs/common/v2';
import { getCalendarDate } from '@libs/common/v2/utils';

import { getDictionaryValues, useDictionary } from '@libs/dictionary';
import { useElementVisibility } from '@libs/permission';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';

import { LicensesQueryKeysEnum, useUpdateLicenseMutation } from '../api';
import { parseGetLicensesRequest } from '../api/queries/useLicensesQuery';
import { DEFAULT_COLUMNS } from '../config/license-column.config';
import { useLicensesTable, useLicensesValidation } from '../hooks';
import { LicensesColumnsEnum, LicenseSnapshotClient } from '../model/license.model';
import { parseLicensesList } from '../parsers/common';

interface IProps {
  actionsColumnWidth?: number;
  applicationId?: string;
  folderId?: string;
  visibleColumns?: Array<LicensesColumnsEnum | { column: LicensesColumnsEnum; isEditable: boolean }>;
  defaultFilters?: {
    id: string;
    value: any;
  }[];
  headerActions?: ReactNode;
  rowActions?: (row: Row<LicenseSnapshotClient>, isTableMutable?: boolean) => ReactNode;
  tablePlugins?: Array<PluginHook<LicenseSnapshotClient>>;
  licenseValidityYearsDictionary?:
    | DomainDictionaryEnum.LICENSE_VALIDITY_YEARS
    | DomainDictionaryEnum.LICENSE_VALIDITY_YEARS_PPO
    | DomainDictionaryEnum.LICENSE_VALIDITY_YEARS_TP_OP;
  isTableMutable?: boolean;
  includePartnershipPermissions?: boolean;
  defaultSort?: SortingRule<LicenseSnapshotClient>[];
  hiddenStatusFilterKeys?: string[];
  isTableDataVisible?: boolean;
  isHeaderActions?: boolean;
}

function LicensesTable({
  actionsColumnWidth,
  applicationId,
  folderId,
  visibleColumns = DEFAULT_COLUMNS,
  defaultFilters,
  headerActions,
  rowActions,
  tablePlugins,
  licenseValidityYearsDictionary,
  isTableMutable,
  includePartnershipPermissions,
  defaultSort,
  hiddenStatusFilterKeys = [],
  isTableDataVisible = true,
  isHeaderActions = true
}: IProps) {
  const [t] = useTranslation();

  const tableProps = useLicensesTable(
    visibleColumns,
    applicationId,
    folderId,
    defaultFilters,
    tablePlugins,
    licenseValidityYearsDictionary,
    isTableMutable,
    includePartnershipPermissions,
    defaultSort,
    hiddenStatusFilterKeys,
    isTableDataVisible
  );
  const queryCache = useQueryCache();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbar();
  const { mutate: updateLicense } = useUpdateLicenseMutation();
  const { getQuery } = useGetApiQueryByPerspective();
  const { getLicenseValidationScheme } = useLicensesValidation({ visibleLicenseColumns: visibleColumns });

  const onRowEdit = (data: LicenseSnapshotClient, { onSuccess }) => {
    const { licenseNumber, validTo, version, printDate, validFrom, licenseValidityYearsKey } = data;
    const licenseValidityYearsKeyValue =
      typeof licenseValidityYearsKey === 'string' ? licenseValidityYearsKey : licenseValidityYearsKey?.value;

    return updateLicense(
      {
        id: data.id,
        licenseValidityYearsKey: licenseValidityYearsKeyValue,
        number: licenseNumber,
        validFrom: getCalendarDate(validFrom),
        version,
        printDate: getCalendarDate(printDate),
        validTo: getCalendarDate(validTo)
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('success.save'));
          queryCache.invalidateQueries(LicensesQueryKeysEnum.LICENSE_COPIES_LIST);
          onSuccess();
        },
        onError: () => showErrorSnackbar(t('global:error.dataSaveFailure'))
      }
    );
  };

  const { checkIsElementVisible } = useElementVisibility();

  const permissionStatuses = getDictionaryValues(useDictionary(DomainDictionaryEnum.PERMISSION_STATUS));
  const areInactivePermissionsVisible = checkIsElementVisible(DomainUIElementEnum.FOLDER_PERMISSION_LIST_UNACTIVE);
  const areActivePermissionsVisible = checkIsElementVisible(DomainUIElementEnum.FOLDER_PERMISSION_LIST_ACTIVE);

  const xslsDownloadQuery = getQuery(
    params =>
      API.client.license
        .getLicensesSnapshotPage(
          '',
          '',
          parseGetLicensesRequest(
            params,
            areInactivePermissionsVisible,
            areActivePermissionsVisible,
            permissionStatuses
          )
        )
        .then(res => ({
          ...res,
          data: { ...res.data, content: parseLicensesList(res.data.content as Array<LicenseSnapshot>) }
        })),
    params =>
      API.license
        .getLicensesSnapshotPage(
          parseGetLicensesRequest(
            params,
            areInactivePermissionsVisible,
            areActivePermissionsVisible,
            permissionStatuses
          )
        )
        .then(res => ({ ...res, data: { ...res.data, content: parseLicensesList(res.data.content) } }))
  );
  return (
    <TableLayout<LicenseSnapshotClient, LicenseSnapshot>
      {...tableProps}
      pageTitle={t('license:title.licenses')}
      xlsxDownload={{
        fileName: t('license:title.licenses'),
        pathToXLSXTranslation: 'license:fields',
        apiRequest: xslsDownloadQuery
      }}
      headerActions={isHeaderActions && headerActions}
      rowActions={row => rowActions?.(row)}
      isActionColumnEnabled={Boolean(rowActions)}
      editFormYupResolverSchema={getLicenseValidationScheme}
      actionsColumnWidth={actionsColumnWidth}
      onRowEditCreateConfirm={onRowEdit}
      isRefreshEnabled
      isTableMutable
      isCollapsable
      isSection
    />
  );
}

export default LicensesTable;
