import { useQuery } from 'react-query';
import { ReportTypeDetailsSnapshot } from '@stack/report/dist/models';

import { API, endpointsConfig } from '..';

import { ReportQueryKeysEnum } from './QueryKeysEnum';

const getReportTypeDetails = async (_, params: { reportTypeId: string }) => {
  const { data } = await API.reportTypes.getReportType(params.reportTypeId, { ...endpointsConfig.getReportType });
  return data;
};

export default function useReportTypeDetailsQuery(params: { reportTypeId: string }) {
  return useQuery<ReportTypeDetailsSnapshot>([ReportQueryKeysEnum.REPORT_TYPE_DETAILS, params], getReportTypeDetails, {
    enabled: Boolean(params.reportTypeId)
  });
}
