import { ApplicationsApiCopyTransportManagersToAssignedApplicationRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

async function copyTransportManagerMutation({
  applicationId,
  transportManagerCopyRequest
}: ApplicationsApiCopyTransportManagersToAssignedApplicationRequest) {
  const { data } = await API.application.copyTransportManagersToAssignedApplication(
    applicationId,
    transportManagerCopyRequest,
    {
      ...endpointsConfig.copyTransportManagersToAssignedApplication
    }
  );
  return data;
}

async function copyTransportManagerMutationClient({
  applicationId,
  transportManagerCopyRequest
}: ApplicationsApiCopyTransportManagersToAssignedApplicationRequest) {
  const { data } = await API.client.application.copyTransportManagersToAssignedApplication(
    applicationId,
    '',
    '',
    transportManagerCopyRequest,
    {
      ...endpointsConfig.copyTransportManagersToAssignedApplication
    }
  );
  return data;
}

function useCopyTransportManagerMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(copyTransportManagerMutationClient, copyTransportManagerMutation));
}

export default useCopyTransportManagerMutation;
