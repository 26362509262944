import { DefaultApiFindPullNotificationsRequest } from '@avispon/message';
import { omit } from 'lodash';

export const parseAdminMessagesTablePayload = (
  params: DefaultApiFindPullNotificationsRequest
): DefaultApiFindPullNotificationsRequest => {
  const { page, size, sort, ...queryParams } = params;

  return {
    page,
    size,
    sort,
    body: {
      ...queryParams.body,
      ...omit(queryParams, ['body'])
    }
  };
};
