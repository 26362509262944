import { getInitialYear, useTableLayoutProps } from '@libs/common/v2';

import { useCommissionApplicationQuery } from '@libs/domain/social-commission';
import { SocialCommissionTableEnum } from '@libs/domain/social-commission/components/common/model/social-comission.model';
import { IntialYearType } from '@libs/domain/social-commission/components/SocialCommissionPage';

import { useCommissionApplicationColumns } from '../columns/useCommissionApplicationColumns';

function useCommissionApplicationTable(initialYear: IntialYearType) {
  const { columns, mappedFilterFields, mappedSortFields } = useCommissionApplicationColumns();

  const year = initialYear || getInitialYear();
  const initialParams = {
    [SocialCommissionTableEnum.YEAR_EXACT]: year.value
  };

  return useTableLayoutProps({
    tableHookQuery: useCommissionApplicationQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'number', desc: true }]
        }
      }
    },
    tableHookQueryInitialParams: initialParams
  });
}

export default useCommissionApplicationTable;
