import { TransitScheduleStopsPage as TransitScheduleStopsPageClient } from '@ibtm/client-domain';
import { TransitScheduleStopsPage, TransitStopCreateRequest } from '@ibtm/domain';
import { sortBy } from 'lodash';

import { StopFormData } from '../model';

export const parseStopRequest = (data: StopFormData): TransitStopCreateRequest => ({
  name: typeof data?.name === 'string' ? data?.name : data?.name?.value,
  typeKey: data?.typeKey?.value,
  countryKey: data?.countryKey?.value,
  city: typeof data?.city === 'string' ? data?.city : data?.city?.value,
  countryKey2: data?.countryKey2?.value,
  city2: typeof data?.city2 === 'string' ? data?.city2 : data?.city2?.value,
  directionKey: data?.directionKey?.value,
  agreementTypeKey: data?.agreementTypeKey?.value,
  kilometers: data?.kilometers,
  mainRoute: !data?.branchRoute,
  departures: data?.departures?.map((departure, i) => ({
    ...departure,
    ordinal: i + 1
  }))
});

export const parseTransitScheduleStopsData = (data: TransitScheduleStopsPage | TransitScheduleStopsPageClient) => ({
  // Posortowanie godzin przyjazdu/odjazdu po atrybucie ordinal żeby kolejność była odpowiednia,
  // oraz ustawienie mainRoute na wartość boolean
  ...data,
  content: data.content.map(item => ({
    ...item,
    mainRoute: !!item?.mainRoute,
    departures: sortBy(item.departures, 'ordinal'),
    branchRoute: !item?.mainRoute
  }))
});
