import { AxiosRequestConfig } from 'axios';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '@libs/notification/api';

const deleteMessage = (notificationsIds: string[]) => {
  return API.message.deleteNotifications({ body: { notificationsIds } }, {
    ...endpointsConfig.deleteNotifications
  } as AxiosRequestConfig);
};

function useDeleteMessageMutation() {
  return useMutation(deleteMessage);
}

export default useDeleteMessageMutation;
