import { PluginHook, SortingRule } from 'react-table';
import { LicenseSearchFilter as LicenseSearchFilterClient } from '@ibtm/client-domain';
import { LicenseSearchFilter } from '@ibtm/domain';

import { SelectOption, TableFilterMapper, TableSortMapper, useTableLayoutProps } from '@libs/common/v2';
import { DateRangeISO, getCalendarDate } from '@libs/common/v2/utils';

import { DomainDictionaryEnum, useDomainConfigContext } from '@libs/domain/config';
import { LicensesColumnsEnum, LicenseSnapshotClient } from '@libs/domain/license';

import { useLicensesQuery } from '../../api/queries';

import { useLicencesTableColumns } from './useLicencesTableColumns';

export const LicensesFilterConverter: TableFilterMapper<LicenseSnapshotClient, LicenseSearchFilter> = {
  applicationNumber: (applicationNumber: string) => ({ applicationNumberContains: applicationNumber }),
  cancellationReason: (cancellationReasons: SelectOption<string>[]) => ({
    cancellationReasonKeyIn: cancellationReasons.map(cancellationReason => cancellationReason.value)
  }),

  cancelledBy: (cancelledBy: string[]) => ({ cancellationByIn: cancelledBy }),
  cancellationDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
    cancellationDateGreaterThanOrEqual: getCalendarDate(dateFrom),
    cancellationDateLessThanOrEqual: getCalendarDate(dateTo)
  }),
  subjectName: (subjectName: string) => ({ subjectNameContains: subjectName }),
  subjectAddress: (subjectAddress: string) => ({ subjectAddressContains: subjectAddress }),
  licenseNumber: (licenseNumber: string) => ({ licenseNumberContains: licenseNumber }),
  formNumber: (formNumber: string) => ({ formNumberContains: formNumber }),
  printDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
    printDateGreaterThanOrEqual: getCalendarDate(dateFrom),
    printDateLessThanOrEqual: getCalendarDate(dateTo)
  }),
  validFrom: ({ dateFrom, dateTo }: DateRangeISO) => ({
    validFromGreaterThanOrEqual: getCalendarDate(dateFrom),
    validFromLessThanOrEqual: getCalendarDate(dateTo)
  }),
  validTo: ({ dateFrom, dateTo }: DateRangeISO) => ({
    validToGreaterThanOrEqual: getCalendarDate(dateFrom),
    validToLessThanOrEqual: getCalendarDate(dateTo)
  }),
  dateOfIssueExternal: ({ dateFrom, dateTo }: DateRangeISO) => ({
    dateOfIssueExternalGreaterThanOrEqual: getCalendarDate(dateFrom),
    dateOfIssueExternalLessThanOrEqual: getCalendarDate(dateTo)
  }),
  signerName: (signers: SelectOption<string>[]) => ({ signerIn: signers.map(signer => signer.value) }),
  status: (statuses: SelectOption<string>[]) => ({ statusKeyIn: statuses.map(status => status.value) })
};

export const LicensesSortConverter: TableSortMapper<LicenseSnapshotClient> = {
  applicationNumber: 'application.number',
  cancellationReason: 'cancellationReasonKey',
  cancelledBy: 'cancelledBy.name',
  subjectName: 'folder.subject.name',
  subjectAddress: 'application.subject.mainAddress.shortAddress',
  licenseNumber: 'number',
  formNumber: 'form.number',
  signerName: 'signer.name',
  status: 'statusKey'
};

const useLicensesTable = (
  visibleColumns: Array<LicensesColumnsEnum | { column: LicensesColumnsEnum; isEditable: boolean }>,
  applicationId?: string,
  folderId?: string,
  defaultFilters = [],
  tablePlugins?: Array<PluginHook<LicenseSnapshotClient>>,
  licenseValidityYearsDictionary?:
    | DomainDictionaryEnum.LICENSE_VALIDITY_YEARS
    | DomainDictionaryEnum.LICENSE_VALIDITY_YEARS_PPO
    | DomainDictionaryEnum.LICENSE_VALIDITY_YEARS_TP_OP,
  isMutableTable = false,
  includePartnershipPermissions?: boolean,
  defaultSort: SortingRule<LicenseSnapshotClient>[] = [],
  hiddenStatusFilterKeys?: string[],
  isTableDataVisible = true
) => {
  const { columns, mappedFilterFields, mappedSortFields } = useLicencesTableColumns({
    isMutableTable,
    visibleColumns: visibleColumns.map(item => (typeof item === 'object' ? item.column : item)),
    editableColumns: visibleColumns.reduce((acc, curr) => {
      if (typeof curr !== 'object') {
        return acc;
      }
      return {
        ...acc,
        [curr.column]: !!curr.isEditable
      };
    }, {}),
    licenseValidityYearsDictionary,
    hiddenStatusFilterKeys
  });
  const { isOperatorPortal } = useDomainConfigContext();

  const tableHookQueryInitialParams: LicenseSearchFilterClient | LicenseSearchFilter = {
    applicationIdIn: applicationId ? [applicationId] : undefined,
    ...(isOperatorPortal ? { folderIdIn: folderId ? [folderId] : undefined } : { folderIdEq: folderId }),
    includePartnershipPermissions: includePartnershipPermissions ? true : undefined
  };

  return useTableLayoutProps({
    tableHookQuery: isTableDataVisible && useLicensesQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      tablePlugins,
      sortByConverter: mappedSortFields,
      tableOptions: {
        initialState: {
          filters: defaultFilters,
          sortBy: defaultSort
        }
      }
    },
    tableHookQueryInitialParams: tableHookQueryInitialParams as Record<string, any>
  });
};

export default useLicensesTable;
