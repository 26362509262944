import { FoldersApiTransferFolderRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function transferFolder(requestData: FoldersApiTransferFolderRequest) {
  return API.folder.transferFolder(requestData.folderId, requestData.folderTransferRequest, {
    ...endpointsConfig.transferFolder
  });
}

function useFolderTransferMutations() {
  return useMutation(transferFolder);
}

export default useFolderTransferMutations;
