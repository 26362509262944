import { useTranslation } from 'react-i18next';

import { useTableAdapter } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

import TableButton from './TableButton';
import TableButtonEdit from './TableButtonEdit';

interface IProps {
  rowId: string;
  isRenderedAsActionIcon?: boolean;
  actionKey?: UIElementNameEnum;
}

/**
 * Komponent przeznaczony do użycia przy tabeli mutowalnej
 */
function TableEditRow({ rowId, actionKey, isRenderedAsActionIcon }: IProps) {
  const [t] = useTranslation();
  const table = useTableAdapter();

  const handleOnClick = () => {
    table.editRow(rowId);
  };

  return isRenderedAsActionIcon ? (
    <TableButtonEdit tooltipTitle={t('action.editRow')} onClick={handleOnClick} actionKey={actionKey} />
  ) : (
    <TableButton label={t('action.editRow')} onClick={handleOnClick} actionKey={actionKey} />
  );
}

export default TableEditRow;
