import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { EkmtCarnetSettlementCreateRequest, EkmtCarnetSettlementDetails } from '@ibtm/domain';
import moment from 'moment';

import { partialTranslate } from '@libs/common';
import {
  AutocompleteSelectField,
  DatepickerField,
  Dialog,
  FormMode,
  FormV2Context,
  GridItem,
  GridLayout,
  NumberInputField,
  SelectOption,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import {
  ekmtPermitValidationSchema,
  ForeignPermissionQueryEnum,
  useCreateEkmtCarnetSettlementMutation,
  useUpdateEkmtCarnetSettlementMutation
} from '@libs/domain/foreign-permission';

interface IProps {
  foreignPermissionId: string;
  initialData: EkmtCarnetSettlementDetails;
  closeDialog: () => void;
}

interface EkmtCarnetSettlementFormData extends Omit<EkmtCarnetSettlementCreateRequest, 'missingPageNumbers'> {
  missingPageNumbers: SelectOption<number>[];
}

function CarnetSettlementsEKMTForm({ foreignPermissionId, initialData, closeDialog }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();

  const isEditMode = !!initialData;

  const defaultData = initialData
    ? {
        ...initialData,
        missingPageNumbers: initialData?.missingPageNumbers?.map(item => ({
          value: item
        }))
      }
    : {};

  const { mutate: createEkmtCarnetSettlement, isLoading: isCreateLoading } = useCreateEkmtCarnetSettlementMutation();
  const { mutate: updateEkmtCarnetSettlement, isLoading: isUpdateLoading } = useUpdateEkmtCarnetSettlementMutation();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Partial<EkmtCarnetSettlementFormData>>({
    mode: 'onBlur',
    resolver: yupResolver(ekmtPermitValidationSchema()),
    defaultValues: initialData
      ? {
          ...defaultData
        }
      : { returnDate: moment().toISOString() }
  });

  const getLabel = partialTranslate('foreignPermits:tabs.releasedPermits.ekmtDetails.settlementEKMT.formFields');

  const getNumbersSelectOptions = (): SelectOption<number>[] => {
    const optionsArray = [];

    for (let i = 1; i <= 52; i++) {
      optionsArray.push({ value: i, name: i.toString() });
    }

    return optionsArray;
  };

  const missingPageNumbersSelectOptions = useMemo(() => getNumbersSelectOptions(), []);

  const handleOnConfirm = (formData: EkmtCarnetSettlementFormData) => {
    const createData = {
      ...formData,
      missingPageNumbers: formData?.missingPageNumbers?.map(item => item.value) || null
    };

    const updateData = { ...createData, version: initialData?.version };

    return isEditMode
      ? updateEkmtCarnetSettlement(
          { ekmtSettlementId: initialData?.id, ekmtCarnetSettlementUpdateRequest: updateData },
          {
            onSuccess: () => {
              showSuccessSnackbar(t('success.save'));
              queryCache.invalidateQueries([
                ForeignPermissionQueryEnum.PERMISSION_DETAILS_WITH_SETTLEMENT,
                foreignPermissionId
              ]);
              closeDialog();
            }
          }
        )
      : createEkmtCarnetSettlement(
          { foreignPermissionId, ekmtCarnetSettlementCreateRequest: createData },
          {
            onSuccess: () => {
              showSuccessSnackbar(t('success.add'));
              queryCache.invalidateQueries([
                ForeignPermissionQueryEnum.PERMISSION_DETAILS_WITH_SETTLEMENT,
                foreignPermissionId
              ]);
              closeDialog();
            }
          }
        );
  };

  const values = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      formMode: FormMode.CREATE
    }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('foreignPermits:tabs.releasedPermits.ekmtDetails.settlementEKMT.title')}
      confirmText={t('foreignPermits:settlementDisposablePermits.action.settle')}
      cancelText={t('action.cancel')}
      onConfirm={handleSubmit(formData => handleOnConfirm(formData as EkmtCarnetSettlementFormData))}
      onCancel={closeDialog}
      dialogSize="medium"
      isConfirmLoading={isCreateLoading || isUpdateLoading}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <GridLayout itemProps={{ xs: 6 }}>
          <NumberInputField
            name={typedNameV2<EkmtCarnetSettlementCreateRequest>('numberFrom')}
            label={getLabel('numberFrom')}
            min={1}
            max={52}
            isRequired
          />
          <NumberInputField
            name={typedNameV2<EkmtCarnetSettlementCreateRequest>('numberTo')}
            label={getLabel('numberTo')}
            min={1}
            max={52}
            isRequired
          />
          <DatepickerField
            name={typedNameV2<EkmtCarnetSettlementCreateRequest>('returnDate')}
            label={getLabel('returnDate')}
            isRequired
          />
          <NumberInputField
            name={typedNameV2<EkmtCarnetSettlementCreateRequest>('ridesCount')}
            label={getLabel('ridesCount')}
            isRequired
          />
          <NumberInputField
            name={typedNameV2<EkmtCarnetSettlementCreateRequest>('ridesWithoutCargoCount')}
            label={getLabel('ridesWithoutCargoCount')}
          />
          <NumberInputField
            name={typedNameV2<EkmtCarnetSettlementCreateRequest>('bilateralRidesCount')}
            label={getLabel('bilateralRidesCount')}
          />
          <NumberInputField
            name={typedNameV2<EkmtCarnetSettlementCreateRequest>('multilateralRidesCount')}
            label={getLabel('multilateralRidesCount')}
          />
          <NumberInputField
            name={typedNameV2<EkmtCarnetSettlementCreateRequest>('ridesBetweenEuCount')}
            label={getLabel('ridesBetweenEuCount')}
          />
          <NumberInputField
            name={typedNameV2<EkmtCarnetSettlementCreateRequest>('ridesBetweenAndOutsideEuCount')}
            label={getLabel('ridesBetweenAndOutsideEuCount')}
          />
          <NumberInputField
            name={typedNameV2<EkmtCarnetSettlementCreateRequest>('ridesOutsideEuCount')}
            label={getLabel('ridesOutsideEuCount')}
          />
          <NumberInputField
            name={typedNameV2<EkmtCarnetSettlementCreateRequest>('ridesBetweenRuAndPlCount')}
            label={getLabel('ridesBetweenRuAndPlCount')}
          />
          <NumberInputField
            name={typedNameV2<EkmtCarnetSettlementCreateRequest>('ridesBetweenRuAndOtherCount')}
            label={getLabel('ridesBetweenRuAndOtherCount')}
          />
          <NumberInputField
            name={typedNameV2<EkmtCarnetSettlementCreateRequest>('ridesInAndOutPlCount')}
            label={getLabel('ridesInAndOutPlCount')}
          />
          <NumberInputField
            name={typedNameV2<EkmtCarnetSettlementCreateRequest>('ridesBetweenUkAndOtherCount')}
            label={getLabel('ridesBetweenUkAndOtherCount')}
          />
          <NumberInputField
            name={typedNameV2<EkmtCarnetSettlementCreateRequest>('ridesBetweenUkAndPlCount')}
            label={getLabel('ridesBetweenUkAndPlCount')}
          />
          <AutocompleteSelectField
            options={missingPageNumbersSelectOptions}
            name={typedNameV2<EkmtCarnetSettlementCreateRequest>('missingPageNumbers')}
            label={getLabel('missingPageNumbers')}
            isMultiple
          />
          <GridItem xs={12}>
            <TextInputField
              name={typedNameV2<EkmtCarnetSettlementCreateRequest>('notes')}
              label={getLabel('notes')}
              lines={3}
            />
          </GridItem>
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default CarnetSettlementsEKMTForm;
