import * as Yup from 'yup';

import { i18n } from '@libs/common';

import { useDictionaryEntryValues } from '@libs/dictionary';
import { DocumentTemplateDictionaryEntryNameEnum } from '@libs/document-template/common/documentTemplateDictionaryEntryNameEnum';

export const useFileSchema = () => {
  const SUPPORTED_FORMATS = useDictionaryEntryValues(
    DocumentTemplateDictionaryEntryNameEnum.DOCUMENT_TARGET_MIME_TYPE
  ).map(({ value }) => value);

  const templateCreateMainSectionSchema = Yup.object({
    name: Yup.string().required(),
    groupKeys: Yup.array().required().typeError(i18n.t('validation:required')),
    description: Yup.string().required(),
    targetMimeType: Yup.object({
      name: Yup.string().required()
    })
      .required()
      .nullable(),
    templateFile: Yup.object({
      fileId: Yup.string().required(),
      name: Yup.string().required(),
      type: Yup.string().required()
    })
      .test(
        'fileFormat',
        i18n.t('validation:wrongFileFormat'),
        value => value && SUPPORTED_FORMATS.includes(value.type)
      )
      .typeError(i18n.t('validation:required')),
    isIncludeExternalInvisible: Yup.boolean()
  });
  const templateEditMainSectionSchema = Yup.object({
    name: Yup.string().required(),
    groupKeys: Yup.array().nullable().required(),
    description: Yup.string().required(),
    templateFile: Yup.object({
      fileId: Yup.string(),
      name: Yup.string(),
      type: Yup.string()
    })
      .test('fileFormat', i18n.t('validation:wrongFileFormat'), value =>
        value ? SUPPORTED_FORMATS.includes(value.type) : true
      )
      .typeError(i18n.t('validation:required'))
      .nullable(),
    isIncludeExternalInvisible: Yup.boolean()
  });

  return { templateCreateMainSectionSchema, templateEditMainSectionSchema };
};
