import { useEffect, useMemo } from 'react';
import { UseFormReset, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { FinancialSecurityDetails } from '@ibtm/domain';
import moment from 'moment';

import { useFields } from '@libs/common/v2';
import { convertCalendarDate, RecursivePartial } from '@libs/common/v2/utils';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import { useFinancialSecurityQuery } from '../api';
import { CurrencyTypeEnum, FinancialSecurityFieldIds, FinancialSecurityFields } from '../model';

import useCurrencyExchange from './useCurrencyExchange';

interface IProps {
  financialSecurityId?: string;
  hiddenFieldIds?: Array<FinancialSecurityFieldIds>;
  initialData?: RecursivePartial<FinancialSecurityDetails>;
  watch: UseFormWatch<FinancialSecurityFields>;
  setValue: UseFormSetValue<FinancialSecurityFields>;
  reset: UseFormReset<FinancialSecurityFields>;
  trigger: UseFormTrigger<FinancialSecurityFields>;
  folderTypeKey: string;
  exchangeRateFrom: string;
  exchangeRateTo: string;
}

function useFinancialSecurityFields({
  exchangeRateFrom,
  exchangeRateTo,
  financialSecurityId,
  reset,
  watch,
  trigger,
  setValue,
  initialData,
  folderTypeKey
}: IProps) {
  const { data, isLoading } = useFinancialSecurityQuery(financialSecurityId, {
    enabled: Boolean(financialSecurityId),
    initialData,
    initialStale: true
  });

  const isPPOFolderType = useMemo(() => folderTypeKey === DomainDictionaryEntry.FOLDER_TYPE.PPO, [folderTypeKey]);

  const initialValues: FinancialSecurityFields = {
    typeKey: null,
    releaseDate: moment().format('YYYY-MM-DD').toString(),
    expirationDate: null,
    currencyKey: isPPOFolderType ? { key: CurrencyTypeEnum[CurrencyTypeEnum.PLN], value: CurrencyTypeEnum.PLN } : null,
    amount: isPPOFolderType ? 5000 : null,
    exchangeAmount: null,
    exchangeCurrency: null,
    notes: null,
    exchangeRate: null,
    exchangeRateFromDate: null,
    statusKey: null,
    created: null,
    author: null,
    modified: null,
    modifier: null,
    version: null,
    currencyExchangeRateId: null
  };

  const getAmount = () => {
    if (data?.currencyKey) {
      return data?.currencyKey === CurrencyTypeEnum.EUR ? data?.amountEur : data?.amountPln;
    }
    return null;
  };
  const getExchangeAmount = () => {
    if (data?.currencyKey) {
      return data?.currencyKey === CurrencyTypeEnum.EUR ? data?.amountPln : data?.amountEur;
    }
    return null;
  };

  useEffect(() => {
    if (reset) {
      if (data) {
        reset({
          typeKey: data?.typeKey ? { value: data?.typeKey } : null,
          releaseDate: convertCalendarDate(data?.releaseDate),
          expirationDate: convertCalendarDate(data?.expirationDate),
          currencyKey: data?.typeKey ? { value: data?.currencyKey as `${CurrencyTypeEnum}` } : null,
          amount: getAmount(),
          exchangeAmount: getExchangeAmount(),
          notes: data?.notes ?? null,
          author: data?.author?.name ?? null,
          created: data?.created ?? null,
          modifier: data?.modifier?.name ?? null,
          modified: data?.modified ?? null,
          version: data?.version ?? null
        });
      } else {
        reset(initialValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, reset]);

  const currencyKeyWatch = watch('currencyKey');
  const amountWatch = watch('amount');

  const { currencyExchangeRate, exchangeAmount, exchangeCurrency } = useCurrencyExchange({
    currencyKey: currencyKeyWatch?.value ?? null,
    exchangeRateFrom,
    exchangeRateTo,
    amount: amountWatch
  });

  useEffect(() => {
    setValue('exchangeAmount', exchangeAmount);
    if (exchangeAmount) {
      trigger('exchangeAmount');
    }
  }, [exchangeAmount, setValue, trigger]);

  useEffect(() => {
    setValue('exchangeCurrency', exchangeCurrency);
    if (exchangeCurrency) {
      trigger('exchangeCurrency');
    }
  }, [exchangeCurrency, setValue, trigger]);

  useEffect(() => {
    if (currencyExchangeRate) {
      setValue('exchangeRate', currencyExchangeRate?.exchangeRate);
      setValue('exchangeRateFromDate', convertCalendarDate(currencyExchangeRate?.exchangeRateFrom));
      setValue('currencyExchangeRateId', currencyExchangeRate?.id);
      trigger(['exchangeRate', 'exchangeRateFromDate', 'currencyExchangeRateId']);
    }
  }, [currencyExchangeRate, setValue, trigger]);

  const typeKeyDictionaryName = useMemo(() => {
    if (folderTypeKey === DomainDictionaryEntry.FOLDER_TYPE.PPO) {
      return DomainDictionaryEnum.FINANCE_SECURITY_KIND_PPO;
    }
    return DomainDictionaryEnum.FINANCE_SECURITY_KIND_TP_AND_OP;
  }, [folderTypeKey]);

  const { renderField } = useFields<FinancialSecurityFields>(
    [
      {
        id: 'typeKey',
        type: 'DICTIONARY',
        dictionaryName: typeKeyDictionaryName,
        validation: {
          required: true
        }
      },
      {
        id: 'releaseDate',
        type: 'DATETIME'
      },
      {
        id: 'expirationDate',
        type: 'DATETIME',
        validation: {
          required: true
        }
      },
      {
        id: 'currencyKey',
        type: 'DICTIONARY',
        dictionaryName: DomainDictionaryEnum.CURRENCY_CODE,
        ...(isPPOFolderType && { isDisabled: true }),
        validation: {
          required: true
        }
      },
      {
        id: 'amount',
        type: 'NUMBER',
        validation: {
          required: true,
          min: 0
        }
      },
      {
        id: 'exchangeCurrency',
        type: 'TEXT',
        validation: {
          required: true
        }
      },
      {
        id: 'exchangeAmount',
        type: 'NUMBER',
        validation: {
          required: true
        }
      },
      {
        id: 'notes',
        type: 'TEXT',
        validation: {
          maxLength: 500
        },
        lines: 3
      },
      {
        id: 'exchangeRate',
        type: 'TEXT'
      },
      {
        id: 'exchangeRateFromDate',
        type: 'DATETIME'
      },
      {
        id: 'created',
        type: 'DATETIME'
      },
      {
        id: 'author',
        type: 'TEXT'
      },
      {
        id: 'modified',
        type: 'DATETIME'
      },
      {
        id: 'modifier',
        type: 'TEXT'
      },
      {
        id: 'statusKey',
        type: 'DICTIONARY',
        dictionaryName: DomainDictionaryEnum.FINANCE_SECURITY_STATUS
      }
    ],
    { translationPath: 'financialSecurity:field' }
  );

  return { renderField, isDataLoading: isLoading };
}

export default useFinancialSecurityFields;
