import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { MoneyTransferSnapshot } from '@ibtm/domain';

import { Button, TableIconButton, TableLayout, useConfirmDialog, useViewModesV2 } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useDeleteMoneyTransferMutation } from '../api';
import { PricingQueryKeysEnum } from '../api/query/PricingQueryKeysEnum';
import {
  useMoneyTransfersForApplicationTable,
  useMoneyTransferUnconfirmedAddDialog,
  useMoneyTransferUnconfirmedEditDialog
} from '../hooks';

interface IProps {
  applicationId?: string;
  areActionsVisible?: boolean;
  dropdownMenu: React.ReactNode;
}

function MoneyTransfersUnconfirmedTable({ applicationId, areActionsVisible, dropdownMenu }: IProps) {
  const [t] = useTranslation();
  const { showMoneyTransferUnconfirmedAddDialog } = useMoneyTransferUnconfirmedAddDialog();
  const { viewMode } = useViewModesV2();
  const { showMoneyTransferUnconfirmedEditDialog } = useMoneyTransferUnconfirmedEditDialog();
  const { mutate: deleteMoneyTransfer } = useDeleteMoneyTransferMutation();
  const { showSuccessSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  const [confirm] = useConfirmDialog();

  const tableProps = useMoneyTransfersForApplicationTable(applicationId, ['paymentDate', 'amount'], {
    quorumConfirmation: false
  });

  const deleteTransferDialog = (original: MoneyTransferSnapshot) => {
    confirm({
      title: t('pricing:moneyTransfersUnconfirmed.deleteDialog.title'),
      message: t('pricing:moneyTransfersUnconfirmed.deleteDialog.message'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        deleteMoneyTransfer(
          { moneyTransferId: original?.id },
          {
            onSuccess: () => {
              showSuccessSnackbar(t('pricing:moneyTransfersUnconfirmed.deleteDialog.successMessage'));
              queryCache.invalidateQueries([PricingQueryKeysEnum.APPLICATION_MONEY_TRANSFERS]);
              closeDialog();
            }
          }
        );
      },
      confirmText: t('action.delete'),
      confirmType: 'danger'
    });
  };

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('pricing:moneyTransfersUnconfirmed.tableTitle')}
      rowActions={({ original }) => (
        <>
          {!viewMode && areActionsVisible && (
            <>
              <TableIconButton
                icon="EditIcon"
                tooltipTitle={t('pricing:moneyTransfersUnconfirmed.action.edit.buttonTitle')}
                onClick={() => showMoneyTransferUnconfirmedEditDialog({ original })}
              />
              <TableIconButton
                icon="TrashIcon"
                tooltipTitle={t('pricing:moneyTransfersUnconfirmed.action.delete.buttonTitle')}
                onClick={() => deleteTransferDialog(original)}
              />
            </>
          )}
        </>
      )}
      isFilterEnabled={false}
      headerActions={
        <>
          {!viewMode && areActionsVisible && (
            <Button
              label={t('pricing:moneyTransfersUnconfirmed.action.addPayment.buttonTitle')}
              variant="outlined"
              onClick={() => showMoneyTransferUnconfirmedAddDialog({ applicationId })}
              isSecondary
            />
          )}
          {dropdownMenu}
        </>
      }
      isSection
    />
  );
}

export default MoneyTransfersUnconfirmedTable;
