import { QueryConfig, useQuery } from 'react-query';
import { NoteDetails } from '@ibtm/domain';

import { API } from '@libs/domain/api';
import { SuspensionQueryKeysEnum } from '@libs/domain/suspensions';

import { endpointsConfig } from '../endpoints-config';

const getSuspensionNote = async (_, id: string) => {
  const { data } = await API.suspensionNote.getSuspensionNote(id, {
    ...endpointsConfig.getSuspensionNote
  });
  return data;
};

function useSuspensionNoteQuery(id: string, queryConfig: QueryConfig<NoteDetails> = {}) {
  return useQuery([SuspensionQueryKeysEnum.SUSPENSION_REMARK, id], getSuspensionNote, queryConfig);
}

export default useSuspensionNoteQuery;
