import { array as YupArray, ArraySchema, object as YupObject, ObjectSchema } from 'yup';

import { FieldTypeEnum, ValidationRegistryType } from './model';
import { createValidationRegistry } from './registry';

const checkIsIndex = path => path?.match(/^[0-9]+$/) != null;

const createArrayRulesRecursive = (arrayScheme: ArraySchema<unknown>, path: string[], value): ArraySchema<unknown> => {
  const nextPath = path.slice(1);
  const nextPathArray = path.slice(2);
  const currentPropery = path[0];
  const currentPropertyIsArray = checkIsIndex(currentPropery);
  const nextPropertyIsArray = checkIsIndex(path[1]);

  const concat = scheme => arrayScheme.concat(scheme);
  switch (true) {
    case nextPropertyIsArray:
      return concat(
        YupArray(
          YupObject({ [currentPropery]: createArrayRulesRecursive(YupArray().nullable(), nextPathArray, value) })
        )
      );
    case path.length > 1 && !currentPropertyIsArray:
      return concat(
        YupArray(YupObject({ [currentPropery]: createObjectRulesRecursive(YupObject(), nextPath, value) }))
      );
    case currentPropertyIsArray:
      return concat(YupArray(createArrayRulesRecursive(YupArray(), nextPath, value)));
    default:
      return concat(YupArray(YupObject({ [currentPropery]: value })));
  }
};

const createObjectRulesRecursive = (objectScheme: ObjectSchema, path: string | string[], value): ObjectSchema => {
  const { fields } = objectScheme;
  const pathArray = Array.isArray(path) ? path : path.split('.');
  const nextPath = pathArray.slice(1);
  // jeżeli kolejny element path to liczba, czyli index tablicy to przy przekazaniu go do funkcji setArrayRules pomijamy go
  const nextPathArray = pathArray.slice(2);
  const currentPropery = pathArray[0];
  const nextProperty = pathArray[1];

  const nextPropertyIsArray = checkIsIndex(nextProperty);
  const properyHasValue = objectScheme?.fields[currentPropery];

  const shape = scheme => objectScheme.shape(scheme).nullable();

  const isNotLastPath = pathArray.length > 1;
  switch (true) {
    case properyHasValue && nextPropertyIsArray:
      return shape({
        [currentPropery]: createArrayRulesRecursive(fields[currentPropery], nextPathArray, value)
      });
    case isNotLastPath:
      switch (true) {
        case properyHasValue && !nextPropertyIsArray:
          return shape({
            [currentPropery]: createObjectRulesRecursive(fields[currentPropery], nextPath, value)
          });
        case nextPropertyIsArray:
          return shape({ [currentPropery]: createArrayRulesRecursive(YupArray(), nextPathArray, value) });
        default:
          return shape({ [currentPropery]: createObjectRulesRecursive(YupObject(), nextPath, value) });
      }
    case properyHasValue:
      return shape({ [currentPropery]: fields[currentPropery].concat(value) });
    default:
      return shape({ [currentPropery]: value });
  }
};

export const validationSchemaBuilder = ({
  fields,
  validators = createValidationRegistry(),
  extendYupScheme = YupObject()
}: {
  fields: {
    fieldName: string;
    isMultiple?: boolean;
    validation: { [key in string]: any[] };
    fieldType: FieldTypeEnum;
  }[];
  validators?: ValidationRegistryType;
  extendYupScheme?: ObjectSchema;
}) => {
  return fields.reduce((acc, curr) => {
    const { isMultiple, fieldType, validation, fieldName } = curr;
    const shemeBase = isMultiple ? validators?.multiple?.[fieldType]._base : validators?.[fieldType]?._base;
    const additionalRules = isMultiple ? validators?.multiple?.[fieldType] : validators?.[fieldType];

    const shape = createObjectRulesRecursive(
      acc,
      fieldName,
      Object.entries(validation).reduce(
        (accValidation, [validatorName, params]) =>
          accValidation.concat(
            additionalRules?.[validatorName]?.(...params) || shemeBase?.[validatorName]?.(...params),
            shemeBase
          ),
        shemeBase
      )
    );

    return shape;
  }, extendYupScheme);
};
