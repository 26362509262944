import { DriverDetails } from '@ibtm/domain';

import { CamelCasePath } from '@libs/common/v2';

import { DomainUIElementEnum } from '@libs/domain/config';
import { DriversTable } from '@libs/domain/drivers/components';
import { IDriverTableOptions } from '@libs/domain/drivers/hooks/tables/useDriversTable';

import { DriverActionButtonsEnum } from '../model';

interface IProps {
  title?: string;
  visibleColumns?: Array<CamelCasePath<DriverDetails>>;
  tableOptions?: IDriverTableOptions;
  visibleActionButtons?: {
    view?: DriverActionButtonsEnum[];
    tableRow?: {
      visible?: DriverActionButtonsEnum[];
      more?: DriverActionButtonsEnum[];
    };
  };
  additionalData?: {
    applicationId?: string;
    folderId?: string;
    subjectNip?: string;
  };
}

export default function DriversTablePage({
  title,
  visibleColumns,
  tableOptions,
  visibleActionButtons,
  additionalData
}: IProps) {
  return (
    <DriversTable
      title={title}
      visibleColumns={visibleColumns}
      tableOptions={tableOptions}
      visibleActionButtons={visibleActionButtons}
      additionalData={{ ...additionalData }}
      permissionsKeysObject={{
        GENERATE_EXPIRE_REPORT: DomainUIElementEnum.DRIVER_GENERATE_EXPIRE_REPORT_BUTTON,
        PREVIEW: DomainUIElementEnum.DRIVER_DETAILS_BUTTON,
        GENERATE_UP_TO_DATE: DomainUIElementEnum.DRIVER_GENERATE_UP_TO_DATE_REPORT_BUTTON,
        GENERATE_ISSUED: DomainUIElementEnum.DRIVER_GENERATE_ISSUED_REPORT_BUTTON,
        EXPIRE: DomainUIElementEnum.DRIVER_EXPIRE_BUTTON,
        ACTIVATE: DomainUIElementEnum.DRIVER_ACTIVATE_BUTTON,
        GO_TO_PROCEEDING: DomainUIElementEnum.DRIVER_GO_TO_PROCEEDING
      }}
    />
  );
}
