import { ForeignPermissionSearchFilter } from '@ibtm/domain';

import { SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { ClientForeignPermissions } from '../../model';

function useClientForeignPermissionsColumns() {
  const { createColumns } = useCreateColumns<ClientForeignPermissions>({
    pathToTranslate: 'foreignPermits:clientForeignPermissions.fields'
  });

  const mappedSortFields: TableSortMapper<ClientForeignPermissions> = {
    countryCode: 'issuingCountryKey',
    state: 'form.stateKey',
    formNumber: 'form.number',
    subjectName: 'folder.subject.name',
    codeKey: 'settlement.codeKey'
  };

  const mappedFilterFields: TableFilterMapper<ClientForeignPermissions, ForeignPermissionSearchFilter> = {
    countryCode: (countries: SelectOption<string>[]) => ({ countryCodeKeyIn: countries.map(({ value }) => value) }),
    name: (name: string) => ({ nameContains: name }),
    state: (states: SelectOption<string>[]) => ({ formStateKeyIn: states.map(({ value }) => value) }),
    formNumber: (formNumber: string) => ({ formNumberContains: formNumber }),
    subjectName: (subjectName: string) => ({ subjectNameContains: subjectName }),
    codeKey: (settlementNumber: SelectOption<string>) => ({ codeKeyIn: [settlementNumber?.value] })
  };

  const columns = createColumns([
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.COUNTRY_CODE,
      accessor: 'countryCode',
      width: 200
    },
    {
      accessor: 'name',
      type: 'TEXT',
      width: 200
    },
    {
      id: 'state',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_STATE,
      width: 200
    },
    {
      type: 'TEXT',
      accessor: 'formNumber'
    },
    {
      type: 'TEXT',
      accessor: 'subjectName'
    },
    {
      type: 'DICTIONARY_SINGLE_SELECT',
      dictionaryName: DomainDictionaryEnum.RETURNS_OF_FOREIGN_LICENSES,
      accessor: 'codeKey',
      header: 'billingCode'
    }
  ]);

  return { columns, mappedSortFields, mappedFilterFields };
}

export default useClientForeignPermissionsColumns;
