import React, { Context, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { CircularProgress, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _, { isArray, isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  ComponentErrorBoundary,
  FormV2Context,
  FormV2ContextState,
  Icon,
  SelectOption,
  SUPPORTED_FILE_EXTENSIONS,
  SUPPORTED_FILE_TYPES,
  TextInput,
  Typography
} from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { calc, important } from '@libs/common/v2/utils';

import { IUploadFileField, IUploadFileResponse, useUploadFileMutation } from '@libs/file';

export interface UploadFileFieldProps {
  name: FieldPath<FieldValues>;
  label?: string;
  placeholder?: string;
  formContext?: Context<FormV2ContextState>;
  uploadFileEndpoint?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isMultiple?: boolean;
  importReportTypes?: boolean;
  reportTypesFiles?: any[];
}

function UploadFileField({
  name,
  label,
  placeholder,
  formContext = FormV2Context,
  uploadFileEndpoint,
  isDisabled,
  isRequired,
  isMultiple,
  importReportTypes,
  reportTypesFiles,
  ...forwardProps
}: UploadFileFieldProps) {
  const styles = useStyles({ isMultiple });
  const [t] = useTranslation();
  const { control, clearErrors, setError, setValue } = useContext(formContext);
  const { showSnackbar } = useSnackbar();
  const [isFile, setIsFile] = useState(false);
  const [validationHelpText, setValidationHelpText] = useState<string>(null);

  const { mutate: uploadFile, isLoading } = useUploadFileMutation({
    onSuccess: ({ data, file }: IUploadFileResponse) => {
      showSnackbar('success', t('attachments:message.uploadFinished'));
      setValue(name, {
        fileId: data.id,
        type: file.type,
        name: file.name
      });
    },
    onError: () => {
      setValue(name, null);
      showSnackbar('error', t('attachments:message.uploadFailed'));
    }
  });

  const reportTypesFilesInJSON = useMemo(() => JSON.stringify(reportTypesFiles), [reportTypesFiles]);
  const inputId = useMemo(() => uuidv4(), []);
  const importedFiles = useMemo(
    () => reportTypesFiles?.filter(file => !file.error),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportTypesFilesInJSON]
  );
  const notImportedFiles = useMemo(
    () => reportTypesFiles?.filter(file => !!file.error),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportTypesFilesInJSON]
  );

  const handleFilesChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(event.target.files);
      const validFiles = files.filter(file => SUPPORTED_FILE_TYPES.includes(file?.type));
      const values = [];

      files.forEach(file => {
        if (file && SUPPORTED_FILE_TYPES.includes(file?.type)) {
          clearErrors(name);
          showSnackbar('info', t('attachments:message.uploadSpecificFileStarted', { file: file?.name }));
          uploadFile(
            { file, uploadFileEndpoint },
            {
              onSuccess: ({ data }) => {
                values.push({ fileId: data?.id, name: file.name, type: file.type });
              },
              onSettled: () => {
                setIsFile(true);
                setValue(name, values);
              }
            }
          );
        }
      });

      if (!isEqual(files, validFiles)) {
        const missingFiles = files
          .filter(file => !validFiles.includes(file))
          .map(file => file.name)
          .toString()
          .replaceAll(',', ', ');
        setValidationHelpText(t('attachments:message.missingFilesWithUnsupportedFileType', { files: missingFiles }));
      } else {
        setValidationHelpText(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearErrors, setValue]
  );

  const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    let file = event.target.files[0];
    if (file && !file.type && file.name.endsWith('.xades')) {
      file = new File([file], file.name, { type: 'application/xades' });
    }
    setValue(name, file);
    const fileExtension = file?.name.split('.').pop();
    const isFileSupported =
      SUPPORTED_FILE_TYPES.includes(file?.type) || SUPPORTED_FILE_EXTENSIONS.includes(fileExtension);
    if (file && isFileSupported) {
      setValue(name, file);
      clearErrors(name);
      setIsFile(true);
      showSnackbar('info', t('attachments:message.uploadStarted'));
      uploadFile(
        { file, uploadFileEndpoint },
        {
          onError: () => {
            setValue(name, null);
            setIsFile(false);
          }
        }
      );
    }

    if (!isFileSupported) {
      setIsFile(false);
      setValue(name, null);
      setError(name, { type: 'custom', message: t('attachments:message.unsupportedFileType') });
    }

    if (!file && !isFileSupported) {
      clearErrors(name);
      setValue(name, null);
      setIsFile(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue(name, null);
    clearErrors(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTextInputValue = (value: SelectOption<string>) => {
    if (isMultiple && isArray(value)) {
      return value.length > 1 ? t('attachments:message.addedFileNumber', { number: value?.length }) : value[0]?.name;
    }
    return value?.name || '';
  };

  return (
    <ComponentErrorBoundary componentName={label || placeholder || name || 'UploadFileField'}>
      <div className="mb-16">
        <form>
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              return (
                <>
                  <input
                    id={inputId}
                    name={name}
                    type="file"
                    onChange={isMultiple ? handleFilesChange : handleFileChange}
                    disabled={isDisabled || isLoading}
                    hidden
                    multiple={isMultiple}
                    data-testid={`upload-file-input${name}`}
                  />
                  <TextInput
                    value={getTextInputValue(value) as unknown}
                    label={<label htmlFor={inputId}>{label ?? t('action.chooseFile')}</label>}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!error}
                    helperText={validationHelpText ?? error?.message}
                    fullWidth
                    disabled={isDisabled}
                    required={isRequired || false}
                    InputProps={{
                      readOnly: true,
                      classes: { root: styles.root, input: styles.input },
                      startAdornment: (
                        <InputAdornment className={styles.startAdornment} position="start">
                          <label className={styles.uploadLabel} htmlFor={inputId}>
                            {!isFile && (placeholder ?? t('action.chooseFile'))}
                          </label>
                        </InputAdornment>
                      ),
                      endAdornment: isLoading && (
                        <InputAdornment className={styles.endAdornment} position="end">
                          <CircularProgress size={24} />
                        </InputAdornment>
                      ),
                      'aria-label': label,
                      ...(isMultiple ? { multiple: true } : {})
                    }}
                    {...forwardProps}
                  />
                  {isMultiple && isArray(value) && value?.length && _.isEmpty(reportTypesFiles) && (
                    <>
                      <Typography themeVariant="textSm.medium" className={styles.addedFilesListText}>
                        {t('attachments:message.addedFilesList')}
                      </Typography>
                      <ul className={styles.addedFilesList}>
                        {value.map((file: IUploadFileField) => (
                          <li key={file.fileId}>- {file.name}</li>
                        ))}
                      </ul>
                    </>
                  )}
                  {!_.isEmpty(value) && !_.isEmpty(importedFiles) && (
                    <>
                      <Typography
                        themeVariant="textSm.medium"
                        className={clsx(styles.addedFilesListText, styles.importedOrUnimportedFileList)}
                      >
                        {t('attachments:message.importedFiles')}
                      </Typography>
                      <ul className={styles.addedFilesList}>
                        {importedFiles?.map((file: IUploadFileField) => (
                          <li key={file.name} className="flex mb-6">
                            <Icon icon="CheckInCircleIcon" />
                            <span className="ml-4">
                              <b>{file.name}</b>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  {!_.isEmpty(value) && !_.isEmpty(notImportedFiles) && (
                    <>
                      <Typography
                        themeVariant="textSm.medium"
                        className={clsx(styles.addedFilesListText, styles.importedOrUnimportedFileList)}
                      >
                        {t('attachments:message.unimportedFiles')}
                      </Typography>
                      <ul className={styles.addedFilesList}>
                        {notImportedFiles?.map((file: IUploadFileField) => (
                          <li key={file.name} className="flex mb-6">
                            <Icon icon="CrossInCircleIcon" color="error" />
                            <span className="ml-4">
                              <b>{file.name}</b>
                              <div>{t('attachments:message.error', { name: file.error })}</div>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </>
              );
            }}
          />
        </form>
      </div>
    </ComponentErrorBoundary>
  );
}

const useStyles = makeStyles<Theme, { isMultiple?: boolean }>(theme => ({
  root: {
    position: 'relative',
    minHeight: '44px'
  },
  input: {
    width: calc('100% - 29px'),
    minHeight: '44px',
    position: 'absolute',
    top: '0',
    left: '28px'
  },
  startAdornment: {
    width: '100%',
    height: '100%'
  },
  uploadLabel: {
    fontSize: '16px',
    color: theme.palette.grey[300],
    width: '100%',
    padding: '10px 35px 10px 14px'
  },
  endAdornment: {
    width: '36px',
    height: '36px'
  },
  addedFilesListText: {
    marginTop: important('18px'),
    marginBottom: 8
  },
  addedFilesList: {
    padding: 0,
    margin: 0,
    listStyle: 'none'
  },
  importedOrUnimportedFileList: {
    marginBottom: important('8px')
  }
}));

export default UploadFileField;
