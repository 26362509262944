import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ArchivePackageGenerationRequest } from '@ibtm/domain';
import * as Yup from 'yup';

import { i18n } from '@libs/common';
import {
  DatepickerField,
  Dialog,
  DictionarySelectField,
  FormV2Context,
  GridLayout,
  typedNameV2
} from '@libs/common/v2';

import { useGenerateArchivePackageMutation } from '@libs/domain/archive/folder/api';

import { DomainDictionaryEnum } from '../../../../config';

interface IProps {
  closeDialog: () => void;
}

const CreateArchivePackageDialog = ({ closeDialog }: IProps) => {
  const [t] = useTranslation();
  const { mutate: generateArchivePackage, isLoading } = useGenerateArchivePackageMutation();
  const { showSuccessSnackbar } = useSnackbar();
  const validationSchema = Yup.object({
    jrwa: Yup.string().required().nullable(),
    createdFrom: Yup.date().required().typeError('validation:default'),
    createdTo: Yup.date()
      .required()
      .when('createdFrom', (createdFrom, schema) => {
        return schema.test({
          test: createdTo => !!createdFrom && createdTo > createdFrom,
          message: i18n.t('validation:minDate', { fieldName: t('archive:folder.field.createdFrom') })
        });
      })
      .typeError('validation:default')
  });

  const form = useForm<ArchivePackageGenerationRequest>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur'
  });

  const handleConfirm = useCallback(
    values => {
      generateArchivePackage(
        {
          jrwa: values.jrwa,
          createdFrom: values.createdFrom,
          createdTo: values.createdTo
        },
        {
          onSuccess: () => {
            showSuccessSnackbar(t('archive:application.message.archivePackageCreationProcessStarted'));
            closeDialog();
          },
          onError: () => {
            closeDialog();
          }
        }
      );
    },
    [closeDialog, generateArchivePackage, showSuccessSnackbar, t]
  );

  const formDataProvider = useMemo(() => {
    return { ...form };
  }, [form]);

  return (
    <Dialog
      title={t('archive:generateArchivePackageTitle')}
      confirmText={t('action.generate')}
      cancelText={t('action.cancel')}
      onConfirm={form.handleSubmit(handleConfirm)}
      onCancel={() => closeDialog()}
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={formDataProvider as Record<string, any>}>
        <form onSubmit={form.handleSubmit(handleConfirm)}>
          <GridLayout itemProps={{ xs: 12 }}>
            <DictionarySelectField
              stringValue
              name={typedNameV2<ArchivePackageGenerationRequest>('jrwa')}
              label={t('archive:folder.field.jrwa')}
              dictionaryName={DomainDictionaryEnum.JRWA_CLASSIFICATION}
              isRequired
            />
            <DatepickerField
              name={typedNameV2<ArchivePackageGenerationRequest>('createdFrom')}
              label={t('archive:folder.field.createdFrom')}
              isRequired
            />
            <DatepickerField
              name={typedNameV2<ArchivePackageGenerationRequest>('createdTo')}
              label={t('archive:folder.field.createdTo')}
              isRequired
            />
          </GridLayout>
        </form>
      </FormV2Context.Provider>
    </Dialog>
  );
};

export default CreateArchivePackageDialog;
