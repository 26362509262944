import { useTranslation } from 'react-i18next';
import { Table } from '@enigma/fe-ui';
import { HeaderActions } from '@enigma/fe-ui/lib/display/table';
import { ForeignPermissionsPrintsSnapshot } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig, PrintsQueryKeysEnum } from '../../api';
import { PrintActionType, usePrintsActionsContext, usePrintsDetailsDataContext } from '../../context';
import usePrintForeignPermissionEkmtColumn, {
  FOREIGN_PERMISSION_DEFAULT_COLUMNS
} from '../../hooks/usePrintForeignPermissionEkmtColumn';
import { PrintForeignPermissionColumnsEnum } from '../../model/print-model';

interface IProps {
  applicationId: string;
  headerActions?: HeaderActions<ForeignPermissionsPrintsSnapshot>;
  visibleColumns?: Array<PrintForeignPermissionColumnsEnum>;
}

export function PrintForeignPermissionEkmtTable({
  applicationId,
  headerActions,
  visibleColumns = FOREIGN_PERMISSION_DEFAULT_COLUMNS
}: IProps) {
  const [t] = useTranslation();

  const { columns } = usePrintForeignPermissionEkmtColumn({ visibleColumns });

  const { setAction } = usePrintsActionsContext();
  const {
    printDetailsData: {
      depotMP: { id: depotMPId },
      notDepotMPTooltip
    }
  } = usePrintsDetailsDataContext();
  const isDisabled = !depotMPId;

  return (
    <Table
      tableTitle={t('prints:printsDetailsBlocks.ekmtCarnets')}
      columns={columns}
      query={{
        request: async () => {
          const { data } = await API.foreignPermissions.getEkmtCarnetPrintsSnapshotList(applicationId, {
            ...endpointsConfig.getForeignPermissionPrintsSnapshotList
          });

          const dataWithIds = data?.content?.map((item, index) => ({ id: index.toString(), ...item }));

          const response = {
            data: {
              ...data,
              content: dataWithIds
            }
          };

          return response;
        },
        queryKey: PrintsQueryKeysEnum.PRINTS_EKMT_CARNETS_LIST
      }}
      headerActions={headerActions}
      rowActions={[
        {
          label: t('prints:actions.regenerate'),
          icon: 'CalculatorIcon',
          onClick: ({ rowData, refetch }) =>
            setAction(PrintActionType.RegenerateForeignPermissionNumber, {
              foreignPermissionsPrintsSnapshot: rowData,
              onSuccess: refetch
            }),
          tooltip: isDisabled ? notDepotMPTooltip : t('prints:actions.regenerate'),
          isDisabled
        }
      ]}
      isFilterable={false}
    />
  );
}
