import { useTranslation } from 'react-i18next';

import { Button, ButtonsGroup, useRouter } from '@libs/common/v2';
import { BackButton } from '@libs/common/v2/components/button';

import { DomainUIElementEnum } from '@libs/domain/config';

import { PrintActionType, usePrintsActionsContext } from '../../../context';
import { useSPOApplicationForeignPermissionsFlags } from '../../../hooks/useSPOApplicationForeignPermissionsFlags';

function PrintDetailsHeaderActions() {
  const [t] = useTranslation();

  const { goBack } = useRouter();
  const { setAction } = usePrintsActionsContext();

  const { isSendToForeignPermissionDepartmentAvailable } = useSPOApplicationForeignPermissionsFlags();

  return (
    <ButtonsGroup>
      <BackButton onClick={goBack} isNoMargin />
      <Button
        type="button"
        isPrimary
        variant="outlined"
        actionKey={DomainUIElementEnum.PRINT_RETURN_APPLICATION_BUTTON}
        label={t('prints:actions.returnApplication')}
        onClick={() => setAction(PrintActionType.ReturnApplication)}
        isNoMargin
      />
      {isSendToForeignPermissionDepartmentAvailable && (
        <Button
          type="button"
          isPrimary
          variant="outlined"
          label={t('prints:actions.sendToForeignPermissionDepartment')}
          onClick={() => setAction(PrintActionType.SendToForeignPermissionDepartment)}
          isNoMargin
        />
      )}
      <Button
        type="button"
        isPrimary
        variant="outlined"
        actionKey={DomainUIElementEnum.PRINT_SEND_FOR_RELEASE_BUTTON_BUTTON}
        label={t('prints:actions.sendForRelease')}
        onClick={() => setAction(PrintActionType.SendForRelease)}
        isNoMargin
      />
    </ButtonsGroup>
  );
}

export default PrintDetailsHeaderActions;
