import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderLimitDetails, LimitType } from '@ibtm/domain';
import { isNil } from 'lodash';

import { partialTranslate } from '@libs/common';
import { TableButton, TableButtonEdit, TableButtonMore, TableContext } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import { useEditOrTransferLimitModal, useSuspendOrPostponeLimitModal } from '@libs/domain/folder';
import useUpdateLimitsDialog from '@libs/domain/folder/hooks/useUpdateLimitsDialog';
import { ForeignPermissionQueryEnum } from '@libs/domain/foreign-permission';

const translate = partialTranslate('folder:details.tab.singleAuthorization.section.basicLimit');

function BasicLimitTableRowActions({ data }: { data: FolderLimitDetails }) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { isOperatorPortal } = useDomainConfigContext();
  const { refetch } = useContext(TableContext);
  const { showEditOrTransferLimitModal } = useEditOrTransferLimitModal();
  const { suspendOrPostponeLimit } = useSuspendOrPostponeLimitModal();
  const { showUpdateLimitsDialog } = useUpdateLimitsDialog();

  const isEditDisabled = data?.resourceType?.limitType !== LimitType.BASIC || isNil(data?.resourceType?.limitType);
  return (
    isOperatorPortal && (
      <>
        <TableButtonMore>
          {closeDialog => (
            <>
              <TableButton
                label={t('folder:details.tab.singleAuthorization.updateLimits.action')}
                isDisabled={isEditDisabled}
                tooltipTitle={data?.resourceType?.limitType ? null : translate('tooltip.updateNotaviable')}
                onClick={() => {
                  closeDialog?.();
                  showUpdateLimitsDialog({
                    folderLimit: data,
                    refetch: () => {
                      queryCache.invalidateQueries(ForeignPermissionQueryEnum.EKMT_LIST);
                      queryCache.invalidateQueries(ForeignPermissionQueryEnum.LIMIT_TRANSFER);
                    }
                  });
                }}
              />
              <TableButton
                label={translate('button.suspendOrPostponeLimit')}
                actionKey={DomainUIElementEnum.FOLDER_BASIC_LIMIT_SUSPEND_OR_POSTPONE_LIMIT}
                onClick={() => {
                  closeDialog?.();
                  suspendOrPostponeLimit({ data, refetch });
                }}
              />
            </>
          )}
        </TableButtonMore>
        <TableButtonEdit
          tooltipTitle={translate('button.editOrTransferLimit')}
          actionKey={DomainUIElementEnum.FOLDER_BASIC_LIMIT_EDIT_OR_TRANSFER_BUTTON}
          onClick={() => showEditOrTransferLimitModal({ ...data, refetch, data, limit: data?.basicLimit })}
          isDisabled={isEditDisabled}
        />
      </>
    )
  );
}

export default BasicLimitTableRowActions;
