import { AlertConfigurationStatusUpdateRequest } from '@stack/report';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '@libs/alert/api';

interface IProps {
  alertId: string;
  statusKey: AlertConfigurationStatusUpdateRequest;
}

const updateAlertConfigurationStatus = async ({ alertId, statusKey }: IProps) => {
  const { data } = await API.alert.updateAlertConfigurationStatus(alertId, statusKey, {
    ...endpointsConfig.updateAlertConfigurationStatus
  });

  return data;
};

export default function useUpdateAlertConfigurationStatus() {
  return useMutation(updateAlertConfigurationStatus);
}
