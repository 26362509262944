import { QueryConditionFilter } from '@stack/report/dist/models';

export { ConditionOperator } from './condition-operator.model';

export * from './ReportDictionaryEntryNameEnum';

export * from './ParameterExtrasTooltipEnum';

export const SingleConditionEmptyValue: QueryConditionFilter = {
  column: '',
  operator: '',
  parameter: { dynamic: false, displayName: '', value: '' }
};
