import { useDialog } from '@libs/common/v2';

import { FolderTransferForm } from '../../components/form';
import { IFoldersToTransferClient } from '../../models';

const useFolderTransferDialog = () => {
  const { openDialog } = useDialog();

  const open = (
    initialData: IFoldersToTransferClient | string[],
    isAssignToTransferForm?: boolean,
    onClose?: () => void
  ) => {
    openDialog(({ closeDialog }) => {
      return (
        <FolderTransferForm
          initialData={initialData}
          closeDialog={() => {
            onClose?.();
            closeDialog();
          }}
          isAssignToTransferForm={isAssignToTransferForm}
        />
      );
    });
  };

  return {
    openFolderTransferDialog: open
  };
};

export default useFolderTransferDialog;
