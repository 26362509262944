import { PluginHook } from 'react-table';
import { SCertificatesSearchFilter as SCertificatesSearchFilterClient } from '@ibtm/client-domain';
import { SCertificatesSearchFilter } from '@ibtm/domain';

import { CamelCasePath, useTableLayoutProps } from '@libs/common/v2';

import { useSCertificatesQuery } from '../api';
import { SCertificatesSnapshot } from '../model';

import { useSCertificatesColumns } from '.';

export default function useSCertificatesTable(
  queryParams?: SCertificatesSearchFilter | SCertificatesSearchFilterClient,
  hasInitialTableOptions?: boolean,
  visibleColumns?: Array<CamelCasePath<SCertificatesSnapshot>>,
  isTableMutable?: boolean,
  tablePlugins?: Array<PluginHook<SCertificatesSnapshot>>
) {
  const { columns, mappedFilterFields, mappedSortFields } = useSCertificatesColumns(visibleColumns, isTableMutable);

  return useTableLayoutProps({
    tableHookQuery: useSCertificatesQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tablePlugins,
      ...(hasInitialTableOptions && {
        tableOptions: {
          initialState: {
            sortBy: [{ id: 'vehicleVin', desc: true }]
          }
        }
      })
    },
    tableHookQueryInitialParams: queryParams as Record<string, unknown>
  });
}
