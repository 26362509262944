import { DocumentsApiCancelDocumentRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function cancelDocument(requestData: DocumentsApiCancelDocumentRequest) {
  return API.documents.cancelDocument(requestData.documentId, requestData.versionedRequest, {
    ...endpointsConfig.cancelDocument
  });
}

function useCancelDocumentMutation() {
  return useMutation(cancelDocument);
}

export default useCancelDocumentMutation;
