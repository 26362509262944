import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PermanentPermissionSubmissionDetails } from '@ibtm/domain';
import { FolderExtendedLite } from '@ibtm/domain/dist/models/folder-extended-lite';
import moment from 'moment';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  FormMode,
  GridLayout,
  InputMatrixField,
  InputMode,
  MatrixCellInputTypeEnum,
  Scrollbar,
  Section,
  SelectOption,
  SwitchField,
  TextInputField,
  typedNameV2,
  useConfirmDialog,
  useFormV2Context
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';
import { IRowInput, ValueMatrix } from '@libs/common/v2/form/components/InputMatrix';

import { FolderSelectField } from '@libs/domain/application/components';
import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';
import { EKTMFormConfig } from '@libs/domain/social-commission';

import { PermanentPermissionSubmissionDetailsForm } from './SocialCommissionTableFormPage';

function SocialCommissionEditForm({ formMode }: { formMode: FormMode }) {
  const [t] = useTranslation();
  const { getValues, setValue, watch } = useFormV2Context();
  const [confirm] = useConfirmDialog();
  const { editMode, createMode, viewMode } = useViewModesV2(formMode);

  const inputMode = useMemo(() => (editMode || createMode ? InputMode.FORM : InputMode.VIEW), [createMode, editMode]);

  const data = getValues() as Partial<PermanentPermissionSubmissionDetailsForm>;

  const rows =
    data?.items?.map((item, index) => ({
      id: item.resourceFormNameKey,
      title: (index + 1).toString()
    })) ?? [];

  const getLabel = partialTranslate('foreignPermits:tabs.socialCommission.tabs.socialCommissionList.fields');

  const createFieldName = (row: IRowInput, fieldName: string) => `items.${+row.rowTitle.title - 1}.${fieldName}`;

  const firstAppeal = watch(typedNameV2<PermanentPermissionSubmissionDetails>('firstAppeal')) as boolean;
  const secondAppeal = watch(typedNameV2<PermanentPermissionSubmissionDetails>('secondAppeal')) as boolean;

  const confirmSecondAppealMark = () => {
    if (secondAppeal) {
      setValue(typedNameV2<PermanentPermissionSubmissionDetails>('secondAppeal'), false);
      return;
    }
    if (!firstAppeal && !secondAppeal) {
      confirm({
        title: getLabel('secondCancel'),
        message: t('foreignPermits:tabs.socialCommission.tabs.socialCommissionList.dialog.description'),
        confirmText: t('action.yes'),
        cancelText: t('action.no'),
        onConfirm: (_, closeDialog) => {
          setValue(typedNameV2<PermanentPermissionSubmissionDetails>('secondAppeal'), true);
          closeDialog();
        },
        onCancel: () => {
          setValue(typedNameV2<PermanentPermissionSubmissionDetails>('secondAppeal'), false);
        }
      });
    } else {
      setValue(typedNameV2<PermanentPermissionSubmissionDetails>('secondAppeal'), true);
    }
  };

  const getSecondCancelKey = () => {
    if (firstAppeal) {
      return 'secondAppeal';
    }
    return !secondAppeal ? 'secondAppeal' : 'secondAppeal2';
  };

  const handleSetFolder = (folder: SelectOption<FolderExtendedLite>) => {
    setValue('folder', folder.value);
    setValue('subject', { name: folder.value?.subject?.name });
  };

  return (
    <>
      <Section title={t('foreignPermits:other.application')}>
        <GridLayout itemProps={{ md: 6, xs: 12 }}>
          <DatepickerField
            name={typedNameV2<PermanentPermissionSubmissionDetails>('year')}
            views={['year']}
            minDate={moment().subtract(1, 'year')}
            maxDate={moment().add(1, 'year')}
            label={getLabel('year')}
            isRequired
          />
          {createMode ? <div /> : <TextInputField name="number" label={getLabel('number')} isDisabled />}
          {createMode ? (
            <FolderSelectField
              fieldName="folder.number"
              label={getLabel('folderNumber')}
              customSetValue={handleSetFolder}
            />
          ) : (
            <TextInputField
              name={typedNameV2<PermanentPermissionSubmissionDetails>('folder.number')}
              inputMode={InputMode.VIEW}
              label={getLabel('folderNumber')}
            />
          )}
          <TextInputField
            name={typedNameV2<PermanentPermissionSubmissionDetails>('subject.name')}
            inputMode={InputMode.VIEW}
            label={getLabel('subjectName')}
            isDisabled
          />
          <SwitchField
            label={getLabel('firstCancel')}
            name={typedNameV2<PermanentPermissionSubmissionDetails>('firstAppeal')}
            inputMode={inputMode}
          />
          <SwitchField
            label={getLabel('secondCancel')}
            name={typedNameV2<PermanentPermissionSubmissionDetails>('secondAppeal')}
            onCustomChange={confirmSecondAppealMark}
            key={getSecondCancelKey()}
          />
        </GridLayout>
      </Section>

      <Section title={t('foreignPermits:other.applicationEKMT')} isPadded={false}>
        <Scrollbar options={{ suppressScrollY: true }}>
          <InputMatrixField
            rowTitlesColumnWidth={80}
            headers={EKTMFormConfig.map(item => ({
              name: { title: t(item.label as any) },
              width: item.width,
              isRequired: !viewMode && item.required
            }))}
            rowTitles={rows}
            isWhiteBackground
            rowInputs={row => {
              const rowTitle = row?.rowTitle?.title;
              const rowResourceForm = rows?.find(item => item?.title === rowTitle)?.id;
              const rowResourceFormName = rowResourceForm?.value || rowResourceForm;

              const includesEuro6 = rowResourceFormName?.includes(
                DomainDictionaryEntry.RESOURCE_FORM_NAME.PARTIAL_EURO_CLASS_6
              );
              const includesEuro5 = rowResourceFormName?.includes(
                DomainDictionaryEntry.RESOURCE_FORM_NAME.PARTIAL_EURO_CLASS_5
              );

              return [
                {
                  type: MatrixCellInputTypeEnum.CUSTOM,
                  fieldName: createFieldName(row, 'resourceFormNameKey'),
                  input: (
                    <ValueMatrix
                      dictionaryName={DomainDictionaryEnum.RESOURCE_FORM_NAME}
                      name={createFieldName(row, 'resourceFormNameKey')}
                    />
                  )
                },
                {
                  type: MatrixCellInputTypeEnum.NUMBER,
                  fieldName: createFieldName(row, 'euro5submitted'),
                  isDisabled: includesEuro6
                },
                {
                  type: MatrixCellInputTypeEnum.NUMBER,
                  fieldName: createFieldName(row, 'euro5decision'),
                  isDisabled: true
                },
                {
                  type: MatrixCellInputTypeEnum.NUMBER,
                  fieldName: createFieldName(row, 'euro6submitted'),
                  isDisabled: includesEuro5
                },
                {
                  type: MatrixCellInputTypeEnum.NUMBER,
                  fieldName: createFieldName(row, 'euro6decision'),
                  isDisabled: true
                }
              ];
            }}
          />
        </Scrollbar>
      </Section>
    </>
  );
}

export default SocialCommissionEditForm;
