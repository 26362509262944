import axios, { AxiosRequestConfig } from 'axios';

const API = {
  downloadFile: (params: Record<string, any>, options: AxiosRequestConfig) =>
    axios.get<Blob>(`/${params.isClientPortal ? 'api-client' : 'api'}/files/${params.fileId}`, options),
  client: {
    downloadFile: (params: Record<string, any>, options: AxiosRequestConfig) =>
      axios.get<Blob>(`/api-client/files/${params.fileId}`, options)
  }
};

export default API;
