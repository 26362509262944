import { FolderAssignToTransferRequest } from '@ibtm/domain/dist/models';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function assignToTransferFolder(requestData: FolderAssignToTransferRequest) {
  return API.folder.assignToTransferFolders(requestData, {
    ...endpointsConfig.assignToTransferFolder
  });
}

function useFolderAssignToTransferMutations() {
  return useMutation(assignToTransferFolder);
}

export default useFolderAssignToTransferMutations;
