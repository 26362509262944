import { useTranslation } from 'react-i18next';

import { DropdownListButton, useDialog } from '@libs/common/v2';

import { DomainDictionaryEntry, DomainUIElementEnum } from '@libs/domain/config';

import CopyDataFromApplicationDialog from './CopyDataFromApplicationDialog';

function CopyDataFromApplicationButton({ applicationData }) {
  const { openDialog } = useDialog();
  const [t] = useTranslation();

  const openCopyDataFromApplicationDialog = () =>
    openDialog(({ closeDialog }) => (
      <CopyDataFromApplicationDialog onCloseDialog={closeDialog} applicationId={applicationData?.id} />
    ));

  const conditions = [
    'spoUe',
    'spoOut',
    'SPO_OUTSIDE',
    'spoWzw',
    'spoWzo',
    'SPO_WZO',
    'spoTransit',
    'spoOwnNeedsPwr1',
    'spoOwnNeedsPwr2',
    'spoOwnNeedsPwr3',
    'spoOwnNeedsIpwz',
    'spoOwnNeedsIpwzInfo',
    'spoOwnNeedsPwo1',
    'spoOwnNeedsPwo2',
    'spoOwnNeedsPwo2Reprint',
    'spoOwnNeedsPwr3Reprint',
    'spoPositionMind'
  ];

  const copyDataApplications = Object.fromEntries(
    Object.entries(DomainDictionaryEntry.APPLICATION_TYPE).filter(([key]) => conditions.some(el => key.includes(el)))
  );

  const isButtonVisible = Object.values(copyDataApplications).includes(
    applicationData?.typeKey as (typeof DomainDictionaryEntry.APPLICATION_TYPE)[keyof typeof DomainDictionaryEntry.APPLICATION_TYPE]
  );

  if (!isButtonVisible) return null;

  return (
    <DropdownListButton
      onClick={openCopyDataFromApplicationDialog}
      label={t('applications:dialog.copyData.title')}
      actionKey={DomainUIElementEnum.APPLICATION_INPUT_DATA_COPY}
    />
  );
}

export default CopyDataFromApplicationButton;
