import { ReactNode, useMemo } from 'react';

import { useElementVisibility } from '@libs/permission/hooks';
import { UIElementNameEnum } from '@libs/permission/models';

interface Props {
  actionKeys: UIElementNameEnum[];
  children: ReactNode;
}

function HasPermission({ actionKeys, children }: Props) {
  const { checkIsElementVisible } = useElementVisibility();

  const isAnyElementVisible = useMemo(() => {
    const filteredActionKeys = actionKeys.filter(i => !!i);
    return !filteredActionKeys.length || filteredActionKeys.some(checkIsElementVisible);
  }, [actionKeys, checkIsElementVisible]);

  return isAnyElementVisible ? <>{children}</> : null;
}

export default HasPermission;
