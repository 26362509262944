import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { ReportDetailsLite } from '@stack/report/dist/models';

import { TableIconButton } from '@libs/common/v2';

import { useFileDownload } from '@libs/file';
import { API } from '@libs/report/api';
import { useReportFiles } from '@libs/report/hooks';

import { ReportUIElementEnum } from '../../config/permission';

export function GeneratedReportsTableRowActions({ report }: { report: ReportDetailsLite }) {
  const [t] = useTranslation();
  const { download, isLoading: isDownloading } = useFileDownload();
  const reportFiles = useReportFiles(report);
  const isDownloadEnabled = Boolean(Object.values(reportFiles.files)?.length);

  const classes = useStyles();

  const handleDownloadFiles = () => {
    Object.values(reportFiles.files).forEach(fileId =>
      download(() => API.file.download(fileId, '', undefined, { responseType: 'blob' }))
    );
  };

  return isDownloadEnabled ? (
    <TableIconButton
      tooltipTitle={t('action.download')}
      icon="DownloadIcon"
      onClick={() => handleDownloadFiles()}
      isDisabled={!isDownloadEnabled || isDownloading}
      isButtonLoading={isDownloading}
      isBackgroundTransparent
      className={classes.icon}
      actionKey={ReportUIElementEnum.REPORT_DOWNLOAD_BUTTON}
    />
  ) : null;
}

const useStyles = makeStyles({
  icon: {
    width: '36px'
  }
});
