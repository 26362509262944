import { DefaultApi as DocumentGeneratorApi } from '@avispon/document-generator';
import { DefaultApi as GroupDefaultApi } from '@avispon/group';
import { DefaultApi as OrganizationStructureDefaultApi } from '@avispon/organization-structure';
import { AccountsApi as UserAccountApi, DefaultApi as UserDefaultApi } from '@avispon/user';
import { FilesApiFactory } from '@stack/file';
import {
  DefinitionsApiFactory,
  MediatorDataSourcesApiFactory,
  ReportApiFactory,
  ReportPreviewApiFactory,
  ReportTypeApiFactory as ReportTypesApiFactory
} from '@stack/report';
import axios from 'axios';

interface ReportApi {
  report: ReturnType<typeof ReportApiFactory>;
  reportTypes: ReturnType<typeof ReportTypesApiFactory>;
  definitions: ReturnType<typeof DefinitionsApiFactory>;
  reportPreview: ReturnType<typeof ReportPreviewApiFactory>;
  mediatorDataSources: ReturnType<typeof MediatorDataSourcesApiFactory>;
  documentGenerator: DocumentGeneratorApi;
  file: ReturnType<typeof FilesApiFactory>;
  organizationStructure: OrganizationStructureDefaultApi;
  group: GroupDefaultApi;
  user: {
    default: UserDefaultApi;
    accountController: UserAccountApi;
  };
}

const reportApiFactoryFallback: any = () => {
  throw new Error('Report api has not been set');
};

const API: ReportApi = {
  report: reportApiFactoryFallback,
  reportPreview: reportApiFactoryFallback,
  reportTypes: reportApiFactoryFallback,
  mediatorDataSources: reportApiFactoryFallback,
  definitions: reportApiFactoryFallback,
  documentGenerator: reportApiFactoryFallback,
  file: reportApiFactoryFallback,
  organizationStructure: reportApiFactoryFallback,
  group: reportApiFactoryFallback,
  user: reportApiFactoryFallback
};

export function setReportApi(API_PORTAL_URL: string) {
  API.report = ReportApiFactory({ isJsonMime: () => true }, API_PORTAL_URL, axios as never);
  API.reportPreview = ReportPreviewApiFactory({ isJsonMime: () => true }, API_PORTAL_URL, axios as never);
  API.reportTypes = ReportTypesApiFactory({ isJsonMime: () => true }, API_PORTAL_URL, axios as never);
  API.mediatorDataSources = MediatorDataSourcesApiFactory({ isJsonMime: () => true }, API_PORTAL_URL, axios as never);
  API.definitions = DefinitionsApiFactory({ isJsonMime: () => true }, API_PORTAL_URL, axios as never);
  API.documentGenerator = new DocumentGeneratorApi(null, API_PORTAL_URL, axios);
  API.file = FilesApiFactory({ isJsonMime: () => true }, API_PORTAL_URL, axios);
  API.organizationStructure = new OrganizationStructureDefaultApi({ isJsonMime: () => true }, API_PORTAL_URL, axios);
  API.group = new GroupDefaultApi({ isJsonMime: () => true }, API_PORTAL_URL, axios as never);
  API.user = {
    default: new UserDefaultApi({ isJsonMime: () => true }, API_PORTAL_URL, axios),
    accountController: new UserAccountApi({ isJsonMime: () => true }, API_PORTAL_URL, axios)
  };
}

export { API };
