import { useEffect, useState } from 'react';

import { IRoute } from '@libs/common/v2/router';
import { hasPermission } from '@libs/common/v2/utils';

import { useAuthSelector } from '@libs/auth/store/reducers';

import { isPermissionCheckEnabled } from './permission.utils';

function useHasRouteAccess(path: string, route: IRoute) {
  const [hasAccess, setHasAccess] = useState(true);
  const [lastPath, setLastPath] = useState<string>(null);
  const userPermissions = useAuthSelector(({ auth }) => auth.user.permissions);

  useEffect(() => {
    const routePermissions = route?.auth?.(route?.params) || null;

    if (Boolean(routePermissions) && Boolean(userPermissions && isPermissionCheckEnabled())) {
      const isAccessGranted = hasPermission(routePermissions, userPermissions);
      setHasAccess(isAccessGranted);
      if (isAccessGranted) {
        setLastPath(path);
      }
    } else {
      setLastPath(path);
      setHasAccess(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  return [hasAccess, lastPath];
}

export default useHasRouteAccess;
