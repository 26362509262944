import _ from 'lodash';
/** This function remove all keys with empty string, empty array, empty object, null or undefined value in object
 * Example:
 *
 * const obj = { name: '', surname: null, age: 20, id: '123abc' };
 *
 * const newObj = removeKeyForEmptyValue(obj);
 *
 * newObj = { age: 20, id: '123abc' }
 */

export const removeKeyForEmptyValue = (obj: any) => {
  const isNotEmptyArray = v => {
    if (Array.isArray(v)) {
      return !!v.length;
    }
    return true;
  };
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([, v]) => !_.isNil(v) && v !== '' && isNotEmptyArray(v) && (!_.isArray(v) || Object.keys(v).length !== 0)
    )
  ) as any;
};
