import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { StarBorder } from '@mui/icons-material';
import { Tooltip, useTheme } from '@mui/material';

function ReleaseDocumentIcon() {
  const [t] = useTranslation();
  const { palette } = useTheme();
  return (
    <div className="flex flex-col mr-2">
      <Tooltip title={t('releaseDocuments:tooltip.originalDocumentIcon')}>
        <StarBorder fontSize="small" htmlColor={palette.info.main} />
      </Tooltip>
    </div>
  );
}

export default memo(ReleaseDocumentIcon);
