import { Column, OnFilterChange } from 'react-table';

import { FetchSelectFilter, NUMBER_OF_AUTOCOMPLETE_RESULTS } from '@libs/common/v2';

import { API, UserQueryKeysEnum } from '@libs/user/api';

interface IProps {
  column: Column & { filterValue?: string; setFilter: (value: string | Array<string>) => void };
  isMultiple?: boolean;
  onFilterChange?: OnFilterChange;
}

function OrganizationUnitFilter({ onFilterChange, ...props }: IProps) {
  const fetchFunction = (searchText: string) => {
    return API.organizationStructure.findOrganizationUnit({
      page: 0,
      size: NUMBER_OF_AUTOCOMPLETE_RESULTS,
      nameFragment: searchText
    });
  };

  return (
    <FetchSelectFilter
      queryKey={UserQueryKeysEnum.ORGANIZATION_UNITS_FILTER}
      fetchFunctionResolver={fetchFunction}
      fetchedDataSelectParser={({ data: { content } }) =>
        content.map(({ id, name }) => ({
          id,
          name: `${name}`,
          value: id
        }))
      }
      onFilterChange={onFilterChange}
      {...props}
    />
  );
}

export default OrganizationUnitFilter;
