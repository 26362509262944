import { PaginatedQueryConfig, usePaginatedQuery } from 'react-query';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';
import { ResourceQueryKeysEnum } from '..';

export const getResourceType = async (resourceTypeId: string) => {
  const { data } = await API.resourceTypes.getResourceType(resourceTypeId, {
    ...endpointsConfig.getResourceType
  });

  return data;
};

export function useResourceTypeDetailsQuery(
  resourceTypeId: string,
  queryConfig?: PaginatedQueryConfig<Awaited<ReturnType<typeof getResourceType>>, unknown>
) {
  return usePaginatedQuery(
    [ResourceQueryKeysEnum.RESOURCE_TYPE, resourceTypeId],
    () => getResourceType(resourceTypeId),
    queryConfig
  );
}
