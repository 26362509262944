import { Context, useContext, useMemo } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { FormHelperText, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { FormV2Context, FormV2ContextState, LoaderCircular, RadioField, Theme } from '@libs/common/v2';
import { FieldTypeEnum, TextFieldValidation, useFieldValidationHandler } from '@libs/common/v2/form/validation';
import { important } from '@libs/common/v2/utils';

import { SelectOption } from '../input';
import { Label } from '../label';

interface IProps {
  name: string;
  options: SelectOption[];
  label: string;
  isRequired?: boolean;
  formContext?: Context<any>;
  isLoading?: boolean;
  isDisabled?: boolean;
}

export function RadioGroup({
  formContext = FormV2Context,
  name,
  label,
  options,
  isRequired,
  isLoading,
  isDisabled
}: IProps) {
  const classes = useStyles();
  const { control } = useContext<FormV2ContextState>(formContext);

  const validation: TextFieldValidation = useMemo(
    () => ({
      ...(isRequired
        ? {
            required: []
          }
        : {})
    }),
    [isRequired]
  );

  useFieldValidationHandler({
    fieldName: name,
    validation,
    fieldType: FieldTypeEnum.TEXT
  });
  const isOptionActive = (option: SelectOption) => {
    if (option.active !== undefined) {
      return option.active;
    }
    return true;
  };
  return (
    <Controller
      control={control}
      name={name as FieldPath<FieldValues>}
      render={({ field: { value }, fieldState: { error } }) => (
        <>
          <input type="text" name={name} value={value} className="hidden" required={isRequired} />
          <fieldset className="p-0">
            <Label label={label} isRequired={isRequired} />
            {isLoading ? (
              <LoaderCircular className={classes.loader} size={20} isLoading={isLoading} />
            ) : (
              <>
                {options.map(option => {
                  const disabled = isDisabled || !isOptionActive(option);

                  return (
                    <Tooltip title={option?.disabledTooltip} key={option.name} disableHoverListener={!disabled}>
                      <span>
                        <RadioField
                          value={option.value}
                          name={name}
                          label={option.name}
                          className={classes.checkboxWrapper}
                          checkboxClassName={clsx(classes.checkboxClassName, error && classes.errorCheckbox)}
                          wrapperClassName={classes.checkboxWrapperClassName}
                          shouldDisplayMessage={false}
                          isDisabled={disabled}
                        />
                      </span>
                    </Tooltip>
                  );
                })}
              </>
            )}
          </fieldset>
          {error && (
            <FormHelperText error className={classes.error}>
              {error.message}
            </FormHelperText>
          )}
        </>
      )}
    />
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  error: {
    color: important(theme.palette.error[800]),
    fontSize: '1.4rem',
    margin: '6px 0',
    lineHeight: '2rem'
  },
  errorCheckbox: {
    '& svg': {
      fill: theme.palette.error[400]
    }
  },
  checkboxWrapper: {
    height: 'unset',
    display: 'block'
  },
  checkboxClassName: {
    display: 'inline-flex'
  },
  checkboxWrapperClassName: {
    height: 'unset',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: '-6px'
  },
  loader: {
    position: 'relative',
    top: 10,
    left: 0
  }
}));
