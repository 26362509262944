import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceDetails } from '@ibtm/domain';

import { TableLayout, typedNameV2, useFormV2Context } from '@libs/common/v2';

import useDocumentTableItemsColumns from '../../hooks/useDocumentTableItemsColumns';
import { ICorrectionItems } from '../../model';

interface IProps {
  isDataLoading: boolean;
  headerActions?: ReactNode;
}

type FieldsType = Partial<InvoiceDetails>;

/**
 * Tabela dla widoku oryginalnego dokumentu wydania
 */
export function OriginalItemsTable({ isDataLoading, headerActions }: IProps) {
  const [t] = useTranslation();

  const { watch } = useFormV2Context();

  const items = watch(typedNameV2<FieldsType>('items')) as ICorrectionItems[];

  const correctionItemsColumns = useDocumentTableItemsColumns();
  const [correctionItemsTable, setCorrectionItemsTable] = useState<ICorrectionItems[]>([]);

  useEffect(() => {
    setCorrectionItemsTable(items);
  }, [items]);

  return (
    <TableLayout
      tableQuery={correctionItemsTable}
      pageTitle={t('releaseDocuments:create.sections.items')}
      isSection
      key="ReleaseDocumentItemsSectionTable"
      isFilterEnabled={false}
      isRefreshEnabled={false}
      headerActions={headerActions}
      tableHookOptions={{
        columns: correctionItemsColumns,
        filterConverter: {},
        sortByConverter: {}
      }}
      isLoading={isDataLoading}
    />
  );
}
