import { useTranslation } from 'react-i18next';

import { WarningInformation } from '@libs/common/v2';

import { useApplicationPricingQuery, useMoneyTransfersForApplicationQuery } from '../api';

function QuotationWarning({ applicationId }: { applicationId: string }) {
  const [t] = useTranslation();
  const { data: pricingData } = useApplicationPricingQuery(applicationId, { enabled: false });
  const { data: moneyTransferDataUnconfirmed } = useMoneyTransfersForApplicationQuery({
    applicationId,
    quorumConfirmation: false
  });
  const { data: moneyTransferDataConfirmed } = useMoneyTransfersForApplicationQuery({
    applicationId,
    quorumConfirmation: true
  });

  const moneyTransferDataUnconfirmedLength = moneyTransferDataUnconfirmed?.content?.length || 0;
  const moneyTransferDataConfirmedLength = moneyTransferDataConfirmed?.content?.length || 0;

  const paymentAmount = pricingData?.paymentAmount;
  const moneyTransferUnconfirmedAmountSum = moneyTransferDataUnconfirmed?.content?.reduce(
    (acc, item) => acc + item.currentApplicationAmountUsed,
    0
  );

  const moneyTransferConfirmedAmountSum = moneyTransferDataConfirmed?.content?.reduce(
    (acc, item) => acc + item.currentApplicationAmountUsed,
    0
  );

  const transferAmountHigher =
    moneyTransferUnconfirmedAmountSum > paymentAmount || moneyTransferConfirmedAmountSum > paymentAmount;
  const transferAmountLower =
    moneyTransferUnconfirmedAmountSum < paymentAmount || moneyTransferConfirmedAmountSum < paymentAmount;

  if (moneyTransferUnconfirmedAmountSum === paymentAmount || moneyTransferConfirmedAmountSum === paymentAmount) {
    return null;
  }
  if (
    moneyTransferDataUnconfirmedLength + moneyTransferDataConfirmedLength === 0 ||
    (!moneyTransferConfirmedAmountSum && !moneyTransferUnconfirmedAmountSum)
  ) {
    return <WarningInformation content={t('pricing:moneyTransfers.action.amountWarning.noTransferAmount')} />;
  }
  if (transferAmountHigher) {
    return <WarningInformation content={t('pricing:moneyTransfers.action.amountWarning.transferAmountHigher')} />;
  }
  if (transferAmountLower) {
    return <WarningInformation content={t('pricing:moneyTransfers.action.amountWarning.transferAmountLower')} />;
  }

  return null;
}

export default QuotationWarning;
