import { useWatch } from 'react-hook-form';
import { SuspensionSnapshot } from '@ibtm/domain';

import { typedNameV2, useFormV2Context, useTableRowActions } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { useFormOperationsActions } from '.';

export const useFormOperationActions = () => {
  const { control } = useFormV2Context();
  const { handleDelete } = useFormOperationsActions();
  const whole = useWatch({ name: typedNameV2<SuspensionSnapshot>('whole'), control }) as boolean;

  const { renderTableActions } = useTableRowActions<any>(
    [
      {
        id: 'SUSPENSIONS_FORM_OPERATIONS_DELETE_BUTTON',
        label: 'action.delete',
        onClick: ({ row: { original } }) => handleDelete(original.id),
        isHidden: ({
          row: {
            original: { statusKey }
          }
        }) => {
          return whole || statusKey === DomainDictionaryEntry.FORM_OPERATION_STATUS.SUSPENDED;
        },
        icon: 'TrashIcon'
      }
    ],
    [whole]
  );

  return renderTableActions(['SUSPENSIONS_FORM_OPERATIONS_DELETE_BUTTON']);
};
