import { usePaginatedQuery } from 'react-query';
import { ResourceTypeAvailableSearchFilter } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { parseAvailableResourceTypesList } from '../../../utils';
import { endpointsConfig } from '../../endpoints-config';
import { ResourceQueryKeysEnum } from '..';

export const getAvailableResourceTypes = async (params: ResourceTypeAvailableSearchFilter) => {
  const { data } = await API.resourceTypes.getAvailableResourceTypes(params, {
    ...endpointsConfig.getAvailableResourceTypes
  });

  return {
    ...data,
    content: parseAvailableResourceTypesList(data.content)
  };
};

export default function useAvailableResourceTypesQuery(params: ResourceTypeAvailableSearchFilter) {
  return usePaginatedQuery<any>([ResourceQueryKeysEnum.AVAILABLE_RESOURCE_TYPES_LIST, params], () =>
    getAvailableResourceTypes(params)
  );
}
