import { PullNotificationSnapshot } from '@avispon/message';

import { useCreateColumns } from '@libs/common/v2';

const useMyMessagesColumns = () => {
  const { createColumns } = useCreateColumns<PullNotificationSnapshot>({
    pathToTranslate: 'messages:table'
  });

  const columns = createColumns([
    {
      type: 'DATE_TIME',
      accessor: 'created'
    },
    {
      type: 'TEXT',
      accessor: 'subject',
      isSortable: false
    },
    {
      type: 'TEXT',
      accessor: 'message',
      isSortable: false
    },
    {
      type: 'TEXT',
      header: 'recipient',
      accessor: 'accountData.name',
      isSortable: false
    }
  ]);

  return { columns };
};

export default useMyMessagesColumns;
