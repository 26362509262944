import { usePaginatedQuery } from 'react-query';
import { ProceedingAvailableFormsDetails, ProceedingAvailableFormsFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';
import { FolderQueryKeysEnum } from '..';

interface GetProceedingAvailableFormsParams extends ProceedingAvailableFormsFilter {
  folderId: string;
}

const getAvailableFormsQuery = async (_, { folderId, ...params }: GetProceedingAvailableFormsParams) => {
  const {
    data: { content, ...paginationParams }
  } = await API.folder.getProceedingAvailableFormsPage(folderId, params, {
    ...endpointsConfig.getProceedingAvailableFormsPage
  });

  return {
    ...paginationParams,
    content: content.map((form, index) => ({
      ...form,
      id: index
    }))
  };
};

export default function useAvailableFormsQuery(params: ProceedingAvailableFormsFilter) {
  return usePaginatedQuery<IPaginatedModel<ProceedingAvailableFormsDetails>>(
    [FolderQueryKeysEnum.AVAILABLE_FORM_LIST, params],
    getAvailableFormsQuery
  );
}
