import { PluginHook } from 'react-table';
import { ProceedingAvailableFormsDetails } from '@ibtm/domain';
import _ from 'lodash';

import { useTableLayoutProps } from '@libs/common/v2';

import { useAvailableFormsQuery } from '..';

import useAvailableFormColumns from './useAvailableFormColumns';

const useAvailableFormsTable = (
  folderId: string,
  visibleColumns: any,
  permissionTypeCodes: Array<string> | string,
  tablePlugins?: PluginHook<ProceedingAvailableFormsDetails>[],
  includePartnershipPermissions?: boolean
) => {
  const { columns, mappedFilterFields, mappedSortFields } = useAvailableFormColumns(visibleColumns);

  return useTableLayoutProps({
    tableHookQuery: useAvailableFormsQuery,
    tableHookOptions: {
      tablePlugins,
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    },
    tableHookQueryInitialParams: {
      folderId: folderId ? [folderId] : undefined,
      ...(_.isEmpty(permissionTypeCodes) ? {} : { permissionTypeCodeKeyIn: permissionTypeCodes }),
      ...(includePartnershipPermissions && { includePartnershipPermissions })
    }
  });
};

export default useAvailableFormsTable;
