import { useTranslation } from 'react-i18next';

import { AutocompleteSelectField, SelectOption, typedNameV2, useFormV2Context } from '@libs/common/v2';

import { usePrintsActionsDataContext } from '@libs/domain/print/context';
import { ForeignPermissionRegenerateForm } from '@libs/domain/print/model';
import { PoolItem } from '@libs/domain/resource';

export function ForeignPermissionsNumberSelectField() {
  const [t] = useTranslation();
  const {
    actionData: { foreignPermissionsPrintsSnapshot }
  } = usePrintsActionsDataContext() ?? {};
  const { firstNumber, lastNumber, formNameKey } = foreignPermissionsPrintsSnapshot ?? {};
  const { getValues } = useFormV2Context();

  const options: Array<SelectOption<PoolItem>> = [];
  for (let formNumber = firstNumber; formNumber <= lastNumber; formNumber += 1) {
    options.push({
      name: formNumber.toString(),
      value: { name: formNameKey, amount: 1, numberFrom: formNumber, numberTo: formNumber }
    });
  }

  return (
    <AutocompleteSelectField
      name={typedNameV2<ForeignPermissionRegenerateForm>('selectedNumbers')}
      label={t('prints:field.detailsFields.formNumbersToRegenerate')}
      options={options}
      validationMultipleSelect={{ required: [] }}
      getOptionSelected={option => {
        return Boolean(
          getValues(typedNameV2<ForeignPermissionRegenerateForm>('selectedNumbers'))?.find(
            value => value.name === option.name
          )
        );
      }}
      isFilterSelectedOptions
      isMultiple
      isRequired
    />
  );
}
