import { InvoiceRecipientUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function updateCorrection(request: InvoiceRecipientUpdateRequest & { invoiceId: string }) {
  const { invoiceId, ...rest } = request;
  return API.releaseDocuments.updateInvoiceRecipient(invoiceId, rest, {
    ...endpointsConfig.updateInvoiceRecipient
  });
}

function useUpdateCorrectionMutation() {
  return useMutation(updateCorrection);
}

export default useUpdateCorrectionMutation;
