import { useMemo } from 'react';

import { useActionColumn, usePaginatedQueryTable } from '@libs/common/v2';

import { useContextSelector } from '@libs/dictionary';

import { useBorrowFolderListQuery } from '../api';

import { useBorrowFoldersColumns } from './useBorrowFoldersColumns';

const useBorrowFoldersTable = (folderId?: string) => {
  const dictionaryData = useContextSelector(({ context }) => context.context.data.dictionary);
  const { columns, mappedFilterFields, mappedSortFields } = useBorrowFoldersColumns();

  return usePaginatedQueryTable(
    useBorrowFolderListQuery,
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns: useMemo(() => columns, [dictionaryData]),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      tablePlugins: useMemo(() => [useActionColumn], []),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      filterConverter: useMemo(() => mappedFilterFields, []),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      sortByConverter: useMemo(() => mappedSortFields, []),
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'borrowDate', desc: true }]
        }
      }
    },
    {
      ...(folderId ? { folderNumberIdIn: [folderId] } : {})
    }
  );
};

export default useBorrowFoldersTable;
