import { MutationConfig } from 'react-query';
import { AddSubjectsToPartnershipRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

type AddSubjectToPartnershipRequest = {
  subjectId: string;
  data: AddSubjectsToPartnershipRequest;
};
function addSubjectTOpartnerShip({ subjectId, data }: AddSubjectToPartnershipRequest) {
  return API.subject.addSubjectsToPartnership(subjectId, data, {
    ...endpointsConfig.addSubjectsToPartnership
  });
}

export function useAddSubjectTOpartnerShipMutation(config?: MutationConfig<unknown>) {
  return useMutation(addSubjectTOpartnerShip, config);
}
