import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Icon, Theme } from '@libs/common/v2';

interface IProps {
  sortState: string;
  countValue?: number;
}
function SortingDirectionIcon({ sortState, countValue }: IProps) {
  const [t] = useTranslation();
  const classes = useStyles();
  const icon = sortState === 'desc' ? 'DescSortingIcon' : 'AscSortingIcon';
  const tooltipTitle =
    sortState === 'desc'
      ? t('reports:reportTypes.parameterExtras.tip.sortingDesc')
      : t('reports:reportTypes.parameterExtras.tip.sortingAsc');

  return (
    <div className={clsx('self-center', classes.iconWrapper)}>
      <Icon icon={icon} tooltipTitle={tooltipTitle} height={20} width={20} color="grey" colorWeight="500" />
      {!!countValue && <span className={classes.value}>{countValue}</span>}
    </div>
  );
}
const useStyles = makeStyles<Theme>(theme => ({
  iconWrapper: {
    padding: 10,
    marginRight: 15,
    position: 'relative',
    zIndex: 10,
    height: 20,
    width: 20,
    display: 'flex'
  },
  value: {
    top: -3,
    right: -4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 16,
    height: 16,
    position: 'absolute',
    zIndex: 10,
    color: theme.palette.blue[800],
    backgroundColor: theme.palette.grey[50],
    ...theme.typography.textXs.bold,
    letterSpacing: '0.5px',
    textAlign: 'center',
    borderWidth: 0.5,
    borderRadius: '50%',
    padding: '2px'
  }
}));
export default SortingDirectionIcon;
