import { useDialog } from '@libs/common/v2';

import { PermissionRestoreFromDistrictAuthorityDialog } from '../../components';

function usePermissionRestoreFromDistrictAuthorityDialog() {
  const { openDialog } = useDialog();

  const showPermissionRestoreFromDistrictAuthorityDialog = ({
    permissionId,
    onSuccess
  }: {
    permissionId: string;
    onSuccess: () => void;
  }) => {
    openDialog(({ closeDialog }) => (
      <PermissionRestoreFromDistrictAuthorityDialog
        permissionId={permissionId}
        onCloseDialog={closeDialog}
        isOpen
        onSuccess={onSuccess}
      />
    ));
  };

  return { showPermissionRestoreFromDistrictAuthorityDialog };
}

export default usePermissionRestoreFromDistrictAuthorityDialog;
