import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccountDetailedStatusSnapshotAccountStatus,
  AccountSnapshot,
  AccountSnapshotStatus
} from '@avispon/user/dist/models';
import { useAppConfig } from '@libs/app-config';

import { DropdownListButton, useRouter } from '@libs/common/v2';
import { IButtonAction } from '@libs/common/v2/components/button/DropdownButton';

import {
  AnonymizeUser,
  ChangePasswordPopUp,
  ChangeUserStatus,
  DeleteUserPopUp,
  LockUserPopUp,
  ResetPasswordPopUp,
  RestoreUserWithPasswordDialog,
  UnlockUserPopUp
} from '@libs/user/components';
import { useResendEmailConfirmation, useUserStatusActionsDefinition } from '@libs/user/hooks';
import { ChangeUserPasswordValidation, IPermissionsObject } from '@libs/user/models';

interface IProps {
  userDetails: AccountSnapshot;
  validationChangePasswordScheme?: ChangeUserPasswordValidation;
  isChangeWithNewPassword?: boolean;
  refetchUserDetails: () => void;
  permissionsKeysObject: IPermissionsObject;
}

function useUserHeaderDropdownMenu({
  userDetails,
  validationChangePasswordScheme,
  isChangeWithNewPassword,
  refetchUserDetails,
  permissionsKeysObject
}: IProps) {
  const [t] = useTranslation();
  const { goToPage, routes } = useRouter();
  const { resendEmailConfirmationVisible } = useAppConfig();
  const {
    handleChangeUserStatusToLockOrUnlock,
    handleChangeUserStatusToRestoreWithNoPassword,
    handleUserRestoreWithPasswordSubmit,
    handleChangeUserStatusToVerified
  } = useUserStatusActionsDefinition({ userDetails, refetchUserDetails });
  const { resendEmailConfirmation } = useResendEmailConfirmation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChangeStatus = useCallback(
    (status: AccountSnapshotStatus) => {
      if (status === AccountSnapshotStatus.BLOCKED) {
        handleChangeUserStatusToLockOrUnlock(true);
      }

      if (status === AccountSnapshotStatus.VERIFIED) {
        switch (userDetails?.status) {
          case AccountSnapshotStatus.BLOCKED:
            handleChangeUserStatusToLockOrUnlock();
            break;
          case AccountSnapshotStatus.DELETED:
            if (isChangeWithNewPassword) {
              setIsModalOpen(true);
            } else {
              handleChangeUserStatusToRestoreWithNoPassword();
            }
            break;
          default:
            handleChangeUserStatusToVerified();
            break;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userDetails]
  );

  const actionButtons: IButtonAction[] = [
    {
      actionKey: permissionsKeysObject?.delete,
      customButton: () => <DeleteUserPopUp userDetails={userDetails} onAccept={() => goToPage(routes.usersList())} />,
      isHidden:
        !permissionsKeysObject?.delete || !userDetails?.id || userDetails?.status === AccountSnapshotStatus.DELETED
    },
    {
      actionKey: permissionsKeysObject?.lock,
      customButton: onClose => (
        <LockUserPopUp
          userDetails={userDetails}
          onAccept={refetchUserDetails}
          onCancel={onClose}
          isTableButton={false}
        />
      ),
      isHidden: !permissionsKeysObject?.lock || userDetails?.status === AccountSnapshotStatus.BLOCKED
    },
    {
      actionKey: permissionsKeysObject?.unlock,
      customButton: onClose => (
        <UnlockUserPopUp
          userDetails={userDetails}
          onAccept={refetchUserDetails}
          onCancel={onClose}
          isTableButton={false}
        />
      ),
      isHidden: !permissionsKeysObject?.unlock || userDetails?.status !== AccountSnapshotStatus.BLOCKED
    },
    {
      actionKey: permissionsKeysObject?.changePassword,
      customButton: onClose => (
        <ChangePasswordPopUp
          accountId={userDetails?.id}
          validationChangePasswordScheme={validationChangePasswordScheme}
          handleClose={onClose}
          isTableButton={false}
        />
      ),
      isHidden: !permissionsKeysObject?.changePassword
    },
    {
      actionKey: permissionsKeysObject?.anonymize,
      customButton: onClose => (
        <AnonymizeUser
          accountId={userDetails?.id}
          handleClose={onClose}
          refetchUserDetails={refetchUserDetails}
          isTableButton={false}
        />
      ),
      isHidden: !permissionsKeysObject?.anonymize
    },
    {
      actionKey: permissionsKeysObject?.suspend,
      customButton: onClose => (
        <ChangeUserStatus
          handleClose={onClose}
          userDetails={userDetails}
          refetchUserDetails={refetchUserDetails}
          isTableButton={false}
          resumeActionKey={permissionsKeysObject?.resume}
          suspendActionKey={permissionsKeysObject?.suspend}
        />
      ),
      isHidden: !permissionsKeysObject?.suspend && !permissionsKeysObject?.resume
    },
    {
      actionKey: permissionsKeysObject?.restore,
      customButton: onClose => (
        <>
          <DropdownListButton
            label={t('user:action.renewUser')}
            onClick={() => {
              handleChangeStatus(AccountSnapshotStatus.VERIFIED);
              onClose();
            }}
          />
          {isModalOpen && (
            <RestoreUserWithPasswordDialog
              isOpen={isModalOpen}
              onCancel={onClose}
              onConfirm={handleUserRestoreWithPasswordSubmit}
            />
          )}
        </>
      ),
      isHidden: !permissionsKeysObject?.restore || userDetails?.status !== AccountSnapshotStatus.UNVERIFIED
    },
    {
      actionKey: permissionsKeysObject?.resetPassword,
      customButton: onClose => (
        <ResetPasswordPopUp
          email={userDetails?.email}
          handleClose={onClose}
          actionKey={permissionsKeysObject?.resetPassword}
        />
      ),
      isHidden:
        !permissionsKeysObject?.resetPassword ||
        (userDetails?.detailedStatus?.accountStatus !== AccountDetailedStatusSnapshotAccountStatus.UNCONFIRMED &&
          !userDetails?.email)
    },
    {
      actionKey: permissionsKeysObject?.resendEmailConfirmation,
      label: t('user:action.resendEmailConfirmation'),
      onClick: () => {
        resendEmailConfirmation(userDetails?.id, userDetails?.login);
      },
      isHidden:
        !resendEmailConfirmationVisible ||
        !permissionsKeysObject?.resendEmailConfirmation ||
        userDetails?.detailedStatus?.accountStatus !== AccountDetailedStatusSnapshotAccountStatus.UNCONFIRMED
    }
  ];

  return { actionButtons };
}

export default useUserHeaderDropdownMenu;
