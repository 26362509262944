import { IconType } from '@libs/common/v2';

export interface IAlertsTab {
  label: string;
  value: AlertDetailsTab;
  icon?: IconType;
}

export enum AlertDetailsTab {
  ALERT_GENERAL_DATA = 'ALERT_GENERAL_DATA',
  ALERT_HISTORY = 'ALERT_HISTORY'
}

export enum AlertNotificationChannel {
  EMAIL = 'alert.channel.email',
  NOTIFICATION = 'alert.channel.pull'
}

export enum AlertStatusColorTypes {
  RED,
  YELLOW,
  GREEN
}

export enum AlertStatus {
  SENDING_FAILURE = 'ibtm-domain.alert.status.sending-failure',
  PENDING = 'ibtm-domain.alert.status.pending',
  SENT = 'ibtm-domain.alert.status.sent'
}

export const ALERT_STATUS_COLOR_CONFIG = [
  { status: AlertStatus.SENDING_FAILURE, color: AlertStatusColorTypes.RED },
  { status: AlertStatus.PENDING, color: AlertStatusColorTypes.YELLOW },
  { status: AlertStatus.SENT, color: AlertStatusColorTypes.GREEN }
];
