import { FormMode } from '@libs/common/v2';

import { ArchiveTabEnum } from '../archive/common';
import { ApplicationArchiveUrlParam, IDomainRoutes } from '../models';
import { RoadTransportTabEnum } from '../road-transport-kreptd/models';

export const DomainRoutes: IDomainRoutes = {
  // Portal klienta
  applicationClientEdit: (id: string, folderId: string) => `/folders/${folderId}/applications/${id}`,

  // Wnioski
  applicationsList: () => '/applications',
  applicationDetails: (applicationId: string) => `/applications/${applicationId}/view`,
  folderApplicationDetails: (applicationId: string, folderId: string) =>
    `/folders/${folderId}/applications/${applicationId}/view`,
  applicationEdit: (applicationId: string) => `/applications/${applicationId}/form`,
  folderApplicationEdit: (applicationId: string, folderId?: string) =>
    `/folders/${folderId}/applications/${applicationId}/form`,
  archiveApplicationDetailsToArchive: (applicationId: string) =>
    `/${ApplicationArchiveUrlParam.TO_ARCHIVE}/applications/${applicationId}/view`,
  archiveApplicationDetailsArchived: (applicationId: string) =>
    `/${ApplicationArchiveUrlParam.ARCHIVED}/applications/${applicationId}/view`,
  exploitationBaseApplicationCreate: (applicationId: string) =>
    `/application/${applicationId}/exploitation-base/create`,
  exploitationBaseApplicationEdit: (exploitationBaseId: string, applicationId: string) =>
    `/application/${applicationId}/exploitation-base/${exploitationBaseId}/edit`,
  exploitationBaseApplicationView: (exploitationBaseId: string, applicationId: string) =>
    `/application/${applicationId}/exploitation-base/${exploitationBaseId}/view`,

  // Teczki
  foldersList: () => '/folders',
  folderDetails: (id: string) => `/folders/${id}/details`,
  folderEdit: (id: string) => `/folders/${id}/edit`,
  folderCreate: () => '/folders/create',
  exploitationBaseFolderView: (exploitationBaseId: string, folderId: string) =>
    `/folder/${folderId}/exploitation-base/${exploitationBaseId}/view`,
  exploitationBaseFolderEdit: (exploitationBaseId: string, folderId: string) =>
    `/folder/${folderId}/exploitation-base/${exploitationBaseId}/edit`,

  // Kierowcy
  driversList: () => '/drivers',

  // Dokumenty wydania
  releaseDocumentList: () => '/release-documents',
  releaseDocumentView: (id: string) => `/release-documents/${id}/${FormMode.VIEW}`,
  releaseDocumentEdit: (id: string) => `/release-documents/${id}/${FormMode.EDIT}`,
  releaseDocumentCreate: (id: string) => `/release-documents/${id}/${FormMode.CREATE}`,
  releaseDocumentReturn: (id: string) => `/release-documents/${id}/return`,
  releaseDocumentOnFolderSuspensionView: (folderId: string, suspensionId: string, releaseDocumentId: string) =>
    `/folders/${folderId}/details/suspensions/${suspensionId}/releaseDocuments/${releaseDocumentId}/${FormMode.VIEW}`,
  releaseDocumentOnFolderSuspensionEdit: (folderId: string, suspensionId: string, releaseDocumentId: string) =>
    `/folders/${folderId}/details/suspensions/${suspensionId}/releaseDocuments/${releaseDocumentId}/${FormMode.EDIT}`,
  releaseDocumentOnFolderSuspensionCreate: (folderId: string, suspensionId: string, releaseDocumentId: string) =>
    `/folders/${folderId}/details/suspensions/${suspensionId}/releaseDocuments/${releaseDocumentId}/${FormMode.CREATE}`,
  releaseDocumentOnFolderSuspensionReturn: (folderId: string, suspensionId: string, releaseDocumentId: string) =>
    `/folders/${folderId}/details/suspensions/${suspensionId}/releaseDocuments/${releaseDocumentId}/return`,

  // Wydruki
  printsList: () => `/prints`,
  printDetails: (id: string) => `/prints/${id}`,

  // Dokumenty wychodzące
  documentsIssuedList: () => '/documents-issued',

  // Dokumenty EZD
  ezdDocumentsList: () => '/ezd-documents',
  ezdDocumentDetails: (id: string) => `/ezd-documents/${id}/details`,

  // Certyfikaty S
  sCertificatesList: () => '/s-certificates',
  sCertificateDetails: (id: string) => `/s-certificates/${id}/view`,

  // Magazyn
  resourcesList: () => '/resource',
  resourceDetails: (depotId: string) => `/resource/${depotId}/details`,
  resourceTransferDetails: (depotId: string) => `/resource/${depotId}/transfer`,
  disposalsList: () => '/resource/disposals', // TODO - zrefaktorować nazwe na resourceDisposalsList
  resourceObjectsList: () => `/resource/objects`,

  // Weryfikacja uprawnień - Postępowania
  proceedingsList: () => '/proceeding',
  proceedingAdministrationCreate: (folderId: string) => `/proceeding/ADMINISTRATION/create/folder/${folderId}/details`,
  proceedingVerificationCreate: (folderId: string) => `/proceeding/VERIFICATION/create/folder/${folderId}/details`,
  proceedingCreateAdministrationFromBase: (folderId: string, id: string) =>
    `/proceeding/ADMINISTRATION/create-from-base/${id}/folder/${folderId}/details`,
  proceedingCreateVerificationFromBase: (folderId: string, id: string) =>
    `/proceeding/VERIFICATION/create-from-base/${id}/folder/${folderId}/details`,
  proceedingAdministrationDetails: (folderId: string, id: string) =>
    `/proceeding/ADMINISTRATION/${id}/details/folder/${folderId}/details`,
  proceedingVerificationDetails: (folderId: string, id: string) =>
    `/proceeding/VERIFICATION/${id}/details/folder/${folderId}/details`,
  proceedingAdministrationEdit: (folderId: string, id: string) =>
    `/proceeding/ADMINISTRATION/${id}/edit/folder/${folderId}/details`,
  proceedingVerificationEdit: (folderId: string, id: string) =>
    `/proceeding/VERIFICATION/${id}/edit/folder/${folderId}/details`,

  // Weryfikacja uprawnień - Zawieszenia
  suspensionsList: () => '/suspensions',
  suspensionCreate: (folderId: string) => `/suspensions/create/folder/${folderId}/edit`,
  suspensionEdit: (folderId: string, id: string) => `/suspensions/${id}/edit/folder/${folderId}/edit`,
  suspensionDetails: (folderId: string, id: string) => `/suspensions/${id}/details/folder/${folderId}/edit`,
  suspensionReleaseDocumentsTab: (id: string, folderId: string) =>
    `/suspensions/${id}/edit/folder/${folderId}/edit?tab=SUSPENSION_RELEASE_DOCUMENTS`,

  // Archiwum
  archiveApplicationsArchiveList: () => `/archive/applications?tab=${ArchiveTabEnum.ARCHIVED}`,
  archiveApplicationsCarryOnArchivedList: () => `/archive/applications?tab=${ArchiveTabEnum.CARRY_ON_ARCHIVED}`, // TODO: do usunięcia
  archiveApplicationsToArchiveList: () => `/archive/applications?tab=${ArchiveTabEnum.TO_ARCHIVE}`,
  // TODO dodać routingi dla archiwum teczki i wypożyczenia teczek

  // Transport drogowy KREPTD
  roadTransportsList: () => '/road-transport',
  roadTransportsAdministrativeProceedingsList: () =>
    `/road-transport?tab=${RoadTransportTabEnum.ADMINISTRATIVE_PROCEEDINGS}`,
  roadTransportsFoldersToTransferList: () => `/road-transport?tab=${RoadTransportTabEnum.FOLDERS_TO_TRANSFER}`,
  roadTransportsViolationsList: () => `/road-transport?tab=${RoadTransportTabEnum.VIOLATIONS}`,
  roadTransportDetails: (id: string) => `/road-transport/${id}/view`,
  roadTransportEdit: (id: string) => `/road-transport/${id}/form`,
  roadTransportDetailsView: (id: string) => `/road-transport/${id}/details-view`,

  // Stowarzyszenia
  associationsList: () => '/associations',
  associationDetails: (id: string) => `/associations/${id}/details`,
  associationEdit: (id: string) => `/associations/${id}/edit`,
  associationCreate: () => '/associations/create',

  // Zezwolenia zagraniczne - Wydane zezwolenia
  releasedPermits: () => `/released-permits`,
  releasedPermitsFilteredByReleaseDocument: (number: string) => `/released-permits/?number=${number}`,
  releasedPermitsEkmtDetails: (id: string) => `/released-permits/permit-EKMT/${id}/view`,

  // Zezwolenia zagraniczne - Limity
  disposablePermitsList: () => `/disposable-permits`,
  disposablePermitDetails: (id: string) => `/disposable-permits/${id}/details`,
  ekmtPermitsList: () => `/ekmt`,
  ekmtPermitDetails: (id: string) => `/ekmt/${id}/details`,

  // Zezwolenia zagraniczne - Komisja społeczna
  socialCommissionsList: () => '/social-commission',
  // TODO dodać funcje routing na poszczególne taby
  socialCommissionTab: (tab: string) => `/social-commission?tab=${tab}`,
  socialCommissionDetails: (id: string) => `/social-commission/application-EKMT/${id}/view`,
  socialCommissionEdit: (id: string) => `/social-commission/application-EKMT/${id}/edit`,
  socialComissionCreate: () => '/social-commission/application-EKMT/create',
  socialComissionDisposablePermitDetails: (id: string) => `/social-commission/disposable-permit/${id}/view`,
  socialComissionDisposablePermitEdit: (id: string) => `/social-commission/disposable-permit/${id}/edit`,
  socialComissionDisposablePermitCreate: () => '/social-commission/disposable-permit/create',

  // TODO do przeniesienia do modułu document-template
  documentTemplatesList: () => '/administration/document-templates',
  documentTemplateCreate: () => '/administration/document-templates/create',
  documentTemplateDetails: (id: string) => `/administration/document-templates/${id}/details`,
  documentTemplateEdit: (id: string) => `/administration/document-templates/${id}/edit`
} satisfies { [key: string]: (...args: string[]) => string };
