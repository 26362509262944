import { FolderLockUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

interface UpdateFolderLockParams extends FolderLockUpdateRequest {
  folderLockId: string;
}

function updateLock({ folderLockId, ...restParams }: UpdateFolderLockParams) {
  return API.folderLock.updateFolderLock(folderLockId, restParams, {
    ...endpointsConfig.updateFolderLock
  });
}

function useUpdateLockMutation() {
  return useMutation(updateLock);
}

export default useUpdateLockMutation;
