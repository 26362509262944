import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { AssignAsApplicationsEditorRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function assignAsEditor(assignAsApplicationsPrinterRequest: AssignAsApplicationsEditorRequest) {
  return API.application.assignAsEditor(assignAsApplicationsPrinterRequest, {
    ...endpointsConfig.updateAssignedTo
  });
}

function useAssignApplicationEditorMutation() {
  const [t] = useTranslation();
  const { showSuccessSnackbar, showSnackbar } = useSnackbar();
  return useMutation(assignAsEditor, {
    onSuccess: () => {
      showSuccessSnackbar(t('prints:messages.printerAssignmentSuccessMessage'));
    },
    onError: () => showSnackbar('error', t('prints:messages.printerAssignmentFailureMessage'))
  });
}

export default useAssignApplicationEditorMutation;
