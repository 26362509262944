import { usePaginatedQuery } from 'react-query';
import { ForeignPermissionSearchFilter } from '@ibtm/client-domain';

import { API } from '@libs/domain/api';

import { ForeignPermissionQueryEnum } from '../../model';
import { parseClientForeignPermissionsList } from '../../utils';
import { endpointsConfig } from '../endpoints-config';

const getForeignPermissions = async (params: ForeignPermissionSearchFilter) => {
  const { data } = await API.client.foreignPermissions.getForeignPermissionSnapshotPage('', '', params, {
    ...endpointsConfig.getForeignPermissionSnapshotPage
  });

  return {
    ...data,
    content: parseClientForeignPermissionsList(data.content)
  };
};

function useForeignPermissionsClientQuery(params: ForeignPermissionSearchFilter) {
  return usePaginatedQuery([ForeignPermissionQueryEnum.FOREIGN_PERMISSION_CLIENT_LIST, params], () =>
    getForeignPermissions(params)
  );
}
export default useForeignPermissionsClientQuery;
