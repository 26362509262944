import React from 'react';

function SplashScreen() {
  return (
    <div id="splash-screen" style={{ width: '100%' }}>
      <div className="center h-full">
        <div className="logo flex h-full items-center justify-center">
          <img width="128" src={`${process.env.PUBLIC_URL}/assets/images/logos/logo.png`} alt="logo" />
        </div>
        <div className="spinner-wrapper">
          <div className="spinner">
            <div className="inner">
              <div className="gap" />
              <div className="left">
                <div className="half-circle" />
              </div>
              <div className="right">
                <div className="half-circle" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(SplashScreen);
