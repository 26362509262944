import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { applicationColorDefnitions, applicationStatusColorConfig } from '../components';

export default function useApplicationStatusColored(status: string) {
  const statusDefinitionItem = applicationStatusColorConfig.find(definition => definition.status === status);
  const { translate } = useDictionaryTranslations();
  return {
    title: translate(DomainDictionaryEnum.APPLICATION_STATUS, status),
    color: applicationColorDefnitions(statusDefinitionItem?.color)
  };
}
