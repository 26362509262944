import { MutationConfig, useMutation } from 'react-query';
import { ForeignPermissionTransportApplicationRecordsUpdateRequest as ForeignPermissionTransportApplicationRecordsUpdateRequestClient } from '@ibtm/client-domain';
import { ForeignPermissionTransportApplicationRecordsUpdateRequest } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

type UpdateSpoRecordsRequest = {
  applicationId: string;
  formData: ForeignPermissionTransportApplicationRecordsUpdateRequest;
};

type UpdateSpoRecordsRequestClient = {
  applicationId: string;
  formData: ForeignPermissionTransportApplicationRecordsUpdateRequestClient;
};

export const updateSpoForeignPermissionRecords = async ({ applicationId, formData }: UpdateSpoRecordsRequest) => {
  return API.applicationForeignPermissions.updateSpoRecords(applicationId, formData, {
    ...endpointsConfig.updateSpoRecords
  });
};

export const updateSpoForeignPermissionRecordsClient = async ({
  applicationId,
  formData
}: UpdateSpoRecordsRequestClient) => {
  return API.client.applicationForeignPermissions.updateSpoRecords(applicationId, '', '', formData, {
    ...endpointsConfig.updateSpoRecords
  });
};

export default function useUpdateApplicationForeignSpoPermissionRecordsMutation(config?: MutationConfig<unknown>) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(updateSpoForeignPermissionRecordsClient, updateSpoForeignPermissionRecords), config);
}
