import { QueryConfig, usePaginatedQuery } from 'react-query';
import { ApplicationSearchFilter, ApplicationSnapshot, ApplicationSnapshotsPage } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import ApplicationQueryKeysEnum from './ApplicationQueryKeysEnum';

export const getApplications = async (_, params: ApplicationSearchFilter) => {
  const { data } = await API.application.getApplicationsSnapshotPage(
    { ...params },
    {
      ...endpointsConfig.getApplicationsSnapshotPage
    }
  );
  return data;
};

export const getApplicationsClient = async (_, params: ApplicationSearchFilter) => {
  const { data } = await API.client.application.getApplicationsSnapshotPageForFolder('', '', params, {
    ...endpointsConfig.getApplicationsSnapshotPage
  });
  return data as ApplicationSnapshotsPage;
};

export default function useApplicationsQuery(
  params: ApplicationSearchFilter,
  queryConfig: QueryConfig<IPaginatedModel<ApplicationSnapshot>, unknown> = {}
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return usePaginatedQuery(
    [ApplicationQueryKeysEnum.APPLICATION_LIST, params],
    getQuery(getApplicationsClient, getApplications),
    queryConfig
  );
}
