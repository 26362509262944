import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener, InputAdornmentProps } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { TextInput, TIME_FORMAT, useDetectTouchScreen } from '@libs/common/v2';

import datepickerTranslations from './datepickerTranslations';
import InputButtons from './InputButtons';

export interface IProps {
  onChange?: (date: string) => void;
  onBlur?: () => void;
  label?: string;
  inputClassName?: string;
  textInputClassName?: string;
  value?: string;
  helperText?: string;
  isClearable?: boolean;
  isFullWidth?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  isWarning?: boolean;
  maxTime?: any;
  minTime?: any;
  hasErrorTooltip?: boolean;
}

function TimepickerInput({
  onChange,
  onBlur,
  label,
  inputClassName,
  textInputClassName,
  value,
  maxTime,
  minTime,
  helperText,
  isClearable,
  isFullWidth = true,
  isRequired,
  isDisabled,
  isError,
  isWarning,
  hasErrorTooltip
}: IProps) {
  const [t] = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string | moment.Moment>(null);
  const { isTouchScreen } = useDetectTouchScreen();

  useEffect(() => {
    if (value) {
      const parseValueToInput = () => {
        const [hour, minutes] = value.split(':');

        return setInputValue(moment().hour(+hour).minute(+minutes));
      };
      parseValueToInput();
    }
  }, [value]);

  const handleInputChange = date => {
    if (moment(date).format(TIME_FORMAT)?.length === 5) {
      return onChange(moment(date).format(TIME_FORMAT));
    }
    if (date === null) {
      return onChange(date);
    }
    return setInputValue(date);
  };

  const translations = useMemo(() => datepickerTranslations(t, true), [t]);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
          <TimePicker
            localeText={translations}
            className={inputClassName}
            onChange={date => handleInputChange(date)}
            onAccept={date => onChange(moment(date).format(TIME_FORMAT))}
            onClose={() => setOpen(false)}
            value={moment(inputValue)}
            label={label}
            {...(minTime && { minTime: moment(minTime) })}
            {...(maxTime && { maxTime: moment(maxTime) })}
            open={isOpen}
            disabled={isDisabled}
            format={TIME_FORMAT}
            slots={{ textField: TextInput }}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock
            }}
            slotProps={{
              // @ts-ignore niepoprawnie bierze typ z biblioteki @enigma/fe-ui
              textField: props => ({
                ...props,
                fullWidth: isFullWidth,
                required: isRequired,
                className: textInputClassName,
                error: isError,
                isWarning,
                helperText,
                onBlur: () => {
                  if (!isTouchScreen) {
                    setOpen(false);
                  }
                  onBlur?.();
                },
                onClick: e => {
                  if (isTouchScreen) {
                    setOpen(true);
                  }
                  e.preventDefault();
                },
                hasErrorTooltip
              }),
              inputAdornment: {
                position: 'end',
                component: () => (
                  <InputButtons
                    onClearClick={() => onChange(null)}
                    onOpenClick={() => setOpen(true)}
                    isDisabled={isDisabled}
                    showClearButton={isClearable && !isEmpty(value)}
                  />
                )
              } as Partial<InputAdornmentProps>,
              toolbar: {
                toolbarFormat: TIME_FORMAT,
                hidden: false
              }
            }}
          />
        </LocalizationProvider>
      </div>
    </ClickAwayListener>
  );
}

export default TimepickerInput;
