import { PaginatedQueryConfig, usePaginatedQuery } from 'react-query';
import { TransitScheduleDatesPage, TransitScheduleDatesSearchFilter } from '@ibtm/domain/dist/models';

import { API } from '@libs/domain/api';

import { ApplicationQueryKeysEnum } from '../../../application';
import { endpointsConfig } from '../endpoints-config';

type GetTransitDatesParams = {
  transitScheduleId: string;
} & TransitScheduleDatesSearchFilter;

export const getTransitDates = async (_, params: GetTransitDatesParams) => {
  const { data } = await API.transitSchedule.getTransitDatesPage(
    params.transitScheduleId,
    { ...params },
    {
      ...endpointsConfig.getTransitDates
    }
  );
  return data;
};

const useTransitDatesQuery = (
  params: GetTransitDatesParams,
  queryConfig?: PaginatedQueryConfig<TransitScheduleDatesPage, unknown>
) => {
  return usePaginatedQuery([ApplicationQueryKeysEnum.TRANSIT_DATES, params], getTransitDates, queryConfig);
};
export default useTransitDatesQuery;
