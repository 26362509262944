import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { useSnackbar } from '@enigma/fe-ui';

import {
  TableIconButton,
  TMutableTableFormContextProvider,
  useFormV2Context,
  useTableAdapter,
  useTableContext
} from '@libs/common/v2';

interface IProps<T extends Record<string, any>> {
  row: Row<T>;
  /**
   * Funkcja pozwalająca na przewinięcie tabeli do wskazanej kolumny
   * @param columnName Nazwa kolumny
   */
  scrollToColumn?: (columnName: string) => void;
}

/**
 * Komponent przeznaczony do użycia przy tabeli mutowalnej. Wysyła edytowany wiersz,
 * jest domyślnie użyty w TableActions
 */
function TableConfirmEditRow<T extends Record<string, any>>({ row, scrollToColumn }: IProps<T>) {
  const [t] = useTranslation();
  const [isLoading, setLoading] = useState(false);

  const table = useTableAdapter();
  const tableContext = useTableContext();

  const { handleSubmit, ...restFormContext } = useFormV2Context();
  const { showErrorSnackbar } = useSnackbar();
  const { onRowEditCreateConfirm, refetch } = tableContext;

  const handleOnClick = async values => {
    setLoading(true);
    await onRowEditCreateConfirm?.(values, {
      isCreated: table.state.isCreating,
      isEdited: table.state.isEditing,
      tableInstance: table,
      tableContext,
      formContext: restFormContext as TMutableTableFormContextProvider,
      onSuccess: () => {
        // reset form tabeli przy tabeli mutowalnej z dodatkowymi kolumnami
        if (table.state.headerGroups?.length) {
          table?.cancelInsertingColumns?.();
          setLoading(false);
        }
        // reset form tabeli przy tabeli mutowalnej bez dodatkowych kolumn
        else {
          table?.cancelEditCreateRow?.();
          setLoading(false);
        }
        refetch?.();
      },
      onError: () => {
        setLoading(false);
      }
    });
  };

  const onRowError = errors => {
    showErrorSnackbar(t('message.discardRowChangesOrCorrect'));
    if (scrollToColumn) {
      const key = getFirstErrorColumn(errors, table);
      scrollToColumn?.(key);
    }
    setLoading(false);
  };

  return (
    <TableIconButton
      icon="SaveIcon"
      row={row}
      tooltipTitle={t('action.save')}
      isButtonLoading={isLoading}
      isDisabled={isLoading}
      onClick={handleSubmit(handleOnClick, onRowError)}
    />
  );
}

const getFirstErrorColumn = (errors: any, table: any) => {
  let isScrolled = false;
  let result = '';
  table?.columns?.forEach(column => {
    Object.keys(errors).forEach(row => {
      Object.keys(errors[row]).forEach(field => {
        if (field === column.id && !isScrolled) {
          isScrolled = true;
          result = field;
        }
      });
    });
  });
  return result;
};

export default TableConfirmEditRow;
