import React, { useEffect, useState } from 'react';
import { QueryConfig, QueryKey, useQuery } from 'react-query';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import _ from 'lodash';

import {
  NUMBER_OF_AUTOCOMPLETE_RESULTS,
  SEARCH_TEXT_DEBOUNCE_TIME,
  SelectOption,
  useDebounceValue,
  useOnScreen
} from '@libs/common/v2';

import AutocompleteSelect from './AutocompleteSelect';

/*
przyklad uzycia tego komponentu:

<AutocompleteLazyFetchSelect
    fetchFunctionResolverWithPage={(text, page) =>
    API.folder.getFoldersSnapshotPage({ page, size: 10, number: text })}
    fetchedDataSelectParser={res =>
        res.data.content.map(item => ({
        id: item.id,
        value: item.id,
        name: item.number
        }))}
        queryKey={FolderQueryKeysEnum.FOLDERS_LIST_FILTER}
    onChange={(e, v) => console.log('selectedItem: ', e, v)}
/>
*/

interface IProps {
  /**
   * @fetchFunctionResolver - funkcja asynchroniczna z dostepem do number page'a, ktora zwraca sparsowana liste opcji
   * "page" ewentualnie mozna uzyc jako multiplier dla pageSize
   */
  fetchFunctionResolverWithPage: (params: any, page: number) => Promise<any>;
  /**
   * Funkcja która przyjmuje dane i zwraca sparsowany array opcji do AutocompleteSelect
   */
  fetchedDataSelectParser: (data: any) => SelectOption[];
  queryKey: string;
  queryKeyParams?: QueryKey;
  queryConfig?: QueryConfig<any, unknown>;
  onChange: (_event: React.SyntheticEvent, value: SelectOption) => void;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  getOptionLabel?: (option: SelectOption) => string;
  getOptionSelected?: (option: SelectOption) => boolean;
  renderTags?: (value: IProps['value'], getTagProps) => React.ReactNode;
  renderOption?: (option: any) => React.ReactNode;
  name?: string;
  label?: string;
  tooltip?: string;
  TextFieldProps?: Partial<MuiTextFieldProps>;
  helperText?: string;
  value?: SelectOption | SelectOption[] | string;
  isMultiple?: boolean;
  isFreeSolo?: boolean;
  isDisableClearable?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  isFilterSelectedOptions?: boolean;
  isQueryInitiallyEnabled?: boolean;
  customFilterOptions?: (options: SelectOption[]) => SelectOption[];
  className?: string;
  hasErrorTooltip?: boolean;
  totalPages?: number;
}

function AutocompleteLazyFetchSelect({
  fetchFunctionResolverWithPage,
  value,
  tooltip,
  fetchedDataSelectParser,
  getOptionLabel,
  renderOption,
  queryKey,
  queryConfig,
  isQueryInitiallyEnabled = false,
  customFilterOptions,
  queryKeyParams,
  hasErrorTooltip,
  totalPages,
  ...forwardProps
}: IProps) {
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [isVisible, lastElementRef] = useOnScreen();
  const [inputText, setInputText] = useState<string>(undefined);
  const [isEndPage, setIsEndPage] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const debouncedInputText = useDebounceValue(inputText, SEARCH_TEXT_DEBOUNCE_TIME);
  const [page, setPage] = useState<number>(0);

  const { isLoading, isFetching, refetch } = useQuery(
    [queryKey, queryKeyParams],
    () => fetchFunctionResolverWithPage(debouncedInputText, page),
    {
      enabled: isQueryInitiallyEnabled,
      onSuccess: data => {
        if (data?.content || data) {
          if (page === 0) {
            setOptions(fetchedDataSelectParser(data?.content || data));
          } else {
            setOptions(oldOptions => [...oldOptions, ...fetchedDataSelectParser(data?.content || data)]);
          }
          const pageAmount = page + 1;
          setIsEndPage(data?.totalPages ? pageAmount >= data?.totalPages : pageAmount >= totalPages);
        }
        queryConfig?.onSuccess?.(data);
      },
      ..._.omit(queryConfig, 'onSuccess')
    }
  );

  useEffect(() => {
    if (_.isString(debouncedInputText) && page === 0) {
      if (isOpen) {
        refetch();
      }
    } else if (page !== 0) {
      setPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputText]);

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (isVisible && !isLoading && !isFetching && !isEndPage && options.length >= NUMBER_OF_AUTOCOMPLETE_RESULTS) {
      setPage(prevState => prevState + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const handleInputChange = event => {
    const eventValue = (event?.target as HTMLInputElement)?.value;
    setInputText(eventValue);
  };

  const handleClosePopup = () => {
    setIsOpen(false);
    setOptions([]);
    setIsEndPage(false);
    setPage(0);
    setInputText('');
  };

  const optionLabel = (props: any) => (getOptionLabel ? getOptionLabel(props) : props.name);
  return (
    <AutocompleteSelect
      options={options ?? []}
      isLoading={isLoading || isFetching || !options}
      tooltip={tooltip}
      value={value}
      customFilterOptions={customFilterOptions}
      onInputChange={handleInputChange}
      isClearable
      onOpen={() => {
        setIsOpen(true);
        refetch();
      }}
      onClose={handleClosePopup}
      renderOption={(props, option) => {
        const isLast = options[options.length - 1] === option;
        return (
          <li ref={isLast ? lastElementRef : null} {...props}>
            {renderOption ? renderOption(option) : optionLabel(option)}
          </li>
        );
      }}
      isFetchedDynamically
      hasErrorTooltip={hasErrorTooltip}
      {...forwardProps}
    />
  );
}

export default AutocompleteLazyFetchSelect;
