import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DictionarySelectField,
  FormMode,
  GridLayout,
  InputMode,
  NUMBER_OF_AUTOCOMPLETE_RESULTS,
  Section,
  SelectWithFetchedOptionsField,
  TextInputField,
  typedNameV2,
  useFormV2Context,
  ValidatorEnums
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DefaultAlertFromValues } from '@libs/alert';
import { API } from '@libs/alert/api';
import { useElementVisibility } from '@libs/permission';

import AlertUIElementEnum from '../../config/permission/AlertUIElementEnum';
import { AlertDictionaryEntryNameEnum, AlertNotificationChannel } from '../../model';

interface IProps {
  formMode: FormMode;
}

function NotificationAlertSection({ formMode }: IProps) {
  const [t] = useTranslation();
  const { viewMode } = useViewModesV2(formMode);
  const { watch } = useFormV2Context();
  const channel = watch('channelKeys');
  const groups = watch('recipients.accountSelector.groups');
  const { checkIsElementVisible } = useElementVisibility();

  const isEmail = useMemo(() => channel && channel.some(field => field === AlertNotificationChannel.EMAIL), [channel]);
  const isUser = useMemo(
    () => channel && channel.some(field => field === AlertNotificationChannel.NOTIFICATION),
    [channel]
  );

  const hasPermissionsToBellNotifications = checkIsElementVisible(AlertUIElementEnum.NOTIFICATION_CHANEL_BELL_OPTION);

  const inputMode = useMemo(() => (viewMode ? InputMode.VIEW : InputMode.FORM), [viewMode]);
  const fetchUsers = useCallback((searchText: string) => {
    return API.user.accountController.searchLiteAccounts({
      page: 0,
      size: NUMBER_OF_AUTOCOMPLETE_RESULTS,
      query: searchText,
      sort: [],
      statuses: ['VERIFIED']
    });
  }, []);

  const fetchGroups = useCallback((searchText: string) => {
    return API.group.find({
      page: 0,
      size: NUMBER_OF_AUTOCOMPLETE_RESULTS,
      query: searchText
    });
  }, []);

  const fetchOrganizeUnits = useCallback((searchText: string) => {
    return API.organizationStructure.findOrganizationUnit({
      page: 0,
      size: NUMBER_OF_AUTOCOMPLETE_RESULTS,
      nameFragment: searchText
    });
  }, []);

  return (
    <Section title={t('alerts:section.notification')} isCollapsable>
      <GridLayout itemProps={{ xs: 12 }}>
        <TextInputField
          inputMode={inputMode}
          label={t('alerts:field.subject')}
          name={typedNameV2<DefaultAlertFromValues>('content.subject')}
          isRequired
        />
        <TextInputField
          inputMode={inputMode}
          name={typedNameV2<DefaultAlertFromValues>('content.message')}
          label={t('alerts:field.message')}
          lines={5}
          isRequired
        />
        <DictionarySelectField
          dictionaryName={AlertDictionaryEntryNameEnum.ALERT_CHANNEL}
          inputMode={inputMode}
          name={typedNameV2<DefaultAlertFromValues>('channelKeys')}
          label={t('alerts:field.channel')}
          stringValue
          isRequired
          isMultiple
          {...(!hasPermissionsToBellNotifications && {
            optionsDisabled: option => option.value === AlertNotificationChannel.NOTIFICATION
          })}
        />
        {isEmail && (
          // Wartość dla pola `email` nie przychodzi z API. Jest to powiązane z zadaniami: https://jira.enigma.com.pl/browse/GITD-871 oraz https://jira.enigma.com.pl/browse/GITD-904
          <TextInputField
            inputMode={inputMode}
            name={typedNameV2<DefaultAlertFromValues>('recipients.emails')}
            label={t('alerts:field.email')}
            validation={{ contextValidator: [ValidatorEnums.EMAIL] }}
            isRequired
          />
        )}
        {isUser && (
          <SelectWithFetchedOptionsField
            inputMode={hasPermissionsToBellNotifications ? inputMode : InputMode.VIEW}
            fetchFunction={fetchUsers}
            isFetchDisabled={!hasPermissionsToBellNotifications}
            getOptionLabel={user => user.serviceNumber}
            label={t('alerts:field.users')}
            name={typedNameV2<DefaultAlertFromValues>('recipients.accountSelector.accounts')}
            isMultiple
            isRequired
          />
        )}
        {isUser && (
          <SelectWithFetchedOptionsField
            inputMode={hasPermissionsToBellNotifications ? inputMode : InputMode.VIEW}
            fetchFunction={fetchGroups}
            isFetchDisabled={!hasPermissionsToBellNotifications}
            getOptionLabel={group => group.name}
            label={t('alerts:field.groups')}
            name={typedNameV2<DefaultAlertFromValues>('recipients.accountSelector.groups')}
            isMultiple
            isRequired
          />
        )}
        {isUser && groups?.length > 0 && (
          <SelectWithFetchedOptionsField
            inputMode={hasPermissionsToBellNotifications ? inputMode : InputMode.VIEW}
            fetchFunction={fetchOrganizeUnits}
            isFetchDisabled={!hasPermissionsToBellNotifications}
            getOptionLabel={unit => unit.name}
            label={t('alerts:field.organizeUnits')}
            name={typedNameV2<DefaultAlertFromValues>('recipients.accountSelector.groupMembershipUnits')}
            isMultiple
            isRequired
          />
        )}
      </GridLayout>
    </Section>
  );
}

export default NotificationAlertSection;
