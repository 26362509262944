import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, Icon, useDialog } from '@libs/common/v2';

interface IProps {
  onConfirm?: (
    setConfirmLoading: (confirmLoading: boolean | Record<string, unknown>) => void,
    closeDialog: () => void
  ) => void;
  onCancel?: () => void;
  confirmType?: 'primary' | 'danger';
  message: string | React.ReactNode;
  title?: string;
  shortTitle?: string;
  confirmText?: string;
  cancelText?: string;
  dialogSize?: 'small' | 'medium' | 'large';
  withErrorIcon?: boolean;
  isCancelButtonVisible?: boolean;
  isConfirmButtonVisible?: boolean;
}

export default function useConfirmDialog() {
  const [t] = useTranslation();
  const { openDialog } = useDialog();

  const confirm = ({
    onConfirm,
    onCancel,
    confirmType,
    message,
    title,
    shortTitle,
    confirmText,
    cancelText,
    dialogSize = 'small',
    withErrorIcon,
    isCancelButtonVisible = true,
    isConfirmButtonVisible = true
  }: IProps) => {
    openDialog(({ closeDialog }) => (
      <Dialog
        title={title}
        shortTitle={shortTitle}
        confirmText={confirmText ?? t('action.confirm')}
        cancelText={cancelText ?? t('action.cancel')}
        onConfirm={setConfirmLoading => {
          onConfirm?.(setConfirmLoading, closeDialog);
        }}
        onCancel={() => {
          closeDialog();
          onCancel?.();
        }}
        onClose={closeDialog}
        confirmButtonType={confirmType}
        dialogSize={dialogSize}
        isCancelButtonVisible={isCancelButtonVisible}
        isConfirmButtonVisible={isConfirmButtonVisible}
        hasIconButtonsOnSmallScreen
        isOpen
      >
        {withErrorIcon ? (
          <div className="flex">
            <div className="h-full mr-24">
              <Icon
                icon="ValidationErrorIcon"
                width={60}
                height={60}
                className="self-center justify-self-center"
                color="error"
                colorWeight="600"
              />
            </div>
            {message}
          </div>
        ) : (
          message
        )}
      </Dialog>
    ));
  };

  return [confirm];
}
