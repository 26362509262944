import { QueryConfig, usePaginatedQuery } from 'react-query';
import { ForeignPermissionTransportApplicationRecordPage, VersionedPageableSearchFilter } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import ApplicationQueryKeysEnum from './ApplicationQueryKeysEnum';

export type TGetApplicationsSearchParams = { applicationId: string; params?: VersionedPageableSearchFilter };

export const getApplications = async (_, params: TGetApplicationsSearchParams) => {
  const { data } = await API.applicationForeignPermissions.searchForeignSpoPermissions(
    params.applicationId,
    params.params,
    { ...endpointsConfig.searchForeignSpoPermissions }
  );
  return data;
};

export default function useApplicationsForeignSpoPermissionsQuery(
  params: TGetApplicationsSearchParams,
  queryConfig: QueryConfig<ForeignPermissionTransportApplicationRecordPage, unknown> = {}
) {
  return usePaginatedQuery(
    [ApplicationQueryKeysEnum.APPLICATIONS_FOREIGN_SPO_PERMISSION_LIST, params],
    getApplications,
    queryConfig
  );
}
