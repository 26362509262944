import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { CalendarDate } from 'calendar-date';
import moment from 'moment';
import { object as YupObject, string as YupString } from 'yup';

import {
  DatepickerField,
  Dialog,
  FormV2Context,
  GridItem,
  GridLayout,
  isDateValid,
  typedNameV2
} from '@libs/common/v2';

import { useUpdateLicenseCopyMutation } from '../api';

export interface IUpdateLicenseRequest {
  id?: string;
  validFrom?: string;
  validTo?: string;
  printDate?: string;
  version?: number;
}

interface IProps {
  readonly closeDialog: () => void;
  readonly initialData: IUpdateLicenseRequest;
  readonly onSuccess: () => void;
}

export function EditLicenseCopyDialog({ closeDialog, initialData, onSuccess }: IProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: updateLicense, isLoading } = useUpdateLicenseCopyMutation();

  const validationScheme = YupObject({
    printDate: YupString().nullable().required().concat(isDateValid()),
    validFrom: YupString().nullable().required().concat(isDateValid()),
    validTo: YupString()
      .nullable()
      .required()
      .test(
        'validFrom',
        t('applications:validations.validToDateCanNotBeBeforeValidFrom'),
        function testValidFrom(validTo) {
          // eslint-disable-next-line react/no-this-in-sfc
          const parent = this.parent as IUpdateLicenseRequest;
          const { validFrom } = parent;
          return !validFrom || !validTo || moment(validFrom).isBefore(validTo);
        }
      )
      .concat(isDateValid())
  });

  const form = useForm<Partial<IUpdateLicenseRequest>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationScheme)
  });

  useEffect(() => {
    form.reset(initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (data: Partial<IUpdateLicenseRequest>) => {
    const { id, validTo, version, printDate, validFrom } = data;
    return updateLicense(
      {
        id,
        validFrom: validFrom as unknown as CalendarDate,
        version,
        printDate: printDate as unknown as CalendarDate,
        validTo: validTo as unknown as CalendarDate
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('license:message.licenseCopyUpdated'));
          onSuccess();
          closeDialog();
        }
      }
    );
  };

  return (
    <Dialog
      title={t('license:title.editLicenseCopy')}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={form.handleSubmit(handleSubmit)}
      onCancel={closeDialog}
      dialogSize="small"
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <GridLayout>
            <GridItem xs={12}>
              <DatepickerField
                name={typedNameV2<IUpdateLicenseRequest>('printDate')}
                label={t('license:fields.printDate')}
                isRequired
              />
            </GridItem>
            <GridItem xs={12}>
              <DatepickerField
                name={typedNameV2<IUpdateLicenseRequest>('validFrom')}
                label={t('license:fields.validFrom')}
                isRequired
              />
            </GridItem>
            <GridItem xs={12}>
              <DatepickerField
                name={typedNameV2<IUpdateLicenseRequest>('validTo')}
                label={t('license:fields.validTo')}
                isRequired
              />
            </GridItem>
          </GridLayout>
        </form>
      </FormV2Context.Provider>
    </Dialog>
  );
}
