import { useCallback } from 'react';
import { LicenseCopySearchFilter } from '@ibtm/domain';
import { get, isNull, omitBy } from 'lodash';

import { AutocompleteLazyFetchSelectField, SelectOption, useFormV2Context } from '@libs/common/v2';

import { useMetaFormContext } from '@libs/meta-form/context';
import { ApiRegistryGet, AutocompleteFieldProperties, FieldProps } from '@libs/meta-form/models';
import { useGetQuery } from '@libs/meta-form/services';

function AutocompleteLazyFetchField({
  fieldId,
  label,
  api,
  inputMode,
  required,
  multiple,
  validationMultipleSelect,
  validationSingleSelect
}: FieldProps<AutocompleteFieldProperties>) {
  const { apiRegistry } = useMetaFormContext();
  const { setValue } = useFormV2Context();

  const prepareText = useCallback(
    (data: SelectOption) => {
      let template = api.fetch.optionTextMatch;

      const keys = template.match(/(?<=\[).+?(?=\])/g);

      keys?.forEach(key => {
        template = template.replaceAll(`[${key}]`, get(omitBy(data, isNull), key, '-') ?? '').trim();
      });

      return template;
    },
    [api.fetch.optionTextMatch]
  );

  const getOption = useCallback(
    (option: SelectOption) => {
      return option?.id
        ? {
            id: option.id,
            value: {
              name: prepareText(option),
              ...option
            },
            name: prepareText(option)
          }
        : null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useGetQuery({
    requestKey: api?.get?.requestKey,
    accessor: api?.get?.accessor,
    apiRegistry,
    setValue: (newValue: SelectOption[] | SelectOption) => {
      const isArray = (arg: SelectOption[] | SelectOption): arg is SelectOption[] => {
        return multiple || Array.isArray(arg);
      };

      if (!newValue) {
        return;
      }

      const prepareValue = isArray(newValue) ? newValue?.map(getOption) : getOption(newValue);
      setValue(fieldId, prepareValue);
    },
    fieldId
  });

  const getOptionLabel = useCallback(option => prepareText(option), [prepareText]);

  const apiEndpoint = apiRegistry[api.fetch?.requestKey] as ApiRegistryGet | null;

  return (
    <AutocompleteLazyFetchSelectField
      name={fieldId}
      label={label}
      queryKey={api.fetch?.requestKey}
      optionLabelParser={getOptionLabel}
      api={{
        FETCH: async (searchText: string, params: LicenseCopySearchFilter) => {
          return apiEndpoint?.request(
            api.fetch.requestKey,
            searchText
              ? {
                  ...params,
                  [api.fetch.backendFilterParamName]: searchText
                }
              : params
          );
        }
      }}
      inputMode={inputMode}
      isRequired={required}
      validationSingleSelect={validationSingleSelect}
      validationMultipleSelect={validationMultipleSelect}
      isMultiple={multiple}
    />
  );
}

export default AutocompleteLazyFetchField;
