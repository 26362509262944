import { useCreateColumns } from '@libs/common/v2';

import { IDepotManagersListClient } from '../../model';

const useResourceAdminsColumns = () => {
  const { createColumns } = useCreateColumns<IDepotManagersListClient>({
    pathToTranslate: 'resource:fields'
  });

  const columns = createColumns([
    {
      id: 'depot',
      accessor: 'depot.user.name',
      header: 'fullName',
      type: 'TEXT',
      isSortable: false
    }
  ]);

  return { columns };
};

export default useResourceAdminsColumns;
