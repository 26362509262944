import axios from 'axios';

interface IDocumentsAPIDownloadParams {
  fileId: string;
}

const API = {
  file: {
    download: (params: IDocumentsAPIDownloadParams, options) => axios.get(`/api/files/${params.fileId}`, options)
  }
};

export default API;
