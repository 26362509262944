import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppConfig } from '@libs/app-config';

import { KeyType } from '@libs/common';
import { IAppNavigation } from '@libs/common/layout';
import { useRouter } from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';

import { useRouterContext } from '../router';

export interface INavigationContext {
  navigation: ISingleNavigation[];
  getNavigation?: () => void;
  setNavigation?: (navigation: ISingleNavigation[]) => void;
  resetNavigation?: () => void;
}

export interface ISingleNavigation {
  id: string;
  title: KeyType | string;
  type?: string;
  icon?: string;
  url?: string;
  children?: ISingleNavigation[];
  auth?: unknown;
  target?: string;
}

interface INavigationContextProvider {
  children: JSX.Element;
  navigationConfig: IAppNavigation[];
  setHiddenRoutePaths?: (value: string[]) => void;
  permissions: unknown[];
}

const initialState: INavigationContext = {
  navigation: []
};

export const NavigationContext = createContext<INavigationContext>(initialState);

function NavigationContextProvider({
  children,
  navigationConfig = [],
  setHiddenRoutePaths,
  permissions
}: INavigationContextProvider) {
  const [providerState, setProviderState] = useState<INavigationContext>(initialState);
  const { hiddenRoutesPaths } = useRouterContext();
  const { mainPage, setMainPage } = useAppConfig();
  const { checkIsElementVisible } = useElementVisibility();
  const { routes } = useRouter();
  useEffect(() => {
    if (!permissions) return;
    const permittedNavigationRoutes = navigationConfig.filter(
      element => !element.viewName || checkIsElementVisible(element.viewName)
    );

    setProviderState(prevState => ({
      ...prevState,
      navigation: permittedNavigationRoutes
    }));

    if (!permittedNavigationRoutes.find(item => item.url === mainPage)) {
      setMainPage(
        permittedNavigationRoutes?.[0]?.url || permittedNavigationRoutes[0]?.children?.[0]?.url || routes.profile()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationConfig, permissions]);
  useEffect(() => {
    if (setHiddenRoutePaths && hiddenRoutesPaths) {
      setHiddenRoutePaths?.(hiddenRoutesPaths);
    }
  }, [setHiddenRoutePaths, hiddenRoutesPaths]);

  const getNavigation = useCallback(() => {
    return providerState.navigation;
  }, [providerState]);

  const setNavigation = useCallback(
    (navigation: ISingleNavigation[]) => {
      setProviderState(prevState => ({ ...prevState, navigation }));
    },
    [setProviderState]
  );

  const resetNavigation = useCallback(() => {
    setProviderState(prevState => ({ ...prevState, navigation: initialState.navigation }));
  }, [setProviderState]);

  const values = useMemo(
    () => ({ ...providerState, getNavigation, setNavigation, resetNavigation }),
    [providerState, getNavigation, setNavigation, resetNavigation]
  );

  return <NavigationContext.Provider value={values}>{children}</NavigationContext.Provider>;
}

export default NavigationContextProvider;
