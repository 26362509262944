import { Theme } from '@enigma/fe-ui';
import { alpha } from '@mui/material/styles';

export const contrastYellow = '#FFF832';
const contrastBlack = '#000000';
const contrastGrey = '#EAECF0';

export const ContrastTheme: Theme = {
  palette: {
    primary: {
      main: contrastYellow,
      light: contrastYellow,
      dark: contrastYellow,
      contrastText: '#fff'
    },
    secondary: {
      main: contrastYellow,
      light: contrastYellow,
      dark: contrastYellow,
      contrastText: contrastYellow
    },
    text: {
      primary: contrastYellow,
      secondary: contrastYellow,
      disabled: contrastYellow
    },
    background: {
      default: contrastBlack,
      paper: contrastBlack
    },
    common: {
      white: contrastBlack,
      black: contrastBlack
    },
    indicators: {
      main: contrastYellow,
      light: contrastYellow,
      dark: contrastYellow
    },
    divider: 'rgba(0,0,0,0.12)',
    grey: {
      '50': contrastBlack,
      '100': contrastBlack,
      '200': contrastGrey,
      '300': contrastYellow,
      '400': contrastYellow,
      '500': contrastYellow,
      '600': contrastBlack,
      '700': contrastYellow,
      '800': contrastYellow,
      '900': contrastYellow
    }
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          '&.Mui-disabled': {
            color: theme.palette.text.primary,
            opacity: '0.2'
          }
        })
      }
    },

    MuiSwitch: {
      styleOverrides: {
        root: ({ theme }) => ({
          width: 36,
          height: 20,
          padding: 0,
          display: 'flex',
          marginRight: 14,
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(16px)'
          },
          '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: theme.palette.common.white,
              '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.indicators.main
              }
            },
            '&.Mui-disabled': {
              backgroundColor: theme.palette.grey[200],
              '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.grey[200]
              }
            }
          },
          '& .MuiSwitch-thumb': {
            width: 16,
            height: 16,
            borderRadius: '50%'
          },
          '& .MuiSwitch-track': {
            borderRadius: theme.shape.borderRadius * 1.5,
            backgroundColor: theme.palette.grey[200]
          }
        })
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&:hover': {
            boxShadow: 'none'
          },
          '& .MuiSvgIcon-root': {
            color: theme.palette.primary.main
          }
        })
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiInputLabel-root.Mui-focused': {
            color: theme.palette.text.primary
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: theme.palette.primary.main
            },
            '&:hover fieldset': {
              borderColor: theme.palette.primary.main
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.palette.primary.main
            },
            '&.Mui-disabled fieldset': {
              borderColor: theme.palette.grey?.[200]
            }
          }
        })
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .Mui-disabled': {
            color: theme.palette.grey?.[200]
          },
          '& button:not(.Mui-disabled)': {
            color: theme.palette.primary?.main
          },
          '& div[class*="root-MuiTablePagination-select"]': {
            '&:hover': {
              boxShadow: 'none'
            },
            '&.Mui-focused': {
              outline: 'unset !important',
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none'
              }
            }
          }
        })
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiMenuItem-root': {
            '&:hover': {
              backgroundColor: alpha(theme.palette.text.primary, 0.2)
            }
          }
        })
      }
    }
  }
};
