import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function inabilityDeclare(proceedingId: string) {
  return API.proceedingsKreptd.proceedingKreptdDeclarationOfInability(proceedingId, {
    ...endpointsConfig.proceedingKreptdDeclarationOfInability
  });
}

function useDeclarationOfInabilityMutation() {
  return useMutation(inabilityDeclare);
}

export default useDeclarationOfInabilityMutation;
