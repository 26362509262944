import { Context, useContext, useEffect } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import clsx from 'clsx';

import {
  BooleanValue,
  ComponentErrorBoundary,
  FormErrorType,
  FormV2Context,
  FormV2ContextState,
  getInputMode,
  InputMode,
  SwitchInput
} from '@libs/common/v2';

import { HasPermission, UIElementNameEnum } from '@libs/permission';

import { BooleanFieldValidation, FieldTypeEnum, useFieldValidationHandler } from '../../validation';

export interface SwitchFieldProps {
  name: FieldPath<FieldValues>;
  label?: string;
  inlineLabel?: string;
  className?: string;
  valueClassName?: string;
  valueTrueText?: string;
  valueFalseText?: string;
  formContext?: Context<FormV2ContextState>;
  inputMode?: InputMode;
  helperText?: string;
  isDefaultChecked?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isHidden?: boolean;
  validation?: BooleanFieldValidation;
  isTableCell?: boolean;
  isHelperTextWarning?: boolean;
  tooltip?: string;
  onCustomChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  actionKey?: UIElementNameEnum;
}

function SwitchField({
  name,
  label,
  inlineLabel,
  formContext = FormV2Context,
  className,
  valueClassName,
  valueTrueText,
  valueFalseText,
  helperText,
  isDefaultChecked,
  inputMode,
  isDisabled,
  isHidden,
  isRequired,
  validation,
  isTableCell,
  isHelperTextWarning,
  tooltip,
  onCustomChange,
  actionKey
}: SwitchFieldProps) {
  const { control, formMode, loading, setValue, isSubmitting } = useContext<FormV2ContextState>(formContext);
  const mode = getInputMode(formMode, inputMode);

  useEffect(() => {
    if (isDefaultChecked) {
      setValue(name, isDefaultChecked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useFieldValidationHandler({
    fieldName: name,
    validation,
    fieldType: FieldTypeEnum.BOOLEAN
  });

  return (
    <HasPermission actionKeys={[actionKey]}>
      <ComponentErrorBoundary componentName={label || name || 'SwitchField'}>
        <Controller
          control={control}
          name={name as FieldPath<FieldValues>}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) =>
            mode === InputMode.FORM ? (
              <SwitchInput
                isDefaultChecked={!!value}
                onBlur={onBlur}
                name={name}
                onChange={(event, checked) => {
                  if (onCustomChange) {
                    onCustomChange(event, checked);
                  }
                  onChange(event);
                }}
                label={label}
                inlineLabel={inlineLabel}
                isRequired={isRequired || Boolean(validation?.required)}
                isDisabled={isDisabled || isSubmitting}
                isError={!!error && error?.type !== FormErrorType.WARNING}
                isWarning={error?.type !== FormErrorType.WARNING || isHelperTextWarning}
                helperText={error?.message || helperText}
                className={clsx(className, isHidden && 'hidden', isTableCell && 'z-0')}
                valueTrueText={valueTrueText}
                valueFalseText={valueFalseText}
                tooltip={tooltip}
              />
            ) : (
              <BooleanValue
                label={label}
                value={value}
                isLoading={loading}
                className={clsx(valueClassName, isHidden && 'hidden')}
                valueFalseText={valueFalseText}
                valueTrueText={valueTrueText}
                isError={!!error && error?.type !== FormErrorType.WARNING}
                isWarning={error?.type !== FormErrorType.WARNING || isHelperTextWarning}
                helperText={error?.message || helperText}
                isTableCell={isTableCell}
              />
            )
          }
        />
      </ComponentErrorBoundary>
    </HasPermission>
  );
}

export default SwitchField;
