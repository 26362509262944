import { useSelector } from 'react-redux';

import { ExtendedPersonalData } from '../models/user.model';

function useUser(): ExtendedPersonalData {
  const { personalData } = useSelector(({ auth }) => auth.user);

  return personalData;
}

export default useUser;
