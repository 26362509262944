import { useTranslation } from 'react-i18next';
import {
  DefaultApiFindMyReplacementsRequest,
  GroupMembershipDetailsExternal,
  PageGroupMembershipDetailsExternal
} from '@avispon/group';
import { Grid } from '@mui/material';
import { AxiosResponse } from 'axios';

import {
  NUMBER_OF_AUTOCOMPLETE_RESULTS,
  SelectOption,
  TableFilter,
  TableFilterMapper,
  TableSortMapper,
  useCreateColumns
} from '@libs/common/v2';
import { convertDateToDateWithoutTimeWithDayOffset, DateRangeISO, getValue } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';
import { API, UserDictionaryEntryNameEnum, UserFilterV2 } from '@libs/user';
import { ReplacementsSnapshot } from '@libs/user/models/replacements.model';

const GroupsMembershipsList = ({ groupMemberships }: { groupMemberships: GroupMembershipDetailsExternal[] }) => {
  const [t] = useTranslation();

  if (!groupMemberships?.length) {
    return <>{t('emptyMark')}</>;
  }

  return (
    <>
      {groupMemberships.slice(0, 2).map(({ group }) => (
        <Grid item key={group?.id}>
          {group?.name}
        </Grid>
      ))}
    </>
  );
};

export const useReplacementsColumns = () => {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();
  const { createColumns } = useCreateColumns<ReplacementsSnapshot>({
    pathToTranslate: 'user:replacements.field'
  });

  const prepareGroupOptions = (response: AxiosResponse<PageGroupMembershipDetailsExternal>) => {
    return response.data.content.map(group => ({
      value: group.group.id,
      name: group.group.name
    }));
  };

  const mappedSortFields: TableSortMapper<ReplacementsSnapshot> = {
    substituteName: 'substitute.name'
  };

  const mappedFilterFields: TableFilterMapper<ReplacementsSnapshot, DefaultApiFindMyReplacementsRequest> = {
    statusKey: (statusKeys: SelectOption<string>[]) => ({
      statusKeys: statusKeys.map(({ value }) => value)
    }),
    startDateTime: ({ dateFrom, dateTo }: DateRangeISO) => ({
      startDateTimeFrom: convertDateToDateWithoutTimeWithDayOffset(dateFrom || ''),
      startDateTimeTo: convertDateToDateWithoutTimeWithDayOffset(dateTo || '')
    }),
    endDateTime: ({ dateFrom, dateTo }: DateRangeISO) => ({
      endDateTimeFrom: convertDateToDateWithoutTimeWithDayOffset(dateFrom || ''),
      endDateTimeTo: convertDateToDateWithoutTimeWithDayOffset(dateTo || '')
    }),
    substituteName: (substituteNames: SelectOption<string>[]) => ({
      substituteIds: substituteNames.map(({ value }) => value)
    }),
    groupMemberships: (groupMemberships: SelectOption<string>[]) => ({
      groupIds: groupMemberships.map(({ value }) => value)
    }),
    modified: ({ dateFrom, dateTo }: DateRangeISO) => ({ modifiedFrom: dateFrom || '', modifiedTo: dateTo || '' })
  };

  const columns = createColumns([
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: UserDictionaryEntryNameEnum.REPLACEMENT_STATUS,
      accessor: 'statusKey',
      customXlsxAccessor: ({ statusKey }) =>
        translate(UserDictionaryEntryNameEnum.REPLACEMENT_STATUS, statusKey, t('emptyMark')),
      width: 200
    },
    {
      type: 'DATE',
      accessor: 'startDateTime'
    },
    {
      type: 'DATE',
      accessor: 'endDateTime'
    },
    {
      type: 'TEXT',
      header: 'substitute',
      accessor: 'substitute.name',
      customXlsxAccessor: ({ substitute }) => getValue(substitute?.name),
      filter: UserFilterV2,
      width: 200
    },
    {
      id: 'groupMemberships',
      type: 'CUSTOM_COLUMN',
      header: 'groupMemberships',
      tooltipTitle: ({ groupMemberships }) => groupMemberships?.map(({ group }) => group?.name).join(', '),
      customAccessor: ({ groupMemberships }) => (
        <Grid container direction="column">
          <GroupsMembershipsList groupMemberships={groupMemberships} />
        </Grid>
      ),
      customXlsxAccessor: ({ groupMemberships }) => groupMemberships?.map(({ group }) => group?.name).join(', '),
      filter: props => (
        <TableFilter.MultiSelectFetch
          {...props}
          fetchFunctionResolver={(params: string) => {
            return API.group.listMyGroupMemberships({
              page: 0,
              size: NUMBER_OF_AUTOCOMPLETE_RESULTS,
              groupName: params
            });
          }}
          fetchedDataSelectParser={prepareGroupOptions}
        />
      ),
      isSortable: false,
      width: 200
    },
    {
      type: 'DATE',
      accessor: 'modified'
    }
  ]);

  return { columns, mappedSortFields, mappedFilterFields };
};
