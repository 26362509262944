import { usePaginatedQuery } from 'react-query';
import { ResourceObjectPoolSearchFilter } from '@ibtm/domain/dist/models';

import { API } from '@libs/domain/api';

import { ResourceObjectGapAndCorrectPoolClient } from '../../../model';
import { resourceObjectGapAndCorrectPoolsParser } from '../../../utils';
import { endpointsConfig } from '../../endpoints-config';
import { ResourceQueryKeysEnum } from '..';

const getResourceObjectGapAndCorrectPools = async (params: ResourceObjectPoolSearchFilter) => {
  const { data } = await API.resourceObjectPools.getResourceObjectPoolSnapshot(params, {
    ...endpointsConfig.getResourceObjectGapAndCorrectPoolSnapshot
  });

  return {
    ...data,
    content: resourceObjectGapAndCorrectPoolsParser(data.content)
  };
};

export default function useResourceObjectGapAndCorrectPoolsQuery(params: ResourceObjectPoolSearchFilter) {
  return usePaginatedQuery<{ content: ResourceObjectGapAndCorrectPoolClient[] }>(
    [ResourceQueryKeysEnum.RESOURCE_OBJECT_POOLS, params],
    () => getResourceObjectGapAndCorrectPools(params)
  );
}
