import { useParams } from 'react-router-dom';

import { DomainUIElementEnum } from '@libs/domain/config';
import { SuspensionDetailsParams } from '@libs/domain/folder';
import { ReleaseDocumentsTablePage, useSuspensionReleaseDocumentsQuery } from '@libs/domain/release-documents';

function ReleaseDocumentsTab() {
  const { id, folderId } = useParams<SuspensionDetailsParams>();

  return (
    <ReleaseDocumentsTablePage
      isSection
      tableHookQuery={useSuspensionReleaseDocumentsQuery}
      suspensionId={id}
      folderId={folderId}
      tableActionKeys={[
        DomainUIElementEnum.SUSPENSIONS_RELEASE_DOCUMENTS_DETAILS_BUTTON,
        DomainUIElementEnum.SUSPENSIONS_CORRECTION_CREATE,
        DomainUIElementEnum.SUSPENSIONS_CORRECTION_UPDATE,
        DomainUIElementEnum.SUSPENSIONS_CORRECTION_CANCEL,
        DomainUIElementEnum.SUSPENSIONS_RELEASE_DOCUMENTS_DOWNLOAD_BUTTON
      ]}
    />
  );
}

export default ReleaseDocumentsTab;
