import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AccountSnapshot } from '@avispon/user/dist/models';

import {
  Button,
  DictionarySelectField,
  GridItem,
  GridLayout,
  InputMode,
  Section,
  TextInputField,
  typedNameV2,
  useFormV2Context,
  ValidatorEnums
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { OrganizationStructureUIElementEnum } from '@libs/organization-structure/config';
import { UserDictionaryEntryNameEnum } from '@libs/user/common';
import { ChangeUnitForm } from '@libs/user/components';
import { SectionNames, useConfigurationContext } from '@libs/user/context';
import { useUserCustomConfiguration } from '@libs/user/hooks';
import { IUserClientListParams } from '@libs/user/models';

interface IProps {
  profilePage?: boolean;
}

function OrganizationDataSection({ profilePage }: IProps) {
  const [t] = useTranslation();
  const { id } = useParams<IUserClientListParams>();
  const { getValues, formMode } = useFormV2Context();
  const values = getValues();
  const [isChangeUnitDialogOpen, setIsChangeUnitDialogOpen] = useState(false);
  const { isFieldVisible, renderAdditionalFields, isSectionVisible } = useConfigurationContext();
  const { isUsingServiceNumber, isUsingEmailAsLogin } = useUserCustomConfiguration();

  const { viewMode } = useViewModesV2(formMode);

  return (
    <Section title={t('user:section.organization')} isCollapsable>
      {viewMode ? (
        <GridLayout>
          {isFieldVisible('position') && (
            <GridItem xs={12}>
              <DictionarySelectField
                inputMode={InputMode.VIEW}
                label={t('user:field.position')}
                name={typedNameV2<AccountSnapshot>('positionKey')}
                dictionaryName={UserDictionaryEntryNameEnum.POSITION_TYPE}
                stringValue
              />
            </GridItem>
          )}
          {!isSectionVisible(SectionNames.LOGIN_SECTION) && isFieldVisible('serviceNumber') && isUsingServiceNumber && (
            <GridItem xs={12}>
              <TextInputField
                inputMode={InputMode.VIEW}
                label={t('user:field.serviceNumber')}
                name={typedNameV2<AccountSnapshot>('serviceNumber')}
              />
            </GridItem>
          )}
          {isFieldVisible('organizationUnit') && (
            <GridItem xs={12}>
              <div className="flex items-center justify-between">
                <TextInputField
                  inputMode={InputMode.VIEW}
                  label={t('user:field.organizationUnit')}
                  name={typedNameV2<AccountSnapshot>('organizationUnit.name')}
                />

                {!profilePage && values.isEditable && (
                  <>
                    <Button
                      onClick={() => setIsChangeUnitDialogOpen(true)}
                      label={t('user:action.changeUnit')}
                      variant="outlined"
                      actionKey={OrganizationStructureUIElementEnum.ORGANIZATION_STRUCTURE_CHANGE_UNIT_BUTTON}
                    />
                    <ChangeUnitForm
                      userId={id}
                      unitId={values?.organizationUnit?.organizationUnitId}
                      unitName={values?.organizationUnit?.name}
                      open={isChangeUnitDialogOpen}
                      setIsOpen={setIsChangeUnitDialogOpen}
                    />
                  </>
                )}
              </div>
            </GridItem>
          )}
          {isFieldVisible('organizationUnit') && (
            <GridItem xs={12}>{renderAdditionalFields(SectionNames.ORGANIZATION_DATA_SECTION)}</GridItem>
          )}
        </GridLayout>
      ) : (
        <GridLayout>
          {isFieldVisible('position') && (
            <GridItem xs={12}>
              <DictionarySelectField
                inputMode={InputMode.FORM}
                label={t('user:field.position')}
                name={typedNameV2<AccountSnapshot>('positionKey')}
                dictionaryName={UserDictionaryEntryNameEnum.POSITION_TYPE}
                stringValue
                isRequired
              />
            </GridItem>
          )}
          {isSectionVisible(SectionNames.LOGIN_SECTION) && isFieldVisible('serviceNumber') && isUsingServiceNumber && (
            <GridItem xs={12}>
              <TextInputField
                inputMode={InputMode.FORM}
                label={t('user:field.serviceNumber')}
                name={typedNameV2<AccountSnapshot>('serviceNumber')}
                isRequired
              />
            </GridItem>
          )}
          {isSectionVisible(SectionNames.LOGIN_SECTION) && isFieldVisible('email') && isUsingEmailAsLogin && (
            <GridItem xs={12}>
              <TextInputField
                inputMode={InputMode.FORM}
                label={t('user:field.email')}
                name={typedNameV2<AccountSnapshot>('email')}
                isRequired
                validation={{ contextValidator: [ValidatorEnums.EMAIL] }}
              />
            </GridItem>
          )}
          <GridItem xs={12}>{renderAdditionalFields(SectionNames.ORGANIZATION_DATA_SECTION)}</GridItem>
        </GridLayout>
      )}
    </Section>
  );
}

export default OrganizationDataSection;
