import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReplacementCreateRequest } from '@avispon/group/dist/models';

import { AutocompleteLazyFetchSelectField, InputMode, typedNameV2 } from '@libs/common/v2';

import { API, UserQueryKeysEnum } from '../../api';

function GroupSelectField({ inputMode }: { inputMode: InputMode }) {
  const [t] = useTranslation();

  const getOptionLabel = option => {
    return option?.group?.name || '';
  };

  return (
    <AutocompleteLazyFetchSelectField
      isMultiple
      isRequired
      name={typedNameV2<ReplacementCreateRequest>('groupMembershipsIds')}
      label={t('user:replacements.field.groupMembershipsIds')}
      optionLabelParser={getOptionLabel}
      inputMode={inputMode}
      queryKey={UserQueryKeysEnum.PROFILE_GROUP_PERMISSIONS_CONTROLLER_FIELD}
      api={{
        FETCH: (searchText: string, params: { page: number; size: number }) => {
          return API.group.listMyGroupMemberships({
            ...params,
            groupName: searchText,
            sort: []
          });
        }
      }}
    />
  );
}

export default GroupSelectField;
