import { MutationConfig } from 'react-query';
import { DocumentNoteCreateRequest, DocumentNoteDetails } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

type CreateParcelNoteRequest = {
  documentId: string;
  formData: DocumentNoteCreateRequest;
};

async function createDocumentNote({ documentId, formData }: CreateParcelNoteRequest) {
  const { data } = await API.documents.createDocumentNote(documentId, formData, {
    ...endpointsConfig.createDocumentNote
  });
  return data;
}

export function useCreateDocumentParcelNoteMutation(config?: MutationConfig<DocumentNoteDetails>) {
  return useMutation(createDocumentNote, config);
}
