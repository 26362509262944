import { useTranslation } from 'react-i18next';
import { Table } from '@enigma/fe-ui';
import { HeaderActions } from '@enigma/fe-ui/lib/display/table';
import { ForeignPermissionsPrintsSnapshot, PermissionTypeCode } from '@ibtm/domain';

import { API } from '@libs/domain/api';
import { DomainDictionaryEntry } from '@libs/domain/config';

import { endpointsConfig, PrintsQueryKeysEnum } from '../../api';
import { PrintActionType, usePrintsActionsContext, usePrintsDetailsDataContext } from '../../context';
import usePrintActionHandler from '../../hooks/usePrintActionHandler';
import usePrintForeignPermissionColumn, {
  FOREIGN_PERMISSION_DEFAULT_COLUMNS
} from '../../hooks/usePrintForeignPermissionColumn';
import { PrintForeignPermissionColumnsEnum } from '../../model/print-model';

interface IProps {
  applicationId: string;
  headerActions?: HeaderActions<ForeignPermissionsPrintsSnapshot>;
  visibleColumns?: Array<PrintForeignPermissionColumnsEnum>;
}

export function PrintForeignPermissionTable({
  applicationId,
  headerActions,
  visibleColumns = FOREIGN_PERMISSION_DEFAULT_COLUMNS
}: IProps) {
  const [t] = useTranslation();
  const { columns } = usePrintForeignPermissionColumn({ visibleColumns });
  const { print } = usePrintActionHandler();

  const { setAction } = usePrintsActionsContext();
  const {
    printDetailsData: {
      applicationSnapshot,
      depotMP: { id: depotMPId },
      notDepotMPTooltip
    }
  } = usePrintsDetailsDataContext();
  const isDisabled = !depotMPId;

  const isEkmtPermissionApplication =
    applicationSnapshot?.typeKey === DomainDictionaryEntry.APPLICATION_TYPE.foreignPermitEkmt;

  return (
    <Table<ForeignPermissionsPrintsSnapshot & { id: string }>
      tableTitle={t('foreignPermits:list.title')}
      columns={columns}
      query={{
        request: async () => {
          const { data } = await API.foreignPermissions.getForeignPermissionPrintsSnapshotList(applicationId, {
            ...endpointsConfig.getForeignPermissionPrintsSnapshotList
          });

          const dataWithIds = data?.content?.map((item, index) => ({ id: index.toString(), ...item }));

          return { data: { ...data, content: dataWithIds } };
        },
        queryKey: PrintsQueryKeysEnum.PRINTS_FOREIGN_PERMISSION_LIST
      }}
      headerActions={headerActions}
      rowActions={[
        {
          label: t('prints:actions.regenerate'),
          icon: 'CalculatorIcon',
          onClick: ({ rowData, refetch }) =>
            setAction(PrintActionType.RegenerateForeignPermissionNumber, {
              foreignPermissionsPrintsSnapshot: rowData,
              onSuccess: refetch
            }),
          tooltip: isDisabled ? notDepotMPTooltip : t('prints:actions.regenerate'),
          isDisabled
        },
        {
          icon: 'PrintIcon',
          label: t('action.print'),
          isHidden: isEkmtPermissionApplication,
          onClick: ({ rowData, refetch, setLoading }) => {
            setLoading(true);
            print(
              {
                permissionTypeCode: PermissionTypeCode.FOREIGN_PERMISSION,
                foreignPermissionRequests: [
                  {
                    resourceTypeId: rowData?.resourceTypeId,
                    firstFormNumber: rowData?.firstNumber,
                    lastFormNumber: rowData?.lastNumber
                  }
                ]
              },
              {
                onSettled: () => {
                  setLoading(false);
                  refetch();
                }
              }
            );
          }
        }
      ]}
      isFilterable={false}
    />
  );
}
