import { useEffect, useMemo } from 'react';
import { FieldValues, useForm, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { NoteDetails } from '@ibtm/domain';
import * as Yup from 'yup';

import {
  FormMode,
  FormV2Context,
  GridLayout,
  Section,
  TextInputField,
  TextValueField,
  typedNameV2
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { UIElementNameEnum } from '@libs/permission';

import { INoteData, RemarksHeaderActions } from '@libs/domain/notes';
import { useProceedingNoteQuery } from '@libs/domain/proceeding';
import { useSuspensionNoteQuery } from '@libs/domain/suspensions/api';

interface IProps {
  formMode: FormMode;
  proceedingId?: string;
  suspensionId?: string;
  noteData?: INoteData;
  onNoteOrVersionChange?: (note: string, version: number, noteId: string) => void;
  cancelActionKey?: UIElementNameEnum;
  setParentFormValues?: UseFormSetValue<FieldValues>;
}

const noteSchema = Yup.object({
  content: Yup.string().max(5000)
});

function RemarksSection({
  formMode,
  proceedingId,
  suspensionId,
  noteData,
  onNoteOrVersionChange,
  cancelActionKey,
  setParentFormValues
}: IProps) {
  const [t] = useTranslation();
  const { data: suspensionNote, isLoading: isSuspensionNoteLoading } = useSuspensionNoteQuery(suspensionId, {
    enabled: Boolean(suspensionId)
  });
  const { data: proceedingNote, isLoading: isProceedingNoteLoading } = useProceedingNoteQuery(proceedingId, {
    enabled: Boolean(proceedingId)
  });

  const { editMode } = useViewModesV2(formMode);

  const isLoading = useMemo(
    () => isSuspensionNoteLoading || isProceedingNoteLoading,
    [isSuspensionNoteLoading, isProceedingNoteLoading]
  );

  const form = useForm<Record<string, any>>({
    resolver: yupResolver(noteSchema),
    defaultValues: { content: '', version: null },
    mode: 'onBlur'
  });

  const label = useMemo(() => {
    if (proceedingId) {
      return t('proceeding:administrative.tab.note.title');
    }

    return t('suspensions:notes.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proceedingId]);

  const formDataProvider = useMemo(() => ({ ...form, formMode, loading: isLoading }), [form, formMode, isLoading]);

  const note = form.watch(typedNameV2<NoteDetails>('content')) as string;
  const version = form.watch(typedNameV2<NoteDetails>('version')) as number;

  useEffect(() => {
    onNoteOrVersionChange(note, version, suspensionNote?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note, version]);

  useEffect(() => {
    resetNote();

    if (suspensionNote) {
      form.reset({
        content: suspensionNote.content,
        version: suspensionNote.version
      });
    }

    if (noteData) {
      form.reset(noteData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proceedingNote, suspensionNote]);

  const resetNote = () => {
    form.reset({
      content: suspensionNote?.content || proceedingNote?.content,
      version: suspensionNote?.version || proceedingNote?.version
    });

    setParentFormValues?.('isNoteChanged', false);
  };

  useEffect(() => {
    setParentFormValues?.('isNoteChanged', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormV2Context.Provider value={formDataProvider}>
      <Section
        title={label}
        headerContent={
          editMode && (
            <RemarksHeaderActions
              proceedingId={proceedingId}
              suspensionId={suspensionId}
              remarkExists={!!suspensionNote?.id || !!proceedingNote?.id}
              resetNote={resetNote}
              cancelActionKey={cancelActionKey}
            />
          )
        }
      >
        <GridLayout itemProps={{ xs: 12 }}>
          {editMode ? (
            <TextInputField
              label={t('suspensions:notes.field.content')}
              name={typedNameV2<NoteDetails>('content')}
              lines={5}
              {...(setParentFormValues && { customHandleChange: () => setParentFormValues?.('isNoteChanged', true) })}
            />
          ) : (
            <TextValueField label={t('suspensions:notes.field.content')} name={typedNameV2<NoteDetails>('content')}>
              {(content: string) => <div className="whitespace-pre-wrap">{content}</div>}
            </TextValueField>
          )}
        </GridLayout>
      </Section>
    </FormV2Context.Provider>
  );
}

export default RemarksSection;
