import { MisspellApplicationDataDriverCertificateDetails } from '@ibtm/domain';

import { useCreateColumns } from '@libs/common/v2';

export function useMisspellApplicationDriverCertificateColumn() {
  const { createColumns } = useCreateColumns<MisspellApplicationDataDriverCertificateDetails>({
    pathToTranslate: 'applications:misspellApplication.driverCertificateFields'
  });

  const columns = createColumns([
    {
      accessor: 'reprint',
      type: 'BOOLEAN',
      isSortable: false
    },
    {
      accessor: 'certificateUpdated.applicationNumber',
      header: 'applicationNumber',
      type: 'TEXT',
      isSortable: false
    },
    {
      accessor: 'certificateUpdated.number',
      header: 'number',
      type: 'TEXT',
      isSortable: false
    },
    {
      accessor: 'certificateUpdated.formNumber',
      header: 'formNumber',
      type: 'TEXT',
      isSortable: false
    },
    {
      accessor: 'certificateUpdated.dateOfIssue',
      header: 'dateOfIssue',
      type: 'TEXT',
      isSortable: false
    },
    {
      accessor: 'certificateUpdated.validFrom',
      header: 'validFrom',
      type: 'TEXT',
      isSortable: false
    },
    {
      accessor: 'certificateUpdated.validTo',
      header: 'validTo',
      type: 'TEXT',
      isSortable: false
    },
    {
      accessor: 'certificateUpdated.subjectName',
      header: 'subjectName',
      type: 'TEXT',
      isSortable: false
    },
    {
      type: 'TEXT',
      header: 'subjectAddress',
      customAccessor: row => {
        const {
          subjectPostCode,
          subjectPostCity,
          subjectCity,
          subjectStreet,
          subjectApartmentNumber,
          subjectPropertyNumber
        } = row.certificateUpdated ?? {};
        const post = `${subjectPostCode} ${subjectPostCity}`;
        const cityAndStreet = `${subjectCity} ${subjectStreet}`;
        const numbers = subjectApartmentNumber
          ? `${subjectPropertyNumber}/${subjectPropertyNumber}`
          : subjectPropertyNumber;

        return [post, cityAndStreet, numbers].join(',').trim();
      },
      isSortable: false
    },
    {
      accessor: 'certificateUpdated.insurancePayerNip',
      header: 'insurancePayerNip',
      type: 'TEXT',
      isSortable: false
    }
  ]);
  return { columns };
}
