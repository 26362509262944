import { useTableLayoutProps } from '@libs/common/v2';

import { ForeignPermissionTransportApplicationRecordUpdateRequestExtended } from '../components/SinglePermitTable';

import useSinglePermitColumns from './useSinglePermitColumns';

function useSinglePermitTable(
  data?: ForeignPermissionTransportApplicationRecordUpdateRequestExtended[],
  applicationYear?: number
) {
  const { columns } = useSinglePermitColumns(applicationYear);
  return useTableLayoutProps({
    tableQuery: data || [],
    tableHookOptions: {
      columns,
      tableOptions: {
        manualSortBy: false
      }
    }
  });
}
export default useSinglePermitTable;
