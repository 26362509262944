import { QueryConfig, usePaginatedQuery } from 'react-query';
import { SingleUsePermissionSubmissionSearchFilter, SingleUsePermissionSubmissionSnapshotPage } from '@ibtm/domain';

import { API } from '@libs/domain/api';
import { SocialCommissionQueryKeysEnum } from '@libs/domain/social-commission';

import { endpointsConfig } from '../endpoints-config';

export const getCommisionApplication = async (params: SingleUsePermissionSubmissionSearchFilter) => {
  const { data } = await API.civicBoard.getSingleUsePermissionSubmissionSnapshotPage(params, {
    ...endpointsConfig.getSingleUseSubmissionItemDetailsPage
  });

  return data;
};

function useCommissionApplicationQuery(
  params: SingleUsePermissionSubmissionSearchFilter,
  queryConfig: QueryConfig<SingleUsePermissionSubmissionSnapshotPage, unknown> = {}
) {
  return usePaginatedQuery(
    [SocialCommissionQueryKeysEnum.SINGLE_USE_SUBMISSION_DETAILS, params],
    () => getCommisionApplication(params),
    queryConfig
  );
}
export default useCommissionApplicationQuery;
