import { DefaultApiGetTemplateFileRequest } from '@avispon/document-generator';
import { DocumentTemplateFileDetailsExternal } from '@avispon/document-generator/dist/models/document-template-file-details-external';

import { SelectOption, TableFilterMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO } from '@libs/common/v2/utils';

import { DocumentTemplateDictionaryEntryNameEnum } from '@libs/document-template';

function useDocumentTemplateVersionColumns() {
  const { createColumns } = useCreateColumns<DocumentTemplateFileDetailsExternal>({
    pathToTranslate: 'documentTemplates:field'
  });

  const mappedFilterFields: TableFilterMapper<DocumentTemplateFileDetailsExternal, DefaultApiGetTemplateFileRequest> = {
    displayName: (value: string) => ({ displayName: value }),
    fileStatus: (statusKey: SelectOption<string>[]) => ({ fileStatusKeys: statusKey.map(({ value }) => value) }),
    created: ({ dateFrom, dateTo }: DateRangeISO) => ({ createdFrom: dateFrom || '', createdTo: dateTo || '' }),
    modified: ({ dateFrom, dateTo }: DateRangeISO) => ({ modifiedFrom: dateFrom || '', modifiedTo: dateTo || '' })
  };

  const columns = createColumns([
    {
      id: 'displayName',
      header: 'name',
      type: 'TEXT'
    },
    {
      id: 'created',
      type: 'DATE_TIME'
    },
    {
      id: 'fileStatus',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DocumentTemplateDictionaryEntryNameEnum.DOCUMENT_TEMPLATE_FILE_STATUS
    },
    {
      id: 'modified',
      type: 'DATE_TIME'
    }
  ]);

  return { columns, mappedFilterFields };
}

export default useDocumentTemplateVersionColumns;
