import { ForeignPermissionApplicationUpdateRequest as ForeignPermissionApplicationUpdateRequestClient } from '@ibtm/client-domain';
import { ForeignPermissionApplicationUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export function updateForeignPermissionApplicationClient({
  applicationId,
  foreignPermissionApplicationRecordsUpdateRequest
}: {
  applicationId: string;
  foreignPermissionApplicationRecordsUpdateRequest: {
    records?: ForeignPermissionApplicationUpdateRequest['records'];
    applicationUpdateRequest?: ForeignPermissionApplicationUpdateRequestClient['applicationUpdateRequest'];
    association?: ForeignPermissionApplicationUpdateRequest['association'];
  };
}) {
  return API.client.applicationForeignPermissions.update(
    applicationId,
    '',
    '',
    foreignPermissionApplicationRecordsUpdateRequest,
    { ...endpointsConfig.updateForeignPermission }
  );
}

export function updateForeignPermissionApplication({
  applicationId,
  foreignPermissionApplicationRecordsUpdateRequest
}: {
  applicationId: string;
  foreignPermissionApplicationRecordsUpdateRequest: ForeignPermissionApplicationUpdateRequest;
}) {
  return API.applicationForeignPermissions.update(applicationId, foreignPermissionApplicationRecordsUpdateRequest, {
    ...endpointsConfig.updateForeignPermission
  });
}

export function useUpdateForeignPermissionApplicationMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(updateForeignPermissionApplicationClient, updateForeignPermissionApplication));
}
