import { Hooks } from 'react-table';

export const useActionColumn = <T extends Record<string, any> = Record<string, unknown>>(hooks: Hooks<T>) => {
  hooks.visibleColumns.push(columns => [
    ...columns,
    {
      id: 'action',
      disableSortBy: true
    }
  ]);
};

useActionColumn.pluginName = 'useActionColumn';

export default useActionColumn;
