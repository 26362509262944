import { NamedObjectDetails, ResourceObjectLite } from '@ibtm/domain';
import { CalendarDate } from 'calendar-date';

import { SelectOption } from '@libs/common/v2';

export interface LicenseSnapshotClient {
  id: string;
  applicationNumber: string;
  cancellationReason: string;
  cancelledBy: string;
  cancellationDate: string;
  subjectName: string;
  subjectAddress: string;
  licenseNumber: string;
  resourceAssignmentRegenerationNeeded: boolean;
  formNumber: string;
  printDate: string;
  validFrom: string;
  validTo: string;
  dateOfIssueExternal: string;
  signerName: string;
  status: string;
  licenseValidityYearsKey: string | SelectOption<string>;
  version: number;
  file?: NamedObjectDetails;
  form?: ResourceObjectLite;
  locksToSetUp?: number;
  issuedLocks?: number;
  permissionsGenerated?: boolean;
}

export interface LicenseCopySnapshotClient {
  id?: string;
  applicationNumber: string;
  cancellationReason: string;
  cancelledBy: string;
  cancellationDate: CalendarDate;
  subjectName: string;
  subjectAddress: string;
  licenseNumber: string;
  formNumber: string;
  printDate: CalendarDate;
  validFrom: CalendarDate;
  validTo: CalendarDate;
  dateOfIssueExternal: CalendarDate;
  resourceAssignmentRegenerationNeeded: boolean;
  signerName: string;
  belowThreeAndHalfTons: boolean;
  status: string;
  licenseValidityYearsKey: string;
  version: number;
  file?: NamedObjectDetails;
  form?: ResourceObjectLite;
}

export enum LicensesColumnsEnum {
  APPLICATION_NUMBER = 'applicationNumber',
  CANCELLATION_REASON = 'cancellationReason',
  CANCELLED_BY = 'cancelledBy',
  CANCELLATION_DATE = 'cancellationDate',
  SUBJECT_NAME = 'subjectName',
  SUBJECT_ADDRESS = 'subjectAddress',
  LICENSE_NUMBER = 'licenseNumber',
  FORM_NUMBER = 'formNumber',
  PRINT_DATE = 'printDate',
  VALID_FROM = 'validFrom',
  VALID_TO = 'validTo',
  DATE_OF_ISSUE_EXTERNAL = 'dateOfIssueExternal',
  LICENSE_VALIDITY_YEARS_KEY = 'licenseValidityYearsKey',
  SIGNER_NAME = 'signerName',
  STATUS = 'status'
}

export enum LicenseCopiesColumnsEnum {
  APPLICATION_NUMBER = 'applicationNumber',
  CANCELLATION_REASON = 'cancellationReason',
  CANCELLED_BY = 'cancelledBy',
  CANCELLATION_DATE = 'cancellationDate',
  SUBJECT_NAME = 'subjectName',
  SUBJECT_ADDRESS = 'subjectAddress',
  LICENSE_NUMBER = 'licenseNumber',
  FORM_NUMBER = 'formNumber',
  PRINT_DATE = 'printDate',
  VALID_FROM = 'validFrom',
  VALID_TO = 'validTo',
  DATE_OF_ISSUE_EXTERNAL = 'dateOfIssueExternal',
  SIGNER_NAME = 'signerName',
  BELOW_THREE_AND_HALF_TONS = 'belowThreeAndHalfTons',
  STATUS = 'status'
}

export enum LicenseStatusEnum {
  ACTIVE = 'ibtm-domain.permission-status.active',
  PENDING = 'ibtm-domain.permission-status.pending'
}
export enum LicenseTypeEnum {
  /** Licencja wspólnotowa rzeczy */
  TP_LICENSE_EU = 'ibtm-domain.license.type.tp-license-eu',

  /** Licencja wspólnotowa _sób */
  OP_LICENSE_EU = 'ibtm-domain.license.type.op-license-eu',

  /** Licencja na pośrednictwo przy przewozie _sób */
  PASSENGER_TRANSPORT_BROKERAGE = 'ibtm-domain.license.type.passenger-transport-brokerage'
}
