import { ForeignPermissionApplicationRecordWithLimitDetails } from '@ibtm/domain';

import { CamelCasePath, useCreateColumns } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

export type EkmtColumns = Array<CamelCasePath<ForeignPermissionApplicationRecordWithLimitDetails>>;

export const useEkmtColumns = ({ visibleColumns }: { visibleColumns: EkmtColumns }) => {
  const { createColumns } = useCreateColumns<ForeignPermissionApplicationRecordWithLimitDetails>({
    pathToTranslate: 'applications:foreignPermissions.fields'
  });

  const { translate } = useDictionaryTranslations();

  const columns = createColumns(
    [
      {
        type: 'DICTIONARY_SINGLE_SELECT',
        dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_NAME,
        id: 'resourceType',
        customAccessor: rowOriginal => (
          <span>{translate(DomainDictionaryEnum.RESOURCE_FORM_NAME, rowOriginal.resourceType?.nameKey)}</span>
        ),
        isSortable: false,
        width: 300
      },
      {
        type: 'NUMBER',
        accessor: 'euro5amount',
        header: 'EURO5',
        isSortable: false,
        width: 120
      },
      {
        type: 'NUMBER',
        accessor: 'euro6amount',
        header: 'EURO6',
        isSortable: false,
        width: 120
      },
      {
        type: 'SINGLE_SELECT',
        id: 'concernForeignPermission',
        header: 'concernPermission',
        accessor: 'concernForeignPermission.number',
        isSortable: false,
        width: 120
      },
      {
        type: 'NUMBER',
        accessor: 'amount',
        isSortable: false,
        width: 120
      },
      {
        type: 'TEXT',
        accessor: 'notes',
        header: 'note',
        isSortable: false
      }
    ],
    visibleColumns
  );

  return { columns };
};
