import { AttachmentVerificationElementDetails } from '@ibtm/domain';

import { useDialog } from '@libs/common/v2';

import { DictionaryEntryNameEnum } from '@libs/dictionary';

import AttachmentCommentDialog from '../../components/dialogs/AttachmentCommentDialog';
import { IAttachmentListItemClient } from '../../model';

const useAddCommentDialog = (verificationElements: AttachmentVerificationElementDetails[]) => {
  const { openDialog } = useDialog();

  const getAttachmentDictionary = (data: IAttachmentListItemClient) => {
    const { id } = data;
    const result = verificationElements.find(element => element?.id === id);

    return result?.attachmentNotesDictionary as DictionaryEntryNameEnum;
  };

  const open = (applicationId: string, data: IAttachmentListItemClient) => {
    const dictionaryKey = getAttachmentDictionary(data);
    openDialog(({ closeDialog }) => {
      return (
        <AttachmentCommentDialog
          applicationId={applicationId}
          data={data}
          closeDialog={closeDialog}
          dictionaryKey={dictionaryKey}
        />
      );
    });
  };

  return {
    open
  };
};

export default useAddCommentDialog;
