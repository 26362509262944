import { TableCell as MuiTableCell } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { TableClassNames, useTableAdapter, useTableContext } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import { getStickyLeftStyle } from '../utils';

import TableCellContainer from './TableCellContainer';

function SelectHeadCell(props) {
  const { column, isWhiteBackground } = props;
  const { data } = useTableAdapter();
  const { selectionColumnWidth } = useTableContext();
  const classes = useStyles({ isWhiteBackground });

  return (
    <MuiTableCell
      {...column.getHeaderProps(props => {
        return getStickyLeftStyle(props, selectionColumnWidth ?? 48);
      })}
      classes={{ root: classes.root }}
      className={clsx(TableClassNames.STICKY_LEFT_COLUMN, { [classes.isVisible]: !data?.length })}
      component="div"
      variant="head"
    >
      <TableCellContainer isHeaderCell padding="10px 0">
        {column.render('Header')}
      </TableCellContainer>
    </MuiTableCell>
  );
}

const useStyles = makeStyles<Theme, { isWhiteBackground?: boolean }>(theme => ({
  root: {
    zIndex: 3,
    backgroundColor: ({ isWhiteBackground }) => (isWhiteBackground ? theme.palette.white : theme.palette.grey[50])
  },
  isVisible: {
    display: important('none')
  }
}));
export default SelectHeadCell;
