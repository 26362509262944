import { MoneyTransferCreateRequest } from '@ibtm/domain';

export enum PricingTab {
  CONFIRMED = 'CONFIRMED',
  UNCONFIRMED = 'UNCONFIRMED'
}

export interface IMoneyTransferCreateRequest extends Omit<MoneyTransferCreateRequest, 'paymentDate'> {
  paymentDate: string;
}
