import { VersionedRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function forwardToShipment({
  documentId,
  versionedRequest
}: {
  documentId: string;
  versionedRequest: VersionedRequest;
}) {
  return API.documents.updateDocumentOutgoingNumber(documentId, versionedRequest, {
    ...endpointsConfig.updateDocumentOutgoingNumber
  });
}

function useForwardToShipmentMutation() {
  return useMutation(forwardToShipment);
}

export default useForwardToShipmentMutation;
