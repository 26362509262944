import { Control, FieldPath, FieldValues, useWatch } from 'react-hook-form';

import { useFormV2Context } from '../context';

/**
 * Zoptymalizowana wersja watch - wywołuje rerender tego komponentu w którym jest użyta
 */
function useFormV2Watch<T = any>(props: {
  name: FieldPath<FieldValues>;
  defaultValue?: FieldValues;
  control?: Control<FieldValues>;
  disabled?: boolean;
  exact?: boolean;
}) {
  const { control } = useFormV2Context();
  return useWatch({ control, ...props }) as T;
}

export default useFormV2Watch;
