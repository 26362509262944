import { FieldPath, FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { SubjectAddressDetails } from '@ibtm/domain';

import { useFormV2Context } from '@libs/common/v2';

type FormData = Record<string, Record<string, SubjectAddressDetails>>;
interface IAddressSectionParams {
  id?: string;
  applicationId?: string;
  [key: string]: string | undefined;
}

const useCountryChange = () => {
  const { id: folderId, applicationId } = useParams<IAddressSectionParams>();
  const { reset, getValues, setValue } = useFormV2Context();

  const handleCountryChangeWhileCreate = ({
    previousCountry,
    countryCode
  }: {
    previousCountry: string;
    countryCode: string;
  }) => {
    if (previousCountry && previousCountry !== countryCode) {
      reset({ ...getValues(), addresses: {}, previousCountry: countryCode });
    }
    setValue('previousCountry', countryCode);
  };

  const handleCountryChangeWhileEdit = (formGroupName: string | FieldPath<FieldValues>) => {
    const formData = getValues() as FormData;
    const [formGroup, addressName] = formGroupName.split('.');
    const previousCountry = formData[formGroup][addressName];

    const newAddress: SubjectAddressDetails = {
      id: previousCountry?.id,
      version: previousCountry?.version,
      deleted: previousCountry?.deleted,
      typeKey: previousCountry?.typeKey,
      countryCodeKey: previousCountry?.countryCodeKey,
      sameAsMainAddress: previousCountry?.sameAsMainAddress
    };

    if (applicationId) {
      reset({
        [formGroup]: {
          ...formData[formGroup],
          [addressName]: newAddress
        }
      });
    }

    if (folderId) {
      reset({
        ...formData,
        [formGroup]: {
          ...formData[formGroup],
          [addressName]: newAddress
        }
      });
    }
  };

  return { handleCountryChangeWhileEdit, handleCountryChangeWhileCreate };
};

export default useCountryChange;
