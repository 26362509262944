import { useMemo } from 'react';
import { PaginatedQueryResult, QueryObserverConfig } from 'react-query';
import { RefetchOptions } from 'react-query/types/core/query';
import { PluginHook, TableOptions } from 'react-table';
import { AxiosResponse } from 'axios';

import {
  IPaginatedModel,
  ITableLayoutColumn,
  Path,
  TableEventContext,
  TableEventsContextProvider
} from '@libs/common/v2';

import { UIElementNameEnum, useElementVisibility } from '@libs/permission';

import PaginatedQueryTable from './PaginatedQueryTable';
import QueryTable from './QueryTable';
import { ITableWithoutHookProps } from './TableWithoutHook';

export interface ITableLayoutProps<T extends Record<string, any>, K extends Record<string, any>>
  extends Omit<
    ITableWithoutHookProps<T>,
    'table' | 'tableHookQueryInitialParams' | 'queryResult' | 'setTableQueryParams' | 'xlsxDownload'
  > {
  // Propsy do pobrania danych do tabeli
  /**
   * Używamy tego props jeżeli chcemy mieć paginację po stronie front'u.
   */
  tableQuery?: ((params) => Promise<T[]>) | T[];
  /**
   * Używamy tego props jeżeli chcemy mieć paginację po stronie backend'u
   */
  tableHookQuery?: (params, options, queryKey) => PaginatedQueryResult<IPaginatedModel<T>>;
  /**
   * Obiekt zawierający propsy używane do tworzenia instancji tabeli.
   */
  tableHookOptions: {
    /**
     * @type T - model Frontend/Backend.
     * @type K - opcjonalny model Backend. Wykorzystywany przy mappowaniu wartości do pliku xlsx
     * @konwencja typ `T`to model zawierający definicje danych do tabeli. Może być modelem backend'u,
     * jeżeli na nim jest oparta implementacja gui, lub jeżeli dane są parsowane i dostosowane go modelu
     * frontend'owego, który jest rozbieżny z modelem backend wtedy do typu `T` przypisujemy model frontend,
     * natomiast do typu `K` przypisujemy model backend. W ten sposób przy mappowaniu wartości do pliku xlsx
     * mamy dostęp do atrybutów z modelu backend.
     * @implementacja  Zakłada użycie którejś z funkcji hook'a useCreateColumns.
     * Tablica obiektów na podstawie których zostają wyrenderowane kolumny tabeli.
     * Lub funkcja w której dostajemy jako argument obiekt,
     * który przekazujemy dalej do fukcji tworzącej kolumny (createColumnsWithArrays)
     */
    columns: ITableLayoutColumn<T, K>[] | ((mapper: Record<string, number>) => ITableLayoutColumn<T, K>[]);
    /**
     * Ścieżki w modelu do tablic z danymi
     */
    arrayPaths?: Path<T>[];
    /**
     *  Konwerter filtrów tabeli (konwersja wartości filtra na wartość przyjmowaną przez api)
     */
    filterConverter?: Record<string, (value) => Record<string, any>>;
    /**
     * Konwerter sortowania tabeli (konwersja klucza tabeli na klucz przyjmowany przez api)
     */
    sortByConverter?: Partial<Record<keyof T, any>>;
    /**
     *  Konwerter filtrów globalnych i dodatkowych
     *  (konwersja wartości filtra na wartość przyjmowaną przez api)
     */
    initialParamsConverter?: Record<string, (value) => Record<string, any>>;
    /**
     * Dodatkowe opcje do tabeli
     */
    tableOptions?: Partial<TableOptions<T>>;
    /**
     * Dodatkowe pluginy do tabeli. Nie trzeba tutaj przekazywać useActionColumn,
     * ponieważ ten plugin jest dodawany wewnątrz hook'a.
     * Decyduje o tym props `isActionColumnEnabled` - domyślnie `true`
     */
    tablePlugins?: Array<PluginHook<T>>;
    /**
     * nie zawsze w modelu mamy id, zmiana id na inny parametr
     */
    getRowId?: (row) => string;
  };
  /**
   * Obiekt zawierający początkowe parametry przekazane do query.
   */
  tableHookQueryInitialParams?: Record<string, unknown>;
  /**
   * Dodatkowy obiekt zawierający konfigurację query (react-query).
   */
  tableHookQueryConfig?: QueryObserverConfig<unknown>;
  /**
   * Klucz przekazywany do react-query hook.
   */
  tableHookQueryKey?: string;
  /**
   * Obiekt zawierający props potrzebne do pobrania tabeli do xlsx
   */
  xlsxDownload?: {
    /**
     * Request który zwróci dane do wsadzenia do pliku xlsx
     */
    apiRequest?: (parameters: {
      page: number;
      size: number;
      sort?: any;
      [_: string]: any;
    }) => Promise<AxiosResponse<any>>;
    /**
     * Nazwa pliku xlsx
     */
    fileName: string;
    /**
     * Ścieżka do tłumaczenia danych do pliku xlsx
     */
    pathToXLSXTranslation: string;
  };
  // Boolean Props
  /**
   * Wartość decyduje czy tabela używa plugin do kolumny akcji. Domyślnie `true`
   */
  isActionColumnEnabled?: boolean;
  /**
   * Wartość używana przy przekazywaniu tablicy wcześniej pobranych danych (tableQuery).
   * Decyduje o użyciu loader'a wyświetlanego przy pobieraniu danych.
   */
  isFetching?: boolean;
  /**
   * Funkcja przekazywana gdy tabela renderuje przekazaną tablicę danych (tableQuery)
   */
  refetch?: (options?: RefetchOptions) => Promise<any>;
  /**
   * Props do całkowitego ukrycia nagłówka tabeli włącznie z przyciskami akcji nad tabelą.
   */
  isHeaderHidden?: boolean;
  /**
   * Props przekazujący obligatoryjne uprawnienia do widoku tabeli
   */
  actionKey?: UIElementNameEnum;
  /**
   * Props umożliwia przekazanie id rekordów, które powinny być wygaszone
   */
  disabledRows?: string[];
  /**
   * Treść komunikatu w tooltip wygaszonego wiersza
   */
  disabledRowTooltipText?: string;
  useOnlyCircleLoader?: boolean;
  delayTable?: boolean;
  isHiddenSelectRowHeader?: boolean;
}

function TableLayout<T extends Record<string, any>, K extends Record<string, any>>({
  tableHookQuery,
  isLoading,
  isFetching,
  tableEventsRef,
  ...forwardProps
}: ITableLayoutProps<T, K> & {
  tableEventsRef?: React.MutableRefObject<TableEventContext>;
}) {
  const { checkIsElementVisible } = useElementVisibility();
  const isElementVisible = useMemo(() => {
    return forwardProps.actionKey ? checkIsElementVisible(forwardProps.actionKey) : true;
  }, [checkIsElementVisible, forwardProps.actionKey]);

  if (isElementVisible) {
    return tableHookQuery ? (
      <TableEventsContextProvider ref={tableEventsRef}>
        <PaginatedQueryTable tableHookQuery={tableHookQuery} {...forwardProps} />
      </TableEventsContextProvider>
    ) : (
      <TableEventsContextProvider ref={tableEventsRef}>
        <QueryTable isLoading={isLoading} isFetching={isFetching} {...forwardProps} />
      </TableEventsContextProvider>
    );
  }
}

export default TableLayout;
