import { useEffect, useRef } from 'react';

type ArgumentTypes<F extends (...args: any) => void> = F extends (...args: infer A) => any ? A : never;

export default function useDebounce<T extends (...args: any) => void>(func: T, delay = 0) {
  const isLoaded = useRef<boolean>(true);
  const timeout = useRef(null);

  useEffect(() => {
    return () => {
      isLoaded.current = false;
    };
  }, []);

  return (...args: ArgumentTypes<typeof func>) => {
    if (timeout.current) {
      window.clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      if (isLoaded.current) {
        func(...args);
      }
    }, delay);
  };
}
