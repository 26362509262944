import { useCallback } from 'react';

import { FormMode, useDialog } from '@libs/common/v2';

import { SCertificatesDetails } from '..';

function useCertificateDetailsDialog() {
  const { openDialog } = useDialog();

  const openDetailsDialog = useCallback(
    ({ id }: { id?: string }) => {
      openDialog(({ closeDialog }) => (
        <SCertificatesDetails id={id} formMode={FormMode.VIEW} closeDialog={closeDialog} isDialogContent />
      ));
    },
    [openDialog]
  );

  return {
    openDetailsDialog
  };
}

export default useCertificateDetailsDialog;
