import { QueryResult, useQuery } from 'react-query';
import { ApplicationDetails as ApplicationDetailsClient } from '@ibtm/client-domain';
import { ApplicationDetails, ApplicationFlagDetails } from '@ibtm/domain';

import { WarningInformation } from '@libs/common/v2';

import { useMetaFormContext } from '@libs/meta-form/context';
import { ApiRegistryGet } from '@libs/meta-form/models';
import { getMetaFormQueryKey } from '@libs/meta-form/services/useGetQuery';

import { useApplicationFlagsQuery } from '../../application/api';
import { useAllWarningVisibleForApplication } from '../hooks';

function ApplicationWarningsList({
  applicationFlags,
  applicationData,
  applicationId
}: {
  applicationFlags?: ApplicationFlagDetails;
  applicationData: ApplicationDetails | ApplicationDetailsClient;
  applicationId: string;
}) {
  const applicationFlagsQuery = useApplicationFlagsQuery(applicationId, {
    refetchOnMount: false
  });
  const { apiRegistry } = useMetaFormContext();
  const requestKey = 'GET_APPLICATION';

  const getQuery = useQuery(
    [getMetaFormQueryKey(requestKey), (apiRegistry?.[requestKey] as ApiRegistryGet)?.params],
    (apiRegistry?.[requestKey] as ApiRegistryGet)?.request,
    {
      enabled: Boolean(apiRegistry?.GET_APPLICATION)
    }
  ) as QueryResult<ApplicationDetails | ApplicationDetailsClient>;

  const warnings = useAllWarningVisibleForApplication({
    applicationData: getQuery.data || applicationData,
    applicationFlags: applicationFlags || applicationFlagsQuery?.data
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 24, marginTop: '-2px' }}>
      {warnings?.map(warning => (
        <WarningInformation content={warning.warning.message} key={warning.warning.name} />
      ))}
    </div>
  );
}

export default ApplicationWarningsList;
