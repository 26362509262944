import { DictionarySearchFilter, DictionarySnapshot } from '@avispon/dictionary/dist/models';

import { CamelCasePath, SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO } from '@libs/common/v2/utils';

import { UserFilterV2 } from '@libs/user';

const useDictionariesListColumns = (visibleColumnIds?: Array<CamelCasePath<DictionarySnapshot>>) => {
  const { createColumns } = useCreateColumns<DictionarySnapshot>({ pathToTranslate: 'dictionary:field' });

  const columns = createColumns(
    [
      { id: 'name', type: 'TEXT', width: 450 },
      { id: 'description', type: 'TEXT', width: 300 },
      { accessor: 'author.name', header: 'author', type: 'CUSTOM_COLUMN', filter: UserFilterV2, width: 250 },
      { accessor: 'created', type: 'DATE', width: 350 },
      {
        accessor: 'modifier.name',
        header: 'modifiedBy',
        type: 'CUSTOM_COLUMN',
        filter: UserFilterV2,
        width: 300
      },
      {
        accessor: 'modified',
        header: 'modifiedDate',
        type: 'DATE',
        width: 350
      }
    ],
    visibleColumnIds
  );

  const mappedFilterFields: TableFilterMapper<DictionarySnapshot, DictionarySearchFilter> = {
    name: (value: string) => ({ nameContains: value }),
    description: (value: string) => ({ descriptionContains: value }),
    authorName: (authorIds: Array<SelectOption<string>>) => ({ authorIds: authorIds.map(({ value }) => value) }),
    created: ({ dateFrom, dateTo }: DateRangeISO) => ({
      createdDateFrom: dateFrom,
      createdDateTo: dateTo
    }),
    modifierName: (modifierIds: Array<SelectOption<string>>) => ({
      modifierIds: modifierIds.map(({ value }) => value)
    }),
    modified: ({ dateFrom, dateTo }: DateRangeISO) => ({
      modifiedDateFrom: dateFrom,
      modifiedDateTo: dateTo
    })
  };

  const mappedSortFields: TableSortMapper<DictionarySnapshot> = {
    name: 'name',
    description: 'description',
    author: 'authorName',
    created: 'created',
    modifier: 'modifierName',
    modified: 'modified'
  };

  return { columns, mappedFilterFields, mappedSortFields };
};

export default useDictionariesListColumns;
