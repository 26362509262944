import { useMutation } from '@libs/common/v2/api';

import API from '../endpoints';
import { endpointsConfig } from '../endpoints-config';

interface IParams {
  fileId: string;
  isClientPortal: boolean;
  uploadFileEndpoint?: string;
}

const downloadFile = async ({ fileId, isClientPortal }: IParams) => {
  return API.downloadFile({ fileId, isClientPortal }, { responseType: 'blob', ...endpointsConfig.downloadFile });
};

export default function useFileDownloadMutation(options?) {
  return useMutation(downloadFile, options);
}
