import { ResourceObjectBatchDisposalCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function batchDisposals(formData: ResourceObjectBatchDisposalCreateRequest) {
  return API.resourceObjects.batchDisposalResourceObjects(formData, {
    ...endpointsConfig.batchDisposalResourceObjects
  });
}

function useBatchDisposalsMutation() {
  return useMutation(batchDisposals);
}

export default useBatchDisposalsMutation;
