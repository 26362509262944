export const SUPPORTED_FILE_TYPES: Array<string> = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/gif',
  'image/jpeg',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  'application/pdf',
  'image/png',
  'application/rtf',
  'image/svg+xml',
  'image/tiff',
  'text/plain',
  'text/xml',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/xml',
  'application/zip',
  'application/vnd.rar',
  'application/json'
];

export const SUPPORTED_FILE_EXTENSIONS: Array<string> = ['xades'];
