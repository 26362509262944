import { SingleUseSubmissionItemSnapshot } from '@ibtm/domain';

import { CamelCasePath, useTableLayoutProps } from '@libs/common/v2';

import { useSingleUseSubmissionItemsColumns } from '../columns/useSingleUseSubmissionItemsColumns';

function useSingleUseSubmissionItemsTable(
  resourceItems: SingleUseSubmissionItemSnapshot[],
  visibleColumns: Array<CamelCasePath<SingleUseSubmissionItemSnapshot>>
) {
  const { columns } = useSingleUseSubmissionItemsColumns(visibleColumns);

  return useTableLayoutProps({
    tableQuery: resourceItems,
    tableHookOptions: {
      columns,
      tableOptions: {
        manualPagination: false,
        initialState: {
          pageSize: 10
        }
      }
    }
  });
}

export default useSingleUseSubmissionItemsTable;
