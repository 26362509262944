import { AssignForDecisionRequest } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface IParams {
  applicationId: string;
  assignForDecisionRequest: AssignForDecisionRequest;
}
export function assignForDecision({ applicationId, assignForDecisionRequest }: IParams) {
  return API.application.assignForDecision(applicationId, assignForDecisionRequest, {
    ...endpointsConfig.updateAssignedTo
  });
}
