import { useBorrowFoldersTable } from '../hooks';

import { BorrowFolderProvider } from './common/BorrowFolderProvider';
import { BorrowFoldersDialog, BorrowFoldersTable } from '.';

export function BorrowFoldersWrapper() {
  const [table, queryResult, loadingInfo] = useBorrowFoldersTable();

  return (
    <BorrowFolderProvider refresh={queryResult?.refetch}>
      <BorrowFoldersTable queryResult={queryResult} loadingInfo={loadingInfo} table={table} />
      <BorrowFoldersDialog />
    </BorrowFolderProvider>
  );
}

export default BorrowFoldersWrapper;
