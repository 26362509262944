import { ProceedingsKreptdApiUpdateProceedingKreptdFolderRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function changeFolder(requestData: ProceedingsKreptdApiUpdateProceedingKreptdFolderRequest) {
  return API.proceedingsKreptd.updateProceedingKreptdFolder(
    requestData.proceedingId,
    requestData.proceedingKreptdFolderUpdateRequest,
    { ...endpointsConfig.updateProceedingKreptdFolder }
  );
}

function useFolderChangeMutations() {
  return useMutation(changeFolder);
}

export default useFolderChangeMutations;
