import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { Dialog, TextInput, useRouter } from '@libs/common/v2';

import { useReturnForCorrectionsMutation } from '@libs/domain/application/api';

import {
  PrintActionType,
  usePrintsActionsContext,
  usePrintsActionsDataContext,
  usePrintsDetailsDataContext
} from '../../../context';

function ReturnApplicationConfirmationDialog() {
  const [inputValue, setInputValue] = useState('');
  const { actionType, actionData } = usePrintsActionsDataContext();
  const { finishAction } = usePrintsActionsContext();
  const { printDetailsData } = usePrintsDetailsDataContext();

  const { goBack } = useRouter();
  const { mutateAsync: returnForCorrection, isLoading } = useReturnForCorrectionsMutation();

  const [t] = useTranslation();
  const { showSuccessSnackbar, showSnackbar } = useSnackbar();

  const confirmHandler = async () => {
    await returnForCorrection(
      {
        applicationId: printDetailsData.applicationId,
        formData: { reason: inputValue, version: printDetailsData.applicationSnapshot.version }
      },
      {
        onSuccess: () => {
          actionData?.onSuccess();
          showSuccessSnackbar(t('prints:messages.applicationReturnSuccessMessage'));
          goBack();
        },
        onError: () => showSnackbar('warning', t('prints:messages.applicationReturnFailureMessage')),
        onSettled: () => finishAction()
      }
    );
  };

  return (
    <Dialog
      title={t('prints:messages.returnApplication')}
      confirmText={t('prints:actions.return')}
      cancelText={t('action.cancel')}
      onConfirm={confirmHandler}
      onCancel={finishAction}
      isConfirmLoading={isLoading}
      isOpen={actionType === PrintActionType.ReturnApplication}
      isConfirmButtonDisabled={inputValue === ''}
    >
      <TextInput
        className="w-full pb-2"
        label={t('prints:formField.returnJustification')}
        multiline
        minRows={5}
        onChange={e => setInputValue(e.target.value)}
        required
        disabled={isLoading}
        value={inputValue}
      />
    </Dialog>
  );
}

export default ReturnApplicationConfirmationDialog;
