import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MutationResultPair } from 'react-query';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { FinancialSecurityDetails } from '@ibtm/domain';
import { AxiosResponse } from 'axios';
import { number as YupNumber, object as YupObject, string as YupString } from 'yup';

import { Dialog, FormV2Context, GridItem, GridLayout, Section, useIsSmallScreen } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { RecursivePartial } from '@libs/common/v2/utils';

import { getMetaFormQueryKey } from '@libs/meta-form/services/useGetQuery';

import { FinancialSecurityQueryKeysEnum } from '../api';
import { useFinancialSecurityFields } from '../hooks';
import { FinancialSecurityFields } from '../model';

interface IProps {
  financialSecurityId: string;
  onCloseDialog: () => void;
  isOpen: boolean;
  initialData?: RecursivePartial<FinancialSecurityDetails>;
  folderTypeKey: string;
  updateMutation: () => MutationResultPair<AxiosResponse<any>, unknown, any, unknown>;
  exchangeRateFrom?: string;
  exchangeRateTo?: string;
}

function FinancialSecurityEditDialog({
  isOpen,
  onCloseDialog,
  financialSecurityId,
  initialData,
  folderTypeKey,
  updateMutation,
  exchangeRateFrom,
  exchangeRateTo
}: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { isSmallScreen } = useIsSmallScreen();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const [editFinancialSecurity, { isLoading: isSubmitLoading }] = updateMutation();

  const validationSchema = YupObject({
    typeKey: YupObject().nullable().required(),
    releaseDate: YupString().nullable(),
    expirationDate: YupString().nullable().required(),
    currencyKey: YupObject<{ key: string; value: string }>().nullable().required(),
    amount: YupNumber().nullable().required(),
    notes: YupString().nullable()
  });

  const {
    control,
    register,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    reset,
    getValues,
    trigger,
    unregister,
    handleSubmit
  } = useForm<FinancialSecurityFields>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema)
  });

  const releaseDate = watch('releaseDate');

  const formValues = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting
    }),
    [control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting]
  );

  const { renderField, isDataLoading } = useFinancialSecurityFields({
    financialSecurityId,
    initialData,
    watch,
    reset,
    setValue,
    trigger,
    folderTypeKey,
    exchangeRateFrom: exchangeRateFrom || releaseDate,
    exchangeRateTo: exchangeRateTo || releaseDate
  });

  const handleEditSubmit = (values: FinancialSecurityFields) => {
    editFinancialSecurity(
      {
        financialSecurityId,
        updateRequestData: {
          version: values?.version,
          typeKey: values?.typeKey?.value,
          expirationDate: values?.expirationDate,
          releaseDate: values?.releaseDate,
          amount: values?.amount,
          exchangeAmount: values?.exchangeAmount,
          currencyKey: values?.currencyKey?.value,
          notes: values?.notes
        }
      },
      {
        onSuccess: () => {
          queryCache.invalidateQueries(FinancialSecurityQueryKeysEnum.FINANCIAL_SECURITY_LIST);
          queryCache.invalidateQueries(FinancialSecurityQueryKeysEnum.FINANCIAL_SECURITY_SUMMARY);
          // queryKey generowany przez metaform
          queryCache.invalidateQueries(getMetaFormQueryKey('GET_FINANCIAL_SECURITY_SUMMARY'));

          showSuccessSnackbar(t('financialSecurity:action.edit.successMessage'));
          onCloseDialog();
        },
        onError: () => {
          showErrorSnackbar(t('financialSecurity:action.edit.failureMessage'));
        }
      }
    );
  };

  return (
    <FormV2Context.Provider value={formValues}>
      <form onSubmit={handleSubmit(handleEditSubmit)}>
        <Dialog
          title={t('financialSecurity:action.edit.dialogTitle')}
          confirmText={t('action.save')}
          cancelText={t('action.cancel')}
          onConfirm={handleSubmit(handleEditSubmit, () => {
            showErrorSnackbar(t('error.formValidationErrors'));
          })}
          onCancel={onCloseDialog}
          dialogSize="medium"
          isConfirmLoading={isDataLoading || isSubmitLoading}
          isOpen={isOpen}
          isFullScreen={isSmallScreen}
        >
          <Section isCollapsable isModalSection isHeaderVisible={false}>
            <GridLayout itemProps={{ xs: 12, md: 6 }}>
              <GridItem xs={12}>{renderField('typeKey')}</GridItem>
              {renderField('releaseDate')}
              {renderField('expirationDate')}
              {renderField('currencyKey')}
              {renderField('exchangeCurrency', { inputMode: 'VIEW' })}
              {renderField('amount')}
              {renderField('exchangeAmount', { inputMode: 'VIEW' })}
              <GridItem xs={12}>{renderField('notes')}</GridItem>
            </GridLayout>
          </Section>
          <Section title={t('financialSecurity:section.exchangeRates')} isCollapsable isModalSection>
            <GridLayout itemProps={{ xs: 12, md: 6 }}>
              {renderField('exchangeRate', { inputMode: 'VIEW' })}
              {renderField('exchangeRateFromDate', { inputMode: 'VIEW' })}
            </GridLayout>
          </Section>
          <Section title={t('financialSecurity:section.generalInformation')} isCollapsable isModalSection>
            <GridLayout itemProps={{ xs: 12, md: 6 }}>
              {renderField('created', { inputMode: 'VIEW' })}
              {renderField('author', { inputMode: 'VIEW' })}
              {renderField('modified', { inputMode: 'VIEW' })}
              {renderField('modifier', { inputMode: 'VIEW' })}
            </GridLayout>
          </Section>
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
}

export default FinancialSecurityEditDialog;
