import _ from 'lodash';

import { ProxyFields } from '../model';

export * from './api-request';

export const proxyPayloadParser = (
  payload: ProxyFields,
  version: number,
  params?: { folderId?: string; applicationId?: string }
) => {
  const authorizations = payload?.authorizations;

  return {
    updateRequestData: {
      name: payload?.name,
      surname: payload?.surname,
      typeKey: payload?.typeKey?.value,
      paid: payload?.paid,
      citizenship: payload?.citizenship?.value,
      address: {
        postCode: payload?.postCode,
        postCity: payload?.postCity,
        city: payload?.city,
        street: payload?.street,
        propertyNumber: payload?.propertyNumber,
        apartmentNumber: payload?.apartmentNumber,
        version
      },

      remarks: payload?.notes,
      ...(params?.folderId && { folderId: params?.folderId }),
      ...(params?.applicationId && { applicationId: params?.applicationId }),
      validUntil: payload?.validUntil,
      authorizations: _.isEmpty(authorizations) ? null : authorizations?.map(({ value }) => value),
      version
    }
  };
};
