import { QueryConfig, useQuery } from 'react-query';
import { ReputationRequestDetailsList } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';
import { FolderQueryKeysEnum } from '..';

const getReputations = async (id: string) => {
  const { data } = await API.subject.getSubjectReputationRequest(id, {
    ...endpointsConfig.getSubjectReputationRequest
  });
  return data;
};

export default function useReputationsQuery(id: string, queryConfig: QueryConfig<any, unknown> = {}) {
  return useQuery<ReputationRequestDetailsList>(
    [FolderQueryKeysEnum.REPUTATIONS_LIST, id],
    () => getReputations(id),
    queryConfig
  );
}
