import { createSearchParams, useParams } from 'react-router-dom';

import { useRouter } from '@libs/common/v2';

import { FolderDetailsTabEnum } from '@libs/domain/folder';

import { ApplicationArchiveUrlParam } from '../../archive';
import { ApplicationDetailsPageParams, ApplicationUpdatePageParams } from '../model';

const useBackFromApplication = () => {
  const { folderId, archiveLocation } = useParams<ApplicationDetailsPageParams & ApplicationUpdatePageParams>();
  const { goToPage, routes } = useRouter();
  const returnFromApplication = () => {
    if (folderId) {
      goToPage({
        pathname: routes.folderDetails(folderId),
        search: `?${createSearchParams({ tab: FolderDetailsTabEnum.APPLICATIONS })}`
      });
    } else if (archiveLocation) {
      if (archiveLocation === ApplicationArchiveUrlParam.ARCHIVED) {
        goToPage(routes.archiveApplicationsArchiveList());
      }
      if (archiveLocation === ApplicationArchiveUrlParam.TO_ARCHIVE) {
        goToPage(routes.archiveApplicationsToArchiveList());
      }
    } else {
      goToPage(routes.applicationsList());
    }
  };
  return returnFromApplication;
};

export default useBackFromApplication;
