import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient, useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { DomainErrorCodes } from '@libs/config';
import moment from 'moment';
import { object as YupObject, string as YupString } from 'yup';

import {
  ALTERNATIVE_DATE_FORMAT,
  DatepickerField,
  Dialog,
  DictionarySelectField,
  FormV2Context,
  GridItem,
  GridLayout,
  isDateValid,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';
import { AxiosErrorResponseType } from '@libs/common/v2/models';
import { getCalendarDate } from '@libs/common/v2/utils';

import { DomainDictionaryEnum } from '../../config';
import { PermissionsQueryKeysEnum, useUpdatePermissionMutation } from '../api';
import { editablePermissionValidityKeys } from '../utils';

import PermissionValidToField from './PermissionValidToField';

export interface IUpdatePermissionRequest {
  id?: string;
  version?: number;
  formNumber?: string;
  validFrom?: string;
  permissionNumber?: string;
  printDate?: string;
  validTo?: string;
  permissionValidityPeriodKey?: string;
}

interface IProps {
  readonly closeDialog: () => void;
  readonly initialData: IUpdatePermissionRequest;
  readonly refetch: () => void;
  readonly titleKey: string;
}

export function PermissionEditDialog({ closeDialog, initialData, refetch, titleKey }: IProps) {
  const [t] = useTranslation();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbar();
  const { mutate: updatePermission, isLoading } = useUpdatePermissionMutation();
  const clientQuery = useQueryClient();

  const permissionNumberFieldName = typedNameV2<IUpdatePermissionRequest>('permissionNumber');
  const formNumberFieldName = typedNameV2<IUpdatePermissionRequest>('formNumber');
  const validFromFieldName = typedNameV2<IUpdatePermissionRequest>('validFrom');
  const permissionValidityPeriodKey = typedNameV2<IUpdatePermissionRequest>('permissionValidityPeriodKey');
  const validToFieldName = typedNameV2<IUpdatePermissionRequest>('validTo');

  const isFieldVisible = (fieldName: keyof IUpdatePermissionRequest) => {
    switch (titleKey) {
      case 'permissionGitd':
        return [permissionNumberFieldName, validFromFieldName].includes(fieldName);
      case 'spo-eu':
      case 'spo-outside-eu':
      case 'spo-transit':
      case 'spo-shuttle':
      case 'spo-occasional':
        return [formNumberFieldName, validFromFieldName, validToFieldName, permissionValidityPeriodKey].includes(
          fieldName
        );
    }
    return false;
  };

  const validationSchema = YupObject({
    ...(isFieldVisible('permissionNumber') ? { permissionNumber: YupString().required().nullable() } : {}),
    ...(isFieldVisible('formNumber') ? { formNumber: YupString().required().nullable() } : {}),
    ...(isFieldVisible('validFrom') ? { validFrom: YupString().required().nullable().concat(isDateValid()) } : {}),
    ...(isFieldVisible('printDate') ? { printDate: YupString().required().nullable().concat(isDateValid()) } : {}),
    ...(isFieldVisible('validTo')
      ? {
          validTo: YupString()
            .nullable()
            .required()
            .test({
              name: 'validTo',
              message: t('applications:validations.validToDateCanNotBeBeforeValidFrom'),
              test(validTo) {
                const { parent } = this;
                const validFrom = parent?.validFrom;
                return !validFrom || !validTo || moment(validFrom, ALTERNATIVE_DATE_FORMAT).isBefore(validTo);
              }
            })
            .concat(isDateValid())
        }
      : {})
  });

  const form = useForm<Partial<IUpdatePermissionRequest>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema)
  });

  const handleSubmit = async (data: IUpdatePermissionRequest) => {
    const { version, formNumber, validFrom, permissionNumber, printDate, validTo, permissionValidityPeriodKey } = data;

    const isEditable =
      permissionValidityPeriodKey && editablePermissionValidityKeys.includes(permissionValidityPeriodKey);
    await updatePermission(
      {
        id: data.id,
        version,
        formNumber,
        printDate: getCalendarDate(printDate),
        validFrom: getCalendarDate(validFrom),
        validTo: getCalendarDate(isEditable ? validTo : null),
        number: permissionNumber,
        permissionValidityPeriodKey
      },
      {
        onSuccess: () => {
          clientQuery.invalidateQueries(PermissionsQueryKeysEnum.PERMISSION_COPIES_LIST);
          showSuccessSnackbar(t('permission:message.permissionUpdateSuccess'));
          refetch();
          closeDialog();
        },
        onError: error => {
          const errorData = (error as AxiosErrorResponseType)?.response?.data;
          if (errorData?.codes.includes(DomainErrorCodes.VALID_TO_EXCEEDS_DURATION)) {
            showErrorSnackbar(errorData?.message.replace(/&quot;/g, '"'));
          }
        }
      }
    );
  };

  useEffect(() => {
    form.reset(initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      title={t('permission:title.permission.editPermissionGitd')}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={form.handleSubmit(handleSubmit)}
      onCancel={closeDialog}
      dialogSize="small"
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <GridLayout>
            {isFieldVisible('permissionNumber') && (
              <GridItem xs={12}>
                <TextInputField
                  name={typedNameV2<IUpdatePermissionRequest>('permissionNumber')}
                  label={t('permission:field.formNumber')}
                  isRequired
                />
              </GridItem>
            )}
            {isFieldVisible('permissionValidityPeriodKey') && (
              <GridItem xs={12}>
                <DictionarySelectField
                  name={typedNameV2<IUpdatePermissionRequest>('permissionValidityPeriodKey')}
                  label={t('permission:field.permissionValidityPeriodKey')}
                  dictionaryName={DomainDictionaryEnum.DURATION_OF_PERMIT}
                  stringValue
                />
              </GridItem>
            )}
            {isFieldVisible('formNumber') && (
              <GridItem xs={12}>
                <TextInputField
                  name={typedNameV2<IUpdatePermissionRequest>('formNumber')}
                  label={t('permission:field.formNumber')}
                  isRequired
                />
              </GridItem>
            )}
            {isFieldVisible('printDate') && (
              <GridItem xs={12}>
                <DatepickerField
                  name={typedNameV2<IUpdatePermissionRequest>('printDate')}
                  label={t('permission:field.printDate')}
                  isRequired
                />
              </GridItem>
            )}
            {isFieldVisible('validFrom') && (
              <GridItem xs={12}>
                <DatepickerField
                  name={typedNameV2<IUpdatePermissionRequest>('validFrom')}
                  label={
                    titleKey === 'permissionGitd' ? t('permission:field.printDate') : t('permission:field.validFrom')
                  }
                  isRequired
                />
              </GridItem>
            )}
            {isFieldVisible('validTo') && (
              <GridItem xs={12}>
                <PermissionValidToField
                  original={{
                    id: initialData.id,
                    permissionValidityPeriodKey: initialData.permissionValidityPeriodKey,
                    validTo: initialData.validTo,
                    validFrom: initialData.validFrom
                  }}
                  name="validTo"
                />
              </GridItem>
            )}
          </GridLayout>
        </form>
      </FormV2Context.Provider>
    </Dialog>
  );
}
