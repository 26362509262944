import { useMemo } from 'react';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import { useMultipleSelectColumn, useTableLayoutProps } from '@libs/common/v2';

import { useAdminMessagesQuery } from '@libs/notification/api';
import { MessageTableSelectAction } from '@libs/notification/components';
import { useAdminMessagesColumns } from '@libs/notification/hooks';
import { useElementVisibility } from '@libs/permission/hooks';

function useAdminMessagesTable() {
  const { columns, mappedFilterFields, mappedSortFields } = useAdminMessagesColumns();
  const { checkIsElementVisible } = useElementVisibility();

  const multipleSelectColumn = useMultipleSelectColumn(MessageTableSelectAction);

  const isDeleteButtonAllowed = useMemo(
    () => checkIsElementVisible(UIElementNameEnum.ANNOUNCEMENT_DELETE_BUTTON),
    [checkIsElementVisible]
  );

  const tablePlugins = useMemo(
    () => (isDeleteButtonAllowed ? [multipleSelectColumn] : []),
    [isDeleteButtonAllowed, multipleSelectColumn]
  );

  return useTableLayoutProps({
    tableHookQuery: useAdminMessagesQuery,
    tableHookOptions: {
      columns,
      tablePlugins,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'created', desc: true }]
        }
      }
    },
    tableHookQueryInitialParams: {
      body: {
        deleted: false
      }
    }
  });
}

export default useAdminMessagesTable;
