import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { DeprecatedThemeOptions } from '@mui/material';
import { createTheme as createMuiTheme, Theme } from '@mui/material/styles';
import { MixinsOptions } from '@mui/material/styles/createMixins';
import _ from 'lodash';

import {
  CommonDefaultThemeOptions,
  CommonDefaultThemesConfig,
  CommonMustHaveThemeOptions,
  themesConfig
} from '@libs/common/v2/configs/layout.config';
import { extendThemeWithMixins, mainThemeVariations } from '@libs/common/v2/utils/theme.utils';

import { useAuth } from '@libs/auth/hooks';

import { useSettings } from '../hooks';

export enum NavbarPositionEnum {
  LEFT = 'left',
  RIGHT = 'right'
}

function updateMainThemeVariations(mainTheme) {
  const themesObj = Object.keys(themesConfig).length !== 0 ? themesConfig : CommonDefaultThemesConfig;
  return mainThemeVariations(themesObj[mainTheme]);
}

function getThemeOptions(themes: Theme[], settings) {
  return {
    mainTheme: themes[settings.theme.main],
    navBarTheme: themes[settings.theme.navBar],
    topBarTheme: themes[settings.theme.topBar],
    footerTheme: themes[settings.theme.footer],
    ...updateMainThemeVariations(settings.theme.main)
  };
}

export function getInitialThemes() {
  const themesObj = Object.keys(themesConfig).length !== 0 ? themesConfig : CommonDefaultThemesConfig;

  const themes = Object.assign(
    {},
    ...Object.entries(themesObj).map(([key, value]) => {
      const muiTheme = _.merge({}, CommonDefaultThemeOptions, value, CommonMustHaveThemeOptions);
      return {
        [key]: createMuiTheme(
          _.merge<any, any, DeprecatedThemeOptions>({}, muiTheme, {
            mixins: extendThemeWithMixins(muiTheme) as MixinsOptions
          })
        )
      };
    })
  );

  return {
    ...themes,
    ...mainThemeVariations(themesObj[initialSettings.theme.main])
  };
}

// eslint-disable-next-line react-hooks/rules-of-hooks
const { getInitialSettings } = useSettings();

const initialSettings = getInitialSettings();
const initialThemes = getInitialThemes();

interface ILayoutContext {
  initial: typeof initialSettings;
  defaults: typeof initialSettings;
  current: typeof initialSettings;
  themes: typeof initialThemes;
  setSettings: (value: any) => void;
  setInitialSettings: (value: any) => void;
  resetDefaultSettings: () => void;
  isFolded: boolean;
  foldNavbar: () => void;
  isMouseInside: boolean;
  setMouseInside: (state: boolean) => void;
  isMobileOpen: boolean;
  openMobile: () => void;
  position: NavbarPositionEnum;
  setNavbarPosition: (position: NavbarPositionEnum) => void;
}

const initialState: ILayoutContext = {
  initial: initialSettings,
  defaults: _.merge({}, initialSettings),
  current: _.merge({}, initialSettings),
  themes: initialThemes,
  setSettings: null,
  setInitialSettings: null,
  resetDefaultSettings: null,
  isFolded: false,
  foldNavbar: null,
  isMouseInside: false,
  setMouseInside: null,
  isMobileOpen: false,
  openMobile: null,
  position: NavbarPositionEnum.LEFT,
  setNavbarPosition: null,
  ...getThemeOptions(initialThemes, initialSettings)
};

export type TypeLayoutContext = typeof initialState;

export const LayoutContext = createContext<TypeLayoutContext>(initialState);

interface ILayoutContextProvider {
  children: ReactNode;
}

function LayoutContextProvider({ children }: ILayoutContextProvider) {
  const [providerState, setProviderState] = useState<ILayoutContext>(initialState);

  const { user } = useAuth();
  const { generateSettings } = useSettings();

  const setSettings = value => {
    const current = generateSettings(providerState.defaults, value);

    const themes =
      current.theme.main !== providerState.current.theme.main
        ? { ...providerState.themes, ...updateMainThemeVariations(current.theme.main) }
        : providerState.themes;

    setProviderState(prevState => ({
      ...prevState,
      current,
      themes,
      ...getThemeOptions(themes, current)
    }));
  };

  const setInitialSettings = value => {
    const defaults = generateSettings(providerState.defaults, value);

    const themes =
      defaults.theme.main !== providerState.defaults.theme.main
        ? { ...providerState.themes, ...updateMainThemeVariations(defaults.theme.main) }
        : providerState.themes;

    setProviderState(prevState => ({
      ...prevState,
      defaults: _.merge({}, defaults),
      current: _.merge({}, defaults),
      themes,
      ...getThemeOptions(themes, defaults)
    }));
  };

  const resetDefaultSettings = () => {
    const themes = { ...providerState.themes, ...updateMainThemeVariations(providerState.defaults.theme.main) };

    setProviderState(prevState => ({
      ...prevState,
      defaults: _.merge({}, prevState.defaults),
      current: _.merge({}, prevState.defaults),
      themes,
      ...getThemeOptions(themes, prevState.defaults)
    }));
  };

  const foldNavbar = () =>
    setProviderState(prev => {
      if (prev.isFolded === false) {
        return { ...prev, isFolded: !prev.isFolded };
      }
      return { ...prev, isFolded: !prev.isFolded, isMouseInside: false };
    });
  const setMouseInside = (state: boolean) => setProviderState(prev => ({ ...prev, isMouseInside: state }));
  const openMobile = () => setProviderState(prev => ({ ...prev, isMobileOpen: !prev.isMobileOpen }));
  const setNavbarPosition = (position: NavbarPositionEnum) => setProviderState(prev => ({ ...prev, position }));

  useEffect(() => {
    setInitialSettings(user?.accountData?.settings || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.accountData?.settings]);

  const values = useMemo(
    () => ({
      ...providerState,
      setSettings,
      setInitialSettings,
      resetDefaultSettings,
      foldNavbar,
      setMouseInside,
      openMobile,
      setNavbarPosition
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [providerState]
  );

  return <LayoutContext.Provider value={values}>{children}</LayoutContext.Provider>;
}

export default LayoutContextProvider;
