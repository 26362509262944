import { ChangeEvent } from 'react';
import { Checkbox as MuiCheckbox, FormHelperText, InputLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { LoaderCircular, useInputStyles } from '@libs/common/v2';
import { Theme, useTheme } from '@libs/common/v2/theme';
import { hexToRgba, important } from '@libs/common/v2/utils';

interface IProps {
  onKeyPress?: (e?: any) => void;
  className?: string;
  checkboxClassName?: string;
  wrapperClassName?: string;
  onClick?: (e?: any) => any;
  onChange?: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onBlur?: (e?: any) => void;
  inputProps?: Record<string, unknown>;
  label?: string;
  isChecked?: boolean;
  isIndeterminate?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRequired?: boolean;
  isError?: boolean;
  isWarning?: boolean;
  helperText?: string;
  value?: unknown;
  rowLayout?: boolean;
  name?: string;
  title?: string;
}

function Checkbox({
  isChecked,
  helperText,
  isIndeterminate,
  isLoading,
  label,
  isDisabled,
  onKeyPress,
  className,
  checkboxClassName,
  wrapperClassName,
  isRequired,
  isError,
  value,
  onChange,
  isWarning,
  rowLayout,
  title,
  ...restProps
}: IProps) {
  const inputStyles = useInputStyles({ isRequired, isDisabled, isError, isWarning });
  const { contrast } = useTheme();

  const classes = useStyles({ isChecked, isIndeterminate, isDisabled, contrast });

  // eslint-disable-next-line consistent-return
  const onKeyPressHandle = e => {
    if (onKeyPress) {
      return onKeyPress(e);
    }

    if (e.key === 'Enter') {
      return onChange(
        { ...e, target: { ...e.target, checked: !e.target.checked } as ChangeEvent<HTMLInputElement> },
        !e.target.checked
      );
    }
  };

  const Checkbox = (
    <MuiCheckbox
      size="small"
      color="default"
      onChange={onChange}
      onKeyPress={onKeyPressHandle}
      classes={{ root: classes.root }}
      className={clsx(className, checkboxClassName)}
      checked={isChecked}
      disabled={isDisabled}
      indeterminate={isIndeterminate}
      title={title}
      {...restProps}
    />
  );

  return (
    <InputLabel
      className={clsx(
        rowLayout ? classes.rowLayoutLabelWrapper : (classes.labelWrapper, className, wrapperClassName),
        inputStyles.root
      )}
    >
      {label && (
        <span>
          {label} {isRequired && <span className={inputStyles.asterisk}>&nbsp;*</span>}
        </span>
      )}
      {isLoading ? (
        <LoaderCircular size={20} isLoading={isLoading}>
          {Checkbox}
        </LoaderCircular>
      ) : (
        Checkbox
      )}
      {helperText && (
        <FormHelperText className={clsx(inputStyles.formHelperText)} error={isError}>
          {helperText}
        </FormHelperText>
      )}
    </InputLabel>
  );
}
const useStyles = makeStyles<
  Theme,
  { isChecked?: boolean; isIndeterminate?: boolean; isDisabled?: boolean; contrast?: boolean }
>(theme => ({
  root: {
    color: ({ isChecked, isIndeterminate, isDisabled, contrast }) => {
      if (isChecked || isIndeterminate) {
        return contrast ? theme.palette.contrastIndicators.background : theme.palette.blue[800];
      }
      if (contrast && isDisabled) {
        return important(hexToRgba(theme.palette.blue[800], 0.5));
      }
      if (isDisabled) {
        return theme.palette.grey[300];
      }
      return theme.palette.grey[500];
    },
    padding: 4,
    width: 28,
    height: '100%'
  },
  labelWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  rowLayoutLabelWrapper: {
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'row-reverse',
    alignItems: 'center'
  }
}));

export default Checkbox;
