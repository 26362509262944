import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupMembershipDetailsExternal } from '@avispon/group/dist/models';
import { useSnackbar } from '@enigma/fe-ui';

import { TableButtonDelete, useConfirmDialog, useTableContext } from '@libs/common/v2';

import { useDeleteUserGroupMutation } from '@libs/user/api';
import { UserUIElementEnum } from '@libs/user/config';

interface IProps {
  original: GroupMembershipDetailsExternal;
}

function UserGroupFormRowActions({ original }: IProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { clear, refetch } = useTableContext();
  const [confirm] = useConfirmDialog();
  const { mutate: deleteUserGroup, isLoading: isDeleteLoading } = useDeleteUserGroupMutation();

  const handleGroupDelete = useCallback((groupMembership: GroupMembershipDetailsExternal) => {
    confirm({
      title: t('user:dialog.deleteGroupMembershipTitle'),
      shortTitle: t('global:dialog.deleteConfirmTitle'),
      message: t('user:dialog.deleteGroupMembershipConfirm', { name: groupMembership.group.name }),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        deleteUserGroup(
          { userId: groupMembership.user?.id, groupMembershipId: groupMembership.id },
          {
            onSuccess: () => {
              showSuccessSnackbar(t('dialog.deleteSuccess'));
              clear();
              refetch();
              closeDialog();
            },
            onSettled: () => {
              setConfirmLoading(false);
            }
          }
        );
      },
      confirmType: 'danger'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableButtonDelete
      onClick={() => handleGroupDelete(original)}
      isButtonLoading={isDeleteLoading}
      actionKey={UserUIElementEnum.USERS_GROUPS_UPDATE}
    />
  );
}

export default UserGroupFormRowActions;
