import { PasswordResetRequestType } from '@avispon/user/dist/models';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '..';

const resetPassword = async (email: string) => {
  const { data } = await API.user.accountController.resetPassword(
    {
      request: {
        type: PasswordResetRequestType.EMAIL,
        value: email
      }
    },
    { ...endpointsConfig.resetPassword }
  );
  return data;
};

export default function useResetPasswordMutation() {
  return useMutation(resetPassword);
}
