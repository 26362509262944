import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { ProceedingAvailableFormsDetails } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import {
  DictionarySelectField,
  FormMode,
  GridLayout,
  typedNameV2,
  Typography,
  useFormV2Context,
  useMultipleSelectColumn,
  useViewModesV2,
  WarningInformation
} from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { useFolderDetailsExtendedQuery } from '@libs/domain/folder';
import PermissionsTab from '@libs/domain/folder/components/details/tabs/permissions/PermissionsTab';
import { FormOperationListItemClient, FormOperationsList, ProceedingTypeKeyEnum } from '@libs/domain/proceeding';

import FormOperationPermissionsTableAction from './FormOperationPermissionsTableAction';
import PermissionTableRowActions from './PermissionTableRowActions';

const getLabel = partialTranslate('proceeding:administrative.tab.formOperations.field');

export enum AuthorizationTypeEnum {
  LICENCE = 'licence',
  PERMIT = 'PERMIT'
}

interface IProps {
  folderId?: string;
  type?: ProceedingTypeKeyEnum;
  isSuspensions?: boolean;
  proceedingId?: string;
  isAddNew?: boolean;
  setAddNew?: (boolean) => void;
  chosenForms?: ProceedingAvailableFormsDetails[];
  setChosenForms?: Dispatch<SetStateAction<[] | ProceedingAvailableFormsDetails[]>>;
  isReasonKeyFieldVisible?: boolean;
  formMode?: FormMode;
}

function FormOperationMainSection({
  folderId,
  type,
  isSuspensions,
  proceedingId,
  isAddNew,
  setAddNew,
  chosenForms,
  setChosenForms,
  isReasonKeyFieldVisible,
  formMode
}: IProps) {
  const [t] = useTranslation();
  const { watch } = useFormV2Context();
  const formIds = watch(typedNameV2<FormOperationListItemClient>('form.ids')) as string[];
  const useMultipleSelectColumnPlugin = useMultipleSelectColumn(FormOperationPermissionsTableAction);
  const { viewMode } = useViewModesV2(formMode);

  const { data: folderDetails } = useFolderDetailsExtendedQuery(folderId, {
    enabled: Boolean(folderId)
  });

  const handleAddForm = () => {
    setAddNew(true);
  };

  if (isAddNew) {
    return (
      <GridLayout itemProps={{ xs: 12 }}>
        <WarningInformation content={t('proceeding:administrative.tab.formOperations.noActiveFormOperationWarning')} />
        <PermissionsTab
          folderId={folderId}
          proceedingTablePlugins={[useMultipleSelectColumnPlugin]}
          folderType={folderDetails?.typeKey}
          isSingleTable
          isDialog
          includePartnershipPermissions
        />
      </GridLayout>
    );
  }

  return (
    <GridLayout itemProps={{ xs: 12 }}>
      {!viewMode && (
        <Typography themeVariant="textSm.medium">
          {t('proceeding:administrative.tab.formOperations.addFormOperationSubtitle')}
        </Typography>
      )}
      {!viewMode && isReasonKeyFieldVisible && (
        <DictionarySelectField
          name={typedNameV2<FormOperationListItemClient>('reasonKey')}
          label={getLabel('reasonKey')}
          dictionaryName={DomainDictionaryEnum.LEGAL_GROUNDS_FOR_PROCEEDINGS}
          isRequired
        />
      )}
      <FormOperationsList
        formMode={formMode}
        type={type}
        suspensions={isSuspensions}
        title={t('proceeding:administrative.tab.formOperations.chosenFormTableTitle')}
        proceedingId={proceedingId}
        headerAction={handleAddForm}
        modalTableData={chosenForms}
        isFormModal
        rowAction={({ original }: Row<ProceedingAvailableFormsDetails>) => (
          <>
            {!viewMode && (
              <PermissionTableRowActions
                row={original}
                formIds={formIds}
                chosenForms={chosenForms}
                setChosenForms={setChosenForms}
                hasDeleteActionVisible
              />
            )}
          </>
        )}
      />
    </GridLayout>
  );
}

export default FormOperationMainSection;
