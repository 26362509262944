import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { queryCache } from 'react-query';
import { Row } from 'react-table';
import { useSnackbar } from '@enigma/fe-ui';

import { FormMode, useConfirmDialog, useTableRowActions } from '@libs/common/v2';

import { DomainDictionaryEntry, DomainUIElementEnum } from '@libs/domain/config';
import {
  TransportManagerQueryKeysEnum,
  TransportManagersActionButtonsEnum,
  useDeleteTransportManagerMutation,
  useRestoreTransportManagerMutation,
  useTransportManagerExpireDialog
} from '@libs/domain/transport-manager';

import { TransportManagerListItemClient } from '../model';

import { useTransportManagerDetailsDialog } from '.';

interface IPermissionsObject {
  [TransportManagersActionButtonsEnum.EDIT]?: DomainUIElementEnum;
  [TransportManagersActionButtonsEnum.DELETE]?: DomainUIElementEnum;
  [TransportManagersActionButtonsEnum.EXPIRE]?: DomainUIElementEnum;
  [TransportManagersActionButtonsEnum.PREVIEW]?: DomainUIElementEnum;
  [TransportManagersActionButtonsEnum.RESTORE]?: DomainUIElementEnum;
}

interface IProps {
  applicationId: string;
  visibleActionButtons?: TransportManagersActionButtonsEnum[];
  folderId?: string;
  transferredFoldersIds?: string[];
  managerModificationTypeKey?: string;
  permissionsKeysObject?: IPermissionsObject;
  isVehiclesAndSubjectsManagementSectionVisible: boolean;
}

function useTransportManagersTableRowActions({
  applicationId,
  visibleActionButtons,
  folderId,
  transferredFoldersIds,
  managerModificationTypeKey,
  permissionsKeysObject,
  isVehiclesAndSubjectsManagementSectionVisible
}: IProps) {
  const [t] = useTranslation();
  const [confirm] = useConfirmDialog();
  const { showSuccessSnackbar } = useSnackbar();
  const { openDetailsDialog } = useTransportManagerDetailsDialog();
  const { openTransportManagerExpireDialog } = useTransportManagerExpireDialog();

  const { mutate: restoreTransportManager, isLoading: isRestoreProcessInProgress } =
    useRestoreTransportManagerMutation();
  const { mutate: deleteTransportManager, isLoading: isDeleteProcessInProgress } = useDeleteTransportManagerMutation();

  const isTableLoading = useMemo(() => {
    return isDeleteProcessInProgress || isRestoreProcessInProgress;
  }, [isDeleteProcessInProgress, isRestoreProcessInProgress]);

  const startRestoreProcess = ({ original }: Row<TransportManagerListItemClient>) => {
    confirm({
      title: t('transportManager:restore.title'),
      message: t('transportManager:restore.confirmationMessage'),
      confirmType: 'danger',
      onConfirm: async (setConfirmLoading: (value: boolean) => void, closeDialog: () => void) => {
        setConfirmLoading(true);
        await restoreTransportManager(
          { id: original.id, version: original.version },
          {
            onSuccess: () => {
              showSuccessSnackbar(t('transportManager:restore.successMessage'));
              queryCache.invalidateQueries(TransportManagerQueryKeysEnum.TRANSPORT_MANAGER_LIST);
              closeDialog();
            },
            onSettled: () => {
              setConfirmLoading(false);
            }
          }
        );
      }
    });
  };

  const startDeleteProcess = ({ original }: Row<TransportManagerListItemClient>) => {
    confirm({
      title: t('transportManager:delete.title'),
      message: t('transportManager:delete.confirmationMessage'),
      confirmType: 'danger',
      onConfirm: async (setConfirmLoading: (value: boolean) => void, closeDialog: () => void) => {
        setConfirmLoading(true);
        await deleteTransportManager(original.id, {
          onSuccess: () => {
            showSuccessSnackbar(t('transportManager:delete.successMessage'));
            queryCache.invalidateQueries(TransportManagerQueryKeysEnum.TRANSPORT_MANAGER_LIST);
            closeDialog();
          },
          onSettled: () => {
            setConfirmLoading(false);
          }
        });
      }
    });
  };

  const openEditDialog = useCallback(
    ({ original }: Row<TransportManagerListItemClient>) => {
      openDetailsDialog({
        id: original.id,
        formMode: FormMode.EDIT,
        applicationId,
        folderId,
        transferredFoldersIds,
        managerModificationTypeKey,
        isVehiclesAndSubjectsManagementSectionVisible,
        permissionsKeysObject: {
          CHECK_REPUTATION: permissionsKeysObject?.[TransportManagersActionButtonsEnum.CHECK_REPUTATION],
          SHOW_REPUTATION: permissionsKeysObject?.[TransportManagersActionButtonsEnum.SHOW_REPUTATION]
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDetailsDialog]
  );

  const openPreviewDialog = useCallback(
    ({ original }: Row<TransportManagerListItemClient>) => {
      openDetailsDialog({
        id: original.id,
        formMode: FormMode.VIEW,
        applicationId,
        folderId,
        transferredFoldersIds,
        managerModificationTypeKey,
        isVehiclesAndSubjectsManagementSectionVisible,
        permissionsKeysObject: {
          CHECK_REPUTATION: permissionsKeysObject?.[TransportManagersActionButtonsEnum.CHECK_REPUTATION],
          SHOW_REPUTATION: permissionsKeysObject?.[TransportManagersActionButtonsEnum.SHOW_REPUTATION]
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDetailsDialog]
  );

  const { renderTableActions } = useTableRowActions<TransportManagerListItemClient>([
    visibleActionButtons?.includes?.(TransportManagersActionButtonsEnum.PREVIEW) && {
      id: permissionsKeysObject?.[TransportManagersActionButtonsEnum.PREVIEW],
      icon: 'ArrowIcon',
      onClick: ({ row }) => openPreviewDialog(row)
    },
    visibleActionButtons?.includes?.(TransportManagersActionButtonsEnum.EDIT) && {
      id: permissionsKeysObject?.[TransportManagersActionButtonsEnum.EDIT],
      label: 'action.edit',
      icon: 'EditIcon',
      isLoading: isTableLoading,
      onClick: ({ row }) => openEditDialog(row)
    },
    visibleActionButtons?.includes?.(TransportManagersActionButtonsEnum.EXPIRE) && {
      id: permissionsKeysObject?.[TransportManagersActionButtonsEnum.EXPIRE],
      label: 'action.expire',
      isLoading: isTableLoading,
      onClick: ({ row }) => openTransportManagerExpireDialog(row.original.id, row.original.version),
      isHidden: ({ row }) => row.original?.statusKey !== DomainDictionaryEntry.TRANSPORT_MANAGER_STATUS.CURRENT,
      icon: 'ExpireIcon'
    },
    visibleActionButtons?.includes?.(TransportManagersActionButtonsEnum.RESTORE) && {
      id: permissionsKeysObject?.[TransportManagersActionButtonsEnum.RESTORE],
      label: 'action.restore',
      isLoading: isTableLoading,
      onClick: ({ row }) => startRestoreProcess(row),
      isHidden: ({ row }) => row.original?.statusKey !== DomainDictionaryEntry.TRANSPORT_MANAGER_STATUS.EXPIRED,
      icon: 'RestoreIcon'
    },
    visibleActionButtons?.includes?.(TransportManagersActionButtonsEnum.DELETE) && {
      id: permissionsKeysObject?.[TransportManagersActionButtonsEnum.DELETE],
      label: 'action.delete',
      isLoading: isTableLoading,
      onClick: ({ row }) => startDeleteProcess(row),
      icon: 'TrashIcon'
    }
  ]);

  const visibleButtons = renderTableActions(permissionsKeysObject ? Object.values(permissionsKeysObject) : []);

  return { visibleButtons };
}

export default useTransportManagersTableRowActions;
