import { QueryCache, useMutation } from 'react-query';
import {
  AttachmentVerificationListDetails as AttachmentVerificationListDetailsClient,
  AttachmentVerificationListUpdateRequest as AttachmentVerificationListUpdateRequestClient
} from '@ibtm/client-domain';
import { AttachmentVerificationListDetails, AttachmentVerificationListUpdateRequest } from '@ibtm/domain';

import { useQueryCache } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { DomainDictionaryEntry } from '@libs/domain/config';

import { AttachmentsQueryKeysEnum } from '../queries';

export interface IUpdateApplicationAttachments {
  applicationId: string;
  notes: string;
  attachments: IAttachments[];
}

interface IAttachments {
  id: string;
  statusKey: string | boolean;
  name: string;
  notes: string;
}

/**
    Filters out non-attachment data from the array of attachments data
    */
const filterOnlyAttachments = (attachmentsData: IAttachments[]) =>
  Object.entries(attachmentsData).filter(([key]) => {
    return key !== 'version' && key !== 'applicationId';
  });

/**
    Updates status of each attachment based on its 'statusKey' property.
    @param attachmentsData array of objects - attachments data
    @returns array of objects - 'id', 'statusKey', 'name', and 'notes'
    */
const attachmentsWithDictionaryStatuses = (attachmentsData: IAttachments[]) => {
  const attachments = filterOnlyAttachments(attachmentsData);
  return attachments
    .map(([key, value]) => {
      if (value?.statusKey === true) {
        return [key, { ...value, statusKey: DomainDictionaryEntry.REQUIRED_FILE_STATUS.ATTACHED }];
      }
      if (value.statusKey === false) {
        return [key, { ...value, statusKey: DomainDictionaryEntry.REQUIRED_FILE_STATUS.MISSING }];
      }
      return [key, { ...value }];
    })
    .map(([key, value]) => ({
      id: key,
      ...(value as { id: string; statusKey: string; name: string; notes: string })
    }));
};

export function updateApplicationAttachments(formData: IUpdateApplicationAttachments, queryCache: QueryCache) {
  const queryData = queryCache.getQueryData<AttachmentVerificationListDetails>([
    AttachmentsQueryKeysEnum.ATTACHMENTS_LIST,
    { applicationId: formData.applicationId }
  ]);

  const requestData: {
    applicationId: string;
    attachmentVerificationListUpdateRequest: AttachmentVerificationListUpdateRequest;
  } = {
    applicationId: formData.applicationId,
    attachmentVerificationListUpdateRequest: {
      notes: formData.notes,
      version: queryData.version,
      verificationElements: attachmentsWithDictionaryStatuses(formData.attachments)
    }
  };

  return API.applicationAttachments.updateApplicationAttachmentsVerificationList(
    requestData.applicationId,
    requestData.attachmentVerificationListUpdateRequest
  );
}

export function updateApplicationAttachmentsClient(formData: IUpdateApplicationAttachments, queryCache: QueryCache) {
  const queryData = queryCache.getQueryData<AttachmentVerificationListDetailsClient>([
    AttachmentsQueryKeysEnum.ATTACHMENTS_LIST,
    { applicationId: formData.applicationId }
  ]);

  const requestData: {
    applicationId: string;
    attachmentVerificationListUpdateRequest: AttachmentVerificationListUpdateRequestClient;
  } = {
    applicationId: formData.applicationId,
    attachmentVerificationListUpdateRequest: {
      notes: formData.notes,
      version: queryData.version,
      verificationElements: attachmentsWithDictionaryStatuses(formData.attachments)
    }
  };

  return API.client.applicationAttachments.updateApplicationAttachmentsVerificationList(
    '',
    '',
    requestData.applicationId,
    requestData.attachmentVerificationListUpdateRequest
  );
}

function useUpdateApplicationAttachments() {
  const queryCache = useQueryCache();
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(
    getQuery(
      (params: IUpdateApplicationAttachments) => updateApplicationAttachmentsClient(params, queryCache),
      (params: IUpdateApplicationAttachments) => updateApplicationAttachments(params, queryCache)
    )
  );
}

export default useUpdateApplicationAttachments;
