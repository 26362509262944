import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRowSelect } from 'react-table';

import { DropdownListButton, useMultipleSelectColumn, useTableAdapter, useTableLayoutProps } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { useResourceObjectAssociationPoolsQuery } from '../../api';
import { AssociationReturnForm, ReleaseForm } from '../../components/forms';
import { useResourceObjectAssociationPoolsColumns } from '../columns';
import { useResourceDialog } from '../dialog';

function SelectionActionRender(selectedIds: string[], handleClose: () => void) {
  return <Actions selectedIds={selectedIds} handleClose={handleClose} />;
}

function Actions({ selectedIds, handleClose }: { selectedIds: string[]; handleClose: () => void }) {
  const [t] = useTranslation();
  const { openDialog } = useResourceDialog();

  const table = useTableAdapter();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedRows = useMemo(() => table.selectedFlatRows.map(item => item.original), [table, selectedIds]);

  const isDisabled = useMemo(
    () => selectedRows.some(item => item.state !== DomainDictionaryEntry.RESOURCE_FORM_STATE.ADOPTED_BY_ASSOCIATION),
    [selectedRows]
  );

  const handleRelease = () => {
    handleClose();
    openDialog(ReleaseForm, { resourceObjects: selectedRows });
  };

  const handleReturn = () => {
    handleClose();
    openDialog(AssociationReturnForm, { resourceObjects: selectedRows });
  };

  return (
    <>
      <DropdownListButton
        label={t('resource:actions.releaseResourceObjects')}
        onClick={() => handleRelease()}
        isDisabled={isDisabled}
        tooltipTitle={isDisabled ? t('resource:messages.resourceFormStateNotAdoptedByAssociation') : undefined}
      />
      <DropdownListButton
        label={t('resource:actions.returnResourceObjects')}
        onClick={() => handleReturn()}
        isDisabled={isDisabled}
        tooltipTitle={isDisabled ? t('resource:messages.resourceFormStateNotAdoptedByAssociation') : undefined}
      />
    </>
  );
}

function useResourceObjectAssociationPoolsTable() {
  const { columns, mappedFilterFields, mappedSortFields } = useResourceObjectAssociationPoolsColumns();
  return useTableLayoutProps({
    tableHookQuery: useResourceObjectAssociationPoolsQuery,
    tableHookOptions: {
      columns,
      tablePlugins: [useRowSelect, useMultipleSelectColumn(SelectionActionRender)],
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'nameKey', desc: false }]
        }
      }
    }
  });
}

export default useResourceObjectAssociationPoolsTable;
