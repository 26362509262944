import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { useSnackbar } from '@enigma/fe-ui';
import { CivicBoardRuleDetails } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import { FormMode, TableButton, TableButtonMore, useConfirmDialog, useDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { DomainUIElementEnum } from '@libs/domain/config';
import { useDeleteTradeRuleMutation } from '@libs/domain/social-commission/api';
import { SocialCommissionQueryKeysEnum } from '@libs/domain/social-commission/config';

import CalculateButton from './CalculateButton';
import DialogBasicLimit from './DialogBasicLimit';

function RowActions({ row }: { row: Row<CivicBoardRuleDetails> }) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: deleteRule } = useDeleteTradeRuleMutation();
  const [confirm] = useConfirmDialog();
  const getLabel = partialTranslate('foreignPermits:tabs.socialCommission.tabs.SocialCommissionBasicRules');
  const { openDialog } = useDialog();

  const onDeleteButtonClick = (closeMore: () => void) => {
    // delete action
    confirm({
      title: getLabel('dialogs.deleteTitle'),
      message: getLabel('dialogs.deleteDescription'),
      confirmType: 'danger',
      onConfirm(setConfirmLoading, closeDialog) {
        setConfirmLoading(true);
        deleteRule(row?.original?.id, {
          onSuccess: () => {
            setConfirmLoading(false);
            queryCache.invalidateQueries(SocialCommissionQueryKeysEnum.TRADE_RULES_DETAILS);
            showSuccessSnackbar(getLabel('dialogs.deleteSucces'));
            closeDialog();
            closeMore();
          }
        });
      }
    });
  };
  const onViewButtonClick = (closeMore: () => void) => {
    openDialog(({ closeDialog }) => (
      <DialogBasicLimit closeDialog={closeDialog} formMode={FormMode.VIEW} id={row?.original?.id} />
    ));
    closeMore();
  };
  const onEditButtonClick = (closeMore: () => void) => {
    openDialog(({ closeDialog }) => (
      <DialogBasicLimit closeDialog={closeDialog} formMode={FormMode.EDIT} id={row?.original?.id} />
    ));
    closeMore();
  };
  return (
    <>
      <TableButtonMore>
        {close => (
          <>
            <TableButton
              actionKey={DomainUIElementEnum.COMMISSION_BASIC_RULES_DETAILS}
              label={t('action.openDetails')}
              onClick={() => onViewButtonClick(close)}
            />
            <TableButton
              actionKey={DomainUIElementEnum.COMMISSION_BASIC_RULES_EDIT}
              label={t('action.edit')}
              onClick={() => onEditButtonClick(close)}
            />
            <TableButton
              actionKey={DomainUIElementEnum.COMMISSION_BASIC_RULES_DELETE}
              label={t('action.delete')}
              onClick={() => onDeleteButtonClick(close)}
            />
          </>
        )}
      </TableButtonMore>

      <CalculateButton
        actionKey={DomainUIElementEnum.COMMISSION_BASIC_RULES_RECALCULATE}
        rule={{
          year: row.original.year,
          resourceTypeId: row.original.resourceTypeId
        }}
      />
    </>
  );
}

export default RowActions;
