import { SuspensionFolderSummary, SuspensionFolderSummarySearchFilter } from '@ibtm/domain';
import { DictionaryEntryNameEnum } from '@libs/config';

import {
  SelectOption,
  TableFilterMapper,
  TableSortMapper,
  useCreateColumns,
  useTableLayoutProps
} from '@libs/common/v2';
import { DateRangeISO, getCalendarDate } from '@libs/common/v2/utils';

import { useSuspensionsSummaryQuery } from '@libs/domain/suspensions';

function useSuspensionsSummaryColumns() {
  const { createColumns } = useCreateColumns<SuspensionFolderSummary>({
    pathToTranslate: 'suspensions:summaryTable.columns'
  });

  const mappedFilterFields: TableFilterMapper<SuspensionFolderSummary, SuspensionFolderSummarySearchFilter> = {
    suspensionNumber: (number: string) => ({ suspensionNumberContains: number }),
    permissionTypeCodeKeys: (typeKeys: SelectOption<string>[]) => ({
      permissionTypeCodeKeyIn: typeKeys.map(({ value }) => value)
    }),
    dateFrom: ({ dateFrom, dateTo }: DateRangeISO) => ({
      dateFromGreaterThanOrEqual: getCalendarDate(dateFrom),
      dateFromLessThanOrEqual: getCalendarDate(dateTo)
    }),
    dateTo: ({ dateFrom, dateTo }: DateRangeISO) => ({
      dateToGreaterThanOrEqual: getCalendarDate(dateFrom),
      dateToLessThanOrEqual: getCalendarDate(dateTo)
    }),
    totalNumberOfMonths: ({ max, min }: { min: number; max: number }) => ({
      totalNumberOfMonthsGreaterThanOrEqual: min,
      totalNumberOfMonthsLessThanOrEqual: max
    })
  };

  const mappedSortFields: TableSortMapper<SuspensionFolderSummary> = {};

  const columns = createColumns([
    {
      accessor: 'suspensionNumber',
      type: 'TEXT'
    },
    {
      accessor: 'permissionTypeCodeKeys',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DictionaryEntryNameEnum.PERMISSION_TYPE_CODE
    },
    {
      accessor: 'dateFrom',
      type: 'DATE'
    },
    {
      accessor: 'dateTo',
      type: 'DATE'
    },
    {
      accessor: 'totalNumberOfMonths',
      type: 'NUMBER_RANGE',
      width: 300
    }
  ]);

  return { columns, mappedFilterFields, mappedSortFields };
}
export function useSuspensionsSummaryTable({ folderId }: { folderId?: string }) {
  const { columns, mappedFilterFields, mappedSortFields } = useSuspensionsSummaryColumns();

  return useTableLayoutProps({
    tableHookQuery: useSuspensionsSummaryQuery,
    tableHookOptions: {
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      columns,
      getRowId: row => JSON.stringify(row)
    },
    ...(folderId
      ? {
          tableHookQueryInitialParams: {
            folderIdIn: [folderId]
          }
        }
      : {})
  });
}
