import { useTranslation } from 'react-i18next';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import { Button, ButtonsGroup } from '@libs/common/v2';

import { OrganizationStructureRoutes, OrganizationStructureUIElementEnum } from '@libs/organization-structure/config';
import { OrganizationUnitView } from '@libs/organization-structure/models';

interface Props {
  isTreeView?: boolean;
  onViewChange?: (viewType: OrganizationUnitView) => void;
}

function OrganizationUnitPageHeaderActions({ isTreeView, onViewChange }: Props) {
  const [t] = useTranslation();

  return (
    <ButtonsGroup>
      {isTreeView ? (
        <Button
          label={t('administration:organizationUnit.viewSwitch.switchToList')}
          variant="outlined"
          onClick={() => onViewChange(OrganizationUnitView.LIST)}
          icon="ListBulletedSquareIcon"
          disabled={!isTreeView}
          actionKey={UIElementNameEnum.ORGANIZATION_STRUCTURE_TREE_VIEW}
        />
      ) : (
        <Button
          label={t('administration:organizationUnit.viewSwitch.switchToTree')}
          variant="outlined"
          onClick={() => onViewChange(OrganizationUnitView.TREE)}
          icon="AlignHorizontalLeftIcon"
          disabled={isTreeView}
          actionKey={UIElementNameEnum.ORGANIZATION_STRUCTURE_TREE_VIEW}
        />
      )}
      <Button
        isPrimary
        variant="contained"
        link={OrganizationStructureRoutes.organizationUnitCreate}
        label={t('administration:organizationUnit.addUnit')}
        actionKey={OrganizationStructureUIElementEnum.ORGANIZATION_STRUCTURE_ADD_BUTTON}
      />
    </ButtonsGroup>
  );
}

export default OrganizationUnitPageHeaderActions;
