import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SCertificateSnapshot as SCertificateSnapshotClient } from '@ibtm/client-domain';
import { SCertificateSnapshot } from '@ibtm/domain';

import { TableLayout, useRouter, useTableRowActions } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { CreateApplicationButton } from '@libs/domain/application';
import { DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import { FolderDetailsParams, useFolderDetailsQuery } from '@libs/domain/folder';
import { SCertificatesSnapshot, useCertificateDetailsDialog, useSCertificatesTable } from '@libs/domain/s-certificates';

interface IProps {
  areHeaderActionsHidden?: boolean;
}

function SCertificatesList({ areHeaderActionsHidden }: IProps) {
  const { folderId } = useParams<FolderDetailsParams>();
  const { data: folderDetails } = useFolderDetailsQuery(folderId, {
    enabled: Boolean(folderId && !areHeaderActionsHidden),
    refetchOnMount: false
  });
  const { isClientPortal } = useDomainConfigContext();
  const [t] = useTranslation();
  const { goToPage, routes } = useRouter();
  const { getQuery } = useGetApiQueryByPerspective();

  const { openDetailsDialog } = useCertificateDetailsDialog();

  const tableProps = useSCertificatesTable(
    { folderIdIn: [folderId] },
    false,
    [
      'statusKey',
      'vehiclePlateNumber',
      'number',
      'vehicleBrandKeyTranslation',
      'euroClassKey',
      'vehicleType',
      'vehicleBrandKey',
      'vehicleModelKey',
      'applicationNumber'
    ],

    false
  );

  const { renderTableActions } = useTableRowActions<SCertificatesSnapshot>([
    {
      id: 'FOLDER_CERTIFICATE_S_DETAILS_BUTTON',
      label: 'action.openDetails',
      ...(isClientPortal
        ? { onClick: ({ row: { original } }) => openDetailsDialog({ id: original?.id }) }
        : {
            link: ({ original }) =>
              routes.sCertificateDetails((original as SCertificateSnapshot | SCertificateSnapshotClient).id)
          }),
      icon: 'ArrowIcon'
    }
  ]);

  const getSCertificates = getQuery(
    params => API.client.sCertificates.getSCertificatesSnapshotPage('', '', params),
    params => API.sCertificates.getSCertificatesSnapshotPage(params)
  );

  return (
    <div>
      <TableLayout
        pageTitle={t('sCertificates:list.title')}
        {...tableProps}
        xlsxDownload={{
          apiRequest: getSCertificates,
          fileName: t('sCertificates:list.title'),
          pathToXLSXTranslation: 'sCertificates:field'
        }}
        headerActions={
          !areHeaderActionsHidden && (
            <CreateApplicationButton
              selectedFolder={folderDetails}
              categoryKey="ibtm-domain.application.category.s-certificate"
              onSuccess={applicationId => {
                goToPage(routes.applicationEdit(applicationId));
              }}
            />
          )
        }
        rowActions={renderTableActions([DomainUIElementEnum.FOLDER_CERTIFICATE_S_DETAILS_BUTTON])}
        isCollapsable
        isSection
      />
    </div>
  );
}

export default SCertificatesList;
