import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ResourceObjectBatchDisposalCreateRequest } from '@ibtm/domain';
import * as Yup from 'yup';

import { partialTranslate } from '@libs/common';
import { Dialog, DictionarySelectField, FormV2Context, GridItem, GridLayout } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { ResourceQueryKeysEnum, useBatchDisposalsMutation } from '../../../api';
import { usePoolsValidationSchema } from '../../../hooks';
import { IBatchDisposalsFormFields, PoolItem, ResourceObjectPoolClient } from '../../../model';
import { PoolsForm } from '../common';

const getLabel = partialTranslate('resource:fields');

interface IProps {
  selectedIds: string[];
  closeDialog: () => void;
}

function BatchDisposalsForm({ selectedIds, closeDialog }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();

  const { mutate: batchDisposals, isLoading } = useBatchDisposalsMutation();

  const lastQuery = queryCache.getQueries([ResourceQueryKeysEnum.RESOURCE_OBJECT_POOLS]).pop();
  const queryData: { content: ResourceObjectPoolClient[] } = queryCache.getQueryData(lastQuery.queryKey);

  const selectedRowsData = useMemo(
    () => queryData.content.filter(item => selectedIds.includes(item.id)),
    [selectedIds, queryData]
  );

  const poolsValidationSchema = usePoolsValidationSchema(selectedRowsData);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        disposalReason: Yup.object().nullable().required(),
        pools: poolsValidationSchema
      })
    ),
    defaultValues: {
      pools: selectedRowsData
    }
  });

  const onSubmit = (formData: IBatchDisposalsFormFields) => {
    const requestData: ResourceObjectBatchDisposalCreateRequest = {
      disposalReasonKey: formData.disposalReason.value,
      resources: formData.pools.map((item, index) => ({
        numberFrom: item.numberFrom,
        numberTo: item.numberTo,
        amount: item.amount,
        resourceTypeId: selectedRowsData[index].resourceType?.id
      }))
    };

    batchDisposals(requestData, {
      onSuccess: () => {
        showSnackbar('success', t('resource:messages.batchDisposalsSuccess'));
        queryCache.invalidateQueries(ResourceQueryKeysEnum.RESOURCE_OBJECT_POOLS);

        closeDialog();
      }
    });
  };
  const pools = watch('pools') as PoolItem[];

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      onCancel={closeDialog}
      onConfirm={() => handleSubmit(data => onSubmit(data as IBatchDisposalsFormFields))()}
      cancelText={t('action.cancel')}
      confirmText={t('action.confirm')}
      isConfirmLoading={isLoading}
      title={t('resource:dialog.batchDisposalsTitle')}
      dialogSize="large"
      isOpen
    >
      <div className="my-12">
        <FormV2Context.Provider value={values}>
          <GridLayout>
            <GridItem xs={pools?.length > 0 ? 6 : 12}>
              <DictionarySelectField
                name="disposalReason"
                label={getLabel('disposalReason')}
                dictionaryName={DomainDictionaryEnum.RESOURCE_FORM_DISPOSAL_REASON}
                isRequired
              />
            </GridItem>
            <GridItem xs={12}>
              <PoolsForm />
            </GridItem>
          </GridLayout>
        </FormV2Context.Provider>
      </div>
    </Dialog>
  );
}

export default BatchDisposalsForm;
