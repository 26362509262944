export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  addSuspensionForm: { translationKey: 'suspensions:api.addSuspensionForm' },
  createSuspension: { translationKey: 'suspensions:api.createSuspension' },
  createSuspensionNote: { translationKey: 'suspensions:api.createSuspensionNote' },
  deleteSuspension: { translationKey: 'suspensions:api.deleteSuspension' },
  updateSuspensionDocument: { translationKey: 'suspensions:api.updateSuspensionDocument' },
  addSuspensionDocument: { translationKey: 'suspensions:api.addSuspensionDocument' },
  restoreSuspensionForms: { translationKey: 'suspensions:api.restoreSuspensionForms' },
  suspendSuspensionForms: { translationKey: 'suspensions:api.suspendSuspensionForms' },
  updateSuspensionStatus: { translationKey: 'suspensions:api.updateSuspensionStatus' },
  updateSuspension: { translationKey: 'suspensions:api.updateSuspension' },
  updateSuspensionNote: { translationKey: 'suspensions:api.updateSuspensionNote' },
  removeSuspensionForm: { translationKey: 'suspensions:api.removeSuspensionForm' },
  getSuspensionFormOperationsPage: { translationKey: 'suspensions:api.getSuspensionFormOperationsPage' },
  getSuspension: { translationKey: 'suspensions:api.getSuspension' },
  getSuspensionDocumentsSnapshotPage: { translationKey: 'suspensions:api.getSuspensionDocumentsSnapshotPage' },
  getSuspensionNote: { translationKey: 'suspensions:api.getSuspensionNote' },
  getSuspensionsPage: { translationKey: 'suspensions:api.getSuspensionsPage' },
  getSuspensionFolderSummaryPage: { translationKey: 'suspensions:api.getSuspensionFolderSummaryPage' }
};
