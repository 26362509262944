import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import { TableContext, TableIconButton, useConfirmDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';
import { FolderLockListItemClient, FolderQueryKeysEnum, useRemoveLockMutation } from '@libs/domain/folder';

function TableRowActions({ lock }: { lock: FolderLockListItemClient }) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();
  const [confirm] = useConfirmDialog();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: removeLock } = useRemoveLockMutation();
  const { refetch } = useContext(TableContext);

  const unblock = () => {
    confirm({
      title: t('folder:details.tab.locks.action.unblock'),
      message: t('folder:details.tab.locks.list.unblockDialogContent', {
        typeKey: translate(DomainDictionaryEnum.FOLDER_LOCK_TYPE, lock?.typeKey)
      }),
      onConfirm: (_, closeDialog) => {
        removeLock(
          {
            folderLockId: lock.id,
            version: lock.version
          },
          {
            onSuccess: () => {
              refetch();
              showSuccessSnackbar(t('folder:details.tab.locks.message.unblockSuccess'));
              closeDialog();
              queryCache.invalidateQueries(FolderQueryKeysEnum.LOCK_LIST);
              queryCache.invalidateQueries(FolderQueryKeysEnum.FOLDER_EXTENDED);
            }
          }
        );
      }
    });
  };

  return (
    lock.isLocked &&
    (lock.typeKey === DomainDictionaryEntry.FOLDER_LOCK_TYPE.BLOCKADE_OF_ISSUANCE_OF_CERTIFICATES ? (
      <TableIconButton
        icon="UnlockIcon"
        tooltipTitle={t('folder:details.tab.locks.action.unblock')}
        onClick={unblock}
        actionKey={UIElementNameEnum.FOLDER_UNBLOCK_PROCEEDING_DRIVER_CERTIFICATE_DELETE_BUTTON}
      />
    ) : (
      <TableIconButton
        icon="UnlockIcon"
        tooltipTitle={t('folder:details.tab.locks.action.unblock')}
        onClick={unblock}
        actionKey={UIElementNameEnum.FOLDER_LOCK_DELETE_BUTTON}
      />
    ))
  );
}

export default TableRowActions;
