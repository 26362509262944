import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';

import {
  BackButton,
  ButtonsGroup,
  FormMode,
  FormV2Context,
  IPropsItem,
  Page,
  PageHeader,
  Tab,
  useBreadcrumbsConverter,
  useBreadcrumbTextByFormMode,
  useRouter,
  useValidationBuilder
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';
import { unescapeValue } from '@libs/common/v2/utils';

import { defaultAlertFormValues, DefaultAlertFromValues, useAlertValidationSchema } from '@libs/alert';
import { useCreateAlertMutation, useEditAlertMutation } from '@libs/alert/api';
import { useAlertForm, useAlertsTabDefinitions } from '@libs/alert/hooks';
import { AlertDetailsTab, AlertNotificationChannel } from '@libs/alert/model/alert.model';
import {
  parseAlertFormValuesToCreateRequest,
  parseAlertFormValuesToEditRequest
} from '@libs/alert/utils/alerts.parsers';

import AlertDetailsPageFormHeader from './AlertDetailsPageFormHeader';
import AlertTabsContent from './AlertTabsContent';

interface IProps {
  id: string;
  formMode: FormMode;
}

function AlertDetailsPageForm({ id, formMode }: IProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { viewMode, editMode, createMode } = useViewModesV2(formMode);
  const { convertBreadcrumbToText } = useBreadcrumbsConverter();
  const [activeTab, setActiveTab] = useState<AlertDetailsTab>(AlertDetailsTab.ALERT_GENERAL_DATA);
  const alertTabs = useAlertsTabDefinitions();
  const { mutate: createAlert, isLoading: isLoadingCreate } = useCreateAlertMutation();
  const { mutate: editAlert, isLoading: isEditLoading } = useEditAlertMutation();
  const isLoading = useMemo(() => isLoadingCreate || isEditLoading, [isLoadingCreate, isEditLoading]);
  const [isEmail, setIsEmail] = useState<boolean>(false);
  const [isUser, setIsUser] = useState<boolean>(false);
  const basicValidationSchema = useAlertValidationSchema(isEmail, isUser);
  const { validationScheme, validationBuilderFunctions } = useValidationBuilder(basicValidationSchema);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, submitCount, dirtyFields },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    control,
    reset,
    formState,
    clearErrors
  } = useForm<Partial<DefaultAlertFromValues>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationScheme),
    defaultValues: defaultAlertFormValues
  });
  useAlertForm({
    id,
    formMode,
    reset,
    getValues,
    setValue,
    defaultAlertFormValues
  });
  const { goToPage, routes } = useRouter();

  const channel = watch('channelKeys');

  useEffect(() => {
    setIsEmail(channel?.some(field => field === AlertNotificationChannel.EMAIL));
    setIsUser(channel?.some(field => field === AlertNotificationChannel.NOTIFICATION));
  }, [channel]);
  const visibleTabHeaders = alertTabs.map(({ label, value }) => (
    <Tab key={value} label={label} value={value} isHorizontalTab />
  ));

  const pageTitle = useMemo(() => {
    if (createMode) {
      return t('alerts:page.create');
    }

    if (editMode) {
      return t('alerts:page.edit');
    }

    return t('alerts:page.view');
  }, [createMode, editMode, t]);

  const alertName = getValues('name');

  const breadcrumbText = useBreadcrumbTextByFormMode(
    {
      createValue: t('alerts:page.create'),
      editValue: t('alerts:page.breadcrumbEdit', unescapeValue({ name: alertName })),
      viewValue: t('alerts:page.breadcrumbView', unescapeValue({ name: alertName }))
    },
    formMode
  );

  const breadcrumbs = useMemo(
    (): Array<IPropsItem> => [
      {
        to: '/administration/notifications-configuration',
        text: t('alerts:title')
      },
      { text: convertBreadcrumbToText(breadcrumbText) }
    ],
    [t, convertBreadcrumbToText, breadcrumbText]
  );

  const handleSubmitAlert = useCallback(
    (values: Partial<DefaultAlertFromValues>) => {
      if (createMode) {
        createAlert(parseAlertFormValuesToCreateRequest(_.cloneDeep(values)), {
          onSuccess: notification => {
            showSuccessSnackbar(t('alerts:message.alertCreateSuccess'));
            goToPage(routes.notificationConfigurationDetails(notification?.id));
          }
        });
      } else {
        editAlert(
          { id, formData: parseAlertFormValuesToEditRequest(_.cloneDeep<Partial<DefaultAlertFromValues>>(values)) },
          {
            onSuccess: () => {
              showSuccessSnackbar(t('alerts:message.alertEditSuccess'));
              goToPage(routes.notificationConfigurationDetails(id));
            }
          }
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createMode]
  );

  return (
    <FormV2Context.Provider
      value={useMemo(() => {
        return {
          control,
          errors,
          handleSubmit,
          register,
          setValue,
          watch,
          getValues,
          trigger,
          unregister,
          isSubmitting,
          formMode,
          submitCount,
          validationBuilderFunctions,
          formState,
          clearErrors
        };
      }, [
        control,
        errors,
        formMode,
        getValues,
        handleSubmit,
        isSubmitting,
        register,
        setValue,
        submitCount,
        trigger,
        unregister,
        validationBuilderFunctions,
        watch,
        formState,
        clearErrors
      ])}
    >
      <Page
        header={
          <PageHeader
            title={pageTitle}
            breadcrumbs={breadcrumbs}
            rightSideContent={
              <ButtonsGroup>
                {viewMode && <BackButton link={routes.notificationsConfigurationList()} isNoMargin />}
                <AlertDetailsPageFormHeader
                  id={id}
                  formMode={formMode}
                  isLoading={isLoading}
                  handleSubmitAlert={handleSubmit(handleSubmitAlert)}
                />
              </ButtonsGroup>
            }
          />
        }
        content={
          <AlertTabsContent
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            visibleTabHeaders={visibleTabHeaders}
            dirtyFields={dirtyFields}
            isViewMode={viewMode}
          />
        }
      />
    </FormV2Context.Provider>
  );
}

export default AlertDetailsPageForm;
