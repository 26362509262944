import { useEffect, useMemo, useState } from 'react';
import { AttachmentVerificationElementDetails } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import { HintsAutocompleteField, InputMode, Section, useFormV2Context } from '@libs/common/v2';

import { DictionaryEntryNameEnum, DictionaryEntrySnapshot, useDictionary } from '@libs/dictionary';
import { useFormDirtyContext } from '@libs/meta-form/context';
import { useElementVisibility } from '@libs/permission';

import { DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';

import { MAIN_COMMENT_FIELD_NAME } from '../model/common.model';

import AttachmentsMatrix from './AttachmentsMatrix';

export interface IAttachmentsSection {
  applicationId?: string;
  fieldId?: string;
  applicationType?: string;
}
interface AdditionalProps {
  verificationElements: AttachmentVerificationElementDetails[];
  isLoading: boolean;
}

function AttachmentsSection({
  applicationId,
  fieldId,
  applicationType,
  isLoading,
  verificationElements
}: IAttachmentsSection & AdditionalProps) {
  const getLabel = partialTranslate('applications:additionalField');
  const [dictionaries, setDictionaries] = useState<DictionaryEntrySnapshot['value'][]>([]);
  const { reset } = useFormV2Context();
  const { setIsDirty } = useFormDirtyContext();

  const { checkIsElementVisible } = useElementVisibility();

  const dictonariesEntries = useDictionary(dictionaries as DictionaryEntryNameEnum[]);
  const applicationTypesWL = useDictionary(DomainDictionaryEnum.APPLICATION_TYPE_WL);

  const isApplicationWL = useMemo(() => {
    return applicationTypesWL?.entries?.some(({ value }) => value === applicationType);
  }, [applicationType, applicationTypesWL]);

  // Dane z backend wstawione do form
  useEffect(() => {
    if (verificationElements?.length) {
      if (isApplicationWL) {
        const dictionariesNames = verificationElements
          .map(val => {
            return val.attachmentNotesDictionary as DictionaryEntrySnapshot['value'];
          })
          .filter(Boolean);
        setDictionaries(dictionariesNames);
      } else {
        setDictionaries([DomainDictionaryEnum.REMARK_SK]);
      }
    }
  }, [isApplicationWL, reset, verificationElements]);

  const onMainCommentSelectOptionChange = () => {
    setIsDirty(true);
  };

  return (
    <>
      <AttachmentsMatrix
        applicationId={applicationId}
        fieldId={fieldId}
        isEditNoteButton
        isLoading={isLoading}
        verificationElements={verificationElements}
        isOnlyBooleanColumn
      />
      <Section title={getLabel('notes')} isCollapsable>
        <HintsAutocompleteField
          name={MAIN_COMMENT_FIELD_NAME}
          lines={5}
          customDictionaryEntriesList={Object?.keys(dictonariesEntries)?.flatMap(
            key => dictonariesEntries?.[key]?.entries
          )}
          onOptionSelected={onMainCommentSelectOptionChange}
          isHintAutocomleteFieldAboveInput
          inputMode={
            !checkIsElementVisible(DomainUIElementEnum.APPLICATION_ATTACHMENTS_EDIT_NOTE_BUTTON) && InputMode.VIEW
          }
        />
      </Section>
    </>
  );
}

export default AttachmentsSection;
