import { QueryConfig, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { useGetApiQueryByPerspective } from '@libs/domain/api';

import API from '../endpoints';
import { endpointsConfig } from '../endpoints-config';

import { FilesQueryKeysEnum } from './QueryKeysEnum';

export const downloadFile = (params: { fileId: string }) => {
  return API.downloadFile(params, { responseType: 'blob', ...endpointsConfig.downloadFile });
};
export const downloadFileClient = (params: { fileId: string }) => {
  return API.client.downloadFile(params, { responseType: 'blob', ...endpointsConfig.downloadFile });
};

export default function useDownloadQuery(
  params: { fileId: string; isClientPortal?: boolean },
  queryConfig: QueryConfig<AxiosResponse<Blob>, unknown> = {}
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery(
    [FilesQueryKeysEnum.DOWNLOAD_FILE, params],
    getQuery(
      () => downloadFileClient(params),
      () => downloadFile(params)
    ),
    queryConfig
  );
}
