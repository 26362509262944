import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PageDocumentTemplateSnapshotExternal } from '@avispon/document-generator';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ApplicationDocumentCreateRequest } from '@ibtm/domain';
import { useAppConfig } from '@libs/app-config';
import _ from 'lodash';
import * as Yup from 'yup';

import {
  AutocompleteLazyFetchSelectField,
  Dialog,
  DialogContentContainer,
  DictionarySelectField,
  FormV2Context,
  GridLayout,
  InputMode,
  typedNameV2
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { API, useDocumentTemplatesQuery } from '@libs/document-template';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';
import { ReleaseDocumentsQueryKeysEnum, useGenerateDocumentMutation } from '@libs/domain/release-documents/api';

import { getDocumentTemplateName, isWZZ } from '../../utils';

type FormTypes = { documentTemplateId: { name: string; value: { id: string } }; receiptTypeKey: any };

function GenerateApplicationDocumentDialog({
  invoiceId,
  closeDialog,
  handleDownloadFile,
  isCorrection,
  documentNumber,
  isWWU
}: {
  invoiceId: string;
  closeDialog: () => void;
  handleDownloadFile: (fileId: string) => void;
  isCorrection?: boolean;
  documentNumber?: string;
  isWWU?: boolean;
}) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { templateNames } = useAppConfig();

  const documentTemplateName = useMemo(
    () => getDocumentTemplateName(templateNames, isCorrection, isWZZ(documentNumber), isWWU),
    [templateNames, isCorrection, documentNumber, isWWU]
  );

  const { mutate: generateDocument } = useGenerateDocumentMutation();

  const { data: initialDocumentTemplate } = useDocumentTemplatesQuery({
    nameFragment: documentTemplateName
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    reset
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        documentType: Yup.object().nullable(),
        documentTemplateId: Yup.object().nullable().required()
      })
    ),
    defaultValues: {
      documentType: DomainDictionaryEntry[DomainDictionaryEnum.DOCUMENT_TEMPLATE_GROUP].ISSUE_DOCUMENT,
      documentTemplateId: null
    }
  });

  const documentTypeWatcher = watch(`documentType`) as string;

  useEffect(() => {
    const templateId = _.get<PageDocumentTemplateSnapshotExternal, string, string>(
      initialDocumentTemplate,
      'content.0.id',
      null
    );

    if (templateId) {
      reset({
        ...getValues(),
        documentTemplateId: {
          name: documentTemplateName,
          value: { id: templateId }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDocumentTemplate]);

  useEffect(() => {
    if (!documentTemplateName) {
      setValue('documentTemplateId', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTypeWatcher]);

  const onSubmit = (formData: FormTypes, setConfirmLoading) => {
    setConfirmLoading(true);

    generateDocument(
      {
        documentTemplateId: formData.documentTemplateId?.value?.id,
        invoiceId
      },
      {
        onSuccess: response => {
          handleDownloadFile(response.data.file.id);
          queryCache.invalidateQueries(ReleaseDocumentsQueryKeysEnum.RELEASE_DOCUMENTS_LIST);
          showSnackbar('success', t('document:message.generateDocumentSuccess'));
          closeDialog();
        },
        onSettled: () => {
          setConfirmLoading(false);
        }
      }
    );
  };

  const getOptionLabel = option => {
    return option.name;
  };

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('document:action.dialogTitle')}
      confirmText={t('action.generate')}
      cancelText={t('action.cancel')}
      onConfirm={setConfirmLoading =>
        handleSubmit(formData => {
          onSubmit(formData as FormTypes, setConfirmLoading);
        })()
      }
      onCancel={closeDialog}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <DialogContentContainer>
          <GridLayout itemProps={{ xs: 12 }}>
            <DictionarySelectField
              name="documentType"
              label={t('document:fields.documentGroup')}
              dictionaryName={DomainDictionaryEnum.DOCUMENT_TEMPLATE_GROUP}
              isDisabled
              inputMode={InputMode.VIEW}
              optionsFilter={option => option.active}
            />
            <AutocompleteLazyFetchSelectField
              name={typedNameV2<ApplicationDocumentCreateRequest>('documentTemplateId')}
              queryKey="DOCUMENT_TEMPLATES"
              optionLabelParser={getOptionLabel}
              api={{
                FETCH: (searchText: string, params: { page: number; size: number }) =>
                  API.documentGenerator
                    .findTemplates({
                      ...params,
                      nameFragment: searchText,
                      groupKeys: [DomainDictionaryEntry[DomainDictionaryEnum.DOCUMENT_TEMPLATE_GROUP].ISSUE_DOCUMENT],
                      includeWithKeys: true
                    })
                    .then(response => ({
                      ...response,
                      data: {
                        ...response.data,
                        content: response.data.content
                      }
                    }))
              }}
              isRequired
              label={t('document:fields.documentType')}
            />
          </GridLayout>
        </DialogContentContainer>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default GenerateApplicationDocumentDialog;
