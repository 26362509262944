import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@libs/common/v2';
import { useTheme } from '@libs/common/v2/theme';

interface IProps {
  className?: string;
}

function ToggleThemeButton({ className }: IProps) {
  const [t] = useTranslation();
  const { contrast, setContrast } = useTheme();

  const ariaLabel = contrast ? t('action.defaultTheme') : t('action.contrastTheme');

  return (
    <IconButton
      className={className}
      icon="ContrastIcon"
      onClick={setContrast}
      tooltipTitle={ariaLabel}
      isBackgroundTransparent
    />
  );
}

export default ToggleThemeButton;
