import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { DriversApiUpdateDriverRequest as DriversApiUpdateDriverRequestClient } from '@ibtm/client-domain';
import { DriversApiCreateDriverRequest, DriversApiUpdateDriverRequest } from '@ibtm/domain';

import { FormMode, FormV2Context, Section, typedNameV2, useViewModesV2 } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { RecursivePartial } from '@libs/common/v2/utils';

import { useGetApiQueryByPerspective } from '@libs/domain/api';
import { ApplicationQueryKeysEnum } from '@libs/domain/application';
import { DomainDictionaryEntry } from '@libs/domain/config';
import { DriversQueryKeysEnum, useAddDriverMutation, useEditDriverMutation } from '@libs/domain/drivers/api';
import { SetValuesForWarnings } from '@libs/domain/drivers/hooks';
import { DriverDetailsClient, IDriverForm } from '@libs/domain/drivers/model';
import {
  defaultValues,
  handleAfterAddCheckFlags,
  parseDriverData,
  parseFormToDriverUpdateRequest,
  validation
} from '@libs/domain/drivers/utils';

import DriverLicense from './sections/DriverLicense';
import GeneralData from './sections/GeneralData';
import IdentityDocument from './sections/IdentityDocument';

interface IProps {
  data: {
    driverId?: string; // edycja kierowcy
    application?: {
      id?: string; // tworzenie i dodanie kierowcy do wniosku
      subjectNip?: string;
    };
  };
  initialData?: RecursivePartial<DriverDetailsClient>;
  onSuccess: () => void;
  onLoading: (val: boolean) => void;
  formMode: FormMode;
  isWarningVisible: boolean;
  setValuesForWarning: SetValuesForWarnings;
  checkAndShowDialog: (onConfirm: (setConfirmLoading: any, closeDialog: any) => void) => void;
  driverDetails: DriverDetailsClient;
  isDriverDetailsFetching: boolean;
}

function DriverForm(
  {
    data,
    formMode,
    onSuccess,
    onLoading,
    initialData,
    setValuesForWarning,
    checkAndShowDialog,
    isWarningVisible,
    driverDetails,
    isDriverDetailsFetching
  }: IProps,
  ref
) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const [areDetailsDownloaded, setAreDetailsDownloaded] = useState<boolean>(false);
  const { createMode } = useViewModesV2(formMode);

  const { mutate: addDriver } = useAddDriverMutation();
  const { mutate: editDriver } = useEditDriverMutation();
  const { getQuery } = useGetApiQueryByPerspective();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    setError,
    watch,
    getValues,
    trigger,
    reset,
    unregister
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validation()),
    defaultValues
  });

  useEffect(() => {
    if (!createMode) {
      reset({ ...initialData, ...driverDetails });
      setValue(typedNameV2<IDriverForm>('applicationId'), data.application?.id);
      setValue(typedNameV2<IDriverForm>('driverLicense.qualifications'), driverDetails?.driverLicense.qualifications);
      setValue(typedNameV2<IDriverForm>('driverLicense.indefinite'), driverDetails?.driverLicense.indefinite);
      setValue(typedNameV2<IDriverForm>('driverLicense.code'), driverDetails?.driverLicense.code);
      setValue(
        typedNameV2<IDriverForm>('driverLicense.expiryDateCompetenceCard'),
        driverDetails?.driverLicense.expiryDateCompetenceCard
      );
      if (driverDetails) {
        setAreDetailsDownloaded(true);
      }
    } else {
      setValue(typedNameV2<IDriverForm>('identityCard.typeKey'), DomainDictionaryEntry.IDENTITY_DOCUMENT_TYPE.PASSPORT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverDetails, createMode, reset]);

  const onSubmit = () => {
    onLoading(true);

    if (createMode) {
      if (isWarningVisible) {
        checkAndShowDialog((_setConfirmLoading, closeDialog) => {
          closeDialog?.();
          const requestData: DriversApiCreateDriverRequest = parseDriverData(
            data.application?.id,
            getValues() as IDriverForm
          );
          addDriver(requestData, {
            onSuccess: () => {
              handleAfterAddCheckFlags(data.application?.id, getQuery, showErrorSnackbar, t);
              queryCache.invalidateQueries(DriversQueryKeysEnum.DRIVERS_LIST);
              if (data.application.id) {
                queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, data.application?.id]);
              }

              onSuccess?.();
              showSuccessSnackbar(t('drivers:messages.addDriverSuccess'));
            },
            onSettled: () => {
              onLoading(false);
            }
          });
        });
      } else {
        const requestData: DriversApiCreateDriverRequest = parseDriverData(
          data.application?.id,
          getValues() as IDriverForm
        );
        addDriver(requestData, {
          onSuccess: () => {
            handleAfterAddCheckFlags(data.application?.id, getQuery, showErrorSnackbar, t);
            queryCache.invalidateQueries(DriversQueryKeysEnum.DRIVERS_LIST);
            if (data.application.id) {
              queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, data.application?.id]);
            }
            onSuccess?.();

            showSuccessSnackbar(t('drivers:messages.addDriverSuccess'));
          },
          onSettled: () => {
            onLoading(false);
          }
        });
      }
    } else {
      const requestData: DriversApiUpdateDriverRequest & DriversApiUpdateDriverRequestClient = {
        driverId: driverDetails.id,
        xFolderId: '',
        xSubjectId: '',
        driverUpdateRequest: {
          ...parseFormToDriverUpdateRequest(getValues() as IDriverForm),
          version: driverDetails.version,
          applicationId: data.application.id
        }
      };

      editDriver(requestData, {
        onSuccess: () => {
          queryCache.invalidateQueries(DriversQueryKeysEnum.DRIVERS_LIST);
          onSuccess?.();
          showSuccessSnackbar(t('drivers:messages.editDriverSuccess'));
        },
        onSettled: () => {
          onLoading(false);
        }
      });
    }
  };

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit(onSubmit)();
    },
    isLoading: isDriverDetailsFetching
  }));

  const values = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      setError,
      trigger,
      unregister,
      isSubmitting,
      formMode,
      loading: isDriverDetailsFetching
    }),
    [
      control,
      errors,
      formMode,
      getValues,
      isDriverDetailsFetching,
      isSubmitting,
      register,
      setError,
      setValue,
      trigger,
      unregister,
      watch
    ]
  );
  return (
    <FormV2Context.Provider value={values}>
      <Section isModalSection isHeaderVisible={false} isLoading={isDriverDetailsFetching} isPadded={false}>
        <form>
          <GeneralData
            subjectNip={data?.application?.subjectNip}
            setValuesForWarning={setValuesForWarning}
            areDetailsDownloaded={areDetailsDownloaded}
          />
          <IdentityDocument setValuesForWarning={setValuesForWarning} />
          <DriverLicense />
        </form>
      </Section>
    </FormV2Context.Provider>
  );
}

export default forwardRef(DriverForm);
