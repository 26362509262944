import { useMemo } from 'react';
import { FieldNamesMarkedBoolean, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DataSourceMediatorDetailsSnapshot, QueryColumnProperties } from '@stack/report';
import clsx from 'clsx';

import {
  EditIcon,
  EmptyCardContent,
  FileTreeIcon,
  FormMode,
  GridItem,
  GridLayout,
  LabeledSwitch,
  Section,
  SectionTitle,
  useFormV2Context,
  useViewModesV2
} from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';

import { useAlertConditionsSection } from '@libs/alert/hooks';
import { ConditionAdvanced, ConditionBasic, ConditionBasicView } from '@libs/report-core';
import { useReportDataSourceColumns } from '@libs/report-core/hooks';

import AlertColumns from './AlertColumns';

interface Props {
  formMode: FormMode;
  dirtyFields?: FieldNamesMarkedBoolean<Record<string, any>>;
  name: FieldPath<FieldValues>;
  queryName?: FieldPath<FieldValues>;
  isParameterizable?: boolean;
}

function ConditionAlertSection({ formMode, dirtyFields, name, queryName, isParameterizable }: Props) {
  const [t] = useTranslation();
  const { errors, watch } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);
  const { basicMode, noConditionsDefined, handleModeChange } = useAlertConditionsSection({
    name,
    queryName,
    formMode,
    dirtyFields,
    basicModeName: 'basicMode'
  });

  const classes = useStyles();
  const source = watch('source');
  const sourceId = source?.id;
  const sourceColumns = useReportDataSourceColumns(source as DataSourceMediatorDetailsSnapshot);
  const columnsNames = watch(`queryDefinition.columns`)?.map((column: QueryColumnProperties) => column.name);
  const columns = columnsNames?.length ? sourceColumns.filter(column => columnsNames?.includes(column.name)) : [];

  const headerContent = (
    <LabeledSwitch
      className="w-auto whitespace-nowrap"
      leftLabel={
        <div className="flex items-center justify-center pl-4 pr-8">
          <FileTreeIcon width={20} height={20} className="mr-8" />
          {t('alerts:section.basicView')}
        </div>
      }
      rightLabel={
        <div className="flex items-center justify-center pl-4 pr-8">
          <EditIcon width={20} height={20} className="mr-8" />
          {t('alerts:section.advancedView')}
        </div>
      }
      onChange={handleModeChange}
      value={basicMode}
    />
  );

  const footerContent =
    errors.query && typeof errors?.query?.message === 'string' ? (
      <span className={clsx(classes.error, 'w-full px-8 py-4')}>{errors.query.message}</span>
    ) : null;

  const renderEmptyDataLayout = (
    <div>
      <div className="flex justify-center w-full my-20">
        <Typography variant="caption">{t('alerts:sqlField.noDataSourceSelected')}</Typography>
      </div>
    </div>
  );

  const renderNoColumnsSelectedLayout = (
    <div>
      <div className="flex justify-center w-full my-20">
        <Typography variant="caption">{t('alerts:message.noColumnsSelected')}</Typography>
      </div>
    </div>
  );

  const renderConditionBasicView = useMemo(() => {
    return noConditionsDefined ? (
      <EmptyCardContent text={t('alerts:sqlField.noConditionsDefined')} />
    ) : (
      <ConditionBasicView name={name} columns={columns} />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noConditionsDefined]);

  const renderConditionBasic = useMemo(() => {
    return viewMode ? (
      renderConditionBasicView
    ) : (
      <ConditionBasic name={name} columns={columns} formMode={formMode} isParameterizable={isParameterizable} />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderConditionBasicView, columns, viewMode]);

  const renderConditionsLayout = useMemo(() => {
    return basicMode ? (
      renderConditionBasic
    ) : (
      <ConditionAdvanced dataSetIndex={0} name={queryName} isReadonly={viewMode} />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicMode, renderConditionBasic]);

  return !viewMode && basicMode ? (
    <GridLayout>
      <GridItem xs={12} md={4}>
        <Section
          title={
            <SectionTitle
              className={clsx('py-0', errors?.condition && classes.error)}
              title={t('reports:reportTypes.columnList')}
            />
          }
        >
          {!sourceId ? renderEmptyDataLayout : <AlertColumns sourceColumns={sourceColumns} source={source} />}
        </Section>
      </GridItem>
      <GridItem xs={12} md={8}>
        <Section
          title={
            <SectionTitle
              className={clsx('py-0', errors?.condition && classes.error)}
              title={t('alerts:section.requirements')}
            />
          }
          headerContent={headerContent}
          footerContent={footerContent}
          className="h-full"
        >
          {!columns.length ? renderNoColumnsSelectedLayout : renderConditionBasic}
        </Section>
      </GridItem>
    </GridLayout>
  ) : (
    <Section
      title={
        <>
          <SectionTitle
            className={clsx('py-0', errors?.condition && classes.error)}
            title={t('alerts:section.requirements')}
          />
          {!viewMode && !basicMode ? <span className={classes.error}>*</span> : null}
        </>
      }
      headerContent={!viewMode && headerContent}
      footerContent={footerContent}
      className="h-full"
    >
      {!sourceId || (basicMode && !columns.length) ? renderEmptyDataLayout : renderConditionsLayout}
    </Section>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  error: {
    color: theme.palette.error[700],
    textAlign: 'left'
  }
}));

export default ConditionAlertSection;
