import { TestOptionsMessage } from 'yup';

import { partialTranslate } from '@libs/common';
import {
  DictionarySelectField,
  GridItem,
  GridLayout,
  InputMode,
  NumberInputField,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { PoolFormData } from '../../../model';
import { handlePoolsChange } from '../../../utils';

const getLabel = partialTranslate('resource:fields');

type InputProperties = { min?: number; max?: number };
interface IProps {
  withWrapper?: boolean;
  inputsProps?: {
    numberFrom?: InputProperties;
    numberTo?: InputProperties;
    amount?: InputProperties;
  };
  isTravelForm?: boolean;
}

function PoolsForm({ inputsProps, withWrapper = true, isTravelForm }: IProps) {
  const { amount, numberFrom, numberTo } = inputsProps ?? {};
  const { getValues, setValue } = useFormV2Context();
  const { pools } = getValues() as PoolFormData;

  const onNumberFieldsChange = (event: React.ChangeEvent<HTMLInputElement>, name: string, index: number) => {
    const { value } = event.target;

    handlePoolsChange[name](value, pools, index, setValue);
  };

  const validation: { required?: [message?: TestOptionsMessage<Record<string, unknown>, any>] } = { required: [] };
  const content = (
    <>
      {pools?.map((pool, index) => (
        <GridLayout containerProps={{ className: 'items-start' }} key={pool.id}>
          {!isTravelForm && (
            <GridItem xs={12} md={4}>
              <DictionarySelectField
                name={typedNameV2<PoolFormData>(`pools.${index}.name` as const)}
                label={getLabel('name')}
                dictionaryName={DomainDictionaryEnum.RESOURCE_FORM_NAME}
                inputMode={InputMode.VIEW}
                validationSingleSelect={validation}
                isRequired
              />
            </GridItem>
          )}
          <GridItem xs={12} md={isTravelForm ? 4 : 3}>
            <NumberInputField
              name={typedNameV2<PoolFormData>(`pools.${index}.numberFrom` as const)}
              label={getLabel('numberFrom')}
              onChange={event => onNumberFieldsChange(event, 'numberFrom', index)}
              min={numberFrom?.min || 0}
              max={numberFrom?.max}
              validation={validation}
              isClearable
              isRequired
            />
          </GridItem>
          <GridItem xs={12} md={isTravelForm ? 4 : 3}>
            <NumberInputField
              name={typedNameV2<PoolFormData>(`pools.${index}.numberTo` as const)}
              label={getLabel('numberTo')}
              onChange={event => onNumberFieldsChange(event, 'numberTo', index)}
              min={numberTo?.min || 0}
              max={numberTo?.max}
              validation={validation}
              isClearable
              isRequired
            />
          </GridItem>
          <GridItem xs={12} md={isTravelForm ? 4 : 2}>
            <NumberInputField
              name={typedNameV2<PoolFormData>(`pools.${index}.amount` as const)}
              label={getLabel('amount')}
              onChange={event => onNumberFieldsChange(event, 'amount', index)}
              min={amount?.min || 1}
              max={amount?.max}
              validation={validation}
              isClearable
              isRequired
            />
          </GridItem>
        </GridLayout>
      ))}
    </>
  );
  return withWrapper ? <div className="my-12">{content}</div> : content;
}

export default PoolsForm;
