import { ForwardedRef, forwardRef } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MisspellApplicationDataRecordUpdateRequest } from '@ibtm/domain';

import {
  DatepickerField,
  FormMode,
  GridLayout,
  InputMode,
  TextInputField,
  typedNameV2,
  ValidatorEnums
} from '@libs/common/v2';

import { AddressSection } from '@libs/domain/address';

import { useMisspellApplicationPermissionFormFieldsValidation } from '../../hooks';
import { MisspellApplicationPermissionTypeEnum } from '../../model/misspell-application.model';

import { MisspellApplicationFormProvider } from './MisspellApplicationFormProvider';

interface IProps {
  permissionData: Partial<MisspellApplicationDataRecordUpdateRequest>;
  permissionType: Partial<MisspellApplicationPermissionTypeEnum>;
  formMode: FormMode;
}

export const MisspellApplicationPermissionFormFields = forwardRef(
  (
    { formMode, permissionData, permissionType }: IProps,
    ref: ForwardedRef<UseFormHandleSubmit<MisspellApplicationDataRecordUpdateRequest>>
  ) => {
    const [t] = useTranslation();

    const { validToFieldValidation } = useMisspellApplicationPermissionFormFieldsValidation();

    const isLicense = permissionType === MisspellApplicationPermissionTypeEnum.LICENSE;

    const isLicenseCopy = permissionType === MisspellApplicationPermissionTypeEnum.LICENSE_COPY;

    return (
      <MisspellApplicationFormProvider<MisspellApplicationDataRecordUpdateRequest>
        ref={ref}
        formMode={formMode}
        permissionData={permissionData}
      >
        <GridLayout itemProps={{ xs: 12 }}>
          <TextInputField
            name={typedNameV2<MisspellApplicationDataRecordUpdateRequest>('number')}
            label={t('applications:misspellApplication.fields.number')}
            validation={{ required: [] }}
            inputProps={{ maxLength: 20 }}
          />
          <DatepickerField
            name={typedNameV2<MisspellApplicationDataRecordUpdateRequest>('dateOfIssue')}
            label={t('applications:misspellApplication.fields.dateOfIssue')}
            validation={{ required: [] }}
          />
          {(isLicense || isLicenseCopy) && (
            <DatepickerField
              name={typedNameV2<MisspellApplicationDataRecordUpdateRequest>('validFrom')}
              label={t('applications:misspellApplication.fields.validFrom')}
              validation={{ required: [], isDateValid: [] }}
            />
          )}
          {(isLicense || isLicenseCopy) && (
            <DatepickerField
              name={typedNameV2<MisspellApplicationDataRecordUpdateRequest>('validTo')}
              label={t('applications:misspellApplication.fields.validTo')}
              validation={validToFieldValidation}
            />
          )}
          <TextInputField
            name={typedNameV2<MisspellApplicationDataRecordUpdateRequest>('name')}
            label={t('applications:misspellApplication.fields.name')}
            validation={{ required: [] }}
            {...(isLicenseCopy && { inputMode: InputMode.VIEW })}
          />

          <TextInputField
            name={typedNameV2<MisspellApplicationDataRecordUpdateRequest>('nip')}
            label={t('applications:misspellApplication.fields.nip')}
            validation={{ required: [], contextValidator: [ValidatorEnums.NIP] }}
            inputProps={{ maxLength: 10 }}
            isOnlyPositiveIntegers
            {...(isLicenseCopy && { inputMode: InputMode.VIEW })}
          />

          <AddressSection
            hiddenFields={[
              'voivodeshipKey',
              'firstLine',
              'commune',
              'countryCodeKey',
              'secondLine',
              'version',
              'county'
            ]}
            isCountryFieldAvaliable={false}
            {...(isLicenseCopy && { inputProps: { inputMode: InputMode.VIEW } })}
          />
        </GridLayout>
      </MisspellApplicationFormProvider>
    );
  }
);
