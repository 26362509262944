import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { queryCache } from 'react-query';
import { VehicleSnapshot } from '@ibtm/domain';

import {
  FormActionEnum,
  FormMode,
  TableButton,
  TableButtonMore,
  TableHeaderButton,
  TableIconButton,
  TableLayout,
  useFormActionModal,
  useRouter,
  useViewModesV2
} from '@libs/common/v2';

import { useFormDirtyContext, useMetaFormContext } from '@libs/meta-form/context';

import { DomainDictionaryEntry, DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import {
  useVehicleDetailsDialog,
  useVehiclesQuery,
  VehicleSearchActionButton,
  VehicleSectionFields
} from '@libs/domain/vehicle';

import { ApplicationQueryKeysEnum } from '../../../../../api';
import { useApplicationVehiclesTable, useCertificateVehicleTabContext } from '../../hooks';
import { RemoveVehicleButton } from '../buttons';

function ApplicationVehiclesTable({
  hiddenFieldsAddVehicle,
  applicationType,
  applicationCategory,
  isPreserveHiddenRightOfDisposition = false
}: {
  hiddenFieldsAddVehicle?: VehicleSectionFields[];
  applicationType: string;
  applicationCategory: string;
  isPreserveHiddenRightOfDisposition?: boolean;
}) {
  const { isOperatorPortal } = useDomainConfigContext();
  const hiddenFieldsEditVehicle = hiddenFieldsAddVehicle.filter(
    item => isPreserveHiddenRightOfDisposition || item !== VehicleSectionFields.RIGHT_OF_DISPOSITION_DATE
  );

  const [t] = useTranslation();
  const { isDirty } = useFormDirtyContext();
  const { formRef } = useMetaFormContext();
  const { viewMode } = useViewModesV2();
  const saveChangesAction = useFormActionModal();
  const { folderType, applicationId, issuingCertificate, folderId, transferredFoldersIds } =
    useCertificateVehicleTabContext();
  const tableProps = useApplicationVehiclesTable(applicationId);
  const { openDetailsDialog } = useVehicleDetailsDialog(hiddenFieldsAddVehicle);
  const { openDetailsDialog: openEditVehicleDialog } = useVehicleDetailsDialog(hiddenFieldsEditVehicle);
  const { routes, goToPage } = useRouter();
  const isCertificateDuplicate =
    applicationType === DomainDictionaryEntry.APPLICATION_TYPE.sCertificateIssuingDuplicate;
  const isSCertificateResumingIssueOfDocument =
    applicationType === DomainDictionaryEntry.APPLICATION_TYPE.sCertificateResumingIssueOfDocument;
  const isSCertificateChangeOfDocument =
    applicationType === DomainDictionaryEntry.APPLICATION_TYPE.sCertificateChangeOfDocument;

  const shouldVehicleBeActive = !isSCertificateResumingIssueOfDocument && !isSCertificateChangeOfDocument;

  const vehicleInitialStatusKeys = useMemo(() => {
    return shouldVehicleBeActive ? [DomainDictionaryEntry.VEHICLE_STATUS.ACTIVE] : [];
  }, [shouldVehicleBeActive]);

  const { data } = useVehiclesQuery(
    {
      folderIdIn: [folderId, ...(transferredFoldersIds || [])],
      hasAnyCertificateWithNumber: true
    },
    { enabled: !isOperatorPortal && isCertificateDuplicate }
  );

  const hasAddVehicleButton =
    !viewMode && ((!isOperatorPortal && isCertificateDuplicate && data?.content?.length === 0) || issuingCertificate);

  const hasSearchVehicleButton =
    isOperatorPortal &&
    !viewMode &&
    [
      DomainDictionaryEntry.APPLICATION_TYPE.sCertificateIssuingDuplicate,
      DomainDictionaryEntry.APPLICATION_TYPE.sCertificateResumingIssueOfDocument,
      DomainDictionaryEntry.APPLICATION_TYPE.sCertificateChangeOfDocument
    ].includes(applicationType);

  const handleOpenVehicleDialog = (vehicle?: VehicleSnapshot) => {
    if (vehicle) {
      openEditVehicleDialog({
        folderType,
        applicationId,
        applicationCategory,
        folderId: null,
        formMode: FormMode.EDIT,
        id: vehicle.id,
        initialData: vehicle
      });
    } else {
      openDetailsDialog({ folderType, applicationId, folderId: null, formMode: FormMode.CREATE, applicationCategory });
    }
  };

  const handleSaveFormChanges = async (vehicle?: VehicleSnapshot) => {
    await formRef?.current?.onSubmit();
    handleOpenVehicleDialog(vehicle);
  };

  const handleDiscardFormChanges = async (vehicle?: VehicleSnapshot) => {
    await formRef?.current?.onReset();
    handleOpenVehicleDialog(vehicle);
  };

  const handleAddVehicle = (vehicle?: VehicleSnapshot, close?: () => void) => {
    if (isDirty) {
      saveChangesAction({
        formAction: FormActionEnum.SAVE_CHANGES,
        onConfirm: () => handleSaveFormChanges(vehicle),
        onCancel: () => handleDiscardFormChanges(vehicle)
      });
    } else {
      handleOpenVehicleDialog(vehicle);
    }

    close?.();
  };

  const handleOpenDetails = useCallback(
    (original: VehicleSnapshot, close?: () => void) => {
      close?.();
      goToPage(routes.sCertificateDetails(original.certificateSId));
    },
    [routes, goToPage]
  );

  const rowActions = useCallback(({ original }: { original: VehicleSnapshot }) => {
    if (viewMode) {
      if ((!isCertificateDuplicate && original.certificateSExpirationDate) || original.certificateSExpirationDate) {
        return (
          <TableIconButton
            icon="ArrowIcon"
            tooltipTitle={t('action.openDetails')}
            onClick={() => handleOpenDetails(original)}
            actionKey={DomainUIElementEnum.APPLICATION_S_CERTIFICATES_SHOW_DETAILS_BUTTON}
          />
        );
      }
    } else {
      return (
        <>
          <RemoveVehicleButton
            vehicleId={original.id}
            actionKey={DomainUIElementEnum.APPLICATION_S_CERTIFICATE_DELETE_VEHICLE_BUTTON}
          />
          {(!isCertificateDuplicate && original.certificateSExpirationDate) || original.certificateSExpirationDate ? (
            <TableButtonMore>
              {close => (
                <>
                  <TableButton
                    label={t('action.edit')}
                    onClick={() => handleAddVehicle(original, close)}
                    actionKey={DomainUIElementEnum.APPLICATION_S_CERTIFICATES_EDIT_BUTTON}
                  />
                  <TableButton
                    label={t('action.openDetails')}
                    onClick={() => handleOpenDetails(original, close)}
                    actionKey={DomainUIElementEnum.APPLICATION_S_CERTIFICATES_SHOW_DETAILS_BUTTON}
                  />
                </>
              )}
            </TableButtonMore>
          ) : (
            <TableIconButton
              icon="EditIcon"
              tooltipTitle={t('action.edit')}
              onClick={() => handleAddVehicle(original)}
              actionKey={DomainUIElementEnum.APPLICATION_S_CERTIFICATES_EDIT_BUTTON}
            />
          )}
        </>
      );
    }

    return null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSCertificateStatusesByApplicationType = useMemo(() => {
    switch (applicationType) {
      case DomainDictionaryEntry.APPLICATION_TYPE.sCertificateIssuingDuplicate:
        return [DomainDictionaryEntry.CERTIFICATE_S_STATUS.COMPLETE];
      case DomainDictionaryEntry.APPLICATION_TYPE.sCertificateChangeOfDocument:
        return [DomainDictionaryEntry.CERTIFICATE_S_STATUS.COMPLETE];
      case DomainDictionaryEntry.APPLICATION_TYPE.sCertificateResumingIssueOfDocument:
        return [
          DomainDictionaryEntry.CERTIFICATE_S_STATUS.COMPLETE,
          DomainDictionaryEntry.CERTIFICATE_S_STATUS.NONACTIVATED,
          DomainDictionaryEntry.CERTIFICATE_S_STATUS.REMIT
        ];
      default:
        return [];
    }
  }, [applicationType]);

  return (
    <TableLayout<VehicleSnapshot, unknown>
      {...tableProps}
      pageTitle={t('applications:certificates.applicationVehiclesTitle')}
      headerActions={
        <>
          {hasAddVehicleButton && (
            <TableHeaderButton
              isFullWidth={false}
              label={t('vehicle:addVehicleButtonContent')}
              variant="outlined"
              onClick={() => handleAddVehicle()}
              actionKey={DomainUIElementEnum.APPLICATION_S_CERTIFICATE_ADD_VEHICLE_BUTTON}
            />
          )}
          {hasSearchVehicleButton && (
            <VehicleSearchActionButton
              isDirty={isDirty}
              certificateStatusList={getSCertificateStatusesByApplicationType}
              applicationId={applicationId}
              applicationType={applicationType}
              applicationCategory={applicationCategory}
              actionKey={DomainUIElementEnum.APPLICATION_VEHICLES_SEARCH_VEHICLE_BUTTON}
              statusKeys={vehicleInitialStatusKeys}
              onSuccess={() => queryCache.invalidateQueries(ApplicationQueryKeysEnum.APPLICATION)}
            />
          )}
        </>
      }
      rowActions={rowActions}
      isActionColumnEnabled={rowActions.length > 0}
      {...(viewMode && { actionsColumnWidth: 70 })}
      isSection
    />
  );
}

export default ApplicationVehiclesTable;
