import React from 'react';
import { useTranslation } from 'react-i18next';

import { DictionaryEntry, DictionaryEntryNameEnum, findDictionaryEntryName, useDictionary } from '@libs/dictionary';

const typedMemo: <T>(c: T) => T = React.memo;
interface DictionaryValueProps<T> {
  dictionaryEntryType: T;
  value: string;
}

function DictionaryValue({ dictionaryEntryType, value }: DictionaryValueProps<any>) {
  const [t] = useTranslation();
  const dictionary: DictionaryEntry = useDictionary(dictionaryEntryType as DictionaryEntryNameEnum);

  return <span>{value ? findDictionaryEntryName(dictionary, value) || value : t('emptyMark')}</span>;
}

export default typedMemo(DictionaryValue);
