import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SortingRule } from 'react-table';
import { Table } from '@enigma/fe-ui';
import { LicenseCopySearchFilter, PermissionTypeCode } from '@ibtm/domain';

import { useElementVisibility } from '@libs/permission';

import { API } from '@libs/domain/api';

import { DomainDictionaryEntry, DomainUIElementEnum } from '../../config';
import { PrintActionType, usePrintsActionsContext, usePrintsDetailsDataContext } from '../../print/context';
import { usePrintActionHandler } from '../../print/hooks';
import { usePrintsRegenerateAction } from '../../print/hooks/usePrintsRegenerateAction';
import { LicensesQueryKeysEnum } from '../api';
import { endpointsConfig } from '../api/endpoints-config';
import { DEFAULT_COLUMNS } from '../config/license-copies-columns';
import { useEditLicenseCopyDialog } from '../hooks/tables/modals/useEditLicenseCopyDialog';
import { useLicenseCopiesTableColumnsV2 } from '../hooks/tables/useLicenseCopiesTableColumnsV2';
import { LicenseCopiesColumnsEnum, LicenseCopySnapshotClient } from '../model/license.model';
import { parseLicenseCopiesList } from '../parsers/common';

interface IProps {
  applicationId?: string;
  folderId?: string;
  proceedingId?: string;
  visibleColumns?: Array<LicenseCopiesColumnsEnum>;
  headerActions?: ({ permissionIds, refetch }: { permissionIds?: string[]; refetch?: () => void }) => ReactNode[];
  maxRowActionNumber?: number;
  includePartnershipPermissions?: boolean;
  defaultSort?: SortingRule<LicenseCopySnapshotClient>[];
  isPrintDisabled?: boolean;
}

export function LicenseCopiesTableV2({
  applicationId,
  folderId,
  proceedingId,
  visibleColumns = DEFAULT_COLUMNS,
  maxRowActionNumber,
  headerActions,
  includePartnershipPermissions,
  defaultSort,
  isPrintDisabled = false
}: IProps) {
  const [t] = useTranslation();
  const { openEditLicenseCopyDialog } = useEditLicenseCopyDialog();
  const { checkIsElementVisible } = useElementVisibility();

  const { columns, mappedFilterFields, mappedSortFields } = useLicenseCopiesTableColumnsV2({
    visibleColumns
  });

  const tableHookQueryInitialParams: LicenseCopySearchFilter = {
    applicationIdIn: applicationId ? [applicationId] : undefined,
    folderIdIn: folderId ? [folderId] : undefined,
    ...(proceedingId && { proceedingId }),
    includePartnershipPermissions: includePartnershipPermissions ? true : undefined
  };

  const { handlePrintRegenerate } = usePrintsRegenerateAction();
  const { print } = usePrintActionHandler();
  const { setAction } = usePrintsActionsContext();

  const {
    printDetailsData: {
      depotMPD: { id: depotMPDId },
      notDepotMPDTooltip
    }
  } = usePrintsDetailsDataContext();

  const isRegenerateDisabled = !depotMPDId;

  const editLicenseCopy = useCallback(
    ({ rowData, refetch }: { rowData: LicenseCopySnapshotClient; refetch?: () => void }) => {
      openEditLicenseCopyDialog(
        {
          id: rowData.id,
          validFrom: rowData.validFrom as unknown as string,
          validTo: rowData.validTo as unknown as string,
          printDate: rowData.printDate as unknown as string,
          version: rowData.version
        },
        refetch
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Table<LicenseCopySnapshotClient, LicenseCopySearchFilter>
      tableTitle={t('license:title.licenseCopies')}
      variant="section"
      columns={columns}
      query={{
        request: async params => {
          const { data } = await API.license.getLicenseCopySnapshotPage(
            {
              ...params,
              ...tableHookQueryInitialParams
            },
            {
              ...endpointsConfig.getLicenseCopySnapshotPage
            }
          );

          const response = {
            data: {
              ...data,
              content: parseLicenseCopiesList(data.content)
            }
          };
          return response;
        },

        queryKey: LicensesQueryKeysEnum.LICENSE_COPIES_LIST,
        filterParamsConverter: mappedFilterFields,
        sortParamConverter: mappedSortFields,
        initParams: tableHookQueryInitialParams
      }}
      initialTableState={{
        sorting: defaultSort
      }}
      xlsxDownload={{
        fileName: t('license:title.licenseCopies'),
        apiRequest: async params => {
          const { data } = await API.license.getLicenseCopySnapshotPage(
            {
              ...params,
              ...tableHookQueryInitialParams
            },
            {
              ...endpointsConfig.getLicenseCopySnapshotPage
            }
          );

          const response = {
            data: {
              ...data,
              content: parseLicenseCopiesList(data.content)
            }
          };
          return response;
        },
        filterParamsConverter: mappedFilterFields,
        sortParamConverter: mappedSortFields
      }}
      maxNumberOfIconsInActionRow={maxRowActionNumber}
      headerActions={({ tableData, refetch }) =>
        headerActions({ permissionIds: tableData?.map(row => row.id), refetch })
      }
      selectedRowActions={[
        {
          title: t('action.print'),
          onClick: ({ selectedRows, refetch, unselectAllRows, setIsLoading }) => {
            setIsLoading(true);
            print(
              {
                permissionTypeCode: PermissionTypeCode.LICENSE_COPY,
                permissionIds: selectedRows.map(row => row.id)
              },
              {
                onSuccess: () => {
                  unselectAllRows();
                  refetch();
                },
                onSettled: () => {
                  setIsLoading(false);
                }
              }
            );
          }
        },
        {
          title: t('prints:actions.regenerate'),
          onClick: ({ selectedRows, refetch, unselectAllRows }) => {
            setAction(PrintActionType.Regenerate, {
              resourcesReassignments: {
                permissionTypeCode: PermissionTypeCode.LICENSE_COPY,
                resources: selectedRows?.map(row => ({
                  applicationId,
                  resourceObjectId: row.form.id,
                  assignmentTypeKey: DomainDictionaryEntry.GENERATOR_ASSIGNMENT_TYPE.PERMISSION_COPY
                }))
              },
              onSuccess: () => {
                unselectAllRows();
                refetch();
              }
            });
          }
        }
      ]}
      rowActions={[
        {
          label: isRegenerateDisabled ? notDepotMPDTooltip : t('prints:actions.regenerate'),
          tooltip: isRegenerateDisabled ? notDepotMPDTooltip : t('prints:actions.regenerate'),
          isDisabled: isRegenerateDisabled || !applicationId,
          onClick: ({ rowData, refetch }) => {
            handlePrintRegenerate({
              applicationId,
              data: rowData,
              onSuccess: refetch,
              isMultipleFormNumberToRegenerateInRow: false,
              permissionTypeCode: PermissionTypeCode.LICENSE_COPY
            });
          },
          icon: 'CalculatorIcon'
        },
        {
          label: t('action.print'),
          tooltip: isPrintDisabled ? t('prints:messages.printActionDisabled') : t('action.print'),
          isDisabled: isPrintDisabled,
          onClick: ({ rowData, setLoading, refetch }) => {
            setLoading(true);
            print(
              { permissionTypeCode: PermissionTypeCode.LICENSE_COPY, permissionIds: [rowData.id] },
              {
                onSettled: () => {
                  setLoading(false);
                  refetch();
                }
              }
            );
          },
          icon: 'PrintIcon'
        },
        {
          label: t('action.edit'),
          onClick: editLicenseCopy,
          icon: 'EditIcon',
          isHidden: !checkIsElementVisible(DomainUIElementEnum.PRINT_DATA_UPDATE_BUTTON)
        }
      ]}
    />
  );
}
