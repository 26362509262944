export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  addMoneyTransfersToApplication: { translationKey: 'pricing:api.addMoneyTransfersToApplication' },
  deleteMoneyTransfersFromApplication: { translationKey: 'pricing:api.deleteMoneyTransfersFromApplication' },
  linkMoneyTransfersToApplication: { translationKey: 'pricing:api.linkMoneyTransfersToApplication' },
  unlinkMoneyTransfersFromApplication: { translationKey: 'pricing:api.unlinkMoneyTransfersFromApplication' },
  updateMoneyTransferApplication: { translationKey: 'pricing:api.updateMoneyTransferApplication' },
  updatePricing: { translationKey: 'pricing:api.updatePricing' },
  getPricingDetails: { translationKey: 'pricing:api.getPricingDetails' },
  getMoneyTransfersForApplication: { translationKey: 'pricing:api.getMoneyTransfersForApplication' },
  getMoneyTransfersSnapshotPage: { translationKey: 'pricing:api.getMoneyTransfersSnapshotPage' },
  createMoneyTransfer: { translationKey: 'pricing:api.createMoneyTransfer' },
  deleteMoneyTransfer: { translationKey: 'pricing:api.deleteMoneyTransfer' }
};
