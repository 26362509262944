import { QueryConfig, useQuery } from 'react-query';
import { ApplicationForeignPermissionVehiclesSnapshot } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import ApplicationQueryKeysEnum from './ApplicationQueryKeysEnum';

export const getApplicationForeignPermissionVehicles = async (applicationId: string) => {
  const { data } = await API.application.getApplicationForeignPermissionVehicles(applicationId);
  return data;
};

export function useApplicationForeignPermissionVehicles(
  applicationId: string,
  queryConfig: QueryConfig<ApplicationForeignPermissionVehiclesSnapshot, unknown> = {}
) {
  return useQuery<ApplicationForeignPermissionVehiclesSnapshot>(
    [ApplicationQueryKeysEnum.APPLICATION, applicationId],
    () => getApplicationForeignPermissionVehicles(applicationId),
    queryConfig
  );
}
