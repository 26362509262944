import { PrintActionsContextProvider, PrintsDetailsDataContextProvider } from '../../context';

import PrintDetailsFormWrapper from './PrintDetailsFormWrapper';
import PrintDetailsPageContent from './PrintDetailsPageContent';

function PrintDetailsPage() {
  return (
    <PrintsDetailsDataContextProvider>
      <PrintActionsContextProvider>
        <PrintDetailsFormWrapper>
          <PrintDetailsPageContent />
        </PrintDetailsFormWrapper>
      </PrintActionsContextProvider>
    </PrintsDetailsDataContextProvider>
  );
}

export default PrintDetailsPage;
