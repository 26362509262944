import { GroupMembershipDetailsExternal } from '@avispon/group/dist/models';

export interface ReplacementsSnapshot {
  id?: string;
  statusKey?: string;
  startDateTime?: string;
  endDateTime?: string;
  substitute?: { id: string; name?: string };
  groupMemberships?: Array<GroupMembershipDetailsExternal>;
  modified?: string;
  replaced?: { id: string; name?: string };
  version?: number;
}

export const replacementsFormInitialValues = {
  substituteAccountId: {
    id: '',
    name: ''
  },
  groupMembershipsIds: [],
  startDateTime: '',
  endDateTime: ''
};
