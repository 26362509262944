import { QueryConfig, useQuery } from 'react-query';
import { LastResourceAssigmentDetailsList } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import PrintsQueryKeysEnum from './PrintsQueryKeysEnum';

const previewLastGenerateResourceAssignment = async (_: any, applicationId: string) => {
  const { data } = await API.printouts.previewLastGenerateResourceAssignment(applicationId, {
    ...endpointsConfig.previewLastGenerateResourceAssignment
  });
  return data;
};

const usePreviewLastGenerateResourceAssignment = (
  applicationId: string,
  queryConfig?: QueryConfig<LastResourceAssigmentDetailsList, unknown>
) =>
  useQuery(
    [PrintsQueryKeysEnum.PRINTS_LAST_PRINTED_PERMISSIONS, applicationId],
    previewLastGenerateResourceAssignment,
    queryConfig
  );

export default usePreviewLastGenerateResourceAssignment;
