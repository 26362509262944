import { useEffect } from 'react';

const useRedirectToRouteWithBaseRoute = (isLoggedOut: boolean) => {
  useEffect(() => {
    if (!window.location.pathname.startsWith(process.env.PUBLIC_URL || '')) {
      if (isLoggedOut) {
        window.location.href = `${process.env.PUBLIC_URL}/login`;
      } else {
        window.location.href = process.env.PUBLIC_URL + window.location.pathname;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useRedirectToRouteWithBaseRoute;
