import { usePaginatedQuery } from 'react-query';
import { ProxiesSearchFilter as ProxiesSearchFilterClient } from '@ibtm/client-domain';
import { ProxiesSearchFilter, ProxySnapshot } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { parseProxyListClientPayload, parseProxyListPayload } from '../../utils';
import { endpointsConfig } from '../endpoints-config';

import { ProxyQueryKeysEnum } from './QueryKeysEnum';

type ISearchParams = { folderId?: string; applicationId?: string } & (ProxiesSearchFilterClient | ProxiesSearchFilter);

export const getProxies = async (_, params: ISearchParams) => {
  const queryParams = parseProxyListPayload(params);
  const { data } = await API.proxy.getProxiesSnapshotPage(queryParams, {
    ...endpointsConfig.getProxiesSnapshotPage
  });
  return data;
};
export const getProxiesClient = async (_, params: ISearchParams) => {
  const queryParams = parseProxyListClientPayload(params);
  const { data } = await API.client.proxy.getProxiesSnapshotPage('', '', queryParams, {
    ...endpointsConfig.getProxiesSnapshotPage
  });
  return data;
};

export default function useProxiesQuery(params: ISearchParams) {
  const { getQuery } = useGetApiQueryByPerspective();
  return usePaginatedQuery<IPaginatedModel<ProxySnapshot>>(
    [ProxyQueryKeysEnum.PROXIES_LIST, params],
    getQuery(getProxiesClient, getProxies)
  );
}
