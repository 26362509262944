import { QueryConfig, useQuery } from 'react-query';
import { FolderForeignPermissionVehiclesSnapshot } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import { FinancialSecurityQueryKeysEnum } from './FinancialSecurityQueryKeysEnum';

const getFolderForeignPermissionVehicles = async (_: any, { folderId }) => {
  const { data } = await API.folder.getFolderForeignPermissionVehicles(folderId, {
    ...endpointsConfig.getFolderForeignPermissionVehicles
  });
  return data;
};

const getFolderForeignPermissionVehiclesClient = async () => {
  const { data } = await API.client.folder.getFolderForeignPermissionVehicles('', '', {
    ...endpointsConfig.getFolderForeignPermissionVehicles
  });
  return data;
};

export default function useForeignVehiclesQuery(
  params: { folderId: string },
  queryConfig: QueryConfig<any, unknown> = {}
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery<FolderForeignPermissionVehiclesSnapshot>(
    [FinancialSecurityQueryKeysEnum.FOREIGN_VEHICLES, params],
    getQuery(getFolderForeignPermissionVehiclesClient, getFolderForeignPermissionVehicles),
    queryConfig
  );
}
