import { useMemo } from 'react';
import { ApplicationDetails as ApplicationDetailsClient } from '@ibtm/client-domain';
import { ApplicationDetails } from '@ibtm/domain';

import { useElementVisibility } from '@libs/permission';

import { DomainDictionaryEntry, DomainUIElementEnum } from '@libs/domain/config';

import { PermissionTableEnum } from '../model';
import { getProcessList } from '../utils';

const useVisiblePermissionTables = (
  applicationDetails?: ApplicationDetails | ApplicationDetailsClient
): {
  isVisible: { [key in PermissionTableEnum]: boolean };
  checkIsVisible: (applicationDetails?: ApplicationDetails | ApplicationDetailsClient) => {
    [key in PermissionTableEnum];
  };
} => {
  const { checkIsElementVisible } = useElementVisibility();
  return useMemo(() => {
    const {
      PB2A,
      PB2B,
      PB5,
      PB14,
      PB26,
      PB29,
      PB30,
      PB31,
      PB32,
      PB33,
      DRIVER_CERTIFICATE,
      SPO_OUTSIDE_UE,
      SPO_UE,
      SPO_WZO,
      SPO_WZW,
      SPO_TRANSIT_RP,
      SPO_POSITION_MIND,
      SPO_OWN_NEEDS,
      FOREIGN_PERMITS,
      EKMT_CARNETS,
      S_CERTIFICATE,
      TRAVEL_FORM,
      SPO_OWN_NEEDS_ONLY_EXTRACT,
      SPO_OUTSIDE_UE_ONLY_EXTRACT,
      SPO_TRANSIT_RP_ONLY_EXTRACT,
      SPO_UE_ONLY_EXTRACT,
      SPO_WZO_ONLY_EXTRACT,
      SPO_WZW_ONLY_EXTRACT
    } = getProcessList();

    const checkIsVisible = (application?: ApplicationDetails | ApplicationDetailsClient) => {
      const additionalPermissionCreationApplied = (application as ApplicationDetails)?.licenseApplicationDetails
        ?.additionalPermissionCreationApplied;

      const checkIsPermissionAvailableForApplicationType = (
        ...applicationTypesList: (typeof DomainDictionaryEntry.APPLICATION_TYPE)[keyof typeof DomainDictionaryEntry.APPLICATION_TYPE][][]
      ) => {
        if (!application) return false;
        const { typeKey: applicationTypeKey } = application as ApplicationDetails;
        return [].concat(...applicationTypesList).includes(applicationTypeKey);
      };
      const { APPLICATION_TYPE } = DomainDictionaryEntry;
      const { PB1A, PB1B, PB1C, PB1D } = APPLICATION_TYPE;
      const { PB3A } = APPLICATION_TYPE;
      const { PB4A, PB4B, PB4C, PB4D, PB4E, PB4F } = APPLICATION_TYPE;
      const { PB6A, PB6B, PB6C, PB6D, PB6E, PB6F } = APPLICATION_TYPE;
      const { PB7A, PB7B, PB7C } = APPLICATION_TYPE;
      const { PB8A, PB8B, PB8C } = APPLICATION_TYPE;
      const { PB9A, PB9B, PB9C } = APPLICATION_TYPE;
      const { PB11A, PB11B, PB11D } = APPLICATION_TYPE;
      const { PB12 } = APPLICATION_TYPE;
      const { SPO_WZO_APPLICATION } = APPLICATION_TYPE;
      const { spoWzwApplication } = APPLICATION_TYPE;

      const additionalPermissionCreationApplications = [PB4A, PB6B, PB6D, PB7A];
      return {
        [PermissionTableEnum.LISENSE]:
          checkIsElementVisible(DomainUIElementEnum.APPLICATION_PERMISSIONS_LICENSE_TABLE) &&
          checkIsPermissionAvailableForApplicationType(
            [PB1A, PB1B, PB1C],
            PB26,
            [PB4A, PB4B, PB4C, PB4D],
            PB5,
            [PB6A, PB6B, PB6C, PB6D],
            [PB7A, PB7B],
            [PB8A, PB8B],
            [PB9A, PB9B],
            PB14,
            PB29,
            PB30,
            PB31,
            PB32,
            PB33,
            [APPLICATION_TYPE.PB4A, APPLICATION_TYPE.PB4B, APPLICATION_TYPE.PB4C, APPLICATION_TYPE.PB4D]
          ),
        [PermissionTableEnum.GITD]:
          checkIsElementVisible(DomainUIElementEnum.APPLICATION_PERMISSIONS_GITD_TABLE) &&
          checkIsPermissionAvailableForApplicationType(
            [
              APPLICATION_TYPE.PB1A,
              APPLICATION_TYPE.PB4B,
              APPLICATION_TYPE.PB4C,
              APPLICATION_TYPE.PB5,
              APPLICATION_TYPE.PB6A,
              APPLICATION_TYPE.PB6C,
              APPLICATION_TYPE.PB7B,
              APPLICATION_TYPE.PB14
            ],
            [PB1D],
            [PB4B, PB4C, PB4E, PB4F],
            [PB7C],
            [PB6E, PB6F],
            [PB8A, PB8B, PB8C],
            [PB9A, PB9B, PB9C],
            additionalPermissionCreationApplied ? additionalPermissionCreationApplications : []
          ),
        [PermissionTableEnum.EXTERNAL_AUTHORITY_PERMISSION]:
          checkIsElementVisible(DomainUIElementEnum.APPLICATION_PERMISSIONS_COUNTRY_TABLE) &&
          checkIsPermissionAvailableForApplicationType(
            [PB1A, PB1B, PB1C],
            PB2A,
            PB2B,
            [PB3A],
            [PB4A, PB4D],
            PB5,
            [PB6B, PB6D],
            [PB7A],
            [PB11A, PB11B, PB11D],
            [PB12],
            PB14,
            [APPLICATION_TYPE.PB1B]
          ),
        [PermissionTableEnum.LICENSE_COPY]:
          checkIsElementVisible(DomainUIElementEnum.APPLICATION_PERMISSIONS_LICENSE_COPY_TABLE) &&
          checkIsPermissionAvailableForApplicationType(
            [PB1A, PB1B, PB1C],
            PB2A,
            PB2B,
            [PB4A, PB4B, PB4C, PB4D],
            PB5,
            [PB6A, PB6B, PB6C, PB6D],
            [PB7A, PB7B],
            [PB8A, PB8B],
            [PB9A, PB9B],
            PB14,
            [
              APPLICATION_TYPE.PB3A,
              APPLICATION_TYPE.PB4A,
              APPLICATION_TYPE.PB4B,
              APPLICATION_TYPE.PB4C,
              APPLICATION_TYPE.PB4D
            ]
          ),
        [PermissionTableEnum.PERMISSION_COPY]:
          checkIsElementVisible(DomainUIElementEnum.APPLICATION_PERMISSIONS_PERMISSION_TABLE) &&
          checkIsPermissionAvailableForApplicationType([
            APPLICATION_TYPE.PB3B,
            APPLICATION_TYPE.PB4B,
            APPLICATION_TYPE.PB4C,
            APPLICATION_TYPE.PB5,
            APPLICATION_TYPE.PB6A,
            APPLICATION_TYPE.PB6C,
            APPLICATION_TYPE.PB7B,
            APPLICATION_TYPE.PB14
          ]),
        [PermissionTableEnum.DRIVER_CERTIFICATE]:
          checkIsElementVisible(DomainUIElementEnum.APPLICATION_PERMISSIONS_DRIVER_CERTIFICATES_TABLE) &&
          checkIsPermissionAvailableForApplicationType(DRIVER_CERTIFICATE),
        [PermissionTableEnum.SPO_UE]:
          checkIsElementVisible(
            DomainUIElementEnum.APPLICATION_PERMISSIONS_PERMITS_FOR_REGULAR_SERVICE_FROM_UE_TABLE
          ) && checkIsPermissionAvailableForApplicationType(SPO_UE, [APPLICATION_TYPE.spoUeDuplicatePermit]),
        [PermissionTableEnum.SPO_UE_COPY]:
          checkIsElementVisible(
            DomainUIElementEnum.APPLICATION_PERMISSIONS_EXTRACTS_FOR_REGULAR_SERVICE_WITNIN_UE_TABLE
          ) && checkIsPermissionAvailableForApplicationType(SPO_UE, SPO_UE_ONLY_EXTRACT),
        [PermissionTableEnum.SPO_OUTSIDE_UE]:
          checkIsElementVisible(
            DomainUIElementEnum.APPLICATION_PERMISSIONS_PERMITS_FOR_REGULAR_SERVICE_OUTSIDE_UE_TABLE
          ) &&
          checkIsPermissionAvailableForApplicationType(SPO_OUTSIDE_UE, [APPLICATION_TYPE.spoOutsideUeDuplicatePermit]),
        [PermissionTableEnum.SPO_OUTSIDE_UE_COPY]:
          checkIsElementVisible(
            DomainUIElementEnum.APPLICATION_PERMISSIONS_EXTRACTS_FOR_REGULAR_SERVICE_OUTSIDE_UE_TABLE
          ) && checkIsPermissionAvailableForApplicationType(SPO_OUTSIDE_UE, SPO_OUTSIDE_UE_ONLY_EXTRACT),
        [PermissionTableEnum.SPO_SHUTTLE]:
          checkIsElementVisible(DomainUIElementEnum.APPLICATION_PERMISSIONS_SHUTTLE_PERMITS_TABLE) &&
          checkIsPermissionAvailableForApplicationType(SPO_WZW, [APPLICATION_TYPE.spoWzwDuplicatePermit]),
        [PermissionTableEnum.SPO_SHUTTLE_COPY]:
          checkIsElementVisible(
            DomainUIElementEnum.APPLICATION_PERMISSIONS_EXTRACTS_FROM_PERMIT_FOR_SHUTTLE_SERVICE_TABLE
          ) && checkIsPermissionAvailableForApplicationType(SPO_WZW, SPO_WZW_ONLY_EXTRACT),
        [PermissionTableEnum.SPO_OCCASIONAL]:
          checkIsElementVisible(DomainUIElementEnum.APPLICATION_PERMISSIONS_PERMITS_FOR_OCCASIONAL_SERVICE_TABLE) &&
          checkIsPermissionAvailableForApplicationType(SPO_WZO, [APPLICATION_TYPE.spoWzoDuplicatePermit]),
        [PermissionTableEnum.SPO_OCCASIONAL_COPY]:
          checkIsElementVisible(
            DomainUIElementEnum.APPLICATION_PERMISSIONS_EXCLUSIONS_FROM_PERMIT_FOR_OCCASIONAL_SERVICE_TABLE
          ) && checkIsPermissionAvailableForApplicationType(SPO_WZO, SPO_WZO_ONLY_EXTRACT),
        [PermissionTableEnum.SPO_TRANSIT]:
          checkIsElementVisible(
            DomainUIElementEnum.APPLICATION_PERMISSIONS_PERMITS_FOR_RREGULAR_TRANSIT_IN_POLAND_TABLE
          ) &&
          checkIsPermissionAvailableForApplicationType(SPO_TRANSIT_RP, [
            APPLICATION_TYPE.spoTransitRpModification,
            APPLICATION_TYPE.spoTransitRpDuplicatePermit
          ]),
        [PermissionTableEnum.SPO_TRANSIT_COPY]:
          checkIsElementVisible(
            DomainUIElementEnum.APPLICATION_PERMISSIONS_EXTRACTS_FROM_PERMIT_FOR_RREGULAR_TRANSIT_IN_POLAND_TABLE
          ) &&
          checkIsPermissionAvailableForApplicationType(SPO_TRANSIT_RP, SPO_TRANSIT_RP_ONLY_EXTRACT, [
            APPLICATION_TYPE.spoTransitRpModification
          ]),
        [PermissionTableEnum.OWN_NEEDS]:
          checkIsElementVisible(
            DomainUIElementEnum.APPLICATION_PERMISSIONS_CERTIFICATES_FOR_OWN_ACCOUNT_TRANSPORT_TABLE
          ) &&
          checkIsPermissionAvailableForApplicationType(SPO_OWN_NEEDS, [
            APPLICATION_TYPE.spoOwnNeedsPwo1,
            APPLICATION_TYPE.spoOwnNeedsPwo2,
            APPLICATION_TYPE.spoOwnNeedsPwo2Reprint,
            APPLICATION_TYPE.spoOwnNeedsPwr2
          ]),
        [PermissionTableEnum.OWN_NEEDS_COPY]:
          checkIsElementVisible(
            DomainUIElementEnum.APPLICATION_PERMISSIONS_EXTRACTS_TO_CERTIFICATE_FOR_OWN_ACCOUNT_TRANSPORT_TABLE
          ) && checkIsPermissionAvailableForApplicationType(SPO_OWN_NEEDS, SPO_OWN_NEEDS_ONLY_EXTRACT),
        [PermissionTableEnum.FOREIGN_AUTHORITY_PERMISSION]:
          checkIsElementVisible(DomainUIElementEnum.APPLICATION_PERMISSIONS_FOREIGN_PERMISSIONS_TABLE) &&
          checkIsPermissionAvailableForApplicationType(SPO_TRANSIT_RP, SPO_POSITION_MIND),
        [PermissionTableEnum.FOREIGN_PERMISSION]:
          checkIsElementVisible(DomainUIElementEnum.APPLICATION_PERMISSIONS_FOREIGN_PERMITS_TABLE) &&
          checkIsPermissionAvailableForApplicationType(FOREIGN_PERMITS, SPO_WZO, SPO_WZW, [
            SPO_WZO_APPLICATION,
            spoWzwApplication
          ]),
        [PermissionTableEnum.EKMT_CARNETS]:
          checkIsElementVisible(DomainUIElementEnum.APPLICATION_PERMISSIONS_FOREIGN_PERMITS_TABLE) &&
          checkIsPermissionAvailableForApplicationType(EKMT_CARNETS),
        [PermissionTableEnum.S_CERTIFICATE]: checkIsPermissionAvailableForApplicationType(S_CERTIFICATE),
        [PermissionTableEnum.INTERBUS]: checkIsPermissionAvailableForApplicationType(TRAVEL_FORM),
        [PermissionTableEnum.TRAVEL_FORM]: checkIsPermissionAvailableForApplicationType(TRAVEL_FORM)
      };
    };
    return { isVisible: checkIsVisible(applicationDetails), checkIsVisible };
  }, [applicationDetails, checkIsElementVisible]);
};

export default useVisiblePermissionTables;
