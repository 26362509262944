import { usePaginatedQuery } from 'react-query';
import { DocumentSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { DocumentsSnapshotClient, parseDocumentsList } from '@libs/domain/documents';

import { endpointsConfig } from '../endpoints-config';

import { SuspensionQueryKeysEnum } from './QueryKeysEnum';

interface GetSuspensionDocumentsParams extends DocumentSearchFilter {
  suspensionId: string;
}

export const getDocuments = async (_, { suspensionId, ...filters }: GetSuspensionDocumentsParams) => {
  const { data } = await API.suspensions.getSuspensionDocumentsSnapshotPage(suspensionId, filters, {
    ...endpointsConfig.getSuspensionDocumentsSnapshotPage
  });

  const response = {
    ...data,
    content: parseDocumentsList(data.content)
  };

  return response;
};

export default function useSuspensionDocumentsQuery(params: DocumentSearchFilter, queryConfig = {}) {
  return usePaginatedQuery<IPaginatedModel<DocumentsSnapshotClient>>(
    [SuspensionQueryKeysEnum.SUSPENSIONS_OUTGOING_DOCUMENTS, params],
    getDocuments,
    queryConfig
  );
}
