import { DriverDetails } from '@ibtm/domain';

import { useDialog } from '@libs/common/v2';

import { ExpireForm } from '@libs/domain/drivers/components';

interface IProps {
  onSuccess?: () => void;
  driverApplicationId?: string;
}

function useExpireDialog({ onSuccess, driverApplicationId }: IProps) {
  const { openDialog } = useDialog();

  const open = (driver: DriverDetails) => {
    openDialog(({ closeDialog }) => (
      <ExpireForm
        driverData={driver}
        closeDialog={closeDialog}
        onSuccess={onSuccess}
        driverApplicationId={driverApplicationId}
      />
    ));
  };

  return {
    openExpireDialog: open
  };
}

export default useExpireDialog;
