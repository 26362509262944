import { NamedObjectDetails } from '@ibtm/domain';
import { CalendarDate } from 'calendar-date';

/**
 * @desc Lista certyfikatów S
 */
export type SCertificatesSnapshot = {
  id?: string;
  author?: {
    id?: string;
    name?: string;
  };
  modifier?: {
    id?: string;
    name?: string;
  };
  created?: string;
  modified?: string;
  version?: number;
  applicationNumber?: string;
  folder?: {
    id?: string;
    number?: string;
    typeKey?: string;
  };
  subject?: {
    id: string;
    name: string;
    nip?: string;
  };
  number?: string;
  status: string;
  vehicleId?: string;
  vehicleType?: string;
  vehicleBrandKey?: string;
  vehicleBrandKeyTranslation?: string;
  vehicleModelKey?: string;
  vehicleModelKeyTranslation?: string;
  vehiclePlateNumber?: string;
  vehicleRegistrationNumber?: string;
  witd?: boolean;
  vehicleVin: string;
  euro?: string;
  file?: NamedObjectDetails;
  printDate?: CalendarDate;
  validFrom?: CalendarDate;
  compatibilityCertificateNumber?: string;
  nextInspectionDate?: CalendarDate;
  statusKey?: string;
  euroClassKey?: string;
};

export enum PrintCertificatesColumnsEnum {
  VEHICLE_PLATE_NUMBER = 'vehiclePlateNumber',
  NUMBER = 'number',
  VEHICLE_BRAND_KEY_TRANSLATION = 'vehicleBrandKeyTranslation',
  VEHICLE_TYPE = 'vehicleType',
  VEHICLE_VIN = 'vehicleVin',
  COMPATIBILITY_CERTIFICATE_NUMBER = 'compatibilityCertificateNumber',
  NEXT_INSPECTION_DATE = 'nextInspectionDate',
  PRINT_DATE = 'printDate',
  VALID_FROM = 'validFrom'
}
