import { Dispatch, SetStateAction } from 'react';
import { SingleUseSubmissionItemSnapshot } from '@ibtm/domain';

import { useDialog } from '@libs/common/v2';

import CreateDisposableDialog from '../components/common/CreateDisposableDialog';

interface IProps {
  singleUseSubmissionItems: SingleUseSubmissionItemSnapshot[];
  setSingleUseSubmissionItems: Dispatch<SetStateAction<SingleUseSubmissionItemSnapshot[]>>;
}

function useCreateDisposablePermitModal({ singleUseSubmissionItems, setSingleUseSubmissionItems }: IProps) {
  const { openDialog } = useDialog();

  const onSubmit = (values: SingleUseSubmissionItemSnapshot) => {
    const newResource = {
      resourceFormNameKey: values?.resourceFormNameKey,
      resourceTypeId: values?.resourceTypeId,
      id: values?.resourceTypeId,
      quantity: values?.quantity
    };
    const singleUseSubmissionItemsWithoutDuplicate = singleUseSubmissionItems?.filter(
      item => item?.resourceTypeId !== values?.resourceTypeId
    );

    if (singleUseSubmissionItems) {
      setSingleUseSubmissionItems([...singleUseSubmissionItemsWithoutDuplicate, newResource]);
    } else {
      setSingleUseSubmissionItems([newResource]);
    }
  };
  const showCreatePermitModal = () => {
    openDialog(({ closeDialog }) => <CreateDisposableDialog closeDialog={closeDialog} onSubmit={onSubmit} />);
  };

  return { showCreatePermitModal };
}

export default useCreateDisposablePermitModal;
