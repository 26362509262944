import { QueryCache } from 'react-query';
import {
  AttachmentVerificationElementUpdateRequest,
  AttachmentVerificationListDetails,
  AttachmentVerificationListDetails as AttachmentVerificationListDetailsClient
} from '@ibtm/domain';

import { useMutation, useQueryCache } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { AttachmentsQueryKeysEnum } from '..';

export interface IUpdateAttachmentFiles {
  applicationId: string;
  attachments: IAttachments[];
}

interface IAttachments {
  statusKey: string;
  name: string;
  notes: string;
}

export function updateAttachmentFiles(formData: IUpdateAttachmentFiles, queryCache: QueryCache) {
  const lastQuery = queryCache
    .getQueries([AttachmentsQueryKeysEnum.ATTACHMENTS_LIST, { applicationId: formData.applicationId }])
    .pop();
  const queryData: AttachmentVerificationListDetails = queryCache.getQueryData(lastQuery.queryKey);

  const verificationList = Object.keys(formData.attachments).map(key => ({
    id: key,
    ...formData.attachments[key]
  })) as Array<AttachmentVerificationElementUpdateRequest>;

  return API.applicationAttachments.updateApplicationAttachmentsVerificationList(formData.applicationId, {
    verificationElements: verificationList,
    version: queryData?.version
  });
}

export function updateAttachmentFilesClient(formData: IUpdateAttachmentFiles, queryCache: QueryCache) {
  const lastQuery = queryCache
    .getQueries([AttachmentsQueryKeysEnum.ATTACHMENTS_LIST, { applicationId: formData.applicationId }])
    .pop();
  const queryData: AttachmentVerificationListDetailsClient = queryCache.getQueryData(lastQuery.queryKey);

  const verificationList = Object.keys(formData.attachments).map(key => ({
    id: key,
    ...formData.attachments[key]
  })) as Array<AttachmentVerificationElementUpdateRequest>;

  return API.client.applicationAttachments.updateApplicationAttachmentsVerificationList(
    '',
    '',
    formData.applicationId,
    {
      verificationElements: verificationList,
      version: queryData?.version
    }
  );
}

function useUpdateAttachmentsVerificationFiles() {
  const queryCache = useQueryCache();
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(
    getQuery(
      (formData: IUpdateAttachmentFiles) => updateAttachmentFilesClient(formData, queryCache),
      (formData: IUpdateAttachmentFiles) => updateAttachmentFiles(formData, queryCache)
    )
  );
}

export default useUpdateAttachmentsVerificationFiles;
