import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { MoneyTransferCreateRequest, MoneyTransferSnapshot } from '@ibtm/domain';
import * as Yup from 'yup';

import {
  DatepickerField,
  Dialog,
  FormV2Context,
  GridLayout,
  isDateValid,
  NumberInputField,
  typedNameV2
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useUpdateMoneyTransferMutation } from '../api';
import { PricingQueryKeysEnum } from '../api/query/PricingQueryKeysEnum';
import { IMoneyTransferCreateRequest } from '../utils';

interface IProps {
  original?: MoneyTransferSnapshot;
  closeDialog: () => void;
}

function MoneyTransferUnconfirmedEditDialog({ original, closeDialog }: IProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: updateMoneyTransfer, isLoading } = useUpdateMoneyTransferMutation();
  const queryCache = useQueryCache();

  const formMethods = useForm<Partial<MoneyTransferCreateRequest>>({
    resolver: yupResolver(
      Yup.object<IMoneyTransferCreateRequest>({
        paymentDate: Yup.string().nullable().required().concat(isDateValid()),
        amount: Yup.number().nullable().required()
      })
    ),
    defaultValues: {
      paymentDate: original?.paymentDate,
      amount: original?.amount
    }
  });

  const handleSubmit = formMethods.handleSubmit((formData: Partial<MoneyTransferCreateRequest>) =>
    updateMoneyTransfer(
      {
        moneyTransferId: original?.id,
        formData: { amount: formData?.amount, paymentDate: formData?.paymentDate, version: original?.version }
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('success.save'));
          queryCache.invalidateQueries([PricingQueryKeysEnum.APPLICATION_MONEY_TRANSFERS]);
          closeDialog();
        }
      }
    )
  );

  const form = useMemo(() => formMethods, [formMethods]);

  return (
    <Dialog
      title={t('pricing:moneyTransfersUnconfirmed.editDialog.title')}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={handleSubmit}
      onCancel={closeDialog}
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={form}>
        <form onSubmit={handleSubmit}>
          <GridLayout itemProps={{ xs: 6 }}>
            <DatepickerField
              name={typedNameV2<MoneyTransferCreateRequest>('paymentDate')}
              label={t('pricing:moneyTransfersUnconfirmed.field.date')}
              isRequired
            />
            <NumberInputField
              name={typedNameV2<MoneyTransferCreateRequest>('amount')}
              label={t('pricing:moneyTransfersUnconfirmed.field.amount')}
              isRequired
            />
          </GridLayout>
        </form>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default MoneyTransferUnconfirmedEditDialog;
