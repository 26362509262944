import { useTranslation } from 'react-i18next';
import { queryCache } from 'react-query';
import { useSnackbar } from '@enigma/fe-ui';

import { TableButton, useConfirmDialog } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

import { useApplicationDetailsQuery } from '@libs/domain/application';
import { DomainDictionaryEntry } from '@libs/domain/config';

import { useApplicationPricingQuery, useUnlinkMoneyTransfersToApplicationMutation } from '../../api';
import { PricingQueryKeysEnum } from '../../api/query/PricingQueryKeysEnum';

interface IProps {
  applicationId: string;
  onClick?: () => void;
  actionKey?: UIElementNameEnum;
  handleDiscardOrSaveFormChanges?: (action: () => void) => void;
}

function LinkMoneyTransferButton({ applicationId, onClick, actionKey, handleDiscardOrSaveFormChanges }: IProps) {
  const { data: pricingData } = useApplicationPricingQuery(applicationId, { refetchOnMount: false });
  const { data } = useApplicationDetailsQuery(applicationId, { enabled: false });

  // TODO - Jeśli proces znajduje się na etapie „Do wydania” lub dalszym
  // przycisk dostępny jest tylko dla osoby o odpowiednich uprawnieniach. GITDDEV-10269
  const isDisabled = DomainDictionaryEntry.APPLICATION_STATUS.PENDING_ISSUE === data.statusKey;

  const { mutate: unlinkTransferToApplication } = useUnlinkMoneyTransfersToApplicationMutation();
  const pricing = pricingData ?? {};

  const { showSuccessSnackbar } = useSnackbar();
  const [t] = useTranslation();
  const [confirm] = useConfirmDialog();

  return (
    <TableButton
      label={t('pricing:moneyTransfers.action.unlink.buttonTitle')}
      isDisabled={isDisabled}
      actionKey={actionKey}
      onClick={() => {
        handleDiscardOrSaveFormChanges(() => {
          confirm({
            onConfirm: (setLoading, closeDialog) => {
              setLoading(true);
              unlinkTransferToApplication(
                {
                  applicationId,
                  pricingId: pricing?.id
                },
                {
                  onSuccess: () => {
                    showSuccessSnackbar(t('pricing:moneyTransfers.action.unlink.successMessage'));
                    queryCache.invalidateQueries(PricingQueryKeysEnum.APPLICATION_MONEY_TRANSFERS);
                  },
                  onSettled: () => {
                    closeDialog();
                    setLoading(false);
                  }
                }
              );
            },
            title: t('pricing:moneyTransfers.action.link.dialogTitle'),
            message: t('pricing:moneyTransfers.action.unlink.dialogMessage')
          });
          onClick?.();
        });
      }}
    />
  );
}

export default LinkMoneyTransferButton;
