import { usePaginatedQuery } from 'react-query';
import { ProceedingKreptdSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { IProceedingClient } from '../../../models';
import { parseProceedingsListResults } from '../../../utils';
import { RoadTransportQueryKeysEnum } from '../..';
import { endpointsConfig } from '../../endpoints-config';

const getProceedings = async (params: ProceedingKreptdSearchFilter) => {
  const { data } = await API.proceedingsKreptd.getProceedingsKreptdSnapshotPage(params, {
    ...endpointsConfig.getProceedingsKreptdSnapshotPage
  });

  return {
    ...data,
    content: parseProceedingsListResults(data.content)
  };
};

const useProceedingsQuery = (params: ProceedingKreptdSearchFilter) =>
  usePaginatedQuery<IPaginatedModel<IProceedingClient>>([RoadTransportQueryKeysEnum.PROCEEDINGS_LIST, params], () =>
    getProceedings(params)
  );

export default useProceedingsQuery;
