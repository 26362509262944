import { Tooltip } from '@mui/material';

import { useCreateColumns } from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { ExploitationBaseInspectionSnapshot } from '@libs/domain/exploitation-base';

const useBaseInspectionTableColumns = () => {
  const { createColumns } = useCreateColumns<ExploitationBaseInspectionSnapshot>({
    pathToTranslate: 'exploitationBase:field'
  });

  const columns = createColumns([
    {
      header: 'controlResult',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.EXPLOITATION_BASE_INSPECTION_RESULT,
      accessor: 'inspectionResultKey',
      isSortable: false,
      isFilterable: false
    },
    {
      type: 'DATE',
      header: 'controlDate',
      accessor: 'inspectionDate',
      isSortable: false
    },
    {
      id: 'remarks',
      header: 'remarks',
      type: 'CUSTOM_COLUMN',
      customAccessor: row =>
        row?.remarks?.length > 13 ? (
          <Tooltip title={row?.remarks}>
            <span>{row?.remarks}</span>
          </Tooltip>
        ) : (
          row?.remarks
        ),
      isSortable: false
    }
  ]);

  return { columns };
};

export default useBaseInspectionTableColumns;
