import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { SubjectPartnershipPartnerSnapshot } from '@ibtm/domain';

import { TableIconButton } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { UIElementNameEnum } from '@libs/permission';

import { EntrepreneursTable as EntrepreneursTableDomain, usePartnershipPartnersQuery } from '@libs/domain/subject';

import { useEntrepreneursColumns } from '../../hooks';
import { EntrepreneursColumnsEnum } from '../../model';
import ApplicationEntrepreneurExpireButton from '../ApplicationEntrepreneurExpireButton';

export enum EntrepreneursActionsEnum {
  EXPIRE = 'expire'
}

export interface IEntrepreneursTable {
  subjectId?: string;
  rowActionsEnabled?: Array<EntrepreneursActionsEnum>;
  actionKey?: UIElementNameEnum;
}
function EntrepreneursTable({ subjectId, actionKey, rowActionsEnabled }: IEntrepreneursTable) {
  const { data: partnershipPartnersData } = usePartnershipPartnersQuery(
    { subjectId },
    {
      enabled: Boolean(subjectId),
      refetchOnMount: false
    }
  );

  const visibleColumns = [
    EntrepreneursColumnsEnum.FOLDER_NUMBER,
    EntrepreneursColumnsEnum.NAME,
    EntrepreneursColumnsEnum.NIP,
    EntrepreneursColumnsEnum.ADDRESS,
    EntrepreneursColumnsEnum.LEGAL_FORM_KEY,
    EntrepreneursColumnsEnum.FOLDER_NUMBER,
    EntrepreneursColumnsEnum.PENDING_PROCEEDING
  ];
  const { columns } = useEntrepreneursColumns(visibleColumns);
  const { viewMode } = useViewModesV2();
  const checkIsActionEnabled = (action: EntrepreneursActionsEnum) => rowActionsEnabled?.includes(action);
  const [t] = useTranslation();

  const isOnlyOnePartnerAvaliable = useMemo(() => {
    const totalPartners = partnershipPartnersData?.partnershipPartners?.length;
    if (totalPartners === 1) {
      return true;
    }

    const expiredPartners = partnershipPartnersData?.partnershipPartners?.filter(
      (partner: SubjectPartnershipPartnerSnapshot) => partner?.expired
    ).length;

    return totalPartners - expiredPartners === 1;
  }, [partnershipPartnersData]);

  const rowActions = useCallback(
    ({ original }: Row<SubjectPartnershipPartnerSnapshot>) => {
      if (original.expired) {
        return (
          <TableIconButton icon="InfoRoundedIcon" tooltipTitle={t('subject:messages.expiredEntrepreneur')} isDisabled />
        );
      }

      return (
        checkIsActionEnabled(EntrepreneursActionsEnum.EXPIRE) &&
        !viewMode && (
          <ApplicationEntrepreneurExpireButton subjectId={original?.id} isDisabled={isOnlyOnePartnerAvaliable} />
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partnershipPartnersData, isOnlyOnePartnerAvaliable]
  );

  return (
    <EntrepreneursTableDomain
      data={partnershipPartnersData?.partnershipPartners}
      visibleColumns={columns}
      actionKey={actionKey}
      rowActions={rowActions}
      actionsColumnWidth={72}
    />
  );
}

export default EntrepreneursTable;
