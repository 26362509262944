import {
  DictionaryEntryCreateRequest,
  DictionaryEntryCreateRequestLanguage,
  DictionaryEntryDetails,
  DictionaryEntryUpdateRequest,
  DictionaryEntryUpdateRequestLanguage
} from '@avispon/dictionary/dist/models';
import * as Yup from 'yup';

import { DictionaryEntryNameStatusEnum } from '@libs/dictionary/models/dictionaries.model';

interface SelectOption {
  id?: string;
  value: string;
  name: string;
  inputValue?: string;
}

export type DictionaryFormValues = Omit<
  DictionaryEntryCreateRequest | (DictionaryEntryUpdateRequest & DictionaryEntryDetails),
  'nestedDictionaryId' | 'language' | 'language' | 'parentKey'
> & {
  nestedDictionaryId: SelectOption;
  parentKey: SelectOption;
  parentKeyValue: SelectOption;
  language: {
    name?: DictionaryEntryCreateRequestLanguage | DictionaryEntryUpdateRequestLanguage;
    value?: DictionaryEntryCreateRequestLanguage | DictionaryEntryUpdateRequestLanguage;
  };
  version?: number;
  status?: DictionaryEntryNameStatusEnum;
};

export const ValidationSchema = Yup.object({
  key: Yup.string().required(),
  value: Yup.string().required()
});

export const InitalFormValues: Partial<DictionaryFormValues> = {
  key: '',
  value: '',
  language: { value: DictionaryEntryCreateRequestLanguage.pl },
  nestedDictionaryId: { name: null, value: null },
  parentKey: { name: null, value: null },
  parentKeyValue: { name: null, value: null },
  version: null,
  status: DictionaryEntryNameStatusEnum.ALL
};
