import {
  AdditionalSectionLicenseOrPermissionType,
  ApplicationMetaFormDefinition
} from '@libs/domain/application/model';

const definition: ApplicationMetaFormDefinition = {
  fields: [
    {
      typeKey: 'DICTIONARY',
      fieldId: 'licenseApplicationDetails-affectedPermissionKey',
      properties: {
        label: 'Zmiana dotyczy',
        dictionaryName: 'APPLICATION_VEHICLE_CONCERNS_TYPE',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.vehicleModificationConcernsKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.vehicleModificationConcernsKey'
          }
        },
        yupValidation: {
          required: true
        }
      }
    }
  ],
  components: [],
  layout: [
    {
      typeKey: 'TAB_NAVIGATION',
      properties: {
        visibility: {
          elementVisibilityKeys: ['TAB_VISIBILITY']
        },
        defaultTabId: 'GENERAL',
        title: 'Sekcje',
        tabs: [
          {
            id: 'GENERAL',
            title: 'Dane podstawowe',
            icon: 'InfoIcon'
          },
          {
            id: 'SUBJECT',
            title: 'Podmiot',
            icon: 'FacadeIcon'
          },
          {
            id: 'ADDRESSES',
            title: 'Adresy',
            icon: 'MailboxIcon'
          },
          {
            id: 'PERMISSIONS',
            title: 'Uprawnienia',
            icon: 'KeyIcon'
          },
          {
            id: 'PROXIES',
            title: 'Pełnomocnicy',
            icon: 'GroupOfPeopleIcon'
          },
          {
            id: 'ATTACHMENTS',
            title: 'Załączniki',
            icon: 'PaperclipIcon'
          },
          {
            id: 'VEHICLES',
            title: 'Pojazdy',
            icon: 'TruckIcon'
          },
          {
            id: 'QUOTATION',
            title: 'Wycena',
            icon: 'CoinsIcon'
          },
          {
            id: 'OUTGOING_DOCUMENTS',
            title: 'Dokumenty wychodzące',
            icon: 'DocumentsIssuedIcon'
          },
          {
            id: 'NOTE',
            title: 'Notatka',
            icon: 'NoteIcon'
          },
          {
            id: 'FILES',
            title: 'Pliki',
            icon: 'FileEmptyIcon'
          },
          {
            id: 'HISTORY',
            title: 'Historia',
            icon: 'HistoryIcon'
          }
        ]
      },
      nodes: [
        {
          typeKey: 'TABS_CONTENT',
          nodes: [
            {
              typeKey: 'TAB_CONTENT',
              properties: {
                id: 'GENERAL'
              },
              nodes: [
                {
                  typeKey: 'FIELD',
                  properties: {
                    fieldId: 'application-warnings'
                  }
                },
                {
                  typeKey: 'SECTION',
                  properties: {
                    title: 'Wniosek'
                  },
                  nodes: [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'application-version'
                      }
                    },
                    {
                      typeKey: 'GRID',
                      properties: {
                        gridItemProps: {
                          xs: 12,
                          sm: 6
                        }
                      },
                      nodes: [
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'application-submission-date'
                          }
                        },
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'application-submission-channel-key'
                          }
                        },
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'application-expected-completion-date'
                          }
                        },
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'application-folder'
                          }
                        },
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'application-created-date'
                          }
                        },
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'application-author'
                          }
                        },
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'application-paper-version-wwu-date'
                          }
                        },
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'application-end-date'
                          }
                        },
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'application-last-supplement-receipt-date'
                          }
                        },
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'application-concern-license'
                          }
                        },
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'application-locked-by-application'
                          }
                        },
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'licenseApplicationDetails-affectedPermissionKey'
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  typeKey: 'COMPONENT',
                  properties: {
                    componentId: 'application-general-tab-delivery-channel-section'
                  }
                },
                {
                  typeKey: 'SECTION',
                  properties: {
                    title: 'Decyzje / Wyroki sądu'
                  },
                  nodes: [
                    {
                      typeKey: 'GRID',
                      properties: {
                        gridItemProps: {
                          xs: 12,
                          sm: 6
                        }
                      },
                      nodes: [
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'application-is-review-application'
                          }
                        },
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'application-second-tier-decision-key'
                          }
                        },
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'application-second-tier-decision-date'
                          }
                        },
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'application-wsa-complaint'
                          }
                        },
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'application-wsa-decision-key'
                          }
                        },
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'application-wsa-decision-date'
                          }
                        },
                        {
                          typeKey: 'FIELD',
                          properties: {
                            fieldId: 'application-final-decision'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-subject-tab',
                additionalNodes: {
                  'application-section-end': [
                    {
                      typeKey: 'COMPONENT',
                      properties: {
                        componentId: 'application-subject-print-data-license-section'
                      }
                    },
                    {
                      typeKey: 'COMPONENT',
                      properties: {
                        componentId: 'application-subject-print-data-permission-section'
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-addresses-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-permissions-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-proxies-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-attachments-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-vehicles-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-quotation-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-outgoing-documents-tab',
                additionalNodes: {
                  'outgoing-documents-additional-section': [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'application-outgoing-documents-additional-section-license-or-permission',
                        sectionTypes: [
                          AdditionalSectionLicenseOrPermissionType.PERMISSION,
                          AdditionalSectionLicenseOrPermissionType.LICENSE
                        ]
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-note-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-files-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-history-tab'
              }
            }
          ]
        }
      ]
    }
  ]
};

export default definition;
