import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AutocompleteLazyFetchSelectField, typedNameV2, useFormV2Context } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { DomainDictionaryEntry } from '../../../config';
import { TransportManagerDetailsClient } from '../../model';

interface IProps {
  folderId: string;
  transferredFoldersIds?: string[];
  initialTransportManagerData?: TransportManagerDetailsClient;
}

function LinkedTransportManagerField({ folderId, transferredFoldersIds, initialTransportManagerData }: IProps) {
  const { setValue } = useFormV2Context();
  const [t] = useTranslation();

  useEffect(() => {
    if (initialTransportManagerData) {
      setValue('cancelledTransportManager', {
        id: initialTransportManagerData.id,
        name: `${initialTransportManagerData?.personalData?.name} ${initialTransportManagerData?.personalData?.surname} (${initialTransportManagerData.certificate?.number})`,
        value: initialTransportManagerData
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTransportManagerData]);

  return (
    <AutocompleteLazyFetchSelectField
      name={typedNameV2<TransportManagerDetailsClient>('cancelledTransportManager')}
      queryKey="TRANSPORT_MANAGERS_LINKED_TO_FOLDER"
      optionLabelParser={option => `${option.name} ${option.surname} (${option.certificate.number})`}
      label={t('transportManager:details.field.linkedTransportManager')}
      isDisableClearable={false}
      isQueryInitiallyEnabled
      api={{
        FETCH: (searchText, params) =>
          API.transportManager.getTransportMangersSnapshotPage({
            ...params,
            folderIdIn: [folderId, ...(transferredFoldersIds || [])],
            surnameContains: searchText,
            statusKeyIn: [DomainDictionaryEntry.TRANSPORT_MANAGER_STATUS.CURRENT]
          })
      }}
      isRequired
    />
  );
}

export default LinkedTransportManagerField;
