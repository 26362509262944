import { ResourceAssignmentLicenseUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

async function updateLicense(formData: ResourceAssignmentLicenseUpdateRequest) {
  const { data } = await API.printouts.updateResourceAssignmentLicense(formData, {
    ...endpointsConfig.updateResourceAssignmentLicense
  });
  return data;
}

export default function useUpdateLicenseMutation() {
  return useMutation(updateLicense);
}
