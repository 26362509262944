import { SCertificateSnapshot as SCertificateSnapshotClient } from '@ibtm/client-domain';
import { SCertificateSnapshot } from '@ibtm/domain';

import { SCertificatesSnapshot } from '../model';

export const parseSCertificateList = (
  data: SCertificateSnapshot[] | SCertificateSnapshotClient[]
): SCertificatesSnapshot[] =>
  data.map((item: SCertificateSnapshot | SCertificateSnapshotClient) => ({
    ...item,
    printDate: item.printDate,
    validFrom: item.validFrom,
    nextInspectionDate: item.nextInspectionDate,
    euro: item.euroClassKey,
    status: item.statusKey,
    vehicleVin: item.vehicleVin,
    statusKey: item.statusKey,
    euroClassKey: item.euroClassKey,
    subject: {
      id: item?.subject?.id,
      name: item?.subject?.name,
      nip: item?.subject?.nip
    }
  }));
