import { ReactNode, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';

import { UIElementNameEnum, useElementVisibility } from '@libs/permission';

import { Scrollbars } from '../scrollbar';

import tabsService, { RESET_TAB_FIELDS } from './tabsService';

export type RegisteredFormEnums = string;

interface IProps<T> {
  value: T;
  activeTab?: T;
  children?: ReactNode;
  scrollerPrefixId?: RegisteredFormEnums;
  saveFieldList?: (tab: T, fields: string[]) => void;
  className?: string;
  scrollbarsClassName?: string;
  isKeepMounted?: boolean;
  isAbsolute?: boolean;
  isInnerScroll?: boolean;
  viewName?: UIElementNameEnum;
}

function TabContent<T>({
  value,
  scrollerPrefixId,
  children,
  activeTab,
  saveFieldList,
  isKeepMounted,
  className,
  scrollbarsClassName,
  isAbsolute,
  isInnerScroll = true,
  viewName
}: IProps<T>) {
  const isActive = value === activeTab;
  const tabContentRef = useRef(null);
  const { checkIsElementVisible } = useElementVisibility();
  const isItemVisible = useMemo(() => {
    return !viewName || checkIsElementVisible(viewName);
  }, [checkIsElementVisible, viewName]);

  const shouldBeRendered: boolean = (isActive || isKeepMounted) && isItemVisible;

  useEffect(() => {
    const listener = () => {
      tabsService.findFields(value, tabContentRef.current, saveFieldList);
    };
    if (tabContentRef.current) {
      tabsService.on(RESET_TAB_FIELDS, listener);
    }
    return () => {
      tabsService.removeListener(RESET_TAB_FIELDS, listener);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (saveFieldList) {
      if (tabContentRef.current) {
        tabsService.findFields(value, tabContentRef.current, saveFieldList);
      }
    }
  }, [saveFieldList, tabContentRef, value]);

  return shouldBeRendered ? (
    <div ref={tabContentRef} className={clsx(isAbsolute ? 'absolute' : 'static', 'h-full w-full', className)}>
      {isInnerScroll ? (
        <Scrollbars
          id={scrollerPrefixId ? `${scrollerPrefixId}-${value}` : null}
          className={clsx('max-h-full h-full', scrollbarsClassName)}
          option={{ wheelPropagation: true, suppressScrollX: true }}
        >
          {children}
        </Scrollbars>
      ) : (
        children
      )}
    </div>
  ) : null;
}

export default TabContent;
