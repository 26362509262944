import { ForeignPermissionApplicationRecordWithLimitDetails } from '@ibtm/domain';

import { useTableLayoutProps } from '@libs/common/v2';

import { type EkmtColumns, useEkmtColumns } from './useEkmtColumns';

function useEkmtTable({
  dataWithIds,
  visibleColumns
}: {
  visibleColumns: EkmtColumns;
  dataWithIds: ForeignPermissionApplicationRecordWithLimitDetails[];
}) {
  const { columns } = useEkmtColumns({
    visibleColumns
  });

  return useTableLayoutProps(
    {
      tableQuery: dataWithIds,
      tableHookOptions: {
        columns
      }
    },
    [columns]
  );
}

export default useEkmtTable;
