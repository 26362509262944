import { ArchiveRestoreCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function restoreArchivedApplication(params: ArchiveRestoreCreateRequest) {
  return API.archiveApplications.restoreArchiveApplication(params, {
    ...endpointsConfig.restoreArchiveApplication
  });
}

function useRestoreArchivedApplicationMutation() {
  return useMutation(restoreArchivedApplication);
}

export default useRestoreArchivedApplicationMutation;
