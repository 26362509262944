import { AddressSnapshot, DocumentSearchFilter, DocumentSnapshot, SubjectAddressDetails } from '@ibtm/domain';
import _ from 'lodash';

import { i18n } from '@libs/common';
import { convertCalendarDate } from '@libs/common/v2/utils';

import { DomainDictionaryEntry } from '@libs/domain/config';
import { availableToEditParcelStatuses, DocumentIssuedClient } from '@libs/domain/documents-issued/model';

import { parseStreet } from '../../address';
import type { DocumentAddressSectionIProps } from '../components/document-update-dialog/DocumentAddressSection';

export const documentsIssuedTableParser = (data: DocumentSnapshot[]): DocumentIssuedClient[] =>
  data?.map(item => ({
    id: item.id,
    authorization: item.isPermission,
    created: item.created,
    statusKey: item.statusKey,
    inputDate: item.inputDate,
    isPriceField: item.isPriceField,
    typeKey: item.typeKey,
    generatedLabelDetails: item.generatedLabelDetails,
    version: item.version,
    insertedBy: {
      id: item.insertedBy?.id,
      name: item.insertedBy?.name
    },
    contactAddress: {
      email: item?.emailAddress,
      phoneNumber: item?.phoneNumber
    },
    folder: {
      id: item.folder?.id,
      number: item.folder?.number,
      type: item.folder?.typeKey,
      version: item.folder?.version
    },
    application: {
      id: item.application?.id,
      archiveNumber: item.application?.archiveNumber,
      number: item.application?.number,
      type: item.application?.typeKey,
      status: item.application?.statusKey,
      recipientName: item.application?.recipientName,
      recipientSurname: item.application?.recipientSurname,
      categoryKey: item.application?.categoryKey
    },
    author: {
      id: item.author.id,
      name: item.author.name
    },
    addresseeName: item.addresseeName,
    subject: {
      id: item.subject?.id,
      name: item.subject?.name,
      nip: item.subject?.nip
    },
    parcel: {
      id: item.parcel?.id,
      passedToCourier: item.parcel?.passedToCourier,
      receiptType: item.parcel?.receiptTypeKey,
      passedToSentDate: convertCalendarDate(item.parcel?.passedToSentDate),
      sendDate: convertCalendarDate(item.parcel?.sendDate),
      receiptAckDate: convertCalendarDate(item.parcel?.receiptAckDate),
      returnDate: convertCalendarDate(item.parcel?.returnDate),
      number: item.parcel?.number,
      labelFileId: item?.parcel?.labelFileId,
      version: item?.parcel?.version
    },
    address: item.address,
    file: {
      id: item.file?.id,
      name: item.file?.name
    },
    epuap: item.epuapAddress,
    recipientName: item.recipientName,
    recipientSurname: item.recipientSurname
  }));

export const getAddressValuesToSend = (address: AddressSnapshot) => {
  const { postCode, postCity, city, street, propertyNumber, apartmentNumber } = address;
  return [postCode, postCity, city, street, propertyNumber, apartmentNumber];
};

export const prepareAddress = (address: AddressSnapshot | SubjectAddressDetails): string => {
  if (!address) {
    return i18n.t('emptyMark');
  }

  const fullAddress = {
    postOffice: null,
    street: null,
    numbers: null
  };

  const { postCode, postCity, city, street, propertyNumber, apartmentNumber } = address;

  if (street) {
    const separator = city ? ', ' : '';
    fullAddress.street = `${separator}${parseStreet(address.street)}`;
  }

  if (propertyNumber || apartmentNumber) {
    fullAddress.numbers = `${propertyNumber || ''} ${apartmentNumber ? `/${apartmentNumber}` : ''}`.trim();
  }

  if (postCode || postCity) {
    const separator = fullAddress.numbers ? ', ' : '';
    const postCityPrepared = postCity ? `${i18n.t('folder:address.postCity').toLocaleLowerCase()} ${postCity}` : null;
    fullAddress.postOffice = [separator, postCode, postCityPrepared].filter(Boolean).join(' ');
  }

  return (
    `${city ?? ''}${fullAddress.street ?? ''} ${fullAddress.numbers ?? ''}${fullAddress.postOffice}`.trim() ||
    i18n.t('emptyMark')
  );
};

export const checkIsCourier = receiptType => {
  return !!receiptType && [DomainDictionaryEntry.RECEIPT_TYPE.COURIER].includes(receiptType);
};

export const parseListFilters = (filters: DocumentSearchFilter) => {
  const initialStatuses = [
    DomainDictionaryEntry.DOCUMENT_STATUS.READY_TO_SENED,
    DomainDictionaryEntry.DOCUMENT_STATUS.DELIVERED,
    DomainDictionaryEntry.DOCUMENT_STATUS.PENDING_DELIVERY,
    DomainDictionaryEntry.DOCUMENT_STATUS.READY_FOR_RELEASE,
    DomainDictionaryEntry.DOCUMENT_STATUS.RETURNED
  ];
  const documentStatusKeyIn = filters?.documentStatusKeyIn;
  return {
    ..._.omit(filters, 'documentStatusKeyIn'),
    documentStatusKeyIn: _.isEmpty(documentStatusKeyIn) ? initialStatuses : documentStatusKeyIn
  };
};

export const isDocumentReleased = (data: DocumentIssuedClient) =>
  [
    DomainDictionaryEntry.DOCUMENT_STATUS.RELEASE_DELIVERED,
    DomainDictionaryEntry.DOCUMENT_STATUS.RELEASE_PENDING_FOR_DELIVERY,
    DomainDictionaryEntry.DOCUMENT_STATUS.READY_TO_SEND
  ].includes(data.statusKey);

export const checkDocumentReceiptTypeIsCourierOrPost = (receiptType: string) =>
  [DomainDictionaryEntry.RECEIPT_TYPE.COURIER, DomainDictionaryEntry.RECEIPT_TYPE.POST].includes(receiptType);

export const getChangeAddressButtonTooltip = (original: DocumentIssuedClient) => {
  if (isDocumentReleased(original)) return 'documentsIssued:messages.actionNotAccessibleForReleaseStatus';
  return checkDocumentReceiptTypeIsCourierOrPost(original.parcel.receiptType)
    ? undefined
    : 'documentsIssued:messages.changeAddressActionNotAccessibleForReceiptType';
};

export const getEditParcelButtonTooltip = (original: DocumentIssuedClient) => {
  if (!checkDocumentReceiptTypeIsCourierOrPost(original.parcel.receiptType)) {
    return 'documentsIssued:messages.editParcelActionNotAccessibleForCourierOrPost';
  }
  if (!availableToEditParcelStatuses.includes(original.statusKey)) {
    return 'documentsIssued:messages.editParcelActionNotAccessibleForStatus';
  }
  return undefined;
};

type AddresSearchParams =
  | {
      searchParams: {
        applicationId?: string;
        folderId?: string;
      };
      addressSearchFilter?: undefined;
    }
  | {
      searchParams?: undefined;
      addressSearchFilter: DocumentAddressSectionIProps['addressSearchParams'];
    };
export const createAddressEditProps = ({
  documentAddress,
  addressToEdit,
  availableAddressesToSelect,
  searchParams,
  addressSearchFilter
}: {
  documentAddress: DocumentIssuedClient['address'];
  addressToEdit: 'CORRESPONDENCE' | 'DOCUMENT_SEND_ADDRESS';
  availableAddressesToSelect?: DocumentAddressSectionIProps['availableAddressesToSelect'];
} & AddresSearchParams): Pick<
  DocumentAddressSectionIProps,
  'getEditableAddress' | 'availableAddressesToSelect' | 'addressSearchParams'
> => {
  const availableAddressesToSelectDefault: DocumentAddressSectionIProps['availableAddressesToSelect'] = fetchedData => {
    const skipDocumentAddress = fetchedData.some(
      address => getAddressValuesToSend(address).toString() === getAddressValuesToSend(documentAddress).toString()
    );
    return [...(skipDocumentAddress ? [] : [documentAddress]), ...fetchedData];
  };
  return {
    availableAddressesToSelect: availableAddressesToSelect ?? availableAddressesToSelectDefault,
    addressSearchParams:
      addressSearchFilter ||
      (searchParams.applicationId
        ? { applicationIdIn: [searchParams.applicationId] }
        : { folderIdIn: [searchParams.folderId] }),
    getEditableAddress: addresses => {
      switch (addressToEdit) {
        case 'CORRESPONDENCE':
          return addresses.find(address => address.value.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.CORRESPONDENCE);
        case 'DOCUMENT_SEND_ADDRESS': {
          if (!documentAddress) return undefined;
          return addresses.find(
            address =>
              getAddressValuesToSend(address.value).toString() === getAddressValuesToSend(documentAddress).toString()
          );
        }
        default:
          addressToEdit satisfies never;
          return undefined;
      }
    }
  };
};
