import DomainDictionaryEnum from './DomainDictionaryEnum';

const DomainDictionaryEntry = {
  [DomainDictionaryEnum.ADDRESS_TYPE]: {
    ASSOCIATION: 'ibtm-domain.address-type.association',
    BASE: 'ibtm-domain.address-type.base',
    CORRESPONDENCE: 'ibtm-domain.address-type.correspondence',
    HQ: 'ibtm-domain.address-type.hq',
    MAIN: 'ibtm-domain.address-type.main',
    RESIDENT: 'ibtm-domain.address-type.resident'
  },
  [DomainDictionaryEnum.APPLICATION_TYPE]: {
    S_CERTIFICATE_ISSUING_DOCUMENT: 'ibtm-domain.application.type.s-certificate-issuing-document',
    PB1A: 'ibtm-domain.application.type.license-pb1-a',
    PB1B: 'ibtm-domain.application.type.license-pb1-b',
    PB1C: 'ibtm-domain.application.type.license-pb1-c',
    PB1D: 'ibtm-domain.application.type.license-pb1-d',
    PB2A: 'ibtm-domain.application.type.license-pb2-a',
    PB2B: 'ibtm-domain.application.type.license-pb2-b',
    PB3A: 'ibtm-domain.application.type.license-pb3-a',
    PB3B: 'ibtm-domain.application.type.license-pb3-b',
    PB4A: 'ibtm-domain.application.type.license-pb4-a',
    PB4B: 'ibtm-domain.application.type.license-pb4-b',
    PB4C: 'ibtm-domain.application.type.license-pb4-c',
    PB4D: 'ibtm-domain.application.type.license-pb4-d',
    PB4E: 'ibtm-domain.application.type.license-pb4-e',
    PB4F: 'ibtm-domain.application.type.license-pb4-f',
    PB5: 'ibtm-domain.application.type.license-pb5',
    PB6A: 'ibtm-domain.application.type.license-pb6-a',
    PB6B: 'ibtm-domain.application.type.license-pb6-b',
    PB6C: 'ibtm-domain.application.type.license-pb6-c',
    PB6D: 'ibtm-domain.application.type.license-pb6-d',
    PB6E: 'ibtm-domain.application.type.license-pb6-e',
    PB6F: 'ibtm-domain.application.type.license-pb6-f',
    PB7A: 'ibtm-domain.application.type.license-pb7-a',
    PB7B: 'ibtm-domain.application.type.license-pb7-b',
    PB7C: 'ibtm-domain.application.type.license-pb7-c',
    PB8A: 'ibtm-domain.application.type.license-pb8-a',
    PB8B: 'ibtm-domain.application.type.license-pb8-b',
    PB8C: 'ibtm-domain.application.type.license-pb8-c',
    PB9A: 'ibtm-domain.application.type.license-pb9-a',
    PB9B: 'ibtm-domain.application.type.license-pb9-b',
    PB9C: 'ibtm-domain.application.type.license-pb9-c',
    PB10A: 'ibtm-domain.application.type.license-pb10-a',
    PB10B: 'ibtm-domain.application.type.license-pb10-b',
    PB11A: 'ibtm-domain.application.type.license-pb11-a',
    PB11B: 'ibtm-domain.application.type.license-pb11-b',
    PB11C: 'ibtm-domain.application.type.license-pb11-c',
    PB11D: 'ibtm-domain.application.type.license-pb11-d',
    PB12: 'ibtm-domain.application.type.license-pb12',
    PB14: 'ibtm-domain.application.type.license-pb14',
    PB26: 'ibtm-domain.application.type.license-pb26',
    PB27: 'ibtm-domain.application.type.license-pb27',
    PB28: 'ibtm-domain.application.type.license-pb28',
    PB29A: 'ibtm-domain.application.type.license-pb29-a',
    PB29B: 'ibtm-domain.application.type.license-pb29-b',
    PB30: 'ibtm-domain.application.type.license-pb30',
    PB31: 'ibtm-domain.application.type.license-pb31',
    PB32A: 'ibtm-domain.application.type.license-pb32-a',
    PB32B: 'ibtm-domain.application.type.license-pb32-b',
    PB33: 'ibtm-domain.application.type.license-pb33',
    fzo: 'ibtm-domain.application.type.travel-form-ue-interbus',
    licenseDischargeWaiver: 'ibtm-domain.application.type.license-discharge-waiver',
    licenseFinancialSecurityUpdate: 'ibtm-domain.application.type.license-financial-security-update',
    licenseIssuingCertificate: 'ibtm-domain.application.type.license-issuing-certificate',
    changeOfDriverCertificate: 'ibtm-domain.application.type.change-of-driver-certificate',
    inputMistake: 'ibtm-domain.application.type.input-mistake',
    issueOfDriverCertificate: 'ibtm-domain.application.type.issue-of-driver-certificate',
    issuingDuplicateDriverCertificate: 'ibtm-domain.application.type.issuing-duplicate-driver-certificate',
    RESUMING_ISSUE_OF_DRIVER_CERTIFICATE: 'ibtm-domain.application.type.resuming-issue-of-driver-certificate',
    DRIVER_CERTIFICATE_DUPLICATE_OF_EXTRACT: 'ibtm-domain.application.type.driver-certificate-duplicate-of-extract',
    restoreOfDriverCertificate: 'ibtm-domain.application.type.restore-of-driver-certificate',
    sCertificateChangeOfDocument: 'ibtm-domain.application.type.s-certificate-change-of-document',
    sCertificateIssuingDocument: 'ibtm-domain.application.type.s-certificate-issuing-document',
    sCertificateIssuingDuplicate: 'ibtm-domain.application.type.s-certificate-issuing-duplicate',
    sCertificateResumingIssueOfDocument: 'ibtm-domain.application.type.s-certificate-resuming-issue-of-document',
    sCertificateReprintBtm: 'ibtm-domain.application.type.s-certificate-reprint-btm',
    foreignPermitSingleTp: 'ibtm-domain.application.type.foreign-permit-single-tp',
    foreignPermitSingleOp: 'ibtm-domain.application.type.foreign-permit-single-op',
    foreignPermitEkmt: 'ibtm-domain.application.type.foreign-permit-ekmt',
    spoUeLaunch: 'ibtm-domain.application.type.spo-ue-launch',
    spoUeDecisionToChangeTerms: 'ibtm-domain.application.type.spo-ue-decision-to-change-terms',
    spoUeExtend: 'ibtm-domain.application.type.spo-ue-extend',
    spoUeAdditionalExcerpt: 'ibtm-domain.application.type.spo-ue-additional-excerpt',
    spoUeModification: 'ibtm-domain.application.type.spo-ue-modification',
    spoUeModificationWithoutPrinting: 'ibtm-domain.application.type.spo-ue-modification-without-printing',
    spoUeDuplicatePermit: 'ibtm-domain.application.type.spo-ue-duplicate-permit',
    spoUeDuplicateOfExtract: 'ibtm-domain.application.type.spo-ue-duplicate-of-extract',
    spoUeFreeReprint: 'ibtm-domain.application.type.spo-ue-free-reprint',
    spoOutsideUeLaunch: 'ibtm-domain.application.type.spo-outside-ue-launch',
    spoOutsideUeDecisionToChangeTerms: 'ibtm-domain.application.type.spo-outside-ue-decision-to-change-terms',
    spoOutsideUeExtend: 'ibtm-domain.application.type.spo-outside-ue-extend',
    spoOutsideUeAdditionalExcerpt: 'ibtm-domain.application.type.spo-outside-ue-additional-excerpt',
    spoOutsideUeModification: 'ibtm-domain.application.type.spo-outside-ue-modification',
    spoOutsideUeModificationWithoutPrinting:
      'ibtm-domain.application.type.spo-outside-ue-modification-without-printing',
    spoOutsideUeDuplicatePermit: 'ibtm-domain.application.type.spo-outside-ue-duplicate-permit',
    spoOutsideUeDuplicateOfExtract: 'ibtm-domain.application.type.spo-outside-ue-duplicate-of-extract',
    SPO_OUTSIDE_ADDITIONAL_EXCERPT: 'ibtm-domain.application.type.spo-outside-additional-excerpt',
    spoOutsideUeFreeReprint: 'ibtm-domain.application.type.spo-outside-ue-free-reprint',
    spoWzoLaunch: 'ibtm-domain.application.type.spo-wzo-launch',
    spoWzoDecisionToChangeTerms: 'ibtm-domain.application.type.spo-wzo-decision-to-change-terms',
    spoWzoAdditionalExcerpt: 'ibtm-domain.application.type.spo-wzo-additional-excerpt',
    spoWzoModification: 'ibtm-domain.application.type.spo-wzo-modification',
    spoWzoModificationWithoutPrinting: 'ibtm-domain.application.type.spo-wzo-modification-without-printing',
    spoWzoDuplicatePermit: 'ibtm-domain.application.type.spo-wzo-duplicate-permit',
    spoWzoDuplicateOfExtract: 'ibtm-domain.application.type.spo-wzo-duplicate-of-extract',
    SPO_WZO_APPLICATION: 'ibtm-domain.application.type.spo-wzo-application',
    spoWzoFreeReprint: 'ibtm-domain.application.type.spo-wzo-free-reprint',
    spoWzwLaunch: 'ibtm-domain.application.type.spo-wzw-launch',
    spoWzwApplication: 'ibtm-domain.application.type.spo-wzw-application',
    spoWzwDecisionToChangeTerms: 'ibtm-domain.application.type.spo-wzw-decision-to-change-terms',
    spoWzwAdditionalExcerpt: 'ibtm-domain.application.type.spo-wzw-additional-excerpt',
    spoWzwModification: 'ibtm-domain.application.type.spo-wzw-modification',
    spoWzwDuplicatePermit: 'ibtm-domain.application.type.spo-wzw-duplicate-permit',
    spoWzwDuplicateOfExtract: 'ibtm-domain.application.type.spo-wzw-duplicate-of-extract',
    spoWzwReprintedAtFaultBtm: 'ibtm-domain.application.type.spo-wzw-reprinted-at-fault-btm',
    spoTransitRpLaunch: 'ibtm-domain.application.type.spo-transit-rp-launch',
    spoTransitRpDecisionToChangeTerms: 'ibtm-domain.application.type.spo-transit-rp-decision-to-change-terms',
    spoTransitRpExtend: 'ibtm-domain.application.type.spo-transit-rp-extend',
    spoTransitRpAdditionalExcerpt: 'ibtm-domain.application.type.spo-transit-rp-additional-excerpt',
    spoTransitRpModification: 'ibtm-domain.application.type.spo-transit-rp-modification',
    spoTransitRpDuplicatePermit: 'ibtm-domain.application.type.spo-transit-rp-duplicate-permit',
    spoTransitRpDuplicateOfExtract: 'ibtm-domain.application.type.spo-transit-rp-duplicate-of-extract',
    spoTransitRpReprintedAtFaultBtm: 'ibtm-domain.application.type.spo-transit-rp-reprinted-at-fault-btm',
    SPO_POSITION_MIND: 'ibtm-domain.application.type.spo-position-mind',
    spoPositionMindLaunch: 'ibtm-domain.application.type.spo-position-mind-launch',
    spoPositionMindExtend: 'ibtm-domain.application.type.spo-position-mind-extend',
    spoPositionMindModification: 'ibtm-domain.application.type.spo-position-mind-modification',
    spoOwnNeedsPwr1: 'ibtm-domain.application.type.spo-own-needs-pwr1',
    spoOwnNeedsPwr2: 'ibtm-domain.application.type.spo-own-needs-pwr2',
    spoOwnNeedsPwr3: 'ibtm-domain.application.type.spo-own-needs-pwr3',
    spoOwnNeedsPwr3Reprint: 'ibtm-domain.application.type.spo-own-needs-pwr3-reprint',
    spoOwnNeedsIpwz: 'ibtm-domain.application.type.spo-own-needs-ipwz',
    spoOwnNeedsIpwzInfo: 'ibtm-domain.application.type.spo-own-needs-ipwz-info',
    spoOwnNeedsPwo1: 'ibtm-domain.application.type.spo-own-needs-pwo1',
    spoOwnNeedsPwo2: 'ibtm-domain.application.type.spo-own-needs-pwo2',
    spoOwnNeedsPwo2Reprint: 'ibtm-domain.application.type.spo-own-needs-pwo2-reprint',
    TRAVEL_FORM: 'ibtm-domain.application.type.travel-form-ue-interbus'
  },
  [DomainDictionaryEnum.FREQUENCY_TYPE]: {
    DAILY: 'ibtm-domain.frequency-type.daily',
    FRIDAY: 'ibtm-domain.frequency-type.friday',
    MONDAY: 'ibtm-domain.frequency-type.monday',
    SATURDAY: 'ibtm-domain.frequency-type.saturday',
    SUNDAY: 'ibtm-domain.frequency-type.sunday',
    THURSDAY: 'ibtm-domain.frequency-type.thursday',
    TUESDAY: 'ibtm-domain.frequency-type.tuesday',
    WEDNESDAY: 'ibtm-domain.frequency-type.wednesday'
  },
  [DomainDictionaryEnum.STOP_TYPE]: {
    ALTERNATIVE_BORDER_CROSSING: 'ibtm-domain.stop-type.alternative-border-crossing',
    BORDER_CROSSING: 'ibtm-domain.stop-type.border-crossing',
    START_OF_TOUR: 'ibtm-domain.stop-type.start-of-tour',
    END_OF_TOUR: 'ibtm-domain.stop-type.end-of-tour',
    NEXT_STOP: 'ibtm-domain.stop-type.next-stop'
  },
  [DomainDictionaryEnum.LICENSE_APPLICATION_ISSUED_PERMISSIONS]: {
    LICENSE: 'ibtm-domain.application.license.issued-permission.license',
    LICENSE_AND_PERMISSION: 'ibtm-domain.application.license.issued-permission.license-and-permission',
    PERMISSION: 'ibtm-domain.application.license.issued-permission.permission'
  },
  [DomainDictionaryEnum.SCHEDULE_TYPE]: {
    MULTIPLE: 'ibtm-domain.schedule-type.multiple',
    SINGLE: 'ibtm-domain.schedule-type.single'
  },
  [DomainDictionaryEnum.RECEIPT_TYPE]: {
    COURIER: 'ibtm-domain.receipt.type.courier',
    EPUAP: 'ibtm-domain.receipt.type.epuap',
    PICKUP_IN_PERSON: 'ibtm-domain.receipt.type.pickup-in-person',
    POST: 'ibtm-domain.receipt.type.post',
    E_MAIL: 'ibtm-domain.receipt.type.e-mail'
  },
  [DomainDictionaryEnum.APPLICATION_STATUS]: {
    ACCOMPLISHED: 'ibtm-domain.application.status.accomplished',
    ACCOMPLISHED_WITHOUT_PRINTING: 'ibtm-domain.application.status.accomplished-without-printing',
    ACCOMPLISHED_REFUSE_PERMISSION: 'ibtm-domain.application.status.accomplished-refuse-permission',
    CANCELLED: 'ibtm-domain.application.status.cancelled',
    COMPLETE: 'ibtm-domain.application.status.complete',
    CONSIDERED: 'ibtm-domain.application.status.considered',
    FOREIGN_VERIFICATION: 'ibtm-domain.application.status.foreign-verification',
    INCOMPLETE: 'ibtm-domain.application.status.incomplete',
    ISSUED: 'ibtm-domain.application.status.issued',
    ISSUED_FOREIGN_PERMISSION: 'ibtm-domain.application.status.issued-foreign-permission',
    NEW: 'ibtm-domain.application.status.new',
    NOT_CONSIDERED: 'ibtm-domain.application.status.not-considered',
    NOT_FILLED: 'ibtm-domain.application.status.not-filled',
    PASS: 'ibtm-domain.application.status.pass',
    PASS_PENDING_ACCEPTATION: 'ibtm-domain.application.status.pass-pending-acceptation',
    PASS_PROJECT_IN_PROGRESS: 'ibtm-domain.application.status.pass-project-in-progress',
    PENDING_DECISION: 'ibtm-domain.application.status.pending-decision',
    PENDING_ISSUE: 'ibtm-domain.application.status.pending-issue',
    PENDING_PRINT: 'ibtm-domain.application.status.pending-print',
    PENDING_VERIFICATION: 'ibtm-domain.application.status.pending-verification',
    PREPARING_DECISION_DRAFT: 'ibtm-domain.application.status.preparing-decision-draft',
    RELEASED_WAITING_FOR_ZPO: 'ibtm-domain.application.status.released-waiting-for-zpo',
    WITHDRAWN: 'ibtm-domain.application.status.withdrawn',
    DRAFT: 'ibtm-domain.application.status.draft'
  },
  [DomainDictionaryEnum.APPLICATION_CATEGORY]: {
    DRIVER_CERTIFICATE: 'ibtm-domain.application.category.driver-certificate',
    SPO_OUTSIDE_UE: 'ibtm-domain.application.category.spo-outside-ue',
    TRAVEL_FORM: 'ibtm-domain.application.category.travel-form',
    BROKERAGE_IN_TRANSPORT_OF_PASSENGERS: 'ibtm-domain.application.category.brokerage-in-transport-of-passengers',
    SPO_WZO: 'ibtm-domain.application.category.spo-wzo',
    SPO_TRANSIT_RP: 'ibtm-domain.application.category.spo-transit-rp',
    FOREIGN_PERMIT: 'ibtm-domain.application.category.foreign-permit',
    SPO_UE: 'ibtm-domain.application.category.spo-ue',
    ROAD_CARRIER_GOODS_CARRIAGE_ABRIDGEMENT: 'ibtm-domain.application.category.road-carrier-goods-carriage-abridgement',
    SPO_OWN_NEEDS: 'ibtm-domain.application.category.spo-own-needs',
    SPO_POSITION_MIND: 'ibtm-domain.application.category.spo-position-mind',
    S_CERTIFICATE: 'ibtm-domain.application.category.s-certificate',
    SPO_WZW: 'ibtm-domain.application.category.spo-wzw'
  },
  [DomainDictionaryEnum.LICENSE_VALIDITY_YEARS]: {
    UNLIMITED: 'ibtm-domain.license-validity-years.unlimited',
    UP_10: 'ibtm-domain.license-validity-years.up-10',
    UP_TO_10_YEARS: 'ibtm-domain.license-validity-years.10',
    UP_TO_15_YEARS: 'ibtm-domain.license-validity-years.15',
    UP_TO_30_YEARS: 'ibtm-domain.license-validity-years.30',
    UP_TO_50_YEARS: 'ibtm-domain.license-validity-years.50',
    UP_TO_50_YEARS_PPO: 'ibtm-domain.license-validity-years.50-ppo',
    UP_TO_5_YEARS: 'ibtm-domain.license-validity-years.5'
  },
  [DomainDictionaryEnum.REQUIRED_FILE_STATUS]: {
    MISSING: 'ibtm-domain.required-files.status.missing',
    NOT_REQUIRED: 'ibtm-domain.required-files.status.not-required',
    ATTACHED: 'ibtm-domain.required-files.status.attached',
    CALLED: 'ibtm-domain.required-files.status.called'
  },
  [DomainDictionaryEnum.LICENSE_TYPE]: {
    TP_LICENSE_EU: 'ibtm-domain.license.type.tp-license-eu',
    OP_LICENSE_EU: 'ibtm-domain.license.type.op-license-eu'
  },
  [DomainDictionaryEnum.VEHICLE_STATUS]: {
    ACTIVE: 'ibtm-domain.vehicle-status.current',
    NEW: 'ibtm-domain.vehicle-status.new',
    EXPIRED: 'ibtm-domain.vehicle-status.expired'
  },
  [DomainDictionaryEnum.VEHICLE_SUBMITTED_TO]: {
    L: 'ibtm-domain.vehicle-submitted-to.l',
    W: 'ibtm-domain.vehicle-submitted-to.w',
    P: 'ibtm-domain.vehicle-submitted-to.p',
    Z: 'ibtm-domain.vehicle-submitted-to.z'
  },
  [DomainDictionaryEnum.VEHICLE_TYPE]: {
    BUS: 'ibtm-domain.vehicle-type.bus',
    CAR: 'ibtm-domain.vehicle-type.car',
    TRUCK_TRACTOR: 'ibtm-domain.vehicle-type.truck-tractor',
    TRUCK_SEMITRAILER: 'ibtm-domain.vehicle-type.truck-semitrailer',
    TRUCK_TRAILER: 'ibtm-domain.vehicle-type.truck-trailer'
  },
  [DomainDictionaryEnum.CITIZENSHIP]: {
    POL: 'ibtm-domain.citizenship.pol'
  },
  [DomainDictionaryEnum.TRANSPORT_MANAGER_STATUS]: {
    CURRENT: 'ibtm-domain.transport-manager-status.current',
    EXPIRED: 'ibtm-domain.transport-manager-status.expired'
  },
  [DomainDictionaryEnum.TRANSPORT_MANAGER_CERTIFICATE_STATUS]: {
    CURRENT: 'ibtm-domain.transport-manager-certificate-status.current',
    NEW: 'ibtm-domain.transport-manager-certificate-status.new',
    DECOMMITTED: 'ibtm-domain.transport-manager-certificate-status.decommitted',
    EXPIRED: 'ibtm-domain.transport-manager-certificate-status.expired'
  },
  [DomainDictionaryEnum.APPLICATION_TRANSPORT_MANAGER_MODIFICATION_TYPE]: {
    ADD: 'ibtm-domain.application.license.transport-manager.add',
    SUBMISSION: 'ibtm-domain.application.license.transport-manager.submission',
    MODIFICATION: 'ibtm-domain.application.license.transport-manager.modification'
  },
  [DomainDictionaryEnum.SUSPENSION_STATUS]: {
    CANCELLED: 'ibtm-domain.suspension-status.cancelled',
    CLOSED: 'ibtm-domain.suspension-status.verified',
    CONSIDERED: 'ibtm-domain.suspension-status.considered'
  },
  [DomainDictionaryEnum.PROCEEDING_STATUS]: {
    CANCELLED: 'ibtm-domain.proceeding-status.cancelled',
    CONSIDERED: 'ibtm-domain.proceeding-status.considered',
    VERIFIED: 'ibtm-domain.proceeding-status.verified'
  },
  [DomainDictionaryEnum.PROCEEDING_STATUS_KREPTD]: {
    DECISION_TO_DOWNGRADE_GOOD_REPUTATION: 'ibtm-domain.status-kreptd.decision-to-downgrade-good-reputation',
    DECISION_TO_KEEP_GOOD_REPUTATION: 'ibtm-domain.status-kreptd.decision-to-keep-good-reputation',
    DECISION_OF_DISCHARGE_WITHDRAWAL: 'ibtm-domain.status-kreptd.decision-of-discharge-withdrawal',
    DECISION_ON_DECLARATION_OF_INABILITY: 'ibtm-domain.status-kreptd.decision-on-declaration-of-inability',
    DECISION_TO_RESTORE_OPERABILITY: 'ibtm-domain.status-kreptd.decision-to-restore-operability',
    DECISION_TO_REFUSE_OPERABILITY: 'ibtm-domain.status-kreptd.decision-to-refuse-operability',
    DECISION_TO_IMPOSE_PENALTY: 'ibtm-domain.status-kreptd.decision-to-impose-a-penalty',
    DECISION_NOT_TO_IMPOSE_PENALTY: 'ibtm-domain.status-kreptd.decision-not-to-impose-a-penalty',
    DECISION_TO_SUSPEND_PERMIT: 'ibtm-domain.status-kreptd.decision-to-suspend-permit',
    ENFORCEABLE_DECISION: 'ibtm-domain.status-kreptd.enforceable-decision',
    FINAL_DECISION_OF_DISCHARGE_WITHDRAWAL: 'ibtm-domain.status-kreptd.final-decision-of-discharge-withdrawal',
    FINAL_DECISION_LOSS: 'ibtm-domain.status-kreptd.final-decision-loss',
    FINAL_DECISION_TO_SUSPEND_PERMIT: 'ibtm-domain.status-kreptd.final-decision-to-suspend-permit',
    FINAL_DECISION_ON_DECLARATION_OF_INABILITY: 'ibtm-domain.status-kreptd.final-decision-on-declaration-of-inability',
    FINAL_DECISION_TO_REFUSE_REINSTATE: 'ibtm-domain.status-kreptd.final-decision-to-refuse-reinstate',
    FINAL_DECISION_TO_DOWNGRADE_GOOD_REPUTATION:
      'ibtm-domain.status-kreptd.final-decision-to-downgrade-good-reputation',
    INCORRECT_QUALIFICATION: 'ibtm-domain.status-kreptd.incorrect-qualification',
    NO_PROPERTIES: 'ibtm-domain.status-kreptd.no-properties',
    REFUND: 'ibtm-domain.status-kreptd.refund',
    REMIT: 'ibtm-domain.status-kreptd.remit',
    REMIT_FINAL_DECISION: 'ibtm-domain.status-kreptd.remit-final-decision',
    SUSPENDED_ENFORCEABLE_DECISION: 'ibtm-domain.status-kreptd.suspended-enforceable-decision'
  },
  [DomainDictionaryEnum.GENERATOR_ASSIGNMENT_TYPE]: {
    LICENSE_COPY: 'ibtm-domain.generator-assignment-type.license-copy',
    INTERBUS: 'ibtm-domain.generator-assignment-type.interbus',
    FOREIGN_PERMISSION: 'ibtm-domain.generator-assignment-type.foreign-permission',
    S_CERTIFICATE: 'ibtm-domain.generator-assignment-type.s-certificate',
    LICENSE: 'ibtm-domain.generator-assignment-type.license',
    DRIVER_CERTIFICATE: 'ibtm-domain.generator-assignment-type.driver-certificate',
    PERMISSION_COPY: 'ibtm-domain.generator-assignment-type.permission-copy',
    TRAVEL_FORM: 'ibtm-domain.generator-assignment-type.travel-form',
    PERMISSION: 'ibtm-domain.generator-assignment-type.permission',
    OWN_NEEDS_PERMISSION_COPY: 'ibtm-domain.generator-assignment-type.own-needs-permission-copy',
    OWN_NEEDS_PERMISSION: 'ibtm-domain.generator-assignment-type.own-needs-permission'
  },
  [DomainDictionaryEnum.FOLDER_STATUS]: {
    ACTIVE: 'ibtm-domain.folder.status.active',
    TRANSFERRED: 'ibtm-domain.folder.status.transferred',
    INACTIVE: 'ibtm-domain.folder.status.inactive',
    RENTED: 'ibtm-domain.folder.status.rented',
    ARCHIVE: 'ibtm-domain.folder.status.archive',
    ARCHIVED: 'ibtm-domain.folder.status.archived',
    BORROWED: 'ibtm-domain.folder.status.borrowed',
    EXPIRED: 'ibtm-domain.folder.status.expired',
    EXPIRED_ASSIGNED_TO_TRANSFER: 'ibtm-domain.folder.status.expired-assigned-to-transfer',
    EXPIRED_TRANSFERRED: 'ibtm-domain.folder.status.expired-transferred',
    EXPIRED_TO_TRANSFER: 'ibtm-domain.folder.status.expired-to-transfer',
    TRANSFER_TO_COUNTY_OFFICE: 'ibtm-domain.folder.status.transfer-to-county-office',
    NEW: 'ibtm-domain.folder.status.new'
  },
  [DomainDictionaryEnum.FOLDER_LOCALIZATION]: {
    DEPARTMENTAL_ARCHIVE: 'ibtm-domain.folder-localization.departmental-archive',
    CARRY_ON_ARCHIVE: 'ibtm-domain.folder-localization.carry-on-archive',
    BORROWED: 'ibtm-domain.folder-localization.borrowed'
  },
  [DomainDictionaryEnum.PROCEEDING_TYPE_KREPTD]: {
    DECLARATION_OF_INABILITY: 'ibtm-domain.proceeding-type-kreptd.declaration-of-inability',
    DISCHARGE_AND_SUSPENSION_OF_CERTIFICATES:
      'ibtm-domain.proceeding-type-kreptd.discharge-and-suspension-of-certificates',
    GOOD_REPUTATION: 'ibtm-domain.proceeding-type-kreptd.good-reputation',
    IMPOSING_PENALTY: 'ibtm-domain.proceeding-type-kreptd.imposing-penalty',
    PERMIT_SUSPENSION: 'ibtm-domain.proceeding-type-kreptd.permit-suspension',
    RESTORE_OF_ABILITIES: 'ibtm-domain.proceeding-type-kreptd.restore-of-abilities'
  },
  [DomainDictionaryEnum.GOOD_REPUTATION_KREPTD_STATUS]: {
    APPEAL_PROCEESINGS: 'ibtm-domain.reputation-status-kreptd.appeal-proceedings',
    DECISION_TO_DOWNGRADE_GOOD_REPUTATION: 'ibtm-domain.reputation-status-kreptd.decision-to-downgrade-good-reputation',
    DECISION_TO_KEEP_REPUTATION: 'ibtm-domain.reputation-status-kreptd.decision-to-keep-good-reputation',
    DURING_PROCEEDINGS: 'ibtm-domain.reputation-status-kreptd.during-proceedings',
    FINAL_DECISION_LOSS: 'ibtm-domain.reputation-status-kreptd.final-decision-loss',
    FINAL_DECISION_TO_KEEP_DR: 'ibtm-domain.reputation-status-kreptd.final-decision-to-keep-dr',
    FORWARDED_FOR_INITIATION: 'ibtm-domain.reputation-status-kreptd.forwarded-for-initiation',
    INCORRECT_QUALIFICATION: 'ibtm-domain.reputation-status-kreptd.incorrect-qualification',
    INITIATION_OF_PROCEDURE: 'ibtm-domain.reputation-status-kreptd.initiation-of-procedure',
    NEW: 'ibtm-domain.reputation-status-kreptd.new',
    NO_PROPERTIES: 'ibtm-domain.reputation-status-kreptd.no-properties',
    REFUND: 'ibtm-domain.reputation-status-kreptd.refund',
    REMIT: 'ibtm-domain.reputation-status-kreptd.remit',
    REMIT_FINAL_DECISION: 'ibtm-domain.reputation-status-kreptd.remit-final-decision',
    REPEALED_CANCELED: 'ibtm-domain.reputation-status-kreptd.repealed-canceled',
    INACTIVITY_REASON_OTHER: 'ibtm-domain.reputation-status-kreptd.inactivity-reason-other'
  },
  [DomainDictionaryEnum.DEPOT_TYPE]: {
    MG: 'ibtm-domain.depot.type.mg',
    MGB: 'ibtm-domain.depot.type.mgb',
    MP: 'ibtm-domain.depot.type.mp',
    MPBIK: 'ibtm-domain.depot.type.mpbik',
    MPD: 'ibtm-domain.depot.type.mpd'
  },
  [DomainDictionaryEnum.DRIVER_CERTIFICATE_STATUS]: {
    ACTIVE: 'ibtm-domain.driver-certificate-status.active',
    INACTIVE: 'ibtm-domain.driver-certificate-status.inactive',
    EXPIRED: 'ibtm-domain.driver-certificate-status.expired'
  },
  [DomainDictionaryEnum.RESOURCE_FORM_TYPE]: {
    EKMT_PERMIT: 'ibtm-domain.resource.form-type.ekmt-permit',
    SINGLE_PERMIT: 'ibtm-domain.resource.form-type.single-permit',
    S_CERTYFICATE: 'ibtm-domain.resource.form-type.s-certificate',
    POLISH_FORM: 'ibtm-domain.resource.form-type.polish-form'
  },
  [DomainDictionaryEnum.RESOURCE_OBJECT_ORDER_OPERATION]: {
    CONFIRM: 'ibtm-domain.resource-object-order-operation.confirm',
    REJECT: 'ibtm-domain.resource-object-order-operation.reject'
  },
  [DomainDictionaryEnum.RESOURCE_FORM_GROUP]: {
    EKMT: 'ibtm-domain.resource.form-group.ekmt',
    FOREIGN_PERMIT: 'ibtm-domain.resource.form-group.foreign-permit',
    POLISH_FOREIGN_PERMIT: 'ibtm-domain.resource.form-group.polish-foreign-permit',
    S_CERTIFICATE: 'ibtm-domain.resource.form-group.s-certificate'
  },
  [DomainDictionaryEnum.RESOURCE_FORM_ORDER_DIRECTION]: {
    ORDERS: 'ibtm-domain.resource.form-order-direction.orders',
    RETURNS: 'ibtm-domain.resource.form-order-direction.returns'
  },
  [DomainDictionaryEnum.INVOICE_STATUS]: {
    CANCELED: 'ibtm-domain.invoice.status.cancelled',
    NEW: 'ibtm-domain.invoice.status.new',
    GENERATED: 'ibtm-domain.invoice.status.generated'
  },
  [DomainDictionaryEnum.RESOURCE_FORM_STATE]: {
    ADOPTED_BY_ASSOCIATION: 'ibtm-domain.resource.form-state.adopted-by-association',
    ASSIGNED: 'ibtm-domain.resource.form-state.assigned',
    PRINTED: 'ibtm-domain.resource.form-state.printed',
    AVAILABLE_IN_WAREHOUSE: 'ibtm-domain.resource.form-state.available-in-warehouse',
    ISSUED_TO_ASSOCIATION: 'ibtm-domain.resource.form-state.issued-to-association',
    RETURNED: 'ibtm-domain.resource.form-state.returned',
    RELEASED: 'ibtm-domain.resource.form-state.released',
    EXTERNAL_RELEASED: 'ibtm-domain.resource.form-state.external-released',
    ISSUED_TO_ENTREPRENEURS: 'ibtm-domain.resource.form-state.issued-to-entrepreneurs',
    RETURNED_TO_ASSOCIATION: 'ibtm-domain.resource.form-state.returned-to-association',
    RETURNED_TO_GITD: 'ibtm-domain.resource.form-state.returned-to-gitd',
    PERMIT_RETURNED_TO_GITD: 'ibtm-domain.resource.form-state.permit-returned-to-gitd',
    ORDERED: 'ibtm-domain.resource.form-state.ordered',
    TRANSFERRING: 'ibtm-domain.resource.form-state.transferring',
    ORDERED_BY_ASSOCIATION: 'ibtm-domain.resource.form-state.ordered-by-association',
    CANCELLED: 'ibtm-domain.resource.form-state.cancelled'
  },
  [DomainDictionaryEnum.DEPOT_TRANSFER_OPERATION_STATE]: {
    IN_PROGRESS: 'ibtm-domain.depot-transfer-operation-state.in-progress',
    WAITING: 'ibtm-domain.depot-transfer-operation-state.waiting'
  },
  [DomainDictionaryEnum.FOREIGN_PERMIT_TYPE]: {
    SINGLE: 'ibtm-domain.foreign-permit-type.single-permits',
    EKMT: 'ibtm-domain.foreign-permit-type.ekmt-permits'
  },
  [DomainDictionaryEnum.DOCUMENT_STATUS]: {
    CANCELED: 'ibtm-domain.document.status.cancelled',
    READY_FOR_RELEASE: 'ibtm-domain.document.status.ready-for-release',
    READY_TO_SEND: 'ibtm-domain.document.status.ready-to-send',
    UPLOADED: 'ibtm-domain.document.status.uploaded',
    /** Wydany - doręczony */
    RELEASE_DELIVERED: 'ibtm-domain.document.status.release-delivered',
    /** Wydany - oczekujący na doręczenie/ZPO */
    RELEASE_PENDING_FOR_DELIVERY: 'ibtm-domain.document.status.release-pending-for-delivery',
    GENERATED: 'ibtm-domain.document.status.generated',
    /** Przygotowany do wysyłki */
    READY_TO_SENED: 'ibtm-domain.document.status.ready-to-send',
    DELIVERED: 'ibtm-domain.document.status.release-delivered',
    PENDING_DELIVERY: 'ibtm-domain.document.status.release-pending-for-delivery',
    RETURNED: 'ibtm-domain.document.status.returned'
  },
  [DomainDictionaryEnum.PERMISSION_TYPE_CODE]: {
    PERMISSION_ROAD_CARRIER_PASSENGER_CARRIAGE_ABRIDGEMENT:
      'ibtm-domain.permission.type.code.permission-road-carrier-passenger-carriage-abridgement',
    PERMISSION_OWN_NEEDS_PASSENGER_CARRIAGE_ABRIDGEMENT:
      'ibtm-domain.permission.type.code.permission-own-needs-passenger-carriage-abridgement',
    DRIVER_CERTIFICATE: 'ibtm-domain.permission.type.code.driver-certificate',
    LICENSE_COPY: 'ibtm-domain.permission.type.code.license-copy',
    PERMISSION_SPO_OCCASIONAL_ABRIDGEMENT: 'ibtm-domain.permission.type.code.permission-spo-occasional-abridgement',
    PERMISSION_SPO_UE_ABRIDGEMENT: 'ibtm-domain.permission.type.code.permission-spo-ue-abridgement',
    PERMISSION_SPO_OCCASIONAL: 'ibtm-domain.permission.type.code.permission-spo-occasional',
    PERMISSION_ROAD_CARRIER_GOODS_CARRIAGE: 'ibtm-domain.permission.type.code.permission-road-carrier-goods-carriage',
    PERMISSION_COPY: 'ibtm-domain.permission.type.code.permission-copy',
    PERMISSION_SPO_SHUTTLE_ABRIDGEMENT: 'ibtm-domain.permission.type.code.permission-spo-shuttle-abridgement',
    PERMISSION_ROAD_CARRIER_GOODS_CARRIAGE_ABRIDGEMENT:
      'ibtm-domain.permission.type.code.permission-road-carrier-goods-carriage-abridgement',
    PERMISSION_OWN_NEEDS_GOODS_CARRIAGE: 'ibtm-domain.permission.type.code.permission-own-needs-goods-carriage',
    PERMISSION: 'ibtm-domain.permission.type.code.permission',
    PERMISSION_SPO_TRANSIT: 'ibtm-domain.permission.type.code.permission-spo-transit',
    PERMISSION_OWN_NEEDS_GOODS_CARRIAGE_ABRIDGEMENT:
      'ibtm-domain.permission.type.code.permission-own-needs-goods-carriage-abridgement',
    FOREIGN_PERMISSION: 'ibtm-domain.permission.type.code.foreign-permission',
    PERMISSION_SPO_TRANSIT_ABRIDGEMENT: 'ibtm-domain.permission.type.code.permission-spo-transit-abridgement',
    LICENSE: 'ibtm-domain.permission.type.code.license',
    PERMISSION_SPO_SHUTTLE: 'ibtm-domain.permission.type.code.permission-spo-shuttle',
    PERMISSION_OWN_NEEDS_PASSENGER_CARRIAGE: 'ibtm-domain.permission.type.code.permission-own-needs-passenger-carriage',
    PERMISSION_SPO_OUTSIDE_UE: 'ibtm-domain.permission.type.code.permission-spo-outside-ue',
    PERMISSION_SPO_OUTSIDE_UE_ABRIDGEMENT: 'ibtm-domain.permission.type.code.permission-spo-outside-ue-abridgement',
    PERMISSION_SPO_UE: 'ibtm-domain.permission.type.code.permission-spo-ue',
    PERMISSION_ROAD_CARRIER_PASSENGER_CARRIAGE:
      'ibtm-domain.permission.type.code.permission-road-carrier-passenger-carriage'
  },
  [DomainDictionaryEnum.PERMISSION_TYPE]: {
    PERMISSION_OWN_NEEDS_GOODS_CARRIAGE_ABRIDGEMENT: 'ibtm-domain.permission.type.own-needs.goods-carriage-abridgement',
    PERMISSION_OWN_NEEDS_GOODS_CARRIAGE: 'ibtm-domain.permission.type.own-needs.goods-carriage'
  },
  [DomainDictionaryEnum.FOLDER_TYPE]: {
    PPO: 'ibtm-domain.folder.type.ppo',
    OP: 'ibtm-domain.folder.type.op',
    TP: 'ibtm-domain.folder.type.tp',
    PA: 'ibtm-domain.folder.type.pa',
    ZZ: 'ibtm-domain.folder.type.zz',
    WITD: 'ibtm-domain.folder.type.witd',
    KS: 'ibtm-domain.folder.type.ks',
    MZ: 'ibtm-domain.folder.type.mz'
  },
  [DomainDictionaryEnum.PROXY_TYPE]: {
    PERMANENT: 'ibtm-domain.proxy-type.permanent',
    ONE_TIME: 'ibtm-domain.proxy-type.one-time'
  },
  [DomainDictionaryEnum.SCOPES_OF_FOREIGN_PERMITS_AUTHORIZATIONS]: {
    SIGNING_DECLARATIONS: 'ibtm-domain.scopes-of-foreign-permits-authorizations.signing-declarations'
  },
  [DomainDictionaryEnum.PROCEEDING_TYPE]: {
    ADMINISTRATIVE: 'ibtm-domain.proceeding-type.administrative',
    VERIFICATION: 'ibtm-domain.proceeding-type.verification'
  },
  [DomainDictionaryEnum.INVOICE_ITEM_SETTLEMENT_TYPE]: {
    K: 'ibtm-domain.invoice-item-settlement-type.k'
  },
  [DomainDictionaryEnum.PERMIT_APPLICATION_STATUS_ZZ]: {
    RELEASED_OR_IN_PROGRESS: 'ibtm-domain.permit-application-status-zz.w'
  },
  [DomainDictionaryEnum.CERTIFICATE_S_STATUS]: {
    RELEASE: 'ibtm-domain.certificate_s_status.release',
    COMPLETE: 'ibtm-domain.certificate_s_status.complete',
    NONACTIVATED: 'ibtm-domain.certificate_s_status.nonactivated',
    REMIT: 'ibtm-domain.certificate_s_status.remit'
  },
  [DomainDictionaryEnum.DOCUMENT_TEMPLATE_GROUP]: {
    CONFIRMATION_OF_SUBMISSION: 'ibtm-domain.document-template-group.confirmation-of-submission',
    MONITION: 'ibtm-domain.document-template-group.monition',
    ISSUE_DOCUMENT: 'ibtm-domain.document-template-group.issue-document',
    OTHER: 'ibtm-domain.document-template-group.other',
    LR1A: 'ibtm-domain.document-template-group.LR1A',
    LR1B: 'ibtm-domain.document-template-group.LR1B',
    LR2: 'ibtm-domain.document-template-group.LR2',
    LR3: 'ibtm-domain.document-template-group.LR3',
    LR4A: 'ibtm-domain.document-template-group.LR4A',
    LR4B: 'ibtm-domain.document-template-group.LR4B',
    LR5A: 'ibtm-domain.document-template-group.LR5A',
    LR5B: 'ibtm-domain.document-template-group.LR5B',
    LR6A: 'ibtm-domain.document-template-group.LR6A',
    LR6B: 'ibtm-domain.document-template-group.LR6B',
    LR7A: 'ibtm-domain.document-template-group.LR7A',
    LR7B: 'ibtm-domain.document-template-group.LR7B',
    LR8: 'ibtm-domain.document-template-group.LR8',
    LR9: 'ibtm-domain.document-template-group.LR9',
    LR10: 'ibtm-domain.document-template-group.LR10',
    LR11: 'ibtm-domain.document-template-group.LR11',
    LR12A: 'ibtm-domain.document-template-group.LR12A',
    LR12B: 'ibtm-domain.document-template-group.LR12B',
    LR13A: 'ibtm-domain.document-template-group.LR13A',
    LR13B: 'ibtm-domain.document-template-group.LR13B',
    OB: 'ibtm-domain.document-template-group.OB',
    IZDP: 'ibtm-domain.document-template-group.IZDP',
    pismoA: 'ibtm-domain.document-template-group.pismoA',
    pismoB: 'ibtm-domain.document-template-group.pismoB',
    pismoC: 'ibtm-domain.document-template-group.pismoC',
    pismoD: 'ibtm-domain.document-template-group.pismoD',
    IZPL: 'ibtm-domain.document-template-group.IZPL',
    LPO7: 'ibtm-domain.document-template-group.LPO7',
    LPO6: 'ibtm-domain.document-template-group.LPO6',
    LPO5: 'ibtm-domain.document-template-group.LPO5',
    LPO4: 'ibtm-domain.document-template-group.LPO4',
    LPO3: 'ibtm-domain.document-template-group.LPO3',
    LPO2: 'ibtm-domain.document-template-group.LPO2',
    LPO8: 'ibtm-domain.document-template-group.LPO8',
    LPO: 'ibtm-domain.document-template-group.LPO',
    sprA: 'ibtm-domain.document-template-group.sprA',
    sprB: 'ibtm-domain.document-template-group.sprB',
    wydruk: 'ibtm-domain.document-template-group.wydruk',
    DECISION: 'ibtm-domain.document-template-group.decision',
    NOTICE: 'ibtm-domain.document-template-group.notice',
    DOCUMENT_TO_NATIONAL_AUTHORITIES: 'ibtm-domain.document-template-group.document-to-national-authorities',
    CAUTION: 'ibtm-domain.document-template-group.caution',
    RESOLUTION: 'ibtm-domain.document-template-group.resolution',
    DOCUMENT_TO_FOREIGN_AUTHORITIES: 'ibtm-domain.document-template-group.document-to-foreign-authorities',
    COMPLAINTS: 'ibtm-domain.document-template-group.complaints',
    FEE_FORMS: 'ibtm-domain.document-template-group.fee-forms',
    STATEMENTS: 'ibtm-domain.document-template-group.statements',
    CONFIRMATION_OF_RETURN: 'ibtm-domain.document-template-group.confirmation-of-return'
  },
  [DomainDictionaryEnum.INVOICE_CORRECTION_REASON]: {
    REVERT_SUSPENSION: 'ibtm-domain.invoice-correction-reason.revert-suspension-of-internation-transport-14a-utd',
    SUSPENSION: 'ibtm-domain.invoice-correction-reason.suspension-of-international-transport-14a-utd'
  },
  [DomainDictionaryEnum.FORM_OPERATION_STATUS]: {
    SUSPENDED: 'ibtm-domain.form-operation.status.suspended',
    EXPIRED: 'ibtm-domain.form-operation.status.expired',
    ACTIVE: 'ibtm-domain.form-operation.status.active',
    REVOKED: 'ibtm-domain.form-operation.status.revoked'
  },
  [DomainDictionaryEnum.ARCHIVE_TYPE]: {
    CARRY_ON: 'ibtm-domain.archive-type.carry-on',
    DEPARTAMENTAL: 'ibtm-domain.archive-type.departmental'
  },
  [DomainDictionaryEnum.RESOURCE_FORM_ORDER_STATUS]: {
    PENDING: 'ibtm-domain.resource.form-order-status.pending',
    REJECTED: 'ibtm-domain.resource.form-order-status.rejected',
    COMPLETED: 'ibtm-domain.resource.form-order-status.completed'
  },
  [DomainDictionaryEnum.SUBJECT_TYPE]: {
    ENTREPRENEUR: 'ibtm-domain.subject-type.entrepreneur',
    ASSOCIATION: 'ibtm-domain.subject-type.association'
  },
  [DomainDictionaryEnum.RESOURCE_FORM_NAME]: {
    PARTIAL_SINGLE: 'ibtm-domain.resource.form-name.single',
    LICENSE_TW: 'ibtm-domain.resource.form-name.license-tw',
    PARTIAL_EURO_CLASS_5: 'euro-class-5',
    PARTIAL_EURO_CLASS_6: 'euro-class-6',
    CEMT_ANNUAL_PROVISION_2023: 'ibtm-domain.resource.form-name.cemt-annual-provision-2023',
    CEMT_ANNUAL_PROVISION_BASIC_2023: 'ibtm-domain.resource.form-name.cemt-annual-provision-basic-2023',
    CEMT_RELOCATION_2023: 'ibtm-domain.resource.form-name.cemt-relocation-2023'
  },
  [DomainDictionaryEnum.EURO_CLASS]: {
    EURO5: 'ibtm-domain.euro-class.euro5',
    EURO6: 'ibtm-domain.euro-class.euro6'
  },
  [DomainDictionaryEnum.RESOURCE_TYPE_TRANSIT_TYPE]: {
    PASSENGER: 'ibtm-domain.resource-type-transit-type.passenger'
  },
  [DomainDictionaryEnum.APPLICATION_EXPLOITATION_BASE_MODIFICATION_TYPE]: {
    MODIFICATION: 'ibtm-domain.application.license.exploitation-base.modification',
    SUBMISSION: 'ibtm-domain.application.license.exploitation-base.submission',
    ADD: 'ibtm-domain.application.license.exploitation-base.add'
  },
  [DomainDictionaryEnum.IDENTITY_DOCUMENT_TYPE]: {
    PASSPORT: 'ibtm-domain.identity-document.passport',
    VEHICLES_LIST: 'ibtm-domain.document-type.vehicles-list'
  },
  [DomainDictionaryEnum.DOCUMENT_TYPE]: {
    VEHICLES_LIST: 'ibtm-domain.document-type.vehicles-list',
    COMPLAINT_FORM: 'ibtm-domain.document-type.complaint-form',
    FEE_PRINT: 'ibtm-domain.document-type.fee-print',
    LIST_FOR_COURIER: 'ibtm-domain.document-type.list-for-courier',
    TRANSIT_SCHEDULE: 'ibtm-domain.document-type.transit-schedule',
    TRANSIT_SCHEDULE_DATES: 'ibtm-domain.document-type.transit-schedule-dates',
    CONFIRMATION_OF_ISSUING_DOCUMENTS: 'ibtm-domain.document-type.confirmation-of-issuing-documents'
  },
  [DomainDictionaryEnum.DOCUMENT_TEMPLATE_KEY]: {
    CONFIRMATION_OF_ISSUING_DOCUMENTS: 'ibtm-domain.document-template-key.confirmation-of-issuing-documents'
  },
  [DomainDictionaryEnum.PERMISSION_STATUS]: {
    ACTIVE: 'ibtm-domain.permission-status.active',
    INACTIVE: 'ibtm-domain.permission-status.inactive',
    REVOKED: 'ibtm-domain.permission-status.revoked',
    PENDING: 'ibtm-domain.permission-status.pending',
    FORWARDED_TO_STAROSTY: 'ibtm-domain.permission-status.transfer-to-county-office',
    SUSPEND: 'ibtm-domain.permission-status.suspend'
  },
  [DomainDictionaryEnum.COMPETENCE]: {
    95: 'ibtm-domain.competence.95'
  },
  [DomainDictionaryEnum.DECISION_PERMISSION_TYPE]: {
    LICENSE: 'ibtm-domain.decision-permission-type.license',
    PERMISSION: 'ibtm-domain.decision-permission-type.permission',
    DRIVER_CERTIFICATE: 'ibtm-domain.decision-permission-type.driver-certificate'
  },
  [DomainDictionaryEnum.LEGAL_NATURE_KEY]: {
    NATURAL_PERSON: 'ibtm-domain.legal-nature.7',
    FOREIGN_ENTERPRISE: 'ibtm-domain.legal-nature.9',
    /** umowa spółki cywilnej */
    CIVIL_PARTNERSHIP_AGREEMENT: 'ibtm-domain.legal-nature.d',
    /** spółka cywilna */
    CIVIL_PARTNERSHIP: 'ibtm-domain.legal-nature.6',
    ASSOCIATION: 'ibtm-domain.legal-nature.o'
  },
  [DomainDictionaryEnum.COUNTRY]: {
    POLAND: 'ibtm-domain.country-code.poland'
  },
  [DomainDictionaryEnum.COUNTRY_CODE]: {
    POLAND: 'ibtm-domain.country-code.pol'
  },
  [DomainDictionaryEnum.FOLDER_LOCK_TYPE]: {
    BLOCKADE_OF_ISSUANCE_OF_CERTIFICATES: 'ibtm-domain.folder-lock-type.blockade-of-issuance-of-certificates',
    COURIER_PARCEL_NOT_COLLECTED: 'ibtm-domain.folder-lock-type.courier-parcel-not-collected'
  },
  [DomainDictionaryEnum.APPLICATION_FINANCIAL_SECURITY_MODIFICATION_TYPE]: {
    MODIFICATION: 'ibtm-domain.application.license.financial-security.modification',
    ADD_AND_MODIFICATION: 'ibtm-domain.application.license.financial-security.add-and-modification',
    ADD: 'ibtm-domain.application.license.financial-security.add'
  },
  [DomainDictionaryEnum.PARTNER_APPLICANT]: {
    SUBCONTRACOTR: 'ibtm-domain.partner-applicant.subcontractor',
    PARTNER: 'ibtm-domain.partner-applicant.partner',
    APPLICANT: 'ibtm-domain.partner-applicant.applicant'
  },
  [DomainDictionaryEnum.DURATION_OF_PERMIT]: {
    1: 'ibtm-domain.duration-of-permit.1',
    2: 'ibtm-domain.duration-of-permit.2',
    3: 'ibtm-domain.duration-of-permit.3',
    4: 'ibtm-domain.duration-of-permit.4',
    5: 'ibtm-domain.duration-of-permit.5',
    6: 'ibtm-domain.duration-of-permit.6',
    a: 'ibtm-domain.duration-of-permit.a',
    b: 'ibtm-domain.duration-of-permit.b',
    c: 'ibtm-domain.duration-of-permit.c',
    d: 'ibtm-domain.duration-of-permit.d',
    e: 'ibtm-domain.duration-of-permit.e',
    f: 'ibtm-domain.duration-of-permit.f'
  },
  [DomainDictionaryEnum.RETURNS_OF_FOREIGN_LICENSES]: {
    NEUTRAL: 'ibtm-domain.returns-of-foreign-licenses.l',
    NOT_USED_CORRECTLY: 'ibtm-domain.returns-of-foreign-licenses.x',
    NOT_USED: 'ibtm-domain.returns-of-foreign-licenses.n',
    AFTER_DEADLINE: 'ibtm-domain.returns-of-foreign-licenses.t',
    STOLEN: 'ibtm-domain.returns-of-foreign-licenses.s',
    USED_CORRECTLY: 'ibtm-domain.returns-of-foreign-licenses.a',
    LOST: 'ibtm-domain.returns-of-foreign-licenses.z',
    APPROVED_BY_MANAGER: 'ibtm-domain.returns-of-foreign-licenses.k'
  },
  [DomainDictionaryEnum.VOIVODESHIP]: {},
  [DomainDictionaryEnum.TRANSIT_DIRECTION]: {
    ROUND_TRIP: 'ibtm-domain.transit-direction.round-trip',
    RETURN: 'ibtm-domain.transit-direction.return',
    ONE_WAY: 'ibtm-domain.transit-direction.one-way'
  },
  [DomainDictionaryEnum.TRANSIT_LINE_TYPE]: {
    REGULAR_LINE_WB: 'ibtm-domain.transit-line-type.regular-line-wb',
    REGULAR_LINE_OUTSIDE_UE: 'ibtm-domain.transit-line-type.regular-line-outside-ue',
    REGULAR_LINE_UE: 'ibtm-domain.transit-line-type.regular-line-ue',
    REMARK: 'ibtm-domain.transit-line-type.remark',
    OCCASIONAL: 'ibtm-domain.transit-line-type.occasional-transport',
    SHUTTLE_SERVICES: 'ibtm-domain.transit-line-type.shuttle-services',
    TRANSIT: 'ibtm-domain.transit-line-type.transit',
    PWO1: 'ibtm-domain.transit-line-type.personal-needs.pwo1',
    PWR1: 'ibtm-domain.transit-line-type.personal-needs.pwr1'
  },
  [DomainDictionaryEnum.TRANSPORT_TYPE]: {
    PASSENGER: 'ibtm-domain.personal-needs-permission.transport-type.passenger',
    FREIGHT: 'ibtm-domain.personal-needs-permission.transport-type.freight'
  },
  [DomainDictionaryEnum.LICENSE_TRANSFER_TYPE]: {
    DIVISION: 'ibtm-domain.application.license.transfer-type.division',
    LINK: 'ibtm-domain.application.license.transfer-type.link',
    TRANSFORMATION: 'ibtm-domain.application.license.transfer-type.transformation'
  },
  [DomainDictionaryEnum.FOLDER_CHANGE]: {
    HAS_BEEN_BORROWED: 'ibtm-domain.folder-change.folder-has-been-borrowed',
    BORROWER_CHANGED: 'ibtm-domain.folder-change.folder-borrower-changed'
  },
  [DomainDictionaryEnum.EKMT_CATEGORY]: {
    MOVE: 'ibtm-domain.ekmt-category.move',
    ANNUAL_PROVISION: 'ibtm-domain.ekmt-category.annual-provision',
    ANNUAL_PROVISION_BASIC: 'ibtm-domain.ekmt-category.annual-provision-basic'
  },
  [DomainDictionaryEnum.FOLDER_CHANGE_REASON_TYPE]: {
    UPDATE_LIMIT: 'ibtm-domain.corrections-of-rationing-foreign-permits.supplement-to-the-limit'
  }
};

export default DomainDictionaryEntry;
