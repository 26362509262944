import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';

import { useFormV2Context } from '@libs/common/v2';

import { ValidationProperties } from '../model';

const useFieldValidationHandler = (validationProps: ValidationProperties) => {
  const { fieldName, validation: validationRules } = validationProps;
  const { validationBuilderFunctions: { setFieldValidation, handleUnmountField } = {} } = useFormV2Context();
  const prevValidationRules = useRef(null);

  useEffect(() => {
    const shouldChangeValidation = !isEqual(prevValidationRules.current, validationRules);
    if (shouldChangeValidation) {
      prevValidationRules.current = validationRules;
      if (validationRules) {
        setFieldValidation?.(validationProps);
      } else {
        handleUnmountField?.(fieldName);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationProps, setFieldValidation, validationRules]);

  // funkcja usuwająca walidację field'a po jego odmontowaniu
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => handleUnmountField?.(fieldName), []);
};

export default useFieldValidationHandler;
