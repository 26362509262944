import { useTranslation } from 'react-i18next';
import { AlertApiGetAlertsRequest, AlertSnapshot, AlertSnapshotExtended } from '@stack/report';

import { ALTERNATIVE_DATE_FORMAT, Chip, TableFilterMapper, useCreateColumns } from '@libs/common/v2';
import {
  convertDateToDateTimeFormat,
  convertDateToDateWithoutTimeWithDayOffset,
  DateRangeISO,
  getValue
} from '@libs/common/v2/utils';

import { AlertDictionaryEntryNameEnum } from '@libs/alert';
import { AlertStatusChip } from '@libs/alert/components/ui';
import { useDictionaryTranslations } from '@libs/dictionary';

export default function useAlertsColumns() {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  const { createColumns } = useCreateColumns<AlertSnapshotExtended>({
    pathToTranslate: 'alerts:table'
  });

  const mappedFilterFields: TableFilterMapper<AlertSnapshot, AlertApiGetAlertsRequest> = {
    name: value => ({ nameFragment: value }),
    statusKey: statusKeys => ({ statusKeys: statusKeys.map(({ value }) => value) }),
    sourceName: value => ({ sourceNameFragment: value }),
    description: value => ({ descriptionFragment: value }),
    created: ({ dateFrom, dateTo }: DateRangeISO) => {
      return {
        createdFrom: convertDateToDateWithoutTimeWithDayOffset(dateFrom || ''),
        createdTo: convertDateToDateWithoutTimeWithDayOffset(dateTo || '', ALTERNATIVE_DATE_FORMAT, false)
      };
    },
    modified: ({ dateFrom, dateTo }: DateRangeISO) => ({
      modifiedFrom: convertDateToDateWithoutTimeWithDayOffset(dateFrom || ''),
      modifiedTo: convertDateToDateWithoutTimeWithDayOffset(dateTo || '', ALTERNATIVE_DATE_FORMAT, false)
    })
  };

  const columns = createColumns([
    {
      type: 'TEXT',
      id: 'name',
      accessor: 'name',
      width: 200
    },
    {
      id: 'statusKey',
      header: 'status',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: AlertDictionaryEntryNameEnum.ALERT_STATUS,
      tooltipTitle: ({ statusKey }) => translate(AlertDictionaryEntryNameEnum.ALERT_STATUS, statusKey, t('emptyMark')),
      customAccessor: ({ statusKey }) => <AlertStatusChip statusKey={statusKey} />,
      customXlsxAccessor: ({ statusKey }) =>
        translate(AlertDictionaryEntryNameEnum.ALERT_STATUS, statusKey, t('emptyMark')),
      isSortable: false
    },
    {
      id: 'configurationStatusKey',
      header: 'configurationStatus',
      type: 'DICTIONARY_SINGLE_SELECT',
      dictionaryName: AlertDictionaryEntryNameEnum.ALERT_CONFIGURATION_STATUS,
      tooltipTitle: ({ configurationStatusKey }) =>
        translate(AlertDictionaryEntryNameEnum.ALERT_CONFIGURATION_STATUS, configurationStatusKey, t('emptyMark')),
      customAccessor: ({ configurationStatusKey }) => (
        <Chip title={translate(AlertDictionaryEntryNameEnum.ALERT_CONFIGURATION_STATUS, configurationStatusKey)} />
      ),
      customXlsxAccessor: ({ configurationStatusKey }) =>
        translate(AlertDictionaryEntryNameEnum.ALERT_CONFIGURATION_STATUS, configurationStatusKey, t('emptyMark')),
      isSortable: false,
      isFilterable: false
    },
    {
      type: 'TEXT',
      header: 'source',
      accessor: 'source.name'
    },
    {
      id: 'recipients',
      header: 'recipient',
      type: 'CUSTOM_COLUMN',
      customAccessor: ({ recipients }) => recipients?.emails?.join(', ') || t('emptyMark'),
      isSortable: false,
      isFilterable: false,
      width: 175
    },
    {
      type: 'TEXT',
      accessor: 'description',
      width: 250
    },
    {
      id: 'created',
      type: 'DATE_TIME',
      customAccessor: (row: AlertSnapshotExtended) => getValue(convertDateToDateTimeFormat(row?.created))
    },
    {
      id: 'modified',
      type: 'DATE_TIME',
      customAccessor: (row: AlertSnapshotExtended) => getValue(convertDateToDateTimeFormat(row?.modified))
    }
  ]);

  return { columns, mappedFilterFields };
}
