import { FinancialSecurityDetails } from '@ibtm/domain';

import { useDialog } from '@libs/common/v2';
import { RecursivePartial } from '@libs/common/v2/utils';

import { useGetApiQueryByPerspective } from '@libs/domain/api';

import { useClientFinancialSecurityUpdateMutation, useFinancialSecurityUpdateMutation } from '../../api';
import { FinancialSecurityEditDialog } from '../../components';

function useFinancialSecurityEditDialog() {
  const { openDialog } = useDialog();
  const { getQuery } = useGetApiQueryByPerspective();

  const updateMutation = getQuery(useClientFinancialSecurityUpdateMutation, useFinancialSecurityUpdateMutation);

  const showFinancialSecurityEditDialog = ({
    folderTypeKey,
    financialSecurityId,
    initialData,
    exchangeRateFrom,
    exchangeRateTo
  }: {
    financialSecurityId: string;
    folderTypeKey: string;
    initialData?: RecursivePartial<FinancialSecurityDetails>;
    exchangeRateFrom: string;
    exchangeRateTo: string;
  }) => {
    openDialog(({ closeDialog }) => (
      <FinancialSecurityEditDialog
        folderTypeKey={folderTypeKey}
        onCloseDialog={closeDialog}
        financialSecurityId={financialSecurityId}
        initialData={initialData}
        updateMutation={updateMutation}
        exchangeRateFrom={exchangeRateFrom}
        exchangeRateTo={exchangeRateTo}
        isOpen
      />
    ));
  };

  return { showFinancialSecurityEditDialog };
}

export default useFinancialSecurityEditDialog;
