import { FolderLimitDetails } from '@ibtm/domain';

import { useDialog } from '@libs/common/v2';

import SuspendOrPostponeLimitModal from '../components/details/tabs/singleAuthorization/basicLimit/SuspendOrPostponeLimitModal';

function useSuspendOrPostponeLimitModal() {
  const { openDialog } = useDialog();

  const suspendOrPostponeLimit = ({ data, refetch }: { data: FolderLimitDetails; refetch: () => void }) => {
    openDialog(({ closeDialog }) => (
      <SuspendOrPostponeLimitModal onCloseDialog={closeDialog} open refetch={refetch} data={data} />
    ));
  };

  return { suspendOrPostponeLimit };
}

export default useSuspendOrPostponeLimitModal;
