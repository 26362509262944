import React from 'react';
import { Noop } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Info } from '@mui/icons-material';
import { FormHelperText, InputLabel, Switch, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { useInputStyles } from '@libs/common/v2';
import { Theme, useTheme } from '@libs/common/v2/theme';

interface IProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onBlur?: Noop;
  name: string;
  label?: string;
  inlineLabel?: string;
  helperText?: string;
  className?: string;
  valueTrueText?: string;
  valueFalseText?: string;
  isDisabled?: boolean;
  isDefaultChecked?: boolean;
  isRequired?: boolean;
  isError?: boolean;
  isWarning?: boolean;
  tooltip?: string;
}

function SwitchInput({
  onChange,
  onBlur,
  name,
  label,
  inlineLabel,
  isDefaultChecked,
  isRequired,
  isDisabled,
  helperText,
  className,
  valueTrueText,
  valueFalseText,
  isError,
  isWarning,
  tooltip
}: IProps) {
  const { contrast } = useTheme();

  const [t] = useTranslation();
  const classes = useStyles({ label, contrast });
  const inputStyles = useInputStyles({ isRequired, isDisabled, isError, isWarning });

  return (
    <div className="flex">
      <InputLabel className={clsx(inputStyles.root, className)}>
        <div className={inputStyles.labelWrapper}>
          {label && (
            <span>
              {label} {isRequired && <span className={inputStyles.asterisk}>&nbsp;*</span>}
            </span>
          )}
          {tooltip && (
            <Tooltip title={tooltip} placement="top" arrow data-testid={`${label}-${tooltip}`}>
              <Info className={inputStyles.tooltipIcon} />
            </Tooltip>
          )}
        </div>
        <div className={classes.inputValueLabel}>
          <Switch
            checked={isDefaultChecked}
            name={name}
            onChange={onChange}
            className={classes.input}
            onBlur={onBlur}
            disabled={isDisabled}
          />
          {inlineLabel || (isDefaultChecked ? valueTrueText || t('action.yes') : valueFalseText || t('action.no'))}
        </div>
        {helperText && (
          <FormHelperText className={clsx(inputStyles.formHelperText)} error={isError}>
            {helperText}
          </FormHelperText>
        )}
      </InputLabel>
    </div>
  );
}

const useStyles = makeStyles<Theme, { label: string; contrast?: boolean }>(theme => ({
  input: {
    width: 36,
    height: 20,
    padding: 0,
    display: 'flex',
    marginRight: 14,
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(16px)'
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-disabled': {
        backgroundColor: theme.palette.grey[100],
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.grey[100]
        }
      },
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: theme.palette.white,
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.blue[500]
        }
      }
    },
    '& .MuiSwitch-thumb': {
      boxShadow: theme.shadowsList.sm,
      width: 16,
      height: 16,
      borderRadius: '50%'
    },
    '& .MuiSwitch-track': {
      borderRadius: theme.borderRadiusBase * 1.5,
      backgroundColor: ({ contrast }) => (contrast ? '#5e5c12' : theme.palette.grey[100]),
      opacity: ({ contrast }) => contrast && 1
    }
  },
  inputValueLabel: {
    ...theme.typography.textMd.medium,
    display: 'flex',
    alignItems: 'center',
    marginTop: ({ label }) => (label ? theme.marginBase : 'unset')
  }
}));

export default SwitchInput;
