import { useMemo } from 'react';

import { DictionaryEntryNameEnum, useDictionaryEntryValues } from '@libs/dictionary';

const useFilteredDictionary = (dictionaryEntryType: DictionaryEntryNameEnum, removeByKeys: string[]) => {
  const dictionaryEntries = useDictionaryEntryValues(dictionaryEntryType);

  const filteredDictionaryEntries = useMemo(
    () => dictionaryEntries.filter(item => !removeByKeys.includes(item.value)),
    [dictionaryEntries, removeByKeys]
  );

  return {
    entries: filteredDictionaryEntries
  };
};

export default useFilteredDictionary;
