import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '@libs/report/api';

const exportAllReportTypes = async (reportTypeIds: string[]) => {
  const { data } = await API.reportTypes.exportMany(
    { reportTypeIds },
    { responseType: 'blob', ...endpointsConfig.exportMany }
  );
  return data;
};

function useExportAllReportTypesMutation() {
  return useMutation(exportAllReportTypes);
}

export default useExportAllReportTypesMutation;
