import React, { ReactElement } from 'react';
import { FieldValues, UnpackNestedValue } from 'react-hook-form';

import { SelectOption } from '@libs/common/v2';

import { IPageComponentProps } from '../MetaForm';
import { ApiRegistry, Field, UiMode, ValidationRegistry } from '../models';
import { FieldValidationMapConfig } from '../validation/validation-registry-model';

export interface MetaFormContextState {
  additionalFields?: {
    [key: string]: (properties: any) => ReactElement<any, any>;
  };
  additionalValidators?: {
    [key: string]: (values: Array<any>) => boolean;
  };
  additionalSelectOptionsFilters?: {
    [key: string]: (option: SelectOption) => boolean;
  };
  elementVisibility?: {
    [key: string]: (popertiesParams?, componentParams?) => boolean;
  };
  elementVisibilityAsync?: {
    [key: string]: (popertiesParams?, componentParams?) => Promise<boolean>;
  };
  mode?: UiMode; // VIEW lub FORM
  fields?: Array<Field>;
  apiRegistry?: ApiRegistry;
  queries?: any;
  initialValues?: FieldValues;
  setInitialValues?: () => void;
  validationSchemeRegistry?: ValidationRegistry;
  onSubmit?: (formValues: UnpackNestedValue<FieldValues>) => void;
  onSubmitFinish?: (response?: any) => void;
  onSubmitError?: (error: any) => void;
  formRef?: React.MutableRefObject<{
    onSubmit: () => Promise<any>;
    onReset: () => void;
  }>;
  pageComponentProps?: IPageComponentProps;
  /** mapowanie fieldId na TypFielda na potrzeby generowania scheme walidacji */
  fieldsValidationTypeMap?: React.MutableRefObject<{
    [fieldId: string]: FieldValidationMapConfig;
  }>;
  additionalDiscardFunctions?: React.MutableRefObject<{
    [fieldId: string]: () => void;
  }>;
}

const MetaFormContext = React.createContext<MetaFormContextState>({
  additionalFields: {},
  additionalValidators: {},
  elementVisibility: {},
  initialValues: null,
  mode: UiMode.VIEW,
  fields: null,
  apiRegistry: null,
  queries: null,
  setInitialValues: () => {},
  pageComponentProps: null
});

export default MetaFormContext;
