import { usePaginatedQuery } from 'react-query';
import { useRowSelect } from 'react-table';
import { DefaultApiFindUsersRequest, ExternalNamedObject } from '@avispon/organization-structure';

import { useMultipleSelectColumn, useTableLayoutProps } from '@libs/common/v2';

import SelectionRowActionRender from '@libs/organization-structure/components/details/SelectionRowActionRender';
import { API, UserQueryKeysEnum } from '@libs/user';

import useUserGroupColumns from './useUserGroupColumns';

interface IProps {
  query: string;
  id: string;
  groupId: string;
}

const initialEmptyData: Array<ExternalNamedObject> = [];

function useUserGroupTable({ query, id, groupId }: IProps) {
  const { columns } = useUserGroupColumns();

  const getOrganizationUnitUsers = async (params, customParams: DefaultApiFindUsersRequest) => {
    const { data } = await API.organizationStructure.findUsers({
      ...customParams,
      ...params,
      size: 1000
    });

    const { data: usersList } = await API.group.listUsers({
      ...customParams,
      ...params,
      groupIds: [groupId]
    });

    const userIds = usersList.users.map(user => user.id);

    const filteredData = data.content.filter(item => !userIds.includes(item.id));

    return { data, content: filteredData };
  };

  const useFindOrganizationUnitUsers = (params, queryConfig = {}) => {
    return usePaginatedQuery(
      [UserQueryKeysEnum.USERS_LIST, { ...params, name: query, unitIds: id }],
      getOrganizationUnitUsers,
      queryConfig
    );
  };

  const { data, isLoading, refetch, isFetching } = useFindOrganizationUnitUsers({});

  return useTableLayoutProps({
    tableQuery: data?.content ?? initialEmptyData,
    tableHookOptions: {
      columns,
      tableOptions: {
        manualPagination: false
      },
      tablePlugins: [useRowSelect, useMultipleSelectColumn(SelectionRowActionRender, null, null, [], true)]
    },
    isLoading,
    isFetching,
    refetch
  });
}

export default useUserGroupTable;
