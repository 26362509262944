import { usePaginatedQuery } from 'react-query';
import { LicenseCopySearchFilter as LicenseCopySearchFilterClient } from '@ibtm/client-domain';
import { LicenseCopySearchFilter } from '@ibtm/domain';
import { omit } from 'lodash';

import { IPaginatedModel } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { LicenseCopySnapshotClient } from '../../model/license.model';
import { parseLicenseCopiesList } from '../../parsers/common';
import { endpointsConfig } from '../endpoints-config';

import LicensesQueryKeysEnum from './LicensesQueryKeysEnum';

export const getLicenseCopies = async (_, params: LicenseCopySearchFilter) => {
  const { data } = await API.license.getLicenseCopySnapshotPage(params, {
    ...endpointsConfig.getLicenseCopySnapshotPage
  });

  return {
    ...data,
    content: parseLicenseCopiesList(data.content)
  };
};

export const getLicenseCopiesClient = async (_, params: LicenseCopySearchFilterClient) => {
  const fixedParams = omit(params, ['proceedingId']);

  const { data } = await API.client.license.getLicenseCopySnapshotPage('', '', fixedParams, {
    ...endpointsConfig.getLicenseCopySnapshotPage
  });

  return {
    ...data,
    content: parseLicenseCopiesList(data.content)
  };
};

export default function useLicenseCopiesQuery(params: LicenseCopySearchFilter, queryConfig = {}) {
  const { getQuery } = useGetApiQueryByPerspective();

  return usePaginatedQuery<IPaginatedModel<LicenseCopySnapshotClient>>(
    [LicensesQueryKeysEnum.LICENSE_COPIES_LIST, params],
    getQuery(getLicenseCopiesClient, getLicenseCopies),
    queryConfig
  );
}
