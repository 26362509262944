import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosResponse } from 'axios';
import { ObjectSchema } from 'yup';

import {
  ButtonsGroup,
  FormMode,
  FormV2Context,
  IAdditionalFilter,
  IPaginatedModel,
  TableAdditionalFiltersPanel,
  TableButtonFilters,
  TableButtonRefetch,
  TableGlobalFilters,
  TableXLSXDownloader,
  useIsSmallScreen
} from '@libs/common/v2';

import { TableHeaderMobile } from './TableHeaderMobile';

interface IProps<T extends Record<string, any>> {
  headerActions?: React.ReactNode;
  xlsxDownload?: {
    filterConverter?: Record<string, (value: unknown) => Record<string, any>>;
    sortByConverter?: Partial<Record<keyof T, string>>;
    initialParamsConverter?: Record<string, (value: unknown) => Record<string, any>>;
    additionalParams?: Record<string, any>;
    apiRequest?: (parameters: {
      page: number;
      size: number;
      sort?: any;
      [_: string]: any;
    }) => Promise<AxiosResponse<IPaginatedModel<T>>>;
    fileName: string;
    pathToXLSXTranslation: string;
  };
  headerFiltersDefaultValues?: Record<string, any>;
  headerFiltersYupResolverSchema?: ObjectSchema;
  globalFilters?: IAdditionalFilter[];
  additionalFilters?: IAdditionalFilter[];
  isFilterEnabled?: boolean;
  isRefreshEnabled?: boolean;
  mobileHeaderActions?: any;
  /**
   * Property służy do odświeżenia tabeli o wartość zmienioną w global filters
   */
  setTableQueryParams?: React.Dispatch<React.SetStateAction<Record<string, unknown>>>;
  tableQueryParams?: Record<string, unknown>;
}

function TableWithoutHookHeaderActions<T extends Record<string, any>>({
  headerActions,
  mobileHeaderActions,
  additionalFilters,
  xlsxDownload,
  headerFiltersDefaultValues,
  headerFiltersYupResolverSchema = null,
  tableQueryParams,
  setTableQueryParams,
  globalFilters,
  isFilterEnabled,
  isRefreshEnabled
}: IProps<T>) {
  const { isSmallScreen } = useIsSmallScreen();
  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    register,
    trigger,
    unregister,
    reset,
    watch,
    formState: { errors, isSubmitting, isDirty, submitCount }
  } = useForm<Record<string, any>>({
    defaultValues: headerFiltersDefaultValues,
    resolver: headerFiltersYupResolverSchema && yupResolver(headerFiltersYupResolverSchema),
    mode: 'onBlur',
    criteriaMode: 'all'
  });

  const values = useMemo(
    () => ({
      control,
      setValue,
      register,
      unregister,
      watch,
      trigger,
      errors,
      isDirty,
      isSubmitting,
      submitCount,
      getValues,
      handleSubmit,
      initialValues: headerFiltersDefaultValues,
      reset,
      formMode: FormMode.CREATE
    }),
    [
      headerFiltersDefaultValues,
      control,
      setValue,
      register,
      unregister,
      watch,
      trigger,
      errors,
      isDirty,
      isSubmitting,
      submitCount,
      getValues,
      handleSubmit,
      reset
    ]
  );

  return (
    <>
      <FormV2Context.Provider value={values}>
        {globalFilters && <TableGlobalFilters globalFilters={globalFilters} />}
        {isFilterEnabled && !isSmallScreen && <TableButtonFilters />}
        {additionalFilters && (
          <TableAdditionalFiltersPanel
            tableQueryParams={tableQueryParams}
            setTableQueryParams={setTableQueryParams}
            additionalFilters={additionalFilters}
          />
        )}
        {xlsxDownload && !isSmallScreen && (
          <TableXLSXDownloader
            {...xlsxDownload}
            mappedFilterFields={xlsxDownload.filterConverter}
            mappedSortFields={xlsxDownload.sortByConverter}
            mappedParamsFields={xlsxDownload.initialParamsConverter}
            tableQueryParams={tableQueryParams}
          />
        )}
        {isRefreshEnabled && !isSmallScreen && <TableButtonRefetch />}
        {isSmallScreen && (
          <TableHeaderMobile
            isFilterEnabled={isFilterEnabled}
            isRefreshEnabled={isRefreshEnabled}
            xlsxDownload={xlsxDownload}
            tableQueryParams={tableQueryParams}
            additionalActions={mobileHeaderActions}
          />
        )}
      </FormV2Context.Provider>
      {headerActions && (!mobileHeaderActions || !isSmallScreen) && <ButtonsGroup>{headerActions}</ButtonsGroup>}
    </>
  );
}

export default TableWithoutHookHeaderActions;
