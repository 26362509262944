import { useTranslation } from 'react-i18next';

import { TableHeaderButton, TableIconButton, useTableAdapter, useTableContext } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

interface IProps {
  isIcon?: boolean;
  isPrimary?: boolean;
  isSecondary?: boolean;
  title?: string;
  variant?: 'text' | 'contained' | 'outlined';
  actionKey?: UIElementNameEnum;
}

function TableButtonAddNewLastRow({ isIcon, isPrimary, isSecondary, title, variant = 'contained', actionKey }: IProps) {
  const [t] = useTranslation();
  const tableInstance = useTableAdapter();
  const tableContext = useTableContext();

  const handleClick = () => {
    tableInstance.addNewLastRow(tableInstance, tableContext);
  };

  if (isIcon) {
    return <TableIconButton icon="AddIcon" tooltipTitle={title || t('action.addNewRow')} onClick={handleClick} />;
  }
  return (
    <TableHeaderButton
      label={title || t('action.addNewRow')}
      onClick={handleClick}
      variant={variant}
      actionKey={actionKey}
      isSecondary={isSecondary}
      isPrimary={isPrimary}
      isFullWidth
    />
  );
}

export default TableButtonAddNewLastRow;
