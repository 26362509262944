import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountSelectorSnapshotRoles, CreateBulkNotificationCommand } from '@avispon/message';

import { NUMBER_OF_AUTOCOMPLETE_RESULTS, SelectWithFetchedOptionsField, typedNameV2 } from '@libs/common/v2';

import { API } from '@libs/notification/api';

interface IProps {
  isDisabled: boolean;
  isRequired: boolean;
}

function UsersAutocompleteField({ isDisabled, isRequired }: IProps) {
  const [t] = useTranslation();

  const rolesWithoutClientPortalUserRole = useMemo(
    () =>
      Object.keys(AccountSelectorSnapshotRoles).filter(
        role => role !== AccountSelectorSnapshotRoles.CLIENT
      ) as AccountSelectorSnapshotRoles[],
    []
  );

  const getUsersList = (text: string) => {
    return API.user.accountController.searchLiteAccounts({
      page: 0,
      size: NUMBER_OF_AUTOCOMPLETE_RESULTS,
      roles: rolesWithoutClientPortalUserRole,
      query: text,
      sort: [],
      statuses: ['VERIFIED']
    });
  };

  return (
    <SelectWithFetchedOptionsField
      fetchFunction={getUsersList}
      getOptionLabel={user => (user?.name || user?.surname ? `${user?.name} ${user?.surname}` : t('emptyMark'))}
      label={t('messages:field.allSelectedUsers')}
      name={typedNameV2<CreateBulkNotificationCommand>('recipients.accountSelector.accountIds')}
      isDisabled={isDisabled}
      isRequired={isRequired}
      isClearable={false}
      isMultiple
    />
  );
}

export default UsersAutocompleteField;
