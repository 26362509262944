export enum ApplicationProcesTransitionsEnum {
  /**  Anuluj wniosek */
  CANCEL_APPLICATION = 'CANCEL_APPLICATION',
  /** Cofnij do poprawy */
  BACK_TO_PREPARE_DECISION_DRAFT = 'BACK_TO_PREPARE_DECISION_DRAFT',
  /**  Pozostaw bez uzupełnienia */
  SET_NOT_CONSIDERED_APPLICATION_STATUS = 'SET_NOT_CONSIDERED_APPLICATION_STATUS',
  /**  Przekaż do akceptacji */
  SET_APPLICATION_STATUS_AS_ACCEPTED = 'SET_APPLICATION_STATUS_AS_ACCEPTED',
  /**  Przekaż do druku */
  SEND_TO_PRINT = 'SEND_TO_PRINT',
  /**  Przekaż do innego wydziału */
  TRANSFER_TO_OTHER_DEPARTMENT = 'TRANSFER_TO_OTHER_DEPARTMENT',
  /** Przygotuj projekt postępowania / decyzji */
  PREPARE_DECISION_DRAFT = 'PREPARE_DECISION_DRAFT',
  /** Wezwij do uzupełnienia */
  SET_INCOMPLETE_APPLICATION_STATUS = 'SET_INCOMPLETE_APPLICATION_STATUS',
  /**  Wycofaj wniosek */
  WITHDRAW_APPLICATION = 'WITHDRAW_APPLICATION',
  /**  Zapisz do teczki */
  SAVE_DATA_TO_FOLDER = 'SAVE_DATA_TO_FOLDER',
  /**  Zwróć */
  RETURN_FOR_CORRECTIONS = 'RETURN_FOR_CORRECTIONS',
  /**  Zwróć */
  ASSIGN_TO_HEAD_OF_DEPARTMENT = 'ASSIGN_TO_HEAD_OF_DEPARTMENT',
  /** Ponów weryfikacje formalno-merytoryczna */
  SET_PENDING_VERIFICATION_APPLICATION_STATUS = 'SET_PENDING_VERIFICATION_APPLICATION_STATUS',
  /** Wyślij do ponownego przedruku */
  SEND_FOR_REPRINT = 'SEND_FOR_REPRINT',
  /** Odmów wydania uprawnień */
  REFUSE_TO_ISSUE_PERMISSION = 'REFUSE_TO_ISSUE_PERMISSION'
}

const transitionsKeysMap: { [key in ApplicationProcesTransitionsEnum]: Array<string> } = {
  /**  Anuluj wniosek */
  [ApplicationProcesTransitionsEnum.CANCEL_APPLICATION]: ['cancelApplication'],
  /** Cofnij do poprawy */
  [ApplicationProcesTransitionsEnum.BACK_TO_PREPARE_DECISION_DRAFT]: [
    'setApplicationStatusAsConsidered',
    'backToPrepareDecisionDraft',
    'passBackToPrepareDecisionDraft'
  ],
  /**  Pozostaw bez uzupełnienia */
  [ApplicationProcesTransitionsEnum.SET_NOT_CONSIDERED_APPLICATION_STATUS]: ['setNotConsideredApplicationStatus'],
  /**  Przekaż do akceptacji */
  [ApplicationProcesTransitionsEnum.SET_APPLICATION_STATUS_AS_ACCEPTED]: ['setApplicationStatusAsAccepted'],
  /**  Przekaż do druku */
  [ApplicationProcesTransitionsEnum.SEND_TO_PRINT]: ['sendToPrint'],
  /**  Przekaż do innego wydziału */
  [ApplicationProcesTransitionsEnum.TRANSFER_TO_OTHER_DEPARTMENT]: ['transferToOtherDepartment'],
  /** Przygotuj projekt postępowania / decyzji */
  [ApplicationProcesTransitionsEnum.PREPARE_DECISION_DRAFT]: [
    'returnToPrepareDecisionDraft',
    'prepareDraftDecision',
    'prepareDecision',
    'prepareDecisionDraft',
    'prepareDecisionDraftEmployee',
    'passPrepareDecisionDraft'
  ],
  /** Wezwij do uzupełnienia */
  [ApplicationProcesTransitionsEnum.SET_INCOMPLETE_APPLICATION_STATUS]: [
    'ibtm-process.message.label.set-incomplete-application-status',
    'setIncompleteApplicationStatus',
    'Oczekuj na uzupełnienie wniosku'
  ],
  /**  Wycofaj wniosek */
  [ApplicationProcesTransitionsEnum.WITHDRAW_APPLICATION]: ['withdrawApplication'],
  /**  Zapisz do teczki */
  [ApplicationProcesTransitionsEnum.SAVE_DATA_TO_FOLDER]: ['saveDataToFolder'],
  /**  Zwróć */
  [ApplicationProcesTransitionsEnum.RETURN_FOR_CORRECTIONS]: ['returnForCorrections'],
  /**  Zwróć */
  [ApplicationProcesTransitionsEnum.ASSIGN_TO_HEAD_OF_DEPARTMENT]: ['assignToHeadOfDepartment'],
  /** Ponów weryfikacje formalno-merytoryczna */
  [ApplicationProcesTransitionsEnum.SET_PENDING_VERIFICATION_APPLICATION_STATUS]: [
    'setPendingVerificationApplicationStatus'
  ],
  /** Wyślij do ponownego przedruku */
  [ApplicationProcesTransitionsEnum.SEND_FOR_REPRINT]: ['sendForReprint'],
  /** Odmów wydania uprawnień */
  [ApplicationProcesTransitionsEnum.REFUSE_TO_ISSUE_PERMISSION]: ['refuseToIssuePermission']
};
export const getApplicationProcessTransitionNameByKey = (dictionaryKey: string) => {
  const [transitionName] = Object.entries(transitionsKeysMap).find(([, value]) => value?.includes(dictionaryKey)) || [];
  return transitionName;
};
