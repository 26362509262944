import { usePaginatedQuery } from 'react-query';
import { ResourceObjectOrderSearchFilter } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { parseAssociationOrdersList } from '../../../utils';
import { endpointsConfig } from '../../endpoints-config';
import { ResourceQueryKeysEnum } from '..';

const getAssociationOrders = async (params: ResourceObjectOrderSearchFilter) => {
  const { data } = await API.resourceObjectsOrders.getResourceObjectOrders(params, {
    ...endpointsConfig.getResourceObjectOrders
  });

  return {
    ...data,
    content: parseAssociationOrdersList(data.content)
  };
};

export default function useAssociationOrdersQuery(params: ResourceObjectOrderSearchFilter) {
  return usePaginatedQuery<any>([ResourceQueryKeysEnum.ASSOCIATION_ORDERS_LIST, params], () =>
    getAssociationOrders(params)
  );
}
