import { useTranslation } from 'react-i18next';

import { Dialog, TableLayout } from '@libs/common/v2';

import { useReputationQueryResultsTable } from '@libs/domain/folder';

interface Props {
  open: boolean;
  setQueryDetailsId: (id: string) => void;
  id: string;
}

function ReputationQuerySearchModal({ open, setQueryDetailsId, id }: Props) {
  const [t] = useTranslation();
  const tableProps = useReputationQueryResultsTable(id);

  return (
    <Dialog
      title={t('folder:details.reputationDetailsModal.title')}
      onCancel={() => setQueryDetailsId(null)}
      isOpen={open}
    >
      <TableLayout
        {...tableProps}
        isSection
        isFilterEnabled={false}
        isRefreshEnabled={false}
        isActionColumnEnabled={false}
      />
    </Dialog>
  );
}

export default ReputationQuerySearchModal;
