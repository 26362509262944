import { InvoiceCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function createCorrection(request: InvoiceCreateRequest & { suspensionId: string }) {
  const { suspensionId, ...rest } = request;
  return API.suspensionReleaseDocuments.createSuspensionInvoice(suspensionId, rest, {
    ...endpointsConfig.createSuspensionInvoice
  });
}

function useSuspensionCreateCorrectionMutation() {
  return useMutation(createCorrection);
}

export default useSuspensionCreateCorrectionMutation;
