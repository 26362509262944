import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '..';

const resendEmailConfirmationLink = async (accountId: string) => {
  const { data } = await API.user.accountController.resendEmailConfirmationLink(
    { accountId },
    { ...endpointsConfig.resendEmailConfirmationLink }
  );
  return data;
};

export function useResendEmailConfirmationLinkMutation() {
  return useMutation(resendEmailConfirmationLink);
}
