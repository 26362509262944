import { ForeignPermissionDocumentCreateRequest } from '@ibtm/domain';

import { DomainDictionaryEntry } from '@libs/domain/config';
import { DocumentsQueryKeysEnum, useGenerateApplicationDocumentDialog } from '@libs/domain/documents';

import { useGenerateForeignPermissionDocumentMutation } from '../api';
import { IDownloadFileParam } from '../model';

interface IHookParams {
  onSuccess?: (formData?: any) => void;
  onCancel?: () => void;
  downloadFileAfterSettleForeignPermission?: (params: IDownloadFileParam) => void;
  isForeignPermissionsUse?: boolean;
}

function useGenerateForeignPermissionDocument({
  onCancel,
  onSuccess,
  downloadFileAfterSettleForeignPermission,
  isForeignPermissionsUse
}: IHookParams = {}) {
  const { openGenerateDocumentDialog } = useGenerateApplicationDocumentDialog({
    generateDocumentMutation: useGenerateForeignPermissionDocumentMutation,
    listQueryKey: DocumentsQueryKeysEnum.DOCUMENTS_LIST
  });

  const generateConfirmationOfReturn = async (params: ForeignPermissionDocumentCreateRequest) => {
    openGenerateDocumentDialog({
      params,
      isDocumentDownloadingAfterGenerate: true,
      groupKey: DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.CONFIRMATION_OF_RETURN,
      isGroupFieldViewOnly: true,
      downloadFilePath: null,
      onSuccess,
      onCancel,
      downloadFileAfterSettleForeignPermission,
      isForeignPermissionsUse
    });
  };

  return { generateConfirmationOfReturn };
}
export default useGenerateForeignPermissionDocument;
