import { useEffect, useState } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  OrganizationStructureNodeSnapshotExternal,
  OrganizationUnitLiteExternal
} from '@avispon/organization-structure/dist/models';
import { makeStyles } from '@mui/styles';

import { EmptyCardContent, LoaderCircular, Page, PageHeader, Section } from '@libs/common/v2';
import { important } from '@libs/common/v2/utils';

import { useOrganizationStructureQuery } from '@libs/organization-structure/api';
import { OrganizationStructureHierarchyLevelEnum, OrganizationUnitView } from '@libs/organization-structure/models';

import { convertChildrenKeys } from '../../utils';
import OrganizationUnitPageHeaderActions from '../header/OrganizationUnitPageHeaderActions';

import OrganizationUnitTree from './OrganizationUnitTreeV2';

interface OrganizationUnitTreeWrapperProps {
  draggable: boolean;
  myUnits?: OrganizationUnitLiteExternal[];
  select?: boolean;
  userUnitSelect?: boolean;
  showLoading?: boolean;
  isTreeView?: boolean;
  setValue?: UseFormSetValue<FieldValues>;
  selectedKey?: string[];
  hierarchyLevelKeyValue?: OrganizationStructureHierarchyLevelEnum;
  setSelectedKey?: React.Dispatch<React.SetStateAction<string[]>>;
  onViewChange?: (viewType: OrganizationUnitView) => void;
  isDisabled?: boolean;
  isPageView?: boolean;
}

function OrganizationUnitTreeWrapperV2(props: OrganizationUnitTreeWrapperProps) {
  const {
    draggable,
    select,
    userUnitSelect,
    showLoading,
    myUnits,
    isTreeView,
    onViewChange,
    setValue,
    selectedKey,
    hierarchyLevelKeyValue,
    setSelectedKey,
    isDisabled,
    isPageView
  } = props;
  const [t] = useTranslation();
  let transformedUnits: OrganizationStructureNodeSnapshotExternal[];
  const [treeDataState, setTreeDataState] = useState<OrganizationStructureNodeSnapshotExternal[]>(null);
  const { data: treeData } = useOrganizationStructureQuery();
  const classes = useStyles();
  useEffect(() => {
    setTreeDataState(treeData);
  }, [treeData]);

  if (treeDataState) {
    const data = [...treeDataState];
    transformedUnits = convertChildrenKeys(data, 'childrenUnits', 'children');
  }

  if (showLoading ?? !transformedUnits) {
    return treeDataState ? <LoaderCircular isLoading size={28} /> : <EmptyCardContent isSection />;
  }

  if (transformedUnits) {
    return onViewChange ? (
      <Page
        {...(isPageView && { contentClassName: classes.contentPageWrapper })}
        header={
          <PageHeader
            title={t('administration:organizationUnit.organizationUnitListTitle')}
            rightSideContent={<OrganizationUnitPageHeaderActions isTreeView={isTreeView} onViewChange={onViewChange} />}
          />
        }
        content={
          <Section>
            <OrganizationUnitTree
              unitsData={transformedUnits}
              draggable={draggable}
              select={select}
              userUnitSelect={userUnitSelect}
              myUnits={myUnits}
              setValue={setValue}
              selectedKey={selectedKey}
              setSelectedKey={setSelectedKey}
              hierarchyLevelKeySelectValue={hierarchyLevelKeyValue}
              isDisabled={isDisabled}
              isPageView={isPageView}
            />
          </Section>
        }
      />
    ) : (
      <OrganizationUnitTree
        unitsData={transformedUnits}
        draggable={draggable}
        select={select}
        userUnitSelect={userUnitSelect}
        myUnits={myUnits}
        setValue={setValue}
        selectedKey={selectedKey}
        setSelectedKey={setSelectedKey}
        hierarchyLevelKeySelectValue={hierarchyLevelKeyValue}
        isDisabled={isDisabled}
        isPageView={isPageView}
      />
    );
  }

  return null;
}

const useStyles = makeStyles(() => ({
  contentPageWrapper: {
    maxHeight: 'calc(100% - 32px)',
    paddingBottom: important(0)
  }
}));

export default OrganizationUnitTreeWrapperV2;
