import React from 'react';
import clsx from 'clsx';

import { LoaderCircular } from '@libs/common/v2';

interface IProps {
  loaderWrapperClassName?: string;
  customFallback?: React.ReactNode;
  children: React.ReactNode;
}

/**
 * Komponent Suspense z React z fallback ustawionym domyślnie na nasz loader linear
 */
function Suspense({ loaderWrapperClassName, customFallback, children }: IProps) {
  return (
    <React.Suspense
      fallback={
        customFallback || (
          <div
            className={clsx('h-full w-1/2 mx-auto flex-col flex justify-center items-center', loaderWrapperClassName)}
          >
            <LoaderCircular isLoading />
          </div>
        )
      }
    >
      {children}
    </React.Suspense>
  );
}

export default Suspense;
