import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { ReputationRequestDetails } from '@ibtm/domain';

import { TableLayout } from '@libs/common/v2';

import { useTransportManagerReputationsTable } from '../../hooks';
import { TransportManagerDetailsClient } from '../../model/transport-manager.models';

interface IProps {
  reputations: Array<TransportManagerDetailsClient['reputationInquires'][0]>;
  loading?: boolean;
  rowActions?: (row: Row<ReputationRequestDetails>) => ReactNode;
  title?: string;
  isTitle?: boolean;
}

function ReputationTable({ loading, reputations, rowActions, title, isTitle = true }: IProps) {
  const [t] = useTranslation();
  const tableProps = useTransportManagerReputationsTable(reputations);
  return (
    <TableLayout
      {...tableProps}
      pageTitle={isTitle && (title || t('transportManager:details.section.reputation'))}
      isSection
      isLoading={loading}
      isRefreshEnabled={false}
      isFilterEnabled={false}
      actionsColumnWidth={65}
      rowActions={rowActions}
    />
  );
}
export default ReputationTable;
