import { AssociationSubjectSearchFilter, AssociationSubjectSnapshot, SubjectSnapshot } from '@ibtm/domain';

import { SelectOption, TableFilter, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { getCalendarDate } from '@libs/common/v2/utils';

import { UserFilterV2 } from '@libs/user';

const useAssociationSubjectColumns = () => {
  const { createColumns } = useCreateColumns<AssociationSubjectSnapshot & SubjectSnapshot>({
    pathToTranslate: 'associations:field'
  });

  const mappedSortFields: TableSortMapper<AssociationSubjectSnapshot & SubjectSnapshot> = {
    subjectName: 'folder.subject.name',
    created: 'joinDate',
    folders: 'folder.number'
  };

  const mappedFilterFields: TableFilterMapper<
    AssociationSubjectSnapshot & SubjectSnapshot,
    AssociationSubjectSearchFilter
  > = {
    subjectName: (name: string) => ({ subjectNameContains: name }),
    joinDate: ({ dateFrom, dateTo }: { dateFrom: string; dateTo: string }) => {
      return {
        joinDateGreaterThanOrEqual: getCalendarDate(dateFrom),
        joinDateLessThanOrEqual: getCalendarDate(dateTo)
      };
    },
    folders: (folderNumber: string) => ({ folderNumberContains: folderNumber }),
    authorName: (values: SelectOption<string>[]) => ({ authorIdIn: values.map(({ value }) => value) })
  };

  const columns = createColumns([
    {
      accessor: 'subject.name',
      type: 'TEXT',
      header: 'name'
    },
    {
      accessor: 'joinDate',
      type: 'DATE',
      header: 'created'
    },
    {
      accessor: 'author.name',
      type: 'CUSTOM_COLUMN',
      header: 'creator',
      filter: UserFilterV2
    },
    {
      id: 'folders',
      type: 'CUSTOM_COLUMN',
      header: 'associations:field.folderNumber',
      customAccessor: row => row.folders?.map(folder => folder.number).join(', '),
      filter: TableFilter.Text
    }
  ]);

  return { columns, mappedFilterFields, mappedSortFields };
};

export default useAssociationSubjectColumns;
