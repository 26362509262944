import { useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';

import { TabHeader, Theme } from '@libs/common/v2';

import { FolderDetailsTabEnum, IFolderTab, useDiscardFolderChanges, useSaveFolder } from '@libs/domain/folder';

interface IProps {
  activeTab: FolderDetailsTabEnum;
  visibleTabs: Array<IFolderTab>;
  setSubmitting: (value: boolean) => void;
  isHeaderHidden: boolean;
  classes?: {
    header?: string;
  };
  folderType: string;
}

function FolderTabsHeader({
  setSubmitting,
  activeTab,
  visibleTabs,
  isHeaderHidden,
  classes: classesProps,
  folderType
}: IProps) {
  const { isSubmitting, saveTab } = useSaveFolder(folderType);
  const { discardChanges } = useDiscardFolderChanges();
  const classes = useStyles();

  useEffect(() => {
    setSubmitting(isSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  const activeTabTitle = useMemo(() => {
    return visibleTabs.find(({ value }) => value === activeTab)?.label;
  }, [activeTab, visibleTabs]);

  const handleSaveData = useCallback(
    (activeTab: FolderDetailsTabEnum) => {
      saveTab(activeTab);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTab]
  );

  const areHeaderButtonsVisible = useCallback(
    (tab: FolderDetailsTabEnum) => {
      return [
        FolderDetailsTabEnum.BASIC_DATA,
        FolderDetailsTabEnum.SUBJECT,
        FolderDetailsTabEnum.ADDRESSES,
        FolderDetailsTabEnum.ORDERS,
        FolderDetailsTabEnum.EKMT,
        FolderDetailsTabEnum.SINGLE_AUTHORIZATION
      ].includes(tab);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTab]
  );

  return (
    <TabHeader
      handleSubmit={() => handleSaveData(activeTab)}
      handleDiscardChanges={() => discardChanges(activeTab)}
      className={classes.header}
      displayButtons={areHeaderButtonsVisible(activeTab)}
      isHeaderHidden={isHeaderHidden}
      activeTabTitle={activeTabTitle}
      classes={classesProps}
    />
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  header: {
    [theme.breakpoints.down('md')]: {
      margin: '0 16px 16px'
    },
    margin: '0px 32px 32px'
  }
}));

export default FolderTabsHeader;
