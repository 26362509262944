import { makeStyles } from '@mui/styles';

import { partialTranslate } from '@libs/common';
import { Tab, TabContent, Tabs, TabsContent, useTab } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';

import { DepotTypes } from '../model';

import { AssociationOrdersTable, ResourceObjectPoolsTable } from './list';

export enum ResourcesDetailsTabEnum {
  RESOURCES = 'RESOURCES',
  ASSOCIATION = 'ASSOCIATION'
}

interface IProps {
  code: DepotTypes;
}

function ResourceDetailsTabs({ code }: IProps) {
  const classes = useStyles();
  const getLabel = partialTranslate('resource:tab');

  const [activeTab, setActiveTab] = useTab<ResourcesDetailsTabEnum>(ResourcesDetailsTabEnum.RESOURCES);

  return code === DepotTypes.MG ? (
    <>
      <Tabs
        value={activeTab}
        onChange={setActiveTab}
        variant="scrollable"
        orientation="horizontal"
        className={classes.tabsWrapper}
      >
        <Tab
          label={<div style={{ textAlign: 'center', margin: 'auto' }}>{getLabel('details')}</div>}
          value={ResourcesDetailsTabEnum.RESOURCES}
          isHorizontalTab
        />

        {code === DepotTypes.MG && (
          <Tab
            label={<div style={{ textAlign: 'center', margin: 'auto' }}>{getLabel('orders')}</div>}
            value={ResourcesDetailsTabEnum.ASSOCIATION}
            isHorizontalTab
          />
        )}
      </Tabs>
      <TabsContent value={activeTab} className={classes.tabs}>
        <TabContent value={ResourcesDetailsTabEnum.RESOURCES} isInnerScroll={false}>
          {code && <ResourceObjectPoolsTable code={code} />}
        </TabContent>

        <TabContent value={ResourcesDetailsTabEnum.ASSOCIATION} isInnerScroll={false}>
          <AssociationOrdersTable />
        </TabContent>
      </TabsContent>
    </>
  ) : (
    code && <ResourceObjectPoolsTable code={code} />
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  tabsWrapper: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`
  }
}));

export default ResourceDetailsTabs;
