import React, { useMemo, useState } from 'react';
import { Row } from 'react-table';
import { TableBody as MuiTableBody } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ITableAdapter, ITableContext } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';

import { useContextSelector } from '@libs/dictionary';

import { useEmptyState, useTableAdapter, useTableContext } from '../hooks';

import TableRow from './TableRow';

export interface ITableBodyProps<T extends Record<string, any>, K extends Record<string, any> = T> {
  rowActions?: (
    row: Row<T>,
    table: { tableInstance: ITableAdapter<T, K>; tableContext: ITableContext<T, K> }
  ) => React.ReactNode;
  /**
   * Dodatkowe style wiersza tabeli.
   */
  rowStyle?: React.CSSProperties;
  /**
   * Dodatkowe style komórki tabeli.
   */
  cellStyle?: React.CSSProperties;
  /**
   * Funkcja wywoływana przy kliknięciu w wiersz.
   * Wewnątrz mamy dostęp do klikniętego wiersza, instancji i context tabeli
   */
  onRowClick?: (params: { row: Row<T>; tableInstance: ITableAdapter<T, K>; tableContext: ITableContext<T, K> }) => void;
  emptyTableText?: string;
  /**
   * Wartość decyduje o tle wiersza
   */
  isTransparent?: boolean;
  /**
   * Dodatkowy dolny margines
   */
  extraMargin?: boolean;
  /**
   * Lista id rekordów, które powinny być wygaszone
   */
  disabledRows?: string[];
  /**
   * Treść komunikatu w tooltip wygaszonego wiersza
   */
  disabledRowTooltipText?: string;
  /**
   * Funkcja pozwalająca na przewinięcie tabeli do wskazanej kolumny
   * @param columnName Nazwa kolumny
   */
  scrollToColumn?: (columnName: string) => void;
}

function TableBody<T extends Record<string, any> = any>({
  rowActions,
  onRowClick,
  emptyTableText,
  rowStyle,
  cellStyle,
  isTransparent,
  extraMargin,
  disabledRows,
  disabledRowTooltipText,
  scrollToColumn
}: ITableBodyProps<T>) {
  const tableContext = useTableContext();
  const table = useTableAdapter();
  const isDictionaryFetching = useContextSelector(({ context }) => context.context.isFetching);
  const { state } = table;
  const { renderEmptyState, isDataEmpty, isCurrentSearchDataEmpty } = useEmptyState(emptyTableText);
  const classes = useStyles({ extraMargin });
  const [lastClickedRow, setLastClickedRow] = useState<string | null>(null);

  const pageToRender = useMemo(() => (state?.page?.length ? state.page : table.page), [state.page, table.page]);

  const tableDataIdentifiers = JSON.stringify(table.data);
  const columnsWidth = table.columns.map(column => column.width).toString();
  const selectedIds = Object.keys(table.state.selectedRowIds || {}).toString();

  const manualTableRenderDependencies = JSON.stringify([
    !table.manualFilters && table.state.filters,
    !table.manualPagination && {
      pageSize: table.state.pageSize,
      pageIndex: table.state.pageIndex,
      pageCount: table.pageCount
    },
    !table.manualSortBy && table.state.sortBy
  ]);

  const TableBodyMemoized = useMemo(() => {
    return pageToRender.map((row: any) => {
      table?.prepareRow?.(row);
      const isDisabled = disabledRows?.includes(row?.id);
      const isRowLastCLicked = row.id === lastClickedRow;

      return (
        <TableRow
          key={row.id}
          rowActions={rowActions}
          rowStyle={rowStyle}
          cellStyle={cellStyle}
          row={row}
          tableInstance={table}
          tableContext={tableContext}
          onRowClick={onRowClick}
          isTransparent={isTransparent}
          isDisabled={isDisabled}
          disabledRowTooltipText={disabledRowTooltipText}
          scrollToColumn={scrollToColumn}
          setLastClickedRow={setLastClickedRow}
          isRowLastCLicked={isRowLastCLicked}
        />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableDataIdentifiers,
    columnsWidth,
    selectedIds,
    state.isCreating,
    state.isEditing,
    manualTableRenderDependencies,
    isDictionaryFetching,
    rowActions
  ]);

  return isDataEmpty ? (
    renderEmptyState()
  ) : (
    <MuiTableBody className={classes.root} component="div">
      {isCurrentSearchDataEmpty ? renderEmptyState() : TableBodyMemoized}
    </MuiTableBody>
  );
}

const useStyles = makeStyles<Theme, Pick<ITableBodyProps<any>, 'extraMargin'>>({
  root: {
    minWidth: '100%',
    display: 'inline-flex',
    flexDirection: 'column',
    marginBottom: ({ extraMargin }) => extraMargin && '20px'
  }
});

export default React.memo(TableBody);
