import { Skeleton } from '@mui/material';

function ValueSkeleton() {
  return (
    <>
      <Skeleton variant="rectangular" width={120} height={16} className="mb-8" />
      <Skeleton variant="rectangular" height={24} />
    </>
  );
}

export default ValueSkeleton;
