import { QueryConfig, useQuery } from 'react-query';
import { ApplicationDetails as ApplicationDetailsClient } from '@ibtm/client-domain';
import { ApplicationDetails } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import ApplicationQueryKeysEnum from './ApplicationQueryKeysEnum';

export const getApplication = async (applicationId?: string) => {
  const { data } = await API.application.getApplicationDetails(applicationId, {
    ...endpointsConfig.getApplicationDetails
  });

  return data;
};

export const getApplicationClient = async (applicationId?: string) => {
  const { data } = await API.client.application.getApplicationDetails(applicationId, '', '', {
    ...endpointsConfig.getApplicationDetails
  });

  return data;
};

export default function useApplicationDetailsQuery(
  applicationId: string,
  queryConfig: QueryConfig<ApplicationDetails | ApplicationDetailsClient, unknown> = {},
  customQueryKey?: string
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery<ApplicationDetails | ApplicationDetailsClient>(
    [customQueryKey ?? ApplicationQueryKeysEnum.APPLICATION, applicationId],
    getQuery(
      () => getApplicationClient(applicationId),
      () => getApplication(applicationId)
    ),
    queryConfig
  );
}
