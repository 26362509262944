import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { FolderDetails } from '@ibtm/domain';
import { isEmpty } from 'lodash';
import { object as YupObject } from 'yup';

import { partialTranslate } from '@libs/common';
import {
  Dialog,
  DictionarySelectField,
  FormV2Context,
  GridItem,
  GridLayout,
  InputMode,
  NumberInputField,
  Section,
  SwitchField,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import { useFolderDetailsQuery } from '../../folder';
import { useCreatePartnerApplicantMutation, usePartnerApplicantsQuery } from '../api';
import { PartnerApplicantFields, validationSchema } from '../model';
import generateDataAddressRequest from '../utils/generateDataAddressRequest';

import AddressFieldsFormSection from './AddressFieldsFormSection';
import PartnerSelectTable from './PartnerSelectTable';

interface IProps {
  applicationId: string;
  onCloseDialog: () => void;
  isOpen: boolean;
  refetch?: () => void;
}

const getLabel = partialTranslate('partnerApplicants:field');

function PartnerApplicantsCreateDialog({ applicationId, onCloseDialog, isOpen, refetch }: IProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: createPartnerApplicant, isLoading } = useCreatePartnerApplicantMutation();
  const [selectedFolder, setSelectedFolder] = useState<FolderDetails | null>(null);
  const [isMainAddress, setIsMainAddress] = useState(true);

  const getMainAddress = (data: FolderDetails) => {
    return data?.addresses?.filter(item => item.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.MAIN)[0];
  };

  const { data: folderDetailsData } = useFolderDetailsQuery(selectedFolder?.id, {
    enabled: Boolean(selectedFolder?.id),
    onSuccess: (data: FolderDetails) => {
      const address = getMainAddress(data);

      if (sameAsMainAddress && address) {
        setValue(typedNameV2<PartnerApplicantFields>('address'), address);
      }
    }
  });

  const address = getMainAddress(folderDetailsData);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(
      isMainAddress
        ? YupObject({
            roleKey: YupObject().required().nullable()
          })
        : validationSchema()
    )
  });

  const { data, isLoading: isPartnerApplicantsLoading } = usePartnerApplicantsQuery(
    { applicationId },
    {
      enabled: !!applicationId,
      onSuccess: partners => {
        setValue(typedNameV2<PartnerApplicantFields>('lp'), partners.totalElements + 1);
        if (partners.totalElements === 0) {
          setValue('roleKey', {
            value: DomainDictionaryEntry.PARTNER_APPLICANT.APPLICANT
          });
        }
      }
    }
  );
  const hasPartnerApplicants = data?.totalElements > 0;

  const formValues = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting
    }),
    [control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting]
  );

  const sameAsMainAddress = watch(typedNameV2<PartnerApplicantFields>('sameAsMainAddress')) as boolean;

  const handleSameAsMainAddressChange = (_event: React.ChangeEvent<HTMLInputElement>, isSameAsMainAddress: boolean) => {
    if (isSameAsMainAddress && address) {
      setValue(typedNameV2<PartnerApplicantFields>('address'), address);
    } else {
      setValue(typedNameV2<PartnerApplicantFields>('address'), {
        name: null,
        city: null,
        postCode: null,
        postCity: null,
        street: null,
        propertyNumber: null,
        apartmentNumber: null,
        voivodeshipKey: null,
        countryCodeKey: null,
        county: null,
        commune: null,
        firstLine: null,
        secondLine: null
      });
    }
    setIsMainAddress(isSameAsMainAddress);
  };

  const handleCreateSubmit = (data: Partial<PartnerApplicantFields>) => {
    createPartnerApplicant(
      {
        applicationId,
        subjectId: folderDetailsData?.subject?.id,
        lp: data?.lp,
        roleKey: data?.roleKey?.value,
        address: {
          sameAsMainAddress: data?.sameAsMainAddress,
          address: data?.sameAsMainAddress ? null : generateDataAddressRequest(data)
        }
      },
      {
        onSuccess: () => {
          onCloseDialog();
          showSuccessSnackbar(t('partnerApplicants:action.create.successMessage'));
          refetch();
        }
      }
    );
  };

  return isEmpty(selectedFolder) ? (
    <Dialog
      title={t('partnerApplicants:action.create.dialogTitle')}
      cancelText={t('action.cancel')}
      onCancel={onCloseDialog}
      dialogSize="medium"
      isOpen={isOpen}
    >
      <PartnerSelectTable setSelectedSubject={setSelectedFolder} />
    </Dialog>
  ) : (
    <FormV2Context.Provider value={formValues}>
      <form onSubmit={handleSubmit(handleCreateSubmit)}>
        <Dialog
          title={t('partnerApplicants:action.create.dialogTitle')}
          confirmText={t('action.save')}
          cancelText={t('action.cancel')}
          onConfirm={handleSubmit(handleCreateSubmit)}
          onCancel={onCloseDialog}
          dialogSize="medium"
          isConfirmLoading={isLoading}
          isOpen={isOpen}
        >
          <Section isModalSection isHeaderVisible={false}>
            <GridLayout itemProps={{ xs: 12 }}>
              <GridItem xs={4}>
                <NumberInputField
                  name={typedNameV2<PartnerApplicantFields>('lp')}
                  label={getLabel('lp')}
                  inputMode={InputMode.VIEW}
                />
              </GridItem>
              <GridItem xs={4}>
                <DictionarySelectField
                  name={typedNameV2<PartnerApplicantFields>('roleKey')}
                  dictionaryName={DomainDictionaryEnum.PARTNER_APPLICANT}
                  label={getLabel('roleKey')}
                  {...(!hasPartnerApplicants
                    ? {
                        optionsFilter: option =>
                          option.active && option.value === DomainDictionaryEntry.PARTNER_APPLICANT.APPLICANT
                      }
                    : {
                        optionsFilter: option =>
                          option.active && option.value !== DomainDictionaryEntry.PARTNER_APPLICANT.APPLICANT
                      })}
                  isDisabled={isPartnerApplicantsLoading}
                  inputMode={!hasPartnerApplicants && InputMode.VIEW}
                  isRequired
                />
              </GridItem>
              <GridItem xs={4}>
                <SwitchField
                  name={typedNameV2<PartnerApplicantFields>('sameAsMainAddress')}
                  label={getLabel('sameAsMainAddress')}
                  isDefaultChecked
                  onCustomChange={handleSameAsMainAddressChange}
                />
              </GridItem>
              <TextInputField
                name={typedNameV2<PartnerApplicantFields>('address.name')}
                label={getLabel('name')}
                isDisabled={sameAsMainAddress}
                tooltip={sameAsMainAddress && getLabel('tooltipSameAsMainAddress')}
              />
              <DictionarySelectField
                name={typedNameV2<PartnerApplicantFields>('address.countryCodeKey')}
                dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
                label={getLabel('countryCodeKey')}
                isDisabled={sameAsMainAddress}
                isRequired={!sameAsMainAddress}
                tooltip={sameAsMainAddress && getLabel('tooltipSameAsMainAddress')}
              />
              <AddressFieldsFormSection />
            </GridLayout>
          </Section>
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
}

export default PartnerApplicantsCreateDialog;
