import { QueryConfig, useQuery } from 'react-query';
import { DictionaryEntryDetailsLanguage } from '@avispon/dictionary';

import { NUMBER_OF_AUTOCOMPLETE_RESULTS, SelectOption } from '@libs/common/v2';

import { DictionaryQuickChangeableEntryNameEnum } from '@libs/dictionary';
import { API } from '@libs/dictionary/api';

enum QuickChangeDictionaryEnum {
  QUICK_CHANGE_DICTIONARY = 'QUICK_CHANGE_DICTIONARY'
}

export async function getQuickChangeDictionary(params) {
  const { data } = await API.dictionary.getTranslationDetailsPage(params ?? {});
  return data?.content.map(item => ({
    value: item.key,
    name: item.value,
    id: item.id
  }));
}

export default function useQuickChangeDictionary(
  dictionary: DictionaryQuickChangeableEntryNameEnum,
  parentKey?: string,
  searchText?: string,
  queryConfig: QueryConfig<SelectOption[], unknown> = {}
) {
  const params = {
    page: 0,
    size: NUMBER_OF_AUTOCOMPLETE_RESULTS,
    language: DictionaryEntryDetailsLanguage.pl,
    valueFragment: searchText,
    dictionary,
    sort: [],
    ...(parentKey ? { parentKeys: [parentKey] } : {})
  };
  return useQuery<SelectOption[]>(
    [QuickChangeDictionaryEnum.QUICK_CHANGE_DICTIONARY, params],
    () => getQuickChangeDictionary(params),
    queryConfig
  );
}
