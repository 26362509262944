import { useMemo } from 'react';
import { ApplicationSearchFilter } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import { AdditionalFilterEnum, IAdditionalFilter } from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';

interface IAdditionalApplicationFilter {
  type: AdditionalFilterEnum;
  name: keyof ApplicationSearchFilter;
  inputProps?: Record<string, unknown>;
}

function useApplicationAdditionalFilters() {
  const getLabel = partialTranslate('applications:additionalField');
  const additionalFilters: Array<IAdditionalFilter> = useMemo((): IAdditionalApplicationFilter[] => {
    return [
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'licenceNumberContains',
        inputProps: {
          label: getLabel('licenceNumber')
        }
      },
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'archiveApplicationNumberContains',
        inputProps: {
          label: getLabel('archiveNumber')
        }
      },
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'rpwNumberContains',
        inputProps: {
          label: getLabel('rpwNumber')
        }
      },
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'driverFullNameContains',
        inputProps: {
          label: getLabel('driverName')
        }
      },
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'driverCertificateNumberContains',
        inputProps: {
          label: getLabel('driverNumber')
        }
      },
      {
        type: AdditionalFilterEnum.DICTIONARY_SELECT,
        name: 'decisionKeyIn',
        inputProps: {
          label: getLabel('conclusion'),
          dictionaryName: DomainDictionaryEnum.DECISION_SETTLEMENT,
          isMultiple: true
        }
      },
      {
        type: AdditionalFilterEnum.DICTIONARY_SELECT,
        name: 'decisionTypeKeyIn',
        inputProps: {
          label: getLabel('conclusionType'),
          dictionaryName: DomainDictionaryEnum.DECISION_TYPE,
          isMultiple: true
        }
      },
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'permissionForReqularLineNumberContains',
        inputProps: {
          label: getLabel('permitNumberForRegularLines')
        }
      },
      {
        type: AdditionalFilterEnum.TEXT_FILTER,
        name: 'certificateForOwnUseNumberContains',
        inputProps: {
          label: getLabel('permitNumberForOwn')
        }
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { additionalFilters };
}

export default useApplicationAdditionalFilters;
