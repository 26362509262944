import { QueryConfig, useQuery } from 'react-query';
import { TravelFormPrintsSnapshot } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import PrintsQueryKeysEnum from './PrintsQueryKeysEnum';

const getTravelFormPrintsSnapshots = async (applicationId: string) => {
  const { data } = await API.travelForm.getTravelFormPrintsSnapshots(
    applicationId,
    {},
    {
      ...endpointsConfig.getTravelFormPrintsSnapshots
    }
  );
  return data;
};

const useGetTravelFormPrintsSnapshotsQuery = (
  applicationId: string,
  queryConfig?: QueryConfig<Array<TravelFormPrintsSnapshot>>
) =>
  useQuery(
    [PrintsQueryKeysEnum.PRINTS_TRAVEL_FORM_LIST, applicationId],
    () => getTravelFormPrintsSnapshots(applicationId),
    queryConfig
  );

export default useGetTravelFormPrintsSnapshotsQuery;
