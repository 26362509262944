import { FormActionEnum, useDialog, useFormActionModal } from '@libs/common/v2';

import { useMetaFormContext } from '@libs/meta-form/context';

import VehicleSearchDialog from '../dialog/VehicleSearchDialog';

interface IProps {
  folderId?: string;
  transferredFoldersIds?: string[];
  certificateStatusList?: string[];
  applicationId: string;
  applicationType: string;
  applicationCategory: string;
  hasIpwzWarningInformation?: boolean;
  statusKeys?: Array<string>;
  vehicleModificationConcernsKey?: string;
  isDirty?: boolean;
  onSuccess?: () => void;
  isAddSelectedActionButtonHidden?: boolean;
}

export function useVehicleSearchAction({
  folderId,
  transferredFoldersIds,
  certificateStatusList,
  applicationId,
  applicationCategory,
  applicationType,
  hasIpwzWarningInformation,
  statusKeys,
  vehicleModificationConcernsKey,
  isDirty,
  onSuccess,
  isAddSelectedActionButtonHidden
}: IProps) {
  const { openDialog } = useDialog();
  const saveChangesAction = useFormActionModal();
  const { formRef } = useMetaFormContext();

  const openVehicleSearchDialog = () => {
    if (isDirty) {
      return saveChangesAction({
        formAction: FormActionEnum.SAVE_CHANGES,
        onConfirm: async () => {
          await formRef?.current?.onSubmit();
          handleOpenDialog();
        },
        onCancel: async () => {
          await formRef?.current?.onReset();
          handleOpenDialog();
        }
      });
    }
    return handleOpenDialog();
  };

  const handleOpenDialog = () => {
    openDialog(({ closeDialog }) => (
      <VehicleSearchDialog
        folderId={folderId}
        transferredFoldersIds={transferredFoldersIds}
        certificateStatusList={certificateStatusList}
        applicationId={applicationId}
        applicationCategory={applicationCategory}
        applicationType={applicationType}
        hasIpwzWarningInformation={hasIpwzWarningInformation}
        closeDialog={closeDialog}
        statusKeys={statusKeys}
        vehicleModificationConcernsKey={vehicleModificationConcernsKey}
        onSuccess={onSuccess}
        isAddSelectedActionButtonHidden={isAddSelectedActionButtonHidden}
      />
    ));
  };

  return { openVehicleSearchDialog };
}
