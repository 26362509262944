import { useTranslation } from 'react-i18next';

import {
  FormMode,
  GridItem,
  GridLayout,
  InputMode,
  Section,
  TextInputField,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';

import { AssociationModel } from '../../model';

function AssociationDataSection() {
  const [t] = useTranslation();
  const { formMode } = useFormV2Context();

  return (
    <Section title={t('associations:field.associationData')} isCollapsable>
      <GridLayout itemProps={{ md: 6, xs: 12 }}>
        {[FormMode.EDIT, FormMode.VIEW].includes(formMode) && (
          <TextInputField
            name={typedNameV2<AssociationModel>('number')}
            label={t('associations:field.number')}
            isRequired
            inputMode={InputMode.VIEW}
          />
        )}

        <TextInputField
          name={typedNameV2<AssociationModel>('subject.name')}
          label={t('folder:details.field.name')}
          isRequired
        />

        <TextInputField
          name={typedNameV2<AssociationModel>('subject.shortName')}
          label={t('associations:field.shortName')}
          isRequired
        />

        <GridItem xs={6}>
          <TextInputField
            inputProps={{ maxLength: 10 }}
            name={typedNameV2<AssociationModel>('subject.nip')}
            label={t('folder:field.subjectNip')}
            isOnlyPositiveIntegers
          />
        </GridItem>

        <GridItem xs={6}>
          <TextInputField
            inputProps={{ maxLength: 10 }}
            name={typedNameV2<AssociationModel>('subject.krs')}
            label={t('folder:details.field.krs')}
            isOnlyPositiveIntegers
          />
        </GridItem>

        <GridItem xs={6}>
          <TextInputField
            inputProps={{ maxLength: 9 }}
            name={typedNameV2<AssociationModel>('subject.regon')}
            label={t('folder:details.field.regon')}
            isOnlyPositiveIntegers
          />
        </GridItem>

        <GridItem xs={12}>
          <TextInputField
            name={typedNameV2<AssociationModel>('subject.notes')}
            label={t('folder:sidebar.section.notes')}
            lines={5}
          />
        </GridItem>
      </GridLayout>
    </Section>
  );
}

export default AssociationDataSection;
