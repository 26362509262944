import { ReactNode } from 'react';

import { GridItem, Section as SectionCommon } from '@libs/common/v2';

import { IGridProps, NodeVisibility } from '@libs/meta-form/models';
import { useCheckElementVisibility } from '@libs/meta-form/services';

export type SectionParameters = {
  title?: string;
  isCollapsable?: boolean;
  className?: string;
  gridItemProps?: IGridProps;
  visibility?: NodeVisibility;
};

function Section({
  title,
  children,
  className,
  isCollapsable = true,
  gridItemProps,
  renderNodes,
  components,
  visibility
}: SectionParameters & {
  renderNodes: (children: ReactNode, components: any) => JSX.Element | JSX.Element[];
  components: any;
  children: ReactNode;
}) {
  const { isVisible } = useCheckElementVisibility(visibility);
  return gridItemProps
    ? isVisible && (
        <GridItem {...gridItemProps}>
          <SectionCommon title={title} isCollapsable={isCollapsable} className={className}>
            {renderNodes(children, components)}
          </SectionCommon>
        </GridItem>
      )
    : isVisible && (
        <SectionCommon title={title} isCollapsable={isCollapsable} className={className}>
          {renderNodes(children, components)}
        </SectionCommon>
      );
}

export default Section;
