import { useRouter } from '@libs/common/v2';

export const CorrectionLink = ({
  id,
  folderId,
  correctionId,
  correctionNumber
}: {
  id: string;
  folderId?: string;
  correctionId: string;
  correctionNumber: string;
}) => {
  const { routes, goToPage } = useRouter();
  const isOnSuspension = !!folderId;

  return (
    <a
      href={
        isOnSuspension
          ? routes.releaseDocumentOnFolderSuspensionView(folderId, id, correctionId)
          : routes.releaseDocumentView(correctionId)
      }
      onClick={e => {
        e.preventDefault();
        goToPage(
          isOnSuspension
            ? routes.releaseDocumentOnFolderSuspensionView(folderId, id, correctionId)
            : routes.releaseDocumentView(correctionId)
        );
      }}
    >
      {correctionNumber}
    </a>
  );
};
