import { useTranslation } from 'react-i18next';

import { partialTranslate } from '@libs/common';
import { DictionarySelectField, GridLayout, InputMode, Section, TextInputField, typedNameV2 } from '@libs/common/v2';
import { convertDateToDateTimeFormat, getDateValue, getValue } from '@libs/common/v2/utils';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { TransportManagerDetailsClient } from '@libs/domain/transport-manager';

function GeneralInformation() {
  const [t] = useTranslation();
  const getLabel = partialTranslate('transportManager:details');

  return (
    <Section title={t('section.generalInformation')} isCollapsable isModalSection>
      <GridLayout itemProps={{ xs: 6 }}>
        <TextInputField
          name={typedNameV2<TransportManagerDetailsClient>('created')}
          label={getLabel('field.created')}
          inputMode={InputMode.VIEW}
          getValueFormat={convertDateToDateTimeFormat}
        />
        <TextInputField
          name={typedNameV2<TransportManagerDetailsClient>('author')}
          label={getLabel('field.author')}
          inputMode={InputMode.VIEW}
          getValueFormat={author => author?.name}
        />
        <TextInputField
          name={typedNameV2<TransportManagerDetailsClient>('modified')}
          label={getLabel('field.modified')}
          inputMode={InputMode.VIEW}
          getValueFormat={modified => getDateValue(modified, convertDateToDateTimeFormat)}
        />
        <TextInputField
          name={typedNameV2<TransportManagerDetailsClient>('modifier')}
          label={getLabel('field.modifier')}
          inputMode={InputMode.VIEW}
          getValueFormat={modifier => getValue(modifier?.name)}
        />
        <DictionarySelectField
          name={typedNameV2<TransportManagerDetailsClient>('statusKey')}
          label={getLabel('field.statusKey')}
          dictionaryName={DomainDictionaryEnum.TRANSPORT_MANAGER_STATUS}
          inputMode={InputMode.VIEW}
          stringValue
        />
      </GridLayout>
    </Section>
  );
}

export default GeneralInformation;
