import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DriverCertificateSnapshotPage } from '@ibtm/client-domain';
import { isEmpty } from 'lodash';

import { useConfirmDialog, WarningInformation } from '@libs/common/v2';
import { getCalendarDate, getDateOrNull, isDateLengthValid } from '@libs/common/v2/utils';

import { useDomainConfigContext } from '@libs/domain/config';
import { DriverCertificateStatusEnum } from '@libs/domain/driver-certificate/';
import { useDriverCertificatesQuery } from '@libs/domain/driver-certificate/api';

import { WarningStateProps } from './useDriverNotificationAndValidation';

const useWarningDriverHasCertificate = (folderId: string, driverId?: string, transferredFoldersIds?: string[]) => {
  const [t] = useTranslation();
  const [confirm] = useConfirmDialog();
  const { isOperatorPortal } = useDomainConfigContext();
  const [valuesForWarning, setValuesForWarning] = useState<WarningStateProps>({});
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const areValuesForWarningComplete = valuesForWarning.documentSeriesNumber;
  const dateOfBirthValue = useMemo(() => getDateOrNull(valuesForWarning?.dateOfBirth), [valuesForWarning?.dateOfBirth]);
  const isQueryEnabled = useMemo(
    () =>
      ((!!driverId && !!folderId) || areValuesForWarningComplete) && isDateLengthValid(valuesForWarning?.dateOfBirth),
    [areValuesForWarningComplete, driverId, folderId, valuesForWarning?.dateOfBirth]
  );

  const { isFetching } = useDriverCertificatesQuery(
    {
      folderIdIn: folderId ? [folderId, ...(transferredFoldersIds || [])] : undefined,
      ...(isEmpty(valuesForWarning) && { driverIdIn: driverId ? [driverId] : undefined }),
      statusKeyIn: [DriverCertificateStatusEnum.ACTIVE],
      ...(areValuesForWarningComplete && {
        driverBirthDateGreaterThanOrEqual: getCalendarDate(dateOfBirthValue),
        driverBirthDateLessThanOrEqual: getCalendarDate(dateOfBirthValue),
        ...(isOperatorPortal
          ? { driverLicenseSeriesNumberContains: valuesForWarning?.documentSeriesNumber }
          : { driverIdentityCardSeriesNumberContains: valuesForWarning?.documentSeriesNumber })
      })
    },
    {
      enabled: isQueryEnabled,
      onSuccess: (data: DriverCertificateSnapshotPage) => {
        setIsWarningVisible(data?.content?.length > 0);
      }
    }
  );

  const checkAndShowDialog = (onConfirm: (setConfirmLoading: any, closeDialog: any) => void) => {
    confirm({
      title: t('drivers:api.createDriver'),
      message: t('drivers:actions.driverHasCertificateDialog'),
      onConfirm
    });
  };

  return {
    notificationComponent: isWarningVisible && (
      <WarningInformation>{t('drivers:actions.driverHasCertificate')}</WarningInformation>
    ),
    setValuesForWarning: (param: keyof WarningStateProps, value: string) => {
      setValuesForWarning(prevState => {
        if (value?.length > 0 && valuesForWarning[param] !== value) {
          return { ...prevState, [param]: value };
        }
        return prevState;
      });
    },
    checkAndShowDialog,
    isWarningVisible,
    isLoading: isFetching
  };
};

export default useWarningDriverHasCertificate;
