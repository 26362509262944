import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@enigma/fe-ui';
import { HeaderActions } from '@enigma/fe-ui/lib/display/table';
import { PermissionCopySearchFilter, PermissionTypeCode } from '@ibtm/domain';

import { partialTranslate, TranslationFrom } from '@libs/common';

import { useElementVisibility } from '@libs/permission';

import { API } from '@libs/domain/api';

import { DomainDictionaryEntry, DomainUIElementEnum } from '../../config';
import { PrintActionType, usePrintsActionsContext, usePrintsDetailsDataContext } from '../../print/context';
import usePrintActionHandler from '../../print/hooks/usePrintActionHandler';
import { usePrintsRegenerateAction } from '../../print/hooks/usePrintsRegenerateAction';
import { endpointsConfig, PermissionsQueryKeysEnum } from '../api';
import { PERMISSION_COPIES_DEFAULT_COLUMNS, usePermissionCopiesColumnsV2, usePermissionCopyEditDialog } from '../hooks';
import { PermissionCopiesColumnsEnum, PermissionCopySnapshotClient, PermissionTypeEnum } from '../model';
import { parsePermissionCopiesList } from '../parsers';

export interface IPermissionCopiesTable {
  applicationId?: string;
  folderId?: string;
  typeKeys?: PermissionTypeEnum[];
  titleKey: TranslationFrom<'permission:title.permissionCopies'>;
  validFromColumnHeader?: string;
  visibleColumns?: Array<PermissionCopiesColumnsEnum>;
  headerActions?: HeaderActions<PermissionCopySnapshotClient>;
  permissionTypeCode: PermissionTypeCode;
  includePartnershipPermissions?: boolean;
  maxRowActionNumber: number;
  isPrintDisabled?: (rowData) => boolean;
  isMultipleFormNumberToRegenerateInRow?: boolean;
}

function PermissionCopiesTableV2({
  applicationId,
  folderId,
  typeKeys,
  titleKey,
  validFromColumnHeader,
  visibleColumns = PERMISSION_COPIES_DEFAULT_COLUMNS,
  headerActions,
  permissionTypeCode,
  includePartnershipPermissions,
  maxRowActionNumber,
  isPrintDisabled,
  isMultipleFormNumberToRegenerateInRow
}: IPermissionCopiesTable) {
  const [t] = useTranslation();
  const { checkIsElementVisible } = useElementVisibility();

  const { openEditPermissionCopyDialog } = usePermissionCopyEditDialog();
  const { columns, mappedFilterFields, mappedSortFields } = usePermissionCopiesColumnsV2({
    visibleColumns,
    validFromColumnHeader
  });

  const {
    printDetailsData: {
      depotMPD: { id: depotMPDId },
      notDepotMPDTooltip
    }
  } = usePrintsDetailsDataContext();

  const { handlePrintRegenerate } = usePrintsRegenerateAction();
  const { print } = usePrintActionHandler();
  const { setAction } = usePrintsActionsContext();

  const getTitle = partialTranslate('permission:title.permissionCopies');

  const title = useMemo(() => getTitle(titleKey), [titleKey, getTitle]);

  const tableHookQueryInitialParams: PermissionCopySearchFilter = {
    applicationIdIn: applicationId ? [applicationId] : undefined,
    folderIdIn: folderId ? [folderId] : undefined,
    typeKeyIn: typeKeys,
    includePartnershipPermissions: includePartnershipPermissions ? true : undefined
  };

  const isRegenerateDisabled = !depotMPDId;

  const editPermission = useCallback(
    ({ rowData, refetch }: { rowData: PermissionCopySnapshotClient; refetch?: () => void }) => {
      openEditPermissionCopyDialog(
        {
          id: rowData.id,
          formNumber: rowData.formNumber,
          validFrom: rowData.validFrom as unknown as string,
          validTo: rowData.validTo as unknown as string,
          printDate: rowData.printDate as unknown as string,
          version: rowData.version
        },
        refetch,
        titleKey
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Table<PermissionCopySnapshotClient, PermissionCopySearchFilter>
      tableTitle={title}
      columns={columns}
      query={{
        request: async params => {
          const { data } = await API.permissions.getPermissionCopiesPage(
            {
              ...params,
              ...tableHookQueryInitialParams
            },
            {
              ...endpointsConfig.getPermissionCopiesPage
            }
          );

          const response = {
            data: {
              ...data,
              content: parsePermissionCopiesList(data.content)
            }
          };
          return response;
        },

        queryKey: PermissionsQueryKeysEnum.PERMISSION_COPIES_LIST,
        filterParamsConverter: mappedFilterFields,
        sortParamConverter: mappedSortFields,
        initParams: tableHookQueryInitialParams
      }}
      xlsxDownload={{
        fileName: title,
        apiRequest: async params => {
          const { data } = await API.permissions.getPermissionCopiesPage(
            {
              ...params,
              ...tableHookQueryInitialParams
            },
            {
              ...endpointsConfig.getPermissionCopiesPage
            }
          );

          const response = {
            data: {
              ...data,
              content: parsePermissionCopiesList(data.content)
            }
          };
          return response;
        },
        filterParamsConverter: mappedFilterFields,
        sortParamConverter: mappedSortFields
      }}
      initialTableState={{
        sorting: [{ id: 'formNumber', desc: false }]
      }}
      headerActions={headerActions}
      selectedRowActions={[
        {
          title: t('action.print'),
          onClick: ({ selectedRows, refetch, unselectAllRows, setIsLoading }) => {
            setIsLoading(true);
            print(
              {
                permissionTypeCode,
                permissionIds: selectedRows.map(row => row.id)
              },
              {
                onSuccess: () => {
                  unselectAllRows();
                  refetch();
                },
                onSettled: () => {
                  setIsLoading(false);
                }
              }
            );
          }
        },
        {
          title: t('prints:actions.regenerate'),
          onClick: ({ selectedRows, refetch, unselectAllRows }) => {
            setAction(PrintActionType.Regenerate, {
              resourcesReassignments: {
                permissionTypeCode,
                resources: selectedRows?.map(row => ({
                  applicationId,
                  resourceObjectId: row.form.id,
                  assignmentTypeKey: DomainDictionaryEntry.GENERATOR_ASSIGNMENT_TYPE.PERMISSION_COPY
                }))
              },
              onSuccess: () => {
                unselectAllRows();
                refetch();
              }
            });
          }
        }
      ]}
      rowActions={[
        {
          label: isRegenerateDisabled ? notDepotMPDTooltip : t('prints:actions.regenerate'),
          tooltip: isRegenerateDisabled ? notDepotMPDTooltip : t('prints:actions.regenerate'),
          isDisabled: isRegenerateDisabled || !applicationId,
          onClick: ({ rowData, refetch }) => {
            handlePrintRegenerate({
              applicationId,
              data: rowData,
              onSuccess: refetch,
              isMultipleFormNumberToRegenerateInRow,
              permissionTypeCode
            });
          },
          icon: 'CalculatorIcon'
        },
        {
          label: t('action.print'),
          tooltip: ({ rowData }) =>
            isPrintDisabled?.(rowData) ? t('prints:messages.printActionDisabled') : t('action.print'),
          isDisabled: ({ rowData }) => isPrintDisabled?.(rowData),
          onClick: ({ rowData, setLoading, refetch }) => {
            setLoading(true);
            print(
              { permissionTypeCode, permissionIds: [rowData.id] },
              {
                onSettled: () => {
                  setLoading(false);
                  refetch();
                }
              }
            );
          },
          icon: 'PrintIcon'
        },
        {
          label: t('action.edit'),
          onClick: editPermission,
          icon: 'EditIcon',
          isHidden: !checkIsElementVisible(DomainUIElementEnum.PRINT_DATA_UPDATE_BUTTON)
        }
      ]}
      maxNumberOfIconsInActionRow={maxRowActionNumber}
    />
  );
}

export default PermissionCopiesTableV2;
