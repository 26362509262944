import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AttachmentVerificationElementDetails } from '@ibtm/domain';

import { InputMatrixField } from '@libs/common/v2';

import { useApplicationDetailsQuery } from '@libs/domain/application/api';
import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import useAttachmentsMatrixRowInputs from './useAttachmentsMatrixRowInputs';

function AttachmentsMatrixInputField({
  verificationElements,
  applicationId,
  isEditNoteButton,
  isOnlyBooleanColumn,
  fieldId
}: {
  fieldId: string;
  verificationElements: AttachmentVerificationElementDetails[];
  applicationId: string;
  isEditNoteButton: boolean;
  isOnlyBooleanColumn: boolean;
}) {
  const [t] = useTranslation();

  const { data } = useApplicationDetailsQuery(applicationId);
  const isWL = useMemo(
    () => data?.categoryKey === DomainDictionaryEntry.APPLICATION_CATEGORY.ROAD_CARRIER_GOODS_CARRIAGE_ABRIDGEMENT,
    [data]
  );
  const isApplicationStatusAccomplished = useMemo(
    () => data?.statusKey === DomainDictionaryEntry.APPLICATION_STATUS.ACCOMPLISHED,
    [data]
  );
  const { createRowInputs, createHeaders } = useAttachmentsMatrixRowInputs(
    applicationId,
    fieldId,
    verificationElements,
    isWL
  );
  const rowTitles = verificationElements
    ?.map(item => ({
      id: item.id,
      title: item.name,
      notes: item.notes,
      statusKey: item.statusKey
    }))
    .sort((a, b) => a?.title?.localeCompare(b?.title));

  return (
    <InputMatrixField
      headers={createHeaders({ isEditNoteButton, isOnlyBooleanColumn })}
      rowTitlesColumnWidth={400}
      rowTitles={rowTitles}
      rowTitlesTitle={t('applications:attachments.fields.name')}
      headerDictionary={DomainDictionaryEnum.REQUIRED_FILE_STATUS}
      rowDictionary={DomainDictionaryEnum.ATTACHMENTS_TO_APPLICATION}
      rowInputs={row => {
        const attachment = rowTitles.find(element => element === row?.rowTitle);

        return createRowInputs({
          attachment,
          isEditNoteButton,
          isOnlyBooleanColumn,
          isEditButtonDisabled: isApplicationStatusAccomplished
        });
      }}
      noContentMessage={t('attachments:noAttachments')}
      isWhiteBackground
    />
  );
}

export default AttachmentsMatrixInputField;
