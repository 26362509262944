import { useTranslation } from 'react-i18next';

import { Button } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

import { useRemarksHeaderActions } from '@libs/domain/notes';

interface IProps {
  proceedingId: string;
  suspensionId: string;
  remarkExists?: boolean;
  resetNote: () => void;
  cancelActionKey?: UIElementNameEnum;
}

function RemarksHeaderActions({ proceedingId, suspensionId, remarkExists, resetNote, cancelActionKey }: IProps) {
  const [t] = useTranslation();
  const { isButtonLoading } = useRemarksHeaderActions({
    proceedingId,
    suspensionId,
    remarkExists
  });
  const handeCancel = () => {
    resetNote();
  };

  return (
    <Button
      label={t('action.revertChanges')}
      onClick={handeCancel}
      isLoading={isButtonLoading}
      variant="outlined"
      actionKey={cancelActionKey}
      isSecondary
    />
  );
}

export default RemarksHeaderActions;
