import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DataSourceMediatorColumn } from '@stack/report/dist/models';
import clsx from 'clsx';

import {
  EditIcon,
  FileTreeIcon,
  LabeledSwitch,
  Section,
  SectionTitle,
  useFormV2Context,
  useViewModesV2
} from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { calc } from '@libs/common/v2/utils';

import { useConditionsSection } from '@libs/report/hooks';
import { ConditionAdvanced, ConditionBasic } from '@libs/report-core/components/condition-builder';

interface ReportConditionsSectionProps {
  initialValues?: any;
  dataSourceId: string;
  columns: DataSourceMediatorColumn[];
  name: FieldPath<FieldValues>;
  queryName?: FieldPath<FieldValues>;
  dataSetIndex?: number;
  parameterizable?: boolean;
}

export function ReportTypeConditionsContent({
  name,
  queryName,
  dataSourceId,
  columns,
  initialValues,
  parameterizable,
  dataSetIndex
}: ReportConditionsSectionProps) {
  const [t] = useTranslation();
  const { errors, formState, formMode } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);

  const { handleModeChange, basicMode } = useConditionsSection({
    name,
    dataSetIndex,
    queryName,
    formMode,
    initialValues,
    dirtyFields: formState.dirtyFields,
    queryDefinitionsColumnsName: `dataSetDefinitions[${dataSetIndex}].queryDefinition.columns`,
    basicModeName: `dataSetDefinitions[${dataSetIndex}].basicMode`
  });
  const classes = useStyles();

  const headerContent: JSX.Element = (
    <LabeledSwitch
      className="w-auto whitespace-nowrap pr-4"
      leftLabel={
        <div className="flex items-center justify-center pl-4 pr-8">
          <FileTreeIcon width={20} height={20} className="mr-8" />
          {t('reports:reportTypes.basicView')}
        </div>
      }
      rightLabel={
        <div className="flex items-center justify-center pl-4 pr-8">
          <EditIcon width={20} height={20} className="mr-8" />
          {t('reports:reportTypes.advancedView')}
        </div>
      }
      onChange={handleModeChange}
      value={basicMode}
    />
  );

  const footerContent =
    errors?.conditionsAreValid && typeof errors?.conditionsAreValid?.message === 'string' ? (
      <span className={clsx(classes.error, 'w-full px-8 py-4')}>{errors?.conditionsAreValid?.message}</span>
    ) : null;

  const noDataInfo: JSX.Element = (
    <div className="flex justify-center w-full my-20">
      <Typography variant="caption">{t('reports:reportTypes.noDataSourceSelected')}</Typography>
    </div>
  );

  const noSelectedColumnsInfo: JSX.Element = (
    <div className="flex justify-center w-full my-20">
      <Typography variant="caption">{t('reports:reportTypes.noColumnsSelected')}</Typography>
    </div>
  );

  const renderEmptyContent = (): JSX.Element => {
    return !dataSourceId ? noDataInfo : noSelectedColumnsInfo;
  };

  const getSectionContent = () => {
    if (!dataSourceId || (basicMode && !columns.length)) {
      return <div>{renderEmptyContent()}</div>;
    }
    return basicMode ? (
      <ConditionBasic name={name} columns={columns} formMode={formMode} isParameterizable={parameterizable} />
    ) : (
      <ConditionAdvanced name={queryName} dataSetIndex={dataSetIndex} isReadonly={viewMode} />
    );
  };

  return (
    <Section
      title={
        <SectionTitle
          className={clsx('py-0', errors?.condition && classes.error)}
          title={t('reports:reportTypes.conditions')}
          isRequired={!basicMode}
        />
      }
      headerContent={headerContent}
      footerContent={footerContent}
      className="h-full"
      isNoChildrenContentWrapper
      isPadded
    >
      <div className="mr-12 flex">{getSectionContent()}</div>
    </Section>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  error: {
    color: theme.palette.error[700]
  },
  section: {
    height: calc('100% + 24px')
  }
}));

export default React.memo(ReportTypeConditionsContent);
