import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '@libs/user/api';
import { AnonymizationType } from '@libs/user/models';

const deleteUser = async (accountId: string) => {
  const { data } = await API.user.accountController.deleteAccount(
    { accountId, anonymization: AnonymizationType.OFF },
    { ...endpointsConfig.deleteAccount }
  );
  return data;
};

export default function useDeleteUserMutation() {
  return useMutation(deleteUser);
}
