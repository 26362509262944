import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { MoneyTransferCreateRequest } from '@ibtm/domain';
import * as Yup from 'yup';

import {
  DatepickerField,
  Dialog,
  FormV2Context,
  GridLayout,
  isDateValid,
  NumberInputField,
  typedNameV2
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useAddMoneyTransfersToApplication, useCreateMoneyTransferMutation } from '../api';
import { PricingQueryKeysEnum } from '../api/query/PricingQueryKeysEnum';
import { IMoneyTransferCreateRequest } from '../utils';

interface IProps {
  applicationId: string;
  closeDialog: () => void;
}

function MoneyTransferUnconfirmedAddDialog({ applicationId, closeDialog }: IProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: createMoneyTransfer, isLoading: isCreatingMoneyTransfer } = useCreateMoneyTransferMutation();
  const { mutate: addMoneyTransferToApplication, isLoading: isAddingMoneyTransfer } =
    useAddMoneyTransfersToApplication();
  const queryCache = useQueryCache();

  const formMethods = useForm<Partial<MoneyTransferCreateRequest>>({
    resolver: yupResolver(
      Yup.object<IMoneyTransferCreateRequest>({
        paymentDate: Yup.string().nullable().required().concat(isDateValid()),
        amount: Yup.number().nullable().required()
      })
    )
  });

  const handleSubmit = formMethods.handleSubmit((formData: Partial<MoneyTransferCreateRequest>) =>
    createMoneyTransfer(formData as MoneyTransferCreateRequest, {
      onSuccess: newMoneyTransfer => {
        addMoneyTransferToApplication(
          { applicationId, formData: { moneyTransfersIds: [newMoneyTransfer.data?.id] } },
          {
            onSuccess: () => {
              showSuccessSnackbar(t('success.save'));
              queryCache.invalidateQueries([PricingQueryKeysEnum.APPLICATION_MONEY_TRANSFERS]);
              closeDialog();
            }
          }
        );
      }
    })
  );

  const form = useMemo(() => formMethods, [formMethods]);

  return (
    <Dialog
      title={t('pricing:moneyTransfersUnconfirmed.addDialog.title')}
      confirmText={t('action.add')}
      cancelText={t('action.cancel')}
      onConfirm={handleSubmit}
      onCancel={closeDialog}
      isConfirmLoading={isCreatingMoneyTransfer || isAddingMoneyTransfer}
      isOpen
    >
      <FormV2Context.Provider value={form}>
        <form onSubmit={handleSubmit}>
          <GridLayout itemProps={{ xs: 6 }}>
            <DatepickerField
              name={typedNameV2<MoneyTransferCreateRequest>('paymentDate')}
              label={t('pricing:moneyTransfersUnconfirmed.field.date')}
              isRequired
            />
            <NumberInputField
              name={typedNameV2<MoneyTransferCreateRequest>('amount')}
              label={t('pricing:moneyTransfersUnconfirmed.field.amount')}
              isRequired
            />
          </GridLayout>
        </form>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default MoneyTransferUnconfirmedAddDialog;
