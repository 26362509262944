import { useTableLayoutProps } from '@libs/common/v2';

import { useReputationResultsQuery } from '@libs/domain/folder';
import useReputationQueryResultColumns from '@libs/domain/folder/config/useReputationQueryResultColumns';

function useReputationQueryResultsTable(transportManagerId: string, reputationRequestId: string) {
  const { columns } = useReputationQueryResultColumns();

  return useTableLayoutProps({
    tableHookQuery: useReputationResultsQuery,
    tableHookOptions: {
      columns
    },
    tableHookQueryInitialParams: {
      transportManagerId,
      reputationRequestId
    }
  });
}

export default useReputationQueryResultsTable;
