import { ReputationRequestDetails } from '@ibtm/domain';

import { useCreateColumns } from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';

const usePartnershipColumns = () => {
  const { createColumns } = useCreateColumns<ReputationRequestDetails>({
    pathToTranslate: 'folder:details.field'
  });

  const columns = createColumns([
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.REPUTATION_REQUEST_STATUS,
      header: 'queryStatus',
      accessor: 'statusKey',
      isFilterable: false,
      isSortable: false
    },
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.REPUTATION,
      header: 'reputationStatus',
      accessor: 'aggregatedStatusKey',
      isFilterable: false,
      isSortable: false
    },
    {
      type: 'DATE',
      header: 'inquiryDate',
      accessor: 'inquiryDate',
      isFilterable: false,
      isSortable: false
    },
    {
      header: 'questioningPerson',
      type: 'TEXT',
      accessor: 'inquiredBy.name',
      isFilterable: false,
      isSortable: false
    }
  ]);

  return { columns };
};

export default usePartnershipColumns;
