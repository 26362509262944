export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  createDepotManager: { translationKey: 'resource:api.createDepotManager' },
  createDepotTransfer: { translationKey: 'resource:api.createDepotTransfer' },
  changeStatusDepotTransfer: { translationKey: 'resource:api.changeStatusDepotTransfer' },
  changeResourceObjectOrderStatus: { translationKey: 'resource:api.changeResourceObjectOrderStatus' },
  associationReturnResourceObject: { translationKey: 'resource:api.associationReturnResourceObject' },
  batchDisposalResourceObjects: { translationKey: 'resource:api.batchDisposalResourceObjects' },
  externalReceiveResourceObject: { translationKey: 'resource:api.externalReceiveResourceObject' },
  externalReleaseResourceObject: { translationKey: 'resource:api.externalReleaseResourceObject' },
  createResourceObjectOrder: { translationKey: 'resource:api.createResourceObjectOrder' },
  returnResourceObject: { translationKey: 'resource:api.returnResourceObject' },
  rollbackReturnResourceObject: { translationKey: 'resource:api.rollbackReturnResourceObject' },
  transformResourceObjectType: { translationKey: 'resource:api.transformResourceObjectType' },
  receiveClientResourceObject: { translationKey: 'resource:api.receiveClientResourceObject' },
  releaseClientResourceObject: { translationKey: 'resource:api.releaseClientResourceObject' },
  createResourceType: { translationKey: 'resource:api.createResourceType' },
  updateResourceObjectOrder: { translationKey: 'resource:api.updateResourceObjectOrder' },
  deleteResourceType: { translationKey: 'resource:api.deleteResourceType' },
  getAssociationSubjectSnapshotPage: { translationKey: 'associations:api.getAssociationSubjectSnapshotPage' },

  availableResourceObjectToOrder: { translationKey: 'resource:api.availableResourceObjectToOrder' },
  getDepotManagersList: { translationKey: 'resource:api.getDepotManagersList' },
  getDepotManagers: { translationKey: 'resource:api.getDepotManagers' },
  getDepotTransferSnapshotPage: { translationKey: 'resource:api.getDepotTransferSnapshotPage' },
  getDepots: { translationKey: 'resource:api.getDepots' },
  getDepot: { translationKey: 'resource:api.getDepot' },
  getResourceObjectContentPoolSnapshot: { translationKey: 'resource:api.getResourceObjectContentPoolSnapshot' },
  getResourceObjectLastAvailablePool: { translationKey: 'resource:api.getResourceObjectLastAvailablePool' },
  getResourceObjectPoolSnapshot: { translationKey: 'resource:api.getResourceObjectPoolSnapshot' },
  getResourceObjectGapAndCorrectPoolSnapshot: {
    translationKey: 'resource:api.getResourceObjectPoolGapAndCorrectSnapshot'
  },
  getValidateAvailableSinglePool: { translationKey: 'resource:api.getValidateAvailableSinglePool' },
  getResourceObjects: { translationKey: 'resource:api.getResourceObjects' },
  getResourceObjectOrders: { translationKey: 'resource:api.getResourceObjectOrders' },
  getResourceObjectDisposals: { translationKey: 'resource:api.getResourceObjectDisposals' },
  getResourceObjectAssociationPoolSnapshot: { translationKey: 'resource:api.getResourceObjectAssociationPoolSnapshot' },
  getResourceObjectOrder: { translationKey: 'resource:api.getResourceObjectOrder' },
  getAvailableResourceTypes: { translationKey: 'resource:api.getAvailableResourceTypes' },
  getResourceTypes: { translationKey: 'resource:api.getResourceTypes' },
  updateResourceType: { translationKey: 'resource:api.updateResourceType' },
  getResourceType: { translationKey: 'resource:api.getResourceType' }
};
