import { ApplicationsApiForeignAuthoritiesNotificatedRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

export function notifyForeignAuthorities(requestData: ApplicationsApiForeignAuthoritiesNotificatedRequest) {
  return API.application.foreignAuthoritiesNotificated(
    requestData.applicationId,
    requestData.foreignAuthoritiesNotificatedRequest,
    { ...endpointsConfig.foreignAuthoritiesNotificated }
  );
}

function useNotifyForeignAuthoritiesMutation() {
  return useMutation(notifyForeignAuthorities);
}

export default useNotifyForeignAuthoritiesMutation;
