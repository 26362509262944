import { CountryEnum } from '../../exploitation-base';
import { PartnerApplicantFields } from '../model';

const generateDataAddressRequest = (data: Partial<PartnerApplicantFields>) => {
  if (!data.address.sameAsMainAddress) {
    if (data?.address?.countryCodeKey?.value === CountryEnum.POLAND) {
      return {
        city: data?.address?.city,
        countryCodeKey: data?.address?.countryCodeKey?.value,
        postCity: data?.address?.postCity,
        postCode: data?.address?.postCode,
        propertyNumber: data?.address?.propertyNumber,
        street: data?.address?.street,
        voivodeshipKey: data?.address?.voivodeshipKey?.value,
        apartmentNumber: data.address.apartmentNumber && data.address.apartmentNumber,
        commune: data.address.commune && data.address.commune,
        county: data.address.county && data.address.county,
        name: data.address.name && data.address.name
      };
    }

    return {
      name: data?.address?.name,
      city: data?.address?.city,
      firstLine: data?.address?.firstLine,
      secondLine: data?.address?.secondLine,
      postCode: data?.address?.postCode,
      countryCodeKey: data?.address?.countryCodeKey?.value
    };
  }
  return null;
};

export default generateDataAddressRequest;
