import { useCallback, useMemo, useState } from 'react';

/*
uzycie:
    const [isVisible, visibleRef] = useOnScreen();
    isVisible - boolean, czy element jest widoczny na ekranie
    visibleRef - ref do elementu który ma triggerowac sprawdzanie widoczności (np. ostatni element na scrollowalnej liscie)
*/
export default function useOnScreen<Element extends HTMLElement>(
  options?: IntersectionObserverInit
): [boolean, React.RefCallback<Element>] {
  const [isVisible, setIsVisible] = useState(false);

  const observer = useMemo(() => {
    if (window.IntersectionObserver) {
      return new IntersectionObserver(([entry]) => {
        setIsVisible(entry.isIntersecting);
      }, options);
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsVisible]);

  const currentElement = useCallback(
    (ele: Element | null) => {
      if (observer?.observe || observer?.disconnect) {
        if (ele) {
          observer?.observe(ele);
        } else {
          observer?.disconnect();
          setIsVisible(false);
        }
      }
    },
    [observer, setIsVisible]
  );

  return [isVisible, currentElement];
}
