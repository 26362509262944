import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FinancialSecurityDetails } from '@ibtm/domain';
import { Grid } from '@mui/material';

import { CamelCasePath, SelectOption, typedNameV2, useFormV2Context } from '@libs/common/v2';
import { typedArray } from '@libs/common/v2/utils';

import { DomainDictionaryEntry, DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import {
  FinancialSecurityActions,
  FinancialSecuritySummary,
  FinancialSecuritySummaryFields,
  FinancialSecurityTable,
  VehiclesReportedForApproval,
  VehiclesReportedForApprovalFields
} from '@libs/domain/financial-security';
import { checkIsPartnership, FolderDetailsClient, FolderDetailsParams } from '@libs/domain/folder';

function FinancialSecurityTab() {
  const { folderId } = useParams<FolderDetailsParams>();
  const { watch } = useFormV2Context();
  const { isClientPortal } = useDomainConfigContext();

  const folderTypeKey = watch(typedNameV2<FolderDetailsClient>('typeKey')) as SelectOption<string>;
  const legalFormKey = watch(typedNameV2<FolderDetailsClient>('subject.legalFormKey')) as string;

  const isPPOFolder = useMemo(() => folderTypeKey.value === DomainDictionaryEntry.FOLDER_TYPE.PPO, [folderTypeKey]);
  const isTPFolder = useMemo(() => folderTypeKey.value === DomainDictionaryEntry.FOLDER_TYPE.TP, [folderTypeKey]);
  const isOPFolder = useMemo(() => folderTypeKey.value === DomainDictionaryEntry.FOLDER_TYPE.OP, [folderTypeKey]);

  const isPartnership = useMemo(() => checkIsPartnership(legalFormKey), [legalFormKey]);

  const financialSecurityTableActions: FinancialSecurityActions[] = useMemo(() => {
    if (isClientPortal) {
      return [];
    }

    return [
      ...typedArray<FinancialSecurityActions>(['details', 'expire', 'restore', 'edit']),
      ...([DomainDictionaryEntry.FOLDER_TYPE.KS, DomainDictionaryEntry.FOLDER_TYPE.ZZ].includes(folderTypeKey.value)
        ? typedArray<FinancialSecurityActions>(['create', 'delete'])
        : [])
    ];
  }, [folderTypeKey.value, isClientPortal]);

  const financialSecurityTableVisibleColumns: Array<CamelCasePath<FinancialSecurityDetails>> = useMemo(() => {
    let columns: Array<CamelCasePath<FinancialSecurityDetails>> = [
      'typeKey',
      'expirationDate',
      'amountPln',
      'amountEur',
      'currencyExchangeRateExchangeRate',
      'currencyExchangeRateExchangeRateFrom',
      'subjectName',
      'notes',
      'statusKey',
      'modified',
      'modifierName'
    ];
    const ppoExcludeColumns = ['amountEur', 'currencyExchangeRateExchangeRate', 'currencyExchangeRateExchangeRateFrom'];
    const clientPortalExcludeColumns = ['notes', 'subjectName', 'modified', 'modifierName'];
    if (isPPOFolder) {
      columns = columns.filter(columnName => !ppoExcludeColumns.includes(columnName));
    }
    if (!isPartnership) {
      columns.splice(columns.indexOf('subjectName'), 1);
    }
    if (isClientPortal) {
      columns = columns.filter(columnName => !clientPortalExcludeColumns.includes(columnName));
    }
    return columns;
  }, [isClientPortal, isPartnership, isPPOFolder]);
  return (
    <Grid spacing={0} container>
      {(folderTypeKey?.value === DomainDictionaryEntry.FOLDER_TYPE.TP ||
        folderTypeKey?.value === DomainDictionaryEntry.FOLDER_TYPE.OP) && (
        <Grid item xs={12}>
          <VehiclesReportedForApproval
            folderId={folderId}
            enabledFields={
              [
                ...(isTPFolder ? ['numberOfBCategoryVehicles', 'numberOfCCategoryVehicles'] : []),
                ...(isOPFolder
                  ? [
                      'foreignPermissionVehiclesNumber',
                      'foreignPermissionVehiclesNumberAboveThreeAndHalfTons',
                      'foreignPermissionVehiclesNumberBelowThreeAndHalfTons'
                    ]
                  : [])
              ] as Array<VehiclesReportedForApprovalFields>
            }
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <div>
          <FinancialSecurityTable
            queryParams={isClientPortal ? { folderIdEq: folderId } : { folderIds: [folderId] }}
            folderId={folderId}
            isFolderView
            folderTypeKey={folderTypeKey?.value}
            visibleColumns={financialSecurityTableVisibleColumns}
            actions={financialSecurityTableActions}
            permissionsKeys={{
              create: DomainUIElementEnum.FOLDER_FINANCIAL_SECURITY_CREATE_BUTTON,
              expire: DomainUIElementEnum.FOLDER_FINANCIAL_SECURITY_WITHDRAW_BUTTON,
              restore: DomainUIElementEnum.FOLDER_FINANCIAL_SECURITY_RESTORE_BUTTON,
              edit: DomainUIElementEnum.FOLDER_FINANCIAL_SECURITY_EDIT_BUTTON,
              delete: DomainUIElementEnum.FOLDER_FINANCIAL_SECURITY_DELETE_BUTTON
            }}
            isCollapsable
            isHeaderActionsVisible
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <FinancialSecuritySummary
          folderId={folderId}
          enabledFields={
            [
              ...['presentedCollateralPLN', 'requiredCollateralPLN', 'missingOrRedundantCollateralPLN'],
              ...(!isPPOFolder
                ? ['presentedCollateralEURO', 'requiredCollateralEURO', 'missingOrRedundantCollateralEURO']
                : [])
            ] as Array<FinancialSecuritySummaryFields>
          }
        />
      </Grid>
    </Grid>
  );
}

export default FinancialSecurityTab;
