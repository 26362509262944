import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { FormV2Context } from '@libs/common/v2';

import { useApplicationDetailsQuery } from '@libs/domain/application';

import { usePrintsDetailsDataContext } from '../../context';

function PrintDetailsFormWrapper({ children }) {
  const form = useForm({
    mode: 'onBlur',
    criteriaMode: 'all'
  });
  const { reset, setValue } = form;
  const { printDetailsData } = usePrintsDetailsDataContext();
  const { applicationId } = printDetailsData;

  useApplicationDetailsQuery(applicationId, {
    enabled: Boolean(applicationId),
    onSuccess: data => {
      reset({
        receiptTypeKey: { value: data?.receiptTypeKey },
        recipientName: data?.recipientName,
        recipientSurname: data?.recipientSurname,
        version: data.version
      });

      if (data?.sendToAddress) {
        setValue(
          'sendToAddress',
          `${data?.sendToAddress.city}, ${data?.sendToAddress.street} ${data?.sendToAddress.propertyNumber}`
        );
      }
    }
  });
  const values = useMemo(
    () => ({
      ...form,
      isDirty: form.formState.isDirty,
      isSubmitting: form.formState.isSubmitting
    }),
    [form]
  );

  return <FormV2Context.Provider value={values}>{children}</FormV2Context.Provider>;
}
export default PrintDetailsFormWrapper;
