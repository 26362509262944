import { usePaginatedQuery } from 'react-query';
import { AssociationSubjectSearchFilter, AssociationSubjectSnapshot } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { AssociationQueryKeysEnum } from '../../model';
import { endpointsConfig } from '../endpoints-config';

const getAssociationSubject = async (_, params: AssociationSubjectSearchFilter & { associationId: string }) => {
  const { associationId, ...restParams } = params;
  const { data } = await API.association.getAssociationSubjectSnapshotPage(associationId, restParams, {
    ...endpointsConfig.getAssociationSubjectSnapshotPage
  });

  return data;
};

export default function useAssociationSubjectQuery(params: any) {
  return usePaginatedQuery<IPaginatedModel<AssociationSubjectSnapshot>>(
    [AssociationQueryKeysEnum.GET_ASSOCIATION_SUBJECT, params],
    getAssociationSubject
  );
}
