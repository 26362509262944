import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CivicBoardRuleItemFolderLimitConfigAllOf,
  CivicBoardRuleItemLimitConfigAllOf,
  CivicBoardRuleItemLimitedByVehiclesUsageConfig,
  CivicBoardRuleItemMonthlyProportionalUsageConfigAllOf,
  CivicBoardRuleItemNotUsedConfigAllOf,
  CivicBoardRuleItemPermissionForVehicleConfigAllOf,
  RuleItemCodes
} from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import {
  Button,
  DatepickerField,
  Divider,
  GridLayout,
  IconButton,
  InputMode,
  NumberInputField,
  Section,
  SwitchField,
  typedNameV2
} from '@libs/common/v2';

export function RuleItemForms({
  type,
  index,
  deleteIndexFromList,
  inputMode,
  length,
  moveUp,
  moveDown,
  generateKey
}: {
  type: RuleItemCodes;
  index: number;
  deleteIndexFromList?: (index: number) => void;
  inputMode?: InputMode;
  length: number;
  moveUp: (index: number) => void;
  moveDown: (index: number) => void;
  generateKey: (rule: string) => string;
}) {
  const [t] = useTranslation();
  const getLabel = partialTranslate('foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.fields');

  const moveTopOrBottomButtons = useMemo(
    () => (
      <>
        {index !== 0 && (
          <IconButton
            onClick={() => moveUp(index)}
            tooltipTitle={t('foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.button.moveTop')}
            icon="ChevronUpIcon"
          />
        )}
        {index !== length - 1 && (
          <IconButton
            onClick={() => moveDown(index)}
            tooltipTitle={t('foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.button.moveBottom')}
            icon="ChevronDownIcon"
          />
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type, index, t]
  );

  const headerButtons = useMemo(
    () => (
      <>
        {moveTopOrBottomButtons}
        <Button label={t('action.delete')} variant="outlined" onClick={() => deleteIndexFromList(index)} />
      </>
    ),
    [moveTopOrBottomButtons, t, deleteIndexFromList, index]
  );
  const divider = () => index !== length - 1 && <Divider className="mt-10" />;

  if (type === RuleItemCodes.NOT_USED_PERMISSION_RULE) {
    return (
      <>
        <Section
          headerContent={inputMode !== InputMode.VIEW && headerButtons}
          key={`${index} - ${generateKey(type)}`}
          title={t(
            'foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.ruleType.notUsedPermissionRule'
          )}
        >
          <GridLayout itemProps={{ xs: 12 }}>
            <SwitchField
              name={`items[${index}].${typedNameV2<CivicBoardRuleItemNotUsedConfigAllOf>('shouldCountNotReturned')}`}
              label={getLabel('shouldCountNotReturned')}
              inputMode={inputMode}
            />
            <SwitchField
              name={`items[${index}].${typedNameV2<CivicBoardRuleItemNotUsedConfigAllOf>('shouldCountMissing')}`}
              label={getLabel('shouldCountMissing')}
              inputMode={inputMode}
            />
            <SwitchField
              name={`items[${index}].${typedNameV2<CivicBoardRuleItemNotUsedConfigAllOf>(
                'shouldCountReturnedNotUsed'
              )}`}
              label={getLabel('shouldCountReturnedNotUsed')}
              inputMode={inputMode}
            />
            <SwitchField
              name={`items[${index}].${typedNameV2<CivicBoardRuleItemNotUsedConfigAllOf>(
                'shouldCountLastYearContingent'
              )}`}
              label={getLabel('shouldCountLastYearContingent')}
              inputMode={inputMode}
            />
            <NumberInputField
              name={`items[${index}].${typedNameV2<CivicBoardRuleItemNotUsedConfigAllOf>('decreaseAmount')}`}
              label={getLabel('decreaseAmount')}
              inputMode={inputMode}
              isRequired
            />
            <NumberInputField
              name={`items[${index}].${typedNameV2<CivicBoardRuleItemNotUsedConfigAllOf>('numberOfMonths')}`}
              label={getLabel('numberOfMonths')}
              inputMode={inputMode}
              isClearable
              min={1}
            />
          </GridLayout>
        </Section>
        {divider()}
      </>
    );
  }
  if (type === RuleItemCodes.PERMISSION_FOR_VEHICLE_RULE) {
    return (
      <>
        <Section
          headerContent={inputMode !== InputMode.VIEW && headerButtons}
          key={`${index} - ${generateKey(type)}`}
          title={t(
            'foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.ruleType.permissionForVehicleRule'
          )}
        >
          <GridLayout itemProps={{ xs: 12 }}>
            <NumberInputField
              name={`items[${index}].${typedNameV2<CivicBoardRuleItemPermissionForVehicleConfigAllOf>('from')}`}
              label={getLabel('from')}
              inputMode={inputMode}
              isOnlyPositiveIntegers
              isRequired
            />
            <NumberInputField
              name={`items[${index}].${typedNameV2<CivicBoardRuleItemPermissionForVehicleConfigAllOf>('to')}`}
              label={getLabel('to')}
              inputMode={inputMode}
              isOnlyPositiveIntegers
              isClearable
            />
            <NumberInputField
              name={`items[${index}].${typedNameV2<CivicBoardRuleItemPermissionForVehicleConfigAllOf>(
                'permissionAmount'
              )}`}
              label={getLabel('permissionAmount')}
              inputMode={inputMode}
              decimalPrecision={2}
              isRequired
            />
            <NumberInputField
              name={`items[${index}].${typedNameV2<CivicBoardRuleItemPermissionForVehicleConfigAllOf>(
                'vehicleAmount'
              )}`}
              label={getLabel('vehicleAmount')}
              inputMode={inputMode}
              decimalPrecision={2}
              isRequired
            />
          </GridLayout>
        </Section>
        {divider()}
      </>
    );
  }
  if (type === RuleItemCodes.MONTHLY_PROPORTIONAL_USAGE_RULE) {
    return (
      <>
        <Section
          headerContent={inputMode !== InputMode.VIEW && headerButtons}
          key={`${index} - ${generateKey(type)}`}
          title={t(
            'foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.ruleType.monthlyProportionalUsageRule'
          )}
        >
          <GridLayout itemProps={{ xs: 12 }}>
            <SwitchField
              name={`items[${index}].${typedNameV2<CivicBoardRuleItemMonthlyProportionalUsageConfigAllOf>(
                'treatNoLimitsAsProportional'
              )}`}
              inputMode={inputMode}
              label={getLabel('treatNoLimitsAsProportional')}
            />
          </GridLayout>
        </Section>
        {divider()}
      </>
    );
  }
  if (type === RuleItemCodes.LIMITED_VALUE_RULE) {
    return (
      <>
        <Section
          headerContent={inputMode !== InputMode.VIEW && headerButtons}
          key={`${index} - ${generateKey(type)}`}
          title={t('foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.ruleType.limitedValueRule')}
        >
          <GridLayout itemProps={{ xs: 12 }}>
            <NumberInputField
              inputMode={inputMode}
              name={`items[${index}].${typedNameV2<CivicBoardRuleItemLimitConfigAllOf>('maxLimit')}`}
              label={getLabel('maxLimit')}
              isRequired
            />
          </GridLayout>
        </Section>
        {divider()}
      </>
    );
  }

  if (type === RuleItemCodes.FOLDER_LIMIT_RULE) {
    return (
      <>
        <Section
          headerContent={inputMode !== InputMode.VIEW && headerButtons}
          key={`${index} - ${generateKey(type)}`}
          isCollapsable
          title={t('foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.ruleType.folderLimitRule')}
        >
          <GridLayout itemProps={{ xs: 12 }}>
            <DatepickerField
              name={`items[${index}].${typedNameV2<CivicBoardRuleItemFolderLimitConfigAllOf>(
                'maxSettlementReturnDate'
              )}`}
              label={getLabel('maxSettlementReturnDate')}
              inputMode={inputMode}
              isRequired
            />
            <NumberInputField
              inputMode={inputMode}
              name={`items[${index}].${typedNameV2<CivicBoardRuleItemFolderLimitConfigAllOf>('limitFactor')}`}
              label={getLabel('limitFactor')}
              isRequired
            />
            <NumberInputField
              max={9}
              inputMode={inputMode}
              name={`items[${index}].${typedNameV2<CivicBoardRuleItemFolderLimitConfigAllOf>('multiplier')}`}
              label={getLabel('multiplier')}
              isRequired
            />
          </GridLayout>
        </Section>
        {divider()}
      </>
    );
  }
  if (type === RuleItemCodes.LIMITED_VALUE_BY_VEHICLES_RULE) {
    return (
      <>
        <Section
          headerContent={inputMode !== InputMode.VIEW && headerButtons}
          key={`${index} - ${generateKey(type)}`}
          isCollapsable
          title={t(
            'foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.ruleType.limitedValueByVehiclesRule'
          )}
        >
          <NumberInputField
            name={`items[${index}].${typedNameV2<CivicBoardRuleItemLimitedByVehiclesUsageConfig>('amount')}`}
            label={getLabel('amountLimited')}
            inputMode={inputMode}
            isRequired
          />
        </Section>

        {divider()}
      </>
    );
  }
  return null;
}
