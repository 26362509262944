import { useMutation } from 'react-query';
import {
  SubjectAddressesApiUpdateAddressRequest as SubjectAddressesApiUpdateAddressRequestClient,
  SubjectAddressUpdateRequest as ClientSubjectAddressUpdateRequest
} from '@ibtm/client-domain';
import { SubjectAddressesApiUpdateAddressRequest, SubjectAddressUpdateRequest } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export type AddressesApiUpdateAddressRequestClient = Omit<
  SubjectAddressesApiUpdateAddressRequestClient,
  'xFolderId' | 'xSubjectId'
> & {
  subjectAddressUpdateRequest: ClientSubjectAddressUpdateRequest & {
    sameAsMainAddress: SubjectAddressUpdateRequest['sameAsMainAddress'];
  };
};

export const updateAddress = async (requestData: SubjectAddressesApiUpdateAddressRequest) => {
  const response = await API.address.updateAddress(requestData.addressId, requestData.subjectAddressUpdateRequest, {
    ...endpointsConfig.updateAddress
  });

  return response;
};

export const updateAddressClient = async (
  requestData: Omit<AddressesApiUpdateAddressRequestClient, 'xFolderId' | 'xSubjectId'>
) => {
  const response = await API.client.address.updateAddress(
    requestData.addressId,
    '',
    '',
    requestData.subjectAddressUpdateRequest,
    { ...endpointsConfig.updateAddress }
  );

  return response;
};

function useUpdateAddressMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(updateAddressClient, updateAddress));
}

export default useUpdateAddressMutation;
