import { MutationConfig } from 'react-query';
import { ExploitationBaseUpdateRequest as ExploitationBaseUpdateRequestClient } from '@ibtm/client-domain';
import { ExploitationBaseDetails, ExploitationBaseUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface IUpdateParams {
  exploitationBaseId: string;
  exploitationBaseUpdateRequest: ExploitationBaseUpdateRequest;
}
interface IUpdateParamsClient {
  exploitationBaseId: string;
  exploitationBaseUpdateRequest: ExploitationBaseUpdateRequestClient;
}

const updateExploitationBase = async ({ exploitationBaseId, exploitationBaseUpdateRequest }: IUpdateParams) => {
  const { data } = await API.exploitationBase.updateExploitationBase(
    exploitationBaseId,
    exploitationBaseUpdateRequest,
    { ...endpointsConfig.updateExploitationBase }
  );
  return data;
};
const updateExploitationBaseClient = async ({
  exploitationBaseId,
  exploitationBaseUpdateRequest
}: IUpdateParamsClient) => {
  const { data } = await API.client.exploitationBase.updateExploitationBase(
    exploitationBaseId,
    '',
    '',
    exploitationBaseUpdateRequest,
    { ...endpointsConfig.updateExploitationBase }
  );

  return data;
};

function useExploitationBaseUpdateMutation(
  config?: MutationConfig<ExploitationBaseDetails, unknown, IUpdateParams, unknown>
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(updateExploitationBaseClient, updateExploitationBase), config);
}

export default useExploitationBaseUpdateMutation;
