export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  copyTransportManagersToAssignedApplication: {
    translationKey: 'applications:api.copyTransportManagersToAssignedApplication'
  },
  createReputationRequest: { translationKey: 'transportManager:api.createReputationRequest' },
  createTransportManager: { translationKey: 'transportManager:api.createTransportManager' },
  deleteTransportManager: { translationKey: 'transportManager:api.deleteTransportManager' },
  expireTransportManager: { translationKey: 'transportManager:api.expireTransportManager' },
  restoreTransportManager: { translationKey: 'transportManager:api.restoreTransportManager' },
  updateTransportManager: { translationKey: 'transportManager:api.updateTransportManager' },
  getTransportMangersSnapshotPage: { translationKey: 'transportManager:api.getTransportMangersSnapshotPage' },
  getTransportManagerDetails: { translationKey: 'transportManager:api.getTransportManagerDetails' }
};
