import { UIElementNameEnum } from '@libs/permission';

export enum ArchiveTabEnum {
  TO_ARCHIVE = 'TO_ARCHIVE',
  ARCHIVED = 'ARCHIVED',
  CARRY_ON_ARCHIVED = 'CARRY_ON_ARCHIVED'
}

export type TabType = {
  label: KeyType;
  value: string;
  viewName?: UIElementNameEnum;
  isConditionalVisibility?: boolean;
};

export enum ArchiveQueryKeysEnum {
  APPLICATIONS_TO_ARCHIVE = 'APPLICATIONS_TO_ARCHIVE',
  ARCHIVED_APPLICATIONS = 'ARCHIVED_APPLICATIONS',
  FOLDERS_TO_ARCHIVE = 'FOLDERS_TO_ARCHIVE',
  FOLDER_ARCHIVED = 'FOLDER_ARCHIVED',
  BORROW_FOLDER_LIST = 'BORROW_FOLDER_LIST',
  BORROW_FOLDER_DETAILS = 'BORROW_FOLDER_DETAILS'
}

export type ArchiveInitialFormValuesTypes = typeof archiveInitialFormValues;

export enum ArchiveFormEnum {
  WARNING = 'warning',
  YEAR = 'year',
  SEND = 'sendToArchive'
}

export const archiveInitialFormValues = {
  warning: ''
};
