import { FolderUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function updateFolder({ folderId, formData }: { folderId: string; formData: FolderUpdateRequest }) {
  return API.folder.updateFolder(folderId, formData, {
    ...endpointsConfig.updateFolder
  });
}

function useUpdateFolderMutation() {
  return useMutation(updateFolder);
}

export default useUpdateFolderMutation;
