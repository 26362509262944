import { Column, Hooks, Row } from 'react-table';
import { Tooltip } from '@mui/material';

import i18n from '@libs/common/i18n';
import { Checkbox, ISpecialColumnEnum, useTableAdapter, useTableContext } from '@libs/common/v2';

import TableSelectAction from './TableSelectAction';

interface CheckboxesTooltips {
  headerCheckboxTooltipTitle?: string;
  enabledCheckboxTooltipTitle?: string;
  disabledCheckboxTooltipTitle?: string;
}

interface ActionRendererProps {
  selectedRowsIds: string[];
}

function getMultipleSelectColumnWithDisabledRows(
  ActionRenderer: ({ selectedRowsIds }: ActionRendererProps) => JSX.Element,
  isRowDisabled: <T extends Row>(row: T) => boolean,
  isRowSelected: <T extends Row>(row: T) => boolean,
  checboxTooltipTitles: CheckboxesTooltips
): Column {
  const { disabledCheckboxTooltipTitle, headerCheckboxTooltipTitle, enabledCheckboxTooltipTitle } =
    checboxTooltipTitles;
  return {
    id: ISpecialColumnEnum.SELECTION,
    Header: ({ rows, state }) => {
      const selectedRowsIds = Object.keys(state.selectedRowIds);
      const checkAllAvailable = () => {
        let count = 0;
        rows.forEach(row => {
          if (!isRowDisabled(row)) {
            row.toggleRowSelected(true);
            count += 1;
          }
        });
        return count;
      };
      return (
        <div className="flex items-center">
          <TableSelectAction
            checkAllAvailable={checkAllAvailable}
            headerCheckboxTooltipTitle={headerCheckboxTooltipTitle}
          />
          {selectedRowsIds.length > 0 && <ActionRenderer selectedRowsIds={selectedRowsIds} />}
        </div>
      );
    },
    Cell: ({ row }) => {
      const { checked } = row.getToggleRowSelectedProps();
      const table = useTableAdapter();
      const { checkDisabled, isError, isLoading, isFetching } = useTableContext();
      if (isRowDisabled(row)) {
        return (
          <Tooltip disableHoverListener={!disabledCheckboxTooltipTitle} title={disabledCheckboxTooltipTitle}>
            <span className="flex items-center">
              <Checkbox
                inputProps={{
                  'aria-label': i18n.t<any>('action.disabled')
                }}
                isChecked={checked}
                isDisabled
              />
            </span>
          </Tooltip>
        );
      }
      return (
        <Tooltip disableHoverListener={!enabledCheckboxTooltipTitle} title={enabledCheckboxTooltipTitle}>
          <span className="flex items-center">
            <Checkbox
              {...row.getToggleRowSelectedProps()}
              onClick={e => e.stopPropagation()}
              inputProps={{
                'aria-label': i18n.t('other:component.table.body.checkRow')
              }}
              isChecked={checked}
              isDisabled={isLoading || isFetching || checkDisabled(table, row) || isError}
            />
          </span>
        </Tooltip>
      );
    },
    disableSortBy: true
  };
}

/**
 * Table plugin for select multiple rows, with an indication of which lines should be disabled
 */
const useMultipleSelectWithDisabledRows = (
  /**
   * Action component for selected rows
   */
  ActionRenderer: ({ selectedRowsIds }: ActionRendererProps) => JSX.Element,
  /**
   * Function which takes row as arg and returns boolean, does row should be disabled
   */
  isRowDisabled: <T extends Row>(row: T) => boolean,
  /**
   * Function which takes row as arg and returns boolean, does row should be selected
   */
  isRowSelected?: <T extends Row>(row: T) => boolean,
  /**
   * Optional object to configure tooltip titles on checkboxes
   */
  checboxTooltipTitles?: CheckboxesTooltips
) => {
  const multipleSelectWithDisabledRows = <T extends Record<string, any> = Record<string, any>>(hooks: Hooks<T>) => {
    hooks.visibleColumns.push(
      (columns: Column[]) =>
        [
          getMultipleSelectColumnWithDisabledRows(
            ActionRenderer,
            isRowDisabled,
            isRowSelected ?? (() => false),
            checboxTooltipTitles ?? {}
          ),
          ...columns
        ] as any
    );
  };

  multipleSelectWithDisabledRows.pluginName = 'useMultipleSelectWithDisabledRows';

  return multipleSelectWithDisabledRows;
};

export default useMultipleSelectWithDisabledRows;
