import { CalendarDate } from 'calendar-date';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

async function expireTransportManager({
  id,
  version,
  expirationDate
}: {
  id: string;
  version: number;
  expirationDate?: CalendarDate;
}) {
  const { data } = await API.transportManager.expireTransportManager(
    id,
    { version, expirationDate },
    {
      ...endpointsConfig.expireTransportManager
    }
  );
  return data;
}

function useExpireTransportManagerMutation() {
  return useMutation(expireTransportManager);
}

export default useExpireTransportManagerMutation;
