import { AccountSnapshot, AccountSnapshotStatus } from '@avispon/user/dist/models';

import { TableButton } from '@libs/common/v2';

import { useUserStatusActionsDefinition } from '@libs/user/hooks';

interface IProps {
  userDetails: AccountSnapshot;
  modalTitle: string;
  modalContentText: string;
  label: string;
  snackBarText: string;
  status: AccountSnapshotStatus;
  onAccept: () => void;
  onCancel?: () => void;
}

function ChangeUserStatusPopUp({
  userDetails,
  modalTitle,
  modalContentText,
  label,
  snackBarText,
  status,
  onAccept,
  onCancel
}: IProps) {
  const { handleChangeUserStatus } = useUserStatusActionsDefinition({
    userDetails,
    refetchUserDetails: onAccept,
    closeContextMenu: onCancel
  });

  return (
    <TableButton
      label={label}
      onClick={() => handleChangeUserStatus(modalTitle, modalContentText, status, snackBarText)}
    />
  );
}

export default ChangeUserStatusPopUp;
