import { useMemo } from 'react';

import { AdditionalFilterEnum, useMultipleSelectColumn, useTableLayoutProps } from '@libs/common/v2';

import { DomainDictionaryEntry, DomainDictionaryEnum, useDomainConfigContext } from '@libs/domain/config';

import { ApplicationToArchiveSelectRowActionRenderer } from '../../../common';
import { useApplicationToArchiveQuery } from '../../api';
import { ApplicationToArchiveInitialParamsEnum } from '../../model';
import useApplicationArchiveColumns from '../columns/useApplicationArchiveColumns';

interface IProps {
  isMassActionVisible: boolean;
  classificationKeyDictionary?: DomainDictionaryEnum;
}

function useApplicationToArchiveTable({ isMassActionVisible, classificationKeyDictionary }: IProps) {
  const { yearFilterContextValues, setYearFilterContextValues } = useDomainConfigContext();
  const { columns, mappedFilterFields, mappedSortFields, initialParamsMapper } = useApplicationArchiveColumns(
    false,
    [
      'number',
      'paperNumber',
      'organizationUnit',
      'categoryKey',
      'typeKey',
      'folderNumber',
      'classificationKey',
      'statusKey',
      'entryDate',
      'entryDate',
      'authorName',
      'subjectName',
      'warning'
    ],
    classificationKeyDictionary
  );

  const globalFilters = [
    {
      type: AdditionalFilterEnum.YEAR_SELECT,
      name: ApplicationToArchiveInitialParamsEnum.YEAR,
      setGlobalYearSelect: setYearFilterContextValues
    }
  ];

  const multipleSelectColumn = useMultipleSelectColumn(ApplicationToArchiveSelectRowActionRenderer);

  const tablePlugins = useMemo(
    () => (isMassActionVisible ? [multipleSelectColumn] : []),
    [isMassActionVisible, multipleSelectColumn]
  );

  return useTableLayoutProps({
    tableHookQuery: useApplicationToArchiveQuery,
    tableHookOptions: {
      columns,
      tablePlugins,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      initialParamsConverter: initialParamsMapper,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'classificationKey', desc: true }],
          filters: [
            {
              id: 'statusKey',
              value: [
                { value: DomainDictionaryEntry.APPLICATION_STATUS.WITHDRAWN },
                { value: DomainDictionaryEntry.APPLICATION_STATUS.CANCELLED },
                { value: DomainDictionaryEntry.APPLICATION_STATUS.NOT_CONSIDERED },
                { value: DomainDictionaryEntry.APPLICATION_STATUS.ACCOMPLISHED_WITHOUT_PRINTING },
                { value: DomainDictionaryEntry.APPLICATION_STATUS.ACCOMPLISHED },
                { value: DomainDictionaryEntry.APPLICATION_STATUS.ISSUED },
                { value: DomainDictionaryEntry.APPLICATION_STATUS.ISSUED_FOREIGN_PERMISSION }
              ]
            }
          ]
        }
      }
    },
    globalFilters,
    tableHookQueryInitialParams: {
      [ApplicationToArchiveInitialParamsEnum.YEAR]: yearFilterContextValues,
      archived: false
    },
    sectionClassName: 'mx-10'
  });
}

export default useApplicationToArchiveTable;
