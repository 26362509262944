import { useEffect, useState } from 'react';
import { Column, OnFilterChange } from 'react-table';
import { DictionaryEntryDetailsLanguage } from '@avispon/dictionary';
import { uniqBy } from 'lodash';

import {
  NUMBER_OF_AUTOCOMPLETE_RESULTS,
  SEARCH_TEXT_DEBOUNCE_TIME,
  SelectFilter,
  SelectOption,
  useDebounceValue,
  useDictionaryQuery
} from '@libs/common/v2';

import { DictionaryEntryNameStatusEnum, DictionaryQuickChangeableEntryNameEnum } from '@libs/dictionary';

interface IProps {
  column: Column & { filterValue?: string; setFilter: (value: string | Array<string>) => void };
  isMultiple?: boolean;
  parentKey?: string;
  dictionary: DictionaryQuickChangeableEntryNameEnum;
  onFilterChange: OnFilterChange;
}

function QuickChangeableDictionaryFilter({ parentKey = null, dictionary, column, ...forwardProps }: IProps) {
  const [inputValue, setInputValue] = useState('');
  const [inputFocused, setInputFocused] = useState(false);

  const [options, setOptions] = useState<SelectOption[]>([]);

  const debouncedInputText = useDebounceValue(inputValue, SEARCH_TEXT_DEBOUNCE_TIME);

  const { isLoading, data } = useDictionaryQuery(
    {
      page: 0,
      size: NUMBER_OF_AUTOCOMPLETE_RESULTS,
      language: DictionaryEntryDetailsLanguage.pl,
      status: DictionaryEntryNameStatusEnum.ALL,
      valueFragment: [debouncedInputText.trim()],
      dictionary,
      sort: [],
      ...(parentKey ? { parentKeys: [parentKey] } : {}),
      ...(debouncedInputText && debouncedInputText.trim() !== '' ? { sortBySimilarity: true } : {})
    },
    { enabled: inputFocused }
  );

  useEffect(() => {
    if (data) {
      const newOptions = uniqBy<SelectOption>(
        [...data.content.map(entry => ({ value: entry.key, name: entry.value }))],
        'value'
      );
      setOptions(newOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentKey, data]);

  return (
    <SelectFilter
      options={options}
      isLoading={isLoading}
      onInputChange={setInputValue}
      onFocus={() => setInputFocused(true)}
      onBlur={() => setInputFocused(false)}
      column={column}
      {...forwardProps}
    />
  );
}

export default QuickChangeableDictionaryFilter;
