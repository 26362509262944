import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import { Button, FormMode } from '@libs/common/v2';

import { AddReplacementDialog } from '@libs/user/components';
import { replacementsFormInitialValues } from '@libs/user/models';

interface IProps {
  formMode: FormMode;
}

function ReplacementsHeaderActions({ formMode }: IProps) {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOpen = useCallback(() => setIsOpen(true), []);

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="outlined"
        label={t('user:replacements.action.addReplacement')}
        actionKey={UIElementNameEnum.PROFILE_REPLACEMENT_ADD_BUTTON}
        isSecondary
      />
      {isOpen && (
        <AddReplacementDialog
          formMode={formMode}
          original={replacementsFormInitialValues}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
}

export default ReplacementsHeaderActions;
