import { DefaultRedirect } from '@libs/config';

import { IConfig, IRedirectPaths, IRoute } from '@libs/common/v2/router/RouterContext';
import { generateRoutesFromConfigs } from '@libs/common/v2/utils';

const useRouterConfig = (pagesConfig: IConfig[], redirectConfig: IRedirectPaths): { routes: IRoute[] } => {
  const routes: IRoute[] = [
    ...generateRoutesFromConfigs([...pagesConfig]),
    {
      path: '*',
      element: <DefaultRedirect redirectConfig={redirectConfig} />
    }
  ];
  return { routes };
};

export default useRouterConfig;
