import { useTableLayoutProps } from '@libs/common/v2';

import { ExploitationBaseInspectionSnapshot } from '@libs/domain/exploitation-base';

import useBaseInspectionTableColumns from './useBaseInspectionTableColumns';

function useBaseInspectionTable(data: ExploitationBaseInspectionSnapshot[]) {
  const { columns } = useBaseInspectionTableColumns();
  return useTableLayoutProps({
    tableQuery: data,
    tableHookOptions: { columns }
  });
}

export default useBaseInspectionTable;
