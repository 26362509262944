import { SCertificatesSearchFilter } from '@ibtm/domain';

import { ColumnTypesEnum, SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';

import { DomainDictionaryEnum, DomainQuickchangeableDictionaryEnum } from '@libs/domain/config';
import { SCertificatesSnapshot } from '@libs/domain/s-certificates';

function useCertificatesColumns() {
  const { createColumns } = useCreateColumns<SCertificatesSnapshot>({ pathToTranslate: 'sCertificates:field' });

  const mappedFilterFields: TableFilterMapper<SCertificatesSnapshot, SCertificatesSearchFilter> = {
    statusKey: (statuses: SelectOption<string>[]) => ({
      statusFilter: { values: statuses.map(({ value }) => value), exclude: false }
    }),
    vehiclePlateNumber: (plateNumber: string) => ({ vehiclePlateNumberContains: plateNumber }),
    number: (certificateSNumber: string) => ({ numberContains: certificateSNumber }),
    vehicleBrandKey: (brandKey: SelectOption<string>) => ({
      vehicleBrandKeyIn: [brandKey?.value]
    }),
    euroClassKey: (euroClassKeys: SelectOption<string>[]) => ({
      euroClassKeyIn: euroClassKeys.map(({ value }) => value)
    }),
    vehicleType: (typeKey: SelectOption<string>) => ({ vehicleTypeIn: [typeKey?.value] }),
    applicationNumber: (applicationNumberContains: string) => ({ applicationNumberContains })
  };

  const mappedSortFields: TableSortMapper<SCertificatesSnapshot> = {
    applicationNumber: 'application.number',
    vehicleBrandKey: 'vehicle.brandKey',
    vehicleType: 'vehicle.typeKey',
    vehiclePlateNumber: 'vehicle.plateNumber',
    euroClassKey: 'vehicle.euroClassKey'
  };

  const columns = createColumns([
    {
      accessor: 'statusKey',
      header: 'status',
      type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
      dictionaryName: DomainDictionaryEnum.CERTIFICATE_S_STATUS
    },
    {
      accessor: 'vehiclePlateNumber',
      header: 'vehicleRegistrationNumber',
      type: ColumnTypesEnum.TEXT
    },
    {
      accessor: 'number',
      type: ColumnTypesEnum.TEXT
    },
    {
      id: 'vehicleBrandKey',
      header: 'vehicleBrand',
      accessor: 'vehicleBrandKeyTranslation',
      type: ColumnTypesEnum.QUICK_DICTIONARY_SINGLE_SELECT,
      quickDictionaryName: DomainQuickchangeableDictionaryEnum.VEHICLE_BRAND
    },
    {
      accessor: 'euroClassKey',
      header: 'euro',
      dictionaryName: DomainDictionaryEnum.EURO_CLASS,
      type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT
    },
    {
      accessor: 'vehicleType',
      type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
      dictionaryName: DomainDictionaryEnum.VEHICLE_TYPE
    },
    {
      accessor: 'applicationNumber',
      type: ColumnTypesEnum.TEXT
    }
  ]);

  return { columns, mappedFilterFields, mappedSortFields };
}

export default useCertificatesColumns;
