import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { InvoiceCreateRequest, InvoiceDetails, InvoiceUpdateRequest } from '@ibtm/domain';
import moment from 'moment';

import { FormMode, FormV2Context, Page, useRouter } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';
import PageHeader from '@libs/common/v2/templates/page-header/PageHeader';

import {
  useCreateCorrectionMutation,
  usePatchInvoiceRecipient,
  useReleaseDocumentsDetailsQuery,
  useSuspensionCreateCorrectionMutation,
  useSuspensionReleaseDetailsQuery,
  useSuspensionUpdateCorrectionMutation
} from '../api';
import ReleaseDocumentsDetailsSections from '../components/common/ReleaseDocumentsDetailsSections';
import FormHeaderActions from '../form/components/FormHeaderActions';
import { useReleaseDocumentsValidationSchema } from '../hooks/useReleaseDocumentsValidationSchema';
import { ReleaseDocumentsDetailsPageParams } from '../model';

import { ChangeReturnModeContext } from './ReleaseDocumentsWrapper';

interface ReleaseDocumentsDetails {
  formMode: FormMode;
  data?: InvoiceDetails;
  isLoading: boolean;
  headerActions: JSX.Element;
  correctionItemsTable: any[];
  setCorrectionItemsTable: (table: any[]) => void;
  baseUrl?: string;
}

function ReleaseDocumentsDetailsPage({
  formMode,
  data,
  isLoading,
  headerActions,
  correctionItemsTable,
  setCorrectionItemsTable,
  baseUrl
}: ReleaseDocumentsDetails) {
  const [t] = useTranslation();
  const { ValidationSchemaCreate, ValidationSchemaEdit } = useReleaseDocumentsValidationSchema();
  const { createMode, editMode } = useViewModesV2(formMode);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, submitCount },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    control,
    reset
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(createMode ? ValidationSchemaCreate : ValidationSchemaEdit)
  });
  const isCorrection = watch('isCorrection') as boolean;
  const { reasonKey } = useContext(ChangeReturnModeContext);

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        ...(createMode && {
          recipientName: null,
          zeroing: false,
          recipientSurname: null,
          correctionDateOfIssue: moment().format('YYYY-MM-DD'),
          items: data?.items,
          itemsForCorrection: data?.itemsForCorrection,
          correctionReasonKey: reasonKey || null,
          dateOfIssuePlaceholder: moment().format('YYYY-MM-DD'),
          numberPlaceholder: '-'
        })
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const title = useCallback(() => {
    if (createMode) {
      return t('releaseDocuments:create.title');
    }
    if (editMode) {
      return t('releaseDocuments:edit.title');
    }
    return isCorrection ? t('releaseDocuments:details.titleCorrection') : t('releaseDocuments:details.title');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createMode, editMode, isCorrection]);

  const formDataProvider = useMemo(() => {
    return {
      control,
      errors,
      register,
      setValue,
      handleSubmit,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      formMode,
      submitCount,
      loading: isLoading
    };
  }, [
    control,
    errors,
    formMode,
    getValues,
    handleSubmit,
    isLoading,
    isSubmitting,
    register,
    setValue,
    submitCount,
    trigger,
    unregister,
    watch
  ]);

  return (
    <FormV2Context.Provider value={formDataProvider}>
      <Page
        header={<PageHeader title={title()} rightSideContent={headerActions} />}
        content={
          <ReleaseDocumentsDetailsSections
            baseUrl={baseUrl}
            correctionItemsTable={correctionItemsTable}
            setCorrectionItemsTable={setCorrectionItemsTable}
            isDataLoading={isLoading}
          />
        }
      />
    </FormV2Context.Provider>
  );
}

export default function ReleaseDocumentsDetailsWrapper() {
  const { id, formMode, folderId, releaseDocumentId } = useParams<ReleaseDocumentsDetailsPageParams>();

  const { data, isLoading } = useReleaseDocumentsDetailsQuery(id, { enabled: !folderId });
  const { data: suspensionInvoiceData, isLoading: suspensionInvoiceiIsLoading } = useSuspensionReleaseDetailsQuery(
    { suspensionId: id, invoiceId: releaseDocumentId },
    {
      enabled: !!folderId
    }
  );

  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();

  const { mutate: createCorrection, isLoading: createLoading } = useCreateCorrectionMutation();
  const { mutate: patchInvoiceRecipient, isLoading: updateLoading } = usePatchInvoiceRecipient();

  const { mutate: createSuspensionCorrection, isLoading: createSuspensionLoading } =
    useSuspensionCreateCorrectionMutation();
  const { mutate: updateSuspensionCorrection, isLoading: updateSuspensionLoading } =
    useSuspensionUpdateCorrectionMutation();

  const [correctionItemsTable, setCorrectionItemsTable] = useState([]);

  const baseUrl = !folderId
    ? '/release-documents/'
    : `/folders/${folderId}/details/suspensions/${id}/releaseDocuments/`;

  const { routes, goToPage } = useRouter();

  return (
    <ReleaseDocumentsDetailsPage
      formMode={formMode}
      correctionItemsTable={correctionItemsTable}
      setCorrectionItemsTable={setCorrectionItemsTable}
      baseUrl={baseUrl}
      data={folderId ? suspensionInvoiceData : data}
      isLoading={folderId ? suspensionInvoiceiIsLoading : isLoading}
      headerActions={
        <FormHeaderActions
          id={folderId ? suspensionInvoiceData?.id : data?.id}
          correctionItemsTable={correctionItemsTable}
          createCorrection={
            folderId
              ? (params: InvoiceCreateRequest, config) => {
                  createSuspensionCorrection({ ...params, suspensionId: id }, config);
                }
              : createCorrection
          }
          updateSuspensCorrection={(params: InvoiceUpdateRequest & { invoiceId: string; suspensionId: string }) => {
            updateSuspensionCorrection(params, {
              onSuccess: () => {
                showSuccessSnackbar(t('releaseDocuments:message.updated'));
                goToPage(routes.suspensionsList());
              }
            });
          }}
          updateCorrection={patchInvoiceRecipient}
          createLoading={folderId ? createSuspensionLoading : createLoading}
          updateLoading={folderId ? updateSuspensionLoading : updateLoading}
          baseUrl={baseUrl}
          suspensionUrl={folderId ? routes.suspensionReleaseDocumentsTab(id, folderId) : null}
          documentNumber={data?.number}
        />
      }
    />
  );
}
