import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const sendToForeignPermissionsDepartment = (applicationId: string) => {
  return API.application.spoCompleted(applicationId, { ...endpointsConfig.spoCompleted });
};

const useSendToForeignPermissionsDepartmentMutation = () => {
  return useMutation(sendToForeignPermissionsDepartment);
};

export default useSendToForeignPermissionsDepartmentMutation;
