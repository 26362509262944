import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { RuleItemCodes } from '@ibtm/domain';
import { isUndefined } from 'lodash';
import { array as YupArray, number as YupNumber, object as YupObject } from 'yup';

import { FormMode, FormV2Context, LoaderCircular, Typography } from '@libs/common/v2';

import { useTradeRulesDetailsQuery } from '@libs/domain/social-commission/api';

import TradeRulesPage from './TradeRulesPage';

function TradeRulesPageWrapper({
  selectedRule,
  formMode,
  setFormMode,
  unSelectAll
}: {
  selectedRule: string;
  formMode: FormMode;
  setFormMode: (formMode: FormMode) => void;
  unSelectAll: () => void;
}) {
  const [t] = useTranslation();
  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(
      YupObject({
        items: YupArray().of(
          YupObject({
            decreaseAmount: YupNumber().when('type', {
              is: type => {
                return type === RuleItemCodes.NOT_USED_PERMISSION_RULE;
              },
              then: YupNumber().nullable().required(),
              otherwise: YupNumber().nullable()
            }),
            numberOfMonths: YupNumber().when('type', {
              is: type => {
                return type === RuleItemCodes.NOT_USED_PERMISSION_RULE;
              },
              then: YupNumber().min(1).nullable(),
              otherwise: YupNumber().nullable()
            }),

            from: YupNumber().when('type', {
              is: type => {
                return type === RuleItemCodes.PERMISSION_FOR_VEHICLE_RULE;
              },
              then: YupNumber().nullable().required(),
              otherwise: YupNumber().nullable()
            }),
            amount: YupNumber().when('type', {
              is: type => {
                return type === RuleItemCodes.LIMITED_VALUE_BY_VEHICLES_RULE;
              },
              then: YupNumber().nullable().required(),
              otherwise: YupNumber().nullable()
            }),
            permissionAmount: YupNumber().when('type', {
              is: type => {
                return type === RuleItemCodes.PERMISSION_FOR_VEHICLE_RULE;
              },
              then: YupNumber().nullable().required(),
              otherwise: YupNumber().nullable()
            }),
            vehicleAmount: YupNumber().when('type', {
              is: type => {
                return type === RuleItemCodes.LIMITED_VALUE_BY_VEHICLES_RULE;
              },
              then: YupNumber().nullable().required(),
              otherwise: YupNumber().nullable()
            }),

            maxLimit: YupNumber().when('type', {
              is: type => {
                return type === RuleItemCodes.LIMITED_VALUE_RULE;
              },
              then: YupNumber().nullable().required(),
              otherwise: YupNumber().nullable()
            })
          })
        )
      })
    )
  });

  useEffect(() => {
    form.reset({ items: [] });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, isLoading } = useTradeRulesDetailsQuery(selectedRule, { enabled: !!selectedRule });

  useEffect(() => {
    if (data) {
      const preparedData = {
        ...data,
        items: data.items.map(item => ({
          ...item.ruleConfiguration,
          id: item.id
        }))
      };
      form.reset(preparedData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setFormMode(FormMode.VIEW);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRule]);

  const move = (array: unknown[], oldIndex: number, newIndex: number): unknown[] => {
    const newArray = [...array];
    const [removedElement] = newArray.splice(oldIndex, 1);
    newArray.splice(newIndex, 0, removedElement);
    return newArray;
  };

  const moveUp = (index: number) => {
    form.setValue('items', move(form.getValues().items, index, index - 1));
  };
  const moveDown = (index: number) => {
    form.setValue('items', move(form.getValues().items, index, index + 1));
  };
  const setCancel = () => {
    setFormMode(FormMode.VIEW);
    if (data) {
      const preparedData = {
        ...data,
        items: data.items.map(item => ({
          ...item.ruleConfiguration,
          id: item.id
        }))
      };
      form.reset(preparedData);
    }
  };
  const setEditMode = () => {
    setFormMode(FormMode.EDIT);
  };

  const contextValue = useMemo(() => ({ ...form, formMode }), [form, formMode]);

  const setViewMode = () => {
    setFormMode(FormMode.VIEW);
  };

  return (
    <FormV2Context.Provider value={contextValue}>
      {isLoading && (
        <RenderCentered>
          <LoaderCircular isLoading isAbsolute={false} />
        </RenderCentered>
      )}
      {!isUndefined(data) && !isLoading ? (
        <TradeRulesPage
          formMode={formMode}
          moveUp={moveUp}
          moveDown={moveDown}
          setCancel={setCancel}
          setEditMode={setEditMode}
          setViewMode={setViewMode}
          unSelectAll={unSelectAll}
        />
      ) : null}
      {!selectedRule && (
        <RenderCentered>
          <Typography themeVariant="textSm.medium">
            {t('foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.messages.noSelectedRule')}
          </Typography>
        </RenderCentered>
      )}
    </FormV2Context.Provider>
  );
}

function RenderCentered({ children }) {
  return (
    <div className="mt-40 h-full" style={{ display: 'flex', justifyContent: 'space-around' }}>
      {children}
    </div>
  );
}

export default TradeRulesPageWrapper;
