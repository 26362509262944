import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { FolderCreateRequest } from '@ibtm/domain';

import { Button, typedNameV2, useFormV2Context } from '@libs/common/v2';

import { RegonDataModal } from '@libs/domain/subject';

function RegonButtonSkeleton() {
  const [t] = useTranslation();
  const { showErrorSnackbar } = useSnackbar();
  const [regonModalVisible, setRegonModalVisible] = useState<boolean>(false);

  const { trigger, reset, getValues } = useFormV2Context();
  const values = getValues();

  const handleButtonClick = () => {
    trigger(typedNameV2<FolderCreateRequest>('subject.nip')).then(isValid => {
      if (isValid) {
        setRegonModalVisible(true);
      } else {
        showErrorSnackbar(t('subject:messages.invalidNip'));
      }
    });
  };
  return (
    <>
      <Button
        type="button"
        label={t('folder:details.button.checkInRegonData')}
        variant="outlined"
        isPrimary
        onClick={handleButtonClick}
      />
      {regonModalVisible && (
        <RegonDataModal
          open={regonModalVisible}
          setIsOpen={setRegonModalVisible}
          onFolderCreation
          isViewOnly
          folderCreateValues={values}
          resetForm={reset}
        />
      )}
    </>
  );
}

export default RegonButtonSkeleton;
