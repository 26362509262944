import { useParams } from 'react-router-dom';

import { FormMode, useFormV2Context } from '@libs/common/v2';

import { DomainUIElementEnum } from '@libs/domain/config';
import { SimpleNoteSection } from '@libs/domain/notes';
import { ProceedingDetailsParams } from '@libs/domain/proceeding/model';

interface IProps {
  formMode: FormMode;
}

function NoteTab({ formMode }: IProps) {
  const { id } = useParams<ProceedingDetailsParams>();
  const { setValue } = useFormV2Context();

  const onNoteOrVersionChange = (note: string, version: number, noteId: string) => {
    setValue('noteContent', note);
    setValue('noteVersion', version);
    setValue('noteId', noteId);
  };

  return (
    <SimpleNoteSection
      formMode={formMode}
      proceedingId={id}
      onNoteOrVersionChange={onNoteOrVersionChange}
      cancelActionKey={DomainUIElementEnum.PROCEEDING_NOTE_CANCEL_BUTTON}
    />
  );
}

export default NoteTab;
