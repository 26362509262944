import { useTranslation } from 'react-i18next';

import { Dialog } from '@libs/common/v2';

import MoneyTransfersSearchTable from './MoneyTransfersSearchTable';

interface IProps {
  applicationId: string;
  onCloseDialog: () => void;
  onSuccess: () => void;
  isOpen: boolean;
}

function MoneyTransferAddToApplicationDialog({ applicationId, onCloseDialog, onSuccess, isOpen }: IProps) {
  const [t] = useTranslation();

  return (
    <Dialog
      title={t('pricing:moneyTransfers.action.search.dialogTitle')}
      cancelText={t('action.close')}
      onCancel={onCloseDialog}
      dialogSize="large"
      isScrollbarEnabled={false}
      isOpen={isOpen}
    >
      <MoneyTransfersSearchTable applicationId={applicationId} isSection onSuccess={onSuccess} />
    </Dialog>
  );
}

export default MoneyTransferAddToApplicationDialog;
