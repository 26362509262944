import { AxiosRequestConfig } from 'axios';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '..';

interface IParams {
  documentTemplateId: string;
  templateFileId: string;
  body: {
    version: number;
    status: string;
    name: string;
  };
}

const editDocumentTemplateFile = async ({ documentTemplateId, templateFileId, body }: IParams) => {
  const { data } = await API.documentGenerator.updateTemplateFile({ documentTemplateId, templateFileId, body }, {
    ...endpointsConfig.updateTemplateFile
  } as AxiosRequestConfig);
  return data;
};

export default function useEditDocumentTemplateFileMutation() {
  return useMutation(editDocumentTemplateFile);
}
