import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '@enigma/fe-ui';
import { ApplicationSnapshot, SubjectSnapshotExtended } from '@ibtm/domain';
import _ from 'lodash';

import { FormMode, typedNameV2, useFormV2Context, useRouter, useTableRowActions } from '@libs/common/v2';

import {
  ApplicationsTable,
  statusesWithBlockedEdit,
  useApplicationClientPortalVisibleColumns
} from '@libs/domain/application';
import { DomainDictionaryEntry, DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import { FolderDetailsClient, FolderDetailsParams } from '@libs/domain/folder';

import { useShowShortageDialog } from '../../../../../application/hooks';
import { getDriversClient } from '../../../../../drivers/api';
import { useDriverDialog } from '../../../../../drivers/hooks';

function ApplicationsTab() {
  const [t] = useTranslation();
  const { routes } = useRouter();
  const { folderId } = useParams<FolderDetailsParams>();
  const { open } = useDriverDialog();
  const { watch } = useFormV2Context();
  const { showErrorSnackbar } = useSnackbar();
  const { isClientPortal, isOperatorPortal } = useDomainConfigContext();
  const { showShortageDialog } = useShowShortageDialog();

  const [areDriversLoading, setAreDriversLoading] = useState<boolean>(false);

  const partnerships = watch(
    typedNameV2<FolderDetailsClient>('subject.partnerships')
  ) as Array<SubjectSnapshotExtended>;

  const showDriver = async (applicationId: string) => {
    setAreDriversLoading(true);
    const { content } = await getDriversClient(null, { applicationIdIn: [applicationId], includeCopies: true });
    setAreDriversLoading(false);
    if (_.isEmpty(content)) {
      showErrorSnackbar(t('applications:message.noDriverAdded'));
      return;
    }
    open({
      data: {
        driverId: content[0].id
      },
      formMode: FormMode.VIEW
    });
  };

  const { renderTableActions } = useTableRowActions<ApplicationSnapshot>(
    [
      {
        id: DomainUIElementEnum.FOLDER_APPLICATION_DETAILS_BUTTON,
        label: 'applications:action.openApplication',
        link: ({ original }) => routes.folderApplicationDetails(original.id, original.folder.id),
        icon: 'ArrowIcon',
        isHidden: isClientPortal
      },
      {
        id: DomainUIElementEnum.FOLDER_APPLICATION_SHOW_SHORTAGE,
        icon: 'ArrowIcon',
        onClick: ({ row }) => {
          showShortageDialog(row.original.id);
        },
        label: 'applications:action.showShortage',
        isHidden: ({ row }) => {
          return isOperatorPortal || row.original.statusKey !== DomainDictionaryEntry.APPLICATION_STATUS.INCOMPLETE;
        }
      },
      {
        id: DomainUIElementEnum.FOLDER_APPLICATION_EDIT_BUTTON,
        label: 'applications:action.editApplication',
        link: ({ original }) =>
          isClientPortal
            ? routes.applicationClientEdit(original.id, original.folder.id)
            : routes.folderApplicationEdit(original.id, original.folder.id),
        icon: 'EditIcon',
        isHidden: ({ row: { original } }) =>
          isClientPortal ? original?.statusKey !== DomainDictionaryEntry.APPLICATION_STATUS.DRAFT : false,
        isDisabled: ({ row: { original } }) => statusesWithBlockedEdit.some(status => status === original?.statusKey),
        tooltip: ({ original }) =>
          statusesWithBlockedEdit.some(status => status === original?.statusKey)
            ? 'applications:tooltip.editBlockedByStatus'
            : undefined
      },
      {
        id: DomainUIElementEnum.FOLDER_APPLICATION_SHOW_DRIVER_DETAILS,
        label: 'applications:action.showDriverDetails',
        icon: 'DriversIcon',
        onClick: ({ row }) => {
          showDriver(row.original.id);
        },
        isLoading: areDriversLoading,
        isHidden: ({ row }) => {
          return (
            isOperatorPortal ||
            row.original.categoryKey !== DomainDictionaryEntry.APPLICATION_CATEGORY.DRIVER_CERTIFICATE ||
            row.original.statusKey === DomainDictionaryEntry.APPLICATION_STATUS.DRAFT
          );
        }
      }
    ],
    [areDriversLoading, isOperatorPortal]
  );
  const clientPortalColumns = useApplicationClientPortalVisibleColumns();

  return (
    <div>
      <ApplicationsTable
        title={t('folder:tab.applications')}
        queryParams={{
          folderIdIn: partnerships
            ? [folderId, ...(partnerships || []).map(partnership => partnership?.folder?.id)]
            : [folderId],
          includeArchived: true
        }}
        tableOptions={{
          initialState: {
            sortBy: [{ id: 'submissionReceiptDate', desc: true }]
          }
        }}
        rowActions={renderTableActions([
          DomainUIElementEnum.FOLDER_APPLICATION_EDIT_BUTTON,
          DomainUIElementEnum.FOLDER_APPLICATION_DETAILS_BUTTON,
          DomainUIElementEnum.FOLDER_APPLICATION_SHOW_SHORTAGE,
          DomainUIElementEnum.FOLDER_APPLICATION_SHOW_DRIVER_DETAILS
        ])}
        visibleColumns={isClientPortal ? clientPortalColumns : undefined}
        headerActions={null}
        isCollapsable
        isSection
      />
    </div>
  );
}

export default ApplicationsTab;
