import { ResourceAssignmentPermissionCopyUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function updatePermissionCopyMutation(formData: ResourceAssignmentPermissionCopyUpdateRequest) {
  return API.printouts.updateResourceAssignmentPermissionCopy(formData, {
    ...endpointsConfig.updateResourceAssignmentPermissionCopy
  });
}

function useUpdatePermissionCopyMutation() {
  return useMutation(updatePermissionCopyMutation);
}

export default useUpdatePermissionCopyMutation;
