import { Context, useContext, useEffect } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';

import {
  BooleanValue,
  Checkbox,
  ComponentErrorBoundary,
  FormErrorType,
  FormV2Context,
  FormV2ContextState,
  getInputMode,
  InputMode,
  useFormV2Watch
} from '@libs/common/v2';

import { BooleanFieldValidation, FieldTypeEnum, useFieldValidationHandler } from '../../validation';

export interface ICheckboxProps {
  isDefaultChecked?: boolean;
  name: FieldPath<FieldValues>;
  label?: string;
  className?: string;
  valueClassName?: string;
  valueTrueText?: string;
  valueFalseText?: string;
  formContext?: Context<FormV2ContextState>;
  inputMode?: InputMode;
  isRequired?: boolean;
  isDisabled?: ((value: unknown) => boolean) | boolean;
  value?: unknown;
  validation?: BooleanFieldValidation;
  rowLayout?: boolean;
  checkboxClassName?: string;
  wrapperClassName?: string;
  /**
   * Props wykorzystywany przy tablicy checkboxów
   */
  resolveOnEvent?: boolean;
  getOnChangeValues?: () => string[];
}

function CheckboxField({
  name,
  label,
  formContext = FormV2Context,
  className,
  valueClassName,
  valueTrueText,
  value: inputValue,
  valueFalseText,
  isDefaultChecked,
  inputMode,
  validation,
  isDisabled,
  rowLayout,
  isRequired,
  checkboxClassName,
  wrapperClassName,
  resolveOnEvent,
  getOnChangeValues
}: ICheckboxProps) {
  const { control, formMode, loading, setValue, isSubmitting } = useContext<FormV2ContextState>(formContext);
  const mode = getInputMode(formMode, inputMode);
  const value = useFormV2Watch({ name });

  useEffect(() => {
    if (isDefaultChecked) {
      setValue(name, inputValue || isDefaultChecked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checked = () => {
    if (typeof inputValue === 'string') {
      return inputValue === value;
    }
    if (Array.isArray(inputValue)) {
      return inputValue.every(item => value?.includes(item));
    }

    return inputValue === value;
  };

  const handleOnChange = (e, onChange, value, inputValueChecked) => {
    if (resolveOnEvent) {
      return onChange(e.target.checked ? inputValue : undefined);
    }
    if (!inputValueChecked && inputValue) {
      const filteredValue = Array.isArray(inputValue) ? value.filter(item => !inputValue.includes(item)) : inputValue;
      return onChange(filteredValue);
    }
    if (inputValue) {
      const newValue = value ? [...value, ...(inputValue as any)] : inputValue;
      return Array.isArray(inputValue) ? onChange(newValue) : onChange(inputValue);
    }
    return onChange(inputValueChecked);
  };

  useFieldValidationHandler({
    fieldName: name,
    validation,
    fieldType: FieldTypeEnum.BOOLEAN
  });

  return (
    <ComponentErrorBoundary componentName={label || name || 'CheckboxField'}>
      <Controller
        control={control}
        name={name as FieldPath<FieldValues>}
        render={({ field: { onChange, onBlur }, fieldState: { error } }) =>
          mode === InputMode.FORM ? (
            <Checkbox
              isChecked={inputValue ? checked() : !!value}
              onBlur={onBlur}
              onChange={(e, inputChecked) => {
                if (getOnChangeValues) {
                  const results = getOnChangeValues();
                  onChange(results);
                } else {
                  handleOnChange(e, onChange, value, inputChecked);
                }
              }}
              label={label}
              value={inputValue}
              isLoading={loading}
              isRequired={isRequired || Boolean(validation?.required)}
              isDisabled={typeof isDisabled === 'function' ? isDisabled(inputValue) : !!isDisabled || isSubmitting}
              isError={!!error && error?.type !== FormErrorType.WARNING}
              isWarning={error?.type === FormErrorType.WARNING}
              helperText={error?.message}
              className={className}
              rowLayout={rowLayout}
              checkboxClassName={checkboxClassName}
              wrapperClassName={wrapperClassName}
            />
          ) : (
            <BooleanValue
              label={label}
              value={inputValue ? checked() : !!value}
              isLoading={loading}
              className={valueClassName}
              valueFalseText={valueFalseText}
              valueTrueText={valueTrueText}
              isError={!!error && error?.type !== FormErrorType.WARNING}
              isWarning={error?.type === FormErrorType.WARNING}
              helperText={error?.message}
            />
          )
        }
      />
    </ComponentErrorBoundary>
  );
}

export default CheckboxField;
