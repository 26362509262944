import { InvoiceItemDetails } from '@ibtm/domain';

import { ColumnTypesEnum, useCreateColumns } from '@libs/common/v2';

import { DomainDictionaryEnum } from '../../config';
import { ReturnItemRange } from '../model/ReturnModels';

export default function useReturnTablesColumns() {
  const pathToTranslate = 'releaseDocuments:return.columns';
  const { createColumns: itemsCreateColumns } = useCreateColumns<InvoiceItemDetails>({
    pathToTranslate
  });
  const { createColumns: returnItemsCreateColumns } = useCreateColumns<ReturnItemRange & { sum: number }>({
    pathToTranslate
  });
  const commonsConfig = {
    isFilterable: false,
    isSortable: false,
    type: ColumnTypesEnum.NUMBER,
    width: 80
  };

  const itemsColumns = itemsCreateColumns([
    {
      id: 'name',
      type: ColumnTypesEnum.TEXT,
      isFilterable: false,
      isSortable: false,
      width: 250
    },
    {
      id: 'quantity',
      header: 'quantity',
      ...commonsConfig
    }
  ]);
  const returnItemsColumns = returnItemsCreateColumns([
    {
      id: 'name',
      type: ColumnTypesEnum.TEXT,
      isFilterable: false,
      isSortable: false,
      width: 150
    },
    {
      id: 'reasonKey',
      type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
      dictionaryName: DomainDictionaryEnum.INVOICE_CORRECTION_REASON,
      isFilterable: false,
      isSortable: false,
      width: 200
    },
    {
      id: 'from',
      header: 'numberFrom',
      ...commonsConfig
    },
    {
      id: 'to',
      header: 'numberTo',
      ...commonsConfig
    },
    {
      id: 'sum',
      header: 'quantity',
      customAccessor: ({ from, to }) => to - from + 1,
      ...commonsConfig
    }
  ]);

  return { itemsColumns, returnItemsColumns };
}
