import React, { ReactNode } from 'react';
import { makeStyles } from '@mui/styles';

import { Theme } from '@libs/common/v2/theme';

interface IProps {
  children: React.ReactElement<any, any>[] | ReactNode;
  gutter?: number;
  isFullWidth?: boolean;
  hasExtraBottomMargin?: boolean;
}

function Button({ children, gutter, isFullWidth, hasExtraBottomMargin }: IProps) {
  const classes = useStyles({ gutter, isFullWidth, hasExtraBottomMargin });

  return <div className={classes.group}>{children}</div>;
}

const useStyles = makeStyles<Theme, Omit<IProps, 'children'>>(() => ({
  group: ({ gutter, isFullWidth, hasExtraBottomMargin }) => ({
    width: isFullWidth ? '100%' : 'unset',
    marginBottom: hasExtraBottomMargin ? '3rem' : 'unset',
    display: 'flex',
    alignItems: 'center',
    gap: gutter ? `${gutter}px` : '16px',
    '&>*': {
      margin: '0 !important',
      padding: '0 !important'
    },
    '&>*>*': {
      margin: '0 !important',
      padding: '0 !important'
    },
    '&>*>button': {
      margin: '0 !important',
      padding: '0 !important'
    }
  })
}));

export default Button;
