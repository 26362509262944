import { QueryConfig, useQuery } from 'react-query';
import { UserTaskDetails } from '@ibtm/process-api';

import { API } from '@libs/domain/api';
import { ApplicationQueryKeysEnum } from '@libs/domain/application';

const getTask = async (id: string) => {
  const { data } = await API.task.details(id);
  return data;
};

export default function useTaskDetailsQuery(id: string, queryConfig: QueryConfig<UserTaskDetails, unknown> = {}) {
  return useQuery<UserTaskDetails>([ApplicationQueryKeysEnum.TASK, id], () => getTask(id), queryConfig);
}
