import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import {
  CivicBoardRuleCreateRequest,
  CivicBoardRuleDetailsAllOf,
  CivicBoardRuleResourceTypeDetails
} from '@ibtm/domain';
import { uniqueId } from 'lodash';
import * as Yup from 'yup';

import {
  ForwardTableState,
  SelectOption,
  TableButtonAddNewStartingRow,
  TableButtonDelete,
  TableEditRow,
  TableLayout,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';
import { OnRowEditContextProps } from '@libs/common/v2/templates/table-layout/TableWithoutHook';

import {
  AdditionalResourceTypesForm,
  useAdditionalResourceTypesColumns
} from '@libs/domain/social-commission/hooks/columns/useAdditionalResourceTypesColumns';

function AdditionalResourceTypesForBasicRulesTable() {
  const [t] = useTranslation();
  const { setValue, watch, formMode } = useFormV2Context();
  const { createMode, viewMode } = useViewModesV2(formMode);
  const myRef = useRef(null);
  const mainResourceTypeId = watch(
    typedNameV2<CivicBoardRuleCreateRequest>('mainResourceTypeId')
  ) as SelectOption<string> & {
    countryKey?: string;
    year?: number;
  };
  useEffect(() => {
    myRef?.current?.cancelEditRow();
  }, [mainResourceTypeId]);

  const countryCodeKey = watch(typedNameV2<CivicBoardRuleDetailsAllOf>('countryCodeKey')) as SelectOption<string>;
  const year = watch(typedNameV2<CivicBoardRuleDetailsAllOf>('year')) as number;

  const validationSchema = Yup.object({
    resourceType: Yup.object({
      nameKey: Yup.string().required()
    }).required()
  });

  const types = watch(typedNameV2<CivicBoardRuleDetailsAllOf>('types')) as (CivicBoardRuleResourceTypeDetails & {
    resourceType?: {
      id?: string;
      nameKey?: string | { value?: string };
    };
    resourceFormNameKey?: string;
    resourceTypeId?: string;
  })[];

  const [table, setTable] = useState<AdditionalResourceTypesForm[]>([]);

  const { columns } = useAdditionalResourceTypesColumns(
    createMode
      ? { countryKey: mainResourceTypeId?.countryKey, year: mainResourceTypeId?.year }
      : { countryKey: countryCodeKey?.value, year }
  );

  useEffect(() => {
    if (types?.length > 0) {
      setTable(
        types?.map(item => ({
          ...item,
          id: uniqueId('queried-'),
          resourceType: item.resourceFormNameKey
            ? { nameKey: item.resourceFormNameKey, id: item.resourceTypeId }
            : {
                nameKey:
                  typeof item.resourceType?.nameKey === 'string'
                    ? item.resourceType?.nameKey
                    : item.resourceType?.nameKey?.value,
                id: item.resourceType?.id
              },
          resourceTypeId: item.resourceTypeId || item.resourceType?.id,
          impactLimit: item.impactLimit
        }))
      );
    }
  }, [types, createMode]);

  const handleEditOrAddRow = (
    row: AdditionalResourceTypesForm,
    context: OnRowEditContextProps<AdditionalResourceTypesForm, AdditionalResourceTypesForm>
  ) => {
    if (context?.isCreated) {
      const newTable = [
        ...table,
        {
          id: uniqueId(),
          resourceTypeId: row.resourceType?.id,
          resourceType: row.resourceType,
          impactLimit: row.impactLimit || false
        }
      ];
      setTable(newTable);
      setValue(typedNameV2<CivicBoardRuleDetailsAllOf>('types'), newTable);
    }
    if (context?.isEdited) {
      const newTable = table.map(item => {
        if (item.id === row?.id) {
          return {
            id: row?.id,
            resourceTypeId: row.resourceType?.id,
            resourceType: {
              nameKey: row.resourceType?.nameKey,
              id: row.resourceType?.id
            },
            impactLimit: row.impactLimit || false
          };
        }
        return item;
      });
      setTable(newTable);
      setValue(typedNameV2<CivicBoardRuleDetailsAllOf>('types'), newTable);
    }
    context?.onSuccess();
  };
  const onDeleteRow = (row: Row<AdditionalResourceTypesForm>) => {
    const newTable = table.filter(item => item.id !== row?.original?.id);
    setTable(newTable);
    setValue(typedNameV2<CivicBoardRuleDetailsAllOf>('types'), newTable);
  };
  const rowActions = (row: Row<AdditionalResourceTypesForm>) =>
    !viewMode ? (
      <>
        <TableEditRow rowId={row?.original?.id} isRenderedAsActionIcon />
        <TableButtonDelete onClick={() => onDeleteRow(row)} />
      </>
    ) : null;

  return (
    <TableLayout
      pageTitle={t(
        'foreignPermits:tabs.socialCommission.tabs.SocialCommissionBasicRules.additionalResourcesTableTitle'
      )}
      tableHookOptions={{
        columns
      }}
      tableQuery={table}
      isFilterEnabled={false}
      isRefreshEnabled={false}
      headerActions={
        !viewMode && (
          <TableButtonAddNewStartingRow
            isDisabled={!mainResourceTypeId && createMode}
            {...(!mainResourceTypeId && createMode
              ? {
                  tooltipTitle: t(
                    'foreignPermits:tabs.socialCommission.tabs.SocialCommissionBasicRules.mainResourceNotSelected'
                  )
                }
              : {})}
          />
        )
      }
      rowActions={rowActions}
      editFormYupResolverSchema={validationSchema}
      onRowEditCreateConfirm={handleEditOrAddRow}
      isTableMutable
      isSection
    >
      <ForwardTableState ref={myRef} />
    </TableLayout>
  );
}

export default AdditionalResourceTypesForBasicRulesTable;
