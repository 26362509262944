import { QueryConfig, useQuery } from 'react-query';
import { PricingDetails } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import { PricingQueryKeysEnum } from './PricingQueryKeysEnum';

export const getApplicationPricing = async (applicationId: string) => {
  const { data } = await API.pricing.getPricingDetails(applicationId, {
    ...endpointsConfig.getPricingDetails
  });
  return data;
};

export const getApplicationPricingClient = async (applicationId: string) => {
  const { data } = await API.client.pricing.getPricingDetails(applicationId, {
    ...endpointsConfig.getPricingDetails
  });

  return data;
};

export default function useApplicationPricingQuery(
  applicationId: string,
  queryConfig?: QueryConfig<PricingDetails, unknown>
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery<PricingDetails>(
    [PricingQueryKeysEnum.APPLICATION_PRICING, applicationId],
    getQuery(
      () => getApplicationPricingClient(applicationId),
      () => getApplicationPricing(applicationId)
    ),
    queryConfig
  );
}
