import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PasswordChangeByOperatorCommand } from '@avispon/user/dist/models';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import {
  Dialog,
  DropdownListButton,
  FormMode,
  FormV2Context,
  GridLayout,
  TableButton,
  TextInputField,
  typedNameV2,
  useIsSmallScreen
} from '@libs/common/v2';

import { ChangePasswordValues, ChangeUserPasswordValidation } from '@libs/user';
import { useChangePasswordMutation } from '@libs/user/api';

const INITIAL_VALUES = {
  newPassword1: '',
  newPassword2: ''
};

interface IProps {
  accountId: string;
  validationChangePasswordScheme: ChangeUserPasswordValidation;
  handleClose: () => void;
  isTableButton?: boolean;
  actionKey?: UIElementNameEnum;
}

function ChangePasswordPopUp({
  accountId,
  validationChangePasswordScheme,
  handleClose,
  isTableButton = true,
  actionKey
}: IProps) {
  const { isSmallScreen } = useIsSmallScreen();
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { showSuccessSnackbar } = useSnackbar();
  const { mutateAsync: changePassword, isLoading } = useChangePasswordMutation();

  const form = useForm<Record<string, any>>({
    defaultValues: INITIAL_VALUES,
    resolver: yupResolver(validationChangePasswordScheme),
    mode: 'all'
  });

  const formDataProvider = useMemo(() => {
    return { ...form, formMode: FormMode.CREATE };
  }, [form]);

  const handlePasswordChange = values => {
    const body: PasswordChangeByOperatorCommand = {
      accountId,
      ...values
    };

    changePassword(body, {
      onSuccess: () => {
        setIsOpen(false);
        handleClose();
        showSuccessSnackbar(t('user:message.passwordChangeSuccess'));
      }
    });
  };

  const handleCancel = () => {
    setIsOpen(false);
    handleClose();
  };

  return (
    <>
      {isOpen && (
        <FormV2Context.Provider value={formDataProvider}>
          <Dialog
            title={t('user:dialog.changePasswordTitle')}
            confirmText={t('action.save')}
            cancelText={t('action.cancel')}
            onConfirm={form.handleSubmit(handlePasswordChange)}
            onCancel={handleCancel}
            dialogSize="small"
            isConfirmLoading={isLoading}
            isConfirmButtonDisabled={isLoading}
            isFullScreen={isSmallScreen}
            isOpen={isOpen}
          >
            <GridLayout itemProps={{ xs: 12 }}>
              <TextInputField
                name={typedNameV2<ChangePasswordValues>('newPassword1')}
                inputProps={{ type: 'password' }}
                label={t('user:field.password')}
                isRequired
              />
              <TextInputField
                name={typedNameV2<ChangePasswordValues>('newPassword2')}
                inputProps={{ type: 'password' }}
                label={t('user:field.repeatPassword')}
                isRequired
              />
            </GridLayout>
          </Dialog>
        </FormV2Context.Provider>
      )}
      {isTableButton ? (
        <TableButton label={t('user:action.changePassword')} onClick={() => setIsOpen(true)} actionKey={actionKey} />
      ) : (
        <DropdownListButton
          label={t('user:action.changePassword')}
          onClick={() => setIsOpen(true)}
          actionKey={actionKey}
        />
      )}
    </>
  );
}

export default ChangePasswordPopUp;
