import { AxiosRequestConfig } from 'axios';
import qs from 'qs';

interface ReactTablePaginationParams {
  pageIndex: number;
  pageSize: number;
  sortBy: any[];
}

enum SortDirectionsEnum {
  DESC = 'DESC',
  ACS = 'ASC'
}

export interface ApiPaginationParams {
  page: number;
  size: number;
  sort: string[];
}

// Funkcja serializująca parametry zapytania - przydatna w zapytania do API
export const paramsSerializer: AxiosRequestConfig['paramsSerializer'] = params =>
  qs.stringify(params, { arrayFormat: 'repeat', encode: false });

// Funkcja konwertująca parametry paginacji z react-table do API
export const adaptPagination = <T>(params: T & ReactTablePaginationParams): ApiPaginationParams => {
  const { pageIndex, pageSize, sortBy, ...forwardParams } = params;
  const sort = (sortBy ?? []).map(
    element => `${element.id},${element.desc ? SortDirectionsEnum.DESC : SortDirectionsEnum.ACS}`
  );
  return { ...forwardParams, page: pageIndex, size: pageSize, sort };
};
