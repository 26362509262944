import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { TableButton, useTableContext } from '@libs/common/v2';

import TableIconButton from './TableIconButton';

interface IProps {
  onBeforeReset?: () => void;
  tooltipTitle?: string;
  className?: string;
  isDisabled?: boolean;
  isMobile?: boolean;
}

function TableButtonRefetch({ isDisabled, className, tooltipTitle, onBeforeReset, isMobile }: IProps) {
  const [t] = useTranslation();
  const { refetch } = useTableContext();

  const handleReset = () => {
    onBeforeReset?.();
    if (refetch) {
      refetch();
    }
  };

  if (isMobile) {
    return (
      <TableButton
        label={tooltipTitle || t('other:component.table.refetch')}
        onClick={() => {
          handleReset();
        }}
        isDisabled={isDisabled}
      />
    );
  }
  return (
    <TableIconButton
      className={clsx(className)}
      tooltipTitle={tooltipTitle || t('other:component.table.refetch')}
      aria-label={t('other:component.table.refetch')}
      onClick={handleReset}
      icon="RefreshIcon"
      isDisabled={isDisabled}
    />
  );
}

export default React.memo(TableButtonRefetch);
