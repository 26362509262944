import { useTableLayoutProps } from '@libs/common/v2';

import { useDisposalsQuery } from '../../api';
import { useDisposalsColumns } from '../columns';

function useDisposalsTable() {
  const { columns, mappedFilterFields, mappedSortFields } = useDisposalsColumns();

  return useTableLayoutProps({
    tableHookQuery: useDisposalsQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'name', desc: false }]
        }
      }
    },
    tableHookQueryInitialParams: {}
  });
}

export default useDisposalsTable;
