import { SubjectAddressCreateRequest } from '@ibtm/client-domain';
import { SubjectApplicationAddressCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export const createAddress = async (subjectApplicationAddressCreateRequest: SubjectApplicationAddressCreateRequest) => {
  const response = await API.address.createAddress(subjectApplicationAddressCreateRequest, {
    ...endpointsConfig.createAddress
  });
  return response;
};
export const createAddressClient = async (subjectApplicationAddressCreateRequest: SubjectAddressCreateRequest) => {
  const response = await API.client.address.createAddress('', '', subjectApplicationAddressCreateRequest, {
    ...endpointsConfig.createAddress
  });
  return response;
};

function useCreateAddressMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(createAddressClient, createAddress));
}

export default useCreateAddressMutation;
