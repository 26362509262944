import React from 'react';

import NodeRegistry from './nodes/NodeRegistry';
import { Component, Node } from './models';
import renderNodes from './Renderer';

/*
 * funkcja podmieniającą element { slotId: "ID"} w definicji komponentu w nodes:[] lub w nodes:[]
 * elemenu zagnieżdżonego na zawartość przekazaną do komponentu w layout
 * {
 *  additionalNodes:{
 *    ID:[]
 * }
 */
const addAdditionalNodes = (
  slotId: string,
  nodes: Node[],
  additionalNodes: {
    [key: string]: Node;
  }
): Node[] => {
  return nodes?.reduce((acc, curr) => {
    const newValue = [].concat(acc);
    const newNode =
      Boolean(curr.slotId) && curr.slotId === slotId
        ? additionalNodes
        : {
            ...curr,
            ...(Array.isArray(curr.nodes) && { nodes: addAdditionalNodes(slotId, curr.nodes, additionalNodes) })
          };
    return newValue.concat(newNode);
  }, []);
};

// funkcja obsługująca usunięcie Node'ów o id lub fieldId podanym w tablicy
const removeNodesByIds = (hiddenIds: string[], nodes: Node[]): Node[] => {
  return nodes
    .filter(item => !hiddenIds.includes((item?.properties?.fieldId || item?.properties?.id) as string))
    .reduce((acc, curr) => {
      const newValue = [].concat(acc);
      const newNode = {
        ...curr,
        ...(Array.isArray(curr.nodes) && { nodes: removeNodesByIds(hiddenIds, curr.nodes) })
      };
      newValue.push(newNode);
      return newValue;
    }, []);
};
const containsId = (node, nodeId) =>
  (Boolean(node.properties?.fieldId) && node.properties?.fieldId === nodeId) ||
  (Boolean(node.properties?.id) && node.properties?.id === nodeId);

// funkcja obsługująca dodanie dodatkowych properties do Nodeów z id lub fieldId
const additionalPropertiesByIds = (nodeId, nodes, additionalProperties): Node[] => {
  return nodes?.reduce((acc, curr) => {
    const newValue = [].concat(acc);
    const newNode = (
      containsId(curr, nodeId)
        ? {
            ...curr,
            properties: { ...curr.properties, ...additionalProperties } as unknown,
            ...(Array.isArray(curr.nodes) && {
              nodes: additionalPropertiesByIds(nodeId, curr.nodes, additionalProperties)
            })
          }
        : {
            ...curr,
            ...(Array.isArray(curr.nodes) && {
              nodes: additionalPropertiesByIds(nodeId, curr.nodes, additionalProperties)
            })
          }
    ) as Node;
    return newValue.concat(newNode);
  }, []);
};

// funkcja dodaje modyfikatory przekazane do komponentu w layout: []
const applyComponentModifications = ({
  componentNodes,
  componentNode,
  additionalNodes,
  additionalProperties,
  hiddenIds
}: {
  componentNodes: Node[];
  componentNode: Node;
  additionalNodes?:
    | {
        [key: string]: Node;
      }
    | unknown;
  additionalProperties: unknown;
  hiddenIds: string[];
}) => {
  let component = { ...componentNode };
  let children = componentNodes ? [].concat(componentNodes) : [];
  if (hiddenIds) {
    children = removeNodesByIds(hiddenIds, children);
  }
  if (additionalNodes) {
    Object.entries(additionalNodes)?.forEach(([slotId, nodes]) => {
      children = addAdditionalNodes(slotId, children, nodes);
    });
  }
  if (additionalProperties) {
    Object.entries(additionalProperties)?.forEach(([nodeId, props]: [string, Record<string, any>]) => {
      children = additionalPropertiesByIds(nodeId, children, props);
      if (containsId(componentNode, nodeId)) {
        component = { ...component, properties: { ...component.properties, ...props } };
      }
    });
  }
  return { component, children };
};

// funkcja renderująca components
export default function renderComponent(node: Node, components: Array<Component>, index: number) {
  const { typeKey, properties } = node;
  const componentNode = components.find(el => el?.componentId === properties?.componentId)?.node as Node;
  if (componentNode) {
    const { additionalNodes, hiddenIds, additionalProperties } = properties ?? {};
    const { children, component } = applyComponentModifications({
      componentNodes: componentNode.nodes,
      additionalProperties,
      componentNode,
      additionalNodes,
      hiddenIds: hiddenIds as string[]
    });

    const componentId = properties?.componentId ? `-${properties.componentId}` : '';
    const componentKey = `component-${component.typeKey}${componentId}-${index}`;

    return React.createElement(
      NodeRegistry[component.typeKey],
      { ...component.properties, renderNodes, components, key: componentKey },
      children
    );
  }
  throw new Error(`Metaform component not found: typeKey:${typeKey}, componentsId:${properties.componentId}`);
}
