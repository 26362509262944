import { useTableLayoutProps } from '@libs/common/v2';

import { usePaginatedDictionariesQuery } from '@libs/dictionary/api';
import useDictionariesListColumns from '@libs/dictionary/hooks/useDictionariesListColumns';

function useDictionariesTable(showDictionaryKeys?: boolean) {
  const { columns, mappedFilterFields, mappedSortFields } = useDictionariesListColumns(
    !showDictionaryKeys && ['description', 'authorName', 'created', 'modifierName', 'modified']
  );

  return useTableLayoutProps({
    tableHookQuery: usePaginatedDictionariesQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tableOptions: {
        initialState: {
          sortBy: [
            {
              id: 'description',
              desc: false
            }
          ]
        }
      }
    }
  });
}

export default useDictionariesTable;
