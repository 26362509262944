/* eslint-disable no-restricted-syntax */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRoutesElementMapper {}

const RoutesElementMapper: IRoutesElementMapper = {} as IRoutesElementMapper;

export const setRoutesElementMapper = params => {
  for (const [key, value] of Object.entries(params)) {
    RoutesElementMapper[key] = value;
  }
};

export default RoutesElementMapper;
