import { useRef } from 'react';

function useCreateApplicationForm() {
  const createApplicationFormRef = useRef(null);

  const submitCreateApplicationForm = () => {
    if (createApplicationFormRef) {
      createApplicationFormRef.current?.submit();
    }
  };

  return { createApplicationFormRef, submitCreateApplicationForm };
}

export default useCreateApplicationForm;
