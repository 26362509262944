import { QueryConfig, usePaginatedQuery } from 'react-query';
import { SuspensionFormsSearchFilter } from '@ibtm/domain';

import { API } from '@libs/domain/api';
import { SuspensionQueryKeysEnum } from '@libs/domain/suspensions';

import { parseSuspensionFormOperationsListPayload } from '../../../proceeding';
import { endpointsConfig } from '../endpoints-config';

const getFormOperations = async (_, params: SuspensionFormsSearchFilter & { suspensionId: string }) => {
  const { suspensionId, ...queryParams } = parseSuspensionFormOperationsListPayload(params);
  const { data } = await API.suspensions.getSuspensionFormOperationsPage(suspensionId, queryParams, {
    ...endpointsConfig.getSuspensionFormOperationsPage
  });

  return data;
};

function useFormOperationsQuery(
  params: SuspensionFormsSearchFilter & { suspensionId: string },
  queryConfig: QueryConfig<any, unknown> = {}
) {
  return usePaginatedQuery(
    [SuspensionQueryKeysEnum.SUSPENSION_FORM_OPERATIONS, params],
    getFormOperations,
    queryConfig
  );
}

export default useFormOperationsQuery;
