import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TravelFormPrintsSnapshot } from '@ibtm/domain';

import { TableLayout } from '@libs/common/v2';

import { useTravelFormTable } from '../../hooks';

import { TravelFormRowActions } from './actions';

interface IProps {
  applicationId: string;
  headerActions: ReactNode;
  isInterbus: boolean;
}

export function TravelFormTable({ applicationId, headerActions, isInterbus }: IProps) {
  const [t] = useTranslation();

  const tableProps = useTravelFormTable({ applicationId, isInterbus });

  return (
    <TableLayout<TravelFormPrintsSnapshot, unknown>
      {...tableProps}
      pageTitle={
        isInterbus
          ? t('prints:travelFormsSections.travelFormTable.interbusTitle')
          : t('prints:travelFormsSections.travelFormTable.travelFormsBookTitle')
      }
      headerActions={headerActions}
      isSection
      isFilterEnabled={false}
      rowActions={row => <TravelFormRowActions row={row} />}
    />
  );
}

export default TravelFormTable;
