import { useTableLayoutProps } from '@libs/common/v2';

import { useListPermissionsForGroupQuery } from '../api';
import { IGroupPermissionClient } from '../models/group-permission-client.model';

import { useListPermissionsForGroupColumns } from './columns';

export function useListPermissionsForGroupTable() {
  const { columns, mappedFilterFields, mappedSortFields } = useListPermissionsForGroupColumns();

  return useTableLayoutProps<IGroupPermissionClient, IGroupPermissionClient>({
    tableHookQuery: useListPermissionsForGroupQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    }
  });
}
