import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { queryCache } from 'react-query';
import { Row } from 'react-table';
import { useSnackbar } from '@enigma/fe-ui';
import { MisspellApplicationDataPermissionDetails, MisspellPermissionDetails } from '@ibtm/domain';
import { MisspellApplicationDataReprintSingleUpdateRequest } from '@ibtm/domain/dist/models/misspell-application-data-reprint-single-update-request';

import {
  TableButtonEdit,
  TableButtonPrint,
  TableButtonView,
  TableLayout,
  useConfirmDialog,
  useDialog,
  useMultipleSelectColumn
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { ApplicationQueryKeysEnum, useApplicationDetailsQuery } from '../../application';
import useUdateApplicationPermissionsReprintMutation from '../../application/api/mutations/useUpdateApplicationPermissionsReprintMutation';
import { useMispellApplicationPermissionTable, useMispellApplicationPermissionTableConfiguration } from '../hooks';
import { AccesorType } from '../hooks/useMisspellApplicationPermissionColumn';
import { DialogModeEnum, MisspellApplicationPermissionTypeEnum } from '../model/misspell-application.model';

import MisspellApplicationPermissionDialog from './dialog/MisspellApplicationPermissionDialog';
import MisspellApplicationSelectColumnButton from './MisspellApplicationSelectColumnButton';

interface IProps {
  applicationId: string;
  permissionType: MisspellApplicationPermissionTypeEnum;
}

function MisspellApplicationPermissionTable({ applicationId, permissionType }: IProps) {
  const config = useMispellApplicationPermissionTableConfiguration({ applicationId });
  const [data, setData] = useState(config[permissionType]?.data ?? []);
  const { showSuccessSnackbar } = useSnackbar();
  const [confirm] = useConfirmDialog();

  useEffect(() => {
    setData(config[permissionType]?.data ?? []);
  }, [config, permissionType]);

  const { viewMode } = useViewModesV2();
  const { mutate: updateApplicationPermissionsReprint } = useUdateApplicationPermissionsReprintMutation();

  const isLicenseCopy = useMemo(
    () => permissionType === MisspellApplicationPermissionTypeEnum.LICENSE_COPY,
    [permissionType]
  );
  const valueAccessor: AccesorType = 'permission';

  const { data: applicationDetails } = useApplicationDetailsQuery(applicationId, { refetchOnMount: false });

  const ref = useRef(applicationDetails?.version);
  ref.current = applicationDetails?.version;

  const handleUpdateApplicationPermissionsReprint = (
    permissions: Array<MisspellApplicationDataReprintSingleUpdateRequest>,
    onSuccess?: () => void,
    onSettled?: () => void
  ) => {
    updateApplicationPermissionsReprint(
      {
        applicationId,
        misspellApplicationDataReprintListUpdateRequest: {
          applicationVersion: ref.current,
          permissions
        }
      },
      {
        onSuccess: () => {
          queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
          showSuccessSnackbar(t('applications:misspellApplication.successMessages.reprinted'));
          onSuccess?.();
        },
        onSettled: () => {
          onSettled?.();
        }
      }
    );
  };

  const multipleSelectColumnPlugin = useMultipleSelectColumn(
    (selected: string[], handleClose: () => void) => (
      <MisspellApplicationSelectColumnButton
        onConfirm={(onSuccess, setConfirmLoading) => {
          handleUpdateApplicationPermissionsReprint(
            selected.map(element => ({ id: element, reprint: true })),
            () => {
              onSuccess();
            },
            () => {
              setConfirmLoading(false);
            }
          );
          handleClose();
        }}
      />
    ),
    true,
    null,
    [],
    false,
    null,
    row => row?.[valueAccessor]?.id
  );

  const isPermissions = [
    MisspellApplicationPermissionTypeEnum.PERMISSION,
    MisspellApplicationPermissionTypeEnum.PERMISSION_COPY
  ].includes(permissionType);

  const isLicences = [
    MisspellApplicationPermissionTypeEnum.LICENSE,
    MisspellApplicationPermissionTypeEnum.LICENSE_COPY
  ].includes(permissionType);

  const tableProps = useMispellApplicationPermissionTable({
    data,
    hiddenColumns: isPermissions ? ['validFrom', 'validTo'] : [],
    tablePlugins: isLicenseCopy ? [multipleSelectColumnPlugin] : [],
    numberColumnHeader: isLicences ? 'licenceNumber' : 'number'
  });

  const [t] = useTranslation();

  const { openDialog } = useDialog();

  const rowActions = useCallback(
    (row: Row<MisspellApplicationDataPermissionDetails & MisspellPermissionDetails>) => (
      <>
        {isLicenseCopy && (
          <TableButtonPrint
            tooltipTitle={t('applications:misspellApplication.action.reprint')}
            onClick={() =>
              confirm({
                title: t('applications:misspellApplication.dialog.reprint'),
                message: t('applications:misspellApplication.dialog.reprintConfirm'),
                onConfirm: (setConfirmLoading, closeDialog) => {
                  setConfirmLoading(true);
                  handleUpdateApplicationPermissionsReprint(
                    [{ id: row.original?.permission?.id || row.original?.id, reprint: true }],
                    () => {
                      closeDialog();
                    },
                    () => {
                      setConfirmLoading(false);
                    }
                  );
                }
              })
            }
          />
        )}
        {!viewMode && (
          <TableButtonEdit
            onClick={() =>
              openDialog(({ closeDialog }) => (
                <MisspellApplicationPermissionDialog
                  applicationId={applicationId}
                  permissionData={row.original.permission}
                  updatePermissionData={row.original.permissionUpdated}
                  closeDialog={closeDialog}
                  permissionType={permissionType}
                  mode={DialogModeEnum.EDIT}
                />
              ))
            }
          />
        )}
        <TableButtonView
          tooltipTitle={t('applications:misspellApplication.action.showChanges')}
          onClick={() =>
            openDialog(({ closeDialog }) => (
              <MisspellApplicationPermissionDialog
                applicationId={applicationId}
                permissionData={row.original.permission}
                updatePermissionData={row.original.permissionUpdated}
                closeDialog={closeDialog}
                permissionType={permissionType}
                mode={DialogModeEnum.COMPARE}
              />
            ))
          }
        />
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [applicationId, openDialog, t, isLicenseCopy]
  );

  const ActionComponent = ({ row }) => rowActions(row);

  if (!data || data?.length === 0) {
    return null;
  }
  const getTitle = () => {
    switch (permissionType) {
      case MisspellApplicationPermissionTypeEnum.LICENSE:
        return t('applications:misspellApplication.table.license');
      case MisspellApplicationPermissionTypeEnum.LICENSE_COPY:
        return t('applications:misspellApplication.table.licenseCopy');
      case MisspellApplicationPermissionTypeEnum.PERMISSION:
        return t('applications:misspellApplication.table.permission');
      case MisspellApplicationPermissionTypeEnum.PERMISSION_COPY:
      default:
        return t('applications:misspellApplication.table.permissionCopy');
    }
  };

  return (
    <TableLayout
      pageTitle={getTitle()}
      {...tableProps}
      isSection
      isFilterEnabled={false}
      isRefreshEnabled={false}
      rowActions={row => <ActionComponent row={row} />}
      {...(isLicenseCopy && { actionsColumnWidth: 200 })}
    />
  );
}

export default MisspellApplicationPermissionTable;
