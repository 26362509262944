import { createContext, useContext } from 'react';

interface IErrorContext {
  isCaseNumberError: boolean;
  setIsCaseNumberError: (isCaseNumberError: boolean) => void;
}

export const ErrorContext = createContext<IErrorContext>({
  isCaseNumberError: false,
  setIsCaseNumberError: () => {}
});

export default function useErrorContext() {
  return useContext(ErrorContext);
}
