import { useCallback } from 'react';
import { ParameterExtras } from '@stack/report';

import { useDialog } from '@libs/common/v2';

import { ParameterDialogContent } from '@libs/report/components/report-type-form';
import { ReportTypeForm } from '@libs/report/model/form.model';

function useReportTypeExtrasDialog(reportTypeVaules: ReportTypeForm) {
  const { openDialog } = useDialog();

  const showDialog = useCallback(
    (onConfirm: (data: ParameterExtras) => void, initialEditFormValues?: ParameterExtras) => {
      openDialog(({ closeDialog }) => (
        <ParameterDialogContent
          reportTypeValues={reportTypeVaules}
          closeDialog={closeDialog}
          onConfirm={onConfirm}
          initialEditFormValues={initialEditFormValues}
        />
      ));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog]
  );

  const openReportTypeExtrasDialog = (
    onConfirm: (data: ParameterExtras) => void,
    initialEditFormValues?: ParameterExtras
  ) => {
    showDialog(onConfirm, initialEditFormValues);
  };

  return {
    openReportTypeExtrasDialog
  };
}

export default useReportTypeExtrasDialog;
