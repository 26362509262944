import { useTranslation } from 'react-i18next';
import { ProxiesSearchFilter, ProxySnapshot } from '@ibtm/domain';
import { isEmpty } from 'lodash';

import {
  CamelCasePath,
  SelectOption,
  TableFilter,
  TableFilterMapper,
  TableSortMapper,
  useCreateColumns
} from '@libs/common/v2';
import { getValue } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';

import { parsePostCode } from '@libs/domain/address';
import { DomainDictionaryEnum } from '@libs/domain/config';

import { ProxyClient } from '../../model/proxy.model';

const useProxyColumns = (visibleColumns?: Array<CamelCasePath<ProxyClient>>) => {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();
  const { createColumns } = useCreateColumns<ProxyClient>({
    pathToTranslate: 'proxy:field'
  });

  const mappedSortField: TableSortMapper<ProxySnapshot> = {
    postCode: 'address.postCode',
    postCity: 'address.postCity',
    city: 'address.city',
    street: 'address.street'
  };

  const mappedFilterFields: TableFilterMapper<ProxySnapshot, ProxiesSearchFilter> = {
    name: (name: string) => ({ nameContains: name }),
    surname: (surname: string) => ({ surnameContains: surname }),
    typeKey: (typeKeys: { value: string }[]) => ({ typeKeyIn: typeKeys.map(typeKey => typeKey.value) }),
    postCode: (postCode: string) => ({ addressPostCodeContains: postCode }),
    postCity: (postCity: string) => ({ addressPostContains: postCity }),
    city: (city: string) => ({ addressCityContains: city }),
    street: (street: string) => ({ addressStreetContains: street }),
    citizenship: (citizenships: SelectOption<string>[]) => ({ citizenshipIn: citizenships.map(({ value }) => value) })
  };

  const columns = createColumns(
    [
      {
        accessor: 'name',
        type: 'TEXT'
      },
      {
        accessor: 'surname',
        type: 'TEXT'
      },
      {
        accessor: 'citizenship',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: 'COUNTRY_CODE'
      },
      {
        accessor: 'typeKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: 'PROXY_TYPE',
        isSortable: false,
        isFilterable: false
      },
      {
        id: 'postCode',
        accessor: 'postCode',
        type: 'TEXT',
        customAccessor: row => `${parsePostCode(row?.postCode)}`
      },
      {
        accessor: 'postCity',
        type: 'TEXT'
      },
      {
        accessor: 'city',
        type: 'TEXT'
      },
      {
        id: 'street',
        type: 'CUSTOM_COLUMN',
        customAccessor: row => {
          const { apartmentNumber, propertyNumber, street } = row;
          if (!street && !propertyNumber) {
            return t('emptyMark');
          }
          return `${getValue(street)} ${getValue(propertyNumber)}${apartmentNumber ? ` / ${apartmentNumber}` : ''}`;
        },
        filter: TableFilter.Text
      },
      {
        accessor: 'paid',
        type: 'BOOLEAN',
        tooltipTitle: ({ paid }) => (paid ? t('action.yes') : t('action.no')),
        isFilterable: false,
        isSortable: false,
        width: 200
      },
      {
        id: 'authorizationKey',
        customAccessor: row =>
          getValue(
            !isEmpty(row?.authorizations) &&
              row?.authorizations
                .map(proxyAuthorization =>
                  translate(
                    DomainDictionaryEnum.SCOPES_OF_FOREIGN_PERMITS_AUTHORIZATIONS,
                    proxyAuthorization,
                    t('emptyMark')
                  )
                )
                .join(', ')
          ),
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: 'SCOPES_OF_FOREIGN_PERMITS_AUTHORIZATIONS',
        header: 'permissionCode',
        isFilterable: false,
        isSortable: false,
        width: 300
      },
      {
        accessor: 'validUntil',
        type: 'DATE',
        isFilterable: false,
        isSortable: false
      },
      {
        id: 'archiveNumber',
        header: 'archiveNumber',
        type: 'TEXT',
        isFilterable: false,
        isSortable: false,
        width: 250
      }
    ],
    visibleColumns
  );

  return { columns, mappedFilterFields, mappedSortField };
};

export default useProxyColumns;
