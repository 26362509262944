import { useMultipleSelectColumn, useTableLayoutProps } from '@libs/common/v2';

import { useReportTypesQuery } from '@libs/report/api';
import { ReportTypesSelectAction } from '@libs/report/components';
import useReportTypesTableColumns from '@libs/report/hooks/useReportTypesTableColumns';

function useReportTypesTable(isDevMode: boolean, isReportTypeManagementEnable: boolean) {
  const multipleSelectColumn = useMultipleSelectColumn(ReportTypesSelectAction);
  const { columns, mappedFilterFields } = useReportTypesTableColumns(
    isReportTypeManagementEnable
      ? ['name', 'description', 'sourceName', 'created', 'modified']
      : ['name', 'description']
  );

  return useTableLayoutProps(
    {
      tableHookQuery: useReportTypesQuery as any,
      tableHookOptions: {
        columns,
        ...(isDevMode && { tablePlugins: [multipleSelectColumn] }),
        filterConverter: mappedFilterFields,
        tableOptions: {
          initialState: {
            sortBy: [{ id: 'modified', desc: true }]
          }
        }
      }
    },
    [isDevMode, isReportTypeManagementEnable]
  );
}

export default useReportTypesTable;
