import { makeStyles } from '@mui/styles';

import { Section, useTheme } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

interface IProps {
  title: string;
  isCollapsable?: boolean;
  children: React.ReactNode;
  isCollapsed?: boolean;
  isLazy?: boolean;
}

function FolderSliderSection({ title, isCollapsable, children, isCollapsed, isLazy }: IProps) {
  const { contrast } = useTheme();
  const classes = useStyles({ contrast });

  return (
    <Section
      headerClassName={classes.sectionHeader}
      className={classes.section}
      collapseButtonClassName={classes.collapseButton}
      contentClassName="relative"
      title={title}
      isCollapsable={isCollapsable}
      isCollapsed={!isCollapsed}
      isLazy={isLazy}
      isModalSection
      isSidebarSection
    >
      {children}
    </Section>
  );
}

export default FolderSliderSection;

const useStyles = makeStyles<Theme, { contrast: boolean }>(theme => ({
  sectionHeader: {
    '& h2': {
      margin: 0
    }
  },
  section: {
    backgroundColor: ({ contrast }) => contrast && important(theme.palette.grey[50]),
    borderRadius: ({ contrast }) => contrast && 0
  },
  collapseButton: {
    width: 28,
    height: 28
  }
}));
