import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ForeignPermissionsPrintsSnapshot, PermissionTypeCode, TravelFormPrintsSnapshot } from '@ibtm/domain';

import { SelectOption } from '@libs/common/v2';

import { ReassignmentSelectValue } from '../model';

export enum PrintActionType {
  None,
  Regenerate,
  ReturnApplication,
  SendForRelease,
  SendToForeignPermissionDepartment,
  RegenerateTravelForm,
  RegenerateMultipleFormNumbers,
  RegenerateForeignPermissionNumber
}

interface IPrintsActionData {
  resourcesReassignments: {
    resources: Array<ReassignmentSelectValue>;
    permissionTypeCode: PermissionTypeCode;
  };
  resourcesReassignmentsSelectOptions: Array<SelectOption<ReassignmentSelectValue>>;
  foreignPermissionsPrintsSnapshot?: ForeignPermissionsPrintsSnapshot;
  travelFormPrintsSnapshot?: TravelFormPrintsSnapshot;
  onSuccess: () => void;
}

type IPrintActionDataValue = Partial<IPrintsActionData>;

type ActionData<T extends keyof IPrintsActionData> = Pick<IPrintsActionData, T | 'onSuccess'>;

type ActionParamsDefinition<T extends [PrintActionType, IPrintActionDataValue]> = T | [Exclude<PrintActionType, T[0]>];

type PrintActionParams = ActionParamsDefinition<
  | [PrintActionType.RegenerateForeignPermissionNumber, ActionData<'foreignPermissionsPrintsSnapshot'>]
  | [PrintActionType.RegenerateTravelForm, ActionData<'travelFormPrintsSnapshot'>]
  | [
      PrintActionType.RegenerateMultipleFormNumbers,
      ActionData<'resourcesReassignments' | 'resourcesReassignmentsSelectOptions'>
    ]
  | [PrintActionType.Regenerate, ActionData<'resourcesReassignments'>]
>;

const initialActionData = { onSuccess: () => {} };

const PrintActionsContext = React.createContext<{
  setAction: (...action: PrintActionParams) => void;
  finishAction: () => void;
}>({
  setAction: () => {},
  finishAction: () => {}
});

const PrintActionsDataContext = React.createContext<{
  actionType: PrintActionType;
  actionData: IPrintActionDataValue;
}>({
  actionType: PrintActionType.None,
  actionData: initialActionData
});

export function PrintActionsContextProvider({ children }) {
  const [actionType, setActionType] = useState<PrintActionType>(PrintActionType.None);
  const [actionData, setActionData] = useState<IPrintActionDataValue>(initialActionData);

  const setAction = useCallback((...action: PrintActionParams) => {
    const [type, data] = action;
    if (data) {
      setActionData(data);
    }
    setActionType(type);
  }, []);

  const finishAction = useCallback(() => {
    setActionType(PrintActionType.None);
    setActionData(initialActionData);
  }, []);

  const actionsContext = useMemo(
    () => ({
      setAction,
      finishAction
    }),
    [setAction, finishAction]
  );
  const actionsDataContext = useMemo(
    () => ({
      actionType,
      actionData
    }),
    [actionType, actionData]
  );
  return (
    <PrintActionsDataContext.Provider value={actionsDataContext}>
      <PrintActionsContext.Provider value={actionsContext}>{children}</PrintActionsContext.Provider>
    </PrintActionsDataContext.Provider>
  );
}

export const usePrintsActionsContext = () => useContext(PrintActionsContext);
export const usePrintsActionsDataContext = () => useContext(PrintActionsDataContext);
