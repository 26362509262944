import _ from 'lodash';

import { KeyType, TFunction } from '@libs/common';
import {
  BooleanValue,
  CamelCasePath,
  ColumnTypesEnum,
  ISingleColumn,
  ITableLayoutColumn,
  Path,
  TableFilter
} from '@libs/common/v2';
import { convertDateToDateFormat, convertDateToDateTimeFormat, getDateValue, getValue } from '@libs/common/v2/utils';

import { DictionaryEntryTypeEnum } from '@libs/dictionary';

import { tableColumnIdParser } from './column-id-parser.util';
import { getPropertyFromRow } from './get-property-from-row.util';

function canFilter(isFilterable, Filter) {
  return isFilterable ? Filter : <div />;
}

/**
 * Funkcja pomocnicza służy do stworzenia kolumny do tabeli. Wykorzystana w hook useCreateColumns.
 */
// eslint-disable-next-line consistent-return
function createTableColumn<T extends Record<string, any>, D extends Record<string, any> = T>(
  {
    id,
    accessor = id as Path<T>,
    customAccessor,
    dictionaryName,
    hiddenSelectOptionsValues,
    areInactiveEntriesVisible,
    truncateLength,
    width,
    header,
    editType,
    filter,
    isFilterable = true,
    dateFormatFn = convertDateToDateFormat,
    isSortable = true,
    xlsxAccessor = accessor as any,
    selectOptions,
    customXlsxAccessor,
    quickDictionaryName,
    defaultValue,
    type,
    isCreatable,
    fetchFunctionResolver,
    fetchedDataSelectParser,
    isEditable,
    isDisabled,
    mutableTableInputProps,
    nestedArrayColumns,
    tooltipTitle,
    sortType,
    customEdit,
    customView,
    isFreeSolo,
    isCellAlignRight,
    isRequired,
    isTooltipHidden
  }: ISingleColumn<T, D>,
  isMutableTable: boolean,
  getLabel: any, // funkcja partialTranslate
  translate: (
    dictionaryEntryType: keyof DictionaryEntryTypeEnum,
    dictionaryItemKey: string,
    defaultValue?: string
  ) => string,
  t: TFunction,
  /**
   * Parametr jest wykorzystywany przy renderowaniu kolumn z tablicy za pomocą hooka useCreateColumn.
   * Przyjmuję go z query hook. Przy renderowaniu kolumn z tablicy przy użyciu nestedArrayColumn,
   * dostajemy to jako drugi argument w którym mamy dostęp do iterowanej tablicy.
   */
  mapper?: Record<string, any>,
  hasErrorTooltip?: boolean
): ITableLayoutColumn<T, D> | ITableLayoutColumn<T, D>[] {
  // Funkcja z ścieżki otrzymanej w id wyciąga wartość z wiersza do wyrenderowania
  const access = (row, additionalFormater?: (value?: any) => any) => {
    if (customAccessor) {
      return customAccessor(row);
    }
    const rowProperty = getPropertyFromRow(row, accessor);
    if (additionalFormater) {
      return additionalFormater(rowProperty);
    }
    return rowProperty;
  };
  // Funkcja z ścieżki otrzymanej w id wyciąga wartość z wiersza do wsadzenia w plik xlsx
  const xlsxFormat = (row, additionalFormater?: (value?: any) => any) => {
    if (customXlsxAccessor) {
      return customXlsxAccessor(row);
    }
    if (customAccessor) {
      return customAccessor(row);
    }
    const rowProperty = getPropertyFromRow(row, xlsxAccessor);
    if (additionalFormater) {
      return additionalFormater(rowProperty);
    }
    return rowProperty;
  };
  // Funkcja wstawia do obiektu kolumny atrybut `accessor` lub `field` w zależności czy tabela jest mutowalna
  const getFieldOrAccessor = ({
    colType,
    formatFn
  }: {
    colType: ColumnTypesEnum;
    // W tej funkcji value jest już wartością wiersza,
    // więc możemy ją np.przekazać do komponentu lub funkcji tłumaczącej
    formatFn?: (value?: any) => any;
  }) => {
    return {
      [isMutableTable ? 'field' : 'accessor']: isMutableTable
        ? {
            name: accessor,
            type: colType,
            isCreatable,
            isEditable,
            isDisabled,
            quickDictionaryName,
            dictionaryName,
            defaultValue,
            inputProps: mutableTableInputProps,
            customEdit,
            customView
          }
        : row => access(row, formatFn)
    };
  };

  /**
   * Funkcja do odpowiedniego wyświetlenia nazw kolumn wraz z numerem kolumn z tablicy [Nazwa kolumny] [index+1].
   * Np. Data 1, Data 2
   */
  const getHeader = (colId: CamelCasePath<T>, colHeader: string) => {
    const parsedHeader = tableColumnIdParser(colId, colHeader);
    if (isEditable && colId.match(/[0-9]/g)) {
      const columnNumber = +colId.split('.').find(item => Number.isInteger(+item));
      return columnNumber ? `${getLabel(parsedHeader)} ${columnNumber + 1}` : `${getLabel(parsedHeader)}`;
    }
    return parsedHeader.includes(':') ? t(parsedHeader as KeyType) : getLabel(parsedHeader);
  };

  // Funkcja renderuje kolumny dla najdłuższych tablic z każdego wiersza.
  const renderArrayColumnCollection = () => {
    const arrayColumns: any = [];
    if (!mapper?.[id] || !mapper?.[id]?.length) {
      return nestedArrayColumns(0, mapper ?? {}).map(col =>
        createTableColumn(col as ISingleColumn<T, D>, isMutableTable, getLabel, translate, t, mapper, hasErrorTooltip)
      ) as ITableLayoutColumn<T, D>[];
    }

    for (let i = 0; i < mapper[id]?.length; i++) {
      arrayColumns.push(
        ...nestedArrayColumns(i, mapper[id]).map(col =>
          createTableColumn(col as ISingleColumn<T, D>, isMutableTable, getLabel, translate, t, mapper, hasErrorTooltip)
        )
      );
    }
    return arrayColumns as ITableLayoutColumn<T, D>[];
  };

  const getSortType = () => {
    return sortType && { sortType };
  };

  const columnMapper = ({
    colType,
    colWidth,
    Filter,
    colValueFormatter = null,
    xlsxValueFormatter = colValueFormatter,
    tooltipTitleFormatter = null
  }) => {
    return {
      id,
      ...getFieldOrAccessor({ colType, formatFn: colValueFormatter }),
      disableSortBy: !isSortable,
      width: colWidth,
      type,
      Header: getHeader(id, header),
      Filter: props => canFilter(isFilterable, Filter?.(props)),
      xlsxFormatter: row => xlsxFormat(row, xlsxValueFormatter),
      originalId: id,
      ...(tooltipTitle
        ? { tooltipTitle }
        : tooltipTitleFormatter && { tooltipTitle: row => access(row, tooltipTitleFormatter) }),
      hasErrorTooltip,
      ...(isMutableTable ? { canSort: isSortable } : {}),
      ...getSortType(),
      isCellAlignRight,
      isRequired,
      isTooltipHidden
    };
  };

  const getFilter = (defaultComponent: (props) => JSX.Element) => {
    return filter ?? defaultComponent;
  };

  // eslint-disable-next-line default-case
  switch (type) {
    case ColumnTypesEnum.TEXT:
      return columnMapper({
        colType: ColumnTypesEnum.TEXT,
        colWidth: width ?? 150,
        colValueFormatter: value =>
          truncateLength ? getValue(_.truncate(value, { length: truncateLength })) : getValue(value),
        xlsxValueFormatter: getValue,
        Filter: getFilter(props => <TableFilter.Text {...props} />)
      });
    case ColumnTypesEnum.DATE:
      return columnMapper({
        colType: ColumnTypesEnum.DATE,
        colValueFormatter: value => getDateValue(value, dateFormatFn),
        colWidth: width ?? 260,
        Filter: getFilter(props => <TableFilter.Datepicker {...props} />)
      });
    case ColumnTypesEnum.DATE_TIME:
      return columnMapper({
        colType: ColumnTypesEnum.DATE_TIME,
        colValueFormatter: value => getDateValue(value, convertDateToDateTimeFormat),
        colWidth: width ?? 260,
        Filter: getFilter(props => <TableFilter.Datepicker {...props} />)
      });
    case ColumnTypesEnum.TIME:
      return columnMapper({
        colType: ColumnTypesEnum.TIME,
        colWidth: width ?? 150,
        Filter: getFilter(props => <TableFilter.Timepicker {...props} />)
      });
    case ColumnTypesEnum.BOOLEAN:
      return columnMapper({
        colType: ColumnTypesEnum.BOOLEAN,
        colValueFormatter: value => <BooleanValue value={value} isTableCell />,
        colWidth: width ?? 150,
        Filter: getFilter(props => <TableFilter.Boolean {...props} />),
        xlsxValueFormatter: value => (value ? t('action.yes') : t('action.no')),
        tooltipTitleFormatter: value => (value ? t('action.yes') : t('action.no'))
      });
    case ColumnTypesEnum.DICTIONARY_MULTI_SELECT:
      return columnMapper({
        colType: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
        colValueFormatter: rowProperty => {
          if (Array.isArray(rowProperty)) {
            if (rowProperty.length) {
              return rowProperty.map(property => translate(dictionaryName, property)).join(', ');
            }
            return t('emptyMark');
          }

          return translate(dictionaryName, rowProperty, t('emptyMark'));
        },
        colWidth: width ?? 170,
        Filter: getFilter(props => (
          <TableFilter.DictionaryMultiSelect
            isFreeSolo={isFreeSolo}
            dictionary={dictionaryName}
            hiddenSelectOptionsValues={hiddenSelectOptionsValues}
            areInactiveEntriesVisible={areInactiveEntriesVisible}
            {...props}
          />
        ))
      });
    case ColumnTypesEnum.DICTIONARY_SINGLE_SELECT:
      return columnMapper({
        colType: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
        colValueFormatter: rowProperty => translate(dictionaryName, rowProperty, t('emptyMark')),
        colWidth: width ?? 170,
        Filter: getFilter(props => (
          <TableFilter.DictionarySingleSelect
            isFreeSolo={isFreeSolo}
            dictionary={dictionaryName}
            hiddenSelectOptionsValues={hiddenSelectOptionsValues}
            areInactiveEntriesVisible={areInactiveEntriesVisible}
            {...props}
          />
        ))
      });
    case ColumnTypesEnum.MULTI_SELECT:
      return columnMapper({
        colType: ColumnTypesEnum.MULTI_SELECT,
        colWidth: width ?? 170,
        Filter: getFilter(props => <TableFilter.MultiSelect options={selectOptions} {...props} />)
      });
    case ColumnTypesEnum.SINGLE_SELECT:
      return columnMapper({
        colType: ColumnTypesEnum.SINGLE_SELECT,
        colWidth: width ?? 170,
        Filter: getFilter(props => <TableFilter.SingleSelect options={selectOptions} {...props} />)
      });
    case ColumnTypesEnum.MULTI_SELECT_FETCH:
      return columnMapper({
        colType: ColumnTypesEnum.MULTI_SELECT_FETCH,
        colWidth: width ?? 170,
        colValueFormatter: value => {
          return getValue(value);
        },
        Filter: getFilter(props => (
          <TableFilter.MultiSelectFetch
            fetchFunctionResolver={fetchFunctionResolver}
            fetchedDataSelectParser={fetchedDataSelectParser}
            {...props}
          />
        ))
      });
    case ColumnTypesEnum.SINGLE_SELECT_FETCH:
      return columnMapper({
        colType: ColumnTypesEnum.SINGLE_SELECT_FETCH,
        colWidth: width ?? 170,
        Filter: getFilter(props => (
          <TableFilter.SingleSelectFetch
            fetchFunctionResolver={fetchFunctionResolver}
            fetchedDataSelectParser={fetchedDataSelectParser}
            {...props}
          />
        ))
      });
    case ColumnTypesEnum.NUMBER:
      return columnMapper({
        colType: ColumnTypesEnum.NUMBER,
        colWidth: width ?? 170,
        Filter: getFilter(props => <TableFilter.Number {...props} />)
      });
    case ColumnTypesEnum.NUMBER_RANGE:
      return columnMapper({
        colType: ColumnTypesEnum.NUMBER,
        colWidth: width ?? 170,
        Filter: getFilter(props => <TableFilter.NumberRange {...props} />)
      });
    case ColumnTypesEnum.QUICK_DICTIONARY_MULTI_SELECT:
      return columnMapper({
        colType: ColumnTypesEnum.QUICK_DICTIONARY_MULTI_SELECT,
        colWidth: width ?? 170,
        Filter: getFilter(props => (
          <TableFilter.QuickChangeDictionaryMultiSelect dictionary={quickDictionaryName} {...props} />
        ))
      });
    case ColumnTypesEnum.QUICK_DICTIONARY_SINGLE_SELECT:
      return columnMapper({
        colType: ColumnTypesEnum.QUICK_DICTIONARY_SINGLE_SELECT,
        colWidth: width ?? 170,
        Filter: getFilter(props => (
          <TableFilter.QuickChangeDictionarySingleSelect dictionary={quickDictionaryName} {...props} />
        ))
      });
    case ColumnTypesEnum.CUSTOM_COLUMN:
      return columnMapper({
        colType: editType,
        colWidth: width ?? 170,
        Filter: filter
      });
    case ColumnTypesEnum.ARRAY_COLUMN:
      return renderArrayColumnCollection();
    case ColumnTypesEnum.YEAR_DATEPICKER:
      return columnMapper({
        colType: ColumnTypesEnum.YEAR_DATEPICKER,
        colWidth: width ?? 150,
        Filter: getFilter(props => <TableFilter.CustomDatepicker views="year" {...props} />)
      });
    case ColumnTypesEnum.MONTH_DATEPICKER:
      return columnMapper({
        colType: ColumnTypesEnum.MONTH_DATEPICKER,
        colWidth: width ?? 150,
        Filter: getFilter(props => <TableFilter.CustomDatepicker views="month" {...props} />)
      });
  }
}

export default createTableColumn;
