import React from 'react';

import { GlobalYearSelectType } from '@libs/common/v2';

export enum ClientPortalUserPerspectiveEnum {
  ENTREPRENEUR = 'ENTREPRENEUR',
  ASSOCIATION = 'ASSOCIATION'
}

export interface DomainConfigContextState {
  isClientPortal: boolean;
  isOperatorPortal: boolean;
  isClientPortalEntrepreneurPerspective?: boolean;
  isClientPortalAssociationPerspective?: boolean;
  clientPortalUserPerspective?: ClientPortalUserPerspectiveEnum;
  /**
   * Służy do przechowywania stanu wybranego roku w komponentach YearSelectField - filtry roku,
   * używamy jako globalnego filtra tabel jak i w sekcjach gdzie zawartość zależy od roku
   * przykładowe widoki: tabela Wydane zezwolenia, zakładka Zezwolenia jednorazowe w szczegółach teczki
   */
  yearFilterContextValues?: GlobalYearSelectType;
  setClientPortalUserPerspective?: (clientPortalUserPerspective: ClientPortalUserPerspectiveEnum) => void;
  /**
   * Służy do ustawiania stanu wybranego roku w komponentach YearSelectField - filtry roku,
   * używamy jako globalnego filtra tabel jak i w sekcjach gdzie zawartość zależy od roku
   * przykładowe widoki: tabela Wydane zezwolenia, zakładka Zezwolenia jednorazowe w szczegółach teczki
   */
  setYearFilterContextValues?: (yearFilterContextValues: GlobalYearSelectType) => void;
}

const DomainConfigContext = React.createContext<DomainConfigContextState>({
  isClientPortal: false,
  isOperatorPortal: true,
  isClientPortalEntrepreneurPerspective: true,
  isClientPortalAssociationPerspective: false,
  clientPortalUserPerspective: null,
  yearFilterContextValues: null,
  setClientPortalUserPerspective: null,
  setYearFilterContextValues: null
});

export default DomainConfigContext;
