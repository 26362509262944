import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { ReportDataSetDefinitionCreateRequest } from '@stack/report/dist/models';
import clsx from 'clsx';

import { Tab, Tabs, useErrorsHandlerV2, useFormV2Context } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';

import { ReportQueryKeysEnum } from '@libs/report/api';

interface IProps {
  value: Array<ReportDataSetDefinitionCreateRequest>;
  tab: number;
  onTabChange: (v: number) => void;
}

function ReportTypeDataTabs({ value, tab, onTabChange }: IProps) {
  const [t] = useTranslation();
  const classes = useStyles();

  useErrorsHandlerV2<number>(i => {
    onTabChange(parseInt(i as never, 10));
  }, ReportQueryKeysEnum.REPORT_TYPE_FORM);

  return (
    <div className={clsx('flex items-center w-full')}>
      <Tabs<number>
        value={tab}
        variant="scrollable"
        onChange={onTabChange}
        className={classes.tabsWrapper}
        orientation="horizontal"
        isTabIndicatorVisible
      >
        {(value ?? []).map((dataSet, index: number) => {
          return <Tab key={`${dataSet?.name}`} isHorizontalTab label={dataSet?.name || t('emptyMark')} value={index} />;
        })}
      </Tabs>
    </div>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  tabsWrapper: {
    height: 'auto',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    width: 'initial',
    background: 'transparent',
    marginLeft: 2,
    marginRight: 2
  }
}));

const MemoizedReportTypeDataTabs = React.memo(ReportTypeDataTabs);

function ReportTypeDataTabsFormAdapter({ tab, onTabChange }) {
  const { getValues } = useFormV2Context();
  const dataSetDefinitions = getValues('dataSetDefinitions');

  return <MemoizedReportTypeDataTabs tab={tab} value={dataSetDefinitions} onTabChange={onTabChange} />;
}

export default React.memo(ReportTypeDataTabsFormAdapter);
