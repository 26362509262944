export enum VehicleColumnNameEnum {
  FOLDER_NUMBER = 'folderNumber',
  VIN = 'vin',
  PLATENUMBER = 'plateNumber',
  BRANDKEY = 'brandKey',
  MANUFACTURE_YEAR = 'manufactureYear',
  TYPEKEY = 'typeKey',
  ACCEPTABLE_CAPACITY = 'acceptableCapacity',
  DMC = 'dmc',
  EUROCLASS_KEY = 'euroClassKey',
  BELOW_THREE_AND_HALF_TONS = 'belowThreeAndHalfTons',
  VEHICLE_SUBMITTED_TO = 'vehicleSubmittedTo',
  CERTIFICATE_S_EXPIRATION_DATE = 'certificateSExpirationDate',
  STATUS_KEY = 'statusKey',
  EXPIRATION_DATE = 'expirationDate',
  MODIFIED = 'modified',
  MODIFIER = 'modifierName',
  REGISTRATION_COUNTRY = 'registrationCountry',
  IS_IN_COMPANY = 'isInCompany',
  CREW_SEATS = 'crewSeats',
  MODEL_KEY = 'modelKey'
}

export enum VehicleTableActionEnum {
  GENERATE_RAPORT = 'GENERATE_RAPORT',
  ADD_VEHICLE = 'ADD_VEHICLE',
  SEARCH = 'SEARCH',
  COPY_ALL_VEHICLES = 'COPY_ALL_VEHICLES',
  DELETE_ALL_VEHICLES = 'DELETE_ALL_VEHICLES'
}

export enum VehicleTableRowActionEnum {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  EXPIRE = 'EXPIRE',
  RESTORE = 'RESTORE',
  SHOW_DETAIL = 'SHOW_DETAIL'
}

export enum VehicleTableEditionVisibleValidator {
  ALWAYS = 'ALWAYS',
  ONLY_NEW = 'ONLY_NEW'
}

export interface VehicleCepikDetails {
  vin: string;
  plateNumber: string;
  brandKey: string;
  modelKey: string;
  manufactureYear: number;
  typeKey: string;
  seatsNumber: number;
  acceptableCapacity: number;
  dmc: number;
}

export interface VehicleCepikRequest {
  vin: string;
  nrRejestracyjny: string;
  marka: string;
  model: string;
  rokProdukcji: number;
  rodzajPojazdu: string;
  liczbaMiejscOgolem: number;
  dopuszczalnaLadownosc: number;
  dopuszczalnaMasaCalkowita: number;
}

export interface GetVehicleCepikDetails {
  vin?: string;
  registrationNumber?: string;
}

export enum AlreadyExistingVehicleError {
  VEHICLE_ALREADY_ACTIVE_IN_FOLDER = 'VEHICLE_ALREADY_ACTIVE_IN_FOLDER',
  VEHICLE_ALREADY_IN_CONSIDERED_APPLICATION = 'VEHICLE_ALREADY_IN_CONSIDERED_APPLICATION'
}

export interface IVehicleFieldsRequiredByCategory {
  [VehicleSectionFields.MODEL]: boolean;
  [VehicleSectionFields.EURO_CLASS_KEY]: boolean;
  [VehicleSectionFields.ENGINE_NUMBER]: boolean;
  [VehicleSectionFields.ENGINE_TYPE]: boolean;
  [VehicleSectionFields.FIRST_REGISTRATION_DATE]: boolean;
  [VehicleSectionFields.DATE_OF_SALE]: boolean;
}

export enum VehicleSectionFields {
  VIN = 'vin',
  PLATE = 'plate',
  BRAND = 'brand',
  MODEL = 'model',
  MANUFACTURE_YEAR = 'manufactureYear',
  TYPE = 'type',
  SEATS = 'seats',
  ACCEPTABLE_CAPACITY = 'acceptableCapacity',
  DMC = 'dmc',
  BELOW_THREE_AND_HALF_TONS = 'belowThreeAndHalfTons',
  OWNERSHIP_TYPE = 'ownershipType',
  TEMPORARY_RIGHT_OF_DISPOSITION = 'temporaryRightOfDisposition',
  RIGHT_OF_DISPOSITION_DATE = 'rightOfDispositionDate',
  CERTIFICATES_EXPIRATION_DATE = 'certificatesExpirationDate',
  COMPATIBILITY_CERTYFICATE_NUMBER = 'compatibilityCertificateNumber',
  ENGINE_TYPE = 'engineType',
  ENGINE_NUMBER = 'engineNumber',
  FIRST_REGISTRATION_DATE = 'firstRegistrationDate',
  EURO_CLASS_KEY = 'euroClassKey',
  IGNORE_EXISTING_VEHICLES = 'ignoreExistingVehicles',
  DATE_OF_SALE = 'dateOfSale'
}
