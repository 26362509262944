import { TextInputField, useFormV2Context } from '@libs/common/v2';

import { useMetaFormContext } from '@libs/meta-form/context';
import { FieldProps, FieldTypes, TextFieldProperties } from '@libs/meta-form/models';
import { useGetQuery, useMapAdditionalFieldsValidationType } from '@libs/meta-form/services';

function TextField({
  fieldId,
  label,
  lines,
  hidden,
  api,
  defaultInitialValue,
  disabled,
  inputMode,
  onlyPositiveInteger,
  maxLength,
  yupValidation
}: FieldProps<TextFieldProperties>) {
  const { setValue } = useFormV2Context();
  const { apiRegistry } = useMetaFormContext();
  useMapAdditionalFieldsValidationType(fieldId, { type: FieldTypes.TEXT });

  useGetQuery({
    requestKey: api?.get?.requestKey,
    accessor: api?.get?.accessor,
    apiRegistry,
    params: api?.get?.backendParams,
    setValue: newValue => setValue(fieldId, newValue ?? defaultInitialValue),
    fieldId
  });

  return (
    <TextInputField
      isHidden={hidden}
      name={fieldId}
      isRequired={yupValidation?.required}
      isOnlyPositiveIntegers={onlyPositiveInteger}
      inputProps={{ maxLength }}
      label={label}
      lines={lines}
      isDisabled={disabled}
      inputMode={inputMode}
    />
  );
}

export default TextField;
