import { UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { DictionaryEntryCreateRequest } from '@avispon/dictionary';
import { DictionaryEntryDetails } from '@avispon/dictionary/dist/models';
import { useSnackbar } from '@enigma/fe-ui';
import { AxiosRequestConfig } from 'axios';

import { AxiosErrorResponseType } from '@libs/common/v2/models';
import { unescapeValue } from '@libs/common/v2/utils';

import { API, endpointsConfig } from '@libs/dictionary/api';
import useAsyncErrorsHandlerV2 from '@libs/dictionary/hooks/useAsyncErrorsHandlerV2';
import { DictionaryFormValues } from '@libs/dictionary/models';

async function createDictionaryEntry(newEntry: DictionaryEntryCreateRequest) {
  const { data } = await API.dictionary.create({ body: newEntry }, {
    ...endpointsConfig.createDictionaryEntry
  } as AxiosRequestConfig);
  return data;
}

function useCreateDictionaryEntryMutation(setError: UseFormSetError<DictionaryFormValues>) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { handleAsyncErrorsV2 } = useAsyncErrorsHandlerV2<DictionaryFormValues>();
  return useMutation<
    DictionaryEntryDetails,
    AxiosErrorResponseType<DictionaryFormValues>,
    DictionaryEntryCreateRequest
  >(createDictionaryEntry, {
    onSuccess: result => {
      showSuccessSnackbar(t('dictionary:createSuccess', unescapeValue({ name: result.value })));
    },
    onError: error => handleAsyncErrorsV2(error, setError)
  });
}

export default useCreateDictionaryEntryMutation;
