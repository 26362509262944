import { usePaginatedQuery } from 'react-query';
import { FolderHistoryDetails, FolderHistorySearchFilter } from '@ibtm/domain';
import { omit } from 'lodash';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import { FolderQueryKeysEnum } from './index';

const getFolderHistoryQuery = async (_, params: FolderHistorySearchFilter & { id: string }) => {
  const fixedParams = omit(params, 'id');
  const { data } = await API.folder.getFolderHistoryDetailsPage(params.id, fixedParams, {
    ...endpointsConfig.getFolderHistoryDetailsPage
  });

  return data;
};

function useFolderHistoryQuery(params) {
  return usePaginatedQuery<IPaginatedModel<FolderHistoryDetails>>(
    [FolderQueryKeysEnum.HISTORY_LIST, params],
    getFolderHistoryQuery
  );
}

export default useFolderHistoryQuery;
