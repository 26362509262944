import { useTranslation } from 'react-i18next';

import { Button } from '@libs/common/v2';
import { ButtonsGroup } from '@libs/common/v2/components/buttonsGroup';

interface PageHeaderFormActionsProps {
  onCancel?: () => void;
  onSave?: () => void;
  isLoading?: boolean;
}

function PageHeaderFormActions({ onCancel, onSave, isLoading }: PageHeaderFormActionsProps) {
  const [t] = useTranslation();

  return (
    <ButtonsGroup>
      {onCancel && <Button onClick={onCancel} label={t('action.cancel')} variant="outlined" />}
      {onSave && <Button isPrimary onClick={onSave} label={t('action.save')} isLoading={isLoading} />}
    </ButtonsGroup>
  );
}

export default PageHeaderFormActions;
