import React, { useCallback, useRef } from 'react';
import { Resolver } from 'react-hook-form';
import { PaginatedQueryResult, QueryResult } from 'react-query';
import { Row } from 'react-table';
import { makeStyles } from '@mui/styles';
import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import { omit } from 'lodash';
import { ObjectSchema } from 'yup';

import { KeyType } from '@libs/common';
import {
  ComponentErrorBoundary,
  IAdditionalFilter,
  IPaginatedModel,
  ISectionProps,
  ISingleColumn,
  ITableAdapter,
  ITableBodyProps,
  ITableContext,
  ITablePaginationProps,
  MIN_TABLE_ELEMENTS,
  PageHeader,
  Section,
  TableContextProvider,
  TableWithoutContext,
  TMutableTableFormContextProvider
} from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { calc, important } from '@libs/common/v2/utils';

import { IBreadcrumbsItem } from '../../components/breadcrumbs/Breadcrumbs';

import TableWithoutHookContent from './TableWithoutHookContent';
import TableWithoutHookHeaderActions from './TableWithoutHookHeaderActions';

export interface OnRowEditContextProps<T extends Record<string, any>, K extends Record<string, any> = T> {
  isCreated: boolean;
  isEdited: boolean;
  tableContext: ITableContext<T, K>;
  tableInstance: ITableAdapter<T, K>;
  formContext: TMutableTableFormContextProvider;
  onSuccess: () => void;
  onError: () => void;
}

export interface ITableWithoutHookProps<T extends Record<string, any>, K extends Record<string, any> = T> {
  // Propsy do Section
  /**
   * Propsy przekazywane do kompoenetu Sekcji.
   */
  sectionProps?: Omit<ISectionProps, 'title' | 'headerActions' | 'collapsable'>;
  /**
   * Dodatkowe style komponentu Sekcji
   */
  sectionClassName?: string;
  /**
   * Dodatkowe style nagłówka Sekcji w którym jest renderowana tabela
   */
  sectionHeaderClassName?: string;
  /**
   * Dodatkowe style contentu Sekcji w którym jest renderowana tabela
   */
  sectionContentClassName?: string;
  /**
   * Dodatkowy content renderowany poniżej tabeli w sekcji
   */
  sectionAdditionalContent?: React.ReactNode;
  // Propsy do zarządania tabelą
  /**
   * Instancja tabeli uzyskiwana po wywołaniu useTable hook.
   */
  table: ITableAdapter<T, K>;
  /**
   * Obiekt zawierający początkowe parametry przekazane do query.
   */
  tableHookQueryInitialParams?: Record<string, unknown>;
  /**
   * Obiekt danych z request.
   *  Zwracane z hook'ów usePaginatedQueryTable,useQueryTable jako drugi element ([table, query])
   */
  queryResult: PaginatedQueryResult<IPaginatedModel<T>> | QueryResult<T[], unknown>;
  /**
   * State wykorzystywany do pobierania parametrów query.
   */
  tableQueryParams?: Record<string, unknown>;
  /**
   * setState wykorzystywany do aktualizacji parametrów query.
   */
  setTableQueryParams: React.Dispatch<React.SetStateAction<Record<string, unknown>>>;
  /**
   * Szerokość kolumny wyboru ( plugin ). Wykorzystana przy zaznaczeniu któregoś wiersza. Domyślnie 96.
   */
  selectionColumnWidth?: number;
  /**
   * Komponent z dodatkowymi akcjami w header tabeli.
   */
  headerActions?: React.ReactNode;
  /**
   * Lista akcji w aplikacji mobilnej
   */
  mobileHeaderActions?: Array<{
    label: string;
    onClick: () => void;
    actionKey?: string;
    isHidden?: boolean;
  }>;
  /**
   * Dodatkowe filtry do tabeli. Renderowane Popover po kliknięciu w ikonę dodatkowych filtrów w header.
   */
  additionalFilters?: IAdditionalFilter[];
  /**
   * szerokość kolumny akcji - zostawione do ewentualnych edycji, ustawione na 132 zgodnie z makietą
   */
  actionsColumnWidth?: number;
  /**
   * Tytuł tabeli.
   */
  pageTitle?: string;
  /**
   * Okruszki tabeli.
   */
  breadcrumbs?: IBreadcrumbsItem[];
  /**
   * Funkcja do renderowania akcji w kolumnie akcji.
   * Wewnątrz funkcji mamy dostęp do aktualnego row, instancji i contextu tabeli.
   */
  rowActions?: (
    row: Row<T>,
    table: { tableInstance: ITableAdapter<T, K>; tableContext: ITableContext<T, K> }
  ) => React.ReactNode;
  /**
   * Dodatkowe propsy przekazane do body tabeli
   */
  bodyProps?: Omit<ITableBodyProps<T, K>, 'emptyTableText' | 'rowActions'>;
  /**
   * Obiekt z propsami przekazanymi do komponentu TablePagination
   */
  paginationProps?: ITablePaginationProps;
  /**
   * Tekst wykorzystany przy braku wierszy do wyświetlenia
   */
  emptyTableText?: string;
  /**
   * Obiekt zawierający props potrzebne do pobrania tabeli do xlsx
   */
  xlsxDownload?: {
    /**
     * Request który zwróci dane do wsadzenia do pliku xlsx
     */
    apiRequest?: (parameters: {
      page: number;
      size: number;
      sort?: any;
      [_: string]: any;
    }) => Promise<AxiosResponse<any>>;
    /**
     * Nazwa pliku xlsx
     */
    fileName: string;
    /**
     *  Konwerter filtrów tabeli (konwersja wartości filtra na wartość przyjmowaną przez api)
     */
    filterConverter?: Record<string, (value: unknown) => Record<string, any>>;
    /**
     * Konwerter sortowania tabeli (konwersja klucza tabeli na klucz przyjmowany przez api)
     */
    sortByConverter?: Partial<Record<keyof T, any>>;
    /**
     *  Konwerter filtrów globalnych i dodatkowych
     *  (konwersja wartości filtra na wartość przyjmowaną przez api)
     */
    initialParamsConverter?: Record<string, (value: unknown) => Record<string, any>>;
    /**
     * Ścieżka do tłumaczenia danych do pliku xlsx
     */
    pathToXLSXTranslation: string;
  };
  /**
   * Schemat walidacji dla filtrów globalnych i dodatkowych
   */
  headerFiltersYupResolverSchema?: ObjectSchema;
  /**
   * Globalne filtry w header tabeli.
   */
  globalFilters?: IAdditionalFilter[];
  /**
   * Tutaj można przekazać np Dialog do wyrenderowania, aby mieć w nim dostęp do instancji i contextu tabeli
   */
  children?: React.ReactNode;

  // Props tabeli mutowalnej
  /**
   * Funkcja wywoływana przy zapisaniu edycji lub dodaniu wiersza w tabeli mutowalnej.
   * Wewnątrz funkcji mamy dostęp do wiersza zwalidowanego,
   * przy pomocy schematu przekazanego w `editFormYupResolverSchema`.
   * Oraz obiektu który zawiera informację czy wiersz jest tworzony,
   * czy edytowany oraz do kontekstu i instacji tabeli.
   * @implementacja W funkcji dostajemy jako drugi argument obiekt z funkcją onSuccess, którą wywołujemy
   * po pomyślnej edycji
   */
  onRowEditCreateConfirm?: (row: T, context?: OnRowEditContextProps<T, K>) => Promise<any> | void;
  /**
   * Jeżeli chcemy dodawać nowy row dla tabeli która może być pusta
   * tutaj przekazujemy obiekt z atrybutami jakie posiadać powinny dane
   * z backend wstawiane do tabeli
   */
  newRowScheme?: T;
  /**
   * Schemat walidacji edytowanego lub dodawanego wiersza w tabeli mutowalnej
   */
  editFormYupResolverSchema?: any;

  // Obsługa dodawania kolumn, plugin (useAdditionalColumns)
  /**
   * Maksymalna liczba dodatkowych kolumn jakie mogą zostać stworzone
   */
  maxInsertedColumnNumber?: number;
  /**
   * Obiekt z kolumnami do dodania i ścieżką do tłumaczenia header'a
   */
  columnsToInsert?: {
    pathToTranslate: KeyType;
    columns: ISingleColumn<T, K>[];
  };
  // Boolean Props
  /**
   * Wartość decyduje o wyświetleniu loader'a w tabeli
   */
  isLoading?: boolean;
  /**
   * Props mówi czy tabela jest renderowana w komponencie Page z tabami
   */
  isPageTabContent?: boolean;
  /**
   * Funkcja decyduje o zablokowaniu tabeli.
   * Wewnątrz mamy dostęp do instancji tabeli
   * oraz, gdy funkcja jest wywołana w wierszu tabeli również do danych wiersza.
   */
  checkDisabled?: (table?: ITableAdapter<T, K>, row?: Row<T>) => boolean;
  /**
   * Funkcja służy do określenia, które selecty mają być wstępnie zaznaczone.
   * Wewnątrz mamy dostęp do instancji tabeli
   * oraz, gdy funkcja jest wywołana w wierszu tabeli również do danych wiersza.
   */
  initiallyChecked?: (table?: ITableAdapter<T, K>, row?: Row<T>) => boolean;
  /**
   * Wartość decyduje o wyświetleniu paginacji na dole tabeli.
   */
  isPaginated?: boolean;
  /**
   * Wartość decyduje czy tabela ma zostać wyrenderowana w komponencie Sekcji
   */
  isSection?: boolean;
  /**
   * Wartość decyduje czy tabela renderowana w sekcji ma być jedyną zawartością taba.
   */
  isSectionFullTab?: boolean;
  /**
   * Wartość decyduje czy tabela renderowana w sekcji ma być na całą wysokość ze stylami zwykłej tabeli.
   */
  isSectionFullPage?: boolean;
  /**
   * Wartość decyduje czy tabela renderowana w sekcji ma być na całą szerokość.
   */
  isSectionFullWidth?: boolean;
  /**
   * Wartość decyduje o wyrenderowaniu ikony do otwierania/zamykania wiersza z filtrami
   */
  isFilterEnabled?: boolean;
  /**
   * Wartość decydujeo wyrenderowaniu ikony odpowiedzialnej za odświeżenie tabeli po kliknięciu.
   * Domyślna wartość `true`
   */
  isRefreshEnabled?: boolean;
  /**
   * Wartość decyduje czy tabela wyrenderowa w komponencie Sekcji może być zwinięta.
   * Domyślna wartość `true`
   */
  isCollapsable?: boolean;
  /**
   * Wartość decyduje czy tło tabeli ma być szare. Przy zwykłej tabeli domyślnie `true`,
   * przy tabeli renderowanej w Sekcji `false`
   */
  isTableGrey?: boolean;
  /**
   * Wartość decyduje czy tabela ma być mutowalna.
   */
  isTableMutable?: boolean;
  /**
   * Props do całkowitego ukrycia nagłówka tabeli włącznie z przyciskami akcji nad tabelą.
   */
  isHeaderHidden?: boolean;
  /**
   * Wartość decyduje czy sekcja posiada znak obligatoryjności przy nazwie tabeli, np. <Nazwa tabeli *>
   * Domyślna wartość `false`
   */
  isRequiredSection?: boolean;
  useOnlyCircleLoader?: boolean;
  isHiddenSelectRowHeader?: boolean;
}

/**
 * Komponent odpowiada za wyrenderowanie tabeli z przekazanych do niego danych.
 * Wykorzystany w PaginatedQueryTable, QueryTable, gdzie są pobierane
 * dane i tworzona instancja tabeli które są przekazane do tego komponentu
 */
function TableWithoutHook<T extends Record<string, any>, K extends Record<string, any> = T>({
  table,
  tableHookQueryInitialParams,
  tableQueryParams,
  setTableQueryParams,
  sectionProps,
  sectionAdditionalContent,
  sectionClassName,
  sectionHeaderClassName,
  sectionContentClassName,
  pageTitle,
  breadcrumbs,
  xlsxDownload,
  headerFiltersYupResolverSchema,
  editFormYupResolverSchema,
  headerActions,
  mobileHeaderActions,
  additionalFilters,
  paginationProps = {},
  globalFilters,
  children,
  queryResult: { refetch, isError, ...restQueryResult },
  isLoading,
  isSectionFullTab,
  isSection = isSectionFullTab,
  columnsToInsert,
  isSectionFullPage,
  isSectionFullWidth,
  isFilterEnabled = true,
  isRefreshEnabled = true,
  isCollapsable,
  isTableGrey = !isSection,
  bodyProps,
  rowActions,
  newRowScheme,
  emptyTableText,
  isTableMutable,
  isRequiredSection,
  checkDisabled = (tableInstance: ITableAdapter<T, K>, row: Record<string, any>) => {
    if (tableInstance?.state?.rowId === row?.original?.id) {
      return false;
    }
    if (tableInstance?.state?.rowId) {
      return true;
    }
    return false;
  },
  initiallyChecked = (tableInstance: ITableAdapter<T, K>, row: Record<string, any>) => {
    if (tableInstance?.state?.rowId === row?.original?.id) {
      return false;
    }
    if (tableInstance?.state?.rowId) {
      return true;
    }
    return false;
  },
  isPaginated = true,
  isPageTabContent,
  maxInsertedColumnNumber,
  isHeaderHidden = false,
  ...restTableProps
}: ITableWithoutHookProps<T, K>) {
  const classes = useStyles({ isPageTabContent, isSectionFullWidth, isSectionFullTab });

  const headerRef = useRef<HTMLDivElement>(null);

  const tableHeaderProps = {
    headerFiltersDefaultValues: tableHookQueryInitialParams,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    headerFiltersYupResolverSchema,
    globalFilters,
    isFilterEnabled,
    additionalFilters,
    xlsxDownload: !!xlsxDownload && {
      ...xlsxDownload,
      additionalParams: tableHookQueryInitialParams
    },
    isRefreshEnabled,
    headerActions,
    mobileHeaderActions,
    setTableQueryParams,
    tableQueryParams
  };

  const isPaginationVisible = useCallback(tableQueryResult => {
    return (
      tableQueryResult?.data?.totalPages > 1 ||
      tableQueryResult?.data?.numberOfElements >= MIN_TABLE_ELEMENTS ||
      tableQueryResult?.data?.length >= MIN_TABLE_ELEMENTS
    );
  }, []);

  return (
    <ComponentErrorBoundary componentName={pageTitle}>
      {isSection ? (
        <TableContextProvider
          bodyProps={{ ...bodyProps, rowActions, emptyTableText }}
          table={table}
          newRowScheme={newRowScheme}
          refetch={refetch}
          columnsToInsert={columnsToInsert}
          isFilterEnabled={isFilterEnabled}
          maxInsertedColumnNumber={maxInsertedColumnNumber}
          isPageTabContent={isPageTabContent}
          isSectionFullPage={isSectionFullPage}
          isTableMutable={isTableMutable}
          editFormYupResolverSchema={editFormYupResolverSchema as Resolver<Record<string, any>, Record<string, any>>}
          restQueryResult={restQueryResult}
          paginationProps={
            isPaginationVisible(restQueryResult) && isPaginated
              ? {
                  className: classes.pagination,
                  ...paginationProps
                }
              : null
          }
          className={classes.table}
          isTableGrey={isTableGrey}
          isSection={isSection}
          headerRowProps={{
            isWhiteBackground: true
          }}
          {...restTableProps}
          isLoading={isLoading}
          isError={isError}
          checkDisabled={checkDisabled}
          initiallyChecked={initiallyChecked}
          scrollbarOffset={24}
          tableComponent={(forwardProps: ITableWithoutHookProps<T>) => (
            <Section
              title={!isHeaderHidden && pageTitle}
              headerContent={
                !isHeaderHidden && (
                  <>
                    {sectionProps?.headerContent}
                    <TableWithoutHookHeaderActions {...tableHeaderProps} />
                  </>
                )
              }
              titleHeaderContent={sectionProps?.titleHeaderContent}
              className={clsx(classes.section, sectionClassName)}
              contentClassName={clsx(classes.sectionContent, sectionContentClassName)}
              headerClassName={sectionHeaderClassName}
              isCollapsable={isCollapsable}
              isRequiredSection={isRequiredSection}
              fillHeight={isSectionFullTab}
              {...omit(sectionProps, ['headerContent', 'titleHeaderContent'])}
            >
              <TableWithoutContext {...forwardProps} />
              {sectionAdditionalContent}
            </Section>
          )}
        >
          {children}
        </TableContextProvider>
      ) : (
        <TableWithoutHookContent
          pageHeader={
            !isHeaderHidden && (
              <PageHeader
                title={pageTitle}
                breadcrumbs={breadcrumbs}
                titleCustomStyles={{ marginTop: 0 }}
                rightSideContent={<TableWithoutHookHeaderActions {...tableHeaderProps} />}
                wrapperClassName={classes.header}
                headerRef={headerRef}
              />
            )
          }
          table={table}
          newRowScheme={newRowScheme}
          isFilterEnabled={isFilterEnabled}
          refetch={refetch}
          paginationProps={paginationProps}
          columnsToInsert={columnsToInsert}
          maxInsertedColumnNumber={maxInsertedColumnNumber}
          isPageTabContent={isPageTabContent}
          editFormYupResolverSchema={editFormYupResolverSchema as Resolver<Record<string, any>, Record<string, any>>}
          queryResult={restQueryResult}
          isLoading={isLoading}
          isTableMutable={isTableMutable}
          isError={isError}
          checkDisabled={checkDisabled}
          initiallyChecked={initiallyChecked}
          isTableGrey={isTableGrey}
          bodyProps={bodyProps}
          rowActions={rowActions}
          emptyTableText={emptyTableText}
          isPaginated={isPaginated}
          headerRef={headerRef}
          {...restTableProps}
        >
          {children}
        </TableWithoutHookContent>
      )}
    </ComponentErrorBoundary>
  );
}

const useStyles = makeStyles<
  Theme,
  { isPageTabContent: boolean; isSectionFullWidth: boolean; isSectionFullTab: boolean }
>(theme => ({
  header: {
    zIndex: theme.zIndex.drawer - 2,
    position: 'sticky'
  },
  section: {
    width: 'auto',
    height: ({ isSectionFullTab }) => (isSectionFullTab ? important(calc('100% - 24px')) : '100%'),
    '& .MuiCollapse-wrapper': {
      height: '100%'
    },
    '& .MuiCollapse-wrapperInner': {
      height: '100%'
    },
    paddingBottom: ({ isSectionFullTab }) => isSectionFullTab && important('24px')
  },
  sectionContent: {
    padding: important(0),
    display: 'flex',
    height: ({ isPageTabContent }) => (isPageTabContent ? important(calc('100% - 55px')) : '100%')
  },
  table: {
    minHeight: 'auto',
    width: ({ isSectionFullWidth }) => (isSectionFullWidth ? '100%' : 'auto'),
    '& .table-cell-action, & .table-cell-selection': {
      backgroundColor: theme.palette.white
    }
  },
  pagination: {
    backgroundColor: theme.palette.white
  },
  headerRow: {
    backgroundColor: theme.palette.white
  }
}));

export default TableWithoutHook;
