import { useTranslation } from 'react-i18next';
import { SubjectHistoryDetails as SubjectHistoryDetailsClient } from '@ibtm/client-domain';
import { ContactHistorySnapshot, SubjectHistoryDetails } from '@ibtm/domain';

import { CamelCasePath, SelectOption, useCreateColumns } from '@libs/common/v2';
import { getValue } from '@libs/common/v2/utils';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

export interface SubjectHistoryDetailsExtend {
  contactAddresses: {
    contactAddress: ContactHistorySnapshot;
  };
}

export default function useSubjectHistoryColumns(
  legalFormKey: SelectOption<string>,
  visibleColumns?: Array<
    CamelCasePath<(SubjectHistoryDetails | SubjectHistoryDetailsClient) & SubjectHistoryDetailsExtend>
  >
) {
  const [t] = useTranslation();
  const isNaturalPerson = legalFormKey.value === DomainDictionaryEntry.LEGAL_NATURE_KEY.NATURAL_PERSON;

  const { createColumns } = useCreateColumns<SubjectHistoryDetails | SubjectHistoryDetailsClient>({
    pathToTranslate: 'folder:details.subjectHistory'
  });

  const columns = createColumns(
    [
      {
        id: 'name',
        type: 'TEXT',
        customAccessor: row =>
          isNaturalPerson ? getValue(`${row?.givenName ?? ''} ${row.surname ?? ''}`.trim()) : row?.name
      },
      {
        id: 'nip',
        type: 'TEXT'
      },
      {
        id: 'legalFormKey',
        type: 'DICTIONARY_SINGLE_SELECT',
        dictionaryName: DomainDictionaryEnum.LEGAL_NATURE,
        width: 250
      },
      {
        id: 'contactAddresses',
        type: 'TEXT',
        header: 'folder:details.section.contactData',
        customAccessor: row => {
          return row?.contactAddresses
            ?.map(contactAddress => {
              return `${t('folder:shortcut.phone')}. ${
                contactAddress?.contactAddress?.phoneNumber || t('emptyMark')
              }, ${contactAddress?.contactAddress?.email || t('emptyMark')}`;
            })
            .join(', ');
        },
        width: 280,
        isSortable: false
      },
      {
        id: 'created',
        header: 'modified',
        type: 'DATE'
      },
      {
        id: 'applicationNumber',
        type: 'TEXT'
      }
    ],
    visibleColumns
  );

  return { columns };
}
