import { useMemo } from 'react';

import { InputMode, useMultipleSelectColumn } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DomainDictionaryEntry, DomainUIElementEnum } from '@libs/domain/config';
import {
  IVehicleActionKeys,
  VehicleClearSelectedActionButton,
  VehicleFormData,
  VehicleSectionFields,
  VehiclesTable,
  VehicleTableActionEnum,
  VehicleTableEditionVisibleValidator,
  VehicleTableRowActionEnum
} from '@libs/domain/vehicle';

import { statusesWithBlockedEdit } from '../../../utils';

interface IProps {
  applicationStatusKey?: string;
  applicationId: string;
  folderId: string;
  folderNumber: string;
  transferredFoldersIds?: string[];
  folderTypeKey: string;
  showDownloadVehiclesButton?: boolean;
  hiddenActions?: VehicleTableRowActionEnum[];
  actionKeys?: IVehicleActionKeys;
  vehicleModificationConcernsKey?: string;
  tableActionKeys: Array<DomainUIElementEnum>;
  applicationType?: string;
  applicationCategory?: string;
  isSection?: boolean;
  editValidator?: VehicleTableEditionVisibleValidator;
  hiddenDetailsFields?: VehicleSectionFields[];
  detailsFieldsMode?: { [key in keyof VehicleFormData]?: InputMode };
  onVehicleSearchSuccess?: () => void;
}

function VehicleTab({
  applicationStatusKey,
  applicationId,
  applicationType,
  applicationCategory,
  folderTypeKey,
  showDownloadVehiclesButton,
  folderId,
  folderNumber,
  transferredFoldersIds,
  vehicleModificationConcernsKey,
  hiddenActions,
  actionKeys,
  tableActionKeys,
  isSection,
  editValidator = VehicleTableEditionVisibleValidator.ALWAYS,
  hiddenDetailsFields,
  detailsFieldsMode,
  onVehicleSearchSuccess
}: IProps) {
  const { ADD_VEHICLE, GENERATE_RAPORT, COPY_ALL_VEHICLES, DELETE_ALL_VEHICLES, SEARCH } = VehicleTableActionEnum;
  const { DELETE, EDIT, SHOW_DETAIL } = VehicleTableRowActionEnum;
  const { viewMode } = useViewModesV2();
  const multipleSelectColumnPlugin = useMultipleSelectColumn(
    (selected: string[], handleClose: () => void) =>
      selected ? (
        <VehicleClearSelectedActionButton selected={selected} handleClose={handleClose} applicationId={applicationId} />
      ) : null,
    true
  );

  const visibleTableActions = useMemo(() => {
    const applicationTypesWithoutCreateVehicle = [
      DomainDictionaryEntry.APPLICATION_TYPE.PB11B,
      DomainDictionaryEntry.APPLICATION_TYPE.PB11C,
      DomainDictionaryEntry.APPLICATION_TYPE.PB11D,
      DomainDictionaryEntry.APPLICATION_TYPE.spoOwnNeedsPwr3,
      DomainDictionaryEntry.APPLICATION_TYPE.spoOwnNeedsPwr3Reprint
    ];
    const applicationTypesWithoutSearchAndCopyAll = [DomainDictionaryEntry.APPLICATION_TYPE.PB11A];
    return [
      !applicationTypesWithoutCreateVehicle.includes(applicationType) && !viewMode && ADD_VEHICLE,
      GENERATE_RAPORT,
      ...(!applicationTypesWithoutSearchAndCopyAll.includes(applicationType) && !viewMode
        ? [COPY_ALL_VEHICLES, SEARCH]
        : []),
      ...(!viewMode ? [DELETE_ALL_VEHICLES, DELETE_ALL_VEHICLES] : [])
    ];
  }, [ADD_VEHICLE, COPY_ALL_VEHICLES, DELETE_ALL_VEHICLES, GENERATE_RAPORT, SEARCH, applicationType, viewMode]);

  const editIsBlocked = useMemo(
    () => statusesWithBlockedEdit.some(status => status === applicationStatusKey),
    [applicationStatusKey]
  );

  return (
    <VehiclesTable
      folderId={folderId}
      folderNumber={folderNumber}
      transferredFoldersIds={transferredFoldersIds}
      folderTypeKey={folderTypeKey}
      applicationId={applicationId}
      applicationType={applicationType}
      applicationCategory={applicationCategory}
      showDownloadVehiclesButton={showDownloadVehiclesButton}
      queryParams={{
        applicationIdIn: [applicationId]
      }}
      visibleTableActions={visibleTableActions.filter(
        action => !hiddenActions?.includes(action as unknown as VehicleTableRowActionEnum)
      )}
      visibleTableRowActions={[...(!viewMode ? [DELETE, EDIT] : []), SHOW_DETAIL].filter(
        action => !hiddenActions?.includes(action)
      )}
      tablePlugins={multipleSelectColumnPlugin && editIsBlocked ? [multipleSelectColumnPlugin] : []}
      actionKeys={actionKeys}
      tableActionKeys={tableActionKeys}
      isSection={isSection}
      editValidator={editValidator}
      hiddenDetailsFields={hiddenDetailsFields}
      detailsFieldsMode={detailsFieldsMode}
      isCollapsable
      onVehicleSearchSuccess={onVehicleSearchSuccess}
      vehicleModificationConcernsKey={vehicleModificationConcernsKey}
    />
  );
}

export default VehicleTab;
