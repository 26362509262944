import { Navigate } from 'react-router-dom';

import { IRedirectPaths } from '@libs/common/v2/router/RouterContext';

import { useAuthSelector } from '@libs/auth/store/reducers';

export interface IProps {
  redirectConfig?: IRedirectPaths;
}

function DefaultRedirect({ redirectConfig }: IProps) {
  const { personalData, permissions } = useAuthSelector(({ auth }) => auth.user);

  const { authenticatedUserRedirectPath, defaultRedirectPath } = redirectConfig;
  if (!personalData) {
    return null;
  }
  return (
    <Navigate to={permissions && permissions.length ? authenticatedUserRedirectPath : defaultRedirectPath} replace />
  );
}

export default DefaultRedirect;
