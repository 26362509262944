import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LogicalOperator } from '@ibtm/domain';

import {
  AutocompleteSelectField,
  Checkbox,
  GridItem,
  GridLayout,
  NumberInputField,
  Section,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';

import { IResourceTypeFormFields } from '../../../model';

const defaultDmcLessOrEqual = 6001;
const defaultLogicalOperator = LogicalOperator.OR;
const defaultAcceptableCapacityLessOrEqual = 3501;

export type FreeSaleRulesProps = {
  defaultValues: {
    chargePermissions: boolean;
  };
};

export const FreeSaleRuleSection = ({ defaultValues: { chargePermissions = true } }: FreeSaleRulesProps) => {
  const { setValue, getValues } = useFormV2Context();

  const [showRulesFields, setShowRulesFields] = useState(chargePermissions);
  const [t] = useTranslation();
  const setDefaultValues = useCallback(() => {
    setValue(
      typedNameV2<IResourceTypeFormFields>('cargoTransportationExclusions.dmcLessOrEqual'),
      defaultDmcLessOrEqual
    );
    setValue(
      typedNameV2<IResourceTypeFormFields>('cargoTransportationExclusions.logicalOperator'),
      defaultLogicalOperator
    );
    setValue(
      typedNameV2<IResourceTypeFormFields>('cargoTransportationExclusions.acceptableCapacityLessOrEqual'),
      defaultAcceptableCapacityLessOrEqual
    );
  }, [setValue]);

  useEffect(() => {
    const shouldSetDefaultValue = !getValues(
      typedNameV2<IResourceTypeFormFields>('cargoTransportationExclusions.logicalOperator')
    );
    if (chargePermissions && shouldSetDefaultValue) {
      setDefaultValues();
    }
    return () => {
      setValue(typedNameV2<IResourceTypeFormFields>('cargoTransportationExclusions'), null);
    };
  }, [setValue, setDefaultValues, getValues, chargePermissions]);

  const handleToggleRulesFields = () => {
    const value = !showRulesFields;
    setShowRulesFields(value);
    if (value) {
      setDefaultValues();
    } else {
      setValue(typedNameV2<IResourceTypeFormFields>('cargoTransportationExclusions'), null);
    }
  };

  return (
    <Section title={t('resource:section.freeSaleRules')} isModalSection isCollapsable>
      <GridLayout itemProps={{ md: 6, xs: 12 }}>
        <GridItem xs={12}>
          <Checkbox
            isChecked={showRulesFields}
            label={t('resource:fields.chargePermissions')}
            onChange={handleToggleRulesFields}
            rowLayout
          />
        </GridItem>
        {showRulesFields && (
          <NumberInputField
            name={typedNameV2<IResourceTypeFormFields>('cargoTransportationExclusions.dmcLessOrEqual')}
            label={t('resource:fields.dmc')}
          />
        )}
        {showRulesFields && (
          <AutocompleteSelectField
            options={[
              {
                name: t('resource:logicalOperators.or'),
                value: LogicalOperator.OR
              },
              {
                name: t('resource:logicalOperators.and'),
                value: LogicalOperator.AND
              }
            ]}
            name={typedNameV2<IResourceTypeFormFields>('cargoTransportationExclusions.logicalOperator')}
            label={t('resource:fields.logicalOperator')}
            isClearable={false}
            stringValue
          />
        )}
        {showRulesFields && (
          <NumberInputField
            name={typedNameV2<IResourceTypeFormFields>('cargoTransportationExclusions.acceptableCapacityLessOrEqual')}
            label={t('resource:fields.acceptableCapacityLessOrEqual')}
          />
        )}
      </GridLayout>
    </Section>
  );
};
