import { useCallback } from 'react';
import { AccountSnapshot } from '@avispon/user/dist/models';

import { useDialog } from '@libs/common/v2';

import { UserModalSelect } from '@libs/user/components';

function useUserModalSelect() {
  const { openDialog } = useDialog();

  const showDialog = useCallback(
    (onConfirm: (data: AccountSnapshot) => void, selectUserLabel: string) => {
      openDialog(({ closeDialog }) => (
        <UserModalSelect closeDialog={closeDialog} onConfirm={onConfirm} selectUserLabel={selectUserLabel} />
      ));
    },
    [openDialog]
  );

  const openUserSelectDialog = (onConfirm: (data: AccountSnapshot) => void, selectUserLabel: string) => {
    showDialog(onConfirm, selectUserLabel);
  };

  return {
    openUserSelectDialog
  };
}

export default useUserModalSelect;
