import { TransitScheduleDatesSearchFilter } from '@ibtm/domain/dist/models';

import { useTableLayoutProps } from '@libs/common/v2';

import { useTransitDatesQuery } from '../api';

import { useTransitDatesColumns } from '.';

function useTransitDatesTable(transitScheduleId: string, queryParams?: TransitScheduleDatesSearchFilter) {
  const { columns } = useTransitDatesColumns();

  return useTableLayoutProps({
    tableHookQuery: useTransitDatesQuery,
    tableHookQueryInitialParams: { transitScheduleId, ...queryParams },
    tableHookOptions: {
      columns,
      tableOptions: {
        initialState: {
          pageSize: 10,
          sortBy: [{ id: 'departure', desc: false }]
        }
      }
    }
  });
}

export default useTransitDatesTable;
