import { QueryConfig, useQuery } from 'react-query';
import { FinancialSecuritySummarySnapshot } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import { FinancialSecurityQueryKeysEnum } from './FinancialSecurityQueryKeysEnum';

const getSummary = async (_: any, { folderId }) => {
  const { data } = await API.financialSecurity.getFinancialSecuritySummary(folderId, {
    ...endpointsConfig.getFinancialSecuritySummary
  });
  return data;
};

const getSummaryClient = async () => {
  const { data } = await API.client.financialSecurity.getFinancialSecuritySummary('', '', {
    ...endpointsConfig.getFinancialSecuritySummary
  });
  return data;
};

export default function useFinancialSecuritySummaryQuery(
  params: { folderId: string },
  queryConfig: QueryConfig<any, unknown> = {}
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery<FinancialSecuritySummarySnapshot>(
    [FinancialSecurityQueryKeysEnum.FINANCIAL_SECURITY_SUMMARY, params],
    getQuery(getSummaryClient, getSummary),
    queryConfig
  );
}
