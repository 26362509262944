import { FolderLimitDetails as FolderLimitDetailsClient } from '@ibtm/client-domain';
import { FolderLimitDetails } from '@ibtm/domain';

import { useCreateColumns } from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';

function useSingleAuthorizationLimitsColumns() {
  const { createColumns } = useCreateColumns<FolderLimitDetails | FolderLimitDetailsClient>({
    pathToTranslate: 'folder:details.tab.singleAuthorization.section.basicLimit.field'
  });

  const columns = createColumns([
    {
      id: 'countryCodeKey',
      header: 'country',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.COUNTRY_CODE,
      accessor: 'countryCodeKey',
      isFilterable: false
    },
    {
      id: 'resourceFormNameKey',
      header: 'nameKey',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_NAME,
      accessor: 'resourceFormNameKey',
      isFilterable: false,
      width: 250
    },
    {
      id: 'used',
      type: 'NUMBER',
      header: 'downloaded',
      accessor: 'used'
    },
    {
      id: 'returned',
      type: 'NUMBER',
      header: 'returned',
      accessor: 'returned',
      width: 200
    },
    {
      id: 'suspended',
      type: 'NUMBER',
      accessor: 'suspended'
    },
    {
      id: 'available',
      type: 'NUMBER',
      header: 'downloadable',
      accessor: 'available'
    },
    {
      id: 'limit',
      type: 'NUMBER',
      accessor: 'limit'
    }
  ]);

  return { columns };
}

export default useSingleAuthorizationLimitsColumns;
