import { PricingItemDetails } from '@ibtm/domain';

import { useCreateColumns } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

const usePricingItemsTableColumns = () => {
  const { translate } = useDictionaryTranslations();

  const { createColumns } = useCreateColumns<PricingItemDetails>({
    pathToTranslate: 'pricing:field'
  });

  const columns = createColumns([
    {
      id: 'permissionTypeKey',
      type: 'TEXT',
      customAccessor: row => translate(DomainDictionaryEnum.PERMISSION_TYPE, row.permissionTypeKey),
      isSortable: false,
      width: 400
    },
    {
      type: 'NUMBER',
      accessor: 'amount',
      isSortable: false,
      width: 100
    },
    {
      type: 'NUMBER',
      accessor: 'unitPrice',
      isSortable: false
    },
    {
      type: 'NUMBER',
      accessor: 'price',
      isSortable: false
    }
  ]);

  return { columns };
};

export default usePricingItemsTableColumns;
