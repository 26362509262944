import { CivicBoardCalculateAllFolderLimitsRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

function calculateForAllFolders(params: CivicBoardCalculateAllFolderLimitsRequest) {
  return API.civicBoard.calculateFolderLimitForAllFolders({
    resourceTypeId: params.resourceTypeId,
    force: params.force
  });
}

function useCalculateLimitForAllFoldersMutation() {
  return useMutation(calculateForAllFolders);
}

export default useCalculateLimitForAllFoldersMutation;
