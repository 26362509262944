import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { useConfirmDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { getMetaFormQueryKey } from '@libs/meta-form/services/useGetQuery';

import { FinancialSecurityQueryKeysEnum, useFinancialSecurityDeleteMutation } from '../../api';

function useFinancialSecurityDeleteAction() {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: deleteFinanceSecurity } = useFinancialSecurityDeleteMutation();
  const [confirm] = useConfirmDialog();

  const handleFinancialSecurityDelete = ({
    financialSecurityId,
    expirationDate,
    onSuccess
  }: {
    financialSecurityId: string;
    expirationDate: string;
    onSuccess?: () => void;
  }) => {
    confirm({
      title: t('financialSecurity:action.delete.dialogTitle'),
      shortTitle: t('global:dialog.deleteConfirmTitle'),
      message: t('financialSecurity:action.delete.confirmationText', {
        expirationDate
      }),
      confirmType: 'danger',
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        deleteFinanceSecurity(
          { financialSecurityId },
          {
            onSuccess: () => {
              queryCache.invalidateQueries(FinancialSecurityQueryKeysEnum.FINANCIAL_SECURITY_LIST);
              queryCache.invalidateQueries(FinancialSecurityQueryKeysEnum.FINANCIAL_SECURITY_SUMMARY);
              // queryKey generowany przez metaform
              queryCache.invalidateQueries(getMetaFormQueryKey('GET_FINANCIAL_SECURITY_SUMMARY'));

              showSuccessSnackbar(t('financialSecurity:action.delete.successMessage'));
              closeDialog();
              onSuccess();
            },
            onSettled: () => {
              setConfirmLoading(false);
            }
          }
        );
      }
    });
  };

  return { handleFinancialSecurityDelete };
}

export default useFinancialSecurityDeleteAction;
