import { MoneyTransferSearchFilter, MoneyTransferSnapshot } from '@ibtm/domain';

import { CamelCasePath, useTableLayoutProps } from '@libs/common/v2';

import { useMoneyTransfersForApplicationQuery } from '../api';

import useMoneyTransfersTableColumns from './useMoneyTransfersTableColumns';

export function useMoneyTransfersForApplicationTable(
  applicationId: string,
  visibleColumns: Array<CamelCasePath<MoneyTransferSnapshot>>,
  queryParams?: MoneyTransferSearchFilter
) {
  const { columns } = useMoneyTransfersTableColumns(visibleColumns);

  return useTableLayoutProps({
    tableHookQuery: useMoneyTransfersForApplicationQuery,
    tableHookOptions: {
      columns
    },
    tableHookQueryInitialParams: { applicationId, ...queryParams }
  });
}

export default useMoneyTransfersForApplicationTable;
