import { useTranslation } from 'react-i18next';

import { Dialog, TableLayout, useDialog } from '@libs/common/v2';

import { useListPermissionsForGroupTable } from './useListPermissionsForGroupTable';

function useGroupPermissionDetailsDialog() {
  const [t] = useTranslation();
  const { openDialog } = useDialog();

  const tableProps = useListPermissionsForGroupTable();

  const showGroupPermissionDetailsDialog = (groupName: string, groupId: string) => {
    openDialog(({ closeDialog }) => {
      return (
        <Dialog
          title={t('user:dialog.groupPermission', { groupName })}
          onCancel={closeDialog}
          dialogSize="large"
          isOpen
        >
          <TableLayout {...tableProps} tableHookQueryInitialParams={{ groupId }} isSection isHeaderHidden />
        </Dialog>
      );
    });
  };

  return { showGroupPermissionDetailsDialog };
}

export default useGroupPermissionDetailsDialog;
