import { useTranslation } from 'react-i18next';

import { partialTranslate } from '@libs/common';
import { DatepickerField, GridLayout, InputMode, Section, typedNameV2 } from '@libs/common/v2';
import { convertDateToDateFormat } from '@libs/common/v2/utils';

import { IProceedingDetailsClient } from '../../../models';

function LicenseCopiesReturn() {
  const [t] = useTranslation();
  const getTranslation = partialTranslate('roadTransport:fields');

  return (
    <Section title={t('roadTransport:sections.returnForLicenseExtracts')} isCollapsable>
      <GridLayout itemProps={{ xs: 6 }}>
        <DatepickerField
          name={typedNameV2<IProceedingDetailsClient>('timeToReturnLicenseCopies')}
          label={getTranslation('timeToReturnLicenseCopies')}
          viewModeDateParser={convertDateToDateFormat}
          inputMode={InputMode.VIEW}
        />
        <DatepickerField
          name={typedNameV2<IProceedingDetailsClient>('licenseCopiesReturnDate')}
          label={getTranslation('licenseCopiesReturnDate')}
          viewModeDateParser={convertDateToDateFormat}
        />
      </GridLayout>
    </Section>
  );
}

export default LicenseCopiesReturn;
