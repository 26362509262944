import { SingleUseSubmissionUpdateDecisionRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function addDecision(requestData: SingleUseSubmissionUpdateDecisionRequest & { singleUseSubmissionItemId: string }) {
  const { singleUseSubmissionItemId, ...rest } = requestData;
  return API.civicBoard.updateSingleUsePermissionSubmissionDecision(singleUseSubmissionItemId, rest, {
    ...endpointsConfig.createSingleUsePermissionSubmission
  });
}

function useAddSingleUseDecisionMutation({ onSuccess }) {
  return useMutation(addDecision, onSuccess);
}

export default useAddSingleUseDecisionMutation;
