import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { ApplicationDetails as ApplicationDetailsClient } from '@ibtm/client-domain';
import { AddressUpdateRequest, SubjectAddressDetails, SubjectDetailsExtended } from '@ibtm/domain';
import { isUndefined } from 'lodash';

import { KeyType } from '@libs/common';
import {
  Button,
  DatepickerField,
  Dialog,
  DictionarySelectField,
  FormV2Context,
  GridItem,
  GridLayout,
  InputMode,
  Section,
  TextInputField,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { convertDateToDateFormat, getCalendarDate } from '@libs/common/v2/utils';

import { DictionaryEntryTypeEnum } from '@libs/dictionary';

import { SubjectAddressDetailsUnion } from '@libs/domain/address';
import { Application, ApplicationQueryKeysEnum, useApplicationAddressesQuery } from '@libs/domain/application';
import { DomainDictionaryEntry, DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';
import { useUpdateAddressMutation } from '@libs/domain/folder';
import {
  addressParse,
  SubjectQueryKeysEnum,
  useRegonDataQuery,
  useSubjectDetailsExtendedQuery
} from '@libs/domain/subject';

import { useEditSubjectMutation } from '../../../application/api';

interface Props {
  open: boolean;
  setIsOpen: (param: boolean) => void;
  isViewOnly?: boolean;
  applicationData?: Application | ApplicationDetailsClient;
  onFolderCreation?: boolean;
  folderCreateValues?: any;
  queryParams?: Record<string, Array<string>>;
  resetForm?: (data?: any) => void;
  onLoading?: (isLoading: boolean) => void;
}
interface IData {
  local: {
    subject: SubjectDetailsExtended;
    mainAddress: SubjectAddressDetails;
  };
  regon: {
    subject: SubjectDetailsExtended;
    mainAddress: SubjectAddressDetails;
    businessEndDate: string;
    businessRenewalDate: string;
    businessStartDate: string;
    businessSuspensionDate: string;
    shortenName: string;
  };
}

interface ErrorResponse {
  response: {
    data: {
      codes: Array<string>;
    };
  };
}

const typeCheckError = (err: unknown): ErrorResponse => {
  if (Object.prototype.hasOwnProperty.call(err, 'response')) {
    if (
      Object.prototype.hasOwnProperty.call(err['response'], 'data') &&
      Object.prototype.hasOwnProperty.call(err['response']['data'], 'codes')
    ) {
      return err as ErrorResponse;
    }
  }
  return null;
};

function RegonDataModal({
  open,
  setIsOpen,
  isViewOnly = false,
  applicationData,
  onFolderCreation,
  folderCreateValues,
  queryParams,
  resetForm,
  onLoading
}: Props) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { mutate: updateAddress, isLoading: isLoadingUpdateSubjectAddress } = useUpdateAddressMutation();
  const [updateSubject, mutationState] = useEditSubjectMutation();
  const { showSuccessSnackbar } = useSnackbar();

  const {
    control,
    setValue: setLocalFormValue,
    getValues: getLocalFormValues,
    reset
  } = useForm({
    mode: 'onChange',
    criteriaMode: 'all'
  });

  const { getValues } = useFormV2Context();

  const getSectionTitle = (name: string) => t(`subject:regonModal.section.${name}` as KeyType);
  const nip = !applicationData ? (getValues('subject.nip') as string) : applicationData?.subject?.nip;

  const { data: regonData, isLoading: isRegonDataLoading } = useRegonDataQuery(
    nip || folderCreateValues?.subject?.nip,
    {
      onError: err => {
        const errResponse = typeCheckError(err);
        if (errResponse?.response?.data?.codes.includes('REGON_DATA_NOT_FOUND')) {
          setIsOpen(false);
        }
      },
      onSettled: () => {
        onLoading?.(false);
      }
    }
  );

  const { data: applicationSubjectData } = useSubjectDetailsExtendedQuery(applicationData?.subject.id, {
    enabled: !onFolderCreation && !!applicationData
  });

  const { data: applicationAddressDataQuery = {} } = useApplicationAddressesQuery(
    { ...(queryParams ?? { applicationIdIn: [applicationData?.id] }) },
    {
      enabled: !onFolderCreation || !!applicationData
    }
  );
  const applicationAddressData: SubjectAddressDetailsUnion[] =
    ('content' in applicationAddressDataQuery && applicationAddressDataQuery.content) ||
    ('addresses' in applicationAddressDataQuery && applicationAddressDataQuery.addresses);

  const setData = async () => {
    reset({
      regon: {
        subject: regonData,
        mainAddress: addressParse(regonData),
        businessEndDate: regonData?.businessEndDate,
        businessRenewalDate: regonData?.businessRenewalDate,
        businessStartDate: regonData?.businessStartDate,
        businessSuspensionDate: regonData?.businessSuspensionDate,
        shortenName: regonData?.shortenName
      },
      local: {
        subject: !applicationData ? (getValues()?.subject as SubjectDetailsExtended) : applicationSubjectData,
        mainAddress: !applicationData
          ? (getValues()?.addresses?.MAIN as SubjectAddressDetailsUnion)
          : applicationAddressData?.find(
              (address: SubjectAddressDetailsUnion) => address.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.MAIN
            )
      }
    });
  };

  useEffect(() => {
    if (regonData && !applicationData) setData();
    if (applicationData && applicationAddressData && applicationSubjectData) setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regonData, applicationSubjectData, applicationAddressData]);

  useEffect(() => {
    onLoading?.(true);
  }, [onLoading]);

  const handleAddressUpdate = () => {
    const data = getLocalFormValues() as IData;
    if (!folderCreateValues?.subject?.nip) {
      updateAddress(
        {
          addresses: [
            {
              formData: {
                typeKey: DomainDictionaryEntry.ADDRESS_TYPE.MAIN,
                sameAsMainAddress: false,
                address: {
                  ...data.regon.mainAddress,
                  version: data.local.mainAddress.version,
                  voivodeshipKey: data.regon.mainAddress.voivodeshipKey
                } as AddressUpdateRequest
              },
              id: data.local.mainAddress.id
            }
          ]
        },
        {
          onSuccess: () => {
            showSuccessSnackbar(t('subject:regonModal.messages.addressDataAccepted'));
            queryCache.invalidateQueries(ApplicationQueryKeysEnum.APPLICATION_ADDRESSES);
          },
          onSettled: () => {
            setIsOpen(false);
          }
        }
      );
    } else {
      const adresses = folderCreateValues?.subject?.addresses?.map(address => {
        if (address.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.MAIN) {
          return {
            ...address,
            address: {
              ...address.address,
              ...data.regon.mainAddress,
              voivodeshipKey: data.regon.mainAddress.voivodeshipKey
            } as AddressUpdateRequest
          };
        }
        return address;
      }) as AddressUpdateRequest[];
      resetForm({
        subject: {
          ...folderCreateValues?.subject,
          addresses: adresses
        } as SubjectAddressDetails
      });
      setIsOpen(false);
    }
  };
  const handleSubjectUpdate = () => {
    const data = getLocalFormValues() as IData;
    const mainAddress = applicationAddressData?.find(
      address => address?.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.MAIN
    );

    if (!folderCreateValues?.subject?.nip) {
      updateSubject(
        {
          subjectId: data.local.subject.id,
          formData: {
            name: data?.regon?.subject?.name,
            shortName: data?.regon?.shortenName,
            subjectTypeKey: data?.local?.subject?.subjectTypeKey,
            givenName: data?.regon?.subject?.givenName,
            surname: data?.regon?.subject?.surname,
            nip: data?.regon?.subject?.nip,
            regon: data?.regon?.subject?.regon,
            krs: data?.regon?.subject?.krs || data?.local?.subject?.krs,
            businessStartDate: getCalendarDate(data?.regon?.businessStartDate),
            businessEndDate: getCalendarDate(data?.regon?.businessEndDate),
            legalFormKey: data?.local?.subject?.legalFormKey,
            version: data.local.subject.version,
            countryOfOrigin: data?.local?.subject?.countryOfOrigin,
            mainAddress: {
              ...mainAddress,
              version: mainAddress.version
            }
          }
        },
        {
          onSuccess: () => {
            showSuccessSnackbar(t('subject:regonModal.messages.regonDataAccepted'));
            queryCache.invalidateQueries(SubjectQueryKeysEnum.SUBJECT_EXTENDED);
          },
          onSettled: () => {
            setIsOpen(false);
          }
        }
      );
    } else {
      resetForm({
        ...folderCreateValues,
        subject: {
          ...folderCreateValues?.subject,
          businessStartDate: data?.regon?.businessStartDate,
          businessEndDate: data?.regon?.businessEndDate,
          name: data?.regon?.shortenName,
          shortName: data?.regon?.shortenName,
          givenName: data?.regon?.subject?.givenName,
          surname: data?.regon?.subject?.surname,
          regon: data?.regon?.subject?.regon,
          krs: data?.regon?.subject?.krs || (folderCreateValues?.subject?.krs as string),
          legalFormKey: folderCreateValues?.subject?.legalFormKey as string,
          countryOfOrigin: folderCreateValues?.subject?.countryOfOrigin as string
        } as SubjectDetailsExtended
      });
      setIsOpen(false);
    }
  };
  const isPostCodeValid = useMemo(() => {
    const postCodeRegex = /\d{2}-\d{3}/;
    const mainPostCodeAddress =
      Array.isArray(applicationAddressData) &&
      applicationAddressData?.find(
        (address: SubjectAddressDetailsUnion) => address?.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.MAIN
      ).postCode;

    return postCodeRegex.test(mainPostCodeAddress);
  }, [applicationAddressData]);

  const values = useMemo(
    () => ({ control, setValue: setLocalFormValue, getValues: getLocalFormValues }),
    [control, getLocalFormValues, setLocalFormValue]
  );

  if (isUndefined(regonData)) {
    return null;
  }

  return (
    <FormV2Context.Provider value={values}>
      <Dialog
        title={t('subject:regonModal.title')}
        onCancel={() => setIsOpen(false)}
        customActions={
          !isViewOnly ? (
            <>
              <Button
                isSecondary
                variant="outlined"
                onClick={() => setIsOpen(false)}
                label={t('action.cancel')}
                isLoading={isRegonDataLoading}
                size="large"
                isNoMargin
              />
              <Button
                isSecondary
                variant="outlined"
                onClick={() => handleAddressUpdate()}
                label={t('subject:regonModal.button.acceptAddressData')}
                isLoading={isRegonDataLoading || mutationState?.isLoading || isLoadingUpdateSubjectAddress}
                disabled={isUndefined(regonData) || !isPostCodeValid}
                actionKey={DomainUIElementEnum.APPLICATION_DOWNLOAD_REGON_ACCEPT_ADDRESS_BUTTON}
                tooltipTitle={!isPostCodeValid && t('validation:incompatiblePostCodeFormat')}
                size="large"
                isNoMargin
              />
              <Button
                isPrimary
                variant="contained"
                onClick={() => handleSubjectUpdate()}
                label={t('subject:regonModal.button.acceptRegonData')}
                isLoading={isRegonDataLoading || mutationState?.isLoading || isLoadingUpdateSubjectAddress}
                disabled={isUndefined(regonData)}
                actionKey={DomainUIElementEnum.APPLICATION_DOWNLOAD_REGON_ACCEPT_DATA_BUTTON}
                size="large"
                isNoMargin
              />
            </>
          ) : (
            <Button
              isSecondary
              variant="outlined"
              onClick={() => setIsOpen(false)}
              label={t('action.close')}
              isLoading={isRegonDataLoading}
              size="large"
              isNoMargin
            />
          )
        }
        dialogSize="large"
        isOpen={open}
      >
        <GridLayout itemProps={{ xs: 6 }}>
          <Section title={getSectionTitle('subject')}>
            <SubjectSection />
          </Section>
          <Section title={getSectionTitle('regonSubject')}>
            <SubjectSection isFromRegon />
          </Section>
          <Section title={getSectionTitle('mainAddress')}>
            <AddressSection />
          </Section>
          <Section title={getSectionTitle('regonMainAddress')}>
            <AddressSection isFromRegon />
          </Section>
          <GridItem xs={12}>
            <Section title={getSectionTitle('regonActivity')}>
              <GridLayout itemProps={{ xs: 6 }}>
                <DatepickerField
                  label={t('subject:regonModal.field.startDate')}
                  name="regon.businessStartDate"
                  inputMode={InputMode.VIEW}
                  viewModeDateParser={convertDateToDateFormat}
                />
                <DatepickerField
                  label={t('subject:regonModal.field.suspensionDate')}
                  name="regon.businessSuspensionDate"
                  inputMode={InputMode.VIEW}
                  viewModeDateParser={convertDateToDateFormat}
                />
                <DatepickerField
                  label={t('subject:regonModal.field.resumptionDate')}
                  name="regon.businessRenewalDate"
                  inputMode={InputMode.VIEW}
                  viewModeDateParser={convertDateToDateFormat}
                />
                <DatepickerField
                  label={t('subject:regonModal.field.endDate')}
                  name="regon.businessEndDate"
                  inputMode={InputMode.VIEW}
                  viewModeDateParser={convertDateToDateFormat}
                />
              </GridLayout>
            </Section>
          </GridItem>
        </GridLayout>
      </Dialog>
    </FormV2Context.Provider>
  );
}
function Row({
  label,
  name,
  translateDictionary,
  isWithSymbol = false,
  isFromRegon = false
}: {
  label: string;
  name: string;
  translateDictionary?: keyof DictionaryEntryTypeEnum;
  isFromRegon?: boolean;
  isWithSymbol?: boolean;
}) {
  const getName = `${isFromRegon ? 'regon' : 'local'}.${name}`;

  const textField = () => (
    <TextInputField
      label={label}
      name={getName}
      inputMode={InputMode.VIEW}
      {...(isWithSymbol && { getValueFormat: (value: any) => `${value?.symbol || ''} ${value?.name || '-'}` })}
    />
  );

  const dictionaryField = () => (
    <DictionarySelectField
      label={label}
      dictionaryName={translateDictionary}
      name={getName}
      stringValue
      inputMode={InputMode.VIEW}
    />
  );

  return isUndefined(translateDictionary) ? textField() : dictionaryField();
}

function SubjectSection({ isFromRegon = false }: { isFromRegon?: boolean }) {
  const [t] = useTranslation();
  const getLabel = (name: string) => t<any>(`folder:details.field.${name}`);

  return (
    <GridLayout itemProps={{ xs: 12 }}>
      <Row
        label={getLabel('nip')}
        name={`subject.${typedNameV2<SubjectDetailsExtended>('nip')}`}
        isFromRegon={isFromRegon}
      />
      <Row
        label={getLabel('regon')}
        name={`subject.${typedNameV2<SubjectDetailsExtended>('regon')}`}
        isFromRegon={isFromRegon}
      />
      <Row label={getLabel('krs')} name="subject.krs" isFromRegon={isFromRegon} />
      <Row
        label={getLabel('legalFormKey')}
        name={`subject.${typedNameV2<SubjectDetailsExtended>('legalFormKey')}`}
        translateDictionary={DomainDictionaryEnum.LEGAL_NATURE}
        isFromRegon={isFromRegon}
      />
      {isFromRegon ? (
        <Row
          label={t('subject:regonModal.field.codeAndNameBasicLegalForm')}
          name="subject.basicLegalForm"
          isWithSymbol
          isFromRegon={isFromRegon}
        />
      ) : (
        <div style={{ height: 52 }} />
      )}
      {isFromRegon ? (
        <Row
          label={t('subject:regonModal.field.codeAndNameExtendedLegalForm')}
          name="subject.detailedLegalForm"
          isWithSymbol
          isFromRegon={isFromRegon}
        />
      ) : (
        <div style={{ height: 52 }} />
      )}
      <Row
        label={getLabel('name')}
        name={`subject.${typedNameV2<SubjectDetailsExtended>('name')}`}
        isFromRegon={isFromRegon}
      />
      <Row
        label={getLabel('subjectName')}
        name={`subject.${typedNameV2<SubjectDetailsExtended>('givenName')}`}
        isFromRegon={isFromRegon}
      />
      <Row
        label={getLabel('subjectSurname')}
        name={`subject.${typedNameV2<SubjectDetailsExtended>('surname')}`}
        isFromRegon={isFromRegon}
      />
    </GridLayout>
  );
}
function AddressSection({ isFromRegon = false }: { isFromRegon?: boolean }) {
  const [t] = useTranslation();

  return (
    <GridLayout itemProps={{ xs: 12 }}>
      <Row label={t('folder:address.postCode')} name="mainAddress.postCode" isFromRegon={isFromRegon} />
      <Row
        label={t('folder:address.voivodeship')}
        name="mainAddress.voivodeshipKey"
        translateDictionary={DomainDictionaryEnum.VOIVODESHIP}
        isFromRegon={isFromRegon}
      />
      <Row label={t('folder:address.county')} name="mainAddress.county" isFromRegon={isFromRegon} />
      <Row label={t('folder:address.commune')} name="mainAddress.commune" isFromRegon={isFromRegon} />
      <Row label={t('folder:address.postCity')} name="mainAddress.postCity" isFromRegon={isFromRegon} />
      <Row label={t('folder:address.city')} name="mainAddress.city" isFromRegon={isFromRegon} />
      <Row label={t('folder:address.street')} name="mainAddress.street" isFromRegon={isFromRegon} />
      <Row label={t('folder:address.propertyNumber')} name="mainAddress.propertyNumber" isFromRegon={isFromRegon} />
      <Row label={t('folder:address.apartmentNumber')} name="mainAddress.apartmentNumber" isFromRegon={isFromRegon} />
      {!isFromRegon ? (
        <Row
          label={t('folder:address.type')}
          name="mainAddress.typeKey"
          translateDictionary={DomainDictionaryEnum.ADDRESS_TYPE}
          isFromRegon={isFromRegon}
        />
      ) : (
        <div style={{ height: 52 }} />
      )}
    </GridLayout>
  );
}

export default RegonDataModal;
