import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { partialTranslate } from '@libs/common';
import { AdditionalFilterEnum, BackButton, TableLayout, useRouter, YearFilterContext } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { FolderDetailsParams, useEkmtLimitsTable } from '@libs/domain/folder';
import { EkmtLimitTableEnum } from '@libs/domain/social-commission';

import { DomainDictionaryEntry, useDomainConfigContext } from '../../../../../../../config';

const translate = partialTranslate('folder:details.tab.ekmt.section.limit');

interface IProps {
  isSection?: boolean;
  isForeignTab?: boolean;
  isCollapsable?: boolean;
}

function EkmtLimitsSection({ isSection, isForeignTab, isCollapsable }: IProps) {
  const { folderId, id: foreignPermissionSelectedFolder } = useParams<FolderDetailsParams>();
  const { yearFilterContextValues } = useDomainConfigContext();
  const context = useContext(YearFilterContext);
  const { isClientPortal } = useDomainConfigContext();
  const { routes } = useRouter();

  const tableProps = useEkmtLimitsTable(folderId || foreignPermissionSelectedFolder, isForeignTab);

  const globalFilters = [{ type: AdditionalFilterEnum.YEAR_SELECT, name: EkmtLimitTableEnum.YEAR }];

  const apiRequestParams = useMemo(() => {
    return isForeignTab
      ? {
          folderId: folderId || foreignPermissionSelectedFolder,
          [EkmtLimitTableEnum.YEAR]: parseInt(yearFilterContextValues?.value, 10),
          typeKeyIn: [DomainDictionaryEntry.FOREIGN_PERMIT_TYPE.EKMT]
        }
      : {
          folderId: folderId || foreignPermissionSelectedFolder,
          [EkmtLimitTableEnum.YEAR]: parseInt(context?.maxYear, 10),
          typeKeyIn: [DomainDictionaryEntry.FOREIGN_PERMIT_TYPE.EKMT]
        };
  }, [foreignPermissionSelectedFolder, isForeignTab, folderId, yearFilterContextValues, context]);

  return (
    <TableLayout
      {...tableProps}
      pageTitle={translate('title')}
      xlsxDownload={{
        fileName: translate('title'),
        pathToXLSXTranslation: 'folder:details.tab.ekmt.section.limit.field',
        apiRequest: params =>
          isClientPortal
            ? API.client.folderLimits.getFolderLimitsPage('', { ...params, ...apiRequestParams })
            : API.folderLimits.getFolderLimitsPage({ ...params, ...apiRequestParams })
      }}
      headerActions={isForeignTab && <BackButton link={routes.ekmtPermitsList()} />}
      globalFilters={isForeignTab && globalFilters}
      isCollapsable={isCollapsable}
      isSection={isSection}
    />
  );
}
export default EkmtLimitsSection;
