import { ReactNode, useMemo } from 'react';

import { useElementVisibility } from '@libs/permission/hooks';
import { UIElementNameEnum } from '@libs/permission/models';

interface Props {
  actionKeys: UIElementNameEnum[];
  children: ReactNode;
}

function HasAllPermissions({ actionKeys, children }: Props) {
  const { checkIsElementVisible } = useElementVisibility();

  const areAllElementsVisible = useMemo(() => {
    return actionKeys.every(checkIsElementVisible);
  }, [actionKeys, checkIsElementVisible]);

  return areAllElementsVisible ? <>{children}</> : null;
}

export default HasAllPermissions;
