import { useEffect, useState } from 'react';

export function useDetectTouchScreen() {
  const [isTouchScreen, setIsTouchScreen] = useState<boolean>(false);

  useEffect(() => {
    setIsTouchScreen(isTouchDevice());
  }, []);

  const isTouchDevice = () => {
    // @ts-ignore
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  };

  return { isTouchScreen };
}
