import { PageableSearchFilter, ProceedingFormsSearchFilter, SuspensionFormsSearchFilter } from '@ibtm/domain';
import { ProceedingSearchFilter } from '@ibtm/domain/dist/models';
import { omit } from 'lodash';

interface IProceedingFormOperationsQueryParams extends PageableSearchFilter {
  proceedingId?: string;
}

export const parseProceedingFormOperationsListPayload = (params: IProceedingFormOperationsQueryParams) => {
  const queryParams: ProceedingFormsSearchFilter = omit(params, ['proceedingId']);

  return { proceedingId: params?.proceedingId, ...queryParams };
};

interface ISuspensionFormOperationsQueryParams extends PageableSearchFilter {
  suspensionId?: string;
}

export const parseSuspensionFormOperationsListPayload = (params: ISuspensionFormOperationsQueryParams) => {
  const queryParams: SuspensionFormsSearchFilter = omit(params, ['suspensionId']);

  return { suspensionId: params?.suspensionId, ...queryParams };
};

export const parseProceedingsListPayload = (params): ProceedingSearchFilter => {
  return {
    ...params,
    includeDeleted: false
  };
};
