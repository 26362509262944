import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';
import { CivicBoardSnapshot } from '@libs/domain/social-commission';

import { endpointsConfig } from '../endpoints-config';

function addMeeting(formData: CivicBoardSnapshot) {
  return API.civicBoard.createCivicBoard(formData, {
    ...endpointsConfig.createCivicBoard
  });
}

function useAddCividBoardMutation(props?) {
  return useMutation(addMeeting, props);
}

export default useAddCividBoardMutation;
