import { TabContent, TabsContent, useErrorsHandlerV2, useFormV2Context } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DomainUIElementEnum } from '@libs/domain/config';
import ReleaseDocumentsTab from '@libs/domain/folder/components/details/suspensions-details/tabs/releaseDocuments/ReleaseDocumentsTab';
import { RegisteredSuspensionFormEnum, SuspensionDetailsTabEnum } from '@libs/domain/suspensions';

import SuspensionDocumentsTab from './tabs/documents/SuspensionDocumentsTab';
import SuspensionFilesTab from './tabs/files/SuspensionFilesTab';
import FormOperationsTab from './tabs/formOperations/FormOperationsTab';
import SuspensionsNoteTab from './tabs/note/SuspensionsNoteTab';
import SuspensionDetailsTab from './tabs/suspension/SuspensionDetailsTab';

interface IProps {
  activeTab: SuspensionDetailsTabEnum;
  setActiveTab: (tab: SuspensionDetailsTabEnum) => void;
}

function SuspensionDetailsContent({ activeTab, setActiveTab }: IProps) {
  const { formMode, setValue } = useFormV2Context();
  const { saveFieldList } = useErrorsHandlerV2<SuspensionDetailsTabEnum, RegisteredSuspensionFormEnum>(
    setActiveTab,
    RegisteredSuspensionFormEnum.SUSPENSION_FORM
  );

  const { createMode } = useViewModesV2(formMode);

  return createMode ? (
    <SuspensionDetailsTab />
  ) : (
    <TabsContent
      value={activeTab}
      saveFieldList={saveFieldList}
      scrollerPrefixId={RegisteredSuspensionFormEnum.SUSPENSION_FORM}
    >
      <TabContent
        value={SuspensionDetailsTabEnum.SUSPENSION_DETAILS}
        viewName={DomainUIElementEnum.SUSPENSIONS_DETAILS_VIEW}
      >
        <SuspensionDetailsTab />
      </TabContent>
      <TabContent
        value={SuspensionDetailsTabEnum.SUSPENSION_DOCUMENTS}
        viewName={DomainUIElementEnum.SUSPENSIONS_DOCUMENTS_VIEW}
      >
        <SuspensionDocumentsTab />
      </TabContent>
      <TabContent
        value={SuspensionDetailsTabEnum.SUSPENSION_RELEASE_DOCUMENTS}
        viewName={DomainUIElementEnum.SUSPENSIONS_CORRECTION_LIST}
      >
        <ReleaseDocumentsTab />
      </TabContent>
      <TabContent
        value={SuspensionDetailsTabEnum.FORM_OPERATIONS}
        viewName={DomainUIElementEnum.SUSPENSIONS_FORM_OPERATIONS_VIEW}
      >
        <FormOperationsTab formMode={formMode} />
      </TabContent>
      <TabContent
        value={SuspensionDetailsTabEnum.SUSPENSION_NOTE_TAB}
        viewName={DomainUIElementEnum.SUSPENSIONS_NOTE_VIEW}
      >
        <SuspensionsNoteTab formMode={formMode} setParentFormValues={setValue} />
      </TabContent>
      <TabContent
        value={SuspensionDetailsTabEnum.SUSPENSION_FILES}
        viewName={DomainUIElementEnum.SUSPENSIONS_FILES_VIEW}
      >
        <SuspensionFilesTab />
      </TabContent>
    </TabsContent>
  );
}

export default SuspensionDetailsContent;
