import { ReactNode, useMemo } from 'react';
import { DeepPartial, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { Dialog, FormV2Context, FormV2ContextState, useValidationBuilder } from '@libs/common/v2';

import { usePrintsActionsContext } from '@libs/domain/print/context';

interface IForeignPermissionActionDialogProps<T> {
  defaultValues?: DeepPartial<T>;
  title: string;
  confirmText: string;
  onSubmit: (formData: T) => Promise<any>;
  children: ReactNode;
}

export function RegeneratePermissionDialogBase<T>({
  defaultValues,
  onSubmit,
  confirmText,
  title,
  children
}: IForeignPermissionActionDialogProps<T>) {
  const [t] = useTranslation();

  const { finishAction } = usePrintsActionsContext() ?? {};

  const { validationBuilderFunctions, validationScheme } = useValidationBuilder();
  const form = useForm<T>({
    reValidateMode: 'onChange',
    defaultValues,
    resolver: yupResolver(validationScheme)
  });
  const { handleSubmit } = form;

  const values = useMemo(() => ({ ...form, validationBuilderFunctions }), [form, validationBuilderFunctions]);

  return (
    <Dialog
      title={title}
      dialogSize="medium"
      confirmText={confirmText}
      onConfirm={setIsLoading =>
        handleSubmit(async data => {
          setIsLoading(true);
          await onSubmit(data);
          setIsLoading(false);
        })()
      }
      onClose={finishAction}
      onCancel={finishAction}
      titleSupportingText={t('prints:messages.regenerateForeignPermissionDialogDescription')}
      isOpen
    >
      <FormV2Context.Provider value={values as unknown as FormV2ContextState}>{children}</FormV2Context.Provider>
    </Dialog>
  );
}
