import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ForeignPermissionWithSettlementDetails } from '@ibtm/domain';
import { isNil } from 'lodash';

import {
  BackButton,
  Breadcrumbs,
  Button,
  ButtonsGroup,
  CommonTabHeader,
  FormMode,
  FormV2Context,
  Page,
  useIsSmallScreen,
  useRouter
} from '@libs/common/v2';

import { modePathPermitEkmt, useSocialCommissionBreadcrumps } from '@libs/domain/social-commission';

import { useEkmtPermitFormQuery } from '../../api';
import { usePermitDialog } from '../../hooks';
import { IReleasedPermitsDetailsParams } from '../../model';

import { PermitEkmtDetailsEditForm } from '.';

function PermitEkmtDetailsPage({ formMode }: { formMode: FormMode }) {
  const [t] = useTranslation();
  const { id: foreignPermissionId } = useParams<IReleasedPermitsDetailsParams>();
  const { permitEkmt } = useSocialCommissionBreadcrumps(modePathPermitEkmt, formMode);
  const { goToPage, routes } = useRouter();
  const { openSettleEKMTPermitDialog } = usePermitDialog();
  const { isSmallScreen } = useIsSmallScreen();

  const { data: foreignPermissionDetails } = useEkmtPermitFormQuery(foreignPermissionId, {
    enabled: !isNil(foreignPermissionId)
  });

  const form = useForm<Partial<ForeignPermissionWithSettlementDetails>>({});

  useEffect(() => {
    form.reset(foreignPermissionDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foreignPermissionDetails]);

  const formDataProvider = useMemo(() => {
    return { ...form, formMode };
  }, [form, formMode]);

  return (
    <FormV2Context.Provider value={formDataProvider}>
      <Page
        header={
          <div className="mx-10 mt-10">
            <Breadcrumbs items={permitEkmt} />
            <CommonTabHeader
              actions={
                <ButtonsGroup>
                  <BackButton
                    onClick={() =>
                      goToPage({
                        pathname: routes.releasedPermits()
                      })
                    }
                    isNoMargin
                  />
                  <Button
                    onClick={() =>
                      openSettleEKMTPermitDialog({
                        foreignPermissionId,
                        initialData: foreignPermissionDetails,
                        hasDefaultDestiny: true
                      })
                    }
                    label={t('foreignPermits:tabs.releasedPermits.ekmtDetails.settlementEKMT.settleButton')}
                    isSecondary
                    variant="outlined"
                    isNoMargin
                  />
                </ButtonsGroup>
              }
              title={t(`${modePathPermitEkmt}.${formMode}.title`)}
            />
          </div>
        }
        contentClassName="flex-col"
        content={<PermitEkmtDetailsEditForm />}
        isContentScrollEnabled={!isSmallScreen}
      />
    </FormV2Context.Provider>
  );
}

export default PermitEkmtDetailsPage;
