import { useMemo } from 'react';

import { useMultipleSelectColumn, useTableLayoutProps } from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';

import { useSCertificatesQuery } from '@libs/domain/s-certificates';

import { DomainUIElementEnum } from '../../../../../../config';
import { statusesWithBlockedEdit } from '../../../../../utils';
import { VehicleSelectActionColumn } from '../../components/select-colums';
import { useCertificatesColumns } from '../columns';
import useCertificateVehicleTabContext from '../useCertificateVehicleTabContext';

function useCertificateTable(folderId: string, transferredFoldersIds?: string[], viewMode?: boolean) {
  const { checkIsElementVisible } = useElementVisibility();
  const { columns, mappedFilterFields, mappedSortFields } = useCertificatesColumns();
  const { applicationStatusKey } = useCertificateVehicleTabContext();

  const hasUserPermission = useMemo(
    () =>
      checkIsElementVisible(
        DomainUIElementEnum.APPLICATION_S_CERTIFICATES_ADD_VEHICLE_FROM_FOLDER_TO_APPLCIATION_BUTTON
      ),
    [checkIsElementVisible]
  );

  const editIsBlocked = useMemo(
    () => viewMode || !hasUserPermission || statusesWithBlockedEdit.some(status => status === applicationStatusKey),
    [applicationStatusKey, hasUserPermission, viewMode]
  );

  const vehicleTablePlugin = [useMultipleSelectColumn(VehicleSelectActionColumn)];

  return useTableLayoutProps({
    tableHookQuery: useSCertificatesQuery,
    tableHookOptions: {
      columns,
      tablePlugins: editIsBlocked ? [] : vehicleTablePlugin,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tableOptions: {
        initialState: {
          pageSize: 10
        }
      }
    },
    tableHookQueryInitialParams: {
      folderIdIn: [folderId, ...(transferredFoldersIds || [])]
    }
  });
}

export default useCertificateTable;
