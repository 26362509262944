import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column, OnFilterChange } from 'react-table';

import SelectFilter from './SelectFilter';

interface IProps {
  column: Column & { filterValue?: string; setFilter: (value: string) => void };
  valueTrueText?: string;
  valueFalseText?: string;
  onFilterChange: OnFilterChange;
}

function BooleanSelect({ column, valueTrueText, valueFalseText, onFilterChange }: IProps) {
  const [t] = useTranslation();

  const options = [
    {
      name: valueTrueText || t('action.yes'),
      value: true
    },
    {
      name: valueFalseText || t('action.no'),
      value: false
    }
  ];

  return (
    <SelectFilter options={options} isMultiple={false} column={column} hasClearIcon onFilterChange={onFilterChange} />
  );
}

export default React.memo(BooleanSelect);
