import { PermissionElementMap } from '@libs/permission';

import { PermissionEnum } from '@libs/domain/config/permission/PermissionEnum';

import NotificationUIElementEnum from './NotificationUIElementEnum';

const NotificationPermissionElementMapper: PermissionElementMap<NotificationUIElementEnum> = {
  [NotificationUIElementEnum.MESSAGE_ADD_BUTTON]: {
    anyOfPermission: [],
    allOfPermissions: [PermissionEnum.MESSAGE_CREATE, PermissionEnum.MESSAGE_VIEW]
  },
  [NotificationUIElementEnum.MESSAGE_LIST]: {
    anyOfPermission: [PermissionEnum.MESSAGE_VIEW],
    allOfPermissions: []
  },
  /** Komunikaty administracyjne */
  [NotificationUIElementEnum.ANNOUNCEMENT_LIST_TAB]: {
    anyOfPermission: [PermissionEnum.MESSAGE_VIEW]
  },
  [NotificationUIElementEnum.ANNOUNCEMENT_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.MESSAGE_VIEW]
  },
  [NotificationUIElementEnum.ANNOUNCEMENT_ADD_BUTTON]: {
    anyOfPermission: [PermissionEnum.MESSAGE_ADMIN]
  },
  [NotificationUIElementEnum.ANNOUNCEMENT_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.MESSAGE_ADMIN]
  }
};

export default NotificationPermissionElementMapper;
