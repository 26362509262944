import { QueryConfig, usePaginatedQuery } from 'react-query';
import { CurrencyExchangeRateSearchFilter, CurrencyExchangeRateSnapshot } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import { FinancialSecurityQueryKeysEnum } from './FinancialSecurityQueryKeysEnum';

const getCurrencyRates = async (_, params: CurrencyExchangeRateSearchFilter) => {
  const { data } = await API.currencyRates.getCurrencyExchangeRatesPage(params, {
    ...endpointsConfig.getCurrencyExchangeRatesPage
  });
  return data;
};

const getCurrencyRatesClient = async (_, params: CurrencyExchangeRateSearchFilter) => {
  const { data } = await API.client.currencyRates.getCurrencyExchangeRatesPage(params, {
    ...endpointsConfig.getCurrencyExchangeRatesPage
  });
  return data;
};

export default function useCurrencyExchangeRatesQuery(
  params?: CurrencyExchangeRateSearchFilter,
  queryConfig: QueryConfig<any, unknown> = {}
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return usePaginatedQuery<IPaginatedModel<CurrencyExchangeRateSnapshot>>(
    [FinancialSecurityQueryKeysEnum.CURRENCY_RATE_LIST, params],
    getQuery(getCurrencyRatesClient, getCurrencyRates),
    queryConfig
  );
}
