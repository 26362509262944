export enum UserDictionaryEntryNameEnum {
  USER_STATUS = 'USER_STATUS',
  WORK_SYSTEM_TYPE = 'WORK_SYSTEM_TYPE',
  WORK_DAY = 'WORK_DAY',
  SHIFT_TYPE = 'SHIFT_TYPE',
  ABSENCE_TYPE = 'ABSENCE_TYPE',
  HOLIDAY_TYPE = 'HOLIDAY_TYPE',
  POSITION_TYPE = 'POSITION_TYPE',
  USER_CERTIFICATE_IDENTIFIER_TYPE = 'USER_CERTIFICATE_IDENTIFIER_TYPE',
  REPLACEMENT_STATUS = 'REPLACEMENT_STATUS'
}
