import usePrintTablesConfiguration from './usePrintTablesConfiguration';

function useGetDepotType(): { isDepotMP: boolean; isDepotMPD: boolean } {
  const config = usePrintTablesConfiguration();

  const isDepotMP =
    config?.INTERBUS?.isVisible || config?.TRAVEL_FORM?.isVisible || config?.FOREIGN_PERMISSION?.isVisible;

  const isDepotMPD =
    config?.DRIVER_CERTIFICATE?.isVisible ||
    config?.EXTERNAL_AUTHORITY_PERMISSION?.isVisible ||
    config?.FOREIGN_AUTHORITY_PERMISSION?.isVisible ||
    config?.GITD?.isVisible ||
    config?.LICENSE_COPY?.isVisible ||
    config?.LISENSE?.isVisible ||
    config?.OWN_NEEDS?.isVisible ||
    config?.OWN_NEEDS_COPY?.isVisible ||
    config?.PERMISSION_COPY?.isVisible ||
    config?.SPO_OCCASIONAL?.isVisible ||
    config?.SPO_OCCASIONAL_COPY?.isVisible ||
    config?.SPO_OUTSIDE_UE?.isVisible ||
    config?.SPO_OUTSIDE_UE_COPY?.isVisible ||
    config?.SPO_SHUTTLE?.isVisible ||
    config?.SPO_SHUTTLE_COPY?.isVisible ||
    config?.SPO_TRANSIT?.isVisible ||
    config?.SPO_TRANSIT_COPY?.isVisible ||
    config?.SPO_UE?.isVisible ||
    config?.SPO_UE_COPY?.isVisible ||
    config?.S_CERTIFICATE?.isVisible;

  return {
    isDepotMP,
    isDepotMPD
  };
}

export default useGetDepotType;
