import React from 'react';
import { actions, Row } from 'react-table';
import { set } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { ITableAdapter, ITableContext, NewRowPlacementEnum, prepareFieldName } from '@libs/common/v2';
/**
 * Funkcja do tworzenia nowego wiersza w tabeli, przyjmuje już istniejący row w tabeli
 * i tworzy nowy podmieniając tylko dane wiersza wyświetlane w tabeli
 */
function createNewRow(row, newId, payload) {
  const { row: clickedRow, tableInstance, tableContext } = payload;
  const rowToCopy = row || tableContext.newRowScheme;

  const getDefaultValue = defaultValue => {
    return typeof defaultValue === 'function'
      ? defaultValue({ rowToCopy, clickedRow, tableInstance, tableContext })
      : defaultValue;
  };

  return {
    ...rowToCopy,
    id: newId,
    original: {
      id: newId,
      ...rowToCopy.cells.reduce(
        (acc, cell: any) =>
          set(
            { ...acc, cell },
            prepareFieldName(cell.column?.field?.name ?? ''),
            getDefaultValue(cell.column?.field?.defaultValue ?? '')
          ),
        {}
      )
    }
  };
}
// Dodaje nowy wiersz na początek tabeli
function addNewStartingRow(state, data, payload) {
  const id = uuidv4();
  return {
    ...state,
    rowId: id,
    isCreating: true,
    page: [createNewRow(data?.page?.[0], id, payload), ...data.page]
  };
}

// Dodaje nowy wiersz na koniec tabeli
function addNewEndRow(state, data, payload) {
  const id = uuidv4();
  return {
    ...state,
    rowId: id,
    isCreating: true,
    page: [...data.page, createNewRow(data?.page?.[0], id, payload)]
  };
}

// Dodaje nowy wiersz do tabeli, w zależności od payload nad lub pod podanym wierszem.
function addBeforeAfterRow(state, data, payload) {
  const id = uuidv4();
  const newState = data.page.reduce(
    (acc, row) => {
      if (payload.placement === NewRowPlacementEnum.BEFORE && row.original.id === payload.row.id) {
        return { clickedRow: row, page: [...acc.page, createNewRow(row, id, payload), row] };
      }
      if (payload.placement === NewRowPlacementEnum.AFTER && row.original.id === payload.row.id) {
        return { clickedRow: row, page: [...acc.page, row, createNewRow(row, id, payload)] };
      }
      return { ...acc, page: [...acc.page, row] };
    },
    { clickedRow: null, page: [] }
  );
  return {
    ...state,
    rowId: id,
    isCreating: true,
    ...newState
  };
}

// eslint-disable-next-line consistent-return
export function useMutableTableReducer(state, action, prev, table) {
  switch (action.type) {
    case actions.init:
      return { ...state, rowId: '', clickedRow: null, page: table.page, isEditing: false, isCreating: false };
    case actions.editRow:
      return { ...state, rowId: action.payload, isEditing: true };
    case actions.cancelEditCreateRow:
      return { ...state, rowId: '', clickedRow: null, page: null, isEditing: false, isCreating: false };
    case actions.addNewStartRow:
      return addNewStartingRow(state, table, action.payload);
    case actions.addNewLastRow:
      return addNewEndRow(state, table, action.payload);
    case actions.addBeforeAfterRow:
      return addBeforeAfterRow(state, table, action.payload);
  }
}

export function useMutableTableInstance(instance) {
  const { dispatch } = instance;

  const editRow = React.useCallback((id: string) => dispatch({ type: actions.editRow, payload: id }), [dispatch]);
  const cancelEditCreateRow = React.useCallback(() => dispatch({ type: actions.cancelEditCreateRow }), [dispatch]);
  const addNewStartRow = React.useCallback(
    (tableInstance: ITableAdapter<any>, tableContext: ITableContext<any>) =>
      dispatch({ type: actions.addNewStartRow, payload: { tableInstance, tableContext } }),
    [dispatch]
  );
  const addNewBeforeAfterRow = React.useCallback(
    (
      row: Row<any>,
      tableInstance: ITableAdapter<any>,
      tableContext: ITableContext<any>,
      placement: NewRowPlacementEnum
    ) => dispatch({ type: actions.addBeforeAfterRow, payload: { row, placement, tableInstance, tableContext } }),
    [dispatch]
  );
  const addNewLastRow = React.useCallback(
    (tableInstance: ITableAdapter<any>, tableContext: ITableContext<any>) =>
      dispatch({ type: actions.addNewLastRow, payload: { tableContext, tableInstance } }),
    [dispatch]
  );

  Object.assign(instance, {
    editRow,
    cancelEditCreateRow,
    addNewStartRow,
    addNewLastRow,
    addNewBeforeAfterRow
  });
}
