import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ParameterValue, ReportDetailsSnapshot } from '@stack/report/dist/models';

import { Chip, GridLayout, Section, Typography, useValueStyles, Value, ValueSkeleton } from '@libs/common/v2';
import { useTheme } from '@libs/common/v2/theme';

import { DictionaryEntryNameEnum, useDictionaryTranslations } from '@libs/dictionary';
import { DocumentTemplateDictionaryEntryNameEnum } from '@libs/document-template';
import { BOOLEAN_STRING_VALUES, FieldTypeEnum } from '@libs/report/model';

interface Props {
  report?: ReportDetailsSnapshot;
  isLoading: boolean;
  isFetching: boolean;
}

function ReportDetailsParametersSection({ report, isLoading, isFetching }: Props) {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();
  const { contrast } = useTheme();

  const classes = useValueStyles({ contrast });

  const compareFunction = () => {
    return (a, b) => {
      if (a.index === b.index) {
        return 0;
      }
      if (a.index === null) {
        return 1;
      }
      if (b.index === null) {
        return -1;
      }
      return a.index < b.index ? -1 : 1;
    };
  };

  const convertBoolean = (value: string) => {
    if (BOOLEAN_STRING_VALUES.includes(value)) {
      return translateBoolean(value);
    }

    return value;
  };

  const translateBoolean = (value: string) => (value === 'true' ? t('action.yes') : t('action.no'));

  const parsedParameters = useMemo(() => {
    return report?.parameterValues?.sort(compareFunction());
  }, [report]);

  const getParameter = (parameter: ParameterValue, value: string) => {
    return parameter.type === FieldTypeEnum.BOOLEAN ? convertBoolean(value) : value;
  };

  return (
    <Section title={t('reports:details.section.reportParameters')} isLoading={isFetching}>
      <GridLayout itemProps={{ xs: 12 }}>
        {report?.targetTypes?.length > 0 && (
          <Value label={t('reports:field.reportFormat')}>
            {report?.targetTypes.map(targetType => (
              <Chip
                key={targetType}
                color="grey"
                title={translate(DocumentTemplateDictionaryEntryNameEnum.DOCUMENT_TARGET_MIME_TYPE, targetType, '-')}
                className="mr-2"
              />
            ))}
          </Value>
        )}
        <GridLayout itemProps={{ xs: 12 }}>
          {!parsedParameters?.length ? (
            <Typography>{t('reports:noParameters')}</Typography>
          ) : (
            parsedParameters?.map(parameter =>
              isLoading ? (
                <ValueSkeleton key={parameter?.index} />
              ) : (
                <>
                  <div className={classes.labelWrapper}>
                    <div>{parameter.displayName}</div>
                  </div>
                  {parameter.values.map(value => (
                    <div key={value} className={classes.value}>
                      {parameter.dictionaryName
                        ? translate(parameter.dictionaryName as DictionaryEntryNameEnum, value, value)
                        : getParameter(parameter, value)}
                    </div>
                  ))}
                </>
              )
            )
          )}
        </GridLayout>
      </GridLayout>
    </Section>
  );
}

export default ReportDetailsParametersSection;
