import { makeStyles } from '@mui/styles';

import { i18n } from '@libs/common';
import { DropdownButton, Icon, MenuItem, Theme } from '@libs/common/v2';

import { PricingTab } from '../utils';

interface IProps {
  tab: PricingTab;
  setTab: (tab: PricingTab) => void;
}

function MoneyTransferDropdownMenu({ tab, setTab }: IProps) {
  const classes = useStyles();

  const switchTab = (tab: PricingTab) => {
    setTab(tab);
    const activeTab = new URLSearchParams(window.location.search).get('tab');
    const paramsUrl = `tab=${activeTab}&moneyTransferTab=${tab}`;
    window.history.replaceState(null, '', `${window.location.pathname}?${paramsUrl}`);
  };

  return (
    <DropdownButton
      buttonType="button"
      variant="outlined"
      isSecondary
      startIcon={<Icon className={classes.tableIcon} icon="TableIcon" />}
    >
      {() => (
        <>
          <MenuItem onClick={() => switchTab(PricingTab.CONFIRMED)}>
            <span className={classes.iconWrapper}>{tab === PricingTab.CONFIRMED && <Icon icon="CheckIcon" />}</span>
            {i18n.t('pricing:moneyTransfers.dropdownLabel')}
          </MenuItem>
          <MenuItem onClick={() => switchTab(PricingTab.UNCONFIRMED)}>
            <span className={classes.iconWrapper}>{tab === PricingTab.UNCONFIRMED && <Icon icon="CheckIcon" />}</span>
            {i18n.t('pricing:moneyTransfersUnconfirmed.dropdownLabel')}
          </MenuItem>
        </>
      )}
    </DropdownButton>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  iconWrapper: {
    width: '34px'
  },
  tableIcon: {
    fill: 'currentColor',
    color: theme.palette.grey[900],
    marginTop: '3px'
  }
}));

export default MoneyTransferDropdownMenu;
