export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  createExternalAuthorityPermission: { translationKey: 'permissions:api.createExternalAuthorityPermission' },
  createForeignAuthorityPermission: { translationKey: 'permissions:api.createForeignAuthorityPermission' },
  deletePermission: { translationKey: 'permissions:api.deletePermission' },
  passToDistrictAuthority: { translationKey: 'permissions:api.passToDistrictAuthority' },
  restoreFromDistrictAuthority: { translationKey: 'permissions:api.restoreFromDistrictAuthority' },
  updateExternalAuthorityPermission: { translationKey: 'permissions:api.updateExternalAuthorityPermission' },
  updateForeignAuthorityPermission: { translationKey: 'folder:api.updateForeignAuthorityPermission' },
  updateResourceAssignmentPermissionCopy: {
    translationKey: 'permissions:api.updateResourceAssignmentPermissionCopy'
  },
  updateResourceAssignmentPermission: { translationKey: 'permissions:api.updateResourceAssignmentPermission' },
  getPermissionCopiesPage: { translationKey: 'permissions:api.getPermissionCopiesPage' },
  getPermission: { translationKey: 'permissions:api.getPermission' },
  getPermissionsPage: { translationKey: 'permissions:api.getPermissionsPage' },
  getSubjectFolderPermissions: { translationKey: 'subject:api.getSubjectFolderPermissionsCopy' }
};
