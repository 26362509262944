import { usePaginatedQuery } from 'react-query';
import { FinancialSecuritySearchFilter, FinancialSecuritySnapshot } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import { FinancialSecurityQueryKeysEnum } from './FinancialSecurityQueryKeysEnum';

const getFinanceSecurities = async (_, { applicationId, ...requestParams }) => {
  const { data } = await API.financialSecurity.getFinancialSecuritiesSnapshotPageForApplication(
    applicationId,
    requestParams,
    {
      ...endpointsConfig.getFinancialSecuritiesSnapshotPage
    }
  );
  return data;
};
const getFinanceSecuritiesClient = async (_, { applicationId, ...requestParams }) => {
  const { data } = await API.client.financialSecurity.getFinancialSecuritiesSnapshotPageForApplication(
    '',
    '',
    applicationId,
    requestParams,
    { ...endpointsConfig.getFinancialSecuritiesSnapshotPage }
  );
  return data;
};

export default function useFinancialSecuritiesApplicationQuery(params: FinancialSecuritySearchFilter) {
  const { getQuery } = useGetApiQueryByPerspective();
  return usePaginatedQuery<IPaginatedModel<FinancialSecuritySnapshot>>(
    [FinancialSecurityQueryKeysEnum.FINANCIAL_SECURITY_LIST, params],
    getQuery(getFinanceSecuritiesClient, getFinanceSecurities)
  );
}
