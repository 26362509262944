import { useTranslation } from 'react-i18next';

import { partialTranslate } from '@libs/common';
import { ColumnTypesEnum, TableFilter } from '@libs/common/v2';
import { convertDateToDateFormat, getValue } from '@libs/common/v2/utils';

import { DictionaryValue, useDictionaryTranslations } from '@libs/dictionary';
import { UserFilterV2 } from '@libs/user';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { PermissionCopiesColumnsEnum, PermissionCopySnapshotClient } from '../../model';

const getLabel = partialTranslate('permission:field');

const usePermissionCopiesColumn = () => {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  return {
    applicationNumber: {
      id: 'applicationNumber',
      Header: getLabel('applicationNumber'),
      accessor: row => getValue(row.applicationNumber),
      Filter: TableFilter.Text
    },
    certificateNumber: {
      id: 'certificateNumber',
      Header: getLabel('certificateNumber'),
      accessor: row => getValue(row.certificateNumber),
      Filter: TableFilter.Text
    },
    cancellationReason: {
      id: 'cancellationReason',
      Header: getLabel('cancellationReason'),
      accessor: (row: PermissionCopySnapshotClient) => (
        <DictionaryValue
          dictionaryEntryType={DomainDictionaryEnum.PERMISSION_CANCELLATION_REASON}
          value={row.cancellationReason}
        />
      ),
      Filter: props => (
        <TableFilter.DictionaryMultiSelect
          {...props}
          dictionary={DomainDictionaryEnum.PERMISSION_CANCELLATION_REASON}
        />
      ),
      xlsxFormatter: row =>
        translate(DomainDictionaryEnum.PERMISSION_CANCELLATION_REASON, row.cancellationReason, t('emptyMark'))
    },
    cancelledBy: {
      id: 'cancelledBy',
      Header: getLabel('cancelledBy'),
      accessor: row => getValue(row.cancelledBy),
      Filter: (props: any) => <UserFilterV2 isMultiple {...props} />
    },
    cancellationDate: {
      id: 'cancellationDate',
      Header: getLabel('cancellationDate'),
      accessor: row => getValue(convertDateToDateFormat(row.cancellationDate)),
      Filter: TableFilter.Datepicker
    },
    subjectName: {
      id: 'subjectName',
      Header: getLabel('subjectName'),
      accessor: row => getValue(row.subjectName),
      Filter: TableFilter.Text
    },
    subjectAddress: {
      id: 'subjectAddress',
      Header: getLabel('subjectAddress'),
      accessor: row => getValue(row.subjectAddress),
      Filter: TableFilter.Text
    },
    permissionNumber: {
      id: 'permissionNumber',
      Header: getLabel('permissionNumber'),
      accessor: row => getValue(row.permissionNumber),
      Filter: TableFilter.Text
    },
    permissionCopyNumber: {
      id: 'permissionCopyNumber',
      Header: getLabel('permissionCopyNumber'),
      accessor: row => getValue(row.permissionCopyNumber),
      Filter: TableFilter.Text
    },
    formNumber: {
      id: 'formNumber',
      Header: getLabel('formNumber'),
      accessor: row => getValue(row.formNumber),
      Filter: TableFilter.Text
    },
    formNumberEditable: {
      id: 'formNumber',
      Header: getLabel('formNumber'),
      accessor: row => getValue(row.formNumber),
      field: { name: 'formNumber', type: ColumnTypesEnum.TEXT },
      Filter: TableFilter.Text
    },
    dateOfIssue: {
      id: 'dateOfIssue',
      Header: getLabel('dateOfIssue'),
      accessor: row => getValue(convertDateToDateFormat(row.dateOfIssue)),
      Filter: TableFilter.Datepicker
    },
    dateOfIssueExternal: {
      id: 'dateOfIssueExternal',
      Header: getLabel('dateOfIssueExternal'),
      accessor: row =>
        getValue(convertDateToDateFormat(row.dateOfIssueExternal ? row.dateOfIssueExternal : row.dateOfIssue)),
      Filter: TableFilter.Datepicker,
      width: 255
    },
    validFrom: {
      id: 'validFrom',
      Header: getLabel('validFrom'),
      accessor: row => getValue(convertDateToDateFormat(row.validFrom)),
      Filter: TableFilter.Datepicker
    },
    validTo: {
      id: 'validTo',
      Header: getLabel('validTo'),
      accessor: row => getValue(convertDateToDateFormat(row.validTo)),
      Filter: TableFilter.Datepicker
    },
    signerName: {
      id: 'signerName',
      Header: getLabel('signerName'),
      accessor: row => getValue(row.signerName),
      Filter: (props: any) => <UserFilterV2 isMultiple {...props} />
    },
    status: {
      id: 'status',
      Header: getLabel('status'),
      accessor: (row: PermissionCopySnapshotClient) => (
        <DictionaryValue dictionaryEntryType={DomainDictionaryEnum.PERMISSION_STATUS} value={row.status} />
      ),
      Filter: props => (
        <TableFilter.DictionaryMultiSelect {...props} dictionary={DomainDictionaryEnum.PERMISSION_STATUS} />
      ),
      xlsxFormatter: row => translate(DomainDictionaryEnum.PERMISSION_STATUS, row.status, t('emptyMark'))
    },
    transitType: {
      id: 'transitType',
      Header: getLabel('transitType'),
      accessor: (row: PermissionCopySnapshotClient) => (
        <DictionaryValue dictionaryEntryType={DomainDictionaryEnum.TRANSIT_TYPE} value={row.transitType} />
      ),
      Filter: props => <TableFilter.DictionaryMultiSelect {...props} dictionary={DomainDictionaryEnum.TRANSIT_TYPE} />,
      xlsxFormatter: row => translate(DomainDictionaryEnum.TRANSIT_TYPE, row.transitType, t('emptyMark'))
    },
    printDate: {
      id: 'printDate',
      Header: getLabel('printDate'),
      accessor: row => getValue(row.printDate)
    }
  };
};

export const PERMISSION_COPIES_DEFAULT_COLUMNS = [
  PermissionCopiesColumnsEnum.APPLICATION_NUMBER,
  PermissionCopiesColumnsEnum.PERMISSION_NUMBER,
  PermissionCopiesColumnsEnum.PERMISSION_COPY_NUMBER,
  PermissionCopiesColumnsEnum.FORM_NUMBER,
  PermissionCopiesColumnsEnum.DATE_OF_ISSUE,
  PermissionCopiesColumnsEnum.VALID_FROM,
  PermissionCopiesColumnsEnum.VALID_TO,
  PermissionCopiesColumnsEnum.SIGNER_NAME,
  PermissionCopiesColumnsEnum.STATUS
];

export default usePermissionCopiesColumn;
