import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { FolderLimitDetails } from '@ibtm/domain';
import { AxiosResponse } from 'axios';
import * as Yup from 'yup';

import { partialTranslate } from '@libs/common';
import {
  Dialog,
  DictionarySelectField,
  FormV2Context,
  GridLayout,
  NumberInputField,
  SelectOption,
  SwitchField,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';

import { FolderSelectField } from '@libs/domain/application';
import { DomainDictionaryEnum } from '@libs/domain/config';
import { EditOrTransferRequest } from '@libs/domain/folder';

const translate = partialTranslate('folder:details.tab.singleAuthorization.section.basicLimit');

interface Props {
  open: boolean;
  closeDialog: () => void;
  onSubmit: (
    id: string,
    data: EditOrTransferRequest,
    refetch: () => void,
    dataRow: FolderLimitDetails
  ) => Promise<AxiosResponse<FolderLimitDetails>>;
  id: string;
  folderNumber: string;
  limit: number;
  refetch: () => void;
  dataRow: FolderLimitDetails;
}

function EditOrTransferLimitModal({ id, onSubmit, open, closeDialog, folderNumber, limit, refetch, dataRow }: Props) {
  const [t] = useTranslation();

  const validationSchema = Yup.object<EditOrTransferRequest>({
    moveLimit: Yup.boolean(),
    folderNumber: Yup.string(),
    folder: Yup.object().when('moveLimit', {
      is: true,
      then: Yup.object().required()
    }),
    limit: Yup.number().required(),
    reasonKey: Yup.object<SelectOption>().nullable().required()
  });
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    reset,
    getValues,
    trigger,
    unregister
  } = useForm<Partial<EditOrTransferRequest>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      folderNumber: '',
      limit: 0,
      moveLimit: false,
      reasonKey: null
    }
  });

  const moveLimit = watch('moveLimit') as boolean;

  useEffect(() => {
    setValue('folderNumber', folderNumber);
    setValue('limit', limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = async (values: Partial<EditOrTransferRequest>) => {
    return onSubmit(
      id,
      {
        folderNumber: values.folderNumber,
        limit: values.limit,
        moveLimit: values.moveLimit,
        reasonKey: values.reasonKey,
        ...(values?.folder && { folder: values.folder })
      },
      () => {
        refetch();
        closeDialog();
      },
      dataRow
    );
  };

  const handleCancel = () => {
    closeDialog();
    reset();
  };

  const formValues = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <FormV2Context.Provider value={formValues}>
      <form onSubmit={handleSubmit(handleConfirm)}>
        <Dialog
          title={translate('title')}
          confirmText={t('action.save')}
          cancelText={t('action.cancel')}
          onConfirm={handleSubmit(handleConfirm)}
          onCancel={handleCancel}
          dialogSize="small"
          isConfirmLoading={isSubmitting}
          isScrollbarEnabled={false}
          isOpen={open}
        >
          <GridLayout itemProps={{ xs: 12 }}>
            <SwitchField label={translate('field.moveLimit')} name="moveLimit" />

            {moveLimit ? (
              <FolderSelectField
                fieldName={typedNameV2<EditOrTransferRequest>('folder')}
                label={translate('field.folderNumber')}
                isRequired
              />
            ) : (
              <TextInputField
                name={typedNameV2<EditOrTransferRequest>('folderNumber')}
                label={translate('field.folderNumber')}
                isDisabled
              />
            )}

            <NumberInputField
              name={typedNameV2<EditOrTransferRequest>('limit')}
              label={translate('field.limit')}
              min={-Number.MAX_SAFE_INTEGER}
              isRequired
            />
            <DictionarySelectField
              name={typedNameV2<EditOrTransferRequest>('reasonKey')}
              label={translate('field.reason')}
              dictionaryName={DomainDictionaryEnum.CORRECTIONS_OF_RATIONING_FOREIGN_PERMITS}
              isRequired
            />
          </GridLayout>
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
}

export default EditOrTransferLimitModal;
