import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CivicBoardRuleCreateRequest,
  CivicBoardRuleDetails,
  CivicBoardRuleUpdateRequest,
  RuleItemCodes,
  RuleType
} from '@ibtm/domain';
import moment from 'moment';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  Dialog,
  DictionarySelectField,
  FormMode,
  FormV2Context,
  GridItem,
  GridLayout,
  InputMode,
  NumberInputField,
  SwitchField,
  typedNameV2
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { useViewModesV2 } from '@libs/common/v2/form';
import { convertDateToDateTimeFormat } from '@libs/common/v2/utils';

import { DomainDictionaryEnum } from '@libs/domain/config';
import {
  useCreateTradeRulesMutation,
  useEditTradeRulesMutation,
  useTradeRulesDetailsQuery
} from '@libs/domain/social-commission/api';
import { SocialCommissionQueryKeysEnum } from '@libs/domain/social-commission/config';
import {
  getTradeRulesSchema,
  ICivicBoardRuleCreateRequest,
  ICivicBoardRuleUpdateRequest
} from '@libs/domain/social-commission/model';

import { ResourceTypesSelectField } from '../../common';

import AdditionalResourceTypesForBasicRulesTable from './AdditionalResourceTypesForBasicRulesTable';

function DialogBasicLimit({ formMode, closeDialog, id }: { formMode: FormMode; closeDialog: () => void; id?: string }) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { createMode, editMode } = useViewModesV2(formMode);
  const form = useForm({
    resolver: yupResolver(getTradeRulesSchema(editMode))
  });
  const { showSuccessSnackbar } = useSnackbar();
  const { data, isLoading } = useTradeRulesDetailsQuery(id, { enabled: !!id });

  const getLabel = partialTranslate('foreignPermits:tabs.socialCommission.tabs.SocialCommissionBasicRules');
  const getLabelTradeRules = partialTranslate(
    'foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.formFields'
  );

  const { mutate: createApplication, isLoading: isLoadingCreate } = useCreateTradeRulesMutation({
    onSuccess: () => {
      showSuccessSnackbar(t('global:success.add'));
      queryCache.invalidateQueries(SocialCommissionQueryKeysEnum.TRADE_RULES_DETAILS);
      closeDialog();
    }
  });

  const { mutate: editApplication, isLoading: isLoadingEdit } = useEditTradeRulesMutation({
    onSuccess: () => {
      showSuccessSnackbar(t('global:success.save'));
      queryCache.invalidateQueries(SocialCommissionQueryKeysEnum.TRADE_RULES_DETAILS);
      closeDialog();
    }
  });

  const submitCreate = async (formValues: Partial<ICivicBoardRuleCreateRequest & CivicBoardRuleDetails>) => {
    const request: CivicBoardRuleCreateRequest = {
      mainResourceTypeId: formValues.mainResourceTypeId?.id,
      validFrom: formValues.validFrom,
      validTo: formValues.validTo,
      resourceTypes:
        formValues?.types?.map(item => {
          return {
            resourceTypeId: item.resourceTypeId,
            impactLimit: item.impactLimit
          };
        }) || [],
      active: formValues.active ? formValues.active : false,
      typeKey: RuleType.FOLDER_LIMIT,
      items: formValues.items?.map(item => ({
        ruleConfiguration: {
          ruleItemCode: RuleItemCodes.FOLDER_LIMIT_RULE,
          maxSettlementReturnDate: item['maxSettlementReturnDate'],
          limitFactor: item['limitFactor'],
          multiplier: item['multiplier']
        }
      }))
    };
    createApplication(request);
  };

  const submitEdit = (formValues: Partial<ICivicBoardRuleUpdateRequest & CivicBoardRuleDetails>) => {
    const request: CivicBoardRuleUpdateRequest & { resourceTypeId: string } = {
      validFrom: formValues.validFrom,
      validTo: formValues.validTo,
      version: data.version,
      resourceTypeId: formValues.resourceTypeId,
      resourceTypes: formValues?.types?.map(item => {
        return {
          resourceTypeId: item.resourceTypeId,
          impactLimit: item.impactLimit
        };
      }),
      active: formValues.active ? formValues.active : false,
      items: formValues.items?.map(item => ({
        id: item?.id,
        ruleConfiguration: {
          ruleItemCode: RuleItemCodes.FOLDER_LIMIT_RULE,
          maxSettlementReturnDate: item['maxSettlementReturnDate'],
          limitFactor: item['limitFactor'],
          multiplier: item['multiplier']
        }
      }))
    };
    editApplication({ civicBoardRuleId: data.id, civicBoardRuleUpdateRequest: request });
  };
  useEffect(() => {
    form.reset({
      ...data,
      items: data?.items?.map(item => ({
        id: item.id,
        ...item.ruleConfiguration
      }))
    });
  }, [data, form]);

  const getDialogTitle = () => {
    if (createMode) {
      return getLabel('dialogTitleAdd');
    }
    if (editMode) {
      getLabel('dialogTitleEdit');
    }
    return getLabel('dialogTitleView');
  };

  const values = useMemo(() => ({ ...form, formMode }), [form, formMode]);

  return (
    <FormV2Context.Provider value={values}>
      <Dialog
        title={getDialogTitle()}
        confirmText={createMode || editMode ? t('action.save') : null}
        onConfirm={createMode || editMode ? form.handleSubmit(createMode ? submitCreate : submitEdit) : null}
        onClose={closeDialog}
        onCancel={closeDialog}
        dialogSize="medium"
        isConfirmLoading={isLoadingCreate || isLoadingEdit || isLoading}
        isOpen
      >
        <GridLayout itemProps={{ xs: 6 }} containerProps={{ className: 'mb-6' }}>
          <SwitchField label={getLabel('fields.isIncludingLimit')} name="active" />
          {!createMode && (
            <DatepickerField
              name={typedNameV2<CivicBoardRuleDetails>('year')}
              inputMode={createMode ? InputMode.FORM : InputMode.VIEW}
              label={getLabelTradeRules('year')}
              views={['year']}
              minDate={moment().subtract(10, 'year').toDate()}
              maxDate={moment().add(10, 'year').toDate()}
              isFullWidth={false}
              isRequired
            />
          )}
          {!createMode && (
            <DictionarySelectField
              inputMode={createMode ? InputMode.FORM : InputMode.VIEW}
              name={typedNameV2<CivicBoardRuleDetails>('countryCodeKey')}
              label={getLabelTradeRules('country')}
              dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
              isRequired
            />
          )}
          {createMode ? (
            <ResourceTypesSelectField
              label={getLabelTradeRules('name')}
              fieldName={typedNameV2<CivicBoardRuleCreateRequest>('mainResourceTypeId')}
              key="resourceType"
              isRequired
              filterForBasicRules
              yearLimiter={{
                yearGreaterThanOrEqual: moment().year(),
                yearLessThanOrEqual: moment().add(1, 'year').year()
              }}
              queryKey={SocialCommissionQueryKeysEnum.RESOURCE_TYPE_BASIC_RULES}
              basicLimit
            />
          ) : (
            <DictionarySelectField
              inputMode={InputMode.VIEW}
              name={typedNameV2<CivicBoardRuleDetails>('resourceFormNameKey')}
              label={getLabelTradeRules('name')}
              dictionaryName={DomainDictionaryEnum.RESOURCE_FORM_NAME}
              isRequired
            />
          )}
          <DatepickerField
            name={typedNameV2<CivicBoardRuleCreateRequest>('validFrom')}
            label={getLabelTradeRules('effectiveDateFrom')}
            viewModeDateParser={convertDateToDateTimeFormat}
            isRequired
          />
          <DatepickerField
            name={typedNameV2<CivicBoardRuleCreateRequest>('validTo')}
            label={getLabelTradeRules('effectiveDateTo')}
            viewModeDateParser={convertDateToDateTimeFormat}
            isRequired
          />
          <DatepickerField
            name="items[0].maxSettlementReturnDate"
            label={getLabel('fields.maxSettlementReturnDate')}
            isRequired
          />
          <NumberInputField
            name="items[0].limitFactor"
            label={getLabel('fields.limitFactor')}
            min={0}
            max={9999999999999}
            step={0.01}
            decimalPrecision={2}
            isRequired
          />
          <NumberInputField
            name="items[0].multiplier"
            label={getLabel('fields.multiplier')}
            min={0}
            max={9}
            step={0.01}
            decimalPrecision={2}
            isRequired
          />
          <div />
          <GridItem xs={12}>
            <AdditionalResourceTypesForBasicRulesTable />
          </GridItem>
        </GridLayout>
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default DialogBasicLimit;
