import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const changeProceedingToComplete = (params: { proceedingId: string; version: number }) => {
  const { proceedingId, version } = params;
  return API.proceeding.closeProceeding(
    proceedingId,
    {
      version
    },
    { ...endpointsConfig.closeProceeding }
  );
};

export const useProceedingCompleteMutation = (params?) => useMutation(changeProceedingToComplete, params);
