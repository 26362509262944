import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProceedingsKreptdApiUpdateProceedingKreptdFolderRequest } from '@ibtm/domain';
import * as Yup from 'yup';

import { Dialog, FormV2Context, GridLayout } from '@libs/common/v2';

import { FolderSelectField } from '@libs/domain/application';

import { useFolderDetailsQuery } from '../../../../folder';
import { useFolderChangeMutations } from '../../../api';
import { ChangeFolderFormFields, IProceedingDetailsClient } from '../../../models';

interface IProps {
  initialData: IProceedingDetailsClient;
  closeDialog: () => void;
  onSuccess?: () => void;
}

function FolderChangeForm({ initialData, onSuccess, closeDialog }: IProps) {
  const [t] = useTranslation();
  const { mutate: changeFolder, isLoading } = useFolderChangeMutations();
  const { showSnackbar } = useSnackbar();

  const { data: folderData } = useFolderDetailsQuery(initialData.folderId);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    clearErrors
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        folder: Yup.object().nullable().required()
      })
    ),
    defaultValues: {
      folder: null
    }
  });

  const onSubmit = (formData: ChangeFolderFormFields) => {
    const requestData: ProceedingsKreptdApiUpdateProceedingKreptdFolderRequest = {
      proceedingId: initialData.id,
      proceedingKreptdFolderUpdateRequest: {
        folderId: formData.folder?.value?.id,
        version: initialData.version
      }
    };
    changeFolder(requestData, {
      onSuccess: () => {
        showSnackbar('success', t('roadTransport:messages.changeFolderSuccess'));

        onSuccess?.();
        closeDialog();
      }
    });
  };

  const folderInitialData = useMemo(() => {
    if (folderData?.number) {
      return {
        value: { id: initialData.folderId },
        name: folderData?.number
      };
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderData]);

  useEffect(() => {
    if (folderInitialData) {
      setValue('folder', folderInitialData);
    }
  }, [folderInitialData, setValue]);

  const values = useMemo(
    () => ({
      control,
      clearErrors,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting
    }),
    [clearErrors, control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('roadTransport:dialog.changeFolderTitle')}
      confirmText={t('action.change')}
      cancelText={t('action.cancel')}
      onCancel={closeDialog}
      onConfirm={() => handleSubmit(data => onSubmit(data as ChangeFolderFormFields))()}
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <GridLayout itemProps={{ xs: 12 }}>
          <FolderSelectField
            fieldName="folder"
            label={t('roadTransport:fields.folder')}
            initialValue={folderInitialData}
            isRequired
          />
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default FolderChangeForm;
