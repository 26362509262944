import { useTranslation } from 'react-i18next';
import { useAppConfig } from '@libs/app-config';

import { BooleanValue, Dialog, GridItem, GridLayout, Value, WarningInformation } from '@libs/common/v2';

export interface LocksSummaryData {
  locksToSetUp?: number;
  issuedLocks?: number;
  permissionsGenerated?: boolean;
  forms: {
    number: string | number;
  }[];
}

interface IProps {
  data: LocksSummaryData;
  onClose: () => void;
  showFormsNumbers?: boolean;
}

function LocksSummaryDialog({ data, onClose, showFormsNumbers }: IProps) {
  const [t] = useTranslation();
  const {
    raw: {
      prints: { showFormsNumbersInLocksSummary }
    }
  } = useAppConfig();

  const isWarningVisible = data?.issuedLocks < data?.locksToSetUp;

  const handleOnClose = () => {
    onClose();
  };

  const isAnyFormNumber = data?.forms?.some(form => !!form.number);

  return (
    <Dialog title={t('prints:locksSummary.title')} onClose={handleOnClose} onCancel={handleOnClose} isOpen>
      <>
        {isWarningVisible && <WarningInformation content={t('prints:messages.notEnoughIssues')} />}
        <GridLayout itemProps={{ xs: 4 }} containerProps={{ className: isWarningVisible && 'mt-10' }}>
          <Value label={t('license:fields.issuedLocks')} value={data?.issuedLocks} />
          <Value label={t('license:fields.locksToSetUp')} value={data?.locksToSetUp} />
          <BooleanValue label={t('license:fields.permissionsGenerated')} value={data?.permissionsGenerated} />
          {showFormsNumbersInLocksSummary && showFormsNumbers && (
            <GridItem xs={12}>
              <Value
                label={t('prints:locksSummary.formsNumbers')}
                value={isAnyFormNumber ? data?.forms?.map(form => form.number)?.join(', ') : t('lack')}
              />
            </GridItem>
          )}
        </GridLayout>
      </>
    </Dialog>
  );
}

export default LocksSummaryDialog;
