import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

type Params = {
  applicationId: string;
  pricingId: string;
};

function unlinkMoneyTransfersFromApplication({ applicationId, pricingId }: Params) {
  // @ts-ignore TODO
  return API.moneyTransfer.unlinkMoneyTransfersFromApplication(applicationId, pricingId, {
    ...endpointsConfig.unlinkMoneyTransfersFromApplication
  });
}

function useLinkMoneyTransfersToApplicationMutation() {
  return useMutation(unlinkMoneyTransfersFromApplication);
}

export default useLinkMoneyTransfersToApplicationMutation;
