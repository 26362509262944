import { useTranslation } from 'react-i18next';

import { partialTranslate } from '@libs/common';
import {
  DATE_FORMAT,
  DatepickerField,
  DictionarySelectField,
  GridItem,
  GridLayout,
  InputMode,
  Section,
  TextInputField,
  typedNameV2,
  useFormV2Context,
  useRouter,
  useViewModesV2
} from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { FieldsType } from '../../model';

function InvoiceSection({ baseUrl }: { baseUrl?: string }) {
  const [t] = useTranslation();
  const { goToPage } = useRouter();
  const { getValues, watch, formMode } = useFormV2Context();
  const { createMode } = useViewModesV2(formMode);
  const getLabel = partialTranslate('releaseDocuments:fields');
  const isCorrection = watch('isCorrection') as boolean;

  const numberName = !isCorrection || createMode ? 'number' : 'correctedInvoice.number';
  const dateOfIssueName = !isCorrection || createMode ? 'dateOfIssue' : 'correctionDateOfIssue';
  const statusName = !isCorrection || createMode ? 'statusKey' : 'correctedInvoice.statusKey';

  return (
    <Section
      title={
        !isCorrection
          ? t('releaseDocuments:create.sections.invoice')
          : t('releaseDocuments:create.sections.invoiceCorrection')
      }
      isCollapsable
      fillHeight
    >
      <GridLayout itemProps={{ xs: 12 }}>
        <GridItem xs={6}>
          <TextInputField
            name={typedNameV2<FieldsType>(numberName)}
            label={getLabel('invoice.number')}
            inputMode={InputMode.VIEW}
            inputProps={{ maxLength: 20 }}
            {...(isCorrection && {
              hyperlink: baseUrl,
              onHyperlinkClick: e => {
                e.preventDefault();
                const id = createMode ? (getValues('id') as string) : (getValues('correctedInvoice.id') as string);
                goToPage(`${baseUrl}${id}/VIEW`);
              }
            })}
            isRequired
            key={numberName}
          />
        </GridItem>
        <GridItem xs={6}>
          <DatepickerField
            name={dateOfIssueName}
            label={getLabel('invoice.issuedDate')}
            displayFormat={DATE_FORMAT}
            inputMode={InputMode.VIEW}
            isRequired
            key={dateOfIssueName}
          />
        </GridItem>
        <GridItem xs={6}>
          <TextInputField
            name={typedNameV2<FieldsType>('folder.number')}
            label={getLabel('invoice.folderNumber')}
            inputMode={InputMode.VIEW}
          />
        </GridItem>
        <GridItem xs={6}>
          <TextInputField
            name={typedNameV2<FieldsType>('application.number')}
            label={getLabel('invoice.applicationNumber')}
            inputMode={InputMode.VIEW}
            inputProps={{ maxLength: 20 }}
          />
        </GridItem>
        {!isCorrection && (
          <GridItem xs={6}>
            <DictionarySelectField
              name={statusName}
              label={getLabel('invoice.status')}
              inputMode={InputMode.VIEW}
              dictionaryName={DomainDictionaryEnum.INVOICE_STATUS}
              isRequired
              key={statusName}
            />
          </GridItem>
        )}
      </GridLayout>
    </Section>
  );
}

export default InvoiceSection;
