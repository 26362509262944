import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { LicenseCopySnapshot, PermissionCopySnapshot, RenounceCopyAssignUpdateRequest } from '@ibtm/domain';
import { makeStyles } from '@mui/styles';

import { partialTranslate } from '@libs/common';
import {
  AutocompleteLazyFetchSelectField,
  Dialog,
  FormV2Context,
  GridLayout,
  SelectOption,
  typedNameV2
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { Theme } from '@libs/common/v2/theme';

import { API } from '@libs/domain/api';
import { ApplicationQueryKeysEnum } from '@libs/domain/application';
import { DomainDictionaryEntry } from '@libs/domain/config';
import { LicenseStatusEnum } from '@libs/domain/license';
import { PermissionsQueryKeysEnum, PermissionTypeEnum } from '@libs/domain/permission';

import { useRenounceCopiesyQuery, useUpdateRenounceCopyMutation } from '../api';

interface IProps {
  applicationId: string;
  applicationTypeKey: string;
  folderId?: string;
  transferredFoldersIds?: string[];
  closeDialog: () => void;
  isLicenseSelect?: boolean;
  isPermissionVisible?: boolean;
}

function SearchLicensePermissionDialog({
  applicationId,
  applicationTypeKey,
  folderId,
  transferredFoldersIds,
  closeDialog,
  isLicenseSelect,
  isPermissionVisible
}: IProps) {
  const queryCache = useQueryCache();
  const classes = useStyles();
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const getLabel = partialTranslate('applications:additionalField');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    setError,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all'
  });

  const { data } = useRenounceCopiesyQuery(applicationId, { enabled: !!applicationId });

  const { mutate: updateMutation } = useUpdateRenounceCopyMutation();

  const submitForm = (values: {
    licenseCopies?: LicenseCopySnapshot[];
    permissionCopies?: PermissionCopySnapshot[];
  }) => {
    const updateData = {
      licenseCopies: values?.licenseCopies?.map(item => item.id),
      permissionCopies: values?.permissionCopies?.map(item => item.id),

      version: data?.version
    };
    setConfirmLoading(true);
    closeDialog();

    updateMutation(
      {
        applicationId,
        renounceCopyAssignUpdateRequest: updateData
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('global:success.save'));
          closeDialog();
          setConfirmLoading(false);
          queryCache.invalidateQueries(ApplicationQueryKeysEnum.RENOUNCE_COPY);
        }
      }
    );
  };

  const renderOption = (props: SelectOption<LicenseCopySnapshot>) => (
    <div>
      {props?.value?.licenseNumber}
      <div className={classes.formNumber}>
        {getLabel('formNumber')}: {props?.value?.formNumber}
      </div>
    </div>
  );

  const getLicenseOptionLabel = (option: LicenseCopySnapshot) => {
    return option.formNumber;
  };

  const getPermissionOptionLabel = (option: PermissionCopySnapshot) => {
    return option.number;
  };

  const values = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      setError,
      trigger,
      unregister,
      isSubmitting
    }),
    [control, errors, getValues, isSubmitting, register, setError, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('applications:button.findForm')}
      confirmText={t('action.add')}
      cancelText={t('action.close')}
      onConfirm={handleSubmit(submitForm)}
      onCancel={closeDialog}
      dialogSize="medium"
      isConfirmLoading={confirmLoading}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <form>
          <GridLayout itemProps={{ xs: 12 }}>
            {isLicenseSelect && (
              <AutocompleteLazyFetchSelectField
                isMultiple
                name={typedNameV2<RenounceCopyAssignUpdateRequest>('licenseCopies')}
                queryKey={PermissionsQueryKeysEnum.LICENSE_COPY}
                optionLabelParser={getLicenseOptionLabel}
                renderOption={renderOption}
                api={{
                  FETCH: (searchText: string, params: { page: number; size: number }) =>
                    API.license
                      .getLicenseCopySnapshotPage({
                        ...params,
                        licenseNumberOrFormNumberContains: searchText,
                        folderIdIn: [folderId, ...(transferredFoldersIds || [])],
                        statusKeyIn: [LicenseStatusEnum.ACTIVE, LicenseStatusEnum.PENDING]
                      })
                      .then(response => {
                        return {
                          ...response,
                          data: {
                            ...response.data,
                            content: response.data.content
                          }
                        };
                      })
                }}
                label={getLabel('licenseCopies')}
              />
            )}

            {isPermissionVisible && (
              <AutocompleteLazyFetchSelectField
                isMultiple
                name={typedNameV2<RenounceCopyAssignUpdateRequest>('permissionCopies')}
                queryKey={PermissionsQueryKeysEnum.PERMISSIONS_LIST}
                optionLabelParser={getPermissionOptionLabel}
                api={{
                  FETCH: (searchText: string, params: { page: number; size: number }) =>
                    API.permissions
                      .getPermissionCopiesPage({
                        ...params,
                        permissionNumberOrCopyNumberContains: searchText,
                        folderIdIn: [folderId, ...(transferredFoldersIds || [])],
                        ...(applicationTypeKey === DomainDictionaryEntry.APPLICATION_TYPE.licenseDischargeWaiver && {
                          typeKeyIn: [
                            PermissionTypeEnum.ROAD_CARRIER_GOODS_CARRIAGE,
                            PermissionTypeEnum.ROAD_CARRIER_PASSENGER_CARRIAGE
                          ]
                        })
                      })
                      .then(response => {
                        return {
                          ...response,
                          data: {
                            ...response.data,
                            content: response.data.content
                          }
                        };
                      })
                }}
                label={getLabel('permissionCopies')}
              />
            )}
          </GridLayout>
        </form>
      </FormV2Context.Provider>
    </Dialog>
  );
}
const useStyles = makeStyles<Theme>(theme => ({
  formNumber: {
    ...theme.typography.textSm.normal,
    color: theme.palette.grey[600]
  }
}));

export default SearchLicensePermissionDialog;
