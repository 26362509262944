import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { MatrixCellInputTypeEnum, MatrixRow } from '@libs/common/v2';

import { permissionSingleMatrixHeaders } from '../utils/foreignPermissionApplicationUtils';

interface ISinglePermissionsProps {
  row: { amount: number; id: string; limit?: number };
  index: number;
  rowTitles: { amount: number; id: string; limit?: number }[];
  mataformFieldId: string;
  isLimitColumnHidden: boolean;
}

export function DefaultSinglePermissionsMatrixRow({
  row,
  index,
  rowTitles,
  mataformFieldId,
  isLimitColumnHidden
}: ISinglePermissionsProps) {
  const classes = useStyles();

  return (
    <MatrixRow
      row={row}
      cells={[
        { name: row, width: 400 },
        ...[
          {
            type: MatrixCellInputTypeEnum.NUMBER,
            fieldName: 'limit',
            value: 0,
            isDisabled: true
          },
          {
            type: MatrixCellInputTypeEnum.NUMBER,
            fieldName: 'amount',
            value: 0,
            max: row.limit
          },
          {
            type: MatrixCellInputTypeEnum.TEXT,
            fieldName: 'notes',
            minWidth: 'auto',
            isDisabled: false
          }
        ]
          .filter(cell => {
            return !isLimitColumnHidden || cell.fieldName !== 'limit';
          })
          .map((input, inputIndex) => ({
            ...input,
            width: permissionSingleMatrixHeaders(isLimitColumnHidden)[inputIndex].width
          }))
      ]}
      className={clsx(classes.notesInput, rowTitles.length === index + 1 && classes.lastRow)}
      formPrefix={`${mataformFieldId}.${row.id}`}
    />
  );
}

const useStyles = makeStyles(() => ({
  lastRow: {
    '& > .cell': {
      borderBottom: 0
    }
  },
  notesInput: {
    '& > .cell:last-child > .MuiFormLabel-root': {
      width: '100%'
    }
  }
}));
