import { useContext } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';

import { AutocompleteLazyFetchSelect, FormV2Context, SelectOption } from '@libs/common/v2';

interface IProps {
  name: FieldPath<FieldValues>;
  label: string;
  getOptionLabel: (option: SelectOption) => string;
  fetchNameOptionsFunction: (params: any, page: number) => Promise<any>;
  fetchedDataSelectParser: (data) => SelectOption[];
  queryKey: string;
}

function AutocompleteLazyFetchSelectField({
  name,
  getOptionLabel,
  queryKey,
  label,
  fetchNameOptionsFunction,
  fetchedDataSelectParser
}: IProps) {
  const { control, setValue } = useContext(FormV2Context);

  const onSelectedUserChange = (_, value) => {
    setValue(name as any, value);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onBlur }, fieldState }) => (
        <AutocompleteLazyFetchSelect
          queryKey={queryKey}
          fetchFunctionResolverWithPage={fetchNameOptionsFunction}
          fetchedDataSelectParser={fetchedDataSelectParser}
          name={name}
          getOptionLabel={getOptionLabel}
          renderOption={getOptionLabel}
          label={label}
          value={value as SelectOption | SelectOption[] | string}
          onBlur={onBlur}
          isError={!!fieldState.error}
          helperText={fieldState.error?.message}
          onChange={(e, v) => {
            onSelectedUserChange(e, v);
          }}
          isRequired
        />
      )}
    />
  );
}

export default AutocompleteLazyFetchSelectField;
