import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { PermissionPassToDistrictAuthorityRequest } from '@ibtm/domain';

import {
  Dialog,
  DictionarySelectField,
  FormMode,
  FormV2Context,
  GridLayout,
  Section,
  typedNameV2
} from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { usePermissionPassToDistrictAuthorityMutation } from '../api';

interface IProps {
  permissionId: string;
  onCloseDialog: () => void;
  onSuccess: () => void;
  isOpen: boolean;
}

type PassToDistrictAuthorityFields = {
  issuingAuthorityKey?: {
    key: string;
    value: string;
  };
};

function PermissionPassToDistrictAuthorityDialog({ permissionId, onCloseDialog, onSuccess, isOpen }: IProps) {
  const [t] = useTranslation();

  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { mutate: passToDistrictAuthority, isLoading } = usePermissionPassToDistrictAuthorityMutation();

  const {
    control,
    register,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    handleSubmit
  } = useForm({
    mode: 'onBlur',
    criteriaMode: 'all'
  });

  const formValues = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      formMode: FormMode.EDIT
    }),
    [control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting]
  );

  const handlePassToDisctrictAuthoritySubmit = (values: PassToDistrictAuthorityFields) => {
    passToDistrictAuthority(
      {
        id: permissionId,
        requestData: {
          issuingAuthority: values?.issuingAuthorityKey?.value
        }
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('permission:action.passToDistrictAuthority.successMessage'));
          onCloseDialog();
          onSuccess();
        },
        onError: () => {
          showErrorSnackbar(t('permission:action.passToDistrictAuthority.failureMessage'));
        }
      }
    );
  };

  return (
    <FormV2Context.Provider value={formValues}>
      <form onSubmit={handleSubmit(handlePassToDisctrictAuthoritySubmit)}>
        <Dialog
          title={t('permission:action.passToDistrictAuthority.dialogTitle')}
          confirmText={t('action.save')}
          cancelText={t('action.cancel')}
          onConfirm={handleSubmit(handlePassToDisctrictAuthoritySubmit)}
          onCancel={onCloseDialog}
          dialogSize="medium"
          isConfirmLoading={isLoading}
          isOpen={isOpen}
        >
          <Section isCollapsable isModalSection isHeaderVisible={false}>
            <GridLayout itemProps={{ xs: 12 }}>
              <DictionarySelectField
                label={t('permission:field.issuingAuthority')}
                name={typedNameV2<PermissionPassToDistrictAuthorityRequest>('issuingAuthority')}
                dictionaryName={DomainDictionaryEnum.AUTHORITY}
                isRequired
              />
            </GridLayout>
          </Section>
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
}

export default PermissionPassToDistrictAuthorityDialog;
