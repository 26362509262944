import {
  CargoTransportationExclusions,
  EkmtGroup,
  FolderExtendedLite,
  ForeignPermissionSnapshot,
  ResourceTypeSnapshot,
  Versioned
} from '@ibtm/domain';

import { SelectOption } from '@libs/common/v2';

export enum DepotTypes {
  MGB = 'MGB',
  MG = 'MG',
  MPBIK = 'MPBIK',
  MP = 'MP',
  MPD = 'MPD'
}

export enum DepotTranslationKeys {
  MGB = 'resource:depots.MGB',
  MG = 'resource:depots.MG',
  MPBIK = 'resource:depots.MPBIK',
  MP = 'resource:depots.MP',
  MPD = 'resource:depots.MPD'
}

export enum ListTypeEnum {
  SOURCE = 'SOURCE',
  TARGET = 'TARGET'
}

export interface GlobalFilters {
  year: number;
}

export interface ResourceObjectPoolClient {
  id: string;
  group: string;
  country: string;
  name: string;
  numberFrom: number;
  numberTo: number;
  amount: number;
  stateKey: string;
  resourceType: {
    id: string;
  };
  admissionDate?: string;
}

export interface ResourceObjectGapAndCorrectPoolClient extends ResourceObjectPoolClient {
  admissionDate?: string;
}

export interface IResourceObjectAssociationPoolClient {
  id: string;
  country: string;
  name: string;
  state: string;
  numberFrom: number;
  numberTo: number;
  amount: number;
  limit: number;
  resourceType: {
    id: string;
  };
}

export interface ResourceObjectContentPoolClient {
  id: string;
  year: number;
  group: string;
  country: string;
  name: string;
  numberFrom: number;
  numberTo: number;
  amount: number;
  stateKey: string;
  resourceType: {
    id: string;
  };
  admissionDate?: string;
}

export interface RollbackReturnsRequestData {
  formId: string;
  reason: string;
}

export interface ChangeAdminsRequestData {
  depotId: string;
  adminIds: string[];
}

export interface ChangeAdminsFormFields {
  admins: {
    id: string;
  }[];
  admin: { id: string };
}

export interface IDepotsListClient {
  id: string;
  depotNumber: string;
  amount: number;
  number: number;
  type: string;
  name: string;
}

export interface IDepotManagersListClient {
  id: string;
  depot: {
    id: string;
    type: string;
    name: string;
    user: {
      id: string;
      name: string;
    };
  };
}

export type IResourceTypesClient = {
  id: string;
  type: string;
  name: string;
  year: number;
  transbitName?: string;
  fullName?: string;
  countryKey: string;
  ekmtCategoryKey?: string;
  groupKey: string;
} & Versioned;

export interface IResourceObjectsClient {
  id: string;
  year: number;
  group: string;
  name?: string;
  state: string;
  released: string;
  releasedTo: FolderExtendedLite;
  releasedFrom: FolderExtendedLite;
  notes: string;
  returnDate?: string;
  number?: string;
  depot: {
    id: string;
    typeKey: string;
    name?: string;
  };
  type: {
    id: string;
    type: string;
  };
  foreignPermission?: ForeignPermissionSnapshot;
  releaseDocumentId?: string;
}

export interface IDepotTransferClient {
  id: string;
  sourceDepot: {
    id: string;
    name: string;
    type: string;
  };
  targetDepot: {
    id: string;
    name: string;
    type: string;
  };
  formName: string;
  numberFrom: number;
  numberTo: number;
  amount: number;
}

export interface IDepotDetailsClient {
  id: string;
  type: string;
  name: string;
  code: string;
  version: number;
}

export interface IDisposalClient {
  id: string;
  year: number;
  disposalDate: string;
  country: string;
  name: string;
  disposalReason: string;
  number?: string;
}

export interface IAssociationOrdersClient {
  id: string;
  created: string;
  association: string;
  country: string;
  name: string;
  amount: number;
  direction: string;
  status: string;
  ranges: IAssociationOrderRanges[];
}

export interface IAssociationOrderDetailsClient {
  id: string;
  ranges: IAssociationOrderRanges[];
}

export interface IAssociationOrderRanges {
  id: string;
  country: string;
  name: string;
  numberFrom: string;
  numberTo: string;
  amount: number;
}

// FORM FIELDS

interface IEuroClass {
  name?: string;
  value: string;
  active?: boolean;
}

export interface IResourceTypeFormFields {
  id?: string;
  formType: {
    name?: string;
    value: string;
  };
  formGroup: {
    name?: string;
    value: string;
  };
  documentTemplate: {
    name?: string;
    value: string;
  };
  year: number;
  country: {
    name?: string;
    value: string;
  };
  limitTypes: {
    name?: string;
    value: string;
  };
  transitTypeKey: {
    name?: string;
    value: string;
  };
  euroClass: IEuroClass[];
  name: {
    name?: string;
    value: string;
  };
  insuredValue: number;
  isLeadingZeros: boolean;
  isPrefix: boolean;
  ekmtType: boolean;
  requiredLength: number;
  prefix: string;
  limitType?: {
    name?: string;
    value: string;
  };
  ekmtGroup?: {
    name?: string;
    value: EkmtGroup;
  };
  ekmtCategoryKey?: {
    name?: string;
    value: string;
  };
  ekmtEuroClass?: IEuroClass;
  version: number;
  cargoTransportationExclusions?: CargoTransportationExclusions;
  parentResourceType?: {
    id?: string;
    name?: string;
  };
}

export interface IResourceObjectReturnFields {
  year: string;
  group: string;
  name: string;
  state: string;
  released: string;
  returnDate: string;
  notes: string;
}

export interface IExternalReceivesFormFields {
  formType: {
    id: string;
    value?: {
      nameKey?: string;
    };
  };
  numberFrom: number;
  numberTo: number;
  amount: number;
}

export interface IExternalReleaseFormFields {
  formType: {
    id: string;
  };
  numberFrom: number;
  numberTo: number;
  amount: number;
  releaseType: {
    name: string;
    value: string;
  };
  address: string;
}

export interface IBatchDisposalsFormFields {
  disposalReason: {
    name: string;
    value: string;
  };
  pools: {
    numberFrom: number;
    numberTo: number;
    amount: number;
  }[];
}

export type TravelFormPoolItem = {
  numberFrom: number;
  numberTo: number;
  amount: number;
  state?: string;
  id?: string;
};
export type TravelFormPoolFormData = { pools: Array<TravelFormPoolItem> };

export type PoolItem = {
  name?: string;
  numberFrom: number;
  numberTo: number;
  amount: number;
  state?: string;
  id?: string;
};
export type PoolFormData = { pools: Array<PoolItem> };

export type TypeTransformFields = {
  sourceType: SelectOption<ResourceTypeSnapshot>;
  targetType: SelectOption<ResourceTypeSnapshot>;
};

export type FormTransferFields = {
  pools: ResourceObjectContentPoolClient[];
};

export interface IDepotManagerListClient {
  depotIds?: string[];
}

export interface IResourceObjectAssociationPoolsAvailabilityClient {
  numberFrom: number;
  numberTo: number;
  amount: number;
  single: boolean;
}

export type ExternalReleaseFields = Omit<IResourceObjectAssociationPoolsAvailabilityClient, 'single'> & {
  formType: SelectOption<ResourceTypeSnapshot>;
  releaseType: SelectOption<string>;
  address: string;
};

export type ResourceDetailsParams = {
  depotId: string;
};
