export type ForeignPermitsTabsConfigTypes = {
  name: string;
  label?: string;
  type?: string;
  icon?: string;
  url?: string;
};

export const ForeignPermitsTabsConfig: ForeignPermitsTabsConfigTypes[] = [
  {
    name: 'releasedPermits',
    label: `foreignPermits:tabs.releasedPermits.title`,
    url: '/released-permits',
    type: 'item'
  },
  {
    name: 'disposablePermits',
    label: `foreignPermits:tabs.disposablePermits.title`,
    url: '/disposable-permits',
    type: 'item'
  },
  {
    name: 'ekmt',
    label: `foreignPermits:tabs.ekmt.title`,
    url: '/ekmt',
    type: 'item'
  },
  {
    name: 'socialCommision',
    label: `foreignPermits:tabs.socialCommission.title`,
    url: '/social-commission',
    type: 'item'
  }
];
