import { useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { ErrorIcon, Section, WarningIcon } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';

import { IconComponent } from '../icon/Icon';

interface IProps {
  message: string;
  type: 'warning' | 'error';
  className?: string;
}

function Error({ className, type, message }: IProps) {
  const classes = useStyles();

  const Icon = useMemo(() => {
    switch (type) {
      case 'warning':
        return WarningIcon as IconComponent;
      default:
        return ErrorIcon as IconComponent;
    }
  }, [type]);

  return (
    <Section className={clsx(className, 'my-24')} isHeaderVisible={false}>
      <div className="flex items-center ml-24">
        <Icon fontSize="large" color="error" className="pr-8" />
        <span className={classes.message}>{message}</span>
      </div>
    </Section>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  message: {
    color: theme.palette.warning[600]
  }
}));

export default Error;
