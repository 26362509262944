import { QueryConfig, useQuery } from 'react-query';
import { InvoiceDetails } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import { ReleaseDocumentsQueryKeysEnum } from './QueryKeysEnum';

export const getSuspensionReleaseDocumentsDetails = async (_, params: { suspensionId: string; invoiceId: string }) => {
  const { suspensionId, invoiceId } = params;
  const data = await API.suspensionReleaseDocuments.getSuspensionInvoices(suspensionId, invoiceId, {
    ...endpointsConfig.getSuspensionInvoices
  });
  return data.data;
};

export default function useSuspensionReleaseDetailsQuery(
  params: { suspensionId: string; invoiceId: string },
  queryConfig: QueryConfig<any, unknown> = {},
  queryKey = ReleaseDocumentsQueryKeysEnum.RELEASE_DOCUMENTS_DETAILS
) {
  return useQuery<InvoiceDetails>([queryKey, params], getSuspensionReleaseDocumentsDetails, queryConfig);
}
