import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeResourceObjectOrderStatusRequest, ResourceObjectOrderUpdateRequest } from '@ibtm/domain';
import * as Yup from 'yup';

import { Dialog, FormV2Context } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { DomainDictionaryEntry } from '@libs/domain/config';

import {
  ResourceQueryKeysEnum,
  updateResourceObjectOrder,
  useAssociationOrderStatusChangeMutation,
  useResourceObjectsOrdersQuery
} from '../../../api';
import { usePoolsValidationSchema } from '../../../hooks';
import { IAssociationOrderRanges } from '../../../model';
import { PoolsForm } from '../common';

interface IProps {
  orderId: string;
  closeDialog: () => void;
}

function AssociationOrderReturnConfirmForm({ orderId, closeDialog }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();

  const [isOrderUpdating, setIsOrderUpdating] = useState(false);

  const { mutate: changeOrderStatus, isLoading } = useAssociationOrderStatusChangeMutation();
  const { data: orderData } = useResourceObjectsOrdersQuery(orderId);

  const poolsValidationSchema = usePoolsValidationSchema(orderData?.ranges);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    reset
  } = useForm<
    Partial<{
      pools: IAssociationOrderRanges[];
    }>
  >({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        pools: poolsValidationSchema
      })
    )
  });

  useEffect(() => {
    if (orderData?.ranges.length) {
      reset({
        pools: orderData.ranges
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData]);

  const onSubmit = async (dataForm: Partial<{ pools: IAssociationOrderRanges[] }>) => {
    setIsOrderUpdating(true);
    const updateRequestData: ResourceObjectOrderUpdateRequest = {
      orderId,
      numberFrom: dataForm.pools[0].numberFrom,
      numberTo: dataForm.pools[0].numberTo,
      amount: dataForm.pools[0].amount,
      directionKey: DomainDictionaryEntry.RESOURCE_FORM_ORDER_DIRECTION.RETURNS
    };
    const updateResults = await updateResourceObjectOrder(updateRequestData);
    setIsOrderUpdating(false);

    if (updateResults) {
      const requestData: ChangeResourceObjectOrderStatusRequest = {
        orderId,
        operationKey: DomainDictionaryEntry.RESOURCE_OBJECT_ORDER_OPERATION.CONFIRM
      };

      changeOrderStatus(requestData, {
        onSuccess: () => {
          queryCache.invalidateQueries(ResourceQueryKeysEnum.ASSOCIATION_ORDERS_LIST);
          showSnackbar('success', t('resource:messages.associationOrderReturnConfirmSuccess'));
        },
        onSettled: () => {
          closeDialog();
        }
      });
    }
  };

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('resource:dialog.associationOrderformsListTitle')}
      confirmText={t('resource:actions.confirm')}
      cancelText={t('action.cancel')}
      onConfirm={() => handleSubmit(onSubmit)()}
      onCancel={closeDialog}
      dialogSize="large"
      isConfirmLoading={isLoading || isOrderUpdating}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        {orderData?.ranges.length ? <PoolsForm /> : <div>{t('common.loading')}</div>}
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default AssociationOrderReturnConfirmForm;
