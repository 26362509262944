import { QueryConfig, usePaginatedQuery } from 'react-query';
import { PartnerApplicantDetails, PartnerApplicantSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { ApplicationQueryKeysEnum } from '@libs/domain/application';

import { endpointsConfig } from '../endpoints-config';

const getPartnerApplicants = async (_, params: PartnerApplicantSearchFilter) => {
  const { data } = await API.partnerApplicants.getPartnerApplicants(params, {
    ...endpointsConfig.getPartnerApplicants
  });
  return data;
};

export default function usePartnerApplicantsQuery(
  params: PartnerApplicantSearchFilter,
  queryConfig: QueryConfig<IPaginatedModel<PartnerApplicantDetails>>
) {
  return usePaginatedQuery<IPaginatedModel<PartnerApplicantDetails>>(
    [ApplicationQueryKeysEnum.PARTNER_APPLICANTS, params],
    getPartnerApplicants,
    queryConfig
  );
}
