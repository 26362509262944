import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { SubjectDetails } from '@ibtm/domain';

import { Button, TableContext, TableLayout, typedNameV2, useFormV2Context } from '@libs/common/v2';

import { UIElementNameEnum, useElementVisibility } from '@libs/permission';

import { DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import {
  checkIsPartnership,
  FolderDetailsClient,
  useCreateSubjectReputationRequestMutation,
  useReputationsQuery,
  useReputationsTable
} from '@libs/domain/folder';

import ReputationQuerySearchModal from './ReputationQuerySearchModal';

interface IProps {
  subjectData?: SubjectDetails;
  actionKey?: UIElementNameEnum;
}

function ReputationSection({ subjectData, actionKey }: IProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { watch, getValues } = useFormV2Context();

  const [queryDetailsId, setQueryDetailsId] = useState<string>(null);
  const { isOperatorPortal } = useDomainConfigContext();
  const legalFormKey = watch(typedNameV2<FolderDetailsClient>('subject.legalFormKey')) as { value: string };
  const { checkIsElementVisible } = useElementVisibility();

  const { data, isLoading, refetch, isFetching } = useReputationsQuery(subjectData?.id ?? getValues('subject.id'), {
    enabled: actionKey ? checkIsElementVisible(actionKey) : true
  });
  const isPartnership = checkIsPartnership(legalFormKey?.value);
  const tableProps = useReputationsTable(data?.content, isPartnership);

  const { mutate: createReputation, isLoading: isCreateReputationLoading } =
    useCreateSubjectReputationRequestMutation();

  const checkDataInKreptd = async (e: React.MouseEvent<Element, MouseEvent>, refetch: () => void) => {
    await createReputation(subjectData ?? getValues('subject'), {
      onSuccess: () => {
        showSuccessSnackbar(t('folder:details.message.reputationCreated'));
        refetch();
      }
    });

    e.preventDefault();
  };

  return (
    <>
      <TableLayout
        {...tableProps}
        actionKey={actionKey}
        pageTitle={t('folder:details.section.reputation')}
        isFilterEnabled={false}
        refetch={refetch}
        isLoading={isLoading}
        isFetching={isFetching || isCreateReputationLoading}
        headerActions={
          isOperatorPortal && (
            <TableContext.Consumer>
              {({ refetch }) => (
                <Button
                  variant="outlined"
                  isPrimary
                  label={t('folder:details.button.checkDataInKreptd')}
                  actionKey={DomainUIElementEnum.FOLDER_SUBJECT_CHECK_DATA_IN_KREPTD_BUTTON}
                  onClick={e => checkDataInKreptd(e, refetch)}
                />
              )}
            </TableContext.Consumer>
          )
        }
        isCollapsable
        isSection
      />
      {!!queryDetailsId && (
        <ReputationQuerySearchModal id={queryDetailsId} open={!!queryDetailsId} setQueryDetailsId={setQueryDetailsId} />
      )}
    </>
  );
}

export default ReputationSection;
