import { ReactNode, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceItemDetails } from '@ibtm/domain';

import { ITableLayoutColumn, TableLayout, useFormV2Context } from '@libs/common/v2';

import useCorrectionTableItemsColumns from '../../hooks/useCorrectionTableItemsColumns';
import { ICorrectionItems } from '../../model';
import { ChangeReturnModeContext } from '../../pages/ReleaseDocumentsWrapper';

import { useAfterReturnCorrecctionCalculation } from './hooks/useAfterReturnCorrecctionCalculation';

interface IProps {
  correctionItemsTable: ICorrectionItems[];
  setCorrectionItemsTable: (table: ICorrectionItems[]) => void;
  headerActions?: ReactNode;
  isDataLoading?: boolean;
}

/**
 * Tabela dla widoku create i po akcji zwróć
 */
export const AfterReturnCorrectionItemsTable = ({
  correctionItemsTable,
  setCorrectionItemsTable,
  headerActions,
  isDataLoading
}: IProps) => {
  const [t] = useTranslation();
  const { watch } = useFormV2Context();

  const itemsForCorrection = watch('itemsForCorrection') as InvoiceItemDetails[];
  const zeroing = watch('zeroing') as boolean;
  const { items: returnedItems } = useContext(ChangeReturnModeContext);
  const columns = useCorrectionTableItemsColumns({
    isFullAmount: zeroing,
    isCreateMode: false,
    isWZZ: true
  });

  const { calculateItemForCorrectionReturned } = useAfterReturnCorrecctionCalculation();

  useEffect(() => {
    if (itemsForCorrection) {
      const tableItems = calculateItemForCorrectionReturned(itemsForCorrection, returnedItems);

      setCorrectionItemsTable(tableItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculateItemForCorrectionReturned, itemsForCorrection, returnedItems]);

  return (
    <TableLayout<ICorrectionItems, ICorrectionItems>
      tableQuery={correctionItemsTable}
      pageTitle={t('releaseDocuments:create.sections.fields')}
      isSection
      key="after-return-table-items"
      isFilterEnabled={false}
      isPaginated={false}
      isRefreshEnabled={false}
      isLoading={isDataLoading}
      tableHookOptions={{
        columns: columns as ITableLayoutColumn<any, ICorrectionItems>[],
        filterConverter: {},
        sortByConverter: {}
      }}
      headerActions={headerActions}
    />
  );
};
