import { useDialog } from '@libs/common/v2';

import { ViolationStatusChangeForm } from '../../components/form';
import { IViolationClient } from '../../models';

const useViolationStatusChangeDialog = () => {
  const { openDialog } = useDialog();

  const open = (data: IViolationClient) => {
    openDialog(({ closeDialog }) => {
      return <ViolationStatusChangeForm initialData={data} closeDialog={closeDialog} />;
    });
  };

  return {
    openViolationStatusChangeDialog: open
  };
};

export default useViolationStatusChangeDialog;
