import { ReactNode, useMemo } from 'react';
import { makeStyles } from '@mui/styles';

import { TabContent as TabContentCommon, Theme } from '@libs/common/v2';
import { important } from '@libs/common/v2/utils/styles.utils';

import { TabHeader } from '@libs/meta-form/components';
import { useTabContext } from '@libs/meta-form/context';
import { Component, NodeVisibility, UiMode } from '@libs/meta-form/models';
import { Form } from '@libs/meta-form/nodes';

import { useCheckElementVisibility } from '../services';

export type IProps = {
  id: string;
  isForm?: boolean;
  isHeader?: boolean;
  headerVisiblity?: NodeVisibility;
  inputMode?: UiMode;
  validationTypeKey?: string;
};

function TabContent({
  id,
  children,
  isForm = true,
  isHeader = true,
  headerVisiblity,
  inputMode,
  renderNodes,
  validationTypeKey,
  components
}: IProps & {
  renderNodes: (children: ReactNode, components: any) => JSX.Element | JSX.Element[];
  components?: Array<Component>;
  children: ReactNode;
}) {
  const { activeTabId } = useTabContext();
  const { checkVisibilitySync } = useCheckElementVisibility(headerVisiblity);
  const classes = useStyles();

  const header = useMemo(() => {
    if (headerVisiblity) {
      return isHeader && checkVisibilitySync() ? <TabHeader /> : null;
    }

    return isHeader ? <TabHeader /> : null;
  }, [isHeader, headerVisiblity, checkVisibilitySync]);

  return (
    <TabContentCommon isInnerScroll={false} activeTab={activeTabId} className={classes.tabs} value={id}>
      {isForm ? (
        <Form inputMode={inputMode} validationTypeKey={validationTypeKey} components={components}>
          {header}
          {renderNodes(children, components)}
        </Form>
      ) : (
        <>
          {header}
          {renderNodes(children, components)}
        </>
      )}
    </TabContentCommon>
  );
}

const useStyles = makeStyles<Theme>(() => ({
  tabs: {
    overflow: important('hidden')
  }
}));
export default TabContent;
