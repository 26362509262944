import { useMutation } from '@libs/common/v2/api';

import { ReportTypeImportFile } from '@libs/report';
import { API, endpointsConfig } from '@libs/report/api';

const importReportTypeMutation = async (params: ReportTypeImportFile) => {
  const { file } = params;
  const { data } = await API.reportTypes.importById(file.fileId, {
    ...endpointsConfig.importById
  });

  return data;
};

function useImportReportTypeMutation() {
  return useMutation(importReportTypeMutation);
}

export default useImportReportTypeMutation;
