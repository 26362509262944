import { QueryConfig, usePaginatedQuery } from 'react-query';
import { FolderSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { EntrepreneurDetailsSnapshot } from '../../model';
import { parseEntrepreneurDetailsSnapshot } from '../../utils';
import { endpointsConfig } from '../endpoints-config';
import { FolderQueryKeysEnum } from '..';

export const getEntrepreneurs = async (
  _,
  params: FolderSearchFilter
): Promise<IPaginatedModel<EntrepreneurDetailsSnapshot>> => {
  const { data } = await API.folder.getFoldersSnapshotPage(params, {
    ...endpointsConfig.getFoldersSnapshotPage
  });

  return {
    ...data?.content,
    content: parseEntrepreneurDetailsSnapshot(data?.content)
  };
};

export default function useEntrepreneurQuery(
  params: FolderSearchFilter,
  queryConfig: QueryConfig<IPaginatedModel<EntrepreneurDetailsSnapshot>> = {}
) {
  return usePaginatedQuery([FolderQueryKeysEnum.ENTREPRENEURS_LIST, params], getEntrepreneurs, queryConfig);
}
