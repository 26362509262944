import { useContext } from 'react';

import { SystemNotificationContext } from '@libs/common/v2';

function useSystemNotificationContext() {
  const context = useContext(SystemNotificationContext);
  return context;
}

export default useSystemNotificationContext;
