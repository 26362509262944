import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderSnapshot } from '@ibtm/domain';

import { TableHeaderButton } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

import CreateApplicationDialog from './CreateApplicationDialog';

type Props = {
  onSuccess: (applicationId: string) => void;
  selectedFolder?: FolderSnapshot;
  categoryKey?: string;
  actionKey?: UIElementNameEnum;
};

function CreateApplicationTableButton({ onSuccess, selectedFolder, categoryKey, actionKey }: Props) {
  const [t] = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <TableHeaderButton
        variant="contained"
        label={t('applications:create.title')}
        actionKey={actionKey}
        onClick={() => {
          setModalOpen(true);
        }}
      />

      <CreateApplicationDialog
        open={isModalOpen}
        selectedFolder={selectedFolder}
        categoryKey={categoryKey}
        isFilterOnlyByFolderNumber={false}
        onCancel={() => {
          setModalOpen(false);
        }}
        onClose={() => {
          setModalOpen(false);
        }}
        onSuccess={applicationId => {
          setModalOpen(false);
          onSuccess(applicationId);
        }}
      />
    </>
  );
}

export default CreateApplicationTableButton;
