import React, { ReactElement } from 'react';
import { Row } from 'react-table';
import { makeStyles } from '@mui/styles';

import {
  ITableAdapter,
  ITableContext,
  TableButtonInsertColumnLast,
  TableButtonMore,
  TableCancelEditRow,
  TableConfirmEditRow,
  TableEvent,
  useRerenderOnTableEvent,
  useTableAdapter,
  useTableContext
} from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';

interface IProps<T extends Record<string, any>, K extends Record<string, any> = T> {
  row: Row<any>;
  rowActions: (
    params: string | Row<T>,
    table: { tableInstance: ITableAdapter<T, K>; tableContext: ITableContext<T, K> }
  ) => ReactElement;
  /**
   * Funkcja pozwalająca na przewinięcie tabeli do wskazanej kolumny
   * @param columnName Nazwa kolumny
   */
  scrollToColumn?: (columnName: string) => void;
  tableInstance: ReturnType<typeof useTableAdapter>;
  tableContext: ReturnType<typeof useTableContext>;
}

function TableActions<T extends Record<string, any> = Record<string, any>>({
  row,
  rowActions,
  scrollToColumn,
  tableInstance,
  tableContext
}: IProps<T>) {
  const {
    state: { rowId, addedColumnsIds }
  } = tableInstance;
  const classes = useStyles();
  useRerenderOnTableEvent({ event: TableEvent.RERENDER_ROW_ACTIONS });

  // Przyciski akcji dla wiersza w tabeli mutowalnej w będącego edytowanym/tworzonym
  if (rowId === row.original?.id && rowId) {
    return (
      <div className={classes.root}>
        {addedColumnsIds ? (
          <TableButtonMore row={row}>
            {close => (
              <>
                <TableCancelEditRow row={row} isIcon={false} onClick={close} />
                <TableButtonInsertColumnLast row={row} rowId={row.original.id} isIcon={false} onClick={close} />
              </>
            )}
          </TableButtonMore>
        ) : (
          <TableCancelEditRow row={row} />
        )}
        <TableConfirmEditRow row={row} scrollToColumn={scrollToColumn} />
      </div>
    );
  }
  // Jeżeli kolumna akcji jest przekazana jako komponent
  if (!Array.isArray(rowActions?.(row, { tableInstance, tableContext })?.props?.children)) {
    return <div className={classes.componentRoot}>{rowActions?.(row, { tableInstance, tableContext })}</div>;
  }
  let [firstButton, secondButton, ...restButtons] =
    rowActions?.(row, { tableInstance, tableContext })?.props?.children ?? [];

  if (firstButton?.props?.action && !firstButton?.props?.action?.icon) {
    if (!restButtons) {
      restButtons = [];
    }
    if (secondButton) {
      restButtons.unshift(secondButton);
    }
    secondButton = firstButton;
    firstButton = null;
  }

  if (!firstButton && !secondButton) {
    return <div className={classes.root}>{rowActions?.(row, { tableInstance, tableContext })}</div>;
  }
  const hasRestButtons = !!restButtons?.filter(item => item)?.length;
  return (
    <div className={classes.root}>
      {(hasRestButtons || (secondButton?.props?.action?.label && !secondButton?.props?.action?.icon)) && (
        <TableButtonMore
          isLoading={restButtons?.some(button => button.props.action.isLoading) || secondButton.props.action.isLoading}
        >
          {close => (
            <>
              {!secondButton?.props?.action?.icon && {
                ...secondButton,
                props: {
                  ...secondButton?.props,
                  action: {
                    ...secondButton?.props?.action,
                    onClick: (...parameters) => {
                      secondButton?.props?.action?.onClick?.(...parameters);
                      close();
                    }
                  }
                }
              }}
              {restButtons?.map(button => ({
                ...button,
                props: {
                  ...button?.props,
                  action: {
                    ...button?.props?.action,
                    onClick: (...parameters) => {
                      button?.props?.action?.onClick?.(...parameters);
                      close();
                    }
                  }
                }
              }))}
            </>
          )}
        </TableButtonMore>
      )}
      {!hasRestButtons && (!secondButton?.props?.action?.label || secondButton?.props?.action?.icon) && secondButton}
      {firstButton && secondButton && firstButton}
      {firstButton && !secondButton && <div className={classes.singleAction}>{firstButton}</div>}
    </div>
  );
}

const useStyles = makeStyles<Theme>(() => ({
  root: {
    width: '100%',
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 16,
    paddingLeft: 16,
    gap: 4
  },
  componentRoot: {
    width: '100%',
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 16,
    paddingLeft: 16,
    gap: 4
  },
  singleAction: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

export default React.memo(TableActions);
