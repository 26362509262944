import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import moment from 'moment';

import { i18n } from '@libs/common';
import { Theme } from '@libs/common/v2/theme';
import { getFormattedDateValue } from '@libs/common/v2/utils';

import { TransportManagerListItemClient } from '@libs/domain/transport-manager';

function PeriodOfValidityField({ certificate }: { certificate: TransportManagerListItemClient['certificate'] }) {
  const { validFrom, validTo, number } = certificate;
  const [t] = useTranslation();
  const classes = useStyles();

  const rangeContent = useMemo(() => {
    return `${i18n.t('from').toLowerCase()} ${getFormattedDateValue(validFrom)} ${i18n
      .t('to')
      .toLowerCase()} ${getFormattedDateValue(validTo)}`;
  }, [validFrom, validTo]);

  return moment().isAfter(moment(validTo).add(1, 'day')) ? (
    <Tooltip title={t('transportManager:details.message.transportManagerIsOutOfDate', { certificateNumber: number })}>
      <div className="flex items-center">
        {rangeContent}
        <span className={clsx('material-icons', classes.highPriorityIcon)}>priority_high</span>
      </div>
    </Tooltip>
  ) : (
    <>{rangeContent}</>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  highPriorityIcon: {
    color: theme.palette.error[500],
    cursor: 'pointer'
  }
}));

export default PeriodOfValidityField;
