import { useEffect, useMemo, useState } from 'react';
import { ReportDetailsLite } from '@stack/report';

import {
  ErrorSection,
  REFETCH_INTERVAL_MS,
  ReportDetailsGeneralSection,
  ReportDetailsParametersSection
} from '@libs/report';
import { useReportDetailsQuery } from '@libs/report/api';
import { isReportInProgress } from '@libs/report/utils/reports.util';

interface IProps {
  generatedReport: ReportDetailsLite;
}

export function GeneratedReportDetails({ generatedReport }: IProps) {
  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);
  const { data, isLoading, isFetching } = useReportDetailsQuery(
    { reportId: generatedReport?.id },
    {
      enabled: !!generatedReport?.id,
      refetchInterval
    }
  );

  useEffect(() => {
    const shouldRefetch = data && isReportInProgress(data);
    setRefetchInterval(shouldRefetch ? REFETCH_INTERVAL_MS : false);
  }, [data]);

  const isErrorDownloading = useMemo(() => {
    return isLoading || isReportInProgress(data);
  }, [data, isLoading]);

  return (
    <>
      <ReportDetailsGeneralSection report={data} isLoading={isLoading} isFetching={isFetching} />
      <ReportDetailsParametersSection report={data} isLoading={isLoading} isFetching={isFetching} />
      <ErrorSection report={data} isLoading={isErrorDownloading} isFetching={isFetching} />
    </>
  );
}
