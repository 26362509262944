import { useSelector } from 'react-redux';

import { ValidatorDetails, ValidatorEnums } from '@libs/common/v2/form';

const useGetContextValidators = (): { [key in ValidatorEnums]?: ValidatorDetails } => {
  return useSelector(({ context }: { context: { context } }) => {
    const validators = context?.context?.data?.validators || {};
    return validators;
  });
};

export default useGetContextValidators;
