import { ColumnTypesEnum, ISingleColumn, useCreateColumns } from '@libs/common/v2';

import { InsurancePolicySnapshot } from '@libs/domain/folder/model';

function useInsurancePoliciesColumns() {
  const { createColumns } = useCreateColumns<InsurancePolicySnapshot>({
    pathToTranslate: 'folder:details.tab.insurancePolicies.field',
    isMutableTable: true
  });

  const additionalColumnsDefinitions = { isCreatable: true, isEditable: true, width: 200 };

  const columns: ISingleColumn<InsurancePolicySnapshot>[] = [
    {
      id: 'grantDate',
      type: ColumnTypesEnum.DATE,
      ...additionalColumnsDefinitions
    },
    {
      id: 'name',
      type: ColumnTypesEnum.TEXT,
      ...additionalColumnsDefinitions
    },
    {
      id: 'value',
      type: ColumnTypesEnum.NUMBER,
      ...additionalColumnsDefinitions
    },
    {
      id: 'validFrom',
      type: ColumnTypesEnum.DATE,
      ...additionalColumnsDefinitions
    },
    {
      id: 'validTo',
      type: ColumnTypesEnum.DATE,
      ...additionalColumnsDefinitions
    }
  ];

  const mutableColumns = createColumns(columns);

  return mutableColumns;
}

export default useInsurancePoliciesColumns;
