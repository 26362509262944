import { MutationConfig } from 'react-query';
import { DocumentReceiptAckDateUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface IMutation extends DocumentReceiptAckDateUpdateRequest {
  documentId: string;
}

const changeReceiptDate = async (params: IMutation) => {
  const { documentId, receiptAckDate, version } = params;
  const { data } = await API.documents.updateDocumentReceiptAckDate(
    documentId,
    {
      receiptAckDate,
      version
    },
    { ...endpointsConfig.updateDocumentReceiptAckDate }
  );

  return data;
};

export default function useDocumentIssuedReceiptDateMutation(
  config?: MutationConfig<any, unknown, IMutation, unknown>
) {
  return useMutation(changeReceiptDate, config);
}
