import { AxiosRequestConfig } from 'axios';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '@libs/notification/api';

function deleteMessages(notificationsIds: Array<string>) {
  return API.message.deleteNotifications({ body: { notificationsIds } }, {
    ...endpointsConfig.deleteNotifications
  } as AxiosRequestConfig);
}

function useDeleteMessagesMutation() {
  return useMutation(deleteMessages);
}

export default useDeleteMessagesMutation;
