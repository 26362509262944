import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import { Dialog, DropdownListButton, TableButton } from '@libs/common/v2';

import useAnonymizeMutation from '@libs/user/api/mutations/useAnonymizeMutation';
import { AnonymizeType } from '@libs/user/models';

interface IProps {
  handleClose: () => void;
  accountId: string;
  refetchUserDetails: () => void;
  isTableButton?: boolean;
  actionKey?: UIElementNameEnum;
}

function AnonymizeUser({ handleClose, accountId, refetchUserDetails, isTableButton = true, actionKey }: IProps) {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { mutate: anonymizeUser, isLoading } = useAnonymizeMutation(refetchUserDetails, handleClose, setIsOpen);

  const onSubmit = accountId => {
    anonymizeUser({ accountId, type: AnonymizeType.IRREVERSIBLE });
  };

  return (
    <>
      {isTableButton ? (
        <TableButton label={t('user:action.anonymize')} onClick={() => setIsOpen(!isOpen)} actionKey={actionKey} />
      ) : (
        <DropdownListButton
          label={t('user:action.anonymize')}
          onClick={() => setIsOpen(!isOpen)}
          actionKey={actionKey}
        />
      )}
      <Dialog
        title={t('user:dialog.anonymize.title')}
        shortTitle={t('user:dialog.anonymize.shortTitle')}
        confirmText={t('action.confirm')}
        cancelText={t('action.close')}
        contentText={t('user:dialog.anonymize.content')}
        onConfirm={() => onSubmit(accountId)}
        onCancel={() => setIsOpen(false)}
        confirmButtonType="danger"
        dialogSize="small"
        isConfirmLoading={isLoading}
        isOpen={isOpen}
      />
    </>
  );
}
export default AnonymizeUser;
