import { ResourceAssignmentSCertificateUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export const updateResourceAssignmentSCertificate = async (formData: ResourceAssignmentSCertificateUpdateRequest) => {
  const { data } = await API.printouts.updateResourceAssignmentSCertificate(formData, {
    ...endpointsConfig.updateResourceAssignmentSCertificate
  });

  return data;
};

export default function useUpdateSCertificateMutation() {
  return useMutation(updateResourceAssignmentSCertificate);
}
