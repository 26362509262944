import { ProceedingKreptdCertificateDetails } from '@ibtm/domain';

import { ColumnTypesEnum, useCreateColumns } from '@libs/common/v2';

const useCertificatesColumns = () => {
  const { createColumns } = useCreateColumns<ProceedingKreptdCertificateDetails>({
    pathToTranslate: 'roadTransport:fields'
  });

  const defaultColumnsSettings = {
    isSortable: false,
    type: ColumnTypesEnum.DATE
  };
  return createColumns([
    {
      id: 'number',
      header: 'certificateNumber',
      accessor: 'number',
      type: ColumnTypesEnum.TEXT,
      width: 200,
      isEditable: false,
      isSortable: false
    },
    {
      id: 'returnDate',
      header: 'returnDate',
      ...defaultColumnsSettings
    },
    {
      id: 'returnDateNotificationDateOfIts',
      header: 'returnDateNotificationDateOfIts',
      ...defaultColumnsSettings
    },
    {
      id: 'sendingDate',
      header: 'sendingDate',
      ...defaultColumnsSettings
    },
    {
      id: 'sendDateNotificationDateOfIts',
      header: 'sendDateNotificationDateOfIts',
      ...defaultColumnsSettings
    },
    {
      id: 'caseNumber95a',
      header: 'caseNumber95a',
      isSortable: false,
      type: ColumnTypesEnum.TEXT,
      width: 150
    }
  ]);
};

export default useCertificatesColumns;
