import { useMemo } from 'react';
import { makeStyles } from '@mui/styles';

import { ColumnTypesEnum, useCreateColumns } from '@libs/common/v2';

import { ICorrectionItems } from '../model';

interface Props {
  isFullAmount: boolean;
  isCreateMode: boolean;
  isWZZ: boolean;
  isSuspension?: boolean;
}
export default function useCorrectionTableItemsColumns({ isCreateMode, isFullAmount, isWZZ, isSuspension }: Props) {
  const classes = useStyles();

  const { createColumns } = useCreateColumns<ICorrectionItems>({
    pathToTranslate: 'releaseDocuments:fields.fields',
    isMutableTable: isCreateMode
  });

  const defaultConfigColumn = useMemo(
    () => ({
      isFilterable: false,
      isSortable: false,
      type: ColumnTypesEnum.NUMBER,
      mutableTableInputProps: {
        valueClassName: classes.alignRight
      },
      isCellAlignRight: true
    }),
    [classes.alignRight]
  );

  return createColumns([
    {
      id: 'name',
      header: 'permissionName',
      type: ColumnTypesEnum.TEXT,
      isFilterable: false,
      isSortable: false,
      width: 400
    },
    {
      id: 'quantity',
      header: 'issuedPermissionsNumber',
      ...defaultConfigColumn,
      width: 80
    },
    {
      id: 'correctedQuantity',
      header: 'issuedPermissionsNumberAfterCorrection',
      ...defaultConfigColumn,
      isEditable: isCreateMode && !(isSuspension && isFullAmount),
      isCreatable: false,
      editType: ColumnTypesEnum.NUMBER,
      mutableTableInputProps: {
        isOnlyPositiveIntegers: true,
        ...defaultConfigColumn.mutableTableInputProps
      },
      width: 150
    },
    {
      id: 'unitCost',
      header: 'oldValue',
      ...defaultConfigColumn,
      width: 80
    },
    {
      id: 'correctedUnitCost',
      header: 'newValue',
      ...defaultConfigColumn,
      isEditable: isFullAmount && isCreateMode && !isWZZ,
      width: 120
    },
    {
      id: 'sumDiffrence',
      header: 'sumDiffrence',
      ...defaultConfigColumn,
      width: 80
    },
    isSuspension
      ? {
          id: 'sumOfCorrection',
          header: 'sumOfCorrection',
          ...defaultConfigColumn,
          width: 80
        }
      : {
          id: 'newSum',
          header: 'newSum',
          ...defaultConfigColumn,
          width: 80
        }
  ]);
}

const useStyles = makeStyles(() => ({
  alignRight: {
    width: '100%',
    textAlign: 'right'
  }
}));
