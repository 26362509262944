import { usePaginatedQuery } from 'react-query';
import { ResourceObjectDisposalSearchFilter } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { IDisposalClient } from '../../../model';
import { parseDisposalsList } from '../../../utils';
import { endpointsConfig } from '../../endpoints-config';
import { ResourceQueryKeysEnum } from '..';

const getDisposals = async (params: ResourceObjectDisposalSearchFilter) => {
  const { data } = await API.resourceObjects.getResourceObjectDisposals(params, {
    ...endpointsConfig.getResourceObjectDisposals
  });

  return {
    ...data,
    content: parseDisposalsList(data.content)
  };
};

export default function useDisposalsQuery(params: ResourceObjectDisposalSearchFilter) {
  return usePaginatedQuery<{ content: IDisposalClient[] }>([ResourceQueryKeysEnum.DISPOSALS_LIST, params], () =>
    getDisposals(params)
  );
}
