import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PullNotificationSnapshot } from '@avispon/message/dist/models';

import { Dialog, GridLayout, Value } from '@libs/common/v2';
import { convertDateToDateTimeFormat } from '@libs/common/v2/utils';

import { useDownloadFileFromNotification } from '../../hooks';

interface MessageModalProps {
  notification: PullNotificationSnapshot;
  onClosed?: () => void;
}

const MessageModal = forwardRef(({ notification, onClosed }: MessageModalProps, ref: React.Ref<HTMLDivElement>) => {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    onClosed();
  };

  const { downloadFileButton, hasFileToDownload } = useDownloadFileFromNotification({
    links: notification.links
  });

  return (
    <div ref={ref}>
      <Dialog
        title={notification.subject || t('messages:dialog.messageDetailsTitle')}
        confirmText={notification.blocking ? t('action.read') : t('action.close')}
        onConfirm={handleClose}
        isCancelButtonVisible={false}
        onClose={handleClose}
        onCancel={handleClose}
        dialogSize="small"
        isCloseIconEnabled={false}
        isBackdropClickEnabled={false}
        isOpen={isOpen}
      >
        <GridLayout itemProps={{ xs: 12 }}>
          <Value label={t('messages:field.created')} value={convertDateToDateTimeFormat(notification.created)} />
          <Value
            label={t('messages:field.message')}
            value={
              <>
                {notification.message}
                {hasFileToDownload && downloadFileButton}
              </>
            }
          />
        </GridLayout>
      </Dialog>
    </div>
  );
});

export default MessageModal;
