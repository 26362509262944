import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProxySnapshot } from '@ibtm/client-domain/dist/models/proxy-snapshot';
import { debounce, get } from 'lodash';

import { AutocompleteSelectField, SEARCH_TEXT_DEBOUNCE_TIME, SelectOption, useFormV2Context } from '@libs/common/v2';

import { useMetaFormContext } from '@libs/meta-form/context';
import { ApiRegistryCreateFunction, AutocompleteFieldProperties, FieldProps, FieldTypes } from '@libs/meta-form/models';
import { ApiFetchBackendFilterParams } from '@libs/meta-form/models/meta-form-api-definition.model';
import { useGetQuery, useMapAdditionalFieldsValidationType } from '@libs/meta-form/services';

type SelectDataOfApplicantOption = SelectOption & ProxySnapshot;

export function SelectDataOfApplicant({
  fieldId,
  label,
  api,
  multiple,
  inputMode,
  defaultInitialValue,
  required,
  additionalFieldsValidationType,
  ...rest
}: FieldProps<AutocompleteFieldProperties>) {
  const [t] = useTranslation();
  const { setValue, getValues } = useFormV2Context();
  const [options, setOptions] = useState([]);
  const [params, setParams] = useState<{ backendParams?: ApiFetchBackendFilterParams<ApiRegistryCreateFunction> }>(
    api?.fetch?.backendParams || {}
  );
  const { apiRegistry } = useMetaFormContext();

  useMapAdditionalFieldsValidationType(fieldId, {
    type: additionalFieldsValidationType ?? FieldTypes.AUTOCOMPLETE,
    multiple
  });

  const prepareText = (data: SelectDataOfApplicantOption) => {
    return `${data.name} ${data.surname}`;
  };

  const getOption = useCallback(
    (option: SelectDataOfApplicantOption) =>
      option?.id
        ? {
            id: option.id,
            value: {
              name: prepareText(option),
              ...option
            },
            name: prepareText(option)
          }
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useGetQuery({
    requestKey: api?.get?.requestKey,
    accessor: api?.get?.accessor,
    apiRegistry,
    setValue: (newValue: SelectDataOfApplicantOption[] | SelectDataOfApplicantOption) => {
      const isArray = (arg: SelectOption[] | SelectOption): arg is SelectOption[] => {
        return multiple || Array.isArray(arg);
      };
      if (!newValue && defaultInitialValue) {
        return;
      }

      const preparedValue = isArray(newValue) ? newValue?.map(getOption) : getOption(newValue);

      setValue(fieldId, preparedValue);
    },
    fieldId
  });

  const { isLoading } = useGetQuery({
    requestKey: api.fetch.requestKey,
    accessor: api.fetch.accessor ?? null,
    apiRegistry,
    params,
    setValue: (data: { content?: SelectDataOfApplicantOption[] } | SelectDataOfApplicantOption[]) => {
      const setInitialValue = (newValue: Array<SelectOption>) => {
        let initValue: SelectOption[] | SelectOption;
        if (getValues(fieldId) || !defaultInitialValue || !Array.isArray(newValue)) {
          return;
        }
        const getOptionValue = (item: SelectOption) => get(item, defaultInitialValue.accessor, undefined);
        if ('values' in defaultInitialValue) {
          initValue = newValue.filter(item => {
            return defaultInitialValue.values.includes(getOptionValue(item));
          });
        } else if ('value' in defaultInitialValue) {
          initValue = newValue.find(item => {
            return defaultInitialValue.value === getOptionValue(item);
          });
        }

        setValue(fieldId, initValue);
      };
      if ('content' in data && data?.content) {
        const newOptionsFromContent = data?.content.map(getOption);
        setOptions(newOptionsFromContent);
        setInitialValue(newOptionsFromContent);
      } else if (Array.isArray(data)) {
        const newOptions = data?.map(getOption);
        setOptions(newOptions);
        setInitialValue(newOptions);
      } else {
        setOptions([]);
      }
    }
  });

  const changeHandler = (data: { target?: { value?: string } } & unknown) => {
    if (api.fetch?.backendFilterParamName) {
      setParams(prevState => ({
        ...prevState,
        [api.fetch.backendFilterParamName]: data?.target?.value
      }));
    }
  };

  const onInputChange = debounce(data => changeHandler(data), SEARCH_TEXT_DEBOUNCE_TIME);

  return (
    <AutocompleteSelectField
      name={fieldId}
      label={label}
      isRequired={required}
      isMultiple={multiple}
      options={options}
      inputMode={inputMode}
      onInputChange={onInputChange}
      isLoading={isLoading}
      {...(options.length === 0 && { tooltip: t('applications:tooltip.dataOfApplicantDisabled'), isDisabled: true })}
      {...rest}
    />
  );
}
