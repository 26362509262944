import { useParams } from 'react-router-dom';

import { useFormV2Context } from '@libs/common/v2';

import { ClientForeignPermissionsTable } from '@libs/domain/foreign-permission';

import { DomainDictionaryEntry } from '../../../../../config';
import { FolderDetailsParams } from '../../../../model';

function ForeignPermissionsTab() {
  const { folderId } = useParams<FolderDetailsParams>();
  const { watch } = useFormV2Context();

  const associationFolderId = watch('association.folderAssociationId');
  const typeKey = watch('typeKey');

  const isZZFolder = (typeKey?.value || typeKey) === DomainDictionaryEntry.FOLDER_TYPE.ZZ;

  return (
    <div>
      <ClientForeignPermissionsTable
        folderId={folderId}
        associationFolderId={associationFolderId}
        isZZFolder={isZZFolder}
        isActionColumnEnabled={false}
        isSection
        isCollapsable
      />
    </div>
  );
}

export default ForeignPermissionsTab;
