import { useMutation } from 'react-query';
import { SubjectContactAddressUpdateRequest as SubjectContactAddressUpdateRequestClient } from '@ibtm/client-domain';
import { SubjectContactAddressUpdateRequest } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function updateSubjectContactData({
  subjectId,
  formData
}: {
  subjectId: string;
  formData: SubjectContactAddressUpdateRequest;
}) {
  return API.subject.updateSubjectContactAddresses(subjectId, formData, {
    ...endpointsConfig.updateSubject
  });
}

function updateSubjectContactDataClient({
  subjectId,
  formData
}: {
  subjectId: string;
  formData: SubjectContactAddressUpdateRequestClient;
}) {
  return API.client.subject.updateSubjectContactAddresses(subjectId, '', '', formData, {
    ...endpointsConfig.updateSubject
  });
}

function useUpdateSubjectContactDataMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(updateSubjectContactDataClient, updateSubjectContactData));
}

export default useUpdateSubjectContactDataMutation;
