import { PluginHook, SortingRule } from 'react-table';

import { useTableLayoutProps } from '@libs/common/v2';

import useDriverCertificatesExtendedQuery from '../../api/queries/useDriverCertificatesExtendedQuery';
import { DriverCertificatesColumnsEnum, DriverCertificateSnapshotClient } from '../../model';
import useDriverCertificatesExtendedColumns from '../columns/useDriverCertificatesExtendedColumns';

const useDriverCertificatesExtendedTable = (
  applicationId?: string,
  folderId?: string,
  defaultFilters = [],
  tablePlugins?: Array<PluginHook<DriverCertificateSnapshotClient>>,
  editableColumns?: DriverCertificatesColumnsEnum[],
  defaultSort: SortingRule<DriverCertificateSnapshotClient>[] = []
) => {
  const { columns, mappedFilterFields, mappedSortFields } = useDriverCertificatesExtendedColumns(editableColumns);

  return useTableLayoutProps({
    tableHookQuery: useDriverCertificatesExtendedQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tablePlugins,
      tableOptions: {
        initialState: {
          filters: defaultFilters,
          sortBy: defaultSort
        }
      }
    },
    tableHookQueryInitialParams: {
      applicationIdIn: applicationId ? [applicationId] : undefined,
      folderIdIn: folderId ? [folderId] : undefined
    }
  });
};

export default useDriverCertificatesExtendedTable;
