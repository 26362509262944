import { PaginatedQueryConfig, usePaginatedQuery } from 'react-query';
import { AlertApiGetAlertsRequest, AlertSnapshotExtended } from '@stack/report';

import { IPaginatedModel } from '@libs/common/v2';

import { AlertQueryKeysEnum, API, endpointsConfig } from '@libs/alert/api';

const getAlerts = async (_, params: AlertApiGetAlertsRequest) => {
  const {
    page,
    size,
    sort,
    alertIds,
    authorId,
    createdFrom,
    createdTo,
    descriptionFragment,
    modifiedFrom,
    modifiedTo,
    nameFragment,
    statusKeys,
    sourceNameFragment
  } = params;

  const { data } = await API.alert.getAlerts(
    page,
    size,
    sort,
    alertIds,
    authorId,
    createdFrom,
    createdTo,
    descriptionFragment,
    modifiedFrom,
    modifiedTo,
    nameFragment,
    statusKeys,
    sourceNameFragment,
    { ...endpointsConfig.getAlerts }
  );

  return data;
};

export default function useAlertsQuery(
  params: AlertApiGetAlertsRequest,
  options: PaginatedQueryConfig<IPaginatedModel<AlertSnapshotExtended>, unknown>
) {
  return usePaginatedQuery<IPaginatedModel<AlertSnapshotExtended>>(
    [AlertQueryKeysEnum.ALERTS_LIST, params],
    getAlerts,
    options
  );
}
