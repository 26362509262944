import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useViewModesV2 } from '@libs/common/v2/form';

import { FilesTable } from '@libs/file';
import { UIElementNameEnum } from '@libs/permission';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';

import { IApplicationDetailsPageParams } from '../../model';

interface IProps {
  tableActionKeys?: Array<UIElementNameEnum>;
  actionKey?: UIElementNameEnum;
}

function ApplicationFilesList({ tableActionKeys, actionKey }: IProps) {
  const [t] = useTranslation();
  const { viewMode } = useViewModesV2();
  const { applicationId } = useParams<IApplicationDetailsPageParams>();
  const { isClientPortal } = useDomainConfigContext();

  const { getQuery } = useGetApiQueryByPerspective();

  const getFilesQuery = getQuery(
    params => API.client.applicationAttachments.getApplicationAttachmentsPage('', '', applicationId, params),
    params => API.applicationAttachments.getApplicationAttachmentsPage(applicationId, params)
  );

  const createQuery = getQuery(
    fileId =>
      API.client.applicationAttachments.addAttachment('', '', applicationId, {
        fileId
      }),
    fileId => API.applicationAttachments.addAttachment(applicationId, { fileId })
  );

  const deleteFileQuery = getQuery(
    fileId => API.client.applicationAttachments.deleteAttachment(applicationId, fileId),
    fileId => API.applicationAttachments.deleteAttachment(applicationId, fileId)
  );

  return (
    <FilesTable
      title={t('applications:list.attachmentsListTitle')}
      api={{
        GET: params => getFilesQuery(params),
        CREATE: ({ fileId }) => createQuery(fileId),
        DELETE: ({ recordId }) => deleteFileQuery(recordId)
      }}
      hideHeaderActions={viewMode}
      showFileWarning={isClientPortal}
      tableActionKeys={tableActionKeys}
      actionKey={actionKey}
      isSignFileEnabled={isClientPortal}
      rowActionIds={{
        download: DomainUIElementEnum.APPLICATION_DOWNLOAD_FILE_BUTTON,
        sign: DomainUIElementEnum.APPLICATION_SIGN_FILE_BUTTON,
        delete: DomainUIElementEnum.APPLICATION_DELETE_FILE_BUTTON
      }}
      isRefreshEnabled
      isCollapsable
      isSection
    />
  );
}

export default ApplicationFilesList;
