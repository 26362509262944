import { AccountCreateCommand } from '@avispon/user/dist/models';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '..';

const createUser = async (body: AccountCreateCommand) => {
  const { data } = await API.user.default.createClientByOperator(
    { body },
    { ...endpointsConfig.createClientByOperator }
  );
  return data;
};

export default function useCreateUserMutation() {
  return useMutation(createUser);
}
