import { FieldPath, FieldValues } from 'react-hook-form';

export type GlobalYearSelectType = { name: string; value: string };

export enum AdditionalFilterEnum {
  YEAR_SELECT = 'YEAR_SELECT',
  TEXT_FILTER = 'TEXT_FILTER',
  DATEPICKER = 'DATEPICKER',
  SELECT = 'SELECT',
  FETCH_SELECT = 'FETCH_SELECT',
  BOOLEAN_TOGGLE = 'BOOLEAN_TOGGLE',
  DICTIONARY_SELECT = 'DICTIONARY_SELECT',
  CUSTOM_FILTER = 'CUSTOM_FILTER'
}
/**
 * @name - żeby wyświetlić wartość początkową, wartość 'name' musi być
 * o wartości przekazanej w globalFilterYupResolverSchema.
 */
export interface IAdditionalFilter {
  type: AdditionalFilterEnum;
  name: FieldPath<FieldValues>;
  inputProps?: Record<string, unknown>;
  customFilter?: any;
  setGlobalYearSelect?: (selectedYear: GlobalYearSelectType) => void;
}
