import { DefaultApiUpdateOrganizationUnitParentUnitRequest } from '@avispon/organization-structure';

import { useMutation, useQueryCache } from '@libs/common/v2/api';

import { API, endpointsConfig, OrganizationQueryKeysEnum } from '..';

const editOrganizationUnitParentUnit = async (params: DefaultApiUpdateOrganizationUnitParentUnitRequest) => {
  const { data } = await API.organizationStructure.updateOrganizationUnitParentUnit(params, {
    ...endpointsConfig.updateOrganizationUnitParentUnit
  });
  return data;
};

export default function useEditOrganizationUnitParentUnit({ onSuccess, onError }) {
  const queryCache = useQueryCache();
  return useMutation(editOrganizationUnitParentUnit, {
    onSuccess: (...args) => {
      queryCache.invalidateQueries(OrganizationQueryKeysEnum.ORGANIZATION_STRUCTURE);
      onSuccess(...args);
    },
    onError
  });
}
