import { DocumentSnapshot } from '@ibtm/domain';

import { convertCalendarDate } from '@libs/common/v2/utils';

import { DocumentsSnapshotClient } from '../model';

export const parseDocumentsList = (documents: DocumentSnapshot[]): DocumentsSnapshotClient[] =>
  documents.map(document => ({
    id: document.id,
    generatedDocumentName: document.typeKey, // Mapowanie zgodnie z GITDDEV-9705
    generatedFileName: document.generatedFileName, // Mapowanie zgodnie z GITDDEV-9705
    generatedBy: document.author?.name,
    generatedDate: document.created,
    documentFileId: document.file.id,
    documentName: document.generatedFileName,
    addedBy: document.insertedBy?.name,
    typeKey: document.typeKey,
    inputDate: document.inputDate,
    statusKey: document.statusKey,
    passedToSentDate: convertCalendarDate(document.parcel?.passedToSentDate),
    packageNumber: document.parcel?.number,
    sendDate: convertCalendarDate(document.parcel?.sendDate),
    receiptAckDate: convertCalendarDate(document.parcel?.receiptAckDate),
    cancellationDate: convertCalendarDate(document.cancellationDate),
    cancelledBy: document.cancelledBy?.name,
    subject: {
      id: document.subject?.id,
      name: document.subject?.name
    },
    address: {
      id: document.address?.id,
      version: document.address?.version
    },
    parcel: {
      number: document.parcel?.number,
      passedToSentDate: convertCalendarDate(document.parcel?.passedToSentDate),
      receiptAckDate: convertCalendarDate(document.parcel?.receiptAckDate),
      sendDate: convertCalendarDate(document.parcel?.sendDate)
    },
    version: document.version,
    isFinalDecision: document.isFinalDecision,
    isPermission: document.isPermission,
    uploadedFileName: document.uploadedFileName
  }));
