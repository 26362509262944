import { useMemo } from 'react';
import { useRowSelect } from 'react-table';
import { ProceedingAvailableFormsDetails, ProceedingFormDetails } from '@ibtm/domain';

import { CamelCasePath, useMultipleSelectColumn, useTableLayoutProps } from '@libs/common/v2';

import { ProceedingTypeKeyEnum, useFormOperationsQuery } from '@libs/domain/proceeding';

import { selectionActionRenderFormOperation } from '../components/proceeding-details/tabs/formOperations/list/FormOperationTableSelectAction';

import { useFormOperationColumns } from './useFormOperationColumns';

function useFormOperationsTable(
  proceedingId: string,
  proceedingTypeKey: string,
  isFormModal: boolean,
  modalTableData: ProceedingAvailableFormsDetails[]
) {
  const isAdministrativeProceeding = useMemo(() => {
    return proceedingTypeKey === ProceedingTypeKeyEnum.ADMINISTRATION;
  }, [proceedingTypeKey]);

  const visibleColumns: Array<CamelCasePath<ProceedingFormDetails>> = isFormModal
    ? ['permissionTypeCodeKey', 'formNumber', 'validTo', 'statusKey']
    : ['permissionTypeCodeKey', 'formNumber', 'reasonKey', 'validTo', 'statusKey'];

  const { columns, mappedFilterFields } = useFormOperationColumns(
    isAdministrativeProceeding ? [...visibleColumns, 'cancelDate', 'suspendedFrom', 'suspendedTo'] : visibleColumns
  );

  const multipleSelectPlugin = useMultipleSelectColumn(selectionActionRenderFormOperation);

  return useTableLayoutProps({
    ...(modalTableData ? { tableQuery: modalTableData } : { tableHookQuery: useFormOperationsQuery }),
    tableHookOptions: {
      columns,
      tablePlugins: [useRowSelect, ...(isAdministrativeProceeding ? [multipleSelectPlugin] : [])],
      filterConverter: mappedFilterFields
    },
    tableHookQueryInitialParams: {
      proceedingId
    }
  });
}

export default useFormOperationsTable;
