import { MutationConfig } from 'react-query';
import { DocumentsReleaseRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

async function sendShipmentsNumbers(request: DocumentsReleaseRequest) {
  return API.documents.releaseDocuments(request, {
    ...endpointsConfig.releaseDocuments
  });
}

function useSendShipmentsNumbersMutation(config?: MutationConfig<any>) {
  return useMutation(sendShipmentsNumbers, config);
}

export default useSendShipmentsNumbersMutation;
