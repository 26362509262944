import { VehicleSnapshot } from '@ibtm/domain';

import { CamelCasePath } from '@libs/common/v2';

import { VehicleColumnNameEnum } from '../model';

export const VEHICLE_DEFAULT_VISIBLE_COLUMNS: Array<CamelCasePath<VehicleSnapshot>> = [
  VehicleColumnNameEnum.VIN,
  VehicleColumnNameEnum.PLATENUMBER,
  VehicleColumnNameEnum.BRANDKEY,
  VehicleColumnNameEnum.MANUFACTURE_YEAR,
  VehicleColumnNameEnum.TYPEKEY,
  VehicleColumnNameEnum.ACCEPTABLE_CAPACITY,
  VehicleColumnNameEnum.DMC,
  VehicleColumnNameEnum.EUROCLASS_KEY,
  VehicleColumnNameEnum.BELOW_THREE_AND_HALF_TONS,
  VehicleColumnNameEnum.VEHICLE_SUBMITTED_TO,
  VehicleColumnNameEnum.CERTIFICATE_S_EXPIRATION_DATE
];

export const VEHICLE_EXTENDED_VISIBLE_COLUMNS: Array<CamelCasePath<VehicleSnapshot>> = [
  ...VEHICLE_DEFAULT_VISIBLE_COLUMNS,
  VehicleColumnNameEnum.REGISTRATION_COUNTRY,
  VehicleColumnNameEnum.IS_IN_COMPANY,
  VehicleColumnNameEnum.CREW_SEATS,
  VehicleColumnNameEnum.MODEL_KEY
];
