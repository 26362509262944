import { useMemo } from 'react';
import { useForm, UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryCache } from 'react-query';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ForeignPermissionApplicationUpdateDetails as ForeignPermissionApplicationUpdateDetailsClient } from '@ibtm/client-domain/dist/models/foreign-permission-application-update-details';
import {
  ApplicationDetails,
  ForeignPermissionApplicationRecordWithLimitDetails,
  ForeignPermissionApplicationUpdateDetails,
  SubjectAddressDetails
} from '@ibtm/domain';
import { number as YupNumber, object as YupObject, string as YupString } from 'yup';

import {
  AutocompleteSelectField,
  CamelCasePath,
  Dialog,
  FormMode,
  FormV2Context,
  GridLayout,
  NumberInputField,
  SelectOption,
  TextInputField,
  typedNameV2,
  useIsSmallScreen,
  useViewModesV2
} from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';

import {
  ApplicationQueryKeysEnum,
  useAddForeignPermissionApplicationRecordsMutation,
  useUpdateForeignPermissionApplicationMutation
} from '@libs/domain/application/api';
import { PermissionsRecordsQueryKeysEnum } from '@libs/domain/application/api/queries/useGetForeignPermissionApplicationRecords';
import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';
import { useResourceTypesQuery } from '@libs/domain/resource';

import {
  ForeignPermissionApplicationRecordFormData,
  ResourceTypeOption
} from '../utils/foreignPermissionApplicationUtils';

import { ConcernPermissionSelectField } from './ConcernPermissionSelectField';

const defaultFormAmount = 1;

interface IEkmtTableEditFormDialog {
  editFormData?: ForeignPermissionApplicationRecordWithLimitDetails;
  folderId: string;
  applicationId: string;
  closeDialog: () => void;
  formMode: FormMode;
  isFieldEditable: (columnName: CamelCasePath<ForeignPermissionApplicationRecordWithLimitDetails>) => boolean;
  validateUniqueResourceTypeInList?: boolean;
  isNoteFieldVisible?: boolean;
  ekmtCategoryKeys?: Array<string>;
  getParentFormValues: UseFormGetValues<Record<string, any>>;
  tableRowsData: ForeignPermissionApplicationRecordFormData[];
}

const EkmtTableEditFormDialog = ({
  editFormData,
  folderId,
  applicationId,
  closeDialog,
  formMode,
  isFieldEditable,
  validateUniqueResourceTypeInList,
  isNoteFieldVisible,
  ekmtCategoryKeys,
  getParentFormValues,
  tableRowsData
}: IEkmtTableEditFormDialog) => {
  const [t] = useTranslation();
  const { isSmallScreen } = useIsSmallScreen();
  const { showSuccessSnackbar } = useSnackbar();
  const queryCache = useQueryCache();

  const { createMode } = useViewModesV2(formMode);

  const { mutate: updateForeignPermission, isLoading: isLoadingUpdate } =
    useUpdateForeignPermissionApplicationMutation();
  const { mutate: addForeignPermission, isLoading: isLoadingCreate } =
    useAddForeignPermissionApplicationRecordsMutation();

  const { data } = useResourceTypesQuery(
    {
      ekmtCategoryKeyIn: ekmtCategoryKeys,
      yearGreaterThanOrEqual: new Date().getFullYear() - 1,
      yearLessThanOrEqual: new Date().getFullYear() + 1
    },
    {
      enabled: isFieldEditable('resourceType')
    }
  );

  const { translate } = useDictionaryTranslations();
  const options: Array<ResourceTypeOption> = data?.content.map(item => ({
    id: item.id,
    value: item.name,
    ekmtCategoryKey: item.ekmtCategoryKey,
    year: item.year,
    nameKey: item.name,
    name: translate(DomainDictionaryEnum.RESOURCE_FORM_NAME, item.name)
  }));

  const validationSchema = YupObject({
    ...(isFieldEditable('resourceType') && {
      resourceType: YupObject({
        value: YupString()
      })
        .nullable()
        .test(
          'isUnique',
          t('validation:valueIsAlreadySelected'),
          (value: ForeignPermissionApplicationRecordFormData['resourceType']) => {
            if (!value || !validateUniqueResourceTypeInList) return true;
            const filtered = tableRowsData?.filter(item => item.resourceType?.nameKey === value.value);
            const isUniqueNameKey = filtered?.length < 2;
            return isUniqueNameKey;
          }
        )
        .required()
    }),
    ...(isFieldEditable('concernForeignPermission') && {
      concernForeignPermission: YupObject({
        value: YupString()
      })
        .nullable()
        .test(
          'isUniquePermission',
          t('validation:valueIsAlreadySelected'),
          (value: ForeignPermissionApplicationRecordFormData['concernForeignPermission']) => {
            if (!value) return true;
            const filtered = tableRowsData?.filter(item => item.concernForeignPermission?.name === value.name);
            const isUniqueNumber = filtered?.length < 2;

            return isUniqueNumber;
          }
        )
        .required()
    }),
    ...(isFieldEditable('euro5amount') && {
      euro5amount: YupNumber().test('euro5amountRequired', t('validation:required'), function isEuro5Required(value) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, react/no-this-in-sfc
        const isEuro5 = this.parent.resourceType?.nameKey?.includes(
          DomainDictionaryEntry.RESOURCE_FORM_NAME.PARTIAL_EURO_CLASS_5
        );
        return isEuro5 ? value >= 0 : true;
      })
    }),
    ...(isFieldEditable('euro6amount') && {
      euro6amount: YupNumber().test('euro6amountRequired', t('validation:required'), function isEuro6Required(value) {
        // eslint-disable-next-line react/no-this-in-sfc, @typescript-eslint/no-unsafe-assignment
        const isEuro6 = this.parent.resourceType?.nameKey?.includes(
          DomainDictionaryEntry.RESOURCE_FORM_NAME.PARTIAL_EURO_CLASS_6
        );
        return isEuro6 ? value >= 0 : true;
      })
    }),
    ...(isFieldEditable('amount') && {
      amount: YupNumber().nullable().min(1).required()
    })
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    clearErrors
  } = useForm<Partial<ForeignPermissionApplicationRecordFormData>>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: {
      euro5amount: editFormData?.euro5amount || 0,
      euro6amount: editFormData?.euro6amount || 0,
      concernForeignPermission: editFormData?.concernForeignPermission || null,
      resourceType: editFormData?.resourceType || null,
      amount: editFormData?.amount || 1,
      notes: editFormData?.notes || ''
    }
  });

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting, clearErrors }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch, clearErrors]
  );

  const resourceTypeWatcher = watch('resourceType');

  const queryConfig = <
    T extends ForeignPermissionApplicationUpdateDetailsClient | ForeignPermissionApplicationUpdateDetails
  >() => ({
    onSuccess: ({ data: responseData }: { data: T }) => {
      showSuccessSnackbar(t('success.save'));
      queryCache.invalidateQueries([PermissionsRecordsQueryKeysEnum.FOREIGN_PERMISSIONS_RECORDS, { applicationId }]);
      queryCache.setQueryData([ApplicationQueryKeysEnum.APPLICATION, applicationId], {
        ...responseData?.applicationDetails,
        recipientName: getParentFormValues('application-send-to-address-name-requested-permits') as string,
        recipientSurname: getParentFormValues('application-send-to-address-surname-requested-permits') as string
      });
      closeDialog();
    }
  });

  const handleSubmitForm = (formValues: Partial<ForeignPermissionApplicationRecordFormData>) => {
    const association = getParentFormValues('foreignPermissionApplicationDetails-association') as boolean;
    const recipientName = getParentFormValues('application-send-to-address-name-requested-permits') as string;
    const recipientSurname = getParentFormValues('application-send-to-address-surname-requested-permits') as string;
    const receiptTypeKey = getParentFormValues(
      'application-receipt-type-key-requested-permits'
    ) as SelectOption<string>;
    const sendToAddress = getParentFormValues(
      'application-send-to-address-required-requested-permits'
    ) as SelectOption<SubjectAddressDetails>;
    const sendToAddressNotRequired = getParentFormValues(
      'application-send-to-address-not-required-requested-permits'
    ) as SelectOption<SubjectAddressDetails>;
    const formAddressData = sendToAddress || sendToAddressNotRequired;

    let formAmount: number | undefined = defaultFormAmount;
    if (isFieldEditable('euro5amount') && isFieldEditable('euro6amount')) {
      formAmount = Number(formValues.euro5amount) + Number(formValues.euro6amount);
    }
    if (isFieldEditable('amount')) {
      formAmount = Number(formValues.amount);
    }

    const applicationData: ApplicationDetails = queryCache.getQueryData([
      ApplicationQueryKeysEnum.APPLICATION,
      applicationId
    ]);

    const getAddressData = () => {
      if (applicationData?.receiptTypeKey === DomainDictionaryEntry.RECEIPT_TYPE.PICKUP_IN_PERSON) {
        return { recipientName, recipientSurname };
      }

      if (
        [DomainDictionaryEntry.RECEIPT_TYPE.COURIER, DomainDictionaryEntry.RECEIPT_TYPE.POST].includes(
          applicationData?.receiptTypeKey
        )
      ) {
        return { sendToAddressTypeKey: formAddressData?.value.typeKey };
      }

      return null;
    };

    if (createMode) {
      return addForeignPermission(
        {
          applicationId,
          foreignPermissionApplicationAddRequest: {
            resourceTypeId: formValues.resourceType?.id,
            association,
            amount: formAmount,
            concernForeignPermissionId: formValues.concernForeignPermission?.id,
            notes: formValues?.notes,
            applicationUpdateRequest: {
              ...getAddressData(),
              receiptTypeKey: receiptTypeKey?.value || applicationData?.receiptTypeKey,
              submissionReceiptDate: applicationData?.submissionReceiptDate,
              version: applicationData?.version
            }
          }
        },
        queryConfig<ForeignPermissionApplicationUpdateDetails>()
      );
    }
    return updateForeignPermission(
      {
        applicationId,
        foreignPermissionApplicationRecordsUpdateRequest: {
          association,
          records: [
            {
              resourceTypeId: formValues.resourceType?.id,
              euro5amount: isFieldEditable('euro5amount') ? Number(formValues.euro5amount) : undefined,
              euro6amount: isFieldEditable('euro6amount') ? Number(formValues.euro6amount) : undefined,
              concernForeignPermissionId: formValues.concernForeignPermission?.id,
              amount: formAmount,
              notes: formValues.notes
            }
          ],
          applicationUpdateRequest: {
            ...getAddressData(),
            receiptTypeKey: receiptTypeKey?.value || applicationData?.receiptTypeKey,
            submissionReceiptDate: applicationData?.submissionReceiptDate,
            version: applicationData?.version
          }
        }
      },
      queryConfig<ForeignPermissionApplicationUpdateDetailsClient>()
    );
  };

  return (
    <FormV2Context.Provider value={values}>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Dialog
          title={createMode ? t('permission:table.createEkmtPermissions') : t('permission:table.editEkmtPermissions')}
          shortTitle={createMode ? t('global:dialog.addTitle') : t('global:dialog.editTitle')}
          isFullScreen={isSmallScreen}
          dialogSize="small"
          onCancel={closeDialog}
          onConfirm={handleSubmit(handleSubmitForm)}
          confirmText={t('action.save')}
          cancelText={t('action.cancel')}
          isConfirmLoading={isLoadingCreate || isLoadingUpdate}
          isOpen
        >
          <GridLayout itemProps={{ xs: 12 }}>
            {isFieldEditable('resourceType') && (
              <AutocompleteSelectField
                name={typedNameV2<ForeignPermissionApplicationRecordFormData>('resourceType')}
                label={t('applications:foreignPermissions.fields.resourceType')}
                isRequired
                options={options}
                hasErrorTooltip
              />
            )}
            {isFieldEditable('concernForeignPermission') && (
              <ConcernPermissionSelectField
                inputName={typedNameV2<ForeignPermissionApplicationRecordFormData>('concernForeignPermission')}
                label={t('applications:foreignPermissions.fields.concernPermission')}
                folderId={folderId}
                isRequired
              />
            )}
            {isFieldEditable('euro5amount') &&
              resourceTypeWatcher?.nameKey?.includes(DomainDictionaryEntry.RESOURCE_FORM_NAME.PARTIAL_EURO_CLASS_5) && (
                <NumberInputField
                  label={t('applications:foreignPermissions.fields.EURO5')}
                  name={typedNameV2<ForeignPermissionApplicationRecordFormData>('euro5amount')}
                  isRequired
                />
              )}
            {isFieldEditable('euro6amount') &&
              resourceTypeWatcher?.nameKey?.includes(DomainDictionaryEntry.RESOURCE_FORM_NAME.PARTIAL_EURO_CLASS_6) && (
                <NumberInputField
                  label={t('applications:foreignPermissions.fields.EURO6')}
                  name={typedNameV2<ForeignPermissionApplicationRecordFormData>('euro6amount')}
                  isRequired
                />
              )}
            {isFieldEditable('amount') && (
              <NumberInputField
                label={t('applications:foreignPermissions.fields.amount')}
                name={typedNameV2<ForeignPermissionApplicationRecordFormData>('amount')}
                isRequired
              />
            )}
            {isNoteFieldVisible && (
              <TextInputField
                label={t('applications:foreignPermissions.fields.note')}
                name={typedNameV2<ForeignPermissionApplicationRecordFormData>('notes')}
              />
            )}
          </GridLayout>
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
};

export default EkmtTableEditFormDialog;
