import { useTranslation } from 'react-i18next';

import { Button } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

import { useFinancialSecuritySearch } from '../hooks/action/useFinancialSecuritySearch';

interface IProps {
  folderId: string;
  transferredFoldersIds?: string[];
  applicationId: string;
  folderTypeKey: string;
  actionKey?: UIElementNameEnum;
  customOnSuccess?: () => void;
}

function FinancialSecuritySearchButton({
  folderId,
  transferredFoldersIds,
  applicationId,
  folderTypeKey,
  actionKey,
  customOnSuccess
}: IProps) {
  const [t] = useTranslation();

  const { openFinancialSecuritySearchModal } = useFinancialSecuritySearch({
    folderId,
    transferredFoldersIds,
    applicationId,
    folderTypeKey,
    customOnSuccess
  });

  return (
    <Button
      type="button"
      variant="outlined"
      onClick={openFinancialSecuritySearchModal}
      label={t('financialSecurity:action.search.title')}
      actionKey={actionKey}
    />
  );
}

export default FinancialSecuritySearchButton;
