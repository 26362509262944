import { MoneyTransferApplicationRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

type Params = {
  applicationId: string;
  formData: MoneyTransferApplicationRequest;
};

function addMoneyTransfersToApplication({ applicationId, formData }: Params) {
  return API.moneyTransfer.addMoneyTransfersToApplication(applicationId, formData, {
    ...endpointsConfig.addMoneyTransfersToApplication
  });
}

function useAddMoneyTransfersToApplication() {
  return useMutation(addMoneyTransfersToApplication);
}

export default useAddMoneyTransfersToApplication;
