import { useCallback, useMemo } from 'react';

import { Chip } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';
import {
  MESSAGE_STATUS_COLOR_CONFIG,
  MessagesDictionaryEntryNameEnum,
  StatusColorType
} from '@libs/notification/models';

interface IProps {
  status: string;
}

function MessageStatusChip({ status }: IProps) {
  const { translate } = useDictionaryTranslations();
  const statusDefinitionItem = useMemo(
    () => MESSAGE_STATUS_COLOR_CONFIG.find(definition => definition.status === status),
    [status]
  );

  const messageColorDefinitions = useCallback((colorType: StatusColorType) => {
    switch (colorType) {
      case StatusColorType.YELLOW:
        return 'warning';
      case StatusColorType.GREY:
        return 'grey';
      case StatusColorType.LIGHTGREEN:
      case StatusColorType.GREEN:
        return 'success';
      default:
        return 'success';
    }
  }, []);

  return (
    <Chip
      title={translate(MessagesDictionaryEntryNameEnum.NOTIFICATION_STATUS, status)}
      color={messageColorDefinitions(statusDefinitionItem.color)}
      colorWeight={statusDefinitionItem.color === StatusColorType.GREEN ? '700' : '800'}
    />
  );
}

export default MessageStatusChip;
