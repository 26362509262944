import { useTranslation } from 'react-i18next';
import { AlertApiGetAlertTransitionsHistoryRequest, AlertTransitionDetailsSnapshot } from '@stack/report';

import { ColumnTypesEnum, TableFilterMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO } from '@libs/common/v2/utils';

import { AlertDictionaryEntryNameEnum } from '@libs/alert';
import { AlertStatusChip } from '@libs/alert/components/ui';
import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

export default function useAlertHistoryColumns() {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  const { createColumns } = useCreateColumns<AlertTransitionDetailsSnapshot>({
    pathToTranslate: 'alerts:table'
  });

  const mappedFilterFields: TableFilterMapper<
    AlertTransitionDetailsSnapshot,
    AlertApiGetAlertTransitionsHistoryRequest
  > = {
    statusKey: values => ({
      currentStatusKeys: values.map(({ value }) => value)
    }),
    created: ({ dateFrom, dateTo }: DateRangeISO) => ({ createdFrom: dateFrom || '', createdTo: dateTo || '' })
  };

  const columns = createColumns([
    {
      type: ColumnTypesEnum.TEXT,
      accessor: 'name',
      isSortable: false,
      isFilterable: false
    },
    {
      type: ColumnTypesEnum.DATE_TIME,
      header: 'time',
      accessor: 'created',
      width: 300
    },
    {
      id: 'statusKey',
      type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
      dictionaryName: AlertDictionaryEntryNameEnum.ALERT_STATUS,
      customAccessor: ({ statusKey }) => <AlertStatusChip statusKey={statusKey} />,
      customXlsxAccessor: row => translate(AlertDictionaryEntryNameEnum.ALERT_STATUS, row?.statusKey, t('emptyMark')),
      tooltipTitle(row) {
        return translate(AlertDictionaryEntryNameEnum.ALERT_STATUS, row?.statusKey);
      },
      isSortable: false,
      width: 300
    },
    {
      type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
      dictionaryName: DomainDictionaryEnum.ERROR,
      accessor: 'errorCode',
      isFilterable: false,
      width: 300
    },
    {
      type: ColumnTypesEnum.TEXT,
      accessor: 'errorMessage',
      isFilterable: false,
      width: 300
    }
  ]);

  return { columns, mappedFilterFields };
}
