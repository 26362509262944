import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isUndefined, omitBy } from 'lodash';

import {
  AdditionalFilterEnum,
  Button,
  IAdditionalFilter,
  Scrollbar,
  TableIconButton,
  useFormV2Context
} from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { border, hexToRgba, important } from '@libs/common/v2/utils';

import { renderFilters } from '../utils';

interface IProps {
  additionalFilters: IAdditionalFilter[];
  setTableQueryParams?: React.Dispatch<React.SetStateAction<Record<string, unknown>>>;
  tableQueryParams?: Record<string, unknown>;
}

function TableAdditionalFiltersPanel({ additionalFilters, tableQueryParams, setTableQueryParams }: IProps) {
  const [t] = useTranslation();
  const { watch, setValue, getValues } = useFormV2Context();
  const [anchor, setAnchor] = useState<Element | null>(null);
  const open = Boolean(anchor);
  const filters = watch();

  const filterActive = useMemo(() => {
    const filtersName = additionalFilters?.map(filter => filter.name);
    return !!filtersName?.filter(name => filters[name]?.length ?? false).length;
  }, [additionalFilters, filters]);
  const classes = useStyles({ active: filterActive, numberOfFilters: additionalFilters?.length });

  const toggleFiltersPanel = (event?: React.MouseEvent<Element>): void => {
    if (open) {
      setAnchor(null);
      return;
    }

    setAnchor(open ? null : event.currentTarget);
  };

  useEffect(() => {
    if (!open) {
      const values = getValues();
      Object.keys(values).forEach(key => {
        setValue(key, tableQueryParams[key]);
      });
    }
  }, [tableQueryParams, getValues, setValue, open]);

  const clearExtraFiltres = () => {
    for (let i = 0; i < additionalFilters.length; i++) {
      setValue(additionalFilters[i].name, null);
    }
    setTableQueryParams(prevState => {
      const defaultFilters = prevState;
      additionalFilters.forEach(filter => delete defaultFilters[filter.name]);
      return defaultFilters;
    });
  };

  const applyFilters = () => {
    const data = getValues();
    let filteredObj: Record<string, any> = omitBy(data, isUndefined);
    const dictionarySelects =
      additionalFilters?.filter(item => item.type === AdditionalFilterEnum.DICTIONARY_SELECT) || [];

    // konwersja multiselectow slownikowych
    if (dictionarySelects.length) {
      const dictionaryKeys = dictionarySelects.map(item => item.name);
      Object.keys(filteredObj).forEach((key: string) => {
        if (dictionaryKeys.includes(key)) {
          filteredObj = {
            ...filteredObj,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            [key]: filteredObj[key]?.map((item: { value: string | number | boolean }) => item.value)
          };
        }
      });
    }

    setTableQueryParams(omitBy(filteredObj, isUndefined));
    toggleFiltersPanel();
  };

  return (
    <>
      <TableIconButton
        icon="FilterAddIcon"
        onClick={toggleFiltersPanel}
        iconClassName={classes.icon}
        className={classes.iconButton}
        tooltipTitle={t('tables:extraFilters')}
      />
      <Popover
        classes={{ paper: classes.popover }}
        open={open}
        anchorEl={anchor}
        onClose={toggleFiltersPanel}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div>
          <Scrollbar options={{ suppressScrollX: true }} className={classes.scrollbar}>
            <Grid
              className={classes.content}
              spacing={1}
              direction="column"
              justifyContent="center"
              alignContent="space-between"
              container
            >
              {additionalFilters?.map(filter => (
                <div key={filter.name} className="my-12 w-full">
                  {renderFilters(filter)}
                </div>
              ))}
            </Grid>
          </Scrollbar>
          <div className={classes.actionPanel}>
            <Button
              className={classes.clearButton}
              onClick={clearExtraFiltres}
              label={t('tables:clearExtraFilters')}
              variant="outlined"
              isNoMargin
              fullWidth
            />
            <Button
              className={classes.clearButton}
              onClick={applyFilters}
              variant="outlined"
              label={t('action.search')}
              isNoMargin
              fullWidth
            />
          </div>
        </div>
      </Popover>
    </>
  );
}

const useStyles = makeStyles<Theme, { active: boolean; numberOfFilters: number }>(theme => ({
  actionPanel: {
    display: 'flex',
    paddingBottom: '0.5rem',
    paddingRight: '0.3rem',
    paddingLeft: '0.3rem',
    gap: '0.3rem'
  },
  filterInput: {
    marginTop: important('0px')
  },
  clearButton: {
    marginTop: '6px'
  },
  icon: {
    '& > path': {
      fill: ({ active }) => (active ? theme.palette.blue[800] : theme.palette.grey[500]),
      stroke: 'none'
    }
  },
  popover: {
    backgroundColor: theme.palette.grey[50],
    width: 300,
    overflow: 'hidden',
    height: 529
  },
  content: {
    padding: '8px 20px',
    display: 'flex',
    flexDirection: 'column'
  },
  listButton: {
    position: 'absolute',
    bottom: 0,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1.75),
    backgroundColor: theme.palette.white,
    borderTop: border(hexToRgba(theme.palette.grey[300], 1.5)),
    '&:hover, &:focus': {
      backgroundColor: hexToRgba(theme.palette.grey[400], 0.5)
    }
  },
  scrollbar: {
    height: important('480px'),
    '& .ps__rail-y': {
      height: important('480px')
    }
  }
}));
export default TableAdditionalFiltersPanel;
