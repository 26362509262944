import { makeStyles } from '@mui/styles';

import { Theme } from '@libs/common/v2/theme';
import { calc } from '@libs/common/v2/utils';

const ConditionGroupMenuHeight = 36;

export const useStyles = makeStyles<Theme>(() => ({
  container: {
    position: 'relative'
  },
  groupMenu: {
    display: 'flex',
    height: ConditionGroupMenuHeight,
    alignItems: 'center',
    '& > :first-child': {
      display: 'flex',
      alignItems: 'center',
      '& > :last-child': {
        marginLeft: 80
      }
    },
    '& button': {
      zIndex: 2
    }
  },
  conditionsWrapper: {
    padding: '0 0 0 20px',
    '& > *': {
      marginTop: 16,
      marginRight: 8,
      marginBottom: 8
    }
  },
  line: {
    position: 'absolute',
    top: -ConditionGroupMenuHeight,
    left: -10,
    width: 10,
    zIndex: 1,
    borderLeft: '1px dashed lightgrey',
    borderBottom: '1px dashed lightgrey',
    borderRadius: 3
  },
  groupChildLine: {
    height: calc(`100% + ${ConditionGroupMenuHeight * 1.5}px`)
  },
  singleChildLine: {
    height: calc(`100% + ${ConditionGroupMenuHeight / 2 + 52}px`)
  },
  childLineWrapper: {
    position: 'relative'
  },
  childLine: {
    position: 'absolute',
    top: ({ type }: any) => (type === 'Condition' ? '50%' : ConditionGroupMenuHeight / 2),
    height: calc('100% + 40px'),
    left: -8,
    width: 8,
    borderTop: '1px dashed lightgrey'
  },
  separatedChildrenGroup: {
    position: 'relative',
    '& > :not(:first-child)': {
      marginTop: 16
    }
  }
}));
