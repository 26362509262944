import { ProceedingsKreptdApiUpdateProceedingKreptdRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function updateProceeding(requestData: ProceedingsKreptdApiUpdateProceedingKreptdRequest) {
  return API.proceedingsKreptd.updateProceedingKreptd(
    requestData.proceedingId,
    requestData.proceedingKreptdUpdateRequest,
    { ...endpointsConfig.updateProceedingKreptd }
  );
}

function useUpdateProceedingMutations() {
  return useMutation(updateProceeding);
}

export default useUpdateProceedingMutations;
