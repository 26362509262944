import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '@libs/user/api';

const lockUser = async (accountId: string) => {
  const { data } = await API.user.accountController.lockAccount({ accountId }, { ...endpointsConfig.lockAccount });
  return data;
};

export default function useLockUserMutation() {
  return useMutation(lockUser);
}
