import { DictionaryEntrySnapshot } from '@libs/dictionary';

export type DictionaryEntryNameEnum = keyof DictionaryEntryTypeEnum;
export type DictionaryQuickChangeableEntryNameEnum = keyof DictionaryQuickChangeableEntryTypeEnum;

export type DictionaryEntry = {
  entries: {
    name: string;
    value: string;
    active?: boolean;
  }[];
  translations: {
    [key: string]: string;
  };
};

export interface DictionaryEntryValue {
  id?: string;
  name: DictionaryEntrySnapshot['value'];
  value: DictionaryEntrySnapshot['key'];
  active?: boolean;
}

export enum DictionaryEntryNameStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ALL = 'ALL'
}
