import { useState } from 'react';

import useReportGeneration from './useReportGeneration';

interface TargetParameter {
  name: string;
  value: string;
}

export default function useGenerateReport(
  reportName: string,
  targetParameters?: TargetParameter[],
  initialValues?: { parameters: Record<string, string>; targetTypes: string[] },
  isTechnical?: boolean
): [() => void, boolean] {
  const [isLoading] = useState();

  const [generate] = useReportGeneration(reportName, targetParameters, initialValues, isTechnical);

  return [generate, isLoading];
}
