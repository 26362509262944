import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '@libs/user/api';

const deleteUserGroup = async ({ userId, groupMembershipId }) => {
  const { data } = await API.group.deleteUserGroupMembership(
    { userId, groupMembershipId },
    {
      ...endpointsConfig.deleteUserGroupMembership
    }
  );

  return data;
};

function useDeleteUserGroupMutation() {
  return useMutation(deleteUserGroup);
}

export default useDeleteUserGroupMutation;
