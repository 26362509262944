export type DocumentListParams = {
  proceedingId?: string;
};

export interface DocumentsSnapshotClient {
  id: string;
  generatedDocumentName: string;
  generatedFileName: string;
  generatedBy: string;
  generatedDate: string;
  documentFileId: string;
  documentName: string;
  addedBy: string;
  inputDate: string;
  statusKey: string;
  passedToSentDate: string;
  packageNumber: string;
  sendDate: string;
  receiptAckDate: string;
  cancellationDate: string;
  cancelledBy: string;
  typeKey: string;
  subject: {
    id: string;
    name: string;
  };
  address: {
    id: string;
    version: number;
  };
  parcel: {
    receiptAckDate: string;
    sendDate: string;
    number: string;
    passedToSentDate: string;
  };
  uploadedFileName: string;
  version: number;
  isFinalDecision?: boolean;
  isPermission?: boolean;
}

export interface UploadDocumentFile {
  file?: {
    fileId: string;
    name: string;
    type: string;
  };
}

export enum DocumentsDictionaryNameEnum {
  DOCUMENT_STATUS = 'DOCUMENT_STATUS'
}

export enum DocumentStatusEnum {
  CANCELLED = 'ibtm-domain.document.status.cancelled',
  READY_FOR_RELEASE = 'ibtm-domain.document.status.ready-for-release',
  READY_TO_SEND = 'ibtm-domain.document.status.ready-to-send',
  UPLOADED = 'ibtm-domain.document.status.uploaded',
  RELEASE_DELIVERED = 'ibtm-domain.document.status.release-delivered',
  RELEASE_PENDING_FOR_DELIVERY = 'ibtm-domain.document.status.release-pending-for-delivery',
  GENERATED = 'ibtm-domain.document.status.generated'
}

export const RELEASE_STATUSES = [
  DocumentStatusEnum.RELEASE_DELIVERED,
  DocumentStatusEnum.RELEASE_PENDING_FOR_DELIVERY,
  DocumentStatusEnum.CANCELLED
];

export enum RowActionEnum {
  UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT',
  DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT',
  CANCEL_DOCUMENT = 'CANCEL_DOCUMENT',
  FORWARD_TO_SHIPMENT_DOCUMENT = 'FORWARD_TO_SHIPMENT_DOCUMENT',
  ENTER_DELIVERY_DATE_DOCUMENT = 'ENTER_DELIVERY_DATE_DOCUMENT',
  FINAL_DECISION = 'FINAL_DECISION'
}

export enum HeaderActionEnum {
  GENERATE_SUBMISSION = 'GENERATE_SUBMISSION',
  GENERATE_CALL_TO_FILL_GAPS = 'GENERATE_CALL_TO_FILL_GAPS',
  GENERATE_ISSUE = 'GENERATE_ISSUE',
  GENERATE_DOCUMENT = 'GENERATE_DOCUMENT',
  GROUP_KEY_IN_FILTER = 'GROUP_KEY_IN_FILTER',
  GROUP_KEY_NOT_IN_FILTER = 'GROUP_KEY_NOT_IN_FILTER'
}
