import { GroupSnapshotExternal } from '@avispon/group/dist/models';
import { GroupAssignCommand } from '@avispon/user';

import { i18n } from '@libs/common';
import { TableFilterMapper, useCreateColumns } from '@libs/common/v2';

export default function useCreatePermissionsGroupColumns() {
  const { createColumns } = useCreateColumns<GroupSnapshotExternal & GroupAssignCommand>({
    pathToTranslate: 'administration:organizationUnit.field'
  });

  const mappedFilterFields: TableFilterMapper<GroupSnapshotExternal & GroupAssignCommand, any> = {
    name: value => ({ groupName: value }),
    unitName: ({ id }) => ({ unitIds: id })
  };

  const columns = createColumns([
    {
      type: 'TEXT',
      accessor: 'name',
      isSortable: false
    },
    {
      header: 'unit',
      type: 'TEXT',
      accessor: 'unit.name',
      isSortable: false
    },
    {
      header: 'user:groupField.includeSubordinates',
      type: 'BOOLEAN',
      tooltipTitle: ({ includeSubordinates }) => (includeSubordinates ? i18n.t('action.yes') : i18n.t('action.no')),
      accessor: 'includeSubordinates',
      isFilterable: false,
      isSortable: false
    }
  ]);

  return { columns, mappedFilterFields };
}
