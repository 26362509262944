import { SubjectAddressHistoryDetails } from '@ibtm/domain';

import { CamelCasePath, useTableLayoutProps } from '@libs/common/v2';

import { useAddressHistoryColumns, useAddressHistoryQuery } from '@libs/domain/folder';

function useAddressHistoryTable(subjectId: string, visibleColumns: Array<CamelCasePath<SubjectAddressHistoryDetails>>) {
  const { columns } = useAddressHistoryColumns(visibleColumns);

  return useTableLayoutProps({
    tableHookQuery: useAddressHistoryQuery,
    tableHookOptions: {
      columns,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'created', desc: true }]
        }
      }
    },
    tableHookQueryInitialParams: {
      subjectId
    }
  });
}

export default useAddressHistoryTable;
