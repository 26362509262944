import { useTranslation } from 'react-i18next';
import { queryCache } from 'react-query';
import { useSnackbar } from '@enigma/fe-ui';
import { PermanentPermissionSubmissionDetails } from '@ibtm/domain';

import {
  AdditionalFilterEnum,
  Button,
  TableLayout,
  useConfirmDialog,
  useRouter,
  useTableRowActions
} from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { DomainUIElementEnum } from '@libs/domain/config';
import {
  ExportCommitteeFileButton,
  ImportComitteeDecisionButton,
  SocialCommissionQueryKeysEnum,
  useDeleteEKMTMutation,
  useSocialCommissionEKMTTable
} from '@libs/domain/social-commission';

import { SocialCommissionTableEnum } from '../../common/model/social-comission.model';
import { IntialYearType } from '../../SocialCommissionPage';

function SocialCommissionTable({
  headerActions,
  globalFilters,
  initialYear
}: {
  tab: string;
  headerActions?: React.ReactNode;
  globalFilters: (name: string) => {
    type: AdditionalFilterEnum;
    name: string;
    customFilter: JSX.Element;
  }[];
  initialYear: IntialYearType;
}) {
  const [t] = useTranslation();

  const [confirm] = useConfirmDialog();

  const { routes } = useRouter();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: deleteListItem } = useDeleteEKMTMutation();

  const tableProps = useSocialCommissionEKMTTable(initialYear);

  const deleteDialog = (original: PermanentPermissionSubmissionDetails) => {
    const data = { permanentSubmissionId: original.id };
    confirm({
      title: t('foreignPermits:other.deleteApplicationTitle'),
      message: t('foreignPermits:other.deleteApplication'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);

        deleteListItem(data, {
          onSuccess: () => {
            showSuccessSnackbar(t('global:dialog.deleteSuccess'));
            queryCache.invalidateQueries(SocialCommissionQueryKeysEnum.EKMT_DETAILS);
          },
          onSettled: () => {
            setConfirmLoading(false);
            closeDialog();
          }
        });
      },
      confirmType: 'danger'
    });
  };

  const { renderTableActions } = useTableRowActions<PermanentPermissionSubmissionDetails>([
    {
      id: DomainUIElementEnum.COMISSION_EKMT_DETAILS,
      label: 'action.openDetails',
      link: ({ original }) => routes.socialCommissionDetails(original.id),
      icon: 'ArrowIcon'
    },
    {
      id: DomainUIElementEnum.COMMISSION_EKMT_EDIT_BUTTON,
      label: 'action.edit',
      link: ({ original }) => routes.socialCommissionEdit(original.id),
      icon: 'EditIcon'
    },
    {
      id: DomainUIElementEnum.COMMISSION_EKMT_DELETE_BUTTON,
      label: 'action.delete',
      icon: 'TrashIcon',
      onClick: ({ row: { original } }) => deleteDialog(original)
    }
  ]);

  return (
    <TableLayout
      {...tableProps}
      xlsxDownload={{
        fileName: t('foreignPermits:list.title'),
        pathToXLSXTranslation: 'foreignPermits:tabs.socialCommission.tabs.socialCommissionList.fields',
        apiRequest: API.civicBoard.getPermanentPermissionSubmissionSnapshotPage
      }}
      isSectionFullTab
      sectionProps={{ titleHeaderContent: headerActions }}
      headerActions={
        <>
          <ExportCommitteeFileButton
            isEkmtCommittee
            year={initialYear?.value}
            actionKey={DomainUIElementEnum.COMISSION_EKMT_EXPORT}
          />

          <ImportComitteeDecisionButton isEkmtCommittee actionKey={DomainUIElementEnum.COMISSION_EKMT_IMPORT} />

          <Button
            label={t('global:action.add')}
            link={routes.socialComissionCreate()}
            isPrimary
            variant="contained"
            actionKey={DomainUIElementEnum.COMMISSION_EKMT_ADD_BUTTON}
          />
        </>
      }
      rowActions={renderTableActions([
        DomainUIElementEnum.COMISSION_EKMT_DETAILS,
        DomainUIElementEnum.COMMISSION_EKMT_EDIT_BUTTON,
        DomainUIElementEnum.COMMISSION_EKMT_DELETE_BUTTON
      ])}
      globalFilters={globalFilters(SocialCommissionTableEnum.YEAR_GRATER)}
    />
  );
}

export default SocialCommissionTable;
