import { useTranslation } from 'react-i18next';
import { FolderSearchFilter, FolderSnapshot } from '@ibtm/domain';

import { CamelCasePath, TableHeaderButton, TableLayout, useRouter, useTableRowActions } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';
import {
  DomainDictionaryEntry,
  DomainUIElementEnum,
  FolderElementsPermissionsEnum,
  useDomainConfigContext
} from '@libs/domain/config';
import {
  FolderQueryKeysEnum,
  useFolderAdditionalFilters,
  useFoldersTableProps,
  useUpdateFolderStatusDialog
} from '@libs/domain/folder';

import { useFolderElementsVisibility } from '../../../config';
import { FolderTypesDisabledInPK } from '../../model/folders-type-disabled-pk.model';

function FoldersTable() {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { isClientPortal, isOperatorPortal } = useDomainConfigContext();
  const { activateFolder, expireFolder } = useUpdateFolderStatusDialog();
  const { additionalFilters } = useFolderAdditionalFilters();
  const { checkFolderElementVisibility } = useFolderElementsVisibility();
  const visibleColumns: Array<CamelCasePath<FolderSnapshot>> = isClientPortal
    ? ['number', 'typeKey', 'subjectName', 'subjectNip', 'statusKey']
    : null;
  const tableProps = useFoldersTableProps(null, visibleColumns);
  const { routes } = useRouter();

  const refetch = () => {
    queryCache.invalidateQueries(FolderQueryKeysEnum.FOLDERS_LIST);
  };

  const { renderTableActions } = useTableRowActions<FolderSnapshot>([
    {
      id: 'FOLDER_DETAILS_BUTTON',
      label: 'action.show',
      icon: 'ArrowIcon',
      link: ({ original }) => routes.folderDetails(original.id),
      isHidden: ({ row }) => {
        const {
          original: { typeKey }
        } = row;
        if (isClientPortal) {
          return FolderTypesDisabledInPK.includes(typeKey);
        }
        return !checkFolderElementVisibility({
          folderTypeKey: row.original.typeKey,
          permisson: FolderElementsPermissionsEnum.FOLDER_VIEW_PERMISSIONS
        });
      }
    },
    {
      id: 'FOLDER_ACTIVATE_BUTTON',
      label: 'action.activate',
      onClick: ({
        row: {
          original: { id }
        }
      }) => activateFolder(id, refetch),
      icon: 'ActivateIcon',
      isHidden: ({ row }) =>
        isClientPortal || (isOperatorPortal && row?.original?.statusKey === DomainDictionaryEntry.FOLDER_STATUS.ACTIVE)
    },
    {
      id: 'FOLDER_EDIT_BUTTON',
      label: 'action.edit',
      icon: 'EditIcon',
      link: ({ original }) => routes.folderEdit(original.id),
      isHidden: ({ row }) =>
        !checkFolderElementVisibility({
          folderTypeKey: row.original.typeKey,
          permisson: FolderElementsPermissionsEnum.FOLDER_EDIT_PERMISSIONS
        }) ||
        isClientPortal ||
        (isOperatorPortal && row?.original?.statusKey !== DomainDictionaryEntry.FOLDER_STATUS.ACTIVE)
    },
    {
      id: 'FOLDER_EXPIRE_BUTTON',
      label: 'action.expire',
      icon: 'ExpireIcon',
      onClick: ({
        row: {
          original: { id }
        }
      }) => expireFolder(id, refetch),
      isHidden: ({ row }) =>
        isClientPortal || (isOperatorPortal && row?.original?.statusKey !== DomainDictionaryEntry.FOLDER_STATUS.ACTIVE)
    }
  ]);

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('folder:title')}
      isTableGrey
      additionalFilters={additionalFilters}
      xlsxDownload={{
        fileName: t('folder:title'),
        pathToXLSXTranslation: 'folder:field',
        apiRequest: (params: FolderSearchFilter) => {
          const paramsTemp = params;
          if (isClientPortal) {
            return API.client.folder.getFoldersSnapshotPage('', '', paramsTemp);
          }
          const sortParam = paramsTemp?.sort[0].split(',') as string[];
          if (sortParam) {
            const sortParamName = sortParam[0];
            const sortParamDirection = sortParam[1];
            if (sortParamName === 'subject.name') {
              paramsTemp.sort = [`subjectName,${sortParamDirection}`];
            }
            if (sortParamName === 'subject.nip') {
              paramsTemp.sort = [`subjectNip,${sortParamDirection}`];
            }
          }
          return API.folder.getFoldersListSnapshotPage(paramsTemp);
        }
      }}
      headerActions={
        isOperatorPortal && (
          <TableHeaderButton
            label={t('folder:action.create')}
            link={routes.folderCreate()}
            actionKey={DomainUIElementEnum.FOLDER_ADD_BUTTON}
          />
        )
      }
      rowActions={renderTableActions([
        DomainUIElementEnum.FOLDER_ACTIVATE_BUTTON,
        DomainUIElementEnum.FOLDER_EDIT_BUTTON,
        DomainUIElementEnum.FOLDER_EXPIRE_BUTTON,
        DomainUIElementEnum.FOLDER_DETAILS_BUTTON
      ])}
    />
  );
}

export default FoldersTable;
