import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Theme } from '@libs/common/v2/theme';
import { hexToRgba } from '@libs/common/v2/utils';

interface IProps {
  leftLabel: React.ReactNode;
  rightLabel: React.ReactNode;
  disabled?: boolean;
  onChange?: (state: boolean) => void;
  initialValue?: boolean;
  value?: boolean;
  className?: string;
  spacing?: number;
}

function LabeledSwitch(props: IProps) {
  const { leftLabel, rightLabel, disabled, onChange, initialValue = true, value, className, spacing = 1 } = props;
  const [leftValue, setLeftValue] = useState<boolean>(initialValue);
  const [width, setWidth] = useState<[number, number]>([0, 0]);

  const leftValueRef = useRef<HTMLDivElement>(null);
  const rightValueRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setWidth([leftValueRef.current.offsetWidth, rightValueRef.current.offsetWidth]);
  }, []);

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setLeftValue(value);
    }
  }, [value]);

  const classes = useStyles({ ...props, leftValue, width, spacing });

  const handleChange = (state: boolean): void => {
    if (!disabled) {
      if (value === undefined || value === null) {
        setLeftValue(state);
      }
      if (onChange) {
        onChange(state);
      }
    }
  };

  return (
    <div className={clsx(classes.root, disabled ? classes.disabled : '', className)}>
      <div
        ref={leftValueRef}
        role="button"
        tabIndex={0}
        onFocus={e => e.target.classList.add('focus')}
        onBlur={e => e.target.classList.remove('focus')}
        onKeyPress={e => {
          if (e.key === 'Enter') handleChange(true);
        }}
        onClick={() => handleChange(true)}
        className={clsx(classes.item, leftValue && 'active')}
      >
        {leftLabel}
      </div>
      <div
        role="button"
        tabIndex={0}
        ref={rightValueRef}
        onFocus={({ target }) => target.classList.add('focus')}
        onBlur={({ target }) => target.classList.remove('focus')}
        onKeyPress={({ key }) => {
          if (key === 'Enter') handleChange(false);
        }}
        onClick={() => handleChange(false)}
        className={clsx(classes.item, !leftValue && 'active')}
      >
        {rightLabel}
      </div>
    </div>
  );
}
const useStyles = makeStyles<Theme, Pick<IProps, 'spacing'> & { width: [number, number]; leftValue: boolean }>(
  theme => ({
    root: {
      fontSize: theme.typography.getFontSize(14) as string,
      height: 40,
      minHeight: 40,
      backgroundColor: theme.palette.white,
      borderRadius: 8,
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: theme.typography.getFontSize(14) as string,
        fontWeight: 500,
        cursor: 'pointer',
        color: theme.palette.grey[500],
        fill: theme.palette.grey[500],
        '&.active': {
          borderRadius: 8,
          backgroundColor: theme.palette.blue['500'],
          color: theme.palette.white,
          fill: theme.palette.white
        },
        '&.focus': { borderRadius: 8, border: '3px solid', borderColor: theme.palette.primary?.focus }
      }
    },
    disabled: {
      backgroundColor: hexToRgba(theme.palette.grey[500], 0.05),
      '& > div': {
        cursor: 'default',
        '&:not(.active)': {
          color: '#9B9B9B',
          fill: '#9B9B9B'
        }
      }
    },
    item: {
      border: '3px solid transparent',
      zIndex: 2,
      padding: 8,
      '&:last-of-type': {
        marginLeft: ({ spacing }) => theme.spacing(spacing)
      }
    },
    indicator: {
      zIndex: 1,
      width: ({ width, leftValue }) => (leftValue ? width[0] : width[1]),
      transform: ({ width, leftValue, spacing }) => `translate(${leftValue ? 0 : width[0] + theme.spacing(spacing)})`,
      transition: 'all .25s',
      position: 'absolute',
      backgroundColor: theme.palette.blue['400'],
      height: 34,
      borderRadius: 17,
      left: 0
    }
  })
);

export default LabeledSwitch;
