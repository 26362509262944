import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '@enigma/fe-ui';
import { FolderLockDetails, FolderPermissionSnapshot, ProceedingDetails, SuspensionSnapshot } from '@ibtm/domain';

import {
  Button,
  FormMode,
  TableButton,
  TableButtonMore,
  typedNameV2,
  useConfirmDialog,
  useFormV2Context,
  useTableAdapter
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { convertCalendarDate } from '@libs/common/v2/utils';

import { UIElementNameEnum, useElementVisibility } from '@libs/permission';

import { DomainUIElementEnum } from '@libs/domain/config';
import { ProceedingDetailsParams, useRemoveLockMutation } from '@libs/domain/folder';
import { ProceedingQueryKeysEnum, ProceedingTypeKeyEnum, useAddOrUpdateLockDialog } from '@libs/domain/proceeding';
import { useFormOperationsActions } from '@libs/domain/suspensions';

interface IActionKeys {
  addFormOperation?: UIElementNameEnum;
  suspendIssuing?: UIElementNameEnum;
  resumeIssuing?: UIElementNameEnum;
  editIssuing?: UIElementNameEnum;
  checkQualifications?: UIElementNameEnum;
}
interface IProps {
  openCheckQualificationsDialog: (listedPermissions: string[]) => void;
  openCreateFormDialog: () => void;
  actionKeys?: IActionKeys;
}

function FormOperationsHeader({ openCheckQualificationsDialog, openCreateFormDialog, actionKeys }: IProps) {
  const queryCache = useQueryCache();
  const { folderId, proceedingTypeKey } = useParams<ProceedingDetailsParams>();
  const [t] = useTranslation();
  const [confirm] = useConfirmDialog();
  const { data } = useTableAdapter();
  const { watch } = useFormV2Context();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: removeFolderLock } = useRemoveLockMutation();
  const { handlePostpone: handlePostponeSuspensionFormOperation, handleSuspend: handleSuspendSuspensionFormOperation } =
    useFormOperationsActions();
  const { openAddOrUpdateLockDialog } = useAddOrUpdateLockDialog();
  const { checkIsElementVisible } = useElementVisibility();

  const isFormOperationsAvailable = useMemo(() => data.length > 0, [data]);
  const listedPermissions = useMemo(
    () => data?.map((permission: FolderPermissionSnapshot) => permission?.formNumber) ?? [],
    [data]
  ) as string[];

  const isAdministrativeProceeding = useMemo(() => {
    return proceedingTypeKey === ProceedingTypeKeyEnum.ADMINISTRATION;
  }, [proceedingTypeKey]);

  const driverCertificateSuspension = watch(
    typedNameV2<ProceedingDetails>('driverCertificateSuspension')
  ) as FolderLockDetails | null;

  const whole = watch(typedNameV2<SuspensionSnapshot>('whole')) as boolean;
  const hasSuspendedForms = watch(typedNameV2<SuspensionSnapshot>('hasSuspendedForms')) as boolean;

  const startRemoveFolderLockProcess = useCallback(() => {
    confirm({
      title: t('proceeding:action.resumeIssuing.modalTitle'),
      message: t('proceeding:action.resumeIssuing.confirmMessage'),
      onConfirm: (setLoading, closeDialog) => {
        setLoading(true);
        removeFolderLock(
          {
            folderLockId: driverCertificateSuspension.id,
            version: driverCertificateSuspension.version
          },
          {
            onSuccess: () => {
              showSuccessSnackbar(t('proceeding:action.resumeIssuing.successMessage'));
              queryCache.invalidateQueries(ProceedingQueryKeysEnum.PROCEEDING_DETAILS);
              closeDialog();
            },
            onSettled: () => {
              setLoading(false);
            }
          }
        );
      },
      confirmType: 'danger'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    confirm,
    driverCertificateSuspension?.id,
    driverCertificateSuspension?.version,
    removeFolderLock,
    t,
    showSuccessSnackbar
  ]);

  return (
    <>
      <TableButtonMore>
        {close => (
          <>
            {checkIsElementVisible(actionKeys?.checkQualifications) && (
              <TableButton
                label={t('suspensions:formOperations.action.checkQualifications')}
                onClick={() => {
                  close();
                  openCheckQualificationsDialog(listedPermissions);
                }}
              />
            )}
            {checkIsElementVisible(DomainUIElementEnum.SUSPENSIONS_FORM_OPERATIONS_RESUME_BUTTON) &&
              hasSuspendedForms &&
              !proceedingTypeKey &&
              isFormOperationsAvailable && (
                <TableButton
                  label={t('suspensions:formOperations.action.postpone')}
                  onClick={() => {
                    close();
                    handlePostponeSuspensionFormOperation();
                  }}
                />
              )}
            {checkIsElementVisible(DomainUIElementEnum.SUSPENSIONS_FORM_OPERATIONS_SUSPEND_BUTTON) &&
              !proceedingTypeKey &&
              isFormOperationsAvailable && (
                <TableButton
                  label={t('suspensions:formOperations.action.suspend')}
                  onClick={() => {
                    close();
                    handleSuspendSuspensionFormOperation();
                  }}
                />
              )}

            {checkIsElementVisible(actionKeys?.suspendIssuing) &&
              isAdministrativeProceeding &&
              !driverCertificateSuspension && (
                <TableButton
                  label={t('proceeding:action.suspendIssuing.buttonContent')}
                  onClick={() => {
                    openAddOrUpdateLockDialog(FormMode.CREATE, {
                      folderId
                    });
                    close();
                  }}
                />
              )}
            {(checkIsElementVisible(actionKeys?.resumeIssuing) || checkIsElementVisible(actionKeys?.editIssuing)) &&
              driverCertificateSuspension && (
                <>
                  {checkIsElementVisible(actionKeys?.resumeIssuing) && (
                    <TableButton
                      label={t('proceeding:action.resumeIssuing.buttonContent')}
                      onClick={() => {
                        close();
                        startRemoveFolderLockProcess();
                      }}
                    />
                  )}
                  {checkIsElementVisible(actionKeys?.editIssuing) && (
                    <TableButton
                      label={t('proceeding:action.editIssuing.buttonContent')}
                      onClick={() => {
                        close();
                        openAddOrUpdateLockDialog(FormMode.EDIT, {
                          id: driverCertificateSuspension.id,
                          validFrom: convertCalendarDate(driverCertificateSuspension.validFrom),
                          validTo: convertCalendarDate(driverCertificateSuspension.validTo),
                          version: driverCertificateSuspension.version,
                          typeKey: driverCertificateSuspension.typeKey,
                          folderId
                        });
                      }}
                    />
                  )}
                </>
              )}
          </>
        )}
      </TableButtonMore>
      {!whole && (
        <Button
          onClick={openCreateFormDialog}
          variant="outlined"
          label={t('proceeding:action.addFormOperation.buttonContent')}
          actionKey={actionKeys?.addFormOperation}
        />
      )}
    </>
  );
}

export default FormOperationsHeader;
