import { DriversApiCreateDriverRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function addDriverClient(requestData: DriversApiCreateDriverRequest) {
  return API.client.drivers.createDriver('', '', requestData.driverCreateRequest, { ...endpointsConfig.createDriver });
}

function addDriver(requestData: DriversApiCreateDriverRequest) {
  return API.drivers.createDriver(requestData.driverCreateRequest, {
    ...endpointsConfig.createDriver
  });
}

function useAddDriverMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(addDriverClient, addDriver));
}

export default useAddDriverMutation;
