import { useParams } from 'react-router-dom';
import { SuspensionSnapshot } from '@ibtm/domain';

import { useFormV2Context, useRouter, useTableRowActions } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import { FolderDetailsParams, FolderDetailsTabEnum } from '@libs/domain/folder';
import { SuspensionQueryKeysEnum } from '@libs/domain/suspensions';
import { useSuspensionActions } from '@libs/domain/suspensions/hooks';

function useSuspensionRowActions() {
  const { folderId } = useParams<FolderDetailsParams>();
  const { formMode } = useFormV2Context();
  const { editMode } = useViewModesV2(formMode);
  const { isOperatorPortal } = useDomainConfigContext();
  const { routes } = useRouter();
  const queryCache = useQueryCache();
  const { startDeletingProcess, isLoading } = useSuspensionActions(null, () => {
    queryCache.invalidateQueries([SuspensionQueryKeysEnum.SUSPENSION_LIST]);
  });

  const { renderTableActions } = useTableRowActions<SuspensionSnapshot>([
    {
      id: DomainUIElementEnum.SUSPENSIONS_VIEW_BUTTON,
      label: 'action.show',
      icon: 'ArrowIcon',
      link({ original }) {
        return {
          pathname: routes.suspensionDetails(folderId, original?.id),
          state: {
            from: `${editMode ? routes.folderEdit(folderId) : routes.folderDetails(folderId)}?tab=${
              FolderDetailsTabEnum.SUSPENSIONS
            }`
          }
        };
      }
    },
    {
      id: DomainUIElementEnum.SUSPENSIONS_EDIT_BUTTON,
      label: 'action.edit',
      icon: 'EditIcon',
      isLoading,
      link({ original }) {
        return {
          pathname: routes.suspensionEdit(folderId, original?.id),
          state: {
            from: `${editMode ? routes.folderEdit(folderId) : routes.folderDetails(folderId)}?tab=${
              FolderDetailsTabEnum.SUSPENSIONS
            }`
          }
        };
      },
      isHidden: !isOperatorPortal
    },
    {
      id: DomainUIElementEnum.SUSPENSIONS_DELETE_BUTTON,
      label: 'action.delete',
      icon: 'TrashIcon',
      onClick: ({ row: { original } }) => {
        startDeletingProcess(original.id);
      },
      isLoading,
      isHidden: !isOperatorPortal
    }
  ]);

  return { renderTableActions };
}

export default useSuspensionRowActions;
