import useAssociationOrderRangesColumns from '../columns/useAssociationOrderRangesColumns';

function useAssociationOrderRangesTable() {
  const columns = useAssociationOrderRangesColumns();
  return {
    tableHookOptions: {
      columns
    }
  };
}

export default useAssociationOrderRangesTable;
