import { RenounceCopyDeleteRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

function deleteMutation({
  applicationId,
  renounceCopyDeleteRequest
}: {
  applicationId: string;
  renounceCopyDeleteRequest: RenounceCopyDeleteRequest;
}) {
  return API.renounceCopies.deletePermissionAndLicenceCopiesFromRenounceCopy(applicationId, renounceCopyDeleteRequest);
}

function useDeleteRenounceCopyMutation({ onSuccess }) {
  return useMutation(deleteMutation, onSuccess);
}

export default useDeleteRenounceCopyMutation;
