import moment from 'moment/moment';

import { DATE_TIME_FORMAT } from '@libs/common/v2';
import { DateRange } from '@libs/common/v2/utils';

export const filterUtil = {
  inDateRange: (value: string, filterValue?: DateRange): boolean => {
    if (filterValue) {
      const valueDate = value.includes(',') ? moment(value, DATE_TIME_FORMAT) : moment(value);
      if (filterValue.dateFrom && filterValue.dateTo) {
        return !value || valueDate.isBetween(filterValue.dateFrom, filterValue.dateTo, 'day', '[]');
      }
      if (filterValue.dateTo) {
        return !value || valueDate.isBefore(filterValue.dateTo);
      }
    }
    return true;
  }
};
