import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthSelector } from '@libs/auth/store/reducers';

import { AppConfigContext, IAppConfigContext } from '../context';

function useAppConfig(isDevMode?: boolean) {
  const [t] = useTranslation();
  const { config, isFetching, setMainPage } = useContext(AppConfigContext) as IAppConfigContext;
  const { user } = useAuthSelector(({ auth }) => auth);
  const generateApplicationPrintouts = config.app?.generateApplicationPrintouts;
  const { hideColumnPicker, hideCompactToggle } = config?.common?.table || {};
  const { hideVersionPrefix, hidePortalOwner, isBuildVersionVisible, showFooterAfterLogin } =
    config?.common?.footer || {};
  const { reportTypes } = config?.report || {};
  const templateNames = config?.templates?.names;
  const mainPage = config?.app?.mainPage;
  const superusers = config?.app?.superusers;
  const { showSystemNotifications, showNotifications } = config?.common?.topBar || {};
  let { showDevModeSwitch } = config?.common?.topBar || {};
  let { isReportTypeManagementEnable } = config?.report || {};
  const isSuperUser = superusers?.includes(user?.accountData?.login);
  const showDictionaryKeys = config?.app?.showDictionaryKeys;
  const isPermissionKeys = config?.app?.isPermissionKeys;
  const defaultDevMode = config?.app?.defaultDevMode;
  const captchaKey = config?.app?.captchaKey;
  const showCaptcha = config?.app?.showCaptcha;
  const showReactQueryDevtools = config?.app?.showReactQueryDevtools;
  const baseUrl = config?.api?.baseUrl;
  const areEkmtLimitsHidden = config?.app?.areEkmtLimitsHidden;
  const areSingleAuthorizationLimitsHidden = config?.app?.areSingleAuthorizationLimitsHidden;
  const resendEmailConfirmationVisible = config?.administration?.resendEmailConfirmationVisible;
  const shouldFetchNotificationsFromWebsockets = config?.app?.shouldFetchNotificationsFromWebsockets;
  const shouldFetchNotificationsOutsideWebsockets = config?.app?.shouldFetchNotificationsOutsideWebsockets;
  const appOwnerName = t('app:portalOwner');

  if (isDevMode) {
    isReportTypeManagementEnable = true;
  }
  if (isSuperUser) {
    isReportTypeManagementEnable = true;
    showDevModeSwitch = true;
  }

  return {
    hideColumnPicker,
    hideCompactToggle,
    hideVersionPrefix,
    hidePortalOwner,
    appOwnerName,
    isReportTypeManagementEnable,
    showDevModeSwitch,
    reportTypes,
    templateNames,
    mainPage,
    superusers,
    isFetching,
    showDictionaryKeys,
    isPermissionKeys,
    defaultDevMode,
    captchaKey,
    showCaptcha,
    showReactQueryDevtools,
    baseUrl,
    isBuildVersionVisible,
    showSystemNotifications,
    showNotifications,
    raw: config,
    isDevModeEnabled: isSuperUser,
    setMainPage,
    showFooterAfterLogin,
    areEkmtLimitsHidden,
    areSingleAuthorizationLimitsHidden,
    resendEmailConfirmationVisible,
    generateApplicationPrintouts,
    shouldFetchNotificationsFromWebsockets,
    shouldFetchNotificationsOutsideWebsockets
  };
}

export default useAppConfig;
