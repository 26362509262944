import React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonLinkDefinition } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

import TableIconButton from './TableIconButton';

interface IProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  tooltipTitle?: string;
  className?: string;
  isDisabled?: boolean;
  isButtonLoading?: boolean;
  actionKey?: UIElementNameEnum;
  link?: ButtonLinkDefinition;
}

function TableButtonView({ isDisabled, tooltipTitle, className, isButtonLoading, onClick, actionKey, link }: IProps) {
  const [t] = useTranslation();

  return (
    <TableIconButton
      tooltipTitle={tooltipTitle || t('action.openDetails')}
      className={className}
      icon="ArrowIcon"
      isButtonLoading={isButtonLoading}
      onClick={onClick}
      isDisabled={isDisabled}
      actionKey={actionKey}
      link={link}
    />
  );
}

export default React.memo(TableButtonView);
