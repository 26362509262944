import { SCertificateDetails } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import { DictionarySelectField, GridLayout, TextInputField, typedNameV2 } from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';

export function VehicleDetails() {
  const getLabel = partialTranslate(`sCertificates:field`);
  return (
    <GridLayout itemProps={{ md: 6, xs: 12 }}>
      <TextInputField
        name={typedNameV2<SCertificateDetails>('vehicleBrandKeyTranslation')}
        label={getLabel('vehicleBrand')}
      />
      <TextInputField
        name={typedNameV2<SCertificateDetails>('vehicleModelKeyTranslation')}
        label={getLabel('modelKey')}
      />
      <TextInputField
        name={typedNameV2<SCertificateDetails>('vehiclePlateNumber')}
        label={getLabel('vehicleRegistrationNumber')}
      />
      <TextInputField name={typedNameV2<SCertificateDetails>('vehicleVin')} label={getLabel('vehicleVin')} />
      <DictionarySelectField
        dictionaryName={DomainDictionaryEnum.VEHICLE_TYPE}
        name={typedNameV2<SCertificateDetails>('vehicleType')}
        label={getLabel('vehicleType')}
      />
      <TextInputField name={typedNameV2<SCertificateDetails>('vehicleEngineType')} label={getLabel('engineType')} />
      <TextInputField name={typedNameV2<SCertificateDetails>('vehicleEngineNumber')} label={getLabel('engineNumber')} />
      <TextInputField
        name={typedNameV2<SCertificateDetails>('firstRegistrationDate')}
        label={getLabel('firstRegistrationDate')}
      />
    </GridLayout>
  );
}
