import { ReportTypeCreateRequest } from '@stack/report';
import {
  DataSourceMediatorDetailsSnapshot,
  HintType,
  QueryColumnProperties,
  QueryCondition,
  QueryOrder,
  QueryParameter,
  ReportCreateRequest,
  ReportDataSetDefinitionCreateRequest,
  ReportDataSetDefinitionUpdateRequest,
  ReportTypeDetailsSnapshot,
  ReportTypeUpdateRequest
} from '@stack/report/dist/models';
import { ParameterExtras } from '@stack/report/dist/models/parameter-extras';
import _ from 'lodash';

import { SelectOption } from '@libs/common/v2';
import { pick, removeKeyForEmptyValue } from '@libs/common/v2/utils';

import { ColumnType } from '@libs/report-core/config';
import { ConditionOperator } from '@libs/report-core/model';

import { CommonFieldsType, ExtendedQueryConditionParameter } from '../model';
import {
  DataSetDefinition,
  DocumentTemplateSnapshot,
  ReportFormNamedObject,
  ReportFormQueryCondition,
  ReportType,
  ReportTypeForm
} from '../model/form.model';

const getKeys = (obj: Record<string, any>, results: string[] = []) => {
  if (!obj) {
    return [];
  }
  const r = results;
  Object.keys(obj).forEach((key, i, arr) => {
    const object = obj[key] as Record<string, any>;
    if (arr.length === 1) {
      r.push(key);
      getKeys(object, r);
    }
  });
  return r;
};

const getNestedValue = (nestedObj, pathArr) => {
  return pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : null), nestedObj);
};

const parseObjectToStringArrayInCommonDynamicParameters = (
  values: Record<string, SelectOption<any> | any>,
  fields: Array<CommonFieldsType>,
  parameterExtras: Array<ParameterExtras>
) => {
  const formattedValues = Object.keys(values).map(field => {
    let fieldName = field;
    let fieldValue = values[field];
    const hasHintValues = parameterExtras.some(({ displayName, hint }) => {
      if (displayName === fieldName) {
        return !_.isNil(hint);
      }

      return false;
    });

    let commonFieldItem = _.first(fields.filter(commonField => commonField?.name === fieldName));
    /**
     * Jeśli commonFieldItem nie istnieje to prawdopodobnie nazwa korzysta z '.', co powoduje zagnieżdżenie obiektu. Sprawdzamy to i ustawiamy prawidłową wartość
     */
    if (!commonFieldItem && typeof fieldValue === 'object') {
      const valueObjectKeys = getKeys(fieldValue as Record<string, any>);
      fieldName = `${fieldName}.${valueObjectKeys.join('.')}`;
      commonFieldItem = _.first(fields.filter(commonField => commonField?.name === fieldName));
      fieldValue = getNestedValue(values, fieldName.split('.'));
    }

    /**
     * Sprawdzamy czy dane pole istnieje w obiekcie
     */
    if (!_.isNil(fieldValue) && (!_.isArray(fieldValue) || !_.isEmpty(fieldValue))) {
      /**
       * Upewniamy się, że wartość tego pola jest tablicą,
       * a następnie konwertujemy tablice obiektów na tablice wartości (value)
       * z pominięciem null i undefined
       */
      if (_.isArray(fieldValue)) {
        return {
          [fieldName]: JSON.stringify(
            fieldValue
              ?.filter(element => element !== undefined && element !== null)
              ?.map(element => element?.value || element?.inputValue || element) ?? []
          )
        };
      }

      /**
       * Sprawdzamy czy typ pola jest typu `BOOLEAN` (zapisane jako string)
       * Następnie sprawdzamy wartość dla tego pola
       * i jeżeli wartości boolean są zapisane jako string, to zamieniamy je
       */
      if (typeof fieldValue === 'string' && commonFieldItem?.type === ColumnType.BOOLEAN) {
        if (fieldValue === 'null') {
          return null;
        }

        return {
          [fieldName]: fieldValue === 'true'
        };
      }

      /**
       * W przypadku gdy wartość pola jest stringiem lub boolean,
       * to zwrcamy wyłacznie string/boolean dla danego pola
       * Sprawdzamy tutaj też czy została zaznaczona wartość `Nie dotyczy` w komponencie ToggleButton
       * i jeżeli jest, to nie zwracamy żadnej wartości
       */
      if (typeof fieldValue === 'string' && commonFieldItem?.type !== ColumnType.BOOLEAN) {
        return {
          [fieldName]: fieldValue.trim()
        };
      }

      if (_.isBoolean(fieldValue)) {
        return {
          [fieldName]: fieldValue
        };
      }

      if (typeof fieldValue === 'object' && commonFieldItem?.type === ColumnType.BOOLEAN && !hasHintValues) {
        if (typeof fieldValue?.value === 'string') {
          if (fieldValue?.value === 'null') {
            return null;
          }

          return {
            [fieldName]: fieldValue?.value === 'true'
          };
        }

        return { [fieldName]: fieldValue?.value };
      }

      /**
       * Natomiast wyciągamy value (albo inputValue)
       * jezeli fieldValue to obiekt (select z pojedynczym wyborem)
       */
      return {
        [fieldName]: _.isNil(fieldValue?.value) ? fieldValue?.selectValue : fieldValue?.value
      };
    }

    return null;
  });
  return formattedValues.filter(val => val !== null);
};

export const parseCommonDynamicParametersValues = (
  values: { [key: string]: any },
  fields: Array<{ name: string; type: string }>,
  parameterExtras?: ParameterExtras[]
) => {
  const arrayValues = parseObjectToStringArrayInCommonDynamicParameters(values, fields, parameterExtras);
  return Object.assign({}, ...arrayValues);
};

export function reportTypeDetailsToReportCreateRequest(
  reportType: ReportTypeDetailsSnapshot,
  values: { [key: string]: any },
  fields: Array<{ name: string; type: string }>,
  isTechnical?: boolean
): ReportCreateRequest {
  return {
    reportTypeId: reportType.id,
    description: reportType.description,
    targetTypes: values.targetTypes as unknown as Array<string>,
    technical: isTechnical,
    commonDynamicParametersValues: parseCommonDynamicParametersValues(
      _.omit(removeKeyForEmptyValue(values), 'targetTypes'),
      fields,
      reportType.parameterExtras
    )
  };
}

function parseConditionFilterToFormValue(condition: ReportFormQueryCondition) {
  const conditionTemp: ReportFormQueryCondition = condition;

  conditionTemp.filter.column = {
    id: condition.filter.column.id || condition.filter.column,
    value: condition.filter.column.value || condition.filter.column
  };
  conditionTemp.filter.operator = {
    id: condition.filter.operator.id || condition.filter.operator,
    value: condition.filter.operator.value || condition.filter.operator
  };

  return conditionTemp;
}

export function parseConditionsToFormValue(
  conditions?: QueryCondition[]
): QueryCondition | ReportFormQueryCondition | null {
  if (!conditions) {
    return null;
  }

  return {
    operator: { value: conditions[conditions.length - 1]?.operator || ConditionOperator.AND } as ReportFormNamedObject,
    filter: null,
    group: conditions.reduce((acc, condition) => {
      if (condition.filter) {
        acc.push(parseConditionFilterToFormValue(condition as ReportFormQueryCondition));
      } else {
        const parsed = condition.group?.length ? parseConditionsToFormValue(condition.group) : null;
        if (parsed) {
          acc.push(parsed);
        }
      }
      return acc;
    }, [])
  };
}

const hasConditionDefined = (conditions: QueryCondition[]) =>
  conditions.some(condition =>
    condition.group?.length ? hasConditionDefined(condition.group) : Boolean(condition.filter)
  );

function parseConditionsGroup(
  conditions: QueryCondition[],
  groupOperator: string,
  columns: Set<string>,
  dynamicParameters?: Set<string>,
  sourceId?: string
): QueryCondition[] {
  return conditions.reduce((acc, condition) => {
    if (!condition.group?.length && !condition.filter) {
      return acc;
    }
    if (condition.filter?.column) {
      columns.add(condition.filter.column);
    }
    if (dynamicParameters && condition.filter?.parameters?.length) {
      condition.filter.parameters.forEach(({ dynamic, displayName }: ExtendedQueryConditionParameter) => {
        return dynamic && dynamicParameters.add(displayName);
      });
    }
    if (condition.group?.length === 1) {
      if (hasConditionDefined(condition.group)) {
        acc.push({
          ...condition.group[0],
          group: condition.group[0].group?.length
            ? parseConditionsGroup(condition.group[0].group, condition.operator, columns, dynamicParameters, sourceId)
            : null,
          operator: acc.length ? groupOperator : null
        });
      }
      return acc;
    }

    if (!condition.group?.length || hasConditionDefined(condition.group)) {
      acc.push({
        ...condition,
        group: condition.group?.length
          ? parseConditionsGroup(condition.group, condition.operator, columns, dynamicParameters, sourceId)
          : null,
        operator: acc.length ? groupOperator : null
      });
    }
    return acc;
  }, [] as QueryCondition[]);
}

export function parseQueryDataToRequest(
  definition: { columns: QueryColumnProperties[]; groups: string[]; distinct?: boolean },
  queryConditions: QueryCondition[],
  columns?: QueryColumnProperties[],
  dynamicParametersSet?: Set<string>,
  sourceId?: string
): {
  definition: { columns: QueryColumnProperties[]; groups: string[]; distinct?: boolean };
  conditions: QueryCondition[];
} | null {
  const columnsSet = new Set<string>();

  const conditions = parseConditionsGroup(
    queryConditions[0].group,
    queryConditions[0].operator,
    columnsSet,
    dynamicParametersSet,
    sourceId
  );

  return {
    definition: {
      ...definition,
      columns: columns || (Array.from(columnsSet).map(name => ({ name })) as QueryColumnProperties[])
    },
    conditions
  };
}

export function removeDynamicParameters(conditions: QueryCondition[]): QueryCondition[] {
  return conditions.reduce((acc, condition) => {
    if (condition.group?.length) {
      acc.push({
        ...condition,
        group: removeDynamicParameters(condition.group)
      });
    } else if (!condition.filter?.parameters?.some(({ dynamic }) => dynamic)) {
      acc.push(condition);
    }
    return acc;
  }, [] as QueryCondition[]);
}

export function parseQueryParameters(parameters: QueryParameter[], onlyDynamic = false) {
  return parameters.reduce((acc, parameter, index) => {
    if (!onlyDynamic || parameter.dynamic) {
      acc.push({
        filter: {
          column: parameter.columnKey,
          operator: '= ?',
          parameters: [
            {
              dynamic: parameter.dynamic,
              displayName: parameter.displayName,
              value: parameter.value
            }
          ]
        },
        operator: index > 0 ? ConditionOperator.AND : undefined
      });
    }
    return acc;
  }, [] as QueryCondition[]);
}

export function parseQueryConditions(conditions: Array<QueryCondition & ReportFormQueryCondition>) {
  return conditions.map(condition => {
    const parameter: any = condition?.filter?.parameter;
    const parsedParameter = { ...parameter, displayName: parameter?.displayName?.value ?? null };

    return {
      ...condition,
      operator: condition?.operator?.value ?? condition?.operator,
      filter: condition?.filter && {
        column: condition?.filter?.column?.value,
        operator: condition?.filter?.operator?.value,
        parameter: parsedParameter
      },
      ...(condition.group ? { group: parseQueryConditions(condition.group) } : {})
    };
  }, [] as QueryCondition[]);
}

export function formValuesToDatasetDefinitionRequest(
  dataSet: DataSetDefinition
): ReportDataSetDefinitionUpdateRequest & ReportDataSetDefinitionCreateRequest {
  const conditionsDefined = dataSet?.queryConditions?.[0]?.group?.length;

  const dynamicParametersSet = new Set<string>();
  const queryRequest = parseQueryDataToRequest(
    dataSet.queryDefinition,
    dataSet.queryConditions,
    dataSet.queryDefinition?.columns,
    dynamicParametersSet,
    dataSet.source?.id
  );

  return {
    ...pick(dataSet, 'name', 'description', 'dbQueryPreparation'),
    queryParameters: dataSet.queryParameters.map(queryParameter => ({
      ...queryParameter,
      columnKey: _.isString(queryParameter.columnKey)
        ? queryParameter.columnKey
        : (queryParameter.columnKey as SelectOption).value
    })),
    sourceId: dataSet.source?.id,
    dataFileFormatKey: dataSet?.dataFileFormatKey?.value ?? dataSet.dataFileFormatKey,
    dataDefinition:
      dataSet?.dataDefinition && dataSet?.basicMode
        ? {
            properties: dataSet.dataDefinition?.properties
          }
        : null,
    query: dataSet?.basicMode ? null : dataSet.query || null,
    queryDefinition: dataSet?.basicMode
      ? queryRequest?.definition ||
        (dataSet.query
          ? null
          : {
              columns: dataSet.queryDefinition?.columns || [],
              orders: dataSet.queryDefinition?.orders || []
            })
      : null,
    queryConditions:
      conditionsDefined && dataSet?.basicMode
        ? parseQueryConditions(queryRequest?.conditions) || parseQueryParameters(dataSet.queryParameters)
        : []
  } as any;
}

function formValuesToReportTypeRequest(formValues) {
  const parsedParamExtras = () =>
    formValues.parameterExtras.map(param => {
      const getHint = () => {
        if (param.hintValue) {
          const parsedHintValues = param?.hint?.values?.map(hint => {
            return hint.value;
          });

          switch (param?.hint?.type) {
            case HintType.VALUES:
              return {
                values: parsedHintValues,
                type: param?.hint?.type,
                defaultValue: param?.hint?.defaultValue,
                requireAllParameterValues: param?.hint?.requireAllParameterValues
              };

            case HintType.DICTIONARY:
              return {
                dictionaryName: _.isString(param.hint.dictionaryName)
                  ? param.hint.dictionaryName
                  : param.hint.dictionaryName?.value,
                type: param?.hint?.type,
                defaultValue: param?.hint?.defaultValue,
                requireAllParameterValues: param?.hint?.requireAllParameterValues,
                ...(param?.hint?.defaultValue
                  ? {
                      dictionaryKeys: Array.isArray(param?.hint?.dictionaryKeys)
                        ? param.hint.dictionaryKeys.map(key => key?.value)
                        : [param?.hint?.dictionaryKeys?.value]
                    }
                  : {})
              };
            case HintType.COLUMN:
              return {
                sourceId: _.isString(param.hint.sourceId) ? param.hint.sourceId : param.hint.sourceId.id,
                columnKey: _.isString(param.hint.columnKey) ? param.hint.columnKey : param.hint.columnKey.value,
                type: param?.hint?.type,
                defaultValue: param?.hint?.defaultValue,
                requireAllParameterValues: param?.hint?.requireAllParameterValues
              };
            case HintType.QUERY:
              return {
                query: param.hint.query,
                type: param?.hint?.type,
                defaultValue: param?.hint?.defaultValue,
                requireAllParameterValues: param?.hint?.requireAllParameterValues
              };
            default:
              return param.hint;
          }
        }
        return null;
      };
      const requiredParamNames = param.requiredParamNames?.map(requiredParam => {
        return requiredParam.name;
      });
      return {
        ...pick(param, 'checkbox', 'groupName', 'index', 'multiValue', 'selectOnly', 'required'),
        displayName: param.displayName.trim(),
        queryName: param.queryName.trim(),
        type: param.type?.name || param.type,
        enabledByParamName: param.enabledByParamName?.name,
        disabledByParamNames: param.disabledByParamNames,
        requiredParamNames,
        validator: param.validator?.value,
        hint: getHint()
      };
    });

  return {
    ...pick(formValues, 'description', 'cron'),
    name: formValues?.name || '',
    permissionKeys: formValues.permissionKeys?.map(({ value }) => value),
    documentTemplateId: formValues.documentTemplate?.id,
    sourceId: formValues.source?.id || formValues.dataSetDefinitions?.[0]?.source?.id,
    parameterExtras: parsedParamExtras()
  };
}

function formValuesToEditRequest(formValues: ReportTypeForm, version: number): ReportTypeUpdateRequest {
  const newDataSets = formValues.dataSetDefinitions.filter(dataSet => !dataSet.dataSetId);

  return {
    ...formValuesToReportTypeRequest(formValues),
    dataSetDefinitions: newDataSets.map((dataSet: ReportTypeForm['dataSetDefinitions'][number]) => {
      return {
        ...formValuesToDatasetDefinitionRequest(dataSet)
      };
    }),
    name: formValues.name,
    dataSetDefinitionUpdateRequests:
      formValues.dataSetDefinitions?.reduce((acc, dataSet) => {
        const accTemp = acc;

        if (!dataSet.dataSetId) {
          return accTemp;
        }
        accTemp[dataSet.dataSetId] = {
          ...formValuesToDatasetDefinitionRequest(dataSet),
          version: dataSet?.version || 0
        };
        return accTemp;
      }, {} as { [key: string]: ReportDataSetDefinitionUpdateRequest }) || null,
    version
  };
}

function parseDataDefinitionColumns(columns: QueryColumnProperties[], orders?: QueryOrder[]): QueryColumnProperties[] {
  return columns.map(column => {
    const sortDirection = orders?.find(columnOrder => columnOrder.column === column.name)?.direction;
    return {
      name: column.name,
      columnAggregationKey: column.columnAggregationKey,
      sort: sortDirection || null
    };
  });
}

export function reportTypeDetailsToInitialFormValues(
  details: ReportTypeDetailsSnapshot,
  defaultValues: ReportTypeForm,
  sources: DataSourceMediatorDetailsSnapshot[],
  documentTemplateSnapshot?: DocumentTemplateSnapshot
): ReportTypeForm {
  const parsedParamExtras = () =>
    details.parameterExtras.map(param => {
      const getHint = () => {
        const parsedHintValues = param?.hint?.values?.map(hint => {
          return { value: hint, name: hint };
        });
        switch (param?.hint?.type) {
          case HintType.VALUES:
            return {
              values: parsedHintValues,
              type: param?.hint?.type,
              defaultValue: param?.hint?.defaultValue,
              requireAllParameterValues: param?.hint?.requireAllParameterValues
            };

          case HintType.DICTIONARY:
            return {
              dictionaryName: param.hint.dictionaryName,
              type: param?.hint?.type,
              defaultValue: param?.hint?.defaultValue,
              requireAllParameterValues: param?.hint?.requireAllParameterValues,
              ...(param?.hint?.dictionaryKeys
                ? {
                    dictionaryKeys: param?.multiValue
                      ? param?.hint?.dictionaryKeys?.map(key => ({ value: key }))
                      : { value: param?.hint?.dictionaryKeys[0] }
                  }
                : {})
            };
          case HintType.COLUMN:
            return {
              sourceId: param.hint.sourceId,
              columnKey: param.hint.columnKey,
              type: param?.hint?.type,
              defaultValue: param?.hint?.defaultValue,
              requireAllParameterValues: param?.hint?.requireAllParameterValues
            };
          case HintType.QUERY:
            return {
              query: param.hint.query,
              type: param?.hint?.type,
              defaultValue: param?.hint?.defaultValue,
              requireAllParameterValues: param?.hint?.requireAllParameterValues
            };
          default:
            return param.hint;
        }
      };

      const requiredParamNames = param.requiredParamNames?.map(requiredParam => {
        return { name: requiredParam, value: requiredParam };
      });

      return {
        ...pick(
          param,
          'checkbox',
          'displayName',
          'groupName',
          'index',
          'multiValue',
          'type',
          'queryName',
          'selectOnly',
          'required',
          'disabledByParamNames'
        ),
        id: param.displayName,
        enabledByParamName: { name: param.enabledByParamName, value: param.enabledByParamName },
        requiredParamNames,
        hint: getHint(),
        hintValue: !!param.hint,
        validator: param.validator ? { name: param.validator, value: param.validator } : null
      };
    });

  return {
    ...defaultValues,
    ...pick(details, 'name', 'description', 'cron', 'documentTemplateId'),
    parameterExtras: parsedParamExtras(),
    documentTemplate: documentTemplateSnapshot,
    permissionKeys: details.permissionKeys?.map(permission => {
      return { name: permission, value: permission };
    }),
    dataSetDefinitions:
      details.dataSetDefinitions?.map((dataSetDef, index) => {
        const hasQueryDefinition = dataSetDef.queryDefinition;
        return {
          ...dataSetDef,
          dataSetId: dataSetDef.id,
          source: sources[index]
            ? { name: sources[index].name, id: sources[index].id, value: { ...sources[index] } }
            : { name: dataSetDef.source.name, id: dataSetDef.source.id, value: { ...dataSetDef.source } },
          queryDefinition: hasQueryDefinition
            ? {
                ...dataSetDef.queryDefinition,
                columns: dataSetDef.queryDefinition.columns?.length
                  ? parseDataDefinitionColumns(
                      dataSetDef.queryDefinition?.columns || [],
                      dataSetDef.queryDefinition?.orders
                    )
                  : []
              }
            : {
                columns: [],
                orders: dataSetDef?.queryDefinition?.orders || [],
                groups: []
              },
          queryConditions: hasQueryDefinition
            ? [parseConditionsToFormValue(dataSetDef.queryConditions || [])]
            : [
                {
                  filter: null,
                  group: [],
                  operator: ConditionOperator.AND
                }
              ]
        };
      }) || defaultValues.dataSetDefinitions
  } as any;
}

export const ReportTypeCreateConverterV2 = {
  formToApi: (formValues: ReportTypeForm): ReportTypeCreateRequest => {
    const request: ReportTypeCreateRequest = {
      ...formValuesToReportTypeRequest(formValues),
      dataSetDefinitions: formValues.dataSetDefinitions?.map(dataSetDefinition =>
        formValuesToDatasetDefinitionRequest(dataSetDefinition)
      )
    };

    if (request.documentTemplateId === '') {
      _.unset(request, 'documentTemplateId');
    }

    return request;
  }
};

export const ReportTypeEditConverter = {
  apiToForm: (
    details: ReportTypeDetailsSnapshot,
    defaultValues: ReportTypeForm,
    sources: DataSourceMediatorDetailsSnapshot[],
    documentTemplateSnapshot?: DocumentTemplateSnapshot
  ) => reportTypeDetailsToInitialFormValues(details, defaultValues, sources, documentTemplateSnapshot),
  formToApi: (formValues: ReportTypeForm, version: number): ReportTypeUpdateRequest => {
    return formValuesToEditRequest(formValues, version);
  }
};

export const parseReportTypesList = (reportTypes: ReportTypeDetailsSnapshot[]): ReportType[] => {
  return reportTypes.map(reportType => ({
    ...reportType,
    sourceName: reportType.dataSetDefinitions.map(({ source }) => source?.name).join(', ')
  }));
};
