import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AccountLite } from '@avispon/user';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';

import {
  AutocompleteLazyFetchSelectField,
  Button,
  Dialog,
  FormV2Context,
  GridItem,
  GridLayout,
  SelectOption,
  typedNameV2
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { API } from '@libs/organization-structure/api';

import { DomainUIElementEnum } from '@libs/domain/config';
import { MixedAccountsSearchRoles } from '@libs/domain/models';

import {
  ResourceQueryKeysEnum,
  useAddDepotAdminsMutation,
  useDeleteDepotAdminsMutation,
  useDepotManagersQuery
} from '../../../api';
import { ChangeAdminsFormFields, IDepotsListClient } from '../../../model';
import { DepotAdminsTable } from '../../list';

interface IProps {
  initialData: Partial<IDepotsListClient>;
  closeDialog: () => void;
}

interface IDepotAdmin {
  id: string;
  value: { id: string; name: string };
  name: string;
}

function DepotAdminsChangeForm({ initialData, closeDialog }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const [loadingRemoveAdminId, setLoadingRemoveAdminId] = useState<string>(null);

  const { data: depotManagers, isLoading: isManagersLoading } = useDepotManagersQuery({ depotIdIn: [initialData.id] });
  const { mutate: addDepotAdmins, isLoading: isLoadingAdd } = useAddDepotAdminsMutation();
  const { mutate: deleteDepotAdmins } = useDeleteDepotAdminsMutation();
  const defaultQueryConfig = {
    onSuccess: () => {
      queryCache.invalidateQueries(ResourceQueryKeysEnum.DEPOTS_LIST);
      queryCache.invalidateQueries(ResourceQueryKeysEnum.DEPOTS_MANAGERS_LIST);
      queryCache.invalidateQueries([ResourceQueryKeysEnum.DEPOTS_MANAGERS, { depotIdIn: [initialData.id] }]);
      showSnackbar('success', t('resource:messages.changeAdminsSuccess'));
    },
    onSettled: () => {
      setLoadingRemoveAdminId(null);
    }
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    reset
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        admin: Yup.object().required().nullable()
      })
    )
  });

  const admin = watch(typedNameV2<ChangeAdminsFormFields>('admin')) as SelectOption<AccountLite>;
  const isAdminSelected = !!admin?.id;
  const admins = watch(typedNameV2<ChangeAdminsFormFields>('admins')) as IDepotAdmin[];

  useEffect(() => {
    if (depotManagers?.content?.length) {
      reset({
        admins: depotManagers?.content.map(depotData => ({
          id: depotData.depot.user.id,
          value: { id: depotData.depot.user.id, name: depotData.depot.user.name },
          name: depotData.depot.user.name
        }))
      });
    }
  }, [depotManagers, reset]);

  const handleAdd = (formData: ChangeAdminsFormFields) => {
    addDepotAdmins(
      {
        depotId: initialData.id,
        userIds: [formData?.admin?.id]
      },
      defaultQueryConfig
    );
  };

  const handleDelete = (adminId: string) => {
    setLoadingRemoveAdminId(adminId);
    deleteDepotAdmins(
      {
        depotId: initialData.id,
        userIds: [adminId]
      },
      defaultQueryConfig
    );
  };

  const optionLabelParser = data => {
    return data?.name || data?.surname ? `${data?.name} ${data?.surname} (${data?.serviceNumber})` : t('emptyMark');
  };

  const values = useMemo<Record<string, any>>(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('resource:dialog.resourceAdmins')}
      confirmText={t('action.close')}
      confirmButtonVariant="outlined"
      onCancel={closeDialog}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <GridLayout itemProps={{ xs: 12 }}>
          <GridItem xs={9}>
            <AutocompleteLazyFetchSelectField
              name={typedNameV2<ChangeAdminsFormFields>('admin')}
              label={t('resource:fields.chooseUser')}
              queryKey="DEPOT_ADMINISTRATORS"
              optionLabelParser={optionLabelParser}
              actionKey={DomainUIElementEnum.RESOURCES_DEPOT_ADD_ADMIN}
              api={{
                FETCH: (searchText: string, params: { page: number; size: number }) => {
                  const filteredRoles: MixedAccountsSearchRoles[] = [
                    'OPERATOR',
                    'OPERATOR_MANAGER',
                    'OPERATOR_TECHNICAL',
                    'ADMIN',
                    'CORPORATION'
                  ];

                  return API.user.accountController.searchLiteAccounts({
                    ...params,
                    excludedIds: admins?.map(item => item.id) ?? [],
                    query: searchText,
                    roles: filteredRoles,
                    sort: [],
                    statuses: ['VERIFIED']
                  });
                }
              }}
              isLoading={isManagersLoading || isLoadingAdd}
            />
          </GridItem>
          <GridItem xs={3} className={classes.buttonWrapper}>
            <Button
              onClick={handleSubmit(data => handleAdd(data as ChangeAdminsFormFields))}
              label={t('action.add')}
              isNoMargin
              disabled={!isAdminSelected}
              isLoading={isLoadingAdd}
              actionKey={DomainUIElementEnum.RESOURCES_DEPOT_ADD_ADMIN}
            />
          </GridItem>
          {depotManagers && (
            <DepotAdminsTable
              onDelete={handleDelete}
              depotId={initialData.id}
              loadingRemoveAdminId={loadingRemoveAdminId}
            />
          )}
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

const useStyles = makeStyles({
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  }
});

export default DepotAdminsChangeForm;
