export type Node = {
  typeKey?: keyof typeof NodeTypes;
  properties?: Record<string, unknown>;
  nodes?: Array<Node>;
  slotId?: string;
};

export type NodeVisibility = {
  elementVisibilityKeys?: Array<string>;
  elementVisibilityAsyncKeys?: Array<string>;
  params?: Record<string, unknown>;
};

export enum NodeTypes {
  COMPONENT = 'COMPONENT',
  ROW = 'ROW',
  COLUMN = 'COLUMN',
  TAB_NAVIGATION = 'TAB_NAVIGATION',
  TAB_CONTENT = 'TAB_CONTENT',
  TABS_CONTENT = 'TABS_CONTENT',
  SECTION = 'SECTION',
  FIELD = 'FIELD',
  GRID = 'GRID',
  FORM = 'FORM',
  CONDITIONALLY_VISIBLE = 'CONDITIONALLY_VISIBLE',
  WARNING = 'WARNING'
}

export type Component = {
  componentId: string;
  node?: Record<string, unknown>;
};

export type WarningApiProp = {
  check?: {
    // Metoda do sprawdzenia wyswietlania komponentu
    requestKey: string; // Klucz requesty z rejestru API przekazywanego w prop apiRegistry w komponencie Metaform
  };
};

export type ApiRegistryCheck = { request: () => Promise<boolean>; params: any };

export type AdditionalNodes = {
  [key: string]: Node[];
};
