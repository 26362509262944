import { actions, Hooks } from 'react-table';

import { useMutableTableInstance, useMutableTableReducer } from './mutableTableReducer';

// Actions
actions.editRow = 'editRow';
actions.cancelEditCreateRow = 'cancelEditCreateRow';
actions.addNewStartRow = 'addNewStartRow';
actions.addNewLastRow = 'addNewLastRow';
actions.addBeforeAfterRow = 'addBeforeAfterRow';

export const useMutableTable = <T extends Record<string, any> = Record<string, unknown>>(hooks: Hooks<T>) => {
  hooks.stateReducers.push(useMutableTableReducer);
  hooks.useInstance.push(useMutableTableInstance);
};

useMutableTable.pluginName = 'useMutableTable';

export default useMutableTable;
