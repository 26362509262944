import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FolderDetails, SubjectDetails } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import { GridLayout, Path, SelectOption, TextInputField, useFormV2Context, Value } from '@libs/common/v2';
import { FieldTypeEnum, useFieldValidationHandler, useViewModesV2 } from '@libs/common/v2/form';
import { convertCalendarDate } from '@libs/common/v2/utils';

import { useFormDirtyContext } from '@libs/meta-form/context';

import { useApplicationDetailsQuery } from '@libs/domain/application';
import { ApplicationDetailsPageParams } from '@libs/domain/application/model';
import { useFolderDetailsQuery } from '@libs/domain/folder';

import { FolderSelectField } from '../../application-create';

export interface IFolderDetailsFields {
  transferFolder?: Partial<FolderDetails>;
  folderTypeKeys?: string[];
  visibleFields: Array<Path<AdditionalSubjectField>>;
  isFolderRequired?: boolean;
}
interface AdditionalSubjectField extends FolderDetails {
  subject: SubjectDetails & { fullname: string };
}

const INPUT_NAME = 'general-tab-folder-details-fields';
const APPLICATION_DEATH_OF_SUBJECT_FIELD_NAME = 'application-date-of-subject-death';
const APPLICATION_DECEASED_NAME = 'application-deceased-name';
const APPLICATION_DECEASED_SURNAME = 'application-deceased-surname';

function FolderDetailsFields({
  transferFolder,
  visibleFields,
  folderTypeKeys,
  isFolderRequired
}: IFolderDetailsFields) {
  const [t] = useTranslation();
  const { applicationId } = useParams<ApplicationDetailsPageParams>();

  const getLabel = partialTranslate('applications:field');
  const { setValue, formMode } = useFormV2Context();
  const { setIsDirty } = useFormDirtyContext();
  const { viewMode } = useViewModesV2(formMode);
  const isFieldVisible = (name: Path<AdditionalSubjectField>) => visibleFields?.includes(name);

  const [searchFolder, setSearchFolder] = useState<FolderDetails>(null);
  const { data, isFetching } = useFolderDetailsQuery(searchFolder?.id || transferFolder?.id, {
    enabled: Boolean(transferFolder?.id || searchFolder),
    initialData: transferFolder,
    initialStale: true
  });

  const { data: applicationData } = useApplicationDetailsQuery(applicationId, { enabled: Boolean(applicationId) });

  const { name, nip, givenName, surname } = data?.subject ?? {};
  const { number, dateOfSubjectDeath } = data ?? {};

  useEffect(() => {
    if (transferFolder) {
      setValue(INPUT_NAME, [transferFolder.id]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferFolder]);

  useEffect(() => {
    if (data?.dateOfSubjectDeath) {
      setValue(APPLICATION_DEATH_OF_SUBJECT_FIELD_NAME, data.dateOfSubjectDeath);
    }
  }, [data, setValue]);

  useEffect(() => {
    if (applicationData?.licenseApplicationDetails?.transferApplicationDataDetails) {
      setValue(
        APPLICATION_DECEASED_NAME,
        applicationData?.licenseApplicationDetails?.transferApplicationDataDetails.deceasedName
      );
      setValue(
        APPLICATION_DECEASED_SURNAME,
        applicationData?.licenseApplicationDetails?.transferApplicationDataDetails.deceasedSurname
      );
    }
  }, [applicationData, setValue]);

  const handleSelectChange = (option?: SelectOption<FolderDetails>) => {
    setValue(INPUT_NAME, [option.value?.id]);
    setSearchFolder(option.value);
    setIsDirty(transferFolder?.id !== option.value?.id);
  };

  const onClear = () => {
    setValue(INPUT_NAME, null);
    setIsDirty(!!transferFolder?.id);
  };

  const subjectFullName = useMemo(() => {
    if (name) {
      return name;
    }

    if (givenName || surname) {
      return [givenName, surname].filter(Boolean).join(' ');
    }

    return t('emptyMark');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, givenName, surname]);

  useFieldValidationHandler({
    fieldName: INPUT_NAME,
    validation: isFolderRequired ? { required: [] } : null,
    isMultiple: true,
    fieldType: FieldTypeEnum.AUTOCOMPLETE
  });

  useFieldValidationHandler({
    fieldName: APPLICATION_DECEASED_NAME,
    validation: isFolderRequired && isFieldVisible('subject.givenName') ? { required: [] } : null,
    fieldType: FieldTypeEnum.TEXT
  });

  useFieldValidationHandler({
    fieldName: APPLICATION_DECEASED_SURNAME,
    validation: isFolderRequired && isFieldVisible('subject.surname') ? { required: [] } : null,
    fieldType: FieldTypeEnum.TEXT
  });

  return (
    <GridLayout itemProps={{ xs: 12, sm: 6 }}>
      {isFieldVisible('subject.nip') && (
        <Value label={getLabel('subjectNip')} isLoading={isFetching}>
          {nip}
        </Value>
      )}
      {isFieldVisible('number') &&
        (!viewMode ? (
          <FolderSelectField
            fieldName={INPUT_NAME}
            customSetValue={handleSelectChange}
            label={getLabel('folderNumber')}
            isRequired={isFolderRequired}
            folderTypeKeys={folderTypeKeys}
            onClear={onClear}
            initialValue={{
              name: number,
              value: {}
            }}
          />
        ) : (
          <Value label={getLabel('folderNumber')} value={number} isLoading={isFetching} />
        ))}
      {isFieldVisible('dateOfSubjectDeath') && (
        <Value label={getLabel('subjectDeath')} value={convertCalendarDate(dateOfSubjectDeath)} />
      )}
      {isFieldVisible('subject.givenName') && (
        <TextInputField
          name={APPLICATION_DECEASED_NAME}
          label={getLabel('givenName')}
          inputProps={{ maxLength: 500 }}
          isRequired={isFolderRequired}
          customHandleChange={event => setValue(APPLICATION_DECEASED_NAME, event?.target?.value)}
        />
      )}
      {isFieldVisible('subject.surname') && (
        <TextInputField
          name={APPLICATION_DECEASED_SURNAME}
          label={getLabel('surname')}
          inputProps={{ maxLength: 500 }}
          isRequired={isFolderRequired}
          customHandleChange={event => setValue(APPLICATION_DECEASED_SURNAME, event?.target?.value)}
        />
      )}
      {isFieldVisible('subject.name') && <Value label={getLabel('subjectName')} value={name} isLoading={isFetching} />}
      {isFieldVisible('subject.fullname') && (
        <Value label={getLabel('name')} value={subjectFullName} isLoading={isFetching} />
      )}
    </GridLayout>
  );
}

export default FolderDetailsFields;
