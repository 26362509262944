import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import {
  FormMode,
  TableButtonDelete,
  TableIconButton,
  useConfirmDialog,
  useDialog,
  useTableContext
} from '@libs/common/v2';

import { DomainUIElementEnum } from '@libs/domain/config';
import { InsurancePolicySnapshot, useDeleteInsuranceMutation } from '@libs/domain/folder';

import { InsurancePoliciesEditFormDialog } from '../components/InsurancePoliciesEditFormDialog';

interface IProps {
  folderId: string;
  editFormData: InsurancePolicySnapshot;
}

function InsurancePoliciesTableRowActions({ folderId, editFormData }: IProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const [confirm] = useConfirmDialog();
  const { refetch } = useTableContext();
  const { openDialog } = useDialog();

  const { mutate: deleteInsurance } = useDeleteInsuranceMutation();

  const startDeleteProcess = () => {
    confirm({
      title: t('folder:details.tab.insurancePolicies.deleteInsuranceDialogTitle'),
      message: t('folder:details.tab.insurancePolicies.deleteInsuranceDialogContent', { name: editFormData.name }),
      confirmType: 'danger',
      onConfirm: async (setConfirmLoading: (value: boolean) => void, closeDialog: () => void) => {
        setConfirmLoading(true);
        await deleteInsurance(editFormData.id, {
          onSuccess: () => {
            showSuccessSnackbar(t('folder:details.tab.insurancePolicies.deleteInsuranceSuccess'));
            refetch();
            closeDialog();
          },
          onSettled: () => {
            setConfirmLoading(false);
          }
        });
      }
    });
  };

  return (
    <>
      <TableButtonDelete
        tooltipTitle={t('action.delete')}
        onClick={startDeleteProcess}
        actionKey={DomainUIElementEnum.FOLDER_INSURANCE_POLICY_DELETE_BUTTON}
      />
      <TableIconButton
        tooltipTitle={t('other:component.table.edit')}
        icon="EditIcon"
        onClick={() =>
          openDialog(({ closeDialog }) => (
            <InsurancePoliciesEditFormDialog
              folderId={folderId}
              editFormData={editFormData}
              formMode={FormMode.EDIT}
              closeDialog={closeDialog}
              refetch={refetch}
            />
          ))
        }
        actionKey={DomainUIElementEnum.FOLDER_INSURANCE_POLICY_EDIT_BUTTON}
      />
    </>
  );
}

export default InsurancePoliciesTableRowActions;
