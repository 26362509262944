import { useRef } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormMode, GridLayout, Section } from '@libs/common/v2';

import { useUpdateApplicationMisspellDriverCertificateMutation } from '../../api';
import { MisspellApplicationDataDriverCertificateFormValues } from '../../model/misspell-application.model';
import { MisspellApplicationDriverCertificateFormFields } from '../form/MisspellApplicationDriverCertificateFormFields';

import { IPermissionDialogProps, PermissionDialog } from './PermissionDialog';

export function PermissionDriverCertificateDialog({
  mode,
  permissionData,
  updatePermissionData,
  applicationId,
  closeDialog
}: Pick<
  IPermissionDialogProps<MisspellApplicationDataDriverCertificateFormValues>,
  'permissionData' | 'updatePermissionData' | 'closeDialog' | 'mode' | 'applicationId'
>) {
  const [t] = useTranslation();
  const handleSubmit = useRef<UseFormHandleSubmit<MisspellApplicationDataDriverCertificateFormValues>>(null);

  return (
    <PermissionDialog
      formRef={handleSubmit}
      applicationId={applicationId}
      closeDialog={closeDialog}
      mode={mode}
      permissionData={permissionData}
      mutation={useUpdateApplicationMisspellDriverCertificateMutation}
      updatePermissionData={updatePermissionData}
      compareTitle={t('applications:misspellApplication.dialog.changesComparition')}
      editTitle={t('applications:misspellApplication.dialog.permissionEdit')}
      compareContentElement={
        <GridLayout itemProps={{ xs: 6 }}>
          <Section title={t('applications:misspellApplication.dialog.permissionData')} isModalSection>
            <MisspellApplicationDriverCertificateFormFields formMode={FormMode.VIEW} permissionData={permissionData} />
          </Section>
          <Section title={t('applications:misspellApplication.dialog.updatedPermissionData')} isModalSection>
            <MisspellApplicationDriverCertificateFormFields
              formMode={FormMode.VIEW}
              permissionData={updatePermissionData}
            />
          </Section>
        </GridLayout>
      }
      editContentElement={
        <MisspellApplicationDriverCertificateFormFields
          formMode={FormMode.EDIT}
          permissionData={updatePermissionData}
          ref={handleSubmit}
        />
      }
    />
  );
}
