enum ProcessMessageKeyEnum {
  PREPARE_DECISION_DRAFT = 'prepareDecisionDraft',
  PREPARE_APPROVAL_DECISION = 'prepareApprovalDecision',
  DECISION_DOCUMENTS_PRINTED = 'decisionDocumentsPrinted',
  CANCEL_APPLICATION = 'cancelApplication',
  SET_INCOMPLETE_APPLICATION_STATUS = 'setIncompleteApplicationStatus',
  WITHDRAW_APPLICATION = 'withdrawApplication',
  SET_PENDING_VERIFICATION_APPLICATION_STATUS = 'setPendingVerificationApplicationStatus',
  ASSIGN_TO_HEAD_OF_DEPARTMENT = 'assignToHeadOfDepartment',
  PREPARE_REJECTION_DECISION = 'prepareRejectionDecision',
  SUBMIT_SIGNED_DOCUMENT_TO_SEND = 'submitSignedDocumentToSend',
  UPDATE_DECISION_DOCUMENT = 'updateDecisionDocument',
  PRINT_APPLICATION_DECISION = 'printApplicationDecision',
  SEND_FOR_REPRINT = 'sendForReprint',
  SEND_APPLICATION_DOCUMENTS = 'sendApplicationDocuments',
  DO_NOT_EXPIRE_FOLDER = 'dontExpireFolder',
  DO_NOT_SEND_DOCUMENTS = 'dontSendDocuments',
  UPDATE_LACK_OF_FORMAL_REQUIREMENTS_DOCUMENT = 'updateLackOfFormalRequirementsDocument',
  UPDATE_APPLICATION_NOT_CONSIDERED_DOCUMENT = 'updateApplicationNotConsideredDocument',
  FOREIGN_AUTHORITIES_NOTIFICATED = 'foreignAuthoritiesNotificated',
  FOREIGN_AUTHORITIES_NOTIFICATED_WITH_PREFIX = 'internal.message.foreignAuthoritiesNotificated',
  RETURN_FOR_CORRECTIONS = 'returnForCorrections',
  VERIFY_APPLICATION_COMPLETENESS_FORM = 'verifyApplicationCompletenessForm',
  CANCEL_PROCESS = 'cancelProcess',
  TRANSFER_TO_OTHER_DEPARTMENT = 'transferToOtherDepartment',
  CHOOSE_ACTION_PATH_FORM = 'chooseActionPathForm',
  PREPARE_DECISION_DRAFT_EMPLOYEE = 'prepareDecisionDraftEmployee',
  REFUSE_TO_ISSUE_PERMISSION = 'refuseToIssuePermission'
}

export default ProcessMessageKeyEnum;
