import * as Yup from 'yup';

import { i18n } from '@libs/common';

import { ChangeUserPasswordValidation } from '@libs/user/models';

export const requiredDefaultFields = {
  email: true
};

export const localChangePasswordValidation = (): ChangeUserPasswordValidation => {
  return Yup.object({
    newPassword1: Yup.string().min(14).required(),
    newPassword2: Yup.string().oneOf([Yup.ref('newPassword1'), null], i18n.t('validation:passwordMatch'))
  });
};
