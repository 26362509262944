/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useTranslation } from 'react-i18next';
import { ColumnTypesEnum, TableFilterMapper, TableSortMapper, useCreateColumns, Value } from '@enigma/fe-ui';
import { PermissionSearchFilter, TransitStopPermission } from '@ibtm/domain';
import { DICTIONARY_IBTM_DOMAIN_PREFIX } from '@libs/config/constants';
import _ from 'lodash';
import moment from 'moment';

import { Chip, SelectOption } from '@libs/common/v2';
import { getCalendarDate, getValue } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { PermissionsColumnsEnum, PermissionSnapshotClient } from '../../model';

enum MigratoryFields {
  MIGRATION = 'MIGRACJA'
}

export function usePermissionsColumnsV2({
  visibleColumns,
  subjectNameColumnHeader,
  permissionNumberColumnHeader,
  dateFromAndToWithoutChips,
  validFromColumnHeader
}: {
  visibleColumns: Array<PermissionsColumnsEnum>;
  subjectNameColumnHeader?: string;
  permissionNumberColumnHeader?: string;
  validFromColumnHeader?: string;
  dateFromAndToWithoutChips?: boolean;
}) {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  const translateIssuingAuthority = row => {
    if (row?.startsWith(DICTIONARY_IBTM_DOMAIN_PREFIX)) {
      return translate(DomainDictionaryEnum.AUTHORITY, row, t('emptyMark'));
    }

    return getValue(row);
  };

  const translateTransitStop = (transitStops: Array<TransitStopPermission>): string => {
    if (_.isEmpty(transitStops)) {
      return t('emptyMark');
    }

    const translatedTransitStops = transitStops.map(transitStop => ({
      city: translate(DomainDictionaryEnum.TRANSIT_LOCATION, transitStop.city, transitStop.city),
      countryKey: translate(DomainDictionaryEnum.COUNTRY_CODE, transitStop.countryKey)
    }));

    const parsedTranslatedTransitStop = translatedTransitStops
      .map(transitStop => `${transitStop.city} - ${transitStop.countryKey}`)
      .join(', ');

    return parsedTranslatedTransitStop;
  };

  const { createColumns } = useCreateColumns<PermissionSnapshotClient>();
  const renderDateFromAndDateTo = (value, rowData) => {
    if (!value) return t('emptyMark');

    const isSameOrBefore = moment(rowData?.validFrom).isSameOrBefore(moment());
    const chipColor = isSameOrBefore ? 'primary' : 'error';
    const chipColorWeight = isSameOrBefore ? '700' : '800';
    return dateFromAndToWithoutChips ? value : <Chip color={chipColor} colorWeight={chipColorWeight} title={value} />;
  };
  const columns = createColumns(
    [
      {
        id: 'applicationNumber',
        accessor: 'applicationNumber',
        header: t('permission:field.applicationNumber'),
        type: ColumnTypesEnum.TEXT,
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'certificateNumber',
        accessor: 'certificateNumber',
        header: t('permission:field.certificateNumber'),
        type: ColumnTypesEnum.TEXT,
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'cancellationReason',
        accessor: 'cancellationReason',
        header: t('permission:field.cancellationReason'),
        type: ColumnTypesEnum.DICTIONARY,
        dictionaryKey: DomainDictionaryEnum.PERMISSION_CANCELLATION_REASON,
        columnFormatter: value => (
          <Value
            variant="dictionary"
            dictionaryKey={DomainDictionaryEnum.PERMISSION_CANCELLATION_REASON}
            value={value}
          />
        ),
        columnXLSXFormatter: cancellationReason => {
          return translate(DomainDictionaryEnum.PERMISSION_CANCELLATION_REASON, cancellationReason, t('emptyMark'));
        },
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'cancellationDate',
        accessor: 'cancellationDate',
        header: t('permission:field.cancellationDate'),
        type: ColumnTypesEnum.DATE,
        filter: 'DateRangePicker',
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'permissionNumber',
        accessor: 'permissionNumber',
        header: permissionNumberColumnHeader || t('permission:field.permissionNumber'),
        type: ColumnTypesEnum.TEXT,
        columnFormatter: permissionNumber =>
          permissionNumber?.includes(MigratoryFields.MIGRATION) ? t('emptyMark') : getValue(permissionNumber),
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'subjectAddress',
        accessor: 'subjectAddress',
        header: t('permission:field.subjectAddress'),
        type: ColumnTypesEnum.TEXT,
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'subjectNip',
        accessor: 'subjectNip',
        header: t('permission:field.subjectNip'),
        type: ColumnTypesEnum.TEXT,
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'formNumber',
        accessor: 'formNumber',
        header: t('permission:field.formNumber'),
        type: ColumnTypesEnum.TEXT,
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'subjectName',
        accessor: 'subjectName',
        header: subjectNameColumnHeader || t('permission:field.subjectName'),
        type: ColumnTypesEnum.TEXT,
        columnFormatter: subjectName =>
          subjectName?.includes(MigratoryFields.MIGRATION) ? t('emptyMark') : getValue(subjectName),
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'dateOfIssue',
        accessor: 'dateOfIssue',
        header: t('permission:field.dateOfIssue'),
        type: ColumnTypesEnum.DATE,
        filter: 'DateRangePicker',
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'dateOfIssueGitd',
        accessor: 'printDate',
        header: t('permission:field.printDate'),
        type: ColumnTypesEnum.DATE,
        filter: 'DateRangePicker',
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'permissionValidityPeriodKey',
        accessor: 'permissionValidityPeriodKey',
        header: t('permission:field.permissionValidityPeriodKey'),
        type: ColumnTypesEnum.DICTIONARY,
        dictionaryKey: DomainDictionaryEnum.DURATION_OF_PERMIT,
        columnFormatter: value => (
          <Value variant="dictionary" dictionaryKey={DomainDictionaryEnum.DURATION_OF_PERMIT} value={value} />
        ),
        columnXLSXFormatter: value => {
          return translate(DomainDictionaryEnum.DURATION_OF_PERMIT, value, t('emptyMark'));
        },
        isFilterable: false,
        isSortable: false
      },
      {
        id: 'validFrom',
        accessor: 'validFrom',
        header: validFromColumnHeader || t('permission:field.validFrom'),
        type: ColumnTypesEnum.DATE,
        filter: 'DateRangePicker',
        columnFormatter: renderDateFromAndDateTo,
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'validTo',
        accessor: 'validTo',
        header: t('permission:field.validTo'),
        type: ColumnTypesEnum.DATE,
        columnFormatter: renderDateFromAndDateTo,
        filter: 'DateRangePicker',
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'transitStopStartTour',
        accessor: 'transitStopStartTour',
        header: t('permission:field.transitStopStartTour'),
        columnFormatter: transitStopStartTour => translateTransitStop(transitStopStartTour),
        type: ColumnTypesEnum.TEXT,
        isFilterable: false,
        isSortable: false
      },
      {
        id: 'transitStopEndTour',
        accessor: 'transitStopEndTour',
        header: t('permission:field.transitStopEndTour'),
        columnFormatter: transitStopEndTour => translateTransitStop(transitStopEndTour),
        type: ColumnTypesEnum.TEXT,
        isFilterable: false,
        isSortable: false
      },
      {
        id: 'status',
        accessor: 'status',
        header: t('permission:field.status'),
        type: ColumnTypesEnum.DICTIONARY,
        dictionaryKey: DomainDictionaryEnum.PERMISSION_STATUS,
        columnFormatter: value => (
          <Value variant="dictionary" dictionaryKey={DomainDictionaryEnum.PERMISSION_STATUS} value={value} />
        ),
        columnXLSXFormatter: value => {
          return translate(DomainDictionaryEnum.PERMISSION_STATUS, value, t('emptyMark'));
        },
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'printDate',
        accessor: 'printDate',
        header: t('permission:field.printDate'),
        type: ColumnTypesEnum.DATE,
        filter: 'DateRangePicker',
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'issuingCountryKey',
        accessor: 'issuingCountryKey',
        header: t('permission:field.issuingCountryKey'),
        type: ColumnTypesEnum.DICTIONARY,
        dictionaryKey: DomainDictionaryEnum.COUNTRY_CODE,
        columnFormatter: value => (
          <Value variant="dictionary" dictionaryKey={DomainDictionaryEnum.COUNTRY_CODE} value={value} />
        ),
        columnXLSXFormatter: issuingCountryKey => {
          return translate(DomainDictionaryEnum.COUNTRY_CODE, issuingCountryKey, t('emptyMark'));
        },
        isFilterable: true,
        isSortable: true
      },
      {
        id: 'issuingAuthority',
        accessor: 'issuingAuthority',
        header: t('permission:field.issuingAuthority'),
        type: ColumnTypesEnum.DICTIONARY,
        dictionaryKey: DomainDictionaryEnum.AUTHORITY,
        columnFormatter: issuingAuthority => {
          return translateIssuingAuthority(issuingAuthority);
        },
        columnXLSXFormatter: issuingAuthority => {
          return translateIssuingAuthority(issuingAuthority);
        },
        isFilterable: false
      }
    ],
    Object.values(PermissionsColumnsEnum).filter(column => !Object.values(visibleColumns).includes(column))
  );

  const mappedFilterFields: TableFilterMapper<PermissionSnapshotClient, PermissionSearchFilter> = {
    applicationNumber: (applicationNumber: string) => ({ applicationNumberContains: applicationNumber }),
    permissionNumber: (permissionNumber: string) => ({ numberContains: permissionNumber }),
    formNumber: (formNumber: string) => ({ formNumberContains: formNumber }),
    certificateNumber: (certificateNumber: string) => ({ numberContains: certificateNumber }),
    dateOfIssue: ({ from, to }) => ({
      dateOfIssueGreaterThanOrEqual: getCalendarDate(from),
      dateOfIssueLessThanOrEqual: getCalendarDate(to)
    }),
    dateOfIssueGitd: ({ from, to }) => ({
      dateOfIssueGreaterThanOrEqual: getCalendarDate(from),
      dateOfIssueLessThanOrEqual: getCalendarDate(to)
    }),
    validFrom: ({ from, to }) => ({
      validFromGreaterThanOrEqual: getCalendarDate(from),
      validFromLessThanOrEqual: getCalendarDate(to)
    }),
    validTo: ({ from, to }) => ({
      validToGreaterThanOrEqual: getCalendarDate(from),
      validToLessThanOrEqual: getCalendarDate(to)
    }),
    signerName: (signers: SelectOption<string>[]) => ({ signerIn: signers.map(signer => signer.value) }),
    status: (statuses: SelectOption<string> | SelectOption<string>[]) => ({
      statusIn: Array.isArray(statuses) ? statuses.map(status => status.value) : [statuses.value]
    }),
    issuingCountryKey: (countries: SelectOption<string>[]) => ({
      issuingCountryIn: countries.map(country => country.value)
    }),
    dateOfIssueExternal: ({ from, to }) => ({
      dateOfIssueExternalGreaterThanOrEqual: getCalendarDate(from),
      dateOfIssueExternalLessThanOrEqual: getCalendarDate(to)
    }),
    cancelledBy: (cancelledBy: SelectOption<string>[]) => ({ cancellationByIn: cancelledBy.map(({ value }) => value) }),
    cancellationReason: (cancellationReasons: SelectOption<string>[]) => ({
      cancellationReasonIn: cancellationReasons.map(({ value }) => value)
    }),
    cancellationDate: ({ from, to }) => ({
      cancellationDateGreaterThanOrEqual: getCalendarDate(from),
      cancellationDateLessThanOrEqual: getCalendarDate(to)
    }),
    subjectName: (subjectName: string) => ({ applicationSubjectNameContains: subjectName }),
    subjectAddress: (subjectAddress: string) => ({ subjectAddressContains: subjectAddress }),
    printDate: ({ from, to }) => ({
      printDateGreaterThanOrEqual: getCalendarDate(from),
      printDateLessThanOrEqual: getCalendarDate(to)
    })
  };

  const mappedSortFields: TableSortMapper<PermissionSnapshotClient> = {
    permissionNumber: 'number',
    formNumber: 'form.number',
    cancellationReason: 'cancellationReasonKey',
    certificateNumber: 'number',
    signerName: 'signer.name',
    status: 'statusKey',
    dateOfIssueGitd: 'dateOfIssue',
    issuingCountryKey: 'issuingCountryKey',
    subjectAddress: 'application.subject.mainAddress.shortAddress',
    subjectName: 'folder.subject.name',
    dateOfIssueExternal: 'dateOfIssue'
  };

  return { columns, mappedFilterFields, mappedSortFields };
}

export const PERMISSIONS_DEFAULT_COLUMNS: Array<PermissionsColumnsEnum> = [
  PermissionsColumnsEnum.APPLICATION_NUMBER,
  PermissionsColumnsEnum.PERMISSION_NUMBER,
  PermissionsColumnsEnum.FORM_NUMBER,
  PermissionsColumnsEnum.DATE_OF_ISSUE,
  PermissionsColumnsEnum.VALID_FROM,
  PermissionsColumnsEnum.VALID_TO,
  PermissionsColumnsEnum.SIGNER_NAME,
  PermissionsColumnsEnum.STATUS
];
