import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PluginHook, TableOptions } from 'react-table';
import { useSnackbar } from '@enigma/fe-ui';
import { ExploitationBasesSearchFilter as ExploitationBasesClienSearchFiltert } from '@ibtm/client-domain';
import { ExploitationBaseDetails, ExploitationBaseSnapshot, ExploitationBasesSearchFilter } from '@ibtm/domain';

import {
  CamelCasePath,
  InputMode,
  TableLayout,
  useConfirmDialog,
  useRouter,
  useTableRowActions
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { UiMode } from '@libs/meta-form';
import { UIElementNameEnum } from '@libs/permission';

import { API } from '@libs/domain/api';
import { DomainUIElementEnum, IPermissionsKeys, useDomainConfigContext } from '@libs/domain/config';
import {
  ExploitationBaseActionButtonsEnum,
  ExploitationBaseQueryKeysEnum,
  ExploitationBaseRowActionButtonsEnum,
  ExploitationBaseStatusEnum,
  useExploitationBaseDeleteMutation,
  useExploitationBaseExpireMutation,
  useExploitationBaseRestoreMutation,
  useExploitationBaseTable,
  useGeoportalMapModal
} from '@libs/domain/exploitation-base';

import { ApplicationQueryKeysEnum } from '../../../application';
import { VISIBLE_COLUMNS } from '../../config/default-view.config';
import { useExploitationBaseSearchAction } from '../../hooks/useExploitationBaseSearchAction';
import { ExploitationBaseCreateActionButton, ExploitationBaseSearchActionButton } from '../actions';

interface IProps {
  folderId?: string;
  transferredFoldersIds?: string[];
  applicationId?: string;
  children?: ReactNode;
  queryParams?: ExploitationBasesSearchFilter | ExploitationBasesClienSearchFiltert;
  mode?: 'FORM' | 'VIEW';
  visibleColumns?: Array<CamelCasePath<ExploitationBaseSnapshot>>;
  visibleHeaderActions?: ExploitationBaseActionButtonsEnum[];
  tableOptions?: Partial<TableOptions<ExploitationBaseSnapshot>>;
  visibleRowActions?: ExploitationBaseRowActionButtonsEnum[];
  tablePlugins?: PluginHook<ExploitationBaseSnapshot>[];
  isInspectionTable?: boolean;
  isXlsxDownloadVisible?: boolean;
  isHeaderHidden?: boolean;
  isActionColumnEnabled?: boolean;
  isCollapsable?: boolean;
  permissionsKeys?: IPermissionsKeys;
  tableActionKeys?: Array<UIElementNameEnum>;
  selectionColumnWidth?: number;
  isHiddenSelectRowHeader?: boolean;
}

function ExploitationBaseTable({
  folderId,
  transferredFoldersIds,
  applicationId,
  children,
  mode = 'VIEW',
  queryParams,
  visibleColumns = VISIBLE_COLUMNS,
  visibleHeaderActions,
  tableOptions,
  tablePlugins,
  visibleRowActions = [],
  isXlsxDownloadVisible = true,
  isHeaderHidden,
  isActionColumnEnabled = true,
  isCollapsable = false,
  permissionsKeys,
  tableActionKeys,
  selectionColumnWidth,
  isHiddenSelectRowHeader
}: IProps) {
  const queryCache = useQueryCache();
  const { goToPage, routes } = useRouter();
  const [t] = useTranslation();
  const { isClientPortal } = useDomainConfigContext();
  const [confirm] = useConfirmDialog();
  const { mutate: deleteExploitationBaseDeleteMutation } = useExploitationBaseDeleteMutation();
  const { mutate: expireExploitationBaseMutation } = useExploitationBaseExpireMutation();
  const { mutate: restoreExploitationBaseMutation } = useExploitationBaseRestoreMutation();

  const isActionColumn = visibleRowActions.length > 0 && isActionColumnEnabled;
  const clientPortalFilteredColumns = useMemo(
    () =>
      visibleColumns.filter(
        item =>
          ![
            'assignedVehicles',
            'assignedSubjects',
            'lastInspectionResultInspectionResultKey',
            'lastInspectionResultInspectionDate',
            'lastInspectionRemarks'
          ].includes(item)
      ),
    [visibleColumns]
  );
  const tableProps = useExploitationBaseTable({
    params: queryParams,
    visibleColumns: isClientPortal ? clientPortalFilteredColumns : visibleColumns,
    tableOptions,
    tablePlugins
  });

  const isHeaderActionVisible = (action: ExploitationBaseActionButtonsEnum) => visibleHeaderActions?.includes(action);
  const isButtonVisible = (name: ExploitationBaseRowActionButtonsEnum) =>
    !!visibleRowActions.find(item => item === name);

  const { ADD, SEARCH } = ExploitationBaseActionButtonsEnum;

  const { showSnackbar, showSuccessSnackbar } = useSnackbar();
  const { showMap } = useGeoportalMapModal();

  const { openExploitationBaseSearchDialog } = useExploitationBaseSearchAction({
    folderId,
    transferredFoldersIds,
    applicationId
  });

  const showGeoportalMap = (original: ExploitationBaseDetails) => {
    const { postCode, city, street, propertyNumber } = original;
    if (postCode && city && street && propertyNumber) {
      showMap(city, street, propertyNumber);
    } else {
      showSnackbar('error', t('exploitationBase:geoportal.message.addressEmpty'));
    }
  };

  const { renderTableActions } = useTableRowActions<ExploitationBaseDetails>([
    {
      id: DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_SHOW_DETAILS_BUTTON,
      label: 'action.openDetails',
      link: ({ original }) => routes.exploitationBaseApplicationView(original.id, applicationId),
      icon: 'ArrowIcon'
    },
    {
      id: DomainUIElementEnum.FOLDER_EXPLOITATION_BASE_SHOW_DETAILS_BUTTON,
      label: 'action.openDetails',
      link: ({ original }) => routes.exploitationBaseFolderView(original.id, folderId),
      icon: 'ArrowIcon'
    },
    {
      id: DomainUIElementEnum.FOLDER_EXPLOITATION_BASE_EXPIRE_BUTTON,
      icon: 'ExpireIcon',
      label: 'action.expire',
      onClick: ({ row: { original } }) =>
        confirm({
          message: t('exploitationBase:list.expireModalContent'),
          title: t('exploitationBase:list.expireModalTitle'),
          onConfirm: (setLoading, closeDialog) => {
            setLoading(true);
            expireExploitationBaseMutation(
              { exploitationBaseId: original?.id, versionedRequest: { version: original?.version } },
              {
                onSuccess: () => {
                  showSuccessSnackbar(t('exploitationBase:list.message.expireSuccess'));
                  queryCache.invalidateQueries(ExploitationBaseQueryKeysEnum.EXPLOITATION_BASE_LIST);
                },
                onSettled: () => {
                  setLoading(false);
                  closeDialog();
                }
              }
            );
          }
        }),
      isHidden: ({ row }) =>
        !isButtonVisible(ExploitationBaseRowActionButtonsEnum.EXPIRE_EXPLOITATION_BASE) ||
        row?.original?.statusKey !== ExploitationBaseStatusEnum.CURRENT
    },
    {
      id: DomainUIElementEnum.FOLDER_EXPLOITATION_BASE_RESTORE_BUTTON,
      icon: 'RestoreIcon',
      label: 'exploitationBase:list.action.restore',
      onClick: ({ row: { original } }) =>
        confirm({
          message: t('exploitationBase:list.restoreModalContent'),
          title: t('exploitationBase:list.restoreModalTitle'),
          shortTitle: t('exploitationBase:list.restoreModalShortTitle'),
          onConfirm: (setLoading, closeDialog) => {
            setLoading(true);
            restoreExploitationBaseMutation(
              { exploitationBaseId: original?.id, versionedRequest: { version: original?.version } },
              {
                onSuccess: () => {
                  showSuccessSnackbar(t('exploitationBase:list.message.restoreSuccess'));
                  queryCache.invalidateQueries(ExploitationBaseQueryKeysEnum.EXPLOITATION_BASE_LIST);
                },
                onSettled: () => {
                  setLoading(false);
                  closeDialog();
                }
              }
            );
          }
        }),
      isHidden: ({ row }) =>
        !isButtonVisible(ExploitationBaseRowActionButtonsEnum.RESTORE_EXPLOITATION_BASE) ||
        row?.original?.statusKey !== ExploitationBaseStatusEnum.EXPIRED
    },
    {
      id: DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_SHOW_GEOPORTAL_BUTTON,
      icon: 'ShowOnMapIcon',
      label: 'exploitationBase:geoportal.button',
      onClick: ({ row: { original } }) => showGeoportalMap(original),
      isHidden: !isButtonVisible(ExploitationBaseRowActionButtonsEnum.SHOW_GEOPORTAL_MAP)
    },
    {
      id: DomainUIElementEnum.FOLDER_EXPLOITATION_BASE_SHOW_GEOPORTAL_BUTTON,
      icon: 'ShowOnMapIcon',
      label: 'exploitationBase:geoportal.button',
      onClick: ({ row: { original } }) => showGeoportalMap(original),
      isHidden: !isButtonVisible(ExploitationBaseRowActionButtonsEnum.SHOW_GEOPORTAL_MAP)
    },
    {
      id: DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_EDIT_BASE_BUTTON,
      icon: 'EditIcon',
      label: 'action.edit',
      link: ({ original }) => routes.exploitationBaseApplicationEdit(original.id, applicationId),
      isHidden: !isButtonVisible(ExploitationBaseRowActionButtonsEnum.EDIT) || mode !== InputMode.FORM
    },
    {
      id: DomainUIElementEnum.FOLDER_EXPLOITATION_BASE_EDIT_BUTTON,
      icon: 'EditIcon',
      label: 'action.edit',
      link: ({ original }) => routes.exploitationBaseFolderEdit(original.id, folderId),
      isHidden: !isButtonVisible(ExploitationBaseRowActionButtonsEnum.EDIT) || mode !== InputMode.FORM
    },
    {
      id: DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_DELETE_BASE_BUTTON,
      icon: 'TrashIcon',
      label: 'action.delete',
      onClick: ({ row: { original } }) =>
        confirm({
          confirmType: 'danger',
          message: t('exploitationBase:list.deleteModalContent', {
            postCode: original?.postCode,
            city: original?.city,
            street: original?.street,
            propertyNumber: original?.propertyNumber
          }),
          title: t('exploitationBase:list.deleteModalTitle'),
          shortTitle: t('global:dialog.deleteConfirmTitle'),
          onConfirm: (setLoading, closeDialog) => {
            setLoading(true);
            deleteExploitationBaseDeleteMutation(
              { exploitationBaseId: original?.id },
              {
                onSuccess: () => {
                  showSuccessSnackbar(t('exploitationBase:list.message.deleteSuccess'));
                  queryCache.invalidateQueries(ExploitationBaseQueryKeysEnum.EXPLOITATION_BASE_LIST);
                  queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
                },
                onSettled: () => {
                  setLoading(false);
                  closeDialog();
                }
              }
            );
          }
        }),
      isHidden: !isButtonVisible(ExploitationBaseRowActionButtonsEnum.DELETE) || mode !== InputMode.FORM
    }
  ]);

  const isNotViewMode = mode !== UiMode.VIEW;
  const isSearchButtonVisible = isNotViewMode && isHeaderActionVisible(SEARCH);
  const isAddButtonVisible = isNotViewMode && isHeaderActionVisible(ADD);
  const mobileHeaderActions = [
    {
      label: t('exploitationBase:details.button.searchBase'),
      actionKey: permissionsKeys?.search,
      onClick: openExploitationBaseSearchDialog,
      isHidden: !isSearchButtonVisible
    },
    {
      label: t('action.add'),
      isHidden: !isAddButtonVisible,
      actionKey: permissionsKeys?.add,
      onClick: () => {
        if (applicationId) {
          goToPage(routes.exploitationBaseApplicationCreate(applicationId));
        }
      }
    }
  ];

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('exploitationBase:list.title')}
      xlsxDownload={
        isXlsxDownloadVisible && {
          fileName: t('exploitationBase:list.title'),
          pathToXLSXTranslation: 'exploitationBase:field',
          apiRequest: params => {
            if (isClientPortal) {
              return API.client.exploitationBase.getExploitationBasesSnapshotPage('', '', params);
            }
            return API.exploitationBase.getExploitationBasesSnapshotPage(params);
          }
        }
      }
      mobileHeaderActions={mobileHeaderActions}
      headerActions={
        <>
          {isSearchButtonVisible && (
            <ExploitationBaseSearchActionButton
              folderId={folderId}
              transferredFoldersIds={transferredFoldersIds}
              applicationId={applicationId}
              actionKey={permissionsKeys?.search}
            />
          )}
          {isAddButtonVisible && (
            <ExploitationBaseCreateActionButton applicationId={applicationId} actionKey={permissionsKeys?.add} />
          )}
        </>
      }
      rowActions={isActionColumn ? renderTableActions(tableActionKeys) : null}
      selectionColumnWidth={selectionColumnWidth}
      isActionColumnEnabled={isActionColumn}
      isHeaderHidden={isHeaderHidden}
      isCollapsable={isCollapsable}
      isSection
      isHiddenSelectRowHeader={isHiddenSelectRowHeader}
    >
      {children}
    </TableLayout>
  );
}

export default ExploitationBaseTable;
