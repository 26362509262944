import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { queryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import { PageDocumentTemplateSnapshotExternal } from '@avispon/document-generator';
import { useSnackbar } from '@enigma/fe-ui';
import { InvoiceCreateRequest, InvoiceLite, InvoiceRecipientUpdateRequest, InvoiceUpdateRequest } from '@ibtm/domain';
import { InvoiceItemUpdateRequest } from '@ibtm/domain/dist/models/invoice-item-update-request';
import { useAppConfig } from '@libs/app-config';
import { makeStyles } from '@mui/styles';
import _, { isNil } from 'lodash';
import moment from 'moment';

import {
  BackButton,
  Button,
  DropdownButton,
  FormMode,
  SelectOption,
  TableButton,
  typedNameV2,
  useConfirmDialog,
  useFormV2Context,
  useRouter
} from '@libs/common/v2';
import { ButtonsGroup } from '@libs/common/v2/components/buttonsGroup';
import { useViewModesV2 } from '@libs/common/v2/form';
import { getCalendarDate } from '@libs/common/v2/utils';

import { DocumentTemplateQueryKeysEnum } from '@libs/document-template';
import { getDocumentTemplates } from '@libs/document-template/api/queries/useDocumentTemplatesQuery';
import { useDownloadQuery, useFileDownload } from '@libs/file';

import { DomainDictionaryEntry, DomainUIElementEnum } from '@libs/domain/config';
import {
  ReleaseDocumentsQueryKeysEnum,
  useChangeReleaseDocumentStatusMutation,
  useGenerateDocumentMutation
} from '@libs/domain/release-documents/api';
import useDownloadReleaseDocumentsDialog from '@libs/domain/release-documents/hooks/useDownloadReleaseDocumentsDialog';

import { FieldsType, ICorrectionItems, ReleaseDocumentsDetailsPageParams } from '../../model';
import { ChangeReturnModeContext } from '../../pages/ReleaseDocumentsWrapper';
import { getDocumentTemplateName, isWZZ } from '../../utils';

interface IProps {
  id: string;
  createCorrection: (params: InvoiceCreateRequest, config?) => void;
  updateSuspensCorrection: (
    params: InvoiceUpdateRequest & {
      invoiceId: string;
      suspensionId: string;
    },
    config?
  ) => void;
  updateCorrection: (request: InvoiceRecipientUpdateRequest & { invoiceId: string }, config?) => void;
  createLoading: boolean;
  updateLoading: boolean;
  baseUrl: string;
  suspensionUrl: string;
  correctionItemsTable: any[];
  documentNumber?: string;
}

const getEmptyStringIfNullOrUndefined = (value: string) => (isNil(value) ? '' : value);

function FormHeaderActions({
  id,
  createCorrection,
  updateCorrection,
  updateSuspensCorrection,
  createLoading,
  updateLoading,
  baseUrl,
  suspensionUrl,
  correctionItemsTable,
  documentNumber
}: IProps) {
  const { handleSubmit, formMode, watch, getValues, setValue } = useFormV2Context();
  const { goToPage, routes } = useRouter();
  const { templateNames } = useAppConfig();

  const { createMode, viewMode, editMode } = useViewModesV2(formMode);
  const [t] = useTranslation();
  const { id: suspensionId, folderId } = useParams<ReleaseDocumentsDetailsPageParams>();
  const { showSuccessSnackbar } = useSnackbar();
  const [confirm] = useConfirmDialog();
  const { mutateAsync: changeStatus } = useChangeReleaseDocumentStatusMutation();
  const { items: returnedItems, clear } = useContext(ChangeReturnModeContext);
  const [downloadFileId, setDownloadFileId] = useState<string>(null);
  const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false);

  const { mutate: generateDocument } = useGenerateDocumentMutation();
  const downloadQuery = useDownloadQuery({ fileId: downloadFileId }, { enabled: Boolean(downloadFileId) });

  const isCorrection = watch('isCorrection') as boolean;
  const statusKey = watch('statusKey') as string;
  const version = watch('version') as number;

  const { openDownloadDialog } = useDownloadReleaseDocumentsDialog(isCorrection, documentNumber);

  const classes = useStyles();

  const onSubmit = (data: FieldsType) => {
    const parseCorrectionItems = (item: InvoiceItemUpdateRequest & ICorrectionItems): InvoiceItemUpdateRequest => ({
      name: item?.name,
      quantity: item?.correctedQuantity,
      unitCost: item?.correctedUnitCost,
      ordinal: item?.ordinal,
      settlementTypeKey: DomainDictionaryEntry.INVOICE_ITEM_SETTLEMENT_TYPE.K,
      correctedInvoiceItem: item?.id
    });

    const correctionReasonKey = getValues('correctionReasonKey') as SelectOption<string>;
    if (createMode) {
      createCorrection(
        {
          dateOfIssue: getCalendarDate(moment().toISOString()),
          applicationId: data?.application?.id,
          correctedInvoiceId: data?.id,
          correctionReasonKey: correctionReasonKey.value,
          recipientName: data?.recipientName,
          recipientSurname: data?.recipientSurname,
          buyerId: data?.buyer.id,
          ...(returnedItems.length > 0
            ? {
                returnRequests: returnedItems.map(item => ({
                  invoiceItemId: item.positionId,
                  reasonKey: item.reasonKey,
                  numberFrom: item.from,
                  numberTo: item.to
                }))
              }
            : {
                items: correctionItemsTable?.map((item: InvoiceItemUpdateRequest & ICorrectionItems) =>
                  parseCorrectionItems(item)
                )
              }),

          zeroing: data?.zeroing
        },
        {
          onSuccess: ({ data }) => {
            showSuccessSnackbar(t('releaseDocuments:message.created'));
            downloadFile(
              getDocumentTemplateName(templateNames, data.isCorrection, isWZZ(data.number), !!folderId),
              () => {
                if (folderId) {
                  clear();
                  goToPage(routes.releaseDocumentOnFolderSuspensionView(folderId, suspensionId, data?.id));
                } else {
                  clear();
                  goToPage(routes.releaseDocumentView(data?.id));
                }
              },
              data?.id
            );
          }
        }
      );
    } else if (suspensionUrl) {
      updateSuspensCorrection(
        {
          invoiceId: data?.id,
          recipientName: getEmptyStringIfNullOrUndefined(data?.recipientName),
          recipientSurname: getEmptyStringIfNullOrUndefined(data?.recipientSurname),
          version: data?.version,
          dateOfIssue: data?.dateOfIssue,
          suspensionId
        },
        {
          onSuccess: ({ data }) => {
            showSuccessSnackbar(t('releaseDocuments:message.updated'));
            downloadFile(
              getDocumentTemplateName(templateNames, data.isCorrection, isWZZ(data.number), !!folderId),
              () => {
                goToPage(`${baseUrl}${id}/VIEW`);
              },
              data?.id
            );
          }
        }
      );
    } else {
      updateCorrection(
        {
          invoiceId: data?.id,
          name: getEmptyStringIfNullOrUndefined(data?.recipientName),
          surname: getEmptyStringIfNullOrUndefined(data?.recipientSurname),
          version: data?.version
        },
        {
          onSuccess: ({ data }) => {
            showSuccessSnackbar(t('releaseDocuments:message.updated'));
            downloadFile(
              getDocumentTemplateName(templateNames, data.isCorrection, isWZZ(data.number), !!folderId),
              () => {
                goToPage(`${baseUrl}${id}/VIEW`);
              },
              data?.id
            );
          }
        }
      );
    }
  };

  const onGoBack = () => {
    goToPage(suspensionUrl || `${baseUrl}`);
  };

  const onAddCorrection = (closeDropdown: () => void) => {
    closeDropdown?.();
    setValue(typedNameV2<FieldsType>('zeroing'), false);
    setValue(typedNameV2<FieldsType>('correctionReasonKey'), null);
    setValue(typedNameV2<FieldsType>('recipientName'), null);
    setValue(typedNameV2<FieldsType>('recipientSurname'), null);
    goToPage(`${baseUrl}${id}/${FormMode.CREATE}`);
  };
  const onCancelCorrection = (closeDropdown: () => void) => {
    confirm({
      title: t('releaseDocuments:cancel.title'),
      message: t('releaseDocuments:messages.cancelDocumentConfirmation'),
      onConfirm: (setLoadingState, close) => {
        setLoadingState(true);
        changeStatus(
          { statusKey: DomainDictionaryEntry.INVOICE_STATUS.CANCELED, version, id },
          {
            onSuccess: () => {
              setValue(typedNameV2<FieldsType>('statusKey'), DomainDictionaryEntry.INVOICE_STATUS.CANCELED);
              showSuccessSnackbar(t('releaseDocuments:messages.cancelSuccess'));
            },
            onSettled: () => {
              close();
              closeDropdown?.();
              setLoadingState(false);
            }
          }
        );
      },
      confirmType: 'danger'
    });
  };

  const downloadFile = (documentTemplateName: string, onSettled: () => void, invoiceId: string) => {
    setIsDownloadLoading(true);
    getDocumentTemplates(DocumentTemplateQueryKeysEnum.DOCUMENT_TEMPLATES, {
      nameFragment: documentTemplateName
    })
      .then(data => {
        generateDocument(
          {
            documentTemplateId: _.get<PageDocumentTemplateSnapshotExternal, string, string>(data, 'content.0.id', null),
            invoiceId
          },
          {
            onSuccess: response => {
              setDownloadFileId(response.data.file.id);
              showSuccessSnackbar(t('document:message.generateDocumentSuccess'));
              queryCache.invalidateQueries(ReleaseDocumentsQueryKeysEnum.RELEASE_DOCUMENTS_DETAILS);
            },
            onSettled: () => {
              setIsDownloadLoading(false);
              onSettled();
            }
          }
        );
      })
      .catch(() => {
        setIsDownloadLoading(false);
        onSettled();
      });
  };

  const { download } = useFileDownload();
  useEffect(() => {
    if (downloadQuery.data) {
      download(
        () =>
          new Promise(res => {
            res(downloadQuery.data);
          })
      );
      setDownloadFileId(null);
    }
  }, [download, downloadQuery]);

  const onDownloadCorrection = () => {
    const fileId = getValues('fileId') as string;

    openDownloadDialog({ fileId, invoiceId: id, isWWU: !!folderId });
  };

  const corrections = watch('corrections') as Array<InvoiceLite & { statusKey: string }>;
  const hasCorrectionInvoice = useMemo(
    () => corrections?.some(correction => correction.statusKey !== DomainDictionaryEntry.INVOICE_STATUS.CANCELED),
    [corrections]
  );

  const isAddVisible = !hasCorrectionInvoice && statusKey !== DomainDictionaryEntry.INVOICE_STATUS.CANCELED;
  const isAddVisibleForRootOnSuspension = !!folderId && !isCorrection;
  const isCancelVisible =
    statusKey !== DomainDictionaryEntry.INVOICE_STATUS.CANCELED && isCorrection && !hasCorrectionInvoice;

  return (
    <ButtonsGroup>
      {viewMode && <BackButton onClick={onGoBack} />}
      {viewMode && isCorrection && (
        <Button
          icon="EditIcon"
          iconColor="white"
          actionKey={
            folderId
              ? DomainUIElementEnum.SUSPENSIONS_CORRECTION_UPDATE
              : DomainUIElementEnum.RELEASE_DOCUMENT_EDIT_BUTTON
          }
          link={`${baseUrl}${id}/EDIT`}
          label={t('action.edit')}
          isPrimary
        />
      )}

      {(editMode || createMode) && (
        <>
          <Button
            {...(createMode && {
              link: suspensionUrl || `${baseUrl}`
            })}
            {...(!createMode && {
              onClick: () => goToPage(`${baseUrl}${id}/VIEW`)
            })}
            label={t('action.cancel')}
            isSecondary
            variant="outlined"
          />
          <Button
            onClick={handleSubmit(onSubmit)}
            label={t('action.save')}
            isPrimary
            isLoading={createLoading || updateLoading || isDownloadLoading}
          />
        </>
      )}
      {!createMode && !editMode && (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
          {isAddVisible || isCancelVisible || isAddVisibleForRootOnSuspension ? (
            <DropdownButton classNames={{ iconButton: classes.iconButton }}>
              {({ handleClose }) => [
                (isAddVisible || isAddVisibleForRootOnSuspension) && (
                  <TableButton
                    key="addButton"
                    onClick={() => onAddCorrection(handleClose)}
                    label={t('releaseDocuments:actions.addCorrection')}
                    actionKey={
                      folderId
                        ? DomainUIElementEnum.SUSPENSIONS_CORRECTION_CREATE
                        : DomainUIElementEnum.RELEASE_DOCUMENT_ADD_CORRECTION_BUTTON
                    }
                  />
                ),
                isCancelVisible && (
                  <TableButton
                    key="cancelButton"
                    onClick={() => onCancelCorrection(handleClose)}
                    label={t('releaseDocuments:actions.cancelCorrection')}
                    actionKey={
                      folderId
                        ? DomainUIElementEnum.SUSPENSIONS_CORRECTION_CANCEL
                        : DomainUIElementEnum.RELEASE_DOCUMENT_CANCEL_BUTTON
                    }
                  />
                ),
                <TableButton
                  key="downloadButton"
                  onClick={() => onDownloadCorrection()}
                  label={t('action.download')}
                  actionKey={DomainUIElementEnum.RELEASE_DOCUMENT_DOWNLOAD_DOCUMENT_BUTTON}
                />
              ]}
            </DropdownButton>
          ) : (
            <Button
              label={t('action.download')}
              onClick={() => onDownloadCorrection()}
              variant="outlined"
              actionKey={DomainUIElementEnum.RELEASE_DOCUMENT_DOWNLOAD_DOCUMENT_BUTTON}
            />
          )}
        </div>
      )}
    </ButtonsGroup>
  );
}

const useStyles = makeStyles({
  iconButton: {
    padding: '5px'
  }
});

export default FormHeaderActions;
