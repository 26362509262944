import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReportDetailsLite } from '@stack/report';
import _ from 'lodash';

import { Dialog, FormV2Context, GridLayout, useRouter, useValidationBuilder } from '@libs/common/v2';
import { unescapeValue } from '@libs/common/v2/utils';

import { DictionaryEntryTypeEnum, useDictionaryTranslations } from '@libs/dictionary';
import { useCreateReportMutation, useReportTypesQuery } from '@libs/report/api';
import { reportTypeDetailsToReportCreateRequest } from '@libs/report/utils/converter.util';

import FieldContainer from './FieldContainer';
import TargetTypeSelectField from './TargetTypeSelectField';

interface TargetParameter {
  name: string;
  value: string;
}

interface IProps {
  reportName: string;
  onClose?: () => void;
  targetParameters?: TargetParameter[];
  onSuccess?: (data: ReportDetailsLite) => void;
  initialValues?: { parameters: Record<string, string>; targetTypes: string[] };
  isTechnical?: boolean;
}

function ReportTypeGenerationDialog({
  reportName,
  onClose,
  targetParameters,
  onSuccess,
  initialValues,
  isTechnical
}: IProps) {
  const [t] = useTranslation();
  const { goToPage, routes } = useRouter();
  const { showSnackbar } = useSnackbar();
  const { validationScheme, validationBuilderFunctions } = useValidationBuilder();
  const { data, isLoading: areReportTypesLoading } = useReportTypesQuery({ name: reportName });
  const reportType = data?.content?.[0];
  const { getDictionaryTranslator, translate } = useDictionaryTranslations();
  const targetParameterNames = targetParameters?.map(targetParameter => targetParameter.name);

  useEffect(() => {
    if (data && data?.content?.length === 0) {
      showSnackbar('error', t('reports:message.IncorrectlyDefinedReportType', unescapeValue({ reportName })));
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { mutate: generateReport, isLoading } = useCreateReportMutation();

  const onSubmit = (values: Record<string, any>) => {
    const fields = reportType.parameterExtras.map(({ displayName, type: { type } }) => ({ name: displayName, type }));

    generateReport(reportTypeDetailsToReportCreateRequest(reportType, values, fields, isTechnical), {
      onSuccess: response => {
        showSnackbar('success', t('reports:message.reportGenerationSuccess'));
        if (onSuccess) {
          onSuccess(response);
        } else {
          goToPage(routes.reportDetails(response?.id));
        }
      },
      onError: () => {
        showSnackbar('error', t('reports:message.reportGenerationFailure'));
      }
    });
  };

  const form = useForm<Record<string, any> | any>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationScheme)
  });
  const { handleSubmit } = form;

  const _onSubmit = async (data: Record<string, any>) => {
    const formData = { ...data, targetTypes: data.targetTypes?.map(targetType => targetType?.id || targetType?.value) };
    onSubmit(formData);
    onClose();
  };

  const closeDialog = () => {
    onClose();
  };

  const values = useMemo(
    () => ({
      ...form,
      loading: false,
      isSubmitting: form.formState.isSubmitting,
      validationBuilderFunctions
    }),
    [form, validationBuilderFunctions]
  );
  const setTargetParameterValue = () => {
    reportType?.parameters?.forEach(parameter => {
      targetParameters.forEach(targetParameter => {
        if (parameter.displayName === targetParameter.name) {
          form.setValue(targetParameter.name, targetParameter.value);
        }
      });
    });
  };

  const parseValue = (value: string): string[] | string => {
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  };

  const setInitialFormValues = () => {
    if (initialValues?.targetTypes) {
      form.setValue('targetTypes', initialValues?.targetTypes);
    }
    return reportType.parameters.forEach(parameter => {
      if (initialValues?.parameters) {
        const value = initialValues.parameters[parameter?.displayName];
        const parsedValue = parseValue(value);

        if (!_.isNil(value)) {
          if (parameter.multiValue && !parameter.checkbox) {
            const fieldValue = _.isArray(parsedValue)
              ? parsedValue.map(item => ({
                  id: item,
                  value: item,
                  name: parameter.dictionaryName
                    ? translate(parameter.dictionaryName as keyof DictionaryEntryTypeEnum, item, item)
                    : item
                }))
              : [
                  {
                    id: parsedValue,
                    value: parsedValue,
                    name: parameter.dictionaryName
                      ? translate(parameter.dictionaryName as keyof DictionaryEntryTypeEnum, parsedValue, parsedValue)
                      : parsedValue
                  }
                ];
            form.setValue(parameter.displayName, fieldValue);
          } else if (parameter.multiValue && parameter.checkbox) {
            if (parameter.dictionaryName) {
              const dictionary = getDictionaryTranslator(parameter.dictionaryName as keyof DictionaryEntryTypeEnum);
              const dictionaryEntries = Object.entries(dictionary).map(entry => {
                return entry[0];
              });
              dictionaryEntries?.forEach((fieldValue, index) => {
                if (parsedValue.includes(fieldValue)) {
                  form.setValue(`${parameter.displayName}.${index}`, fieldValue);
                }
              });
            } else {
              parameter.hintValues?.forEach((fieldValue, index) => {
                if (parsedValue.includes(fieldValue)) {
                  form.setValue(`${parameter.displayName}.${index}`, fieldValue);
                }
              });
            }
          } else if (
            _.isString(value) &&
            parameter.hintQueryAvailable &&
            !parameter.multiValue &&
            parameter.type !== 'DATE'
          ) {
            form.setValue(parameter.displayName, {
              id: undefined,
              name: value,
              value
            });
          } else if (_.isString(value) && parameter.selectOnly) {
            if (['CHARACTER', 'DATE'].includes(parameter.type)) {
              form.setValue(parameter.displayName, value);
            } else {
              form.setValue(parameter.displayName, {
                value
              });
            }
          } else if (_.isString(value) && parameter.type === 'BOOLEAN') {
            form.setValue(parameter.displayName, parameter.required ? value === 'true' : { value });
          } else {
            form.setValue(parameter.displayName, value);
          }
        }
      }
    });
  };

  useEffect(() => {
    if (initialValues && reportType?.parameters) {
      setInitialFormValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType?.parameters, initialValues]);

  useEffect(() => {
    if (targetParameterNames?.length) {
      setTargetParameterValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType?.parameters]);

  const fields = useMemo(() => {
    const filteredParametersValue = targetParameters?.length
      ? reportType?.parameters?.filter(parameter => !targetParameterNames.includes(parameter.displayName))
      : reportType?.parameters;

    const dynamicFields = filteredParametersValue?.map(parameter => {
      return (
        <FieldContainer
          gridItemProps={{}}
          parameterExtras={reportType.parameterExtras}
          reportTypeId={reportType.id}
          fieldDescription={parameter}
          fieldsDescription={reportType.parameters}
          key={parameter.displayName}
        />
      );
    });
    return [
      <TargetTypeSelectField key="targetType" reportType={reportType} isTargetMimeTypeSelected />,
      ...(dynamicFields || [])
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType]);

  return (
    <Dialog
      title={t('reports:generateReport.title')}
      confirmText={t('action.generate')}
      cancelText={t('action.cancel')}
      titleSupportingText={t(`reports:message.generateReportConfirm`, {
        name: reportType?.name,
        interpolation: { escapeValue: false }
      })}
      onConfirm={handleSubmit(_onSubmit)}
      onCancel={closeDialog}
      maxWidth="md"
      isConfirmLoading={isLoading}
      isLoading={areReportTypesLoading}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <form>
          <GridLayout itemProps={{ xs: 12 }}>{fields?.map(element => element)}</GridLayout>
        </form>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default ReportTypeGenerationDialog;
