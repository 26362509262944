import { useTranslation } from 'react-i18next';
import { ColumnInstance } from 'react-table';
import { TableCell as MuiTableCell, TableSortLabel as MuiTableSortLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { TableColumnHeaderDeleteIcon } from '@libs/common/v2';
import { Theme, useTheme } from '@libs/common/v2/theme';
import { calc, hexToRgba, important } from '@libs/common/v2/utils';

import { useTableAdapter, useTableContext } from '../hooks';

import TableCellContainer from './TableCellContainer';

interface IProps {
  column: ColumnInstance & {
    originalId?: string;
    canResize?: boolean;
    getResizerProps?: any;
    isResizing?: boolean;
    isErasable?: boolean;
    isCellAlignRight?: boolean;
    isRequired?: boolean;
  };
  isLastColumn: boolean;
  setHeadCells?: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        ref: React.MutableRefObject<HTMLElement>;
      }[]
    >
  >;
}
function TableHeadCell({
  column: {
    sortedIndex,
    canSort,
    isSorted,
    isSortedDesc,
    getSortByToggleProps,
    getHeaderProps,
    render,
    canResize,
    getResizerProps = null,
    isResizing,
    Header = null,
    isErasable,
    originalId,
    id,
    isCellAlignRight,
    isRequired
  },
  isLastColumn,
  setHeadCells
}: IProps) {
  const [t] = useTranslation();
  const table = useTableAdapter();
  const {
    state: { sortBy }
  } = table;
  const { contrast } = useTheme();
  const { checkDisabled, isError } = useTableContext();
  const isMultiSorted = sortBy?.length > 1;

  const classes = useStyles({ sortedIndex, contrast });

  return (
    <MuiTableCell
      {...getHeaderProps()}
      className={classes.root}
      component="div"
      variant="head"
      ref={(ref: React.MutableRefObject<HTMLElement>) => {
        setHeadCells(prev => {
          if (prev.some(r => r.name === id)) {
            return prev;
          }
          return [...prev, { name: id, ref }];
        });
      }}
    >
      {canSort && !checkDisabled(table) && !isError ? (
        <MuiTableSortLabel
          focusRipple
          disableRipple={false}
          active={isSorted}
          direction={isSortedDesc ? 'desc' : 'asc'}
          classes={{
            root: clsx(classes.label, {
              [classes.sortedColumn]: isSorted,
              [classes.multiSortLabel]: isMultiSorted && isSorted
            }),
            icon: classes.sortIcon
          }}
          {...getSortByToggleProps()}
          onClick={e => {
            const props = getSortByToggleProps() as any;
            props.onClick(e);
            table.onSortChange?.();
          }}
          title={`${t('tables:sortBy')} ${Header || ''}`}
          data-testid={`tableHeader-${Header || ''}`}
        >
          <TableCellContainer padding="0" isHeaderCell isCellAlignRight={isCellAlignRight}>
            {render('Header')}
            {isRequired && <span className={classes.requiredAsterisk}>*</span>}
          </TableCellContainer>
        </MuiTableSortLabel>
      ) : (
        <TableCellContainer isHeaderCell isCellAlignRight={isCellAlignRight}>
          {render('Header')}
          {isRequired && <span className={classes.requiredAsterisk}>*</span>}
          {isErasable && <TableColumnHeaderDeleteIcon id={originalId || id} />}
        </TableCellContainer>
      )}
      {canResize && !isLastColumn && (
        <div {...(getResizerProps?.() ?? {})} className={clsx(classes.resizer, isResizing && classes.isResizing)} />
      )}
    </MuiTableCell>
  );
}

const useStyles = makeStyles<Theme, { contrast: boolean; sortedIndex: number }>(theme => ({
  root: {
    display: 'flex !important',
    alignItems: 'center',
    ...theme.typography.textXs.medium,
    color: theme.palette.grey[700],
    '& .MuiTableSortLabel-root:focus,.MuiTableSortLabel-root:hover': {
      color: theme.palette.grey[700],
      backgroundColor: ({ contrast }) => contrast && hexToRgba(theme.palette.grey[700], 0.1)
    }
  },
  sortedColumn: {
    ...theme.typography.textXs.semibold,
    color: ({ contrast }) =>
      contrast ? important(theme.palette.contrastIndicators.background) : important(theme.palette.blue[800])
  },
  label: {
    position: 'relative',
    padding: '5px 10px',
    borderRadius: 8,
    width: calc('100% - 20px')
  },
  multiSortLabel: {
    '&::after': {
      content: ({ sortedIndex }: any) => `'${sortedIndex + 1}'`,
      position: 'absolute',
      display: 'block',
      width: 12,
      height: 12,
      right: 8,
      ...theme.typography.textXs.semibold,
      color: theme.palette.blue[800],
      textAlign: 'center',
      boxSizing: 'content-box',
      borderRadius: 2
    }
  },
  sortIcon: {
    position: 'relative',
    width: 16,
    height: 16,
    '& > path': {
      fill: ({ contrast }) => (contrast ? theme.palette.contrastIndicators.background : theme.palette.blue[800])
    }
  },
  resizer: {
    display: 'inline-block',
    background: 'transparent',
    width: 8,
    height: '100%',
    position: 'absolute',
    right: 4,
    top: 0,
    transform: 'translateX(50%)',
    // /* prevents from scrolling while dragging on touch devices */}
    touchAction: 'none',
    userSelect: 'none',
    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      top: 12,
      width: 1,
      right: 2,
      height: 28,
      borderRightWidth: '1.5px',
      borderRightStyle: 'solid',
      borderRightColor: theme.palette.grey[300]
    }
  },
  isResizing: {
    // Jeśli w przyszłości będzie potrzeba nadpisania stylu resizera w trakcie rozszerzania kolumn,
    // można go wpisać tu
    '&:after': {}
  },
  requiredAsterisk: {
    color: theme.palette.error[500],
    marginLeft: theme.marginBase
  }
}));

export default TableHeadCell;
