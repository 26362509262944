import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { DefaultApiGetAllDictionariesRequest, DictionarySnapshot } from '@avispon/dictionary';
import _ from 'lodash';

import { useQueryCache } from '@libs/common/v2/api';

import { API, DictionaryQueryKeysEnum } from '@libs/dictionary/api';

export default function useGetQuicklyChangeableDictionaryInfo(dictionaryKey: string) {
  const queryCache = useQueryCache();

  async function getDictionaries(params: DefaultApiGetAllDictionariesRequest) {
    const { data } = await API.dictionary.getAllDictionaries(params ?? {});
    return data?.content;
  }

  const [dictionary, setDictionary] = useState<DictionarySnapshot>({} as DictionarySnapshot);
  const query = useQuery(
    [DictionaryQueryKeysEnum.DICTIONARY_LIST, { includeQuicklyChangeable: true }],
    () => getDictionaries({ includeQuicklyChangeable: true }),
    {
      enabled: !queryCache.getQuery([DictionaryQueryKeysEnum.DICTIONARY_LIST, { includeQuicklyChangeable: true }])
    }
  );

  useEffect(() => {
    const { data } = query;
    if (data) {
      const result = data.find(item => item.name === dictionaryKey);
      setDictionary(result);
    }
  }, [query, dictionaryKey]);

  return { dictionary, query: _.omit(query, 'data') };
}
