import { ForeignAuthorityPermissionUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

async function updateForeignPermission({
  permissionId,
  formData
}: {
  permissionId: string;
  formData: ForeignAuthorityPermissionUpdateRequest;
}) {
  const { data } = await API.permissions.updateForeignAuthorityPermission(permissionId, formData, {
    ...endpointsConfig.updateForeignAuthorityPermission
  });
  return data;
}

function useUpdateForeignAuthorityPermissionMutation() {
  return useMutation(updateForeignPermission);
}

export default useUpdateForeignAuthorityPermissionMutation;
