import React, { ReactNode, useRef, useState } from 'react';
import { Icon, IconButton, Popover as PopoverMui, Tooltip } from '@mui/material';
import { PopoverProps as PopoverPropsMui } from '@mui/material/Popover';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

import { Theme } from '@libs/common/v2/theme';

interface PopoverButtonProps {
  disabled?: boolean;
  tooltipText?: string;
  icon?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

function PopoverButton({ disabled, icon, tooltipText, onClick }: PopoverButtonProps) {
  const classes = useStyles();

  const iconButton: JSX.Element = (
    <IconButton disabled={disabled} classes={{ root: classes.root }} onClick={onClick}>
      <Icon>{icon}</Icon>
    </IconButton>
  );

  return tooltipText ? (
    <Tooltip title={tooltipText}>
      <span>{iconButton}</span>
    </Tooltip>
  ) : (
    iconButton
  );
}

interface PopoverProps {
  disabled?: boolean;
  tooltipText?: string;
  icon?: string;
  children?:
    | (({ onClose }: { onClose: () => void }) => JSX.Element | JSX.Element[] | ReactNode)
    | JSX.Element
    | JSX.Element[]
    | ReactNode;
  renderOuterElement?: (renderProps: PopoverRenderProps) => JSX.Element;
}

export interface PopoverRenderProps {
  onClose: any;
  onOpen: any;
}

function Popover(props: PopoverProps & Omit<PopoverPropsMui, 'open' | 'children'>) {
  const { children, disabled, icon, tooltipText, renderOuterElement, ...popoverProps } = props;
  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const popoverOpen = !!anchorEl;

  const handlePopoverOpen = (): void => {
    setAnchorEl(anchorRef.current);
  };

  const handlePopoverClose = (): void => {
    setAnchorEl(null);
  };

  const renderProps: PopoverRenderProps = {
    onOpen: handlePopoverOpen,
    onClose: handlePopoverClose
  };

  const outerElement: JSX.Element = renderOuterElement ? (
    renderOuterElement(renderProps)
  ) : (
    <PopoverButton tooltipText={tooltipText} icon={icon} disabled={disabled} onClick={handlePopoverOpen} />
  );

  return (
    <>
      <div className="inline-block" ref={anchorRef}>
        {outerElement}
      </div>
      <PopoverMui
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        {...popoverProps}
      >
        {_.isFunction(children) ? children(renderProps) : children}
      </PopoverMui>
    </>
  );
}
Popover.displayName = 'Popover';

Popover.defaultProps = {
  icon: 'more_vert'
};

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    padding: 9,
    color: theme.palette.grey[300],
    fill: theme.palette.grey[300]
  }
}));

export default Popover;
