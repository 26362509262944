import { forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppConfig } from '@libs/app-config';

import { Button, TableButtonColumnPicker, useTableContext } from '@libs/common/v2';

import { UserUIElementEnum } from '../../config';

interface IProps {
  setModalIsOpen: (value: boolean) => void;
  canAddGroup: boolean;
  isLoading: boolean;
}

function UserGroupFormHeaderActions(props: IProps, ref) {
  const [t] = useTranslation();
  const { hideColumnPicker } = useAppConfig();
  const { clear, refetch } = useTableContext();
  const { canAddGroup, isLoading, setModalIsOpen } = props;

  useImperativeHandle(
    ref,
    () => ({
      refetchTable: () => refetch(),
      clearTable: () => clear()
    }),
    [clear, refetch]
  );

  return (
    <>
      {!hideColumnPicker && <TableButtonColumnPicker />}
      <Button
        label={canAddGroup ? t('user:action.addPermissionGroup') : t('user:dialog.groupAdminPermissionRequired')}
        onClick={() => setModalIsOpen(true)}
        variant="outlined"
        disabled={!canAddGroup || isLoading}
        isLoading={isLoading}
        actionKey={UserUIElementEnum.USERS_GROUPS_UPDATE}
      />
    </>
  );
}

export default forwardRef(UserGroupFormHeaderActions);
