import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '@enigma/fe-ui';
import { AlertApiGetAlertTransitionsHistoryRequest } from '@stack/report';

import { TableButtonDownload, TableLayout, useDevMode } from '@libs/common/v2';

import { API, useDownloadAlertDataFilesMutation } from '@libs/alert/api';
import { useAlertHistoryTable } from '@libs/alert/hooks';
import { useFileDownload } from '@libs/file';

import { IAlertDetailsParams } from '../../model';

function AlertHistoryTable() {
  const [t] = useTranslation();
  const { id: alertId } = useParams<IAlertDetailsParams>();

  const { isDevMode } = useDevMode();
  const { download, isLoading } = useFileDownload();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutateAsync: downloadAlertDataFiles } = useDownloadAlertDataFilesMutation({
    onSuccess: () => {
      showSuccessSnackbar(t('alerts:message.downloadSuccess'));
    }
  });

  const tableProps = useAlertHistoryTable(alertId);

  const handleDownloadFile = async (alertId: string) => {
    download(() => downloadAlertDataFiles(alertId));
  };

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('alerts:tab.history')}
      xlsxDownload={{
        fileName: t('alerts:tab.history'),
        pathToXLSXTranslation: 'alerts:table',
        apiRequest: (params: AlertApiGetAlertTransitionsHistoryRequest) => {
          const { page, size, sort, alertIds, createdFrom, createdTo, currentStatusKeys, previousStatusKeys } = params;
          return API.alert.getAlertTransitionsHistory(
            page,
            size,
            sort,
            alertIds,
            createdFrom,
            createdTo,
            currentStatusKeys,
            previousStatusKeys
          );
        }
      }}
      isSection
      isActionColumnEnabled
      rowActions={({ original }) =>
        isDevMode && original?.fileId ? (
          <TableButtonDownload onClick={() => handleDownloadFile(original.id)} isDisabled={isLoading} />
        ) : null
      }
    />
  );
}

export default AlertHistoryTable;
