import { FolderLimitTransferRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function transferLimit({ folderLimitUpdateRequest }: { folderLimitUpdateRequest: FolderLimitTransferRequest }) {
  return API.folderLimits.transferFolderLimit(folderLimitUpdateRequest, {
    ...endpointsConfig.transferFolderLimit
  });
}

function useTransferFolderLimitMutation() {
  return useMutation(transferLimit);
}

export default useTransferFolderLimitMutation;
