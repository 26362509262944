import { useTranslation } from 'react-i18next';

import { Dialog } from '@libs/common/v2';

interface Props {
  onClose: () => void;
  onConfirm: () => Promise<any>;
  message?: string;
}

function ApplicationAssignEditorDialog({ onClose, onConfirm, message }: Props) {
  const [t] = useTranslation();

  return (
    <Dialog
      title={t('prints:messages.confirmAplicationAssign')}
      confirmText={t('prints:actions.assign')}
      cancelText={t('action.cancel')}
      contentText={`${message ? `${message}. ` : ''}${t('applications:message.confirmAssign')}`}
      onConfirm={async setLoading => {
        setLoading(true);
        await onConfirm();
        setLoading(false);
        onClose();
      }}
      onCancel={onClose}
      isOpen
    />
  );
}

export default ApplicationAssignEditorDialog;
