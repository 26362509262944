import React from 'react';

import { NumberInputField, useFormV2Context } from '@libs/common/v2';

import { useMetaFormContext } from '@libs/meta-form/context';
import { FieldProps, FieldTypes, NumberFieldProperties } from '@libs/meta-form/models';
import { useGetQuery, useMapAdditionalFieldsValidationType } from '@libs/meta-form/services';

function NumberField({
  fieldId,
  label,
  api,
  disabled,
  inputMode,
  defaultInitialValue,
  max,
  min,
  step,
  yupValidation,
  // Props służący do przekazania nazwy pola, które przekazywane jest do funkcji `trigger` pozwalając na rewalidację jednego pola po przez zmianńę wartości w drugim polu
  triggerFieldIdValidationOnChange
}: FieldProps<NumberFieldProperties>) {
  const { setValue, trigger } = useFormV2Context();
  useMapAdditionalFieldsValidationType(fieldId, { type: FieldTypes.NUMBER });

  const { apiRegistry } = useMetaFormContext();

  useGetQuery({
    requestKey: api?.get?.requestKey,
    accessor: api?.get?.accessor,
    apiRegistry,
    params: api?.get?.backendParams,
    setValue: newValue => setValue(fieldId, newValue ?? defaultInitialValue),
    fieldId
  });

  const customHandleChange = () => {
    if (triggerFieldIdValidationOnChange?.length) {
      trigger(triggerFieldIdValidationOnChange);
    }
  };

  return (
    <NumberInputField
      name={fieldId}
      max={max}
      min={min}
      step={step}
      isRequired={yupValidation?.required}
      label={label}
      onChange={customHandleChange}
      isDisabled={disabled}
      inputMode={inputMode}
    />
  );
}

export default NumberField;
