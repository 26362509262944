import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { i18n } from '@libs/common';
import { Dialog, FormMode, FormV2Context, GridItem, GridLayout, TextInputField, typedNameV2 } from '@libs/common/v2';

import { ChangePasswordValues } from '@libs/user';

const VALIDATION_SCHEMA = Yup.object({
  newPassword2: Yup.string()
    .required()
    .oneOf([Yup.ref('newPassword1'), null], i18n.t('validation:passwordMatch'))
});

const INITIAL_VALUES: ChangePasswordValues = {
  newPassword1: '',
  newPassword2: ''
};

interface IProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: (data: any) => any;
}

function RestoreUserWithNewPasswordDialog({ isOpen, onCancel, onConfirm }: IProps) {
  const [t] = useTranslation();
  const form = useForm<Record<string, any>>({
    defaultValues: INITIAL_VALUES,
    resolver: yupResolver(VALIDATION_SCHEMA),
    mode: 'onBlur'
  });

  const formDataProvider = useMemo(() => {
    return { ...form, formMode: FormMode.EDIT };
  }, [form]);

  const onSubmit = formValues => {
    onConfirm(formValues);
  };

  return (
    <Dialog
      title={t('user:dialog.userRenewTitle')}
      confirmText={t('user:action.renew')}
      cancelText={t('action.cancel')}
      onConfirm={form.handleSubmit(onSubmit)}
      onCancel={onCancel}
      dialogSize="small"
      isOpen={isOpen}
    >
      <FormV2Context.Provider value={formDataProvider}>
        <GridLayout>
          <GridItem xs={12}>
            <TextInputField
              name={typedNameV2<ChangePasswordValues>('newPassword1')}
              label={t('user:field.newPassword1')}
              inputProps={{ type: 'password' }}
              isRequired
            />
          </GridItem>
          <GridItem xs={12}>
            <TextInputField
              name={typedNameV2<ChangePasswordValues>('newPassword2')}
              label={t('user:field.newPassword2')}
              inputProps={{ type: 'password' }}
              isRequired
            />
          </GridItem>
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default RestoreUserWithNewPasswordDialog;
