import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { FinancialSecuritySummarySnapshot } from '@ibtm/domain';

import { Dialog, FormV2Context, InputMode, Section, useValidationBuilder } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { getMetaFormQueryKey } from '@libs/meta-form/services/useGetQuery';

import { useUpdateApplicationFinancialSecuritySummaryMutation } from '@libs/domain/application';

import { FinancialSecurityQueryKeysEnum } from '../../../api';
import FinancialSecurityApplicationExchangeFields, {
  IFinancialSecurityApplicationExchangeFields
} from '../summary/FinancialSecurityApplicationExchangeFields';

export interface IFinancialSecurityApplicationExchangeDialogProps
  extends Omit<IFinancialSecurityApplicationExchangeFields, 'fieldId'> {
  closeDialog: () => void;
  applicationId: string;
}

export function FinancialSecurityApplicationExchangeDialog({
  created,
  folderType,
  applicationId,
  closeDialog
}: IFinancialSecurityApplicationExchangeDialogProps) {
  const [t] = useTranslation();
  const { validationScheme, validationBuilderFunctions } = useValidationBuilder();
  const formMethods = useForm<FinancialSecuritySummarySnapshot>({ resolver: yupResolver(validationScheme) });
  const { showSuccessSnackbar } = useSnackbar();
  const { handleSubmit } = formMethods;
  const formContext = useMemo(
    () => ({ ...formMethods, validationBuilderFunctions }),
    [formMethods, validationBuilderFunctions]
  );

  const { mutate: updateApplicationFinancialSecuritySummary, isLoading } =
    useUpdateApplicationFinancialSecuritySummaryMutation();
  const queryCache = useQueryCache();
  const handleSubmitForm = handleSubmit(formData => {
    const { requireAmountPln, requireAmountEur, version } = formData;
    const data = { requiredAmountPln: requireAmountPln, requiredAmountEur: requireAmountEur, version };
    updateApplicationFinancialSecuritySummary(
      {
        applicationId,
        formData: data
      },
      {
        onSuccess: ({ data: updatedData }) => {
          showSuccessSnackbar(t('financialSecurity:section.summary.edit.editRequiredFinancialSecuritySuccess'));
          queryCache.setQueryData(
            [FinancialSecurityQueryKeysEnum.FINANCIAL_SECURITY_SUMMARY, applicationId],
            updatedData
          );
          queryCache.invalidateQueries([getMetaFormQueryKey('GET_FINANCIAL_SECURITY'), { applicationId }]);
          closeDialog();
        }
      }
    );
  });
  return (
    <Dialog
      title={t('financialSecurity:section.summary.dialog.editRequiredFinancialSecurityTitle')}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      isConfirmLoading={isLoading}
      onConfirm={handleSubmitForm}
      onCancel={closeDialog}
      isOpen
    >
      <FormV2Context.Provider value={formContext}>
        <Section isHeaderVisible={false} isModalSection>
          <FinancialSecurityApplicationExchangeFields
            created={created}
            folderType={folderType}
            inputMode={InputMode.FORM}
            applicationId={applicationId}
            visibleFields={['requireAmountEurInput', 'requireAmountPlnInput']}
            gridItemProps={{ xs: 12 }}
          />
        </Section>
      </FormV2Context.Provider>
    </Dialog>
  );
}
