import { useEffect, useState } from 'react';

import { useDictionaryEntriesQuery } from '@libs/dictionary';
import { usePermissionsQuery } from '@libs/group';

import { DomainDictionaryEntry, DomainDictionaryEnum, PermissionEnum } from '../../config';

export type TAction = 'update' | 'view' | 'create';

const applicationTypePrefix = /ibtm-domain\.application\.type\./;
const applicationCrudPermissionPattern = 'application-crud';
const applicationTypeCrudPrefix = 'group.permission.ibtm-domain.application-crud.';

export const applicationGeneralPermission: {
  [key in TApplicationCrudPermissionParams['action'] | 'basicInformationView']: PermissionEnum | null;
} = {
  create: PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_CREATE,
  update: PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_UPDATE,
  view: PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_DETAILS_VIEW,
  basicInformationView: PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_BASIC_INFORMATION_VIEW
};

const isWL = (applicationType: string) => {
  return Boolean(applicationType.match(/license/));
};

const isTypeWithAdditionalPermissions = (applicationType: string) => {
  const {
    restoreOfDriverCertificate,
    changeOfDriverCertificate,
    issuingDuplicateDriverCertificate,
    inputMistake,
    issueOfDriverCertificate,
    PB1A,
    PB4B,
    PB5,
    PB6A,
    PB6B,
    PB6C,
    PB7A,
    PB7B,
    PB8A,
    PB1B,
    PB8B,
    PB9A,
    PB9B,
    PB10B,
    PB10A,
    PB11A,
    PB11B,
    PB11C,
    PB11D,
    PB12,
    PB1C,
    PB14,
    PB6D,
    PB2A,
    PB3A,
    PB3B,
    PB4C,
    PB4D,
    PB4A,
    licenseIssuingCertificate,
    licenseDischargeWaiver,
    licenseFinancialSecurityUpdate,
    PB26,
    PB33,
    PB27,
    PB28,
    PB29A,
    PB29B,
    PB30,
    PB31,
    PB32A,
    PB32B
  } = DomainDictionaryEntry.APPLICATION_TYPE;

  const typesWithAdditionalPermissions = [
    restoreOfDriverCertificate,
    changeOfDriverCertificate,
    issuingDuplicateDriverCertificate,
    inputMistake,
    issueOfDriverCertificate,
    PB1A,
    PB4B,
    PB5,
    PB6A,
    PB6B,
    PB6C,
    PB7A,
    PB7B,
    PB8A,
    PB1B,
    PB8B,
    PB9A,
    PB9B,
    PB10B,
    PB10A,
    PB11A,
    PB11B,
    PB11C,
    PB11D,
    PB12,
    PB1C,
    PB14,
    PB6D,
    PB2A,
    PB3A,
    PB3B,
    PB4C,
    PB4D,
    PB4A,
    licenseIssuingCertificate,
    licenseDischargeWaiver,
    licenseFinancialSecurityUpdate,
    PB26,
    PB33,
    PB27,
    PB28,
    PB29A,
    PB29B,
    PB30,
    PB31,
    PB32A,
    PB32B
  ];

  return typesWithAdditionalPermissions.includes(applicationType);
};

const isWLWithoutPPO = (applicationType: string) => {
  const {
    PB1A,
    PB2B,
    PB4B,
    PB5,
    PB6A,
    PB6B,
    PB6C,
    PB7A,
    PB7B,
    PB8A,
    PB1B,
    PB8B,
    PB9A,
    PB9B,
    PB10B,
    PB10A,
    PB11A,
    PB11B,
    PB11C,
    PB11D,
    PB12,
    PB1C,
    PB14,
    PB6D,
    PB2A,
    PB3A,
    PB3B,
    PB4C,
    PB4D,
    PB4A
  } = DomainDictionaryEntry.APPLICATION_TYPE;

  const WLTypes = [
    PB1A,
    PB2B,
    PB4B,
    PB5,
    PB6A,
    PB6B,
    PB6C,
    PB7A,
    PB7B,
    PB8A,
    PB1B,
    PB8B,
    PB9A,
    PB9B,
    PB10B,
    PB10A,
    PB11A,
    PB11B,
    PB11C,
    PB11D,
    PB12,
    PB1C,
    PB14,
    PB6D,
    PB2A,
    PB3A,
    PB3B,
    PB4C,
    PB4D,
    PB4A
  ];
  return WLTypes.includes(applicationType);
};

const isWLPPOOnly = (applicationType: string) => {
  const {
    PB26,
    PB33,
    PB27,
    PB28,
    PB29A,
    PB29B,
    PB30,
    PB31,
    PB32A,
    PB32B,
    licenseFinancialSecurityUpdate,
    licenseIssuingCertificate,
    licenseDischargeWaiver
  } = DomainDictionaryEntry.APPLICATION_TYPE;
  const PPOTypes = [
    PB26,
    PB33,
    PB27,
    PB28,
    PB29A,
    PB29B,
    PB30,
    PB31,
    PB32A,
    PB32B,
    licenseFinancialSecurityUpdate,
    licenseIssuingCertificate,
    licenseDischargeWaiver
  ];
  return PPOTypes.includes(applicationType);
};

const isDriverCertificateInputMistake = (applicationType: string) => {
  return applicationType.match(/input-mistake/);
};

const isDriverCertificate = (applicationType: string) => {
  return applicationType.match(/driver-certificate/) || isDriverCertificateInputMistake(applicationType);
};

const isSPO = (applicationType: string) => {
  return applicationType.match(/spo-/);
};

const isForeign = (applicationType: string) => {
  return applicationType.match(/foreign/);
};

const isSCertificate = (applicationType: string) => {
  return applicationType.match(/s-certificate/);
};

const isSPOType = (applicationType: string) => {
  const match = (pattern: RegExp) => Boolean(applicationType.match(pattern));
  return {
    spoOwnNeeds: match(/spo-own-needs/),
    spoTransitRP: match(/spo-transit-rp/),
    spoUe: match(/spo-ue/),
    spoOutsideUe: match(/spo-outside-ue/),
    spoPositionMind: match(/spo-position-mind/),
    spoWzo: match(/spo-wzo/),
    spoWzw: match(/spo-wzw/)
  };
};

const isTravelFormInterbus = (applicationType: string) => {
  return applicationType.match(/travel-form-ue/);
};

const applicationCrudPermissions: Array<string> = [];

export type TApplicationCrudPermissionParams = {
  action: TAction;
  applicationType: string;
  isLoading?: boolean;
};

export const getPermissionsForApplicationType = ({
  applicationType,
  action,
  isLoading
}: TApplicationCrudPermissionParams): Array<string> => {
  if (isLoading) {
    return [];
  }
  if (!applicationType) {
    throw new Error('applicationType is required, set isLoading if waiting for applicationType to be loaded');
  }

  if (applicationCrudPermissions.length === 0) {
    applicationCrudPermissions.push(
      ...Object.values(PermissionEnum).filter(value => value.includes(applicationCrudPermissionPattern))
    );
  }

  const applicationTypeSuffix = applicationType.replace(applicationTypePrefix, '');

  const findCrudPermission = (permissionIncludes: string) => {
    return applicationCrudPermissions.find(value => value.match(permissionIncludes));
  };

  const getViewPermissions = () => {
    // eslint-disable-next-line no-useless-escape
    const viewPermissionPattern = `${applicationTypeSuffix}(\.details)?\.view`;
    if (isTypeWithAdditionalPermissions(applicationType)) {
      const viewTypePermission = `${applicationTypeCrudPrefix}${applicationTypeSuffix}.view`;
      const viewCrudPermission = findCrudPermission(viewPermissionPattern);

      return [viewTypePermission, viewCrudPermission];
    }

    if (isWL(applicationType)) {
      const viewAllCategory = PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_WL_ALL_VIEW;
      const viewType = findCrudPermission(viewPermissionPattern);

      return [viewAllCategory, viewType];
    }

    if (isSPO(applicationType)) {
      const viewAllCategory = PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_SPO_ALL_VIEW;
      const viewType = findCrudPermission(viewPermissionPattern);
      return [viewAllCategory, viewType];
    }
    if (isDriverCertificate(applicationType)) {
      const viewAllCategory = PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_DRIVER_CERTIFICATE_ALL_VIEW;
      const viewType = findCrudPermission(viewPermissionPattern);
      return [viewAllCategory, viewType];
    }

    return [];
  };

  const getUpdatePermissions = () => {
    const updatePermissionPattern = `${applicationTypeSuffix}.update`;

    if (isTypeWithAdditionalPermissions(applicationType)) {
      const updateTypePermission = `${applicationTypeCrudPrefix}${applicationTypeSuffix}.update`;
      const updateCrudPermission = findCrudPermission(updatePermissionPattern);

      return [updateTypePermission, updateCrudPermission];
    }

    if (isWLWithoutPPO(applicationType)) {
      const editAllWL = PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_WL_ALL_UPDATE;
      const viewType = findCrudPermission(updatePermissionPattern);
      return [editAllWL, viewType];
    }
    if (isWLPPOOnly(applicationType)) {
      const editAllPPO = PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_PPO_ALL_UPDATE;
      const viewType = findCrudPermission(updatePermissionPattern);
      return [editAllPPO, viewType];
    }

    if (isSPO(applicationType)) {
      const editAllSPO = PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_SPO_ALL_UPDATE;
      const { spoOwnNeeds } = isSPOType(applicationType);
      const viewType = findCrudPermission(updatePermissionPattern);

      return [
        editAllSPO,
        viewType,
        spoOwnNeeds || PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_SPO_OWN_NEEDS_ALL_UPDATE
      ].filter((i): i is string => typeof i === 'string');
    }
    if (isDriverCertificate(applicationType)) {
      const editAllDriverCertificate = PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_DRIVER_CERTIFICATE_ALL_UPDATE;
      const editType = findCrudPermission(updatePermissionPattern);
      return [editAllDriverCertificate, editType];
    }

    return [];
  };

  const getCreatePermissions = () => {
    const createPermissionPattern = `${applicationTypeSuffix}.create`;

    if (isTypeWithAdditionalPermissions(applicationType)) {
      const viewAllCategory = `${applicationTypeCrudPrefix}${applicationTypeSuffix}.create`;
      const viewType = findCrudPermission(viewAllCategory);

      return [viewAllCategory, viewType];
    }

    if (isWLWithoutPPO(applicationType)) {
      const createAllWL = PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_WL_ALL_CREATE;
      const createType = findCrudPermission(createPermissionPattern);
      return [createAllWL, createType];
    }
    if (isWLPPOOnly(applicationType)) {
      const createAllPPO = PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_PPO_ALL_CREATE;
      const createType = findCrudPermission(createPermissionPattern);
      return [createAllPPO, createType];
    }

    if (isDriverCertificate(applicationType)) {
      const createAllDriverCertificate = PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_DRIVER_CERTIFICATE_ALL_CREATE;
      const createType = findCrudPermission(createPermissionPattern);
      return [createAllDriverCertificate, createType];
    }

    if (isSPO(applicationType)) {
      const createAllSPO = PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_SPO_ALL_CREATE;
      const { spoOutsideUe, spoOwnNeeds, spoPositionMind, spoTransitRP, spoUe, spoWzo, spoWzw } =
        isSPOType(applicationType);

      const createType = findCrudPermission(createPermissionPattern);
      return [
        createAllSPO,
        createType,
        spoOutsideUe || PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_SPO_OUTSIDE_UE_ALL_CREATE,
        spoOwnNeeds || PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_SPO_OWN_NEEDS_ALL_CREATE,
        spoPositionMind || PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_SPO_POSITION_MIND_ALL_CREATE,
        spoTransitRP || PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_SPO_TRANSIT_RP_ALL_CREATE,
        spoUe || PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_SPO_UE_ALL_CREATE,
        spoWzo || PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_SPO_WZO_ALL_CREATE,
        spoWzw || PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_SPO_WZW_ALL_CREATE
      ].filter((i): i is string => typeof i === 'string');
    }

    if (isSCertificate(applicationType)) {
      const createType = findCrudPermission(createPermissionPattern);
      return [createType];
    }

    if (isTravelFormInterbus(applicationType)) {
      const createType = findCrudPermission(createPermissionPattern);
      return [createType];
    }

    if (isForeign(applicationType)) {
      const createAllWZZ = PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_WZZ_ALL_CREATE;
      const createType = findCrudPermission(createPermissionPattern);
      return [createAllWZZ, createType];
    }
    return [];
  };

  const crudPermissions: { [key in TAction]: () => Array<string> } = {
    view: getViewPermissions,
    update: getUpdatePermissions,
    create: getCreatePermissions
  };

  return crudPermissions[action]().filter(Boolean);
};

export const useCheckApplicationPermissions = () => {
  const [enabled, setEnabled] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { data: permissionsData, isFetching: arePermissionsFetching } = usePermissionsQuery(null, {
    enabled
  });
  const { data: dictionaryData, isFetching: areDictionariesFetching } = useDictionaryEntriesQuery(
    {
      dictionary: DomainDictionaryEnum.APPLICATION_TYPE,
      size: 1000,
      page: 0,
      sort: [],
      language: 'pl',
      status: 'ALL'
    },
    {
      enabled
    }
  );
  useEffect(() => {
    if (
      !dictionaryData?.content ||
      !permissionsData?.permissions ||
      areDictionariesFetching ||
      arePermissionsFetching ||
      !enabled
    ) {
      return;
    }
    const allActions: { [key in TAction]: key } = {
      create: 'create',
      update: 'update',
      view: 'view'
    };
    const applicationTypes = dictionaryData.content.map(({ key }) => key);

    const allBEPermissions: Array<string> =
      permissionsData?.permissions
        ?.map(({ name }) => name)
        .filter(item => item.match(applicationCrudPermissionPattern)) ?? [];

    const getPermissionsForAllActionsForAllApplicationTypes = () => {
      const permissionsList: Array<string> = Object.keys(allActions).flatMap(action => {
        return applicationTypes.flatMap(applicationType => {
          return getPermissionsForApplicationType({
            action: action as TAction,
            applicationType
          });
        });
      });
      permissionsList.push(...Object.values(applicationGeneralPermission).filter(Boolean));
      const uniqPermissions = Array.from(new Set(permissionsList));
      return uniqPermissions;
    };

    const allFEPermissions = getPermissionsForAllActionsForAllApplicationTypes();

    // eslint-disable-next-line no-console
    console.log('Application CRUD permissions result: ', {
      allBEPermissions,
      allFEPermissions,
      FEnotIncludedInBE: allFEPermissions.filter(item => !allBEPermissions.includes(item)),
      BEnotIncludedInFE: allBEPermissions.filter(item => !allFEPermissions.includes(item))
    });
    setIsSuccess(true);
    setEnabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areDictionariesFetching, arePermissionsFetching, dictionaryData?.content, permissionsData?.permissions]);

  return {
    check: () => {
      setEnabled(true);
      setIsSuccess(false);
    },
    isLoading: areDictionariesFetching || arePermissionsFetching,
    isSuccess
  };
};
