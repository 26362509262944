import { useRef, useState } from 'react';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Theme } from '../../../theme';
import { important } from '../../../utils';

interface IProps {
  children: React.ReactNode;
  padding?: string;
  style?: Record<string, any>;
  width?: number;
  isHeaderCell?: boolean;
  isViewMode?: boolean;
  className?: string;
  tooltipTitle?: any;
  isCellAlignRight?: boolean;
  isTooltipHidden?: boolean;
}

function TableCellContainer({
  children,
  padding,
  style,
  width,
  isHeaderCell,
  isViewMode,
  className,
  tooltipTitle,
  isCellAlignRight = false,
  isTooltipHidden
}: IProps) {
  const ref = useRef(null);
  const [isTooltipAvailable, setIsTooltipAvailable] = useState<boolean>(false);
  const styles = useStyles();
  const disabled = !isTooltipAvailable || !isViewMode;

  const getCellWidth = () => {
    if (isCellAlignRight) return '100%';
    if (isHeaderCell) return '100%';

    if (ref?.current?.offsetWidth > width) {
      return 'calc(100% - 20px)';
    }

    return '100%';
  };
  const CellContent = (
    <div style={{ width: getCellWidth() }}>
      <div className="truncate">{children}</div>
    </div>
  );

  return (
    <div
      ref={ref}
      className={className}
      style={{
        padding: padding || 10,
        ...(isHeaderCell ? { maxWidth: '100%' } : { width: '100%' }),
        ...(isHeaderCell && isCellAlignRight ? { marginLeft: 'auto', marginRight: 'auto' } : {}),
        ...style
      }}
      onMouseEnter={() => {
        if (!isTooltipHidden && ref?.current?.offsetWidth > width) {
          setIsTooltipAvailable(true);
        }
      }}
      onMouseLeave={() => {
        setIsTooltipAvailable(false);
      }}
    >
      {isTooltipAvailable ? (
        <Tooltip
          title={<span className={styles.tooltipTitle}>{tooltipTitle || children}</span>}
          disableHoverListener={!isTooltipAvailable}
          disableFocusListener={disabled}
          disableInteractive={disabled}
          disableTouchListener={disabled}
          classes={{ popper: styles.popper }}
        >
          {CellContent}
        </Tooltip>
      ) : (
        CellContent
      )}
    </div>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  tooltipTitle: {
    '& a': {
      color: theme.palette.white,
      textDecoration: 'none',
      overflow: 'scroll'
    },
    '& h1': {
      color: important(theme.palette.white)
    }
  },
  popper: {
    '& > *': {
      maxHeight: '500px',
      overflowY: 'auto'
    }
  }
}));

export default TableCellContainer;
