import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSnackbar } from '@enigma/fe-ui';
import { EkmtCarnetSettlementDetails, ForeignPermissionWithSettlementDetails } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import { Button, TableIconButton, TableLayout, typedNameV2, useConfirmDialog, useFormV2Context } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { IDisposablePermitsDetailsParams } from '@libs/domain/social-commission';

import { useDeleteEkmtCarnetSettlementMutation } from '../../api';
import { usePermitDialog, usePermitEkmttemsTable } from '../../hooks';
import { ForeignPermissionQueryEnum } from '../../model';

function PermitEkmtItemsTable() {
  const [t] = useTranslation();
  const queryCache = useQueryCache();
  const [confirm] = useConfirmDialog();
  const { watch } = useFormV2Context();
  const { showSuccessSnackbar } = useSnackbar();
  const { id: foreignPermissionId } = useParams<IDisposablePermitsDetailsParams>();
  const { openCarnetSettlementsEKMTForm } = usePermitDialog();
  const { mutate: deleteEkmtCarnetSettlement } = useDeleteEkmtCarnetSettlementMutation();

  const getLabel = partialTranslate('foreignPermits:tabs.releasedPermits.ekmtDetails');

  const ekmtCarnetSettlements = watch(
    typedNameV2<ForeignPermissionWithSettlementDetails>('ekmtCarnetSettlements')
  ) as EkmtCarnetSettlementDetails[];

  const getCarnetSettlementsSum = (): number => {
    let sum = 0;

    if (ekmtCarnetSettlements?.length > 0) {
      for (let i = 0; i < ekmtCarnetSettlements.length; i++) {
        sum =
          sum +
          ekmtCarnetSettlements[i].numberTo -
          ekmtCarnetSettlements[i].numberFrom +
          ekmtCarnetSettlements[i].missingPageNumbers.length;
      }

      return sum;
    }

    return 0;
  };

  const carnetSettlementsSum = getCarnetSettlementsSum();

  const tableProps = usePermitEkmttemsTable(ekmtCarnetSettlements);

  const handleDelete = (ekmtSettlementId: string) => {
    confirm({
      title: getLabel('deleteDialog.title'),
      message: getLabel('deleteDialog.message'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);

        deleteEkmtCarnetSettlement(
          { ekmtSettlementId },
          {
            onSuccess: () => {
              showSuccessSnackbar(t('global:dialog.deleteSuccess'));
              queryCache.invalidateQueries([
                ForeignPermissionQueryEnum.PERMISSION_DETAILS_WITH_SETTLEMENT,
                foreignPermissionId
              ]);
              closeDialog();
            },
            onSettled: () => {
              setConfirmLoading(false);
              closeDialog();
            }
          }
        );
      },
      confirmType: 'danger'
    });
  };

  return (
    <TableLayout<EkmtCarnetSettlementDetails, unknown>
      {...tableProps}
      isSection
      isFilterEnabled={false}
      isRefreshEnabled={false}
      pageTitle={getLabel('sections.list.title')}
      headerActions={
        carnetSettlementsSum < 52 && (
          <Button
            label={getLabel('sections.list.addButton')}
            onClick={() => {
              openCarnetSettlementsEKMTForm(foreignPermissionId);
            }}
            variant="outlined"
          />
        )
      }
      rowActions={({ original }) => {
        return (
          <>
            <TableIconButton
              onClick={() => {
                openCarnetSettlementsEKMTForm(foreignPermissionId, original);
              }}
              icon="EditIcon"
            />
            <TableIconButton onClick={() => handleDelete(original.id)} icon="TrashIcon" />
          </>
        );
      }}
    />
  );
}

export default PermitEkmtItemsTable;
