import { FolderLimitDetails, FolderLimitSearchFilter, ForeignPermissionSnapshot } from '@ibtm/domain';

import {
  CamelCasePath,
  ColumnTypesEnum,
  SelectOption,
  TableFilterMapper,
  TableSortMapper,
  useCreateColumns
} from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { LimitTableEnum } from '@libs/domain/foreign-permission';

function useLimitColumns(visibleColumnIds?: Array<CamelCasePath<FolderLimitDetails>>) {
  const { createColumns } = useCreateColumns<FolderLimitDetails, ForeignPermissionSnapshot>({
    pathToTranslate: 'foreignPermits:tabs.ekmt.fields'
  });

  const mappedFilterFields: TableFilterMapper<FolderLimitDetails, FolderLimitSearchFilter> = {
    folderNumber: (folderNumber: string) => ({ folderNumberContains: folderNumber }),
    subjectName: (subjectName: string) => ({ subjectNameContains: subjectName }),
    resourceFormNameKey: (statuses: SelectOption<string>[]) => ({
      resourceFormNameKeyIn: statuses.map(({ value }) => value)
    }),
    limit: (limitRange: { min: number; max: number }) => ({
      limitGreaterThanOrEqual: limitRange.min,
      limitLessThanOrEqual: limitRange.max
    })
  };

  const mappedSortFields: TableSortMapper<FolderLimitDetails> = {
    subjectName: 'folder.subject.name'
  };

  const initialParamsMapper = {
    [LimitTableEnum.YEAR]: (year: { value: string }) => ({ [LimitTableEnum.YEAR]: year.value })
  };

  const columns = createColumns(
    [
      {
        id: 'folderNumber',
        header: 'number',
        accessor: 'folder.number',
        type: ColumnTypesEnum.TEXT,
        width: 200
      },
      {
        id: 'resourceFormNameKey',
        header: 'name',
        accessor: 'resourceFormNameKey',
        type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
        dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_NAME
      },
      {
        id: 'subjectName',
        header: 'subjectName',
        accessor: 'subject.name',
        type: ColumnTypesEnum.TEXT,
        width: 300
      },
      {
        id: 'limit',
        header: 'availableLimit',
        accessor: 'limit',
        type: ColumnTypesEnum.NUMBER_RANGE,
        width: 100
      }
    ],
    visibleColumnIds
  );

  return { columns, mappedFilterFields, mappedSortFields, initialParamsMapper };
}

export default useLimitColumns;
