import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { useSnackbar } from '@enigma/fe-ui';
import { DriversSearchFilter, PermissionTypeCode } from '@ibtm/domain';
import { useAppConfig } from '@libs/app-config';
import moment from 'moment';

import { TableEditRow, TableIconButton, useTableContext } from '@libs/common/v2';
import { ROW_WIDTH_ICONS } from '@libs/common/v2/models';

import { DomainUIElementEnum } from '@libs/domain/config';
import { getDrivers } from '@libs/domain/drivers/api';

import { usePrintsDetailsDataContext } from '../../../context';
import useFetchPrintsApplicationDetails from '../../../hooks/useFetchPrintsApplicationDetails';
import { useOpenLocksSummaryDialog } from '../../../hooks/useOpenLocksSummaryDialog';
import usePrintActionHandler from '../../../hooks/usePrintActionHandler';
import { usePrintsRegenerateAction } from '../../../hooks/usePrintsRegenerateAction';
import { PrintablePermissionsUnion } from '../../../model';
import { LocksSummaryData } from '../dialog/LocksSummaryDialog';

export interface IPermissionsRowActionsProps {
  row: Row<PrintablePermissionsUnion>;
  isEditVisible?: boolean;
  permissionTypeCode: PermissionTypeCode;
  isRegenerateButtonVisible?: boolean;
  isMultipleFormNumberToRegenerateInRow?: boolean;
  isPrintDisabled?: boolean;
  isLocksSummaryVisible?: boolean;
  isFormsNumbersVisibleInLockSummary?: boolean;
}

function PermissionsTablesRowActions({
  row,
  permissionTypeCode,
  isEditVisible,
  isMultipleFormNumberToRegenerateInRow,
  isRegenerateButtonVisible = true,
  isPrintDisabled = false,
  isLocksSummaryVisible = false,
  isFormsNumbersVisibleInLockSummary = false
}: IPermissionsRowActionsProps) {
  const { original } = row;
  const [t] = useTranslation();
  const { refetch } = useTableContext();
  const { showErrorSnackbar } = useSnackbar();
  const {
    mutation: { printMutation, isLoading }
  } = usePrintActionHandler();
  const { applicationData } = useFetchPrintsApplicationDetails();
  const { handlePrintRegenerate } = usePrintsRegenerateAction();
  const { openLocksSummaryDialog } = useOpenLocksSummaryDialog(isFormsNumbersVisibleInLockSummary);
  const {
    printDetailsData: {
      depotMPD: { id: depotMPDId },
      notDepotMPDTooltip
    }
  } = usePrintsDetailsDataContext();
  const {
    raw: {
      prints: { showLocksSummaryAction }
    }
  } = useAppConfig();
  const isDisabled = !depotMPDId;

  const handleOnClick = async () => {
    if (permissionTypeCode === PermissionTypeCode.DRIVER_CERTIFICATE) {
      const response = await getDrivers(null, {
        applicationIdIn: [applicationData?.id],
        includeCopies: true,
        page: 0,
        size: 25,
        sort: []
      } as DriversSearchFilter);

      const driver = response?.content?.[0];

      const isIndenityCardValid = driver?.identityCard?.expirationDate
        ? moment(driver?.identityCard?.expirationDate).isAfter(moment())
        : true;
      const isDriverLicenseValid = driver?.driverLicense?.expirationDate
        ? moment(driver?.driverLicense?.expirationDate).isAfter(moment())
        : true;
      if (!isIndenityCardValid) {
        showErrorSnackbar(t('prints:actions.indenityDocumentExpired', { number: driver?.identityCard?.seriesNumber }));
      }
      if (!isDriverLicenseValid) {
        showErrorSnackbar(t('prints:actions.driverLicenseExpired', { number: driver?.driverLicense?.seriesNumber }));
      }
    }
    printMutation({ permissionTypeCode, permissionIds: [original.id] });
  };
  const isForeignPermissions = permissionTypeCode === PermissionTypeCode.FOREIGN_PERMISSION;

  return (
    <>
      {isRegenerateButtonVisible && (
        <TableIconButton
          actionKey={DomainUIElementEnum.PRINT_REGENERATE_BUTTON}
          icon="CalculatorIcon"
          tooltipTitle={isDisabled ? notDepotMPDTooltip : t('prints:actions.regenerate')}
          isDisabled={isDisabled || !applicationData?.id}
          onClick={() => {
            handlePrintRegenerate({
              applicationId: applicationData?.id,
              data: original,
              onSuccess: refetch,
              isMultipleFormNumberToRegenerateInRow,
              permissionTypeCode
            });
          }}
        />
      )}
      <TableIconButton
        icon="PrintIcon"
        isButtonLoading={isLoading}
        onClick={() => {
          handleOnClick();
        }}
        isDisabled={isPrintDisabled}
        tooltipTitle={isPrintDisabled ? t('prints:messages.printActionDisabled') : t('action.print')}
        actionKey={
          isForeignPermissions
            ? DomainUIElementEnum.PRINT_FOR_FOREIGN_PERMISSIONS
            : DomainUIElementEnum.PERMISSION_PRINT_BUTTON
        }
      />
      {isLocksSummaryVisible && showLocksSummaryAction && (
        <TableIconButton
          icon="FileFindIcon"
          onClick={() => {
            openLocksSummaryDialog(row?.original as LocksSummaryData);
          }}
          tooltipTitle={t('prints:locksSummary.title')}
        />
      )}

      {isEditVisible && (
        <TableEditRow
          rowId={row.original.id}
          isRenderedAsActionIcon
          actionKey={DomainUIElementEnum.PRINT_DATA_UPDATE_BUTTON}
        />
      )}
    </>
  );
}

export const useAmountOfIcons = () => {
  const {
    raw: {
      prints: { showLocksSummaryAction }
    }
  } = useAppConfig();

  const getWidthOfActionColumnWidth = useCallback(
    ({
      isRegenerateButtonVisible = true,
      isLocksSummaryVisible = false,
      isEditVisible
    }: Pick<IPermissionsRowActionsProps, 'isRegenerateButtonVisible' | 'isEditVisible' | 'isLocksSummaryVisible'>) => {
      let amountOfIcons = 1;
      if (isRegenerateButtonVisible) {
        amountOfIcons++;
      }
      if (showLocksSummaryAction && isLocksSummaryVisible) {
        amountOfIcons++;
      }
      if (isEditVisible) {
        amountOfIcons++;
      }
      return ROW_WIDTH_ICONS[amountOfIcons];
    },
    [showLocksSummaryAction]
  );

  return { getWidthOfActionColumnWidth };
};

export function rowActionRenderer({
  row,
  isEditVisible,
  permissionTypeCode,
  isRegenerateButtonVisible,
  isPrintDisabled,
  isMultipleFormNumberToRegenerateInRow,
  isLocksSummaryVisible,
  isFormsNumbersVisibleInLockSummary
}: Omit<IPermissionsRowActionsProps, 'assignmentTypeKey'>) {
  return (
    <PermissionsTablesRowActions
      row={row}
      permissionTypeCode={permissionTypeCode}
      isEditVisible={isEditVisible}
      isRegenerateButtonVisible={isRegenerateButtonVisible}
      isPrintDisabled={isPrintDisabled}
      isMultipleFormNumberToRegenerateInRow={isMultipleFormNumberToRegenerateInRow}
      isLocksSummaryVisible={isLocksSummaryVisible}
      isFormsNumbersVisibleInLockSummary={isFormsNumbersVisibleInLockSummary}
    />
  );
}
