import { SetSignerRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const setSigner = async ({ unitId, userId, version }: SetSignerRequest) => {
  const { data } = await API.signers.setSigner(
    { version, unitId, userId },
    {
      ...endpointsConfig.setSigner
    }
  );
  return data;
};

const useSetSignerMutation = () => {
  return useMutation(setSigner);
};

export default useSetSignerMutation;
