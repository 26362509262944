import { PaginatedQueryConfig, usePaginatedQuery } from 'react-query';
import { UserTaskSnapshot } from '@ibtm/process-api';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { ApplicationQueryKeysEnum } from '@libs/domain/application';
import { TaskQueryParams } from '@libs/domain/application/model';

const getTasks = async (_, params: TaskQueryParams) => {
  const { data } = await API.task.list(params);
  return data;
};

export default function useTasksQuery(
  params: TaskQueryParams,
  queryConfig: PaginatedQueryConfig<IPaginatedModel<UserTaskSnapshot>, unknown> = {}
) {
  return usePaginatedQuery<IPaginatedModel<UserTaskSnapshot>>(
    [ApplicationQueryKeysEnum.TASKS, params],
    getTasks,
    queryConfig
  );
}
