import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { FolderPermissionSnapshot } from '@ibtm/domain/dist/models/folder-permission-snapshot';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { ISingleNavigation, Typography, useRouter } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { hasPermission } from '@libs/common/v2/utils';

interface IProps {
  list: ISingleNavigation[];
}

function SitemapList({ list }: IProps) {
  const classes = useStyles();
  const params = useParams<any>();
  const { goToPage } = useRouter();
  const userPermissions = useSelector(({ auth }) => auth.user.permissions) as Array<FolderPermissionSnapshot>;

  const getTreeItem = item => {
    return (
      <Typography
        themeVariant="textMd.normal"
        onClick={() => {
          if (item.target === '_blank') {
            window.open(item.url, '_blank');
          } else {
            goToPage(item.url);
          }
        }}
        className={clsx(classes.linkStyles, 'underline')}
      >
        {item.title}
      </Typography>
    );
  };

  const renderTreeNodes = (data: ISingleNavigation[], paddingLeft: number) =>
    data.map(item => {
      if (
        !hasPermission((typeof item.auth === 'function' ? item.auth?.(params) : item.auth) || null, userPermissions)
      ) {
        return null;
      }
      return (
        <Grid className={clsx(classes.listItem, `pl-${paddingLeft}`)} key={item.id}>
          <li>
            {getTreeItem(item)}
            {item.children ? (
              <Grid className={clsx(classes.listItem, `pl-${paddingLeft}`)}>
                <ul className="pl-24">{renderTreeNodes(item.children, paddingLeft + 5)}</ul>
              </Grid>
            ) : null}
          </li>
        </Grid>
      );
    });

  return (
    <Grid>
      <ul className="pl-24">{renderTreeNodes(list, 0)}</ul>
    </Grid>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  linkStyles: {
    padding: '5px 5px 0 5px',
    borderBottom: '1px solid transparent',
    cursor: 'pointer',
    '&:hover,&:focus': {
      borderBottom: `1px solid ${theme.palette.primary?.active}`
    },
    display: 'inline-block'
  },
  listItem: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column'
  }
}));

export default SitemapList;
