import { Context, useContext, useEffect } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';

import {
  BooleanValue,
  ComponentErrorBoundary,
  FormErrorType,
  FormV2Context,
  FormV2ContextState,
  getInputMode,
  InputMode,
  Radio,
  Value
} from '@libs/common/v2';
import { getValue } from '@libs/common/v2/utils';

export interface RadioFieldProps {
  isDefaultChecked?: boolean;
  name: FieldPath<FieldValues>;
  label?: string;
  className?: string;
  valueClassName?: string;
  valueTrueText?: string;
  value?: string;
  valueFalseText?: string;
  formContext?: Context<FormV2ContextState>;
  getValueFormat?: (value: any) => string;
  inputMode?: InputMode;
  isRequired?: boolean;
  isDisabled?: boolean;
  isShownAsBooleanValue?: boolean;
  checkboxClassName?: string;
  wrapperClassName?: string;
  shouldDisplayMessage?: boolean;
}

function RadioField({
  name,
  label,
  formContext = FormV2Context,
  className,
  value,
  valueClassName,
  valueTrueText,
  valueFalseText,
  isDefaultChecked,
  inputMode,
  getValueFormat,
  isDisabled,
  isRequired,
  isShownAsBooleanValue = true,
  checkboxClassName,
  wrapperClassName,
  shouldDisplayMessage = true
}: RadioFieldProps) {
  const { control, formMode, loading, setValue } = useContext<FormV2ContextState>(formContext);
  const mode = getInputMode(formMode, inputMode);

  useEffect(() => {
    if (isDefaultChecked) {
      setValue(name, value || isDefaultChecked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getViewModeInput = (checked, error) => {
    return isShownAsBooleanValue ? (
      <BooleanValue
        label={label}
        value={checked}
        isLoading={loading}
        className={valueClassName}
        valueFalseText={valueFalseText}
        valueTrueText={valueTrueText}
        isError={!!error && error?.type !== FormErrorType.WARNING}
        isWarning={error?.type === FormErrorType.WARNING}
        helperText={error?.message}
      />
    ) : (
      <Value
        label={label}
        className={valueClassName}
        isLoading={loading}
        isError={!!error && error?.type !== FormErrorType.WARNING}
        isWarning={error?.type !== FormErrorType.WARNING}
        helperText={error?.message}
      >
        {!loading && !getValueFormat
          ? getValue(checked ? value : null)
          : getValue?.(getValueFormat?.(checked ? value : null))}
      </Value>
    );
  };

  return (
    <ComponentErrorBoundary componentName={label || name || 'RadioField'}>
      <Controller
        control={control}
        name={name as FieldPath<FieldValues>}
        render={({ field: { onChange, value: fieldValue, onBlur }, fieldState: { error } }) => {
          const checked = value === fieldValue;

          return mode === InputMode.FORM ? (
            <Radio
              value={value}
              isChecked={checked}
              onBlur={onBlur}
              onChange={onChange}
              label={label}
              isLoading={loading}
              isRequired={isRequired}
              isDisabled={isDisabled}
              isError={shouldDisplayMessage && !!error && error?.type !== FormErrorType.WARNING}
              isWarning={error?.type === FormErrorType.WARNING}
              helperText={shouldDisplayMessage && error?.message}
              className={className}
              checkboxClassName={checkboxClassName}
              wrapperClassName={wrapperClassName}
            />
          ) : (
            getViewModeInput(checked, error)
          );
        }}
      />
    </ComponentErrorBoundary>
  );
}

export default RadioField;
