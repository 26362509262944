export const endpointsConfig: { [key: string]: { params: { translationKey: string } } } = {
  getFoldersSnapshotPage: { params: { translationKey: 'folder:api.getFoldersSnapshotPage' } },
  findMyOrganizationUnits: { params: { translationKey: 'group:api.findMyOrganizationUnits' } },
  findGroupsUserIsNotMemberOf: { params: { translationKey: 'group:api.findGroupsUserIsNotMemberOf' } },
  listMyGroupMemberships: { params: { translationKey: 'group:api.listMyGroupMemberships' } },
  findMyReplacements: { params: { translationKey: 'group:api.findMyReplacements' } },
  getUsersAvailableSubjects: { params: { translationKey: 'subject:api.getUsersAvailableSubjects' } },
  getUserSubjects: { params: { translationKey: 'subject:api.getUserSubjects' } },
  assignMember: { params: { translationKey: 'group:api.assignMember' } },
  createMyReplacement: { params: { translationKey: 'group:api.createMyReplacement' } },
  deleteMyReplacement: { params: { translationKey: 'group:api.deleteMyReplacement' } },
  deleteUserGroupMembership: { params: { translationKey: 'group:api.deleteUserGroupMembership' } },
  updateReplacement: { params: { translationKey: 'group:api.updateReplacement' } },
  deleteUsersAvailableSubject: { params: { translationKey: 'subject:api.deleteUsersAvailableSubject' } },
  groupFind: { params: { translationKey: 'group:api.findGroup' } },
  addUsersAvailableSubjects: { params: { translationKey: 'account:api.addUsersAvailableSubjects' } },
  userDetails: { params: { translationKey: 'account:api.details' } },
  usersList: { params: { translationKey: 'account:api.search' } },
  anonymizeAccount: { params: { translationKey: 'account:api.anonymizeAccount' } },
  updateDetailedStatus: { params: { translationKey: 'account:api.updateDetailedStatus' } },
  createClientByOperator: { params: { translationKey: 'account:api.createClientByOperator' } },
  deleteAccount: { params: { translationKey: 'account:api.deleteAccount' } },
  updateAccountData: { params: { translationKey: 'account:api.updateAccountData' } },
  lockAccount: { params: { translationKey: 'account:api.lockAccount' } },
  resetPassword: { params: { translationKey: 'account:api.resetPassword' } },
  restoreAccount: { params: { translationKey: 'account:api.restoreAccount' } },
  unlockAccount: { params: { translationKey: 'account:api.unlockAccount' } },
  suspendAccount: { params: { translationKey: 'account:api.suspendAccount' } },
  listPermissionsForGroup: { params: { translationKey: 'account:api.listPermissionsForGroup' } },
  recoverPassword: { params: { translationKey: 'account:api.recoverPassword' } },
  resendEmailConfirmationLink: { params: { translationKey: 'account:api.resendEmailConfirmationLink' } }
};
