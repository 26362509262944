import { useCallback } from 'react';

import usePermissions from '@libs/permission/hooks/usePermissions';

import {
  applicationGeneralPermission,
  getPermissionsForApplicationType,
  type TApplicationCrudPermissionParams
} from '../utils/application.permission';

export function useApplicationPermission() {
  const { hasPermission: checkPermissions } = usePermissions();

  const hasPermissions = useCallback(
    (params: TApplicationCrudPermissionParams) => {
      if (!params.applicationType && !params.isLoading) {
        return false;
      }
      const permissions = getPermissionsForApplicationType(params);
      const permissionNotExist = permissions.length === 0;

      const generalPermission = applicationGeneralPermission[params.action];

      if (generalPermission && !checkPermissions(generalPermission)) {
        return false;
      }

      return permissionNotExist || checkPermissions(permissions);
    },
    [checkPermissions]
  );

  return { hasPermissions };
}
