import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { FieldPath } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { useSnackbar } from '@enigma/fe-ui';
import { ResourceObjectTypeTransformCreateRequest, ResourceTypeSnapshot } from '@ibtm/domain';
import { makeStyles } from '@mui/styles';

import {
  SelectOption,
  TableButtonEdit,
  TableEvent,
  TableLayout,
  typedNameV2,
  useFormV2Context,
  useFormV2Watch,
  useTableAdapter,
  useTableContext,
  useTableEventsRef,
  WarningInformation
} from '@libs/common/v2';
import { important } from '@libs/common/v2/utils';

import { API } from '@libs/domain/api';

import { useTransformTypeMutation } from '../../../api';
import { useResourceTransformTypeObjectPoolsTable } from '../../../hooks';
import { ResourceObjectPoolClient, TypeTransformFields } from '../../../model';
import { resourceObjectPoolsParser } from '../../../utils';

interface IProps {
  depotId: string;
}

type ResourceTypeOption = SelectOption<ResourceTypeSnapshot>;

const getFieldName = typedNameV2 as typeof typedNameV2<TypeTransformFields>;

function ResourceTransformTypeObjectPoolsTable({ depotId }: IProps) {
  const [t] = useTranslation();
  const classes = useStyles();
  const { mutate: transformType } = useTransformTypeMutation();

  const { showSnackbar } = useSnackbar();

  const { getValues } = useFormV2Context();
  const getValue = (name: FieldPath<TypeTransformFields>) => getValues(name);
  const sourceType = useFormV2Watch<ResourceTypeOption>({
    name: getFieldName('sourceType')
  });
  const sourceTypeId = useMemo(() => sourceType?.id, [sourceType]);

  const targetType = useFormV2Watch<ResourceTypeOption>({
    name: typedNameV2<TypeTransformFields>('targetType')
  });

  const ref = useRef<{ refetchTable: () => void }>(null);

  const isOneOfTypesSelected = Boolean(sourceType || targetType);

  const tableProps = useResourceTransformTypeObjectPoolsTable({ depotId, sourceTypeId });

  const getSourceAndTargetTypes = () => {
    const sourceTypeValue = getValue('sourceType') as ResourceTypeOption;
    const targetTypeValue = getValue('targetType') as ResourceTypeOption;
    return { sourceTypeValue, targetTypeValue };
  };

  const actionsRowRender = ({ original }: Row<ResourceObjectPoolClient>) => (
    <TableRowActions
      original={original}
      checkIsDisabled={() => {
        const { sourceTypeValue, targetTypeValue } = getSourceAndTargetTypes();
        const bothTypesSelected = Boolean(sourceTypeValue && targetTypeValue);
        return !bothTypesSelected;
      }}
    />
  );

  const { tableEventsRef } = useTableEventsRef();

  useEffect(() => {
    if (sourceType?.id) {
      ref?.current?.refetchTable();
    }
    tableEventsRef.current?.emitEvent(TableEvent.RERENDER_ROW_ACTIONS);
  }, [sourceType?.id, tableEventsRef]);

  useEffect(() => {
    tableEventsRef.current?.emitEvent(TableEvent.RERENDER_ROW_ACTIONS);
  }, [tableEventsRef, targetType?.id]);

  const handleEditRow = (row: ResourceObjectPoolClient, context) => {
    const { sourceTypeValue, targetTypeValue } = getSourceAndTargetTypes();
    const requestData: ResourceObjectTypeTransformCreateRequest = {
      sourceResourceTypeId: sourceTypeValue?.id,
      targetResourceTypeId: targetTypeValue?.id,
      elements: [
        {
          numberFrom: row?.numberFrom,
          numberTo: row?.numberTo,
          amount: row?.amount,
          resourceTypeId: row?.resourceType?.id
        }
      ]
    };

    transformType(requestData, {
      onSuccess: () => {
        showSnackbar('success', t('resource:messages.typeTransformSuccess'));
        context.onSuccess();
      },
      onError: () => {
        context.onError();
      }
    });
  };

  return isOneOfTypesSelected ? (
    <>
      <WarningInformation content={t('resource:messages.dataSynchronizationAfterEditType')} />
      <TableLayout
        tableEventsRef={tableEventsRef}
        {...tableProps}
        pageTitle={t('resource:list.typeTransformListTitle')}
        xlsxDownload={{
          fileName: t('resource:list.listOfResourceDetails'),
          pathToXLSXTranslation: 'resource:fields',
          apiRequest: params =>
            API.resourceObjectPools
              .getResourceObjectPoolSnapshot(params)
              .then(res => ({ ...res, data: { ...res.data, content: resourceObjectPoolsParser(res.data.content) } }))
        }}
        headerActions={<RefetchProvider ref={ref} />}
        rowActions={actionsRowRender}
        onRowEditCreateConfirm={handleEditRow}
        sectionClassName={classes.section}
        sectionContentClassName={classes.sectionContent}
        isTableMutable
        isSection
        isRefreshEnabled
        isSectionFullPage
      />
    </>
  ) : null;
}

const RefetchProvider = forwardRef((_, ref) => {
  const { refetch } = useTableContext();
  const table = useTableAdapter();

  useImperativeHandle(
    ref,
    () => ({
      refetchTable: () => {
        table.cancelEditCreateRow();
        refetch();
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetch]
  );

  return null;
});

function TableRowActions({
  original,
  checkIsDisabled
}: {
  original: ResourceObjectPoolClient;
  checkIsDisabled: () => boolean;
}) {
  const [t] = useTranslation();

  const table = useTableAdapter();

  const handleOnClick = () => {
    table.editRow(original?.id);
  };
  const isDisabledValue = checkIsDisabled();
  return (
    <TableButtonEdit
      tooltipTitle={
        !isDisabledValue ? t('resource:actions.editAndTransformType') : t('resource:messages.typeTransformDisabled')
      }
      onClick={handleOnClick}
      isDisabled={isDisabledValue}
    />
  );
}

const useStyles = makeStyles({
  section: {
    padding: important('10px')
  },
  sectionContent: {
    height: important('auto')
  }
});

export default ResourceTransformTypeObjectPoolsTable;
