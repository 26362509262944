enum DocumentTemplateUIElementEnum {
  DOCUMENT_TEMPLATES_LIST_TAB = 'DOCUMENT_TEMPLATES_LIST_TAB',
  DOCUMENT_TEMPLATES_ADD_BUTTON = 'DOCUMENT_TEMPLATES_ADD_BUTTON',
  DOCUMENT_TEMPLATES_EDIT_BUTTON = 'DOCUMENT_TEMPLATES_EDIT_BUTTON',
  DOCUMENT_TEMPLATES_EXPORT_BUTTON = 'DOCUMENT_TEMPLATES_EXPORT_BUTTON',
  DOCUMENT_TEMPLATES_DELETE_BUTTON = 'DOCUMENT_TEMPLATES_DELETE_BUTTON',
  DOCUMENT_TEMPLATES_DETAILS_BUTTON = 'DOCUMENT_TEMPLATES_DETAILS_BUTTON',
  DOCUMENT_TEMPLATES_PUBLISH_BUTTON = 'DOCUMENT_TEMPLATES_PUBLISH_BUTTON',
  DOCUMENT_TEMPLATES_FILE_EDIT_BUTTON = 'DOCUMENT_TEMPLATES_FILE_EDIT_BUTTON',
  DOCUMENT_TEMPLATES_FILE_UPLOAD_BUTTON = 'DOCUMENT_TEMPLATES_FILE_UPLOAD_BUTTON'
}

export default DocumentTemplateUIElementEnum;
