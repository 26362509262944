import React, { useEffect, useState } from 'react';
import { DataSourceMediatorColumn, DataSourceMediatorDetailsSnapshot } from '@stack/report';

import { useFormV2Context } from '@libs/common/v2';

import { ConditionOperator } from '@libs/report-core';

import AlertBasicColumns from './AlertBasicColumns';

interface IProps {
  sourceColumns: DataSourceMediatorColumn[];
  source: DataSourceMediatorDetailsSnapshot;
}

function AlertColumns({ sourceColumns, source }: IProps) {
  const { getValues, setValue, loading } = useFormV2Context();

  const [previousSourceId, setPreviousSourceId] = useState<string | null>(null);

  const isColumnSectionVisible = Boolean(source?.id);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!source?.id || (previousSourceId !== null && previousSourceId !== source.id)) {
      const localValues = getValues('queryDefinition.columns');
      setValue('queryConditions[0]', {
        filter: null,
        group: [],
        operator: ConditionOperator.AND
      });

      if (localValues?.length) {
        setValue('queryDefinition.columns', []);
      }
    }
    setPreviousSourceId(source?.id || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, source?.id]);

  if (!isColumnSectionVisible) {
    return null;
  }

  return <AlertBasicColumns sourceColumns={sourceColumns} source={source} />;
}

export default React.memo(AlertColumns);
