import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { ApplicationDetails } from '@ibtm/domain';

import { Dialog, useDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { getMetaFormQueryKey } from '@libs/meta-form/services/useGetQuery';

import { ApplicationQueryKeysEnum } from '@libs/domain/application';
import { ExploitationBaseQueryKeysEnum } from '@libs/domain/exploitation-base';

import { FinancialSecurityQueryKeysEnum, useFinancialSecurityCopyMutation } from '../../api';

function useConfirmAddFinancialSecurityDialog(customOnSuccess?: () => void) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { mutate: copyFinancialSecurity } = useFinancialSecurityCopyMutation();

  const { openDialog } = useDialog();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  const openConfirmAddFinancialSecurityDialog = useCallback(
    (financialSecurityIds: string[], applicationId: string) =>
      openDialog(({ closeDialog }) => (
        <Dialog
          title={t('financialSecurity:action.create.dialogTitle')}
          confirmText={t('action.add')}
          cancelText={t('action.cancel')}
          contentText={
            financialSecurityIds?.length > 1
              ? t('financialSecurity:action.create.dialogContentPlural')
              : t('financialSecurity:action.create.dialogContent')
          }
          onConfirm={setIsLoading => {
            setIsLoading(true);
            const data: ApplicationDetails = queryCache.getQueryData([
              ApplicationQueryKeysEnum.APPLICATION,
              applicationId
            ]);
            copyFinancialSecurity(
              {
                applicationId,
                financialSecurityIds,
                version: data?.version
              },
              {
                onSuccess: () => {
                  showSuccessSnackbar(
                    financialSecurityIds.length > 1
                      ? t('financialSecurity:action.create.successMessagePlural')
                      : t('financialSecurity:action.create.successMessage')
                  );
                  queryCache.invalidateQueries([ExploitationBaseQueryKeysEnum.EXPLOITATION_BASE_LIST]);
                  queryCache.invalidateQueries(FinancialSecurityQueryKeysEnum.FINANCIAL_SECURITY_LIST);
                  queryCache.invalidateQueries(FinancialSecurityQueryKeysEnum.FINANCIAL_SECURITY_SUMMARY);
                  // queryKey generowany przez metaform
                  queryCache.invalidateQueries(getMetaFormQueryKey('GET_FINANCIAL_SECURITY_SUMMARY'));
                  customOnSuccess?.();
                },
                onError: () => {
                  showErrorSnackbar(
                    financialSecurityIds.length > 1
                      ? t('financialSecurity:action.create.failureMessagePlural')
                      : t('financialSecurity:action.create.failureMessage')
                  );
                },
                onSettled: () => {
                  closeDialog();
                  setIsLoading(false);
                }
              }
            );
          }}
          onCancel={closeDialog}
          isOpen
        />
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog, t, showSuccessSnackbar, copyFinancialSecurity, showErrorSnackbar]
  );

  return { openConfirmAddFinancialSecurityDialog };
}

export default useConfirmAddFinancialSecurityDialog;
