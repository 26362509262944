import { DomainUIElementEnum } from '@libs/domain/config';

export interface DriverCertificateServer {
  id: string;
  version: number;
  cancellationDate: string;
  cancellationReason: string;
  deleted: boolean;
  certificateNumber: string;
  formNumber: string;
  statusKey: string;
  typeKey: string;
  validFrom: string;
  validTo: string;
}

export interface DriverLicenseServer {
  typeKey: string;
  seriesNumber: string;
  issuingDate: string;
  expirationDate: string;
  issuingAuthority: string;
  deleted: boolean;
}

export interface IdentityCardServer {
  typeKey: string;
  seriesNumber: string;
  issuingDate: string;
  expirationDate: string;
  issuingAuthority: string;
  deleted: boolean;
}

export interface DriverListItemServer {
  id: string;
  driverCertificate: DriverCertificateServer;
  driverLicense: DriverLicenseServer;
  employmentDocument: string;
  identityCard: IdentityCardServer;
  insuranceNumber: string;
  applicationNumber: string;
  applicationType: string;
  archiveNumber: string;
  deleted: boolean;
}

export interface DriverDetailsServer {
  id: string;
  driverCertificate: DriverCertificateServer;
  driverLicense: DriverLicenseServer;
  employmentDocument: string;
  identityCard: IdentityCardServer;
  insurancePayerNIP: string;
  deleted: boolean;
  name: string;
  surname: string;
  birthDate: string;
  birthPlace: string;
  citizenship: string;
}

export enum DriversColumnsEnum {
  FOLDER_NUMBER = 'folderNumber',
  NAME = 'name',
  SURNAME = 'surname',
  BIRTHDATE = 'birthDate',
  BIRTHPLACE = 'birthPlace',
  CITIZENSHIP = 'citizenship',
  INSURANCE_PAYER_NIP = 'insurancePayerNip',
  EMPLOYMENT_DOCUMENT = 'employmentDocumentKey',
  ID_TYPE = 'identityCardTypeKey',
  ID_SERIES_NUMBER = 'identityCardSeriesNumber',
  ID_ISSUING_DATE = 'idIssuingDate',
  ID_ISSUING_COUNTRY = 'idIssuingCountryKey',
  DRIVER_LICENSE_SERIES_NUMBER = 'driverLicenseSeriesNumber',
  DRIVER_LICENSE_ISSUING_DATE = 'driverLicenseIssuingDate',
  DRIVER_LICENSE_ISSUING_COUNTRY = 'driverLicenseIssuingCountryKey',
  DRIVER_CERTIFICATE_NUMBER = 'currentCertificateNumber',
  DRIVER_CERTIFICATE_FORM_NUMBER = 'currentCertificateFormFullNumber',
  COMPETENCE_CODE = 'competenceCodeKey',
  APPLICATION_TYPE = 'currentCertificateApplicationTypeKey',
  FORM_NUMBER = 'formNumber',
  ARCHIVE_NUMBER = 'applicationArchiveNumber',
  DRIVER_CERTIFICATE_STATUS = 'currentCertificateStatusKey',
  DRIVER_CERTIFICATE_CANCELLATION_DATE = 'currentCertificateCancellationDate',
  DRIVER_CERTIFICATE_CANCELATION_REASON = 'currentCertificateCancellationReasonKey',
  DRIVER_CERTIFICATE_PERIOD_OF_ISSUING = 'driverCertificateValidityPeriodKey',
  CURRENT_CERTIFICATE_APPLICATION_NUMBER = 'currentCertificateApplicationNumber'
}

export enum DriverActionButtonsEnum {
  ACTIVATE = 'ACTIVATE',
  DELETE = 'DELETE',
  EXPIRE = 'EXPIRE',
  PREVIEW = 'PREVIEW',
  GENERATE_UP_TO_DATE = 'GENERATE_UP_TO_DATE',
  GENERATE_ISSUED = 'GENERATE_ISSUED',
  GENERATE_EXPIRE_REPORT = 'GENERATE_EXPIRE_REPORT',
  ADD_DRIVER = 'ADD_DRIVER',
  EDIT = 'EDIT',
  SEARCH = 'SEARCH',
  SELECT_DRIVER_TO_ADD = 'SELECT_DRIVER_TO_ADD',
  GO_TO_PROCEEDING = 'GO_TO_PROCEEDING'
}

export enum DriverCertificateReportTypeEnum {
  UP_TO_DATE = 'UP_TO_DATE',
  ISSUED = 'ISSUED',
  EXPIRE = 'EXPIRE'
}

export interface IPermissionsObject {
  [DriverActionButtonsEnum.ADD_DRIVER]?: DomainUIElementEnum;
  [DriverActionButtonsEnum.SEARCH]?: DomainUIElementEnum;
  [DriverActionButtonsEnum.EDIT]?: DomainUIElementEnum;
  [DriverActionButtonsEnum.PREVIEW]?: DomainUIElementEnum;
  [DriverActionButtonsEnum.DELETE]?: DomainUIElementEnum;
  [DriverActionButtonsEnum.EXPIRE]?: DomainUIElementEnum;
  [DriverActionButtonsEnum.ACTIVATE]?: DomainUIElementEnum;
  [DriverActionButtonsEnum.GENERATE_UP_TO_DATE]?: DomainUIElementEnum;
  [DriverActionButtonsEnum.GENERATE_ISSUED]?: DomainUIElementEnum;
  [DriverActionButtonsEnum.GENERATE_EXPIRE_REPORT]?: DomainUIElementEnum;
  [DriverActionButtonsEnum.GO_TO_PROCEEDING]?: DomainUIElementEnum;
}

export interface DriverDetailsClient {
  id: string;
  version: number;
  name: string;
  surname: string;
  birthDate: string;
  birthPlace: string;
  citizenship: string;
  insurancePayerNIP: string;
  periodOfIssuing: string;
  employmentDocumentKey: string;
  driverLicense: {
    seriesNumber: string;
    expirationDate: string;
    issuingDate: string;
    issuingCountryKey: string;
    issuingPlaceKey: string;
    indefinite: boolean;
    qualifications: boolean;
    code: string;
    expiryDateCompetenceCard: string;
  };
  identityCard: {
    typeKey: string;
    seriesNumber: string;
    issuingDate: string;
    expirationDate: string;
    issuingCountryKey: string;
    issuingPlaceKey: string;
  };
  created: string;
  linkedDriverCreated: string;
}

export interface IDriverForm {
  driverCertificateValidityPeriodKey: { name: string; value: string };
  birthDate: string;
  applicationId?: string;
  version?: number;
  birthPlace: {
    name?: string;
    value: string;
  };
  pesel: string;
  citizenship: {
    name?: string;
    value: string;
  };
  name: string;
  surname: string;
  periodOfIssuing: string;
  employmentDocumentKey?: {
    name?: string;
    value?: string;
  };
  insurancePayerNIP: string;
  driverLicense: {
    issuingDate: string;
    seriesNumber: string;
    indefinite: boolean;
    qualifications: boolean;
    expiryDateCompetenceCard: string;
    expirationDate: string;
    issuingAuthority: { name: string; value: string };
    issuingCountryKey: {
      name: string;
      value: string;
    };
    issuingPlaceKey: {
      name: string;
      value: string;
    };
    code: {
      name: string;
      value: string;
    };
  };
  identityCard: {
    issuingDate: string;
    seriesNumber: string;
    issuingAuthority: { name: string; value: string };
    typeKey: {
      name: string;
      value: string;
    };
    expirationDate: string;
    issuingCountryKey: {
      name: string;
      value: string;
    };
    issuingPlaceKey: {
      name: string;
      value: string;
    };
  };
  created: string;
  linkedDriverCreated: string;
}

export const expiryDateCompetenceCardRequiredStartingDate = '2022/04/15';
