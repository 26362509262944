import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountSnapshot, AccountSnapshotStatus } from '@avispon/user';
import { useSnackbar } from '@enigma/fe-ui';

import { useConfirmDialog } from '@libs/common/v2';

import {
  useChangeUserStatusMutation,
  useDeleteUserMutation,
  useLockUserMutation,
  useRestoreUserMutation,
  useUnlockUserMutation
} from '@libs/user/api';

import useResumeUserMutation from '../api/mutations/useResumeUserMutation';
import useSuspendUserMutation from '../api/mutations/useSuspendUserMutation';
import { ChangePasswordValues, SuspendFormValues } from '../models';

interface IParams {
  userDetails: AccountSnapshot;
  refetchUserDetails: () => void;
  closeContextMenu?: () => void;
}

function useUserStatusActionsDefinition({ userDetails, refetchUserDetails, closeContextMenu }: IParams) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const [confirm] = useConfirmDialog();
  const { mutate: changeUserStatus, isLoading: isChangeUserStatusLoading } = useChangeUserStatusMutation();
  const { mutate: lockUser, isLoading: isLockUserLoading } = useLockUserMutation();
  const { mutate: unlockUser, isLoading: isUnlockUserLoading } = useUnlockUserMutation();
  const { mutate: restoreUser, isLoading: isRestoreUserLoading } = useRestoreUserMutation();
  const { mutate: deleteUser, isLoading: isDeleteUserLoading } = useDeleteUserMutation();
  const { mutate: suspendUser, isLoading: isSuspendUserLoading } = useSuspendUserMutation();
  const { mutate: resumeUser, isLoading: isResumeUserLoading } = useResumeUserMutation();

  const handleChangeUserStatusToVerified = useCallback(() => {
    closeContextMenu?.();
    confirm({
      title: t('user:dialog.userRenewTitle'),
      message: t('user:dialog.userRenewRequest', { serviceNumber: userDetails?.serviceNumber }),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        changeUserStatus(
          { accountId: userDetails?.id, status: AccountSnapshotStatus.VERIFIED },
          {
            onSuccess: () => {
              closeDialog();
              showSuccessSnackbar(t('user:dialog.userRenewSuccess', { serviceNumber: userDetails?.serviceNumber }));
              refetchUserDetails();
            },
            onSettled: () => setConfirmLoading(false)
          }
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const handleChangeUserStatusToLockOrUnlock = useCallback(
    (isLock?: boolean) => {
      const { id, serviceNumber } = userDetails;

      const translations = {
        title: isLock ? t('user:dialog.userBlockTitle') : t('user:dialog.userUnblockTitle'),
        description: isLock
          ? t('user:dialog.userBlockRequest', { serviceNumber })
          : t('user:dialog.userUnblockRequest', { serviceNumber }),
        snackbarMessage: isLock
          ? t('user:dialog.userBlockSuccess', { serviceNumber })
          : t('user:dialog.userUnblockSuccess', { serviceNumber })
      };

      closeContextMenu?.();
      confirm({
        title: translations.title,
        message: translations.description,
        onConfirm: (setConfirmLoading, closeDialog) => {
          setConfirmLoading(true);
          return (isLock ? lockUser : unlockUser)(id, {
            onSuccess: () => {
              showSuccessSnackbar(translations.snackbarMessage);
              refetchUserDetails();
            },
            onSettled: () => {
              setConfirmLoading(false);
              closeDialog();
            }
          });
        },
        ...(isLock && { confirmType: 'danger' })
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userDetails]
  );

  const handleChangeUserStatusToDeleted = useCallback(() => {
    const { id, serviceNumber } = userDetails;

    closeContextMenu?.();
    confirm({
      title: t('user:dialog.deleteUser'),
      message: t('user:dialog.deleteUserConfirm', { serviceNumber }),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        deleteUser(id, {
          onSuccess: () => {
            refetchUserDetails();
            showSuccessSnackbar(t('dialog.deleteSuccess'));
          },
          onSettled: () => {
            setConfirmLoading(false);
            closeDialog();
          }
        });
      },
      confirmType: 'danger'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const handleChangeUserStatusToRestoreWithNoPassword = useCallback(() => {
    closeContextMenu?.();
    confirm({
      title: t('user:dialog.userRenewTitle'),
      message: t('user:dialog.userRenewRequest', { serviceNumber: userDetails?.serviceNumber }),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        restoreUser(
          { accountId: userDetails?.id, withNewPassword: false },
          {
            onSuccess: () => {
              refetchUserDetails();
              showSuccessSnackbar(t('user:dialog.userRenewSuccess', { serviceNumber: userDetails?.serviceNumber }));
            },
            onSettled: () => {
              setConfirmLoading(false);
              closeDialog();
            }
          }
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const handleUserRestoreWithPasswordSubmit = useCallback(
    (values: ChangePasswordValues) => {
      closeContextMenu?.();
      restoreUser(
        { accountId: userDetails?.id, withNewPassword: true, password: values.newPassword1 },
        {
          onSuccess: () => {
            refetchUserDetails();
            showSuccessSnackbar(t('user:dialog.userRenewSuccess', { serviceNumber: userDetails?.serviceNumber }));
          }
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userDetails]
  );

  const handleChangeUserStatusToSuspend = useCallback(
    (data: SuspendFormValues) => {
      closeContextMenu?.();
      suspendUser(
        { accountId: userDetails?.id, body: data },
        {
          onSuccess: () => {
            refetchUserDetails();
            showSuccessSnackbar(t('user:message.suspendUser'));
          }
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userDetails]
  );

  const handleChangeUserStatusToResume = useCallback(() => {
    closeContextMenu?.();
    resumeUser(
      { accountId: userDetails?.id },
      {
        onSuccess: () => {
          refetchUserDetails();
          showSuccessSnackbar(t('user:message.resumeUser'));
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const handleChangeUserStatus = useCallback(
    (modalTitle: string, modalContentText: string, status: AccountSnapshotStatus, successSnackbarText: string) => {
      confirm({
        title: modalTitle,
        message: modalContentText,
        onConfirm: (setConfirmLoading, closeDialog) => {
          setConfirmLoading(true);
          changeUserStatus(
            { accountId: userDetails?.id, status },
            {
              onSuccess: () => {
                refetchUserDetails();
                showSuccessSnackbar(successSnackbarText);
                if (closeContextMenu) {
                  closeContextMenu();
                } else {
                  closeDialog();
                }
              },
              onSettled: () => setConfirmLoading(false)
            }
          );
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userDetails]
  );

  return {
    handleChangeUserStatusToVerified,
    handleChangeUserStatusToLockOrUnlock,
    handleChangeUserStatusToDeleted,
    handleChangeUserStatusToRestoreWithNoPassword,
    handleUserRestoreWithPasswordSubmit,
    handleChangeUserStatusToSuspend,
    handleChangeUserStatusToResume,
    handleChangeUserStatus,
    isChangeUserStatusLoading,
    isLockUserLoading,
    isUnlockUserLoading,
    isRestoreUserLoading,
    isDeleteUserLoading,
    isSuspendUserLoading,
    isResumeUserLoading
  };
}

export default useUserStatusActionsDefinition;
