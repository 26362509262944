import { usePaginatedQuery } from 'react-query';
import { DefaultApiSearchRequest } from '@avispon/group/dist/api/default-api';
import { PageGroupMembershipDetailsExternal } from '@avispon/group/dist/models';

import { API, endpointsConfig, OrganizationQueryKeysEnum } from '..';

const searchGroups = async (_, params: DefaultApiSearchRequest) => {
  const { data } = await API.group.search(params, {
    ...endpointsConfig.search
  });
  return data;
};

export default function useGroupSearchQuery(params: DefaultApiSearchRequest, queryConfig = {}) {
  return usePaginatedQuery<PageGroupMembershipDetailsExternal>(
    [OrganizationQueryKeysEnum.SEARCH_GROUP_LIST, params],
    searchGroups,
    queryConfig
  );
}
