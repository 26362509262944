import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TableButton } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';
import useGenerateReport from '@libs/report/hooks/useGenerateReport';

import { DriverCertificateReportTypeEnum } from '@libs/domain/drivers/model';

interface TargetParameter {
  name: string;
  value: string;
}
interface IProps {
  reportType: string;
  reportTitle: DriverCertificateReportTypeEnum;
  actionKey: UIElementNameEnum;
  targetParameters?: TargetParameter[];
}

function GenerateReportButton({ reportType, reportTitle, actionKey, targetParameters }: IProps) {
  const [t] = useTranslation();

  const [generateReport, isReportTypesLoading] = useGenerateReport(reportType, targetParameters);

  const buttonTitle = useMemo(() => {
    if (reportTitle === DriverCertificateReportTypeEnum.UP_TO_DATE) {
      return t('drivers:actions.generateUpToDate');
    }

    return t('drivers:actions.generateIssued');
  }, [reportTitle, t]);

  return (
    <TableButton
      loaderWithText
      isButtonLoading={isReportTypesLoading}
      onClick={generateReport}
      label={buttonTitle}
      actionKey={actionKey}
    />
  );
}

export default GenerateReportButton;
