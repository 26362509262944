import { i18n } from '@libs/common';

export const getApplicationDetailsBreadcrumb = (formMode: string, number: string, id: string) => {
  const firstBreadcrumb = { text: i18n.t('applications:list.applicationsList'), to: '/applications' };
  const secondBreadcrumb =
    formMode !== 'view' && formMode !== 'form'
      ? { text: i18n.t('applications:newApplicationTitle') }
      : {
          text: i18n.t('applications:applicationDetailsTitleBreadcrumb', { sid: number }),
          to: `/applications/${id}/view`
        };
  const thirdBreadcrumb = formMode === 'form' ? { text: i18n.t('applications:applicationEditTitle') } : null;
  return thirdBreadcrumb ? [firstBreadcrumb, secondBreadcrumb, thirdBreadcrumb] : [firstBreadcrumb, secondBreadcrumb];
};
