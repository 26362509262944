import { useCallback, useEffect, useState } from 'react';
import { Controller, FieldError } from 'react-hook-form';
import { StringInFilter } from '@ibtm/domain';
import _ from 'lodash';

import { AutocompleteLazyFetchSelect, FormErrorType, SelectOption, useFormV2Context } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { getResourceTypes } from '@libs/domain/resource/api';
import { IResourceTypesClient } from '@libs/domain/resource/model';
import { SocialCommissionQueryKeysEnum } from '@libs/domain/social-commission/config';

interface IProps {
  fieldName?: string;
  label?: string;
  queryKey?: string;
  initialValue?: {
    id: string;
    value: string;
    nameKey: string;
    name: string;
  };
  customSetValue?: (option?: SelectOption) => void;
  isRequired?: boolean;
  typeKeyFilter?: StringInFilter;
  transitTypeKeyIn?: string[];
  yearFilter?: number;
  yearLimiter?: {
    yearGreaterThanOrEqual?: number;
    yearLessThanOrEqual?: number;
  };
  filterForTradeRules?: boolean;
  filterForBasicRules?: boolean;
  countryKeyIn?: string[];
  additionalLimit?: boolean;
  basicLimit?: boolean;
  freeDisposalLimit?: boolean;
  className?: string;
  hasError?: (error: FieldError) => boolean;
  getErrorMessage?: (error: FieldError) => string;
  hasErrorTooltip?: boolean;
}

function ResourceTypesSelectField({
  fieldName,
  label,
  queryKey = SocialCommissionQueryKeysEnum.RESOURCE_TYPE_SOCIAL_COMMISSION,
  customSetValue,
  initialValue,
  typeKeyFilter,
  transitTypeKeyIn,
  isRequired = false,
  yearFilter,
  filterForTradeRules = false,
  filterForBasicRules = false,
  countryKeyIn,
  additionalLimit,
  basicLimit,
  freeDisposalLimit,
  yearLimiter,
  className,
  hasError,
  getErrorMessage,
  hasErrorTooltip
}: IProps) {
  const queryCache = useQueryCache();
  const { setValue, control, trigger, clearErrors } = useFormV2Context();

  const [inputValue, setInputValue] = useState<SelectOption>(null);

  useEffect(() => {
    if (initialValue && !inputValue) {
      setValue(fieldName, initialValue);
      setInputValue(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setInputValue, initialValue, inputValue]);

  const handleInputChange = (event, selectedOption: SelectOption) => {
    if (selectedOption === null) {
      setInputValue({ value: undefined, name: undefined });
    } else if (event && !_.isEqual(selectedOption, inputValue)) {
      setInputValue(selectedOption);
      if (customSetValue) {
        customSetValue(selectedOption);
      } else {
        setValue(fieldName as any, selectedOption);
      }
    }
    if (fieldName) trigger(fieldName);
    clearErrors?.();
  };

  const fetchFunctionResolverWithPage = useCallback(
    (text: string, page: number) => {
      return getResourceTypes({
        page: 1 * page,
        size: 10,
        typeKeyFilter,
        fullNameContains: text,
        transitTypeKeyIn,
        yearGreaterThanOrEqual: yearFilter || yearLimiter?.yearGreaterThanOrEqual,
        yearLessThanOrEqual: yearFilter || yearLimiter?.yearLessThanOrEqual,
        countryKeyIn,
        ...(filterForBasicRules ? { usedInFolderLimitRule: false } : {}),
        ...(filterForTradeRules ? { usedInFreeDisposalRule: false } : {}),
        ...(freeDisposalLimit ? { freeDisposalLimit: true } : {}),
        ...(basicLimit ? { basicLimit: true } : {}),
        ...(additionalLimit ? { additionalLimit: true } : {})
      });
    },
    [
      additionalLimit,
      basicLimit,
      countryKeyIn,
      filterForBasicRules,
      filterForTradeRules,
      freeDisposalLimit,
      transitTypeKeyIn,
      typeKeyFilter,
      yearFilter,
      yearLimiter?.yearGreaterThanOrEqual,
      yearLimiter?.yearLessThanOrEqual
    ]
  );

  useEffect(() => {
    return () => {
      queryCache.invalidateQueries(queryKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchedDataSelectParser = (res: IResourceTypesClient[]) => {
    return res?.map(item => ({
      id: item.id,
      value: item?.name,
      name: item?.fullName,
      nameKey: item?.name,
      countryKey: item?.countryKey,
      year: item?.year
    }));
  };
  const renderOption = props => {
    return <div>{props?.name}</div>;
  };

  const customFilterOptionsMap = (options: SelectOption[]) => options;

  return !fieldName ? (
    <AutocompleteLazyFetchSelect
      name={fieldName}
      label={label}
      fetchFunctionResolverWithPage={fetchFunctionResolverWithPage}
      queryKey={queryKey}
      fetchedDataSelectParser={fetchedDataSelectParser}
      value={inputValue || null}
      renderOption={renderOption}
      onChange={handleInputChange}
      customFilterOptions={customFilterOptionsMap}
      isRequired={isRequired}
      hasErrorTooltip={hasErrorTooltip}
    />
  ) : (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => {
        const isErrorVisible = hasError?.(error) || !!error;
        const errorMessage = getErrorMessage?.(error) || error?.message;
        return (
          <AutocompleteLazyFetchSelect
            name={fieldName}
            label={label}
            fetchFunctionResolverWithPage={fetchFunctionResolverWithPage}
            queryKey={queryKey}
            fetchedDataSelectParser={fetchedDataSelectParser}
            onBlur={onBlur}
            value={value || null}
            renderOption={renderOption}
            onChange={(event, value) => {
              onChange(event, value);
              handleInputChange(event, value);
            }}
            isRequired={isRequired}
            isError={isErrorVisible && error?.type !== FormErrorType.WARNING}
            helperText={errorMessage}
            customFilterOptions={customFilterOptionsMap}
            className={className}
            hasErrorTooltip={hasErrorTooltip}
          />
        );
      }}
    />
  );
}

export default ResourceTypesSelectField;
