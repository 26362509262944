import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AccountLite } from '@avispon/user';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { AssignToMultipleProceedingsKreptdRequest, ViolationAssigneeUpdateRequest } from '@ibtm/domain';
import * as Yup from 'yup';

import { partialTranslate } from '@libs/common';
import { Dialog, FormV2Context, NUMBER_OF_AUTOCOMPLETE_RESULTS } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { API } from '@libs/organization-structure/api';
import { UserQueryKeysEnum } from '@libs/user';

import {
  RoadTransportQueryKeysEnum,
  useProceedingAssignedPersonChangeMutation,
  useViolationAssignedPersonChangeMutation
} from '../../../api';
import { AutocompleteLazyFetchSelectField } from '../common';

const getLabel = partialTranslate('roadTransport:fields');

interface IProps {
  initialData: { id: string }[];
  invokeLevel: 'VIOLATIONS' | 'PROCEEDIGNS';
  closeDialog: () => void;
  dialogTitle?: string;
}

function AssignedPersonChangeForm({ initialData, invokeLevel, closeDialog, dialogTitle }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();

  const { mutate: changeProceedingAssignedPerson, isLoading: isProceedingLoading } =
    useProceedingAssignedPersonChangeMutation();
  const { mutate: changeViolationAssignedPerson, isLoading: isViolationLoading } =
    useViolationAssignedPersonChangeMutation();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        account: Yup.object().nullable().required()
      })
    ),
    defaultValues: {
      account: null
    }
  });

  const onSubmit = formData => {
    if (invokeLevel === 'VIOLATIONS') {
      const requestData: ViolationAssigneeUpdateRequest = {
        assignee: formData.account.id,
        violationIds: initialData.map(violation => violation.id)
      };
      changeViolationAssignedPerson(requestData, {
        onSuccess: () => {
          queryCache.invalidateQueries(RoadTransportQueryKeysEnum.VIOLATIONS_LIST);
          showSnackbar('success', t('roadTransport:messages.changeAssignedPersonSuccess'));
          closeDialog();
        }
      });
    } else {
      const requestData: AssignToMultipleProceedingsKreptdRequest = {
        assignee: formData.account.id,
        proceedingKreptdIds: initialData.map(proceeding => proceeding.id)
      };
      changeProceedingAssignedPerson(requestData, {
        onSuccess: () => {
          queryCache.invalidateQueries(RoadTransportQueryKeysEnum.PROCEEDINGS_LIST);
          showSnackbar('success', t('roadTransport:messages.changeAssignedPersonSuccess'));
          closeDialog();
        }
      });
    }
  };

  const fetchNameOptionsFunction = (searchText: string, page: number) => {
    return API.user.accountController.searchLiteAccounts({
      page,
      size: NUMBER_OF_AUTOCOMPLETE_RESULTS,
      query: searchText,
      sort: [],
      statuses: ['VERIFIED']
    });
  };
  const parseDataToSelect = data => {
    return data?.data?.content.map((record: AccountLite) => ({
      id: record.id,
      value: record,
      name: record?.name || record?.surname ? `${record?.name} ${record?.surname}` : t('emptyMark')
    }));
  };
  const getOptionLabel = option => {
    return option.name;
  };

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={dialogTitle ?? t('roadTransport:dialog.transferTitle')}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={() => handleSubmit(onSubmit)()}
      onCancel={closeDialog}
      isConfirmLoading={isProceedingLoading || isViolationLoading}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <AutocompleteLazyFetchSelectField
          name="account"
          label={getLabel('assignedTo')}
          fetchNameOptionsFunction={fetchNameOptionsFunction}
          fetchedDataSelectParser={parseDataToSelect}
          queryKey={UserQueryKeysEnum.USER_ACCOUNT_CONTROLLER_FIELD}
          getOptionLabel={getOptionLabel}
        />
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default AssignedPersonChangeForm;
