import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { useConfirmDialog } from '@libs/common/v2';

import { useDeletePermissionMutation } from '../../api';

function usePermissionDeleteAction() {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: deletePermission } = useDeletePermissionMutation();

  const [confirm] = useConfirmDialog();

  const handlePermissionDelete = ({ permissionId, onSuccess }: { permissionId: string; onSuccess: () => void }) => {
    confirm({
      title: t('permission:action.delete.dialogTitle'),
      shortTitle: t('global:dialog.deleteConfirmTitle'),
      message: t('permission:action.delete.confirmationText'),
      confirmType: 'danger',
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        deletePermission(
          { id: permissionId },
          {
            onSuccess: () => {
              showSuccessSnackbar(t('permission:action.delete.successMessage'));
              closeDialog();
              onSuccess();
            },
            onSettled: () => {
              setConfirmLoading(false);
            }
          }
        );
      }
    });
  };

  return { handlePermissionDelete };
}

export default usePermissionDeleteAction;
