import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { get, set } from 'lodash';

import { prepareFieldName, useTableContext } from '@libs/common/v2';

/**
 * Walidator używany przy tabeli mutowalnej.
 * Zapewnia walidację tylko wiersza który aktualnie edytujemy/tworzymy
 */
const useCustomYupResolver = (validationSchema, rowId: string) => {
  const [t] = useTranslation();
  const { columnsToInsert: { columns = [] } = {} } = useTableContext();

  // Funkcja używana gdy korzystamy z plugina dodawania kolumn do tabeli.
  // Odfiltrowuje puste wartości przed przystąpieniem do walidacji
  const getDataToValidate = useCallback(
    data => {
      if (!columns.length) {
        return data;
      }
      const filteredData = columns.reduce((acc, { id, idIndexing = index => `${id}.${index}` }) => {
        const colSplittedIndexedId = idIndexing(0).split('.');
        const colNumberIndexId = colSplittedIndexedId.findIndex(el => el.match(/[0-9]/g));
        const path = colSplittedIndexedId.slice(0, colNumberIndexId).join('.');

        return set(
          acc,
          path,
          get(data, `${rowId}.${path}`)?.filter(el => el)
        );
      }, {});

      return { ...data, [rowId]: { ...data[rowId], ...filteredData } };
    },
    [columns, rowId]
  );

  // Funkcja walidująca na podstawie przekazanego schematu
  return useCallback(
    async data => {
      try {
        const dataToValidate = getDataToValidate(data)?.[rowId] ?? getDataToValidate(data);

        const values = await validationSchema?.validate?.(dataToValidate, {
          abortEarly: false,
          context: data
        });

        return {
          values: values ?? dataToValidate,
          errors: {}
        };
      } catch (errors: any) {
        return {
          values: {},
          errors:
            errors.inner?.reduce?.(
              (allErrors, currentError) => ({
                ...allErrors,
                ...(data[rowId]
                  ? {
                      [rowId]: set({ ...allErrors[rowId] }, prepareFieldName(currentError.path), {
                        type: currentError.type ?? 'validation',
                        message: t(currentError.message)
                      })
                    }
                  : {
                      [currentError.path]: {
                        type: currentError.type ?? 'validation',
                        message: t(currentError.message)
                      }
                    })
              }),
              {}
            ) ?? errors
        };
      }
    },
    [getDataToValidate, rowId, t, validationSchema]
  );
};

export default useCustomYupResolver;
