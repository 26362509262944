import { FieldPath, FieldValues } from 'react-hook-form';
import { DataSourceMediatorColumn } from '@stack/report/dist/models';

import { FormMode } from '@libs/common/v2';

import ConditionBuilder from './ConditionBuilder';
import { SingleConditionForm } from './SingleConditionForm';

interface IProps {
  name: FieldPath<FieldValues>;
  columns: DataSourceMediatorColumn[];
  formMode: FormMode;
  isParameterizable?: boolean;
}

function ConditionBasic({ name, columns, formMode, isParameterizable }: IProps) {
  return (
    <ConditionBuilder fieldName={name} formMode={formMode}>
      {(formName, index, onConditionRemove) => {
        return (
          <SingleConditionForm
            fieldName={formName}
            index={index}
            columns={columns}
            isParameterizable={isParameterizable}
            onConditionRemove={onConditionRemove}
            key={formName}
          />
        );
      }}
    </ConditionBuilder>
  );
}

export default ConditionBasic;
