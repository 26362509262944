import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderCreateRequest } from '@ibtm/domain';

import {
  DictionarySelectField,
  InputMode,
  SectionWithFields,
  SelectOption,
  TextInputField,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { useDictionaryTranslations } from '@libs/dictionary';
import { useElementVisibility } from '@libs/permission/hooks';

import { DomainDictionaryEntry, DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';
import { useFoldersListQuery } from '@libs/domain/folder/api';
import RegonButtonSkeleton from '@libs/domain/folder/components/common/regon/RegonButtonSkeleton';

function SubjectDataSection() {
  const [t] = useTranslation();

  const { translate } = useDictionaryTranslations();
  const { watch, formMode } = useFormV2Context();
  const { createMode } = useViewModesV2(formMode);
  const [nipHelperText, setNipHelperText] = useState<string>(null);
  const { checkIsElementVisible } = useElementVisibility();

  const typeKey = watch(typedNameV2<FolderCreateRequest>('typeKey')) as SelectOption<string>;
  const legalFormKey = watch(typedNameV2<FolderCreateRequest>('subject.legalFormKey')) as string;
  const nip = watch(typedNameV2<FolderCreateRequest>('subject.nip')) as string;

  const { data: folderList } = useFoldersListQuery(
    { subjectNipContains: nip },
    { enabled: createMode && nip?.length === 10 }
  );

  useEffect(() => {
    if (folderList && folderList.content?.length > 0 && nip?.length === 10) {
      const alreadyExistiongFolderTypes = folderList.content
        .map(item => translate(DomainDictionaryEnum.FOLDER_TYPE, item.typeKey))
        .join(', ');
      if (folderList.content?.length === 1) {
        setNipHelperText(
          t('folder:details.message.subjectHasExistingFolder', {
            name: alreadyExistiongFolderTypes
          })
        );
      } else {
        setNipHelperText(
          t('folder:details.message.subjectHasExistingFolders', {
            name: alreadyExistiongFolderTypes
          })
        );
      }
    } else {
      setNipHelperText(null);
    }
  }, [folderList, nip?.length, t, translate]);
  const countryOfOrigin = watch(typedNameV2<FolderCreateRequest>('subject.countryOfOrigin')) as string;

  const isCountryOfOriginDisabled = typeKey?.value !== DomainDictionaryEntry.FOLDER_TYPE.MZ;
  const isCountryOfOriginPoland = !countryOfOrigin || countryOfOrigin === DomainDictionaryEntry.COUNTRY_CODE.POLAND;
  const isNaturalPerson = legalFormKey === DomainDictionaryEntry.LEGAL_NATURE_KEY.NATURAL_PERSON;
  const isFolderAddEntrepreneursVisible = checkIsElementVisible(DomainUIElementEnum.FOLDER_CIVIL_PARTNERSHIP);

  const getOptions = (option: SelectOption) => {
    return isFolderAddEntrepreneursVisible || option.value !== DomainDictionaryEntry.LEGAL_NATURE_KEY.CIVIL_PARTNERSHIP;
  };

  return (
    <SectionWithFields
      title={t('folder:details.section.legalNature')}
      headerContent={createMode && <RegonButtonSkeleton />}
      fields={[
        <DictionarySelectField
          name={typedNameV2<FolderCreateRequest>('subject.subjectTypeKey')}
          label={t('folder:details.field.subjectType')}
          dictionaryName={DomainDictionaryEnum.SUBJECT_TYPE}
          key="details.field.subjectType"
          inputMode={InputMode.VIEW}
          stringValue
        />,
        <DictionarySelectField
          name={typedNameV2<FolderCreateRequest>('subject.countryOfOrigin')}
          label={t('folder:details.field.countryOfOrigin')}
          dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
          isDisabled={isCountryOfOriginDisabled}
          stringValue
          inputMode={isCountryOfOriginDisabled && InputMode.VIEW}
          isRequired={!isCountryOfOriginDisabled}
          key="details.field.countryOfOrigin"
        />,
        <TextInputField
          inputProps={{ maxLength: 10 }}
          name={typedNameV2<FolderCreateRequest>('subject.nip')}
          isOnlyPositiveIntegers
          label={t('folder:field.subjectNip')}
          isRequired={isCountryOfOriginPoland}
          key="field.nip"
          helperText={nipHelperText}
          isHelperTextWarning={Boolean(nipHelperText)}
        />,
        <TextInputField
          inputProps={{ maxLength: 10 }}
          name={typedNameV2<FolderCreateRequest>('subject.krs')}
          isOnlyPositiveIntegers
          label={t('folder:details.field.krs')}
          isRequired={!isNaturalPerson && isCountryOfOriginPoland}
          key="field.krs"
        />,
        <TextInputField
          inputProps={{ maxLength: 9 }}
          name={typedNameV2<FolderCreateRequest>('subject.regon')}
          isOnlyPositiveIntegers
          label={t('folder:details.field.regon')}
          isRequired={isCountryOfOriginPoland}
          key="field.regon"
        />,
        <DictionarySelectField
          name={typedNameV2<FolderCreateRequest>('subject.legalFormKey')}
          label={t('folder:details.field.legalFormKey')}
          dictionaryName={DomainDictionaryEnum.LEGAL_NATURE}
          isRequired
          stringValue
          optionsFilter={getOptions}
          key="details.field.legalFormKey"
        />,
        <TextInputField
          inputProps={{ maxLength: 500 }}
          name={typedNameV2<FolderCreateRequest>('subject.name')}
          label={t('folder:details.field.name')}
          isRequired
          key="details.field.name"
        />,
        <TextInputField
          inputProps={{ maxLength: 50 }}
          name={typedNameV2<FolderCreateRequest>('subject.givenName')}
          label={t('folder:details.field.subjectName')}
          isRequired={isNaturalPerson}
          key="details.field.subjectName"
        />,
        <TextInputField
          inputProps={{ maxLength: 80 }}
          name={typedNameV2<FolderCreateRequest>('subject.surname')}
          label={t('folder:details.field.subjectSurname')}
          isRequired={isNaturalPerson}
          key="details.field.subjectSurname"
        />
      ]}
    />
  );
}

export default SubjectDataSection;
