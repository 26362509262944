import { useTranslation } from 'react-i18next';

import { Button } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

import { useAddDriverAction } from '@libs/domain/drivers/hooks';

interface IProps {
  subjectNip?: string;
  actionKey?: UIElementNameEnum;
  folderId: string;
  transferredFoldersIds?: string[];
}

function AddDriverButton({ subjectNip, actionKey, folderId, transferredFoldersIds }: IProps) {
  const [t] = useTranslation();
  const { handleOpenDialog } = useAddDriverAction({ folderId, transferredFoldersIds, subjectNip });

  return (
    <Button
      variant="outlined"
      onClick={handleOpenDialog}
      label={t('action.add')}
      classNameWrapper="ml-16"
      actionKey={actionKey}
      isNoMargin
    />
  );
}

export default AddDriverButton;
