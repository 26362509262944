import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppConfig } from '@libs/app-config';
import { Divider } from '@mui/material';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

import { BackButton, Button, DropdownButton, Page, useRouter } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { calc, hexToRgba, important } from '@libs/common/v2/utils';

import { useAuth } from '@libs/auth/hooks';
import { useDictionaryTranslations } from '@libs/dictionary';
import useGenerateReport from '@libs/report/hooks/useGenerateReport';

import { DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';

import { useDepotDetailsQuery, useDepotManagersListQuery } from '../api';
import { ResourceDetailsTabs, ResourcesPageHeader } from '../components';
import {
  DepotAdminsChangeForm,
  ExternalReceivesForm,
  ExternalReleaseForm,
  SearchFormsForm,
  TypeTransformForm
} from '../components/forms';
import { useResourceTypeCreateDialog } from '../components/forms/resource-type-dialog';
import { useDeleteFormTypeDialog, useResourceDialog } from '../hooks';
import { DepotTranslationKeys, DepotTypes, ResourceDetailsParams } from '../model';

function ResourcesDetailsPage() {
  const [t] = useTranslation();
  const { depotId } = useParams<ResourceDetailsParams>();
  const classes = useSimpleContentStyles();
  const { reportTypes } = useAppConfig();
  const { translate } = useDictionaryTranslations();
  const { routes } = useRouter();
  const { user } = useAuth();
  const { data: depotDetails, isLoading: isDepotDetailsLoading } = useDepotDetailsQuery(depotId, {
    enabled: !!depotId
  });
  const { data: depotManagersList } = useDepotManagersListQuery({ userId: user?.personalData?.id });
  const depotType = translate(DomainDictionaryEnum.DEPOT_TYPE, depotDetails?.type);
  const depotName = depotDetails?.name ? ` ${depotDetails.name}` : '';
  const [generateReport, isReportTypesLoading] = useGenerateReport(reportTypes.resourcesStateOverview.reportTypeName, [
    {
      name: reportTypes.resourcesStateOverview.targetParameters.resource,
      value: `${depotType}${depotName}`
    }
  ]);
  const { openDeleteFormTypeDialog } = useDeleteFormTypeDialog();

  const { openCreateResourceTypeDialog } = useResourceTypeCreateDialog();
  const { openDialog } = useResourceDialog();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isAdmin = useMemo(() => (depotManagersList?.depotIds || []).includes(depotId), [depotManagersList]);

  const handleChangeAdmins = (closePopup: () => void) => {
    closePopup();

    openDialog(DepotAdminsChangeForm, { initialData: { id: depotId } });
  };

  const handleGenerateReport = () => {
    generateReport();
  };

  const handleDeleteFormTypes = closeMenu => {
    closeMenu();

    openDeleteFormTypeDialog();
  };

  const handleExternalReceives = closeMenu => {
    closeMenu();

    openDialog(ExternalReceivesForm, {});
  };

  const handleExternalRelease = closeMenu => {
    closeMenu();

    openDialog(ExternalReleaseForm, { depotId });
  };

  const handleTypeTransform = closeMenu => {
    closeMenu();
    openDialog(TypeTransformForm, { depotId });
  };

  const menuButtonsCommonProps = useMemo(
    () => ({
      tabIndex: 0,
      variant: 'text' as MuiButtonProps['variant'],
      size: 'small' as MuiButtonProps['size'] | 'xl' | 'xxl',
      className: classes.button,
      fullWidth: true,
      isNoMargin: true
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Page
      header={
        <ResourcesPageHeader title={t(DepotTranslationKeys[depotDetails?.code])} code={depotDetails?.code} id={depotId}>
          <SearchFormsForm />
          <Divider orientation="vertical" style={{ height: 30, margin: '0px 8px' }} />
          <BackButton link={routes.resourcesList()} className="mr-2" />

          <div className={classes.dropdownButton}>
            <DropdownButton>
              {({ handleClose }) => (
                <>
                  {depotDetails?.code === DepotTypes.MG && (
                    <>
                      <Button
                        onClick={() => {
                          handleClose();
                          openCreateResourceTypeDialog();
                        }}
                        label={t('resource:actions.createNewFormType')}
                        actionKey={DomainUIElementEnum.RESOURCES_DEPOT_CREATE_OBJECT_TYPE}
                        {...menuButtonsCommonProps}
                      />
                      <Button
                        onClick={() => handleExternalReceives(handleClose)}
                        label={t('resource:actions.externalReceive')}
                        actionKey={DomainUIElementEnum.RESOURCES_EXTERNAL_RECEIVE}
                        {...menuButtonsCommonProps}
                      />

                      <Button
                        onClick={() => handleExternalRelease(handleClose)}
                        label={t('resource:actions.externalRelease')}
                        actionKey={DomainUIElementEnum.RESOURCES_EXTERNAL_RELEASE}
                        {...menuButtonsCommonProps}
                      />
                    </>
                  )}
                  {depotDetails?.code === DepotTypes.MGB && (
                    <Button
                      link={routes.disposalsList()}
                      label={t('resource:actions.disposalsList')}
                      {...menuButtonsCommonProps}
                    />
                  )}
                  {depotDetails?.code === DepotTypes.MG && (
                    <Button
                      onClick={() => handleDeleteFormTypes(handleClose)}
                      label={t('resource:actions.deleteFormType')}
                      actionKey={DomainUIElementEnum.RESOURCES_OBJECT_TYPE_DELETE}
                      {...menuButtonsCommonProps}
                    />
                  )}
                  {isAdmin && (
                    <Button
                      onClick={() => handleChangeAdmins(handleClose)}
                      label={t('resource:actions.changeAdmins')}
                      actionKey={DomainUIElementEnum.RESOURCES_DEPOT_CHANGE_ADMIN}
                      {...menuButtonsCommonProps}
                    />
                  )}
                  <Button
                    link={routes.resourceTransferDetails(depotId)}
                    label={t('resource:actions.transferForms')}
                    actionKey={DomainUIElementEnum.RESOURCES_OBJECT_TRANSFER_INIT}
                    {...menuButtonsCommonProps}
                  />
                  <Button
                    onClick={() => handleTypeTransform(handleClose)}
                    label={t('resource:actions.typeTransform')}
                    actionKey={DomainUIElementEnum.RESOURCES_OBJECT_TYPE_MANAGEMENT}
                    {...menuButtonsCommonProps}
                  />
                  <Button
                    className={isReportTypesLoading && classes.disabledButton}
                    label={t('resource:actions.resourcesStateOverview')}
                    onClick={() => handleGenerateReport()}
                    actionKey={DomainUIElementEnum.RESOURCES_DEPOT_STATE_REPORT}
                    isLoading={isReportTypesLoading || isDepotDetailsLoading}
                    disabled={isReportTypesLoading}
                    {...menuButtonsCommonProps}
                  />
                </>
              )}
            </DropdownButton>
          </div>
        </ResourcesPageHeader>
      }
      content={<ResourceDetailsTabs code={depotDetails?.code as DepotTypes} />}
      isLoading={!depotManagersList || !depotDetails}
      contentClassName={classes.content}
      isTable
    />
  );
}

const useSimpleContentStyles = makeStyles<Theme>(theme => ({
  dropdownButton: {
    '& > button': { backgroundColor: 'transparent' }
  },
  loader: {
    marginLeft: 16
  },
  disabledButton: {
    pointerEvents: 'none',
    boxShadow: 'none',
    color: important(hexToRgba(theme.palette.grey[700], 0.26))
  },
  button: {
    padding: '12px 53px 12px 16px',
    width: '100%',
    justifyContent: 'flex-start',
    borderRadius: 0
  },
  content: {
    height: calc('100% - 150px'),
    display: 'flex',
    flexDirection: 'column'
  }
}));

export default ResourcesDetailsPage;
