import { PluginHook } from 'react-table';
import { MisspellApplicationDataPermissionDetails } from '@ibtm/domain';

import { ITableLayoutColumn, useTableLayoutProps } from '@libs/common/v2';

import useMisspellApplicationPermissionColumn, {
  AccesorType,
  ApplicationMisspellData
} from './useMisspellApplicationPermissionColumn';

interface IParams {
  data: MisspellApplicationDataPermissionDetails[];
  hiddenColumns: Array<keyof ApplicationMisspellData>;
  tablePlugins?: Array<PluginHook<MisspellApplicationDataPermissionDetails>>;
  numberColumnHeader?: string;
}

function useMispellApplicationPermissionTable({ data, hiddenColumns, tablePlugins, numberColumnHeader }: IParams) {
  const valueAccessor: AccesorType = 'permission';
  let columns = useMisspellApplicationPermissionColumn(
    valueAccessor,
    numberColumnHeader
  ) as ITableLayoutColumn<MisspellApplicationDataPermissionDetails>[];
  columns = hiddenColumns.length
    ? columns.filter(({ id }) => !hiddenColumns.includes(id as keyof ApplicationMisspellData))
    : columns;

  return useTableLayoutProps<MisspellApplicationDataPermissionDetails, MisspellApplicationDataPermissionDetails>({
    tableQuery: data,
    tableHookOptions: {
      columns,
      getRowId: (row: MisspellApplicationDataPermissionDetails) => row?.[valueAccessor]?.id,
      tablePlugins
    }
  });
}

export default useMispellApplicationPermissionTable;
