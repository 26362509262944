import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryCache } from '@libs/common/v2/api';

import { useDictionaryTranslations } from '@libs/dictionary/hooks';

import { INoteSectionProps, NoteSection } from '@libs/domain/notes';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '../../config';
import { ApplicationQueryKeysEnum } from '../api';

interface IProps extends INoteSectionProps {
  applicationStatusKey?: string;
}

function ApplicationNoteSection({ applicationId, applicationStatusKey, ...rest }: IProps) {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  const queryCache = useQueryCache();

  const isApplicationStatusAccomplished = useMemo(
    () => applicationStatusKey === DomainDictionaryEntry.APPLICATION_STATUS.ACCOMPLISHED,
    [applicationStatusKey]
  );

  const handleOnRefetch = () => {
    queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
  };

  return (
    <NoteSection
      applicationId={applicationId}
      refetch={handleOnRefetch}
      isDisabled={isApplicationStatusAccomplished}
      customTooltipTitle={
        isApplicationStatusAccomplished
          ? t('applications:tooltip.statusBlockEdit', {
              status: translate(DomainDictionaryEnum.APPLICATION_STATUS, applicationStatusKey, t('emptyMark'))
            })
          : t('action.edit')
      }
      {...rest}
    />
  );
}

export default ApplicationNoteSection;
