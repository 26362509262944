import React, { useContext } from 'react';
import { Grid } from '@mui/material';

import {
  AdditionalInformationSection,
  LoginSection,
  OrganizationalUnitSection,
  OrganizationDataSection,
  PersonalDataSection,
  WorkSystemHoursSection
} from '@libs/user/components';
import {
  SectionNames,
  useConfigurationContext,
  UserDetailsCustomSectionsContext,
  UserDetailsSerialNumbersSectionsContext
} from '@libs/user/context';

interface IProps {
  requiredFields?: Record<string, boolean>;
  isClientPortal?: boolean;
}

function UserGeneralTab({ requiredFields, isClientPortal }: IProps) {
  const { isSectionVisible, renderAdditionalSection } = useConfigurationContext();

  const customSections = useContext(UserDetailsCustomSectionsContext);
  const serialNumbersSection = useContext(UserDetailsSerialNumbersSectionsContext);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6}>
        {isSectionVisible(SectionNames.PERSONAL_DATA_SECTION) && (
          <Grid item xs={12}>
            <PersonalDataSection requiredFields={requiredFields} isClientPortal={isClientPortal} />
          </Grid>
        )}
        {isSectionVisible(SectionNames.ORGANIZATION_DATA_SECTION) && (
          <Grid item xs={12}>
            <OrganizationDataSection />
          </Grid>
        )}
        {isSectionVisible(SectionNames.LOGIN_SECTION) && (
          <Grid item xs={12}>
            <LoginSection />
          </Grid>
        )}
        {isSectionVisible(SectionNames.WORK_SYSTEM_HOURS_SECTION) && (
          <Grid item xs={12}>
            <WorkSystemHoursSection />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        {isSectionVisible(SectionNames.ADDITIONAL_INFORMATION_SECTION) && (
          <Grid item xs={12}>
            <AdditionalInformationSection />
          </Grid>
        )}
        {isSectionVisible(SectionNames.ORGANIZATION_UNIT_SECTION) && (
          <Grid item xs={12}>
            <OrganizationalUnitSection />
          </Grid>
        )}
        {serialNumbersSection}
      </Grid>
      {renderAdditionalSection()}
      {customSections}
    </Grid>
  );
}

export default UserGeneralTab;
