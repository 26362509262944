import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface IParams {
  ekmtSettlementId: string;
}

function deleteEkmtCarnetSettlement({ ekmtSettlementId }: IParams) {
  return API.foreignPermissions.deleteEkmtCarnetSettlement(ekmtSettlementId, {
    ...endpointsConfig.createEkmtCarnetSettlement
  });
}

function useDeleteEkmtCarnetSettlementMutation() {
  return useMutation(deleteEkmtCarnetSettlement);
}

export default useDeleteEkmtCarnetSettlementMutation;
