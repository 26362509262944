import { useTranslation } from 'react-i18next';
import { ArchiveFolderSearchFilter } from '@ibtm/domain';

import {
  CamelCasePath,
  SelectOption,
  TableFilter,
  TableFilterMapper,
  TableSortMapper,
  useColumns,
  useCreateColumns
} from '@libs/common/v2';
import { DateRangeISO, getCalendarDate, getValue } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';
import { FolderTypeChip } from '@libs/domain/folder';

import { useFilteredDictionary } from '../../../resource';
import { FolderArchiveSnapshot } from '../model';

interface IProps {
  hiddenColumns?: CamelCasePath<FolderArchiveSnapshot>[];
  hiddenLocalizatinDictionaryOptions?: string[];
}

const useFolderArchiveColumns = ({ hiddenColumns = [], hiddenLocalizatinDictionaryOptions = [] }: IProps) => {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  const { createColumns } = useCreateColumns<FolderArchiveSnapshot>({
    pathToTranslate: 'folder:field'
  });

  const { entries: filteredFolderStatusEntries } = useFilteredDictionary(DomainDictionaryEnum.FOLDER_STATUS, [
    DomainDictionaryEntry.FOLDER_STATUS.TRANSFER_TO_COUNTY_OFFICE,
    DomainDictionaryEntry.FOLDER_STATUS.INACTIVE,
    DomainDictionaryEntry.FOLDER_STATUS.NEW
  ]);

  const { entries: filteredLocalizationEntries } = useFilteredDictionary(
    DomainDictionaryEnum.FOLDER_LOCALIZATION,
    hiddenLocalizatinDictionaryOptions
  );

  const mappedSortFields: TableSortMapper<FolderArchiveSnapshot> = {
    classificationKey: 'jrwaCategory.category',
    expiredDate: 'expirationTime',
    subjectName: 'subject.name',
    warning: 'archiveNote'
  };

  const mappedFilterFields: TableFilterMapper<FolderArchiveSnapshot, ArchiveFolderSearchFilter> = {
    number: (number: string) => ({ numberContains: number.trim() }),
    typeKey: (typeKeys: SelectOption<string>[]) => ({ typeKeyIn: typeKeys.map(({ value }) => value) }),
    classificationKey: (jrwaCategory: string) => ({ jrwaCategoryContains: jrwaCategory }),
    created: ({ dateFrom, dateTo }: DateRangeISO) => ({
      createdGreaterThanOrEqual: dateFrom,
      createdLessThanOrEqual: dateTo
    }),
    expiredDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      expirationDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      expirationDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    subjectName: (subjectName: string) => ({ subjectNameContains: subjectName.trim() }),
    fullAddress: (address: string) => ({ fullAddressContains: address.trim() }),
    warning: (warning: string) => ({ archiveNoteContains: warning.trim() }),
    statusKey: (statusKeys: SelectOption<string>[]) => ({ statusKeyIn: statusKeys.map(({ value }) => value) }),
    folderLocalizationKey: (folderLocalizationKeys: SelectOption<string>[]) => ({
      localizationKeyIn: folderLocalizationKeys.map(({ value }) => value)
    }),
    passedToDepartmentalArchiveDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      passedToDepartmentalArchiveDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      passedToDepartmentalArchiveDateLessThanOrEqual: getCalendarDate(dateTo)
    })
  };

  const allColumns = createColumns([
    {
      accessor: 'number',
      type: 'TEXT'
    },
    {
      id: 'typeKey',
      type: 'CUSTOM_COLUMN',
      tooltipTitle: ({ typeKey }) => translate(DomainDictionaryEnum.FOLDER_TYPE, typeKey) || t('emptyMark'),
      customAccessor: ({ typeKey }) => <FolderTypeChip typeKey={typeKey ?? t('emptyMark')} />,
      customXlsxAccessor: ({ typeKey }) => translate(DomainDictionaryEnum.FOLDER_TYPE, typeKey, t('emptyMark')),
      filter: props => (
        <TableFilter.DictionaryMultiSelect
          {...props}
          placeholder={t('action.select')}
          dictionary={DomainDictionaryEnum.FOLDER_TYPE}
        />
      )
    },
    {
      accessor: 'classificationKey',
      type: 'TEXT',
      header: 'archive:folder.field.classificationKey'
    },
    {
      accessor: 'created',
      type: 'DATE'
    },
    {
      accessor: 'expiredDate',
      type: 'DATE',
      header: 'archive:folder.field.expiredDate'
    },
    {
      accessor: 'subject.name',
      type: 'TEXT'
    },
    {
      accessor: 'fullAddress',
      header: 'primaryAddress',
      type: 'TEXT'
    },
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.FOLDER_STATUS,
      accessor: 'statusKey',
      filter: props => (
        <TableFilter.DictionaryMultiSelect
          {...props}
          dictionary={DomainDictionaryEnum.FOLDER_STATUS}
          options={filteredFolderStatusEntries}
        />
      )
    },
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.FOLDER_LOCALIZATION,
      accessor: 'folderLocalizationKey',
      header: 'archive:folder.field.folderLocalization',
      filter: props => (
        <TableFilter.DictionaryMultiSelect
          {...props}
          dictionary={DomainDictionaryEnum.FOLDER_LOCALIZATION}
          options={filteredLocalizationEntries}
        />
      )
    },
    {
      type: 'DATE',
      accessor: 'passedToDepartmentalArchiveDate',
      header: 'archive:folder.field.passedToDepartmentalArchiveDate'
    },
    {
      id: 'warning',
      header: 'archive:folder.field.warning',
      type: 'CUSTOM_COLUMN',
      customAccessor: ({ warning }) => (warning ? <span>{getValue(warning)}</span> : getValue(warning)),
      customXlsxAccessor: ({ warning }) => getValue(warning),
      filter: TableFilter.Text
    }
  ]);

  const columns = useColumns<FolderArchiveSnapshot>({
    columns: allColumns,
    hiddenColumns
  });

  return { columns, mappedFilterFields, mappedSortFields };
};

export default useFolderArchiveColumns;
