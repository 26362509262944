import { Context, useContext, useEffect } from 'react';
import { FieldPath, FieldValues, useWatch } from 'react-hook-form';
import _ from 'lodash';

import { SelectOption } from '@libs/common/v2/components';

import { FormV2Context } from '../context';

function useSetInitialSelectFieldValue(
  name: FieldPath<FieldValues>,
  options: SelectOption[],
  stringValue?: boolean,
  formContext: Context<any> = FormV2Context,
  isDictionaryValue?: boolean
) {
  const { setValue, control } = useContext(formContext);
  const valueFromContext = useWatch({ name, control });

  useEffect(() => {
    if (valueFromContext) {
      if (
        !Array.isArray(valueFromContext) &&
        (!valueFromContext?.name || (isDictionaryValue && valueFromContext?.name === valueFromContext?.value)) &&
        options?.length
      ) {
        const newValue = options.find(
          option =>
            option.value === valueFromContext?.value ||
            (Boolean(option?.id) && option.id === valueFromContext?.id) ||
            option.value === valueFromContext ||
            (Boolean(option?.id) && option.id === valueFromContext)
        );
        // Mechanizm zabezpieczający przed sytuacją w której name i value są takie same, co powodowałoby ciągłe nadpisywanie wartości dla słowników
        // zmiana - wyłączenie mechanizmu
        // powodował problem w formularzu w którym pole słownikowe było w trybie VIEW i jego wartość była wymagane, tłumaczenia i klucze w słowniku miały takie same wartości, a mechanizm blokował ustawienie wartości.
        if (newValue && !_.isEqual(newValue, valueFromContext)) {
          setValue(name, stringValue ? newValue.value : newValue);
        }
      }
      if (Array.isArray(valueFromContext) && options?.length) {
        let shouldSetValue = false;
        const valuesToForm = valueFromContext
          .filter(value => !!value)
          .map(value => {
            if (value?.name || stringValue) {
              return stringValue ? value.value || value : value;
            }
            shouldSetValue = true;
            const dictionaryKey = value?.value;
            let selectedOption = options
              .filter(option => option?.value || option?.id)
              .find(option => {
                return (
                  option.value === dictionaryKey ||
                  (Boolean(option.id) && option.id === value.id) ||
                  option.value === value
                );
              });

            if (!selectedOption && _.isString(value)) {
              selectedOption = {
                value,
                name: value
              };
            }
            return stringValue ? selectedOption.value : selectedOption;
          });

        if (shouldSetValue && (!_.isArray(valuesToForm) || !_.isEmpty(valuesToForm.filter(Boolean)))) {
          setValue(name, valuesToForm);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueFromContext, JSON.stringify(options), name]);
}

export default useSetInitialSelectFieldValue;
