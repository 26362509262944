import { useTranslation } from 'react-i18next';
import { ProceedingKreptdCertificateDetails } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import { DatepickerField, GridItem, GridLayout, InputMode, Section } from '@libs/common/v2';
import { convertDateToDateFormat } from '@libs/common/v2/utils';

import { DomainUIElementEnum } from '@libs/domain/config';

import { isFieldVisible } from '../../../utils';
import { CertificatesTable } from '../../list';

interface IProps {
  hiddenFields?: Array<'certificatesReturnTimeToReturn'>;
  isDeclarationOfInability?: boolean;
  data?: ProceedingKreptdCertificateDetails[];
}

function CertificatesReturnSection({ hiddenFields = [], isDeclarationOfInability = false, data }: IProps) {
  const [t] = useTranslation();
  const getTranslation = partialTranslate('roadTransport:fields');

  return (
    <Section
      title={t('roadTransport:sections.certificatesReturn')}
      actionKey={DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_CERTIFICATES_RETURN_SECTION}
      isCollapsable
    >
      <GridLayout itemProps={{ xs: 6 }}>
        {isFieldVisible('certificatesReturnTimeToReturn', hiddenFields) && (
          <DatepickerField
            name="certificatesReturnTimeToReturn"
            label={getTranslation('certificatesReturnTimeToReturn')}
            viewModeDateParser={convertDateToDateFormat}
            inputMode={InputMode.VIEW}
          />
        )}
        {isDeclarationOfInability && (
          <GridItem xs={12}>
            <CertificatesTable data={data} />
          </GridItem>
        )}
      </GridLayout>
    </Section>
  );
}

export default CertificatesReturnSection;
