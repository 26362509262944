import { useCallback } from 'react';

import { useDialog } from '@libs/common/v2';

import CreateArchivePackageDialog from '../components/common/CreateArchivePackageDialog';

const useCreateArchivePackageDialog = () => {
  const { openDialog } = useDialog();

  const showDialog = useCallback(() => {
    openDialog(({ closeDialog }) => <CreateArchivePackageDialog closeDialog={closeDialog} />);
  }, [openDialog]);

  const openCreateArchivePackageDialog = () => {
    showDialog();
  };

  return {
    openCreateArchivePackageDialog
  };
};

export default useCreateArchivePackageDialog;
