enum UserStatusKeysEnum {
  DELETED = 'DELETED',
  DISABLED_MIGRATED = 'DISABLED_MIGRATED',
  VERIFIED = 'VERIFIED',
  BLOCKED = 'BLOCKED',
  EXPIRED = 'EXPIRED',
  SUSPENDED = 'SUSPENDED',
  UNCONFIRMED = 'UNCONFIRMED',
  UNVERIFIED = 'UNVERIFIED',
  DISABLED = 'DISABLED'
}

export default UserStatusKeysEnum;
