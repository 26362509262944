import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PermissionCopySearchFilter } from '@ibtm/domain';

import {
  CamelCasePath,
  ColumnTypesEnum,
  ISingleColumn,
  SelectOption,
  TableFilterMapper,
  TableSortMapper,
  useCreateColumns
} from '@libs/common/v2';
import { convertDateToDateFormat, DateRangeISO, getCalendarDate } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';
import { UserFilterV2 } from '@libs/user';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { PermissionCopiesColumnsEnum, PermissionCopySnapshotClient } from '../model';

const columnDefaultProperties = { isFilterable: true, isSortable: true };

const usePermissionCopiesColumn = (
  visibleColumns: Array<CamelCasePath<PermissionCopySnapshotClient>>,
  editableColumns?: Array<CamelCasePath<PermissionCopySnapshotClient>>,
  isMutableTable?: boolean,
  validFromColumnHeader?: string
) => {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();
  const { createColumns } = useCreateColumns<PermissionCopySnapshotClient>({
    pathToTranslate: 'permission:field',
    isMutableTable
  });

  const mappedSortFields: TableSortMapper<PermissionCopySnapshotClient> = {
    permissionCopyNumber: 'number',
    formNumber: 'form.number',
    signerName: 'signer.name',
    subjectName: 'folder.subject.name',
    status: 'statusKey',
    cancellationReason: 'cancellationReasonKey',
    certificateNumber: 'number',
    transitType: 'transitTypeKey',
    subjectAddress: 'application.subject.mainAddress.shortAddress',
    dateOfIssueExternal: 'dateOfIssue',
    printOfDate: 'printDate',
    printDate: 'validFrom'
  };

  const mappedFilterFields: TableFilterMapper<PermissionCopySnapshotClient, PermissionCopySearchFilter> = {
    applicationNumber: (applicationNumber: string) => ({
      applicationNumberContains: applicationNumber
    }),
    permissionNumber: (permissionNumber: string) => ({ permissionNumberOrCopyNumberContains: permissionNumber }),
    permissionCopyNumber: (permissionCopyNumber: string) => ({
      permissionNumberOrCopyNumberContains: permissionCopyNumber
    }),
    formNumber: (formNumber: string) => ({ formNumberContains: formNumber }),
    subjectName: (subjectName: string) => ({ subjectNameContains: subjectName }),
    certificateNumber: (certificateNumber: string) => ({ numberContains: certificateNumber }),
    dateOfIssue: ({ dateFrom, dateTo }: DateRangeISO) => ({
      dateOfIssueGreaterThanOrEqual: getCalendarDate(dateFrom),
      dateOfIssueLessThanOrEqual: getCalendarDate(dateTo)
    }),
    validFrom: ({ dateFrom, dateTo }: DateRangeISO) => ({
      validFromGreaterThanOrEqual: getCalendarDate(dateFrom),
      validFromLessThanOrEqual: getCalendarDate(dateTo)
    }),
    validTo: ({ dateFrom, dateTo }: DateRangeISO) => ({
      validToGreaterThanOrEqual: getCalendarDate(dateFrom),
      validToLessThanOrEqual: getCalendarDate(dateTo)
    }),
    cancellationReason: (cancellationReasons: SelectOption<string>[]) => ({
      cancellationReasonKeyIn: cancellationReasons.map(cancellationReason => cancellationReason.value)
    }),
    cancelledBy: (cancelledBy: string) => ({ cancelledByNameContains: cancelledBy }),
    cancellationDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      cancellationDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      cancellationDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    signerName: (signers: SelectOption<string>[]) => ({ signerIdIn: signers.map(signer => signer.value) }),
    status: (statuses: SelectOption<string> | SelectOption<string>[]) => ({
      statusKeyIn: Array.isArray(statuses) ? statuses.map(status => status.value) : [statuses.value]
    }),
    transitType: (transitTypes: SelectOption<string>[]) => ({
      typeKeyIn: transitTypes.map(transitType => transitType.value)
    }),
    subjectAddress: (subjectAddress: string) => ({ subjectAddressContains: subjectAddress }),
    number: (number: string) => ({ numberContains: number }),
    printDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      validFromGreaterThanOrEqual: getCalendarDate(dateFrom),
      validFromLessThanOrEqual: getCalendarDate(dateTo)
    })
  };

  const defaultColumns: ISingleColumn<PermissionCopySnapshotClient>[] = useMemo(
    () => [
      {
        id: 'licenseNumber',
        header: 'number',
        accessor: 'licenseNumber',
        type: ColumnTypesEnum.TEXT
      },
      {
        id: 'certificateNumber',
        type: ColumnTypesEnum.TEXT,
        ...columnDefaultProperties
      },
      {
        id: 'applicationNumber',
        type: ColumnTypesEnum.TEXT,
        ...columnDefaultProperties
      },
      {
        id: 'cancellationReason',
        type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
        dictionaryName: DomainDictionaryEnum.PERMISSION_CANCELLATION_REASON,
        ...columnDefaultProperties,
        customXlsxAccessor(row) {
          return translate(DomainDictionaryEnum.PERMISSION_CANCELLATION_REASON, row.cancellationReason, t('emptyMark'));
        },
        ...columnDefaultProperties
      },
      {
        id: 'cancelledBy',
        type: ColumnTypesEnum.TEXT,
        ...columnDefaultProperties
      },
      {
        id: 'cancellationDate',
        type: ColumnTypesEnum.DATE,
        dateFormatFn: convertDateToDateFormat,
        ...columnDefaultProperties
      },
      {
        id: 'subjectName',
        type: ColumnTypesEnum.TEXT,
        ...columnDefaultProperties
      },
      {
        id: 'subjectAddress',
        type: ColumnTypesEnum.TEXT,
        ...columnDefaultProperties
      },
      {
        id: 'permissionNumber',
        type: ColumnTypesEnum.TEXT,
        ...columnDefaultProperties
      },
      {
        id: 'number',
        header: 'permissionCopyNumber',
        type: ColumnTypesEnum.TEXT,
        ...columnDefaultProperties
      },
      {
        id: 'permissionCopyNumber',
        type: ColumnTypesEnum.TEXT,
        ...columnDefaultProperties
      },
      {
        id: 'formNumber',
        type: ColumnTypesEnum.TEXT,
        ...columnDefaultProperties
      },

      {
        id: 'printOfDate',
        accessor: 'printDate',
        type: ColumnTypesEnum.DATE,
        dateFormatFn: convertDateToDateFormat
      },
      {
        id: 'dateOfIssue',
        type: ColumnTypesEnum.DATE,
        dateFormatFn: convertDateToDateFormat,
        ...columnDefaultProperties
      },
      {
        id: 'dateOfIssueExternal',
        type: ColumnTypesEnum.DATE,
        customAccessor: row => (row.dateOfIssueExternal ? row.dateOfIssueExternal : row.dateOfIssue),
        dateFormatFn: convertDateToDateFormat,
        ...columnDefaultProperties
      },
      {
        id: 'validFrom',
        header: validFromColumnHeader || 'validFrom',
        type: ColumnTypesEnum.DATE,
        dateFormatFn: convertDateToDateFormat,
        ...columnDefaultProperties
      },
      {
        id: 'validTo',
        type: ColumnTypesEnum.DATE,
        dateFormatFn: convertDateToDateFormat,
        ...columnDefaultProperties
      },
      {
        id: 'signerName',
        type: ColumnTypesEnum.MULTI_SELECT,
        filter: UserFilterV2
      },
      {
        id: 'status',
        type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
        dictionaryName: DomainDictionaryEnum.PERMISSION_STATUS,
        customXlsxAccessor(row) {
          return translate(DomainDictionaryEnum.PERMISSION_STATUS, row.status, t('emptyMark'));
        },
        ...columnDefaultProperties
      },
      {
        id: 'transitType',
        type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
        dictionaryName: DomainDictionaryEnum.TRANSIT_TYPE,
        customXlsxAccessor: row => translate(DomainDictionaryEnum.TRANSIT_TYPE, row.transitType, t('emptyMark'))
      },
      {
        id: 'printDate',
        accessor: 'validFrom',
        type: ColumnTypesEnum.DATE,
        dateFormatFn: convertDateToDateFormat
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, translate]
  );

  const mappedColumns = useMemo(
    () =>
      defaultColumns.map(column => {
        const columnIdentifier = (column.id || column.accessor) as CamelCasePath<PermissionCopySnapshotClient>;
        return { ...column, isEditable: editableColumns?.includes(columnIdentifier) };
      }),
    [defaultColumns, editableColumns]
  );

  const columns = createColumns(mappedColumns, visibleColumns);

  return { columns, mappedFilterFields, mappedSortFields };
};

export const PERMISSION_COPIES_DEFAULT_COLUMNS: Array<CamelCasePath<PermissionCopySnapshotClient>> = [
  PermissionCopiesColumnsEnum.PERMISSION_NUMBER,
  PermissionCopiesColumnsEnum.PERMISSION_COPY_NUMBER,
  PermissionCopiesColumnsEnum.FORM_NUMBER,
  PermissionCopiesColumnsEnum.DATE_OF_ISSUE,
  PermissionCopiesColumnsEnum.VALID_FROM,
  PermissionCopiesColumnsEnum.VALID_TO,
  PermissionCopiesColumnsEnum.SIGNER_NAME,
  PermissionCopiesColumnsEnum.STATUS
];

export default usePermissionCopiesColumn;
