import { AccessTokenDetailsExternal } from '@avispon/auth/dist/models';
import SockJS from 'sockjs-client';
import Stomp, { Client } from 'stompjs';

import { AuthStorageService } from '@libs/auth';

export enum Channels {
  NOTIFICATION = 'NOTIFICATION',
  NOTIFICATIONS_CHANGED = 'NOTIFICATIONS_CHANGED',
  PONG = 'PONG',
  PRINTOUT_NOTIFICATION = 'PRINTOUT_NOTIFICATION'
}

class WebSocketService {
  private socketURL: string = null;

  private destination: string = null;

  private socket: Client = null;

  private channels = {};

  isConnected = false;

  init = (socketURL: string, destination: string) => {
    this.socketURL = socketURL;
    this.destination = destination;

    this.socket = Stomp.over(new SockJS(this.socketURL)) as never;
    this.socket.debug = msg => {
      // eslint-disable-next-line no-console
      console.debug(msg);
    };
    window['socket'] = this.socket as Window & typeof globalThis;

    this.connect();
  };

  connect = () => {
    const authTokenPayload: AccessTokenDetailsExternal = AuthStorageService.getAuthTokenPayload();

    if (this.socket) {
      this.socket.connect(
        { token: authTokenPayload.access_token },
        () => {
          this.isConnected = true;

          this.socket.subscribe(this.destination, message => {
            const response = JSON.parse(message.body) as JSON;

            if (this.channels[JSON.parse(message.body).type]) {
              this.channels[JSON.parse(message.body).type].map(item => item(response));
            }
          });
        },
        error => {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      );
    }
  };

  subscribe = (channel: Channels, messageHandler) => {
    if (this.channels[channel]) {
      this.channels[channel].push(messageHandler);
    } else {
      this.channels[channel] = [messageHandler];
    }
  };

  unsubscribe = (channel: Channels, messageHandler) => {
    if (this.channels[channel]) {
      this.channels[channel] = this.channels[channel].filter(fn => fn !== messageHandler) as Channels;
    }
  };

  disconnect = () => {
    if (this.isConnected) {
      this.socket.disconnect(() => false);
    }

    this.isConnected = false;
    this.channels = {};
  };
}

const instance = new WebSocketService();

export default instance;
