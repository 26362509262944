import { useTranslation } from 'react-i18next';
import { PullNotificationSnapshot } from '@avispon/message';

import { useDownloadDocumentFile } from '@libs/file';

import { DownloadFileButton } from '../components/common/DownloadFileButton';

export const useDownloadFileFromNotification = ({ links }: { links: PullNotificationSnapshot['links'] }) => {
  const [t] = useTranslation();
  const { downloadFile, isFetching } = useDownloadDocumentFile({
    successMessage: t('alerts:message.downloadSuccess')
  });
  const file = links[0];
  const hasFileToDownload = file && file.resource === 'META_ENTITY' && file.resourceId;
  return {
    isFetching,
    hasFileToDownload,
    downloadFileButton: (
      <DownloadFileButton
        onClick={() => {
          if (!hasFileToDownload) return;
          downloadFile({ id: file.resourceId });
        }}
      />
    )
  };
};
