import {
  CivicBoardRuleItemFolderLimitConfigAllOf,
  CivicBoardRuleItemLimitConfigAllOf,
  CivicBoardRuleItemLimitedByVehiclesUsageConfig,
  CivicBoardRuleItemNotUsedConfigAllOf,
  CivicBoardRuleItemPermissionForVehicleConfigAllOf,
  RuleItemCodes
} from '@ibtm/domain';

import { typedNameV2 } from '@libs/common/v2';

export const DEFAULT_SOCIAL_COMMISSION_RULES_SETLED_FIELDS = {
  [RuleItemCodes.NOT_USED_PERMISSION_RULE]: {
    [typedNameV2<CivicBoardRuleItemNotUsedConfigAllOf>('decreaseAmount')]: 0
  },
  [RuleItemCodes.PERMISSION_FOR_VEHICLE_RULE]: {
    [typedNameV2<CivicBoardRuleItemPermissionForVehicleConfigAllOf>('from')]: 0,
    [typedNameV2<CivicBoardRuleItemPermissionForVehicleConfigAllOf>('to')]: 0,
    [typedNameV2<CivicBoardRuleItemPermissionForVehicleConfigAllOf>('permissionAmount')]: 0,
    [typedNameV2<CivicBoardRuleItemPermissionForVehicleConfigAllOf>('vehicleAmount')]: 0
  },
  [RuleItemCodes.MONTHLY_PROPORTIONAL_USAGE_RULE]: {},
  [RuleItemCodes.LIMITED_VALUE_RULE]: {
    [typedNameV2<CivicBoardRuleItemLimitConfigAllOf>('maxLimit')]: 0
  },
  [RuleItemCodes.FOLDER_LIMIT_RULE]: {
    [typedNameV2<CivicBoardRuleItemFolderLimitConfigAllOf>('limitFactor')]: 0,
    [typedNameV2<CivicBoardRuleItemFolderLimitConfigAllOf>('multiplier')]: 0
  },
  [RuleItemCodes.LIMITED_VALUE_BY_VEHICLES_RULE]: {
    [typedNameV2<CivicBoardRuleItemLimitedByVehiclesUsageConfig>('amount')]: 0
  }
};
