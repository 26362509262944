import { useContext } from 'react';
import { usePaginatedQuery } from 'react-query';
import { FolderLimitClientSearchFilter, FolderLimitDetails as FolderLimitDetailsClient } from '@ibtm/client-domain';
import { FolderLimitDetails, FolderLimitSearchFilter } from '@ibtm/domain';

import { IPaginatedModel, YearFilterContext } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { DomainDictionaryEntry } from '@libs/domain/config';

import { endpointsConfig } from '../endpoints-config';
import { FolderQueryKeysEnum } from '..';

const getFolderLimits = async (_, params: FolderLimitSearchFilter & { maxYear: number }) => {
  const { maxYear, ...rest } = params;
  const { data } = await API.folderLimits.getFolderLimitsPage(
    {
      ...rest,
      typeKeyIn: [DomainDictionaryEntry.FOREIGN_PERMIT_TYPE.EKMT],
      ...(maxYear && { yearEquals: maxYear })
    },
    { ...endpointsConfig.getFolderLimitsPage }
  );
  return data;
};

const getFolderLimitsClient = async (_, params: FolderLimitClientSearchFilter) => {
  const { data } = await API.client.folderLimits.getFolderLimitsPage('', params, {
    ...endpointsConfig.getFolderLimitsPage
  });

  return data;
};

export default function useEkmtLimitsQuery(params: FolderLimitSearchFilter | FolderLimitClientSearchFilter) {
  const context = useContext(YearFilterContext);
  const { getQuery } = useGetApiQueryByPerspective();
  return usePaginatedQuery<IPaginatedModel<FolderLimitDetails | FolderLimitDetailsClient>>(
    [FolderQueryKeysEnum.FOLDER_LIMIT_LIST, { ...params, maxYear: context?.maxYear }],
    getQuery(getFolderLimitsClient, getFolderLimits)
  );
}
