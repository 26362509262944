import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@enigma/fe-ui';
import { HeaderActions } from '@enigma/fe-ui/lib/display/table';
import { PermissionTypeCode, SCertificatesSearchFilter } from '@ibtm/domain';

import { useElementVisibility } from '@libs/permission';

import { API } from '@libs/domain/api';

import { DomainUIElementEnum } from '../../config';
import { usePrintActionHandler } from '../../print/hooks';
import { endpointsConfig, SCertificatesKeysEnum } from '../api';
import { useSCertificateEditDialog, useSCertificatesColumnsV2 } from '../hooks';
import { PrintCertificatesColumnsEnum, SCertificatesSnapshot } from '../model';
import { parseSCertificateList } from '../utils';

interface IProps {
  initialParams?: SCertificatesSearchFilter;
  headerActions?: HeaderActions<SCertificatesSnapshot>;
  isFilterEnabled?: boolean;
  visibleColumns?: Array<PrintCertificatesColumnsEnum>;
  permissionTypeCode?: PermissionTypeCode;
  isPrintDisabled?: boolean;
}

export function SCertificatesTableV2({
  initialParams,
  headerActions,
  isFilterEnabled,
  visibleColumns,
  permissionTypeCode,
  isPrintDisabled
}: IProps) {
  const [t] = useTranslation();
  const { columns, mappedFilterFields, mappedSortFields } = useSCertificatesColumnsV2({ visibleColumns });
  const { openEditSCertificateDialog } = useSCertificateEditDialog();
  const { checkIsElementVisible } = useElementVisibility();

  const { print } = usePrintActionHandler();

  const editSCertificate = useCallback(
    ({ rowData, refetch }: { rowData: SCertificatesSnapshot; refetch?: () => void }) => {
      openEditSCertificateDialog(
        {
          id: rowData.id,
          validFrom: rowData.validFrom as unknown as string,
          version: rowData.version
        },
        refetch
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Table<SCertificatesSnapshot, SCertificatesSearchFilter>
      columns={columns}
      tableTitle={t('sCertificates:list.title')}
      query={{
        request: async params => {
          const { data } = await API.sCertificates.getSCertificatesSnapshotPage(
            {
              ...params,
              ...initialParams
            },
            {
              ...endpointsConfig.getPermissionCopiesPage
            }
          );

          const response = {
            data: {
              ...data,
              content: parseSCertificateList(data.content)
            }
          };
          return response;
        },

        queryKey: SCertificatesKeysEnum.CERTIFICATES_S_LIST,
        filterParamsConverter: mappedFilterFields,
        sortParamConverter: mappedSortFields,
        initParams: initialParams
      }}
      xlsxDownload={{
        fileName: t('sCertificates:list.title'),
        apiRequest: async params => {
          const { data } = await API.sCertificates.getSCertificatesSnapshotPage(
            {
              ...params,
              ...initialParams
            },
            {
              ...endpointsConfig.getPermissionCopiesPage
            }
          );

          const response = {
            data: {
              ...data,
              content: parseSCertificateList(data.content)
            }
          };
          return response;
        },
        filterParamsConverter: mappedFilterFields,
        sortParamConverter: mappedSortFields
      }}
      headerActions={headerActions}
      selectedRowActions={[
        {
          title: t('action.print'),
          onClick: ({ selectedRows, refetch, unselectAllRows, setIsLoading }) => {
            setIsLoading(true);
            print(
              {
                permissionTypeCode,
                permissionIds: selectedRows.map(row => row.id)
              },
              {
                onSuccess: () => {
                  unselectAllRows();
                  refetch();
                },
                onSettled: () => {
                  setIsLoading(false);
                }
              }
            );
          }
        }
      ]}
      rowActions={[
        {
          label: t('action.print'),
          tooltip: isPrintDisabled ? t('prints:messages.printActionDisabled') : t('action.print'),
          isDisabled: isPrintDisabled,
          onClick: ({ rowData, setLoading, refetch }) => {
            setLoading(true);
            print(
              { permissionTypeCode, permissionIds: [rowData.id] },
              {
                onSettled: () => {
                  setLoading(false);
                  refetch();
                }
              }
            );
          },
          icon: 'PrintIcon'
        },
        {
          label: t('action.edit'),
          onClick: editSCertificate,
          icon: 'EditIcon',
          isHidden: !checkIsElementVisible(DomainUIElementEnum.PRINT_DATA_UPDATE_BUTTON)
        }
      ]}
      emptyTableText={t('sCertificates:messages.noSCertificates')}
      isFilterable={isFilterEnabled}
    />
  );
}
