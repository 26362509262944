import { useTranslation } from 'react-i18next';
import { UserTaskDetails } from '@ibtm/process-api';
import { ListItem, ListItemText, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useDevMode } from '@libs/common/v2';
import { important } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';
import { useElementVisibility } from '@libs/permission';

import {
  DomainDictionaryEnum,
  getApplicationProcessMainActionNameByKey,
  getApplicationProcessTransitionNameByKey
} from '@libs/domain/config';

import { CANCEL_APPLICATION_PROCESS } from '../model/process';

interface IProps {
  task: UserTaskDetails;
  onTaskConfirmClick: () => void;
  onSendActionClick: (messageKey: string) => void;
  isCancelDisabled?: boolean;
}

const TooltipForDisabledListItem = ({
  messageKey,
  isCancelDisabled,
  children
}: {
  messageKey: string;
  isCancelDisabled?: boolean;
  children: React.ReactElement;
}) => {
  const [t] = useTranslation();

  const isMessageCancel = messageKey === CANCEL_APPLICATION_PROCESS;
  return isMessageCancel && isCancelDisabled ? (
    <Tooltip title={t('processes:message.cancelApplicationDisabled')}>
      <div>{children}</div>
    </Tooltip>
  ) : (
    children
  );
};

function ProcessExecuteTaskList({ task, onTaskConfirmClick, onSendActionClick, isCancelDisabled }: IProps) {
  const [t] = useTranslation();

  const { checkIsElementVisible } = useElementVisibility();

  const isElementVisible = (taskKey: string) => {
    const mainTaskKey = getApplicationProcessMainActionNameByKey(taskKey);
    const transitionKey = getApplicationProcessTransitionNameByKey(taskKey);
    const keysNotDefined = !mainTaskKey && !transitionKey;
    return (
      keysNotDefined ||
      (mainTaskKey && checkIsElementVisible(mainTaskKey)) ||
      (transitionKey && checkIsElementVisible(transitionKey))
    );
  };

  const { translate, checkTranslationAvailable } = useDictionaryTranslations();
  const { isDevMode } = useDevMode();

  const classes = useStyles();

  return (
    <>
      {task?.messageKeys?.length
        ? task.messageKeys.map(messageKey =>
            checkTranslationAvailable(DomainDictionaryEnum.PROCESS_MESSAGE_LABEL, messageKey) || isDevMode
              ? isElementVisible(messageKey) && (
                  <TooltipForDisabledListItem
                    messageKey={messageKey}
                    key={`${messageKey}-tooltipcomponent`}
                    isCancelDisabled={isCancelDisabled}
                  >
                    <ListItem
                      className={classes.item}
                      button
                      onClick={() => {
                        onSendActionClick(messageKey);
                      }}
                      dense
                      key={messageKey}
                      disabled={messageKey === CANCEL_APPLICATION_PROCESS && isCancelDisabled}
                    >
                      <ListItemText
                        primaryTypographyProps={{ className: classes.primaryTypography }}
                        primary={translate(DomainDictionaryEnum.PROCESS_MESSAGE_LABEL, messageKey, messageKey)}
                      />
                    </ListItem>
                  </TooltipForDisabledListItem>
                )
              : null
          )
        : null}
      {task?.submitAvailable && isElementVisible(task.taskKey) ? (
        <ListItem dense className={classes.item} button onClick={onTaskConfirmClick}>
          <ListItemText
            primaryTypographyProps={{ className: classes.primaryTypography }}
            primary={translate(
              DomainDictionaryEnum.PROCESS_TASK_MAIN_ACTION_NAME,
              task?.taskKey,
              t('processes:task.confirm')
            )}
          />
        </ListItem>
      ) : null}
    </>
  );
}

const useStyles = makeStyles({
  item: {
    padding: '4px 16px',
    boxShadow: '0px 1px 2px rgb(16 24 40 / 5%)',
    '&:hover': {
      borderColor: 'rgb(208, 213, 221)',
      backgroundColor: 'rgb(208, 213, 221)'
    }
  },
  primaryTypography: {
    fontWeight: important(500)
  }
});

export default ProcessExecuteTaskList;
