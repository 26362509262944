import { MisspellApplicationDataPermissionDetails, MisspellPermissionDetails } from '@ibtm/domain';
import { get } from 'lodash';

import { ColumnTypesEnum, ISingleColumn, useCreateColumns } from '@libs/common/v2';
import { convertCalendarDate, getValue } from '@libs/common/v2/utils';

export type AccesorType = keyof Omit<MisspellApplicationDataPermissionDetails, 'reprint'>;

const getByCustomAccessor = (
  row: MisspellApplicationDataPermissionDetails,
  accessor: AccesorType = 'permissionUpdated'
): MisspellPermissionDetails & { reprint: boolean } => ({
  ...get(row, accessor, row.permissionUpdated),
  reprint: row.reprint
});

export type ApplicationMisspellData = ReturnType<typeof getByCustomAccessor>;

function useMisspellApplicationPermissionColumn(accessor?: AccesorType, numberColumnHeader?: string) {
  const { createColumns } = useCreateColumns<ApplicationMisspellData>({
    pathToTranslate: 'applications:misspellApplication.fields'
  });

  const customAccessor = row => getByCustomAccessor(row, accessor);

  const columns: ISingleColumn<ApplicationMisspellData>[] = [
    {
      id: 'reprint',
      type: ColumnTypesEnum.BOOLEAN
    },
    {
      id: 'applicationNumber',
      customAccessor: row =>
        getValue(customAccessor(row as MisspellApplicationDataPermissionDetails).applicationNumber),
      type: ColumnTypesEnum.TEXT
    },
    {
      id: 'number',
      header: numberColumnHeader,
      customAccessor: row => getValue(customAccessor(row as MisspellApplicationDataPermissionDetails).number),
      type: ColumnTypesEnum.TEXT
    },
    {
      id: 'formNumber',
      customAccessor: row => getValue(customAccessor(row as MisspellApplicationDataPermissionDetails).formNumber),
      type: ColumnTypesEnum.TEXT
    },
    {
      id: 'dateOfIssue',
      type: ColumnTypesEnum.DATE,
      customAccessor: row =>
        getValue(convertCalendarDate(customAccessor(row as MisspellApplicationDataPermissionDetails).dateOfIssue))
    },
    {
      id: 'validFrom',
      customAccessor: row =>
        getValue(convertCalendarDate(customAccessor(row as MisspellApplicationDataPermissionDetails).validFrom)),
      type: ColumnTypesEnum.DATE
    },
    {
      id: 'validTo',
      customAccessor: row =>
        getValue(convertCalendarDate(customAccessor(row as MisspellApplicationDataPermissionDetails).validTo)),
      type: ColumnTypesEnum.DATE
    },
    {
      id: 'name',
      customAccessor: row => getValue(customAccessor(row as MisspellApplicationDataPermissionDetails).name),
      type: ColumnTypesEnum.TEXT
    },
    {
      id: 'city',
      header: 'address',
      customAccessor: row =>
        `${getValue(customAccessor(row as MisspellApplicationDataPermissionDetails)?.city)} ${getValue(
          customAccessor(row as MisspellApplicationDataPermissionDetails)?.postCode
        )}, ${getValue(customAccessor(row as MisspellApplicationDataPermissionDetails)?.street)} ${getValue(
          customAccessor(row as MisspellApplicationDataPermissionDetails)?.propertyNumber
        )} ${getValue(customAccessor(row as MisspellApplicationDataPermissionDetails)?.apartmentNumber)}`,
      type: ColumnTypesEnum.TEXT,
      width: 300
    },
    {
      id: 'nip',
      customAccessor: row => getValue(customAccessor(row as MisspellApplicationDataPermissionDetails).nip),
      type: ColumnTypesEnum.TEXT
    }
  ];

  return createColumns(columns);
}

export default useMisspellApplicationPermissionColumn;
