import { useTranslation } from 'react-i18next';
import { ProceedingKreptdSearchFilter } from '@ibtm/domain';

import { CamelCasePath, TableLayout, useRouter, useTableRowActions } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';
import { DomainUIElementEnum } from '@libs/domain/config';

import { RoadTransportQueryKeysEnum } from '../../../api';
import { useProceedingsTable, useProceedingStatusChangeDialog } from '../../../hooks';
import { IProceedingClient } from '../../../models';
import { parseProceedingsListResults } from '../../../utils';

interface IProps {
  title: string;
  isSection?: boolean;
  isCollapsable: boolean;
  isSectionFullTab?: boolean;
  visibleColumns: Array<CamelCasePath<IProceedingClient>>;
  enabledActions: string[];
  isRowSelectionEnabled?: boolean;
  isModificationDisabled?: boolean;
  initialParams?: ProceedingKreptdSearchFilter;
}

function ProceedingsKreptdTable({
  title,
  isSection,
  isCollapsable,
  isSectionFullTab,
  visibleColumns,
  enabledActions,
  isRowSelectionEnabled,
  isModificationDisabled,
  initialParams
}: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();

  const { routes } = useRouter();
  const tableProps = useProceedingsTable(visibleColumns, isRowSelectionEnabled, initialParams);
  const { openProceedingStatusChangeDialog } = useProceedingStatusChangeDialog();

  const { renderTableActions } = useTableRowActions<IProceedingClient>([
    {
      id: DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_BUTTON,
      label: 'roadTransport:actions.goToProceeding',
      link: ({ original }) =>
        isModificationDisabled
          ? { pathname: routes.roadTransportDetailsView(original.id), state: { isFromKreptdProceedings: true } }
          : {
              pathname: routes.roadTransportDetails(original.id),
              state: { isFromKreptdProceedings: true }
            },
      icon: 'ArrowIcon',
      isHidden: ({ row: { original } }) => !original?.caseNumber
    },
    {
      id: DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_CHANGE_STATUS_BUTTON,
      label: 'roadTransport:actions.changeStatus',
      onClick: ({ row: { original } }) =>
        openProceedingStatusChangeDialog(original, {
          onSuccess: () => {
            queryCache.invalidateQueries(RoadTransportQueryKeysEnum.PROCEEDINGS_LIST);
          }
        }),
      icon: 'ChangeStatusIcon',
      isHidden: ({ row: { original } }) => !original?.caseNumber || !original.statusTransitions?.length
    }
  ]);

  return (
    <TableLayout
      {...tableProps}
      pageTitle={title}
      xlsxDownload={{
        fileName: t('roadTransport:list.proceedings'),
        pathToXLSXTranslation: 'resource:fields',
        apiRequest: params =>
          API.proceedingsKreptd
            .getProceedingsKreptdSnapshotPage(params)
            .then(res => ({ ...res, data: { ...res.data, content: parseProceedingsListResults(res.data.content) } }))
      }}
      rowActions={renderTableActions(enabledActions)}
      isCollapsable={isCollapsable}
      actionsColumnWidth={120}
      isSection={isSection}
      isSectionFullTab={isSectionFullTab}
    />
  );
}

export default ProceedingsKreptdTable;
