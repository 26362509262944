import { NoteCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function createSuspensionNote({ suspensionId, request }: { suspensionId: string; request: NoteCreateRequest }) {
  return API.suspensionNote.createSuspensionNote(suspensionId, request, {
    ...endpointsConfig.createSuspensionNote
  });
}

function useCreateSuspensionNoteMutation() {
  return useMutation(createSuspensionNote);
}

export default useCreateSuspensionNoteMutation;
