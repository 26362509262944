import { useTableLayoutProps } from '@libs/common/v2';

import useReplacementsQuery from '@libs/user/api/queries/useReplacementsQuery';

import { useReplacementsColumns } from './columns';

function useReplacementsTable() {
  const { columns, mappedFilterFields, mappedSortFields } = useReplacementsColumns();

  return useTableLayoutProps({
    tableHookQuery: useReplacementsQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'modified', desc: true }]
        }
      }
    }
  });
}

export default useReplacementsTable;
