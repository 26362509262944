import { InvoiceItemDetails } from '@ibtm/domain';

import { ICorrectionItems } from '../../../model';
import { getAllValidNumbersItem, getRoundedValue } from '../../../utils';
import { calculateSumDiffrence } from '../utils/calculation';

export function useViewEditCorrectionCalculation(isSuspension?: boolean) {
  /**
   *  Funkcja do przeliczania pozycji korekty na widokach VIEW I EDIT
   */
  const calculateItemsDetails = (items: InvoiceItemDetails[]): ICorrectionItems[] => {
    return items.map(item => {
      const { id: rowId, name, ordinal, settlementTypeKey, correctedItem } = item;
      const { quantity, unitCost } = getAllValidNumbersItem(item);

      const { quantity: correctedQuantity, unitCost: correctedUnitCost } = getAllValidNumbersItem(correctedItem);

      return {
        id: rowId,
        name,
        quantity: isSuspension ? quantity : correctedQuantity,
        correctedQuantity: quantity,
        unitCost: correctedUnitCost,
        correctedUnitCost: unitCost,
        ordinal,
        settlementTypeKey,
        sumDiffrence: isSuspension
          ? getRoundedValue(unitCost - correctedUnitCost)
          : calculateSumDiffrence({
              quantity: correctedQuantity,
              unitCost: correctedUnitCost,
              correctedUnitCost: unitCost,
              correctedQuantity: quantity
            }),
        newSum: getRoundedValue(quantity * unitCost),
        sumOfCorrection: -getRoundedValue((correctedUnitCost - unitCost) * quantity)
      };
    });
  };

  return {
    calculateItemsDetails
  };
}
