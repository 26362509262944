import { useEffect, useMemo } from 'react';
import { AttachmentVerificationElementDetails, AttachmentVerificationListDetailsAllOf } from '@ibtm/domain';

import { useFormV2Context } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { useAttachmentsQuery } from '../api';
import { MAIN_COMMENT_FIELD_NAME, VertificationElements } from '../model/common.model';

const AttachmentsWrapper = ({
  applicationId,
  fieldId,
  isOnlyBooleanColumn,
  children,
  isSetMainComment
}: {
  applicationId: string;
  fieldId: string;
  isOnlyBooleanColumn?: boolean;
  isSetMainComment?: boolean;
  children: (value: {
    verificationElements: AttachmentVerificationElementDetails[];
    notes: AttachmentVerificationListDetailsAllOf['notes'];
    isLoading: boolean;
  }) => JSX.Element;
}) => {
  const { setValue } = useFormV2Context();
  const { data, isLoading } = useAttachmentsQuery({
    applicationId
  });

  const verificationElements = useMemo(() => {
    return data?.verificationElements || ([] as AttachmentVerificationListDetailsAllOf['verificationElements']);
  }, [data]);
  const notes = data?.notes;
  const version = data?.version || null;

  const defaultData: VertificationElements = useMemo(() => {
    const defaultVerificationElements = verificationElements?.reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: {
          statusKey: isOnlyBooleanColumn
            ? item.statusKey === DomainDictionaryEntry.REQUIRED_FILE_STATUS.ATTACHED
            : item.statusKey,
          notes: item.notes,
          name: item.name
        }
      }),
      {}
    );
    return { [fieldId]: { ...defaultVerificationElements } };
  }, [fieldId, isOnlyBooleanColumn, verificationElements]);

  // Dane z backend wstawione do form
  useEffect(() => {
    if (verificationElements?.length) {
      Object.entries(defaultData).forEach(([key, value]) => {
        setValue(key, value);
      });
      setValue('version', version);
    }
    if (isSetMainComment) {
      setValue(MAIN_COMMENT_FIELD_NAME, notes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultData, version]);

  const value = useMemo(() => {
    return {
      verificationElements,
      notes,
      isLoading
    };
  }, [verificationElements, notes, isLoading]);
  return children(value);
};

export default AttachmentsWrapper;
