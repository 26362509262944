import { useMemo } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';

import { i18n, KeyType } from '@libs/common';
import { AutocompleteSelectField, SelectOption } from '@libs/common/v2';

import { getDateOption } from '@libs/domain/social-commission/utils/civic-board.utils';

interface DropDownI {
  name?: FieldPath<FieldValues>;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string;
}

function DropdownDateInput({ name, label, isRequired, isDisabled }: DropDownI) {
  const dateOptions = useMemo(() => ({ year: getDateOption(true), month: getDateOption(false) }), []);

  return (
    <AutocompleteSelectField
      label={i18n.t(label as KeyType)}
      name={name}
      options={dateOptions[name] as SelectOption<string>[]}
      isDisabled={isDisabled}
      isRequired={isRequired}
    />
  );
}
export default DropdownDateInput;
