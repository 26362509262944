import { useTranslation } from 'react-i18next';
import { PullNotificationSnapshot } from '@avispon/message';

import { TableLayout } from '@libs/common/v2';

import { API } from '@libs/notification/api';
import { MyMessagesTableHeaderActions, MyMessagesTableRowActions } from '@libs/notification/components';
import { useMyMessagesTable, useNotificationConfig } from '@libs/notification/hooks';

function MyMessagesTable() {
  const [t] = useTranslation();
  const { useCreateButton } = useNotificationConfig();
  const tableProps = useMyMessagesTable();

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('messages:title')}
      xlsxDownload={{
        fileName: t('messages:listTitle'),
        pathToXLSXTranslation: 'messages:table',
        apiRequest: params =>
          API.message.getPaginatedNotifications({ ...params, sort: ['createdAt,DESC'], body: { deleted: false } })
      }}
      headerActions={<MyMessagesTableHeaderActions isCreateButtonEnabled={useCreateButton} />}
      rowActions={({ original }: { original: PullNotificationSnapshot }) => (
        <MyMessagesTableRowActions notification={original} />
      )}
      isFilterEnabled={false}
    />
  );
}

export default MyMessagesTable;
