import React from 'react';
import { Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Icon, IconType } from '@libs/common/v2';
import { Theme, useTheme } from '@libs/common/v2/theme';

import { important } from '../../utils';

export enum WarningThemeType {
  WARNING = 'WARNING',
  INFORMATIVE = 'INFORMATIVE'
}

interface IProps {
  content?: string;
  children?: React.ReactNode;
  paperClassName?: string;
  gridClassName?: string;
  icon?: IconType;
  colorVersion?: WarningThemeType;
  isIconVisible?: boolean;
  withoutBorder?: boolean;
}

function WarningInformation({
  content,
  children,
  paperClassName,
  gridClassName,
  icon = 'WarningIcon',
  colorVersion = WarningThemeType.WARNING,
  isIconVisible,
  withoutBorder
}: IProps) {
  const isWarning = colorVersion === WarningThemeType.WARNING;
  const { contrast } = useTheme();
  const classes = useStyles({ isWarning, contrast, withoutBorder });
  return (
    <Grid xs={12} className={gridClassName} item>
      <Paper className={clsx(classes.paper, paperClassName)} variant="outlined">
        {isIconVisible && (
          <Icon icon={icon} className="flex" {...{ color: !isWarning || contrast ? 'blue' : 'warning' }} />
        )}
        {content ? <span>{content}</span> : children}
      </Paper>
    </Grid>
  );
}

export const useStyles = makeStyles<Theme, { isWarning: boolean; contrast?: boolean; withoutBorder?: boolean }>(
  theme => ({
    paper: {
      outline: ({ contrast }) => contrast && important('3px solid black'),
      boxShadow: 'none',
      border: ({ isWarning, contrast, withoutBorder }) =>
        withoutBorder
          ? 'unset'
          : `1px solid ${isWarning && !contrast ? theme.palette.warning['500'] : theme.palette.blue['500']}`,
      height: '100%',
      overflow: 'hidden',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      padding: '8px 16px',
      gap: '16px',
      color: ({ contrast }) => contrast && theme.palette.grey[700]
    }
  })
);

export default WarningInformation;
