import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Checkbox, useTableAdapter, useTableContext } from '@libs/common/v2';

interface IProps {
  checkAllAvailable: () => number;
  headerCheckboxTooltipTitle: string;
}

function TableSelectAction({ checkAllAvailable, headerCheckboxTooltipTitle }: IProps) {
  const [t] = useTranslation();
  const table = useTableAdapter();
  const { state, toggleAllRowsSelected, getToggleAllRowsSelectedProps } = table;
  const { checkDisabled, isError, isLoading, isFetching } = useTableContext();
  const [checkAllState, setAllCheckState] = useState(false);
  const [count, setCount] = useState(0);
  const classes = useStyles();

  const selectedRowsIds = Object.keys(state.selectedRowIds);

  const toggleCheck = () => {
    setAllCheckState(prevState => !prevState);
    if (!checkAllState) setCount(checkAllAvailable());
    if (checkAllState) {
      toggleAllRowsSelected(false);
      setCount(0);
    }
  };

  useEffect(() => {
    if (count > selectedRowsIds.length) {
      setCount(0);
      setAllCheckState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowsIds]);

  return (
    <Tooltip disableHoverListener={!headerCheckboxTooltipTitle} title={headerCheckboxTooltipTitle}>
      <Checkbox
        {...getToggleAllRowsSelectedProps()}
        isChecked={checkAllState}
        onChange={toggleCheck}
        className={classes.checkbox}
        isIndeterminate={!checkAllState && selectedRowsIds.length > 0}
        isDisabled={isLoading || isFetching || checkDisabled(table) || isError}
        inputProps={{
          'aria-label': t('other:component.table.body.checkAllRows')
        }}
      />
    </Tooltip>
  );
}

const useStyles = makeStyles({
  checkbox: {
    padding: 10
  }
});

export default TableSelectAction;
