import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button as MuiButton, Tooltip as MuiTooltip, TooltipProps } from '@mui/material';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { ButtonLinkDefinition, Icon, IconType, LoaderCircular } from '@libs/common/v2';
import { PaletteOption, PaletteOptions, Theme, useTheme } from '@libs/common/v2/theme';
import { hexToRgba, important } from '@libs/common/v2/utils';

import { UIElementNameEnum, useElementVisibility } from '@libs/permission';

export interface IButtonProps {
  label?: string | React.ReactNode;
  icon?: IconType;
  tooltipTitle?: string;
  placement?: TooltipProps['placement'];
  className?: string;
  classNameWrapper?: string;
  customTextClass?: string;
  customWrapperClass?: string;
  link?: ButtonLinkDefinition;
  iconClassName?: string;
  iconColor?: keyof PaletteOptions;
  iconColorWeight?: keyof PaletteOption | number;
  iconHeight?: number;
  iconWidth?: number;
  component?: React.ElementType;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | (() => void);
  size?: MuiButtonProps['size'] | 'xl' | 'xxl';
  buttonType?: 'submit' | 'reset' | 'button';
  isPrimary?: boolean;
  isSecondary?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  isNoMargin?: boolean;
  loaderWithText?: boolean;
  actionKey?: UIElementNameEnum;
  target?: string;
}

enum ButtonVariant {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
  ERROR = 'Error'
}

function Button(
  {
    label,
    className,
    icon,
    tooltipTitle,
    placement = 'bottom',
    classNameWrapper,
    customTextClass,
    customWrapperClass,
    component,
    iconClassName,
    iconHeight,
    iconWidth,
    iconColor,
    iconColorWeight,
    buttonType = 'button',
    isNoMargin,
    disabled,
    isLoading,
    loaderWithText,
    size = 'medium',
    isPrimary,
    isSecondary,
    isError,
    variant = 'contained',
    actionKey,
    link,
    fullWidth = false,
    target,
    ...forwardProps
  }: IButtonProps & Omit<MuiButtonProps, 'color' | 'size'>,
  ref
) {
  const { checkIsElementVisible } = useElementVisibility();

  const { contrast } = useTheme();
  const getType = () => {
    if (isPrimary) {
      return ButtonVariant.PRIMARY;
    }
    if (isSecondary) {
      return ButtonVariant.SECONDARY;
    }
    if (isError) {
      return ButtonVariant.ERROR;
    }

    return null;
  };

  const classes = useStyles({ size, type: getType(), variant, disabled, loaderWithText });

  const linkTo = useMemo(() => {
    return typeof link === 'function' ? link() : link;
  }, [link]);

  const isElementVisible = useMemo(() => {
    return actionKey ? checkIsElementVisible(actionKey) : true;
  }, [checkIsElementVisible, actionKey]);

  if (isElementVisible) {
    const ButtonContent = (
      <div className={clsx(customTextClass || classes.text, 'flex items-center justify-center')}>
        {icon && (
          <Icon
            className={clsx(classes.icon, iconClassName)}
            color={iconColor}
            colorWeight={iconColorWeight}
            icon={icon}
            height={iconHeight || (size === 'xxl' ? 24 : 20)}
            width={iconWidth || (size === 'xxl' ? 24 : 20)}
            isDisabled={disabled}
          />
        )}
        {label || ''}
      </div>
    );

    const ButtonWithLoader = (
      <MuiButton
        ref={ref}
        className={clsx(
          classes.button,
          {
            [classes.disabledButton]: disabled,
            [classes.buttonContrast]: contrast,
            'cursor-auto pointer-events-none': isLoading
          },
          fullWidth && classes.fullWidth,
          className
        )}
        disabled={disabled || isLoading}
        component={link ? Link : component}
        type={buttonType}
        {...(linkTo && {
          to:
            typeof linkTo === 'string'
              ? linkTo
              : { pathname: linkTo?.pathname, key: linkTo?.key, search: linkTo?.search, hash: linkTo?.hash },
          ...(typeof linkTo !== 'string' && 'state' in linkTo && { state: linkTo.state })
        })}
        target={target}
        {...forwardProps}
      >
        {isLoading ? (
          <>
            <LoaderCircular isLoading={isLoading} size={24} className={classes.loader} isAbsolute={fullWidth}>
              {ButtonContent}
            </LoaderCircular>
            {loaderWithText && <div>{label}</div>}
          </>
        ) : (
          ButtonContent
        )}
      </MuiButton>
    );

    return (
      <div
        className={clsx(
          customWrapperClass || classes.wrapper,
          classNameWrapper || '',
          isNoMargin && classes.noMargin,
          fullWidth && classes.fullWidth
        )}
      >
        {tooltipTitle ? (
          <MuiTooltip
            disableHoverListener={!tooltipTitle}
            disableFocusListener={!tooltipTitle}
            placement={placement}
            aria-label={tooltipTitle}
            title={tooltipTitle || ''}
          >
            <span>{ButtonWithLoader}</span>
          </MuiTooltip>
        ) : (
          ButtonWithLoader
        )}
      </div>
    );
  }

  return null;
}

const useStyles = makeStyles<
  Theme & { components: Record<string, any> },
  Pick<IButtonProps, 'size' | 'loaderWithText'> & { type: ButtonVariant } & Pick<MuiButtonProps, 'variant' | 'disabled'>
>(theme => ({
  button: ({ size, type, variant, disabled }) => ({
    ...theme.components.MuiButton.styleOverrides[size],
    ...theme.components.MuiButton.styleOverrides[`${variant}${type ?? ButtonVariant.PRIMARY}`],
    whiteSpace: 'nowrap',
    backgroundColor:
      !disabled &&
      (theme.components.MuiButton.styleOverrides[`${variant}${type ?? ButtonVariant.PRIMARY}`]
        .backgroundColor as string),
    '& svg': {
      color: ({ type, variant }) =>
        (type === ButtonVariant.PRIMARY || type === ButtonVariant.ERROR || variant === 'contained') &&
        theme.palette.white
    }
  }),
  buttonContrast: {
    '&:hover': { color: ({ variant }) => variant !== 'outlined' && important(theme.palette.grey[50]) },
    '&:focus': {
      color: ({ variant }) => variant !== 'outlined' && important(theme.palette.grey[50]),
      boxShadow: important('none')
    }
  },
  disabledButton: {
    pointerEvents: 'none',
    boxShadow: 'none',
    color: important(hexToRgba(theme.palette.grey[700], 0.26)),
    backgroundColor: important(hexToRgba(theme.palette.grey[500], 0.12)),
    borderColor: important(hexToRgba(theme.palette.grey[500], 0.12))
  },
  icon: {
    marginRight: 8,
    display: 'flex'
  },
  text: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    display: 'inline-block'
  },
  noMargin: {
    margin: 0
  },
  fullWidth: {
    width: important('100%')
  },
  loader: {
    display: 'flex',
    justiftContent: 'center',
    alignItems: 'center',
    marginRight: ({ loaderWithText }) => loaderWithText && 16,
    '& svg': {
      color: ({ type, variant }) =>
        (type === ButtonVariant.PRIMARY || type === ButtonVariant.ERROR || variant === 'contained') &&
        important(theme.palette.white),
      width: '100%',
      height: '100%'
    }
  }
}));

export default React.forwardRef(Button);
