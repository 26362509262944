import { useCallback, useEffect, useState } from 'react';
import { CurrencyExchangeRateDetails } from '@ibtm/domain';
import moment from 'moment';

import { convertDateToDateFormat, getCalendarDate } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { useCurrencyExchangeRatesQuery } from '../api';
import { CurrencyTypeEnum } from '../model';

interface IProps {
  currencyKey: string;
  exchangeRateFrom: string;
  exchangeRateTo: string;
  amount?: number;
}

function useCurrencyExchange({ currencyKey, exchangeRateFrom, exchangeRateTo, amount }: IProps) {
  const { translate } = useDictionaryTranslations();

  const [currencyExchangeRate, setCurrencyExchangeRate] = useState<CurrencyExchangeRateDetails>(null);
  const [exchangeAmount, setExchangeAmount] = useState<number | null>(null);
  const [exchangeCurrency, setExchangeCurrency] = useState('');

  const { data: currencyRate, isLoading: currencyRatesDataLoading } = useCurrencyExchangeRatesQuery(
    {
      // Pobieramy zawsze kurs EURO
      currencyKeys: [CurrencyTypeEnum.EUR],
      operativeExchangeDayFrom: getCalendarDate(
        convertDateToDateFormat(exchangeRateFrom || moment().startOf('day').toISOString())
      ),
      operativeExchangeDayTo: getCalendarDate(
        convertDateToDateFormat(exchangeRateTo || moment().endOf('day').toISOString())
      )
    },
    { enabled: Boolean(currencyKey) }
  );

  const getExchangeAmount = useCallback(() => {
    if (amount && currencyRate?.content[0]?.exchangeRate) {
      if (currencyKey === CurrencyTypeEnum.PLN) {
        return Math.round((amount / currencyRate.content[0].exchangeRate) * 100) / 100;
      }
      return Math.round(currencyRate.content[0].exchangeRate * amount * 100) / 100;
    }
    return null;
  }, [amount, currencyKey, currencyRate?.content]);

  useEffect(() => {
    if (currencyRate && currencyRate?.content[0]?.exchangeRate) {
      setCurrencyExchangeRate(currencyRate?.content[0]);
      setExchangeAmount(getExchangeAmount());
    }
  }, [currencyRate, amount, getExchangeAmount]);

  useEffect(() => {
    if (currencyKey) {
      setExchangeCurrency(
        translate(
          DomainDictionaryEnum.CURRENCY_CODE,
          currencyKey === CurrencyTypeEnum.EUR ? CurrencyTypeEnum.PLN : CurrencyTypeEnum.EUR,
          ''
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyKey]);

  return { currencyExchangeRate, currencyRatesDataLoading, exchangeAmount, exchangeCurrency };
}

export default useCurrencyExchange;
