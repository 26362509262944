import { useMutation } from 'react-query';
import { DocumentCreateRequest } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

export interface IProceedingKreptdDocumentCreateParams {
  proceedingId: string;
  data: DocumentCreateRequest;
}

function generateDocument({ proceedingId, data }: IProceedingKreptdDocumentCreateParams) {
  return API.proceedingsKreptd.addProceedingKreptdDocument(
    proceedingId,
    {
      documentTemplateId: data.documentTemplateId
    },
    { ...endpointsConfig.addProceedingKreptdDocument }
  );
}

function useDocumentGenerationMutation() {
  return useMutation(generateDocument);
}

export default useDocumentGenerationMutation;
