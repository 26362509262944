import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface IParams {
  transitScheduleId: string;
  stopIds: string[];
}

export function deleteTransitStops({ transitScheduleId, stopIds }: IParams) {
  return API.transitSchedule.deleteTransitScheduleStops(
    transitScheduleId,
    { stopIds },
    {
      ...endpointsConfig.deleteTransitScheduleStops
    }
  );
}

export function deleteTransitStopsClient({ transitScheduleId, stopIds }: IParams) {
  return API.client.transitSchedule.deleteTransitScheduleStops(
    transitScheduleId,
    '',
    '',
    { ids: stopIds },
    {
      ...endpointsConfig.deleteTransitScheduleStops
    }
  );
}

function useDeleteTransitStopsMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(deleteTransitStopsClient, deleteTransitStops));
}

export default useDeleteTransitStopsMutation;
