export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  getPaginatedNotifications: { translationKey: 'notifications:api.getPaginatedNotifications' },
  findSentMessages: { translationKey: 'notifications:api.findSentMessages' },
  findPullNotifications: { translationKey: 'notifications:api.findPullNotifications' },
  deleteNotification: { translationKey: 'notifications:api.deleteNotification' },
  deleteNotifications: { translationKey: 'notifications:api.deleteNotifications' },
  deleteNotificationsByUser: { translationKey: 'notifications:api.deleteNotificationsByUser' },
  create: { translationKey: 'notifications:api.create' },
  findMessage: { translationKey: 'notifications:api.findMessage' },
  findMessagesWithUnreadCount: { translationKey: 'notifications:api.findMessagesWithUnreadCount' },
  getAdminMessageDetails: { translationKey: 'notifications:api.getAdminMessageDetails' }
};
