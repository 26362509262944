import { RenounceCopyAssignUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

function updateMutation({
  applicationId,
  renounceCopyAssignUpdateRequest
}: {
  applicationId: string;
  renounceCopyAssignUpdateRequest: RenounceCopyAssignUpdateRequest;
}) {
  return API.renounceCopies.assignUpdateRenounceCopy(applicationId, renounceCopyAssignUpdateRequest);
}

function useUpdateRenounceCopyMutation() {
  return useMutation(updateMutation);
}

export default useUpdateRenounceCopyMutation;
