import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentLite } from '@ibtm/domain';
import { useAppConfig } from '@libs/app-config';

import { TableButton } from '@libs/common/v2';

import { useDownloadDocumentFile } from '@libs/file';
import { UIElementNameEnum } from '@libs/permission';

import { useGenerateApplicationDocumentMutation } from '@libs/domain/application';
import { DomainDictionaryEntry } from '@libs/domain/config';
import { DocumentsQueryKeysEnum, useGenerateApplicationDocumentDialog } from '@libs/domain/documents';
import { DriverCertificateReportTypeEnum } from '@libs/domain/drivers/model';

interface IProps {
  reportType: DriverCertificateReportTypeEnum;
  driverApplicationId?: string;
  actionKey: UIElementNameEnum;
  cancellationDocument?: DocumentLite;
}

function GenerateExpireReportButton({ reportType, driverApplicationId, actionKey, cancellationDocument }: IProps) {
  const [t] = useTranslation();
  const { openGenerateDocumentDialog } = useGenerateApplicationDocumentDialog({
    generateDocumentMutation: useGenerateApplicationDocumentMutation,
    apiParams: { applicationId: driverApplicationId },
    listQueryKey: DocumentsQueryKeysEnum.DOCUMENTS_LIST
  });
  const { templateNames } = useAppConfig();
  const { downloadFile } = useDownloadDocumentFile();

  const handleGenerateExpireReport = (title: string) => {
    if (cancellationDocument) {
      downloadFile({ id: cancellationDocument.fileId });
    } else {
      openGenerateDocumentDialog({
        title,
        groupKey: DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.OTHER,
        documentTemplateName: templateNames?.expirationsGitd,
        isDocumentDownloadingAfterGenerate: true,
        isFieldsViewOnly: true
      });
    }
  };

  const expirationGitdTemplateTitle = t('document:dialog.generateDocumentTitle', {
    templateGroup: templateNames?.expirationsGitd
  });

  const buttonTitle = useMemo(() => {
    if (reportType === DriverCertificateReportTypeEnum.EXPIRE) {
      return expirationGitdTemplateTitle;
    }

    return t('drivers:actions.generateIssued');
  }, [expirationGitdTemplateTitle, reportType, t]);

  return (
    <TableButton
      onClick={() => handleGenerateExpireReport(expirationGitdTemplateTitle)}
      label={buttonTitle}
      actionKey={actionKey}
    />
  );
}

export default GenerateExpireReportButton;
