import { FolderLite, NamedObjectDetails, SubjectSnapshot } from '@ibtm/domain';

export interface ApplicationToArchiveSnapshot {
  id: string;
  warning: string;
  classificationKey: string;
  organizationUnit: string;
  subject: SubjectSnapshot;
  archiveDate: string;
  archived: boolean;
  version: number;
  statusKey: string;
  paperNumber: string;
  entryDate: string;
  typeKey: string;
  archiveNumber: string;
  number: string;
  categoryKey: string;
  author: NamedObjectDetails;
  folder: FolderLite;
  anonymized: boolean;
  year: number;
}

export enum ApplicationToArchiveInitialParamsEnum {
  YEAR = 'year'
}

export enum ApplicationArchiveUrlParam {
  TO_ARCHIVE = 'toarchive',
  ARCHIVED = 'archived'
}
