import { VehicleUpdateRequest as VehicleUpdateRequestClient } from '@ibtm/client-domain';
import { VehicleCreateRequest, VehicleDetails, VehicleUpdateRequest } from '@ibtm/domain';

import { convertCalendarDate, getCalendarDate } from '@libs/common/v2/utils';

import { VehicleFormData } from '../model';

const parseVehicleRequest = (createData: VehicleFormData): VehicleCreateRequest => ({
  plateNumber: createData.plateNumber,
  brandKey: createData?.brandKey?.value,
  manufactureYear: createData.manufactureYear,
  typeKey: createData?.typeKey?.value,
  acceptableCapacity: createData.acceptableCapacity,
  euroClassKey: createData.euroClassKey?.value,
  modelKey: createData?.modelKey?.value,
  belowThreeAndHalfTons: createData?.belowThreeAndHalfTons?.value,
  dmc: createData.dmc,
  vin: createData.vin,
  seatsNumber: createData.seatsNumber,
  ownershipTypeKey: createData?.ownershipTypeKey?.value,
  certificatesExpirationDate: createData?.certificatesExpirationDate,
  manufacturerCertificate: createData?.manufacturerCertificate,
  rightOfDispositionDate: getCalendarDate(createData?.rightOfDispositionDate),
  temporaryRightOfDisposition: createData?.temporaryRightOfDisposition?.value,
  cancelledVehicleId:
    createData?.withdrawnAlteredVehicleRegistrationNumber?.id ||
    createData?.withdrawnAlteredVehicleRegistrationNumber?.value?.id,
  linkedVehicleId: createData?.linkedVehicleId,
  engineNumber: createData.engineNumber,
  engineType: createData.engineType,
  firstRegistrationDate: getCalendarDate(createData.firstRegistrationDate),
  ignoreExistingVehicles: createData.ignoreExistingVehicles
});

export const parseVehicleCreateRequest = (createData: VehicleFormData): VehicleCreateRequest => ({
  applicationId: createData.applicationId,
  folderId: createData.applicationId ? undefined : createData.folderId,
  ...parseVehicleRequest(createData)
});

export const parseVehicleUpdateRequest = (
  editData: VehicleFormData
): VehicleUpdateRequest | VehicleUpdateRequestClient => ({
  version: editData.version,
  dateOfSale: editData.dateOfSale,
  ...parseVehicleRequest(editData)
});

export const parseVehicleFormData = (data: VehicleDetails): VehicleFormData => ({
  plateNumber: data.plateNumber,
  brandKey: data.brandKey && { value: data.brandKey },
  manufactureYear: data.manufactureYear,
  typeKey: data.typeKey && { value: data.typeKey },
  acceptableCapacity: data.acceptableCapacity,
  euroClassKey: data.euroClassKey && { value: data.euroClassKey },
  modelKey: data.modelKey && { value: data.modelKey },
  belowThreeAndHalfTons: typeof data.belowThreeAndHalfTons === 'boolean' && { value: data.belowThreeAndHalfTons },
  dmc: data.dmc,
  vin: data.vin,
  seatsNumber: data.seatsNumber,
  ownershipTypeKey: data.ownershipTypeKey && { value: data.ownershipTypeKey },
  certificatesExpirationDate: data.certificateSExpirationDate,
  rightOfDispositionDate: convertCalendarDate(data.rightOfDispositionDate),
  temporaryRightOfDisposition: typeof data.temporaryRightOfDisposition === 'boolean' && {
    value: data.temporaryRightOfDisposition
  },
  version: data.version,
  folderId: data.folderId,
  applicationId: data.applicationId,
  statusKey: data.statusKey && { value: data.statusKey },
  engineNumber: data.engineNumber,
  engineType: data.engineType,
  firstRegistrationDate: convertCalendarDate(data.firstRegistrationDate),
  withdrawnAlteredVehicleRegistrationNumber: { id: data.cancelledVehicle?.id },
  linkedVehicleId: data?.linkedVehicle?.id,
  ignoreExistingVehicles: null
});
