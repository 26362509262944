import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { makeStyles } from '@mui/styles';

import { TableButton, TableIconButton, useCreateColumns, useTableAdapter, useTableContext } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import { UIElementNameEnum } from '@libs/permission';

interface IProps<T extends Record<string, any>> {
  row?: Row<T>;
  rowId: string;
  isIcon?: boolean;
  onClick?: () => void;
  actionKey?: UIElementNameEnum;
}
/**
 * Komponent używany przy plugin useAdditionalColumns.
 * Daje możliwość dodawania kolumn na koniec tabeli
 */
function TableButtonInsertColumnLast<T extends Record<string, any>>({
  row,
  rowId,
  isIcon = true,
  onClick,
  actionKey
}: IProps<T>) {
  const [t] = useTranslation();
  const { insertColumnLast, state } = useTableAdapter();
  const {
    maxInsertedColumnNumber,
    columnsToInsert: { columns = [], pathToTranslate },
    setTableLoading
  } = useTableContext();
  const { createColumns } = useCreateColumns({
    pathToTranslate,
    isMutableTable: true
  });
  const classes = useStyles();

  const canAddNewColumns = (state?.additionalCells?.last?.length ?? 0) + columns.length < maxInsertedColumnNumber;

  const handleOnClick = () => {
    setTableLoading(true);
    insertColumnLast({
      id: rowId,
      createColumns,
      columnsToInsert: columns
    });
    onClick?.();
    setTableLoading(false);
  };

  return isIcon ? (
    <TableIconButton
      row={row}
      icon="AddIcon"
      isDisabled={!canAddNewColumns}
      tooltipTitle={canAddNewColumns ? t('action.addNewColumn') : t('action.maxNewColumnsAdded')}
      onClick={handleOnClick}
      actionKey={actionKey}
    />
  ) : (
    <TableButton
      row={row}
      className={!canAddNewColumns && classes.disabledButtons}
      isDisabled={!canAddNewColumns}
      tooltipTitle={!canAddNewColumns && t('action.maxNewColumnsAdded')}
      label={t('action.addNewColumn')}
      onClick={handleOnClick}
      actionKey={actionKey}
    />
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  disabledButtons: {
    backgroundColor: important(theme.palette.grey[300])
  }
}));

export default TableButtonInsertColumnLast;
