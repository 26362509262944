import { usePaginatedQuery } from 'react-query';
import { LicenseCopySearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { LicenseCopySnapshotClient, LicensesQueryKeysEnum, parseLicenseCopiesList } from '@libs/domain/license';
import { endpointsConfig } from '@libs/domain/license/api/endpoints-config';

export const getProceedingKreptdLicenseCopies = async (proceedingId: string, params: LicenseCopySearchFilter) => {
  const { data } = await API.proceedingsKreptd.getLicenseCopySnapshotPageForProceeding(proceedingId, params, {
    ...endpointsConfig.getLicenseCopySnapshotPage
  });

  return {
    ...data,
    content: parseLicenseCopiesList(data.content)
  };
};

function useProceedingKreptdLicenseCopiesQuery(
  params: LicenseCopySearchFilter & { proceedingId: string },
  queryConfig = {}
) {
  return usePaginatedQuery<IPaginatedModel<LicenseCopySnapshotClient>>(
    [LicensesQueryKeysEnum.LICENSE_COPIES_LIST, params],
    () => getProceedingKreptdLicenseCopies(params?.proceedingId, params),
    queryConfig
  );
}

export default useProceedingKreptdLicenseCopiesQuery;
