import { useCallback } from 'react';
import { QueryConfig } from 'react-query';
import { isNull } from 'lodash';
import moment from 'moment';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { useEuroClassesQuery } from '@libs/domain/folder';

function useTranslateEuroClass(euroClassesQueryConfig?: QueryConfig<any, unknown>) {
  const { data } = useEuroClassesQuery(euroClassesQueryConfig);
  const { translate } = useDictionaryTranslations();

  const getEuroClassByYear = useCallback(
    (year: number) => {
      const find = data?.find(euroClass => {
        if (isNull(euroClass.validTo)) return moment(euroClass.validFrom).isSameOrBefore(moment(year, 'YYYY'), 'year');
        return (
          moment(euroClass.validFrom).isSameOrBefore(moment(year, 'YYYY'), 'year') &&
          moment(euroClass.validTo).isSameOrAfter(moment(year, 'YYYY'), 'year')
        );
      });
      return find;
    },
    [data]
  );

  const getEuroClassTranslationByYearOrEuroClassKey = useCallback(
    (year: number, euroClassKey: string) => {
      if (!isNull(euroClassKey)) return translate(DomainDictionaryEnum.EURO_CLASS, euroClassKey);
      const find = getEuroClassByYear(year);
      return find ? translate(DomainDictionaryEnum.EURO_CLASS, find?.euroClassKey) : '-';
    },
    [getEuroClassByYear, translate]
  );

  return { getEuroClassTranslationByYearOrEuroClassKey, getEuroClassByYear, data };
}

export default useTranslateEuroClass;
