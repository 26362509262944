import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { TableIconButton, useConfirmDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { SocialCommissionQueryKeysEnum, useDeleteMeetingMutation } from '@libs/domain/social-commission';

function DeleteMeetingButton({ civicBoardId }: { civicBoardId: string }) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();

  const { showSnackbar } = useSnackbar();
  const [confirm] = useConfirmDialog();
  const { mutate: deleteMeeting, isLoading } = useDeleteMeetingMutation();

  const handleActivateSuccess = () => {
    showSnackbar('success', t('foreignPermits:other.deleteCommisionDateSuccess'));
    queryCache.invalidateQueries(SocialCommissionQueryKeysEnum.CIVIC_BOARD_DETAILS);
  };

  const handleDelete = () => {
    confirm({
      title: t('foreignPermits:other.deleteCommisionDateTitle'),
      shortTitle: t('global:dialog.deleteConfirmTitle'),
      message: t('foreignPermits:other.deleteCommisionDateMessage'),
      confirmText: t('action.delete'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        const requestData = {
          civicBoardId
        };
        setConfirmLoading(true);
        deleteMeeting(requestData, {
          onSuccess: () => {
            handleActivateSuccess();
            closeDialog();
          }
        });
      },
      confirmType: 'danger'
    });
  };

  return (
    <TableIconButton
      icon="TrashIcon"
      onClick={handleDelete}
      isButtonLoading={isLoading}
      tooltipTitle={t('action.delete')}
    />
  );
}

export default DeleteMeetingButton;
