import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RenounceCopySnapshot } from '@ibtm/domain';
import { isObject } from 'lodash';

import { KeyType, partialTranslate } from '@libs/common';
import { DictionarySelectField, Section, SelectOption, useFormV2Context } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import { useCreateUpdateRenounceCopyMutation, useRenounceCopiesyQuery } from './api';
import DischargeCopyTable from './DischargeCopyTable';
import SearchLicensePermissionButton from './SearchLicensePermissionButton';

function LicensePermissionsDischargeSection({
  applicationId,
  applicationTypeKey,
  folderId,
  transferredFoldersIds
}: {
  applicationId: string;
  applicationTypeKey: string;
  folderId: string;
  transferredFoldersIds?: string[];
}) {
  const [t] = useTranslation();
  const getLabel = partialTranslate('applications:additionalField');
  const [renounceCopiesData, setRenounceCopiesData] = useState<RenounceCopySnapshot | null>(null);
  const { control, setValue } = useFormV2Context();
  const { viewMode } = useViewModesV2();
  const affectedPermissionKey = useWatch({
    control,
    name: 'licenseApplicationDetails-affectedPermissionKey'
  }) as SelectOption<string>;
  const [renounceCopyVersion, setRenounceCopyVersion] = useState(0);

  const isLicenseVisible = [
    DomainDictionaryEntry.LICENSE_APPLICATION_ISSUED_PERMISSIONS.LICENSE,
    DomainDictionaryEntry.LICENSE_APPLICATION_ISSUED_PERMISSIONS.LICENSE_AND_PERMISSION
  ].includes(affectedPermissionKey?.value);
  const isPermissionVisible = [
    DomainDictionaryEntry.LICENSE_APPLICATION_ISSUED_PERMISSIONS.PERMISSION,
    DomainDictionaryEntry.LICENSE_APPLICATION_ISSUED_PERMISSIONS.LICENSE_AND_PERMISSION
  ].includes(affectedPermissionKey?.value);

  const { data: renounceCopies, isLoading } = useRenounceCopiesyQuery(applicationId);
  const { mutate: createUpdateMutation } = useCreateUpdateRenounceCopyMutation();

  useEffect(() => {
    if (renounceCopies !== undefined) {
      setRenounceCopiesData(renounceCopies);
      setValue('licenseApplicationDetails-affectedPermissionKey', {
        value: renounceCopies.affectedPermission
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renounceCopies]);

  useEffect(() => {
    if (renounceCopiesData !== null && !isObject(renounceCopiesData) && affectedPermissionKey?.value) {
      createUpdateMutation(
        {
          applicationId,
          renounceCopyCreateOrUpdateRequest: {
            affectedPermissionKey: affectedPermissionKey.value,
            version: renounceCopyVersion
          }
        },
        {
          onSuccess: ({ data }) => {
            setRenounceCopyVersion(data?.version);
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renounceCopiesData, affectedPermissionKey]);

  return (
    <>
      <Section
        title={t('applications:details.section.dischargeCopy' as KeyType)}
        headerContent={
          !viewMode &&
          (isLicenseVisible || isPermissionVisible) && (
            <SearchLicensePermissionButton
              applicationId={applicationId}
              applicationTypeKey={applicationTypeKey}
              folderId={folderId}
              transferredFoldersIds={transferredFoldersIds}
              isLicenseSelect={isLicenseVisible}
              isPermissionVisible={isPermissionVisible}
            />
          )
        }
      >
        <DictionarySelectField
          name="licenseApplicationDetails-affectedPermissionKey"
          dictionaryName={DomainDictionaryEnum.LICENSE_APPLICATION_ISSUED_PERMISSIONS}
          label={getLabel('affectedPermission')}
          isRequired
        />
      </Section>
      {isLicenseVisible && (
        <DischargeCopyTable
          applicationId={applicationId}
          titleKey="licenseCopies"
          data={renounceCopiesData}
          isDataLoading={isLoading}
        />
      )}
      {isPermissionVisible && (
        <DischargeCopyTable
          applicationId={applicationId}
          titleKey="permissionCopies"
          data={renounceCopiesData}
          isDataLoading={isLoading}
        />
      )}
    </>
  );
}

export default LicensePermissionsDischargeSection;
