import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { LicensesSnapshotPage } from '@ibtm/domain';

import { useConfirmDialog } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { DomainDictionaryEntry } from '@libs/domain/config';

import { useChangeFolderStatusMutation } from '../api';

const useUpdateFolderStatusDialog = () => {
  const [t] = useTranslation();
  const [confirm] = useConfirmDialog();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { mutate: changeFolderStatus, isLoading: isUpdating } = useChangeFolderStatusMutation();

  const expireFolder = useCallback(
    (id: string, onSuccess?: () => void) => {
      confirm({
        title: t('folder:details.message.expireConfirmationTitle'),
        message: t('folder:details.message.expireConfirmation'),
        onConfirm: async (setConfirmLoading, closeDialog) => {
          setConfirmLoading(true);
          let licensePage: LicensesSnapshotPage = null;
          try {
            const { data } = await API.license.getLicensesSnapshotPage({
              folderIdIn: [id],
              statusKeyIn: [DomainDictionaryEntry.PERMISSION_STATUS.ACTIVE]
            });
            licensePage = data;
          } catch {
            setConfirmLoading(false);
            showErrorSnackbar(t('folder:details.message.expireConfirmationError'));
            return;
          }
          if (licensePage?.content.length > 0) {
            setConfirmLoading(false);
            closeDialog();
            showErrorSnackbar(t('folder:details.message.expireConfirmationErrorDueToActiveLicense'));
          } else if (licensePage?.content?.length === 0) {
            changeFolderStatus(
              { folderId: id, folderStatusUpdateRequest: { statusKey: DomainDictionaryEntry.FOLDER_STATUS.EXPIRED } },
              {
                onSuccess: () => {
                  onSuccess?.();
                  setConfirmLoading(false);
                  closeDialog();
                  showSuccessSnackbar(t('folder:details.message.expireSuccess'));
                },
                onError: () => {
                  closeDialog();
                  setConfirmLoading(false);
                  showErrorSnackbar(t('folder:details.message.expireConfirmationError'));
                }
              }
            );
          }
        },
        confirmType: 'danger'
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirm, t, changeFolderStatus, showSuccessSnackbar]
  );

  const activateFolder = useCallback(
    (id: string, onSuccess?: () => void) => {
      confirm({
        title: t('folder:details.message.activateConfirmationTitle'),
        message: t('folder:details.message.activateConfirmation'),
        onConfirm: async (setConfirmLoading, closeDialog) => {
          setConfirmLoading(true);
          changeFolderStatus(
            { folderId: id, folderStatusUpdateRequest: { statusKey: DomainDictionaryEntry.FOLDER_STATUS.ACTIVE } },
            {
              onSuccess: () => {
                onSuccess?.();
                closeDialog();
                showSuccessSnackbar(t('folder:details.message.activateSuccess'));
              },
              onSettled: () => {
                setConfirmLoading(false);
              }
            }
          );
        }
      });
    },
    [confirm, changeFolderStatus, showSuccessSnackbar, t]
  );

  return { activateFolder, expireFolder, isUpdating };
};

export default useUpdateFolderStatusDialog;
