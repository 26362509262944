import {
  ForeignPermissionsPrintsSnapshot,
  NamedObjectDetails,
  ResourceObjectLite,
  ResourcesReassignmentRequest
} from '@ibtm/domain';

import { SelectOption } from '@libs/common/v2';

import { DriverCertificateSnapshotClient } from '@libs/domain/driver-certificate';
import { LicenseCopySnapshotClient, LicenseSnapshotClient } from '@libs/domain/license';
import { PermissionCopySnapshotClient, PermissionSnapshotClient } from '@libs/domain/permission';
import { PoolFormData, PoolItem, TravelFormPoolFormData, TravelFormPoolItem } from '@libs/domain/resource';
import { SCertificatesSnapshot } from '@libs/domain/s-certificates';

export type TravelFormRegenerateRegenerateForm = {
  reasonsForReprinting: SelectOption<string>;
  pools: Array<TravelFormPoolItem>;
  selectedNumbers: Array<SelectOption<TravelFormPoolItem>>;
} & TravelFormPoolFormData;

export type ForeignPermissionRegenerateForm = {
  reasonsForReprinting: SelectOption<string>;
  pools: Array<PoolItem>;
  selectedNumbers: Array<SelectOption<PoolItem>>;
} & PoolFormData;

export type ReassignmentSelectValue = Pick<ResourcesReassignmentRequest, 'applicationId' | 'resourceObjectId'>;

export type MultiplePermissionRegenerateForm = {
  reasonsForReprinting: SelectOption<string>;
  areAllNumberSelected?: boolean;
  selectedForms: Array<SelectOption<ReassignmentSelectValue>>;
};

export type PrintablePermissionsUnion = (
  | LicenseSnapshotClient
  | LicenseCopySnapshotClient
  | PermissionCopySnapshotClient
  | PermissionSnapshotClient
  | DriverCertificateSnapshotClient
  | (SCertificatesSnapshot & { form?: ResourceObjectLite })
  | (ForeignPermissionsPrintsSnapshot & { id?: string; form?: ResourceObjectLite; file?: NamedObjectDetails })
) & { forms?: Array<ResourceObjectLite> };

export type IPrintsDetailsParams = {
  id: string;
};

export enum PrintForeignPermissionColumnsEnum {
  FORM_NAME_KEY = 'formNameKey',
  PERMISSIONS_TO_PRINT_AMOUNT = 'permissionsToPrintAmount',
  FIRST_NUMBER = 'firstNumber',
  LAST_NUMBER = 'lastNumber',
  FORM_AMOUNT = 'formAmount'
}
