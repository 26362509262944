import { ProceedingsKreptdApiUpdateProceedingKreptdStatusRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function changeStatus(requestData: ProceedingsKreptdApiUpdateProceedingKreptdStatusRequest) {
  return API.proceedingsKreptd.updateProceedingKreptdStatus(
    requestData.proceedingId,
    requestData.proceedingKreptdStatusUpdateRequest,
    { ...endpointsConfig.updateProceedingKreptdStatus }
  );
}

function useProceedingStatusChangeMutations() {
  return useMutation(changeStatus);
}

export default useProceedingStatusChangeMutations;
