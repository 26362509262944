import { useMemo } from 'react';
import { DriverCertificateSearchFilter } from '@ibtm/domain';

import { ISingleColumn, SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO, getCalendarDate } from '@libs/common/v2/utils';

import { DomainDictionaryEnum } from '../../../config';
import { DriverCertificatesColumnsEnum, DriverCertificateSnapshotClient } from '../../model';

const useDriverCertificatesExtendedColumns = (editableColumns?: DriverCertificatesColumnsEnum[]) => {
  const { createColumns } = useCreateColumns<DriverCertificateSnapshotClient>({
    pathToTranslate: 'driverCertificate:fields',
    isMutableTable: true
  });

  const mappedFilterFields: TableFilterMapper<DriverCertificateSnapshotClient, DriverCertificateSearchFilter> = {
    applicationNumber: (applicationNumber: string) => ({ applicationNumberContains: applicationNumber }),
    driverCertificateNumber: (driverCertificateNumber: string) => ({ numberContains: driverCertificateNumber }),
    subjectName: (subjectName: string) => ({ subjectNameContains: subjectName }),
    formNumber: (formNumber: string) => ({ formNumberContains: formNumber }),
    driverName: (driverName: string) => ({ driverNameContains: driverName }),
    driverSurname: (driverSurname: string) => ({ driverSurnameContains: driverSurname }),

    driverBirthDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      driverBirthDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      driverBirthDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    driverBirthPlace: (driverBirthPlace: SelectOption<string>) => ({
      driverBirthPlaceContains: driverBirthPlace.value
    }),
    driverCitizenship: (driverCitizenship: SelectOption<string>[]) => ({
      driverCitizenshipKeyIn: driverCitizenship?.map(({ value }) => value)
    }),
    driverIdentityCardTypeKey: (driverIdentityCardTypeKey: SelectOption<string>[]) => ({
      driverIdentityCardTypeKeyIn: driverIdentityCardTypeKey?.map(({ value }) => value)
    }),
    driverIdentityCardSeriesNumber: (driverIdentityCardSeriesNumber: string) => ({
      driverIdentityCardSeriesNumberContains: driverIdentityCardSeriesNumber
    }),
    driverIdentityCardIssuingDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      driverIdentityCardIssuingDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      driverIdentityCardIssuingDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    driverIdentityCardIssuingCountryKey: (driverIdentityCardIssuingCountryKey: SelectOption<string>[]) => ({
      driverIdentityCardIssuingCountryCodeKeyIn: driverIdentityCardIssuingCountryKey?.map(({ value }) => value)
    }),
    driverDriverLicenseSeriesNumber: (driverDriverLicenseSeriesNumber: string) => ({
      driverLicenseSeriesNumberContains: driverDriverLicenseSeriesNumber
    }),
    driverDriverLicenseIssuingDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      driverLicenseIssuingDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      driverLicenseIssuingDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    driverInsurancePayerNip: (driverInsurancePayerNip: string) => ({
      driverInsurancePayerNipContains: driverInsurancePayerNip
    }),
    driverCompetenceCodeKey: (driverCompetenceCodeKey: SelectOption<string>[]) => ({
      driverCompetenceCodeKeyIn: driverCompetenceCodeKey?.map(({ value }) => value)
    }),

    printDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      printDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      printDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    validFrom: ({ dateFrom, dateTo }: DateRangeISO) => ({
      validFromGreaterThanOrEqual: getCalendarDate(dateFrom),
      validFromLessThanOrEqual: getCalendarDate(dateTo)
    }),
    validTo: ({ dateFrom, dateTo }: DateRangeISO) => ({
      validToGreaterThanOrEqual: getCalendarDate(dateFrom),
      validToLessThanOrEqual: getCalendarDate(dateTo)
    })
  };

  const mappedSortFields: TableSortMapper<DriverCertificateSnapshotClient> = {
    driverCertificateNumber: 'number',
    formNumber: 'form.fullNumber',
    subjectName: 'driver.application.subject.name',
    driverName: 'driver.personalData.name',
    driverSurname: 'driver.personalData.surname',
    driverBirthDate: 'driver.personalData.birthDate',
    driverBirthPlace: 'driver.personalData.birthPlace',
    driverCitizenship: 'driver.personalData.citizenship',
    driverIdentityCardSeriesNumber: 'driver.identityCard.seriesNumber'
  };

  const defaultColumns: ISingleColumn<DriverCertificateSnapshotClient>[] = useMemo(
    () => [
      {
        type: 'TEXT',
        accessor: 'driverCertificateNumber'
      },
      {
        type: 'TEXT',
        accessor: 'subjectName'
      },
      {
        type: 'TEXT',
        accessor: 'formNumber'
      },
      {
        type: 'TEXT',
        accessor: 'driver.name'
      },
      {
        type: 'TEXT',
        accessor: 'driver.surname'
      },
      {
        header: 'drivers:fields.birthDate',
        type: 'DATE',
        accessor: 'driver.birthDate'
      },
      {
        header: 'drivers:fields.birthPlace',
        type: 'DICTIONARY_SINGLE_SELECT',
        accessor: 'driver.birthPlace',
        dictionaryName: DomainDictionaryEnum.COUNTRY_CODE,
        isFreeSolo: true
      },
      {
        header: 'drivers:fields.citizenship',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.CITIZENSHIP,
        accessor: 'driver.citizenship'
      },
      {
        header: 'drivers:fields.identityCard.typeKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.IDENTITY_DOCUMENT_TYPE,
        accessor: 'driver.identityCard.typeKey'
      },
      {
        header: 'drivers:fields.identityCard.seriesNumber',
        type: 'TEXT',
        accessor: 'driver.identityCard.seriesNumber'
      },
      {
        header: 'drivers:fields.identityCard.issuingDate',
        type: 'DATE',
        accessor: 'driver.identityCard.issuingDate'
      },
      {
        header: 'drivers:fields.identityCard.issuingPlaceKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.COUNTRY_CODE,
        accessor: 'driver.identityCard.issuingCountryKey'
      },
      {
        header: 'drivers:fields.driverLicense.seriesNumber',
        type: 'TEXT',
        accessor: 'driver.driverLicense.seriesNumber'
      },
      {
        header: 'drivers:fields.driverLicense.issuingDate',
        type: 'DATE',
        accessor: 'driver.driverLicense.issuingDate'
      },
      {
        header: 'drivers:fields.driverLicense.issuingPlaceKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.COUNTRY_CODE,
        accessor: 'driver.driverLicense.issuingCountryKey'
      },
      {
        header: 'drivers:fields.insurancePayerNIP',
        type: 'TEXT',
        accessor: 'driver.insurancePayerNip'
      },
      {
        type: 'DATE',
        accessor: 'printDate'
      },
      {
        type: 'DATE',
        accessor: 'validFrom'
      },
      {
        type: 'DATE',
        accessor: 'validTo'
      },
      {
        header: 'drivers:fields.competenceCodeKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.COMPETENCE,
        accessor: 'driver.competenceCodeKey'
      }
    ],
    []
  );

  const mappedColumns = useMemo(
    () =>
      defaultColumns.map(column => {
        const columnIdentifier = (column.id || column.accessor) as DriverCertificatesColumnsEnum;
        return { ...column, isEditable: editableColumns?.includes(columnIdentifier) };
      }),
    [defaultColumns, editableColumns]
  );

  const columns = createColumns(mappedColumns);

  return { columns, mappedSortFields, mappedFilterFields };
};

export default useDriverCertificatesExtendedColumns;
