import { useTranslation } from 'react-i18next';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';
import { AlertApiGetAlertsRequest, AlertSnapshotExtended } from '@stack/report';

import { Button, TableLayout, useRouter, useTableRowActions } from '@libs/common/v2';

import { API } from '@libs/alert/api';
import { useAlertsTable, useUpdateAlertConfigStatusDialog } from '@libs/alert/hooks';

function AlertsTable() {
  const [t] = useTranslation();
  const tableProps = useAlertsTable();
  const { routes } = useRouter();
  const { openUpdateAlertConfigStatusDialog } = useUpdateAlertConfigStatusDialog();

  const { renderTableActions } = useTableRowActions<AlertSnapshotExtended>([
    {
      id: 'NOTIFICATIONS_CONFIGURATION_DETAILS_BUTTON',
      label: 'action.show',
      icon: 'ArrowIcon',
      link: ({ original }) => routes.notificationConfigurationDetails(original?.id)
    },
    {
      id: 'NOTIFICATIONS_CONFIGURATION_EDIT_BUTTON',
      label: 'action.edit',
      icon: 'EditIcon',
      link: ({ original }) => routes.notificationConfigurationEdit(original?.id)
    },
    {
      id: 'NOTIFICATIONS_CONFIGURATION_EDIT_CONFIGURATION_STATUS_BUTTON',
      label: 'alerts:action.editConfigurationStatus',
      icon: 'ChangeStatusIcon',
      onClick: ({
        row: {
          original: { id }
        }
      }) => openUpdateAlertConfigStatusDialog(id)
    }
  ]);

  return (
    <TableLayout
      {...tableProps}
      xlsxDownload={{
        fileName: t('alerts:listTitle'),
        pathToXLSXTranslation: 'alerts:table',
        apiRequest: (params: AlertApiGetAlertsRequest) => {
          const {
            page,
            size,
            sort,
            alertIds,
            authorId,
            createdFrom,
            createdTo,
            descriptionFragment,
            modifiedFrom,
            modifiedTo,
            nameFragment,
            statusKeys,
            sourceNameFragment
          } = params;
          return API.alert.getAlerts(
            page,
            size,
            sort,
            alertIds,
            authorId,
            createdFrom,
            createdTo,
            descriptionFragment,
            modifiedFrom,
            modifiedTo,
            nameFragment,
            statusKeys,
            sourceNameFragment
          );
        }
      }}
      pageTitle={t('alerts:title')}
      headerActions={
        <Button
          isPrimary
          variant="contained"
          label={t('alerts:action.create')}
          link={routes.notificationConfigurationCreate()}
          actionKey={UIElementNameEnum.NOTIFICATIONS_CONFIGURATION_ADD_BUTTON}
        />
      }
      rowActions={renderTableActions([
        'NOTIFICATIONS_CONFIGURATION_DETAILS_BUTTON',
        'NOTIFICATIONS_CONFIGURATION_EDIT_BUTTON',
        'NOTIFICATIONS_CONFIGURATION_EDIT_CONFIGURATION_STATUS_BUTTON'
      ])}
    />
  );
}

export default AlertsTable;
