import { AddressUpdateRequest } from '@ibtm/domain';
import { GridProps } from '@mui/material';

import { partialTranslate } from '@libs/common';
import {
  DictionarySelectField,
  GridItem,
  GridLayout,
  InputMode,
  TextFieldValidation,
  TextInputField,
  ValidatorEnums
} from '@libs/common/v2';

import { parsePostCode } from '@libs/domain/address';
import { DomainDictionaryEnum } from '@libs/domain/config';

const getLabel = partialTranslate('address:fields');

export type AddressFieldsGridProps = {
  [key in keyof AddressUpdateRequest]?: GridProps<'div'>;
};
const defaultGridItemProps: GridProps<'div'> = { xs: 12, sm: 6 };

interface IProps {
  inputProps?: {
    inputMode?: InputMode;
  };
  validation: TextFieldValidation;
  getFieldName: (fieldName: keyof AddressUpdateRequest) => string;
  checkIsHiddenField: (fieldName: keyof AddressUpdateRequest) => boolean;
  isValidationEnabled?: boolean;
  isCountyValidated?: boolean;
  isCommuneValidated?: boolean;
  isVoivodeshipValidated?: boolean;
  fieldGridProps?: AddressFieldsGridProps;
}

function PolishAddressSection({
  validation,
  checkIsHiddenField,
  getFieldName,
  inputProps,
  isValidationEnabled,
  isCountyValidated,
  isCommuneValidated,
  isVoivodeshipValidated,
  fieldGridProps
}: IProps) {
  return (
    <GridLayout itemProps={defaultGridItemProps}>
      {checkIsHiddenField('voivodeshipKey') && (
        <DictionarySelectField
          name={getFieldName('voivodeshipKey')}
          label={getLabel('voivodeship')}
          dictionaryName={DomainDictionaryEnum.VOIVODESHIP}
          validationSingleSelect={isValidationEnabled && isVoivodeshipValidated && { required: [] }}
          {...inputProps}
        />
      )}
      {checkIsHiddenField('county') && (
        <TextInputField
          name={getFieldName('county')}
          label={getLabel('county')}
          inputProps={{ maxLength: 50 }}
          validation={isValidationEnabled && isCountyValidated && validation}
          {...inputProps}
        />
      )}
      {checkIsHiddenField('commune') && (
        <TextInputField
          name={getFieldName('commune')}
          label={getLabel('commune')}
          inputProps={{ maxLength: 50 }}
          validation={isValidationEnabled && isCommuneValidated && validation}
          {...inputProps}
        />
      )}
      {checkIsHiddenField('city') && (
        <GridItem {...(fieldGridProps?.city || defaultGridItemProps)}>
          <TextInputField
            name={getFieldName('city')}
            label={getLabel('city')}
            inputProps={{ maxLength: 50 }}
            validation={isValidationEnabled && validation}
            {...inputProps}
          />
        </GridItem>
      )}
      {checkIsHiddenField('street') && (
        <TextInputField
          name={getFieldName('street')}
          label={getLabel('street')}
          inputProps={{ maxLength: 70 }}
          {...inputProps}
        />
      )}
      {checkIsHiddenField('propertyNumber') && (
        <GridItem md={3}>
          <TextInputField
            name={getFieldName('propertyNumber')}
            label={getLabel('propertyNumber')}
            inputProps={{ maxLength: 10 }}
            validation={isValidationEnabled && validation}
            {...inputProps}
          />
        </GridItem>
      )}
      {checkIsHiddenField('apartmentNumber') && (
        <GridItem md={3}>
          <TextInputField
            name={getFieldName('apartmentNumber')}
            label={getLabel('apartmentNumber')}
            inputProps={{ maxLength: 5 }}
            {...inputProps}
          />
        </GridItem>
      )}
      {checkIsHiddenField('postCode') && (
        <TextInputField
          name={getFieldName('postCode')}
          label={getLabel('postCode')}
          validation={
            isValidationEnabled && {
              ...validation,
              contextValidator: [ValidatorEnums.ADDRESS_POSTAL_CODE]
            }
          }
          getValueFormat={postCode => {
            return parsePostCode(postCode);
          }}
          inputMask={{ mask: '99-999', alwaysShowMask: true }}
          {...inputProps}
        />
      )}
      {checkIsHiddenField('postCity') && (
        <TextInputField
          name={getFieldName('postCity')}
          label={getLabel('postCity')}
          inputProps={{ maxLength: 50 }}
          validation={isValidationEnabled && validation}
          {...inputProps}
        />
      )}
    </GridLayout>
  );
}

export default PolishAddressSection;
