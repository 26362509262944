import { useTranslation } from 'react-i18next';

import { PartialDictionaryField } from '@libs/common/v2';

import { useDictionaryEntryValues } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { ArchiveFormEnum } from '../model';

export function ArchiveWarningForm() {
  const [t] = useTranslation();

  const options = useDictionaryEntryValues(DomainDictionaryEnum.ARCHIVE_NOTE);
  return (
    <PartialDictionaryField
      name={ArchiveFormEnum.WARNING}
      options={options}
      label={t('archive:application.field.warning')}
      dictionaryName={DomainDictionaryEnum.ARCHIVE_NOTE}
      isRequired
      isMultiline
    />
  );
}
