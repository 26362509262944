import React from 'react';
import { useTranslation } from 'react-i18next';

import TableIconButton from './TableIconButton';

interface IProps {
  onClick: () => void;
  className?: string;
  isExpanded: boolean;
}

function TableButtonCollapse({ className, onClick, isExpanded }: IProps) {
  const [t] = useTranslation();

  return (
    <TableIconButton
      icon={isExpanded ? 'ChevronDownIcon' : 'ChevronRightIcon'}
      onClick={onClick}
      className={className}
      tooltipTitle={isExpanded ? t('action.collapse') : t('action.expand')}
    />
  );
}

export default React.memo(TableButtonCollapse);
