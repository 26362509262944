import { useTranslation } from 'react-i18next';

import { Section } from '@libs/common/v2';

import { AddressSection } from '@libs/domain/address';

function AssociationAddressSection({ isAddressCountryFieldAvaliable }: { isAddressCountryFieldAvaliable: boolean }) {
  const [t] = useTranslation();
  return (
    <Section title={t('folder:field.primaryAddress')} isCollapsable>
      <AddressSection formGroupName="addresses.0" isCountryFieldAvaliable={isAddressCountryFieldAvaliable} />
    </Section>
  );
}

export default AssociationAddressSection;
