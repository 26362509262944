import { ApplicationSnapshot } from '@ibtm/domain';

import { useRouter, useTableRowActions } from '@libs/common/v2';

import { DomainUIElementEnum } from '@libs/domain/config';

import { ApplicationEditOrAssignButton } from '../components';
import ApplicationsTable from '../components/ApplicationsTable';
import { useApplicationPermission } from '../hooks';

function ApplicationsPage() {
  const { routes } = useRouter();
  const { hasPermissions } = useApplicationPermission();
  const { renderTableActions } = useTableRowActions<ApplicationSnapshot>([
    {
      id: DomainUIElementEnum.APPLICATION_SHOW_DETAILS_BUTTON,
      label: 'applications:action.openApplication',
      link: ({ original }) => ({
        pathname: routes.applicationDetails(original.id),
        state: original
      }),
      icon: 'ArrowIcon',
      isHidden: ({ row }) => !hasPermissions({ action: 'view', applicationType: row.original.typeKey })
    },
    {
      id: DomainUIElementEnum.APPLICATION_EDIT_BUTTON,
      customButton: ({ original }) => (
        <ApplicationEditOrAssignButton
          application={original}
          isTableRowButton
          onEdit={{ link: routes.applicationEdit(original.id), state: { application: original } }}
          permissionsKey="application"
        />
      )
    }
  ]);

  return (
    <ApplicationsTable
      isAssignEditorActionEnabled
      rowActions={renderTableActions([
        DomainUIElementEnum.APPLICATION_SHOW_DETAILS_BUTTON,
        DomainUIElementEnum.APPLICATION_EDIT_BUTTON
      ])}
      queryParams={{ includeArchived: true }}
    />
  );
}

export default ApplicationsPage;
