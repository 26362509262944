import { ResourceTypeSearchFilter } from '@ibtm/domain';

import { useTableLayoutProps } from '@libs/common/v2';

import useFormTypesQuery from '../../api/queries/resource-types/useResourceTypesQuery';
import { useResourceTypesColumns } from '../columns';

function useResourceTypesTable(initialParams: Partial<ResourceTypeSearchFilter>) {
  const { columns, mappedFilterFields, mappedSortFields } = useResourceTypesColumns();

  return useTableLayoutProps({
    tableHookQuery: useFormTypesQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    },
    tableHookQueryInitialParams: initialParams
  });
}

export default useResourceTypesTable;
