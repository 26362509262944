import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthSelector } from '@libs/auth/store/reducers';
import { PermissionElementMapper, UIElementNameEnum } from '@libs/permission';

import { isPermissionCheckEnabled } from './permission.utils';

function useElementVisibility() {
  const [t] = useTranslation();
  const userPermissions = useAuthSelector(({ auth }) => auth.user.permissions);

  const checkIsElementVisible = useCallback(
    (element: UIElementNameEnum) => {
      if (!isPermissionCheckEnabled()) {
        return true;
      }

      try {
        const { anyOfPermission, allOfPermissions, nestedAnyOfPermissions } = PermissionElementMapper[element];

        let isVisible = true;

        if (anyOfPermission?.length) {
          isVisible = anyOfPermission.some(permission => userPermissions.includes(permission));
        }

        if (allOfPermissions?.length) {
          isVisible = allOfPermissions.every(permission => userPermissions.includes(permission));
        }

        // Jeśli używamy nestedAnyOfPermissions nie możemy korzystać z anyOfPermission
        if (nestedAnyOfPermissions?.length) {
          isVisible = nestedAnyOfPermissions
            .map(nestedAnyOfPermission =>
              nestedAnyOfPermission.every(permission => userPermissions.includes(permission))
            )
            .some(permissionGroup => permissionGroup === true);
        }

        return isVisible;
      } catch (e) {
        throw new Error(t('error.permissionNotDefined', { element }));
      }
    },
    [t, userPermissions]
  );
  return { checkIsElementVisible };
}

export default useElementVisibility;
