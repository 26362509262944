import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DriverDetails } from '@ibtm/domain';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

import {
  Button,
  Dialog,
  FormMode,
  GridLayout,
  Theme,
  useDialog,
  useIsSmallScreen,
  useViewModesV2
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { convertCalendarDate, isDateLengthValid, RecursivePartial } from '@libs/common/v2/utils';

import { ApplicationQueryKeysEnum } from '@libs/domain/application';
import { DriverForm } from '@libs/domain/drivers/components';
import { SetValuesForWarnings, useForm } from '@libs/domain/drivers/hooks';
import { DriverDetailsClient } from '@libs/domain/drivers/model';

import { DomainDictionaryEntry } from '../../../config';
import { useDriverDetailsQuery } from '../../api';
import useDriverNotificationAndValidation from '../useDriverNotificationAndValidation';
import useWarningDriverHasCertificate from '../useWarningDriverHasCertificate';

interface IProps {
  data: {
    driverId?: string;
    application?: {
      id?: string;
      subjectNip?: string;
      applicationTypeKey?: string;
    };
  };
  handleSuccess: () => void;
  closeDialog: () => void;
  formMode: FormMode;
  folderId?: string;
  transferredFoldersIds?: string[];
  initialData?: RecursivePartial<DriverDetailsClient>;
}

function DriverDialog({
  data,
  formMode,
  closeDialog,
  handleSuccess,
  initialData,
  folderId,
  transferredFoldersIds
}: IProps) {
  const { isSmallScreen } = useIsSmallScreen();
  const [t] = useTranslation();

  const dialogRef = useRef(null);
  const [warningScrolled, setWarningScrolled] = useState(false);
  const [loading, setLoading] = useState(false);
  const { formRef, submitForm } = useForm();
  const { viewMode, createMode, editMode } = useViewModesV2(formMode);
  const {
    notificationComponent,
    setValuesForWarning,
    isWarningVisible: isWarningDriverInFolderVisible,
    valuesForWarning,
    data: driversListData
  } = useDriverNotificationAndValidation(
    closeDialog,
    data?.application?.id,
    folderId,
    transferredFoldersIds,
    createMode,
    data?.application?.applicationTypeKey
  );
  const {
    setValuesForWarning: setValuesForCertificateWarning,
    checkAndShowDialog,
    isWarningVisible,
    isLoading: isLoadingWarning
  } = useWarningDriverHasCertificate(folderId, undefined, transferredFoldersIds);

  const { data: driverDetails, isLoading: isDriverDetailsLoading } = useDriverDetailsQuery(
    { driverId: data?.driverId },
    { enabled: !!data?.driverId }
  );

  useEffect(() => {
    if (isWarningDriverInFolderVisible && !warningScrolled) {
      dialogRef.current.scrollTop = 0;
      setWarningScrolled(true);
    }
  }, [isWarningDriverInFolderVisible, warningScrolled]);

  const setValuesForWarnings: SetValuesForWarnings = (param, value) => {
    setValuesForWarning(param, value);
    setValuesForCertificateWarning(param, value);
  };

  const getDialogTitle = () => {
    if (createMode) return t('drivers:dialogs.driverAddTitle');
    return editMode ? t('drivers:dialogs.driverEditTitle') : t('drivers:dialogs.driverDataTitle');
  };

  const isAdditionalDetailsFetching = useMemo(
    () =>
      isDriverDetailsLoading ||
      (editMode &&
        Boolean(
          !driverDetails ||
            ((createMode ||
              data?.application?.applicationTypeKey ===
                DomainDictionaryEntry.APPLICATION_TYPE.changeOfDriverCertificate) &&
              valuesForWarning.documentSeriesNumber &&
              isDateLengthValid(valuesForWarning.dateOfBirth) &&
              !driversListData)
        )),
    [
      createMode,
      data?.application?.applicationTypeKey,
      driverDetails,
      driversListData,
      valuesForWarning,
      editMode,
      isDriverDetailsLoading
    ]
  );

  const classes = useStyles({
    isLoading: isAdditionalDetailsFetching
  });

  const isConfirmButtonDisabled = useMemo(() => {
    if (createMode) {
      return isWarningDriverInFolderVisible;
    }

    return isAdditionalDetailsFetching;
  }, [createMode, isWarningDriverInFolderVisible, isAdditionalDetailsFetching]);

  const isConfirmLoading = useMemo(() => loading || isLoadingWarning, [loading, isLoadingWarning]);

  return (
    <Dialog
      className={classes.root}
      title={getDialogTitle()}
      onConfirm={submitForm}
      onCancel={closeDialog}
      confirmText={createMode ? t(isSmallScreen ? 'action.add' : 'drivers:actions.addToApplication') : t('action.save')}
      {...(isWarningDriverInFolderVisible && {
        confirmButtonTooltipText: { disabled: t('drivers:actions.similarDriverOnFolderTooltip') }
      })}
      cancelText={t('action.cancel')}
      isConfirmButtonDisabled={isConfirmButtonDisabled}
      isFullScreen={isSmallScreen}
      {...(viewMode
        ? {
            customActions: (
              <Button
                onClick={closeDialog}
                label={t('action.close')}
                isSecondary
                variant="outlined"
                isNoMargin
                size="large"
              />
            )
          }
        : {})}
      isConfirmLoading={isConfirmLoading}
      // eslint-disable-next-line no-return-assign
      scrollbarRontainerRef={el => (dialogRef.current = el)}
      isOpen
    >
      <GridLayout itemProps={{ xs: 12 }}>
        {createMode && notificationComponent}
        <DriverForm
          data={data}
          initialData={initialData}
          driverDetails={driverDetails}
          isDriverDetailsFetching={isAdditionalDetailsFetching}
          formMode={formMode}
          setValuesForWarning={setValuesForWarnings}
          onSuccess={handleSuccess}
          checkAndShowDialog={checkAndShowDialog}
          onLoading={setLoading}
          ref={formRef}
          isWarningVisible={isWarningVisible}
        />
      </GridLayout>
    </Dialog>
  );
}

const useDriverDialog = (folderId?: string, transferredFoldersIds?: string[]) => {
  const queryCache = useQueryCache();
  const { openDialog } = useDialog();

  const open = ({
    data,
    initialData,
    formMode
  }: {
    data: {
      driverId?: string;
      application?: {
        id?: string;
        subjectNip?: string;
        applicationTypeKey?: string;
      };
    };
    formMode: FormMode;
    initialData?: DriverDetails;
  }) => {
    openDialog(({ closeDialog }) => {
      const handleSuccess = () => {
        if (data.application) {
          queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, data.application.id]);
        }
        closeDialog();
      };

      const parsedInitialData: RecursivePartial<DriverDetailsClient> = {
        ..._.pick(initialData, [
          'id',
          'version',
          'name',
          'surname',
          'birthPlace',
          'citizenship',
          'insurancePayerNIP',
          'periodOfIssuing',
          'employmentDocumentKey',
          'created',
          'linkedDriverCreated'
        ]),
        birthDate: convertCalendarDate(initialData?.birthDate),
        driverLicense: {
          ..._.pick(initialData?.driverLicense, [
            'seriesNumber',
            'issuingCountryKey',
            'indefinite',
            'qualifications',
            'code',
            'expiryDateCompetenceCard'
          ]),
          expirationDate: convertCalendarDate(initialData?.driverLicense?.expirationDate),
          issuingDate: convertCalendarDate(initialData?.driverLicense?.issuingDate)
        },
        identityCard: {
          ..._.pick(initialData?.identityCard, [
            'seriesNumber',
            'expirationDate',
            'issuingCountryKey',
            'issuingPlaceKey',
            'typeKey'
          ]),
          issuingDate: convertCalendarDate(initialData?.identityCard?.issuingDate),
          expirationDate: convertCalendarDate(initialData?.identityCard?.expirationDate)
        }
      };

      return (
        <DriverDialog
          data={data}
          formMode={formMode}
          closeDialog={closeDialog}
          handleSuccess={handleSuccess}
          initialData={parsedInitialData}
          folderId={folderId}
          transferredFoldersIds={transferredFoldersIds}
        />
      );
    });
  };

  return {
    open
  };
};

const useStyles = makeStyles<Theme, { isLoading?: boolean }>({
  root: {
    '& .MuiPaper-outlined': {
      height: ({ isLoading }) => (isLoading ? '50px' : 'unset')
    },
    '& .scrollbar-container': {
      height: ({ isLoading }) => (isLoading ? '250px' : 'unset')
    }
  }
});

export default useDriverDialog;
