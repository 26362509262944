import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { CalendarDate } from 'calendar-date';
import moment from 'moment';
import { object as YupObject, string as YupString } from 'yup';

import {
  DatepickerField,
  Dialog,
  DictionarySelectField,
  FormV2Context,
  GridItem,
  GridLayout,
  isDateValid,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';

import { DictionaryEntryTypeEnum } from '@libs/dictionary';

import { DomainDictionaryEnum } from '../../config';
import { useUpdateLicenseMutation } from '../api';
import { licenseValidityPeriodKeyToValue } from '../utils';

export interface IUpdateLicenseRequest {
  id?: string;
  validFrom?: string;
  validTo?: string;
  printDate?: string;
  version?: number;
  licenseNumber?: string;
  licenseValidityYearsKey?: string;
}

interface IProps {
  readonly closeDialog: () => void;
  readonly initialData: IUpdateLicenseRequest;
  readonly onSuccess: () => void;
  readonly licenseValidityYearsDictionary: keyof DictionaryEntryTypeEnum;
}

export function EditLicenseDialog({ closeDialog, licenseValidityYearsDictionary, initialData, onSuccess }: IProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: updateLicense, isLoading } = useUpdateLicenseMutation();

  const validationScheme = YupObject({
    licenseNumber: YupString().nullable().required(),
    licenseValidityYearsKey: YupString().nullable().required(),
    printDate: YupString().nullable().required().concat(isDateValid()),
    validFrom: YupString().nullable().required().concat(isDateValid()),
    validTo: YupString()
      .nullable()
      .required()
      .test({
        name: 'validityPeriodKey',
        message: t('prints:messages.validFromExceedsValidityPeriod'),
        test: function isValid(validTo) {
          // eslint-disable-next-line react/no-this-in-sfc
          const parent = this.parent as IUpdateLicenseRequest;
          const validFrom = parent?.validFrom;
          const licenseValidityYearsKey = parent?.licenseValidityYearsKey;

          const licenseValidityPeriodValue = licenseValidityPeriodKeyToValue[licenseValidityYearsKey];
          if (!licenseValidityPeriodValue) {
            return true;
          }

          const validFromPeriod = moment(validFrom).add(licenseValidityPeriodValue, 'years');
          return !moment(validTo).isAfter(validFromPeriod);
        }
      })
      .test({
        name: 'validTo',
        message: t('applications:validations.validToDateCanNotBeBeforeValidFrom'),
        test: function isValid(validTo) {
          // eslint-disable-next-line react/no-this-in-sfc
          const parent = this.parent as IUpdateLicenseRequest;
          const validFrom = parent?.validFrom;
          return !validFrom || !validTo || moment(validFrom).isBefore(validTo);
        }
      })
      .concat(isDateValid())
  });

  const form = useForm<Partial<IUpdateLicenseRequest>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationScheme)
  });

  useEffect(() => {
    form.reset(initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (data: Partial<IUpdateLicenseRequest>) => {
    const { id, validTo, version, printDate, validFrom, licenseNumber, licenseValidityYearsKey } = data;
    return updateLicense(
      {
        id,
        validFrom: validFrom as unknown as CalendarDate,
        version,
        number: licenseNumber,
        licenseValidityYearsKey,
        printDate: printDate as unknown as CalendarDate,
        validTo: validTo as unknown as CalendarDate
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('license:message.licenseCopyUpdated'));
          onSuccess();
          closeDialog();
        }
      }
    );
  };

  return (
    <Dialog
      title={t('license:title.editLicense')}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={form.handleSubmit(handleSubmit)}
      onCancel={closeDialog}
      dialogSize="small"
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <GridLayout>
            <GridItem xs={12}>
              <TextInputField
                name={typedNameV2<IUpdateLicenseRequest>('licenseNumber')}
                label={t('license:fields.licenseNumber')}
                isRequired
              />
            </GridItem>
            <GridItem xs={12}>
              <DatepickerField
                name={typedNameV2<IUpdateLicenseRequest>('printDate')}
                label={t('license:fields.printDate')}
                isRequired
              />
            </GridItem>
            <GridItem xs={12}>
              <DictionarySelectField
                dictionaryName={licenseValidityYearsDictionary || DomainDictionaryEnum.LICENSE_VALIDITY_YEARS}
                name={typedNameV2<IUpdateLicenseRequest>('licenseValidityYearsKey')}
                label={t('license:fields.licenseValidityYearsKey')}
                stringValue
                isRequired
              />
            </GridItem>
            <GridItem xs={12}>
              <DatepickerField
                name={typedNameV2<IUpdateLicenseRequest>('validFrom')}
                label={t('license:fields.validFrom')}
                isRequired
              />
            </GridItem>
            <GridItem xs={12}>
              <DatepickerField
                name={typedNameV2<IUpdateLicenseRequest>('validTo')}
                label={t('license:fields.validTo')}
                isRequired
              />
            </GridItem>
          </GridLayout>
        </form>
      </FormV2Context.Provider>
    </Dialog>
  );
}
