import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableOptions } from 'react-table';
import { useSnackbar } from '@enigma/fe-ui';

import {
  Button,
  CamelCasePath,
  TableButton,
  TableButtonDownload,
  TableButtonMore,
  TableLayout,
  useConfirmDialog,
  useTableRowActions
} from '@libs/common/v2';
import { useMutation, useQueryCache } from '@libs/common/v2/api';

import { useFileDownload } from '@libs/file';
import { UIElementNameEnum } from '@libs/permission';

import { ApplicationQueryKeysEnum } from '@libs/domain/application';
import { useDomainConfigContext } from '@libs/domain/config';

import { FilesQueryKeysEnum, useDownloadQuery } from '../../api';
import { DEFAULT_COLUMNS, useAddFileDialog, useFilesTable, useSignFileDialog } from '../../hooks';
import { APIProps, Extension, FilesListClientItem } from '../../models';

interface FilesListProps {
  title: string;
  api: APIProps;
  visibleColumns?: Array<CamelCasePath<FilesListClientItem>>;
  tableInitialState?: Partial<TableOptions<any>>;
  isSignFileEnabled?: boolean;
  isSection?: boolean;
  isRefreshEnabled?: boolean;
  isCollapsable?: boolean;
  hideHeaderActions?: boolean;
  tableActionKeys?: UIElementNameEnum[];
  actionKey?: UIElementNameEnum;
  showFileWarning?: boolean;
  rowActionIds?: {
    download?: string;
    delete?: string;
    sign?: string;
  };
}

function FilesTable({
  title,
  api,
  visibleColumns = DEFAULT_COLUMNS,
  tableInitialState,
  isSignFileEnabled,
  tableActionKeys,
  actionKey,
  showFileWarning = false,
  hideHeaderActions,
  rowActionIds = {},
  ...forwardProps
}: FilesListProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { download } = useFileDownload();
  const [confirm] = useConfirmDialog();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const [downloadFileId, setDownloadFileId] = useState<string>();
  const { isClientPortal } = useDomainConfigContext();

  const downloadQuery = useDownloadQuery(
    { fileId: downloadFileId, isClientPortal },
    { enabled: Boolean(downloadFileId) }
  );
  const tableProps = useFilesTable(visibleColumns, api.GET, tableInitialState);
  const { mutate: deleteFile, isLoading: isDeleteFileLoading } = useMutation(api.DELETE);

  const { openAddFileDialog } = useAddFileDialog();
  const { openSignFileDialog } = useSignFileDialog();

  useEffect(() => {
    if (downloadQuery.data) {
      download(
        () =>
          new Promise(res => {
            res(downloadQuery.data);
          })
      );
      setDownloadFileId(null);
    }
  }, [download, downloadQuery]);

  const handleOpenAddFileDialog = () => {
    openAddFileDialog(
      api.CREATE,
      () => {
        queryCache.invalidateQueries(FilesQueryKeysEnum.FILE_LIST);
        queryCache.invalidateQueries(ApplicationQueryKeysEnum.APPLICATION);
      },
      showFileWarning
    );
  };

  const handleDeleteFile = (data: FilesListClientItem) => {
    confirm({
      message: t('dialog.deleteContent', { name: data.fileName }),
      title: t('dialog.deleteFileTitle'),
      onConfirm: (setLoading, closeDialog) => {
        setLoading(true);
        deleteFile(
          {
            recordId: data.id
          },
          {
            onSuccess: () => {
              queryCache.invalidateQueries(FilesQueryKeysEnum.FILE_LIST);
              showSuccessSnackbar(t('success.fileDelete'));
            },
            onError: () => {
              showErrorSnackbar(t('dialog.deleteFailure'));
            },
            onSettled: () => {
              closeDialog();
              setLoading(false);
            }
          }
        );
      },
      confirmType: 'danger'
    });
  };

  const isLoading = useMemo(() => {
    return downloadQuery.isLoading || isDeleteFileLoading;
  }, [downloadQuery.isLoading, isDeleteFileLoading]);

  const getRowActions = (original: FilesListClientItem) => {
    return (
      <>
        <TableButtonDownload onClick={() => setDownloadFileId(original.fileId)} isDisabled={isLoading} />
        {(api.DELETE || api?.EDIT || isSignFileEnabled) && (
          <TableButtonMore>
            {close => (
              <>
                {isSignFileEnabled && original && (
                  <TableButton
                    label={t('document:action.signFile')}
                    icon="WorkspacePremium"
                    onClick={() => {
                      openSignFileDialog({ fileId: original?.fileId, apiCreateQuery: api.CREATE });
                      close();
                    }}
                    isDisabled={isLoading}
                  />
                )}
                {api?.DELETE && (
                  <TableButton
                    label={t('action.delete')}
                    icon="TrashIcon"
                    onClick={() => {
                      handleDeleteFile(original);
                      close();
                    }}
                    isDisabled={isLoading}
                  />
                )}
              </>
            )}
          </TableButtonMore>
        )}
      </>
    );
  };

  const { renderTableActions } = useTableRowActions<FilesListClientItem>(
    [
      {
        id: rowActionIds.download,
        label: 'action.download',
        onClick: ({
          row: {
            original: { fileId }
          }
        }) => setDownloadFileId(fileId),
        isDisabled: isLoading,
        icon: 'DownloadIcon'
      },
      {
        id: rowActionIds.delete,
        label: 'action.delete',
        icon: 'TrashIcon',
        onClick: ({ row: { original } }) => handleDeleteFile(original),
        isDisabled: isLoading,
        isHidden: !api?.DELETE
      },
      {
        id: rowActionIds.sign,
        label: 'document:action.signFile',
        icon: 'WorkspacePremium',
        onClick: ({ row: { original } }) =>
          openSignFileDialog({ onSuccess: () => {}, fileId: original?.fileId, apiCreateQuery: api?.CREATE }),
        isDisabled: isLoading,
        isHidden: ({
          row: {
            original: { fileExtension }
          }
        }) => !isSignFileEnabled || fileExtension !== Extension.PDF
      }
    ],
    [isSignFileEnabled]
  );

  const mobileHeaderActions = [
    {
      label: t('action.addFile'),
      onClick: handleOpenAddFileDialog,
      actionKey,
      isHidden: !api.CREATE || hideHeaderActions
    }
  ];

  return (
    <TableLayout<FilesListClientItem, FilesListClientItem>
      {...tableProps}
      pageTitle={title}
      xlsxDownload={{
        fileName: title,
        pathToXLSXTranslation: 'global:field.attachments',
        apiRequest: api.GET
      }}
      headerActions={
        api?.CREATE &&
        !hideHeaderActions && (
          <Button
            label={t('action.addFile')}
            onClick={handleOpenAddFileDialog}
            variant="outlined"
            classNameWrapper="ml-16"
            isNoMargin
            actionKey={actionKey}
          />
        )
      }
      mobileHeaderActions={mobileHeaderActions}
      rowActions={tableActionKeys ? renderTableActions(tableActionKeys) : ({ original }) => getRowActions(original)}
      {...forwardProps}
    />
  );
}

export default FilesTable;
