import { makeStyles } from '@mui/styles';

import { Theme } from '@libs/common/v2/theme';
import { border, hexToRgba, important } from '@libs/common/v2/utils';

const useMessagePopoverStyles = makeStyles<Theme, { unreadMessagesCount?: number }>(theme => ({
  icon: { padding: 10 },
  paper: {
    width: 380,
    overflow: 'hidden',
    height: 600
  },
  container: {
    height: 300
  },
  notifications: {
    position: 'relative',
    color: hexToRgba(theme.palette.grey[300], 0.2),
    '&:hover, &:focus': {
      backgroundColor: hexToRgba(theme.palette.grey[300], 0.2)
    },
    '&::before': {
      content: ({ unreadMessagesCount }) => `"${unreadMessagesCount}"`,
      visibility: ({ unreadMessagesCount }) => (unreadMessagesCount ? 'visible' : 'hidden'),
      width: ({ unreadMessagesCount }) => (unreadMessagesCount >= 10 ? 24 : 20),
      height: 16,
      borderRadius: 10,
      backgroundColor: theme.palette.blue[500],
      color: theme.palette.white,
      fontSize: 12,
      lineHeight: '17px',
      position: 'absolute',
      right: ({ unreadMessagesCount }) => (unreadMessagesCount >= 10 ? '-2px' : 1),
      top: 3
    }
  },
  listButton: {
    position: 'absolute',
    bottom: 0,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1.75),
    backgroundColor: theme.palette.white,
    borderTop: border(hexToRgba(theme.palette.grey[300], 1.5)),
    '&:hover, &:focus': {
      backgroundColor: hexToRgba(theme.palette.grey[400], 0.5)
    }
  },
  listSubheader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(1.75),
    borderBottom: border(hexToRgba(theme.palette.grey[200], 1))
  },
  listSubheaderBottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(1.5)
  },
  scroll: {
    height: important('502px'),
    overflowY: 'auto',
    overflowX: 'hidden',
    '& .ps__rail-x': {
      display: important('none')
    }
  },
  scrollbar: {
    height: important('502px')
  },
  muteNotificationsButton: { position: 'absolute', right: 8 }
}));

export default useMessagePopoverStyles;
