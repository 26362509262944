import { CreateBulkNotificationCommand, DefaultApiCreateRequest } from '@avispon/message';
import { AxiosRequestConfig } from 'axios';

import { useMutation } from '@libs/common/v2/api';

import API from '../endpoints';
import { endpointsConfig } from '../endpoints-config';

const createAdminMessage = async (requestData: CreateBulkNotificationCommand) => {
  const request: DefaultApiCreateRequest = {
    body: requestData
  };
  const { data } = await API.message.create(request, {
    ...endpointsConfig.create
  } as AxiosRequestConfig);

  return data;
};

export default function useCreateAdminMessageMutation() {
  return useMutation(createAdminMessage);
}
