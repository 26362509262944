import { useTranslation } from 'react-i18next';
import { useQueryCache } from 'react-query';
import { useSnackbar } from '@enigma/fe-ui';

import { useConfirmDialog } from '@libs/common/v2';

import { useCopyFolderVehiclesMutation, VehicleQueryKeysEnum } from '../api';

interface IProps {
  applicationId: string;
  transferredFoldersIds?: string[];
}

export function useCopyFolderVehiclesAction({ applicationId, transferredFoldersIds }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();

  const [confirm] = useConfirmDialog();
  const { mutate: copyVehicles } = useCopyFolderVehiclesMutation();
  const { showSuccessSnackbar } = useSnackbar();
  const handleCopyVehicles = () => {
    confirm({
      onConfirm: (setLoading, closeDialog) => {
        setLoading(true);
        copyVehicles(
          { applicationId, vehicleIds: [], folderIds: transferredFoldersIds },
          {
            onSuccess: () => {
              queryCache.invalidateQueries([VehicleQueryKeysEnum.VEHICLE_LIST]);
              showSuccessSnackbar(t('vehicle:details.message.vehicleAddedPlural'));
            },
            onSettled: () => {
              closeDialog();
              setLoading(false);
            }
          }
        );
      },
      message: t('vehicle:vehicleCopyModalContent'),
      title: t('vehicle:downloadVehiclesTitle')
    });
  };

  return { handleCopyVehicles };
}
