import { PaletteOptions } from '@libs/common/v2/theme';

// kontrastowe kolory użyte w theme
export const contrastYellow = '#FFF832';
const contrastBlack = '#000000';
const contrastRed = '#B42318';
// dodany na potrzebę lepszego oznaczenia pól formularza w trybie disable jest to kolor grey[200]
const contrastGrey = '#EAECF0';

export type PrimaryPalette = {
  [key in keyof Pick<PaletteOptions, 'primary'>['primary']]: {
    normalColor: string;
    contrastColor?: string;
  };
};

/**
 *
 * @param primaryPalette   Obiekt z kolorami primary zawierający normalny kolor oraz opcjonalnie jego wersję kontrastową - domyślnie '#FFF832'
 * @param contrast         Opcjonalna parametr true/false definiująca czy paleta ma być w trybie kontrastowym.
 * @returns                Paleta kolorów dla projektu
 */
export const createCommonPalette = (primaryPalette: PrimaryPalette, contrast?: boolean) => {
  const getColor = (normalColor: string, contrastColor: string) => (contrast ? contrastColor : normalColor);

  return {
    white: getColor('#FFFFFF', contrastBlack),
    grey: {
      '25': getColor('#FCFCFD', contrastBlack),
      '50': getColor('#F9FAFB', contrastBlack),
      '100': getColor('#F2F4F7', contrastBlack),
      '200': getColor('#EAECF0', contrastYellow),
      /**
       * To jest ten sam kolor co grey[200] tylko zachwuje swój kolor w trybie kontrastowym
       */
      '250': getColor('#EAECF0', contrastGrey),
      '300': getColor('#D0D5DD', contrastYellow),
      '400': getColor('#98A2B3', contrastYellow),
      '500': getColor('#667085', contrastYellow),
      '600': getColor('#475467', contrastBlack),
      '700': getColor('#344054', contrastYellow),
      '800': getColor('#1D2939', contrastYellow),
      '900': getColor('#101828', contrastYellow)
    },
    primary: Object.entries(primaryPalette).reduce((acc, primaryColor) => {
      const accTemp = acc;

      accTemp[primaryColor[0]] = getColor(
        primaryColor[1].normalColor,
        primaryColor[1]?.contrastColor ?? contrastYellow
      );
      return accTemp;
    }, {}),
    contrastIndicators: {
      hover: getColor('#E7EAEE', '#807C19'),
      background: getColor('#FFFFFF', contrastYellow)
    },
    error: {
      '25': getColor('#FFFBFA', contrastRed),
      '50': getColor('#FEF3F2', contrastRed),
      '100': getColor('#FEE4E2', contrastRed),
      '200': getColor('#FECDCA', contrastRed),
      '300': getColor('#FDA29B', contrastRed),
      '400': getColor('#F97066', contrastRed),
      '500': getColor('#F04438', contrastRed),
      '600': getColor('#D92D20', contrastRed),
      '700': getColor('#B42318', contrastRed),
      '800': getColor('#912018', contrastGrey),
      '900': getColor('#7A271A', contrastRed)
    },
    warning: {
      '25': '#FFFCF5',
      '50': '#FFFAEB',
      '100': '#FEF0C7',
      '200': '#FEDF89',
      '300': '#FEC84B',
      '400': '#FDB022',
      '500': '#F79009',
      '600': '#DC6803',
      '700': '#B54708',
      '800': '#93370D',
      '900': '#7A2E0E'
    },
    success: {
      '25': getColor('#F6FEF9', contrastYellow),
      '50': getColor('#ECFDF3', contrastYellow),
      '100': getColor('#D1FADF', contrastYellow),
      '200': getColor('#A6F4C5', contrastYellow),
      '300': getColor('#6CE9A6', contrastYellow),
      '400': getColor('#32D583', contrastYellow),
      '500': getColor('#12B76A', contrastYellow),
      '600': getColor('#12B76A', contrastYellow),
      '700': getColor('#027A48', contrastBlack),
      '800': getColor('#05603A', contrastBlack),
      '900': getColor('#054F31', contrastYellow)
    },
    blueGrey: {
      '25': getColor('#FCFCFD', contrastYellow),
      '50': getColor('#F8F9FC', contrastYellow),
      '100': getColor('#EAECF5', contrastYellow),
      '200': getColor('#D5D9EB', contrastYellow),
      '300': getColor('#AFB5D9', contrastYellow),
      '400': getColor('#717BBC', contrastYellow),
      '500': getColor('#4E5BA6', contrastYellow),
      '600': getColor('#3E4784', contrastYellow),
      '700': getColor('#363F72', contrastYellow),
      '800': getColor('#293056', contrastYellow),
      '900': getColor('#101323', contrastYellow)
    },
    blueLight: {
      '25': getColor('#F5FBFF', contrastYellow),
      '50': getColor('#F0F9FF', contrastYellow),
      '100': getColor('#E0F2FE', contrastYellow),
      '200': getColor('#B9E6FE', contrastYellow),
      '300': getColor('#7CD4FD', contrastYellow),
      '400': getColor('#36BFFA', contrastYellow),
      '500': getColor('#0BA5EC', contrastYellow),
      '600': getColor('#0086C9', contrastYellow),
      '700': getColor('#026AA2', contrastYellow),
      '800': getColor('#065986', contrastYellow),
      '900': getColor('#0B4A6F', contrastYellow)
    },
    blue: {
      '25': getColor('#F5FAFF', contrastYellow),
      '50': getColor('#EFF8FF', contrastYellow),
      '100': getColor('#D1E9FF', contrastYellow),
      '200': getColor('#B2DDFF', contrastYellow),
      '300': getColor('#84CAFF', contrastYellow),
      '400': getColor('#53B1FD', contrastYellow),
      '500': getColor('#2E90FA', contrastYellow),
      '600': getColor('#1570EF', contrastYellow),
      '700': getColor('#175CD3', contrastYellow),
      '800': getColor('#1849A9', contrastBlack),
      '900': getColor('#194185', contrastYellow)
    },
    indigo: {
      '25': getColor('#F5F8FF', contrastYellow),
      '50': getColor('#EEF4FF', contrastYellow),
      '100': getColor('#E0EAFF', contrastYellow),
      '200': getColor('#C7D7FE', contrastYellow),
      '300': getColor('#A4BCFD', contrastYellow),
      '400': getColor('#8098F9', contrastYellow),
      '500': getColor('#6172F3', contrastYellow),
      '600': getColor('#444CE7', contrastYellow),
      '700': getColor('#3538CD', contrastYellow),
      '800': getColor('#2D31A6', contrastYellow),
      '900': getColor('#2D3282', contrastYellow)
    },
    purple: {
      '25': getColor('#FAFAFF', contrastYellow),
      '50': getColor('#F4F3FF', contrastYellow),
      '100': getColor('#EBE9FE', contrastYellow),
      '200': getColor('#D9D6FE', contrastYellow),
      '300': getColor('#BDB4FE', contrastYellow),
      '400': getColor('#9B8AFB', contrastYellow),
      '500': getColor('#7A5AF8', contrastYellow),
      '600': getColor('#6938EF', contrastYellow),
      '700': getColor('#5925DC', contrastYellow),
      '800': getColor('#4A1FB8', contrastYellow),
      '900': getColor('#3E1C96', contrastYellow)
    },
    pink: {
      '25': getColor('#FEF6FB', contrastYellow),
      '50': getColor('#FDF2FA', contrastYellow),
      '100': getColor('#FCE7F6', contrastYellow),
      '200': getColor('#FCCEEE', contrastYellow),
      '300': getColor('#FAA7E0', contrastYellow),
      '400': getColor('#F670C7', contrastYellow),
      '500': getColor('#EE46BC', contrastYellow),
      '600': getColor('#DD2590', contrastYellow),
      '700': getColor('#C11574', contrastYellow),
      '800': getColor('#C11574', contrastYellow),
      '900': getColor('#851651', contrastYellow)
    },
    red: {
      '25': getColor('#FFF5F6', contrastYellow),
      '50': getColor('#FFEBEE', contrastYellow),
      '100': getColor('#FFCDD2', contrastYellow),
      '200': getColor('#EF9A9A', contrastYellow),
      '300': getColor('#E57373', contrastYellow),
      '400': getColor('#EF5350', contrastYellow),
      '500': getColor('#F44336', contrastYellow),
      '600': getColor('#E53935', contrastYellow),
      '700': getColor('#D32F2F', contrastYellow),
      '800': getColor('#C62828', contrastYellow),
      '900': getColor('#B71C1C', contrastYellow)
    },
    rose: {
      '25': getColor('#FFF5F6', contrastYellow),
      '50': getColor('#FFF1F3', contrastYellow),
      '100': getColor('#FFE4E8', contrastYellow),
      '200': getColor('#FECDD6', contrastYellow),
      '300': getColor('#FEA3B4', contrastYellow),
      '400': getColor('#FD6F8E', contrastYellow),
      '500': getColor('#F63D68', contrastYellow),
      '600': getColor('#E31B54', contrastYellow),
      '700': getColor('#C01048', contrastYellow),
      '800': getColor('#A11043', contrastYellow),
      '900': getColor('#89123E', contrastYellow)
    },
    orange: {
      '25': getColor('#FFFAF5', contrastYellow),
      '50': getColor('#FFF6ED', contrastYellow),
      '100': getColor('#FFEAD5', contrastYellow),
      '200': getColor('#FDDCAB', contrastYellow),
      '300': getColor('#FEB273', contrastYellow),
      '400': getColor('#FD853A', contrastYellow),
      '500': getColor('#FB6514', contrastYellow),
      '600': getColor('#EC4A0A', contrastYellow),
      '700': getColor('#C4320A', contrastYellow),
      '800': getColor('#9C2A10', contrastYellow),
      '900': getColor('#7E2410', contrastYellow)
    },
    saturatedBlue: {
      '25': getColor('#FFFAF5', contrastYellow),
      '50': getColor('#FFF6ED', contrastYellow),
      '100': getColor('#FFEAD5', contrastYellow),
      '200': getColor('#FDDCAB', contrastYellow),
      '300': getColor('#FEB273', contrastYellow),
      '400': getColor('#FD853A', contrastYellow),
      '500': getColor('#FB6514', contrastYellow),
      '600': getColor('#16284B', contrastYellow),
      '700': getColor('#032041', contrastYellow),
      '800': getColor('#9C2A10', contrastYellow),
      '900': getColor('#7E2410', contrastYellow)
    }
  };
};
