import { DefaultApiCreateOrganizationUnitRequest } from '@avispon/organization-structure';

import { useMutation, useQueryCache } from '@libs/common/v2/api';

import { API, endpointsConfig, OrganizationQueryKeysEnum } from '..';

const createOrganizationUnit = async (params: DefaultApiCreateOrganizationUnitRequest) => {
  const { data } = await API.organizationStructure.createOrganizationUnit(params, {
    ...endpointsConfig.createOrganizationUnit
  });
  return data;
};

export default function useCreateOrganizationUnitMutation({ onSuccess }) {
  const queryCache = useQueryCache();
  return useMutation(createOrganizationUnit, {
    onSuccess: (...args) => {
      queryCache.invalidateQueries(OrganizationQueryKeysEnum.ORGANIZATION_UNIT_DETAILS);
      queryCache.invalidateQueries(OrganizationQueryKeysEnum.ORGANIZATION_UNIT_LIST);
      onSuccess(...args);
    }
  });
}
