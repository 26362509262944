import { ResourceObjectInvoiceReturnCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function returnResourceObjectsRange(request: ResourceObjectInvoiceReturnCreateRequest) {
  return API.resourceObjects.returnResourceObjectsRange(request, {
    ...endpointsConfig.createSuspensionInvoice
  });
}

function useReturnResourceReleaseDocuments() {
  return useMutation(returnResourceObjectsRange);
}

export default useReturnResourceReleaseDocuments;
