import React from 'react';

import { PermissionEnum } from '@libs/domain/config/permission/PermissionEnum';

const ReleaseDocumentsTablePage = React.lazy(() => import('../pages/ReleaseDocumentsTablePage'));
const ReleaseDocumentsWrapper = React.lazy(() => import('../pages/ReleaseDocumentsWrapper'));

// eslint-disable-next-line import/prefer-default-export
export const ReleaseDocumentsConfig = {
  routes: [
    {
      path: '/release-documents',
      element: <ReleaseDocumentsTablePage />,
      auth: () => [PermissionEnum.IBTM_DOMAIN_INVOICE_LIST_VIEW]
    },
    {
      path: '/release-documents/:id/:formMode',
      element: <ReleaseDocumentsWrapper />,
      auth: () => [PermissionEnum.IBTM_DOMAIN_INVOICE_DETAILS_VIEW]
    },
    {
      path: '/release-documents/:id/return',
      element: <ReleaseDocumentsWrapper />,
      auth: () => [PermissionEnum.IBTM_DOMAIN_INVOICE_CORRECTION_CREATE]
    }
  ]
};
