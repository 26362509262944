import { QueryConfig, useQuery } from 'react-query';
import { UserTaskDetails } from '@ibtm/process-api';

import { API } from '@libs/domain/api';
import { ApplicationQueryKeysEnum } from '@libs/domain/application';

const getTasksDetails = async (ids: string[]) => {
  const response = await Promise.all(ids.map(id => API.task.details(id).then(res => res.data)));

  return response;
};

export default function useTasksDetailsQuery(ids: string[], queryConfig: QueryConfig<UserTaskDetails[], unknown> = {}) {
  return useQuery<UserTaskDetails[]>([ApplicationQueryKeysEnum.TASK, ids], () => getTasksDetails(ids), queryConfig);
}
