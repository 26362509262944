import { FormErrorType, InputMode, ValidatorEnums } from '@libs/common/v2';

import { ApplicationMetaFormDefinition, PermissionTableEnum } from '@libs/domain/application/model';
import { PermissionEnum } from '@libs/domain/config/permission/PermissionEnum';
import { PermissionCopiesColumnsEnum, PermissionsColumnsEnum, PermissionTypeEnum } from '@libs/domain/permission';
import { VehicleTableEditionVisibleValidator } from '@libs/domain/vehicle/model/vehicle.model';

import { MAIN_COMMENT_FIELD_NAME } from '../../application/shared/attachments/model/common.model';

const definition: ApplicationMetaFormDefinition = {
  fields: [
    //  'ZAKŁADKA DANE PODSTAWOWE'
    {
      typeKey: 'TEXT',
      fieldId: 'application-version',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'version'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'version'
          }
        },
        hidden: true
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-version-requested-permits',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_PERMISSION_MATRIX',
            accessor: 'applicationUpdateRequest.version'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'version'
          }
        },
        hidden: true
      }
    },
    {
      typeKey: 'ADDRESS_FIELDS',
      fieldId: 'application-permission-address-fields',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'permissionData.address'
          }
        }
      }
    },
    {
      typeKey: 'OUTGOIN_DOCUMENTS_ADDITIONAL_SECTION_LICENSE_OR_PERMISSION',
      fieldId: 'application-outgoing-documents-additional-section-license-or-permission',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_CREATE_APPLICATION_DECISION',
            accessor: 'application-outgoing-documents-additional-section-license-or-permission'
          }
        }
      }
    },
    {
      typeKey: 'ADDRESS_FIELDS',
      fieldId: 'application-license-address-fields',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'licenseData.address'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-deceased-name',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.transferApplicationDataUpdateRequest.deceasedName'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-deceased-surname',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.transferApplicationDataUpdateRequest.deceasedSurname'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-date-of-subject-death',
      properties: {
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'dateOfSubjectDeath'
          },
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'dateOfSubjectDeath'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-date-of-subject-death-hidden-get',
      properties: {
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'dateOfSubjectDeath'
          }
        },
        hidden: true,
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'DATETIME',
      fieldId: 'application-submission-date',
      properties: {
        label: 'Data wpływu',
        yupValidation: {
          required: {},
          max: {
            date: 'TODAY'
          }
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'submissionReceiptDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'submissionReceiptDate'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-submission-date-hidden-requested-permits',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_PERMISSION_MATRIX',
            accessor: 'applicationUpdateRequest.submissionReceiptDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'submissionReceiptDate'
          }
        },
        hidden: true
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-submission-date-hidden',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'submissionReceiptDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'submissionReceiptDate'
          }
        },
        hidden: true
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-submission-date-hidden-get',
      properties: {
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'submissionReceiptDate'
          }
        },
        hidden: true
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-submission-channel-key',
      properties: {
        label: 'Sposób wpływu',
        dictionaryName: 'APPLICATION_CHANNEL',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'applicationChannelKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'applicationChannelKey'
          }
        }
      }
    },
    {
      typeKey: 'DATETIME',
      fieldId: 'application-expected-completion-date',
      properties: {
        label: 'Termin realizacji',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'expectedCompletionDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'expectedCompletionDate'
          }
        }
      }
    },
    {
      typeKey: 'LAST_SUPPLEMENT_RECEIPT_DATE',
      fieldId: 'application-last-supplement-receipt-date',
      properties: {
        label: 'Data wpływu ostatniego uzupełnienia',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'lastSupplementReceiptDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'lastSupplementReceiptDate'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-folder',
      properties: {
        label: 'Teczka powiązana',
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'folder.number'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-folder-number',
      properties: {
        label: 'Nr teczki',
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'folder.number'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'DATETIME',
      fieldId: 'application-created-date',
      properties: {
        label: 'Data wprowadzenia',
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'created'
          }
        },
        inputMode: InputMode.VIEW,
        withTime: true
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-author',
      properties: {
        label: 'Wprowadził',
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'author.name'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'DATETIME',
      fieldId: 'application-paper-version-wwu-date',
      properties: {
        label: 'Data otrzymania wersji papierowej (WWU)',
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'paperVersionDateWWU'
          }
        },
        inputMode: InputMode.VIEW,
        withTime: true
      }
    },
    {
      typeKey: 'DATETIME',
      fieldId: 'application-end-date',
      properties: {
        label: 'Data zakończenia',
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'closeDate'
          }
        },
        inputMode: InputMode.VIEW,
        withTime: true
      }
    },
    {
      typeKey: 'AUTOCOMPLETE',
      fieldId: 'application-concern-license',
      properties: {
        label: 'Dotyczy licencji',
        visibility: {
          elementVisibilityAsyncKeys: ['FOLDER_HAS_ACTIVE_OR_PENDING_LISENSE']
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'concernLicense',
            formAccessor: 'id'
          },
          fetch: {
            requestKey: 'GET_LICENCES',
            optionTextMatch: '[number]'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'concernLicense'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-receipt-type-key',
      properties: {
        label: 'Sposób odbioru',
        dictionaryName: 'RECEIPT_TYPE',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'receiptTypeKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'receiptTypeKey'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_RECEIPT_TYPE_CHOOSE]
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-receipt-type-key-requested-permits',
      properties: {
        label: 'Sposób odbioru',
        dictionaryName: 'RECEIPT_TYPE',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_PERMISSION_MATRIX',
            accessor: 'applicationUpdateRequest.receiptTypeKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'receiptTypeKey'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_RECEIPT_TYPE_CHOOSE]
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-receipt-key-other',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'receiptTypeKey'
          },
          get: {
            requestKey: 'GET_CONSTANTS',
            accessor: 'receiptTypes.other'
          }
        },
        hidden: true
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-send-to-address-type-key',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'sendToAddressTypeKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'sendToAddressTypeKey'
          }
        },
        hidden: true
      }
    },
    {
      typeKey: 'SELECT_ADDRESS_TYPE_FIELD',
      fieldId: 'application-send-to-address-required',
      properties: {
        label: 'Adres do wysyłki',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'newSendToAddressTypeKey',
            formAccessor: 'value.typeKey'
          },
          fetch: {
            requestKey: 'GET_APPLICATION_ADDRESSES'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'sendToAddress'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_SHIPPING_ADDRESS_CHOOSE]
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-send-to-address-type-key-requested-permits',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_PERMISSION_MATRIX',
            accessor: 'applicationUpdateRequest.sendToAddressTypeKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'sendToAddressTypeKey'
          }
        },
        hidden: true
      }
    },
    {
      typeKey: 'SELECT_ADDRESS_TYPE_FIELD',
      fieldId: 'application-send-to-address-required-requested-permits',
      properties: {
        label: 'Adres do wysyłki',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_PERMISSION_MATRIX',
            accessor: 'applicationUpdateRequest.newSendToAddressTypeKey',
            formAccessor: 'value.typeKey'
          },
          fetch: {
            requestKey: 'GET_APPLICATION_ADDRESSES_WITH_ASSOCIATION_ADDRESS'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'sendToAddress'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_SHIPPING_ADDRESS_CHOOSE]
        }
      }
    },
    {
      typeKey: 'SELECT_ADDRESS_TYPE_FIELD',
      fieldId: 'application-send-to-address-not-required-requested-permits',
      properties: {
        label: 'Adres do wysyłki',
        api: {
          submit: {
            requestKey: 'UPDATE_PERMISSION_MATRIX',
            accessor: 'applicationUpdateRequest.newSendToAddressTypeKey',
            formAccessor: 'value.typeKey'
          },
          fetch: {
            requestKey: 'GET_APPLICATION_ADDRESSES_WITH_ASSOCIATION_ADDRESS'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'sendToAddress'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_SHIPPING_ADDRESS_CHOOSE]
        }
      }
    },
    {
      typeKey: 'SELECT_ADDRESS_TYPE_FIELD',
      fieldId: 'application-send-to-address-notRequired',
      properties: {
        label: 'Adres do wysyłki',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'newSendToAddressTypeKey',
            formAccessor: 'value.typeKey'
          },
          fetch: {
            requestKey: 'GET_APPLICATION_ADDRESSES'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'sendToAddress'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_SHIPPING_ADDRESS_CHOOSE]
        }
      }
    },
    {
      typeKey: 'SELECT_ADDRESS_TYPE_FIELD',
      fieldId: 'application-send-to-address-notRequired-requested-permits',
      properties: {
        label: 'Adres do wysyłki',
        api: {
          submit: {
            requestKey: 'UPDATE_PERMISSION_MATRIX',
            accessor: 'applicationUpdateRequest.newSendToAddressTypeKey',
            formAccessor: 'value.typeKey'
          },
          fetch: {
            requestKey: 'GET_APPLICATION_ADDRESSES'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'sendToAddress'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_SHIPPING_ADDRESS_CHOOSE]
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-email-view',
      properties: {
        label: 'Email',
        yupValidation: {
          contextValidator: ValidatorEnums.EMAIL
        },
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'contact.email'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-send-to-address-name',
      properties: {
        label: 'Imię odbierającego',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'recipientName'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'recipientName'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-send-to-address-name-requested-permits',
      properties: {
        label: 'Imię odbierającego',
        api: {
          submit: {
            requestKey: 'UPDATE_PERMISSION_MATRIX',
            accessor: 'applicationUpdateRequest.recipientName'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'recipientName'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-send-to-address-surname-requested-permits',
      properties: {
        label: 'Nazwisko odbierającego',
        api: {
          submit: {
            requestKey: 'UPDATE_PERMISSION_MATRIX',
            accessor: 'applicationUpdateRequest.recipientSurname'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'recipientSurname'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-send-to-address-surname',
      properties: {
        label: 'Nazwisko odbierającego',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'recipientSurname'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'recipientSurname'
          }
        }
      }
    },
    {
      typeKey: 'AUTOCOMPLETE',
      fieldId: 'application-locked-by-application',
      properties: {
        label: 'Wniosek blokujący',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'lockedByApplication',
            formAccessor: 'id'
          },
          fetch: {
            requestKey: 'GET_LOCKING_APPLICATIONS',
            accessor: null,
            optionTextMatch: '[number]'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'lockedByApplication'
          }
        }
      }
    },
    {
      typeKey: 'BOOLEAN',
      fieldId: 'application-is-review-application',
      properties: {
        label: 'Czy wpłynął wniosek o ponowne rozpatrzenie sprawy?',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'isReviewApplication'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'isReviewApplication'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_COURT_JUDGMENTS_UPDATE]
        }
      }
    },
    {
      typeKey: 'BOOLEAN',
      fieldId: 'licenseApplicationDetails-transferApplicationDataDetails-folders-boolean-false',
      properties: {
        defaultInitialValue: false,
        hidden: true,
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'transferFromMultipleFolders'
          },
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'transferFromMultipleFolders'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-second-tier-decision-key',
      properties: {
        label: 'Rodzaj rozstrzygnięcia II instancji',
        dictionaryName: 'SECOND_TIER_DECISION',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'secondTierDecisionKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'secondTierDecisionKey'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_COURT_JUDGMENTS_UPDATE]
        }
      }
    },
    {
      typeKey: 'DATETIME',
      fieldId: 'application-second-tier-decision-date',
      properties: {
        label: 'Data rozstrzygnięcia (II instancji)',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'secondTierDecisionDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'secondTierDecisionDate'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_COURT_JUDGMENTS_UPDATE]
        }
      }
    },
    {
      typeKey: 'BOOLEAN',
      fieldId: 'application-wsa-complaint',
      properties: {
        label: 'Czy wpłynęła skarga do WSA?',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'wsaComplaint'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'wsaComplaint'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_COURT_JUDGMENTS_UPDATE]
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-wsa-decision-key',
      properties: {
        label: 'Rodzaj rozstrzygnięcia WSA',
        dictionaryName: 'WSA_DECISION',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'wsaDecisionKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'wsaDecisionKey'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_COURT_JUDGMENTS_UPDATE]
        }
      }
    },
    {
      typeKey: 'DATETIME',
      fieldId: 'application-wsa-decision-date',
      properties: {
        label: 'Data rozstrzygnięcia (WSA)',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'wsaDecisionDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'wsaDecisionDate'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_COURT_JUDGMENTS_UPDATE]
        }
      }
    },
    {
      typeKey: 'BOOLEAN',
      fieldId: 'application-final-decision',
      properties: {
        label: 'Ostateczne rozstrzygnięcie?',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'finalDecision'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'finalDecision'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_COURT_JUDGMENTS_UPDATE]
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-name-view',
      properties: {
        label: 'Nazwa',
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'subject.name'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-categoryKey',
      properties: {
        label: 'Kategoria',
        dictionaryName: 'APPLICATION_CATEGORY',
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'categoryKey'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-typeKey',
      properties: {
        label: 'Rodzaj',
        yupValidation: {
          required: true
        },
        dictionaryName: 'APPLICATION_TYPE',
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'typeKey'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-number',
      properties: {
        label: 'Numer',
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'number'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-archiveNumber',
      properties: {
        label: 'Numer archiwizacyjny',
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'archiveNumber'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-paperNumber',
      properties: {
        label: 'Numer papierowy',
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'paperNumber'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'SPO_APPLICATIONS_SELECT',
      fieldId: 'application-linked-spo-applications',
      properties: {
        label: 'Powiązane wnioski',
        multiple: true,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'linkedApplications',
            formAccessor: 'id',
            typeKey: 'AUTOCOMPLETE'
          },
          get: {
            accessor: 'linkedApplications'
          }
        }
      }
    },
    {
      typeKey: 'SPO_APPLICATIONS_SELECT',
      fieldId: 'application-extended-spo-applications',
      properties: {
        label: 'Przedłuża wniosek',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'extendedApplication',
            formAccessor: 'id',
            typeKey: 'AUTOCOMPLETE'
          },
          get: {
            accessor: 'extendedApplication'
          }
        }
      }
    },
    {
      typeKey: 'SPO_APPLICATIONS_SELECT',
      fieldId: 'application-linked-spo-applications',
      properties: {
        label: 'Powiązane wnioski',
        multiple: true,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'linkedApplications',
            formAccessor: 'id',
            typeKey: 'AUTOCOMPLETE'
          },
          get: {
            accessor: 'linkedApplications'
          }
        }
      }
    },
    {
      typeKey: 'NUMBER',
      fieldId: 'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumber',
      properties: {
        label: 'Liczba pojazdów',
        min: 0,
        yupValidation: {
          required: true
        },
        triggerFieldIdValidationOnChange: ['licenseApplicationDetails-commonLicenseApplicationData-copiesNumber'],
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.commonLicenseApplicationData.vehiclesNumber'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.commonLicenseApplicationData.vehiclesNumber'
          }
        }
      }
    },
    {
      typeKey: 'NUMBER',
      fieldId: 'licenseApplicationDetails-commonLicenseApplicationData-copiesNumber',
      properties: {
        label: 'Liczba wypisów',
        yupValidation: {
          required: true,
          dependentMax: {
            fieldId: 'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumber',
            fieldName: 'Liczba pojazdów'
          },
          min: 1
        },
        min: 0,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.commonLicenseApplicationData.copiesNumber'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.commonLicenseApplicationData.copiesNumber'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'licenseApplicationDetails-emptyField',
      properties: {
        hidden: true
      }
    },
    {
      typeKey: 'NUMBER',
      fieldId: 'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumberBelowThreeAndHalfTons',
      properties: {
        label: 'Liczba pojazdów (<=3.5t)',
        yupValidation: {
          required: true,
          dependentSumMin: {
            fieldId: 'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumberAboveThreeAndHalfTons',
            fieldName: 'Liczba pojazdów (większa niż 3.5t)'
          }
        },
        triggerFieldIdValidationOnChange: [
          'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumberAboveThreeAndHalfTons',
          'licenseApplicationDetails-commonLicenseApplicationData-copiesNumberBelowThreeAndHalfTons'
        ],
        min: 0,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.commonLicenseApplicationData.vehiclesNumberBelowThreeAndHalfTons'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.commonLicenseApplicationData.vehiclesNumberBelowThreeAndHalfTons'
          }
        }
      }
    },
    {
      typeKey: 'NUMBER',
      fieldId: 'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumberAboveThreeAndHalfTons',
      properties: {
        label: 'Liczba pojazdów (>3.5t)',
        yupValidation: {
          required: true,
          dependentSumMin: {
            fieldId: 'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumberBelowThreeAndHalfTons',
            fieldName: 'Liczba pojazdów (mniejsza lub równa 3.5t)'
          }
        },
        triggerFieldIdValidationOnChange: [
          'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumberBelowThreeAndHalfTons',
          'licenseApplicationDetails-commonLicenseApplicationData-copiesNumberAboveThreeAndHalfTons'
        ],
        min: 0,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.commonLicenseApplicationData.vehiclesNumberAboveThreeAndHalfTons'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.commonLicenseApplicationData.vehiclesNumberAboveThreeAndHalfTons'
          }
        }
      }
    },
    {
      typeKey: 'NUMBER',
      fieldId: 'licenseApplicationDetails-commonLicenseApplicationData-copiesNumberBelowThreeAndHalfTons',
      properties: {
        label: 'Liczba wypisów (ze znacznikiem <= 3.5t)',
        yupValidation: {
          required: true,
          dependentMax: {
            fieldId: 'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumberBelowThreeAndHalfTons',
            fieldName: 'Liczba pojazdów (mniejsza lub równa 3.5t)'
          }
        },
        triggerFieldIdValidationOnChange: [
          'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumberBelowThreeAndHalfTons',
          'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumberBelowThreeAndHalfTons'
        ],
        min: 0,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.commonLicenseApplicationData.copiesNumberBelowThreeAndHalfTons'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.commonLicenseApplicationData.copiesNumberBelowThreeAndHalfTons'
          }
        }
      }
    },
    {
      typeKey: 'NUMBER',
      fieldId: 'licenseApplicationDetails-commonLicenseApplicationData-copiesNumberAboveThreeAndHalfTons',
      properties: {
        label: 'Liczba wypisów (bez znacznika <= 3.5t)',
        yupValidation: {
          required: true,
          dependentMax: {
            fieldId: 'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumberAboveThreeAndHalfTons',
            fieldName: 'Liczba pojazdów (większa niż 3.5t)'
          }
        },
        triggerFieldIdValidationOnChange: [
          'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumberAboveThreeAndHalfTons'
        ],
        min: 0,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.commonLicenseApplicationData.copiesNumberAboveThreeAndHalfTons'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.commonLicenseApplicationData.copiesNumberAboveThreeAndHalfTons'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'permissionApplicationDetails-personalNeedsApplicationDetails-transportTypeKey',
      properties: {
        label: 'Rodzaj przewozu',
        yupValidation: {
          required: true
        },
        dictionaryName: 'TRANSPORT_TYPE',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.personalNeedsDataUpdateRequest.transportTypeKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.personalNeedsApplicationDetails.transportTypeKey'
          }
        }
      }
    },
    {
      typeKey: 'BOOLEAN',
      fieldId: 'permissionApplicationDetails-personalNeedsApplicationDetails-employmentStatement',
      properties: {
        label: 'Oświadczenie o zatrudnieniu',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.personalNeedsDataUpdateRequest.employmentStatement'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.personalNeedsApplicationDetails.employmentStatement'
          }
        }
      }
    },
    {
      typeKey: 'NUMBER',
      fieldId: 'permissionApplicationDetails-personalNeedsApplicationDetails-vehicles',
      properties: {
        label: 'Liczba pojazdów',
        yupValidation: {
          required: true
        },
        min: 0,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.personalNeedsDataUpdateRequest.vehicles'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.personalNeedsApplicationDetails.vehicles'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'permissionApplicationDetails-personalNeedsApplicationDetails-validityPeriodKey',
      properties: {
        label: 'Czas obowiązywania',
        yupValidation: {
          required: true
        },
        dictionaryName: 'VALIDITY_PERIOD_FOR_OWN_NEEDS',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.personalNeedsDataUpdateRequest.validityPeriodKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.personalNeedsApplicationDetails.validityPeriodKey'
          }
        }
      }
    },
    {
      typeKey: 'SELECT_DATA_OF_APPLICANT',
      fieldId: 'permissionApplicationDetails-personalNeedsApplicationDetails-issuer',
      properties: {
        label: 'Dane składającego',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.personalNeedsDataUpdateRequest.issuer',
            formAccessor: 'id'
          },
          fetch: {
            requestKey: 'GET_COMPANY_PROXIES',
            accessor: null,
            optionTextMatch: '[name] [surname]'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.personalNeedsApplicationDetails.issuer'
          }
        }
      }
    },
    {
      typeKey: 'APPLICATION_TERM_INPUT',
      fieldId: 'permissionApplicationDetails-personalNeedsApplicationDetails-term',
      properties: {
        label: 'Data terminu',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.personalNeedsDataUpdateRequest.term'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.personalNeedsApplicationDetails.term'
          }
        }
      }
    },
    {
      typeKey: 'FOLDER_SELECT_FIELD',
      fieldId: 'licenseApplicationDetails-transferApplicationDataDetails-folders',
      properties: {
        label: 'Teczki',
        multiple: true,
        api: {
          fetch: {
            requestKey: 'GET_FOLDER_TO_TRANSFER',
            optionTextMatch: '[number]',
            backendFilterParamName: 'numberContains'
          },
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.transferApplicationDataUpdateRequest.folders',
            formAccessor: 'id'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.transferApplicationDataDetails.folders'
          }
        }
      }
    },
    //   'ZAKŁADKA DANE PODSTAWOWE - koniec'
    //  'ZAKŁADKA KABOTAŻ'
    {
      typeKey: 'CABOTAGE_COUNTRY_FIELD',
      fieldId: 'cabotage-country',
      properties: {
        label: 'Kraj kabotażu',
        dictionaryName: 'COUNTRY_CODE',
        multiple: true,
        api: {
          get: {
            requestKey: 'GET_APPLICATION_CABOTAGE',
            accessor: 'countryCodeKeys'
          },
          submit: {
            requestKey: 'UPDATE_APPLICATION_CABOTAGE',
            accessor: 'countryCodeKeys'
          }
        }
      }
    },
    {
      typeKey: 'CABOTAGE_REMARKS_FIELD',
      fieldId: 'cabotage-remarks',
      properties: {
        label: 'Uwagi',
        lines: 10,
        api: {
          get: {
            requestKey: 'GET_APPLICATION_CABOTAGE',
            accessor: 'notes'
          },
          submit: {
            requestKey: 'UPDATE_APPLICATION_CABOTAGE',
            accessor: 'notes'
          }
        }
      }
    },
    //  'ZAKŁADKA ZAKŁADKA KABOTAŻ - koniec'
    {
      typeKey: 'ADDRESSES_TAB_CONTENT',
      fieldId: 'application-addresses-tab',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION_ADDRESS',
            accessor: 'addresses'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: MAIN_COMMENT_FIELD_NAME,
      properties: {
        label: 'Uwagi ogólne',
        lines: 5,
        api: {
          submit: {
            requestKey: 'UPDATE_ATTACHMENTS',
            accessor: 'notes'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-version',
      properties: {
        hidden: true,
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'version'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'version'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-subject-type-key',
      properties: {
        label: 'Typ podmiotu',
        dictionaryName: 'SUBJECT_TYPE',
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'subjectTypeKey'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-subject-country-of-origin',
      properties: {
        label: 'Kraj pochodzenia',
        dictionaryName: 'COUNTRY_CODE',
        yupValidation: {
          required: true
        },
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'countryOfOrigin'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'countryOfOrigin'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-subject-country-of-origin-mz',
      properties: {
        label: 'Kraj pochodzenia',
        dictionaryName: 'COUNTRY_CODE',
        yupValidation: {
          required: true
        },
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'countryOfOrigin'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'countryOfOrigin'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_UPDATE]
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-nip',
      properties: {
        label: 'NIP',
        yupValidation: {
          required: true,
          length: 10
        },
        maxLength: 10,
        onlyPositiveInteger: true,
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'nip'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'nip'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_UPDATE]
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-regon',
      properties: {
        label: 'REGON',
        yupValidation: {
          required: true,
          length: 9
        },
        maxLength: 9,
        onlyPositiveInteger: true,
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'regon'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'regon'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_UPDATE]
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-krs',
      properties: {
        label: 'KRS',
        yupValidation: {
          notRequired: true,
          length: 10
        },
        maxLength: 10,
        onlyPositiveInteger: true,
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'krs'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'krs'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_UPDATE]
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-krs-required',
      properties: {
        label: 'KRS',
        yupValidation: {
          required: true,
          length: 10
        },
        maxLength: 10,
        onlyPositiveInteger: true,
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'krs'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'krs'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_UPDATE]
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-subject-legal-form-key',
      properties: {
        label: 'Charakter prawny',
        dictionaryName: 'LEGAL_NATURE',
        yupValidation: {
          required: true
        },
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'legalFormKey'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'legalFormKey'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_UPDATE]
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-name',
      properties: {
        label: 'Nazwa',
        yupValidation: {
          required: true
        },
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'name'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'name'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_UPDATE]
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-given-name',
      properties: {
        label: 'Imię',
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'givenName'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'givenName'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_UPDATE]
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-surname',
      properties: {
        label: 'Nazwisko',
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'surname'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'surname'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_UPDATE]
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-phone-number',
      properties: {
        label: 'Telefon',
        onlyPositiveInteger: true,
        maxLength: 9,
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'contact.phoneNumber'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            // request przyjmuje tablice w UPDATE_SUBJECT jest parsowanie, dlatego taki customowy typ
            accessor: 'contactAddresses.phone'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_UPDATE]
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-email',
      properties: {
        label: 'Email',
        yupValidation: {
          contextValidator: ValidatorEnums.EMAIL
        },
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'contact.email'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            // request przyjmuje tablice w UPDATE_SUBJECT jest parsowanie, dlatego taki customowy typ
            accessor: 'contactAddresses.email'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_UPDATE]
        }
      }
    },
    {
      typeKey: 'BOOLEAN',
      fieldId: 'application-subject-print-data-license',
      properties: {
        label: 'Jak adres główny',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'licenseData.isSameAsMainAddress'
          },
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'mainAddressOnLicense'
          }
        }
      }
    },
    {
      typeKey: 'BOOLEAN',
      fieldId: 'application-subject-print-data-permission',
      properties: {
        label: 'Jak adres główny',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'permissionData.isSameAsMainAddress'
          },
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'mainAddressOnPermission'
          }
        }
      }
    },
    {
      typeKey: 'NUMBER',
      fieldId: 'application-foreign-permissions-limit',
      properties: {
        label: 'Limit',
        yupValidation: {
          required: true
        },
        min: 0
      }
    },
    {
      typeKey: 'NUMBER',
      fieldId: 'application-foreign-permissions-number-of-permits',
      properties: {
        label: 'Liczba zamawianych zezwoleń',
        min: 0
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-foreign-permissions-comment',
      properties: {
        label: 'Uwagi',
        yupValidation: {
          max: 10
        }
      }
    },
    {
      typeKey: 'ENTREPRENEURS_COLLECTION',
      fieldId: 'application-entrepreneurs-collection'
    },
    {
      typeKey: 'PARTNER_APPLICANTS_COLLECTION',
      fieldId: 'application-partner_applicants_collection'
    },
    {
      typeKey: 'LICENSES_COLLECTION',
      fieldId: 'application-licenses-collection'
    },
    {
      typeKey: 'LICENSE_COPIES_COLLECTION',
      fieldId: 'application-license-copies-collection'
    },
    {
      typeKey: 'PERMISSION_COPIES_COLLECTION',
      fieldId: 'application-permissions-collection-copies',
      properties: {
        typeKeys: [PermissionTypeEnum.ROAD_CARRIER_GOODS_CARRIAGE, PermissionTypeEnum.ROAD_CARRIER_PASSENGER_CARRIAGE],
        titleKey: 'permissionGitdCopy',
        permissionType: PermissionTableEnum.PERMISSION_COPY
      }
    },
    {
      typeKey: 'PERMISSIONS_FOREIGN_COLLECTION',
      fieldId: 'application-foreign-permissions-collection'
    },
    {
      typeKey: 'DRIVER_CERTIFICATES_COLLECTION',
      fieldId: 'application-driver-certificates-collection'
    },
    {
      typeKey: 'PERMISSIONS_COLLECTION',
      fieldId: 'application-permissions-collection-gitd',
      properties: {
        initialParams: {
          isIssuedByExternalAuthority: false,
          typeKeyIn: [
            PermissionTypeEnum.ROAD_CARRIER_GOODS_CARRIAGE,
            PermissionTypeEnum.ROAD_CARRIER_PASSENGER_CARRIAGE
          ]
        },
        titleKey: 'gitd',
        visibleColumns: [
          PermissionsColumnsEnum.APPLICATION_NUMBER,
          PermissionsColumnsEnum.PERMISSION_NUMBER,
          PermissionsColumnsEnum.FORM_NUMBER,
          PermissionsColumnsEnum.DATE_OF_ISSUE_GITD,
          PermissionsColumnsEnum.DATE_OF_ISSUE_EXTERNAL,
          PermissionsColumnsEnum.SIGNER_NAME,
          PermissionsColumnsEnum.STATUS
        ],
        permissionType: PermissionTableEnum.GITD
      }
    },
    {
      typeKey: 'PERMISSION_COPIES_COLLECTION',
      fieldId: 'application-permission-copies-collection-copies',
      properties: {
        typeKeys: [PermissionTypeEnum.ROAD_CARRIER_GOODS_CARRIAGE, PermissionTypeEnum.ROAD_CARRIER_PASSENGER_CARRIAGE],
        titleKey: 'gitd',
        permissionType: PermissionTableEnum.GITD
      }
    },
    {
      typeKey: 'PERMISSIONS_COLLECTION',
      fieldId: 'application-permissions-collection-country-permissions',
      properties: {
        initialParams: {
          typeKeyIn: [
            PermissionTypeEnum.ROAD_CARRIER_GOODS_CARRIAGE,
            PermissionTypeEnum.ROAD_CARRIER_PASSENGER_CARRIAGE
          ],
          isIssuedByExternalAuthority: true
        },
        titleKey: 'country-permissions',
        permissionType: PermissionTableEnum.EXTERNAL_AUTHORITY_PERMISSION,
        headerActionsEnabled: ['create'],
        visibleColumns: [
          PermissionsColumnsEnum.APPLICATION_NUMBER,
          PermissionsColumnsEnum.PERMISSION_NUMBER,
          PermissionsColumnsEnum.DATE_OF_ISSUE,
          PermissionsColumnsEnum.VALID_TO,
          PermissionsColumnsEnum.ISSUING_AUTHORITY,
          PermissionsColumnsEnum.SUBJECT_NIP,
          PermissionsColumnsEnum.SUBJECT_NAME,
          PermissionsColumnsEnum.STATUS
        ],
        rowActionsEnabled: ['edit', 'delete', 'details']
      }
    },
    {
      typeKey: 'PERMISSIONS_COLLECTION',
      fieldId: 'application-foreign-authority-permissions-collection',
      properties: {
        initialParams: { isIssuedByForeignAuthority: true },
        visibleColumns: [
          PermissionsColumnsEnum.APPLICATION_NUMBER,
          PermissionsColumnsEnum.PERMISSION_NUMBER,
          PermissionsColumnsEnum.DATE_OF_ISSUE,
          PermissionsColumnsEnum.VALID_TO,
          PermissionsColumnsEnum.ISSUING_COUNTRY,
          PermissionsColumnsEnum.SUBJECT_NAME,
          PermissionsColumnsEnum.STATUS,
          PermissionsColumnsEnum.TRANSIT_STOP_END_TOUR,
          PermissionsColumnsEnum.TRANSIT_STOP_START_TOUR
        ],
        titleKey: 'foreign-authority-permissions',
        permissionType: PermissionTableEnum.FOREIGN_AUTHORITY_PERMISSION,
        headerActionsEnabled: ['create'],
        rowActionsEnabled: ['edit', 'delete', 'details']
      }
    },
    {
      typeKey: 'PERMISSIONS_COLLECTION',
      fieldId: 'application-permissions-collection-spo-eu',
      properties: {
        initialParams: {
          typeKeyIn: [PermissionTypeEnum.SPO_UE]
        },
        titleKey: 'spo-eu',
        permissionType: PermissionTableEnum.SPO_UE,
        visibleColumns: [
          PermissionsColumnsEnum.APPLICATION_NUMBER,
          PermissionsColumnsEnum.PERMISSION_NUMBER,
          PermissionsColumnsEnum.PERMISSION_COPY_NUMBER,
          PermissionsColumnsEnum.FORM_NUMBER,
          PermissionsColumnsEnum.DATE_OF_ISSUE,
          PermissionsColumnsEnum.VALID_FROM,
          PermissionsColumnsEnum.VALID_TO,
          PermissionsColumnsEnum.SIGNER_NAME,
          PermissionsColumnsEnum.STATUS,
          PermissionsColumnsEnum.TRANSIT_STOP_END_TOUR,
          PermissionsColumnsEnum.TRANSIT_STOP_START_TOUR
        ]
      }
    },
    {
      typeKey: 'PERMISSIONS_COLLECTION',
      fieldId: 'application-permissions-collection-spo-outside-eu',
      properties: {
        initialParams: {
          typeKeyIn: [PermissionTypeEnum.SPO_OUTSIDE_UE]
        },
        titleKey: 'spo-outside-eu',
        permissionType: PermissionTableEnum.SPO_OUTSIDE_UE,
        visibleColumns: [
          PermissionsColumnsEnum.APPLICATION_NUMBER,
          PermissionsColumnsEnum.PERMISSION_NUMBER,
          PermissionsColumnsEnum.PERMISSION_COPY_NUMBER,
          PermissionsColumnsEnum.FORM_NUMBER,
          PermissionsColumnsEnum.DATE_OF_ISSUE,
          PermissionsColumnsEnum.VALID_FROM,
          PermissionsColumnsEnum.VALID_TO,
          PermissionsColumnsEnum.SIGNER_NAME,
          PermissionsColumnsEnum.STATUS,
          PermissionsColumnsEnum.TRANSIT_STOP_END_TOUR,
          PermissionsColumnsEnum.TRANSIT_STOP_START_TOUR
        ]
      }
    },
    {
      typeKey: 'PERMISSIONS_COLLECTION',
      fieldId: 'application-permissions-collection-spo-shuttle',
      properties: {
        initialParams: {
          typeKeyIn: [PermissionTypeEnum.SPO_SHUTTLE]
        },
        titleKey: 'spo-shuttle',
        permissionType: PermissionTableEnum.SPO_SHUTTLE,
        visibleColumns: [
          PermissionsColumnsEnum.APPLICATION_NUMBER,
          PermissionsColumnsEnum.PERMISSION_NUMBER,
          PermissionsColumnsEnum.PERMISSION_COPY_NUMBER,
          PermissionsColumnsEnum.FORM_NUMBER,
          PermissionsColumnsEnum.DATE_OF_ISSUE,
          PermissionsColumnsEnum.VALID_FROM,
          PermissionsColumnsEnum.VALID_TO,
          PermissionsColumnsEnum.SIGNER_NAME,
          PermissionsColumnsEnum.STATUS,
          PermissionsColumnsEnum.TRANSIT_STOP_END_TOUR,
          PermissionsColumnsEnum.TRANSIT_STOP_START_TOUR
        ]
      }
    },
    {
      typeKey: 'PERMISSIONS_COLLECTION',
      fieldId: 'application-permissions-collection-spo-occasional',
      properties: {
        initialParams: {
          typeKeyIn: [PermissionTypeEnum.SPO_OCCASIONAL]
        },
        titleKey: 'spo-occasional',
        permissionType: PermissionTableEnum.SPO_OCCASIONAL,
        visibleColumns: [
          PermissionsColumnsEnum.APPLICATION_NUMBER,
          PermissionsColumnsEnum.PERMISSION_NUMBER,
          PermissionsColumnsEnum.PERMISSION_COPY_NUMBER,
          PermissionsColumnsEnum.FORM_NUMBER,
          PermissionsColumnsEnum.DATE_OF_ISSUE,
          PermissionsColumnsEnum.VALID_FROM,
          PermissionsColumnsEnum.VALID_TO,
          PermissionsColumnsEnum.SIGNER_NAME,
          PermissionsColumnsEnum.STATUS,
          PermissionsColumnsEnum.TRANSIT_STOP_END_TOUR,
          PermissionsColumnsEnum.TRANSIT_STOP_START_TOUR
        ]
      }
    },
    {
      typeKey: 'PERMISSIONS_COLLECTION',
      fieldId: 'application-permissions-collection-spo-transit',
      properties: {
        initialParams: {
          typeKeyIn: [PermissionTypeEnum.SPO_TRANSIT]
        },
        titleKey: 'spo-transit',
        permissionType: PermissionTableEnum.SPO_TRANSIT,
        visibleColumns: [
          PermissionsColumnsEnum.APPLICATION_NUMBER,
          PermissionsColumnsEnum.PERMISSION_NUMBER,
          PermissionsColumnsEnum.PERMISSION_COPY_NUMBER,
          PermissionsColumnsEnum.FORM_NUMBER,
          PermissionsColumnsEnum.DATE_OF_ISSUE,
          PermissionsColumnsEnum.VALID_FROM,
          PermissionsColumnsEnum.VALID_TO,
          PermissionsColumnsEnum.SIGNER_NAME,
          PermissionsColumnsEnum.STATUS,
          PermissionsColumnsEnum.TRANSIT_STOP_END_TOUR,
          PermissionsColumnsEnum.TRANSIT_STOP_START_TOUR
        ]
      }
    },
    {
      typeKey: 'PERMISSIONS_COLLECTION',
      fieldId: 'application-permissions-collection-own-needs',
      properties: {
        initialParams: {
          typeKeyIn: [PermissionTypeEnum.OWN_NEEDS_PASSENGER_CARRIAGE, PermissionTypeEnum.OWN_NEEDS_GOODS_CARRIAGE]
        },
        visibleColumns: [
          PermissionsColumnsEnum.APPLICATION_NUMBER,
          PermissionsColumnsEnum.CERTIFICATE_NUMBER,
          PermissionsColumnsEnum.DATE_OF_ISSUE,
          PermissionsColumnsEnum.VALID_FROM,
          PermissionsColumnsEnum.VALID_TO,
          PermissionsColumnsEnum.SIGNER_NAME,
          PermissionsColumnsEnum.STATUS
        ],
        titleKey: 'own-needs',
        permissionType: PermissionTableEnum.OWN_NEEDS
      }
    },
    {
      typeKey: 'PERMISSION_COPIES_COLLECTION',
      fieldId: 'application-permission-copies-collection-own-needs',
      properties: {
        typeKeys: [PermissionTypeEnum.OWN_NEEDS_GOODS_CARRIAGE, PermissionTypeEnum.OWN_NEEDS_PASSENGER_CARRIAGE],
        visibleColumns: [
          PermissionCopiesColumnsEnum.APPLICATION_NUMBER,
          PermissionCopiesColumnsEnum.CERTIFICATE_NUMBER,
          PermissionCopiesColumnsEnum.PERMISSION_COPY_NUMBER,
          PermissionCopiesColumnsEnum.DATE_OF_ISSUE,
          PermissionCopiesColumnsEnum.VALID_FROM,
          PermissionCopiesColumnsEnum.VALID_TO,
          PermissionCopiesColumnsEnum.SIGNER_NAME,
          PermissionCopiesColumnsEnum.STATUS,
          PermissionCopiesColumnsEnum.TRANSIT_TYPE
        ],
        titleKey: 'own-needs',
        permissionType: PermissionTableEnum.OWN_NEEDS_COPY
      }
    },
    {
      typeKey: 'PERMISSION_COPIES_COLLECTION',
      fieldId: 'application-permission-copies-collection-spo-eu',
      properties: {
        typeKeys: [PermissionTypeEnum.SPO_UE],
        titleKey: 'spo-eu',
        permissionType: PermissionTableEnum.SPO_UE_COPY
      }
    },
    {
      typeKey: 'PERMISSION_COPIES_COLLECTION',
      fieldId: 'application-permission-copies-collection-spo-outside-eu',
      properties: {
        typeKeys: [PermissionTypeEnum.SPO_OUTSIDE_UE],
        titleKey: 'spo-outside-eu',
        permissionType: PermissionTableEnum.SPO_OUTSIDE_UE_COPY
      }
    },
    {
      typeKey: 'PERMISSION_COPIES_COLLECTION',
      fieldId: 'application-permission-copies-collection-spo-shuttle',
      properties: {
        typeKeys: [PermissionTypeEnum.SPO_SHUTTLE],
        titleKey: 'spo-shuttle',
        permissionType: PermissionTableEnum.SPO_SHUTTLE_COPY
      }
    },
    {
      typeKey: 'PERMISSION_COPIES_COLLECTION',
      fieldId: 'application-permission-copies-collection-spo-occasional',
      properties: {
        typeKeys: [PermissionTypeEnum.SPO_OCCASIONAL],
        titleKey: 'spo-occasional',
        permissionType: PermissionTableEnum.SPO_OCCASIONAL_COPY
      }
    },
    {
      typeKey: 'PERMISSION_COPIES_COLLECTION',
      fieldId: 'application-permission-copies-collection-spo-transit',
      properties: {
        typeKeys: [PermissionTypeEnum.SPO_TRANSIT],
        titleKey: 'spo-transit',
        permissionType: PermissionTableEnum.SPO_TRANSIT_COPY
      }
    },
    {
      typeKey: 'FINANCIAL_SECURITY_COLLECTION',
      fieldId: 'application-financial-security-collection'
    },
    {
      typeKey: 'NUMBER',
      fieldId: 'application-financial-security-less-than-3_5t-vehicles-count',
      properties: {
        min: 0,
        label: 'Liczba pojazdów <= 3.5t',
        yupValidation: {
          required: true
        },
        api: {
          get: {
            requestKey: 'GET_FINANCIAL_SECURITY',
            accessor: 'foreignPermissionVehiclesNumberBelowThreeAndHalfTons'
          },
          submit: {
            requestKey: 'UPDATE_FINANCIAL_SECURITY',
            accessor: 'foreignPermissionVehiclesNumberBelowThreeAndHalfTons'
          }
        }
      }
    },
    {
      typeKey: 'NUMBER',
      fieldId: 'application-financial-security-vehicles-count',
      properties: {
        min: 0,
        label: 'Liczba pojazdów',
        yupValidation: {
          required: true
        },
        api: {
          get: {
            requestKey: 'GET_FINANCIAL_SECURITY',
            accessor: 'foreignPermissionVehiclesNumber'
          },
          submit: {
            requestKey: 'UPDATE_FINANCIAL_SECURITY',
            accessor: 'foreignPermissionVehiclesNumber'
          }
        }
      }
    },
    {
      typeKey: 'NUMBER',
      fieldId: 'application-financial-security-3_5t-vehicles-count',
      properties: {
        min: 0,
        label: 'Liczba pojazdów > 3.5t',
        yupValidation: {
          required: true
        },
        api: {
          get: {
            requestKey: 'GET_FINANCIAL_SECURITY',
            accessor: 'foreignPermissionVehiclesNumberAboveThreeAndHalfTons'
          },
          submit: {
            requestKey: 'UPDATE_FINANCIAL_SECURITY',
            accessor: 'foreignPermissionVehiclesNumberAboveThreeAndHalfTons'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-financial-security-vehicles-version',
      properties: {
        api: {
          get: {
            requestKey: 'GET_FINANCIAL_SECURITY',
            accessor: 'version'
          },
          submit: {
            requestKey: 'UPDATE_FINANCIAL_SECURITY',
            accessor: 'version'
          }
        },
        hidden: true
      }
    },
    {
      typeKey: 'VEHICLES_COLLECTION',
      fieldId: 'application-vehicles-collection'
    },
    {
      typeKey: 'PROXIES_COLLECTION',
      fieldId: 'application-proxies-collection'
    },
    {
      typeKey: 'REPUTATION_COLLECTION',
      fieldId: 'application-reputation-collection'
    },
    {
      typeKey: 'MONEY_TRANSFER_COLLECTION',
      fieldId: 'application-money-transfer-collection',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_MONEY_TRANSFER',
            accessor: 'moneyTransfer'
          }
        }
      }
    },
    {
      typeKey: 'ATTACHMENTS_COLLECTION',
      fieldId: 'application-attachments-collection',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_ATTACHMENTS',
            accessor: 'attachments'
          }
        }
      }
    },
    {
      typeKey: 'PERMISSIONS_MATRIX_COLLECTION',
      fieldId: 'application-permissions-matrix-collection',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_PERMISSION_MATRIX',
            accessor: 'permissions'
          }
        }
      }
    },
    {
      typeKey: 'ATTACHMENTS_MATRIX_COLLECTION',
      fieldId: 'application-attachments-matrix-collection',
      properties: {
        api: {
          get: {
            requestKey: 'GET_ATTACHMENTS'
          },
          submit: {
            requestKey: 'UPDATE_ATTACHMENTS_MATRIX',
            accessor: 'attachments'
          }
        }
      }
    },
    {
      typeKey: 'EXPLOITATION_BASES_COLLECTION',
      fieldId: 'application-exploitation-base-collection'
    },
    {
      typeKey: 'TRANSPORT_MANAGERS_COLLECTION',
      fieldId: 'application-transport-managers-collection'
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-proxies-count',
      properties: {
        label: 'Liczba pełnomocników',
        inputMode: InputMode.VIEW,
        api: {
          get: {
            requestKey: 'GET_PRICING_DETAILS',
            accessor: 'proxyDetails.proxyNumber'
          }
        }
      }
    },
    {
      typeKey: 'BOOLEAN',
      fieldId: 'application-proxy-paid',
      properties: {
        label: 'Pełnomocnictwo opłacone',
        inputMode: InputMode.VIEW,
        api: {
          get: {
            requestKey: 'GET_PRICING_DETAILS',
            accessor: 'proxyDetails.proxyPaid'
          }
        },
        validation: {
          mode: FormErrorType.WARNING,
          text: 'Opłata za pełnomocnika nie została wniesiona',
          conditions: [
            {
              conditionKey: 'CHECK_PROXIES_PAYMENT',
              params: ['proxyDetails.proxyNumber', 'proxyDetails.proxyPaid']
            }
          ]
        }
      }
    },
    {
      typeKey: 'QUOTATIONS_COLLECTION',
      fieldId: 'application-quotations-collection'
    },
    {
      typeKey: 'NOTE_COLLECTION',
      fieldId: 'application-note-collection'
    },
    {
      typeKey: 'FILES_COLLECTION',
      fieldId: 'application-files-collection'
    },
    {
      typeKey: 'APPLICATION_HISTORY_COLLECTION',
      fieldId: 'application-history-collection'
    },
    {
      typeKey: 'WARNINGS_COLLECTION',
      fieldId: 'application-warnings'
    },
    {
      typeKey: 'FINANCIAL_SECURITY_EXCHANGE_COLLECTION',
      fieldId: 'application-financial-security-exchange-fields'
    },
    {
      typeKey: 'TRANSIT_SCHEDULE',
      fieldId: 'application-transit-schedule-collection',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_TRANSIT_SCHEDULE_FREQUENCIES'
          }
        }
      }
    },
    {
      typeKey: 'DRIVERS_COLLECTION',
      fieldId: 'application-drivers-collection'
    },
    {
      typeKey: 'SCERTIFICATES_COLLECTION',
      fieldId: 'application-scertificates-collection',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_SCERTIFICATES'
          }
        }
      }
    },
    {
      typeKey: 'SCERTIFICATES_APPLICATION_SECTION',
      fieldId: 'application-scertificates-application-section',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_SCERTIFICATES'
          }
        }
      }
    },
    // '': 'Zakładka wnioskowane zezwolenia'
    {
      typeKey: 'TEXT',
      fieldId: 'foreignPermissionApplicationDetails-typeKey',
      properties: {
        hidden: true,
        api: {
          get: {
            requestKey: 'GET_APPLICATION_FOREIGN_PERMISSIONS_TYPE'
          }
        }
      }
    },
    {
      typeKey: 'BOOLEAN',
      fieldId: 'foreignPermissionApplicationDetails-association',
      properties: {
        label: 'Wniosek dla Stowarzyszenia',
        api: {
          get: {
            requestKey: 'GET_FOLDER_DETAILS',
            accessor: 'association'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    // '': 'Zakładka wnioskowane zezwolenia - koniec'

    // '': 'Zakładka dokumenty wychodzące '
    {
      typeKey: 'DOCUMENTS_COLLECTION',
      fieldId: 'application-documents-collection'
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-decision-version',
      properties: {
        hidden: true,
        api: {
          submit: {
            requestKey: 'UPDATE_CREATE_APPLICATION_DECISION',
            accessor: 'version'
          },
          get: {
            requestKey: 'GET_APPLICATION_DECISION',
            accessor: 'version'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-statusKey',
      properties: {
        hidden: true,
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'statusKey'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-folder-typeKey',
      properties: {
        hidden: true,
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'folder.typeKey'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-send-to-address-flag',
      properties: {
        hidden: true,
        api: {
          get: {
            requestKey: 'GET_APPLICATION_FLAGS',
            accessor: 'sendToAddressRequirementType'
          }
        }
      }
    },
    {
      typeKey: 'DATETIME',
      fieldId: 'application-decision-permissionExpirationDate',
      properties: {
        label: 'Termin wykonywania uprawnień',
        yupValidation: {
          required: {}
        },
        api: {
          submit: {
            requestKey: 'UPDATE_CREATE_APPLICATION_DECISION',
            accessor: 'permissionExpirationDate'
          },
          get: {
            requestKey: 'GET_APPLICATION_DECISION',
            accessor: 'permissionExpirationDate'
          }
        }
      }
    },

    //  'Zakładka dokumenty wychodzące - koniec'

    //   'Zakładka zezwolenia jednorazowe'

    {
      typeKey: 'SINGLE_PERMIT_COLLECTION',
      fieldId: 'application-single-foreign-permit-collection',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_SPO_FOREIGN_PERMISSION_RECORDS',
            accessor: 'records'
          }
        }
      }
    },
    // '': 'Zakładka zezwolenia jednorazowe - koniec'
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-receipt-type-key-scertificate',
      properties: {
        label: 'Sposób odbioru',
        dictionaryName: 'RECEIPT_TYPE',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_SCERTIFICATES',
            accessor: 'receiptTypeKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'receiptTypeKey'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-send-to-address-type-key-scertificate',
      properties: {
        api: {
          submit: {
            requestKey: 'UPDATE_SCERTIFICATES',
            accessor: 'sendToAddressTypeKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'sendToAddressTypeKey'
          }
        },
        hidden: true
      }
    },
    {
      typeKey: 'SELECT_ADDRESS_TYPE_FIELD',
      fieldId: 'application-send-to-address-required-scertificate',
      properties: {
        label: 'Adres do wysyłki',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_SCERTIFICATES',
            accessor: 'newSendToAddressTypeKey',
            formAccessor: 'value.typeKey'
          },
          fetch: {
            requestKey: 'GET_APPLICATION_ADDRESSES'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'sendToAddress'
          }
        },
        permission: {
          form: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_SHIPPING_ADDRESS_CHOOSE]
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-send-to-address-name-scertificate',
      properties: {
        label: 'Imię odbierającego',
        api: {
          submit: {
            requestKey: 'UPDATE_SCERTIFICATES',
            accessor: 'recipientName'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'recipientName'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-send-to-address-surname-scertificate',
      properties: {
        label: 'Nazwisko odbierającego',
        api: {
          submit: {
            requestKey: 'UPDATE_SCERTIFICATES',
            accessor: 'recipientSurname'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'recipientSurname'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-permissionDuplicate-causeDependentOnSubjectKey',
      properties: {
        label: 'Przyczyna wydania wtórnika',
        dictionaryName: 'DUPLICATE_CAUSE_DEPENDENT_ON_SUBJECT',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'permissionUpdateRequest.permissionUpdateRequest.causeDependentOnSubjectKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'permissionApplicationDetails.permissionApplicationDetails.causeDependentOnSubjectKey'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-description',
      properties: {
        label: 'Opis popełnionego błędu',
        yupValidation: {
          required: true
        },
        lines: 5,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'misspellApplicationDataUpdateRequest.description'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'misspellApplicationDataDetails.description'
          }
        }
      }
    },
    {
      typeKey: 'DATETIME',
      fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-dateOfIssue',
      properties: {
        label: 'Data wpływu',
        yupValidation: {
          required: {}
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'misspellApplicationDataUpdateRequest.dateOfIssue'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'misspellApplicationDataDetails.dateOfIssue'
          }
        }
      }
    },
    {
      typeKey: 'BOOLEAN',
      fieldId: 'licenseDataUpdateRequest-additionalPermissionCreationApplied',
      properties: {
        label: 'Wydanie zezwolenia',
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.additionalPermissionCreationApplied'
          },
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.additionalPermissionCreationApplied'
          }
        },
        defaultInitialValue: false
      }
    }
  ],
  components: [
    {
      componentId: 'license-application-details',
      node: {
        typeKey: 'SECTION',
        properties: {
          title: 'Wniosek / Terminy'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-folder-typeKey'
            }
          },
          {
            typeKey: 'GRID',
            properties: {
              gridItemProps: {
                xs: 12,
                sm: 6
              }
            },
            nodes: [
              {
                slotId: 'license-application-details-additional-fields'
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-folder-typeKey',
                  value: {
                    includes: ['ibtm-domain.folder.type.tp']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId:
                        'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumberBelowThreeAndHalfTons'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-folder-typeKey',
                  value: {
                    includes: ['ibtm-domain.folder.type.tp']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId:
                        'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumberAboveThreeAndHalfTons'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-folder-typeKey',
                  value: {
                    includes: ['ibtm-domain.folder.type.tp']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId:
                        'licenseApplicationDetails-commonLicenseApplicationData-copiesNumberBelowThreeAndHalfTons'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-folder-typeKey',
                  value: {
                    includes: ['ibtm-domain.folder.type.tp']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId:
                        'licenseApplicationDetails-commonLicenseApplicationData-copiesNumberAboveThreeAndHalfTons'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-folder-typeKey',
                  value: {
                    includes: ['ibtm-domain.folder.type.op']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumber'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-folder-typeKey',
                  value: {
                    includes: ['ibtm-domain.folder.type.op']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'licenseApplicationDetails-commonLicenseApplicationData-copiesNumber'
                    }
                  }
                ]
              },
              {
                slotId: 'license-application-details-commonLicenseApplicationData-permissionCopiesNumber'
              }
            ]
          }
        ]
      }
    },
    {
      componentId: 'application-permissions-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'PERMISSIONS'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-licenses-collection'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-permissions-collection-gitd'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-license-copies-collection'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-permissions-collection-copies'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-driver-certificates-collection'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-permissions-collection-spo-eu'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-permission-copies-collection-spo-eu'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-permissions-collection-spo-outside-eu'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-permission-copies-collection-spo-outside-eu'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-permissions-collection-spo-shuttle'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-permission-copies-collection-spo-shuttle'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-permissions-collection-spo-occasional'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-permission-copies-collection-spo-occasional'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-permissions-collection-spo-transit'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-permission-copies-collection-spo-transit'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-foreign-permissions-collection'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-permissions-collection-own-needs'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-permission-copies-collection-own-needs'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-permissions-collection-country-permissions'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-foreign-authority-permissions-collection'
            }
          }
        ]
      }
    },
    {
      componentId: 'application-subject-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'SUBJECT'
        },
        nodes: [
          {
            typeKey: 'GRID',
            properties: {
              gridContainerProps: {
                spacing: 0
              },
              gridItemProps: {
                xs: 12
              }
            },
            nodes: [
              {
                typeKey: 'SECTION',
                properties: {
                  title: 'Dane podstawowe'
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-subject-version'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-folder-typeKey'
                    }
                  },
                  {
                    typeKey: 'GRID',
                    properties: {
                      gridItemProps: {
                        xs: 12,
                        sm: 6
                      }
                    },
                    nodes: [
                      {
                        typeKey: 'GRID',
                        properties: {
                          gridContainerProps: {
                            xs: 12,
                            item: true
                          },
                          gridItemProps: {
                            xs: 6
                          }
                        },
                        nodes: [
                          {
                            typeKey: 'FIELD',
                            properties: {
                              fieldId: 'application-subject-type-key'
                            }
                          }
                        ]
                      },
                      {
                        typeKey: 'CONDITIONALLY_VISIBLE',
                        properties: {
                          dependendFieldId: 'application-folder-typeKey',
                          value: {
                            notIncludes: ['ibtm-domain.folder.type.mz']
                          }
                        },
                        nodes: [
                          {
                            typeKey: 'FIELD',
                            properties: {
                              fieldId: 'application-subject-country-of-origin'
                            }
                          }
                        ]
                      },
                      {
                        typeKey: 'CONDITIONALLY_VISIBLE',
                        properties: {
                          dependendFieldId: 'application-folder-typeKey',
                          value: {
                            includes: ['ibtm-domain.folder.type.mz']
                          }
                        },
                        nodes: [
                          {
                            typeKey: 'FIELD',
                            properties: {
                              fieldId: 'application-subject-country-of-origin-mz'
                            }
                          }
                        ]
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-subject-nip'
                        }
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-subject-name'
                        }
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-subject-regon'
                        }
                      },
                      {
                        typeKey: 'CONDITIONALLY_VISIBLE',
                        properties: {
                          dependendFieldId: 'application-subject-legal-form-key',
                          value: {
                            notIncludes: ['ibtm-domain.legal-nature.7']
                          }
                        },
                        nodes: [
                          {
                            typeKey: 'FIELD',
                            properties: {
                              fieldId: 'application-subject-krs-required'
                            }
                          }
                        ]
                      },
                      {
                        typeKey: 'CONDITIONALLY_VISIBLE',
                        properties: {
                          dependendFieldId: 'application-subject-legal-form-key',
                          value: {
                            includes: ['ibtm-domain.legal-nature.7']
                          }
                        },
                        nodes: [
                          {
                            typeKey: 'FIELD',
                            properties: {
                              fieldId: 'application-subject-krs'
                            }
                          }
                        ]
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-subject-legal-form-key'
                        }
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-subject-given-name'
                        }
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-subject-surname'
                        }
                      }
                    ]
                  }
                ]
              },
              {
                typeKey: 'SECTION',
                properties: {
                  title: 'Dane kontaktowe'
                },
                nodes: [
                  {
                    typeKey: 'GRID',
                    properties: {
                      gridItemProps: {
                        xs: 12,
                        sm: 6
                      }
                    },
                    nodes: [
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-subject-phone-number'
                        }
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-subject-email'
                        }
                      }
                    ]
                  }
                ]
              },
              {
                typeKey: 'FIELD',
                properties: {
                  fieldId: 'application-entrepreneurs-collection'
                }
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-folder-typeKey',
                  value: {
                    includes: ['ibtm-domain.folder.type.tp', 'ibtm-domain.folder.type.op']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-reputation-collection'
                    }
                  }
                ]
              },
              {
                slotId: 'application-section-end'
              }
            ]
          }
        ]
      }
    },
    {
      componentId: 'application-addresses-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'ADDRESSES'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-addresses-tab'
            }
          }
        ]
      }
    },
    {
      componentId: 'application-proxies-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'PROXIES',
          isHeader: false
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-proxies-collection'
            }
          }
        ]
      }
    },
    {
      componentId: 'application-attachments-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'ATTACHMENTS'
        },
        nodes: [
          {
            typeKey: 'GRID',
            properties: {
              gridItemProps: {
                xs: 12
              }
            },
            nodes: [
              {
                typeKey: 'FIELD',
                properties: {
                  fieldId: 'application-attachments-collection'
                }
              }
            ]
          }
        ]
      }
    },
    {
      componentId: 'application-financial-security-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'FINANCIAL_SECURITY'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-folder-typeKey'
            }
          },
          {
            typeKey: 'CONDITIONALLY_VISIBLE',
            properties: {
              dependendFieldId: 'application-folder-typeKey',
              value: {
                notIncludes: ['ibtm-domain.folder.type.ppo']
              }
            },
            nodes: [
              {
                typeKey: 'SECTION',
                properties: {
                  title: 'Pojazdy zgłoszone do zezwolenia wydanego przez organ zewnętrzny',
                  id: 'application-financial-security-section-vehicles-submitted-for-authorization'
                },
                nodes: [
                  {
                    typeKey: 'GRID',
                    properties: {
                      gridItemProps: {
                        sm: 6,
                        xs: 12
                      }
                    },
                    nodes: [
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-financial-security-less-than-3_5t-vehicles-count'
                        }
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-financial-security-3_5t-vehicles-count'
                        }
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-financial-security-vehicles-count'
                        }
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-financial-security-vehicles-version'
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-financial-security-collection'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-financial-security-exchange-fields'
            }
          }
        ]
      }
    },
    {
      componentId: 'application-transport-managers-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'TRANSPORT_MANAGERS',
          isHeader: false
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-transport-managers-collection'
            }
          }
        ]
      }
    },
    {
      componentId: 'application-vehicles-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'VEHICLES',
          isHeader: false
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-vehicles-collection'
            }
          }
        ]
      }
    },
    {
      componentId: 'application-vehicles-tab-only-new-editable',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'VEHICLES',
          isHeader: false
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-vehicles-collection',
              editValidator: VehicleTableEditionVisibleValidator.ONLY_NEW
            }
          }
        ]
      }
    },
    {
      componentId: 'application-limited-vehicles-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'VEHICLES',
          isHeader: false
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-vehicles-collection',
              hiddenActions: [
                'ADD_VEHICLE',
                'GENERATE_RAPORT',
                'COPY_ALL_VEHICLES',
                'DELETE_ALL_VEHICLES',
                'EDIT',
                'SHOW_DETAIL'
              ]
            }
          }
        ]
      }
    },
    {
      componentId: 'application-without-add-and-edit-vehicles-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'VEHICLES',
          isHeader: false
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-vehicles-collection',
              hiddenActions: ['ADD_VEHICLE', 'EDIT']
            }
          }
        ]
      }
    },
    {
      componentId: 'application-exploitation-base-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'EXPLOITATION_BASE',
          isHeader: false
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-exploitation-base-collection'
            }
          }
        ]
      }
    },
    {
      componentId: 'application-quotation-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'QUOTATION'
        },
        nodes: [
          {
            typeKey: 'SECTION',
            properties: {
              title: 'Pełnomocnicy',
              visibility: {
                elementVisibilityAsyncKeys: ['IS_PROXIES_QUOTATION_SECTION_VISIBLE']
              }
            },
            nodes: [
              {
                typeKey: 'GRID',
                properties: {
                  gridItemProps: {
                    sm: 6,
                    xs: 12
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-proxies-count'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-proxy-paid'
                    }
                  }
                ]
              }
            ]
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-quotations-collection'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-money-transfer-collection'
            }
          }
        ]
      }
    },
    {
      componentId: 'application-outgoing-documents-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'OUTGOING_DOCUMENTS',
          isHeader: true,
          headerVisiblity: {
            elementVisibilityKeys: ['OUTGOING_DOCUMENTS_HEADER_VISIBILITY']
          }
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-statusKey'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-documents-collection'
            }
          },
          {
            typeKey: 'CONDITIONALLY_VISIBLE',
            properties: {
              dependendFieldId: 'application-statusKey',
              value: {
                includes: [
                  'ibtm-domain.application.status.preparing-decision-draft',
                  'ibtm-domain.application.status.released-waiting-for-zpo',
                  'ibtm-domain.application.status.pending-decision',
                  'ibtm-domain.application.status.pending-print',
                  'ibtm-domain.application.status.pending-issue',
                  'ibtm-domain.application.status.issued'
                ]
              }
            },
            nodes: [
              {
                slotId: 'outgoing-documents-additional-section'
              },
              {
                slotId: 'proceeding-section'
              }
            ]
          }
        ]
      }
    },
    {
      componentId: 'application-note-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'NOTE',
          isHeader: false
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-note-collection'
            }
          }
        ]
      }
    },
    {
      componentId: 'application-files-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'FILES',
          isHeader: false
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-files-collection'
            }
          }
        ]
      }
    },
    {
      componentId: 'application-history-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'HISTORY',
          isHeader: false
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-history-collection'
            }
          }
        ]
      }
    },
    {
      componentId: 'application-attachments-matrix-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'ATTACHMENTS'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-attachments-matrix-collection'
            }
          }
        ]
      }
    },
    {
      componentId: 'application-partner-applicants-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'PARTNER_APPLICANTS'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-partner_applicants_collection'
            }
          }
        ]
      }
    },
    {
      componentId: 'application-transit-schedules-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'TRANSIT_SCHEDULES'
        },
        nodes: []
      }
    },
    {
      componentId: 'application-cabotage-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'CABOTAGE'
        },
        nodes: [
          {
            typeKey: 'SECTION',
            properties: {
              title: 'Kraje kabotażu'
            },
            nodes: [
              {
                typeKey: 'GRID',
                properties: {
                  gridItemProps: {
                    xs: 12
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'cabotage-country'
                    }
                  }
                ]
              }
            ]
          },
          {
            typeKey: 'SECTION',
            properties: {
              title: 'Uwagi kabotażu'
            },
            nodes: [
              {
                typeKey: 'GRID',
                properties: {
                  gridItemProps: {
                    xs: 12
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'cabotage-remarks'
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    },
    {
      componentId: 'application-subject-print-data-license-section',
      node: {
        typeKey: 'CONDITIONALLY_VISIBLE',
        properties: {
          dependendFieldId: 'application-subject-legal-form-key',
          value: {
            includes: ['ibtm-domain.legal-nature.6', 'ibtm-domain.legal-nature.d']
          }
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-folder-typeKey'
            }
          },
          {
            typeKey: 'CONDITIONALLY_VISIBLE',
            properties: {
              dependendFieldId: 'application-folder-typeKey',
              value: {
                notIncludes: ['ibtm-domain.folder.type.ppo']
              }
            },
            nodes: [
              {
                typeKey: 'SECTION',
                properties: {
                  title: 'Wydruk - dane do licencji/wypisu z licencji'
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-license-address-fields'
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    },
    {
      componentId: 'application-subject-print-data-permission-section',
      node: {
        typeKey: 'CONDITIONALLY_VISIBLE',
        properties: {
          dependendFieldId: 'application-subject-legal-form-key',
          value: {
            includes: ['ibtm-domain.legal-nature.6', 'ibtm-domain.legal-nature.d']
          }
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-folder-typeKey'
            }
          },
          {
            typeKey: 'CONDITIONALLY_VISIBLE',
            properties: {
              dependendFieldId: 'application-folder-typeKey',
              value: {
                notIncludes: ['ibtm-domain.folder.type.ppo']
              }
            },
            nodes: [
              {
                typeKey: 'SECTION',
                properties: {
                  title: 'Wydruk - dane do zezwolenia na wykonywanie zawodu przewoźnika drogowego / wypisu z zezwolenia'
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-permission-address-fields',
                      isSubjectNipFieldVisible: true
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    },
    {
      componentId: 'application-general-tab-delivery-channel-section-requested-permits',
      node: {
        typeKey: 'SECTION',
        properties: {
          title: 'Sposób odbioru'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-send-to-address-flag'
            }
          },
          {
            typeKey: 'GRID',
            properties: {
              gridItemProps: {
                xs: 12,
                md: 6
              }
            },
            nodes: [
              {
                typeKey: 'FIELD',
                properties: {
                  fieldId: 'application-receipt-type-key-requested-permits'
                }
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-send-to-address-flag',
                  value: {
                    includes: ['PO', 'PO_PK']
                  }
                },
                nodes: [
                  {
                    typeKey: 'CONDITIONALLY_VISIBLE',
                    properties: {
                      dependendFieldId: 'application-receipt-type-key-requested-permits',
                      value: {
                        notIncludes: [
                          'ibtm-domain.receipt.type.pickup-in-person',
                          'ibtm-domain.receipt.type.e-mail',
                          'ibtm-domain.receipt.type.epuap'
                        ]
                      }
                    },
                    nodes: [
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-send-to-address-type-key-requested-permits'
                        }
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-send-to-address-required-requested-permits'
                        }
                      }
                    ]
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-send-to-address-flag',
                  value: {
                    notIncludes: ['PO', 'PO_PK']
                  }
                },
                nodes: [
                  {
                    typeKey: 'CONDITIONALLY_VISIBLE',
                    properties: {
                      dependendFieldId: 'application-receipt-type-key-requested-permits',
                      value: {
                        notIncludes: ['ibtm-domain.receipt.type.pickup-in-person', 'ibtm-domain.receipt.type.e-mail']
                      }
                    },
                    nodes: [
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-send-to-address-not-required-requested-permits'
                        }
                      }
                    ]
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key-requested-permits',
                  value: {
                    includes: ['ibtm-domain.receipt.type.pickup-in-person']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-send-to-address-name-requested-permits'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key-requested-permits',
                  value: {
                    includes: ['ibtm-domain.receipt.type.pickup-in-person']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-send-to-address-surname-requested-permits'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key-requested-permits',
                  value: {
                    includes: ['ibtm-domain.receipt.type.e-mail']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-subject-email-view'
                    }
                  }
                ]
              },
              {
                slotId: 'additional-field'
              }
            ]
          }
        ]
      }
    },
    {
      componentId: 'application-general-tab-delivery-channel-section',
      node: {
        typeKey: 'SECTION',
        properties: {
          title: 'Sposób odbioru'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-send-to-address-flag'
            }
          },
          {
            typeKey: 'GRID',
            properties: {
              gridItemProps: {
                xs: 12,
                md: 6
              }
            },
            nodes: [
              {
                typeKey: 'FIELD',
                properties: {
                  fieldId: 'application-receipt-type-key'
                }
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-send-to-address-flag',
                  value: {
                    includes: ['PO', 'PO_PK']
                  }
                },
                nodes: [
                  {
                    typeKey: 'CONDITIONALLY_VISIBLE',
                    properties: {
                      dependendFieldId: 'application-receipt-type-key',
                      value: {
                        notIncludes: [
                          'ibtm-domain.receipt.type.pickup-in-person',
                          'ibtm-domain.receipt.type.e-mail',
                          'ibtm-domain.receipt.type.epuap'
                        ]
                      }
                    },
                    nodes: [
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-send-to-address-type-key'
                        }
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-send-to-address-required'
                        }
                      }
                    ]
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-send-to-address-flag',
                  value: {
                    notIncludes: ['PO', 'PO_PK']
                  }
                },
                nodes: [
                  {
                    typeKey: 'CONDITIONALLY_VISIBLE',
                    properties: {
                      dependendFieldId: 'application-receipt-type-key',
                      value: {
                        notIncludes: ['ibtm-domain.receipt.type.pickup-in-person', 'ibtm-domain.receipt.type.e-mail']
                      }
                    },
                    nodes: [
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-send-to-address-type-key'
                        }
                      },
                      {
                        typeKey: 'FIELD',
                        properties: {
                          fieldId: 'application-send-to-address-notRequired'
                        }
                      }
                    ]
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key',
                  value: {
                    includes: ['ibtm-domain.receipt.type.pickup-in-person']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-send-to-address-name'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key',
                  value: {
                    includes: ['ibtm-domain.receipt.type.pickup-in-person']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-send-to-address-surname'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key',
                  value: {
                    includes: ['ibtm-domain.receipt.type.e-mail']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-subject-email-view'
                    }
                  }
                ]
              },
              {
                slotId: 'additional-field'
              }
            ]
          }
        ]
      }
    },
    {
      componentId: 'application-general-tab-delivery-channel-section-scertificate',
      node: {
        typeKey: 'SECTION',
        properties: {
          title: 'Sposób odbioru'
        },
        nodes: [
          {
            typeKey: 'GRID',
            properties: {
              gridItemProps: {
                xs: 12,
                md: 6
              }
            },
            nodes: [
              {
                typeKey: 'FIELD',
                properties: {
                  fieldId: 'application-receipt-type-key-scertificate'
                }
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key-scertificate',
                  value: {
                    notIncludes: ['ibtm-domain.receipt.type.pickup-in-person', 'ibtm-domain.receipt.type.e-mail']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-send-to-address-type-key-scertificate'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-send-to-address-required-scertificate'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key-scertificate',
                  value: {
                    includes: ['ibtm-domain.receipt.type.pickup-in-person']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-send-to-address-name-scertificate'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key-scertificate',
                  value: {
                    includes: ['ibtm-domain.receipt.type.pickup-in-person']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-send-to-address-surname-scertificate'
                    }
                  }
                ]
              },
              {
                typeKey: 'CONDITIONALLY_VISIBLE',
                properties: {
                  dependendFieldId: 'application-receipt-type-key-scertificate',
                  value: {
                    includes: ['ibtm-domain.receipt.type.e-mail']
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-subject-email-view'
                    }
                  }
                ]
              },
              {
                slotId: 'additional-field'
              }
            ]
          }
        ]
      }
    },
    {
      componentId: 'application-transit-schedule-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'TRANSIT_SCHEDULES'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-transit-schedule-collection'
            }
          }
        ]
      }
    },
    {
      componentId: 'application-general-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'GENERAL'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-warnings'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-version'
            }
          },
          {
            typeKey: 'SECTION',
            properties: {
              title: 'Wniosek'
            },
            nodes: [
              {
                typeKey: 'GRID',
                properties: {
                  gridItemProps: {
                    xs: 12,
                    sm: 6
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-submission-date'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-submission-channel-key'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-expected-completion-date'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-folder'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-created-date'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-author'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-paper-version-wwu-date'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-end-date'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-last-supplement-receipt-date'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-concern-license'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-locked-by-application'
                    }
                  },
                  {
                    slotId: 'application-section'
                  }
                ]
              }
            ]
          },
          {
            typeKey: 'COMPONENT',
            properties: {
              componentId: 'application-general-tab-delivery-channel-section'
            }
          },
          {
            typeKey: 'SECTION',
            properties: {
              title: 'Decyzje / Wyroki sądu',
              id: 'court-decisions-judgments-section'
            },
            nodes: [
              {
                typeKey: 'GRID',
                properties: {
                  gridItemProps: {
                    xs: 12,
                    sm: 6
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-is-review-application'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-second-tier-decision-key'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-second-tier-decision-date'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-wsa-complaint'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-wsa-decision-key'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-wsa-decision-date'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-final-decision'
                    }
                  }
                ]
              }
            ]
          },
          {
            slotId: 'additional-section'
          }
        ]
      }
    },
    {
      componentId: 'application-spo-general-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'GENERAL'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-warnings'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-version'
            }
          },
          {
            typeKey: 'SECTION',
            properties: {
              title: 'Wniosek'
            },
            nodes: [
              {
                typeKey: 'FIELD',
                properties: {
                  fieldId: 'application-statusKey'
                }
              },
              {
                typeKey: 'GRID',
                properties: {
                  gridItemProps: {
                    xs: 12,
                    sm: 6
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-folder-number'
                    }
                  },
                  {
                    slotId: 'application-section-after-folder-number'
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-subject-name-view'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-categoryKey'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-typeKey'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-number'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-archiveNumber'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-paperNumber'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-submission-date'
                    }
                  },
                  {
                    slotId: 'application-section-after-sumission-date'
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-created-date'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-author'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-locked-by-application'
                    }
                  },
                  {
                    slotId: 'application-section-end'
                  }
                ]
              }
            ]
          },
          {
            typeKey: 'SECTION',
            properties: {
              title: 'Wniosek / Terminy'
            },
            nodes: [
              {
                typeKey: 'GRID',
                properties: {
                  gridItemProps: {
                    xs: 12,
                    sm: 6
                  }
                },
                nodes: [
                  {
                    slotId: 'application-terms-section'
                  }
                ]
              }
            ]
          },
          {
            typeKey: 'SECTION',
            properties: {
              title: 'Powiązane wnioski'
            },
            nodes: [
              {
                typeKey: 'GRID',
                properties: {
                  gridItemProps: {
                    sm: 12
                  }
                },
                nodes: [
                  {
                    slotId: 'application-associated-applications-section'
                  }
                ]
              }
            ]
          },
          {
            typeKey: 'COMPONENT',
            properties: {
              componentId: 'application-general-tab-delivery-channel-section',
              id: 'hide-delivery-channel-section'
            }
          }
        ]
      }
    },
    {
      componentId: 'application-spo-own-needs-general-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'GENERAL'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-warnings'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-version'
            }
          },
          {
            typeKey: 'SECTION',
            properties: {
              title: 'Wniosek'
            },
            nodes: [
              {
                typeKey: 'GRID',
                properties: {
                  gridItemProps: {
                    xs: 12,
                    sm: 6
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-folder-number'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-subject-name-view'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-categoryKey'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-typeKey'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-number'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-archiveNumber'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-submission-date'
                    }
                  },
                  {
                    slotId: 'application-section-after-submission-date'
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'permissionApplicationDetails-personalNeedsApplicationDetails-transportTypeKey'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'permissionApplicationDetails-personalNeedsApplicationDetails-employmentStatement'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'permissionApplicationDetails-personalNeedsApplicationDetails-vehicles'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'permissionApplicationDetails-personalNeedsApplicationDetails-validityPeriodKey'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'permissionApplicationDetails-personalNeedsApplicationDetails-issuer'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-created-date'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-author'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'permissionApplicationDetails-personalNeedsApplicationDetails-term'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-locked-by-application'
                    }
                  },
                  {
                    slotId: 'application-section-end'
                  }
                ]
              }
            ]
          },
          {
            typeKey: 'SECTION',
            properties: {
              title: 'Powiązane wnioski'
            },
            nodes: [
              {
                typeKey: 'GRID',
                properties: {
                  gridItemProps: {
                    xs: 12,
                    sm: 6
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-linked-spo-applications'
                    }
                  }
                ]
              }
            ]
          },
          {
            typeKey: 'COMPONENT',
            properties: {
              componentId: 'application-general-tab-delivery-channel-section',
              id: 'hide-delivery-channel-section'
            }
          }
        ]
      }
    },
    {
      componentId: 'application-foreign-permissions-single-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'FOREIGN_PERMISSIONS_SINGLE'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-single-foreign-permit-collection'
            }
          }
        ]
      }
    },
    {
      componentId: 'application-drivers-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'DRIVERS',
          isHeader: false
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-drivers-collection'
            }
          }
        ]
      }
    },
    {
      componentId: 'application-scertificates-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'SCERTIFICATES'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-warnings'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-scertificates-application-section'
            }
          },
          {
            typeKey: 'COMPONENT',
            properties: {
              componentId: 'application-general-tab-delivery-channel-section-scertificate'
            }
          },
          {
            slotId: 'additional-section'
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-scertificates-collection'
            }
          }
        ]
      }
    },
    {
      componentId: 'application-requested-permits-tab',
      node: {
        typeKey: 'TAB_CONTENT',
        properties: {
          id: 'REQUESTED_PERMITS'
        },
        nodes: [
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-warnings'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-version-requested-permits'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'application-submission-date-hidden-requested-permits'
            }
          },
          {
            typeKey: 'SECTION',
            properties: {
              isCollapsable: false
            },
            nodes: [
              {
                typeKey: 'GRID',
                properties: {
                  gridItemProps: {
                    xs: 12,
                    sm: 6
                  }
                },
                nodes: [
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'foreignPermissionApplicationDetails-association'
                    }
                  },
                  {
                    typeKey: 'FIELD',
                    properties: {
                      fieldId: 'application-folder-typeKey'
                    }
                  }
                ]
              }
            ]
          },
          {
            typeKey: 'COMPONENT',
            properties: {
              componentId: 'application-general-tab-delivery-channel-section-requested-permits'
            }
          },
          {
            typeKey: 'FIELD',
            properties: {
              fieldId: 'foreignPermissionApplicationDetails-typeKey'
            }
          },
          {
            typeKey: 'CONDITIONALLY_VISIBLE',
            properties: {
              dependendFieldId: 'foreignPermissionApplicationDetails-typeKey',
              value: {
                includes: ['ibtm-domain.foreign-permit-type.ekmt-permits']
              }
            },
            nodes: [
              {
                typeKey: 'FIELD',
                properties: {
                  fieldId: 'application-permissions-matrix-collection',
                  resourceType: 'ibtm-domain.resource.form-type.single-permit'
                }
              }
            ]
          },
          {
            typeKey: 'CONDITIONALLY_VISIBLE',
            properties: {
              dependendFieldId: 'foreignPermissionApplicationDetails-typeKey',
              value: {
                includes: ['ibtm-domain.foreign-permit-type.single-permits']
              }
            },
            nodes: [
              {
                typeKey: 'FIELD',
                properties: {
                  fieldId: 'application-permissions-matrix-collection',
                  resourceType: 'ibtm-domain.resource.form-type.single-permit'
                }
              }
            ]
          }
        ]
      }
    },
    {
      componentId: 'application-misspell-description-and-date-of-issue-section',
      node: {
        typeKey: 'SECTION',
        properties: {
          isCollapsable: false
        },
        nodes: [
          {
            typeKey: 'GRID',
            properties: {
              gridItemProps: {
                xs: 12,
                sm: 6
              }
            },
            nodes: [
              {
                typeKey: 'FIELD',
                properties: {
                  fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-description'
                }
              },
              {
                typeKey: 'FIELD',
                properties: {
                  fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-dateOfIssue'
                }
              }
            ]
          }
        ]
      }
    }
  ],
  layout: []
};
export default definition;
