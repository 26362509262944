import { useTranslation } from 'react-i18next';
import { DefaultApiCreateMyReplacementRequest } from '@avispon/group';
import { useSnackbar } from '@enigma/fe-ui';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '@libs/user/api';

interface IMutationParams {
  setIsOpen: (value: boolean) => void;
  refetch: () => void;
}

function createReplacements(params: DefaultApiCreateMyReplacementRequest) {
  return API.group.createMyReplacement({ ...params }, { ...endpointsConfig.createMyReplacement });
}

function useCreateReplacementsMutation({ setIsOpen, refetch }: IMutationParams) {
  const [t] = useTranslation();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  return useMutation(createReplacements, {
    onSuccess: () => {
      showSuccessSnackbar(t('user:message.createReplacement'));
      setIsOpen(false);
      refetch();
    },
    onError: () => {
      showErrorSnackbar(t('user:error.createReplacement'));
      setIsOpen(false);
      refetch();
    }
  });
}

export default useCreateReplacementsMutation;
