import { useTableLayoutProps } from '@libs/common/v2';

import { useDocumentTemplateVersionsQuery } from '@libs/document-template/api';
import { useDocumentTemplateVersionColumns } from '@libs/document-template/hooks';

function useDocumentTemplateVersionsTable(id: string) {
  const { columns, mappedFilterFields } = useDocumentTemplateVersionColumns();

  return useTableLayoutProps({
    tableHookQuery: useDocumentTemplateVersionsQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'modified', desc: false }]
        }
      }
    },
    tableHookQueryInitialParams: {
      documentTemplateId: [id]
    }
  });
}

export default useDocumentTemplateVersionsTable;
