import { useCallback, useEffect, useMemo } from 'react';
import { FieldNamesMarkedBoolean, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormMode, useConfirmDialog, useFormV2Context, useViewModesV2 } from '@libs/common/v2';

interface IUseConditionSectionProps {
  name: FieldPath<FieldValues>;
  queryName: FieldPath<FieldValues>;
  formMode: FormMode;
  basicModeName: string;
  queryDefinitionsColumnsName?: string;
  dirtyFields?: FieldNamesMarkedBoolean<Record<string, any>>;
}

function useAlertConditionsSection({
  name,
  queryName,
  formMode,
  dirtyFields,
  basicModeName
}: IUseConditionSectionProps) {
  const [t] = useTranslation();
  const { getValues, setValue, watch } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);

  const [confirm] = useConfirmDialog();
  const queryConditionsField = getValues(name);
  const queryField = getValues(queryName);
  const basicMode = watch(basicModeName);

  useEffect(() => {
    if (basicMode === undefined || basicMode === null) {
      setValue(basicModeName, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const noConditionsDefined = useMemo(
    () => !queryConditionsField?.group?.length && !queryConditionsField?.filter,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formMode, queryConditionsField]
  );

  const confirmDataLoss = (dialogParams: { title: string; message: string }) => {
    return new Promise<void>((resolve, reject) => {
      confirm({
        ...dialogParams,
        onConfirm: (setConfirmLoading, closeDialog) => {
          closeDialog();
          resolve();
        },
        onCancel: reject,
        confirmType: 'danger'
      });
    });
  };

  const handleModeChange = useCallback(
    async (enableBasicMode: boolean) => {
      if (enableBasicMode === basicMode) return;
      if (!viewMode) {
        if (!enableBasicMode) {
          if (getValues(`queryConditions[0].group`)?.length) {
            await confirmDataLoss({
              title: t('alerts:sqlField.dataLossWarning'),
              message: t('alerts:sqlField.sqlModeWarning')
            });
          }
          setValue('queryConditions[0].group', []);
          setValue('queryDefinition.columns', []);
        } else {
          if (queryField?.length) {
            await confirmDataLoss({
              title: t('alerts:sqlField.dataLossWarning'),
              message: t('alerts:sqlField.basicModeWarning')
            });
          }

          setValue(queryName, null);
        }
      }
      setValue(basicModeName, enableBasicMode);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [basicMode, dirtyFields, queryField]
  );

  return { basicMode, noConditionsDefined, handleModeChange };
}

export default useAlertConditionsSection;
