import { useMemo } from 'react';
import _ from 'lodash';

import { useSubjectPermissionsQuery } from '@libs/domain/folder';

import Licence from './Licence';

function LicencePartnerDetails({ subjectId }: { subjectId: string }) {
  const { isLoading, data } = useSubjectPermissionsQuery(subjectId);

  const shouldRenderLicences = useMemo(() => {
    return !isLoading && _.size(data);
  }, [isLoading, data]);

  return shouldRenderLicences ? (
    <>
      {data.map(licence => (
        <Licence key={licence.id} licence={licence} />
      ))}
    </>
  ) : null;
}

export default LicencePartnerDetails;
