import { AdditionalFilterEnum, useTableLayoutProps } from '@libs/common/v2';

import { useDomainConfigContext } from '@libs/domain/config';
import { LimitTableEnum, useEKMTQuery, useLimitColumns } from '@libs/domain/foreign-permission';

function useEKMTTable({ queryInitialParams }: { queryInitialParams: { typeKeyIn: string[] } }) {
  const { yearFilterContextValues, setYearFilterContextValues } = useDomainConfigContext();
  const { columns, mappedFilterFields, mappedSortFields, initialParamsMapper } = useLimitColumns([
    'folderNumber',
    'subjectName',
    'limit'
  ]);

  const globalFilters = [
    {
      type: AdditionalFilterEnum.YEAR_SELECT,
      name: LimitTableEnum.YEAR,
      setGlobalYearSelect: setYearFilterContextValues
    }
  ];

  return useTableLayoutProps({
    tableHookQuery: useEKMTQuery,
    tableHookOptions: {
      columns,
      initialParamsConverter: initialParamsMapper,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    },
    globalFilters,
    tableHookQueryInitialParams: {
      [LimitTableEnum.YEAR]: yearFilterContextValues?.value,
      ...queryInitialParams
    }
  });
}

export default useEKMTTable;
