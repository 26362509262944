import { MoneyTransferApplicationRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

type Params = {
  applicationId: string;
  formData: MoneyTransferApplicationRequest;
};

function deleteMoneyTransfersFromApplication({ applicationId, formData }: Params) {
  return API.moneyTransfer.deleteMoneyTransfersFromApplication(applicationId, formData, {
    ...endpointsConfig.deleteMoneyTransfersFromApplication
  });
}

function useDeleteMoneyTransfersFromApplication() {
  return useMutation(deleteMoneyTransfersFromApplication);
}

export default useDeleteMoneyTransfersFromApplication;
