import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Theme } from '@libs/common/v2/theme';

import { DictionaryEntryNameEnum } from '@libs/dictionary';

import MatrixCell from './MatrixCell';
import { IMatrixCell, TMatrixRow } from './model';

interface IProps {
  cells: IMatrixCell[];
  row?: TMatrixRow;
  className?: string;
  isHeader?: boolean;
  formPrefix?: string;
  dictionary?: DictionaryEntryNameEnum;
  isWhiteBackground?: boolean;
}

function MatrixRow({ cells, row, isHeader, formPrefix, dictionary, isWhiteBackground, className }: IProps) {
  const classes = useStyles({ isWhiteBackground });

  return (
    <div className={clsx('flex items-stretch align-center', classes.root, className)}>
      {cells.map((cell, index) => (
        <MatrixCell
          key={cell.name?.id ?? cell.name?.title ?? index}
          cell={cell}
          dictionary={dictionary}
          formPrefix={formPrefix}
          isWhiteBackground={isWhiteBackground}
          isHeaderCell={isHeader}
          isLastCell={cells.length === index + 1}
          row={row}
          className={cell.style}
        />
      ))}
    </div>
  );
}

const useStyles = makeStyles<Theme, { isWhiteBackground?: boolean }>(theme => ({
  root: {
    backgroundColor: ({ isWhiteBackground }) => (isWhiteBackground ? theme.palette.white : theme.palette.grey[50])
  }
}));

export default MatrixRow;
