import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PluginHook, Row } from 'react-table';
import { useSnackbar } from '@enigma/fe-ui';
import { PermissionCopySnapshot as PermissionCopySnapshotClientDomain } from '@ibtm/client-domain';
import { PermissionCopySnapshot, PermissionCopySnapshotPage } from '@ibtm/domain';
import { AxiosResponse } from 'axios';
import { isNil } from 'lodash';
import { object as YupObject, string as YupString } from 'yup';

import { partialTranslate, TranslationFrom } from '@libs/common';
import { CamelCasePath, TableLayout } from '@libs/common/v2';
import { isDateValid } from '@libs/common/v2/form/validation';
import { getCalendarDate } from '@libs/common/v2/utils';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { PermissionTableEnum } from '../../application';
import { useUpdatePermissionCopyMutation } from '../api';
import { PERMISSION_COPIES_DEFAULT_COLUMNS, usePermissionCopiesTable } from '../hooks';
import { PermissionCopiesColumnsEnum, PermissionCopySnapshotClient, PermissionTypeEnum } from '../model';
import { parsePermissionCopiesList } from '../parsers';

export interface IPermissionCopiesTable {
  applicationId?: string;
  folderId?: string;
  typeKeys?: PermissionTypeEnum[];
  titleKey: TranslationFrom<'permission:title.permissionCopies'>;
  visibleColumns?: Array<CamelCasePath<PermissionCopySnapshotClient>>;
  editableColumns?: Array<CamelCasePath<PermissionCopySnapshotClient>>;
  headerActions?: ReactNode;
  rowActions?: (row: Row<PermissionCopySnapshotClient>) => ReactNode;
  tablePlugins?: Array<PluginHook<PermissionCopySnapshotClient>>;
  tableData?: PermissionCopySnapshotClient[];
  actionsColumnWidth?: number;
  isRefreshEnabled?: boolean;
  isLoading?: boolean;
  isTableMutable?: boolean;
  permissionType?: PermissionTableEnum;
  includePartnershipPermissions?: boolean;
  isTableDataVisible?: boolean;
  isHeaderActions?: boolean;
  validFromColumnHeader?: string;
}

function PermissionCopiesTable({
  applicationId,
  folderId,
  typeKeys,
  titleKey,
  visibleColumns = PERMISSION_COPIES_DEFAULT_COLUMNS,
  editableColumns,
  headerActions,
  rowActions,
  tablePlugins = [],
  actionsColumnWidth,
  tableData,
  isRefreshEnabled,
  isLoading,
  isTableMutable,
  includePartnershipPermissions,
  isTableDataVisible = true,
  isHeaderActions = true,
  validFromColumnHeader
}: IPermissionCopiesTable) {
  const getTitle = partialTranslate('permission:title.permissionCopies');
  const tableProps = usePermissionCopiesTable(
    visibleColumns,
    applicationId,
    folderId,
    typeKeys,
    editableColumns,
    tablePlugins,
    tableData,
    isTableMutable,
    includePartnershipPermissions,
    isTableDataVisible,
    validFromColumnHeader
  );
  const { getQuery } = useGetApiQueryByPerspective();
  const title = useMemo(() => getTitle(titleKey), [titleKey, getTitle]);
  const [t] = useTranslation();
  const { mutate: updatePermissionCopy } = useUpdatePermissionCopyMutation();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbar();

  const getValidationScheme = () => {
    const isColumnEditable = name => editableColumns?.includes(name);

    return YupObject({
      ...(isColumnEditable(PermissionCopiesColumnsEnum.FORM_NUMBER) && {
        [PermissionCopiesColumnsEnum.FORM_NUMBER]: YupString().required().nullable()
      }),
      ...(isColumnEditable(PermissionCopiesColumnsEnum.PRINT_DATE) && {
        [PermissionCopiesColumnsEnum.PRINT_DATE]: YupString().required().nullable().concat(isDateValid())
      })
    });
  };

  const onRowEdit = (data: PermissionCopySnapshotClient, { onSuccess }) => {
    const { version, formNumber, printDate, validFrom, validTo } = data;
    return updatePermissionCopy(
      {
        id: data.id,
        version,
        formNumber,
        printDate: getCalendarDate(printDate),
        validFrom: getCalendarDate(validFrom),
        validTo: getCalendarDate(validTo)
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('success.save'));
          onSuccess();
        },
        onError: () => showErrorSnackbar(t('global:error.dataSaveFailure'))
      }
    );
  };

  const parseXlsxRequest = (response: AxiosResponse<PermissionCopySnapshotPage>) => ({
    ...response,
    data: { ...response.data, content: parsePermissionCopiesList(response.data.content) }
  });

  const hasTableData = useMemo(() => !isNil(tableData), [tableData]);

  const xlsxTableDataDownloadQuery = async () => ({ data: { content: tableData } });

  const xlsxlDownloadQuery = getQuery(
    async params => {
      const response = await API.client.permissions.getPermissionCopiesPage('', '', params);
      return parseXlsxRequest(response as AxiosResponse<PermissionCopySnapshotPage>);
    },
    async params => {
      const response = await API.permissions.getPermissionCopiesPage(params);
      return parseXlsxRequest(response);
    }
  );

  return (
    <TableLayout<PermissionCopySnapshotClient, PermissionCopySnapshot | PermissionCopySnapshotClientDomain>
      {...tableProps}
      pageTitle={title}
      xlsxDownload={{
        fileName: title,
        pathToXLSXTranslation: 'permission:field',
        apiRequest: hasTableData ? xlsxTableDataDownloadQuery : xlsxlDownloadQuery
      }}
      headerActions={isHeaderActions && headerActions}
      actionsColumnWidth={actionsColumnWidth}
      rowActions={row => rowActions?.(row)}
      onRowEditCreateConfirm={onRowEdit}
      editFormYupResolverSchema={getValidationScheme()}
      isActionColumnEnabled={Boolean(rowActions)}
      isRefreshEnabled={isRefreshEnabled}
      isLoading={isLoading}
      isTableMutable
      isCollapsable
      isSection
    />
  );
}

export default PermissionCopiesTable;
