import { getInitialYear, useTableLayoutProps } from '@libs/common/v2';

import { useSocialCommissionEKMTQuery } from '@libs/domain/social-commission';
import { IntialYearType } from '@libs/domain/social-commission/components/SocialCommissionPage';

import { SocialCommissionTableEnum } from '../../components';
import { useSocialCommissionEKMTColumns } from '../columns/useSocialCommissionEKMTColumns';

function useSocialCommissionEKMTTable(initialYear: IntialYearType) {
  const { columns, mappedFilterFields, mappedSortFields } = useSocialCommissionEKMTColumns();

  const year = initialYear || getInitialYear();
  const initialParams = {
    [SocialCommissionTableEnum.YEAR_EXACT]: year.value
  };

  return useTableLayoutProps({
    tableHookQuery: useSocialCommissionEKMTQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    },
    tableHookQueryInitialParams: initialParams
  });
}

export default useSocialCommissionEKMTTable;
