import { MisspellApplicationDataReprintListUpdateRequest } from '@ibtm/domain/dist/models';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export const updateApplicationPermissionsReprint = ({
  applicationId,
  misspellApplicationDataReprintListUpdateRequest
}: {
  applicationId: string;
  misspellApplicationDataReprintListUpdateRequest: MisspellApplicationDataReprintListUpdateRequest;
}) =>
  API.applicationMisspellData.updateApplicationPermissionsReprintFlag(
    applicationId,
    misspellApplicationDataReprintListUpdateRequest,
    {
      ...endpointsConfig.updateApplicationPermissionsReprintFlag
    }
  );

function useUdateApplicationPermissionsReprintMutation() {
  return useMutation(updateApplicationPermissionsReprint);
}

export default useUdateApplicationPermissionsReprintMutation;
