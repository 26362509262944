import { IConfig, IRoute } from '../router/RouterContext';

export function setRoutes(config: IConfig) {
  if (config.settings) {
    return config.routes.map(route => {
      return {
        ...route,
        settings: { ...config.settings },
        auth: params => {
          const arr = route.auth?.(params) || null;
          return arr;
        }
      };
    });
  }

  return [...config.routes];
}

export function generateRoutesFromConfigs(configs: IConfig[]): IRoute[] {
  return configs.reduce((allRoutes: any[], config) => {
    allRoutes.push(...setRoutes(config));
    return allRoutes;
  }, []);
}
