import React from 'react';
import { Column, Hooks } from 'react-table';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

import { i18n } from '@libs/common';
import { Checkbox, ISpecialColumnEnum, ITableAdapter, useTableAdapter, useTableContext } from '@libs/common/v2';

interface IChecboxTooltip {
  headerTooltip?: string;
  headerDisabledTooltip?: string;
  cellTooltip?: string;
  cellDisabledTooltip?: string;
}

const getMultipleSameValueRowSelect = <T extends Record<string, any>, K extends Record<string, any> = T>(
  selectionActionRender: () => React.ReactNode,
  headerDisabledFunction: (
    headers: ITableAdapter<T, K>['headers'],
    state?: ITableAdapter<T, K>['state'],
    getToggleAllRowsSelectedProps?: ITableAdapter<T, K>['getToggleAllRowsSelectedProps']
  ) => boolean,
  cellDisabledFunction: (
    row: unknown,
    state?: ITableAdapter<T, K>['state'],
    rows?: ITableAdapter<T, K>['rows']
  ) => boolean,
  checboxTooltipTitles: IChecboxTooltip
): Column => {
  return {
    id: ISpecialColumnEnum.SELECTION,
    Header: ({ state, headers, getToggleAllRowsSelectedProps }: any) => {
      const {
        headerDisabledTooltip = i18n.t('action.disabled'),
        headerTooltip = i18n.t('other:component.table.body.checkAllRows')
      } = checboxTooltipTitles;
      const table = useTableAdapter();
      const { checked, indeterminate } = getToggleAllRowsSelectedProps();
      const { checkDisabled, isError, isLoading, isFetching } = useTableContext();

      const selectedIds = Object.keys(state.selectedRowIds);
      const headerCheckDisabled = headerDisabledFunction(headers, state, getToggleAllRowsSelectedProps);
      const classes = useStyles();

      return (
        <Tooltip
          disableHoverListener={(!headerDisabledTooltip && !headerTooltip) || !!selectedIds.length}
          title={headerCheckDisabled ? headerDisabledTooltip : headerTooltip}
        >
          <span className="flex items-center">
            <Checkbox
              {...getToggleAllRowsSelectedProps()}
              isDisabled={isLoading || isFetching || headerCheckDisabled || checkDisabled(table) || isError}
              className={classes.checkbox}
              onClick={e => e.stopPropagation()}
              inputProps={{
                'aria-label': i18n.t('other:component.table.body.checkAllRows')
              }}
              isChecked={checked}
              isIndeterminate={indeterminate}
            />
            {!_.isEmpty(selectedIds) && selectionActionRender()}
          </span>
        </Tooltip>
      );
    },
    Cell: ({ state, rows, row }: any) => {
      const {
        cellDisabledTooltip = i18n.t('action.disabled'),
        cellTooltip = i18n.t('other:component.table.body.checkRow')
      } = checboxTooltipTitles;
      const cellCheckDisabled = cellDisabledFunction(row, state, rows);
      const table = useTableAdapter();
      const { checkDisabled, isError, isLoading, isFetching } = useTableContext();
      const { indeterminate, checked } = row.getToggleRowSelectedProps();
      const classes = useStyles();

      return (
        <Tooltip
          disableHoverListener={!cellDisabledTooltip && !cellTooltip}
          title={cellCheckDisabled ? cellDisabledTooltip : cellTooltip}
        >
          <span className="flex items-center">
            <Checkbox
              {...row.getToggleRowSelectedProps()}
              isDisabled={isLoading || isFetching || cellCheckDisabled || checkDisabled(table, row) || isError}
              className={classes.checkbox}
              onClick={e => e.stopPropagation()}
              inputProps={{
                'aria-label': i18n.t('other:component.table.body.checkRow')
              }}
              isChecked={checked}
              isIndeterminate={indeterminate}
            />
          </span>
        </Tooltip>
      );
    },
    disableSortBy: true
  };
};

const useStyles = makeStyles({
  checkbox: {
    padding: 10
  }
});

export default function useMultipleSameValueRowSelect<T extends Record<string, any>, K extends Record<string, any> = T>(
  selectionActionRender: () => React.ReactNode,
  /**
   * funkcja do ustawiający checkbox w header w stan disabled/enabled
   */
  headerDisabledFunction: (
    headers: ITableAdapter<T, K>['headers'],
    state?: ITableAdapter<T, K>['state'],
    getToggleAllRowsSelectedProps?: ITableAdapter<T, K>['getToggleAllRowsSelectedProps']
  ) => boolean,
  /**
   * funkcja decydująca czy checkbox w rzędzie ma być disabled/enabled
   */
  cellDisabledFunction: (
    row: unknown,
    state?: ITableAdapter<T, K>['state'],
    rows?: ITableAdapter<T, K>['rows']
  ) => boolean,
  /**
   * Optional object to configure tooltip titles on checkboxes
   */
  checboxTooltipTitles?: IChecboxTooltip
) {
  const multipleSameValueRowSelect = <T extends Record<string, any> = Record<string, any>>(hooks: Hooks<T>) => {
    hooks.visibleColumns.push(columns => [
      getMultipleSameValueRowSelect(
        selectionActionRender,
        headerDisabledFunction,
        cellDisabledFunction,
        checboxTooltipTitles ?? {}
      ),
      ...columns
    ]);
  };

  multipleSameValueRowSelect.pluginName = 'useMultipleSameValueRowSelect';

  return multipleSameValueRowSelect;
}
