import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AccountSnapshotStatus } from '@avispon/user';
import { useSnackbar } from '@enigma/fe-ui';
import { IAppConfigContext } from '@libs/app-config';
import qs from 'qs';
import { AnyAction, Dispatch } from 'redux';

import {
  SplashScreen,
  TErrorMessage,
  useRouter,
  useSystemNotificationContext,
  useSystemParametersContext
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { getLocalStorageItem } from '@libs/common/v2/utils';

import { AutoLogoutWarning } from '@libs/auth/components';
import { AutoLogoutDefaultConfig } from '@libs/auth/configs';
import { AuthEventsEnum } from '@libs/auth/models/auth-events.model';
import { AuthService } from '@libs/auth/services';
import { getUserData, GetUserStateTypeEnum, sendLogoutMsgToChannel } from '@libs/auth/store/actions';

import { useAuth, useAutoLogout } from '../hooks';
import { AutoLogoutStorageItemEnum } from '../models';
import { useAuthSelector } from '../store/reducers';

interface Props {
  onAppConfigLoaded?: (dispatch: Dispatch) => void;
  onLogin?: () => void;
  onLogout?: () => void;
  autoLogin?: boolean;
  onNewErrorMessage?: (error: TErrorMessage) => void;
  ignoredCodes?: string[];
  platform: string;
  getUserStateType?: GetUserStateTypeEnum;
  appConfig: IAppConfigContext;
  children: (data: { permissions: unknown[]; isLoggedIn: boolean }) => JSX.Element;
}

const getOverriddenAutoLogoutTime = () => {
  return Number(getLocalStorageItem(AutoLogoutStorageItemEnum.OVERRIDDEN_TIME));
};

function AuthProvider({
  children,
  onAppConfigLoaded,
  autoLogin,
  onLogin,
  onNewErrorMessage,
  onLogout,
  ignoredCodes = [],
  platform,
  getUserStateType,
  appConfig
}: Props) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const dispatch = useDispatch() as Dispatch;
  const { showSnackbar } = useSnackbar();
  const { sessionValidityDuration } = useSystemParametersContext();
  const isAuthInitialized = useRef(false);
  const {
    logout,
    isLoggedIn,
    user: { permissions },
    loginSSO
  } = useAuth();
  const { location, navigate } = useRouter();
  const isDisabledAutoLogout = useRef(false);

  const { setAutoLogout, time, setOnLogoutFn } = useAutoLogout({ showSnackbar });
  setOnLogoutFn(onLogout);

  const [waitAuthCheck, setWaitAuthCheck] = useState<boolean>(true);

  const { user } = useAuthSelector(({ auth }) => auth);

  useEffect(() => {
    const userStatus = user?.accountData?.status;
    if (userStatus === AccountSnapshotStatus.BLOCKED) {
      logout(queryCache);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const systemNotificationContext = useSystemNotificationContext();
  const handleAutoLogin = (config?: IAppConfigContext['config']) => {
    const { disableAutoLogout } = config?.app ?? {};
    dispatch(getUserData([], null, getUserStateType) as unknown as AnyAction)
      .then(() => {
        onLogin?.();
      })
      .finally(() => {
        setWaitAuthCheck(false);
      });

    if (!disableAutoLogout) {
      return setAutoLogout(
        (getOverriddenAutoLogoutTime() || sessionValidityDuration?.value) ??
          config?.app?.autoLogoutMinutesTime ??
          AutoLogoutDefaultConfig.logoutAfter
      );
    }

    return null;
  };

  const handleAutoLogout = (message: string) => {
    if (message) {
      showSnackbar('success', message);
    }

    let redirectUrl: string = null;

    if (!['/login', '/error-page'].includes(location?.pathname)) {
      redirectUrl = location?.pathname as string;
    }

    const { tab, ...query } = qs.parse(window.location?.search ?? '', {
      ignoreQueryPrefix: true
    }) as { [_: string]: string; tab: string };

    logout(queryCache, {
      redirectUrl,
      redirectSearch: qs.stringify({ ...query, tab })
    });
    setWaitAuthCheck(false);
    onLogout?.();
  };

  const handleNoAccessToken = () => {
    setWaitAuthCheck(false);

    if (location.pathname === '/') {
      navigate('/login');
    }
  };

  const handleAutoLogoutWarning = () => {
    const layoutRenderingDelayInSeconds = 10;
    showSnackbar('warning', t('global:message.nearAutoLogoutWarning'), AutoLogoutWarning, {
      autoHideDuration: (AuthService.secondsBeforeSessionEndingWarningDisplay + layoutRenderingDelayInSeconds) * 1000
    });
  };

  const handleLogin = (config?) => {
    setWaitAuthCheck(false);
    onLogin?.();
    navigate('/login');

    setAutoLogout(
      (getOverriddenAutoLogoutTime() || sessionValidityDuration?.value) ??
        config?.app?.autoLogoutMinutesTime ??
        AutoLogoutDefaultConfig.logoutAfter
    );
  };
  const showErrorToastr = (message: string, duration?: number): void => {
    showSnackbar('error', message, null, { autoHideDuration: duration || 6000 });
  };

  const showWarningToastr = (message: string, duration?: number): void => {
    showSnackbar('warning', message, null, { autoHideDuration: duration || 6000 });
  };

  const showInfoToastr = (message: string, duration?: number): void => {
    showSnackbar('info', message, null, { autoHideDuration: duration || 6000 });
  };

  useEffect(() => {
    if (sessionValidityDuration?.value && !appConfig.isFetching) {
      const { config } = appConfig;
      const { disableAutoLogout } = config?.app ?? {};
      if (!disableAutoLogout && (time !== sessionValidityDuration?.value || !time)) {
        setAutoLogout(
          (getOverriddenAutoLogoutTime() || sessionValidityDuration?.value) ??
            config?.app?.autoLogoutMinutesTime ??
            AutoLogoutDefaultConfig.logoutAfter
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, appConfig, sessionValidityDuration, time]);

  useEffect(() => {
    let active = true;
    let onAutoLoginCleanup = null;
    let onLoginCleanup = null;
    const loginAutoLogoutCleanup: () => void = null;

    if (!isAuthInitialized.current) {
      if (active && !appConfig.isFetching) {
        const { config } = appConfig;
        const { disableAutoLogout } = config?.app ?? {};
        isAuthInitialized.current = true;
        isDisabledAutoLogout.current = disableAutoLogout;

        const uInt32Array = new Uint32Array(10);
        const sessionId = window.crypto.getRandomValues(uInt32Array).join('');

        sessionStorage.setItem('sessionId', sessionId);

        AuthService.setLogoutBroadcast(e => {
          if (e.data !== sessionId) handleAutoLogout(t('login:messages.success.logoutOtherTab'));
        });
        onAutoLoginCleanup = () => {
          handleAutoLogin(config);
        };
        onLoginCleanup = () => {
          handleLogin(config);
        };
        AuthService.on(AuthEventsEnum.AUTO_LOGIN, onAutoLoginCleanup);
        AuthService.on(AuthEventsEnum.LOGIN, onLoginCleanup);
        AuthService.on(AuthEventsEnum.AUTO_LOGOUT_WARNING, handleAutoLogoutWarning);
        AuthService.on(AuthEventsEnum.LOGOUT, () => {
          onLogout?.();
        });

        if (!disableAutoLogout) {
          AuthService.on(AuthEventsEnum.AUTO_LOGOUT, (msg: string) => {
            sendLogoutMsgToChannel();
            handleAutoLogout(msg);
            setTimeout(() => loginSSO(), 800);
          });
        }
        AuthService.on(AuthEventsEnum.NO_ACCESS_TOKEN, handleNoAccessToken);
        AuthService.on(AuthEventsEnum.LDAP_USER_MUST_RESET_PASSWORD, () => {
          navigate('/reset-password');
        });
        AuthService.on(AuthEventsEnum.ERROR_MESSAGE, error => onNewErrorMessage?.(error));
        AuthService.init(
          dispatch,
          platform,
          autoLogin,
          ignoredCodes,
          systemNotificationContext,
          showErrorToastr,
          showWarningToastr,
          showInfoToastr,
          disableAutoLogout,
          navigate
        );
        onAppConfigLoaded?.(dispatch);
      }
    } else {
      onAutoLoginCleanup = handleAutoLogin;
      onLoginCleanup = handleLogin;
      AuthService.on(AuthEventsEnum.AUTO_LOGIN, onAutoLoginCleanup);
      AuthService.on(AuthEventsEnum.LOGIN, onLoginCleanup);
      if (sessionValidityDuration?.value && !isDisabledAutoLogout.current) {
        setAutoLogout(getOverriddenAutoLogoutTime() || sessionValidityDuration?.value);
      }
    }
    return () => {
      active = false;
      loginAutoLogoutCleanup?.();
      AuthService.removeListener(AuthEventsEnum.AUTO_LOGIN, onAutoLoginCleanup);
      AuthService.removeListener(AuthEventsEnum.LOGIN, onLoginCleanup);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionValidityDuration?.value, appConfig]);

  return waitAuthCheck ? <SplashScreen /> : <>{children({ permissions, isLoggedIn })}</>;
}

export default AuthProvider;
