import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountSnapshot } from '@avispon/user/dist/models';

import {
  GridItem,
  GridLayout,
  InputMode,
  Section,
  TextInputField,
  typedNameV2,
  useFormV2Context,
  useViewModesV2,
  ValidatorEnums
} from '@libs/common/v2';

import { requiredDefaultFields } from '@libs/user/config';
import { SectionNames, useConfigurationContext } from '@libs/user/context';

interface IProps {
  requiredFields?: Record<string, boolean>;
  isClientPortal?: boolean;
}

function PersonalDataSection({ requiredFields = requiredDefaultFields, isClientPortal }: IProps) {
  const [t] = useTranslation();
  const { formMode } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);

  const { isFieldVisible, renderAdditionalFields } = useConfigurationContext();

  const inputMode = useMemo(() => {
    if (viewMode) {
      return InputMode.VIEW;
    }

    return InputMode.FORM;
  }, [viewMode]);

  const gridItemXsProp = useMemo(() => (isClientPortal ? 6 : 12), [isClientPortal]);

  return (
    <Section title={t('user:section.generalDetails')} isCollapsable>
      <GridLayout>
        {isFieldVisible('surname') && (
          <GridItem xs={gridItemXsProp}>
            <TextInputField
              inputMode={inputMode}
              name={typedNameV2<AccountSnapshot>('personalData.name')}
              label={t('user:field.name')}
              isRequired
            />
          </GridItem>
        )}
        {isFieldVisible('surname') && (
          <GridItem xs={gridItemXsProp}>
            <TextInputField
              inputMode={inputMode}
              name={typedNameV2<AccountSnapshot>('personalData.surname')}
              label={t('user:field.surname')}
              isRequired
            />
          </GridItem>
        )}
        {isFieldVisible('personalNumber') && (
          <GridItem xs={gridItemXsProp}>
            <TextInputField
              inputMode={inputMode}
              label={t('user:field.personalNumber')}
              name={typedNameV2<AccountSnapshot>('personalData.personalNumber')}
              inputProps={{ maxLength: 11 }}
              isRequired
            />
          </GridItem>
        )}
        {isFieldVisible('email') && (
          <GridItem xs={gridItemXsProp}>
            <TextInputField
              inputMode={inputMode}
              name={typedNameV2<AccountSnapshot>('email')}
              label={t('user:field.email')}
              isRequired={requiredFields.email}
              validation={{ contextValidator: [ValidatorEnums.EMAIL] }}
            />
          </GridItem>
        )}
        <GridItem xs={12}>{renderAdditionalFields(SectionNames.PERSONAL_DATA_SECTION)}</GridItem>
      </GridLayout>
    </Section>
  );
}

export default PersonalDataSection;
