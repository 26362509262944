import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function updateAssociation({
  folderId,
  associationId,
  version
}: {
  folderId: string;
  associationId?: string;
  version: number;
}) {
  return API.folder.updateAssociation(
    folderId,
    { folderId: associationId ?? null, version },
    { ...endpointsConfig.updateAssociation }
  );
}

function useUpdateAssociationMutation() {
  return useMutation(updateAssociation);
}

export default useUpdateAssociationMutation;
