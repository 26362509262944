import { ReportTypeUpdateRequest } from '@stack/report';

import { useMutation } from '@libs/common/v2/api';

import { endpointsConfig } from '@libs/user/api/endpoints-config';

import { API } from '..';

const editReportType = async ({
  reportTypeId,
  formData
}: {
  reportTypeId: string;
  formData: ReportTypeUpdateRequest;
}) => {
  const { data } = await API.reportTypes.updateReportType(reportTypeId, formData, {
    ...endpointsConfig.updateReportType
  });
  return data;
};

export default function useEditReportTypeMutation() {
  return useMutation(editReportType);
}
