import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RefetchOptions } from 'react-query/types/core/query';
import { useParams } from 'react-router-dom';
import { FolderDetails } from '@ibtm/domain';

import { BackButton, Button, DropdownButton, FormMode, useFormV2Context, useRouter } from '@libs/common/v2';
import { ButtonsGroup } from '@libs/common/v2/components/buttonsGroup';
import { useViewModesV2 } from '@libs/common/v2/form';

import { CreateApplicationDialog } from '@libs/domain/application';
import {
  DomainDictionaryEntry,
  DomainUIElementEnum,
  FolderElementsPermissionsEnum,
  useDomainConfigContext
} from '@libs/domain/config';
import { FolderDetailsParams, FolderDetailsTabEnum } from '@libs/domain/folder';
import { RegonDataModal } from '@libs/domain/subject';

import { useFolderElementsVisibility } from '../../../config';

import FolderHeaderDropdownMenu from './FolderHeaderDropdownMenu';

interface Props {
  folderDetails: FolderDetails;
  formMode: FormMode;
  refetch: (options?: RefetchOptions) => void;
  activeTab: FolderDetailsTabEnum;
}

function FolderDetailsHeader({ folderDetails, formMode, refetch, activeTab }: Props) {
  const { folderId } = useParams<FolderDetailsParams>();
  const [t] = useTranslation();
  const { goToPage } = useRouter();
  const { createMode, viewMode, editMode } = useViewModesV2(formMode);
  const { isSubmitting } = useFormV2Context();
  const [isCreateApplicationModalOpen, setCreateApplicationModalOpen] = useState(false);
  const [isRegonDataLoading, setIsRegonDataLoading] = useState(false);
  const [regonModalVisible, setRegonModalVisible] = useState(false);
  const { isOperatorPortal, isClientPortal } = useDomainConfigContext();
  const { checkFolderElementVisibility } = useFolderElementsVisibility();

  const queryParams = useMemo(() => ({ folderIdIn: [folderId] }), [folderId]);

  const getButtonLabel = (name: string): string => t<any>(`folder:details.button.${name}`);

  const goBack = useCallback(() => {
    goToPage(`/folders`);
  }, [goToPage]);

  const goBackFromEdit = useCallback(() => {
    const currentURLSearchParam = activeTab ? `?tab=${activeTab}` : '';
    goToPage(`/folders/${folderId}/details${currentURLSearchParam}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goToPage]);

  const currentURLSearchParam = activeTab ? `?tab=${activeTab}` : '';
  return (
    <ButtonsGroup>
      {viewMode && <BackButton onClick={goBack} />}
      {createMode && <Button isPrimary label={t('action.cancel')} onClick={goBack} variant="outlined" type="button" />}
      {editMode && (
        <Button isPrimary label={t('action.cancel')} onClick={goBackFromEdit} variant="outlined" type="button" />
      )}
      {viewMode && (
        <Button
          variant="outlined"
          label={getButtonLabel('addApplication')}
          disabled={!folderDetails?.id}
          onClick={() => setCreateApplicationModalOpen(true)}
          actionKey={DomainUIElementEnum.FOLDER_APPLICATION_CREATE_BUTTON}
          isPrimary
          {...(folderDetails?.archived && {
            tooltipTitle: getButtonLabel('tooltip.archived'),
            disabled: true
          })}
        />
      )}
      {checkFolderElementVisibility({
        folderTypeKey: folderDetails?.typeKey,
        permisson: FolderElementsPermissionsEnum.FOLDER_EDIT_PERMISSIONS
      }) &&
        isOperatorPortal &&
        viewMode && (
          <Button
            isPrimary
            label={getButtonLabel('editFolder')}
            {...(folderDetails?.archived && {
              tooltipTitle: getButtonLabel('tooltip.archived'),
              disabled: true
            })}
            link={`/folders/${folderDetails?.id || folderId}/edit${currentURLSearchParam}`}
          />
        )}
      {isOperatorPortal && createMode && (
        <Button
          isPrimary
          label={t('folder:button.create')}
          type="submit"
          isLoading={isSubmitting}
          actionKey={DomainUIElementEnum.FOLDER_CREATE_BUTTON}
        />
      )}
      {isOperatorPortal && !createMode && (
        <DropdownButton>
          {({ handleClose }) => (
            <FolderHeaderDropdownMenu
              activeTab={activeTab}
              handleClose={handleClose}
              formMode={formMode}
              refetch={refetch}
              id={folderDetails?.id}
              openRegonModal={() => setRegonModalVisible(true)}
              showEditAssociationMembership={folderDetails?.typeKey !== DomainDictionaryEntry.FOLDER_TYPE.ZZ}
              isRegonDataLoading={isRegonDataLoading}
            />
          )}
        </DropdownButton>
      )}
      <CreateApplicationDialog
        selectedFolder={folderDetails}
        showAddFolderButton={isOperatorPortal}
        open={isCreateApplicationModalOpen}
        onCancel={() => {
          setCreateApplicationModalOpen(false);
        }}
        onClose={() => {
          setCreateApplicationModalOpen(false);
        }}
        onSuccess={applicationId => {
          setCreateApplicationModalOpen(false);
          if (isOperatorPortal) {
            goToPage(`/folders/${folderId}/applications/${applicationId}/form`);
          }
          if (isClientPortal) {
            goToPage(`/folders/${folderId}/applications/${applicationId}`);
          }
        }}
      />
      {regonModalVisible && (
        <RegonDataModal
          open={regonModalVisible}
          setIsOpen={setRegonModalVisible}
          queryParams={queryParams}
          onLoading={isLoading => setIsRegonDataLoading(isLoading)}
          isViewOnly
        />
      )}
    </ButtonsGroup>
  );
}

export default FolderDetailsHeader;
