import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '@enigma/fe-ui';
import { SuspensionSnapshot } from '@ibtm/domain';
import { AxiosResponse } from 'axios';
import { isEmpty, last, pick } from 'lodash';
import moment from 'moment';

import {
  ALTERNATIVE_DATE_FORMAT,
  BackButton,
  Button,
  FormActionEnum,
  Page,
  Tab,
  TabsPageSidebar,
  typedNameV2,
  useFormActionModal,
  useFormV2Context,
  useIsSmallScreen,
  useRouter,
  useTab
} from '@libs/common/v2';
import { ButtonsGroup } from '@libs/common/v2/components/buttonsGroup';
import { useViewModesV2 } from '@libs/common/v2/form';
import PageHeader from '@libs/common/v2/templates/page-header/PageHeader';
import { getCalendarDate, replaceFirstOccurence } from '@libs/common/v2/utils';

import { useElementVisibility } from '@libs/permission';
import usePermissions from '@libs/permission/hooks/usePermissions';

import { DomainUIElementEnum, PermissionEnum, useDomainConfigContext } from '@libs/domain/config';
import { FolderSlider, useFolderDetailsExtendedQuery } from '@libs/domain/folder';
import { useRemarksHeaderActions } from '@libs/domain/notes';
import {
  FAILED_NUMBER_FROM_EZD_CODE,
  getSuspensionBreadcrumbItems,
  ISuspensionDetailsParams,
  SuspensionDetailsTabEnum,
  useCreateSuspensionMutation,
  useSuspensionDetailsQuery,
  useSuspensionsQuery,
  useUpdateSuspensionMutation
} from '@libs/domain/suspensions';
import useErrorContext from '@libs/domain/suspensions/context/ErrorContext';

import SuspensionActions from './SuspensionActions';
import SuspensionDetailsContent from './SuspensionDetailsContent';

interface IProps {
  setSubmitting: (value: boolean) => void;
}

function SuspensionsDetails({ setSubmitting }: IProps) {
  const [t] = useTranslation();
  const { folderId, id } = useParams<ISuspensionDetailsParams>();
  const { formMode, handleSubmit, reset, isSubmitting, watch, setValue, isDirty } = useFormV2Context();
  const { createMode, editMode, viewMode } = useViewModesV2(formMode);
  const { showSuccessSnackbar, showSnackbar } = useSnackbar();
  const { setIsCaseNumberError } = useErrorContext();
  const { routes, goToPage, location, navigate } = useRouter();
  const { data, refetch, isLoading: isSuspensionDataLoading } = useSuspensionDetailsQuery(id, { enabled: !!id });
  const { data: folderDetails } = useFolderDetailsExtendedQuery(folderId, { enabled: Boolean(folderId) });
  const { data: folderSuspensionData } = useSuspensionsQuery(
    { folderIdIn: [folderId], sort: ['created,DESC'] },
    { enabled: Boolean(folderId) }
  );
  const { isOperatorPortal } = useDomainConfigContext();
  const { checkIsElementVisible } = useElementVisibility();
  const { hasPermission } = usePermissions();
  const tabChangeAction = useFormActionModal();
  const { isSmallScreen } = useIsSmallScreen();

  useEffect(() => {
    if (folderSuspensionData && !isEmpty(folderSuspensionData?.content)) {
      const { dateTo } = last(folderSuspensionData?.content);
      setValue(typedNameV2<SuspensionSnapshot>('dateTo'), dateTo);
    }
  }, [folderSuspensionData, setValue]);

  const noteId = watch('noteId') as string;
  const version = watch('version') as number;

  const { handleSave } = useRemarksHeaderActions({
    suspensionId: id,
    remarkExists: !!noteId
  });

  const [activeTab, setActiveTab] = useTab<SuspensionDetailsTabEnum>(SuspensionDetailsTabEnum.SUSPENSION_DETAILS);

  const { mutateAsync: createSuspension } = useCreateSuspensionMutation();
  const { mutate: updateSuspension } = useUpdateSuspensionMutation();

  useEffect(() => {
    if (data) {
      reset(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const goBack = useCallback(() => {
    const previousUrl = location?.state?.from;
    goToPage(previousUrl ?? routes.suspensionsList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.from]);

  const title = useMemo(() => {
    if (createMode) {
      return t('suspensions:details.newTitle');
    }
    if (viewMode) {
      return t('suspensions:details.title');
    }
    return t('suspensions:details.editTitle');
  }, [createMode, viewMode, t]);

  const prepareCreateRequest = values => {
    return {
      ...values,
      folderId,
      whole: !!values.whole,
      dateTo: moment(values.dateTo).format(ALTERNATIVE_DATE_FORMAT)
    };
  };

  const onObjectUpdated = () => {
    const currentUrl = location.pathname;
    const nextUrl = replaceFirstOccurence('edit', 'details', currentUrl);
    goToPage(nextUrl, {
      state: { from: location?.state?.from }
    });
  };

  useEffect(() => {
    if (isOperatorPortal && !viewMode && folderDetails) {
      if ('borrowedBy' in folderDetails && folderDetails?.borrowedBy) {
        showSnackbar(
          'info',
          t('folder:details.message.folderIsBorrowedBy', {
            name: folderDetails?.borrowedBy?.firstName,
            surname: folderDetails?.borrowedBy?.surname,
            number: folderDetails?.number
          })
        );
      } else {
        showSnackbar('info', t('folder:details.message.folderIsNotBorrowed'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOperatorPortal, formMode, folderDetails]);

  const onSubmit = (
    values,
    _event,
    onSuccess?: (responseTo?: AxiosResponse<SuspensionSnapshot>) => void,
    showSuccessMessage = true
  ) => {
    setSubmitting(true);
    if (createMode) {
      createSuspension(prepareCreateRequest(values), {
        onSuccess: response => {
          if (showSuccessMessage) {
            showSuccessSnackbar(t('suspensions:details.message.added'));
          }
          onSuccess?.(response);
        },
        onError: error => {
          const errorTmp = error || {};
          if (Object.prototype.hasOwnProperty.call(errorTmp, 'codes')) {
            if (errorTmp?.['codes']?.includes(FAILED_NUMBER_FROM_EZD_CODE)) {
              showSnackbar('warning', t('suspensions:details.message.noCaseNumberAvailable'));
              setIsCaseNumberError(true);
            }
          }
        },
        onSettled: () => {
          setSubmitting(false);
        }
      });
    } else {
      updateSuspension(
        {
          id,
          request: {
            ...pick(values, ['version', 'whole']),
            dateFrom: getCalendarDate(moment(values.dateFrom).format(ALTERNATIVE_DATE_FORMAT)),
            dateTo: getCalendarDate(moment(values.dateTo).format(ALTERNATIVE_DATE_FORMAT)),
            dateOfIssue: getCalendarDate(moment(values.dateOfIssue).format(ALTERNATIVE_DATE_FORMAT))
          }
        },
        {
          onSuccess: () => {
            if (showSuccessMessage && !Object.prototype.hasOwnProperty.call(values, 'noteId')) {
              showSuccessSnackbar(t('suspensions:details.message.updated'));
            }
            refetch();
            onSuccess?.();
          },
          onSettled: () => {
            setSubmitting(false);
          }
        }
      );
    }
  };

  const saveProgress = useCallback(
    (values: { noteContent?: string; noteVersion?: number }, event, onSuccessSave: () => void) => {
      onSubmit(
        values,
        event,
        response => {
          if (createMode) {
            goToPage(routes.suspensionDetails(folderId, response.data.id), {
              state: { from: location?.state?.from }
            });
          } else if (Object.prototype.hasOwnProperty.call(values, 'noteId') && editMode) {
            const { noteContent } = values;
            if (!(typeof noteContent === 'string' && noteContent.length > 5000)) {
              handleSave(
                { note: values.noteContent, version: values.noteVersion },
                event,
                () => {
                  onSuccessSave();
                  showSuccessSnackbar(t('suspensions:details.message.updated'));
                },
                false
              );
            }
          } else {
            onSuccessSave();
          }
        },
        true
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      createMode,
      editMode,
      folderId,
      goToPage,
      handleSave,
      location?.state?.from,
      onObjectUpdated,
      routes,
      showSuccessSnackbar,
      t
    ]
  );

  const isNoteChanged = watch('isNoteChanged');

  const changeTab = useCallback(
    tab => {
      if (editMode && (isDirty || isNoteChanged)) {
        tabChangeAction({
          formAction: FormActionEnum.SAVE_CHANGES,
          onConfirm: () => {
            handleSubmit((values, event) => {
              if (isNoteChanged) {
                setValue('isNoteChanged', false);
              }
              saveProgress(values, event, () => setActiveTab(tab));
            })();
          },
          onCancel: () => {
            reset(data);
            setActiveTab(tab);
          }
        });
      } else {
        setActiveTab(tab);
      }
    },
    [editMode, isDirty, isNoteChanged, tabChangeAction, handleSubmit, saveProgress, setValue, setActiveTab, reset, data]
  );

  const handleOpenEditMode = useCallback(() => {
    const previousUrl = location?.state?.from;
    const newUrl = replaceFirstOccurence('details', 'edit', location.pathname).replace('details', 'edit');
    navigate(
      { pathname: newUrl, search: `tab=${activeTab}` },
      {
        state: { from: previousUrl }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, location.pathname]);

  const openViewMode = useCallback(() => {
    const previousUrl = location?.state?.from ?? routes.proceedingsList();
    const currentUrl = `${location.pathname}`;
    const newUrl = replaceFirstOccurence('edit', 'details', currentUrl);
    if (createMode) {
      navigate(-1);
    } else {
      navigate(
        { pathname: newUrl, search: `tab=${activeTab}` },
        {
          state: { from: previousUrl }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, location.pathname]);

  return (
    <Page
      isLoading={isSuspensionDataLoading}
      header={
        <PageHeader
          title={title}
          breadcrumbs={getSuspensionBreadcrumbItems(formMode, data?.number?.toString(), data?.id, folderId)}
          rightSideContent={
            <ButtonsGroup>
              {viewMode && (
                <>
                  <BackButton onClick={goBack} />
                  <Button
                    variant="contained"
                    onClick={handleOpenEditMode}
                    label={t('action.edit')}
                    actionKey={DomainUIElementEnum.SUSPENSIONS_EDIT_BUTTON}
                    isPrimary
                    isNoMargin
                  />
                </>
              )}
              {!viewMode && (
                <>
                  <Button
                    onClick={openViewMode}
                    label={t('action.cancel')}
                    type="button"
                    variant="outlined"
                    isSecondary
                    isNoMargin
                  />
                  <Button
                    onClick={handleSubmit((values, event) => saveProgress(values, event, () => onObjectUpdated()))}
                    label={t('action.save')}
                    type="button"
                    isLoading={isSubmitting}
                    isPrimary
                    isNoMargin
                  />
                </>
              )}
              {!createMode && <SuspensionActions version={version} formMode={formMode} />}
            </ButtonsGroup>
          }
          isDividerShown
        />
      }
      content={<SuspensionDetailsContent activeTab={activeTab} setActiveTab={changeTab} />}
      leftSidebarHeader={!createMode && t('sections')}
      leftSidebarContent={
        !createMode && (
          <TabsPageSidebar
            visibleTabs={[
              <Tab
                label={t('suspensions:details.title')}
                value={SuspensionDetailsTabEnum.SUSPENSION_DETAILS}
                key="procedureDetails-title"
                viewName={DomainUIElementEnum.SUSPENSIONS_DETAILS_VIEW}
                icon="InfoIcon"
              />,
              <Tab
                label={t('proceeding:administrative.tab.outgoingDocuments.title')}
                value={SuspensionDetailsTabEnum.SUSPENSION_DOCUMENTS}
                key="outgoingDocuments-title"
                viewName={DomainUIElementEnum.SUSPENSIONS_DOCUMENTS_VIEW}
                icon="DocumentsIssuedIcon"
              />,
              <Tab
                label={t('suspensions:releaseDocuments.title')}
                value={SuspensionDetailsTabEnum.SUSPENSION_RELEASE_DOCUMENTS}
                key="releaseDocuments-title"
                viewName={DomainUIElementEnum.SUSPENSIONS_CORRECTION_LIST}
                icon="ReleaseDocumentsIcon"
              />,
              <Tab
                label={t('proceeding:administrative.tab.formOperations.title')}
                value={SuspensionDetailsTabEnum.FORM_OPERATIONS}
                key="formOperations-title"
                viewName={DomainUIElementEnum.SUSPENSIONS_FORM_OPERATIONS_VIEW}
                icon="ReleaseDocumentsIcon"
              />,
              <Tab
                label={t('suspensions:notes.title')}
                value={SuspensionDetailsTabEnum.SUSPENSION_NOTE_TAB}
                key="note-title"
                icon="NoteIcon"
                viewName={DomainUIElementEnum.SUSPENSIONS_NOTE_VIEW}
              />,
              <Tab
                label={t('proceeding:administrative.tab.files.title')}
                value={SuspensionDetailsTabEnum.SUSPENSION_FILES}
                key="files"
                icon="FileEmptyIcon"
                viewName={DomainUIElementEnum.SUSPENSIONS_FILES_VIEW}
              />
            ]}
            activeTab={activeTab}
            onChange={changeTab}
          />
        )
      }
      isRightSidebarVisible={checkIsElementVisible(DomainUIElementEnum.FOLDER_SLIDER)}
      rightSidebarContent={
        !createMode && (
          <FolderSlider
            folderId={folderId}
            hasSubjectDataPermission={hasPermission(PermissionEnum.IBTM_DOMAIN_FOLDER_SUBJECT_VIEW)}
          />
        )
      }
      isLeftSidebarOpen={!createMode}
      isContentScrollEnabled={!isSmallScreen}
      isLeftSidebarScrollEnabled={!createMode}
    />
  );
}

export default SuspensionsDetails;
