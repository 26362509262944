import { useCallback } from 'react';

import { useDialog } from '@libs/common/v2';

import { UpdateAlertConfigStatusDialog } from '@libs/alert/components';

function useUpdateAlertConfigStatusDialog() {
  const { openDialog } = useDialog();

  const showDialog = useCallback(
    (alertId: string) => {
      openDialog(({ closeDialog }) => <UpdateAlertConfigStatusDialog closeDialog={closeDialog} alertId={alertId} />);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog]
  );

  const openUpdateAlertConfigStatusDialog = (alertId: string) => {
    showDialog(alertId);
  };

  return {
    openUpdateAlertConfigStatusDialog
  };
}

export default useUpdateAlertConfigStatusDialog;
