import {
  ComponentsProps,
  DeprecatedThemeOptions,
  Direction,
  GridSpacing,
  Mixins,
  Theme as MuiTheme,
  Transitions,
  TransitionsOptions,
  ZIndex
} from '@mui/material';
import { Shape, ShapeOptions, Spacing, SpacingOptions } from '@mui/system';

import { TypographyOptions } from '@libs/common/v2';
import { Palette, PaletteOptions, TypographyOptionsType } from '@libs/common/v2/theme';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'xMd' | 'lg' | 'xl';
export interface Breakpoints {
  keys: Breakpoint[];
  values: { [key in Breakpoint]: number };
  up: (key: Breakpoint | number) => string;
  down: (key: Breakpoint | number) => string;
  between: (start: Breakpoint | number, end: Breakpoint | number) => string;
  only: (key: Breakpoint) => string;
  not: (key: Breakpoint) => string;
  unit?: string | undefined;
}

export interface BreakpointsOptions extends Partial<Breakpoints> {
  step?: number | undefined;
}

export interface Shadows {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  '2xl': string;
  '3xl': string;
  inputFocus: string;
  inputHover: string;
  buttonFocus: string;
  outlinedButtonFocus: string;
  tableActions: string;
  selectCell: string;
}

export type ShadowsOptions = Partial<Shadows>;

export interface CustomTheme extends MuiTheme {
  palette: MuiTheme['palette'] & PaletteOptions;
  typography: MuiTheme['typography'] & TypographyOptions;
}

export enum ThemeFontSizeEnum {
  NORMAL_FONT = 1,
  MEDIUM_FONT = 1.25,
  BIG_FONT = 1.5
}

declare module '@mui/material/styles/createMuiThemeV2' {
  interface DeprecatedThemeOptions {
    shape?: ShapeOptions;
    breakpoints?: BreakpointsOptions;
    direction?: Direction;
    mixins?: Partial<Mixins>;
    palette?: PaletteOptions;
    props?: ComponentsProps;
    shadows?: Shadows;
    spacing?: SpacingOptions;
    transitions?: TransitionsOptions;
    typography?: TypographyOptions | ((palette: Palette) => TypographyOptions);
    zIndex?: Partial<ZIndex>;
    unstable_strictMode?: boolean;
  }
}

export interface Theme {
  shape: Shape;
  breakpoints: Breakpoints;
  direction: Direction;
  mixins: Mixins;
  palette: Palette;
  props?: ComponentsProps;
  shadows: Shadows;
  shadowsList?: ShadowsOptions;
  borderRadiusBase?: number;
  gridDefaultSpacing?: GridSpacing;
  marginBase?: number;
  spacing: Spacing;
  transitions: Transitions;
  typography: {
    /**
     * @desc  Funkcja przyjmuje jako argument rozmiar fontu w px, a zwraca font pomnożony przez aktualne powiększenie tekstu w formacie rem.
     */
    getFontSize: (fontSize: number) => string;
  } & TypographyOptionsType;
  zIndex: ZIndex;
  unstable_strictMode?: boolean;
}

export default function createMuiTheme(options?: DeprecatedThemeOptions, ...args: Record<string, any>[]): Theme;
