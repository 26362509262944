import { partialTranslate } from '@libs/common';
import { DictionarySelectField, GridLayout, InputMode, Section, TextInputField, typedNameV2 } from '@libs/common/v2';

import { parsePostCode } from '@libs/domain/address';
import { DomainDictionaryEnum } from '@libs/domain/config';
import { TransportManagerDetailsClient } from '@libs/domain/transport-manager';

function AddressDataSection() {
  const getLabel = partialTranslate('transportManager:details');

  return (
    <Section title={getLabel('section.address')} isCollapsable isModalSection>
      <GridLayout itemProps={{ xs: 12, md: 6 }}>
        <TextInputField
          name={typedNameV2<TransportManagerDetailsClient>('address.postCode')}
          label={getLabel('field.postCode')}
          getValueFormat={postCode => {
            return parsePostCode(postCode);
          }}
          isRequired
          inputMask={{ mask: '99-999', alwaysShowMask: true }}
        />
        <DictionarySelectField
          name={typedNameV2<TransportManagerDetailsClient>('address.voivodeship')}
          label={getLabel('field.voivodeship')}
          dictionaryName={DomainDictionaryEnum.VOIVODESHIP}
          isRequired
          stringValue
        />
        <TextInputField
          name={typedNameV2<TransportManagerDetailsClient>('address.postCity')}
          label={getLabel('field.postCity')}
          inputProps={{ maxLength: 50 }}
          isRequired
        />
        <TextInputField
          name={typedNameV2<TransportManagerDetailsClient>('address.city')}
          label={getLabel('field.city')}
          inputProps={{ maxLength: 50 }}
          isRequired
        />
        <TextInputField
          name={typedNameV2<TransportManagerDetailsClient>('address.street')}
          label={getLabel('field.street')}
          inputProps={{ maxLength: 50 }}
        />
        <TextInputField
          name={typedNameV2<TransportManagerDetailsClient>('address.propertyNumber')}
          label={getLabel('field.propertyNumber')}
          inputProps={{ maxLength: 10 }}
          isRequired
        />
        <TextInputField
          name={typedNameV2<TransportManagerDetailsClient>('address.apartmentNumber')}
          label={getLabel('field.apartmentNumber')}
          inputProps={{ maxLength: 10 }}
        />
        <DictionarySelectField
          name={typedNameV2<TransportManagerDetailsClient>('address.addressTypeKey')}
          label={getLabel('field.addressTypeKey')}
          dictionaryName={DomainDictionaryEnum.ADDRESS_TYPE}
          inputMode={InputMode.VIEW}
          stringValue
        />
      </GridLayout>
    </Section>
  );
}

export default AddressDataSection;
