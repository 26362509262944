import { ApplicationPaymentUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export function editPaymentData({
  applicationId,
  formData
}: {
  applicationId: string;
  formData: ApplicationPaymentUpdateRequest;
}) {
  return API.application.updateApplicationPaymentData(applicationId, formData, {
    ...endpointsConfig.updateApplicationPaymentData
  });
}

function useEditPaymentData() {
  return useMutation(editPaymentData);
}

export default useEditPaymentData;
