import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { FolderLimitDetails, FolderLimitUpdateRequest } from '@ibtm/domain';

import { useDialog } from '@libs/common/v2';

import { EditOrTransferRequest, useTransferFolderLimitMutation, useUpdateLimitMutation } from '@libs/domain/folder';

import EditOrTransferLimitModal from '../components/details/tabs/singleAuthorization/basicLimit/EditOrTransferLimitModal';

function useEditOrTransferLimitModal() {
  const [t] = useTranslation();

  const { openDialog } = useDialog();
  const { showSuccessSnackbar } = useSnackbar();

  const { mutateAsync: transferLimit } = useTransferFolderLimitMutation();
  const { mutateAsync: updateLimit } = useUpdateLimitMutation();

  const onSubmit = (id: string, values: EditOrTransferRequest, refetch: () => void, data: FolderLimitDetails) => {
    if (!values.moveLimit) {
      const folderLimitUpdateRequest: FolderLimitUpdateRequest = {
        typeKey: data.typeKey,
        suspended: data.suspended,
        resourceTypeId: data?.resourceType?.id,
        version: data.version,
        folderId: values.moveLimit ? values.folder?.id : data.folder.id,
        limit: +values.limit,
        reasonKey: values.reasonKey.value as string
      };

      return updateLimit(
        { folderLimitId: id, folderLimitUpdateRequest },
        {
          onSuccess: () => {
            showSuccessSnackbar(t('folder:details.tab.singleAuthorization.section.basicLimit.message.success'));
          },
          onSettled: () => {
            refetch();
          }
        }
      );
    }

    return transferLimit(
      {
        folderLimitUpdateRequest: {
          folderLimitId: id,
          limit: +values.limit,
          version: data.version,
          destinationFolderId: values.folder.id,
          reasonKey: values.reasonKey?.value as string
        }
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('folder:details.tab.singleAuthorization.section.basicLimit.message.transferSucces'));
        },
        onSettled: () => {
          refetch();
        }
      }
    );
  };

  const showEditOrTransferLimitModal = ({
    limit,
    id,
    refetch,
    data
  }: {
    limit: number;
    id: string;
    refetch: () => void;
    data: FolderLimitDetails;
  }) => {
    openDialog(({ closeDialog }) => (
      <EditOrTransferLimitModal
        folderNumber={data?.folder?.number}
        limit={limit}
        closeDialog={closeDialog}
        open
        dataRow={data}
        refetch={refetch}
        onSubmit={onSubmit}
        id={id}
      />
    ));
  };

  return { showEditOrTransferLimitModal };
}

export default useEditOrTransferLimitModal;
