import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from '@enigma/fe-ui';
import axios from 'axios';

import { i18n } from '@libs/common';

import { useAppConfigQuery } from '../api';
import { AppConfigScheme, parseAppConfigScheme } from '../scheme';

export interface IAppConfigContext {
  config: AppConfigScheme;
  setMainPage: (mainPage: string) => void;
  isFetching: boolean;
}

const initialState: IAppConfigContext = {
  isFetching: true,
  setMainPage: () => null,
  config: {
    env: {
      name: ''
    },
    api: {
      baseUrl: '',
      webSocketUrl: ''
    },
    app: {
      captchaKey: '',
      showCaptcha: false,
      showDictionaryKeys: false,
      showReactQueryDevtools: false,
      notificationsUpdateSeconds: 300,
      isPermissionKeys: true,
      mainPage: '',
      defaultDevMode: false,
      superusers: [],
      disableAutoLogout: false
    },
    external: {
      auditServiceUrl: '',
      processesServiceUrl: ''
    },
    mockUser: {
      login: '',
      password: ''
    },
    common: {
      table: {
        hideColumnPicker: false,
        hideCompactToggle: true
      },
      topBar: {
        showDevModeSwitch: false,
        showSystemNotifications: false,
        showNotifications: false
      },
      footer: {
        hideVersionPrefix: true,
        hidePortalOwner: true
      }
    },
    notification: {
      table: {
        useCreateButton: true
      }
    },
    administration: {
      resendEmailConfirmationVisible: true
    },
    report: {
      isReportTypeManagementEnable: true,
      reportTypes: {
        issuingSk: { reportTypeName: '', targetParameters: {} },
        upToDateSk: { reportTypeName: '', targetParameters: {} },
        upToDateSkCompany: { reportTypeName: '', targetParameters: {} },
        issuingSkCompany: { reportTypeName: '', targetParameters: {} },
        resourcesStateOverview: { reportTypeName: '', targetParameters: {} },
        deliveryAndSummaryListOfFiles: { reportTypeName: '', targetParameters: {} },
        listOfCases: { reportTypeName: '', targetParameters: {} },
        listOfPartners: { reportTypeName: '', targetParameters: {} },
        aboutVehicles: { reportTypeName: '', targetParameters: {} },
        returnedAndUnreturnedPermits: { reportTypeName: '', targetParameters: {} }
      }
    },
    templates: {
      names: { expirationsGitd: '' }
    },
    prints: {
      showFormsNumbersInLocksSummary: false,
      showLocksSummaryAction: false
    }
  }
};

export const AppConfigContext = createContext<IAppConfigContext>(initialState);

interface IProps {
  children: (appConfig: IAppConfigContext) => ReactNode;
  isClientPortalConfigScheme?: boolean;
}

function AppConfigContextProvider({ children, isClientPortalConfigScheme }: IProps) {
  const { data, isFetched } = useAppConfigQuery();
  const { showSnackbar } = useSnackbar();
  const [mainPage, setMainPage] = useState<string>(null);
  axios.defaults.baseURL = data?.api?.baseUrl || '';

  const values: IAppConfigContext = useMemo(
    () => ({
      config: {
        ...initialState.config,
        ...data,
        app: {
          ...initialState?.config.app,
          ...data?.app,
          mainPage: mainPage || data?.app?.mainPage || initialState.config.app.mainPage
        }
      },
      isFetching: !isFetched,
      setMainPage
    }),
    [data, isFetched, mainPage]
  );

  useEffect(() => {
    if (isFetched && data) {
      const parseError = parseAppConfigScheme(data, isClientPortalConfigScheme);
      if (parseError) {
        // @ts-ignore Type instantiation is excessively deep and possibly infinite.
        showSnackbar('error', i18n.t('error.invalidAppConfiguration'), null, { autoHideDuration: 10000 });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFetched]);

  return <AppConfigContext.Provider value={values}>{children(values)}</AppConfigContext.Provider>;
}

export default AppConfigContextProvider;
