import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ExploitationBaseInspectionUpdateRequest } from '@ibtm/domain';
import { object as YupObject, string as YupString } from 'yup';

import { Dialog, FormV2Context } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { getCalendarDate } from '@libs/common/v2/utils';

import { ExploitationBaseQueryKeysEnum, useExploitationBaseUpdateMutation } from '../../../api';
import { ExploitationBaseFormData, ExploitationBaseInspectionSnapshot } from '../../../model';
import { parseExploitationBaseUpdateRequest } from '../../../utils';

import InspectionSectionFields from './InspectionSectionFields';

interface IProps {
  closeDialog: () => void;
  inspections: ExploitationBaseInspectionSnapshot[];
  exploitationBaseId: string;
  initialExploitationBaseData: ExploitationBaseFormData;
}

function InspectionDialog({ closeDialog, inspections, exploitationBaseId, initialExploitationBaseData }: IProps) {
  const [t] = useTranslation();
  const queryCache = useQueryCache();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: editExploitationBase, isLoading } = useExploitationBaseUpdateMutation();

  const form = useForm<Partial<{ inspection: ExploitationBaseInspectionUpdateRequest }>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(
      YupObject<{ inspection: ExploitationBaseInspectionSnapshot }>({
        inspection: YupObject({
          inspectionResultKey: YupString().required(),
          remarks: YupString().required(),
          inspectionDate: YupString().required()
        })
      })
    )
  });

  const handleAdd = (editData: Partial<{ inspection: ExploitationBaseInspectionUpdateRequest }>) => {
    const {
      inspection: { inspectionDate, inspectionResultKey, remarks }
    } = editData;
    const parsedData = parseExploitationBaseUpdateRequest(initialExploitationBaseData);

    const exploitationBaseInspections =
      inspections?.map(item => ({
        inspectionDate: getCalendarDate(item?.inspectionDate),
        inspectionResultKey: item?.inspectionResultKey,
        remarks: item?.remarks,
        version: initialExploitationBaseData.version
      })) || [];

    const newExploitationBaseInspections = [
      ...exploitationBaseInspections,
      {
        inspectionDate,
        inspectionResultKey,
        remarks,
        version: initialExploitationBaseData.version
      }
    ];
    const exploitationBaseUpdateRequest = {
      ...parsedData,
      exploitationBaseInspections: newExploitationBaseInspections
    };

    editExploitationBase(
      {
        exploitationBaseId,
        exploitationBaseUpdateRequest
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('exploitationBase:details.message.baseUpdated'));
          queryCache.invalidateQueries([ExploitationBaseQueryKeysEnum.EXPLOITATION_BASE, exploitationBaseId]);
          closeDialog();
        }
      }
    );
  };

  const {
    formState: { isSubmitting, errors },
    handleSubmit
  } = form;

  const values = useMemo(() => ({ ...form, isSubmitting, errors }), [errors, form, isSubmitting]);

  return (
    <FormV2Context.Provider value={values}>
      <Dialog
        title={t('exploitationBase:dialog.addControlBase.title')}
        onConfirm={handleSubmit(handleAdd)}
        onCancel={closeDialog}
        confirmText={t('action.save')}
        cancelText={t('action.close')}
        isConfirmLoading={isLoading}
        isOpen
      >
        <InspectionSectionFields isDateRequired isInspectionResultRequired isRemarksRequired />
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default InspectionDialog;
