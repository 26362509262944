import { QueryConfig, useQuery } from 'react-query';
import { DefaultApiFindGroupsUserIsNotMemberOfRequest } from '@avispon/group';
import { PageGroupSnapshotExternal } from '@avispon/group/dist/models';

import { API, endpointsConfig } from '..';

import { UserQueryKeysEnum } from './QueryKeysEnum';

const getGroupsToAssignApiRequest = async (requestParameters: DefaultApiFindGroupsUserIsNotMemberOfRequest) => {
  if (!requestParameters.unitId) return {};
  const { data } = await API.group.findGroupsUserIsNotMemberOf(requestParameters, {
    ...endpointsConfig.findGroupsUserIsNotMemberOf
  });
  return data;
};

export default function useGetGroupsToAssignQuery(
  requestParameters: DefaultApiFindGroupsUserIsNotMemberOfRequest,
  config?: QueryConfig<PageGroupSnapshotExternal, unknown>
) {
  return useQuery(
    [UserQueryKeysEnum.GROUPS_EDIT, requestParameters],
    () => getGroupsToAssignApiRequest(requestParameters),
    config
  );
}
