import { SwitchInput } from '../components';

import useDevMode from './useDevMode';

interface IProps {
  className: string;
}

function DevModeSwitch({ className }: IProps) {
  const { isDevMode, setDevMode } = useDevMode();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDevMode(event.target.checked);
  };

  return (
    <SwitchInput
      name="devmode"
      inlineLabel="Dev"
      isDefaultChecked={isDevMode}
      onChange={handleChange}
      className={className}
    />
  );
}

export default DevModeSwitch;
