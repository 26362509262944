import { MouseEvent, ReactNode, useMemo } from 'react';
import { MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Theme } from '@libs/common/v2/theme';

import { useElementVisibility } from '@libs/permission';

interface Props {
  className?: string;
  actionKey?: string;
  children: ReactNode;
  tabIndex?: number;
  onClick?: (event: MouseEvent<any>) => void;
}

function MenuItemComponent(props: Props) {
  const classes = useStyles();
  const { checkIsElementVisible } = useElementVisibility();
  const { actionKey, className, children } = props;
  const isElementVisible = useMemo(() => {
    return actionKey ? checkIsElementVisible(actionKey) : true;
  }, [checkIsElementVisible, actionKey]);

  if (isElementVisible)
    return (
      <MenuItem {...props} className={className} classes={{ root: classes.root }}>
        {children}
      </MenuItem>
    );
  return null;
}

export default MenuItemComponent;

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    color: theme.palette.grey[900],
    '& span': {
      fontSize: theme.typography?.getFontSize(16)
    },
    '& span.material-icons': {
      fontSize: theme.typography?.getFontSize(24)
    },
    '&:hover,&:focus': {
      backgroundColor: theme.palette.primary?.hover
    }
  }
}));
