import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, useFormV2 } from '@libs/common/v2';

import { DictionaryEntryNameEnum } from '@libs/dictionary';

import { IAttachmentListItemClient } from '../../model';
import AttachmentCommentForm from '../forms/AttachmentCommentForm';

interface IProps {
  applicationId: string;
  data: IAttachmentListItemClient;
  closeDialog: () => void;
  dictionaryKey?: DictionaryEntryNameEnum;
}

function AttachmentCommentDialog({ applicationId, data, closeDialog, dictionaryKey }: IProps) {
  const [t] = useTranslation();

  const [loading, setLoading] = useState(false);
  const { formRef, submitForm } = useFormV2();

  return (
    <Dialog
      title={t('applications:attachments.dialog.addCommentTitle')}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={submitForm}
      onCancel={closeDialog}
      isConfirmLoading={loading}
      isOpen
    >
      <AttachmentCommentForm
        applicationId={applicationId}
        data={data}
        onLoading={setLoading}
        ref={formRef}
        onSuccess={closeDialog}
        dictionaryKey={dictionaryKey}
      />
    </Dialog>
  );
}

export default AttachmentCommentDialog;
