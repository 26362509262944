import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { FormV2Context, useDialog } from '@libs/common/v2';

import { UploadReportTypeDialog } from '@libs/report/components';

function useUploadReportTypeDialog(refetch: () => void, isMultiple?: boolean) {
  const { openDialog } = useDialog();
  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object(
        isMultiple
          ? {
              file: Yup.array().nullable().required()
            }
          : {
              file: Yup.object().nullable().required()
            }
      )
    ),
    defaultValues: { file: null }
  });

  const values = useMemo(
    () => ({
      control,
      errors,
      register,
      handleSubmit,
      clearErrors,
      setError,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      loading: isSubmitting,
      initialValues: { file: null }
    }),
    [
      clearErrors,
      control,
      errors,
      getValues,
      handleSubmit,
      isSubmitting,
      register,
      setError,
      setValue,
      trigger,
      unregister,
      watch
    ]
  );

  const openUploadReportTypeDialog = useCallback(() => {
    openDialog(({ closeDialog }) => (
      <FormV2Context.Provider value={values}>
        <UploadReportTypeDialog
          closeDialog={closeDialog}
          refetch={refetch}
          isMultiple={isMultiple}
          isSubmitting={isSubmitting}
        />
      </FormV2Context.Provider>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  return { openUploadReportTypeDialog };
}

export default useUploadReportTypeDialog;
