import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function deleteAssociation({ folderId, associationId }: { folderId: string; associationId?: string }) {
  return API.association.deleteAssociationSubject(associationId, folderId, {
    ...endpointsConfig.deleteAssociationSubject
  });
}

function useDeleteAssociationForFolderMutation() {
  return useMutation(deleteAssociation);
}

export default useDeleteAssociationForFolderMutation;
