import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function deleteFinancialSecurityClient({ financialSecurityId }: { financialSecurityId: string }) {
  return API.client.financialSecurity.deleteFinancialSecurity('', '', financialSecurityId, {
    ...endpointsConfig.deleteFinancialSecurity
  });
}

function deleteFinancialSecurity({ financialSecurityId }: { financialSecurityId: string }) {
  return API.financialSecurity.deleteFinancialSecurity(financialSecurityId, {
    ...endpointsConfig.deleteFinancialSecurity
  });
}

function useFinancialSecurityDeleteMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(deleteFinancialSecurityClient, deleteFinancialSecurity));
}

export default useFinancialSecurityDeleteMutation;
