import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { FolderLockCreateRequest } from '@ibtm/domain';
import _ from 'lodash';
import moment from 'moment';

import { partialTranslate } from '@libs/common';
import {
  ALTERNATIVE_DATE_FORMAT,
  DatepickerField,
  Dialog,
  FormMode,
  FormV2Context,
  GridLayout,
  typedNameV2
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { useViewModesV2 } from '@libs/common/v2/form';
import { getCalendarDate } from '@libs/common/v2/utils';

import { DomainDictionaryEntry } from '@libs/domain/config';
import { useCreateLockMutation, useUpdateLockMutation } from '@libs/domain/folder';
import { ProceedingQueryKeysEnum } from '@libs/domain/proceeding';

import { useFormOperationValidations } from '../hooks';

export interface IUpdateLockInitialData {
  folderId: string;
  id?: string;
  validFrom?: string;
  validTo?: string;
  version?: number;
  typeKey?: string;
}

interface IProps {
  closeDialog: () => void;
  formMode: FormMode;
  initialData: IUpdateLockInitialData;
}

function AddOrUpdateLockDialog({ formMode, closeDialog, initialData }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: createLock, isLoading: isCreating } = useCreateLockMutation();
  const { mutate: updateFolderLock, isLoading: isUpdating } = useUpdateLockMutation();
  const getLabel = partialTranslate('proceeding:addOrUpdateLockDialog.field');
  const { addOrUpdateLockValidationSchema } = useFormOperationValidations();

  const { createMode } = useViewModesV2(formMode);

  const isLoading = useMemo(() => {
    return isCreating || isUpdating;
  }, [isCreating, isUpdating]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    reset,
    getValues,
    trigger,
    unregister
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(addOrUpdateLockValidationSchema)
  });

  useEffect(() => {
    if (initialData && reset) {
      reset(initialData);
    }
  }, [reset, initialData]);

  const onSubmit = useCallback(
    values => {
      if (createMode) {
        createLock(
          {
            folderId: values.folderId,
            validFrom: moment(values.validFrom).format(ALTERNATIVE_DATE_FORMAT),
            validTo: moment(values.validTo).format(ALTERNATIVE_DATE_FORMAT),
            typeKey: DomainDictionaryEntry.FOLDER_LOCK_TYPE.BLOCKADE_OF_ISSUANCE_OF_CERTIFICATES
          },
          {
            onSuccess: () => {
              queryCache.invalidateQueries(ProceedingQueryKeysEnum.PROCEEDING_DETAILS);
              showSuccessSnackbar(t('proceeding:action.suspendIssuing.successMessage'));
              closeDialog();
            }
          }
        );
      } else {
        updateFolderLock(
          {
            folderLockId: values.id,
            validFrom: getCalendarDate(moment(values.validFrom).format(ALTERNATIVE_DATE_FORMAT)),
            validTo: getCalendarDate(moment(values.validTo).format(ALTERNATIVE_DATE_FORMAT)),
            ..._.pick(values, ['version', 'folderId', 'typeKey'])
          },
          {
            onSuccess: () => {
              queryCache.invalidateQueries(ProceedingQueryKeysEnum.PROCEEDING_DETAILS);
              showSuccessSnackbar(t('proceeding:action.editIssuing.successMessage'));
              closeDialog();
            }
          }
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeDialog, createLock, createMode, showSuccessSnackbar, t, updateFolderLock]
  );

  const values = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      reset,
      handleSubmit
    }),
    [control, errors, getValues, handleSubmit, isSubmitting, register, reset, setValue, trigger, unregister, watch]
  );

  const confirmText = useMemo(() => {
    return createMode ? t('action.add') : t('action.save');
  }, [createMode, t]);

  const title = useMemo(() => {
    return createMode
      ? t('proceeding:action.suspendIssuing.modalTitle')
      : t('proceeding:action.editIssuing.modalTitle');
  }, [createMode, t]);

  return (
    <FormV2Context.Provider value={values}>
      <Dialog
        title={title}
        confirmText={confirmText}
        cancelText={t('action.cancel')}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={closeDialog}
        dialogSize="medium"
        isConfirmLoading={isLoading}
        isOpen
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <GridLayout itemProps={{ xs: 12 }}>
            <DatepickerField
              name={`${typedNameV2<FolderLockCreateRequest>('validFrom')}` as const}
              label={getLabel('validFrom')}
              isRequired
            />
            <DatepickerField
              name={`${typedNameV2<FolderLockCreateRequest>('validTo')}` as const}
              label={getLabel('validTo')}
              isRequired
            />
          </GridLayout>
        </form>
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default AddOrUpdateLockDialog;
