import { useTranslation } from 'react-i18next';

import { DropdownListButton } from '@libs/common/v2';

import { useConfirmAddVehicleDialog } from '@libs/domain/vehicle';

interface IProps {
  selected: string[];
  applicationId: string;
  handleClose: () => void;
}

function VehicleAddSelectedActionButton({ selected, applicationId, handleClose }: IProps) {
  const [t] = useTranslation();
  const { openConfirmAddVehicleDialog } = useConfirmAddVehicleDialog();
  return selected.length > 0 ? (
    <DropdownListButton
      onClick={() => {
        openConfirmAddVehicleDialog(selected, applicationId);
        handleClose();
      }}
      label={t('action.add')}
    />
  ) : null;
}
export default VehicleAddSelectedActionButton;
