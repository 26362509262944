import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { GroupMembersAssignRequest } from '@avispon/group/dist/models';
import { useSnackbar } from '@enigma/fe-ui';

import {
  Dialog,
  FormMode,
  FormV2Context,
  ForwardTableState,
  GridItem,
  GridLayout,
  InputMode,
  SEARCH_TEXT_DEBOUNCE_TIME_LONG,
  TableLayout,
  TextInputField,
  useDebounce,
  useForwardedSelectedRowsAction,
  useIsSmallScreen
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { API, OrganizationQueryKeysEnum } from '@libs/organization-structure/api';
import useUserGroupTable from '@libs/organization-structure/hooks/useUserGroupTable';

import { OrganizationStructureDetailsParams } from '../../models';

interface AddUserToGroupModalProps {
  open?: boolean;
  setIsOpen?: (param: boolean) => void;
  groupId?: string;
}

function AddUserToOrganizationUnitModal({ open, setIsOpen, groupId }: AddUserToGroupModalProps) {
  const queryCache = useQueryCache();
  const { isSmallScreen } = useIsSmallScreen();
  const [t] = useTranslation();
  const { id } = useParams<OrganizationStructureDetailsParams>();
  const [query, setQuery] = useState();
  const [isLoading, setIsLoading] = useState<boolean>();
  const tableProps = useUserGroupTable({ query, id, groupId });
  const { showSuccessSnackbar } = useSnackbar();
  const onChangeDebounced = useDebounce(setQuery, SEARCH_TEXT_DEBOUNCE_TIME_LONG);

  const { handleSubmit, getValues, setValue, reset, control } = useForm<Partial<GroupMembersAssignRequest>>({
    mode: 'onBlur'
  });

  const handleCancel = () => {
    setIsOpen(false);
    reset();
  };

  const { forwardTableStateRef, getSelectedIds, handleDisableAction, isActionDisabled } =
    useForwardedSelectedRowsAction();

  const submit = () => {
    const userIds = getSelectedIds() as string[];
    setIsLoading(true);
    API.group
      .assignMembers({
        body: {
          includeSubordinates: true,
          unitId: id,
          userIds
        },
        groupId
      })
      .then(() => {
        showSuccessSnackbar(t('administration:organizationUnit.message.membersAddedToGroupSuccess'));
        setIsLoading(false);
        queryCache.invalidateQueries(OrganizationQueryKeysEnum.SEARCH_GROUP_LIST);
        setIsOpen(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const values = useMemo(
    () => ({
      formMode: FormMode.CREATE,
      handleSubmit,
      getValues,
      setValue,
      reset,
      control
    }),
    [control, getValues, handleSubmit, reset, setValue]
  );

  return (
    <FormV2Context.Provider value={values}>
      <Dialog
        title={t('administration:organizationUnit.action.addMembersToGroup')}
        confirmText={t('action.add')}
        cancelText={t('action.cancel')}
        onConfirm={handleSubmit(submit)}
        onCancel={handleCancel}
        confirmButtonTooltipText={{ disabled: t('validation:oneOfOptionRequired') }}
        isConfirmLoading={isLoading}
        isConfirmButtonDisabled={isActionDisabled}
        isScrollbarEnabled={false}
        isOpen={open}
        isFullScreen={isSmallScreen}
      >
        <GridLayout itemProps={{ xs: 12 }}>
          <TextInputField
            name="search"
            inputMode={InputMode.FORM}
            label={t('action.search')}
            onInput={e => onChangeDebounced(e.target.value)}
          />
          <GridItem style={{ maxHeight: '60vh', marginBottom: '-30px' }} className="w-full">
            <TableLayout
              {...tableProps}
              isHeaderHidden
              isRefreshEnabled={false}
              isFilterEnabled={false}
              isSection
              isSectionFullTab
              emptyTableText={t('error.notSearchFound')}
              isHiddenSelectRowHeader
            >
              <ForwardTableState ref={forwardTableStateRef} setState={handleDisableAction} />
            </TableLayout>
          </GridItem>
        </GridLayout>
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default AddUserToOrganizationUnitModal;
