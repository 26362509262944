import React from 'react';

import { useTab } from '@libs/common/v2';

import { DomainUIElementEnum } from '@libs/domain/config';

import { ArchiveTabEnum, ArchiveTabsContent, TabType } from '../../common';

import { CarryOnAchivedFolderTab, FolderArchivedTab } from './tabs';

const tabs: TabType[] = [
  {
    label: 'archive:folder.carryOnArchive' as KeyType,
    value: ArchiveTabEnum.CARRY_ON_ARCHIVED,
    viewName: DomainUIElementEnum.ARCHIVE_FOLDER_TO_ARCHIVE_VIEW
  },
  {
    label: 'archive:folder.companyArchive' as KeyType,
    value: ArchiveTabEnum.ARCHIVED,
    viewName: DomainUIElementEnum.ARCHIVE_FOLDER_ARCHIVED_VIEW
  }
];

const tabsContent = [
  {
    value: ArchiveTabEnum.CARRY_ON_ARCHIVED,
    content: <CarryOnAchivedFolderTab />,
    viewName: DomainUIElementEnum.ARCHIVE_FOLDER_TO_ARCHIVE_VIEW
  },
  {
    value: ArchiveTabEnum.ARCHIVED,
    content: <FolderArchivedTab />,
    viewName: DomainUIElementEnum.ARCHIVE_FOLDER_ARCHIVED_VIEW
  }
];

function FolderArchiveContent() {
  const [activeTab, setActiveTab] = useTab<ArchiveTabEnum>(ArchiveTabEnum.CARRY_ON_ARCHIVED);

  return <ArchiveTabsContent activeTab={activeTab} onTabChange={setActiveTab} tabs={tabs} tabsContent={tabsContent} />;
}

export default FolderArchiveContent;
