import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '@libs/user/api';

interface MutationProps {
  accountId: string;
  withNewPassword: boolean;
  password?: string;
}

const restoreUser = async ({ accountId, withNewPassword, password }: MutationProps) => {
  const { data } = await API.user.accountController.restoreAccount(
    {
      accountId,
      body: withNewPassword ? { password } : {}
    },
    {
      ...endpointsConfig.restoreAccount
    }
  );
  return data;
};

export default function useRestoreUserMutation() {
  return useMutation(restoreUser);
}
