import { InsurancePolicyUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function updateInsurance({ id, data }: { id: string; data: InsurancePolicyUpdateRequest }) {
  return API.insurancePolicies.updateInsurancePolicy(id, data, {
    ...endpointsConfig.updateInsurancePolicy
  });
}

function useUpdateInsuranceMutation() {
  return useMutation(updateInsurance);
}

export default useUpdateInsuranceMutation;
