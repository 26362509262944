import { PluginHook } from 'react-table';
import { PermissionCopySearchFilter as PermissionCopySearchFilterClient } from '@ibtm/client-domain';
import { PermissionCopySearchFilter } from '@ibtm/domain';

import { CamelCasePath, useTableLayoutProps } from '@libs/common/v2';

import { useDomainConfigContext } from '@libs/domain/config';

import { usePermissionCopiesQuery } from '../../api';
import usePermissionCopiesColumn from '../../config/permission-copies-column';
import { PermissionCopySnapshotClient, PermissionTypeEnum } from '../../model';

const usePermissionCopiesTable = (
  visibleColumns: Array<CamelCasePath<PermissionCopySnapshotClient>>,
  applicationId: string,
  folderId: string,
  typeKeys: PermissionTypeEnum[],
  editableColumns?: Array<CamelCasePath<PermissionCopySnapshotClient>>,
  tablePlugins?: Array<PluginHook<PermissionCopySnapshotClient>>,
  tableData?: PermissionCopySnapshotClient[],
  isMutableTable = false,
  includePartnershipPermissions?: boolean,
  isTableDataVisible = true,
  validFromColumnHeader?: string
) => {
  const { columns, mappedFilterFields, mappedSortFields } = usePermissionCopiesColumn(
    visibleColumns,
    editableColumns,
    isMutableTable,
    validFromColumnHeader
  );
  const { isOperatorPortal } = useDomainConfigContext();

  const tableHookQueryInitialParams: PermissionCopySearchFilter | PermissionCopySearchFilterClient = {
    applicationIdIn: applicationId ? [applicationId] : undefined,
    ...(isOperatorPortal ? { folderIdIn: folderId ? [folderId] : undefined } : { folderIdEq: folderId }),
    typeKeyIn: typeKeys,
    includePartnershipPermissions: includePartnershipPermissions ? true : undefined
  };

  return useTableLayoutProps({
    ...(tableData
      ? { tableQuery: isTableDataVisible && tableData }
      : { tableHookQuery: isTableDataVisible && usePermissionCopiesQuery }),
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tablePlugins,
      tableOptions: {
        ...(tableData && {
          manualSortBy: false,
          manualFilters: false,
          manualPagination: false
        }),
        initialState: {
          sortBy: [{ id: 'formNumber', desc: false }]
        }
      }
    },
    tableHookQueryInitialParams: tableHookQueryInitialParams as Record<string, any>
  });
};

export default usePermissionCopiesTable;
