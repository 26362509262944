import { usePaginatedQuery } from 'react-query';
import { ArchiveFolderSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { ArchiveQueryKeysEnum } from '../../../common';
import { FolderArchiveSnapshot } from '../../model';
import { parseFolderArchiveList } from '../../utils';
import { endpointsConfig } from '../endpoints-config';

const getFolderToArchive = async (_, params: ArchiveFolderSearchFilter) => {
  const { data } = await API.archiveFolders.getArchiveFolders(params, {
    ...endpointsConfig.getArchiveFolders
  });
  return { ...data, content: parseFolderArchiveList(data.content) };
};

const useFolderToArchiveQuery = (params: ArchiveFolderSearchFilter, queryConfig = {}) => {
  return usePaginatedQuery<IPaginatedModel<FolderArchiveSnapshot>>(
    [ArchiveQueryKeysEnum.FOLDERS_TO_ARCHIVE, params],
    getFolderToArchive,
    queryConfig
  );
};

export default useFolderToArchiveQuery;
