import { PaginatedQueryConfig, usePaginatedQuery } from 'react-query';
import { DepotManagerSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { IDepotManagersListClient } from '../../../model';
import { parseDepotManagersList } from '../../../utils';
import { endpointsConfig } from '../../endpoints-config';
import { ResourceQueryKeysEnum } from '..';

export const getDepotManagers = async (params: DepotManagerSearchFilter) => {
  const { data } = await API.depotManagers.getDepotManagers(params, {
    ...endpointsConfig.getDepotManagers
  });

  return {
    ...data,
    content: parseDepotManagersList(data.content)
  };
};

const useDepotManagersQuery = (
  params: DepotManagerSearchFilter,
  queryConfig?: PaginatedQueryConfig<IPaginatedModel<IDepotManagersListClient>, unknown>
) =>
  usePaginatedQuery<IPaginatedModel<IDepotManagersListClient>>(
    [ResourceQueryKeysEnum.DEPOTS_MANAGERS, params],
    () => getDepotManagers(params),
    queryConfig
  );

export default useDepotManagersQuery;
