import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { SingleUseSubmissionItemCreateRequest } from '@ibtm/domain';
import moment from 'moment';
import * as Yup from 'yup';

import { partialTranslate } from '@libs/common';
import {
  Dialog,
  DialogContentContainer,
  FormV2Context,
  GridLayout,
  NumberInputField,
  SelectOptionPartial,
  typedNameV2
} from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';

import ResourceTypesSelectField from './ResourceTypesSelectField';

type SingleUseSubmissionItemCreateForm = Omit<SingleUseSubmissionItemCreateRequest, 'resourceFormNameKey'> & {
  resourceFormNameKey: SelectOptionPartial<string>;
};

function CreateDisposableDialog({
  closeDialog,
  onSubmit
}: {
  closeDialog: () => void;
  onSubmit: (values: SingleUseSubmissionItemCreateRequest) => void;
}) {
  const [t] = useTranslation();
  const getLabel = partialTranslate('foreignPermits:tabs.socialCommission.tabs.socialCommissionDisposablePermits');

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Partial<SingleUseSubmissionItemCreateForm>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        resourceFormNameKey: Yup.string().required(),
        quantity: Yup.number().required().min(1)
      })
    )
  });

  const handleOnSubmit = setConfirmLoading => {
    const formData = getValues();
    setConfirmLoading(true);
    onSubmit({
      resourceFormNameKey: formData?.resourceFormNameKey?.value,
      quantity: formData?.quantity,
      resourceTypeId: formData?.resourceFormNameKey?.id
    });
    closeDialog();
  };

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={getLabel('createDialogTitle')}
      confirmText={t('action.add')}
      cancelText={t('action.cancel')}
      onConfirm={setConfirmLoading =>
        handleSubmit(() => {
          handleOnSubmit(setConfirmLoading);
        })()
      }
      onCancel={closeDialog}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <DialogContentContainer>
          <GridLayout itemProps={{ xs: 12 }}>
            <ResourceTypesSelectField
              label={getLabel('fields.resourceFormNameKey')}
              fieldName={typedNameV2<SingleUseSubmissionItemCreateRequest>('resourceFormNameKey')}
              key="resourceType"
              typeKeyFilter={{ values: [DomainDictionaryEntry.RESOURCE_FORM_TYPE.SINGLE_PERMIT] }}
              yearLimiter={{
                yearGreaterThanOrEqual: moment().subtract(1, 'year').year(),
                yearLessThanOrEqual: moment().add(1, 'year').year()
              }}
              isRequired
              additionalLimit
            />
            <NumberInputField
              name={typedNameV2<SingleUseSubmissionItemCreateRequest>('quantity')}
              label={getLabel('fields.quantity')}
              min={1}
              isRequired
            />
          </GridLayout>
        </DialogContentContainer>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default CreateDisposableDialog;
