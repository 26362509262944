import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RefetchOptions } from 'react-query/types/core/query';
import { useSnackbar } from '@enigma/fe-ui';
import { NoteDetails, NoteUpdateRequest } from '@ibtm/domain';
import { isEmpty, isNil } from 'lodash';

import { FormMode, FormV2Context, GridLayout, IconButton, Section, typedNameV2, useViewModesV2 } from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';

import { noteInitialFormValues, useNoteApiActions, useNoteFields, useNoteSectionData } from '@libs/domain/notes';

export interface INoteSectionProps {
  applicationId?: string;
  folderId?: string;
  formMode?: FormMode;
  isEditModeForced?: boolean;
  isHeaderVisible?: boolean;
  editButtonActionKey?: string;
  refetch?: (options?: RefetchOptions) => void;
  customHandleChangeForNoteSection?: (value: string | NoteUpdateRequest) => void;
  isDisabled?: boolean;
  customTooltipTitle?: string;
}

function NoteSection({
  applicationId,
  folderId,
  formMode,
  editButtonActionKey,
  isEditModeForced = false,
  isHeaderVisible = true,
  customHandleChangeForNoteSection,
  refetch,
  isDisabled,
  customTooltipTitle
}: INoteSectionProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const notes = useNoteSectionData(applicationId, folderId);
  const data = useMemo(() => notes.data?.data, [notes]);
  const [isNoteInEditMode, setIsNoteInEditMode] = useState(isEditModeForced ?? false);
  const { checkIsElementVisible } = useElementVisibility();
  const { viewMode } = useViewModesV2(formMode);

  const isContentFieldDisabled = useMemo(() => {
    return !checkIsElementVisible(editButtonActionKey);
  }, [checkIsElementVisible, editButtonActionKey]);

  const noteFields = useNoteFields(
    data,
    formMode,
    isNoteInEditMode,
    customHandleChangeForNoteSection,
    isContentFieldDisabled
  );

  const successCallback = useCallback(() => {
    const { data, refetch: dataRefetch } = notes.data;

    setIsNoteInEditMode(false);
    dataRefetch();
    refetch();
    showSuccessSnackbar(isEmpty(data) || isNil(data) ? t('notes:message.saveSuccess') : t('notes:message.editSuccess'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    createNewFolderNote,
    isCreateFolderNoteLoading,
    createNewApplicationNote,
    isCreateApplicationNoteLoading,
    updateFolderNote,
    isEditFolderNoteLoading,
    updateApplicationNote,
    isEditApplicationNoteLoading
  } = useNoteApiActions({ folderId, applicationId, successCallback });

  const isButtonLoading = useMemo(
    () =>
      isCreateFolderNoteLoading ||
      isCreateApplicationNoteLoading ||
      isEditFolderNoteLoading ||
      isEditApplicationNoteLoading,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isCreateApplicationNoteLoading,
      isCreateApplicationNoteLoading,
      isEditFolderNoteLoading,
      isEditApplicationNoteLoading
    ]
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    control,
    reset
  } = useForm<Record<string, any>>({
    defaultValues: noteInitialFormValues
  });

  const handleCancel = useCallback(() => {
    setIsNoteInEditMode(false);
    const currentNoteContent = getValues(typedNameV2<NoteDetails>('content')) as string;
    setValue('content', currentNoteContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (formValues: NoteDetails) => {
    const formData = {
      content: formValues?.content,
      version: data?.version
    };

    if (data?.id) {
      if (folderId) {
        updateFolderNote(formData);
      } else {
        updateApplicationNote(formData);
      }
    } else {
      if (folderId) {
        createNewFolderNote(formValues?.content);
      }
      createNewApplicationNote(formValues?.content);
    }
  };

  useEffect(() => {
    reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isNoteInEditMode]);

  const formValues = useMemo(
    () => ({
      register,
      handleSubmit,
      errors,
      isSubmitting,
      setValue,
      getValues,
      watch,
      trigger,
      unregister,
      control,
      reset
    }),
    [register, handleSubmit, errors, isSubmitting, setValue, getValues, watch, trigger, unregister, control, reset]
  );

  return (
    <FormV2Context.Provider value={formValues}>
      <Section
        title={t('notes:title')}
        isHeaderVisible={isHeaderVisible}
        isLoading={notes.data.isLoading}
        headerContent={
          <>
            {viewMode && !isNoteInEditMode && (
              <IconButton
                icon="EditIcon"
                onClick={() => setIsNoteInEditMode(true)}
                tooltipTitle={customTooltipTitle || t('action.edit')}
                actionKey={editButtonActionKey}
                isDisabled={isDisabled}
                isBackgroundTransparent
              />
            )}
            {isNoteInEditMode && viewMode && (
              <IconButton
                icon="CrossIcon"
                onClick={handleCancel}
                tooltipTitle={t('action.cancel')}
                actionKey={editButtonActionKey}
                isBackgroundTransparent
              />
            )}
            {(isNoteInEditMode || !viewMode) && (
              <IconButton
                icon="SaveIcon"
                onClick={handleSubmit(onSubmit)}
                tooltipTitle={t('action.save')}
                actionKey={editButtonActionKey}
                isLoading={isButtonLoading}
                isBackgroundTransparent
              />
            )}
          </>
        }
        isCollapsable
      >
        <GridLayout itemProps={{ xs: 6 }}>{[...noteFields.filter(field => React.isValidElement(field))]}</GridLayout>
      </Section>
    </FormV2Context.Provider>
  );
}

export default NoteSection;
