import { useTranslation } from 'react-i18next';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  DictionarySelectField,
  GridItem,
  GridLayout,
  InputMode,
  Section,
  TextInputField,
  typedNameV2,
  useFormV2Context,
  useViewModesV2
} from '@libs/common/v2';
import { convertDateToDateFormat } from '@libs/common/v2/utils';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { FieldsType } from '../../model';
import { PlacholderFields } from '../../model/PlaceholderReleaseDocuments';

function CorrectiveConfirmationSection() {
  const [t] = useTranslation();
  const { watch, formMode } = useFormV2Context();
  const { createMode } = useViewModesV2(formMode);
  const getLabel = partialTranslate('releaseDocuments:fields');
  const isCorrection = watch('isCorrection') as boolean;

  return (
    <Section title={t('releaseDocuments:create.sections.confirmation')} isCollapsable>
      <GridLayout itemProps={{ xs: 12 }}>
        <GridItem xs={6}>
          <TextInputField
            name={createMode ? typedNameV2<PlacholderFields>('numberPlaceholder') : typedNameV2<FieldsType>('number')}
            label={getLabel('correctiveConfirmation.number')}
            inputMode={InputMode.VIEW}
            inputProps={{ maxLength: 20 }}
            isRequired
            key={createMode ? 'numberPlaceholder' : 'number'}
          />
        </GridItem>
        <GridItem xs={6}>
          <DatepickerField
            name={
              createMode
                ? typedNameV2<PlacholderFields>('dateOfIssuePlaceholder')
                : typedNameV2<FieldsType>('dateOfIssue')
            }
            label={getLabel('correctiveConfirmation.issuedDate')}
            viewModeDateParser={convertDateToDateFormat}
            inputMode={InputMode.VIEW}
            isRequired
            key={createMode ? 'dateOfIssuePlaceholder' : 'dateOfIssue'}
          />
        </GridItem>
        {isCorrection && !createMode && (
          <GridItem xs={6}>
            <DictionarySelectField
              name={typedNameV2<FieldsType>('statusKey')}
              label={getLabel('invoice.status')}
              inputMode={InputMode.VIEW}
              dictionaryName={DomainDictionaryEnum.INVOICE_STATUS}
              isRequired
            />
          </GridItem>
        )}
      </GridLayout>
    </Section>
  );
}
export default CorrectiveConfirmationSection;
