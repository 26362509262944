import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { isNull, isUndefined } from 'lodash';

import {
  AdditionalFilterEnum,
  Button,
  FormMode,
  GridItem,
  GridLayout,
  TableLayout,
  useDialog,
  useViewModesV2
} from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { DomainUIElementEnum } from '@libs/domain/config';
import { useTradeRulesTable } from '@libs/domain/social-commission';

import { TradeRulesTableEnum, TradeRulesTypeEnum } from '../../common/model/social-comission.model';
import { IntialYearType } from '../../SocialCommissionPage';

import DialogCreateRule from './details/DialogCreateRule';
import TradeRulesPageWrapper from './details/TradeRulesPageWrapper';

const globalFilters = [{ type: AdditionalFilterEnum.YEAR_SELECT, name: TradeRulesTableEnum.YEAR }];

function SocialCommissionTradeRulesTable({
  initialYear,
  globalFilters: customGlobalFilters
}: {
  initialYear?: IntialYearType;
  globalFilters?: (name: string) => {
    type: AdditionalFilterEnum;
    name: string;
    customFilter: JSX.Element;
  }[];
}) {
  const [t] = useTranslation();
  const forward = useRef(null);
  const tableProps = useTradeRulesTable([TradeRulesTypeEnum.SINGLE_PERMITS], initialYear);
  const { openDialog } = useDialog();
  const { showErrorSnackbar } = useSnackbar();
  const [selectedRow, setSelectedRow] = useState<string>();
  const [formMode, setFormMode] = useState<FormMode>(FormMode.VIEW);
  const { editMode } = useViewModesV2(formMode);

  const onAddRule = () => {
    openDialog(({ closeDialog }) => <DialogCreateRule closeDialog={closeDialog} />);
  };
  useEffect(() => {
    forward?.current?.unSelectAll();
  }, [initialYear]);

  return (
    <GridLayout>
      <GridItem xs={7} className="pb-0">
        <TableLayout
          {...tableProps}
          pageTitle={t('foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.title')}
          checkDisabled={() => editMode}
          isActionColumnEnabled={false}
          actionsColumnWidth={0}
          xlsxDownload={{
            fileName: t('foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.title'),
            pathToXLSXTranslation: 'foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.fields',
            apiRequest: params => API.civicBoard.getCivicBoardRulesSnapshotPage(params)
          }}
          bodyProps={{
            onRowClick: ({ row, tableInstance }) => {
              if (!editMode) {
                tableInstance.toggleAllRowsSelected(false);
                setSelectedRow(row?.original?.id);
                row.toggleRowSelected(true);
              } else {
                showErrorSnackbar(
                  t('foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.messages.closeEdit')
                );
              }
            }
          }}
          headerActions={
            <Button
              label={t('global:action.add')}
              onClick={onAddRule}
              isPrimary
              variant="contained"
              actionKey={DomainUIElementEnum.COMMISSION_TRADE_RULES_ADD}
            />
          }
          globalFilters={customGlobalFilters(TradeRulesTableEnum.YEAR) || globalFilters}
          isSectionFullTab
        />
      </GridItem>
      <GridItem xs={5} className="mb-10 h-full">
        <TradeRulesPageWrapper
          selectedRule={!isUndefined(selectedRow) && !isNull(selectedRow) && selectedRow}
          formMode={formMode}
          unSelectAll={() => {
            forward?.current?.unSelectAll();
            setSelectedRow(null);
          }}
          setFormMode={setFormMode}
        />
      </GridItem>
    </GridLayout>
  );
}
export default SocialCommissionTradeRulesTable;
