import { partialTranslate } from '@libs/common';

import { DomainUIElementEnum } from '@libs/domain/config';

import { DepotsTable, DepotTransfersTable } from '../components/list';
import { ResourceFormTypesTable } from '../components/list/resource-form-types/ResourceFormTypesTable';

export interface IResourcesTab {
  label: string;
  value: ResourcesTabEnum;
  content: JSX.Element;
  viewName: DomainUIElementEnum;
}

export enum ResourcesTabEnum {
  RESOURCES = 'RESOURCES',
  DEPOT_TRANSFERS = 'ORDERS',
  FORM_TYPES = 'FORM_TYPES'
}

const useResourcesTabDefinitions = (): Array<IResourcesTab> => {
  const getLabel = partialTranslate('resource:tab');

  return [
    {
      label: getLabel('resources'),
      value: ResourcesTabEnum.RESOURCES,
      content: <DepotsTable />,
      viewName: DomainUIElementEnum.RESOURCES_DEPOT_LIST
    },
    {
      label: getLabel('depotTransfers'),
      value: ResourcesTabEnum.DEPOT_TRANSFERS,
      content: <DepotTransfersTable />,
      viewName: DomainUIElementEnum.RESOURCES_OBJECT_TRANSFER
    },
    {
      label: getLabel('resourceTypes'),
      value: ResourcesTabEnum.FORM_TYPES,
      content: <ResourceFormTypesTable />,
      viewName: DomainUIElementEnum.RESOURCES_FORM_TYPES
    }
  ];
};
export default useResourcesTabDefinitions;
