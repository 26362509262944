import { DefaultApiListPermissionsForGroupRequest } from '@avispon/group';

import { TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';

import { IGroupPermissionClient } from '../../models/group-permission-client.model';

function useListPermissionsForGroupColumns() {
  const { createColumns } = useCreateColumns<IGroupPermissionClient>({
    pathToTranslate: 'user:listPermissionsForGroup.field'
  });

  const mappedSortFields: TableSortMapper<IGroupPermissionClient> = {
    name: 'value',
    nameKey: 'key'
  };

  const columns = createColumns([
    {
      id: 'name',
      type: 'TEXT'
    },
    {
      id: 'nameKey',
      type: 'TEXT'
    }
  ]);

  const mappedFilterFields: TableFilterMapper<IGroupPermissionClient, DefaultApiListPermissionsForGroupRequest> = {
    nameKey: (value: string) => ({ nameKeyFragment: value }),
    name: (value: string) => ({ nameFragment: value })
  };

  return { columns, mappedFilterFields, mappedSortFields };
}

export default useListPermissionsForGroupColumns;
