import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

const confirmTask = (taskId: string) => {
  return API.task.confirm(taskId);
};

const useProcessConfirmTaskMutation = () => {
  return useMutation(confirmTask);
};

export default useProcessConfirmTaskMutation;
