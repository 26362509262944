import { QueryConfig, useQuery } from 'react-query';
import { DriversApiGetDriverDetailsRequest } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { DriverDetailsClient } from '@libs/domain/drivers/model';
import { parseDriverDetailsResults } from '@libs/domain/drivers/utils';

import { endpointsConfig } from '../endpoints-config';

import { DriversQueryKeysEnum } from './DriversQueryKeysEnum';

const getDriverDetailsClient = async (params: DriversApiGetDriverDetailsRequest) => {
  const { data } = await API.client.drivers.getDriverApplicationDetails('', '', params.driverId, {
    ...endpointsConfig.getDriverDetails
  });

  return parseDriverDetailsResults(data);
};

const getDriverDetails = async (params: DriversApiGetDriverDetailsRequest) => {
  const { data } = await API.drivers.getDriverDetails(params.driverId, {
    ...endpointsConfig.getDriverDetails
  });

  return parseDriverDetailsResults(data);
};

export default function useDriverDetailsQuery(params, queryConfig: QueryConfig<DriverDetailsClient, unknown> = {}) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery<DriverDetailsClient>(
    [DriversQueryKeysEnum.DRIVER_DETAILS, params],
    () => getQuery(getDriverDetailsClient, getDriverDetails)(params),
    queryConfig
  );
}
