import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AccountSnapshot } from '@avispon/user';
import { useSnackbar } from '@enigma/fe-ui';
import { ProceedingCreateRequest, ProceedingDetails, ProceedingWithFormsCreateRequest } from '@ibtm/domain';
import _ from 'lodash';

import { typedNameV2, useConfirmDialog, useFormV2Context, useRouter } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { useViewModesV2 } from '@libs/common/v2/form';
import { getCalendarDate, replaceFirstOccurence } from '@libs/common/v2/utils';

import { useUserModalSelect } from '@libs/user';

import { DomainDictionaryEntry } from '@libs/domain/config';
import { FolderDetailsTabEnum, ProceedingDetailsParams } from '@libs/domain/folder';
import {
  ProceedingDetailsClient,
  ProceedingDetailsTabEnum,
  ProceedingQueryKeysEnum,
  ProceedingTypeKeyEnum,
  useDeleteProceedingMutation,
  useProceedingCancelMutation,
  useProceedingCompleteMutation,
  useProceedingCreateMutation,
  useProceedingCreateWithFormsMutation,
  useUpdateProceedingMutation
} from '@libs/domain/proceeding';

type ProceedingDetailsWithReviewApplicationDate = Omit<ProceedingDetails, 'reviewApplicationDate'> & {
  reviewApplicationDate?: Date;
};
type ProceedingWithFormsCreateRequestWithReviewApplicationDate = Omit<
  ProceedingWithFormsCreateRequest,
  'reviewApplicationDate'
> & { reviewApplicationDate?: Date };

export function useDropdownActions(refetch?: () => void, handleClose?: () => void) {
  const queryCache = useQueryCache();
  const { folderId, id } = useParams<ProceedingDetailsParams>();
  const [t] = useTranslation();
  const [confirm] = useConfirmDialog();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbar();
  const { watch, setValue, handleSubmit, formMode } = useFormV2Context();
  const { openUserSelectDialog } = useUserModalSelect();
  const { mutate: cancelProceeding } = useProceedingCancelMutation();
  const { mutate: completeProceeding } = useProceedingCompleteMutation();
  const { mutate: createProceeding } = useProceedingCreateMutation();
  const { mutate: createProceedingWithForms } = useProceedingCreateWithFormsMutation();
  const { mutate: updateProceeding } = useUpdateProceedingMutation();
  const { mutate: deleteProceeding } = useDeleteProceedingMutation();
  const { createMode } = useViewModesV2(formMode);
  const { goToPage, routes, location } = useRouter();

  const number = watch(typedNameV2<ProceedingDetailsClient>('number')) as number;
  const version = watch(typedNameV2<ProceedingDetailsClient>('version')) as number;

  const handleOpenEditMode = (activeTab: ProceedingDetailsTabEnum) => {
    const previousUrl = location?.state?.from as string;
    const newUrl = replaceFirstOccurence('details', 'edit', location.pathname);

    goToPage(
      { pathname: newUrl, search: `tab=${activeTab}` },
      {
        state: {
          from: previousUrl
        }
      }
    );
  };

  const handleDelete = async () => {
    confirm({
      message: t('proceeding:administrative.tab.procedureDetails.delete.confirmMessage', {
        number,
        interpolation: { escapeValue: false }
      }),
      onConfirm: async (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        await deleteProceeding(id, {
          onSuccess: () => {
            showSuccessSnackbar(
              t('proceeding:administrative.tab.procedureDetails.delete.successMessage', {
                number,
                interpolation: { escapeValue: false }
              })
            );
            handleClose?.();
            closeDialog();
            goToPage({
              pathname: routes.folderEdit(folderId),
              search: `tab=${FolderDetailsTabEnum.PROCEEDINGS}`
            });
          },
          onSettled: () => {
            setConfirmLoading(false);
          }
        });
      },
      title: t('proceeding:administrative.tab.procedureDetails.delete.title'),
      confirmType: 'danger'
    });
  };

  const handleFinish = () => {
    confirm({
      title: t('proceeding:administrative.tab.procedureDetails.finish.title'),
      message: t('proceeding:administrative.tab.procedureDetails.finish.confirmMessage', {
        number,
        interpolation: { escapeValue: false }
      }),
      onConfirm: async (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        await completeProceeding(
          { proceedingId: id, version },
          {
            onSuccess: () => {
              showSuccessSnackbar(
                t('proceeding:administrative.tab.procedureDetails.finish.successMessage', {
                  number,
                  interpolation: { escapeValue: false }
                })
              );
              closeDialog();
              refetch?.();
            },
            onSettled: () => {
              setConfirmLoading(false);
            }
          }
        );
      }
    });
  };

  const handleCancel = () => {
    confirm({
      title: t('proceeding:administrative.tab.procedureDetails.cancel.title'),
      message: t('proceeding:administrative.tab.procedureDetails.cancel.confirmMessage', {
        number,
        interpolation: { escapeValue: false }
      }),
      onConfirm: async (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        cancelProceeding(
          { proceedingId: id, version },
          {
            onSuccess: () => {
              showSuccessSnackbar(
                t('proceeding:administrative.tab.procedureDetails.cancel.successMessage', {
                  number,
                  interpolation: { escapeValue: false }
                })
              );
              closeDialog();
              refetch?.();
              queryCache.invalidateQueries(ProceedingQueryKeysEnum.FORM_OPERATION_LIST);
            },
            onSettled: () => {
              setConfirmLoading(false);
            }
          }
        );
      }
    });
  };

  const parseProceedingToCreateWithFormsRequest = (
    values: ProceedingWithFormsCreateRequestWithReviewApplicationDate
  ): ProceedingWithFormsCreateRequest => {
    return {
      ..._.omit(parseProceedingToCreateRequest(values), 'folderId'),
      baseProceeding: values.baseProceeding
    };
  };

  const parseProceedingToCreateRequest = (
    values: ProceedingDetailsWithReviewApplicationDate
  ): ProceedingCreateRequest => {
    return {
      folderId,
      categoryKey: values.categoryKey,
      typeKey: values?.typeKey,
      assigned: values?.assignedTo?.id,
      reviewApplicationDate: getCalendarDate(values.reviewApplicationDate),
      reviewApplicationNumber: values.reviewApplicationNumber,
      legalOfficeDecision: values.legalOfficeDecision,
      legalOfficeDecisionDate: values.legalOfficeDecisionDate,
      wsaComplaint: {
        complaintDate: values.wsaComplaint?.complaintDate,
        complaintSignatureNumber: values.wsaComplaint?.signatureNumber,
        decisionKey: values.wsaComplaint?.resultKey
      },
      nsaComplaint: {
        complaintDate: values.nsaComplaint?.complaintDate,
        complaintSignatureNumber: values.nsaComplaint?.signatureNumber,
        decisionKey: values.nsaComplaint?.resultKey
      }
    };
  };

  const onProceedingCreated = (values: ProceedingDetailsWithReviewApplicationDate, response) => {
    const categoryKey = values.categoryKey ?? values.categoryKey;
    const type =
      categoryKey === DomainDictionaryEntry.PROCEEDING_TYPE.ADMINISTRATIVE
        ? ProceedingTypeKeyEnum.ADMINISTRATION
        : ProceedingTypeKeyEnum.VERIFICATION;
    const previousUrl = location?.state?.from as string;
    if (type === ProceedingTypeKeyEnum.ADMINISTRATION) {
      goToPage(routes.proceedingAdministrationDetails(folderId, response.data.id), {
        state: {
          from: previousUrl
        }
      });
    } else {
      goToPage(routes.proceedingVerificationDetails(folderId, response.data.id), {
        state: {
          from: previousUrl
        }
      });
    }
  };

  const handleSaveProceeding = async (
    values: Omit<ProceedingWithFormsCreateRequest, 'reviewApplicationDate'> &
      ProceedingDetailsWithReviewApplicationDate,
    _onInvalid,
    onSuccess?: () => void,
    showSnackbar = true,
    setLoading: (value: boolean) => void = () => {}
  ) => {
    if (createMode) {
      if ('baseProceeding' in values && values.baseProceeding) {
        createProceedingWithForms(parseProceedingToCreateWithFormsRequest(values), {
          onSuccess: response => onProceedingCreated(values, response),
          onSettled: () => setLoading(false)
        });
      } else {
        createProceeding(parseProceedingToCreateRequest(values), {
          onSuccess: response => onProceedingCreated(values, response),
          onSettled: () => setLoading(false)
        });
      }
    } else {
      updateProceeding(
        {
          proceedingId: values.id,
          proceedingUpdateRequest: {
            categoryKey: values.categoryKey,
            assigned: values.assignedTo.id,
            typeKey: values.typeKey,
            reviewApplicationDate: getCalendarDate(values.reviewApplicationDate),
            reviewApplicationNumber: _.isEmpty(values.reviewApplicationNumber) ? null : values.reviewApplicationNumber,
            legalOfficeDecision: values.legalOfficeDecision,
            legalOfficeDecisionDate: values.legalOfficeDecisionDate,
            startDecisionDelivered: values.startDecisionDelivered,
            decisionTypeKey: values.decisionTypeKey,
            wsaComplaint: {
              complaintDate: values.wsaComplaint.complaintDate,
              complaintSignatureNumber: values.wsaComplaint.signatureNumber,
              ...(values.wsaComplaint?.resultKey && {
                decisionKey: values.wsaComplaint.resultKey
              })
            },
            nsaComplaint: {
              complaintDate: values.nsaComplaint.complaintDate,
              complaintSignatureNumber: values.nsaComplaint.signatureNumber,
              ...(values.nsaComplaint?.resultKey && {
                decisionKey: values.nsaComplaint.resultKey
              })
            },
            version: values.version
          }
        },
        {
          onSuccess: () => {
            if (showSnackbar) {
              showSuccessSnackbar(t('proceeding:messages.updateProceedingSuccess'));
            }
            refetch?.();
            onSuccess?.();
          },
          onSettled: () => setLoading(false)
        }
      );
    }
  };

  const handleSaveAndGoNext = async values => {
    if (values.baseProceeding) {
      createProceedingWithForms(parseProceedingToCreateWithFormsRequest(values), {
        onSuccess: response => onProceedingCreated(values, response)
      });
    } else {
      createProceeding(parseProceedingToCreateRequest(values), {
        onSuccess: response => onProceedingCreated(values, response)
      });
    }
  };

  const handleAddAdministrativeProceeding = (categoryKey: string) => {
    if (categoryKey === DomainDictionaryEntry.PROCEEDING_TYPE.ADMINISTRATIVE) {
      showErrorSnackbar(t('proceeding:messages.proceedingNotSaved'));
    } else {
      goToPage(routes.proceedingCreateAdministrationFromBase(folderId, id), {
        state: {
          from: routes.proceedingVerificationEdit(folderId, id)
        }
      });
    }
  };

  const handleOnConductingPersonChanged = (user: AccountSnapshot) => {
    const { personalData, id } = user;
    setValue(typedNameV2<ProceedingDetailsClient>('assignedTo'), {
      id,
      name: personalData ? `${personalData.name} ${personalData.surname}` : user.login
    });
  };

  const handleChangeConductingPerson = () => {
    openUserSelectDialog(
      handleOnConductingPersonChanged,
      t('proceeding:administrative.tab.procedureDetails.field.conductingPerson')
    );
  };

  return useMemo(
    () => ({
      handleDelete,
      handleFinish,
      handleCancel,
      handleSaveAndGoNext: handleSubmit(handleSaveAndGoNext),
      handleAddAdministrativeProceeding,
      handleChangeConductingPerson,
      handleSaveProceeding,
      handleOpenEditMode
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folderId, id, version]
  );
}
