import { ResourceObjectPoolSearchFilter } from '@ibtm/domain';

import { SelectOption, TableFilter, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { TAmountRange } from '@libs/common/v2/utils';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';
import { ResourceObjectPoolClient } from '@libs/domain/resource';

import useFilteredDictionary from '../useFilteredDictionary';

import useResourceTransformTypeFieldsChange, { ResourceTypeFieldName } from './useResourceTransformTypeFieldsChange';

const useResourceTransformTypeObjectPoolsColumns = () => {
  const { handleChange } = useResourceTransformTypeFieldsChange();

  const { entries: filteredGroupDictionaryEntries } = useFilteredDictionary(DomainDictionaryEnum.RESOURCE_FORM_GROUP, [
    DomainDictionaryEntry.RESOURCE_FORM_GROUP.S_CERTIFICATE
  ]);

  const { createColumns } = useCreateColumns<ResourceObjectPoolClient>({
    pathToTranslate: 'resource:fields',
    isMutableTable: true
  });

  const mappedSortFields: TableSortMapper<ResourceObjectPoolClient> = {
    group: 'resourceType.groupKey',
    country: 'resourceType.countryKey',
    name: 'resourceType.nameKey'
  };

  const mappedFilterFields: TableFilterMapper<ResourceObjectPoolClient, ResourceObjectPoolSearchFilter> = {
    group: (groups: SelectOption<string>[]) => ({ groupKeyIn: groups.map(group => group.value) }),
    country: (countries: SelectOption<string>[]) => ({ countryCodeKeyIn: countries.map(country => country.value) }),
    name: (names: SelectOption<string>[]) => ({ nameKeyIn: names.map(name => name.value) }),
    numberFrom: (amountRange: TAmountRange) => ({
      numberFromGreaterThanOrEqual: amountRange.min,
      numberFromLessThanOrEqual: amountRange.max
    }),
    numberTo: (amountRange: TAmountRange) => ({
      numberToGreaterThanOrEqual: amountRange.min,
      numberToLessThanOrEqual: amountRange.max
    }),
    amount: (amountRange: TAmountRange) => ({
      amountToGreaterThanOrEqual: amountRange.min,
      amountToLessThanOrEqual: amountRange.max
    })
  };

  const columns = createColumns([
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_GROUP,
      accessor: 'group',
      filter: props => (
        <TableFilter.DictionaryMultiSelect
          {...props}
          dictionary={DomainDictionaryEnum.RESOURCE_FORM_GROUP}
          options={filteredGroupDictionaryEntries}
        />
      ),
      width: 300
    },
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.COUNTRY_CODE,
      accessor: 'country',
      width: 200
    },
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_NAME,
      accessor: 'name',
      width: 300
    },
    {
      type: 'NUMBER',
      accessor: 'numberFrom',
      width: 200,
      isEditable: true,
      mutableTableInputProps: (row, context) => {
        return {
          min: row?.numberFrom,
          max: row?.numberTo,
          onChange: event => {
            handleChange(row, context, Number(event.target.value), ResourceTypeFieldName.NUMBER_FROM);
          }
        };
      },
      filter: TableFilter.NumberRange
    },
    {
      type: 'NUMBER',
      accessor: 'numberTo',
      width: 200,
      isEditable: true,
      mutableTableInputProps: (row, context) => {
        return {
          min: row?.numberFrom,
          max: row?.numberTo,
          onChange: event => {
            handleChange(row, context, Number(event.target.value), ResourceTypeFieldName.NUMBER_TO);
          }
        };
      },
      filter: TableFilter.NumberRange
    },
    {
      type: 'NUMBER',
      accessor: 'amount',
      width: 200,
      isEditable: true,
      mutableTableInputProps: (row, context) => {
        return {
          min: 1,
          max: (row?.numberTo || 0) - (row?.numberFrom || 0) + 1,
          onChange: event => {
            handleChange(row, context, Number(event.target.value), ResourceTypeFieldName.AMOUNT);
          }
        };
      },
      filter: TableFilter.NumberRange
    }
  ]);

  return { columns, mappedFilterFields, mappedSortFields };
};

export default useResourceTransformTypeObjectPoolsColumns;
