import { QueryConfig, useQuery } from 'react-query';
import { ApplicationPrintingItem } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import PrintsQueryKeysEnum from './PrintsQueryKeysEnum';

const getApplicationPrintingItem = async (_, applicationId: string) => {
  const { data } = await API.application.getApplicationPrintingItem(applicationId, {
    ...endpointsConfig.previewLastGenerateResourceAssignment
  });
  return data;
};

const useGetApplicationPrintingItem = (
  applicationId: string,
  queryConfig?: QueryConfig<ApplicationPrintingItem, unknown>
) => useQuery([PrintsQueryKeysEnum.PRINTS_DETAILS, applicationId], getApplicationPrintingItem, queryConfig);

export default useGetApplicationPrintingItem;
