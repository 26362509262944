import { DataSourceLite, DataSourceMediatorColumn, DataSourceMediatorDetailsSnapshot } from '@stack/report/dist/models';

import { useReportSourceDetailsQuery } from '../api';

export type DataSource = DataSourceLite & DataSourceMediatorDetailsSnapshot;

function useReportDataSourceColumns(dataSource?: DataSource): DataSourceMediatorColumn[] {
  const sourceId = dataSource?.id;
  const { data: dataSourceSnapshot } = useReportSourceDetailsQuery(
    { sourceId },
    {
      enabled: Boolean(sourceId && !dataSource.sourceColumns)
    }
  );

  const sourceColumns: DataSourceMediatorColumn[] =
    (dataSourceSnapshot?.sourceColumns || dataSource?.sourceColumns) ?? [];

  return sourceColumns;
}

export default useReportDataSourceColumns;
