import { DomainDictionaryEntry } from '../../config';

export const editablePermissionValidityKeys = [
  DomainDictionaryEntry.DURATION_OF_PERMIT.a,
  DomainDictionaryEntry.DURATION_OF_PERMIT.b,
  DomainDictionaryEntry.DURATION_OF_PERMIT.c,
  DomainDictionaryEntry.DURATION_OF_PERMIT.d,
  DomainDictionaryEntry.DURATION_OF_PERMIT.e,
  DomainDictionaryEntry.DURATION_OF_PERMIT.f
];
