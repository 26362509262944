import { QueryConfig, useQuery } from 'react-query';
import { FileMetadataObject, GenerateLabelRequest } from '@ibtm/domain';
import { AxiosResponse } from 'axios';

import { API } from '@libs/domain/api';

import { ApplicationQueryKeysEnum } from '.';

const generateLabel = (generateLabelRequest: GenerateLabelRequest) => {
  return API.label.generate(generateLabelRequest);
};

export default function useGenerateLabelQuery(
  generateLabelRequest: GenerateLabelRequest,
  queryConfig: QueryConfig<any, unknown> = {}
) {
  return useQuery<AxiosResponse<FileMetadataObject>>(
    [ApplicationQueryKeysEnum.GENERATE_LABEL, { generateLabelRequest }],
    () => generateLabel(generateLabelRequest),
    queryConfig
  );
}
