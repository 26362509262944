import React from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { TextField } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { Button, useFormV2Context } from '@libs/common/v2';

import { IUploadFileResponse, useUploadFileMutation } from '@libs/file';
import { HasPermission } from '@libs/permission';
import { UIElementNameEnum } from '@libs/permission/models';

interface UploadFieldProps {
  name: FieldPath<FieldValues>;
  onSuccessLoad: () => void;
  label?: string;
  required?: boolean;
  isLoading?: boolean;
  uploadFileEndpoint?: string;
  variant?: 'contained' | 'outlined';
  isDisabled?: boolean;
  actionKey?: UIElementNameEnum;
}

function UploadButton({
  name,
  required,
  label,
  isLoading,
  uploadFileEndpoint,
  onSuccessLoad,
  isDisabled,
  variant = 'contained',
  actionKey
}: UploadFieldProps) {
  const { control, setValue, trigger } = useFormV2Context();
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();

  const { mutate: uploadFile, isLoading: isFileLoading } = useUploadFileMutation({
    onSuccess: ({ data, file }: IUploadFileResponse) => {
      showSnackbar('success', t('attachments:message.uploadFinished'));
      setValue(name, {
        fileId: data.id,
        type: file.type,
        name: file.name
      });
      trigger();
      onSuccessLoad();
    },
    onError: () => {
      setValue(name, null);
      showSnackbar('error', t('attachments:message.uploadFailed'));
    }
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    setValue(name, file);

    if (file) {
      showSnackbar('info', t('attachments:message.uploadStarted'));
      uploadFile({ file, uploadFileEndpoint });
    }
  };

  const inputId = uuidv4();

  return (
    <HasPermission actionKeys={[actionKey]}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, onBlur }, fieldState }) => (
          <>
            <label htmlFor={inputId} className="p-0 m-0 ml-16 mt-auto">
              <input
                id={inputId}
                name={name}
                type="file"
                onChange={handleFileChange}
                disabled={isFileLoading || isLoading || isDisabled}
                hidden
              />

              <Button
                label={label ?? t('action.chooseFile')}
                variant={variant}
                component="span"
                isLoading={isFileLoading || isLoading}
                disabled={isDisabled}
                isNoMargin
                isPrimary
              />
            </label>

            <TextField
              value={(value?.name as unknown) || ''}
              onChange={onChange}
              onBlur={onBlur}
              required={required || false}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              disabled={isFileLoading}
              className="hidden"
              hidden
              fullWidth
            />
          </>
        )}
      />
    </HasPermission>
  );
}

export default UploadButton;
