import { CamelCasePath, ITableLayoutColumn } from '@libs/common/v2';

interface IParams<T extends Record<string, any>, D extends Record<string, any> = T> {
  /**
   * Tablica obiektów zwróconych przez hook useCreateColumns
   */
  columns: ITableLayoutColumn<T, D>[];
  /**
   * Tablica ścieżek do porządanej wartości w modelu. Przykład `folder.number`
   */
  hiddenColumns?: CamelCasePath<T>[];
}
/**
 * Hook do wykorzystania kolumn stworzonych przy pomocy useCreateColumns,
 * z możliwością odfiltrowania niechcianych kolumn
 */
function useColumns<T extends Record<string, any>, D extends Record<string, any> = T>({
  columns,
  hiddenColumns
}: IParams<T, D>): ITableLayoutColumn<T, D>[] {
  return columns.filter(column => !hiddenColumns.includes(column.id as CamelCasePath<T>));
}

export default useColumns;
