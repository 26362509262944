import { isNil } from 'lodash';
import {
  array as YupArray,
  boolean as YupBoolean,
  number as YupNumber,
  object as YupObject,
  string as YupString
} from 'yup';

import { SelectOption, ValidatorDetails, ValidatorEnums } from '@libs/common/v2';

import FieldTypeEnum from '../model/FieldTypeEnum';
import { isDateValid } from '../util/validation.utils';

const createValidationRegistry = (contextValidators?: { [key: string]: ValidatorDetails }) => {
  const selectValidators = {
    _base: YupObject().nullable(),
    contextValidator: (validator: ValidatorEnums) => {
      const validation = contextValidators?.[validator];

      if (validation) {
        return YupObject()
          .transform(value => value || null)
          .test('isMatchRegex', validation.message, fieldValue => {
            if (!isNil(fieldValue)) {
              const { value, selectValue } = fieldValue;
              return (value || selectValue).match(new RegExp(validation.regex));
            }
            return true;
          });
      }

      return YupObject().nullable();
    }
  };

  const multipleSelectValidators = {
    _base: YupArray().nullable(),
    contextValidator: (validator: ValidatorEnums, message?: string) => {
      const validation = contextValidators?.[validator];

      if (validation) {
        return YupArray()
          .transform(value => value || null)
          .test('isMatchRegex', message || validation.message, (value: Array<SelectOption<string>>) => {
            if (!isNil(value)) {
              return value?.every(element => {
                const elementValue: string = element?.inputValue || element.value;

                return !isNil(elementValue?.match(new RegExp(validation.regex)));
              });
            }

            return true;
          });
      }
      return YupArray().nullable();
    }
  };

  const validationRegistryMultiple = {
    [FieldTypeEnum.AUTOCOMPLETE]: multipleSelectValidators,
    [FieldTypeEnum.DICTIONARY]: multipleSelectValidators,
    [FieldTypeEnum.DICTIONARY_QUICK_CHANGEABLE]: multipleSelectValidators
  };

  return {
    [FieldTypeEnum.TEXT]: {
      _base: YupString().nullable(),
      contextValidator: (validator: ValidatorEnums) => {
        const validation = contextValidators?.[validator];
        if (validation) {
          return YupString()
            .transform(value => value || null)
            .matches(new RegExp(validation.regex), validation.message);
        }
        return YupString().nullable();
      }
    },
    [FieldTypeEnum.AUTOCOMPLETE]: selectValidators,
    [FieldTypeEnum.DICTIONARY]: selectValidators,
    [FieldTypeEnum.DICTIONARY_QUICK_CHANGEABLE]: selectValidators,
    [FieldTypeEnum.NUMBER]: {
      _base: YupNumber().nullable()
    },
    [FieldTypeEnum.BOOLEAN]: { _base: YupBoolean().nullable() },
    [FieldTypeEnum.DATETIME]: {
      _base: YupString().nullable(),
      required: (requiredMessage?: string, invalidDateMessage?: string) =>
        YupString().required(requiredMessage).concat(isDateValid(invalidDateMessage)),
      isDateValid: (invalidDateMessage?: string) => isDateValid(invalidDateMessage)
    },
    multiple: validationRegistryMultiple
  };
};

export default createValidationRegistry;
