import { useTranslation } from 'react-i18next';
import { FolderHistoryDetails, FolderHistorySearchFilter, NamedObjectDetails } from '@ibtm/domain';

import { ALTERNATIVE_DATE_TIME_FORMAT, SelectOption, TableFilterMapper, useCreateColumns } from '@libs/common/v2';
import { convertDateToDateTimeFormat, DateRangeISO, getValue } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';
import { UserFilterV2 } from '@libs/user';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

function useFolderHistoryColumns() {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();
  const { createColumns } = useCreateColumns<FolderHistoryDetails>({
    pathToTranslate: 'history:field'
  });

  const mappedFilterFields: TableFilterMapper<FolderHistoryDetails, FolderHistorySearchFilter> = {
    created: ({ dateFrom, dateTo }: DateRangeISO) => ({
      createdGreaterThanOrEqual: dateFrom,
      createdLessThanOrEqual: dateTo
    }),
    createdByName: (createdByIds: SelectOption<string>[]) => ({
      createdByIdIn: createdByIds.map(({ value }) => value)
    }),
    statusKey: (statusKeys: SelectOption<string>[]) => ({ statusesIn: statusKeys.map(({ value }) => value) }),
    description: (descriptionFragment: SelectOption<string>[]) => ({
      descriptionIn: descriptionFragment.map(({ value }) => value)
    })
  };

  const getTranslatedDescription = (descriptionKey: string) => {
    return translate(DomainDictionaryEnum.FOLDER_CHANGE, descriptionKey);
  };

  const getBorrowerHistoryDescription = (borrower: NamedObjectDetails) => {
    return t('history:field.descriptionBorrwedBy', { borrower: borrower?.name });
  };

  const getBorrowerChangeHistoryDescription = (borrower: NamedObjectDetails) => {
    return t('history:field.descriptionBorrowdChanged', { borrower: borrower?.name });
  };

  const getDescriptionTranslation = (descriptionKey: string, borrower: NamedObjectDetails) => {
    switch (descriptionKey) {
      case DomainDictionaryEntry.FOLDER_CHANGE.HAS_BEEN_BORROWED:
        return getBorrowerHistoryDescription(borrower);
      case DomainDictionaryEntry.FOLDER_CHANGE.BORROWER_CHANGED:
        return getBorrowerChangeHistoryDescription(borrower);
      default:
        return getTranslatedDescription(descriptionKey);
    }
  };

  const columns = createColumns([
    {
      id: 'created',
      type: 'DATE',
      header: 'modified',
      customAccessor: row => getValue(convertDateToDateTimeFormat(row?.created, ALTERNATIVE_DATE_TIME_FORMAT)),
      width: 250
    },
    {
      type: 'TEXT',
      header: 'modifier',
      accessor: 'createdBy.name',
      filter: UserFilterV2,
      width: 200
    },
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.FOLDER_STATUS,
      accessor: 'statusKey',
      width: 150
    },
    {
      header: 'remarks',
      type: 'DICTIONARY_MULTI_SELECT',
      accessor: 'description',
      dictionaryName: DomainDictionaryEnum.FOLDER_CHANGE,
      customAccessor: row => getDescriptionTranslation(row?.description, row?.borrowedBy),
      width: 300
    }
  ]);

  return { columns, mappedFilterFields };
}

export default useFolderHistoryColumns;
