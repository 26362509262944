import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountSnapshot } from '@avispon/user/dist/models';

import {
  DictionarySelectField,
  GridItem,
  GridLayout,
  InputMode,
  Section,
  TextInputField,
  typedNameV2,
  useFormV2Context,
  useViewModesV2
} from '@libs/common/v2';

import { UserDictionaryEntryNameEnum } from '@libs/user/common';
import { SectionNames, useConfigurationContext } from '@libs/user/context';

function WorkSystemHoursSection() {
  const [t] = useTranslation();
  const { formMode } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);
  const { isFieldVisible, renderAdditionalFields } = useConfigurationContext();

  const inputMode = useMemo(() => {
    if (viewMode) {
      return InputMode.VIEW;
    }

    return InputMode.FORM;
  }, [viewMode]);

  return (
    <Section title={t('user:section.workSystem')} isCollapsable>
      <GridLayout>
        {isFieldVisible('workSystem') && (
          <GridItem xs={12}>
            <DictionarySelectField
              inputMode={inputMode}
              name={typedNameV2<AccountSnapshot>('workSystem.typeKey')}
              label={t('user:field.systemTypeKey')}
              dictionaryName={UserDictionaryEntryNameEnum.WORK_SYSTEM_TYPE}
              stringValue
              isRequired
            />
          </GridItem>
        )}
        {isFieldVisible('workHours') && (
          <GridItem xs={12}>
            <TextInputField
              inputMode={inputMode}
              name={typedNameV2<AccountSnapshot>('workSystem.workHours')}
              label={t('user:section.workingHours')}
            />
          </GridItem>
        )}
        <GridItem xs={12}>{renderAdditionalFields(SectionNames.WORK_SYSTEM_HOURS_SECTION)}</GridItem>
      </GridLayout>
    </Section>
  );
}

export default WorkSystemHoursSection;
