import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FolderLimitDetails } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import {
  AdditionalFilterEnum,
  BackButton,
  TableLayout,
  useFormV2Context,
  useRouter,
  YearFilterContext
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { useDomainConfigContext } from '@libs/domain/config';
import { BasicLimitTableEnum, useSingleAuthorizationLimitsTable } from '@libs/domain/social-commission';

import { FolderDetailsParams } from '../../../../folder';
import { parseSingleAuthorizationLimitsClientPayload, parseSingleAuthorizationLimitsPayload } from '../../../utils';

import BasicLimitTableRowActions from './BasicLimitTableRowActions';

const translate = partialTranslate('folder:details.tab.singleAuthorization.section.basicLimit');

interface IProps {
  isSection?: boolean;
  isYearFilter?: boolean;
  queryInitialParams?: { typeKeyIn: string[]; folderId?: string };
  isCollapsable?: boolean;
  isGoBackButtonVisible?: boolean;
  titleHeaderContent?: React.ReactNode;
}

function BasicLimitTable({
  isSection = true,
  queryInitialParams,
  isYearFilter,
  isCollapsable,
  isGoBackButtonVisible = false,
  titleHeaderContent
}: IProps) {
  const { folderId } = useParams<FolderDetailsParams>();
  const { formMode } = useFormV2Context();
  const { editMode } = useViewModesV2(formMode);
  const { routes } = useRouter();
  const { setYearFilterContextValues, isOperatorPortal, yearFilterContextValues } = useDomainConfigContext();
  const { getQuery } = useGetApiQueryByPerspective();
  const tableProps = useSingleAuthorizationLimitsTable(folderId, queryInitialParams, isYearFilter);
  const globalFilters = [
    {
      type: AdditionalFilterEnum.YEAR_SELECT,
      name: BasicLimitTableEnum.YEAR,
      setGlobalYearSelect: setYearFilterContextValues
    }
  ];
  const xlsxDownloadApiRequest = getQuery(
    params =>
      API.client.folderLimits.getFolderLimitsPage(
        '',
        parseSingleAuthorizationLimitsClientPayload({ maxYear: yearFilterContextValues?.value, ...params })
      ),
    params => API.folderLimits.getFolderLimitsPage(parseSingleAuthorizationLimitsPayload({ folderId, ...params }))
  );

  return (
    <TableLayout
      {...tableProps}
      {...(!titleHeaderContent && { pageTitle: translate('title') })}
      selectionColumnWidth={editMode ? 100 : 0}
      xlsxDownload={{
        fileName: translate('title'),
        pathToXLSXTranslation: 'folder:details.tab.singleAuthorization.section.basicLimit.field',
        apiRequest: params => xlsxDownloadApiRequest(params)
      }}
      {...(isOperatorPortal && {
        rowActions: ({ original }) => <BasicLimitTableRowActions data={original as FolderLimitDetails} />
      })}
      globalFilters={isYearFilter && globalFilters}
      sectionProps={{ titleHeaderContent }}
      headerActions={<>{isGoBackButtonVisible && <BackButton link={routes.disposablePermitsList()} />}</>}
      actionsColumnWidth={isOperatorPortal ? 115 : 0}
      isSection={isSection}
      isFilterEnabled={false}
      isCollapsable={isCollapsable}
    />
  );
}

export function BasicLimitTableWrapper({ isSection = true, maxYear }: { isSection?: boolean; maxYear: number }) {
  const { yearFilterContextValues } = useDomainConfigContext();

  const values = useMemo(
    () => ({ maxYear: maxYear ? maxYear.toString() : yearFilterContextValues?.value }),
    [maxYear, yearFilterContextValues?.value]
  );
  return (
    <YearFilterContext.Provider value={values}>
      <BasicLimitTable isSection={isSection} />
    </YearFilterContext.Provider>
  );
}

export default BasicLimitTable;
