import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import { AlertPermissionElementMapper } from '@libs/alert/config';
import { DictionaryPermissionElementMapper } from '@libs/dictionary/configs';
import { DocumentTemplatePermissionElementMapper } from '@libs/document-template/config';
import { GroupPermissionElementMapper } from '@libs/group/config';
import { NotificationPermissionElementMapper } from '@libs/notification/config';
import { OrganizationStructurePermissionElementMapper } from '@libs/organization-structure/config';
import { PermissionElementMap } from '@libs/permission';
import { ReportPermissionElementMapper } from '@libs/report/config';
import { UserPermissionElementMapper } from '@libs/user/config';

import { ApplicationProcessPermissionElementMapper, DomainPermissionElementMapper } from '@libs/domain/config';

export const PermissionElementMapper: PermissionElementMap<UIElementNameEnum> = {
  ...ApplicationProcessPermissionElementMapper,
  ...DomainPermissionElementMapper,
  ...ReportPermissionElementMapper,
  ...AlertPermissionElementMapper,
  ...OrganizationStructurePermissionElementMapper,
  ...UserPermissionElementMapper,
  ...GroupPermissionElementMapper,
  ...DictionaryPermissionElementMapper,
  ...DocumentTemplatePermissionElementMapper,
  ...NotificationPermissionElementMapper
};
