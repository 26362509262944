import { useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';

import { TabContent, TabsContent, useErrorsHandlerV2 } from '@libs/common/v2';
import { important } from '@libs/common/v2/utils/styles.utils';

import { FolderDetailsTabEnum, IFolderTab, RegisteredFolderFormEnum, useSaveFolder } from '@libs/domain/folder';
import { TabContext } from '@libs/domain/general';

interface IProps {
  setActiveTab: (tab: FolderDetailsTabEnum) => void;
  activeTab: FolderDetailsTabEnum;
  isTabVisible: (folderType: FolderDetailsTabEnum) => boolean;
  visibleTabs: Array<IFolderTab>;
  setSubmitting: (value: boolean) => void;
  isHeaderHidden: boolean;
  folderType: string;
}

function FolderTabsContent({
  setActiveTab,
  setSubmitting,
  activeTab,
  isTabVisible,
  visibleTabs,
  isHeaderHidden,
  folderType
}: IProps) {
  const { isSubmitting } = useSaveFolder(folderType);
  const classes = useStyles();
  const { saveFieldList } = useErrorsHandlerV2<FolderDetailsTabEnum, RegisteredFolderFormEnum>(
    setActiveTab,
    RegisteredFolderFormEnum.FOLDER_FORM
  );

  useEffect(() => {
    setSubmitting(isSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  const activeTabTitle = useMemo(() => {
    return visibleTabs.find(({ value }) => value === activeTab)?.label;
  }, [activeTab, visibleTabs]);

  const values = useMemo(
    () => ({ activeTabId: activeTab, activeTabTitle, isHeaderHidden, setActiveTabId: setActiveTab }),
    [activeTab, activeTabTitle, isHeaderHidden, setActiveTab]
  );

  return (
    <TabContext.Provider value={values}>
      <TabsContent
        saveFieldList={saveFieldList}
        scrollerPrefixId={RegisteredFolderFormEnum.FOLDER_FORM}
        value={activeTab}
        className={classes.tabs}
      >
        {visibleTabs.map(({ content, value }) => {
          return (
            <TabContent value={value} key={value} isInnerScroll={false}>
              {isTabVisible(value) && <>{content}</>}
            </TabContent>
          );
        })}
      </TabsContent>
    </TabContext.Provider>
  );
}

const useStyles = makeStyles({
  tabs: {
    overflow: important('hidden')
  }
});

export default FolderTabsContent;
