import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ForeignPermissionTransportApplicationRecordSnapshot,
  ForeignPermissionTransportApplicationRecordUpdateRequest,
  ResourceTypeLite
} from '@ibtm/domain';
import _ from 'lodash';
import moment from 'moment';
import { number as YupNumber, object as YupObject, ref, string as YupString } from 'yup';

import {
  TableButtonAddNewLastRow,
  TableButtonDelete,
  TableEditRow,
  TableLayout,
  useFormV2Context
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { typedNameV2, useViewModesV2 } from '@libs/common/v2/form';

import { useFormDirtyContext } from '@libs/meta-form/context';
import { useDiscardChangesCustomFunctions } from '@libs/meta-form/services';

import { useSinglePermitTable } from '@libs/domain/application-single-permit/hooks';

import { useApplicationsForeignSpoPermissionsQuery } from '../../application/api';
import ApplicationQueryKeysEnum from '../../application/api/queries/ApplicationQueryKeysEnum';
import { IApplicationDetailsPageParams } from '../../application/model';
import { DomainUIElementEnum } from '../../config';

export const SINGLE_PERMIT_MIN_RESOURCE_REQUESTS = 1;
export const SINGLE_PERMIT_MIN_RESOURCE_ISSUED = 0;
export const SINGLE_PERMIT_MAX_RESOURCE_REQUESTS = Number.MAX_SAFE_INTEGER;

export interface ISinglePermitTable {
  fieldId?: FieldPath<FieldValues>;
  applicationCreatedAt?: string;
}
export interface ForeignPermissionTransportApplicationRecordUpdateRequestExtended
  extends ForeignPermissionTransportApplicationRecordUpdateRequest {
  resourceType: ResourceTypeLite;
  id: string;
  issued: number;
}

function SinglePermitTable({ fieldId, applicationCreatedAt }: ISinglePermitTable) {
  const { applicationId } = useParams<IApplicationDetailsPageParams>();
  const queryCache = useQueryCache();
  const { setIsDirty } = useFormDirtyContext();
  const [t] = useTranslation();
  const [tableData, setTableData] = useState<Array<ForeignPermissionTransportApplicationRecordUpdateRequestExtended>>(
    []
  );
  const { data, isLoading } = useApplicationsForeignSpoPermissionsQuery({ applicationId, params: { size: 1000 } });
  const { setValue, formMode } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);
  const rowId = useRef(0);

  const createRowId = () => {
    const result = rowId.current;
    rowId.current += 1;
    return result.toString();
  };

  const validationSchema = YupObject<ForeignPermissionTransportApplicationRecordUpdateRequestExtended>({
    resourceType: YupObject({
      nameKey: YupString()
        .required()
        .test('isUnique', t('validation:valueIsAlreadySelected'), function isUnique(resourceType: string) {
          // eslint-disable-next-line react/no-this-in-sfc
          const { context } = this.options;
          const selectedResourceTypeIds: Array<string> = [];
          Object.values(context).forEach(resourceTypeObject => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const nameKey = resourceTypeObject?.resourceType?.nameKey;
            const resourceTypeNameKey = _.isString(nameKey) ? nameKey : nameKey?.value;
            if (resourceTypeNameKey) {
              selectedResourceTypeIds.push(resourceTypeNameKey);
            }
          });
          const nonUniqueIds = selectedResourceTypeIds.filter(id => id === resourceType);
          return _.size(nonUniqueIds) < 2;
        })
    }),
    requested: YupNumber()
      .nullable()
      .min(SINGLE_PERMIT_MIN_RESOURCE_REQUESTS)
      .max(SINGLE_PERMIT_MAX_RESOURCE_REQUESTS)
      .required(),
    issued: YupNumber()
      .min(SINGLE_PERMIT_MIN_RESOURCE_ISSUED)
      .max(ref(typedNameV2<ForeignPermissionTransportApplicationRecordUpdateRequestExtended>('requested')))
      .required(),
    id: YupString(),
    resourceTypeId: YupString()
  });

  const formTableData = useMemo(() => {
    if (data?.content) {
      return data.content?.map(item => {
        const newItem: ForeignPermissionTransportApplicationRecordUpdateRequestExtended = {
          id: item.id || createRowId(),
          requested: item.requested,
          resourceTypeId: item.resourceType.id,
          resourceType: item.resourceType,
          issued: item.issued
        };
        return newItem;
      });
    }

    return [];
  }, [data]);

  const loadTableData = useCallback(() => {
    if (!_.isEmpty(formTableData)) {
      setValue(fieldId, formTableData);
      setTableData(formTableData);
    } else {
      setValue(fieldId, []);
      setTableData([]);
    }
  }, [formTableData, fieldId, setValue]);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  const refetchInitialTableData = useCallback(() => {
    queryCache.invalidateQueries(ApplicationQueryKeysEnum.APPLICATIONS_FOREIGN_SPO_PERMISSION_LIST);
    loadTableData();
  }, [queryCache, loadTableData]);

  useDiscardChangesCustomFunctions(fieldId, refetchInitialTableData);

  const tableProps = useSinglePermitTable(tableData, moment(applicationCreatedAt).year());

  const handleSetValues = (newTableData: ForeignPermissionTransportApplicationRecordUpdateRequestExtended[]) => {
    const formValue: ForeignPermissionTransportApplicationRecordUpdateRequestExtended[] = newTableData.map(item => ({
      id: item?.id,
      resourceTypeId: item.resourceType?.id,
      requested: item.requested,
      resourceType: item.resourceType,
      issued: item.issued
    }));
    setValue(fieldId, formValue);
    setTableData(newTableData);
    setIsDirty(true);
  };

  const handleCreate = (row: ForeignPermissionTransportApplicationRecordUpdateRequestExtended, onSuccess) => {
    const newTableData: Array<ForeignPermissionTransportApplicationRecordUpdateRequestExtended> = [
      ...tableData,
      { ...row, id: createRowId() }
    ];
    handleSetValues(newTableData);
    onSuccess();
  };
  const handleEdit = (row: ForeignPermissionTransportApplicationRecordUpdateRequestExtended, onSuccess) => {
    const newValue = [...tableData];
    newValue[newValue.findIndex(item => item.id === row.id)] = row;
    handleSetValues(newValue);
    onSuccess();
  };
  const handleDelete = (deleteRowId: string) => {
    const newValue = tableData.filter(({ id }) => id !== deleteRowId);
    handleSetValues(newValue);
  };

  return (
    <TableLayout<
      ForeignPermissionTransportApplicationRecordUpdateRequestExtended,
      ForeignPermissionTransportApplicationRecordSnapshot
    >
      {...tableProps}
      pageTitle={t('applications:singlePermits.listTitle')}
      isLoading={isLoading}
      headerActions={
        !viewMode && (
          <span>
            <TableButtonAddNewLastRow
              actionKey={DomainUIElementEnum.APPLICATION_FOREIGN_PERMISSIONS_SINGLE_ADD_NEW_BUTTON}
              title={t('action.add')}
              variant="outlined"
            />
          </span>
        )
      }
      rowActions={row =>
        !viewMode ? (
          <>
            <TableButtonDelete
              actionKey={DomainUIElementEnum.APPLICATION_FOREIGN_PERMISSIONS_SINGLE_DELETE_BUTTON}
              onClick={() => handleDelete(row.original.id)}
            />
            <TableEditRow
              actionKey={DomainUIElementEnum.APPLICATION_FOREIGN_PERMISSIONS_SINGLE_EDIT_BUTTON}
              rowId={row.original.id}
              isRenderedAsActionIcon
            />
          </>
        ) : null
      }
      onRowEditCreateConfirm={(row, { isCreated, isEdited, onSuccess }) => {
        if (isCreated) {
          handleCreate(row, onSuccess);
        } else if (isEdited) {
          handleEdit(row, onSuccess);
        }
      }}
      isRefreshEnabled={false}
      isFilterEnabled={false}
      isPaginated={false}
      editFormYupResolverSchema={validationSchema}
      isTableMutable
      isSection
    />
  );
}
export default SinglePermitTable;
