import { SelectOption } from '@libs/common/v2';

export interface SignFileForm {
  certificateType: SelectOption;
  certificate: SelectOption[];
  pin: string;
}

export enum SignFileStage {
  UPLOAD = 'UPLOAD',
  SIGN = 'SIGN',
  GET_SIZE = 'GET_SIZE',
  DOWNLOAD = 'DOWNLOAD',
  DELETE = 'DELETE',
  DONE = 'DONE'
}

export interface SigningFileData {
  certificateType?: CertificateType;
  pemHeartResponseProcessedFlag: boolean;
  contextId?: string;
  signatureMetadata?: SignatureMetadata;
  currentStage?: SigningFileStage;
  signingFileStages?: SigningFileStage[];
  forceCancelSigning?: boolean;
}

export interface SigningFileStage {
  stage?: SignFileStage;
  count?: number;
}

export interface SignatureMetadata {
  id?: string;
  pin?: string;
  certificates?: Certificates[];
  size?: string;
  data?: string;
}

export interface Certificates {
  certId: string;
  certificate: Certificate;
}

export interface Certificate {
  base64: string;
  issuerDN: string;
  sn: string;
  subjectDN: string;
  validFrom: string;
  validTo: string;
}

export enum CertificateType {
  CENCERT = 'Cencert',
  SIGILLUM = 'Sigillum',
  ASSECO = 'ASSECO'
}

export enum FilePath {
  CENCERT = 'enigmap11.dll',
  SIGILLUM = 'asepkcs.dll',
  ASSECO = 'cryptoCertum3PKCS.dll'
}

export interface FileAddress {
  from: string;
  fromHex: string;
  to: string;
  size: string;
}

export enum SignAlgorithmType {
  XAdES = 'XAdES',
  XAdESEnveloping = 'XAdESEnveloping',
  PAdES = 'PAdES',
  CAdES = 'CAdES'
}

export enum Extension {
  PDF = '.pdf',
  XML = '.xml',
  XML_XADES = '.xml.xades',
  XADES = '.xades'
}

export enum PemheartEventEnum {
  REQUEST = 'PassToPemheart',
  RESPONSE = 'PemheartResponse'
}

export enum PemheartOperation {
  CREATE_CONTEXT = 'phCreateContext',
  DELETE_CONTEXT = 'phDeleteContext',
  ADD_PKCS11_LIBRARY = 'phAddPKCS11Library',
  GET_AVAILABLE_CERTIFICATES = 'phGetAvailableCertificates',
  UPLOAD_RAM_FILE = 'phUploadRAMFile',
  SIGN_DETACHED = 'phSignDetached',
  SIGN = 'phSign',
  GET_RAM_FILE_SIZE = 'phGetRAMFileSize',
  DOWNLOAD_RAM_FILE = 'phDownloadRAMFile',
  DELETE_RAM_FILE = 'phDeleteRAMFile'
}

export interface ICustomEvent<T> {
  detail: T;
}

export interface IParsedDetails {
  error?: boolean;
  status?: string;
  results?: Record<string, any>;
  size?: string;
  data?: string;
}
