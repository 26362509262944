import { DocumentTemplateCreateRequest } from '@avispon/document-generator/dist/models';
import { AxiosRequestConfig } from 'axios';

import { useMutation, useQueryCache } from '@libs/common/v2/api';

import { API, DocumentTemplateQueryKeysEnum, endpointsConfig } from '..';

const createDocumentTemplate = async (params: DocumentTemplateCreateRequest) => {
  const { data } = await API.documentGenerator.createTemplate({ body: params }, {
    ...endpointsConfig.createTemplate
  } as AxiosRequestConfig);
  return data;
};

export default function useCreateDocumentTemplateMutation({ onSuccess }) {
  const queryCache = useQueryCache();
  return useMutation(createDocumentTemplate, {
    onSuccess: (...args) => {
      queryCache.invalidateQueries(DocumentTemplateQueryKeysEnum.DOCUMENT_TEMPLATES);
      onSuccess(...args);
    }
  });
}
