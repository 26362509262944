import { FolderSearchFilter as FolderSearchFilterClient } from '@ibtm/client-domain';
import { FolderSearchFilter, FolderSnapshot } from '@ibtm/domain';

import { CamelCasePath, useTableLayoutProps } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';
import { AdditionalFiltersModel, useFolderColumns, useFoldersListQuery } from '@libs/domain/folder';
import { useSelectPartnershipRoutingGuardContext } from '@libs/domain/routing-guards';

export function useFoldersTableProps(
  additionalFilters?: AdditionalFiltersModel | Record<string, unknown>,
  visibleColumns?: Array<CamelCasePath<FolderSnapshot>>
) {
  const { columns, mappedFilterFields, mappedSortFields } = useFolderColumns(visibleColumns);
  const { selectedPartnership } = useSelectPartnershipRoutingGuardContext();

  const paramsParsed: FolderSearchFilterClient | FolderSearchFilter = {
    statusKeyNotIn: [DomainDictionaryEntry.FOLDER_STATUS.ARCHIVED],
    ...(selectedPartnership?.folder?.id && {
      folderIdIn: [selectedPartnership?.folder?.id, selectedPartnership?.linkedFolder?.id]
    }),
    ...additionalFilters
  };
  return useTableLayoutProps({
    tableHookQuery: useFoldersListQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tableOptions: {
        initialState: {
          sortBy: [
            {
              id: 'number',
              desc: false
            }
          ]
        }
      }
    },
    tableHookQueryInitialParams: paramsParsed as Record<string, unknown>
  });
}
