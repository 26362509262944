import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

async function copyVehiclesToApplication({
  applicationId,
  vehicleIds
}: {
  applicationId: string;
  vehicleIds: string[];
}) {
  const response = await API.application.copyVehiclesToAssignedApplication(
    applicationId,
    { vehicleIds },
    {
      ...endpointsConfig.copyFolderVehiclesToAssignedApplication
    }
  );
  return response;
}

function useCopyVehiclesMutation() {
  return useMutation(copyVehiclesToApplication);
}

export default useCopyVehiclesMutation;
