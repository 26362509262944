import { QueryConfig, usePaginatedQuery } from 'react-query';
import { DefaultApiFindPullNotificationsRequest, PullNotificationSnapshot } from '@avispon/message';
import { AxiosRequestConfig } from 'axios';

import { IPaginatedModel } from '@libs/common/v2';

import { parseAdminMessagesTablePayload } from '../../utils';
import { API, endpointsConfig } from '..';

import { MessageQueryKeysEnum } from './QueryKeysEnum';

const getAdminMessages = async (_queryKey, params: DefaultApiFindPullNotificationsRequest) => {
  const queryParams = parseAdminMessagesTablePayload(params);
  const { data } = await API.message.findPullNotifications(queryParams, {
    ...endpointsConfig.findPullNotifications
  } as AxiosRequestConfig);

  return data;
};

export default function useAdminMessagesQuery(
  params: DefaultApiFindPullNotificationsRequest,
  queryConfig: QueryConfig<IPaginatedModel<PullNotificationSnapshot>> = {}
) {
  return usePaginatedQuery<IPaginatedModel<PullNotificationSnapshot>>(
    [MessageQueryKeysEnum.ADMIN_MESSAGES_LIST, params],
    getAdminMessages,
    queryConfig
  );
}
