import { usePaginatedQuery } from 'react-query';
import { DocumentSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { DocumentsSnapshotClient, parseDocumentsList } from '@libs/domain/documents';
import { endpointsConfig, ProceedingQueryKeysEnum } from '@libs/domain/proceeding';

interface GetProceedingDocumentsParams extends DocumentSearchFilter {
  proceedingId: string;
}

const getProceedingDocuments = async (_, { proceedingId, ...filters }: GetProceedingDocumentsParams) => {
  const { data } = await API.proceeding.getProceedingDocumentsPage(proceedingId, filters, {
    ...endpointsConfig.getProceeding
  });

  const response = {
    ...data,
    content: parseDocumentsList(data.content)
  };
  return response;
};

export default function useProceedingDocumentsQuery(params: DocumentSearchFilter, queryConfig = {}) {
  return usePaginatedQuery<IPaginatedModel<DocumentsSnapshotClient>>(
    [ProceedingQueryKeysEnum.PROCEEDING_DOCUMENTS, params],
    getProceedingDocuments,
    queryConfig
  );
}
