import { AdditionalFilterEnum, useTableLayoutProps } from '@libs/common/v2';

import { DomainDictionaryEntry, useDomainConfigContext } from '@libs/domain/config';
import { DisposablePermitsTableEnum, useFolderLiteQuery } from '@libs/domain/foreign-permission';

import useDisposiblePermitsColumns from '../columns/useDisposiblePermitsColumns';

function useDisposablePermitsTable() {
  const { yearFilterContextValues, setYearFilterContextValues } = useDomainConfigContext();
  const { columns, mappedFilterFields, mappedSortFields, initialParamsMapper } = useDisposiblePermitsColumns([
    'number',
    'subjectName'
  ]);

  const globalFilters = [
    {
      type: AdditionalFilterEnum.YEAR_SELECT,
      name: DisposablePermitsTableEnum.YEAR_GRATER,
      setGlobalYearSelect: setYearFilterContextValues
    }
  ];

  return useTableLayoutProps({
    tableHookQuery: useFolderLiteQuery,
    tableHookOptions: {
      columns,
      initialParamsConverter: initialParamsMapper,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    },
    globalFilters,
    tableHookQueryInitialParams: {
      [DisposablePermitsTableEnum.YEAR_GRATER]: yearFilterContextValues?.value,
      typeKey: DomainDictionaryEntry.FOREIGN_PERMIT_TYPE.SINGLE,
      yearEquals: yearFilterContextValues?.value
    }
  });
}

export default useDisposablePermitsTable;
