import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { ReportDetailsSnapshot } from '@stack/report/dist/models';

import { Button, useConfirmDialog } from '@libs/common/v2';
import { unescapeValue } from '@libs/common/v2/utils';

import { useRemoveReportMutation } from '@libs/report/api';
import { ReportUIElementEnum } from '@libs/report/config';

function ReportDeleteButton({ report, onDeleted }: { report: ReportDetailsSnapshot; onDeleted: () => void }) {
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();
  const [confirm] = useConfirmDialog();

  const { mutateAsync: deleteReport } = useRemoveReportMutation({
    onSuccess: () => {
      showSnackbar('success', t('reports:message.reportRemoveSuccess'));
    },
    onError: () => {
      showSnackbar('error', t('reports:message.reportRemoveFailure'));
    }
  });

  const handleDeleteReport = () => {
    if (report) {
      confirm({
        title: t('reports:dialog.deleteReport'),
        shortTitle: t('global:dialog.deleteConfirmTitle'),
        message: t('reports:message.reportRemoveConfirmation', unescapeValue({ name: report.sid })),
        onConfirm: (setConfirmLoading, closeDialog) => {
          setConfirmLoading(true);
          deleteReport(report.id)
            .then(() => {
              closeDialog();
              onDeleted();
            })
            .finally(() => {
              setConfirmLoading(false);
            });
        },
        confirmType: 'danger'
      });
    }
  };

  return (
    <Button
      variant="outlined"
      label={t('global:action.delete')}
      onClick={handleDeleteReport}
      actionKey={ReportUIElementEnum.REPORT_TYPE_DELETE_BUTTON}
    />
  );
}

export default ReportDeleteButton;
