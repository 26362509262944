import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Section, TableButtonView, useFormV2Context } from '@libs/common/v2';

import { DomainUIElementEnum } from '@libs/domain/config';
import { TransportManagerDetailsClient } from '@libs/domain/transport-manager';

import ReputationTable from '../../table/ReputationTable';

import ReputationQuerySearchModal from './ReputationQuerySearchModal';

interface Props {
  reputations: Array<TransportManagerDetailsClient['reputationInquires'][0]>;
  transportManagerId: string;
}

function ReputationSection({ reputations, transportManagerId }: Props) {
  const [t] = useTranslation();

  const [queryDetailsId, setQueryDetailsId] = useState<string>(null);

  const { loading } = useFormV2Context();
  const rowActions = useCallback(
    ({ original }) => {
      return (
        <span>
          <TableButtonView
            tooltipTitle={t('action.openDetails')}
            onClick={() => setQueryDetailsId((original as unknown as TransportManagerDetailsClient).id)}
            actionKey={DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_SHOW_REPUTATION_BUTTON}
          />
        </span>
      );
    },
    [setQueryDetailsId, t]
  );

  return (
    <Section title={t('transportManager:details.section.reputation')} isCollapsable isModalSection>
      <ReputationTable loading={loading} reputations={reputations} rowActions={rowActions} isTitle={false} />
      {!!queryDetailsId && (
        <ReputationQuerySearchModal
          transportManagerId={transportManagerId}
          reputationRequestId={queryDetailsId}
          open={!!queryDetailsId}
          setQueryDetailsId={setQueryDetailsId}
        />
      )}
    </Section>
  );
}

export default ReputationSection;
