import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog as MuiDialog, DialogActions, DialogContentText, DialogTitle, Grid } from '@mui/material';
import { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import {
  Button,
  IconButton,
  IconType,
  LoaderCircular,
  Scrollbar,
  SwitchInput,
  useFormV2Context,
  useIsSmallScreen,
  Value
} from '@libs/common/v2';
import { Theme, useTheme } from '@libs/common/v2/theme';
import { calc } from '@libs/common/v2/utils';

interface IProps {
  onCancel?: () => void;
  onClose?: () => void;
  onConfirm?: (setConfirmLoading) => void;
  onSwitchChange?: () => void;
  icon?: IconType;
  dialogSize?: 'small' | 'medium' | 'large';
  cancelText?: string;
  confirmText?: string;
  title?: string;
  shortTitle?: string;
  titleSupportingText?: string;
  contentText?: string;
  customActions?: React.ReactNode;
  customActionsClassName?: string;
  confirmButtonType?: 'primary' | 'danger';
  confirmButtonVariant?: 'text' | 'outlined' | 'contained';
  cancelButtonType?: 'primary' | 'danger';
  cancelButtonVariant?: 'text' | 'outlined' | 'contained';
  confirmButtonTooltipText?: {
    disabled?: string;
    enabled?: string;
  };
  switchLabel?: string;
  actionsLeftText?: string;
  isOpen?: boolean;
  isConfirmLoading?: boolean;
  isConfirmButtonDisabled?: boolean;
  isSwitchDefaultChecked?: boolean;
  isSwitchDisabled?: boolean;
  isSwitchRequired?: boolean;
  isYOverflow?: boolean;
  isScrollbarEnabled?: boolean;
  isCloseIconEnabled?: boolean;
  isConfirmButtonVisible?: boolean;
  isBackdropClickEnabled?: boolean;
  isDialogActionsSectionVisibled?: boolean;
  isCancelButtonVisible?: boolean;
  scrollbarRontainerRef?: (container: HTMLElement) => void;
  isLoading?: boolean;
  requiredFields?: Array<string>;
  isFullScreen?: boolean;
  isContentPadding?: boolean;
  isContentFullHeight?: boolean;
  hasIconButtonsOnSmallScreen?: boolean;
  confirmIcon?: IconType;
  cancelIcon?: IconType;
}

function Dialog({
  onCancel,
  onClose,
  onConfirm,
  onSwitchChange,
  title,
  shortTitle,
  titleSupportingText,
  icon = 'ExitIcon',
  dialogSize = 'medium',
  cancelText,
  confirmText,
  contentText,
  customActions,
  customActionsClassName,
  children,
  confirmButtonType = 'primary',
  confirmButtonVariant,
  cancelButtonType,
  cancelButtonVariant = 'outlined',
  confirmButtonTooltipText,
  switchLabel,
  actionsLeftText,
  scrollbarRontainerRef,
  isOpen,
  isConfirmLoading,
  isConfirmButtonDisabled,
  isSwitchDefaultChecked,
  isSwitchDisabled,
  isSwitchRequired,
  isYOverflow = true,
  isScrollbarEnabled = true,
  isCloseIconEnabled = true,
  isBackdropClickEnabled = false,
  isConfirmButtonVisible = true,
  isCancelButtonVisible = true,
  isLoading,
  requiredFields,
  isDialogActionsSectionVisibled = true,
  isFullScreen = false,
  isContentPadding = true,
  isContentFullHeight,
  hasIconButtonsOnSmallScreen,
  confirmIcon = 'CheckIcon',
  cancelIcon = 'CrossIcon',
  ...forwardProps
}: IProps & Partial<MuiDialogProps>) {
  const [t] = useTranslation();
  const { getValues } = useFormV2Context();
  const { isSmallScreen } = useIsSmallScreen();

  const isConfirmButtonDisabledByRequiredFields = requiredFields ? getValues(requiredFields).includes(null) : false;
  const { disabled, enabled } = confirmButtonTooltipText ?? {
    disabled: isConfirmButtonDisabledByRequiredFields ? t('message.completeRequiredFields') : '',
    enabled: ''
  };

  const [isContentTopShadow, setIsContentTopShadow] = useState(false);
  const [isContentBottomShadow, setIsContentBottomShadow] = useState(false);
  const [isConfirmLoadingCustom, setConfirmLoadingCustom] = useState<boolean>(isConfirmLoading ?? false);

  useEffect(() => {
    setConfirmLoadingCustom(isConfirmLoading);
  }, [isConfirmLoading]);

  const currentTitle = useMemo(
    () => (shortTitle && isSmallScreen ? shortTitle : title),
    [isSmallScreen, shortTitle, title]
  );

  const { contrast } = useTheme();
  const classes = useStyles({
    dialogSize,
    titleSupportingText,
    isYOverflow,
    isContentTopShadow,
    isContentBottomShadow,
    contrast,
    isLoading,
    isContentPadding,
    isContentFullHeight
  });

  const dialogRef = useRef();

  const setCustomLoadingHandler = (isLoading: boolean) => {
    if (dialogRef.current) {
      setConfirmLoadingCustom(isLoading);
    }
  };
  return (
    <MuiDialog
      ref={dialogRef}
      classes={{
        paper: classes.dialogPaper
      }}
      open={isOpen}
      onClose={isBackdropClickEnabled ? onCancel : null}
      maxWidth="lg"
      fullScreen={isFullScreen}
      {...forwardProps}
    >
      {!isLoading && (
        <DialogTitle className={clsx(classes.dialogTitle)}>
          <Grid container>
            <Grid item xs>
              {currentTitle && <div className={clsx(classes.dialogTitleHeader)}>{currentTitle}</div>}
            </Grid>
            {isCloseIconEnabled && (
              <Grid item className={clsx(classes.dialogTitleButton)}>
                <IconButton
                  icon={icon}
                  width={24}
                  height={24}
                  onClick={onClose || onCancel}
                  iconColor="grey"
                  iconColorWeight={500}
                  isDisabled={isConfirmLoadingCustom}
                />
              </Grid>
            )}
          </Grid>
          {titleSupportingText && <div className={clsx(classes.dialogTitleSupporting)}>{titleSupportingText}</div>}
        </DialogTitle>
      )}
      <Scrollbar
        onScrollY={() => {
          if (!isContentTopShadow) setIsContentTopShadow(true);
          if (!isContentBottomShadow) setIsContentBottomShadow(true);
        }}
        onYReachEnd={() => setIsContentBottomShadow(false)}
        onYReachStart={() => setIsContentTopShadow(false)}
        isScrollbarEnabled={isScrollbarEnabled}
        className={classes.scrollbarContainer}
        containerRef={scrollbarRontainerRef}
        isMobile={isSmallScreen}
      >
        <div className={classes.dialogContainer}>
          <div className={clsx(classes.dialogContent)}>
            <LoaderCircular isLoading={isLoading} isAbsolute>
              {contentText ? (
                <DialogContentText className={classes.contentText}>{contentText}</DialogContentText>
              ) : (
                children
              )}
            </LoaderCircular>
          </div>
        </div>
      </Scrollbar>
      {!isLoading && isDialogActionsSectionVisibled ? (
        <DialogActions className={clsx(classes.dialogActions)}>
          {switchLabel && (
            <div className={clsx(classes.dialogActionsSwitch)}>
              <SwitchInput
                onChange={onSwitchChange}
                inlineLabel={switchLabel}
                name={switchLabel}
                isDefaultChecked={isSwitchDefaultChecked}
                isDisabled={isSwitchDisabled}
                isRequired={isSwitchRequired}
              />
            </div>
          )}
          {actionsLeftText && (
            <div className={clsx(classes.dialogActionsLeftText)}>
              <Value>{actionsLeftText}</Value>
            </div>
          )}
          <div className={clsx(classes.dialogButtonsContainer, customActionsClassName)}>
            {customActions || (
              <>
                {isCancelButtonVisible && (
                  <Button
                    {...(hasIconButtonsOnSmallScreen && isSmallScreen
                      ? {
                          icon: cancelIcon,
                          isError: true,
                          tooltipTitle: cancelText || (onConfirm ? t('action.cancel') : t('action.close')),
                          variant: 'contained',
                          className: clsx(classes.iconButton)
                        }
                      : {
                          tooltipTitle:
                            isConfirmButtonDisabled || isConfirmButtonDisabledByRequiredFields ? disabled : enabled,
                          label: cancelText || (onConfirm ? t('action.cancel') : t('action.close')),
                          isPrimary: cancelButtonType === 'primary',
                          isError: cancelButtonType === 'danger',
                          variant: cancelButtonVariant || 'outlined'
                        })}
                    isNoMargin
                    size="large"
                    onClick={onCancel}
                    disabled={isConfirmLoadingCustom}
                  />
                )}
                {isConfirmButtonVisible && onConfirm && (
                  <Button
                    {...(hasIconButtonsOnSmallScreen && isSmallScreen
                      ? {
                          icon: confirmIcon,
                          isSuccess: true,
                          tooltipTitle: confirmText,
                          variant: 'contained',
                          className: clsx(classes.iconButton)
                        }
                      : {
                          tooltipTitle:
                            isConfirmButtonDisabled || isConfirmButtonDisabledByRequiredFields ? disabled : enabled,
                          label: confirmText,
                          isPrimary: confirmButtonType === 'primary',
                          isError: confirmButtonType === 'danger',
                          variant: confirmButtonVariant || 'contained'
                        })}
                    size="large"
                    disabled={isConfirmButtonDisabled || isConfirmButtonDisabledByRequiredFields}
                    isNoMargin
                    isLoading={isConfirmLoadingCustom}
                    onClick={() => {
                      onConfirm(setCustomLoadingHandler);
                    }}
                  />
                )}
              </>
            )}
          </div>
        </DialogActions>
      ) : null}
    </MuiDialog>
  );
}

const useStyles = makeStyles<
  Theme,
  Pick<IProps, 'dialogSize' | 'titleSupportingText' | 'isYOverflow' | 'isLoading'> & {
    isContentTopShadow: boolean;
    isContentBottomShadow: boolean;
    contrast?: boolean;
    isContentPadding?: boolean;
    isContentFullHeight?: boolean;
  }
>(theme => ({
  dialogPaper: ({ isYOverflow, dialogSize }) => {
    let dialogWidth = '500px';

    if (dialogSize === 'large') {
      dialogWidth = '1000px';
    } else if (dialogSize === 'medium') {
      dialogWidth = '700px';
    }
    return {
      ...{ width: dialogWidth },
      overflowY: isYOverflow ? 'auto' : 'unset'
    };
  },
  dialogContainer: ({ isContentFullHeight }) => ({
    background: theme.palette.white,
    position: 'relative',
    paddingBottom: '10px',
    ...(isContentFullHeight && { height: '100%' })
  }),
  [theme.breakpoints.down('md')]: {
    dialogContainer: {
      width: '100% !important'
    }
  },
  dialogTitle: ({ isContentTopShadow }) => ({
    boxSizing: 'border-box',
    padding: '12px 12px 0px 24px',
    width: '100%',
    flexDirection: 'column',
    position: 'sticky',
    top: 0,
    left: 0,
    background: theme.palette.white,
    zIndex: 100,
    boxShadow: isContentTopShadow ? 'rgb(16 24 40 / 10%) 0 0 4px 4px' : 'none',
    color: theme.palette.grey[700]
  }),
  dialogTitleHeader: ({ titleSupportingText }) => ({
    padding: '12px 0',
    paddingBottom: titleSupportingText ? '8px' : '12px',
    ...theme.typography.textXl?.medium
  }),
  dialogTitleButton: {
    margin: 'auto'
  },
  dialogTitleSupporting: {
    padding: '0 12px 12px 0',
    width: calc('100% - 44px'),
    alignSelf: 'flex-start',
    color: theme.palette.grey[600],
    ...theme.typography.textSm?.normal
  },
  dialogContent: {
    color: ({ contrast }) => contrast && theme.palette.grey[900],
    boxSizing: 'border-box',
    padding: ({ isContentPadding }) => (isContentPadding ? '12px 24px' : 0),
    width: '100%',
    wordBreak: 'break-word',
    height: ({ isLoading, isContentFullHeight }) => {
      if (isLoading) {
        return '100px';
      }
      if (isContentFullHeight) {
        return '100%';
      }
      return 'unset';
    }
  },
  dialogActions: ({ isContentBottomShadow }) => ({
    boxSizing: 'border-box',
    padding: '20px 24px 24px 24px',
    width: '100%',
    position: 'sticky',
    bottom: 0,
    zIndex: 100,
    background: theme.palette.white,
    boxShadow: isContentBottomShadow ? 'rgb(16 24 40 / 10%) 0 0 4px 4px' : 'none'
  }),
  dialogActionsSwitch: {
    flexGrow: 1
  },
  dialogActionsLeftText: {
    flexGrow: 1,
    paddingBottom: '8px'
  },
  dialogButtonsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px'
  },
  scrollbarContainer: {
    backgroundColor: theme.palette.white
  },
  iconButton: {
    minWidth: '90px',

    '& svg': {
      margin: 0
    }
  },
  contentText: {
    color: ({ contrast }) => contrast && theme.palette.grey[900]
  }
}));

export default Dialog;
