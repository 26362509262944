import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { TableState } from 'react-table';
import { get } from 'lodash';

import { ITableAdapter, useTableAdapter, useTableContext } from '@libs/common/v2';

interface IProps {
  /*
   * Opcjonalnie przekazujemy funkcję do ustawienia state komponentu komponentu rodzica, aby wywołać jego render, gdy zmieni się state tabeli
   */
  setState?: (value) => void;
  /*
   * Opcjonalna ścieżka dostępu do wartości które chcemy przekazać, domyślnie komponent przekazuje "selectedRowIds"
   */
  tableStatePath?: keyof ITableAdapter<any> | `state.${keyof TableState<Record<string, any>>}` | '';
}

/**
 * Komponent służy do przekazania state tabeli z contextu do komponentu komponentu rodzica
 * Możemy użyć propsa "ref", jeżeli potrzebujemy tylko pobrać wartość ze state, lub przekazać funkcję ustawienia state komponentu rodzica
 * jeżeli chcemy wywołać re-render komponentu rodzica.
 * @useSelectedRowsActionAvailable - hook do użycia wraz z tym komponentem w oknie modalnym (zwraca funkcję do pobierania listy Id
 * zaznaczonych wierszy oraz obsługuję zmiane stanu przycisku disabled/enabled
 * */
function ForwardTableState({ setState, tableStatePath = 'state.selectedRowIds' }: IProps, ref) {
  const data = useTableAdapter();
  const { data: tableContextData } = useTableContext();
  const stateValue = get(data, tableStatePath);
  const selectedRows = data.selectedFlatRows;

  useImperativeHandle(
    ref,
    () => ({
      getStateValue: () => stateValue,
      getSelectedRows: () => selectedRows,
      cancelEditRow: () => data?.cancelEditCreateRow()
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stateValue, tableContextData]
  );

  useEffect(() => {
    setState?.(stateValue);
  }, [stateValue, setState]);

  return null;
}
export default forwardRef<{ getStateValue: () => unknown }, IProps>(ForwardTableState);
