import { DriverCertificateSearchFilter } from '@ibtm/domain';

import { CamelCasePath, SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO, getCalendarDate } from '@libs/common/v2/utils';

import { UserFilterV2 } from '@libs/user';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { ProceedingCell } from '@libs/domain/drivers/components/table/DriversTable/ProceedingCell';

import {
  DriverCertificateDictionaryNameEnum,
  DriverCertificatesColumnsEnum,
  DriverCertificateSnapshotClient
} from '../../model';

const useDriverCertificatesColumns = (visibleColumns: Array<CamelCasePath<DriverCertificateSnapshotClient>>) => {
  const { createColumns } = useCreateColumns<DriverCertificateSnapshotClient>({
    pathToTranslate: 'driverCertificate:fields'
  });

  const mappedFilterFields: TableFilterMapper<DriverCertificateSnapshotClient, DriverCertificateSearchFilter> = {
    applicationNumber: (applicationNumber: string) => ({ applicationNumberContains: applicationNumber }),
    driverCertificateNumber: (driverCertificateNumber: string) => ({ numberContains: driverCertificateNumber }),
    formNumber: (formNumber: string) => ({ formNumberContains: formNumber }),
    driverName: (driverName: string) => ({ driverNameContains: driverName }),
    driverSurname: (driverSurname: string) => ({ driverSurnameContains: driverSurname }),
    driverID: (drivers: SelectOption<string>[]) => ({ driverIdIn: drivers.map(({ value }) => value) }),
    printDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      printDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      printDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    validFrom: ({ dateFrom, dateTo }: DateRangeISO) => ({
      validFromGreaterThanOrEqual: getCalendarDate(dateFrom),
      validFromLessThanOrEqual: getCalendarDate(dateTo)
    }),
    validTo: ({ dateFrom, dateTo }: DateRangeISO) => ({
      validToGreaterThanOrEqual: getCalendarDate(dateFrom),
      validToLessThanOrEqual: getCalendarDate(dateTo)
    }),
    dateOfIssueExternal: ({ dateFrom, dateTo }: DateRangeISO) => ({
      dateOfIssueExternalGreaterThanOrEqual: getCalendarDate(dateFrom),
      dateOfIssueExternalLessThanOrEqual: getCalendarDate(dateTo)
    }),
    signedBy: (signers: SelectOption<string>[]) => ({ signerIdIn: signers.map(signer => signer.value) }),
    status: (statuses: SelectOption<string>[]) => ({ statusKeyIn: statuses.map(status => status.value) }),
    cancellationDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      cancellationDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      cancellationDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    cancelledBy: (cancelledBy: SelectOption<string>[]) => ({
      cancelledByIdIn: cancelledBy.map(({ value }) => value)
    }),
    subjectName: (subjectName: string) => ({
      subjectNameContains: subjectName
    }),
    cancellationReason: (cancellationReason: SelectOption<string>[]) => ({
      cancellationReasonKeyIn: cancellationReason.map(({ value }) => value)
    }),
    subjectAddress: (subjectAddress: string) => ({ addressContains: subjectAddress })
  };

  const mappedSortFields: TableSortMapper<DriverCertificateSnapshotClient> = {
    status: 'statusKey',
    driverCertificateNumber: 'number',
    formNumber: 'form.fullNumber',
    subjectName: 'driver.application.subject.name',
    signedBy: 'signer.name',
    driverName: 'driver.personalData.name',
    driverSurname: 'driver.personalData.surname',
    driverBirthDate: 'driver.personalData.birthDate',
    driverBirthPlace: 'driver.personalData.birthPlace',
    driverCitizenship: 'driver.personalData.citizenship',
    driverIdentityCardSeriesNumber: 'driver.identityCard.seriesNumber',
    subjectAddress: 'application.subject.mainAddress.shortAddress',
    cancellationReason: 'cancellationReasonKey'
  };

  const columns = createColumns(
    [
      {
        type: 'TEXT',
        accessor: 'applicationNumber'
      },
      {
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.PERMISSION_CANCELLATION_REASON,
        accessor: 'cancellationReason'
      },
      {
        type: 'TEXT',
        accessor: 'cancelledBy',
        filter: UserFilterV2
      },
      {
        type: 'DATE',
        accessor: 'cancellationDate'
      },
      {
        type: 'TEXT',
        accessor: 'subjectName'
      },
      {
        type: 'TEXT',
        accessor: 'subjectAddress'
      },
      {
        type: 'TEXT',
        accessor: 'driverCertificateNumber'
      },
      {
        type: 'TEXT',
        accessor: 'formNumber',
        customAccessor: row => (
          <ProceedingCell formNumber={row?.formNumber} pendingProceedings={row?.pendingProceedings} />
        )
      },
      {
        type: 'TEXT',
        accessor: 'driverName'
      },
      {
        type: 'TEXT',
        accessor: 'driverSurname'
      },
      {
        type: 'MULTI_SELECT',
        accessor: 'driver.id'
      },
      {
        type: 'DATE',
        accessor: 'printDate'
      },
      {
        type: 'DATE',
        accessor: 'validFrom'
      },
      {
        type: 'DATE',
        accessor: 'validTo'
      },
      {
        type: 'DATE',
        accessor: 'dateOfIssueExternal'
      },
      {
        type: 'TEXT',
        accessor: 'signedBy',
        filter: UserFilterV2
      },
      {
        accessor: 'status',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DriverCertificateDictionaryNameEnum.DRIVER_CERTIFICATE_STATUS
      }
    ],
    visibleColumns
  );

  return { columns, mappedSortFields, mappedFilterFields };
};

export const DEFAULT_COLUMNS: Array<CamelCasePath<DriverCertificateSnapshotClient>> = [
  DriverCertificatesColumnsEnum.APPLICATION_NUMBER,
  DriverCertificatesColumnsEnum.DRIVER_CERTIFICATE_NUMBER,
  DriverCertificatesColumnsEnum.FORM_NUMBER,
  DriverCertificatesColumnsEnum.DRIVER_NAME,
  DriverCertificatesColumnsEnum.DRIVER_SURNAME,
  DriverCertificatesColumnsEnum.DRIVER_ID,
  DriverCertificatesColumnsEnum.PRINT_DATE,
  DriverCertificatesColumnsEnum.VALID_FROM,
  DriverCertificatesColumnsEnum.VALID_TO,
  DriverCertificatesColumnsEnum.DATE_OF_ISSUE_EXTERNAL,
  DriverCertificatesColumnsEnum.SIGNED_BY,
  DriverCertificatesColumnsEnum.STATUS,
  DriverCertificatesColumnsEnum.CANCELLATION_DATE
];

export default useDriverCertificatesColumns;
