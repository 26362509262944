import { InvoiceSnapshot } from '@ibtm/domain';

import { Chip } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

function ReleaseDocumentStatusChip({ row }: { row: InvoiceSnapshot }) {
  const { translate } = useDictionaryTranslations();
  if (row?.statusKey === DomainDictionaryEntry.INVOICE_STATUS.NEW) {
    return <Chip color="success" title={translate(DomainDictionaryEnum.INVOICE_STATUS, row?.statusKey, '-')} />;
  }
  if (row?.statusKey === DomainDictionaryEntry.INVOICE_STATUS.GENERATED) {
    return <Chip color="grey" title={translate(DomainDictionaryEnum.INVOICE_STATUS, row?.statusKey, '-')} />;
  }
  return <Chip color="error" title={translate(DomainDictionaryEnum.INVOICE_STATUS, row?.statusKey, '-')} />;
}

export default ReleaseDocumentStatusChip;
