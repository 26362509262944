export const endpointsConfig = {
  getProxiesSnapshotPage: { translationKey: 'proxy:api.getProxiesSnapshotPage' },

  getSubjectDetails: { translationKey: 'subject:api.getSubjectDetails' },
  updateSubject: { translationKey: 'subject:api.updateSubject' },
  expireSubject: { translationKey: 'subject:api.expireSubject' },

  definitions: { translationKey: 'processes:api.definitions' },
  getAddressesList: { translationKey: 'address:api.getAddressesList' },

  getApplicationCabotage: { translationKey: 'applications:api.getApplicationCabotage' },
  getApplicationDecisions: { translationKey: 'applications:api.getApplicationDecisions' },
  getApplicationDetails: { translationKey: 'applications:api.getApplicationDetails' },
  getApplicationForeignPermissionVehicles: {
    translationKey: 'applications:api.getApplicationForeignPermissionVehicles'
  },
  getApplicationNote: { translationKey: 'applications:api.getApplicationNote' },
  searchForeignSpoPermissions: { translationKey: 'applications:api.searchForeignSpoPermissions' },
  createAddress: { translationKey: 'applications:api.createAddress' },
  updateAddress: { translationKey: 'applications:api.updateAddress' },
  getApplicationsSnapshotPage: { translationKey: 'applications:api.getApplicationsSnapshotPage' },
  getSubjectDetailsByApplication: { translationKey: 'applications:api.getSubjectDetailsByApplication' },
  generateLabel: { translationKey: 'applications:api.generateLabel' },
  getForeignPermissionApplicationRecords: { translationKey: 'applications:api.getForeignPermissionApplicationRecords' },
  getPaymentsSummary: { translationKey: 'applications:api.getPaymentsSummary' },
  taskList: { translationKey: 'applications:api.taskList' },
  taskDetails: { translationKey: 'applications:api.taskDetails' },
  taskConfirm: { translationKey: 'applications:api.taskConfirm' },
  getApplicationDefinitionsSnapshotPage: { translationKey: 'applications:api.getApplicationDefinitionsSnapshotPage' },
  confirmApplicationDraft: { translationKey: 'applications:api.confirmApplicationDraft' },
  deleteDraftApplication: { translationKey: 'applications:api.deleteDraftApplication' },
  updateApplication: { translationKey: 'address:api.updateApplication' },
  updateSCertificateApplication: { translationKey: 'address:api.updateSCertificateApplication' },
  updateApplicationCabotage: { translationKey: 'applications:api.updateApplicationCabotage' },
  updateSpoRecords: { translationKey: 'applications:api.updateSpoRecords' },
  updateApplicationNote: { translationKey: 'applications:api.updateApplicationNote' },
  startApplicationDraft: { translationKey: 'applications:api.startApplicationDraft' },
  updateOrganizationUnit: { translationKey: 'address:api.updateOrganizationUnit' },
  updateAssignedTo: { translationKey: 'address:api.updateAssignedTo' },
  cancelApplication: { translationKey: 'applications:api.cancelApplication' },
  foreignAuthoritiesNotificated: { translationKey: 'applications:api.foreignAuthoritiesNotificated' },
  passApplication: { translationKey: 'applications:api.passApplication' },
  returnApplicationForCorrection: { translationKey: 'address:api.returnApplicationForCorrection' },
  startApplication: { translationKey: 'address:api.startApplication' },
  copyDataFromAnotherApplication: { translationKey: 'applications:api.copyDataFromAnotherApplication' },
  createApplicationNote: { translationKey: 'applications:api.createApplicationNote' },
  createForeignPermission: { translationKey: 'applications:api.createForeignPermission' },
  createOrUpdateApplicationCabotage: { translationKey: 'address:api.createOrUpdateApplicationCabotage' },
  createOrUpdateApplicationDecisions: { translationKey: 'address:api.updateSCertificateApplication' },
  updateApplicationForeignPermissionVehicles: {
    translationKey: 'address:api.updateApplicationForeignPermissionVehicles'
  },
  updateApplicationPaymentData: {
    translationKey: 'applications:api.updateApplicationPaymentData'
  },
  createApplicationDocument: { translationKey: 'applications:api.createApplicationDocument' },
  updateForeignPermission: { translationKey: 'applications:api.updateForeignPermission' },
  addForeignPermission: { translationKey: 'applications:api.addForeignPermission' },
  removeForeignPermission: { translationKey: 'applications:api.removeForeignPermission' },
  verify: { translationKey: 'applications:api.verify' },
  spoCompleted: { translationKey: 'applications:api.spoCompleted' },
  updateFinancialSecuritySummary: { translationKey: 'applications:api.updateFinancialSecuritySummary' },
  updateApplicationPermissionsReprintFlag: {
    translationKey: 'applications:api.updateApplicationPermissionsReprintFlag'
  },
  getApplicationCategories: { translationKey: 'applications:api.getApplicationCategories' },
  generateApplicationPrintouts: { translationKey: 'applications:api.generateApplicationPrintouts' }
} satisfies { [key: string]: { translationKey: string } };
