import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TravelFormDetails } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import {
  GridLayout,
  InputMode,
  NumberInputField,
  Section,
  typedNameV2,
  useFormV2Context,
  Value
} from '@libs/common/v2';
import { getValue } from '@libs/common/v2/utils';

import { useDomainConfigContext } from '@libs/domain/config';
import { FolderDetailsParams, useTravelFormsQuery } from '@libs/domain/folder';

const translate = partialTranslate('folder:details.tab.orders');

function OrdersTab() {
  const { folderId } = useParams<FolderDetailsParams>();
  const { isOperatorPortal } = useDomainConfigContext();
  const { reset, getValues } = useFormV2Context();

  const { data, isLoading } = useTravelFormsQuery({ folderId }, { enabled: isOperatorPortal });

  const interbusFormNumbers = data?.travelForms
    ?.filter(item => item?.interbusForm)
    ?.map(item => item?.interbusForm.fullNumber)
    ?.join(', ');

  const travelFormNumbers = data?.travelForms
    ?.filter(item => item?.travelFormsBookForm)
    ?.map(item => item?.travelFormsBookForm.fullNumber)
    ?.join(', ');

  useEffect(() => {
    if (data) {
      const { travelForms } = data;
      const interbusFormNumber = travelForms?.filter(item => item?.interbus)?.length;
      const travelFormNumber = travelForms?.filter(item => item?.travelFormsBook)?.length;

      const formValues = getValues();
      reset({
        ...formValues,
        travelFormsBook: travelFormNumber,
        interbus: interbusFormNumber
      });
    }
  }, [data, getValues, reset]);

  return (
    <>
      <Section title={translate('interbus' as never)} isCollapsable isLoading={isLoading}>
        <GridLayout itemProps={{ xs: 12 }}>
          <NumberInputField
            label={translate('field.number' as never)}
            name={typedNameV2<TravelFormDetails>('interbus')}
            key="interbus"
            inputMode={InputMode.VIEW}
          />
          <Value
            label={translate('field.formNumbers' as never)}
            key="formNumbers"
            value={getValue(interbusFormNumbers)}
          />
        </GridLayout>
      </Section>
      <Section title={translate('travelForm' as never)} isCollapsable isLoading={isLoading}>
        <GridLayout itemProps={{ xs: 12 }}>
          <NumberInputField
            name={typedNameV2<TravelFormDetails>('travelFormsBook')}
            label={translate('field.number' as never)}
            key="travelFormsBook"
            inputMode={InputMode.VIEW}
          />
          <Value
            label={translate('field.formNumbers' as never)}
            key="formNumbers"
            value={getValue(travelFormNumbers)}
          />
        </GridLayout>
      </Section>
    </>
  );
}

export default OrdersTab;
