import { InputMode } from '@libs/common/v2';

import {
  AdditionalSectionLicenseOrPermissionType,
  ApplicationMetaFormDefinition
} from '@libs/domain/application/model';

const definition: ApplicationMetaFormDefinition = {
  fields: [
    {
      typeKey: 'DICTIONARY',
      fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionKeys',
      properties: {
        label: 'Wtórnik',
        dictionaryName: 'LICENSE_APPLICATION_ISSUED_PERMISSIONS',
        multiple: true,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.duplicateLicenseApplicationDataUpdateRequest.permissionKeys'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.duplicateLicenseApplicationDataDetails.permissionKeys'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionKeys-op',
      properties: {
        label: 'Wtórnik',
        dictionaryName: 'LICENSE_APPLICATION_ISSUED_PERMISSIONS_DUPLICATE_OP',
        multiple: true,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.duplicateLicenseApplicationDataUpdateRequest.permissionKeys'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.duplicateLicenseApplicationDataDetails.permissionKeys'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionKeys-tp',
      properties: {
        label: 'Wtórnik',
        dictionaryName: 'LICENSE_APPLICATION_ISSUED_PERMISSIONS_DUPLICATE_TP',
        multiple: true,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.duplicateLicenseApplicationDataUpdateRequest.permissionKeys'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.duplicateLicenseApplicationDataDetails.permissionKeys'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-causeDependentOnSubjectKey',
      properties: {
        label: 'Przyczyna wydania wtórnika',
        dictionaryName: 'DUPLICATE_CAUSE_DEPENDENT_ON_SUBJECT',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.duplicateLicenseApplicationDataUpdateRequest.causeDependentOnSubjectKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.duplicateLicenseApplicationDataDetails.causeDependentOnSubjectKey'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-lossCauseKey',
      properties: {
        label: 'Uprawnienie zniszczone/ utracone',
        dictionaryName: 'LOSS_CAUSE',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.duplicateLicenseApplicationDataUpdateRequest.lossCauseKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.duplicateLicenseApplicationDataDetails.lossCauseKey'
          }
        }
      }
    },
    {
      typeKey: 'DATETIME',
      fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-lossDate',
      properties: {
        label: 'Data utraty',
        yupValidation: {
          required: {},
          max: {
            date: 'TODAY',
            message: 'Data nie może być późniejsza niż dzisiejszy dzień'
          }
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.duplicateLicenseApplicationDataUpdateRequest.lossDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.duplicateLicenseApplicationDataDetails.lossDate'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-lossLocation',
      properties: {
        label: 'Miejsce utraty',
        maxLength: 100,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.duplicateLicenseApplicationDataUpdateRequest.lossLocation'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.duplicateLicenseApplicationDataDetails.lossLocation'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-lossLocation-required',
      properties: {
        label: 'Miejsce utraty',
        yupValidation: {
          required: true
        },
        maxLength: 100,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.duplicateLicenseApplicationDataUpdateRequest.lossLocation'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.duplicateLicenseApplicationDataDetails.lossLocation'
          }
        }
      }
    },
    {
      typeKey: 'AUTOCOMPLETE_LAZY_FETCH',
      fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionDuplicates',
      properties: {
        label: 'Zezwolenie na wykonywanie zawodu przewoźnika drogowego',
        validationMultipleSelect: { required: [] },
        multiple: true,
        required: true,
        api: {
          fetch: {
            requestKey: 'GET_PERMISSIONS_TO_DUPLICATE_PB5',
            optionTextMatch: '[form.fullNumber]',
            backendFilterParamName: 'numberContains'
          },
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.duplicateLicenseApplicationDataUpdateRequest.permissionDuplicates',
            formAccessor: 'id'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.duplicateLicenseApplicationDataDetails.permissionDuplicates'
          }
        }
      }
    },
    {
      typeKey: 'AUTOCOMPLETE_LAZY_FETCH',
      fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-licenseDuplicates',
      properties: {
        label: 'Licencja wspólnotowa',
        validationMultipleSelect: { required: [] },
        multiple: true,
        required: true,
        api: {
          fetch: {
            requestKey: 'GET_LICENSES_TO_DUPLICATE_PB5',
            optionTextMatch: '[form.fullNumber]',
            backendFilterParamName: 'licenseNumberContains'
          },
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.duplicateLicenseApplicationDataUpdateRequest.licenseDuplicates',
            formAccessor: 'id'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.duplicateLicenseApplicationDataDetails.licenseDuplicates'
          }
        }
      }
    },
    {
      typeKey: 'AUTOCOMPLETE_LAZY_FETCH',
      fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-licenseCopyDuplicates',
      properties: {
        label: 'Wypis z licencji wspólnotowej',
        multiple: true,
        required: true,
        validationMultipleSelect: { required: [] },
        api: {
          fetch: {
            requestKey: 'GET_LICENSES_TO_DUPLICATE_COPY_PB5',
            optionTextMatch: '[form.fullNumber]',
            backendFilterParamName: 'licenseNumberOrFormNumberContains'
          },
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.duplicateLicenseApplicationDataUpdateRequest.licenseCopyDuplicates',
            formAccessor: 'id'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.duplicateLicenseApplicationDataDetails.licenseCopyDuplicates'
          }
        }
      }
    },
    {
      typeKey: 'AUTOCOMPLETE_LAZY_FETCH',
      fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionCopyDuplicates',
      properties: {
        label: 'Wypis z zezwolenia na wykonywanie zawodu przewoźnika drogowego',
        multiple: true,
        required: true,
        validationMultipleSelect: { required: [] },
        api: {
          fetch: {
            requestKey: 'GET_PERMISSIONS_TO_DUPLICATE_COPY_PB5',
            optionTextMatch: '[form.fullNumber]',
            backendFilterParamName: 'permissionNumberOrCopyNumberContains'
          },
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.duplicateLicenseApplicationDataUpdateRequest.permissionCopyDuplicates',
            formAccessor: 'id'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.duplicateLicenseApplicationDataDetails.permissionCopyDuplicates'
          }
        }
      }
    }
  ],
  layout: [
    {
      typeKey: 'TAB_NAVIGATION',
      properties: {
        visibility: {
          elementVisibilityKeys: ['TAB_VISIBILITY']
        },
        defaultTabId: 'GENERAL',
        title: 'Sekcje',
        tabs: [
          {
            id: 'GENERAL',
            title: 'Dane podstawowe',
            icon: 'InfoIcon'
          },
          {
            id: 'SUBJECT',
            title: 'Podmiot',
            icon: 'FacadeIcon'
          },
          {
            id: 'ADDRESSES',
            title: 'Adresy',
            icon: 'MailboxIcon'
          },
          {
            id: 'PERMISSIONS',
            title: 'Uprawnienia',
            icon: 'KeyIcon'
          },
          {
            id: 'PROXIES',
            title: 'Pełnomocnicy',
            icon: 'GroupOfPeopleIcon'
          },
          {
            id: 'ATTACHMENTS',
            title: 'Załączniki',
            icon: 'PaperclipIcon'
          },
          {
            id: 'QUOTATION',
            title: 'Wycena',
            icon: 'CoinsIcon'
          },
          {
            id: 'OUTGOING_DOCUMENTS',
            title: 'Dokumenty wychodzące',
            icon: 'DocumentsIssuedIcon'
          },
          {
            id: 'NOTE',
            title: 'Notatka',
            icon: 'NoteIcon'
          },
          {
            id: 'FILES',
            title: 'Pliki',
            icon: 'FileEmptyIcon'
          },
          {
            id: 'HISTORY',
            title: 'Historia',
            icon: 'HistoryIcon'
          }
        ]
      },
      nodes: [
        {
          typeKey: 'TABS_CONTENT',
          nodes: [
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-general-tab',
                additionalNodes: {
                  'additional-section': [
                    {
                      typeKey: 'SECTION',
                      properties: {
                        title: 'Wniosek / Terminy'
                      },
                      nodes: [
                        {
                          typeKey: 'GRID',
                          properties: {
                            gridItemProps: {
                              xs: 12,
                              sm: 6
                            }
                          },
                          nodes: [
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'application-folder-typeKey'
                              }
                            },
                            {
                              typeKey: 'CONDITIONALLY_VISIBLE',
                              properties: {
                                gridItemProps: {
                                  xs: 12
                                },
                                dependendFieldId: 'application-folder-typeKey',
                                value: {
                                  includes: ['ibtm-domain.folder.type.tp']
                                }
                              },
                              nodes: [
                                {
                                  typeKey: 'FIELD',
                                  properties: {
                                    fieldId:
                                      'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionKeys-tp'
                                  }
                                }
                              ]
                            },
                            {
                              typeKey: 'CONDITIONALLY_VISIBLE',
                              properties: {
                                gridItemProps: {
                                  xs: 12
                                },
                                dependendFieldId: 'application-folder-typeKey',
                                value: {
                                  includes: ['ibtm-domain.folder.type.op']
                                }
                              },
                              nodes: [
                                {
                                  typeKey: 'FIELD',
                                  properties: {
                                    fieldId:
                                      'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionKeys-op'
                                  }
                                }
                              ]
                            },
                            {
                              typeKey: 'CONDITIONALLY_VISIBLE',
                              properties: {
                                gridItemProps: {
                                  xs: 12
                                },
                                dependendFieldId: 'application-folder-typeKey',
                                value: {
                                  notIncludes: ['ibtm-domain.folder.type.tp', 'ibtm-domain.folder.type.op']
                                }
                              },
                              nodes: [
                                {
                                  typeKey: 'FIELD',
                                  properties: {
                                    fieldId:
                                      'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionKeys'
                                  }
                                }
                              ]
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId:
                                  'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-causeDependentOnSubjectKey'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-lossCauseKey'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-lossDate'
                              }
                            },
                            {
                              typeKey: 'CONDITIONALLY_VISIBLE',
                              properties: {
                                dependendFieldId:
                                  'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-lossCauseKey',
                                value: {
                                  includes: ['ibtm-domain.application.license.loss-cause.destroyed']
                                }
                              },
                              nodes: [
                                {
                                  typeKey: 'FIELD',
                                  properties: {
                                    fieldId:
                                      'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-lossLocation'
                                  }
                                }
                              ]
                            },
                            {
                              typeKey: 'CONDITIONALLY_VISIBLE',
                              properties: {
                                dependendFieldId:
                                  'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-lossCauseKey',
                                value: {
                                  includes: ['ibtm-domain.application.license.loss-cause.lost']
                                }
                              },
                              nodes: [
                                {
                                  typeKey: 'FIELD',
                                  properties: {
                                    fieldId:
                                      'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-lossLocation-required'
                                  }
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    {
                      typeKey: 'CONDITIONALLY_VISIBLE',
                      properties: {
                        dependendFieldId:
                          'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionKeys-tp',
                        value: {
                          includes: [
                            'ibtm-domain.application.license.issued-permission-duplicate.goods-carriage-permission',
                            'ibtm-domain.application.license.issued-permission-duplicate.goods-carriage-permission-abridgement',
                            'ibtm-domain.application.license.issued-permission-duplicate.goods-carriage-transport-license',
                            'ibtm-domain.application.license.issued-permission-duplicate.goods-carriage-transport-license-abridgement'
                          ]
                        }
                      },
                      nodes: [
                        {
                          typeKey: 'SECTION',
                          properties: {
                            title: 'Wtórniki uprawnień'
                          },
                          nodes: [
                            {
                              typeKey: 'CONDITIONALLY_VISIBLE',
                              properties: {
                                dependendFieldId: 'application-folder-typeKey',
                                value: {
                                  includes: ['ibtm-domain.folder.type.tp']
                                }
                              },
                              nodes: [
                                {
                                  typeKey: 'GRID',
                                  properties: {
                                    gridItemProps: {
                                      xs: 12,
                                      sm: 6
                                    }
                                  },
                                  nodes: [
                                    {
                                      typeKey: 'CONDITIONALLY_VISIBLE',
                                      properties: {
                                        dependendFieldId:
                                          'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionKeys-tp',
                                        value: {
                                          includes: [
                                            'ibtm-domain.application.license.issued-permission-duplicate.goods-carriage-permission'
                                          ]
                                        }
                                      },
                                      nodes: [
                                        {
                                          typeKey: 'FIELD',
                                          properties: {
                                            fieldId:
                                              'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionDuplicates',
                                            yupValidation: {
                                              required: true
                                            }
                                          }
                                        }
                                      ]
                                    },
                                    {
                                      typeKey: 'CONDITIONALLY_VISIBLE',
                                      properties: {
                                        dependendFieldId:
                                          'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionKeys-tp',
                                        value: {
                                          includes: [
                                            'ibtm-domain.application.license.issued-permission-duplicate.goods-carriage-permission-abridgement'
                                          ]
                                        }
                                      },
                                      nodes: [
                                        {
                                          typeKey: 'FIELD',
                                          properties: {
                                            fieldId:
                                              'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionCopyDuplicates',
                                            yupValidation: {
                                              required: true
                                            }
                                          }
                                        }
                                      ]
                                    },
                                    {
                                      typeKey: 'CONDITIONALLY_VISIBLE',
                                      properties: {
                                        dependendFieldId:
                                          'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionKeys-tp',
                                        value: {
                                          includes: [
                                            'ibtm-domain.application.license.issued-permission-duplicate.goods-carriage-transport-license'
                                          ]
                                        }
                                      },
                                      nodes: [
                                        {
                                          typeKey: 'FIELD',
                                          properties: {
                                            fieldId:
                                              'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-licenseDuplicates',
                                            yupValidation: {
                                              required: true
                                            }
                                          }
                                        }
                                      ]
                                    },
                                    {
                                      typeKey: 'CONDITIONALLY_VISIBLE',
                                      properties: {
                                        dependendFieldId:
                                          'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionKeys-tp',
                                        value: {
                                          includes: [
                                            'ibtm-domain.application.license.issued-permission-duplicate.goods-carriage-transport-license-abridgement'
                                          ]
                                        }
                                      },
                                      nodes: [
                                        {
                                          typeKey: 'FIELD',
                                          properties: {
                                            fieldId:
                                              'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-licenseCopyDuplicates',
                                            yupValidation: {
                                              required: true
                                            }
                                          }
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    {
                      typeKey: 'CONDITIONALLY_VISIBLE',
                      properties: {
                        dependendFieldId:
                          'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionKeys-op',
                        value: {
                          includes: [
                            'ibtm-domain.application.license.issued-permission-duplicate.passenger-carriage-permission',
                            'ibtm-domain.application.license.issued-permission-duplicate.passenger-carriage-permission-abridgement',
                            'ibtm-domain.application.license.issued-permission-duplicate.passenger-carriage-license',
                            'ibtm-domain.application.license.issued-permission-duplicate.passenger-carriage-license-abridgement'
                          ]
                        },
                        checkIfNotEmpty: true
                      },
                      nodes: [
                        {
                          typeKey: 'SECTION',
                          properties: {
                            title: 'Wtórniki uprawnień'
                          },
                          nodes: [
                            {
                              typeKey: 'CONDITIONALLY_VISIBLE',
                              properties: {
                                dependendFieldId: 'application-folder-typeKey',
                                value: {
                                  includes: ['ibtm-domain.folder.type.op']
                                }
                              },
                              nodes: [
                                {
                                  typeKey: 'GRID',
                                  properties: {
                                    gridItemProps: {
                                      xs: 12,
                                      sm: 6
                                    }
                                  },
                                  nodes: [
                                    {
                                      typeKey: 'CONDITIONALLY_VISIBLE',
                                      properties: {
                                        dependendFieldId:
                                          'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionKeys-op',
                                        value: {
                                          includes: [
                                            'ibtm-domain.application.license.issued-permission-duplicate.passenger-carriage-permission'
                                          ]
                                        }
                                      },
                                      nodes: [
                                        {
                                          typeKey: 'FIELD',
                                          properties: {
                                            fieldId:
                                              'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionDuplicates',
                                            yupValidation: {
                                              required: true
                                            }
                                          }
                                        }
                                      ]
                                    },
                                    {
                                      typeKey: 'CONDITIONALLY_VISIBLE',
                                      properties: {
                                        dependendFieldId:
                                          'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionKeys-op',
                                        value: {
                                          includes: [
                                            'ibtm-domain.application.license.issued-permission-duplicate.passenger-carriage-permission-abridgement'
                                          ]
                                        }
                                      },
                                      nodes: [
                                        {
                                          typeKey: 'FIELD',
                                          properties: {
                                            fieldId:
                                              'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionCopyDuplicates',
                                            yupValidation: {
                                              required: true
                                            }
                                          }
                                        }
                                      ]
                                    },
                                    {
                                      typeKey: 'CONDITIONALLY_VISIBLE',
                                      properties: {
                                        dependendFieldId:
                                          'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionKeys-op',
                                        value: {
                                          includes: [
                                            'ibtm-domain.application.license.issued-permission-duplicate.passenger-carriage-license'
                                          ]
                                        }
                                      },
                                      nodes: [
                                        {
                                          typeKey: 'FIELD',
                                          properties: {
                                            fieldId:
                                              'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-licenseDuplicates',
                                            yupValidation: {
                                              required: true
                                            }
                                          }
                                        }
                                      ]
                                    },
                                    {
                                      typeKey: 'CONDITIONALLY_VISIBLE',
                                      properties: {
                                        dependendFieldId:
                                          'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-permissionKeys-op',
                                        value: {
                                          includes: [
                                            'ibtm-domain.application.license.issued-permission-duplicate.passenger-carriage-license-abridgement'
                                          ]
                                        }
                                      },
                                      nodes: [
                                        {
                                          typeKey: 'FIELD',
                                          properties: {
                                            fieldId:
                                              'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-licenseCopyDuplicates',
                                            yupValidation: {
                                              required: true
                                            }
                                          }
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-subject-tab',
                additionalNodes: {
                  'application-section-end': [
                    {
                      typeKey: 'COMPONENT',
                      properties: {
                        componentId: 'application-subject-print-data-license-section'
                      }
                    },
                    {
                      typeKey: 'COMPONENT',
                      properties: {
                        componentId: 'application-subject-print-data-permission-section'
                      }
                    }
                  ]
                },
                additionalProperties: {
                  SUBJECT: {
                    inputMode: InputMode.VIEW
                  }
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-addresses-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-permissions-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-proxies-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-attachments-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-quotation-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-outgoing-documents-tab',
                additionalNodes: {
                  'outgoing-documents-additional-section': [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'application-outgoing-documents-additional-section-license-or-permission',
                        sectionTypes: [
                          AdditionalSectionLicenseOrPermissionType.PERMISSION,
                          AdditionalSectionLicenseOrPermissionType.LICENSE
                        ]
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-note-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-files-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-history-tab'
              }
            }
          ]
        }
      ]
    }
  ]
};

export default definition;
