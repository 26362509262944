import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AccountSnapshot } from '@avispon/user/dist/models';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { AutocompleteLazyFetchSelect, Dialog, FormV2Context, NUMBER_OF_AUTOCOMPLETE_RESULTS } from '@libs/common/v2';

import { API, UserQueryKeysEnum } from '@libs/user/api';

const InitialFormValues = {
  user: null
};

interface Props {
  closeDialog: () => void;
  onConfirm: (data: AccountSnapshot) => void;
  isSubmitting?: boolean;
  selectUserLabel: string;
}

function UserModalSelect({ closeDialog, onConfirm, isSubmitting, selectUserLabel }: Props) {
  const [t] = useTranslation();

  const validationSchema = Yup.object({
    user: Yup.object().nullable().required()
  });

  const form = useForm<Record<string, any>>({
    defaultValues: InitialFormValues,
    resolver: yupResolver(validationSchema),
    mode: 'onBlur'
  });

  const getOptionLabel = option => {
    return option.personalData ? `${option.personalData.name} ${option.personalData.surname}` : option.login ?? '';
  };

  const fetchUserFunction = (searchText: string, page: number) => {
    return API.user.accountController.search({
      page,
      size: NUMBER_OF_AUTOCOMPLETE_RESULTS,
      query: searchText,
      sort: []
    });
  };

  const parseDataToSelect = ({ data }) =>
    data.content.map(record => ({
      id: record.id,
      personalData: record.personalData,
      value: record.id,
      login: record.login,
      name: record.personalData ? `${record.personalData.name} ${record.personalData.surname}` : record.login || ''
    }));

  const handleConfirm = useCallback(
    values => {
      onConfirm(values.user);
      closeDialog();
    },
    [onConfirm, closeDialog]
  );

  const onSelectedUserChange = (_, value) => {
    form.setValue('user', value);
  };

  return (
    <FormV2Context.Provider value={form}>
      <form onSubmit={form.handleSubmit(handleConfirm)}>
        <Dialog
          title={t('user:selectUser')}
          confirmText={t('action.confirm')}
          cancelText={t('action.cancel')}
          onConfirm={form.handleSubmit(handleConfirm)}
          onCancel={closeDialog}
          isConfirmLoading={isSubmitting}
          isOpen
        >
          <Controller
            control={form.control}
            name="user"
            render={({ field: { value, onBlur }, fieldState }) => (
              <AutocompleteLazyFetchSelect
                fetchFunctionResolverWithPage={fetchUserFunction}
                fetchedDataSelectParser={parseDataToSelect}
                queryKey={UserQueryKeysEnum.USER_ACCOUNT_CONTROLLER_FIELD}
                name="user"
                getOptionLabel={getOptionLabel}
                renderOption={getOptionLabel}
                label={selectUserLabel}
                value={value}
                onBlur={onBlur}
                isError={!!fieldState.error}
                helperText={fieldState.error?.message}
                onChange={(e, v) => {
                  onSelectedUserChange(e, v);
                }}
                key="user"
                isRequired
              />
            )}
          />
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
}

export default UserModalSelect;
