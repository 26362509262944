import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { queryCache } from 'react-query';
import { useSnackbar } from '@enigma/fe-ui';

import { Button, ButtonsGroup, Dialog, FormV2Context } from '@libs/common/v2';

import { ApplicationQueryKeysEnum } from '../../application';
import { DomainDictionaryEntry } from '../../config';
import { useApplicationTransitScheduleQuery, useCreateTransitDateMutation } from '../api';

import { TransitScheduleCalendar } from '.';

interface IProps {
  closeDialog?: () => void;
  applicationData: { id: string; categoryKey: string };
  transitDataId: string;
}

function TransitDatesDialog({ closeDialog, applicationData, transitDataId }: IProps) {
  const { id: applicationId, categoryKey } = applicationData;
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const isCategorySpoWzw = categoryKey === DomainDictionaryEntry.APPLICATION_CATEGORY.SPO_WZW;
  const [t] = useTranslation();

  const [createTransitSchedule, createMutationState] = useCreateTransitDateMutation();
  const { showSuccessSnackbar } = useSnackbar();

  const { data } = useApplicationTransitScheduleQuery(applicationId, { enabled: false });

  const { setValue, reset, watch, handleSubmit, getValues, formState, ...form } = useForm<Record<any, any>>({
    mode: 'all',
    criteriaMode: 'all'
  });

  const formValues = useMemo(
    () => ({
      reset,
      setValue,
      handleSubmit,
      getValues,
      formState,
      ...form
    }),
    [form, formState, getValues, handleSubmit, reset, setValue]
  );

  const onSubmit = formData => {
    createTransitSchedule(
      { transitScheduleId: data.id, transitDatesCreateRequest: { dates: formData.dates } },
      {
        onSuccess: () => {
          queryCache.invalidateQueries([ApplicationQueryKeysEnum.TRANSIT_DATES, { transitScheduleId: data.id }]);
          showSuccessSnackbar(t('applications:transitSchedule.successMessages.dateAdded'));
          closeDialog();
        }
      }
    );
  };

  return (
    <FormV2Context.Provider value={formValues}>
      <Dialog
        title={
          isCategorySpoWzw
            ? t('applications:transitSchedule.dialog.addTransitDateTitle')
            : t('applications:transitSchedule.dialog.addTransitDatesTitle')
        }
        onCancel={closeDialog}
        customActions={
          <ButtonsGroup>
            <Button variant="outlined" onClick={closeDialog} label={t('action.cancel')} />
            <Button
              isLoading={createMutationState?.isLoading}
              disabled={isSaveDisabled}
              tooltipTitle={isSaveDisabled && t('applications:transitSchedule.dialog.saveTransitDatesDisabledTooltip')}
              variant="contained"
              label={t('action.save')}
              onClick={handleSubmit(onSubmit)}
            />
          </ButtonsGroup>
        }
        isOpen
      >
        <form>
          <TransitScheduleCalendar
            isCalendarRange={isCategorySpoWzw}
            setIsSaveDisabled={setIsSaveDisabled}
            transitDataId={transitDataId}
          />
        </form>
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default TransitDatesDialog;
