import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { object as YupObject, string as YupString } from 'yup';

import { isDateValid } from '@libs/common/v2';

import { DriverCertificatesColumnsEnum } from '../model';

function useDriverCertificatesExtendedValidation(editableColumns?: DriverCertificatesColumnsEnum[]) {
  const [t] = useTranslation();

  const validationScheme = useMemo(() => {
    const isColumnEditable = name => editableColumns?.includes(name);

    return YupObject({
      ...(isColumnEditable(DriverCertificatesColumnsEnum.DRIVER_CERTIFICATE_NUMBER) && {
        [DriverCertificatesColumnsEnum.DRIVER_CERTIFICATE_NUMBER]: YupString().required()
      }),
      ...(isColumnEditable(DriverCertificatesColumnsEnum.VALID_FROM) && {
        [DriverCertificatesColumnsEnum.VALID_FROM]: YupString().nullable().required().concat(isDateValid()),
        ...(isColumnEditable(DriverCertificatesColumnsEnum.VALID_TO) && {
          [DriverCertificatesColumnsEnum.VALID_TO]: YupString()
            .nullable()
            .required()
            .concat(isDateValid())
            .test({
              name: 'validFrom',
              message: t('applications:validations.validToDateCanNotBeBeforeValidFrom'),
              test(validTo) {
                const parent = this.parent as Record<DriverCertificatesColumnsEnum, string>;
                const validFrom = parent?.[DriverCertificatesColumnsEnum.VALID_FROM];
                return !validFrom || !validTo || moment(validFrom).isBefore(validTo);
              }
            })
        })
      })
    });
  }, [t, editableColumns]);

  return { validationScheme };
}

export default useDriverCertificatesExtendedValidation;
