import { InvoiceUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function updateCorrection(request: InvoiceUpdateRequest & { suspensionId: string; invoiceId: string }) {
  const { invoiceId, suspensionId, ...rest } = request;
  return API.suspensionReleaseDocuments.updateSuspensionInvoice(suspensionId, invoiceId, rest, {
    ...endpointsConfig.updateSuspensionInvoice
  });
}

function useSuspensionUpdateCorrectionMutation() {
  return useMutation(updateCorrection);
}

export default useSuspensionUpdateCorrectionMutation;
