import { FormMode } from '@libs/common/v2';

import { ProceedingDetailsWrapper } from '..';

interface IProps {
  formMode: FormMode;
}

function ProceedingDetailsPage({ formMode }: IProps) {
  return <ProceedingDetailsWrapper formMode={formMode} />;
}

export default ProceedingDetailsPage;
