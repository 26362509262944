import { HTMLAttributes, ReactNode, useMemo } from 'react';
import { InputAdornment, Tooltip } from '@mui/material';
import clsx from 'clsx';

import { Icon, useInputStyles } from '@libs/common/v2';

type Props = Pick<HTMLAttributes<HTMLDivElement>, 'className' | 'children'> & {
  helperText?: ReactNode;
  hasErrorTooltip?: boolean;
};

function ErrorAdornment({ children, className, helperText, hasErrorTooltip }: Props) {
  const classes = useInputStyles({});

  const renderInputAdornmentComponent = useMemo(
    () => (
      <InputAdornment className={clsx(classes.adornment, className)} position="end">
        <Icon
          color="error"
          colorWeight={500}
          icon="ValidationErrorIcon"
          width={18}
          height={18}
          iconWrapper="flex items-center"
          className={classes.errorIcon}
        />
        {children}
      </InputAdornment>
    ),
    [classes.adornment, classes.errorIcon, className, children]
  );

  if (hasErrorTooltip) {
    return (
      <Tooltip title={helperText} classes={{ popper: classes.tooltipError }} placement="bottom-end" open arrow>
        {renderInputAdornmentComponent}
      </Tooltip>
    );
  }

  return renderInputAdornmentComponent;
}

export default ErrorAdornment;
