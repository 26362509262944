export type ToggleButtonInitialValue = string | boolean | null;

export interface IToggleButtonOptions {
  value: ToggleButtonInitialValue;
  name: string;
}

export const DEFAULT_TOGGLE_BUTTONS_OPTIONS: Array<IToggleButtonOptions> = [
  { value: 'true', name: 'action.yes' },
  { value: 'null', name: 'action.notApply' },
  { value: 'false', name: 'action.no' }
];
