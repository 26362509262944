import { useTranslation } from 'react-i18next';

import { partialTranslate } from '@libs/common';
import { DatepickerField, GridLayout, Section } from '@libs/common/v2';
import { convertDateToDateFormat } from '@libs/common/v2/utils';

import { DomainUIElementEnum } from '@libs/domain/config';

import { isFieldVisible } from '../../../utils';

interface IProps {
  hiddenFields?: Array<'suspensionDecisionDate' | 'suspensionRenewalDate'>;
}

function SuspensionSection({ hiddenFields = [] }: IProps) {
  const [t] = useTranslation();
  const getTranslation = partialTranslate('roadTransport:fields');

  return (
    <Section
      title={t('roadTransport:sections.suspensionProceeding')}
      actionKey={DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_DECISION_SUSPENSION_SECTION}
      isCollapsable
    >
      <GridLayout itemProps={{ xs: 6 }}>
        {isFieldVisible('suspensionDecisionDate', hiddenFields) && (
          <DatepickerField
            name="suspensionDecisionDate"
            label={getTranslation('suspensionDecisionDate')}
            viewModeDateParser={convertDateToDateFormat}
          />
        )}

        {isFieldVisible('suspensionRenewalDate', hiddenFields) && (
          <DatepickerField
            name="suspensionRenewalDate"
            label={getTranslation('suspensionRenewalDate')}
            viewModeDateParser={convertDateToDateFormat}
          />
        )}
      </GridLayout>
    </Section>
  );
}

export default SuspensionSection;
