import { FolderPermissionSnapshot } from '@ibtm/domain';

import { useTableLayoutProps } from '@libs/common/v2';

import useFormOperationActivePermissionsColumns from './useFormOperationActivePermissionsColumns';

function useFormOperationActivePermissionsTable(data: FolderPermissionSnapshot[]) {
  const { columns } = useFormOperationActivePermissionsColumns();

  return useTableLayoutProps({
    tableQuery: data,
    tableHookOptions: {
      columns,
      tableOptions: {
        manualPagination: false,
        manualSortBy: false,
        manualFilters: false
      }
    }
  });
}

export default useFormOperationActivePermissionsTable;
