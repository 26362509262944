import { useRowSelect } from 'react-table';
import { FolderSearchFilter } from '@ibtm/domain';

import { useMultipleSelectColumn, useTableLayoutProps } from '@libs/common/v2';

import SelectionRowActionRender from '@libs/user/components/details/SelectionRowActionRender';

import { EntrepreneurDetailsSnapshot, useEntrepreneurQuery, useFolderColumns } from '@libs/domain/folder';

import { useEntrepreneursColumns } from '../../application/hooks';
import { EntrepreneursColumnsEnum } from '../../application/model';

function useSearchEntrepreneursTable(
  params: FolderSearchFilter,
  entrepreneur: EntrepreneurDetailsSnapshot[] = [],
  createMode?: boolean
) {
  const visibleColumns = [
    EntrepreneursColumnsEnum.FOLDER_NUMBER,
    EntrepreneursColumnsEnum.NAME,
    EntrepreneursColumnsEnum.NIP,
    EntrepreneursColumnsEnum.ADDRESS,
    EntrepreneursColumnsEnum.STATUS_KEY,
    EntrepreneursColumnsEnum.LEGAL_FORM_KEY,
    EntrepreneursColumnsEnum.FOLDER_NUMBER,
    EntrepreneursColumnsEnum.PENDING_PROCEEDING
  ];
  const { columns, mappedSortFields } = useEntrepreneursColumns(visibleColumns, createMode);
  const { mappedFilterFields } = useFolderColumns();

  const selectedEntrepreneur = (entrepreneur || []).map(({ customId, id }) => id || customId);

  return useTableLayoutProps({
    tableHookQuery: useEntrepreneurQuery,
    tableHookOptions: {
      columns,
      sortByConverter: mappedSortFields,
      filterConverter: mappedFilterFields,
      tablePlugins: [
        useRowSelect,
        useMultipleSelectColumn(SelectionRowActionRender, false, null, selectedEntrepreneur, true)
      ]
    },
    tableHookQueryInitialParams: {
      subjectNameContains: params.subjectNameContains,
      subjectNipContains: params.subjectNipContains
    }
  });
}

export default useSearchEntrepreneursTable;
