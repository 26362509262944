import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { ApplicationDetails } from '@ibtm/domain';

import { Dialog, useDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { ApplicationQueryKeysEnum } from '@libs/domain/application';
import { ExploitationBaseQueryKeysEnum, useCopyExploitationBasesMutation } from '@libs/domain/exploitation-base';

function useConfirmAddExploitationBaseDialog() {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { mutate: copyExploitationBases } = useCopyExploitationBasesMutation();

  const { openDialog } = useDialog();
  const { showSuccessSnackbar } = useSnackbar();

  const openConfirmAddBaseDialog = useCallback(
    (exploitationBaseIds: string[], applicationId: string) =>
      openDialog(({ closeDialog }) => (
        <Dialog
          title={t('exploitationBase:dialog.addBase.title')}
          confirmText={t('action.add')}
          cancelText={t('action.cancel')}
          contentText={
            exploitationBaseIds.length > 1
              ? t('exploitationBase:dialog.addBase.messagePlural')
              : t('exploitationBase:dialog.addBase.message')
          }
          onConfirm={setIsLoading => {
            setIsLoading(true);
            const applicationQuery = queryCache.getQuery([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
            const data = applicationQuery?.state?.data as ApplicationDetails;

            copyExploitationBases(
              {
                applicationId,
                linkExploitationBasesRequest: { ids: exploitationBaseIds, version: data?.version }
              },
              {
                onSuccess: () => {
                  showSuccessSnackbar(
                    exploitationBaseIds.length > 1
                      ? t('exploitationBase:details.message.basesAddedSuccessMessagePlural')
                      : t('exploitationBase:details.message.basesAddedSuccessMessage')
                  );
                  queryCache.invalidateQueries([ExploitationBaseQueryKeysEnum.EXPLOITATION_BASE_LIST]);
                  queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
                },
                onError: () => {},
                onSettled: () => {
                  closeDialog();
                  setIsLoading(false);
                }
              }
            );
          }}
          onCancel={closeDialog}
          isOpen
        />
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog, t, showSuccessSnackbar, copyExploitationBases]
  );

  return { openConfirmAddBaseDialog };
}

export default useConfirmAddExploitationBaseDialog;
