import { DefaultApiUnassignOrganizationUnitMemberRequest } from '@avispon/organization-structure/dist/api/default-api';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '..';

const unassignOrganizationUnitMember = async (params: DefaultApiUnassignOrganizationUnitMemberRequest) => {
  const { data } = await API.organizationStructure.unassignOrganizationUnitMember(params, {
    ...endpointsConfig.unassignOrganizationUnitMember
  });

  return data;
};

export default function useUnassignOrganizationUnitMemberMutation() {
  return useMutation(unassignOrganizationUnitMember);
}
