import { useEffect, useState } from 'react';
import { AddressUpdateRequest } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import {
  AutocompleteSelectField,
  DatepickerField,
  DictionarySelectField,
  GridItem,
  GridLayout,
  SelectOption,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { AddressSection } from '@libs/domain/address';
import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';
import {
  ExploitationBaseFormData,
  ExploitationBaseStatusEnum,
  useExploitationBasesQuery
} from '@libs/domain/exploitation-base';

const getLabel = partialTranslate('exploitationBase:field');

const hiddenFields: Array<keyof AddressUpdateRequest> = ['commune', 'county', 'apartmentNumber'];

interface IProps {
  isWithdrawnBaseVisible: boolean;
  folderId?: string;
  transferredFoldersIds?: string[];
  isAddressCountryFieldAvaliable: boolean;
}

function ExploitationBaseGeneralDataSection({
  isWithdrawnBaseVisible,
  folderId,
  transferredFoldersIds,
  isAddressCountryFieldAvaliable
}: IProps) {
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([]);
  const { formMode } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);
  const { data, isFetched } = useExploitationBasesQuery(
    {
      page: 0,
      size: 1000,
      sort: ['city,ASC'],
      folderIdIn: !viewMode && folderId ? [folderId, ...(transferredFoldersIds || [])] : [],
      statusIn: viewMode ? [] : [ExploitationBaseStatusEnum.CURRENT]
    },
    { enabled: isWithdrawnBaseVisible }
  );

  useEffect(() => {
    if (isFetched && data?.content) {
      const parseOptions = data.content.map(item => ({
        name: [item.city, item.postCode, item.street, item.propertyNumber].join(', '),
        value: item.id
      }));
      setSelectOptions(parseOptions);
    }
  }, [data, setSelectOptions, isFetched]);

  return (
    <GridLayout itemProps={{ xs: 12, sm: 6 }}>
      <GridItem xs={12}>
        <DictionarySelectField
          name={typedNameV2<ExploitationBaseFormData>('typeKeys')}
          label={getLabel('type')}
          dictionaryName={DomainDictionaryEnum.EXPLOITATION_BASE_TYPE}
          isRequired
          isMultiple
        />
      </GridItem>

      <GridItem xs={12}>
        <AddressSection
          formGroupName={typedNameV2<ExploitationBaseFormData>('address')}
          hiddenFields={hiddenFields}
          isCountryFieldAvaliable={isAddressCountryFieldAvaliable}
        />
      </GridItem>

      <GridItem xs={12}>
        <DictionarySelectField
          name={typedNameV2<ExploitationBaseFormData>('addressTypeKey')}
          label={getLabel('addressType')}
          dictionaryName={DomainDictionaryEnum.ADDRESS_TYPE}
          optionsFilter={option => option.value === DomainDictionaryEntry.ADDRESS_TYPE.BASE}
          isRequired
        />
      </GridItem>

      <DatepickerField
        name={typedNameV2<ExploitationBaseFormData>('validFrom')}
        label={getLabel('validFrom')}
        isRequired
      />

      <DatepickerField name={typedNameV2<ExploitationBaseFormData>('validTo')} label={getLabel('validTo')} />

      {isWithdrawnBaseVisible && (
        <AutocompleteSelectField
          name={typedNameV2<ExploitationBaseFormData>('cancelledExploitationBase')}
          label={getLabel('withdrawnBase')}
          options={selectOptions}
          isRequired
          isLoading={!isFetched}
        />
      )}
    </GridLayout>
  );
}

export default ExploitationBaseGeneralDataSection;
