import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

interface ContentProps {
  children?: React.ReactNode;
  active?: boolean;
  padded?: boolean;
  keepMounted?: boolean;
}

export function Content(props: ContentProps) {
  const { active, keepMounted, children, padded } = props;

  const classes = useStyles(props);
  const shouldBeRendered = active || keepMounted;

  return shouldBeRendered ? (
    <div className={clsx(classes.content, padded && 'p-24', { [classes.active]: active })}>{children}</div>
  ) : null;
}

interface ContentWrapperProps<T> {
  children: JSX.Element | JSX.Element[];
  value: T;
}

interface ContentWrapperChildProps<T> {
  value?: T;
  lazy?: boolean;
  keepMounted?: boolean;
}

export function ContentWrapper<T = unknown>({ children, value }: ContentWrapperProps<T>) {
  const [visited, setVisited] = useState<T[]>([]);

  useEffect(() => {
    if (!visited.includes(value)) {
      setVisited([...visited, value]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      {React.Children.map(children, child => {
        const { lazy, keepMounted } = child.props as ContentWrapperChildProps<T>;
        return React.cloneElement(child, {
          active: value,
          keepMounted: lazy ? keepMounted && visited.includes(value) : keepMounted
        });
      })}
    </>
  );
}

const useStyles = makeStyles({
  content: {
    display: ({ active, keepMounted }: ContentProps) => (!active && keepMounted ? 'none' : 'inline'),
    flex: '1 0 auto',
    position: 'relative'
  },
  active: {}
});
