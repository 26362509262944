import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { AxiosError, AxiosResponse } from 'axios';
import { get, isEmpty, startsWith, uniq } from 'lodash';

import { useMutation, useQueryCache } from '@libs/common/v2/api';
import { getValue } from '@libs/common/v2/utils';

import { ApiRegistry, ApiRegistryGet, ApiRegistrySubmit, Field, FieldTypes } from '@libs/meta-form/models';

import { getMetaFormQueryKey } from './useGetQuery';

const SUCCESS_STATUS_FIRST_NUMBER = '2';

const getArrayValues = (values: any[], formAccessor?: string) => {
  return values.map(item => {
    if (formAccessor) {
      return get(item, formAccessor, null);
    }
    return item.value;
  });
};

export const getFieldValue = ({
  typeKey,
  formValue,
  formAccessor
}: {
  typeKey: FieldTypes | keyof typeof FieldTypes;
  formValue: any;
  formAccessor: string;
}) => {
  switch (typeKey) {
    case FieldTypes.FOLDER_SELECT_FIELD:
    case FieldTypes.AUTOCOMPLETE:
    case FieldTypes.AUTOCOMPLETE_LAZY_FETCH: {
      if (formAccessor) {
        return Array.isArray(formValue) ? getArrayValues(formValue, formAccessor) : get(formValue, formAccessor, null);
      }
      return Array.isArray(formValue) ? getArrayValues(formValue) : formValue?.value;
    }
    case FieldTypes.DICTIONARY:
    case FieldTypes.DICTIONARY_QUICK_CHANGEABLE:
      if (formAccessor) {
        return Array.isArray(formValue) ? getArrayValues(formValue, formAccessor) : get(formValue, formAccessor, null);
      }
      return Array.isArray(formValue) ? getArrayValues(formValue) : formValue?.value;
    default:
      return formAccessor ? get(formValue, formAccessor, null) : formValue;
  }
};

type SubmitApiRegistryType = Array<{
  apiKey: string;
  apiCall: ApiRegistryGet | ApiRegistrySubmit;
  fields: Array<{
    fieldId: string;
    fieldTypeKey: FieldTypes | keyof typeof FieldTypes;
    accessor: string;
    formAccessor: string;
    refetchKey: string;
  }>;
}>;

const getSubmitApiRegistry = ({
  apiRegistry,
  fields
}: {
  apiRegistry: ApiRegistry;
  fields: Field[];
}): SubmitApiRegistryType =>
  Object.entries(apiRegistry)
    .map(([apiKey, apiCall]) => {
      return {
        apiKey,
        apiCall,
        fields: fields
          .filter(field => field.properties?.api?.submit?.requestKey === apiKey)
          .map(field => ({
            fieldId: field.fieldId,
            fieldTypeKey: field.properties.api.submit.typeKey || field.typeKey,
            accessor: field.properties?.api?.submit?.accessor ?? field.fieldId,
            formAccessor: field.properties?.api?.submit?.formAccessor ?? null,
            refetchKey: field.properties?.api?.get?.requestKey
          }))
      };
    })
    .filter(el => el.fields.length > 0);

/* eslint-disable no-param-reassign */
const setPath = (object, path, value) => {
  path.split('.').reduce((o, p, i, array) => {
    if (array.length === i + 1) {
      o[p] = value as unknown;
    } else {
      o[p] = (o[p] as unknown) || {};
    }
    return o[p];
  }, object);
  return object;
};

const fillApiBody = (apisubmitregistry: SubmitApiRegistryType, formDataValues: Record<string, unknown>) => {
  return apisubmitregistry.map(api => ({
    ...api,
    ...{
      body: Object.entries(formDataValues)
        .map(([key, value]) => ({ key, value }))
        .filter(el => api.fields.findIndex(field => field.fieldId === el.key) !== -1)
        .map(({ key, value }) => {
          const { fieldTypeKey, accessor, formAccessor } = api.fields.find(el => el.fieldId === key);
          return {
            key,
            value,
            fieldTypeKey,
            accessor,
            formAccessor
          };
        })
        .reduce((acc, el) => {
          const newObj = { ...acc };
          setPath(
            newObj,
            el.accessor,
            getFieldValue({
              typeKey: el.fieldTypeKey,
              formValue: el.value as string,
              formAccessor: el.formAccessor
            })
          );
          return newObj;
        }, {})
    }
  }));
};

const getApiCall = async (apiCall, data) => {
  return apiCall(data);
};

function useSubmitMutation({ apiRegistry, fields }: { apiRegistry: Record<string, any>; fields: Array<Field> }) {
  const queryCache = useQueryCache();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbar();
  const [t] = useTranslation();

  const mutationSubmit = async (formData: Record<string, any>) => {
    const filteredFields = fields.filter(({ fieldId }) => Object.keys(formData).includes(fieldId));
    const apiEndpoints = fillApiBody(getSubmitApiRegistry({ apiRegistry, fields: filteredFields }), formData);

    const refetchKeys = uniq(
      apiEndpoints.reduce((acc: string[], curr) => [...acc, ...curr.fields.map(item => item.refetchKey)], [])
    );

    try {
      const response: AxiosResponse[] = await Promise.all(
        apiEndpoints.map(el => {
          return getApiCall(el.apiCall, el.body);
        })
      );

      refetchKeys.map(queryKey => queryCache.invalidateQueries([getMetaFormQueryKey(queryKey)]));

      const statuses =
        response
          .flat(1)
          .filter(Boolean)
          ?.map(({ status }) => status?.toString(10)) ?? [];
      const isSuccessStatus =
        statuses.every(status => startsWith(status, SUCCESS_STATUS_FIRST_NUMBER)) && !isEmpty(statuses);

      if (!isSuccessStatus) {
        throw new Error(t('error.dataSaveFailure'));
      }

      return { response, refetchKeys };
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  return useMutation(mutationSubmit, {
    onSuccess: () => {
      showSuccessSnackbar(t('success.save'));
    },
    onError: (error: AxiosError) => {
      if (!error?.request) {
        showErrorSnackbar(getValue(error?.message));
      }
    }
  });
}

export default useSubmitMutation;
