import { FieldPath, FieldValues } from 'react-hook-form';
import { DataSourceMediatorColumn } from '@stack/report/dist/models';

import { useFormV2Context } from '@libs/common/v2';

import { ConditionOperator } from '../../../model';

import { Condition } from './Condition';
import { ConditionGroup } from './condition-group';
import { SingleConditionForm } from './SingleConditionForm';

interface IProps {
  name: FieldPath<FieldValues>;
  columns: DataSourceMediatorColumn[];
}

function ConditionBasicView({ name, columns }: IProps) {
  const { getValues } = useFormV2Context();
  const queryConditionsField = getValues(`${name}.group`);

  return (
    <ConditionGroup operator={(getValues(`${name}.operator`) as ConditionOperator) ?? ConditionOperator.AND} readonly>
      {queryConditionsField &&
        queryConditionsField.length > 0 &&
        queryConditionsField.map((field, index) => {
          const groupName = `${name}.group.${index}`;
          const fieldName = `${name}.group.${index}.filter`;
          return field.group ? (
            <ConditionBasicView key={groupName} name={groupName} columns={columns} />
          ) : (
            <Condition key={fieldName}>
              <SingleConditionForm fieldName={fieldName} columns={columns} />
            </Condition>
          );
        })}
    </ConditionGroup>
  );
}

export default ConditionBasicView;
