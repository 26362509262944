import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment';

import {
  ALTERNATIVE_DATE_FORMAT,
  Button,
  Calendar,
  CalendarViewEnum,
  ICalendarProps,
  ISectionProps,
  LoseValueType,
  Section
} from '@libs/common/v2';

export interface ICalendarSectionProps {
  // Props dla komponentu Sekcji
  sectionClassName?: string;
  sectionContentClassName?: string;
  sectionProps?: Omit<ISectionProps, 'children' | 'className' | 'contentClassName'>;
  /**
   * Funkcja do ustawienia aktualnie wybranych dni w kalendarzu
   */
  setField: (value: LoseValueType) => void;
  /**
   * Wartość jaka ma zostać ustawiona po naciśnięciu przycisku wyczyść
   */
  afterClearValue?: LoseValueType;
  /**
   * Formatowanie daty, domyślnie YYYY-MM-DD
   */
  dateFormat?: string;
  calendarClassName?: string;
  /**
   * Początkowe dni zaznaczone w kalendarzu
   */
  value?: LoseValueType;
  calendarProps?: Omit<ICalendarProps, 'className' | 'wrapperClassName' | 'getShownMonthYear'>;
  /**
   * Jeżeli chcemy żeby użytkownik mógł zaznaczać różne daty
   */
  isCustomeDayPickEnabled?: boolean;
  /**
   * Wartość decyduje o wyświetleniu przycisku zaznaczenia całego miesiąca,
   * lub do daty do jeżeli jest krótsza niż miesiąc.
   * Domyślnie jest o wartości props `isCustomeDayPickEnabled`
   */
  isSelectWholeMonthEnabled?: boolean;
  /**
   * Wartość decyduje o wyświetleniu przycisku zaznaczenia całego roku,
   * lub do daty do jeżeli jest krótsza niż rok.
   * Domyślnie jest o wartości props `isCustomeDayPickEnabled`
   */
  isSelectWholeYearEnabled?: boolean;
  isViewOnly?: boolean;
  /**
   * Tablica dni, które nie mają być dostępne do zaznaczenia
   */
  disabledDays?: string[];
  checkDisabledDays?: (startDate, endDate) => Promise<string[]>;
}

export function listMinMaxDateToArray(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);
  if (!start.isValid() || !end.isValid()) {
    return [];
  }
  const dates = [start.format(ALTERNATIVE_DATE_FORMAT)];
  for (let i = 1; i < moment.duration(endDate.diff(startDate)).asDays(); i++) {
    dates.push(moment(startDate).add(i, 'days').format(ALTERNATIVE_DATE_FORMAT));
  }

  return dates as [string, string];
}

function CalendarSection({
  sectionClassName,
  sectionContentClassName,
  calendarClassName,
  value: initialValue,
  isViewOnly,
  afterClearValue,
  setField,
  dateFormat = ALTERNATIVE_DATE_FORMAT,
  isCustomeDayPickEnabled,
  calendarProps: { isSelectRange, minDate, maxDate, ...restCalendarProps } = {},
  isSelectWholeMonthEnabled = isCustomeDayPickEnabled || isSelectRange,
  isSelectWholeYearEnabled = isCustomeDayPickEnabled || isSelectRange,
  disabledDays,
  sectionProps: { headerContent, ...restSectionProps } = {},
  checkDisabledDays
}: ICalendarSectionProps) {
  const [t] = useTranslation();
  const [disabledDaysInMonth, setDisabledDaysInMonth] = useState<string[]>([]);

  useEffect(() => {
    if (checkDisabledDays) {
      const fetchData = async () => {
        const result = await checkDisabledDays(
          moment().startOf('month').subtract(7, 'd').toString(),
          moment().endOf('month').add(7, 'd').toString()
        );
        setDisabledDaysInMonth(result);
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialState = () => {
    if (isCustomeDayPickEnabled) {
      return initialValue ?? [];
    }
    if (isSelectRange) {
      return initialValue ?? [];
    }
    return initialValue ?? '';
  };
  const [value, setValue] = useState(initialState());
  const [currentViewStartDate, setCurrentViewStartDate] = useState<{ date: Date; view: CalendarViewEnum }>();

  useEffect(() => {
    if (value) {
      const parseValue = () => {
        if (dateFormat) {
          return Array.isArray(value)
            ? setField(value.map(val => moment(val).format(dateFormat)) as LoseValueType & LoseValueType[])
            : setField?.(moment(value).format(dateFormat) as LoseValueType & LoseValueType[]);
        }
        return setField(value as LoseValueType & LoseValueType[]);
      };
      parseValue();
    }
  }, [dateFormat, setField, value]);

  // Funkcja jest wykorzystana i przekazana gdy zaznaczamy różne daty. Styluje zaznaczone dni.
  const handleIsTileActive = ({ date, view }) => {
    if (isCustomeDayPickEnabled && Array.isArray(value)) {
      if (view === CalendarViewEnum.YEAR) {
        return value.find(el => moment(el).isSame(moment(date), 'month'));
      }
      return value.find(el => moment(el).isSame(moment(date)));
    }
    return null;
  };

  // Jeżeli wybieramy różne daty wtedy zarządzamy stanem w rodzicu ( czyli tutaj ) i nie przekazujemy stanu
  // do kalendarza. W innym wypadku przekazujemy stan do kalendarza.
  const valueProp = useMemo(() => {
    if (isCustomeDayPickEnabled) {
      return null;
    }
    if (Array.isArray(value)) {
      return value.length ? (value.map(val => new Date(val)) as LoseValueType) : null;
    }
    return value ? (new Date(value) as LoseValueType) : null;
  }, [isCustomeDayPickEnabled, value]);

  // Funkcja jest używana przy zaznaczaniu różnych dni. Dodaje zaznaczone daty i odfiltrowuje odznaczone.
  const filterAddDate = (val): LoseValueType => {
    if (Array.isArray(value)) {
      let dateExist = null;
      const restDates = [];

      [...value].forEach(item => {
        if (moment(item).isSame(val)) {
          dateExist = item;
        } else {
          restDates.push(item);
        }
      });
      return dateExist ? (restDates as LoseValueType) : (Array.from(restDates).concat(val) as LoseValueType);
    }
    return null;
  };

  const handleOnChange = selectedValue => {
    if (isViewOnly) {
      return;
    }
    if (isCustomeDayPickEnabled) {
      setValue(filterAddDate(selectedValue));
      return;
    }
    setValue(selectedValue);
  };

  const handleOnClear = () => {
    if (afterClearValue) {
      setValue(afterClearValue);
    }
    setValue(isCustomeDayPickEnabled || isSelectRange ? [] : '');
  };

  // Zaznaczanie całego miesiąca
  const handleSelectWholeMonth = () => {
    // Weryfikacja czy początkowa data nie jest wcześniej niż minDate
    const startMonth = () => {
      const currStartDate = currentViewStartDate?.date ? moment(currentViewStartDate.date) : moment();
      if (minDate) {
        return currStartDate.isBefore(moment(minDate)) ? moment(minDate) : currStartDate.startOf('month');
      }
      return currStartDate.startOf('month');
    };
    // Weryfikacja czy końcowa data nie jest po maxDate
    const endMonth = () => {
      const currEndDate = currentViewStartDate?.date
        ? moment(currentViewStartDate.date).endOf('month')
        : moment().endOf('month');
      if (maxDate) {
        return currEndDate.isAfter(moment(maxDate)) ? moment(maxDate) : currEndDate;
      }
      return currEndDate;
    };

    if (isCustomeDayPickEnabled) {
      return setValue(prev =>
        [...listMinMaxDateToArray(startMonth(), endMonth())].reduce((acc, item) => {
          if (!acc.includes(item)) {
            return acc.concat(item);
          }
          return acc;
        }, prev)
      );
    }
    return setValue([startMonth().format(ALTERNATIVE_DATE_FORMAT), endMonth().format(ALTERNATIVE_DATE_FORMAT)]);
  };

  // Zaznaczenie całego roku
  const handleSelectWholeYear = () => {
    const startDate = () => {
      const startYear = currentViewStartDate?.date
        ? moment(currentViewStartDate.date).startOf('year')
        : moment().startOf('year');

      if (minDate) {
        return moment(minDate).isBefore(startYear) ? startYear : moment(minDate);
      }
      return startYear;
    };
    const endDate = () => {
      const endYear = currentViewStartDate?.date
        ? moment(currentViewStartDate.date).endOf('year')
        : moment().endOf('year');
      if (maxDate) {
        return moment(maxDate).isAfter(endYear) ? endYear : moment(maxDate);
      }
      return endYear;
    };
    if (isCustomeDayPickEnabled) {
      return setValue(prev =>
        [...listMinMaxDateToArray(startDate(), endDate())].reduce((acc, item) => {
          if (!acc.includes(item)) {
            return acc.concat(item);
          }
          return acc;
        }, prev)
      );
    }
    return setValue([startDate().format(ALTERNATIVE_DATE_FORMAT), endDate().format(ALTERNATIVE_DATE_FORMAT)]);
  };

  const handleChangeMonth = async (date: Date) => {
    const startDayForRequest = moment(date).startOf('month').subtract(7, 'd').toString();
    const endDayForRequest = moment(date).endOf('month').add(7, 'd').toString();

    setDisabledDaysInMonth(await checkDisabledDays(startDayForRequest, endDayForRequest));
  };

  return (
    <Section
      className={sectionClassName}
      contentClassName={clsx('flex justify-between w-auto pb-0 pt-0', sectionContentClassName)}
      headerContent={<>{headerContent}</>}
      isModalSection
      {...restSectionProps}
    >
      <Calendar
        className={calendarClassName}
        setCurrentViewStartDate={(date, view) => {
          setCurrentViewStartDate({ date, view });
          if (view === CalendarViewEnum.MONTH && checkDisabledDays) {
            handleChangeMonth(date);
          }
        }}
        onChange={handleOnChange}
        value={valueProp}
        minDate={minDate}
        maxDate={maxDate}
        isTileActive={isCustomeDayPickEnabled ? params => !!handleIsTileActive(params) : null}
        isSelectRange={isSelectRange}
        isDayTileDisabled={({ date }) =>
          disabledDaysInMonth.includes(moment(date).format(ALTERNATIVE_DATE_FORMAT)) ||
          (disabledDays?.length && disabledDays.includes(moment(date).format(ALTERNATIVE_DATE_FORMAT)))
        }
        {...restCalendarProps}
      />
      <div className="flex flex-col">
        {!isViewOnly && isSelectWholeMonthEnabled && (
          <Button label={t('action.selectWholeMonth')} onClick={handleSelectWholeMonth} variant="outlined" />
        )}
        {!isViewOnly && isSelectWholeYearEnabled && (
          <Button
            className="w-full"
            label={t('action.selectWholeYear')}
            onClick={handleSelectWholeYear}
            variant="outlined"
          />
        )}
        {!isViewOnly && (
          <Button className="w-full" label={t('action.clear')} onClick={handleOnClear} variant="outlined" />
        )}
      </div>
    </Section>
  );
}

export default CalendarSection;
