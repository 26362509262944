import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { PaginatedQueryResult } from 'react-query';
import { Filters, PluginHook, Row, SortingRule, TableOptions, TableState, useTable } from 'react-table';
import deepmerge from 'deepmerge';
import _, { isEmpty } from 'lodash';

import {
  CommonTablePlugins,
  filterTypes,
  getTheLongestRowToRenderColumns,
  IPaginatedModel,
  ITableAdapter,
  ITableLayoutColumn,
  Path,
  TableEvent,
  useCreateTableInstance,
  useTableEventsContext
} from '@libs/common/v2';
import { adaptPagination, convertFilters, convertInitialParams, convertSortBy } from '@libs/common/v2/utils';

const DEFAULT_INITIAL_PARAMS = { pageIndex: 0, pageSize: 25, sortBy: null };

type ParamsObject = { pageSize?: number; pageIndex?: number; sortBy?: SortingRule<any>[]; filters?: Filters<any> };
export interface PaginationSearchParams {
  pageIndex: number;
  pageSize: number;
  sortBy: any;
}

export interface IPaginatedQueryTableOptions<T extends Record<string, any>, K extends Record<string, any> = T> {
  /**
   * @type T - model Frontend/Backend.
   * @type K - opcjonalny model Backend. Wykorzystywany przy mappowaniu wartości do pliku xlsx
   * @konwencja typ `T`to model zawierający definicje danych do tabeli. Może być modelem backend'u,
   * jeżeli na nim jest oparta implementacja gui, lub jeżeli dane są parsowane i dostosowane go modelu
   * frontend'owego, który jest rozbieżny z modelem backend wtedy do typu `T` przypisujemy model frontend,
   * natomiast do typu `K` przypisujemy model backend. W ten sposób przy mappowaniu wartości do pliku xlsx
   * mamy dostęp do atrybutów z modelu backend.
   * @implementacja Zakłada użycie którejś z funkcji hook'a useCreateColumns.
   * Tablica obiektów na podstawie których zostają wyrenderowane kolumny tabeli.
   * Lub funkcja w której dostajemy jako argument obiekt,
   * który przekazujemy dalej do fukcji tworzącej kolumny (createColumnsWithArrays)
   */
  columns: ITableLayoutColumn<T, K>[] | ((mapper: Record<string, number>) => ITableLayoutColumn<T, K>[]);
  /**
   * Ścieżki w modelu do tablic z danymi
   */
  arrayPaths?: Path<T>[];
  // Konwerter filtrów globalnych i dodatkowych (konwersja wartości filtra na wartość przyjmowaną przez api)
  initialParamsConverter?: Record<string, (value: unknown) => Record<string, any>>;
  // Konwerter filtrów tabeli (konwersja wartości filtra na wartość przyjmowaną przez api)
  filterConverter?: Record<string, (value: unknown) => Record<string, any>>;
  // Konwerter sortowania tabeli (konwersja klucza tabeli na klucz przyjmowany przez api)
  sortByConverter?: Partial<Record<keyof T, string | string[]>>;
  // Dodatkowe opcje do tabeli
  tableOptions?: Partial<TableOptions<T>>;
  // Dodatkowe pluginy do tabeli
  tablePlugins?: Array<PluginHook<T>>;
  // nie zawsze w modelu mamy id, zmiana id na inny parametr
  getRowId?: (row) => string;
}

// Domyślna wartość danych - wyciągnięta poza hook żeby nie wywoływać rerenderingu
const EmptyArray = [];

/**
 * Hook przystosowany do działania z hook `useCustomTableQuery`.
 * Używanie bez niego może prowadzić do dziwnych zachowań
 */
function usePaginatedQueryTable<T extends Record<string, any>, K extends Record<string, any> = T>(
  usePaginatedDataHook: (params, options, queryKey) => PaginatedQueryResult<IPaginatedModel<T>>,
  options: IPaginatedQueryTableOptions<T, K> = { columns: EmptyArray },
  queryInitialParams?: any,
  paginatedDataHookQueryConfig = {},
  queryKey?: string
): [
  ITableAdapter<T, K>,
  PaginatedQueryResult<IPaginatedModel<T>>,
  { isLoading: boolean; newRowScheme?: Row<T> },
  Dispatch<SetStateAction<PaginationSearchParams>>
] {
  const {
    columns,
    sortByConverter,
    filterConverter,
    initialParamsConverter,
    tableOptions,
    tablePlugins,
    getRowId,
    arrayPaths
  } = options;

  const memoizedSortByConverter = useMemo(() => sortByConverter, [sortByConverter]);
  const memoizedFilterConverter = useMemo(() => filterConverter, [filterConverter]);

  const tableInitialStateParser = useMemo(() => {
    const sortBy = tableOptions?.initialState?.sortBy;
    const tableInitialSortState = memoizedSortByConverter ? convertSortBy(sortBy, memoizedSortByConverter) : sortBy;
    const filters = tableOptions?.initialState?.filters;
    const tableInitialFilterState =
      filters && memoizedFilterConverter ? convertFilters(filters, memoizedFilterConverter) : filters;
    const tableInitialState = {
      sortBy: tableInitialSortState,
      ...tableInitialFilterState,
      ..._.omit(tableOptions?.initialState, ['sortBy', 'filters'])
    };
    return tableInitialState;
  }, [memoizedFilterConverter, memoizedSortByConverter, tableOptions?.initialState]);

  const [params, setParams] = useState<PaginationSearchParams>(
    deepmerge(DEFAULT_INITIAL_PARAMS, tableInitialStateParser ?? {})
  );

  const initialParams = () => {
    if (initialParamsConverter) {
      const paramsToConvert =
        Object.entries(queryInitialParams).map(([key, value]) => {
          if (typeof value === 'object' && !_.isArray(value)) {
            return { ...value, id: key };
          }
          return { id: key, value };
        }) ?? [];
      return convertInitialParams(paramsToConvert, initialParamsConverter);
    }
    return queryInitialParams;
  };

  const query = usePaginatedDataHook(
    { ...initialParams(), ...adaptPagination(params) },
    paginatedDataHookQueryConfig,
    queryKey
  );

  const longestRow = useMemo(
    () => getTheLongestRowToRenderColumns(query?.resolvedData?.content, arrayPaths) as Record<string, number>,
    [arrayPaths, query?.resolvedData?.content]
  );

  const manualTableOptions = {
    manualPagination: true,
    manualFilters: true,
    manualSortBy: true,
    initialState: {
      pageSize: 25,
      pageIndex: query?.latestData?.number ?? 0
    },
    filterTypes
  };

  const tableNewRowScheme = useCreateTableInstance<T, K>({
    columns,
    getRowId: row => (getRowId ? getRowId(row) : row.id),
    longestRow,
    queryData: useMemo(() => [{ id: `newFirstRow` }], []),
    tableOptions: deepmerge(manualTableOptions, tableOptions ?? {}),
    tablePlugins
  });

  const table = useTable<any>(
    {
      columns: useMemo(() => (typeof columns === 'function' ? columns(longestRow) : columns), [columns, longestRow]),
      data: query.resolvedData?.content ?? EmptyArray,
      pageCount: query.latestData?.totalPages ?? query.resolvedData?.totalPages ?? -1,
      getRowId: row => (getRowId ? getRowId(row) : row.id),
      ...deepmerge(manualTableOptions, tableOptions ?? {})
    },
    ...CommonTablePlugins,
    ...(tablePlugins ?? EmptyArray)
  );
  const { emitEvent } = useTableEventsContext();
  return useMemo(() => {
    const prepareParamsObject = ({ pageIndex: pIndex, sortBy, filters, pageSize }: ParamsObject) => ({
      pageIndex: pIndex,
      pageSize,
      sortBy: sortByConverter
        ? convertSortBy(isEmpty(sortBy) ? DEFAULT_INITIAL_PARAMS.sortBy : sortBy, sortByConverter)
        : sortBy,
      ...(filterConverter ? convertFilters(filters, filterConverter) : filters)
    });
    const setParamsDependedOnHookedState = (updater: (hookedState: TableState<any>) => Partial<ParamsObject>) => {
      table.hookTableState(state => {
        setParams(prevState => {
          return prepareParamsObject({
            ...prevState,
            filters: state.filters,
            sortBy: state.sortBy,
            ...updater(state)
          });
        });
      });
    };

    const getSchemeRow = () => {
      if (tableNewRowScheme?.page?.[0]) {
        tableNewRowScheme.prepareRow(tableNewRowScheme?.page?.[0]);
        return tableNewRowScheme?.page?.[0];
      }
      return tableNewRowScheme?.page?.[0];
    };

    const additionalData = {
      isLoading: query.isLoading,
      newRowScheme: getSchemeRow()
    };
    const { gotoPage: gotoPageTable, setPageSize, clearFilters } = table;

    table.gotoPage = (updater: number | ((pageIndex: number) => number)) => {
      gotoPageTable(updater);
      setParamsDependedOnHookedState(state => {
        return { pageIndex: typeof updater === 'function' ? updater(state.pageIndex) : updater };
      });
    };
    table.setPageSize = (pageSize: number) => {
      setPageSize(pageSize);
      setParamsDependedOnHookedState(() => {
        return { pageSize };
      });
    };
    table.onFilterChange = () => {
      setParamsDependedOnHookedState(state => {
        return { filters: state.filters, pageIndex: 0 };
      });
    };
    table.onSortChange = () => {
      setParamsDependedOnHookedState(state => {
        return { sortBy: state.sortBy };
      });
    };

    table.clearFilters = () => {
      emitEvent(TableEvent.ON_FILTER_CLEAR);
      setTimeout(() => {
        clearFilters();
        setParamsDependedOnHookedState(() => {
          return { filters: [] };
        });
      }, 0);
    };

    return [table, query, additionalData, setParams];
  }, [query, table, sortByConverter, filterConverter, tableNewRowScheme, emitEvent]);
}

export default usePaginatedQueryTable;
