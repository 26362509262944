import { ExploitationBaseSnapshot } from '@ibtm/domain';

import { CamelCasePath } from '@libs/common/v2';

export const VISIBLE_COLUMNS: Array<CamelCasePath<ExploitationBaseSnapshot>> = [
  'typeKeys',
  'assignedSubjects',
  'assignedVehicles',
  'postCode',
  'postCity',
  'voivodeshipKey',
  'city',
  'street',
  'propertyNumber',
  'validFrom',
  'lastInspectionResultInspectionResultKey',
  'lastInspectionResultInspectionDate',
  'lastInspectionRemarks'
];
