import { FieldPath, FieldValues } from 'react-hook-form';

import { FormErrorType, InputMode, SelectFieldValidation, SelectMultipleFieldValidation } from '@libs/common/v2';

import { DictionaryEntryNameEnum, DictionaryQuickChangeableEntryNameEnum } from '@libs/dictionary';
import { PermissionEnum } from '@libs/permission';

import { NodeVisibility } from './node.model';
import { ValidationProperties, ValidationRegistryEntry } from './validation-properties.model';

export type Field = {
  fieldId: FieldPath<FieldValues>;
  typeKey: FieldTypes | keyof typeof FieldTypes;
  properties: FieldProperties;
};

export enum FieldTypes {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DICTIONARY = 'DICTIONARY',
  DATETIME = 'DATETIME',
  BOOLEAN = 'BOOLEAN',
  COLLECTION = 'COLLECTION',
  TYPOGRAPHY = 'TYPOGRAPHY',
  HIDDEN = 'HIDDEN',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  AUTOCOMPLETE_LAZY_FETCH = 'AUTOCOMPLETE_LAZY_FETCH',
  DICTIONARY_QUICK_CHANGEABLE = 'DICTIONARY_QUICK_CHANGEABLE',
  FOLDER_SELECT_FIELD = 'FOLDER_SELECT_FIELD'
}

export type FieldProps<T> = {
  fieldId?: FieldPath<FieldValues>;
  disabled?: boolean;
  inputMode?: InputMode;
  visibility?: NodeVisibility;
} & T;

export type FieldProperties = (
  | TypographyFieldProperties
  | DatetimeFieldProperties
  | TextFieldProperties
  | DictionaryFieldProperties
  | HiddenFieldProperties
  | BooleanFieldProperties
  | QuickChangeableDictionaryFieldProperties
  | AutocompleteFieldProperties
) &
  CommonProperties;

export type CommonProperties = {
  containerClassName?: string;
  validation?: CustomValidation;
  inputMode?: InputMode;
  multiple?: boolean;
  visibility?: NodeVisibility;
  disabled?: boolean;
  label?: string;
};

export type CustomValidation = {
  conditions: Array<{
    conditionKey: string;
    params: Array<string | number>;
  }>;
  mode: FormErrorType;
  text: string;
};

export type ApiProp = {
  /** Request wywoływany przy zapisywaniu formularza */
  submit?: {
    /** Klucz requesty z rejestru API przekazywanego w prop apiRegistry w komponencie Metaform */
    requestKey: string;
    /** odnośnik do pola przekazywanego w body requesta */
    accessor?: string;
    /**
     * odnośnik do pola przekazywanego w "accessor",jeżeli * wartość fielda jest obiektem, a potrzebujemy
     * przekazać  wybrane pole
     */
    formAccessor?: string;
    /** Typ fielda do przekazania w przypadku gdy używamy
     * properties "submit" w customowym fieldzie,
     * aby wskazać w jaki sposób useSubmitMutation powinno sparsować
     * wartość inputa do formularza np. w przypadku select wartością jest obiekt i
     * zazwyczaj chcemy { value:"" } przekazać samą wartość value
     * */
    typeKey?: FieldTypes | keyof typeof FieldTypes;
  };
  get?: {
    // Request pobierający dane początkowe dla pola
    requestKey?: string; // Klucz requesty z rejestru API przekazywanego w prop apiRegistry w komponencie Metaform
    accessor?: string; // odnośnik do pola z obiektu danych pobieranych z API
    /** umożliwia przekazanie parametrów do funkcji api.get.request */
    backendParams?: Record<string, unknown>;
  };
  fetch?: {
    // Request pobierający dodatkowe opcje dla pola
    requestKey: string; // Klucz requesty z rejestru API przekazywanego w prop apiRegistry w komponencie Metaform
    accessor?: string; // odnośnik do pola z obiektu danych pobieranych z API
    optionTextMatch?: string; // odnośnik do pola z obiektu danych pobieranych z API renderujący labelke opcji
    backendFilterParamName?: string; // nazwa parametru backendowego, pozwalającego na filtrowanie opcji
    /** umożliwia przekazanie parametrów do funkcji api.fetch.request */
    backendParams?: Record<string, unknown>;
  };
};

type PermissionProps = {
  permission?: {
    view?: PermissionEnum[];
    form?: PermissionEnum[];
  };
};

export type HiddenFieldProperties<T extends ApiProp = ApiProp> = {
  api?: T;
  yupValidation?: Record<string, unknown>;
} & PermissionProps;

export type DictionaryFieldProperties<T extends ApiProp = ApiProp> =
  | (
      | {
          dictionaryName?: DictionaryEntryNameEnum;
          label?: string;
          freeSolo?: boolean;
          multiple?: false;
          required?: boolean;
          api: T;
          optionsFilter?: string;
          defaultInitialValue?: unknown;
          stringValue?: boolean;
          yupValidation?: ValidationProperties<ValidationRegistryEntry['DICTIONARY']>;
          triggerFieldIdValidationOnChange?: string[];
        }
      | {
          dictionaryName?: DictionaryEntryNameEnum;
          label?: string;
          freeSolo?: boolean;
          multiple?: true;
          required?: boolean;
          api: T;
          optionsFilter?: string;
          defaultInitialValue?: unknown;
          stringValue?: boolean;
          yupValidation?: ValidationProperties<ValidationRegistryEntry['multiple']['DICTIONARY']>;
          triggerFieldIdValidationOnChange?: string[];
        }
    ) &
      PermissionProps;

export type QuickChangeableDictionaryFieldProperties<T extends ApiProp = ApiProp> =
  | (
      | {
          dictionaryName?: DictionaryQuickChangeableEntryNameEnum;
          label?: string;
          freeSolo?: boolean;
          multiple?: false;
          required?: boolean;
          api: T;
          optionsFilter?: string;
          defaultInitialValue?: unknown;
          yupValidation?: ValidationProperties<ValidationRegistryEntry['DICTIONARY_QUICK_CHANGEABLE']>;
        }
      | {
          dictionaryName?: DictionaryQuickChangeableEntryNameEnum;
          label?: string;
          freeSolo?: boolean;
          multiple?: true;
          required?: boolean;
          api: T;
          optionsFilter?: string;
          defaultInitialValue?: unknown;
          yupValidation?: ValidationProperties<ValidationRegistryEntry['multiple']['DICTIONARY_QUICK_CHANGEABLE']>;
        }
    ) &
      PermissionProps;

interface DefaultAutocompleteInitialValue {
  /**
   * Wartość jaką ma mieć domyślnie wybrana opcja
   */
  value: unknown;
  /**
   * Ścieżka do wartości w obiekcie SelectOption pola Autocomplete
   * jeżeli któraś opcja ma wskazaną wartość, zostanie domyślnie wybrana jako wartość początkowa
   */
  accessor?: string;
}
interface DefaultAutocompleteInitialValueMultiple {
  /**
   * Wartości jakie ma mieć domyślnie wybrana opcja
   */
  values: Array<unknown>;
  /**
   * Ścieżka do wartości w obiekcie SelectOption pola Autocomplete
   * jeżeli któreś opcje mają wskazane wartości, zostanie domyślnie wybrane jako wartość początkowa
   */
  accessor?: string;
}
export type AutocompleteFieldProperties<T extends ApiProp = ApiProp> =
  | (
      | {
          label?: string;
          onValueChange?: any;
          required?: boolean;
          multiple?: false;
          api: T;
          defaultInitialValue?: DefaultAutocompleteInitialValue;
          yupValidation?: ValidationProperties<ValidationRegistryEntry['AUTOCOMPLETE']>;
          isFetchedDynamically?: boolean;
          isFetchingOptionsOnInit?: boolean;
          additionalFieldsValidationType?: FieldTypes;
          validationSingleSelect?: SelectFieldValidation;
          validationMultipleSelect?: SelectMultipleFieldValidation;
        }
      | {
          label?: string;
          onValueChange?: any;
          required?: boolean;
          multiple?: true;
          api: T;
          defaultInitialValue?: DefaultAutocompleteInitialValueMultiple;
          yupValidation?: ValidationProperties<ValidationRegistryEntry['multiple']['AUTOCOMPLETE']>;
          isFetchedDynamically?: boolean;
          isFetchingOptionsOnInit?: boolean;
          additionalFieldsValidationType?: FieldTypes;
          validationSingleSelect?: SelectFieldValidation;
          validationMultipleSelect?: SelectMultipleFieldValidation;
        }
    ) &
      PermissionProps;

export type TypographyFieldProperties = {
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit';
  text?: string;
  api?: ApiProp;
  yupValidation?: Record<string, unknown>;
} & PermissionProps;

export type DatetimeFieldProperties<T extends ApiProp = ApiProp> = {
  label?: string;
  fullWidth?: boolean;
  required?: boolean;
  minDate?: any;
  maxDate?: any;
  format?: string;
  clearable?: boolean;
  withTime?: boolean;
  api?: T;
  defaultInitialValue?: unknown;
  yupValidation?: ValidationProperties<ValidationRegistryEntry['DATETIME']>;
} & PermissionProps;

export type TextFieldProperties<T extends ApiProp = ApiProp> = {
  required?: boolean;
  lines?: number;
  label?: string;
  hidden?: boolean;
  api?: T;
  defaultInitialValue?: unknown;
  onlyPositiveInteger?: boolean;
  maxLength?: number;
  yupValidation?: ValidationProperties<ValidationRegistryEntry['TEXT']>;
} & PermissionProps;
export type NumberFieldProperties<T extends ApiProp = ApiProp> = {
  required?: boolean;
  min?: number;
  max?: number;
  step?: number;
  label?: string;
  api?: T;
  defaultInitialValue?: unknown;
  triggerFieldIdValidationOnChange?: Array<FieldPath<FieldValues>>;
  yupValidation?: ValidationProperties<ValidationRegistryEntry['NUMBER']>;
} & PermissionProps;

export type BooleanFieldProperties<T extends ApiProp = ApiProp> = {
  label?: string;
  api?: T;
  hidden?: boolean;
  defaultInitialValue?: unknown;
  triggerFieldIdValidationOnChange?: Array<FieldPath<FieldValues>>;
  yupValidation?: ValidationProperties<ValidationRegistryEntry['BOOLEAN']>;
} & PermissionProps;
