import { useTranslation } from 'react-i18next';
import { queryCache } from 'react-query';
import { useSnackbar } from '@enigma/fe-ui';
import { TransitDateDetails } from '@ibtm/domain';

import {
  Button,
  TableIconButton,
  TableLayout,
  useConfirmDialog,
  useFormV2Context,
  useViewModesV2
} from '@libs/common/v2';

import { ApplicationQueryKeysEnum } from '../../application';
import { DomainUIElementEnum } from '../../config';
import { useDeleteTransitDatesMutation } from '../api';
import { useTransitDatesDialog, useTransitDatesTable } from '../hooks';
import { TransitDateDetailsWithId } from '../model';

interface IProps {
  applicationData: { id: string; categoryKey: string };
  transitDataId: string;
}

function TransitDatesTable({ transitDataId, applicationData }: IProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { openDetailsDialog } = useTransitDatesDialog();
  const { formMode } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);

  const [confirm] = useConfirmDialog();

  const [deleteTransitDates] = useDeleteTransitDatesMutation();

  const tableProps = useTransitDatesTable(transitDataId);

  const handleDelete = (date: TransitDateDetailsWithId) => {
    confirm({
      title: t('applications:transitSchedule.dialog.deleteDateTitle'),
      message: t('applications:transitSchedule.dialog.deleteDateMessage'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);

        deleteTransitDates(
          {
            transitScheduleId: transitDataId,
            datesIds: [date.id]
          },
          {
            onSuccess: () => {
              queryCache.invalidateQueries([
                ApplicationQueryKeysEnum.TRANSIT_DATES,
                { transitScheduleId: transitDataId }
              ]);
              showSuccessSnackbar(t('applications:transitSchedule.successMessages.dateDeleted'));
              closeDialog();
              setConfirmLoading(false);
            }
          }
        );
      },
      confirmType: 'danger'
    });
  };

  return (
    <TableLayout<TransitDateDetailsWithId, TransitDateDetails>
      {...tableProps}
      isFilterEnabled={false}
      headerActions={
        !viewMode && (
          <Button
            variant="outlined"
            onClick={() => openDetailsDialog({ applicationData, transitDataId })}
            label={t('applications:transitSchedule.actions.addDates')}
            actionKey={DomainUIElementEnum.APPLICATION_TRANSIT_DATE_UPDATE}
          />
        )
      }
      isSection
      pageTitle={t('applications:transitSchedule.blocks.transitDates')}
      rowActions={({ original }) => {
        return (
          !viewMode && (
            <TableIconButton
              actionKey={DomainUIElementEnum.APPLICATION_TRANSIT_DATE_UPDATE}
              tooltipTitle={t('action.delete')}
              icon="TrashIcon"
              onClick={() => handleDelete(original)}
            />
          )
        );
      }}
    />
  );
}

export default TransitDatesTable;
