import React, { useMemo } from 'react';
import { TableCell as MuiTableCell, TableHead as MuiTableHead, TableRow as MuiTableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';

import { Theme } from '@libs/common/v2/theme';
import { hexToRgba, important } from '@libs/common/v2/utils';

import { useTableAdapter, useTableContext } from '../hooks';
import { ISpecialColumnEnum, TableClassNames } from '../model';

import TableActionHeadCell from './TableActionHeadCell';
import TableHeadCell from './TableHeadCell';
import SelectHeadCell from './TableSelectHeadCell';

export interface ITableHeadColumnsProps {
  actionsTableRender?: () => React.ReactNode;
  className?: string;
  rowStyle?: React.CSSProperties;
  isWhiteBackground?: boolean;
  isPadded?: boolean;
  setHeadCells?: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        ref: React.MutableRefObject<HTMLElement>;
      }[]
    >
  >;
  tableContext?: ReturnType<typeof useTableContext>;
  tableAdapter?: ReturnType<typeof useTableAdapter>;
  headerRowRef?: React.Ref<HTMLDivElement>;
}

const TableRow = ({
  children,
  isPadded,
  isWhiteBackground,
  rowStyle,
  headerRowRef
}: {
  children: React.ReactNode;
  isPadded: boolean;
  isWhiteBackground: boolean;
  rowStyle: React.CSSProperties;
  headerRowRef?: React.Ref<HTMLDivElement>;
}) => {
  const { headerGroups, filtersPanelOpen, data } = useTableAdapter();
  const { isFilterEnabled } = useTableContext();
  const hasSelectColumn = headerGroups?.[0]?.headers.some(column => column.id === ISpecialColumnEnum.SELECTION);

  const classes = useStyles({
    isPadded,
    isWhiteBackground,
    hasSelectColumn,
    isFilterEnabled,
    hasData: data?.length ?? 0
  });

  return (
    <MuiTableRow
      ref={headerRowRef}
      component="div"
      classes={{ root: classes.rowRoot }}
      className={clsx(filtersPanelOpen && 'filters-open')}
      style={rowStyle}
    >
      {children}
    </MuiTableRow>
  );
};

function TableHeadColumns({
  className,
  actionsTableRender,
  rowStyle,
  isWhiteBackground,
  isPadded,
  setHeadCells,
  tableContext,
  tableAdapter,
  headerRowRef
}: ITableHeadColumnsProps) {
  const { headerGroups, state, data } = tableAdapter;
  const { actionsColumnWidth, isFilterEnabled } = tableContext;
  const hasSelectColumn = headerGroups?.[0]?.headers.some(column => column.id === ISpecialColumnEnum.SELECTION);
  const hasActionColumn = headerGroups?.[0]?.headers.some(column => column.id === ISpecialColumnEnum.ACTION);

  const columnsToRender = useMemo(
    () => (state?.headerGroups?.length ? state.headerGroups : headerGroups),
    [headerGroups, state.headerGroups]
  );

  const classes = useStyles({
    isPadded,
    isWhiteBackground,
    hasSelectColumn,
    isFilterEnabled,
    hasData: data?.length ?? 0
  });

  const getKeyId = (key?: string) => {
    return key ? `${key}${uuidv4()}` : uuidv4();
  };

  return (
    <MuiTableHead component="div" classes={{ root: clsx(classes.headRoot, className) }}>
      {columnsToRender.map(headerGroup => (
        <TableRow
          headerRowRef={headerRowRef}
          isPadded={isPadded}
          isWhiteBackground={isWhiteBackground}
          key={getKeyId(headerGroup.id)}
          rowStyle={rowStyle}
        >
          {headerGroup.headers.map((column: any, colIndex) => {
            if (column.id === ISpecialColumnEnum.SELECTION) {
              return <SelectHeadCell column={column} isWhiteBackground={isWhiteBackground} key={getKeyId(column.id)} />;
            }
            if (column.id === ISpecialColumnEnum.ACTION) {
              return (
                <TableActionHeadCell
                  column={column}
                  isWhiteBackground={isWhiteBackground}
                  key={getKeyId(column.id)}
                  actionsColumnWidth={actionsColumnWidth}
                  actionsTableRender={actionsTableRender}
                />
              );
            }
            return (
              <TableHeadCell
                column={column}
                key={getKeyId(column.originalId || column.id)}
                isLastColumn={colIndex >= headerGroup.headers.length - 1}
                setHeadCells={setHeadCells}
              />
            );
          })}
          {!hasActionColumn && (
            <MuiTableCell
              className={clsx(classes.withoutActionStyle, isFilterEnabled && TableClassNames.STICKY_RIGHT_COLUMN)}
              component="div"
              variant="head"
              key={getKeyId()}
            />
          )}
        </TableRow>
      ))}
    </MuiTableHead>
  );
}

const useStyles = makeStyles<
  Theme,
  {
    isPadded?: boolean;
    isWhiteBackground?: boolean;
    hasSelectColumn?: boolean;
    isFilterEnabled: boolean;
    hasData: boolean;
  }
>(theme => ({
  headRoot: {
    width: 'auto',
    display: 'flex',
    zIndex: theme.zIndex.drawer - 2,
    backgroundColor: ({ isWhiteBackground }: { isWhiteBackground?: boolean }) =>
      isWhiteBackground ? theme.palette.white : theme.palette.grey[50]
  },
  withoutActionStyle: {
    display: 'flex !important',
    alignItems: 'center',
    ...theme.typography.textXs.medium,
    color: theme.palette.grey[700],
    backgroundColor: ({ isWhiteBackground, isFilterEnabled }) =>
      isFilterEnabled && (isWhiteBackground ? theme.palette.white : theme.palette.grey[50]),
    width: ({ isFilterEnabled }) => isFilterEnabled && 116,
    position: ({ isFilterEnabled }) => (isFilterEnabled && 'sticky') as any,
    top: ({ isFilterEnabled }) => isFilterEnabled && 0,
    right: ({ isFilterEnabled }) => isFilterEnabled && 0,
    padding: ({ isFilterEnabled }) => isFilterEnabled && 0,
    flex: ({ isFilterEnabled }) => isFilterEnabled && 'none'
  },
  rowRoot: {
    padding: ({ isPadded }: { isPadded?: boolean }) => isPadded && important('0 24px'),
    paddingLeft: ({ hasSelectColumn, hasData }) => (!hasSelectColumn || !hasData) && 32,
    boxSizing: 'border-box',
    display: important('inline-flex'),
    flex: '1 0 auto',
    boxShadow: `0px 1px 2px ${hexToRgba(theme.palette.grey[900], 0.05)}`,
    backgroundColor: ({ isWhiteBackground }) => (isWhiteBackground ? theme.palette.white : theme.palette.grey[50])
  }
}));

export default React.memo(TableHeadColumns);
