import { ColumnTypesEnum, ISingleColumn } from '@libs/common/v2';
import { convertDateToDateFormat } from '@libs/common/v2/utils';

import { UserFilterV2 } from '@libs/user';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { LicensesColumnsEnum, LicenseSnapshotClient } from '../model/license.model';

export const DEFAULT_COLUMNS = [
  LicensesColumnsEnum.APPLICATION_NUMBER,
  LicensesColumnsEnum.LICENSE_NUMBER,
  LicensesColumnsEnum.FORM_NUMBER,
  LicensesColumnsEnum.PRINT_DATE,
  LicensesColumnsEnum.VALID_FROM,
  LicensesColumnsEnum.VALID_TO,
  LicensesColumnsEnum.DATE_OF_ISSUE_EXTERNAL,
  LicensesColumnsEnum.SIGNER_NAME,
  LicensesColumnsEnum.STATUS
];
const columnDefaultProperties = { isFilterable: true, isSortable: true };

const LICENSE_COLUMN: ISingleColumn<LicenseSnapshotClient>[] = [
  {
    id: 'applicationNumber',
    type: ColumnTypesEnum.TEXT,
    isSortable: false,
    ...columnDefaultProperties
  },
  {
    id: 'cancellationReason',
    type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
    dictionaryName: DomainDictionaryEnum.LICENSE_CANCELLATION_REASON,
    isFilterable: true,
    ...columnDefaultProperties
  },
  {
    id: 'licenseValidityYearsKey',
    type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
    dictionaryName: DomainDictionaryEnum.LICENSE_VALIDITY_YEARS,
    isFilterable: true,
    ...columnDefaultProperties
  },
  {
    id: 'cancelledBy',
    type: ColumnTypesEnum.TEXT,
    ...columnDefaultProperties
  },
  {
    id: 'cancellationDate',
    type: ColumnTypesEnum.DATE,
    dateFormatFn: convertDateToDateFormat,
    ...columnDefaultProperties
  },
  {
    id: 'subjectName',
    type: ColumnTypesEnum.TEXT,
    ...columnDefaultProperties,
    width: 200
  },
  {
    id: 'subjectAddress',
    type: ColumnTypesEnum.TEXT,
    ...columnDefaultProperties,
    width: 200
  },
  {
    id: 'licenseNumber',
    type: ColumnTypesEnum.TEXT,
    ...columnDefaultProperties
  },
  {
    id: 'formNumber',
    type: ColumnTypesEnum.TEXT,
    ...columnDefaultProperties
  },
  {
    id: 'printDate',
    type: ColumnTypesEnum.DATE,
    dateFormatFn: convertDateToDateFormat,
    ...columnDefaultProperties
  },
  {
    id: 'validFrom',
    type: ColumnTypesEnum.DATE,
    dateFormatFn: convertDateToDateFormat,
    ...columnDefaultProperties
  },
  {
    id: 'validTo',
    type: ColumnTypesEnum.DATE,
    dateFormatFn: convertDateToDateFormat,
    ...columnDefaultProperties
  },
  {
    id: 'dateOfIssueExternal',
    type: ColumnTypesEnum.DATE,
    dateFormatFn: convertDateToDateFormat,
    ...columnDefaultProperties
  },
  {
    id: 'signerName',
    type: ColumnTypesEnum.MULTI_SELECT,
    filter: UserFilterV2,
    ...columnDefaultProperties
  },
  {
    id: 'status',
    type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
    dictionaryName: DomainDictionaryEnum.PERMISSION_STATUS,
    isFilterable: true,
    ...columnDefaultProperties
  }
];

export default LICENSE_COLUMN;
