import { MutationConfig } from 'react-query';
import { AxiosPromise, AxiosResponse } from 'axios';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '..';

const download = (alertId: string) => {
  return API.alert.getAlertDataFiles(alertId, {
    responseType: 'blob',
    ...endpointsConfig.getAlertDataFiles
  }) as AxiosPromise<File>;
};

export default function useDownloadAlertDataFilesMutation(
  config?: MutationConfig<AxiosResponse<File>, unknown, string, unknown>
) {
  return useMutation(download, config);
}
