import { useTranslation } from 'react-i18next';
import { InvoiceSearchFilter, InvoiceSnapshot } from '@ibtm/domain';
import moment from 'moment';

import { SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { convertDateToDateFormat, getCalendarDate, getValue } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';

import DomainDictionaryEnum from '../../config/DomainDictionaryEnum';
import ReleaseDocumentStatusChip from '../components/common/ReleaseDocumentStatusChip';
import ReleaseDocumentIcon from '../form/components/ReleaseDocumentIcon';

export default function useReleaseDocumentsColumns() {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();
  const { createColumns } = useCreateColumns<InvoiceSnapshot>({
    pathToTranslate: 'releaseDocuments:fields'
  });

  const mappedFilterFields: TableFilterMapper<InvoiceSnapshot, InvoiceSearchFilter> = {
    number: (number: string) => ({ numberContains: number }),
    dateOfIssue: dateOfIssue => ({
      ...(dateOfIssue?.dateTo && {
        dateOfIssueLessThanOrEqual: getCalendarDate(moment(dateOfIssue.dateTo).format('YYYY-MM-DD'))
      }),
      ...(dateOfIssue?.dateFrom && {
        dateOfIssueGreaterThanOrEqual: getCalendarDate(moment(dateOfIssue.dateFrom).format('YYYY-MM-DD'))
      })
    }),
    applicationNumber: (applicationNumber: string) => ({ applicationNumberContains: applicationNumber }),
    amount: ({ max, min }: { min: number; max: number }) => ({
      amountLessThanOrEqual: max,
      amountGreaterThanOrEqual: min
    }),
    folderNumber: (folderIdIn: string) => ({ folderNumberContains: folderIdIn }),
    isCorrection: (isCorrection: SelectOption<boolean>) => ({ isCorrection: isCorrection.value }),
    subjectNip: (nip: string) => ({ nipContains: nip }),
    subjectName: (subjectName: string) => ({ subjectNameContains: subjectName }),
    hasCorrectionInvoice: (correctionInvoice: SelectOption<boolean>) => ({
      hasCorrectionInvoice: correctionInvoice.value
    })
  };

  const mappedSortFields: TableSortMapper<InvoiceSnapshot> = {
    subjectNip: 'buyer.nip',
    subjectName: 'buyer.name',
    folderNumber: 'application.folder.number'
  };

  const columns = createColumns([
    {
      accessor: 'number',
      header: 'invoiceNumber',
      type: 'TEXT',
      width: 200,
      customAccessor: row => {
        return (
          <div className="flex" style={{ paddingLeft: row?.isCorrection ? 25 : 0 }}>
            {!row?.isCorrection && <ReleaseDocumentIcon />} {row?.number}
          </div>
        );
      },
      customXlsxAccessor: row => getValue(row?.number)
    },
    {
      accessor: 'dateOfIssue',
      header: 'issuedDate',
      type: 'DATE',
      dateFormatFn: convertDateToDateFormat
    },
    {
      accessor: 'application.number',
      type: 'TEXT',
      width: 200
    },
    {
      accessor: 'amount',
      type: 'NUMBER_RANGE',
      width: 200
    },
    {
      accessor: 'folder.number',
      type: 'TEXT'
    },
    {
      accessor: 'subjectNip',
      header: 'NIP',
      type: 'TEXT'
    },
    {
      accessor: 'subjectName',
      type: 'TEXT'
    },
    {
      accessor: 'hasCorrectionInvoice',
      type: 'BOOLEAN',
      tooltipTitle: ({ hasCorrectionInvoice }) => (hasCorrectionInvoice ? t('action.yes') : t('action.no')),
      header: 'hasCorrection'
    },
    {
      id: 'statusKey',
      type: 'CUSTOM_COLUMN',
      customAccessor: row => <ReleaseDocumentStatusChip row={row} />,
      tooltipTitle: ({ statusKey }) => translate(DomainDictionaryEnum.INVOICE_STATUS, statusKey) || t('emptyMark'),
      customXlsxAccessor: row => translate(DomainDictionaryEnum.INVOICE_STATUS, row?.statusKey, t('emptyMark')),
      isFilterable: false,
      width: 200
    }
  ]);

  return { columns, mappedFilterFields, mappedSortFields };
}
