enum ApplicationChannelKeyEnum {
  /**	Elektronicznie - EPUAP */
  EPUAP = 'ibtm-domain.channel.epuap',
  /**	Elektronicznie - Portal Klienta */
  CLIENT_PORTAL = 'ibtm-domain.channel.client-portal',
  /**	Wewnętrznie */
  INTERNAL = 'ibtm-domain.channel.internal',
  /**	Sala obsługi */
  CUSTOMER_SERVICE_ROOM = 'ibtm-domain.channel.customer-service-room',
  /**	Elektronicznie - Inne */
  OTHER = 'ibtm-domain.channel.other',
  /**	Korespondencyjnie */
  CORRESPONDENCE = 'ibtm-domain.channel.correspondence'
}

export default ApplicationChannelKeyEnum;
