import { ForeignPermissionApplicationRecordDetails, ForeignPermissionLite } from '@ibtm/domain';

import { i18n } from '@libs/common';
import { SelectOption } from '@libs/common/v2';

export const permissionSingleMatrixHeaders = (isLimitColumnHidden?: boolean) => {
  return [
    ...(isLimitColumnHidden
      ? []
      : [
          {
            width: 110,
            name: {
              title: i18n.t('applications:foreignPermissions.fields.limit')
            }
          }
        ]),
    {
      width: 110,
      name: {
        title: i18n.t('applications:foreignPermissions.fields.numberOrderedLicenses')
      }
    },
    {
      width: 'calc(100% - 90px)',
      minWidth: 'auto',
      name: { title: i18n.t('applications:foreignPermissions.fields.note') }
    }
  ];
};

export type ResourceTypeOption = SelectOption<string> & { ekmtCategoryKey: string; nameKey: string; year: number };
export type ConcernPermissionOption = SelectOption<string> & { number: string };

export type ForeignPermissionApplicationRecordFormData = { id?: string } & Omit<
  ForeignPermissionApplicationRecordDetails,
  'resourceType' | 'concernForeignPermission'
> & {
    concernForeignPermission: ConcernPermissionOption;
    resourceType: ResourceTypeOption;
  };

export const parseConcernPermissionAsSelectOption = (data?: ForeignPermissionLite): ConcernPermissionOption =>
  data
    ? {
        id: data.id,
        value: data.id,
        name: data.number,
        number: data.number
      }
    : undefined;
