import { MoneyTransferSnapshot } from '@ibtm/domain';

import { CamelCasePath, useTableLayoutProps } from '@libs/common/v2';

import { useMoneyTransfersQuery } from '../api';

import useMoneyTransfersTableColumns from './useMoneyTransfersTableColumns';

export function useMoneyTransfersSearchTable(
  applicationId,
  visibleColumns: Array<CamelCasePath<MoneyTransferSnapshot>>
) {
  const { columns, mappedFilterFields } = useMoneyTransfersTableColumns(visibleColumns);

  return useTableLayoutProps({
    tableHookQuery: useMoneyTransfersQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'paymentDate', desc: true }]
        }
      }
    },
    tableHookQueryInitialParams: {
      applicationIdNotEquals: applicationId,
      quorumConfirmation: true
    }
  });
}

export default useMoneyTransfersSearchTable;
