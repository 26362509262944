import { createContext, SetStateAction, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Button, ButtonsGroup, Page, PageHeader, useRouter } from '@libs/common/v2';

import { useReleaseDocumentsDetailsQuery, useSuspensionReleaseDetailsQuery } from '../api';
import ReturnSection from '../form/components/ReturnSection';

import { ChangeReturnModeContext } from './ReleaseDocumentsWrapper';

interface IContextHeaderButton {
  isAnyItemAddedAndAllHaveReason: boolean;
  handleReturn: () => void;
  setIsAnyItemAddedAndAllHaveReason: (value: boolean) => void;
  setHandleReturn: SetStateAction<(values: any) => void>;
  isMutationLoading?: boolean;
  setIsMutationLoading?: (value: boolean) => void;
}
export const ContextHeaderButton = createContext<IContextHeaderButton>({
  isAnyItemAddedAndAllHaveReason: false,
  setIsAnyItemAddedAndAllHaveReason: () => null,
  handleReturn: () => null,
  setHandleReturn: () => null,
  isMutationLoading: false,
  setIsMutationLoading: () => null
});

function ContextHeaderProvider({ children }: { children: JSX.Element }) {
  const [isAnyItemAddedAndAllHaveReason, setIsAnyItemAddedAndAllHaveReason] = useState(false);
  const [handleReturn, setHandleReturn] = useState(() => () => {});
  const [isMutationLoading, setIsMutationLoading] = useState(false);
  return (
    <ContextHeaderButton.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isAnyItemAddedAndAllHaveReason,
        setIsAnyItemAddedAndAllHaveReason,
        handleReturn,
        setHandleReturn,
        isMutationLoading,
        setIsMutationLoading
      }}
    >
      {children}
    </ContextHeaderButton.Provider>
  );
}

function ReleaseDocumentsReturnPage() {
  const [t] = useTranslation();
  const { goToPage, routes } = useRouter();
  const { changeMode, isFromCreation } = useContext(ChangeReturnModeContext);
  const { id, folderId, releaseDocumentId } = useParams<{
    id: string;
    folderId: string;
    releaseDocumentId: string;
  }>();

  const { data, isLoading } = useReleaseDocumentsDetailsQuery(id, { enabled: !folderId });
  const { data: suspensionInvoiceData, isLoading: suspensionInvoiceisLoading } = useSuspensionReleaseDetailsQuery(
    { suspensionId: id, invoiceId: releaseDocumentId },
    {
      enabled: !!folderId
    }
  );
  const handleCancel = (redirectToId?: string) => {
    if (!folderId) {
      if (isFromCreation) {
        goToPage(routes.releaseDocumentCreate(redirectToId || id));
      } else {
        goToPage(routes.releaseDocumentView(redirectToId || id));
      }
    } else if (isFromCreation) {
      routes.releaseDocumentOnFolderSuspensionCreate(folderId, id, redirectToId || releaseDocumentId);
    } else {
      routes.releaseDocumentOnFolderSuspensionView(folderId, id, redirectToId || releaseDocumentId);
    }
    changeMode();
  };
  return (
    <div>
      <ContextHeaderProvider>
        <Page
          isLoading={isLoading || suspensionInvoiceisLoading}
          header={
            <PageHeader
              title={`${t('releaseDocuments:return.pageTitle')} ${
                data?.number || suspensionInvoiceData?.number || '-'
              }`}
              rightSideContent={
                <ContextHeaderButton.Consumer>
                  {({ handleReturn, isAnyItemAddedAndAllHaveReason, isMutationLoading }) => (
                    <ButtonsGroup>
                      <Button
                        label={t('releaseDocuments:return.label.cancel')}
                        variant="outlined"
                        onClick={() => handleCancel()}
                      />
                      <Button
                        disabled={!isAnyItemAddedAndAllHaveReason}
                        tooltipTitle={
                          !isAnyItemAddedAndAllHaveReason
                            ? t('releaseDocuments:return.tooltip.allItemsAddedAndHaveReason')
                            : null
                        }
                        isLoading={isMutationLoading}
                        label={t('action.save')}
                        onClick={handleReturn}
                      />
                    </ButtonsGroup>
                  )}
                </ContextHeaderButton.Consumer>
              }
            />
          }
          content={
            <div style={{ width: '100%' }}>
              <ReturnSection
                invoiceId={folderId ? releaseDocumentId : id}
                originalItems={!folderId ? data?.items : suspensionInvoiceData?.items}
                handleCancel={handleCancel}
              />
            </div>
          }
        />
      </ContextHeaderProvider>
    </div>
  );
}

export default ReleaseDocumentsReturnPage;
