import { useTranslation } from 'react-i18next';
import {
  FindPullNotificationsExtendedCommand,
  FindPullNotificationsExtendedCommandStatuses,
  PullNotificationSnapshot
} from '@avispon/message';

import { SelectOption, TableFilter, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { convertDateToDateTimeFormat, DateRangeISO, getValue } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';
import { MessageStatusChip } from '@libs/notification/components';
import { MessagesDictionaryEntryNameEnum } from '@libs/notification/models';
import { UserFilterV2 } from '@libs/user';

function useAdminMessagesColumns() {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  const { createColumns } = useCreateColumns<PullNotificationSnapshot>({
    pathToTranslate: 'messages:field'
  });

  const columns = createColumns([
    {
      id: 'created',
      type: 'DATE',
      customAccessor: row => getValue(convertDateToDateTimeFormat(row?.created))
    },
    {
      id: 'displayed',
      type: 'DATE',
      customAccessor: row => getValue(convertDateToDateTimeFormat(row?.displayed))
    },
    {
      accessor: 'subject',
      type: 'TEXT',
      isSortable: false,
      isFilterable: false
    },
    {
      id: 'accountData',
      header: 'recipient',
      type: 'CUSTOM_COLUMN',
      customAccessor: row => getValue(row?.accountData?.name),
      filter: UserFilterV2,
      width: 250
    },
    {
      id: 'status',
      header: 'status',
      type: 'CUSTOM_COLUMN',
      tooltipTitle: ({ status }) =>
        translate(MessagesDictionaryEntryNameEnum.NOTIFICATION_STATUS, status, t('emptyMark')),
      customAccessor: ({ status }) => <MessageStatusChip status={status} />,
      filter: props => {
        return (
          <TableFilter.DictionaryMultiSelect
            {...props}
            placehholder={t('action.select')}
            dictionary={MessagesDictionaryEntryNameEnum.NOTIFICATION_STATUS}
          />
        );
      },
      customXlsxAccessor: ({ status }) =>
        translate(MessagesDictionaryEntryNameEnum.NOTIFICATION_STATUS, status, t('emptyMark')),
      width: 200
    }
  ]);

  const mappedFilterFields: TableFilterMapper<PullNotificationSnapshot, FindPullNotificationsExtendedCommand> = {
    created: ({ dateFrom, dateTo }: DateRangeISO) => ({
      createdFrom: dateFrom,
      createdTo: dateTo
    }),
    displayed: ({ dateFrom, dateTo }: DateRangeISO) => ({
      displayedFrom: dateFrom,
      displayedTo: dateTo
    }),
    accountData: (values: SelectOption<string>[]) => ({ accountIds: values.map(({ id }) => id) }),
    status: (statuses: SelectOption<FindPullNotificationsExtendedCommandStatuses>[]) => ({
      statuses: statuses.map(({ value }) => value)
    })
  };

  const mappedSortFields: TableSortMapper<PullNotificationSnapshot> = {
    created: 'createdAt',
    accountData: 'accountData',
    displayed: 'readAt'
  };

  return { columns, mappedFilterFields, mappedSortFields };
}

export default useAdminMessagesColumns;
