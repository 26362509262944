import { QueryConfig, usePaginatedQuery } from 'react-query';
import { LicenseSearchFilter as LicenseSearchFilterClient } from '@ibtm/client-domain';
import { LicenseSearchFilter, LicenseSnapshot } from '@ibtm/domain';
import _ from 'lodash';

import { IPaginatedModel } from '@libs/common/v2';

import { DictionaryEntryValue, getDictionaryValues, useDictionary } from '@libs/dictionary';
import { useElementVisibility } from '@libs/permission';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { DomainDictionaryEntry, DomainDictionaryEnum, DomainUIElementEnum } from '../../../config';
import { LicenseSnapshotClient } from '../../model/license.model';
import { parseLicensesList } from '../../parsers/common';
import { endpointsConfig } from '../endpoints-config';

import LicensesQueryKeysEnum from './LicensesQueryKeysEnum';

export const parseGetLicensesRequest = (
  params: LicenseSearchFilter | LicenseSearchFilterClient,
  areInactivePermissionsVisible: boolean,
  areActivePermissionsVisible: boolean,
  statuses: Array<DictionaryEntryValue>
) => {
  const { statusKeyIn } = params;
  const filteredStatuses = _.isEmpty(statusKeyIn)
    ? statuses
        .filter(dictionaryEntry => {
          const dictionaryEntryValue = dictionaryEntry.value;
          if (
            dictionaryEntryValue === DomainDictionaryEntry.PERMISSION_STATUS.INACTIVE &&
            !areInactivePermissionsVisible
          ) {
            return false;
          }
          if (dictionaryEntryValue === DomainDictionaryEntry.PERMISSION_STATUS.ACTIVE && !areActivePermissionsVisible) {
            return false;
          }
          return true;
        })
        .map(({ value }) => value)
    : statusKeyIn;

  return { ...params, statusKeyIn: filteredStatuses };
};

export const getLicenses = async (params: LicenseSearchFilter) => {
  const { data } = await API.license.getLicensesSnapshotPage(params, {
    ...endpointsConfig.getLicensesSnapshotPage
  });

  return {
    ...data,
    content: parseLicensesList(data.content)
  };
};
export const getLicensesClient = async (params: LicenseSearchFilterClient) => {
  const { data } = await API.client.license.getLicensesSnapshotPage('', '', params, {
    ...endpointsConfig.getLicensesSnapshotPage
  });

  return {
    ...data,
    content: parseLicensesList(data.content as Array<LicenseSnapshot>)
  };
};

export default function useLicensesQuery(
  params: LicenseSearchFilter | LicenseSearchFilterClient,
  queryConfig: QueryConfig<IPaginatedModel<LicenseSnapshotClient>, unknown> = {}
) {
  const { checkIsElementVisible } = useElementVisibility();

  const areInactivePermissionsVisible = checkIsElementVisible(DomainUIElementEnum.FOLDER_PERMISSION_LIST_UNACTIVE);
  const areActivePermissionsVisible = checkIsElementVisible(DomainUIElementEnum.FOLDER_PERMISSION_LIST_ACTIVE);

  const permissionStatuses = getDictionaryValues(useDictionary(DomainDictionaryEnum.PERMISSION_STATUS));

  const modifiedParams = parseGetLicensesRequest(
    params,
    areInactivePermissionsVisible,
    areActivePermissionsVisible,
    permissionStatuses
  );

  const { getQuery } = useGetApiQueryByPerspective();
  return usePaginatedQuery<IPaginatedModel<LicenseSnapshotClient>>(
    [LicensesQueryKeysEnum.LICENSES_LIST, modifiedParams],
    getQuery(
      () => getLicensesClient(modifiedParams),
      () => getLicenses(modifiedParams)
    ),
    queryConfig
  );
}
