import { QueryConfig, useQuery } from 'react-query';
import { UserTaskDetails } from '@ibtm/process-api';
import { AxiosRequestConfig } from 'axios';

import { endpointsConfig } from '../endpoints-config';
import { API } from '..';

import { ProcessQueryKeysEnum } from './ProcessQueryKeysEnum';

const getUserTaskDetails = async (_, taskId: string) => {
  const { data } = await API.task.getUserTaskDetails({ taskId }, {
    ...endpointsConfig.getUserTaskDetails
  } as AxiosRequestConfig);
  return data;
};

export default function useUserTaskDetailsQuery(taskId: string, queryConfig: QueryConfig<any, unknown> = {}) {
  return useQuery<UserTaskDetails>([ProcessQueryKeysEnum.TASK_DETAILS, taskId], getUserTaskDetails, queryConfig);
}
