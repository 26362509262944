import { useTranslation } from 'react-i18next';
import { DepotTransferSearchFilter } from '@ibtm/domain';

import { SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { TAmountRange } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { IDepotTransferClient } from '../../model';

const useDepotTransfersColumns = () => {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  const { createColumns } = useCreateColumns<IDepotTransferClient>({
    pathToTranslate: 'resource:fields'
  });

  const mappedSortFields: TableSortMapper<IDepotTransferClient> = {
    sourceDepot: 'sourceDepot.name',
    targetDepot: 'targetDepot.name',
    formName: 'resourceType.nameKey'
  };

  const mappedFilterFields: TableFilterMapper<IDepotTransferClient, DepotTransferSearchFilter> = {
    sourceDepot: (sourceDepot: SelectOption<string>) => ({ sourceDepotTypeKey: sourceDepot?.value }),
    targetDepot: (targetDepot: SelectOption<string>) => ({ targetDepotTypeKey: targetDepot?.value }),
    formName: (names: SelectOption<string>[]) => ({ nameKeyIn: names.map(name => name.value) }),
    numberFrom: (amountRange: TAmountRange) => ({
      numberFromGreaterThanOrEqual: amountRange.min,
      numberFromLessThanOrEqual: amountRange.max
    }),
    numberTo: (amountRange: TAmountRange) => ({
      numberToGreaterThanOrEqual: amountRange.min,
      numberToLessThanOrEqual: amountRange.max
    }),
    amount: (amountRange: TAmountRange) => ({
      amountToGreaterThanOrEqual: amountRange.min,
      amountToLessThanOrEqual: amountRange.max
    })
  };

  const columns = createColumns([
    {
      id: 'sourceDepot',
      type: 'DICTIONARY_SINGLE_SELECT',
      dictionaryName: DomainDictionaryEnum.DEPOT_TYPE,
      customAccessor: row => {
        const name = row.sourceDepot?.name ? ` ${row.sourceDepot?.name}` : '';
        return `${translate(DomainDictionaryEnum.DEPOT_TYPE, row.sourceDepot?.type, t('emptyMark'))}${name}`;
      },
      width: 250
    },
    {
      id: 'targetDepot',
      type: 'DICTIONARY_SINGLE_SELECT',
      dictionaryName: DomainDictionaryEnum.DEPOT_TYPE,
      customAccessor: row => {
        const name = row.targetDepot?.name ? ` ${row.targetDepot?.name}` : '';
        return `${translate(DomainDictionaryEnum.DEPOT_TYPE, row.targetDepot?.type, t('emptyMark'))}${name}`;
      },
      width: 250
    },
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_NAME,
      accessor: 'formName',
      width: 300
    },
    {
      type: 'NUMBER_RANGE',
      accessor: 'numberFrom',
      width: 200
    },
    {
      type: 'NUMBER_RANGE',
      accessor: 'numberTo',
      width: 200
    },
    {
      type: 'NUMBER_RANGE',
      accessor: 'amount',
      width: 200
    }
  ]);

  return { columns, mappedFilterFields, mappedSortFields };
};

export default useDepotTransfersColumns;
