import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { isArray } from 'lodash';

import { Button, useFormV2Context } from '@libs/common/v2';

import { ReportTypeImportFile } from '@libs/report';
import { useImportReportTypeMutation } from '@libs/report/api';

interface IProps {
  handleClose: () => void;
  refetch: () => void;
  setReportTypesFiles: (values: any[]) => void;
}

function UploadReportTypeDialogActions({ handleClose, refetch, setReportTypesFiles }: IProps) {
  const [t] = useTranslation();
  const { handleSubmit } = useFormV2Context();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutateAsync: importReportTypeMutation, isLoading: isImportReportLoading } = useImportReportTypeMutation();
  const [waitingFiles, setWaitingFiles] = useState<null | number>(null);

  const handleSuccess = () => {
    showSuccessSnackbar(t('reports:reportTypeImport.importReportTypeSuccessMessage'));
    refetch();
    handleClose();
  };

  const handleSubmitForm = useCallback((formData: ReportTypeImportFile) => {
    if (isArray(formData?.file)) {
      const reportTypesFiles = [];
      setWaitingFiles(formData?.file.length);
      formData?.file.forEach(async file => {
        await importReportTypeMutation(
          { file },
          {
            onSuccess: () => {
              setWaitingFiles(prevState => prevState - 1);
              reportTypesFiles.push({ name: file.name, fileId: file.fileId });
            },
            onError: (error: any) => {
              const errorDetails = error?.response?.data;
              reportTypesFiles.push({
                name: file.name,
                error: errorDetails?.message || errorDetails?.codes?.join(', '),
                fileId: file.fileId
              });
            }
          }
        ).then(() => {
          setReportTypesFiles(reportTypesFiles);
        });
      });
    } else {
      importReportTypeMutation(formData, {
        onSuccess: handleSuccess
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (waitingFiles === 0) {
      handleSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingFiles]);

  return (
    <>
      <Button variant="outlined" label={t('action.cancel')} onClick={handleClose} isNoMargin />
      <Button
        label={t('action.save')}
        onClick={handleSubmit(data => handleSubmitForm(data as ReportTypeImportFile))}
        isLoading={isImportReportLoading}
        isNoMargin
        isPrimary
      />
    </>
  );
}

export default UploadReportTypeDialogActions;
