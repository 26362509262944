import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProceedingFormSuspendRequest } from '@ibtm/domain';
import moment from 'moment';

import { partialTranslate } from '@libs/common';
import {
  ALTERNATIVE_DATE_FORMAT,
  DatepickerField,
  Dialog,
  FormV2Context,
  GridLayout,
  typedNameV2
} from '@libs/common/v2';
import { getCalendarDate } from '@libs/common/v2/utils';

import { useSuspendFormOperationsMutation } from '@libs/domain/proceeding';

import { useFormOperationValidations } from '../hooks';

interface IProps {
  closeDialog: () => void;
  proceedingId: string;
  proceedingFormIds: Array<string>;
  proceedingVersion: number;
  onSuccess: () => void;
}

function SuspendFormOperationsDialog({
  closeDialog,
  proceedingId,
  proceedingFormIds,
  proceedingVersion,
  onSuccess
}: IProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: suspendFormOperations, isLoading } = useSuspendFormOperationsMutation();
  const getLabel = partialTranslate('proceeding:administrative.tab.formOperations.field');
  const { suspendFormOperationValidationSchema } = useFormOperationValidations();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    reset,
    getValues,
    trigger,
    unregister
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(suspendFormOperationValidationSchema)
  });

  const onSubmit = useCallback(
    values => {
      suspendFormOperations(
        {
          proceedingId,
          request: {
            suspendedFrom: getCalendarDate(moment(values.suspendedFrom).format(ALTERNATIVE_DATE_FORMAT)),
            suspendedTo: getCalendarDate(moment(values.suspendedTo).format(ALTERNATIVE_DATE_FORMAT)),
            proceedingFormIds,
            version: proceedingVersion
          }
        },
        {
          onSuccess: () => {
            showSuccessSnackbar(
              proceedingFormIds.length > 1
                ? t('proceeding:action.suspendFormOperation.successMessagePlural')
                : t('proceeding:action.suspendFormOperation.successMessageSingular')
            );
            closeDialog();
            onSuccess();
          }
        }
      );
    },
    [
      closeDialog,
      showSuccessSnackbar,
      t,
      proceedingFormIds,
      proceedingId,
      proceedingVersion,
      suspendFormOperations,
      onSuccess
    ]
  );

  const formDataProvider = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      reset,
      handleSubmit
    }),
    [control, errors, getValues, handleSubmit, isSubmitting, register, reset, setValue, trigger, unregister, watch]
  );

  return (
    <FormV2Context.Provider value={formDataProvider}>
      <Dialog
        title={t('proceeding:action.suspendFormOperation.title')}
        confirmText={t('action.confirm')}
        cancelText={t('action.cancel')}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={closeDialog}
        dialogSize="medium"
        isConfirmLoading={isLoading}
        isOpen
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <GridLayout itemProps={{ xs: 12 }}>
            <DatepickerField
              name={`${typedNameV2<ProceedingFormSuspendRequest>('suspendedFrom')}` as const}
              label={getLabel('suspensionFrom')}
              isRequired
            />
            <DatepickerField
              name={`${typedNameV2<ProceedingFormSuspendRequest>('suspendedTo')}` as const}
              label={getLabel('suspensionTo')}
              isRequired
            />
          </GridLayout>
        </form>
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default SuspendFormOperationsDialog;
