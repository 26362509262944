import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleSnapshot, VehiclesSearchFilter } from '@ibtm/domain';

import { CamelCasePath, SelectOption, TableFilter, TableFilterMapper, useCreateColumns } from '@libs/common/v2';
import {
  convertCalendarDate,
  convertDateToDateFormat,
  DateRangeISO,
  getCalendarDate,
  getValue
} from '@libs/common/v2/utils';

import { useDictionaryEntryValues } from '@libs/dictionary';
import { UserFilterV2 } from '@libs/user';

import { DomainDictionaryEntry, DomainDictionaryEnum, DomainQuickchangeableDictionaryEnum } from '@libs/domain/config';

function useVehicleColumns(
  visibleColumns: Array<CamelCasePath<VehicleSnapshot>>,
  translateEuroClass?: (year: number, euroClassKey: string) => string
) {
  const [t] = useTranslation();
  const vehicleTypeDictionaryEntryValues = useDictionaryEntryValues(DomainDictionaryEnum.VEHICLE_TYPE);
  const { createColumns } = useCreateColumns<VehicleSnapshot>({
    pathToTranslate: 'vehicle:field'
  });

  const vehicleTypeDictionaryOptions = useMemo(() => {
    const excludedVehicleTypes = [
      DomainDictionaryEntry.VEHICLE_TYPE.TRUCK_TRAILER,
      DomainDictionaryEntry.VEHICLE_TYPE.TRUCK_SEMITRAILER
    ];
    return vehicleTypeDictionaryEntryValues.filter(({ value }) => !excludedVehicleTypes.includes(value));
  }, [vehicleTypeDictionaryEntryValues]);

  const mappedFilterFields: TableFilterMapper<VehicleSnapshot, VehiclesSearchFilter> = {
    vin: (vinContains: string) => ({ vinContains }),
    plateNumber: (registrationNumber: string) => ({ plateNumberContains: registrationNumber }),
    brandKey: (brandKeys: SelectOption<string>) => ({ brandKeyIn: [brandKeys.value] }),
    manufactureYear: (value: number) => ({
      manufactureYearLessThanOrEqual: value,
      manufactureYearGreaterThanOrEqual: value
    }),
    typeKey: (typeKeys: SelectOption<string>[]) => ({ typeKeyIn: typeKeys.map(({ value }) => value) }),
    acceptableCapacity: (value: { max: number; min: number }) => ({
      acceptableCapacityLessThanOrEqual: value.max,
      acceptableCapacityGreaterThanOrEqual: value.min
    }),
    dmc: (value: { max: number; min: number }) => ({
      dmcLessThanOrEqual: value.max,
      dmcGreaterThanOrEqual: value.min
    }),
    euroClassKey: (euroClassKeys: SelectOption<string>[]) => ({
      euroClassKeyIn: euroClassKeys?.map?.(({ value }) => value)
    }),
    belowThreeAndHalfTons: (options: SelectOption<boolean>) => ({ belowTreeAndHalfTons: options.value }),
    vehicleSubmittedTo: (vehicleSubmittedTo: SelectOption<string>[]) => ({
      vehicleSubmittedToIn: vehicleSubmittedTo.map(({ value }) => value)
    }),
    statusKey: (statusKeys: SelectOption<string>[]) => ({ statusKeyIn: statusKeys.map(({ value }) => value) }),
    certificateSExpirationDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      certificatesExpirationDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      certificatesExpirationDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    modified: ({ dateFrom, dateTo }: DateRangeISO) => ({
      modifiedGreaterThanOrEqual: dateFrom,
      modifiedLessThanOrEqual: dateTo
    }),
    expirationDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      rightOfDispositionDateLessThanOrEqual: getCalendarDate(dateTo),
      rightOfDispositionDateGreaterThanOrEqual: getCalendarDate(dateFrom)
    }),
    modifierName: (modifier: SelectOption<string>[]) => ({ modifierIdIn: modifier.map(({ value }) => value) }),
    registrationCountry: (registrationCountries: SelectOption<string>[]) => ({
      registrationCountryIn: registrationCountries.map(({ value }) => value)
    }),
    isInCompany: ({ value }: SelectOption<boolean>) => ({ isInCompany: value })
  };

  const columns = createColumns(
    [
      { type: 'TEXT', accessor: 'folderNumber', isFilterable: false },
      {
        type: 'TEXT',
        accessor: 'vin',
        width: 200
      },
      {
        type: 'TEXT',
        header: 'registrationNumber',
        accessor: 'plateNumber'
      },
      {
        id: 'brandKey',
        type: 'QUICK_DICTIONARY_SINGLE_SELECT',
        quickDictionaryName: DomainQuickchangeableDictionaryEnum.VEHICLE_BRAND,
        accessor: 'brandKeyTranslation'
      },
      {
        id: 'manufactureYear',
        type: 'YEAR_DATEPICKER',
        customAccessor: row => getValue(row.manufactureYear)
      },
      {
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.VEHICLE_TYPE,
        accessor: 'typeKey',
        filter: props => (
          <TableFilter.DictionaryMultiSelect
            {...props}
            dictionary={DomainDictionaryEnum.VEHICLE_TYPE}
            options={vehicleTypeDictionaryOptions}
          />
        )
      },
      {
        type: 'NUMBER_RANGE',
        accessor: 'acceptableCapacity',
        customAccessor: row => getValue(row.acceptableCapacity),
        width: 210
      },
      {
        type: 'NUMBER_RANGE',
        accessor: 'dmc',
        customAccessor: row => getValue(row.dmc),
        width: 200
      },
      {
        id: 'euroClassKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.EURO_CLASS,
        customAccessor: ({ manufactureYear, euroClassKey }) => translateEuroClass?.(manufactureYear, euroClassKey)
      },
      {
        type: 'BOOLEAN',
        accessor: 'belowThreeAndHalfTons'
      },
      {
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.VEHICLE_SUBMITTED_TO,
        accessor: 'vehicleSubmittedTo'
      },
      {
        id: 'certificateSExpirationDate',
        type: 'DATE',
        width: 255,
        customAccessor: ({ certificateSExpirationDate }) =>
          certificateSExpirationDate
            ? convertDateToDateFormat(convertCalendarDate(certificateSExpirationDate))
            : t('lack').toLowerCase()
      },
      {
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.VEHICLE_STATUS,
        accessor: 'statusKey',
        isSortable: false
      },
      {
        type: 'DATE',
        accessor: 'expirationDate',
        isSortable: false
      },
      {
        type: 'DATE',
        accessor: 'modified',
        isSortable: false
      },
      {
        header: 'modifier',
        type: 'TEXT',
        accessor: 'modifier.name',
        filter: UserFilterV2,
        isSortable: false
      },
      {
        accessor: 'registrationCountry',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.COUNTRY_CODE
      },
      {
        accessor: 'isInCompany',
        type: 'BOOLEAN'
      },
      {
        id: 'modelKey',
        customAccessor: row => getValue(row.modelKeyTranslation),
        header: 'modelKey',
        type: 'QUICK_DICTIONARY_SINGLE_SELECT',
        quickDictionaryName: DomainQuickchangeableDictionaryEnum.VEHICLE_MODEL,
        isSortable: false,
        isFilterable: false
      },
      {
        accessor: 'crewSeats',
        type: 'NUMBER',
        isSortable: false,
        isFilterable: false,
        customAccessor: row => getValue(row.crewSeats)
      }
    ],
    visibleColumns
  );

  return { columns, mappedFilterFields };
}

export default useVehicleColumns;
