import { useTranslation } from 'react-i18next';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  DictionarySelectField,
  GridLayout,
  Section,
  SwitchField,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';
import { convertDateToDateFormat } from '@libs/common/v2/utils';

import { DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';

import { IProceedingDetailsClient } from '../../../models';
import { isFieldVisible } from '../../../utils';

interface IProps {
  hiddenFields?: Array<
    | 'secondTierBpDeliveryDate'
    | 'secondTierBpCaseNumber'
    | 'secondTierBpDecisionNumber'
    | 'secondTierBpDecisionDate'
    | 'secondTierBpDecision'
    | 'secondTierBpComplaintDeliveryDate'
    | 'secondTierBpExecutionSuspensionDecisionDate'
    | 'secondTierWsaSignature'
    | 'secondTierWsaDecisionDate'
    | 'secondTierWsaDecision'
    | 'secondTierWsaDecisionFinalDate'
    | 'secondTierNsaExecutionSuspensionDecisionDate'
    | 'secondTierCassation'
    | 'secondTierNsaDecisionDate'
    | 'secondTierNsaDecision'
  >;
}

function SecondTierSection({ hiddenFields = [] }: IProps) {
  const [t] = useTranslation();
  const getTranslation = partialTranslate('roadTransport:fields');

  return (
    <Section
      title={t('roadTransport:sections.secondInstance')}
      actionKey={DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_SECOND_TIER_SECTION}
      isCollapsable
    >
      <GridLayout itemProps={{ xs: 6 }}>
        {isFieldVisible('secondTierBpDeliveryDate', hiddenFields) && (
          <DatepickerField
            name={typedNameV2<IProceedingDetailsClient>('secondTierBpDeliveryDate')}
            label={getTranslation('secondTierBpDeliveryDate')}
            viewModeDateParser={convertDateToDateFormat}
          />
        )}

        {isFieldVisible('secondTierBpCaseNumber', hiddenFields) && (
          <TextInputField
            name={typedNameV2<IProceedingDetailsClient>('secondTierBpCaseNumber')}
            label={getTranslation('secondTierBpCaseNumber')}
            inputProps={{ maxLength: 20 }}
          />
        )}

        {isFieldVisible('secondTierBpDecisionNumber', hiddenFields) && (
          <TextInputField
            name={typedNameV2<IProceedingDetailsClient>('secondTierBpDecisionNumber')}
            label={getTranslation('secondTierBpDecisionNumber')}
          />
        )}

        {isFieldVisible('secondTierBpDecisionDate', hiddenFields) && (
          <DatepickerField
            name={typedNameV2<IProceedingDetailsClient>('secondTierBpDecisionDate')}
            label={getTranslation('secondTierBpDecisionDate')}
            viewModeDateParser={convertDateToDateFormat}
          />
        )}

        {isFieldVisible('secondTierBpDecision', hiddenFields) && (
          <DictionarySelectField
            name={typedNameV2<IProceedingDetailsClient>('secondTierBpDecision')}
            label={getTranslation('secondTierBpDecision')}
            dictionaryName={DomainDictionaryEnum.SECOND_TIER_DECISION}
            stringValue
          />
        )}

        {isFieldVisible('secondTierBpComplaintDeliveryDate', hiddenFields) && (
          <DatepickerField
            name={typedNameV2<IProceedingDetailsClient>('secondTierBpComplaintDeliveryDate')}
            label={getTranslation('secondTierBpComplaintDeliveryDate')}
            viewModeDateParser={convertDateToDateFormat}
          />
        )}

        {isFieldVisible('secondTierBpExecutionSuspensionDecisionDate', hiddenFields) && (
          <DatepickerField
            name={typedNameV2<IProceedingDetailsClient>('secondTierBpExecutionSuspensionDecisionDate')}
            label={getTranslation('secondTierBpExecutionSuspensionDecisionDate')}
            viewModeDateParser={convertDateToDateFormat}
          />
        )}

        {isFieldVisible('secondTierWsaSignature', hiddenFields) && (
          <TextInputField
            name={typedNameV2<IProceedingDetailsClient>('secondTierWsaSignature')}
            label={getTranslation('secondTierWsaSignature')}
            inputProps={{ maxLength: 20 }}
          />
        )}

        {isFieldVisible('secondTierWsaDecisionDate', hiddenFields) && (
          <DatepickerField
            name={typedNameV2<IProceedingDetailsClient>('secondTierWsaDecisionDate')}
            label={getTranslation('secondTierWsaDecisionDate')}
            viewModeDateParser={convertDateToDateFormat}
          />
        )}

        {isFieldVisible('secondTierWsaDecision', hiddenFields) && (
          <DictionarySelectField
            name={typedNameV2<IProceedingDetailsClient>('secondTierWsaDecision')}
            label={getTranslation('secondTierWsaDecision')}
            dictionaryName={DomainDictionaryEnum.WSA_DECISION}
            stringValue
          />
        )}

        {isFieldVisible('secondTierWsaDecisionFinalDate', hiddenFields) && (
          <DatepickerField
            name={typedNameV2<IProceedingDetailsClient>('secondTierWsaDecisionFinalDate')}
            label={getTranslation('secondTierWsaDecisionFinalDate')}
            viewModeDateParser={convertDateToDateFormat}
          />
        )}

        {isFieldVisible('secondTierNsaExecutionSuspensionDecisionDate', hiddenFields) && (
          <DatepickerField
            name={typedNameV2<IProceedingDetailsClient>('secondTierNsaExecutionSuspensionDecisionDate')}
            label={getTranslation('secondTierNsaExecutionSuspensionDecisionDate')}
            viewModeDateParser={convertDateToDateFormat}
          />
        )}

        {isFieldVisible('secondTierCassation', hiddenFields) && (
          <SwitchField
            name={typedNameV2<IProceedingDetailsClient>('secondTierCassation')}
            label={getTranslation('secondTierCassation')}
          />
        )}

        {isFieldVisible('secondTierNsaDecisionDate', hiddenFields) && (
          <DatepickerField
            name={typedNameV2<IProceedingDetailsClient>('secondTierNsaDecisionDate')}
            label={getTranslation('secondTierNsaDecisionDate')}
            viewModeDateParser={convertDateToDateFormat}
          />
        )}

        {isFieldVisible('secondTierNsaDecision', hiddenFields) && (
          <DictionarySelectField
            name={typedNameV2<IProceedingDetailsClient>('secondTierNsaDecision')}
            label={getTranslation('secondTierNsaDecision')}
            dictionaryName={DomainDictionaryEnum.NSA_DECISION}
            stringValue
          />
        )}
      </GridLayout>
    </Section>
  );
}

export default SecondTierSection;
