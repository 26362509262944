export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  getAlert: { translationKey: 'alerts:api.getAlert' },
  getAlertTransitionsHistory: { translationKey: 'alerts:api.getAlertTransitionsHistory' },
  getAlerts: { translationKey: 'alerts:api.getAlerts' },
  create: { translationKey: 'alerts:api.create' },

  getTranslationDetails: { translationKey: 'dictionary:api.getTranslationDetails' },

  getReport: { translationKey: 'reports:api.getReport' },
  getReports: { translationKey: 'reports:api.getReports' },
  getFieldTypes: { translationKey: 'reports:api.getFieldTypes' },
  getParameterValues: { translationKey: 'reports:api.getParameterValues' },
  updateParameterValues: { translationKey: 'reports:api.updateParameterValues' },
  getReportType: { translationKey: 'reports:api.getReportType' },
  getOperators: { translationKey: 'reports:api.getOperators' },
  getReportSource: { translationKey: 'reports:api.getReportSource' },
  getReportSources: { translationKey: 'reports:api.getReportSources' },

  deleteReportType: { translationKey: 'reports:api.deleteReportType' },
  deleteReport: { translationKey: 'reports:api.deleteReport' },
  importById: { translationKey: 'reports:api.importById' },
  _export: { translationKey: 'reports:api._export' },
  exportMany: { translationKey: 'reports:api.exportMany' },
  updateReportType: { translationKey: 'reports:api.updateReportType' },
  edit: { translationKey: 'reports:api.edit' },
  delete: { translationKey: 'reports:api.delete' },
  createReportType: { translationKey: 'reports:api.createReportType' },
  getReportPreview: { translationKey: 'reports:api.getReportPreview' },
  createReport: { translationKey: 'reports:api.createReport' }
};
