import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { TransitStopDetails } from '@ibtm/domain';

import {
  Button,
  ButtonsGroup,
  Dialog,
  FormMode,
  FormV2Context,
  GridLayout,
  SelectOption,
  useIsSmallScreen,
  useValidationBuilder
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { useViewModesV2 } from '@libs/common/v2/form';

import { useElementVisibility } from '@libs/permission';

import { ApplicationQueryKeysEnum } from '../../../application';
import { DomainUIElementEnum } from '../../../config';
import { useCreateTransitScheduleStopMutation, useEditTransitScheduleStopMutation } from '../../api';
import { useStopValidationSchema } from '../../hooks/useStopValidationSchema';
import { ScheduleApplicationGroupsEnum, StopFormData, StopSectionFields } from '../../model';
import { parseStopRequest } from '../../utils';

import { DeparturesSection, StopSection } from './components';

interface IProps {
  formMode: FormMode;
  transitScheduleId: string;
  closeDialog?: () => void;
  initialData?: Partial<TransitStopDetails>;
  applicationId: string;
  applicationTypeKey: ScheduleApplicationGroupsEnum;
}

function StopDetails({
  transitScheduleId,
  formMode,
  closeDialog,
  initialData,
  applicationId,
  applicationTypeKey
}: IProps) {
  const { isSmallScreen } = useIsSmallScreen();
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { createMode, viewMode } = useViewModesV2(formMode);
  const { showSuccessSnackbar } = useSnackbar();
  const { checkIsElementVisible } = useElementVisibility();

  const isApplicationGroupSpoWzo = applicationTypeKey.includes(ScheduleApplicationGroupsEnum.SPO_WZO);
  const isApplicationGroupSpoWzw = applicationTypeKey.includes(ScheduleApplicationGroupsEnum.SPO_WZW);

  const hiddenFields = useMemo(() => {
    if (applicationTypeKey.includes(ScheduleApplicationGroupsEnum.SPO_OUTSIDE_UE)) {
      return [
        StopSectionFields.DIRECTION_KEY,
        StopSectionFields.BRANCH_ROUTE,
        StopSectionFields.KILOMETERS,
        StopSectionFields.DEPARTURES
      ];
    }
    if (applicationTypeKey.includes(ScheduleApplicationGroupsEnum.SPO_WZO)) {
      return [
        StopSectionFields.DIRECTION_KEY,
        StopSectionFields.BRANCH_ROUTE,
        StopSectionFields.NAME,
        StopSectionFields.AGREEMENT_TYPE_KEY,
        StopSectionFields.KILOMETERS,
        StopSectionFields.DEPARTURES
      ];
    }
    if (applicationTypeKey.includes(ScheduleApplicationGroupsEnum.SPO_WZW)) {
      return [
        StopSectionFields.DIRECTION_KEY,
        StopSectionFields.BRANCH_ROUTE,
        StopSectionFields.NAME,
        StopSectionFields.AGREEMENT_TYPE_KEY,
        StopSectionFields.KILOMETERS,
        StopSectionFields.DEPARTURES
      ];
    }
    if (applicationTypeKey.includes(ScheduleApplicationGroupsEnum.SPO_TRANSIT_RP)) {
      return [
        StopSectionFields.DIRECTION_KEY,
        StopSectionFields.BRANCH_ROUTE,
        StopSectionFields.AGREEMENT_TYPE_KEY,
        StopSectionFields.KILOMETERS,
        StopSectionFields.DEPARTURES
      ];
    }
    return [];
  }, [applicationTypeKey]);

  const { mutate: createTransitStop, isLoading: isCreateLoading } = useCreateTransitScheduleStopMutation();
  const { mutate: editTransitScheduleStopHour, isLoading: isEditLoading } = useEditTransitScheduleStopMutation();
  const extendedSchema = useStopValidationSchema(hiddenFields, isApplicationGroupSpoWzo, isApplicationGroupSpoWzw);
  const { validationScheme, validationBuilderFunctions } = useValidationBuilder(extendedSchema);

  const { setValue, reset, watch, ...form } = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationScheme),
    defaultValues: { branchRoute: true }
  });

  const directionKey = watch('directionKey') as SelectOption;

  useEffect(() => {
    if (createMode && !hiddenFields.includes(StopSectionFields.DEPARTURES)) {
      setValue(StopSectionFields.DEPARTURES, [{ ordinal: 1, departure: null, arrival: null }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialData) {
      reset(initialData);
    }
  }, [initialData, reset]);

  const onSubmit = (values: StopFormData) => {
    if (createMode) {
      createTransitStop(
        {
          transitScheduleId,
          formData: parseStopRequest(values)
        },
        {
          onSuccess: () => {
            queryCache.invalidateQueries(ApplicationQueryKeysEnum.TRANSIT_SCHEDULE_DETAILS_STOPS);
            queryCache.invalidateQueries([ApplicationQueryKeysEnum.TRANSIT_SCHEDULE_DETAILS, applicationId]);
            showSuccessSnackbar(t('applications:transitSchedule.actions.createSuccessMessage'));
            closeDialog();
          }
        }
      );
    } else {
      editTransitScheduleStopHour(
        {
          transitStopId: initialData?.id,
          formData: {
            applicationId,
            version: initialData.version,
            ordinal: initialData.ordinal,
            ...parseStopRequest(values)
          }
        },
        {
          onSuccess: () => {
            queryCache.invalidateQueries(ApplicationQueryKeysEnum.TRANSIT_SCHEDULE_DETAILS_STOPS);
            queryCache.invalidateQueries([ApplicationQueryKeysEnum.TRANSIT_SCHEDULE_DETAILS, applicationId]);
            showSuccessSnackbar(t('applications:transitSchedule.actions.updateSuccessMessage'));
            closeDialog();
          }
        }
      );
    }
  };

  const getDialogTitle = () => {
    if (createMode) {
      return t('applications:transitSchedule.dialog.addStopTitle');
    }
    if (viewMode) {
      return t('applications:transitSchedule.dialog.stopDetailsTitle');
    }
    return t('applications:transitSchedule.dialog.editStopTitle');
  };

  const isDeperturesSectionHidden = hiddenFields.includes(StopSectionFields.DEPARTURES);

  const formValues = useMemo(
    () => ({
      reset,
      setValue,
      watch,
      ...form,
      formMode,
      validationBuilderFunctions
    }),
    [form, formMode, validationBuilderFunctions, reset, setValue, watch]
  );

  return (
    <FormV2Context.Provider value={formValues}>
      <Dialog
        title={getDialogTitle()}
        onCancel={closeDialog}
        isFullScreen={isSmallScreen}
        customActions={
          <ButtonsGroup>
            <Button
              isPrimary
              variant="outlined"
              onClick={closeDialog}
              label={viewMode ? t('action.close') : t('action.cancel')}
            />
            {!viewMode && (
              <Button
                isPrimary
                variant="contained"
                form="createVehicleForm"
                label={createMode ? t('action.add') : t('action.save')}
                isLoading={isEditLoading || isCreateLoading}
                type="submit"
              />
            )}
          </ButtonsGroup>
        }
        isOpen
      >
        <form onSubmit={form.handleSubmit(data => onSubmit(data as StopFormData))} id="createVehicleForm">
          <GridLayout itemProps={{ xs: 12 }}>
            <StopSection
              hiddenFields={hiddenFields}
              isTypeKeySelectNotNextStop={isApplicationGroupSpoWzo}
              isCityNotRequiredForStartEndStopType={isApplicationGroupSpoWzo}
              isApplicationGroupSpoWzw={isApplicationGroupSpoWzw}
            />
            {!isDeperturesSectionHidden &&
              checkIsElementVisible(DomainUIElementEnum.APPLICATION_SCHEDULE_STOP_ADD_HOURS) && (
                <DeparturesSection directionKey={directionKey} />
              )}
          </GridLayout>
        </form>
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default StopDetails;
