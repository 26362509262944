import { usePaginatedQuery } from 'react-query';
import { DefaultApiFindTemplatesRequest } from '@avispon/document-generator';
import { PageDocumentTemplateSnapshotExternal } from '@avispon/document-generator/dist/models';
import { AxiosRequestConfig } from 'axios';

import { API, DocumentTemplateQueryKeysEnum, endpointsConfig } from '..';

export const getDocumentTemplates = async (
  _: DocumentTemplateQueryKeysEnum,
  params: DefaultApiFindTemplatesRequest
) => {
  const { data } = await API.documentGenerator.findTemplates({ ...params, includeExternalInvisible: true }, {
    ...endpointsConfig.findTemplates
  } as AxiosRequestConfig);
  return data;
};

export default function useDocumentTemplatesQuery(params: DefaultApiFindTemplatesRequest, queryConfig = {}) {
  return usePaginatedQuery<PageDocumentTemplateSnapshotExternal>(
    [DocumentTemplateQueryKeysEnum.DOCUMENT_TEMPLATES, params],
    getDocumentTemplates,
    queryConfig
  );
}
