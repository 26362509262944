import { FieldValues, UseFormGetValues } from 'react-hook-form';
import { MisspellApplicationDataRemoveRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { useMutationConfig } from '../../hooks';
import { endpointsConfig } from '../endpoints-config';

const removeApplicationPermission = async ({
  applicationId,
  removeRequest
}: {
  applicationId: string;
  removeRequest: MisspellApplicationDataRemoveRequest;
}) => {
  const { data } = await API.applicationMisspellData.removeApplicationPermissions(applicationId, removeRequest, {
    ...endpointsConfig.removeApplicationPermissions
  });
  return data;
};

function useRemoveApplicationMisspellApplicationMutation({
  getValues,
  categoryKey
}: {
  getValues?: UseFormGetValues<FieldValues>;
  categoryKey?: string;
}) {
  const getConfig = useMutationConfig({ getValues, categoryKey });
  return useMutation(removeApplicationPermission, getConfig({ shouldOnlyUpdateMisspellApplications: true }));
}

export default useRemoveApplicationMisspellApplicationMutation;
