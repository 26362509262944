import { useState } from 'react';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  GridLayout,
  Section,
  TextInputField,
  typedNameV2,
  useDialog,
  useFormV2Context,
  useIsSmallScreen,
  ValidatorEnums
} from '@libs/common/v2';

import { AddressSection } from '@libs/domain/address';

import {
  DeclarationOfNoCriminalRecordFormProvider,
  DeclarationOfNoCriminalRecordFormValues
} from './DeclarationOfNoCriminalRecordFormProvider';

export const useDeclarationOfNoCriminalRecordDialog = () => {
  const { openDialog } = useDialog();

  return {
    openDeclarationOfNoCriminalRecordDialog: (props: Pick<DeclarationOfNoCriminalRecordDialogProps, 'onConfirm'>) => {
      openDialog(({ closeDialog }) => (
        <DeclarationOfNoCriminalRecordFormProvider key="declaration-of-no-criminal-record-dialog">
          <DeclarationOfNoCriminalRecordDialog onClose={closeDialog} {...props} />
        </DeclarationOfNoCriminalRecordFormProvider>
      ));
    }
  };
};

export type DeclarationOfNoCriminalRecordDialogProps = {
  onClose: () => void;
  onConfirm: (params: {
    formData: DeclarationOfNoCriminalRecordFormValues;
    closeDialog: () => void;
    setIsLoading: (value: boolean) => void;
  }) => void;
};

function DeclarationOfNoCriminalRecordDialog({ onClose, onConfirm }: DeclarationOfNoCriminalRecordDialogProps) {
  const { isSmallScreen } = useIsSmallScreen();
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit } = useFormV2Context();

  const handleSubmitForm = () => {
    handleSubmit((async (formData: DeclarationOfNoCriminalRecordFormValues) => {
      onConfirm({ formData, closeDialog: onClose, setIsLoading });
    }) as SubmitHandler<FieldValues>)();
  };

  return (
    <Dialog
      key="correspondence-address-dialog"
      title={t('applications:dialog.declarationOfNoCriminalRecordDialogTitle')}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleSubmitForm}
      confirmText={t('action.generate')}
      isConfirmLoading={isLoading}
      cancelText={t('action.cancel')}
      isFullScreen={isSmallScreen}
      isOpen
    >
      <GridLayout itemProps={{ xs: 12 }}>
        <Section title={t('applications:dialog.section.personalData')} isModalSection isCollapsable>
          <GridLayout itemProps={{ xs: 12, md: 6 }}>
            <TextInputField
              name={typedNameV2<DeclarationOfNoCriminalRecordFormValues>('personalData.name')}
              label={t('applications:field.givenName')}
              validation={{ required: [] }}
            />
            <TextInputField
              name={typedNameV2<DeclarationOfNoCriminalRecordFormValues>('personalData.surname')}
              label={t('applications:field.surname')}
              validation={{ required: [] }}
            />
            <TextInputField
              name={typedNameV2<DeclarationOfNoCriminalRecordFormValues>('personalData.identityCardNumber')}
              label={t('applications:field.identityCardNumber')}
              validation={{ required: [] }}
            />
            <TextInputField
              name={typedNameV2<DeclarationOfNoCriminalRecordFormValues>('personalData.issuingAuthority')}
              label={t('applications:field.issuingAuthority')}
              validation={{ required: [] }}
            />
            <TextInputField
              name={typedNameV2<DeclarationOfNoCriminalRecordFormValues>('personalData.pesel')}
              label={t('applications:field.pesel')}
              validation={{ required: [], contextValidator: [ValidatorEnums.PESEL] }}
            />
          </GridLayout>
        </Section>
        <Section title={t('applications:dialog.section.ressidenceAddress')} isModalSection isCollapsable>
          <AddressSection
            formGroupName={typedNameV2<DeclarationOfNoCriminalRecordFormValues>('address')}
            isCommuneValidated={false}
            isVoivodeshipValidated={false}
            isCountyValidated={false}
            hiddenFields={['voivodeshipKey', 'county', 'commune']}
            fieldGridProps={{ city: { xs: 12 } }}
          />
        </Section>
      </GridLayout>
    </Dialog>
  );
}
