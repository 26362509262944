import { TFunction } from '@libs/common';

const datepickerTranslations = (t: TFunction, isTimePicker?: boolean) => ({
  previousMonth: t('global:action.previousMonth'),
  nextMonth: t('global:action.nextMonth'),
  okButtonLabel: t('global:action.confirm'),
  clearButtonLabel: t('global:action.clear'),
  cancelButtonLabel: t('global:action.cancel'),
  ...(!isTimePicker && { toolbarTitle: t('global:action.selectDate') }),
  ...(isTimePicker && { toolbarTitle: t('global:action.selectHour') })
});

export default datepickerTranslations;
