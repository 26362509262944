import { InsurancePolicyCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function createInsurance(data: InsurancePolicyCreateRequest) {
  return API.insurancePolicies.createInsurancePolicy(data, {
    ...endpointsConfig.createInsurancePolicy
  });
}

function useCreateInsuranceMutation() {
  return useMutation(createInsurance);
}

export default useCreateInsuranceMutation;
