import {
  AlertCreateRequest,
  AlertEditRequest,
  AlertTypeSnapshot,
  QueryColumnProperties,
  QueryCondition,
  QueryConditionFilter,
  QueryDefinition,
  RecipientSelectorSnapshot
} from '@stack/report/dist/models';
import _ from 'lodash';

import { pick } from '@libs/common/v2/utils';

import { AlertFormQueryCondition, AlertNotificationChannel, DefaultAlertFromValues } from '@libs/alert';
import { ConditionOperator } from '@libs/report-core';

const hasConditionDefined = (conditions: QueryCondition[]) =>
  conditions.some(condition =>
    condition.group?.length ? hasConditionDefined(condition.group) : Boolean(condition.filter)
  );

function parseConditionsGroup(
  conditions: AlertFormQueryCondition[],
  groupOperator: string,
  columns: Array<{ name: string }>,
  dynamicParameters?: Array<string>
): QueryCondition[] {
  return conditions.reduce((acc, condition) => {
    const conditionTemp: AlertFormQueryCondition = condition;
    if (!condition.group?.length && !condition.filter) {
      return acc;
    }

    if (condition?.filter?.column?.value) {
      conditionTemp.filter.column = condition.filter.column.value;
    }

    if (condition?.filter?.operator?.value) {
      conditionTemp.filter.operator = condition.filter.operator.value;
    }

    if (condition.filter?.column) {
      columns.push({ name: condition.filter.column });
    }

    if (dynamicParameters && condition.filter?.parameters?.length) {
      conditionTemp.filter.parameters.forEach(
        ({ dynamic, displayName }) => dynamic && dynamicParameters.push(displayName)
      );
    }

    if (condition.group?.length === 1) {
      if (hasConditionDefined(condition.group)) {
        acc.push({
          ...conditionTemp,
          group: conditionTemp.group?.length
            ? parseConditionsGroup(conditionTemp.group, conditionTemp.operator, columns, dynamicParameters)
            : null,
          operator: acc.length ? groupOperator : null
        });
      }

      return acc;
    }

    if (!condition.group?.length || hasConditionDefined(condition.group)) {
      acc.push({
        ...condition,
        group: condition.group?.length
          ? parseConditionsGroup(condition.group, condition.operator, columns, dynamicParameters)
          : null,
        operator: acc.length ? groupOperator : null
      });
    }

    return acc;
  }, [] as QueryCondition[]);
}

export function parseConditionsToFormValue(conditions?: QueryCondition[]): QueryCondition | null {
  if (!conditions) {
    return null;
  }

  return {
    operator: conditions[conditions.length - 1]?.operator || ConditionOperator.AND,
    filter: null,
    group: conditions.reduce((acc, condition) => {
      if (condition.filter) {
        acc.push(condition);
      } else {
        const parsed = condition.group?.length ? parseConditionsToFormValue(condition.group) : null;
        if (parsed) {
          acc.push(parsed);
        }
      }

      return acc;
    }, [])
  };
}

export function parseQueryDataToRequest(
  definition: QueryDefinition,
  queryConditions: AlertFormQueryCondition[],
  columns?: QueryColumnProperties[],
  dynamicParametersSet?: Array<string>
): { definition: QueryDefinition; conditions: QueryCondition[] } | null {
  if (!queryConditions?.[0]?.group?.length) {
    return null;
  }

  const columnsSet = new Array<{ name: string }>();

  const conditions = parseConditionsGroup(
    queryConditions[0].group,
    queryConditions[0].operator,
    columnsSet,
    dynamicParametersSet
  );

  return {
    definition: {
      ...definition,
      columns: columns || columnsSet
    },
    conditions
  };
}

function reduceConditions(conditions: QueryCondition[], callback: (filter: QueryConditionFilter) => boolean) {
  return conditions.reduce((acc, condition) => {
    if (condition.group?.length) {
      acc.push({
        ...condition,
        group: reduceConditions(condition.group, callback)
      });
    } else if (condition.filter && callback(condition.filter)) {
      acc.push(condition);
    }
    return acc;
  }, [] as QueryCondition[]);
}

export function removeEmptyDynamicParameters(
  conditions: QueryCondition[],
  dynamicParametersValues: { [key: string]: string }
) {
  return reduceConditions(
    conditions,
    filter => !filter?.parameters?.some(({ dynamic, displayName }) => dynamic && !dynamicParametersValues[displayName])
  );
}

function convertRecipientsToIdsArray(
  values: DefaultAlertFromValues['recipients'],
  channelKeys: string[]
): RecipientSelectorSnapshot {
  const { accountSelector, emails } = values;
  const isEmail = channelKeys.includes(AlertNotificationChannel.EMAIL);
  const isNotification = channelKeys.includes(AlertNotificationChannel.NOTIFICATION);
  return {
    accountSelector: {
      accountIds: isNotification && accountSelector.accounts ? accountSelector.accounts.map(account => account.id) : [],
      groupIds: isNotification && accountSelector.groups ? accountSelector.groups.map(group => group.id) : [],
      groupMembershipUnitIds:
        isNotification && accountSelector.groupMembershipUnits
          ? accountSelector.groupMembershipUnits.map(unit => unit.id)
          : []
    },
    emails:
      isEmail && channelKeys.includes(AlertNotificationChannel.EMAIL) && typeof emails === 'string' && emails?.length
        ? [emails]
        : []
  };
}

export function parseAlertFormValuesToCreateRequest(formValues: Partial<DefaultAlertFromValues>): AlertCreateRequest {
  const conditionsDefined = formValues.queryConditions?.[0]?.group?.length;
  const query = parseQueryDataToRequest(
    formValues.queryDefinition,
    formValues.queryConditions as AlertFormQueryCondition[],
    formValues.queryDefinition?.columns
  );

  _.unset(formValues, 'recipients.isRecipientEmail');
  return {
    ...pick(formValues, 'description'),
    channelKeys: formValues.channelKeys,
    configurationStatusKey: formValues.configurationStatusKey,
    recipients: convertRecipientsToIdsArray(formValues.recipients, formValues.channelKeys),
    cron: formValues.cron,
    query: conditionsDefined ? null : formValues?.query || null,
    sourceId: formValues.source.id,
    queryDefinition: query && formValues.basicMode ? query.definition : {},
    queryConditions: query ? query.conditions : [],
    content: {
      message: formValues.content.message,
      subject: formValues.content.subject
    },
    documentTypeId: '00000000-0000-0000-0000-000000000000',
    name: formValues.name
  };
}

export function parseAlertFormValuesToEditRequest(formValues: Partial<DefaultAlertFromValues>): AlertEditRequest {
  return {
    ...parseAlertFormValuesToCreateRequest(formValues),
    modifierId: 'cd53f643-b685-4ef8-98ba-2a6fdf742465'
  };
}

export function parseAlertDetailsDataToInitialFormValues(
  values: AlertTypeSnapshot,
  defaultValues: DefaultAlertFromValues
): DefaultAlertFromValues {
  return {
    ...defaultValues,
    ...values,
    channelKeys: (values.channelKeys as any[]) || defaultValues.channelKeys,
    recipients: {
      accountSelector: values.recipients?.accountSelector || { accountIds: [] },
      emails: values.recipients?.emails?.join(', ')
    },
    queryConditions: values.queryConditions
      ? [parseConditionsToFormValue(values.queryConditions)]
      : values.queryConditions,
    queryDefinition: values.queryDefinition ? values.queryDefinition : defaultValues.queryDefinition,
    query: !values.basicMode ? values.query : null
  };
}
