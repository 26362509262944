import { useMemo } from 'react';

import { FormMode } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import {
  DomainDictionaryEntry,
  FolderElementsPermissionsEnum,
  useDomainConfigContext,
  useFolderElementsVisibility
} from '@libs/domain/config';
import { checkIsPartnership, FolderDetailsTabEnum } from '@libs/domain/folder';

const useTabVisibility = (folderType: string, legalNatureKey: string, formMode: FormMode) => {
  const { createMode } = useViewModesV2(formMode);
  const { isOperatorPortal, isClientPortalAssociationPerspective } = useDomainConfigContext();
  const { checkFolderElementVisibility } = useFolderElementsVisibility();

  const isPartnership = useMemo(() => checkIsPartnership(legalNatureKey), [legalNatureKey]);

  const { PPO, OP, TP, PA, ZZ, WITD, KS, MZ } = DomainDictionaryEntry.FOLDER_TYPE;
  const CreateFolderTabs = [FolderDetailsTabEnum.FOLDER, FolderDetailsTabEnum.SUBJECT, FolderDetailsTabEnum.ADDRESSES];
  const ClientPortalTabsOnly = [FolderDetailsTabEnum.FOREIGN_PERMISSIONS];
  const OperatorPortalTabsOnly = [
    FolderDetailsTabEnum.LOCKS,
    FolderDetailsTabEnum.NOTE,
    FolderDetailsTabEnum.RENTALS,
    FolderDetailsTabEnum.PROCEEDINGS,
    FolderDetailsTabEnum.SUSPENSIONS,
    FolderDetailsTabEnum.HISTORY
  ];
  const FolderTabsMapper: Record<string, string[]> = {
    [FolderDetailsTabEnum.APPLICATIONS]: [PPO, OP, TP, PA, KS, MZ],
    [FolderDetailsTabEnum.SUBJECT]: [PPO, OP, TP, PA, ZZ, WITD, KS, MZ],
    [FolderDetailsTabEnum.ADDRESSES]: [PPO, OP, TP, PA, ZZ, WITD, KS, MZ],
    [FolderDetailsTabEnum.BASIC_DATA]: [PPO, OP, TP, PA, ZZ, WITD, KS, MZ],
    [FolderDetailsTabEnum.PROXIES]: [PPO, OP, TP, PA, ZZ, WITD, KS, MZ],
    [FolderDetailsTabEnum.CERTIFICATE_S]: [TP, PA, KS],
    [FolderDetailsTabEnum.SINGLE_AUTHORIZATION]: [OP, TP, PA, ZZ, KS],
    [FolderDetailsTabEnum.EKMT]: [TP],
    ...(isOperatorPortal && { [FolderDetailsTabEnum.ORDERS]: [OP, PA, KS, ZZ, WITD] }),
    [FolderDetailsTabEnum.INSURANCE_POLICIES]: [ZZ],
    [FolderDetailsTabEnum.PERMISSIONS]: [PPO, OP, TP, PA, MZ],
    [FolderDetailsTabEnum.FINANCIAL_SECURITY]: [PPO, OP, TP, KS],
    [FolderDetailsTabEnum.DRIVERS]: [TP, KS],
    [FolderDetailsTabEnum.TRANSPORT_MANAGER]: [OP, TP],
    [FolderDetailsTabEnum.VEHICLES]: [OP, TP, PA, KS],
    [FolderDetailsTabEnum.EXPLOATATION_BASES]: [OP, TP],
    [FolderDetailsTabEnum.SUSPENSIONS]: [PPO, OP, TP],
    ...(!isPartnership && { [FolderDetailsTabEnum.PROCEEDINGS]: [PPO, OP, TP, PA, MZ] }),
    [FolderDetailsTabEnum.RENTALS]: [PPO, OP, TP, PA, MZ],
    [FolderDetailsTabEnum.LOCKS]: [PPO, OP, TP, PA, ZZ, KS, MZ],
    [FolderDetailsTabEnum.HISTORY]: [PPO, OP, TP, PA, ZZ, WITD, KS, MZ],
    [FolderDetailsTabEnum.NOTE]: [PPO, OP, TP, PA, ZZ, WITD, KS, MZ],
    [FolderDetailsTabEnum.FOREIGN_PERMISSIONS]: [PPO, OP, TP, PA, ZZ, WITD, KS, MZ],
    ...(isClientPortalAssociationPerspective
      ? { [FolderDetailsTabEnum.ENTERPRENEURS]: [PPO, OP, TP, PA, ZZ, WITD, KS, MZ] }
      : { [FolderDetailsTabEnum.ENTERPRENEURS]: [] })
  };

  const isTabVisibleByFolderType = (tab: FolderDetailsTabEnum) => {
    switch (tab) {
      case FolderDetailsTabEnum.BASIC_DATA:
        return checkFolderElementVisibility({
          folderTypeKey: folderType,
          permisson: FolderElementsPermissionsEnum.FOLDER_BASIC_DATA_VIEW_PERMISSIONS
        });
      case FolderDetailsTabEnum.PERMISSIONS:
        return checkFolderElementVisibility({
          folderTypeKey: folderType,
          permisson: FolderElementsPermissionsEnum.FOLDER_PERMISSION_VIEW_PERMISSIONS
        });
      default:
        return true;
    }
  };

  const isTabVisible = (tab: FolderDetailsTabEnum) => {
    if (createMode) {
      return CreateFolderTabs.includes(tab);
    }

    return (
      isTabVisibleByFolderType(tab) &&
      !!FolderTabsMapper[tab]?.includes(folderType) &&
      (isOperatorPortal ? !ClientPortalTabsOnly.includes(tab) : !OperatorPortalTabsOnly.includes(tab))
    );
  };

  return { isTabVisible };
};

export default useTabVisibility;
