import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AdditionalFilterEnum, IAdditionalFilter } from '@libs/common/v2';

import { API } from '@libs/user/api';

function useUserAdditionalFilters() {
  const [t] = useTranslation();
  const fetchData = (text: string) => API.group.find({ query: text });
  const getOptionLabel = option => option.name;

  const additionalUserFilters: Array<IAdditionalFilter> = useMemo(() => {
    return [
      {
        type: AdditionalFilterEnum.FETCH_SELECT,
        name: 'group',
        inputProps: {
          label: t('user:groupField.group'),
          fetchFunction: fetchData,
          getOptionLabel,
          // isMultiple: true,
          isFreeSolo: true,
          isDisableClearable: false
        }
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { additionalUserFilters };
}

export default useUserAdditionalFilters;
