import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { CalendarDate } from 'calendar-date';
import { object as YupObject, string as YupString } from 'yup';

import {
  DatepickerField,
  Dialog,
  FormV2Context,
  GridItem,
  GridLayout,
  isDateValid,
  typedNameV2
} from '@libs/common/v2';

import { useUpdateSCertificateMutation } from '../../api';

export interface IUpdateSCertificateRequest {
  id?: string;
  validFrom?: string;
  version?: number;
}

interface IProps {
  readonly closeDialog: () => void;
  readonly initialData: IUpdateSCertificateRequest;
  readonly refetch?: () => void;
}

export function CertificateSEditDialog({ closeDialog, initialData, refetch }: IProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: updateSCertificate, isLoading } = useUpdateSCertificateMutation();

  const validationSchema = YupObject({
    validFrom: YupString().required().nullable().concat(isDateValid())
  });

  const form = useForm<Partial<IUpdateSCertificateRequest>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema)
  });

  const handleSubmit = (data: IUpdateSCertificateRequest) => {
    const { version, validFrom, id } = data;
    return updateSCertificate(
      { id, validFrom: validFrom as unknown as CalendarDate, version },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('sCertificates:messages.certificateEditSuccess'));
          refetch();
          closeDialog();
        }
      }
    );
  };

  useEffect(() => {
    form.reset(initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      title={t('sCertificates:details.editCertificate')}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={form.handleSubmit(handleSubmit)}
      onCancel={closeDialog}
      dialogSize="small"
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <GridLayout>
            <GridItem xs={12}>
              <DatepickerField
                name={typedNameV2<IUpdateSCertificateRequest>('validFrom')}
                label={t('sCertificates:field.validFrom')}
                isRequired
              />
            </GridItem>
          </GridLayout>
        </form>
      </FormV2Context.Provider>
    </Dialog>
  );
}
