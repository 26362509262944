export enum EKMTTableEnum {
  YEAR = 'yearEquals'
}

export enum SocialCommissionTableEnum {
  YEAR_GRATER = 'createdGreaterThanOrEqual',
  YEAR_LESS = 'createdLessThanOrEqual',
  YEAR_EXACT = 'year'
}

export enum CivicBoardTableEnum {
  YEAR_GRATER = 'yearGreaterThanOrEquals',
  YEAR_LESS = 'yearLessThanOrEquals'
}

export enum TradeRulesTableEnum {
  YEAR = 'yearIn'
}

export enum ReleasedPermitsTableEnum {
  YEAR = 'year'
}

export enum DisposablePermitsTableEnum {
  YEAR = 'civicBoardYear'
}

export enum SocialCommissionTabEnum {
  DEADLINES = 'deadlines',
  LIST = 'list',
  PETITIONS = 'petitions',
  DISPOSABLE_PERMIST = 'disposable-permist',
  TRADE_RULES = 'trade-rules',
  BASIC_RULES = 'basic-rules'
}
export enum TradeRulesTypeEnum {
  SINGLE_PERMITS = 'SINGLE_PERMITS',
  FOLDER_LIMIT = 'FOLDER_LIMIT'
}

export enum PetitionsTypeEnum {
  SINGLE = 'single',
  EKMT = 'ekmt'
}

export enum ExportFileYear {
  YEAR = 2022
}

export interface ICreateDisposableRequest {
  resourceFormNameKey: {
    name?: string;
    id?: string;
    nameKey?: string;
    value?: {
      countryKey?: string;
      fullName?: string;
      id?: string;
      name?: string;
      transbitName?: string;
      type?: string;
      year?: number;
    };
  };
  quantity?: number;
}
