import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { DocumentReceiptAckDateUpdateRequest, DocumentsApiUpdateDocumentReceiptAckDateRequest } from '@ibtm/domain';
import * as Yup from 'yup';

import { DatepickerField, Dialog, FormV2Context, GridLayout, typedNameV2 } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { convertDateWithoutTimezoneToCalendarDate } from '@libs/common/v2/utils';

import { useEnterDeliveryDateMutation } from '../../api';
import { DocumentsSnapshotClient } from '../../model';

interface IProps {
  documentData: DocumentsSnapshotClient;
  onSuccess: () => void;
  closeDialog: () => void;
  listQueryKey: string;
  isKREPTProceeding?: boolean;
  proceedingId?: string;
}

interface IRequestData extends DocumentsApiUpdateDocumentReceiptAckDateRequest {
  proceedingId?: string;
  isKREPTProceeding?: boolean;
}

function EnterDeliveryDateDialog({
  documentData,
  onSuccess,
  closeDialog,
  listQueryKey,
  isKREPTProceeding,
  proceedingId
}: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();

  const { mutate: enterDeliveryDate, isLoading } = useEnterDeliveryDateMutation();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        receiptAckDate: Yup.date().required().max(new Date(Date.now()), t('validation:maxDateTodayIncluded')).nullable()
      })
    ),
    defaultValues: {
      receiptAckDate: null
    }
  });

  const onSubmit = (formData: Partial<DocumentReceiptAckDateUpdateRequest>) => {
    const requestData: IRequestData = {
      proceedingId,
      documentId: documentData.id,
      documentReceiptAckDateUpdateRequest: {
        receiptAckDate: convertDateWithoutTimezoneToCalendarDate(formData.receiptAckDate),
        version: documentData.version
      },
      isKREPTProceeding
    };

    enterDeliveryDate(requestData, {
      onSuccess: () => {
        queryCache.invalidateQueries(listQueryKey);
        showSnackbar('success', t('document:message.enterDeliveryDateSuccess'));
        onSuccess?.();
        closeDialog();
      }
    });
  };

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('document:dialog.enterDeliveryDateTitle')}
      shortTitle={t('document:dialog.enterDeliveryDateShortTitle')}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={() => handleSubmit(onSubmit)()}
      onCancel={closeDialog}
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <form>
          <GridLayout itemProps={{ xs: 12 }}>
            <DatepickerField
              name={typedNameV2<DocumentReceiptAckDateUpdateRequest>('receiptAckDate')}
              label={t('document:fields.receiptDate')}
              isRequired
            />
          </GridLayout>
        </form>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default EnterDeliveryDateDialog;
