import { ProxyCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function createProxy(formData: ProxyCreateRequest) {
  return API.proxy.createProxy(formData, {
    ...endpointsConfig.createProxy
  });
}

function createProxyClient(formData: ProxyCreateRequest) {
  return API.client.proxy.createProxy('', '', formData, {
    ...endpointsConfig.createProxy
  });
}

function useCreateProxyMutation() {
  const { getQuery } = useGetApiQueryByPerspective();

  return useMutation(getQuery(createProxyClient, createProxy));
}

export default useCreateProxyMutation;
