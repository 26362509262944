import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReportDetailsLite } from '@stack/report/dist/models';

import { TableButtonView, TableLayout, useDevMode, useRouter } from '@libs/common/v2';

import { API } from '@libs/report/api';
import { modelToApi } from '@libs/report/api/queries/useReportsQuery';
import { useReportsTable } from '@libs/report/hooks';

import { ReportUIElementEnum } from '../../config/permission';

import { GeneratedReportsTableRowActions } from './GeneratedReportsTableRowActions';

function GeneratedReportsTable() {
  const [t] = useTranslation();
  const tableProps = useReportsTable();
  const { isDevMode } = useDevMode();
  const { routes } = useRouter();

  return (
    <TableLayout<ReportDetailsLite, ReportDetailsLite>
      {...tableProps}
      pageTitle={t('reports:reports.sectionTitle')}
      xlsxDownload={{
        fileName: t('reports:reports.sectionTitle'),
        pathToXLSXTranslation: 'reports:field',
        apiRequest: params => {
          const apiModel = modelToApi(params);
          return API.report
            .getReports(
              apiModel.pageNumber,
              apiModel.pageSize,
              apiModel.pageSort,
              apiModel.createdBy,
              apiModel.createdFrom,
              apiModel.createdTo,
              apiModel.dataSourceNameFragment,
              apiModel.generationEndedMax,
              apiModel.generationEndedMin,
              apiModel.generationStartedMax,
              apiModel.generationStartedMin,
              apiModel.ids,
              apiModel.includeDeleted,
              apiModel.modifiedFrom,
              apiModel.modifiedTo,
              apiModel.organizationUnitId,
              apiModel.reportTypeDescriptionFragment,
              apiModel.reportTypeIds,
              apiModel.reportTypeNameFragment,
              apiModel.sidFragment,
              apiModel.statusKeys,
              isDevMode
            )
            .then(res => ({ ...res, data: { ...res.data, content: res.data.content } }));
        }
      }}
      rowActions={({ original }) => (
        <>
          <TableButtonView
            link={routes.reportDetails(original.id)}
            actionKey={ReportUIElementEnum.REPORT_DETAILS_BUTTON}
          />
          <GeneratedReportsTableRowActions report={original} />
        </>
      )}
      isSectionFullTab
    />
  );
}

export default React.memo(GeneratedReportsTable);
