import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

const removeEntrepreneurFromUser = async ({ userId, subjectId }) => {
  const { data } = await API.usersAvailableSubjects.deleteUsersAvailableSubject(userId, subjectId, {
    ...endpointsConfig.deleteUsersAvailableSubject
  });
  return data;
};

function useRemoveEntrepreneurFromUser() {
  return useMutation(removeEntrepreneurFromUser);
}

export default useRemoveEntrepreneurFromUser;
