import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { ApplicationDetails } from '@ibtm/domain';

import { Dialog, useDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useGetApiQueryByPerspective } from '@libs/domain/api';
import { ApplicationQueryKeysEnum } from '@libs/domain/application';
import { DriversQueryKeysEnum, useLinkDriverToApplicationMutation } from '@libs/domain/drivers/api';

import { handleAfterAddCheckFlags } from '../../utils';

function useConfirmAddDriverDialog() {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { mutate: linkDriver } = useLinkDriverToApplicationMutation();
  const { getQuery } = useGetApiQueryByPerspective();

  const { openDialog } = useDialog();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  const openConfirmAddDriverDialog = useCallback(
    (rowIds: string[], applicationId: string) =>
      openDialog(({ closeDialog }) => (
        <Dialog
          title={t('drivers:dialogs.driverAddTitle')}
          confirmText={t('action.add')}
          cancelText={t('action.cancel')}
          contentText={t('drivers:dialogs.addDriverMessage')}
          onConfirm={setLoading => {
            setLoading(true);
            const data: ApplicationDetails = queryCache.getQueryData([
              ApplicationQueryKeysEnum.APPLICATION,
              applicationId
            ]);
            linkDriver(
              {
                applicationId,
                driverId: rowIds[0],
                version: data.version
              },
              {
                onSuccess: () => {
                  handleAfterAddCheckFlags(applicationId, getQuery, showErrorSnackbar, t);
                  showSuccessSnackbar(t('drivers:messages.addDriverSuccess'));
                  queryCache.invalidateQueries([DriversQueryKeysEnum.DRIVERS_LIST]);
                  queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
                },
                onSettled: () => {
                  setLoading(false);
                  closeDialog();
                }
              }
            );
          }}
          onCancel={closeDialog}
          isOpen
        />
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog, t, showSuccessSnackbar]
  );

  return { openConfirmAddDriverDialog };
}

export default useConfirmAddDriverDialog;
