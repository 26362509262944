import { useState } from 'react';
import { ListItem, Popover } from '@mui/material';
import clsx from 'clsx';

import { IconCountButton, IconType, Typography } from '@libs/common/v2';

import { HasPermission } from '@libs/permission/components';
import { UIElementNameEnum } from '@libs/permission/models';

import { MuteNotificationsIcon } from './MuteNotifications';
import useMessagePopoverStyles from './styles';

interface IProps {
  icon: IconType;
  iconClassName?: string;
  unreadMessagesCount?: number;
  headerMessageText?: string;
  children?: React.ReactNode;
  handleBottomActionClick?: () => void;
  bottomActionText?: string;
  noMessagesText?: string;
  hasMessages?: boolean;
  hasMuteNotificationsButton?: boolean;
  tooltipTitle: string;
  fullListActionKey?: UIElementNameEnum;
}

function MessagePopover({
  icon,
  iconClassName,
  unreadMessagesCount,
  headerMessageText,
  children,
  handleBottomActionClick,
  bottomActionText,
  noMessagesText,
  hasMessages,
  hasMuteNotificationsButton,
  tooltipTitle,
  fullListActionKey
}: IProps) {
  const classes = useMessagePopoverStyles({ unreadMessagesCount });

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);

  const closeMessagesPopover = () => setPopoverAnchorEl(null);
  const openMessagesPopover = ev => {
    setPopoverAnchorEl(ev.currentTarget);
  };

  return (
    <>
      <IconCountButton
        className={clsx(classes.icon, iconClassName)}
        tooltipTitle={tooltipTitle}
        icon={icon}
        onClick={openMessagesPopover}
        countValue={unreadMessagesCount}
      />
      <Popover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={closeMessagesPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        classes={{
          paper: classes.paper
        }}
      >
        <Typography themeVariant="textSm.medium" className={classes.listSubheader}>
          {headerMessageText}

          {hasMuteNotificationsButton && (
            <div className={classes.muteNotificationsButton}>
              <MuteNotificationsIcon />
            </div>
          )}
        </Typography>

        {hasMessages ? (
          <>
            {children}
            <HasPermission actionKeys={[fullListActionKey]}>
              <ListItem
                button
                onClick={() => {
                  handleBottomActionClick();
                  closeMessagesPopover();
                }}
                className={classes.listButton}
              >
                <Typography themeVariant="textSm.medium">{bottomActionText}</Typography>
              </ListItem>
            </HasPermission>
          </>
        ) : (
          <Typography themeVariant="textSm.normal" className={classes.listSubheaderBottom}>
            {noMessagesText}
          </Typography>
        )}
      </Popover>
    </>
  );
}

export default MessagePopover;
