import { LicenseCopySnapshot as LicenseCopySnapshotClientPortal } from '@ibtm/client-domain';
import { AddressSnapshot, LicenseCopySnapshot, LicenseSnapshot } from '@ibtm/domain';
import { NamedObjectDetails } from '@ibtm/domain/dist/models/named-object-details';
import { CalendarDate } from 'calendar-date';

import { i18n } from '@libs/common';
import { convertCalendarDate } from '@libs/common/v2/utils';

import { LicenseCopySnapshotClient, LicenseSnapshotClient } from '../model/license.model';

export const parseAddressString = (address: AddressSnapshot) =>
  address
    ? [
        address?.postCode,
        address?.city,
        address?.street,
        address?.apartmentNumber ? `${address?.propertyNumber}/${address?.apartmentNumber}` : address?.propertyNumber
      ]
        .filter(Boolean)
        .join(', ')
    : i18n.t('emptyMark');

export const parseLicensesList = (licenses: LicenseSnapshot[]): LicenseSnapshotClient[] =>
  licenses.map(license => ({
    id: license.id,
    applicationNumber: license.application?.number,
    cancellationReason: license.cancellationReasonKey,
    cancelledBy: license.cancelledBy?.name,
    cancellationDate: convertCalendarDate(license.cancellationDate),
    subjectName: license.subject?.name,
    subjectAddress: parseAddressString(license.address),
    licenseNumber: license.number,
    formNumber: license?.form?.fullNumber,
    formId: license?.form?.id,
    printDate: convertCalendarDate(license.printDate),
    validFrom: convertCalendarDate(license.validFrom),
    resourceAssignmentRegenerationNeeded: license.resourceAssignmentRegenerationNeeded,
    validTo: convertCalendarDate(license.validTo),
    dateOfIssueExternal: convertCalendarDate(license.dateOfIssueExternal),
    signerName: license.signer?.name,
    status: license.statusKey,
    licenseValidityYearsKey: license.licenseValidityYearsKey,
    version: license.version,
    file: license.file,
    form: license.form,
    issuedLocks: license.issuedLocks,
    locksToSetUp: license.locksToSetUp,
    permissionsGenerated: license.permissionsGenerated
  }));

const parseLicenseCopyClient = (
  licenseCopy:
    | LicenseCopySnapshot
    | (LicenseCopySnapshotClientPortal & {
        resourceAssignmentRegenerationNeeded?: boolean;
        applicationNumber?: string;
        cancellationReasonKey?: string;
        cancelledBy?: NamedObjectDetails;
        cancellationDate?: CalendarDate;
      })
) => ({
  id: licenseCopy.id,
  applicationNumber: licenseCopy?.applicationNumber || licenseCopy.license?.application?.number,
  cancellationReason: licenseCopy?.cancellationReasonKey,
  cancelledBy: licenseCopy?.cancelledBy?.name,
  cancellationDate: licenseCopy?.cancellationDate,
  subjectName: licenseCopy.subject?.name,
  subjectAddress: parseAddressString(licenseCopy.subjectAddress),
  licenseNumber: licenseCopy.licenseNumber,
  formNumber: licenseCopy.formNumber,
  printDate: licenseCopy.printDate,
  validFrom: licenseCopy.validFrom,
  validTo: licenseCopy.validTo,
  dateOfIssueExternal: licenseCopy.dateOfIssueExternal,
  signerName: licenseCopy.signer?.name,
  belowThreeAndHalfTons: licenseCopy.belowThreeAndHalfTons,
  status: licenseCopy.status,
  licenseValidityYearsKey: licenseCopy?.license?.licenseValidityYearsKey,
  version: licenseCopy.version,
  resourceAssignmentRegenerationNeeded: licenseCopy?.resourceAssignmentRegenerationNeeded || false,
  file: licenseCopy.file,
  ...('form' in licenseCopy && { form: licenseCopy.form })
});
export const parseLicenseCopiesList = (
  licenseCopies: LicenseCopySnapshot[] | LicenseCopySnapshotClientPortal[]
): LicenseCopySnapshotClient[] => licenseCopies.map(parseLicenseCopyClient);
