import { useTranslation } from 'react-i18next';
import { ParameterExtras } from '@stack/report';

import { Button, TableButtonDelete, TableButtonEdit, TableLayout, useFormV2Context } from '@libs/common/v2';

import useReportTypeExtrasDialog from '@libs/report/hooks/useReportTypeExtrasDialog';
import useReportTypesExtrasTable from '@libs/report/hooks/useReportTypesExtrasTable';
import { ReportTypeForm } from '@libs/report/model/form.model';

export function ReportTypesExtrasTable() {
  const [t] = useTranslation();
  const { getValues, setValue, watch, loading } = useFormV2Context();
  const { openReportTypeExtrasDialog } = useReportTypeExtrasDialog(getValues() as ReportTypeForm);
  const parameterExtrasWatch = watch('parameterExtras') as ParameterExtras[];
  const tableProps = useReportTypesExtrasTable(parameterExtrasWatch);

  const handleSubmitParameter = (parameterExtras: ParameterExtras) => {
    setValue(
      'parameterExtras',
      getValues().parameterExtras
        ? [{ ...parameterExtras, id: parameterExtras.displayName }, ...getValues().parameterExtras]
        : [{ ...parameterExtras, id: parameterExtras.displayName }],
      { shouldDirty: true }
    );
  };

  const handleEditParameter = parameterExtras => {
    const values = getValues('parameterExtras');
    setValue(
      'parameterExtras',
      values.map(param => {
        if (param.displayName === parameterExtras.id) {
          return parameterExtras;
        }
        return param;
      }),
      { shouldDirty: true }
    );
  };

  const handleDeleteParameter = original => {
    setValue(
      'parameterExtras',
      getValues().parameterExtras.filter(parameter => parameter.displayName !== original.displayName),
      { shouldDirty: true }
    );
  };

  const getType = values => {
    if (!values.type) {
      return undefined;
    }
    return values?.type?.name ? values.type : { name: values.type.type, value: values.type.type };
  };

  const editFormValues = (original: ParameterExtras): ParameterExtras => {
    return {
      ...original,
      type: getType(original)
    };
  };

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('reports:details.section.reportParameters')}
      headerActions={
        <Button
          variant="outlined"
          label={t('reports:reportTypes.parameterExtras.action.addParameter')}
          onClick={() => openReportTypeExtrasDialog(handleSubmitParameter)}
        />
      }
      rowActions={({ original }: { original: ParameterExtras }) => (
        <>
          <TableButtonDelete
            tooltipTitle={t('reports:reportTypes.parameterExtras.tip.removeParameter')}
            onClick={() => handleDeleteParameter(original)}
          />
          <TableButtonEdit
            tooltipTitle={t('reports:reportTypes.parameterExtras.tip.editParameter')}
            onClick={() => openReportTypeExtrasDialog(handleEditParameter, editFormValues(original))}
          />
        </>
      )}
      isFilterEnabled={false}
      isPaginated={false}
      isSection
      isCollapsable
      isLoading={loading}
      isRefreshEnabled={false}
    />
  );
}
