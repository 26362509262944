import { usePaginatedQuery } from 'react-query';
import { ArchiveApplicationSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { ArchiveQueryKeysEnum } from '../../../common';
import { ApplicationToArchiveSnapshot } from '../../model';
import { parseArchiveApplicationList } from '../../utils';
import { endpointsConfig } from '../endpoints-config';

const getApplicationsToArchive = async (_, params: ArchiveApplicationSearchFilter) => {
  const { data } = await API.archiveApplications.getArchiveApplications(params, {
    ...endpointsConfig.getArchiveApplications
  });
  return { ...data, content: parseArchiveApplicationList(data.content) };
};

export const useApplicationToArchiveQuery = (params: ArchiveApplicationSearchFilter, queryConfig = {}) => {
  return usePaginatedQuery<IPaginatedModel<ApplicationToArchiveSnapshot>>(
    [ArchiveQueryKeysEnum.APPLICATIONS_TO_ARCHIVE, params],
    getApplicationsToArchive,
    queryConfig
  );
};
