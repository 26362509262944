import { createContext, useContext } from 'react';
import moment from 'moment';

interface IMuteNotificationContext {
  mutedUntil: string;
  setData: (mutedUntil: string) => void;
}

export const MuteNotificationContext = createContext<IMuteNotificationContext>({
  mutedUntil: null,
  setData: () => {}
});

export default function useMuteNotifications() {
  const { mutedUntil, setData } = useContext(MuteNotificationContext);

  const momentedDate = moment(mutedUntil);
  return { isMuted: momentedDate.isValid() ? !moment(mutedUntil).isBefore(moment()) : false, setData };
}
