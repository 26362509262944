import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { FolderDetails, FolderOwnerDateOfDeathUpdateRequest } from '@ibtm/domain';
import { Grid } from '@mui/material';
import { date as YupDate, object as YupObject } from 'yup';

import { DatepickerField, Dialog, FormMode, FormV2Context, typedNameV2 } from '@libs/common/v2';
import { convertDateToDateFormat } from '@libs/common/v2/utils';

import { useUpdateDateOfDeathMutation } from '@libs/domain/folder';

interface Props {
  folderId: string;
  closeDialog: () => void;
  dateOfDeath: string;
  onSuccess: (res: FolderDetails) => void;
  version: number;
}

function UpdateDateOfDeathDialog({ closeDialog, dateOfDeath, folderId, onSuccess, version }: Props) {
  const [t] = useTranslation();

  const { showSuccessSnackbar } = useSnackbar();

  const validationSchema = YupObject({
    dateOfDeath: YupDate().nullable().max(new Date(), t('error.valueCannotBeInTheFuture'))
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    reset,
    getValues,
    trigger,
    unregister
  } = useForm<Partial<FolderOwnerDateOfDeathUpdateRequest>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: { dateOfDeath: convertDateToDateFormat(dateOfDeath), version },
    resolver: yupResolver(validationSchema)
  });

  const { mutate: updateDateOfDeath, isLoading } = useUpdateDateOfDeathMutation();

  const onSubmit = async (values: Partial<FolderOwnerDateOfDeathUpdateRequest>) => {
    updateDateOfDeath(
      { folderId, values },
      {
        onSuccess: () => {
          onSuccess({ dateOfSubjectDeath: values?.dateOfDeath, version: values?.version });
          if (!values.dateOfDeath) {
            showSuccessSnackbar(t('folder:details.message.dateOfDeathRemoved'));
          } else if (dateOfDeath) {
            showSuccessSnackbar(t('folder:details.message.dateOfDeathUpdated'));
          } else {
            showSuccessSnackbar(t('folder:details.message.dateOfDeathAdded'));
          }
          closeDialog();
        }
      }
    );
  };

  const values = useMemo(
    () => ({
      formMode: FormMode.EDIT,
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      reset,
      handleSubmit
    }),
    [control, errors, getValues, handleSubmit, isSubmitting, register, reset, setValue, trigger, unregister, watch]
  );

  return (
    <FormV2Context.Provider value={values}>
      <Dialog
        title={t('folder:details.entrepreneurDeathModal.title')}
        onCancel={closeDialog}
        onConfirm={handleSubmit(onSubmit)}
        confirmText={t('action.save')}
        cancelText={t('action.cancel')}
        confirmButtonTooltipText={{ disabled: t('validation:oneOfOptionRequired') }}
        isLoading={isLoading}
        isOpen
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DatepickerField
                name={`${typedNameV2<FolderOwnerDateOfDeathUpdateRequest>('dateOfDeath')}` as const}
                label={t('folder:details.field.dateOfDeath')}
                maxDate={Date.now()}
              />
            </Grid>
          </Grid>
        </form>
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default UpdateDateOfDeathDialog;
