import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { GridLayout, useFormV2Context } from '@libs/common/v2';

import { DomainUIElementEnum } from '@libs/domain/config';
import {
  DocumentsQueryKeysEnum,
  DocumentsSnapshotClient,
  DocumentsTable,
  HeaderActionEnum,
  isReceiveDateDocumentActionDisabled,
  RowActionEnum,
  UploadDocumentFile,
  useCancelDocumentMutation,
  useDeliveryDateDialog,
  useFinalDecisionDocumentMutation,
  useForwardToShipmentMutation,
  useGenerateApplicationDocumentDialog,
  useUpdateDocumentDialog
} from '@libs/domain/documents';

import { API } from '../../../../api';
import {
  RoadTransportQueryKeysEnum,
  useDocumentGenerationMutation,
  useUpdateKreptdDocumentMutation
} from '../../../api';
import { useDocumentsTable } from '../../../hooks';
import { KreptdProceedingDetailsParams } from '../../../models';

function DocumentsTab() {
  const { formMode } = useFormV2Context();
  const { proceedingId } = useParams<KreptdProceedingDetailsParams>();
  const [t] = useTranslation();

  const requestData = useCallback(
    (formData: UploadDocumentFile, documentData: DocumentsSnapshotClient) => ({
      proceedingId,
      documentId: documentData.id,
      documentUpdateRequest: {
        fileId: formData.file.fileId,
        version: documentData.version
      }
    }),
    [proceedingId]
  );

  const { openUpdateDocumentDialog } = useUpdateDocumentDialog(
    DocumentsQueryKeysEnum.DOCUMENTS_LIST,
    useUpdateKreptdDocumentMutation,
    proceedingId,
    requestData
  );
  const { openEnterDeliveryDateDialog } = useDeliveryDateDialog({ isKREPTProceeding: true });
  const { mutateAsync: forwardToShipment } = useForwardToShipmentMutation();
  const { mutateAsync: cancelDocument } = useCancelDocumentMutation();
  const { mutateAsync: finalDecisionDocument } = useFinalDecisionDocumentMutation();

  const { openGenerateDocumentDialog } = useGenerateApplicationDocumentDialog({
    generateDocumentMutation: useDocumentGenerationMutation,
    apiParams: { proceedingId },
    listQueryKey: RoadTransportQueryKeysEnum.DOCUMENTS_LIST
  });

  const tableProps = useDocumentsTable({ proceedingId });

  const handleGenerateDocument = (data: {
    title?: string;
    groupKey?: string;
    isGroupFieldDisabled?: boolean;
    onSuccess?: () => void;
  }) => {
    openGenerateDocumentDialog({
      title: t('roadTransport:dialog.generateFormTitle'),
      groupKey: data.groupKey
    });
  };

  return (
    <GridLayout itemProps={{ xs: 12 }}>
      <DocumentsTable
        parentId={proceedingId}
        tableProps={tableProps}
        apiRequest={API.proceedingsKreptd.getProceedingKreptdDocumentsPage}
        openGenerateDocumentDialog={handleGenerateDocument}
        openUpdateDocumentDialog={openUpdateDocumentDialog}
        openEnterDeliveryDateDialog={openEnterDeliveryDateDialog}
        forwardToShipment={forwardToShipment}
        cancelDocument={cancelDocument}
        finalDecisionDocument={finalDecisionDocument}
        tableActionKeys={[
          DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_UPLOAD_DOCUMENT_BUTTON,
          DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_CANCEL_DOCUMENT_BUTTON,
          DomainUIElementEnum.DOCUMENTS_ISSUED_DOCUMENTS_DOWNLOAD_BUTTON,
          DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_ADD_RECIEVE_DATE_BUTTON,
          DomainUIElementEnum.DOCUMENTS_ISSUED_DOCUMENTS_FORWARD_TO_SHIPMENT_BUTTON,
          DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_FINAL_DECISION
        ]}
        rowActions={[
          RowActionEnum.CANCEL_DOCUMENT,
          RowActionEnum.DOWNLOAD_DOCUMENT,
          RowActionEnum.UPLOAD_DOCUMENT,
          RowActionEnum.ENTER_DELIVERY_DATE_DOCUMENT,
          RowActionEnum.FORWARD_TO_SHIPMENT_DOCUMENT,
          RowActionEnum.FINAL_DECISION
        ]}
        permissionsKeys={{
          generateDocument: DomainUIElementEnum.DOCUMENTS_ISSUED_GENERATE_DOCUMENT_BUTTON,
          generateCallToFillGaps: DomainUIElementEnum.DOCUMENTS_ISSUED__DOCUMENTS_GENERATE_CALL_TO_FILL_GAPS_BUTTON,
          generateSubmissionConfirmation: DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_GENERATE_CONFIRM_BUTTON
        }}
        customDisabledLabelKey="document:message.changeReceiveDateTooltip"
        mode={formMode}
        isCollapsable={false}
        headerActions={[
          HeaderActionEnum.GENERATE_SUBMISSION,
          HeaderActionEnum.GENERATE_CALL_TO_FILL_GAPS,
          HeaderActionEnum.GENERATE_ISSUE,
          HeaderActionEnum.GENERATE_DOCUMENT
        ]}
        isReceiveDateDocumentActionDisabled={isReceiveDateDocumentActionDisabled}
      />
    </GridLayout>
  );
}

export default DocumentsTab;
