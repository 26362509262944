import { useTranslation } from 'react-i18next';
import { FolderRentalDetails, FolderRentalSearchFilter } from '@ibtm/domain';

import {
  CamelCasePath,
  SelectOption,
  TableFilter,
  TableFilterMapper,
  TableSortMapper,
  useCreateColumns
} from '@libs/common/v2';
import { DateRangeISO, getCalendarDate, getValue } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';
import { UserFilterV2 } from '@libs/user';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { BorrowFolderSnapshot } from '../model';

export const useBorrowFoldersColumns = (visibleColumns?: Array<CamelCasePath<BorrowFolderSnapshot>>) => {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  const { createColumns } = useCreateColumns<BorrowFolderSnapshot, FolderRentalDetails>({
    pathToTranslate: 'archive:borrowFolder.field'
  });

  const mappedSortFields: TableSortMapper<BorrowFolderSnapshot> = {
    folderNumber: 'folder.number',
    subjectNip: 'folder.subject.nip',
    subjectName: 'folder.subject.name',
    archiveIssuer: 'releasedBy.name',
    archiveReceiver: 'receivedBy.name',
    borrowPurpose: 'purposeKey',
    borrower: 'borrowedBy.name',
    returnPerson: 'returnedBy.name',
    localizationKey: 'localizationKey'
  };

  const mappedFilterFields: TableFilterMapper<BorrowFolderSnapshot, FolderRentalSearchFilter> = {
    folderNumber: (number: string) => ({ folderNumberContains: number }),
    subjectNip: (nip: string) => ({ nipContains: nip }),
    subjectName: (subjectNameFragment: string) => ({ subjectNameContains: subjectNameFragment }),
    borrower: (borrowers: SelectOption<string>[]) => ({ borrowedByIn: borrowers.map(({ value }) => value) }),
    borrowDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      borrowDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      borrowDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    borrowPurpose: (borrowPurposes: SelectOption<string>[]) => ({
      purposeKeyIn: borrowPurposes.map(({ value }) => value)
    }),
    returnDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      returnDateGreaterThanOrEquals: getCalendarDate(dateFrom),
      returnDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    returnPerson: (returners: SelectOption<string>[]) => ({ returnedByIn: returners.map(({ value }) => value) }),
    archiveIssuer: (archiveIssuers: SelectOption<string>[]) => ({
      releasedByIn: archiveIssuers.map(({ value }) => value)
    }),
    archiveReceiver: (archiveReceivers: SelectOption<string>[]) => ({
      receivedByIn: archiveReceivers.map(({ value }) => value)
    }),
    localizationKey: (statuses: SelectOption<string>[]) => ({ localizationKeyIn: statuses.map(({ value }) => value) })
  };

  const columns = createColumns(
    [
      {
        header: 'number',
        accessor: 'folder.number',
        xlsxAccessor: 'folderNumber',
        type: 'TEXT'
      },
      {
        accessor: 'subject.nip',
        header: 'nip',
        xlsxAccessor: 'nip',
        type: 'TEXT'
      },
      {
        accessor: 'subject.name',
        xlsxAccessor: 'subjectName',
        type: 'TEXT'
      },
      {
        accessor: 'borrowDate',
        type: 'DATE'
      },
      {
        id: 'borrowPurpose',
        type: 'CUSTOM_COLUMN',
        customAccessor: row =>
          translate(DomainDictionaryEnum.FOLDER_BORROW_PURPOSE, row?.borrowPurpose, t('emptyMark')),
        customXlsxAccessor: row =>
          translate(DomainDictionaryEnum.FOLDER_BORROW_PURPOSE, row?.purposeKey, t('emptyMark')),
        filter: props => (
          <TableFilter.DictionaryMultiSelect
            {...props}
            placeholder={t('action.select')}
            dictionary={DomainDictionaryEnum.FOLDER_BORROW_PURPOSE}
          />
        )
      },
      {
        id: 'borrower',
        type: 'CUSTOM_COLUMN',
        customAccessor: row => getValue(row.borrower),
        customXlsxAccessor: row => getValue(row.borrowedBy?.name),
        filter: UserFilterV2
      },
      {
        accessor: 'returnDate',
        type: 'DATE'
      },
      {
        accessor: 'returnPerson',
        xlsxAccessor: 'returnedBy.name',
        type: 'CUSTOM_COLUMN',
        filter: UserFilterV2
      },
      {
        accessor: 'archiveIssuer',
        xlsxAccessor: 'releasedBy.name',
        type: 'CUSTOM_COLUMN',
        filter: UserFilterV2
      },
      {
        accessor: 'archiveReceiver',
        xlsxAccessor: 'receivedBy.name',
        type: 'CUSTOM_COLUMN',
        filter: UserFilterV2
      },
      {
        id: 'localizationKey',
        header: 'folderLocalization',
        type: 'DICTIONARY_MULTI_SELECT',
        customAccessor: row =>
          translate(DomainDictionaryEnum.FOLDER_LOCALIZATION_RENTALS, row?.localizationKey, t('emptyMark')),
        customXlsxAccessor: row =>
          translate(DomainDictionaryEnum.FOLDER_LOCALIZATION_RENTALS, row?.folderLocalizationKey, t('emptyMark')),
        filter: props => (
          <TableFilter.DictionaryMultiSelect {...props} dictionary={DomainDictionaryEnum.FOLDER_LOCALIZATION_RENTALS} />
        )
      }
    ],
    visibleColumns
  );

  return { columns, mappedFilterFields, mappedSortFields };
};
