import { FieldPath, FieldValues } from 'react-hook-form';
import { AddressUpdateRequest } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import { DictionarySelectField, GridItem, GridLayout, InputMode, typedNameV2 } from '@libs/common/v2';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import { useSetInitialAddressType } from '../../hooks';
import { AddressSection } from '../address-content';

const getLabel = partialTranslate('address:fields');

interface IProps {
  formGroupName: string | FieldPath<FieldValues>;
  addressFormGroupName?: string | FieldPath<FieldValues>;
  hiddenFields?: Array<keyof AddressUpdateRequest>;
  inputProps?: {
    inputMode?: InputMode;
  };
  isCountryFieldAvaliable: boolean;
}

function MainAddress({
  formGroupName,
  addressFormGroupName = formGroupName,
  hiddenFields,
  inputProps,
  isCountryFieldAvaliable
}: IProps) {
  const getFieldName = (fieldName: any) => typedNameV2<any>(`${formGroupName}.${fieldName}`);
  const typeKey = getFieldName('typeKey');

  useSetInitialAddressType(typeKey, DomainDictionaryEntry.ADDRESS_TYPE.MAIN);
  return (
    <GridLayout itemProps={{ xs: 12, sm: 6 }}>
      <GridItem xs={12}>
        <AddressSection
          formGroupName={addressFormGroupName}
          hiddenFields={hiddenFields}
          inputProps={inputProps}
          isCountryFieldAvaliable={isCountryFieldAvaliable}
          isValidationEnabled={inputProps.inputMode !== InputMode.VIEW}
        />
      </GridItem>

      <DictionarySelectField
        name={getFieldName('typeKey')}
        label={getLabel('type')}
        dictionaryName={DomainDictionaryEnum.ADDRESS_TYPE}
        inputMode={InputMode.VIEW}
      />
    </GridLayout>
  );
}

export default MainAddress;
