import { MutationConfig, useMutation } from 'react-query';
import { FolderCreateRequest } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const createFolder = async (request: FolderCreateRequest) => {
  const { data } = await API.folder.createFolder(request, {
    ...endpointsConfig.createFolder
  });
  return data;
};

export default function useCreateFolderMutation(config?: MutationConfig<any, unknown, FolderCreateRequest, unknown>) {
  return useMutation(createFolder, config);
}
