import { useTranslation } from 'react-i18next';

import { GridLayout, Section, useFormV2Context } from '@libs/common/v2';

import { useReportTypeFieldRenderer } from '@libs/report/hooks';

export function ReportTypeGeneralSection() {
  const [t] = useTranslation();
  const fieldRenderer = useReportTypeFieldRenderer();

  const { loading } = useFormV2Context();

  return (
    <Section title={t('reports:details.section.generalData')} isLoading={loading} isCollapsable>
      <GridLayout itemProps={{ xs: 6 }} containerProps={{ direction: 'row', alignItems: 'flex-start', spacing: 4 }}>
        {fieldRenderer.name()}
        {fieldRenderer.description()}
        {fieldRenderer.documentTemplate()}
        {fieldRenderer.cron()}
        {fieldRenderer.permissionKeys()}
      </GridLayout>
    </Section>
  );
}
