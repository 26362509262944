import { QueryConfig, useQuery } from 'react-query';

import { API } from '@libs/domain/api';

import { DocumentsIssuedQueryEnum } from '../../model/documents-issued.model';
import { endpointsConfig } from '../endpoints-config';

export const downloadLabel = (fieldId: string, token: string) => {
  return API.file.download(fieldId, token, undefined, { ...endpointsConfig.publicDownload, responseType: 'blob' });
};

export default function useDownloadLabelQuery(
  params: { fieldId: string; token: string },
  queryConfig: QueryConfig<any, unknown> = {}
) {
  return useQuery(
    [DocumentsIssuedQueryEnum.DOWNLOAD_FILE, params],
    () => downloadLabel(params.fieldId, params.token),
    queryConfig
  );
}
