import { RefetchOptions } from 'react-query/types/core/query';
import { useParams } from 'react-router-dom';
import { NoteUpdateRequest } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import { FormMode, IconType } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DomainDictionaryEntry, DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import { EditFolderParams, FolderDetailsTabEnum } from '@libs/domain/folder';
import {
  TransportManagersActionButtonsEnum,
  TransportManagersColumnsEnum,
  TransportManagersTable
} from '@libs/domain/transport-manager';

import {
  AddressesTab,
  BasicDataTab,
  DriversTab,
  EkmtTab,
  EnterpreneursTab,
  ExploitationBaseTab,
  FinancialSecurityTab,
  FolderHistoryTable,
  FolderTab,
  ForeignPermissionsTab,
  InsurancePoliciesTab,
  LocksTab,
  NoteTab,
  OrdersTab,
  PermissionsTab,
  ProceedingsTab,
  ProxiesTab,
  RentalsTab,
  SCertificatesTab,
  SingleAuthorizationTab,
  SubjectTab,
  SuspensionsTab,
  VehicleTab
} from '../components/details/tabs';
import { ApplicationsTab } from '../components/details/tabs/applications';

export interface IFolderTab {
  label: string;
  value: FolderDetailsTabEnum;
  content: JSX.Element;
  icon?: IconType;
  showAlways?: boolean;
  hideTabHeader?: boolean;
  isSaveDisabled?: boolean;
}

export const useFolderTabDefinitions = (
  folderType: string,
  formMode: FormMode,
  folderNumber: string,
  refetchSubject?: (options?: RefetchOptions) => void,
  refetchFolder?: (options?: RefetchOptions) => void,
  customHandleChangeForNoteSection?: (value: string | NoteUpdateRequest) => void,
  isMainAddressEditable?: boolean,
  additionalContent?: {
    header?: JSX.Element;
  }
): Array<IFolderTab> => {
  const { folderId } = useParams<EditFolderParams>();
  const { isOperatorPortal } = useDomainConfigContext();
  const { createMode } = useViewModesV2(formMode);
  const getLabel = partialTranslate('folder:tab');

  return [
    {
      label: getLabel('folder'),
      value: FolderDetailsTabEnum.FOLDER,
      content: <FolderTab />,
      icon: 'InfoIcon',
      showAlways: true
    },
    {
      label: getLabel('basicData'),
      value: FolderDetailsTabEnum.BASIC_DATA,
      icon: 'InfoIcon',
      content: <BasicDataTab additionalContent={additionalContent} />
    },
    {
      label: getLabel('subject'),
      value: FolderDetailsTabEnum.SUBJECT,
      icon: 'FacadeIcon',
      content: <SubjectTab refetch={refetchSubject} />
    },
    {
      label: getLabel('enterpreneurs'),
      value: FolderDetailsTabEnum.ENTERPRENEURS,
      icon: 'PeoplesIcon',
      content: <EnterpreneursTab />,
      hideTabHeader: true
    },
    {
      label: getLabel('addresses'),
      value: FolderDetailsTabEnum.ADDRESSES,
      icon: 'MailboxIcon',
      content: (
        <AddressesTab
          isCountryFieldAvaliable={!createMode && folderType === DomainDictionaryEntry.FOLDER_TYPE.MZ}
          isMainAddressEditable={isMainAddressEditable}
        />
      )
    },
    {
      label: getLabel('proxies'),
      value: FolderDetailsTabEnum.PROXIES,
      icon: 'GroupOfPeopleIcon',
      content: <ProxiesTab folderType={folderType} />,
      hideTabHeader: true
    },
    {
      label: getLabel('certificateS'),
      value: FolderDetailsTabEnum.CERTIFICATE_S,
      icon: 'SCertificatesIcon',
      content: <SCertificatesTab areHeaderActionsHidden />,
      hideTabHeader: true
    },
    {
      label: getLabel('foreignPermissions'),
      value: FolderDetailsTabEnum.FOREIGN_PERMISSIONS,
      icon: 'ForeignPermitsIcon',
      content: <ForeignPermissionsTab />,
      hideTabHeader: true
    },
    {
      label: getLabel('singleAuthorizations'),
      value: FolderDetailsTabEnum.SINGLE_AUTHORIZATION,
      icon: 'ForeignPermitsIcon',
      content: <SingleAuthorizationTab />
    },
    {
      label: getLabel('ekmt'),
      value: FolderDetailsTabEnum.EKMT,
      icon: 'ForeignPermitsIcon',
      content: <EkmtTab />
    },
    {
      label: getLabel('orders'),
      value: FolderDetailsTabEnum.ORDERS,
      icon: 'BusIcon',
      content: <OrdersTab />,
      hideTabHeader: true
    },
    {
      label: getLabel('permissions'),
      value: FolderDetailsTabEnum.PERMISSIONS,
      icon: 'KeyIcon',
      content: <PermissionsTab folderId={folderId} folderType={folderType} hasInitialValue />,
      isSaveDisabled: true
    },
    {
      label: getLabel('applications'),
      value: FolderDetailsTabEnum.APPLICATIONS,
      icon: 'ApplicationsIcon',
      content: <ApplicationsTab />,
      hideTabHeader: true
    },
    {
      label: getLabel('financialSecurity'),
      value: FolderDetailsTabEnum.FINANCIAL_SECURITY,
      icon: 'WalletIcon',
      content: <FinancialSecurityTab />,
      hideTabHeader: true
    },
    {
      label: getLabel('transportManager'),
      value: FolderDetailsTabEnum.TRANSPORT_MANAGER,
      hideTabHeader: true,
      icon: 'TieManIcon',
      content: (
        <TransportManagersTable
          folderId={folderId}
          queryParams={isOperatorPortal ? { folderIdIn: folderId ? [folderId] : [] } : { folderIdEq: folderId }}
          visibleColumns={[
            TransportManagersColumnsEnum.NAME,
            TransportManagersColumnsEnum.SURNAME,
            TransportManagersColumnsEnum.CERTIFICATE_NUMBER,
            TransportManagersColumnsEnum.PERIOD_OF_VALIDITY,
            TransportManagersColumnsEnum.REPUTATION_KEY,
            TransportManagersColumnsEnum.MANAGEMENT_PERIOD,
            TransportManagersColumnsEnum.TYPE_KEY,
            TransportManagersColumnsEnum.STATUS_KEY,
            ...(isOperatorPortal ? [TransportManagersColumnsEnum.MODIFIED, TransportManagersColumnsEnum.MODIFIER] : [])
          ]}
          permissionsKeysObject={{
            EDIT: DomainUIElementEnum.FOLDER_TRANSPORT_MANAGER_EDIT_BUTTON,
            EXPIRE: DomainUIElementEnum.FOLDER_TRANSPORT_MANAGER_EXPIRE_BUTTON,
            RESTORE: DomainUIElementEnum.FOLDER_TRANSPORT_MANAGER_RESTORE_BUTTON,
            PREVIEW: DomainUIElementEnum.FOLDER_TRANSPORT_MANAGER_VIEW,
            CHECK_REPUTATION: DomainUIElementEnum.FOLDER_TRANSPORT_MANAGERS_CHECK_IN_KREPTD_BUTTON,
            SHOW_REPUTATION: DomainUIElementEnum.FOLDER_TRANSPORT_MANAGERS_SHOW_REPUTATION_BUTTON
          }}
          visibleActionButtons={
            isOperatorPortal
              ? [
                  TransportManagersActionButtonsEnum.PREVIEW,
                  TransportManagersActionButtonsEnum.EDIT,
                  TransportManagersActionButtonsEnum.EXPIRE,
                  TransportManagersActionButtonsEnum.RESTORE
                ]
              : []
          }
          isCollapsable
        />
      )
    },
    {
      label: getLabel('drivers'),
      value: FolderDetailsTabEnum.DRIVERS,
      icon: 'DriversIcon',
      content: <DriversTab />,
      hideTabHeader: true
    },
    {
      label: getLabel('vehicles'),
      value: FolderDetailsTabEnum.VEHICLES,
      icon: 'TruckIcon',
      content: <VehicleTab folderTypeKey={folderType} folderNumber={folderNumber} />,
      hideTabHeader: true
    },
    {
      label: getLabel('exploitationBases'),
      value: FolderDetailsTabEnum.EXPLOATATION_BASES,
      icon: 'MarkerTruckIcon',
      content: <ExploitationBaseTab />,
      hideTabHeader: true
    },
    {
      label: getLabel('insurancePolicies'),
      value: FolderDetailsTabEnum.INSURANCE_POLICIES,
      icon: 'ShieldLockIcon',
      content: <InsurancePoliciesTab />,
      hideTabHeader: true
    },
    {
      label: getLabel('suspensions'),
      value: FolderDetailsTabEnum.SUSPENSIONS,
      icon: 'JudgeHammerIcon',
      content: <SuspensionsTab />,
      hideTabHeader: true
    },
    {
      label: getLabel('rentals'),
      value: FolderDetailsTabEnum.RENTALS,
      icon: 'ApplicationsIcon',
      content: <RentalsTab />,
      hideTabHeader: true
    },
    {
      label: getLabel('proceedings'),
      value: FolderDetailsTabEnum.PROCEEDINGS,
      icon: 'ApproveIcon',
      content: <ProceedingsTab />,
      hideTabHeader: true
    },
    {
      label: getLabel('locks'),
      value: FolderDetailsTabEnum.LOCKS,
      icon: 'LockIcon',
      content: <LocksTab />,
      hideTabHeader: true
    },
    {
      label: getLabel('note'),
      value: FolderDetailsTabEnum.NOTE,
      content: (
        <NoteTab
          formMode={formMode}
          refetch={refetchFolder}
          customHandleChangeForNoteSection={customHandleChangeForNoteSection}
        />
      ),
      icon: 'NoteIcon',
      hideTabHeader: true
    },
    {
      label: getLabel('history'),
      value: FolderDetailsTabEnum.HISTORY,
      icon: 'HistoryIcon',
      content: <FolderHistoryTable />,
      hideTabHeader: true
    }
  ];
};
