import { FolderLimitUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

interface Params {
  folderLimitId: string;
  request: FolderLimitUpdateRequest;
}

function updateFolderLimit({ folderLimitId, request }: Params) {
  return API.folderLimits.updateFolderLimit(folderLimitId, request, {
    ...endpointsConfig.updateFolderLimit
  });
}

function useUpdateFolderLimitMutation() {
  return useMutation(updateFolderLimit);
}

export default useUpdateFolderLimitMutation;
