import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { FolderLimitDetails } from '@ibtm/domain';
import { number, object } from 'yup';

import { partialTranslate } from '@libs/common';
import { Dialog, FormV2Context, GridLayout, NumberInputField, TextInputField, typedNameV2 } from '@libs/common/v2';

import { useUpdateLimitSuspendedActionMutation } from '@libs/domain/folder';

const translate = partialTranslate('folder:details.tab.singleAuthorization.section.basicLimit');

interface Props {
  open: boolean;
  onCloseDialog: () => void;
  data: FolderLimitDetails;
  refetch: () => void;
}

function SuspendOrPostponeLimitModal({ open, onCloseDialog, refetch, data }: Props) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();

  const { mutate: updateLimitSuspended, isLoading } = useUpdateLimitSuspendedActionMutation();

  const validationSchema = () =>
    object({
      suspended: number().required()
    });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    reset,
    getValues,
    trigger,
    unregister
  } = useForm<Partial<FolderLimitDetails>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema())
  });
  const limit = watch('limit');

  useEffect(() => {
    reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleUpdateLimitLock = (values: Partial<FolderLimitDetails>) => {
    updateLimitSuspended(
      {
        folderLimitId: data.id,
        updateRequest: {
          suspended: values.suspended,
          version: data.version
        }
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('folder:details.tab.singleAuthorization.section.basicLimit.message.suspend'));
          onCloseDialog();
          refetch();
        }
      }
    );
  };

  const formValues = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <FormV2Context.Provider value={formValues}>
      <form onSubmit={handleSubmit(handleUpdateLimitLock)}>
        <Dialog
          title={translate('button.suspendOrPostponeLimit')}
          confirmText={t('action.save')}
          cancelText={t('action.cancel')}
          onConfirm={handleSubmit(handleUpdateLimitLock)}
          onCancel={onCloseDialog}
          isConfirmLoading={isLoading}
          isOpen={open}
        >
          <GridLayout itemProps={{ xs: 12 }}>
            <TextInputField
              name={typedNameV2<FolderLimitDetails>('limit')}
              label={translate('labels.limit')}
              isDisabled
            />
            <NumberInputField
              name={typedNameV2<FolderLimitDetails>('suspended')}
              label={translate('labels.suspended')}
              max={limit}
              isRequired
            />
          </GridLayout>
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
}

export default SuspendOrPostponeLimitModal;
