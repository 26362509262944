import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { Typography } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

interface IProps {
  version: string;
  isVersionPrefixHidden?: boolean;
  align?: 'center' | 'left';
  appOwnerName?: string;
  showVersionInNavigation?: boolean;
  isUserLogged?: boolean;
  isBuildVersionVisible?: boolean;
  isWhiteText?: boolean;
}

function VersionText({
  version,
  isVersionPrefixHidden,
  showVersionInNavigation,
  align,
  appOwnerName,
  isUserLogged,
  isBuildVersionVisible,
  isWhiteText
}: IProps) {
  const [t] = useTranslation();
  const classes = useStyles({ isFooter: !isUserLogged });

  return (
    <Typography
      themeVariant="textXs.medium"
      className={classes.appVersion}
      align={align || (isUserLogged ? 'center' : 'left')}
      component="p"
      isWhiteText={isWhiteText}
    >
      {isUserLogged ? (
        <>
          {showVersionInNavigation && !isVersionPrefixHidden && (
            <>
              {t('app:business')}
              {isBuildVersionVisible && ', '}
            </>
          )}
          {showVersionInNavigation && appOwnerName && <>{appOwnerName}&nbsp;</>}
        </>
      ) : (
        <>
          {t('app:business')}, {t('app:portalOwner')}&nbsp;
        </>
      )}
      {version ? `v${version} ` : ''}
    </Typography>
  );
}

const useStyles = makeStyles<Theme, { isFooter: boolean }>(theme => ({
  appVersion: {
    marginLeft: ({ isFooter }) => !isFooter && important('0.4rem'),
    letterSpacing: ({ isFooter }) => !isFooter && '0.8px',
    whiteSpace: 'nowrap',
    paddingBottom: ({ isFooter }) => !isFooter && 8,
    paddingTop: ({ isFooter }) => !isFooter && 10,
    fontSize: ({ isFooter }) => isFooter && important('1.4rem'),
    color: ({ isFooter }) => isFooter && important(theme.palette.grey[700])
  }
}));

export default VersionText;
