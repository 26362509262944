import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

async function copyVehiclesToApplication({
  applicationId,
  vehicleIds,
  folderIds
}: {
  applicationId: string;
  vehicleIds: string[];
  folderIds?: string[];
}) {
  const response = await API.application.copyFolderVehiclesToAssignedApplication(
    applicationId,
    { vehicleIds, folderIds },
    {
      ...endpointsConfig.copyFolderVehiclesToAssignedApplication
    }
  );
  return response;
}

async function copyVehiclesToApplicationClient({
  applicationId,
  vehicleIds,
  folderIds
}: {
  applicationId: string;
  vehicleIds: string[];
  folderIds?: string[];
}) {
  const response = await API.client.application.copyFolderVehiclesToAssignedApplication(
    applicationId,
    '',
    '',
    { vehicleIds, folderIds },
    {
      ...endpointsConfig.copyFolderVehiclesToAssignedApplication
    }
  );
  return response;
}

function useCopyFolderVehiclesMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(copyVehiclesToApplicationClient, copyVehiclesToApplication));
}

export default useCopyFolderVehiclesMutation;
