import { useTranslation } from 'react-i18next';
import { DriverDetails } from '@ibtm/domain';

import { TableButton } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { UIElementNameEnum } from '@libs/permission';

import { DriversQueryKeysEnum } from '@libs/domain/drivers/api';
import { useExpireDialog } from '@libs/domain/drivers/hooks';

interface IProps {
  data: DriverDetails;
  driverApplicationId?: string;
  actionKey?: UIElementNameEnum;
}

function ExpireDriverButton({ data, driverApplicationId, actionKey }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();

  const { openExpireDialog } = useExpireDialog({
    onSuccess: () => {
      queryCache.invalidateQueries(DriversQueryKeysEnum.DRIVERS_LIST);
    },
    driverApplicationId
  });

  return <TableButton onClick={() => openExpireDialog(data)} label={t('action.expire')} actionKey={actionKey} />;
}

export default ExpireDriverButton;
