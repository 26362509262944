import { QueryConfig, useQuery } from 'react-query';
import { CabotageDetails } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import ApplicationQueryKeysEnum from './ApplicationQueryKeysEnum';

export const getApplicationCabotage = async (applicationId: string) => {
  const { data } = await API.applicationCabotage.getApplicationCabotage(applicationId, {
    ...endpointsConfig.getApplicationCabotage
  });
  return data;
};

export default function useApplicationCabotageQuery(
  applicationId: string,
  queryConfig: QueryConfig<CabotageDetails, unknown> = {}
) {
  return useQuery<CabotageDetails>(
    [ApplicationQueryKeysEnum.APPLICATION_CABOTAGE, applicationId],
    () => getApplicationCabotage(applicationId),
    queryConfig
  );
}
