import { GridLayout } from '@libs/common/v2';

import { DecisionSection, SecondTierSection, StartSection, SuspensionSection } from '../sections';

function ImposingPenaltyTab() {
  return (
    <div className="overflow-hidden">
      <GridLayout itemProps={{ xs: 12 }}>
        <StartSection />
        <SuspensionSection />
        <DecisionSection hiddenFields={['decisionPermissionSuspensionFrom', 'decisionPermissionSuspensionTo']} />
        <SecondTierSection />
      </GridLayout>
    </div>
  );
}

export default ImposingPenaltyTab;
