import { CivicBoardRuleUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export type TUpdateTradeRulesRequest = {
  civicBoardRuleId: string;
  civicBoardRuleUpdateRequest: CivicBoardRuleUpdateRequest;
};

function tradeRulesEdit(requestData: TUpdateTradeRulesRequest) {
  return API.civicBoard.updateCivicBoardRule(requestData.civicBoardRuleId, requestData.civicBoardRuleUpdateRequest, {
    ...endpointsConfig.updateCivicBoardRule
  });
}

function useEditTradeRulesMutation(config) {
  return useMutation(tradeRulesEdit, config);
}

export default useEditTradeRulesMutation;
