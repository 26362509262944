import { useTranslation } from 'react-i18next';

import { NUMBER_OF_AUTOCOMPLETE_RESULTS, SelectWithFetchedOptionsField } from '@libs/common/v2';

import { API } from '@libs/notification/api';

import { MixedAccountsSearchRoles } from '@libs/domain/models';

interface IProps {
  fieldName: string;
  label?: string;
  roles?: MixedAccountsSearchRoles[];
  customErrorMessage?: string;
}

function UsersAutocompleteField({ fieldName, roles, label, customErrorMessage }: IProps) {
  const [t] = useTranslation();

  const getUsersList = (text: string) => {
    return API.user.accountController.search({
      page: 0,
      size: NUMBER_OF_AUTOCOMPLETE_RESULTS,
      roles,
      fullName: text,
      sort: []
    });
  };

  return (
    <SelectWithFetchedOptionsField
      fetchFunction={getUsersList}
      getOptionLabel={user =>
        user?.personalData?.name || user?.personalData?.surname
          ? `${user?.personalData?.name} ${user?.personalData?.surname}`
          : t('emptyMark')
      }
      fetchedValueParser={option => option.id}
      label={label}
      name={fieldName}
      customErrorMessage={customErrorMessage}
      isDisabled={false}
      isRequired={false}
      isMultiple={false}
      stringValue
    />
  );
}

export default UsersAutocompleteField;
