import _ from 'lodash';
import * as Yup from 'yup';

import { TFunction } from '@libs/common';
import { initializeCustomValidator, initializeValidators } from '@libs/common/v2/form/validation';

function initializeYup(t: TFunction) {
  Yup.setLocale({
    mixed: {
      default: t('validation:default'),
      required: t('validation:required'),
      notType: ({ type }) => {
        if (type === 'date') {
          return t('validation:dateTypeError');
        }
        return t('validation:typeError');
      }
    },
    string: {
      min: t('validation:minlength'),
      max: ({ max }) => {
        return t('validation:maxlength', {
          max,
          unit: max === 1 ? t('other:unit.character') : t('other:unit.characters')
        });
      },
      email: t('validation:email'),
      matches: t('validation:pattern', { pattern: '000 000 000' }),
      length: ({ length }) => t<any>('validation:fieldLength', { length })
    },
    number: {
      min: t('validation:min'),
      max: t('validation:max'),
      moreThan: t('validation:moreThan'),
      integer: t('validation:integer'),
      positive: t('validation:positive')
    },
    array: {
      min: ({ min }) => t('validation:arrayMin', { min })
    }
  });
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    'requiredIf',
    function checkRequiredIf(currentFieldName: string, otherFieldNames: string[], translationPath: string) {
      let fieldNamesTranslated = '';
      otherFieldNames.forEach(fieldName => {
        fieldNamesTranslated += `${t<any>(`${translationPath}.${fieldName}`)}, `;
      });
      fieldNamesTranslated = fieldNamesTranslated.substring(0, fieldNamesTranslated.length - 2);
      return this.test(
        'requiredIf',
        t<any>(`validation:${otherFieldNames.length > 1 ? 'requiredIfPlural' : 'requiredIf'}`, {
          fieldName: fieldNamesTranslated
        }),
        function checkDeepRequiredIf() {
          const isOneOfOtherFieldEmpty = otherFieldNames.some((fieldName: string) => {
            return this.parent[fieldName];
          });
          return !(!this.parent[currentFieldName] && isOneOfOtherFieldEmpty);
        }
      );
    }
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    'oneOfRequired',
    function checkOneOfRequired(fieldName: string, secondFieldName: string, translationPath: string) {
      return this.test(
        'oneOfRequired',
        t('validation:requiredIfNot', { fieldName: t<any>(`${translationPath}.${secondFieldName}`) }),
        function checkDeepOneOfRequired() {
          return !!this.parent[fieldName] || !!this.parent[secondFieldName];
        }
      );
    }
  );
  Yup.addMethod<Yup.StringSchema>(Yup.string, 'postCode', function checkPostCode() {
    return this.test('postCode', t('validation:invalidPostCode'), value => {
      return /\d{2}-\d{3}/.test(value);
    });
  });
  initializeCustomValidator(Yup);
  initializeValidators(Yup);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const originalRequiredMethod: Yup.StringSchema['required'] = Yup.string.prototype.required;
  Yup.string.prototype.required = function required(errorMessage: string) {
    return originalRequiredMethod
      .call(this, errorMessage)
      .test('custom-required', errorMessage, function customRequired(value: string | null) {
        const { createError } = this;
        if (value && !_.isEmpty(value.trim())) {
          return true;
        }
        return createError({
          message: errorMessage
        });
      });
  };
}

export default initializeYup;
