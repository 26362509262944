import { useTranslation } from 'react-i18next';
import {
  ApplicationDetails as ApplicationDetailsClient,
  ApplicationUpdateRequest as ApplicationUpdateRequestClient
} from '@ibtm/client-domain';
import {
  AddressCreateRequest,
  ApplicationDetails,
  ApplicationFlagDetails,
  ApplicationForeignPermissionVehiclesUpdateRequest,
  ApplicationPaymentUpdateRequest,
  ApplicationUpdateRequest,
  CabotageDetails,
  DecisionDetails,
  FinancialSecuritySummarySnapshot,
  ForeignPermissionApplicationUpdateRequest,
  ForeignPermissionTransportApplicationRecordsUpdateRequest,
  PermissionSearchFilter,
  SCertificateApplicationDataUpdateRequest,
  SubjectAddressDetails,
  SubjectContactAddressUpdateRequest,
  SubjectDetailsExtended
} from '@ibtm/domain';
import { AxiosResponse } from 'axios';
import _, { isEmpty } from 'lodash';

import { InputMode, SelectOption } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { AXIOS_SUCCESS_STATUS } from '@libs/common/v2/models';
import { getCalendarDate } from '@libs/common/v2/utils';

import { useContextSelector, useDictionaryTranslations } from '@libs/dictionary';
import { getMetaFormQueryKey } from '@libs/meta-form/services/useGetQuery';
import usePermissions from '@libs/permission/hooks/usePermissions';

import { createAddress, SubjectAddressDetailsUnion, useUpdateAddressMutation } from '@libs/domain/address';
import {
  ApplicationQueryKeysEnum,
  CabotageUpdateRequestClient,
  createUpdateApplicationCabotage,
  createUpdateApplicationDecision,
  editForeignPermissionVehicles,
  editPaymentData,
  getApplicationCabotage,
  getApplicationDecision,
  getApplicationForeignPermissionVehicles,
  getApplications,
  getForeignPermissionApplicationRecords,
  useApplicationAddressesQuery,
  useApplicationDetailsQuery,
  useCreateUpdateApplicationCabotageMutation,
  useEditApplicationMutation,
  useEditSCertificateMutation,
  useEditSubjectMutation,
  useUpdateApplicationForeignSpoPermissionRecordsMutation
} from '@libs/domain/application/api';
import {
  updateForeignPermissionApplication,
  updateForeignPermissionApplicationClient
} from '@libs/domain/application/api/mutations/useUpdateForeignPermissionApplicationMutation';
import useApplicationFlagsQuery from '@libs/domain/application/api/queries/useApplicationFlagsQuery';
import { PermissionsRecordsQueryKeysEnum } from '@libs/domain/application/api/queries/useGetForeignPermissionApplicationRecords';
import { AddressesInputMode } from '@libs/domain/application/components/tabs/AddressTab';
import {
  AddressFormData,
  Application,
  IApplicationApiRegistryCreateParams,
  SubjectUpdateRequestUnion
} from '@libs/domain/application/model';
import {
  AttachmentsQueryKeysEnum,
  getAttachments,
  IUpdateApplicationAttachments,
  IUpdateAttachmentFiles,
  updateApplicationAttachments,
  updateApplicationAttachmentsClient,
  updateAttachmentFiles,
  updateAttachmentFilesClient
} from '@libs/domain/application/shared/attachments/api';
import {
  createApplicationPermissionDuplicateApiRegistry,
  parseDecisionFormdata,
  prepareCreateAddressRequest,
  prepareUpdateAddressRequest,
  prepareUpdateSubjectAdditionalAddressRequest
} from '@libs/domain/application/utils';
import {
  DomainDictionaryEntry,
  DomainDictionaryEnum,
  PermissionEnum,
  useDomainConfigContext
} from '@libs/domain/config';
import { FinancialSecurityQueryKeysEnum } from '@libs/domain/financial-security';
import { getFolder, getFoldersForMetaForm } from '@libs/domain/folder';
import { ForeignNameKeyBaseEnum } from '@libs/domain/foreign-permission';
import { getLicenses, LicenseStatusEnum } from '@libs/domain/license';
import { PermissionStatusEnum, PermissionTypeEnum } from '@libs/domain/permission';
import { getPermissions } from '@libs/domain/permission/api';
import { getApplicationPricing, getApplicationPricingClient } from '@libs/domain/pricing';
import { PricingQueryKeysEnum } from '@libs/domain/pricing/api/query/PricingQueryKeysEnum';
import { getProxies, getProxiesClient } from '@libs/domain/proxy';
import {
  getPartnershipPartners,
  getPartnershipPartnersClient,
  SubjectQueryKeysEnum,
  useSubjectDetailsExtendedQuery
} from '@libs/domain/subject';
import { editTransitFrequency, TGetTransitScheduleStops } from '@libs/domain/transit-schedule/api';

export const createApiRegistry = ({
  applicationId,
  GET_APPLICATION_PARAMS,
  applicationData,
  updateApplicationCabotage,
  isClientPortal,
  updateApplicationQuery,
  updateSubjectQuery,
  folderId,
  transferredFoldersIds,
  parseAddressOption,
  t,
  updateAddressQuery,
  queryConfig,
  updateSCertificate,
  dictionaryEntries,
  updateSpoForeignPermission,
  applicationFlags,
  getApplicationAddresses,
  queryCache,
  getApplicationAddressSelectOptions
}: IApplicationApiRegistryCreateParams) => ({
  GET_APPLICATION: {
    // Pobieramy i zwracamy dane z cache z query wyżej, bez wywoływania requestu
    request: async () => {
      const query = queryCache.getQuery<ApplicationDetails>([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
      const minRefetchDelay = 10 * 1000;
      if (query.isStaleByTime(minRefetchDelay)) {
        await query.refetch();
      }

      if (query?.state?.data?.sendToAddressTypeKey) {
        return {
          ...query?.state?.data,
          sendToAddress: {
            ...query?.state?.data.sendToAddress,
            ...parseAddressOption({
              typeKey: query?.state?.data?.sendToAddressTypeKey
            })[0]
          }
        };
      }

      return query?.state?.data;
    },
    params: GET_APPLICATION_PARAMS
  },
  GET_APPLICATION_PRICING: {
    request: async () => {
      return isClientPortal ? getApplicationPricingClient(applicationId) : getApplicationPricing(applicationId);
    },
    params: { applicationId }
  },
  GET_APPLICATION_SUBJECT: {
    // Pobieramy i zwracamy dane z cache z query wyżej, bez wywoływania requestu
    request: async () =>
      // w tym przypadku musimy użyć type casting do Promise<SubjectDetailsExtended> aby TypeScript inferował typ w definicji meta-form
      queryCache.getQueryData([
        SubjectQueryKeysEnum.SUBJECT_EXTENDED,
        applicationData?.subject?.id
      ]) as Promise<SubjectDetailsExtended>,
    params: GET_APPLICATION_PARAMS
  },
  GET_APPLICATION_CABOTAGE: {
    request: async () => {
      const data = await getApplicationCabotage(applicationId);
      return data;
    },
    params: { applicationId }
  },
  UPDATE_APPLICATION_CABOTAGE: async (
    formData: Omit<CabotageUpdateRequestClient, 'countryCodeKeys'> & {
      countryCodeKeys: SelectOption<string>[];
    }
  ) => {
    const cabotage = (applicationData as Application & { cabotage: CabotageDetails })?.cabotage;

    const parsedData: CabotageUpdateRequestClient = {
      ...formData,
      countryCodeKeys: formData?.countryCodeKeys?.map(item => item.value)
    };

    if (isClientPortal && cabotage?.id) {
      return updateApplicationCabotage(
        {
          applicationId,
          ...parsedData,
          cabotageId: cabotage?.id,
          version: cabotage?.version
        },
        {
          throwOnError: true
        }
      )
        .then(res => res)
        .catch(err => err);
    }

    return createUpdateApplicationCabotage({ applicationId, ...parsedData })
      .then(res => res)
      .catch(err => err);
  },
  GET_FINANCIAL_SECURITY: {
    request: async () => getApplicationForeignPermissionVehicles(applicationId),
    params: { applicationId }
  },
  UPDATE_FINANCIAL_SECURITY: async (formData: ApplicationForeignPermissionVehiclesUpdateRequest) => {
    const financialSummaryQueryKey = [FinancialSecurityQueryKeysEnum.FINANCIAL_SECURITY_SUMMARY, applicationId];
    const {
      state: { data, isFetching }
    } = queryCache.getQuery<FinancialSecuritySummarySnapshot>(financialSummaryQueryKey);

    if (!data || isFetching) {
      return Promise.reject(new Error(t('applications:message.financialSecurityIsNotCalculatedYet')));
    }

    const response = await editForeignPermissionVehicles({
      applicationId,
      formData: { ...formData, version: data.applicationVersion }
    });
    queryCache.setQueryData([getMetaFormQueryKey('GET_FINANCIAL_SECURITY'), { applicationId }], response?.data);
    queryCache.invalidateQueries(financialSummaryQueryKey);
    queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);

    return response;
  },
  GET_FOLDER_TO_TRANSFER: {
    request: async (requestKey, params) => {
      const response = await getFoldersForMetaForm(null, { typeKeyIn: [applicationData.folder.typeKey], ...params });
      return response;
    },
    params: null
  },
  UPDATE_APPLICATION: async (formValues: ApplicationUpdateRequest & { newSendToAddressTypeKey?: string }) => {
    let formData = formValues as ApplicationUpdateRequest &
      ApplicationUpdateRequestClient & { newSendToAddressTypeKey?: string };

    if (formValues?.dateOfSubjectDeath && !formValues?.version) {
      const application = (await queryCache.getQueryData([
        ApplicationQueryKeysEnum.APPLICATION,
        applicationId
      ])) as ApplicationDetails;

      formData = {
        ...formData,
        version: application?.version,
        submissionReceiptDate: application?.submissionReceiptDate
      };
    }

    formData = {
      ...formData,
      sendToAddressTypeKey: formData?.newSendToAddressTypeKey || formData?.sendToAddressTypeKey
    };

    const response = await updateApplicationQuery({ formData, applicationId }, { throwOnError: true }).catch(
      err => err
    );
    const data = response?.data;
    if (data) {
      queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, data.id]);
      queryCache.invalidateQueries([SubjectQueryKeysEnum.SUBJECT_EXTENDED]);
    }
    return response;
  },
  UPDATE_APPLICATION_TRAVEL: async (formValues: ApplicationUpdateRequest) => {
    const formData = { ...formValues, ...applicationData } as ApplicationUpdateRequest &
      ApplicationUpdateRequestClient &
      Application;
    const response = await updateApplicationQuery(
      {
        formData,
        applicationId
      },
      {
        throwOnError: true
      }
    ).catch(err => err);
    const data = response?.data;
    if (data) {
      queryCache.setQueryData([ApplicationQueryKeysEnum.APPLICATION, data.id], data, queryConfig);
    }

    return response;
  },
  UPDATE_SUBJECT: async (
    formData: Omit<SubjectUpdateRequestUnion, 'contactAddresses' | 'address.voivodeshipKey'> & {
      contactAddresses: SubjectContactAddressUpdateRequest;
    }
  ) => {
    const hasRequiredAddressSection = [
      DomainDictionaryEntry.LEGAL_NATURE_KEY.CIVIL_PARTNERSHIP,
      DomainDictionaryEntry.LEGAL_NATURE_KEY.CIVIL_PARTNERSHIP_AGREEMENT
    ].includes(formData?.legalFormKey);

    const isPermissionData = !!formData?.permissionData;
    const isLicenseData = !!formData?.licenseData;

    if (!hasRequiredAddressSection && formData?.licenseData && formData?.permissionData) {
      formData = _.omit(formData, ['licenseData', 'permissionData']);
    }

    const permissionData = prepareUpdateSubjectAdditionalAddressRequest(formData?.permissionData);
    const licenseData = prepareUpdateSubjectAdditionalAddressRequest(formData?.licenseData);
    const isPermissionAddressSameAsMainAddress = permissionData.isSameAsMainAddress;
    const isLicenseAddressSameAsMainAddress = licenseData.isSameAsMainAddress;

    const partnershipPartnersData = await (isClientPortal
      ? getPartnershipPartnersClient(null, { subjectId: applicationData?.subject?.id })
      : getPartnershipPartners(null, { subjectId: applicationData?.subject?.id }));

    const response = await updateSubjectQuery(
      {
        subjectId: applicationData.subject.id,
        formData: {
          ...formData,
          partnershipPartnerIds: partnershipPartnersData?.partnershipPartners?.map(i => i.id) || [],
          ...(isLicenseData &&
            hasRequiredAddressSection && {
              licenseData: isLicenseAddressSameAsMainAddress
                ? { isSameAsMainAddress: true, nip: licenseData?.nip, name: licenseData?.name }
                : licenseData
            }),
          ...(isPermissionData &&
            hasRequiredAddressSection && {
              permissionData: isPermissionAddressSameAsMainAddress
                ? { isSameAsMainAddress: true, nip: permissionData?.nip, name: permissionData?.name }
                : permissionData
            }),
          contactAddresses: [
            {
              ...formData?.contactAddresses,
              typeKey: DomainDictionaryEntry.ADDRESS_TYPE.MAIN,
              version: formData?.version
            }
          ]
        }
      },
      {
        throwOnError: true
      }
    ).catch(err => err);

    if (response?.data) {
      queryCache.invalidateQueries([SubjectQueryKeysEnum.SUBJECT_EXTENDED, response.data.id]);
      queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationData.id]);
    }

    return response;
  },
  GET_APPLICATION_ADDRESSES: {
    request: getApplicationAddressSelectOptions([
      DomainDictionaryEntry.ADDRESS_TYPE.MAIN,
      DomainDictionaryEntry.ADDRESS_TYPE.HQ,
      DomainDictionaryEntry.ADDRESS_TYPE.CORRESPONDENCE
    ]),
    params: { applicationId }
  },
  GET_APPLICATION_ADDRESSES_WITH_ASSOCIATION_ADDRESS: {
    request: getApplicationAddressSelectOptions([
      DomainDictionaryEntry.ADDRESS_TYPE.MAIN,
      DomainDictionaryEntry.ADDRESS_TYPE.HQ,
      DomainDictionaryEntry.ADDRESS_TYPE.CORRESPONDENCE,
      DomainDictionaryEntry.ADDRESS_TYPE.ASSOCIATION
    ]),
    params: { applicationId }
  },
  GET_APPLICATION_DECISION: {
    request: async () => {
      const data = await getApplicationDecision(null, applicationId);
      return data[0];
    },
    params: applicationId
  },
  UPDATE_CREATE_APPLICATION_DECISION: async formData => {
    const data = formData?.['application-outgoing-documents-additional-section-license-or-permission'] as {
      driverCertificate: DecisionDetails;
      license: DecisionDetails;
      permission: DecisionDetails;
    };
    const parsedData = parseDecisionFormdata(data);
    return Promise.all(
      parsedData.map(item => createUpdateApplicationDecision({ applicationId, decisionCreateOrUpdateRequest: item }))
    )
      .then(res => res)
      .catch(err => err);
  },
  GET_COMPANY_PROXIES: {
    request: async () => {
      const response = await (isClientPortal
        ? getProxiesClient(null, { applicationId: null, folderId: applicationData.folder.id })
        : getProxies(null, { applicationId: null, folderId: applicationData.folder.id }));
      return response.content;
    },
    params: { applicationId }
  },
  GET_APPLICATION_FLAGS: {
    request: async () => {
      const query = queryCache.getQuery([ApplicationQueryKeysEnum.APPLICATION_FLAGS, applicationId]);
      return query?.state?.data as any;
    },
    params: { applicationId }
  },
  GET_PERMISSIONS: {
    request: async (_, params: PermissionSearchFilter) => {
      const response = await getPermissions(null, {
        folderIdIn: [folderId, ...(transferredFoldersIds || [])],
        ...params
      });
      return response.content;
    },
    params: { applicationId }
  },
  GET_OWN_NEEDS_PERMISSIONS: {
    request: async (_, params: PermissionSearchFilter) => {
      const response = await getPermissions(null, {
        numberContains: params?.numberContains,
        typeKeyIn: [PermissionTypeEnum.OWN_NEEDS_PASSENGER_CARRIAGE, PermissionTypeEnum.OWN_NEEDS_GOODS_CARRIAGE],
        statusIn: [PermissionStatusEnum.ACTIVE],
        ...(folderId && { folderIdIn: [folderId, ...(transferredFoldersIds || [])] })
      });
      return response.content;
    },
    params: { applicationId }
  },
  GET_APPLICATION_ADDRESSES_WITH_SUCCESSOR: {
    request: async () => {
      const response = await getApplicationAddresses({ applicationIdIn: [applicationId] });
      const data: SubjectAddressDetailsUnion[] =
        ('content' in response && response.content) || ('addresses' in response && response.addresses) || [];
      const { apartmentNumber, city, postCity, postCode, propertyNumber, street } =
        applicationData?.licenseApplicationDetails?.successorTransferApplicationDataDetails?.successor ?? {};
      const successorAddres: Omit<AddressCreateRequest, 'voivodeshipKey'>[] & { label?: string } = !isEmpty(
        applicationData?.licenseApplicationDetails?.successorTransferApplicationDataDetails?.successor
      )
        ? [
            {
              apartmentNumber,
              city,
              postCity,
              postCode,
              propertyNumber,
              street,
              countryCodeKey: DomainDictionaryEntry.COUNTRY_CODE.POLAND,
              name: t('applications:additionalField.succesorAddress')
            }
          ]
        : [];

      return [
        ...successorAddres,
        ...parseAddressOption(data.find(address => address.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.MAIN)),
        ...parseAddressOption(data.find(address => address.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.HQ)),
        ...parseAddressOption(
          data.find(address => address.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.CORRESPONDENCE)
        )
      ];
    },
    params: { applicationId }
  },
  GET_APPLICATION_ADDRESSES_WITH_SUCCESION_MANAGER: {
    request: async () => {
      const response = await getApplicationAddresses({ applicationIdIn: [applicationId] });
      const data: SubjectAddressDetailsUnion[] =
        ('content' in response && response.content) || ('addresses' in response && response.addresses) || [];
      const { apartmentNumber, city, postCity, postCode, propertyNumber, street } =
        applicationData?.licenseApplicationDetails?.successorTransferApplicationDataDetails?.successor ?? {};
      const successionMangerAddress: Omit<AddressCreateRequest, 'voivodeshipKey'>[] & {
        label?: string;
      } = !isEmpty(applicationData?.licenseApplicationDetails?.successorTransferApplicationDataDetails?.successor)
        ? [
            {
              apartmentNumber,
              city,
              postCity,
              postCode,
              propertyNumber,
              street,
              countryCodeKey: DomainDictionaryEntry.COUNTRY_CODE.POLAND,
              name: t('applications:additionalField.successionMangerAddress')
            }
          ]
        : [];

      return [
        ...successionMangerAddress,
        ...parseAddressOption(data.find(address => address.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.MAIN)),
        ...parseAddressOption(data.find(address => address.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.HQ)),
        ...parseAddressOption(
          data.find(address => address.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.CORRESPONDENCE)
        )
      ];
    },
    params: { applicationId }
  },
  UPDATE_APPLICATION_ADDRESS: ({
    addresses
  }: {
    addresses: {
      main: AddressFormData;
      hq: AddressFormData;
      correspondence: AddressFormData;
      addressInputMode: AddressesInputMode;
    };
  }) => {
    const { CORRESPONDENCE, HQ, MAIN } = DomainDictionaryEntry.ADDRESS_TYPE;
    const { addressInputMode: inputMode } = addresses ?? {};
    const mainAddressRequest =
      inputMode?.mainAddress !== InputMode.VIEW &&
      (addresses?.main?.id
        ? updateAddressQuery(prepareUpdateAddressRequest(addresses?.main), {
            throwOnError: true
          })
        : createAddress(
            prepareCreateAddressRequest({
              applicationId: applicationData.id,
              typeKey: MAIN,
              address: addresses?.main
            })
          ));
    const hqAddressRequest =
      inputMode?.businessAddress !== InputMode.VIEW &&
      (addresses?.hq?.id
        ? updateAddressQuery(prepareUpdateAddressRequest(addresses?.hq), {
            throwOnError: true
          })
        : createAddress(
            prepareCreateAddressRequest({
              applicationId: applicationData.id,
              typeKey: HQ,
              address: addresses?.hq
            })
          ));
    const correspondenceAddressRequest =
      inputMode?.correspondenceAddress !== InputMode.VIEW &&
      (addresses?.correspondence?.id
        ? updateAddressQuery(prepareUpdateAddressRequest(addresses?.correspondence), {
            throwOnError: true
          })
        : createAddress(
            prepareCreateAddressRequest({
              applicationId: applicationData.id,
              typeKey: CORRESPONDENCE,
              address: addresses?.correspondence
            })
          ));

    return Promise.all([mainAddressRequest, hqAddressRequest, correspondenceAddressRequest]).then(res => {
      queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION_ADDRESSES]);
      queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
      return res.filter(Boolean);
    });
  },
  GET_APPLICATION_FOREIGN_PERMISSIONS: {
    request: async () => {
      const data = await getForeignPermissionApplicationRecords(null, { applicationId });
      return data;
    },
    params: applicationId
  },
  GET_APPLICATION_FOREIGN_PERMISSIONS_TYPE: {
    request: async () => {
      if (applicationData.typeKey === DomainDictionaryEntry.APPLICATION_TYPE?.foreignPermitEkmt) {
        return DomainDictionaryEntry.FOREIGN_PERMIT_TYPE.EKMT;
      }

      if (
        [
          DomainDictionaryEntry.APPLICATION_TYPE?.foreignPermitSingleTp,
          DomainDictionaryEntry.APPLICATION_TYPE?.foreignPermitSingleOp
        ].includes(applicationData.typeKey)
      ) {
        return DomainDictionaryEntry.FOREIGN_PERMIT_TYPE.SINGLE;
      }

      return null;
    }
  },
  GET_LICENCES: {
    request: async () => {
      const response = await getLicenses({
        ...(folderId && { folderIdIn: [folderId, ...(transferredFoldersIds || [])] }),
        statusKeyIn: [LicenseStatusEnum.ACTIVE, LicenseStatusEnum.PENDING]
      });
      const parsedContent = response.content.map(item => {
        const parsedLicence = {
          id: item.id,
          number: item.licenseNumber,
          validFrom: item.validFrom,
          validTo: item.validTo
        };
        return parsedLicence;
      });
      return parsedContent;
    },
    params: { applicationId }
  },
  GET_LOCKING_APPLICATIONS: {
    request: async () => {
      const { CANCELLED, WITHDRAWN, ACCOMPLISHED } = DomainDictionaryEntry.APPLICATION_STATUS;
      const response = await getApplications(null, {
        ...(folderId && { folderIdIn: [folderId, ...(transferredFoldersIds || [])] }),
        statusKeyIn: Object.values(DomainDictionaryEntry.APPLICATION_STATUS).filter(
          item => ![CANCELLED, WITHDRAWN, ACCOMPLISHED].includes(item)
        )
      });
      return response.content;
    },
    params: { applicationId }
  },
  GET_APPLICATIONS_FOR_CORRECTION: {
    request: async () => {
      const { ACCOMPLISHED } = DomainDictionaryEntry.APPLICATION_STATUS;
      const response = await getApplications(null, {
        ...(folderId && { folderIdIn: [folderId, ...(transferredFoldersIds || [])] }),
        statusKeyIn: Object.values(DomainDictionaryEntry.APPLICATION_STATUS).filter(item =>
          [ACCOMPLISHED].includes(item)
        )
      });
      return response.content;
    },
    params: { applicationId }
  },
  GET_ATTACHMENTS: {
    request: async () => getAttachments(null, { applicationId }),
    params: { applicationId }
  },
  UPDATE_MONEY_TRANSFER: async (formData: { moneyTransfer: ApplicationPaymentUpdateRequest }) => {
    const response = await editPaymentData({
      applicationId,
      formData: {
        accountingApproved: formData.moneyTransfer.accountingApproved
      }
    });
    queryCache.invalidateQueries([PricingQueryKeysEnum.APPLICATION_MONEY_TRANSFERS, { applicationId }]);
    queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);

    return response;
  },
  UPDATE_ATTACHMENTS: async (formData: IUpdateApplicationAttachments) => {
    const response = isClientPortal
      ? await updateApplicationAttachmentsClient(
          { applicationId, notes: formData.notes || '', attachments: formData.attachments || [] },
          queryCache
        )
      : await updateApplicationAttachments(
          {
            applicationId,
            notes: formData.notes || '',
            attachments: formData.attachments || []
          },
          queryCache
        );
    const { data } = response;
    if (data) {
      queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
      queryCache.setQueryData([AttachmentsQueryKeysEnum.ATTACHMENTS_LIST, { applicationId }], data);
    }
    queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
    return response;
  },
  UPDATE_ATTACHMENTS_MATRIX: async (formData: IUpdateAttachmentFiles) => {
    const response = isClientPortal
      ? await updateAttachmentFilesClient({ applicationId, attachments: formData.attachments }, queryCache)
      : await updateAttachmentFiles({ applicationId, attachments: formData.attachments }, queryCache);
    const { data } = response;
    if (data) {
      queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
      queryCache.setQueryData([AttachmentsQueryKeysEnum.ATTACHMENTS_LIST, { applicationId }], data);
    }
    queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
    return response;
  },
  UPDATE_PERMISSION_MATRIX: async (formData: {
    permissions: ForeignPermissionApplicationUpdateRequest & {
      typeKey: string;
    };
    applicationUpdateRequest: ApplicationUpdateRequest &
      ApplicationUpdateRequestClient & { newSendToAddressTypeKey?: string };
  }) => {
    const {
      permissions: { records: values, typeKey, association },
      applicationUpdateRequest
    } = formData;

    const isEKMTType = typeKey === DomainDictionaryEntry.FOREIGN_PERMIT_TYPE.EKMT;
    const records = isEKMTType
      ? null
      : Object.keys(values).map(key => {
          return {
            nameKey: ForeignNameKeyBaseEnum.RESOURCE_FORM_NAME + key,
            amount: values[key].amount ?? 0,
            notes: values[key].notes ?? '',
            resourceTypeId: values[key].resourceTypeId
          };
        });

    const requestData = {
      applicationId,
      foreignPermissionApplicationRecordsUpdateRequest: {
        association,
        records,
        applicationUpdateRequest: {
          ...applicationUpdateRequest,
          sendToAddressTypeKey:
            applicationUpdateRequest?.newSendToAddressTypeKey || applicationUpdateRequest?.sendToAddressTypeKey
        }
      }
    };

    const response = isClientPortal
      ? await updateForeignPermissionApplicationClient(requestData)
      : await updateForeignPermissionApplication(requestData);

    const { data } = response;
    if (data) {
      queryCache.invalidateQueries([PermissionsRecordsQueryKeysEnum.FOREIGN_PERMISSIONS_RECORDS, { applicationId }]);
      queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
    }
    return response;
  },
  UPDATE_TRANSIT_SCHEDULE_FREQUENCIES: async (formData: TGetTransitScheduleStops) => {
    const frequencies = formData['application-transit-schedule-collection']?.frequencies;

    if (frequencies.transitScheduleId) {
      const { data } = await editTransitFrequency({
        transitScheduleId: frequencies.transitScheduleId,
        frequencies: dictionaryEntries?.[DomainDictionaryEnum.TRANSIT_DIRECTION]?.entries.map(entry => ({
          directionTypeKey: entry.value,
          frequencyItems: frequencies?.[entry.value.split('.')?.slice(-1)[0]] ?? null
        }))
      });

      queryCache.setQueryData([ApplicationQueryKeysEnum.TRANSIT_FREQUENCIES, applicationId], data);
      return Promise.resolve(data)
        .then(() => ({ status: AXIOS_SUCCESS_STATUS }))
        .catch(err => err);
    }
    return null;
  },
  UPDATE_SCERTIFICATES: async (
    formData: SCertificateApplicationDataUpdateRequest & { newSendToAddressTypeKey?: string }
  ) => {
    const response = await updateSCertificate(
      {
        formData: {
          version: applicationData?.version,
          witd: formData['application-scertificates-application-section']?.witd
            .value as SCertificateApplicationDataUpdateRequest,
          ...formData,
          sendToAddressTypeKey: formData?.newSendToAddressTypeKey || formData?.sendToAddressTypeKey
        },
        applicationId
      },
      {
        throwOnError: true
      }
    );
    const { data } = response;
    if (data) {
      queryCache.setQueryData([ApplicationQueryKeysEnum.APPLICATION, applicationId], data);
    }

    return response;
  },
  UPDATE_SCERTIFICATES_REPRINT: async (formData: {
    misspellApplicationDataUpdateRequest: { description: string; dateOfIssue: string };
    submissionReceiptDate: string;
    version: number;
    scertificates: {
      witd: {
        value: boolean;
      };
    };
    receiptTypeKey: string;
    sendToAddressTypeKey: string;
    newSendToAddressTypeKey: string;
    recipientName: string;
    recipientSurname: string;
  }) => {
    const { misspellApplicationDataUpdateRequest, version, submissionReceiptDate, ...formValues } = formData;

    const responseApplication = await updateApplicationQuery(
      {
        formData: {
          version,
          submissionReceiptDate: getCalendarDate(submissionReceiptDate),
          misspellApplicationDataUpdateRequest: {
            description: misspellApplicationDataUpdateRequest?.description,
            dateOfIssue: getCalendarDate(misspellApplicationDataUpdateRequest?.dateOfIssue)
          },
          sendToAddressTypeKey: formValues?.newSendToAddressTypeKey || formValues?.sendToAddressTypeKey
        },
        applicationId
      },
      {
        throwOnError: true
      }
    );

    const dataApplication = responseApplication?.data;

    const responseSCertificate = await updateSCertificate(
      {
        formData: {
          version: dataApplication?.version,
          witd: formData.scertificates.witd.value,
          ...formValues,
          sendToAddressTypeKey: formValues?.newSendToAddressTypeKey || formValues?.sendToAddressTypeKey
        },
        applicationId
      },
      {
        throwOnError: true
      }
    );

    const data = responseSCertificate?.data;

    if (data) {
      queryCache.removeQueries([ApplicationQueryKeysEnum.APPLICATION, data.id]);
      queryCache.setQueryData([ApplicationQueryKeysEnum.APPLICATION, data.id], data, queryConfig);
      queryCache.invalidateQueries([SubjectQueryKeysEnum.SUBJECT_EXTENDED]);
    }
    return responseSCertificate;
  },
  IS_FOLDER_HAS_NOT_ACTIVE_LICENSE: {
    request: async () => {
      return !applicationFlags?.hasActiveLicense;
    },
    params: applicationFlags
  },
  IS_FOLDER_HAS_NOT_ARCHIVE_NUMBER: {
    request: async () => {
      return !!applicationData?.archiveNumber;
    },
    params: applicationId
  },
  GET_PRICING_DETAILS: {
    request: async () => {
      const request = isClientPortal ? getApplicationPricingClient : getApplicationPricing;
      const data = await request(applicationId);
      return data;
    },
    params: applicationId
  },
  GET_CONSTANTS: {
    request: async () => ({ receiptTypes: { other: DomainDictionaryEntry.RECEIPT_TYPE.POST } }),
    params: null
  },
  UPDATE_SPO_FOREIGN_PERMISSION_RECORDS: async (
    formData: ForeignPermissionTransportApplicationRecordsUpdateRequest
  ) => {
    const response = updateSpoForeignPermission({ applicationId, formData });

    return Promise.resolve<AxiosResponse>(response).then(res => {
      if (res?.data) {
        queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
      }
      return res;
    });
  },
  GET_PERMISSIONS_SPO: {
    request: async (_, params: PermissionSearchFilter) => {
      const response = await getPermissions(null, {
        numberContains: params?.numberContains,
        folderIdIn: [folderId, ...(transferredFoldersIds || [])],
        statusIn: [DomainDictionaryEntry.PERMISSION_STATUS.ACTIVE],
        typeKeyIn: params?.typeKeyIn
      });
      return response.content;
    },
    params: { applicationId }
  },
  GET_FOLDER_DETAILS: {
    request: async () => {
      const data = await getFolder(null, folderId);
      return data;
    },
    params: folderId
  }
});

function useApplicationApiRegistry(
  applicationData: Application | ApplicationDetailsClient,
  applicationFlags?: ApplicationFlagDetails
) {
  const queryCache = useQueryCache();
  const { translate } = useDictionaryTranslations();
  const [t] = useTranslation();
  const { isClientPortal } = useDomainConfigContext();
  const { hasPermission } = usePermissions();
  const hasSubjectDataPermission = hasPermission(PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_VIEW);

  const applicationId = applicationData?.id;
  const folderId = applicationData?.folder?.id;
  const folderTypekey = applicationData?.folder?.typeKey;
  const transferredFoldersIds =
    applicationData?.licenseApplicationDetails?.transferApplicationDataDetails?.folders?.map(folder => folder.id);
  const subjectId = applicationData.subject?.id;
  const dictionaryEntries = useContextSelector(({ context }) => context.context.data.dictionary);
  const parseAddressOption = (addres: SubjectAddressDetails) =>
    !isEmpty(addres)
      ? [
          {
            label: translate(DomainDictionaryEnum.ADDRESS_TYPE, addres.typeKey),
            ...addres
          }
        ]
      : [];

  const [updateAddressQuery] = useUpdateAddressMutation();
  const [updateApplicationQuery] = useEditApplicationMutation();
  const [updateSubjectQuery] = useEditSubjectMutation();
  const [updateSCertificate] = useEditSCertificateMutation();
  const [updateSpoForeignPermission] = useUpdateApplicationForeignSpoPermissionRecordsMutation();
  const [updateApplicationCabotage] = useCreateUpdateApplicationCabotageMutation();
  const { refetch: getApplicationAddresses } = useApplicationAddressesQuery(
    { applicationIdIn: [applicationId] },
    { enabled: false }
  );
  const queryConfig = { staleTime: 1000 * 60 * 3 };
  const GET_APPLICATION_PARAMS = { applicationId };
  // Korzystamy z query wywoływanego wyżej w ApplicationDetailsPage
  useApplicationDetailsQuery(applicationId, {
    ...queryConfig,
    onSuccess: applicationDetails => {
      // Ustawiamy zwrócone dane w cache meta-form aby nie wywoływać drugiego requestu po te same dane z meta-form
      queryCache.setQueryData([getMetaFormQueryKey('GET_APPLICATION'), GET_APPLICATION_PARAMS], applicationDetails);
    }
  });

  useSubjectDetailsExtendedQuery(subjectId, {
    ...queryConfig,
    enabled: Boolean(subjectId && hasSubjectDataPermission),
    onSuccess: subjectData => {
      // Ustawiamy zwrócone dane w cache meta-form aby nie wywoływać drugiego requestu po te same dane z meta-form
      queryCache.setQueryData([getMetaFormQueryKey('GET_APPLICATION_SUBJECT'), GET_APPLICATION_PARAMS], subjectData);
    }
  });

  useApplicationFlagsQuery(applicationId, {
    ...queryConfig,
    onSuccess: applicationFlags => {
      // Ustawiamy zwrócone dane w cache meta-form aby nie wywoływać drugiego requestu po te same dane z meta-form
      queryCache.setQueryData([getMetaFormQueryKey('APPLICATION_FLAGS'), GET_APPLICATION_PARAMS], applicationFlags);
    }
  });

  const getApplicationAddressSelectOptions = (addressTypeKeys: Array<string>) => async () => {
    const response = await getApplicationAddresses();
    const data: SubjectAddressDetailsUnion[] =
      ('content' in response && response.content) || ('addresses' in response && response.addresses) || [];

    return addressTypeKeys
      .map(typeKey => parseAddressOption(data.find(address => address.typeKey === typeKey))[0])
      .filter(Boolean);
  };

  const apiRegistry = {
    ...createApiRegistry({
      applicationData,
      applicationId,
      dictionaryEntries,
      folderId,
      transferredFoldersIds,
      GET_APPLICATION_PARAMS,
      isClientPortal,
      parseAddressOption,
      queryConfig,
      t,
      updateAddressQuery,
      updateApplicationCabotage,
      updateApplicationQuery,
      updateSCertificate,
      updateSpoForeignPermission,
      updateSubjectQuery,
      applicationFlags,
      getApplicationAddresses,
      queryCache,
      getApplicationAddressSelectOptions
    }),
    ...createApplicationPermissionDuplicateApiRegistry({ folderTypekey, folderId, transferredFoldersIds })
  };

  return { apiRegistry };
}

export default useApplicationApiRegistry;
