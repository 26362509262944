import { QueryConfig, useQuery } from 'react-query';
import { ForeignPermissionWithSettlementDetails } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { ForeignPermissionQueryEnum } from '../../model';
import { endpointsConfig } from '../endpoints-config';

export const getDetailsWithSettlement = async (_, foreignPermissionId: string) => {
  const { data } = await API.foreignPermissions.getForeignPermissionDetailsWithSettlement(foreignPermissionId, {
    ...endpointsConfig.getSingleUseSubmissionItemDetailsPage
  });
  return data;
};

function useEkmtPermitFormQuery(
  foreignPermissionId: string,
  queryConfig: QueryConfig<ForeignPermissionWithSettlementDetails, unknown> = {}
) {
  return useQuery(
    [ForeignPermissionQueryEnum.PERMISSION_DETAILS_WITH_SETTLEMENT, foreignPermissionId],
    getDetailsWithSettlement,
    queryConfig
  );
}
export default useEkmtPermitFormQuery;
