import { EkmtCarnetSettlementDetails, ForeignPermissionDetails } from '@ibtm/domain';

import { useDialog } from '@libs/common/v2';

import { CarnetSettlementsEKMTForm, SettlePermitEKMTForm, SettleSinglePermitForm } from '../components';
import { ReceiveReturnPermissionForm } from '../components/forms';
import { ClientForeignPermissions, PermitActionTypeEnum, ReleasedPermitsSnapshot } from '../model';

interface SinglePermitParams {
  foreignPermissionId?: string;
  selectedPermitIds?: string[];
  selectedPermits?: ReleasedPermitsSnapshot[];
  initialData?: ForeignPermissionDetails;
  onSuccess?: () => void;
}

interface EkmtPermitParams {
  foreignPermissionId?: string;
  selectedPermitIds?: string[];
  selectedPermits?: ReleasedPermitsSnapshot[];
  onSuccess?: () => void;
  initialData?: EkmtCarnetSettlementDetails;
  hasDefaultDestiny?: boolean;
}

function usePermitDialog() {
  const { openDialog } = useDialog();

  const openEKMT = ({
    foreignPermissionId,
    selectedPermitIds,
    selectedPermits,
    onSuccess,
    initialData,
    hasDefaultDestiny
  }: EkmtPermitParams) => {
    openDialog(({ closeDialog }) => {
      return (
        <SettlePermitEKMTForm
          foreignPermissionId={foreignPermissionId}
          selectedPermitIds={selectedPermitIds}
          selectedPermits={selectedPermits}
          initialData={initialData}
          closeDialog={closeDialog}
          onSuccess={onSuccess}
          hasDefaultDestiny={hasDefaultDestiny}
        />
      );
    });
  };

  const openCarnetSettlementsEKMTForm = (foreignPermissionId: string, initialData?: EkmtCarnetSettlementDetails) => {
    openDialog(({ closeDialog }) => {
      return (
        <CarnetSettlementsEKMTForm
          foreignPermissionId={foreignPermissionId}
          initialData={initialData}
          closeDialog={closeDialog}
        />
      );
    });
  };

  // Rozliczenie zezwolenia jednorazowego
  const openSinglePermit = ({
    foreignPermissionId,
    selectedPermitIds,
    selectedPermits,
    initialData,
    onSuccess
  }: SinglePermitParams) => {
    openDialog(({ closeDialog }) => {
      return (
        <SettleSinglePermitForm
          foreignPermissionId={foreignPermissionId}
          selectedPermitIds={selectedPermitIds}
          selectedPermits={selectedPermits}
          initialData={initialData}
          closeDialog={closeDialog}
          onSuccess={onSuccess}
        />
      );
    });
  };

  const openReceivePermissionDialog = (data: ClientForeignPermissions) => {
    openDialog(({ closeDialog }) => {
      return (
        <ReceiveReturnPermissionForm actionType={PermitActionTypeEnum.RECEIVE} data={data} closeDialog={closeDialog} />
      );
    });
  };

  const openReturnPermissionDialog = (data: ClientForeignPermissions) => {
    openDialog(({ closeDialog }) => {
      return (
        <ReceiveReturnPermissionForm actionType={PermitActionTypeEnum.RETURN} data={data} closeDialog={closeDialog} />
      );
    });
  };

  return {
    openSettleEKMTPermitDialog: openEKMT,
    openSettleSinglePermitDialog: openSinglePermit,
    openCarnetSettlementsEKMTForm,
    openReceivePermissionDialog,
    openReturnPermissionDialog
  };
}

export default usePermitDialog;
