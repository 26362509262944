import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { partialTranslate } from '@libs/common';
import { DropdownFullButton, TableDropdownButton, useConfirmDialog } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';
import { UIElementNameEnum } from '@libs/permission';

import { DomainDictionaryEnum } from '@libs/domain/config';
import useCalculateLimitForAllFoldersMutation from '@libs/domain/social-commission/api/mutations/useCalculateLimitForAllFoldersMutation';
import useCalculateLimitFreeDisposalFoldersMutation from '@libs/domain/social-commission/api/mutations/useCalculateLimitFreeDisposalFoldersMutation';
import useDialogSelectFolderForCalculation from '@libs/domain/social-commission/hooks/useDialogSelectFolderForCalculation';

function CalculateButton({
  rule,
  actionKey,
  isNormalButton = false
}: {
  rule: {
    year: number;
    resourceTypeId: string;
  };
  isNormalButton?: boolean;
  actionKey?: UIElementNameEnum;
}) {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();
  const [confirm] = useConfirmDialog();
  const { showSuccessSnackbar } = useSnackbar();
  const getLabel = partialTranslate('foreignPermits:tabs.socialCommission.tabs.SocialCommissionBasicRules');

  const { showDialog } = useDialogSelectFolderForCalculation({ ...rule }, isNormalButton);
  const { mutate: calculateForAllFolders } = useCalculateLimitForAllFoldersMutation();
  const { mutate: calculateForFoldersTradeRules } = useCalculateLimitFreeDisposalFoldersMutation();

  const submitCalculateForAllFolders = (closeDialog, loadingStop) => {
    if (!isNormalButton) {
      calculateForAllFolders(
        {
          resourceTypeId: rule.resourceTypeId,
          force: false
        },
        {
          onSuccess: res => {
            closeDialog?.();
            showSuccessSnackbar(
              translate(DomainDictionaryEnum.FOLDER_LIMIT_CALCULATION_RESPONSE, res?.data?.responseKey)
            );
          },
          onSettled: () => {
            loadingStop?.();
          }
        }
      );
    } else {
      calculateForFoldersTradeRules(
        {
          resourceTypeId: rule.resourceTypeId,
          force: false
        },
        {
          onSuccess: res => {
            closeDialog?.();

            showSuccessSnackbar(
              translate(DomainDictionaryEnum.FOLDER_LIMIT_CALCULATION_RESPONSE, res?.data?.responseKey)
            );
          },
          onSettled: () => {
            loadingStop?.();
          }
        }
      );
    }
  };
  return !isNormalButton ? (
    <TableDropdownButton
      actionKey={actionKey}
      tooltipTitle={getLabel('action.calculate')}
      icon="CoinsIcon"
      actions={[
        {
          label: getLabel('action.all'),
          onClick: () =>
            confirm({
              title: getLabel('action.calculate'),
              message: getLabel('dialogs.confirmAllCalculate'),
              onConfirm: (setConfirmLoading, closeDialog) => {
                setConfirmLoading(true);
                submitCalculateForAllFolders(closeDialog, () => setConfirmLoading(false));
              },
              confirmText: t('action.confirm'),
              cancelText: t('action.cancel')
            })
        },
        {
          label: getLabel('action.one'),
          onClick: () => {
            showDialog();
          }
        }
      ]}
    />
  ) : (
    <DropdownFullButton
      actionKey={actionKey}
      tooltipTitle={getLabel('action.calculate')}
      actions={[
        {
          label: getLabel('action.all'),
          onClick: () =>
            confirm({
              title: getLabel('action.calculate'),
              message: getLabel('dialogs.confirmAllCalculate'),
              onConfirm: (setConfirmLoading, closeDialog) => {
                setConfirmLoading(true);
                submitCalculateForAllFolders(closeDialog, () => setConfirmLoading(false));
              },
              confirmText: t('action.confirm'),
              cancelText: t('action.cancel')
            })
        },
        {
          label: getLabel('action.one'),
          onClick: () => {
            showDialog();
          }
        }
      ]}
    />
  );
}

export default CalculateButton;
