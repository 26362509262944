import { getRoundedValue } from '../../../utils';

interface CalculateProps {
  quantity: number;
  correctedQuantity: number;
  unitCost: number;
  correctedUnitCost: number;
}

/**
 *
 * „Stara wartość” dzielona przez miesiące (liczba miesięcy w długi zezwolenia 5,10 lat itp) i razy
 * liczba miesięcy wyliczona w polu „Liczba miesięcy” (pole opisane w bloku
 * Szczegóły zawieszenia”).
 *
 * @param months
 * Wartość miesięcy z pola "Liczba miesięcy" - wartość podstawowa lub po zmianach
 */
export const calculateDiffrenceWithMonths = (unitCost: number, validityMonths: number, months: number): number => {
  return (unitCost / validityMonths) * months;
};

/**
 * Funkcja do przeliczania różnicy,
 * nowa wartosc - stara wartosc
 */
export const calculateSumDiffrence = (item: CalculateProps): number => {
  const { correctedQuantity, correctedUnitCost, quantity, unitCost } = item;

  return getRoundedValue(correctedQuantity * correctedUnitCost - quantity * unitCost);
};
