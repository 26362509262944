import { QueryConfig, useQuery } from 'react-query';
import { DefaultApiGetPullNotificationRequest, PullNotificationSnapshot } from '@avispon/message';
import { AxiosRequestConfig } from 'axios';

import API from '../endpoints';
import { endpointsConfig } from '../endpoints-config';

import { MessageQueryKeysEnum } from './QueryKeysEnum';

const getAdminMessageDetails = async ({ pullNotificationId }: DefaultApiGetPullNotificationRequest) => {
  const { data } = await API.message.getPullNotification({ pullNotificationId }, {
    ...endpointsConfig.getAdminMessageDetails
  } as AxiosRequestConfig);

  return data;
};

function useAdminMessageDetailsQuery(
  pullNotificationId: string,
  queryConfig: QueryConfig<PullNotificationSnapshot, unknown> = {}
) {
  return useQuery(
    [MessageQueryKeysEnum.ADMIN_MESSAGE_DETAILS, pullNotificationId],
    () => getAdminMessageDetails({ pullNotificationId }),
    queryConfig
  );
}

export default useAdminMessageDetailsQuery;
