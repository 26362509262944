import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { every, isString } from 'lodash';

import {
  Dialog,
  ForwardTableState,
  useForwardedSelectedRowsAction,
  useIsSmallScreen,
  useMultipleSelectColumn
} from '@libs/common/v2';
import { important } from '@libs/common/v2/utils';

import { DomainDictionaryEntry, useDomainConfigContext } from '@libs/domain/config';
import {
  useConfirmAddVehicleDialog,
  VehicleAddSelectedActionButton,
  VehicleColumnNameEnum,
  VehiclesTable
} from '@libs/domain/vehicle';

interface IProps {
  folderId?: string;
  transferredFoldersIds?: string[];
  certificateStatusList?: string[];
  applicationId: string;
  applicationType: string;
  applicationCategory: string;
  closeDialog: () => void;
  hasIpwzWarningInformation?: boolean;
  statusKeys?: Array<string>;
  vehicleModificationConcernsKey?: string;
  onSuccess?: () => void;
  isAddSelectedActionButtonHidden?: boolean;
}

function VehicleSearchDialog({
  applicationId,
  applicationType,
  applicationCategory,
  folderId,
  transferredFoldersIds,
  certificateStatusList = [],
  closeDialog,
  hasIpwzWarningInformation,
  statusKeys = [DomainDictionaryEntry.VEHICLE_STATUS.ACTIVE],
  vehicleModificationConcernsKey,
  onSuccess,
  isAddSelectedActionButtonHidden
}: IProps) {
  const [t] = useTranslation();
  const classes = useStyles();
  const { isOperatorPortal } = useDomainConfigContext();
  const { openConfirmAddVehicleDialog } = useConfirmAddVehicleDialog(onSuccess);
  const { isSmallScreen } = useIsSmallScreen();
  const multipleSelectColumnPlugin = useMultipleSelectColumn(
    isAddSelectedActionButtonHidden
      ? () => null
      : (selected: string[], handleClose: () => void) => (
          <VehicleAddSelectedActionButton selected={selected} handleClose={handleClose} applicationId={applicationId} />
        ),
    true,
    null,
    [],
    isAddSelectedActionButtonHidden
  );

  const { forwardTableStateRef, getSelectedIds, handleDisableAction, isActionDisabled } =
    useForwardedSelectedRowsAction();

  return (
    <Dialog
      title={t('vehicle:dialog.searchVehicle.title')}
      confirmText={t('action.add')}
      onConfirm={() => {
        const selectedIds = getSelectedIds();
        openConfirmAddVehicleDialog(
          every(selectedIds, isString) ? (selectedIds as string[]) : [],
          applicationId,
          certificateStatusList,
          hasIpwzWarningInformation
        );
      }}
      onCancel={closeDialog}
      dialogSize="large"
      isConfirmButtonDisabled={isActionDisabled}
      isOpen
      isFullScreen={isSmallScreen}
    >
      <VehiclesTable
        applicationId={applicationId}
        applicationCategory={applicationCategory}
        applicationType={applicationType}
        visibleColumns={[
          VehicleColumnNameEnum.VIN,
          VehicleColumnNameEnum.PLATENUMBER,
          VehicleColumnNameEnum.BRANDKEY,
          VehicleColumnNameEnum.MANUFACTURE_YEAR
        ]}
        isXlsxDownloadVisible={false}
        tablePlugins={[multipleSelectColumnPlugin]}
        queryParams={{
          ...(isOperatorPortal
            ? {
                folderIdIn: folderId ? [folderId, ...(transferredFoldersIds || [])] : [],
                certificateStatusIn: certificateStatusList,
                vehiclesubmittedto: vehicleModificationConcernsKey
              }
            : { folderIdEq: folderId, vehiclesubmittedto: vehicleModificationConcernsKey }),
          statusKeyIn: statusKeys,
          excludeApplicationId: applicationId
        }}
        isHeaderHidden
        isSection
        sectionContentClassName={classes.tableContent}
        isHiddenSelectRowHeader={isAddSelectedActionButtonHidden}
      >
        <ForwardTableState ref={forwardTableStateRef} setState={handleDisableAction} />
      </VehiclesTable>
    </Dialog>
  );
}

const useStyles = makeStyles(() => ({
  tableContent: {
    height: important('60vh')
  }
}));

export default VehicleSearchDialog;
