import { useTableLayoutProps } from '@libs/common/v2';

import { useSingleAuthorizationColumns, useSingleAuthorizationsQuery } from '@libs/domain/folder';

function useSingleAuthorizationsTable(ids: Array<string>, permissionType: string, statusKeyIn?: Array<string>) {
  const { columns, mappedSortFields } = useSingleAuthorizationColumns();
  return useTableLayoutProps({
    tableHookQuery: useSingleAuthorizationsQuery,
    tableHookOptions: {
      columns,
      sortByConverter: mappedSortFields
    },
    tableHookQueryInitialParams: {
      ids,
      typeKeyIn: [permissionType],
      statusKeyIn
    }
  });
}

export default useSingleAuthorizationsTable;
