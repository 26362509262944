import { DefaultApiUpdateOrganizationUnitRequest } from '@avispon/organization-structure';

import { useMutation, useQueryCache } from '@libs/common/v2/api';

import { API, endpointsConfig, OrganizationQueryKeysEnum } from '..';

const updateOrganizationUnit = async (params: DefaultApiUpdateOrganizationUnitRequest) => {
  const { data } = await API.organizationStructure.updateOrganizationUnit(params, {
    ...endpointsConfig.updateOrganizationUnit
  });

  return data;
};

export default function useEditOrganizationUnitMutation({ onSuccess }) {
  const queryCache = useQueryCache();
  return useMutation(updateOrganizationUnit, {
    onSuccess: (...args) => {
      queryCache.invalidateQueries(OrganizationQueryKeysEnum.ORGANIZATION_UNIT_DETAILS);
      queryCache.invalidateQueries(OrganizationQueryKeysEnum.ORGANIZATION_UNIT_LIST);
      onSuccess(...args);
    }
  });
}
