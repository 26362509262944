import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';
import {
  Pagination as MuiPagination,
  TablePagination as MuiTablePagination,
  TablePaginationClassKey
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useIsSmallScreen } from '@libs/common/v2';
import { Theme, useTheme } from '@libs/common/v2/theme';
import { hexToRgba, important } from '@libs/common/v2/utils';

import { useTableAdapter, useTableContext } from '../hooks';

import PaginationActions from './TablePaginationActions';

export interface ITablePaginationProps {
  className?: string;
  classes?: Partial<Record<TablePaginationClassKey, string>>;
  isFullWidth?: boolean;
  isSelectRowsCountDisabled?: boolean;
}

function TablePagination({
  className,
  classes: propClasses,
  isFullWidth,
  isSelectRowsCountDisabled
}: ITablePaginationProps) {
  const [t] = useTranslation();
  const table = useTableAdapter();
  const { contrast } = useTheme();
  const {
    state: { pageSize, pageIndex },
    gotoPage,
    setPageSize
  } = table;
  const { checkDisabled, isError } = useTableContext();
  const classes = useStyles({ isFullWidth, contrast });
  const { isSmallScreen } = useIsSmallScreen();
  const { width } = useWindowSize();

  const isExtraSmallScreen = useMemo(() => width < 500, [width]);

  const handleChangePage = (_, pageNumber) => {
    gotoPage(pageNumber);
  };

  const handleChangePageSize = e => {
    if (e?.target?.value) {
      const newPageSize = e?.target?.value;
      gotoPage(0);
      setPageSize(newPageSize);
    }
  };

  if (isSmallScreen) {
    return (
      <MuiPagination
        className={className}
        {...(isExtraSmallScreen && { size: 'small', boundaryCount: 0, showFirstButton: true, showLastButton: true })}
        classes={{
          ...propClasses,
          root: classes.mobileRoot
        }}
        color="primary"
        shape="rounded"
        count={table.pageCount}
        page={(pageIndex ?? 0) + 1}
        onChange={(event, page) => handleChangePage(event, page - 1)}
        disabled={checkDisabled(table) || isError}
      />
    );
  }

  return (
    <MuiTablePagination
      component="div"
      ActionsComponent={PaginationActions}
      className={className}
      classes={{
        ...propClasses,
        root: classes.root,
        menuItem: classes.menuItem,
        toolbar: classes.toolbar,
        selectRoot: classes.selectRoot,
        input: classes.input,
        spacer: classes.spacer
      }}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      SelectProps={{
        inputProps: {
          'aria-label': t('other:component.table.elementsOnPage'),
          'aria-labelledby': 'selectInputLabel'
        },
        id: 'selectInputLabel',
        className: 'MuiInputSelectBase-root',
        disabled: checkDisabled(table) || isError || isSelectRowsCountDisabled
      }}
      count={table.pageCount * pageSize}
      backIconButtonProps={{
        'aria-label': t('other:component.table.previousPage'),
        title: t('other:component.table.previousPage'),
        disabled: !table.canPreviousPage || checkDisabled(table) || isError,
        className: classes.icon
      }}
      nextIconButtonProps={{
        'aria-label': t('other:component.table.nextPage'),
        title: t('other:component.table.nextPage'),
        disabled: !table.canNextPage || checkDisabled(table) || isError,
        className: classes.icon
      }}
      page={pageIndex ?? 0}
      rowsPerPage={pageSize ?? 0}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangePageSize}
      labelRowsPerPage={t('other:component.table.elementsOnPage')}
    />
  );
}

const useStyles = makeStyles<Theme, { contrast: boolean; isFullWidth?: boolean }>(theme => ({
  root: {
    position: 'sticky',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    bottom: 0,
    backgroundColor: theme.palette.grey[50],
    padding: ({ isFullWidth }) => (isFullWidth ? important(0) : 'inherit'),
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    ...theme.typography.textSm.normal,
    zIndex: theme.zIndex.drawer,
    height: 52,
    minHeight: 52,
    '& .MuiInputSelectBase-root': {
      '& > div': {
        ...theme.typography.textMd.normal,
        color: theme.palette.grey[900],
        padding: important('6px 38px 6px 14px'),
        textOverflow: 'unset',
        textAlign: 'center'
      },
      '& > svg': {
        color: theme.palette.grey[500],
        right: 14,
        left: 38,
        top: 'unset'
      },
      height: 36,
      width: 72,
      fontSize: '1.4rem',
      backgroundColor: theme.palette.white,
      borderRadius: 8,
      border: `1px solid ${theme.palette.grey[300]}`,
      marginLeft: 12,
      marginRight: 16,
      padding: 0
    }
  },
  mobileRoot: {
    position: 'sticky',
    display: 'flex',
    justifyContent: 'center',
    bottom: 0,
    right: 0,
    backgroundColor: theme.palette.grey[50],
    padding: ({ isFullWidth }) => (isFullWidth ? important(0) : 'inherit'),
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    ...theme.typography.textSm.normal,
    zIndex: theme.zIndex.drawer,
    height: 52,
    minHeight: 52,

    '& .MuiPagination-ul': {
      height: 52,
      '& > li': {
        '& > *': { color: ({ contrast }) => contrast && theme.palette.contrastIndicators.background },
        '& > button': {
          border: `1px solid ${theme.palette.grey[300]}`
        },
        '& > .Mui-selected': {
          color: theme.palette.white,
          backgroundColor: ({ contrast }) => (contrast ? theme.palette.grey[700] : theme.palette.blue[800])
        }
      }
    }
  },
  menuItem: {
    backgroundColor: theme.palette.grey[50],
    ...theme.typography.textMd.normal
  },
  selectRoot: {
    '& .Mui-disabled': {
      '-webkit-text-fill-color': ({ contrast }) => contrast && hexToRgba(theme.palette.grey[700], 0.6)
    }
  },
  toolbar: {
    maxHeight: 52,
    minHeight: 'auto',
    justifyContent: 'flex-end',
    '& p:last-of-type': {
      display: 'none'
    },
    '& > div:last-child': {
      paddingLeft: 0
    }
  },
  spacer: {
    display: 'none'
  },
  icon: {
    color: theme.palette.grey[700]
  }
}));

export default React.memo<ITablePaginationProps>(TablePagination);
