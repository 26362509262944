import { useState } from 'react';

import { Button, FormMode, IconType, TableIconButton } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

import { CivicBoardSnapshot } from '../../model';

function ModalButton({
  title,
  component: Modal,
  data,
  isRowButton,
  modalTitle,
  icon,
  actionKey
}: {
  title: string;
  component: any;
  data?: {
    original?: CivicBoardSnapshot;
    mode?: FormMode;
  };
  isRowButton?: boolean;
  modalTitle?: string;
  icon?: IconType;
  actionKey?: UIElementNameEnum;
}) {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      {isRowButton ? (
        <TableIconButton
          tooltipTitle={title}
          onClick={() => {
            setModalOpen(true);
          }}
          icon={icon}
          actionKey={actionKey}
        />
      ) : (
        <Button
          label={title}
          type="button"
          onClick={() => {
            setModalOpen(true);
          }}
          actionKey={actionKey}
          isPrimary
        />
      )}

      {data ? (
        <Modal
          open={isModalOpen}
          data={data}
          onCancel={() => {
            setModalOpen(false);
          }}
          onClose={() => {
            setModalOpen(false);
          }}
          modalTitle={modalTitle && modalTitle}
        />
      ) : (
        <Modal
          open={isModalOpen}
          onCancel={() => {
            setModalOpen(false);
          }}
          onClose={() => {
            setModalOpen(false);
          }}
          title={title}
          modalTitle={modalTitle && modalTitle}
        />
      )}
    </>
  );
}

export default ModalButton;
