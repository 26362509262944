import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { InsurancePolicyUpdateRequest } from '@ibtm/domain';
import _ from 'lodash';
import moment from 'moment/moment';
import * as Yup from 'yup';

import {
  ALTERNATIVE_DATE_FORMAT,
  DatepickerField,
  Dialog,
  FormMode,
  FormV2Context,
  GridLayout,
  NumberInputField,
  TextInputField,
  typedNameV2,
  useViewModesV2
} from '@libs/common/v2';
import { getCalendarDate } from '@libs/common/v2/utils';

import { InsurancePolicySnapshot, useCreateInsuranceMutation, useUpdateInsuranceMutation } from '@libs/domain/folder';

interface IInsurancePoliciesEditFormDialog {
  folderId: string;
  formMode: FormMode;
  closeDialog: () => void;
  refetch: () => void;
  editFormData?: InsurancePolicySnapshot;
}

export const InsurancePoliciesEditFormDialog = ({
  folderId,
  formMode,
  closeDialog,
  refetch,
  editFormData
}: IInsurancePoliciesEditFormDialog) => {
  const [t] = useTranslation();
  const { createMode } = useViewModesV2(formMode);
  const { showSuccessSnackbar } = useSnackbar();

  const { mutate: createInsurance, isLoading: isLoadingCreate } = useCreateInsuranceMutation();
  const { mutate: updateInsurance, isLoading: isLoadingUpdate } = useUpdateInsuranceMutation();

  const validationSchema = Yup.object({
    grantDate: Yup.date().required().nullable(),
    name: Yup.string().required(),
    value: Yup.number().required(),
    validFrom: Yup.date().required().nullable(),
    validTo: Yup.date()
      .required()
      .nullable()
      .when('validFrom', (validFrom, schema) => {
        return schema.test({
          test: validTo => !moment(validTo).isBefore(validFrom),
          message: t('validation:minDate', {
            fieldName: t('folder:details.tab.insurancePolicies.field.validFrom')
          })
        });
      })
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    clearErrors
  } = useForm<Partial<InsurancePolicySnapshot>>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: {
      grantDate: editFormData?.grantDate || null,
      value: editFormData?.value || 0,
      name: editFormData?.name || '',
      validFrom: editFormData?.validFrom || null,
      validTo: editFormData?.validTo || null
    }
  });

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting, clearErrors }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch, clearErrors]
  );

  const prepareRequest = (data: InsurancePolicySnapshot) => ({
    grantDate: getCalendarDate(moment(data.grantDate).format(ALTERNATIVE_DATE_FORMAT)),
    value: data.value,
    name: data.name,
    folderId,
    validDateRange: {
      from: getCalendarDate(moment(data.validFrom).format(ALTERNATIVE_DATE_FORMAT)),
      to: getCalendarDate(moment(data.validTo).format(ALTERNATIVE_DATE_FORMAT))
    },
    ...(_.isUndefined(editFormData?.version) ? {} : { version: editFormData.version })
  });

  const handleSubmitForm = (formValues: InsurancePolicySnapshot) => {
    const parsedData = prepareRequest(formValues);

    if (createMode) {
      return createInsurance(parsedData, {
        onSuccess: () => {
          showSuccessSnackbar(t('folder:details.tab.insurancePolicies.policyAdded'));
          refetch();
          closeDialog();
        }
      });
    }

    return updateInsurance(
      { id: editFormData.id, data: parsedData as InsurancePolicyUpdateRequest },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('folder:details.tab.insurancePolicies.policyUpdated'));
          refetch();
          closeDialog();
        }
      }
    );
  };

  return (
    <FormV2Context.Provider value={values}>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Dialog
          title={
            createMode
              ? t('folder:details.tab.insurancePolicies.createInsuranceDialogTitle')
              : t('folder:details.tab.insurancePolicies.editInsuranceDialogTitle')
          }
          shortTitle={createMode ? t('global:dialog.addTitle') : t('global:dialog.editTitle')}
          dialogSize="small"
          onCancel={closeDialog}
          onConfirm={handleSubmit(handleSubmitForm)}
          confirmText={t('action.save')}
          cancelText={t('action.cancel')}
          isConfirmLoading={isLoadingCreate || isLoadingUpdate}
          isOpen
        >
          <GridLayout itemProps={{ xs: 12 }}>
            <DatepickerField
              label={t('folder:details.tab.insurancePolicies.field.grantDate')}
              name={typedNameV2<InsurancePolicySnapshot>('grantDate')}
              isRequired
            />
            <TextInputField
              label={t('folder:details.tab.insurancePolicies.field.name')}
              name={typedNameV2<InsurancePolicySnapshot>('name')}
              isRequired
            />
            <NumberInputField
              label={t('folder:details.tab.insurancePolicies.field.value')}
              name={typedNameV2<InsurancePolicySnapshot>('value')}
              isRequired
            />
            <DatepickerField
              label={t('folder:details.tab.insurancePolicies.field.validFrom')}
              name={typedNameV2<InsurancePolicySnapshot>('validFrom')}
              isRequired
            />
            <DatepickerField
              label={t('folder:details.tab.insurancePolicies.field.validTo')}
              name={typedNameV2<InsurancePolicySnapshot>('validTo')}
              isRequired
            />
          </GridLayout>
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
};
