import { ArchiveFolderCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function archiveFolder(params: ArchiveFolderCreateRequest) {
  return API.archiveFolders.archiveFolder(params, {
    ...endpointsConfig.archiveFolder
  });
}

function useArchiveFolderMutation() {
  return useMutation(archiveFolder);
}

export default useArchiveFolderMutation;
