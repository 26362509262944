import { ResourceAssignmentPermissionUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function updatePermission(formData: ResourceAssignmentPermissionUpdateRequest) {
  return API.printouts.updateResourceAssignmentPermission(formData, {
    ...endpointsConfig.updateResourceAssignmentPermission
  });
}

function useUpdatePermissionMutation() {
  return useMutation(updatePermission);
}

export default useUpdatePermissionMutation;
