import { partialTranslate } from '@libs/common';
import { DictionarySelectField, InputMode, TextInputField, typedNameV2 } from '@libs/common/v2';

import { parsePostCode } from '@libs/domain/address';
import { DomainDictionaryEnum } from '@libs/domain/config';

const getLabel = partialTranslate('address:fields');

function useAdressFields(formGroupName: string, inputMode?: InputMode) {
  const getFieldName = (fieldName: any) => typedNameV2<any>(`${formGroupName}.${fieldName}`);

  const fields = [
    <TextInputField
      inputMode={inputMode}
      name={getFieldName('postCode')}
      getValueFormat={postCode => {
        return parsePostCode(postCode);
      }}
      label={getLabel('postCode')}
      key={`${formGroupName}-postCode`}
    />,
    <DictionarySelectField
      name={getFieldName('voivodeshipKey')}
      label={getLabel('voivodeship')}
      dictionaryName={DomainDictionaryEnum.VOIVODESHIP}
      inputMode={inputMode}
      key={`${formGroupName}-voivodeship`}
    />,
    <TextInputField
      inputMode={inputMode}
      name={getFieldName('county')}
      label={getLabel('county')}
      key={`${formGroupName}-county`}
    />,
    <TextInputField
      inputMode={inputMode}
      name={getFieldName('commune')}
      label={getLabel('commune')}
      key={`${formGroupName}-commune`}
    />,
    <TextInputField
      inputMode={inputMode}
      name={getFieldName('postCity')}
      label={getLabel('postCity')}
      key={`${formGroupName}-postCity`}
    />,
    <TextInputField
      inputMode={inputMode}
      name={getFieldName('city')}
      label={getLabel('city')}
      key={`${formGroupName}-city`}
    />,
    <TextInputField
      inputMode={inputMode}
      name={getFieldName('street')}
      label={getLabel('street')}
      key={`${formGroupName}-street`}
    />,
    <TextInputField
      inputMode={inputMode}
      name={getFieldName('propertyNumber')}
      label={getLabel('propertyNumber')}
      key={`${formGroupName}-propertyNumber`}
    />,
    <TextInputField
      inputMode={inputMode}
      name={getFieldName('apartmentNumber')}
      label={getLabel('apartmentNumber')}
      key={`${formGroupName}-apartmentNumber`}
    />
  ];
  return fields;
}

export default useAdressFields;
