import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { KeyType } from '@libs/common';
import { Button, Divider, NavLinkAdapter, Theme, ThemeVariant, Typography } from '@libs/common/v2';
import { important } from '@libs/common/v2/utils';

interface ILink {
  label: KeyType;
  url: string;
  isHiddenAfterLogin?: boolean;
  isFullWidth?: boolean;
  target?: string;
}

interface IProps {
  links?: ILink[];
  version?: ReactNode;
  themeVariant?: ThemeVariant;
}

function MobileFooterLayout({ links, version, themeVariant = 'textSm.medium' }: IProps) {
  const [t] = useTranslation();
  const [type] = themeVariant.split('.');
  const classes = useStyles({ type });

  return (
    <div>
      <Divider />

      <div className={clsx(classes.linksWrapper)}>
        {links.map(
          ({ label, url, isHiddenAfterLogin, isFullWidth, target }) =>
            !isHiddenAfterLogin && (
              <div style={{ flex: isFullWidth ? '100%' : '50%', overflow: 'hidden', textAlign: 'left' }} key={label}>
                <Button
                  className={clsx(classes.button)}
                  label={t(label)}
                  variant="text"
                  // @ts-ignore
                  component={NavLinkAdapter}
                  link={url}
                  target={target}
                  size="small"
                  isNoMargin
                  fullWidth
                  customTextClass={classes.linkButtonText}
                  customWrapperClass={classes.linkButtonWrapper}
                />
              </div>
            )
        )}
      </div>
      <Divider />
      <div className={clsx(classes.version)}>
        <Typography themeVariant={themeVariant}>{version}</Typography>
      </div>
    </div>
  );
}

const useStyles = makeStyles<Theme, { type: string }>(theme => ({
  linksWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '12px'
  },
  button: ({ type }) => ({
    padding: 0,
    flex: '50%',
    width: '100%',
    textDecoration: important('underline'),
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'white'
    },
    textAlign: 'left',
    ...theme.typography[type].medium
  }),
  version: {
    marginTop: 20,
    marginBottom: 20
  },
  linkButtonText: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: important('block'),
    width: important('100%'),
    overflow: 'hidden'
  },
  linkButtonWrapper: {
    display: important('block'),
    width: important('100% !important'),
    textAlign: 'left'
  }
}));

export default MobileFooterLayout;
