import { DefaultApi as GroupDefaultApi } from '@avispon/group/dist/api/default-api';
import { DefaultApi as OrganizationStructureApi } from '@avispon/organization-structure';
import { AccountsApi as UserAccountsApi, DefaultApi as UserDefaultApi } from '@avispon/user';
import axios from 'axios';

interface AuthApi {
  organizationStructure: OrganizationStructureApi;
  group: GroupDefaultApi;
  user: {
    default: UserDefaultApi;
    accountController: UserAccountsApi;
  };
}

const organizationStructureApiFactoryFallback: any = () => {
  throw new Error('Organization structure api has not been set');
};

const API: AuthApi = {
  organizationStructure: organizationStructureApiFactoryFallback as OrganizationStructureApi,
  group: organizationStructureApiFactoryFallback as GroupDefaultApi,
  user: organizationStructureApiFactoryFallback as {
    default: UserDefaultApi;
    accountController: UserAccountsApi;
  }
};

export function setOrganizationStructureApi(API_PORTAL_URL: string) {
  API.organizationStructure = new OrganizationStructureApi({ isJsonMime: () => true }, API_PORTAL_URL, axios);
  API.group = new GroupDefaultApi({ isJsonMime: () => true }, API_PORTAL_URL, axios as never);
  API.user = {
    default: new UserDefaultApi({ isJsonMime: () => true }, API_PORTAL_URL, axios),
    accountController: new UserAccountsApi({ isJsonMime: () => true }, API_PORTAL_URL, axios)
  };
}

export default API;
