import { usePaginatedQuery } from 'react-query';
import { AssociationSubjectSearchFilter } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { AssociationQueryKeysEnum } from '@libs/domain/entrepreneurs';

import { endpointsConfig } from '../endpoints-config';

const getAssociationSubject = async (_, params: AssociationSubjectSearchFilter & { associationId: string }) => {
  const { associationId, ...restParams } = params;
  const { data } = await API.association.getAssociationSubjectSnapshotPage(associationId, restParams, {
    ...endpointsConfig.getAssociationSubjectSnapshotPage
  });

  return data;
};

const getAssociationSubjectClient = async (_, params: AssociationSubjectSearchFilter) => {
  const { data } = await API.client.association.getAssociationSubjectSnapshotPage('', '', params, {
    ...endpointsConfig.getAssociationSubjectSnapshotPage
  });

  return data;
};
export default function useAssociationSubjectQuery(params: any) {
  const { getQuery } = useGetApiQueryByPerspective();
  return usePaginatedQuery(
    [AssociationQueryKeysEnum.GET_ASSOCIATION_SUBJECT, params],
    getQuery(getAssociationSubjectClient, getAssociationSubject)
  );
}
