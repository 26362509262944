import { useTranslation } from 'react-i18next';
import { ResourceTypeSearchFilter } from '@ibtm/domain';

import {
  type SelectOption,
  TableFilterMapper,
  type TableSortMapper,
  useCreateColumns,
  useTableLayoutProps
} from '@libs/common/v2';
import type { DateRangeISO, TAmountRange } from '@libs/common/v2/utils';

import { UserFilterV2 } from '@libs/user';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { useResourceTypesQuery } from '../../api';
import { IResourceTypesClient } from '../../model';

const useResourceFormTypesTableColumns = () => {
  const [t] = useTranslation();
  const { createColumns } = useCreateColumns<IResourceTypesClient>({
    pathToTranslate: 'resource:fields'
  });

  const mappedSortFields: TableSortMapper<IResourceTypesClient> = {
    name: 'nameKey'
  };
  const mappedFilterFields: TableFilterMapper<IResourceTypesClient, ResourceTypeSearchFilter> = {
    year: (yearRange: TAmountRange) => ({
      yearGreaterThanOrEqual: yearRange.min,
      yearLessThanOrEqual: yearRange.max
    }),
    groupKey: (groups: SelectOption<string>[]) => ({ groupKeyFilter: { values: groups.map(group => group.value) } }),
    name: (names: SelectOption<string>[]) => ({ nameKeyIn: names.map(name => name.value) }),
    authorName: (authorIds: SelectOption<string>[]) => ({ authorIdIn: authorIds.map(({ value }) => value) }),
    created: ({ dateFrom, dateTo }: DateRangeISO) => ({
      createdGreaterThanOrEqual: dateFrom || '',
      createdLessThanOrEqual: dateTo || ''
    }),
    modifierName: (modifierIds: SelectOption<string>[]) => ({ modifierIdIn: modifierIds.map(({ value }) => value) }),
    modified: ({ dateFrom, dateTo }: DateRangeISO) => ({
      modifiedGreaterThanOrEqual: dateFrom || '',
      modifiedLessThanOrEqual: dateTo || ''
    })
  };

  const columns = createColumns([
    {
      id: 'groupKey',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_GROUP,
      width: 300
    },
    {
      id: 'year',
      dictionaryName: DomainDictionaryEnum.DEPOT_TYPE,
      customAccessor: row => row.year || t('emptyMark'),
      type: 'NUMBER_RANGE'
    },
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_NAME,
      accessor: 'name',
      width: 300
    },
    {
      type: 'DATE_TIME',
      accessor: 'created'
    },
    { type: 'TEXT', accessor: 'author.name', filter: UserFilterV2 },
    { type: 'DATE_TIME', accessor: 'modified' },
    { type: 'TEXT', accessor: 'modifier.name', filter: UserFilterV2 }
  ]);

  return { columns, mappedFilterFields, mappedSortFields };
};

export function useResourceFormTypesTable() {
  const { columns, mappedFilterFields, mappedSortFields } = useResourceFormTypesTableColumns();

  return useTableLayoutProps({
    tableHookQuery: useResourceTypesQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    }
  });
}
