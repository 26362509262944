import { i18n } from '@libs/common';
import { FormMode } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';
import { ProceedingTypeKeyEnum } from '@libs/domain/proceeding';

export const getProceedingBreadcrumbItems = (
  formMode: FormMode,
  proceedingNumber: string,
  proceedingId: string,
  folderId: string
) => {
  const firstBreadcrumb = {
    text: i18n.t('folder:details.tab.proceedings.list.title'),
    to: `/proceeding`
  };

  const secondBreadcrumb =
    formMode === FormMode.CREATE
      ? { text: `${i18n.t('proceeding:titles.newTitle')}` }
      : {
          text: `${i18n.t('proceeding:titles.detailsPageTitle')}: ${proceedingNumber}`,
          to: `/proceeding/VERIFICATION/${proceedingId}/details/folder/${folderId}/details`
        };
  const thirdBreadcrumb = formMode === FormMode.EDIT ? { text: i18n.t('proceeding:titles.editTitle') } : null;
  return thirdBreadcrumb ? [firstBreadcrumb, secondBreadcrumb, thirdBreadcrumb] : [firstBreadcrumb, secondBreadcrumb];
};

export const getMainProceedingBreadcrumbItems = (
  formMode: FormMode,
  proceedingNumber: string,
  proceedingId: string,
  type: string
) => {
  const firstBreadcrumb = {
    text: i18n.t('folder:details.tab.proceedings.list.title'),
    to: `/proceeding`
  };
  const secondBreadcrumb =
    formMode === FormMode.CREATE
      ? { text: `${i18n.t('proceeding:titles.newTitle')}` }
      : {
          text: `${i18n.t('proceeding:titles.detailsPageTitle')}: ${proceedingNumber}`,
          to: `/proceedings/${proceedingId}/${
            type === DomainDictionaryEntry.PROCEEDING_TYPE.ADMINISTRATIVE
              ? ProceedingTypeKeyEnum.ADMINISTRATION
              : ProceedingTypeKeyEnum.VERIFICATION
          }/view`
        };
  const thirdBreadcrumb = formMode === FormMode.EDIT ? { text: i18n.t('proceeding:titles.editTitle') } : null;
  return thirdBreadcrumb ? [firstBreadcrumb, secondBreadcrumb, thirdBreadcrumb] : [firstBreadcrumb, secondBreadcrumb];
};
