import { QueryConfig, useQuery } from 'react-query';
import { ParcelSummaryResponse } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { DocumentsIssuedQueryEnum } from '../../model';
import { endpointsConfig } from '../endpoints-config';

const getParcelSummary = async (_, parcelNumbers: string[]) => {
  const { data } = await API.documents.getParcelSummary({ parcelNumbers }, endpointsConfig.getParcelSummary);
  return data;
};

function useGetParcelSummaryQuery(parcelId, config: QueryConfig<ParcelSummaryResponse>) {
  return useQuery([DocumentsIssuedQueryEnum.PARCEL_SUMMARY, parcelId], getParcelSummary, config);
}

export default useGetParcelSummaryQuery;
