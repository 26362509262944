import { DocumentSearchFilter } from '@ibtm/domain';

import { SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO, getCalendarDate } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';
import { UserFilterV2 } from '@libs/user';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { DocumentsDictionaryNameEnum } from '@libs/domain/documents';

import { IProceedingDocumentClient } from '../../models';

const useDocumentsColumns = () => {
  const { translate } = useDictionaryTranslations();

  const { createColumns } = useCreateColumns<IProceedingDocumentClient>({
    pathToTranslate: 'document:fields'
  });

  const mappedSortFields: TableSortMapper<IProceedingDocumentClient> = {
    generatedDocumentName: 'file.name',
    generatedBy: 'author.name',
    generatedDate: 'created',
    documentName: 'name',
    addedBy: 'modifier.name',
    passedToSentDate: 'parcel.passedToSentDate',
    packageNumber: 'parcel.number',
    sendDate: 'parcel.sendDate',
    receiptAckDate: 'parcel.receiptAckDate'
  };

  const mappedFilterFields: TableFilterMapper<IProceedingDocumentClient, DocumentSearchFilter> = {
    generatedDocumentName: (generatedDocumentName: string) => ({ fileNameContains: generatedDocumentName }),
    generatedBy: (generatedBy: SelectOption<string>[]) => ({ authorIdIn: generatedBy.map(({ id }) => id) }),
    generatedDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      createdGreaterThanOrEqual: dateFrom,
      createdLessThanOrEqual: dateTo
    }),
    documentName: (documentName: string) => ({ documentNameContains: documentName }),
    addedBy: (addedBy: SelectOption<string>[]) => ({ modifierIdIn: addedBy.map(({ id }) => id) }),
    statusKey: (statuses: SelectOption<string>[]) => ({ documentStatusKeyIn: statuses.map(status => status.value) }),
    passedToSentDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      passedToSentDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      passedToSentDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    packageNumber: (packageNumber: string) => ({ parcelNumberContains: packageNumber }),
    sendDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      sendDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      sendDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    receiptAckDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      receiptAckDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      receiptAckDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    cancellationDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      cancellationDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      cancellationDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    cancelledBy: (cancelledBy: SelectOption<string>[]) => ({ cancellationByIdIn: cancelledBy.map(({ id }) => id) })
  };

  const columns = createColumns([
    {
      accessor: 'documentName',
      type: 'TEXT',
      width: 300
    },
    {
      id: 'generatedBy',
      type: 'CUSTOM_COLUMN',
      filter: (props: any) => <UserFilterV2 isMultiple {...props} />
    },
    {
      id: 'generatedDate',
      type: 'DATE',
      width: 280
    },
    {
      accessor: 'generatedDocumentName',
      type: 'TEXT',
      customAccessor: row => translate(DomainDictionaryEnum.DOCUMENT_TYPE, row.generatedDocumentName)
    },
    {
      id: 'addedBy',
      type: 'CUSTOM_COLUMN',
      filter: (props: any) => <UserFilterV2 isMultiple {...props} />
    },
    {
      id: 'inputDate',
      type: 'DATE',
      width: 280
    },
    {
      id: 'statusKey',
      header: 'status',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DocumentsDictionaryNameEnum.DOCUMENT_STATUS
    },
    {
      id: 'passedToSentDate',
      type: 'DATE',
      width: 280
    },
    {
      id: 'packageNumber',
      type: 'TEXT',
      accessor: 'parcel.number'
    },
    {
      id: 'sendDate',
      type: 'DATE',
      width: 280
    },
    {
      id: 'receiptAckDate',
      type: 'DATE',
      width: 280
    },
    {
      id: 'cancellationDate',
      type: 'DATE',
      width: 280
    },
    {
      id: 'cancelledBy',
      type: 'CUSTOM_COLUMN',
      filter: (props: any) => <UserFilterV2 isMultiple {...props} />
    },
    {
      id: 'isFinalDecision',
      header: 'decision',
      type: 'BOOLEAN',
      isFilterable: false
    }
  ]);

  return { columns, mappedFilterFields, mappedSortFields };
};

export default useDocumentsColumns;
