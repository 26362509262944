import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ForeignPermissionDetails,
  ForeignPermissionDocumentCreateRequest,
  ForeignPermissionSettlementCreateRequest
} from '@ibtm/domain';
import { isNil } from 'lodash';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  Dialog,
  DictionarySelectField,
  FormMode,
  FormV2Context,
  GridItem,
  GridLayout,
  InputMode,
  NumberInputField,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { convertDateToDateFormat, getCalendarDate } from '@libs/common/v2/utils';

import { useElementVisibility } from '@libs/permission';

import { DomainDictionaryEntry, DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';
import {
  ForeignPermissionDetailsForm,
  ForeignPermissionQueryEnum,
  IDownloadFileParam,
  ReleasedPermitsSnapshot,
  SettlementCurrentPermitsData,
  SettlementCurrentPermitsTable,
  singlePermitValidationSchema,
  useForeignPermitsDetailsQuery,
  useGenerateForeignPermissionDocument,
  usePrintReportDialog,
  useSettleForeignPermitMutation
} from '@libs/domain/foreign-permission';

interface IProps {
  foreignPermissionId?: string;
  selectedPermitIds?: string[];
  selectedPermits?: ReleasedPermitsSnapshot[];
  initialData?: ForeignPermissionDetails;
  closeDialog: () => void;
  onSuccess?: () => void;
}

function SettleSinglePermitForm({
  initialData,
  closeDialog,
  foreignPermissionId,
  selectedPermitIds = null,
  selectedPermits,
  onSuccess
}: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: settlePermit, isLoading } = useSettleForeignPermitMutation();
  const [singlePermit, setSinglePermit] = useState<ReleasedPermitsSnapshot[]>([]);
  const { openPrintReportDialog } = usePrintReportDialog();
  const { checkIsElementVisible } = useElementVisibility();

  const downloadFileAfterSettleForeignPermission = useCallback(({ downloadFunction, data }: IDownloadFileParam) => {
    return data?.content?.map(file => downloadFunction(file));
  }, []);

  const { generateConfirmationOfReturn } = useGenerateForeignPermissionDocument({
    downloadFileAfterSettleForeignPermission,
    isForeignPermissionsUse: true,
    onSuccess: () => {
      if (checkIsElementVisible(DomainUIElementEnum.PRINT_REPORT_CONFIRMATION)) {
        openPrintReportDialog();
      }
    }
  });

  const { data } = useForeignPermitsDetailsQuery(
    { foreignPermissionId },
    {
      enabled: Boolean(foreignPermissionId),
      ...(initialData ?? { initialData }),
      initialStale: true
    }
  );

  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<ForeignPermissionDetailsForm>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(singlePermitValidationSchema()),
    defaultValues: {
      settlementDate: new Date().toISOString()
    }
  });
  const codeKey = watch('codeKey');

  const isUsedDateAvailable = useMemo(
    () =>
      codeKey === DomainDictionaryEntry.RETURNS_OF_FOREIGN_LICENSES.NOT_USED ||
      codeKey === DomainDictionaryEntry.RETURNS_OF_FOREIGN_LICENSES.STOLEN ||
      codeKey === DomainDictionaryEntry.RETURNS_OF_FOREIGN_LICENSES.LOST,
    [codeKey]
  );

  useEffect(() => {
    if (data && isNil(selectedPermitIds)) {
      setSinglePermit([data]);
    }
  }, [selectedPermitIds, data]);

  useEffect(() => {
    if (isUsedDateAvailable) {
      clearErrors('usedDate');
    }
  }, [isUsedDateAvailable, clearErrors]);

  const getLabel = partialTranslate('foreignPermits:settlementDisposablePermits.formFields');

  const handleOnConfirm = async (formData: ForeignPermissionDetailsForm) => {
    const foreignPermissionIds = isNil(selectedPermitIds) ? [foreignPermissionId] : selectedPermitIds;

    const parsedData: ForeignPermissionSettlementCreateRequest = {
      ids: foreignPermissionIds,
      returnDate: getCalendarDate(formData.returnDate),
      codeKey: formData.codeKey,
      usageDate: getCalendarDate(formData.usedDate),
      relationsKey: formData.relationsKey?.value || null,
      ridesCount: formData.driveCount,
      notes: formData.notes
    };

    const params: ForeignPermissionDocumentCreateRequest = {
      documentTemplateId: '',
      foreignPermissionIdsObjects: [
        {
          foreignPermissionIds: foreignPermissionIds.map(permissionId => permissionId)
        }
      ]
    };

    settlePermit(parsedData, {
      onSuccess: () => {
        generateConfirmationOfReturn(params);
        showSuccessSnackbar(t('success.save'));
        onSuccess?.();
        queryCache.invalidateQueries(ForeignPermissionQueryEnum.FOREIGN_PERMISSION_LIST);
      }
    });
  };

  const values = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      handleSubmit,
      formMode: FormMode.CREATE
    }),
    [control, errors, getValues, handleSubmit, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  const visibleColumnsForSinglePermit: Array<keyof ReleasedPermitsSnapshot> = useMemo(
    () => ['year', 'name', 'formNumberFrom', 'formNumberTo', 'formNumber'],
    []
  );

  const tableData: SettlementCurrentPermitsData[] = useMemo(() => {
    if (!isNil(selectedPermits) && selectedPermits.length) {
      return selectedPermits.map(item => ({
        ...item,
        // TODO: Po przejściu na grupowanie blankietów, należy sumować ilość wybranych blankietów
        formSumNumber: 1
      }));
    }

    return singlePermit.map(item => ({
      ...item,
      formSumNumber: 1
    }));
  }, [selectedPermits, singlePermit]);

  return (
    <Dialog
      title={t('foreignPermits:settlementDisposablePermits.title')}
      confirmText={t('foreignPermits:settlementDisposablePermits.action.settle')}
      cancelText={t('action.cancel')}
      onConfirm={handleSubmit(handleOnConfirm)}
      onCancel={closeDialog}
      dialogSize="medium"
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <GridLayout itemProps={{ xs: 6 }}>
          <DatepickerField
            name={typedNameV2<ForeignPermissionDetailsForm>('returnDate')}
            label={getLabel('returnDate')}
            isRequired
          />
          <DatepickerField
            name={typedNameV2<ForeignPermissionDetailsForm>('settlementDate')}
            label={getLabel('settlementDate')}
            inputMode={InputMode.VIEW}
            viewModeDateParser={convertDateToDateFormat}
          />
          <DictionarySelectField
            name={typedNameV2<ForeignPermissionDetailsForm>('codeKey')}
            label={getLabel('destiny')}
            dictionaryName={DomainDictionaryEnum.RETURNS_OF_FOREIGN_LICENSES}
            isRequired
            stringValue
          />
          <DatepickerField
            name={typedNameV2<ForeignPermissionDetailsForm>('usedDate')}
            label={getLabel('usePermitionDate')}
            isDisabled={isUsedDateAvailable}
            tooltip={
              isUsedDateAvailable ? t('foreignPermits:settlementDisposablePermits.tooltip.usedDateDisabled') : undefined
            }
            isRequired={!isUsedDateAvailable}
          />
          <DictionarySelectField
            name={typedNameV2<ForeignPermissionDetailsForm>('relationsKey')}
            label={getLabel('permitionRelation')}
            dictionaryName={DomainDictionaryEnum.USAGE_METHOD_FOR_FOREIGN_PERMIT}
          />
          <NumberInputField
            name={typedNameV2<ForeignPermissionDetailsForm>('driveCount')}
            label={getLabel('driving')}
          />
          <GridItem xs={12}>
            <SettlementCurrentPermitsTable
              data={tableData}
              {...(isNil(selectedPermitIds) && { visibleColumns: visibleColumnsForSinglePermit })}
            />
          </GridItem>
          <GridItem xs={12}>
            <TextInputField
              name={typedNameV2<ForeignPermissionDetailsForm>('notes')}
              label={getLabel('notes')}
              lines={3}
            />
          </GridItem>
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default SettleSinglePermitForm;
