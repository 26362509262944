import React from 'react';
import { Icon, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

interface IconInfoProps {
  icon: string | JSX.Element;
  label: string;
  bolded?: boolean;
}

function IconInfo({ icon, label, bolded }: IconInfoProps) {
  const classes = useStyles({ bolded });
  const iconEl = typeof icon === 'string' ? <Icon className={classes.icon}>{icon}</Icon> : icon;
  return (
    <Typography variant="body1" className={classes.root}>
      {iconEl}
      <span className={classes.label}>{label}</span>
    </Typography>
  );
}

const useStyles = makeStyles<Theme, { bolded?: boolean }>(theme => ({
  root: {
    display: 'inline',
    lineHeight: '2rem',
    '& svg': {
      display: 'inline',
      verticalAlign: 'middle',
      marginRight: 4,
      height: '24px'
    }
  },
  icon: {
    display: 'inline',
    verticalAlign: 'middle',
    marginRight: 4,
    fontSize: theme.typography?.getFontSize(20),
    color: important(theme.palette.blueGrey[900])
  },
  label: {
    verticalAlign: 'middle',
    fontWeight: ({ bolded }) =>
      bolded ? theme.typography.textXs.bold.fontWeight : theme.typography.textXs.normal.fontWeight,
    fontSize: ({ bolded }) => theme.typography?.getFontSize(bolded ? 16 : 14),
    lineHeight: '19px',
    letterSpacing: '0.88px',
    color: theme.palette.blueGrey[900]
  }
}));

export default IconInfo;
