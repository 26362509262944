import { PermissionElementMap } from '@libs/permission';

import { ApplicationProcessMainActionEnum } from './ApplicationProcessMainAction';
import { ApplicationProcesTransitionsEnum } from './ApplicationProcesTransitions';
import { PermissionEnum } from './PermissionEnum';

export const ApplicationProcessPermissionElementMapper: PermissionElementMap<
  ApplicationProcesTransitionsEnum | ApplicationProcessMainActionEnum
> = {
  // Wnioski akcje procesów (alternatywne ścieżki w procesach )- w przycisku  "realizuj"
  [ApplicationProcesTransitionsEnum.PREPARE_DECISION_DRAFT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_PREPARING_PROJECT_ASSIGN]
  },
  [ApplicationProcesTransitionsEnum.TRANSFER_TO_OTHER_DEPARTMENT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_DEPARTMENT_TRANSFER]
  },
  [ApplicationProcesTransitionsEnum.SEND_TO_PRINT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_PRINTING_TRANSFER]
  },
  [ApplicationProcesTransitionsEnum.SET_APPLICATION_STATUS_AS_ACCEPTED]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_ACCEPTATION_TRANSFER]
  },
  [ApplicationProcesTransitionsEnum.BACK_TO_PREPARE_DECISION_DRAFT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_IMPROVEMENT_WITHDRAWAL]
  },
  [ApplicationProcesTransitionsEnum.SET_NOT_CONSIDERED_APPLICATION_STATUS]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_UNFILLED_LEAVE]
  },
  [ApplicationProcesTransitionsEnum.SET_INCOMPLETE_APPLICATION_STATUS]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_REQUEST_FOR_SUPPLEMENT_SEND]
  },
  [ApplicationProcesTransitionsEnum.CANCEL_APPLICATION]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_PROCESS_CANCEL]
  },
  [ApplicationProcesTransitionsEnum.WITHDRAW_APPLICATION]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_WITHDRAW]
  },
  [ApplicationProcesTransitionsEnum.RETURN_FOR_CORRECTIONS]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_RETURN]
  },
  [ApplicationProcesTransitionsEnum.ASSIGN_TO_HEAD_OF_DEPARTMENT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_RETURN]
  },
  [ApplicationProcesTransitionsEnum.SAVE_DATA_TO_FOLDER]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_SAVE_IN_FOLDER]
  },
  // Wnioski Akcje główne procesów
  [ApplicationProcessMainActionEnum.MAIN_SENT_TO_VERIFICATION]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_VERIFICATION_TRANSFER]
  },
  [ApplicationProcessMainActionEnum.MAIN_SAVE_DATA_TO_FOLDER]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_SAVE_IN_FOLDER]
  },
  [ApplicationProcessMainActionEnum.MAIN_SUPPLEMENTING_FORMAL_DEFICIENCIES]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_MISSING_DATA_CORRECT]
  },
  [ApplicationProcessMainActionEnum.MAIN_SET_INCOMPLETE_APPLICATION_STATUS]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_STATUS_INCOMPLETE_RESTORE]
  },
  [ApplicationProcessMainActionEnum.MAIN_PREPARE_DECISION_DRAFT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_PREPARING_PROJECT_ASSIGN]
  },
  [ApplicationProcessMainActionEnum.MAIN_SEND_TO_PRINT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_PRINTING_TRANSFER]
  },
  [ApplicationProcessMainActionEnum.MAIN_SET_APPLICATION_STATUS_AS_ACCEPTED]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_ACCEPTATION_TRANSFER]
  },
  [ApplicationProcessMainActionEnum.MAIN_CONFIRM_GENERATION_OF_LEAVING_APPLICATION_WITHOUT_CONSIDERATION_DOCUMENT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_WITHOUT_CONSIDERATION_DOCUMENT_CONFIRM]
  },
  [ApplicationProcessMainActionEnum.MAIN_SET_COMPLETE_APPLICATION_STATUS]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_COMPLETE_MARK]
  },
  [ApplicationProcessMainActionEnum.MAIN_CONFIRM_PREPARATION_OF_ISSUE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_PARCEL_RELEASE_CONFIRM]
  },
  [ApplicationProcessMainActionEnum.MAIN_SENT_FOR_ISSUE]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_ISSUE_TRANSFER]
  },
  [ApplicationProcessMainActionEnum.SET_PENDING_VERIFICATION_APPLICATION_STATUS]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_VERIFICATION_TRANSFER]
  },
  [ApplicationProcessMainActionEnum.SEND_FOR_REPRINT]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_PRINTING_TRANSFER]
  },
  [ApplicationProcessMainActionEnum.REFUSE_TO_ISSUE_PERMISSION]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_PREPARING_PROJECT_ASSIGN]
  }
};
