import { FolderDetails, SubjectAddressDetails } from '@ibtm/domain';
import * as Yup from 'yup';

export type AssociationModel = {
  number: string;
  subject: {
    id: string;
    name: string;
    shortName: string;
    nip: string;
    regon: string;
    krs: string;
    version?: number;
    notes: string;
  };
  addresses: Array<Omit<SubjectAddressDetails, 'voivodeshipKey'> & { voivodeshipKey: { value: string; name: string } }>;
  version?: number;
};

export type AssociationDetailsParams = {
  id: string;
};

export interface IAddAssociationSubject {
  entrepreneurSelect: { value: FolderDetails };
}

export const AssociationValidationSchema = ({ isNumberValidated }: { isNumberValidated: boolean }) =>
  Yup.object({
    number: isNumberValidated ? Yup.string().nullable().required() : Yup.string().nullable(),
    subject: Yup.object({
      name: Yup.string().max(500).required().nullable(),
      shortName: Yup.string().max(500).required().nullable()
    }),
    addresses: Yup.array().of(
      Yup.object().shape({
        postCode: Yup.string().nullable().required().postCode(),
        voivodeshipKey: Yup.object().nullable().required(),
        county: Yup.string().nullable().required(),
        commune: Yup.string().nullable().required(),
        postCity: Yup.string().nullable().required(),
        city: Yup.string().nullable().required(),
        street: Yup.string().nullable(),
        propertyNumber: Yup.string().nullable().required(),
        apartmentNumber: Yup.string().nullable()
      })
    )
  });

export enum AssociationQueryKeysEnum {
  GET_ASSOCIATION_SUBJECT = 'GET_ASSOCIATION_SUBJECT',
  ASSOCIATION_FOLDERS_FIELD = 'ASSOCIATION_FOLDERS_FIELD'
}

export enum AssociationFormEnum {
  ENTREPRENEUR_SELECT = 'entrepreneurSelect'
}

export enum AssociationTabEnum {
  BASIC_DATA = 'BASIC_DATA',
  ENTREPRENEURS = 'ENTREPRENEURS'
}
