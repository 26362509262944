import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PullNotificationSnapshot } from '@avispon/message';
import { FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  ALTERNATIVE_DATE_TIME_FORMAT,
  ALTERNATIVE_DATE_TIME_FORMAT_WITHOUT_COMMA,
  DatepickerField,
  DictionarySelectField,
  GridLayout,
  InputMode,
  Radio,
  Section,
  SwitchField,
  TextInputField,
  typedNameV2,
  useFormV2Context,
  withSkeleton
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';
import { Theme } from '@libs/common/v2/theme';
import { calc, convertDateToDateTimeFormat } from '@libs/common/v2/utils';

import { GroupsAutocompleteField, UsersAutocompleteField } from '@libs/notification/components';
import { MessagesDictionaryEntryNameEnum } from '@libs/notification/models';
import { useElementVisibility } from '@libs/permission';

import { DomainUIElementEnum } from '@libs/domain/config';

function AdminMessageSection() {
  const [t] = useTranslation();
  const classes = useStyles();
  const { formMode, setValue, control, clearErrors, setError } = useFormV2Context();
  const { viewMode, createMode, editMode } = useViewModesV2(formMode);
  const inputMode = editMode || createMode ? InputMode.FORM : InputMode.VIEW;
  const FormControlLabelWithSkeleton = withSkeleton(FormControlLabel);
  const [isRecipientUsers, setIsRecipientUsers] = useState(true);
  const [isRecipientGroups, setIsRecipientGroups] = useState(false);
  const { checkIsElementVisible } = useElementVisibility();

  const handleChangeMultiSelectRecipients = useCallback(
    (e: ChangeEvent<HTMLInputElement>, isUserRecipient: boolean) => {
      setIsRecipientUsers(isUserRecipient ? e.target.checked : false);
      setIsRecipientGroups(!isUserRecipient ? e.target.checked : false);
      setValue(isUserRecipient ? 'recipients.accountSelector.groupIds' : 'recipients.accountSelector.accountIds', []);
      clearErrors(isUserRecipient ? 'recipients.accountSelector.groupIds' : 'recipients.accountSelector.accountIds');
      setError(isUserRecipient ? 'recipients.accountSelector.accountIds' : 'recipients.accountSelector.groupIds', {
        message: t('validation:required')
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const hasAccountViewPermission = checkIsElementVisible(DomainUIElementEnum.ADMIN_MESSAGE_USERS_SELECT);
  const hasGroupViewPermission = checkIsElementVisible(DomainUIElementEnum.ADMIN_MESSAGE_GROUPS_SELECT);

  useEffect(() => {
    if (hasAccountViewPermission && !hasGroupViewPermission) {
      handleChangeMultiSelectRecipients({ target: { checked: true } } as ChangeEvent<HTMLInputElement>, true);
      setValue('recipients.accountSelector.recipient', 'users');
    } else if (!hasAccountViewPermission && hasGroupViewPermission) {
      handleChangeMultiSelectRecipients({ target: { checked: true } } as ChangeEvent<HTMLInputElement>, false);
      setValue('recipients.accountSelector.recipient', 'groups');
    }
  }, [handleChangeMultiSelectRecipients, hasAccountViewPermission, hasGroupViewPermission, setValue]);

  return (
    <GridLayout itemProps={{ md: 6 }}>
      <Section title={t('messages:group.message')} isCollapsable>
        <GridLayout itemProps={{ xs: 12 }}>
          <TextInputField
            inputMode={inputMode}
            name={typedNameV2<PullNotificationSnapshot>('subject')}
            label={t('messages:field.subject')}
            isRequired
          />
          <TextInputField
            inputMode={inputMode}
            name={typedNameV2<PullNotificationSnapshot>('message')}
            label={t('messages:field.message')}
            isRequired
          />
          <SwitchField
            inputMode={inputMode}
            name={typedNameV2<PullNotificationSnapshot>('blocking')}
            label={t('messages:field.blocking')}
          />
        </GridLayout>
      </Section>
      <Section title={t('messages:group.date')} isCollapsable>
        <GridLayout itemProps={{ xs: 12 }}>
          <DatepickerField
            inputMode={inputMode}
            name={typedNameV2<PullNotificationSnapshot>('visibleFrom')}
            label={t('messages:field.validFrom')}
            viewModeDateParser={convertDateToDateTimeFormat}
            format={viewMode ? ALTERNATIVE_DATE_TIME_FORMAT : ALTERNATIVE_DATE_TIME_FORMAT_WITHOUT_COMMA}
            displayFormat={ALTERNATIVE_DATE_TIME_FORMAT}
            isRequired
            isDateTimePicker
          />
          <DatepickerField
            inputMode={inputMode}
            name={typedNameV2<PullNotificationSnapshot>('validUntil')}
            label={t('messages:field.validUntil')}
            viewModeDateParser={convertDateToDateTimeFormat}
            format={viewMode ? ALTERNATIVE_DATE_TIME_FORMAT : ALTERNATIVE_DATE_TIME_FORMAT_WITHOUT_COMMA}
            displayFormat={ALTERNATIVE_DATE_TIME_FORMAT}
            isDateTimePicker
          />
        </GridLayout>
      </Section>
      <Section title={t('messages:group.recipient')} isCollapsable>
        {viewMode && (
          <GridLayout itemProps={{ xs: 12 }}>
            <TextInputField
              inputMode={inputMode}
              name={typedNameV2<PullNotificationSnapshot>('accountData.name')}
              label={t('messages:field.recipient')}
            />
            <DictionarySelectField
              inputMode={inputMode}
              name={typedNameV2<PullNotificationSnapshot>('status')}
              label={t('messages:field.status')}
              dictionaryName={MessagesDictionaryEntryNameEnum.NOTIFICATION_STATUS}
            />
          </GridLayout>
        )}
        {createMode && (
          <GridLayout itemProps={{ xs: 12 }}>
            {hasAccountViewPermission && (
              <Controller
                control={control}
                name="recipients.accountSelector.recipient"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlLabelWithSkeleton
                      control={
                        <Radio
                          name="recipients.accountSelector.recipient"
                          value="users"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            handleChangeMultiSelectRecipients(e, true);
                            onChange(e.target.value);
                          }}
                          className="mt-16"
                          isChecked={value === 'users'}
                        />
                      }
                      classes={{ root: classes.label }}
                      label={<UsersAutocompleteField isDisabled={!isRecipientUsers} isRequired={isRecipientUsers} />}
                    />
                  );
                }}
              />
            )}
            {hasGroupViewPermission && (
              <Controller
                control={control}
                name="recipients.accountSelector.recipient"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlLabelWithSkeleton
                      control={
                        <Radio
                          name="recipients.accountSelector.recipient"
                          value="groups"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            handleChangeMultiSelectRecipients(e, false);
                            onChange(e.target.value);
                          }}
                          isChecked={value === 'groups'}
                          className="mt-16"
                        />
                      }
                      classes={{ root: classes.label }}
                      label={<GroupsAutocompleteField isDisabled={!isRecipientGroups} isRequired={isRecipientGroups} />}
                    />
                  );
                }}
              />
            )}
          </GridLayout>
        )}
      </Section>
    </GridLayout>
  );
}

const useStyles = makeStyles<Theme>(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: calc('100vh - 155px'),
    overflow: 'hidden'
  },
  content: {
    padding: '1.5rem 2.4rem',
    overflow: 'auto'
  },
  label: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    '& > span:nth-child(2)': {
      width: '100%'
    },
    '& > div': {
      '& > div': {
        width: '100%'
      }
    }
  }
}));

export default AdminMessageSection;
