enum DomainUIElementEnum {
  // Teczki
  FOLDER_LIST = 'FOLDER_LIST',
  FOLDER_SLIDER = 'FOLDER_SLIDER',
  FOLDER_SLIDER_PERMISSION_SECTION = 'FOLDER_SLIDER_PERMISSION_TAB',
  FOLDER_SLIDER_SUBJECT_SECTION = 'FOLDER_SLIDER_SUBJECT_SECTION',
  FOLDER_CREATE_BUTTON = 'FOLDER_CREATE_BUTTON',
  FOLDER_ADD_BUTTON = 'FOLDER_ADD_BUTTON',
  FOLDER_DETAILS_BUTTON = 'FOLDER_DETAILS_BUTTON',
  FOLDER_EDIT_BUTTON = 'FOLDER_EDIT_BUTTON',
  FOLDER_KS_EDIT_BUTTON = 'FOLDER_KS_EDIT_BUTTON',
  FOLDER_MZ_EDIT_BUTTON = 'FOLDER_MZ_EDIT_BUTTON',
  FOLDER_OP_EDIT_BUTTON = 'FOLDER_OP_EDIT_BUTTON',
  FOLDER_PA_EDIT_BUTTON = 'FOLDER_PA_EDIT_BUTTON',
  FOLDER_PPO_EDIT_BUTTON = 'FOLDER_PPO_EDIT_BUTTON',
  FOLDER_TP_EDIT_BUTTON = 'FOLDER_TP_EDIT_BUTTON',
  FOLDER_WITD_EDIT_BUTTON = 'FOLDER_WITD_EDIT_BUTTON',
  FOLDER_ZZ_EDIT_BUTTON = 'FOLDER_ZZ_EDIT_BUTTON',
  FOLDER_KS_VIEW_BUTTON = 'FOLDER_KS_VIEW_BUTTON',
  FOLDER_MZ_VIEW_BUTTON = 'FOLDER_MZ_VIEW_BUTTON',
  FOLDER_OP_VIEW_BUTTON = 'FOLDER_OP_VIEW_BUTTON',
  FOLDER_PA_VIEW_BUTTON = 'FOLDER_PA_VIEW_BUTTON',
  FOLDER_PPO_VIEW_BUTTON = 'FOLDER_PPO_VIEW_BUTTON',
  FOLDER_TP_VIEW_BUTTON = 'FOLDER_TP_VIEW_BUTTON',
  FOLDER_WITD_VIEW_BUTTON = 'FOLDER_WITD_VIEW_BUTTON',
  FOLDER_ZZ_VIEW_BUTTON = 'FOLDER_ZZ_VIEW_BUTTON',
  FOLDER_KS_BASIC_DATA_VIEW = 'FOLDER_KS_BASIC_DATA_VIEW',
  FOLDER_ZZ_BASIC_DATA_VIEW = 'FOLDER_ZZ_BASIC_DATA_VIEW',
  FOLDER_WITD_BASIC_DATA_VIEW = 'FOLDER_WITD_BASIC_DATA_VIEW',
  FOLDER_MZ_BASIC_DATA_VIEW = 'FOLDER_MZ_BASIC_DATA_VIEW',
  FOLDER_MZ_PERMISSION_VIEW = 'FOLDER_MZ_PERMISSION_VIEW',
  FOLDER_EXPIRE_BUTTON = 'FOLDER_EXPIRE_BUTTON',
  FOLDER_ACTIVATE_BUTTON = 'FOLDER_ACTIVATE_BUTTON',
  FOLDER_APPLICATION_CREATE_BUTTON = 'FOLDER_APPLICATION_CREATE_BUTTON',
  FOLDER_GENERATE_LABEL = 'FOLDER_GENERATE_LABEL',
  FOLDER_CHECK_IN_REGON_DATA_BUTTON = 'FOLDER_CHECK_IN_REGON_DATA_BUTTON',
  FOLDER_EDIT_ASSOCIATION_MEMBERSHIP = 'FOLDER_EDIT_ASSOCIATION_MEMBERSHIP',
  FOLDER_ENTER_OR_EDIT_DATE_OF_DEATH = 'FOLDER_ENTER_OR_EDIT_DATE_OF_DEATH',
  FOLDER_BASIC_DATA_VIEW = 'FOLDER_BASIC_DATA_VIEW',
  FOLDER_BASIC_DATA_EDIT = 'FOLDER_BASIC_DATA_EDIT',
  FOLDER_SUBJECT_VIEW = 'FOLDER_SUBJECT_VIEW',
  FOLDER_SUBJECT_EDIT = 'FOLDER_SUBJECT_EDIT',
  FOLDER_SUBJECT_CHECK_DATA_IN_KREPTD_BUTTON = 'FOLDER_SUBJECT_CHECK_DATA_IN_KREPTD_BUTTON',
  FOLDER_SUBJECT_REPUTATION_VIEW = 'FOLDER_SUBJECT_REPUTATION_VIEW',
  FOLDER_ADDRESS_VIEW = 'FOLDER_ADDRESS_VIEW',
  FOLDER_MAIN_ADDRESS_KS_UPDATE = 'FOLDER_MAIN_ADDRESS_KS_UPDATE',
  FOLDER_MAIN_ADDRESS_WITD_UPDATE = 'FOLDER_MAIN_ADDRESS_WITD_UPDATE',
  FOLDER_MAIN_ADDRESS_ZZ_UPDATE = 'FOLDER_MAIN_ADDRESS_ZZ_UPDATE',
  FOLDER_PROXY_VIEW = 'FOLDER_PROXY_VIEW',
  FOLDER_PROXY_ADD_BUTTON = 'FOLDER_PROXY_ADD_BUTTON',
  FOLDER_PROXY_EDIT_BUTTON = 'FOLDER_PROXY_EDIT_BUTTON',
  FOLDER_PROXY_DELETE_BUTTON = 'FOLDER_PROXY_DELETE_BUTTON',
  FOLDER_CERTIFICATE_S_VIEW = 'FOLDER_CERTIFICATE_S_VIEW',
  FOLDER_CERTIFICATE_S_TABLE_VIEW = 'FOLDER_CERTIFICATE_S_TABLE_VIEW',
  FOLDER_CERTIFICATE_S_DETAILS_BUTTON = 'FOLDER_CERTIFICATE_S_DETAILS_BUTTON',
  FOLDER_BASIC_LIMIT_VIEW = 'FOLDER_BASIC_LIMIT_VIEW',
  FOLDER_BASIC_LIMIT_BLOCK_PERMITS_VIEW = 'FOLDER_BASIC_LIMIT_BLOCK_PERMITS_VIEW',
  FOLDER_BASIC_LIMIT_BLOCK_PERMITS_EDIT = 'FOLDER_BASIC_LIMIT_BLOCK_PERMITS_EDIT',
  FOLDER_BASIC_LIMIT_SUSPEND_OR_POSTPONE_LIMIT = 'FOLDER_BASIC_LIMIT_SUSPEND_OR_POSTPONE_LIMIT',
  FOLDER_BASIC_LIMIT_EDIT_OR_TRANSFER_BUTTON = 'FOLDER_BASIC_LIMIT_EDIT_OR_TRANSFER_BUTTON',
  FOLDER_BASIC_LIMIT_RELEASED_OR_ISSUED_TABLE_VIEW = 'FOLDER_BASIC_LIMIT_RELEASED_OR_ISSUED_TABLE_VIEW',
  FOLDER_BASIC_LIMIT_RELEASED_OR_ISSUED_DETAILS_BUTTON = 'FOLDER_BASIC_LIMIT_RELEASED_OR_ISSUED_DETAILS_BUTTON',
  FOLDER_EKMT_VIEW = 'FOLDER_EKMT_VIEW',
  FOLDER_EKMT_BLOCK_PERMITS_VIEW = 'FOLDER_EKMT_BLOCK_PERMITS_VIEW',
  FOLDER_EKMT_BLOCK_PERMITS_EDIT = 'FOLDER_EKMT_BLOCK_PERMITS_EDIT',
  FOLDER_EKMT_DETAILS = 'FOLDER_EKMT_DETAILS',
  FOLDER_EKMT_LIMIT_TABLE_VIEW = 'FOLDER_EKMT_LIMIT_TABLE_VIEW',
  FOLDER_EKMT_RELEASED_OR_ISSUED_TABLE_VIEW = 'FOLDER_EKMT_RELEASED_OR_ISSUED_TABLE_VIEW',
  FOLDER_EKMT_RELEASED_OR_ISSUED_DETAILS_BUTTON = 'FOLDER_EKMT_RELEASED_OR_ISSUED_DETAILS_BUTTON',
  FOLDER_ORDER_DETAILS = 'FOLDER_ORDER_DETAILS',
  FOLDER_PERMISSION_VIEW = 'FOLDER_PERMISSION_VIEW',
  FOLDER_PERMISSION_LICENCE_TABLE_VIEW = 'FOLDER_PERMISSION_LICENCE_TABLE_VIEW',
  FOLDER_PERMISSION_GITD_PERMITS_TABLE_VIEW = 'FOLDER_PERMISSION_GITD_PERMITS_TABLE_VIEW',
  FOLDER_PERMISSION_FORWARD_TO_STAROSTY_BUTTON = 'FOLDER_PERMISSION_FORWARD_TO_STAROSTY_BUTTON',
  FOLDER_PERMISSION_BACK_TO_GITD_BUTTON = 'FOLDER_PERMISSION_BACK_TO_GITD_BUTTON',
  FOLDER_PERMISSION_PERMITS_AND_NATIONAL_LICENCES_FROM_EXTERNAL_TABLE_VIEW = 'FOLDER_PERMISSION_PERMITS_AND_NATIONAL_LICENCES_FROM_EXTERNAL_TABLE_VIEW',
  FOLDER_PERMISSION_VIEW_BUTTON = 'FOLDER_PERMISSION_VIEW_BUTTON',
  FOLDER_PERMISSION_DELETE_BUTTON = 'FOLDER_PERMISSION_DELETE_BUTTON',
  FOLDER_PERMISSION_EDIT_BUTTON = 'FOLDER_PERMISSION_EDIT_BUTTON',
  FOLDER_PERMISSION_LICENCE_EXTRACTS_TABLE_VIEW = 'FOLDER_PERMISSION_LICENCE_EXTRACTS_TABLE_VIEW',
  FOLDER_PERMISSION_EXTRACTS_FROM_PERMITS_TABLE_VIEW = 'FOLDER_PERMISSION_EXTRACTS_FROM_PERMITS_TABLE_VIEW',
  FOLDER_PERMISSION_DRIVER_CERTIFICATES_TABLE_VIEW = 'FOLDER_PERMISSION_DRIVER_CERTIFICATES_TABLE_VIEW',
  FOLDER_PERMISSION_PERMITS_FOR_REGULAR_SERVICE_FROM_UE_TABLE_VIEW = 'FOLDER_PERMISSION_PERMITS_FOR_REGULAR_SERVICE_FROM_UE_TABLE_VIEW',
  FOLDER_PERMISSION_EXTRACTS_FOR_REGULAR_SERVICE_WITHIN_UE_TABLE_VIEW = 'FOLDER_PERMISSION_EXTRACTS_FOR_REGULAR_SERVICE_WITHIN_UE_TABLE_VIEW',
  FOLDER_PERMISSION_PERMITS_FOR_REGULAR_SERVICE_OUTSIDE_UE_TABLE_VIEW = 'FOLDER_PERMISSION_PERMITS_FOR_REGULAR_SERVICE_OUTSIDE_UE_TABLE_VIEW',
  FOLDER_PERMISSION_EXTRACTS_FOR_REGULAR_SERVICE_OUTSIDE_UE_TABLE_VIEW = 'FOLDER_PERMISSION_EXTRACTS_FOR_REGULAR_SERVICE_OUTSIDE_UE_TABLE_VIEW',
  FOLDER_PERMISSION_SHUTTLE_PERMITS_TABLE_VIEW = 'FOLDER_PERMISSION_SHUTTLE_PERMITS_TABLE_VIEW',
  FOLDER_PERMISSION_EXTRACTS_FROM_PERMIT_FOR_SHUTTLE_SERVICE_TABLE_VIEW = 'FOLDER_PERMISSION_EXTRACTS_FROM_PERMIT_FOR_SHUTTLE_SERVICE_TABLE_VIEW',
  FOLDER_PERMISSION_PERMITS_FOR_OCCASIONAL_SERVICE_TABLE_VIEW = 'FOLDER_PERMISSION_PERMITS_FOR_OCCASIONAL_SERVICE_TABLE_VIEW',
  FOLDER_PERMISSION_EXCLUSIONS_FROM_PERMIT_FOR_OCCASIONAL_SERVICE_TABLE_VIEW = 'FOLDER_PERMISSION_EXCLUSIONS_FROM_PERMIT_FOR_OCCASIONAL_SERVICE_TABLE_VIEW',
  FOLDER_PERMISSION_PERMITS_FOR_REGULAR_TRANSIT_IN_POLAND_TABLE_VIEW = 'FOLDER_PERMISSION_PERMITS_FOR_REGULAR_TRANSIT_IN_POLAND_TABLE_VIEW',
  FOLDER_PERMISSION_EXTRACTS_FROM_PERMIT_FOR_REGULAR_TRANSIT_IN_POLAND_TABLE_VIEW = 'FOLDER_PERMISSION_EXTRACTS_FROM_PERMIT_FOR_REGULAR_TRANSIT_IN_POLAND_TABLE_VIEW',
  FOLDER_PERMISSION_FOREIGN_PERMITS_TABLE_VIEW = 'FOLDER_PERMISSION_FOREIGN_PERMITS_TABLE_VIEW',
  FOLDER_PERMISSION_CERTIFICATES_FOR_OWN_ACCOUNT_TRANSPORT_TABLE_VIEW = 'FOLDER_PERMISSION_CERTIFICATES_FOR_OWN_ACCOUNT_TRANSPORT_TABLE_VIEW',
  FOLDER_PERMISSION_EXTRACTS_TO_CERTIFICATE_FOR_OWN_ACCOUNT_TRANSPORT_TABLE_VIEW = 'FOLDER_PERMISSION_EXTRACTS_TO_CERTIFICATE_FOR_OWN_ACCOUNT_TRANSPORT_TABLE_VIEW',
  FOLDER_FOREIGN_AUTHORITY_PERMISSION_TABLE_VIEW = 'FOLDER_FOREIGN_AUTHORITY_PERMISSION_TABLE_VIEW',
  FOLDER_FOREIGN_PERMISSION_TABLE_VIEW = 'FOLDER_FOREIGN_PERMISSION_TABLE_VIEW',
  FOLDER_APPLICATION_VIEW = 'FOLDER_APPLICATION_VIEW',
  FOLDER_APPLICATION_DETAILS_BUTTON = 'FOLDER_APPLICATION_DETAILS_BUTTON',
  FOLDER_APPLICATION_EDIT_BUTTON = 'FOLDER_APPLICATION_EDIT_BUTTON',
  FOLDER_APPLICATION_SHOW_SHORTAGE = 'FOLDER_APPLICATION_SHOW_SHORTAGE',
  FOLDER_APPLICATION_SHOW_DRIVER_DETAILS = 'FOLDER_APPLICATION_SHOW_DRIVER_DETAILS',
  FOLDER_FINANCIAL_SECURITY_VIEW = 'FOLDER_FINANCIAL_SECURITY_VIEW',
  FOLDER_FINANCIAL_SECURITY_CREATE_BUTTON = 'FOLDER_FINANCIAL_SECURITY_CREATE_BUTTON',
  FOLDER_FINANCIAL_SECURITY_DELETE_BUTTON = 'FOLDER_FINANCIAL_SECURITY_DELETE_BUTTON',
  FOLDER_FINANCIAL_SECURITY_EDIT_BUTTON = 'FOLDER_FINANCIAL_SECURITY_EDIT_BUTTON',
  FOLDER_FINANCIAL_SECURITY_WITHDRAW_BUTTON = 'FOLDER_FINANCIAL_SECURITY_WITHDRAW_BUTTON',
  FOLDER_FINANCIAL_SECURITY_RESTORE_BUTTON = 'FOLDER_FINANCIAL_SECURITY_RESTORE_BUTTON',
  FOLDER_INSURANCE_POLICY_VIEW = 'FOLDER_INSURANCE_POLICY_VIEW',
  FOLDER_INSURANCE_POLICY_CREATE_BUTTON = 'FOLDER_INSURANCE_POLICY_CREATE_BUTTON',
  FOLDER_INSURANCE_POLICY_EDIT_BUTTON = 'FOLDER_INSURANCE_POLICY_EDIT_BUTTON',
  FOLDER_INSURANCE_POLICY_DELETE_BUTTON = 'FOLDER_INSURANCE_POLICY_DELETE_BUTTON',
  FOLDER_TRANSPORT_MANAGER_VIEW = 'FOLDER_TRANSPORT_MANAGER_VIEW',
  FOLDER_TRANSPORT_MANAGER_EDIT_BUTTON = 'FOLDER_TRANSPORT_MANAGER_EDIT_BUTTON',
  FOLDER_TRANSPORT_MANAGER_EXPIRE_BUTTON = 'FOLDER_TRANSPORT_MANAGER_EXPIRE_BUTTON',
  FOLDER_TRANSPORT_MANAGER_RESTORE_BUTTON = 'FOLDER_TRANSPORT_MANAGER_RESTORE_BUTTON',
  FOLDER_DRIVER_VIEW = 'FOLDER_DRIVER_VIEW',
  FOLDER_DRIVER_EXPIRE_BUTTON = 'FOLDER_DRIVER_EXPIRE_BUTTON',
  FOLDER_DRIVER_ACTIVATE_BUTTON = 'FOLDER_DRIVER_ACTIVATE_BUTTON',
  FOLDER_DRIVER_GENERATE_UP_TO_DATE_REPORT_BUTTON = 'FOLDER_DRIVER_GENERATE_UP_TO_DATE_REPORT_BUTTON',
  FOLDER_DRIVER_GENERATE_ISSUED_REPORT_BUTTON = 'FOLDER_DRIVER_GENERATE_ISSUED_REPORT_BUTTON',
  FOLDER_DRIVER_DETAILS_BUTTON = 'FOLDER_DRIVER_DETAILS_BUTTON',
  FOLDER_VEHICLE_VIEW = 'FOLDER_VEHICLE_VIEW',
  FOLDER_VEHICLE_SHOW_DETAILS_BUTTON = 'FOLDER_VEHICLE_SHOW_DETAILS_BUTTON',
  FOLDER_VEHICLE_CREATE_BUTTON = 'FOLDER_VEHICLE_CREATE_BUTTON',
  FOLDER_VEHICLE_DELETE_BUTTON = 'FOLDER_VEHICLE_DELETE_BUTTON',
  FOLDER_VEHICLE_EDIT_BUTTON = 'FOLDER_VEHICLE_EDIT_BUTTON',
  FOLDER_VEHICLE_EXPIRE_BUTTON = 'FOLDER_VEHICLE_EXPIRE_BUTTON',
  FOLDER_VEHICLE_RESTORE_BUTTON = 'FOLDER_VEHICLE_RESTORE_BUTTON',
  FOLDER_EXPLOITATION_BASE_VIEW = 'FOLDER_EXPLOITATION_BASE_VIEW',
  FOLDER_EXPLOITATION_BASE_EDIT_BUTTON = 'FOLDER_EXPLOITATION_BASE_EDIT_BUTTON',
  FOLDER_EXPLOITATION_BASE_SHOW_DETAILS_BUTTON = 'FOLDER_EXPLOITATION_BASE_SHOW_DETAILS_BUTTON',
  FOLDER_EXPLOITATION_BASE_SHOW_GEOPORTAL_BUTTON = 'FOLDER_EXPLOITATION_BASE_SHOW_GEOPORTAL_BUTTON',
  FOLDER_EXPLOITATION_BASE_EXPIRE_BUTTON = 'FOLDER_EXPLOITATION_BASE_EXPIRE_BUTTON',
  FOLDER_EXPLOITATION_BASE_RESTORE_BUTTON = 'FOLDER_EXPLOITATION_BASE_RESTORE_BUTTON',
  FOLDER_PROCEEDING_VIEW = 'FOLDER_PROCEEDING_VIEW',
  FOLDER_PROCEEDING_CREATE_BUTTON = 'FOLDER_PROCEEDING_CREATE_BUTTON',
  FOLDER_PROCEEDING_DETAILS_BUTTON = 'FOLDER_PROCEEDING_DETAILS_BUTTON',
  FOLDER_PROCEEDING_DELETE_BUTTON = 'FOLDER_PROCEEDING_DELETE_BUTTON',
  FOLDER_SUSPENSION_VIEW = 'FOLDER_SUSPENSION_VIEW',
  FOLDER_SUSPENSION_CREATE_BUTTON = 'FOLDER_SUSPENSION_CREATE_BUTTON',
  FOLDER_RENTAL_VIEW = 'FOLDER_RENTAL_VIEW',
  FOLDER_DETAILS_VIEW = 'FOLDER_DETAILS_VIEW',
  FOLDER_RENTAL_CREATE_BUTTON = 'FOLDER_RENTAL_CREATE_BUTTON',
  FOLDER_RENTAL_RETURN_BUTTON = 'FOLDER_RENTAL_RETURN_BUTTON',
  FOLDER_RENTAL_CHANGE_BORROWER_BUTTON = 'FOLDER_RENTAL_CHANGE_BORROWER_BUTTON',
  FOLDER_LOCK_VIEW = 'FOLDER_LOCK_VIEW',
  FOLDER_LOCK_CREATE_BUTTON = 'FOLDER_LOCK_CREATE_BUTTON',
  FOLDER_LOCK_DELETE_BUTTON = 'FOLDER_LOCK_DELETE_BUTTON',
  FOLDER_UNBLOCK_PROCEEDING_DRIVER_CERTIFICATE_DELETE_BUTTON = 'FOLDER_UNBLOCK_PROCEEDING_DRIVER_CERTIFICATE_DELETE_BUTTON',
  FOLDER_NOTE_VIEW = 'FOLDER_NOTE_VIEW',
  FOLDER_NOTE_EDIT_BUTTON = 'FOLDER_NOTE_EDIT_BUTTON',
  FOLDER_HISTORY_VIEW = 'FOLDER_HISTORY_VIEW',
  FOLDER_COURIER_PARCEL_BLOCK_FIELD = 'FOLDER_COURIER_PARCEL_BLOCK_FIELD',
  FOLDER_SUBJECT_REPUTATION_SECTION = 'FOLDER_SUBJECT_REPUTATION_SECTION',
  FOLDER_TRANSPORT_MANAGERS_CHECK_IN_KREPTD_BUTTON = 'FOLDER_TRANSPORT_MANAGERS_CHECK_IN_KREPTD_BUTTON',
  FOLDER_TRANSPORT_MANAGERS_SHOW_REPUTATION_BUTTON = 'FOLDER_TRANSPORT_MANAGERS_SHOW_REPUTATION_BUTTON',

  // Administracja - Parametry systemowe
  FOLDER_TP_CREATE = 'FOLDER_TP_CREATE',
  FOLDER_OP_CREATE = 'FOLDER_OP_CREATE',
  FOLDER_PPO_CREATE = 'FOLDER_PPO_CREATE',
  FOLDER_MZ_CREATE = 'FOLDER_MZ_CREATE',
  FOLDER_PA_CREATE = 'FOLDER_PA_CREATE',
  FOLDER_KS_CREATE = 'FOLDER_KS_CREATE',
  FOLDER_WITD_CREATE = 'FOLDER_WITD_CREATE',
  FOLDER_ZZ_CREATE = 'FOLDER_ZZ_CREATE',
  FOLDER_DATE_OF_DEATH_SET_BUTTON = 'FOLDER_DATE_OF_DEATH_SET_BUTTON',
  FOLDER_DATE_OF_DEATH_UPDATE_BUTTON = 'FOLDER_DATE_OF_DEATH_UPDATE_BUTTON',
  FOLDER_MIGRATION_CONFIRM_BUTTON = 'FOLDER_MIGRATION_CONFIRM_BUTTON',
  FOLDER_ADD_ENTREPRENEURS = 'FOLDER_ADD_ENTREPRENEURS',
  FOLDER_CIVIL_PARTNERSHIP = 'FOLDER_CIVIL_PARTNERSHIP',
  FOLDER_PERMISSION_LIST_ACTIVE = 'FOLDER_PERMISSION_LIST_ACTIVE',
  FOLDER_PERMISSION_LIST_UNACTIVE = 'FOLDER_PERMISSION_LIST_UNACTIVE',
  FOLDER_DRIVERS_GO_TO_PROCEEDING = 'FOLDER_DRIVERS_GO_TO_PROCEEDING',

  // Stowarzyszenia
  ASSOCIATIONS_LIST = 'ASSOCIATIONS_LIST',
  ASSOCIATIONS_ADD_BUTTON = 'ASSOCIATIONS_ADD_BUTTON',
  ASSOCIATIONS_DELETE_BUTTON = 'ASSOCIATIONS_DELETE_BUTTON',
  ASSOCIATIONS_SHOW_DETAILS_BUTTON = 'ASSOCIATIONS_SHOW_DETAILS_BUTTON',
  ASSOCIATIONS_EDIT_BUTTON = 'ASSOCIATIONS_EDIT_BUTTON',

  // Stowarzyszenia - Przedsiębiorcy
  ASSOCIATIONS_DETAILS_ENTREPRENEURS_ADD_BUTTON = 'ASSOCIATIONS_DETAILS_ENTREPRENEURS_ADD_BUTTON',
  ASSOCIATIONS_DETAILS_ENTREPRENEURS_DELETE_BUTTON = 'ASSOCIATIONS_DETAILS_ENTREPRENEURS_DELETE_BUTTON',

  // Dokumenty EZD
  EZD_DOCUMENT_LIST = 'EZD_DOCUMENT_LIST',
  EZD_DOCUMENT_ADD_APPLICATION_BUTTON = 'EZD_DOCUMENT_ADD_APPLICATION_BUTTON',
  EZD_DOCUMENT_INCLUDE_DOCUMENT_BUTTON = 'EZD_DOCUMENT_INCLUDE_DOCUMENT_BUTTON',
  EZD_DOCUMENT_REJECT_BUTTON = 'EZD_DOCUMENT_REJECT_BUTTON',
  EZD_DOCUMENT_DETAILS_BUTTON = 'EZD_DOCUMENT_DETAILS_BUTTON',
  EZD_DOCUMENT_FILE_LIST_VIEW = 'EZD_DOCUMENT_FILE_LIST_VIEW',
  EZD_DOCUMENT_FILE_UPDATE = 'EZD_DOCUMENT_FILE_UPDATE',

  // Dokumenty wychodzace
  DOCUMENTS_ISSUED_LIST = 'DOCUMENTS_ISSUED_LIST',
  DOCUMENTS_ISSUED_SEND_BUTTON = 'DOCUMENTS_ISSUED_SEND_BUTTON',
  DOCUMENTS_ISSUED_ADDICTIONAL_SERVICES_BUTTON = 'DOCUMENTS_ISSUED_ADDICTIONAL_SERVICES_BUTTON',
  DOCUMENTS_ISSUED_CHANGE_ADDRESS_BUTTON = 'DOCUMENTS_ISSUED_CHANGE_ADDRESS_BUTTON',
  DOCUMENTS_ISSUED_EDIT_PARCEL_BUTTON = 'DOCUMENTS_ISSUED_EDIT_PARCEL_BUTTON',
  DOCUMENTS_ISSUED_VIEW_NOTES_BUTTON = 'DOCUMENTS_ISSUED_VIEW_NOTES_BUTTON',
  DOCUMENTS_ISSUED_CREATE_NOTE = 'DOCUMENTS_ISSUED_CREATE_NOTE',
  DOCUMENTS_ISSUED_CHANGE_SEND_OPTION_BUTTON = 'DOCUMENTS_ISSUED_CHANGE_SEND_OPTION_BUTTON',
  DOCUMENTS_ISSUED_CANCEL_SENDING_BUTTON = 'DOCUMENTS_ISSUED_CANCEL_SENDING_BUTTON',
  DOCUMENTS_ISSUED_CHANGE_DELIVERY_DATE_BUTTON = 'DOCUMENTS_ISSUED_CHANGE_DELIVERY_DATE_BUTTON',
  DOCUMENTS_ISSUED_SET_RETURN_DATE_BUTTON = 'DOCUMENTS_ISSUED_SET_RETURN_DATE_BUTTON',
  DOCUMENTS_ISSUED_CREATE_COMPLAINT_BUTTON = 'DOCUMENTS_ISSUED_CREATE_COMPLAINT_BUTTON',
  DOCUMENTS_ISSUED_GENERATE_LABEL_BUTTON = 'DOCUMENTS_ISSUED_GENERATE_LABEL_BUTTON',
  DOCUMENTS_ISSUED_GENERATE_BILLING_NOTE_BUTTON = 'DOCUMENTS_ISSUED_GENERATE_BILLING_NOTE_BUTTON',
  DOCUMENTS_ISSUED_SCAN_PACKAGES_BUTTON = 'DOCUMENTS_ISSUED_SCAN_PACKAGES_BUTTON',
  DOCUMENTS_ISSUED_GENERATE_LIST_FOR_COURIER_BUTTON = 'DOCUMENTS_ISSUED_GENERATE_LIST_FOR_COURIER_BUTTON',
  DOCUMENTS_ISSUED_PARCEL_SUMMARY_BUTTON = 'DOCUMENTS_ISSUED_PARCEL_SUMMARY_BUTTON',
  DOCUMENTS_ISSUED_EPUAP_SEND_BUTTON = 'DOCUMENTS_ISSUED_EPUAP_SEND_BUTTON',
  DOCUMENTS_ISSUED_E_MAIL_SEND_BUTTON = 'DOCUMENTS_ISSUED_E_MAIL_SEND_BUTTON',
  DOCUMENTS_ISSUED_E_MAIL_SEND_OPTION = 'DOCUMENTS_ISSUED_E_MAIL_SEND_OPTION',
  DOCUMENTS_ISSUED_COURIER_SEND_OPTION = 'DOCUMENTS_ISSUED_COURIER_SEND_OPTION',
  DOCUMENTS_ISSUED_EPUAP_SEND_OPTION = 'DOCUMENTS_ISSUED_EPUAP_SEND_OPTION',
  DOCUMENTS_ISSUED_PICKUP_IN_PERSON_SEND_OPTION = 'DOCUMENTS_ISSUED_PICKUP_IN_PERSON_SEND_OPTION',
  DOCUMENTS_ISSUED_POST_SEND_OPTION = 'DOCUMENTS_ISSUED_POST_SEND_OPTION',
  DOCUMENTS_ISSUED_PICKUP_IN_PERSON_ISSUE_BUTTON = 'DOCUMENTS_ISSUED_PICKUP_IN_PERSON_ISSUE_BUTTON',
  DOCUMENTS_ISSUED_POST_SEND_BUTTON = 'DOCUMENTS_ISSUED_POST_SEND_BUTTON',
  DOCUMENTS_ISSUED_COURIER_SEND_BUTTON = 'DOCUMENTS_ISSUED_COURIER_SEND_BUTTON',
  DOCUMENTS_ISSUED_GENERATE_DOCUMENT_BUTTON = 'DOCUMENTS_ISSUED_GENERATE_DOCUMENT_BUTTON',
  DOCUMENTS_ISSUED_DOCUMENTS_DOWNLOAD_BUTTON = 'DOCUMENTS_ISSUED_DOCUMENTS_DOWNLOAD_BUTTON',
  DOCUMENTS_ISSUED_DOCUMENTS_FORWARD_TO_SHIPMENT_BUTTON = 'DOCUMENTS_ISSUED_DOCUMENTS_FORWARD_TO_SHIPMENT_BUTTON',
  DOCUMENTS_ISSUED__DOCUMENTS_GENERATE_CALL_TO_FILL_GAPS_BUTTON = 'DOCUMENTS_ISSUED__DOCUMENTS_GENERATE_CALL_TO_FILL_GAPS_BUTTON',
  // Certyfikaty S
  S_CERTIFICATE_LIST = 'S_CERTIFICATE_LIST',
  S_CERTIFICATE_DETAILS_BUTTON = 'S_CERTIFICATE_DETAILS_BUTTON',
  S_CERTIFICATE_APPLICATION_CREATE_BUTTON = 'S_CERTIFICATE_APPLICATION_CREATE_BUTTON',
  S_CERTIFICATE_FOLDER_DETAILS_BUTTON = 'S_CERTIFICATE_FOLDER_DETAILS_BUTTON',

  // Archiwum
  ARCHIVE_MENU = 'ARCHIVE_MENU',
  ARCHIVE_APPLICATIONS_LIST = 'ARCHIVE_APPLICATIONS_LIST',
  ARCHIVE_FOLDER_LIST = 'ARCHIVE_FOLDER_LIST',
  ARCHIVE_BORROWED_LIST = 'ARCHIVE_BORROWED_LIST',
  ARCHIVE_APPLICATIONS_TO_ARCHIVE_VIEW = 'ARCHIVE_APPLICATIONS_TO_ARCHIVE_VIEW',
  ARCHIVE_APPLICATIONS_TO_ARCHIVE_SHOW_DETAILS_BUTTON = 'ARCHIVE_APPLICATIONS_TO_ARCHIVE_SHOW_DETAILS_BUTTON',
  ARCHIVE_APPLICATIONS_FORWARD_TO_ARCHIVE_BUTTON = 'ARCHIVE_APPLICATIONS_FORWARD_TO_ARCHIVE_BUTTON',
  ARCHIVE_APPLICATIONS_FORWARD_TO_ARCHIVE_MANY_BUTTON = 'ARCHIVE_APPLICATIONS_FORWARD_TO_ARCHIVE_MANY_BUTTON',
  ARCHIVE_APPLICATIONS_TO_ARCHIVE_ADD_NOTE_BUTTON = 'ARCHIVE_APPLICATIONS_TO_ARCHIVE_ADD_NOTE_BUTTON',
  ARCHIVE_APPLICATIONS_ARCHIVED_VIEW = 'ARCHIVE_APPLICATIONS_ARCHIVED_VIEW',
  ARCHIVE_APPLICATIONS_ARCHIVED_SHOW_DETAILS_BUTTON = 'ARCHIVE_APPLICATIONS_ARCHIVED_SHOW_DETAILS_BUTTON',
  ARCHIVE_APPLICATIONS_ARCHIVED_UNDO_FROM_ARCHIVE_BUTTON = 'ARCHIVE_APPLICATIONS_ARCHIVED_UNDO_FROM_ARCHIVE_BUTTON',
  ARCHIVE_APPLICATIONS_ARCHIVED_GENERATE_CASE_LIST_BUTTON = 'ARCHIVE_APPLICATIONS_ARCHIVED_GENERATE_CASE_LIST_BUTTON',
  ARCHIVE_APPLICATIONS_ARCHIVED_ADD_NOTE_BUTTON = 'ARCHIVE_APPLICATIONS_ARCHIVED_ADD_NOTE_BUTTON',
  ARCHIVE_FOLDER_TO_ARCHIVE_VIEW = 'ARCHIVE_FOLDER_TO_ARCHIVE_VIEW',
  ARCHIVE_FOLDER_TO_ARCHIVE_SHOW_DETAILS_BUTTON = 'ARCHIVE_FOLDER_TO_ARCHIVE_SHOW_DETAILS_BUTTON',
  ARCHIVE_FOLDER_TO_ARCHIVE_FORWARD_TO_ARCHIVE_BUTTON = 'ARCHIVE_FOLDER_TO_ARCHIVE_FORWARD_TO_ARCHIVE_BUTTON',
  ARCHIVE_FOLDER_TO_ARCHIVE_GENERATE_GENERATE_CASE_LIST_BUTTON = 'ARCHIVE_FOLDER_TO_ARCHIVE_GENERATE_GENERATE_CASE_LIST_BUTTON',
  ARCHIVE_FOLDER_TO_ARCHIVE_ADD_NOTE_BUTTON = 'ARCHIVE_FOLDER_TO_ARCHIVE_ADD_NOTE_BUTTON',
  ARCHIVE_FOLDER_CARRY_ON_ARCHIVE_FOLDER = 'ARCHIVE_FOLDER_CARRY_ON_ARCHIVE_FOLDER',
  ARCHIVE_FOLDER_ARCHIVED_VIEW = 'ARCHIVE_FOLDER_ARCHIVED_VIEW',
  ARCHIVE_FOLDER_ARCHIVED_SHOW_DETAILS_BUTTON = 'ARCHIVE_FOLDER_ARCHIVED_SHOW_DETAILS_BUTTON',
  ARCHIVE_FOLDER_ARCHIVED_UNDO_FROM_ARCHIVE_BUTTON = 'ARCHIVE_FOLDER_ARCHIVED_UNDO_FROM_ARCHIVE_BUTTON',
  ARCHIVE_FOLDER_ARCHIVED_ADD_NOTE_BUTTON = 'ARCHIVE_FOLDER_ARCHIVED_ADD_NOTE_BUTTON',
  ARCHIVE_BORROWED_SHOW_DETAILS_BUTTON = 'ARCHIVE_BORROWED_SHOW_DETAILS_BUTTON',
  ARCHIVE_BORROWED_BORROW_FOLDER_BUTTON = 'ARCHIVE_BORROWED_BORROW_FOLDER_BUTTON',
  ARCHIVE_BORROWED_RETURN_BUTTON = 'ARCHIVE_BORROWED_RETURN_BUTTON',
  ARCHIVE_BORROWED_CHANGE_BORROWER_BUTTON = 'ARCHIVE_BORROWED_CHANGE_BORROWER_BUTTON',
  ARCHIVE_FOLDER_ARCHIVED_GENERATE_CASE_LIST_BUTTON = 'ARCHIVE_FOLDER_ARCHIVED_GENERATE_CASE_LIST_BUTTON',

  // Weryfikacja uprawnień
  AUTHORIZATION_VERIFICATION_MENU = 'AUTHORIZATION_VERIFICATION_MENU',

  // Weryfikacja uprawnień - Postępowania
  PROCEEDING_LIST = 'PROCEEDING_LIST',
  PROCEEDING_CREATE_BUTTON = 'PROCEEDING_CREATE_BUTTON',
  PROCEEDING_VERIFICATION_EDIT_BUTTON = 'PROCEEDING_VERIFICATION_EDIT_BUTTON',
  PROCEEDING_VERIFICATION_DELETE_BUTTON = 'PROCEEDING_VERIFICATION_DELETE_BUTTON',
  PROCEEDING_VERIFICATION_VIEW_BUTTON = 'PROCEEDING_VERIFICATION_VIEW_BUTTON',
  PROCEEDING_ADMINISTRATIVE_EDIT_BUTTON = 'PROCEEDING_ADMINISTRATIVE_EDIT_BUTTON',
  PROCEEDING_ADMINISTRATIVE_DELETE_BUTTON = 'PROCEEDING_ADMINISTRATIVE_DELETE_BUTTON',
  PROCEEDING_ADMINISTRATIVE_VIEW_BUTTON = 'PROCEEDING_ADMINISTRATIVE_VIEW_BUTTON',
  PROCEEDING_CLOSE_BUTTON = 'PROCEEDING_CLOSE_BUTTON',
  PROCEEDING_SAVE_PROGRESS_BUTTON = 'PROCEEDING_SAVE_PROGRESS_BUTTON',
  PROCEEDING_EDIT_DELETE_BUTTON = 'PROCEEDING_EDIT_DELETE_BUTTON',
  PROCEEDING_EDIT_END_BUTTON = 'PROCEEDING_EDIT_END_BUTTON',
  PROCEEDING_EDIT_CANCEL_BUTTON = 'PROCEEDING_EDIT_CANCEL_BUTTON',
  PROCEEDING_EDIT_CHANGE_CONDUCTING_PERSON_BUTTON = 'PROCEEDING_EDIT_CHANGE_CONDUCTING_PERSON_BUTTON',
  PROCEEDING_DOCUMENTS_VIEW = 'PROCEEDING_DOCUMENTS_VIEW',
  PROCEEDING_DOCUMENTS_DOWNLOAD_BUTTON = 'PROCEEDING_DOCUMENTS_DOWNLOAD_BUTTON',
  PROCEEDING_DOCUMENTS_UPLOAD_BUTTON = 'PROCEEDING_DOCUMENTS_UPLOAD_BUTTON',
  PROCEEDING_DOCUMENTS_CANCEL_BUTTON = 'PROCEEDING_DOCUMENTS_CANCEL_BUTTON',
  PROCEEDING_DOCUMENTS_ADD_DELIVERY_DATE_BUTTON = 'PROCEEDING_DOCUMENTS_ADD_DELIVERY_DATE_BUTTON',
  PROCEEDING_DOCUMENTS_UNCHECK_FINAL_DECISION_BUTTON = 'PROCEEDING_DOCUMENTS_UNCHECK_FINAL_DECISION_BUTTON',
  PROCEEDING_DOCUMENTS_CHECK_FINAL_DECISION_BUTTON = 'PROCEEDING_DOCUMENTS_CHECK_FINAL_DECISION_BUTTON',
  PROCEEDING_DOCUMENTS_REVOKE_FINAL_DECISION_BUTTON = 'PROCEEDING_DOCUMENTS_REVOKE_FINAL_DECISION_BUTTON',
  PROCEEDING_FORM_OPERATIONS_VIEW = 'PROCEEDING_FORM_OPERATIONS_VIEW',
  PROCEEDING_FORM_OPERATIONS_ADD_BUTTON = 'PROCEEDING_FORM_OPERATIONS_ADD_BUTTON',
  PROCEEDING_FORM_OPERATIONS_SHOW_ACTIVE_PERMISSIONS_BUTTON = 'PROCEEDING_FORM_OPERATIONS_SHOW_ACTIVE_PERMISSIONS_BUTTON',
  PROCEEDING_FORM_OPERATIONS_DELETE_BUTTON = 'PROCEEDING_FORM_OPERATIONS_DELETE_BUTTON',
  PROCEEDING_FORM_OPERATIONS_EXPIRE_BUTTON = 'PROCEEDING_FORM_OPERATIONS_EXPIRE_BUTTON',
  PROCEEDING_FORM_OPERATIONS_UNDO_BUTTON = 'PROCEEDING_FORM_OPERATIONS_UNDO_BUTTON',
  PROCEEDING_FORM_OPERATIONS_CHANGE_ACTIVE_BUTTON = 'PROCEEDING_FORM_OPERATIONS_CHANGE_ACTIVE_BUTTON',
  PROCEEDING_FORM_OPERATIONS_SUSPEND_BUTTON = 'PROCEEDING_FORM_OPERATIONS_SUSPEND_BUTTON',
  PROCEEDING_FORM_OPERATIONS_SUSPEND_ISSUING_BUTTON = 'PROCEEDING_FORM_OPERATIONS_SUSPEND_ISSUING_BUTTON',
  PROCEEDING_FORM_OPERATIONS_EDIT_ISSUING_BUTTON = 'PROCEEDING_FORM_OPERATIONS_EDIT_ISSUING_BUTTON',
  PROCEEDING_FORM_OPERATIONS_UNSUSPEND_ISSUING_BUTTON = 'PROCEEDING_FORM_OPERATIONS_UNSUSPEND_ISSUING_BUTTON',
  PROCEEDING_NOTE_VIEW = 'PROCEEDING_NOTE_VIEW',
  PROCEEDING_NOTE_SAVE_BUTTON = 'PROCEEDING_NOTE_SAVE_BUTTON',
  PROCEEDING_NOTE_CANCEL_BUTTON = 'PROCEEDING_NOTE_CANCEL_BUTTON',
  PROCEEDING_FILES_VIEW = 'PROCEEDING_FILES_VIEW',
  PROCEEDING_FILES_UPLOAD_BUTTON = 'PROCEEDING_FILES_UPLOAD_BUTTON',
  PROCEEDING_FILES_DELETE_BUTTON = 'PROCEEDING_FILES_DELETE_BUTTON',
  PROCEEDING_FILES_DOWNLOAD_BUTTON = 'PROCEEDING_FILES_DOWNLOAD_BUTTON',
  PROCEEDING_VERIFICATION_SELECT_OPTION = 'PROCEEDING_VERIFICATION_SELECT_OPTION',
  PROCEEDING_ADMINISTRATIVE_SELECT_OPTION = 'PROCEEDING_ADMINISTRATIVE_SELECT_OPTION',

  // Weryfikacja uprawnień - Zawieszenia
  SUSPENSIONS_LIST = 'SUSPENSIONS_VIEW_LIST',
  SUSPENSIONS_EDIT_BUTTON = 'SUSPENSIONS_EDIT_BUTTON',
  SUSPENSIONS_CLOSE_BUTTON = 'SUSPENSIONS_CLOSE_BUTTON',
  SUSPENSIONS_DELETE_BUTTON = 'SUSPENSIONS_DELETE_BUTTON',
  SUSPENSIONS_VIEW_BUTTON = 'SUSPENSIONS_VIEW_BUTTON',
  SUSPENSIONS_EDIT_SAVE_BUTTON = 'SUSPENSIONS_SAVE_BUTTON',
  SUSPENSIONS_EDIT_END_BUTTON = 'SUSPENSIONS_END_BUTTON',
  SUSPENSIONS_EDIT_CANCEL_BUTTON = 'SUSPENSIONS_CANCEL_BUTTON',
  SUSPENSIONS_EDIT_DELETE_BUTTON = 'SUSPENSIONS_EDIT_DELETE_BUTTON',
  SUSPENSIONS_DETAILS_VIEW = 'SUSPENSIONS_DETAILS_VIEW',
  SUSPENSIONS_FORM_OPERATIONS_VIEW = 'SUSPENSIONS_FORM_OPERATIONS_VIEW',
  SUSPENSIONS_FORM_OPERATIONS_ADD_BUTTON = 'SUSPENSIONS_FORM_OPERATIONS_ADD_BUTTON',
  SUSPENSIONS_FORM_OPERATIONS_DELETE_BUTTON = 'SUSPENSIONS_FORM_OPERATIONS_DELETE_BUTTON',
  SUSPENSIONS_FORM_OPERATIONS_RESUME_BUTTON = 'SUSPENSIONS_FORM_OPERATIONS_RESUME_BUTTON',
  SUSPENSIONS_FORM_OPERATIONS_SUSPEND_BUTTON = 'SUSPENSIONS_FORM_OPERATIONS_SUSPEND_BUTTON',
  SUSPENSIONS_FORM_OPERATIONS_SHOW_ACTIVE_PERMISSIONS_BUTTON = 'SUSPENSIONS_FORM_OPERATIONS_SHOW_ACTIVE_PERMISSIONS_BUTTON',

  SUSPENSIONS_RELEASE_DOCUMENTS_DETAILS_BUTTON = 'SUSPENSIONS_RELEASE_DOCUMENTS_DETAILS_BUTTON',
  SUSPENSIONS_RELEASE_DOCUMENTS_DOWNLOAD_BUTTON = 'SUSPENSIONS_RELEASE_DOCUMENTS_DOWNLOAD_BUTTON',

  SUSPENSIONS_NOTE_VIEW = 'SUSPENSIONS_NOTE_VIEW',
  SUSPENSIONS_NOTE_SAVE_BUTTON = 'SUSPENSIONS_NOTE_SAVE_BUTTON',
  SUSPENSIONS_NOTE_DELETE_BUTTON = 'SUSPENSIONS_NOTE_DELETE_BUTTON',
  SUSPENSIONS_NOTE_EDIT_BUTTON = 'SUSPENSIONS_NOTE_EDIT_BUTTON',
  SUSPENSIONS_DOCUMENTS_VIEW = 'SUSPENSIONS_DOCUMENTS_VIEW',
  SUSPENSIONS_DOCUMENTS_UPLOAD_BUTTON = 'SUSPENSIONS_DOCUMENTS_UPLOAD_BUTTON',
  SUSPENSIONS_DOCUMENTS_ADD_DELIVERY_DATE_BUTTON = 'SUSPENSIONS_DOCUMENTS_ADD_DELIVERY_DATE_BUTTON',
  SUSPENSIONS_DOCUMENTS_CANCEL_BUTTON = 'SUSPENSIONS_DOCUMENTS_CANCEL_BUTTON',
  SUSPENSIONS_FILES_VIEW = 'SUSPENSIONS_FILES_VIEW',
  SUSPENSIONS_FILES_UPLOAD_BUTTON = 'SUSPENSIONS_FILES_UPLOAD_BUTTON',
  SUSPENSIONS_FILES_DELETE_BUTTON = 'SUSPENSIONS_FILES_DELETE_BUTTON',
  SUSPENSIONS_FILES_DOWNLOAD_BUTTON = 'SUSPENSIONS_FILES_DOWNLOAD_BUTTON',
  CARRY_ON_ARCHIVED_FOLDER_SHOW_DETAILS_BUTTON = 'CARRY_ON_ARCHIVED_FOLDER_SHOW_DETAILS_BUTTON',
  SUSPENSIONS_CORRECTION_CREATE = 'SUSPENSIONS_CORRECTION_CREATE',
  SUSPENSIONS_CORRECTION_LIST = 'SUSPENSIONS_CORRECTION_LIST',
  SUSPENSIONS_CORRECTION_UPDATE = 'SUSPENSIONS_CORRECTION_UPDATE',
  SUSPENSIONS_CORRECTION_CANCEL = 'SUSPENSIONS_CORRECTION_CANCEL',

  // Magazyny - Lista magazynów
  RESOURCES_LIST = 'RESOURCES_LIST',
  RESOURCES_DELETE_RESOURCE_TYPE_BUTTON = 'RESOURCES_DELETE_RESOURCE_TYPE_BUTTON',

  // Magazyny - Przesunięcia pomiędzy magazynami
  RESOURCES_TRANSFERS_CONFIRM_RESOURCE_BUTTON = 'RESOURCES_TRANSFERS_CONFIRM_RESOURCE_BUTTON',
  RESOURCES_TRANSFERS_DELETE_BUTTON = 'RESOURCES_TRANSFERS_DELETE_BUTTON',
  // Magazyny- Typy blankietów
  RESOURCES_FORM_TYPES_EDIT_BUTTON = 'RESOURCES_FORM_TYPES_EDIT_BUTTON',
  RESOURCES_FORM_TYPES_DELETE_BUTTON = 'RESOURCES_FORM_TYPES_DELETE_BUTTON',
  // Magazyny - Szczegóły magazynu
  RESOURCES_DETAILS_SAVE_BUTTON = 'RESOURCES_DETAILS_SAVE_BUTTON',
  RESOURCES_DETAILS_REJECT_BUTTON = 'RESOURCES_DETAILS_REJECT_BUTTON',
  RESOURCES_DETAILS_BATCH_DISPOSALS_BUTTON = 'RESOURCES_DETAILS_BATCH_DISPOSALS_BUTTON',

  // Magazyny - Wyniki wyszukiwania blankietu
  RESOURCES_SEARCH_SETTLEMENT_BUTTON = 'RESOURCES_SEARCH_SETTLEMENT_BUTTON',

  // Magazyny
  // Przyjęcie zewnętrzne
  RESOURCES_EXTERNAL_RECEIVE = 'RESOURCES_EXTERNAL_RECEIVE',
  // Przesunięcie blankietów pomiędzy magazynami
  RESOURCES_OBJECT_TRANSFER = 'RESOURCES_OBJECT_TRANSFER',
  // Typy blankietów
  RESOURCES_FORM_TYPES = 'RESOURCES_FORM_TYPES',
  // Wyszukanie blankietu
  RESOURCES_OBJECT_SEARCH = 'RESOURCES_OBJECT_SEARCH',
  // Przeglądanie szczegółów magazynu
  RESOURCES_DEPOT_DETAILS_VIEW = 'RESOURCES_DEPOT_DETAILS_VIEW',
  // Zmiana administratorów
  RESOURCES_DEPOT_CHANGE_ADMIN = 'RESOURCES_DEPOT_CHANGE_ADMIN',
  // Dodanie administratora
  RESOURCES_DEPOT_ADD_ADMIN = 'RESOURCES_DEPOT_ADD_ADMIN',
  // Usunięcie administratora
  RESOURCES_DEPOT_DELETE_ADMIN = 'RESOURCES_DEPOT_DELETE_ADMIN',
  // Wydanie zewnętrzne
  RESOURCES_EXTERNAL_RELEASE = 'RESOURCES_EXTERNAL_RELEASE',
  // Wyświetlenie listy magazynów
  RESOURCES_DEPOT_LIST = 'RESOURCES_DEPOT_LIST',
  // Edycja magazynu
  RESOURCES_DEPOT_EDIT = 'RESOURCES_DEPOT_EDIT',
  // Utworzenie nowego typu blankietu
  RESOURCES_DEPOT_CREATE_OBJECT_TYPE = 'RESOURCES_DEPOT_CREATE_OBJECT_TYPE',
  // Brakowanie
  RESOURCES_DEFECTIVE_CHANGE = 'RESOURCES_DEFECTIVE_CHANGE',
  // Zarządzanie typami blankietów
  RESOURCES_OBJECT_TYPE_MANAGEMENT = 'RESOURCES_OBJECT_TYPE_MANAGEMENT',
  // Zainicjowanie przesunięcia
  RESOURCES_OBJECT_TRANSFER_INIT = 'RESOURCES_OBJECT_TRANSFER_INIT',
  // Usunięcie typu blankietu
  RESOURCES_OBJECT_TYPE_DELETE = 'RESOURCES_OBJECT_TYPE_DELETE',
  // Wywołanie raportu Zestawienie stanów magazynowych)
  RESOURCES_DEPOT_STATE_REPORT = 'RESOURCES_DEPOT_STATE_REPORT',
  // Potwierdzenie otrzymania blankietów
  RESOURCES_OBJECT_CONFIRM = 'RESOURCES_OBJECT_CONFIRM',
  // Zwracanie zezwolenia
  RESOURCES_OBJECT_RETURN = 'RESOURCES_OBJECT_RETURN',
  // Cofanie zwrotu zezwolenia
  RESOURCES_OBJECT_RETURN_ROLLBACK = 'RESOURCES_OBJECT_RETURN_ROLLBACK',

  // Dokumenty wydania
  RELEASE_DOCUMENT_LIST = 'RELEASE_DOCUMENT_LIST',
  RELEASE_DOCUMENT_ADD_CORRECTION_BUTTON = 'RELEASE_DOCUMENT_ADD_CORRECTION_BUTTON',
  RELEASE_DOCUMENT_EDIT_BUTTON = 'RELEASE_DOCUMENT_EDIT_BUTTON',
  RELEASE_DOCUMENT_DETAILS_BUTTON = 'RELEASE_DOCUMENT_DETAILS_BUTTON',
  RELEASE_DOCUMENT_CANCEL_BUTTON = 'RELEASE_DOCUMENT_CANCEL_BUTTON',
  RELEASE_DOCUMENT_DOWNLOAD_DOCUMENT_BUTTON = 'RELEASE_DOCUMENT_DOWNLOAD_DOCUMENT_BUTTON',
  RELEASE_DOCUMENT_CONFIRM_BUTTON = 'RELEASE_DOCUMENT_CONFIRM_BUTTON',
  RELEASE_DOCUMENT_CREATE_CORRECTION_BUTTON = 'RELEASE_DOCUMENT_CREATE_CORRECTION_BUTTON',

  // Kierowcy
  DRIVER_LIST = 'DRIVER_LIST',
  DRIVER_EXPIRE_BUTTON = 'DRIVER_EXPIRE_BUTTON',
  DRIVER_ACTIVATE_BUTTON = 'DRIVER_ACTIVATE_BUTTON',
  DRIVER_GENERATE_UP_TO_DATE_REPORT_BUTTON = 'DRIVER_GENERATE_UP_TO_DATE_REPORT_BUTTON',
  DRIVER_GENERATE_ISSUED_REPORT_BUTTON = 'DRIVER_GENERATE_ISSUED_REPORT_BUTTON',
  DRIVER_DETAILS_BUTTON = 'DRIVER_DETAILS_BUTTON',
  DRIVER_EDIT_BUTTON = 'DRIVER_EDIT_BUTTON',
  DRIVER_GENERATE_EXPIRE_REPORT_BUTTON = 'DRIVER_GENERATE_EXPIRE_REPORT_BUTTON',
  DRIVER_GO_TO_PROCEEDING = 'DRIVER_GO_TO_PROCEEDING',

  // Transport drogowy KREPTD - Naruszenia
  ROAD_TRANSPORT_LIST = 'ROAD_TRANSPORT_LIST',
  ROAD_TRANSPORT_VIOLATIONS_VIEW = 'ROAD_TRANSPORT_VIOLATIONS_VIEW',
  ROAD_TRANSPORT_VIEW_BUTTON = 'ROAD_TRANSPORT_VIEW_BUTTON',
  ROAD_TRANSPORT_CHANGE_STATUS_BUTTON = 'ROAD_TRANSPORT_CHANGE_STATUS_BUTTON',
  ROAD_TRANSPORT_TRANSFER_BUTTON = 'ROAD_TRANSPORT_TRANSFER_BUTTON',
  ROAD_TRANSPORT_START_PROCEEDING_BUTTON = 'ROAD_TRANSPORT_START_PROCEEDING_BUTTON',
  ROAD_TRANSPORT_ADD_TO_PROCEEDING_BUTTON = 'ROAD_TRANSPORT_ADD_TO_PROCEEDING_BUTTON',

  // Transport drogowy KREPTD - Postępownia administracyjne - Lista
  ROAD_TRANSPORT_PROCEEDING_VIEW = 'ROAD_TRANSPORT_PROCEEDING_VIEW',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_BUTTON',
  ROAD_TRANSPORT_PROCEEDING_CHANGE_STATUS_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_CHANGE_STATUS_BUTTON',

  // Transport drogowy KREPTD - Postępownia administracyjne - Szczegóły
  ROAD_TRANSPORT_PROCEEDING_DETAILS_VIEW = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_VIEW',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_START_SECTION = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_START_SECTION',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_DECISION_SECTION = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_DECISION_SECTION',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_LICENSE_EXCERPTS = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_LICENSE_EXCERPTS',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_SECOND_TIER_SECTION = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_SECOND_TIER_SECTION',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_CHANGE_FOLDER_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_CHANGE_FOLDER_BUTTON',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_CHANGE_STATUS_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_CHANGE_STATUS_BUTTON',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_SAVE_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_SAVE_BUTTON',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_GOOD_REPUTATION_EDIT_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_GOOD_REPUTATION_EDIT_BUTTON',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_DECLARATION_OF_INABILITY_EDIT_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_DECLARATION_OF_INABILITY_EDIT_BUTTON',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_DISCHARGE_AND_SUSPENSION_OF_CERTIFICATES_EDIT_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_DISCHARGE_AND_SUSPENSION_OF_CERTIFICATES_EDIT_BUTTON',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_IMPOSING_PENALTY_EDIT_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_IMPOSING_PENALTY_EDIT_BUTTON',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_PERMIT_SUSPENSION_EDIT_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_PERMIT_SUSPENSION_EDIT_BUTTON',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_RESTORE_OF_ABILITIES_EDIT_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_RESTORE_OF_ABILITIES_EDIT_BUTTON',

  ROAD_TRANSPORT_PROCEEDING_DETAILS_DOCUMENTS_VIEW = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_DOCUMENTS_VIEW',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_GENERATE_CONFIRM_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_GENERATE_CONFIRM_BUTTON',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_GENERATE_RELEASE_DOCUMENTS_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_GENERATE_RELEASE_DOCUMENTS_BUTTON',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_UPLOAD_DOCUMENT_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_UPLOAD_DOCUMENT_BUTTON',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_ADD_RECIEVE_DATE_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_ADD_RECIEVE_DATE_BUTTON',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_CANCEL_DOCUMENT_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_CANCEL_DOCUMENT_BUTTON',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_FINAL_DECISION = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_FINAL_DECISION',

  ROAD_TRANSPORT_PROCEEDING_DETAILS_CASH_PENALTY_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_CASH_PENALTY_BUTTON',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_DECISION_SUSPENSION_SECTION = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_DECISION_SUSPENSION_SECTION',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_CERTIFICATES_RETURN_SECTION = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_CERTIFICATES_RETURN_SECTION',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_RESTORATION_PROCEEDING_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_RESTORATION_PROCEEDING_BUTTON',

  ROAD_TRANSPORT_PROCEEDING_DETAILS_VIOLATIONS_VIEW = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_VIOLATIONS_VIEW',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_VIOLATIONS_DETAILS_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_VIOLATIONS_DETAILS_BUTTON',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_VIOLATIONS_CHANGE_STATUS_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_VIOLATIONS_CHANGE_STATUS_BUTTON',

  ROAD_TRANSPORT_PROCEEDING_DETAILS_TRANSPORT_MANAGERS_VIEW = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_TRANSPORT_MANAGERS_VIEW',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_CHECK_KREPTD_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_CHECK_KREPTD_BUTTON',
  ROAD_TRANSPORT_PROCEEDING_DETAILS_SHOW_REPUTATION_BUTTON = 'ROAD_TRANSPORT_PROCEEDING_DETAILS_SHOW_REPUTATION_BUTTON',

  ROAD_TRANSPORT_FOLDERS_TO_HANDED_OVER_VIEW = 'ROAD_TRANSPORT_FOLDERS_TO_HANDED_OVER_VIEW',
  ROAD_TRANSPORT_FOLDERS_TO_HANDED_OVER_TRANSFER_BUTTON = 'ROAD_TRANSPORT_FOLDERS_TO_HANDED_OVER_TRANSFER_BUTTON',
  ROAD_TRANSPORT_FOLDERS_TO_HANDED_OVER_CHANGE_STATUS_BUTTON = 'ROAD_TRANSPORT_FOLDERS_TO_HANDED_OVER_CHANGE_STATUS_BUTTON',
  ROAD_TRANSPORT_FOLDERS_TO_HANDED_OVER_GENERATE_DOCUMENT_BUTTON = 'ROAD_TRANSPORT_FOLDERS_TO_HANDED_OVER_GENERATE_DOCUMENT_BUTTON',
  ROAD_TRANSPORT_FOLDERS_TO_ASSIGN_HANDED_OVER_GENERATE_DOCUMENT_BUTTON = 'ROAD_TRANSPORT_FOLDERS_TO_ASSIGN_HANDED_OVER_GENERATE_DOCUMENT_BUTTON',

  // Zezwolenia zagraniczne
  FOREIGN_PERMITS_LIST_TAB = 'FOREIGN_PERMITS_LIST_TAB',
  RELEASED_PERMITS_LIST_TAB = 'RELEASED_PERMITS_LIST_TAB',
  LIMITS_LIST_TAB = 'LIMITS_LIST_TAB',
  DISPOSABLE_PERMITS_LIST_TAB = 'DISPOSABLE_PERMITS_LIST_TAB',
  EKMT_LIST_TAB = 'EKMT_LIST_TAB',
  SOCIAL_COMMISION_LIST_TAB = 'SOCIAL_COMMISION_LIST_TAB',
  PRINT_REPORT_CONFIRMATION = 'PRINT_REPORT_CONFIRMATION',
  PERMITS_RELEASED_VIEW = 'PERMITS_RELEASED_VIEW',
  PERMITS_RELEASED_EDIT = 'PERMITS_RELEASED_EDIT',
  PERMITS_RELEASED_SETTLEMENT_BUTTON = 'PERMITS_RELEASED_SETTLEMENT_BUTTON',
  PERMITS_DISPOSABLE_VIEW = 'PERMITS_DISPOSABLE_VIEW',
  PERMITS_DISPOSABLE_SHOW_DETAILS_VIEW = 'PERMITS_DISPOSABLE_SHOW_DETAILS_VIEW',
  PERMITS_EKMT_VIEW = 'PERMITS_EKMT_VIEW',
  PERMITS_DISPOSABLE_REPORT_GENERATE_BUTTON = 'PERMITS_DISPOSABLE_REPORT_GENERATE_BUTTON',

  SOCIAL_COMMITTEE_DATE_OF_MEETINGS_TAB = 'SOCIAL_COMMITTEE_DATE_OF_MEETINGS_TAB',
  SOCIAL_COMMITTEE_FREE_DISPOSAL_RULE_LIST_VIEW = 'SOCIAL_COMMITTEE_FREE_DISPOSAL_RULE_LIST_VIEW',
  SOCIAL_COMMITTEE_LIMIT_LIST_VIEW = 'SOCIAL_COMMITTEE_LIMIT_LIST_VIEW',
  SOCIAL_COMMITTEE_SINGLE_PERMIT_APPLICATION = 'SOCIAL_COMMITTEE_SINGLE_PERMIT_APPLICATION',
  SOCIAL_COMMITTEE_EKMT_APPLICATION = 'SOCIAL_COMMITTEE_EKMT_APPLICATION',
  SOCIAL_COMMITTEE_APPLICATIONS = 'SOCIAL_COMMITTEE_APPLICATIONS',

  COMMISSION_EKMT_LIST_VIEW = 'COMMISSION_EKMT_LIST_VIEW',
  COMMISSION_EKMT_EDIT_BUTTON = 'COMMISSION_EKMT_EDIT_BUTTON',
  COMMISSION_EKMT_DELETE_BUTTON = 'COMMISSION_EKMT_DELETE_BUTTON',
  COMMISSION_EKMT_ADD_BUTTON = 'COMMISSION_EKMT_ADD_BUTTON',
  COMMISSION_DEADLINES_VIEW = 'COMMISSION_DEADLINES_VIEW',
  COMMISSION_PETITION_TAB = 'COMMISSION_PETITION_TAB',
  COMMISSION_DISPOSABLE_VIEW = 'COMMISSION_DISPOSABLE_VIEW',
  COMMISSION_DISPOSABLE_ADD_BUTTON = 'COMMISSION_DISPOSABLE_ADD_BUTTON',
  COMMISSION_DISPOSABLE_DELETE_BUTTON = 'COMMISSION_DISPOSABLE_DELETE_BUTTON',
  COMMISSION_DISPOSABLE_EDIT_BUTTON = 'COMMISSION_DISPOSABLE_EDIT_BUTTON',
  COMMISSION_TRADE_RULES_VIEW = 'COMMISSION_TRADE_RULES_VIEW',
  COMISSION_DISPOSABLE_EXPORT = 'COMISSION_DISPOSABLE_EXPORT',
  COMISSION_DISPOSABLE_IMPORT = 'COMISSION_DISPOSABLE_IMPORT',
  COMISSION_EKMT_EXPORT = 'COMISSION_EKMT_EXPORT',
  COMISSION_EKMT_IMPORT = 'COMISSION_EKMT_IMPORT',
  COMISSION_EKMT_DETAILS = 'COMISSION_EKMT_DETAILS',
  COMMISSION_DISPOSABLE_DECISION_ACTION_BUTTON = 'COMMISSION_DISPOSABLE_DECISION_ACTION_BUTTON',
  COMMISSION_DECISION_UPDATE_BUTTON = 'COMMISSION_DECISION_UPDATE_BUTTON',
  COMMISSION_DEADLINES_ADD = 'COMMISSION_DEADLINES_ADD',
  COMMISSION_DEADLINES_EDIT = 'COMMISSION_DEADLINES_EDIT',
  COMMISSION_DEADLINES_DELETE = 'COMMISSION_DEADLINES_DELETE',
  COMMISSION_TRADE_RULES_RECALCULATE = 'COMMISSION_TRADE_RULES_RECALCULATE',
  COMMISSION_TRADE_RULES_EDIT = 'COMMISSION_TRADE_RULES_EDIT',
  COMMISSION_TRADE_RULES_ADD = 'COMMISSION_TRADE_RULES_ADD',
  COMMISSION_TRADE_RULES_DELETE = 'COMMISSION_TRADE_RULES_DELETE',
  COMMISSION_BASIC_RULES_RECALCULATE = 'COMMISSION_BASIC_RULES_RECALCULATE',
  COMMISSION_BASIC_RULES_DETAILS = 'COMMISSION_BASIC_RULES_DETAILS',
  COMMISSION_BASIC_RULES_EDIT = 'COMMISSION_BASIC_RULES_EDIT',
  COMMISSION_BASIC_RULES_ADD = 'COMMISSION_BASIC_RULES_ADD',
  COMMISSION_BASIC_RULES_DELETE = 'COMMISSION_BASIC_RULES_DELETE',

  // Wydruki
  PRINT_LIST = 'PRINT_LIST',
  PRINT_ASSIGN_TO_ME_BUTTON = 'PRINT_ASSIGN_TO_ME_BUTTON',
  PRINT_SHOW_EDIT_VIEW_BUTTON = 'PRINT_SHOW_EDIT_VIEW_BUTTON',
  PRINT_SET_PRINTING_PARAMETERS_BUTTON = 'PRINT_SET_PRINTING_PARAMETERS_BUTTON',
  PRINT_GO_TO_RESOURCE_DETAILS_BUTTON = 'PRINT_GO_TO_RESOURCE_DETAILS_BUTTON',
  PRINT_GENERATE_INSTRUCTION_BUTTON = 'PRINT_GENERATE_INSTRUCTION_BUTTON',
  PERMISSION_ALL_PRINT_BUTTON = 'PERMISSION_ALL_PRINT_BUTTON',
  PERMISSION_PRINT_BUTTON = 'PERMISSION_PRINT_BUTTON',
  PRINT_DATA_UPDATE_BUTTON = 'PRINT_DATA_UPDATE_BUTTON',
  LICENCE_TABLE_VIEW = 'LICENCE_TABLE_VIEW',
  LICENCE_COPY_TABLE_VIEW = 'LICENCE_COPY_TABLE_VIEW',
  PERMIT_TABLE_VIEW = 'PERMIT_TABLE_VIEW',
  PERMIT_COPY_TABLE_VIEW = 'PERMIT_COPY_TABLE_VIEW',
  DRIVER_CERTIFICATE_TABLE_VIEW = 'DRIVER_CERTIFICATE_TABLE_VIEW',
  REGULAR_UE_TRANSPORT_PERMISSIONS_TABLE_VIEW = 'REGULAR_UE_TRANSPORT_PERMISSIONS_TABLE_VIEW',
  REGULAR_UE_TRANSPORT_PERMISSIONS_COPY_TABLE_VIEW = 'REGULAR_UE_TRANSPORT_PERMISSIONS_COPY_TABLE_VIEW',
  REGULAR_TRANSIT_OUTSIDE_EU_PERMISSIONS_TABLE_VIEW = 'REGULAR_TRANSIT_OUTSIDE_EU_PERMISSIONS_TABLE_VIEW',
  REGULAR_TRANSIT_THROUGH_RP_PERMISSIONS_TABLE_VIEW = 'REGULAR_TRANSIT_THROUGH_RP_PERMISSIONS_TABLE_VIEW',
  REGULAR_BEYOND_UE_TRANSPORT_PERMISSIONS_EXTRACT_TABLE_VIEW = 'REGULAR_BEYOND_UE_TRANSPORT_PERMISSIONS_EXTRACT_TABLE_VIEW',
  REGULAR_TRANSIT_THROUGH_RP_PERMISSIONS_EXTRACT_TABLE_VIEW = 'REGULAR_TRANSIT_THROUGH_RP_PERMISSIONS_EXTRACT_TABLE_VIEW',
  SHUTTLE_SERVICES_PERMISISONS_TABLE_VIEW = 'SHUTTLE_SERVICES_PERMISISONS_TABLE_VIEW',
  SHUTTLE_SERVICES_PERMISISONS_EXTRACT_TABLE_VIEW = 'SHUTTLE_SERVICES_PERMISISONS_EXTRACT_TABLE_VIEW',
  OCCASIONAL_TRANSPORT_PERMISSIONS_TABLE_VIEW = 'OCCASIONAL_TRANSPORT_PERMISSIONS_TABLE_VIEW',
  OCCASIONAL_TRANSPORT_PERMISSIONS_EXTRACT_TABLE_VIEW = 'OCCASIONAL_TRANSPORT_PERMISSIONS_EXTRACT_TABLE_VIEW',
  OWN_TRANSPORT_CERTIFICATES_TABLE_VIEW = 'OWN_TRANSPORT_CERTIFICATES_TABLE_VIEW',
  OWN_TRANSPORT_CERTIFICATES_EXTRACT_TABLE_VIEW = 'OWN_TRANSPORT_CERTIFICATES_EXTRACT_TABLE_VIEW',
  FOREIGN_PERMISSIONS_TABLE_VIEW = 'FOREIGN_PERMISSIONS_TABLE_VIEW',
  PRINT_FOR_FOREIGN_PERMISSIONS = 'PRINT_FOR_FOREIGN_PERMISSIONS',

  FOREIGN_PERMISSIONS_REGENERATE_BUTTON = 'FOREIGN_PERMISSIONS_REGENERATE_BUTTON',
  FOREIGN_PERMISSIONS_PRINT_BUTTON = 'FOREIGN_PERMISSIONS_PRINT_BUTTON',
  EKMT_CARNETS_TABLE_VIEW = 'EKMT_CARNETS_TABLE_VIEW',
  EKMT_CARNETS_REGENERATE_BUTTON = 'EKMT_CARNETS_REGENERATE_BUTTON',
  PRINT_UPDATE_BUTTON = 'PRINT_UPDATE_BUTTON',
  PRINT_GENERATE_PERMISSION_BUTTON = 'PRINT_GENERATE_PERMISSION_BUTTON',
  PRINT_GENERATE_PERMISSION_COPY_BUTTON = 'PRINT_GENERATE_PERMISSION_COPY_BUTTON',
  PRINT_GENERATE_PARTNER_LIST_BUTTON = 'PRINT_GENERATE_PARTNER_LIST_BUTTON',
  PRINT_GENERATE_TIMETABLE_BUTTON = 'PRINT_GENERATE_TIMETABLE_BUTTON',
  PRINT_VEHICLE_LIST_BUTTON = 'PRINT_VEHICLE_LIST_BUTTON',
  PRINT_LIST_OF_ARRIVAL_DATES_BUTTON = 'PRINT_LIST_OF_ARRIVAL_DATES_BUTTON',
  PRINT_REGENERATE_BUTTON = 'PRINT_REGENERATE_BUTTON',
  GENERATE_LIST_OF_VEHICLES = 'GENERATE_LIST_OF_VEHICLES',
  GENERATE_TRANSIT_SCHEDULES_DATES = 'GENERATE_TRANSIT_SCHEDULES_DATES',

  PRINT_SEND_FOR_RELEASE_BUTTON_BUTTON = 'PRINT_SEND_FOR_RELEASE_BUTTON_BUTTON',
  PRINT_RETURN_APPLICATION_BUTTON = 'PRINT_RETURN_APPLICATION_BUTTON',
  // Wnioski - dane podstawowe

  APPLICATION_DETAILS_GENERAL_DATA_LINKED_APPLICATIONS_INPUT = 'APPLICATION_DETAILS_GENERAL_DATA_LINKED_APPLICATIONS_INPUT',
  APPLICATION_DETAILS_GENERAL_DATA_LINKED_APPLICATIONS_OPEN_LINKED_APPLICATIONS = 'APPLICATION_DETAILS_GENERAL_DATA_LINKED_APPLICATIONS_OPEN_LINKED_APPLICATIONS',

  // Wnioski - Lista wniosków
  APPLICATION_LIST = 'APPLICATION_LIST',
  APPLICATION_SHOW_DETAILS_BUTTON = 'APPLICATION_SHOW_DETAILS_BUTTON',
  APPLICATION_EDIT_BUTTON = 'APPLICATION_EDIT_BUTTON',
  APPLICATION_ADD_APPLICATION_BUTTON = 'APPLICATION_ADD_APPLICATION_BUTTON',
  APPLICATION_FOLDER_SLIDER_VIEW = 'APPLICATION_FOLDER_SLIDER_VIEW',
  APPLICATION_FOLDER_LOCK_REDIRECT_BUTTON = 'APPLICATION_FOLDER_LOCK_REDIRECT_BUTTON',
  APPLICATION_FOLDER_REDIRECT_BUTTON = 'APPLICATION_FOLDER_REDIRECT_BUTTON',
  APPLICATION_SUBJECT_NIP_COPY_BUTTON = 'APPLICATION_SUBJECT_NIP_COPY_BUTTON',
  APPLICATION_SUBJECT_REGON_COPY_BUTTON = 'APPLICATION_SUBJECT_REGON_COPY_BUTTON',
  APPLICATION_SUBJECT_EMAIL_COPY_BUTTON = 'APPLICATION_SUBJECT_EMAIL_COPY_BUTTON',
  APPLICATION_INPUT_DATA_COPY = 'APPLICATION_INPUT_DATA_COPY',
  APPLICATION_GENERATE_LABEL = 'APPLICATION_GENERATE_LABEL',
  APPLICATION_PRINT_ASSIGN_TO_ME_BUTTON = 'APPLICATION_PRINT_ASSIGN_TO_ME_BUTTON',
  APPLICATION_VERIFY_BUTTON = 'APPLICATION_VERIFY_BUTTON',
  APPLICATION_CREATE_BILLING_NOTE_BUTTON = 'APPLICATION_CREATE_BILLING_NOTE_BUTTON',

  // Wnioski - dane podstawowe
  APPLICATION_GENERAL_DATA_VIEW = 'APPLICATION_GENERAL_DATA_VIEW',
  APPLICATION_LAST_SUPPLEMENT_RECEIPT_DATE_INPUT = 'APPLICATION_LAST_SUPPLEMENT_RECEIPT_DATE_INPUT',

  // Wnioski - Pliki
  APPLICATION_FILE_LIST_VIEW = 'APPLICATION_FILE_LIST_VIEW',
  APPLICATION_UPLOAD_FILE_BUTTON = 'APPLICATION_UPLOAD_FILE_BUTTON',
  APPLICATION_DELETE_FILE_BUTTON = 'APPLICATION_DELETE_FILE_BUTTON',
  APPLICATION_DOWNLOAD_FILE_BUTTON = 'APPLICATION_DOWNLOAD_FILE_BUTTON',
  APPLICATION_SIGN_FILE_BUTTON = 'APPLICATION_SIGN_FILE_BUTTON',

  // Wnioski - Dokumenty wychodzące
  APPLICATION_DOCUMENT_LIST_VIEW = 'APPLICATION_DOCUMENT_LIST_VIEW',
  APPLICATION_OUTGOING_DOCUMENTS_UPLOAD_DOCUMENT_BUTTON = 'APPLICATION_OUTGOING_DOCUMENTS_UPLOAD_DOCUMENT_BUTTON',
  APPLICATION_OUTGOING_DOCUMENTS_GENERATE_ISSUE_CONFIRMATION_BUTTON = 'APPLICATION_OUTGOING_DOCUMENTS_GENERATE_ISSUE_CONFIRMATION_BUTTON',
  APPLICATION_OUTGOING_DOCUMENTS_GENERATE_SUBMISSION_CONFIRMATION_BUTTON = 'APPLICATION_OUTGOING_DOCUMENTS_GENERATE_SUBMISSION_CONFIRMATION_BUTTON',
  APPLICATION_OUTGOING_DOCUMENTS_CANCEL_BUTTON = 'APPLICATION_OUTGOING_DOCUMENTS_CANCEL_BUTTON',
  APPLICATION_OUTGOING_DOCUMENTS_ENTER_DELIVERY_DATE_BUTTON = 'APPLICATION_OUTGOING_DOCUMENTS_ENTER_DELIVERY_DATE_BUTTON',
  APPLICATION_OUTGOING_DOCUMENTS_CAUTION_GENERATE = 'APPLICATION_OUTGOING_DOCUMENTS_CAUTION_GENERATE',
  // Wnioski - Dokumenty wychodzące - Filtrowanie grup
  APPLICATION_OUTGOING_DOCUMENTS_GROUP_SUBMISSION_CONFIRMATION = 'APPLICATION_OUTGOING_DOCUMENTS_GROUP_SUBMISSION_CONFIRMATION',

  // Wnioski - Uprawnienia
  APPLICATION_PERMISSIONS_VIEW = 'APPLICATION_PERMISSIONS_VIEW',
  APPLICATION_PERMISSIONS_LICENSE_TABLE = 'APPLICATION_PERMISSIONS_LICENSE_TABLE',
  APPLICATION_PERMISSIONS_LICENSE_COPY_TABLE = 'APPLICATION_PERMISSIONS_LICENSE_COPY_TABLE',
  APPLICATION_PERMISSIONS_DRIVER_CERTIFICATES_TABLE = 'APPLICATION_PERMISSIONS_DRIVER_CERTIFICATES_TABLE',
  APPLICATION_PERMISSIONS_FOREIGN_PERMITS_TABLE = 'APPLICATION_PERMISSIONS_FOREIGN_PERMITS_TABLE',
  APPLICATION_PERMISSIONS_EXCLUSIONS_FROM_PERMIT_FOR_OCCASIONAL_SERVICE_TABLE = 'APPLICATION_PERMISSIONS_EXCLUSIONS_FROM_PERMIT_FOR_OCCASIONAL_SERVICE_TABLE',
  APPLICATION_PERMISSIONS_GITD_TABLE = 'APPLICATION_PERMISSIONS_GITD_TABLE',
  APPLICATION_PERMISSIONS_PERMISSION_TABLE = 'APPLICATION_PERMISSIONS_PERMISSION_TABLE',
  APPLICATION_PERMISSIONS_PERMITS_FOR_REGULAR_SERVICE_FROM_UE_TABLE = 'APPLICATION_PERMISSIONS_PERMITS_FOR_REGULAR_SERVICE_FROM_UE_TABLE',
  APPLICATION_PERMISSIONS_PERMITS_FOR_REGULAR_SERVICE_OUTSIDE_UE_TABLE = 'APPLICATION_PERMISSIONS_PERMITS_FOR_REGULAR_SERVICE_OUTSIDE_UE_TABLE',
  APPLICATION_PERMISSIONS_EXTRACTS_FOR_REGULAR_SERVICE_OUTSIDE_UE_TABLE = 'APPLICATION_PERMISSIONS_EXTRACTS_FOR_REGULAR_SERVICE_OUTSIDE_UE_TABLE',
  APPLICATION_PERMISSIONS_COUNTRY_TABLE = 'APPLICATION_PERMISSIONS_COUNTRY_TABLE',
  APPLICATION_PERMISSIONS_EXTRACTS_FOR_REGULAR_SERVICE_WITNIN_UE_TABLE = 'APPLICATION_PERMISSIONS_EXTRACTS_FOR_REGULAR_SERVICE_WITNIN_UE_TABLE',
  APPLICATION_PERMISSIONS_SHUTTLE_PERMITS_TABLE = 'APPLICATION_PERMISSIONS_SHUTTLE_PERMITS_TABLE',
  APPLICATION_PERMISSIONS_EXTRACTS_FROM_PERMIT_FOR_SHUTTLE_SERVICE_TABLE = 'APPLICATION_PERMISSIONS_EXTRACTS_FROM_PERMIT_FOR_SHUTTLE_SERVICE_TABLE',
  APPLICATION_PERMISSIONS_PERMITS_FOR_OCCASIONAL_SERVICE_TABLE = 'APPLICATION_PERMISSIONS_PERMITS_FOR_OCCASIONAL_SERVICE_TABLE',
  APPLICATION_PERMISSIONS_PERMITS_FOR_RREGULAR_TRANSIT_IN_POLAND_TABLE = 'APPLICATION_PERMISSIONS_PERMITS_FOR_RREGULAR_TRANSIT_IN_POLAND_TABLE',
  APPLICATION_PERMISSIONS_EXTRACTS_FROM_PERMIT_FOR_RREGULAR_TRANSIT_IN_POLAND_TABLE = 'APPLICATION_PERMISSIONS_EXTRACTS_FROM_PERMIT_FOR_RREGULAR_TRANSIT_IN_POLAND_TABLE',
  APPLICATION_PERMISSIONS_CERTIFICATES_FOR_OWN_ACCOUNT_TRANSPORT_TABLE = 'APPLICATION_PERMISSIONS_CERTIFICATES_FOR_OWN_ACCOUNT_TRANSPORT_TABLE',
  APPLICATION_PERMISSIONS_EXTRACTS_TO_CERTIFICATE_FOR_OWN_ACCOUNT_TRANSPORT_TABLE = 'APPLICATION_PERMISSIONS_EXTRACTS_TO_CERTIFICATE_FOR_OWN_ACCOUNT_TRANSPORT_TABLE',
  APPLICATION_PERMISSIONS_FOREIGN_PERMISSIONS_TABLE = 'APPLICATION_PERMISSIONS_FOREIGN_PERMISSIONS_TABLE',
  APPLICATION_PERMISSIONS_ITEM_PERMISSION_EXTERNAL_CREATE_BUTTON = 'APPLICATION_PERMISSIONS_ITEM_PERMISSION_EXTERNAL_CREATE_BUTTON',
  APPLICATION_PERMISSIONS_ITEM_PERMISSION_EXTERNAL_UPDATE_BUTTON = 'APPLICATION_PERMISSIONS_ITEM_PERMISSION_EXTERNAL_UPDATE_BUTTON',
  APPLICATION_PERMISSIONS_ITEM_PERMISSION_EXTERNAL_DELETE_BUTTON = 'APPLICATION_PERMISSIONS_ITEM_PERMISSION_EXTERNAL_DELETE_BUTTON',
  APPLICATION_PERMISSIONS_ITEM_PERMISSION_EXTERNAL_SHOW_BUTTON = 'APPLICATION_PERMISSIONS_ITEM_PERMISSION_EXTERNAL_SHOW_BUTTON',
  // Wnioski - Historia
  APPLICATION_HISTORY_VIEW = 'APPLICATION_HISTORY_VIEW',
  APPLICATION_HISTORY_DOCUMENT_LINK_DOWNLOAD = 'APPLICATION_HISTORY_DOCUMENT_LINK_DOWNLOAD',
  APPLICATION_HISTORY_TABLE = 'APPLICATION_HISTORY_TABLE',

  // Wnioski - Zabezpieczenia finansowe
  APPLICATION_FINACIAL_SECURITY_VIEW = 'APPLICATION_FINACIAL_SECURITY_VIEW',
  APPLICATION_FINACIAL_SECURITY_ADD_SECURITY_BUTTON = 'APPLICATION_FINACIAL_SECURITY_ADD_SECURITY_BUTTON',
  APPLICATION_FINACIAL_SECURITY_SEARCH_SECURITY_BUTTON = 'APPLICATION_FINACIAL_SECURITY_SEARCH_SECURITY_BUTTON',
  APPLICATION_FINACIAL_SECURITY_EDIT_SECURITY_BUTTON = 'APPLICATION_FINACIAL_SECURITY_EDIT_SECURITY_BUTTON',
  APPLICATION_FINACIAL_SECURITY_DELETE_SECURITY_BUTTON = 'APPLICATION_FINACIAL_SECURITY_DELETE_SECURITY_BUTTON',
  APPLICATION_FINACIAL_SECURITY_SHOW_DETAILS_BUTTON = 'APPLICATION_FINACIAL_SECURITY_SHOW_DETAILS_BUTTON',
  APPLICATION_FINACIAL_SECURITY_EXPIRE_BUTTON = 'APPLICATION_FINACIAL_SECURITY_EXPIRE_BUTTON',
  APPLICATION_FINACIAL_SECURITY_RESTORE_BUTTON = 'APPLICATION_FINACIAL_SECURITY_RESTORE_BUTTON',
  APPLICATION_FINACIAL_SECURITY_SUMMARY_SECTION = 'APPLICATION_FINACIAL_SECURITY_SUMMARY_SECTION',

  // Wnioski - Pojazdy
  APPLICATION_VEHICLES_VIEW = 'APPLICATION_VEHICLES_VIEW',
  APPLICATION_VEHICLES_ADD_VEHICLE_BUTTON = 'APPLICATION_VEHICLES_ADD_VEHICLE_BUTTON',
  APPLICATION_VEHICLES_SEARCH_VEHICLE_BUTTON = 'APPLICATION_VEHICLES_SEARCH_VEHICLE_BUTTON',
  APPLICATION_VEHICLES_DELETE_VEHICLE_BUTTON = 'APPLICATION_VEHICLES_DELETE_VEHICLE_BUTTON',
  APPLICATION_VEHICLES_EDIT_VEHICLE_BUTTON = 'APPLICATION_VEHICLES_EDIT_VEHICLE_BUTTON',
  APPLICATION_VEHICLES_SHOW_DETAILS_BUTTON = 'APPLICATION_VEHICLES_SHOW_DETAILS_BUTTON',
  APPLICATION_VEHICLES_DOWNLOAD_VEHICLES_BUTTON = 'APPLICATION_VEHICLES_DOWNLOAD_VEHICLES_BUTTON',
  APPLICATION_VEHICLES_CLEAR_VEHICLES_BUTTON = 'APPLICATION_VEHICLES_CLEAR_VEHICLES_BUTTON',
  APPLICATION_VEHICLES_GENERATE_RAPORT_BUTTON = 'APPLICATION_VEHICLES_GENERATE_RAPORT_BUTTON',
  APPLICATION_VEHICLES_DOWNLOAD_FROM_CEPIK_BUTTON = 'APPLICATION_VEHICLES_DOWNLOAD_FROM_CEPIK_BUTTON',
  APPLICATION_VEHICLES_DOWNLOAD_XLSX = 'APPLICATION_VEHICLES_DOWNLOAD_XLSX',

  // Wnioski - Pełnomocnicy
  APPLICATION_PROXY_VIEW = 'APPLICATION_PROXY_VIEW',
  APPLICATION_PROXY_ADD_PROXY_BUTTON = 'APPLICATION_PROXY_ADD_PROXY_BUTTON',
  APPLICATION_PROXY_EDIT_PROXY_BUTTON = 'APPLICATION_PROXY_EDIT_PROXY_BUTTON',
  APPLICATION_PROXY_DELETE_PROXY_BUTTON = 'APPLICATION_PROXY_DELETE_PROXY_BUTTON',
  APPLICATION_PROXY_SHOW_DETAILS_BUTTON = 'APPLICATION_PROXY_SHOW_DETAILS_BUTTON',

  // Wnioski - Wycena
  APPLICATION_PRICING_VIEW = 'APPLICATION_PRICING_VIEW',
  APPLICATION_PRICING_CHANGE_DATA_FROM_PRICING_BUTTON = 'APPLICATION_PRICING_CHANGE_DATA_FROM_PRICING_BUTTON',
  APPLICATION_PRICING_SEARCH_TRANSFER_BUTTON = 'APPLICATION_PRICING_SEARCH_TRANSFER_BUTTON',
  APPLICATION_PRICING_EDIT_TRANSFER_BUTTON = 'APPLICATION_PRICING_EDIT_TRANSFER_BUTTON',
  APPLICATION_PRICING_DELETE_TRANSFER_BUTTON = 'APPLICATION_PRICING_DELETE_TRANSFER_BUTTON',
  APPLICATION_PRICING_LINK_TRANSFERS_WITH_APPLICATION_BUTTON = 'APPLICATION_PRICING_LINK_TRANSFERS_WITH_APPLICATION_BUTTON',
  APPLICATION_PRICING_UNLINK_TRANSFERS_WITH_APPLICATION_BUTTON = 'APPLICATION_PRICING_UNLINK_TRANSFERS_WITH_APPLICATION_BUTTON',
  APPLICATION_PRICING_GENERATE_RELEASE_DOCUMENT_BUTTON = 'APPLICATION_PRICING_GENERATE_RELEASE_DOCUMENT_BUTTON',

  // Wnioski - Załączniki
  APPLICATION_VERIFICATION_ELEMENT_LIST_VIEW = 'APPLICATION_VERIFICATION_ELEMENT_LIST_VIEW',
  APPLICATION_ATTACHMENTS_TABLE = 'APPLICATION_ATTACHMENTS_TABLE',
  APPLICATION_ATTACHMENTS_EDIT_NOTE_BUTTON = 'APPLICATION_ATTACHMENTS_EDIT_NOTE_BUTTON',
  APPLICATION_ATTACHMENTS_EDIT = 'APPLICATION_ATTACHMENTS_EDIT',

  // Wnioski - Bazy eksploatacyjne
  APPLICATION_EXLOITATION_BASES_VIEW = 'APPLICATION_EXLOITATION_BASES_VIEW',
  APPLICATION_EXLOITATION_BASES_ADD_BASE_BUTTON = 'APPLICATION_EXLOITATION_BASES_ADD_BASE_BUTTON',
  APPLICATION_EXLOITATION_BASES_SEARCH_BASES_BUTTON = 'APPLICATION_EXLOITATION_BASES_SEARCH_BASES_BUTTON',
  APPLICATION_EXLOITATION_BASES_SHOW_GEOPORTAL_BUTTON = 'APPLICATION_EXLOITATION_BASES_SHOW_GEOPORTAL_BUTTON',
  APPLICATION_EXLOITATION_BASES_DELETE_BASE_BUTTON = 'APPLICATION_EXLOITATION_BASES_DELETE_BASE_BUTTON',
  APPLICATION_EXLOITATION_BASES_EDIT_BASE_BUTTON = 'APPLICATION_EXLOITATION_BASES_EDIT_BASE_BUTTON',
  APPLICATION_EXLOITATION_BASES_SHOW_DETAILS_BUTTON = 'APPLICATION_EXLOITATION_BASES_SHOW_DETAILS_BUTTON',
  APPLICATION_EXLOITATION_BASES_CHECK_BASE_CONTROL_BUTTON = 'APPLICATION_EXLOITATION_BASES_CHECK_BASE_CONTROL_BUTTON',

  // Wnioski - Zarządzający transportem
  APPLICATION_TRANSPORT_MANAGERS_VIEW = 'APPLICATION_TRANSPORT_MANAGERS_VIEW',
  APPLICATION_TRANSPORT_MANAGERS_TABLE = 'APPLICATION_TRANSPORT_MANAGERS_TABLE',
  APPLICATION_TRANSPORT_MANAGERS_ADD_MANAGER_BUTTON = 'APPLICATION_TRANSPORT_MANAGERS_ADD_MANAGER_BUTTON',
  APPLICATION_TRANSPORT_MANAGERS_SEARCH_MANAGER_BUTTON = 'APPLICATION_TRANSPORT_MANAGERS_SEARCH_MANAGER_BUTTON',
  APPLICATION_TRANSPORT_MANAGERS_DELETE_MANAGER_BUTTON = 'APPLICATION_TRANSPORT_MANAGERS_DELETE_MANAGER_BUTTON',
  APPLICATION_TRANSPORT_MANAGERS_EDIT_MANAGER_BUTTON = 'APPLICATION_TRANSPORT_MANAGERS_EDIT_MANAGER_BUTTON',
  APPLICATION_TRANSPORT_MANAGERS_SHOW_DETAILS_BUTTON = 'APPLICATION_TRANSPORT_MANAGERS_SHOW_DETAILS_BUTTON',
  APPLICATION_TRANSPORT_MANAGERS_CHECK_IN_KREPTD_BUTTON = 'APPLICATION_TRANSPORT_MANAGERS_CHECK_IN_KREPTD_BUTTON',
  APPLICATION_TRANSPORT_MANAGERS_SHOW_REPUTATION_BUTTON = 'APPLICATION_TRANSPORT_MANAGERS_SHOW_REPUTATION_BUTTON',
  APPLICATION_TRANSPORT_MANAGERS_ADD_TO_APPLICATION_BUTTON = 'APPLICATION_TRANSPORT_MANAGERS_ADD_TO_APPLICATION_BUTTON',

  // Wnioski - Adresy
  APPLICATION_ADDRESSES_VIEW = 'APPLICATION_ADDRESSES_VIEW',
  APPLICATION_ADDRESSES_UPDATE = 'APPLICATION_ADDRESSES_UPDATE',

  // Wnioski - Podmiot
  APPLICATION_SUBJECT_REPUTATION_SECTION = 'APPLICATION_SUBJECT_REPUTATION_SECTION',
  APPLICATION_SUBJECT_KREPTD_REPUTATION_IMPORT_BUTTON = 'APPLICATION_SUBJECT_KREPTD_REPUTATION_IMPORT_BUTTON',
  APPLLICATION_SUBJECT_VIEW = 'APPLLICATION_SUBJECT_VIEW',
  APPLICATION_SUBJECT_ENTREPRENEURS_TABLE = 'APPLICATION_SUBJECT_ENTREPRENEURS_TABLE',
  APPLICATION_DOWNLOAD_REGON_ACCEPT_ADDRESS_BUTTON = 'APPLICATION_DOWNLOAD_REGON_ACCEPT_ADDRESS_BUTTON',
  APPLICATION_DOWNLOAD_REGON_ACCEPT_DATA_BUTTON = 'APPLICATION_DOWNLOAD_REGON_ACCEPT_DATA_BUTTON',
  APPLICATION_DOWNLOAD_REGON_DATA_BUTTON = 'APPLICATION_DOWNLOAD_REGON_DATA_BUTTON',
  APPLICATION_PARTNER_APPLICANT_SUBJECT_LIST_VIEW = 'APPLICATION_PARTNER_APPLICANT_SUBJECT_LIST_VIEW',
  APPLICATION_ITEM_PARTNER_APPLICANT_SUBJECT_UPDATE_BUTTON = 'APPLICATION_ITEM_PARTNER_APPLICANT_SUBJECT_UPDATE_BUTTON',
  APPLICATION_ITEM_PARTNER_APPLICANT_SUBJECT_DELETE_BUTTON = 'APPLICATION_ITEM_PARTNER_APPLICANT_SUBJECT_DELETE_BUTTON',
  APPLICATION_ITEM_PARTNER_APPLICANT_SUBJECT_CREATE_BUTTON = 'APPLICATION_ITEM_PARTNER_APPLICANT_SUBJECT_CREATE_BUTTON',

  // Wnioski - Notatka
  APPLICATION_NOTE_VIEW = 'APPLICATION_NOTE_VIEW',
  NOTE_UPDATE = 'NOTE_UPDATE',

  // Wnioski - Certyfikaty S
  APPLICATION_S_CERTIFICATES_ADD_VEHICLE_FROM_FOLDER_TO_APPLCIATION_BUTTON = 'APPLICATION_S_CERTIFICATES_ADD_VEHICLE_FROM_FOLDER_TO_APPLCIATION_BUTTON',
  APPLICATION_S_CERTIFICATES_SHOW_DETAILS_BUTTON = 'APPLICATION_S_CERTIFICATES_SHOW_DETAILS_BUTTON',
  APPLICATION_S_CERTIFICATE_DELETE_VEHICLE_BUTTON = 'APPLICATION_S_CERTIFICATE_DELETE_VEHICLE_BUTTON',
  APPLICATION_S_CERTIFICATE_ADD_VEHICLE_BUTTON = 'APPLICATION_S_CERTIFICATE_ADD_VEHICLE_BUTTON',
  APPLICATION_S_CERTIFICATES_EDIT_BUTTON = 'APPLICATION_S_CERTIFICATES_EDIT_BUTTON',

  // Wnioski - Kierowcy
  APPLICATION_DRIVERS_VIEW = 'APPLICATION_DRIVERS_VIEW',
  APPLICATION_DRIVERS_ADD_DRIVER_BUTTON = 'APPLICATION_DRIVERS_ADD_DRIVER_BUTTON',
  APPLICATION_DRIVERS_SEARCH_DRIVER_BUTTON = 'APPLICATION_DRIVERS_SEARCH_DRIVER_BUTTON',
  APPLICATION_DRIVERS_DELETE_DRIVER_BUTTON = 'APPLICATION_DRIVERS_DELETE_DRIVER_BUTTON',
  APPLICATION_DRIVERS_EDIT_DRIVER_BUTTON = 'APPLICATION_DRIVERS_EDIT_DRIVER_BUTTON',
  APPLICATION_DRIVERS_SHOW_DETAILS_BUTTON = 'APPLICATION_DRIVERS_SHOW_DETAILS_BUTTON',
  APPLICATION_DRIVERS_GO_TO_PROCEEDING = 'APPLICATION_DRIVERS_GO_TO_PROCEEDING',

  // Wnioski - Rozkłady jazd
  APPLICATION_TRANSIT_SCHEDULE_VIEW = 'APPLICATION_TRANSIT_SCHEDULE_VIEW',
  APPLICATION_SCHEDULE_NEW_SCHEDULE_BUTTON = 'APPLICATION_SCHEDULE_NEW_SCHEDULE_BUTTON',
  APPLICATION_SCHEDULE_MOVE_TO_UP_BUTTON = 'APPLICATION_SCHEDULE_MOVE_TO_UP_BUTTON',
  APPLICATION_SCHEDULE_MOVE_DOWN_BUTTON = 'APPLICATION_SCHEDULE_MOVE_DOWN_BUTTON',
  APPLICATION_SCHEDULE_DELETE_SCHEDULE_BUTTON = 'APPLICATION_SCHEDULE_DELETE_SCHEDULE_BUTTON',
  APPLICATION_SCHEDULE_SAVE_SCHEDULE_BUTTON = 'APPLICATION_SCHEDULE_SAVE_SCHEDULE_BUTTON',
  APPLICATION_SCHEDULE_EDIT_SCHEDULE_BUTTON = 'APPLICATION_SCHEDULE_EDIT_SCHEDULE_BUTTON',
  APPLICATION_SCHEDULE_CLEAR_FREQUENCY_BUTTON = 'APPLICATION_SCHEDULE_CLEAR_FREQUENCY_BUTTON',
  APPLICATION_SCHEDULE_ADD_STOP_BUTTON = 'APPLICATION_SCHEDULE_ADD_STOP_BUTTON',
  APPLICATION_SCHEDULE_EDIT_STOP_BUTTON = 'APPLICATION_SCHEDULE_EDIT_STOP_BUTTON',
  APPLICATION_SCHEDULE_DELETE_STOP_BUTTON = 'APPLICATION_SCHEDULE_DELETE_STOP_BUTTON',
  APPLICATION_SCHEDULE_SHOW_DETAILS_BUTTON = 'APPLICATION_SCHEDULE_SHOW_DETAILS_BUTTON',
  APPLICATION_SCHEDULE_STOP_ADD_HOURS = 'APPLICATION_SCHEDULE_STOP_ADD_HOURS',
  APPLICATION_TRANSIT_SCHEDULE_FREQUENCY = 'APPLICATION_SCHEDULE_FREQUENCY',
  APPLICATION_TRANSIT_DATE_UPDATE = 'APPLICATION_TRANSIT_DATE_UPDATE',
  // Wnioski - Opłata
  APPLICATION_PAYMENT_ADD_PAYMENT_BUTTON = 'APPLICATION_PAYMENT_ADD_PAYMENT_BUTTON',
  APPLICATION_PAYMENT_EDIT_PAYMENT_BUTTON = 'APPLICATION_PAYMENT_EDIT_PAYMENT_BUTTON',

  // Wnioski - Kabotaż
  APPLICATION_CABOTAGE_VIEW = 'APPLICATION_CABOTAGE_VIEW',
  APPLICATION_CABOTAGE_EDIT_NOTE = 'APPLICATION_CABOTAGE_EDIT_NOTE',
  APPLICATION_CABOTAGE_EDIT_COUNTRY = 'APPLICATION_CABOTAGE_EDIT_COUNTRY',

  // Wnioski - Zezwolenia jednorazowe
  APPLICATION_FOREIGN_PERMISSIONS_SINGLE_VIEW = 'APPLICATION_FOREIGN_PERMISSIONS_SINGLE_VIEW',
  APPLICATION_FOREIGN_PERMISSIONS_SINGLE_ADD_NEW_BUTTON = 'APPLICATION_FOREIGN_PERMISSIONS_SINGLE_ADD_NEW_BUTTON',
  APPLICATION_FOREIGN_PERMISSIONS_SINGLE_DELETE_BUTTON = 'APPLICATION_FOREIGN_PERMISSIONS_SINGLE_DELETE_BUTTON',
  APPLICATION_FOREIGN_PERMISSIONS_SINGLE_EDIT_BUTTON = 'APPLICATION_FOREIGN_PERMISSIONS_SINGLE_EDIT_BUTTON',

  // Administracja
  ADMINISTRATION_VIEW = 'ADMINISTRATION_VIEW',
  APPLICATION_FEES_LIST_TAB = 'APPLICATION_FEE_LIST_TAB',
  APPLICATION_FEES_EXPORT_FILE = 'APPLICATION_FEES_EXPORT_FILE',
  APPLICATION_FEES_IMPORT_FILE = 'APPLICATION_FEES_IMPORT_FILE',
  AUDIT_LOG_LIST_TAB = 'AUDIT_LOG_LIST_TAB',
  PROCESSES_LIST_TAB = 'PROCESSES_LIST_TAB',

  // Administracja - Parametry systemowe
  SYSTEM_PARAMETERS_LIST_TAB = 'SYSTEM_PARAMETERS_LIST_TAB',
  SYSTEM_PARAMETERS_EDIT_BUTTON = 'SYSTEM_PARAMETERS_EDIT_BUTTON',

  // Powiadomienia
  NOTIFICATION_SHORT_LIST = 'NOTIFICATIONS_SHORT_LIST',
  NOTIFICATION_FULL_LIST = 'NOTIFICATION_FULL_LIST',

  // Generuj raport - spis spraw
  REPORT_GENERATE_CASES_LIST_BUTTON = 'REPORT_GENERATE_CASES_LIST_BUTTON',

  // Komunikaty administracyjne
  ADMIN_MESSAGE_USERS_SELECT = 'ADMIN_MESSAGE_USERS_SELECT',
  ADMIN_MESSAGE_GROUPS_SELECT = 'ADMIN_MESSAGE_GROUPS_SELECT'
}

export default DomainUIElementEnum;
