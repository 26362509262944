import { useTranslation } from 'react-i18next';
import { DriversSearchFilter } from '@ibtm/domain';

import { Button } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

import { useOpenDriverSearchDialog } from '@libs/domain/drivers/hooks';

interface IProps {
  folderId: string;
  transferredFoldersIds?: string[];
  applicationId: string;
  actionKey: UIElementNameEnum;
  searchParams?: DriversSearchFilter;
}

function DriverSearchButton({ folderId, transferredFoldersIds, applicationId, actionKey, searchParams }: IProps) {
  const { openDriverSearchDialog } = useOpenDriverSearchDialog();
  const [t] = useTranslation();

  return (
    <Button
      type="button"
      variant="outlined"
      onClick={() => openDriverSearchDialog({ applicationId, folderId, transferredFoldersIds, searchParams })}
      label={t('drivers:actions.searchDriver')}
      actionKey={actionKey}
      isNoMargin
    />
  );
}

export default DriverSearchButton;
