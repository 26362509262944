import { PluginHook, SortingRule } from 'react-table';
import { PermissionSearchFilter } from '@ibtm/domain';

import { CamelCasePath, useTableLayoutProps } from '@libs/common/v2';

import PermissionTableEnum from '../../../application/model/PermissionTableEnum';
import { usePermissionsQuery } from '../../api';
import { useExternalAuthPermissionsQuery } from '../../api/queries/usePermissionsQuery';
import { PermissionSnapshotClient } from '../../model';
import usePermissionsColumns from '../column/usePermissionsColumns';

const usePermissionsTable = (
  visibleColumns: Array<CamelCasePath<PermissionSnapshotClient>>,
  initialParams: PermissionSearchFilter,
  editableColumns?: Array<CamelCasePath<PermissionSnapshotClient>>,
  tablePlugins?: Array<PluginHook<PermissionSnapshotClient>>,
  isMutableTable = false,
  subjectNameColumnHeader?: string,
  permissionNumberColumnHeader?: string,
  validFromColumnHeader?: string,
  permissionType?: string,
  defaultSort: SortingRule<PermissionSnapshotClient>[] = [],
  isTableDataVisible = true,
  dateFromAndToWithoutChips?: boolean
) => {
  const { columns, mappedFilterFields, mappedSortFields } = usePermissionsColumns(
    visibleColumns,
    editableColumns,
    isMutableTable,
    subjectNameColumnHeader,
    permissionNumberColumnHeader,
    validFromColumnHeader,
    dateFromAndToWithoutChips
  );

  const getQuery = () => {
    return permissionType === PermissionTableEnum.EXTERNAL_AUTHORITY_PERMISSION
      ? useExternalAuthPermissionsQuery
      : usePermissionsQuery;
  };

  return useTableLayoutProps({
    tableHookQuery: isTableDataVisible && getQuery(),
    tableHookOptions: {
      columns,
      tablePlugins,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tableOptions: {
        initialState: {
          sortBy: defaultSort
        }
      }
    },
    tableHookQueryInitialParams: initialParams as Record<string, unknown>
  });
};

export default usePermissionsTable;
