import { useCallback, useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosResponse } from 'axios';
import * as Yup from 'yup';

import {
  AutocompleteSelectField,
  Dialog,
  FormV2Context,
  GridLayout,
  SelectOption,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useDomainConfigContext } from '@libs/domain/config';

import { FilesQueryKeysEnum, useFileDownloadMutation } from '../../api';
import { getFilenameFromHeader, useSignFile } from '../../hooks';
import { AddFileRequestData, CertificateType, SignFileForm } from '../../models';

interface IProps {
  fileId: string;
  closeDialog: () => void;
  apiCreateQuery: (formValues: AddFileRequestData) => Promise<AxiosResponse>;
}

interface IValues {
  certificate: SelectOption<string>;
  pin: string;
}

function FileSignDialog({ fileId, closeDialog, apiCreateQuery }: IProps) {
  const { isClientPortal } = useDomainConfigContext();
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { mutateAsync: downloadFile } = useFileDownloadMutation();

  const { signFile, certificates, setCertificateType, areCertificatesLoading, isFileSigning, setForceCancelSigning } =
    useSignFile(apiCreateQuery, closeDialog);

  const certificateTypeOptions = useMemo(
    () => [
      {
        name: CertificateType.CENCERT,
        value: CertificateType.CENCERT
      },
      {
        name: CertificateType.SIGILLUM,
        value: CertificateType.SIGILLUM
      },
      {
        name: CertificateType.ASSECO,
        value: CertificateType.ASSECO
      }
    ],
    []
  );

  const initialValues = useMemo(
    () => ({
      certificateType: certificateTypeOptions[0]
    }),
    [certificateTypeOptions]
  );

  const validationSchema = Yup.object({
    certificateType: Yup.object().required(),
    certificate: Yup.object().nullable().required(),
    pin: Yup.string().required()
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
    watch,
    trigger,
    unregister
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema)
  });

  const certificateTypeWatch = useWatch({
    control,
    name: typedNameV2<SignFileForm>('certificateType')
  }) as SelectOption<CertificateType>;

  useEffect(() => {
    setValue(typedNameV2<SignFileForm>('certificate'), null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificates]);

  useEffect(() => {
    const certificateType = certificateTypeWatch?.value;
    if (certificateType) {
      setCertificateType(certificateType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificateTypeWatch?.value]);

  const onSubmit = async ({ certificate, pin }: Partial<IValues>) => {
    const response = await downloadFile({ fileId, isClientPortal });
    try {
      await signFile({
        fileBlob: response.data,
        fileName: getFilenameFromHeader(response),
        certificateId: certificate.value,
        pin
      });
      await queryCache.invalidateQueries(FilesQueryKeysEnum.FILE_LIST);
      showSuccessSnackbar(t('document:message.signFileSuccessMessage'));
    } catch (error) {
      showErrorSnackbar(t('document:message.signFileFailureMessage'));
    }
    closeDialog();
  };

  const formDataProvider = useMemo(() => {
    return { control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting };
  }, [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]);

  const onCancel = useCallback(() => {
    setForceCancelSigning(true);
    closeDialog();
  }, [closeDialog, setForceCancelSigning]);

  return (
    <FormV2Context.Provider value={formDataProvider}>
      <Dialog
        title={t('document:dialog.signFileDialogTitle')}
        confirmText={t('document:dialog.signFileDialogConfirm')}
        cancelText={t('action.cancel')}
        onConfirm={handleSubmit(data => onSubmit(data))}
        onCancel={onCancel}
        isConfirmLoading={isFileSigning}
        isOpen
      >
        <form onSubmit={handleSubmit(data => onSubmit(data))}>
          <GridLayout itemProps={{ xs: 12 }}>
            <AutocompleteSelectField
              name="certificateType"
              options={certificateTypeOptions}
              label={t('document:signFileDialog.field.certificateType')}
              isRequired
            />
            <AutocompleteSelectField
              name="certificate"
              options={certificates}
              label={t('document:signFileDialog.field.certificate')}
              isLoading={areCertificatesLoading}
              isRequired
            />
            <TextInputField label={t('document:signFileDialog.field.pin')} name="pin" type="password" isRequired />
          </GridLayout>
        </form>
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default FileSignDialog;
