import { boolean as YupBoolean, mixed as YupMixed, number as YupNumber, object as YupObject } from 'yup';

import { DomainDictionaryEntry } from '../../config';

export const useStopValidationSchema = (hiddenFields, isApplicationGroupSpoWzo, isApplicationGroupSpoWzw) => {
  if (isApplicationGroupSpoWzw) {
    return YupObject({
      ...(!hiddenFields.includes('typeKey') ? { typeKey: YupObject().nullable().required() } : {}),
      ...(!hiddenFields.includes('countryKey')
        ? {
            countryKey: YupMixed().when('typeKey', {
              is: typeKey => typeKey?.value === DomainDictionaryEntry.STOP_TYPE.END_OF_TOUR,
              then: YupMixed().nullable(),
              otherwise: YupMixed().nullable().required()
            })
          }
        : {}),
      ...(!hiddenFields.includes('city')
        ? {
            city: YupMixed().when('typeKey', {
              is: typeKey => typeKey?.value === DomainDictionaryEntry.STOP_TYPE.END_OF_TOUR,
              then: YupMixed().nullable(),
              otherwise: YupMixed().nullable().required()
            })
          }
        : {}),
      ...(!hiddenFields.includes('city2')
        ? {
            city2: YupObject().when('typeKey', {
              is: typeKey =>
                [
                  DomainDictionaryEntry.STOP_TYPE.ALTERNATIVE_BORDER_CROSSING,
                  DomainDictionaryEntry.STOP_TYPE.BORDER_CROSSING,
                  DomainDictionaryEntry.STOP_TYPE.END_OF_TOUR
                ].includes(typeKey?.value),
              then: YupObject().nullable().required(),
              otherwise: YupObject().nullable()
            })
          }
        : {}),
      ...(!hiddenFields.includes('countryKey2')
        ? {
            countryKey2: YupObject().when('typeKey', {
              is: typeKey =>
                [
                  DomainDictionaryEntry.STOP_TYPE.ALTERNATIVE_BORDER_CROSSING,
                  DomainDictionaryEntry.STOP_TYPE.BORDER_CROSSING,
                  DomainDictionaryEntry.STOP_TYPE.END_OF_TOUR
                ].includes(typeKey?.value),
              then: YupObject().nullable().required(),
              otherwise: YupObject().nullable()
            })
          }
        : {}),
      ...(!hiddenFields.includes('directionKey') ? { directionKey: YupObject().nullable().required() } : {}),
      ...(!hiddenFields.includes('kilometers') ? { kilometers: YupNumber().nullable() } : {}),
      branchRoute: YupBoolean()
    });
  }
  return YupObject({
    ...(!hiddenFields.includes('typeKey') ? { typeKey: YupObject().nullable().required() } : {}),
    ...(!hiddenFields.includes('countryKey') ? { countryKey: YupObject().nullable().required() } : {}),
    ...(!hiddenFields.includes('city')
      ? {
          city: YupMixed().when('typeKey', {
            is: typeKey =>
              [DomainDictionaryEntry.STOP_TYPE.START_OF_TOUR, DomainDictionaryEntry.STOP_TYPE.END_OF_TOUR].includes(
                typeKey?.value
              ) && isApplicationGroupSpoWzo,
            then: YupMixed().nullable(),
            otherwise: YupMixed().nullable().required()
          })
        }
      : {}),
    ...(!hiddenFields.includes('countryKey2')
      ? {
          countryKey2: YupObject().when('typeKey', {
            is: typeKey =>
              [
                DomainDictionaryEntry.STOP_TYPE.ALTERNATIVE_BORDER_CROSSING,
                DomainDictionaryEntry.STOP_TYPE.BORDER_CROSSING
              ].includes(typeKey?.value),
            then: YupObject().nullable().required(),
            otherwise: YupObject().nullable()
          })
        }
      : {}),
    ...(!hiddenFields.includes('directionKey') ? { directionKey: YupObject().nullable().required() } : {}),
    ...(!hiddenFields.includes('kilometers') ? { kilometers: YupNumber().nullable() } : {}),
    branchRoute: YupBoolean()
  });
};
