import {
  AdditionalFilterEnum,
  AutocompleteSelectField,
  DatepickerField,
  DictionarySelectField,
  IAdditionalFilter,
  SelectWithFetchedOptionsField,
  SwitchField,
  TextInputField,
  YearSelectField
} from '@libs/common/v2';

function renderFilters({ type, name, inputProps, setGlobalYearSelect }: IAdditionalFilter) {
  switch (type) {
    case AdditionalFilterEnum.YEAR_SELECT:
      return <YearSelectField key={name} name={name} setGlobalYearSelect={setGlobalYearSelect} {...inputProps} />;
    case AdditionalFilterEnum.TEXT_FILTER:
      return <TextInputField key={name} name={name} {...inputProps} />;
    case AdditionalFilterEnum.SELECT:
      return <AutocompleteSelectField key={name} name={name} {...(inputProps as any)} />;
    case AdditionalFilterEnum.FETCH_SELECT:
      return <SelectWithFetchedOptionsField key={name} name={name} {...(inputProps as any)} />;
    case AdditionalFilterEnum.DICTIONARY_SELECT:
      return <DictionarySelectField key={name} name={name} {...(inputProps as any)} />;
    case AdditionalFilterEnum.DATEPICKER:
      return <DatepickerField key={name} name={name} {...inputProps} />;
    case AdditionalFilterEnum.BOOLEAN_TOGGLE:
      return <SwitchField key={name} name={name} {...inputProps} />;
    default:
      return <TextInputField key={name} name={name} {...inputProps} />;
  }
}

export default renderFilters;
