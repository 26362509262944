import { useContext, useMemo } from 'react';
import { useParams } from 'react-router';
import { InvoiceItemDetails, InvoiceLite } from '@ibtm/domain';
import { isObject, isUndefined } from 'lodash';

import { SelectOption, useFormV2Context, useViewModesV2 } from '@libs/common/v2';

import { DomainDictionaryEntry } from '../../../config';
import { ICorrectionItems, ReleaseDocumentsDetailsPageParams } from '../../model';
import { ChangeReturnModeContext } from '../../pages/ReleaseDocumentsWrapper';
import { isWZZ } from '../../utils';
import ReturnButton from '../components/ReturnButton';

import { AfterReturnCorrectionItemsTable } from './AfterReturnCorrectionItemsTable';
import { CreateCorrectionItemsTable } from './CreateCorrectionItemsTable';
import { OriginalItemsTable } from './OriginalItemsTable';
import { ViewEditCorrectionItemsTable } from './ViewEditCorrectionItemsTable';

interface IProps {
  correctionItemsTable: ICorrectionItems[];
  setCorrectionItemsTable: (table: ICorrectionItems[]) => void;
  isOriginalDocument?: boolean;
  isDataLoading?: boolean;
}

const SUSPENSION_REASONS = [
  DomainDictionaryEntry.INVOICE_CORRECTION_REASON.REVERT_SUSPENSION,
  DomainDictionaryEntry.INVOICE_CORRECTION_REASON.SUSPENSION
];

/**
 * Wrapper na wszystkie wersje tabeli pozycji
 */
export const ReleaseDocumentsItemsWrapper = ({
  correctionItemsTable,
  setCorrectionItemsTable,
  isOriginalDocument,
  isDataLoading
}: IProps) => {
  const { formMode, watch } = useFormV2Context();
  const { folderId } = useParams<ReleaseDocumentsDetailsPageParams>();
  const { createMode, editMode } = useViewModesV2(formMode);
  const { items: returnedItems } = useContext(ChangeReturnModeContext);
  const items = watch('items') as InvoiceItemDetails[];
  const number = watch('number') as string;
  const isCorrectionOfCorrection = watch('isCorrectionOfCorrection') as boolean;
  const isCorrection = watch('isCorrection') as boolean;
  const zeroing = watch('zeroing') as boolean;
  const correctionReasonKey = watch('correctionReasonKey') as SelectOption;
  const corrections = watch('corrections') as Array<InvoiceLite & { statusKey: string }>;

  const isAfterReturnAction = returnedItems.length !== 0 && createMode;

  const correctionInvoice = useMemo(
    () => corrections?.find(correction => correction.statusKey !== DomainDictionaryEntry.INVOICE_STATUS.CANCELED),
    [corrections]
  );

  const headerActions = useMemo(() => {
    return isUndefined(correctionInvoice) && isWZZ(number) && !editMode && <ReturnButton />;
  }, [correctionInvoice, editMode, number]);

  const isSuspension = useMemo(() => {
    if ((isCorrection && !!folderId && createMode) || (isCorrectionOfCorrection && !!folderId)) {
      return true;
    }
    return SUSPENSION_REASONS.includes(
      isObject(correctionReasonKey) ? correctionReasonKey?.value : correctionReasonKey
    );
  }, [correctionReasonKey, createMode, folderId, isCorrection, isCorrectionOfCorrection]);

  const afterReturnComponent = useMemo(() => {
    return (
      <AfterReturnCorrectionItemsTable
        correctionItemsTable={correctionItemsTable}
        setCorrectionItemsTable={setCorrectionItemsTable}
        headerActions={headerActions}
        isDataLoading={isDataLoading}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correctionItemsTable, headerActions, setCorrectionItemsTable, zeroing]);

  const normalTable = useMemo(() => {
    return createMode ? (
      <CreateCorrectionItemsTable
        correctionItemsTable={correctionItemsTable}
        items={items}
        setCorrectionItemsTable={setCorrectionItemsTable}
        headerActions={headerActions}
        isSuspension={isSuspension}
        isDataLoading={isDataLoading}
      />
    ) : (
      <ViewEditCorrectionItemsTable
        items={items}
        correctionItemsTable={correctionItemsTable}
        setCorrectionItemsTable={setCorrectionItemsTable}
        headerActions={headerActions}
        isSuspension={isSuspension}
        isDataLoading={isDataLoading}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correctionItemsTable, createMode, headerActions, items, setCorrectionItemsTable, zeroing, isSuspension]);

  if (isOriginalDocument) {
    return <OriginalItemsTable isDataLoading={isDataLoading} headerActions={headerActions} />;
  }
  // jeśli była akcja zwróć na m.in. WZZ - zwracamy liste ograniczoną przez akcje
  return isAfterReturnAction ? afterReturnComponent : normalTable;
};
