import React from 'react';

export { default as useConfigurationContext } from './ConfigurationContext';
export * from './ConfigurationContext';
export * from './ConfigurationContextTypes';
export const UserDetailsCustomSectionsContext = React.createContext<React.ReactNode>(null);
export const UserDetailsSerialNumbersSectionsContext = React.createContext<React.ReactNode>(null);
export const UserDetailsCustomFieldsContext = React.createContext<React.ReactNode>(null);

interface GroupCustomPermissionsContextProps {
  canAddGroup?: boolean;
}

export const GroupCustomPermissionsContext = React.createContext<GroupCustomPermissionsContextProps>({
  canAddGroup: false
});

interface UserCustomConfigurationContextProps {
  isUsingServiceNumber?: boolean;
  isUsingEmailAsLogin?: boolean;
}

export const UserCustomConfigurationContext = React.createContext<UserCustomConfigurationContextProps>({
  isUsingServiceNumber: true,
  isUsingEmailAsLogin: false
});
