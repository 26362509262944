import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import {
  Button,
  DropdownButton,
  IPropsItem,
  PageHeader,
  useBreadcrumbsConverter,
  useBreadcrumbTextByFormMode,
  useIsSmallScreen
} from '@libs/common/v2';
import { IButtonAction } from '@libs/common/v2/components/button/DropdownButton';
import { ButtonsGroup } from '@libs/common/v2/components/buttonsGroup';

interface IProps {
  setShowResetPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

function ProfilePageHeader({ setShowResetPassword }: IProps) {
  const [t] = useTranslation();
  const { convertBreadcrumbToText } = useBreadcrumbsConverter();
  const { isSmallScreen } = useIsSmallScreen();

  const breadcrumbText = useBreadcrumbTextByFormMode({
    viewValue: t('user:profilePageTitle')
  });

  const breadcrumbs = useMemo(
    (): Array<IPropsItem> => [{ to: '/', text: t('mainPage') }, { text: convertBreadcrumbToText(breadcrumbText) }],
    [t, convertBreadcrumbToText, breadcrumbText]
  );

  const actionButtons: IButtonAction[] = [
    {
      label: t('user:action.changePassword'),
      onClick: () => {
        setShowResetPassword(true);
      },
      actionKey: UIElementNameEnum.PROFILE_PASSWORD_CHANGE_BUTTON
    }
  ];

  return (
    <PageHeader
      title={t('user:profilePageTitle')}
      breadcrumbs={breadcrumbs}
      rightSideContent={
        isSmallScreen ? (
          <DropdownButton actionButtons={actionButtons} />
        ) : (
          <ButtonsGroup>
            <Button
              onClick={() => setShowResetPassword(true)}
              label={t('user:action.changePassword')}
              variant="outlined"
              actionKey={UIElementNameEnum.PROFILE_PASSWORD_CHANGE_BUTTON}
            />
          </ButtonsGroup>
        )
      }
    />
  );
}

export default ProfilePageHeader;
