import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { ProceedingAvailableFormsDetails } from '@ibtm/domain';
import _ from 'lodash';

import { Button, ButtonsGroup, typedNameV2, useFormV2Context } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { useFormV2Watch, useViewModesV2 } from '@libs/common/v2/form';

import {
  FormOperationListItemClient,
  ProceedingQueryKeysEnum,
  useCreateProceedingFormOperationsMutation
} from '@libs/domain/proceeding';
import { SuspensionQueryKeysEnum, useCreateSuspensionFormOperationsMutation } from '@libs/domain/suspensions/api';

interface Props {
  id: string;
  parentId: string;
  closeDialog: () => void;
  refreshTable: () => void;
  setAddNew?: (boolean) => void;
  isSuspension: boolean;
  isAddNew?: boolean;
  chosenForms?: ProceedingAvailableFormsDetails[];
  setChosenForms?: Dispatch<SetStateAction<[] | ProceedingAvailableFormsDetails[]>>;
}

function CustomActions({
  closeDialog,
  id,
  parentId,
  refreshTable,
  isSuspension,
  isAddNew,
  setAddNew,
  chosenForms,
  setChosenForms
}: Props) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { handleSubmit, watch, formMode, getValues, setValue } = useFormV2Context();
  const tempRows = useFormV2Watch<ProceedingAvailableFormsDetails[]>({ name: 'form.tempRows' });
  const { mutate: createProceedingFormOperations } = useCreateProceedingFormOperationsMutation();
  const { mutate: createSuspensionFormOperations } = useCreateSuspensionFormOperationsMutation();
  const { viewMode } = useViewModesV2(formMode);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const formIds = watch(typedNameV2<FormOperationListItemClient>('form.ids')) as string[];

  const onSuccess = () => {
    showSuccessSnackbar(t('proceeding:administrative.tab.formOperations.formOperationAdded'));
    refreshTable();
    queryCache.invalidateQueries(
      isSuspension ? SuspensionQueryKeysEnum.SUSPENSION_DETAILS : ProceedingQueryKeysEnum.PROCEEDING_DETAILS
    );
    closeDialog();
  };

  const onAdd = () => {
    const values = getValues();
    const tempRows = values?.form?.tempRows;
    const tempRowIds = tempRows?.map(row => row?.formId);

    setValue(
      typedNameV2<FormOperationListItemClient>('form.ids'),
      formIds ? [...formIds, ...tempRowIds] : [...tempRowIds]
    );
    setChosenForms([...chosenForms, ...tempRows]);

    setValue('form.tempRows', []);
    setAddNew(false);
  };

  const onSettled = () => {
    setIsSubmitting(false);
  };

  const onSuspensionSubmit = useCallback(
    async data => {
      setIsSubmitting(true);
      await createSuspensionFormOperations(
        {
          parentId,
          request: {
            forms: data.form.ids
          }
        },
        {
          onSuccess,
          onSettled
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeDialog, refreshTable, id]
  );

  const onProceedingSubmit = useCallback(
    async data => {
      const forms = data?.form?.ids?.map(item => {
        return { id: item, reasonKey: data.reasonKey?.value ?? null };
      });

      setIsSubmitting(true);
      await createProceedingFormOperations(
        {
          parentId,
          request: {
            forms
          }
        },
        {
          onSuccess,
          onSettled
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeDialog, refreshTable, id]
  );

  return (
    <ButtonsGroup>
      {isAddNew ? (
        <>
          <Button variant="outlined" onClick={() => setAddNew(false)} label={t('action.cancel')} isNoMargin />
          <Button
            variant="contained"
            onClick={onAdd}
            label={t('action.add')}
            disabled={_.isEmpty(tempRows)}
            isNoMargin
            isPrimary
          />
        </>
      ) : (
        <>
          <Button
            variant="outlined"
            onClick={closeDialog}
            label={viewMode ? t('action.close') : t('action.cancel')}
            isNoMargin
          />
          {!viewMode && (
            <Button
              variant="contained"
              onClick={handleSubmit(isSuspension ? onSuspensionSubmit : onProceedingSubmit)}
              label={t('action.save')}
              isLoading={isSubmitting}
              isPrimary
              isNoMargin
              disabled={!formIds || !formIds?.length}
            />
          )}
        </>
      )}
    </ButtonsGroup>
  );
}

export default CustomActions;
