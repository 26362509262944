import { useTranslation } from 'react-i18next';

import { TranslationFrom } from '@libs/common';

function useGetGenerateButtonTitle() {
  const [t] = useTranslation();

  const getButtonTitle = (docName: TranslationFrom<'prints:actions.generate'>) =>
    t('prints:actions.generate.generate', {
      docName: t(`prints:actions.generate.${docName}` as const),
      interpolation: { escapeValue: false }
    });

  const getDialogTitle = (
    docName: TranslationFrom<'prints:actions.generate'> & TranslationFrom<'prints:actions.generation'>
  ) =>
    t('prints:actions.generation.generate', {
      docName: t(`prints:actions.generation.${docName}` as const),
      interpolation: { escapeValue: false }
    });

  return { getButtonTitle, getDialogTitle };
}
export default useGetGenerateButtonTitle;
