export interface FetchStatusState {
  fetching: boolean;
  downloaded: boolean;
  error: boolean;
}

enum StatusType {
  INITIAL = 'INITIAL',
  FETCHING = 'FETCHING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}

export const Status: { [_ in StatusType]: FetchStatusState } = {
  [StatusType.INITIAL]: { fetching: false, downloaded: false, error: false },
  [StatusType.FETCHING]: { fetching: true, downloaded: false, error: false },
  [StatusType.SUCCESS]: { fetching: false, downloaded: true, error: false },
  [StatusType.FAILURE]: { fetching: false, downloaded: false, error: true }
};
