import { PaginatedQueryConfig, usePaginatedQuery } from 'react-query';
import { PermissionDetails, PermissionSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { PermissionSnapshotClient } from '../../model';
import { parseExternalAuthPermissionsList, parsePermissionsList } from '../../parsers';
import { endpointsConfig } from '../endpoints-config';

import PermissionsQueryKeysEnum from './PermissionsQueryKeysEnum';

export const getPermissions = async (_, params: PermissionSearchFilter) => {
  const { data } = await API.permissions.getPermissionsPage(params, {
    ...endpointsConfig.getPermissionsPage
  });
  return {
    ...data,
    content: parsePermissionsList(data.content)
  };
};

export const getPermissionsClient = async (_, params: PermissionSearchFilter) => {
  const { data } = await API.client.permissions.getPermissionsPage('', '', params, {
    ...endpointsConfig.getPermissionsPage
  });

  return {
    ...data,
    content: parsePermissionsList(data.content as Array<PermissionDetails>)
  };
};

export default function usePermissionsQuery(
  params: PermissionSearchFilter,
  queryConfig: PaginatedQueryConfig<any> = {}
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return usePaginatedQuery<IPaginatedModel<PermissionSnapshotClient>>(
    [PermissionsQueryKeysEnum.PERMISSIONS_LIST, params],
    getQuery(getPermissionsClient, getPermissions),
    queryConfig
  );
}

export const getExternalAuthPermissions = async (_, params: PermissionSearchFilter) => {
  const { data } = await API.permissions.getPermissionsPage(params, {
    ...endpointsConfig.getPermissionsPage
  });
  return {
    ...data,
    content: parseExternalAuthPermissionsList(data.content)
  };
};

export const getExternalAuthPermissionsClient = async (_, params: PermissionSearchFilter) => {
  const { data } = await API.client.permissions.getPermissionsPage('', '', params);

  return {
    ...data,
    content: parseExternalAuthPermissionsList(data.content as Array<PermissionDetails>)
  };
};

export function useExternalAuthPermissionsQuery(
  params: PermissionSearchFilter,
  queryConfig: PaginatedQueryConfig<any> = {}
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return usePaginatedQuery<IPaginatedModel<PermissionSnapshotClient>>(
    [PermissionsQueryKeysEnum.PERMISSIONS_LIST, params],
    getQuery(getExternalAuthPermissionsClient, getExternalAuthPermissions),
    queryConfig
  );
}
