import { ReactNode, useCallback, useMemo, useState } from 'react';

import { MuteNotificationContext } from '../../hooks/system-notification';

function MuteNotificationProvider({ children }: { children: ReactNode }) {
  const [mutedUntil, setMutedUntil] = useState<string>(null);

  const setData = useCallback((until: string) => {
    setMutedUntil(until);
  }, []);

  const value = useMemo(
    () => ({
      mutedUntil,
      setData
    }),
    [mutedUntil, setData]
  );
  return <MuteNotificationContext.Provider value={value}>{children}</MuteNotificationContext.Provider>;
}

export default MuteNotificationProvider;
