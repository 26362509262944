import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ProxySnapshot } from '@ibtm/domain';
import { isNil } from 'lodash';

import {
  Button,
  CamelCasePath,
  TableLayout,
  useFormV2Context,
  useTableRowActions,
  useViewModesV2
} from '@libs/common/v2';
import { ValueOf } from '@libs/common/v2/utils';

import { UIElementNameEnum } from '@libs/permission';

import { API } from '@libs/domain/api';
import { useApplicationDetailsQuery } from '@libs/domain/application';

import { useDomainConfigContext } from '../../config';
import DomainDictionaryEntry from '../../config/DomainDictionaryEntry';
import { useProxyCreateDialog, useProxyDetailsDialog, useProxyEditDialog, useProxyTable } from '../hooks';
import useProxyDeleteAction from '../hooks/action/useProxyDeleteAction';
import { ProxyActions, ProxyClient, ProxyFields } from '../model/proxy.model';
import { parseProxyListClientPayload, parseProxyListPayload } from '../utils';

type ApplicationCategoryEnum = ValueOf<typeof DomainDictionaryEntry.APPLICATION_CATEGORY>;
interface IProps {
  folderId?: string; // id wniosku lub teczki
  applicationId?: string;
  visibleColumns?: Array<CamelCasePath<ProxyClient>>;
  actions?: Array<string>;
  isSection?: boolean;
  isTabActionsNarrow?: boolean;
  isCollapsable?: boolean;
  actionKeys?: {
    add?: UIElementNameEnum;
    edit?: UIElementNameEnum;
    delete?: UIElementNameEnum;
    details?: UIElementNameEnum;
  };
  tableActionKeys?: Array<UIElementNameEnum>;
  folderType?: string;
  hiddenFieldIds?: (keyof ProxyFields)[];
  isCitizenshipFieldVisible?: boolean;
}

function ProxyTable({
  folderId,
  applicationId,
  visibleColumns,
  actions,
  isSection = false,
  isTabActionsNarrow,
  isCollapsable = false,
  actionKeys,
  folderType,
  hiddenFieldIds,
  tableActionKeys,
  isCitizenshipFieldVisible
}: IProps) {
  const [t] = useTranslation();
  const { formMode } = useFormV2Context();
  const { isClientPortal } = useDomainConfigContext();
  const { viewMode } = useViewModesV2(formMode);
  const applicationQuery = useApplicationDetailsQuery(applicationId, {
    enabled: !isNil(applicationId),
    refetchOnMount: true
  });
  const applicationType = applicationQuery.data?.categoryKey as ApplicationCategoryEnum;
  const tableProps = useProxyTable({ folderId, applicationId }, [
    ...visibleColumns,
    isCitizenshipFieldVisible ? 'citizenship' : null
  ]);
  const isPostCityRequired: boolean =
    DomainDictionaryEntry.APPLICATION_CATEGORY.SPO_UE === applicationQuery.data?.categoryKey ||
    folderType === DomainDictionaryEntry.FOLDER_TYPE.OP;
  const { showProxyCreateDialog } = useProxyCreateDialog({
    folderId,
    applicationId,
    applicationType,
    isPostCityRequired,
    isCitizenshipFieldVisible,
    hiddenFieldIds
  });
  const { showProxyDetailsDialog } = useProxyDetailsDialog();
  const { showProxyEditDialog } = useProxyEditDialog({
    folderId,
    applicationId,
    applicationType,
    isPostCityRequired,
    isCitizenshipFieldVisible,
    hiddenFieldIds
  });
  const { handleProxyDelete } = useProxyDeleteAction();
  const showAction = useCallback((action: ProxyActions) => actions?.includes(action), [actions]);

  const { renderTableActions } = useTableRowActions<ProxySnapshot>([
    {
      id: actionKeys?.details,
      label: 'action.openDetails',
      onClick: ({
        row: {
          original: { id }
        }
      }) => showProxyDetailsDialog(id, isCitizenshipFieldVisible),
      isHidden: !showAction('details'),
      icon: 'ArrowIcon'
    },
    {
      id: actionKeys?.edit,
      label: 'action.edit',
      onClick: ({
        row: {
          original: { id, version }
        }
      }) => showProxyEditDialog(id, version),
      isHidden: !showAction('edit'),
      ...(!showAction('details') && { icon: 'EditIcon' })
    },
    {
      id: actionKeys?.delete,
      label: 'action.delete',
      onClick: ({
        row: {
          original: { id, name, surname }
        }
      }) => handleProxyDelete({ proxyId: id, name, surname, onSuccess: null }),
      isHidden: !showAction('delete'),
      ...(!showAction('details') && { icon: 'TrashIcon' })
    }
  ]);

  const apiRequest = useCallback(
    params => {
      if (isClientPortal) {
        const queryParams = parseProxyListClientPayload(params);
        return API.client.proxy.getProxiesSnapshotPage('', '', queryParams);
      }

      const queryParams = parseProxyListPayload(params);
      return API.proxy.getProxiesSnapshotPage(queryParams);
    },
    [isClientPortal]
  );

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('proxy:title')}
      xlsxDownload={{
        fileName: t('proxy:tableTitle'),
        pathToXLSXTranslation: 'proxy:field',
        apiRequest: params => apiRequest(params)
      }}
      actionsColumnWidth={isTabActionsNarrow && 80}
      headerActions={
        !viewMode &&
        showAction('create') && (
          <Button
            label={t('action.add')}
            onClick={showProxyCreateDialog}
            variant="outlined"
            classNameWrapper="ml-16"
            actionKey={actionKeys?.add}
            isNoMargin
          />
        )
      }
      mobileHeaderActions={[
        {
          label: t('action.add'),
          actionKey: actionKeys?.add,
          onClick: showProxyCreateDialog,
          isHidden: viewMode || !showAction('create')
        }
      ]}
      rowActions={renderTableActions(tableActionKeys)}
      isActionColumnEnabled={actions?.some(action => ['edit', 'delete', 'details'].includes(action))}
      isCollapsable={isCollapsable}
      isSection={isSection}
    />
  );
}

export default ProxyTable;
