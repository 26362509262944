import { useTableLayoutProps } from '@libs/common/v2';

import { useDocumentsQuery } from '../../api';
import useDocumentsColumns from '../columns/useDocumentsColumns';

interface IDocumentsTableParams {
  proceedingId: string;
}

function useDocumentsTable({ proceedingId }: IDocumentsTableParams) {
  const { columns, mappedFilterFields, mappedSortFields } = useDocumentsColumns();

  return useTableLayoutProps({
    tableHookQuery: useDocumentsQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    },
    tableHookQueryInitialParams: {
      proceedingId
    }
  });
}

export default useDocumentsTable;
