import {
  AdditionalSectionLicenseOrPermissionType,
  ApplicationMetaFormDefinition
} from '@libs/domain/application/model';

const definition: ApplicationMetaFormDefinition = {
  fields: [
    {
      typeKey: 'TEXT',
      fieldId: 'foreignPermissionApplicationDetails-typeKey',
      properties: {
        api: {
          get: {
            requestKey: 'GET_APPLICATION_FOREIGN_PERMISSIONS_TYPE'
          }
        },
        hidden: true
      }
    }
  ],
  components: [],
  layout: [
    {
      typeKey: 'TAB_NAVIGATION',
      properties: {
        visibility: {
          elementVisibilityKeys: ['TAB_VISIBILITY']
        },
        title: 'Sekcje',
        defaultTabId: 'REQUESTED_PERMITS',
        tabs: [
          {
            id: 'REQUESTED_PERMITS',
            title: 'Wnioskowane zezwolenia',
            icon: 'ForeignPermitsIcon'
          },
          {
            id: 'SUBJECT',
            title: 'Podmiot',
            icon: 'FacadeIcon'
          },
          {
            id: 'ADDRESSES',
            title: 'Adresy',
            icon: 'MailboxIcon'
          },
          {
            id: 'PROXIES',
            title: 'Pełnomocnicy',
            icon: 'GroupOfPeopleIcon'
          },
          {
            id: 'QUOTATION',
            title: 'Wycena',
            icon: 'CoinsIcon'
          },
          {
            id: 'OUTGOING_DOCUMENTS',
            title: 'Dokumenty wychodzące',
            icon: 'DocumentsIssuedIcon'
          },
          {
            id: 'NOTE',
            title: 'Notatka',
            icon: 'NoteIcon'
          },
          {
            id: 'FILES',
            title: 'Pliki',
            icon: 'FileEmptyIcon'
          },
          {
            id: 'HISTORY',
            title: 'Historia',
            icon: 'HistoryIcon'
          }
        ]
      },
      nodes: [
        {
          typeKey: 'TABS_CONTENT',
          nodes: [
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-requested-permits-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-subject-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-addresses-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-proxies-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-quotation-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-outgoing-documents-tab',
                additionalNodes: {
                  'outgoing-documents-additional-section': [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'application-outgoing-documents-additional-section-license-or-permission',
                        sectionTypes: [AdditionalSectionLicenseOrPermissionType.PERMISSION]
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-note-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-files-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-history-tab'
              }
            }
          ]
        }
      ]
    }
  ]
};

export default definition;
