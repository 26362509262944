import { usePaginatedQuery } from 'react-query';
import { DefaultApiListUserGroupMembershipsRequest } from '@avispon/group';
import { PageGroupMembershipDetailsExternal } from '@avispon/group/dist/models';

import { parsePermissionGroupsPayload } from '../../utils';
import { API, endpointsConfig } from '..';

import { UserQueryKeysEnum } from './QueryKeysEnum';

const getMyGroupMemberships = async (_, params: DefaultApiListUserGroupMembershipsRequest & { userId?: string }) => {
  const { data } = await (!params?.userId
    ? API.group.listMyGroupMemberships.bind(API.group)
    : API.group.listUserGroupMemberships.bind(API.group))(parsePermissionGroupsPayload(params), {
    ...endpointsConfig.listMyGroupMemberships
  });

  return data;
};

export default function usePermissionGroupQuery(params: DefaultApiListUserGroupMembershipsRequest, queryConfig = {}) {
  return usePaginatedQuery<PageGroupMembershipDetailsExternal>(
    [UserQueryKeysEnum.PROFILE_GROUP_PERMISSIONS, params],
    getMyGroupMemberships,
    queryConfig
  );
}
