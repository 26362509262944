import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useRouter } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';

interface IProps {
  userId: string;
  name: string;
}

function GroupMemberLink({ userId, name }: IProps) {
  const [t] = useTranslation();
  const classes = useStyles();
  const { routes } = useRouter();

  return (
    <Tooltip title={t('administration:organizationUnit.viewUserDetails')}>
      <Link className={classes.link} to={routes.organizationUnitMemberDetails(userId)}>
        {name}
      </Link>
    </Tooltip>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  link: {
    color: theme.palette.blue[500],
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline',
      border: 'none',
      outline: 'none'
    }
  }
}));

export default GroupMemberLink;
