import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { ReportTypeDetailsSnapshot } from '@stack/report';

import { useConfirmDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { ReportQueryKeysEnum, useRemoveReportTypeMutation } from '@libs/report/api';

const useDeleteReportType = () => {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const [confirm] = useConfirmDialog();

  const { mutate: deleteReportType } = useRemoveReportTypeMutation();

  const handleDeleteReportType = (reportType: ReportTypeDetailsSnapshot) => {
    confirm({
      title: t('reports:action.deleteReport'),
      message: t('reports:message.reportTypeRemoveConfirmation', { name: reportType.name }),
      onConfirm: async (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        deleteReportType(reportType.id, {
          onSuccess: () => {
            showSuccessSnackbar(t('reports:message.reportTypeRemoveSuccess'));
            queryCache.invalidateQueries(ReportQueryKeysEnum.REPORT_TYPES);
            closeDialog();
          },
          onSettled: () => {
            setConfirmLoading(false);
          }
        });
      },
      confirmType: 'danger'
    });
  };

  return { handleDeleteReportType };
};

export default useDeleteReportType;
