import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ForeignPermissionDocumentCreateRequest, ForeignPermissionSettlementCreateRequest } from '@ibtm/domain';
import { isNil } from 'lodash';
import moment from 'moment';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  Dialog,
  DictionarySelectField,
  FormMode,
  FormV2Context,
  GridLayout,
  typedNameV2
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';
import {
  ekmtSettleValidationSchema,
  ForeignPermissionQueryEnum,
  IDownloadFileParam,
  ReleasedPermitsSnapshot,
  SettlementCurrentPermitsTable,
  useGenerateForeignPermissionDocument,
  usePrintReportDialog,
  useSettleForeignPermitMutation
} from '@libs/domain/foreign-permission';

interface IProps {
  foreignPermissionId: string;
  selectedPermitIds?: string[];
  selectedPermits: ReleasedPermitsSnapshot[];
  initialData: ReleasedPermitsSnapshot;
  closeDialog: () => void;
  onSuccess?: () => void;
  hasDefaultDestiny?: boolean;
}

interface ForeignPermissionSettlementCreateFormValues extends ForeignPermissionSettlementCreateRequest {
  permissionNumber: number;
  formNumber?: string;
}

function SettlePermitEKMTForm({
  foreignPermissionId,
  selectedPermitIds,
  selectedPermits,
  initialData,
  closeDialog,
  onSuccess,
  hasDefaultDestiny
}: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();

  const { mutate: settlePermit, isLoading } = useSettleForeignPermitMutation();
  const { openPrintReportDialog } = usePrintReportDialog();
  const downloadFileAfterSettleForeignPermission = useCallback(({ downloadFunction, data }: IDownloadFileParam) => {
    return data?.content?.map(file => downloadFunction(file));
  }, []);
  const { generateConfirmationOfReturn } = useGenerateForeignPermissionDocument({
    onSuccess: openPrintReportDialog,
    downloadFileAfterSettleForeignPermission,
    isForeignPermissionsUse: true
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Partial<ForeignPermissionSettlementCreateFormValues>>({
    mode: 'onBlur',
    resolver: yupResolver(ekmtSettleValidationSchema()),
    defaultValues: { ...initialData, returnDate: moment().toISOString() }
  });

  const getLabel = partialTranslate('foreignPermits:tabs.releasedPermits.ekmtDetails.settlementEKMT.formFields');

  const handleOnConfirm = (formData: ForeignPermissionSettlementCreateFormValues) => {
    const foreignPermissionIds = isNil(selectedPermitIds) ? [foreignPermissionId] : selectedPermitIds;

    const parsedData: ForeignPermissionSettlementCreateRequest = {
      ids: foreignPermissionIds,
      returnDate: formData?.returnDate,
      codeKey: formData?.codeKey
    };
    const params: ForeignPermissionDocumentCreateRequest = {
      documentTemplateId: '',
      foreignPermissionIdsObjects: [
        {
          foreignPermissionIds
        }
      ]
    };

    settlePermit(parsedData, {
      onSuccess: () => {
        generateConfirmationOfReturn(params);
        showSuccessSnackbar(t('success.save'));
        onSuccess?.();
        queryCache.invalidateQueries(ForeignPermissionQueryEnum.FOREIGN_PERMISSION_LIST);
      }
    });
  };

  const values = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      formMode: FormMode.CREATE
    }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  const visibleColumnsForSinglePermit: Array<keyof ReleasedPermitsSnapshot> = useMemo(
    () => ['year', 'name', 'formFullNumber'],
    []
  );

  useEffect(() => {
    if (hasDefaultDestiny) {
      setValue('codeKey', DomainDictionaryEntry.RETURNS_OF_FOREIGN_LICENSES.USED_CORRECTLY);
    }
  }, [hasDefaultDestiny, setValue]);

  return (
    <Dialog
      title={t('foreignPermits:tabs.releasedPermits.ekmtDetails.settlementEKMT.settleTitle')}
      confirmText={t('foreignPermits:settlementDisposablePermits.action.settle')}
      cancelText={t('action.cancel')}
      onConfirm={handleSubmit(formData => handleOnConfirm(formData as ForeignPermissionSettlementCreateFormValues))}
      onCancel={closeDialog}
      dialogSize="medium"
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <GridLayout itemProps={{ xs: 12 }}>
          <DictionarySelectField
            name={typedNameV2<ForeignPermissionSettlementCreateRequest>('codeKey')}
            label={getLabel('destiny')}
            dictionaryName={DomainDictionaryEnum.RETURNS_OF_FOREIGN_LICENSES}
            isRequired
            stringValue
          />
          <DatepickerField
            name={typedNameV2<ForeignPermissionSettlementCreateRequest>('returnDate')}
            label={getLabel('settlementDate')}
            isRequired
          />
          <SettlementCurrentPermitsTable
            data={selectedPermits ?? [initialData]}
            visibleColumns={visibleColumnsForSinglePermit}
          />
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default SettlePermitEKMTForm;
