import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSnackbar } from '@enigma/fe-ui';
import { SubjectLiteExtended } from '@ibtm/client-domain';
import { DICTIONARY_IBTM_DOMAIN_PREFIX } from '@libs/config/constants';
import axios from 'axios';

import { useDevMode } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '@libs/common/v2/utils';

import { useAuth } from '@libs/auth/hooks';

import { ClientPortalUserPerspectiveEnum, DomainDictionaryEntry, useDomainConfigContext } from '@libs/domain/config';
import { LocalStorageItems } from '@libs/domain/routing-guards';

import PartnershipSelectionRoutingGuardDialog from './modal/PartnershipSelectionDialog';
import PartnershipSelectionRoutingGuardContext from './PartnershipSelectionRoutingGuardContext';

export enum DomainLocalStorageItems {
  SELECTED_PARTNERSHIP = 'selected_partnership'
}

const initialState: SubjectLiteExtended = {
  id: null,
  name: null,
  folder: { id: null }
};

interface IProps {
  children: ReactNode;
  onSelectPartnership: (subject: SubjectLiteExtended) => void;
}

function PartnershipSelectionRoutingGuardProvider({ children, onSelectPartnership }: IProps) {
  const queryCache = useQueryCache();
  // TODO: useDevMode - usunąć po całkowitej integracji API ibtm-client-domain
  const { isDevMode } = useDevMode();
  const domainConfigContext = useDomainConfigContext();
  const { showSnackbar } = useSnackbar();
  const { isLoggedIn, logout } = useAuth();

  const interceptorId = useRef<number>(null);
  const [selectedPartnership, setSelectedPartnership] = useState<SubjectLiteExtended>(initialState);

  const handleSetSelectedPartnership = useCallback(
    (subject: SubjectLiteExtended) => {
      setSelectedPartnership(subject);
      onSelectPartnership?.(subject);
    },
    [onSelectPartnership]
  );

  const logoutAndPartnershipSelected = !isLoggedIn && selectedPartnership?.folder?.id !== null;
  const loggedInAndNoPartnershipSelected = isLoggedIn && selectedPartnership?.folder?.id === null;

  // TODO: usunąć po całkowitej integracji API ibtm-client-domain
  const showRequestWarnings = useCallback(
    config => {
      if (isDevMode) {
        if (config?.url.includes(DICTIONARY_IBTM_DOMAIN_PREFIX)) {
          // eslint-disable-next-line no-console
          console.log(`Wymagane przepięcie API na ibtm-client-domain (${config.method.toUpperCase()}: ${config.url})`);
          showSnackbar(
            'info',
            `Wymagane przepięcie API na ibtm-client-domain (${config.method.toUpperCase()}: ${config.url})`
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDevMode]
  );

  const getSubjectId = config => {
    try {
      return config.headers['x-subject-id']
        ? JSON.parse(config.headers['x-subject-id']) || selectedPartnership?.id
        : selectedPartnership?.id;
    } catch (_) {
      return selectedPartnership?.id;
    }
  };

  const interceptor = useCallback(
    config => {
      showRequestWarnings(config);

      return {
        ...config,
        headers: {
          ...config.headers,
          'x-folder-id': selectedPartnership?.folder?.id,
          'x-subject-id': getSubjectId(config) as string
        } as object
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPartnership, isDevMode]
  );

  // TODO: usunąć po całkowitej integracji API ibtm-client-domain
  useEffect(() => {
    axios.interceptors.request.eject(interceptorId.current);

    if (selectedPartnership?.folder?.id) {
      interceptorId.current = axios.interceptors.request.use(interceptor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDevMode]);

  const setClientPortalUserPerspective = useCallback(
    (typeKey: string) => {
      if (typeKey === DomainDictionaryEntry.FOLDER_TYPE.ZZ) {
        domainConfigContext.setClientPortalUserPerspective(ClientPortalUserPerspectiveEnum.ASSOCIATION);
      } else {
        domainConfigContext.setClientPortalUserPerspective(ClientPortalUserPerspectiveEnum.ENTREPRENEUR);
      }
    },
    [domainConfigContext]
  );

  useEffect(() => {
    if (selectedPartnership?.folder?.id) {
      interceptorId.current = axios.interceptors.request.use(interceptor);
    }
  }, [selectedPartnership, interceptor]);

  useEffect(() => {
    if (logoutAndPartnershipSelected) {
      handleSetSelectedPartnership(initialState);
      axios.interceptors.request.eject(interceptorId.current);
    }

    if (loggedInAndNoPartnershipSelected) {
      const partnershipFromLocalStorage: SubjectLiteExtended = JSON.parse(
        getLocalStorageItem(DomainLocalStorageItems.SELECTED_PARTNERSHIP)
      ) as SubjectLiteExtended;

      if (partnershipFromLocalStorage) {
        handleSetSelectedPartnership(partnershipFromLocalStorage);
        setClientPortalUserPerspective(partnershipFromLocalStorage?.folder?.typeKey);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, loggedInAndNoPartnershipSelected, logoutAndPartnershipSelected, selectedPartnership]);

  const handleChooseRow = useCallback(
    (_selectedPartnership: SubjectLiteExtended) => {
      axios.interceptors.request.eject(interceptorId.current);
      setLocalStorageItem(DomainLocalStorageItems.SELECTED_PARTNERSHIP, JSON.stringify(_selectedPartnership));

      handleSetSelectedPartnership(_selectedPartnership);
      setClientPortalUserPerspective(_selectedPartnership.folder?.typeKey);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [interceptorId, setClientPortalUserPerspective]
  );

  const handleCancel = useCallback(async () => {
    await logout(queryCache);
    removeLocalStorageItem(LocalStorageItems.SELECTED_PARTNERSHIP);
    queryCache.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryCache]);

  const values = useMemo(
    () => ({ selectedPartnership, setSelectedPartnership: handleSetSelectedPartnership }),
    [handleSetSelectedPartnership, selectedPartnership]
  );

  return (
    <PartnershipSelectionRoutingGuardContext.Provider value={values}>
      {loggedInAndNoPartnershipSelected ? (
        <PartnershipSelectionRoutingGuardDialog handleChooseRow={handleChooseRow} handleCancel={handleCancel} />
      ) : (
        children
      )}
    </PartnershipSelectionRoutingGuardContext.Provider>
  );
}

export default PartnershipSelectionRoutingGuardProvider;
