import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button, FormMode, TableLayout, useDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import {
  FolderDetailsParams,
  FolderQueryKeysEnum,
  parseInsurancePoliciesListResults,
  useInsurancePoliciesTable
} from '@libs/domain/folder';

import { InsurancePoliciesEditFormDialog } from '../components/InsurancePoliciesEditFormDialog';

import InsurancePoliciesTableRowActions from './InsurancePoliciesTableRowActions';

function InsurancePoliciesTab() {
  const { folderId } = useParams<FolderDetailsParams>();
  const [t] = useTranslation();
  const { isOperatorPortal } = useDomainConfigContext();
  const { getQuery } = useGetApiQueryByPerspective();
  const { isClientPortal } = useDomainConfigContext();
  const queryCache = useQueryCache();

  const { openDialog } = useDialog();

  const tableProps = useInsurancePoliciesTable({
    ...(isOperatorPortal ? { folderIdIn: [folderId] } : { folderIdEq: folderId })
  });

  const xlsxDownloadApi = useCallback(params => {
    const apiMethod = getQuery(
      () => API.client.insurancePolicies.getInsurancePoliciesPageForFolder('', '', params),
      () => API.insurancePolicies.getInsurancePoliciesPage(params)
    );

    return apiMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openFromDialog = () => {
    openDialog(({ closeDialog }) => (
      <InsurancePoliciesEditFormDialog
        folderId={folderId}
        formMode={FormMode.CREATE}
        closeDialog={closeDialog}
        refetch={() => {
          queryCache.invalidateQueries([{ folderIdIn: [folderId] }, FolderQueryKeysEnum.INSURANCE_POLICIES]);
        }}
      />
    ));
  };

  return (
    <div>
      <TableLayout
        pageTitle={t('folder:tab.insurancePolicies')}
        {...(!isClientPortal && {
          rowActions: ({ original }) => <InsurancePoliciesTableRowActions folderId={folderId} editFormData={original} />
        })}
        headerActions={
          isOperatorPortal && (
            <Button
              variant="outlined"
              label={t('action.add')}
              actionKey={DomainUIElementEnum.FOLDER_INSURANCE_POLICY_CREATE_BUTTON}
              onClick={openFromDialog}
            />
          )
        }
        mobileHeaderActions={[
          {
            label: t('action.add'),
            actionKey: DomainUIElementEnum.FOLDER_INSURANCE_POLICY_CREATE_BUTTON,
            onClick: openFromDialog,
            isHidden: !isOperatorPortal
          }
        ]}
        xlsxDownload={{
          fileName: t('folder:tab.insurancePolicies'),
          pathToXLSXTranslation: 'folder:details.tab.insurancePolicies.field',
          apiRequest: params =>
            xlsxDownloadApi(params).then(res => ({
              ...res,
              data: {
                ...res.data,
                content: parseInsurancePoliciesListResults(res.data.content)
              }
            }))
        }}
        {...tableProps}
        isFilterEnabled={false}
        isTableMutable
        isCollapsable
        isSection
      />
    </div>
  );
}

export default InsurancePoliciesTab;
