import { QueryConfig, useQuery } from 'react-query';

import { useGetApiQueryByPerspective } from '@libs/domain/api';
import { ApplicationQueryKeysEnum } from '@libs/domain/application';
import { DomainDictionaryEntry } from '@libs/domain/config';

import * as ApplicationDefinitionsClientPortal from '../../definitions/client-portal';
import * as ApplicationDefinitions from '../../definitions/operator-portal';

type UiDefinition = {
  fields: any;
  components: any;
  layout: any;
};
const ApplicationDefinitionClientPortalConfig = {
  [DomainDictionaryEntry.APPLICATION_TYPE.PB1A]: ApplicationDefinitionsClientPortal.pb1a_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB1B]: ApplicationDefinitionsClientPortal.pb1b_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB1C]: ApplicationDefinitionsClientPortal.pb1c_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB3A]: ApplicationDefinitionsClientPortal.pb3a_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB11A]: ApplicationDefinitionsClientPortal.pb11a_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB11B]: ApplicationDefinitionsClientPortal.pb11b_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB11C]: ApplicationDefinitionsClientPortal.pb11c_JSON,
  // Driver
  [DomainDictionaryEntry.APPLICATION_TYPE.issueOfDriverCertificate]:
    ApplicationDefinitionsClientPortal.issueOfDriverCertificate_JSON,

  [DomainDictionaryEntry.APPLICATION_TYPE.RESUMING_ISSUE_OF_DRIVER_CERTIFICATE]:
    ApplicationDefinitionsClientPortal.issueOfDriverCertificate_JSON,
  // S-Certificate
  [DomainDictionaryEntry.APPLICATION_TYPE.sCertificateChangeOfDocument]:
    ApplicationDefinitionsClientPortal.sCertificateChangeOfDocument_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.sCertificateIssuingDocument]:
    ApplicationDefinitionsClientPortal.sCertificateIssuingDocument_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.sCertificateIssuingDuplicate]:
    ApplicationDefinitionsClientPortal.sCertificateIssuingDuplicate_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.sCertificateResumingIssueOfDocument]:
    ApplicationDefinitionsClientPortal.sCertificateResumingIssueOfDocument_JSON,
  // Foreign-Permit
  [DomainDictionaryEntry.APPLICATION_TYPE.foreignPermitSingleTp]: ApplicationDefinitionsClientPortal.foreignPermit_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.foreignPermitSingleOp]: ApplicationDefinitionsClientPortal.foreignPermit_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.foreignPermitEkmt]: ApplicationDefinitionsClientPortal.foreignPermit_JSON,
  // SPO - spoUe
  [DomainDictionaryEntry.APPLICATION_TYPE.spoUeLaunch]: ApplicationDefinitionsClientPortal.spoUe_JSON,
  // SPO-OWN-NEEDS
  [DomainDictionaryEntry.APPLICATION_TYPE.spoOwnNeedsIpwz]: ApplicationDefinitionsClientPortal.spoOwnNeedsIpwz_JSON,
  // FZO - fzo
  [DomainDictionaryEntry.APPLICATION_TYPE.fzo]: ApplicationDefinitions.fzo_JSON
};

const ApplicationDefinitionOperatorPortalConfig = {
  [DomainDictionaryEntry.APPLICATION_TYPE.PB1A]: ApplicationDefinitions.pb1a_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB1B]: ApplicationDefinitions.pb1b_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB1C]: ApplicationDefinitions.pb1c_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB1D]: ApplicationDefinitions.pb1d_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB2A]: ApplicationDefinitions.pb2a_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB2B]: ApplicationDefinitions.pb2a_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB3A]: ApplicationDefinitions.pb3a_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB3B]: ApplicationDefinitions.pb3b_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB4A]: ApplicationDefinitions.pb4a_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB4B]: ApplicationDefinitions.pb4b_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB4C]: ApplicationDefinitions.pb4c_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB4D]: ApplicationDefinitions.pb4d_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB4E]: ApplicationDefinitions.pb4e_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB4F]: ApplicationDefinitions.pb4f_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB5]: ApplicationDefinitions.pb5_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB6A]: ApplicationDefinitions.pb6a_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB6B]: ApplicationDefinitions.pb6b_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB6C]: ApplicationDefinitions.pb6c_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB6D]: ApplicationDefinitions.pb6d_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB6E]: ApplicationDefinitions.pb6e_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB6F]: ApplicationDefinitions.pb6f_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB7A]: ApplicationDefinitions.pb7a_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB7B]: ApplicationDefinitions.pb7b_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB7C]: ApplicationDefinitions.pb7c_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB8A]: ApplicationDefinitions.pb8a_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB8B]: ApplicationDefinitions.pb8b_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB8C]: ApplicationDefinitions.pb8c_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB9A]: ApplicationDefinitions.pb9a_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB9B]: ApplicationDefinitions.pb9b_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB9C]: ApplicationDefinitions.pb9c_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB10A]: ApplicationDefinitions.pb10a_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB10B]: ApplicationDefinitions.pb10b_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB11A]: ApplicationDefinitions.pb11a_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB11B]: ApplicationDefinitions.pb11b_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB11C]: ApplicationDefinitions.pb11c_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB11D]: ApplicationDefinitions.pb11d_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB12]: ApplicationDefinitions.pb12_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB14]: ApplicationDefinitions.pb14_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB26]: ApplicationDefinitions.pb26_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB27]: ApplicationDefinitions.pb27_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB28]: ApplicationDefinitions.pb28_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB29A]: ApplicationDefinitions.pb29a_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB29B]: ApplicationDefinitions.pb29b_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB30]: ApplicationDefinitions.pb30_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB31]: ApplicationDefinitions.pb31_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB32A]: ApplicationDefinitions.pb32a_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB32B]: ApplicationDefinitions.pb32b_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB32A]: ApplicationDefinitions.pb32a_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.PB33]: ApplicationDefinitions.pb33_JSON,
  // Other
  [DomainDictionaryEntry.APPLICATION_TYPE.licenseDischargeWaiver]: ApplicationDefinitions.licenseDischargeWaiver_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.licenseFinancialSecurityUpdate]:
    ApplicationDefinitions.licenseFinancialSecurityUpdate_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.licenseIssuingCertificate]:
    ApplicationDefinitions.licenseIssuingCertificate_JSON,
  // Driver
  [DomainDictionaryEntry.APPLICATION_TYPE.changeOfDriverCertificate]:
    ApplicationDefinitions.changeOfDriverCertificate_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.inputMistake]: ApplicationDefinitions.inputMistake_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.issueOfDriverCertificate]:
    ApplicationDefinitions.issueOfDriverCertificate_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.issuingDuplicateDriverCertificate]:
    ApplicationDefinitions.issuingDuplicateDriverCertificate_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.DRIVER_CERTIFICATE_DUPLICATE_OF_EXTRACT]:
    ApplicationDefinitions.issuingDuplicateDriverCertificate_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.restoreOfDriverCertificate]:
    ApplicationDefinitions.restoreOfDriverCertificate_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.RESUMING_ISSUE_OF_DRIVER_CERTIFICATE]:
    ApplicationDefinitions.issueOfDriverCertificate_JSON,
  // S-Certificate
  [DomainDictionaryEntry.APPLICATION_TYPE.sCertificateChangeOfDocument]:
    ApplicationDefinitions.sCertificateChangeOfDocument_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.sCertificateIssuingDocument]:
    ApplicationDefinitions.sCertificateIssuingDocument_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.sCertificateIssuingDuplicate]:
    ApplicationDefinitions.sCertificateIssuingDuplicate_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.sCertificateResumingIssueOfDocument]:
    ApplicationDefinitions.sCertificateResumingIssueOfDocument_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.sCertificateReprintBtm]:
    ApplicationDefinitions.sCertificateReprintBtmDocument_JSON,
  // Foreign-Permit
  [DomainDictionaryEntry.APPLICATION_TYPE.foreignPermitSingleTp]: ApplicationDefinitions.foreignPermit_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.foreignPermitSingleOp]: ApplicationDefinitions.foreignPermit_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.foreignPermitEkmt]: ApplicationDefinitions.foreignPermit_JSON,
  // SPO
  // SPO - spoUe
  [DomainDictionaryEntry.APPLICATION_TYPE.spoUeLaunch]: ApplicationDefinitions.spoUe_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoUeDecisionToChangeTerms]: ApplicationDefinitions.spoUe_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoUeExtend]: ApplicationDefinitions.spoUe_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoUeAdditionalExcerpt]: ApplicationDefinitions.spoUe_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoUeModification]: ApplicationDefinitions.spoUe_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoUeModificationWithoutPrinting]: ApplicationDefinitions.spoUe_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoUeDuplicatePermit]: ApplicationDefinitions.spoUe_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoUeDuplicateOfExtract]: ApplicationDefinitions.spoUe_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoUeFreeReprint]: ApplicationDefinitions.spoUe_JSON,
  // SPO - spoOutsideUe
  [DomainDictionaryEntry.APPLICATION_TYPE.spoOutsideUeLaunch]: ApplicationDefinitions.spoOutsideUe_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoOutsideUeDecisionToChangeTerms]: ApplicationDefinitions.spoOutsideUe_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoOutsideUeExtend]: ApplicationDefinitions.spoOutsideUe_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoOutsideUeAdditionalExcerpt]: ApplicationDefinitions.spoOutsideUe_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoOutsideUeModification]: ApplicationDefinitions.spoOutsideUe_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoOutsideUeModificationWithoutPrinting]:
    ApplicationDefinitions.spoOutsideUe_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoOutsideUeDuplicatePermit]: ApplicationDefinitions.spoOutsideUe_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoOutsideUeDuplicateOfExtract]: ApplicationDefinitions.spoOutsideUe_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoOutsideUeFreeReprint]: ApplicationDefinitions.spoOutsideUe_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.SPO_OUTSIDE_ADDITIONAL_EXCERPT]: ApplicationDefinitions.spoOutsideUe_JSON,
  // SPO - spoWzo
  [DomainDictionaryEntry.APPLICATION_TYPE.spoWzoLaunch]: ApplicationDefinitions.spoWzo_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoWzoDecisionToChangeTerms]: ApplicationDefinitions.spoWzo_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoWzoAdditionalExcerpt]: ApplicationDefinitions.spoWzo_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoWzoModification]: ApplicationDefinitions.spoWzo_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoWzoModificationWithoutPrinting]: ApplicationDefinitions.spoWzo_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoWzoDuplicatePermit]: ApplicationDefinitions.spoWzo_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoWzoDuplicateOfExtract]: ApplicationDefinitions.spoWzo_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoWzoFreeReprint]: ApplicationDefinitions.spoWzo_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.SPO_WZO_APPLICATION]: ApplicationDefinitions.spoWzo_JSON,
  // SPO - spoWzw
  [DomainDictionaryEntry.APPLICATION_TYPE.spoWzwLaunch]: ApplicationDefinitions.spoWzw_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoWzwDecisionToChangeTerms]: ApplicationDefinitions.spoWzw_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoWzwAdditionalExcerpt]: ApplicationDefinitions.spoWzw_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoWzwModification]: ApplicationDefinitions.spoWzw_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoWzwDuplicatePermit]: ApplicationDefinitions.spoWzw_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoWzwDuplicateOfExtract]: ApplicationDefinitions.spoWzw_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoWzwReprintedAtFaultBtm]: ApplicationDefinitions.spoWzw_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoWzwApplication]: ApplicationDefinitions.spoWzw_JSON,

  // SPO - spoTransitRp
  [DomainDictionaryEntry.APPLICATION_TYPE.spoTransitRpLaunch]: ApplicationDefinitions.spoTransitRp_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoTransitRpDecisionToChangeTerms]: ApplicationDefinitions.spoTransitRp_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoTransitRpExtend]: ApplicationDefinitions.spoTransitRp_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoTransitRpAdditionalExcerpt]: ApplicationDefinitions.spoTransitRp_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoTransitRpModification]: ApplicationDefinitions.spoTransitRp_JSON,

  [DomainDictionaryEntry.APPLICATION_TYPE.spoTransitRpDuplicatePermit]: ApplicationDefinitions.spoTransitRp_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoTransitRpDuplicateOfExtract]: ApplicationDefinitions.spoTransitRp_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoTransitRpReprintedAtFaultBtm]: ApplicationDefinitions.spoTransitRp_JSON,

  // SPO - spoPositionMind
  [DomainDictionaryEntry.APPLICATION_TYPE.SPO_POSITION_MIND]: ApplicationDefinitions.spoPositionMind_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoPositionMindLaunch]: ApplicationDefinitions.spoPositionMind_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoPositionMindExtend]: ApplicationDefinitions.spoPositionMind_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoPositionMindModification]: ApplicationDefinitions.spoPositionMind_JSON,
  // SPO-OWN-NEEDS
  [DomainDictionaryEntry.APPLICATION_TYPE.spoOwnNeedsPwr1]: ApplicationDefinitions.spoOwnNeedsPwr1_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoOwnNeedsPwr2]: ApplicationDefinitions.spoOwnNeedsPwr2_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoOwnNeedsPwr3]: ApplicationDefinitions.spoOwnNeedsPwr3_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoOwnNeedsPwr3Reprint]: ApplicationDefinitions.spoOwnNeedsPwr3_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoOwnNeedsIpwz]: ApplicationDefinitions.spoOwnNeedsIpwz_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoOwnNeedsIpwzInfo]: ApplicationDefinitions.spoOwnNeedsIpwzInfo_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoOwnNeedsPwo1]: ApplicationDefinitions.spoOwnNeedsPwo1_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoOwnNeedsPwo2]: ApplicationDefinitions.spoOwnNeedsPwo2_JSON,
  [DomainDictionaryEntry.APPLICATION_TYPE.spoOwnNeedsPwo2Reprint]: ApplicationDefinitions.spoOwnNeedsPwo2_JSON,

  // FZO - fzo
  [DomainDictionaryEntry.APPLICATION_TYPE.fzo]: ApplicationDefinitions.fzo_JSON
};

export const getApplicationUIDefinition = async (applicationTypeKey: string) => {
  const uiDefinition = ApplicationDefinitionOperatorPortalConfig[applicationTypeKey] ?? null;
  return uiDefinition;
};
export const getApplicationUIDefinitionClient = async (applicationTypeKey: string) => {
  const uiDefinition = ApplicationDefinitionClientPortalConfig[applicationTypeKey] ?? null;
  return uiDefinition;
};

export default function useApplicationUIDefinitionQuery(
  { applicationTypeKey }: { applicationTypeKey: string },
  queryConfig: QueryConfig<UiDefinition, unknown> = {}
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery(
    [ApplicationQueryKeysEnum.APPLICATION_UI_DEFINITION, { applicationTypeKey }],
    getQuery(
      () => getApplicationUIDefinitionClient(applicationTypeKey) as any,
      () => getApplicationUIDefinition(applicationTypeKey) as any
    ),
    {
      enabled: applicationTypeKey,
      ...queryConfig
    }
  );
}
