import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PullNotificationSnapshot } from '@avispon/message/models';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { isNil } from 'lodash';

import {
  ButtonsGroup,
  FormMode,
  FormV2Context,
  IPropsItem,
  Page,
  PageHeader,
  useBreadcrumbsConverter,
  useBreadcrumbTextByFormMode,
  useRouter
} from '@libs/common/v2';
import { BackButton } from '@libs/common/v2/components/button/BackButton';
import { useViewModesV2 } from '@libs/common/v2/form';
import PageHeaderFormActions from '@libs/common/v2/templates/page-header/PageHeaderFormActions';
import { unescapeValue } from '@libs/common/v2/utils';

import { useAdminMessageDetailsQuery, useCreateAdminMessageMutation } from '@libs/notification/api';
import { AdminMessageSection } from '@libs/notification/components';

import useAdminMessagesValidation from '../../hooks/useAdminMessagesValidation';
import { AdminMessageParams, INITIAL_VALUES } from '../../models';
import { convertedData } from '../../utils';

interface AdminMessageDetailsProps {
  formMode: FormMode;
}

function AdminMessageDetails({ formMode }: AdminMessageDetailsProps) {
  const [t] = useTranslation();
  const { goBack } = useRouter();
  const { showSuccessSnackbar } = useSnackbar();
  const { id } = useParams<AdminMessageParams>();
  const { data } = useAdminMessageDetailsQuery(id, { enabled: !isNil(id) });
  const { validationSchema } = useAdminMessagesValidation();

  const { createMode, viewMode } = useViewModesV2(formMode);
  const { convertBreadcrumbToText } = useBreadcrumbsConverter();
  const { mutate: createAdminMessage, isLoading } = useCreateAdminMessageMutation();
  const { goToPage, routes } = useRouter();
  const form = useForm<Partial<PullNotificationSnapshot>>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: INITIAL_VALUES
  });

  const { reset, setValue } = form;

  const handleCancel = () => {
    goBack();
  };

  const handleSubmit = values => {
    const parsedValues = convertedData(values);

    createAdminMessage(parsedValues, {
      onSuccess: () => {
        showSuccessSnackbar(t('messages:dialog.messageCreateSuccess'));
        goToPage(routes.adminMessagesList());
      }
    });
  };

  const title = useMemo(() => {
    if (createMode) {
      return t('messages:newMessageTitle');
    }
    return t('messages:details.title');
  }, [createMode, t]);

  const breadcrumbText = useBreadcrumbTextByFormMode(
    {
      createValue: t('messages:newMessageTitle'),
      viewValue: t('messages:details.breadcrumbTitle', unescapeValue({ name: data?.message || '' }))
    },
    formMode
  );

  const breadcrumbs = useMemo(
    (): Array<IPropsItem> => [
      { to: '/administration/notifications', text: t('messages:administrationTitle') },
      { text: convertBreadcrumbToText(breadcrumbText) }
    ],
    [t, convertBreadcrumbToText, breadcrumbText]
  );

  const values = useMemo(
    () => ({
      formMode,
      setValue,
      ...form
    }),
    [form, formMode, setValue]
  );

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  return (
    <FormV2Context.Provider value={values}>
      <Page
        header={
          <PageHeader
            title={title}
            breadcrumbs={breadcrumbs}
            rightSideContent={
              <ButtonsGroup>
                {viewMode && <BackButton link={routes.adminMessagesList()} />}
                {createMode && (
                  <PageHeaderFormActions
                    onCancel={handleCancel}
                    onSave={form.handleSubmit(handleSubmit)}
                    isLoading={isLoading}
                  />
                )}
              </ButtonsGroup>
            }
          />
        }
        content={<AdminMessageSection />}
      />
    </FormV2Context.Provider>
  );
}

export default AdminMessageDetails;
