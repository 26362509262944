import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, NUMBER_OF_AUTOCOMPLETE_RESULTS } from '@libs/common/v2';

import { MetaForm } from '@libs/meta-form';
import { UiMode } from '@libs/meta-form/models';
import { API } from '@libs/notification';
import { getOrganizationUnitList } from '@libs/organization-structure';

import { passApplicationToOtherDepartment } from '@libs/domain/application';

import { assignForDecision, notifyForeignAuthorities, returnForCorrections } from '../../application/api';

interface IProps {
  isOpen?: boolean;
  messageKey?: string;
  applicationId?: string;
  applicationVersion?: number;
  formConfig?: {
    messageKey: string;
    title: string;
    uiDefinition: {
      fields: any;
      components: any;
      layout: any;
    };
  };
  onFinish?: () => void;
  onCloseDialog?: () => void;
}

function ProcessFormDialog({
  isOpen,
  messageKey,
  applicationId,
  applicationVersion,
  formConfig,
  onFinish,
  onCloseDialog
}: IProps) {
  const [t] = useTranslation();
  const formRef = useRef(null);
  const [isLoading, setLoading] = useState(false);

  return (
    <Dialog
      title={formConfig?.title}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={() => {
        setLoading(true);
        formRef?.current?.onSubmit();
      }}
      onCancel={onCloseDialog}
      dialogSize="medium"
      isConfirmLoading={isLoading}
      isOpen={isOpen}
    >
      <MetaForm
        ref={formRef}
        // eslint-disable-next-line
        fields={formConfig?.uiDefinition?.fields}
        components={[]}
        // eslint-disable-next-line
        layout={formConfig?.uiDefinition?.layout}
        properties={{ mode: UiMode.FORM }}
        onSubmitFinish={() => {
          setLoading(false);
          onCloseDialog();
          onFinish();
        }}
        onSubmitError={() => {
          setLoading(false);
        }}
        apiRegistry={{
          PASS_TO_OTHER_DEPARTMENT: async data => {
            return passApplicationToOtherDepartment({
              applicationId,
              formData: {
                organizationUnitId: data.organizationUnit.id,
                note: data.note,
                version: applicationVersion,
                messageKey
              }
            });
          },
          RETURN_FOR_CORRECTIONS: async data => {
            return returnForCorrections({
              applicationId,
              formData: {
                reason: data.reason,
                version: applicationVersion
              }
            });
          },
          GET_INFORMATION_ABOUT_SENDING_SCANS_TO_KAS: {
            request: async () => {
              return {
                scansHaveBeenSent: false
              };
            }
          },
          SAVE_INFORMATION_ABOUT_SENDING_SCANS_TO_KAS: async data => {
            return notifyForeignAuthorities({
              applicationId,
              foreignAuthoritiesNotificatedRequest: {
                scansSentToKas: data.scansHaveBeenSent,
                version: applicationVersion
              }
            });
          },
          ASSIGN_APPLICATION_TO_EMPLOYEE_FOR_DECISION: data => {
            return assignForDecision({
              applicationId,
              assignForDecisionRequest: {
                assignedTo: data.user.id,
                version: applicationVersion
              }
            });
          },
          GET_ORGANIZATION_UNITS: {
            request: async (_, params) => {
              const response = await getOrganizationUnitList({}, { nameFragment: params?.nameFragment || '' });
              return response;
            }
          },
          GET_USERS: {
            request: async (_, params) => {
              const { data } = await API.user.accountController.searchLiteAccounts({
                page: 0,
                size: NUMBER_OF_AUTOCOMPLETE_RESULTS,
                query: params?.fullName || '',
                sort: [],
                statuses: ['VERIFIED']
              });

              return data;
            }
          }
        }}
      />
    </Dialog>
  );
}

export default ProcessFormDialog;
