import { useTranslation } from 'react-i18next';

import { DropdownListButton, useConfirmDialog, useTableAdapter } from '@libs/common/v2';

const MisspellApplicationSelectColumnButton = ({
  onConfirm
}: {
  onConfirm: (onSuccess: () => void, setLoading: (isLoading: boolean) => void) => void;
}) => {
  const [t] = useTranslation();
  const [confirm] = useConfirmDialog();

  const { toggleAllRowsSelected } = useTableAdapter();

  return (
    <DropdownListButton
      label={t('applications:misspellApplication.action.reprint')}
      onClick={() =>
        confirm({
          title: t('applications:misspellApplication.dialog.reprint'),
          message: t('applications:misspellApplication.dialog.reprintsConfirm'),
          onConfirm: (setConfirmLoading, closeDialog) => {
            setConfirmLoading(true);
            onConfirm(() => {
              toggleAllRowsSelected(false);
              closeDialog();
            }, setConfirmLoading);
          }
        })
      }
    />
  );
};

export default MisspellApplicationSelectColumnButton;
