import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { GridLayout } from '@libs/common/v2';

import { DomainDictionaryEntry, DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import { FolderDetailsParams } from '@libs/domain/folder';
import {
  VehicleColumnNameEnum,
  VehiclesTable,
  VehicleTableActionEnum,
  VehicleTableRowActionEnum
} from '@libs/domain/vehicle';

import FolderHasNoVehiclesInformation from './FolderHasNoVehiclesInformation';

interface IProps {
  folderTypeKey: string;
  folderNumber: string;
}

function VehicleTab({ folderTypeKey, folderNumber }: IProps) {
  const { folderId } = useParams<FolderDetailsParams>();
  const isTypeKS = folderTypeKey === DomainDictionaryEntry.FOLDER_TYPE.KS;
  const isTypeOP = folderTypeKey === DomainDictionaryEntry.FOLDER_TYPE.OP;
  const { ADD_VEHICLE, GENERATE_RAPORT } = VehicleTableActionEnum;
  const { EXPIRE, RESTORE, EDIT, SHOW_DETAIL, DELETE } = VehicleTableRowActionEnum;
  const { isOperatorPortal } = useDomainConfigContext();

  const tableActions = useMemo(
    () => [isOperatorPortal && GENERATE_RAPORT, ...(isTypeKS ? [ADD_VEHICLE] : [])],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isTypeKS]
  );
  const rowActions = useMemo(
    () => [
      SHOW_DETAIL,
      isOperatorPortal && EDIT,
      isOperatorPortal && EXPIRE,
      isOperatorPortal && RESTORE,
      ...(isTypeKS && isOperatorPortal ? [DELETE] : [])
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isTypeKS]
  );

  return (
    <GridLayout itemProps={{ xs: 12 }}>
      <FolderHasNoVehiclesInformation />
      <div>
        <VehiclesTable
          folderId={folderId}
          folderNumber={folderNumber}
          isCreatingVehicleFromFolder={isTypeKS}
          folderTypeKey={folderTypeKey}
          actionKeys={{
            add: DomainUIElementEnum.FOLDER_VEHICLE_CREATE_BUTTON,
            edit: DomainUIElementEnum.FOLDER_VEHICLE_EDIT_BUTTON,
            delete: DomainUIElementEnum.FOLDER_VEHICLE_DELETE_BUTTON,
            details: DomainUIElementEnum.FOLDER_VEHICLE_SHOW_DETAILS_BUTTON,
            expire: DomainUIElementEnum.FOLDER_VEHICLE_EXPIRE_BUTTON,
            restore: DomainUIElementEnum.FOLDER_VEHICLE_RESTORE_BUTTON
          }}
          tableActionKeys={[
            DomainUIElementEnum.FOLDER_VEHICLE_CREATE_BUTTON,
            DomainUIElementEnum.FOLDER_VEHICLE_EDIT_BUTTON,
            DomainUIElementEnum.FOLDER_VEHICLE_DELETE_BUTTON,
            DomainUIElementEnum.FOLDER_VEHICLE_SHOW_DETAILS_BUTTON,
            DomainUIElementEnum.FOLDER_VEHICLE_EXPIRE_BUTTON,
            DomainUIElementEnum.FOLDER_VEHICLE_RESTORE_BUTTON
          ]}
          visibleTableActions={tableActions}
          visibleTableRowActions={rowActions}
          visibleColumns={[
            VehicleColumnNameEnum.VIN,
            VehicleColumnNameEnum.PLATENUMBER,
            VehicleColumnNameEnum.BRANDKEY,
            VehicleColumnNameEnum.MANUFACTURE_YEAR,
            VehicleColumnNameEnum.TYPEKEY,
            VehicleColumnNameEnum.ACCEPTABLE_CAPACITY,
            VehicleColumnNameEnum.DMC,
            VehicleColumnNameEnum.EUROCLASS_KEY,
            VehicleColumnNameEnum.BELOW_THREE_AND_HALF_TONS,
            VehicleColumnNameEnum.VEHICLE_SUBMITTED_TO,
            VehicleColumnNameEnum.CERTIFICATE_S_EXPIRATION_DATE,
            VehicleColumnNameEnum.STATUS_KEY,
            VehicleColumnNameEnum.EXPIRATION_DATE,
            ...(isOperatorPortal ? [VehicleColumnNameEnum.MODIFIED, VehicleColumnNameEnum.MODIFIER] : []),
            ...(isTypeOP
              ? [
                  VehicleColumnNameEnum.REGISTRATION_COUNTRY,
                  VehicleColumnNameEnum.IS_IN_COMPANY,
                  VehicleColumnNameEnum.CREW_SEATS,
                  VehicleColumnNameEnum.MODEL_KEY
                ]
              : [])
          ]}
          tableOptions={{
            initialState: {
              sortBy: [
                {
                  id: 'plateNumber',
                  desc: false
                }
              ],
              filters: [
                {
                  id: 'statusKey',
                  value: [{ value: DomainDictionaryEntry.VEHICLE_STATUS.ACTIVE }]
                }
              ]
            }
          }}
          queryParams={isOperatorPortal ? { folderIdIn: folderId ? [folderId] : [] } : { folderIdEq: folderId }}
          isCollapsable
          isSection
          checkCepikInformationFolderType
        />
      </div>
    </GridLayout>
  );
}

export default VehicleTab;
