import { useTranslation } from 'react-i18next';
import { ResourceObjectSearchFilter } from '@ibtm/domain';

import { SelectOption, TableFilter, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { TAmountRange } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import { IResourceObjectsClient } from '../../model';
import useFilteredDictionary from '../useFilteredDictionary';

const useResourceObjectsColumns = () => {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  const { entries: filteredGroupDictionaryEntries } = useFilteredDictionary(DomainDictionaryEnum.RESOURCE_FORM_GROUP, [
    DomainDictionaryEntry.RESOURCE_FORM_GROUP.S_CERTIFICATE
  ]);

  const { createColumns } = useCreateColumns<IResourceObjectsClient>({
    pathToTranslate: 'resource:fields'
  });

  const mappedSortFields: TableSortMapper<IResourceObjectsClient> = {
    group: 'groupKey',
    name: 'type.nameKey',
    state: 'stateKey',
    depotTypeKey: 'depot.typeKey',
    released: 'releasedTo',
    number: 'number'
  };

  const mappedFilterFields: TableFilterMapper<IResourceObjectsClient, ResourceObjectSearchFilter> = {
    year: (yearRange: TAmountRange) => ({
      yearGreaterThanOrEqual: yearRange.min,
      yearLessThanOrEqual: yearRange.max
    }),
    group: (groups: SelectOption<string>[]) => ({ groupKeyIn: groups.map(group => group.value) }),
    name: (names: SelectOption<string>[]) => ({ typeNameKeyIn: names.map(name => name.value) }),
    number: (number: string) => ({ numberContains: number }),
    state: (states: SelectOption<string>[]) => ({ stateKeyIn: states.map(state => state.value) }),
    depotTypeKey: (resources: SelectOption<string>[]) => ({ depotKeyIn: resources.map(resource => resource.value) }),
    released: (released: string) => ({ releasedToNumberContains: released }),
    notes: (notes: string) => ({ noteContains: notes })
  };

  const columns = createColumns([
    {
      type: 'NUMBER_RANGE',
      accessor: 'year'
    },
    {
      type: 'TEXT',
      accessor: 'number'
    },
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_GROUP,
      accessor: 'group',
      filter: props => (
        <TableFilter.DictionaryMultiSelect
          {...props}
          dictionary={DomainDictionaryEnum.RESOURCE_FORM_GROUP}
          options={filteredGroupDictionaryEntries}
        />
      ),
      width: 300
    },
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_NAME,
      accessor: 'name',
      width: 300
    },
    {
      header: 'status',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_STATE,
      accessor: 'state',
      width: 200
    },
    {
      id: 'depotTypeKey',
      header: 'resource',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.DEPOT_TYPE,
      customAccessor: row =>
        `${translate(DomainDictionaryEnum.DEPOT_TYPE, row?.depot?.typeKey, t('emptyMark'))} ${
          row?.depot?.name ? row?.depot?.name : ''
        }`,
      width: 200
    },
    {
      id: 'released',
      type: 'TEXT',
      accessor: 'releasedTo.number'
    },
    {
      type: 'TEXT',
      accessor: 'notes'
    }
  ]);

  return { columns, mappedFilterFields, mappedSortFields };
};

export default useResourceObjectsColumns;
