import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { AttachmentVerificationElementDetails } from '@ibtm/domain';

import { FormActionEnum, IconButton, InputMode, MatrixCellInputTypeEnum, useFormActionModal } from '@libs/common/v2';

import { useDictionaryEntryValues } from '@libs/dictionary';
import { useFormDirtyContext, useMetaFormContext } from '@libs/meta-form/context';
import { useElementVisibility } from '@libs/permission';

import { DomainDictionaryEntry, DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';

import { useAddCommentDialog } from '../hooks';

interface IHeaders {
  isEditNoteButton: boolean;
  isOnlyBooleanColumn: boolean;
}

interface IRowInputs {
  attachment: IAttachment;
  isEditNoteButton: boolean;
  isOnlyBooleanColumn: boolean;
  isEditButtonDisabled: boolean;
}

export interface IAttachment {
  id: string;
  title: string;
  notes: string;
  statusKey: string;
}

const useAttachmentsMatrixRowInputs = (
  applicationId: string,
  fieldId: string,
  verificationElements: AttachmentVerificationElementDetails[],
  isWL: boolean,
  inputMode?: InputMode
) => {
  const [t] = useTranslation();
  const { checkIsElementVisible } = useElementVisibility();
  const { isDirty } = useFormDirtyContext();
  const { showErrorSnackbar } = useSnackbar();
  const saveAttachmentsAction = useFormActionModal();
  const { open: openAddCommentDialog } = useAddCommentDialog(verificationElements);
  const { formRef } = useMetaFormContext();
  const dictionaryFileStatus = useDictionaryEntryValues(DomainDictionaryEnum.REQUIRED_FILE_STATUS);

  const isEditNoteAvailable = checkIsElementVisible(DomainUIElementEnum.APPLICATION_ATTACHMENTS_EDIT_NOTE_BUTTON);

  const headersWithCheckedStatuses = useMemo(() => {
    return [
      ...(dictionaryFileStatus || [])
        .filter(entry => DomainDictionaryEntry.REQUIRED_FILE_STATUS.CALLED !== entry.value || !isWL)
        .map(entry => ({
          name: { title: entry.value },
          width: 150
        })),
      { name: { title: t('applications:attachments.fields.notes') }, width: 300 }
    ];
  }, [dictionaryFileStatus, t, isWL]);

  const headersWithSwitchStatus = [
    { name: { title: t('applications:attachments.fields.notes') }, width: 400 },
    {
      name: { title: t('applications:attachments.fields.attached') },
      width: 150
    }
  ];

  const headerForEditNoteButtons = { name: { title: '' }, width: 80 };

  const handleSaveAttachmentsFormChanges = async (attachment: IAttachment) => {
    try {
      await formRef?.current?.onSubmit();
      openAddCommentDialog(applicationId, attachment);
    } catch {
      showErrorSnackbar(t('error.discardChangesOrCorrectFormDataToAddNote'));
    }
  };

  const handleDiscardAttachmentsFormChanges = async (attachment: IAttachment) => {
    await formRef?.current?.onReset();
    openAddCommentDialog(applicationId, attachment);
  };

  const handleNoteButtonClick = (attachment: IAttachment) => {
    if (isDirty) {
      saveAttachmentsAction({
        formAction: FormActionEnum.SAVE_CHANGES,
        onConfirm: () => handleSaveAttachmentsFormChanges(attachment),
        onCancel: () => handleDiscardAttachmentsFormChanges(attachment)
      });
    } else {
      openAddCommentDialog(applicationId, attachment);
    }
  };

  const fileStatusInputs = (id: string) => {
    return dictionaryFileStatus
      .filter(entry => DomainDictionaryEntry.REQUIRED_FILE_STATUS.CALLED !== entry.value || !isWL)
      .map(entry => ({
        type: MatrixCellInputTypeEnum.CHECKBOX,
        value: entry.value,
        fieldName: `${fieldId}.${id}.statusKey`,
        style: 'justify-center',
        inputMode
      }));
  };

  const fileStatusSwitchInput = (attachment: IAttachment) => {
    return {
      type: MatrixCellInputTypeEnum.SWITCH,
      fieldName: `${fieldId}.${attachment.id}.statusKey`,
      style: 'justify-center',
      inputMode: !checkIsElementVisible(DomainUIElementEnum.APPLICATION_ATTACHMENTS_EDIT) && InputMode.VIEW
    };
  };

  const noteInput = (attachment: IAttachment, isEditNoteButton: boolean) => {
    return {
      type: MatrixCellInputTypeEnum.TEXT,
      fieldName: `${fieldId}.${attachment.id}.notes`,
      inputMode:
        (isEditNoteButton || !checkIsElementVisible(DomainUIElementEnum.APPLICATION_ATTACHMENTS_EDIT)) && InputMode.VIEW
    };
  };
  const noteEditButton = (attachment: IAttachment, isEditButtonDisabled: boolean) => {
    return {
      type: MatrixCellInputTypeEnum.CUSTOM,
      fieldName: `${fieldId}.${attachment.id}.notesButton`,
      input: (
        <IconButton
          tooltipTitle={
            isEditButtonDisabled
              ? t('applications:attachments.actions.addCommentDisabled')
              : t('applications:attachments.actions.addComment')
          }
          onClick={() => handleNoteButtonClick(attachment)}
          icon="NoteIcon"
          isDisabled={isEditButtonDisabled}
        />
      ),
      style: 'justify-center'
    };
  };

  const createHeaders = ({ isEditNoteButton, isOnlyBooleanColumn }: IHeaders) => {
    const statusHeaders = isOnlyBooleanColumn ? headersWithSwitchStatus : headersWithCheckedStatuses;

    return isEditNoteButton && isEditNoteAvailable && (!inputMode || inputMode !== InputMode.VIEW)
      ? statusHeaders.concat(headerForEditNoteButtons)
      : statusHeaders;
  };

  const createRowInputs = ({ attachment, isEditNoteButton, isOnlyBooleanColumn, isEditButtonDisabled }: IRowInputs) => {
    const inputArray = isOnlyBooleanColumn
      ? [noteInput(attachment, isEditNoteButton), fileStatusSwitchInput(attachment)]
      : [...fileStatusInputs(attachment.id), noteInput(attachment, isEditNoteButton)];

    return isEditNoteButton &&
      isEditNoteAvailable &&
      checkIsElementVisible(DomainUIElementEnum.APPLICATION_ATTACHMENTS_EDIT_NOTE_BUTTON)
      ? [...inputArray, noteEditButton(attachment, isEditButtonDisabled)]
      : inputArray;
  };

  return { createRowInputs, createHeaders };
};

export default useAttachmentsMatrixRowInputs;
