import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubjectAddressDetails } from '@ibtm/domain';
import { makeStyles } from '@mui/styles';

import { FormMode, FormV2Context, Page, useRouter, useViewModesV2 } from '@libs/common/v2';
import { calc } from '@libs/common/v2/utils';

import { DomainDictionaryEntry } from '@libs/domain/config';
import { useCreateFolderMutation, useFolderDetailsQuery } from '@libs/domain/folder';

import useEditSubjectMutation from '../../../application/api/mutations/useEditSubjectMutation';
import { AssociationDetailsParams, AssociationModel, AssociationValidationSchema } from '../../model';
import { parseCreateAssociationRequest, parseUpdateAssociationRequest } from '../../utils';

import { AssociationFormContent, AssociationPageHeader } from '.';

interface IProps {
  formMode: FormMode;
}

function AssociationDetails({ formMode }: IProps) {
  const { id } = useParams<AssociationDetailsParams>();
  const { goToPage, routes, goBack } = useRouter();
  const { data: folderData, refetch, isLoading } = useFolderDetailsQuery(id, { enabled: Boolean(id) });
  const [t] = useTranslation();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const classes = useStyle();
  const { createMode } = useViewModesV2(formMode);

  const [createAssociation, { isLoading: isCreateAssociationFolderLoading }] = useCreateFolderMutation({
    onSuccess: ({ id }) => {
      goToPage(routes.associationDetails(id));
      showSuccessSnackbar(t('associations:messages.asociationCreatedSuccess'));
    },
    onError: () => showErrorSnackbar(t('associations:messages.asociationCreatedFailure'))
  });

  const [updateAssociation, mutationState] = useEditSubjectMutation({
    onSuccess: () => {
      goToPage(routes.associationDetails(id));
      refetch();
      showSuccessSnackbar(t('associations:messages.asociationUpdateSuccess'));
    },
    onError: () => showErrorSnackbar(t('associations:messages.asociationUpdateFailure'))
  });

  const form = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(AssociationValidationSchema({ isNumberValidated: !createMode }))
  });

  const {
    handleSubmit,
    reset,
    clearErrors,
    formState: { isSubmitting }
  } = form;

  const resetAssociationForm = useCallback(() => {
    if (folderData) {
      const address = folderData.addresses.find(item => item.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.MAIN);
      const formData = {
        number: folderData.number,
        subject: {
          name: folderData.subject.name,
          shortName: folderData.subject.shortName,
          nip: folderData.subject.nip,
          regon: folderData.subject.regon,
          krs: folderData.subject.krs,
          version: folderData.subject.version,
          notes: folderData.subject.notes
        },
        addresses: [{ ...address }]
      };
      reset(formData);
    }
  }, [folderData, reset]);

  useEffect(() => {
    resetAssociationForm();
  }, [resetAssociationForm]);

  const onSubmit = data => {
    if (createMode) {
      createAssociation(parseCreateAssociationRequest(data));
    } else {
      const restOfAddresses = folderData?.addresses
        .filter(address => address.typeKey !== DomainDictionaryEntry.ADDRESS_TYPE.MAIN)
        .map(address => {
          if (address.sameAsMainAddress) {
            return { sameAsMainAddress: address.sameAsMainAddress, typeKey: address.typeKey };
          }
          return address;
        });

      const formData: AssociationModel = {
        ...data,
        addresses: [...data.addresses, ...restOfAddresses] as SubjectAddressDetails[]
      };

      updateAssociation({ subjectId: folderData.subject.id, formData: parseUpdateAssociationRequest(formData) });
    }
  };

  const handleCancel = useCallback(() => {
    clearErrors();
    resetAssociationForm();
    goBack();
  }, [clearErrors, goBack, resetAssociationForm]);

  const values = useMemo(
    () => ({ formMode, ...form, isSubmitting, loading: isLoading }),
    [form, formMode, isLoading, isSubmitting]
  );

  return (
    <FormV2Context.Provider value={values}>
      <form className="h-full" onSubmit={handleSubmit(onSubmit)}>
        <Page
          header={
            <AssociationPageHeader
              folderData={folderData}
              onCancel={handleCancel}
              onSave={handleSubmit(onSubmit)}
              isSubmitLoading={isCreateAssociationFolderLoading || mutationState?.isLoading}
            />
          }
          content={
            <AssociationFormContent
              isAddressCountryFieldAvaliable={folderData?.typeKey === DomainDictionaryEntry.FOLDER_TYPE.MZ}
            />
          }
          isTable
          contentClassName={classes.content}
        />
      </form>
    </FormV2Context.Provider>
  );
}

const useStyle = makeStyles(() => ({
  content: {
    height: calc('100% - 150px'),
    display: 'flex',
    flexDirection: 'column'
  }
}));

export default AssociationDetails;
