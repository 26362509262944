import { useEffect, useState } from 'react';
import { QueryConfig, useQuery } from 'react-query';
import { Column, OnFilterChange } from 'react-table';

import { SelectOption } from '@libs/common/v2';

import SelectFilter from './SelectFilter';

interface IProps {
  /**
   * @fetchFunctionResolver - funkcja asynchroniczna która zwraca dane
   */
  fetchFunctionResolver: (params: any) => Promise<any>;
  /**
   * Funkcja która przyjmuje dane i zwraca sparsowany array opcji do AutocompleteSelect
   */
  fetchedDataSelectParser: (data: any) => SelectOption[];
  queryKey: string;
  queryConfig?: QueryConfig<unknown, unknown>;
  column: Column & { filterValue?: string; setFilter: (value: string) => void };
  isMultiple?: boolean;
  getOptionSelected?: (option: Record<string, any>, value: Record<string, any>) => boolean;
  shouldCheckVisibility?: boolean;
  onFilterChange: OnFilterChange;
}
function FetchSelectFilter({
  fetchFunctionResolver,
  column,
  queryKey,
  fetchedDataSelectParser,
  queryConfig = {},
  getOptionSelected,
  shouldCheckVisibility,
  onFilterChange,
  ...forwardProps
}: IProps) {
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [inputFocused, setInputFocused] = useState(false);
  const { data, isLoading } = useQuery([queryKey, inputValue], () => fetchFunctionResolver(inputValue), {
    enabled: inputFocused,
    ...queryConfig
  });
  useEffect(() => {
    if (data) {
      setOptions(fetchedDataSelectParser?.(data));
    }
  }, [data, fetchedDataSelectParser]);

  return (
    <SelectFilter
      options={options}
      isLoading={isLoading}
      onInputChange={setInputValue}
      column={column}
      onFocus={() => setInputFocused(true)}
      onBlur={() => setInputFocused(false)}
      getOptionSelected={getOptionSelected}
      shouldCheckVisibility={shouldCheckVisibility}
      onFilterChange={onFilterChange}
      isFetchedDynamically
      {...forwardProps}
    />
  );
}

export default FetchSelectFilter;
