import { SingleUsePermissionSubmissionSearchFilter, SingleUsePermissionSubmissionSnapshot } from '@ibtm/domain';

import {
  ColumnTypesEnum,
  SelectOptionPartial,
  TableFilter,
  TableFilterMapper,
  TableSortMapper,
  useCreateColumns
} from '@libs/common/v2';

export const useCommissionApplicationColumns = () => {
  const { createColumns } = useCreateColumns<SingleUsePermissionSubmissionSnapshot>({
    pathToTranslate: 'foreignPermits:tabs.socialCommission.tabs.socialCommissionDisposablePermits.fields'
  });

  const mappedFilterFields: TableFilterMapper<
    SingleUsePermissionSubmissionSnapshot,
    SingleUsePermissionSubmissionSearchFilter
  > = {
    number: (number: number) => ({ number }),
    folderNumber: (folder: string) => ({ folderNumberContains: folder }),
    subjectName: (name: string) => ({ subjectNameContains: name }),
    month: (month: number) => ({ month }),
    considered: (considered: SelectOptionPartial<boolean>) => ({ considered: considered?.value })
  };

  const mappedSortFields: TableSortMapper<SingleUsePermissionSubmissionSnapshot> = {
    number: 'number',
    folderNumber: 'folder.number',
    subjectName: 'folder.subject.name',
    month: 'month'
  };

  const columns = createColumns([
    {
      accessor: 'number',
      header: 'submission.number',
      type: ColumnTypesEnum.NUMBER,
      width: 200
    },
    {
      accessor: 'month',
      header: 'submission.month',
      type: ColumnTypesEnum.NUMBER,
      customAccessor: ({ month }) => month || 0,
      filter: props => {
        return <TableFilter.Number {...props} min={0} />;
      },
      width: 200
    },
    {
      accessor: 'folder.number',
      header: 'submission.folder.number',
      type: ColumnTypesEnum.TEXT,
      width: 200
    },
    {
      accessor: 'subject.name',
      header: 'submission.subject.name',
      type: ColumnTypesEnum.TEXT,
      width: 240
    },
    {
      accessor: 'considered',
      header: 'considered',
      type: ColumnTypesEnum.BOOLEAN,
      width: 200
    }
  ]);

  return { columns, mappedFilterFields, mappedSortFields };
};
