import { QueryConfig, usePaginatedQuery } from 'react-query';
import { DefaultApiFindOrganizationUnitRequest } from '@avispon/organization-structure/dist/api/default-api';
import { PageOrganizationUnitSnapshotExternal } from '@avispon/organization-structure/dist/models';

import { API, endpointsConfig, OrganizationQueryKeysEnum } from '..';

export const getOrganizationUnitList = async (_, params: DefaultApiFindOrganizationUnitRequest) => {
  const { data } = await API.organizationStructure.findOrganizationUnit(params, {
    ...endpointsConfig.findOrganizationUnit
  });

  return data;
};

export default function useOrganizationListQuery(
  params: DefaultApiFindOrganizationUnitRequest,
  queryConfig: QueryConfig<unknown> = { keepPreviousData: true }
) {
  return usePaginatedQuery<PageOrganizationUnitSnapshotExternal>(
    [OrganizationQueryKeysEnum.ORGANIZATION_UNIT_LIST, params],
    getOrganizationUnitList,
    queryConfig
  );
}
