import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, useTableContext } from '@libs/common/v2';

import { useDeleteReplacementsMutation } from '@libs/user/api';

interface IProps {
  replacementId: string;
  isDeleteOpen: boolean;
  setIsDeleteOpen: (value: boolean) => void;
}

function DeleteReplacementsDialog({ replacementId, isDeleteOpen, setIsDeleteOpen }: IProps) {
  const [t] = useTranslation();
  const { refetch } = useTableContext();
  const { mutate: deleteReplacement, isLoading } = useDeleteReplacementsMutation({ setIsDeleteOpen, refetch });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDeleteReplacement = useCallback(() => deleteReplacement({ id: replacementId }), []);

  return (
    <Dialog
      title={t('user:replacements.action.deleteReplacement')}
      confirmText={t('action.delete')}
      cancelText={t('action.cancel')}
      contentText={t('user:message.deleteReplacement')}
      onConfirm={handleDeleteReplacement}
      onCancel={() => setIsDeleteOpen(false)}
      confirmButtonType="danger"
      dialogSize="small"
      isConfirmLoading={isLoading}
      isOpen={isDeleteOpen}
    />
  );
}

export default DeleteReplacementsDialog;
