import { useContext } from 'react';

import { UserCustomConfigurationContext } from '@libs/user/context';

function useUserCustomConfiguration() {
  const { isUsingServiceNumber, isUsingEmailAsLogin } = useContext(UserCustomConfigurationContext);

  return {
    isUsingServiceNumber,
    isUsingEmailAsLogin
  };
}

export default useUserCustomConfiguration;
