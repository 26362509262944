import * as React from 'react';
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';

interface Props {
  lastSyncedPage: number;
  requestPage: (page: number) => void;
  nextPage(): number | undefined;
  className?: string;
  children?: React.ReactNode;
  offsetHeight?: number;
}

function InfinityScroll(props: Props) {
  const { lastSyncedPage, requestPage, nextPage, className, children, offsetHeight = 700 } = props;
  const [lastPage, setLastPage] = useState<number>(lastSyncedPage);

  useEffect(() => {
    if (lastSyncedPage === 0) {
      setLastPage(lastSyncedPage);
    }
  }, [lastSyncedPage]);

  const handleScrollDown = scrollContainer => {
    const next: number | undefined = nextPage();

    if (
      scrollContainer &&
      scrollContainer.scrollTop + scrollContainer.clientHeight >=
        Math.max(scrollContainer.scrollHeight - offsetHeight, 0)
    ) {
      if (lastSyncedPage === lastPage || next) {
        requestNewData(next);
      }
    }
  };

  const requestNewData = (next: number): void => {
    setLastPage(next);
    requestPage(next);
  };

  const perfectScrollbarStyles = `
    .perfect-scroll-bar .scrollbar-container {
      position: absolute!important;
  }`;

  return (
    <div className="perfect-scroll-bar">
      <style>{perfectScrollbarStyles}</style>
      <PerfectScrollbar
        className={clsx('overflow-scroll', 'w-full', className)}
        onScrollDown={container => handleScrollDown(container)}
      >
        {children}
      </PerfectScrollbar>
    </div>
  );
}

export default InfinityScroll;
