import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';

import { Dialog, FormV2Context, GridLayout, Section, useIsSmallScreen } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { ValueOf } from '@libs/common/v2/utils';

import { ApplicationQueryKeysEnum } from '../../application/api';
import DomainDictionaryEntry from '../../config/DomainDictionaryEntry';
import { ProxyQueryKeysEnum, useCreateProxyMutation } from '../api';
import { useProxyFields } from '../hooks';
import { getValidationSchema, ProxyFields } from '../model';

interface IProps {
  folderId: string;
  applicationId: string;
  onCloseDialog: () => void;
  isOpen: boolean;
  applicationType?: ValueOf<typeof DomainDictionaryEntry.APPLICATION_CATEGORY>;
  isPostCityRequired?: boolean;
  isCitizenshipFieldVisible?: boolean;
  hiddenFieldIds?: (keyof ProxyFields)[];
}

function ProxyCreateDialog({
  folderId,
  applicationId,
  onCloseDialog,
  isOpen,
  applicationType,
  isPostCityRequired,
  hiddenFieldIds,
  isCitizenshipFieldVisible
}: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { mutate: createProxy, isLoading } = useCreateProxyMutation();
  const { isSmallScreen } = useIsSmallScreen();

  const validationSchema = getValidationSchema(isPostCityRequired, isCitizenshipFieldVisible);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    reset
  } = useForm<ProxyFields>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema)
  });

  const formValues = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting
    }),
    [control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting]
  );

  const { renderField } = useProxyFields({
    reset,
    folderId,
    applicationType,
    isPostCityRequired,
    isCitizenshipFieldRequired: isCitizenshipFieldVisible,
    hiddenFieldIds
  });

  const handleCreateSubmit = (values: ProxyFields) => {
    createProxy(
      {
        typeKey: values?.typeKey?.value,
        paid: values?.paid,
        name: values?.name,
        surname: values?.surname,
        citizenship: values?.citizenship?.value,
        address: {
          city: values?.city,
          postCode: values?.postCode,
          postCity: values?.postCity,
          propertyNumber: values?.propertyNumber,
          street: values?.street,
          apartmentNumber: values?.apartmentNumber
        },
        remarks: values?.notes,
        validUntil: values?.validUntil,
        applicationId,
        folderId,
        authorizations: [DomainDictionaryEntry.SCOPES_OF_FOREIGN_PERMITS_AUTHORIZATIONS.SIGNING_DECLARATIONS]
      },
      {
        onSuccess: () => {
          queryCache.invalidateQueries(ProxyQueryKeysEnum.PROXIES_LIST);
          if (applicationId) {
            queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
          }
          showSuccessSnackbar(t('proxy:action.create.successMessage'));
          onCloseDialog();
        },
        onError: () => {
          showErrorSnackbar(t('proxy:action.create.failureMessage'));
        }
      }
    );
  };

  return (
    <FormV2Context.Provider value={formValues}>
      <form onSubmit={handleSubmit(handleCreateSubmit)}>
        <Dialog
          title={t('proxy:action.create.dialogTitle')}
          confirmText={t('action.save')}
          cancelText={t('action.cancel')}
          onConfirm={handleSubmit(handleCreateSubmit, () => showErrorSnackbar(t('error.formValidationErrors')))}
          onCancel={onCloseDialog}
          dialogSize="medium"
          isConfirmLoading={isLoading}
          isOpen={isOpen}
          isFullScreen={isSmallScreen}
        >
          <Section isCollapsable isModalSection isHeaderVisible={false}>
            <GridLayout itemProps={{ xs: 6 }}>
              {renderField('name')}
              {renderField('surname')}
              {renderField('typeKey')}
              {renderField('paid')}
              {renderField('validUntil')}
              {isCitizenshipFieldVisible && renderField('citizenship')}
              {renderField('notes')}
            </GridLayout>
          </Section>
          <Section title={t('proxy:section.address')} isModalSection>
            <GridLayout itemProps={{ xs: 6 }}>
              {renderField('city')}
              {renderField('street')}
              {renderField('propertyNumber')}
              {renderField('apartmentNumber')}
              {renderField('postCode')}
              {renderField('postCity')}
            </GridLayout>
          </Section>
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
}

export default ProxyCreateDialog;
