import { useTranslation } from 'react-i18next';

import { Button, ButtonsGroup, useFormV2Context, useIsSmallScreen } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

interface Props {
  closeDialog: () => void;
  onSubmit: () => void;
  submitAndAddNext: (data) => void;
}

function CustomActions({ closeDialog, onSubmit, submitAndAddNext }: Props) {
  const [t] = useTranslation();

  const { isSmallScreen } = useIsSmallScreen();
  const { formMode, isSubmitting } = useFormV2Context();
  const { viewMode, createMode, editMode } = useViewModesV2(formMode);

  return (
    <ButtonsGroup>
      <Button
        isPrimary
        variant="outlined"
        onClick={closeDialog}
        label={viewMode ? t('action.close') : t('action.cancel')}
      />
      {!viewMode && (
        <>
          {!isSmallScreen && !editMode && (
            <Button
              variant="outlined"
              onClick={submitAndAddNext}
              isLoading={isSubmitting}
              label={t('action.addNextMasculine')}
            />
          )}
          <Button
            isPrimary
            variant="contained"
            onClick={onSubmit}
            isLoading={isSubmitting}
            form="createVehicleForm"
            label={createMode ? t('action.add') : t('action.save')}
            type="submit"
          />
        </>
      )}
    </ButtonsGroup>
  );
}

export default CustomActions;
