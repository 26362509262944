import { AxiosRequestConfig } from 'axios';

import { useMutation } from '@libs/common/v2/api';

import { endpointsConfig } from '../endpoints-config';
import { API } from '..';

interface IParams {
  processInstanceId: string;
  messageKey: string;
}

function sendProcessMessage(params: IParams) {
  return API.process.sendMessageToProcess(
    {
      processInstanceId: params.processInstanceId,
      sendMessageToProcessRequest: {
        messageKey: params.messageKey
      }
    },
    { ...endpointsConfig.sendMessageToProcess } as AxiosRequestConfig
  );
}

function useSendMessageMutation() {
  return useMutation(sendProcessMessage);
}

export default useSendMessageMutation;
