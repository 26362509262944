import React from 'react';
import { Icon as MDIcon } from '@mdi/react';

const MDIcons = require('@mdi/js');

export interface IconProps {
  name: string;
  color: string;
  size: number;
}

function Icon({ name, size, ...forwardProps }: IconProps) {
  const iconPath = MDIcons[name];
  return iconPath && <MDIcon path={iconPath} {...forwardProps} className={`mdi ${name}`} />;
}

export default Icon;
