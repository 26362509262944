import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';
import { Typography } from '@mui/material';

import { Dialog, DropdownListButton, TableButton } from '@libs/common/v2';

import { useResetPasswordMutation } from '@libs/user';

interface IProps {
  email: string;
  handleClose: () => void;
  isTableButton?: boolean;
  actionKey?: UIElementNameEnum;
}

function ResetPasswordPopUp({ email, handleClose, isTableButton, actionKey }: IProps) {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: handleResetPassword, isLoading } = useResetPasswordMutation();

  const handleConfirm = () => {
    handleResetPassword(email, {
      onSuccess: () => {
        setIsOpen(false);
        handleClose();
        showSuccessSnackbar(t('user:message.resetPasswordSuccess'));
      }
    });
  };

  const handleCancel = () => {
    setIsOpen(false);
    handleClose();
  };

  return (
    <>
      {isOpen && (
        <Dialog
          title={t('user:dialog.userResetPasswordTitle')}
          confirmText={t('action.confirm')}
          cancelText={t('action.cancel')}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          dialogSize="small"
          isConfirmLoading={isLoading}
          isConfirmButtonDisabled={isLoading}
          isOpen={isOpen}
        >
          <Typography>{t('user:dialog.userResetPasswordRequest')}</Typography>
        </Dialog>
      )}
      {isTableButton ? (
        <TableButton label={t('user:action.resetPassword')} onClick={() => setIsOpen(true)} actionKey={actionKey} />
      ) : (
        <DropdownListButton
          label={t('user:action.resetPassword')}
          onClick={() => setIsOpen(true)}
          actionKey={actionKey}
        />
      )}
    </>
  );
}

export default ResetPasswordPopUp;
