import { useAppConfig } from '@libs/app-config';

import { useDevMode, useTableLayoutProps } from '@libs/common/v2';

import { useReportsQuery } from '../api';

import useReportsTableColumns from './useReportsTableColumns';

function useReportsTable() {
  const { isDevMode } = useDevMode();
  const { isReportTypeManagementEnable } = useAppConfig(isDevMode);
  const { columns, mappedFilterFields, mappedSortFields } = useReportsTableColumns(
    isReportTypeManagementEnable
      ? ['sid', 'statusKey', 'reportTypeName', 'generationEnded', 'created']
      : ['sid', 'reportTypeName', 'generationEnded']
  );

  return useTableLayoutProps(
    {
      tableHookQuery: useReportsQuery,
      tableHookOptions: {
        columns,
        filterConverter: mappedFilterFields,
        sortByConverter: mappedSortFields
      }
    },
    [isReportTypeManagementEnable]
  );
}

export default useReportsTable;
