export enum ColumnType {
  GUID = 'GUID',
  CHARACTER = 'CHARACTER',
  LONG = 'LONG',
  DOUBLE = 'DOUBLE',
  DATE = 'DATE',
  TIME = 'TIME',
  TIMESTAMP = 'TIMESTAMP',
  TIMESTAMP_TZ = 'TIMESTAMP_TZ',
  BOOLEAN = 'BOOLEAN',
  HINTED = 'HINTED'
}
