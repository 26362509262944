import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface Params {
  vehicleId: string;
}

function deleteVehicle({ vehicleId }: Params) {
  return API.vehicle.deleteVehicle(vehicleId, {
    ...endpointsConfig.deleteVehicle
  });
}

function deleteVehicleClient({ vehicleId }: Params) {
  return API.client.vehicle.deleteVehicle('', '', vehicleId, {
    ...endpointsConfig.deleteVehicle
  });
}

function useDeleteVehicleMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(deleteVehicleClient, deleteVehicle));
}

export default useDeleteVehicleMutation;
