import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { OrganizationUnitMemberSnapshotExternal } from '@avispon/organization-structure/dist/models';
import { useSnackbar } from '@enigma/fe-ui';

import { partialTranslate } from '@libs/common';
import { TableButtonDelete, useConfirmDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { OrganizationQueryKeysEnum, useUnassignOrganizationUnitMemberMutation } from '@libs/organization-structure/api';
import { OrganizationStructureUIElementEnum } from '@libs/organization-structure/config';

import { OrganizationStructureDetailsParams } from '../../models';

function OpenDeleteMemberPopUp({ original }: { original: OrganizationUnitMemberSnapshotExternal }) {
  const queryCache = useQueryCache();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const [confirm] = useConfirmDialog();
  const { id } = useParams<OrganizationStructureDetailsParams>();
  const [t] = useTranslation();
  const getFieldLabel = partialTranslate('administration:organizationUnit.message');

  const { mutateAsync: unassignOrganizationUnitMember } = useUnassignOrganizationUnitMemberMutation();

  const handleDelete = (members: OrganizationUnitMemberSnapshotExternal[]) => {
    confirm({
      title: t('administration:organizationUnit.message.deleteMemberTitle'),
      shortTitle: t('global:dialog.deleteConfirmTitle'),
      message: members.length > 1 ? getFieldLabel('deleteMembersConfirm') : getFieldLabel('deleteMemberConfirm'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        const ids = members.map((member: OrganizationUnitMemberSnapshotExternal) => member.user.id);
        const requestParameters = {
          organizationUnitId: id,
          userIds: ids
        };
        unassignOrganizationUnitMember(requestParameters, {
          onSuccess: () => {
            showSuccessSnackbar(t('dialog.deleteSuccess'));
            closeDialog();
            queryCache.invalidateQueries(OrganizationQueryKeysEnum.ORGANIZATION_UNIT_MEMBER_LIST);
          },
          onError: () => {
            showErrorSnackbar(t('dialog.deleteFailure'));
            closeDialog();
          }
        });
      },
      confirmType: 'danger'
    });
  };

  return (
    <TableButtonDelete
      onClick={() => handleDelete([original])}
      actionKey={OrganizationStructureUIElementEnum.ORGANIZATION_STRUCTURE_DELETE_MEMBER_BUTTON}
    />
  );
}

export default OpenDeleteMemberPopUp;
