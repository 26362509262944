import { PasswordChangeByOperatorCommand } from '@avispon/user/dist/models';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '@libs/user/api';

const changePassword = async (body: PasswordChangeByOperatorCommand) => {
  const { data } = await API.user.default.changePasswordByOperator({ body }, { ...endpointsConfig.changePassword });

  return data;
};

function useChangePasswordMutation() {
  return useMutation(changePassword);
}

export default useChangePasswordMutation;
