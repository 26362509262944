import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSnackbar } from '@enigma/fe-ui';
import { ProceedingDetails } from '@ibtm/domain';

import { KeyType } from '@libs/common';
import {
  DropdownListButton,
  typedNameV2,
  useConfirmDialog,
  useDialog,
  useFormV2Context,
  useTableAdapter
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { DomainDictionaryEntry, DomainUIElementEnum } from '@libs/domain/config';
import { FolderQueryKeysEnum } from '@libs/domain/folder';
import {
  ProceedingDetailsParams,
  ProceedingQueryKeysEnum,
  useActivateFormOperationsMutation,
  useExpireFormOperationsMutation,
  useRevokeFormOperationsMutation
} from '@libs/domain/proceeding';
import { SubjectQueryKeysEnum } from '@libs/domain/subject';

import { SuspendFormOperationsDialog } from '../dialog';

interface Props {
  selected: string[];
  handleClose: () => void;
}

function FormOperationTableSelectAction({ selected, handleClose }: Props) {
  const queryCache = useQueryCache();
  const { id } = useParams<ProceedingDetailsParams>();
  const [t] = useTranslation();
  const [confirm] = useConfirmDialog();
  const { showSuccessSnackbar } = useSnackbar();
  const { watch } = useFormV2Context();
  const { openDialog } = useDialog();

  const version = watch(typedNameV2<ProceedingDetails>('version')) as number;

  const { mutate: expireFormOperations } = useExpireFormOperationsMutation();
  const { mutate: revokeFormOperations } = useRevokeFormOperationsMutation();
  const { mutate: activateFormOperations } = useActivateFormOperationsMutation();

  const { selectedFlatRows } = useTableAdapter();

  const refreshView = useCallback(() => {
    queryCache.invalidateQueries(ProceedingQueryKeysEnum.FORM_OPERATION_LIST);
    queryCache.invalidateQueries(ProceedingQueryKeysEnum.PROCEEDING_DETAILS);

    // Slider
    queryCache.invalidateQueries(FolderQueryKeysEnum.FOLDER_EXTENDED);
    queryCache.invalidateQueries(SubjectQueryKeysEnum.SUBJECT_EXTENDED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSuccessMessage = useCallback(
    (singularTranslation: KeyType, pluralTranslation: KeyType) => {
      return selected.length > 1 ? t(pluralTranslation) : t(singularTranslation);
    },
    [t, selected?.length]
  );

  const handleExpire = useCallback(() => {
    confirm({
      title: t('proceeding:action.expireFormOperation.title'),
      message: t('proceeding:action.expireFormOperation.confirmMessage'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        expireFormOperations(
          {
            proceedingId: id,
            proceedingFormIds: selected,
            version
          },
          {
            onSuccess: () => {
              showSuccessSnackbar(
                getSuccessMessage(
                  'proceeding:action.expireFormOperation.successMessageSingular',
                  'proceeding:action.expireFormOperation.successMessagePlural'
                )
              );
              refreshView();
              closeDialog();
            },
            onSettled: () => {
              setConfirmLoading(false);
            }
          }
        );
      }
    });
  }, [refreshView, confirm, expireFormOperations, id, selected, showSuccessSnackbar, t, version, getSuccessMessage]);

  const handleUndo = useCallback(() => {
    confirm({
      title: t('proceeding:action.undoFormOperation.title'),
      message: t('proceeding:action.undoFormOperation.confirmMessage'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        revokeFormOperations(
          {
            proceedingId: id,
            proceedingFormIds: selected,
            version
          },
          {
            onSuccess: () => {
              showSuccessSnackbar(
                getSuccessMessage(
                  'proceeding:action.undoFormOperation.successMessageSingular',
                  'proceeding:action.undoFormOperation.successMessagePlural'
                )
              );
              refreshView();
              closeDialog();
            },
            onSettled: () => {
              setConfirmLoading(false);
            }
          }
        );
      }
    });
  }, [refreshView, revokeFormOperations, confirm, id, selected, showSuccessSnackbar, t, version, getSuccessMessage]);

  const handleChangeToActive = useCallback(() => {
    confirm({
      title: t('proceeding:action.changeToActiveFormOperation.title'),
      message: t('proceeding:action.changeToActiveFormOperation.confirmMessage'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        activateFormOperations(
          {
            proceedingId: id,
            proceedingFormIds: selected,
            version
          },
          {
            onSuccess: () => {
              showSuccessSnackbar(
                getSuccessMessage(
                  'proceeding:action.changeToActiveFormOperation.successMessageSingular',
                  'proceeding:action.changeToActiveFormOperation.successMessagePlural'
                )
              );
              refreshView();
              closeDialog();
            },
            onSettled: () => {
              setConfirmLoading(false);
            }
          }
        );
      }
    });
  }, [refreshView, activateFormOperations, confirm, id, selected, showSuccessSnackbar, t, version, getSuccessMessage]);

  const openSuspendModal = useCallback(() => {
    openDialog(({ closeDialog }) => (
      <SuspendFormOperationsDialog
        closeDialog={closeDialog}
        proceedingId={id}
        proceedingFormIds={selected}
        proceedingVersion={version}
        onSuccess={refreshView}
      />
    ));
  }, [id, openDialog, selected, version, refreshView]);

  const isExpireButtonVisible = useMemo(() => {
    return selectedFlatRows.every(
      ({ original }) => original.statusKey !== DomainDictionaryEntry.FORM_OPERATION_STATUS.EXPIRED
    );
  }, [selectedFlatRows]);

  const isUndoButtonVisible = useMemo(() => {
    return selectedFlatRows.every(
      ({ original }) => original.statusKey !== DomainDictionaryEntry.FORM_OPERATION_STATUS.REVOKED
    );
  }, [selectedFlatRows]);

  const isChangeToActiveButtonVisible = useMemo(() => {
    return selectedFlatRows.every(
      ({ original }) => original.statusKey !== DomainDictionaryEntry.FORM_OPERATION_STATUS.ACTIVE
    );
  }, [selectedFlatRows]);

  const isSuspendButtonVisible = useMemo(() => {
    return selectedFlatRows.every(
      ({ original }) => original.statusKey !== DomainDictionaryEntry.FORM_OPERATION_STATUS.SUSPENDED
    );
  }, [selectedFlatRows]);

  const menuActions = [
    {
      label: t('proceeding:action.expireFormOperation.buttonContent'),
      key: DomainUIElementEnum.PROCEEDING_FORM_OPERATIONS_EXPIRE_BUTTON,
      onClick: handleExpire,
      isVisible: isExpireButtonVisible
    },
    {
      label: t('proceeding:action.undoFormOperation.buttonContent'),
      key: DomainUIElementEnum.PROCEEDING_FORM_OPERATIONS_UNDO_BUTTON,
      onClick: handleUndo,
      isVisible: isUndoButtonVisible
    },
    {
      label: t('proceeding:action.changeToActiveFormOperation.buttonContent'),
      key: DomainUIElementEnum.PROCEEDING_FORM_OPERATIONS_CHANGE_ACTIVE_BUTTON,
      onClick: handleChangeToActive,
      isVisible: isChangeToActiveButtonVisible
    },
    {
      label: t('proceeding:action.suspendFormOperation.buttonContent'),
      key: DomainUIElementEnum.PROCEEDING_FORM_OPERATIONS_SUSPEND_BUTTON,
      onClick: openSuspendModal,
      isVisible: isSuspendButtonVisible
    }
  ];

  return (
    <>
      {menuActions
        .filter(({ isVisible }) => isVisible)
        .map(action => (
          <DropdownListButton
            label={action.label}
            actionKey={action.key}
            key={action.key}
            aria-label={action.label}
            onClick={() => {
              action.onClick();
              handleClose();
            }}
          />
        ))}
    </>
  );
}

export const selectionActionRenderFormOperation = (selected: string[], handleClose: () => void) => {
  return <FormOperationTableSelectAction selected={selected} handleClose={handleClose} />;
};
