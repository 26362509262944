import { useParams } from 'react-router-dom';

import { useFormV2Context } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';
import { DomainUIElementEnum } from '@libs/domain/config';
import {
  DocumentsTable,
  HeaderActionEnum,
  isReceiveDateDocumentActionDisabled,
  RowActionEnum,
  useCancelDocumentMutation,
  useDeliveryDateDialog,
  useForwardToShipmentMutation,
  useGenerateApplicationDocumentDialog,
  useUpdateDocumentDialog
} from '@libs/domain/documents';
import {
  ISuspensionDetailsParams,
  SuspensionQueryKeysEnum,
  useEditSuspensionDocumentMutation,
  useGenerateSuspensionDocumentMutation,
  useSuspensionDocumentsTable
} from '@libs/domain/suspensions';

function SuspensionDocumentsTab() {
  const queryCache = useQueryCache();
  const { formMode } = useFormV2Context();
  const { id } = useParams<ISuspensionDetailsParams>();

  const { mutateAsync: cancelDocument } = useCancelDocumentMutation();
  const { mutateAsync: forwardToShipment } = useForwardToShipmentMutation();

  const tableProps = useSuspensionDocumentsTable(id);
  const { openUpdateDocumentDialog } = useUpdateDocumentDialog(
    SuspensionQueryKeysEnum.SUSPENSIONS_OUTGOING_DOCUMENTS,
    useEditSuspensionDocumentMutation,
    id
  );
  const { openEnterDeliveryDateDialog } = useDeliveryDateDialog({
    listQueryKey: SuspensionQueryKeysEnum.SUSPENSIONS_OUTGOING_DOCUMENTS
  });
  const { openGenerateDocumentDialog } = useGenerateApplicationDocumentDialog({
    generateDocumentMutation: useGenerateSuspensionDocumentMutation,
    apiParams: {
      suspensionId: id
    },
    listQueryKey: SuspensionQueryKeysEnum.SUSPENSIONS_OUTGOING_DOCUMENTS
  });

  const handleSuccess = () => {
    queryCache.invalidateQueries([SuspensionQueryKeysEnum.SUSPENSION_DETAILS, id]);
  };

  return (
    <DocumentsTable
      parentId={id}
      apiRequest={API.suspensions.getSuspensionDocumentsSnapshotPage}
      queryKey={SuspensionQueryKeysEnum.SUSPENSIONS_OUTGOING_DOCUMENTS}
      tableProps={tableProps}
      cancelDocument={cancelDocument}
      forwardToShipment={forwardToShipment}
      openEnterDeliveryDateDialog={openEnterDeliveryDateDialog}
      openGenerateDocumentDialog={() =>
        openGenerateDocumentDialog({
          onSuccess: handleSuccess
        })
      }
      openUpdateDocumentDialog={openUpdateDocumentDialog}
      headerActions={[HeaderActionEnum.GENERATE_DOCUMENT]}
      permissionsKeys={{
        generateDocument: DomainUIElementEnum.DOCUMENTS_ISSUED_GENERATE_DOCUMENT_BUTTON
      }}
      tableActionKeys={[
        DomainUIElementEnum.SUSPENSIONS_DOCUMENTS_UPLOAD_BUTTON,
        DomainUIElementEnum.DOCUMENTS_ISSUED_DOCUMENTS_DOWNLOAD_BUTTON,
        DomainUIElementEnum.DOCUMENTS_ISSUED_DOCUMENTS_FORWARD_TO_SHIPMENT_BUTTON,
        DomainUIElementEnum.SUSPENSIONS_DOCUMENTS_ADD_DELIVERY_DATE_BUTTON,
        DomainUIElementEnum.SUSPENSIONS_DOCUMENTS_CANCEL_BUTTON
      ]}
      rowActions={[
        RowActionEnum.UPLOAD_DOCUMENT,
        RowActionEnum.DOWNLOAD_DOCUMENT,
        RowActionEnum.CANCEL_DOCUMENT,
        RowActionEnum.FORWARD_TO_SHIPMENT_DOCUMENT,
        RowActionEnum.ENTER_DELIVERY_DATE_DOCUMENT
      ]}
      isReceiveDateDocumentActionDisabled={isReceiveDateDocumentActionDisabled}
      customDisabledLabelKey="document:message.changeReceiveDateTooltip"
      mode={formMode}
    />
  );
}

export default SuspensionDocumentsTab;
