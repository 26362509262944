export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  copyFolderVehiclesToAssignedApplication: {
    translationKey: 'applications:api.copyFolderVehiclesToAssignedApplication'
  },
  createVehicle: { translationKey: 'suspensions:api.getSystemParameters' },
  deleteVehicles: { translationKey: 'suspensions:api.deleteVehicles' },
  deleteVehicle: { translationKey: 'suspensions:api.deleteVehicle' },
  expireVehicle: { translationKey: 'suspensions:api.expireVehicle' },
  restoreVehicle: { translationKey: 'suspensions:api.restoreVehicle' },
  updateVehicle: { translationKey: 'suspensions:api.updateVehicle' },
  cepik: { translationKey: 'suspensions:api.cepik' },
  getVehicleDetails: { translationKey: 'suspensions:api.getVehicleDetails' },
  getVehiclesSearchFilter: { translationKey: 'suspensions:api.getVehiclesSearchFilter' },
  isVehicleActive: { translationKey: 'suspensions:api.isVehicleActive' }
};
