import { PaginatedQueryConfig, usePaginatedQuery } from 'react-query';
import { DepotManagersList, GetDepotManagersListRequest } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { parseUsersDepotsList } from '../../../utils';
import { endpointsConfig } from '../../endpoints-config';
import { ResourceQueryKeysEnum } from '..';

export const getDepotManagers = async (params: GetDepotManagersListRequest) => {
  const { data } = await API.depotManagers.getDepotManagersList(params, {
    ...endpointsConfig.getDepotManagersList
  });

  return parseUsersDepotsList(data);
};

const useDepotManagersListQuery = (
  params: GetDepotManagersListRequest,
  queryConfig?: PaginatedQueryConfig<DepotManagersList, unknown>
) =>
  usePaginatedQuery<DepotManagersList>(
    [ResourceQueryKeysEnum.DEPOTS_MANAGERS_LIST, params],
    () => getDepotManagers(params),
    queryConfig
  );

export default useDepotManagersListQuery;
