import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { boolean as YupBoolean, number as YupNumber, object as YupObject, string as YupString } from 'yup';

import { useValidatorUtil, ValidatorEnums } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';
import {
  IVehicleFieldsRequiredByCategory,
  VEHICLE_3_5T_WEIGHT,
  VehicleFormData,
  VehicleSectionFields
} from '@libs/domain/vehicle';

function useVehicleValidationSchema(
  folderType: string,
  hiddenFieldsAddVehicle?: string[],
  fieldsRequiredByCategory?: IVehicleFieldsRequiredByCategory
) {
  const [t] = useTranslation();
  const plateValidator = useValidatorUtil(ValidatorEnums.VEHICLE_PLATE_NUMBER);
  const getIsFieldHidden = (fieldName: string) => {
    return hiddenFieldsAddVehicle?.includes(fieldName);
  };

  const folderTypeTpOrKs = [DomainDictionaryEntry.FOLDER_TYPE.TP, DomainDictionaryEntry.FOLDER_TYPE.KS].includes(
    folderType
  );

  const folderTypeTpOrKsOrPa = [
    DomainDictionaryEntry.FOLDER_TYPE.TP,
    DomainDictionaryEntry.FOLDER_TYPE.KS,
    DomainDictionaryEntry.FOLDER_TYPE.PA
  ].includes(folderType);

  const validationSchema = (isVehicleSaleDate?: boolean) => {
    if (isVehicleSaleDate) {
      return YupObject({
        ...(!getIsFieldHidden(VehicleSectionFields.DATE_OF_SALE) && {
          dateOfSale: YupString().required().nullable()
        })
      });
    }

    return YupObject<VehicleFormData>({
      ...(!getIsFieldHidden(VehicleSectionFields.VIN) && {
        vin: YupString().required()
      }),
      ...(!getIsFieldHidden(VehicleSectionFields.PLATE) && {
        plateNumber: YupString()
          .required()
          .matches(...plateValidator)
      }),
      ...(!getIsFieldHidden(VehicleSectionFields.BRAND) && {
        brandKey: YupObject().nullable().required()
      }),
      ...(!getIsFieldHidden(VehicleSectionFields.MODEL) && {
        modelKey: fieldsRequiredByCategory.model ? YupObject().nullable().required() : YupObject().nullable()
      }),
      ...(!getIsFieldHidden(VehicleSectionFields.MANUFACTURE_YEAR) && {
        manufactureYear: YupNumber()
          .required()
          .min(1000)
          .max(new Date().getFullYear())
          .typeError(t('validation:required'))
      }),
      ...(!getIsFieldHidden(VehicleSectionFields.TYPE) && {
        typeKey: YupObject().nullable().required()
      }),
      ...(!getIsFieldHidden(VehicleSectionFields.SEATS) && {
        seatsNumber: YupNumber().when('typeKey', {
          is: typeKey => {
            return (
              folderType === DomainDictionaryEntry.FOLDER_TYPE.OP ||
              typeKey?.value === DomainDictionaryEntry.VEHICLE_TYPE.BUS
            );
          },
          then: YupNumber()
            .required()
            .min(10)
            .transform(value => (Number.isNaN(value) ? undefined : value))
            .nullable(),
          otherwise: YupNumber()
            .transform(value => (Number.isNaN(value) ? undefined : value))
            .nullable()
        })
      }),
      ...(!getIsFieldHidden(VehicleSectionFields.ACCEPTABLE_CAPACITY) && {
        acceptableCapacity: YupNumber()
          .positive()
          .test('required', t('validation:required'), function checkIsRequired(value) {
            return (
              !folderTypeTpOrKs ||
              [
                DomainDictionaryEntry.VEHICLE_TYPE.BUS,
                DomainDictionaryEntry.VEHICLE_TYPE.CAR,
                DomainDictionaryEntry.VEHICLE_TYPE.TRUCK_TRACTOR
              ].includes(this.parent.typeKey?.value) ||
              !!value
            );
          })
          .transform(value => (Number.isNaN(value) ? undefined : value))
          .nullable()
      }),
      ...(!getIsFieldHidden(VehicleSectionFields.DMC) && {
        dmc: YupNumber()
          .positive()
          .test('required', t('validation:required'), function checkIsRequired(value) {
            return (
              !this.parent.typeKey ||
              !folderTypeTpOrKsOrPa ||
              this.parent.typeKey?.value === DomainDictionaryEntry.VEHICLE_TYPE.BUS ||
              !!value
            );
          })
          .test({
            name: 'belowThreeAndHalfTons',
            test(value) {
              if (!folderTypeTpOrKsOrPa || !this.parent.belowThreeAndHalfTons) {
                return true;
              }
              if (!this.parent.belowThreeAndHalfTons?.value && value < VEHICLE_3_5T_WEIGHT) {
                return this.createError({
                  message: t('vehicle:details.validation.dmcTooSmall'),
                  path: this.path
                });
              }
              if (this.parent.belowThreeAndHalfTons?.value && value > VEHICLE_3_5T_WEIGHT) {
                return this.createError({
                  message: t('vehicle:details.validation.dmcTooBig'),
                  path: this.path
                });
              }
              if (!this.parent.belowThreeAndHalfTons?.value && value === VEHICLE_3_5T_WEIGHT) {
                return this.createError({
                  message: t('vehicle:details.validation.dmcEqual3500'),
                  path: this.path
                });
              }
              return true;
            }
          })
          .transform(value => (Number.isNaN(value) ? undefined : value))
          .nullable()
          .max(2000000000)
      }),
      ...(!getIsFieldHidden(VehicleSectionFields.BELOW_THREE_AND_HALF_TONS) && {
        belowThreeAndHalfTons: YupObject()
          .nullable()
          .test('required', t('validation:required'), value => {
            return folderType !== DomainDictionaryEntry.FOLDER_TYPE.TP || !!value;
          })
      }),
      ...(!getIsFieldHidden(VehicleSectionFields.OWNERSHIP_TYPE) && {
        ownershipTypeKey: YupObject().nullable().required()
      }),
      ...(!getIsFieldHidden(VehicleSectionFields.TEMPORARY_RIGHT_OF_DISPOSITION) && {
        temporaryRightOfDisposition: YupObject().nullable().required()
      }),
      ...(!getIsFieldHidden(VehicleSectionFields.EURO_CLASS_KEY) && {
        euroClassKey: fieldsRequiredByCategory.euroClassKey ? YupObject().nullable().required() : YupObject().nullable()
      }),
      ...(!getIsFieldHidden(VehicleSectionFields.RIGHT_OF_DISPOSITION_DATE) && {
        rightOfDispositionDate: YupString()
          .nullable()
          .test('required', t('validation:required'), function checkIsRequired(value) {
            return !this.parent.temporaryRightOfDisposition?.value || !!value;
          })
          .test('minDateToday', t('validation:minDateToday'), function checkDate(value) {
            return !this.parent.temporaryRightOfDisposition?.value || moment(value).isSameOrAfter(moment(), 'day');
          })
      }),
      ...(!getIsFieldHidden(VehicleSectionFields.ENGINE_NUMBER) && {
        engineNumber: fieldsRequiredByCategory.engineNumber ? YupString().required().nullable() : YupString().nullable()
      }),
      ...(!getIsFieldHidden(VehicleSectionFields.ENGINE_TYPE) && {
        engineType: fieldsRequiredByCategory.engineType ? YupString().required().nullable() : YupString().nullable()
      }),
      ...(!getIsFieldHidden(VehicleSectionFields.FIRST_REGISTRATION_DATE) && {
        firstRegistrationDate: fieldsRequiredByCategory.firstRegistrationDate
          ? YupString().required().nullable()
          : YupString().nullable()
      }),
      ...(!getIsFieldHidden(VehicleSectionFields.IGNORE_EXISTING_VEHICLES) && {
        ignoreExistingVehicles: YupBoolean().nullable()
      })
    });
  };

  return { validationSchema };
}

export default useVehicleValidationSchema;
