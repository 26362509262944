import { DriverDetails, NamedObjectDetails, ProceedingIdentityDetails, ResourceObjectLite } from '@ibtm/domain';

import { DriversColumnsEnum } from '../../drivers/model/drivers.model';

export interface DriverCertificateSnapshotClient {
  id: string;
  driverCertificateNumber: string;
  formNumber: string;
  applicationNumber: string;
  driverName: string;
  driverSurname: string;
  driverID: string;
  printDate: string;
  validFrom: string;
  validTo: string;
  dateOfIssueExternal: string;
  signedBy: string;
  status: string;
  cancellationDate: string;
  cancellationReason: string;
  cancelledBy: string;
  subjectName: string;
  subjectAddress: string;
  driver?: DriverDetails;
  file?: NamedObjectDetails;
  resourceAssignmentRegenerationNeeded?: boolean;
  version?: number;
  form?: ResourceObjectLite;
  pendingProceedings?: ProceedingIdentityDetails[];
}

export enum DriverCertificateDictionaryNameEnum {
  DRIVER_CERTIFICATE_STATUS = 'DRIVER_CERTIFICATE_STATUS'
}

export enum DriverCertificateStatusEnum {
  ACTIVE = 'ibtm-domain.driver-certificate-status.active',
  INACTIVE = 'ibtm-domain.driver-certificate-status.inactive',
  PENDING = 'ibtm-domain.driver-certificate-status.pending',
  EXPIRED = 'ibtm-domain.driver-certificate-status.expired',
  SUSPEND = 'ibtm-domain.driver-certificate-status.suspend'
}

export enum DriverCertificatesColumnsEnum {
  DRIVER_CERTIFICATE_NUMBER = 'driverCertificateNumber',
  DRIVER_CERTIFICATE_NUMBER_EDITABLE = 'driverCertificateNumberEditable',
  FORM_NUMBER = 'formNumber',
  APPLICATION_NUMBER = 'applicationNumber',
  DRIVER_NAME = 'driverName',
  DRIVER_SURNAME = 'driverSurname',
  DRIVER_ID = 'driverID',
  PRINT_DATE = 'printDate',
  VALID_FROM = 'validFrom',
  VALID_FROM_EDITABLE = 'validFromEditable',
  VALID_TO = 'validTo',
  VALID_TO_EDITABLE = 'validToEditable',
  DATE_OF_ISSUE_EXTERNAL = 'dateOfIssueExternal',
  SIGNED_BY = 'signedBy',
  STATUS = 'status',
  CANCELLATION_DATE = 'cancellationDate',
  CANCELLATION_REASON = 'cancellationReason',
  CANCELLED_BY = 'cancelledBy',
  SUBJECT_NAME = 'subjectName',
  SUBJECT_ADDRESS = 'subjectAddress'
}

export const DriverCertificateExtendedEnum = { ...DriverCertificatesColumnsEnum, ...DriversColumnsEnum };
export type DriverCertificateExtendedEnum = DriverCertificatesColumnsEnum | DriversColumnsEnum;

export type VisibleColumnConfig<T> = Array<{ column: T; isEditable?: boolean }>;
