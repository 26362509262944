import { useQuery } from 'react-query';
import { ForeignPermissionApplicationRecordSearchFilter } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export enum PermissionsRecordsQueryKeysEnum {
  FOREIGN_PERMISSIONS_RECORDS = 'FOREIGN_PERMISSIONS_RECORDS'
}

export type ForeignPermissionApplicationRecordsQueryParams = {
  applicationId: string;
} & ForeignPermissionApplicationRecordSearchFilter;

export const getForeignPermissionApplicationRecordsClient = async (_, params: { applicationId: string }) => {
  const { data } = await API.client.applicationForeignPermissions.getForeignPermissionApplicationRecords(
    params.applicationId,
    {},
    { ...endpointsConfig.getForeignPermissionApplicationRecords }
  );

  return data;
};

export const getForeignPermissionApplicationRecords = async (
  _,
  params: ForeignPermissionApplicationRecordsQueryParams
) => {
  const { applicationId, ...filters } = params;
  const { data } = await API.applicationForeignPermissions.getForeignPermissionApplicationRecords(
    params.applicationId,
    filters,
    { ...endpointsConfig.getForeignPermissionApplicationRecords }
  );

  return data;
};

export default function useGetForeignPermissionApplicationRecords(
  params: ForeignPermissionApplicationRecordsQueryParams,
  queryConfig = {}
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery(
    [PermissionsRecordsQueryKeysEnum.FOREIGN_PERMISSIONS_RECORDS, params],
    getQuery(getForeignPermissionApplicationRecordsClient, getForeignPermissionApplicationRecords),
    queryConfig
  );
}
