import { useTranslation } from 'react-i18next';
import { FolderSearchFilter } from '@ibtm/domain';

import { TableLayout } from '@libs/common/v2';

import { EntrepreneurDetailsSnapshot, useSearchEntrepreneursTable } from '@libs/domain/folder';

interface EntrepreneursTableProps {
  searchParams: FolderSearchFilter;
  children?: any;
  entrepreneurs?: EntrepreneurDetailsSnapshot[];
  createMode?: boolean;
}

function SearchEntrepreneursTable({ searchParams, children, entrepreneurs, createMode }: EntrepreneursTableProps) {
  const [t] = useTranslation();
  const tableProps = useSearchEntrepreneursTable(searchParams, entrepreneurs, createMode);
  return (
    <TableLayout
      {...tableProps}
      isRefreshEnabled={false}
      isFilterEnabled={false}
      pageTitle={t('folder:details.section.searchResults')}
      actionsColumnWidth={40}
      isPageTabContent
      isSection
    >
      {children}
    </TableLayout>
  );
}

export default SearchEntrepreneursTable;
