import { ExploitationBaseInspectionDetails } from '@ibtm/domain';

export type ExploitationBaseInspectionSnapshot = Omit<ExploitationBaseInspectionDetails, 'inspectionDate'> & {
  id?: string;
  inspectionDate?: string;
};

export enum ExploitationBaseActionButtonsEnum {
  ADD = 'ADD',
  SEARCH = 'SEARCH'
}

export enum ExploitationBaseRowActionButtonsEnum {
  EXPIRE_EXPLOITATION_BASE = 'EXPIRE_EXPLOITATION_BASE',
  RESTORE_EXPLOITATION_BASE = 'RESTORE_EXPLOITATION_BASE',
  SHOW_GEOPORTAL_MAP = 'SHOW_GEOPORTAL_MAP',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  OPEN_DETAILS = 'OPEN_DETAILS'
}

export enum ExploitationBaseStatusEnum {
  OK = 'ibtm-domain.exploitation-base-status.ok',
  CANCELLED = 'ibtm-domain.exploitation-base-status.cancelled',
  EXPIRED = 'ibtm-domain.exploitation-base-status.expired',
  CURRENT = 'ibtm-domain.exploitation-base-status.current',
  NEW = 'ibtm-domain.exploitation-base-status.new'
}

export enum CountryEnum {
  POLAND = 'ibtm-domain.country-code.pol'
}
