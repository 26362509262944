import { useEffect } from 'react';

import { TableEvent, useTableEventsContext } from '../context';

export type SubscribeTableEventParams = { event: TableEvent; onEvent: () => void; isActive?: boolean };

/**
 * @param onEvent mieć stałą referencję - musi być w useCallback
 */
export const useSubscribeTableEvent = ({ event, onEvent, isActive = true }: SubscribeTableEventParams) => {
  const { subscribe } = useTableEventsContext();
  useEffect(() => {
    const unsubscribe = subscribe(e => {
      if (e === event && isActive) {
        onEvent();
      }
    });
    return unsubscribe;
  }, [subscribe, onEvent, event, isActive]);
};
