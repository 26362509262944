import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

async function restoreTransportManager({ id, version }: { id: string; version: number }) {
  const { data } = await API.transportManager.restoreTransportManager(
    id,
    { version },
    {
      ...endpointsConfig.restoreTransportManager
    }
  );
  return data;
}

function useRestoreTransportManagerMutation() {
  return useMutation(restoreTransportManager);
}

export default useRestoreTransportManagerMutation;
