import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ViolationsApiUpdateViolationStatusRequest } from '@ibtm/domain';
import * as Yup from 'yup';

import { partialTranslate } from '@libs/common';
import { Dialog, DictionarySelectField, FormV2Context, typedNameV2 } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import { RoadTransportQueryKeysEnum, useViolationStatusChangeMutations } from '../../../api';
import { IViolationClient } from '../../../models';

const getLabel = partialTranslate('roadTransport:fields');

interface IProps {
  initialData: IViolationClient;
  closeDialog: () => void;
}

function ViolationStatusChangeForm({ initialData, closeDialog }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();

  const { mutate: changeStatus } = useViolationStatusChangeMutations();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    reset
  } = useForm<Partial<IViolationClient>>({
    mode: 'all',
    resolver: yupResolver(
      Yup.object({
        status: Yup.string().nullable().required()
      })
    ),
    defaultValues: {
      status: null
    }
  });

  const onSubmit = (formData: Partial<IViolationClient>) => {
    const requestData: ViolationsApiUpdateViolationStatusRequest = {
      violationId: initialData.id,
      violationStatusUpdateRequest: {
        statusKey: formData.status
      }
    };

    changeStatus(requestData, {
      onSuccess: () => {
        showSnackbar('success', t('roadTransport:messages.changeStatusSuccess'));
        queryCache.invalidateQueries(RoadTransportQueryKeysEnum.VIOLATIONS_LIST);

        closeDialog();
      }
    });
  };

  const formValues = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      reset
    }),
    [reset, control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <FormV2Context.Provider value={formValues}>
      <Dialog
        title={t('roadTransport:dialog.changeStatusTitle')}
        confirmText={t('action.change')}
        cancelText={t('action.cancel')}
        onConfirm={() => handleSubmit(onSubmit)()}
        onCancel={closeDialog}
        isOpen
      >
        <DictionarySelectField
          name={typedNameV2<IViolationClient>('status')}
          label={getLabel('status')}
          dictionaryName={DomainDictionaryEnum.PROCEEDING_STATUS_KREPTD}
          optionsFilter={option => {
            if (option.value === initialData.status) {
              return false;
            }
            return [
              DomainDictionaryEntry.PROCEEDING_STATUS_KREPTD.NO_PROPERTIES,
              DomainDictionaryEntry.PROCEEDING_STATUS_KREPTD.REFUND,
              DomainDictionaryEntry.PROCEEDING_STATUS_KREPTD.INCORRECT_QUALIFICATION
            ].includes(option.value);
          }}
          stringValue
          isRequired
        />
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default ViolationStatusChangeForm;
