import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { IPropsItem, PageHeader, useBreadcrumbsConverter, useBreadcrumbTextByFormMode } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';

interface IProps {
  title: string;
  children?: any;
  code?: string;
  id?: string;
}

function ResourcesPageHeader({ title, code = '', id = '', children = null }: IProps) {
  const [t] = useTranslation();
  const classes = useSimpleContentStyles();
  const { convertBreadcrumbToText } = useBreadcrumbsConverter();

  const breadcrumbText = useBreadcrumbTextByFormMode({
    viewValue: t('resource:create.breadcrumbs.details', { resource: code })
  });

  const breadcrumbs = useMemo(
    (): Array<IPropsItem> => [
      {
        to: '/resource',
        text: t('resource:list.tableTitle')
      },
      { text: convertBreadcrumbToText(breadcrumbText) }
    ],
    [t, convertBreadcrumbToText, breadcrumbText]
  );

  return (
    <div>
      {!children && title}
      {children && (
        <PageHeader
          title={title}
          rightSideContent={<div className={classes.actions}>{children}</div>}
          {...(code || id
            ? {
                breadcrumbs
              }
            : {})}
        />
      )}
    </div>
  );
}

const useSimpleContentStyles = makeStyles<Theme>(() => ({
  actions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

export default React.memo(ResourcesPageHeader);
