import { partialTranslate } from '@libs/common';
import { FormMode, useViewModesV2 } from '@libs/common/v2';

import { IUsersTab, UserDetailsTab } from '@libs/user';

function useUsersTabDefinitions(formMode: FormMode): Array<IUsersTab> {
  const getLabel = partialTranslate('user:tab');
  const { viewMode, createMode } = useViewModesV2(formMode);

  return [
    {
      label: getLabel('account'),
      value: UserDetailsTab.GENERAL,
      icon: 'AssociationsIcon',
      isVisible: true
    },
    {
      label: getLabel('permissionsGroup'),
      value: UserDetailsTab.PERMISSIONS_GROUP,
      isVisible: viewMode || createMode,
      icon: 'PeoplesIcon'
    }
  ];
}

export default useUsersTabDefinitions;
