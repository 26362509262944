import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationDetails as ApplicationDetailsClient } from '@ibtm/client-domain';
import { filter } from 'lodash';
import { number, object, ValidationError } from 'yup';

import { FormErrorType, LoaderCircular } from '@libs/common/v2';

import { MetaForm, UiMode } from '@libs/meta-form';
import { useElementVisibility } from '@libs/permission';

import { useGetApiQueryByPerspective } from '@libs/domain/api';
import {
  useApplicationApiRegistry,
  useApplicationFieldRegistry,
  useApplicationUIDefinitionCommonQuery,
  useApplicationUIDefinitionQuery
} from '@libs/domain/application-definitions';
import { DomainDictionaryEntry, useDomainConfigContext } from '@libs/domain/config';
import { getLicenses, getLicensesClient, LicenseStatusEnum } from '@libs/domain/license';
import { getProxies, getProxiesClient } from '@libs/domain/proxy';

import { TAB_PERMISSIONS } from '../../config/tabPermissions';
import { Application, ApplicationTabIdEnum, clientPortalVisibleTabs } from '../../model';
import { additionalSelectOptionsFilters, checkProxiesPayment } from '../../utils';

interface Props {
  applicationData: Application | ApplicationDetailsClient;
  isFormOpen: boolean;
  pageComponentProps?: any;
}

function ApplicationDetailsContent({ applicationData, isFormOpen, pageComponentProps }: Props) {
  const [t] = useTranslation();
  const { checkIsElementVisible } = useElementVisibility();
  const { data: uiDefinition, isLoading: isUiDefinitionLoading } = useApplicationUIDefinitionQuery({
    applicationTypeKey: applicationData?.typeKey
  });
  const { data: { uiDefinitionCommon, uiDefinitionClientPortalCommon } = {} } = useApplicationUIDefinitionCommonQuery();
  const { viewFieldsRegistry, formFieldsRegistry } = useApplicationFieldRegistry(applicationData);
  const { apiRegistry } = useApplicationApiRegistry(applicationData);
  const { isOperatorPortal, isClientPortal } = useDomainConfigContext();
  const { getQuery } = useGetApiQueryByPerspective();

  const getLicensesQuery = getQuery(getLicensesClient, getLicenses);
  const getProxiesQuery = getQuery(getProxiesClient, getProxies);

  const isAllowedToViewTab = useCallback(
    (key: string) => {
      const permission = TAB_PERMISSIONS[key];
      return !permission || checkIsElementVisible(permission);
    },
    [checkIsElementVisible]
  );
  const elementVisibility = useMemo(
    () => ({
      TAB_VISIBILITY: (_, tabId) =>
        isAllowedToViewTab(tabId) && (isOperatorPortal || clientPortalVisibleTabs.includes(tabId)),
      DRIVER_TAB_FOR_MIGRATED_APPLICATIONS_VISIBILITY: (_, tabId: string) =>
        ApplicationTabIdEnum.DRIVERS !== tabId || (applicationData as Application)?.migratedFromTransbit,
      OUTGOING_DOCUMENTS_HEADER_VISIBILITY: () =>
        [
          'ibtm-domain.application.status.preparing-decision-draft',
          'ibtm-domain.application.status.released-waiting-for-zpo',
          'ibtm-domain.application.status.pending-decision',
          'ibtm-domain.application.status.pending-print',
          'ibtm-domain.application.status.pending-issue',
          'ibtm-domain.application.status.issued'
        ].includes(applicationData?.statusKey)
    }),
    [isOperatorPortal, applicationData, isAllowedToViewTab]
  );
  const elementVisibilityAsync = useMemo(
    () => ({
      FOLDER_HAS_ACTIVE_OR_PENDING_LISENSE: async () => {
        const folderId = applicationData?.folder?.id;
        const transferredFoldersIds =
          applicationData?.licenseApplicationDetails?.transferApplicationDataDetails?.folders?.map(folder => folder.id);
        if (!folderId) {
          return false;
        }
        const data = await getLicensesQuery({
          folderIdIn: [folderId, ...(transferredFoldersIds || [])],
          statusKeyIn: [LicenseStatusEnum.ACTIVE, LicenseStatusEnum.PENDING]
        });
        const licenseInStatusCount = (status: LicenseStatusEnum) =>
          filter(data.content, item => item.status === status).length ?? 0;

        const activeLicenseCount = licenseInStatusCount(LicenseStatusEnum.ACTIVE);
        const pendingLicenseCount = licenseInStatusCount(LicenseStatusEnum.PENDING);

        return Boolean(activeLicenseCount > 1 || (activeLicenseCount && pendingLicenseCount));
      },
      IS_PROXIES_QUOTATION_SECTION_VISIBLE: async () => {
        const {
          APPLICATION_TYPE: { foreignPermitSingleTp, foreignPermitSingleOp, foreignPermitEkmt }
        } = DomainDictionaryEntry;
        if (!applicationData.id) {
          return false;
        }
        const proxiesList = await getProxiesQuery(null, { applicationId: applicationData.id });
        const hasProxies = Boolean(proxiesList.content?.length);
        return (
          hasProxies &&
          ![foreignPermitSingleTp, foreignPermitSingleOp, foreignPermitEkmt].includes(applicationData.typeKey)
        );
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [applicationData?.folder?.id, applicationData.id, applicationData.typeKey]
  );

  if (isUiDefinitionLoading || !applicationData?.typeKey) {
    return <LoaderCircular isLoading />;
  }

  const getCheckVehicleSameAmoutValidation = () =>
    object({
      'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumberBelowThreeAndHalfTons': number().test(
        'sameAmountVehiclesWithTabBelowThreeAndHalfTons',
        t('applications:message.sameVehicleBelowAmountAsInVehiclesTab'),
        function checkSameAmountVehiclesWithTabBelowThreeAndHalfTons(this) {
          const { parent } = this;
          return 'vehiclesSummary' in applicationData &&
            parent['licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumberBelowThreeAndHalfTons'] ===
              applicationData?.vehiclesSummary.belowThreeAndHalfTons
            ? true
            : new ValidationError(
                t('applications:message.sameVehicleBelowAmountAsInVehiclesTab'),
                FormErrorType.WARNING,
                'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumberBelowThreeAndHalfTons',
                FormErrorType.WARNING
              );
        }
      ),
      'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumberAboveThreeAndHalfTons': number().test(
        'sameAmountVehiclesWithTabAboveThreeAndHalfTons',
        t('applications:message.sameVehicleAboveAmountAsInVehiclesTab'),
        function checkSameAmountVehiclesWithTabAboveThreeAndHalfTons(this) {
          const { parent } = this;
          return 'vehiclesSummary' in applicationData &&
            parent['licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumberAboveThreeAndHalfTons'] ===
              applicationData?.vehiclesSummary.aboveThreeAndHalfTons
            ? true
            : new ValidationError(
                t('applications:message.sameVehicleAboveAmountAsInVehiclesTab'),
                FormErrorType.WARNING,
                'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumberAboveThreeAndHalfTons',
                FormErrorType.WARNING
              );
        }
      )
    });

  if (uiDefinition?.fields && uiDefinition?.layout && uiDefinitionCommon.fields && applicationData) {
    return (
      <MetaForm
        elementVisibility={elementVisibility}
        elementVisibilityAsync={elementVisibilityAsync}
        fields={[
          ...(uiDefinition?.fields ?? []),
          ...(isClientPortal ? uiDefinitionClientPortalCommon?.fields ?? [] : []),
          ...(uiDefinitionCommon?.fields ?? [])
        ]}
        pageComponentProps={pageComponentProps}
        components={[
          ...(uiDefinition?.components ?? []),
          ...(isClientPortal ? uiDefinitionClientPortalCommon?.components ?? [] : []),
          ...(uiDefinitionCommon?.components ?? [])
        ]}
        layout={uiDefinition?.layout ?? {}}
        properties={{ mode: isFormOpen ? UiMode.FORM : UiMode.VIEW }}
        apiRegistry={apiRegistry}
        additionalValidators={{
          CHECK_PROXIES_PAYMENT: checkProxiesPayment
        }}
        additionalFields={isFormOpen ? formFieldsRegistry : viewFieldsRegistry}
        additionalSelectOptionsFilters={additionalSelectOptionsFilters}
        validationSchemeRegistry={{
          CHECK_VEHICLE_SAME_AMOUNT_PB12: getCheckVehicleSameAmoutValidation().shape({
            'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumber': number().test(
              'sameAmountVehiclesWithTab',
              t('applications:message.sameVehicleAmountAsInVehiclesTab'),
              function checkSameAmountVehiclesWithTab(this) {
                const { parent } = this;
                return 'vehiclesSummary' in applicationData &&
                  parent['licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumber'] ===
                    applicationData?.vehiclesSummary.allCount
                  ? true
                  : new ValidationError(
                      t('applications:message.sameVehicleAmountAsInVehiclesTab'),
                      FormErrorType.WARNING,
                      'licenseApplicationDetails-commonLicenseApplicationData-vehiclesNumber',
                      FormErrorType.WARNING
                    );
              }
            )
          }),
          CHECK_VEHICLE_SAME_AMOUNT: getCheckVehicleSameAmoutValidation()
        }}
      />
    );
  }
  return (
    <div className="p-24">
      {t('applications:message.applicationDefinitionNotFound', { typeKey: applicationData.typeKey })}
    </div>
  );
}

export default ApplicationDetailsContent;
