import { useEffect } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { AddressUpdateRequest } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import {
  DictionarySelectField,
  GridLayout,
  InputMode,
  typedNameV2,
  useFormV2Context,
  useFormV2Watch
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';
import { TextFieldValidation } from '@libs/common/v2/form/validation';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import { EditFolderParams } from '../../../folder';
import useCountryChange from '../../hooks/useCountryChange';

import ForeignAddressSection from './ForeignAddressSection';
import PolishAddressSection, { AddressFieldsGridProps } from './PolishAddressSection';

const getLabel = partialTranslate('address:fields');

interface IProps {
  formGroupName?: string | FieldPath<FieldValues>;
  hiddenFields?: Array<keyof AddressUpdateRequest>;
  inputProps?: {
    inputMode?: InputMode;
  };
  fieldGridProps?: AddressFieldsGridProps;
  isCountryFieldAvaliable?: boolean;
  isValidationEnabled?: boolean;
  isCountyValidated?: boolean;
  isCommuneValidated?: boolean;
  isVoivodeshipValidated?: boolean;
}

const validation: TextFieldValidation = { required: [] };

const SUBJECT_COUNTRY_OF_ORIGIN = 'subject.countryOfOrigin';

function AddressSection({
  formGroupName,
  hiddenFields = [],
  inputProps = {},
  isCountryFieldAvaliable,
  isValidationEnabled = true,
  isCountyValidated = true,
  isCommuneValidated = true,
  isVoivodeshipValidated = true,
  fieldGridProps
}: IProps) {
  const { formMode, watch } = useFormV2Context();
  const { createMode } = useViewModesV2(formMode);
  const getFieldName = (fieldName: keyof AddressUpdateRequest) =>
    `${formGroupName ? `${formGroupName}.` : ''}${typedNameV2<AddressUpdateRequest>(
      fieldName
    )}` as FieldPath<FieldValues>;
  const checkIsHiddenField = (fieldName: keyof AddressUpdateRequest) => !hiddenFields.includes(fieldName);

  const { id: folderId } = useParams<EditFolderParams>();

  const { handleCountryChangeWhileEdit, handleCountryChangeWhileCreate } = useCountryChange();

  const countryOfOrigin = useFormV2Watch<string>({ name: SUBJECT_COUNTRY_OF_ORIGIN });
  const countryCodeKey = useFormV2Watch<string>({ name: getFieldName('countryCodeKey') });
  const countryCode = countryOfOrigin || countryCodeKey;
  const isCountryPoland = countryCode ? countryCode === DomainDictionaryEntry.COUNTRY_CODE.POLAND : true;

  const previousCountry = watch('previousCountry') as string;

  // reset addresses przy tworzeniu teczki jeżeli zmienia się kraj
  useEffect(() => {
    if (createMode) {
      handleCountryChangeWhileCreate({ previousCountry, countryCode });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  return (
    <GridLayout itemProps={{ xs: 12 }}>
      {isCountryFieldAvaliable && (
        <DictionarySelectField
          onChange={() => handleCountryChangeWhileEdit(formGroupName)}
          isDisabled={!!folderId}
          name={getFieldName('countryCodeKey')}
          label={getLabel('country')}
          dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
          validationSingleSelect={inputProps.inputMode !== InputMode.VIEW ? { required: [] } : null}
          stringValue
          {...inputProps}
        />
      )}
      {isCountryPoland ? (
        <PolishAddressSection
          validation={validation}
          checkIsHiddenField={checkIsHiddenField}
          getFieldName={getFieldName}
          inputProps={inputProps}
          isValidationEnabled={isValidationEnabled}
          isCountyValidated={isCountyValidated}
          isCommuneValidated={isCommuneValidated}
          isVoivodeshipValidated={isVoivodeshipValidated}
          fieldGridProps={fieldGridProps}
        />
      ) : (
        <ForeignAddressSection
          checkIsHiddenField={checkIsHiddenField}
          getFieldName={getFieldName}
          inputProps={inputProps}
        />
      )}
    </GridLayout>
  );
}

export default AddressSection;
