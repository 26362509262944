import { TRoutingPermissionConfig } from '@libs/permission/routes-guard/route-guard.model';

import { PermissionEnum } from '@libs/domain/config/permission/PermissionEnum';

export const RoutingPermissionConfig: TRoutingPermissionConfig = {
  // Portal klienta
  applicationClientEdit: { permissions: [] },

  // Wnioski
  applicationsList: { permissions: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_LIST_VIEW] },
  applicationDetails: { permissions: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_DETAILS_VIEW] },
  folderApplicationDetails: { permissions: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_DETAILS_VIEW] },
  applicationEdit: { permissions: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_UPDATE] },
  folderApplicationEdit: { permissions: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_UPDATE] },
  archiveApplicationDetailsToArchive: { permissions: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_DETAILS_VIEW] },
  archiveApplicationDetailsArchived: { permissions: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CRUD_DETAILS_VIEW] },
  exploitationBaseApplicationCreate: {
    permissions: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_EXPLOITATION_BASE_CREATE]
  },
  exploitationBaseApplicationEdit: {
    permissions: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_EXPLOITATION_BASE_UPDATE]
  },
  exploitationBaseApplicationView: {
    permissions: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_EXPLOITATION_BASE_DETAILS_VIEW]
  },

  // Teczki
  foldersList: { permissions: [PermissionEnum.IBTM_DOMAIN_FOLDER_LIST_VIEW] },
  folderDetails: { permissions: [] },
  folderEdit: { permissions: [PermissionEnum.IBTM_DOMAIN_FOLDER_UPDATE] },
  folderCreate: { permissions: [PermissionEnum.IBTM_DOMAIN_FOLDER_CREATE] },
  exploitationBaseFolderView: { permissions: [PermissionEnum.IBTM_DOMAIN_FOLDER_EXPLOITATION_BASE_VIEW] },
  exploitationBaseFolderEdit: { permissions: [PermissionEnum.IBTM_DOMAIN_FOLDER_EXPLOITATION_BASE_UPDATE] },

  // Kierowcy
  driversList: { permissions: [PermissionEnum.IBTM_DOMAIN_DRIVER_LIST_VIEW] },

  // Dokumenty wydania
  releaseDocumentList: { permissions: [PermissionEnum.IBTM_DOMAIN_INVOICE_LIST_VIEW] },
  releaseDocumentView: { permissions: [PermissionEnum.IBTM_DOMAIN_INVOICE_DETAILS_VIEW] },
  releaseDocumentEdit: { permissions: [PermissionEnum.IBTM_DOMAIN_INVOICE_CORRECTION_UPDATE] },
  releaseDocumentCreate: { permissions: [PermissionEnum.IBTM_DOMAIN_INVOICE_CORRECTION_CREATE] },
  releaseDocumentReturn: { permissions: [PermissionEnum.IBTM_DOMAIN_INVOICE_CORRECTION_CREATE] },
  releaseDocumentOnFolderSuspensionView: { permissions: [PermissionEnum.IBTM_DOMAIN_INVOICE_DETAILS_VIEW] },
  releaseDocumentOnFolderSuspensionEdit: { permissions: [PermissionEnum.IBTM_DOMAIN_SUSPENSION_CORRECTION_UPDATE] },
  releaseDocumentOnFolderSuspensionCreate: { permissions: [PermissionEnum.IBTM_DOMAIN_SUSPENSION_CORRECTION_CREATE] },
  releaseDocumentOnFolderSuspensionReturn: { permissions: [PermissionEnum.IBTM_DOMAIN_SUSPENSION_CORRECTION_CREATE] },

  // Wydruki
  printsList: { permissions: [PermissionEnum.IBTM_DOMAIN_PRINTS_LIST_VIEW] },
  printDetails: { permissions: [PermissionEnum.IBTM_DOMAIN_APPLICATION_CORE_PRINT_ITEMS_VIEW] },

  // Dokumenty wychodzące
  documentsIssuedList: { permissions: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_LIST_VIEW] },

  // Dokumenty EZD
  ezdDocumentsList: { permissions: [PermissionEnum.IBTM_DOMAIN_EZD_DOCUMENT_LIST_VIEW] },
  ezdDocumentDetails: { permissions: [PermissionEnum.IBTM_DOMAIN_EZD_DOCUMENT_DETAILS_VIEW] },

  // Certyfikaty S
  sCertificatesList: { permissions: [PermissionEnum.IBTM_DOMAIN_S_CERTIFICATE_LIST_VIEW] },
  sCertificateDetails: {
    permissions: [PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_S_CERTIFICATE_CERTIFICATE_DETAILS_VIEW]
  },

  // Magazyn
  resourcesList: { permissions: [PermissionEnum.IBTM_DOMAIN_DEPOT_LIST_VIEW] },
  resourceDetails: { permissions: [PermissionEnum.IBTM_DOMAIN_DEPOT_DETAILS_VIEW] },
  resourceTransferDetails: { permissions: [PermissionEnum.IBTM_DOMAIN_RESOURCE_OBJECT_TRANSFER_FORM_VIEW] },
  resourceObjectsList: { permissions: [PermissionEnum.IBTM_DOMAIN_RESOURCE_OBJECT_SEARCH] },
  disposalsList: { permissions: [] },

  // Weryfikacja uprawnień - Postępowania
  proceedingsList: { permissions: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_LIST_VIEW] },
  proceedingAdministrationDetails: { permissions: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_ADMINISTRATIVE_DETAILS_VIEW] },
  proceedingVerificationDetails: { permissions: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_VERIFICATION_DETAILS_VIEW] },
  proceedingAdministrationEdit: { permissions: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_ADMINISTRATIVE_UPDATE] },
  proceedingVerificationEdit: { permissions: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_VERIFICATION_UPDATE] },
  proceedingCreateAdministrationFromBase: {
    permissions: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_ADMINISTRATIVE_CREATE]
  },
  proceedingCreateVerificationFromBase: { permissions: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_VERIFICATION_CREATE] },
  proceedingAdministrationCreate: { permissions: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_ADMINISTRATIVE_CREATE] },
  proceedingVerificationCreate: { permissions: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_VERIFICATION_CREATE] },

  // Weryfikacja uprawnień - Zawieszenia
  suspensionsList: { permissions: [PermissionEnum.IBTM_DOMAIN_SUSPENSION_LIST_VIEW] },
  suspensionCreate: { permissions: [PermissionEnum.IBTM_DOMAIN_SUSPENSION_CREATE] },
  suspensionEdit: { permissions: [PermissionEnum.IBTM_DOMAIN_SUSPENSION_UPDATE] },
  suspensionDetails: { permissions: [PermissionEnum.IBTM_DOMAIN_SUSPENSION_DETAILS_VIEW] },
  suspensionReleaseDocumentsTab: { permissions: [] },

  // Archiwum
  archiveApplicationsArchiveList: { permissions: [PermissionEnum.IBTM_DOMAIN_ARCHIVE_APPLICATION_ARCHIVED_VIEW] },
  archiveApplicationsCarryOnArchivedList: { permissions: [] },
  archiveApplicationsToArchiveList: { permissions: [PermissionEnum.IBTM_DOMAIN_ARCHIVE_APPLICATION_TO_ARCHIVE_VIEW] },
  // TODO dodać routingi dla archiwum teczki i wypożyczenia teczek

  // Transport drogowy KREPTD
  roadTransportsList: {
    permissions: [
      PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_LIST_VIEW,
      PermissionEnum.ASSIGN_TO_TRANSFER_FOLDER,
      PermissionEnum.TRANSFER_FOLDER
    ]
  },
  roadTransportsViolationsList: {
    permissions: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_VIOLATION_ASSIGNED_LIST_VIEW]
  },
  roadTransportsAdministrativeProceedingsList: {
    permissions: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_LIST_VIEW]
  },
  roadTransportsFoldersToTransferList: { permissions: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_LIST_VIEW] },
  roadTransportDetails: { permissions: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_DETAILS_VIEW] },
  roadTransportDetailsView: { permissions: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_DETAILS_VIEW] },
  roadTransportEdit: { permissions: [PermissionEnum.IBTM_DOMAIN_PROCEEDING_KREPTD_UPDATE] },

  // Stowarzyszenia
  associationsList: { permissions: [PermissionEnum.IBTM_DOMAIN_FOLDER_ASSOCIATION_LIST_VIEW] },
  associationDetails: { permissions: [PermissionEnum.IBTM_DOMAIN_FOLDER_ASSOCIATION_DETAILS_VIEW] },
  associationEdit: { permissions: [PermissionEnum.IBTM_DOMAIN_FOLDER_ASSOCIATION_UPDATE] },
  associationCreate: { permissions: [PermissionEnum.IBTM_DOMAIN_FOLDER_ASSOCIATION_CREATE] },

  // Zezwolenia zagraniczne - Wydane zezwolenia
  releasedPermits: {
    permissions: [],
    anyOfPermissions: [
      PermissionEnum.IBTM_DOMAIN_FOLDER_FOREIGN_PERMISSION_SINGLE_VIEW,
      PermissionEnum.IBTM_DOMAIN_FOLDER_FOREIGN_PERMISSION_EKMT_VIEW
    ]
  },
  releasedPermitsFilteredByReleaseDocument: { permissions: [] },
  releasedPermitsEkmtDetails: { permissions: [] },

  // Zezwolenia zagraniczne - Limity
  disposablePermitsList: {
    permissions: [PermissionEnum.IBTM_DOMAIN_FOLDER_FOREIGN_PERMISSION_SINGLE_VIEW]
  },
  disposablePermitDetails: { permissions: [] },
  ekmtPermitsList: { permissions: [PermissionEnum.IBTM_DOMAIN_FOLDER_FOREIGN_PERMISSION_EKMT_VIEW] },
  ekmtPermitDetails: { permissions: [] },

  // Zezwolenia zagraniczne - Komisja społeczna
  socialCommissionsList: {
    permissions: [
      PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_LIST_VIEW,
      PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_LIST_VIEW,
      PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_BASIC_LIMIT_LIST_VIEW,
      PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_FREE_DISPOSAL_RULE_LIST_VIEW,
      PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_LIST_VIEW
    ]
  },
  // TODO dodać funcje routing na poszczególne taby
  socialCommissionTab: { permissions: [] },
  socialCommissionDetails: { permissions: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_DETAILS_VIEW] },
  socialCommissionEdit: { permissions: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_UPDATE] },
  socialComissionCreate: { permissions: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_CREATE] },
  socialComissionDisposablePermitDetails: {
    permissions: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_DETAILS_VIEW]
  },
  socialComissionDisposablePermitEdit: {
    permissions: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_UPDATE]
  },
  socialComissionDisposablePermitCreate: {
    permissions: [PermissionEnum.IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_CREATE]
  },

  // TODO do przeniesienia do modułu document-template
  documentTemplatesList: { permissions: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_TEMPLATE_VIEW] },
  documentTemplateCreate: { permissions: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_TEMPLATE_ADMIN] },
  documentTemplateDetails: { permissions: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_TEMPLATE_VIEW] },
  documentTemplateEdit: { permissions: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_TEMPLATE_ADMIN] },

  // Raporty
  reportCreate: { permissions: [PermissionEnum.REPORT_REPORT_TYPE_VIEW] },
  reportsList: { permissions: [PermissionEnum.REPORT_REPORT_TYPE_VIEW] },
  reportDetails: { permissions: [PermissionEnum.REPORT_REPORT_TYPE_VIEW] },
  reportEdit: { permissions: [PermissionEnum.REPORT_REPORT_TYPE_VIEW] },

  // ADMINISTRACJA

  // Jednostki organizacyjne
  organizationUnitsList: {
    permissions: [PermissionEnum.ORGANIZATION_STRUCTURE_VIEW]
  },
  organizationUnitDetails: { permissions: [PermissionEnum.ORGANIZATION_STRUCTURE_VIEW] },
  organizationUnitEdit: { permissions: [PermissionEnum.ORGANIZATION_STRUCTURE_ADMIN] },
  organizationUnitCreate: { permissions: [PermissionEnum.ORGANIZATION_STRUCTURE_ADMIN] },
  // Dla organizationUnitMemberDetails jest ten sam url co userDetails
  organizationUnitMemberDetails: { permissions: [PermissionEnum.USER_VIEW, PermissionEnum.ACCOUNT_OPERATOR_ADMIN] },

  // Użytkownicy
  usersList: {
    permissions: [PermissionEnum.USER_VIEW, PermissionEnum.ACCOUNT_OPERATOR_ADMIN],
    hasAllPermissions: true
  },
  userDetails: {
    permissions: [PermissionEnum.USER_VIEW, PermissionEnum.ACCOUNT_OPERATOR_ADMIN],
    hasAllPermissions: true
  },
  userEdit: {
    permissions: [PermissionEnum.USER_VIEW, PermissionEnum.USER_ADMIN, PermissionEnum.ACCOUNT_OPERATOR_ADMIN],
    hasAllPermissions: true
  },
  userCreate: {
    permissions: [PermissionEnum.USER_VIEW, PermissionEnum.USER_ADMIN, PermissionEnum.ACCOUNT_OPERATOR_ADMIN],
    hasAllPermissions: true
  },

  // Użytkownicy Portalu Klienta
  userClientPortalList: {
    permissions: [PermissionEnum.USER_VIEW, PermissionEnum.IBTM_DOMAIN_CLIENT_PORTAL_USER_LIST_VIEW],
    hasAllPermissions: true
  },
  userClientPortalDetails: {
    permissions: [PermissionEnum.USER_VIEW, PermissionEnum.IBTM_DOMAIN_CLIENT_PORTAL_USER_DETAILS_VIEW],
    hasAllPermissions: true
  },
  userClientPortalEdit: {
    permissions: [
      PermissionEnum.USER_VIEW,
      PermissionEnum.USER_ADMIN,
      PermissionEnum.IBTM_DOMAIN_CLIENT_PORTAL_USER_UPDATE
    ],
    hasAllPermissions: true
  },
  userClientPortalCreate: {
    permissions: [
      PermissionEnum.USER_VIEW,
      PermissionEnum.USER_ADMIN,
      PermissionEnum.IBTM_DOMAIN_CLIENT_PORTAL_USER_CREATE
    ],
    hasAllPermissions: true
  },

  // Grupy uprawnień
  groupsList: { permissions: [PermissionEnum.GROUP_VIEW] },
  groupCreate: { permissions: [PermissionEnum.GROUP_ADMIN] },
  groupDetails: { permissions: [PermissionEnum.GROUP_VIEW] },
  groupEdit: { permissions: [PermissionEnum.GROUP_ADMIN] },
  groupClone: { permissions: [PermissionEnum.GROUP_ADMIN] },

  // Słowniki
  dictionaryDetails: { permissions: [PermissionEnum.DICTIONARY_VIEW] },
  dictionariesList: { permissions: [PermissionEnum.DICTIONARY_VIEW] },

  // Komunikaty administracyjne
  adminMessagesList: { permissions: [PermissionEnum.MESSAGE_VIEW] },
  adminMessageCreate: {
    permissions: [PermissionEnum.MESSAGE_ADMIN],
    anyOfPermissions: [PermissionEnum.USER_VIEW, PermissionEnum.GROUP_VIEW]
  },
  adminMessageDetails: { permissions: [PermissionEnum.MESSAGE_VIEW] },

  // Konfiguracja powiadomień
  notificationsConfigurationList: { permissions: [PermissionEnum.ALERT_VIEW] },
  notificationConfigurationDetails: { permissions: [PermissionEnum.ALERT_VIEW] },
  notificationConfigurationEdit: {
    permissions: [PermissionEnum.ALERT_VIEW, PermissionEnum.ALERT_ADMIN],
    hasAllPermissions: true
  },
  notificationConfigurationCreate: {
    permissions: [PermissionEnum.ALERT_ADMIN, PermissionEnum.ALERT_VIEW],
    hasAllPermissions: true
  },

  // Powiadomienia
  messagesList: { permissions: [] },
  messageCreate: { permissions: [PermissionEnum.ALERT_ADMIN] }
};
