import {
  AdditionalSectionLicenseOrPermissionType,
  ApplicationMetaFormDefinition
} from '@libs/domain/application/model';

const definition: ApplicationMetaFormDefinition = {
  fields: [
    {
      typeKey: 'DICTIONARY',
      fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-causeDependentOnSubjectKey',
      properties: {
        label: 'Przyczyna wydania wtórnika',
        dictionaryName: 'DUPLICATE_CAUSE_DEPENDENT_ON_SUBJECT',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.duplicateLicenseApplicationDataUpdateRequest.causeDependentOnSubjectKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.duplicateLicenseApplicationDataDetails.causeDependentOnSubjectKey'
          }
        }
      }
    },
    {
      typeKey: 'BOOLEAN',
      fieldId: 'application-ceidg-available',
      properties: {
        label: 'Wpis w CEIDG?',
        api: {
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'ceidgAvailable'
          },
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'ceidgAvailable'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-lossCauseKey',
      properties: {
        label: 'Uprawnienie zniszczone/ utracone',
        dictionaryName: 'LOSS_CAUSE',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.duplicateLicenseApplicationDataUpdateRequest.lossCauseKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.duplicateLicenseApplicationDataDetails.lossCauseKey'
          }
        }
      }
    },
    {
      typeKey: 'DATETIME',
      fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-lossDate',
      properties: {
        label: 'Data utraty',
        yupValidation: {
          required: {},
          max: {
            date: 'TODAY',
            message: 'Data nie może być późniejsza niż dzisiejszy dzień'
          }
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.duplicateLicenseApplicationDataUpdateRequest.lossDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.duplicateLicenseApplicationDataDetails.lossDate'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-lossLocation',
      properties: {
        label: 'Miejsce utraty',
        maxLength: 100,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.duplicateLicenseApplicationDataUpdateRequest.lossLocation'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.duplicateLicenseApplicationDataDetails.lossLocation'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-lossLocation-required',
      properties: {
        label: 'Miejsce utraty',
        yupValidation: {
          required: true
        },
        maxLength: 100,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.duplicateLicenseApplicationDataUpdateRequest.lossLocation'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.duplicateLicenseApplicationDataDetails.lossLocation'
          }
        }
      }
    },
    {
      typeKey: 'AUTOCOMPLETE',
      fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-licenseDuplicates',
      properties: {
        label: 'Licencja w zakresie pośrednictwa przy przewozie osób',
        multiple: true,
        yupValidation: {
          required: true
        },
        api: {
          fetch: {
            requestKey: 'GET_LICENSES_TO_DUPLICATE_PB31',
            accessor: 'content',
            optionTextMatch: '[form.fullNumber]',
            backendFilterParamName: 'licenseNumberContains'
          },
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'licenseDataUpdateRequest.duplicateLicenseApplicationDataUpdateRequest.licenseDuplicates',
            formAccessor: 'id'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'licenseApplicationDetails.duplicateLicenseApplicationDataDetails.licenseDuplicates'
          }
        }
      }
    }
  ],
  layout: [
    {
      typeKey: 'TAB_NAVIGATION',
      properties: {
        visibility: {
          elementVisibilityKeys: ['TAB_VISIBILITY']
        },
        defaultTabId: 'GENERAL',
        title: 'Sekcje',
        tabs: [
          {
            id: 'GENERAL',
            title: 'Dane podstawowe',
            icon: 'InfoIcon'
          },
          {
            id: 'SUBJECT',
            title: 'Podmiot',
            icon: 'FacadeIcon'
          },
          {
            id: 'ADDRESSES',
            title: 'Adresy',
            icon: 'MailboxIcon'
          },
          {
            id: 'PERMISSIONS',
            title: 'Uprawnienia',
            icon: 'KeyIcon'
          },
          {
            id: 'PROXIES',
            title: 'Pełnomocnicy',
            icon: 'GroupOfPeopleIcon'
          },
          {
            id: 'ATTACHMENTS',
            title: 'Załączniki',
            icon: 'PaperclipIcon'
          },
          {
            id: 'QUOTATION',
            title: 'Wycena',
            icon: 'CoinsIcon'
          },
          {
            id: 'OUTGOING_DOCUMENTS',
            title: 'Dokumenty wychodzące',
            icon: 'DocumentsIssuedIcon'
          },
          {
            id: 'NOTE',
            title: 'Notatka',
            icon: 'NoteIcon'
          },
          {
            id: 'FILES',
            title: 'Pliki',
            icon: 'FileEmptyIcon'
          },
          {
            id: 'HISTORY',
            title: 'Historia',
            icon: 'HistoryIcon'
          }
        ]
      },
      nodes: [
        {
          typeKey: 'TABS_CONTENT',
          nodes: [
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-general-tab',
                additionalNodes: {
                  'additional-section': [
                    {
                      typeKey: 'SECTION',
                      properties: {
                        title: 'Wniosek / Terminy'
                      },
                      nodes: [
                        {
                          typeKey: 'GRID',
                          properties: {
                            gridItemProps: {
                              xs: 12,
                              sm: 6
                            }
                          },
                          nodes: [
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId:
                                  'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-causeDependentOnSubjectKey'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'application-ceidg-available'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-lossCauseKey'
                              }
                            },
                            {
                              typeKey: 'CONDITIONALLY_VISIBLE',
                              properties: {
                                dependendFieldId:
                                  'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-lossCauseKey',
                                value: {
                                  includes: ['ibtm-domain.application.license.loss-cause.destroyed']
                                }
                              },
                              nodes: [
                                {
                                  typeKey: 'FIELD',
                                  properties: {
                                    fieldId:
                                      'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-lossLocation'
                                  }
                                }
                              ]
                            },
                            {
                              typeKey: 'CONDITIONALLY_VISIBLE',
                              properties: {
                                dependendFieldId:
                                  'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-lossCauseKey',
                                value: {
                                  includes: ['ibtm-domain.application.license.loss-cause.lost']
                                }
                              },
                              nodes: [
                                {
                                  typeKey: 'FIELD',
                                  properties: {
                                    fieldId:
                                      'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-lossLocation-required'
                                  }
                                }
                              ]
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId:
                                  'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-licenseDuplicates'
                              }
                            },
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'licenseApplicationDetails-duplicateLicenseApplicationDataDetails-lossDate'
                              }
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-subject-tab',
                additionalNodes: {
                  'application-section-end': [
                    {
                      typeKey: 'COMPONENT',
                      properties: {
                        componentId: 'application-subject-print-data-license-section'
                      }
                    },
                    {
                      typeKey: 'COMPONENT',
                      properties: {
                        componentId: 'application-subject-print-data-permission-section'
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-addresses-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-permissions-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-proxies-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-attachments-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-quotation-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-outgoing-documents-tab',
                additionalNodes: {
                  'outgoing-documents-additional-section': [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'application-outgoing-documents-additional-section-license-or-permission',
                        sectionTypes: [AdditionalSectionLicenseOrPermissionType.LICENSE]
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-note-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-files-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-history-tab'
              }
            }
          ]
        }
      ]
    }
  ]
};

export default definition;
