import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportDetailsSnapshot } from '@stack/report';
import _ from 'lodash';

import { Section } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';
import { DictionaryEntryNameEnum } from '@libs/dictionary/models/dictionaryEntryNameEnum';

interface IProps {
  report?: ReportDetailsSnapshot;
  isLoading: boolean;
  isFetching: boolean;
}

export function ErrorSection({ report, isLoading, isFetching }: IProps) {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  const errors = useMemo(() => {
    const dataSets = report?.dataSets;
    const mainErrorMessage = report?.errorMessage;
    const mainErrorCode = report?.errorCode;

    if (dataSets) {
      const datasetErrors =
        dataSets
          .filter(({ failureDetails }) => failureDetails)
          .map(({ failureDetails, name }) => ({
            title: t('reports:errorSection.dataSetError', { name }),
            message: failureDetails
          })) || [];
      const hasMainError = mainErrorMessage || mainErrorCode;
      return [
        ...datasetErrors,
        ...(hasMainError
          ? [
              {
                title: t('reports:errorSection.mainError'),
                message: `${mainErrorMessage ? `${mainErrorMessage}\n` : ''}${
                  translate(DictionaryEntryNameEnum.ERROR, mainErrorCode) ?? ''
                }`
              }
            ]
          : [])
      ];
    }
    return [];
  }, [translate, report, t]);

  const hasErrors = useMemo(() => !_.isEmpty(errors), [errors]);

  return (
    (hasErrors || isLoading) && (
      <Section title={t('reports:errorSection.title')} isLoading={isLoading || isFetching}>
        {errors?.map(({ title, message }) => (
          <Section title={title} key={title} isCollapsed={false} isCollapsable isModalSection>
            {message}
          </Section>
        ))}
      </Section>
    )
  );
}
