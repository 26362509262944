import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { GroupSnapshotExternal } from '@avispon/group/dist/models';
import {
  ExternalNamedObject,
  OrganizationUnitMemberSnapshotExternal
} from '@avispon/organization-structure/dist/models';
import { useSnackbar } from '@enigma/fe-ui';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import { partialTranslate } from '@libs/common';
import { TableButtonDelete, useConfirmDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { unescapeValue } from '@libs/common/v2/utils';

import { OrganizationQueryKeysEnum, useUnassignGroupMemberMutation } from '@libs/organization-structure/api';

import { OrganizationStructureDetailsParams } from '../../models';

function OpenDeleteGroupMemberPopUp({
  original,
  selectedGroup
}: {
  original: OrganizationUnitMemberSnapshotExternal;
  selectedGroup: GroupSnapshotExternal;
}) {
  const queryCache = useQueryCache();
  const snackbar = useSnackbar();
  const [confirm] = useConfirmDialog();
  const { id: unitId } = useParams<OrganizationStructureDetailsParams>();
  const [t] = useTranslation();
  const getFieldLabel = partialTranslate('administration:organizationUnit.message');

  const { mutateAsync: unassignGroupMember } = useUnassignGroupMemberMutation();

  const handleDelete = (user: ExternalNamedObject) => {
    confirm({
      title: 'Usuwanie',
      message: getFieldLabel(
        'memberDeleteFromGrupConfirmation',
        unescapeValue({
          userName: user?.name,
          groupName: selectedGroup?.name
        })
      ),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        const requestParameters = {
          groupId: selectedGroup?.id,
          unitId,
          userIds: [user?.id]
        };
        unassignGroupMember(requestParameters, {
          onSuccess: () => {
            snackbar.showSnackbar('success', getFieldLabel('memberDeletedFromGroupSuccess'));
            closeDialog();
          }
        })
          .then(() => {
            queryCache.invalidateQueries(OrganizationQueryKeysEnum.SEARCH_GROUP_LIST);
            closeDialog();
          })
          .catch(() => {
            snackbar.showSnackbar('error', t('dialog.deleteFailure'));
            closeDialog();
          });
      },
      confirmType: 'danger'
    });
  };

  return (
    <TableButtonDelete
      onClick={() => handleDelete(original?.user)}
      actionKey={UIElementNameEnum.USERS_GROUP_USERS_DELETE}
    />
  );
}

export default OpenDeleteGroupMemberPopUp;
