import { useCallback, useMemo } from 'react';
import { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';

import { useFileFetch } from '@libs/file';

export function getFilenameFromHeader(response: AxiosResponse): string {
  const contentDisposition = (response.headers['content-disposition'] as string) || '';
  const matches = /filename=([^;]+)/gi.exec(contentDisposition);
  return matches && matches.length > 1 ? matches[1].trim().replaceAll('"', '') : 'untitled';
}

interface FileDownloadHookResult {
  download: (fetchQuery: () => Promise<AxiosResponse>, afterFileDownload?: (file: File) => void) => Promise<void>;
  isLoading?: boolean;
}

function useFileDownload(): FileDownloadHookResult {
  const { fetch, isLoading } = useFileFetch();

  const download = useCallback(
    async (fetchQuery: () => Promise<AxiosResponse>, afterFileDownload) => {
      const file = await fetch(fetchQuery);
      const fileName = getFilenameFromHeader(file);
      FileSaver.saveAs(file.data, fileName);

      afterFileDownload?.(file.data);
    },
    [fetch]
  );

  return useMemo(
    () => ({
      download,
      isLoading
    }),
    [download, isLoading]
  );
}

export default useFileDownload;
