import { PaletteOptions, ThemeFontSizeEnum, TypographyOptions } from '@libs/common/v2/theme';
import { hexToRgba, important } from '@libs/common/v2/utils';

import initialTheme from './initialTheme';

type TypographyParams = (
  fontWieght: number,
  lineHeight: number,
  fontSize: number
) => {
  fontFamily: string;
  fontWeight: number;
  fontSize: string;
  lineHeight: string;
};

const setTypographyStyles = (setTypographyStyle: TypographyParams): TypographyOptions => ({
  display2xl: {
    regular: setTypographyStyle(400, 90, 72),
    medium: setTypographyStyle(500, 90, 72),
    semibold: setTypographyStyle(600, 90, 72),
    bold: setTypographyStyle(700, 90, 72)
  },
  displayXl: {
    normal: setTypographyStyle(400, 72, 60),
    medium: setTypographyStyle(500, 72, 60),
    semibold: setTypographyStyle(600, 72, 60),
    bold: setTypographyStyle(700, 72, 60)
  },
  displayLg: {
    normal: setTypographyStyle(400, 60, 48),
    medium: setTypographyStyle(500, 60, 48),
    semibold: setTypographyStyle(600, 60, 48),
    bold: setTypographyStyle(700, 60, 48)
  },
  displayMd: {
    normal: setTypographyStyle(400, 44, 36),
    medium: setTypographyStyle(500, 44, 36),
    semibold: setTypographyStyle(600, 44, 36),
    bold: setTypographyStyle(700, 44, 36)
  },
  displaySm: {
    normal: setTypographyStyle(400, 38, 30),
    medium: setTypographyStyle(500, 38, 30),
    semibold: setTypographyStyle(600, 38, 30),
    bold: setTypographyStyle(700, 38, 30)
  },
  displayXs: {
    normal: setTypographyStyle(400, 32, 24),
    medium: setTypographyStyle(500, 32, 24),
    semibold: setTypographyStyle(600, 32, 24),
    bold: setTypographyStyle(700, 32, 24)
  },
  textXl: {
    normal: setTypographyStyle(400, 30, 20),
    medium: setTypographyStyle(500, 30, 20),
    semibold: setTypographyStyle(600, 30, 20),
    bold: setTypographyStyle(700, 30, 20)
  },
  textLg: {
    normal: setTypographyStyle(400, 28, 18),
    medium: setTypographyStyle(500, 28, 18),
    semibold: setTypographyStyle(600, 28, 18),
    bold: setTypographyStyle(700, 28, 18)
  },
  textMd: {
    normal: setTypographyStyle(400, 24, 16),
    medium: setTypographyStyle(500, 24, 16),
    semibold: setTypographyStyle(600, 24, 16),
    bold: setTypographyStyle(700, 24, 16)
  },
  textSm: {
    normal: setTypographyStyle(400, 20, 14),
    medium: setTypographyStyle(500, 20, 14),
    semibold: setTypographyStyle(600, 20, 14),
    bold: setTypographyStyle(700, 20, 14),
    normalWrap: setTypographyStyle(700, 4, 14)
  },
  textXs: {
    normal: setTypographyStyle(400, 18, 12),
    medium: setTypographyStyle(500, 18, 12),
    semibold: setTypographyStyle(600, 18, 12),
    bold: setTypographyStyle(700, 18, 12)
  }
});

/**
 * Funkcja rozszerza theme z dodatkowymi zmianami (initialTheme). Tutaj możemy zmieniać globalne style komponentów Mui.
 */
export default function customTheme(palette: PaletteOptions, currentFontSize: ThemeFontSizeEnum) {
  const setTypographyStyle = (fontWieght: number, lineHeight: number, fontSize: number) => ({
    fontFamily: 'Inter',
    fontWeight: fontWieght,
    fontSize: initialTheme.typography.pxToRem(Math.ceil(fontSize * currentFontSize)),
    lineHeight: initialTheme.typography.pxToRem(Math.ceil(lineHeight * currentFontSize))
  });

  const typography = setTypographyStyles(setTypographyStyle);
  const shadowsList = {
    xs: `0px 1px 2px ${hexToRgba(palette.grey[900], 0.05)}`,
    sm: `0px 1px 3px ${hexToRgba(palette.grey[900], 0.1)}, 0px 1px 2px ${hexToRgba(palette.grey[900], 0.06)}`,
    md: `0px 4px 8px -2px ${hexToRgba(palette.grey[900], 0.1)}, 0px 2px 4px -2px ${hexToRgba(palette.grey[900], 0.06)}`,
    lg: `0px 12px 16px -4px ${hexToRgba(palette.grey[900], 0.08)}, 0px 4px 6px -2px ${hexToRgba(
      palette.grey[900],
      0.03
    )}`,
    xl: `0px 20px 24px -4px ${hexToRgba(palette.grey[900], 0.08)}, 0px 8px 8px -4px ${hexToRgba(
      palette.grey[900],
      0.03
    )}`,
    '2xl': `0px 24px 48px -12px ${hexToRgba(palette.grey[900], 0.18)}`,
    '3xl': `0px 32px 64px -12px ${hexToRgba(palette.grey[900], 0.14)}`,
    inputHover: `0px 1px 2px ${hexToRgba(palette.grey[900], 0.05)}, 0px 0px 0px 4px ${palette.grey[100]}`,
    inputFocus: `0px 1px 2px ${hexToRgba(palette.grey[900], 0.05)}, 0px 0px 0px 4px ${palette.blueLight[100]}`,
    buttonFocus: `0px 1px 2px ${hexToRgba(palette.grey[900], 0.05)}, 0px 0px 0px 4px #F4EBFF`,
    outlinedButtonFocus: `0px 1px 2px ${hexToRgba(palette.grey[900], 0.05)}, 0px 0px 0px 4px #F2F4F7`
  };

  return {
    ...initialTheme,
    typography: {
      ...initialTheme.typography,
      ...typography,
      fontFamily: ['Inter'],
      getFontSize: (fontSize: number) => initialTheme.typography.pxToRem(Math.ceil(currentFontSize * fontSize))
    },
    fieldsOptions: {
      requireStartLocalization: 'left',
      mode: 'portal'
    },
    palette,
    shadowsList,
    borderRadiusBase: 8,
    marginBase: 6,
    gridDefaultSpacing: 3,

    components: {
      MuiCssBaseline: {
        styleOverrides: { '*, *::before, *::after': { boxSizing: 'unset' }, h1: { margin: 0 }, p: { margin: 0 } }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            whiteSpace: 'unset'
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: palette.grey[700]
          },
          h1: typography.display2xl.regular,
          h2: typography.displayXl.normal,
          h3: typography.displayLg.normal,
          h4: typography.displayMd.normal,
          h5: typography.displaySm.normal,
          h6: typography.displayXs.normal,
          subtitle1: typography.textXl.normal,
          subtitle2: typography.textLg.normal,
          body1: typography.textMd.normal,
          body2: typography.textSm.normal,
          caption: typography.textXs.normal
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderRadius: 8,
            ...typography.textSm.medium,
            backgroundColor: palette.primary[900],
            color: palette.white,
            boxShadow: shadowsList.xs,
            minWidth: '120px',
            '&:hover': {
              backgroundColor: palette.primary[700],
              borderColor: palette.primary[700]
            },
            '&:focus': {
              backgroundColor: palette.primary[600],
              boxShadow: shadowsList.buttonFocus
            },
            '&.Mui-disabled': {
              backgroundColor: palette.primary[200],
              color: palette.white
            }
          },
          containedPrimary: {
            backgroundColor: palette.primary[900],
            color: palette.white,
            '&:hover': {
              backgroundColor: palette.primary[700],
              borderColor: palette.primary[700]
            },
            '&:focus': {
              backgroundColor: palette.primary[600],
              boxShadow: shadowsList.buttonFocus
            },
            '&.Mui-disabled': {
              backgroundColor: palette.primary[200],
              color: palette.white
            }
          },
          outlinedPrimary: {
            backgroundColor: palette.white,
            border: `1px solid ${palette.grey[300]}`,
            color: palette.grey[700],
            '&:hover': {
              backgroundColor: palette.grey[50],
              borderColor: palette.grey[300]
            },
            '&:focus': {
              backgroundColor: palette.white,
              boxShadow: shadowsList.outlinedButtonFocus
            },
            '&.Mui-disabled': {
              backgroundColor: palette.white,
              color: palette.grey[300],
              borderColor: palette.grey[200]
            }
          },
          textPrimary: {
            backgroundColor: 'unset',
            color: palette.grey[700],
            '&:hover, &:focus': {
              backgroundColor: palette.grey[300],
              borderColor: palette.grey[300]
            }
          },
          containedSecondary: {
            backgroundColor: palette.white,
            color: palette.primary[900],
            border: `2px solid ${palette.primary[500]}`,
            '&:hover': {
              backgroundColor: palette.primary[200],
              borderColor: palette.primary[200]
            },
            '&:focus': {
              backgroundColor: palette.primary[200]
            }
          },
          outlinedSecondary: {
            backgroundColor: palette.white,
            border: `1px solid ${palette.grey[300]}`,
            color: palette.grey[700],
            '&:hover': {
              border: `1px solid ${palette.grey[300]}`,
              backgroundColor: palette.grey[100]
            },
            '&:focus': {
              backgroundColor: palette.grey[100]
            }
          },
          textSecondary: {
            backgroundColor: 'unset',
            color: palette.primary[900],
            '&:hover, &:focus': {
              backgroundColor: palette.primary[50]
            }
          },
          containedError: {
            backgroundColor: palette.error[600],
            color: palette.white,
            '&:hover': {
              backgroundColor: palette.error[700]
            },
            '&:focus, &:disabled': {
              backgroundColor: palette.error[500]
            }
          },
          outlinedError: {
            backgroundColor: palette.white,
            border: `1px solid ${palette.error[300]}`,
            color: palette.error[700],
            '&:hover': {
              border: `1px solid ${palette.error[300]}`,
              backgroundColor: palette.error[100]
            },
            '&:focus, &:disabled': {
              backgroundColor: palette.error[100]
            }
          },
          textError: {
            backgroundColor: 'unset',
            color: palette.error[900],
            '&:hover, &:focus': {
              backgroundColor: palette.error[50]
            }
          },
          text: {
            backgroundColor: 'unset',
            color: palette.grey[700],
            '&:hover, &:focus': {
              backgroundColor: palette.grey[300]
            }
          },
          small: { ...typography.textSm.medium, height: 36, width: 'auto', padding: '8px 14px' },
          medium: { ...typography.textSm.medium, height: 40, width: 'auto', padding: '10px 16px' },
          large: { ...typography.textMd.medium, height: 44, width: 'auto', padding: '10px 18px' },
          xl: { ...typography.textMd.medium, height: 48, width: 'auto', padding: '12px 20px' },
          xxl: { ...typography.textLg.medium, height: 60, width: 'auto', padding: '16px 28px' }
        }
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          root: {
            letterSpacing: 0.75,
            ...typography.textXs.normal
          },
          separator: {
            marginLeft: 6,
            marginRight: 6
          }
        }
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover'
        },
        styleOverrides: {
          root: {
            color: 'unset'
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: palette.grey[50],
            backgroundImage: 'unset',
            ...typography.textSm.normal
          },
          rounded: {
            borderRadius: '6px'
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            paddingTop: 11,
            paddingBottom: 11,
            ...typography.textXs.normal,
            '&:hover, &:focus': { backgroundColor: hexToRgba(palette.grey[500], 0.1) }
          }
        }
      },
      MuiList: {
        styleOverrides: {
          padding: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            backgroundColor: palette.grey[50],
            ...typography.textXs.normal,
            color: palette.grey[700]
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            ...typography.textXs.normal,
            color: palette.grey[100],
            padding: '2px 6px'
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            ...typography.textXs.normal,
            padding: 9,
            background: palette.white
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            ...typography.textXs.normal
          },
          underline: {
            padding: 0
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          input: {
            padding: important('9.5px 4px'),
            paddingLeft: 6,
            marginRight: 20,
            '&:hover, &:focus': {
              marginRight: 20
            },
            ...typography.textXs.normal
          },
          endAdornment: {
            right: 7
          }
        }
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            ...typography.textSm.normal
          },
          item: {
            '&:empty': { display: 'none' }
          }
        }
      },
      MuiGridContainer: {
        styleOverrides: { gapRow: '100 !important' }
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            height: '48px',
            ...typography.textSm.normal
          }
        }
      },
      // Table styles
      MuiTableContainer: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            borderRadius: '6px',
            borderWidth: 1,
            ...typography.textSm.normal
          }
        }
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            position: 'sticky',
            top: 0,
            ...typography.textSm.normal
          }
        }
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            ...typography.textSm.normal,
            '& > div:last-child > div': {
              borderWidth: 0
            }
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            padding: 0,
            alignItems: 'center',
            ...typography.textSm.normal
          },
          head: {
            padding: 0,
            borderColor: palette.grey[200]
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            border: important('none'),
            padding: 0,
            display: important('flex')
          },
          head: {
            height: 52,
            ...typography.textXs.normal,
            letterSpacing: '0.5px'
          },
          body: {
            height: 48,
            ...typography.textSm.normal,
            letterSpacing: 0.9,
            paddingLeft: important('0')
          },
          sizeSmall: {
            height: 32,
            padding: 0
          }
        }
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            ...typography.textXs.normal
          },
          icon: {
            color: 'inherit',
            fill: 'inherit'
          }
        }
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            paddingLeft: 32,
            ...typography.textSm.normal
          },
          caption: {
            ...typography.textXs.normal,
            letterSpacing: '0.9px',
            textAlign: 'right'
          },
          toolbar: {
            paddingRight: 0
          },
          actions: {
            marginLeft: 24,
            '& > button': {
              padding: 8
            }
          }
        }
      },
      /**
       * @todo dostosowanie stylu do nowej makiety - jeżeli będzie trzeba.
       */
      /**
       * @todo Dostosowanie formularza.
       */
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            padding: '9.5px 4px',
            paddingLeft: 6
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            ...typography.textXs.normal
          }
        }
      },
      /**
       * @todo Dostosowanie Dialog do makiety
       */
      /// Dialog styles
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            margin: 0,
            padding: 0,
            display: 'flex',
            alignItems: 'center'
          }
        }
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: 0,
            margin: 0,
            ...typography.textSm.normal
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
            ...typography.textSm.normal
          }
        }
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: palette.grey[100]
            },
            '&:focus': {
              backgroundColor: palette.grey[200]
            },
            '&.Mui-selected': {
              backgroundColor: palette.blue[600],
              color: important(palette.white),
              '&:hover': {
                backgroundColor: palette.blue[400]
              },
              '&:focus': {
                backgroundColor: palette.blue[500]
              }
            }
          }
        }
      },
      MuiPickersMonth: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: important(palette.grey[100])
            },
            '&:focus': {
              backgroundColor: important(palette.grey[200])
            },
            '& .Mui-selected': {
              backgroundColor: important(palette.blue[600]),
              color: important(palette.white),
              '&:hover': {
                backgroundColor: important(palette.blue[400])
              },
              '&:focus': {
                backgroundColor: important(palette.blue[500])
              }
            }
          }
        }
      },
      MuiPickersYear: {
        styleOverrides: {
          root: {
            width: '65px',
            '&:hover': {
              backgroundColor: important(palette.grey[100])
            },
            '&:focus': {
              backgroundColor: important(palette.grey[200])
            },
            '& .Mui-selected': {
              backgroundColor: important(palette.blue[600]),
              color: important(palette.white),
              '&:hover': {
                backgroundColor: important(palette.blue[400])
              },
              '&:focus': {
                backgroundColor: important(palette.blue[500])
              }
            }
          }
        }
      },
      MuiPickersToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: palette.blue[500],
            borderBottom: `1px solid ${palette.blue[50]}`,
            '& > span': {
              ...typography.textMd.normal,
              color: palette.grey[700]
            }
          }
        }
      },
      MuiClock: {
        styleOverrides: {
          clock: {
            backgroundColor: palette.grey[200]
          },
          pin: {
            backgroundColor: palette.blue[700]
          }
        }
      },
      MuiClockPointer: {
        styleOverrides: {
          root: {
            backgroundColor: palette.blue[700],
            '& > div': {
              backgroundColor: palette.blue[700],
              borderColor: palette.blue[700]
            }
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            padding: 0,
            minWidth: '120px',
            ...typography.textXs.normal,
            letterSpacing: '0.75px',
            textTransform: 'none'
          },

          wrapper: {
            display: 'flex',
            flexDirection: 'row',
            '& svg': {
              marginBottom: 0
            }
          }
        }
      }
    }
  } as any;
}
