import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { AttachmentVerificationListDetails, AttachmentVerificationListUpdateRequest } from '@ibtm/domain';
import * as Yup from 'yup';

import { FormV2Context, GridLayout, HintsAutocompleteField, typedNameV2 } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { DictionaryEntryNameEnum } from '@libs/dictionary';

import { useAddCommentMutation } from '../../api';
import { AttachmentsQueryKeysEnum } from '../../api/queries';
import { IAttachmentListItemClient } from '../../model';

interface IProps {
  applicationId: string;
  data: IAttachmentListItemClient;
  dictionaryKey?: DictionaryEntryNameEnum;
  onLoading: (val: boolean) => void;
  onSuccess: () => void;
}

function AttachmentCommentForm({ applicationId, data, dictionaryKey, onLoading, onSuccess }: IProps, ref) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();

  const { mutate: addComment, isLoading } = useAddCommentMutation();

  const lastQuery = queryCache.getQuery([
    AttachmentsQueryKeysEnum.ATTACHMENTS_LIST,
    {
      applicationId
    }
  ]);
  const queryData = queryCache.getQueryData(lastQuery.queryKey);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    reset
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(
      Yup.object({
        notes: Yup.string().nullable().required()
      })
    )
  });

  useEffect(() => {
    if (data) {
      reset(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit = (formData: IAttachmentListItemClient) => {
    onLoading(true);

    const lastData = queryData as AttachmentVerificationListDetails;

    const requestData: {
      applicationId: string;
      attachmentVerificationListUpdateRequest: AttachmentVerificationListUpdateRequest;
    } = {
      applicationId,
      attachmentVerificationListUpdateRequest: {
        notes: lastData.notes,
        version: lastData.version,
        verificationElements: lastData.verificationElements.map(item => {
          if (item.id === data.id) {
            return {
              ...item,
              notes: formData.notes
            };
          }

          return item;
        })
      }
    };

    addComment(requestData, {
      onSuccess: () => {
        showSuccessSnackbar(t('applications:attachments.messages.commentAddedSuccess'));
        onLoading(false);

        queryCache.invalidateQueries(AttachmentsQueryKeysEnum.ATTACHMENTS_LIST);
        onSuccess();
      },
      onError: () => {
        onLoading(false);
      }
    });
  };

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit(data => onSubmit(data as IAttachmentListItemClient))();
    }
  }));

  const values = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      loading: isLoading
    }),
    [control, errors, getValues, isLoading, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <FormV2Context.Provider value={values}>
      <form>
        <GridLayout itemProps={{ xs: 12 }}>
          <HintsAutocompleteField
            name={typedNameV2<IAttachmentListItemClient>('notes')}
            label={t('applications:attachments.fields.notes')}
            dictionaryName={dictionaryKey}
            lines={5}
            required
          />
        </GridLayout>
      </form>
    </FormV2Context.Provider>
  );
}

export default forwardRef(AttachmentCommentForm);
