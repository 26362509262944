import React from 'react';

import { FormMode } from '@libs/common/v2/form/model';

import { PermissionEnum } from '@libs/domain/config/permission/PermissionEnum';

const AlertsListPage = React.lazy(() => import('./AlertsListPage'));
const AlertDetailsPage = React.lazy(() => import('./AlertDetailsPage'));

export const AlertsConfig = {
  routes: [
    {
      path: '/administration/notifications-configuration',
      auth: () => [PermissionEnum.ALERT_VIEW],
      element: <AlertsListPage />
    },
    {
      path: '/administration/notifications-configuration/create',
      auth: () => [PermissionEnum.ALERT_ADMIN],
      element: <AlertDetailsPage formMode={FormMode.CREATE} />
    },
    {
      path: '/administration/notifications-configuration/edit/:id',
      auth: () => [PermissionEnum.ALERT_ADMIN],
      element: <AlertDetailsPage formMode={FormMode.EDIT} />
    },
    {
      path: '/administration/notifications-configuration/details/:id',
      auth: () => [PermissionEnum.ALERT_VIEW],
      element: <AlertDetailsPage formMode={FormMode.VIEW} />
    }
  ]
};
