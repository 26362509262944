import { usePaginatedQuery } from 'react-query';
import { ResourceObjectAssociationPoolSearchFilter } from '@ibtm/client-domain';

import { API } from '@libs/domain/api';

import { IResourceObjectAssociationPoolClient } from '../../../model';
import { resourceObjectAssociationPoolsParser } from '../../../utils';
import { endpointsConfig } from '../../endpoints-config';
import { ResourceQueryKeysEnum } from '..';

const getResourceObjectAssociationPools = async (params: ResourceObjectAssociationPoolSearchFilter) => {
  const { data } = await API.client.resourceObjects.getResourceObjectAssociationPoolSnapshot('', params, {
    ...endpointsConfig.getResourceObjectAssociationPoolSnapshot
  });

  return {
    ...data,
    content: resourceObjectAssociationPoolsParser(data.content)
  };
};

export default function useResourceObjectAssociationPoolsQuery(params: ResourceObjectAssociationPoolSearchFilter) {
  return usePaginatedQuery<{ content: IResourceObjectAssociationPoolClient[] }>(
    [ResourceQueryKeysEnum.RESOURCE_OBJECT_ASSOCIATION_POOLS, params],
    () => getResourceObjectAssociationPools(params)
  );
}
