import { useMutation } from 'react-query';
import { ApplicationShippingDataUpdateRequest } from '@ibtm/client-domain';
import { SCertificateApplicationDataUpdateRequest } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export function editSCertificate({
  applicationId,
  formData
}: {
  applicationId: string;
  formData: SCertificateApplicationDataUpdateRequest;
}) {
  return API.application.updateSCertificateApplication(applicationId, formData, {
    ...endpointsConfig.updateSCertificateApplication
  });
}

export function editClientSCertificateClient({
  applicationId,
  formData
}: {
  applicationId: string;
  formData: ApplicationShippingDataUpdateRequest;
}) {
  return API.client.application.updateApplicationShippingData(applicationId, '', '', formData, {
    ...endpointsConfig.updateSCertificateApplication
  });
}

function useEditSCertificateMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(editClientSCertificateClient, editSCertificate));
}

export default useEditSCertificateMutation;
