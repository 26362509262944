import { DocumentTemplateEditRequest } from '@avispon/document-generator/dist/models';
import { AxiosRequestConfig } from 'axios';

import { useMutation, useQueryCache } from '@libs/common/v2/api';

import { API, DocumentTemplateQueryKeysEnum, endpointsConfig } from '..';

interface IParams {
  documentTemplateId: string;
  body?: DocumentTemplateEditRequest;
}

const editDocumentTemplate = async ({ documentTemplateId, body }: IParams) => {
  const { data } = await API.documentGenerator.updateTemplate({ documentTemplateId, body }, {
    ...endpointsConfig.updateTemplate
  } as AxiosRequestConfig);
  return data;
};

export default function useEditDocumentTemplateMutation({ onSuccess, onError }) {
  const queryCache = useQueryCache();
  return useMutation(editDocumentTemplate, {
    onSuccess: (...args) => {
      queryCache.invalidateQueries(DocumentTemplateQueryKeysEnum.DOCUMENT_TEMPLATES);
      queryCache.invalidateQueries(DocumentTemplateQueryKeysEnum.DOCUMENT_TEMPLATE_DETAILS);
      queryCache.invalidateQueries(DocumentTemplateQueryKeysEnum.DOCUMENT_TEMPLATE_VERSIONS);
      onSuccess(...args);
    },
    onError
  });
}
