import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DefaultApiAssignMemberRequest } from '@avispon/group';
import { GroupMembershipDetailsExternal } from '@avispon/group/dist/models';
import { useSnackbar } from '@enigma/fe-ui';
import { makeStyles } from '@mui/styles';

import { TableLayout } from '@libs/common/v2';
import { calc, important } from '@libs/common/v2/utils/styles.utils';

import { API, useAddUserToPermissionGroupsMutation } from '@libs/user/api';
import { UserGroupFormHeaderActions, UserGroupFormRowActions } from '@libs/user/components';
import { usePermissionGroupsTable } from '@libs/user/hooks';
import { IUserClientListParams } from '@libs/user/models';

import AddUserToGroupModal from './AddUserToGroupModal';

interface UserGroupFormProps {
  canAddGroup: boolean;
}

function UserGroupForm({ canAddGroup }: UserGroupFormProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { id: userId } = useParams<IUserClientListParams>();

  const [isModalOpen, setModalIsOpen] = useState<boolean>(false);
  const ref = useRef<{ refetchTable: () => void; clearTable: () => void }>(null);
  const { mutate: addUserToPermissionGroupsMutation, isLoading } = useAddUserToPermissionGroupsMutation();

  const classes = useStyles();

  const tableProps = usePermissionGroupsTable(userId);

  const handleAddUserToGroup = useCallback(
    ({ unitId, includeSubordinates }, selection) => {
      const groupIds = selection.map(item => item.id);
      const params: DefaultApiAssignMemberRequest = {
        userId,
        body: {
          groupIds,
          unitId,
          includeSubordinates
        }
      };
      addUserToPermissionGroupsMutation(params, {
        onSuccess: () => {
          showSuccessSnackbar(t('user:dialog.createGroupMembershipSuccess'));
          ref.current?.clearTable();
          ref.current?.refetchTable();
          setModalIsOpen(false);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <TableLayout<GroupMembershipDetailsExternal, GroupMembershipDetailsExternal>
        {...tableProps}
        pageTitle={t('user:table.permissionsGroup')}
        headerActions={
          <UserGroupFormHeaderActions
            ref={ref}
            setModalIsOpen={setModalIsOpen}
            canAddGroup={canAddGroup}
            isLoading={isLoading}
          />
        }
        rowActions={({ original }) => <UserGroupFormRowActions original={original} />}
        xlsxDownload={{
          fileName: t('user:table.permissionsGroup'),
          pathToXLSXTranslation: 'user:groupField',
          apiRequest: params =>
            API.group.listUserGroupMemberships({
              userId,
              includeSubordinates: !params.unitIds,
              includeActiveReplacements: true,
              ...params
            })
        }}
        sectionContentClassName={classes.sectionContent}
        isSection
      />
      {isModalOpen && (
        <AddUserToGroupModal
          open={isModalOpen}
          setIsOpen={setModalIsOpen}
          onSave={handleAddUserToGroup}
          isLoading={isLoading}
        />
      )}
    </>
  );
}

const useStyles = makeStyles({
  sectionContent: {
    height: important(calc('100% - 108px'))
  }
});

export default UserGroupForm;
