import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { QueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { redirect } from 'react-router-dom';
import { AccessTokenCreateRequest } from '@avispon/auth/dist/models';
import { useAppConfig } from '@libs/app-config';
import { isEmpty } from 'lodash';
import { AnyAction, Dispatch } from 'redux';

import { useOverrideSystemParameters } from '@libs/common/v2';
import { removeLocalStorageItem, setLocalStorageItem } from '@libs/common/v2/utils';

import { AuthService } from '@libs/auth/services';

import { AuthLocalStorageItem, AutoLogoutStorageItemEnum } from '../models';
import { getUserData } from '../store/actions';
import { useAuthSelector } from '../store/reducers';

enum GetUserStateTypeEnum {
  DEFAULT,
  LITE
}

const initialState = {
  success: false,
  error: {
    username: null,
    password: null,
    status: null
  }
};

export type InitialLoginState = typeof initialState;

export const USER_LOGGED_OUT = '[USER] LOGGED OUT';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

const useAuth = () => {
  const [isLoginLoading, setIsLoginLoading] = useState<boolean>(false);

  const { superusers } = useAppConfig();

  const isLoggedInByWK = JSON.parse(sessionStorage.getItem('is_logged_in_by_wk' as string)) as boolean;
  const dispatch = useDispatch() as Dispatch;
  const user = useAuthSelector(({ auth }) => auth.user);
  const isLoggedIn = !!user?.accountData?.id;
  const isGuest = isEmpty(user?.role);
  const isSuperUser = superusers?.includes(user?.accountData?.login);
  const { clearOverriddenParameters } = useOverrideSystemParameters();

  const loginSSO = async () => {
    setLocalStorageItem(AuthLocalStorageItem.SSO_LOGIN_PROCES_STARTED, String(true));
    await AuthService.loginSSO({
      onSuccess: () => {
        setLocalStorageItem(AuthLocalStorageItem.LOGGED_BY_SS0, String(true));
      }
    });
    await dispatch(getUserData(null, null, GetUserStateTypeEnum.DEFAULT) as unknown as AnyAction);
  };

  const login = async (
    credentials: AccessTokenCreateRequest,
    getUserStateType?: GetUserStateTypeEnum,
    recaptchaField?: ReCAPTCHA,
    setIsLoginFailed?: (val: boolean) => void
  ) => {
    setLocalStorageItem(AutoLogoutStorageItemEnum.IDLE_START_TIME, String(new Date().getTime()));
    setLocalStorageItem(AuthLocalStorageItem.SSO_LOGIN_PROCES_STARTED, String(false));
    setIsLoginLoading(true);
    setIsLoginFailed?.(false);
    try {
      await AuthService.login(credentials);
      await dispatch(getUserData(null, null, getUserStateType) as unknown as AnyAction);
      redirect('/');
      setIsLoginFailed?.(false);
      setIsLoginLoading(false);
    } catch (error) {
      setIsLoginLoading(false);
      recaptchaField?.reset?.();
      setIsLoginFailed?.(true);
      dispatch({ type: LOGIN_ERROR, payload: error as object });
    }
  };

  const logout = async (queryCache: QueryCache, redirectOptions = null) => {
    clearOverriddenParameters();
    removeLocalStorageItem(AuthLocalStorageItem.LOGGED_BY_SS0);
    const logoutAction = () => {
      AuthService.logout(queryCache, redirectOptions);
      AuthService.clearSessionStorage();
      return { type: USER_LOGGED_OUT };
    };

    const logoutWkAction = () => {
      AuthService.logout(queryCache);
      AuthService.clearSessionStorage();
      window.open(process.env.REACT_APP_WK_LOGOUT, '_self');
      return { type: USER_LOGGED_OUT };
    };

    if (isLoggedInByWK) {
      await dispatch(logoutWkAction());
    } else {
      await dispatch(logoutAction());
    }
  };

  return { user, logout, login, loginSSO, isLoginLoading, isLoggedIn, isGuest, isSuperUser };
};

export default useAuth;
