import { FormMode, InputMode } from '@libs/common/v2';

const getInputMode = (formMode: FormMode, inputMode: InputMode) => {
  if (inputMode === InputMode.INVERTED) {
    return FormMode.VIEW === formMode ? InputMode.FORM : InputMode.VIEW;
  }
  if (!inputMode) {
    return FormMode.VIEW === formMode ? InputMode.VIEW : InputMode.FORM;
  }
  return inputMode;
};

export default getInputMode;
