import { useRowSelect } from 'react-table';

import { useTableLayoutProps } from '@libs/common/v2';

import { useGetUserSubjectsListQuery } from '@libs/domain/api';

import usePartnershipColumns from './usePartnershipColumns';

function usePartnershipListTable(loggedInUserId) {
  const { columns, mappedSortFields } = usePartnershipColumns();

  return useTableLayoutProps({
    tableHookQuery: useGetUserSubjectsListQuery,
    tableHookOptions: {
      columns,
      tablePlugins: [useRowSelect],
      getRowId: row => row?.id,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'name', desc: false }],
          pageSize: 100
        }
      },
      sortByConverter: mappedSortFields
    },
    tableHookQueryInitialParams: {
      userId: loggedInUserId
    }
  });
}

export default usePartnershipListTable;
