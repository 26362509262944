import { useTranslation } from 'react-i18next';
import { SubjectAddressHistoryAddressDetails, SubjectAddressHistoryDetails } from '@ibtm/domain';

import { CamelCasePath, useCreateColumns } from '@libs/common/v2';
import { getValue } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

const useAddressHistoryColumns = (visibleColumns: Array<CamelCasePath<SubjectAddressHistoryDetails>>) => {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  const { createColumns } = useCreateColumns<SubjectAddressHistoryDetails>({
    pathToTranslate: 'folder:details.addressHistory'
  });

  const getParsedAddress = (address?: SubjectAddressHistoryAddressDetails): string => {
    if (!address) {
      return t('emptyMark');
    }
    const { postCode, voivodeshipKey, county, commune, postCity, city, street, propertyNumber, apartmentNumber } =
      address;
    return `${getValue(postCode)}, ${translate(DomainDictionaryEnum.VOIVODESHIP, voivodeshipKey)}, ${getValue(
      county
    )}, ${getValue(commune)}, ${getValue(postCity)}, ${getValue(city)}, ${getValue(street)}, ${getValue(
      propertyNumber
    )}, ${getValue(apartmentNumber)}`;
  };

  const columns = createColumns(
    [
      {
        id: 'mainAddress',
        type: 'CUSTOM_COLUMN',
        customAccessor: row => getParsedAddress(row.mainAddress),
        isSortable: false,
        isFilterable: false
      },
      {
        id: 'hqAddress',
        customAccessor: row => getParsedAddress(row.hqAddress),
        type: 'TEXT',
        header: 'businessAddress',
        isSortable: false,
        isFilterable: false
      },
      {
        id: 'correspondenceAddress',
        customAccessor: row => getParsedAddress(row.correspondenceAddress),
        type: 'TEXT',
        isSortable: false,
        isFilterable: false
      },
      {
        header: 'modified',
        accessor: 'created',
        type: 'DATE',
        isFilterable: false
      },
      {
        accessor: 'applicationNumber',
        type: 'TEXT',
        isFilterable: false
      }
    ],
    visibleColumns
  );

  return { columns };
};

export default useAddressHistoryColumns;
