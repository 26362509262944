import { SelectOption, useTableLayoutProps } from '@libs/common/v2';

import { DomainDictionaryEntry, useDomainConfigContext } from '@libs/domain/config';
import { useEkmtLimitsQuery } from '@libs/domain/folder';
import { EkmtLimitTableEnum } from '@libs/domain/social-commission';

import { useFolderLimitColumns } from './ekmt';

function useEkmtLimitsTable(folderId: string, isForeignTab?: boolean) {
  const { columns, mappedFilterFields } = useFolderLimitColumns();
  const { yearFilterContextValues } = useDomainConfigContext();

  const initialParamsMapper = {
    [EkmtLimitTableEnum.YEAR]: (year: SelectOption<string>) => ({ [EkmtLimitTableEnum.YEAR]: year.value })
  };

  const initalParams = isForeignTab
    ? {
        folderId,
        [EkmtLimitTableEnum.YEAR]: yearFilterContextValues,
        typeKeyIn: [DomainDictionaryEntry.FOREIGN_PERMIT_TYPE.EKMT]
      }
    : {
        folderId,
        typeKeyIn: [DomainDictionaryEntry.FOREIGN_PERMIT_TYPE.EKMT]
      };

  return useTableLayoutProps({
    tableHookQuery: useEkmtLimitsQuery,
    tableHookOptions: {
      columns,
      initialParamsConverter: initialParamsMapper,
      filterConverter: mappedFilterFields
    },
    tableHookQueryInitialParams: initalParams
  });
}

export default useEkmtLimitsTable;
