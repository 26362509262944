import { QueryConfig, useQuery } from 'react-query';
import { SubjectAddressSearchFilter, SubjectAddressSnapshotList } from '@ibtm/domain';

import { API } from '@libs/domain/api';
import { DocumentsIssuedQueryEnum } from '@libs/domain/documents-issued/model';

import { endpointsConfig } from '../endpoints-config';

const getSubjectAddresses = async (_, params: SubjectAddressSearchFilter) => {
  const { data } = await API.address.getAddressesList(params, {
    ...endpointsConfig.getAddressesList
  });
  return data;
};

export default function useDocumentsIssuedAddressesListQuery(
  params: SubjectAddressSearchFilter,
  queryConfig: QueryConfig<SubjectAddressSnapshotList> = {}
) {
  return useQuery<SubjectAddressSnapshotList>(
    [DocumentsIssuedQueryEnum.SUBJECT_ADDRESSES, params],
    getSubjectAddresses,
    queryConfig
  );
}
