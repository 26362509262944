import { FormLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Theme } from '@libs/common/v2/theme';

export interface LabelProps {
  label: string;
  isRequired?: boolean;
  className?: string;
}

export function Label({ label, isRequired, className }: LabelProps) {
  const classes = useStyles({ isRequired });

  return (
    <FormLabel className={className} classes={classes} required={isRequired}>
      {label}
    </FormLabel>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    color: theme.palette.grey[700],
    fontWeight: theme.typography.textXs.medium.fontWeight,
    lineHeight: '2rem'
  },
  asterisk: {
    color: theme.palette.error[500],
    paddingLeft: 3
  }
}));
