import {
  AddressUpdateRequest,
  MisspellApplicationDataCertificateRecordUpdateRequest,
  MisspellApplicationDataDriverCertificateRecordUpdateRequest,
  MisspellCertificateDetails
} from '@ibtm/domain';

import { SelectOption, SelectOptionPartial } from '@libs/common/v2';

export enum MisspellApplicationPermissionTypeEnum {
  LICENSE = 'LICENSE',
  LICENSE_COPY = 'LICENSE_COPY',
  PERMISSION = 'PERMISSION',
  PERMISSION_COPY = 'PERMISSION_COPY'
}

export enum DialogModeEnum {
  COMPARE,
  EDIT
}

export type ApplicationVersion = { applicationVersion: number; reprint?: boolean };

export type PermissionUpdateRequest<T> = {
  applicationId: string;
  permissionUpdateRequest: T;
};

export type MisspellDriverCertificateAddressFields =
  | 'subjectApartmentNumber'
  | 'subjectCity'
  | 'subjectPostCity'
  | 'subjectPostCode'
  | 'subjectPropertyNumber'
  | 'subjectStreet';

type AddressFormValues = Pick<
  AddressUpdateRequest,
  'apartmentNumber' | 'city' | 'postCity' | 'postCode' | 'propertyNumber' | 'street'
>;

export type MisspellApplicationDataDriverCertificateFormValues = Omit<
  MisspellApplicationDataDriverCertificateRecordUpdateRequest,
  MisspellDriverCertificateAddressFields | 'driverCompetenceCodeKey'
> &
  AddressFormValues & { qualification: boolean; driverCompetenceCodeKey: SelectOptionPartial<string> };

export type MisspellApplicationDataCertificateFormValues = Omit<
  MisspellApplicationDataCertificateRecordUpdateRequest,
  'vehicleModelKey' | 'vehicleBrandKey'
> & {
  vehicleModelKey?: SelectOption<string>;
  vehicleBrandKey?: SelectOption<string>;
};

export type MisspellCertificateDetailsValues = Omit<
  MisspellCertificateDetails,
  'vehicleModelKey' | 'vehicleBrandKey'
> & {
  vehicleModelKey?: SelectOption<string>;
  vehicleBrandKey?: SelectOption<string>;
};
