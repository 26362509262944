import { useMemo } from 'react';
import { NoteDetails, NoteUpdateRequest } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  FormMode,
  GridItem,
  InputMode,
  TextInputField,
  TextValueField,
  typedNameV2,
  useViewModesV2
} from '@libs/common/v2';
import { convertDateToDateTimeFormat } from '@libs/common/v2/utils';

function useNoteFields(
  data: NoteDetails,
  currentFormMode: FormMode,
  isEditMode: boolean,
  customHandleChange?: (val: string | NoteUpdateRequest) => void,
  isContentFieldDisabled?: boolean
) {
  const { editMode } = useViewModesV2(currentFormMode);
  const getLabel = partialTranslate('folder:details.tab.note.field');
  const formData = useMemo(
    () => ({
      version: data?.version
    }),
    [data]
  );

  const contentField = useMemo(() => {
    if (isEditMode || editMode) {
      return (
        <GridItem key="content" xs={12}>
          <TextInputField
            name={typedNameV2<NoteDetails>('content')}
            label={getLabel('content')}
            key="content"
            isDisabled={isContentFieldDisabled}
            customHandleChange={e => {
              if (customHandleChange) {
                if (data) {
                  customHandleChange({ ...formData, content: e.target.value });
                } else {
                  customHandleChange(e.target.value);
                }
              }
            }}
            inputMode={InputMode.FORM}
            lines={10}
          />
        </GridItem>
      );
    }

    return (
      <GridItem key="content" xs={12}>
        <TextValueField key="content" name={typedNameV2<NoteDetails>('content')} label={getLabel('content')}>
          {(value: string) => <div className="whitespace-pre-wrap break-words">{value}</div>}
        </TextValueField>
      </GridItem>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, currentFormMode]);

  return [
    contentField,
    <TextInputField
      name={typedNameV2<NoteDetails>('author.name')}
      label={getLabel('author')}
      key="author"
      inputMode={InputMode.VIEW}
    />,
    <DatepickerField
      name={typedNameV2<NoteDetails>('created')}
      label={getLabel('created')}
      key="created"
      viewModeDateParser={convertDateToDateTimeFormat}
      inputMode={InputMode.VIEW}
    />,
    <TextInputField
      name={typedNameV2<NoteDetails>('modifier.name')}
      label={getLabel('modifier')}
      key="modifier"
      inputMode={InputMode.VIEW}
    />,
    <DatepickerField
      name={typedNameV2<NoteDetails>('modified')}
      label={getLabel('modified')}
      key="modified"
      viewModeDateParser={convertDateToDateTimeFormat}
      inputMode={InputMode.VIEW}
    />
  ];
}

export default useNoteFields;
