import {
  ApplicationAddressUpdateRequest,
  type ApplicationRecipientDataUpdateRequest,
  CourierAdditionalServices,
  SubjectAddressDetails
} from '@ibtm/domain';

import { SelectOption, SelectOptionPartial } from '@libs/common/v2';

import { DomainDictionaryEntry } from '../../config';

export type AddressFormValues = Omit<ApplicationAddressUpdateRequest, 'voivodeshipKey'> & {
  voivodeshipKey: SelectOptionPartial<string>;
};

export type IDocumentUpdateFormValues = {
  receiptTypeKey: SelectOption<string>;
  sendAddress: SelectOption<SubjectAddressDetails>;
  addressData: AddressFormValues;
  epuap: string;
  recipientName: string;
  recipientSurname: string;
  additionalService: { [key in keyof CourierAdditionalServices]: boolean };
} & Pick<ApplicationRecipientDataUpdateRequest, 'addresseeName' | 'contactData'>;

export const getAddressValue = (values: IDocumentUpdateFormValues): ApplicationAddressUpdateRequest => {
  const sendAddress = values.sendAddress?.value;
  const correspondenceAddress: ApplicationAddressUpdateRequest = values.addressData
    ? {
        ...values.addressData,
        countryCodeKey: values?.addressData?.countryCodeKey,
        typeKey: values?.addressData?.typeKey || DomainDictionaryEntry.ADDRESS_TYPE.CORRESPONDENCE,
        voivodeshipKey: values.addressData?.voivodeshipKey?.value
      }
    : {
        city: sendAddress.city,
        commune: sendAddress.commune,
        county: sendAddress.county,
        postCity: sendAddress.postCity,
        postCode: sendAddress.postCode,
        countryCodeKey: sendAddress?.countryCodeKey,
        propertyNumber: sendAddress.propertyNumber,
        street: sendAddress.street,
        voivodeshipKey: sendAddress.voivodeshipKey,
        apartmentNumber: sendAddress.apartmentNumber,
        typeKey: sendAddress.typeKey || DomainDictionaryEntry.ADDRESS_TYPE.CORRESPONDENCE,
        ...sendAddress
      };
  return correspondenceAddress;
};
export const parseAddressFormValues = (address: SubjectAddressDetails): AddressFormValues => ({
  ...address,
  postCode: address.postCode,
  city: address.city,
  commune: address.commune,
  county: address.county,
  countryCodeKey: address?.countryCodeKey,
  postCity: address.postCity,
  propertyNumber: address.propertyNumber,
  street: address.street,
  apartmentNumber: address.apartmentNumber,
  voivodeshipKey: { value: address.voivodeshipKey },
  typeKey: address.typeKey
});
