import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { Skeleton } from '@mui/material';
import { DataSourceMediatorColumn, DataSourceMediatorDetailsSnapshot } from '@stack/report/dist/models';
import clsx from 'clsx';

import { IconButton, Typography, useConfirmDialog, useFormV2Context } from '@libs/common/v2';
import { unescapeValue } from '@libs/common/v2/utils';

import ReportSourceColumnsField from '@libs/report/components/report-type-form/ReportTypeDataSection/ReportTypeColumns/ReportSourceColumnsField';
import { useStyles } from '@libs/report-core/components/condition-builder/condition-basic/condition-group/styles';
import { ConditionOperator } from '@libs/report-core/model';

interface IProps {
  sourceColumns: DataSourceMediatorColumn[];
  source: DataSourceMediatorDetailsSnapshot;
}

function AlertBasicColumns({ sourceColumns, source }: IProps) {
  const [t] = useTranslation();

  const { setValue, watch } = useFormV2Context();
  const snackbar = useSnackbar();
  const [confirm] = useConfirmDialog();

  const sourceId = source?.id;
  const queryDefinition = watch(`queryDefinition`);

  const classes = useStyles({ columnsListRowHeight: 'auto' });

  const [selectedColumns, setSelectedColumns] = useState<Array<DataSourceMediatorColumn>>(
    sourceColumns.filter(({ name }) => queryDefinition.columns?.some(column => column.name === name))
  );

  useEffect(() => {
    setSelectedColumns(
      sourceColumns.filter(({ name }) => queryDefinition.columns?.some(column => column.name === name))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceId, sourceColumns]);

  const handleChange = (options: DataSourceMediatorColumn[]) => {
    setSelectedColumns(options);
    if (!options.length) {
      setValue('queryConditions[0]', {
        filter: null,
        group: [],
        operator: ConditionOperator.AND
      });
    }
    setValue(
      `queryDefinition.columns`,
      options.map(({ name }) => ({ name })),
      { shouldDirty: true }
    );
  };

  const handleDeleteColumn = (column: DataSourceMediatorColumn) => {
    const name = column.displayName || column.name;
    confirm({
      message: t('reports:message.deleteReportTypeColumnConfirmation', unescapeValue({ name })),
      title: t('reports:dialog.deleteColumn'),
      shortTitle: t('global:dialog.deleteConfirmTitle'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        handleChange(selectedColumns.filter(_column => _column !== column));
        snackbar.showSuccessSnackbar(t('reports:message.deleteReportTypeColumnSuccess', unescapeValue({ name })));
        closeDialog();
      },
      confirmType: 'danger'
    });
  };

  return !sourceColumns.length ? (
    <Skeleton variant="rectangular" height={56} className="mb-16" />
  ) : (
    <div className={clsx('pb-16 flex flex-col flex-grow', classes.basicColumnsContainer)}>
      <div className="pr-24">
        <ReportSourceColumnsField columns={sourceColumns} selectedColumns={selectedColumns} onChange={handleChange} />
      </div>
      <div className={classes.columnsList}>
        {selectedColumns.map(column => (
          <div key={column.name} className="pl-4 py-8 pr-16 flex justify-between items-center">
            <div className="flex flex-col justify-start w-full">
              <div className="flex w-full">
                <div>
                  <Typography color="textSecondary" style={{ fontWeight: 600 }}>
                    {column.displayName}
                  </Typography>
                  <Typography variant="caption">{`${column.name}: ${column.type}`}</Typography>
                </div>
              </div>
            </div>
            <IconButton title={t('global:action.delete')} onClick={() => handleDeleteColumn(column)} icon="TrashIcon" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default AlertBasicColumns;
