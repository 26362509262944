import { useFormV2Context, useViewModesV2 } from '@libs/common/v2';

import { UserGroupCreateForm, UserGroupForm } from '@libs/user/components';
import { useGroupCustomPermissions } from '@libs/user/hooks';

interface IProps {
  isDisabled?: boolean;
}

function UserPermissionsGroupTab({ isDisabled }: IProps) {
  const { formMode } = useFormV2Context();
  const { createMode } = useViewModesV2(formMode);

  const { canAddGroup } = useGroupCustomPermissions();

  return createMode ? (
    <UserGroupCreateForm canAddGroup={!isDisabled || canAddGroup} />
  ) : (
    <UserGroupForm canAddGroup={!isDisabled || canAddGroup} />
  );
}

export default UserPermissionsGroupTab;
