import { CivicBoardCreateRequest } from '@ibtm/domain';

import { typedNameV2 } from '@libs/common/v2';

export type SocialCommissionAddMeetingTypeConfig = {
  type?: string;
  required?: boolean;
  isDisabled?: boolean;
  name?: string;
  label?: string;
  isYear?: boolean;
};

export const SocialCommissionAddMeetingFormDropdownConfig = [
  {
    type: 'number',
    required: true,
    isDisabled: false,
    name: `year`,
    label: `foreignPermits:tabs.socialCommission.tabs.socialCommissionDeadlines.fields.year`,
    isYear: true
  },
  {
    type: 'number',
    required: true,
    isDisabled: false,
    name: 'month',
    label: `foreignPermits:tabs.socialCommission.tabs.socialCommissionDeadlines.fields.month`,
    isYear: false
  }
];

export const SocialCommissionAddMeetingFormConfig = [
  {
    type: 'date',
    required: true,
    isDisabled: false,
    name: typedNameV2<CivicBoardCreateRequest>('submissionDateFrom'),
    label: `foreignPermits:tabs.socialCommission.tabs.socialCommissionDeadlines.fields.submissionDateFrom`
  },
  {
    type: 'date',
    required: true,
    isDisabled: false,
    name: typedNameV2<CivicBoardCreateRequest>('submissionDateTo'),
    label: `foreignPermits:tabs.socialCommission.tabs.socialCommissionDeadlines.fields.submissionDateTo`
  },
  {
    type: 'date',
    required: true,
    isDisabled: false,
    name: typedNameV2<CivicBoardCreateRequest>('receiptDateFrom'),
    label: `foreignPermits:tabs.socialCommission.tabs.socialCommissionDeadlines.fields.receiptDateFrom`
  },
  {
    type: 'date',
    required: true,
    isDisabled: false,
    name: typedNameV2<CivicBoardCreateRequest>('receiptDateTo'),
    label: `foreignPermits:tabs.socialCommission.tabs.socialCommissionDeadlines.fields.receiptDateTo`
  },
  {
    type: 'date',
    required: true,
    isDisabled: false,
    name: typedNameV2<CivicBoardCreateRequest>('boardDate'),
    label: `foreignPermits:tabs.socialCommission.tabs.socialCommissionDeadlines.fields.boardDate`
  }
];
