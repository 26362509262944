import { useRef } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormMode, GridLayout, Section } from '@libs/common/v2';

import { useUpdateApplicationMisspellCertificateMutation } from '../../api';
import { MisspellApplicationDataCertificateFormValues } from '../../model/misspell-application.model';
import MisspellApplicationCertificateFormFields from '../MisspellApplicationCertificateFormFields';

import { IPermissionDialogProps, PermissionDialog } from './PermissionDialog';

export function MisspellApplicationCertificateDialog({
  mode,
  permissionData,
  updatePermissionData,
  applicationId,
  closeDialog
}: Pick<
  IPermissionDialogProps<MisspellApplicationDataCertificateFormValues>,
  'permissionData' | 'updatePermissionData' | 'closeDialog' | 'mode' | 'applicationId'
>) {
  const [t] = useTranslation();
  const handleSubmit = useRef<UseFormHandleSubmit<MisspellApplicationDataCertificateFormValues>>(null);

  return (
    <PermissionDialog
      formRef={handleSubmit}
      applicationId={applicationId}
      closeDialog={closeDialog}
      mode={mode}
      permissionData={permissionData}
      mutation={useUpdateApplicationMisspellCertificateMutation}
      updatePermissionData={updatePermissionData}
      compareTitle={t('applications:misspellApplication.dialog.changesCertificateComparition')}
      editTitle={t('applications:misspellApplication.dialog.certificateEdit')}
      compareContentElement={
        <GridLayout itemProps={{ xs: 6 }}>
          <Section title={t('applications:misspellApplication.dialog.certificateData')} isModalSection>
            <MisspellApplicationCertificateFormFields formMode={FormMode.VIEW} certificatesData={permissionData} />
          </Section>
          <Section title={t('applications:misspellApplication.dialog.updatedCertificateData')} isModalSection>
            <MisspellApplicationCertificateFormFields
              formMode={FormMode.VIEW}
              certificatesData={updatePermissionData}
            />
          </Section>
        </GridLayout>
      }
      editContentElement={
        <MisspellApplicationCertificateFormFields
          formMode={FormMode.EDIT}
          certificatesData={updatePermissionData}
          ref={handleSubmit}
        />
      }
    />
  );
}
