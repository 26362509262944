import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const cancelProceeding = (params: { proceedingId: string; version: number }) => {
  const { proceedingId, version } = params;
  return API.proceeding.cancelProceeding(
    proceedingId,
    {
      version
    },
    { ...endpointsConfig.cancelProceeding }
  );
};

export const useProceedingCancelMutation = () => useMutation(cancelProceeding);
