import { FinancialSecurityCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function createFinancialSecurityClient(formData: FinancialSecurityCreateRequest) {
  return API.client.financialSecurity.createFinancialSecurity('', '', formData, {
    ...endpointsConfig.createFinancialSecurity
  });
}

function createFinancialSecurity(formData: FinancialSecurityCreateRequest) {
  return API.financialSecurity.createFinancialSecurity(formData, {
    ...endpointsConfig.createFinancialSecurity
  });
}

function useFinancialSecurityCreateMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(createFinancialSecurityClient, createFinancialSecurity));
}

export default useFinancialSecurityCreateMutation;
