import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupSnapshotExternal } from '@avispon/group/dist/models';
import { AccountSnapshot } from '@avispon/user';
import * as Yup from 'yup';

function useAdminMessagesValidation() {
  const [t] = useTranslation();

  const validationSchema = useMemo(() => {
    return Yup.object({
      recipients: Yup.object({
        accountSelector: Yup.object({
          recipient: Yup.string().required().oneOf(['users', 'groups']),
          accountIds: Yup.array<AccountSnapshot>().when('recipient', {
            is: 'users',
            then: Yup.array<AccountSnapshot>().required()
          }),
          groupIds: Yup.array<GroupSnapshotExternal>().when('recipient', {
            is: 'groups',
            then: Yup.array<GroupSnapshotExternal>().required()
          })
        })
      }),
      subject: Yup.string().required(),
      message: Yup.string().required(),
      visibleFrom: Yup.date().required().typeError(t('validation:required')),
      validUntil: Yup.date()
        .test(
          'isValidUntilAfterVisibleFrom',
          t('messages:validation.validUntilDateAfterThanVisibleFrom'),
          function validUntilAfterVisibleFrom(this, validUntil) {
            if (validUntil) {
              const visibleFromDate = new Date(this.parent.visibleFrom);
              const formattedValidUntil = new Date(validUntil);

              return formattedValidUntil > visibleFromDate;
            }

            return true;
          }
        )
        .test('isValidUntilAfterThanToday', t('messages:validation.validUntilDateAfterThanNow'), validUntil => {
          if (validUntil) {
            const formattedValidUntil = new Date(validUntil);
            const currentDate = new Date();

            return formattedValidUntil > currentDate;
          }

          return true;
        })
        .nullable()
    });
  }, [t]);

  return { validationSchema };
}

export default useAdminMessagesValidation;
