import { useCallback } from 'react';

import { useDialog } from '@libs/common/v2';

import { IUpdatePermissionRequest, PermissionEditDialog } from '../../components/PermissionEditDialog';

export function usePermissionEditDialog() {
  const { openDialog } = useDialog();

  const showDialog = useCallback(
    (initialData: IUpdatePermissionRequest, refetch: () => void, titleKey: string) => {
      openDialog(({ closeDialog }) => (
        <PermissionEditDialog
          closeDialog={closeDialog}
          initialData={initialData}
          refetch={refetch}
          titleKey={titleKey}
        />
      ));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog]
  );

  const openEditPermissionDialog = (initialData: IUpdatePermissionRequest, refetch: () => void, titleKey: string) => {
    showDialog(initialData, refetch, titleKey);
  };

  return {
    openEditPermissionDialog
  };
}
