import { ReactElement, useEffect, useMemo, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FieldPath, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppConfig } from '@libs/app-config';
import { Checkbox, Divider, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import * as Yup from 'yup';

import { Button, FormV2Context, ReCaptchaField, TextInputField, Typography } from '@libs/common/v2';
import { Theme, useTheme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import { LoginPageValues as ILoginPageValues } from '../LoginPage';

interface IProps {
  customInitialValuesTest?: ILoginPageValues;
  isLoading?: boolean;
  showForgotPasswordButton?: boolean;
  handleSubmit?: (values) => void;
  customForgotPasswordLabel?: string;
  setRecaptchaField?: (ref: ReCAPTCHA) => void;
  additionalButton?: ReactElement;
  isLoginFailed?: boolean;
}

export interface LoginPageValues {
  username: string;
  password: string;
  reCaptcha: string;
}

enum LoginInputNamesEnum {
  USERNAME = 'username',
  PASSWORD = 'password'
}

function Form({
  customInitialValuesTest,
  isLoading,
  showForgotPasswordButton,
  handleSubmit,
  customForgotPasswordLabel,
  setRecaptchaField,
  additionalButton,
  isLoginFailed
}: IProps) {
  const [t] = useTranslation();
  const { contrast } = useTheme();
  const classes = useStyles({ contrast });
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { captchaKey, showCaptcha } = useAppConfig();

  const initialValues: LoginPageValues = {
    username: '',
    password: '',
    reCaptcha: null
  };

  const reCaptchaValidation = useMemo(
    () => (showCaptcha ? { reCaptcha: Yup.string().nullable().required() } : {}),
    [showCaptcha]
  );

  const validationSchema = Yup.object({
    username: Yup.string().required(),
    password: Yup.string().required(),
    ...reCaptchaValidation
  });

  const {
    control,
    register,
    handleSubmit: handleReactFormSubmit,
    formState: { errors, isSubmitting },
    setValue,
    setError,
    watch,
    getValues,
    trigger,
    unregister,
    resetField
  } = useForm<Record<string, any>>({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: customInitialValuesTest || initialValues
  });

  const isBtnDisabled = Boolean(errors.password?.message) || Boolean(errors.username?.message);

  const values = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      setError,
      trigger,
      unregister,
      resetField,
      isSubmitting
    }),
    [control, errors, getValues, isSubmitting, register, setError, setValue, trigger, unregister, watch, resetField]
  );

  useEffect(() => {
    if (isLoginFailed) {
      resetField('reCaptcha' as FieldPath<FieldValues>);
    }
  }, [isLoginFailed, resetField]);

  return (
    <FormV2Context.Provider value={values}>
      <form>
        <TextInputField
          name={LoginInputNamesEnum.USERNAME}
          label={t('field.login')}
          className={classes.loginInputField}
        />
        <TextInputField
          type={showPassword ? 'text' : 'password'}
          label={t('field.password')}
          name={LoginInputNamesEnum.PASSWORD}
        />
        <div className="w-full flex flex-row m-0 py-12 items-center justify-between">
          <FormControlLabel
            className={classes.checkboxContainer}
            control={
              <Checkbox
                checked={showPassword}
                classes={{ checked: classes.checkbox }}
                onKeyPress={e => {
                  e.preventDefault();
                  if (e.key === 'Enter') setShowPassword(!showPassword);
                }}
                onChange={() => setShowPassword(!showPassword)}
              />
            }
            label={t('login:form.showPassword')}
          />
          {showForgotPasswordButton && (
            <Link className={clsx('p-0', classes.link)} to="/reset-password">
              <Typography className={clsx(classes.linkText)} themeVariant="textSm.medium">
                {customForgotPasswordLabel || t('login:form.forgotPassword')}
              </Typography>
            </Link>
          )}
        </div>

        {showCaptcha && (
          <div className="w-full flex m-0 pb-12" style={{ transform: 'scale(0.85)', transformOrigin: '0 0' }}>
            <ReCaptchaField name="reCaptcha" captchaKey={captchaKey} setRecaptchaField={setRecaptchaField} />
          </div>
        )}

        <Button
          isLoading={isLoading ?? isSubmitting}
          disabled={!!isBtnDisabled}
          label={t('action.logIn')}
          onClick={handleReactFormSubmit(handleSubmit)}
          className={clsx(classes.logInButton)}
          variant="contained"
          fullWidth
          isPrimary
          isNoMargin
          buttonType="submit"
        />
        {additionalButton && (
          <>
            <Divider className={classes.divider}>{t('other:component.conditionBuilder.or')}</Divider>
            <div className={classes.additionalButton}>{additionalButton}</div>
          </>
        )}
      </form>
    </FormV2Context.Provider>
  );
}

const useStyles = makeStyles<Theme, { contrast?: boolean }>(theme => ({
  link: {
    textDecoration: 'none'
  },
  linkText: {
    color: important(theme.palette.primary[900])
  },
  loginInputField: {
    marginBottom: '12px'
  },
  logInButton: {
    width: '100%',
    marginBottom: '16px'
  },
  additionalButton: {
    marginTop: '16px',
    width: '100%',
    '& svg': {
      width: '70px',
      margin: 0
    },
    '& button': {
      width: '100%'
    }
  },
  checkboxContainer: {
    '& > span:last-child': {
      ...theme.typography.textXs.semibold,
      color: theme.palette.grey[700],
      letterSpacing: 0.5
    } as object,
    '& > span': {
      color: ({ contrast }) => (contrast ? important(theme.palette.grey[900]) : 'unset')
    }
  },
  checkbox: {
    color: important(theme.palette.primary[800])
  },
  divider: {
    width: '50%',
    marginLeft: '25%'
  }
}));

export default Form;
