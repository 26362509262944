import React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { IconType } from '@libs/common/v2';
import { Theme, useTheme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import IconButton from './IconButton';

interface IconCountButtonProps {
  onClick?: (params: any) => void;
  tooltipTitle: string;
  icon: IconType;
  countValue?: number;
  className?: string;
  height?: number;
  width?: number;
  isDisabled?: boolean;
  isLoading?: boolean;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
}

function IconCountButton({
  countValue,
  height = 20,
  width = 20,
  isDisabled,
  icon,
  tooltipTitle,
  isLoading,
  onClick,
  className,
  type
}: IconCountButtonProps) {
  const { contrast } = useTheme();
  const classes = useStyles({ contrast });

  return (
    <IconButton
      icon={icon}
      isDisabled={isDisabled}
      className={clsx('relative', classes.iconButton, className)}
      tooltipTitle={tooltipTitle}
      onClick={onClick}
      height={height}
      width={width}
      isBackgroundTransparent
      isLoading={isLoading}
      type={type}
    >
      {countValue && <span className={classes.value}>{countValue}</span>}
    </IconButton>
  );
}

const useStyles = makeStyles<Theme, { contrast?: boolean }>(theme => ({
  iconButton: {
    padding: 7,
    marginTop: 5
  },
  value: {
    top: -5,
    right: -8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 16,
    minHeight: 16,
    position: 'absolute',
    zIndex: 10,
    color: ({ contrast }) => (contrast ? important(theme.palette.grey[400]) : important(theme.palette.blue[800])),
    backgroundColor: theme.palette.grey[50],
    letterSpacing: '0.5px',
    textAlign: 'center',
    borderWidth: 0.5,
    borderRadius: '50%',
    padding: '2px',
    fontSize: theme.typography.getFontSize(14),
    ...theme.typography.textXs.bold
  }
}));

export default IconCountButton;
