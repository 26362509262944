import { useTranslation } from 'react-i18next';
import { ApplicationDetails as ApplicationDetailsClient } from '@ibtm/client-domain';
import { ApplicationDetails, ApplicationVerificationDetails } from '@ibtm/domain';
import { isEmpty, isNil } from 'lodash';

import { Dialog, GridItem, GridLayout } from '@libs/common/v2';
import { WarningInformation } from '@libs/common/v2/components/warning';

import { useDictionaryTranslations } from '@libs/dictionary';
import { DictionaryEntryNameEnum } from '@libs/dictionary/models/dictionaryEntryNameEnum';

import { ApplicationWarningsList } from '@libs/domain/application-warnings';
import { ReputationTable, TransportManagerDetailsClient } from '@libs/domain/transport-manager';

interface IProps {
  onClose: () => void;
  data: ApplicationVerificationDetails;
  isOpen: boolean;
  applicationId: string;
  applicationDetails: ApplicationDetails | ApplicationDetailsClient;
}

function ApplicationVerifyDialog({ onClose, data, isOpen, applicationId, applicationDetails }: IProps) {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  const errorsWithTranslations = [
    'negativeExploitationBasesInspections',
    'lackOfContactAddressData',
    'notComplete',
    'invalidStateErrors',
    'invalidTransportManagerCertificate',
    'insufficientFinancialCapacity',
    'invalidExternalAuthorityPermission',
    'requiredAttachmentsMissing',
    'existingSCertificatesVerificationResult',
    'parallelApplicationWithSamePermission'
  ];

  const filterErrorsWithoutTranslations = (obj: ApplicationVerificationDetails, properties: string[]) => {
    const newObj = {};
    if (!isNil(obj)) {
      const arrayData = Object.values(obj);
      arrayData.forEach(property => {
        if (Object.prototype.hasOwnProperty.call(obj, property)) {
          if (typeof obj[property] === 'object') {
            newObj[property] = filterErrorsWithoutTranslations(obj[property], properties);
          } else if (!properties.includes(property)) {
            newObj[property] = obj[property];
          }
        }
      });
    }
    return newObj;
  };

  const filteredData = filterErrorsWithoutTranslations(data, errorsWithTranslations);

  const hasTrueValue = obj => {
    if (!isNil(obj) && !isEmpty(obj)) {
      obj.forEach(property => {
        if (Object.prototype.hasOwnProperty.call(obj, property)) {
          if (typeof obj[property] === 'object') {
            if (hasTrueValue(obj[property])) {
              return true;
            }
          } else if (obj[property] === true) {
            return true;
          }
        }

        return false;
      });
    }

    return false;
  };

  const hasErrorWithoutTranslation = hasTrueValue(filteredData);

  return (
    <Dialog
      title={t('applications:verify.title')}
      onCancel={onClose}
      onClose={onClose}
      isOpen={Boolean(isOpen && data)}
    >
      <GridLayout itemProps={{ xs: 12 }}>
        <ApplicationWarningsList
          applicationFlags={data?.applicationFlags}
          applicationId={applicationId}
          applicationData={applicationDetails}
        />
        {Boolean(data?.subjectReputation?.reputationRequests?.length) && (
          <ReputationTable
            title={t('applications:verify.field.subjectReputation')}
            reputations={
              data?.subjectReputation
                ?.reputationRequests as unknown as TransportManagerDetailsClient['reputationInquires']
            }
          />
        )}
        {data?.transportManagersReputation?.length && (
          <GridItem>
            {data?.transportManagersReputation.map(item => (
              <ReputationTable
                key={item.transportManager.id}
                reputations={item?.reputationRequests as unknown as TransportManagerDetailsClient['reputationInquires']}
                title={t('applications:verify.field.transportManagerReputation')}
              />
            ))}
          </GridItem>
        )}
        {data?.negativeExploitationBasesInspections && (
          <WarningInformation content={t('exploitationBase:details.message.baseHasNgativeControlResult')} />
        )}
        {data?.lackOfContactAddressData && (
          <WarningInformation content={t('applications:verify.message.lackOfContactAddressData')} />
        )}
        {data?.notComplete && <WarningInformation content={t('applications:verify.message.notComplete')} />}
        {data?.invalidStateErrors && (
          <GridItem>
            {data.invalidStateErrors.map(item => translate(DictionaryEntryNameEnum.ERROR, item).split(', '))}
          </GridItem>
        )}
        {data?.invalidTransportManagerCertificate && (
          <WarningInformation content={t('applications:verify.message.invalidTransportManagerCertificate')} />
        )}
        {data?.insufficientFinancialCapacity && (
          <WarningInformation content={t('applications:verify.message.insufficientFinancialCapacity')} />
        )}
        {data?.invalidExternalAuthorityPermission && (
          <WarningInformation content={t('applications:verify.message.invalidExternalAuthorityPermission')} />
        )}
        {data?.requiredAttachmentsMissing && (
          <WarningInformation content={t('applications:verify.message.requiredAttachmentsMissing')} />
        )}
        {data?.existingSCertificatesVerificationResult?.length && (
          <WarningInformation content={t('applications:verify.message.existingSCertificatesVerificationResult')} />
        )}
        {data?.parallelApplicationWithSamePermission && (
          <WarningInformation content={t('applications:verify.message.parallelApplicationWithSamePermission')} />
        )}
        {hasErrorWithoutTranslation && (
          <WarningInformation content={t('applications:verify.message.applicationIncorrectlyCompleted')} />
        )}
      </GridLayout>
    </Dialog>
  );
}

export default ApplicationVerifyDialog;
