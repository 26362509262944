import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { MoneyTransferApplicationUpdateRequest, MoneyTransferSnapshot } from '@ibtm/domain';
import { number as YupNumber, object as YupObject } from 'yup';

import { Dialog, FormV2Context, GridLayout, NumberInputField, typedNameV2, Value } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useUpdateMoneyTransferApplicationMutation } from '../api';
import { PricingQueryKeysEnum } from '../api/query/PricingQueryKeysEnum';

interface IProps {
  applicationId: string;
  data: MoneyTransferSnapshot;
  accountingApproved: boolean;
  closeDialog: () => void;
}

function MoneyTransferEditDialog({ applicationId, data: moneyTransferData, closeDialog, accountingApproved }: IProps) {
  const queryCache = useQueryCache();
  const formMethods = useForm<Record<string, any>>({
    defaultValues: {
      currentApplicationAmountUsed: moneyTransferData.currentApplicationAmountUsed,
      accountingApproved
    },
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(
      YupObject({
        currentApplicationAmountUsed: YupNumber().required().nullable().max(moneyTransferData.availableAmount)
      })
    )
  });
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { mutate: updateMoneyTransfer, isLoading } = useUpdateMoneyTransferApplicationMutation();
  const [t] = useTranslation();

  const handleSubmit = formMethods.handleSubmit((formData: Partial<MoneyTransferApplicationUpdateRequest>) =>
    updateMoneyTransfer(
      {
        applicationId,
        moneyTransferId: moneyTransferData.id,
        formData: {
          currentApplicationAmountUsed: formData?.currentApplicationAmountUsed,
          accountingApproved: formData?.accountingApproved || false
        }
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('success.save'));
          queryCache.invalidateQueries([PricingQueryKeysEnum.APPLICATION_MONEY_TRANSFERS]);
          closeDialog();
        },
        onError: () => showErrorSnackbar(t('global:error.dataSaveFailure'))
      }
    )
  );

  const form = useMemo(() => formMethods, [formMethods]);

  return (
    <Dialog
      title={t('pricing:paymentConfirmation.dialog.editMoneyTransferTitle')}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={handleSubmit}
      onCancel={closeDialog}
      dialogSize="small"
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={form}>
        <form onSubmit={handleSubmit}>
          <GridLayout>
            <Value
              label={t('pricing:moneyTransfers.field.availableAmount')}
              value={moneyTransferData.availableAmount}
            />
            <NumberInputField
              name={typedNameV2<MoneyTransferApplicationUpdateRequest>('currentApplicationAmountUsed')}
              label={t('pricing:moneyTransfers.field.currentApplicationAmountUsed')}
              isRequired
              max={moneyTransferData.availableAmount}
              step={0.01}
              isClearable
            />
          </GridLayout>
        </form>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default MoneyTransferEditDialog;
