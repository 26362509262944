import { ReactNode, useEffect, useMemo, useState } from 'react';
import { SubjectLiteExtended } from '@ibtm/client-domain';

import { getInitialYear, GlobalYearSelectType } from '@libs/common/v2';
import { useRouterContext } from '@libs/common/v2/router';

import { SelectPartnershipRoutingGuardProvider } from '../routing-guards';

import DomainConfigContext, { ClientPortalUserPerspectiveEnum, DomainConfigContextState } from './DomainConfigContext';

interface IProps {
  children: ReactNode;
  config: DomainConfigContextState;
  clientPortalEntrepreneurPerspectiveHiddenRoutesPaths?: Array<string>;
  clientPortalAssociationPerspectiveHiddenRoutesPaths?: Array<string>;
  onSelectPartnership?: (subject: SubjectLiteExtended) => void;
}

function DomainConfigProvider({
  children,
  config,
  clientPortalEntrepreneurPerspectiveHiddenRoutesPaths,
  clientPortalAssociationPerspectiveHiddenRoutesPaths,
  onSelectPartnership
}: IProps) {
  const { setHiddenRoutesPaths } = useRouterContext();

  const [clientPortalUserPerspective, setClientPortalUserPerspective] = useState(
    ClientPortalUserPerspectiveEnum.ENTREPRENEUR
  );
  const [yearFilterContextValues, setYearFilterContextValues] = useState<GlobalYearSelectType>(getInitialYear());

  const isClientPortalEntrepreneurPerspective = useMemo(
    () => clientPortalUserPerspective === ClientPortalUserPerspectiveEnum.ENTREPRENEUR,
    [clientPortalUserPerspective]
  );

  const isClientPortalAssociationPerspective = useMemo(
    () => clientPortalUserPerspective === ClientPortalUserPerspectiveEnum.ASSOCIATION,
    [clientPortalUserPerspective]
  );

  useEffect(() => {
    if (isClientPortalEntrepreneurPerspective) {
      setHiddenRoutesPaths(clientPortalEntrepreneurPerspectiveHiddenRoutesPaths);
    }

    if (isClientPortalAssociationPerspective) {
      setHiddenRoutesPaths(clientPortalAssociationPerspectiveHiddenRoutesPaths);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClientPortalEntrepreneurPerspective, isClientPortalAssociationPerspective]);

  const values = useMemo(
    () => ({
      ...config,
      isClientPortalEntrepreneurPerspective,
      isClientPortalAssociationPerspective,
      clientPortalUserPerspective,
      setClientPortalUserPerspective,
      yearFilterContextValues,
      setYearFilterContextValues
    }),
    [
      clientPortalUserPerspective,
      config,
      isClientPortalAssociationPerspective,
      isClientPortalEntrepreneurPerspective,
      yearFilterContextValues
    ]
  );

  return (
    <DomainConfigContext.Provider value={values}>
      {config.isClientPortal ? (
        <SelectPartnershipRoutingGuardProvider onSelectPartnership={onSelectPartnership}>
          {children}
        </SelectPartnershipRoutingGuardProvider>
      ) : (
        children
      )}
    </DomainConfigContext.Provider>
  );
}

export default DomainConfigProvider;
