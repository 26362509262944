import { ProceedingKreptdCreateBaseOnViolationsRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function startProceeding(requestData: ProceedingKreptdCreateBaseOnViolationsRequest) {
  return API.proceedingsKreptd.createProceedingKreptdBaseOnViolations(requestData, {
    ...endpointsConfig.createProceedingKreptdBaseOnViolations
  });
}

function useProceedingStartMutations() {
  return useMutation(startProceeding);
}

export default useProceedingStartMutations;
