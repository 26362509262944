import { EkmtCarnetSettlementDetails } from '@ibtm/domain';

import { useTableLayoutProps } from '@libs/common/v2';

import { usePermitEkmtItemsColumns } from '../columns';

function usePermitEkmttemsTable(resourceItems: EkmtCarnetSettlementDetails[]) {
  const { columns } = usePermitEkmtItemsColumns();

  return useTableLayoutProps({
    tableQuery: resourceItems,
    tableHookOptions: {
      columns,
      tableOptions: {
        manualPagination: false,
        initialState: {
          pageSize: 10
        }
      }
    }
  });
}

export default usePermitEkmttemsTable;
