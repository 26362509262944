import { QueryConfig, useQuery } from 'react-query';
import { DataSourceMediatorDetailsSnapshotPage } from '@stack/report';

import { API, endpointsConfig, ReportQueryKeysEnum } from '..';

export interface GetReportSourcesRequestParams {
  nameFragment?: string;
  dataSourceMediatorIds?: Array<string>;
  dataSourceIds?: Array<string>;
  size?: number;
  sort?: Array<string>;
  page?: number;
}

interface GetReportSourcesRequestParamsApiModel {
  pageNumber?: number;
  pageSize?: number;
  pageSort?: Array<string>;
  dataSourceIds?: Array<string>;
  dataSourceMediatorIds?: Array<string>;
  nameFragment?: string;
}

function modelToApi(params: GetReportSourcesRequestParams): GetReportSourcesRequestParamsApiModel {
  return {
    pageNumber: params.page,
    pageSize: params.size,
    pageSort: params.sort,
    dataSourceIds: params.dataSourceIds,
    dataSourceMediatorIds: params.dataSourceMediatorIds,
    nameFragment: params.nameFragment
  };
}

const getReportSources = async (_, params: GetReportSourcesRequestParams) => {
  const apiModel = modelToApi(params);
  const { data } = await API.mediatorDataSources.getReportSources(
    apiModel.pageNumber,
    apiModel.pageSize,
    apiModel.pageSort,
    apiModel.dataSourceIds,
    apiModel.dataSourceMediatorIds,
    apiModel.nameFragment,
    { ...endpointsConfig.getReportSources }
  );

  return data;
};

export default function useReportSourcesQuery(
  params: GetReportSourcesRequestParams,
  config: QueryConfig<DataSourceMediatorDetailsSnapshotPage, unknown>
) {
  return useQuery<DataSourceMediatorDetailsSnapshotPage>(
    [ReportQueryKeysEnum.REPORT_SOURCES, params],
    getReportSources,
    config
  );
}
