import { ReactNode, useEffect, useMemo, useState } from 'react';
import { FieldPath, FieldValues, useWatch } from 'react-hook-form';
import { GridProps, GridTypeMap } from '@mui/material';
import { isEmpty } from 'lodash';

import { GridItem, useFormV2Context } from '@libs/common/v2';

import { useMetaFormContext } from '@libs/meta-form/context';
import { getFieldValue } from '@libs/meta-form/services/useSubmitMutation';

interface IProps {
  dependendFieldId: FieldPath<FieldValues>;
  customAccesor?: string;
  value: ICondition;
  isGridItem?: boolean;
  gridItemProps: Omit<GridProps<GridTypeMap['defaultComponent']>, 'item'>;
}

interface ICondition {
  includes: Array<any>;
  notIncludes: Array<any>;
}

function ConditionallyVisible({
  dependendFieldId,
  customAccesor,
  value,
  renderNodes,
  components,
  children,
  isGridItem = true,
  /** Domyślnie przekazane są gridItemProps zdefiniowane w node "GRID", tutaj możemy je nadpisać */
  gridItemProps
}: IProps & {
  renderNodes: (children: ReactNode, components: any) => JSX.Element | JSX.Element[];
  components: any;
  children: ReactNode;
}) {
  const { fields } = useMetaFormContext();
  const { typeKey, properties } = fields.find(el => el.fieldId === dependendFieldId) ?? {};

  const { includes, notIncludes } = value ?? {};
  const { control, getValues } = useFormV2Context();
  const field = (useWatch({ control, name: dependendFieldId }) || getValues()?.[dependendFieldId]) as unknown;

  // workaround aby wywołać dodatkowy render ponieważ watch zwraca undefined lub defaultValue w pierwszym render,
  const [, setRerender] = useState(false);
  useEffect(() => {
    if (isEmpty(getValues())) {
      setRerender(prev => !prev);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldValue = getFieldValue({
    typeKey,
    formValue: field,
    formAccessor: customAccesor || properties.api?.submit?.formAccessor
  }) as unknown;

  const fieldValueIncludes = (conditionArray: Array<any>) =>
    Boolean(
      Array.isArray(fieldValue)
        ? conditionArray.find(item => fieldValue.includes(item))
        : conditionArray.includes(fieldValue)
    );

  const isVisible = (): boolean => {
    switch (true) {
      case Boolean(value.includes):
        return fieldValueIncludes(includes);
      case Boolean(value.notIncludes):
        return !fieldValueIncludes(notIncludes);
      default:
        return false;
    }
  };
  const visible = isVisible();
  const component = useMemo(
    () => {
      if (visible) {
        return isGridItem ? (
          <GridItem {...gridItemProps}>{renderNodes(children, components)}</GridItem>
        ) : (
          renderNodes(children, components)
        );
      }
      return null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visible]
  );

  return component;
}

export default ConditionallyVisible;
