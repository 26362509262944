import { Context, useContext } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';

import {
  BooleanValue,
  ComponentErrorBoundary,
  FormErrorType,
  FormV2Context,
  FormV2ContextState,
  getInputMode,
  InputMode,
  IToggleButtonOptions,
  ToggleButton,
  ToggleButtonInitialValue,
  Typography,
  Value
} from '@libs/common/v2';

export interface ToggleButtonFieldProps {
  name: FieldPath<FieldValues>;
  initialValue?: ToggleButtonInitialValue;
  options?: Array<IToggleButtonOptions>;
  orientation?: 'horizontal' | 'vertical';
  size?: 'small' | 'medium' | 'large';
  label?: string;
  formContext?: Context<FormV2ContextState>;
  inputMode?: InputMode;
  isRequired?: boolean;
  isDisabled?: boolean;
  isExclusive?: boolean;
}

function ToggleButtonField({
  name,
  initialValue = 'null',
  options,
  orientation = 'horizontal',
  size = 'medium',
  label,
  formContext = FormV2Context,
  inputMode,
  isRequired,
  isDisabled,
  isExclusive
}: ToggleButtonFieldProps) {
  const [t] = useTranslation();
  const { control, formMode, loading, isSubmitting } = useContext<FormV2ContextState>(formContext);
  const mode = getInputMode(formMode, inputMode);

  const getViewModeContent = (value, error) => {
    return isNil(value) ? (
      <Value label={label} isLoading={loading}>
        <Typography>{t('action.notApply')}</Typography>
      </Value>
    ) : (
      <BooleanValue
        label={label}
        value={value}
        isLoading={loading}
        isError={!!error && error?.type !== FormErrorType.WARNING}
        isWarning={error?.type === FormErrorType.WARNING}
        helperText={error?.message}
      />
    );
  };

  return (
    <ComponentErrorBoundary componentName={label || name || 'ToggleButtonField'}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return mode === InputMode.FORM ? (
            <ToggleButton
              fieldValue={value}
              label={label}
              fieldName={name}
              initialValue={initialValue}
              options={options}
              orientation={orientation}
              size={size}
              onChange={onChange}
              isError={!!error && error?.type !== FormErrorType.WARNING}
              isWarning={error?.type === FormErrorType.WARNING}
              isRequired={isRequired}
              isDisabled={isDisabled || isSubmitting}
              isExclusive={isExclusive}
            />
          ) : (
            getViewModeContent(value, error)
          );
        }}
      />
    </ComponentErrorBoundary>
  );
}

export default ToggleButtonField;
