import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderDetails } from '@ibtm/client-domain';

import { TableLayout, useFormV2Context } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { useBorrowFoldersTableProps } from '../hooks';
import { BorrowFolderSnapshot } from '../model';

import { BorrowFolderProvider } from './common/BorrowFolderProvider';
import { BorrowFolderHeaderActions, BorrowFolderRowActions, BorrowFoldersDialog } from '.';

function RentalTableWrapper({
  folderId,
  headerBorrowButtonTooltip,
  isHeaderReturnButtonVisible = false,
  isHeaderBorrowButtonDisabled = false
}: {
  folderId: string;
  headerBorrowButtonTooltip?: string;
  isHeaderReturnButtonVisible?: boolean;
  isHeaderBorrowButtonDisabled?: boolean;
}) {
  const { getValues } = useFormV2Context();
  const [selectedFolder, setSelectedFolder] = useState<{ value: string; label: string }>(null);
  const tableProps = useBorrowFoldersTableProps(folderId);

  useEffect(() => {
    const folderData: FolderDetails = getValues();
    if (folderData) {
      setSelectedFolder({
        value: folderData.id,
        label: folderData.number
      });
    }
  }, [getValues]);

  return (
    <BorrowFolderProvider selectedFolder={selectedFolder}>
      <RentalTable
        tableProps={tableProps}
        folderId={folderId}
        headerBorrowButtonTooltip={headerBorrowButtonTooltip}
        isHeaderReturnButtonVisible={isHeaderReturnButtonVisible}
        isHeaderBorrowButtonDisabled={isHeaderBorrowButtonDisabled}
      />
      <BorrowFoldersDialog />
    </BorrowFolderProvider>
  );
}

interface IProps {
  tableProps: any;
  folderId: string;
  headerBorrowButtonTooltip?: string;
  isHeaderReturnButtonVisible?: boolean;
  isHeaderBorrowButtonDisabled?: boolean;
}

function RentalTable({
  tableProps,
  headerBorrowButtonTooltip,
  isHeaderReturnButtonVisible = false,
  isHeaderBorrowButtonDisabled = false
}: IProps) {
  const [t] = useTranslation();

  return (
    <div style={{ width: '100%' }}>
      <TableLayout<BorrowFolderSnapshot, BorrowFolderSnapshot>
        {...tableProps}
        pageTitle={t('archive:borrowFolder.folderTitle')}
        headerActions={
          <BorrowFolderHeaderActions
            isHeaderReturnButtonVisible={isHeaderReturnButtonVisible}
            headerBorrowButtonTooltip={headerBorrowButtonTooltip}
            isHeaderBorrowButtonDisabled={isHeaderBorrowButtonDisabled}
          />
        }
        xlsxDownload={{
          fileName: t('archive:borrowFolder.title'),
          pathToXLSXTranslation: 'archive:borrowFolder.field',
          apiRequest: API.borrowFolders.getFolderRentalDetailsPage
        }}
        rowActions={({ original }) => {
          return <BorrowFolderRowActions row={original} hideOpenFolderButton />;
        }}
        isCollapsable
        isSection
      />
    </div>
  );
}

export default RentalTableWrapper;
