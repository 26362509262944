import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRowSelect } from 'react-table';
import { omit } from 'lodash';

import { DropdownListButton, useMultipleSelectColumn, useTableAdapter, useTableLayoutProps } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';
import { FormTransferForm } from '@libs/domain/resource/components/forms';
import { useResourceDialog } from '@libs/domain/resource/hooks/dialog';
import { DepotTransferDataContext } from '@libs/domain/resource/pages/ResourceTransferPage';

import { useResourceObjectContentPoolsQuery } from '../../api';
import { ListTypeEnum } from '../../model';
import { useResourceObjectContentPoolsColumns } from '../columns';

function SelectionActionRender(listType: ListTypeEnum, handleClose: () => void) {
  return <Actions listType={listType} handleClose={handleClose} />;
}

function Actions({ listType, handleClose }: { listType: ListTypeEnum; handleClose: () => void }) {
  const [t] = useTranslation();
  const depotTransferDataContext = useContext(DepotTransferDataContext);
  const { TARGET } = depotTransferDataContext;

  const { openDialog } = useResourceDialog();

  const table = useTableAdapter();
  const selectedRows = useMemo(() => table.selectedFlatRows.map(item => item.original), [table.selectedFlatRows]);

  const transfer = () => {
    handleClose();
    openDialog(FormTransferForm, { selectedRows, listType, depots: omit(depotTransferDataContext, 'change') });
  };

  const isMissingTargetId = useMemo(
    () => !depotTransferDataContext?.SOURCE?.id || !TARGET?.id,
    [depotTransferDataContext, TARGET]
  );

  const getTooltip = () => {
    if (isMissingTargetId) {
      return t('resource:messages.selectDepots');
    }

    return t('resource:actions.transfer');
  };

  return (
    <DropdownListButton
      icon={listType === ListTypeEnum.SOURCE ? 'ChevronsRightIcon' : 'ChevronsLeftIcon'}
      label={t('resource:actions.transfer')}
      tooltipTitle={getTooltip()}
      onClick={transfer}
      isDisabled={isMissingTargetId}
    />
  );
}

const initialParamsMapper = {
  depot: depot => ({ depotIdIn: depot.value ? [depot.value] : undefined })
};

const excludedStateKeyIn = [
  DomainDictionaryEntry.RESOURCE_FORM_STATE.RELEASED,
  DomainDictionaryEntry.RESOURCE_FORM_STATE.ISSUED_TO_ASSOCIATION,
  DomainDictionaryEntry.RESOURCE_FORM_STATE.ISSUED_TO_ENTREPRENEURS,
  DomainDictionaryEntry.RESOURCE_FORM_STATE.ADOPTED_BY_ASSOCIATION,
  DomainDictionaryEntry.RESOURCE_FORM_STATE.RETURNED_TO_GITD,
  DomainDictionaryEntry.RESOURCE_FORM_STATE.RETURNED_TO_ASSOCIATION,
  DomainDictionaryEntry.RESOURCE_FORM_STATE.PERMIT_RETURNED_TO_GITD,
  DomainDictionaryEntry.RESOURCE_FORM_STATE.EXTERNAL_RELEASED,
  DomainDictionaryEntry.RESOURCE_FORM_STATE.ORDERED,
  DomainDictionaryEntry.RESOURCE_FORM_STATE.TRANSFERRING,
  DomainDictionaryEntry.RESOURCE_FORM_STATE.ORDERED_BY_ASSOCIATION,
  DomainDictionaryEntry.RESOURCE_FORM_STATE.ASSIGNED,
  DomainDictionaryEntry.RESOURCE_FORM_STATE.PRINTED
];

function useResourceObjectContentPoolsTable({ depotId, listType }: { depotId?: string; listType: ListTypeEnum }) {
  const { columns, mappedFilterFields, mappedSortFields } = useResourceObjectContentPoolsColumns(excludedStateKeyIn);

  return useTableLayoutProps({
    tableHookQuery: useResourceObjectContentPoolsQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tablePlugins: [
        useRowSelect,
        useMultipleSelectColumn((_, handleClose) => SelectionActionRender(listType, handleClose))
      ],
      initialParamsConverter: initialParamsMapper
    },
    tableHookQueryInitialParams: {
      depotIdIn: [depotId],
      excludedStateKeyIn
    }
  });
}

export default useResourceObjectContentPoolsTable;
