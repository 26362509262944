import moment from 'moment';

import { useTableRowActions } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { useDeleteAction } from './useDeleteAction';

export const useFormOperationActions = () => {
  const { handleDelete } = useDeleteAction();
  const { renderTableActions } = useTableRowActions<any>([
    {
      id: 'PROCEEDING_FORM_OPERATIONS_DELETE_BUTTON',
      label: 'action.delete',
      onClick: ({ row: { original } }) => handleDelete(original.formId),
      icon: 'TrashIcon',
      isHidden: ({ row: { original } }) =>
        (original.statusKey === DomainDictionaryEntry.FORM_OPERATION_STATUS.EXPIRED &&
          moment().isBefore(original.validTo)) ||
        [
          DomainDictionaryEntry.FORM_OPERATION_STATUS.REVOKED,
          DomainDictionaryEntry.FORM_OPERATION_STATUS.SUSPENDED
        ].includes(original.statusKey)
    }
  ]);

  return renderTableActions(['PROCEEDING_FORM_OPERATIONS_DELETE_BUTTON']);
};
