import { usePaginatedQuery } from 'react-query';
import { DepotTransferSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { IDepotTransferClient } from '../../../model';
import { depotTransfersListParser } from '../../../utils';
import { endpointsConfig } from '../../endpoints-config';
import { ResourceQueryKeysEnum } from '..';

export const getOrders = async (params: DepotTransferSearchFilter) => {
  const { data } = await API.depotTransfer.getDepotTransferSnapshotPage(params, {
    ...endpointsConfig.getDepotTransferSnapshotPage
  });

  return {
    ...data,
    content: depotTransfersListParser(data.content)
  };
};

const useDepotTransfersQuery = (params: DepotTransferSearchFilter) =>
  usePaginatedQuery<IPaginatedModel<IDepotTransferClient>>(
    [ResourceQueryKeysEnum.DEPOT_TRANSFERS_LIST, params],
    (_, values) => getOrders(values)
  );

export default useDepotTransfersQuery;
