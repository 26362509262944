import { useTranslation } from 'react-i18next';

import { AdditionalFilterEnum, FormMode, TableHeaderButton, TableLayout, useDialog } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { DomainUIElementEnum } from '@libs/domain/config';
import { useTradeRulesTable } from '@libs/domain/social-commission/hooks';

import { TradeRulesTableEnum, TradeRulesTypeEnum } from '../../common/model/social-comission.model';
import { IntialYearType } from '../../SocialCommissionPage';

import DialogBasicLimit from './DialogBasicLimit';
import RowActions from './RowActions';

function SocialCommissionBasicRulesTable({
  initialYear,
  globalFilters
}: {
  initialYear?: IntialYearType;
  globalFilters?: (name: string) => {
    type: AdditionalFilterEnum;
    name: string;
    customFilter: JSX.Element;
  }[];
}) {
  const [t] = useTranslation();
  const tableProps = useTradeRulesTable([TradeRulesTypeEnum.FOLDER_LIMIT], initialYear);
  const { openDialog } = useDialog();
  const onOpenAdd = () => {
    openDialog(({ closeDialog }) => <DialogBasicLimit closeDialog={closeDialog} formMode={FormMode.CREATE} />);
  };

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('foreignPermits:tabs.socialCommission.tabs.SocialCommissionBasicRules.title')}
      xlsxDownload={{
        fileName: t('foreignPermits:tabs.socialCommission.tabs.SocialCommissionBasicRules.title'),
        pathToXLSXTranslation: 'foreignPermits:tabs.socialCommission.tabs.SocialCommissionBasicRules.fields',
        apiRequest: API.civicBoard.getCivicBoardRulesSnapshotPage
      }}
      headerActions={
        <TableHeaderButton
          isPrimary
          actionKey={DomainUIElementEnum.COMMISSION_BASIC_RULES_ADD}
          label={t('foreignPermits:tabs.socialCommission.tabs.SocialCommissionBasicRules.action.add')}
          onClick={onOpenAdd}
          className="ml-2"
          isFullWidth={false}
        />
      }
      globalFilters={globalFilters(TradeRulesTableEnum.YEAR)}
      rowActions={row => <RowActions row={row} />}
      isSectionFullTab
    />
  );
}

export default SocialCommissionBasicRulesTable;
