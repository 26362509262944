import { ResourceObjectClientReleaseRequest } from '@ibtm/client-domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function release(requestData: ResourceObjectClientReleaseRequest) {
  return API.client.resourceObjects.releaseClientResourceObject('', requestData, {
    ...endpointsConfig.releaseClientResourceObject
  });
}

function useReleaseMutation() {
  return useMutation(release);
}

export default useReleaseMutation;
