import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { array as YupArray, date as YupDate, object as YupObject, string as YupString } from 'yup';

export const useReplacementsSchema = mode => {
  const [t] = useTranslation();

  const replacementsSchema = useMemo(
    () =>
      YupObject().shape({
        startDateTime: YupDate().required().typeError(t('validation:required')),
        endDateTime: YupDate()
          .required()
          .typeError(t('validation:required'))
          .test('startDateTime', t('user:error.endDateTime'), function checkStartDateTime(value) {
            const endDateTime = moment(value);
            const startDateTime = moment(this.parent.startDateTime);
            const addDay = moment(startDateTime);

            return !addDay.isAfter(endDateTime);
          }),

        substituteAccountId: YupObject({
          id: YupString(),
          name: YupString()
        }).test('isValid', t('validation:required'), data => {
          return !!data?.id?.length && !!data?.name?.length;
        }),
        groupMembershipsIds: YupArray().required()
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mode]
  );
  return { replacementsSchema };
};
