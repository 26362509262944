import axios, { AxiosResponse } from 'axios';

import { useMutation } from '@libs/common/v2/api';

interface Args {
  file: File;
  uploadFileEndpoint?: string;
}

export interface IUploadFileResponse {
  id: string;
  data: {
    id: string;
  };
  file: File;
}

const API_PREFIX = process.env.REACT_APP_API_PREFIX;

const uploadFile = async ({ file, uploadFileEndpoint = `${API_PREFIX}/files` }: Args) => {
  const fileData = new FormData();
  fileData.append('file', file, file.name);

  const { data }: AxiosResponse<IUploadFileResponse> = await axios.post(uploadFileEndpoint, fileData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });

  return { data, file };
};

export default function useUploadFileMutation(options?) {
  return useMutation(uploadFile, options);
}
