import React from 'react';
import { Tooltip, TooltipProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { IconType, LoaderCircular } from '@libs/common/v2';
import * as icons from '@libs/common/v2/icons';
import { PaletteOption, PaletteOptions, Theme } from '@libs/common/v2/theme';
import { hexToRgba } from '@libs/common/v2/utils';

interface IProps {
  className?: string;
  icon: IconType;
  height?: number;
  width?: number;
  color?: keyof PaletteOptions;
  colorWeight?: keyof PaletteOption | number;
  isCurrentColor?: boolean;
  tooltipTitle?: string;
  placement?: TooltipProps['placement'];
  children?: React.ReactNode;
  iconWrapper?: string;
  isDisabled?: boolean;
}

export type IconComponent = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

function Icon({
  icon,
  className,
  height = 20,
  width = 20,
  color = 'grey',
  colorWeight = 700,
  tooltipTitle,
  placement = 'bottom',
  children,
  iconWrapper,
  isDisabled,
  isCurrentColor
}: IProps) {
  const IconComponent = icons[icon] as IconComponent;
  const classes = useStyles({ height, width, color, colorWeight, isDisabled, isCurrentColor });

  const IconContent = (
    <>
      {IconComponent ? <IconComponent className={clsx(classes.svg, className)} /> : <div />}
      {children}
    </>
  );
  const IconWithLoader = (
    <span className={clsx(iconWrapper, 'flex')}>
      {!IconComponent ? (
        <LoaderCircular size={width} isLoading={!IconComponent} isAbsolute={false}>
          {IconContent}
        </LoaderCircular>
      ) : (
        IconContent
      )}
    </span>
  );
  return tooltipTitle ? (
    <Tooltip placement={placement} title={tooltipTitle || ''}>
      {IconWithLoader}
    </Tooltip>
  ) : (
    IconWithLoader
  );
}

const useStyles = makeStyles<
  Theme,
  Pick<IProps, 'height' | 'width' | 'color' | 'colorWeight' | 'isDisabled' | 'isCurrentColor'>
>(theme => ({
  svg: {
    width: ({ width }) => width,
    height: ({ height }) => height,
    color: ({ color, colorWeight, isDisabled, isCurrentColor }) => {
      if (isCurrentColor) return 'unset';
      return isDisabled
        ? hexToRgba(theme.palette[color]?.[colorWeight] ?? theme.palette[color], 0.4)
        : theme.palette[color]?.[colorWeight] ?? theme.palette[color];
    }
  }
}));

export default Icon;
