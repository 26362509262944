import { ProceedingWithFormsCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const createProceedingWithForms = (params: ProceedingWithFormsCreateRequest) => {
  return API.proceeding.createProceedingWithForms(params, { ...endpointsConfig.createProceeding });
};
export const useProceedingCreateWithFormsMutation = () => useMutation(createProceedingWithForms);
