import _ from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';

import { ValidatorDetails } from './validation-model';

export const rule =
  (yupRule: Yup.Schema<any> | Yup.Schema<any>[]) =>
  (value: any): Promise<any> => {
    const yupRules = _.castArray(yupRule);
    return Promise.all(yupRules.map(rule => rule.validate(value)))
      .then(_.noop)
      .catch(({ message }) => message);
  };

// eslint-disable-next-line import/no-mutable-exports
export let string = _.noop;
// eslint-disable-next-line import/no-mutable-exports
export let number = _.noop;
// eslint-disable-next-line import/no-mutable-exports
export let date = _.noop;
// eslint-disable-next-line import/no-mutable-exports
export let requiredArray = _.noop;
// eslint-disable-next-line import/no-mutable-exports
export let requiredString = _.noop;
// eslint-disable-next-line import/no-mutable-exports
export let requiredObject = _.noop;
// eslint-disable-next-line import/no-mutable-exports
export let requiredNumber = _.noop;
// eslint-disable-next-line import/no-mutable-exports
export let requiredDate = _.noop;
// eslint-disable-next-line import/no-mutable-exports
export let positiveNumber = _.noop;

export const Rule = {
  string: _.noop as unknown as Yup.StringSchema<string>,
  requiredString: _.noop as unknown as Yup.StringSchema<string>,
  date: _.noop as unknown as Yup.ObjectSchema<Record<string, any>>,
  requiredDate: _.noop as unknown as Yup.ObjectSchema<Record<string, any>>,
  object: _.noop as unknown as Yup.ObjectSchema<Record<string, any>>,
  requiredObject: _.noop as unknown as Yup.ObjectSchema<Record<string, any>>,
  number: _.noop as unknown as Yup.NumberSchema<number>,
  positiveNumber: _.noop as unknown as Yup.NumberSchema<number>,
  requiredNumber: _.noop as unknown as Yup.NumberSchema<number>,
  requiredArray: _.noop as unknown as Yup.ArraySchema<unknown>
};

export function dateTypeTransform(this: Yup.ObjectSchema<Record<string, any>>, value: any, originalValue: any) {
  if (this.isType(value)) {
    return value;
  }
  const date = moment(originalValue);
  return date.isValid() ? date : _.noop();
}

export function numberTypeTransform(this: Yup.NumberSchema<number>, value: any) {
  return this.isType(value) ? value : _.noop();
}

export const initializeValidators = (yup: typeof Yup) => {
  Rule.string = yup.string().required();
  Rule.requiredString = Rule.string.nullable().required();
  Rule.date = yup.object().transform(dateTypeTransform);
  Rule.requiredDate = Rule.date.required();
  Rule.object = yup.object().nullable();
  Rule.requiredObject = Rule.object.required();
  Rule.number = yup.number().transform(numberTypeTransform);
  Rule.positiveNumber = Rule.number.min(0);
  Rule.requiredNumber = Rule.number.required();
  Rule.requiredArray = yup.array().required();

  string = rule(Rule.string);
  requiredString = rule(Rule.requiredString);
  date = rule(Rule.date);
  requiredDate = rule(Rule.requiredDate);
  requiredObject = rule(Rule.requiredObject);
  number = rule(Rule.number);
  positiveNumber = rule(Rule.positiveNumber);
  requiredNumber = rule(Rule.requiredNumber);
  requiredArray = rule(Rule.requiredArray);
};

export const CustomValidator = {
  validate: _.noop
};

const customRule =
  (yupRule: Yup.Schema<any>) =>
  (value: any): Promise<any> =>
    yupRule
      .validate(value)
      .then(() => _.noop())
      .catch(({ message }) => message);

const contextValidatorRule = yup => (validator: ValidatorDetails, required: boolean) =>
  yup
    .string()
    .nullable()
    .concat(required ? yup.string().required() : yup.string().notRequired())
    .matches(new RegExp(validator.regex), { message: validator.message, excludeEmptyString: true });

export const contextValidatorRuleValidate =
  yup =>
  (validator: ValidatorDetails, required = false) =>
    customRule(contextValidatorRule(yup)(validator, required));

export const initializeCustomValidator = yup => {
  CustomValidator.validate = contextValidatorRuleValidate(yup);
};
