import { useTranslation } from 'react-i18next';
import { FilterProps, Renderer } from 'react-table';
import { ApplicationSearchFilter, ApplicationSnapshot } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import { ColumnDefinition, SelectOption, TableFilter, TableFilterMapper, TableSortMapper } from '@libs/common/v2';
import {
  convertDateToDateTimeFormat,
  convertDateToDateWithoutTimeWithDayOffset,
  DateRangeISO,
  getCalendarDate,
  getConvertedCalendarDateValue,
  getValue
} from '@libs/common/v2/utils';

import { DictionaryValue, useDictionaryTranslations } from '@libs/dictionary';
import { UserFilterV2 } from '@libs/user';

import { DomainDictionaryEntry, DomainDictionaryEnum, useDomainConfigContext } from '@libs/domain/config';

import { ArchiveApplicationStatusMultiselectFilter } from '../../archive/common';
import { ApplicationStatusChip } from '../components';

export const getApplicationListMappedFilterField = (
  isDayOffset = false
): TableFilterMapper<ApplicationSnapshot, ApplicationSearchFilter> => ({
  number: (number: string) => ({ numberContains: number?.trim() }),
  paperNumber: (paperNumber: string) => ({ paperNumberContains: paperNumber?.trim() }),
  categoryKey: (categoryKeys: SelectOption[]) => ({ categoryKeyIn: categoryKeys.map(({ value }) => value) }),
  typeKey: (typeKeys: SelectOption[]) => ({ typeKeyIn: typeKeys.map(({ value }) => value) }),
  folderNumber: (folderNumber: string) => ({ folderNumberContains: folderNumber?.trim() }),
  statusKey: (statusKeys: SelectOption[]) => ({ statusKeyIn: statusKeys.map(({ value }) => value) }),
  submissionReceiptDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
    submissionReceiptDateGreaterThanOrEqual: getCalendarDate(dateFrom),
    submissionReceiptDateLessThanOrEqual: getCalendarDate(dateTo)
  }),
  applicationChannelKey: (applicationChannelKey: SelectOption[]) => ({
    applicationChannelKeyIn: applicationChannelKey.map(({ value }) => value)
  }),
  created: ({ dateFrom, dateTo }: DateRangeISO) => ({
    createdGreaterThanOrEqual: isDayOffset ? convertDateToDateWithoutTimeWithDayOffset(dateFrom) : dateFrom,
    createdLessThanOrEqual: dateTo
  }),
  authorName: (authorIdIn: SelectOption[]) => ({ authorIdIn: authorIdIn.map(({ value }) => value) }),
  subjectName: (subjectNameContains: string) => ({ subjectNameContains }),
  subjectNip: (nipContains: string) => ({ nipContains }),
  modified: ({ dateFrom, dateTo }: DateRangeISO) => ({
    modifiedGreaterThanOrEqual: isDayOffset ? convertDateToDateWithoutTimeWithDayOffset(dateFrom) : dateFrom,
    modifiedLessThanOrEqual: dateTo
  }),
  modifierName: (modifierNameKeys: SelectOption[]) => ({
    modifierIdIn: modifierNameKeys.map(({ value }) => value)
  }),
  expectedCompletionDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
    expectedCompletionDateGreaterThanOrEqual: getCalendarDate(dateFrom),
    expectedCompletionDateLessThanOrEqual: getCalendarDate(dateTo)
  }),
  driverCertificateNumber: (driverCertificateNumberContains: string) => ({ driverCertificateNumberContains })
});

export const applicationSortParamsConverted: TableSortMapper<ApplicationSnapshot> = {
  folderNumber: 'folder.number',
  authorName: 'author.name',
  subjectName: 'subject.name',
  subjectNip: 'subject.nip',
  modifierName: 'modifier.name',
  editorName: 'editor.name'
};

type ApplicationColumnSnapshot = ApplicationSnapshot;

function useApplicationTableColumns(isArchive?: boolean, numberHeader?: string): ColumnDefinition<ApplicationSnapshot> {
  const getLabel = partialTranslate('applications:field');
  const { translate } = useDictionaryTranslations();
  const [t] = useTranslation();
  const { isOperatorPortal, isClientPortal } = useDomainConfigContext();

  const clientApplicationStatusesToKeep = [
    DomainDictionaryEntry.APPLICATION_STATUS.RELEASED_WAITING_FOR_ZPO,
    DomainDictionaryEntry.APPLICATION_STATUS.NOT_CONSIDERED,
    DomainDictionaryEntry.APPLICATION_STATUS.ISSUED,
    DomainDictionaryEntry.APPLICATION_STATUS.DRAFT,
    DomainDictionaryEntry.APPLICATION_STATUS.INCOMPLETE
  ];

  const getStatusKeyFilter: Renderer<FilterProps<ApplicationColumnSnapshot>> = props => {
    if (isClientPortal) return null;
    return isArchive ? (
      <ArchiveApplicationStatusMultiselectFilter props={props} />
    ) : (
      <TableFilter.DictionaryMultiSelect dictionary={DomainDictionaryEnum.APPLICATION_STATUS} {...props} />
    );
  };

  return {
    number: {
      id: 'number',
      Header: numberHeader || getLabel('number'),
      accessor: (row: ApplicationSnapshot) => getValue(row.number),
      Filter: TableFilter.Text
    },
    paperNumber: {
      id: 'paperNumber',
      Header: getLabel('paperNumber'),
      accessor: (row: ApplicationSnapshot) => getValue(row.paperNumber),
      Filter: TableFilter.Text
    },
    categoryKey: {
      id: 'categoryKey',
      Header: getLabel('categoryKey'),
      accessor: (row: ApplicationColumnSnapshot) => (
        <DictionaryValue dictionaryEntryType={DomainDictionaryEnum.APPLICATION_CATEGORY} value={row?.categoryKey} />
      ),
      Filter: props => (
        <TableFilter.DictionaryMultiSelect dictionary={DomainDictionaryEnum.APPLICATION_CATEGORY} {...props} />
      ),
      xlsxFormatter: (row: ApplicationSnapshot) =>
        translate(DomainDictionaryEnum.APPLICATION_CATEGORY, row?.categoryKey, t('emptyMark')),
      width: 200
    },
    typeKey: {
      id: 'typeKey',
      Header: getLabel('typeKey'),
      accessor: (row: ApplicationColumnSnapshot) => (
        <DictionaryValue dictionaryEntryType={DomainDictionaryEnum.APPLICATION_TYPE} value={row.typeKey} />
      ),
      Filter: props => (
        <TableFilter.DictionaryMultiSelect dictionary={DomainDictionaryEnum.APPLICATION_TYPE} {...props} />
      ),
      xlsxFormatter: (row: ApplicationSnapshot) =>
        translate(DomainDictionaryEnum.APPLICATION_TYPE, row?.typeKey, t('emptyMark')),
      width: 200
    },
    folderNumber: {
      id: 'folderNumber',
      Header: getLabel('folderNumber'),
      accessor: (row: ApplicationSnapshot) => getValue(row?.folder?.number),
      Filter: TableFilter.Text
    },
    statusKey: {
      id: 'statusKey',
      Header: getLabel('statusKey'),
      tooltipTitle: (row: ApplicationSnapshot) =>
        isOperatorPortal || clientApplicationStatusesToKeep.includes(row?.statusKey)
          ? translate(DomainDictionaryEnum.APPLICATION_STATUS, row?.statusKey)
          : t('applications:message.inProgress'),
      accessor: (row: ApplicationColumnSnapshot) => (
        <ApplicationStatusChip
          status={
            isOperatorPortal || clientApplicationStatusesToKeep.includes(row?.statusKey)
              ? row?.statusKey
              : t('applications:message.inProgress')
          }
        />
      ),
      width: 270,
      Filter: props => getStatusKeyFilter(props),
      xlsxFormatter: (row: ApplicationSnapshot) =>
        translate(DomainDictionaryEnum.APPLICATION_STATUS, row?.statusKey, t('emptyMark')),
      disableSortBy: isClientPortal
    },
    submissionReceiptDate: {
      id: 'submissionReceiptDate',
      Header: getLabel('entryDate'),
      accessor: (row: ApplicationSnapshot) => getConvertedCalendarDateValue(row.submissionReceiptDate),
      Filter: TableFilter.Datepicker,
      width: 255
    },
    applicationChannelKey: {
      id: 'applicationChannelKey',
      Header: getLabel('entryTypeKey'),
      accessor: (row: ApplicationColumnSnapshot) => (
        <DictionaryValue
          dictionaryEntryType={DomainDictionaryEnum.APPLICATION_CHANNEL}
          value={row?.applicationChannelKey}
        />
      ),
      Filter: props => (
        <TableFilter.DictionaryMultiSelect dictionary={DomainDictionaryEnum.APPLICATION_CHANNEL} {...props} />
      ),
      xlsxFormatter: (row: ApplicationSnapshot) =>
        translate(DomainDictionaryEnum.APPLICATION_CHANNEL, row?.applicationChannelKey, t('emptyMark')),
      width: 200
    },
    created: {
      id: 'created',
      Header: getLabel('created'),
      accessor: (row: ApplicationSnapshot) => getValue(convertDateToDateTimeFormat(row.created)),
      Filter: TableFilter.Datepicker,
      width: 255
    },
    authorName: {
      id: 'authorName',
      Header: getLabel('authorName'),
      accessor: (row: ApplicationSnapshot) => getValue(row?.author?.name),
      Filter: (props: any) => <UserFilterV2 {...props} />
    },
    subjectName: {
      id: 'subjectName',
      Header: getLabel('subjectName'),
      accessor: (row: ApplicationSnapshot) => getValue(row?.subject?.name),
      Filter: TableFilter.Text
    },
    subjectNip: {
      id: 'subjectNip',
      Header: getLabel('subjectNip'),
      accessor: (row: ApplicationSnapshot) => getValue(row?.subject?.nip),
      Filter: TableFilter.Text
    },
    modified: {
      id: 'modified',
      Header: getLabel('modified'),
      accessor: (row: ApplicationSnapshot) => getValue(convertDateToDateTimeFormat(row.modified)),
      Filter: TableFilter.Datepicker,
      width: 255
    },
    modifierName: {
      id: 'modifierName',
      Header: getLabel('modifierName'),
      accessor: (row: ApplicationSnapshot) => getValue(row?.modifier?.name),
      Filter: (props: any) => <UserFilterV2 {...props} />
    },
    expectedCompletionDate: {
      id: 'expectedCompletionDate',
      Header: getLabel('completionDate'),
      accessor: (row: ApplicationSnapshot) => getConvertedCalendarDateValue(row.expectedCompletionDate),
      Filter: TableFilter.Datepicker,
      width: 255
    },
    editorName: {
      width: 160,
      id: 'editorName',
      Header: getLabel('editor'),
      accessor: (row: ApplicationSnapshot) => getValue(row.editor?.name)
    },
    parcelNumbers: {
      id: 'parcelNumbers',
      Header: getLabel('parcelNumbers'),
      accessor: (row: ApplicationSnapshot) => getValue(row.parcelNumbers?.join(', ')),
      width: 200
    },
    driverCertificateNumber: {
      id: 'driverCertificateNumber',
      Header: getLabel('driverCertificateNumber'),
      accessor: (row: ApplicationSnapshot) => getValue(row.driverCertificateNumber),
      Filter: TableFilter.Text,
      disableSortBy: true
    }
  };
}

export default useApplicationTableColumns;
