import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ForeignPermissionApplicationRecordWithLimitDetails } from '@ibtm/domain';
import { makeStyles } from '@mui/styles';

import { LoaderCircular, Section, useFormV2Context } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DictionaryValue, useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEntry, DomainDictionaryEnum, useDomainConfigContext } from '@libs/domain/config';
import { useFolderDetailsExtendedQuery } from '@libs/domain/folder';
import { ForeignNameKeyBaseEnum, ForeignPermissionApplicationDetailsModel } from '@libs/domain/foreign-permission';

import {
  ApplicationQueryKeysEnum,
  useCreateForeignPermissionApplication,
  useGetForeignPermissionApplicationRecords
} from '../../../api';
import { PermissionsRecordsQueryKeysEnum } from '../../../api/queries/useGetForeignPermissionApplicationRecords';

import EkmtTable from './EkmtTable';
import SinglePermissionMatrixTable from './SinglePermissionMatrixTable';

interface IProps {
  fieldId?: string;
  folderId: string;
  folderType: string;
  applicationId: string;
}

function PermissionMatrix({ fieldId, folderId, folderType, applicationId }: IProps) {
  const [t] = useTranslation();
  const classes = useStyles();
  const { translate } = useDictionaryTranslations();
  const { formMode, watch, setValue } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);
  const queryCache = useQueryCache();

  const { isOperatorPortal } = useDomainConfigContext();
  const [rowTitles, setRowTitles] = useState([]);

  const { mutate: createForeignPermission, isLoading } = useCreateForeignPermissionApplication();

  const { data: folderDetails } = useFolderDetailsExtendedQuery(folderId, { enabled: !!folderId });

  const typeKey = watch('foreignPermissionApplicationDetails-typeKey');

  const isEKMTType = useMemo(() => typeKey === DomainDictionaryEntry.FOREIGN_PERMIT_TYPE.EKMT, [typeKey]);

  const {
    data: foreignPermissionApplicationDetails,
    isFetching: isAppDataLoading,
    refetch
  } = useGetForeignPermissionApplicationRecords({
    applicationId,
    ...(isEKMTType && { ekmtCategoryKeyEqualsNull: true })
  });

  const permissionsFieldId = `${fieldId}.records`;

  const createRowTitles = (data: ForeignPermissionApplicationRecordWithLimitDetails[]) => {
    setRowTitles(
      data
        .sort((a, b) =>
          translate(DomainDictionaryEnum.RESOURCE_FORM_NAME, a.resourceType.nameKey, t('emptyMark')).localeCompare(
            translate(DomainDictionaryEnum.RESOURCE_FORM_NAME, b.resourceType.nameKey, t('emptyMark'))
          )
        )
        .map(item => ({
          id: item.resourceType.nameKey.replace(ForeignNameKeyBaseEnum.RESOURCE_FORM_NAME, ''),
          title: (
            <DictionaryValue
              dictionaryEntryType={DomainDictionaryEnum.RESOURCE_FORM_NAME}
              value={item.resourceType.nameKey}
            />
          ),
          limit: item.limit,
          amount: item.amount,
          resourceTypeId: item.resourceType.id,
          unlimited: item.unlimited
        }))
    );
  };

  useEffect(() => {
    if (typeKey) {
      // Stworzenie nowego modelu wniosku jezeli taki jeszcze nie istnieje
      if (!foreignPermissionApplicationDetails && !isAppDataLoading && folderDetails && !isLoading) {
        createForeignPermission(
          {
            applicationId,
            foreignPermissionApplicationCreateRequest: {
              association: folderType === DomainDictionaryEntry.FOLDER_TYPE.ZZ || !!folderDetails?.association,
              typeKey
            }
          },
          {
            onSuccess: ({ data }) => {
              createRowTitles(
                data.foreignPermissionApplicationDataRecordsDetails as ForeignPermissionApplicationRecordWithLimitDetails[]
              );
              queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
              queryCache.invalidateQueries([PermissionsRecordsQueryKeysEnum.FOREIGN_PERMISSIONS_RECORDS]);
              setValue('foreignPermissionApplicationDetails-association', data.association);
              refetch();
            }
          }
        );
      }

      // Dodawanie danych do tabeli na podstawie danych jesli istnieja
      if (foreignPermissionApplicationDetails?.content) {
        createRowTitles(
          foreignPermissionApplicationDetails?.content as ForeignPermissionApplicationRecordWithLimitDetails[]
        );

        const defaultData = foreignPermissionApplicationDetails?.content.reduce((acc, item) => {
          const formKey = item?.resourceType?.nameKey.replace(ForeignNameKeyBaseEnum.RESOURCE_FORM_NAME, '');
          const values: ForeignPermissionApplicationDetailsModel = {
            amount: item.amount,
            euro5amount: item.euro5amount,
            euro6amount: item.euro6amount,
            notes: item.notes,
            limit: item.limit,
            resourceTypeId: item.resourceType.id
          };

          return { ...acc, [formKey]: values };
        }, {});

        setValue(fieldId, {
          records: defaultData,
          typeKey,
          association: foreignPermissionApplicationDetails?.association
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppDataLoading, foreignPermissionApplicationDetails, typeKey, folderDetails, isLoading]);
  const EKMT_ANNUAL_PROVISION_CATEGORIES = [
    DomainDictionaryEntry.EKMT_CATEGORY.ANNUAL_PROVISION,
    DomainDictionaryEntry.EKMT_CATEGORY.ANNUAL_PROVISION_BASIC
  ];
  const EKMT_RELOCATION_CATEGORIES = [DomainDictionaryEntry.EKMT_CATEGORY.MOVE];
  return isEKMTType ? (
    <>
      <EkmtTable
        title={t('permission:table.ekmtPermissions')}
        applicationId={applicationId}
        folderId={folderId}
        viewMode={viewMode}
        queryParams={{ ekmtCategoryKeyEqualsNull: true }}
        visibleColumns={['resourceType', 'euro5amount', 'euro6amount', 'notes']}
        editableColumns={['euro5amount', 'euro6amount', 'notes']}
      />
      {isOperatorPortal && (
        <>
          <EkmtTable
            applicationId={applicationId}
            title={t('permission:table.ekmtPermissionsReserve')}
            folderId={folderId}
            viewMode={viewMode}
            queryParams={{ ekmtCategoryKeyIn: EKMT_ANNUAL_PROVISION_CATEGORIES, ekmtCategoryKeyEqualsNull: false }}
            visibleColumns={['resourceType', 'concernForeignPermission', 'notes']}
            editableColumns={['resourceType', 'concernForeignPermission', 'notes']}
            ekmtCategoryKeys={EKMT_ANNUAL_PROVISION_CATEGORIES}
            isDeleteButtonVisible
            addButtonVisible
          />
          <EkmtTable
            applicationId={applicationId}
            title={t('permission:table.ekmtPermissionsRelocation')}
            folderId={folderId}
            viewMode={viewMode}
            queryParams={{ ekmtCategoryKeyIn: EKMT_RELOCATION_CATEGORIES, ekmtCategoryKeyEqualsNull: false }}
            ekmtCategoryKeys={EKMT_RELOCATION_CATEGORIES}
            visibleColumns={['resourceType', 'amount']}
            editableColumns={['resourceType', 'amount']}
            isDeleteButtonVisible
            validateUniqueResourceTypeInList
            addButtonVisible
          />
        </>
      )}
    </>
  ) : (
    <Section title={t('permission:table.singlePermissions')} isPadded={false}>
      <>
        {isAppDataLoading && (
          <LoaderCircular isLoading={isLoading} isAbsolute={false} className="flex justify-center p-10" />
        )}
        {!isAppDataLoading && (
          <SinglePermissionMatrixTable
            classes={classes}
            rowTitles={rowTitles}
            viewMode={viewMode}
            mataformFieldId={permissionsFieldId}
          />
        )}
      </>
    </Section>
  );
}

export default PermissionMatrix;

const useStyles = makeStyles(() => ({
  rowContainer: {
    display: 'flex',
    width: '100%',
    '& > div': {
      width: '100%'
    }
  },
  root: {
    display: 'inline-block'
  },
  loaderWrapper: {
    position: 'relative',
    height: 80
  },
  tableContent: {
    overflowY: 'auto',
    borderRadius: '8px'
  },
  hidden: {
    display: 'none'
  }
}));
