import { PermissionElementMap } from '@libs/permission';

import { PermissionEnum } from '@libs/domain/config/permission/PermissionEnum';

import DocumentTemplateUIElementEnum from './DocumentTemplateUIElementEnum';

const DocumentTemplatePermissionElementMapper: PermissionElementMap<DocumentTemplateUIElementEnum> = {
  [DocumentTemplateUIElementEnum.DOCUMENT_TEMPLATES_PUBLISH_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_TEMPLATE_ADMIN]
  },
  [DocumentTemplateUIElementEnum.DOCUMENT_TEMPLATES_FILE_UPLOAD_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_TEMPLATE_ADMIN]
  },
  [DocumentTemplateUIElementEnum.DOCUMENT_TEMPLATES_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_TEMPLATE_VIEW]
  },
  [DocumentTemplateUIElementEnum.DOCUMENT_TEMPLATES_LIST_TAB]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_TEMPLATE_VIEW]
  },
  [DocumentTemplateUIElementEnum.DOCUMENT_TEMPLATES_ADD_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_TEMPLATE_ADMIN]
  },
  [DocumentTemplateUIElementEnum.DOCUMENT_TEMPLATES_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_TEMPLATE_ADMIN]
  },
  [DocumentTemplateUIElementEnum.DOCUMENT_TEMPLATES_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_TEMPLATE_ADMIN]
  },
  [DocumentTemplateUIElementEnum.DOCUMENT_TEMPLATES_EXPORT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_TEMPLATE_VIEW]
  },
  [DocumentTemplateUIElementEnum.DOCUMENT_TEMPLATES_FILE_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.IBTM_DOMAIN_DOCUMENT_TEMPLATE_ADMIN]
  }
};

export default DocumentTemplatePermissionElementMapper;
