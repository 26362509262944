import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PermissionTypeCode, ResourcesReassignmentRequest } from '@ibtm/domain';

import { Dialog, DictionarySelectField, FormV2Context, SelectOption, typedNameV2 } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { PrintsQueryKeysEnum, useRegenerateResourceAssignmentMutation } from '../../../api';
import {
  PrintActionType,
  usePrintsActionsContext,
  usePrintsActionsDataContext,
  usePrintsDetailsDataContext
} from '../../../context';
import { ForeignPermissionRegenerateForm } from '../../../model';

function RegenerateConfirmationDialog() {
  const queryCache = useQueryCache();
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const {
    actionType,
    actionData: { resourcesReassignments, onSuccess }
  } = usePrintsActionsDataContext();
  const { finishAction } = usePrintsActionsContext();
  const [t] = useTranslation();
  const { mutate: regenerate, isLoading } = useRegenerateResourceAssignmentMutation();
  const form = useForm();
  const { watch, getValues } = form;
  const {
    printDetailsData: { depotMP, depotMPD }
  } = usePrintsDetailsDataContext();
  useEffect(
    () =>
      watch(values => {
        if (values?.reasonsForReprinting?.value) {
          setConfirmDisabled(false);
        } else {
          setConfirmDisabled(true);
        }
      }).unsubscribe,
    [watch]
  );
  const confirmHandler = () => {
    const reprintReasonKey = getValues(
      typedNameV2<ForeignPermissionRegenerateForm>('reasonsForReprinting')
    ) as SelectOption<string>;

    const assignment: Array<ResourcesReassignmentRequest> = resourcesReassignments.resources.map(item => ({
      ...item,
      reprintReasonKey: reprintReasonKey.value
    }));

    regenerate(
      {
        assignment,
        depotId:
          resourcesReassignments.permissionTypeCode === PermissionTypeCode.FOREIGN_PERMISSION ? depotMP.id : depotMPD.id
      },
      {
        onSuccess: () => {
          onSuccess();
          finishAction();
          queryCache.invalidateQueries([PrintsQueryKeysEnum.PRINTS_LAST_PRINTED_PERMISSIONS]);
        }
      }
    );
  };

  const cancelHandler = () => {
    finishAction();
  };

  return (
    <Dialog
      title={t('prints:messages.regeneratePermission')}
      confirmText={t('prints:actions.regenerate')}
      cancelText={t('action.cancel')}
      onConfirm={confirmHandler}
      onCancel={cancelHandler}
      isConfirmLoading={isLoading}
      isConfirmButtonDisabled={confirmDisabled}
      isOpen={actionType === PrintActionType.Regenerate}
    >
      <FormV2Context.Provider value={form}>
        <DictionarySelectField
          name={typedNameV2<ForeignPermissionRegenerateForm>('reasonsForReprinting')}
          dictionaryName={DomainDictionaryEnum.REASONS_FOR_REPRINTING}
          label={t('prints:formField.rePrintReason')}
          isRequired
        />
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default RegenerateConfirmationDialog;
