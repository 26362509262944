import React from 'react';

interface IProps {
  children: React.ReactElement<any, any>[] | React.ReactNode;
}

function TabsGroup({ children }: IProps) {
  return <div className="w-full">{children}</div>;
}

export default TabsGroup;
