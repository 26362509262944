import { useTranslation } from 'react-i18next';
import { QueryCache } from 'react-query';
import { useSnackbar } from '@enigma/fe-ui';
import { UserTaskDetails } from '@ibtm/process-api';
import { List, ListSubheader } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

import { DropdownButton, useConfirmDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { WarningInformation } from '@libs/common/v2/components/warning';
import { unescapeValue } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';

import {
  Application,
  ApplicationQueryKeysEnum,
  ProcessMessageKeyEnum,
  useProcessConfirmTaskMutation
} from '@libs/domain/application';
import { DomainDictionaryEnum } from '@libs/domain/config';

import { useSendMessageMutation } from '../api';
import {
  useAssignApplicationDialog,
  useProcessFormConfig,
  useProcessFormDialog,
  useProcessWarningConfig
} from '../hooks';
import { checkIfTaskConfirmationIsRequired } from '../utils';

import ProcessExecuteTaskList from './ProcessExecuteTaskList';

interface Props {
  tasks: UserTaskDetails[];
  applicationId: string;
  applicationVersion: number;
  onSendMessageSuccess?: (onRefetchFinish?: (data: Application) => void) => void;
  onConfirmTaskSuccess?: () => void;
  onApplicationCancelled?: () => void;
  isTasksLoading?: boolean;
  fieldsDependentWarning?: { [key: string]: any };
  isCancelDisabled?: boolean;
}

const refetchAfterTaskExecution = (queryCache: QueryCache, applicationId: string) =>
  queryCache.invalidateQueries(query => {
    const keysToExclude = [
      ApplicationQueryKeysEnum.TASKS,
      ApplicationQueryKeysEnum.TASK,
      ApplicationQueryKeysEnum.APPLICATION_UI_DEFINITION,
      ApplicationQueryKeysEnum.APPLICATION_UI_DEFINITION_COMMON
    ] as Array<string>;
    const queryKey = query.queryKey[0];
    const request = query.queryKey[1];
    const isRequestObject = _.isObject(request);
    // @ts-ignore
    if (isRequestObject && request.hasOwnProperty('applicationId') && request.applicationId !== applicationId) {
      return false;
    }
    const keysWithPlainQuery = [ApplicationQueryKeysEnum.APPLICATION, ApplicationQueryKeysEnum.APPLICATION_FLAGS];
    if (
      !isRequestObject &&
      keysWithPlainQuery.includes(queryKey as ApplicationQueryKeysEnum) &&
      request !== applicationId
    ) {
      return false;
    }
    return !keysToExclude.includes(queryKey as string) && query.state.data;
  });

function ProcessExecuteButton({
  tasks,
  applicationId,
  applicationVersion,
  onConfirmTaskSuccess,
  onSendMessageSuccess,
  onApplicationCancelled,
  isTasksLoading,
  fieldsDependentWarning,
  isCancelDisabled
}: Props) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  const { showProcessFormDialog } = useProcessFormDialog();
  const { showAssignApplicationDialog } = useAssignApplicationDialog();
  const { processWarningConfig } = useProcessWarningConfig();
  const { processFormConfig } = useProcessFormConfig();

  const [confirm] = useConfirmDialog();
  const { showSuccessSnackbar } = useSnackbar();
  const classes = useStyles();

  const { mutate: confirmTask, isLoading: confirmTaskMutation } = useProcessConfirmTaskMutation();
  const { mutate: sendMessage, isLoading: sendMessageMutation } = useSendMessageMutation();

  const handleConfirmUserTask = (task: UserTaskDetails) => {
    const formConfig = processFormConfig.find(task.formKey);

    if (
      [
        ProcessMessageKeyEnum.VERIFY_APPLICATION_COMPLETENESS_FORM,
        ProcessMessageKeyEnum.CHOOSE_ACTION_PATH_FORM
      ].includes(task.formKey as ProcessMessageKeyEnum)
    ) {
      showAssignApplicationDialog({
        applicationId,
        applicationVersion,
        onFinish: () => {
          onSendMessageSuccess();
          completeMainTask(task);
        }
      });
      return;
    }

    if (formConfig) {
      showProcessFormDialog({
        messageKey: task.formKey,
        applicationId,
        applicationVersion,
        formConfig,
        onFinish: () => {
          onSendMessageSuccess();

          if (formConfig.isApproveRequestNeeded) {
            completeMainTask(task);
          }
        }
      });
      return;
    }

    completeMainTask(task);
  };

  const completeMainTask = task => {
    const warningConfig = processWarningConfig.find(task.taskKey);
    if (checkIfTaskConfirmationIsRequired(task.taskKey)) {
      confirm({
        message: (
          <div>
            {translate(
              DomainDictionaryEnum.PROCESS_TASK_MAIN_ACTION_CONFIRMATION_TEXT,
              `process.task.main.action.confirmation.text.${task.taskKey}`,
              translate(
                DomainDictionaryEnum.PROCESS_TASK_MAIN_ACTION_NAME,
                task?.taskKey,
                t('processes:dialog.processConfirmationConfirm')
              )
            )}
            {warningConfig?.condition(fieldsDependentWarning) && (
              <div className="mt-12">
                <WarningInformation content={warningConfig.title} />
              </div>
            )}
          </div>
        ),
        title: t('processes:task.confirmTaskFinish'),
        shortTitle: t('processes:task.confirmTaskFinishShort'),
        onConfirm: (setConfirmLoading, closeDialog) => {
          setConfirmLoading(true);
          confirmTask(task.taskId, {
            onSuccess: () => {
              showSuccessSnackbar(t('processes:message.processTaskConfirmSuccess'));
              onConfirmTaskSuccess();
              closeDialog();
              refetchAfterTaskExecution(queryCache, applicationId);
            },
            onSettled: () => {
              setConfirmLoading(false);
            }
          });
        },
        confirmType: 'primary'
      });
    } else {
      confirmTask(task.taskId, {
        onSuccess: () => {
          showSuccessSnackbar(t('processes:message.processTaskConfirmSuccess'));
          onConfirmTaskSuccess();
        }
      });
    }
  };

  const handleSendActionTask = (processInstanceId: string, messageKey: ProcessMessageKeyEnum) => {
    const formConfig = processFormConfig.find(messageKey);

    if (formConfig) {
      showProcessFormDialogForAlternativeTask({
        messageKey,
        onFinish: () => {
          if (formConfig.isApproveRequestNeeded) {
            completeAlternativeTask(processInstanceId, messageKey);
          }
        }
      });
      return;
    }

    completeAlternativeTask(processInstanceId, messageKey);
  };

  const showProcessFormDialogForAlternativeTask = ({
    messageKey,
    onFinish,
    version
  }: {
    messageKey: string;
    onFinish?: (data?: Application) => void;
    version?: number;
  }) => {
    const formConfig = processFormConfig.find(messageKey);

    showProcessFormDialog({
      messageKey,
      applicationId,
      applicationVersion: version || applicationVersion,
      formConfig,
      onFinish: () => {
        onSendMessageSuccess();
        onFinish?.();
      }
    });
  };

  const completeAlternativeTask = (
    processInstanceId: string,
    messageKey: ProcessMessageKeyEnum,
    // onFinish zostanie wykonany po pobraniu nowych szczegółów dla wniosku (wymagana najnowsza wersja danych)
    onFinish?: (data?: Application) => void
  ) => {
    const translatedKey = translate(
      DomainDictionaryEnum.PROCESS_MESSAGE_CONFIRMATION_TEXT,
      `process.message.confirmation.text.${messageKey}`,
      translate(DomainDictionaryEnum.PROCESS_MESSAGE_LABEL, messageKey, messageKey)
    );
    const successMessage = t(
      'processes:message.processSentMessageSuccess',
      unescapeValue({ actionName: translatedKey })
    );

    if (checkIfTaskConfirmationIsRequired(messageKey)) {
      confirm({
        message: translatedKey,
        title: t('processes:task.confirmTaskFinish'),
        shortTitle: t('processes:task.confirmTaskFinishShort'),
        onConfirm: (setConfirmLoading, closeDialog) => {
          setConfirmLoading(true);
          sendMessage(
            { processInstanceId, messageKey },
            {
              onSuccess: () => {
                if (ProcessMessageKeyEnum.CANCEL_APPLICATION === messageKey) {
                  onApplicationCancelled?.();
                  showSuccessSnackbar(successMessage);
                  closeDialog();
                } else {
                  showSuccessSnackbar(successMessage);
                  onSendMessageSuccess(newData => {
                    onFinish?.(newData);
                  });
                  closeDialog();
                }
              },
              onSettled: () => {
                setConfirmLoading(false);
              }
            }
          );
        },
        confirmType: 'primary'
      });
    } else {
      sendMessage(
        { processInstanceId, messageKey },
        {
          onSuccess: () => {
            showSuccessSnackbar(successMessage);
            onSendMessageSuccess(newData => {
              onFinish?.(newData);
            });
          }
        }
      );
    }
  };

  return (
    <div className="flex-wrapped-padding-8">
      <DropdownButton
        title={t('processes:action.execute')}
        buttonType="button"
        isLoading={sendMessageMutation || confirmTaskMutation || isTasksLoading}
        isNoMargin={false}
        forceRender
      >
        {({ handleClose }) => {
          if (tasks.length > 0) {
            return tasks.map(task => {
              return (
                <List
                  key={task.taskId}
                  subheader={
                    <ListSubheader className={classes.subHeader} component="div" id="nested-list-subheader">
                      {task.taskName}
                    </ListSubheader>
                  }
                  className={classes.root}
                >
                  <ProcessExecuteTaskList
                    task={task}
                    onTaskConfirmClick={() => {
                      handleClose();
                      handleConfirmUserTask(task);
                    }}
                    onSendActionClick={messageKey => {
                      handleClose();
                      handleSendActionTask(task.processInstanceId, messageKey as ProcessMessageKeyEnum);
                    }}
                    isCancelDisabled={isCancelDisabled}
                  />
                </List>
              );
            });
          }
          return (
            <List
              component="nav"
              subheader={<ListSubheader>{t('processes:task.noTasks')}</ListSubheader>}
              className={classes.root}
            />
          );
        }}
      </DropdownButton>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  subHeader: {
    padding: '9px 16px',
    boxShadow: '0px 1px 2px rgb(16 24 40 / 5%)',
    lineHeight: 'initial'
  }
}));

export default ProcessExecuteButton;
