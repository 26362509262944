import { CalendarDate } from 'calendar-date';
import { isEmpty, isNil } from 'lodash';
import Moment, { Moment as MomentType } from 'moment';
import { extendMoment } from 'moment-range';

import {
  ALTERNATIVE_DATE_FORMAT,
  ALTERNATIVE_DATE_TIME_FORMAT,
  DATE_STANDARD_LENGTH,
  DATE_TIME_FORMAT_ISO,
  DATE_TIME_WITH_WARSAW_OFFSET
} from '@libs/common/v2';

import { getValue } from './common.utils';

const moment = extendMoment(Moment as never);

export interface DateRange {
  dateFrom?: MomentType;
  dateTo?: MomentType;
}

export interface DateRangeISO {
  dateFrom?: string;
  dateTo?: string;
}

export function convertDateToDateWithoutTimeWithDayOffset(
  date: Date | string,
  format = ALTERNATIVE_DATE_FORMAT,
  isSubtract = true
): string {
  if (isSubtract) {
    return (date ? moment(date).subtract(1, 'd').format(format) + DATE_TIME_WITH_WARSAW_OFFSET : date) as string;
  }

  return (date ? moment(date).format(format) + DATE_TIME_WITH_WARSAW_OFFSET : date) as string;
}

export function convertDateToDateTimeFormat(date: Date | string, format = ALTERNATIVE_DATE_TIME_FORMAT): string {
  return (date ? moment(date).format(format) : date) as string;
}

export function convertDateToDateFormat(date: Date | string, format = ALTERNATIVE_DATE_FORMAT): string {
  return (date ? moment(date).format(format) : date) as string;
}

export function convertDateToStringWithoutTimezone(date: Date | string): string {
  return date ? moment(date).utc(true).toISOString() : '';
}

export function convertDatesToRangeTime(range: Required<DateRange>, attrNames: Required<string[]>, format?: string) {
  const [minAttrName, maxAttrName] = attrNames;
  if (format) {
    return {
      [minAttrName]: range.dateFrom?.startOf('day').format(format),
      [maxAttrName]: range.dateTo?.endOf('day').format(format)
    };
  }
  return {
    [minAttrName]: range.dateFrom?.startOf('day').toISOString(),
    [maxAttrName]: range.dateTo?.endOf('day').toISOString()
  };
}

export const addOneDayToISODate = value => {
  const date = new Date(value);
  date.setDate(date.getDate() + 1);
  return date.toISOString();
};

export function convertDateToStringWithTimeISO(date: Date | string, format = DATE_TIME_FORMAT_ISO): string {
  // Ucinamy końcówkę `.xxxZ` która nie jest akceptowana przez BE
  return date ? moment(date).format(format).slice(0, 19) : null;
}

export const getDateOrNull = (value: string): string | null => {
  if (isNil(moment(value).toISOString())) {
    return null;
  }

  return value;
};

export const isDateLengthValid = (value: string) => {
  return value?.length === DATE_STANDARD_LENGTH;
};

export const getCalendarDate = (value: string | Date): CalendarDate => {
  const parsedValue = convertDateToDateFormat(value);
  const isDateEmptyOrNull = isEmpty(parsedValue) || isNil(parsedValue);

  return isDateEmptyOrNull ? null : new CalendarDate(parsedValue);
};

export const convertCalendarDate = (value: CalendarDate): string => {
  return value ? value.toString() : null;
};

export const convertDateWithoutTimezoneToCalendarDate = (value: CalendarDate): CalendarDate => {
  return getCalendarDate(convertDateToStringWithoutTimezone(convertCalendarDate(value)));
};

export const getConvertedCalendarDateValue = (value: CalendarDate): string => {
  return getValue(convertDateToDateFormat(convertCalendarDate(value)));
};
