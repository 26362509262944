import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { FormMode, useFormV2Context } from '@libs/common/v2';

import { DomainUIElementEnum } from '@libs/domain/config';
import { SimpleNoteSection } from '@libs/domain/notes';

import { ISuspensionDetailsParams } from '../../../../model';

interface IProps {
  formMode: FormMode;
  setParentFormValues?: UseFormSetValue<FieldValues>;
}

function SuspensionsNoteTab({ formMode, setParentFormValues }: IProps) {
  const { id } = useParams<ISuspensionDetailsParams>();
  const { setValue } = useFormV2Context();

  const onNoteOrVersionChange = (note: string, version: number, noteId: string) => {
    setValue('noteContent', note);
    setValue('noteVersion', version);
    setValue('noteId', noteId);
  };

  return (
    <SimpleNoteSection
      formMode={formMode}
      suspensionId={id}
      onNoteOrVersionChange={onNoteOrVersionChange}
      cancelActionKey={DomainUIElementEnum.SUSPENSIONS_NOTE_DELETE_BUTTON}
      setParentFormValues={setParentFormValues}
    />
  );
}

export default SuspensionsNoteTab;
