import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MutateFunction } from 'react-query';
import { useParams } from 'react-router';
import { useSnackbar } from '@enigma/fe-ui';
import {
  DocumentsApiCancelDocumentRequest,
  DocumentsApiUpdateDocumentFinalDecisionRequest,
  DocumentSearchFilter,
  DocumentSnapshot,
  DocumentSnapshotsPage
} from '@ibtm/domain';
import { AxiosPromise, AxiosResponse } from 'axios';

import { KeyType } from '@libs/common';
import { Button, FormMode, TableIconButton, TableLayout, useConfirmDialog, useTableRowActions } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { ValueOf } from '@libs/common/v2/utils';

import { useFileDownload } from '@libs/file';
import { UiMode } from '@libs/meta-form';
import { UIElementNameEnum } from '@libs/permission';

import { API } from '@libs/domain/api';
import { DomainDictionaryEntry, DomainUIElementEnum, IPermissionsKeys } from '@libs/domain/config';

import { ApplicationQueryKeysEnum } from '../../application';
import { IApplicationDetailsPageParams } from '../../application/model';
import { RoadTransportQueryKeysEnum } from '../../road-transport-kreptd/api';
import { DocumentsQueryKeysEnum, useDownloadDocumentQuery } from '../api';
import { DocumentsSnapshotClient, HeaderActionEnum, RowActionEnum } from '../model';
import { parseDocumentsList } from '../parsers';

interface IProps {
  openGenerateDocumentDialog: (data: {
    title?: string;
    groupKey?: string;
    isGroupFieldDisabled?: boolean;
    isDocumentDownloadingAfterGenerate?: boolean;
    onSuccess?: () => void;
  }) => void;
  openUpdateDocumentDialog: (documentData: DocumentsSnapshotClient, onSuccess: any, suspensionId?: string) => void;
  openEnterDeliveryDateDialog: (documentData: DocumentsSnapshotClient, onSuccess: any) => void;
  mode: UiMode | FormMode;
  parentId?: string;
  apiRequest?:
    | ((documentSearchFilter: DocumentSearchFilter, options?: any) => AxiosPromise<DocumentSnapshotsPage>)
    | ((
        parentId: string,
        documentSearchFilter: DocumentSearchFilter,
        options?: any
      ) => AxiosPromise<DocumentSnapshotsPage>);
  queryKey?: string;
  applicationStatus?: (typeof DomainDictionaryEntry.APPLICATION_STATUS)[keyof typeof DomainDictionaryEntry.APPLICATION_STATUS];
  cancelDocument?: MutateFunction<AxiosResponse<any>, unknown, any, unknown>;
  forwardToShipment?: MutateFunction<AxiosResponse<any>, unknown, any, unknown>;
  finalDecisionDocument?: MutateFunction<AxiosResponse<any>, unknown, any, unknown>;
  tableProps?: any;
  headerActions?: Array<ValueOf<HeaderActionEnum>>;
  permissionsKeys?: IPermissionsKeys;
  rowActions?: Array<ValueOf<RowActionEnum>>;
  tableActionKeys?: Array<UIElementNameEnum>;
  isCollapsable?: boolean;
  isCancelActionHidden?: (item: DocumentsSnapshotClient) => boolean;
  isForwardToShipmentActionHidden?: (item: DocumentsSnapshotClient) => boolean;
  isEnterDeliveryDateActionHidden?: (item: DocumentsSnapshotClient) => boolean;
  isReceiveDateDocumentActionDisabled?: (documentStatus: string, disabledStatuses?: string[]) => boolean;
  customDisabledLabelKey?: KeyType;
  setGroupKeyIn?: Dispatch<SetStateAction<boolean>>;
  setGroupKeyNotIn?: Dispatch<SetStateAction<boolean>>;
  groupKeyNotIn?: boolean;
  groupKeyIn?: boolean;
}

function DocumentsTable({
  queryKey = DocumentsQueryKeysEnum.DOCUMENTS_LIST,
  mode,
  apiRequest = parameters => API.documents.getDocumentsSnapshotPage(parameters),
  applicationStatus,
  cancelDocument,
  forwardToShipment,
  finalDecisionDocument,
  tableProps,
  parentId,
  openGenerateDocumentDialog,
  openUpdateDocumentDialog,
  openEnterDeliveryDateDialog,
  headerActions = Object.values(HeaderActionEnum),
  permissionsKeys,
  rowActions = [
    RowActionEnum.CANCEL_DOCUMENT,
    RowActionEnum.DOWNLOAD_DOCUMENT,
    RowActionEnum.UPLOAD_DOCUMENT,
    RowActionEnum.ENTER_DELIVERY_DATE_DOCUMENT,
    RowActionEnum.FORWARD_TO_SHIPMENT_DOCUMENT
  ],
  tableActionKeys,
  isCollapsable = true,
  isCancelActionHidden = () => false,
  isForwardToShipmentActionHidden = () => false,
  isEnterDeliveryDateActionHidden = () => false,
  isReceiveDateDocumentActionDisabled = () => false,
  customDisabledLabelKey,
  setGroupKeyIn,
  setGroupKeyNotIn,
  groupKeyNotIn,
  groupKeyIn
}: IProps) {
  const { applicationId } = useParams<IApplicationDetailsPageParams>();
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { download } = useFileDownload();

  const [confirm] = useConfirmDialog();
  const [downloadId, setDownloadId] = useState<string>();

  const downloadQuery = useDownloadDocumentQuery({ fileId: downloadId }, { enabled: Boolean(downloadId) });

  const handleGenerateDocument = (title: string, groupKey?: string) => {
    openGenerateDocumentDialog({
      title,
      groupKey,
      onSuccess: () => {
        if (applicationId) {
          queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
        }
      },
      isDocumentDownloadingAfterGenerate: true
    });
  };

  const isHeaderActionVisible = useCallback(
    (action: ValueOf<HeaderActionEnum>) => {
      return headerActions.includes(action);
    },
    [headerActions]
  );

  useEffect(() => {
    if (downloadQuery.data) {
      download(
        () =>
          new Promise(res => {
            res(downloadQuery.data);
          })
      );
      setDownloadId(null);
    }
  }, [download, downloadQuery]);

  const xlsxApiRequestParser = (res: AxiosResponse<DocumentSnapshotsPage>) => {
    return {
      ...res,
      data: { ...res.data, content: parseDocumentsList(res.data.content) }
    };
  };

  const handleCancel = (data: DocumentsSnapshotClient) => {
    confirm({
      title: t('document:dialog.cancelDocumentTitle'),
      shortTitle: t('document:dialog.cancelDocumentShortTitle'),
      message: t('document:dialog.cancelDocumentConfirm'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        const requestData: DocumentsApiCancelDocumentRequest = {
          documentId: data?.id,
          versionedRequest: {
            version: data?.version
          }
        };

        cancelDocument(requestData, {
          onSuccess: () => {
            queryCache.invalidateQueries(queryKey);
            showSnackbar('success', t('document:message.cancelDocumentSuccess'));
            closeDialog();
          },
          onSettled: () => {
            setConfirmLoading(false);
          }
        });
      },
      confirmType: 'danger'
    });
  };

  const handleForwardToShipment = (document: DocumentSnapshot) => {
    const sendForwardToShipmentRequest = data => {
      forwardToShipment(data, {
        onSuccess: () => {
          queryCache.invalidateQueries(queryKey);
          showSnackbar('success', t('document:message.forwardToShipmentSuccess'));
        }
      });
    };

    const forwardToShipmentRequestData = {
      documentId: document.id,
      versionedRequest: {
        version: document.version
      }
    };

    if (!document.isFinalDecision && document.isDecision) {
      confirm({
        title: t('document:dialog.markAsDecisionDocumentTitle'),
        message: t('document:dialog.shipmentMarkAsDecisionDocumentTitle'),
        onConfirm: (setConfirmLoading, closeDialog) => {
          setConfirmLoading(true);
          const finalDecisionRequestData = {
            documentId: document.id,
            documentFinalDecisionUpdateRequest: {
              isFinalDecision: true,
              version: document.version
            }
          };
          finalDecisionDocument(finalDecisionRequestData, {
            onSuccess: () => {
              showSnackbar('success', t('document:message.markAsDecisionDocumentSuccess'));
              closeDialog();
              queryCache.invalidateQueries(queryKey);
            },
            onSettled: (response: { data: DocumentSnapshot }) => {
              setConfirmLoading(false);
              if (response?.data?.version) {
                forwardToShipmentRequestData.versionedRequest.version = response.data.version;
              }
              sendForwardToShipmentRequest(forwardToShipmentRequestData);
            }
          });
        },
        onCancel: () => {
          sendForwardToShipmentRequest(forwardToShipmentRequestData);
        }
      });
    } else {
      sendForwardToShipmentRequest(forwardToShipmentRequestData);
    }
  };

  const handleMarkFinalDecisionDocument = (document: DocumentSnapshot) => {
    const { id, isFinalDecision, version } = document;
    const data: DocumentsApiUpdateDocumentFinalDecisionRequest = {
      documentId: id,
      documentFinalDecisionUpdateRequest: {
        isFinalDecision: !isFinalDecision,
        version
      }
    };

    confirm({
      title: isFinalDecision
        ? t('document:dialog.unmarkAsDecisionDocumentTitle')
        : t('document:dialog.markAsDecisionDocumentTitle'),
      message: isFinalDecision
        ? t('document:dialog.unmarkAsDecisionDocumentConfirm')
        : t('document:dialog.markAsDecisionDocumentConfirm'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        finalDecisionDocument(data, {
          onSuccess: () => {
            showSnackbar(
              'success',
              data.documentFinalDecisionUpdateRequest.isFinalDecision
                ? t('document:message.unmarkAsDecisionDocumentSuccess')
                : t('document:message.markAsDecisionDocumentSuccess')
            );
            closeDialog();
            queryCache.invalidateQueries(queryKey);
          },
          onSettled: () => {
            setConfirmLoading(false);
          }
        });
      }
    });
  };

  const isRowActionVisible = useCallback(
    (action: ValueOf<RowActionEnum>) => {
      return rowActions.includes(action);
    },
    [rowActions]
  );

  const isUploadDocumentDisabled = (original: DocumentSnapshot) => {
    return (
      ![DomainDictionaryEntry.DOCUMENT_STATUS.GENERATED, DomainDictionaryEntry.DOCUMENT_STATUS.UPLOADED].includes(
        original?.statusKey
      ) && original?.statusKey !== null
    );
  };

  const isCancelDocumentDisabled = (original: DocumentSnapshot) => {
    return [
      DomainDictionaryEntry.DOCUMENT_STATUS.CANCELED,
      DomainDictionaryEntry.DOCUMENT_STATUS.RELEASE_DELIVERED,
      DomainDictionaryEntry.DOCUMENT_STATUS.RELEASE_PENDING_FOR_DELIVERY
    ].includes(original?.statusKey);
  };

  const isForwardToShipmentDisabled = (original: DocumentSnapshot, status) =>
    status === DomainDictionaryEntry.APPLICATION_STATUS.CONSIDERED ||
    [
      DomainDictionaryEntry.DOCUMENT_STATUS.RELEASE_DELIVERED,
      DomainDictionaryEntry.DOCUMENT_STATUS.RELEASE_PENDING_FOR_DELIVERY,
      DomainDictionaryEntry.DOCUMENT_STATUS.READY_FOR_RELEASE
    ].includes(original?.statusKey);

  const { renderTableActions } = useTableRowActions<DocumentSnapshotsPage & DocumentsSnapshotClient & DocumentSnapshot>(
    [
      {
        id: DomainUIElementEnum.DOCUMENTS_ISSUED_DOCUMENTS_DOWNLOAD_BUTTON,
        label: 'action.download',
        onClick: ({ row: { original } }) => setDownloadId(original?.documentFileId),
        isHidden: () => !isRowActionVisible('DOWNLOAD_DOCUMENT'),
        icon: 'DownloadIcon'
      },
      {
        id: DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_UPLOAD_DOCUMENT_BUTTON,
        label: 'document:action.uploadDocument',
        isDisabledLabel: 'document:message.uploadDocumentDisabledMessage',
        onClick: ({ row: { original } }) => openUpdateDocumentDialog(original, () => {}),
        isHidden: () => !isRowActionVisible('UPLOAD_DOCUMENT'),
        isDisabled: ({ row: { original } }) => isUploadDocumentDisabled(original),
        icon: 'UploadIcon'
      },
      {
        id: DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_ADD_RECIEVE_DATE_BUTTON,
        label: 'document:action.enterDeliveryDate',
        onClick: ({ row: { original } }) =>
          openEnterDeliveryDateDialog(original, () => {
            queryCache.invalidateQueries(RoadTransportQueryKeysEnum.PROCEEDING_DETAILS);
          }),
        isHidden: () => !isRowActionVisible('ENTER_DELIVERY_DATE_DOCUMENT'),
        ...(isReceiveDateDocumentActionDisabled && {
          isDisabled: ({ row: { original } }) => !isReceiveDateDocumentActionDisabled(original?.statusKey)
        }),
        isDisabledLabel: customDisabledLabelKey || null,
        icon: 'CalendarIcon'
      },
      {
        id: DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_CANCEL_DOCUMENT_BUTTON,
        label: 'action.cancel',
        isDisabledLabel: 'document:message.cancelDocumentDisabledMessage',
        onClick: ({ row: { original } }) => handleCancel(original),
        isHidden: () => !isRowActionVisible('CANCEL_DOCUMENT'),
        isDisabled: ({ row: { original } }) => isCancelDocumentDisabled(original),
        icon: 'CrossIcon'
      },
      {
        id: DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_FINAL_DECISION,
        label: row => {
          return row.original.isFinalDecision ? 'document:action.unmarkAsDecision' : 'document:action.markAsDecision';
        },
        isDisabledLabel: 'document:action.markAsDecision',
        onClick: ({ row: { original } }) => handleMarkFinalDecisionDocument(original),
        isHidden: () => !isRowActionVisible('FINAL_DECISION'),
        icon: 'LockIcon'
      },
      {
        id: DomainUIElementEnum.APPLICATION_OUTGOING_DOCUMENTS_UPLOAD_DOCUMENT_BUTTON,
        label: 'document:action.uploadDocument',
        isDisabledLabel: 'document:message.uploadDocumentDisabledMessage',
        onClick: ({ row: { original } }) => openUpdateDocumentDialog(original, () => {}),
        isHidden: ({ row: { original } }) => !isRowActionVisible('UPLOAD_DOCUMENT') || original?.isPermission,
        isDisabled: ({ row: { original } }) => isUploadDocumentDisabled(original),
        icon: 'UploadIcon'
      },
      {
        id: DomainUIElementEnum.APPLICATION_OUTGOING_DOCUMENTS_CANCEL_BUTTON,
        label: 'action.cancel',
        isDisabledLabel: 'document:message.cancelDocumentDisabledMessage',
        onClick: ({ row: { original } }) => handleCancel(original),
        isHidden: ({ row: { original } }) => !isRowActionVisible('CANCEL_DOCUMENT') || isCancelActionHidden(original),
        isDisabled: ({ row: { original } }) => isCancelDocumentDisabled(original),
        icon: 'CrossIcon'
      },
      {
        id: DomainUIElementEnum.DOCUMENTS_ISSUED_DOCUMENTS_FORWARD_TO_SHIPMENT_BUTTON,
        label: 'document:action.forwardToShipment',
        isDisabledLabel: 'document:message.forwardToShipmentDisabledMessage',
        onClick: ({ row: { original } }) => handleForwardToShipment(original),
        isHidden: ({ row: { original } }) =>
          !isRowActionVisible('FORWARD_TO_SHIPMENT_DOCUMENT') || isForwardToShipmentActionHidden(original),
        isDisabled: ({ row: { original } }) => isForwardToShipmentDisabled(original, applicationStatus),
        icon: 'SendIcon'
      },
      {
        id: DomainUIElementEnum.APPLICATION_OUTGOING_DOCUMENTS_ENTER_DELIVERY_DATE_BUTTON,
        label: 'document:action.enterDeliveryDate',
        onClick: ({ row: { original } }) => openEnterDeliveryDateDialog(original, null),
        isHidden: ({ row: { original } }) =>
          !isRowActionVisible('ENTER_DELIVERY_DATE_DOCUMENT') || isEnterDeliveryDateActionHidden(original),
        ...(isReceiveDateDocumentActionDisabled && {
          isDisabled: ({ row: { original } }) => !isReceiveDateDocumentActionDisabled(original?.statusKey)
        }),
        isDisabledLabel: customDisabledLabelKey || null,
        icon: 'CalendarIcon'
      },
      {
        id: DomainUIElementEnum.SUSPENSIONS_DOCUMENTS_UPLOAD_BUTTON,
        label: 'document:action.uploadDocument',
        onClick: ({ row: { original } }) => openUpdateDocumentDialog(original, () => {}),
        isHidden: ({ row: { original } }) => isUploadDocumentDisabled(original),
        icon: 'UploadIcon'
      },
      {
        id: DomainUIElementEnum.SUSPENSIONS_DOCUMENTS_CANCEL_BUTTON,
        label: 'action.cancel',
        onClick: ({ row: { original } }) => handleCancel(original),
        isHidden: ({ row: { original } }) => isCancelDocumentDisabled(original),
        icon: 'CrossIcon'
      },
      {
        id: DomainUIElementEnum.SUSPENSIONS_DOCUMENTS_ADD_DELIVERY_DATE_BUTTON,
        label: 'document:action.enterDeliveryDate',
        onClick: ({ row: { original } }) => openEnterDeliveryDateDialog(original, null),
        ...(isReceiveDateDocumentActionDisabled && {
          isDisabled: ({ row: { original } }) => isReceiveDateDocumentActionDisabled(original?.statusKey)
        }),
        isDisabledLabel: customDisabledLabelKey || null,
        icon: 'CalendarIcon'
      }
    ],
    [applicationStatus]
  );

  const groupKeyInAction = (
    <TableIconButton
      tooltipTitle={t('document:action.showOnlyForeign')}
      onClick={() => {
        setGroupKeyIn(prevState => !prevState);
        setGroupKeyNotIn(false);
      }}
      icon="ForeignIcon"
      isActive={groupKeyIn}
    />
  );

  const groupKeyNotInAction = (
    <TableIconButton
      tooltipTitle={t('document:action.showOnlyNational')}
      onClick={() => {
        setGroupKeyNotIn(prevState => !prevState);
        setGroupKeyIn(false);
      }}
      icon="NationalIcon"
      isActive={groupKeyNotIn}
    />
  );

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('document:listTitle')}
      xlsxDownload={{
        fileName: t('document:listTitle'),
        pathToXLSXTranslation: 'document:fields',
        apiRequest: params => {
          if (apiRequest.length === 2) {
            return (
              apiRequest as (
                documentSearchFilter: DocumentSearchFilter,
                options?: any
              ) => AxiosPromise<DocumentSnapshotsPage>
            )(params).then(xlsxApiRequestParser);
          }
          return apiRequest(parentId, params).then(xlsxApiRequestParser);
        }
      }}
      headerActions={
        ((mode === UiMode.FORM || mode === FormMode.EDIT) && (
          <>
            {isHeaderActionVisible(HeaderActionEnum.GENERATE_SUBMISSION) && (
              <TableIconButton
                tooltipTitle={t('document:action.generateSubmissionConfirmation')}
                onClick={() =>
                  handleGenerateDocument(
                    t('document:dialog.generateDocumentTitle', {
                      templateGroup: t('document:dialog.generateSubmissionConfirmationTitleSuffix')
                    }),
                    DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.CONFIRMATION_OF_SUBMISSION
                  )
                }
                icon="NoteIcon"
                actionKey={permissionsKeys?.generateSubmissionConfirmation}
              />
            )}
            {isHeaderActionVisible(HeaderActionEnum.GENERATE_CALL_TO_FILL_GAPS) && (
              <TableIconButton
                tooltipTitle={t('document:action.generateCallToFillGaps')}
                onClick={() =>
                  handleGenerateDocument(
                    t('document:dialog.generateDocumentTitle', {
                      templateGroup: t('document:dialog.generateCallToFillGapsTitleSuffix')
                    }),
                    DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.MONITION
                  )
                }
                icon="ValidationErrorIcon"
                actionKey={permissionsKeys?.generateCallToFillGaps}
              />
            )}
            {isHeaderActionVisible(HeaderActionEnum.GROUP_KEY_IN_FILTER) && groupKeyInAction}
            {isHeaderActionVisible(HeaderActionEnum.GROUP_KEY_NOT_IN_FILTER) && groupKeyNotInAction}
            {isHeaderActionVisible(HeaderActionEnum.GENERATE_DOCUMENT) && (
              <Button
                label={t('document:action.generateDocument')}
                onClick={() =>
                  handleGenerateDocument(t('document:dialog.generateDocumentTitle', { templateGroup: '' }))
                }
                variant="outlined"
                classNameWrapper="ml-16"
                actionKey={permissionsKeys?.generateDocument}
                isNoMargin
              />
            )}
          </>
        )) || (
          <>
            {isHeaderActionVisible(HeaderActionEnum.GROUP_KEY_IN_FILTER) && groupKeyInAction}
            {isHeaderActionVisible(HeaderActionEnum.GROUP_KEY_NOT_IN_FILTER) && groupKeyNotInAction}
          </>
        )
      }
      rowActions={renderTableActions(tableActionKeys)}
      isCollapsable={isCollapsable}
      isSection
      isRefreshEnabled
    />
  );
}

export default DocumentsTable;
