import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';

import { IconButton, LoaderCircular, Typography, useValueStyles } from '@libs/common/v2';
import { getValue } from '@libs/common/v2/utils';

import { parsePostCode, parseStreet } from '@libs/domain/address';
import { DomainDictionaryEntry } from '@libs/domain/config';
import { FolderDetailsClient } from '@libs/domain/folder';

import FolderSliderSection from '../FolderSliderSection';

interface Props {
  folderDetails: FolderDetailsClient;
  emptyMode?: boolean;
  isLoading?: boolean;
  isCopyEmailActionVisible?: boolean;
}

function ContactBlock({ emptyMode, folderDetails, isLoading, isCopyEmailActionVisible }: Props) {
  const [t] = useTranslation();

  const globalClasses = useValueStyles({});
  const classes = useStyles();

  const email = useMemo(() => folderDetails?.subject?.contact?.email, [folderDetails]);

  const address = useMemo(() => {
    const address =
      folderDetails?.addresses?.find(({ typeKey }) => typeKey === DomainDictionaryEntry.ADDRESS_TYPE.CORRESPONDENCE) ||
      folderDetails?.subjectMainAddress;

    if (!address) {
      return null;
    }
    const { city, street, propertyNumber, apartmentNumber, postCode } = address;

    const parsedPostCode = parsePostCode(postCode);

    return `${getValue(parsedPostCode)} ${getValue(city)}, ${parseStreet(getValue(street))} ${getValue(
      propertyNumber
    )} ${apartmentNumber ?? ''}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderDetails]);

  return (
    <FolderSliderSection title={t('folder:sidebar.section.contactData')} isCollapsable>
      <LoaderCircular isLoading={isLoading} size={25}>
        {emptyMode ? (
          <div className={clsx(classes.row, 'flex items-center')}>
            <p>{t('error.noData')}</p>
          </div>
        ) : (
          <div className="flex flex-col">
            <div className={clsx(classes.row, classes.addressRow, 'flex items-center')}>
              <Typography
                component="div"
                themeVariant="textSm.medium"
                className={clsx(globalClasses.labelWrapper, 'pr-5', 'mb-0')}
              >
                {t('folder:field.primaryAddress')}
              </Typography>
              <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
                {folderDetails?.printingSettings?.displayAddress ?? address ?? t('emptyMark')}
              </Typography>
            </div>
            <div className={clsx(classes.row, 'flex items-center')}>
              <Typography
                component="div"
                themeVariant="textSm.medium"
                className={clsx(globalClasses.labelWrapper, 'pr-5', 'mb-0')}
              >
                {t('folder:details.field.phoneNumber')}
              </Typography>
              <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
                {folderDetails?.subject?.contact?.phoneNumber ?? t('emptyMark')}
              </Typography>
            </div>
            <div className={clsx(classes.row, 'flex items-center')}>
              <Typography
                component="div"
                themeVariant="textSm.medium"
                className={clsx(globalClasses.labelWrapper, 'pr-5', 'mb-0')}
              >
                {t('folder:details.field.email')}
              </Typography>
              <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
                {email ?? t('emptyMark')}
              </Typography>
              {!!email && isCopyEmailActionVisible && (
                <IconButton
                  icon="CopyIcon"
                  className={clsx('ml-5', classes.iconButton)}
                  onClick={() => copy(email)}
                  tooltipTitle={t('folder:sidebar.tooltip.copyEmail')}
                />
              )}
            </div>
          </div>
        )}
      </LoaderCircular>
    </FolderSliderSection>
  );
}

const useStyles = makeStyles(() => ({
  row: {
    minHeight: 48
  },
  addressRow: {
    marginTop: '10px'
  },
  iconButton: {
    height: 20,
    width: 20
  }
}));

export default ContactBlock;
