import { useEffect, useState } from 'react';

import { AdditionalFilterEnum, useRouter } from '@libs/common/v2';

import { PetitionsTypeEnum } from '../../common';
import { IntialYearType } from '../../SocialCommissionPage';
import { SocialCommissionDisposablePermitsTable } from '../social-commission-disposable-permits';
import { SocialCommissionTable } from '../social-commission-list';

import PetitionsRadioButtons from './PetitionsRadioButtons';

interface IProps {
  globalFilters: (name: string) => {
    type: AdditionalFilterEnum;
    name: string;
    customFilter: JSX.Element;
  }[];
  initialYear: IntialYearType;
}

function PetitionsSocialCommissionPage({ globalFilters, initialYear }: IProps) {
  const { location } = useRouter();
  const [subTab, setSubTab] = useState<PetitionsTypeEnum>(PetitionsTypeEnum.SINGLE);

  useEffect(() => {
    if (location.state) {
      setSubTab(location.state.type);
    } else {
      setSubTab(PetitionsTypeEnum.SINGLE);
    }

    return () => {
      location.state = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const radioButtons = <PetitionsRadioButtons tab={subTab} tabChange={setSubTab} />;

  return subTab === PetitionsTypeEnum.SINGLE ? (
    <SocialCommissionDisposablePermitsTable
      tab={PetitionsTypeEnum.SINGLE}
      headerActions={radioButtons}
      globalFilters={globalFilters}
      initialYear={initialYear}
    />
  ) : (
    <SocialCommissionTable
      tab={PetitionsTypeEnum.EKMT}
      headerActions={radioButtons}
      globalFilters={globalFilters}
      initialYear={initialYear}
    />
  );
}

export default PetitionsSocialCommissionPage;
