import { useDialog } from '@libs/common/v2';

import { ProcessCancellationFormDialog } from '../components';

interface IProcessCancellationFormProps {
  applicationId?: string;
  applicationVersion?: number;
  formConfig?: {
    messageKey: string;
    title: string;
    uiDefinition: any;
  };
  onFinish: () => void;
}

function useProcessCancellationFormDialog() {
  const { openDialog } = useDialog();

  const showProcessCancellationFormDialog = ({
    applicationId,
    applicationVersion,
    formConfig,
    onFinish
  }: IProcessCancellationFormProps) => {
    openDialog(({ closeDialog }) => (
      <ProcessCancellationFormDialog
        applicationId={applicationId}
        applicationVersion={applicationVersion}
        formConfig={formConfig}
        onCloseDialog={closeDialog}
        onFinish={onFinish}
        isOpen
      />
    ));
  };

  return { showProcessCancellationFormDialog };
}

export default useProcessCancellationFormDialog;
