import { usePaginatedQuery } from 'react-query';
import { ResourceObjectSearchFilter } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { IResourceObjectsClient } from '../../../model';
import { parseResourceObjectsList } from '../../../utils';
import { endpointsConfig } from '../../endpoints-config';
import { ResourceQueryKeysEnum } from '..';

const getResourceObjects = async (params: ResourceObjectSearchFilter) => {
  const { data } = await API.resourceObjects.getResourceObjects(params, {
    ...endpointsConfig.getResourceObjects
  });

  return {
    ...data,
    content: parseResourceObjectsList(data.content)
  };
};

export default function useResourcesSearchQuery(params: ResourceObjectSearchFilter) {
  return usePaginatedQuery<{ content: IResourceObjectsClient[] }>(
    [ResourceQueryKeysEnum.RESOURCES_SEARCH, params],
    () => getResourceObjects(params)
  );
}
