import { NoteUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function updateSuspensionNote({ id, request }: { id: string; request: NoteUpdateRequest }) {
  return API.suspensionNote.updateSuspensionNote(id, request, {
    ...endpointsConfig.updateSuspensionNote
  });
}

function useUpdateSuspensionNoteMutation() {
  return useMutation(updateSuspensionNote);
}

export default useUpdateSuspensionNoteMutation;
