import { useEffect, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { isNil } from 'lodash';

import { Section, useFormV2Context, useFormV2Watch, useInputStyles } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import { OrganizationUnitTreeWrapperV2 } from '@libs/organization-structure/components/tree';

function OrganizationalUnitSection() {
  const [t] = useTranslation();
  const inputClasses = useInputStyles({});
  const classes = useStyles();
  const { setValue, formMode, errors, control } = useFormV2Context();
  const { createMode } = useViewModesV2(formMode);
  const [selectedKey, setSelectedKey] = useState([]);
  const organizationUnitId = useFormV2Watch({ control, name: 'organizationUnit.id' });

  const isOrganizationNotUnitValid = useMemo(() => isNil(organizationUnitId), [organizationUnitId]);
  const isErrorMessageVisible =
    isOrganizationNotUnitValid && (errors?.organizationUnit as { id?: FieldError })?.id?.message;

  useEffect(() => {
    if (createMode && organizationUnitId) {
      setSelectedKey([organizationUnitId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationUnitId]);

  return (
    <Section title={t('user:section.organizationalUnitSelect')} isRequiredSection isCollapsable>
      {isErrorMessageVisible ? (
        <FormHelperText className={clsx(inputClasses.formHelperText, classes.helperText, 'mt-0')} error>
          {t('administration:organizationUnit.message.parentUnitRequired')}
        </FormHelperText>
      ) : null}
      <OrganizationUnitTreeWrapperV2
        draggable={false}
        select
        userUnitSelect
        setValue={setValue}
        setSelectedKey={setSelectedKey}
        selectedKey={selectedKey}
      />
    </Section>
  );
}

const useStyles = makeStyles<Theme>(() => ({
  helperText: {
    marginBottom: important('1rem')
  }
}));

export default OrganizationalUnitSection;
