import { EntrepreneursHiddenActions, EntrepreneursSection } from '@libs/domain/entrepreneurs';

function EnterpreneursTab() {
  return (
    <div>
      <EntrepreneursSection
        hiddenActions={[EntrepreneursHiddenActions.ADD, EntrepreneursHiddenActions.DELETE]}
        visibleColumns={[
          'subjectName',
          'subjectNip',
          'subjectRegon',
          'primaryAddress',
          'subjectContactEmail',
          'subjectContactPhoneNumber'
        ]}
        isCollapsable
      />
    </div>
  );
}

export default EnterpreneursTab;
