import { Context, useEffect, useMemo, useState } from 'react';
import { FieldError, FieldPath, FieldValues } from 'react-hook-form';
import { isArray, isEqual, isObject, isString } from 'lodash';

import { DictionaryEntryNameEnum, useDictionaryEntryValues, useDictionaryTranslations } from '@libs/dictionary';
import { UIElementNameEnum, useElementVisibility } from '@libs/permission';

import { AutocompleteSelect, SelectOption, Value } from '../../../components';
import { getValue } from '../../../utils';
import { FormV2ContextState } from '../../context';
import { useSetInitialSelectFieldValue } from '../../hooks';
import { FormErrorType, FormMode, InputMode } from '../../model';
import { getInputMode } from '../../utils';
import {
  FieldTypeEnum,
  SelectFieldValidation,
  SelectMultipleFieldValidation,
  TextFieldValidation,
  useFieldValidationHandler,
  ValidationProperties
} from '../../validation';

export interface DictionarySelectProps {
  name: FieldPath<FieldValues>;
  dictionaryName: DictionaryEntryNameEnum;
  optionsFilter?: (element: SelectOption) => boolean;
  optionsDisabled?: (element: SelectOption) => boolean;
  freeSoloOptionFilter?: (value: string) => boolean;
  onChange?: (value: SelectOption) => void;
  label?: string;
  tooltip?: string;
  formContext?: Context<FormV2ContextState>;
  inputMode?: InputMode;
  className?: string;
  valueClassName?: string;
  validationSingleSelect?: SelectFieldValidation;
  validationMultipleSelect?: SelectMultipleFieldValidation;
  validationStringValueSingleSelect?: TextFieldValidation;
  isRequired?: boolean;
  isDisabled?: boolean;
  isMultiple?: boolean;
  isFreeSolo?: boolean;
  isClearable?: boolean;
  stringValue?: boolean;
  customErrorMessage?: string;
  hasErrorTooltip?: boolean;
  actionKey?: UIElementNameEnum;
}

type DictionarySelectControllerComponentProps = Omit<DictionarySelectProps, 'formContext'> & {
  formMode: FormMode;
  loading: boolean;
  isSubmitting: boolean;
  currentValue: SelectOption;
  onBlur: () => void;
  error: FieldError;
  customOnChange: (value: SelectOption) => void;
};

const DictionarySelectControllerComponent = ({
  name,
  label,
  tooltip,
  dictionaryName,
  className,
  onChange,
  inputMode,
  stringValue,
  isMultiple,
  customErrorMessage,
  isRequired,
  isDisabled,
  isFreeSolo,
  isClearable,
  optionsFilter,
  optionsDisabled,
  freeSoloOptionFilter,
  validationStringValueSingleSelect,
  validationMultipleSelect,
  validationSingleSelect,
  valueClassName,
  formMode,
  loading,
  isSubmitting,
  currentValue,
  onBlur,
  error,
  customOnChange,
  actionKey
}: DictionarySelectControllerComponentProps) => {
  const mode = getInputMode(formMode, inputMode);
  const { translate } = useDictionaryTranslations();
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [initialValue, setInitialValue] = useState<SelectOption | string | Array<SelectOption>>(null);
  const dictionaryEntries = useDictionaryEntryValues(dictionaryName);
  const { checkIsElementVisible } = useElementVisibility();
  const isElementVisible = useMemo(() => {
    return actionKey ? checkIsElementVisible(actionKey) : true;
  }, [checkIsElementVisible, actionKey]);

  useSetInitialSelectFieldValue(name, options, stringValue, undefined, true);

  useEffect(() => {
    if (initialValue === null && currentValue) {
      setInitialValue(currentValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  const getValidationType = (): ValidationProperties => {
    if (stringValue && !isMultiple) {
      return {
        fieldName: name,
        validation: validationStringValueSingleSelect,
        fieldType: FieldTypeEnum.TEXT
      };
    }
    if (isMultiple) {
      return {
        fieldName: name,
        validation: validationMultipleSelect,
        isMultiple: true,
        fieldType: FieldTypeEnum.AUTOCOMPLETE
      };
    }
    return {
      fieldName: name,
      validation: validationSingleSelect,
      isMultiple: false,
      fieldType: FieldTypeEnum.AUTOCOMPLETE
    };
  };

  useFieldValidationHandler(getValidationType());

  const filteredOptions = useMemo(() => {
    let newOptions =
      dictionaryEntries?.map(item => {
        if (optionsDisabled) {
          return { ...item, isDisabled: optionsDisabled(item) };
        }
        return item;
      }) || [];
    if (optionsFilter) {
      newOptions = newOptions.filter(option => optionsFilter(option));
    }
    newOptions = newOptions?.filter(option => {
      return (
        (isArray(initialValue) &&
          initialValue.find(value => {
            if (isString(value)) {
              return value === option.value;
            }
            return 'value' in value && value!.value === option.value;
          })) ||
        initialValue === option.value ||
        (isObject(initialValue) && 'value' in initialValue && initialValue?.value === option.value) ||
        option.active
      );
    });

    return newOptions;
  }, [dictionaryEntries, optionsFilter, optionsDisabled, initialValue]);

  useEffect(() => {
    if (!isEqual(options, filteredOptions)) {
      setOptions(filteredOptions);
    }
  }, [filteredOptions, options]);

  return (
    isElementVisible &&
    (mode === InputMode.FORM ? (
      <AutocompleteSelect
        className={className}
        name={name}
        label={label}
        options={options}
        value={currentValue || (isMultiple ? [] : '')}
        onChange={(_event, selectValue: SelectOption) => {
          onChange(selectValue);
          customOnChange?.(selectValue);
        }}
        isError={Boolean(customErrorMessage) || (!!error && error?.type !== FormErrorType.WARNING)}
        isWarning={error?.type === FormErrorType.WARNING}
        helperText={customErrorMessage || error?.message}
        onBlur={onBlur}
        isMultiple={isMultiple}
        isRequired={isRequired || Boolean(validationMultipleSelect?.required || validationSingleSelect?.required)}
        isDisabled={isDisabled || isSubmitting}
        isFreeSolo={isFreeSolo}
        {...(isFreeSolo && { freeSoloOptionFilter })}
        isLoading={loading}
        isClearable={isClearable}
        tooltip={tooltip}
        stringValue={stringValue}
      />
    ) : (
      <Value
        label={label}
        isLoading={loading}
        className={valueClassName}
        isError={!!error && error?.type !== FormErrorType.WARNING}
        isWarning={error?.type === FormErrorType.WARNING}
        helperText={error?.message}
      >
        {!loading &&
          getValue(
            Array.isArray(currentValue)
              ? currentValue
                  .map((item: SelectOption<string> | string) => {
                    const value = isString(item) ? item : item?.value;
                    return translate(dictionaryName, value, value);
                  })
                  .join(', ')
              : translate(
                  dictionaryName,
                  (currentValue?.value || currentValue) as string,
                  (currentValue?.value || currentValue) as string
                )
          )}
      </Value>
    ))
  );
};

export default DictionarySelectControllerComponent;
