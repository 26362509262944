import { useParams } from 'react-router-dom';
import { isObject } from 'lodash';

import { partialTranslate } from '@libs/common';
import {
  InputMode,
  SectionWithFields,
  SelectOption,
  SwitchField,
  typedNameV2,
  useFormV2Context,
  Value
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { useElementVisibility } from '@libs/permission';

import { DomainUIElementEnum } from '@libs/domain/config';
import { FolderDetailsClient, FolderDetailsParams, useEkmtDetailsQuery } from '@libs/domain/folder';

const getLabel = partialTranslate('folder:details.tab.ekmt.section.general.field');

function EkmtMainSection() {
  const { folderId } = useParams<FolderDetailsParams>();

  const { watch, formMode } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);
  const selectedYear = watch('selectedYear') as SelectOption<number>;
  const { checkIsElementVisible } = useElementVisibility();

  const { data, isLoading } = useEkmtDetailsQuery(folderId, selectedYear?.value, {
    enabled: isObject(selectedYear)
  });

  return (
    <SectionWithFields
      className="mt-5"
      itemProps={{ xs: 12, sm: 4 }}
      fields={[
        ...(checkIsElementVisible(DomainUIElementEnum.FOLDER_EKMT_BLOCK_PERMITS_VIEW)
          ? [
              <SwitchField
                label={getLabel('arePermitsBlocked')}
                name={typedNameV2<FolderDetailsClient>('permissionIssuingBlocked')}
                key="arePermitsBlocked"
                inputMode={
                  !viewMode &&
                  checkIsElementVisible(DomainUIElementEnum.FOLDER_EKMT_BLOCK_PERMITS_EDIT) &&
                  InputMode.FORM
                }
              />
            ]
          : []),

        ...(checkIsElementVisible(DomainUIElementEnum.FOLDER_EKMT_DETAILS)
          ? [
              <Value label={getLabel('downloaded')} isLoading={isLoading} key="downloaded">
                {data?.used}
              </Value>,
              <Value label={getLabel('returned')} isLoading={isLoading} key="returned">
                {data?.returned}
              </Value>,
              <Value label={getLabel('remained')} isLoading={isLoading} key="remained">
                {data?.left}
              </Value>,
              <Value label={getLabel('sum')} isLoading={isLoading} key="sum">
                {data?.limit}
              </Value>
            ]
          : [])
      ]}
    />
  );
}

export default EkmtMainSection;
