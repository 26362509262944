import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { TableLayout } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { FolderDetailsParams } from '@libs/domain/folder';
import { useFolderHistoryTable } from '@libs/domain/folder/hooks';

function FolderHistoryTable() {
  const { folderId } = useParams<FolderDetailsParams>();
  const [t] = useTranslation();
  const tableProps = useFolderHistoryTable(folderId);

  return (
    <div>
      <TableLayout
        {...tableProps}
        pageTitle={t('history:table.title')}
        isActionColumnEnabled={false}
        xlsxDownload={{
          fileName: t('history:table.title'),
          pathToXLSXTranslation: 'history:field',
          apiRequest: params => API.folder.getFolderHistoryDetailsPage(folderId, params)
        }}
        isCollapsable
        isSection
      />
    </div>
  );
}

export default FolderHistoryTable;
