export type SocialCommissionFormConfigTypes = {
  type: string;
  required: boolean;
  isDisabled: boolean;
  name?: any;
  label: string;
};

export type IDisposablePermitsDetailsParams = {
  id: string;
};

export enum SocialCommissionQueryKeysEnum {
  FOLDER_DETAILS = 'FOLDER_DETAILS',
  PERMANENT_PERMISSION_SUBMISSION_DETAILS = 'PERMANENT_PERMISSION_SUBMISSION_DETAILS',
  TRADE_RULES_DETAILS = 'TRADE_RULES_DETAILS',
  EKMT_DETAILS = 'EKMT_DETAILS',
  SINGLE_USE_SUBMISSION_DETAILS = 'SINGLE_USE_SUBMISSION_DETAILS',
  CIVIC_BOARD_DETAILS = 'CIVIC_BOARD_DETAILS',
  RESOURCE_TYPE_SOCIAL_COMMISSION = 'RESOURCE_TYPE_SOCIAL_COMMISSION',
  RESOURCE_TYPE_TRADE_RULES = 'RESOURCE_TYPE_TRADE_RULES',
  RESOURCE_TYPE_BASIC_RULES = 'RESOURCE_TYPE_BASIC_RULES'
}

export const modePathEkmt = 'foreignPermits:tabs.socialCommission.tabs.socialCommissionList.formModes';
export const modePathDisposablePermits =
  'foreignPermits:tabs.socialCommission.tabs.socialCommissionDisposablePermits.formModes';
export const modePathPermitEkmt = 'foreignPermits:tabs.releasedPermits.ekmtDetails.formModes';

export const EKTMFormConfig = [
  {
    type: 'text',
    required: false,
    isDisabled: true,
    name: 'permissionName',
    label: `foreignPermits:tabs.socialCommission.tabs.socialCommissionList.fields.permissionName`,
    width: 600
  },
  {
    type: 'number',
    required: true,
    isDisabled: false,
    name: 'euro5submitted',
    label: `foreignPermits:tabs.socialCommission.tabs.socialCommissionList.fields.euro5`,
    width: 200
  },
  {
    type: 'number',
    required: false,
    isDisabled: true,
    name: 'euro5decision',
    label: `foreignPermits:tabs.socialCommission.tabs.socialCommissionList.fields.decisionEuro5`,
    width: 200
  },
  {
    type: 'number',
    required: true,
    isDisabled: false,
    name: 'euro6submitted',
    label: `foreignPermits:tabs.socialCommission.tabs.socialCommissionList.fields.euro6`,
    width: 200
  },
  {
    type: 'number',
    required: false,
    isDisabled: true,
    name: 'euro6decision',
    label: `foreignPermits:tabs.socialCommission.tabs.socialCommissionList.fields.decisionEuro6`,
    width: 200
  }
];
