import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { TransitStopDetails } from '@ibtm/domain';

import { Button, FormMode, TableLayout, useConfirmDialog, useFormV2Context, useTableRowActions } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DomainUIElementEnum } from '@libs/domain/config';

import { ApplicationQueryKeysEnum } from '../../application';
import { useDeleteTransitStopsMutation } from '../api';
import { useStopDetailsDialog, useTransitScheduleTable } from '../hooks';
import { ScheduleApplicationGroupsEnum } from '../model';
import { TransitScheduleContext } from '../pages';

function TransitScheduleTable() {
  const queryCache = useQueryCache();
  const [t] = useTranslation();

  const { formMode } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);
  const tableProps = useTransitScheduleTable(viewMode);
  const [confirm] = useConfirmDialog();
  const { transitScheduleId, applicationId, applicationTypeKey } = useContext(TransitScheduleContext);
  const { openDetailsDialog } = useStopDetailsDialog({
    transitScheduleId,
    applicationId,
    applicationTypeKey: applicationTypeKey as ScheduleApplicationGroupsEnum
  });
  const { mutate: deleteTransitStops, isLoading } = useDeleteTransitStopsMutation();
  const { showSuccessSnackbar } = useSnackbar();

  const { renderTableActions } = useTableRowActions<TransitStopDetails>([
    {
      id: DomainUIElementEnum.APPLICATION_SCHEDULE_SHOW_DETAILS_BUTTON,
      label: 'action.openDetails',
      onClick: ({ row: { original } }) =>
        openDetailsDialog({
          initialData: original,
          formMode: FormMode.VIEW
        }),
      icon: 'ArrowIcon'
    },
    {
      id: DomainUIElementEnum.APPLICATION_SCHEDULE_EDIT_STOP_BUTTON,
      label: 'action.edit',
      isHidden: () => viewMode,
      onClick: ({ row: { original } }) =>
        openDetailsDialog({
          initialData: original,
          formMode: FormMode.EDIT
        }),
      icon: 'EditIcon'
    },
    {
      id: DomainUIElementEnum.APPLICATION_SCHEDULE_DELETE_STOP_BUTTON,
      label: 'action.delete',
      isHidden: () => viewMode,
      onClick: ({ row: { original } }) =>
        confirm({
          title: t('applications:transitSchedule.deleteModalTitle'),
          message: t('applications:transitSchedule.deleteModalContent', { number: original?.ordinal }),
          onConfirm: (setConfirmLoading, closeDialog) => {
            setConfirmLoading(true);
            deleteTransitStops(
              { transitScheduleId, stopIds: [original?.id] },
              {
                onSuccess: () => {
                  showSuccessSnackbar(t('applications:transitSchedule.actions.deleteSuccess'));
                  queryCache.invalidateQueries(ApplicationQueryKeysEnum.TRANSIT_SCHEDULE_DETAILS_STOPS);
                },
                onSettled: () => {
                  setConfirmLoading(false);
                  closeDialog();
                }
              }
            );
          },
          confirmType: 'danger'
        }),
      icon: 'TrashIcon'
    }
  ]);

  const mobileHeaderActions = [
    {
      onClick: () => {
        openDetailsDialog({
          formMode: FormMode.CREATE
        });
      },
      label: t('applications:transitSchedule.addStop'),
      actionKey: DomainUIElementEnum.APPLICATION_SCHEDULE_ADD_STOP_BUTTON
    }
  ];

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('applications:transitSchedule.blocks.transitSchedule')}
      mobileHeaderActions={mobileHeaderActions}
      headerActions={
        !viewMode && (
          <Button
            variant="outlined"
            type="button"
            label={t('applications:transitSchedule.addStop')}
            actionKey={DomainUIElementEnum.APPLICATION_SCHEDULE_ADD_STOP_BUTTON}
            onClick={() =>
              openDetailsDialog({
                formMode: FormMode.CREATE
              })
            }
          />
        )
      }
      rowActions={renderTableActions([
        DomainUIElementEnum.APPLICATION_SCHEDULE_SHOW_DETAILS_BUTTON,
        DomainUIElementEnum.APPLICATION_SCHEDULE_EDIT_STOP_BUTTON,
        DomainUIElementEnum.APPLICATION_SCHEDULE_DELETE_STOP_BUTTON
      ])}
      isLoading={isLoading}
      isSection
      isTableMutable
    />
  );
}

export default TransitScheduleTable;
