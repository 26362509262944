import {
  MisspellApplicationDataCertificateRecordUpdateRequest,
  MisspellApplicationDataDriverCertificateRecordUpdateRequest,
  MisspellDriverCertificateDetails
} from '@ibtm/domain';

import { DomainDictionaryEntry } from '@libs/domain/config';

import {
  ApplicationVersion,
  MisspellApplicationDataCertificateFormValues,
  MisspellApplicationDataDriverCertificateFormValues
} from '../model/misspell-application.model';

export const parseMisspellDriverCertificateFormValues = (
  values: MisspellApplicationDataDriverCertificateRecordUpdateRequest
): MisspellApplicationDataDriverCertificateFormValues => {
  const {
    subjectApartmentNumber,
    subjectCity,
    subjectPostCity,
    subjectPostCode,
    subjectPropertyNumber,
    subjectStreet,
    ...restValues
  } = values;
  return {
    ...restValues,
    qualification: Boolean(values.driverCompetenceCodeKey),
    driverCompetenceCodeKey: { value: values.driverCompetenceCodeKey },
    apartmentNumber: subjectApartmentNumber,
    city: subjectCity,
    postCity: subjectPostCity,
    postCode: subjectPostCode,
    propertyNumber: subjectPropertyNumber,
    street: subjectStreet
  };
};

export const parseMisspellCertificateRequest = (
  values: MisspellApplicationDataCertificateFormValues
): MisspellApplicationDataCertificateRecordUpdateRequest => ({
  ...values,
  vehicleModelKey: values?.vehicleModelKey?.value,
  vehicleBrandKey: values?.vehicleBrandKey?.value
});

export const parseMisspellDriverCertificateRequest = ({
  qualification,
  ...values
}: MisspellApplicationDataDriverCertificateFormValues): MisspellApplicationDataDriverCertificateRecordUpdateRequest => ({
  ...values,
  driverCompetenceCodeKey: qualification ? values.driverCompetenceCodeKey?.value : null,
  driverExpiryDateCompetenceCard: qualification ? values.driverExpiryDateCompetenceCard : null,
  subjectApartmentNumber: values.apartmentNumber,
  subjectCity: values.city,
  subjectPostCity: values.postCity,
  subjectPostCode: values.postCode,
  subjectPropertyNumber: values.propertyNumber,
  subjectStreet: values.street
});

export const parseDetailsToMisspellDriverCertificateFormValues = (
  values: MisspellDriverCertificateDetails & ApplicationVersion
): MisspellApplicationDataDriverCertificateFormValues => ({
  applicationVersion: values.applicationVersion,
  dateOfIssue: values.dateOfIssue,
  driverBirthDate: values.driverBirthDate,
  driverBirthPlace: values.driverBirthPlace,
  driverCitizenship: values.driverCitizenship,
  driverName: values.driverName,
  driverSurname: values.driverSurname,
  qualification: Boolean(values.driverCompetenceCodeKey),
  driverCompetenceCodeKey: values.driverCompetenceCodeKey ? { value: values.driverCompetenceCodeKey } : null,
  driverExpiryDateCompetenceCard: values.driverExpiryDateCompetenceCard,
  id: values.id,
  driverLicense: {
    issuingCountryKey: values.driverLicense?.issuingCountryKey,
    issuingDate: values.driverLicense?.issuingDate,
    seriesNumber: values.driverLicense?.seriesNumber,
    typeKey: values.driverLicense?.typeKey
  },
  identityCard: {
    issuingCountryKey: values.identityCard?.issuingCountryKey,
    issuingDate: values.identityCard?.issuingDate,
    seriesNumber: values.identityCard?.seriesNumber,
    typeKey: values.identityCard?.typeKey
  },
  insurancePayerNip: values.insurancePayerNip,
  number: values.number,
  subjectName: values.subjectName,
  validFrom: values.validFrom,
  validTo: values.validTo,
  apartmentNumber: values.subjectApartmentNumber,
  city: values.subjectCity,
  postCity: values.subjectPostCity,
  postCode: values.subjectPostCode,
  propertyNumber: values.subjectPropertyNumber,
  street: values.subjectStreet
});

export const getApplicationCategoryType = (typeKey: string) => {
  if (typeKey === DomainDictionaryEntry.APPLICATION_TYPE.PB14) {
    return DomainDictionaryEntry.APPLICATION_CATEGORY.ROAD_CARRIER_GOODS_CARRIAGE_ABRIDGEMENT;
  }

  if (typeKey === DomainDictionaryEntry.APPLICATION_TYPE.PB33) {
    return DomainDictionaryEntry.APPLICATION_CATEGORY.BROKERAGE_IN_TRANSPORT_OF_PASSENGERS;
  }

  if (typeKey === DomainDictionaryEntry.APPLICATION_TYPE.sCertificateReprintBtm) {
    return DomainDictionaryEntry.APPLICATION_CATEGORY.S_CERTIFICATE;
  }

  if (typeKey === DomainDictionaryEntry.APPLICATION_TYPE.inputMistake) {
    return DomainDictionaryEntry.APPLICATION_CATEGORY.DRIVER_CERTIFICATE;
  }

  return null;
};
