import { useTranslation } from 'react-i18next';

import { TableLayout, useRouter, useTableRowActions } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { DomainUIElementEnum } from '@libs/domain/config';

import { useViolationsTable, useViolationStatusChangeDialog } from '../../../hooks';
import { IViolationClient } from '../../../models';
import { parseViolationsListResults } from '../../../utils';

import GoToProceedingsGroupButton from './GoToProceedingsGroupButton';

interface IProps {
  nip?: string;
  preceedingKreptdId?: string;
  isSection?: boolean;
  isCollapsable: boolean;
  isSectionFullTab?: boolean;
}

function ViolationsTable({ nip, preceedingKreptdId, isSection, isCollapsable, isSectionFullTab }: IProps) {
  const [t] = useTranslation();

  const tableProps = useViolationsTable(nip, preceedingKreptdId);
  const { openViolationStatusChangeDialog } = useViolationStatusChangeDialog();
  const { routes } = useRouter();

  const { renderTableActions } = useTableRowActions<IViolationClient>([
    {
      id: DomainUIElementEnum.ROAD_TRANSPORT_VIEW_BUTTON,
      label: 'roadTransport:actions.goToProceeding',
      link: ({ original }) => routes.roadTransportDetails(original?.proceedingsKreptd?.[0].proceedingKreptdId),
      icon: 'ArrowIcon',
      isHidden: ({ row: { original } }) =>
        !original?.rwaCaseNumber ||
        original?.proceedingsKreptd?.length !== 1 ||
        preceedingKreptdId === original?.proceedingsKreptd?.[0].proceedingKreptdId
    },
    {
      id: DomainUIElementEnum.ROAD_TRANSPORT_CHANGE_STATUS_BUTTON,
      label: 'roadTransport:actions.changeStatus',
      icon: 'ChangeStatusIcon',
      onClick: ({ row: { original } }) => openViolationStatusChangeDialog(original)
    },
    {
      id: DomainUIElementEnum.ROAD_TRANSPORT_VIEW_BUTTON,
      label: null,
      customButton: ({ original }) => (
        <GoToProceedingsGroupButton
          proceedingKreptdId={preceedingKreptdId}
          proceedingsKreptd={original.proceedingsKreptd}
        />
      ),
      isHidden: ({ row: { original } }) => original?.proceedingsKreptd?.length <= 1
    }
  ]);

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('roadTransport:list.violations')}
      xlsxDownload={{
        fileName: t('roadTransport:list.violations'),
        pathToXLSXTranslation: 'resource:fields',
        apiRequest: params =>
          API.violations
            .getViolationsSnapshotPage(params)
            .then(res => ({ ...res, data: { ...res.data, content: parseViolationsListResults(res.data.content) } }))
      }}
      rowActions={renderTableActions([
        DomainUIElementEnum.ROAD_TRANSPORT_VIEW_BUTTON,
        DomainUIElementEnum.ROAD_TRANSPORT_CHANGE_STATUS_BUTTON
      ])}
      isCollapsable={isCollapsable}
      isSection={isSection}
      isSectionFullTab={isSectionFullTab}
    />
  );
}

export default ViolationsTable;
