import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryCache } from 'react-query';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ApplicationTabName } from '@ibtm/domain';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';

import { CheckboxField, Dialog, FormV2Context, GridLayout } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';

import { ApplicationQueryKeysEnum, useCopyDataFromApplicationMutation } from '../../api';
import ApplicationNumberAutocomplete from '../ApplicationNumberAutocomplete';

interface Props {
  onCloseDialog: () => void;
  applicationId: string;
}

function CopyDataFromApplicationDialog({ onCloseDialog, applicationId }: Props) {
  const [t] = useTranslation();
  const queryCache = useQueryCache();
  const classes = useStyles();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: copyData, isLoading } = useCopyDataFromApplicationMutation();

  const inputsLabels = {
    ADDRESSES: t('applications:dialog.copyData.addresses'),
    BASIC_DATA: t('applications:dialog.copyData.basicData'),
    CABOTAGE: t('applications:dialog.copyData.cabotage'),
    FILES: t('applications:dialog.copyData.files'),
    PARTNERS: t('applications:dialog.copyData.partners'),
    PROXIES: t('applications:dialog.copyData.proxies'),
    VEHICLES: t('applications:dialog.copyData.vehicles'),
    SUBJECT: t('applications:dialog.copyData.subject'),
    TRANSIT_SCHEDULE: t('applications:dialog.copyData.transitSchedule')
  };

  const validationSchema = Yup.object({
    applicationNumber: Yup.string().required()
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    setError,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = values => {
    const applicationTabsList = Object.keys(values).filter(key => {
      return values[key] === true;
    }) as ApplicationTabName[];

    copyData(
      {
        applicationId,
        copyPayload: {
          copyDataFromApplicationId: watch('applicationNumber') as string,
          applicationTabsList
        }
      },
      {
        onSuccess: applicationDetails => {
          queryCache.removeQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
          queryCache.setQueryData([ApplicationQueryKeysEnum.APPLICATION, applicationId], applicationDetails);
          onCloseDialog();
          showSuccessSnackbar(t('success.save'));
        }
      }
    );
  };

  const values = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      setError,
      trigger,
      unregister,
      isSubmitting
    }),
    [control, errors, getValues, isSubmitting, register, setError, setValue, trigger, unregister, watch]
  );

  return (
    <FormV2Context.Provider value={values}>
      <Dialog
        title={t('applications:dialog.copyData.title')}
        shortTitle={t('applications:dialog.copyData.shortTitle')}
        confirmText={t('action.confirm')}
        cancelText={t('action.cancel')}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={onCloseDialog}
        dialogSize="small"
        isConfirmLoading={isLoading}
        isOpen
      >
        <form>
          <div className={classes.wrapper}>
            <ApplicationNumberAutocomplete applicationId={applicationId} />
          </div>
          <GridLayout itemProps={{ md: 6, xs: 12 }}>
            {Object.keys(ApplicationTabName).map(key => (
              <CheckboxField
                name={ApplicationTabName[key]}
                label={inputsLabels[ApplicationTabName[key]]}
                key={ApplicationTabName[key]}
                rowLayout
              />
            ))}
          </GridLayout>
        </form>
      </Dialog>
    </FormV2Context.Provider>
  );
}

const useStyles = makeStyles<Theme>(() => ({
  wrapper: {
    marginBottom: '32px'
  }
}));

export default CopyDataFromApplicationDialog;
