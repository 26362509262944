import { useTranslation } from 'react-i18next';

import { useDictionaryKeyDialog } from '@libs/common/v2';

import { DomainDictionaryEnum } from '../../../config';

function useFinancialSecuritySummaryInfoAction() {
  const [t] = useTranslation();
  const [open] = useDictionaryKeyDialog();

  const handleFinancialSecuritySummaryInfo = () => {
    open({
      title: t('financialSecurity:section.summary.dialog.rulesOfRecalculationFinancialSecurityTitle'),
      dictionaryKey: DomainDictionaryEnum.GENERAL_DICTIONARY,
      dictionaryItem: 'ibtm-domain.general-dictionary.financial-security-summary-explanation'
    });
  };

  return { handleFinancialSecuritySummaryInfo };
}

export default useFinancialSecuritySummaryInfoAction;
