import { useTranslation } from 'react-i18next';

import { partialTranslate } from '@libs/common';
import { FormMode, IconButton, InputMode, Section, useViewModesV2 } from '@libs/common/v2';

import { DomainUIElementEnum } from '@libs/domain/config';

import { useFinancialSecuritySummaryInfoAction } from '../../../hooks/action';
import { useFinancialSecurityApplicationExchangeSummaryDialog } from '../../../hooks/dialog/useFinancialSecurityApplicationExchangeSummaryDialog';

import FinancialSecurityApplicationExchangeFields, {
  IFinancialSecurityApplicationExchangeFields
} from './FinancialSecurityApplicationExchangeFields';

const translate = partialTranslate('financialSecurity:section.summary');

interface IProps extends IFinancialSecurityApplicationExchangeFields {
  applicationId: string;
  formMode?: FormMode;
  sectionActionKey?: DomainUIElementEnum;
}
export function FinancialSecuritySummarySection({ applicationId, formMode, sectionActionKey, ...props }: IProps) {
  const { handleFinancialSecuritySummaryInfo } = useFinancialSecuritySummaryInfoAction();
  const { editMode } = useViewModesV2(formMode);
  const [t] = useTranslation();

  const { openFinancialSecurityApplicationExchangeSummaryDialog } =
    useFinancialSecurityApplicationExchangeSummaryDialog();
  return (
    <Section
      title={translate('title')}
      actionKey={sectionActionKey}
      headerActions={
        <>
          <IconButton
            icon="HelpIcon"
            tooltipTitle={t('action.help')}
            onClick={handleFinancialSecuritySummaryInfo}
            isBackgroundTransparent
          />
          {editMode && (
            <IconButton
              icon="EditIcon"
              tooltipTitle={t('action.edit')}
              onClick={() => {
                openFinancialSecurityApplicationExchangeSummaryDialog({ ...props, applicationId });
              }}
              isBackgroundTransparent
            />
          )}
        </>
      }
    >
      <FinancialSecurityApplicationExchangeFields {...props} applicationId={applicationId} inputMode={InputMode.VIEW} />
    </Section>
  );
}
