import { useQuery } from 'react-query';
import { PermanentPermissionSubmissionDetails } from '@ibtm/domain';

import { API } from '@libs/domain/api';
import { SocialCommissionQueryKeysEnum } from '@libs/domain/social-commission';

import { endpointsConfig } from '../endpoints-config';

/**
 * Sort items array to enable displaying in right order
 * @param {PermanentPermissionSubmissionDetails} queryData
 * @returns {Array<PermanentPermissionSubmissionItemDetails>} sorted items array
 */
const sortQueryDataItems = (queryData: PermanentPermissionSubmissionDetails) => {
  return queryData?.items?.sort((a, b) => {
    const fa = a.resourceFormNameKey;
    const fb = b.resourceFormNameKey;

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
};

export const getFormValues = async (_, permanentSubmissionId: string) => {
  const { data } = await API.civicBoard.getPermanentPermissionSubmission(permanentSubmissionId, {
    ...endpointsConfig.getPermanentPermissionSubmissionDetailsPage
  });

  if (data) {
    const queryData = data;
    queryData.items = sortQueryDataItems(queryData);
    return queryData;
  }

  return null;
};

function useEKTMFormQuery(permissionSubmissionId: string, queryConfig = {}) {
  return useQuery(
    [SocialCommissionQueryKeysEnum.PERMANENT_PERMISSION_SUBMISSION_DETAILS, permissionSubmissionId],
    getFormValues,
    queryConfig
  );
}
export default useEKTMFormQuery;
