import { useCallback } from 'react';
import { AxiosResponse } from 'axios';

import { useDialog } from '@libs/common/v2';

import { AddFileRequestData, FileSignDialog } from '@libs/file';

const useSignFileDialog = () => {
  const { openDialog } = useDialog();

  const open = useCallback(
    ({
      fileId,
      apiCreateQuery
    }: {
      fileId: string;
      onSuccess?: () => void;
      apiCreateQuery: (formValues: AddFileRequestData) => Promise<AxiosResponse<AddFileRequestData>>;
    }) => {
      openDialog(({ closeDialog }) => (
        <FileSignDialog fileId={fileId} closeDialog={closeDialog} apiCreateQuery={apiCreateQuery} />
      ));
    },
    [openDialog]
  );

  return {
    openSignFileDialog: open
  };
};

export default useSignFileDialog;
