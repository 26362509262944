import { TravelFormPrintsSnapshot } from '@ibtm/domain';
import { v4 as uuidv4 } from 'uuid';

import { useTableLayoutProps } from '@libs/common/v2';

import { useGetTravelFormPrintsSnapshotsQuery } from '../api';

import useTravelFormColumns from './useTravelFormColumns';

function useTravelFormTable({ applicationId, isInterbus }: { applicationId: string; isInterbus: boolean }) {
  const { columns } = useTravelFormColumns();
  const { data, isLoading, isFetching, refetch } = useGetTravelFormPrintsSnapshotsQuery(applicationId);

  const dataWithIds: TravelFormPrintsSnapshot[] = data
    ?.filter(item => item.interbus === isInterbus)
    ?.map(item => ({ id: uuidv4(), ...item }));

  return useTableLayoutProps({
    tableQuery: dataWithIds,
    tableHookOptions: {
      columns,
      tableOptions: {
        manualPagination: false,
        manualSortBy: false,
        manualFilters: false
      }
    },
    isFilterEnabled: false,
    refetch,
    isLoading,
    isFetching
  });
}

export default useTravelFormTable;
