import { useEffect } from 'react';

import { SwitchField, useFormV2Context } from '@libs/common/v2';

import { useMetaFormContext } from '@libs/meta-form/context';
import { BooleanFieldProperties, FieldProps, FieldTypes } from '@libs/meta-form/models';
import { useGetQuery, useMapAdditionalFieldsValidationType } from '@libs/meta-form/services';

function BooleanField({
  label,
  fieldId,
  api,
  disabled,
  defaultInitialValue,
  yupValidation,
  inputMode,
  hidden,
  triggerFieldIdValidationOnChange
}: FieldProps<BooleanFieldProperties>) {
  const { apiRegistry } = useMetaFormContext();
  const { setValue, trigger, watch } = useFormV2Context();
  useMapAdditionalFieldsValidationType(fieldId, { type: FieldTypes.BOOLEAN });

  useGetQuery({
    requestKey: api.get.requestKey,
    accessor: api.get.accessor,
    apiRegistry,
    params: api?.get?.backendParams,
    setValue: newValue => {
      setValue(fieldId, (newValue && Boolean(newValue)) ?? defaultInitialValue ?? Boolean(defaultInitialValue));
    },
    fieldId
  });

  let fieldIdWatch = null;

  if (triggerFieldIdValidationOnChange) {
    fieldIdWatch = watch(fieldId);
  }

  useEffect(() => {
    if (fieldIdWatch) {
      trigger(triggerFieldIdValidationOnChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldIdWatch]);

  return (
    <SwitchField
      name={fieldId}
      label={label}
      isHidden={hidden}
      isDisabled={disabled}
      isRequired={yupValidation?.required}
      inputMode={inputMode}
    />
  );
}

export default BooleanField;
