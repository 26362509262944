import { useCallback } from 'react';

import { partialTranslate } from '@libs/common';
import { Value } from '@libs/common/v2';
import SectionWithFields from '@libs/common/v2/components/section/SectionWithFields';
import { getNumberValue } from '@libs/common/v2/utils';

import { useForeignVehiclesQuery } from '../../../api';
import { VehiclesReportedForApprovalFields } from '../../../model';

interface IProps {
  folderId?: string;
  enabledFields: Array<VehiclesReportedForApprovalFields>;
}

const translate = partialTranslate('financialSecurity:section.vehiclesReportedForApproval');

function VehiclesReportedForApproval({ folderId, enabledFields }: IProps) {
  const { data, isLoading } = useForeignVehiclesQuery({ folderId });
  const showField = useCallback(
    (field: VehiclesReportedForApprovalFields) => enabledFields.includes(field),
    [enabledFields]
  );

  return (
    <SectionWithFields
      title={translate('title')}
      isLoading={isLoading}
      isCollapsable
      fields={[
        ...(showField('numberOfBCategoryVehicles')
          ? [
              <Value label={translate('field.numberOfBCategoryVehicles')} key="numberOfBCategoryVehicles">
                {getNumberValue(data?.foreignPermissionVehiclesNumberBelowThreeAndHalfTons)}
              </Value>
            ]
          : []),
        ...(showField('numberOfCCategoryVehicles')
          ? [
              <Value label={translate('field.numberOfCCategoryVehicles')} key="numberOfCCategoryVehicles">
                {getNumberValue(data?.foreignPermissionVehiclesNumberAboveThreeAndHalfTons)}
              </Value>
            ]
          : []),
        ...(showField('foreignPermissionVehiclesNumberAboveThreeAndHalfTons')
          ? [
              <Value
                label={translate('field.foreignPermissionVehiclesNumberAboveThreeAndHalfTons')}
                key="foreignPermissionVehiclesNumberAboveThreeAndHalfTons"
              >
                {getNumberValue(data?.foreignPermissionVehiclesNumberAboveThreeAndHalfTons)}
              </Value>
            ]
          : []),
        ...(showField('foreignPermissionVehiclesNumberBelowThreeAndHalfTons')
          ? [
              <Value
                label={translate('field.foreignPermissionVehiclesNumberBelowThreeAndHalfTons')}
                key="foreignPermissionVehiclesNumberBelowThreeAndHalfTons"
              >
                {getNumberValue(data?.foreignPermissionVehiclesNumberBelowThreeAndHalfTons)}
              </Value>
            ]
          : []),
        ...(showField('foreignPermissionVehiclesNumber')
          ? [
              <Value label={translate('field.foreignPermissionVehiclesNumber')} key="foreignPermissionVehiclesNumber">
                {getNumberValue(data?.foreignPermissionVehiclesNumber)}
              </Value>
            ]
          : [])
      ]}
    />
  );
}

export default VehiclesReportedForApproval;
