import { QueryConfig, useQuery } from 'react-query';
import { NoteDetails } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '..';

import { ProceedingQueryKeysEnum } from '.';

const getProceedingNote = async (_, id: string) => {
  const { data } = await API.proceedingNote.getProceedingNote(id, { ...endpointsConfig.getProceedingNote });
  return data;
};

function useProceedingNoteQuery(id: string, queryConfig: QueryConfig<NoteDetails> = {}) {
  return useQuery([ProceedingQueryKeysEnum.PROCEEDING_NOTE, id], getProceedingNote, queryConfig);
}

export default useProceedingNoteQuery;
