import {
  ExternalAuthorityPermissionCreateRequest,
  ExternalAuthorityPermissionUpdateRequest,
  ForeignAuthorityPermissionCreateRequest,
  ForeignAuthorityPermissionUpdateRequest,
  PermissionCopySnapshot,
  PermissionDetails
} from '@ibtm/domain';

import { convertCalendarDate, getCalendarDate } from '@libs/common/v2/utils';

import { PermissionTableEnum } from '@libs/domain/application';
import { parseAddressString } from '@libs/domain/license';

import { PermissionCopySnapshotClient, PermissionFields, PermissionSnapshotClient } from '../model';

const getCorrectValue = (fieldName: string, permission: PermissionDetails, isExtendedList?: boolean) => {
  if (isExtendedList) {
    if (permission.applyToPartnership) {
      return permission.subject?.[fieldName];
    }
    return permission.partnershipPartners.map(partner => partner[fieldName]).join(', ');
  }
  return permission.subject?.[fieldName];
};

const parsePermission = (permission: PermissionDetails, isExtendedList?: boolean): PermissionSnapshotClient => ({
  id: permission.id,
  applicationId: permission.application?.id,
  applicationNumber: permission.application?.number,
  cancellationReason: permission.cancellationReasonKey,
  cancelledBy: permission.cancelledBy?.name,
  cancellationDate: convertCalendarDate(permission.cancellationDate),
  subjectName: getCorrectValue('name', permission, isExtendedList),
  certificateNumber: permission?.number,
  subjectNip: getCorrectValue('nip', permission, isExtendedList),
  subjectAddress: parseAddressString(permission.subjectAddress),
  resourceAssignmentRegenerationNeeded: permission?.resourceAssignmentRegenerationNeeded,
  permissionNumber: permission.number,
  number: permission.number,
  formNumber: permission.form?.fullNumber,
  dateOfIssue: convertCalendarDate(permission.dateOfIssue),
  dateOfIssueGitd: convertCalendarDate(permission.dateOfIssue),
  validFrom: convertCalendarDate(permission.validFrom),
  validTo: convertCalendarDate(permission.validTo),
  signerName: permission.signer?.name,
  status: permission.statusKey,
  printDate: convertCalendarDate(permission.printDate),
  issuingCountryKey: permission.issuingCountryKey,
  issuingAuthority: permission.issuingAuthority,
  version: permission.version,
  dateOfIssueExternal: convertCalendarDate(permission.dateOfIssueExternal),
  file: permission.file,
  form: permission.form,
  forms: permission?.forms,
  permissionValidityPeriodKey: permission?.permissionValidityPeriodKey,
  transitStopEndTour: permission?.transitStopEndTour?.map(element => ({
    city: element.city,
    countryKey: element.countryKey
  })),
  transitStopStartTour: permission?.transitStopStartTour?.map(element => ({
    city: element.city,
    countryKey: element.countryKey
  })),
  issuedLocks: permission.issuedLocks,
  locksToSetUp: permission.locksToSetUp,
  permissionsGenerated: permission.permissionsGenerated
});

export const parsePermissionsList = (permissions: PermissionDetails[]): PermissionSnapshotClient[] =>
  permissions.map(permission => parsePermission(permission));

export const parseExternalAuthPermissionsList = (permissions: PermissionDetails[]): PermissionSnapshotClient[] =>
  permissions.map(permission => parsePermission(permission, true));

export const parsePermissionCopiesList = (permissionCopies: PermissionCopySnapshot[]): PermissionCopySnapshotClient[] =>
  permissionCopies.map(permissionCopy => ({
    id: permissionCopy.id,
    applicationNumber: permissionCopy.applicationNumber,
    cancellationReason: permissionCopy.cancellationReasonKey,
    cancelledBy: permissionCopy.cancelledBy?.name,
    cancellationDate: convertCalendarDate(permissionCopy.cancellationDate),
    subjectName: permissionCopy.subject?.name,
    subjectAddress: parseAddressString(permissionCopy.subjectAddress),
    permissionNumber: permissionCopy.permissionNumber,
    resourceAssignmentRegenerationNeeded: permissionCopy.resourceAssignmentRegenerationNeeded,
    permissionCopyNumber: permissionCopy.number,
    formNumber: permissionCopy.formNumber,
    certificateNumber: permissionCopy.permissionNumber,
    dateOfIssue: convertCalendarDate(permissionCopy.dateOfIssue),
    dateOfIssueExternal: convertCalendarDate(permissionCopy.dateOfIssueExternal),
    validFrom: convertCalendarDate(permissionCopy.validFrom),
    validTo: convertCalendarDate(permissionCopy.validTo),
    signerName: permissionCopy.signer?.name,
    status: permissionCopy.statusKey,
    transitType: permissionCopy.transitTypeKey,
    printDate: convertCalendarDate(permissionCopy.printDate),
    version: permissionCopy.version,
    file: permissionCopy.file,
    form: permissionCopy.form,
    forms: permissionCopy.forms,
    number: permissionCopy.number
  }));

export const parsePermissionFields = (data: PermissionDetails): PermissionFields => ({
  applyToPartnership: data.applyToPartnership,
  permissionNumber: data.number,
  author: data.author?.name,
  created: data.created,
  enterpreneur: data.subject?.name,
  foreignPermissionName: data.foreignPermissionName,
  issuingAuthorityKey: { value: data.issuingAuthority, name: data.issuingAuthority },
  issuingCountryKey: { value: data.issuingCountryKey },
  modified: data.modified,
  modifier: data.modifier?.name,
  isPartnershipPermission: data.applyToPartnership,
  statusKey: { value: data.statusKey },
  dateOfIssue: convertCalendarDate(data.dateOfIssue),
  validFrom: convertCalendarDate(data.validFrom),
  validTo: convertCalendarDate(data.validTo),
  version: data.version,
  typeKey: { value: data.typeKey },
  partnershipEnterpreneur: data?.partnershipPartners?.map(item => item.name).join(', '),
  partnershipPartnersNip: data?.partnershipPartners?.map(item => ({ value: item.nip })),
  validIndefinitely: !data.validTo
});

const parseRequestBase = (formValues: PermissionFields) => ({
  number: formValues.permissionNumber,
  typeKey: formValues.typeKey?.value,
  dateOfIssue: formValues.dateOfIssue,
  validTo: formValues.validTo,
  version: formValues.version
});

interface IPermissionRequestParser {
  [PermissionTableEnum.EXTERNAL_AUTHORITY_PERMISSION]:
    | ExternalAuthorityPermissionCreateRequest
    | (ExternalAuthorityPermissionUpdateRequest & { applicationId: string }); // Obejście bo: applicationId jest optionalne w ExternalAuthorityPermissionUpdateRequest i wymagane w ExternalAuthorityPermissionCreateRequest
  [PermissionTableEnum.FOREIGN_AUTHORITY_PERMISSION]:
    | ForeignAuthorityPermissionCreateRequest
    | (ForeignAuthorityPermissionUpdateRequest & { applicationId: string }); // Obejście bo: applicationId jest optionalne w ExternalAuthorityPermissionUpdateRequest i wymagane w ExternalAuthorityPermissionCreateRequest
}

export const parsePermissionRequests = (data: PermissionFields, applicationId: string): IPermissionRequestParser => {
  const requestBase = { applicationId, ...parseRequestBase(data) };
  const partnershipPartnersNip: string[] = [];
  data?.partnershipPartnersNip?.forEach(nip => partnershipPartnersNip.push(nip?.value));

  return {
    [PermissionTableEnum.FOREIGN_AUTHORITY_PERMISSION]: {
      ...requestBase,
      dateOfIssue: getCalendarDate(data.dateOfIssue),
      validTo: getCalendarDate(data.validTo),
      foreignPermissionName: data.foreignPermissionName,
      issuingCountryKey: data.issuingCountryKey?.value
    },
    [PermissionTableEnum.EXTERNAL_AUTHORITY_PERMISSION]: {
      ...requestBase,
      ...(partnershipPartnersNip?.length > 0 && { partnershipPartnersNip }),
      dateOfIssue: getCalendarDate(data.dateOfIssue),
      validTo: getCalendarDate(data.validTo),
      isPartnershipPermission: data.isPartnershipPermission,
      issuingAuthority: data.issuingAuthorityKey?.value
    }
  };
};
