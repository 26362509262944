import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { Dialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { ArchiveQueryKeysEnum } from '../../../common';
import { useRestoreFolderFromArchiveMutation } from '../../api';

interface IFolderFromArchiveDialog {
  ids: string[];
  closeDialog: () => void;
}

const FolderFromArchiveDialog = ({ ids, closeDialog }: IFolderFromArchiveDialog) => {
  const [t] = useTranslation();
  const queryCache = useQueryCache();
  const { showSnackbar } = useSnackbar();

  const { mutateAsync: restoreFolderFromArchive, isLoading } = useRestoreFolderFromArchiveMutation();

  return (
    <Dialog
      isOpen
      onConfirm={async () => {
        await restoreFolderFromArchive(
          {
            ids
          },
          {
            onSuccess: () => {
              closeDialog();
              queryCache.invalidateQueries(ArchiveQueryKeysEnum.FOLDERS_TO_ARCHIVE);

              showSnackbar('success', t('archive:folder.message.restoredFromArchive'));
            }
          }
        );
      }}
      confirmText={t('action.restore')}
      title={t('archive:restoringFromArchive')}
      cancelText={t('action.cancel')}
      onCancel={closeDialog}
      onClose={closeDialog}
      isConfirmLoading={isLoading}
    >
      {t('archive:folder.message.fromArchiveDialogQuestion')}
    </Dialog>
  );
};

export default FolderFromArchiveDialog;
