import { Page } from '@libs/common/v2';

interface IProps {
  title: string;
  content: React.ReactNode;
}

function ArchivePage({ title, content }: IProps) {
  return (
    <Page
      contentClassName="flex-col"
      title={title}
      content={content}
      isContentScrollEnabled={false}
      isTable
      isNoContentMargin
    />
  );
}
export default ArchivePage;
