import { MutationConfig } from 'react-query';
import { DocumentMultiplePrepareForSendingRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

async function prepareDocumentsForSending(requestData: DocumentMultiplePrepareForSendingRequest) {
  return API.documents.prepareDocumentsForSendingOrMarkAsDelivered(requestData, {
    ...endpointsConfig.prepareDocumentsForSendingOrMarkAsDelivered
  });
}

function usePrepareDocumentsForSendingMutation(config?: MutationConfig<any>) {
  return useMutation(prepareDocumentsForSending, config);
}

export default usePrepareDocumentsForSendingMutation;
