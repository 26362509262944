import { FolderRentalBorrowerUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const changeFolderAssignment = (params: FolderRentalBorrowerUpdateRequest) =>
  API.borrowFolders.changeRentedFolderAssignment(params, {
    ...endpointsConfig.changeRentedFolderAssignment
  });

export const useChangeFolderAssignmentMutation = () => useMutation(changeFolderAssignment);
