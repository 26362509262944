import { FolderCreateRequest } from '@ibtm/domain';
import { isEmpty, isNull } from 'lodash';

import { DomainDictionaryEntry } from '@libs/domain/config';

export const checkIsPartnership = (legalNatureKey: string) => {
  return legalNatureKey === DomainDictionaryEntry.LEGAL_NATURE_KEY.CIVIL_PARTNERSHIP;
};

export const subjectContactAddressParser = (values: FolderCreateRequest) => {
  const contactAddresses = values.subject.contactAddresses[0];
  const isNullOrEmptyStringInContactAddresses =
    (isNull(contactAddresses?.fax) || isEmpty(contactAddresses?.fax)) &&
    (isNull(contactAddresses?.email) || isEmpty(contactAddresses?.email)) &&
    (isNull(contactAddresses?.phone) || isEmpty(contactAddresses?.phone));

  return isNullOrEmptyStringInContactAddresses ? [] : values.subject.contactAddresses;
};
