import { usePaginatedQuery } from 'react-query';
import { DocumentSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { DocumentsSnapshotClient } from '../../model';
import { parseDocumentsList } from '../../parsers';
import { endpointsConfig } from '../endpoints-config';

import DocumentsQueryKeysEnum from './DocumentsQueryKeysEnum';

export const getDocuments = async (__, params: DocumentSearchFilter) => {
  const { data } = await API.documents.getDocumentsSnapshotPage(params, {
    ...endpointsConfig.getDocumentsSnapshotPage
  });

  const response = {
    ...data,
    content: parseDocumentsList(data.content)
  };

  return response;
};

export default function useDocumentsQuery(params: DocumentSearchFilter, queryConfig = {}) {
  return usePaginatedQuery<IPaginatedModel<DocumentsSnapshotClient>>(
    [DocumentsQueryKeysEnum.DOCUMENTS_LIST, params],
    getDocuments,
    queryConfig
  );
}
