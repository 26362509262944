import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function deleteVehicles({ applicationId, vehicleIds }: { applicationId: string; vehicleIds: string[] }) {
  return API.application.deleteVehicles(
    applicationId,
    { vehiclesId: vehicleIds },
    {
      ...endpointsConfig.deleteVehicles
    }
  );
}

function deleteVehiclesClient({ applicationId, vehicleIds }: { applicationId: string; vehicleIds: string[] }) {
  return API.client.application.deleteVehicles(
    applicationId,
    '',
    '',
    { vehiclesId: vehicleIds },
    {
      ...endpointsConfig.deleteVehicles
    }
  );
}

function useDeleteMultipleVehicleMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(deleteVehiclesClient, deleteVehicles));
}

export default useDeleteMultipleVehicleMutation;
