import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { PermissionRestoreFromDistrictAuthorityRequest } from '@ibtm/domain';

import {
  Dialog,
  DictionarySelectField,
  FormMode,
  FormV2Context,
  GridLayout,
  Section,
  typedNameV2
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { PermissionsQueryKeysEnum, usePermissionRestoreFromDistrictAuthorityMutation } from '../api';

interface IProps {
  permissionId: string;
  onCloseDialog: () => void;
  onSuccess: () => void;
  isOpen: boolean;
}

type RestoreFromDistrictAuthorityFields = {
  restoreReasonKey?: {
    key: string;
    value: string;
  };
};

function PermissionRestoreFromDistrictAuthorityDialog({ permissionId, onCloseDialog, onSuccess, isOpen }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();

  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { mutate: restoreFromDistrictAuthority, isLoading } = usePermissionRestoreFromDistrictAuthorityMutation();

  const {
    control,
    register,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    handleSubmit
  } = useForm({
    mode: 'onBlur',
    criteriaMode: 'all'
  });

  const formValues = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      formMode: FormMode.EDIT
    }),
    [control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting]
  );

  const handleRestoreFromDisctrictAuthoritySubmit = (values: RestoreFromDistrictAuthorityFields) => {
    restoreFromDistrictAuthority(
      {
        id: permissionId,
        requestData: {
          restoreReasonKey: values?.restoreReasonKey?.value
        }
      },
      {
        onSuccess: () => {
          queryCache.invalidateQueries(PermissionsQueryKeysEnum.PERMISSIONS_LIST);
          showSuccessSnackbar(t('permission:action.restoreFromDistrictAuthority.successMessage'));
          onCloseDialog();
          onSuccess();
        },
        onError: () => {
          showErrorSnackbar(t('permission:action.restoreFromDistrictAuthority.failureMessage'));
        }
      }
    );
  };

  return (
    <FormV2Context.Provider value={formValues}>
      <form onSubmit={handleSubmit(handleRestoreFromDisctrictAuthoritySubmit)}>
        <Dialog
          title={t('permission:action.restoreFromDistrictAuthority.dialogTitle')}
          confirmText={t('action.save')}
          cancelText={t('action.cancel')}
          onConfirm={handleSubmit(handleRestoreFromDisctrictAuthoritySubmit)}
          onCancel={onCloseDialog}
          dialogSize="medium"
          isConfirmLoading={isLoading}
          isOpen={isOpen}
        >
          <Section isCollapsable isModalSection isHeaderVisible={false}>
            <GridLayout itemProps={{ xs: 12 }}>
              <DictionarySelectField
                label={t('permission:field.restoreReasonKey')}
                name={typedNameV2<PermissionRestoreFromDistrictAuthorityRequest>('restoreReasonKey')}
                dictionaryName={DomainDictionaryEnum.PERMISSION_RESTORE_FROM_DISTRICT_AUTHORITY_REASON}
                isRequired
              />
            </GridLayout>
          </Section>
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
}

export default PermissionRestoreFromDistrictAuthorityDialog;
