import { useTranslation } from 'react-i18next';
import { ColumnTypesEnum, TableFilterMapper, TableSortMapper, useCreateColumns, Value } from '@enigma/fe-ui';
import { SCertificatesSearchFilter as SCertificatesSearchFilterClient } from '@ibtm/client-domain';
import { SCertificatesSearchFilter } from '@ibtm/domain';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { PrintCertificatesColumnsEnum, SCertificatesSnapshot } from '../model';

interface DictionaryFilterObject<T = string> {
  value: T;
}

export function useSCertificatesColumnsV2({ visibleColumns }: { visibleColumns: Array<PrintCertificatesColumnsEnum> }) {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  const { createColumns } = useCreateColumns<SCertificatesSnapshot>();

  const columns = createColumns(
    [
      {
        id: 'statusKey',
        accessor: 'statusKey',
        header: t('sCertificates:field.status'),
        type: ColumnTypesEnum.DICTIONARY,
        columnFormatter: value => (
          <Value variant="dictionary" dictionaryKey={DomainDictionaryEnum.CERTIFICATE_S_STATUS} value={value} />
        ),
        columnXLSXFormatter: value => {
          return translate(DomainDictionaryEnum.CERTIFICATE_S_STATUS, value, t('emptyMark'));
        },
        dictionaryKey: DomainDictionaryEnum.CERTIFICATE_S_STATUS
      },
      {
        id: 'subjectName',
        accessor: 'subject.name',
        header: t('sCertificates:field.subjectName'),
        type: ColumnTypesEnum.TEXT
      },
      {
        id: 'folderNumber',
        accessor: 'folder.number',
        header: t('sCertificates:field.folderNumber'),
        type: ColumnTypesEnum.TEXT
      },
      {
        id: 'vehiclePlateNumber',
        accessor: 'vehiclePlateNumber',
        header: t('sCertificates:field.vehicleRegistrationNumber'),
        type: ColumnTypesEnum.TEXT
      },
      {
        id: 'vehicleVin',
        accessor: 'vehicleVin',
        header: t('sCertificates:field.vehicleVin'),
        type: ColumnTypesEnum.TEXT
      },
      {
        id: 'number',
        accessor: 'number',
        header: t('sCertificates:field.number'),
        type: ColumnTypesEnum.TEXT
      },
      {
        id: 'vehicleType',
        accessor: 'vehicleType',
        header: t('sCertificates:field.vehicleType'),
        type: ColumnTypesEnum.DICTIONARY,
        dictionaryKey: DomainDictionaryEnum.VEHICLE_TYPE,
        filter: 'AutocompleteDictionaryMultiple',
        columnFormatter: value => (
          <Value variant="dictionary" dictionaryKey={DomainDictionaryEnum.VEHICLE_TYPE} value={value} />
        ),
        columnXLSXFormatter: value => {
          return translate(DomainDictionaryEnum.VEHICLE_TYPE, value, t('emptyMark'));
        }
      },
      {
        id: 'applicationNumber',
        accessor: 'applicationNumber',
        header: t('sCertificates:field.applicationNumber'),
        type: ColumnTypesEnum.TEXT
      },
      {
        id: 'witd',
        accessor: 'witd',
        header: t('sCertificates:field.WITD'),
        type: ColumnTypesEnum.BOOLEAN,
        columnFormatter: value => <Value variant="boolean" value={value} />,
        columnXLSXFormatter: value => (value ? t('action.yes') : t('action.no'))
      },
      {
        id: 'euroClassKey',
        accessor: 'euroClassKey',
        header: t('sCertificates:field.euro'),
        type: ColumnTypesEnum.DICTIONARY,
        dictionaryKey: DomainDictionaryEnum.EURO_CLASS,
        filter: 'AutocompleteDictionaryMultiple',
        columnFormatter: value => (
          <Value variant="dictionary" dictionaryKey={DomainDictionaryEnum.EURO_CLASS} value={value} />
        ),
        columnXLSXFormatter: value => {
          return translate(DomainDictionaryEnum.EURO_CLASS, value, t('emptyMark'));
        }
      },
      {
        id: 'validFrom',
        accessor: 'validFrom',
        header: t('sCertificates:field.validFrom'),
        filter: 'DateRangePicker',
        type: ColumnTypesEnum.DATE
      },
      {
        id: 'printDate',
        accessor: 'printDate',
        header: t('sCertificates:field.printDate'),
        filter: 'DateRangePicker',
        type: ColumnTypesEnum.DATE
      },
      {
        id: 'compatibilityCertificateNumber',
        accessor: 'compatibilityCertificateNumber',
        header: t('sCertificates:field.compatibilityCertificateNumber'),
        type: ColumnTypesEnum.TEXT
      },
      {
        id: 'nextInspectionDate',
        accessor: 'nextInspectionDate',
        header: t('sCertificates:field.nextInspectionDate'),
        type: ColumnTypesEnum.TEXT
      }
    ],
    Object.values(PrintCertificatesColumnsEnum).filter(column => !Object.values(visibleColumns).includes(column))
  );

  const mappedFilterFields: TableFilterMapper<
    SCertificatesSnapshot,
    SCertificatesSearchFilter | SCertificatesSearchFilterClient
  > = {
    statusKey: (statuses: DictionaryFilterObject[]) => ({
      statusFilter: { values: statuses.map(({ value }) => value), exclude: false }
    }),
    subjectName: (subjectNameContains: string) => ({ subjectNameContains }),
    folderNumber: (folderNumber: string) => ({ folderNumberContains: folderNumber }),
    vehiclePlateNumber: (vehiclesRegistrationNumber: string) => ({
      vehiclePlateNumberContains: vehiclesRegistrationNumber
    }),
    number: (number: string) => ({ numberContains: number }),
    vehicleBrandKey: (vehicleBrands: DictionaryFilterObject) => {
      return { vehicleBrandKeyIn: [vehicleBrands.value] };
    },
    vehicleModelKey: (vehicleModels: DictionaryFilterObject) => {
      return { vehicleModelKeyIn: [vehicleModels.value] };
    },
    vehicleType: (vehicleTypes: DictionaryFilterObject[]) => ({
      vehicleTypeIn: vehicleTypes.map(({ value }) => value)
    }),
    applicationNumber: (applicationNumberContains: string) => ({ applicationNumberContains }),
    witd: (witd: DictionaryFilterObject<boolean>) => ({ includeWitd: witd.value }),
    vehicleVin: (vehicleVIN: string) => ({ vinContains: vehicleVIN }),
    euroClassKey: (euroClassKeys: DictionaryFilterObject[]) => ({
      euroClassKeyIn: euroClassKeys.map(({ value }) => value)
    })
  };

  const mappedSortFields: TableSortMapper<SCertificatesSnapshot> = {
    vehicleVin: 'vehicle.vin',
    statusKey: 'statusKey',
    subjectName: 'application.subject.name',
    folderNumber: 'application.folder.number',
    applicationNumber: 'application.number',
    vehicleBrandKey: 'vehicle.brandKey',
    vehicleModelKey: 'vehicle.modelKey',
    vehicleType: 'vehicle.typeKey',
    vehiclePlateNumber: 'vehicle.plateNumber',
    witd: 'witd',
    euroClassKey: 'vehicle.euroClassKey'
  };

  return { columns, mappedFilterFields, mappedSortFields };
}
