import { Context, useContext, useMemo } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { AutocompleteRenderGetTagProps, AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';

import {
  AutocompleteSelect,
  ComponentErrorBoundary,
  FormErrorType,
  FormV2Context,
  FormV2ContextState,
  getInputMode,
  InputMode,
  SelectOption,
  Value
} from '@libs/common/v2';
import { getValue } from '@libs/common/v2/utils';

import { UIElementNameEnum, useElementVisibility } from '@libs/permission';

import { useSetInitialSelectFieldValue } from '../../hooks';
import {
  FieldTypeEnum,
  SelectFieldValidation,
  SelectMultipleFieldValidation,
  useFieldValidationHandler
} from '../../validation';

export interface ICommonAutocompleteProps {
  name: FieldPath<FieldValues>;
  options: SelectOption[];
  label?: string;
  placeholder?: string;
  formContext?: Context<FormV2ContextState>;
  TextFieldProps?: Partial<MuiTextFieldProps>;
  inputMode?: InputMode;
  className?: string;
  valueClassName?: string;
  onInputChange?: (_event: React.SyntheticEvent, value: any) => void;
  renderOption?: (props: Record<string, any>, option: any, state: AutocompleteRenderOptionState) => React.ReactNode;
  customFilterOptions?: (options: SelectOption[]) => SelectOption[];
  isFreeSolo?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isMultiple?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  getValueFormat?: (value: any) => any;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  validationSingleSelect?: SelectFieldValidation;
  validationMultipleSelect?: SelectMultipleFieldValidation;
  onChangeCustom?: (options: SelectOption[] | SelectOption) => void;
  actionKey?: UIElementNameEnum;
  tooltip?: string;
  renderTags?: (value: any, getTagProps: AutocompleteRenderGetTagProps) => React.ReactNode;
  getOptionSelected?: (option: SelectOption, value?: any) => boolean;
  stringValue?: boolean;
  customErrorMessage?: string;
  isFetchedDynamically?: boolean;
  isFilterSelectedOptions?: boolean;
  hasErrorTooltip?: boolean;
  inputValue?: string;
}

function AutocompleteSelectField({
  name,
  label,
  placeholder,
  options,
  formContext = FormV2Context,
  className,
  valueClassName,
  TextFieldProps,
  onInputChange,
  renderOption,
  isFreeSolo,
  isRequired,
  isDisabled,
  inputMode,
  isMultiple,
  isLoading,
  isClearable = !isRequired,
  getValueFormat,
  validationMultipleSelect,
  validationSingleSelect,
  onClick,
  actionKey,
  onChangeCustom,
  tooltip,
  renderTags,
  getOptionSelected,
  stringValue,
  customErrorMessage,
  isFetchedDynamically,
  isFilterSelectedOptions,
  customFilterOptions,
  hasErrorTooltip,
  inputValue
}: ICommonAutocompleteProps) {
  const { control, formMode, loading, isSubmitting } = useContext<FormV2ContextState>(formContext);
  const mode = getInputMode(formMode, inputMode);
  const { checkIsElementVisible } = useElementVisibility();

  const isElementVisible = useMemo(() => {
    return actionKey ? checkIsElementVisible(actionKey) : true;
  }, [checkIsElementVisible, actionKey]);

  useSetInitialSelectFieldValue(name, options, stringValue);
  useFieldValidationHandler(
    isMultiple
      ? {
          fieldName: name,
          validation: validationMultipleSelect,
          isMultiple: true,
          fieldType: FieldTypeEnum.AUTOCOMPLETE
        }
      : {
          fieldName: name,
          validation: validationSingleSelect,
          isMultiple: false,
          fieldType: FieldTypeEnum.AUTOCOMPLETE
        }
  );

  if (isElementVisible) {
    return (
      <ComponentErrorBoundary componentName={label || placeholder || name || 'AutocompleteSelectField'}>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) =>
            mode === InputMode.FORM ? (
              <AutocompleteSelect
                className={className}
                name={name}
                label={label}
                placeholder={placeholder}
                options={options}
                value={value || (isMultiple ? [] : null)}
                onChange={(_event, selectValue: SelectOption) => {
                  onChangeCustom?.(selectValue);
                  if (selectValue?.inputValue) {
                    const { inputValue } = selectValue;
                    onChange({ name: inputValue, value, selectValue: selectValue.value, id: selectValue.id });
                  } else {
                    onChange(selectValue);
                  }
                }}
                onClick={onClick}
                onBlur={onBlur}
                TextFieldProps={TextFieldProps}
                onInputChange={onInputChange}
                renderOption={renderOption}
                isError={Boolean(customErrorMessage) || (!!error && error?.type !== FormErrorType.WARNING)}
                isWarning={error?.type === FormErrorType.WARNING}
                helperText={customErrorMessage || error?.message}
                isFreeSolo={isFreeSolo}
                isMultiple={isMultiple}
                isRequired={
                  isRequired || Boolean(validationMultipleSelect?.required || validationSingleSelect?.required)
                }
                isDisabled={isDisabled || isSubmitting}
                isLoading={isLoading}
                isClearable={isClearable}
                tooltip={tooltip}
                renderTags={renderTags}
                getOptionSelected={getOptionSelected}
                stringValue={stringValue}
                isFetchedDynamically={isFetchedDynamically}
                customFilterOptions={customFilterOptions}
                isFilterSelectedOptions={isFilterSelectedOptions}
                hasErrorTooltip={hasErrorTooltip}
                inputValue={inputValue}
              />
            ) : (
              <Value
                label={label}
                isLoading={loading || isLoading}
                className={valueClassName}
                isError={!!error && error?.type !== FormErrorType.WARNING}
                isWarning={error?.type === FormErrorType.WARNING}
                helperText={error?.message}
              >
                {!loading &&
                  getValue(
                    Array.isArray(value)
                      ? (value as SelectOption<string>[])
                          .map(
                            item =>
                              (Boolean(getValueFormat) && getValue?.(getValueFormat?.(item))) ||
                              item?.name ||
                              item?.value ||
                              item
                          )
                          .join(', ')
                      : (Boolean(getValueFormat) && getValue?.(getValueFormat?.(value))) ||
                          value?.name ||
                          value?.value ||
                          value
                  )}
              </Value>
            )
          }
        />
      </ComponentErrorBoundary>
    );
  }
}

export default AutocompleteSelectField;
