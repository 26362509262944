import { useEffect } from 'react';
import { FieldValues, UseFormReset, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { typedNameV2, useFields } from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { usePermissionDetailsQuery } from '../api';
import { PermissionFields } from '../model';
import { parsePermissionFields } from '../parsers';

interface IProps {
  permissionId?: string;
  hiddenFieldIds?: Array<PermissionFields>;
  reset: UseFormReset<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

function usePermissionFields({ permissionId, reset, watch, setValue }: IProps) {
  const { data, isLoading } = usePermissionDetailsQuery(permissionId, { enabled: Boolean(permissionId) });

  useEffect(() => {
    if (reset) {
      if (data) {
        reset(parsePermissionFields(data));
      } else {
        reset({});
      }
    }
  }, [data, reset]);

  const isValidIndefinitely = watch(typedNameV2<PermissionFields>('validIndefinitely')) as boolean;
  const dateOfIssue = watch(typedNameV2<PermissionFields>('dateOfIssue')) as Date;

  useEffect(() => {
    if (isValidIndefinitely || !dateOfIssue) {
      setValue(typedNameV2<PermissionFields>('validTo'), null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidIndefinitely, dateOfIssue]);

  const { renderField } = useFields<PermissionFields>(
    [
      // Nr zezwolenia
      {
        id: 'permissionNumber',
        type: 'TEXT',
        yupValidation: {
          required: []
        },
        isDisabled: isLoading
      },
      // Nazwa  zezwolenia wydane przez organ zagraniczny
      {
        id: 'foreignPermissionName',
        type: 'TEXT',
        yupValidation: {
          required: []
        },
        isDisabled: isLoading
      },
      {
        id: 'enterpreneur',
        type: 'TEXT',
        inputMode: 'VIEW'
      },
      {
        id: 'partnershipEnterpreneur',
        type: 'TEXT',
        inputMode: 'VIEW'
      },
      {
        id: 'issuingCountryKey',
        type: 'DICTIONARY',
        dictionaryName: DomainDictionaryEnum.COUNTRY_CODE,
        yupValidationSingleSelect: {
          required: []
        },
        isDisabled: isLoading
      },
      {
        id: 'statusKey',
        type: 'DICTIONARY',
        dictionaryName: DomainDictionaryEnum.PERMISSION_STATUS,
        inputMode: 'VIEW'
      },
      // Data wydania
      {
        id: 'dateOfIssue',
        type: 'DATETIME',
        validation: {
          required: true,
          max: new Date()
        },
        yupValidation: {
          required: []
        },
        isDisabled: isLoading
      },
      // Ważne bezterminowo
      {
        id: 'validIndefinitely',
        type: 'BOOLEAN',
        isDisabled: isLoading
      },
      // Data ważności do
      {
        id: 'validTo',
        type: 'DATETIME',
        validation: {
          min: dateOfIssue
        },
        yupValidation: !isValidIndefinitely && {
          required: []
        },
        isDisabled: isLoading || isValidIndefinitely || !dateOfIssue
      },
      // Organ wydający
      {
        id: 'issuingAuthorityKey',
        type: 'DICTIONARY',
        dictionaryName: DomainDictionaryEnum.AUTHORITY,
        yupValidationSingleSelect: {
          required: []
        },
        isDisabled: isLoading
      },
      // Uprawnienie spółki
      {
        id: 'applyToPartnership',
        type: 'BOOLEAN',
        isDisabled: isLoading
      },
      {
        id: 'created',
        type: 'DATETIME',
        isDisabled: isLoading
      },
      {
        id: 'author',
        type: 'TEXT',
        isDisabled: isLoading
      },
      {
        id: 'modified',
        type: 'DATETIME',
        isDisabled: isLoading
      },
      {
        id: 'modifier',
        type: 'TEXT',
        isDisabled: isLoading
      }
    ],
    { translationPath: 'permission:field' }
  );

  return { renderField, isDataLoading: isLoading };
}

export default usePermissionFields;
