import { useTableLayoutProps } from '@libs/common/v2';

import { useResourceObjectsQuery } from '../../api';
import { useResourceObjectsColumns } from '../columns';

function useResourceObjectsTable(initialParams = {}) {
  const { columns, mappedFilterFields, mappedSortFields } = useResourceObjectsColumns();

  return useTableLayoutProps({
    tableHookQuery: useResourceObjectsQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    },
    tableHookQueryInitialParams: initialParams
  });
}

export default useResourceObjectsTable;
