import { QueryConfig, useQuery } from 'react-query';
import { SingleUsePermissionSubmissionDetails } from '@ibtm/domain';

import { API } from '@libs/domain/api';
import { SocialCommissionQueryKeysEnum } from '@libs/domain/social-commission';

import { endpointsConfig } from '../endpoints-config';

export const getFormValues = async (_, singleUsePermissionId: string) => {
  const { data } = await API.civicBoard.getSingleUsePermissionSubmission(singleUsePermissionId, {
    ...endpointsConfig.getSingleUseSubmissionItemDetailsPage
  });
  return data;
};

function useDisposablePermitFormQuery(
  singleUsePermissionId: string,
  queryConfig: QueryConfig<SingleUsePermissionSubmissionDetails, unknown> = {}
) {
  return useQuery(
    [SocialCommissionQueryKeysEnum.PERMANENT_PERMISSION_SUBMISSION_DETAILS, singleUsePermissionId],
    getFormValues,
    queryConfig
  );
}
export default useDisposablePermitFormQuery;
