export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  updateFinancialSecurity: { translationKey: 'financialSecurity:api.updateFinancialSecurity' },
  addFinancialSecuritiesToApplication: { translationKey: 'financialSecurity:api.addFinancialSecuritiesToApplication' },
  createFinancialSecurity: { translationKey: 'financialSecurity:api.createFinancialSecurity' },
  deleteFinancialSecurity: { translationKey: 'financialSecurity:api.deleteFinancialSecurity' },
  expireFinancialSecurity: { translationKey: 'financialSecurity:api.expireFinancialSecurity' },
  restoreFinancialSecurity: { translationKey: 'financialSecurity:api.restoreFinancialSecurity' },
  getFinancialSecuritiesSnapshotPage: { translationKey: 'financialSecurity:api.getFinancialSecuritiesSnapshotPage' },
  getFinancialSecurityApplicationSummary: {
    translationKey: 'financialSecurity:api.getFinancialSecurityApplicationSummary'
  },
  getFinancialSecurity: { translationKey: 'financialSecurity:api.getFinancialSecurity' },
  getFolderForeignPermissionVehicles: { translationKey: 'folder:api.getFolderForeignPermissionVehicles' }
};
