import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { QueryOrder } from '@stack/report';
import * as Yup from 'yup';

import { AutocompleteSelectField, Dialog, FormV2Context, GridLayout, SelectOption } from '@libs/common/v2';

import { QueryOrderSettings } from '@libs/report/model';

const InitialFormValues: QueryOrderSettings = {
  direction: '',
  index: null
};

interface IProps {
  closeDialog: () => void;
  onConfirm: (data: QueryOrderSettings) => void;
  isSubmitting?: boolean;
  initialEditFormValues?: QueryOrder;
  queryDefinitionOrders: QueryOrder[];
}

function SortingSettingsDialogContent({
  closeDialog,
  onConfirm,
  isSubmitting,
  initialEditFormValues,
  queryDefinitionOrders
}: IProps) {
  const [t] = useTranslation();
  const validationSchema = Yup.object().shape(
    {
      direction: Yup.string()
        .nullable()
        .when('index', {
          is: index => !!index,
          then: Yup.string().required().nullable()
        }),
      index: Yup.number()
        .nullable()
        .when('direction', {
          is: direction => !!direction,
          then: Yup.number().required().nullable()
        })
    },
    [['direction', 'index']]
  );

  const isCreateMode = !!initialEditFormValues;
  const directionOptions: SelectOption[] = [
    { name: t('reports:reportTypes.columnSettings.directionOptions.desc'), value: 'desc' },
    { name: t('reports:reportTypes.columnSettings.directionOptions.asc'), value: 'asc' }
  ];
  const getNumbersArray = (length: number) => Array.from(Array(length), (_, i) => i + 1);
  const numbersArray = isCreateMode
    ? getNumbersArray(queryDefinitionOrders.length)
    : getNumbersArray(queryDefinitionOrders.length + 1);
  const indexOptions: SelectOption[] = numbersArray.map(number => ({
    name: number.toString(),
    value: number
  }));

  const form = useForm<Record<string, any>>({
    defaultValues: initialEditFormValues || InitialFormValues,
    resolver: yupResolver(validationSchema),
    mode: 'onBlur'
  });
  const isRequired = form.watch('direction') || form.watch('index');

  const handleConfirm = useCallback(
    (values: QueryOrderSettings) => {
      onConfirm(values);
      closeDialog();
    },
    [onConfirm, closeDialog]
  );

  return (
    <FormV2Context.Provider value={form}>
      <form onSubmit={form.handleSubmit(handleConfirm)}>
        <Dialog
          title={t('reports:reportTypes.columnSettings.advancedParameters')}
          dialogSize="medium"
          onCancel={closeDialog}
          onConfirm={form.handleSubmit(handleConfirm)}
          confirmText={t('action.save')}
          cancelText={t('action.cancel')}
          isConfirmLoading={isSubmitting}
          isOpen
        >
          <GridLayout itemProps={{ xs: 12 }}>
            <AutocompleteSelectField
              name="direction"
              label={t('reports:reportTypes.columnSettings.fields.direction')}
              stringValue
              options={directionOptions}
              isClearable
              isRequired={isRequired}
            />
            <AutocompleteSelectField
              name="index"
              label={t('reports:reportTypes.columnSettings.fields.index')}
              stringValue
              options={indexOptions}
              isClearable
              isRequired={isRequired}
            />
          </GridLayout>
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
}

export default SortingSettingsDialogContent;
