import { QueryConfig, useQuery } from 'react-query';
import { ResourceObjectAvailablePoolRequest } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { IResourceObjectAssociationPoolsAvailabilityClient } from '../../../model';
import { resourceObjectPoolsAvailabilityParser } from '../../../utils';
import { endpointsConfig } from '../../endpoints-config';
import { ResourceQueryKeysEnum } from '..';

const getResourceObjectPoolsAvailability = async (params: ResourceObjectAvailablePoolRequest) => {
  const { data } = await API.resourceObjectPools.getResourceObjectLastAvailablePool(params, {
    ...endpointsConfig.getResourceObjectLastAvailablePool
  });

  return resourceObjectPoolsAvailabilityParser(data);
};

export default function useResourceObjectPoolsAvailabilityQuery(
  params: ResourceObjectAvailablePoolRequest,
  queryConfig: QueryConfig<any, unknown> = {}
) {
  return useQuery<IResourceObjectAssociationPoolsAvailabilityClient>(
    [ResourceQueryKeysEnum.RESOURCE_OBJECT_POOLS_AVAILABILITY, params],
    () => getResourceObjectPoolsAvailability(params),
    queryConfig
  );
}
