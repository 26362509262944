import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ResourceObjectRollbackReturnRequest } from '@ibtm/domain';
import { Grid } from '@mui/material';
import * as Yup from 'yup';

import { Dialog, FormV2Context, InputMode, TextInputField, typedNameV2 } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { ResourceQueryKeysEnum, useRollbackReturnsMutation } from '../../../api';
import { IResourceObjectsClient, RollbackReturnsRequestData } from '../../../model';

type FormValuesType = Omit<RollbackReturnsRequestData, 'formId'>;

interface IProps {
  initialData: IResourceObjectsClient;
  closeDialog: () => void;
}

function RollbackReturnsForm({ initialData, closeDialog }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();

  const { mutate: rollbackReturn, isLoading } = useRollbackReturnsMutation();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(
      Yup.object({
        reason: Yup.string().required()
      })
    ),
    defaultValues: {
      reason: ''
    }
  });

  const onSubmit = (formData: FormValuesType) => {
    const requestData: ResourceObjectRollbackReturnRequest = {
      resourceObjectId: initialData.id,
      reason: formData.reason
    };

    rollbackReturn(requestData, {
      onSuccess: () => {
        showSnackbar('success', t('resource:messages.rollbackReturnsSuccess'));

        queryCache.invalidateQueries(ResourceQueryKeysEnum.RESOURCES_SEARCH);
        closeDialog();
      }
    });
  };

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('resource:dialog.undoTheRefundTitle')}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={handleSubmit(data => onSubmit(data as FormValuesType))}
      onCancel={closeDialog}
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <form>
          <Grid container>
            <Grid item xs={12}>
              <TextInputField
                inputMode={InputMode.FORM}
                name={typedNameV2<RollbackReturnsRequestData>('reason')}
                label={t('resource:fields.reason')}
                lines={3}
              />
            </Grid>
          </Grid>
        </form>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default RollbackReturnsForm;
