import React from 'react';
import { useTranslation } from 'react-i18next';

import TableIconButton from './TableIconButton';

interface IProps {
  onClick: () => void;
  tooltipTitle?: string;
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
}

function TableButtonUpload({ isDisabled, isLoading, tooltipTitle, className, onClick }: IProps) {
  const [t] = useTranslation();

  return (
    <TableIconButton
      tooltipTitle={tooltipTitle || t('action.upload')}
      className={className}
      icon="UploadIcon"
      onClick={onClick}
      isDisabled={isDisabled}
      isButtonLoading={isLoading}
    />
  );
}

export default React.memo(TableButtonUpload);
