import React, { useCallback, useRef, useState } from 'react';
import { Row } from 'react-table';

import { ITableAdapter, ITableContext } from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';

import RenderTableActionButton from './RenderTableAction';
import { TAction } from './table-action.model';

function useTableRowActions<P extends Record<string, any>, D extends Record<string, any> = P>(
  actions: TAction<P, D>[],
  // zależności uaktualniające akcje w wierszu tabeli
  ...dependencyItems: Array<any>
) {
  const { checkIsElementVisible } = useElementVisibility();
  const getAccessibleActions = useCallback(() => {
    return actions.filter(action => {
      const id = action?.id;
      return id && checkIsElementVisible(id);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions]);

  const [accessibleActions, setAccessibleActions] = useState<TAction<P, D>[]>(getAccessibleActions());

  const prevDependencyItems = useRef(dependencyItems.toString());

  const rerenderOnDependencyChange = () => {
    const newDependencyItems = dependencyItems.toString();
    if (prevDependencyItems.current !== newDependencyItems) {
      prevDependencyItems.current = newDependencyItems;
      setAccessibleActions(getAccessibleActions());
    }
  };

  rerenderOnDependencyChange();

  const renderTableActions = (enabledActions: TAction<P, D>['id'][]) => {
    return function TableAction(
      row: Row<P>,
      table: { tableInstance: ITableAdapter<P, D>; tableContext: ITableContext<P, D> }
    ) {
      const actionsToRender = accessibleActions.filter(action => {
        const { tableInstance, tableContext } = table;
        const shouldRender = () => {
          return !(typeof action.isHidden === 'function'
            ? action.isHidden({ row, tableInstance, tableContext })
            : action.isHidden);
        };

        return enabledActions?.includes(action.id) && shouldRender();
      });

      const mainActionIndex = actionsToRender.indexOf(actionsToRender.find(item => Boolean(item.icon)));
      return (
        <>
          {actionsToRender.map((action, index) => {
            const renderAction = { ...action };
            if (mainActionIndex !== index && actionsToRender.length > 2) {
              delete renderAction.icon;
            }
            if (action.customButton) {
              return <React.Fragment key={renderAction.id}>{renderAction.customButton(row)}</React.Fragment>;
            }
            return <RenderTableActionButton key={action.id} action={renderAction} row={row} table={table} />;
          })}
        </>
      );
    };
  };

  return {
    renderTableActions
  };
}

export default useTableRowActions;
