import { LinkExploitationBasesRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export function linkExploitationBasesClient({
  applicationId,
  linkExploitationBasesRequest
}: {
  applicationId: string;
  linkExploitationBasesRequest: LinkExploitationBasesRequest;
}) {
  return API.client.application.linkExploitationBases(applicationId, '', '', linkExploitationBasesRequest, {
    ...endpointsConfig.linkExploitationBases
  });
}

export function linkExploitationBases({
  applicationId,
  linkExploitationBasesRequest
}: {
  applicationId: string;
  linkExploitationBasesRequest: LinkExploitationBasesRequest;
}) {
  return API.application.linkExploitationBases(applicationId, linkExploitationBasesRequest, {
    ...endpointsConfig.linkExploitationBases
  });
}

function useCopyExploitationBasesMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(linkExploitationBasesClient, linkExploitationBases));
}

export default useCopyExploitationBasesMutation;
