import { useCallback } from 'react';
import { TransitStopDetails } from '@ibtm/domain';

import { FormMode, useDialog } from '@libs/common/v2';

import { StopDetails } from '../components';
import { ScheduleApplicationGroupsEnum } from '../model';

function useStopDetailsDialog({
  transitScheduleId,
  applicationId,
  applicationTypeKey
}: {
  transitScheduleId: string;
  applicationId: string;
  applicationTypeKey: ScheduleApplicationGroupsEnum;
}) {
  const { openDialog } = useDialog();

  const openDetailsDialog = useCallback(
    ({ formMode, initialData }: { formMode?: FormMode; initialData?: Partial<TransitStopDetails> }) => {
      openDialog(({ closeDialog }) => (
        <StopDetails
          closeDialog={closeDialog}
          transitScheduleId={transitScheduleId}
          formMode={formMode}
          initialData={initialData}
          applicationId={applicationId}
          applicationTypeKey={applicationTypeKey}
        />
      ));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog]
  );

  return {
    openDetailsDialog
  };
}

export default useStopDetailsDialog;
