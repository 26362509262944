import { QueryConfig, usePaginatedQuery } from 'react-query';
import { VehiclesSearchFilter, VehiclesSnapshotPage } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { VehicleQueryKeysEnum } from '@libs/domain/vehicle';

import { endpointsConfig } from '../endpoints-config';

export const getVehicles = async (params: VehiclesSearchFilter) => {
  const { data } = await API.vehicle.getVehiclesSearchFilter(params, {
    ...endpointsConfig.copyFolderVehiclesToAssignedApplication
  });
  return data;
};

export const getVehiclesClient = async (params: VehiclesSearchFilter) => {
  const { data } = await API.client.vehicle.getVehiclesSearchFilter('', '', params, {
    ...endpointsConfig.getVehiclesSearchFilter
  });
  return data;
};

export default function useVehiclesQuery(
  params: VehiclesSearchFilter,
  queryConfig: QueryConfig<VehiclesSnapshotPage, unknown>
) {
  const { getQuery } = useGetApiQueryByPerspective();

  return usePaginatedQuery(
    [VehicleQueryKeysEnum.VEHICLE_LIST, params],
    () => getQuery(getVehiclesClient, getVehicles)(params),
    queryConfig
  );
}
