import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { partialTranslate } from '@libs/common';
import { IconButton, SectionWithFields, Value } from '@libs/common/v2';
import { getNumberValue } from '@libs/common/v2/utils';

import { useFinancialSecuritySummaryQuery } from '../../../api';
import { useFinancialSecuritySummaryInfoAction } from '../../../hooks/action';
import { FinancialSecuritySummaryFields } from '../../../model';

const translate = partialTranslate('financialSecurity:section.summary');

interface Props {
  folderId: string;
  enabledFields: Array<FinancialSecuritySummaryFields>;
}

function FinancialSecuritySummary({ folderId, enabledFields }: Props) {
  const [t] = useTranslation();

  const { data, isLoading } = useFinancialSecuritySummaryQuery({ folderId });
  const { handleFinancialSecuritySummaryInfo } = useFinancialSecuritySummaryInfoAction();

  const showField = useCallback(
    (field: FinancialSecuritySummaryFields) => enabledFields.includes(field),
    [enabledFields]
  );

  return (
    <SectionWithFields
      headerActions={
        <IconButton
          icon="HelpIcon"
          tooltipTitle={t('action.help')}
          onClick={handleFinancialSecuritySummaryInfo}
          isBackgroundTransparent
        />
      }
      title={translate('title')}
      isLoading={isLoading}
      isCollapsable
      itemProps={{ sm: 4, xs: 12 }}
      fields={[
        <Value label={translate('field.presentedCollateral')} key="presentedCollateral">
          {showField('presentedCollateralEURO')
            ? `${getNumberValue(data?.presentAmountEur, 2)} ${t('currency.EURO')}`
            : null}
          <br />
          {showField('presentedCollateralPLN')
            ? `${getNumberValue(data?.presentAmountPln, 2)} ${t('currency.PLN')}`
            : null}
        </Value>,
        <Value label={translate('field.requiredCollateral')} key="requiredCollateral">
          {showField('requiredCollateralEURO')
            ? `${getNumberValue(data?.requireAmountEur, 2)} ${t('currency.EURO')}`
            : null}
          <br />
          {showField('requiredCollateralPLN')
            ? `${getNumberValue(data?.requireAmountPln, 2)} ${t('currency.PLN')}`
            : null}
        </Value>,
        <Value label={translate('field.missingOrRedundantCollateral')} key="missingOrRedundantCollateral">
          {showField('missingOrRedundantCollateralEURO')
            ? `${getNumberValue(data?.differenceAmountEur, 2)} ${t('currency.EURO')}`
            : null}
          <br />
          {showField('missingOrRedundantCollateralPLN')
            ? `${getNumberValue(data?.differenceAmountPln, 2)} ${t('currency.PLN')}`
            : null}
        </Value>
      ]}
    />
  );
}

export default FinancialSecuritySummary;
