import { Context, SyntheticEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { Menu } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

import {
  ComponentErrorBoundary,
  FormV2Context,
  InputMode,
  MenuItem,
  TextInput,
  Typography,
  Value
} from '@libs/common/v2';
import { FormV2ContextState, getInputMode } from '@libs/common/v2/form';
import { Theme } from '@libs/common/v2/theme';
import { calc, important } from '@libs/common/v2/utils';

import { DictionaryEntryNameEnum, DictionaryEntryValue, useDictionaryEntryValues } from '@libs/dictionary';

interface IProps {
  name: FieldPath<FieldValues>;
  label?: string;
  dictionaryName?: DictionaryEntryNameEnum;
  formContext?: Context<FormV2ContextState>;
  lines?: number;
  disabled?: boolean;
  required?: boolean;
  isHintAutocomleteFieldAboveInput?: boolean;
  customDictionaryEntriesList?: DictionaryEntryValue[];
  onOptionSelected?: () => void;
  hasErrorTooltip?: boolean;
  inputMode?: InputMode;
}

/**
 * Pole tekstowe z podpowiedziami autouzupełniającymi ze słownika
 */
function HintsAutocompleteField({
  name,
  label,
  dictionaryName,
  formContext = FormV2Context,
  lines = 1,
  disabled,
  required,
  isHintAutocomleteFieldAboveInput,
  customDictionaryEntriesList,
  onOptionSelected,
  hasErrorTooltip,
  inputMode
}: IProps) {
  const inputRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const inputWidth = inputRef?.current?.offsetWidth;
  const classes = useStyles({ width: inputWidth });

  const { control, getValues, setValue, formMode } = useContext<FormV2ContextState>(formContext);
  const mode = getInputMode(formMode, inputMode);
  const dictionaryEntries = useDictionaryEntryValues(dictionaryName);

  const handleClick = (event: SyntheticEvent) => {
    event.preventDefault();

    setOpen(true);
    setAnchorEl(event.currentTarget as HTMLElement);
  };

  const handleOptionSelect = (selected: string, e) => {
    e.preventDefault();
    const existingValue = _.get(getValues(), name, '');
    setValue(name, existingValue ? `${existingValue?.value || existingValue} ${selected}` : selected);
    onOptionSelected?.();
  };

  const outsideClick = e => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setOpen(false);
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', outsideClick);

    return () => {
      document.removeEventListener('click', outsideClick);
    };
  });

  const hasDictionaryValues = useMemo(
    () =>
      (!_.isNil(dictionaryEntries) && !_.isEmpty(dictionaryEntries)) ||
      (!_.isNil(customDictionaryEntriesList) && !_.isEmpty(customDictionaryEntriesList)),
    [dictionaryEntries, customDictionaryEntriesList]
  );

  return (
    <ComponentErrorBoundary componentName={label || name || 'HintsAutocompleteField'}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => {
          return mode === InputMode.VIEW ? (
            <Value label={label} value={value?.value || value} />
          ) : (
            <>
              <TextInput
                onBlur={onBlur}
                label={label}
                value={value?.value || value}
                onClick={handleClick}
                inputRef={inputRef}
                onChange={onChange}
                InputLabelProps={{
                  shrink: !!value || open
                }}
                rows={lines}
                multiline={lines > 1}
                disabled={disabled}
                required={required}
                helperText={error?.message}
                error={!!error}
                hasErrorTooltip={hasErrorTooltip}
                fullWidth
              />
              {hasDictionaryValues && (
                <Menu
                  className={classes.menu}
                  anchorEl={anchorEl}
                  open={open}
                  anchorOrigin={{
                    vertical: isHintAutocomleteFieldAboveInput ? 'top' : 'bottom',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: isHintAutocomleteFieldAboveInput ? 'bottom' : 'top',
                    horizontal: 'center'
                  }}
                >
                  {(customDictionaryEntriesList || dictionaryEntries)?.map(({ name: dictionaryEntry }) => (
                    <MenuItem onClick={e => handleOptionSelect(dictionaryEntry, e)} key={dictionaryEntry}>
                      <Typography themeVariant="textMd.normal">{dictionaryEntry}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </>
          );
        }}
      />
    </ComponentErrorBoundary>
  );
}

const useStyles = makeStyles<Theme, { width?: number }>(theme => ({
  menu: {
    height: important('300px'),

    '& .MuiMenuItem-root': {
      width: ({ width }) => {
        return calc(`${width}px + 2 * ${theme.spacing(1.75)}`);
      },
      whiteSpace: important('normal')
    }
  }
}));

export default HintsAutocompleteField;
