import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { smallIconSize } from '@libs/common/config';
import { IconButton } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';

import { important } from '../../../utils';

interface IProps {
  onOpenClick: () => void;
  onClearClick?: () => void;
  showClearButton?: boolean;
  isDisabled?: boolean;
  smallIcon?: boolean;
}

function InputButtons({ showClearButton, onClearClick, onOpenClick, isDisabled, smallIcon }: IProps) {
  const { container, clearButton, openButton } = useStyles({ smallIcon });
  const [t] = useTranslation();

  return (
    <div className={clsx('flex items-center', container)}>
      {showClearButton ? (
        <IconButton
          width={smallIconSize}
          height={smallIconSize}
          icon="CrossIcon"
          aria-label={t('action.clear')}
          onClick={onClearClick}
          className={clearButton}
          isBackgroundTransparent
        />
      ) : null}
      <IconButton
        aria-label={t('action.openDatePicker')}
        onClick={onOpenClick}
        icon="CalendarIcon"
        width={smallIcon ? smallIconSize : 18}
        height={smallIcon ? smallIconSize : 18}
        isDisabled={isDisabled}
        className={openButton}
        isBackgroundTransparent
      />
    </div>
  );
}

const useStyles = makeStyles<Theme, { smallIcon?: boolean }>(() => ({
  container: {
    marginRight: ({ smallIcon }) => (smallIcon ? -12 : -4)
  },
  clearButton: {
    opacity: 0.8,
    padding: important('4px'),
    marginRight: 5,
    '&:hover': {
      opacity: 1
    }
  },
  openButton: {
    padding: important('4px')
  }
}));

export default InputButtons;
