import { FolderLockDetails } from '@ibtm/domain';

import { convertCalendarDate } from '@libs/common/v2/utils';

import { FolderLockListItemClient } from '@libs/domain/folder';

export const parseLockListResults = (data: FolderLockDetails[]): FolderLockListItemClient[] =>
  data.map(obj => {
    return {
      ...obj,
      unlocked: convertCalendarDate(obj.unlocked),
      validFrom: convertCalendarDate(obj.validFrom),
      validTo: convertCalendarDate(obj.validTo)
    };
  });
