import { PassAnotherDepartmentApplicationRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

export function passApplicationToOtherDepartment({
  applicationId,
  formData
}: {
  applicationId: string;
  formData: PassAnotherDepartmentApplicationRequest;
}) {
  return API.application.passApplication(applicationId, formData, {
    ...endpointsConfig.passApplication
  });
}

function usePassApplicationToOtherDepartmentMutation() {
  return useMutation(passApplicationToOtherDepartment);
}

export default usePassApplicationToOtherDepartmentMutation;
