import { TransportManagerDetails as TransportManagerDetailsClientDomain } from '@ibtm/client-domain';
import { TransportManagerDetails, TransportManagerSnapshot } from '@ibtm/domain';
import _ from 'lodash';

import { convertCalendarDate } from '@libs/common/v2/utils';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { TransportManagerDetailsClient, TransportManagerListItemClient } from '../model';

// Do poprawy po realizacji GITDDEV-2066
export const parseTransportManagerListResults = (data: TransportManagerSnapshot[]): TransportManagerListItemClient[] =>
  data.map(obj => ({
    ...obj,
    certificate: {
      ...obj.certificate,
      validFrom: convertCalendarDate(obj.certificate?.validFrom),
      validTo: convertCalendarDate(obj.certificate?.validTo)
    },
    managementPeriodFrom: convertCalendarDate(obj.managementPeriodFrom),
    managementPeriodTo: convertCalendarDate(obj.managementPeriodTo)
  }));

export const parseTransportManagerDetails = (
  data: TransportManagerDetails & TransportManagerDetailsClientDomain
): TransportManagerDetailsClient => ({
  ..._.pick<TransportManagerDetails>(data, [
    'id',
    'convicted',
    'art7c',
    'created',
    'author',
    'modified',
    'modifier',
    'version',
    'expirationDate'
  ]),
  applicationId: data?.application?.id,
  linkedTransportManager: data?.linkedTransportManager,
  cancelledTransportManager: data?.cancelledTransportManager,
  personalData: {
    name: data.name,
    surname: data.surname,
    birthDate: convertCalendarDate(data.birthDate),
    birthPlace: data.birthPlace,
    version: data.version
  },
  address: {
    postCode: data?.address?.postCode,
    postCity: data?.address?.postCity,
    city: data?.address?.city,
    street: data?.address?.street,
    propertyNumber: data?.address?.propertyNumber,
    apartmentNumber: data?.address?.apartmentNumber,
    voivodeship: data?.address?.voivodeshipKey,
    countryCode: data.address.countryCodeKey,
    version: data.version,
    addressTypeKey: data.addressTypeKey
  },
  certificate: {
    ...data.certificate,
    validFrom: convertCalendarDate(data.certificate?.validFrom),
    validTo: convertCalendarDate(data.certificate?.validTo),
    dateOfIssue: convertCalendarDate(data.certificate?.dateOfIssue)
  },
  managementPeriodFrom: convertCalendarDate(data.managementPeriodFrom),
  managementPeriodTo: convertCalendarDate(data.managementPeriodTo),
  expirationDate: convertCalendarDate(data.expirationDate),
  reputationInquires: data.reputationRequests?.map(details => {
    return {
      ...details,
      inquiryDate: convertCalendarDate(details.inquiryDate)
    };
  }),
  statusKey: data?.statusKey,
  typeKey: data?.typeKey,
  numberOtherSubjectVehiclesAppointedAsManager: data.numberOtherSubjectVehiclesAppointedAsManager,
  numberOtherSubjectsRegisteredAsManager: data.numberOtherSubjectsRegisteredAsManager,
  numberOtherSubjectsAppointedAsManager: data.numberOtherSubjectsAppointedAsManager,
  numberOtherSubjectVehiclesRegisteredAsManager: data.numberOtherSubjectVehiclesRegisteredAsManager,
  version: data?.version
});

export const checkIfLinkedTransportManagerFieldIsVisible = (folderId: string, managerModificationTypeKey: string) => {
  return (
    folderId &&
    managerModificationTypeKey === DomainDictionaryEntry.APPLICATION_TRANSPORT_MANAGER_MODIFICATION_TYPE.MODIFICATION
  );
};
