import { NamedObjectDetails, ResourceObjectLite, TransitStopPermission } from '@ibtm/domain';

import { SelectOptionPartial } from '@libs/common/v2';

export interface PermissionSnapshotClient {
  id: string;
  applicationId: string;
  applicationNumber: string;
  cancellationReason: string;
  cancelledBy: string;
  cancellationDate: string;
  subjectAddress: string;
  subjectName: string;
  certificateNumber: string;
  subjectNip: string;
  permissionNumber: string;
  resourceAssignmentRegenerationNeeded: boolean;
  formNumber: string;
  dateOfIssue: string;
  dateOfIssueGitd: string;
  dateOfIssueExternal: string;
  validFrom: string;
  validTo: string;
  signerName: string;
  status: string;
  printDate: string;
  issuingCountryKey: string;
  issuingAuthority: string;
  version: number;
  file?: NamedObjectDetails;
  form?: ResourceObjectLite;
  forms?: Array<ResourceObjectLite>;
  permissionValidityPeriodKey?: string;
  transitStopStartTour?: Array<TransitStopPermission>;
  transitStopEndTour?: Array<TransitStopPermission>;
  permissionCopyNumber?: string;
  number?: string;
  locksToSetUp?: number;
  issuedLocks?: number;
  permissionsGenerated?: boolean;
}

export interface PermissionCopySnapshotClient {
  id: string;
  certificateNumber: string;
  applicationNumber: string;
  licenseNumber?: string;
  cancellationReason: string;
  cancelledBy: string;
  cancellationDate: string;
  subjectName: string;
  subjectAddress: string;
  permissionNumber: string;
  permissionCopyNumber: string;
  resourceAssignmentRegenerationNeeded: boolean;
  formNumber: string;
  dateOfIssue: string;
  dateOfIssueExternal: string;
  validFrom: string;
  validTo: string;
  signerName: string;
  status: string;
  transitType: string;
  printDate: string;
  version: number;
  file?: NamedObjectDetails;
  form?: ResourceObjectLite;
  forms?: Array<ResourceObjectLite>;
  number?: string;
  printOfDate?: string;
  numberOf?: string;
}

export enum PermissionStatusEnum {
  REVERSED = 'ibtm-domain.permission-status.reversed',
  INACTIVE = 'ibtm-domain.permission-status.inactive',
  TRANSFER_TO_COUNTY_OFFICE = 'ibtm-domain.permission-status.transfer-to-county-office',
  DRAFT = 'ibtm-domain.permission-status.draft',
  EXPIRED = 'ibtm-domain.permission-status.expired',
  SUSPEND = 'ibtm-domain.permission-status.suspend',
  ACTIVE = 'ibtm-domain.permission-status.active',
  PENDING = 'ibtm-domain.permission-status.pending'
}

export enum PermissionTypeEnum {
  /** Zaświadczenie na potrzeby własne przewóz osób */
  OWN_NEEDS_PASSENGER_CARRIAGE = 'ibtm-domain.permission.type.own-needs.passenger-carriage',

  /** Wypis z zezwolenia na przewozy wahadłowe */
  SPO_SHUTTLE_ABRIDGEMENT = 'ibtm-domain.permission.type.spo-shuttle-abridgement',

  /** Zezwolenie na przewozy wahadłowe */
  SPO_SHUTTLE = 'ibtm-domain.permission.type.spo-shuttle',

  /** Wypis z zaświadczenia na potrzeby własne przewóz osób */
  OWN_NEEDS_PASSENGER_CARRIAGE_ABRIDGEMENT = 'ibtm-domain.permission.type.own-needs.passenger-carriage-abridgement',

  /** Certyfikat potwierdzający spełnienie przez pojazd odpowiednich wymogów bezpieczeństwa lub warunków dopuszczenia do ruchu */
  CERTIFICATE_S = 'ibtm-domain.permission.type.certificate-s',

  /** Wypis z Licencji wspólnotowej rzeczy do 10 lat */
  TP_LICENSE_EU_10_YEARS_ABRIDGEMENT = 'ibtm-domain.permission.type.tp-license-eu-10-years-abridgement',

  /** Licencja na pośrednictwo przy przewozie osób do 15 lat */
  FIFTEEN_YEARS_PASSENGER_TRANSPORT_BROKERAGE_LICENSE = 'ibtm-domain.permission.type.fifteen-years-passenger-transport-brokerage-license',

  /** Zezwolenia jednorazowe przewóz rzeczy */
  SINGLE_PERMIT_TP = 'ibtm-domain.permission.type.single-permit-tp',

  /** Licencja wspólnotowa osób do 10 lat */
  OP_LICENSE_EU_10_YEARS = 'ibtm-domain.permission.type.op-license-eu-10-years',

  /** Wypis z Licencji wspólnotowa osób do 10 lat */
  OP_LICENSE_EU_10_YEARS_ABRIDGEMENT = 'ibtm-domain.permission.type.op-license-eu-10-years-abridgement',

  /** Wypis z Licencji wspólnotowej rzeczy do 5 lat */
  TP_LICENSE_EU_5_YEARS_ABRIDGEMENT = 'ibtm-domain.permission.type.tp-license-eu-5-years-abridgement',

  /** Wypis z zezwolenia na przewozy tranzytowe */
  SPO_TRANSIT_ABRIDGEMENT = 'ibtm-domain.permission.type.spo-transit-abridgement',

  /** Formularz podróży UE */
  TRAVEL_FORM_UE = 'ibtm-domain.permission.type.travel-form-ue',

  /** Zezwolenie na wykonywanie zawodu przewoźnika drogowego rzeczy */
  ROAD_CARRIER_GOODS_CARRIAGE = 'ibtm-domain.permission.type.road-carrier-goods-carriage',

  /** Wypis z zezwolenia na przewozy regularne do Wielkiej Brytanii */
  SPO_UK_ABRIDGEMENT = 'ibtm-domain.permission.type.spo-uk-abridgement',

  /** Wypis z zezwolenia na przewozy okazjonalne */
  SPO_OCCASIONAL_ABRIDGEMENT = 'ibtm-domain.permission.type.spo-occasional-abridgement',

  /** Świadectwo Kierowcy */
  DRIVER_CERTIFICATE = 'ibtm-domain.permission.type.driver-certificate',

  /** Zezwolenie EKMT */
  EKMT_PERMIT = 'ibtm-domain.permission.type.ekmt-permit',

  /** Licencja wspólnotowa rzeczy do 10 lat */
  TP_LICENSE_EU_10_YEARS = 'ibtm-domain.permission.type.tp-license-eu-10-years',

  /** Wypis z zezwolenia na przewozy regularne poza UE */
  SPO_OUTSIDE_UE_ABRIDGEMENT = 'ibtm-domain.permission.type.spo-outside-ue-abridgement',

  /** Zezwolenie na wykonywanie zawodu przewoźnika drogowego osób */
  ROAD_CARRIER_PASSENGER_CARRIAGE = 'ibtm-domain.permission.type.road-carrier-passenger-carriage',

  /** Wypis z Licencji wspólnotowej osób do 50 lat */
  OP_LICENSE_EU_50_YEARS_ABRIDGEMENT = 'ibtm-domain.permission.type.op-license-eu-50-years-abridgement',

  /** Zezwolenia jednorazowe przewóz osób */
  SINGLE_PERMIT_OP = 'ibtm-domain.permission.type.single-permit-op',

  /** Licencja na pośrednictwo przy przewozie osób do 50 lat */
  FIFTY_YEARS_PASSENGER_TRANSPORT_BROKERAGE_LICENSE = 'ibtm-domain.permission.type.fifty-years-passenger-transport-brokerage-license',

  /** Zezwolenie EKMT przeprowadzka */
  EKMT_PERMIT_MOVE = 'ibtm-domain.permission.type.ekmt-permit-move',

  /** Licencja wspólnotowa rzeczy do 5 lat */
  TP_LICENSE_EU_5_YEARS = 'ibtm-domain.permission.type.tp-license-eu-5-years',

  /** Wypis z zezwolenia na wykonywanie zawodu przewoźnika drogowego rzeczy */
  ROAD_CARRIER_GOODS_CARRIAGE_ABRIDGEMENT = 'ibtm-domain.permission.type.road-carrier-goods-carriage-abridgement',

  /** Zezwolenie na przewozy regularne do Wielkiej Brytanii */
  SPO_UK = 'ibtm-domain.permission.type.spo-uk',

  /** Wypis z zezwolenia na wykonywanie zawodu przewoźnika drogowego osób */
  ROAD_CARRIER_PASSENGER_CARRIAGE_ABRIDGEMENT = 'ibtm-domain.permission.type.road-carrier-passenger-carriage-abridgement',

  /** Wypis z Licencji wspólnotowej rzeczy do 50 lat */
  TP_LICENSE_EU_50_YEARS_ABRIDGEMENT = 'ibtm-domain.permission.type.tp-license-eu-50-years-abridgement',

  /** Licencja na pośrednictwo przy przewozie osób do 30 lat */
  THIRTY_YEARS_PASSENGER_TRANSPORT_BROKERAGE_LICENSE = 'ibtm-domain.permission.type.thirty-years-passenger-transport-brokerage-license',

  /** Zezwolenie na przewozy okazjonalne */
  SPO_OCCASIONAL = 'ibtm-domain.permission.type.spo-occasional',

  /** Licencja wspólnotowa osób do 5 lat */
  OP_LICENSE_EU_5_YEARS = 'ibtm-domain.permission.type.op-license-eu-5-years',

  /** Zezwolenia na przewozy tranzytowe */
  SPO_TRANSIT = 'ibtm-domain.permission.type.spo-transit',

  /** Licencja wspólnotowa rzeczy do 50 lat */
  TP_LICENSE_EU_50_YEARS = 'ibtm-domain.permission.type.tp-license-eu-50-years',

  /** Zaświadczenie na potrzeby własne przewóz rzeczy */
  OWN_NEEDS_GOODS_CARRIAGE = 'ibtm-domain.permission.type.own-needs.goods-carriage',

  /** Wypis z zaświadczenia na potrzeby własne przewóz rzeczy */
  OWN_NEEDS_GOODS_CARRIAGE_ABRIDGEMENT = 'ibtm-domain.permission.type.own-needs.goods-carriage-abridgement',

  /** Brak wydawanych uprawnień */
  NO_ALLOWANCES_ISSUED = 'ibtm-domain.permission.type.no-allowances-issued',

  /** Zezwolenie na przewozy regularne do UE */
  SPO_UE = 'ibtm-domain.permission.type.spo-ue',

  /** Wypis z zezwolenia na przewozy regularne do UE */
  SPO_UE_ABRIDGEMENT = 'ibtm-domain.permission.type.spo-ue-abridgement',

  /** Interbus */
  INTERBUS = 'ibtm-domain.permission.type.interbus',

  /** Licencja wspólnotowa osób do 50 lat */
  OP_LICENSE_EU_50_YEARS = 'ibtm-domain.permission.type.op-license-eu-50-years',

  /** Wypis z Licencji wspólnotowej osób do 5 lat */
  OP_LICENSE_EU_5_YEARS_ABRIDGEMENT = 'ibtm-domain.permission.type.op-license-eu-5-years-abridgement',

  /** Zezwolenie na przewozy regularne poza UE */
  SPO_OUTSIDE_UE = 'ibtm-domain.permission.type.spo-outside-ue'
}

export enum PermissionsColumnsEnum {
  APPLICATION_NUMBER = 'applicationNumber',
  CANCELLATION_REASON = 'cancellationReason',
  CANCELLED_BY = 'cancelledBy',
  CANCELLATION_DATE = 'cancellationDate',
  SUBJECT_ADDRESS = 'subjectAddress',
  PERMISSION_NUMBER = 'permissionNumber',
  FORM_NUMBER = 'formNumber',
  DATE_OF_ISSUE = 'dateOfIssue',
  DATE_OF_ISSUE_GITD = 'dateOfIssueGitd',
  DATE_OF_ISSUE_EXTERNAL = 'dateOfIssueExternal',
  VALID_FROM = 'validFrom',
  VALID_TO = 'validTo',
  SIGNER_NAME = 'signerName',
  SUBJECT_NAME = 'subjectName',
  SUBJECT_NIP = 'subjectNip',
  STATUS = 'status',
  PRINT_DATE = 'printDate',
  ISSUING_COUNTRY = 'issuingCountryKey',
  ISSUING_AUTHORITY = 'issuingAuthority',
  PERMISSION_VALIDITY_PERIOD_KEY = 'permissionValidityPeriodKey',
  CERTIFICATE_NUMBER = 'certificateNumber',
  PERMISSION_COPY_NUMBER = 'permissionCopyNumber',
  TRANSIT_STOP_START_TOUR = 'transitStopStartTour',
  TRANSIT_STOP_END_TOUR = 'transitStopEndTour'
}

export enum PermissionCopiesColumnsEnum {
  APPLICATION_NUMBER = 'applicationNumber',
  CANCELLATION_REASON = 'cancellationReason',
  CANCELLED_BY = 'cancelledBy',
  CANCELLATION_DATE = 'cancellationDate',
  CERTIFICATE_NUMBER = 'certificateNumber',
  SUBJECT_NAME = 'subjectName',
  SUBJECT_ADDRESS = 'subjectAddress',
  PERMISSION_NUMBER = 'permissionNumber',
  PERMISSION_COPY_NUMBER = 'permissionCopyNumber',
  FORM_NUMBER = 'formNumber',
  DATE_OF_ISSUE = 'dateOfIssue',
  DATE_OF_ISSUE_EXTERNAL = 'dateOfIssueExternal',
  VALID_FROM = 'validFrom',
  VALID_TO = 'validTo',
  SIGNER_NAME = 'signerName',
  STATUS = 'status',
  TRANSIT_TYPE = 'transitType',
  PRINT_DATE = 'printDate',
  LICENSE_NUMBER = 'licenseNumber',
  NUMBER = 'number',
  TRANSPORT_TYPE = 'transitType',
  PRINT_OF_DATE = 'printOfDate',
  NUMBER_OF = 'numberOf'
}

export type PermissionFields = {
  /**
   * Nr zezwolenia
   */
  permissionNumber?: string;
  /**
   * Data wydania
   */
  validFrom?: string;
  /**
   * Data ważności do
   */
  validTo?: string;
  /**
   * Data wydania
   */
  dateOfIssue?: string;
  /**
   * Ważne bezterminowo
   */
  validIndefinitely?: boolean;
  /**
   * Organ wydający
   */
  issuingAuthority?: string;
  /**
   * Organ wydający (słownik)
   */
  issuingAuthorityKey?: SelectOptionPartial<string>;
  /**
   * Uprawnienie spółki
   */
  applyToPartnership?: boolean;
  /**
   *  lista NIP
   */
  partnershipPartnersNip?: SelectOptionPartial[];
  /**
   * Przedsiębiorca
   */
  enterpreneur?: string;
  /**
   * Przedsiębiorca jeżeli charakter prawny = spółka cywilna
   */
  partnershipEnterpreneur?: string;
  /**
   * Status
   */
  statusKey?: SelectOptionPartial;
  /**
   * Państwo wydające zezwolenie
   */
  issuingCountryKey?: SelectOptionPartial<string>;
  /**
   * Nazwa zezwolenia wydanego przez organ zewnętrzny
   */
  foreignPermissionName?: string;
  /**
   * Data wprowadzenia
   */
  created?: string;
  /**
   * Wprowadził
   */
  author?: string;
  /**
   * Data edycji
   */
  modified?: string;
  /**
   * Edytował
   */
  modifier?: string;
  /**
   * Wersja
   */
  version?: number;
  /**
   * Uprawnienie spółki cywilnej
   */
  isPartnershipPermission?: boolean;
  /**
   * Rodzaj uprawnienia
   */
  typeKey?: {
    name?: string;
    value: PermissionTypeEnum | string;
  };
};

export type PermissionActions =
  | 'create'
  | 'details'
  | 'edit'
  | 'delete'
  | 'passToDistrictAuthority'
  | 'restoreFromDistrictAuthority';
