import { useEffect, useMemo, useState } from 'react';
import { FolderDetails } from '@ibtm/domain';

import { useFormV2Context } from '@libs/common/v2';

import { FolderDetailsSnapshot } from '@libs/domain/folder';

import { useFolderDetailsQuery } from '../../../folder/api';
import { ArchiveQueryKeysEnum } from '../../common';
import { useBorrowFolderSingleQuery } from '../api';
import { useBorrowFoldersContext } from '../components/common/BorrowFolderProvider';
import { BorrowFolderDialogTypeEnum, BorrowFolderFormEnum, BorrowFolderSnapshot } from '../model';

const useFetchFolderDialogContent = () => {
  const { contentType, onOpenWithCheck } = useBorrowFoldersContext();
  const { watch } = useFormV2Context();
  const pickedRow = watch(BorrowFolderFormEnum.BORROW_PICKED_FOLDER) as FolderDetailsSnapshot;
  const [isFetching, setIsFetching] = useState(false);

  const { data: borrowedFolderDetails, isLoading: isBorrowFolderLoading } = useBorrowFolderSingleQuery(
    pickedRow?.number,
    {
      enabled: !!pickedRow.number && contentType === BorrowFolderDialogTypeEnum.RETURN_FOLDER
    }
  );
  const { data, isLoading } = useFolderDetailsQuery(
    pickedRow?.id,
    { enabled: !!pickedRow?.id },
    ArchiveQueryKeysEnum.BORROW_FOLDER_DETAILS
  );

  useEffect(() => {
    setIsFetching(true);
    if (!isLoading && data && (!isBorrowFolderLoading || contentType !== BorrowFolderDialogTypeEnum.RETURN_FOLDER)) {
      if (borrowedFolderDetails && contentType === BorrowFolderDialogTypeEnum.RETURN_FOLDER) {
        onOpenWithCheck(contentType, {
          ...data,
          [BorrowFolderFormEnum.BORROW_DATE]: borrowedFolderDetails.borrowDate,
          [BorrowFolderFormEnum.BORROW_PURPOSE]: borrowedFolderDetails.purposeKey,
          [BorrowFolderFormEnum.BORROWER]: borrowedFolderDetails.borrowedBy.name
        } as never);
      } else {
        onOpenWithCheck(contentType, data as BorrowFolderSnapshot & FolderDetails);
      }
      setIsFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data, borrowedFolderDetails, contentType, isBorrowFolderLoading]);

  return useMemo(() => ({ isLoading: !isFetching }), [isFetching]);
};

export default useFetchFolderDialogContent;
