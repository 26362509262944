import { ForeignPermissionReceiveRequest } from '@ibtm/client-domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

export interface IForeignPermissionReceiveRequest extends ForeignPermissionReceiveRequest {
  xSubjectId: string;
  xFolderId: string;
}

function receivePermission({ xFolderId, xSubjectId, ...params }: IForeignPermissionReceiveRequest) {
  return API.client.foreignPermissions.receiveForeignPermissions('', '', params, {
    ...endpointsConfig.receiveForeignPermissions
  });
}

function useReceiveForeignClientPermission() {
  return useMutation(receivePermission);
}

export default useReceiveForeignClientPermission;
