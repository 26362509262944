import React from 'react';
import { makeStyles } from '@mui/styles';

import { Button, IconType } from '@libs/common/v2';
import { Theme, useTheme } from '@libs/common/v2/theme';

import { HasPermission, UIElementNameEnum } from '@libs/permission';

import { important } from '../../utils';

interface IProps {
  onClick?: React.MouseEventHandler;
  tooltipTitle?: string;
  label?: string;
  icon?: IconType;
  actionKey?: UIElementNameEnum;
  className?: string;
  classNameWrapper?: string;
  iconClassName?: string;
  isButtonLoading?: boolean;
  isDisabled?: boolean;
  isHidden?: boolean;
  isLoading?: boolean;
  isNoMargin?: boolean;
}

function DropdownListButton({ isNoMargin = true, isDisabled, actionKey, ...forwardProps }: IProps) {
  const { contrast } = useTheme();
  const classes = useStyles({ contrast });

  return (
    <HasPermission actionKeys={[actionKey]}>
      <Button
        iconClassName={classes.icon}
        className={classes.button}
        variant="text"
        size="small"
        type="button"
        disabled={isDisabled}
        isNoMargin={isNoMargin}
        loaderWithText
        fullWidth
        {...forwardProps}
      />
    </HasPermission>
  );
}

const useStyles = makeStyles<Theme, { contrast?: boolean }>(theme => ({
  button: {
    backgroundColor: ({ contrast }) => contrast && theme.palette.grey[50],
    padding: '12px 53px 12px 16px',
    width: '100%',
    justifyContent: 'flex-start',
    borderRadius: 0,
    '&:disabled': {
      color: important(theme.palette.grey[500]),
      backgroundColor: important(theme.palette.grey[200])
    }
  },
  icon: {
    marginRight: 12,
    marginLeft: 0
  }
}));

export default DropdownListButton;
