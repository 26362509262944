import { ReactNode } from 'react';

import { GridLayout } from '@libs/common/v2';

import { IGridProps } from '@libs/meta-form/models';

export type IGridParameters = {
  gridContainerProps: IGridProps;
  gridItemProps: IGridProps;
};

function Grid({
  children,
  gridContainerProps,
  gridItemProps,
  renderNodes,
  components
}: IGridParameters & {
  renderNodes: (children: ReactNode, components: any) => JSX.Element | JSX.Element[];
  components: any;
  children: ReactNode;
}) {
  return (
    <GridLayout containerProps={gridContainerProps} itemProps={gridItemProps}>
      {renderNodes(children, components)}
    </GridLayout>
  );
}

export default Grid;
