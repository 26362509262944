import { ApplicationPaymentUpdateRequest, MoneyTransferSnapshot } from '@ibtm/domain';

import { useDialog, useFormV2Context } from '@libs/common/v2';

import MoneyTransferEditDialog from '../components/MoneyTransferEditDialog';

function useMoneyTransferEditDialog() {
  const { openDialog } = useDialog();
  const { getValues } = useFormV2Context();
  const showMoneyTransferEditDialog = ({
    applicationId,
    data,
    fieldId
  }: {
    applicationId: string;
    data: MoneyTransferSnapshot;
    fieldId: string;
  }) => {
    openDialog(({ closeDialog }) => {
      const formValues = getValues(fieldId) as ApplicationPaymentUpdateRequest;
      return (
        <MoneyTransferEditDialog
          closeDialog={closeDialog}
          data={data}
          applicationId={applicationId}
          accountingApproved={formValues?.accountingApproved || false}
        />
      );
    });
  };

  return { showMoneyTransferEditDialog };
}

export default useMoneyTransferEditDialog;
