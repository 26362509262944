import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { SCertificatesSnapshotPage } from '@ibtm/domain';

import { DropdownListButton, TableIconButton, useTableAdapter, useTableContext } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useFormDirtyContext, useMetaFormContext } from '@libs/meta-form/context';
import useHandleFormChanges from '@libs/meta-form/services/useHandleFormChanges';

import { ApplicationQueryKeysEnum } from '@libs/domain/application/api';
import { DomainDictionaryEntry } from '@libs/domain/config';
import { useCopyFolderVehiclesMutation, VehicleQueryKeysEnum } from '@libs/domain/vehicle';

import { useCertificateVehicleTabContext } from '../../hooks';

interface IProps {
  selectedIds?: string[];
  certificateSStatusKey?: string;
  handleClose?: () => void;
  isIconButton?: boolean;
  isMultipleActionButton?: boolean;
  onSettled?: () => void;
  onActionStart?: () => void;
  isLoading?: boolean;
}

function MoveVehicleButton({
  selectedIds,
  certificateSStatusKey,
  handleClose,
  isIconButton,
  isMultipleActionButton,
  onActionStart,
  onSettled,
  isLoading: isLoadingOverrided
}: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { data } = useTableContext() as {
    data: SCertificatesSnapshotPage;
  };
  const { applicationId } = useCertificateVehicleTabContext();
  const { toggleAllRowsSelected } = useTableAdapter();
  const { mutate: copyVehicle, isLoading } = useCopyFolderVehiclesMutation();
  const { showSuccessSnackbar } = useSnackbar();
  const isDisabled = certificateSStatusKey === DomainDictionaryEntry.CERTIFICATE_S_STATUS.RELEASE;

  const { isDirty } = useFormDirtyContext();
  const { formRef } = useMetaFormContext();

  const { handleFormChanges } = useHandleFormChanges(isDirty, formRef?.current?.onSubmit, formRef?.current?.onReset);

  const handleSendData = () => {
    onActionStart?.();
    const ids = isMultipleActionButton
      ? data?.content
          ?.filter(item => selectedIds.includes(item.id))
          .map(item => {
            if (item.vehicleId) return item.vehicleId;
            return item.id;
          })
      : selectedIds;

    handleFormChanges(() => {
      copyVehicle(
        { applicationId, vehicleIds: ids },
        {
          onSuccess: () => {
            toggleAllRowsSelected(false);
            showSuccessSnackbar(t('success.save'));
            queryCache.invalidateQueries(VehicleQueryKeysEnum.VEHICLE_LIST);
            queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
            handleClose?.();
          },
          onSettled: () => {
            onSettled?.();
          }
        }
      );
    });
  };

  return isIconButton ? (
    <TableIconButton
      isDisabled={isDisabled}
      isButtonLoading={isLoading || isLoadingOverrided}
      icon="AddIcon"
      tooltipTitle={isDisabled ? t('applications:certificates.actionAddUnavaliable') : t('action.add')}
      onClick={handleSendData}
    />
  ) : (
    <DropdownListButton
      isLoading={isLoading || isLoadingOverrided}
      isDisabled={isDisabled}
      label={isDisabled ? t('applications:certificates.actionAddUnavaliable') : t('action.add')}
      onClick={handleSendData}
    />
  );
}

export default MoveVehicleButton;
