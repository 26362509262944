import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { object as YupObject, string as YupString } from 'yup';

import { isDateValid, SelectOption } from '@libs/common/v2';

import { LicenseCopiesColumnsEnum, LicensesColumnsEnum } from '../model/license.model';
import { licenseValidityPeriodKeyToValue } from '../utils';

interface IParams {
  visibleLicenseColumns?: Array<LicensesColumnsEnum | { column: LicensesColumnsEnum; isEditable: boolean }>;
  visibleLicenseCopiesColumns?: Array<
    LicenseCopiesColumnsEnum | { column: LicenseCopiesColumnsEnum; isEditable: boolean }
  >;
}

function useLicensesValidation({ visibleLicenseColumns }: IParams) {
  const [t] = useTranslation();

  const getLicenseValidationScheme = useMemo(() => {
    const isColumnEditable = name =>
      visibleLicenseColumns?.find(item => {
        if (typeof item === 'object') {
          return item?.column === name && item.isEditable;
        }
        return false;
      });

    return YupObject({
      ...(isColumnEditable(LicensesColumnsEnum.LICENSE_NUMBER) && {
        [LicensesColumnsEnum.LICENSE_NUMBER]: YupString().nullable().required()
      }),
      ...(isColumnEditable(LicensesColumnsEnum.LICENSE_VALIDITY_YEARS_KEY) && {
        [LicensesColumnsEnum.LICENSE_VALIDITY_YEARS_KEY]: YupObject().nullable().required()
      }),
      ...(isColumnEditable(LicensesColumnsEnum.PRINT_DATE) && {
        [LicensesColumnsEnum.PRINT_DATE]: YupString().nullable().required().concat(isDateValid())
      }),
      ...(isColumnEditable(LicensesColumnsEnum.VALID_FROM) && {
        [LicensesColumnsEnum.VALID_FROM]: YupString().nullable().required().concat(isDateValid()),
        ...(isColumnEditable(LicensesColumnsEnum.VALID_TO) && {
          [LicensesColumnsEnum.VALID_TO]: YupString()
            .nullable()
            .required()
            .test({
              name: 'validityPeriodKey',
              message: t('prints:messages.validFromExceedsValidityPeriod'),
              test(validTo) {
                const parent = this.parent as Record<LicensesColumnsEnum, string>;
                const validFrom = parent?.[LicensesColumnsEnum.VALID_FROM];
                const licenseValidityYearsKey = parent?.[LicensesColumnsEnum.LICENSE_VALIDITY_YEARS_KEY] as
                  | SelectOption<string>
                  | string;

                const licenseValidityYearsKeyValue =
                  typeof licenseValidityYearsKey === 'string'
                    ? licenseValidityYearsKey
                    : licenseValidityYearsKey?.value;

                const licenseValidityPeriodValue = licenseValidityPeriodKeyToValue[licenseValidityYearsKeyValue];
                if (!licenseValidityPeriodValue) {
                  return true;
                }

                const validFromPeriod = moment(validFrom).add(licenseValidityPeriodValue, 'years');
                return !moment(validTo).isAfter(validFromPeriod);
              }
            })
            .test({
              name: 'validTo',
              message: t('applications:validations.validToDateCanNotBeBeforeValidFrom'),
              test(validTo) {
                const parent = this.parent as Record<LicensesColumnsEnum, string>;
                const validFrom = parent?.[LicensesColumnsEnum.VALID_FROM];
                return !validFrom || !validTo || moment(validFrom).isBefore(validTo);
              }
            })
            .concat(isDateValid())
        })
      })
    });
  }, [t, visibleLicenseColumns]);

  return { getLicenseValidationScheme };
}

export default useLicensesValidation;
