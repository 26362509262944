import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PullNotificationSnapshot } from '@avispon/message/dist/models';
import { useSnackbar } from '@enigma/fe-ui';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import { TableButtonDelete, TableButtonView, useConfirmDialog, useRouter, useTableContext } from '@libs/common/v2';

import { useDeleteMessageMutation } from '@libs/notification/api';

interface IProps {
  notification: PullNotificationSnapshot;
}

function AdminMessagesTableRowActions({ notification }: IProps) {
  const [t] = useTranslation();
  const { refetch } = useTableContext();
  const { mutate: deleteMessage } = useDeleteMessageMutation();
  const [confirm] = useConfirmDialog();
  const { showSuccessSnackbar } = useSnackbar();
  const { routes } = useRouter();

  const deleteNotification = useCallback(() => {
    confirm({
      title: t('messages:dialog.deleteItem'),
      message: t('messages:dialog.messageDeleteConfirm'),
      onConfirm: (setConfirmLoading, closeDialog) => {
        setConfirmLoading(true);
        deleteMessage([notification.id], {
          onSuccess: () => {
            showSuccessSnackbar(t('dialog.deleteSuccess'));
            refetch();
            closeDialog();
          },
          onSettled: () => {
            setConfirmLoading(false);
          }
        });
      },
      confirmType: 'danger'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TableButtonDelete onClick={deleteNotification} actionKey={UIElementNameEnum.ANNOUNCEMENT_DELETE_BUTTON} />
      <TableButtonView
        link={{
          pathname: routes.adminMessageDetails(notification.id),
          state: { message: notification }
        }}
        actionKey={UIElementNameEnum.ANNOUNCEMENT_DETAILS_BUTTON}
      />
    </>
  );
}

export default AdminMessagesTableRowActions;
