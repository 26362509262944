import { useCallback } from 'react';
import { FinancialSecurityDetails } from '@ibtm/domain';

import { CamelCasePath } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { UiMode } from '@libs/meta-form';
import { useMetaFormContext } from '@libs/meta-form/context';
import { UIElementNameEnum } from '@libs/permission';

import { FinancialSecurityActions, FinancialSecurityTable } from '@libs/domain/financial-security';

import { ApplicationQueryKeysEnum } from '../../api';

interface FinancialSecurityPermissionsObj {
  create?: UIElementNameEnum;
  edit?: UIElementNameEnum;
  expire?: UIElementNameEnum;
  restore?: UIElementNameEnum;
  delete?: UIElementNameEnum;
  search?: UIElementNameEnum;
  details?: UIElementNameEnum;
}

interface IProps {
  folderTypeKey: string;
  folderId?: string;
  transferredFoldersIds?: string[];
  applicationId?: string;
  visibleColumns?: Array<CamelCasePath<FinancialSecurityDetails>>;
  actions?: Array<FinancialSecurityActions>;
  permissionsKeys?: FinancialSecurityPermissionsObj;
  isSection?: boolean;
  subjectId?: string;
  exchangeRateFrom: string;
  exchangeRateTo: string;
}

function FinancialSecurityList({
  folderId,
  transferredFoldersIds,
  applicationId,
  visibleColumns,
  actions,
  permissionsKeys,
  folderTypeKey,
  isSection,
  subjectId,
  exchangeRateFrom,
  exchangeRateTo
}: IProps) {
  const { mode } = useMetaFormContext();
  const queryCache = useQueryCache();

  const handleRefetchApplicationOnSuccess = useCallback(() => {
    queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
  }, [queryCache, applicationId]);

  return (
    <FinancialSecurityTable
      applicationId={applicationId}
      folderId={folderId}
      transferredFoldersIds={transferredFoldersIds}
      actions={actions}
      permissionsKeys={permissionsKeys}
      folderTypeKey={folderTypeKey}
      visibleColumns={visibleColumns}
      customOnSuccess={handleRefetchApplicationOnSuccess}
      isActionColumnEnabled={mode !== UiMode.VIEW}
      isSection={isSection}
      subjectId={subjectId}
      exchangeRateFrom={exchangeRateFrom}
      exchangeRateTo={exchangeRateTo}
      isHeaderActionsVisible
    />
  );
}

export default FinancialSecurityList;
