import React, { useMemo, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DataSourceMediatorDetailsSnapshot } from '@stack/report';

import {
  Button,
  FormMode,
  Section,
  SelectOption,
  Typography,
  useDialog,
  useErrorsHandlerV2,
  useFormV2Context
} from '@libs/common/v2';

import { ReportQueryKeysEnum } from '@libs/report/api';
import { EMPTY_DATASET_DEFINITION } from '@libs/report/model';

import { NewReportTypeDataSectionForm } from './NewReportTypeDataSectionForm';
import ReportTypeDataTabContentWrapper from './ReportTypeDataTabContentWrapper';
import ReportTypeDataTabs from './ReportTypeDataTabs';

function ReportDataSourceSection({ dataSetIndex, setDataSetIndex, removeTab, updateTab }) {
  const [t] = useTranslation();
  const { watch } = useFormV2Context();
  const fields = watch('dataSetDefinitions');

  const hasField = useMemo(() => fields.length > 0, [fields]);
  const { saveFieldList } = useErrorsHandlerV2<number>(i => {
    setDataSetIndex(parseInt(i as never, 10));
  }, ReportQueryKeysEnum.REPORT_TYPE_FORM);

  return hasField ? (
    <>
      <ReportTypeDataTabs tab={dataSetIndex} onTabChange={setDataSetIndex} />
      <ReportTypeDataTabContentWrapper
        tab={dataSetIndex}
        onTabChange={setDataSetIndex}
        removeTab={removeTab}
        updateTab={updateTab}
        saveFieldList={saveFieldList}
      />
    </>
  ) : (
    <Section areTitleAndActionsInside>
      <Typography className="text-center">{t('reports:reportTypes.missingDataSet')}</Typography>
    </Section>
  );
}

function ReportTypeDataSection() {
  const [t] = useTranslation();
  const { loading, control } = useFormV2Context();
  const [dataSetIndex, setDataSetIndex] = useState(0);
  const { openDialog } = useDialog();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'dataSetDefinitions'
  });

  const handleAddDataSourceInDialog = (
    values: {
      dataFileFormatKey: SelectOption<string>;
      name: string;
      source: SelectOption<DataSourceMediatorDetailsSnapshot>;
    },
    index: number
  ) => {
    const newDataSetDefinition = {
      ...EMPTY_DATASET_DEFINITION,
      ...values
    };

    append(newDataSetDefinition);
    setDataSetIndex(index);
  };

  return (
    <Section
      title={t('reports:details.section.reportData')}
      contentClassName="bg-transparent border-transparent shadow-none m-0 p-0"
      headerContent={
        <Button
          label={t('reports:reportTypes.action.addDataSet')}
          variant="outlined"
          onClick={() =>
            openDialog(({ closeDialog }) => (
              <NewReportTypeDataSectionForm
                data={fields}
                formMode={FormMode.CREATE}
                closeDialog={closeDialog}
                onSubmit={handleAddDataSourceInDialog}
              />
            ))
          }
        />
      }
      isLoading={loading}
      isPadded
      isCollapsable
    >
      <ReportDataSourceSection
        dataSetIndex={dataSetIndex}
        setDataSetIndex={setDataSetIndex}
        removeTab={remove}
        updateTab={update}
      />
    </Section>
  );
}

export default ReportTypeDataSection;
