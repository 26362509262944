import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { SingleUsePermissionSubmissionDetails, SingleUseSubmissionItemSnapshot } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import {
  Button,
  CamelCasePath,
  FormMode,
  TableIconButton,
  TableLayout,
  typedNameV2,
  useConfirmDialog,
  useFormV2Context,
  useViewModesV2
} from '@libs/common/v2';

import { DomainUIElementEnum } from '@libs/domain/config';
import {
  IDisposablePermitsDetailsParams,
  useAddDecisionPermitModal,
  useCreateDisposablePermitModal,
  useSingleUseSubmissionItemsTable
} from '@libs/domain/social-commission';

import { useDateOfCollectionDialog } from '../../common/date-of-collection-dialog';

function SingleUseSubmissionItemsTable({
  formMode,
  singleUseSubmissionItems,
  setSingleUseSubmissionItems
}: {
  formMode: FormMode;
  singleUseSubmissionItems: SingleUseSubmissionItemSnapshot[];
  setSingleUseSubmissionItems: Dispatch<SetStateAction<SingleUseSubmissionItemSnapshot[]>>;
}) {
  const [t] = useTranslation();
  const [confirm] = useConfirmDialog();
  const { watch } = useFormV2Context();
  const { viewMode, createMode } = useViewModesV2(formMode);

  const { id: singleUsePermissionId } = useParams<IDisposablePermitsDetailsParams>();
  const getLabel = partialTranslate('foreignPermits:tabs.socialCommission.tabs.socialCommissionDisposablePermits');

  const resourceItems = watch(
    typedNameV2<SingleUsePermissionSubmissionDetails>('items')
  ) as SingleUseSubmissionItemSnapshot[];

  const { showAddDecisionPermitModal } = useAddDecisionPermitModal({ singleUsePermissionId });
  const { openDateOfCollectionDialog } = useDateOfCollectionDialog();
  const { showCreatePermitModal } = useCreateDisposablePermitModal({
    singleUseSubmissionItems,
    setSingleUseSubmissionItems
  });

  const visibleColumns: Array<CamelCasePath<SingleUseSubmissionItemSnapshot>> = [
    'receiptDateTo',
    'receiptDateFrom',
    'resourceFormNameKey',
    'quantity',
    'limit',
    'used',
    'returned',
    'shouldReturn',
    'decision',
    'vehicles',
    'notReturned'
  ];

  const tableProps = useSingleUseSubmissionItemsTable(resourceItems, visibleColumns);
  const isDeleteDisabled = resourceItems?.length === 1;

  const handleDeleteResource = (resourceTypeId: string) => {
    const newResources = singleUseSubmissionItems.filter(item => item.resourceTypeId !== resourceTypeId);
    confirm({
      title: getLabel('deleteResourceDialog.title'),
      message: getLabel('deleteResourceDialog.message'),
      onConfirm: (_, closeDialog) => {
        setSingleUseSubmissionItems(newResources);
        closeDialog();
      },
      confirmType: 'danger'
    });
  };

  const handleAddDecision = (resourceTypeId: string) => {
    const resourceItem = singleUseSubmissionItems.find(item => item.resourceTypeId === resourceTypeId);
    showAddDecisionPermitModal(resourceItem);
  };
  return (
    <TableLayout<SingleUseSubmissionItemSnapshot, unknown>
      {...tableProps}
      isSection
      isFilterEnabled={false}
      isRefreshEnabled={false}
      pageTitle={getLabel('sections.resources')}
      headerActions={
        !viewMode && (
          <Button
            label={t('global:action.add')}
            onClick={() => {
              showCreatePermitModal();
            }}
            variant="outlined"
          />
        )
      }
      rowActions={({ original }) => {
        return (
          <>
            {!viewMode && (
              <TableIconButton
                tooltipTitle={
                  !createMode && isDeleteDisabled
                    ? getLabel('tooltip.deleteResourceDisabled')
                    : getLabel('tooltip.deleteResource')
                }
                onClick={() => handleDeleteResource(original.resourceTypeId)}
                icon="TrashIcon"
                isDisabled={!createMode && isDeleteDisabled}
              />
            )}
            {viewMode && (
              <>
                <TableIconButton
                  onClick={() => openDateOfCollectionDialog(original)}
                  tooltipTitle={getLabel('dateOfCollectionEdit')}
                  icon="EditIcon"
                  actionKey={DomainUIElementEnum.COMMISSION_DECISION_UPDATE_BUTTON}
                />
                <TableIconButton
                  tooltipTitle={getLabel('decisionAction')}
                  onClick={() => handleAddDecision(original.resourceTypeId)}
                  icon="CalculatorIcon"
                  actionKey={DomainUIElementEnum.COMMISSION_DISPOSABLE_DECISION_ACTION_BUTTON}
                />
              </>
            )}
          </>
        );
      }}
    />
  );
}

export default SingleUseSubmissionItemsTable;
