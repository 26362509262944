import { IUserRoutes } from '@libs/user/models/routes.model';

export const UserRoutes: IUserRoutes = {
  userEdit: (id: string) => `/administration/users/${id}/edit`,
  userCreate: () => `/administration/users/create`,
  userDetails: (id: string) => `/administration/users/${id}/details`,
  usersList: () => '/administration/users',
  userClientPortalEdit: (id: string) => `/administration/users-client-portal/${id}/edit`,
  userClientPortalCreate: () => `/administration/users-client-portal/create`,
  userClientPortalDetails: (id: string) => `/administration/users-client-portal/${id}/details`,
  userClientPortalList: () => `/administration/users-client-portal`
};
