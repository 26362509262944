import _ from 'lodash';
import qs from 'qs';

import { CommonDefaultLayoutConfig } from '@libs/common/v2/configs/layout.config';

const useSettings = () => {
  const layoutConfig = {
    layout: {
      defaults: {
        mode: 'fullwidth',
        scroll: 'content',
        navbar: {
          display: true,
          folded: false,
          position: 'left'
        },
        topBar: {
          display: true,
          style: 'fixed',
          position: 'below'
        },
        footer: {
          display: false,
          style: 'fixed',
          position: 'below'
        }
      },
      form: {
        mode: {
          title: 'Mode',
          type: 'radio',
          options: [
            {
              name: 'Boxed',
              value: 'boxed'
            },
            {
              name: 'Full Width',
              value: 'fullwidth'
            }
          ]
        },
        scroll: {
          title: 'Scrollable Area',
          type: 'radio',
          options: [
            {
              name: 'Body',
              value: 'body'
            },
            {
              name: 'Content',
              value: 'content'
            }
          ]
        },
        navbar: {
          type: 'group',
          title: 'Navbar',
          children: {
            display: {
              title: 'Display',
              type: 'switch'
            },
            folded: {
              title: 'Folded',
              type: 'switch'
            },
            position: {
              title: 'Position',
              type: 'radio',
              options: [
                {
                  name: 'Left',
                  value: 'left'
                },
                {
                  name: 'Right',
                  value: 'right'
                }
              ]
            }
          }
        },
        topBar: {
          type: 'group',
          title: 'TopBar',
          children: {
            display: {
              title: 'Display',
              type: 'switch'
            },
            style: {
              title: 'Style',
              type: 'radio',
              options: [
                {
                  name: 'Fixed',
                  value: 'fixed'
                },
                {
                  name: 'Static',
                  value: 'static'
                }
              ]
            },
            position: {
              title: 'Position',
              type: 'radio',
              options: [
                {
                  name: 'Above',
                  value: 'above'
                },
                {
                  name: 'Below',
                  value: 'below'
                }
              ]
            }
          }
        },
        footer: {
          type: 'group',
          title: 'Footer',
          children: {
            display: {
              title: 'Display',
              type: 'switch'
            },
            style: {
              title: 'Style',
              type: 'radio',
              options: [
                {
                  name: 'Fixed',
                  value: 'fixed'
                },
                {
                  name: 'Static',
                  value: 'static'
                }
              ]
            },
            position: {
              title: 'Position',
              type: 'radio',
              options: [
                {
                  name: 'Above',
                  value: 'above'
                },
                {
                  name: 'Below',
                  value: 'below'
                }
              ]
            }
          }
        }
      }
    }
  };

  const getParsedQuerySettings = () => {
    const parsedQueryString = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    if (parsedQueryString && parsedQueryString.defaultSettings) {
      return JSON.parse(parsedQueryString.defaultSettings as string);
    }
    return {};
  };

  const getInitialSettings = () => {
    const layoutStyle = CommonDefaultLayoutConfig?.layout?.style ?? 'layout';

    const layout = {
      style: layoutStyle,
      config: layoutConfig[layoutStyle].defaults
    };
    return _.merge({}, CommonDefaultLayoutConfig, { layout }, getParsedQuerySettings());
  };

  const generateSettings = (defaultSettings, newSettings) => {
    return _.merge(
      {},
      defaultSettings,
      newSettings && newSettings.layout && newSettings.layout.style
        ? { layout: { config: layoutConfig[newSettings.layout.style].defaults } }
        : {},
      newSettings
    );
  };

  return { getInitialSettings, generateSettings };
};

export default useSettings;
