import { InputMode } from '@libs/common/v2';

import {
  AdditionalSectionLicenseOrPermissionType,
  ApplicationMetaFormDefinition
} from '@libs/domain/application/model';
import { MisspellApplicationPermissionTypeEnum } from '@libs/domain/application-misspell';

const definition: ApplicationMetaFormDefinition = {
  fields: [
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-submission-channel-key',
      properties: {
        label: 'Sposób wpływu',
        dictionaryName: 'APPLICATION_CHANNEL',
        optionsFilter: 'PB_33_13_APPLICATION_CHANNEL_KEY_FILTER',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'applicationChannelKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'applicationChannelKey'
          }
        },
        inputMode: InputMode.VIEW,
        defaultInitialValue: 'ibtm-domain.channel.internal'
      }
    },
    {
      typeKey: 'MISSPELL_APPLICATIONS_COLLECTION',
      fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-applications'
    },
    {
      typeKey: 'MISSPELL_APPLICATION_PERMISSION_COLLECTION',
      fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-licenses',
      properties: {
        permissionType: MisspellApplicationPermissionTypeEnum.LICENSE
      }
    },
    {
      typeKey: 'MISSPELL_APPLICATION_PERMISSION_COLLECTION',
      fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-licenseCopies',
      properties: {
        permissionType: MisspellApplicationPermissionTypeEnum.LICENSE_COPY
      }
    },
    {
      typeKey: 'MISSPELL_APPLICATION_PERMISSION_COLLECTION',
      fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-permissions',
      properties: {
        permissionType: MisspellApplicationPermissionTypeEnum.PERMISSION
      }
    },
    {
      typeKey: 'MISSPELL_APPLICATION_PERMISSION_COLLECTION',
      fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-permissionCopies',
      properties: {
        permissionType: MisspellApplicationPermissionTypeEnum.PERMISSION_COPY
      }
    }
  ],
  layout: [
    {
      typeKey: 'TAB_NAVIGATION',
      properties: {
        visibility: {
          elementVisibilityKeys: ['TAB_VISIBILITY']
        },
        defaultTabId: 'GENERAL',
        title: 'Sekcje',
        tabs: [
          {
            id: 'GENERAL',
            title: 'Dane podstawowe',
            icon: 'InfoIcon'
          },
          {
            id: 'SUBJECT',
            title: 'Podmiot',
            icon: 'FacadeIcon'
          },
          {
            id: 'ADDRESSES',
            title: 'Adresy',
            icon: 'MailboxIcon'
          },
          {
            id: 'PERMISSIONS',
            title: 'Uprawnienia',
            icon: 'KeyIcon'
          },
          {
            id: 'PROXIES',
            title: 'Pełnomocnicy',
            icon: 'GroupOfPeopleIcon'
          },
          {
            id: 'ATTACHMENTS',
            title: 'Załączniki',
            icon: 'PaperclipIcon'
          },
          {
            id: 'QUOTATION',
            title: 'Wycena',
            icon: 'CoinsIcon'
          },
          {
            id: 'OUTGOING_DOCUMENTS',
            title: 'Dokumenty wychodzące',
            icon: 'DocumentsIssuedIcon'
          },
          {
            id: 'NOTE',
            title: 'Notatka',
            icon: 'NoteIcon'
          },
          {
            id: 'FILES',
            title: 'Pliki',
            icon: 'FileEmptyIcon'
          },
          {
            id: 'HISTORY',
            title: 'Historia',
            icon: 'HistoryIcon'
          }
        ]
      },
      nodes: [
        {
          typeKey: 'TABS_CONTENT',
          nodes: [
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-general-tab',
                hiddenIds: ['court-decisions-judgments-section'],
                additionalNodes: {
                  'additional-section': [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-applications'
                      }
                    },

                    {
                      typeKey: 'COMPONENT',
                      properties: {
                        componentId: 'application-misspell-description-and-date-of-issue-section'
                      }
                    },
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-licenses'
                      }
                    },
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-licenseCopies'
                      }
                    },
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-permissions'
                      }
                    },
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-permissionCopies'
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-subject-tab',
                additionalNodes: {
                  'application-section-end': [
                    {
                      typeKey: 'COMPONENT',
                      properties: {
                        componentId: 'application-subject-print-data-license-section'
                      }
                    },
                    {
                      typeKey: 'COMPONENT',
                      properties: {
                        componentId: 'application-subject-print-data-permission-section'
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-addresses-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-permissions-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-proxies-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-attachments-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-quotation-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-outgoing-documents-tab',
                additionalNodes: {
                  'outgoing-documents-additional-section': [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'application-outgoing-documents-additional-section-license-or-permission',
                        sectionTypes: [
                          AdditionalSectionLicenseOrPermissionType.PERMISSION,
                          AdditionalSectionLicenseOrPermissionType.LICENSE
                        ]
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-note-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-files-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-history-tab'
              }
            }
          ]
        }
      ]
    }
  ]
};

export default definition;
