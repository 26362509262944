enum AlertUIElementEnum {
  NOTIFICATIONS_CONFIGURATION_LIST_TAB = 'NOTIFICATIONS_CONFIGURATION_LIST_TAB',
  NOTIFICATIONS_CONFIGURATION_ADD_BUTTON = 'NOTIFICATIONS_CONFIGURATION_ADD_BUTTON',
  NOTIFICATIONS_CONFIGURATION_DETAILS_BUTTON = 'NOTIFICATIONS_CONFIGURATION_DETAILS_BUTTON',
  NOTIFICATIONS_CONFIGURATION_EDIT_BUTTON = 'NOTIFICATIONS_CONFIGURATION_EDIT_BUTTON',
  NOTIFICATIONS_CONFIGURATION_DELETE_BUTTON = 'NOTIFICATIONS_CONFIGURATION_DELETE_BUTTON',
  NOTIFICATIONS_CONFIGURATION_EDIT_CONFIGURATION_STATUS_BUTTON = 'NOTIFICATIONS_CONFIGURATION_EDIT_CONFIGURATION_STATUS_BUTTON',
  NOTIFICATION_CHANEL_BELL_OPTION = 'NOTIFICATION_CHANEL_BELL_OPTION'
}

export default AlertUIElementEnum;
