import { createContext, useContext } from 'react';

import { ITabContext } from '@libs/common/v2';

export const TabContext = createContext<ITabContext<any>>({
  activeTabId: '',
  activeTabTitle: '',
  isHeaderHidden: false,
  setActiveTabId: () => {}
});

export const useTabContext = () => useContext(TabContext);
