import { PaginatedQueryConfig, usePaginatedQuery } from 'react-query';
import { ExploitationBasesSearchFilter, ExploitationBasesSnapshotPage } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import { ExploitationBaseQueryKeysEnum } from './ExploitationBaseQueryKeysEnum';

const getExploitationBases = async (_, params: ExploitationBasesSearchFilter) => {
  const { data } = await API.exploitationBase.getExploitationBasesSnapshotPage(params, {
    ...endpointsConfig.getExploitationBasesSnapshotPage
  });
  return data;
};

const getExploitationBasesClient = async (_, params: ExploitationBasesSearchFilter, isAssociation: boolean) => {
  let response: { data: ExploitationBasesSnapshotPage } = { data: { content: [] } };
  if (!isAssociation) {
    response = await API.client.exploitationBase.getExploitationBasesSnapshotPage('', '', params, {
      ...endpointsConfig.getExploitationBasesSnapshotPage
    });
  }

  return response?.data;
};

export default function useExploitationBasesQuery(
  params: ExploitationBasesSearchFilter,
  config: PaginatedQueryConfig<ExploitationBasesSnapshotPage, unknown>
) {
  const { getQuery } = useGetApiQueryByPerspective();

  return usePaginatedQuery<ExploitationBasesSnapshotPage>(
    [ExploitationBaseQueryKeysEnum.EXPLOITATION_BASE_LIST, params],
    getQuery(getExploitationBasesClient, getExploitationBases),
    config
  );
}
