import { useTranslation } from 'react-i18next';
import { ViolationSearchFilter } from '@ibtm/domain';

import { SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO, getCalendarDate } from '@libs/common/v2/utils';

import { UserFilterV2 } from '@libs/user';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { IViolationClient } from '../../models';

const useViolationColumns = () => {
  const [t] = useTranslation();
  const { createColumns } = useCreateColumns<IViolationClient>({
    pathToTranslate: 'roadTransport:fields'
  });

  const columns = createColumns([
    {
      accessor: 'referenceNumber',
      type: 'TEXT'
    },
    {
      accessor: 'caseNumber',
      type: 'TEXT'
    },
    {
      accessor: 'transferDate',
      type: 'DATE'
    },
    {
      accessor: 'subjectName',
      type: 'TEXT'
    },
    {
      accessor: 'nip',
      type: 'TEXT'
    },
    {
      accessor: 'manager',
      type: 'TEXT',
      width: 200
    },
    {
      header: 'certificateNumber',
      accessor: 'transportManagerCertificateNumber',
      type: 'TEXT'
    },
    {
      accessor: 'code',
      type: 'TEXT'
    },
    {
      accessor: 'status',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.PROCEEDING_STATUS_KREPTD,
      width: 250
    },
    {
      accessor: 'assignedTo.name',
      type: 'TEXT',
      header: 'assignedTo',
      filter: UserFilterV2,
      width: 200
    },
    {
      accessor: 'rwaCaseNumber',
      type: 'TEXT'
    },
    {
      accessor: 'pendingProceeding',
      type: 'BOOLEAN',
      tooltipTitle: ({ pendingProceeding }) => (pendingProceeding ? t('action.yes') : t('action.no')),
      header: 'proceedingInProgress',
      width: 200,
      isSortable: false
    }
  ]);

  const mappedFilterFields: TableFilterMapper<IViolationClient, ViolationSearchFilter> = {
    referenceNumber: (referenceNumber: string) => ({ numberContains: referenceNumber }),
    caseNumber: (caseNumber: string) => ({ caseNumberContains: caseNumber }),
    transferDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      transferDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      transferDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    transportManagerCertificateNumber: (transportManagerCertificateNumber: string) => ({
      transportManagerCertificateNumberContains: transportManagerCertificateNumber
    }),
    subjectName: (subjectName: string) => ({ subjectNameContains: subjectName }),
    nip: (nip: string) => ({ nipContains: nip }),
    manager: (manager: string) => ({ transportManagerNameContains: manager }),
    code: (code: string) => ({ codeContains: code }),
    status: (statuses: SelectOption<string>[]) => ({ statusIn: statuses.map(status => status.value) }),
    assignedToName: (assignedTo: SelectOption<string>[]) => ({
      assignedToIdIn: assignedTo.map(status => status.value)
    }),
    rwaCaseNumber: (rwaCaseNumber: string) => ({ rwaCaseNumberContains: rwaCaseNumber }),
    pendingProceeding: (pendingProceeding: SelectOption<boolean>) => ({ isPendingProceeding: pendingProceeding.value })
  };

  const mappedSortFields: TableSortMapper<IViolationClient> = {
    referenceNumber: 'number',
    subjectName: 'subject.name',
    status: 'statusKey',
    assignedToName: 'assignedTo.name',
    rwaCaseNumber: 'caseNumber',
    manager: 'transportManager.personalData.surname',
    transportManagerCertificateNumber: 'transportManager.certificate.number'
  };

  return { columns, mappedFilterFields, mappedSortFields };
};

export default useViolationColumns;
