import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PermissionSearchFilter, TransitStopPermission } from '@ibtm/domain';
import { DICTIONARY_IBTM_DOMAIN_PREFIX } from '@libs/config/constants';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import moment from 'moment';

import {
  CamelCasePath,
  ColumnTypesEnum,
  DatepickerField,
  DictionarySelectField,
  InputMode,
  ISingleColumn,
  SelectOption,
  TableFilterMapper,
  TableSortMapper,
  TextInputField,
  useCreateColumns
} from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { DateRangeISO, getCalendarDate, getValue, important } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';
import { UserFilterV2 } from '@libs/user';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { PermissionsColumnsEnum, PermissionSnapshotClient } from '../../model';

enum MigratoryFields {
  MIGRATION = 'MIGRACJA'
}

const columnDefaultProperties = { isFilterable: true, isSortable: true };

function usePermissionsColumns(
  visibleColumns: Array<CamelCasePath<PermissionSnapshotClient>>,
  editableColumns?: Array<CamelCasePath<PermissionSnapshotClient>>,
  isMutableTable?: boolean,
  subjectNameColumnHeader?: string,
  permissionNumberColumnHeader?: string,
  validFromColumnHeader?: string,
  dateFromAndToWithoutChips?: boolean
) {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();
  const classes = useStyles();

  const translateIssuingAuthority = row => {
    if (row?.startsWith(DICTIONARY_IBTM_DOMAIN_PREFIX)) {
      return translate(DomainDictionaryEnum.AUTHORITY, row, t('emptyMark'));
    }

    return getValue(row);
  };

  const translateTransitStop = (transitStops: Array<TransitStopPermission>): string => {
    if (_.isEmpty(transitStops)) {
      return t('emptyMark');
    }

    const translatedTransitStops = transitStops.map(transitStop => ({
      city: translate(DomainDictionaryEnum.TRANSIT_LOCATION, transitStop.city, transitStop.city),
      countryKey: translate(DomainDictionaryEnum.COUNTRY_CODE, transitStop.countryKey)
    }));

    const parsedTranslatedTransitStop = translatedTransitStops
      .map(transitStop => `${transitStop.city} - ${transitStop.countryKey}`)
      .join(', ');

    return parsedTranslatedTransitStop;
  };

  const { createColumns } = useCreateColumns<PermissionSnapshotClient>({
    pathToTranslate: 'permission:field',
    isMutableTable
  });

  const mappedFilterFields: TableFilterMapper<PermissionSnapshotClient, PermissionSearchFilter> = {
    applicationNumber: (applicationNumber: string) => ({ applicationNumberContains: applicationNumber }),
    permissionNumber: (permissionNumber: string) => ({ numberContains: permissionNumber }),
    formNumber: (formNumber: string) => ({ formNumberContains: formNumber }),
    certificateNumber: (certificateNumber: string) => ({ numberContains: certificateNumber }),
    dateOfIssue: ({ dateFrom, dateTo }: DateRangeISO) => ({
      dateOfIssueGreaterThanOrEqual: getCalendarDate(dateFrom),
      dateOfIssueLessThanOrEqual: getCalendarDate(dateTo)
    }),
    dateOfIssueGitd: ({ dateFrom, dateTo }: DateRangeISO) => ({
      validFromGreaterThanOrEqual: getCalendarDate(dateFrom),
      validFromLessThanOrEqual: getCalendarDate(dateTo)
    }),
    validFrom: ({ dateFrom, dateTo }: DateRangeISO) => ({
      validFromGreaterThanOrEqual: getCalendarDate(dateFrom),
      validFromLessThanOrEqual: getCalendarDate(dateTo)
    }),
    validTo: ({ dateFrom, dateTo }: DateRangeISO) => ({
      validToGreaterThanOrEqual: getCalendarDate(dateFrom),
      validToLessThanOrEqual: getCalendarDate(dateTo)
    }),
    signerName: (signers: SelectOption<string>[]) => ({ signerIn: signers.map(signer => signer.value) }),
    status: (statuses: SelectOption<string> | SelectOption<string>[]) => ({
      statusIn: Array.isArray(statuses) ? statuses.map(status => status.value) : [statuses.value]
    }),
    issuingCountryKey: (countries: SelectOption<string>[]) => ({
      issuingCountryIn: countries.map(country => country.value)
    }),
    dateOfIssueExternal: ({ dateFrom, dateTo }: DateRangeISO) => ({
      dateOfIssueExternalGreaterThanOrEqual: getCalendarDate(dateFrom),
      dateOfIssueExternalLessThanOrEqual: getCalendarDate(dateTo)
    }),
    cancelledBy: (cancelledBy: SelectOption<string>[]) => ({ cancellationByIn: cancelledBy.map(({ value }) => value) }),
    cancellationReason: (cancellationReasons: SelectOption<string>[]) => ({
      cancellationReasonIn: cancellationReasons.map(({ value }) => value)
    }),
    cancellationDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      cancellationDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      cancellationDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    subjectName: (subjectName: string) => ({ applicationSubjectNameContains: subjectName }),
    subjectAddress: (subjectAddress: string) => ({ subjectAddressContains: subjectAddress }),
    printDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      printDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      printDateLessThanOrEqual: getCalendarDate(dateTo)
    })
  };

  const mappedSortFields: TableSortMapper<PermissionSnapshotClient> = {
    permissionNumber: 'number',
    formNumber: 'form.number',
    cancellationReason: 'cancellationReasonKey',
    certificateNumber: 'number',
    signerName: 'signer.name',
    status: 'statusKey',
    dateOfIssueGitd: 'validFrom',
    issuingCountryKey: 'issuingCountryKey',
    subjectAddress: 'application.subject.mainAddress.shortAddress',
    subjectName: 'folder.subject.name',
    dateOfIssueExternal: 'dateOfIssue'
  };

  const defaultColumns: ISingleColumn<PermissionSnapshotClient>[] = useMemo(
    () => [
      {
        accessor: 'applicationNumber',
        type: ColumnTypesEnum.TEXT,
        ...columnDefaultProperties
      },
      {
        accessor: 'certificateNumber',
        type: ColumnTypesEnum.TEXT,
        ...columnDefaultProperties
      },
      {
        accessor: 'cancellationReason',
        type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
        dictionaryName: DomainDictionaryEnum.PERMISSION_CANCELLATION_REASON,
        customXlsxAccessor(row) {
          return translate(DomainDictionaryEnum.PERMISSION_CANCELLATION_REASON, row.cancellationReason, t('emptyMark'));
        },
        ...columnDefaultProperties
      },
      {
        accessor: 'cancelledBy',
        type: ColumnTypesEnum.TEXT,
        filter: UserFilterV2,
        ...columnDefaultProperties
      },
      {
        accessor: 'cancellationDate',
        type: ColumnTypesEnum.DATE,
        ...columnDefaultProperties
      },
      {
        id: 'permissionNumber',
        header: permissionNumberColumnHeader || 'permissionNumber',
        type: ColumnTypesEnum.TEXT,
        customAccessor: row =>
          row?.permissionNumber?.includes(MigratoryFields.MIGRATION) ? t('emptyMark') : getValue(row?.permissionNumber),
        ...columnDefaultProperties
      },
      {
        accessor: 'subjectAddress',
        type: ColumnTypesEnum.TEXT,
        ...columnDefaultProperties
      },
      {
        accessor: 'subjectNip',
        type: ColumnTypesEnum.TEXT,
        ...columnDefaultProperties
      },
      {
        accessor: 'formNumber',
        type: ColumnTypesEnum.TEXT,
        ...columnDefaultProperties
      },
      {
        id: 'subjectName',
        header: subjectNameColumnHeader || 'subjectName',
        type: ColumnTypesEnum.TEXT,
        customAccessor: row =>
          row?.subjectName?.includes(MigratoryFields.MIGRATION) ? t('emptyMark') : getValue(row?.subjectName),
        ...columnDefaultProperties
      },
      {
        accessor: 'dateOfIssue',
        type: ColumnTypesEnum.DATE,
        ...columnDefaultProperties
      },
      {
        id: 'dateOfIssueGitd',
        accessor: 'validFrom',
        type: ColumnTypesEnum.DATE,
        ...columnDefaultProperties
      },
      {
        id: 'dateOfIssueExternal',
        type: ColumnTypesEnum.DATE,
        customAccessor: row => (row.dateOfIssueExternal ? row.dateOfIssueExternal : row.dateOfIssue),
        ...columnDefaultProperties
      },
      {
        accessor: 'permissionValidityPeriodKey',
        type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
        dictionaryName: DomainDictionaryEnum.DURATION_OF_PERMIT,
        isFilterable: false,
        isSortable: false
      },
      {
        accessor: 'validFrom',
        header: validFromColumnHeader || 'validFrom',
        type: ColumnTypesEnum.DATE,
        mutableTableInputProps(row) {
          const isSameOrBefore = moment(row?.validFrom).isSameOrBefore(moment());
          const chipColor = isSameOrBefore ? 'primary' : 'error';
          const chipColorWeight = isSameOrBefore ? '700' : '800';
          return { isChipValue: !dateFromAndToWithoutChips, chipColor, chipColorWeight };
        },
        customEdit: (_inputMode: InputMode, original, inputName) => {
          return (
            <DatepickerField
              textInputClassName={classes.text}
              valueClassName={classes.value}
              name={inputName}
              initialValue={original?.validFrom || moment().format('YYYY-MM-DD')}
              hasErrorTooltip
            />
          );
        },
        ...columnDefaultProperties
      },
      {
        accessor: 'validTo',
        type: ColumnTypesEnum.DATE,
        mutableTableInputProps(row) {
          const isSameOrBefore = moment(row?.validFrom).isSameOrBefore(moment());
          const chipColor = isSameOrBefore ? 'primary' : 'error';
          const chipColorWeight = isSameOrBefore ? '700' : '800';
          return { isChipValue: !dateFromAndToWithoutChips, chipColor, chipColorWeight };
        },
        ...columnDefaultProperties
      },
      {
        id: 'transitStopStartTour',
        customAccessor: row => translateTransitStop(row?.transitStopStartTour),
        type: ColumnTypesEnum.TEXT,
        isFilterable: false,
        isSortable: false
      },
      {
        id: 'transitStopEndTour',
        customAccessor: row => translateTransitStop(row?.transitStopEndTour),
        type: ColumnTypesEnum.TEXT,
        isFilterable: false,
        isSortable: false
      },
      {
        accessor: 'signerName',
        type: ColumnTypesEnum.MULTI_SELECT,
        filter: UserFilterV2,
        ...columnDefaultProperties
      },
      {
        accessor: 'status',
        type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
        dictionaryName: DomainDictionaryEnum.PERMISSION_STATUS,
        ...columnDefaultProperties
      },
      {
        accessor: 'printDate',
        type: ColumnTypesEnum.DATE,
        ...columnDefaultProperties
      },
      {
        accessor: 'issuingCountryKey',
        type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
        dictionaryName: DomainDictionaryEnum.COUNTRY_CODE,
        customXlsxAccessor(row) {
          return translate(DomainDictionaryEnum.COUNTRY_CODE, row.issuingCountryKey, t('emptyMark'));
        },
        ...columnDefaultProperties
      },
      {
        id: 'issuingAuthority',
        type: ColumnTypesEnum.CUSTOM_COLUMN,
        dictionaryName: DomainDictionaryEnum.AUTHORITY,
        customEdit: (_inputMode: InputMode, original, inputName) => (
          <div style={{ width: '100%' }}>
            {original['issuingAuthority'].startsWith(DICTIONARY_IBTM_DOMAIN_PREFIX) ? (
              <DictionarySelectField
                name={inputName}
                inputMode={_inputMode}
                className={classes.select}
                valueClassName={classes.value}
                dictionaryName={DomainDictionaryEnum.AUTHORITY}
                isMultiple={false}
                hasErrorTooltip
              />
            ) : (
              <TextInputField
                name={inputName}
                inputMode={_inputMode}
                className={classes.text}
                valueClassName={classes.value}
                hasErrorTooltip
              />
            )}
          </div>
        ),
        customAccessor: row => {
          return translateIssuingAuthority(row?.issuingAuthority);
        },
        customXlsxAccessor: row => {
          return translateIssuingAuthority(row?.issuingAuthority);
        },
        isFilterable: false
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, translate]
  );

  const mappedColumns = useMemo(
    () =>
      defaultColumns.map(column => {
        const columnIdentifier = (column.id || column.accessor) as CamelCasePath<PermissionSnapshotClient>;
        return { ...column, isEditable: editableColumns?.includes(columnIdentifier) };
      }),
    [defaultColumns, editableColumns]
  );

  const columns = createColumns(mappedColumns, visibleColumns);

  return { columns, mappedFilterFields, mappedSortFields };
}

export const PERMISSIONS_DEFAULT_COLUMNS: Array<CamelCasePath<PermissionSnapshotClient>> = [
  PermissionsColumnsEnum.APPLICATION_NUMBER,
  PermissionsColumnsEnum.PERMISSION_NUMBER,
  PermissionsColumnsEnum.FORM_NUMBER,
  PermissionsColumnsEnum.DATE_OF_ISSUE,
  PermissionsColumnsEnum.VALID_FROM,
  PermissionsColumnsEnum.VALID_TO,
  PermissionsColumnsEnum.SIGNER_NAME,
  PermissionsColumnsEnum.STATUS
];

const useStyles = makeStyles<Theme>(theme => ({
  value: {
    overflow: 'hidden',
    '& .field-value': {
      ...theme.typography.textXs.normal,
      fontSize: important(theme.typography.textSm.normal.fontSize),
      fontWeight: important(theme.typography.textSm.normal.fontWeight),
      lineHeight: important(theme.typography.textSm.normal.lineHeight),
      color: important(theme.palette.grey[700])
    }
  },
  text: {
    '& > div > div ': {
      padding: important(0),
      marginTop: important(0),
      '& > input': {
        padding: important('8px 0 8px 8px'),
        fontSize: important(theme.typography.textSm.normal.fontSize),
        fontWeight: important(theme.typography.textSm.normal.fontWeight),
        lineHeight: important(theme.typography.textSm.normal.lineHeight),
        color: important(theme.palette.grey[700])
      }
    },
    '& > p': {
      whiteSpace: 'pre-wrap',
      marginBottom: 0,
      overflow: 'hidden',
      wordBreak: 'break-word',
      height: 65
    }
  }
}));

export default usePermissionsColumns;
