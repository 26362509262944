import DomainDictionaryEntry from '../../DomainDictionaryEntry';
import DomainUIElementEnum from '../DomainUIElementEnum';

import FolderElementsPermissionsEnum from './FolderElementsPermissionsEnum';

export const FOLDER_ELEMENTS_PERMISSIONS = {
  [FolderElementsPermissionsEnum.FOLDER_EDIT_PERMISSIONS]: {
    [DomainDictionaryEntry.FOLDER_TYPE.KS]: DomainUIElementEnum.FOLDER_KS_EDIT_BUTTON,
    [DomainDictionaryEntry.FOLDER_TYPE.MZ]: DomainUIElementEnum.FOLDER_MZ_EDIT_BUTTON,
    [DomainDictionaryEntry.FOLDER_TYPE.OP]: DomainUIElementEnum.FOLDER_OP_EDIT_BUTTON,
    [DomainDictionaryEntry.FOLDER_TYPE.PA]: DomainUIElementEnum.FOLDER_PA_EDIT_BUTTON,
    [DomainDictionaryEntry.FOLDER_TYPE.PPO]: DomainUIElementEnum.FOLDER_PPO_EDIT_BUTTON,
    [DomainDictionaryEntry.FOLDER_TYPE.TP]: DomainUIElementEnum.FOLDER_TP_EDIT_BUTTON,
    [DomainDictionaryEntry.FOLDER_TYPE.WITD]: DomainUIElementEnum.FOLDER_WITD_EDIT_BUTTON,
    [DomainDictionaryEntry.FOLDER_TYPE.ZZ]: DomainUIElementEnum.FOLDER_ZZ_EDIT_BUTTON
  },
  [FolderElementsPermissionsEnum.FOLDER_VIEW_PERMISSIONS]: {
    [DomainDictionaryEntry.FOLDER_TYPE.KS]: DomainUIElementEnum.FOLDER_KS_VIEW_BUTTON,
    [DomainDictionaryEntry.FOLDER_TYPE.MZ]: DomainUIElementEnum.FOLDER_MZ_VIEW_BUTTON,
    [DomainDictionaryEntry.FOLDER_TYPE.OP]: DomainUIElementEnum.FOLDER_OP_VIEW_BUTTON,
    [DomainDictionaryEntry.FOLDER_TYPE.PA]: DomainUIElementEnum.FOLDER_PA_VIEW_BUTTON,
    [DomainDictionaryEntry.FOLDER_TYPE.PPO]: DomainUIElementEnum.FOLDER_PPO_VIEW_BUTTON,
    [DomainDictionaryEntry.FOLDER_TYPE.TP]: DomainUIElementEnum.FOLDER_TP_VIEW_BUTTON,
    [DomainDictionaryEntry.FOLDER_TYPE.WITD]: DomainUIElementEnum.FOLDER_WITD_VIEW_BUTTON,
    [DomainDictionaryEntry.FOLDER_TYPE.ZZ]: DomainUIElementEnum.FOLDER_ZZ_VIEW_BUTTON
  },
  [FolderElementsPermissionsEnum.FOLDER_BASIC_DATA_VIEW_PERMISSIONS]: {
    [DomainDictionaryEntry.FOLDER_TYPE.KS]: DomainUIElementEnum.FOLDER_KS_BASIC_DATA_VIEW,
    [DomainDictionaryEntry.FOLDER_TYPE.ZZ]: DomainUIElementEnum.FOLDER_ZZ_BASIC_DATA_VIEW,
    [DomainDictionaryEntry.FOLDER_TYPE.WITD]: DomainUIElementEnum.FOLDER_WITD_BASIC_DATA_VIEW,
    [DomainDictionaryEntry.FOLDER_TYPE.MZ]: DomainUIElementEnum.FOLDER_MZ_BASIC_DATA_VIEW
  },
  [FolderElementsPermissionsEnum.FOLDER_PERMISSION_VIEW_PERMISSIONS]: {
    [DomainDictionaryEntry.FOLDER_TYPE.MZ]: DomainUIElementEnum.FOLDER_MZ_PERMISSION_VIEW
  }
};

export default FOLDER_ELEMENTS_PERMISSIONS;
