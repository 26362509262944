import { useEffect } from 'react';

import { useMetaFormContext } from '@libs/meta-form/context';

function useDiscardChangesCustomFunctions(fieldId: string, customDiscardChangesFunction: () => void) {
  const { additionalDiscardFunctions } = useMetaFormContext();
  additionalDiscardFunctions.current = { [fieldId]: customDiscardChangesFunction };

  useEffect(
    () => () => {
      delete additionalDiscardFunctions.current[fieldId];
    },
    [fieldId, additionalDiscardFunctions]
  );
}

export default useDiscardChangesCustomFunctions;
