import { QueryConfig, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { API } from '@libs/domain/api';

import { IRegonData, RegonDataModel } from '../../model/regon.model';
import { parseRegonData } from '../../utils/regon.util';

import SubjectQueryKeysEnum from './SubjectQueryKeysEnum';

export const getRegonData = async (nip: string) => {
  const response = (await API.regon(nip)) as AxiosResponse<IRegonData>;
  return parseRegonData(response.data);
};

const useRegonDataQuery = (nip: string, queryConfig: QueryConfig<any, unknown> = {}) =>
  useQuery<RegonDataModel>([SubjectQueryKeysEnum.REGON_DATA, nip], () => getRegonData(nip), queryConfig);

export default useRegonDataQuery;
