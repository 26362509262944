import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { FolderExtendedBorrowerLite } from '@ibtm/domain';

import {
  Dialog,
  FormV2Context,
  FormV2ContextState,
  GridLayout,
  SelectOption,
  typedNameV2,
  useDialog,
  useFormV2Context,
  useFormV2Watch
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import FolderSelectField from '../../../application/components/application-create/FolderSelectField';
import { SubjectQueryKeysEnum } from '../../../subject/api/queries';
import { useAddSubjectTOpartnerShipMutation } from '../../api/mutations';

type FormData = {
  folders: SelectOption<FolderExtendedBorrowerLite>[];
};

function AddSubjectToPartnershipDialog({ subjectId, closeDialog }: { subjectId: string; closeDialog: () => void }) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  const { handleSubmit } = useFormV2Context();
  const { mutateAsync } = useAddSubjectTOpartnerShipMutation({
    onSuccess: () => {
      showSuccessSnackbar(t('folder:details.message.foldersLinkSuccess'));
      closeDialog();
      queryCache.invalidateQueries(SubjectQueryKeysEnum.SUBJECT_EXTENDED);
    }
  });

  const submit = handleSubmit(v => {
    const values = v as FormData;
    return mutateAsync({
      subjectId,
      data: {
        subjectIds: values.folders.map(f => f.value.subject.id)
      }
    });
  });

  const selectedFolders = useFormV2Watch({ name: 'folders' });
  const isEmpty = !selectedFolders?.length;

  return (
    <Dialog
      isOpen
      title={t('folder:selectFoldersToLinkToPartnershipDialogTitle')}
      onConfirm={async setLoading => {
        setLoading(true);
        await submit();
        setLoading(false);
      }}
      onClose={closeDialog}
      onCancel={closeDialog}
      isConfirmButtonDisabled={isEmpty}
      confirmText={t('action.link')}
    >
      <form>
        <GridLayout itemProps={{ xs: 12 }}>
          <FolderSelectField
            fieldName={typedNameV2<FormData>('folders')}
            label={t('folder:field.folderNumbers')}
            isRequired
            isMultiple
          />
        </GridLayout>
      </form>
    </Dialog>
  );
}

export const useAddSubjectToPartnerShipDialog = () => {
  const { openDialog } = useDialog();
  const formMethods = useForm<FormData>();
  return {
    openAddSubjectToPartnershipDialog: (subjectId: string) =>
      openDialog(({ closeDialog }) => (
        <FormV2Context.Provider value={formMethods as unknown as FormV2ContextState}>
          <AddSubjectToPartnershipDialog subjectId={subjectId} closeDialog={closeDialog} />
        </FormV2Context.Provider>
      ))
  };
};
