import { LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Theme } from '@libs/common/v2/theme';

interface IProps {
  className?: string;
  isLoading?: boolean;
  isDisabledDefaultStyles?: boolean;
}

function LoaderLinear({ className, isLoading, isDisabledDefaultStyles }: IProps) {
  const classes = useStyles();
  if (isLoading) {
    return <LinearProgress className={clsx(classes.loader, className, isDisabledDefaultStyles ? '' : 'h-1 w-full')} />;
  }
  return null;
}

const useStyles = makeStyles<Theme>(theme => ({
  loader: {
    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.palette.blue[500]
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.blue[200]
    }
  }
}));

export default LoaderLinear;
