export enum WarningEnumKeys {
  IS_FOLDER_NOT_LICENSE_DATE_MORE_THAN_10Y = 'IS_FOLDER_NOT_LICENSE_DATE_MORE_THAN_10Y',
  IS_LICENSE_COPY_BEFORE_VALID = 'IS_LICENSE_COPY_BEFORE_VALID',
  IS_APPLICATION_HAS_ACTIVE_LICENSE = 'IS_APPLICATION_HAS_ACTIVE_LICENSE',
  IS_APPLICATION_HAS_ATLEAST_ONE_ACTIVE_VEHICLE = 'IS_APPLICATION_HAS_ATLEAST_ONE_ACTIVE_VEHICLE',
  IS_APPLICATION_LICENSE_MORE_THAN_VEHICLE = 'IS_APPLICATION_LICENSE_MORE_THAN_VEHICLE',
  IS_SUBJECT_HAS_ACTIVE_PERMISSIONS = 'IS_SUBJECT_HAS_ACTIVE_PERMISSIONS',
  IS_DATE_OF_FOLDER_SUBJECT_DEATH_PAST_6MONTHS = 'IS_DATE_OF_FOLDER_SUBJECT_DEATH_PAST_6MONTHS',
  IS_DEADLINE_DATE_EXPIRED = 'IS_DEADLINE_DATE_EXPIRED',
  IS_FOLDER_HAS_NOT_ACTIVE_LICENSE = 'IS_FOLDER_HAS_NOT_ACTIVE_LICENSE',
  IS_FOLDER_HAS_NOT_ARCHIVE_NUMBER = 'IS_FOLDER_HAS_NOT_ARCHIVE_NUMBER',
  IS_APPLICATION_ALREADY_IN_PROGRESS_FOR_GIVEN_DRIVER = 'IS_APPLICATION_ALREADY_IN_PROGRESS_FOR_GIVEN_DRIVER',
  IS_SUCCESSOR_HAS_ACTIVE_LICENSE = 'IS_SUCCESSOR_HAS_ACTIVE_LICENSE',
  IS_PARTNER_HAS_ACTIVE_PERMISSION = 'IS_PARTNER_HAS_ACTIVE_PERMISSION',
  IS_PARTNER_HAS_ACTIVE_LICENSE = 'IS_PARTNER_HAS_ACTIVE_LICENSE',
  IS_MORE_THAN_TWO_MONTHS_AFTER_DOCUMENT_RECEIPT_ACK_DATE = 'IS_MORE_THAN_TWO_MONTHS_AFTER_DOCUMENT_RECEIPT_ACK_DATE',
  IS_APPLICATION_ARCHIVED = 'IS_APPLICATION_ARCHIVED',
  IS_APPLICATION_PASSED_TO_FOREIGN_PERMISSION_DEPARTMENT = 'IS_APPLICATION_PASSED_TO_FOREIGN_PERMISSION_DEPARTMENT'
}
