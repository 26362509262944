import { DeprecatedThemeOptions } from '@mui/material';
import { createTheme as createMuiTheme } from '@mui/material/styles';
import _ from 'lodash';

import { CommonDefaultThemeOptions, CommonMustHaveThemeOptions } from '@libs/common/v2/configs/layout.config';

export function extendThemeWithMixins(obj: DeprecatedThemeOptions) {
  const theme = createMuiTheme(obj);
  return {
    border: (width = 1) => ({
      borderWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette?.divider
    }),
    borderLeft: (width = 1) => ({
      borderLeftWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette?.divider
    }),
    borderRight: (width = 1) => ({
      borderRightWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette?.divider
    }),
    borderTop: (width = 1) => ({
      borderTopWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette?.divider
    }),
    borderBottom: (width = 1) => ({
      borderBottomWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette?.divider
    })
  };
}

export function mainThemeVariations(theme) {
  return {
    mainThemeDark: createMuiTheme(
      _.merge({}, CommonDefaultThemeOptions, theme, { palette: { mode: 'dark' }, ...CommonMustHaveThemeOptions })
    ),
    mainThemeLight: createMuiTheme(
      _.merge({}, CommonDefaultThemeOptions, theme, { palette: { mode: 'light' }, ...CommonMustHaveThemeOptions })
    )
  };
}
