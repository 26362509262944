import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { CommitteeFileDetails } from '@ibtm/domain';

import { Button, useConfirmDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useDownloadQuery, useFileDownload } from '@libs/file';
import { UIElementNameEnum } from '@libs/permission';

import {
  SocialCommissionQueryKeysEnum,
  useExportFileForEkmtCommitteeMutation,
  useExportFileForSingleUseCommitteeMutation
} from '@libs/domain/social-commission';

function ExportCommitteeFileButton({
  isEkmtCommittee,
  year,
  actionKey
}: {
  isEkmtCommittee?: boolean;
  year?: string;
  actionKey?: UIElementNameEnum;
}) {
  const [t] = useTranslation();
  const [exportFileData, setExportFileData] = useState<CommitteeFileDetails>(null);
  const queryCache = useQueryCache();

  const { download } = useFileDownload();
  const { showSuccessSnackbar } = useSnackbar();
  const [confirm] = useConfirmDialog();

  const downloadQuery = useDownloadQuery({ fileId: exportFileData?.id }, { enabled: Boolean(exportFileData) });

  useEffect(() => {
    if (downloadQuery?.data) {
      download(
        () =>
          new Promise(res => {
            res(downloadQuery?.data);
          })
      );
      setExportFileData(null);
      showSuccessSnackbar(t('foreignPermits:other.exportSuccess'));
      queryCache.invalidateQueries([SocialCommissionQueryKeysEnum.EKMT_DETAILS]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [download, downloadQuery]);

  const { mutateAsync: exportEkmtFile } = useExportFileForEkmtCommitteeMutation();
  const { mutateAsync: exportSingleUseFile } = useExportFileForSingleUseCommitteeMutation();

  const handleExportEkmtFile = async () => {
    const exportFileResponse = await exportEkmtFile(Number(year));
    setExportFileData(exportFileResponse?.data?.file);
  };

  const handleExportSingleUseFile = async () => {
    const exportFileResponse = await exportSingleUseFile();
    setExportFileData(exportFileResponse?.data?.file);
  };

  return (
    <Button
      variant="outlined"
      onClick={() =>
        confirm({
          title: t('foreignPermits:tabs.socialCommission.tabs.socialCommissionList.dialog.exportFile.title'),
          message: t('foreignPermits:tabs.socialCommission.tabs.socialCommissionList.dialog.exportFile.description'),
          confirmText: t('action.export'),
          onConfirm: async (setLoading, closeDialog) => {
            setLoading(true);
            if (isEkmtCommittee) {
              await handleExportEkmtFile();
            } else {
              await handleExportSingleUseFile();
            }
            closeDialog();
          }
        })
      }
      label={isEkmtCommittee ? t('foreignPermits:other.exportFile') : t('foreignPermits:other.exportFile')}
      actionKey={actionKey}
    />
  );
}

export default ExportCommitteeFileButton;
