import { Info } from '@mui/icons-material';
import { FormHelperText, TextareaAutosize, TextareaAutosizeProps, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Theme } from '@libs/common/v2/theme';

interface Props {
  tooltip?: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  isWarning?: boolean;
  isLabelHidden?: boolean;
}

function TextArea({
  tooltip,
  label,
  helperText,
  isLabelHidden,
  isWarning,
  className,
  ...rest
}: Props & TextareaAutosizeProps) {
  const { required: isRequired, error: isError } = rest;
  const classes = useStyles({ isWarning });

  return (
    <div className={classes.root}>
      <div className={classes.labelWrapper}>
        {!isLabelHidden && (
          <span>
            {label} {isRequired && <span className={classes.asterisk}>&nbsp;*</span>}
          </span>
        )}
        {tooltip && (
          <Tooltip title={tooltip} placement="top" arrow data-testid={`${label}-${tooltip}`}>
            <Info className={classes.tooltipIcon} />
          </Tooltip>
        )}
      </div>
      <TextareaAutosize
        className={clsx(classes.textArea, className)}
        {...(typeof label === 'string' && { 'aria-label': label })}
        {...rest}
      />
      {helperText && (
        <FormHelperText className={clsx(classes.formHelperText)} error={isError}>
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
}

const useStyles = makeStyles<Theme, { isWarning?: boolean }>(theme => ({
  root: {
    display: 'grid',
    flex: '1 1 0%',
    rowGap: '4px',
    overflow: 'unset'
  },
  textArea: {
    fontFamily: 'inherit',
    fontSize: theme.typography.getFontSize(16),
    height: '100%',
    padding: '10px 14px',
    borderRadius: theme.borderRadiusBase,
    border: `1px solid ${theme.palette.grey[300]}`,
    outline: 'none',
    '&:active': {
      border: `1px solid ${theme.palette.grey[300]}`
    },
    boxShadow: theme.shadowsList.xs,
    '&:not([disabled]):hover': {
      boxShadow: theme.shadowsList.inputHover,
      '&:focus': {
        boxShadow: theme.shadowsList.inputFocus,
        border: `1px solid ${theme.palette.blue[300]}`
      }
    },
    '&:focus': {
      boxShadow: theme.shadowsList.inputFocus,
      border: `1px solid ${theme.palette.blue[300]}`
    }
  },
  asterisk: {
    color: theme.palette.error[500]
  },
  tooltipIcon: {
    width: 16,
    height: 16,
    marginLeft: 5,
    color: theme.palette.blue[500]
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  formHelperText: {
    ...theme.typography.textSm.normal,
    wordWrap: 'break-word',
    whiteSpace: 'break-spaces',
    margin: `6px 0`,
    color: ({ isWarning }) => (isWarning ? theme.palette.warning[600] : theme.palette.grey[600]),
    '&.Mui-error': {
      color: theme.palette.error[800]
    }
  }
}));

export default TextArea;
