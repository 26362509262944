import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { PermissionsGenerationDetails } from '@ibtm/domain';
import { DomainErrorCodes } from '@libs/config';
import { MenuItem, Tooltip } from '@mui/material';

import { Button, useConfirmDialog, useTableContext } from '@libs/common/v2';

import { UIElementNameEnum, useElementVisibility } from '@libs/permission';

import { useApplicationFlagsQuery } from '@libs/domain/application/api';

import { DomainDictionaryEntry } from '../../../../config';
import { useGenerateResourceAssignmentMutation } from '../../../api';
import useFetchPrintsApplicationDetails from '../../../hooks/useFetchPrintsApplicationDetails';

interface IProps {
  depotId: string;
  depotTooltip: string;
  permissionGeneratedTooltip?: string;
  title: string;
  onClose?: () => void;
  actionKey?: UIElementNameEnum;
  assignmentTypeKey?: string;
  permissionType?: keyof PermissionsGenerationDetails;
  onSuccess?: () => void;
  customGenerateAction?: () => void;
  isCustomActionLoading?: boolean;
}

function GeneratePermissionButton({
  depotId,
  depotTooltip,
  permissionGeneratedTooltip,
  title,
  onClose,
  actionKey,
  assignmentTypeKey,
  permissionType,
  onSuccess,
  customGenerateAction,
  isCustomActionLoading
}: IProps) {
  const {
    applicationData,
    applicationSnapshot,
    query: { refetch: refetchPrintsApplicationDetails }
  } = useFetchPrintsApplicationDetails();
  const { checkIsElementVisible } = useElementVisibility();
  const { refetch } = useTableContext();
  const [confirmDialog] = useConfirmDialog();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbar();

  const [t] = useTranslation();

  const onSuccesGenerateResourceAssignment = async () => {
    refetch?.();
    onSuccess?.();

    const data = await refetchPrintsApplicationDetails();
    if (!data?.permissionsGenerationDetails[permissionType]) {
      showErrorSnackbar(t('prints:messages.notEnoughFormsForWholePrint'));
      return;
    }

    showSuccessSnackbar(t('prints:messages.generationSuccessMessage'));
  };

  const { mutateAsync: generateAssignment, isLoading } = useGenerateResourceAssignmentMutation(
    () => onSuccesGenerateResourceAssignment(),
    false
  );
  const { isFetching: areFlagsFetching, refetch: fetchApplicationFlags } = useApplicationFlagsQuery(
    applicationData?.id,
    {
      enabled: false
    }
  );

  const isPermissionGenerated = applicationData?.permissionsGenerationDetails?.[permissionType];
  const isDisabled = Boolean(isPermissionGenerated || !depotId || !applicationData?.id);

  const isElementVisible = useMemo(() => {
    return actionKey ? checkIsElementVisible(actionKey) : true;
  }, [checkIsElementVisible, actionKey]);

  const disabledTooltip = useMemo(() => {
    if (isPermissionGenerated) {
      return permissionGeneratedTooltip || t('prints:messages.permissionsAlreadyGenerated');
    }
    return depotTooltip;
  }, [depotTooltip, isPermissionGenerated, permissionGeneratedTooltip, t]);

  if (!isElementVisible) {
    return null;
  }

  const checkIsActiveLicenseOnFolder = async (cancelGeneration: () => void) => {
    let hasInactiveLicense = false;
    try {
      const { hasActiveLicense } = await fetchApplicationFlags({ throwOnError: true });
      hasInactiveLicense = !hasActiveLicense;
    } catch (e) {
      cancelGeneration();
    }
    return new Promise(resolve => {
      if (hasInactiveLicense) {
        confirmDialog({
          title: t('prints:messages.inactiveLicenseOnFolder'),
          message: t('prints:messages.shouldAddCertificateToFolderWithInactiveLicense'),
          onConfirm: async (_setLoading, closeDialog) => {
            closeDialog();
            resolve(undefined);
          },
          onCancel: () => {
            cancelGeneration();
            resolve(undefined);
          }
        });
      } else {
        resolve(undefined);
      }
    });
  };
  const handleGenerateError = (error: { response?: { data?: { codes?: string[] } } }) => {
    if (error?.response?.data?.codes?.includes(DomainErrorCodes.DRIVER_POSSES_ACTIVE_DRIVER_CERTIFICATE)) {
      confirmDialog({
        title: t('prints:actions.driverCertificateAlreadyGenerated'),
        message: t('prints:messages.driverCertificateAlreadyGenerated'),
        onConfirm: async (_setLoading, closeDialog) => {
          if (customGenerateAction) {
            customGenerateAction();
          } else {
            generateAssignment({
              applicationId: applicationData?.id,
              assignmentTypeKey,
              depotId,
              ignoreDriverCurrentCertificate: true
            });
          }

          closeDialog();
        }
      });
    }
  };

  const handleClickGenerateButton = async () => {
    let shouldGenerate = true;
    const ignoreDriverCurrentCertificate = [
      DomainDictionaryEntry.APPLICATION_TYPE.issuingDuplicateDriverCertificate,
      DomainDictionaryEntry.APPLICATION_TYPE.changeOfDriverCertificate,
      DomainDictionaryEntry.APPLICATION_TYPE.inputMistake
    ].includes(applicationSnapshot.typeKey);

    const cancelGeneration = () => {
      shouldGenerate = false;
    };

    if (permissionType === 'driverCertificate' && !ignoreDriverCurrentCertificate) {
      await checkIsActiveLicenseOnFolder(cancelGeneration);
    }

    if (shouldGenerate) {
      if (customGenerateAction) {
        customGenerateAction();
      } else {
        await generateAssignment(
          {
            applicationId: applicationData?.id,
            assignmentTypeKey,
            depotId,
            ignoreDriverCurrentCertificate
          },
          {
            onError: handleGenerateError
          }
        );
      }
    }
  };

  return onClose ? (
    <Tooltip disableHoverListener={!isDisabled} title={disabledTooltip}>
      <span>
        <MenuItem disabled={isDisabled} onClick={handleClickGenerateButton}>
          {title}
        </MenuItem>
      </span>
    </Tooltip>
  ) : (
    <Tooltip disableHoverListener={!isDisabled} title={disabledTooltip}>
      <span>
        <Button
          type="button"
          variant="outlined"
          disabled={isDisabled}
          isLoading={isLoading || areFlagsFetching || isCustomActionLoading}
          onClick={handleClickGenerateButton}
          label={title}
        />
      </span>
    </Tooltip>
  );
}
export default GeneratePermissionButton;
