import { useMemo } from 'react';

import { contrastYellow, useTheme } from '@libs/common/v2/theme';

import { important } from '../../../utils';

const useDateLayoutStyles = () => {
  const { contrast } = useTheme();
  const layoutProps = useMemo(
    () => ({
      sx: {
        '.MuiPickersDay-today, .MuiPickersDay-root, .MuiPickersCalendarHeader-root, .MuiDayCalendar-weekDayLabel, .MuiPickersArrowSwitcher-button, .MuiPickersCalendarHeader-switchViewIcon, .MuiPickersYear-root':
          {
            color: contrast ? important(contrastYellow) : 'unset'
          },
        '.MuiPickersDay-root:focus, .MuiButton-root:hover': {
          color: contrast ? important('black') : 'unset'
        }
      }
    }),
    [contrast]
  );

  return layoutProps;
};

export { useDateLayoutStyles };
