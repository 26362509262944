import { ProceedingKreptdCertificateDetails } from '@ibtm/domain';

import { useTableLayoutProps } from '@libs/common/v2';

import useCertificatesColumns from '../columns/useCertificatesColumns';

function useCertificatesTable(data: ProceedingKreptdCertificateDetails[]) {
  const columns = useCertificatesColumns();
  return useTableLayoutProps({
    tableQuery: data,
    tableHookOptions: {
      columns,
      tableOptions: {
        manualPagination: false
      }
    }
  });
}

export default useCertificatesTable;
