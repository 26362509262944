import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import {
  Button,
  ButtonsGroup,
  CommonTabHeader,
  FormActionEnum,
  useFormActionModal,
  useFormV2Context,
  useIsSmallScreen
} from '@libs/common/v2';
import { useFormV2DirtyWatch, useViewModesV2 } from '@libs/common/v2/form';
import { Theme } from '@libs/common/v2/theme';

interface IProps {
  className?: string;
  handleSubmit?: () => void;
  handleDiscardChanges?: () => void;
  useFormDirty?: () => { isDirty?: boolean; setIsDirty?: (value: boolean) => void };
  displayButtons?: boolean;
  activeTabTitle?: string;
  isHeaderHidden?: boolean;
  classes?: {
    header?: string;
  };
}

function TabHeader({
  className,
  displayButtons = true,
  handleDiscardChanges,
  handleSubmit,
  activeTabTitle,
  isHeaderHidden,
  useFormDirty = useFormV2DirtyWatch,
  classes: classesProps
}: IProps) {
  const { isSmallScreen } = useIsSmallScreen();
  const [t] = useTranslation();

  const { formMode, isSubmitting, formState } = useFormV2Context();
  const { isDirty } = useFormDirty();

  const { editMode } = useViewModesV2(formMode);

  const tabChangeAction = useFormActionModal();
  const classes = useStyles();

  const areButtonsVisible = useMemo(() => {
    return displayButtons && editMode;
  }, [editMode, displayButtons]);

  return isHeaderHidden ? (
    <div />
  ) : (
    <CommonTabHeader
      title={activeTabTitle}
      className={clsx(classes.header, classesProps?.header, className)}
      actions={
        areButtonsVisible && (
          <ButtonsGroup isFullWidth={isSmallScreen} hasExtraBottomMargin={isSmallScreen}>
            <Button
              disabled={!isDirty}
              type="button"
              variant="outlined"
              onClick={() =>
                tabChangeAction({ formAction: FormActionEnum.DISCARD_CHANGES, onDiscardChanges: handleDiscardChanges })
              }
              label={t('action.discardChanges')}
              fullWidth={isSmallScreen}
              isNoMargin
            />
            <Button
              disabled={!isDirty}
              type="button"
              isLoading={isSubmitting || formState.isSubmitting}
              icon="SaveIcon"
              iconColor="white"
              // TODO - tymczasowo wyłączone potwierdzanie zapisu w oknie modalnym
              // onClick={() => tabChangeAction(FormAction.SAVE_CHANGES)}
              onClick={handleSubmit}
              label={t('action.save')}
              variant="contained"
              fullWidth={isSmallScreen}
              isPrimary
              isNoMargin
            />
          </ButtonsGroup>
        )
      }
    />
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  header: {
    paddingTop: 0,
    margin: 0,
    paddingBottom: theme.marginBase * 2,
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  }
}));

export default TabHeader;
