import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';

import { TableLayout } from '@libs/common/v2';

import { useAssociationOrderRangesTable } from '../../../hooks';
import { IAssociationOrderRanges } from '../../../model';

interface IProps {
  data: IAssociationOrderRanges[];
}

function AssociationOrderRangesTable({ data = [] }: IProps) {
  const [t] = useTranslation();
  const tableProps = useAssociationOrderRangesTable();

  return (
    <TableLayout
      {...tableProps}
      tableQuery={data}
      xlsxDownload={{
        fileName: t('resource:list.associationOrderRangesList'),
        pathToXLSXTranslation: 'resource:fields',
        apiRequest: async () => ({ data: { content: data } } as AxiosResponse | PromiseLike<AxiosResponse>)
      }}
      isFilterEnabled={false}
      isRefreshEnabled={false}
      isCollapsable={false}
      isSection
    />
  );
}

export default AssociationOrderRangesTable;
