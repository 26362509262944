import React from 'react';
import { useTranslation } from 'react-i18next';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';
import { makeStyles } from '@mui/styles';

import { Tab, TabContent, Tabs, TabsContent, TabsGroup, useTab } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import {
  ChangePasswordDialog,
  PermissionGroupsTab,
  ProfileGeneralTab,
  ProfileSectionEnum,
  ReplacementsTab
} from '@libs/user';

function ProfileDetailsContent({
  handleChangePassword,
  changePasswordSchema,
  setShowResetPassword,
  showResetPassword
}) {
  const [tab, setTab] = useTab<ProfileSectionEnum>(ProfileSectionEnum.GENERAL);
  const classes = useStyles({ tab });
  const [t] = useTranslation();

  return (
    <TabsGroup>
      <Tabs value={tab} onChange={setTab} orientation="horizontal" className={classes.tabsWrapper}>
        <Tab
          label={<div style={{ textAlign: 'center', margin: 'auto' }}>{t('user:tab.general')}</div>}
          value={ProfileSectionEnum.GENERAL}
          isHorizontalTab
        />
        <Tab
          label={<div style={{ textAlign: 'center', margin: 'auto' }}>{t('user:tab.permissionsGroup')}</div>}
          value={ProfileSectionEnum.PERMISSIONS}
          key={`${ProfileSectionEnum.PERMISSIONS}-tab`}
          viewName={UIElementNameEnum.PROFILE_GROUP_VIEW_TAB}
          isHorizontalTab
        />
        <Tab
          label={<div style={{ textAlign: 'center', margin: 'auto' }}>{t('user:tab.replacements')}</div>}
          value={ProfileSectionEnum.REPLACEMENTS}
          key={`${ProfileSectionEnum.REPLACEMENTS}-tab`}
          viewName={UIElementNameEnum.PROFILE_REPLACEMENT_VIEW_TAB}
          isHorizontalTab
        />
      </Tabs>
      <TabsContent value={tab} className={classes.tabsContent}>
        <TabContent value={ProfileSectionEnum.GENERAL} key={ProfileSectionEnum.GENERAL} isInnerScroll>
          <ProfileGeneralTab />
        </TabContent>
        <TabContent
          value={ProfileSectionEnum.PERMISSIONS}
          key={ProfileSectionEnum.PERMISSIONS}
          viewName={UIElementNameEnum.PROFILE_GROUP_VIEW_TAB}
          isInnerScroll={false}
        >
          <PermissionGroupsTab />
        </TabContent>
        <TabContent
          value={ProfileSectionEnum.REPLACEMENTS}
          key={ProfileSectionEnum.REPLACEMENTS}
          viewName={UIElementNameEnum.PROFILE_REPLACEMENT_VIEW_TAB}
          isInnerScroll={false}
        >
          <ReplacementsTab />
        </TabContent>
      </TabsContent>
      <ChangePasswordDialog
        open={showResetPassword}
        onCancel={() => setShowResetPassword(false)}
        onConfirm={handleChangePassword}
        changePasswordSchema={changePasswordSchema}
      />
    </TabsGroup>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  tabsContent: {
    overflow: important('hidden'),
    paddingBottom: 24,
    height: '100%'
  },
  tabsWrapper: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`
  }
}));

export default ProfileDetailsContent;
