import { QueryConfig, useQuery } from 'react-query';
import { DecisionDetails } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import ApplicationQueryKeysEnum from './ApplicationQueryKeysEnum';

export const getApplicationDecision = async (_, applicationId: string) => {
  const { data } = await API.decisions.getApplicationDecisions(applicationId, {
    ...endpointsConfig.getApplicationDecisions
  });
  return data;
};

function useApplicationDecisionQuery(applicationId, queryConfig?: QueryConfig<DecisionDetails[]>) {
  return useQuery([ApplicationQueryKeysEnum.APPLICATION_DECISION, applicationId], getApplicationDecision, queryConfig);
}

export default useApplicationDecisionQuery;
