import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationSnapshot } from '@ibtm/client-domain';

import { DropdownListButton, useTableAdapter } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { ApplicationQueryKeysEnum } from '../api';
import useAssignApplicationEditorMutation from '../api/mutations/useAssignApplicationEditorMutation';

import ApplicationAssignEditorDialog from './ApplicationAssignEditorDialog';

function ApplicationAssignEditorSelectColumnButton(selectedRowsIds: string[], handleClose: () => void) {
  const queryCache = useQueryCache();
  const { length: selectedRowsCount } = selectedRowsIds;
  const [t] = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const isButtonVisible = selectedRowsCount > 0;
  const { toggleAllRowsSelected, state, data } = useTableAdapter();
  const { mutateAsync: assignPrinter } = useAssignApplicationEditorMutation();

  const assignPrinterHandler = async () => {
    const applications = (data as ApplicationSnapshot[]) ?? [];
    const request = {
      applications: applications.reduce((acc, { id, version }) => {
        return Object.keys(state.selectedRowIds).includes(id) ? acc.concat({ id, version }) : acc;
      }, [])
    };
    await assignPrinter(request);

    queryCache.invalidateQueries(ApplicationQueryKeysEnum.APPLICATION_LIST);
    toggleAllRowsSelected(false);
  };

  return (
    <>
      {showModal && (
        <ApplicationAssignEditorDialog
          onClose={() => {
            setShowModal(false);
            handleClose();
          }}
          onConfirm={assignPrinterHandler}
        />
      )}
      {isButtonVisible && (
        <DropdownListButton
          label={t('prints:actions.assignToMe')}
          onClick={() => {
            setShowModal(true);
          }}
        />
      )}
    </>
  );
}

export default ApplicationAssignEditorSelectColumnButton;
