import { Chip } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

enum StatusColorTypes {
  RED,
  YELLOW,
  GREY,
  LIGHTGREEN,
  GREEN
}
export const applicationStatusColorConfig = [
  { status: DomainDictionaryEntry.APPLICATION_STATUS.CANCELLED, color: StatusColorTypes.RED },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.NOT_CONSIDERED, color: StatusColorTypes.YELLOW },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.NOT_FILLED, color: StatusColorTypes.YELLOW },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.PENDING_ISSUE, color: StatusColorTypes.LIGHTGREEN },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.COMPLETE, color: StatusColorTypes.LIGHTGREEN },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.INCOMPLETE, color: StatusColorTypes.YELLOW },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.NEW, color: StatusColorTypes.GREY },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.PASS, color: StatusColorTypes.LIGHTGREEN },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.PASS_PENDING_ACCEPTATION, color: StatusColorTypes.LIGHTGREEN },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.PASS_PROJECT_IN_PROGRESS, color: StatusColorTypes.LIGHTGREEN },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.CONSIDERED, color: StatusColorTypes.LIGHTGREEN },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.PENDING_DECISION, color: StatusColorTypes.LIGHTGREEN },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.PREPARING_DECISION_DRAFT, color: StatusColorTypes.LIGHTGREEN },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.PENDING_VERIFICATION, color: StatusColorTypes.LIGHTGREEN },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.PENDING_PRINT, color: StatusColorTypes.LIGHTGREEN },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.FOREIGN_VERIFICATION, color: StatusColorTypes.LIGHTGREEN },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.WITHDRAWN, color: StatusColorTypes.RED },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.ISSUED, color: StatusColorTypes.GREEN },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.RELEASED_WAITING_FOR_ZPO, color: StatusColorTypes.GREEN },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.ISSUED_FOREIGN_PERMISSION, color: StatusColorTypes.GREEN },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.ACCOMPLISHED_WITHOUT_PRINTING, color: StatusColorTypes.GREEN },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.ACCOMPLISHED, color: StatusColorTypes.GREEN },
  { status: DomainDictionaryEntry.APPLICATION_STATUS.DRAFT, color: StatusColorTypes.YELLOW }
];
export const applicationColorDefnitions = (colorType: StatusColorTypes) => {
  switch (colorType) {
    case StatusColorTypes.RED:
      return 'error';
    case StatusColorTypes.YELLOW:
      return 'warning';
    case StatusColorTypes.GREY:
      return 'grey';
    case StatusColorTypes.LIGHTGREEN:
      return 'success';
    case StatusColorTypes.GREEN:
      return 'success';
    default:
      return 'success';
  }
};

export default function ApplicationChip({ status }: { status: string }) {
  const { translate } = useDictionaryTranslations();
  const statusDefinitionItem = applicationStatusColorConfig.find(definition => definition.status === status);
  const titleTranslation = translate(DomainDictionaryEnum.APPLICATION_STATUS, status);
  return (
    <Chip
      title={titleTranslation.length ? titleTranslation : status}
      color={applicationColorDefnitions(statusDefinitionItem?.color)}
      colorWeight={statusDefinitionItem?.color === StatusColorTypes.GREEN ? '700' : '800'}
    />
  );
}
