import { ForeignPermissionApplicationCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export function createForeignPermissionApplicationClient({
  applicationId,
  foreignPermissionApplicationCreateRequest
}: {
  applicationId: string;
  foreignPermissionApplicationCreateRequest: ForeignPermissionApplicationCreateRequest;
}) {
  return API.client.applicationForeignPermissions.create(
    applicationId,
    '',
    '',
    foreignPermissionApplicationCreateRequest,
    { ...endpointsConfig.createForeignPermission }
  );
}

export function createForeignPermissionApplication({
  applicationId,
  foreignPermissionApplicationCreateRequest
}: {
  applicationId: string;
  foreignPermissionApplicationCreateRequest: ForeignPermissionApplicationCreateRequest;
}) {
  return API.applicationForeignPermissions.create(applicationId, foreignPermissionApplicationCreateRequest, {
    ...endpointsConfig.createForeignPermission
  });
}

function useCreateForeignPermissionApplication() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(createForeignPermissionApplicationClient, createForeignPermissionApplication));
}

export default useCreateForeignPermissionApplication;
