import { ArchiveNoteUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function createArchiveApplicationNote(params: ArchiveNoteUpdateRequest) {
  return API.archiveApplications.createArchiveApplicationNote(params, {
    ...endpointsConfig.createArchiveApplicationNote
  });
}

function useCreateArchiveApplicationNoteMutation() {
  return useMutation(createArchiveApplicationNote);
}

export default useCreateArchiveApplicationNoteMutation;
