import { useTranslation } from 'react-i18next';

import { AdditionalFilterEnum, FormMode, TableLayout, useTableRowActions } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { DomainUIElementEnum } from '@libs/domain/config';
import { CivicBoardSnapshot, ModalButton, useCivicBoardTable } from '@libs/domain/social-commission';

import { CivicBoardTableEnum } from '../../common/model/social-comission.model';
import { IntialYearType } from '../../SocialCommissionPage';

import AddMeetingModal from './add-meeting/AddMeetingModal';
import DeleteMeetingButton from './add-meeting/DeleteMeetingButton';

function SocialCommissionCivicBoardTable({
  globalFilters,
  initialYear
}: {
  globalFilters: (name: string) => {
    type: AdditionalFilterEnum;
    name: string;
    customFilter: JSX.Element;
  }[];
  initialYear: IntialYearType;
}) {
  const [t] = useTranslation();
  const tableProps = useCivicBoardTable(initialYear);

  const { renderTableActions } = useTableRowActions<CivicBoardSnapshot>([
    {
      id: DomainUIElementEnum.COMMISSION_DEADLINES_DELETE,
      label: 'action.delete',
      customButton: ({ original }) => <DeleteMeetingButton civicBoardId={original.id} />
    },
    {
      id: DomainUIElementEnum.COMMISSION_DEADLINES_EDIT,
      label: 'action.edit',
      customButton: ({ original }) => (
        <ModalButton
          component={AddMeetingModal}
          title={t('action.edit')}
          data={{ ...original, mode: FormMode.EDIT }}
          isRowButton
          icon="EditIcon"
        />
      )
    }
  ]);
  return (
    <TableLayout
      globalFilters={globalFilters(CivicBoardTableEnum.YEAR_GRATER)}
      {...tableProps}
      pageTitle={t('foreignPermits:tabs.socialCommission.tabs.socialCommissionDeadlines.fullTitle')}
      xlsxDownload={{
        fileName: t('foreignPermits:tabs.socialCommission.tabs.socialCommissionDeadlines.fullTitle'),
        pathToXLSXTranslation: 'foreignPermits:tabs.socialCommission.tabs.socialCommissionDeadlines.fields',
        apiRequest: params => API.civicBoard.getCivicBoardsDetailsPage(params)
      }}
      headerActions={
        <ModalButton
          component={AddMeetingModal}
          title={t('action.add')}
          data={{ mode: FormMode.CREATE }}
          actionKey={DomainUIElementEnum.COMMISSION_DEADLINES_ADD}
        />
      }
      rowActions={renderTableActions([
        DomainUIElementEnum.COMMISSION_DEADLINES_DELETE,
        DomainUIElementEnum.COMMISSION_DEADLINES_EDIT
      ])}
      isSectionFullTab
    />
  );
}

export default SocialCommissionCivicBoardTable;
