export enum FolderDetailsTabEnum {
  APPLICATIONS = 'APPLICATIONS',
  FOLDER = 'FOLDER',
  SUBJECT = 'SUBJECT',
  ADDRESSES = 'ADDRESSES',
  BASIC_DATA = 'BASIC_DATA',
  PROXIES = 'PROXIES',
  CERTIFICATE_S = 'CERTIFICATE_S',
  SINGLE_AUTHORIZATION = 'SINGLE_AUTHORIZATION',
  EKMT = 'EKMT',
  ORDERS = 'ORDERS',
  PERMISSIONS = 'PERMISSIONS',
  FINANCIAL_SECURITY = 'FINANCIAL_SECURITY',
  DRIVERS = 'DRIVERS',
  VEHICLES = 'VEHICLES',
  INSURANCE_POLICIES = 'INSURANCE_POLICIES',
  EXPLOATATION_BASES = 'EXPLOATATION_BASES',
  SUSPENSIONS = 'SUSPENSIONS',
  PROCEEDINGS = 'PROCEEDINGS',
  RENTALS = 'RENTALS',
  LOCKS = 'LOCKS',
  HISTORY = 'HISTORY',
  TRANSPORT_MANAGER = 'TRANSPORT_MANAGER',
  NOTE = 'NOTE',
  ENTERPRENEURS = 'ENTERPRENEURS',
  FOREIGN_PERMISSIONS = 'FOREIGN_PERMISSIONS'
}

export enum ApplicationArchiveUrlParam {
  TO_ARCHIVE = 'toarchive',
  ARCHIVED = 'archived'
}

type OnlyStringParams<T extends { [key: string]: (...args: string[]) => string }> = T;

/** jako parametry przekazujemy tylko dynamiczne parametry czyli id'ki. Taby, tryby formularza traktujemy jako osobny route - definiujemy nowe pole. */
export type IDomainRoutes = OnlyStringParams<{
  // Portal klienta
  applicationClientEdit: (id: string, folderId: string) => string;

  // Wnioski
  applicationsList: () => string;
  applicationDetails: (applicationId: string) => string;
  folderApplicationDetails: (folderId: string, applicationId: string) => string;
  applicationEdit: (applicationId: string) => string;
  folderApplicationEdit: (applicationId: string, folderId?: string) => string;
  archiveApplicationDetailsToArchive: (applicationId: string) => string;
  archiveApplicationDetailsArchived: (applicationId: string) => string;
  exploitationBaseApplicationCreate: (applicationId: string) => string;
  exploitationBaseApplicationEdit: (exploitationBaseId: string, applicationId: string) => string;
  exploitationBaseApplicationView: (exploitationBaseId: string, applicationId: string) => string;

  // Teczki
  foldersList: () => string;
  folderDetails: (folderId: string) => string;
  folderEdit: (folderId: string) => string;
  folderCreate: () => string;
  exploitationBaseFolderView: (exploitationBaseId: string, folderId: string) => string;
  exploitationBaseFolderEdit: (exploitationBaseId: string, folderId: string) => string;

  // Kierowcy
  driversList: () => string;

  // Dokumenty wydania
  releaseDocumentList: () => string;
  releaseDocumentView: (id: string) => string;
  releaseDocumentEdit: (id: string) => string;
  releaseDocumentCreate: (id: string) => string;
  releaseDocumentReturn: (id: string) => string;
  releaseDocumentOnFolderSuspensionView: (folderId: string, suspensionId: string, releaseDocumentId: string) => string;
  releaseDocumentOnFolderSuspensionEdit: (folderId: string, suspensionId: string, releaseDocumentId: string) => string;
  releaseDocumentOnFolderSuspensionCreate: (
    folderId: string,
    suspensionId: string,
    releaseDocumentId: string
  ) => string;
  releaseDocumentOnFolderSuspensionReturn: (
    folderId: string,
    suspensionId: string,
    releaseDocumentId: string
  ) => string;

  // Wydruki
  printsList: () => string;
  printDetails: (id: string) => string;

  // Dokumenty wychodzące
  documentsIssuedList: () => string;

  // Dokumenty EZD
  ezdDocumentsList: () => string;
  ezdDocumentDetails: (id: string) => string;

  // Certyfikaty S
  sCertificatesList: () => string;
  sCertificateDetails: (id: string) => string;

  // Magazyn
  resourcesList: () => string;
  resourceDetails: (depotId: string) => string;
  resourceTransferDetails: (depotId: string) => string;
  disposalsList: () => string;
  resourceObjectsList: () => string;

  // Weryfikacja uprawnień - Postępowania
  proceedingsList: () => string;
  proceedingAdministrationCreate: (folderId: string) => string;
  proceedingVerificationCreate: (folderId: string) => string;
  proceedingCreateAdministrationFromBase: (folderId: string, id: string) => string;
  proceedingCreateVerificationFromBase: (folderId: string, id: string) => string;
  proceedingAdministrationDetails: (folderId: string, id: string) => string;
  proceedingVerificationDetails: (folderId: string, id: string) => string;
  proceedingAdministrationEdit: (folderId: string, id: string) => string;
  proceedingVerificationEdit: (folderId: string, id: string) => string;

  // Weryfikacja uprawnień - Zawieszenia
  suspensionsList: () => string;
  suspensionCreate: (folderId: string) => string;
  suspensionEdit: (folderId: string, id: string) => string;
  suspensionDetails: (folderId: string, id: string) => string;
  suspensionReleaseDocumentsTab: (id: string, folderId: string) => string;

  // Archiwum
  archiveApplicationsArchiveList: () => string;
  archiveApplicationsCarryOnArchivedList: () => string;
  archiveApplicationsToArchiveList: () => string;
  // TODO dodać routingi dla archiwum teczki i wypożyczenia teczek

  // Transport drogowy KREPTD
  roadTransportsList: () => string;
  roadTransportsAdministrativeProceedingsList: () => string;
  roadTransportsFoldersToTransferList: () => string;
  roadTransportsViolationsList: () => string;
  roadTransportDetails: (id: string) => string;
  roadTransportEdit: (id: string) => string;
  roadTransportDetailsView: (id: string) => string;

  // Stowarzyszenia
  associationsList: () => string;
  associationDetails: (id: string) => string;
  associationEdit: (id: string) => string;
  associationCreate: () => string;

  // Zezwolenia zagraniczne - Wydane zezwolenia
  releasedPermits: () => string;
  releasedPermitsFilteredByReleaseDocument: (number: string) => string;
  releasedPermitsEkmtDetails: (id: string) => string;

  // Zezwolenia zagraniczne - Limity
  disposablePermitsList: () => string;
  disposablePermitDetails: (id: string) => string;
  ekmtPermitsList: () => string;
  ekmtPermitDetails: (id: string) => string;

  // Zezwolenia zagraniczne - Komisja społeczna
  socialCommissionsList: () => string;
  // TODO dodać funcje routing na poszczególne taby
  socialCommissionTab: (tab: string) => string;
  socialCommissionDetails: (id: string) => string;
  socialCommissionEdit: (id: string) => string;
  socialComissionCreate: () => string;
  socialComissionDisposablePermitDetails: (id: string) => string;
  socialComissionDisposablePermitEdit: (id: string) => string;
  socialComissionDisposablePermitCreate: () => string;

  // TODO do przeniesienia do modułu document-template
  documentTemplatesList: () => string;
  documentTemplateCreate: () => string;
  documentTemplateDetails: (id: string) => string;
  documentTemplateEdit: (id: string) => string;
}>;
