import { useTranslation } from 'react-i18next';

import { SectionWithFields, SwitchField, typedNameV2 } from '@libs/common/v2';

import { FolderDetailsClient } from '@libs/domain/folder';

function PermitDataSectionDetails() {
  const [t] = useTranslation();

  return (
    <SectionWithFields
      title={t('folder:details.section.permit')}
      isCollapsable
      fields={[
        <SwitchField
          name={typedNameV2<FolderDetailsClient>(`subject.mainAddressOnPermission`)}
          label={t('folder:address.similarToMainAddress')}
          key="permit-similarToMainAddress"
        />
      ]}
    />
  );
}

export default PermitDataSectionDetails;
