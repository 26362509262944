import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';

import { Dialog, FormV2Context, useIsSmallScreen, useValidationBuilder } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { prepareUpdateAddressRequest } from '@libs/domain/application';
import { ApplicationQueryKeysEnum, useApplicationAddressesQuery } from '@libs/domain/application/api';
import { DomainDictionaryEntry } from '@libs/domain/config';

import { AddressFormData } from '../../../application/model';
import { useUpdateAddressMutation } from '../../api';
import { SubjectAddressDetailsUnion } from '../../models';
import { CorrespondenceAddress } from '../address-section';

interface IProps {
  applicationId: string;
  closeDialog: () => void;
}

function CorrespondenceAddressDialog({ applicationId, closeDialog }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { isSmallScreen } = useIsSmallScreen();
  const { showSuccessSnackbar } = useSnackbar();
  const [updateAddress] = useUpdateAddressMutation();
  const { validationScheme, validationBuilderFunctions } = useValidationBuilder();

  const formGroupName = 'application-addresses-tab.correspondence';
  const [formGroup, addressName] = formGroupName.split('.');

  const { data: addressesResponse, isFetching } = useApplicationAddressesQuery(
    { applicationIdIn: [applicationId] },
    { enabled: Boolean(applicationId) }
  );

  const applicationAddresses: SubjectAddressDetailsUnion[] = addressesResponse
    ? ('content' in addressesResponse && addressesResponse?.content) ||
      ('addresses' in addressesResponse && addressesResponse?.addresses)
    : [];

  const correspondenceAddress = applicationAddresses?.find(
    address => address.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.CORRESPONDENCE
  );

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    reset
  } = useForm<Partial<AddressFormData>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationScheme)
  });

  useEffect(() => {
    if (correspondenceAddress) {
      reset({
        [formGroup]: {
          [addressName]: {
            ...correspondenceAddress,
            sameAsMainAddress: false,
            typeKey: { value: DomainDictionaryEntry.ADDRESS_TYPE.CORRESPONDENCE }
          }
        }
      });
    }
  }, [addressName, correspondenceAddress, formGroup, reset]);

  const [loading, setLoading] = useState(false);

  const onSubmit = (formData: Partial<AddressFormData>) => {
    setLoading(true);

    const requestData = prepareUpdateAddressRequest(formData[formGroup][addressName]);

    updateAddress(requestData, {
      onSuccess: () => {
        showSuccessSnackbar(t('applications:message.updateAddressSuccess'));
        queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION_ADDRESSES]);
        queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
        closeDialog();
      },
      onSettled: () => {
        setLoading(false);
      }
    });
  };

  const values = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      loading,
      validationBuilderFunctions
    }),
    [
      control,
      errors,
      getValues,
      isSubmitting,
      loading,
      register,
      setValue,
      trigger,
      unregister,
      validationBuilderFunctions,
      watch
    ]
  );

  return (
    <Dialog
      title={t('applications:dialog.editCorrespondenceAddressShortTitle')}
      shortTitle={t('applications:dialog.editCorrespondenceAddressShortTitle')}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={handleSubmit(onSubmit)}
      onCancel={closeDialog}
      isConfirmLoading={loading}
      isLoading={isFetching}
      isFullScreen={isSmallScreen}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <form>
          <CorrespondenceAddress
            formGroupName={formGroupName}
            applicationId={applicationId}
            isCountryFieldAvaliable={false}
            hideProxyFields
            hideSameAsMainField
            hideAddressTypeField
          />
        </form>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default CorrespondenceAddressDialog;
