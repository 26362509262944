export const getDateOption = isYear => {
  if (isYear) {
    const currentYear = new Date().getFullYear();
    return Array(10)
      .fill(0)
      .map((_item, index) => ({
        value: currentYear + index,
        name: (currentYear + index).toString()
      }));
  }

  return Array(13)
    .fill(0)
    .map((_item, index) => {
      const i = index;
      return {
        value: i,
        name: (i < 10 ? '0' : '') + i
      };
    });
};
