import { usePaginatedQuery } from 'react-query';
import { CivicBoardRuleDetails } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { SocialCommissionQueryKeysEnum } from '@libs/domain/social-commission';

import { endpointsConfig } from '../endpoints-config';

export const getTradeRules = async params => {
  const { data } = await API.civicBoard.getCivicBoardRulesSnapshotPage(params, {
    ...endpointsConfig.getCivicBoardRulesSnapshotPage
  });

  return {
    ...data,
    content: data.content.map(({ calculationInProgress, ...item }) => ({
      ...item,
      calculationInProgress: calculationInProgress || false
    }))
  };
};

function useTradeRulesQuery(params: CivicBoardRuleDetails, queryConfig = {}) {
  return usePaginatedQuery<IPaginatedModel<CivicBoardRuleDetails>>(
    [SocialCommissionQueryKeysEnum.TRADE_RULES_DETAILS, params],
    () => getTradeRules(params),
    queryConfig
  );
}
export default useTradeRulesQuery;
