import React, { ReactNode } from 'react';
import { Grid, GridProps, GridSize } from '@mui/material';

import { Component } from '../models';

function Column({
  renderNodes,
  components,
  children,
  size,
  ...forwardProps
}: GridProps & {
  renderNodes?: (children: ReactNode, components: any) => JSX.Element | JSX.Element[];
  components?: Component[];
  size?: GridSize;
}) {
  return (
    <Grid {...forwardProps} container item direction="column" xs={size ?? 'auto'}>
      {renderNodes(children, components)}
    </Grid>
  );
}

export default Column;
