import { useIsSmallScreen } from '@libs/common/v2';

interface IProps {
  pageHeader: React.ReactNode;
  contentHeader: React.ReactNode;
  children: React.ReactNode;
}

const FolderContentWithHeaderWrapper = ({ children, contentHeader, pageHeader }: IProps) => {
  const { isSmallScreen } = useIsSmallScreen();
  return isSmallScreen ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      {pageHeader}
      {contentHeader}
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

export default FolderContentWithHeaderWrapper;
