import { Context, useContext } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { Autocomplete, FilledInputProps, InputBaseProps, InputProps, OutlinedInputProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { ComponentErrorBoundary, FormV2Context, FormV2ContextState } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import { DictionaryEntryNameEnum, DictionaryEntryValue, useDictionaryTranslations } from '@libs/dictionary';

import { TextInput } from '../../../components';

interface PartialDictionaryFieldProps {
  dictionaryName: DictionaryEntryNameEnum;
  formContext?: Context<FormV2ContextState>;
  name: FieldPath<FieldValues>;
  options: Array<DictionaryEntryValue>;
  isRequired?: boolean;
  isDisabled?: boolean;
  label?: string;
  inputProps?: InputBaseProps['inputProps'];
  InputProps?: FilledInputProps | OutlinedInputProps | InputProps;
  isMultiline?: boolean;
  textFieldClassName?: string;
  hasErrorTooltip?: boolean;
}

export default function PartialDictionaryField({
  dictionaryName,
  formContext = FormV2Context,
  name,
  options,
  isRequired,
  isDisabled,
  isMultiline,
  textFieldClassName,
  hasErrorTooltip,
  ...forwardProps
}: PartialDictionaryFieldProps) {
  const { translate } = useDictionaryTranslations();
  const { control } = useContext<FormV2ContextState>(formContext);

  const classes = useStyles();

  return (
    <ComponentErrorBoundary componentName={forwardProps.label || name || 'PartialDictionaryField'}>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
          return (
            <Autocomplete
              onChange={(_, selectedOption) => onChange(selectedOption?.name ?? '')}
              value={value}
              onBlur={onBlur}
              loading={!options.length}
              options={options}
              getOptionLabel={option => translate(dictionaryName, option.value, option)}
              classes={{
                option: classes.option,
                inputRoot: clsx(classes.root, !!error && classes.errorPadding),
                listbox: classes.listbox,
                paper: classes.paper,
                noOptions: classes.option,
                endAdornment: classes.endAdornment
              }}
              renderInput={props => (
                <TextInput
                  required={isRequired}
                  error={!!error}
                  helperText={error?.message}
                  variant="outlined"
                  disabled={isDisabled}
                  multiline={isMultiline}
                  {...forwardProps}
                  {...props}
                  inputProps={{
                    ...forwardProps.inputProps,
                    ...props.inputProps,
                    'aria-label': forwardProps.label
                  }}
                  hasErrorTooltip={hasErrorTooltip}
                />
              )}
              onInputChange={(_, newValue) => onChange(newValue)}
              freeSolo
              fullWidth
            />
          );
        }}
      />
    </ComponentErrorBoundary>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    padding: important('0 14px'),
    minHeight: 43,
    paddingRight: '35px'
  },
  errorPadding: {
    paddingRight: important('55px')
  },
  option: {
    ...theme.typography.textMd.normal,
    color: theme.palette.grey[900],
    padding: '10px 14px',
    backgroundColor: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[100]
    },
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.blueLight[50]
    }
  },
  endAdornment: {
    position: 'unset',
    '&.MuiAutocomplete-endAdornment': {
      marginLeft: 4
    },
    '& button': {
      padding: 4
    },
    '& path': {
      marginleft: important(6),
      stroke: theme.palette.grey[500]
    }
  },
  listbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
  paper: {
    boxShadow: theme.shadowsList.lg,
    borderRadius: theme.borderRadiusBase
  }
}));
