import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { get } from 'lodash';

import { DropdownListButton } from '@libs/common/v2';

import { useDocumentTemplatesQuery } from '@libs/document-template';
import { useDownloadDocumentFile } from '@libs/file';

import { DomainDictionaryEntry, DomainUIElementEnum } from '@libs/domain/config';

import { useGenerateApplicationDocumentMutation } from '../api';

interface IProps {
  applicationId: string;
}

function ApplicationCreateBillingNoteButton({ applicationId }: IProps) {
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [generateDocument] = useGenerateApplicationDocumentMutation();

  const { data: initialDocumentTemplate } = useDocumentTemplatesQuery({
    groupKeys: [DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.FEE_FORMS]
  });

  const { downloadFile } = useDownloadDocumentFile();

  const onSubmit = () => {
    setIsConfirmLoading(true);
    if (initialDocumentTemplate) {
      const params = {
        applicationId,
        data: {
          documentTemplateId: initialDocumentTemplate?.content[0]?.id
        }
      };

      generateDocument(params, {
        onSuccess: ({ data }) => {
          showSnackbar('success', t('document:message.generateDocumentSuccess'));

          downloadFile(get(data, 'file'));
        },
        onSettled: () => {
          setIsConfirmLoading(false);
        }
      });
    }
  };

  return (
    <DropdownListButton
      label={t('documentsIssued:actions.createBillingNote')}
      onClick={() => {
        onSubmit();
      }}
      actionKey={DomainUIElementEnum.APPLICATION_CREATE_BILLING_NOTE_BUTTON}
      isLoading={isConfirmLoading}
    />
  );
}

export default ApplicationCreateBillingNoteButton;
