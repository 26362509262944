import { DefaultApiFindTemplatesRequest } from '@avispon/document-generator';
import { DocumentTemplateSnapshotExternal } from '@avispon/document-generator/dist/models';

import { CamelCasePath, SelectOption, TableFilterMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO } from '@libs/common/v2/utils';

import { DocumentTemplateGroupEnum } from '@libs/document-template/model';
import { UserFilterV2 } from '@libs/user';

export function useDocumentTemplateColumn(visibleColumns: Array<CamelCasePath<DocumentTemplateSnapshotExternal>>) {
  const { createColumns } = useCreateColumns<DocumentTemplateSnapshotExternal>({
    pathToTranslate: 'documentTemplates:field'
  });

  const mappedFilterFields: TableFilterMapper<DocumentTemplateSnapshotExternal, DefaultApiFindTemplatesRequest> = {
    name: (value: string) => ({ nameFragment: value }),
    authorName: (author: SelectOption<string>[]) => ({ authorId: author.map(({ value }) => value) }),
    groupKeys: (groupKeys: SelectOption<string>[]) => ({ groupKeys: groupKeys.map(({ value }) => value) }),
    created: ({ dateFrom, dateTo }: DateRangeISO) => ({ createdFrom: dateFrom || '', createdTo: dateTo || '' }),
    modified: ({ dateFrom, dateTo }: DateRangeISO) => ({ modifiedFrom: dateFrom || '', modifiedTo: dateTo || '' })
  };

  const columns = createColumns(
    [
      {
        id: 'name',
        type: 'TEXT',
        width: 400
      },
      {
        id: 'groupKeys',
        header: 'group',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DocumentTemplateGroupEnum.DOCUMENT_TEMPLATE_GROUP,
        isSortable: false
      },
      {
        accessor: 'author.name',
        header: 'author',
        type: 'CUSTOM_COLUMN',
        filter: UserFilterV2,
        width: 300
      },
      {
        id: 'created',
        type: 'DATE_TIME',
        width: 300
      },
      {
        id: 'modified',
        type: 'DATE_TIME',
        width: 300
      }
    ],
    visibleColumns
  );

  return { columns, mappedFilterFields };
}

export default useDocumentTemplateColumn;
