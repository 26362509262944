import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { ForeignPermissionSnapshot } from '@ibtm/domain';
import { omit } from 'lodash';

import { TableIconButton, TableLayout, useRouter, useTableRowActions } from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';

import { API } from '@libs/domain/api';
import { DomainDictionaryEntry, DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import {
  ForeignPermissionTypeEnum,
  ReleasedPermitsSnapshot,
  usePermitDialog,
  useReleasedPermistsTable
} from '@libs/domain/foreign-permission';

function ReleasedPermitsTablePage({
  documentReleaseNumber,
  isReleasedPermits
}: {
  documentReleaseNumber?: string;
  isReleasedPermits?: boolean;
}) {
  const { routes } = useRouter();
  const [t] = useTranslation();
  const { checkIsElementVisible } = useElementVisibility();
  const tableProps = useReleasedPermistsTable({
    documentReleaseNumber,
    isReleasedPermits,
    isMassActionVisible: checkIsElementVisible(DomainUIElementEnum.PERMITS_RELEASED_SETTLEMENT_BUTTON)
  });
  const { yearFilterContextValues } = useDomainConfigContext();
  const { openSettleEKMTPermitDialog, openSettleSinglePermitDialog } = usePermitDialog();

  const handleOnSettleClick = (original: ReleasedPermitsSnapshot) => {
    if (original.typeKey === ForeignPermissionTypeEnum.EKMT) {
      return openSettleEKMTPermitDialog({
        foreignPermissionId: original.id,
        initialData: original,
        hasDefaultDestiny: true
      });
    }

    return openSettleSinglePermitDialog({ foreignPermissionId: original.id, initialData: original });
  };

  const { renderTableActions } = useTableRowActions<ReleasedPermitsSnapshot>([
    {
      id: DomainUIElementEnum.PERMITS_RELEASED_EDIT,
      label: 'global:action.openDetails',
      link: ({ original }) => routes.releasedPermitsEkmtDetails(original.id),
      icon: 'ArrowIcon',
      isHidden: ({
        row: {
          original: { typeKey }
        }
      }) => typeKey !== ForeignPermissionTypeEnum.EKMT
    },
    {
      id: DomainUIElementEnum.PERMITS_RELEASED_SETTLEMENT_BUTTON,
      label: 'foreignPermits:other.settlement',
      icon: 'CoinsIcon',
      isHidden: ({
        row: {
          original: { isSettled, typeKey, form }
        }
      }) => {
        return (
          (isSettled && typeKey !== ForeignPermissionTypeEnum.EKMT) ||
          form?.stateKey === DomainDictionaryEntry.RESOURCE_FORM_STATE.RETURNED
        );
      },
      customButton: ({ original }) => (
        <TableIconButton
          tooltipTitle={t('foreignPermits:other.settlement')}
          icon="CoinsIcon"
          onClick={() => handleOnSettleClick(original)}
        />
      )
    }
  ]);

  const tableActions = (row: Row<ForeignPermissionSnapshot>, table): JSX.Element => {
    const actions = renderTableActions([
      DomainUIElementEnum.PERMITS_RELEASED_SETTLEMENT_BUTTON,
      DomainUIElementEnum.PERMITS_RELEASED_EDIT
    ]);
    return actions(row, table);
  };

  const apiRequest = useCallback(
    params => {
      const queryParams = omit(params, ['year']);

      return API.foreignPermissions.getForeignPermissionSnapshotPage({
        ...queryParams,
        resourceTypeYearEquals: parseInt(yearFilterContextValues?.value, 10)
      });
    },
    [yearFilterContextValues?.value]
  );

  return checkIsElementVisible(DomainUIElementEnum.PERMITS_RELEASED_VIEW) ? (
    <TableLayout
      {...tableProps}
      pageTitle={t('foreignPermits:tabs.releasedPermits.title')}
      xlsxDownload={{
        fileName: t('foreignPermits:list.title'),
        pathToXLSXTranslation: 'foreignPermits:tabs.releasedPermits.fields',
        apiRequest: params => apiRequest(params)
      }}
      rowActions={(row, table) => tableActions(row, table)}
    />
  ) : null;
}

export default ReleasedPermitsTablePage;
