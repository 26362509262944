import React, { Ref, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { IconButton as MuiIconButton, Tooltip, TooltipProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { ButtonLinkDefinition, Icon, IconType, LoaderCircular } from '@libs/common/v2';
import { PaletteOption, PaletteOptions, Theme, useTheme } from '@libs/common/v2/theme';
import { handleActionOnEnterPress, hexToRgba, important } from '@libs/common/v2/utils';

import { UIElementNameEnum, useElementVisibility } from '@libs/permission';

interface IProps {
  tooltipTitle?: string;
  placement?: TooltipProps['placement'];
  className?: string;
  wrapperClassName?: string;
  title?: string;
  onClick?: (() => void) | ((event: React.MouseEvent<HTMLButtonElement>) => void);
  iconClassName?: string;
  icon?: IconType;
  children?: React.ReactNode;
  iconColor?: keyof PaletteOptions;
  iconColorWeight?: keyof PaletteOption | number;
  height?: number;
  width?: number;
  isLoading?: boolean;
  isDisabled?: boolean;
  isNoPadding?: boolean;
  isBackgroundTransparent?: boolean;
  isRippleDisabled?: boolean;
  actionKey?: UIElementNameEnum;
  link?: ButtonLinkDefinition;
  component?: keyof JSX.IntrinsicElements;
  size?: 'small' | 'medium' | 'large';
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
}

function IconButton(
  {
    icon,
    iconClassName,
    tooltipTitle,
    placement = 'bottom',
    onClick,
    height,
    width,
    iconColor = 'grey',
    iconColorWeight = '500',
    className,
    title,
    children,
    isNoPadding,
    isLoading,
    wrapperClassName,
    isBackgroundTransparent,
    isRippleDisabled,
    isDisabled,
    actionKey,
    link,
    component,
    size = 'large',
    type
  }: IProps,
  forwardRef?: Ref<HTMLElement>
) {
  const { contrast } = useTheme();
  const { checkIsElementVisible } = useElementVisibility();
  const [isHover, setIsHover] = useState(false);
  const classes = useStyles({ isNoPadding, isBackgroundTransparent, height, width, isDisabled, contrast });

  const linkTo = useMemo(() => {
    return typeof link === 'function' ? link() : link;
  }, [link]);

  const isElementVisible = useMemo(() => {
    return actionKey ? checkIsElementVisible(actionKey) : true;
  }, [checkIsElementVisible, actionKey]);

  if (isElementVisible) {
    const ButtonContent = (
      <>
        <Icon
          icon={icon}
          color={iconColor}
          colorWeight={iconColorWeight}
          className={clsx(classes.icon, iconClassName)}
          height={height}
          width={width}
          isDisabled={isDisabled}
        />
        {children}
      </>
    );
    const ButtonWithLoader = (
      <span
        className={wrapperClassName}
        {...(tooltipTitle && {
          onMouseOver: () => setIsHover(true),
          onMouseLeave: () => setIsHover(false),
          onFocus: () => setIsHover(false)
        })}
      >
        <MuiIconButton
          ref={forwardRef}
          aria-label={tooltipTitle ?? icon}
          data-testid={tooltipTitle ?? title ?? icon}
          className={clsx('self-center', classes.button, className)}
          disableRipple={isRippleDisabled}
          disabled={isDisabled || isLoading}
          onKeyDown={handleActionOnEnterPress(e => onClick?.(e as unknown as React.MouseEvent<HTMLButtonElement>))}
          onClick={event => {
            setIsHover(false);
            onClick?.(event);
          }}
          type={type}
          title={title}
          component={link ? Link : component}
          {...(linkTo && {
            to:
              typeof linkTo === 'string'
                ? linkTo
                : { pathname: linkTo?.pathname, key: linkTo?.key, search: linkTo?.search, hash: linkTo?.hash },
            ...(typeof linkTo !== 'string' && 'state' in linkTo && { state: linkTo.state })
          })}
          size={size}
        >
          {isLoading ? (
            <LoaderCircular isLoading={isLoading} size={width < height ? width : height} isAbsolute={false}>
              {ButtonContent}
            </LoaderCircular>
          ) : (
            ButtonContent
          )}
        </MuiIconButton>
      </span>
    );
    return tooltipTitle ? (
      <Tooltip open={isHover} placement={placement} title={tooltipTitle || ''}>
        {ButtonWithLoader}
      </Tooltip>
    ) : (
      ButtonWithLoader
    );
  }
}
const useStyles = makeStyles<
  Theme,
  Pick<IProps, 'isBackgroundTransparent' | 'isNoPadding' | 'height' | 'width' | 'isDisabled'> & { contrast: boolean }
>(theme => ({
  button: {
    backgroundColor: ({ isBackgroundTransparent }) => {
      return isBackgroundTransparent ? 'transparent' : theme.palette.white;
    },
    color: ({ isDisabled }) => isDisabled && important(theme.palette.grey[400]),
    zIndex: 10,
    '&:hover': {
      backgroundColor: hexToRgba(theme.palette.grey[400], 0.5)
    },
    padding: ({ isNoPadding }) => (isNoPadding ? 0 : 10),
    '&.MuiButtonBase-root > span:first-of-type': {
      height: ({ height }) => height ?? 30,
      width: ({ width }) => width ?? 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
}));

export default React.forwardRef(IconButton);
