import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Dialog, FormV2Context, GridLayout, typedNameV2 } from '@libs/common/v2';

import { useAssignApplicationToOrganizationMutation, useAssignApplicationToUserMutation } from '../../application/api';
import { MixedAccountsSearchRoles } from '../../models';

import OrganizationUnitAutocompleteField from './OrganizationUnitAutocompleteField';
import UsersAutocompleteField from './UsersAutocompleteField';

interface IProps {
  applicationId: string;
  applicationVersion: number;
  closeDialog: () => void;
  onFinish: () => void;
}

interface IAssignApplicationForm {
  user?: string;
  unit?: string;
}

function AssignApplicationToUserDialog({ applicationId, applicationVersion, closeDialog, onFinish }: IProps) {
  const [t] = useTranslation();
  const { mutate: assignApplicationToOrganization, isLoading: isLoadingOrganization } =
    useAssignApplicationToOrganizationMutation();
  const { mutate: assignApplicationToUser, isLoading: isLoadingUser } = useAssignApplicationToUserMutation();

  const userRoles: MixedAccountsSearchRoles[] = [
    'OPERATOR',
    'OPERATOR_MANAGER',
    'OPERATOR_TECHNICAL',
    'ADMIN',
    'CORPORATION'
  ];

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger
  } = useForm<Partial<IAssignApplicationForm>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        user: Yup.string()
          .nullable()
          .test(
            'checkIfNoFieldIsFilled',
            t('applications:validations.oneFieldMustBeFilled'),
            function checkIfJustOneFieldIsFilled(this) {
              const { parent } = this;
              if (!parent.user && !parent.unit) {
                return false;
              }
              return true;
            }
          )
          .test(
            'checkIfJustOneFieldIsFilled',
            t('applications:validations.justOneFieldShouldBeFilled'),
            function checkIfJustOneFieldIsFilled(this) {
              const { parent } = this;
              if (parent.user && parent.unit) {
                return false;
              }
              return true;
            }
          )
      })
    )
  });

  const values = useMemo(
    () => ({ control, errors, setValue, watch, getValues, trigger, isSubmitting }),
    [control, errors, getValues, isSubmitting, setValue, trigger, watch]
  );

  const handleOnConfirm = (data: IAssignApplicationForm) => {
    if (data?.unit) {
      assignApplicationToOrganization(
        {
          applicationId,
          applicationUnitUpdateRequest: { version: applicationVersion, organizationUnit: data?.unit }
        },
        {
          onSuccess: () => {
            onFinish();
          }
        }
      );
    }
    if (data?.user) {
      assignApplicationToUser(
        {
          applicationId,
          applicationAssigneeUpdateRequest: { version: applicationVersion, assignedTo: data.user }
        },
        {
          onSuccess: () => {
            onFinish();
          }
        }
      );
    }
  };

  return (
    <Dialog
      title={t('processes:dialog.assignApplicationConfirm')}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={handleSubmit(handleOnConfirm)}
      onCancel={closeDialog}
      isConfirmLoading={isLoadingOrganization || isLoadingUser}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <GridLayout itemProps={{ xs: 12 }}>
          <UsersAutocompleteField
            fieldName={typedNameV2<IAssignApplicationForm>('user')}
            roles={userRoles}
            label={t('administration:groups.field.user')}
          />
          <OrganizationUnitAutocompleteField
            fieldName={typedNameV2<IAssignApplicationForm>('unit')}
            label={t('administration:groups.field.organizationUnit')}
            size={1000}
            onChange={() => trigger('user')}
          />
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default AssignApplicationToUserDialog;
