import { useCallback, useEffect, useState } from 'react';
import { Column, OnFilterChange } from 'react-table';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import moment from 'moment';

import { DatepickerInput, SEARCH_TEXT_DEBOUNCE_TIME, useDebounce, useHandleClearFilters } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

interface IProps {
  column: Column & { filterValue?: string; setFilter: (value: string) => void };
  views: 'year' | 'month';
  onFilterChange: OnFilterChange;
}

function CustomDatepicker({ views, column: { filterValue, setFilter }, onFilterChange }: IProps) {
  const getInitialValue = useCallback(() => {
    if (!filterValue) {
      return null;
    }
    if (views === 'month') {
      return moment().month(filterValue);
    }
    if (views === 'year') {
      return filterValue;
    }
    return moment([filterValue]);
  }, [filterValue, views]);

  const [value, setValue] = useState(getInitialValue());

  const onChangeDebounced = useDebounce(v => {
    setFilter(v);
    onFilterChange?.();
  }, SEARCH_TEXT_DEBOUNCE_TIME);
  const classes = useStyles();

  const handleClearInput = useCallback(() => {
    setValue(null);
  }, []);

  useHandleClearFilters(handleClearInput);

  useEffect(() => {
    setValue(getInitialValue());
  }, [filterValue, getInitialValue]);

  const handleChange = valueTochange => {
    const date = views === 'month' ? valueTochange?.month() : valueTochange?.year();
    setValue(valueTochange);

    if (views === 'month' && date === 0) {
      // fix dla stycznia, tabela nie łapie 0 jako truthy wartości
      onChangeDebounced(date.toString());
    }
    if (views === 'year') {
      onChangeDebounced(_.isNaN(date) ? null : date?.toString());
    } else {
      onChangeDebounced(date);
    }
  };

  return (
    <DatepickerInput
      inputClassName={classes.header}
      textInputClassName={classes.textInputRoot}
      value={value}
      onChange={handleChange}
      views={[views]}
      isFreeToFill
    />
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  header: {
    '& .MuiPickersCalendarHeader-root': {
      display: 'none'
    }
  },
  textInputRoot: {
    '& > div > div ': {
      padding: important(0),
      marginTop: important(0),
      '& > input': {
        padding: important('8px 0 8px 8px'),
        fontSize: important(theme.typography.textXs.normal.fontSize),
        fontWeight: important(theme.typography.textXs.normal.fontWeight),
        lineHeight: important(theme.typography.textXs.normal.lineHeight)
      }
    }
  }
}));

export default CustomDatepicker;
