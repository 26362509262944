import React from 'react';
import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface IProps {
  children: React.ReactNode;
}

export function Condition({ children }: IProps) {
  const classes = useStyles();
  return (
    <Paper className={classes.content}>
      <div className="flex-auto flex center-items">{children}</div>
    </Paper>
  );
}

const useStyles = makeStyles({
  content: {
    minHeight: 87,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 0 5px rgba(0, 0, 0, 0.12)'
  }
});
