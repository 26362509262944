import { QueryConfig, useQuery } from 'react-query';

import { API } from '@libs/domain/api';
import {
  GetVehicleCepikDetails,
  VehicleCepikDetails,
  VehicleCepikRequest,
  VehicleQueryKeysEnum
} from '@libs/domain/vehicle';

interface IResponseMessage {
  message?: string;
}

const getCepik = async (_: any, params: { vin: string; registrationNumber: string }) => {
  const { data } = await API.cepik(params?.vin ? { vin: params.vin } : { nrRejestracyjny: params.registrationNumber });
  const dataWithResponse = data as unknown as VehicleCepikRequest & IResponseMessage;

  const parsedData: VehicleCepikDetails & IResponseMessage = {
    vin: dataWithResponse.vin,
    plateNumber: dataWithResponse.nrRejestracyjny,
    brandKey: dataWithResponse.marka,
    manufactureYear: dataWithResponse.rokProdukcji,
    typeKey: dataWithResponse.rodzajPojazdu,
    seatsNumber: dataWithResponse.liczbaMiejscOgolem,
    acceptableCapacity: dataWithResponse.dopuszczalnaLadownosc,
    dmc: dataWithResponse.dopuszczalnaMasaCalkowita,
    modelKey: dataWithResponse.model,
    message: dataWithResponse.message
  };

  return parsedData;
};

export default function useCepikDataQuery(params: GetVehicleCepikDetails, queryConfig: QueryConfig<any, unknown> = {}) {
  return useQuery<VehicleCepikDetails>([VehicleQueryKeysEnum.CEPIK, params], getCepik, queryConfig);
}
