import { QueryConfig, useQuery } from 'react-query';
import { ForeignPermissionDetails } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { ForeignPermissionQueryEnum } from '../../model';
import { endpointsConfig } from '../endpoints-config';

const getForeignPermissionDetails = async ({ foreignPermissionId }) => {
  const { data } = await API.foreignPermissions.getForeignPermission(foreignPermissionId, {
    ...endpointsConfig.getForeignPermission
  });
  return data;
};

function useForeignPermitsDetailsQuery(
  params: {
    foreignPermissionId: string;
  },
  queryConfig: QueryConfig<ForeignPermissionDetails, unknown> = {}
) {
  return useQuery(
    [ForeignPermissionQueryEnum.FOREIGN_PERMISSIONS_DETAILS, params.foreignPermissionId],
    () => getForeignPermissionDetails(params),
    queryConfig
  );
}
export default useForeignPermitsDetailsQuery;
