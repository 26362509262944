import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import { makeStyles } from '@mui/styles';

import { important } from '@libs/common/v2/utils';

const CKEditorConfig: CKEditorContext['props']['config'] = {
  toolbar: { items: [] },
  ui: {
    viewportOffset: {
      top: Number.MAX_SAFE_INTEGER
    }
  }
};

function RichTextReadonly({ text, showDots, ...forwardProps }: { [_: string]: any; text: string }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CKEditor editor={InlineEditor} data={text} config={CKEditorConfig} disabled {...forwardProps} />
      {showDots && <span>. . .</span>}
    </div>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& > .ck': {
      overflow: important('hidden'),
      border: important('none'),
      boxShadow: important('none')
    },
    '& > .ck-focused': {
      border: important('none'),
      boxShadow: important('none')
    }
  }
}));

export default RichTextReadonly;
