import { useTableLayoutProps } from '@libs/common/v2';

import { useDocumentsColumns } from '@libs/domain/documents';
import { useProceedingDocumentsQuery } from '@libs/domain/proceeding';

function useProceedingDocumentsTable(proceedingId: string) {
  const { columns, mappedFilterFields, mappedSortFields } = useDocumentsColumns();

  return useTableLayoutProps({
    tableHookQuery: useProceedingDocumentsQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    },
    tableHookQueryInitialParams: {
      proceedingId
    }
  });
}

export default useProceedingDocumentsTable;
