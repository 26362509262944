import { usePaginatedQuery } from 'react-query';
import { InsurancePolicySearchFilter as InsurancePolicySearchClientFilter } from '@ibtm/client-domain';
import { InsurancePolicySearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { FolderQueryKeysEnum, InsurancePolicySnapshot, parseInsurancePoliciesListResults } from '@libs/domain/folder';

import { endpointsConfig } from '../../endpoints-config';

const getInsurance = async (params: InsurancePolicySearchFilter) => {
  const { data } = await API.insurancePolicies.getInsurancePoliciesPage(params, {
    ...endpointsConfig.getInsurancePoliciesPage
  });
  return { ...data, content: parseInsurancePoliciesListResults(data.content) };
};

const getInsuranceClient = async (params: InsurancePolicySearchClientFilter, isAssociation) => {
  let response = null;

  if (isAssociation) {
    const { data } = await API.client.insurancePolicies.getInsurancePoliciesPageForFolder('', '', params, {
      ...endpointsConfig.getInsurancePoliciesPageForFolder
    });
    response = { ...data, content: parseInsurancePoliciesListResults(data.content) };
  }

  return response;
};

export default function useInsurancePoliciesQuery(params: InsurancePolicySearchFilter, queryConfig = {}) {
  const { getQuery } = useGetApiQueryByPerspective();

  return usePaginatedQuery<IPaginatedModel<InsurancePolicySnapshot>>(
    [params, FolderQueryKeysEnum.INSURANCE_POLICIES],
    getQuery(getInsuranceClient, getInsurance),
    queryConfig
  );
}
