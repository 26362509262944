import { useCallback } from 'react';

import { FormMode, useDialog } from '@libs/common/v2';

import { AddOrUpdateLockDialog } from '../components/proceeding-details/tabs/formOperations/dialog';
import { IUpdateLockInitialData } from '../components/proceeding-details/tabs/formOperations/dialog/AddOrUpdateLockDialog';

function useAddOrUpdateLockDialog() {
  const { openDialog } = useDialog();

  const openAddOrUpdateLockDialog = useCallback(
    (formMode: FormMode, initialData: IUpdateLockInitialData) => {
      openDialog(({ closeDialog }) => (
        <AddOrUpdateLockDialog closeDialog={closeDialog} formMode={formMode} initialData={initialData} />
      ));
    },
    [openDialog]
  );

  return {
    openAddOrUpdateLockDialog
  };
}

export default useAddOrUpdateLockDialog;
