import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { queryCache } from 'react-query';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { TransportManagerExpireRequest } from '@ibtm/domain';
import * as Yup from 'yup';

import { DatepickerField, Dialog, FormV2Context, GridLayout, typedNameV2 } from '@libs/common/v2';
import { getCalendarDate } from '@libs/common/v2/utils';

import { TransportManagerQueryKeysEnum, useExpireTransportManagerMutation } from '../../../api';

interface ITransportManagerExpireDialog {
  closeDialog: () => void;
  id: string;
  version?: number;
}

type FormTypes = { expirationDate?: string };

const TransportManagerExpireDialog = ({ closeDialog, id, version }: ITransportManagerExpireDialog) => {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();

  const { mutate: expireTransportManager, isLoading } = useExpireTransportManagerMutation();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<FormTypes>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        expirationDate: Yup.date().nullable()
      })
    ),
    defaultValues: {
      expirationDate: null
    }
  });

  const onSubmit = ({ expirationDate }: FormTypes) => {
    expireTransportManager(
      { id, version, expirationDate: getCalendarDate(expirationDate) },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('transportManager:expire.successMessage'));
          queryCache.invalidateQueries(TransportManagerQueryKeysEnum.TRANSPORT_MANAGER_LIST);
          closeDialog();
        }
      }
    );
  };

  const values = useMemo(
    () => ({ control, errors, register, handleSubmit, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, handleSubmit, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      isOpen
      title={t('transportManager:expire.title')}
      titleSupportingText={t('transportManager:expire.confirmationMessage')}
      confirmText={t('action.save')}
      onConfirm={handleSubmit(onSubmit)}
      onCancel={closeDialog}
      isConfirmLoading={isLoading}
    >
      <FormV2Context.Provider value={values}>
        <GridLayout itemProps={{ xs: 12 }}>
          <DatepickerField
            name={typedNameV2<TransportManagerExpireRequest>('expirationDate')}
            label={t('transportManager:details.field.terminationDate')}
          />
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
};

export default TransportManagerExpireDialog;
