import { useDialog } from '@libs/common/v2';

import { ProxyDetailsDialog } from '../../components';

function useProxyDetailsDialog() {
  const { openDialog } = useDialog();

  const showProxyDetailsDialog = (proxyId: string, isCitizenshipFieldVisible?: boolean) => {
    openDialog(({ closeDialog }) => (
      <ProxyDetailsDialog
        isOpen
        onCloseDialog={closeDialog}
        isCitizenshipFieldVisible={isCitizenshipFieldVisible}
        proxyId={proxyId}
      />
    ));
  };

  return { showProxyDetailsDialog };
}

export default useProxyDetailsDialog;
