import { PluginHook } from 'react-table';
import { TransportManagersSearchFilter } from '@ibtm/domain';
import _ from 'lodash';

import { CamelCasePath, useTableLayoutProps } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { useTransportManagersQuery } from '../api';
import { TransportManagerListItemClient } from '../model';

import useTransportManagersTableColumns from './useTransportManagersTableColumns';

function useTransportManagersTable({
  visibleColumns,
  queryParams,
  tablePlugins,
  tableData
}: {
  visibleColumns: Array<CamelCasePath<TransportManagerListItemClient>>;
  queryParams: TransportManagersSearchFilter;
  tablePlugins?: PluginHook<Record<string, any>>[];
  tableData?: PluginHook<Record<string, any>>[];
}) {
  const { columns, mappedFilterFields, mappedSortFields } = useTransportManagersTableColumns(visibleColumns);

  const hasFolderIdParam = !_.isEmpty(queryParams?.folderIdIn);

  return useTableLayoutProps({
    ...(tableData ? { tableQuery: tableData } : { tableHookQuery: useTransportManagersQuery }),
    tableHookQueryInitialParams: {
      ...queryParams,
      ...(hasFolderIdParam && {
        statusKey: DomainDictionaryEntry.TRANSPORT_MANAGER_STATUS.CURRENT
      })
    },
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tablePlugins,
      tableOptions: {
        ...(hasFolderIdParam && {
          initialState: {
            sortBy: [{ id: 'surname', desc: false }]
          }
        })
      }
    }
  });
}

export default useTransportManagersTable;
