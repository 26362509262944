import { useTranslation } from 'react-i18next';
import { PullNotificationSnapshot } from '@avispon/message';

import { Dialog, GridLayout, Value } from '@libs/common/v2';
import { convertDateToDateTimeFormat } from '@libs/common/v2/utils';

import { useDownloadFileFromNotification } from '../../hooks';

interface IProps {
  notification: PullNotificationSnapshot;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

function MessageDetailsDialog({ notification, isOpen, setIsOpen }: IProps) {
  const [t] = useTranslation();

  const { downloadFileButton, hasFileToDownload } = useDownloadFileFromNotification({
    links: notification.links
  });

  return (
    <Dialog
      title={notification.subject || t('messages:dialog.messageDetailsTitle')}
      onClose={() => setIsOpen(false)}
      dialogSize="small"
      isCancelButtonVisible={false}
      isOpen={isOpen}
    >
      <GridLayout itemProps={{ xs: 12 }}>
        <Value label={t('messages:field.created')} value={convertDateToDateTimeFormat(notification.created)} />
        <Value
          label={t('messages:field.message')}
          value={
            <>
              {notification.message}
              {hasFileToDownload && downloadFileButton}
            </>
          }
        />
      </GridLayout>
    </Dialog>
  );
}

export default MessageDetailsDialog;
