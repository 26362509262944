import { QueryConfig, usePaginatedQuery } from 'react-query';
import { AnyFolderLimitSearchFilter, FolderExtendedLite, FolderLiteSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { ForeignPermissionQueryEnum, parseDisposablePermitsPayload } from '@libs/domain/foreign-permission';

import { endpointsConfig } from '../endpoints-config';

export const getFolderLite = async (_, params: AnyFolderLimitSearchFilter & FolderLiteSearchFilter) => {
  const { data } = await API.folder.getFoldersLitePage(parseDisposablePermitsPayload(params), {
    ...endpointsConfig.getFolderLimitsPage
  });
  return data;
};

function useFolderLiteQuery(
  params: AnyFolderLimitSearchFilter & FolderLiteSearchFilter,
  queryConfig: QueryConfig<IPaginatedModel<FolderExtendedLite>> = {}
) {
  return usePaginatedQuery<IPaginatedModel<FolderExtendedLite>>(
    [ForeignPermissionQueryEnum.FOLDER_LITE, params],
    getFolderLite,
    queryConfig
  );
}
export default useFolderLiteQuery;
