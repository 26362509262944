import { QueryConfig, useQuery } from 'react-query';

import { API } from '@libs/domain/api';

import { IDepotDetailsClient } from '../../../model';
import { parseDepotDetails } from '../../../utils';
import { endpointsConfig } from '../../endpoints-config';
import { ResourceQueryKeysEnum } from '..';

export const getDepotDetails = async (depotId: string) => {
  const { data } = await API.depots.getDepot(depotId, {
    ...endpointsConfig.getDepot
  });

  return parseDepotDetails(data);
};

const useDepotDetailsQuery = (depotId: string, queryConfig: QueryConfig<any, unknown>) =>
  useQuery<IDepotDetailsClient>(
    [ResourceQueryKeysEnum.DEPOT_DETAILS, depotId],
    () => getDepotDetails(depotId),
    queryConfig
  );

export default useDepotDetailsQuery;
