import { SingleUseSubmissionUpdateReceiptDateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function updateSingleUsePermissionSubmissionReceiptDate({
  singleUseSubmissionItemId,
  singleUseSubmissionUpdateReceiptDateRequest
}: {
  singleUseSubmissionItemId: string;
  singleUseSubmissionUpdateReceiptDateRequest: SingleUseSubmissionUpdateReceiptDateRequest;
}) {
  return API.civicBoard.updateSingleUsePermissionSubmissionReceiptDate(
    singleUseSubmissionItemId,
    singleUseSubmissionUpdateReceiptDateRequest,
    {
      ...endpointsConfig.updateSingleUsePermissionSubmissionDecision
    }
  );
}

export function useUpdateSingleUsePermissionSubmissionReceiptDateMutation() {
  return useMutation(updateSingleUsePermissionSubmissionReceiptDate);
}
