import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { AssociationSubjectSnapshot } from '@ibtm/domain';
import { object } from 'yup';

import { Button, TableLayout, useConfirmDialog, useTableRowActions } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { AssociationDetailsParams, AssociationQueryKeysEnum } from '@libs/domain/associations';
import { DomainUIElementEnum } from '@libs/domain/config';

import { useDeleteAssociationSubjectMutation } from '../../api';
import { useAssociationSubjectTable } from '../../hooks';

import AddEntrepreneurDialog from './AddEntrepreneurDialog';

function EntrepreneursSection() {
  const queryCache = useQueryCache();
  const { id } = useParams<AssociationDetailsParams>();
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const tableProps = useAssociationSubjectTable(id);
  const { mutateAsync: deleteAssociationSubject } = useDeleteAssociationSubjectMutation();
  const [isOpen, setIsOpen] = useState(false);

  const [confirm] = useConfirmDialog();

  const handleDelete = (subjectId: string) => {
    confirm({
      title: t('associations:action.deleteEnterpreneurFromAssociation'),
      shortTitle: t('global:dialog.deleteConfirmTitle'),
      message: t('associations:messages.deleteEntrepreneur'),
      onConfirm: (setLoading, closeDialog) => {
        setLoading(true);
        deleteAssociationSubject({ associationId: id, subjectId }).then(() => {
          showSuccessSnackbar(t('associations:messages.entrepreneurDeleteSuccess'));
          setLoading(false);
          closeDialog();
          queryCache.invalidateQueries(AssociationQueryKeysEnum.GET_ASSOCIATION_SUBJECT);
        });
      },
      confirmType: 'danger'
    });
  };
  useForm<Record<string, any>>({
    mode: 'onBlur',
    resolver: yupResolver(
      object({
        entrepreneurSelect: object().required()
      })
    )
  });

  const openAddDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const { renderTableActions } = useTableRowActions<AssociationSubjectSnapshot>([
    {
      id: 'ASSOCIATIONS_DETAILS_ENTREPRENEURS_DELETE_BUTTON',
      label: 'associations:action.delete',
      onClick: ({
        row: {
          original: {
            subject: { id }
          }
        }
      }) => handleDelete(id),
      icon: 'TrashIcon'
    }
  ]);

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('associations:entrepreneursListTitle')}
      headerActions={
        <Button
          isSecondary
          variant="outlined"
          label={t('action.add')}
          onClick={openAddDialog}
          actionKey={DomainUIElementEnum.ASSOCIATIONS_DETAILS_ENTREPRENEURS_ADD_BUTTON}
        />
      }
      rowActions={renderTableActions([DomainUIElementEnum.ASSOCIATIONS_DETAILS_ENTREPRENEURS_DELETE_BUTTON])}
      isSectionFullTab
    >
      {isOpen && <AddEntrepreneurDialog open={isOpen} setIsOpen={setIsOpen} />}
    </TableLayout>
  );
}

export default EntrepreneursSection;
