import { ReactNode } from 'react';

import { useAuthSelector } from '@libs/auth/store/reducers';

import { PermissionContextProvider } from '../providers';

interface IProps {
  children: ReactNode;
}

function PermissionContext({ children }: IProps) {
  const userPermissions = useAuthSelector(({ auth }) => auth.user.permissions);

  return <PermissionContextProvider userPermissions={userPermissions}>{children}</PermissionContextProvider>;
}

export default PermissionContext;
