import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import {
  MisspellApplicationDataCertificateDetails,
  MisspellApplicationDataDriverCertificateDetails
} from '@ibtm/domain';

import { TableButtonEdit, TableButtonView, TableLayout, useDialog } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { useFormDirtyContext, useMetaFormContext } from '@libs/meta-form/context';
import useHandleFormChanges from '@libs/meta-form/services/useHandleFormChanges';

import { useApplicationDetailsQuery } from '../../application/api';
import { useMisspellApplicationDriverCertificateTable } from '../hooks';
import { DialogModeEnum } from '../model/misspell-application.model';
import { parseDetailsToMisspellDriverCertificateFormValues } from '../utils/application-misspell.util';

import { PermissionDriverCertificateDialog } from './dialog/PermissionDriverCertificateDialog';

interface IProps {
  applicationId: string;
}

export function MisspellApplicationDriverCertificateTable({ applicationId }: IProps) {
  const [key, setKey] = useState<number>(0);
  const { formRef } = useMetaFormContext();
  const { data } = useApplicationDetailsQuery(applicationId, { refetchOnMount: false });

  const driverCertificate = data?.misspellApplicationDataDetails?.driverCertificate;

  const { viewMode } = useViewModesV2();
  const tableProps = useMisspellApplicationDriverCertificateTable(driverCertificate ? [driverCertificate] : []);
  const { isDirty } = useFormDirtyContext();
  const [t] = useTranslation();

  const { openDialog } = useDialog();

  useEffect(() => {
    setKey(prevKey => (prevKey += 1));
  }, [isDirty]);

  const openEditDialog = (row: Row<MisspellApplicationDataDriverCertificateDetails>) => {
    openDialog(({ closeDialog }) => {
      return (
        <PermissionDriverCertificateDialog
          applicationId={applicationId}
          permissionData={parseDetailsToMisspellDriverCertificateFormValues({
            ...row.original.certificate,
            applicationVersion: data.version
          })}
          updatePermissionData={parseDetailsToMisspellDriverCertificateFormValues({
            ...row.original.certificateUpdated,
            applicationVersion: data.version
          })}
          closeDialog={closeDialog}
          mode={DialogModeEnum.EDIT}
        />
      );
    });
  };
  const { handleFormChanges } = useHandleFormChanges(isDirty, formRef?.current?.onSubmit, formRef?.current?.onReset);

  const rowActions = (row: Row<MisspellApplicationDataDriverCertificateDetails>) => (
    <>
      <TableButtonView
        tooltipTitle={t('applications:misspellApplication.action.showChanges')}
        onClick={() =>
          openDialog(({ closeDialog }) => (
            <PermissionDriverCertificateDialog
              applicationId={applicationId}
              permissionData={parseDetailsToMisspellDriverCertificateFormValues({
                ...row.original.certificate,
                applicationVersion: data.version
              })}
              updatePermissionData={parseDetailsToMisspellDriverCertificateFormValues({
                ...row.original.certificateUpdated,
                applicationVersion: data.version
              })}
              closeDialog={closeDialog}
              mode={DialogModeEnum.COMPARE}
            />
          ))
        }
      />
      {!viewMode && (
        <TableButtonEdit
          onClick={() => {
            handleFormChanges(() => {
              openEditDialog(row);
            });
          }}
        />
      )}
    </>
  );

  return (
    <TableLayout<MisspellApplicationDataCertificateDetails, unknown>
      key={key}
      pageTitle={t('applications:misspellApplication.table.driverCertificate')}
      {...tableProps}
      isFilterEnabled={false}
      isRefreshEnabled={false}
      rowActions={rowActions}
      isSection
    />
  );
}
