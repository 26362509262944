import { useTranslation } from 'react-i18next';

import { BackButton, Divider, TableLayout, useRouter } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { useDisposalsTable } from '../../../hooks';
import { parseDisposalsList } from '../../../utils';

function DisposalsTable() {
  const [t] = useTranslation();
  const { goBack } = useRouter();

  const tableProps = useDisposalsTable();

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('resource:list.disposalsListTitle')}
      xlsxDownload={{
        fileName: t('resource:list.disposalsListTitle'),
        pathToXLSXTranslation: 'resource:fields',
        apiRequest: params =>
          API.resourceObjects
            .getResourceObjectDisposals(params)
            .then(res => ({ ...res, data: { ...res.data, content: parseDisposalsList(res.data.content) } }))
      }}
      headerActions={
        <>
          <Divider orientation="vertical" style={{ height: 30, margin: '0px 8px' }} />
          <BackButton onClick={() => goBack()} />
        </>
      }
      isRefreshEnabled
    />
  );
}

export default DisposalsTable;
