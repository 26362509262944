import { useWindowSize } from 'react-use';

import { useThemeValuesContext } from '../../theme';

export function useIsSmallScreen() {
  const { width } = useWindowSize();
  const globalTheme = useThemeValuesContext();
  const isSmallScreen = width < globalTheme?.breakpoints?.values?.md;

  return { isSmallScreen };
}
