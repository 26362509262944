import { useMemo } from 'react';
import { ResourceTypeSearchFilter } from '@ibtm/domain';

import { AutocompleteLazyFetchSelectField } from '@libs/common/v2';

import { useContextSelector, useDictionaryTranslations } from '@libs/dictionary';

import { API } from '@libs/domain/api';
import { DomainDictionaryEnum } from '@libs/domain/config';

import { ResourceQueryKeysEnum } from '../../../api';

export function ResourceTypeAutocompleteField({
  name,
  label,
  filterFunction,
  searchFilter
}: Pick<React.ComponentProps<typeof AutocompleteLazyFetchSelectField>, 'name' | 'label' | 'filterFunction'> & {
  searchFilter?: ResourceTypeSearchFilter;
}) {
  const { translate } = useDictionaryTranslations();

  const dictionaryData = useContextSelector(({ context }) => context.context.data.dictionary);
  const resourceFormNameDictionaryEntries = useMemo(() => {
    return dictionaryData[DomainDictionaryEnum.RESOURCE_FORM_NAME]?.entries || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const optionLabelParser = option => {
    return translate(DomainDictionaryEnum.RESOURCE_FORM_NAME, option.nameKey);
  };

  const fetchNameOptionsFunction = (searchText: string, params: { page: number; size: number }) => {
    return API.resourceTypes.getResourceTypes({
      ...params,
      nameKeyIn: searchText
        ? resourceFormNameDictionaryEntries
            .filter(entry => entry.name.toLowerCase().includes(searchText.toLowerCase()))
            .map(entry => entry.value)
        : [],
      ...searchFilter
    });
  };

  return (
    <AutocompleteLazyFetchSelectField
      name={name}
      label={label}
      queryKey={ResourceQueryKeysEnum.RESOURCE_TYPES_FIELD}
      optionLabelParser={optionLabelParser}
      filterFunction={filterFunction}
      api={{
        FETCH: fetchNameOptionsFunction
      }}
    />
  );
}
