import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { PaginatedQueryResult, QueryResult } from 'react-query';
import { PluginHook, Row, TableInstance, TableOptions } from 'react-table';

import {
  IPaginatedModel,
  ITableAdapter,
  ITableLayoutColumn,
  Path,
  TableEvent,
  useActionColumn,
  useMutableTable,
  useTableEventsContext
} from '@libs/common/v2';

import { IPaginatedQueryTableOptions, IQueryTableOptions, PaginationSearchParams } from '.';

interface IParams<T extends Record<string, any>, K extends Record<string, any> = T> {
  /**
   * Tego atrybutu używamy gdy paginacja jest po stronie frontu.
   * Wywoła to hook useQueryTable, ale z najświeższymi parametrami globalnymi
   */
  tableQuery?: (
    dataQuery: ((params) => Promise<T[]>) | T[],
    options: IQueryTableOptions<T, K>,
    queryInitialParams?: any,
    dataHookQueryConfig?: Record<string, any>,
    queryKey?: string
  ) => [ITableAdapter<T, K>, QueryResult<T[], unknown>, { isLoading: boolean; newRowScheme?: Row<T> }];
  /**
   * Tego atrybutu używamy gdy paginacja jest po stronie backend.
   * Wywoła to hook usePaginatedQueryTable, ale z najświeższymi parametrami globalnymi
   */
  tableHookQuery?: (
    usePaginatedDataHook: (params, options, queryKey) => PaginatedQueryResult<IPaginatedModel<T>>,
    options: IPaginatedQueryTableOptions<T, K>,
    queryInitialParams?: any,
    paginatedDataHookQueryConfig?: Record<string, any>,
    queryKey?: string
  ) => [
    ITableAdapter<T, K>,
    PaginatedQueryResult<IPaginatedModel<T>>,
    Record<string, any>,
    Dispatch<SetStateAction<PaginationSearchParams>>
  ];
  /**
   * Funkcja zwracająca promise z danymi do tabeli, zostanie osadzona w react-query hook.
   */
  query:
    | ((params, options, queryKey) => PaginatedQueryResult<IPaginatedModel<T>>)
    | ((params) => Promise<T[]>)
    | ((params) => Promise<IPaginatedModel<T>>)
    | T[];
  /**
   * Parametry początkowe (sortowanie, globalne filtry, dodatkowe filtry)
   */
  tableHookQueryInitialParams?: Record<string, any>;
  /**
   * Obiekt z dodatkową konfiguracją query.
   */
  dataHookQueryConfig?: Record<string, any>;
  /**
   * Dodatkowy key przekazywany do wywołanego hook
   */
  tableHookQueryKey?: string;
  /**
   * Obiekt zawieracjący propsy używane do tworzenia instancji tabeli.
   */
  tableHookOptions: {
    /**
     * @type T - model Frontend/Backend.
     * @type K - opcjonalny model Backend. Wykorzystywany przy mappowaniu wartości do pliku xlsx
     * @konwencja typ `T`to model zawierający definicje danych do tabeli. Może być modelem backend'u,
     * jeżeli na nim jest oparta implementacja gui, lub jeżeli dane są parsowane i dostosowane go modelu
     * frontend'owego, który jest rozbieżny z modelem backend wtedy do typu `T` przypisujemy model frontend,
     * natomiast do typu `K` przypisujemy model backend. W ten sposób przy mappowaniu wartości do pliku xlsx
     * mamy dostęp do atrybutów z modelu backend.
     * @implementacja Zakłada użycie którejś z funkcji hook'a useCreateColumns.
     * Tablica obiektów na podstawie których zostają wyrenderowane kolumny tabeli.
     * Lub funkcja w której dostajemy jako argument obiekt,
     * który przekazujemy dalej do fukcji tworzącej kolumny (createColumnsWithArrays)
     */
    columns: ITableLayoutColumn<T, K>[] | ((mapper: Record<string, number>) => ITableLayoutColumn<T, K>[]);
    /**
     * Ścieżki w modelu do tablic z danymi
     */
    arrayPaths?: Path<T>[];
    /**
     *  Konwerter filtrów tabeli (konwersja wartości filtra na wartość przyjmowaną przez api)
     */
    filterConverter?: Record<string, (value: unknown) => Record<string, any>>;
    /**
     * Konwerter sortowania tabeli (konwersja klucza tabeli na klucz przyjmowany przez api)
     */
    sortByConverter?: Partial<Record<keyof T, any>>;
    /**
     *  Konwerter filtrów globalnych i dodatkowych
     *  (konwersja wartości filtra na wartość przyjmowaną przez api)
     */
    initialParamsConverter?: Record<string, (value: unknown) => Record<string, any>>;
    /**
     * Dodatkowe opcje do tabeli
     */
    tableOptions?: Partial<TableOptions<T>>;
    /**
     * Dodatkowe pluginy do tabeli. Nie trzeba tutaj przekazywać useActionColumn,
     * ponieważ ten plugin jest dodawany wewnątrz hook'a.
     * Decyduje o tym parametr `isActionColumnEnabled` - domyślnie `true`
     */
    tablePlugins?: Array<PluginHook<T>>;
    /**
     * nie zawsze w modelu mamy id, zmiana id na inny parametr
     */
    getRowId?: (row) => string;
  };
  /**
   * Wartość decyduje czy tabela ma być mutowalna
   */
  isTableMutable?: boolean;
  /**
   * Wartość decyduje czy tabela ma posiadać kolumnę akcji
   */
  isActionColumnEnabled?: boolean;
}

/**
 * Tego hook'a należy użyć tworząc customową tabelę ( przy użyciu komponentu TableWithoutHook).
 * i jako parametr do niego przekazać wybrany hook z exportowanych w pliku index
 */
function useCustomTableQuery<T extends Record<string, any>, K extends Record<string, any> = T>({
  tableQuery: customTableQuery,
  tableHookQuery,
  query,
  tableHookQueryInitialParams,
  dataHookQueryConfig,
  tableHookOptions: { tableOptions, tablePlugins, ...restTableOptions },
  tableHookQueryKey,
  isTableMutable,
  isActionColumnEnabled
}: IParams<T, K>) {
  const [tableQueryParams, setTableQueryParams] = useState<Record<string, unknown>>(tableHookQueryInitialParams ?? {});

  useEffect(() => {
    // Pozwala na poprawne pobranie(odświeżenie) danych w tabeli, w sytuacji gdy zmieniają się tylko tableHookQueryInitialParams
    if (tableHookQueryInitialParams && Object.keys(tableHookQueryInitialParams).length !== 0) {
      setTableQueryParams(tableHookQueryInitialParams);
    }
  }, [tableHookQueryInitialParams]);

  const pickPlugins = useCallback(() => {
    if (isTableMutable) {
      return [isActionColumnEnabled && useActionColumn, useMutableTable, ...tablePlugins].filter(Boolean);
    }
    if (isActionColumnEnabled) {
      return [useActionColumn, ...tablePlugins];
    }
    return [...tablePlugins];
  }, [isTableMutable, isActionColumnEnabled, tablePlugins]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const getQuery = () => {
    if (customTableQuery) {
      return customTableQuery(
        query as ((params) => Promise<T[]>) | T[],
        {
          tablePlugins: pickPlugins(),
          tableOptions,
          ...restTableOptions
        },
        tableQueryParams,
        dataHookQueryConfig,
        tableHookQueryKey
      );
    }
    return tableHookQuery(
      query as (params, options, queryKey) => PaginatedQueryResult<IPaginatedModel<T>>,
      {
        tablePlugins: pickPlugins(),
        tableOptions,
        ...restTableOptions
      },
      tableQueryParams,
      dataHookQueryConfig,
      tableHookQueryKey
    );
  };

  const [table, tableQuery, additionalData, setParams] = getQuery() as [
    ITableAdapter<any, any>,
    PaginatedQueryResult<IPaginatedModel<T>> | QueryResult<T[], unknown>,
    { isLoading: boolean; newRowScheme: T },
    Dispatch<SetStateAction<PaginationSearchParams>>
  ];
  const { emitEvent } = useTableEventsContext();
  return useMemo(() => {
    const customQuery: {
      table: Omit<TableInstance<any>, 'columns'> & { columns: ITableLayoutColumn<T, K>[] };
      tableQuery:
        | QueryResult<T[], unknown>
        | PaginatedQueryResult<IPaginatedModel<T>>
        | PaginatedQueryResult<IPaginatedModel<T[]>>;
      additionalData: Record<string, any>;
      tableQueryParams: Record<string, any>;
      setTableQueryParams: Dispatch<SetStateAction<Record<string, unknown>>>;
    } = {
      table,
      tableQuery,
      additionalData,
      tableQueryParams,
      setTableQueryParams: state => {
        table.gotoPage(0);
        setParams?.(prev => ({
          ...prev,
          pageIndex: 0
        }));
        setTableQueryParams(state);
      }
    };
    const { clearFilters } = table;
    table.clearFilters = () => {
      emitEvent(TableEvent.ON_FILTER_CLEAR);
      setTimeout(() => {
        clearFilters();
      }, 0);
    };

    return { ...customQuery };
  }, [additionalData, emitEvent, table, tableQuery, tableQueryParams, setParams]);
}

export default useCustomTableQuery;
