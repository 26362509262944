import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DriverDetails, DriversSearchFilter } from '@ibtm/domain';
import { every, isString } from 'lodash';

import {
  CamelCasePath,
  Dialog,
  ForwardTableState,
  useForwardedSelectedRowsAction,
  useIsSmallScreen
} from '@libs/common/v2';
import { useSingleSelectColumnWithHandleClick } from '@libs/common/v2/components/table/hooks/custom-plugins/select-columns/single-select/useSingleSelectColumn';

import { DriversTable } from '@libs/domain/drivers/components';
import { useConfirmAddDriverDialog } from '@libs/domain/drivers/hooks';
import { DriversColumnsEnum } from '@libs/domain/drivers/model';

import { useDomainConfigContext } from '../../../config';
import { IDriverTableOptions } from '../../hooks/tables/useDriversTable';
import useWarningDriverHasCertificate from '../../hooks/useWarningDriverHasCertificate';

interface IProps {
  folderId: string;
  transferredFoldersIds?: string[];
  applicationId: string;
  closeDialog: () => void;
  tableOptions?: IDriverTableOptions;
  searchParams?: DriversSearchFilter;
}

const VISIBLE_COLUMNS: Array<CamelCasePath<DriverDetails>> = [
  DriversColumnsEnum.NAME,
  DriversColumnsEnum.SURNAME,
  DriversColumnsEnum.BIRTHDATE,
  DriversColumnsEnum.DRIVER_LICENSE_SERIES_NUMBER,
  DriversColumnsEnum.ID_SERIES_NUMBER,
  DriversColumnsEnum.DRIVER_CERTIFICATE_FORM_NUMBER,
  DriversColumnsEnum.DRIVER_CERTIFICATE_STATUS,
  DriversColumnsEnum.CITIZENSHIP,
  DriversColumnsEnum.BIRTHPLACE
];

function DriverSearchDialog({
  applicationId,
  folderId,
  transferredFoldersIds,
  closeDialog,
  searchParams,
  tableOptions = {
    initialState: {}
  }
}: IProps) {
  const { isClientPortal } = useDomainConfigContext();
  const [t] = useTranslation();

  const { openConfirmAddDriverDialog } = useConfirmAddDriverDialog();
  const { isSmallScreen } = useIsSmallScreen();
  const [driverId, setDriverId] = useState<string>(null);
  const { notificationComponent } = useWarningDriverHasCertificate(folderId, driverId, transferredFoldersIds);
  const { forwardTableStateRef, getSelectedIds, handleDisableAction, isActionDisabled } =
    useForwardedSelectedRowsAction();

  return (
    <Dialog
      title={t('drivers:dialogs.searchDriverTitle')}
      confirmText={t('action.add')}
      cancelText={t('action.close')}
      onConfirm={() => {
        const selectedIds = getSelectedIds();
        openConfirmAddDriverDialog(every(selectedIds, isString) ? (selectedIds as string[]) : [], applicationId);
      }}
      onCancel={closeDialog}
      dialogSize="large"
      isConfirmButtonDisabled={isActionDisabled}
      isOpen
      isFullScreen={isSmallScreen}
    >
      {notificationComponent}
      <DriversTable
        isSection
        visibleColumns={VISIBLE_COLUMNS}
        visibleActionButtons={{ view: [], tableRow: { visible: [], more: [] } }}
        tableOptions={tableOptions}
        tablePlugins={[
          // @ts-ignore
          useSingleSelectColumnWithHandleClick.bind(null, (row: { id: string }) => {
            setDriverId(row?.id);
          })
        ]}
        additionalData={{
          folderId,
          transferredFoldersIds
        }}
        isActionColumnEnabled={false}
        isHeaderHidden
        queryParams={{
          ...(isClientPortal ? { folderIdEq: folderId } : { folderIdIn: [folderId, ...(transferredFoldersIds || [])] }),
          applicationIdNotIn: [applicationId],
          ...searchParams
        }}
      >
        <ForwardTableState ref={forwardTableStateRef} setState={handleDisableAction} />
      </DriversTable>
    </Dialog>
  );
}
export default DriverSearchDialog;
