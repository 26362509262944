import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MisspellApplicationDataRecordUpdateRequest } from '@ibtm/domain';
import moment from 'moment/moment';

import { DatePickerFieldValidation } from '@libs/common/v2';

function useMisspellApplicationPermissionFormFieldsValidation() {
  const [t] = useTranslation();

  const validToFieldValidation: DatePickerFieldValidation = useMemo(
    () => ({
      required: [],
      test: [
        {
          name: 'validTo',
          message: t('applications:validations.dateCanNotBeBeforeValidFromDate'),
          test(validTo) {
            const { validFrom } = this.parent as MisspellApplicationDataRecordUpdateRequest;
            return !validFrom || !validTo || moment(validFrom).isBefore(validTo);
          }
        }
      ]
    }),
    [t]
  );

  return { validToFieldValidation };
}

export default useMisspellApplicationPermissionFormFieldsValidation;
