import { useDialog } from '@libs/common/v2';

import { FolderChangeForm } from '../../components/form';
import { IProceedingDetailsClient } from '../../models';

const useFolderChangeDialog = () => {
  const { openDialog } = useDialog();

  const open = (initialData: IProceedingDetailsClient, { onSuccess }: { onSuccess?: () => void }) => {
    openDialog(({ closeDialog }) => {
      return <FolderChangeForm initialData={initialData} onSuccess={onSuccess} closeDialog={closeDialog} />;
    });
  };

  return {
    openFolderChangeDialog: open
  };
};

export default useFolderChangeDialog;
