export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsBinaryString(blob);

    reader.onload = () => {
      if (typeof reader.result === 'string') {
        const b64 = btoa(reader.result);
        resolve(b64);
      }
      return null;
    };
  });
};
