import { QueryConfig, useQuery } from 'react-query';
import { FolderPermissionSnapshot } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';
import { FolderQueryKeysEnum } from '..';

export const getFolderPermissions = async (folderId: string) => {
  const { data } = await API.folder.getFolderPermissions(folderId, {
    ...endpointsConfig.getFolderPermissions
  });

  return data;
};

export default function useFolderPermissionsQuery(
  folderId: string,
  queryConfig: QueryConfig<FolderPermissionSnapshot[], unknown> = {}
) {
  return useQuery<FolderPermissionSnapshot[]>(
    [FolderQueryKeysEnum.FOLDER_PERMISSIONS, folderId],
    () => getFolderPermissions(folderId),
    queryConfig
  );
}
