import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '@enigma/fe-ui';
import { ReportDetailsLite } from '@stack/report';
import { AxiosPromise } from 'axios';

import {
  Button,
  DropdownButton,
  DropdownListButton,
  IconButton,
  PageHeader,
  useFormV2Context,
  useFormV2DirtyWatch,
  useRouter,
  useTab
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { useViewModesV2 } from '@libs/common/v2/form';

import { useDictionaryTranslations } from '@libs/dictionary';
import { useFileDownload } from '@libs/file';
import { ReportDetailsParams, ReportTypeDetailsTabsEnum } from '@libs/report';
import {
  API,
  ReportQueryKeysEnum,
  useEditReportTypeMutation,
  useReportDetailsQuery,
  useReportTypeDetailsQuery
} from '@libs/report/api';
import { ReportUIElementEnum } from '@libs/report/config';
import { useReportFiles } from '@libs/report/hooks';
import useReportGeneration from '@libs/report/hooks/useReportGeneration';
import { ReportTypeForm } from '@libs/report/model/form.model';
import { ReportTypeEditConverter } from '@libs/report/utils/converter.util';
import { ReportDictionaryEntryNameEnum } from '@libs/report-core';

interface IProps {
  onSubmit: () => void;
  reportTypeName?: string;
  isSubmitting?: boolean;
  setGeneratedReport: (data: ReportDetailsLite) => void;
  title: string;
  breadcrumbsTitle: string;
}

function ReportTypePageHeaderV2({
  reportTypeName,
  onSubmit,
  isSubmitting,
  setGeneratedReport,
  title,
  breadcrumbsTitle
}: IProps) {
  const queryCache = useQueryCache();
  const { id } = useParams<ReportDetailsParams>();
  const { formMode, getValues, handleSubmit, watch } = useFormV2Context();
  const { isDirty } = useFormV2DirtyWatch();
  const { routes } = useRouter();
  const { download, isLoading: isDownloading } = useFileDownload();
  const [generatedReportId, setGeneratedReportId] = useState<string>(null);
  const [activeTab, setActiveTab] = useTab<ReportTypeDetailsTabsEnum>(ReportTypeDetailsTabsEnum.DEFINITION);
  const { data } = useReportDetailsQuery(
    {
      reportId: generatedReportId
    },
    {
      enabled: !!generatedReportId
    }
  );
  const { createMode, editMode } = useViewModesV2(formMode);
  const [generateReport] = useReportGeneration(
    reportTypeName,
    null,
    {
      parameters: data?.commonDynamicParametersValues,
      targetTypes: data?.targetTypes
    },
    editMode
  );
  const { mutate: editReportType, isLoading } = useEditReportTypeMutation();
  const { data: reportType } = useReportTypeDetailsQuery({ reportTypeId: id });
  const { showSuccessSnackbar } = useSnackbar();
  const { translate } = useDictionaryTranslations();
  const resultFilesOptions = Object.entries(data?.resultFiles ?? {}).map(([mime, fileId]) => ({ mime, fileId }));
  const reportFiles = useReportFiles(data);
  const fields = watch('dataSetDefinitions');
  const isDownloadEnabled = Boolean(Object.values(reportFiles.files)?.length);
  const [t] = useTranslation();
  const isSubmitButtonDisabled = useMemo(() => !isDirty || fields?.length === 0, [isDirty, fields]);

  const handleGenerateReport = handleSubmit(() => {
    const onHandleGenerateReport = () =>
      generateReport(null, null, response => {
        setGeneratedReport(response);
        setGeneratedReportId(response?.id);
        setActiveTab(ReportTypeDetailsTabsEnum.VIEW);
        queryCache.invalidateQueries(ReportQueryKeysEnum.REPORT_TYPE_DETAILS);
      });

    if (isDirty) {
      editReportType(
        {
          formData: ReportTypeEditConverter.formToApi(getValues() as ReportTypeForm, reportType?.version),
          reportTypeId: reportType?.id
        },
        {
          onSuccess: () => {
            showSuccessSnackbar(t('reports:message.reportTypeEditSuccess'));
            queryCache.invalidateQueries(ReportQueryKeysEnum.REPORT_TYPE_DETAILS);
            onHandleGenerateReport();
          }
        }
      );
    } else {
      onHandleGenerateReport();
    }
  });

  const handleDownloadReportDataFiles = async () => {
    await download(() => API.report.getReportDataFiles(generatedReportId, { responseType: 'blob' }) as AxiosPromise);
  };

  const handleDownloadSingleFile = async (fileId: string) => {
    await download(() => API.file.download(fileId, '', undefined, { responseType: 'blob' }));
  };

  return (
    <PageHeader
      title={title}
      breadcrumbs={[{ to: '/reports', text: t('reports:list.title') }, { text: breadcrumbsTitle }]}
      rightSideContent={
        <div className="flex flex-wrapped-padding-16">
          {editMode && (
            <IconButton
              isBackgroundTransparent
              icon="PlayArrowIcon"
              height={20}
              width={20}
              onClick={handleGenerateReport}
              isLoading={isLoading}
              actionKey={ReportUIElementEnum.REPORT_GENERATE_BUTTON}
            />
          )}
          <Button
            label={t('action.close')}
            type="button"
            variant="outlined"
            link={routes.reportsList()}
            isPrimary
            isNoMargin
          />
          {generatedReportId && (
            <Button
              label={t('reports:action.downloadReportData')}
              variant="outlined"
              isLoading={isDownloading}
              onClick={handleDownloadReportDataFiles}
              isNoMargin
            />
          )}
          {isDownloadEnabled && activeTab === ReportTypeDetailsTabsEnum.VIEW && (
            <DropdownButton
              title={t('reports:action.downloadReport')}
              buttonType="button"
              variant="outlined"
              isNoMargin
            >
              {() =>
                resultFilesOptions.map(({ mime, fileId }) => (
                  <DropdownListButton
                    key={fileId}
                    label={translate(ReportDictionaryEntryNameEnum.DOCUMENT_TARGET_MIME_TYPE, mime)}
                    onClick={() => handleDownloadSingleFile(fileId)}
                  />
                ))
              }
            </DropdownButton>
          )}
          {(createMode || editMode) && (
            <Button
              label={t('action.save')}
              isLoading={isSubmitting || isLoading}
              onClick={onSubmit}
              disabled={!isDirty || isSubmitButtonDisabled}
              actionKey={ReportUIElementEnum.REPORT_TYPE_SAVE_BUTTON}
              isNoMargin
              isPrimary
            />
          )}
        </div>
      }
    />
  );
}

export default ReportTypePageHeaderV2;
