import { InputMode } from '@libs/common/v2';

import { DictionaryField } from '@libs/meta-form/fields';
import { DictionaryFieldProperties, FieldProps } from '@libs/meta-form/models';
import { useElementVisibility } from '@libs/permission';

import { DomainUIElementEnum } from '@libs/domain/config';

function CabotageCountryDictionaryField(props: FieldProps<DictionaryFieldProperties>) {
  const { checkIsElementVisible } = useElementVisibility();

  return (
    <DictionaryField
      {...(!checkIsElementVisible(DomainUIElementEnum.APPLICATION_CABOTAGE_EDIT_COUNTRY) && {
        inputMode: InputMode.VIEW
      })}
      {...props}
    />
  );
}

export default CabotageCountryDictionaryField;
