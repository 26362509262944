import { ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PluginHook, Row, SortingRule } from 'react-table';
import { useSnackbar } from '@enigma/fe-ui';
import { PermissionSearchFilter, PermissionsSnapshotPage } from '@ibtm/domain';
import { DomainErrorCodes } from '@libs/config';
import { AxiosPromise, AxiosResponse } from 'axios';
import moment from 'moment';
import { object as YupObject, string as YupString } from 'yup';

import { partialTranslate, TranslationFrom } from '@libs/common';
import {
  ALTERNATIVE_DATE_FORMAT,
  CamelCasePath,
  FormMode,
  SelectOptionPartial,
  TableButton,
  TableButtonMore,
  TableContext,
  TableHeaderButton,
  TableIconButton,
  TableLayout
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { isDateValid } from '@libs/common/v2/form/validation';
import { AxiosErrorResponseType } from '@libs/common/v2/models';
import { getCalendarDate } from '@libs/common/v2/utils';

import { UiMode } from '@libs/meta-form';
import { useMetaFormContext } from '@libs/meta-form/context';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { PermissionTableEnum } from '@libs/domain/application';
import { endpointsConfig } from '@libs/domain/permission/api/endpoints-config';

import { DomainUIElementEnum } from '../../config';
import { useUpdatePermissionMutation } from '../api';
import PermissionsQueryKeysEnum from '../api/queries/PermissionsQueryKeysEnum';
import {
  PERMISSIONS_DEFAULT_COLUMNS,
  usePermissionDeleteAction,
  usePermissionDialog,
  usePermissionPassToDistrictAuthorityDialog,
  usePermissionRestoreFromDistrictAuthorityDialog,
  usePermissionsTable
} from '../hooks';
import {
  PermissionActions,
  PermissionsColumnsEnum,
  PermissionSnapshotClient,
  PermissionStatusEnum,
  PermissionTypeEnum
} from '../model';
import { parsePermissionsList } from '../parsers';
import { editablePermissionValidityKeys } from '../utils';

import PermissionWarnings from './PermissionWarnings';

export interface IPermissionsTable {
  applicationId?: string;
  titleKey: TranslationFrom<'permission:title.permission'>;
  visibleColumns?: Array<CamelCasePath<PermissionSnapshotClient>>;
  editableColumns?: Array<CamelCasePath<PermissionSnapshotClient>>;
  headerActionsEnabled?: Array<PermissionActions>;
  rowActionsEnabled?: Array<PermissionActions>;
  headerActions?: ReactNode;
  rowActions?: (row: Row<PermissionSnapshotClient>, isTableMutable?: boolean) => ReactNode;
  tablePlugins?: Array<PluginHook<PermissionSnapshotClient>>;
  initialParams?: Omit<PermissionSearchFilter, 'typeKeyIn'> & { typeKeyIn?: Array<PermissionTypeEnum> };
  permissionType?: PermissionTableEnum;
  actionsColumnWidth?: number;
  isWarningEnabled?: boolean;
  isTableMutable?: boolean;
  // Props do ustawienia filtrowania pola NIP w formularzu zezwolenia
  subjectNameColumnHeader?: string;
  permissionNumberColumnHeader?: string;
  validFromColumnHeader?: string;
  defaultSort?: SortingRule<PermissionSnapshotClient>[];
  isTableDataVisible?: boolean;
  isHeaderActions?: boolean;
  dateFromAndToWithoutChips?: boolean;
}

function PermissionsTable({
  applicationId,
  titleKey,
  visibleColumns = PERMISSIONS_DEFAULT_COLUMNS,
  editableColumns,
  headerActionsEnabled,
  rowActionsEnabled,
  headerActions,
  rowActions,
  tablePlugins,
  initialParams,
  permissionType,
  actionsColumnWidth,
  isWarningEnabled = true,
  isTableMutable,
  subjectNameColumnHeader,
  permissionNumberColumnHeader,
  validFromColumnHeader,
  defaultSort,
  isTableDataVisible = true,
  isHeaderActions = true,
  dateFromAndToWithoutChips
}: IPermissionsTable) {
  const queryCache = useQueryCache();

  const [t] = useTranslation();
  const getTitle = partialTranslate('permission:title.permission');
  const { getQuery } = useGetApiQueryByPerspective();
  const tableProps = usePermissionsTable(
    visibleColumns,
    initialParams,
    editableColumns,
    tablePlugins,
    isTableMutable,
    subjectNameColumnHeader,
    permissionNumberColumnHeader,
    validFromColumnHeader,
    permissionType,
    defaultSort,
    isTableDataVisible,
    dateFromAndToWithoutChips
  );
  const title = useMemo(() => getTitle(titleKey), [getTitle, titleKey]);
  const { mode } = useMetaFormContext();

  const { showPermissionRestoreFromDistrictAuthorityDialog } = usePermissionRestoreFromDistrictAuthorityDialog();
  const { showPermissionPassToDistrictAuthorityDialog } = usePermissionPassToDistrictAuthorityDialog();
  const { showPermissionsDetailDialog } = usePermissionDialog({
    applicationId,
    permissionType
  });

  const { handlePermissionDelete } = usePermissionDeleteAction();
  const { mutateAsync: updatePermission } = useUpdatePermissionMutation();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbar();

  const showHeaderAction = useCallback(
    (action: PermissionActions) => headerActionsEnabled?.includes(action),
    [headerActionsEnabled]
  );

  const getValidationScheme = () => {
    const isColumnEditable = name => editableColumns?.includes(name);

    return YupObject({
      ...(isColumnEditable(PermissionsColumnsEnum.PERMISSION_NUMBER) && {
        [PermissionsColumnsEnum.PERMISSION_NUMBER]: YupString().required().nullable()
      }),
      ...(isColumnEditable(PermissionsColumnsEnum.FORM_NUMBER) && {
        [PermissionsColumnsEnum.FORM_NUMBER]: YupString().required().nullable()
      }),
      ...(isColumnEditable(PermissionsColumnsEnum.PRINT_DATE) && {
        [PermissionsColumnsEnum.PRINT_DATE]: YupString().required().nullable().concat(isDateValid())
      }),
      ...(isColumnEditable(PermissionsColumnsEnum.VALID_FROM) && {
        [PermissionsColumnsEnum.VALID_FROM]: YupString().required().nullable().concat(isDateValid())
      }),
      ...(isColumnEditable(PermissionsColumnsEnum.VALID_TO) && {
        [PermissionsColumnsEnum.VALID_TO]: YupString()
          .nullable()
          .required()
          .test({
            name: 'validTo',
            message: t('applications:validations.validToDateCanNotBeBeforeValidFrom'),
            test(validTo) {
              const { parent } = this;
              const validFrom = parent?.[PermissionsColumnsEnum.VALID_FROM];
              return !validFrom || !validTo || moment(validFrom, ALTERNATIVE_DATE_FORMAT).isBefore(validTo);
            }
          })
          .concat(isDateValid())
      })
    });
  };

  const onRowEdit = async (data: PermissionSnapshotClient, { onSuccess, onError }) => {
    const { version, formNumber, printDate, validFrom, validTo, permissionNumber } = data;

    const permissionValidityPeriodKey = data.permissionValidityPeriodKey as SelectOptionPartial<string>;

    const isEditable =
      permissionValidityPeriodKey && editablePermissionValidityKeys.includes(permissionValidityPeriodKey.value);

    const result = await updatePermission(
      {
        id: data.id,
        version,
        formNumber,
        printDate: getCalendarDate(printDate),
        validFrom: getCalendarDate(validFrom),
        validTo: getCalendarDate(isEditable ? validTo : null),
        number: permissionNumber,
        permissionValidityPeriodKey: permissionValidityPeriodKey?.value
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('success.save'));
          queryCache.invalidateQueries(PermissionsQueryKeysEnum.PERMISSION_COPIES_LIST);
          onSuccess();
        },
        onError: error => {
          const errorData = (error as AxiosErrorResponseType)?.response?.data;
          if (errorData?.codes.includes(DomainErrorCodes.VALID_TO_EXCEEDS_DURATION)) {
            showErrorSnackbar(errorData?.message.replace(/&quot;/g, '"'));
          }

          onError();
        }
      }
    );
    return result;
  };

  const getCreateButtonTitle = () => {
    switch (permissionType) {
      case PermissionTableEnum.FOREIGN_AUTHORITY_PERMISSION:
        return t('permission:action.create.addForeignAuthorityPermission');
      case PermissionTableEnum.EXTERNAL_AUTHORITY_PERMISSION:
        return t('permission:action.create.addPermission');
      default:
        return t('action.add');
    }
  };

  const renderAction = ({
    action,
    permissionId,
    applicationId,
    isMoreButtonAction,
    status,
    close
  }: {
    action: PermissionActions;
    permissionId?: string;
    applicationId?: string;
    isMoreButtonAction: boolean;
    status?: string;
    close?: () => void;
  }) => {
    switch (action) {
      case 'edit':
        return (
          <TableContext.Consumer>
            {({ refetch }) =>
              isMoreButtonAction ? (
                <TableButton
                  label={t('action.edit')}
                  onClick={() => {
                    close?.();
                    showPermissionsDetailDialog({
                      permissionId,
                      formMode: FormMode.EDIT,
                      folderApplicationId: applicationId,
                      onSuccess: () => {
                        queryCache.invalidateQueries(PermissionsQueryKeysEnum.PERMISSIONS_LIST);
                        refetch();
                      }
                    });
                  }}
                  actionKey={
                    permissionType === PermissionTableEnum.EXTERNAL_AUTHORITY_PERMISSION &&
                    DomainUIElementEnum.APPLICATION_PERMISSIONS_ITEM_PERMISSION_EXTERNAL_UPDATE_BUTTON
                  }
                />
              ) : (
                <TableIconButton
                  tooltipTitle={t('action.edit')}
                  icon="EditIcon"
                  onClick={() => {
                    close?.();
                    showPermissionsDetailDialog({
                      permissionId,
                      formMode: FormMode.EDIT,
                      folderApplicationId: applicationId,
                      onSuccess: () => {
                        queryCache.invalidateQueries(PermissionsQueryKeysEnum.PERMISSIONS_LIST);
                        refetch();
                      }
                    });
                  }}
                  actionKey={
                    permissionType === PermissionTableEnum.EXTERNAL_AUTHORITY_PERMISSION &&
                    DomainUIElementEnum.APPLICATION_PERMISSIONS_ITEM_PERMISSION_EXTERNAL_UPDATE_BUTTON
                  }
                />
              )
            }
          </TableContext.Consumer>
        );
      case 'delete':
        return (
          <TableContext.Consumer>
            {({ refetch }) =>
              isMoreButtonAction ? (
                <TableButton
                  label={t('action.delete')}
                  onClick={() => {
                    close?.();
                    handlePermissionDelete({
                      permissionId,
                      onSuccess: () => {
                        queryCache.invalidateQueries(PermissionsQueryKeysEnum.PERMISSIONS_LIST);
                        refetch();
                      }
                    });
                  }}
                  actionKey={
                    permissionType === PermissionTableEnum.EXTERNAL_AUTHORITY_PERMISSION &&
                    DomainUIElementEnum.APPLICATION_PERMISSIONS_ITEM_PERMISSION_EXTERNAL_DELETE_BUTTON
                  }
                />
              ) : (
                <TableIconButton
                  tooltipTitle={t('action.delete')}
                  icon="TrashIcon"
                  onClick={() => {
                    close?.();
                    handlePermissionDelete({
                      permissionId,
                      onSuccess: () => {
                        queryCache.invalidateQueries(PermissionsQueryKeysEnum.PERMISSIONS_LIST);
                        refetch();
                      }
                    });
                  }}
                  actionKey={
                    permissionType === PermissionTableEnum.EXTERNAL_AUTHORITY_PERMISSION &&
                    DomainUIElementEnum.APPLICATION_PERMISSIONS_ITEM_PERMISSION_EXTERNAL_DELETE_BUTTON
                  }
                />
              )
            }
          </TableContext.Consumer>
        );
      case 'details':
        return isMoreButtonAction ? (
          <TableButton
            label={t('action.showDetails')}
            onClick={() => {
              close?.();
              showPermissionsDetailDialog({
                permissionId,
                formMode: FormMode.VIEW,
                folderApplicationId: applicationId
              });
            }}
            actionKey={
              permissionType === PermissionTableEnum.EXTERNAL_AUTHORITY_PERMISSION &&
              DomainUIElementEnum.APPLICATION_PERMISSIONS_ITEM_PERMISSION_EXTERNAL_SHOW_BUTTON
            }
          />
        ) : (
          <TableIconButton
            tooltipTitle={t('action.showDetails')}
            icon="ArrowIcon"
            onClick={() => {
              close?.();
              showPermissionsDetailDialog({
                permissionId,
                formMode: FormMode.VIEW,
                folderApplicationId: applicationId
              });
            }}
            actionKey={
              permissionType === PermissionTableEnum.EXTERNAL_AUTHORITY_PERMISSION &&
              DomainUIElementEnum.APPLICATION_PERMISSIONS_ITEM_PERMISSION_EXTERNAL_SHOW_BUTTON
            }
          />
        );
      case 'passToDistrictAuthority':
        return (
          (status === PermissionStatusEnum.ACTIVE || status === PermissionStatusEnum.PENDING) && (
            <TableContext.Consumer>
              {({ refetch }) =>
                isMoreButtonAction ? (
                  <TableButton
                    label={t('permission:action.passToDistrictAuthority.buttonTitle')}
                    onClick={() => {
                      close?.();
                      showPermissionPassToDistrictAuthorityDialog({
                        permissionId,
                        onSuccess: () => {
                          refetch();
                        }
                      });
                    }}
                  />
                ) : (
                  <TableIconButton
                    tooltipTitle={t('permission:action.passToDistrictAuthority.buttonTitle')}
                    icon="ChevronsRightIcon"
                    onClick={() => {
                      close?.();
                      showPermissionPassToDistrictAuthorityDialog({
                        permissionId,
                        onSuccess: () => {
                          refetch();
                        }
                      });
                    }}
                  />
                )
              }
            </TableContext.Consumer>
          )
        );
      case 'restoreFromDistrictAuthority':
        return (
          (status === PermissionStatusEnum?.ACTIVE || status === PermissionStatusEnum?.PENDING) && (
            <TableContext.Consumer>
              {({ refetch }) =>
                isMoreButtonAction ? (
                  <TableButton
                    label={t('permission:action.restoreFromDistrictAuthority.buttonTitle')}
                    onClick={() => {
                      close?.();
                      showPermissionRestoreFromDistrictAuthorityDialog({
                        permissionId,
                        onSuccess: () => {
                          refetch();
                        }
                      });
                    }}
                  />
                ) : (
                  <TableIconButton
                    tooltipTitle={t('permission:action.restoreFromDistrictAuthority.buttonTitle')}
                    icon="ChevronsLeftIcon"
                    onClick={() => {
                      close?.();
                      showPermissionRestoreFromDistrictAuthorityDialog({
                        permissionId,
                        onSuccess: () => {
                          refetch();
                        }
                      });
                    }}
                  />
                )
              }
            </TableContext.Consumer>
          )
        );
      default:
        return null;
    }
  };

  const lastColumnWidth = useMemo(() => {
    if (actionsColumnWidth) {
      return actionsColumnWidth;
    }
    return rowActionsEnabled?.length > 1 || !rowActionsEnabled ? 116 : 100;
  }, [actionsColumnWidth, rowActionsEnabled]);

  const xlsxDownloadApi = useCallback(params => {
    const apiMethod = getQuery(
      () =>
        API.client.permissions.getPermissionsPage('', '', params, {
          ...endpointsConfig.getPermissionsPage
        }),
      () =>
        API.permissions.getPermissionsPage(params, {
          ...endpointsConfig.getPermissionsPage
        })
    );

    return apiMethod() as AxiosPromise<PermissionsSnapshotPage>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableLayout<PermissionSnapshotClient, PermissionsSnapshotPage>
      {...tableProps}
      pageTitle={title}
      isActionColumnEnabled={Boolean(rowActions) || rowActionsEnabled?.length > 0}
      actionsColumnWidth={lastColumnWidth}
      xlsxDownload={{
        fileName: title,
        pathToXLSXTranslation: 'permission:field',
        apiRequest: params =>
          xlsxDownloadApi(params).then((res: AxiosResponse<PermissionsSnapshotPage>) => ({
            ...res,
            data: { ...res.data, content: parsePermissionsList('content' in res.data ? res.data.content : []) }
          }))
      }}
      headerActions={
        isHeaderActions && (
          <div>
            {headerActions}
            {mode !== UiMode.VIEW && showHeaderAction('create') && (
              <TableContext.Consumer>
                {({ refetch }) => (
                  <TableHeaderButton
                    label={getCreateButtonTitle()}
                    onClick={() => {
                      showPermissionsDetailDialog({
                        formMode: FormMode.CREATE,
                        onSuccess: () => {
                          queryCache.invalidateQueries(PermissionsQueryKeysEnum.PERMISSIONS_LIST);
                          refetch();
                        }
                      });
                    }}
                    variant="outlined"
                    classNameWrapper="ml-16"
                    actionKey={
                      permissionType === PermissionTableEnum.EXTERNAL_AUTHORITY_PERMISSION &&
                      DomainUIElementEnum.APPLICATION_PERMISSIONS_ITEM_PERMISSION_EXTERNAL_CREATE_BUTTON
                    }
                  />
                )}
              </TableContext.Consumer>
            )}
          </div>
        )
      }
      rowActions={data => {
        if (rowActions) {
          return rowActions(data);
        }

        if (rowActionsEnabled?.length < 3) {
          return rowActionsEnabled?.map(action =>
            renderAction({
              action,
              isMoreButtonAction: false,
              permissionId: data?.original.id,
              applicationId: data?.original?.applicationId
            })
          );
        }

        return [
          <TableButtonMore key={0}>
            {close =>
              rowActionsEnabled?.map((action, i) => {
                return rowActionsEnabled.length - 1 > i
                  ? renderAction({
                      action,
                      isMoreButtonAction: true,
                      permissionId: data?.original.id,
                      applicationId: data?.original?.applicationId,
                      status: data?.original.status,
                      close
                    })
                  : null;
              })
            }
          </TableButtonMore>,
          rowActionsEnabled?.length &&
            renderAction({
              action: rowActionsEnabled[rowActionsEnabled.length - 1],
              isMoreButtonAction: false,
              permissionId: data?.original.id,
              applicationId: data?.original?.applicationId,
              status: data?.original.status
            })
        ];
      }}
      onRowEditCreateConfirm={onRowEdit}
      editFormYupResolverSchema={getValidationScheme()}
      isTableMutable
      isSection
      isCollapsable
      isRefreshEnabled
    >
      {isWarningEnabled && (
        <PermissionWarnings titleKey={titleKey} applicationId={applicationId} initialParams={initialParams} />
      )}
    </TableLayout>
  );
}

export default PermissionsTable;
