import { usePaginatedQuery } from 'react-query';

import { SelectOption } from '@libs/common/v2';

import { API, endpointsConfig, GroupQueryKeysEnum } from '@libs/group/api';

export const getGroups = async (_, params: { permissionsNames: SelectOption<string>[] }) => {
  const permissionsNames = params?.permissionsNames?.map(({ value }) => value) ?? [];

  const { data } = await API.group.find({ ...params, permissionsNames } ?? {}, {
    ...endpointsConfig.find
  });

  return data;
};

export default function useGroupsQuery(params, queryConfig = {}) {
  return usePaginatedQuery([GroupQueryKeysEnum.GROUPS_LIST, params], getGroups, queryConfig);
}
