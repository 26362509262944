import React from 'react';

import { DialogContext } from '../providers/DialogProvider';

function useDialog() {
  const { setOpen, setContent } = React.useContext(DialogContext);

  const onClose = () => {
    setContent(null);
    setOpen(false);
  };

  const openDialog = (component: ({ closeDialog }: { closeDialog: () => void }) => React.ReactElement) => {
    setOpen(true);
    setContent(component({ closeDialog: onClose }));
  };

  return { openDialog };
}

export default useDialog;
