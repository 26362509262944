import { MutateConfig, useMutation } from 'react-query';
import { DefaultApiDeleteRequest } from '@avispon/dictionary';
import { DictionaryEntryDetails } from '@avispon/dictionary/dist/models';
import { AxiosError, AxiosRequestConfig } from 'axios';

import { API, endpointsConfig } from '@libs/dictionary/api';

async function deleteDictionaryEntry(entryId: DefaultApiDeleteRequest) {
  const { data } = await API.dictionary._delete(entryId, {
    ...endpointsConfig.deleteDictionaryEntry
  } as AxiosRequestConfig);
  return data;
}

function useDeleteDictionaryEntryMutation(config?: MutateConfig<DictionaryEntryDetails, AxiosError>) {
  return useMutation<DictionaryEntryDetails, AxiosError, DefaultApiDeleteRequest>(deleteDictionaryEntry, config);
}

export default useDeleteDictionaryEntryMutation;
