import { useTranslation } from 'react-i18next';

import { Button } from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';

import { DomainUIElementEnum } from '../../../config';
import { useCreateProceedingAction } from '../../hooks';

interface IProps {
  folderId: string;
  previousLink: (folderId?: string) => string;
}

function CreateProceedingButton({ folderId, previousLink }: IProps) {
  const [t] = useTranslation();

  const { openCreateProceedingDialog } = useCreateProceedingAction(previousLink);
  const { checkIsElementVisible } = useElementVisibility();
  const isButtonVisible =
    checkIsElementVisible(DomainUIElementEnum.PROCEEDING_VERIFICATION_SELECT_OPTION) ||
    checkIsElementVisible(DomainUIElementEnum.PROCEEDING_ADMINISTRATIVE_SELECT_OPTION);

  return (
    isButtonVisible && (
      <Button variant="outlined" onClick={() => openCreateProceedingDialog(folderId)} label={t('action.add')} />
    )
  );
}

export default CreateProceedingButton;
