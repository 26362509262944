import { InputMode } from '@libs/common/v2';

import {
  AdditionalSectionLicenseOrPermissionType,
  ApplicationMetaFormDefinition
} from '@libs/domain/application/model';
import { DomainDictionaryEntry } from '@libs/domain/config';
import { DriverActionButtonsEnum } from '@libs/domain/drivers';

const definition: ApplicationMetaFormDefinition = {
  fields: [
    {
      typeKey: 'DATETIME',
      fieldId: 'application-expected-completion-date',
      properties: {
        label: 'Termin realizacji',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'expectedCompletionDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'expectedCompletionDate'
          }
        }
      }
    },
    {
      typeKey: 'AUTOCOMPLETE',
      fieldId: 'application-concern-license',
      properties: {
        label: 'Dotyczy licencji',
        inputMode: InputMode.VIEW,
        visibility: {
          elementVisibilityAsyncKeys: ['FOLDER_HAS_ACTIVE_OR_PENDING_LISENSE']
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'concernLicense',
            formAccessor: 'id'
          },
          fetch: {
            requestKey: 'GET_LICENCES',
            optionTextMatch: '[number]'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'concernLicense'
          }
        }
      }
    },
    {
      typeKey: 'LAST_SUPPLEMENT_RECEIPT_DATE',
      fieldId: 'application-last-supplement-receipt-date',
      properties: {
        label: 'Data wpływu ostatniego uzupełnienia',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'lastSupplementReceiptDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'lastSupplementReceiptDate'
          }
        }
      }
    },
    {
      typeKey: 'AUTOCOMPLETE',
      fieldId: 'application-locked-by-application',
      properties: {
        label: 'Wniosek blokujący',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'lockedByApplication',
            formAccessor: 'id'
          },
          fetch: {
            requestKey: 'GET_LOCKING_APPLICATIONS',
            accessor: null,
            optionTextMatch: '[number]'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'lockedByApplication'
          }
        }
      }
    },

    {
      typeKey: 'BOOLEAN',
      fieldId: 'application-is-review-application',
      properties: {
        label: 'Czy wpłynął wniosek o ponowne rozpatrzenie sprawy?',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'isReviewApplication'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'isReviewApplication'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-second-tier-decision-key',
      properties: {
        label: 'Rodzaj rozstrzygnięcia II instancji',
        dictionaryName: 'SECOND_TIER_DECISION',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'secondTierDecisionKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'secondTierDecisionKey'
          }
        }
      }
    },
    {
      typeKey: 'DATETIME',
      fieldId: 'application-second-tier-decision-date',
      properties: {
        label: 'Data rozstrzygnięcia (II instancji)',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'secondTierDecisionDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'secondTierDecisionDate'
          }
        }
      }
    },
    {
      typeKey: 'BOOLEAN',
      fieldId: 'application-wsa-complaint',
      properties: {
        label: 'Czy wpłynęła skarga do WSA?',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'wsaComplaint'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'wsaComplaint'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-wsa-decision-key',
      properties: {
        label: 'Rodzaj rozstrzygnięcia WSA',
        dictionaryName: 'WSA_DECISION',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'wsaDecisionKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'wsaDecisionKey'
          }
        }
      }
    },
    {
      typeKey: 'DATETIME',
      fieldId: 'application-wsa-decision-date',
      properties: {
        label: 'Data rozstrzygnięcia (WSA)',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'wsaDecisionDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'wsaDecisionDate'
          }
        }
      }
    },
    {
      typeKey: 'BOOLEAN',
      fieldId: 'application-final-decision',
      properties: {
        label: 'Ostateczne rozstrzygnięcie?',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'finalDecision'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'finalDecision'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'driver-certificate-application-cause-dependent-on-subject-key',
      properties: {
        label: 'Przyczyna wydania wtórnika',
        dictionaryName: 'DUPLICATE_CAUSE_DEPENDENT_ON_SUBJECT',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'driverCertificateUpdateRequest.causeDependentOnSubjectKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'driverCertificateApplicationDetails.causeDependentOnSubjectKey'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'driver-certificate-application-loss-cause-key',
      properties: {
        label: 'Uprawnienie zniszczone / utracone',
        dictionaryName: 'LOSS_CAUSE',
        yupValidation: {
          required: true
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'driverCertificateUpdateRequest.lossCauseKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'driverCertificateApplicationDetails.lossCauseKey'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'driver-certificate-application-loss-location',
      properties: {
        label: 'Miejsce utraty',
        maxLength: 100,
        yupValidation: { required: true },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'driverCertificateUpdateRequest.lossLocation'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'driverCertificateApplicationDetails.lossLocation'
          }
        }
      }
    },
    {
      typeKey: 'DATETIME',
      fieldId: 'driver-certificate-application-loss-date',
      properties: {
        label: 'Data utraty',
        yupValidation: {
          required: {},
          max: {
            date: 'TODAY',
            message: 'Data nie może być późniejsza niż dzisiejszy dzień'
          }
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'driverCertificateUpdateRequest.lossDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'driverCertificateApplicationDetails.lossDate'
          }
        }
      }
    },
    {
      typeKey: 'DRIVER_CERTIFICATE_SELECT',
      fieldId: 'application-driver-certificate-select',
      properties: {
        label: 'Świadectwo kierowcy',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'driverCertificateUpdateRequest.driverCertificateId',
            formAccessor: 'id',
            typeKey: 'AUTOCOMPLETE'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'driverCertificateApplicationDetails.driverCertificate'
          }
        },
        yupValidation: { required: true }
      }
    },
    {
      typeKey: 'DRIVERS_COLLECTION',
      fieldId: 'application-drivers-collection',
      properties: {
        visibleActions: [
          DriverActionButtonsEnum.SEARCH,
          DriverActionButtonsEnum.PREVIEW,
          DriverActionButtonsEnum.DELETE
        ],
        searchDriverDialogSearchParams: {
          driverCertificateStatusKeyIn: [DomainDictionaryEntry.DRIVER_CERTIFICATE_STATUS.ACTIVE]
        }
      }
    }
  ],
  components: [],
  layout: [
    {
      typeKey: 'TAB_NAVIGATION',
      properties: {
        visibility: {
          elementVisibilityKeys: ['TAB_VISIBILITY']
        },
        defaultTabId: 'GENERAL',
        title: 'Sekcje',
        tabs: [
          {
            id: 'GENERAL',
            title: 'Dane podstawowe',
            icon: 'InfoIcon'
          },
          {
            id: 'SUBJECT',
            title: 'Podmiot',
            icon: 'FacadeIcon'
          },
          {
            id: 'ADDRESSES',
            title: 'Adresy',
            icon: 'MailboxIcon'
          },
          {
            id: 'PERMISSIONS',
            title: 'Uprawnienia',
            icon: 'KeyIcon'
          },
          {
            id: 'PROXIES',
            title: 'Pełnomocnicy',
            icon: 'GroupOfPeopleIcon'
          },
          {
            id: 'ATTACHMENTS',
            title: 'Załączniki',
            icon: 'PaperclipIcon'
          },
          {
            id: 'DRIVERS',
            title: 'Kierowcy',
            icon: 'DriversIcon'
          },
          {
            id: 'QUOTATION',
            title: 'Wycena',
            icon: 'CoinsIcon'
          },
          {
            id: 'OUTGOING_DOCUMENTS',
            title: 'Dokumenty wychodzące',
            icon: 'DocumentsIssuedIcon'
          },
          {
            id: 'NOTE',
            title: 'Notatka',
            icon: 'NoteIcon'
          },
          {
            id: 'FILES',
            title: 'Pliki',
            icon: 'FileEmptyIcon'
          },
          {
            id: 'HISTORY',
            title: 'Historia',
            icon: 'HistoryIcon'
          }
        ]
      },
      nodes: [
        {
          typeKey: 'TABS_CONTENT',
          nodes: [
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-general-tab',
                additionalNodes: {
                  'application-section': [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'driver-certificate-application-cause-dependent-on-subject-key'
                      }
                    },
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'driver-certificate-application-loss-cause-key'
                      }
                    },
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'driver-certificate-application-loss-location'
                      }
                    },
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'driver-certificate-application-loss-date'
                      }
                    }
                  ],
                  'additional-section': [
                    {
                      typeKey: 'SECTION',
                      properties: {
                        title: 'Wtórniki uprawnień'
                      },
                      nodes: [
                        {
                          typeKey: 'GRID',
                          properties: {
                            gridItemProps: {
                              xs: 12,
                              sm: 6
                            }
                          },
                          nodes: [
                            {
                              typeKey: 'FIELD',
                              properties: {
                                fieldId: 'application-driver-certificate-select'
                              }
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-subject-tab',
                additionalProperties: {
                  SUBJECT: {
                    inputMode: InputMode.VIEW
                  }
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-addresses-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-permissions-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-proxies-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-attachments-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-drivers-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-quotation-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-outgoing-documents-tab',
                additionalNodes: {
                  'outgoing-documents-additional-section': [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'application-outgoing-documents-additional-section-license-or-permission',
                        sectionTypes: [AdditionalSectionLicenseOrPermissionType.DRIVER_CERTIFICATE]
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-note-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-files-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-history-tab'
              }
            }
          ]
        }
      ]
    }
  ]
};

export default definition;
