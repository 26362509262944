import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { useAppConfig } from '@libs/app-config';
import { ReportTypeDetailsSnapshot } from '@stack/report';

import { TableLayout, useDevMode, useRouter, useTableRowActions } from '@libs/common/v2';

import { API, useExportSingleReportTypeMutation, useReportsQuery } from '@libs/report/api';
import { modelToApi } from '@libs/report/api/queries/useReportTypesQuery';
import { useDeleteReportType, useReportGeneration, useReportTypesTable } from '@libs/report/hooks';
import { convertDataToJsonFile } from '@libs/report/utils/reports.util';

import { ReportUIElementEnum } from '../../config/permission';
import { parseReportTypesList } from '../../utils/converter.util';

import { ReportTypesTableHeaderActions } from './ReportTypesTableHeaderActions';

function ReportTypesTable() {
  const [t] = useTranslation();
  const { goToPage } = useRouter();
  const { isDevMode } = useDevMode();
  const { isReportTypeManagementEnable } = useAppConfig(isDevMode);
  const { mutate: exportSingleReportType, isLoading } = useExportSingleReportTypeMutation();
  const [generate] = useReportGeneration();
  const tableProps = useReportTypesTable(isDevMode, isReportTypeManagementEnable);
  // Id rekordu używanego do testów automatycznych na środowisku DEV
  const testingRecordId = 'adbc26b7-0af9-42f5-837e-3dc1b4403dbf';
  const [reportTypeNameFragment, setReportTypeNameFragment] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { data: reportsData, isLoading: isReportsQueryLoading } = useReportsQuery(
    { reportTypeNameFragment, sort: ['created', 'DESC'] },
    {
      onSuccess: data => {
        const lastReport = data?.content?.[0];

        if (lastReport?.reportTypeName === reportTypeNameFragment) {
          goToPage(`/reports/generated/${lastReport.id}/details`);
        } else {
          setReportTypeNameFragment(null);
          showSnackbar('warning', t('reports:message.noReportGenerated'));
        }
        setLoading(false);
      },
      enabled: reportTypeNameFragment
    }
  );
  const { handleDeleteReportType } = useDeleteReportType();
  const { showSnackbar } = useSnackbar();

  const handleExportSingleReportType = useCallback((id: string, name: string) => {
    exportSingleReportType(id, {
      onSuccess: data => convertDataToJsonFile(data, name)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { renderTableActions } = useTableRowActions<ReportTypeDetailsSnapshot>(
    isDevMode
      ? [
          {
            id: ReportUIElementEnum.REPORT_GENERATE_BUTTON,
            label: 'reports:action.generateReport',
            icon: 'PlayArrowIcon',
            onClick: ({ row: { original } }) => generate(original.name)
          },
          {
            id: ReportUIElementEnum.REPORT_DETAILS_BUTTON,
            label: 'action.show',
            isLoading: loading || isReportsQueryLoading,
            onClick: ({ row: { original } }) => {
              setLoading(true);
              setReportTypeNameFragment(original.name);
            },
            icon: 'ArrowIcon'
          },
          {
            id: ReportUIElementEnum.REPORT_DOWNLOAD_BUTTON,
            label: 'action.export',
            onClick: ({
              row: {
                original: { id, name }
              }
            }) => handleExportSingleReportType(id, name),
            isHidden: !isDevMode,
            icon: 'DownloadIcon'
          },
          {
            id: ReportUIElementEnum.REPORT_EDIT_BUTTON,
            label: 'action.edit',
            link: ({ original }) => `/reports/report-types/${original.id}/edit`,
            isHidden: ({
              row: {
                original: { id }
              }
            }) => id === testingRecordId || !isReportTypeManagementEnable,
            icon: 'EditIcon'
          },
          {
            id: ReportUIElementEnum.REPORT_DELETE_BUTTON,
            label: 'action.delete',
            onClick: ({ row: { original } }) => handleDeleteReportType(original),
            isHidden: ({
              row: {
                original: { id }
              }
            }) => id === testingRecordId || !isReportTypeManagementEnable,
            icon: 'TrashIcon'
          }
        ]
      : [
          {
            id: ReportUIElementEnum.REPORT_DETAILS_BUTTON,
            label: 'action.show',
            icon: 'ArrowIcon',
            isLoading: loading || isReportsQueryLoading,
            onClick: ({ row: { original } }) => {
              setLoading(true);
              setReportTypeNameFragment(original.name);
            }
          },
          {
            id: ReportUIElementEnum.REPORT_GENERATE_BUTTON,
            label: 'reports:action.generateReport',
            icon: 'PlayArrowIcon',
            onClick: ({ row: { original } }) => generate(original.name)
          }
        ],
    [isReportTypeManagementEnable, reportsData, loading, isDevMode]
  );

  return (
    // @ts-ignore
    <TableLayout
      {...tableProps}
      pageTitle={t('reports:reportTypes.sectionTitle')}
      xlsxDownload={{
        fileName: t('reports:reportTypes.sectionTitle'),
        pathToXLSXTranslation: 'reports:fields',
        apiRequest: params => {
          const apiModel = modelToApi(params);
          return (
            API.reportTypes
              .getReportTypes(
                apiModel.sourceIds,
                apiModel.pageNumber,
                apiModel.pageSize,
                apiModel.pageSort,
                apiModel.createdBy,
                apiModel.createdMax,
                apiModel.createdMin,
                apiModel.documentTemplateId,
                apiModel.idFragment,
                apiModel.includeDeleted,
                apiModel.modifiedMax,
                apiModel.modifiedMin,
                apiModel.nameFragment,
                apiModel.name,
                apiModel.sourceNameFragment,
                apiModel.descriptionFragment
              )
              // @ts-ignore
              .then(res => ({ ...res, data: { ...res.data, content: parseReportTypesList(res.data.content) } }))
          );
        }
      }}
      headerActions={isDevMode && <ReportTypesTableHeaderActions />}
      rowActions={renderTableActions([
        ReportUIElementEnum.REPORT_DOWNLOAD_BUTTON,
        ReportUIElementEnum.REPORT_EDIT_BUTTON,
        ReportUIElementEnum.REPORT_DELETE_BUTTON,
        ReportUIElementEnum.REPORT_GENERATE_BUTTON,
        ReportUIElementEnum.REPORT_DETAILS_BUTTON
      ])}
      isLoading={isLoading}
      isSectionFullTab
    />
  );
}

export default React.memo(ReportTypesTable);
