import { useCallback } from 'react';

import { FormMode, useDialog } from '@libs/common/v2';

import { DomainUIElementEnum } from '../../config';
import { TransportManagerDetailsDialog } from '../components';

interface OpenDetailsProps {
  formMode?: FormMode;
  id?: string;
  applicationId?: string;
  folderId?: string;
  folderType?: string;
  transferredFoldersIds?: string[];
  managerModificationTypeKey?: string;
  isVehiclesAndSubjectsManagementSectionVisible: boolean;
  permissionsKeysObject?: {
    SHOW_REPUTATION: DomainUIElementEnum;
    CHECK_REPUTATION: DomainUIElementEnum;
  };
}

function useTransportManagerDetailsDialog() {
  const { openDialog } = useDialog();

  const openDetailsDialog = useCallback(
    ({
      id,
      formMode = FormMode.CREATE,
      applicationId,
      folderId,
      transferredFoldersIds,
      folderType,
      managerModificationTypeKey,
      permissionsKeysObject,
      isVehiclesAndSubjectsManagementSectionVisible
    }: OpenDetailsProps) => {
      openDialog(({ closeDialog }) => (
        <TransportManagerDetailsDialog
          id={id}
          closeDialog={closeDialog}
          formMode={formMode}
          applicationId={applicationId}
          folderId={folderId}
          folderType={folderType}
          transferredFoldersIds={transferredFoldersIds}
          managerModificationTypeKey={managerModificationTypeKey}
          isVehiclesAndSubjectsManagementSectionVisible={isVehiclesAndSubjectsManagementSectionVisible}
          permissionsKeysObject={permissionsKeysObject}
        />
      ));
    },
    [openDialog]
  );

  return {
    openDetailsDialog
  };
}

export default useTransportManagerDetailsDialog;
