import { useTranslation } from 'react-i18next';

import { FolderDetailsClient } from '@libs/domain/folder';

import FolderSliderSection from '../FolderSliderSection';

import LicenceBlockContent from './LicenceBlockContent';

interface Props {
  folderDetails: FolderDetailsClient;
  folderId: string;
  isLoading?: boolean;
}

function LicenceBlock({ folderDetails, isLoading, folderId }: Props) {
  const [t] = useTranslation();

  return (
    <FolderSliderSection title={t('folder:sidebar.section.licences')} isCollapsable isCollapsed isLazy>
      <LicenceBlockContent folderDetails={folderDetails} isLoading={isLoading} folderId={folderId} />
    </FolderSliderSection>
  );
}

export default LicenceBlock;
