import { ForeignPermissionSnapshot } from '@ibtm/domain';

import { TableSortMapper, useCreateColumns } from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';

function useSingleAuthorizationColumns() {
  const { createColumns } = useCreateColumns<ForeignPermissionSnapshot>({
    pathToTranslate: 'folder:details.tab.singleAuthorization.section.releasedOrIssued.field'
  });
  const mappedSortFields: TableSortMapper<ForeignPermissionSnapshot> = {
    number: 'numericNumber',
    formTypeCountryKey: 'form.type.countryKey',
    formTypeNameKey: 'form.type.nameKey',
    invoiceNumber: 'invoice.number'
  };

  const columns = createColumns([
    {
      id: 'number',
      type: 'TEXT',
      header: 'authorizationNumber',
      accessor: 'number'
    },
    {
      id: 'formTypeCountryKey',
      header: 'country',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.COUNTRY_CODE,
      accessor: 'form.type.countryKey'
    },
    {
      header: 'nameKey',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_NAME,
      accessor: 'form.type.nameKey'
    },
    {
      id: 'statusKey',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.PERMIT_APPLICATION_STATUS_ZZ,
      accessor: 'statusKey'
    },
    {
      id: 'invoiceNumber',
      header: 'confirmationNumber',
      accessor: 'invoice.number',
      type: 'TEXT'
    }
  ]);

  return { columns, mappedSortFields };
}

export default useSingleAuthorizationColumns;
