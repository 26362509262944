import { QueryConfig, usePaginatedQuery } from 'react-query';
import { DefaultApiGetPaginatedNotificationsRequest, PullNotificationSnapshot } from '@avispon/message';
import { AxiosRequestConfig } from 'axios';

import { IPaginatedModel } from '@libs/common/v2';

import { API, endpointsConfig, MessageQueryKeysEnum } from '@libs/notification/api';

export const getMyMessages = async (_, params: DefaultApiGetPaginatedNotificationsRequest) => {
  const { data } = await API.message.getPaginatedNotifications(params, {
    ...endpointsConfig.getPaginatedNotifications
  } as AxiosRequestConfig);

  return data;
};

function useMyMessagesQuery(
  params: DefaultApiGetPaginatedNotificationsRequest,
  queryConfig: QueryConfig<IPaginatedModel<PullNotificationSnapshot>> = {}
) {
  return usePaginatedQuery<IPaginatedModel<PullNotificationSnapshot>>(
    [MessageQueryKeysEnum.MY_MESSAGES_LIST, params],
    getMyMessages,
    queryConfig
  );
}

export default useMyMessagesQuery;
