import { useCallback } from 'react';

import { useDialog } from '@libs/common/v2';

import { TransitDatesDialog } from '../components';

function useTransitDatesDialog() {
  const { openDialog } = useDialog();

  const openDetailsDialog = useCallback(
    ({
      applicationData,
      transitDataId
    }: {
      applicationData: { id: string; categoryKey: string };
      transitDataId: string;
    }) => {
      openDialog(({ closeDialog }) => (
        <TransitDatesDialog closeDialog={closeDialog} applicationData={applicationData} transitDataId={transitDataId} />
      ));
    },
    [openDialog]
  );

  return {
    openDetailsDialog
  };
}

export default useTransitDatesDialog;
