import { useQuery } from 'react-query';
import { Operators } from '@stack/report/dist/models';

import { API, endpointsConfig } from '@libs/report-core/api';

import { ReportCoreQueryKeysEnum } from './QueryKeysEnum';

const getReportTypeOperators = async () => {
  const { data } = await API.definitions.getDefinitionOperators({ ...endpointsConfig.getOperators });
  return data;
};

export default function useReportTypeOperatorsQuery() {
  return useQuery<Operators>([ReportCoreQueryKeysEnum.REPORT_OPERATORS], getReportTypeOperators, {});
}
