import { InputMode } from '@libs/common/v2';

import {
  AdditionalSectionLicenseOrPermissionType,
  ApplicationMetaFormDefinition
} from '@libs/domain/application/model';

const definition: ApplicationMetaFormDefinition = {
  fields: [
    {
      typeKey: 'AUTOCOMPLETE',
      fieldId: 'application-concern-license',
      properties: {
        label: 'Dotyczy licencji',
        inputMode: InputMode.VIEW,
        visibility: {
          elementVisibilityAsyncKeys: ['FOLDER_HAS_ACTIVE_OR_PENDING_LISENSE']
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'concernLicense',
            formAccessor: 'id'
          },
          fetch: {
            requestKey: 'GET_LICENCES',
            optionTextMatch: '[number]'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'concernLicense'
          }
        }
      }
    },
    {
      typeKey: 'BOOLEAN',
      fieldId: 'application-is-review-application',
      properties: {
        label: 'Czy wpłynął wniosek o ponowne rozpatrzenie sprawy?',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'isReviewApplication'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'isReviewApplication'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-second-tier-decision-key',
      properties: {
        label: 'Rodzaj rozstrzygnięcia II instancji',
        dictionaryName: 'SECOND_TIER_DECISION',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'secondTierDecisionKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'secondTierDecisionKey'
          }
        }
      }
    },
    {
      typeKey: 'DATETIME',
      fieldId: 'application-second-tier-decision-date',
      properties: {
        label: 'Data rozstrzygnięcia (II instancji)',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'secondTierDecisionDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'secondTierDecisionDate'
          }
        }
      }
    },
    {
      typeKey: 'BOOLEAN',
      fieldId: 'application-wsa-complaint',
      properties: {
        label: 'Czy wpłynęła skarga do WSA?',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'wsaComplaint'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'wsaComplaint'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-wsa-decision-key',
      properties: {
        label: 'Rodzaj rozstrzygnięcia WSA',
        dictionaryName: 'WSA_DECISION',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'wsaDecisionKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'wsaDecisionKey'
          }
        }
      }
    },
    {
      typeKey: 'DATETIME',
      fieldId: 'application-wsa-decision-date',
      properties: {
        label: 'Data rozstrzygnięcia (WSA)',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'wsaDecisionDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'wsaDecisionDate'
          }
        }
      }
    },
    {
      typeKey: 'BOOLEAN',
      fieldId: 'application-final-decision',
      properties: {
        label: 'Ostateczne rozstrzygnięcie?',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'finalDecision'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'finalDecision'
          }
        }
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-nip',
      properties: {
        label: 'NIP',
        maxLength: 10,
        onlyPositiveInteger: true,
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'nip'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'nip'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-name',
      properties: {
        label: 'Nazwa',
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'name'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'name'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-regon',
      properties: {
        label: 'REGON',
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'regon'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'regon'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-krs-required',
      properties: {
        label: 'KRS',
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'krs'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'krs'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-krs',
      properties: {
        label: 'KRS',
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'krs'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'krs'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-subject-legal-form-key',
      properties: {
        label: 'Charakter prawny',
        dictionaryName: 'LEGAL_NATURE',
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'legalFormKey'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'legalFormKey'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-given-name',
      properties: {
        label: 'Imię',
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'givenName'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'givenName'
          }
        },
        inputMode: InputMode.VIEW
      }
    },
    {
      typeKey: 'TEXT',
      fieldId: 'application-subject-surname',
      properties: {
        label: 'Nazwisko',
        api: {
          get: {
            requestKey: 'GET_APPLICATION_SUBJECT',
            accessor: 'surname'
          },
          submit: {
            requestKey: 'UPDATE_SUBJECT',
            accessor: 'surname'
          }
        },
        inputMode: InputMode.VIEW
      }
    }
  ],
  components: [],
  layout: [
    {
      typeKey: 'TAB_NAVIGATION',
      properties: {
        visibility: {
          elementVisibilityKeys: ['TAB_VISIBILITY']
        },
        defaultTabId: 'GENERAL',
        title: 'Sekcje',
        tabs: [
          {
            id: 'GENERAL',
            title: 'Dane podstawowe',
            icon: 'InfoIcon'
          },
          {
            id: 'SUBJECT',
            title: 'Podmiot',
            icon: 'FacadeIcon'
          },
          {
            id: 'ADDRESSES',
            title: 'Adresy',
            icon: 'MailboxIcon'
          },
          {
            id: 'PERMISSIONS',
            title: 'Uprawnienia',
            icon: 'KeyIcon'
          },
          {
            id: 'PROXIES',
            title: 'Pełnomocnicy',
            icon: 'GroupOfPeopleIcon'
          },
          {
            id: 'ATTACHMENTS',
            title: 'Załączniki',
            icon: 'PaperclipIcon'
          },
          {
            id: 'DRIVERS',
            title: 'Kierowcy',
            icon: 'DriversIcon'
          },
          {
            id: 'QUOTATION',
            title: 'Wycena',
            icon: 'CoinsIcon'
          },
          {
            id: 'OUTGOING_DOCUMENTS',
            title: 'Dokumenty wychodzące',
            icon: 'DocumentsIssuedIcon'
          },
          {
            id: 'NOTE',
            title: 'Notatka',
            icon: 'NoteIcon'
          },
          {
            id: 'FILES',
            title: 'Pliki',
            icon: 'FileEmptyIcon'
          },
          {
            id: 'HISTORY',
            title: 'Historia',
            icon: 'HistoryIcon'
          }
        ]
      },
      nodes: [
        {
          typeKey: 'TABS_CONTENT',
          nodes: [
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-general-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-subject-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-addresses-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-permissions-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-proxies-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-attachments-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-drivers-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-quotation-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-outgoing-documents-tab',
                additionalNodes: {
                  'outgoing-documents-additional-section': [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'application-outgoing-documents-additional-section-license-or-permission',
                        sectionTypes: [AdditionalSectionLicenseOrPermissionType.DRIVER_CERTIFICATE]
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-note-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-files-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-history-tab'
              }
            }
          ]
        }
      ]
    }
  ]
};

export default definition;
