import { useEffect } from 'react';
import { GroupSnapshotExternal } from '@avispon/group/dist/models';

import { useTableAdapter } from '@libs/common/v2';

interface IProps {
  setSelectedRows: (value: GroupSnapshotExternal[]) => void;
}

function UserGroupSelectAction({ setSelectedRows }: IProps) {
  const { state, data } = useTableAdapter();

  useEffect(() => {
    const result = data.filter(({ id }) => Object.keys(state.selectedRowIds).includes(id));
    setSelectedRows(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, state.selectedRowIds]);

  return null;
}

function SelectionRowActionRender(setSelectedRows?: (value: GroupSnapshotExternal[]) => void) {
  return <UserGroupSelectAction setSelectedRows={setSelectedRows} />;
}

export default SelectionRowActionRender;
