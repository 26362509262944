import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface ExpireProceedingFormsParams {
  proceedingId: string;
  proceedingFormIds: Array<string>;
  version: number;
}

function expireFormOperations({ proceedingId, proceedingFormIds, version }: ExpireProceedingFormsParams) {
  return API.proceeding.expireProceedingForms(
    proceedingId,
    {
      proceedingFormIds,
      version
    },
    { ...endpointsConfig.expireProceeding }
  );
}

export function useExpireFormOperationsMutation() {
  return useMutation(expireFormOperations);
}
