import { FocusEventHandler, useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';
import { Autocomplete as MuiAutocomplete, createFilterOptions, FilterOptionsState } from '@mui/material';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { isArray, isEqual, uniqBy } from 'lodash';

import { i18n } from '@libs/common';
import { smallIconSize } from '@libs/common/config';
import { Chip, Icon, LoaderCircular, SelectOption, TextInput, useOnScreen } from '@libs/common/v2';
import { contrastYellow, Theme, useTheme, useThemeValuesContext } from '@libs/common/v2/theme';
import { calc, hexToRgba, important } from '@libs/common/v2/utils';

interface IProps {
  onChange: (_event: React.SyntheticEvent, value: any) => void;
  onInputChange?: (_event: React.SyntheticEvent, value: any) => void;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  getOptionLabel?: (option: SelectOption | string) => string;
  getOptionSelected?: (option: SelectOption, value?: any) => boolean;
  freeSoloOptionFilter?: (value: string) => boolean;
  renderTags?: (value: IProps['value'], getTagProps) => React.ReactNode;
  renderOption?: (props: Record<string, any>, option: any, state: AutocompleteRenderOptionState) => React.ReactNode;
  customFilterOptions?: (options: SelectOption[]) => SelectOption[];
  options: SelectOption[];
  name?: string;
  label?: string;
  inputValue?: string;
  TextFieldProps?: Partial<MuiTextFieldProps>;
  className?: string;
  helperText?: string;
  value?: SelectOption | SelectOption[] | string;
  defaultValue?: SelectOption | string;
  placeholder?: string;
  adornmentClassName?: string;
  onFocus?: FocusEventHandler<HTMLDivElement>;
  onDelete?: () => void;
  onClose?: (event: React.SyntheticEvent) => void;
  onClick?: React.MouseEventHandler;
  hasCustomDeleteIcon?: boolean;
  isMultiple?: boolean;
  isFreeSolo?: boolean;
  isClearable?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  isWarning?: boolean;
  isFilterSelectedOptions?: boolean;
  isLoading?: boolean;
  isDeleteEnabled?: boolean;
  tooltip?: string;
  shouldCheckVisibility?: boolean;
  stringValue?: boolean;
  isFetchedDynamically?: boolean;
  hasErrorTooltip?: boolean;
  isSmallPopupIcon?: boolean;
  onOpen?: () => void;
}

const filter = createFilterOptions<SelectOption>();

const getDefaultIsDeleteEnabled = ({
  value,
  adornmentClassName,
  isClearable
}: {
  value?: SelectOption | SelectOption[] | string;
  adornmentClassName?: string;
  isClearable?: boolean;
}) => {
  if (!isClearable && Array.isArray(value)) {
    return !!value?.length;
  }
  return adornmentClassName ? Object.keys(value || {})?.length !== 0 : !!value;
};

function AutocompleteSelect({
  onChange,
  onBlur,
  value,
  defaultValue,
  placeholder = i18n.t('action.select'),
  adornmentClassName,
  getOptionLabel = (option: SelectOption | string) => {
    if (typeof option === 'object') {
      return isMultiple ? option?.name || '' : option?.inputValue || option?.name || '';
    }

    return option || '';
  },
  // eslint-disable-next-line consistent-return
  renderTags = (valueToRender, getTagProps) => {
    if (Array.isArray(valueToRender)) {
      return uniqBy(valueToRender, 'value').map((option: SelectOption<unknown>, index) => {
        if (isFreeSolo) {
          return (
            <Chip
              key={option.value}
              title={option?.inputValue || option?.name || option || ''}
              {...getTagProps({ index })}
            />
          );
        }

        return <Chip key={option?.value} title={getOptionLabel(option) || ''} {...getTagProps({ index })} />;
      });
    }
  },
  renderOption,
  customFilterOptions,
  freeSoloOptionFilter,
  label,
  name,
  TextFieldProps,
  options = [],
  onInputChange,
  className,
  onFocus,
  onClose,
  helperText,
  hasCustomDeleteIcon,
  isRequired,
  inputValue,
  isMultiple,
  isFreeSolo,
  onDelete,
  onClick,
  isClearable = !isRequired,
  isDisabled,
  isError,
  isLoading,
  isWarning,
  isFilterSelectedOptions,
  getOptionSelected,
  isDeleteEnabled = getDefaultIsDeleteEnabled({ value, adornmentClassName, isClearable }),
  tooltip,
  shouldCheckVisibility,
  stringValue,
  isFetchedDynamically,
  hasErrorTooltip,
  isSmallPopupIcon,
  onOpen
}: IProps) {
  const [t] = useTranslation();
  const { contrast } = useTheme();
  const classes = useStyles({ isError, contrast, isClearable });

  const { width } = useWindowSize();
  const globalTheme = useThemeValuesContext();
  const isSmallScreen = width < globalTheme?.breakpoints?.values?.md;

  const i18nProps: { [key: string]: string } = {
    loadingText: t('common.loading'),
    noOptionsText: t('error.noResults'),
    openText: t('action.open'),
    closeText: t('action.close')
  };

  const isCustomValueExisting = useCallback(
    (val: string) => {
      if (isArray(value)) {
        return value.some(option => val === option?.inputValue);
      }

      return false;
    },
    [value]
  );

  const freeSoloFilterOptions = (options: SelectOption[], state: FilterOptionsState<SelectOption>) => {
    const filtered = filter(options, { ...state, getOptionLabel });
    const { inputValue } = state;
    const isExisting = options.some(option => inputValue === option.name);
    const hideAddLabel = isCustomValueExisting(inputValue);

    if (inputValue !== '' && !isExisting && freeSoloOptionFilter?.(inputValue)) {
      filtered.push({
        inputValue,
        value: inputValue,
        name: hideAddLabel ? inputValue : t('action.addWithValue', { value: inputValue })
      });
    }

    return filtered;
  };

  const filterOptions = (options: SelectOption[], state: FilterOptionsState<SelectOption>) => {
    const parsedOptions = options.filter(option => !!option);
    return customFilterOptions
      ? customFilterOptions(parsedOptions)
      : filter(parsedOptions, { ...state, getOptionLabel });
  };

  const getFreeSoloOptionSelected = (option: SelectOption<unknown>, val: SelectOption<unknown>) => {
    const optionTitle = typeof option === 'string' ? option : option?.value;
    const valueTitle = typeof val === 'string' ? val : val?.value;

    return optionTitle === valueTitle;
  };

  const handleAutocompleteValue = useCallback(
    (value: string | SelectOption<any> | SelectOption<any>[], options: SelectOption<unknown>[]) => {
      if (isArray(value)) {
        if (stringValue && options?.length > 0) {
          const formattedValues = value.map(singleValue => {
            const option = options?.find(option => option.value === singleValue);
            return { name: option?.name, value: option?.value };
          });

          return uniqBy(formattedValues, 'value');
        }
        return uniqBy(value, 'value');
      }

      if (stringValue && value && options?.length > 0) {
        const option = options?.find(option => option.value === value);
        return { name: option?.name, value: option?.value };
      }

      return value;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  const [autocompleteValue, setAutocompleteValue] = useState(handleAutocompleteValue(value, options));
  const [isInitialChange, setIsInitialChange] = useState(true);
  const [currentValue, setCurrentValue] = useState<string | SelectOption | SelectOption[]>(null);

  useEffect(() => {
    if (isInitialChange && options?.length > 0 && (isArray(value) || (stringValue && value && options?.length > 0))) {
      setIsInitialChange(false);
      setAutocompleteValue(handleAutocompleteValue(value, options));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if ((isFetchedDynamically && !isEqual(value, currentValue)) || !isFetchedDynamically) {
      setAutocompleteValue(handleAutocompleteValue(value, options));
    }
    setCurrentValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options]);

  const [isVisible, lastElementRef] = useOnScreen({
    threshold: 1,
    rootMargin: isSmallScreen ? undefined : '-110px'
  });

  const defaultGetOptionSelected = (option, value: string | SelectOption) => {
    if (option) {
      if (typeof value === 'string') {
        return option.name === value;
      }
      return option.name === value?.name;
    }

    return null;
  };

  return (
    <MuiAutocomplete
      disableClearable={!isClearable}
      options={options}
      filterSelectedOptions={isFilterSelectedOptions}
      multiple={isMultiple}
      value={autocompleteValue}
      defaultValue={defaultValue}
      disabled={isDisabled}
      loading={isLoading}
      inputValue={inputValue}
      className={className}
      freeSolo={isFreeSolo}
      onOpen={onOpen}
      onBlur={onBlur}
      onFocus={onFocus}
      onClose={onClose}
      classes={{
        option: classes.option,
        inputRoot: classes.root,
        listbox: classes.listbox,
        paper: classes.paper,
        noOptions: classes.option,
        input: classes.input,
        popper: isVisible || !shouldCheckVisibility ? classes.popper : 'hidden',
        endAdornment: classes.endAdornment,
        clearIndicator: classes.buttonIndicator,
        popupIndicator: classes.buttonIndicator
      }}
      renderInput={params => (
        <TextInput
          {...params}
          name={name}
          required={isRequired}
          label={label}
          placeholder={`${placeholder}...`}
          adornmentClassName={adornmentClassName}
          variant={'outlined' as never}
          error={isError}
          isWarning={isWarning}
          helperText={helperText}
          onDelete={onDelete}
          onClick={onClick}
          hasCustomDeleteIcon={hasCustomDeleteIcon}
          isDeleteEnabled={isDeleteEnabled}
          tooltip={tooltip}
          hasErrorTooltip={hasErrorTooltip}
          {...TextFieldProps}
        />
      )}
      ref={lastElementRef}
      getOptionLabel={option => getOptionLabel(option as string | SelectOption<any>)}
      getOptionDisabled={option => typeof option !== 'string' && 'isDisabled' in option && !!option?.isDisabled}
      clearIcon={<Icon tooltipTitle={t('action.clear')} icon="CrossIcon" height={17} width={17} />}
      popupIcon={
        <div className={isLoading && classes.loaderWrapper}>
          <LoaderCircular size={17} className={classes.loader} isLoading={isLoading} isAbsolute={false}>
            <Icon
              icon="ChevronDownIcon"
              height={isSmallPopupIcon && smallIconSize}
              width={isSmallPopupIcon && smallIconSize}
            />
          </LoaderCircular>
        </div>
      }
      forcePopupIcon={isFreeSolo || 'auto'}
      renderTags={(value, getTagProps) => renderTags(value as SelectOption<any>[], getTagProps)}
      renderOption={renderOption}
      disableCloseOnSelect={isMultiple && !!options?.length}
      clearText={null}
      {...i18nProps}
      onChange={(event, val) => {
        if (isArray(val)) {
          const formattedValue = val.map(element => {
            if (stringValue) {
              return typeof element === 'string' ? element : element?.value;
            }
            if (typeof element === 'string') {
              return {
                name: element,
                value: element,
                inputValue: element
              };
            }
            return element;
          });
          return onChange(event, stringValue ? formattedValue : uniqBy(formattedValue, 'value'));
        }

        if (stringValue) {
          return onChange(event, typeof val === 'string' ? val : val?.value);
        }

        if (typeof val === 'string') {
          return onChange(event, {
            name: val,
            value: val,
            inputValue: val
          });
        }

        return onChange(event, val);
      }}
      onInputChange={onInputChange}
      isOptionEqualToValue={(option, value) =>
        getOptionSelected?.(option as SelectOption<any>, value) ||
        defaultGetOptionSelected?.(option, value as SelectOption<any> | string)
      }
      {...(isFreeSolo
        ? { filterOptions: (options, state) => freeSoloFilterOptions(options as SelectOption[], state) }
        : { filterOptions: (options, state) => filterOptions(options as SelectOption[], state) })}
      {...(isFreeSolo && isMultiple ? { getOptionSelected: getFreeSoloOptionSelected } : {})}
    />
  );
}

const useStyles = makeStyles<Theme, { isError?: boolean; contrast: boolean; isClearable?: boolean }>(theme => ({
  root: {
    padding: important('0 14px'),
    minHeight: 43,
    paddingRight: ({ isError }) => important(isError ? '55px' : '35px'),
    backgroundColor: important(theme.palette.contrastIndicators.background),
    '& svg > path': {
      stroke: theme.palette.grey[600]
    },
    '&.Mui-disabled': {
      backgroundColor: ({ contrast }) => (contrast ? important(contrastYellow) : important(theme.palette.grey[100])),
      '& svg > path': {
        opacity: '50%'
      }
    }
  },
  option: {
    ...theme.typography.textMd.normal,
    color: theme.palette.grey[900],
    padding: '10px 14px',
    backgroundColor: theme.palette.white,
    '&:hover': {
      backgroundColor: ({ contrast }) =>
        contrast ? important(hexToRgba(theme.palette.grey[250], 0.2)) : important(theme.palette.grey[100])
    },
    '&[aria-selected="true"]': {
      backgroundColor: ({ contrast }) =>
        contrast ? important(hexToRgba(theme.palette.blueLight[50], 0.2)) : important(theme.palette.blueLight[50])
    }
  },
  input: {
    color: important(theme.palette.grey[600]),
    marginRight: ({ isClearable }) => isClearable && 52,
    '&:hover': {
      marginRight: ({ isClearable }) => isClearable && 52
    },
    '&:focus': {
      marginRight: ({ isClearable }) => isClearable && 52
    },
    '&::placeholder': {
      color: important(theme.palette.grey[600])
    }
  },
  listbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
  paper: {
    boxShadow: theme.shadowsList.lg,
    borderRadius: theme.borderRadiusBase,
    margin: '4px 0px'
  },
  loaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
    width: 20
  },
  popper: {
    '&[data-popper-reference-hidden]': {
      display: 'none'
    }
  },
  endAdornment: {
    right: important('21px'),
    top: ({ isError }) => (isError ? 'auto' : calc('50% - 7px'))
  },
  buttonIndicator: {
    height: 24,
    width: 24,

    '& > span span': {
      height: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
}));

export default AutocompleteSelect;
