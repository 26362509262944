export const endpointsConfig: { [key: string]: { params: { translationKey: string } } } = {
  findOrganizationUnitMembers: { params: { translationKey: 'administration:api.findOrganizationUnitMembers' } },
  findUsers: { params: { translationKey: 'administration:api.findUsers' } },
  findOrganizationUnit: { params: { translationKey: 'administration:api.findOrganizationUnit' } },
  getOrganizationUnit: { params: { translationKey: 'administration:api.getOrganizationUnit' } },
  getOrganizationStructure: { params: { translationKey: 'administration:api.getAlert' } },
  assignOrganizationUnitMember: { params: { translationKey: 'administration:api.assignOrganizationUnitMember' } },
  createOrganizationUnit: { params: { translationKey: 'administration:api.createOrganizationUnit' } },
  updateOrganizationUnit: { params: { translationKey: 'administration:api.updateOrganizationUnit' } },
  updateOrganizationUnitParentUnit: {
    params: { translationKey: 'administration:api.updateOrganizationUnitParentUnit' }
  },
  deleteOrganizationUnit: { params: { translationKey: 'administration:api.deleteOrganizationUnit' } },
  unassignOrganizationUnitMember: { params: { translationKey: 'administration:api.unassignOrganizationUnitMember' } },
  unassignMembers: { params: { translationKey: 'group:api.unassignMembers' } },
  pageUsers: { params: { translationKey: 'group:api.pageUsers' } },
  listMyOrganizationUnits: { params: { translationKey: 'group:api.listMyOrganizationUnits' } },
  search: { params: { translationKey: 'group:api.search' } }
};
