import { ExternalNamedObject } from '@avispon/group/dist/models';
import { NotificationContentTranslationPart } from '@avispon/message/dist/models';
import { OrganizationUnitSnapshotExternal } from '@avispon/organization-structure/dist/models/organization-unit-snapshot-external';
import {
  AlertCreateRequest,
  QueryCondition,
  QueryConditionFilter,
  QueryDefinition,
  RecipientSelectorDetails
} from '@stack/report/dist/models';
import * as Yup from 'yup';

import { i18n } from '@libs/common';

import { ConditionOperator } from '@libs/report-core';

export const defaultAlertFormValues = {
  created: String(new Date()),
  author: {} as OrganizationUnitSnapshotExternal,
  basicMode: null,
  name: '',
  statusKey: '',
  configurationStatusKey: '',
  description: '',
  source: null,
  cron: '',
  channelKeys: [],
  recipients: {
    emails: '',
    accountSelector: {
      accounts: [],
      groups: [],
      groupMembershipUnit: []
    }
  } as Omit<RecipientSelectorDetails, 'emails'> & {
    emails: string | string[];
  },
  content: {
    subject: '',
    message: ''
  } as NotificationContentTranslationPart,
  conditionsAreValid: false,
  query: '',
  queryConditions: [
    {
      filter: null,
      group: [],
      operator: ConditionOperator.AND
    }
  ] as QueryCondition[],
  queryDefinition: {
    columns: [],
    groups: []
  } as QueryDefinition
};

export type DefaultAlertFromValues = typeof defaultAlertFormValues;
export type AlertFormModel = AlertCreateRequest;

export const useAlertValidationSchema = (isEmail, isUser) => {
  return Yup.object({
    name: Yup.string().required(),
    configurationStatusKey: Yup.string().required().nullable(),
    description: Yup.string().required(),
    source: Yup.object().nullable().required(),
    cron: Yup.string().nullable(),
    content: Yup.object({
      subject: Yup.string().required(),
      message: Yup.string().required()
    }),
    channelKeys: Yup.array<string>().min(1, i18n.t('validation:required')),
    recipients: Yup.object({
      accountSelector: Yup.object({
        accounts: Yup.array<ExternalNamedObject>().when([], () => {
          if (isUser) return Yup.array<ExternalNamedObject>().nullable().required();
          return Yup.array<ExternalNamedObject>().nullable();
        }),
        groups: Yup.array<ExternalNamedObject>().when([], () => {
          if (isUser) return Yup.array<ExternalNamedObject>().nullable().required();
          return Yup.array<ExternalNamedObject>().nullable();
        }),
        groupMembershipUnits: Yup.array<string>().when([], () => {
          if (isUser) return Yup.array<string>().nullable().required();
          return Yup.array<string>().nullable();
        })
      }),
      emails: Yup.string().when([], () => {
        if (isEmail) return Yup.string().email().nullable().required();
        return Yup.string().email();
      })
    }),
    query: Yup.string()
      .nullable()
      .when('basicMode', {
        is: false,
        then: Yup.string().test('queryTest', i18n.t('validation:required'), function checkQuery(value) {
          const { basicMode } = this.parent;
          if (!basicMode) {
            return !!value?.length;
          }

          return true;
        })
      })
  });
};

export interface AlertFormNamedObject {
  value: string & AlertFormNamedObject;
  name: string & AlertFormNamedObject;
}

export interface AlertFormQueryConditionFilter extends Omit<QueryConditionFilter, 'column'> {
  column: string & AlertFormNamedObject;
  operator?: string & AlertFormNamedObject;
}

export interface AlertFormQueryCondition extends AlertFormQueryConditionFilter {
  filter: AlertFormQueryConditionFilter;
  group?: Array<AlertFormQueryCondition>;
}
