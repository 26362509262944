import { useCallback, useMemo } from 'react';
import { ProceedingAvailableFormsDetails } from '@ibtm/domain';

import { ColumnTypesEnum, SelectOption, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO, getValue } from '@libs/common/v2/utils';

import { UserFilterV2 } from '@libs/user';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { DriverCertificatesColumnsEnum } from '@libs/domain/driver-certificate';
import { LicensesColumnsEnum } from '@libs/domain/license';
import { PermissionCopiesColumnsEnum, PermissionsColumnsEnum } from '@libs/domain/permission';

function useAvailableFormColumns(visibleColumns: Array<string>) {
  const { createColumns } = useCreateColumns<ProceedingAvailableFormsDetails>({
    pathToTranslate: 'folder:availableForm.field'
  });

  const permissionToAvailableFormMapper = useMemo(
    () => ({
      [PermissionsColumnsEnum.PERMISSION_NUMBER]: 'number',
      [PermissionsColumnsEnum.STATUS]: 'formStateKey',
      [PermissionCopiesColumnsEnum.CANCELLATION_REASON]: 'cancellationReasonKey',
      [PermissionCopiesColumnsEnum.CANCELLED_BY]: 'cancelledByName',
      [LicensesColumnsEnum.LICENSE_NUMBER]: 'number',
      [DriverCertificatesColumnsEnum.DRIVER_CERTIFICATE_NUMBER]: 'number',
      [PermissionCopiesColumnsEnum.PERMISSION_COPY_NUMBER]: 'number'
    }),
    []
  );

  const isColumnVisible = useCallback(
    (columnId: string) => {
      return visibleColumns.includes(permissionToAvailableFormMapper[columnId] ?? columnId);
    },
    [permissionToAvailableFormMapper, visibleColumns]
  );

  const columnDefinitions = createColumns([
    {
      id: 'applicationNumber',
      type: ColumnTypesEnum.TEXT,
      isSortable: true
    },
    {
      id: 'number',
      type: ColumnTypesEnum.TEXT
    },
    {
      id: 'formNumber',
      type: ColumnTypesEnum.TEXT
    },
    {
      id: 'validFrom',
      type: ColumnTypesEnum.DATE
    },
    {
      id: 'validTo',
      type: ColumnTypesEnum.DATE
    },
    {
      id: 'printDate',
      type: ColumnTypesEnum.DATE
    },
    {
      id: 'dateOfIssue',
      type: ColumnTypesEnum.DATE
    },
    {
      id: 'dateOfIssueExternal',
      type: ColumnTypesEnum.DATE
    },
    {
      id: 'subjectName',
      type: ColumnTypesEnum.TEXT
    },
    {
      id: 'subjectAddress',
      type: ColumnTypesEnum.TEXT
    },
    {
      id: 'cancellationDate',
      type: ColumnTypesEnum.DATE
    },
    {
      id: 'cancellationReasonKey',
      type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
      dictionaryName: DomainDictionaryEnum.PERMISSION_CANCELLATION_REASON
    },
    {
      accessor: 'cancelledBy.name',
      type: ColumnTypesEnum.CUSTOM_COLUMN,
      customAccessor: row => getValue(row.cancelledBy?.name),
      filter: UserFilterV2
    },
    {
      accessor: 'signer.name',
      type: ColumnTypesEnum.CUSTOM_COLUMN,
      customAccessor: row => getValue(row.signer?.name),
      filter: UserFilterV2
    },
    {
      id: 'statusKey',
      type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
      dictionaryName: DomainDictionaryEnum.FORM_OPERATION_STATUS
    }
  ]);

  const columns = useMemo(() => {
    return columnDefinitions.filter(({ id }) => isColumnVisible(id));
  }, [columnDefinitions, isColumnVisible]);

  const mappedSortFields: TableSortMapper<ProceedingAvailableFormsDetails> = useMemo(
    () => ({
      signerName: 'signer.name',
      cancelledByName: 'cancelledBy.name'
    }),
    []
  );

  const mappedFilterFields = useMemo(
    () => ({
      applicationNumber: (value: string) => ({ applicationNumberContains: value }),
      number: (value: string) => ({ numberContains: value }),
      formNumber: (value: string) => ({ formNumberContains: value }),
      validFrom: ({ dateFrom, dateTo }: DateRangeISO) => ({
        validFromGreaterThanOrEqual: dateFrom,
        validFromLessThanOrEqual: dateTo
      }),
      validTo: ({ dateFrom, dateTo }: DateRangeISO) => ({
        validToGreaterThanOrEqual: dateFrom,
        validToLessThanOrEqual: dateTo
      }),
      printDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
        printDateGreaterThanOrEqual: dateFrom,
        printDateLessThanOrEqual: dateTo
      }),
      dateOfIssue: ({ dateFrom, dateTo }: DateRangeISO) => ({
        dateOfIssuelGreaterThanOrEqual: dateFrom,
        dateOfIssueLessThanOrEqual: dateTo
      }),
      dateOfIssueExternal: ({ dateFrom, dateTo }: DateRangeISO) => ({
        dateOfIssueExternalGreaterThanOrEqual: dateFrom,
        dateOfIssueExternalLessThanOrEqual: dateTo
      }),
      subjectName: (value: string) => ({ subjectNameContains: value }),
      subjectAddress: (value: string) => ({ subjectAddressContains: value }),
      cancellationDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
        cancellationDateGreaterThanOrEqual: dateFrom,
        cancellationDateLessThanOrEqual: dateTo
      }),
      cancellationReasonKey: (values: SelectOption<string>[]) => ({
        cancellationReasonKeyIn: values.map(({ value }) => value)
      }),
      cancelledByName: (values: SelectOption<string>[]) => ({ cancelledByIdIn: values.map(({ value }) => value) }),
      signerName: (values: SelectOption<string>[]) => ({ signerIdIn: values.map(({ value }) => value) }),
      formStateKey: (values: SelectOption<string>[]) => ({ formStatusKeyIn: values.map(({ value }) => value) })
    }),
    []
  );

  return { columns, mappedSortFields, mappedFilterFields };
}

export default useAvailableFormColumns;
