import { useSelector } from 'react-redux';

import { ValidatorDetails, ValidatorEnums } from '@libs/common/v2/form';

function useValidator(validator: ValidatorEnums): ValidatorDetails {
  const data = useSelector(({ context }) => context?.context?.data?.validators || {}) as
    | Record<ValidatorEnums, ValidatorDetails>
    | object;

  return typeof validator === 'string' ? data?.[validator] || {} : validator;
}

export default useValidator;
