import { useCallback, useState } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { QueryConfig, QueryKey } from 'react-query';
import { AxiosPromise } from 'axios';
import { isArray } from 'lodash';

import {
  AutocompleteLazyFetchSelect,
  ComponentErrorBoundary,
  FormErrorType,
  getInputMode,
  InputMode,
  NUMBER_OF_AUTOCOMPLETE_RESULTS,
  SelectOption,
  useFormV2Context,
  Value
} from '@libs/common/v2';
import { getValue } from '@libs/common/v2/utils';

import { HasPermission } from '@libs/permission';

import { DomainUIElementEnum } from '@libs/domain/config';

import {
  FieldTypeEnum,
  SelectFieldValidation,
  SelectMultipleFieldValidation,
  useFieldValidationHandler
} from '../../validation';

export interface AutocompleteLazyFetchSelectFieldProps {
  name: FieldPath<FieldValues>;
  queryKey: string;
  queryKeyParams?: QueryKey;
  className?: string;
  optionLabelParser: (data: any) => string;
  api: {
    FETCH: (searchText?: string, params?: any) => AxiosPromise | Promise<any>;
  };
  filterFunction?: (dataRow: any) => boolean;
  getValueFormat?: (value: any) => string;
  label?: string;
  tooltip?: string;
  inputMode?: InputMode;
  isRequired?: boolean;
  isDisableClearable?: boolean;
  valueClassName?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  isMultiple?: boolean;
  isFreeSolo?: boolean;
  queryConfig?: QueryConfig<any, unknown>;
  isQueryInitiallyEnabled?: boolean;
  validationSingleSelect?: SelectFieldValidation;
  validationMultipleSelect?: SelectMultipleFieldValidation;
  onChange?: (value: SelectOption) => void;
  renderOption?: (option: SelectOption) => React.ReactNode;
  customErrorMessage?: string;
  hasErrorTooltip?: boolean;
  actionKey?: DomainUIElementEnum;
}

function AutocompleteLazyFetchSelectField({
  name,
  queryKey,
  queryKeyParams,
  className,
  optionLabelParser,
  filterFunction,
  api,
  label,
  tooltip,
  inputMode,
  isRequired,
  isDisableClearable,
  getValueFormat,
  valueClassName,
  queryConfig,
  isQueryInitiallyEnabled,
  isLoading,
  isDisabled,
  isMultiple,
  isFreeSolo,
  validationMultipleSelect,
  validationSingleSelect,
  onChange: customOnChange,
  renderOption,
  hasErrorTooltip,
  customErrorMessage,
  actionKey
}: AutocompleteLazyFetchSelectFieldProps) {
  const { control, formMode, loading, isSubmitting } = useFormV2Context();
  const [totalPages, setTotalPages] = useState<number>(0);
  const mode = getInputMode(formMode, inputMode);
  const emptyValue = isMultiple ? [] : null;
  const parseDataToSelect = useCallback(
    data => {
      if (!isArray(data)) {
        return [];
      }

      const mapRecord = record => ({
        id: record.id,
        value: record,
        name: optionLabelParser(record)
      });

      const recordsToMap = filterFunction ? data.filter(filterFunction) : data;
      return recordsToMap.map(mapRecord);
    },
    [filterFunction, optionLabelParser]
  );

  const fetchFunction = async (searchText: string, page: number) => {
    if (!isDisabled) {
      const { data } = await api.FETCH(searchText, {
        page,
        size: NUMBER_OF_AUTOCOMPLETE_RESULTS
      });
      setTotalPages(data?.totalPages);
      return data;
    }

    return [];
  };

  useFieldValidationHandler(
    isMultiple
      ? {
          fieldName: name,
          validation: validationMultipleSelect,
          isMultiple: true,
          fieldType: FieldTypeEnum.AUTOCOMPLETE
        }
      : {
          fieldName: name,
          validation: validationSingleSelect,
          isMultiple: false,
          fieldType: FieldTypeEnum.AUTOCOMPLETE
        }
  );

  const customFilterOptionsMap = (options: SelectOption[]) => options;

  return (
    <HasPermission actionKeys={[actionKey]}>
      <ComponentErrorBoundary componentName={label || name || 'AutocompleteLazyFetchSelectField'}>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) =>
            mode === InputMode.FORM ? (
              <AutocompleteLazyFetchSelect
                name={name}
                label={label}
                className={className}
                tooltip={tooltip}
                queryKey={queryKey}
                queryKeyParams={queryKeyParams}
                isRequired={isRequired}
                value={value || emptyValue}
                onChange={(_event, selectValue) => {
                  customOnChange?.(selectValue);
                  onChange(selectValue);
                }}
                fetchFunctionResolverWithPage={fetchFunction}
                fetchedDataSelectParser={parseDataToSelect}
                onBlur={onBlur}
                isError={Boolean(customErrorMessage) || (!!error && error?.type !== FormErrorType.WARNING)}
                helperText={customErrorMessage || error?.message}
                isDisableClearable={isDisableClearable}
                isDisabled={isDisabled || isSubmitting || isLoading}
                isMultiple={isMultiple}
                isFreeSolo={isFreeSolo}
                queryConfig={queryConfig}
                isQueryInitiallyEnabled={isQueryInitiallyEnabled}
                renderOption={renderOption}
                totalPages={totalPages}
                customFilterOptions={customFilterOptionsMap}
                hasErrorTooltip={hasErrorTooltip}
              />
            ) : (
              <Value
                label={label}
                isLoading={loading || isLoading}
                className={valueClassName}
                isError={!!error && error?.type !== FormErrorType.WARNING}
                isWarning={error?.type === FormErrorType.WARNING}
                helperText={error?.message}
              >
                {!loading &&
                  getValue(
                    Array.isArray(value)
                      ? (value as SelectOption<string>[])
                          .map(
                            item =>
                              (Boolean(getValueFormat) && getValue?.(getValueFormat?.(item))) ||
                              item?.name ||
                              item?.value ||
                              item
                          )
                          .join(', ')
                      : (Boolean(getValueFormat) && getValue?.(getValueFormat?.(value))) ||
                          ((value?.name || value?.value || value) as string)
                  )}
              </Value>
            )
          }
        />
      </ComponentErrorBoundary>
    </HasPermission>
  );
}

export default AutocompleteLazyFetchSelectField;
