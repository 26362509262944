import { useTranslation } from 'react-i18next';

import { AutocompleteSelectField, SelectOption, typedNameV2 } from '@libs/common/v2';

import { usePrintsActionsDataContext } from '@libs/domain/print/context';
import { TravelFormRegenerateRegenerateForm } from '@libs/domain/print/model/';
import { PoolItem } from '@libs/domain/resource';

export function TravelFormNumberSelectField() {
  const [t] = useTranslation();
  const {
    actionData: { travelFormPrintsSnapshot }
  } = usePrintsActionsDataContext() ?? {};
  const { firstFormNumber, lastFormNumber } = travelFormPrintsSnapshot ?? {};

  const options: Array<SelectOption<PoolItem>> = [];
  for (let formNumber = firstFormNumber; formNumber <= lastFormNumber; formNumber += 1) {
    options.push({
      name: formNumber.toString(),
      value: { name: formNumber?.toString(), amount: 1, numberFrom: formNumber, numberTo: formNumber }
    });
  }

  return (
    <AutocompleteSelectField
      name={typedNameV2<TravelFormRegenerateRegenerateForm>('selectedNumbers')}
      label={t('prints:field.detailsFields.formNumbersToRegenerate')}
      options={options}
      validationMultipleSelect={{ required: [] }}
      isMultiple
      isRequired
    />
  );
}
