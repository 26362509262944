import { CivicBoardRuleResourceTypeCreateRequest } from '@ibtm/domain';

import { ColumnTypesEnum, InputMode, useCreateColumns, useEditableInputsStyles } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { ResourceTypesSelectField } from '../../components';

export interface AdditionalResourceTypesForm extends CivicBoardRuleResourceTypeCreateRequest {
  id: string;
  resourceType: { id: string; name?: string; nameKey: string };
}

export const useAdditionalResourceTypesColumns = (mainResourceType: any) => {
  const { translate } = useDictionaryTranslations();
  const classes = useEditableInputsStyles();
  const { createColumns } = useCreateColumns<AdditionalResourceTypesForm>({
    pathToTranslate: 'foreignPermits:tabs.socialCommission.tabs.SocialCommissionBasicRules.fields',
    isMutableTable: true
  });

  const hasResourceNameError = (error: any) => {
    return !!error?.nameKey;
  };

  const getResourceNameErrorMessage = (error: any) => {
    return error?.nameKey?.message;
  };

  const columns = createColumns([
    {
      id: 'resourceTypeId',
      accessor: 'resourceType.nameKey',
      header: 'resourceName',
      type: ColumnTypesEnum.DICTIONARY_MULTI_SELECT,
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_NAME,
      customEdit: (_inputMode: InputMode, original: AdditionalResourceTypesForm, inputName: string) => {
        const parsedInputName = inputName.replace('.nameKey', '');
        return (
          <ResourceTypesSelectField
            fieldName={parsedInputName}
            initialValue={{
              id: original?.resourceType?.id,
              value: original?.resourceType?.nameKey,
              nameKey: original?.resourceType?.nameKey,
              name: translate(DomainDictionaryEnum.RESOURCE_FORM_NAME, original?.resourceType?.nameKey)
            }}
            className={classes.select}
            countryKeyIn={[mainResourceType?.countryKey]}
            yearFilter={mainResourceType?.year && mainResourceType.year - 1}
            hasError={hasResourceNameError}
            getErrorMessage={getResourceNameErrorMessage}
            hasErrorTooltip
          />
        );
      },
      isEditable: true,
      isCreatable: true,
      width: 200,
      isFilterable: false,
      isSortable: false,
      isRequired: true
    },
    {
      type: ColumnTypesEnum.BOOLEAN,
      accessor: 'impactLimit',
      header: 'isIncludingLimit',
      isEditable: true,
      isCreatable: true,
      isFilterable: false,
      isSortable: false
    }
  ]);

  return { columns };
};
