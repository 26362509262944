import { DefaultApiUnassignMembersRequest } from '@avispon/group';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '..';

const unassignGroupMember = async (params: DefaultApiUnassignMembersRequest) => {
  const { data } = await API.group.unassignMembers(params, {
    ...endpointsConfig.unassignMembers
  });
  return data;
};

export default function useUnassignGroupMemberMutation() {
  return useMutation(unassignGroupMember);
}
