/* eslint-disable consistent-return */
/* eslint-disable default-case */
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { FolderDetails } from '@ibtm/domain';
import moment from 'moment';
import { object as YupObject, string as YupString } from 'yup';

import { i18n } from '@libs/common';
import { FormV2Context } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { getCalendarDate } from '@libs/common/v2/utils';

import useDictionaryEntryValues from '@libs/dictionary/hooks/useDictionaryEntryValues';

import { DomainDictionaryEnum, useDomainConfigContext } from '@libs/domain/config';
import { FolderQueryKeysEnum } from '@libs/domain/folder';

import {
  useBorrowFolderMutation,
  useBorrowFolderSingleQuery,
  useChangeFolderAssignmentMutation,
  useReturnFolderMutation
} from '../../api';
import {
  BorrowFolderDialogTypeEnum,
  BorrowFolderFormEnum,
  BorrowFoldersContextType,
  BorrowFolderSendValues,
  BorrowFolderSnapshot,
  BorrowFolderState,
  initialValues
} from '../../model';

export const BorrowFoldersContext = createContext<BorrowFoldersContextType>({
  row: null,
  open: false,
  onClose: null,
  onOpen: null,
  contentType: null,
  handleSend: null,
  onOpenWithCheck: null
});

export const useBorrowFoldersContext = () => {
  const borrowFoldersContext = useContext(BorrowFoldersContext);
  return borrowFoldersContext;
};

interface BorrowFolderProviderProps {
  refresh?: () => void;
  children: React.ReactNode;
  selectedFolder?: { label: string; value: string };
}

const getSchemaBorrow = () =>
  YupObject({
    borrow: YupObject({
      borrower: YupObject().nullable().required(),
      borrowPurpose: YupObject().nullable(),
      borrowDate: YupString().nullable().required()
    })
  });
const getSchemaChangeBorrower = () =>
  YupObject({
    borrow: YupObject({
      borrower: YupObject().nullable().required(),
      borrowPurpose: YupObject().nullable().required()
    })
  });
const getSchemaReturn = () =>
  YupObject({
    returnDate: YupString()
      .nullable()
      .required()
      .test(
        'isBeforeBorrowDate',
        i18n.t('archive:borrowFolder.validation.returnAfterBorrow'),
        function checkIsBeforeBorrowDate(returnDate) {
          const { borrowDate } = this.parent as typeof initialValues.borrow;
          return !borrowDate || moment(returnDate).isSameOrAfter(borrowDate);
        }
      ),
    returnPerson: YupObject().nullable().required()
  });

export function BorrowFolderProvider({ selectedFolder, refresh, children }: BorrowFolderProviderProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { isOperatorPortal } = useDomainConfigContext();

  const [borrowFolderState, setBorrowFolderState] = useState<BorrowFolderState>({
    open: false,
    contentType: null,
    row: null,
    rowToCheck: null,
    refetch: refresh || (() => null)
  });
  const { mutateAsync: borrowFolder } = useBorrowFolderMutation();
  const { mutateAsync: changeFolderAssignment } = useChangeFolderAssignmentMutation();
  const { mutateAsync: returnFolder } = useReturnFolderMutation();
  const { data, refetch } = useBorrowFolderSingleQuery(borrowFolderState?.row?.number, {
    enabled: false
  });

  const dictionaryEntries = useDictionaryEntryValues(DomainDictionaryEnum.FOLDER_BORROW_PURPOSE);
  const { showSnackbar } = useSnackbar();

  const ValidationSchemaBorrow = getSchemaBorrow();
  const ValidationSchemaChangeBorrower = getSchemaChangeBorrower();
  const ValidationSchemaReturn = getSchemaReturn();

  const getYupSchema = () => {
    if (borrowFolderState.contentType === BorrowFolderDialogTypeEnum.BORROW_FOLDER) return ValidationSchemaBorrow;
    return borrowFolderState.contentType === BorrowFolderDialogTypeEnum.CHANGE_BORROWER
      ? ValidationSchemaChangeBorrower
      : ValidationSchemaReturn;
  };

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    trigger,
    formState: { errors, isSubmitting }
  } = useForm<FieldValues>({
    defaultValues: initialValues,
    resolver: yupResolver(getYupSchema()),
    mode: 'onChange',
    criteriaMode: 'all'
  });
  const pickedRow = watch(BorrowFolderFormEnum.BORROW_PICKED_FOLDER) as BorrowFolderSnapshot & {
    label: string;
  };

  const onClose = useCallback(() => {
    setBorrowFolderState(prev => ({ ...prev, contentType: null, open: false, row: null, rowToCheck: null }));
    reset(initialValues);
    queryCache.invalidateQueries(FolderQueryKeysEnum.FOLDER_EXTENDED);
  }, [queryCache, reset]);

  const onOpen = useCallback(
    (type: BorrowFolderDialogTypeEnum, row: BorrowFolderSnapshot, tableRefetch?: () => void) => {
      setBorrowFolderState(prev => ({
        rowToCheck: null,
        row,
        open: true,
        contentType: type,
        refetch: tableRefetch || prev.refetch
      }));
    },
    []
  );

  const onOpenWithCheck = (
    type: BorrowFolderDialogTypeEnum,
    rowToCheck: BorrowFolderSnapshot & FolderDetails = null
  ) => {
    setBorrowFolderState(prev => ({ ...prev, rowToCheck, contentType: type }));
  };

  const setFormValue = () => {
    if (borrowFolderState.rowToCheck) {
      if (
        borrowFolderState.rowToCheck?.borrowDate &&
        borrowFolderState.contentType === BorrowFolderDialogTypeEnum.BORROW_FOLDER
      ) {
        setValue(BorrowFolderFormEnum.BORROW_PICKED_FOLDER, pickedRow.label, { shouldValidate: true });
        setBorrowFolderState(prev => ({ ...prev, rowToCheck: null }));
        return showSnackbar('warning', t('archive:borrowFolder.message.alreadyBorrowed'));
      }
      if (
        borrowFolderState.rowToCheck?.returnDate &&
        borrowFolderState.contentType === BorrowFolderDialogTypeEnum.RETURN_FOLDER
      ) {
        setValue(BorrowFolderFormEnum.BORROW_PICKED_FOLDER, pickedRow.label, { shouldValidate: true });
        setBorrowFolderState(prev => ({ ...prev, rowToCheck: null }));
        return showSnackbar('warning', t('archive:borrowFolder.message.alreatyReturned'));
      }
      onOpen(borrowFolderState.contentType, borrowFolderState.rowToCheck);
    }
    if (borrowFolderState.row) {
      return setValue(BorrowFolderFormEnum.BORROW_OBJECT, {
        [BorrowFolderFormEnum.BORROW_PICKED_FOLDER]: pickedRow || { label: borrowFolderState.row.number },
        [BorrowFolderFormEnum.BORROWER]:
          borrowFolderState.contentType === BorrowFolderDialogTypeEnum.RETURN_FOLDER
            ? { id: borrowFolderState?.row?.borrower?.name }
            : borrowFolderState.row.borrower?.name || '',
        [BorrowFolderFormEnum.BORROW_PURPOSE]:
          borrowFolderState.contentType === BorrowFolderDialogTypeEnum.RETURN_FOLDER
            ? { name: borrowFolderState.row.borrowPurpose }
            : dictionaryEntries.find(item => item.value === borrowFolderState.row.borrowPurpose) || '',
        [BorrowFolderFormEnum.CONTENT_TYPE]: borrowFolderState.contentType
      });
    }
  };

  useEffect(() => {
    if (borrowFolderState.contentType === BorrowFolderDialogTypeEnum.RETURN_FOLDER && borrowFolderState.row?.number) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [borrowFolderState]);

  useEffect(() => {
    if (borrowFolderState.open) {
      setFormValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [borrowFolderState.open, borrowFolderState.row, borrowFolderState.rowToCheck]);

  const clearAfterSubmit = useCallback(
    (message: string, showSnackbarMessage = true) => {
      onClose();
      borrowFolderState.refetch();

      if (showSnackbarMessage) {
        showSnackbar('success', message);
      }
    },
    [borrowFolderState, onClose, showSnackbar]
  );

  const handleSend = useCallback(
    async ({
      borrow: { borrower, borrowDate, borrowPurpose },
      returnDate,
      returnPerson,
      pickedFolder
    }: BorrowFolderSendValues) => {
      const id = borrowFolderState.row?.id;
      switch (borrowFolderState.contentType) {
        case BorrowFolderDialogTypeEnum.CHANGE_BORROWER:
          await changeFolderAssignment(
            {
              folderRentalId: id,
              borrowerId: borrower.id,
              version: borrowFolderState.row.version,
              purposeKey: borrowPurpose?.value ?? null
            },
            {
              onSuccess: () => {
                clearAfterSubmit(t('archive:borrowFolder.message.changedBorrower'));
              }
            }
          );
          return;
        case BorrowFolderDialogTypeEnum.BORROW_FOLDER:
          await borrowFolder(
            {
              folderId: id ?? pickedFolder.value,
              borrowedBy: borrower.id,
              borrowDate: getCalendarDate(borrowDate),
              purposeKey: borrowPurpose?.value
            },
            {
              onSuccess: () => {
                clearAfterSubmit(t('archive:borrowFolder.message.folderBorrowed'), isOperatorPortal);
              }
            }
          );
          return;

        case BorrowFolderDialogTypeEnum.RETURN_FOLDER:
          await returnFolder(
            {
              folderRentalId: data?.id || borrowFolderState.row.id,
              returnDate: getCalendarDate(returnDate),
              returnedBy: returnPerson?.id
            },
            {
              onSuccess: () => {
                clearAfterSubmit(t('archive:borrowFolder.message.folderReturned'));
              }
            }
          );
      }
    },
    [
      data?.id,
      borrowFolder,
      borrowFolderState.contentType,
      borrowFolderState.row?.id,
      borrowFolderState.row?.version,
      changeFolderAssignment,
      clearAfterSubmit,
      returnFolder,
      isOperatorPortal,
      t
    ]
  );

  const contextValues = useMemo<BorrowFoldersContextType>(
    () => ({
      row: borrowFolderState.row,
      open: borrowFolderState.open,
      onClose,
      onOpen,
      onOpenWithCheck,
      contentType: borrowFolderState.contentType,
      handleSend: handleSubmit(sendValues => handleSend(sendValues as BorrowFolderSendValues)),
      selectedFolder
    }),
    [
      borrowFolderState.contentType,
      borrowFolderState.open,
      borrowFolderState.row,
      handleSend,
      handleSubmit,
      onClose,
      onOpen,
      selectedFolder
    ]
  );

  const values = useMemo(
    () => ({
      control,
      isSubmitting,
      errors,
      reset,
      setValue,
      trigger,
      watch,
      handleSubmit
    }),
    [control, errors, handleSubmit, isSubmitting, reset, setValue, trigger, watch]
  );

  return (
    <FormV2Context.Provider value={values}>
      <BorrowFoldersContext.Provider value={contextValues}>{children}</BorrowFoldersContext.Provider>
    </FormV2Context.Provider>
  );
}
