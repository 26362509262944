import { useContext } from 'react';

import { ThemeContext } from '@libs/common/v2/theme';

export default function useTheme() {
  const { contrast, fontSizeZoom, getFontSizeZoom, setContrast } = useContext(ThemeContext);
  return {
    /**
     * Wartość boolean mówiąca czy aktualna paleta kolorów jest normalna czy kontrastowa
     */
    contrast,
    /**
     * Aktualny mnożnik rozmiaru fontu na stronie
     */
    fontSizeZoom,
    /**
     * Funkcja do zmiany aktualnego mnożnika fontu na stronie, możliwości mnożnika zdefiniowane w ThemeFontSizeEnum
     */
    changeFontSizeZoom: getFontSizeZoom,
    /**
     * Funkcja do ustawienia palety kontrastowej bądź normalnej
     */
    setContrast
  };
}
