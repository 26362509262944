import { Path } from '@libs/common/v2';

import { getPropertyFromRow } from './get-property-from-row.util';

/**
 * Funkcja przyjmuje aktualne dane do wyświetlenia w tabeli i tablicę ścieżek
 * z modelu do wartości które są tablicami. Na ich podstawie znajduje najdłuższe tablice,
 * które są wykorzystane przy renderowaniu kolumn.
 */
const getTheLongestRowToRenderColumns = <T extends Record<string, any>>(currData, arrayPaths: Path<T>[]) => {
  // Jeżeli nie ma podanych ścieżek zwróć pierwszy wiersz
  if (!arrayPaths?.length) {
    return currData?.[0];
  }

  // Jak są podane ścieżki atrybutów do wartości będących tablicą znajdź najdłuższe
  return currData?.reduce((acc, item) => {
    const arrValues = Object.entries(
      arrayPaths.reduce((acc2, el) => ({ ...acc2, [el]: getPropertyFromRow(item, el) }), {})
    );
    return arrValues.reduce((acc3, [key, value]: any) => {
      if (!value?.length) {
        return acc3;
      }
      if (acc3[key] && acc3[key]?.length < value.length) {
        return { ...acc3, [key]: value };
      }
      if (!acc3[key]) {
        return { ...acc3, [key]: value };
      }
      return acc3;
    }, acc);
  }, {});
};
export default getTheLongestRowToRenderColumns;
