import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function confirmDataMigration({ applicationId, version }: { applicationId: string; version: number }) {
  const response = API.folder.confirmDataMigration(
    applicationId,
    { version },
    {
      ...endpointsConfig.confirmDataMigration
    }
  );
  return response;
}

function useConfirmDataMigrationMutation() {
  return useMutation(confirmDataMigration);
}

export default useConfirmDataMigrationMutation;
