import { FieldNamesMarkedBoolean } from 'react-hook-form';

import { GridItem, GridLayout, typedNameV2, useFormV2Context } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DefaultAlertFromValues } from '@libs/alert/model';

import ConditionAlertSection from './ConditionAlertSection';
import GeneralAlertSection from './GeneralAlertSection';
import NotificationAlertSection from './NotificationAlertSection';
import RegisterAlertSection from './RegisterAlertSection';

interface IProps {
  dirtyFields?: FieldNamesMarkedBoolean<Record<string, any>>;
}

function AlertGeneralDataTab({ dirtyFields }: IProps) {
  const { formMode } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);

  return (
    <GridLayout>
      <GridItem xs={12} md={4}>
        <GridLayout>
          <GridItem xs={12}>
            <GeneralAlertSection formMode={formMode} />
          </GridItem>
          {viewMode && (
            <GridItem xs={12}>
              <RegisterAlertSection />
            </GridItem>
          )}
        </GridLayout>
      </GridItem>
      <GridItem xs={12} md={8}>
        <GridLayout>
          <GridItem xs={12}>
            <NotificationAlertSection formMode={formMode} />
          </GridItem>
          {viewMode && (
            <GridItem xs={12}>
              <ConditionAlertSection
                formMode={formMode}
                name={typedNameV2<DefaultAlertFromValues>('queryConditions.0')}
                queryName={typedNameV2<DefaultAlertFromValues>('query')}
              />
            </GridItem>
          )}
        </GridLayout>
      </GridItem>
      {!viewMode && (
        <GridItem xs={12} md={12}>
          <ConditionAlertSection
            name={typedNameV2<DefaultAlertFromValues>('queryConditions.0')}
            queryName={typedNameV2<DefaultAlertFromValues>('query')}
            dirtyFields={dirtyFields}
            formMode={formMode}
            isParameterizable={false}
          />
        </GridItem>
      )}
    </GridLayout>
  );
}

export default AlertGeneralDataTab;
