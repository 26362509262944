import React from 'react';
import { amber } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Theme } from '@libs/common/v2/theme';

interface IProps {
  mode: 'ERROR' | 'WARNING';
  text: string;
}

function WarningMessage({ mode, text }: IProps) {
  const classes = useStyles();

  return <p className={clsx({ [classes.error]: mode === 'ERROR', [classes.warning]: mode === 'WARNING' })}>{text}</p>;
}

const useStyles = makeStyles<Theme>(theme => ({
  error: {
    color: theme.palette.error[700]
  },
  warning: {
    color: amber[600]
  }
}));

export default WarningMessage;
