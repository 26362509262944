import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  BackButton,
  ButtonsGroup,
  FormMode,
  FormV2Context,
  GridLayout,
  Page,
  PageHeader,
  Section,
  useIsSmallScreen,
  useRouter
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';
import { convertCalendarDate } from '@libs/common/v2/utils';

import { useApplicationDetailsQuery } from '@libs/domain/application';
import { DomainDictionaryEntry, DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import {
  ExploitationBaseInspectionSnapshot,
  ExploitationBaseRouteParams,
  ExploitationBaseStatusEnum,
  parseExploitationBaseFormData,
  useExploitationBaseDetailsQuery
} from '@libs/domain/exploitation-base';
import { useFolderDetailsExtendedQuery } from '@libs/domain/folder';

import ExploitationBaseFormData, { exploitationBaseValidationSchema } from '../../model/exploitation-base-form.model';

import {
  ExploitationBaseGeneralDataSection,
  ExploitatoinBaseDetailsActions,
  GeneralInformationSection,
  InspectionSection,
  InspectionTable
} from './components';

interface IProps {
  formMode: FormMode;
}

function ExploitationBaseDetails({ formMode }: IProps) {
  const { id, folderId, applicationId } = useParams<ExploitationBaseRouteParams>();
  const isInspectionTable = !!folderId;
  const [t] = useTranslation();
  const { goBack } = useRouter();
  const [initialFormData, setInitialFormData] = useState<ExploitationBaseFormData>(null);
  const [checkedBaseInspections, setCheckedBaseInspections] = useState<ExploitationBaseInspectionSnapshot[]>(null);
  const { createMode } = useViewModesV2(formMode);
  const { isOperatorPortal } = useDomainConfigContext();
  const { isSmallScreen } = useIsSmallScreen();

  const { data: applicationData } = useApplicationDetailsQuery(applicationId, { refetchOnMount: false });

  const currentFolderId = useMemo(
    () => folderId || applicationData?.folder?.id,
    [folderId, applicationData?.folder?.id]
  );

  const { data: folderData } = useFolderDetailsExtendedQuery(currentFolderId, {
    enabled: Boolean(currentFolderId)
  });

  const transferredFoldersIds =
    applicationData?.licenseApplicationDetails?.transferApplicationDataDetails?.folders?.map(folder => folder.id);

  const isWithdrawnBaseVisible =
    applicationData?.licenseApplicationDetails?.exploitationBaseModificationTypeKey ===
    DomainDictionaryEntry.APPLICATION_EXPLOITATION_BASE_MODIFICATION_TYPE.MODIFICATION;

  const isAddressCountryFieldAvaliable =
    applicationData?.folder?.typeKey === DomainDictionaryEntry.FOLDER_TYPE.MZ ||
    folderData?.typeKey === DomainDictionaryEntry.FOLDER_TYPE.MZ;

  const { data, isLoading, isFetching } = useExploitationBaseDetailsQuery(id, {
    enabled: !createMode,
    forceFetchOnMount: true
  });

  const inspections = data?.inspections?.map(item => ({ id: item.id, ...item })) || [];

  const form = useForm<Partial<ExploitationBaseFormData>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(exploitationBaseValidationSchema(isWithdrawnBaseVisible))
  });

  const {
    formState: { isSubmitting, errors },
    reset
  } = form;

  useEffect(() => {
    if (data) {
      setInitialFormData(parseExploitationBaseFormData(data));
      reset(parseExploitationBaseFormData(data));
    } else {
      reset({
        applicationId,
        statusKey: { value: ExploitationBaseStatusEnum.NEW },
        addressTypeKey: { value: DomainDictionaryEntry.ADDRESS_TYPE.BASE }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const values = useMemo(
    () => ({ ...form, formMode, isSubmitting, errors, loading: isLoading }),
    [errors, form, formMode, isLoading, isSubmitting]
  );

  return (
    <FormV2Context.Provider value={values}>
      <Page
        isContentScrollEnabled={!isSmallScreen}
        header={
          <PageHeader
            title={t('exploitationBase:details.title')}
            rightSideContent={
              <ButtonsGroup>
                <BackButton onClick={goBack} />
                <ExploitatoinBaseDetailsActions
                  id={id}
                  applicationId={applicationId}
                  setCheckedBaseInspections={setCheckedBaseInspections}
                  permissionKeys={
                    applicationId && {
                      showGeoportal: DomainUIElementEnum.APPLICATION_EXLOITATION_BASES_SHOW_GEOPORTAL_BUTTON
                    }
                  }
                />
              </ButtonsGroup>
            }
          />
        }
        content={
          <GridLayout itemProps={{ xs: 12 }}>
            <Section>
              <ExploitationBaseGeneralDataSection
                isWithdrawnBaseVisible={isWithdrawnBaseVisible}
                folderId={currentFolderId}
                transferredFoldersIds={transferredFoldersIds}
                isAddressCountryFieldAvaliable={isAddressCountryFieldAvaliable}
              />
            </Section>
            {checkedBaseInspections?.length > 1 && (
              <InspectionTable
                inspections={checkedBaseInspections}
                title={t('exploitationBase:details.section.checkedControlBase')}
              />
            )}
            {isOperatorPortal && (
              <InspectionSection
                inspections={inspections.map(element => ({
                  ...element,
                  inspectionDate: convertCalendarDate(element.inspectionDate)
                }))}
                initialExploitationBaseData={initialFormData}
                exploitationBaseId={id}
                isInspectionTable={isInspectionTable}
                isFetching={isFetching}
                formMode={formMode}
              />
            )}
            <GeneralInformationSection />
          </GridLayout>
        }
      />
    </FormV2Context.Provider>
  );
}

export default ExploitationBaseDetails;
