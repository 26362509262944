import { ReactNode } from 'react';

import { useFormV2Context } from '../context';
import { FormMode } from '../model';

interface IValues {
  createValue?: ReactNode;
  editValue?: ReactNode;
  viewValue?: ReactNode;
}

const useBreadcrumbTextByFormMode = (
  { createValue, editValue, viewValue }: IValues,
  customFormMode: FormMode = null
) => {
  const { formMode } = useFormV2Context();

  switch (customFormMode || formMode) {
    case FormMode.CREATE:
      return createValue ?? null;
    case FormMode.EDIT:
      return editValue ?? null;
    case FormMode.VIEW:
      return viewValue ?? null;
    default:
      return null;
  }
};

export default useBreadcrumbTextByFormMode;
