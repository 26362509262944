import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface IProps {
  folderId?: string;
  content?: string;
  version?: number;
}

async function editFolderNote({ folderId, content, version }: IProps) {
  const { data } = await API.folderNote.updateFolderNote(
    folderId,
    { content, version },
    {
      ...endpointsConfig.updateFolderNote
    }
  );

  return data;
}

function useEditFolderNoteMutation() {
  return useMutation(editFolderNote);
}

export default useEditFolderNoteMutation;
