import { SuspensionFormRestoreRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface RestoreFormOperationsParams extends SuspensionFormRestoreRequest {
  suspensionId: string;
}

function restoreFormOperations({ suspensionId, ...request }: RestoreFormOperationsParams) {
  return API.suspensions.restoreSuspensionForms(suspensionId, request, {
    ...endpointsConfig.restoreSuspensionForms
  });
}

function useRestoreFormOperationsMutation() {
  return useMutation(restoreFormOperations);
}

export default useRestoreFormOperationsMutation;
