import { useCallback, useEffect } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { ApplicationLite, ApplicationSnapshot } from '@ibtm/domain';
import { get } from 'lodash';

import {
  AutocompleteLazyFetchSelectField,
  NUMBER_OF_AUTOCOMPLETE_RESULTS,
  SelectOption,
  useFormV2Context,
  Value
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';
import { ValueOf } from '@libs/common/v2/utils';

import { UiMode } from '@libs/meta-form';
import { FieldTypes } from '@libs/meta-form/models';
import { useMapAdditionalFieldsValidationType } from '@libs/meta-form/services';
import { useElementVisibility } from '@libs/permission';

import { API } from '@libs/domain/api';
import { useApplicationDetailsQuery } from '@libs/domain/application';
import { DomainDictionaryEntry, DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';

import { ApplicationQueryKeysEnum } from '../../../api';
import { IApplicationDetailsPageParams } from '../../../model';

const LinkedApplicationOption = ({ number, id }: { number: string; id: string }) => {
  return (
    <Link key={id} to={`/applications/${id}/view`}>
      {number}
    </Link>
  );
};
interface IProps {
  fieldId: FieldPath<FieldValues>;
  label: string;
  multiple?: boolean;
  inputMode?: UiMode;
  api: {
    get: {
      accessor: string;
    };
  };
  yupValidation: {
    required: boolean;
  };
  typeKeyIn?: Array<ValueOf<typeof DomainDictionaryEntry.APPLICATION_TYPE>>;
  statusKeyIn?: Array<ValueOf<typeof DomainDictionaryEntry.APPLICATION_STATUS>>;
}
function SPOApplicationsSelectField({
  fieldId,
  label,
  multiple,
  inputMode,
  api,
  yupValidation,
  typeKeyIn,
  statusKeyIn
}: IProps) {
  const { required } = yupValidation ?? {};
  const { checkIsElementVisible } = useElementVisibility();
  const isLinkedApplicationsInputVisible = checkIsElementVisible(
    DomainUIElementEnum.APPLICATION_DETAILS_GENERAL_DATA_LINKED_APPLICATIONS_INPUT
  );
  const isOpenLinkedApplicationAvailable = checkIsElementVisible(
    DomainUIElementEnum.APPLICATION_DETAILS_GENERAL_DATA_LINKED_APPLICATIONS_OPEN_LINKED_APPLICATIONS
  );
  useMapAdditionalFieldsValidationType(fieldId, { type: FieldTypes.AUTOCOMPLETE, multiple });
  const { isClientPortal } = useDomainConfigContext();

  const { setValue, formMode } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);
  const { applicationId } = useParams<IApplicationDetailsPageParams>();
  const { data, isLoading: isDetailsLoading } = useApplicationDetailsQuery(applicationId, { refetchOnMount: false });

  const {
    APPLICATION_CATEGORY,
    APPLICATION_STATUS: { ISSUED, ACCOMPLISHED_WITHOUT_PRINTING, RELEASED_WAITING_FOR_ZPO }
  } = DomainDictionaryEntry;

  const getSPOApplicationCategoryKeys = () =>
    Object.values(APPLICATION_CATEGORY).filter(type => type?.match(/category.spo/));

  const transferredFoldersIds = data?.licenseApplicationDetails?.transferApplicationDataDetails?.folders?.map(
    folder => folder.id
  );

  const parseSelectOption = (item: ApplicationLite) => ({
    value: item?.id,
    name: item?.number,
    id: item?.id
  });

  const getAccessorData = useCallback(
    (): ApplicationLite[] | ApplicationLite => get(data, api?.get?.accessor),
    [data, api]
  );

  useEffect(() => {
    if (data) {
      const getValue = getAccessorData();
      const isGetValueArray = Array.isArray(getValue);

      if ((isGetValueArray && getValue.length === 0) || !getValue) {
        return;
      }
      const value: SelectOption[] | SelectOption = isGetValueArray
        ? getValue?.map(parseSelectOption)
        : parseSelectOption(getValue);

      setValue(fieldId, value);
    }
  }, [data, setValue, fieldId, api, getAccessorData]);

  const renderViewValue = () => {
    const value = getAccessorData();

    if (!value || (Array.isArray(value) && value.length === 0)) {
      return '-';
    }

    const renderOption = ({ applicationNumber, id, key }: { applicationNumber: string; id: string; key?: string }) =>
      isOpenLinkedApplicationAvailable ? (
        <LinkedApplicationOption number={applicationNumber} id={id} key={key} />
      ) : (
        applicationNumber
      );
    return Array.isArray(value)
      ? value?.map((item, index) => (
          <>
            {renderOption({ applicationNumber: item.number, id: item.id, key: item.id })}
            {data.linkedApplications.length > index + 1 && ', '}
          </>
        ))
      : renderOption({ applicationNumber: value.number, id: value.id });
  };

  const renderOption = (props: SelectOption<ApplicationSnapshot>) => <>{props?.value.number}</>;

  const getOptionLabel = (option: ApplicationSnapshot) => {
    return option.number;
  };

  return viewMode || inputMode === UiMode.VIEW || !isLinkedApplicationsInputVisible ? (
    <Value label={label} value={renderViewValue()} isLoading={isDetailsLoading} />
  ) : (
    <AutocompleteLazyFetchSelectField
      isMultiple={multiple}
      name={fieldId}
      isRequired={required}
      queryKey={ApplicationQueryKeysEnum.APPLICATION_LIST}
      optionLabelParser={getOptionLabel}
      renderOption={renderOption}
      api={{
        FETCH: (searchText: string, params: { page: number; size: number }) => {
          const requestParams = {
            ...params,
            numberContains: searchText,
            folderIdIn: [...(transferredFoldersIds || [])],
            statusKeyIn: statusKeyIn || [ISSUED, ACCOMPLISHED_WITHOUT_PRINTING, RELEASED_WAITING_FOR_ZPO],
            categoryKeyIn: getSPOApplicationCategoryKeys(),
            typeKeyIn,
            size: NUMBER_OF_AUTOCOMPLETE_RESULTS
          };
          if (isClientPortal) {
            return API.client.application.getApplicationsSnapshotPageForFolder('', '', requestParams);
          }
          return API.application.getApplicationsSnapshotPage(requestParams);
        }
      }}
      label={label}
    />
  );
}
export default SPOApplicationsSelectField;
