import { DocumentsApiUpdateDocumentRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface UpdateProceedingDocument extends DocumentsApiUpdateDocumentRequest {
  parentId: string;
}

function editProceedingDocument({ parentId, documentId, documentUpdateRequest }: UpdateProceedingDocument) {
  return API.proceeding.updateProceedingDocument(parentId, documentId, documentUpdateRequest, {
    ...endpointsConfig.editProceeding
  });
}

export function useEditProceedingDocumentMutation() {
  return useMutation(editProceedingDocument);
}
