import { MutationConfig } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface OperationParameters {
  proceedingId: string;
  formOperationId: string;
}

const deleteFormOperation = ({ formOperationId, proceedingId }: OperationParameters) => {
  return API.proceeding.deleteProceedingForm(proceedingId, formOperationId, { ...endpointsConfig.deleteProceeding });
};

export function useFormOperationsDeleteMutation(
  config?: MutationConfig<AxiosResponse<void>, AxiosError, OperationParameters>
) {
  return useMutation(deleteFormOperation, config);
}
