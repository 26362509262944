import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { object as YupObject, string as YupString } from 'yup';

import {
  DatepickerField,
  Dialog,
  FormV2Context,
  GridItem,
  GridLayout,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';
import { getCalendarDate } from '@libs/common/v2/utils';

import { useUpdatePermissionCopyMutation } from '../api';

export interface IUpdatePermissionCopyRequest {
  id?: string;
  version?: number;
  formNumber?: string;
  printDate?: string;
  validTo?: string;
  validFrom?: string;
}

interface IProps {
  readonly closeDialog: () => void;
  readonly initialData: IUpdatePermissionCopyRequest;
  readonly refetch: () => void;
  readonly titleKey: string;
}

export function PermissionCopyEditDialog({ closeDialog, initialData, refetch, titleKey }: IProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: updatePermissionCopy, isLoading } = useUpdatePermissionCopyMutation();

  const formNumberFieldName = typedNameV2<IUpdatePermissionCopyRequest>('formNumber');
  const validFromFieldName = typedNameV2<IUpdatePermissionCopyRequest>('validFrom');
  const validToFieldName = typedNameV2<IUpdatePermissionCopyRequest>('validTo');

  const isFieldVisible = (fieldName: keyof IUpdatePermissionCopyRequest) => {
    switch (titleKey) {
      case 'permissionGitdCopy':
        return fieldName === validFromFieldName;
      default:
        return [formNumberFieldName, validFromFieldName, validToFieldName].includes(fieldName);
    }
  };

  const validationSchema = YupObject({
    ...(isFieldVisible('formNumber') ? { formNumber: YupString().required().nullable() } : {})
  });

  const form = useForm<Partial<IUpdatePermissionCopyRequest>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema)
  });

  const handleSubmit = (data: IUpdatePermissionCopyRequest) => {
    const { version, formNumber, printDate, validFrom, validTo } = data;
    return updatePermissionCopy(
      {
        id: data.id,
        version,
        formNumber,
        printDate: getCalendarDate(printDate),
        validFrom: getCalendarDate(validFrom),
        validTo: getCalendarDate(validTo)
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('permission:message.permissionCopyUpdateSuccess'));
          refetch();
          closeDialog();
        }
      }
    );
  };

  useEffect(() => {
    form.reset(initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      title={t('permission:title.permissionCopies.permissionGitdCopy')}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={form.handleSubmit(handleSubmit)}
      onCancel={closeDialog}
      dialogSize="small"
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <GridLayout>
            {isFieldVisible('formNumber') && (
              <GridItem xs={12}>
                <TextInputField
                  name={typedNameV2<IUpdatePermissionCopyRequest>('formNumber')}
                  label={t('permission:field.formNumber')}
                  isRequired
                />
              </GridItem>
            )}
            {isFieldVisible('validFrom') && (
              <GridItem xs={12}>
                <DatepickerField
                  name={typedNameV2<IUpdatePermissionCopyRequest>('validFrom')}
                  label={t('permission:field.validFrom')}
                />
              </GridItem>
            )}
            {isFieldVisible('validTo') && (
              <GridItem xs={12}>
                <DatepickerField
                  name={typedNameV2<IUpdatePermissionCopyRequest>('validTo')}
                  label={t('permission:field.validTo')}
                />
              </GridItem>
            )}
          </GridLayout>
        </form>
      </FormV2Context.Provider>
    </Dialog>
  );
}
