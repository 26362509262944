import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, OnFilterChange } from 'react-table';
import { makeStyles } from '@mui/styles';

import { Icon, SEARCH_TEXT_DEBOUNCE_TIME, TextInput, useDebounce, useHandleClearFilters } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

interface IProps {
  column: Column & { filterValue?: number; setFilter: (value: number | string) => void };
  min?: number;
  placeholder?: string;
  max?: number;
  step?: number;
  isHalfWidth?: boolean;
  isClearable?: boolean;
  onFilterChange: OnFilterChange;
  onChange?: (value: number | string) => void;
}

function NumberFilter({
  column: { setFilter, filterValue },
  placeholder,
  min,
  isHalfWidth = false,
  isClearable,
  max,
  step,
  onFilterChange,
  onChange
}: IProps) {
  const [t] = useTranslation();
  const [value, setValue] = useState<string | number>(filterValue ?? '');
  const onChangeDebounced = useDebounce(v => {
    setFilter(v);
    onFilterChange?.();
  }, SEARCH_TEXT_DEBOUNCE_TIME);
  const classes = useStyles({ isHalfWidth });

  const handleClearInput = useCallback(() => {
    setValue('');
  }, []);

  useHandleClearFilters(handleClearInput);

  const parseValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (isClearable && value === '') {
      return '';
    }

    let result = +value;
    if (result > max) result = max;
    if (result < min) result = min;
    return result;
  };

  const handleChange = e => {
    const newValue = parseValue(e);
    setValue(newValue);
    onChange?.(newValue);
    if (min === 0 && newValue === 0) {
      // fix dla wartości 0 w przypadku kiedy min ustawione jest na 0, tabela nie łapie 0 jako truthy wartości
      onChangeDebounced(newValue.toString());
    } else {
      onChangeDebounced(newValue === '' ? undefined : newValue);
    }
  };

  return (
    <TextInput
      placeholder={placeholder || t('other:component.table.textFilterCaption')}
      value={value}
      className={classes.root}
      InputProps={{
        endAdornment: !value && <Icon icon="MagnifierIcon" iconWrapper="flex" width={12} height={12} />,
        inputProps: {
          ...(step && { step }),
          ...(min && { min }),
          ...(max && { max })
        }
      }}
      type="number"
      onChange={handleChange}
    />
  );
}

const useStyles = makeStyles<Theme, { isHalfWidth?: boolean }>(theme => ({
  root: {
    width: ({ isHalfWidth }) => isHalfWidth && '50%',
    '& > div > div ': {
      padding: important(0),
      marginTop: important(0),
      '& > input': {
        padding: important('8px 0 8px 8px'),
        fontSize: important(theme.typography.textXs.normal.fontSize),
        fontWeight: important(theme.typography.textXs.normal.fontWeight),
        lineHeight: important(theme.typography.textXs.normal.lineHeight)
      }
    }
  }
}));

export default NumberFilter;
