import { useQuery } from 'react-query';
import { ResourceObjectAvailableRequest } from '@ibtm/client-domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';
import { ResourceQueryKeysEnum } from '..';

const getAvailableResourceObjectToOrder = async (requestData: ResourceObjectAvailableRequest) => {
  const { data } = await API.client.resourceObjects.availableResourceObjectToOrder('', requestData, {
    ...endpointsConfig.availableResourceObjectToOrder
  });

  return data;
};

export default function useAvailableResourcesToOrderQuery(requestData: ResourceObjectAvailableRequest, queryConfig) {
  return useQuery(
    [ResourceQueryKeysEnum.AVAILABLE_RESOURCES_TO_ORDER, requestData],
    () => getAvailableResourceObjectToOrder(requestData),
    queryConfig
  );
}
