import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { FolderSnapshot } from '@ibtm/domain';
import { FolderExtendedBorrowerLite } from '@ibtm/domain/dist/models/folder-extended-borrower-lite';
import * as Yup from 'yup';

import { Button, FormV2Context, GridLayout, typedNameV2, useRouter, Value, WarningMessage } from '@libs/common/v2';

import { useApplicationCountQuery } from '@libs/domain/application/api';
import { DomainDictionaryEntry, DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import { FolderBlockade } from '@libs/domain/folder';

import { ApplicationCreateFormValues } from '../../model';

import ApplicationCategorySelectField from './ApplicationCategorySelectField';
import ApplicationTypeSelectField from './ApplicationTypeSelectField';
import FolderSelectField from './FolderSelectField';

type Props = {
  selectedFolder?: FolderExtendedBorrowerLite | FolderSnapshot;
  categoryKey?: string;
  onSubmit: (data) => void;
  showAddFolderButton?: boolean;
  isFilterOnlyByFolderNumber?: boolean;
  folderLabel?: string;
};

const CreateApplicationForm = forwardRef(
  (
    {
      selectedFolder,
      categoryKey = null,
      onSubmit,
      isFilterOnlyByFolderNumber = false,
      showAddFolderButton = true,
      folderLabel
    }: Props,
    ref
  ) => {
    const [t] = useTranslation();
    const { isOperatorPortal } = useDomainConfigContext();
    const { routes } = useRouter();
    const { showSnackbar } = useSnackbar();

    const {
      control,
      register,
      handleSubmit,
      formState: { errors, isSubmitting },
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      clearErrors
    } = useForm({
      mode: 'onBlur',
      criteriaMode: 'all',
      resolver: yupResolver(
        Yup.object<ApplicationCreateFormValues>({
          folder: Yup.object<ApplicationCreateFormValues['folder']>().nullable().required(),
          applicationDefinition: Yup.object<ApplicationCreateFormValues['applicationDefinition']>()
            .nullable()
            .required(),
          categoryKey: Yup.object<ApplicationCreateFormValues['categoryKey']>().nullable().required()
        })
      ),
      defaultValues: {
        ...(selectedFolder && { folder: { id: selectedFolder.id, value: selectedFolder } }),
        categoryKey: categoryKey
          ? {
              value: categoryKey
            }
          : null,
        applicationDefinitionId: null
      } as any
    });

    const folderFieldWatchValue: ApplicationCreateFormValues['folder'] = watch(
      typedNameV2<ApplicationCreateFormValues>('folder'),
      null
    );

    const applicationDefinitionFieldWatchValue: ApplicationCreateFormValues['applicationDefinition'] = watch(
      typedNameV2<ApplicationCreateFormValues>('applicationDefinition'),
      null
    );

    const processingApplicationStatuses = Object.values(DomainDictionaryEntry.APPLICATION_STATUS).filter(
      v =>
        ![
          DomainDictionaryEntry.APPLICATION_STATUS.ACCOMPLISHED,
          DomainDictionaryEntry.APPLICATION_STATUS.WITHDRAWN,
          DomainDictionaryEntry.APPLICATION_STATUS.CANCELLED,
          DomainDictionaryEntry.APPLICATION_STATUS.ISSUED
        ].includes(v)
    );

    const { data: sameApplicationsCount } = useApplicationCountQuery(
      {
        folderIdIn: [folderFieldWatchValue?.id],
        typeKeyIn: [applicationDefinitionFieldWatchValue?.value.typeKey],
        statusKeyIn: processingApplicationStatuses
      },
      {
        enabled: Boolean(applicationDefinitionFieldWatchValue && folderFieldWatchValue)
      }
    );

    useImperativeHandle(ref, () => ({
      submit() {
        handleSubmit(onSubmit)();
      }
    }));

    const values = useMemo(
      () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting, clearErrors }),
      [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch, clearErrors]
    );

    const currentSelectedFolder = useMemo(
      () => selectedFolder || folderFieldWatchValue?.value,
      [selectedFolder, folderFieldWatchValue?.value]
    );

    useEffect(() => {
      if (isOperatorPortal && currentSelectedFolder) {
        if ('borrowedBy' in currentSelectedFolder && currentSelectedFolder?.borrowedBy) {
          showSnackbar(
            'info',
            t('folder:details.message.folderIsBorrowedBy', {
              name: currentSelectedFolder?.borrowedBy?.firstName,
              surname: currentSelectedFolder?.borrowedBy?.surname,
              number: currentSelectedFolder?.number
            })
          );
        } else if (isOperatorPortal) {
          showSnackbar('info', t('folder:details.message.folderIsNotBorrowed'));
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOperatorPortal, currentSelectedFolder]);

    return (
      <FormV2Context.Provider value={values}>
        <form>
          <GridLayout itemProps={{ xs: 12 }}>
            {selectedFolder ? (
              <Value label={folderLabel ?? t('applications:field.folderNumber')}>{selectedFolder?.number}</Value>
            ) : (
              <FolderSelectField
                fieldName={typedNameV2<ApplicationCreateFormValues>('folder')}
                isFilterOnlyByFolderNumber={isFilterOnlyByFolderNumber}
                label={folderLabel ?? t('applications:field.folderNumber')}
                isRequired
              />
            )}
            {showAddFolderButton ? (
              <div className="flex justify-end mt-12">
                <Button
                  link={routes.folderCreate()}
                  label={t('applications:action.addFolder')}
                  variant="outlined"
                  size="large"
                  actionKey={DomainUIElementEnum.FOLDER_ADD_BUTTON}
                  isNoMargin
                />
              </div>
            ) : null}
            {isOperatorPortal && currentSelectedFolder?.locked && <FolderBlockade id={currentSelectedFolder?.id} />}

            <ApplicationCategorySelectField
              folderData={currentSelectedFolder}
              onChange={() => {
                setValue(typedNameV2<ApplicationCreateFormValues>('applicationDefinition'), null);
              }}
            />
            <ApplicationTypeSelectField
              label={t('applications:field.typeKey')}
              folderTypeKey={currentSelectedFolder?.typeKey}
              disabledTooltip={t('applications:create.categoryIsRequiredForCategoryField')}
              isRequired
            />
            {Boolean(sameApplicationsCount) && (
              <WarningMessage mode="WARNING" text={t('applications:message.sameTypeApplicationsWarning')} />
            )}
          </GridLayout>
        </form>
      </FormV2Context.Provider>
    );
  }
);

export default CreateApplicationForm;
