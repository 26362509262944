import { usePaginatedQuery } from 'react-query';
import { TransitScheduleStopSearchFilter as TransitScheduleStopSearchFilterClient } from '@ibtm/client-domain';
import { TransitScheduleStopSearchFilter } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { ApplicationQueryKeysEnum } from '@libs/domain/application';
import { parseTransitScheduleStopsData } from '@libs/domain/transit-schedule';

import { endpointsConfig } from '../endpoints-config';

export type TGetTransitScheduleStops =
  | (TransitScheduleStopSearchFilter & { transitScheduleId: string })
  | (TransitScheduleStopSearchFilterClient & { transitScheduleId: string });

export const getTransitScheduleStops = async (_, params: TGetTransitScheduleStops) => {
  const { transitScheduleId, ...restParams } = params;
  const { data } = await API.transitSchedule.getTransitScheduleStopPage(transitScheduleId, restParams, {
    ...endpointsConfig.getTransitScheduleStopPage
  });
  return parseTransitScheduleStopsData(data);
};

export const getTransitScheduleStopsClient = async (_, params: TGetTransitScheduleStops) => {
  const { transitScheduleId, ...restParams } = params;
  const { data } = await API.client.transitSchedule.getTransitScheduleStopPage(transitScheduleId, '', '', restParams, {
    ...endpointsConfig.getTransitScheduleStopPage
  });

  return parseTransitScheduleStopsData(data);
};

export default function useTransitScheduleStopsQuery(params: TGetTransitScheduleStops, queryConfig = {}) {
  const { getQuery } = useGetApiQueryByPerspective();
  return usePaginatedQuery(
    [ApplicationQueryKeysEnum.TRANSIT_SCHEDULE_DETAILS_STOPS, params],
    getQuery(getTransitScheduleStopsClient, getTransitScheduleStops),
    queryConfig
  );
}
