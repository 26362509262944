import { partialTranslate } from '@libs/common';
import { DropdownListButton } from '@libs/common/v2';

import { useClearVehicles } from '@libs/domain/vehicle';

interface IProps {
  selected: string[];
  applicationId: string;
  handleClose: () => void;
}

const translate = partialTranslate('vehicle:cleanFleetVehicle');

function VehicleClearSelectedActionButton({ selected, applicationId, handleClose }: IProps) {
  const { clearSelectedVehicles } = useClearVehicles(applicationId);

  return selected.length > 0 ? (
    <DropdownListButton
      onClick={() => {
        clearSelectedVehicles(selected);
        handleClose();
      }}
      label={translate(selected.length === 1 ? 'cleanSelectedButton' : 'cleanSelectedButtonPlural')}
    />
  ) : null;
}
export default VehicleClearSelectedActionButton;
