import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { DriversApiDeleteDriverRequest } from '@ibtm/domain';

import { TableButton, useConfirmDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { UIElementNameEnum } from '@libs/permission';

import { ApplicationQueryKeysEnum } from '@libs/domain/application';
import {
  DriversQueryKeysEnum,
  useDeleteDriverFromApplicationMutation,
  useDeleteDriverMutation
} from '@libs/domain/drivers/api';

interface IProps {
  driverId: string;
  name: string;
  surname: string;
  actionKey: UIElementNameEnum;
  applicationId?: string;
}

function DeleteDriverButton({ driverId, name, surname, actionKey, applicationId }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();

  const { showSnackbar } = useSnackbar();
  const [confirm] = useConfirmDialog();
  const { mutate: deleteDriver, isLoading } = useDeleteDriverMutation();
  const { mutate: deleteDriverFromApplication, isLoading: isLoadingDeleteFromApplication } =
    useDeleteDriverFromApplicationMutation();

  const handleDeleteSuccess = () => {
    queryCache.invalidateQueries(DriversQueryKeysEnum.DRIVERS_LIST);
    queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
    showSnackbar('success', t('drivers:messages.deleteDriverSuccess'));
  };

  interface DeleteDriverRequest extends DriversApiDeleteDriverRequest {
    applicationId?: string;
  }

  const handleDelete = () => {
    confirm({
      title: t('drivers:dialogs.deleteTitle'),
      message: t('drivers:dialogs.deleteMessage', { name, surname }),
      onConfirm: (setConfirmLoading, closeDialog) => {
        const requestData: DeleteDriverRequest = {
          driverId,
          applicationId
        };

        const requestOptions = {
          onSuccess: () => {
            handleDeleteSuccess();
            closeDialog();
          },
          onSettled: () => {
            setConfirmLoading(false);
          }
        };

        setConfirmLoading(true);

        if (applicationId) {
          deleteDriverFromApplication(requestData, requestOptions);
        } else {
          deleteDriver(requestData, requestOptions);
        }
      },
      confirmType: 'danger'
    });
  };

  return (
    <TableButton
      label={t('action.delete')}
      onClick={handleDelete}
      isDisabled={isLoading || isLoadingDeleteFromApplication}
      actionKey={actionKey}
    />
  );
}

export default DeleteDriverButton;
