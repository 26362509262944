import { FolderCreateRequest } from '@ibtm/domain';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { SubjectUpdateRequestUnion } from '../../application/model';
import { AssociationModel } from '../model';

export const parseCreateAssociationRequest = (data: AssociationModel): FolderCreateRequest => {
  return {
    typeKey: DomainDictionaryEntry.FOLDER_TYPE.ZZ,
    subject: {
      name: data.subject.name,
      shortName: data.subject.shortName,
      nip: data.subject.nip || null,
      regon: data.subject.regon || null,
      krs: data.subject.krs || null,
      countryOfOrigin: DomainDictionaryEntry.COUNTRY_CODE.POLAND,
      legalFormKey: DomainDictionaryEntry.LEGAL_NATURE_KEY.ASSOCIATION,
      subjectTypeKey: DomainDictionaryEntry.SUBJECT_TYPE.ASSOCIATION,
      notes: data.subject.notes,
      addresses: [
        {
          typeKey: DomainDictionaryEntry.ADDRESS_TYPE.MAIN,
          sameAsMainAddress: false,
          address: {
            countryCodeKey: DomainDictionaryEntry.COUNTRY_CODE.POLAND,
            postCode: data.addresses[0]?.postCode,
            voivodeshipKey: data.addresses[0]?.voivodeshipKey.value,
            county: data.addresses[0]?.county,
            commune: data.addresses[0]?.commune,
            postCity: data.addresses[0]?.postCity,
            city: data.addresses[0]?.city,
            street: data.addresses[0]?.street,
            propertyNumber: data.addresses[0]?.propertyNumber,
            apartmentNumber: data.addresses[0]?.apartmentNumber
          }
        },
        { sameAsMainAddress: true, typeKey: DomainDictionaryEntry.ADDRESS_TYPE.HQ },
        { sameAsMainAddress: true, typeKey: DomainDictionaryEntry.ADDRESS_TYPE.CORRESPONDENCE }
      ]
    }
  };
};

export const parseUpdateAssociationRequest = (data: AssociationModel): SubjectUpdateRequestUnion => {
  const mainAddress = data.addresses?.filter(
    address => address.typeKey === DomainDictionaryEntry.ADDRESS_TYPE.MAIN
  )?.[0];

  return {
    name: data.subject.name,
    shortName: data.subject.shortName,
    nip: data.subject.nip || null,
    regon: data.subject.regon || null,
    krs: data.subject.krs || null,
    countryOfOrigin: DomainDictionaryEntry.COUNTRY_CODE.POLAND,
    legalFormKey: DomainDictionaryEntry.LEGAL_NATURE_KEY.ASSOCIATION,
    subjectTypeKey: DomainDictionaryEntry.SUBJECT_TYPE.ASSOCIATION,
    notes: data.subject.notes,
    version: data.subject.version,
    ...(mainAddress && {
      mainAddress: {
        version: mainAddress.version,
        countryCodeKey: mainAddress.countryCodeKey,
        postCode: mainAddress.postCode,
        voivodeshipKey: mainAddress.voivodeshipKey.value,
        county: mainAddress.county,
        commune: mainAddress.commune,
        postCity: mainAddress.postCity,
        city: mainAddress.city,
        street: mainAddress.street,
        propertyNumber: mainAddress.propertyNumber,
        apartmentNumber: mainAddress.apartmentNumber,
        firstLine: mainAddress.firstLine,
        name: mainAddress.name,
        secondLine: mainAddress.secondLine
      }
    })
  };
};
