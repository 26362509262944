import { MoneyTransferCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export function createMoneyTransfer(params: MoneyTransferCreateRequest) {
  return API.moneyTransfer.createMoneyTransfer(params, {
    ...endpointsConfig.createMoneyTransfer
  });
}

function useCreateMoneyTransferMutation() {
  return useMutation(createMoneyTransfer);
}

export default useCreateMoneyTransferMutation;
