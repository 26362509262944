import { CalendarDate } from 'calendar-date';
import moment from 'moment';
import { array as YupArray, date as YupDate, number as YupNumber, object as YupObject, string as YupString } from 'yup';

import { i18n, KeyType } from '@libs/common';
import { SelectOption } from '@libs/common/v2';
import { isDateValid } from '@libs/common/v2/form/validation';

export interface CivicBoardSnapshot {
  mode?: string;
  civicBoardId?: string;
  year?: number;
  month?: number;
  id?: string;
  submissionDateFrom?: CalendarDate;
  submissionDateTo?: CalendarDate;
  receiptDateFrom?: CalendarDate;
  receiptDateTo?: CalendarDate;
  boardDate?: CalendarDate;
}

export type UpdateCivicBoardPayload = {
  year: number;
  month: number;
  submissionDateFrom: CalendarDate;
  submissionDateTo: CalendarDate;
  receiptDateFrom: CalendarDate;
  receiptDateTo: CalendarDate;
  boardDate: CalendarDate;
  civicBoardId: string;
  version: number;
};

export const AddMeetingValidationSchema = () =>
  YupObject({
    year: YupObject().required().nullable(),
    month: YupObject().required().nullable(),
    submissionDateFrom: YupDate().required(),
    submissionDateTo: YupDate()
      .when('submissionDateFrom', (submissionDateFrom, schema) => {
        return (
          submissionDateFrom &&
          schema.min(
            moment(submissionDateFrom).isValid() ? submissionDateFrom : moment(),
            i18n.t('foreignPermits:validation.submissionDateTo')
          )
        );
      })
      .required(),
    receiptDateFrom: YupDate().required(),
    receiptDateTo: YupDate()
      .when(
        'receiptDateFrom',
        (receiptDateFrom, schema) =>
          receiptDateFrom &&
          schema.min(
            moment(receiptDateFrom).isValid() ? receiptDateFrom : moment(),
            i18n.t('foreignPermits:validation.receiptDateTo')
          )
      )
      .required(),
    boardDate: YupDate().required()
  });

export const ApplicationFormSchema = () =>
  YupObject({
    folder: YupObject({ number: YupString().required() }),
    year: YupString().nullable().required()
  });

export const getTradeRulesSchema = (isEditMode: boolean) =>
  YupObject({
    validFrom: YupString()
      .nullable()
      .required()
      .concat(isDateValid())
      .test({
        name: 'validFrom',
        message: i18n.t('foreignPermits:validation.effectiveDateFrom' as KeyType),
        test(validFrom) {
          const validTo = this.parent?.['validTo'] as Date;
          return !validFrom || !validTo || moment(validFrom).isBefore(validTo);
        }
      }),
    validTo: YupString()
      .nullable()
      .required()
      .concat(isDateValid())
      .test({
        name: 'validTo',
        message: i18n.t('foreignPermits:validation.effectiveDateFrom' as KeyType),
        test(validTo) {
          const validFrom = this.parent?.['validFrom'] as Date;
          return !validFrom || !validTo || moment(validFrom).isBefore(validTo);
        }
      }),
    ...(isEditMode ? {} : { mainResourceTypeId: YupObject().nullable().required() }),
    items: YupArray().of(
      YupObject({
        maxSettlementReturnDate: YupString().nullable().required().concat(isDateValid()),
        limitFactor: YupNumber().min(0.01).nullable().required(),
        multiplier: YupNumber().nullable().required()
      })
    )
  });

export const RowDisposablePermitsSchema = () =>
  YupObject({
    year: YupNumber()
      .required()
      .min(new Date().getFullYear() - 1)
      .max(new Date().getFullYear() + 1),
    month: YupNumber().required().min(1).max(12),
    folder: YupObject({
      number: YupString().nullable().required()
    })
  });

export enum BasicLimitTableEnum {
  YEAR = 'yearEquals'
}

export enum EkmtLimitTableEnum {
  YEAR = 'yearEquals'
}

interface ICivicBoardRuleItems {
  id: string;
  maxSettlementReturnDate: string;
  limitFactor: number;
  multiplier: number;
}

export interface ICivicBoardRuleUpdateRequest {
  items?: ICivicBoardRuleItems[];
}

export interface ICivicBoardRuleCreateRequest {
  mainResourceTypeId: SelectOption<string>;
  items?: ICivicBoardRuleItems[];
}

export type SocialCommissionDetailsParams = {
  id: string;
};
