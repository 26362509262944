import { useTranslation } from 'react-i18next';

import { Dialog, useDialog } from '@libs/common/v2';

import { ResourceTypesTable } from '../../components/list';

const useDeleteFormTypeDialog = () => {
  const [t] = useTranslation();
  const { openDialog } = useDialog();

  const open = () => {
    openDialog(({ closeDialog }) => {
      return (
        <Dialog
          title={t('resource:dialog.deleteFormTypeTitle')}
          cancelText={t('action.close')}
          onCancel={closeDialog}
          dialogSize="large"
          isOpen
        >
          <ResourceTypesTable initialParams={{ active: false }} />
        </Dialog>
      );
    });
  };

  return {
    openDeleteFormTypeDialog: open
  };
};

export default useDeleteFormTypeDialog;
