import { MutationConfig } from 'react-query';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface IMutation {
  documentId: string;
  version: number;
}

const withdrawSending = async (params: IMutation) => {
  const { documentId, version } = params;
  const { data } = await API.documents.withdrawSendingOfDocument(
    documentId,
    { version },
    {
      ...endpointsConfig.withdrawSendingOfDocument
    }
  );
  return data;
};

export default function useWithdrawSendingMutation(config?: MutationConfig<any, unknown, IMutation, unknown>) {
  return useMutation(withdrawSending, config);
}
