import { QueryConfig, useQuery } from 'react-query';
import { ExploitationBaseDetails } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import { ExploitationBaseQueryKeysEnum } from './ExploitationBaseQueryKeysEnum';

const getExploitationBaseClient = async (_: any, id: string) => {
  const { data } = await API.client.exploitationBase.getExploitationBaseDetails(id, '', '', {
    ...endpointsConfig.getExploitationBaseDetails
  });
  return data;
};

const getExploitationBase = async (_: any, id: string) => {
  const { data } = await API.exploitationBase.getExploitationBaseDetails(id, {
    ...endpointsConfig.getExploitationBaseDetails
  });
  return data;
};

export default function useExploitationBaseDetailsQuery(
  id: string,
  queryConfig: QueryConfig<ExploitationBaseDetails, unknown> = {}
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery<ExploitationBaseDetails>(
    [ExploitationBaseQueryKeysEnum.EXPLOITATION_BASE, id],
    getQuery(getExploitationBaseClient, getExploitationBase),
    queryConfig
  );
}
