import { useTranslation } from 'react-i18next';

import { IconButton } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

import { useCopyFolderVehiclesAction } from '@libs/domain/vehicle';

interface IProps {
  applicationId: string;
  actionKey: UIElementNameEnum;
  transferredFoldersIds?: string[];
}

function CopyFolderVehiclesActionButton({ applicationId, actionKey, transferredFoldersIds }: IProps) {
  const [t] = useTranslation();

  const { handleCopyVehicles } = useCopyFolderVehiclesAction({ applicationId, transferredFoldersIds });
  return (
    <IconButton
      icon="TruckIcon"
      tooltipTitle={t('vehicle:downloadVehicles')}
      onClick={handleCopyVehicles}
      actionKey={actionKey}
      isBackgroundTransparent
    />
  );
}

export default CopyFolderVehiclesActionButton;
