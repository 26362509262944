import { MutableRefObject, useEffect } from 'react';
import { EventType, FieldValues, UseFormWatch } from 'react-hook-form';
import { get } from 'lodash';

import { checkMetaformFieldChanged } from '@libs/meta-form/utils';

interface IProps {
  dirtyFields: MutableRefObject<string[]>;
  formValuesBeforeDirty: MutableRefObject<Record<string, unknown>>;
  setIsDirty: (value: boolean) => void;
  changeDirtyFields: (newDirtyFields: string[]) => void;
}
export type IFieldCheckProps = IProps & {
  value: { [x: string]: any };
  info: {
    name?: string;
    type?: EventType;
  };
  checkMetaformFieldChange: (initialFieldValue: any, currentFieldValue: any) => boolean;
};
type ICustomIsDirtyEffectProps = IProps & { fieldIds: string[]; watch: UseFormWatch<FieldValues> };

export function fieldCheck({
  info,
  value,
  dirtyFields,
  formValuesBeforeDirty,
  setIsDirty,
  changeDirtyFields,
  checkMetaformFieldChange
}: IFieldCheckProps) {
  const fieldName = info?.name;
  const isChange = info?.type === 'change';
  const currentFormValues = value;

  if (fieldName && isChange) {
    const initialFieldValue = get(formValuesBeforeDirty.current, fieldName, null);
    const currentFieldValue = get(currentFormValues, fieldName, null);

    if (checkMetaformFieldChange(initialFieldValue, currentFieldValue)) {
      if (!dirtyFields.current.includes(fieldName)) {
        changeDirtyFields([...dirtyFields.current, fieldName]);
      }
      setIsDirty(!!dirtyFields.current.length);
    } else {
      changeDirtyFields(dirtyFields.current.filter(item => item !== fieldName));
      setIsDirty(!!dirtyFields.current.length);
    }
  }
}

function useCustomIsDirtyEffect({
  fieldIds,
  dirtyFields,
  formValuesBeforeDirty,
  watch,
  setIsDirty,
  changeDirtyFields
}: ICustomIsDirtyEffectProps) {
  useEffect(() => {
    const subscription = watch((value, info) => {
      fieldCheck({
        info,
        value,
        dirtyFields,
        formValuesBeforeDirty,
        setIsDirty,
        changeDirtyFields,
        checkMetaformFieldChange: checkMetaformFieldChanged
      });
    });

    return () => subscription.unsubscribe();
  }, [watch, setIsDirty, fieldIds, formValuesBeforeDirty, dirtyFields, changeDirtyFields]);
}

export default useCustomIsDirtyEffect;
