import {
  DataSourceMediatorDetailsSnapshot,
  QueryColumnProperties,
  QueryCondition,
  QueryConditionFilter,
  QueryConditionParameter,
  QueryParameter,
  ReportDataSetDefinitionDetailsSnapshot
} from '@stack/report';

import { i18n } from '@libs/common';

import { ConditionOperator } from '@libs/report-core';

import { DataFileFormatEnum } from './form.model';

export interface ExtendedQueryConditionParameter extends QueryConditionParameter {
  isHintAvailable?: boolean;
  isSqlQueryAvailable?: boolean;
  hintSource?: string;
  sqlQuery?: string;
  columnList?: [string];
}

export interface ReportTypeImportFile {
  file: {
    fileId: string;
    name: string;
    type: string;
  };
}

export enum ReportTypeDetailsTabsEnum {
  DEFINITION = 'DEFINITION',
  VIEW = 'VIEW'
}

export type CommonFieldsType = {
  name: string;
  type: string;
};

export const EMPTY_DATASET_DEFINITION: Partial<ReportDataSetDefinitionDetailsSnapshot> = {
  id: '',
  dataDefinition: {
    properties: {
      fontSize: 14
    }
  },
  query: '',
  queryDefinition: {
    columns: [] as QueryColumnProperties[],
    groups: []
  },
  queryConditions: [
    {
      filter: null as QueryConditionFilter | null,
      group: [] as QueryCondition[],
      operator: ConditionOperator.AND
    }
  ],
  queryParameters: [] as (QueryParameter & { id?: string })[],
  dynamicParameterNames: [],
  source: null as DataSourceMediatorDetailsSnapshot,
  dataFileFormatKey: DataFileFormatEnum.TABLE,
  name: i18n.t('reports:reportTypes.newDataSet', { number: 1 }),
  description: '',
  version: 0
};

export const BOOLEAN_STRING_VALUES = ['true', 'false'];
