import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { DropdownListButton, useTableContext } from '@libs/common/v2';

import { DomainUIElementEnum } from '@libs/domain/config';

import { useDeleteTransitStopsMutation, useTransitScheduleStopShiftMutation } from '../api';
import { StopShiftDirection } from '../model';
import { TransitScheduleContext } from '../pages';

function SelectActionTableColumn(selectedRowIds, handleClose: () => void) {
  const [t] = useTranslation();
  const { transitScheduleId } = useContext(TransitScheduleContext);
  const { refetch } = useTableContext();
  const { mutate: changeStopOrder } = useTransitScheduleStopShiftMutation();
  const { mutate: deleteTransitStops } = useDeleteTransitStopsMutation();
  const { showSuccessSnackbar } = useSnackbar();

  const handleOnClick = ({ type, close }: { type: StopShiftDirection; close: () => void }) => {
    return changeStopOrder(
      { transitScheduleId, stops: selectedRowIds, direction: type },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('success.save'));
          close();
          refetch();
        }
      }
    );
  };

  const handleDelete = close => {
    return deleteTransitStops(
      { transitScheduleId, stopIds: selectedRowIds },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('success.delete'));
          refetch();
          close();
        }
      }
    );
  };

  return (
    <>
      <DropdownListButton
        label={t('applications:transitSchedule.actions.moveUp')}
        onClick={() => handleOnClick({ type: StopShiftDirection.UP, close: handleClose })}
        actionKey={DomainUIElementEnum.APPLICATION_SCHEDULE_MOVE_TO_UP_BUTTON}
      />
      <DropdownListButton
        label={t('applications:transitSchedule.actions.moveDown')}
        onClick={() => handleOnClick({ type: StopShiftDirection.DOWN, close: handleClose })}
        actionKey={DomainUIElementEnum.APPLICATION_SCHEDULE_MOVE_DOWN_BUTTON}
      />
      <DropdownListButton
        label={t('other:component.table.deleteRecord')}
        onClick={() => handleDelete(handleClose)}
        actionKey={DomainUIElementEnum.APPLICATION_SCHEDULE_DELETE_SCHEDULE_BUTTON}
      />
    </>
  );
}

export default SelectActionTableColumn;
