import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as Yup from 'yup';

import { FormMode, FormV2Context, useViewModesV2 } from '@libs/common/v2';

import NewReportTypeDataSectionDialog from './NewReportTypeDataSectionDialog';

interface IProps {
  tab?: number;
  data: Array<any>;
  formMode: FormMode;
  closeDialog: () => void;
  onSubmit: (formValues, index) => void;
}

const initialValues = {
  name: '',
  source: null,
  dataFileFormatKey: ''
};

function NewReportTypeDataSectionForm({ tab, data, formMode, closeDialog, onSubmit }: IProps) {
  const [t] = useTranslation();
  const { editMode } = useViewModesV2(formMode);

  const form = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: initialValues,
    resolver: yupResolver(
      Yup.object({
        name: Yup.string()
          .required()
          .test('nameMustBeDistinct', t('reports:message.nameMustBeDistict'), value => {
            const dataSetDefinitions = [...data];
            if (editMode) {
              dataSetDefinitions.splice(tab, 1);
            }
            return _.isEmpty(dataSetDefinitions.filter(({ name }) => name === value));
          }),
        source: Yup.object().nullable().required()
      })
    )
  });

  const {
    reset,
    formState: { isDirty, isSubmitting }
  } = form;

  const values = useMemo(
    () => ({
      ...form,
      formMode,
      isDirty,
      isSubmitting
    }),
    [form, formMode, isDirty, isSubmitting]
  );

  const dataIndex = useMemo(() => (editMode ? tab : data.length), [data.length, editMode, tab]);

  useEffect(() => {
    if (editMode && data) {
      const { name, source, dataFileFormatKey } = data[tab];
      reset({ name, source, dataFileFormatKey });
    } else {
      reset(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, editMode]);

  return (
    <FormV2Context.Provider value={values}>
      <NewReportTypeDataSectionDialog
        index={dataIndex}
        closeDialog={closeDialog}
        onSubmit={onSubmit}
        isEditMode={editMode}
      />
      ;
    </FormV2Context.Provider>
  );
}

export default NewReportTypeDataSectionForm;
