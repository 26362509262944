import { ForeignPermissionSettlementCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function settlePermit(foreignPermissionSettlementCreateRequest: ForeignPermissionSettlementCreateRequest) {
  return API.foreignPermissions.settleForeignPermission(foreignPermissionSettlementCreateRequest, {
    ...endpointsConfig.settleForeignPermission
  });
}

function useSettleForeignPermitMutation() {
  return useMutation(settlePermit);
}

export default useSettleForeignPermitMutation;
