import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { ApplicationSnapshot } from '@ibtm/domain';

import { TableButtonDelete, useConfirmDialog } from '@libs/common/v2';
import { useFormV2Context, useViewModesV2 } from '@libs/common/v2/form';

import { ApplicationsTable, useApplicationDetailsQuery, useApplicationTableColumns } from '@libs/domain/application';

import { useRemoveApplicationMisspellApplicationMutation } from '../api';
import { getApplicationCategoryType } from '../utils/application-misspell.util';

import MisspellApplicationsSearchButton from './MisspellApplicationsSearchButton';

export interface IMisspellApplicationsTableProps {
  applicationId: string;
  maxAddedApplicationsCount?: number;
  searchDialogColumns?: Array<keyof ReturnType<typeof useApplicationTableColumns>>;
  title?: string;
}

function MisspellApplicationsTable({
  applicationId,
  maxAddedApplicationsCount,
  searchDialogColumns = ['number', 'typeKey'],
  title
}: IMisspellApplicationsTableProps) {
  const { getValues } = useFormV2Context();
  const columns = useApplicationTableColumns();
  const visibleColumns = searchDialogColumns.map(name => columns[name]);
  const [t] = useTranslation();
  const [confirm] = useConfirmDialog();

  const { data, refetch } = useApplicationDetailsQuery(applicationId, {
    refetchOnMount: false
  });
  const ref = useRef(data?.version);
  ref.current = data?.version;

  const categoryKey = useMemo(() => {
    return getApplicationCategoryType(data?.typeKey);
  }, [data?.typeKey]);

  const { mutate: removeApplicationForCorrection } = useRemoveApplicationMisspellApplicationMutation({
    getValues,
    categoryKey
  });

  const { viewMode } = useViewModesV2();
  const tableData = (data?.misspellApplicationDataDetails?.applications as ApplicationSnapshot[]) ?? [];
  const rowAction = useCallback(
    (row: Row<ApplicationSnapshot>) =>
      !viewMode ? (
        <TableButtonDelete
          onClick={() =>
            confirm({
              title: t('applications:misspellApplication.dialog.deleteMisspelledApplication'),
              shortTitle: t('global:dialog.deleteConfirmTitle'),
              message: t('applications:misspellApplication.dialog.deleteMisspelledApplicationConfirm'),
              confirmType: 'danger',
              onConfirm: (setConfirmLoading, closeDialog) => {
                setConfirmLoading(true);
                removeApplicationForCorrection(
                  {
                    applicationId,
                    removeRequest: { applicationVersion: ref.current, misspellApplicationId: row.original.id }
                  },
                  {
                    onSuccess: () => closeDialog(),
                    onSettled: () => {
                      refetch();
                      setConfirmLoading(false);
                    }
                  }
                );
              }
            })
          }
        />
      ) : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.version, removeApplicationForCorrection, applicationId]
  );

  const isAddButtonVisible = (!maxAddedApplicationsCount || maxAddedApplicationsCount > tableData.length) && !viewMode;

  return (
    <ApplicationsTable
      tableData={(data?.misspellApplicationDataDetails?.applications as ApplicationSnapshot[]) ?? []}
      visibleColumns={[columns.number, columns.typeKey]}
      title={title || t('applications:misspellApplication.table.applicationsForCorrection')}
      isFilterEnabled={false}
      headerActions={
        isAddButtonVisible && (
          <MisspellApplicationsSearchButton
            getValues={getValues}
            applicationId={applicationId}
            visibleColumns={visibleColumns}
          />
        )
      }
      tableOptions={{
        manualPagination: false,
        manualSortBy: false
      }}
      isXlsxDownloadVisible={false}
      isAdditionalFiltersVisible={false}
      isRefreshEnabled={false}
      rowActions={rowAction}
      isSection
      isCollapsable
    />
  );
}

export default MisspellApplicationsTable;
