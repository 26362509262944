import { ApplicationMetaFormDefinition } from '@libs/domain/application/model';

const definition: ApplicationMetaFormDefinition = {
  fields: [],
  layout: [
    {
      typeKey: 'TAB_NAVIGATION',
      properties: {
        visibility: {
          elementVisibilityKeys: ['TAB_VISIBILITY']
        },
        defaultTabId: 'TRANSIT_SCHEDULES',
        title: 'Sekcje',
        tabs: [
          {
            id: 'TRANSIT_SCHEDULES',
            title: 'Rozkład jazdy',
            icon: 'CalendarIcon'
          }
        ]
      },
      nodes: [
        {
          typeKey: 'TABS_CONTENT',
          nodes: [
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-transit-schedule-tab'
              }
            }
          ]
        }
      ]
    }
  ]
};

export default definition;
