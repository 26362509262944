import { useContext } from 'react';

import { TableContext, TableInstanceContext } from '@libs/common/v2';

export const useTableAdapter = () => {
  const instance = useContext(TableInstanceContext);
  return instance;
};

export const useTableContext = () => {
  const context = useContext(TableContext);
  return context;
};
