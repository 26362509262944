import { useTranslation } from 'react-i18next';

import { partialTranslate } from '@libs/common';
import { getNumberValue, getValue } from '@libs/common/v2/utils';

import { DictionaryValue, useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { IAssociationOrderRanges } from '../../model';

const useAssociationOrderRangesColumns = () => {
  const [t] = useTranslation();
  const getLabel = partialTranslate('resource:fields');
  const { translate } = useDictionaryTranslations();

  return [
    {
      id: 'country',
      Header: getLabel('country'),
      accessor: (row: IAssociationOrderRanges) => (
        <DictionaryValue dictionaryEntryType={DomainDictionaryEnum.COUNTRY_CODE} value={row?.country} />
      ),
      xlsxFormatter: (row: IAssociationOrderRanges) =>
        translate(DomainDictionaryEnum.COUNTRY_CODE, row?.country, t('emptyMark')),
      disableSortBy: true,
      width: 200
    },
    {
      id: 'name',
      Header: getLabel('name'),
      accessor: (row: IAssociationOrderRanges) => (
        <DictionaryValue dictionaryEntryType={DomainDictionaryEnum.RESOURCE_FORM_NAME} value={row?.name} />
      ),
      xlsxFormatter: (row: IAssociationOrderRanges) =>
        translate(DomainDictionaryEnum.RESOURCE_FORM_NAME, row?.name, t('emptyMark')),
      disableSortBy: true,
      width: 300
    },
    {
      id: 'numberFrom',
      accessor: (row: IAssociationOrderRanges) => getValue(row?.numberFrom),
      Header: getLabel('numberFrom'),
      disableSortBy: true,
      width: 150
    },
    {
      id: 'numberTo',
      accessor: (row: IAssociationOrderRanges) => getValue(row?.numberTo),
      Header: getLabel('numberTo'),
      disableSortBy: true,
      width: 150
    },
    {
      id: 'amount',
      Header: getLabel('amount'),
      accessor: (row: IAssociationOrderRanges) => getNumberValue(row?.amount),
      disableSortBy: true,
      width: 150
    }
  ];
};

export default useAssociationOrderRangesColumns;
