import { useMemo } from 'react';

import { Chip } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';
import { StatusColorTypes, USER_STATUS_COLOR_CONFIG, UserDictionaryEntryNameEnum } from '@libs/user';

interface IProps {
  status: string;
}

function UserStatusChip({ status }: IProps) {
  const { translate } = useDictionaryTranslations();

  const userColorDefinitions = (colorType: StatusColorTypes) => {
    switch (colorType) {
      case StatusColorTypes.RED:
        return 'error';
      case StatusColorTypes.YELLOW:
        return 'warning';
      case StatusColorTypes.GREY:
        return 'grey';
      case StatusColorTypes.GREEN:
        return 'success';
      default:
        return 'success';
    }
  };

  const statusDefinitionItem = useMemo(
    () => USER_STATUS_COLOR_CONFIG.find(definition => definition.status === status),
    [status]
  );

  const color = useMemo(() => userColorDefinitions(statusDefinitionItem?.color), [statusDefinitionItem?.color]);
  const colorWeight = useMemo(() => {
    if (statusDefinitionItem?.color === StatusColorTypes.GREEN) {
      return '700';
    }

    return '800';
  }, [statusDefinitionItem]);
  const backgroundColorWeight = useMemo(() => {
    if (statusDefinitionItem?.color === StatusColorTypes.GREY) {
      return '100';
    }

    return '50';
  }, [statusDefinitionItem]);

  return (
    <Chip
      title={translate(UserDictionaryEntryNameEnum.USER_STATUS, status)}
      color={color}
      colorWeight={colorWeight}
      backgroundColorWeight={backgroundColorWeight}
    />
  );
}

export default UserStatusChip;
