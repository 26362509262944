import { AccessTokenDetailsExternal } from '@avispon/auth/dist/models';
import moment from 'moment';

export enum AuthPayloadType {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
  EXPIRES_IN_SECONDS = 'expires_in_seconds',
  TOKEN_EXPIRATION_DATE = 'token_expiration_date'
}

class AuthStorageService {
  private readonly _localStorage: Storage;

  constructor() {
    this._localStorage = localStorage;
  }

  get localStorage(): Storage {
    return this._localStorage;
  }

  static getExtendedKey(key: string): string {
    return `${key}_${process.env.REACT_APP_PLATFORM_NAME}`;
  }

  private getItem(key: string) {
    return this.localStorage.getItem(AuthStorageService.getExtendedKey(key));
  }

  private setItem(key: string, value: string | number) {
    this.localStorage.setItem(
      AuthStorageService.getExtendedKey(key),
      typeof value === 'number' ? value.toString() : value
    );
  }

  private removeItem(key: string) {
    this.localStorage.removeItem(AuthStorageService.getExtendedKey(key));
  }

  public getAuthTokenPayload(): AccessTokenDetailsExternal {
    return {
      access_token: this.getItem(AuthPayloadType.ACCESS_TOKEN),
      refresh_token: this.getItem(AuthPayloadType.REFRESH_TOKEN),
      expires_in_seconds: Number(this.getItem(AuthPayloadType.EXPIRES_IN_SECONDS))
    };
  }

  public getTokenExpirationDate(): string {
    return this.getItem(AuthPayloadType.TOKEN_EXPIRATION_DATE);
  }

  public setAuthTokenPayload(authTokenPayload: AccessTokenDetailsExternal) {
    this.setItem(AuthPayloadType.ACCESS_TOKEN, authTokenPayload.access_token);
    this.setItem(AuthPayloadType.REFRESH_TOKEN, authTokenPayload.refresh_token);
    this.setItem(AuthPayloadType.EXPIRES_IN_SECONDS, authTokenPayload.expires_in_seconds);
    this.setItem(
      AuthPayloadType.TOKEN_EXPIRATION_DATE,
      moment().add(authTokenPayload.expires_in_seconds, 'seconds').format()
    );
  }

  public removeAuthTokenPayload() {
    this.removeItem(AuthPayloadType.ACCESS_TOKEN);
    this.removeItem(AuthPayloadType.REFRESH_TOKEN);
    this.removeItem(AuthPayloadType.EXPIRES_IN_SECONDS);
    this.removeItem(AuthPayloadType.TOKEN_EXPIRATION_DATE);
  }
}

const instance = new AuthStorageService();

export default instance;
