import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { FolderLimitDetails, FolderLimitUpdateRequest, LimitType } from '@ibtm/domain';
import { FolderLimitUpdateRequestAllOf } from '@ibtm/domain/dist/models/folder-limit-update-request-all-of';
import { AxiosResponse } from 'axios';
import * as Yup from 'yup';

import { partialTranslate } from '@libs/common';
import { Dialog, FormV2Context, GridLayout, NumberInputField } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { DomainDictionaryEntry } from '@libs/domain/config';
import { ForeignPermissionQueryEnum } from '@libs/domain/foreign-permission';

const translate = partialTranslate('folder:details.tab.singleAuthorization.updateLimits');
const translateLimit = partialTranslate('folder:details.tab.singleAuthorization.limitTypesUpdate');

interface Props {
  open: boolean;
  closeDialog: () => void;
  onSubmit: (
    id: string,
    data: FolderLimitUpdateRequest,
    refetch: () => void
  ) => Promise<AxiosResponse<FolderLimitDetails>>;
  refetch: () => void;
  folderLimit: FolderLimitDetails;
}

const initialValues = {
  limit: null
};

function UpdateLimitsDialog({ folderLimit, onSubmit, open, closeDialog, refetch }: Props) {
  const { id, version } = folderLimit;
  const queryCache = useQueryCache();
  const [t] = useTranslation();

  const limitInitialValue = useMemo(() => {
    switch (folderLimit?.resourceType?.limitType as LimitType) {
      case LimitType.BASIC:
        return folderLimit?.basicLimit;
      case LimitType.ADDITIONAL:
        return folderLimit?.additionalLimit;
      case LimitType.FREE_DISPOSAL:
        return folderLimit?.freeDisposalLimit;
      default:
        return null;
    }
  }, [folderLimit]);

  const validationSchema = Yup.object({
    limit: Yup.number().nullable().required()
  });

  initialValues.limit = limitInitialValue;
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    reset,
    getValues,
    trigger,
    unregister
  } = useForm<Record<string, number>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });

  const _handleSubmit = async values => {
    const value: FolderLimitUpdateRequestAllOf = {
      limit: values.limit,
      typeKey: folderLimit?.typeKey,
      suspended: folderLimit?.suspended,
      folderId: folderLimit?.folder?.id,
      resourceTypeId: folderLimit?.resourceType?.id,
      reasonKey: DomainDictionaryEntry.FOLDER_CHANGE_REASON_TYPE.UPDATE_LIMIT
    };

    return onSubmit(id, { ...value, version }, () => {
      refetch();
      queryCache.invalidateQueries(ForeignPermissionQueryEnum.SINGLE_AUTHORIZATION_LIMIT_LIST);
      closeDialog();
    });
  };

  const _handleCancel = () => {
    closeDialog();
    reset();
  };

  const formValues = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  useEffect(() => {
    if (open) {
      let limit = 0;
      switch (folderLimit?.resourceType?.limitType as LimitType) {
        case LimitType.BASIC:
          limit = folderLimit?.basicLimit;
          break;
        case LimitType.ADDITIONAL:
          limit = folderLimit?.additionalLimit;
          break;
        case LimitType.FREE_DISPOSAL:
          limit = folderLimit?.freeDisposalLimit;
          break;
        default:
          break;
      }
      reset({
        limit
      });
    }
  }, [folderLimit, reset, open]);

  const getTranslationForLimit = useMemo(() => {
    const limitType = folderLimit?.resourceType?.limitType as LimitType;
    switch (limitType) {
      case LimitType.BASIC:
        return translateLimit('basic');
      case LimitType.ADDITIONAL:
        return translateLimit('additional');
      case LimitType.FREE_DISPOSAL:
        return translateLimit('freeDisposal');
      default:
        return translateLimit('noLimitSet');
    }
  }, [folderLimit]);

  return (
    <FormV2Context.Provider value={formValues}>
      <form onSubmit={handleSubmit(_handleSubmit)}>
        <Dialog
          title={translate('title')}
          confirmText={t('action.save')}
          cancelText={t('action.cancel')}
          onConfirm={handleSubmit(_handleSubmit)}
          onCancel={_handleCancel}
          isConfirmLoading={isSubmitting}
          isOpen={open}
        >
          <GridLayout itemProps={{ xs: 12 }}>
            <NumberInputField name="limit" label={getTranslationForLimit} min={0} isClearable max={10000000} />
          </GridLayout>
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
}

export default UpdateLimitsDialog;
