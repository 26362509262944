import { useTranslation } from 'react-i18next';

import { TableIconButton } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { usePermitDialog } from '../hooks';
import { ClientForeignPermissions } from '../model';

interface IProps {
  data: ClientForeignPermissions;
}

function ClientForeignPermissionsRowActions({ data }: IProps) {
  const [t] = useTranslation();
  const { openReceivePermissionDialog, openReturnPermissionDialog } = usePermitDialog();

  return (
    <>
      {data.state === DomainDictionaryEntry.RESOURCE_FORM_STATE.ISSUED_TO_ENTREPRENEURS && (
        <TableIconButton
          tooltipTitle={t('foreignPermits:clientForeignPermissions.actions.receivePermission')}
          onClick={() => openReceivePermissionDialog(data)}
          icon="ApproveIcon"
        />
      )}
      {data.state === DomainDictionaryEntry.RESOURCE_FORM_STATE.RETURNED_TO_ASSOCIATION && (
        <TableIconButton
          tooltipTitle={t('foreignPermits:clientForeignPermissions.actions.returnPermission')}
          onClick={() => openReturnPermissionDialog(data)}
          icon="ReturnIcon"
        />
      )}
    </>
  );
}

export default ClientForeignPermissionsRowActions;
