import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function expireFinancialSecurity({ financialSecurityId, version }: { financialSecurityId: string; version: number }) {
  return API.financialSecurity.expireFinancialSecurity(
    financialSecurityId,
    { version },
    {
      ...endpointsConfig.expireFinancialSecurity
    }
  );
}

function useFinancialSecurityExpireMutation() {
  return useMutation(expireFinancialSecurity);
}

export default useFinancialSecurityExpireMutation;
