import { useMutation } from 'react-query';
import { ForeignPermissionDocumentCreateRequest } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function generateForeignPermissionDocument(data: ForeignPermissionDocumentCreateRequest) {
  return API.foreignPermissions.generateDocument(data, {
    ...endpointsConfig.generateDocument
  });
}
function useGenerateForeignPermissionDocumentMutation() {
  return useMutation(generateForeignPermissionDocument);
}
export default useGenerateForeignPermissionDocumentMutation;
