import { createContext, useContext } from 'react';

export const CertificateVehicleTabContext = createContext({
  folderId: '',
  transferredFoldersIds: undefined,
  applicationId: '',
  folderType: '' as any,
  issuingCertificate: true,
  applicationStatusKey: ''
});

export default function useCertificateVehicleTabContext() {
  return useContext(CertificateVehicleTabContext);
}
