import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '..';

const removeOrganizationUnit = async (id: string) => {
  const { data } = await API.organizationStructure.deleteOrganizationUnit(
    { id },
    {
      ...endpointsConfig.deleteOrganizationUnit
    }
  );
  return data;
};

export default function useRemoveOrganizationUnitMutation() {
  return useMutation(removeOrganizationUnit);
}
