import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormMode,
  GridLayout,
  InputMode,
  NUMBER_OF_AUTOCOMPLETE_RESULTS,
  Section,
  SelectWithFetchedOptionsField,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';
import { DictionarySelectField, useViewModesV2 } from '@libs/common/v2/form';

import { API } from '@libs/alert/api';
import { useDictionaryTranslations } from '@libs/dictionary/hooks';
import { ParameterExtrasTooltipEnum, ReportDictionaryEntryNameEnum } from '@libs/report-core/model';

import { AlertDictionaryEntryNameEnum, DefaultAlertFromValues } from '../../model';

interface IProps {
  formMode: FormMode;
}

function GeneralAlertSection({ formMode }: IProps) {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();

  const { viewMode } = useViewModesV2(formMode);
  const inputMode = useMemo(() => (viewMode ? InputMode.VIEW : InputMode.FORM), [viewMode]);

  const fetchSources = useCallback((searchText: string) => {
    return API.mediatorDataSources.getReportSources(0, NUMBER_OF_AUTOCOMPLETE_RESULTS, null, null, null, searchText);
  }, []);

  return (
    <Section title={t('alerts:section.generalData')} isCollapsable>
      <GridLayout itemProps={{ xs: 12 }}>
        <TextInputField
          inputMode={inputMode}
          label={t('alerts:field.name')}
          name={typedNameV2<DefaultAlertFromValues>('name')}
          isRequired
        />
        <DictionarySelectField
          stringValue
          name={typedNameV2<DefaultAlertFromValues>('configurationStatusKey')}
          label={t('alerts:field.configurationStatus')}
          dictionaryName={AlertDictionaryEntryNameEnum.ALERT_CONFIGURATION_STATUS}
          isClearable={false}
          isRequired
        />
        <TextInputField
          inputMode={inputMode}
          label={t('alerts:field.description')}
          name={typedNameV2<DefaultAlertFromValues>('description')}
          lines={5}
          isRequired
        />
        <SelectWithFetchedOptionsField
          fetchFunction={fetchSources}
          getOptionLabel={value => value.name}
          label={t('alerts:field.source')}
          name={typedNameV2<DefaultAlertFromValues>('source')}
          isRequired
        />
        <TextInputField
          name="cron"
          label={t('reports:field.cron')}
          tooltip={translate(ReportDictionaryEntryNameEnum.REPORT_DEFINITION_HINTS, ParameterExtrasTooltipEnum.CRON)}
        />
      </GridLayout>
    </Section>
  );
}

export default GeneralAlertSection;
