import { usePaginatedQuery } from 'react-query';
import { SuspensionSearchFilter, SuspensionSnapshot } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import { SuspensionQueryKeysEnum } from './QueryKeysEnum';

const getSuspensions = async (_, params: SuspensionSearchFilter) => {
  const { data } = await API.suspensions.getSuspensionsPage(params, {
    ...endpointsConfig.getSuspensionsPage
  });
  return data;
};

export default function useSuspensionsQuery(params: SuspensionSearchFilter, queryConfig = {}) {
  return usePaginatedQuery<IPaginatedModel<SuspensionSnapshot>>(
    [SuspensionQueryKeysEnum.SUSPENSION_LIST, params],
    getSuspensions,
    queryConfig
  );
}
