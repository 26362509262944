import { FolderLimitClientSearchFilter, FolderLimitDetails as FolderLimitDetailsClient } from '@ibtm/client-domain';
import { FolderLimitDetails, FolderLimitSearchFilter } from '@ibtm/domain';

import { NumberInputFieldProps, SelectOption, TableFilterMapper, useCreateColumns } from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';

const useFolderLimitColumns = () => {
  const { createColumns } = useCreateColumns<FolderLimitDetails | FolderLimitDetailsClient>({
    pathToTranslate: 'folder:details.tab.ekmt.section.limit.field'
  });

  const mappedFilterFields:
    | TableFilterMapper<FolderLimitDetails, FolderLimitSearchFilter>
    | TableFilterMapper<FolderLimitDetailsClient, FolderLimitClientSearchFilter> = {
    resourceFormNameKey: (names: SelectOption<string>[]) => ({ resourceFormNameKeyIn: names.map(name => name.value) }),
    used: (used: NumberInputFieldProps) => ({ usedLessThanOrEqual: used?.max, usedGreaterThanOrEqual: used?.min }),
    left: (left: NumberInputFieldProps) => ({ leftLessThanOrEqual: left?.max, leftGreaterThanOrEqual: left?.min }),
    limit: (limit: NumberInputFieldProps) => ({ limitLessThanOrEqual: limit?.max, limitGreaterThanOrEqual: limit?.min })
  };

  const columns = createColumns([
    {
      type: 'DICTIONARY_MULTI_SELECT',
      header: 'name',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_NAME,
      accessor: 'resourceFormNameKey'
    },
    {
      header: 'usedAllocation',
      type: 'NUMBER',
      accessor: 'used'
    },
    {
      header: 'remained',
      type: 'NUMBER',
      accessor: 'left'
    },
    {
      header: 'sum',
      type: 'NUMBER',
      accessor: 'limit'
    }
  ]);

  return { columns, mappedFilterFields };
};
export default useFolderLimitColumns;
