import { useTranslation } from 'react-i18next';

import { IconButton } from '@libs/common/v2';

import { useEditCorrespondenceAddress } from '../../hooks';

interface IProps {
  applicationId: string;
}

function CorrespondenceAddressButton({ applicationId }: IProps) {
  const [t] = useTranslation();

  const { open } = useEditCorrespondenceAddress();
  return (
    <IconButton
      title={t('applications:action.editCorrespondenceAddress')}
      onClick={() => open(applicationId)}
      icon="EditIcon"
      className="mt-20"
    />
  );
}

export default CorrespondenceAddressButton;
