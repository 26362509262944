import { PartnerApplicantCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function createPartnerApplicant(formData: PartnerApplicantCreateRequest) {
  return API.partnerApplicants.createPartnerApplicant(formData, {
    ...endpointsConfig.createPartnerApplicant
  });
}

function useCreatePartnerApplicantMutation() {
  return useMutation(createPartnerApplicant);
}

export default useCreatePartnerApplicantMutation;
