import { QueryConfig, useQuery } from 'react-query';
import { AccountSnapshot } from '@avispon/user/dist/models';

import { API, endpointsConfig } from '..';

import { UserQueryKeysEnum } from './QueryKeysEnum';

async function getUserDetails(accountId: string) {
  if (accountId) {
    const { data } = await API.user.accountController.byId(
      { accountId },
      {
        ...endpointsConfig.userDetails
      }
    );
    return data;
  }
  return {} as AccountSnapshot;
}

export default function useUserDetailsQuery(accountId: string, config?: QueryConfig<AccountSnapshot, unknown>) {
  return useQuery([UserQueryKeysEnum.USER_DETAILS, accountId], () => getUserDetails(accountId), config);
}
