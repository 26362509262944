import { PaginatedQueryConfig, usePaginatedQuery } from 'react-query';
import { ReportTypeApiGetReportTypesRequest, ReportTypeDetailsSnapshotPage } from '@stack/report';

import { parseReportTypesList } from '../../utils/converter.util';
import { API, endpointsConfig, ReportQueryKeysEnum } from '..';

type GetReportTypesRequestParamsApiModel = {
  sourceIds: Array<string>;
  pageNumber?: number;
  pageSize?: number;
  pageSort?: Array<string>;
  createdBy?: Array<string>;
  createdMax?: string;
  createdMin?: string;
  documentTemplateId?: Array<string>;
  idFragment?: string;
  includeDeleted?: boolean;
  modifiedMax?: string;
  modifiedMin?: string;
  nameFragment?: string;
  name?: string;
  sourceNameFragment?: string;
  descriptionFragment?: string;
};

export function modelToApi(request: ReportTypeApiGetReportTypesRequest): GetReportTypesRequestParamsApiModel {
  return {
    sourceIds: request.sourceIds,
    pageNumber: request.page,
    pageSize: request.size,
    pageSort: request.sort,
    createdBy: request.createdByIds,
    createdMax: request.createdMax,
    createdMin: request.createdMin,
    documentTemplateId: request.documentTemplateIds,
    idFragment: request.idFragment,
    includeDeleted: request.includeDeleted,
    modifiedMax: request.modifiedMax,
    modifiedMin: request.modifiedMin,
    nameFragment: request.nameFragment,
    name: request.name,
    sourceNameFragment: request.sourceNameFragment,
    descriptionFragment: request.descriptionFragment
  };
}

const getReportTypes = async (_, params: ReportTypeApiGetReportTypesRequest) => {
  const apiModel = modelToApi(params);
  const { data } = await API.reportTypes.getReportTypes(
    apiModel.sourceIds,
    apiModel.pageNumber,
    apiModel.pageSize,
    apiModel.pageSort,
    apiModel.createdBy,
    apiModel.createdMax,
    apiModel.createdMin,
    apiModel.documentTemplateId,
    apiModel.idFragment,
    apiModel.includeDeleted,
    apiModel.modifiedMax,
    apiModel.modifiedMin,
    apiModel.nameFragment,
    apiModel.name,
    apiModel.sourceNameFragment,
    apiModel.descriptionFragment,
    undefined,
    { ...endpointsConfig.getReportType }
  );

  const content = parseReportTypesList(data.content);
  return {
    ...data,
    content
  };
};

export default function useReportTypesQuery(
  params: ReportTypeApiGetReportTypesRequest,
  queryConfig?: PaginatedQueryConfig<ReportTypeDetailsSnapshotPage, unknown>
) {
  return usePaginatedQuery<ReportTypeDetailsSnapshotPage>(
    [ReportQueryKeysEnum.REPORT_TYPES, params],
    getReportTypes,
    queryConfig
  );
}
