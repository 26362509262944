import { useTranslation } from 'react-i18next';
import { Info } from '@mui/icons-material';
import { FormHelperText, InputAdornment, InputLabel, TextField, TextFieldProps, Tooltip } from '@mui/material';
import clsx from 'clsx';

import { IconButton, useFormV2Context, useInputStyles } from '@libs/common/v2';
import { useTheme } from '@libs/common/v2/theme';

import { ErrorAdornment } from './adornments';

/*
 * Komponent jest 1 do 1 TextFieldem z material-ui - przyjmuje takie same propsy, ma jedynie nadpisane style.
 */

interface IProps {
  adornmentClassName?: string;
  adornmentErrorClassName?: string;
  hasCustomDeleteIcon?: boolean;
  isWarning?: boolean;
  isDeleteEnabled?: boolean;
  isLabelHidden?: boolean;
  onDelete?: () => void;
  onClick?: React.MouseEventHandler;
  tooltip?: string | React.ReactNode;
  hasErrorTooltip?: boolean;
  isErrorIconHidden?: boolean;
}

function TextInput({
  inputRef,
  label,
  className,
  InputProps,
  helperText,
  'aria-label': ariaLabel,
  adornmentClassName,
  adornmentErrorClassName,
  hasCustomDeleteIcon,
  isWarning,
  isLabelHidden,
  onDelete,
  isDeleteEnabled,
  name,
  onClick,
  tooltip,
  isErrorIconHidden,
  hasErrorTooltip,
  ...forwardProps
}: TextFieldProps & IProps) {
  const { required: isRequired, disabled: isDisabled, error: isError } = forwardProps;
  const { contrast } = useTheme();
  const classes = useInputStyles({ isRequired, isDisabled, isError, isWarning, contrast });
  const [t] = useTranslation();
  const { setValue } = useFormV2Context();

  const renderAdornments = () => {
    if (isError && !isDisabled && !isErrorIconHidden) {
      return (
        <ErrorAdornment className={adornmentErrorClassName} helperText={helperText} hasErrorTooltip={hasErrorTooltip}>
          {InputProps?.endAdornment}
        </ErrorAdornment>
      );
    }

    if (InputProps?.endAdornment) {
      return (
        <InputAdornment className={clsx(classes.adornment, classes.popupIndicator, adornmentClassName)} position="end">
          {hasCustomDeleteIcon && isDeleteEnabled && (
            <IconButton
              tooltipTitle={t('action.clear')}
              className="p-0"
              icon="CrossIcon"
              height={17}
              width={17}
              onClick={() => {
                setValue?.(name as any, null);
                onDelete?.();
              }}
              isBackgroundTransparent
            />
          )}
          {InputProps?.endAdornment}
        </InputAdornment>
      );
    }

    return null;
  };

  return (
    <InputLabel onClick={onClick} className={clsx(classes.root, className)}>
      <div className={classes.labelWrapper}>
        {!isLabelHidden && (
          <span>
            {label} {isRequired && <span className={classes.asterisk}>&nbsp;*</span>}
          </span>
        )}
        {tooltip && (
          <Tooltip
            classes={{
              tooltip: classes.tooltipPopper
            }}
            title={tooltip}
            placement="top"
            arrow
            data-testid={`${label}-${tooltip}`}
          >
            <Info className={classes.tooltipIcon} />
          </Tooltip>
        )}
      </div>
      <TextField
        inputRef={inputRef}
        className={clsx('field-text-input', classes.input)}
        name={name}
        {...forwardProps}
        InputProps={{
          ...(typeof label === 'string' ? { 'aria-label': label } : { 'aria-label': ariaLabel }),
          ...InputProps,
          endAdornment: renderAdornments()
        }}
      />
      {!hasErrorTooltip && helperText && (
        <FormHelperText className={clsx(classes.formHelperText)} error={isError}>
          {helperText}
        </FormHelperText>
      )}
    </InputLabel>
  );
}

export default TextInput;
