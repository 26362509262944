import { useTranslation } from 'react-i18next';
import { HintType } from '@stack/report';
import * as Yup from 'yup';

import { useValidatorUtil, ValidatorEnums } from '@libs/common/v2';

interface IParams {
  reportTypeValues: any;
  initialEditFormValues: any;
}

function useParameterDialogContentValidation({ reportTypeValues, initialEditFormValues }: IParams) {
  const [t] = useTranslation();

  const isDuplicate = (value: string) => {
    const paramsDisplayName = reportTypeValues.parameterExtras.map(param => {
      return param.displayName;
    });
    const isNotInitialValue = initialEditFormValues ? value !== initialEditFormValues.displayName : true;
    const isDuplicated = paramsDisplayName.includes(value) && isNotInitialValue;

    return !isDuplicated;
  };

  const validationSchema = Yup.object({
    displayName: Yup.string()
      .required()
      .test('isDuplicate', t('reports:message.duplicatedParamName'), (value: string) => isDuplicate(value)),
    queryName: Yup.string()
      .nullable()
      .required()
      .matches(...useValidatorUtil(ValidatorEnums.SQL_PARAMETER_NAME)),
    type: Yup.object({}).required().nullable(),
    hint: Yup.object({
      query: Yup.string().nullable().when('type', {
        is: HintType.QUERY,
        then: Yup.string().nullable().required()
      }),
      values: Yup.array().nullable().when('type', {
        is: HintType.VALUES,
        then: Yup.array().nullable().required()
      }),
      sourceId: Yup.object().nullable().when('type', {
        is: HintType.COLUMN,
        then: Yup.object().nullable().required()
      }),
      columnKey: Yup.object().nullable().when('type', {
        is: HintType.COLUMN,
        then: Yup.object().nullable().required()
      }),
      dictionaryName: Yup.object().nullable().when('type', {
        is: HintType.DICTIONARY,
        then: Yup.object().nullable().required()
      }),
      dictionaryKeys: Yup.mixed()
        .nullable()
        .test('required', t('validation:required'), function checkIsRequired() {
          const { defaultValue, dictionaryKeys, type } = this.parent;
          if (!defaultValue || type !== HintType.DICTIONARY) {
            return true;
          }
          return Array.isArray(dictionaryKeys) ? dictionaryKeys.length > 0 : Boolean(dictionaryKeys);
        }),
      validator: Yup.object().nullable()
    })
  });

  return { validationSchema };
}

export default useParameterDialogContentValidation;
