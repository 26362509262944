import { FormActionEnum, useFormActionModal } from '@libs/common/v2';

const useHandleFormChanges = (isDirty: boolean, onSubmitForm: () => void, onResetForm: () => void) => {
  const saveChangesAction = useFormActionModal();

  const handleFormChanges = (handleAction: () => void) => {
    if (isDirty) {
      saveChangesAction({
        formAction: FormActionEnum.SAVE_CHANGES,
        onConfirm: async () => {
          await onSubmitForm();
          handleAction();
        },
        onCancel: async () => {
          await onResetForm();
          handleAction();
        }
      });
    } else {
      handleAction();
    }
  };

  return { handleFormChanges };
};

export default useHandleFormChanges;
