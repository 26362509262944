import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Row } from 'react-table';
import { ProceedingAvailableFormsDetails, ProceedingFormDetails } from '@ibtm/domain';

import {
  Button,
  FormMode,
  ITableAdapter,
  ITableContext,
  TableContext,
  TableLayout,
  useViewModesV2
} from '@libs/common/v2';

import { API } from '@libs/domain/api';
import DomainUIElementEnum from '@libs/domain/config/permission/DomainUIElementEnum';
import {
  parseProceedingFormOperationsListPayload,
  parseSuspensionFormOperationsListPayload,
  ProceedingTypeKeyEnum,
  useFormOperationDetails,
  useFormOperationsTable
} from '@libs/domain/proceeding';
import {
  useFormOperationActions as useSuspensionFormOperationActions,
  useFormOperationsSuspensionsTable
} from '@libs/domain/suspensions';

import { endpointsConfig } from '../../../../../../suspensions/api/endpoints-config';
import useFormOperationActivePermissionsDialog from '../../../../../hooks/useFormOperationActivePermissionsDialog';
import { useFormOperationActions as useProceedingFormOperationActions } from '../hooks/useFormOperationActions';

import FormOperationsHeader from './FormOperationsHeader';

interface IProps {
  title?: string;
  mode?: FormMode;
  type?: ProceedingTypeKeyEnum;
  suspensions?: boolean;
  proceedingId?: string;
  modalTableData?: ProceedingAvailableFormsDetails[] | [];
  headerAction?: () => void;
  isFormModal?: boolean;
  rowAction?: (row: Row<any>) => ReactNode;
  formMode?: FormMode;
}

function FormOperationsList({
  type,
  suspensions,
  title,
  proceedingId,
  modalTableData,
  headerAction,
  rowAction,
  isFormModal,
  formMode
}: IProps) {
  let rowActions: (
    row: Row<ProceedingFormDetails>,
    table: {
      tableInstance: ITableAdapter<ProceedingFormDetails, ProceedingFormDetails>;
      tableContext: ITableContext<ProceedingFormDetails>;
    }
  ) => ReactNode;
  const { folderId, id, proceedingTypeKey } = useParams<{
    folderId: string;
    id: string;
    proceedingTypeKey: ProceedingTypeKeyEnum;
  }>();
  const [t] = useTranslation();
  const { openDetailsDialog } = useFormOperationDetails(folderId, proceedingTypeKey, type, suspensions, id);
  const { openActivePermissionsDialog } = useFormOperationActivePermissionsDialog(folderId);
  const tableProps = useFormOperationsTable(proceedingId || id, type ?? proceedingTypeKey, isFormModal, modalTableData);
  const suspensionFormOperationActions = useSuspensionFormOperationActions();
  const proceedingFormOperationActions = useProceedingFormOperationActions();
  const { viewMode } = useViewModesV2(formMode);

  const suspensionTableProps = useFormOperationsSuspensionsTable(proceedingId || id, modalTableData);

  const openCreateFormDialog = (refetch: () => void) => {
    openDetailsDialog(FormMode.CREATE, refetch, null, id);
  };

  const openCheckQualificationsDialog = (listedPermissions: string[]) => {
    openActivePermissionsDialog(listedPermissions);
  };

  const selectionColumnWidth = useMemo(() => {
    return proceedingTypeKey === ProceedingTypeKeyEnum.ADMINISTRATION && !suspensions ? 100 : 0;
  }, [proceedingTypeKey, suspensions]);

  if (isFormModal) {
    rowActions = rowAction;
  } else {
    rowActions = suspensions ? suspensionFormOperationActions : proceedingFormOperationActions;
  }

  return (
    <TableLayout
      {...(suspensions ? suspensionTableProps : tableProps)}
      pageTitle={title || t('proceeding:administrative.tab.formOperations.title')}
      selectionColumnWidth={selectionColumnWidth}
      xlsxDownload={{
        fileName: t('proceeding:administrative.tab.formOperations.title'),
        pathToXLSXTranslation: 'proceeding:administrative.tab.formOperations',
        apiRequest: params => {
          if (suspensions) {
            const { suspensionId: suspensionIdentifier, ...queryParams } =
              parseSuspensionFormOperationsListPayload(params);
            return API.suspensions.getSuspensionFormOperationsPage(suspensionIdentifier, queryParams, {
              ...endpointsConfig.getSuspensionFormOperationsPage
            });
          }

          const { proceedingId: proceedingIdentifier, ...queryParams } =
            parseProceedingFormOperationsListPayload(params);
          return API.proceeding.getProceedingFormsPage(proceedingIdentifier, queryParams);
        }
      }}
      headerActions={
        isFormModal ? (
          <>
            {!viewMode && (
              <Button
                label={t('proceeding:administrative.tab.formOperations.addFormTitle')}
                onClick={headerAction}
                variant="outlined"
                isNoMargin
              />
            )}
          </>
        ) : (
          <TableContext.Consumer>
            {({ refetch }) => (
              <FormOperationsHeader
                openCheckQualificationsDialog={openCheckQualificationsDialog}
                openCreateFormDialog={() => openCreateFormDialog(refetch)}
                actionKeys={{
                  addFormOperation: suspensions
                    ? DomainUIElementEnum.SUSPENSIONS_FORM_OPERATIONS_ADD_BUTTON
                    : DomainUIElementEnum.PROCEEDING_FORM_OPERATIONS_ADD_BUTTON,
                  suspendIssuing: DomainUIElementEnum.PROCEEDING_FORM_OPERATIONS_SUSPEND_ISSUING_BUTTON,
                  resumeIssuing: DomainUIElementEnum.PROCEEDING_FORM_OPERATIONS_UNSUSPEND_ISSUING_BUTTON,
                  editIssuing: DomainUIElementEnum.PROCEEDING_FORM_OPERATIONS_EDIT_ISSUING_BUTTON,
                  checkQualifications: suspensions
                    ? DomainUIElementEnum.SUSPENSIONS_FORM_OPERATIONS_SHOW_ACTIVE_PERMISSIONS_BUTTON
                    : DomainUIElementEnum.PROCEEDING_FORM_OPERATIONS_SHOW_ACTIVE_PERMISSIONS_BUTTON
                }}
              />
            )}
          </TableContext.Consumer>
        )
      }
      rowActions={rowActions}
      isFilterEnabled={!isFormModal}
      isRefreshEnabled={!isFormModal}
      isSection
    />
  );
}

export default FormOperationsList;
