import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function deleteAssociationSubject(params: { associationId: string; subjectId: string }) {
  const { associationId, subjectId } = params;
  return API.association.deleteAssociationSubject(associationId, subjectId, {
    ...endpointsConfig.deleteAssociationSubject
  });
}

export default function useDeleteAssociationSubjectMutation() {
  return useMutation(deleteAssociationSubject);
}
