import { ResourceAssignmentLicenseCopyUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

async function updateLicenseCopy(formData: ResourceAssignmentLicenseCopyUpdateRequest) {
  const { data } = await API.printouts.updateResourceAssignmentLicenseCopy(formData, {
    ...endpointsConfig.updateResourceAssignmentLicenseCopy
  });
  return data;
}

export default function useUpdateLicenseCopyMutation() {
  return useMutation(updateLicenseCopy);
}
