import { useViewModesV2 } from '@libs/common/v2';

import {
  folderRoutesPermissionConfig,
  folderTabPermissionConfig,
  type PermissionConfig
} from '@libs/domain/config/permission/folder/folderRoutesPermission.config';

import { FolderDetailsTabEnum } from '../model';

export function useGetFolderPermissions({ folderTypeKey, tab }: { folderTypeKey: string; tab: FolderDetailsTabEnum }) {
  const { editMode, viewMode } = useViewModesV2();

  let permissionKey: keyof PermissionConfig;
  if (editMode) {
    permissionKey = 'edit';
  } else if (viewMode) {
    permissionKey = 'view';
  } else {
    permissionKey = 'create';
  }

  const permissions = folderRoutesPermissionConfig?.[folderTypeKey]?.[permissionKey] || [];
  const tabPermissions = folderTabPermissionConfig?.[tab]?.[permissionKey] || [];

  return { permissions: [...permissions, ...tabPermissions] };
}
