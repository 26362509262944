export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  receiveForeignPermissions: { translationKey: 'foreignPermits:api.receiveForeignPermissions' },
  returnForeignPermissions: { translationKey: 'foreignPermits:api.returnForeignPermissions' },
  returnForeignPermission: { translationKey: 'foreignPermits:api.returnForeignPermission' },
  getForeignPermissionSnapshotPage: { translationKey: 'foreignPermits:api.getForeignPermissionSnapshotPage' },
  getForeignPermission: { translationKey: 'foreignPermits:api.getForeignPermission' },
  getPermissionsPage: { translationKey: 'foreignPermits:api.getPermissionsPage' },
  getFolderLimitsPage: { translationKey: 'folder:api.getFolderLimitsPage' },
  settleForeignPermission: { translationKey: 'foreignPermits:api.settleForeignPermission' },
  createEkmtCarnetSettlement: { translationKey: 'foreignPermits:api.createEkmtCarnetSettlement' },
  deleteEkmtCarnetSettlement: { translationKey: 'foreignPermits:api.deleteEkmtCarnetSettlement' },
  updateEkmtCarnetSettlement: { translationKey: 'foreignPermits:api.updateEkmtCarnetSettlement' },
  generateDocument: { translationKey: 'foreignPermits:api.generateDocument' }
};
