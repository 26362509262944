import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '@enigma/fe-ui';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';
import { Tooltip } from '@mui/material';
import { isBoolean, omit, pickBy } from 'lodash';

import { DropdownListButton } from '@libs/common/v2';

import { useApplicationDetailsQuery, useApplicationVerifyQuery } from '../../api';
import { IApplicationDetailsPageParams } from '../../model';

import ApplicationVerifyDialog from './ApplicationVerifyDialog';

interface IProps {
  onClose: () => void;
}
function ApplicationVerifyButton({ onClose }: IProps) {
  const [shouldVerify, setShouldVerify] = useState(false);
  const [isDataToDisplay, setIsDataToDisplay] = useState(false);

  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const handleClose = () => {
    setIsDataToDisplay(false);
    setShouldVerify(false);
    onClose();
  };
  const { applicationId } = useParams<IApplicationDetailsPageParams>();
  const { data: applicationDetails } = useApplicationDetailsQuery(applicationId, { refetchOnMount: false });
  const { data, isLoading } = useApplicationVerifyQuery(applicationId, {
    enabled: Boolean(applicationId && shouldVerify)
  });
  const isDisabled = applicationDetails && 'modifier' in applicationDetails ? !applicationDetails?.modifier : true;

  useEffect(() => {
    if (data && shouldVerify) {
      const shouldOpenDialog = Boolean(
        data?.subjectReputation.reputationRequests?.length ||
          data?.transportManagersReputation?.length ||
          data?.invalidStateErrors?.length ||
          Object.values(omit(pickBy(data, isBoolean)))?.filter(Boolean).length
      );
      setIsDataToDisplay(shouldOpenDialog);
      if (!shouldOpenDialog) {
        showSuccessSnackbar(t('applications:verify.message.noErrorsInApplication'));
        setShouldVerify(false);
      }
    }
  }, [setIsDataToDisplay, showSuccessSnackbar, data, shouldVerify, t]);

  return (
    <>
      <Tooltip disableHoverListener={!isDisabled} title={t('applications:verify.message.applicationWasNotEdited')}>
        <span>
          <DropdownListButton
            onClick={() => setShouldVerify(true)}
            label={t('applications:verify.action.verify')}
            isLoading={isLoading}
            isDisabled={isDisabled}
            actionKey={UIElementNameEnum.APPLICATION_VERIFY_BUTTON}
          />
        </span>
      </Tooltip>
      <ApplicationVerifyDialog
        onClose={handleClose}
        data={data}
        isOpen={shouldVerify && isDataToDisplay}
        applicationId={applicationId}
        applicationDetails={applicationDetails}
      />
    </>
  );
}
export default ApplicationVerifyButton;
