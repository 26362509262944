import { useTableLayoutProps } from '@libs/common/v2';

import { useVehiclesQuery } from '@libs/domain/vehicle';

import { useApplicationVehiclesColumns } from '../columns';

function useApplicationVehiclesTable(applicationId) {
  const columns = useApplicationVehiclesColumns();

  return useTableLayoutProps({
    tableHookQuery: useVehiclesQuery,
    tableHookOptions: {
      columns
    },
    tableHookQueryInitialParams: {
      applicationIdIn: [applicationId]
    },
    isFilterEnabled: false
  });
}

export default useApplicationVehiclesTable;
