import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { MisspellCertificateDetails } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  FormMode,
  FormV2Context,
  GridLayout,
  InputMode,
  QuickChangeableDictionarySelectField,
  TextInputField,
  typedNameV2,
  useValidationBuilder,
  useViewModesV2
} from '@libs/common/v2';

import { DomainQuickchangeableDictionaryEnum } from '../../config';
import { MisspellApplicationDataCertificateFormValues } from '../model/misspell-application.model';

interface IProps {
  certificatesData: Partial<MisspellApplicationDataCertificateFormValues>;
  formMode: FormMode;
}

function MisspellApplicationCertificateFormFields({ formMode, certificatesData }: IProps, ref) {
  const { validationBuilderFunctions, validationScheme } = useValidationBuilder();
  const [t] = useTranslation();
  const { viewMode } = useViewModesV2(formMode);

  const getLabel = partialTranslate('applications:misspellApplication.fields');
  const formMethods = useForm<Partial<MisspellApplicationDataCertificateFormValues>>({
    defaultValues: certificatesData,
    resolver: yupResolver(validationScheme),
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });

  useImperativeHandle(ref, () => formMethods.handleSubmit, [formMethods.handleSubmit]);

  const values = useMemo(
    () => ({ ...formMethods, formMode, validationBuilderFunctions }),
    [formMethods, formMode, validationBuilderFunctions]
  );

  const vehicleBrandKeyValue = values.watch('vehicleBrandKey')?.value;

  useEffect(() => {
    if (!vehicleBrandKeyValue) {
      values.setValue('vehicleModelKey', null);
    }
  }, [values, vehicleBrandKeyValue]);

  return (
    <FormV2Context.Provider value={values}>
      <GridLayout itemProps={{ xs: 12 }}>
        <TextInputField
          name={typedNameV2<MisspellCertificateDetails>('formNumber')}
          label={getLabel('formNumber')}
          isRequired
          validation={{ required: [] }}
          inputProps={{ maxLength: 20 }}
          inputMode={InputMode.VIEW}
        />
        <TextInputField
          name={typedNameV2<MisspellCertificateDetails>('number')}
          label={getLabel('number')}
          isRequired
          validation={{ required: [] }}
          inputProps={{ maxLength: 20 }}
          inputMode={InputMode.VIEW}
        />
        <TextInputField
          name={typedNameV2<MisspellCertificateDetails>('applicationNumber')}
          label={getLabel('applicationNumber')}
          isRequired
          validation={{ required: [] }}
          inputProps={{ maxLength: 20 }}
          inputMode={InputMode.VIEW}
        />
        <DatepickerField
          name={typedNameV2<MisspellCertificateDetails>('dateOfIssue')}
          label={getLabel('dateOfIssue')}
          isRequired
          validation={{ required: [] }}
          inputMode={InputMode.VIEW}
        />
        <TextInputField
          name={typedNameV2<MisspellCertificateDetails>('vehiclePlateNumber')}
          label={getLabel('vehiclePlateNumber')}
          validation={{ required: [] }}
          inputProps={{ maxLength: 30 }}
          isRequired
        />
        <TextInputField
          name={typedNameV2<MisspellCertificateDetails>('vehicleVin')}
          label={getLabel('vehicleVin')}
          validation={{ required: [] }}
          inputProps={{ maxLength: 30 }}
          isRequired
        />
        <QuickChangeableDictionarySelectField
          name={typedNameV2<MisspellCertificateDetails>('vehicleBrandKey')}
          label={getLabel('vehicleBrandKey')}
          dictionaryName={DomainQuickchangeableDictionaryEnum.VEHICLE_BRAND}
          validationSingleSelect={{ required: [] }}
          isRequired
          loadDictionaryOnStart
          isClearable
        />
        <QuickChangeableDictionarySelectField
          name={typedNameV2<MisspellCertificateDetails>('vehicleModelKey')}
          label={getLabel('vehicleModelKey')}
          dictionaryName={DomainQuickchangeableDictionaryEnum.VEHICLE_MODEL}
          {...(!viewMode ? { parentKey: vehicleBrandKeyValue } : {})}
          isDisabled={!vehicleBrandKeyValue}
          {...(!vehicleBrandKeyValue && { tooltip: t('vehicle:field.tooltipModel') })}
          validationSingleSelect={{ required: [] }}
          isRequired
          loadDictionaryOnStart
          isClearable
        />
        <TextInputField
          name={typedNameV2<MisspellCertificateDetails>('vehicleEngineType')}
          label={getLabel('vehicleEngineType')}
          validation={{ required: [] }}
          isRequired
        />
        <TextInputField
          name={typedNameV2<MisspellCertificateDetails>('vehicleEngineNumber')}
          label={getLabel('vehicleEngineNumber')}
          validation={{ required: [] }}
          isRequired
        />
        <TextInputField
          name={typedNameV2<MisspellCertificateDetails>('compatibilityCertificateNumber')}
          label={getLabel('compatibilityCertificateNumber')}
          validation={{ required: [] }}
          isRequired
        />
      </GridLayout>
    </FormV2Context.Provider>
  );
}

export default forwardRef(MisspellApplicationCertificateFormFields);
