import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReportDetailsLite } from '@stack/report';
import { isEmpty, isNil } from 'lodash';

import { FormMode, FormV2Context, Page, useValidationBuilder } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { useDictionaryTranslations } from '@libs/dictionary';
import { DictionaryEntryNameEnum } from '@libs/dictionary/models/dictionaryEntryNameEnum';
import { ReportTypeForm } from '@libs/report/model/form.model';

import ReportTypePageHeader from './ReportTypePageHeader';
import ReportTypeTabs from './ReportTypeTabs';

function ReportTypePageContent({
  initialValues,
  formMode,
  onSubmit,
  isSubmitting,
  title,
  breadcrumbsTitle
}: {
  initialValues: ReportTypeForm;
  formMode: FormMode;
  onSubmit: (values: ReportTypeForm) => void;
  isSubmitting: boolean;
  title: string;
  breadcrumbsTitle: string;
}) {
  const { translate } = useDictionaryTranslations();
  const { editMode } = useViewModesV2(formMode);
  const { validationScheme, validationBuilderFunctions } = useValidationBuilder();
  const [generatedReport, setGeneratedReport] = useState<ReportDetailsLite>(null);

  const form = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: initialValues,
    resolver: yupResolver(validationScheme)
  });

  const {
    reset,
    formState: { isDirty, dirtyFields }
  } = form;

  const values = useMemo(
    () => ({
      ...form,
      formMode,
      isDirty,
      dirtyFields,
      isSubmitting,
      validationBuilderFunctions,
      loading: editMode ? initialValues.name === '' : false
    }),
    [form, formMode, validationBuilderFunctions, isDirty, dirtyFields, isSubmitting, initialValues.name, editMode]
  );

  useEffect(() => {
    const initialFormValue: ReportTypeForm = initialValues;

    if (editMode && !isNil(initialValues.permissionKeys) && !isEmpty(initialValues.permissionKeys)) {
      initialFormValue.permissionKeys = initialValues.permissionKeys.map(({ name }) => ({
        name: translate(DictionaryEntryNameEnum.PERMISSION, name, name),
        value: name
      }));
    }

    reset(initialFormValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, initialValues.name, translate]);

  return (
    <FormV2Context.Provider value={values}>
      <Page
        classes={{ contentWrapper: 'h-full', content: 'flex flex-col' }}
        contentWrapperClassName="h-full"
        header={
          <ReportTypePageHeader
            title={title}
            breadcrumbsTitle={breadcrumbsTitle}
            reportTypeName={initialValues.name}
            onSubmit={form.handleSubmit(data => onSubmit(data as ReportTypeForm))}
            isSubmitting={isSubmitting}
            setGeneratedReport={setGeneratedReport}
          />
        }
        content={<ReportTypeTabs formMode={formMode} generatedReport={generatedReport} />}
      />
    </FormV2Context.Provider>
  );
}

export default ReportTypePageContent;
