import { useDialog } from '@libs/common/v2';

import { ViolationStartForm } from '../../components/form';

const useStartProceedingDialog = () => {
  const { openDialog } = useDialog();

  const open = (selectedIds: string[]) => {
    openDialog(({ closeDialog }) => {
      return <ViolationStartForm selectedIds={selectedIds} closeDialog={closeDialog} />;
    });
  };

  return {
    open
  };
};

export default useStartProceedingDialog;
