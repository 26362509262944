import { useContext } from 'react';

import SystemParametersContext from './SystemParametersContext';

const useSystemParametersContext = () => {
  const { sessionValidityDuration } = useContext(SystemParametersContext);

  return { sessionValidityDuration };
};

export default useSystemParametersContext;
