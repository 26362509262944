import { useTableLayoutProps } from '@libs/common/v2';

import { useAlertHistoryQuery } from '@libs/alert/api';
import { useAlertHistoryColumns } from '@libs/alert/hooks';

function useAlertHistoryTable(alertId: string) {
  const { columns, mappedFilterFields } = useAlertHistoryColumns();
  const alertIds = [alertId];

  return useTableLayoutProps({
    tableHookQuery: useAlertHistoryQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields
    },
    tableHookQueryInitialParams: {
      alertIds
    }
  });
}

export default useAlertHistoryTable;
