import { DefaultApi as GroupDefaultApi } from '@avispon/group';
import { DefaultApi as OrganizationStructureDefaultApi } from '@avispon/organization-structure';
import { AccountsApi as UserAccountApi, DefaultApi as UserDefaultApi } from '@avispon/user';
import { AlertApiFactory, MediatorDataSourcesApiFactory } from '@stack/report';
import axios from 'axios';

interface AlertApi {
  alert: ReturnType<typeof AlertApiFactory>;
  mediatorDataSources: ReturnType<typeof MediatorDataSourcesApiFactory>;
  organizationStructure: OrganizationStructureDefaultApi;
  group: GroupDefaultApi;
  user: {
    default: UserDefaultApi;
    accountController: UserAccountApi;
  };
}

const alertApiFactoryFallback: any = () => {
  throw new Error('Alert api has not been set');
};

const API: AlertApi = {
  alert: alertApiFactoryFallback,
  organizationStructure: alertApiFactoryFallback,
  group: alertApiFactoryFallback,
  user: alertApiFactoryFallback,
  mediatorDataSources: alertApiFactoryFallback
};

export function setAlertApi(API_PORTAL_URL: string) {
  API.alert = AlertApiFactory({ isJsonMime: () => true }, API_PORTAL_URL, axios as never);
  API.mediatorDataSources = MediatorDataSourcesApiFactory({ isJsonMime: () => true }, API_PORTAL_URL, axios as never);
  API.group = new GroupDefaultApi({ isJsonMime: () => true }, API_PORTAL_URL, axios);
  API.organizationStructure = new OrganizationStructureDefaultApi({ isJsonMime: () => true }, API_PORTAL_URL, axios);
  API.user = {
    default: new UserDefaultApi({ isJsonMime: () => true }, API_PORTAL_URL, axios),
    accountController: new UserAccountApi({ isJsonMime: () => true }, API_PORTAL_URL, axios)
  };
}

export { API };
