export enum ReportQueryKeysEnum {
  REPORT_TYPE_FORM = 'REPORT_TYPE_FORM',
  REPORT_TYPE_DETAILS = 'REPORT_TYPE_DETAILS',
  REPORT_DETAILS = 'REPORT_DETAILS',
  REPORT_SOURCES = 'REPORT_SOURCES',
  REPORT_SOURCES_FILTER = 'REPORT_SOURCES_FILTER',
  REPORT_TYPES = 'REPORT_TYPES',
  REPORTS = 'REPORTS',
  FIELD_TYPES = 'FIELD_TYPES',
  REPORT_PARAMETER_VALUES = 'REPORT_PARAMETER_VALUES'
}
