import React, { ReactNode } from 'react';
import { Drawer, Hidden } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import PageSidebarContent from './PageSidebarContent';

interface IProps {
  variant?: 'temporary' | 'permanent';
  header?: ReactNode;
  content?: ((toggle: () => void) => ReactNode) | ReactNode;
  contentNotExpanded?: ((toggle: () => void) => ReactNode) | ReactNode;
  position: 'left' | 'right';
  rootRef: React.MutableRefObject<Element>;
  contentClassName?: string;
  headerClassName?: string;
  sidebarClassName?: string;
  handleToggleDrawer?: () => void;
  isInnerScrollEnabled?: boolean;
  isWhite?: boolean;
  isSidebarOpen?: boolean;
  isToggleDrawerVisible?: boolean;
  minWidth?: string;
}

function PageSidebar({
  variant = 'permanent',
  sidebarClassName,
  position,
  isSidebarOpen,
  isWhite,
  rootRef,
  handleToggleDrawer,
  minWidth,
  ...forwardProps
}: IProps) {
  const classes = useStyles({ isWhite, isSidebarOpen, minWidth });

  return (
    <>
      {/* @TODO - nie używamy tej wersji nigdzie czy jest to potrzebne? */}
      {variant === 'temporary' && (
        <Hidden lgUp>
          <Drawer
            variant="temporary"
            anchor={position}
            open={isSidebarOpen}
            onClose={handleToggleDrawer}
            classes={{
              root: clsx(classes.sidebarWrapper, variant),
              paper: clsx(classes.sidebar, variant, position === 'left' ? classes.leftSidebar : classes.rightSidebar)
            }}
            className={clsx('h-full', sidebarClassName)}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
            container={rootRef.current}
            BackdropProps={{
              classes: {
                root: classes.backdrop
              }
            }}
            style={{ position: 'absolute' }}
          >
            <PageSidebarContent
              isExpanded={isSidebarOpen}
              handleToggleDrawer={handleToggleDrawer}
              position={position}
              {...forwardProps}
            />
          </Drawer>
        </Hidden>
      )}
      {variant === 'permanent' && (
        <Drawer
          variant="permanent"
          anchor={position}
          className={clsx(classes.sidebarWrapper, variant, 'h-full', sidebarClassName)}
          open={isSidebarOpen}
          onClose={handleToggleDrawer}
          classes={{
            paper: clsx(classes.sidebar, variant, position === 'left' ? classes.leftSidebar : classes.rightSidebar)
          }}
        >
          <PageSidebarContent
            isExpanded={isSidebarOpen}
            handleToggleDrawer={handleToggleDrawer}
            position={position}
            {...forwardProps}
          />
        </Drawer>
      )}
    </>
  );
}

const useStyles = makeStyles<Theme, { isWhite?: boolean; isSidebarOpen?: boolean; minWidth?: string }>(theme => ({
  sidebarWrapper: {
    overflow: 'hidden',
    backgroundColor: 'transparent',
    position: 'absolute',
    transform: 'translateZ(0)',
    '&.permanent': {
      position: 'relative'
    },
    [theme.breakpoints.down('xMd')]: {
      // right: 0,
      zIndex: 2
    },
    height: '92%',
    width: ({ isSidebarOpen }) => (isSidebarOpen ? 'min-content' : 55),
    '& .MuiTabs-root': {
      width: ({ isSidebarOpen, minWidth }) => (isSidebarOpen ? minWidth || 'max-content' : 54)
    }
  },
  sidebar: {
    position: 'absolute',
    '&.permanent': {
      backgroundColor: ({ isWhite }) => (isWhite ? theme.palette.white : theme.palette.grey[50]),
      color: theme.palette.grey[700],
      position: 'relative'
    },
    height: '100%'
  },
  leftSidebar: {
    borderRight: important(`1px solid ${theme.palette.grey[200]}`),
    borderLeft: 0
  },
  rightSidebar: {
    borderLeft: important(`1px solid ${theme.palette.grey[200]}`),
    borderRight: 0
  },
  backdrop: {
    position: 'absolute'
  }
}));

export default PageSidebar;
