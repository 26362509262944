import { QueryConfig, useQuery } from 'react-query';
import { ApplicationFlagDetails as ApplicationFlagDetailsClient } from '@ibtm/client-domain';
import { ApplicationFlagDetails } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import ApplicationQueryKeysEnum from './ApplicationQueryKeysEnum';

export const getApplicationFlags = async (applicationId?: string) => {
  const { data } = await API.application.getApplicationFlagsDetails(applicationId);

  return data;
};

export const getApplicationFlagsClient = async (applicationId?: string) => {
  const { data } = await API.client.application.getApplicationFlagsDetails('', '', applicationId);

  return data;
};

export default function useApplicationFlagsQuery(
  applicationId: string,
  queryConfig: QueryConfig<ApplicationFlagDetails | ApplicationFlagDetailsClient, unknown> = {}
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery<ApplicationFlagDetails>(
    [ApplicationQueryKeysEnum.APPLICATION_FLAGS, applicationId],
    getQuery(
      () => getApplicationFlagsClient(applicationId),
      () => getApplicationFlags(applicationId)
    ),
    queryConfig
  );
}
