import { ApplicationHistoryDetails, ApplicationHistorySearchFilter } from '@ibtm/domain';

import { ALTERNATIVE_DATE_TIME_FORMAT, SelectOption, TableFilterMapper, useCreateColumns } from '@libs/common/v2';
import { convertDateToDateTimeFormat, DateRangeISO, getValue } from '@libs/common/v2/utils';

import { UserFilterV2 } from '@libs/user';

import { DomainDictionaryEnum } from '../../config';

function useApplicationHistoryColumns() {
  const { createColumns } = useCreateColumns<ApplicationHistoryDetails>({
    pathToTranslate: 'history:field'
  });

  const mappedFilterFields: TableFilterMapper<ApplicationHistoryDetails, ApplicationHistorySearchFilter> = {
    created: ({ dateFrom, dateTo }: DateRangeISO) => ({
      createdGreaterThanOrEqual: dateFrom,
      createdLessThanOrEqual: dateTo
    }),
    createdByName: (createdByIds: SelectOption[]) => ({
      createdByIdIn: createdByIds.map(({ value }: SelectOption<string>) => value)
    }),
    statusKey: (statusKeys: SelectOption[]) => ({
      statusKeysIn: statusKeys.map(({ value }: SelectOption<string>) => value)
    }),
    descriptionKey: (descriptionKeys: SelectOption[]) => ({
      descriptionIn: descriptionKeys.map(({ value }: SelectOption<string>) => value)
    })
  };

  const columns = createColumns([
    {
      id: 'created',
      header: 'modified',
      type: 'DATE',
      customAccessor: row => getValue(convertDateToDateTimeFormat(row?.created, ALTERNATIVE_DATE_TIME_FORMAT))
    },
    {
      type: 'TEXT',
      header: 'modifier',
      accessor: 'createdBy.name',
      filter: UserFilterV2
    },
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.APPLICATION_STATUS,
      accessor: 'statusKey'
    },
    {
      header: 'remarks',
      type: 'DICTIONARY_MULTI_SELECT',
      accessor: 'descriptionKey',
      dictionaryName: DomainDictionaryEnum.APPLICATION_CHANGE
    }
  ]);

  return { columns, mappedFilterFields };
}

export default useApplicationHistoryColumns;
