import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderExtendedLite } from '@ibtm/domain';
import { AxiosResponse } from 'axios';

import { ForwardTableState, TableLayout, useRouter, useTableRowActions } from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';

import { API } from '@libs/domain/api';
import { DomainUIElementEnum } from '@libs/domain/config';
import { parseDisposablePermitsPayload, useDisposablePermitsTable } from '@libs/domain/foreign-permission';

function DisposablePermitsTablePage() {
  const [t] = useTranslation();
  const [content, setContent] = useState<FolderExtendedLite[]>([]);
  const tableProps = useDisposablePermitsTable();
  const { routes } = useRouter();

  const { checkIsElementVisible } = useElementVisibility();
  const { renderTableActions } = useTableRowActions<FolderExtendedLite>([
    {
      id: DomainUIElementEnum.PERMITS_DISPOSABLE_SHOW_DETAILS_VIEW,
      label: 'foreignPermits:tabs.disposablePermits.action.details',
      icon: 'ArrowIcon',
      link: ({ original }) => routes.disposablePermitDetails(original?.id)
    }
  ]);

  const requestToXlsx = async (params): Promise<AxiosResponse> => {
    const data = await API.folder.getFoldersLitePage(parseDisposablePermitsPayload(params));

    return {
      ...data,
      data: {
        content
      }
    };
  };

  return checkIsElementVisible(DomainUIElementEnum.PERMITS_DISPOSABLE_VIEW) ? (
    <TableLayout
      {...tableProps}
      pageTitle={t('foreignPermits:tabs.disposablePermits.title')}
      xlsxDownload={{
        apiRequest: params => requestToXlsx(params),
        fileName: t('foreignPermits:tabs.disposablePermits.title'),
        pathToXLSXTranslation: 'foreignPermits:tabs.disposablePermits.fields'
      }}
      rowActions={renderTableActions([DomainUIElementEnum.PERMITS_DISPOSABLE_SHOW_DETAILS_VIEW])}
    >
      <ForwardTableState setState={setContent} tableStatePath="data" />
    </TableLayout>
  ) : null;
}

export default DisposablePermitsTablePage;
