import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

const cancelSuspension = (params: { suspensionId: string; version: number }) => {
  const { suspensionId, version } = params;
  return API.suspensions.cancelSuspension(suspensionId, { version });
};

export const useSuspensionCancelMutation = () => useMutation(cancelSuspension);
