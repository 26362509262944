import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { KeyType, partialTranslate } from '@libs/common';
import { CamelCasePath, createTableColumn, ISingleColumn, ITableLayoutColumn } from '@libs/common/v2';
import { capitalizeFirstLetter } from '@libs/common/v2/utils';

import useDictionaryTranslations from '@libs/dictionary/hooks/useDictionaryTranslations';

interface IParams {
  isMutableTable?: boolean;
  hasErrorTooltip?: boolean;
  pathToTranslate: KeyType;
}
/**
 * Hook do stworzenia kolumn do tabeli. W nim deklarujemy typy wszystkich kolumn dla danego modelu
 * @type T - model Frontend/Backend.
 * @type D - opcjonalny model Backend. Wykorzystywany przy mappowaniu wartości do pliku xlsx
 * @konwencja typ `T`to model zawierający definicje danych do tabeli. Może być modelem backend'u,
 * jeżeli na nim jest oparta implementacja gui, lub jeżeli dane są parsowane i dostosowane go modelu
 * frontend'owego, który jest rozbieżny z modelem backend wtedy do typu `T` przypisujemy model frontend,
 * natomiast do typu `D` przypisujemy model backend. W ten sposób przy mappowaniu wartości do pliku xlsx
 * mamy dostęp do atrybutów z modelu backend.
 *
 */
function useCreateColumns<T extends Record<string, any>, D extends Record<string, any> = T>({
  pathToTranslate,
  isMutableTable,
  hasErrorTooltip = isMutableTable
}: IParams) {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();
  const getLabel = partialTranslate(pathToTranslate);
  /**
   * @zastosowanie Funkcja której używamy do renderowania tabel w których mamy do wyrenderowania tablicę.
   *               Jako argument funkcji dostajemy mapper, który przekazujemy dalej, zapewnia to odpowiednie
   *               wyrenderowanie tabeli gdy przekazane tablice nie są tych samych długości.
   */
  const createColumnsWithDataAccess = ({
    columns,
    mapper
  }: {
    mapper: Record<string, number>;
    columns: ISingleColumn<T, D>[];
  }): ITableLayoutColumn<T, D>[] =>
    columns.reduce((acc: ITableLayoutColumn<T, D>[], column) => {
      const createdColumn = createTableColumn(column, isMutableTable, getLabel, translate, t, mapper, hasErrorTooltip);
      if (Array.isArray(createdColumn)) {
        return [...acc, ...createdColumn];
      }
      return [...acc, createdColumn];
    }, []);
  /**
   * @zastosowanie Funkcja do tworzenia kolumn do tabeli.
   */
  const createColumns = (
    columns: ISingleColumn<T, D>[],
    visibleColumnIds?: Array<CamelCasePath<T>>
  ): ITableLayoutColumn<T, D>[] => {
    const parsedColumns: ISingleColumn<T, D>[] = columns.map(column => ({
      ...column,
      id:
        _.isString(column.accessor) && !Object.hasOwnProperty.call(column, 'id')
          ? (column.accessor.split('.').reduce(
              // eslint-disable-next-line no-return-assign
              (prev, curr, index) => (prev += index ? capitalizeFirstLetter(curr) : curr),
              ''
            ) as CamelCasePath<T>)
          : column.id
    }));
    const visibleColumns = visibleColumnIds
      ? parsedColumns.filter(({ id }) => visibleColumnIds.includes(id))
      : parsedColumns;
    return visibleColumns.reduce((acc, column) => {
      const createdColumns = createTableColumn(column, isMutableTable, getLabel, translate, t, null, hasErrorTooltip);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return [...acc, createdColumns];
    }, []);
  };

  return { createColumnsWithDataAccess, createColumns };
}

export default useCreateColumns;
