export enum OrganizationQueryKeysEnum {
  ORGANIZATION_STRUCTURE = 'ORGANIZATION_STRUCTURE',
  ORGANIZATION_UNIT_DETAILS = 'ORGANIZATION_UNIT_DETAILS',
  MY_ORGANIZATION_UNIT_LIST = 'MY_ORGANIZATION_UNIT_LIST',
  ORGANIZATION_UNIT_LIST = 'ORGANIZATION_UNIT_LIST',
  ORGANIZATION_UNIT_MEMBER_LIST = 'ORGANIZATION_UNIT_MEMBER_LIST',
  SEARCH_GROUP_LIST = 'SEARCH_GROUP_LIST',
  ORGANIZATION_FILTER_LIST = 'ORGANIZATION_FILTER_LIST',
  ORGANIZATION_UNIT_USER_LIST = 'ORGANIZATION_UNIT_USER_LIST',
  MANAGERS_LIST = 'MANAGERS_LIST'
}
