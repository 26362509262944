import { useCallback } from 'react';

import { FormV2ContextState } from '@libs/common/v2';

import { ResourceObjectPoolClient } from '../../model';

export enum ResourceTypeFieldName {
  NUMBER_FROM = 'NUMBER_FROM',
  NUMBER_TO = 'NUMBER_TO',
  AMOUNT = 'AMOUNT'
}

const useResourceTransformTypeFieldsChange = () => {
  const handleChange = useCallback(
    (row: ResourceObjectPoolClient, rowContext: FormV2ContextState, value: number, input: ResourceTypeFieldName) => {
      const { getValues, setValue: setValueContext } = rowContext;
      const setValue = (name, value) => setValueContext?.(`${row?.id}.${name}`, value);
      const currentValues = getValues?.()?.[row?.id] as ResourceObjectPoolClient;

      const dataNumberTo = row?.numberTo;

      const currentAmount = currentValues?.amount;
      const currentNumberFrom = currentValues?.numberFrom;

      const correctValue = getCorrectValue(
        value,
        dataNumberTo,
        row?.numberFrom,
        currentValues?.numberTo,
        currentNumberFrom,
        input
      );
      switch (input) {
        case ResourceTypeFieldName.NUMBER_FROM: {
          handleNumberFromFieldChange(correctValue, currentAmount, dataNumberTo, setValue);
          break;
        }

        case ResourceTypeFieldName.NUMBER_TO: {
          handleNumberToFieldChange(correctValue, currentNumberFrom, setValue);
          break;
        }

        case ResourceTypeFieldName.AMOUNT: {
          handleAmountFieldChange(correctValue, currentNumberFrom, setValue);
          break;
        }
        default: {
          break;
        }
      }
    },
    []
  );
  return { handleChange };
};

const getCorrectValue = (
  value: number,
  dataNumberTo: number,
  dataNumberFrom: number,
  currentNumberTo: number,
  currentNumberFrom: number,
  input: ResourceTypeFieldName
): number => {
  switch (input) {
    case ResourceTypeFieldName.NUMBER_FROM: {
      if (value > currentNumberTo) {
        return currentNumberTo;
      }
      if (value < dataNumberFrom) {
        return dataNumberFrom;
      }
      return value;
    }
    case ResourceTypeFieldName.NUMBER_TO: {
      if (value < currentNumberFrom) {
        return currentNumberFrom;
      }
      if (value > dataNumberTo) {
        return dataNumberTo;
      }
      return value;
    }
    case ResourceTypeFieldName.AMOUNT: {
      if (value < 1) {
        return 1;
      }
      if (currentNumberFrom + value - 1 > dataNumberTo) {
        return dataNumberTo - currentNumberFrom;
      }
      return value;
    }
    default: {
      return value;
    }
  }
};

const handleNumberFromFieldChange = (
  numberFrom: number,
  currentAmount: number,
  dataNumberTo: number,
  setValue: (field, value) => void
) => {
  const numberTo = numberFrom + currentAmount > dataNumberTo ? dataNumberTo : numberFrom + currentAmount;
  const amount = numberTo > dataNumberTo ? dataNumberTo - numberFrom + 1 : numberTo - numberFrom + 1;

  setValue('numberFrom', numberFrom);
  setValue('numberTo', numberTo);
  setValue('amount', amount);
};

const handleNumberToFieldChange = (numberTo: number, currentNumberFrom: number, setValue: (field, value) => void) => {
  const amount = numberTo - currentNumberFrom + 1 < 0 ? 1 : numberTo - currentNumberFrom + 1;

  setValue('numberTo', numberTo);
  setValue('amount', amount);
};

const handleAmountFieldChange = (amount: number, currentNumberFrom: number, setValue: (field, value) => void) => {
  const numberTo = currentNumberFrom + amount - 1;

  setValue('amount', amount);
  setValue('numberTo', numberTo);
};

export default useResourceTransformTypeFieldsChange;
