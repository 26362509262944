import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DriverDetails } from '@ibtm/domain';

import { CamelCasePath } from '@libs/common/v2';

import { DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import { DriverActionButtonsEnum, DriversColumnsEnum, DriversTable } from '@libs/domain/drivers';
import { FolderDetailsParams } from '@libs/domain/folder';

const VISIBLE_COLUMNS: Array<CamelCasePath<DriverDetails>> = [
  DriversColumnsEnum.NAME,
  DriversColumnsEnum.SURNAME,
  DriversColumnsEnum.BIRTHDATE,
  DriversColumnsEnum.BIRTHPLACE,
  DriversColumnsEnum.CITIZENSHIP,
  DriversColumnsEnum.INSURANCE_PAYER_NIP,
  DriversColumnsEnum.EMPLOYMENT_DOCUMENT,
  DriversColumnsEnum.ID_TYPE,
  DriversColumnsEnum.ID_SERIES_NUMBER,
  DriversColumnsEnum.DRIVER_LICENSE_SERIES_NUMBER,
  DriversColumnsEnum.DRIVER_CERTIFICATE_NUMBER,
  DriversColumnsEnum.CURRENT_CERTIFICATE_APPLICATION_NUMBER,
  DriversColumnsEnum.APPLICATION_TYPE,
  DriversColumnsEnum.ARCHIVE_NUMBER,
  DriversColumnsEnum.DRIVER_CERTIFICATE_FORM_NUMBER,
  DriversColumnsEnum.DRIVER_CERTIFICATE_STATUS,
  DriversColumnsEnum.DRIVER_CERTIFICATE_CANCELLATION_DATE,
  DriversColumnsEnum.DRIVER_CERTIFICATE_CANCELATION_REASON
];

function DriversTab() {
  const { folderId } = useParams<FolderDetailsParams>();
  const { isClientPortal, isOperatorPortal } = useDomainConfigContext();

  const visibleActionButtons = useMemo(
    () => ({
      view: [],
      tableRow: {
        visible: [DriverActionButtonsEnum.PREVIEW],
        more: isOperatorPortal
          ? [
              DriverActionButtonsEnum.EXPIRE,
              DriverActionButtonsEnum.ACTIVATE,
              DriverActionButtonsEnum.GENERATE_UP_TO_DATE,
              DriverActionButtonsEnum.GENERATE_ISSUED,
              DriverActionButtonsEnum.GO_TO_PROCEEDING
            ]
          : []
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      <DriversTable
        visibleColumns={VISIBLE_COLUMNS}
        visibleActionButtons={visibleActionButtons}
        additionalData={{
          folderId
        }}
        queryParams={isClientPortal ? { folderIdEq: folderId } : { folderIdIn: [folderId] }}
        permissionsKeysObject={{
          PREVIEW: DomainUIElementEnum.FOLDER_DRIVER_DETAILS_BUTTON,
          EXPIRE: DomainUIElementEnum.FOLDER_DRIVER_EXPIRE_BUTTON,
          ACTIVATE: DomainUIElementEnum.FOLDER_DRIVER_ACTIVATE_BUTTON,
          GENERATE_UP_TO_DATE: DomainUIElementEnum.FOLDER_DRIVER_GENERATE_UP_TO_DATE_REPORT_BUTTON,
          GENERATE_ISSUED: DomainUIElementEnum.FOLDER_DRIVER_GENERATE_ISSUED_REPORT_BUTTON,
          GO_TO_PROCEEDING: DomainUIElementEnum.DRIVER_GO_TO_PROCEEDING
        }}
        isCollapsable
        isSection
      />
    </div>
  );
}

export default DriversTab;
