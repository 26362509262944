import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  DictionarySelectField,
  GridLayout,
  InputMode,
  Section,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { convertDateToDateFormat } from '@libs/common/v2/utils';

import { DomainDictionaryEntry, DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';

import { RoadTransportQueryKeysEnum } from '../../../api';
import { IProceedingDetailsClient, KreptdProceedingDetailsParams } from '../../../models';
import { isFieldVisible } from '../../../utils';

interface IProps {
  hiddenFields?: Array<
    | 'decisionType'
    | 'decisionDate'
    | 'decisionNumber'
    | 'decisionDeliveryDate'
    | 'decisionReviewApplicationDeliveryDate'
    | 'decisionWsaComplaintDeliveryDate'
    | 'decisionPermissionSuspensionFrom'
    | 'decisionPermissionSuspensionTo'
    | 'decisionFinalDate'
  >;
}

function DecisionSection({ hiddenFields = [] }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const getTranslation = partialTranslate('roadTransport:fields');
  const { proceedingId } = useParams<KreptdProceedingDetailsParams>();
  const proceedingDetails: IProceedingDetailsClient = queryCache.getQueryData([
    RoadTransportQueryKeysEnum.PROCEEDING_DETAILS,
    proceedingId
  ]);

  const decisionTypeOptionsFilter = option => {
    switch (proceedingDetails?.category) {
      case DomainDictionaryEntry.PROCEEDING_TYPE_KREPTD.DISCHARGE_AND_SUSPENSION_OF_CERTIFICATES:
        return [
          DomainDictionaryEntry.PROCEEDING_STATUS_KREPTD.DECISION_OF_DISCHARGE_WITHDRAWAL,
          DomainDictionaryEntry.PROCEEDING_STATUS_KREPTD.REMIT
        ].includes(option.value);
      case DomainDictionaryEntry.PROCEEDING_TYPE_KREPTD.GOOD_REPUTATION:
        return [
          DomainDictionaryEntry.PROCEEDING_STATUS_KREPTD.DECISION_TO_DOWNGRADE_GOOD_REPUTATION,
          DomainDictionaryEntry.PROCEEDING_STATUS_KREPTD.DECISION_TO_KEEP_GOOD_REPUTATION,
          DomainDictionaryEntry.PROCEEDING_STATUS_KREPTD.REMIT
        ].includes(option.value);
      case DomainDictionaryEntry.PROCEEDING_TYPE_KREPTD.PERMIT_SUSPENSION:
        return [
          DomainDictionaryEntry.PROCEEDING_STATUS_KREPTD.DECISION_TO_SUSPEND_PERMIT,
          DomainDictionaryEntry.PROCEEDING_STATUS_KREPTD.REMIT
        ].includes(option.value);
      case DomainDictionaryEntry.PROCEEDING_TYPE_KREPTD.DECLARATION_OF_INABILITY:
        return [
          DomainDictionaryEntry.PROCEEDING_STATUS_KREPTD.DECISION_ON_DECLARATION_OF_INABILITY,
          DomainDictionaryEntry.PROCEEDING_STATUS_KREPTD.REMIT
        ].includes(option.value);
      case DomainDictionaryEntry.PROCEEDING_TYPE_KREPTD.RESTORE_OF_ABILITIES:
        return [
          DomainDictionaryEntry.PROCEEDING_STATUS_KREPTD.DECISION_TO_RESTORE_OPERABILITY,
          DomainDictionaryEntry.PROCEEDING_STATUS_KREPTD.DECISION_TO_REFUSE_OPERABILITY,
          DomainDictionaryEntry.PROCEEDING_STATUS_KREPTD.REMIT
        ].includes(option.value);
      case DomainDictionaryEntry.PROCEEDING_TYPE_KREPTD.IMPOSING_PENALTY:
        return [
          DomainDictionaryEntry.PROCEEDING_STATUS_KREPTD.DECISION_TO_IMPOSE_PENALTY,
          DomainDictionaryEntry.PROCEEDING_STATUS_KREPTD.DECISION_NOT_TO_IMPOSE_PENALTY,
          DomainDictionaryEntry.PROCEEDING_STATUS_KREPTD.REMIT
        ].includes(option.value);
      default:
        return false;
    }
  };

  return (
    <Section
      title={t('roadTransport:sections.decision')}
      actionKey={DomainUIElementEnum.ROAD_TRANSPORT_PROCEEDING_DETAILS_DECISION_SECTION}
      isCollapsable
    >
      <GridLayout itemProps={{ xs: 6 }}>
        {isFieldVisible('decisionType', hiddenFields) && (
          <DictionarySelectField
            name={typedNameV2<IProceedingDetailsClient>('decisionType')}
            label={getTranslation('decisionType')}
            dictionaryName={DomainDictionaryEnum.PROCEEDING_STATUS_KREPTD}
            optionsFilter={decisionTypeOptionsFilter}
            stringValue
          />
        )}

        {isFieldVisible('decisionDate', hiddenFields) && (
          <DatepickerField
            name={typedNameV2<IProceedingDetailsClient>('decisionDate')}
            label={getTranslation('decisionDate')}
            viewModeDateParser={convertDateToDateFormat}
          />
        )}

        {isFieldVisible('decisionNumber', hiddenFields) && (
          <TextInputField
            name={typedNameV2<IProceedingDetailsClient>('decisionNumber')}
            label={getTranslation('decisionNumber')}
          />
        )}

        {isFieldVisible('decisionDeliveryDate', hiddenFields) && (
          <DatepickerField
            name={typedNameV2<IProceedingDetailsClient>('decisionDeliveryDate')}
            label={getTranslation('decisionDeliveryDate')}
            viewModeDateParser={convertDateToDateFormat}
          />
        )}

        {isFieldVisible('decisionReviewApplicationDeliveryDate', hiddenFields) && (
          <DatepickerField
            name={typedNameV2<IProceedingDetailsClient>('decisionReviewApplicationDeliveryDate')}
            label={getTranslation('decisionReviewApplicationDeliveryDate')}
            viewModeDateParser={convertDateToDateFormat}
          />
        )}

        {isFieldVisible('decisionWsaComplaintDeliveryDate', hiddenFields) && (
          <DatepickerField
            name={typedNameV2<IProceedingDetailsClient>('decisionWsaComplaintDeliveryDate')}
            label={getTranslation('decisionWsaComplaintDeliveryDate')}
            viewModeDateParser={convertDateToDateFormat}
          />
        )}

        {isFieldVisible('decisionPermissionSuspensionFrom', hiddenFields) && (
          <DatepickerField
            name={typedNameV2<IProceedingDetailsClient>('decisionPermissionSuspensionFrom')}
            label={getTranslation('decisionPermissionSuspensionFrom')}
            viewModeDateParser={convertDateToDateFormat}
          />
        )}

        {isFieldVisible('decisionPermissionSuspensionTo', hiddenFields) && (
          <DatepickerField
            name={typedNameV2<IProceedingDetailsClient>('decisionPermissionSuspensionTo')}
            label={getTranslation('decisionPermissionSuspensionTo')}
            viewModeDateParser={convertDateToDateFormat}
          />
        )}

        {isFieldVisible('decisionFinalDate', hiddenFields) && (
          <DatepickerField
            name={typedNameV2<IProceedingDetailsClient>('decisionFinalDate')}
            label={getTranslation('decisionFinalDate')}
            viewModeDateParser={convertDateToDateFormat}
            inputMode={InputMode.VIEW}
          />
        )}
      </GridLayout>
    </Section>
  );
}

export default DecisionSection;
