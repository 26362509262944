import React, { useMemo } from 'react';
import { Resolver } from 'react-hook-form';
import { PaginatedQueryResult, QueryResult } from 'react-query';
import { Row } from 'react-table';
import _ from 'lodash';

import { KeyType } from '@libs/common';
import {
  IPaginatedModel,
  ISingleColumn,
  ITableAdapter,
  ITableBodyProps,
  ITableContext,
  TMutableTableFormContextProvider
} from '@libs/common/v2';

import { ITableHeadColumnsProps } from './ui/TableHeadColumns';
import { ITablePaginationProps } from './ui/TablePagination';
import Table from './Table';
import TableContextProvider from './TableContextProvider';

interface IProps<T extends Record<string, any>, K extends Record<string, any> = T> {
  table: ITableAdapter<T, K>;
  refetch: () => void;
  restQueryResult:
    | Omit<PaginatedQueryResult<IPaginatedModel<T>>, 'refetch' | 'isError'>
    | Omit<QueryResult<T[], unknown>, 'refetch' | 'isError'>;
  actionsColumnWidth?: number;
  selectionColumnWidth?: number;
  onRowEditCreateConfirm?: (
    row: T,
    context: {
      isCreated: boolean;
      isEdited: boolean;
      tableContext: ITableContext<T, K>;
      tableInstance: ITableAdapter<T, K>;
      formContext: TMutableTableFormContextProvider;
      onSuccess: () => void;
      onError: () => void;
    }
  ) => Promise<any> | void;
  newRowScheme?: T;
  /**
   * Maksymalna liczba dodatkowych kolumn jakie mogą zostać stworzone
   */
  maxInsertedColumnNumber?: number;
  /**
   * Obiekt z kolumnami do dodania i ścieżką do tłumaczenia header'a
   */
  columnsToInsert?: {
    pathToTranslate: KeyType;
    columns: ISingleColumn<T, K>[];
  };
  editFormYupResolverSchema?: Resolver<Record<string, any>, Record<string, any>>;
  headerContent: React.ReactNode;
  scrollbarOffset?: number;
  bodyProps?: ITableBodyProps<T, K>;
  paginationProps?: ITablePaginationProps;
  headerRowProps?: ITableHeadColumnsProps;
  /**
   * Prop children służy do przekazania np. dialogu w którym chcemy mieć dostęp do contextu tabeli
   */
  children?: React.ReactNode;
  className?: string;
  checkDisabled?: (table?: ITableAdapter<T, K>, row?: Row<T>) => boolean;
  initiallyChecked?: (table?: ITableAdapter<T, K>, row?: Row<T>) => boolean;
  isLoading?: boolean;
  isError?: boolean;
  isTableMutable?: boolean;
  isFetching?: boolean;
  isTableGrey?: boolean;
  isFilterEnabled?: boolean;
  isSectionFullPage?: boolean;
  /**
   * Props mówi czy tabela jest renderowana w komponencie Page z tabami
   */
  isPageTabContent?: boolean;
  useOnlyCircleLoader?: boolean;
  isHiddenSelectRowHeader?: boolean;
  headerRef?: React.MutableRefObject<HTMLDivElement>;
}

function TableWithContext<T extends Record<string, any>, K extends Record<string, any> = T>({
  table,
  selectionColumnWidth,
  ...restProps
}: IProps<T, K>) {
  const tableSelectionColumnWidth = useMemo(() => {
    if (selectionColumnWidth) {
      return _.isEmpty(table.state.selectedRowIds) ? 48 : selectionColumnWidth;
    }
    return _.isEmpty(table.state.selectedRowIds) ? 48 : 96;
  }, [table.state, selectionColumnWidth]);

  return (
    <TableContextProvider
      {...restProps}
      table={table}
      selectionColumnWidth={tableSelectionColumnWidth}
      tableComponent={(forwardTableProps: Partial<IProps<T, K>>) => <Table {...forwardTableProps} />}
    />
  );
}

export default TableWithContext;
