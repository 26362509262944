import { useEffect, useState } from 'react';

export const useGetScrollPosition = () => {
  const [scrollXStartReached, setScrollXStartReached] = useState(false);
  const [scrollXEndReached, setScrollXEndReached] = useState(false);
  const [scrollElement, setScrollElement] = useState<React.ElementRef<'div'>>(null);
  const [innerScrollElement, setInnerScrollElement] = useState<React.ElementRef<'div'>>(null);

  useEffect(() => {
    if (!scrollElement || !innerScrollElement) {
      return;
    }
    const handleScrollX = () => {
      const scrollElementBoundingClientRect = scrollElement.getBoundingClientRect();
      const innerElementBoundingClientRect = innerScrollElement.getBoundingClientRect();

      const widthDifference = Math.floor(scrollElementBoundingClientRect.width - innerElementBoundingClientRect.width);
      const xDifference = Math.floor(innerElementBoundingClientRect.x - scrollElementBoundingClientRect.x);

      const reachedScrollEnd = xDifference <= widthDifference;
      const reachedScrollStart = innerElementBoundingClientRect.x === scrollElementBoundingClientRect.x;

      setScrollXStartReached(reachedScrollStart);
      setScrollXEndReached(reachedScrollEnd);
    };
    scrollElement.addEventListener('scroll', handleScrollX);
    handleScrollX();
    // eslint-disable-next-line consistent-return
    return () => {
      scrollElement.removeEventListener('scroll', handleScrollX);
    };
  }, [scrollElement, innerScrollElement]);

  return {
    scrollXStartReached,
    scrollXEndReached,
    refs: {
      setScrollElementRef: setScrollElement,
      setInnerScrollElementRef: setInnerScrollElement
    }
  };
};
