import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import * as Yup from 'yup';

function useSuspensionDetailsValidation(isCaseNumberError: boolean) {
  const [t] = useTranslation();

  const validationSchema = useMemo(
    () =>
      Yup.object({
        caseNumber: isCaseNumberError && Yup.string().required(),
        dateFrom: Yup.string().required().typeError(t('validation:required')),
        dateTo: Yup.date()
          .typeError(t('validation:required'))
          .required()
          .test('isDateBefore', t('suspensions:details.validation.to'), function checkIsDateBefore(dateTo) {
            const dateFrom = moment(this.options['parent']['dateFrom']);
            return dateFrom.isSameOrBefore(moment(dateTo));
          }),
        dateOfIssue: Yup.string().required().typeError(t('validation:required'))
      }),
    [t, isCaseNumberError]
  );

  return { validationSchema };
}

export default useSuspensionDetailsValidation;
