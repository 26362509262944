import { ResourceObjectRollbackReturnRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function rollbackReturn(formData: ResourceObjectRollbackReturnRequest) {
  return API.resourceObjects.rollbackReturnResourceObject(formData, {
    ...endpointsConfig.rollbackReturnResourceObject
  });
}

function useRollbackReturnsMutation() {
  return useMutation(rollbackReturn);
}

export default useRollbackReturnsMutation;
