import { usePaginatedQuery } from 'react-query';
import { ResourceObjectPoolSearchFilter } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { ResourceObjectPoolClient } from '../../../model';
import { resourceObjectPoolsParser } from '../../../utils';
import { endpointsConfig } from '../../endpoints-config';
import { ResourceQueryKeysEnum } from '..';

const getResourceObjectPools = async (params: ResourceObjectPoolSearchFilter) => {
  const { data } = await API.resourceObjectPools.getResourceObjectPoolSnapshot(params, {
    ...endpointsConfig.getResourceObjectPoolSnapshot
  });

  return {
    ...data,
    content: resourceObjectPoolsParser(data.content)
  };
};

export default function useResourceObjectPoolsQuery(params: ResourceObjectPoolSearchFilter) {
  return usePaginatedQuery<{ content: ResourceObjectPoolClient[] }>(
    [ResourceQueryKeysEnum.RESOURCE_OBJECT_POOLS, params],
    () => getResourceObjectPools(params)
  );
}
