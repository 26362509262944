import DriverContext from './DriverContext';

interface IProps {
  children: React.ReactNode;
  values: {
    application: {
      id: string;
      folderId: string;
      subjectNip: string;
      applicationTypeKey?: string;
    };
  };
}

function DriverProvider({ children, values }: IProps) {
  return <DriverContext.Provider value={values}>{children}</DriverContext.Provider>;
}

export default DriverProvider;
