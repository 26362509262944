import { FieldPath, UseFormSetError } from 'react-hook-form';
import { Violation } from '@avispon/user/dist/models';

import { AxiosErrorResponseType } from '@libs/common/v2/models';

import { useDictionaryTranslations } from '@libs/dictionary/hooks';
import { DictionaryEntryNameEnum } from '@libs/dictionary/models';

const useAsyncErrorsHandlerV2 = <T>() => {
  const { translate } = useDictionaryTranslations();

  const handleAsyncErrorsV2 = (error: AxiosErrorResponseType<T>, setError: UseFormSetError<T>, shouldFocus = false) => {
    const violations = error?.response?.data?.violations;
    if (violations && violations.length) {
      violations.forEach(({ field, message }: Violation) => {
        const messageTranslated = translate('ERROR' as DictionaryEntryNameEnum, message, message);
        setError(field as FieldPath<T>, { message: messageTranslated }, { shouldFocus });
      });
    }
  };

  return { handleAsyncErrorsV2 };
};

export default useAsyncErrorsHandlerV2;
