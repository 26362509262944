import { useCallback } from 'react';

import { useDialog } from '@libs/common/v2';

import { ApplicationsFromArchiveDialog } from '../../components/common';

function useSendApplicationsFromArchiveDialog() {
  const { openDialog } = useDialog();

  const showDialog = useCallback(
    (ids: string[]) => {
      openDialog(({ closeDialog }) => <ApplicationsFromArchiveDialog ids={ids} closeDialog={closeDialog} />);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog]
  );

  const openSendApplicationsFromArchiveDialog = (ids: string[]) => {
    showDialog(ids);
  };

  return {
    openSendApplicationsFromArchiveDialog
  };
}

export default useSendApplicationsFromArchiveDialog;
