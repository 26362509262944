import { ResourceTypeSearchFilter } from '@ibtm/domain';

import { SelectOption, TableFilter, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { TAmountRange } from '@libs/common/v2/utils';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import { IResourceTypesClient } from '../../model';
import useFilteredDictionary from '../useFilteredDictionary';

const useResourceTypesColumns = () => {
  const { entries: filteredFormTypeDictionaryEntries } = useFilteredDictionary(
    DomainDictionaryEnum.RESOURCE_FORM_TYPE,
    [DomainDictionaryEntry.RESOURCE_FORM_TYPE.S_CERTYFICATE]
  );

  const { createColumns } = useCreateColumns<IResourceTypesClient>({
    pathToTranslate: 'resource:fields'
  });

  const mappedSortFields: TableSortMapper<IResourceTypesClient> = {
    type: 'typeKey',
    name: 'nameKey'
  };

  const mappedFilterFields: TableFilterMapper<IResourceTypesClient, ResourceTypeSearchFilter> = {
    type: (types: SelectOption<string>[]) => ({
      typeKeyFilter: {
        values: types.map(type => type.value)
      }
    }),
    name: (names: SelectOption<string>[]) => ({ nameKeyIn: names.map(name => name.value) }),
    year: (yearRange: TAmountRange) => ({
      yearGreaterThanOrEqual: yearRange.min,
      yearLessThanOrEqual: yearRange.max
    })
  };

  const columns = createColumns([
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_TYPE,
      header: 'formType',
      accessor: 'type',
      filter: props => (
        <TableFilter.DictionaryMultiSelect
          {...props}
          dictionary={DomainDictionaryEnum.RESOURCE_FORM_TYPE}
          options={filteredFormTypeDictionaryEntries}
        />
      ),
      width: 200
    },
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_NAME,
      accessor: 'name',
      width: 300
    },
    {
      type: 'NUMBER_RANGE',
      accessor: 'year'
    }
  ]);

  return { columns, mappedFilterFields, mappedSortFields };
};

export default useResourceTypesColumns;
