import { usePaginatedQuery } from 'react-query';
import { PageableSearchFilter, SubjectLite } from '@ibtm/client-domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

import UserEntrepreneursQueryKeysEnum from './UserEntrepreneursQueryKeysEnum';

export const getUserSubjectsList = async (params: PageableSearchFilter) => {
  const { data } = await API.client.userSubjects.getUserSubjects(params, {
    ...endpointsConfig.getUserSubjects
  });

  return data;
};

function useGetUserSubjectsListQuery(params: PageableSearchFilter) {
  return usePaginatedQuery<IPaginatedModel<SubjectLite>>(
    [UserEntrepreneursQueryKeysEnum.USER_SUBJECTS_LIST, params],
    () => getUserSubjectsList(params)
  );
}

export default useGetUserSubjectsListQuery;
