import { useContext } from 'react';
import { usePaginatedQuery } from 'react-query';
import { ForeignPermissionSearchFilter, ForeignPermissionSnapshot, ForeignPermissionsSnapshotPage } from '@ibtm/domain';

import { IPaginatedModel, YearFilterContext } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { parseFolderSingleAuthorizationPayload } from '../../utils';
import { endpointsConfig } from '../endpoints-config';
import { FolderQueryKeysEnum } from '..';

interface IProps {
  ids: Array<string>;
  maxYear: number;
  statusKeyIn: Array<string>;
}

const getSingleAuthorizations = async (_, { ids, maxYear, statusKeyIn, ...params }: IProps) => {
  const { data } = await API.foreignPermissions.getForeignPermissionSnapshotPage(
    parseFolderSingleAuthorizationPayload({ ...params, ids, maxYear, statusKeyIn }),
    { ...endpointsConfig.getForeignPermissionSnapshotPage }
  );
  return data;
};
const getSingleAuthorizationsClient = async (_, { ids, maxYear, statusKeyIn, ...params }: IProps) => {
  const { data } = await API.client.foreignPermissions.getForeignPermissionSnapshotPageForFolder(
    '',
    '',
    parseFolderSingleAuthorizationPayload({ ...params, ids, maxYear, statusKeyIn }),
    { ...endpointsConfig.getForeignPermissionSnapshotPage }
  );
  return data as ForeignPermissionsSnapshotPage;
};

export default function useSingleAuthorizationsQuery(params: ForeignPermissionSearchFilter) {
  const context = useContext(YearFilterContext);
  const { getQuery } = useGetApiQueryByPerspective();
  return usePaginatedQuery<IPaginatedModel<ForeignPermissionSnapshot>>(
    [FolderQueryKeysEnum.SINGLE_AUTHORIZATIONS, { ...params, resourceTypeYearEquals: context?.maxYear }],
    getQuery(getSingleAuthorizationsClient, getSingleAuthorizations)
  );
}
