import { ProceedingSnapshot } from '@ibtm/domain';

import { ProceedingSnapshotClient } from '../model';

export const parseProceedingsList = (proceedings: ProceedingSnapshot[]): ProceedingSnapshotClient[] =>
  proceedings.map(proceeding => ({
    id: proceeding.id,
    folderId: proceeding.folder?.id,
    enterpreneur: proceeding.subjectName,
    NIP: proceeding.subjectNip,
    executive: proceeding.assignedTo?.name,
    certificateNumber: null,
    conductingPerson: {
      id: proceeding.assignedTo?.id,
      name: proceeding.assignedTo?.name
    },
    assignedTo: proceeding.assignedTo,
    caseNumber: proceeding.caseNumber,
    proceedingType: proceeding.typeKey,
    proceedingCategory: proceeding.categoryKey,
    status: proceeding.statusKey,
    created: proceeding.created,
    modified: proceeding.modified,
    proceedingNumber: proceeding.number,
    isDecision: proceeding.isDecision,
    isNSAComplaint: proceeding.isNSAComplaint,
    isWSAComplaint: proceeding.isWSAComplaint,
    isReviewed: proceeding.isReviewed,
    isSecondTierDecision: proceeding.isSecondTierDecision,
    version: proceeding.version,
    typeKey: proceeding.typeKey,
    number: proceeding.number
  }));
