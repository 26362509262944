import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ResourceObjectExternalReceiveCreateRequest, ResourceTypeSnapshotPage } from '@ibtm/domain';
import { AxiosResponse } from 'axios';
import * as Yup from 'yup';

import { i18n, partialTranslate } from '@libs/common';
import {
  Dialog,
  FormV2Context,
  GridLayout,
  NUMBER_OF_AUTOCOMPLETE_RESULTS,
  NumberInputField,
  typedNameV2,
  WarningInformation
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useContextSelector, useDictionaryTranslations } from '@libs/dictionary';

import { API } from '@libs/domain/api';
import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import { ResourceQueryKeysEnum, useExternalReceivesMutation } from '../../../api';
import { IExternalReceivesFormFields } from '../../../model';
import { handlePoolsChange } from '../../../utils';
import { AutocompleteLazyFetchSelectField } from '../common';

const TW_RESOURCE_TYPE_MAX_AMOUNT = 9999999;

const getLabel = partialTranslate('resource:fields');

interface IProps {
  closeDialog: () => void;
}

function ExternalReceivesForm({ closeDialog }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();
  const { showSnackbar } = useSnackbar();
  const { mutate: externalReceives, isLoading } = useExternalReceivesMutation();

  const dictionaryData = useContextSelector(({ context }) => context.context.data.dictionary);
  const resourceFormNameDictionaryEntries = useMemo(() => {
    return dictionaryData[DomainDictionaryEnum.RESOURCE_FORM_NAME]?.entries || [];
  }, [dictionaryData]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        formType: Yup.object().nullable().required(),
        numberFrom: Yup.number()
          .transform(value => (Number.isNaN(value) ? undefined : value))
          .test(
            'lessThan',
            i18n.t('validation:lessThan', { less: TW_RESOURCE_TYPE_MAX_AMOUNT + 1 }),
            function checkIsMoreThan(value) {
              // eslint-disable-next-line react/no-this-in-sfc
              const { formType } = this.parent;

              // dodatkowa walidacja ze wzgledu na problemy z przyjmowaniem przez system KREPTD liczb większych niż 7 znakowe
              if (formType?.value?.nameKey !== DomainDictionaryEntry.RESOURCE_FORM_NAME.LICENSE_TW) {
                return true;
              }
              return value <= TW_RESOURCE_TYPE_MAX_AMOUNT;
            }
          )
          .required(),
        numberTo: Yup.number()
          .transform(value => (Number.isNaN(value) ? undefined : value))
          .test(
            'lessThan',
            i18n.t('validation:lessThan', { less: TW_RESOURCE_TYPE_MAX_AMOUNT + 1 }),
            function checkIsMoreThan(value) {
              // eslint-disable-next-line react/no-this-in-sfc
              const { formType } = this.parent;

              // dodatkowa walidacja ze wzgledu na problemy z przyjmowaniem przez system KREPTD liczb większych niż 7 znakowe
              if (formType?.value?.nameKey !== DomainDictionaryEntry.RESOURCE_FORM_NAME.LICENSE_TW) {
                return true;
              }
              return value <= TW_RESOURCE_TYPE_MAX_AMOUNT;
            }
          )
          .required(),
        amount: Yup.number()
          .transform(value => (Number.isNaN(value) ? undefined : value))
          .test(
            'lessThan',
            i18n.t('validation:lessThan', { less: TW_RESOURCE_TYPE_MAX_AMOUNT + 1 }),
            function checkIsMoreThan(value) {
              // eslint-disable-next-line react/no-this-in-sfc
              const { formType } = this.parent;

              // dodatkowa walidacja ze wzgledu na problemy z przyjmowaniem przez system KREPTD liczb większych niż 7 znakowe
              if (formType?.value?.nameKey !== DomainDictionaryEntry.RESOURCE_FORM_NAME.LICENSE_TW) {
                return true;
              }
              return value <= TW_RESOURCE_TYPE_MAX_AMOUNT;
            }
          )
          .required()
      })
    )
  });

  const onSubmit = (formData: IExternalReceivesFormFields) => {
    const requestData: ResourceObjectExternalReceiveCreateRequest = {
      resourceTypeId: formData.formType.id,
      numberFrom: formData.numberFrom,
      numberTo: formData.numberTo,
      amount: formData.amount
    };

    externalReceives(requestData, {
      onSuccess: () => {
        showSnackbar('success', t('resource:messages.externalReceivesSuccess'));
        queryCache.invalidateQueries(ResourceQueryKeysEnum.RESOURCE_OBJECT_POOLS);

        closeDialog();
      }
    });
  };

  const getOptionLabel = option => {
    return option.name;
  };

  const onNumberFieldsChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const { numberFrom, numberTo, amount, formType } = getValues() as IExternalReceivesFormFields;
    const { value } = event.target;
    if (
      formType?.value?.nameKey === DomainDictionaryEntry.RESOURCE_FORM_NAME.LICENSE_TW &&
      parseInt(value, 10) > TW_RESOURCE_TYPE_MAX_AMOUNT
    ) {
      return;
    }

    handlePoolsChange[name](value, { numberFrom, numberTo, amount }, null, setValue);
  };

  const fetchNameOptionsFunction = (searchText: string, page: number) => {
    return API.resourceTypes.getResourceTypes({
      page,
      size: NUMBER_OF_AUTOCOMPLETE_RESULTS,
      nameKeyIn: searchText
        ? resourceFormNameDictionaryEntries
            .filter(entry => entry.name.toLowerCase().includes(searchText.toLowerCase()))
            .map(entry => entry.value)
        : [],
      groupKeyFilter: {
        values: [DomainDictionaryEntry.RESOURCE_FORM_GROUP.S_CERTIFICATE],
        exclude: true
      }
    });
  };

  const parseDataToSelect = ({ data }: AxiosResponse<ResourceTypeSnapshotPage>) => {
    return data?.content?.map(record => ({
      id: record.id,
      value: record,
      name: translate(DomainDictionaryEnum.RESOURCE_FORM_NAME, record.nameKey)
    }));
  };

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('resource:dialog.receiveFormsTitle')}
      confirmText={t('action.receive')}
      cancelText={t('action.cancel')}
      onConfirm={() => handleSubmit(data => onSubmit(data as IExternalReceivesFormFields))()}
      onCancel={closeDialog}
      dialogSize="small"
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <GridLayout itemProps={{ xs: 12 }}>
          <WarningInformation content={t('resource:messages.dataSynchronizationAfterExternalAdmission')} />
          <AutocompleteLazyFetchSelectField
            name={typedNameV2<IExternalReceivesFormFields>('formType')}
            label={getLabel('formType')}
            getOptionLabel={getOptionLabel}
            fetchNameOptionsFunction={fetchNameOptionsFunction}
            fetchedDataSelectParser={parseDataToSelect}
            queryKey={ResourceQueryKeysEnum.RESOURCE_TYPES_FIELD_EXTERNAL_RECEIVES}
          />

          <NumberInputField
            name={typedNameV2<IExternalReceivesFormFields>('numberFrom')}
            label={getLabel('numberFrom')}
            inputProps={{
              onChange: (event: any) => onNumberFieldsChange(event, 'numberFrom')
            }}
            min={0}
            isClearable
            isRequired
          />

          <NumberInputField
            name={typedNameV2<IExternalReceivesFormFields>('numberTo')}
            label={getLabel('numberTo')}
            inputProps={{
              onChange: (event: any) => onNumberFieldsChange(event, 'numberTo')
            }}
            min={0}
            isClearable
            isRequired
          />

          <NumberInputField
            name={typedNameV2<IExternalReceivesFormFields>('amount')}
            label={getLabel('amount')}
            inputProps={{
              onChange: (event: any) => onNumberFieldsChange(event, 'amount')
            }}
            min={1}
            isClearable
            isRequired
          />
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default ExternalReceivesForm;
