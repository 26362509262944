import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';

import useQueryParams from './useQueryParams';

const parseTab = (tab: string | undefined, useInt: boolean) => {
  if (tab !== undefined && useInt) {
    const tabInt = parseInt(tab, 10);
    return Number.isNaN(tabInt) ? undefined : tabInt;
  }
  return tab;
};

const useTab = <ValueType extends string | number>(
  defaultTab: ValueType = 0 as never,
  useHistoryPush = false
): [ValueType, (tab: ValueType) => void] => {
  const { tab, ...query }: { [_: string]: string; tab: string } = useQueryParams();

  const useInt = useMemo(() => typeof defaultTab === 'number', [defaultTab]);

  const [activeTab, setTab] = useState(parseTab(tab, useInt) ?? defaultTab);

  const navigate = useNavigate();

  useEffect(() => {
    const { tab: currentTab } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    if (currentTab !== activeTab) {
      setTab(parseTab(currentTab as string, useInt) ?? defaultTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  const setActiveTab = (tab: ValueType) => {
    if (tab === activeTab) {
      return;
    }

    const nextSearch = qs.stringify({ ...query, tab });
    if (useHistoryPush) {
      navigate(`?search=${nextSearch}`);
    } else {
      window.history.replaceState(null, '', `${window.location.pathname}?${nextSearch}`);
    }

    setTab(tab);
  };

  return [activeTab as ValueType, setActiveTab];
};

export default useTab;
