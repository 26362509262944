import { useTranslation } from 'react-i18next';
import { useAppConfig } from '@libs/app-config';
import { ReportDetailsSnapshot } from '@stack/report/dist/models';

import { BooleanValue, GridLayout, Section, useDevMode, Value } from '@libs/common/v2';
import { convertDateToDateFormat, convertDateToDateTimeFormat } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';
import { isReportParameterized } from '@libs/report/utils/reports.util';
import { ReportDictionaryEntryNameEnum } from '@libs/report-core';

interface Props {
  report?: ReportDetailsSnapshot;
  isLoading: boolean;
  isFetching: boolean;
}

function ReportDetailsGeneralSection({ report, isLoading, isFetching }: Props) {
  const { isDevMode } = useDevMode();
  const { isReportTypeManagementEnable } = useAppConfig(isDevMode);
  const { translate } = useDictionaryTranslations();
  const [t] = useTranslation();

  return (
    <Section title={t('reports:details.section.reportData')} isLoading={isFetching}>
      <GridLayout itemProps={{ xs: 12 }}>
        <Value label={t('reports:field.sid')} isLoading={isLoading}>
          {report?.sid}
        </Value>
        <Value label={t('reports:field.status')} isLoading={isLoading}>
          {translate(ReportDictionaryEntryNameEnum.REPORT_STATUS, report?.statusKey)}
        </Value>
        {isReportTypeManagementEnable && (
          <Value label={t('reports:field.created')} isLoading={isLoading}>
            {convertDateToDateFormat(report?.created)}
          </Value>
        )}
        <Value label={t('reports:field.generated')} isLoading={isLoading}>
          {convertDateToDateTimeFormat(report?.generationEnded)}
        </Value>
        <Value label={t('reports:field.reportType')} isLoading={isLoading}>
          {report?.reportTypeName}
        </Value>
        <Value label={t('reports:field.description')} isLoading={isLoading}>
          {report?.description}
        </Value>
        {isReportTypeManagementEnable && (
          <Value label={t('reports:field.parameterized')} isLoading={isLoading}>
            {report ? <BooleanValue value={isReportParameterized(report)} /> : null}
          </Value>
        )}
        {isReportTypeManagementEnable && (
          <Value label={t('reports:field.source')} isLoading={isLoading}>
            {(report?.dataSets ?? []).map(dataset => dataset.dataSource?.name).join(', ')}
          </Value>
        )}
        {isReportTypeManagementEnable && (
          <Value label={t('reports:field.technical')} isLoading={isLoading}>
            {report ? <BooleanValue value={report?.technical} /> : null}
          </Value>
        )}
      </GridLayout>
    </Section>
  );
}

export default ReportDetailsGeneralSection;
