import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  SingleUseSubmissionItemDetails,
  SingleUseSubmissionItemSnapshot,
  SingleUseSubmissionUpdateDecisionRequest
} from '@ibtm/domain';
import { AxiosResponse } from 'axios';
import * as Yup from 'yup';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  Dialog,
  FormV2Context,
  GridLayout,
  InputMode,
  NumberInputField,
  typedNameV2
} from '@libs/common/v2';

function AddDecisionDialog({
  closeDialog,
  onSubmit,
  data
}: {
  closeDialog: () => void;
  onSubmit: (
    values: SingleUseSubmissionUpdateDecisionRequest & { singleUseSubmissionItemId: string },
    closeDialog: () => void
  ) => Promise<AxiosResponse<SingleUseSubmissionItemDetails>>;
  data: SingleUseSubmissionItemSnapshot;
}) {
  const [t] = useTranslation();
  const getLabel = partialTranslate('foreignPermits:tabs.socialCommission.tabs.socialCommissionDisposablePermits');

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Partial<SingleUseSubmissionUpdateDecisionRequest & { quantity: number }>>({
    mode: 'onBlur',
    defaultValues: {
      receiptDateFrom: data?.receiptDateFrom,
      receiptDateTo: data?.receiptDateTo
    },
    resolver: yupResolver(
      Yup.object({
        decision: Yup.number().required(),
        receiptDateFrom: Yup.date().required(),
        receiptDateTo: Yup.date().required()
      })
    )
  });

  const _onSubmit = setConfirmLoading => {
    const formData = getValues();
    setConfirmLoading(true);
    onSubmit(
      {
        singleUseSubmissionItemId: data?.id,
        decision: formData.decision,
        receiptDateFrom: formData.receiptDateFrom,
        receiptDateTo: formData.receiptDateTo
      },
      closeDialog
    );
  };

  useEffect(() => {
    setValue('quantity', data?.quantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={getLabel('decisionModalTitle')}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={setConfirmLoading =>
        handleSubmit(() => {
          _onSubmit(setConfirmLoading);
        })()
      }
      onCancel={closeDialog}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <GridLayout itemProps={{ xs: 6 }}>
          <NumberInputField
            name={typedNameV2<SingleUseSubmissionItemSnapshot>('quantity')}
            label={getLabel('fields.quantity')}
            inputMode={InputMode.VIEW}
          />
          <NumberInputField
            name={typedNameV2<SingleUseSubmissionUpdateDecisionRequest>('decision')}
            label={getLabel('fields.decisionAmount')}
            isRequired
          />
          <DatepickerField
            name={typedNameV2<SingleUseSubmissionUpdateDecisionRequest>('receiptDateFrom')}
            label={getLabel('fields.receiptDateFrom')}
            isRequired
          />
          <DatepickerField
            name={typedNameV2<SingleUseSubmissionUpdateDecisionRequest>('receiptDateTo')}
            label={getLabel('fields.receiptDateTo')}
            isRequired
          />
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default AddDecisionDialog;
