import { useMutation } from 'react-query';
import { ApplicationUpdateRequest as ApplicationUpdateRequestClient } from '@ibtm/client-domain';
import { ApplicationUpdateRequest } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export function editApplication({
  applicationId,
  formData
}: {
  applicationId: string;
  formData: ApplicationUpdateRequest;
}) {
  return API.application.updateApplication(applicationId, formData, {
    ...endpointsConfig.updateApplication
  });
}
export function editApplicationClient({
  applicationId,
  formData
}: {
  applicationId: string;
  formData: ApplicationUpdateRequestClient;
}) {
  return API.client.application.updateApplication(applicationId, '', '', formData, {
    ...endpointsConfig.updateApplication
  });
}

function useEditApplicationMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(editApplicationClient, editApplication));
}

export default useEditApplicationMutation;
