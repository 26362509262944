import { TravelFormUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function updateTravelForm({
  travelFormId,
  travelFormUpdateRequest
}: {
  travelFormId: string;
  travelFormUpdateRequest: TravelFormUpdateRequest;
}) {
  return API.travelForm.updateTravelForm(travelFormId, travelFormUpdateRequest, {
    ...endpointsConfig.updateTravelForm
  });
}

function useUpdateTravelFormMutation() {
  return useMutation(updateTravelForm);
}

export default useUpdateTravelFormMutation;
