import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';
import { SubjectPartnershipPartnerSnapshot } from '@ibtm/domain';

import { TableLayout } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

import { useEntrepreneurTable } from '@libs/domain/subject';

import { EntrepreneurDetailsSnapshot } from '../../folder';

interface IProps {
  data: EntrepreneurDetailsSnapshot[];
  visibleColumns: Column<any>[];
  headerActions?: ReactNode;
  rowActions?: (row: Row<SubjectPartnershipPartnerSnapshot>) => ReactNode;
  actionsColumnWidth?: number;
  actionKey?: UIElementNameEnum;
}

function EntrepreneursTable({
  actionsColumnWidth,
  data,
  visibleColumns,
  headerActions,
  rowActions,
  actionKey
}: IProps) {
  const [t] = useTranslation();

  const parsedData = useMemo(() => {
    return (data || []).map(({ customId, id, ...restParams }) => ({ ...restParams, id: (id || customId) as string }));
  }, [data]);

  const tableProps = useEntrepreneurTable(parsedData, visibleColumns);

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('folder:details.section.entrepreneurs')}
      isActionColumnEnabled={!!rowActions}
      headerActions={headerActions}
      rowActions={rowActions}
      actionsColumnWidth={actionsColumnWidth}
      actionKey={actionKey}
      isFilterEnabled={false}
      isRefreshEnabled={false}
      isCollapsable
      isSection
    />
  );
}

export default EntrepreneursTable;
