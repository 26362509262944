import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReplacementCreateRequest } from '@avispon/group/dist/models';

import { AutocompleteLazyFetchSelectField, InputMode, typedNameV2 } from '@libs/common/v2';

import { API, UserQueryKeysEnum } from '@libs/user';

function UsersListSelectField({ inputMode }: { inputMode: InputMode }) {
  const [t] = useTranslation();

  const getOptionLabel = option => {
    return option.personalData
      ? `${option.personalData.name} ${option.personalData.surname}`
      : option.serviceNumber ?? '';
  };

  return (
    <AutocompleteLazyFetchSelectField
      name={typedNameV2<ReplacementCreateRequest>('substituteAccountId')}
      label={t('user:replacements.field.substitute')}
      queryKey={UserQueryKeysEnum.USER_ACCOUNT_CONTROLLER_FIELD}
      isRequired
      inputMode={inputMode}
      optionLabelParser={getOptionLabel}
      api={{
        FETCH: (searchText: string, params: { page: number; size: number }) => {
          return API.user.accountController.search({
            ...params,
            query: searchText,
            sort: []
          });
        }
      }}
    />
  );
}

export default UsersListSelectField;
