import { useCallback } from 'react';
import { ApplicationDetails as ApplicationDetailsClient } from '@ibtm/client-domain';
import { ApplicationDetails, ApplicationFlagDetails } from '@ibtm/domain';
import { isNull, isUndefined } from 'lodash';
import moment from 'moment';

import { useDomainConfigContext } from '@libs/domain/config';
import { getFolder } from '@libs/domain/folder';
import { ScheduleApplicationGroupsEnum } from '@libs/domain/transit-schedule';

import { WarningEnumKeys } from '../models';

export const useChecksRegistry = ({
  applicationFlags,
  folderId,
  applicationData
}: {
  applicationFlags: ApplicationFlagDetails;
  folderId: string;
  applicationData: ApplicationDetails | ApplicationDetailsClient;
}) => {
  const { isOperatorPortal } = useDomainConfigContext();
  return useCallback(() => {
    const warningVisibilityCheckFunctions: Record<WarningEnumKeys, () => Promise<boolean>> = {
      // PB2 - W teczce nie ma licencji wydanej na okres powyżej 10 lat. Wniosek nie powinien zostać obsłużony pozytywnie. -
      IS_FOLDER_NOT_LICENSE_DATE_MORE_THAN_10Y: async () => {
        if (!isUndefined(applicationFlags?.hasActiveLicense)) {
          return !applicationFlags?.hasActiveLicense;
        }
        if (!isUndefined(applicationFlags?.hasActiveLicenseForOverTenYears)) {
          return !applicationFlags?.hasActiveLicenseForOverTenYears;
        }
        return false;
      },
      // PB2 - Wniosek złożony przed terminem. -
      IS_LICENSE_COPY_BEFORE_VALID: async () => {
        return !applicationFlags?.lessThanThreeMonthsToCopyExpiration;
      },
      // PB6/PB29 - Nabywca posiada aktywną licencję. Wniosek powinien zostać obsłużony odmownie. -
      IS_APPLICATION_HAS_ACTIVE_LICENSE: async () => {
        return applicationFlags?.hasActiveLicense;
      },

      // PB5 - Na teczce brak zgłoszonych pojazdów -
      IS_APPLICATION_HAS_ATLEAST_ONE_ACTIVE_VEHICLE: async () => {
        return !isUndefined(applicationFlags?.hasActiveVehicle) ? !applicationFlags?.hasActiveVehicle : false;
      },
      // PB5 - Liczba wypisów większa niż liczba zgłoszonych pojazdów -
      IS_APPLICATION_LICENSE_MORE_THAN_VEHICLE: async () => {
        return applicationFlags?.hasLessVehiclesThanCopies;
      },
      // PB1A - Podmiot ma aktywne zezwolenie – nie można drugi raz wnioskować o udzielenie zezwolenia. -
      IS_SUBJECT_HAS_ACTIVE_PERMISSIONS: async () => {
        return !isUndefined(applicationFlags?.hasActivePermission) ? applicationFlags?.hasActivePermission : false;
      },
      // -
      IS_DATE_OF_FOLDER_SUBJECT_DEATH_PAST_6MONTHS: async () => {
        if (isNull(folderId)) {
          return false;
        }
        const folder = await getFolder(null, folderId);
        if (folder?.dateOfSubjectDeath) {
          return moment(folder?.dateOfSubjectDeath).isBefore(moment().subtract(6, 'months'));
        }

        return false;
      },
      // -
      IS_DEADLINE_DATE_EXPIRED: async () => {
        const permissionObject = applicationData?.permissionApplicationDetails?.permissionApplicationDetails;

        if (applicationData.typeKey.includes(ScheduleApplicationGroupsEnum.SPO_UE)) {
          const term = permissionObject?.term;
          return term ? moment(term).isBefore(moment()) : false;
        }
        if (applicationData.typeKey.includes(ScheduleApplicationGroupsEnum.SPO_WZO)) {
          const term = permissionObject?.term;
          return term ? moment(term).isBefore(moment()) : false;
        }
        if (applicationData.typeKey.includes(ScheduleApplicationGroupsEnum.SPO_WZW)) {
          const term = permissionObject?.term;
          return term ? moment(term).isBefore(moment()) : false;
        }
        if (applicationData.typeKey.includes(ScheduleApplicationGroupsEnum.SPO_TRANSIT_RP)) {
          const term = permissionObject?.term;
          return term ? moment(term).isBefore(moment()) : false;
        }
        if (applicationData.typeKey.includes(ScheduleApplicationGroupsEnum.SPO_OUTSIDE_UE)) {
          const term = permissionObject?.term;
          return term ? moment(term).isBefore(moment()) : false;
        }
        if (applicationData.typeKey.includes(ScheduleApplicationGroupsEnum.SPO_OPINION)) {
          const term = permissionObject?.term;
          return term ? moment(term).isBefore(moment()) : false;
        }
        return false;
      },

      IS_FOLDER_HAS_NOT_ACTIVE_LICENSE: async () => {
        return !isUndefined(applicationFlags?.hasActiveLicense) ? !applicationFlags?.hasActiveLicense : false;
      },
      IS_FOLDER_HAS_NOT_ARCHIVE_NUMBER: async () => {
        return isOperatorPortal && !isUndefined(applicationFlags?.hasEzdCaseNumber)
          ? !applicationFlags?.hasEzdCaseNumber
          : false;
      },
      // SK - Dla podanego kierowcy na danej teczce przedsiębiorcy jest już realizowany wniosek
      IS_APPLICATION_ALREADY_IN_PROGRESS_FOR_GIVEN_DRIVER: async () => {
        return applicationFlags?.isDriverCurrentlyProceeding;
      },
      IS_SUCCESSOR_HAS_ACTIVE_LICENSE: async () => {
        return applicationFlags?.hasActiveLicense;
      },
      // PB1A, PB26 - Wspólnicy spółki (wszyscy lub część) posiadają już aktywne zezwolenie
      IS_PARTNER_HAS_ACTIVE_PERMISSION: async () => {
        return applicationFlags?.partnerPermission;
      },
      // PB1A, PB26 - Wspólnicy spółki (wszyscy lub część) posiadają już aktywne licencję
      IS_PARTNER_HAS_ACTIVE_LICENSE: async () => {
        return applicationFlags?.partnerLicense;
      },

      // Czy minęło ponad 2 miesiące od najwcześniejszej daty doręczenia dokumentu z wniosku
      IS_MORE_THAN_TWO_MONTHS_AFTER_DOCUMENT_RECEIPT_ACK_DATE: async () => {
        return !!applicationFlags?.moreThanTwoMonthsAfterDocumentReceiptAckDate;
      },
      IS_APPLICATION_ARCHIVED: async () => {
        return applicationData?.archived;
      },
      IS_APPLICATION_PASSED_TO_FOREIGN_PERMISSION_DEPARTMENT: async () => {
        return (applicationData as ApplicationDetails)?.permissionApplicationDetails?.permissionApplicationDetails
          ?.spoComplete;
      }
    };
    return warningVisibilityCheckFunctions;
  }, [applicationFlags, folderId, applicationData, isOperatorPortal])();
};
