import { FieldValues, UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { AddressSnapshot, ApplicationDetails, ApplicationLite, LicenseLite } from '@ibtm/domain';

import { SelectOption } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { getCalendarDate } from '@libs/common/v2/utils';

import { ApplicationQueryKeysEnum } from '@libs/domain/application';
import { DomainDictionaryEntry } from '@libs/domain/config';

function useMutationConfig({
  getValues,
  categoryKey
}: {
  getValues?: UseFormGetValues<FieldValues>;
  categoryKey?: string;
}) {
  const queryCache = useQueryCache();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbar();
  const [t] = useTranslation();

  return ({
    onSuccess,
    onError,
    shouldOnlyUpdateMisspellApplications = false
  }: { onSuccess?: () => void; onError?: () => void; shouldOnlyUpdateMisspellApplications?: boolean } = {}) => ({
    onSuccess: (data: ApplicationDetails) => {
      if (
        getValues &&
        [
          DomainDictionaryEntry.APPLICATION_CATEGORY.ROAD_CARRIER_GOODS_CARRIAGE_ABRIDGEMENT,
          DomainDictionaryEntry.APPLICATION_CATEGORY.BROKERAGE_IN_TRANSPORT_OF_PASSENGERS,
          DomainDictionaryEntry.APPLICATION_CATEGORY.DRIVER_CERTIFICATE
        ].includes(categoryKey)
      ) {
        const [
          dateOfIssue,
          description,
          submissionReceiptDate,
          expectedCompletionDate,
          lastSupplementReceiptDate,
          name,
          surname,
          lockedByApplication,
          concernLicense,
          receiptTypeKey,
          sendToAddress,
          submissionChannelKey
        ] = getValues?.([
          'licenseApplicationDetails-misspellApplicationDataDetails-dateOfIssue',
          'licenseApplicationDetails-misspellApplicationDataDetails-description',
          'application-submission-date',
          'application-expected-completion-date',
          'application-last-supplement-receipt-date',
          'application-send-to-address-name',
          'application-send-to-address-surname',
          'application-locked-by-application',
          'application-concern-license',
          'application-receipt-type-key',
          'application-send-to-address-required',
          'application-submission-channel-key'
        ]) as [
          string,
          string,
          string,
          string,
          string,
          string,
          string,
          SelectOption<ApplicationLite>,
          SelectOption<LicenseLite>,
          SelectOption<string>,
          SelectOption<AddressSnapshot>,
          SelectOption<string>
        ];

        const misspelledFlag = !shouldOnlyUpdateMisspellApplications;

        const newData: ApplicationDetails = {
          misspellApplicationDataDetails: {
            ...data?.misspellApplicationDataDetails,
            ...(dateOfIssue && misspelledFlag && { dateOfIssue: getCalendarDate(dateOfIssue) }),
            ...(description && misspelledFlag && { description })
          },
          ...(receiptTypeKey && { receiptTypeKey: receiptTypeKey?.value }),
          ...(sendToAddress && { sendToAddress: sendToAddress?.value }),
          ...(name && { recipientName: name }),
          ...(surname && { recipientSurname: surname }),
          ...(submissionChannelKey && { applicationChannelKey: submissionChannelKey?.value }),
          ...(misspelledFlag && {
            ...data,
            ...(submissionReceiptDate && { submissionReceiptDate: getCalendarDate(submissionReceiptDate) }),
            ...(expectedCompletionDate && { expectedCompletionDate: getCalendarDate(expectedCompletionDate) }),
            ...(lastSupplementReceiptDate && { lastSupplementReceiptDate: getCalendarDate(lastSupplementReceiptDate) }),
            ...(lockedByApplication && { lockedByApplication: lockedByApplication?.value }),
            ...(concernLicense && { concernLicense: concernLicense?.value })
          })
        };

        queryCache.setQueryData([ApplicationQueryKeysEnum.APPLICATION, data.id], (oldData: ApplicationDetails) => ({
          ...oldData,
          ...newData,
          version: data.version
        }));
        showSuccessSnackbar(t('success.save'));
        onSuccess?.();
      } else if (getValues) {
        const [dateOfIssue, description, name, surname, receiptTypeKey, sendToAddress] = getValues?.([
          'licenseApplicationDetails-misspellApplicationDataDetails-dateOfIssue-reprint',
          'licenseApplicationDetails-misspellApplicationDataDetails-description-reprint',
          'application-send-to-address-name-scertificate-reprint',
          'application-send-to-address-surname-scertificate-reprint',
          'application-receipt-type-key-scertificate-reprint',
          'application-send-to-address-required-scertificate-reprint'
        ]) as [string, string, string, string, SelectOption<string>, SelectOption<AddressSnapshot>];

        const newData: ApplicationDetails = {
          ...data,
          misspellApplicationDataDetails: {
            ...data?.misspellApplicationDataDetails,
            ...(dateOfIssue && { dateOfIssue: getCalendarDate(dateOfIssue) }),
            ...(description && { description })
          },
          ...(receiptTypeKey && { receiptTypeKey: receiptTypeKey?.value }),
          ...(sendToAddress && { sendToAddress: sendToAddress?.value }),
          ...(name && { recipientName: name }),
          ...(surname && { recipientSurname: surname })
        };

        queryCache.setQueryData([ApplicationQueryKeysEnum.APPLICATION, data.id], newData);
        showSuccessSnackbar(t('success.save'));
        onSuccess?.();
      } else {
        queryCache.setQueryData([ApplicationQueryKeysEnum.APPLICATION, data.id], data);
        showSuccessSnackbar(t('success.save'));
        onSuccess?.();
      }
    },
    onError: () => {
      onError?.();
      showErrorSnackbar(t('error.dataSaveFailure'));
    }
  });
}

export default useMutationConfig;
