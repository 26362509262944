import { VehicleUpdateRequest as VehicleUpdateRequestClient } from '@ibtm/client-domain';
import { VehicleUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface IFormData {
  id: string;
  formData: VehicleUpdateRequest | VehicleUpdateRequestClient;
}

function updateVehicle({ id, formData }: IFormData) {
  return API.vehicle.updateVehicle(id, formData, {
    ...endpointsConfig.updateVehicle
  });
}
function updateVehicleClient({ id, formData }: IFormData) {
  return API.client.vehicle.updateVehicle('', '', id, formData as VehicleUpdateRequestClient, {
    ...endpointsConfig.updateVehicle
  });
}

function useUpdateVehicleMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(
    getQuery(
      (formData: IFormData) => updateVehicleClient(formData),
      (formData: IFormData) => updateVehicle(formData)
    )
  );
}

export default useUpdateVehicleMutation;
