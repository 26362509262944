import {
  AutocompleteField,
  AutocompleteLazyFetchField,
  BooleanField,
  DatetimeField,
  DictionaryField,
  NumberField,
  QuickChangeableDictionarySelectField,
  TextField,
  TypographyField
} from '@libs/meta-form/fields';
import { FieldTypes } from '@libs/meta-form/models';

const FieldRegistry = {
  [FieldTypes.TEXT]: TextField,
  [FieldTypes.NUMBER]: NumberField,
  [FieldTypes.DICTIONARY]: DictionaryField,
  [FieldTypes.DICTIONARY_QUICK_CHANGEABLE]: QuickChangeableDictionarySelectField,
  [FieldTypes.DATETIME]: DatetimeField,
  [FieldTypes.BOOLEAN]: BooleanField,
  [FieldTypes.TYPOGRAPHY]: TypographyField,
  [FieldTypes.AUTOCOMPLETE]: AutocompleteField,
  [FieldTypes.AUTOCOMPLETE_LAZY_FETCH]: AutocompleteLazyFetchField
};

export default FieldRegistry;
