import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { PartnerApplicantDetails } from '@ibtm/domain';
import { object as YupObject } from 'yup';

import { partialTranslate } from '@libs/common';
import {
  Dialog,
  DictionarySelectField,
  FormV2Context,
  GridLayout,
  InputMode,
  NumberInputField,
  Section,
  SwitchField,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import { useEditPartnerApplicantMutation } from '../api';
import { PartnerApplicantFields, validationSchema } from '../model';
import generateDataAddressRequest from '../utils/generateDataAddressRequest';

import AddressFieldsFormSection from './AddressFieldsFormSection';

interface IProps {
  partnerApplicantId: string;
  onCloseDialog: () => void;
  isOpen: boolean;
  refetch?: () => void;
  partnerApplicant: PartnerApplicantDetails;
  clearSelection?: () => void;
}

const getLabel = partialTranslate('partnerApplicants:field');

function PartnerApplicantsEditDialog({
  partnerApplicantId,
  partnerApplicant,
  onCloseDialog,
  isOpen,
  refetch,
  clearSelection
}: IProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: editPartnerApplicant, isLoading } = useEditPartnerApplicantMutation();
  const [isMainAddress, setIsMainAddress] = useState<boolean>(
    Boolean(partnerApplicant?.correspondenceAddress?.sameAsMainAddress)
  );
  const [address, setAddress] = useState(partnerApplicant.HQAddress);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    reset,
    trigger,
    unregister
  } = useForm<Partial<PartnerApplicantFields>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(
      isMainAddress
        ? YupObject({
            roleKey: YupObject().required().nullable()
          })
        : validationSchema()
    )
  });

  useEffect(() => {
    setValue('roleKey', { value: partnerApplicant?.roleKey });
    setValue('lp', partnerApplicant?.lp);
  }, [partnerApplicant?.roleKey, partnerApplicant?.lp, setValue]);

  useEffect(() => {
    setValue('address.sameAsMainAddress', partnerApplicant?.correspondenceAddress?.sameAsMainAddress);
  }, [partnerApplicant?.correspondenceAddress.sameAsMainAddress, setValue]);

  const formValues = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      reset,
      getValues,
      trigger,
      unregister,
      isSubmitting
    }),
    [control, errors, register, setValue, watch, reset, getValues, trigger, unregister, isSubmitting]
  );

  const sameAsMainAddress = watch('address.sameAsMainAddress') as boolean;

  useEffect(() => {
    if (sameAsMainAddress) {
      setAddress(partnerApplicant.HQAddress);
    } else {
      setAddress(partnerApplicant.correspondenceAddress);
    }

    setIsMainAddress(sameAsMainAddress);
  }, [partnerApplicant?.HQAddress, partnerApplicant?.correspondenceAddress, sameAsMainAddress]);

  useEffect(() => {
    if (address) {
      setValue('address.name', address.name);
      setValue('address.city', address.city);
      setValue('address.postCode', address.postCode);
      setValue('address.postCity', address.postCity);
      setValue('address.street', address.street);
      setValue('address.propertyNumber', address.propertyNumber);
      setValue('address.apartmentNumber', address.apartmentNumber);
      setValue('address.voivodeshipKey', address?.voivodeshipKey && { value: address?.voivodeshipKey });
      setValue('address.countryCodeKey', address?.countryCodeKey && { value: address?.countryCodeKey });
      setValue('address.county', address.county);
      setValue('address.commune', address.commune);
      setValue('address.firstLine', address.firstLine);
      setValue('address.secondLine', address.secondLine);
    } else {
      setValue('address.name', null);
      setValue('address.city', null);
      setValue('address.postCode', null);
      setValue('address.postCity', null);
      setValue('address.street', null);
      setValue('address.propertyNumber', null);
      setValue('address.apartmentNumber', null);
      setValue('address.voivodeshipKey', null);
      setValue('address.countryCodeKey', null);
      setValue('address.county', null);
      setValue('address.commune', null);
      setValue('address.firstLine', null);
      setValue('address.secondLine', null);
    }
  }, [address, setValue]);

  const handleEditSubmit = useCallback(() => {
    const data = getValues();

    editPartnerApplicant(
      {
        partnerApplicantId,
        formData: {
          lp: data?.lp,
          roleKey: data?.roleKey?.value,
          address: {
            sameAsMainAddress: data.address.sameAsMainAddress,
            address: generateDataAddressRequest(data)
          },
          version: partnerApplicant?.version
        }
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('partnerApplicants:action.edit.successMessage'));
          onCloseDialog();
          clearSelection();
          refetch();
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPartnerApplicant, getValues, onCloseDialog, partnerApplicantId, refetch, showSuccessSnackbar, t]);

  return (
    <FormV2Context.Provider value={formValues}>
      <form onSubmit={handleSubmit(handleEditSubmit)}>
        <Dialog
          title={t('partnerApplicants:action.edit.dialogTitle')}
          confirmText={t('action.save')}
          cancelText={t('action.cancel')}
          onCancel={onCloseDialog}
          onConfirm={handleSubmit(handleEditSubmit)}
          dialogSize="medium"
          isConfirmLoading={isLoading}
          isOpen={isOpen}
        >
          <Section isModalSection isHeaderVisible={false}>
            <GridLayout
              itemProps={{ xs: 4 }}
              containerProps={{
                marginBottom: '2rem'
              }}
            >
              <NumberInputField name="lp" label={getLabel('lp')} inputMode={InputMode.VIEW} />
              <DictionarySelectField
                name={typedNameV2<PartnerApplicantFields>('roleKey')}
                dictionaryName={DomainDictionaryEnum.PARTNER_APPLICANT}
                label={getLabel('roleKey')}
                isRequired
                inputMode={partnerApplicant?.lp === 1 && InputMode.VIEW}
                optionsFilter={option =>
                  [
                    DomainDictionaryEntry.PARTNER_APPLICANT.PARTNER,
                    DomainDictionaryEntry.PARTNER_APPLICANT.SUBCONTRACOTR
                  ].includes(option?.value)
                }
              />
              <SwitchField
                name={typedNameV2<PartnerApplicantFields>('address.sameAsMainAddress')}
                label={getLabel('sameAsMainAddress')}
              />
            </GridLayout>
            <GridLayout itemProps={{ xs: 12 }}>
              <TextInputField
                name={typedNameV2<PartnerApplicantFields>('address.name')}
                label={getLabel('name')}
                isDisabled={sameAsMainAddress}
                tooltip={sameAsMainAddress && getLabel('tooltipSameAsMainAddress')}
              />
              <DictionarySelectField
                name={typedNameV2<PartnerApplicantFields>('address.countryCodeKey')}
                dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
                label={getLabel('countryCodeKey')}
                isDisabled={sameAsMainAddress}
                isRequired={!sameAsMainAddress}
                tooltip={sameAsMainAddress && getLabel('tooltipSameAsMainAddress')}
              />
              <AddressFieldsFormSection
                customSameAsMainAddressPath={typedNameV2<PartnerApplicantFields>('address.sameAsMainAddress')}
              />
            </GridLayout>
          </Section>
        </Dialog>
      </form>
    </FormV2Context.Provider>
  );
}

export default PartnerApplicantsEditDialog;
