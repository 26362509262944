import { useMemo } from 'react';
import { useRowSelect } from 'react-table';

import { AdditionalFilterEnum, IAdditionalFilter, useMultipleSelectColumn, useTableLayoutProps } from '@libs/common/v2';

import { useDomainConfigContext } from '@libs/domain/config';
import {
  ForeignPermitsGlobalFiltersEnum,
  initialParamsMapper,
  useForeignPermissionsColumns,
  usePermitsQuery
} from '@libs/domain/foreign-permission';

import ForeignPermissionSelectColumnButton from '../../components/ForeignPermissionSelectColumn';

interface IProps {
  documentReleaseNumber?: string;
  isReleasedPermits?: boolean;
  isMassActionVisible: boolean;
}

function useReleasedPermistsTable({ documentReleaseNumber, isReleasedPermits, isMassActionVisible }: IProps) {
  const { columns, mappedFilterFields, mappedSortByFields } = useForeignPermissionsColumns();

  const { yearFilterContextValues, setYearFilterContextValues } = useDomainConfigContext();

  const globalFilters: IAdditionalFilter[] = [
    {
      type: AdditionalFilterEnum.YEAR_SELECT,
      name: ForeignPermitsGlobalFiltersEnum.YEAR,
      setGlobalYearSelect: setYearFilterContextValues
    }
  ];

  const multipleSelectColumn = useMultipleSelectColumn(ForeignPermissionSelectColumnButton);

  const tablePlugins = useMemo(
    () => (isMassActionVisible ? [useRowSelect, multipleSelectColumn] : []),
    [isMassActionVisible, multipleSelectColumn]
  );

  return useTableLayoutProps({
    tableHookQuery: usePermitsQuery,
    tableHookOptions: {
      columns,
      tablePlugins,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'name', desc: true }],
          ...(documentReleaseNumber && {
            filters: [
              {
                id: 'invoiceNumber',
                value: documentReleaseNumber
              }
            ]
          })
        }
      },
      initialParamsConverter: initialParamsMapper,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortByFields
    },
    globalFilters,
    tableHookQueryInitialParams: {
      ...(isReleasedPermits && { applicationIdIn: [null] }),
      [ForeignPermitsGlobalFiltersEnum.YEAR]: yearFilterContextValues?.value
    }
  });
}
export default useReleasedPermistsTable;
