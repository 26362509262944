import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Parameter, ParameterExtras } from '@stack/report';
import _ from 'lodash';

import {
  AutocompleteLazyFetchSelectField,
  AutocompleteSelectField,
  CheckboxGroup,
  DatepickerField,
  DictionaryCheckboxAndRadioGroup,
  DictionarySelectField,
  GridItem,
  RadioGroup,
  SwitchField,
  TextInputField,
  useFormV2Context,
  ValidatorEnums
} from '@libs/common/v2';
import {
  DatePickerFieldValidation,
  SelectFieldValidation,
  SelectMultipleFieldValidation,
  TextFieldValidation
} from '@libs/common/v2/form/validation';
import { removeKeyForEmptyValue } from '@libs/common/v2/utils';

import { DictionaryEntryNameEnum, useDictionaryEntryValues } from '@libs/dictionary';
import { FieldTypeEnum } from '@libs/report';
import {
  getReportAllParametersValues,
  getReportParameterValues,
  ReportQueryKeysEnum,
  useReportAllParametersValuesQuery,
  useReportParameterValuesQuery
} from '@libs/report/api';
import { parseCommonDynamicParametersValues } from '@libs/report/utils/converter.util';

type ValidationRules = TextFieldValidation &
  DatePickerFieldValidation &
  SelectMultipleFieldValidation &
  SelectFieldValidation;

interface IProps {
  reportTypeId: string;
  fieldDescription: Parameter;
  fieldsDescription: Array<Parameter>;
  // props używany jako obejście do poprawnego wyświetlania GridLayout
  // eslint-disable-next-line
  gridItemProps: any;
  parameterExtras: ParameterExtras[];
}

function FieldContainer({ reportTypeId, fieldDescription, fieldsDescription, parameterExtras }: IProps) {
  const {
    checkbox,
    type,
    displayName,
    enabledByParamName,
    disabledByParamNames,
    required,
    groupName,
    dictionaryName,
    hintValues,
    hintQueryAvailable,
    multiValue,
    selectOnly,
    defaultValue,
    requireAllParameterValues,
    validator
  } = fieldDescription;

  const [t] = useTranslation();
  const { control, setValue, getValues, clearErrors } = useFormV2Context();
  const dictionaryEntries = useDictionaryEntryValues(dictionaryName as DictionaryEntryNameEnum);
  const [isQueryEnabled, setIsQueryEnabled] = useState(true);

  const queryKeyNameForHintParameter = useMemo(
    () => `${ReportQueryKeysEnum.REPORT_PARAMETER_VALUES}_${displayName.toUpperCase()}`,
    [displayName]
  );
  const isDateField = useMemo(() => type === FieldTypeEnum.DATE, [type]);
  const fields = parameterExtras.map(({ displayName: name, type: { type: paramType } }) => ({
    name,
    type: paramType
  }));
  const formValues = getValues();

  const parameterValues = parseCommonDynamicParametersValues(
    _.omit(removeKeyForEmptyValue(formValues), 'targetTypes'),
    fields,
    parameterExtras
  );

  const { data } = useReportParameterValuesQuery(
    { queryKey: queryKeyNameForHintParameter, reportTypeId, displayName },
    { enabled: hintQueryAvailable && (checkbox || isDateField || defaultValue) && !requireAllParameterValues }
  );

  const { data: allParametersData, isLoading: isAllParametersDataLoading } = useReportAllParametersValuesQuery(
    {
      queryKey: queryKeyNameForHintParameter,
      reportTypeId,
      displayName,
      queryParameterValuesFilter: { parameterValues, displayName }
    },
    {
      enabled:
        hintQueryAvailable && (checkbox || isDateField || defaultValue) && requireAllParameterValues && isQueryEnabled
    }
  );

  const groupNameFieldNames = useMemo(() => {
    return groupName
      ? fieldsDescription
          .filter(fieldDescription => fieldDescription.groupName === groupName)
          .map(({ displayName }) => displayName)
      : [];
  }, [fieldsDescription, groupName]);

  const requiredDependencyFields = useMemo(() => {
    return fieldsDescription
      .filter(({ requiredParamNames }) => requiredParamNames?.includes(displayName))
      .map(({ displayName }) => displayName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsDescription, groupName]);

  const groupFieldValues = useWatch({ control, name: groupNameFieldNames });
  const requiredDependencyFieldValues = useWatch({ control, name: requiredDependencyFields });
  const fieldDateValue = useWatch({ control, name: displayName, disabled: !isDateField });

  const enabledByFieldValue = useWatch({ control, name: enabledByParamName });
  const isEnabledByFieldValue = useMemo(() => {
    if (enabledByParamName && enabledByFieldValue) {
      const dependentParamField = parameterExtras?.find(i => i.displayName === enabledByParamName);

      if (dependentParamField?.type?.type === FieldTypeEnum.BOOLEAN && enabledByFieldValue?.value === 'false') {
        return false;
      }
    }
    return !!enabledByFieldValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledByFieldValue]);

  const disabledByFieldValues = useWatch({ control, name: disabledByParamNames });
  const isDisabledByFieldValues = useMemo(() => {
    if (disabledByParamNames?.length && Array.isArray(disabledByFieldValues)) {
      const parsedValues = disabledByFieldValues.map((value, index) => {
        const dependentParamField = parameterExtras?.find(i => i.displayName === disabledByParamNames[index]);
        if (dependentParamField?.type?.type === FieldTypeEnum.BOOLEAN && value?.value === 'false') {
          return false;
        }
        return value;
      });
      return !!parsedValues.filter(value => (Array.isArray(value) ? value.some(i => i) : value))?.length;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledByFieldValues]);

  const isVisible = useMemo(() => {
    if (enabledByParamName && disabledByParamNames?.length) {
      return isEnabledByFieldValue && !isDisabledByFieldValues;
    }

    return (
      (!enabledByParamName && !disabledByParamNames?.length) ||
      (enabledByParamName && isEnabledByFieldValue) ||
      (disabledByParamNames && !isDisabledByFieldValues)
    );
  }, [enabledByParamName, disabledByParamNames, isEnabledByFieldValue, isDisabledByFieldValues]);

  const isArrayEmptyInRequired = useMemo(() => {
    // W przypadku groupField mamy do czynienia z tablicą wartości dla każdego fielda w grupie,
    // gdzie ta wartosć też jest tablicą, więc mamy tablice wartości w tablicy.
    // Dlatego żeby poprawnie walidować po wartościach i ustawiać obligatoryjnośc, musimy sprawdzić każdą
    // tablicę w tej głównej tablicy
    const arrayOfValues = groupFieldValues.map(filterElement => {
      if (_.isArray(filterElement)) {
        return filterElement?.filter(el => el !== undefined);
      }
      return !_.isNil(filterElement) && [filterElement];
    });

    const isArrayOfValuesEmpty = _.isEmpty(arrayOfValues.filter(el => !_.isEmpty(el) && Boolean(el)));

    return _.isArray(groupFieldValues.filter(Boolean)) && isArrayOfValuesEmpty;
  }, [groupFieldValues]);

  const isRequired = useMemo(() => {
    return (
      isVisible &&
      (required ||
        (_.size(groupNameFieldNames) && (_.size(groupFieldValues.filter(Boolean)) === 0 || isArrayEmptyInRequired)) ||
        !!_.size(requiredDependencyFieldValues.filter(Boolean)))
    );
  }, [
    groupFieldValues,
    groupNameFieldNames,
    requiredDependencyFieldValues,
    required,
    isVisible,
    isArrayEmptyInRequired
  ]);

  useEffect(() => {
    if (!isRequired) {
      clearErrors(displayName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequired, displayName]);

  useEffect(() => {
    if (!isVisible) {
      setValue(displayName, null);
      clearErrors(displayName);
    } else if (type === FieldTypeEnum.BOOLEAN && isRequired && !formValues[displayName]) {
      setValue(displayName, false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const validation: ValidationRules = useMemo(
    () => ({
      ...(isRequired
        ? {
            required: []
          }
        : {}),
      contextValidator: [validator as ValidatorEnums]
    }),
    [isRequired, validator]
  );

  const longValidation: ValidationRules = useMemo(
    () => ({
      ...(isRequired
        ? {
            required: []
          }
        : {}),
      contextValidator: [validator ? (validator as ValidatorEnums) : ValidatorEnums.NON_NEGATIVE_LONG]
    }),
    [isRequired, validator]
  );

  const doubleValidation: TextFieldValidation = useMemo(
    () => ({
      ...(isRequired
        ? {
            required: []
          }
        : {}),
      contextValidator: [validator ? (validator as ValidatorEnums) : ValidatorEnums.DOUBLE_PRECISION_FLOAT_NUMBER]
    }),
    [isRequired, validator]
  );

  const booleanSelectOptions = useMemo(() => {
    return [
      { value: 'true', name: t('action.yes') },
      { value: 'false', name: t('action.no') }
    ];
  }, [t]);

  const options = useMemo(() => {
    return hintValues?.map(value => ({
      value,
      name: value
    }));
  }, [hintValues]);

  const hintOptions = useMemo(() => {
    if (requireAllParameterValues && allParametersData && 'content' in allParametersData) {
      return allParametersData.content?.map(value => ({
        value,
        name: value
      }));
    }
    if (!requireAllParameterValues && data && 'content' in data) {
      return data.content?.map(value => ({
        value,
        name: value
      }));
    }
    return null;
  }, [allParametersData, data, requireAllParameterValues]);

  useEffect(() => {
    if (isDateField && !fieldDateValue && data && 'content' in data) {
      setValue(displayName, data.content[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDateField, fieldDateValue, data]);

  useEffect(() => {
    if (type === FieldTypeEnum.BOOLEAN && isRequired && !formValues[displayName]) {
      setValue(displayName, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchParameterValues = useCallback(
    (searchText: string, params: { page: number; size: number }) => {
      return requireAllParameterValues
        ? getReportAllParametersValues(null, {
            reportTypeId,
            isUsedInFetchAutocomplete: true,
            queryParameterValuesFilter: { parameterValues, ...(searchText ? { filter: searchText } : {}), displayName },
            page: params.page,
            size: params.size
          })
        : getReportParameterValues(null, {
            reportTypeId,
            displayName,
            isUsedInFetchAutocomplete: true,
            parameterValue: searchText,
            page: params.page,
            size: params.size
          });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requireAllParameterValues, parameterValues]
  );

  const autocompleteLazyFetchOptionLabelParser = useCallback(option => option, []);

  const getDefaultValue = useMemo(() => {
    const initialValue = getValues(displayName);

    if (_.isNil(initialValue)) {
      if (!_.isEmpty(options)) {
        return options[0];
      }
      if (!_.isEmpty(hintOptions)) {
        return hintOptions[0];
      }
      if (!_.isEmpty(dictionaryEntries)) {
        return dictionaryEntries[0];
      }
    } else {
      return initialValue;
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayName, options, dictionaryEntries, hintOptions]);

  useEffect(() => {
    if (type !== FieldTypeEnum.DATE && defaultValue && getDefaultValue) {
      setIsQueryEnabled(false);
      const selectedValue = getDefaultValue;
      if (multiValue) {
        if (checkbox) {
          setValue(`${displayName}.0`, selectedValue?.value);
        } else {
          setValue(displayName, [selectedValue]);
        }
      } else if (checkbox) {
        setValue(displayName, selectedValue?.value);
      } else {
        setValue(displayName, selectedValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, hintOptions, allParametersData]);

  const withGridItem = (item: JSX.Element) => {
    return <GridItem xs={12}>{item}</GridItem>;
  };

  if (dictionaryName) {
    if (checkbox) {
      return withGridItem(
        <DictionaryCheckboxAndRadioGroup
          name={displayName}
          label={displayName}
          isMulti={multiValue}
          isRequired={isRequired}
          dictionaryName={dictionaryName as DictionaryEntryNameEnum}
          isDisabled={!isVisible}
        />
      );
    }
    return withGridItem(
      <DictionarySelectField
        name={displayName}
        label={displayName}
        dictionaryName={dictionaryName as DictionaryEntryNameEnum}
        isMultiple={multiValue}
        isRequired={isRequired}
        validationMultipleSelect={validation}
        validationSingleSelect={validation}
        isFreeSolo={!selectOnly}
        isDisabled={!isVisible}
      />
    );
  }

  if (hintQueryAvailable) {
    if (checkbox) {
      if (multiValue) {
        return withGridItem(
          <CheckboxGroup
            name={displayName}
            label={displayName}
            options={hintOptions}
            isRequired={isRequired}
            isDisabled={!isVisible}
          />
        );
      }

      return withGridItem(
        <RadioGroup
          name={displayName}
          label={displayName}
          options={hintOptions}
          isRequired={isRequired}
          isLoading={_.isNil(data)}
          isDisabled={!isVisible}
        />
      );
    }

    if (isDateField) {
      return withGridItem(
        <DatepickerField
          name={displayName}
          label={displayName}
          isRequired={isRequired}
          isFreeToFill={!selectOnly}
          validation={{ ...validation, isDateValid: [] }}
          isDisabled={!isVisible}
        />
      );
    }

    return withGridItem(
      <AutocompleteLazyFetchSelectField
        name={displayName}
        label={displayName}
        queryKey={queryKeyNameForHintParameter}
        queryKeyParams={requireAllParameterValues ? parameterValues : null}
        optionLabelParser={autocompleteLazyFetchOptionLabelParser}
        api={{ FETCH: fetchParameterValues }}
        isMultiple={multiValue}
        isFreeSolo={!selectOnly}
        isDisabled={!isVisible}
        isRequired={isRequired}
        isLoading={isAllParametersDataLoading}
        validationMultipleSelect={validation}
        validationSingleSelect={validation}
        isQueryInitiallyEnabled={defaultValue || getValues(displayName)}
      />
    );
  }

  if (hintValues || multiValue) {
    if (checkbox) {
      if (!options) {
        return <div className="p-12 font-medium">{t('reports:generateReport.noOptions', { name: displayName })}</div>;
      }
      if (multiValue) {
        return withGridItem(
          <CheckboxGroup
            name={displayName}
            label={displayName}
            options={options}
            isRequired={isRequired}
            isDisabled={!isVisible}
          />
        );
      }
      return withGridItem(
        <RadioGroup
          name={displayName}
          label={displayName}
          options={options}
          isRequired={isRequired}
          isDisabled={!isVisible}
        />
      );
    }

    if (isDateField) {
      return withGridItem(
        <DatepickerField
          name={displayName}
          label={displayName}
          isRequired={isRequired}
          isFreeToFill={!selectOnly}
          validation={{ ...validation, isDateValid: [] }}
          isDisabled={!isVisible}
        />
      );
    }

    return withGridItem(
      <AutocompleteSelectField
        isFreeSolo={!selectOnly}
        name={displayName}
        label={displayName}
        options={options}
        isMultiple={multiValue}
        isRequired={isRequired}
        validationMultipleSelect={validation}
        validationSingleSelect={validation}
        isDisabled={!isVisible}
      />
    );
  }
  if (type === FieldTypeEnum.DOUBLE) {
    return withGridItem(
      <TextInputField
        name={displayName}
        label={displayName}
        isRequired={isRequired}
        validation={doubleValidation}
        isDisabled={!isVisible}
        isOnLimitDemical
      />
    );
  }
  if (type === FieldTypeEnum.LONG) {
    return withGridItem(
      <TextInputField
        name={displayName}
        label={displayName}
        isRequired={isRequired}
        validation={longValidation}
        isDisabled={!isVisible}
        isOnlyPositiveIntegers
      />
    );
  }
  if (type === FieldTypeEnum.BOOLEAN) {
    if (!isRequired && isVisible) {
      return withGridItem(
        <AutocompleteSelectField
          name={displayName}
          label={displayName}
          options={booleanSelectOptions}
          isDisabled={!isVisible}
          isClearable
        />
      );
    }

    return withGridItem(
      <SwitchField name={displayName} label={displayName} isDisabled={!isVisible} isRequired={isRequired} />
    );
  }
  if (!type || type === FieldTypeEnum.CHARACTER || type === FieldTypeEnum.GUID) {
    return withGridItem(
      <TextInputField
        name={displayName}
        label={displayName}
        isRequired={isRequired}
        validation={validation}
        isDisabled={!isVisible}
      />
    );
  }
  if (isDateField) {
    return withGridItem(
      <DatepickerField
        name={displayName}
        label={displayName}
        isRequired={isRequired}
        validation={{ ...validation, isDateValid: [] }}
        isDisabled={!isVisible}
      />
    );
  }

  return <GridItem xs={12} className="hidden" />;
}

export default FieldContainer;
