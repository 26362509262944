import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { TableButtonMore, TableIconButton, TableLayout, useConfirmDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useFormDirtyContext, useMetaFormContext } from '@libs/meta-form/context';
import useHandleFormChanges from '@libs/meta-form/services/useHandleFormChanges';
import { UIElementNameEnum } from '@libs/permission';

import { IPermissionsKeys } from '../../config';
import { useDeleteMoneyTransfersFromApplication } from '../api';
import { PricingQueryKeysEnum } from '../api/query/PricingQueryKeysEnum';
import { useMoneyTransfersForApplicationTable } from '../hooks';
import useMoneyTransferEditDialog from '../hooks/useMoneyTransferEditDialog';

import { LinkMoneyTransferButton, SearchMoneyTransferButton, UnlinkMoneyTransferButton } from '.';

interface IProps {
  dropdownMenu: React.ReactNode;
  applicationId?: string;
  isSection?: boolean;
  permissionsKeys?: IPermissionsKeys;
  tableActionKeys?: Array<UIElementNameEnum>;
  areSearchLinkUnlinkMoneyTransferButtonsVisible?: boolean;
  fieldId?: string;
}

function MoneyTransfersTable({
  dropdownMenu,
  applicationId,
  permissionsKeys,
  isSection,
  areSearchLinkUnlinkMoneyTransferButtonsVisible,
  fieldId
}: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { showMoneyTransferEditDialog } = useMoneyTransferEditDialog();

  const [confirm] = useConfirmDialog();
  const { mutate: deleteMoneyTransfersFromApplication, isLoading: isDeleteMoneyTransferFromApplicationLoading } =
    useDeleteMoneyTransfersFromApplication();

  const { isDirty } = useFormDirtyContext();
  const { formRef } = useMetaFormContext();
  const { handleFormChanges } = useHandleFormChanges(isDirty, formRef?.current?.onSubmit, formRef?.current?.onReset);

  const tableProps = useMoneyTransfersForApplicationTable(
    applicationId,
    [
      'paymentDate',
      'transactionDescription',
      'senderAccountNumber',
      'senderName',
      'amount',
      'availableAmount',
      'currentApplicationAmountUsed'
    ],
    { quorumConfirmation: true }
  );

  const handleDeleteMoneyTransfersFromApplication = (id: string, onSuccess: () => void, onSettled: () => void) => {
    deleteMoneyTransfersFromApplication(
      {
        applicationId,
        formData: {
          moneyTransfersIds: [id]
        }
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('pricing:moneyTransfers.action.delete.successMessage'));
          onSuccess();
        },
        onSettled
      }
    );
  };

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('pricing:moneyTransfers.tableTitle')}
      headerActions={
        <>
          {areSearchLinkUnlinkMoneyTransferButtonsVisible && (
            <>
              <TableButtonMore>
                {close => (
                  <>
                    <UnlinkMoneyTransferButton
                      onClick={close}
                      applicationId={applicationId}
                      actionKey={permissionsKeys?.unlink}
                      handleDiscardOrSaveFormChanges={handleFormChanges}
                    />
                    <LinkMoneyTransferButton
                      onClick={close}
                      applicationId={applicationId}
                      actionKey={permissionsKeys?.link}
                      handleDiscardOrSaveFormChanges={handleFormChanges}
                      fieldId={fieldId}
                    />
                  </>
                )}
              </TableButtonMore>
              <SearchMoneyTransferButton
                applicationId={applicationId}
                actionKey={permissionsKeys?.search}
                handleDiscardOrSaveFormChanges={handleFormChanges}
              />
            </>
          )}
          {dropdownMenu}
        </>
      }
      rowActions={({ original }) => (
        <>
          <TableIconButton
            icon="CoinHandOverIcon"
            tooltipTitle={t('pricing:action.edit.editMoneyTransfer')}
            actionKey={permissionsKeys?.link}
            onClick={() =>
              handleFormChanges(() =>
                showMoneyTransferEditDialog({
                  applicationId,
                  data: original,
                  fieldId
                })
              )
            }
          />
          <TableIconButton
            icon="TrashIcon"
            tooltipTitle={t('pricing:moneyTransfers.action.delete.buttonTitle')}
            actionKey={permissionsKeys?.delete}
            onClick={() =>
              handleFormChanges(() =>
                confirm({
                  message: t('pricing:paymentConfirmation.dialog.deleteTransferMessage'),
                  title: t('pricing:paymentConfirmation.dialog.deleteTransferTitle'),
                  onConfirm: (setLoading, closeDialog) => {
                    setLoading(true);
                    handleDeleteMoneyTransfersFromApplication(
                      original?.id,
                      () => {
                        queryCache.invalidateQueries([PricingQueryKeysEnum.APPLICATION_MONEY_TRANSFERS]);
                      },
                      () => {
                        closeDialog();
                        setLoading(false);
                      }
                    );
                  },
                  confirmType: 'danger'
                })
              )
            }
            isButtonLoading={isDeleteMoneyTransferFromApplicationLoading}
          />
        </>
      )}
      isFilterEnabled={false}
      isSection={isSection}
    />
  );
}

export default MoneyTransfersTable;
