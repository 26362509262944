import { QueryConfig, useQuery } from 'react-query';
import { QueryParameterResultPage } from '@stack/report';
import { AxiosResponse } from 'axios';

import { API, endpointsConfig } from '@libs/report/api';

export const getReportParameterValues = async (
  _,
  params: {
    reportTypeId: string;
    displayName: string;
    isUsedInFetchAutocomplete?: boolean;
    parameterValue?: string;
    page?: number;
    size?: number;
    sort?: string[];
  }
) => {
  const { reportTypeId, displayName, isUsedInFetchAutocomplete, parameterValue, page, size, sort } = params;
  const response = await API.reportTypes.getParameterValues(
    reportTypeId,
    displayName,
    parameterValue,
    page,
    size,
    sort,
    { ...endpointsConfig.getParameterValues }
  );

  if (isUsedInFetchAutocomplete) {
    return response as AxiosResponse<QueryParameterResultPage>;
  }

  return response.data;
};

function useReportParameterValuesQuery(
  params: { queryKey: string; reportTypeId: string; displayName: string; isUsedInFetchAutocomplete?: boolean },
  queryConfig?: QueryConfig<QueryParameterResultPage | AxiosResponse<QueryParameterResultPage>>
) {
  const { queryKey, reportTypeId, displayName, isUsedInFetchAutocomplete } = params;
  return useQuery<AxiosResponse<QueryParameterResultPage> | QueryParameterResultPage>(
    [queryKey, { reportTypeId, displayName, isUsedInFetchAutocomplete }],
    getReportParameterValues,
    queryConfig
  );
}

export default useReportParameterValuesQuery;
