import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function removeLock({ folderLockId, version }: { folderLockId: string; version: number }) {
  return API.folderLock.removeFolderLock(folderLockId, { version }, { ...endpointsConfig.removeFolderLock });
}

function useRemoveLockMutation() {
  return useMutation(removeLock);
}

export default useRemoveLockMutation;
