import { ForwardedRef, forwardRef } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  DictionarySelectField,
  FormMode,
  GridItem,
  GridLayout,
  Section,
  TextInputField,
  typedNameV2,
  ValidatorEnums
} from '@libs/common/v2';

import { AddressSection } from '@libs/domain/address';
import { DomainDictionaryEnum } from '@libs/domain/config';

import { useMisspellApplicationPermissionFormFieldsValidation } from '../../hooks';
import { MisspellApplicationDataDriverCertificateFormValues } from '../../model/misspell-application.model';

import { DriverLicenseSection } from './DriverLicenseSection';
import { MisspellApplicationFormProvider } from './MisspellApplicationFormProvider';

interface IProps {
  permissionData: Partial<MisspellApplicationDataDriverCertificateFormValues>;
  formMode: FormMode;
}

const getLabel = partialTranslate('drivers:fields');

export const MisspellApplicationDriverCertificateFormFields = forwardRef(
  (
    { formMode, permissionData }: IProps,
    ref: ForwardedRef<UseFormHandleSubmit<MisspellApplicationDataDriverCertificateFormValues>>
  ) => {
    const [t] = useTranslation();
    const { validToFieldValidation } = useMisspellApplicationPermissionFormFieldsValidation();

    return (
      <MisspellApplicationFormProvider<MisspellApplicationDataDriverCertificateFormValues>
        ref={ref}
        formMode={formMode}
        permissionData={permissionData}
      >
        <Section isHeaderVisible={false} isModalSection isCollapsable>
          <GridLayout itemProps={{ xs: 12, sm: 6 }}>
            <TextInputField
              name={typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('number')}
              label={t('applications:misspellApplication.driverCertificateFields.number')}
              validation={{ required: [] }}
              inputProps={{ maxLength: 20 }}
            />
            <DatepickerField
              name={typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('dateOfIssue')}
              label={t('applications:misspellApplication.driverCertificateFields.dateOfIssue')}
              validation={{ required: [] }}
            />

            <DatepickerField
              name={typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('validFrom')}
              label={t('applications:misspellApplication.driverCertificateFields.validFrom')}
              validation={{ required: [], isDateValid: [] }}
            />

            <DatepickerField
              name={typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('validTo')}
              label={t('applications:misspellApplication.driverCertificateFields.validTo')}
              validation={validToFieldValidation}
            />

            <TextInputField
              name={typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('subjectName')}
              label={t('applications:misspellApplication.driverCertificateFields.subjectName')}
              validation={{ required: [] }}
            />

            <TextInputField
              name={typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('insurancePayerNip')}
              label={t('applications:misspellApplication.driverCertificateFields.insurancePayerNip')}
              validation={{ required: [], contextValidator: [ValidatorEnums.NIP] }}
              inputProps={{ maxLength: 10 }}
              isOnlyPositiveIntegers
            />
            <GridItem xs={12}>
              <AddressSection
                hiddenFields={[
                  'voivodeshipKey',
                  'firstLine',
                  'commune',
                  'countryCodeKey',
                  'secondLine',
                  'version',
                  'county'
                ]}
                isCountryFieldAvaliable={false}
              />
            </GridItem>
          </GridLayout>
        </Section>

        <Section title={t('drivers:sections.generalData')} isModalSection isCollapsable>
          <GridLayout itemProps={{ xs: 12, sm: 6 }}>
            <TextInputField
              name={typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('driverName')}
              label={getLabel('name')}
              validation={{ required: [] }}
            />
            <TextInputField
              name={typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('driverSurname')}
              label={getLabel('surname')}
              validation={{ required: [] }}
            />
            <DatepickerField
              name={typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('driverBirthDate')}
              label={getLabel('birthDate')}
              maxDate={moment().toISOString()}
              validation={{ required: [] }}
            />

            <DictionarySelectField
              name={typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('driverCitizenship')}
              label={getLabel('citizenship')}
              dictionaryName={DomainDictionaryEnum.CITIZENSHIP}
              validationStringValueSingleSelect={{ required: [] }}
              isRequired
              stringValue
            />

            <DictionarySelectField
              name={typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('driverBirthPlace')}
              label={getLabel('birthPlace')}
              dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
              validationStringValueSingleSelect={{ required: [] }}
              isRequired
              stringValue
            />
          </GridLayout>
        </Section>

        <Section title={t('drivers:sections.identityDocument')} isCollapsable isModalSection>
          <GridLayout itemProps={{ xs: 12, sm: 6 }}>
            <DictionarySelectField
              name={typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('identityCard.typeKey')}
              label={getLabel('identityCard.typeKey')}
              dictionaryName={DomainDictionaryEnum.IDENTITY_DOCUMENT_TYPE}
              validationStringValueSingleSelect={{ required: [] }}
              isRequired
              stringValue
            />

            <DictionarySelectField
              name={typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('identityCard.issuingCountryKey')}
              label={getLabel('identityCard.issuingCountryKey')}
              dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
              validationStringValueSingleSelect={{ required: [] }}
              isRequired
              stringValue
            />

            <TextInputField
              inputProps={{ maxLength: 20 }}
              name={typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('identityCard.seriesNumber')}
              label={getLabel('identityCard.seriesNumber')}
              validation={{ required: [] }}
            />

            <DatepickerField
              name={typedNameV2<MisspellApplicationDataDriverCertificateFormValues>('identityCard.issuingDate')}
              label={getLabel('identityCard.issuingDate')}
              validation={{ required: [] }}
            />
          </GridLayout>
        </Section>

        <DriverLicenseSection />
      </MisspellApplicationFormProvider>
    );
  }
);
