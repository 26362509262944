import { UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { DictionaryEntryUpdateRequest } from '@avispon/dictionary';
import { DictionaryEntryDetails } from '@avispon/dictionary/dist/models';
import { useSnackbar } from '@enigma/fe-ui';
import { AxiosRequestConfig } from 'axios';

import { AxiosErrorResponseType } from '@libs/common/v2/models';
import { unescapeValue } from '@libs/common/v2/utils';

import { API, endpointsConfig } from '@libs/dictionary/api';
import useAsyncErrorsHandlerV2 from '@libs/dictionary/hooks/useAsyncErrorsHandlerV2';
import { DictionaryFormValues } from '@libs/dictionary/models';

interface UpdateDictionaryEntryRequest {
  entryId: string;
  updatedEntry: DictionaryEntryUpdateRequest;
}

async function updateDictionaryEntry({ entryId, updatedEntry }: UpdateDictionaryEntryRequest) {
  const { data } = await API.dictionary.update({ entryId, body: updatedEntry }, {
    ...endpointsConfig.updateDictionaryEntry
  } as AxiosRequestConfig);
  return data;
}
function useEditDictionaryEntryMutation(setError: UseFormSetError<DictionaryFormValues>) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { handleAsyncErrorsV2 } = useAsyncErrorsHandlerV2<DictionaryFormValues>();
  return useMutation<
    DictionaryEntryDetails,
    AxiosErrorResponseType<DictionaryFormValues>,
    UpdateDictionaryEntryRequest
  >(updateDictionaryEntry, {
    onSuccess: result => {
      showSuccessSnackbar(t('dictionary:modifySuccess', unescapeValue({ name: result.value })));
    },
    onError: error => handleAsyncErrorsV2(error, setError)
  });
}

export default useEditDictionaryEntryMutation;
