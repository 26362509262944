import { QueryConfig, usePaginatedQuery } from 'react-query';
import { DocumentTemplateDetailsExternal } from '@avispon/document-generator/dist/models';
import { AxiosRequestConfig } from 'axios';

import { API, DocumentTemplateQueryKeysEnum, endpointsConfig } from '@libs/document-template';

const getDocumentTemplate = async (_: DocumentTemplateQueryKeysEnum, documentTemplateId: string) => {
  const { data } = await API.documentGenerator.getTemplate({ documentTemplateId }, {
    ...endpointsConfig.getTemplate
  } as AxiosRequestConfig);
  return data;
};

export default function useDocumentTemplateDetailsQuery(
  documentTemplateId: string,
  config?: QueryConfig<DocumentTemplateDetailsExternal>
) {
  return usePaginatedQuery<DocumentTemplateDetailsExternal>(
    [DocumentTemplateQueryKeysEnum.DOCUMENT_TEMPLATE_DETAILS, documentTemplateId],
    getDocumentTemplate,
    {
      enabled: Boolean(documentTemplateId),
      ...config
    }
  );
}
