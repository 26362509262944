import { useMutation } from 'react-query';
import { FinancialSecurityUpdateRequest } from '@ibtm/client-domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function updateFinancialSecurity({
  financialSecurityId,
  updateRequestData
}: {
  financialSecurityId: string;
  updateRequestData: FinancialSecurityUpdateRequest;
}) {
  return API.client.financialSecurity.updateFinancialSecurity('', '', financialSecurityId, updateRequestData, {
    ...endpointsConfig.updateFinancialSecurity
  });
}

function useClientFinancialSecurityUpdateMutation() {
  return useMutation(updateFinancialSecurity);
}

export default useClientFinancialSecurityUpdateMutation;
