import { useTranslation } from 'react-i18next';

import { KeyType } from '@libs/common';
import { Button, useDialog } from '@libs/common/v2';

import SearchLicensePermissionDialog from './dialog/SearchLicensePermissionDialog';

function SearchLicensePermissionButton({
  applicationId,
  applicationTypeKey,
  folderId,
  transferredFoldersIds,
  isLicenseSelect,
  isPermissionVisible
}: {
  applicationId: string;
  applicationTypeKey: string;
  folderId?: string;
  transferredFoldersIds?: string[];
  isLicenseSelect?: boolean;
  isPermissionVisible?: boolean;
}) {
  const { openDialog } = useDialog();
  const [t] = useTranslation();

  const openDriverSearchDialog = () =>
    openDialog(({ closeDialog }) => (
      <SearchLicensePermissionDialog
        closeDialog={closeDialog}
        applicationId={applicationId}
        applicationTypeKey={applicationTypeKey}
        folderId={folderId}
        transferredFoldersIds={transferredFoldersIds}
        isLicenseSelect={isLicenseSelect}
        isPermissionVisible={isPermissionVisible}
      />
    ));

  return (
    <Button
      type="button"
      variant="outlined"
      onClick={openDriverSearchDialog}
      label={t('applications:button.findForm' as KeyType)}
      isNoMargin
    />
  );
}

export default SearchLicensePermissionButton;
