import { QueryObserverConfig } from 'react-query';

import { IPaginatedModel, useTableLayoutProps } from '@libs/common/v2';

import { useDepotsQuery } from '../../api';
import { IDepotsListClient } from '../../model';
import { useDepotsColumns } from '../columns';

function useDepotsTable(
  depotIdIn?: string[],
  tableHookQueryConfig?: QueryObserverConfig<IPaginatedModel<IDepotsListClient>>
) {
  const { columns, mappedFilterFields, mappedSortFields } = useDepotsColumns();

  return useTableLayoutProps({
    tableHookQuery: useDepotsQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tableOptions: {
        initialState: {
          sortBy: [
            { id: 'type', desc: false },
            { id: 'name', desc: false }
          ]
        }
      }
    },
    ...(depotIdIn
      ? {
          tableHookQueryInitialParams: {
            depotIdIn
          }
        }
      : {}),
    tableHookQueryConfig
  });
}

export default useDepotsTable;
