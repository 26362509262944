import { PayloadAction } from '@reduxjs/toolkit';

import { FetchStatusState, Status } from '@libs/common/v2';

export interface PromiseState {
  status: FetchStatusState;
}

const REQUESTED = 'Requested';
const FAILURE = 'Failure';

export function promiseReducer<T extends PromiseState, W>(
  name: string,
  success: (state: T, action: PayloadAction<W>) => void
) {
  return {
    [name]: (state, action: PayloadAction<W>) => {
      state.status = Status.SUCCESS;
      success(state, action);
    },
    [`${name}${REQUESTED}`]: state => {
      state.status = Status.FETCHING;
    },
    [`${name}${FAILURE}`]: state => {
      state.status = Status.FAILURE;
    }
  };
}

export const createActionType = (namespace: string, name: string) => ({
  name,
  type: `${namespace}/${name}`
});
