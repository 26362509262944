const toastrConfig = {
  success: {
    autoHideDuration: 5000
  },
  warning: {
    autoHideDuration: 5000
  },
  error: {
    autoHideDuration: 5000
  },
  info: {
    autoHideDuration: 5000
  }
};

export default toastrConfig;
