export enum OrganizationUnitTabsEnum {
  GENERAL = 'general',
  USERS = 'users',
  PERMISSION_GROUPS = 'permission-groups'
}

export enum OrganizationUnitView {
  TREE = 'TREE',
  LIST = 'LIST'
}

export enum OrganizationStructureHierarchyLevelEnum {
  ORGANIZATION_STRUCTURE_HIERARCHY_LEVEL_IO = 'organization.structure.hierarchy.level.io',
  ORGANIZATION_STRUCTURE_HIERARCHY_LEVEL_IOK = 'organization.structure.hierarchy.level.iok',
  ORGANIZATION_STRUCTURE_HIERARCHY_LEVEL_IOKZ = 'organization.structure.hierarchy.level.iokz',
  ORGANIZATION_STRUCTURE_HIERARCHY_LEVEL_IOKP = 'organization.structure.hierarchy.level.iokp'
}

export type IOrganizationUnitDetailsParams = {
  id: string;
};
