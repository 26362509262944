import React, { useEffect, useState } from 'react';
import { QueryConfig, useQuery } from 'react-query';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';

import { SelectOption } from '@libs/common/v2';

import AutocompleteSelect from './AutocompleteSelect';

interface IProps {
  /**
   * @fetchFunctionResolver - funkcja asynchroniczna która zwraca dane
   */
  fetchFunctionResolver: (params: any) => Promise<any>;
  /**
   * Funkcja która przyjmuje dane i zwraca sparsowany array opcji do AutocompleteSelect
   */
  fetchedDataSelectParser: (data: any) => SelectOption[];
  queryKey: string;
  queryConfig?: QueryConfig<unknown, unknown>;
  onChange: (_event: React.SyntheticEvent, value: any) => void;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  getOptionLabel?: (option: SelectOption | string) => string;
  getOptionSelected?: (option: SelectOption) => boolean;
  renderTags?: (value: IProps['value'], getTagProps) => React.ReactNode;
  name?: string;
  label?: string;
  TextFieldProps?: Partial<MuiTextFieldProps>;
  className?: string;
  helperText?: string;
  value?: SelectOption | SelectOption[] | string;
  isMultiple?: boolean;
  isFreeSolo?: boolean;
  isDisableClearable?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  isFilterSelectedOptions?: boolean;
}

function AutocompleteFetchSelect({
  fetchFunctionResolver,
  value,
  fetchedDataSelectParser,
  onBlur,
  queryKey,
  queryConfig,
  ...forwardProps
}: IProps) {
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [inputFocused, setInputFocused] = useState(false);
  const { data, isLoading } = useQuery([queryKey, value], () => fetchFunctionResolver(value), {
    enabled: inputFocused,
    ...queryConfig
  });
  useEffect(() => {
    if (data) {
      setOptions(fetchedDataSelectParser(data));
    }
  }, [data, fetchedDataSelectParser]);

  const handleOnBlur = event => {
    onBlur?.(event);
    setInputFocused(false);
  };

  return (
    <AutocompleteSelect
      options={options}
      isLoading={isLoading}
      onBlur={handleOnBlur}
      onFocus={() => setInputFocused(true)}
      value={value}
      {...forwardProps}
    />
  );
}

export default AutocompleteFetchSelect;
