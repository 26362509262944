import { useMemo } from 'react';
import { ProceedingKreptdSearchFilter } from '@ibtm/domain';

import {
  CamelCasePath,
  ISingleColumn,
  SelectOption,
  TableFilterMapper,
  TableSortMapper,
  useCreateColumns
} from '@libs/common/v2';

import { IProceedingClient } from '../../models';

const useProceedingColumns = (visibleColumns: Array<CamelCasePath<IProceedingClient>>) => {
  const { createColumns } = useCreateColumns<IProceedingClient>({
    pathToTranslate: 'roadTransport:fields'
  });

  const sortedColumns = useMemo(
    () =>
      visibleColumns.map(visibleColumn =>
        [
          {
            accessor: 'subjectName',
            type: 'TEXT'
          },
          {
            accessor: 'subjectNip',
            type: 'TEXT'
          },
          {
            accessor: 'transportManagerName',
            type: 'TEXT'
          },
          {
            accessor: 'transportManagerCertificateNumber',
            type: 'TEXT'
          },
          {
            accessor: 'assignedName',
            type: 'TEXT'
          },
          {
            accessor: 'caseNumber',
            type: 'TEXT'
          },
          {
            accessor: 'category',
            type: 'DICTIONARY_MULTI_SELECT',
            dictionaryName: 'PROCEEDING_TYPE_KREPTD',
            width: 250
          },
          {
            accessor: 'status',
            type: 'DICTIONARY_MULTI_SELECT',
            dictionaryName: 'PROCEEDING_STATUS_KREPTD',
            width: 250
          }
        ].find(column => column?.accessor === visibleColumn)
      ),

    [visibleColumns]
  );

  const columns = createColumns(sortedColumns as ISingleColumn<IProceedingClient>[], visibleColumns);

  const mappedFilterFields: TableFilterMapper<IProceedingClient, ProceedingKreptdSearchFilter> = {
    subjectName: (subjectName: string) => ({ subjectNameContains: subjectName }),
    subjectNip: (subjectNip: string) => ({ subjectNipContains: subjectNip }),
    transportManagerName: (transportManagerName: string) => ({ transportManagerNameContains: transportManagerName }),
    transportManagerCertificateNumber: (transportManagerCertificateNumber: string) => ({
      transportManagerCertificateNumberContains: transportManagerCertificateNumber
    }),
    assignedName: (assignedName: string) => ({ assignedNameContains: assignedName }),
    caseNumber: (caseNumber: string) => ({ caseNumberContains: caseNumber }),
    category: (categories: SelectOption<string>[]) => ({ categoryKeyIn: categories.map(category => category.value) }),
    status: (statuses: SelectOption<string>[]) => ({ statusKeyIn: statuses.map(status => status.value) })
  };

  const mappedSortFields: TableSortMapper<IProceedingClient> = {
    category: 'categoryKey',
    status: 'statusKey'
  };

  return { columns, mappedFilterFields, mappedSortFields };
};

export default useProceedingColumns;
