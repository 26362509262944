import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';
import { AccountsApiSearchRequest } from '@avispon/user';
import { AccountSnapshot, AccountSnapshotStatus } from '@avispon/user/dist/models';
import { useAppConfig } from '@libs/app-config';

import {
  CamelCasePath,
  IAdditionalFilter,
  TableButtonMore,
  TableButtonView,
  TableHeaderButton,
  TableLayout,
  useDevMode,
  useRouter,
  useTableRowActions
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { API, UserQueryKeysEnum } from '@libs/user/api';
import {
  ChangeUserStatusPopUp,
  DeleteUserPopUp,
  LockUserPopUp,
  RestoreUserPopUp,
  UnlockUserPopUp
} from '@libs/user/components';
import { useResendEmailConfirmation, useSendEmailToResetPassword, useUsersTable } from '@libs/user/hooks';

import { IPermissionsObject } from '../../models';
import { parseUsersListPayload } from '../../utils';

interface Props {
  additionalParams?: AccountsApiSearchRequest;
  additionalFilters?: IAdditionalFilter[];
  renderAdditionalFilters?: (table?: TableInstance<AccountSnapshot>, clear?: () => void) => React.ReactNode;
  disableAddUserFunctionality?: boolean;
  modulePath?: string;
  title?: string;
  customPath?: string;
  visibleColumns: Array<CamelCasePath<AccountSnapshot>>;
  permissionsKeysObject: IPermissionsObject;
}

function UserListPage({
  additionalParams,
  additionalFilters,
  modulePath = 'users',
  title,
  visibleColumns,
  permissionsKeysObject
}: Props) {
  const [t] = useTranslation();
  const { isDevMode } = useDevMode();
  const [onlyUnassignedToUnit, setOnlyUnassignedToUnit] = useState(false);
  const { resendEmailConfirmation } = useResendEmailConfirmation();
  const { sendEmailToResetPassword } = useSendEmailToResetPassword();
  const { resendEmailConfirmationVisible } = useAppConfig();

  const closePopupRef = useRef(null);
  const queryCache = useQueryCache();
  const tableProps = useUsersTable(onlyUnassignedToUnit, additionalParams, visibleColumns);

  const { routes } = useRouter();

  const refetch = () => {
    queryCache.invalidateQueries(UserQueryKeysEnum.USERS_LIST);
  };

  const { renderTableActions } = useTableRowActions<AccountSnapshot>(
    [
      {
        id: permissionsKeysObject?.details,
        label: 'action.show',
        link: ({ original: { id } }) =>
          modulePath === 'users' ? routes.userDetails(id) : routes.userClientPortalDetails(id),
        isHidden: ({
          row: {
            original: { isEditable }
          }
        }) => !isEditable,
        icon: 'ArrowIcon'
      },
      {
        id: permissionsKeysObject?.edit,
        label: 'user:action.editUser',
        link: ({ original: { id } }) =>
          modulePath === 'users' ? routes.userEdit(id) : routes.userClientPortalEdit(id),
        isHidden: ({
          row: {
            original: { isEditable }
          }
        }) => !isEditable
      },
      {
        id: permissionsKeysObject?.delete,
        label: null,
        customButton: ({ original }) => (
          <DeleteUserPopUp userDetails={original} onAccept={refetch} actionKey={permissionsKeysObject?.delete} />
        ),
        isHidden: ({
          row: {
            original: { isEditable, status }
          }
        }) => !isEditable || status !== AccountSnapshotStatus.DELETED
      },
      {
        id: permissionsKeysObject?.unlock,
        label: null,
        customButton: ({ original }) => (
          <UnlockUserPopUp
            userDetails={original}
            onAccept={refetch}
            onCancel={() => closePopupRef.current?.()}
            actionKey={permissionsKeysObject?.unlock}
          />
        ),
        isHidden: ({
          row: {
            original: { isEditable, status }
          }
        }) => !isEditable || status !== AccountSnapshotStatus.BLOCKED
      },
      {
        id: permissionsKeysObject?.lock,
        label: null,
        customButton: ({ original }) => (
          <LockUserPopUp
            userDetails={original}
            onAccept={refetch}
            onCancel={() => closePopupRef.current?.()}
            actionKey={permissionsKeysObject?.lock}
          />
        ),
        isHidden: ({
          row: {
            original: { isEditable, status }
          }
        }) => !isEditable || !!(status === AccountSnapshotStatus.BLOCKED && AccountSnapshotStatus.DELETED)
      },
      {
        id: permissionsKeysObject?.restore,
        label: null,
        customButton: ({ original }) => (
          <RestoreUserPopUp
            userDetails={original}
            withNewPassword
            onAccept={refetch}
            onCancel={() => closePopupRef.current?.()}
          />
        ),
        isHidden: ({
          row: {
            original: { isEditable, status }
          }
        }) => !isEditable || status !== AccountSnapshotStatus.DELETED
      },
      {
        id: permissionsKeysObject?.reject,
        label: null,
        customButton: ({ original }) => (
          <ChangeUserStatusPopUp
            userDetails={original}
            modalTitle={t('user:dialog.userRejectTitle')}
            modalContentText={t('user:dialog.userRejectRequest', { serviceNumber: original?.serviceNumber })}
            label={t('user:action.reject')}
            snackBarText={t('user:dialog.userRejectSuccess', { serviceNumber: original?.serviceNumber })}
            status={AccountSnapshotStatus.REJECTED}
            onAccept={refetch}
            onCancel={() => closePopupRef.current?.()}
          />
        ),
        isHidden: ({
          row: {
            original: { isEditable, status }
          }
        }) => !isEditable || status !== AccountSnapshotStatus.UNVERIFIED
      },
      {
        id: permissionsKeysObject?.verify,
        label: null,
        customButton: ({ original }) => (
          <ChangeUserStatusPopUp
            userDetails={original}
            modalTitle={t('user:dialog.userRenewTitle')}
            modalContentText={t('user:dialog.userRenewRequest', { serviceNumber: original?.serviceNumber })}
            label={t('user:action.renew')}
            snackBarText={t('user:dialog.userRenewSuccess', { serviceNumber: original?.serviceNumber })}
            status={AccountSnapshotStatus.VERIFIED}
            onAccept={refetch}
            onCancel={() => closePopupRef.current?.()}
          />
        ),
        isHidden: ({
          row: {
            original: { isEditable, status }
          }
        }) => !isEditable || !(status === AccountSnapshotStatus.UNVERIFIED && AccountSnapshotStatus.REJECTED)
      },
      {
        id: permissionsKeysObject?.resendEmailConfirmation,
        onClick: ({ row }) => {
          closePopupRef.current?.();
          resendEmailConfirmation(row.original.id, row.original.login);
        },
        label: 'user:action.resendEmailConfirmation',
        isHidden: ({ row }) =>
          !resendEmailConfirmationVisible || row.original.status !== AccountSnapshotStatus.UNCONFIRMED
      },
      {
        id: permissionsKeysObject?.sendEmailToResetPassword,
        label: 'user:action.sendEmailToResetPassword',
        onClick: ({ row }) => {
          closePopupRef.current?.();
          sendEmailToResetPassword(row.original.email || row.original.unverifiedEmail, row.original.login);
        },
        isHidden: ({ row }) =>
          !isDevMode ||
          ([AccountSnapshotStatus.UNCONFIRMED, AccountSnapshotStatus.DELETED] as Array<AccountSnapshotStatus>).includes(
            row.original.status
          )
      }
    ],
    [isDevMode, resendEmailConfirmationVisible]
  );

  return (
    <TableLayout<AccountSnapshot, AccountSnapshot>
      {...tableProps}
      pageTitle={title || t('user:title')}
      xlsxDownload={{
        fileName: t('user:title'),
        pathToXLSXTranslation: 'user:field',
        apiRequest: params => {
          const queryParams = parseUsersListPayload({ ...params, ...additionalParams });
          return API.user.accountController.search(queryParams);
        }
      }}
      headerActions={
        <>
          {permissionsKeysObject?.showUsersFlag && (
            <TableButtonView
              tooltipTitle={t(
                onlyUnassignedToUnit ? 'user:action.showAllUsers' : 'user:action.showOnlyUnassignedToUnit'
              )}
              onClick={() => {
                setOnlyUnassignedToUnit(!onlyUnassignedToUnit);
              }}
              actionKey={permissionsKeysObject?.showUsersFlag}
            />
          )}
          {permissionsKeysObject?.add && (
            <TableHeaderButton
              label={t('user:action.addUser')}
              link={modulePath === 'users' ? routes.userCreate() : routes.userClientPortalCreate()}
              actionKey={permissionsKeysObject?.add}
            />
          )}
        </>
      }
      additionalFilters={additionalFilters}
      rowActions={(row, table) => (
        <>
          {renderTableActions([permissionsKeysObject?.details])(row, table)}
          <TableButtonMore>
            {close => {
              closePopupRef.current = close;

              return renderTableActions([
                permissionsKeysObject?.edit,
                permissionsKeysObject?.delete,
                permissionsKeysObject?.unlock,
                permissionsKeysObject?.lock,
                permissionsKeysObject?.restore,
                permissionsKeysObject?.reject,
                permissionsKeysObject?.verify,
                permissionsKeysObject?.resendEmailConfirmation,
                permissionsKeysObject?.sendEmailToResetPassword
              ])(row, table);
            }}
          </TableButtonMore>
        </>
      )}
    />
  );
}

export default UserListPage;
