import { Column, OnFilterChange } from 'react-table';

import { FetchSelectFilter, NUMBER_OF_AUTOCOMPLETE_RESULTS } from '@libs/common/v2';

import { API, ReportQueryKeysEnum } from '../../api';

interface IProps {
  column?: Column & { filterValue?: string; setFilter: (value: string | Array<string>) => void };
  onFilterChange: OnFilterChange;
}

function ReportSourcesFilter({ column, ...forwardProps }: IProps) {
  const fetchFunction = params =>
    API.mediatorDataSources.getReportSources(0, NUMBER_OF_AUTOCOMPLETE_RESULTS, null, null, null, params);

  const dataParser = data => {
    return [...data.data.content].map(({ id, name }: { id?: string; name: string }) => ({
      name,
      value: id,
      id
    }));
  };

  return (
    <FetchSelectFilter
      queryKey={ReportQueryKeysEnum.REPORT_SOURCES_FILTER}
      fetchFunctionResolver={fetchFunction}
      column={column}
      fetchedDataSelectParser={dataParser}
      getOptionSelected={(option, value) => option.name === value.name}
      shouldCheckVisibility
      {...forwardProps}
    />
  );
}

export default ReportSourcesFilter;
