import { ReactNode } from 'react';
import { Grid, GridProps } from '@mui/material';

function Row({
  renderNodes,
  components,
  children,
  ...forwardProps
}: {
  renderNodes: (children: ReactNode, components: any) => JSX.Element | JSX.Element[];
  components: any;
} & GridProps) {
  return (
    <Grid {...forwardProps} container direction="row">
      {renderNodes(children, components)}
    </Grid>
  );
}

export default Row;
