import { useTranslation } from 'react-i18next';
import { FolderPermissionSnapshot } from '@ibtm/domain';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Chip, Typography, useValueStyles } from '@libs/common/v2';
import { convertCalendarDate, getValue, important } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { parseLicenseDurationToString } from '@libs/domain/folder';

interface IProps {
  licence: FolderPermissionSnapshot;
}

function Licence({ licence }: IProps) {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();
  const globalClasses = useValueStyles({});
  const classes = useStyles();
  return (
    <div className={clsx(classes.gridSection, 'pb-5')}>
      <div>
        <div className={classes.row}>
          <Typography
            component="div"
            themeVariant="textSm.medium"
            className={clsx(globalClasses.labelWrapper, classes.label, 'pr-5')}
          >
            {t('folder:sidebar.field.permissionName')}
          </Typography>
          <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
            {translate(
              licence.typeKey.includes('permission')
                ? DomainDictionaryEnum.PERMISSION_TYPE
                : DomainDictionaryEnum.LICENSE_TYPE,
              licence.typeKey,
              t('emptyMark')
            )}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography
            component="div"
            themeVariant="textSm.medium"
            className={clsx(globalClasses.labelWrapper, classes.label, 'pr-5')}
          >
            {t('folder:sidebar.field.permissionNumber')}
          </Typography>
          <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
            {licence.number ?? t('emptyMark')}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography
            component="div"
            themeVariant="textSm.medium"
            className={clsx(globalClasses.labelWrapper, classes.label, 'pr-5')}
          >
            {t('folder:sidebar.field.formNumber')}
          </Typography>
          <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
            {licence.formNumber ?? t('emptyMark')}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography
            component="div"
            themeVariant="textSm.medium"
            className={clsx(globalClasses.labelWrapper, classes.label, 'pr-5')}
          >
            {t('folder:sidebar.field.releasedBy')}
          </Typography>
          <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
            {translate(
              DomainDictionaryEnum.AUTHORITY,
              licence.issuingAuthority,
              licence.issuingAuthority ?? t('emptyMark')
            )}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography
            component="div"
            themeVariant="textSm.medium"
            className={clsx(globalClasses.labelWrapper, classes.label, 'pr-5')}
          >
            {t('folder:sidebar.field.excerpts')}
          </Typography>
          <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
            {licence.numberOfCopies ?? t('emptyMark')}
          </Typography>
        </div>
      </div>
      <div>
        <div className={classes.row}>
          <Typography
            component="div"
            themeVariant="textSm.medium"
            className={clsx(globalClasses.labelWrapper, classes.label, 'pr-5')}
          >
            {t('folder:sidebar.field.granted')}
          </Typography>
          <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
            {convertCalendarDate(licence.printDate) ?? t('emptyMark')}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography
            component="div"
            themeVariant="textSm.medium"
            className={clsx(globalClasses.labelWrapper, classes.label, 'pr-5')}
          >
            {t('folder:sidebar.field.permissionStatus')}
          </Typography>
          {licence?.statusKey ? (
            <Chip title={getValue(translate(DomainDictionaryEnum.PERMISSION_STATUS, licence?.statusKey))} />
          ) : (
            <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
              {t('emptyMark')}
            </Typography>
          )}
        </div>
        <div className={classes.row}>
          <Typography
            component="div"
            themeVariant="textSm.medium"
            className={clsx(globalClasses.labelWrapper, classes.label, 'pr-5')}
          >
            {t('folder:sidebar.field.permissionDuration')}
          </Typography>
          <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
            {licence.typeKey.includes('permission')
              ? t('emptyMark')
              : parseLicenseDurationToString(licence.durationOfValidity)}
          </Typography>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  row: {
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  label: {
    marginBottom: important('8px')
  },
  gridSection: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '1rem'
  }
}));

export default Licence;
