import { ExternalNamedObject } from '@avispon/dictionary';
import { DefaultApiPageUsersRequest } from '@avispon/group';

import { BooleanValue, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';

import usePrintsGetUnitSigner from './usePrintsGetUnitSigner';

function SignatoryBooleanValue({ id }) {
  const { signerDetails } = usePrintsGetUnitSigner();
  const isSigner = signerDetails?.user?.id === id;
  return isSigner && <BooleanValue value />;
}

const useSignatoryColums = () => {
  const { createColumns } = useCreateColumns<ExternalNamedObject>({
    pathToTranslate: 'prints:signatory.field'
  });

  const mappedSortFields: TableSortMapper<ExternalNamedObject> = {
    name: 'userName'
  };

  const mappedFilterFields: TableFilterMapper<ExternalNamedObject, DefaultApiPageUsersRequest> = {
    name: (name: string) => ({ userName: name })
  };

  const columns = createColumns([
    {
      header: 'nameAndSurname',
      type: 'TEXT',
      accessor: 'name'
    },
    {
      id: 'id',
      header: 'signatory',
      type: 'BOOLEAN',
      customAccessor: row => <SignatoryBooleanValue id={row.id} />,
      isTooltipHidden: true,
      isFilterable: false,
      isSortable: false
    }
  ]);

  return { columns, mappedFilterFields, mappedSortFields };
};

export default useSignatoryColums;
