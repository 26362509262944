import { useEffect, useState } from 'react';
import { UseFormGetValues, UseFormReset, UseFormSetValue } from 'react-hook-form';

import { FormMode, useViewModesV2 } from '@libs/common/v2';

import { AlertDictionaryEntryNameEnum, DefaultAlertFromValues } from '@libs/alert';
import { useAlertDetailsQuery } from '@libs/alert/api';
import { parseAlertDetailsDataToInitialFormValues } from '@libs/alert/utils/alerts.parsers';
import { useDictionaryEntryValues } from '@libs/dictionary';

interface Props {
  id: string;
  formMode: FormMode;
  reset: UseFormReset<Record<string, any>>;
  getValues: UseFormGetValues<Record<string, any>>;
  setValue: UseFormSetValue<Record<string, any>>;
  defaultAlertFormValues: DefaultAlertFromValues;
}

export default function useAlertForm({ id, formMode, reset, getValues, setValue, defaultAlertFormValues }: Props) {
  const { data } = useAlertDetailsQuery(id, { enabled: Boolean(id) });
  const channelKeys = useDictionaryEntryValues(AlertDictionaryEntryNameEnum.ALERT_CHANNEL);
  const [initialFormValues, setInitialFormValues] = useState<DefaultAlertFromValues>(defaultAlertFormValues);
  const { editMode, viewMode } = useViewModesV2(formMode);

  const setChannelKeysValue = () => {
    if (editMode || viewMode) {
      const channels = getValues('channelKeys');
      const updatedChannels = channelKeys.filter(option =>
        channels.find(val => val === option.value || val.value === option.value)
      );
      setValue('channelKeys', updatedChannels);
    }
  };

  const setRecipientsViewValues = () => {
    if (viewMode) {
      const emails = getValues('recipients.emails');
      setValue('recipients.emails', emails?.[0]);
    }
  };

  const setAccountSelectorValues = () => {
    const accounts = getValues('recipients.accountSelector.accounts');
    if (accounts.length) {
      setValue(
        'recipients.accountSelector.accounts',
        accounts.map(i => ({
          ...i,
          value: i
        }))
      );
    }

    const groups = getValues('recipients.accountSelector.groups');
    if (groups.length) {
      setValue(
        'recipients.accountSelector.groups',
        groups.map(i => ({
          ...i,
          value: i
        }))
      );
    }

    const groupMembershipUnits = getValues('recipients.accountSelector.groupMembershipUnits');
    if (groupMembershipUnits.length) {
      setValue(
        'recipients.accountSelector.groupMembershipUnits',
        groupMembershipUnits.map(i => ({
          ...i,
          value: i
        }))
      );
    }
  };

  useEffect(() => {
    if (data) {
      setChannelKeysValue();
      setRecipientsViewValues();
      if (id) {
        setInitialFormValues(parseAlertDetailsDataToInitialFormValues(data, defaultAlertFormValues));
        reset(parseAlertDetailsDataToInitialFormValues(data, defaultAlertFormValues));
        setAccountSelectorValues();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, id]);

  return { initialFormValues, data };
}
