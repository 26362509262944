import { FieldPath, FieldValues, useWatch } from 'react-hook-form';
import { AddressUpdateRequest } from '@ibtm/domain';

import { partialTranslate } from '@libs/common';
import {
  DictionarySelectField,
  GridItem,
  GridLayout,
  InputMode,
  SwitchField,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import { useSetInitialAddressType } from '../../hooks';
import { AddressSection } from '../address-content';

const getLabel = partialTranslate('address:fields');

interface IProps {
  formGroupName: string | FieldPath<FieldValues>;
  addressFormGroupName?: string | FieldPath<FieldValues>;
  hiddenFields?: Array<keyof AddressUpdateRequest>;
  inputMode?: InputMode;
  isCountryFieldAvaliable: boolean;
}

function BusinessAddress({
  formGroupName,
  addressFormGroupName = formGroupName,
  hiddenFields,
  inputMode,
  isCountryFieldAvaliable
}: IProps) {
  const { control } = useFormV2Context();

  const hideAddressFields = useWatch({
    control,
    name: `${formGroupName}.sameAsMainAddress` as FieldPath<FieldValues>
  }) as boolean;

  const getFieldName = (fieldName: any) => typedNameV2<any>(`${formGroupName}.${fieldName}`);

  const typeKey = getFieldName('typeKey');

  useSetInitialAddressType(typeKey, DomainDictionaryEntry.ADDRESS_TYPE.HQ);

  return (
    <GridLayout itemProps={{ xs: 12, sm: 6 }}>
      <SwitchField
        name={getFieldName('sameAsMainAddress')}
        label={getLabel('sameAsMainAddress')}
        {...(inputMode && { inputMode })}
      />

      {!hideAddressFields && (
        <GridItem xs={12}>
          <AddressSection
            formGroupName={addressFormGroupName}
            hiddenFields={hiddenFields}
            isCountryFieldAvaliable={isCountryFieldAvaliable}
            {...(inputMode && { inputProps: { inputMode } })}
            isValidationEnabled={inputMode !== InputMode.VIEW}
          />
        </GridItem>
      )}

      <DictionarySelectField
        name={typeKey}
        label={getLabel('type')}
        dictionaryName={DomainDictionaryEnum.ADDRESS_TYPE}
        inputMode={InputMode.VIEW}
      />
    </GridLayout>
  );
}

export default BusinessAddress;
