import moment from 'moment';
import {
  array as YupArray,
  boolean as YupBoolean,
  number as YupNumber,
  object as YupObject,
  string as YupString
} from 'yup';

import { i18n } from '@libs/common';
import { ValidatorEnums } from '@libs/common/v2';
import { isDateValid, ValidatorDetails } from '@libs/common/v2/form/validation';

const validationRangeOfData = (
  value: string,
  date: string,
  granularity: moment.unitOfTime.StartOf,
  isCheckingWithBorderingValue: boolean,
  isCheckingMax: boolean
) => {
  const validationFunctionWithBorderValue: keyof moment.Moment = isCheckingMax ? 'isSameOrBefore' : 'isSameOrAfter';
  const validationFunction: keyof moment.Moment = isCheckingMax ? 'isBefore' : 'isAfter';

  return isCheckingWithBorderingValue
    ? moment(value)?.[validationFunctionWithBorderValue](date?.match(/today/i) ? moment() : moment(date), granularity)
    : moment(value)?.[validationFunction](date?.match(/today/i) ? moment() : moment(date), granularity);
};

const createValidationRegistry = (contextValidators?: { [key: string]: ValidatorDetails }) => {
  return {
    TEXT: {
      _base: YupString().nullable(),
      /**
       * aby użyć reguły przekazujemy do properties fielda typu "TEXT"
       * "yupValidation": {
       *    "required":true
       *  }
       */
      required: YupString().required(),
      /**
       * aby użyć reguły przekazujemy do properties fielda typu "TEXT"
       * "yupValidation": {
       *    "max": 20
       *  }
       * wartość "max" będzie przekazana jako parametr do funkcji, możemy zdefiniować własne funkcje przyjmujące obiekt, tablicę etc.
       * następnie przekazać dane do funkcji z poziomu pliku json
       */
      max: (maxValue: number) => YupString().max(maxValue),
      min: (minValue: number) => YupString().min(minValue),
      length: (length: number) => YupString().length(length),
      email: YupString().email(),
      /**
       * jeżeli pole ma dodatkową walidację, ale nie jest wymagane
       */
      notRequired: YupString().transform(value => value || null),
      contextValidator: (validator: ValidatorEnums) => {
        const validation = contextValidators?.[validator];
        if (validation) {
          return YupString().matches(new RegExp(validation.regex), validation.message);
        }
        return YupString().nullable();
      }
    },
    AUTOCOMPLETE: {
      _base: YupObject().nullable(),
      required: YupObject().required()
    },
    DICTIONARY: {
      _base: YupObject().nullable(),
      required: YupObject().required()
    },
    DICTIONARY_QUICK_CHANGEABLE: {
      _base: YupObject().nullable(),
      required: YupObject().required()
    },
    NUMBER: {
      _base: YupNumber().nullable(),
      required: YupNumber().required(),
      max: (maxValue: number) => YupNumber().max(maxValue),
      min: (minValue: number) => YupNumber().min(minValue),
      /**
       * reguła wykorzystywana w przypadku gdy potrzeba sprawdzić minimalną wartość z sumy dwóch pól
       * "fieldId" oraz "fieldName" to wartości drugiego pola, które będą brane pod uwagę przy sprawdzaniu testu
       */
      dependentSumMin: ({
        fieldId,
        fieldName,
        minValue = 1,
        message
      }: {
        fieldId: string;
        fieldName: string;
        minValue?: number;
        message?: string;
      }) => {
        const validationMessage = message ?? i18n.t('validation:dependentSumMin', { field: fieldName, minValue });

        return YupNumber().test('testMinSumValue', validationMessage, function checkMinSumValue(value) {
          const dependentValue = this.parent[fieldId] as number;

          return value + dependentValue >= minValue;
        });
      },
      /**
       * reguła służąca do wskazania maksymalnej wartości dla pola (NUMBER)
       * ale w przypadku gdy zależna jest ona od innego pola i może zmieniać się dynamicznie
       * "fieldId" i "fieldName" dotyczą drugiego pola, które bedzie ograniczeniem
       */
      dependentMax: ({ fieldId, fieldName, message }: { fieldId: string; fieldName?: string; message?: string }) => {
        const validationMessage = fieldName
          ? i18n.t('validation:dependentMax', { field: fieldName })
          : message ?? i18n.t('validation:max');

        return YupNumber().test('testMaxValue', validationMessage, function checkMaxValue(value) {
          const dependentValue = this.parent[fieldId] as number;

          return value <= dependentValue;
        });
      }
    },
    BOOLEAN: {
      _base: YupBoolean().nullable(),
      required: YupBoolean().required()
    },
    DATETIME: {
      _base: YupString().nullable(),
      required: ({ requiredMessage, invalidDateMessage }: { requiredMessage?: string; invalidDateMessage?: string }) =>
        YupString().required(requiredMessage).concat(isDateValid(invalidDateMessage)),
      min: ({
        date,
        message,
        granularity = 'D',
        isSameOrAfter = true
      }: {
        /**  wartość "TODAY" jeżeli wartość ma być mniejsza od dnia dzisiejszego */
        date: string | 'TODAY';
        message?: string;
        granularity?: moment.unitOfTime.StartOf;
        isSameOrAfter?: boolean;
      }) =>
        YupString()
          .test('isValidDate', message || i18n.t('validation:minDateToday'), (inputValue: string) => {
            return validationRangeOfData(inputValue, date, granularity, isSameOrAfter, false);
          })
          .concat(isDateValid()),
      max: ({
        /**  wartość "TODAY" jeżeli wartość ma być większa od dnia dzisiejszego */
        date,
        message,
        granularity = 'D',
        isSameOrBefore = true
      }: {
        date: string | 'TODAY';
        message?: string;
        granularity?: moment.unitOfTime.StartOf;
        isSameOrBefore?: boolean;
      }) =>
        YupString()
          .test('isValidDate', message || i18n.t('validation:maxDateToday'), (inputValue: string) => {
            return validationRangeOfData(inputValue, date, granularity, isSameOrBefore, true);
          })
          .concat(isDateValid()),
      dependent: ({
        isCheckingMax,
        dependentFieldId,
        dependentFieldName,
        message,
        range,
        granularity = 'D',
        isCheckingWithMarginalDate = true
      }: {
        isCheckingMax?: boolean;
        /* id pola, z którego chcemy pobrać datę */
        dependentFieldId?: string;
        /* name pola, z którego chcemy pobrać datę, służąca do wyświetlenia komunikatu */
        dependentFieldName?: string;
        /* modyfikuje datę walidacji o podany czas */
        range?: { number: number; unitOfTime: moment.unitOfTime.Base };
        message?: string;
        granularity?: moment.unitOfTime.StartOf;
        isCheckingWithMarginalDate?: boolean;
      }) => {
        const validationMessage = dependentFieldName
          ? i18n.t(isCheckingMax ? 'validation:maxDateRange' : 'validation:minDateRange', {
              rangeNumber: range.number.toString(),
              rangeUnit: range.unitOfTime,
              fieldName: dependentFieldName
            })
          : message ?? i18n.t(isCheckingMax ? 'validation:max' : 'validation:min');

        return YupString()
          .test('isValidDate', validationMessage, function checkIsValidDate(inputValue: string) {
            const validationDate = this.parent[dependentFieldId] as string;

            if (!moment(validationDate).isValid() || validationDate === undefined) {
              return this.createError({
                message: i18n.t('validation:notFoundValue', { fieldName: dependentFieldName ?? '' })
              });
            }

            if (range) {
              moment(validationDate)?.add(range.number, range.unitOfTime);
            }

            return validationRangeOfData(
              inputValue,
              validationDate,
              granularity,
              isCheckingWithMarginalDate,
              isCheckingMax
            );
          })
          .concat(isDateValid());
      }
    },
    multiple: {
      AUTOCOMPLETE: {
        _base: YupArray().nullable(),
        required: YupArray().of(YupObject()).required(),
        /**
         * reguła umożliwiająca dodanie wymagalności w zależności od wartości innego pola
         * 'fieldId' - id pola, na podstawie którego włączana będzie walidacja
         * 'whenDependentValueEqual' - tablica warunków (jeśli choć jeden zostanie spełniony, to )
         * 'arrayMinLength' - parametr dodatkowy, umożliwiający na dodatkową walidację ilości wybranych opcji
         */
        dependentRequired: ({
          fieldId,
          whenDependentValueEqual,
          arrayMinLength
        }: {
          fieldId: string;
          whenDependentValueEqual: any[];
          arrayMinLength?: number;
        }) => {
          return YupArray()
            .of(YupObject())
            .when(fieldId, (value, schema) => {
              if (whenDependentValueEqual.includes(value)) {
                if (arrayMinLength) {
                  return schema
                    .required()
                    .test('isValidLength', i18n.t('validation:arrayMin', { min: arrayMinLength }), value => {
                      return value.length >= arrayMinLength;
                    });
                }
                return schema.required();
              }

              return schema;
            });
        }
      },
      DICTIONARY: {
        _base: YupArray().nullable(),
        required: YupArray().of(YupObject()).required()
      },
      DICTIONARY_QUICK_CHANGEABLE: {
        _base: YupArray().nullable(),
        required: YupArray().of(YupObject()).required()
      }
    }
  };
};

export default createValidationRegistry;
