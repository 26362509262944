import { ApplicationCopyRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export const copyDataFromApplication = async ({
  applicationId,
  copyPayload
}: {
  applicationId: string;
  copyPayload: ApplicationCopyRequest;
}) => {
  return API.application.copyDataFromAnotherApplication(applicationId, copyPayload, {
    ...endpointsConfig.copyDataFromAnotherApplication
  });
};

function useCopyDataFromApplicationMutation() {
  return useMutation(copyDataFromApplication);
}

export default useCopyDataFromApplicationMutation;
