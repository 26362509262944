import React from 'react';
import { actions } from 'react-table';

import { ISingleColumn, ISpecialColumnEnum, ITableLayoutColumn } from '@libs/common/v2';

/**
 * Funkcja do tworzenia nowej kolumny w tabeli, przyjmuje już istniejące kolumny,
 * i tworzy nowe podmieniając tylko dane kolumny wyświetlane w tabeli.
 */
function createNewColumn(column, columnsToInsert) {
  return columnsToInsert.map(col => ({
    ...column,
    ...col,
    // dodajemy width który jest użyty przy renderowaniu wierszy i funkcję render, która poprawnie wyświetli head kolumny.
    width: 200,
    render: () => col.Header,
    // Nadpisanie styli kolumny na najdłuższą domyślną wersję (200px)
    getHeaderProps: () => ({
      style: {
        boxSizing: 'border-box',
        flex: '200 0 auto',
        minWidth: '0px',
        position: 'relative',
        width: '200px'
      }
    }),
    isErasable: true
  }));
}

// Dodanie kolumn na koniec tabeli
function insertLastColumn(state, columns, payload) {
  // Ostatnia kolumna, do sprawdzenia czy tabela ma kolumnę akcji
  const lastColumn = columns.slice(-1)[0];

  // Przygotowanie przekazanych kolumn, dodawne kolumny nie mogą być sortowane i filtrowane,
  // tabela powinna byc zablokowana na tym etapie ( chodzi o zablokowanie akcji sortowania i filtrowania tabeli ),
  // ale to dodatkowe zabezpieczenie.
  const parsedColumns = payload.columnsToInsert.map(
    ({ id: colId, originalId, idIndexing = index => `${colId}.${index}`, ...restCol }, index) => {
      // Sprawdzenie czy już dodano taką kolumnę, jak tak to pobranie jej indexu
      const lastColumnWithSameIdIndex =
        state?.addedColumnsIds
          ?.filter((ids, arrIndex) => ids.find(el => el === (idIndexing(arrIndex + 1) as never)))
          .slice(-1)?.[0]
          ?.[index]?.split('')
          ?.reduce((acc, char) => {
            if (Number.isInteger(+char)) {
              return `${acc}${char}`;
            }
            return acc;
          }, '') ?? 0;

      // funkcja sprawdza czy istnieje już kolumna o takim id
      const columnFound = newIndex => columns.find(column => column.originalId === idIndexing(newIndex));

      // Dodanie do id indexu tablicy, aby wyłapał to hook form i odpowiednio to zmappował
      const indexedId = () => {
        let newColumnIndex = 0;
        // To nie jest pierwsze dodawanie kolumny więc dodajemy nową kolumnę z indexem powiększonym o 1 plus orginalnych
        // Jest to 0-index konwersja do header'a jest w create-tabele-column,
        // czyli do tego indexu jest +1, aby kolumna 0 była wyświetlona jako kolumną 1
        if (+lastColumnWithSameIdIndex > 0) {
          return idIndexing(+lastColumnWithSameIdIndex + 1);
        }
        // to pierwsze dodawanie więc zaczynając od 0 szukamy czy istnieje już taka kolumna w początkowych kolumnach
        // jeżeli tak to szukamy indexu ostatniej, jeżeli nie to dodajemy z indexem 0.
        while (columnFound(newColumnIndex)) {
          newColumnIndex += 1;
        }

        return idIndexing(newColumnIndex + +lastColumnWithSameIdIndex);
      };

      return {
        ...restCol,
        id: indexedId(),
        isSortable: false,
        isFilterable: false,
        filter: null
      };
    }
  );

  // Nowe kolumny po stworzeniu.
  const newColumns = createNewColumn(columns[1], payload.createColumns(parsedColumns));

  const newHeaderColumns = newColumns.map(col => ({
    ...col,
    render: () => col.Header
  }));

  return {
    ...state,
    rowId: payload?.id,
    isEditing: true,
    // Tablica tablic z dodawanymi kolumnami, używana do znajdywania i usuwania kolumn.
    addedColumnsIds: [...state.addedColumnsIds, newColumns.map(col => col.originalId)],
    headerGroups: [
      // Sprawdzamy czy jest to pierwsze dodawanie czy kolejne,
      // oraz jeżeli tabela posiada kolumnę akcji
      // to nowe kolumny wstawiamy przed nią.
      state?.headerGroups?.[0]?.headers?.length
        ? {
            headers:
              lastColumn.id === ISpecialColumnEnum.ACTION
                ? [...(state?.headerGroups?.[0]?.headers.slice(0, -1) || []), ...newHeaderColumns, lastColumn]
                : [...(state?.headerGroups?.[0]?.headers || []), ...newHeaderColumns]
          }
        : {
            headers:
              lastColumn.id === ISpecialColumnEnum.ACTION
                ? [...columns.slice(0, -1), ...newHeaderColumns, lastColumn]
                : [...columns, ...newHeaderColumns]
          }
    ],
    // Dodajemy dodatkowe komórki do wyrenderowania w wierszu. Ich brak rozjechałby tabelę.
    // Tak samo weryfikujemy czy jest to pierwsze dodanie czy kolejne.
    additionalCells: {
      last: state?.additionalCells?.last?.length
        ? [
            ...(state?.additionalCells?.last || []),
            ...newColumns.map(col => ({ column: { ...col }, originalId: col.originalId, id: col.id, render: () => '' }))
          ]
        : newColumns.map(col => ({ column: { ...col }, originalId: col.originalId, id: col.id, render: () => '' }))
    }
  };
}

// Funkcja używana przy usuwaniu kolumn, odfiltrowuje usunięte kolumny,
// zwraca tablicę odfiltrowanych kolumn, oraz id kolumn które zostaną usunięte
function filterColumns(columns, columnIdsToFilter) {
  return columns.reduce(
    (acc, column) => {
      if (columnIdsToFilter.includes(column.originalId)) {
        return {
          ...acc,
          filteredId: [...acc.filteredId, column.originalId]
        };
      }
      return { ...acc, filteredColumns: [...acc.filteredColumns, column] };
    },
    { filteredColumns: [], filteredId: [] }
  );
}

// Usunięcie wybranych dodanych kolumn z tabeli.
function deletePickedColumns(state, { id, setValue }) {
  // Znalezienie kolumn dodanych razem z usuwaną kolumną i odfiltrowanie ich
  // Zwracamy tablicę kolumn które zostaną usunięte oraz nowy state id kolumn dodanych
  const { filtered, newAddedColumnsIds } = state.addedColumnsIds.reduce(
    (acc, ids) => {
      if (ids.includes(id)) {
        return { ...acc, filtered: ids };
      }
      return { ...acc, newAddedColumnsIds: [...acc.newAddedColumnsIds, ids] };
    },
    { filtered: [], newAddedColumnsIds: [] }
  );

  // Odfiltrowujemy kolumny z header i body które mają być usunięte
  const { filteredColumns: newHeaderColumns } = filterColumns(state.headerGroups[0].headers, filtered);
  const { filteredColumns: newAdditionalLastColumns, filteredId } = filterColumns(state.additionalCells.last, filtered);

  // Jeżeli nadal są jakieś kolumny dodane do tabeli
  // Ustawiamy w form Context wartości usuniętych kolumn na `undefined`
  // filtr pustych wartości zostanie następnie odfiltrowany przed walidacją w useCustomYupResolver
  if (newAddedColumnsIds.length) {
    filteredId.forEach(item => {
      setValue(`${state.rowId}.${item}`, undefined);
    });
  }
  return {
    ...state,
    addedColumnsIds: newAddedColumnsIds.length ? newAddedColumnsIds : [],
    additionalCells: newAdditionalLastColumns.length
      ? {
          last: newAdditionalLastColumns
        }
      : null,
    headerGroups: newHeaderColumns.length
      ? [
          {
            headers: newHeaderColumns
          }
        ]
      : null
  };
}

// eslint-disable-next-line consistent-return
export function useAdditionalColumnsReducer(state, action, prev, table) {
  switch (action.type) {
    case actions.insertColumnLast:
      return insertLastColumn(state, table.headerGroups[0].headers, action?.payload);
    case actions.cancelInsertingColumns:
      return {
        ...state,
        addedColumnsIds: [],
        headerGroups: null,
        additionalCells: null,
        page: null,
        rowId: '',
        isEditing: false
      };
    case actions.deleteAddedColumns:
      return deletePickedColumns(state, action.payload);
    case actions.init:
      return {
        ...state,
        addedColumnsIds: [],
        headerGroups: table.headerGroups ?? null,
        additionalCells: null,
        rowId: '',
        isEditing: false
      };
  }
}

export function useAdditionalColumnsInstance(instance) {
  const { dispatch } = instance;

  const insertColumnLast = React.useCallback(
    <T extends Record<string, unknown>, K extends Record<string, any> = T>(payload: {
      id: string;
      columnsToInsert: ISingleColumn<T, K>[];
      createColumns: (columns: ISingleColumn<T, K>[]) => ITableLayoutColumn<T, K>[];
    }) => dispatch({ type: actions.insertColumnLast, payload }),
    [dispatch]
  );
  const cancelInsertingColumns = React.useCallback(
    () => dispatch({ type: actions.cancelInsertingColumns }),
    [dispatch]
  );
  const deleteAddedColumns = React.useCallback(
    (id: string, setValue) => dispatch({ type: actions.deleteAddedColumns, payload: { id, setValue } }),
    [dispatch]
  );

  Object.assign(instance, {
    insertColumnLast,
    cancelInsertingColumns,
    deleteAddedColumns
  });
}
