import { ExploitationBaseCreateRequest, ExploitationBaseDetails, ExploitationBaseUpdateRequest } from '@ibtm/domain';
import { omit } from 'lodash';

import { convertCalendarDate, getCalendarDate } from '@libs/common/v2/utils';

import { CountryEnum, ExploitationBaseFormData } from '../model';

export const parseExploitationBaseUpdateRequest = (
  editData: ExploitationBaseFormData
): ExploitationBaseUpdateRequest => ({
  statusKey: editData.statusKey?.value,
  typeKeys: editData.typeKeys?.map(({ value }) => value),
  validFrom: getCalendarDate(editData.validFrom),
  validTo: getCalendarDate(editData.validTo),
  address: {
    ...editData.address,
    postCode: editData.address.postCode,
    voivodeshipKey: editData?.address?.voivodeshipKey?.value,
    version: editData.addressVersion
  },
  exploitationBaseInspections: editData.inspection.inspectionResultKey && [
    {
      ...editData.inspection,
      inspectionDate: getCalendarDate(editData.inspection.inspectionDate),
      inspectionResultKey: editData.inspection.inspectionResultKey,
      version: editData.version
    }
  ],
  linkedExploitationBaseId: editData?.linkedExploitationBaseId,
  cancelledExploitationBaseId: editData?.cancelledExploitationBase?.value,
  version: editData.version
});

export const parseExploitationBaseCreateRequest = (
  createData: ExploitationBaseFormData
): ExploitationBaseCreateRequest => {
  return {
    typeKeys: createData.typeKeys?.map(({ value }) => value),
    validFrom: getCalendarDate(createData.validFrom),
    validTo: getCalendarDate(createData.validTo),
    address: {
      ...omit(createData.address, ['version']),
      postCode: createData?.address?.postCode,
      countryCodeKey: CountryEnum.POLAND,
      voivodeshipKey: createData?.address?.voivodeshipKey?.value
    },
    exploitationBaseInspections: createData.inspection?.inspectionResultKey
      ? [
          {
            ...createData.inspection,
            inspectionDate: getCalendarDate(createData.inspection.inspectionDate),
            inspectionResultKey: createData.inspection?.inspectionResultKey
          }
        ]
      : [],
    cancelledExploitationBaseId: createData?.cancelledExploitationBase?.value,
    applicationId: createData.applicationId
  };
};

export const parseExploitationBaseFormData = (data: ExploitationBaseDetails): ExploitationBaseFormData => {
  return {
    typeKeys: data.typeKeys.map(value => ({ value })),
    addressVersion: data.addressVersion,
    address: {
      version: data.addressVersion,
      voivodeshipKey: { value: data.voivodeshipKey },
      city: data.city,
      countryCodeKey: data.countryCodeKey,
      propertyNumber: data.propertyNumber,
      postCity: data.postCity,
      street: data.street,
      postCode: data.postCode
    },
    inspection: {
      inspectionDate: convertCalendarDate(data.lastInspectionResult?.inspectionDate),
      inspectionResultKey: data.lastInspectionResult?.inspectionResultKey,
      remarks: data.lastInspectionResult?.remarks
    },
    addressTypeKey: { value: data.addressTypeKey },
    exploitationBaseInspections: data?.inspections?.map(item => ({
      inspectionDate: convertCalendarDate(item.inspectionDate),
      inspectionResultKey: item.inspectionResultKey,
      remarks: item.remarks
    })),
    modified: data.modified,
    modifier: data.modifier,
    created: data.created,
    author: data.author,
    linkedExploitationBaseId: data.linkedExploitationBase?.id,
    cancelledExploitationBase: {
      value: data.cancelledExploitationBase?.id,
      name: data?.cancelledExploitationBase?.address
    },
    statusKey: { value: data.statusKey },
    validFrom: convertCalendarDate(data.validFrom),
    validTo: convertCalendarDate(data.validTo),
    version: data.version
  };
};
