import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { EkmtGroup } from '@ibtm/domain';
import { LimitType } from '@ibtm/domain/dist/models/limit-type';
import * as Yup from 'yup';

import { partialTranslate } from '@libs/common';
import {
  AutocompleteSelectField,
  CheckboxField,
  Dialog,
  DictionarySelectField,
  FormV2Context,
  FormV2ContextState,
  GridItem,
  GridLayout,
  NumberInputField,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import { IResourceTypeFormFields } from '../../../model';
import { getResourcesInitialYear } from '../../../utils';
import { ResourceTypeAutocompleteField } from '../resource-type-autocomplete-field/ResourceTypeAutocompleteField';

import { DuplicateResourceTypeSearchParams } from './form-type.utils';
import { FreeSaleRuleSection, type FreeSaleRulesProps } from './FreeSaleRuleSection';

const getLabel = partialTranslate('resource:fields');

export type ResourceTypeDialogProps = {
  closeDialog: () => void;
  onConfirm: (param: { formData: IResourceTypeFormFields; setIsLoading: (isLoading: boolean) => void }) => void;
  handleCheckDuplicateResourceTypes: (params: DuplicateResourceTypeSearchParams) => Promise<{ hasDuplicate: boolean }>;
  defaultValues?: IResourceTypeFormFields;
  title: string;
  isLoading?: boolean;
  freeSaleDefaultValues: FreeSaleRulesProps['defaultValues'];
};

export function ResourceTypeDialog({
  title,
  defaultValues,
  closeDialog,
  onConfirm,
  isLoading,
  handleCheckDuplicateResourceTypes,
  freeSaleDefaultValues
}: ResourceTypeDialogProps) {
  const [t] = useTranslation();

  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    setError,
    unregister,
    resetField
  } = useForm<IResourceTypeFormFields>({
    mode: 'onChange',
    resolver: yupResolver(
      Yup.object({
        formType: Yup.object().nullable().required(),
        formGroup: Yup.object().nullable().required(),
        documentTemplate: Yup.object().nullable(),
        year: Yup.number().required(),
        country: Yup.object()
          .nullable()
          .when('formType', formType => {
            if (formType?.value === DomainDictionaryEntry.RESOURCE_FORM_TYPE.SINGLE_PERMIT) {
              return Yup.object().nullable().required();
            }

            return Yup.object().nullable();
          }),
        euroClass: Yup.object()
          .nullable()
          .when(['formType', 'limitType'], (formType, limitType) => {
            if (
              formType?.value === DomainDictionaryEntry.RESOURCE_FORM_TYPE.SINGLE_PERMIT ||
              (formType?.value === DomainDictionaryEntry.RESOURCE_FORM_TYPE.EKMT_PERMIT &&
                limitType?.value === LimitType.FREE_DISPOSAL)
            ) {
              return Yup.array().nullable().required();
            }

            return Yup.array().nullable();
          }),
        ekmtEuroClass: Yup.object()
          .nullable()
          .when('ekmtType', ekmtType => {
            if (ekmtType) {
              return Yup.object().nullable().required();
            }

            return Yup.object().nullable();
          }),
        ekmtGroup: Yup.object()
          .nullable()
          .when('ekmtType', ekmtType => {
            if (ekmtType) {
              return Yup.object().nullable().required();
            }

            return Yup.object().nullable();
          }),
        name: Yup.object().nullable().required(),
        insuredValue: Yup.number().when('formType', formType => {
          if (formType?.value === DomainDictionaryEntry.RESOURCE_FORM_TYPE.SINGLE_PERMIT) {
            return Yup.number().nullable().required();
          }

          return Yup.number().nullable();
        }),
        requiredLength: Yup.number()
          .transform(value => (Number.isNaN(value) ? undefined : value))
          .when('isLeadingZeros', {
            is: value => value,
            then: Yup.number()
              .transform(value => (Number.isNaN(value) ? undefined : value))
              .required(),
            otherwise: Yup.number().transform(value => (Number.isNaN(value) ? undefined : value))
          }),
        prefix: Yup.string().when('isPrefix', {
          is: value => value,
          then: Yup.string().required(),
          otherwise: Yup.string()
        }),
        transitTypeKey: Yup.object().nullable().required()
      })
    ),
    defaultValues: defaultValues ?? {
      formType: {
        value: DomainDictionaryEntry.RESOURCE_FORM_TYPE.SINGLE_PERMIT,
        name: DomainDictionaryEntry.RESOURCE_FORM_TYPE.SINGLE_PERMIT
      },
      isLeadingZeros: false,
      isPrefix: false,
      ekmtType: false,
      year: getResourcesInitialYear()
    }
  });

  const typeValueWatcher = watch('formType');
  const nameValueWatcher = watch('name');
  const yearValueWatcher = watch('year');
  const isLeadingZerosWatcher = watch('isLeadingZeros');
  const isPrefixWatcher = watch('isPrefix');
  const isEkmtTypeWatcher = watch('ekmtType');
  const limitTypeWatcher = watch('limitType');

  const foreignResourceTypes = [
    DomainDictionaryEntry.RESOURCE_FORM_TYPE.EKMT_PERMIT,
    DomainDictionaryEntry.RESOURCE_FORM_TYPE.SINGLE_PERMIT,
    DomainDictionaryEntry.RESOURCE_FORM_TYPE.POLISH_FORM
  ];
  const isSinglePermit = useMemo(
    () => typeValueWatcher?.value === DomainDictionaryEntry.RESOURCE_FORM_TYPE.SINGLE_PERMIT,
    [typeValueWatcher]
  );

  const isEkmtPermit = useMemo(
    () => typeValueWatcher?.value === DomainDictionaryEntry.RESOURCE_FORM_TYPE.EKMT_PERMIT,
    [typeValueWatcher]
  );

  useEffect(() => {
    if (!isLeadingZerosWatcher) {
      setValue('requiredLength', null);
    }

    if (!isPrefixWatcher) {
      setValue('prefix', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLeadingZerosWatcher, isPrefixWatcher]);

  const checkDuplicates = async () => {
    const { formType, name, year } = getValues();

    if (formType?.value && name?.value && year) {
      const result = await handleCheckDuplicateResourceTypes({ formType, name, year });
      setIsDuplicate(result.hasDuplicate);
      if (result.hasDuplicate) {
        setError('name', { message: t('resource:messages.createTypeDuplicate') });
      }
    }
  };

  const handleFilterFormTypes = option => option.value !== DomainDictionaryEntry.RESOURCE_FORM_TYPE.S_CERTYFICATE;

  useEffect(() => {
    if (typeValueWatcher) {
      resetField('country');
      resetField('euroClass');
      resetField('limitTypes');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeValueWatcher]);

  useEffect(() => {
    if (nameValueWatcher) {
      checkDuplicates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeValueWatcher, nameValueWatcher, yearValueWatcher]);

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={title}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={setIsLoading => handleSubmit(formData => onConfirm({ formData, setIsLoading }))()}
      isLoading={isLoading}
      onCancel={closeDialog}
      confirmButtonTooltipText={{ disabled: t('resource:messages.createTypeDuplicate') }}
      dialogSize="medium"
      isConfirmButtonDisabled={isDuplicate}
      isOpen
    >
      <FormV2Context.Provider value={values as unknown as FormV2ContextState}>
        <GridLayout itemProps={{ md: 6, xs: 12 }}>
          <DictionarySelectField
            name={typedNameV2<IResourceTypeFormFields>('formType')}
            dictionaryName={DomainDictionaryEnum.RESOURCE_FORM_TYPE}
            optionsFilter={handleFilterFormTypes}
            label={getLabel('formTypes')}
            isRequired
          />

          <DictionarySelectField
            name={typedNameV2<IResourceTypeFormFields>('formGroup')}
            dictionaryName={DomainDictionaryEnum.RESOURCE_FORM_GROUP}
            label={getLabel('formGroup')}
            isRequired
          />

          <DictionarySelectField
            name={typedNameV2<IResourceTypeFormFields>('documentTemplate')}
            dictionaryName={DomainDictionaryEnum.DOCUMENT_TEMPLATE_KEY}
            label={getLabel('documentTemplate')}
          />

          <NumberInputField name={typedNameV2<IResourceTypeFormFields>('year')} label={getLabel('year')} isRequired />

          {isSinglePermit && (
            <DictionarySelectField
              name={typedNameV2<IResourceTypeFormFields>('country')}
              label={getLabel('country')}
              dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
              isRequired
            />
          )}

          {isSinglePermit && (
            <DictionarySelectField
              name={typedNameV2<IResourceTypeFormFields>('euroClass')}
              label={getLabel('euroClass')}
              dictionaryName={DomainDictionaryEnum.EURO_CLASS}
              optionsFilter={option => {
                return option?.active ?? true;
              }}
              isMultiple
              isRequired={isSinglePermit}
            />
          )}

          {isEkmtPermit && (
            <GridItem xs={12}>
              <CheckboxField
                name={typedNameV2<IResourceTypeFormFields>('ekmtType')}
                label={getLabel('ekmtType')}
                rowLayout
              />
            </GridItem>
          )}

          {isEkmtPermit && (
            <DictionarySelectField
              name={typedNameV2<IResourceTypeFormFields>('euroClass')}
              label={getLabel('euroClass')}
              dictionaryName={DomainDictionaryEnum.EURO_CLASS}
              optionsFilter={option => {
                return option?.active ?? true;
              }}
              isRequired={limitTypeWatcher?.value === LimitType.FREE_DISPOSAL}
              isMultiple
            />
          )}

          {isEkmtPermit && isEkmtTypeWatcher && (
            <DictionarySelectField
              name={typedNameV2<IResourceTypeFormFields>('ekmtEuroClass')}
              label={getLabel('euroClassEKMT')}
              dictionaryName={DomainDictionaryEnum.EURO_CLASS}
              optionsFilter={option => {
                return option?.active ?? true;
              }}
              isRequired
            />
          )}

          {isEkmtPermit && isEkmtTypeWatcher && (
            <AutocompleteSelectField
              options={Object.keys(EkmtGroup)?.map(key => ({
                value: key,
                name: key
              }))}
              name={typedNameV2<IResourceTypeFormFields>('ekmtGroup')}
              label={getLabel('ekmtGroup')}
              isRequired
            />
          )}

          <DictionarySelectField
            name={typedNameV2<IResourceTypeFormFields>('name')}
            label={getLabel('resourceName')}
            dictionaryName={DomainDictionaryEnum.RESOURCE_FORM_NAME}
            isRequired
          />

          {foreignResourceTypes.includes(typeValueWatcher?.value) && (
            <ResourceTypeAutocompleteField
              label={getLabel('parentResourceType')}
              name={typedNameV2<IResourceTypeFormFields>('parentResourceType')}
              searchFilter={{ typeKeyFilter: { values: foreignResourceTypes } }}
            />
          )}

          {isSinglePermit && <NumberInputField name="insuredValue" label={getLabel('insuredValue')} isRequired />}

          <DictionarySelectField
            name={typedNameV2<IResourceTypeFormFields>('transitTypeKey')}
            dictionaryName={DomainDictionaryEnum.RESOURCE_TYPE_TRANSIT_TYPE}
            label={getLabel('transitType')}
            isRequired
          />
          {(isSinglePermit || isEkmtPermit) && (
            <AutocompleteSelectField
              name={typedNameV2<IResourceTypeFormFields>('limitType')}
              label={getLabel('limitType')}
              options={[
                {
                  name: t('resource:limitTypes.basic'),
                  value: LimitType.BASIC
                },
                {
                  name: t('resource:limitTypes.additional'),
                  value: LimitType.ADDITIONAL
                },
                {
                  name: t('resource:limitTypes.freeDisposal'),
                  value: LimitType.FREE_DISPOSAL
                }
              ]}
            />
          )}

          <CheckboxField
            name={typedNameV2<IResourceTypeFormFields>('isLeadingZeros')}
            label={getLabel('isLeadingZeros')}
            rowLayout
          />

          <CheckboxField
            name={typedNameV2<IResourceTypeFormFields>('isPrefix')}
            label={getLabel('isPrefix')}
            rowLayout
          />

          <NumberInputField
            name={typedNameV2<IResourceTypeFormFields>('requiredLength')}
            label={getLabel('requiredLength')}
            isDisabled={!isLeadingZerosWatcher}
            isRequired={isLeadingZerosWatcher}
            max={100}
            isClearable
          />

          <TextInputField
            name={typedNameV2<IResourceTypeFormFields>('prefix')}
            label={getLabel('prefix')}
            isDisabled={!isPrefixWatcher}
            isRequired={isPrefixWatcher}
          />
          {isEkmtPermit && (
            <DictionarySelectField
              name={typedNameV2<IResourceTypeFormFields>('ekmtCategoryKey')}
              dictionaryName={DomainDictionaryEnum.EKMT_CATEGORY}
              label={getLabel('ekmtPermissionCategoryKey')}
            />
          )}
        </GridLayout>
        {isSinglePermit && (
          <FreeSaleRuleSection defaultValues={{ chargePermissions: freeSaleDefaultValues.chargePermissions }} />
        )}
      </FormV2Context.Provider>
    </Dialog>
  );
}
