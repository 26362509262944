import { useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { PermissionDetails } from '@ibtm/domain';
import moment from 'moment';

import { FormMode, typedNameV2 } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { PermissionTableEnum, useApplicationDetailsQuery } from '@libs/domain/application';
import { DomainDictionaryEntry } from '@libs/domain/config';
import { checkIsPartnership } from '@libs/domain/folder';
import { useSubjectDetailsExtendedQuery } from '@libs/domain/subject';

import {
  useCreateExternalAuthorityPermissionMutation,
  useCreateForeignAuthorityPermissionMutation,
  useUpdateExternalAuthorityPermissionMutation,
  useUpdateForeignAuthorityPermissionMutation
} from '../../api';
import { SelectEnterpreneurNIPField } from '../../components';
import { PermissionFields, PermissionStatusEnum, PermissionTypeEnum } from '../../model';
import { parsePermissionRequests } from '../../parsers';

interface IPermissionConfig {
  permissionType: PermissionTableEnum;
  applicationId: string;
  onCloseDialog: () => void;
  onSuccess: () => void;
  formMode: FormMode;
  formValues?: UseFormReturn;
}

function usePermissionDialogConfiguration({
  permissionType,
  applicationId,
  formMode,
  onCloseDialog,
  onSuccess,
  formValues
}: IPermissionConfig) {
  const [t] = useTranslation();
  const getQueryConfig = id => ({
    enabled: Boolean(id),
    refetchOnMount: false
  });
  const { data: applicationDetails } = useApplicationDetailsQuery(applicationId, getQueryConfig(applicationId));

  const isFolderTypeTP = applicationDetails?.folder?.typeKey === DomainDictionaryEntry.FOLDER_TYPE.TP;
  const isFolderTypeOP = applicationDetails?.folder?.typeKey === DomainDictionaryEntry.FOLDER_TYPE.OP;

  const isFolderTypeTPorOP = isFolderTypeTP || isFolderTypeOP;

  const subjectId = applicationDetails?.subject?.id;

  const { data: subjectData, isLoading: isSubjectLoading } = useSubjectDetailsExtendedQuery(
    subjectId,
    getQueryConfig(subjectId)
  );

  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { mutateAsync: createExternalAuthorityPermission, isLoading: isLoadingExternalCreate } =
    useCreateExternalAuthorityPermissionMutation();
  const { mutateAsync: createForeignAuthorityPermission, isLoading: isLoadingForeignCreate } =
    useCreateForeignAuthorityPermissionMutation();
  const { mutateAsync: updateExternalAuthorityPermission, isLoading: isLoadingForeignUpdate } =
    useUpdateExternalAuthorityPermissionMutation();
  const { mutateAsync: updateForeignAuthorityPermission, isLoading: isLoadingExternalUpdate } =
    useUpdateForeignAuthorityPermissionMutation();

  const { createMode, editMode } = useViewModesV2(formMode);

  const mutationConfig = {
    onSuccess: () => {
      showSuccessSnackbar(
        editMode ? t('permission:action.edit.successMessage') : t('permission:action.create.successMessage')
      );
      onCloseDialog();
      onSuccess();
    },
    onError: () => {
      showErrorSnackbar(
        editMode ? t('permission:action.edit.failureMessage') : t('permission:action.create.failureMessage')
      );
    }
  };

  const isPartnership = checkIsPartnership(subjectData?.legalFormKey);
  const applyToPartnership = formValues?.watch(typedNameV2<PermissionFields>('applyToPartnership'));
  const validToValue = formValues?.watch(typedNameV2<PermissionFields>('validTo'));
  const dateOfIssueValue = formValues?.watch(typedNameV2<PermissionFields>('dateOfIssue'));

  useEffect(() => {
    if (applyToPartnership) {
      formValues.setValue(typedNameV2<PermissionFields>('partnershipPartnersNip'), []);
    }
  }, [applyToPartnership, formValues]);

  useEffect(() => {
    if (createMode && validToValue && dateOfIssueValue) {
      if (moment().isSameOrAfter(dateOfIssueValue) && moment().isSameOrBefore(validToValue)) {
        formValues.setValue(typedNameV2<PermissionFields>('statusKey'), PermissionStatusEnum.ACTIVE);
      } else {
        formValues.setValue(typedNameV2<PermissionFields>('statusKey'), PermissionStatusEnum.PENDING);
      }
    }
  }, [dateOfIssueValue, validToValue, formValues, createMode]);

  const partnershipFields: Array<keyof PermissionFields | JSX.Element> = useMemo(() => {
    if (isPartnership) {
      return [
        <SelectEnterpreneurNIPField
          key="nipfield"
          subjectId={subjectId}
          isLoading={isSubjectLoading}
          isDisabled={applyToPartnership}
          applicationFolderTypeKey={applicationDetails?.folder?.typeKey}
          onChangeCustom={value => {
            formValues.setValue(
              typedNameV2<PermissionFields>('partnershipEnterpreneur'),
              value.map(element => element.subjectName).join(', ')
            );
          }}
        />,
        'partnershipEnterpreneur'
      ];
    }

    return ['enterpreneur'];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPartnership, subjectId, applicationDetails?.folder?.typeKey, isSubjectLoading, applyToPartnership]);

  const config: {
    [key in PermissionTableEnum]?: {
      fields: Array<keyof PermissionFields | JSX.Element>;
      submit: (values: PermissionFields, permissionId: string) => Promise<PermissionDetails>;
    };
  } = {
    [PermissionTableEnum.EXTERNAL_AUTHORITY_PERMISSION]: {
      fields: [
        'permissionNumber',
        'dateOfIssue',
        'validIndefinitely',
        ...(isFolderTypeTPorOP ? (['validTo'] as Array<keyof PermissionFields>) : []),
        'issuingAuthorityKey',
        ...(isPartnership ? (['applyToPartnership'] as Array<keyof PermissionFields>) : []),
        ...partnershipFields,
        'statusKey'
      ],
      submit: (values: PermissionFields, permissionId?: string) => {
        const formData = {
          ...parsePermissionRequests(values, applicationId)[PermissionTableEnum.EXTERNAL_AUTHORITY_PERMISSION],
          version: values.version,
          typeKey: isFolderTypeTP
            ? PermissionTypeEnum.ROAD_CARRIER_GOODS_CARRIAGE
            : PermissionTypeEnum.ROAD_CARRIER_PASSENGER_CARRIAGE,
          isPartnershipPermission: isPartnership ? values.applyToPartnership : true,
          partnershipPartnersNip: !values.applyToPartnership
            ? values.partnershipPartnersNip?.map(item => item.value)
            : [],
          issuingAuthority: values.issuingAuthorityKey?.value
        };
        return editMode
          ? updateExternalAuthorityPermission(
              {
                permissionId,
                formData
              },
              mutationConfig
            )
          : createExternalAuthorityPermission(formData, mutationConfig);
      }
    },
    [PermissionTableEnum.FOREIGN_AUTHORITY_PERMISSION]: {
      fields: ['permissionNumber', 'dateOfIssue', 'validTo', 'foreignPermissionName', 'issuingCountryKey', 'statusKey'],
      submit: (values: PermissionFields, permissionId?: string) => {
        const formData = {
          ...parsePermissionRequests(values, applicationId)[PermissionTableEnum.FOREIGN_AUTHORITY_PERMISSION],
          version: values.version,
          typeKey: PermissionTypeEnum.ROAD_CARRIER_GOODS_CARRIAGE
        };
        return editMode
          ? updateForeignAuthorityPermission(
              {
                permissionId,
                formData
              },
              mutationConfig
            )
          : createForeignAuthorityPermission(formData, mutationConfig);
      }
    }
  };
  const isLoading =
    isLoadingForeignCreate || isLoadingExternalCreate || isLoadingExternalUpdate || isLoadingForeignUpdate;

  return { ...config[permissionType], isLoading, isConfirmDisabled: isSubjectLoading };
}

export default usePermissionDialogConfiguration;
