import { MutationConfig } from 'react-query';
import { DocumentParcelReturnRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface IMutation extends DocumentParcelReturnRequest {
  documentId: string;
}

const createReturnDate = async (params: IMutation) => {
  const { documentId, ...restParams } = params;
  const { data } = await API.documents.registerParcelReturn(documentId, restParams, {
    ...endpointsConfig.changeReturnDate
  });
  return data;
};

export default function useDocumentIssuedReturnDateMutation(config?: MutationConfig<any, unknown, IMutation, unknown>) {
  return useMutation(createReturnDate, config);
}
