import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function createLock(formData) {
  return API.folderLock.createFolderLock(formData, {
    ...endpointsConfig.createFolderLock
  });
}

function useCreateLockMutation() {
  return useMutation(createLock);
}

export default useCreateLockMutation;
