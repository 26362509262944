import { useMemo } from 'react';
import { DriverDetails } from '@ibtm/domain';
import { useAppConfig } from '@libs/app-config';
import { isEmpty } from 'lodash';

import { TableButtonMore } from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';

import { DriverCertificateStatusEnum } from '@libs/domain/driver-certificate';
import {
  ActivateDriverButton,
  DeleteDriverButton,
  EditDriverButton,
  ExpireDriverButton,
  GenerateExpireReportButton,
  GenerateReportButton,
  GoToProceedingButton,
  PreviewDriverButton,
  SelectDriverToAddButton
} from '@libs/domain/drivers/components';
import {
  DriverActionButtonsEnum,
  DriverCertificateReportTypeEnum,
  IPermissionsObject
} from '@libs/domain/drivers/model';

interface IProps {
  data: DriverDetails;
  visibleActionButtons?: {
    view?: DriverActionButtonsEnum[];
    tableRow?: {
      visible?: DriverActionButtonsEnum[];
      more?: DriverActionButtonsEnum[];
    };
  };
  permissionsKeysObject?: IPermissionsObject;
  applicationId?: string;
  folderId?: string;
}

function TableRowActions({ data, visibleActionButtons, permissionsKeysObject, applicationId, folderId }: IProps) {
  const { reportTypes } = useAppConfig();
  const { checkIsElementVisible } = useElementVisibility();

  const visibleMoreActionButtonsByPermissions = useMemo(
    () =>
      visibleActionButtons.tableRow.more.filter(actionKey => checkIsElementVisible(permissionsKeysObject[actionKey])),
    [checkIsElementVisible, permissionsKeysObject, visibleActionButtons.tableRow.more]
  );

  const hasPendingProceeding = !isEmpty(data?.currentCertificate?.pendingProceedings);

  const allButtons = useMemo(
    () => ({
      [DriverActionButtonsEnum.PREVIEW]: (
        <PreviewDriverButton
          data={data}
          driverId={data?.id}
          actionKey={permissionsKeysObject?.[DriverActionButtonsEnum.PREVIEW]}
        />
      ),
      [DriverActionButtonsEnum.DELETE]: (
        <DeleteDriverButton
          driverId={data?.id}
          name={data?.name}
          surname={data?.surname}
          applicationId={applicationId}
          actionKey={permissionsKeysObject?.[DriverActionButtonsEnum.DELETE]}
        />
      ),
      [DriverActionButtonsEnum.EDIT]: (
        <EditDriverButton
          data={data}
          driverId={data?.id}
          actionKey={permissionsKeysObject?.[DriverActionButtonsEnum.EDIT]}
        />
      ),
      [DriverActionButtonsEnum.ACTIVATE]: data?.driverCertificates?.length > 0 &&
        !data?.driverCertificates?.some(
          certificate => certificate?.statusKey === DriverCertificateStatusEnum.ACTIVE
        ) && <ActivateDriverButton data={data} actionKey={permissionsKeysObject?.[DriverActionButtonsEnum.ACTIVATE]} />,
      [DriverActionButtonsEnum.EXPIRE]: data?.driverCertificates?.length > 0 &&
        data?.driverCertificates?.some(
          certificate => certificate?.statusKey === DriverCertificateStatusEnum.ACTIVE
        ) && (
          <ExpireDriverButton
            data={data}
            driverApplicationId={applicationId}
            actionKey={permissionsKeysObject?.[DriverActionButtonsEnum.EXPIRE]}
          />
        ),
      [DriverActionButtonsEnum.GENERATE_EXPIRE_REPORT]: data?.driverCertificates?.length > 0 &&
        !data?.driverCertificates?.some(
          certificate => certificate?.statusKey === DriverCertificateStatusEnum.ACTIVE
        ) && (
          <GenerateExpireReportButton
            driverApplicationId={data?.currentCertificate?.application?.id}
            cancellationDocument={data?.currentCertificate?.cancellationDocument}
            reportType={DriverCertificateReportTypeEnum.EXPIRE}
            actionKey={permissionsKeysObject?.[DriverActionButtonsEnum.GENERATE_EXPIRE_REPORT]}
          />
        ),
      [DriverActionButtonsEnum.GENERATE_UP_TO_DATE]: (
        <GenerateReportButton
          targetParameters={[
            {
              name: reportTypes?.upToDateSkCompany?.targetParameters?.company,
              value: data?.subjectName
            },
            { name: reportTypes?.upToDateSkCompany?.targetParameters?.nip, value: data?.subjectNip },
            { name: reportTypes?.upToDateSkCompany?.targetParameters?.folderId, value: data?.folderNumber }
          ]}
          reportType={reportTypes?.upToDateSkCompany?.reportTypeName}
          reportTitle={DriverCertificateReportTypeEnum.UP_TO_DATE}
          actionKey={permissionsKeysObject?.[DriverActionButtonsEnum.GENERATE_UP_TO_DATE]}
        />
      ),
      [DriverActionButtonsEnum.GENERATE_ISSUED]: (
        <GenerateReportButton
          targetParameters={[
            { name: reportTypes?.issuingSkCompany?.targetParameters?.company, value: data?.subjectName },
            { name: reportTypes?.issuingSkCompany?.targetParameters?.nip, value: data?.subjectNip },
            { name: reportTypes?.issuingSkCompany?.targetParameters?.folderId, value: data?.folderNumber }
          ]}
          reportType={reportTypes?.issuingSkCompany?.reportTypeName}
          reportTitle={DriverCertificateReportTypeEnum.ISSUED}
          actionKey={permissionsKeysObject?.[DriverActionButtonsEnum.GENERATE_ISSUED]}
        />
      ),
      [DriverActionButtonsEnum.SELECT_DRIVER_TO_ADD]: <SelectDriverToAddButton driverId={data?.id} />,
      [DriverActionButtonsEnum.GO_TO_PROCEEDING]: hasPendingProceeding && (
        <GoToProceedingButton
          pendingProceedings={data?.currentCertificate?.pendingProceedings}
          actionKey={permissionsKeysObject?.[DriverActionButtonsEnum.GO_TO_PROCEEDING]}
          applicationId={applicationId}
          folderId={folderId}
        />
      )
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );
  return (
    <>
      {!!visibleMoreActionButtonsByPermissions?.length && (
        <TableButtonMore>
          {close =>
            visibleMoreActionButtonsByPermissions?.map(buttonType => (
              <div key={buttonType} onClick={close} aria-hidden="true">
                {allButtons[buttonType]}
              </div>
            ))
          }
        </TableButtonMore>
      )}
      {visibleActionButtons?.tableRow?.visible?.map(buttonType => allButtons[buttonType])}
    </>
  );
}

export default TableRowActions;
