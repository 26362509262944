import { first, last } from 'lodash';

import { PermissionElement, PermissionElementMap, UIElementNameEnum } from '@libs/permission/models';

const PermissionElementMapper: PermissionElementMap<UIElementNameEnum> = {} as PermissionElementMap<UIElementNameEnum>;

export const setPermissionElementMapper = (params: PermissionElementMap<UIElementNameEnum>) => {
  Object.entries(params).forEach(element => {
    const value = first(element) as string;
    PermissionElementMapper[value] = last(element) as PermissionElement;
  });
};

export default PermissionElementMapper;
