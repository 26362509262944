import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '@enigma/fe-ui';
import { ProceedingDetails as ProceedingDetailsModel, ProceedingWithFormsCreateRequest } from '@ibtm/domain';

import {
  BackButton,
  Button,
  FormActionEnum,
  Page,
  SelectOption,
  Tab,
  TabsPageSidebar,
  typedNameV2,
  useFormActionModal,
  useFormV2Context,
  useIsSmallScreen,
  useRouter,
  useTab
} from '@libs/common/v2';
import { ButtonsGroup } from '@libs/common/v2/components/buttonsGroup';
import { useViewModesV2 } from '@libs/common/v2/form';
import PageHeader from '@libs/common/v2/templates/page-header/PageHeader';
import { convertCalendarDate, replaceFirstOccurence } from '@libs/common/v2/utils';

import { useElementVisibility } from '@libs/permission';
import usePermissions from '@libs/permission/hooks/usePermissions';

import {
  DomainDictionaryEntry,
  DomainUIElementEnum,
  PermissionEnum,
  useDomainConfigContext
} from '@libs/domain/config';
import { useFolderDetailsExtendedQuery } from '@libs/domain/folder';
import { useRemarksHeaderActions } from '@libs/domain/notes';
import {
  getMainProceedingBreadcrumbItems,
  getProceedingBreadcrumbItems,
  ProceedingDetailsClient,
  ProceedingDetailsParams,
  ProceedingDetailsTabEnum,
  ProceedingTypeKeyEnum,
  useProceedingDetailsQuery
} from '@libs/domain/proceeding';

import { FolderSlider } from '../../../folder/components/common';

import { HeaderContent } from './tabs/procedureDetails/Header';
import { useDropdownActions } from './tabs/procedureDetails/Header/hooks/useDropdownActions';
import ProceedingDetailsContent from './ProceedingDetailsContent';

interface IProps {
  setSubmitting: (value: boolean) => void;
}

export function ProceedingDetails({ setSubmitting }: IProps) {
  const { folderId, proceedingTypeKey, id, type, baseProceedingId } = useParams<ProceedingDetailsParams>();
  const { goToPage, routes, location, navigate } = useRouter();
  const { isOperatorPortal } = useDomainConfigContext();
  const { hasPermission } = usePermissions();
  const { isSmallScreen } = useIsSmallScreen();

  const [t] = useTranslation();
  const { checkIsElementVisible } = useElementVisibility();

  const { showSuccessSnackbar, showSnackbar } = useSnackbar();
  const { formMode, handleSubmit, reset, isSubmitting, watch, isDirty, getValues } = useFormV2Context();
  const [activeTab, setActiveTab] = useTab<ProceedingDetailsTabEnum>(ProceedingDetailsTabEnum.PROCEDURE_DETAILS);
  const { createMode, editMode, viewMode } = useViewModesV2(formMode);
  const { handleSaveProceeding } = useDropdownActions();
  const { handleSave } = useRemarksHeaderActions({
    proceedingId: id
  });
  const tabChangeAction = useFormActionModal();

  const { handleOpenEditMode } = useDropdownActions();

  const statusKey = watch(typedNameV2<ProceedingDetailsClient>('statusKey')) as SelectOption<string>;

  const { data, refetch } = useProceedingDetailsQuery(id, { enabled: !!id });
  const { data: folderDetails } = useFolderDetailsExtendedQuery(folderId, { enabled: Boolean(folderId) });
  const { handleFinish } = useDropdownActions(refetch);

  const categoryKey = useMemo(() => {
    return proceedingTypeKey === ProceedingTypeKeyEnum.ADMINISTRATION
      ? DomainDictionaryEntry.PROCEEDING_TYPE.ADMINISTRATIVE
      : DomainDictionaryEntry.PROCEEDING_TYPE.VERIFICATION;
  }, [proceedingTypeKey]);

  useEffect(() => {
    if (
      data?.categoryKey &&
      proceedingTypeKey &&
      proceedingTypeKey === ProceedingTypeKeyEnum.VERIFICATION &&
      data.categoryKey === DomainDictionaryEntry.PROCEEDING_TYPE.ADMINISTRATIVE
    ) {
      goToPage(
        editMode
          ? routes.proceedingAdministrationEdit(folderId, id)
          : routes.proceedingAdministrationDetails(folderId, id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryKey, proceedingTypeKey, editMode, data]);

  const isAdministrationProceeding = useMemo(
    () => categoryKey === DomainDictionaryEntry.PROCEEDING_TYPE.ADMINISTRATIVE,
    [categoryKey]
  );

  useEffect(() => {
    if (data) {
      if (createMode) {
        reset({
          categoryKey: data.categoryKey
        });
      } else {
        reset({
          ...data,
          typeKey: data.typeKey,
          categoryKey: data.categoryKey,
          wsaComplaint: { ...data.wsaComplaint, resultKey: data.wsaComplaint?.resultKey },
          nsaComplaint: { ...data.nsaComplaint, resultKey: data.nsaComplaint?.resultKey }
        });
      }
    }
    const categoryKey = isAdministrationProceeding
      ? DomainDictionaryEntry.PROCEEDING_TYPE.ADMINISTRATIVE
      : DomainDictionaryEntry.PROCEEDING_TYPE.VERIFICATION;

    if (createMode && baseProceedingId) {
      reset({
        baseProceeding: baseProceedingId,
        categoryKey
      });
    } else if (createMode) {
      reset({
        categoryKey
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const proceedingTypeTitle = useMemo(() => {
    return categoryKey === DomainDictionaryEntry.PROCEEDING_TYPE.ADMINISTRATIVE
      ? t('proceeding:administration')
      : t('proceeding:verification');
  }, [categoryKey, t]);

  const title = useMemo(() => {
    if (createMode) {
      return t('proceeding:administrative.newTitle', {
        proceedingType: proceedingTypeTitle
      });
    }
    if (editMode) {
      return isAdministrationProceeding
        ? t('proceeding:administrative.editAdministrationTitle')
        : t('proceeding:administrative.editVerificationTitle');
    }

    return isAdministrationProceeding
      ? t('proceeding:administrative.detailsAdministrationTitle')
      : t('proceeding:administrative.detailsVerificationTitle');
  }, [proceedingTypeTitle, isAdministrationProceeding, createMode, editMode, t]);

  const goBack = useCallback(() => {
    const previousUrl = location?.state?.from as string;
    goToPage(previousUrl ?? '/proceeding');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.from]);

  const openViewMode = useCallback(() => {
    const previousUrl = (location?.state?.from as string) ?? '/proceeding';
    const currentUrl = `${location.pathname}`;
    const newUrl = replaceFirstOccurence('edit', 'details', currentUrl);
    if (createMode) {
      navigate(-1);
    } else {
      navigate(
        { pathname: newUrl, search: `tab=${activeTab}` },
        {
          state: { from: previousUrl }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, location?.pathname]);

  useEffect(() => {
    if (isOperatorPortal && !viewMode && folderDetails) {
      if ('borrowedBy' in folderDetails && folderDetails?.borrowedBy) {
        showSnackbar(
          'info',
          t('folder:details.message.folderIsBorrowedBy', {
            name: folderDetails?.borrowedBy?.firstName,
            surname: folderDetails?.borrowedBy?.surname,
            number: folderDetails?.number
          })
        );
      } else {
        showSnackbar('info', t('folder:details.message.folderIsNotBorrowed'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOperatorPortal, formMode, folderDetails]);

  const saveProgress = (
    values: Partial<
      ProceedingWithFormsCreateRequest & ProceedingDetailsModel & { noteContent: string; noteVersion: number }
    >,
    event
  ) => {
    const currentUrl = `${location.pathname}?tab=${activeTab}`;
    const successUrl = replaceFirstOccurence('edit', 'details', currentUrl);

    setSubmitting(true);
    handleSaveProceeding(
      values as Omit<ProceedingWithFormsCreateRequest, 'reviewApplicationDate'> &
        ProceedingDetailsModel & { reviewApplicationDate?: Date },
      event,
      () => {
        if (Object.prototype.hasOwnProperty.call(values, 'noteId') && editMode) {
          const { noteContent, noteVersion } = values;
          if (!(typeof noteContent === 'string' && noteContent.length > 5000)) {
            handleSave(
              { note: noteContent, version: noteVersion },
              event,
              () => {
                showSuccessSnackbar(t('proceeding:messages.updateProceedingSuccess'));

                goToPage(successUrl, {
                  state: { from: location?.state?.from as string }
                });
                setSubmitting(false);
              },
              false
            );
          } else {
            showSuccessSnackbar(t('proceeding:messages.updateProceedingSuccess'));

            goToPage(successUrl, {
              state: { from: location?.state?.from as string }
            });
            setSubmitting(false);
          }
        } else {
          if (editMode) {
            showSuccessSnackbar(t('proceeding:messages.updateProceedingSuccess'));
          } else {
            showSuccessSnackbar(t('proceeding:messages.createProceedingSuccess'));
          }

          goToPage(successUrl, {
            state: { from: location?.state?.from as string }
          });
          setSubmitting(false);
        }
      },
      false,
      setSubmitting
    );
  };

  const changeTab = useCallback(
    tab => {
      const note = getValues('noteContent') as string;
      if (editMode && (isDirty || (note && note !== data?.note))) {
        tabChangeAction({
          formAction: FormActionEnum.SAVE_CHANGES,
          onConfirm: () => {
            handleSubmit(saveProgress)();
          },
          onCancel: () => {
            setActiveTab(tab);
            reset({
              ...data,
              typeKey: data.typeKey,
              categoryKey: data.categoryKey,
              wsaComplaint: { ...data.wsaComplaint, resultKey: data.wsaComplaint?.resultKey },
              nsaComplaint: { ...data.nsaComplaint, resultKey: data.nsaComplaint?.resultKey }
            });
          }
        });
      } else {
        setActiveTab(tab);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabChangeAction, setActiveTab, saveProgress, editMode, isDirty]
  );

  return (
    <Page
      header={
        <PageHeader
          title={title}
          breadcrumbs={
            folderId
              ? getProceedingBreadcrumbItems(formMode, data?.number?.toString(), data?.id, folderId)
              : getMainProceedingBreadcrumbItems(formMode, data?.number?.toString(), type, data?.id)
          }
          rightSideContent={
            <>
              {viewMode && (
                <ButtonsGroup>
                  <BackButton onClick={goBack} isNoMargin />
                  <Button
                    onClick={() => handleOpenEditMode(activeTab)}
                    label={t('action.edit')}
                    variant="outlined"
                    isSecondary
                    isNoMargin
                    actionKey={
                      isAdministrationProceeding
                        ? DomainUIElementEnum.PROCEEDING_ADMINISTRATIVE_EDIT_BUTTON
                        : DomainUIElementEnum.PROCEEDING_VERIFICATION_EDIT_BUTTON
                    }
                  />
                  {statusKey?.value === DomainDictionaryEntry.PROCEEDING_STATUS.CONSIDERED && (
                    <Button
                      variant="contained"
                      onClick={() => handleFinish()}
                      actionKey={DomainUIElementEnum.PROCEEDING_EDIT_END_BUTTON}
                      label={t('proceeding:administrative.tab.procedureDetails.action.finish')}
                      isPrimary
                      isNoMargin
                    />
                  )}
                  <HeaderContent />
                </ButtonsGroup>
              )}
              {!viewMode && (
                <ButtonsGroup>
                  <Button
                    onClick={openViewMode}
                    label={t('action.cancel')}
                    type="button"
                    variant="outlined"
                    isSecondary
                    isNoMargin
                  />
                  <Button
                    onClick={handleSubmit(saveProgress)}
                    label={t('action.save')}
                    type="button"
                    isLoading={isSubmitting}
                    isPrimary
                    isNoMargin
                    actionKey={DomainUIElementEnum.PROCEEDING_SAVE_PROGRESS_BUTTON}
                  />
                </ButtonsGroup>
              )}
            </>
          }
          isDividerShown
        />
      }
      content={
        <ProceedingDetailsContent
          formMode={formMode}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          finalDecision={{
            haveFinalDecision: data?.finalDecision,
            finalDecisionProcessingDate: convertCalendarDate(data?.finalDecisionProcessingDate)
          }}
        />
      }
      leftSidebarHeader={!createMode && t('sections')}
      leftSidebarContent={
        !createMode && (
          <TabsPageSidebar
            visibleTabs={[
              <Tab
                label={t('proceeding:administrative.tab.procedureDetails.title')}
                value={ProceedingDetailsTabEnum.PROCEDURE_DETAILS}
                key="procedureDetails-title"
                icon="InfoIcon"
              />,
              <Tab
                label={t('proceeding:administrative.tab.outgoingDocuments.title')}
                value={ProceedingDetailsTabEnum.OUTGOING_DOCUMENTS}
                key="outgoingDocuments-title"
                viewName={DomainUIElementEnum.PROCEEDING_DOCUMENTS_VIEW}
                icon="DocumentsIssuedIcon"
              />,
              <Tab
                label={t('proceeding:administrative.tab.formOperations.title')}
                value={ProceedingDetailsTabEnum.FORM_OPERATIONS}
                key="formOperations-title"
                viewName={DomainUIElementEnum.PROCEEDING_FORM_OPERATIONS_VIEW}
                icon="ReleaseDocumentsIcon"
              />,
              <Tab
                label={t('proceeding:administrative.tab.note.title')}
                value={ProceedingDetailsTabEnum.NOTE}
                key="note-title"
                viewName={DomainUIElementEnum.PROCEEDING_NOTE_VIEW}
                icon="NoteIcon"
              />,
              <Tab
                label={t('proceeding:administrative.tab.files.title')}
                value={ProceedingDetailsTabEnum.FILES}
                key="files"
                viewName={DomainUIElementEnum.PROCEEDING_FILES_VIEW}
                icon="FileEmptyIcon"
              />
            ]}
            activeTab={activeTab}
            onChange={changeTab}
          />
        )
      }
      isRightSidebarVisible={checkIsElementVisible(DomainUIElementEnum.FOLDER_SLIDER)}
      rightSidebarContent={
        !createMode && (
          <FolderSlider
            folderId={folderId}
            hasSubjectDataPermission={hasPermission(PermissionEnum.IBTM_DOMAIN_FOLDER_SUBJECT_VIEW)}
          />
        )
      }
      isLeftSidebarOpen={!createMode}
      isContentScrollEnabled={!isSmallScreen}
      isLeftSidebarScrollEnabled={!createMode}
    />
  );
}
