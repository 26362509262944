import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { ImportFileCreateRequest } from '@ibtm/domain';

import { Button, useFormV2Context } from '@libs/common/v2';

import { useImportEkmtFileMutation, useImportSingleUseFileMutation } from '@libs/domain/social-commission';

interface IProps {
  handleClose: () => void;
  isEkmtCommittee?: boolean;
}

function UploadFileDialogCustomActions({ handleClose, isEkmtCommittee }: IProps) {
  const [t] = useTranslation();
  const { handleSubmit } = useFormV2Context();
  const { mutate: addEkmtFile, isLoading: isEkmtFileLoading } = useImportEkmtFileMutation();
  const { mutate: addSingleUseFile, isLoading: isSingleUseFileLoading } = useImportSingleUseFileMutation();
  const { showSuccessSnackbar } = useSnackbar();

  const isLoading = useMemo(
    () => isEkmtFileLoading || isSingleUseFileLoading,
    [isEkmtFileLoading, isSingleUseFileLoading]
  );

  const handleSubmitEkmtFile = (formData: Partial<{ file: ImportFileCreateRequest }>) => {
    addEkmtFile(
      { fileId: formData.file.fileId },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('global:success.add'));
          handleClose();
        }
      }
    );
  };

  const handleSubmitSingleUseFIle = (formData: Partial<{ file: ImportFileCreateRequest }>) => {
    addSingleUseFile(
      { fileId: formData.file.fileId },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('global:success.add'));
          handleClose();
        }
      }
    );
  };

  return (
    <Button
      label={t('action.save')}
      onClick={handleSubmit(isEkmtCommittee ? handleSubmitEkmtFile : handleSubmitSingleUseFIle)}
      isLoading={isLoading}
      isPrimary
    />
  );
}

export default UploadFileDialogCustomActions;
