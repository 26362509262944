import { useCallback } from 'react';

import { useDialog } from '@libs/common/v2';

import { DocumentsQueryKeysEnum, useUpdateDocumentMutation } from '../../api';
import UpdateDocumentDialog from '../../components/update-document/UpdateDocumentDialog';
import { DocumentsSnapshotClient, UploadDocumentFile } from '../../model';

const useUpdateDocumentDialog = (
  listQueryKey: string = DocumentsQueryKeysEnum.DOCUMENTS_LIST,
  mutation: () => any = useUpdateDocumentMutation,
  parentId?: string,
  createRequestData?: (formData: UploadDocumentFile, documentData: DocumentsSnapshotClient) => any
) => {
  const { openDialog } = useDialog();

  const open = useCallback(
    (documentData: DocumentsSnapshotClient, onSuccess: () => void) => {
      openDialog(({ closeDialog }) => (
        <UpdateDocumentDialog
          closeDialog={closeDialog}
          documentData={documentData}
          onSuccess={onSuccess}
          mutation={mutation}
          parentId={parentId}
          listQueryKey={listQueryKey}
          createRequestData={createRequestData}
        />
      ));
    },
    [createRequestData, listQueryKey, mutation, parentId, openDialog]
  );

  return {
    openUpdateDocumentDialog: open
  };
};

export default useUpdateDocumentDialog;
