import { QueryConfig, useQuery } from 'react-query';
import { DefaultApiGetNotificationRequest, PullNotificationSnapshot } from '@avispon/message';
import { AxiosRequestConfig } from 'axios';

import { API, endpointsConfig, MessageQueryKeysEnum } from '@libs/notification/api';

export const getMessageSearch = async (_, params: DefaultApiGetNotificationRequest) => {
  const { data } = await API.message.getNotification(params, {
    ...endpointsConfig.findMessage
  } as AxiosRequestConfig);

  return data;
};

export default function useMessagesSearchQuery(
  params: DefaultApiGetNotificationRequest,
  queryConfig: QueryConfig<PullNotificationSnapshot> = {}
) {
  return useQuery<PullNotificationSnapshot>(
    [MessageQueryKeysEnum.MESSAGES_SEARCH_LIST, params],
    getMessageSearch,
    queryConfig
  );
}
