import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { DatepickerField, InputMode, typedNameV2, useFormV2Context, useFormV2Watch } from '@libs/common/v2';

import { DomainDictionaryEntry } from '../../config';
import { editablePermissionValidityKeys } from '../utils';

import { IUpdatePermissionRequest } from './PermissionEditDialog';

interface IProps {
  original: {
    id: string;
    permissionValidityPeriodKey: string;
    validTo: string;
    validFrom: string;
  };
  name: string;
  hasErrorTooltip?: boolean;
}

function PermissionValidToField({ original, name, hasErrorTooltip }: IProps) {
  const [t] = useTranslation();
  const { setValue, getValues } = useFormV2Context();
  const permissionValidityPeriodKey = useFormV2Watch<string>({
    name: typedNameV2<IUpdatePermissionRequest>('permissionValidityPeriodKey')
  });
  const validFrom = useFormV2Watch<string>({ name: typedNameV2<IUpdatePermissionRequest>('validFrom') });

  const getShiftedValidFromDate = (amount?: moment.DurationInputArg1, unit?: moment.DurationInputArg2) =>
    moment(getValues(typedNameV2<IUpdatePermissionRequest>('validFrom')))
      .add(amount, unit)
      .format('YYYY-MM-DD');

  const getValidToFieldValue = (): string => {
    const checkPermissionValidityPeriodKeyIn = (values: string[]) => values.includes(permissionValidityPeriodKey);

    if (
      checkPermissionValidityPeriodKeyIn([
        DomainDictionaryEntry.DURATION_OF_PERMIT.a,
        DomainDictionaryEntry.DURATION_OF_PERMIT['6']
      ])
    ) {
      return getShiftedValidFromDate(6, 'M');
    }

    if (
      checkPermissionValidityPeriodKeyIn([
        DomainDictionaryEntry.DURATION_OF_PERMIT.b,
        DomainDictionaryEntry.DURATION_OF_PERMIT['1']
      ])
    ) {
      return getShiftedValidFromDate(1, 'y');
    }

    if (
      checkPermissionValidityPeriodKeyIn([
        DomainDictionaryEntry.DURATION_OF_PERMIT.c,
        DomainDictionaryEntry.DURATION_OF_PERMIT['2']
      ])
    ) {
      return getShiftedValidFromDate(2, 'y');
    }

    if (
      checkPermissionValidityPeriodKeyIn([
        DomainDictionaryEntry.DURATION_OF_PERMIT.d,
        DomainDictionaryEntry.DURATION_OF_PERMIT['3']
      ])
    ) {
      return getShiftedValidFromDate(3, 'y');
    }

    if (
      checkPermissionValidityPeriodKeyIn([
        DomainDictionaryEntry.DURATION_OF_PERMIT.e,
        DomainDictionaryEntry.DURATION_OF_PERMIT['4']
      ])
    ) {
      return getShiftedValidFromDate(4, 'y');
    }

    if (
      checkPermissionValidityPeriodKeyIn([
        DomainDictionaryEntry.DURATION_OF_PERMIT.f,
        DomainDictionaryEntry.DURATION_OF_PERMIT['5']
      ])
    ) {
      return getShiftedValidFromDate(5, 'y');
    }
    return '';
  };

  useEffect(() => {
    if (permissionValidityPeriodKey) {
      const nextFieldValue = getValidToFieldValue();

      if (
        !editablePermissionValidityKeys.includes(permissionValidityPeriodKey) ||
        moment(getValues(name)).isAfter(nextFieldValue)
      ) {
        setValue(name, nextFieldValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionValidityPeriodKey, validFrom]);

  return (
    <DatepickerField
      name={name}
      label={t('permission:field.validTo')}
      initialValue={original?.validTo || getValidToFieldValue()}
      minDate={getShiftedValidFromDate(1, 'd')}
      maxDate={getValidToFieldValue()}
      inputMode={editablePermissionValidityKeys.includes(permissionValidityPeriodKey) ? InputMode.FORM : InputMode.VIEW}
      hasErrorTooltip={hasErrorTooltip}
    />
  );
}

export default PermissionValidToField;
