import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

const closeSuspension = (params: { suspensionId: string; version: number }) => {
  const { suspensionId, version } = params;
  return API.suspensions.closeSuspension(suspensionId, { version });
};

export const useSuspensionCloseMutation = () => useMutation(closeSuspension);
