import { VehicleCreateRequest as VehicleCreateRequestClient } from '@ibtm/client-domain';
import { VehicleCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function createVehicle(formData: VehicleCreateRequest) {
  return API.vehicle.createVehicle(formData, {
    ...endpointsConfig.createVehicle
  });
}

function createVehicleClient(formData: VehicleCreateRequestClient) {
  return API.client.vehicle.createVehicle('', '', formData, {
    ...endpointsConfig.createVehicle
  });
}

function useCreateVehicleMutation() {
  const { getQuery } = useGetApiQueryByPerspective();

  return useMutation(
    getQuery(
      (formData: VehicleCreateRequestClient | VehicleCreateRequest) =>
        createVehicleClient(formData as VehicleCreateRequestClient),
      (formData: VehicleCreateRequestClient | VehicleCreateRequest) => createVehicle(formData as VehicleCreateRequest)
    )
  );
}

export default useCreateVehicleMutation;
