import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  DATE_TIME_FORMAT,
  MessagePopover,
  MessagePopoverListItem,
  Scrollbar,
  useConfirmDialog,
  useMessagePopoverStyles,
  useSystemNotificationContext
} from '@libs/common/v2';

function SystemNotificationsPopover({ iconClassName }: { iconClassName?: string }) {
  const [t] = useTranslation();
  const { snackbarMessages, deleteSystemMessage, clearAllSystemMessages } = useSystemNotificationContext();

  const classes = useMessagePopoverStyles({});

  const [confirm] = useConfirmDialog();

  const deleteMessageHandler = id => {
    deleteSystemMessage(id);
  };

  const deleteAllMessagesHandler = () => {
    confirm({
      title: t('removeAllSystemNotificationsTitle'),
      message: t('removeAllSystemNotificationsMessages'),
      cancelText: t('action.cancel'),
      confirmText: t('action.delete'),
      confirmType: 'danger',
      onConfirm: (_, closeDialog) => {
        clearAllSystemMessages();
        closeDialog();
      }
    });
  };

  const systemNotifications = snackbarMessages.map(obj => {
    return {
      ...obj,
      date: moment().format(DATE_TIME_FORMAT)
    };
  });

  return (
    <MessagePopover
      icon="MessageCloudIcon"
      iconClassName={iconClassName}
      headerMessageText={t('systemNotification')}
      bottomActionText={t('action.clear')}
      handleBottomActionClick={deleteAllMessagesHandler}
      hasMessages={!!snackbarMessages?.length}
      noMessagesText={t('noSystemNotifiaction')}
      unreadMessagesCount={snackbarMessages?.length || null}
      tooltipTitle={t('messages')}
    >
      {snackbarMessages?.length && (
        <Scrollbar
          options={{ suppressScrollX: true, suppressScrollY: snackbarMessages?.length < 8 && true }}
          className={classes.scrollbar}
        >
          {systemNotifications.map(error => (
            <MessagePopoverListItem
              systemNotification={error}
              key={error.id}
              handleClick={() => deleteMessageHandler(error.id)}
            />
          ))}
        </Scrollbar>
      )}
    </MessagePopover>
  );
}

export default SystemNotificationsPopover;
