import { AxiosRequestConfig } from 'axios';

import { useMutation, useQueryCache } from '@libs/common/v2/api';

import { API, DocumentTemplateQueryKeysEnum, endpointsConfig } from '..';

const removeDocumentTemplate = async (documentTemplateId: string) => {
  const { data } = await API.documentGenerator.deleteTemplate({ documentTemplateId }, {
    ...endpointsConfig.deleteTemplate
  } as AxiosRequestConfig);
  return data;
};

export default function useRemoveDocumentTemplateMutation({ onSuccess }) {
  const queryCache = useQueryCache();
  return useMutation(removeDocumentTemplate, {
    onSuccess: () => {
      queryCache.invalidateQueries(DocumentTemplateQueryKeysEnum.DOCUMENT_TEMPLATES);
      onSuccess();
    }
  });
}
