export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  updateFolderStatus: { translationKey: 'folder:api.updateFolderStatus' },
  createFolderDocument: { translationKey: 'folder:api.createFolderDocument' },
  transferFolder: { translationKey: 'folder:api.transferFolder' },
  assignToTransferFolder: { translationKey: 'folder.api.assignToTransferFolder' },
  getFoldersToTransfer: { translationKey: 'folder:api.getFoldersToTransfer' },
  getFolderDetails: { translationKey: 'folder:api.getFolderDetails' },
  proceedingKreptdRestoreAbility: { translationKey: 'proceeding:api.proceedingKreptdRestoreAbility' },
  proceedingKreptdDeclarationOfInability: {
    translationKey: 'proceeding:api.proceedingKreptdDeclarationOfInability'
  },
  addCertificateToProceedingKreptd: { translationKey: 'roadTransport:api.addCertificate' },
  deleteProceedingsKreptdCertificate: { translationKey: 'roadTransport:api.deleteCertificate' },
  addProceedingKreptdDocument: { translationKey: 'proceeding:api.addProceedingKreptdDocument' },
  updateProceedingKreptdFineImposed: { translationKey: 'proceeding:api.updateProceedingKreptdFineImposed' },
  updateProceedingKreptdFolder: { translationKey: 'proceeding:api.updateProceedingKreptdFolder' },
  updateMultipleProceedingsAssignee: { translationKey: 'proceeding:api.updateMultipleProceedingsAssignee' },
  proceedingKreptdPermitSuspension: { translationKey: 'proceeding:api.proceedingKreptdPermitSuspension' },
  updateProceedingKreptd: { translationKey: 'proceeding:api.updateProceedingKreptd' },
  addProceedingKreptdViolationsToCurrent: { translationKey: 'proceeding:api.addProceedingKreptdViolationsToCurrent' },
  createProceedingKreptdBaseOnViolations: {
    translationKey: 'proceeding:api.createProceedingKreptdBaseOnViolations'
  },
  updateViolationStatus: { translationKey: 'proceeding:api.updateViolationStatus' },
  getProceedingKreptdDocumentsPage: { translationKey: 'proceeding:api.getProceedingKreptdDocumentsPage' },
  getProceedingsKreptdDetails: { translationKey: 'proceeding:api.getProceedingsKreptdDetails' },
  getProceedingsKreptdSnapshotPage: { translationKey: 'proceeding:api.getProceedingsKreptdSnapshotPage' },
  getViolationsSnapshotPage: { translationKey: 'proceeding:api.getViolationsSnapshotPage' },
  getTransportManagersCertificatesSnapshotPage: {
    translationKey: 'proceeding.api.getTransportManagersCertificatesSnapshotPage'
  }
};
