import React, { Context, useContext } from 'react';
import { Skeleton } from '@mui/material';
import clsx from 'clsx';

import { FormV2Context, FormV2ContextState } from '../form';

const withSkeleton = <P extends { className?: string; style?: React.CSSProperties }>(
  WrappedComponent: React.ComponentType<P>,
  context: Context<FormV2ContextState> = FormV2Context
): React.FC<P> =>
  function WithSkeletonHoc(props) {
    const { loading } = useContext(context);
    const { className, style } = props;

    return loading ? (
      <div className={clsx(className, 'flex items-stretch flex-col')} style={style}>
        <Skeleton variant="rectangular" animation="wave" height={56} className="mb-16" />
      </div>
    ) : (
      <WrappedComponent {...props} />
    );
  };

export default withSkeleton;
