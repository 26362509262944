import { useTranslation } from 'react-i18next';
import { AccountSnapshot } from '@avispon/user';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import { DropdownListButton, TableButton } from '@libs/common/v2';

import { useUserStatusActionsDefinition } from '@libs/user/hooks';

interface IProps {
  userDetails: AccountSnapshot;
  onAccept: () => void;
  onCancel?: () => void;
  isTableButton?: boolean;
  actionKey?: UIElementNameEnum;
}

function UnlockUserPopUp({ userDetails, onAccept, onCancel, isTableButton = true, actionKey }: IProps) {
  const [t] = useTranslation();

  const { handleChangeUserStatusToLockOrUnlock } = useUserStatusActionsDefinition({
    userDetails,
    refetchUserDetails: onAccept,
    closeContextMenu: onCancel
  });

  return isTableButton ? (
    <TableButton
      label={t('user:action.unblockUser')}
      onClick={() => handleChangeUserStatusToLockOrUnlock()}
      actionKey={actionKey}
    />
  ) : (
    <DropdownListButton
      label={t('user:action.unblockUser')}
      onClick={() => handleChangeUserStatusToLockOrUnlock()}
      actionKey={actionKey}
    />
  );
}

export default UnlockUserPopUp;
