import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TableButtonDelete, TableLayout } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { useViewModesV2 } from '@libs/common/v2/form';

import { API } from '@libs/domain/api';

import ApplicationQueryKeysEnum from '../../application/api/queries/ApplicationQueryKeysEnum';
import { statusesWithBlockedEdit } from '../../application/utils';
import { DomainDictionaryEntry, DomainUIElementEnum } from '../../config';
import { usePartnerApplicantsDeleteAction, usePartnerApplicantsTable } from '../hooks';

import PartnerApplicantsCreateButton from './PartnerApplicantsCreateButton';
import PartnerApplicantsEditButton from './PartnerApplicantsEditButton';

interface IProps {
  applicationId?: string;
  subjectId?: string;
  setHqAddress?: React.Dispatch<React.SetStateAction<unknown>>;
  setMailingAddress?: React.Dispatch<React.SetStateAction<unknown>>;
  applicationStatusKey?: string;
}

function PartnerApplicantsTable({ applicationId, setHqAddress, setMailingAddress, applicationStatusKey }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const tableProps = usePartnerApplicantsTable({ applicationId });
  const { viewMode } = useViewModesV2();
  const { handlePartnerApplicantDelete } = usePartnerApplicantsDeleteAction();

  const editIsBlocked = useMemo(
    () => statusesWithBlockedEdit.some(status => status === applicationStatusKey),
    [applicationStatusKey]
  );

  return (
    <TableLayout
      {...tableProps}
      xlsxDownload={{
        fileName: t('partnerApplicants:tableTitle'),
        pathToXLSXTranslation: 'partnerApplicants:field',
        apiRequest: params => API.partnerApplicants.getPartnerApplicants(params)
      }}
      pageTitle={t('partnerApplicants:tableTitle')}
      headerActions={!viewMode && <PartnerApplicantsCreateButton applicationId={applicationId} />}
      bodyProps={{
        onRowClick: ({ row, tableInstance }) => {
          setHqAddress(row.original.HQAddress);
          if (row.original.correspondenceAddress) {
            setMailingAddress(row.original.correspondenceAddress);
          } else {
            setMailingAddress(row.original.HQAddress);
          }
          tableInstance.toggleAllRowsSelected(false);
          row.toggleRowSelected(true);
        }
      }}
      rowActions={(
        { original, toggleRowSelected },
        {
          tableContext: {
            data: { totalElements }
          }
        }
      ) => {
        const isFirstLp = original?.lp === 1;
        const isDisabled = isFirstLp ? totalElements > 1 : false;
        const isDeleteButtonVisible = original?.roleKey !== DomainDictionaryEntry.PARTNER_APPLICANT.APPLICANT;

        return editIsBlocked ? null : (
          <>
            {isDeleteButtonVisible ? (
              <TableButtonDelete
                tooltipTitle={t('action.delete')}
                isDisabled={isDisabled}
                actionKey={DomainUIElementEnum.APPLICATION_ITEM_PARTNER_APPLICANT_SUBJECT_DELETE_BUTTON}
                onClick={() => {
                  handlePartnerApplicantDelete({
                    partnerApplicantId: original.id,
                    onSuccess: () => queryCache.invalidateQueries(ApplicationQueryKeysEnum.PARTNER_APPLICANTS)
                  });
                }}
              />
            ) : null}
            <PartnerApplicantsEditButton
              partnerApplicant={original}
              partnerApplicantId={original.id}
              clearSelection={() => {
                toggleRowSelected(false);
                setMailingAddress(null);
                setHqAddress(null);
              }}
            />
          </>
        );
      }}
      isSection
    />
  );
}

export default PartnerApplicantsTable;
