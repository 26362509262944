import { useCallback } from 'react';

import { FormMode, useDialog } from '@libs/common/v2';

import { AddLockDialog } from '../../components/details/tabs/locks';

function useAddLockDialog(folderId?: string) {
  const { openDialog } = useDialog();

  const openAddLockDialog = useCallback(
    ({
      formMode,
      defaulTypeKey,
      folderId: folderIdInParam
    }: {
      formMode?: FormMode;
      defaulTypeKey?: string;
      folderId?: string;
    }) => {
      openDialog(({ closeDialog }) => (
        <AddLockDialog
          closeDialog={closeDialog}
          folderId={folderIdInParam || folderId}
          formMode={formMode}
          defaulTypeKey={defaulTypeKey}
        />
      ));
    },
    [openDialog, folderId]
  );

  return {
    openAddLockDialog
  };
}

export default useAddLockDialog;
