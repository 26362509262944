import { ReactNode } from 'react';
import { FieldNamesMarkedBoolean } from 'react-hook-form';
import { makeStyles } from '@mui/styles';

import { TabContent, Tabs, TabsContent, TabsGroup } from '@libs/common/v2';
import { important } from '@libs/common/v2/utils';

import { AlertDetailsTab } from '@libs/alert/model';

import AlertGeneralDataTab from './AlertGeneralDataTab';
import AlertHistoryTable from './AlertHistoryTable';

interface IProps {
  activeTab: AlertDetailsTab;
  setActiveTab: (tab: AlertDetailsTab) => void;
  visibleTabHeaders: ReactNode[];
  dirtyFields?: FieldNamesMarkedBoolean<Record<string, any>>;
  isViewMode: boolean;
}

function AlertTabsContent({ activeTab, setActiveTab, visibleTabHeaders, dirtyFields, isViewMode }: IProps) {
  const classes = useStyles();

  return isViewMode ? (
    <TabsGroup>
      <Tabs value={activeTab} onChange={setActiveTab} orientation="horizontal" className={classes.tabsWrapper}>
        {visibleTabHeaders}
      </Tabs>
      <TabsContent value={activeTab} className={classes.tabsContent}>
        <TabContent value={AlertDetailsTab.ALERT_GENERAL_DATA} isInnerScroll={false}>
          <AlertGeneralDataTab dirtyFields={dirtyFields} />
        </TabContent>
        <TabContent value={AlertDetailsTab.ALERT_HISTORY} isInnerScroll={false}>
          <AlertHistoryTable />
        </TabContent>
      </TabsContent>
    </TabsGroup>
  ) : (
    <AlertGeneralDataTab dirtyFields={dirtyFields} />
  );
}

const useStyles = makeStyles(theme => ({
  tabsContent: {
    overflow: important('hidden')
  },
  tabsWrapper: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`
  }
}));

export default AlertTabsContent;
