import { useTableLayoutProps } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { useDepotTransfersQuery } from '../../api';
import { useDepotTransfersColumns } from '../columns';

function useDepotTransfersTable() {
  const { columns, mappedFilterFields, mappedSortFields } = useDepotTransfersColumns();

  return useTableLayoutProps({
    tableHookQuery: useDepotTransfersQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    },
    tableHookQueryInitialParams: {
      statusKeyIn: [DomainDictionaryEntry.DEPOT_TRANSFER_OPERATION_STATE.WAITING]
    }
  });
}

export default useDepotTransfersTable;
