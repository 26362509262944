import { useMemo } from 'react';

import {
  AutocompleteSelectField,
  ColumnTypesEnum,
  DatepickerField,
  DictionarySelectField,
  ICustomMutableTableCellRenderer,
  InputMode,
  NumberInputField,
  SelectOption,
  SwitchField,
  TextInputField,
  TimepickerField,
  typedNameV2,
  useEditableInputsStyles,
  useTableAdapter
} from '@libs/common/v2';

import { DictionaryEntryNameEnum, DictionaryQuickChangeableEntryNameEnum } from '@libs/dictionary';

import FetchSelectField from './FetchSelectField';
import QuickChangeDictionarySelectField from './QuickChangeDictionarySelectField';

interface IProps<T extends Record<string, any> = Record<string, any>> {
  row: Record<string, any>;
  type: ColumnTypesEnum;
  name: string;
  dictionaryName?: DictionaryEntryNameEnum;
  quickDictionaryName?: DictionaryQuickChangeableEntryNameEnum;
  customEdit?: ICustomMutableTableCellRenderer<T>;
  customView?: ICustomMutableTableCellRenderer<T>;
  inputProps?: any;
  isViewMode?: boolean;
  /**
   * @fetchFunctionResolver - funkcja asynchroniczna która zwraca sparsowany array opcji do AutocompleteSelect
   */
  fetchFunctionResolver?: (params: any) => Promise<SelectOption[]>;
  hasErrorTooltip?: boolean;
}

function TableEditInput<T extends Record<string, any> = Record<string, any>>({
  row,
  type,
  name,
  dictionaryName,
  isViewMode,
  quickDictionaryName,
  fetchFunctionResolver,
  inputProps,
  customEdit,
  customView,
  hasErrorTooltip
}: IProps<T>) {
  const { state } = useTableAdapter();
  const classes = useEditableInputsStyles();

  const inputMode = useMemo(() => {
    if (isViewMode) {
      return InputMode.VIEW;
    }
    if (state.rowId && row.id === state.rowId) {
      return InputMode.FORM;
    }
    return InputMode.VIEW;
  }, [isViewMode, state.rowId, row.id]);

  const rowOriginal = useMemo(() => row?.original, [row]);

  const inputName = typedNameV2<any>(`${row.id}.${name}`);

  const customCellParams: Parameters<ICustomMutableTableCellRenderer<T>> = [inputMode, rowOriginal, inputName, classes];
  let customCell: JSX.Element = null;

  switch (inputMode) {
    case InputMode.FORM:
      customCell = customEdit?.(...customCellParams);
      break;
    case InputMode.VIEW:
      customCell = customView?.(...customCellParams);
      break;
    default:
      break;
  }
  if (customCell) {
    return customCell;
  }
  // eslint-disable-next-line default-case
  switch (type) {
    case ColumnTypesEnum.TEXT:
      return (
        <div style={{ width: '100%' }}>
          <TextInputField
            name={inputName}
            inputMode={inputMode}
            className={classes.text}
            valueClassName={classes.value}
            hasErrorTooltip={hasErrorTooltip}
            {...inputProps}
          />
        </div>
      );
    case ColumnTypesEnum.DICTIONARY_MULTI_SELECT:
      return (
        <div style={{ width: '100%' }}>
          <DictionarySelectField
            name={inputName}
            className={classes.select}
            valueClassName={classes.value}
            inputMode={inputMode}
            dictionaryName={dictionaryName}
            hasErrorTooltip={hasErrorTooltip}
            {...inputProps}
          />
        </div>
      );
    case ColumnTypesEnum.DICTIONARY_SINGLE_SELECT:
      return (
        <div style={{ width: '100%' }}>
          <DictionarySelectField
            name={inputName}
            inputMode={inputMode}
            className={classes.select}
            valueClassName={classes.value}
            dictionaryName={dictionaryName}
            isMultiple={false}
            hasErrorTooltip={hasErrorTooltip}
            {...inputProps}
          />
        </div>
      );
    case ColumnTypesEnum.MULTI_SELECT:
      return (
        <div style={{ width: '100%' }}>
          <AutocompleteSelectField
            name={inputName}
            valueClassName={classes.value}
            className={classes.select}
            inputMode={inputMode}
            hasErrorTooltip={hasErrorTooltip}
            {...inputProps}
          />
        </div>
      );
    case ColumnTypesEnum.SINGLE_SELECT:
      return (
        <div style={{ width: '100%' }}>
          <AutocompleteSelectField
            name={inputName}
            valueClassName={classes.value}
            className={classes.select}
            inputMode={inputMode}
            isMultiple={false}
            hasErrorTooltip={hasErrorTooltip}
            {...inputProps}
          />
        </div>
      );
    case ColumnTypesEnum.BOOLEAN:
      return (
        <div style={{ width: '100%' }}>
          <SwitchField
            name={inputName}
            className={classes.switch}
            valueClassName={classes.value}
            inputMode={inputMode}
            {...inputProps}
            isTableCell
          />
        </div>
      );
    case ColumnTypesEnum.DATE:
      return (
        <div style={{ width: '100%' }}>
          <DatepickerField
            name={inputName}
            textInputClassName={classes.text}
            valueClassName={classes.value}
            inputMode={inputMode}
            hasErrorTooltip={hasErrorTooltip}
            {...inputProps}
          />
        </div>
      );
    case ColumnTypesEnum.TIME:
      return (
        <div style={{ width: '100%' }}>
          <TimepickerField
            name={inputName}
            textInputClassName={classes.text}
            valueClassName={classes.value}
            inputMode={inputMode}
            hasErrorTooltip={hasErrorTooltip}
            {...inputProps}
          />
        </div>
      );
    case ColumnTypesEnum.NUMBER:
      return (
        <div style={{ width: '100%' }}>
          <NumberInputField
            name={inputName}
            inputMode={inputMode}
            className={classes.text}
            valueClassName={classes.value}
            hasErrorTooltip={hasErrorTooltip}
            {...inputProps}
          />
        </div>
      );
    case ColumnTypesEnum.QUICK_DICTIONARY_MULTI_SELECT:
      return (
        <div style={{ width: '100%' }}>
          <QuickChangeDictionarySelectField
            name={inputName}
            valueClassName={classes.value}
            className={classes.select}
            inputMode={inputMode}
            dictionary={quickDictionaryName}
            hasErrorTooltip={hasErrorTooltip}
            {...inputProps}
          />
        </div>
      );
    case ColumnTypesEnum.QUICK_DICTIONARY_SINGLE_SELECT:
      return (
        <div style={{ width: '100%' }}>
          <QuickChangeDictionarySelectField
            valueClassName={classes.value}
            name={inputName}
            className={classes.select}
            inputMode={inputMode}
            dictionary={quickDictionaryName}
            isMultiple={false}
            hasErrorTooltip={hasErrorTooltip}
            {...inputProps}
          />
        </div>
      );
    case ColumnTypesEnum.MULTI_SELECT_FETCH:
      return (
        <div style={{ width: '100%' }}>
          <FetchSelectField
            fetchFunctionResolver={fetchFunctionResolver}
            name={inputName}
            className={classes.select}
            inputMode={inputMode}
            hasErrorTooltip={hasErrorTooltip}
            {...inputProps}
          />
        </div>
      );
    case ColumnTypesEnum.SINGLE_SELECT_FETCH:
      return (
        <div style={{ width: '100%' }}>
          <FetchSelectField
            fetchFunctionResolver={fetchFunctionResolver}
            name={inputName}
            className={classes.select}
            inputMode={inputMode}
            isMultiple={false}
            hasErrorTooltip={hasErrorTooltip}
            {...inputProps}
          />
        </div>
      );
  }
  return null;
}

export default TableEditInput;
