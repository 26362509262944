import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { Dialog, GridLayout, Typography, useDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { WarningInformation } from '@libs/common/v2/components/warning';

import { useCopyFolderVehiclesMutation, useCopyVehiclesMutation, VehicleQueryKeysEnum } from '@libs/domain/vehicle';

function useConfirmAddVehicleDialog(onSuccess?: () => void) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { mutate: copyFolderVehicles } = useCopyFolderVehiclesMutation();
  const { mutate: copyVehicles } = useCopyVehiclesMutation();

  const { openDialog } = useDialog();
  const { showSuccessSnackbar } = useSnackbar();

  const openConfirmAddVehicleDialog = useCallback(
    (rowIds: string[], applicationId: string, certificateStatusList?: string[], hasIpwzWarningInformation?: boolean) =>
      openDialog(({ closeDialog }) => (
        <Dialog
          title={t('vehicle:dialog.addVehicle.title')}
          confirmText={t('action.add')}
          cancelText={t('action.cancel')}
          onConfirm={setLoading => {
            setLoading(true);

            const requestOptions = {
              onSuccess: () => {
                onSuccess?.();
                queryCache.invalidateQueries([VehicleQueryKeysEnum.VEHICLE_LIST]);
                showSuccessSnackbar(
                  rowIds?.length > 1
                    ? t('vehicle:details.message.vehicleAddedPlural')
                    : t('vehicle:details.message.vehicleAdded')
                );
              },
              onSettled: () => {
                setLoading(false);
                closeDialog();
              }
            };
            if (certificateStatusList.length) {
              copyVehicles({ applicationId, vehicleIds: rowIds }, requestOptions);
            } else {
              copyFolderVehicles({ applicationId, vehicleIds: rowIds }, requestOptions);
            }
          }}
          onCancel={closeDialog}
          isOpen
        >
          <GridLayout itemProps={{ xs: 12 }}>
            <Typography>
              {rowIds?.length > 1
                ? t('vehicle:dialog.addVehicle.messagePlural')
                : t('vehicle:dialog.addVehicle.message')}
            </Typography>

            {hasIpwzWarningInformation && (
              <WarningInformation
                isIconVisible
                icon="WarningIcon"
                content={
                  rowIds?.length > 1
                    ? t('vehicle:dialog.addVehicle.warningIpwzPlural')
                    : t('vehicle:dialog.addVehicle.warningIpwz')
                }
              />
            )}
          </GridLayout>
        </Dialog>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog, t, showSuccessSnackbar]
  );

  return { openConfirmAddVehicleDialog };
}

export default useConfirmAddVehicleDialog;
