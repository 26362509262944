import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { TableLayout } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { useAssociationOrdersTable } from '../../../hooks';
import { IAssociationOrdersClient } from '../../../model';
import { parseAssociationOrdersList } from '../../../utils';

import TableRowActions from './AssociationOrdersTableRowActions';

function AssociationOrdersTable() {
  const [t] = useTranslation();

  const tableProps = useAssociationOrdersTable();

  const actionsRowRender = useCallback(
    ({ original }: { original: IAssociationOrdersClient }) => <TableRowActions data={original} />,
    []
  );

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('resource:list.listOfAssociationOrders')}
      xlsxDownload={{
        fileName: t('resource:list.listOfResourceDetails'),
        pathToXLSXTranslation: 'resource:fields',
        apiRequest: params =>
          API.resourceObjectsOrders
            .getResourceObjectOrders(params)
            .then(res => ({ ...res, data: { ...res.data, content: parseAssociationOrdersList(res.data.content) } }))
      }}
      rowActions={actionsRowRender}
      actionsColumnWidth={150}
      isSectionFullTab
    />
  );
}

export default AssociationOrdersTable;
