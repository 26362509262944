import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppConfig } from '@libs/app-config';

import { useConfirmDialog } from '@libs/common/v2';

import { useGenerateReport } from '@libs/report/hooks';

function usePrintReportDialog() {
  const [t] = useTranslation();
  const [confirm] = useConfirmDialog();
  const { reportTypes } = useAppConfig();
  const [generateReport] = useGenerateReport(reportTypes?.returnedAndUnreturnedPermits?.reportTypeName);

  const openPrintReportDialog = useCallback(() => {
    confirm({
      title: t('reports:action.generateReport'),
      message: t('reports:message.printReportConfirmation'),
      onConfirm: () => {
        generateReport();
      }
    });
  }, [confirm, generateReport, t]);

  return { openPrintReportDialog };
}

export default usePrintReportDialog;
