import { PluginHook } from 'react-table';
import { ExtendedFinancialSecuritySearchFilter, FinancialSecurityDetails } from '@ibtm/domain';

import { CamelCasePath, useTableLayoutProps } from '@libs/common/v2';

import { useFinancialSecuritiesApplicationQuery, useFinancialSecuritiesQuery } from '../../api';
import { useFinancialSecurityColumns } from '..';

function useFinancialSecurityTable({
  visibleColumns,
  tablePlugins,
  queryParams,
  folderTypeKey,
  applicationId
}: {
  visibleColumns: Array<CamelCasePath<FinancialSecurityDetails>>;
  folderTypeKey: string;
  tablePlugins?: PluginHook<Record<string, any>>[];
  queryParams?: ExtendedFinancialSecuritySearchFilter;
  applicationId?: string;
}) {
  const { columns, mappedFilterFields, mappedSortFields } = useFinancialSecurityColumns(visibleColumns, folderTypeKey);

  return useTableLayoutProps({
    tableHookQuery: applicationId ? useFinancialSecuritiesApplicationQuery : useFinancialSecuritiesQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tableOptions: {
        initialState: {
          sortBy: [
            {
              id: 'expirationDate',
              desc: true
            }
          ]
        }
      },
      tablePlugins
    },
    tableHookQueryInitialParams: applicationId
      ? {
          applicationId,
          ...queryParams
        }
      : {
          ...queryParams
        }
  });
}

export default useFinancialSecurityTable;
