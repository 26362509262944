import { useTranslation } from 'react-i18next';

import {
  GridItem,
  GridLayout,
  InputMode,
  Section,
  TextInputField,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { SectionNames, useConfigurationContext } from '@libs/user/context';
import { CreateUserValues } from '@libs/user/models';

function LoginSection() {
  const [t] = useTranslation();
  const { renderAdditionalFields } = useConfigurationContext();

  const { formMode } = useFormV2Context();
  const { createMode } = useViewModesV2(formMode);

  return (
    <Section title={t('user:section.firstLoginAttempt')} isCollapsable>
      <GridLayout>
        {createMode && (
          <GridItem xs={12}>
            <TextInputField
              inputMode={InputMode.FORM}
              name={typedNameV2<CreateUserValues>('password')}
              inputProps={{ type: 'password' }}
              label={t('user:field.password')}
              isRequired
            />
          </GridItem>
        )}
        {createMode && (
          <GridItem xs={12}>
            <TextInputField
              inputMode={InputMode.FORM}
              name={typedNameV2<CreateUserValues>('repeatPassword')}
              inputProps={{ type: 'password' }}
              label={t('user:field.repeatPassword')}
              isRequired
            />
          </GridItem>
        )}
        <GridItem xs={12}>{renderAdditionalFields(SectionNames.LOGIN_SECTION)}</GridItem>
      </GridLayout>
    </Section>
  );
}

export default LoginSection;
