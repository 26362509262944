export function important(styleValue: string | number): string {
  return `${styleValue} !important`;
}

export function calc(operation: string): string {
  return `calc(${operation})`;
}

export function border(color: string, width = 1, type = 'solid'): string {
  return `${width}px ${type} ${color}`;
}
