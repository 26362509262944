import { actions, Hooks } from 'react-table';

import { useAdditionalColumnsInstance, useAdditionalColumnsReducer } from './additionalColumnsReducer';

// Actions
actions.insertColumnLast = 'insertColumnLast';
actions.deleteAddedColumns = 'deleteAddedColumns';
actions.cancelInsertingColumns = 'cancelInsertingColumns';

export const useAdditionalColumns = <T extends Record<string, unknown> = Record<string, unknown>>(hooks: Hooks<T>) => {
  hooks.stateReducers.push(useAdditionalColumnsReducer);
  hooks.useInstance.push(useAdditionalColumnsInstance);
};

useAdditionalColumns.pluginName = 'useAdditionalColumns';

export default useAdditionalColumns;
