import { Context, useContext } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';

import {
  ComponentErrorBoundary,
  FormErrorType,
  FormV2Context,
  FormV2ContextState,
  InputMode,
  TextArea
} from '@libs/common/v2';

import { getInputMode } from '../../utils';
import { FieldTypeEnum, TextFieldValidation, useFieldValidationHandler } from '../../validation';

export interface TextAreaFieldProps {
  name: FieldPath<FieldValues>;
  label?: string;
  className?: string;
  formContext?: Context<FormV2ContextState>;
  inputMode?: InputMode;
  validation?: TextFieldValidation;
  isDisabled?: boolean;
  isRequired?: boolean;
  placeholder?: string;
  minRows?: number;
  maxRows?: number;
  tooltip?: string;
  isLabelHidden?: boolean;
}

function TextAreaField({
  name,
  label,
  className,
  formContext = FormV2Context,
  inputMode,
  validation,
  isDisabled,
  isRequired,
  isLabelHidden,
  placeholder,
  minRows,
  maxRows,
  tooltip
}: TextAreaFieldProps) {
  const { control, formMode, isSubmitting } = useContext<FormV2ContextState>(formContext);
  const mode = getInputMode(formMode, inputMode);

  useFieldValidationHandler({
    fieldName: name,
    validation,
    fieldType: FieldTypeEnum.TEXT
  });

  return (
    <ComponentErrorBoundary componentName={label || placeholder || name || 'TextAreaField'}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
          <TextArea
            name={name}
            label={label}
            className={className}
            value={value || ''}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isDisabled || isSubmitting}
            placeholder={placeholder}
            required={isRequired || Boolean(validation?.required) || false}
            isLabelHidden={isLabelHidden}
            error={error && error?.type !== FormErrorType.WARNING}
            helperText={error?.message}
            minRows={minRows}
            maxRows={maxRows}
            tooltip={tooltip}
            readOnly={mode === InputMode.VIEW}
          />
        )}
      />
    </ComponentErrorBoundary>
  );
}

export default TextAreaField;
