import { SuspensionFormCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const createFormOperation = (params: { parentId: string; request: SuspensionFormCreateRequest }) => {
  const { parentId, request } = params;
  return API.suspensions.addSuspensionForm(parentId, request, {
    ...endpointsConfig.addSuspensionForm
  });
};

const useCreateSuspensionFormOperationsMutation = () => {
  return useMutation(createFormOperation);
};

export default useCreateSuspensionFormOperationsMutation;
