import { ArchiveNoteUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function createArchiveFolderNote(params: ArchiveNoteUpdateRequest) {
  return API.archiveFolders.createArchiveFolderNote(params, {
    ...endpointsConfig.createArchiveFolderNote
  });
}

function useCreateArchiveFolderNoteMutation() {
  return useMutation(createArchiveFolderNote);
}

export default useCreateArchiveFolderNoteMutation;
