import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryCache } from 'react-query';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddressSnapshot } from '@ibtm/domain';
import _ from 'lodash';
import { boolean as YupBoolean, object as YupObject, string as YupString } from 'yup';

import { Dialog, FormV2Context } from '@libs/common/v2';

import { PrintsQueryKeysEnum, useUpdateApplicationPrintingItem } from '../../../api';
import SubjectDataChangeForm from '../SubjectDataChangeForm';

export interface ISubjectDataChangeDialogProps {
  onClose: () => void;
  isLicense?: boolean;
  isPermission?: boolean;
  licenseAddress: AddressSnapshot;
  permissionAddress: AddressSnapshot;
  licenseSubjectName: string;
  licenseSubjectNip: string;
  permissionSubjectName: string;
  permissionSubjectNip: string;
  licenseIsSameAsMainAddress: boolean;
  permissionIsSameAsMainAddress: boolean;
  applicationId: string;
}

export const SubjectDataChangeDialog = ({
  onClose,
  isLicense,
  isPermission,
  licenseAddress,
  licenseIsSameAsMainAddress,
  licenseSubjectName,
  licenseSubjectNip,
  permissionAddress,
  permissionIsSameAsMainAddress,
  permissionSubjectName,
  permissionSubjectNip,
  applicationId
}: ISubjectDataChangeDialogProps) => {
  const [t] = useTranslation();
  const queryCache = useQueryCache();
  const { mutate: updateSubjectQuery, isLoading } = useUpdateApplicationPrintingItem({
    onSuccess: () => {
      queryCache.invalidateQueries(PrintsQueryKeysEnum.PRINTS_DETAILS);
      showSuccessSnackbar(t('prints:messages.editPrintSuccessMessage'));
      handleOnClose();
    }
  });
  const { showSuccessSnackbar } = useSnackbar();

  const validationSchema = YupObject({
    licenseData: YupObject({
      isSameAsMainAddress: YupBoolean().nullable(),
      name: YupString().when('isSameAsMainAddress', {
        is: false,
        then: YupString().required(),
        otherwise: YupString().nullable()
      }),
      address: YupObject().when('isSameAsMainAddress', {
        is: false,
        then: YupObject({
          street: YupString().required(),
          city: YupString().required(),
          postCode: YupString().required().postCode(),
          propertyNumber: YupString().required(),
          postCity: YupString().required()
        })
      })
    }),
    permissionData: YupObject({
      isSameAsMainAddress: YupBoolean().nullable(),
      name: YupString().when('isSameAsMainAddress', {
        is: false,
        then: YupString().required(),
        otherwise: YupString().nullable()
      }),
      address: YupObject().when('isSameAsMainAddress', {
        is: false,
        then: YupObject({
          street: YupString().required(),
          city: YupString().required(),
          postCode: YupString().required().postCode(),
          propertyNumber: YupString().required(),
          postCity: YupString().required()
        })
      })
    })
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    clearErrors,
    reset
  } = useForm({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema)
  });

  const handleOnClose = () => {
    onClose();
  };

  const getParsedPrintoutDataUpdateRequest = values => {
    const hasPermissionData = !_.isNil(values.isSameAsMainAddress) || !_.isEmpty(values.address);
    if (!hasPermissionData) {
      return null;
    }
    return values?.isSameAsMainAddress ? { isSameAsMainAddress: true, nip: values?.nip, name: values?.name } : values;
  };

  const onSubmit = () => {
    const values = getValues();
    updateSubjectQuery({
      applicationId,
      applicationPrintingItemPermissionDataUpdateRequest: {
        licenseData: getParsedPrintoutDataUpdateRequest(values.licenseData),
        permissionData: getParsedPrintoutDataUpdateRequest(values.permissionData)
      }
    });
  };

  useEffect(() => {
    reset({
      licenseData: {
        isSameAsMainAddress: licenseIsSameAsMainAddress,
        name: licenseSubjectName,
        nip: licenseSubjectNip,
        address: {
          ...licenseAddress
        }
      },
      permissionData: {
        isSameAsMainAddress: permissionIsSameAsMainAddress,
        name: permissionSubjectName,
        nip: permissionSubjectNip,
        address: {
          ...permissionAddress
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const values = useMemo(
    () => ({
      control,
      register,
      handleSubmit,
      errors,
      isSubmitting,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      clearErrors
    }),
    [
      control,
      register,
      handleSubmit,
      errors,
      isSubmitting,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      clearErrors
    ]
  );
  return (
    <Dialog
      title={t('prints:messages.subjectDataDialogTitle')}
      onClose={handleOnClose}
      onCancel={handleOnClose}
      isOpen
      onConfirm={() => {
        handleSubmit(onSubmit)();
      }}
      isConfirmLoading={isLoading}
      confirmText={t('action.save')}
    >
      <FormV2Context.Provider value={values}>
        {isLicense && (
          <SubjectDataChangeForm groupName="license" title={t('prints:printsDetailsBlocks.printLicenseData')} />
        )}
        {isPermission && (
          <SubjectDataChangeForm
            groupName="permission"
            title={t('prints:printsDetailsBlocks.printPermitData')}
            isNipFieldVisible
          />
        )}
      </FormV2Context.Provider>
    </Dialog>
  );
};
