import { WarningDefinition, WarningEnumKeys } from '../models';

export const WARNING_DEFINITIONS: WarningDefinition[] = [
  {
    applicationType: ['ibtm-domain.application.type.license-pb2-a'],
    warning: {
      checkKey: WarningEnumKeys.IS_FOLDER_NOT_LICENSE_DATE_MORE_THAN_10Y,
      message:
        'W teczce nie ma licencji wydanej na okres powyżej 10 lat. Wniosek nie powinien zostać obsłużony pozytywnie.',
      name: WarningEnumKeys.IS_FOLDER_NOT_LICENSE_DATE_MORE_THAN_10Y
    }
  },
  {
    applicationType: ['ibtm-domain.application.type.license-pb2-a'],
    warning: {
      checkKey: WarningEnumKeys.IS_LICENSE_COPY_BEFORE_VALID,
      message: 'Wniosek złożony przed terminem.',
      name: WarningEnumKeys.IS_LICENSE_COPY_BEFORE_VALID
    }
  },
  {
    applicationType: [
      'ibtm-domain.application.type.license-pb6-c',
      'ibtm-domain.application.type.license-pb6-d',
      'ibtm-domain.application.type.license-pb6-f',
      'ibtm-domain.application.type.license-pb29-b'
    ],
    warning: {
      checkKey: WarningEnumKeys.IS_APPLICATION_HAS_ACTIVE_LICENSE,
      message: 'Nabywca posiada aktywną licencję. Wniosek powinien zostać obsłużony odmownie.',
      name: WarningEnumKeys.IS_APPLICATION_HAS_ACTIVE_LICENSE
    }
  },
  {
    applicationType: ['ibtm-domain.application.type.license-pb5'],
    warning: {
      checkKey: WarningEnumKeys.IS_APPLICATION_HAS_ATLEAST_ONE_ACTIVE_VEHICLE,
      message: 'Na teczce brak zgłoszonych pojazdów.',
      name: WarningEnumKeys.IS_APPLICATION_HAS_ATLEAST_ONE_ACTIVE_VEHICLE
    }
  },
  {
    applicationType: ['ibtm-domain.application.type.license-pb5'],
    warning: {
      checkKey: WarningEnumKeys.IS_APPLICATION_LICENSE_MORE_THAN_VEHICLE,
      message: 'Liczba wypisów większa niż liczba zgłoszonych pojazdów.',
      name: WarningEnumKeys.IS_APPLICATION_LICENSE_MORE_THAN_VEHICLE
    }
  },
  {
    applicationType: ['ibtm-domain.application.type.license-pb1-a', 'ibtm-domain.application.type.license-pb1-d'],
    warning: {
      checkKey: WarningEnumKeys.IS_SUBJECT_HAS_ACTIVE_PERMISSIONS,
      message: 'Podmiot ma aktywne zezwolenie – nie można drugi raz wnioskować o udzielenie zezwolenia.',
      name: WarningEnumKeys.IS_SUBJECT_HAS_ACTIVE_PERMISSIONS
    }
  },
  {
    applicationType: [
      'ibtm-domain.application.type.license-pb7-a',
      'ibtm-domain.application.type.license-pb7-b',
      'ibtm-domain.application.type.license-pb8-a',
      'ibtm-domain.application.type.license-pb8-b',
      'ibtm-domain.application.type.license-pb28',
      'ibtm-domain.application.type.license-pb30'
    ],
    warning: {
      checkKey: WarningEnumKeys.IS_DATE_OF_FOLDER_SUBJECT_DEATH_PAST_6MONTHS,
      message: 'Od daty zgonu przedsiębiorcy minęło ponad 6 miesięcy. Wniosek nie został złożony w terminie.',
      name: WarningEnumKeys.IS_DATE_OF_FOLDER_SUBJECT_DEATH_PAST_6MONTHS
    }
  },
  {
    applicationType: [],
    applicationTypeIncludes: ['ibtm-domain.application.type.spo'],
    warning: {
      checkKey: WarningEnumKeys.IS_DEADLINE_DATE_EXPIRED,
      message: 'Data terminu się przedawniła.',
      name: WarningEnumKeys.IS_DEADLINE_DATE_EXPIRED
    }
  },
  {
    applicationType: [],
    applicationTypeIncludes: ['ibtm-domain.application.type.'],
    warning: {
      checkKey: WarningEnumKeys.IS_FOLDER_HAS_NOT_ACTIVE_LICENSE,
      message: 'W teczce nie ma ważnej licencji',
      name: WarningEnumKeys.IS_FOLDER_HAS_NOT_ACTIVE_LICENSE
    }
  },
  {
    applicationType: [],
    applicationTypeIncludes: ['ibtm-domain.application.type.'],
    warning: {
      checkKey: WarningEnumKeys.IS_FOLDER_HAS_NOT_ARCHIVE_NUMBER,
      message: 'Brak nadanego numeru sprawy EZD. Nadawanie numeru w toku',
      name: WarningEnumKeys.IS_FOLDER_HAS_NOT_ARCHIVE_NUMBER
    }
  },
  {
    applicationType: [],
    applicationTypeIncludes: ['ibtm-domain.application.type.'], // Dla wszystkich wniosków
    warning: {
      checkKey: WarningEnumKeys.IS_APPLICATION_ALREADY_IN_PROGRESS_FOR_GIVEN_DRIVER,
      message: 'Dla podanego kierowcy na danej teczce przedsiębiorcy jest już realizowany wniosek',
      name: WarningEnumKeys.IS_APPLICATION_ALREADY_IN_PROGRESS_FOR_GIVEN_DRIVER
    }
  },
  {
    applicationType: ['ibtm-domain.application.type.license-pb30', 'ibtm-domain.application.type.license-pb7-a'],
    applicationTypeIncludes: [],
    warning: {
      checkKey: WarningEnumKeys.IS_SUCCESSOR_HAS_ACTIVE_LICENSE,
      message: 'Spadkobierca posiada aktywną licencję. Wniosek powinien zostać obsłużony odmownie',
      name: WarningEnumKeys.IS_SUCCESSOR_HAS_ACTIVE_LICENSE
    }
  },
  {
    applicationType: ['ibtm-domain.application.type.license-pb1-a', 'ibtm-domain.application.type.license-pb26'],
    applicationTypeIncludes: [],
    warning: {
      checkKey: WarningEnumKeys.IS_PARTNER_HAS_ACTIVE_LICENSE,
      message: 'Wspólnicy spółki (wszyscy lub część) posiadają już aktywną licencję',
      name: WarningEnumKeys.IS_PARTNER_HAS_ACTIVE_LICENSE
    }
  },
  {
    applicationType: ['ibtm-domain.application.type.license-pb1-a', 'ibtm-domain.application.type.license-pb26'],
    applicationTypeIncludes: [],
    warning: {
      checkKey: WarningEnumKeys.IS_PARTNER_HAS_ACTIVE_PERMISSION,
      message: 'Wspólnicy spółki (wszyscy lub część) posiadają już aktywnę zezwolenie',
      name: WarningEnumKeys.IS_PARTNER_HAS_ACTIVE_PERMISSION
    }
  },
  {
    applicationType: [],
    applicationTypeIncludes: [
      'ibtm-domain.application.type.spo-position-mind-',
      'ibtm-domain.application.type.spo-outside-ue-',
      'ibtm-domain.application.type.spo-ue-'
    ],
    warning: {
      checkKey: WarningEnumKeys.IS_MORE_THAN_TWO_MONTHS_AFTER_DOCUMENT_RECEIPT_ACK_DATE,
      message: 'Minął termin na zgłaszanie uwag.',
      name: WarningEnumKeys.IS_MORE_THAN_TWO_MONTHS_AFTER_DOCUMENT_RECEIPT_ACK_DATE
    }
  },
  {
    applicationType: [],
    applicationTypeIncludes: ['ibtm-domain.application.type.'],
    warning: {
      checkKey: WarningEnumKeys.IS_APPLICATION_ARCHIVED,
      message: 'Wniosek został zarchiwizowany',
      name: WarningEnumKeys.IS_APPLICATION_ARCHIVED
    }
  },
  {
    applicationType: [],
    applicationTypeIncludes: ['ibtm-domain.application.type.spo-wzo', 'ibtm-domain.application.type.spo-wzw'],
    warning: {
      checkKey: WarningEnumKeys.IS_APPLICATION_PASSED_TO_FOREIGN_PERMISSION_DEPARTMENT,
      message: 'Wniosek został przekazany do realizacji Wydziału Zezwoleń Zagranicznych',
      name: WarningEnumKeys.IS_APPLICATION_PASSED_TO_FOREIGN_PERMISSION_DEPARTMENT
    }
  }
];
