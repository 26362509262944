import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, useTableAdapter, useTableContext } from '@libs/common/v2';

import { useExportAllReportTypesMutation } from '@libs/report/api';
import { useUploadReportTypeDialog } from '@libs/report/hooks';
import { convertDataToZip } from '@libs/report/utils/reports.util';

export function ReportTypesTableHeaderActions() {
  const [t] = useTranslation();
  const { data: tableData } = useTableAdapter();
  const { refetch } = useTableContext();
  const { mutate: exportAllReportTypes, isLoading: isExportAllLoading } = useExportAllReportTypesMutation();
  const { openUploadReportTypeDialog } = useUploadReportTypeDialog(refetch, true);

  const handleExportAllReports = useCallback(() => {
    const reportTypeIds = tableData.map(({ id }) => id) as string[];
    exportAllReportTypes(reportTypeIds, {
      onSuccess: (data: Blob) => {
        convertDataToZip(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  return (
    <>
      <Button
        variant="outlined"
        label={t('reports:reportTypeImport.importReportType')}
        onClick={openUploadReportTypeDialog}
      />
      <Button
        variant="outlined"
        label={t('reports:reportTypeExport.exportAllReportTypes')}
        onClick={handleExportAllReports}
        isLoading={isExportAllLoading}
      />
    </>
  );
}
