import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AutocompleteSelectField,
  DictionarySelectField,
  GridLayout,
  Radio,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { usePrintsActionsDataContext } from '@libs/domain/print/context';
import { MultiplePermissionRegenerateForm } from '@libs/domain/print/model';

import { RegeneratePermissionDialogLayout } from './common/RegeneratePermissionDialogLayout';

export function RegenerateMultiplePermissionDialogContent() {
  const [t] = useTranslation();
  const {
    actionData: { resourcesReassignmentsSelectOptions }
  } = usePrintsActionsDataContext() ?? {};

  const { setValue, getValues } = useFormV2Context();
  const [areAllNumberSelected, setAreAllNumberSelected] = useState(
    Boolean(getValues(typedNameV2<MultiplePermissionRegenerateForm>('areAllNumberSelected')))
  );

  const handleRadioClick = (value: boolean) => {
    setAreAllNumberSelected(value);
    setValue(typedNameV2<MultiplePermissionRegenerateForm>('areAllNumberSelected'), value);
  };

  return (
    <RegeneratePermissionDialogLayout
      formNumbersSection={
        <GridLayout itemProps={{ xs: 12 }}>
          <div className="flex flex-wrap">
            <Radio
              label={t('prints:formField.selectAllFormNumbers')}
              isChecked={areAllNumberSelected}
              onChange={() => handleRadioClick(true)}
            />
            <Radio
              label={t('prints:formField.selectSingleFormNumbers')}
              isChecked={!areAllNumberSelected}
              onChange={() => handleRadioClick(false)}
            />
          </div>

          {!areAllNumberSelected && (
            <AutocompleteSelectField
              name={typedNameV2<MultiplePermissionRegenerateForm>('selectedForms')}
              label={t('prints:field.detailsFields.formNumbersToRegenerate')}
              options={resourcesReassignmentsSelectOptions}
              validationMultipleSelect={{ required: [] }}
              getOptionSelected={option => {
                return Boolean(
                  getValues(typedNameV2<MultiplePermissionRegenerateForm>('selectedForms'))?.find(
                    value => value.name === option.name
                  )
                );
              }}
              isFilterSelectedOptions
              isMultiple
              isRequired
            />
          )}
        </GridLayout>
      }
      reprintSection={
        <DictionarySelectField
          name={typedNameV2<MultiplePermissionRegenerateForm>('reasonsForReprinting')}
          dictionaryName={DomainDictionaryEnum.REASONS_FOR_REPRINTING}
          label={t('prints:formField.rePrintReason')}
          validationSingleSelect={{ required: [] }}
          isRequired
        />
      }
    />
  );
}
