import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatusState, Status } from '@libs/common/v2/configs';
import { PromiseState } from '@libs/common/v2/utils';

import { PromiseMiddlewareConfig } from '..';

export interface DataFetchSliceState<T> {
  data: T;
  status: FetchStatusState;
}

const DataFetchSliceInitialState = {
  status: Status.INITIAL,
  data: null
};

const REQUESTED = 'Requested';
const FAILURE = 'Failure';

function promiseReducer<T extends PromiseState, W>(
  name: string,
  success: (state: T, action: PayloadAction<W>) => void
) {
  return {
    [name]: (state, action: PayloadAction<W>) => {
      state.status = Status.SUCCESS;
      success(state, action);
    },
    [`${name}${REQUESTED}`]: state => {
      state.status = Status.FETCHING;
    },
    [`${name}${FAILURE}`]: state => {
      state.status = Status.FAILURE;
    }
  };
}

const createActionType = (namespace: string, name: string) => ({
  name,
  type: `${namespace}/${name}`
});

export function createDataFetchSlice<T>(name: string, getResourceName = 'get', convertPayload = data => data) {
  const actionType = createActionType(name, getResourceName);
  return {
    slice: createSlice({
      name,
      initialState: DataFetchSliceInitialState,
      reducers: {
        ...promiseReducer<DataFetchSliceState<T>, T>(actionType.name, (state, action) => {
          state.data = convertPayload(action.payload) as T;
        })
      }
    }),
    actions: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      get: (payload, config?: PromiseMiddlewareConfig) => ({ type: actionType.type, payload, config }),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      put: payload => ({ type: actionType.type, payload }),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      post: payload => ({ type: actionType.type, payload })
    }
  };
}
