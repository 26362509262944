import React, { Context, useContext, useEffect, useState } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { DictionaryEntryDetailsLanguage } from '@avispon/dictionary';
import _ from 'lodash';

import {
  AutocompleteSelect,
  ComponentErrorBoundary,
  FieldTypeEnum,
  FormErrorType,
  FormV2Context,
  FormV2ContextState,
  getInputMode,
  InputMode,
  NUMBER_OF_AUTOCOMPLETE_RESULTS,
  SEARCH_TEXT_DEBOUNCE_TIME,
  SelectFieldValidation,
  SelectMultipleFieldValidation,
  SelectOption,
  useDebounceValue,
  useDictionaryQuery,
  useFieldValidationHandler,
  useOnScreen,
  Value
} from '@libs/common/v2';
import { getValue } from '@libs/common/v2/utils';

import { DictionaryEntryNameStatusEnum, DictionaryQuickChangeableEntryNameEnum } from '@libs/dictionary';

import { useSetInitialSelectFieldValue } from '../../hooks';

export interface IQuickChangeableDictionarySelectFieldProps {
  name: FieldPath<FieldValues>;
  dictionaryName: DictionaryQuickChangeableEntryNameEnum;
  optionsFilter?: (element: SelectOption) => boolean;
  label?: string;
  tooltip?: string;
  inputMode?: InputMode;
  formContext?: Context<FormV2ContextState>;
  className?: string;
  parentKey?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isMultiple?: boolean;
  isClearable?: boolean;
  hasSearchByExactValue?: boolean;
  additionalOptions?: SelectOption[];
  loadDictionaryOnStart?: boolean;
  validationSingleSelect?: SelectFieldValidation;
  validationMultipleSelect?: SelectMultipleFieldValidation;
  requiredIfNotEmpty?: boolean;
}

function QuickChangeableDictionarySelectField({
  name,
  label,
  tooltip,
  dictionaryName,
  optionsFilter = () => true,
  formContext = FormV2Context,
  parentKey,
  inputMode,
  additionalOptions = [],
  isRequired,
  isDisabled,
  isClearable = false,
  isMultiple,
  className,
  loadDictionaryOnStart = false,
  validationSingleSelect,
  validationMultipleSelect,
  requiredIfNotEmpty,
  hasSearchByExactValue
}: IQuickChangeableDictionarySelectFieldProps) {
  const { control, formMode, loading, watch, isSubmitting } = useContext<FormV2ContextState>(formContext);
  const mode = getInputMode(formMode, inputMode);
  const [inputText, setInputText] = useState<string>('');
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [inputFocused, setInputFocuded] = useState<boolean>(loadDictionaryOnStart);
  const [page, setPage] = useState<number>(0);
  const [isEndPage, setIsEndPage] = useState(false);
  const [isVisible, lastElementRef] = useOnScreen();

  useSetInitialSelectFieldValue(name, options);

  const watchValue = watch(name);

  const debouncedInputText = useDebounceValue(inputText, SEARCH_TEXT_DEBOUNCE_TIME);

  const searchWatchValue = hasSearchByExactValue ? { keys: [watchValue?.value] } : { keyFragment: watchValue?.value };
  const { isLoading, data } = useDictionaryQuery(
    {
      page,
      size: NUMBER_OF_AUTOCOMPLETE_RESULTS,
      language: DictionaryEntryDetailsLanguage.pl,
      status: DictionaryEntryNameStatusEnum.ALL,
      ...(watchValue?.value && !watchValue?.name && !debouncedInputText
        ? searchWatchValue
        : { valueFragment: [debouncedInputText.trim()] }),
      dictionary: dictionaryName,
      sort: [],
      ...(parentKey ? { parentKeys: [parentKey] } : {}),
      ...(debouncedInputText && debouncedInputText.trim() !== '' ? { sortBySimilarity: true } : {})
    },
    { enabled: inputFocused }
  );

  const isDataEmpty = _.isEmpty(data?.content);
  const isRequiredNonEmpty = requiredIfNotEmpty && !isDataEmpty;

  useEffect(() => {
    setPage(0);
  }, [debouncedInputText]);

  useEffect(() => {
    if (isVisible && !isLoading && !isEndPage && options.length >= NUMBER_OF_AUTOCOMPLETE_RESULTS) {
      setPage(page + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    if (page === 0 && isLoading) {
      setOptions([]);
    }
  }, [page, isLoading]);

  const getMultipleSelectValidation = () => {
    if (!requiredIfNotEmpty || isRequiredNonEmpty) {
      return validationMultipleSelect;
    }

    return {};
  };

  const getSingleSelectValidation = () => {
    if (!requiredIfNotEmpty || isRequiredNonEmpty) {
      return validationSingleSelect;
    }

    return {};
  };

  useFieldValidationHandler(
    isMultiple
      ? {
          fieldName: name,
          validation: getMultipleSelectValidation(),
          isMultiple: true,
          fieldType: FieldTypeEnum.AUTOCOMPLETE
        }
      : {
          fieldName: name,
          validation: getSingleSelectValidation(),
          isMultiple: false,
          fieldType: FieldTypeEnum.AUTOCOMPLETE
        }
  );

  const handleInputChange = (event: React.ChangeEvent<unknown>, value: string) => {
    if (event) {
      setInputText(value);
    }
  };

  useEffect(() => {
    if (!watchValue) {
      setInputText('');
    }
  }, [watchValue]);

  useEffect(() => {
    if (data) {
      let newOptions;

      if (page === 0) {
        newOptions = _.uniqBy<SelectOption>(
          [...data.content.map(entry => ({ value: entry.key, name: entry.value })), ...additionalOptions],
          'value'
        );

        setOptions(optionsFilter ? newOptions.filter(optionsFilter) : newOptions);
      } else {
        setOptions(oldOptions => {
          newOptions = _.uniqBy<SelectOption>(
            [
              ...oldOptions,
              ...data.content.map(entry => ({ value: entry.key, name: entry.value })),
              ...additionalOptions
            ],
            'value'
          );

          return optionsFilter ? newOptions.filter(optionsFilter) : newOptions;
        });
      }

      setIsEndPage(page + 1 > data?.totalPages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentKey, data, page]);

  return (
    <ComponentErrorBoundary componentName={label || name || 'QuickChangeableDictionarySelectField'}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, onBlur }, fieldState: { error } }) =>
          mode === InputMode.FORM ? (
            <AutocompleteSelect
              className={className}
              name={name}
              label={label}
              isLoading={isLoading}
              options={options}
              value={value || (isMultiple ? [] : '')}
              onChange={(_event, selectValue) => {
                onChange(selectValue);
              }}
              inputValue={inputText || value?.name || ''}
              onInputChange={handleInputChange}
              helperText={error?.message}
              onBlur={() => {
                onBlur();
                setInputFocuded(false);
              }}
              onFocus={() => setInputFocuded(true)}
              isError={!!error}
              isClearable={isClearable}
              isMultiple={isMultiple}
              isRequired={isRequiredNonEmpty || isRequired}
              isDisabled={isDisabled || isSubmitting}
              tooltip={tooltip}
              renderOption={(props, option) => {
                const isLast = options[options.length - 1] === option;
                return (
                  <li ref={isLast ? lastElementRef : null} {...props}>
                    {option.name}
                  </li>
                );
              }}
            />
          ) : (
            <Value
              label={label}
              isLoading={isLoading || loading}
              isError={!!error && error?.type !== FormErrorType.WARNING}
              isWarning={error?.type === FormErrorType.WARNING}
              helperText={error?.message}
            >
              {(!loading && value?.name) || value?.value || getValue(typeof value !== 'object' && (value as string))}
            </Value>
          )
        }
      />
    </ComponentErrorBoundary>
  );
}

export default QuickChangeableDictionarySelectField;
