/* istanbul ignore file */

import { Theme } from '@enigma/fe-ui';
import { alpha, PaletteOptions } from '@mui/material';

import { Shadows } from '@libs/common/v2';

declare module '@mui/material/styles' {
  interface Palette {
    indicators: Palette['primary'];
  }

  interface PaletteOptions {
    indicators?: PaletteOptions['primary'];
  }
}

const palette: PaletteOptions = {
  primary: {
    light: '#438cf2',
    main: '#1570EF',
    dark: '#1849A9',
    contrastText: '#fff'
  },
  secondary: {
    light: '#768b94',
    main: '#1849A9',
    dark: '#3a4d55',
    contrastText: '#fff'
  },
  error: {
    main: '#D92D20',
    light: '#e0574c',
    dark: '#971f16',
    contrastText: '#fff'
  },
  warning: {
    light: '#e38635',
    main: '#DC6803',
    dark: '#9a4802',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  info: {
    light: '#438cf2',
    main: '#1849A9',
    dark: '#1849A9',
    contrastText: '#fff'
  },
  success: {
    light: '#41c587',
    main: '#12B76A',
    dark: '#0c804a',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  text: {
    primary: '#344054',
    secondary: '#344054',
    disabled: 'rgba(0, 0, 0, 0.38)'
  },
  background: {
    default: '#fafafa',
    paper: '#fff'
  },
  common: {
    white: '#fff',
    black: '#000'
  },
  indicators: {
    light: '#438cf2',
    main: '#1849A9',
    dark: '#1849A9'
  },
  grey: {
    '50': '#F9FAFB',
    '100': '#F2F4F7',
    '200': '#EAECF0',
    '300': '#D0D5DD',
    '400': '#98A2B3',
    '500': '#667085',
    '600': '#475467',
    '700': '#344054',
    '800': '#1D2939',
    '900': '#101828'
  },
  divider: '#D0D5DD'
};

const shadowsList: Shadows = {
  xs: `0px 1px 2px ${alpha(palette?.grey?.[900] as string, 0.05)}`,
  sm: `0px 1px 3px ${alpha(palette?.grey?.[900] as string, 0.1)}, 0px 1px 2px ${alpha(
    palette?.grey?.[900] as string,
    0.06
  )}`,
  md: `0px 4px 8px -2px ${alpha(palette?.grey?.[900] as string, 0.1)}, 0px 2px 4px -2px ${alpha(
    palette?.grey?.[900] as string,
    0.06
  )}`,
  lg: `0px 12px 16px -4px ${alpha(palette?.grey?.[900] as string, 0.08)}, 0px 4px 6px -2px ${alpha(
    palette?.grey?.[900] as string,
    0.03
  )}`,
  xl: `0px 20px 24px -4px ${alpha(palette?.grey?.[900] as string, 0.08)}, 0px 8px 8px -4px ${alpha(
    palette?.grey?.[900] as string,
    0.03
  )}`,
  '2xl': `0px 24px 48px -12px ${alpha(palette?.grey?.[900] as string, 0.18)}`,
  '3xl': `0px 32px 64px -12px ${alpha(palette?.grey?.[900] as string, 0.14)}`,
  inputHover: `0px 1px 2px ${alpha(palette?.grey?.[900] as string, 0.05)}, 0px 0px 0px 4px ${palette?.grey?.[100]}`,
  inputFocus: `0px 1px 2px ${alpha(palette?.grey?.[900] as string, 0.05)}, 0px 0px 0px 4px #E0F2FE`,
  buttonFocus: `0px 1px 2px ${alpha(palette?.grey?.[900] as string, 0.05)}, 0px 0px 0px 4px #F4EBFF`,
  outlinedButtonFocus: `0px 1px 2px ${alpha(palette?.grey?.[900] as string, 0.05)}, 0px 0px 0px 4px #F2F4F7`,
  tableActions: `${alpha(palette?.grey?.[900] as string, 0.1)} -3px 0px 4px -1px;`,
  selectCell: `${alpha(palette?.grey?.[900] as string, 0.1)} 3px 0px 4px 1px`
};

export const GITDTheme: Theme = {
  typography: {
    fontSize: 14,
    htmlFontSize: 10
  },
  palette: { ...palette },
  shape: {
    borderRadius: 8
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: ({ theme }) => ({
          fontSize: `${theme.typography.pxToRem(18)} !important`
        }),
        fontSizeMedium: ({ theme }) => ({
          fontSize: `${theme.typography.pxToRem(20)} !important`
        }),
        fontSizeLarge: ({ theme }) => ({
          fontSize: `${theme.typography.pxToRem(36)} !important`
        })
      }
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          fontWeight: 500,
          textTransform: 'none',
          borderRadius: 8,
          boxShadow: shadowsList.xs,
          minWidth: '120px',
          border: `1px solid ${palette.grey[300]}`,
          '&.MuiButtonBase-root': {
            backgroundColor: theme.palette.common.white
          },
          '& .MuiTypography-root': {
            color: palette.grey[700],
            fontSize: '14px',
            fontWeight: 500
          },
          '&:focus': {
            boxShadow: shadowsList.buttonFocus
          },

          '& .loader': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            '& svg': {
              color: ownerState?.variant === 'contained' ? theme?.palette.common.white : theme?.palette.primary?.main,
              width: '100%',
              height: '100%'
            }
          }
        }),
        containedPrimary: ({ theme: { palette } }) => ({
          '&:hover': {
            backgroundColor: palette.primary.light,
            borderColor: palette.primary.light,
            boxShadow: 'none'
          },
          '&:focus': {
            backgroundColor: palette.primary.light,
            boxShadow: shadowsList.buttonFocus
          }
        }),
        outlinedPrimary: ({ theme: { palette } }) => ({
          border: `1px solid ${palette.grey[300]}`,
          color: palette.grey[700],
          backgroundColor: palette?.common?.white,
          '&:hover': {
            backgroundColor: palette.grey[50],
            borderColor: palette.grey[300]
          },
          '&:focus': {
            boxShadow: shadowsList.outlinedButtonFocus
          },
          '&.Mui-disabled': {
            color: palette.grey[300],
            borderColor: palette.grey[200]
          }
        }),
        textPrimary: ({ theme: { palette } }) => ({
          backgroundColor: 'unset',
          color: palette.grey[700],
          '&:hover, &:focus': {
            backgroundColor: palette.grey[300],
            borderColor: palette.grey[300]
          }
        })
      }
    },
    Dialog: {
      styleOverrides: {
        dialogActions: {
          styleOverrides: {
            root: ({ theme }) => ({
              gap: theme.spacing(2),
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            })
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: 'unset !important',
          wordWrap: 'break-word',
          whiteSpace: 'break-spaces',
          margin: `${theme.spacing(0.8)} 0`
        })
      },
      variants: [
        {
          // @ts-ignore
          props: { variant: 'error' },
          style: ({ theme }) => ({
            color: theme.palette.error.main
          })
        },
        {
          // @ts-ignore
          props: { variant: 'warn' },
          style: ({ theme }) => ({
            color: theme.palette.warning.main
          })
        },
        {
          // @ts-ignore
          props: { variant: 'standard' },
          style: ({ theme }) => ({
            color: theme.palette.grey[600]
          })
        }
      ]
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(0.5),
          marginLeft: theme.spacing(0.5),

          '&.Mui-checked': {
            color: theme.palette.indicators.dark
          },

          '& .MuiFormControlLabel-label': {
            marginLeft: theme.spacing(0.5)
          }
        })
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ theme }) => ({
          width: 36,
          height: 20,
          padding: 0,
          display: 'flex',
          marginRight: 14,
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(16px)'
          },
          '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: theme.palette.common.white,
              '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.indicators.main
              }
            },
            '&.Mui-disabled': {
              backgroundColor: theme.palette.grey[100],
              '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.grey[100]
              }
            }
          },
          '& .MuiSwitch-thumb': {
            boxShadow: shadowsList.sm,
            width: 16,
            height: 16,
            borderRadius: '50%'
          },
          '& .MuiSwitch-track': {
            borderRadius: theme.shape.borderRadius * 1.5,
            backgroundColor: theme.palette.grey[100]
          }
        })
      }
    },
    Section: {
      styleOverrides: {
        root: {
          width: '100%',
          height: 'auto',
          flexDirection: 'column',
          position: 'relative',
          border: 'none',
          backgroundColor: 'unset'
        },
        footer: {
          height: 56,
          paddingVertical: '16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }
      }
    },
    SectionHeader: {
      styleOverrides: {
        root: {
          position: 'relative'
        },
        mainContent: ({ theme }) => ({
          padding: `${theme.spacing(1)} 0`,
          paddingRight: theme.spacing(1.2),
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        })
      },
      variants: [
        {
          props: { variant: 'clean' },
          style: ({ theme }) => ({
            borderBottom: `1px solid ${theme.palette.divider}`
          })
        }
      ]
    },
    SectionContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          alignItems: 'center',
          display: 'flex',
          backgroundColor: theme?.palette?.common?.white,
          boxShadow: shadowsList.sm,
          borderRadius: theme?.shape?.borderRadius,
          margin: theme.spacing(0.25)
        })
      },
      variants: [
        {
          props: { variant: 'clean' },
          style: {
            boxShadow: 'none !important',
            borderRadius: '0 !important'
          }
        }
      ]
    },
    SectionTitle: {
      styleOverrides: {
        titleTypography: ({ theme }) => ({
          margin: `${theme.spacing(1.2)} 0 ${theme.spacing(1.2)} 0`,
          fontSize: '1.8rem',
          fontWeight: 500,
          color: theme.palette.grey[900]
        })
      }
    },
    PageLayout: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.default
        }),
        content: ({ theme }) => ({
          width: '100%',
          height: '100%',
          overflow: 'auto',
          padding: `0 ${theme.spacing(4)} ${theme.spacing(4)}`
        })
      }
    },
    PageLayoutHeader: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.default,
          padding: `${theme.spacing(4.5)} ${theme.spacing(5.5)}`
        }),
        breadcrumbs: ({ theme }) => ({
          height: `${theme.spacing(3)}`,
          fontSize: '1rem',
          lineHeight: theme.spacing(3),
          fontWeight: 600,
          color: theme.palette.grey[700]
        }),
        actionButtons: ({ theme }) => ({
          gap: `${theme.spacing(1)}`
        }),
        title: ({ theme }) => ({
          fontSize: '3rem',
          color: theme.palette.grey[700],
          lineHeight: '3.8rem',
          margin: `${theme.spacing(1)} 0 0`
        }),
        titleActionsWrapper: () => ({})
      }
    },
    Navigation: {
      variants: [
        {
          props: { variant: 'full' },
          style: ({ theme }) => ({
            width: theme.spacing(35),
            minWidth: theme.spacing(35)
          })
        },
        {
          props: { variant: 'folded' },
          style: ({ theme }) => ({
            width: theme.spacing(9),
            minWidth: theme.spacing(9)
          })
        }
      ]
    },
    NavigationListItem: {
      variants: [
        {
          props: { variant: 'full' },
          style: {
            width: '100%',
            borderRadius: 0
          }
        },
        {
          props: { variant: 'folded' },
          style: ({ theme }) => ({
            width: theme.spacing(7),
            borderRadius: '50%'
          })
        }
      ]
    },
    Tabs: {
      styleOverrides: {
        root: {
          minHeight: 0,
          height: '100%'
        },
        content: ({ theme }) => ({
          minHeight: 0,
          padding: `${theme.spacing(2)} ${theme.spacing(3)}`
        })
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginTop: '0px !important',
          borderColor: theme.palette.grey[300],
          backgroundColor: theme.palette.common.white,
          '& .MuiFormLabel-asterisk': {
            color: theme.palette.error.main
          },
          '& label.Mui-focused': {
            color: theme.palette.indicators.light
          },
          '& .MuiInputBase-root.Mui-focused': {
            outline: `4px solid #E0F2FE !important`
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: theme.palette.grey[300]
            },
            '&:hover fieldset': {
              borderWidth: 1,
              borderColor: theme.palette.grey[300],
              boxShadow: shadowsList.inputHover
            },
            '&.Mui-focused fieldset': {
              borderWidth: 1,
              borderColor: '#84caff',
              '&:hover': {
                borderWidth: 1,
                borderColor: '#84caff',
                boxShadow: 'none'
              }
            }
          }
        })
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          marginRight: '6px'
        }
      }
    },
    ControlWrapper: {
      variants: [
        {
          props: { variant: 'radio' },
          style: {
            '& .MuiButtonBase-root': {
              margin: 0,
              padding: 0
            }
          }
        },
        {
          props: { variant: 'checkbox' },
          style: {
            '& .MuiButtonBase-root': {
              margin: 0,
              padding: 0
            }
          }
        }
      ]
    },
    Table: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius,
          '& .resizer:hover': {
            '&::after': {
              backgroundColor: theme.palette.grey[300],
              width: 1
            }
          }
        })
      },
      variants: [
        {
          props: { variant: 'section' },
          style: {
            boxShadow: `${shadowsList.sm} !important`
          }
        },
        {
          props: { variant: 'full' },
          style: {
            boxShadow: 'none',
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0
          }
        }
      ]
    },
    TableHeader: {
      styleOverrides: {
        root: {
          '& .MuiStack-root': {
            gap: 12
          }
        }
      },
      variants: [
        {
          props: { variant: 'section' },
          style: ({ theme }) => ({
            padding: `${theme.spacing(3)} 0`
          })
        },
        {
          props: { variant: 'full' },
          style: ({ theme }) => ({
            padding: `${theme.spacing(3)} ${theme.spacing(4)}`
          })
        }
      ]
    },
    TableHeaderActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          gap: `${theme.spacing(0.5)}`
        }),
        wrapper: {
          alignItems: 'center'
        }
      }
    },
    TableHeadRow: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          fontSize: '1.2rem !important',
          fontWeight: 400,
          lineHeight: '1.8rem',

          '& .Mui-active': {
            fontWeight: 600,
            color: `${theme.palette.indicators.dark} !important`,
            '& .MuiTableSortLabel-icon': {
              color: `inherit !important`
            }
          },
          '& .MuiTableSortLabel-root': {
            '& svg': {
              color: `${theme.palette.indicators.dark} !important`
            }
          },
          '& .MuiInputBase-root': {
            paddingRight: 2,
            fontSize: '1.2rem',
            '& svg': {
              width: 14,
              height: 14
            }
          }
        })
      },
      variants: [
        {
          props: { variant: 'section' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.common.white
          })
        },
        {
          props: { variant: 'full' },
          style: {
            backgroundColor: 'transparent'
          }
        }
      ]
    },
    TableHeadCell: {
      styleOverrides: {
        actions: {
          boxShadow: shadowsList.tableActions
        },
        resizer: {
          width: '1px',
          '&:after': {
            width: '1px'
          }
        },
        root: {
          fontSize: '1.2rem !important',
          fontWeight: '400 !important',
          lineHeight: '1.8rem !important',
          letterSpacing: '0.5px !important',
          '& svg': {
            width: 16,
            height: 16
          }
        }
      },
      variants: [
        {
          props: { variant: 'section' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.common.white
          })
        },
        {
          props: { variant: 'full' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.grey[50]
          })
        }
      ]
    },
    TableActionsHeadCell: {
      styleOverrides: {
        root: {
          boxShadow: shadowsList.tableActions
        }
      },
      variants: [
        {
          props: { variant: 'section' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.common.white
          })
        },
        {
          props: { variant: 'full' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.grey[50]
          })
        }
      ]
    },
    TableFilterPanel: {
      styleOverrides: {
        root: {
          boxShadow: shadowsList.tableActions
        },
        headFilterCell: ({ theme }) => ({ ...theme.typography.body1 }),
        selectionCell: ({ theme }) => ({
          ...theme.typography.body1,
          boxShadow: shadowsList.selectCell
        }),
        actionsCell: {
          backgroundColor: '#F9FAFB'
        }
      },
      variants: [
        {
          props: { variant: 'section' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.grey[50]
          })
        },
        {
          props: { variant: 'full' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.common.white
          })
        }
      ]
    },
    MuiTableCell: {
      variants: [
        {
          // @ts-ignore
          props: { variant: 'section' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.common.white
          })
        },
        {
          // @ts-ignore
          props: { variant: 'full' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.grey[50]
          })
        }
      ],
      styleOverrides: {
        body: ({ theme }) => ({
          padding: `${theme.spacing(0)} ${theme.spacing(1.25)}`,
          letterSpacing: '0.9px !important'
        }),
        head: ({ theme }) => ({
          padding: `${theme.spacing(1.25)} ${theme.spacing(1.25)}`
        })
      }
    },
    TableRow: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginLeft: 32,
          '&.row-clickable:hover .MuiTableCell-root': {
            backgroundColor: theme.palette.grey[50],
            cursor: 'pointer'
          },
          '&.row-selected': {
            backgroundColor: '#fff'
          }
        }),
        actionsCell: ({ theme }) => ({
          boxShadow: shadowsList.tableActions,
          padding: `${theme.spacing(0.25)} ${theme.spacing(2)} !important`
        })
      },
      variants: [
        {
          props: { variant: 'section' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.common.white
          })
        },
        {
          props: { variant: 'full' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.grey[50]
          })
        }
      ]
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: 52,
          '&:last-of-type > div:not(.MuiTableCell-head)': {
            borderBottom: 'none'
          }
        }
      }
    },
    TableHeadFilterPanel: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderBottom: `1px solid ${theme.palette.grey[200]} !important`,
          input: {
            '&::placeholder': {
              fontSize: '1.2rem'
            },
            height: 17
          },
          span: {
            display: 'flex',
            alignItems: 'center'
          },
          '& .MuiFormControl': {
            marginTop: 0
          },
          '& div[class*="DateRangePicker-wrapper"]': {
            gap: 6
          }
        })
      },
      variants: [
        {
          props: { variant: 'section' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.grey[50]
          })
        },
        {
          props: { variant: 'full' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.common.white
          })
        }
      ]
    },
    TablePagination: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderBottomRightRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
          '& .MuiTablePagination-displayedRows > div': {
            alignItems: 'center'
          },
          '& div[class*="root-MuiTablePagination-select"]': {
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: theme.shape.borderRadius,
            '&:hover': {
              borderWidth: 1,
              borderColor: theme.palette.grey[300],
              boxShadow: shadowsList.inputHover
            },
            '&.Mui-focused': {
              borderWidth: 1,
              outline: `4px solid #E0F2FE !important`,
              borderColor: '#84caff',
              '&:hover': {
                borderWidth: 1,
                borderColor: '#84caff',
                boxShadow: shadowsList.inputHover
              }
            }
          },
          '& input': {
            border: 'none !important'
          },
          '& .MuiInputBase-root': {
            backgroundColor: theme.palette.common.white,
            height: 35
          }
        })
      },
      variants: [
        {
          props: { variant: 'section' },
          style: ({ theme }) => ({
            boxShadow: shadowsList.sm,
            backgroundColor: theme.palette.common.white
          })
        },
        {
          props: { variant: 'full' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.grey[50],
            boxShadow: 'none',
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0
          })
        }
      ]
    },
    MuiTable: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          '& div[class*="MuiTableCell-root-Table-emptyState"]': {
            transform: 'translate(-50%, 0%)',
            padding: 48,
            top: 'auto'
          }
        })
      }
    },
    TableRowSelectionPanel: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.grey[50],
          '& .MuiStack-root  .MuiTypography-root': {
            fontWeight: 500,
            color: theme.palette.grey[700]
          }
        }),
        selectedRowsCountNumber: ({ theme }) => ({
          color: `${theme.palette.text.primary}`,
          fontSize: '1.4rem',
          fontWeight: 500
        }),
        selectedRowsCount: ({ theme }) => ({
          color: `${theme.palette.text.primary}`,
          fontSize: '1.4rem',
          fontWeight: 500
        })
      }
    },
    TableHeadSelect: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          boxShadow: shadowsList.selectCell
        })
      }
    },
    TableRowSelect: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          boxShadow: shadowsList.selectCell
        })
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiMenuItem-root': {
            paddingRight: 30,
            '&:hover': {
              backgroundColor: theme.palette.grey[200]
            }
          },
          '& .MuiButtonBase-root': {
            minWidth: 'unset',
            '& .MuiTypography-root': {
              color: theme.palette.grey[700],
              fontSize: '1.4rem',
              fontWeight: 500
            }
          }
        })
      }
    },
    InputContainer: {
      styleOverrides: {
        inputLabelText: ({ theme }) => ({
          fontSize: '1.2rem',
          fontWeight: 500,
          color: theme.palette.grey[700],
          lineHeight: '2rem'
        })
      }
    },
    DropdownButton: {
      styleOverrides: {
        listButton: {
          '& .MuiBox-root': {
            left: '85%'
          }
        }
      }
    }
  }
};
