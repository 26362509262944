import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FolderDetails } from '@ibtm/domain';

import { SelectOption, TableLayout, typedNameV2, useFormV2Context } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import {
  checkIsPartnership,
  FolderDetailsClient,
  FolderDetailsParams,
  useSubjectHistoryTable
} from '@libs/domain/folder';

import { useDomainConfigContext } from '../../../../../../../config';

interface IProps {
  isCollapsable?: boolean;
}

function HistoricalDataSection({ isCollapsable }: IProps) {
  const { isClientPortal } = useDomainConfigContext();
  const { folderId } = useParams<FolderDetailsParams>();
  const [t] = useTranslation();
  const { watch } = useFormV2Context();

  const legalFormKey = watch(typedNameV2<FolderDetailsClient>('subject.legalFormKey')) as SelectOption<string>;
  const isPartnership = checkIsPartnership(legalFormKey.value);
  const subjectId = watch(typedNameV2<FolderDetails>('subject.id')) as string;

  const tableProps = useSubjectHistoryTable({
    folderId,
    subjectId,
    legalFormKey,
    visibleColumns: [
      'name',
      'nip',
      'legalFormKey',
      'applicationNumber',
      'contactAddresses',
      isPartnership ? 'partnershipPartners' : null,
      'created'
    ]
  });

  const apiRequest = useCallback(
    params => {
      return isClientPortal
        ? API.client.subject.getSubjectHistoryPage('', '', params)
        : API.subject.getSubjectHistoryPage(subjectId, params);
    },
    [isClientPortal, subjectId]
  );

  return (
    <div>
      <TableLayout
        {...tableProps}
        pageTitle={t('folder:details.section.historicalData')}
        isSection
        xlsxDownload={{
          fileName: t('folder:details.section.historicalData'),
          pathToXLSXTranslation: 'folder:details.subjectHistory',
          apiRequest: params => apiRequest(params)
        }}
        isActionColumnEnabled={false}
        isCollapsable={isCollapsable}
        isFilterEnabled={false}
      />
    </div>
  );
}

export default HistoricalDataSection;
