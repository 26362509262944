import { ReactNode, useMemo, useState } from 'react';

import RouterContext, { IRoute } from './RouterContext';

interface IProps {
  children: ReactNode;
  routes: IRoute[];
}

function RouterContextProvider({ children, routes: allRoutes }: IProps) {
  const [hiddenRoutesPaths, setHiddenRoutesPaths] = useState([]);

  const routes = useMemo(
    () => allRoutes.filter(route => !hiddenRoutesPaths?.includes(route?.path)),
    [allRoutes, hiddenRoutesPaths]
  );

  const values = useMemo(() => ({ routes, hiddenRoutesPaths, setHiddenRoutesPaths }), [hiddenRoutesPaths, routes]);

  return <RouterContext.Provider value={values}>{children}</RouterContext.Provider>;
}

export default RouterContextProvider;
