import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { first, isEqual, isNil, isString } from 'lodash';

import { DropdownListButton, useTableAdapter } from '@libs/common/v2';

import { DomainDictionaryEntry } from '../../config';
import { usePermitDialog } from '../hooks';
import { ReleasedPermitsSnapshot } from '../model';

function ForeignPermissionSelectColumnButton(selectedRowsIds: string[]) {
  const [t] = useTranslation();
  const { selectedFlatRows } = useTableAdapter();
  const [tooltip, setTooltip] = useState<string>(null);
  const { openSettleEKMTPermitDialog, openSettleSinglePermitDialog } = usePermitDialog();
  const [selectedPermits, setSelectedPermits] = useState<ReleasedPermitsSnapshot[]>(null);

  const isEkmtPermit = useMemo(
    () =>
      (first(selectedFlatRows)?.original as ReleasedPermitsSnapshot)?.typeKey ===
      DomainDictionaryEntry.FOREIGN_PERMIT_TYPE.EKMT,
    [selectedFlatRows]
  );

  const folderIds = useMemo(
    () => (selectedPermits ? selectedPermits.map(permit => permit?.folder?.id) : []),
    [selectedPermits]
  );

  const isOneSinglePermitSelected = useMemo(() => selectedPermits?.length === 1, [selectedPermits]);

  const firstFolderId = useMemo(() => first(folderIds), [folderIds]);

  const areSameFolderIds = useMemo(() => {
    return folderIds.every(folderId => isString(folderId) && isEqual(folderId, firstFolderId));
  }, [folderIds, firstFolderId]);

  const isFolderIdNull = useMemo(() => {
    return folderIds.some(folderId => isNil(folderId));
  }, [folderIds]);

  const isButtonDisabled: boolean = useMemo(() => {
    const permitsTypeKey = selectedFlatRows?.map(
      ({ original }: Row<ReleasedPermitsSnapshot>) => original?.typeKey ?? []
    );
    const permitsSettled = selectedFlatRows?.map(({ original }: Row<ReleasedPermitsSnapshot>) => original?.isSettled);

    const formStateKeyReturned = selectedFlatRows?.map(
      ({ original }: Row<ReleasedPermitsSnapshot>) =>
        original?.form?.stateKey === DomainDictionaryEntry.RESOURCE_FORM_STATE.RETURNED
    );

    if (isFolderIdNull && !isOneSinglePermitSelected && !areSameFolderIds) {
      setTooltip(t('foreignPermits:settlementDisposablePermits.tooltip.missingFolderNumberToSettle'));
      return true;
    }

    if (permitsSettled.some(Boolean)) {
      setTooltip(t('foreignPermits:settlementDisposablePermits.tooltip.settlementBeforeDisabled'));
      return true;
    }

    if (formStateKeyReturned.some(Boolean)) {
      setTooltip(t('foreignPermits:settlementDisposablePermits.tooltip.settlementReturned'));
      return true;
    }

    if (!areSameFolderIds && !isOneSinglePermitSelected) {
      setTooltip(t('foreignPermits:settlementDisposablePermits.tooltip.settlementsInTheSameSubjectOnlyAvailable'));
      return true;
    }

    if (
      permitsTypeKey.includes(DomainDictionaryEntry.FOREIGN_PERMIT_TYPE.SINGLE) &&
      permitsTypeKey.includes(DomainDictionaryEntry.FOREIGN_PERMIT_TYPE.EKMT)
    ) {
      setTooltip(t('foreignPermits:settlementDisposablePermits.tooltip.manySettlementsDisabled'));
      return true;
    }

    return false;
  }, [selectedFlatRows, t, areSameFolderIds, isFolderIdNull, isOneSinglePermitSelected]);

  useEffect(() => {
    if (selectedFlatRows?.length) {
      const selectedRows = selectedFlatRows?.map(({ original }) => original);
      setSelectedPermits(selectedRows);
    }
  }, [selectedFlatRows]);

  return (
    <DropdownListButton
      label={t('foreignPermits:other.settlement')}
      onClick={() =>
        isEkmtPermit
          ? openSettleEKMTPermitDialog({ selectedPermitIds: selectedRowsIds, selectedPermits })
          : openSettleSinglePermitDialog({ selectedPermitIds: selectedRowsIds, selectedPermits })
      }
      tooltipTitle={isButtonDisabled ? tooltip : null}
      isDisabled={isButtonDisabled}
    />
  );
}

export default ForeignPermissionSelectColumnButton;
