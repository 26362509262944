import { forceArray } from '@libs/common/v2/utils';

import {
  DictionaryEntriesMap,
  DictionaryEntriesState,
  DictionaryEntry,
  DictionaryEntryNameEnum,
  useContextSelector
} from '@libs/dictionary';

const selectDictionary = (dictionaryState: DictionaryEntriesMap, dictionaryType: string): DictionaryEntry =>
  dictionaryState?.[dictionaryType] ?? { entries: [], translations: {} };

function useDictionary(): DictionaryEntriesState['data'];
function useDictionary(dictionaryType: DictionaryEntryNameEnum): DictionaryEntry;
function useDictionary(dictionaryType: DictionaryEntryNameEnum[]): Partial<DictionaryEntriesState['data']>;

function useDictionary(dictionaryType?: DictionaryEntryNameEnum | DictionaryEntryNameEnum[]) {
  const dictionaryState = useContextSelector(({ context }) => context.context.data.dictionary);
  const dictionaryTypes = forceArray(dictionaryType).filter(Boolean);

  if (!dictionaryType) {
    return dictionaryState || {};
  }

  if (Array.isArray(dictionaryType)) {
    return dictionaryTypes.reduce(
      (acc, type) => ({ ...acc, [type]: selectDictionary(dictionaryState, type) }),
      {} as Partial<DictionaryEntriesState['data']>
    );
  }
  return selectDictionary(dictionaryState, dictionaryType);
}

export default useDictionary;
