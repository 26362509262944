export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  generatePermissions: { translationKey: 'applications:api.generatePermissions' },
  transferResourceAssignmentResourceObject: { translationKey: 'prints:api.transferResourceAssignmentResourceObject' },
  generateResourceAssignment: { translationKey: 'prints:api.generateResourceAssignment' },
  regenerateResourceAssignment: { translationKey: 'prints:api.regenerateResourceAssignment' },
  setSigner: { translationKey: 'prints:api.setSigner' },
  previewLastGenerateResourceAssignment: { translationKey: 'prints:api.previewLastGenerateResourceAssignment' },
  getUnitSigner: { translationKey: 'prints:api.getUnitSigner' },
  getTravelFormPrintsSnapshots: {
    translationKey: 'foreignPermits:api.getTravelFormPrintsSnapshots'
  },
  getForeignPermissionPrintsSnapshotList: {
    translationKey: 'foreignPermits:api.getForeignPermissionPrintsSnapshotList'
  },
  getEkmtCarnetPrintsSnapshotList: { translationKey: 'foreignPermits:api.getEkmtCarnetPrintsSnapshotList' }
};
