import { FolderDetailsTabEnum } from '@libs/domain/folder';

import DomainDictionaryEntry from '../../DomainDictionaryEntry';
import { PermissionEnum } from '../PermissionEnum';

const { FOLDER_TYPE } = DomainDictionaryEntry;

export type PermissionConfig = {
  view?: Array<PermissionEnum>;
  edit?: Array<PermissionEnum>;
  create?: Array<PermissionEnum>;
};

type PermissionsConfig<T extends string = string> = { [key in T]?: PermissionConfig };

export const folderRoutesPermissionConfig: PermissionsConfig = {
  [FOLDER_TYPE.TP]: {
    view: [PermissionEnum.IBTM_DOMAIN_FOLDER_TP_DETAILS_VIEW],
    edit: [PermissionEnum.IBTM_DOMAIN_FOLDER_TP_UPDATE]
  }
};

export const folderTabPermissionConfig: PermissionsConfig<FolderDetailsTabEnum> = {
  [FolderDetailsTabEnum.SUBJECT]: {
    view: [PermissionEnum.IBTM_DOMAIN_FOLDER_SUBJECT_VIEW]
  }
};
