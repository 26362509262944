import { MouseEvent, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';

import { ITableAdapter, ITableContext, TableButton, TableIconButton, TAction } from '@libs/common/v2';

interface IProps<P extends Record<string, any>, D extends Record<string, any> = P> {
  action: TAction<P, D>;
  row: Row<P>;
  table: { tableInstance: ITableAdapter<P, D>; tableContext: ITableContext<P, D> };
}

function RenderTableActionButton<P extends Record<string, any>, D extends Record<string, any>>({
  action: { label, isDisabledLabel, onClick, icon, isDisabled, isHidden, isLoading, tooltip, link },
  row,
  table: { tableInstance, tableContext }
}: IProps<P, D>) {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const buttonRef = useRef();

  const handleOnClick = async (event: MouseEvent) => {
    setLoading(true);
    await onClick?.({ event, row, tableContext, tableInstance });
    if (buttonRef?.current) {
      setLoading(false);
    }
  };
  const shouldRender = useMemo(() => {
    return !(typeof isHidden === 'function' ? isHidden({ row, tableContext, tableInstance }) : isHidden);
  }, [isHidden, row, tableContext, tableInstance]);

  const isDisabledFunction = typeof isDisabled === 'function';
  const isDisabledLabelFunction = typeof isDisabledLabel !== 'function';

  const getTooltipTitle = () => {
    if (isDisabledFunction ? isDisabled({ row, tableContext, tableInstance }) : isDisabled) {
      return isDisabledLabelFunction ? t(isDisabledLabel) : t(isDisabledLabel?.(row));
    }

    return typeof label !== 'function' ? t(label) : t(label?.(row));
  };

  if (icon && shouldRender) {
    return (
      <TableIconButton
        ref={buttonRef}
        icon={icon}
        isButtonLoading={loading || isLoading}
        isDisabled={loading || (isDisabledFunction ? isDisabled({ row, tableContext, tableInstance }) : isDisabled)}
        tooltipTitle={getTooltipTitle()}
        onClick={handleOnClick}
        link={typeof link !== 'function' ? link : link?.(row)}
      />
    );
  }

  if (shouldRender) {
    return (
      <TableButton
        ref={buttonRef}
        isButtonLoading={loading || isLoading}
        isDisabled={loading || (isDisabledFunction ? isDisabled({ row, tableContext, tableInstance }) : isDisabled)}
        label={typeof label !== 'function' ? t(label) : t(label?.(row))}
        tooltipTitle={
          ((isDisabledFunction ? isDisabled({ row, tableContext, tableInstance }) : isDisabled) &&
            (isDisabledLabelFunction ? t(isDisabledLabel) : t(isDisabledLabel?.(row)))) ||
          (typeof tooltip !== 'function' ? t(tooltip) : t(tooltip?.(row)))
        }
        onClick={handleOnClick}
        link={typeof link !== 'function' ? link : link?.(row)}
      />
    );
  }

  return null;
}

export default RenderTableActionButton;
