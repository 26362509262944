import { QueryConfig, useQuery } from 'react-query';
import { FolderDetails } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';
import { FolderQueryKeysEnum } from '..';

export const getFolder = async (_, id: string) => {
  const { data } = await API.folder.getFolderDetails(id, {
    ...endpointsConfig.getFolderDetails
  });
  return data;
};

export default function useFolderDetailsQuery(
  id: string,
  queryConfig: QueryConfig<any, unknown> = {},
  queryKey: string = FolderQueryKeysEnum.FOLDER
) {
  return useQuery<FolderDetails>([queryKey, id], getFolder, queryConfig);
}
