import { useTableLayoutProps } from '@libs/common/v2';

import { useAssociationSubjectQuery } from '../api';

import useAssociationSubjectColumns from './useAssociationSubjectColumns';

function useAssociationSubjectTable(id: string) {
  const { columns, mappedFilterFields, mappedSortFields } = useAssociationSubjectColumns();

  return useTableLayoutProps({
    tableHookQuery: useAssociationSubjectQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    },
    tableHookQueryInitialParams: {
      associationId: id,
      resigned: false
    }
  });
}

export default useAssociationSubjectTable;
