import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { TableIconButton, useConfirmDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { SubjectQueryKeysEnum } from '../../subject/api';
import { useExpireSubjectMutation } from '../api';

interface IProps {
  subjectId: string;
  isDisabled?: boolean;
}

function ApplicationEntrepreneurExpireButton({ subjectId, isDisabled }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: expire } = useExpireSubjectMutation();
  const [confirm] = useConfirmDialog();

  const expireHandler = () => {
    confirm({
      title: t('applications:dialog.expireEntrepreneurDialogTitle'),
      shortTitle: t('applications:dialog.expireEntrepreneurDialogShortTitle'),
      message: t('applications:message.confirmExpireEntrepreneur'),
      cancelText: t('action.cancel'),
      confirmText: t('action.expire'),
      confirmType: 'danger',
      onConfirm: (setLoading, closeDialog) => {
        setLoading(true);
        expire(subjectId, {
          onSuccess: () => {
            showSuccessSnackbar(t('applications:message.expireEntrepreneurSuccess'));
            queryCache.invalidateQueries([SubjectQueryKeysEnum.SUBJECT_EXTENDED]);
          },
          onSettled: () => {
            setLoading(false);
            closeDialog();
          }
        });
      }
    });
  };
  return (
    <TableIconButton
      icon="ExpireIcon"
      tooltipTitle={isDisabled ? t('applications:action.expiryUnavailable') : t('action.expire')}
      onClick={expireHandler}
      isDisabled={isDisabled}
    />
  );
}

export default ApplicationEntrepreneurExpireButton;
