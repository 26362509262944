import { object as YupObject, string as YupString } from 'yup';

import { DomainDictionaryEntry } from '@libs/domain/config';

function useReciptTypeValidationSchema() {
  const validationSchema = () =>
    YupObject({
      receiptTypeKey: YupObject().required().nullable(),
      sendToAddress: YupObject()
        .nullable()
        .when('receiptTypeKey', (receiptTypeKey, schema) => {
          if (
            ![DomainDictionaryEntry.RECEIPT_TYPE.PICKUP_IN_PERSON, DomainDictionaryEntry.RECEIPT_TYPE.EPUAP].includes(
              receiptTypeKey?.value
            )
          ) {
            return schema.required();
          }
          return schema;
        }),
      recipientName: YupString().nullable(),
      recipientSurname: YupString().nullable()
    });

  return { validationSchema };
}

export default useReciptTypeValidationSchema;
