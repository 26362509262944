import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function exportFile(year: number) {
  const data = API.civicBoard.exportFileForEkmtCommittee(year, {
    ...endpointsConfig.exportFileForEkmtCommittee
  });
  return data;
}

export default function useExportFileForEkmtCommitteeMutation() {
  return useMutation(exportFile);
}
