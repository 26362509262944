import React from 'react';
import { actions, Hooks } from 'react-table';

// Actions
actions.openFiltersPanel = 'openFiltersPanel';
actions.closeFiltersPanel = 'closeFiltersPanel';
actions.clearFilters = 'clearFilters';
actions.resetSelectedRows = 'resetSelectedRows';

// eslint-disable-next-line consistent-return
function reducer(state, action, prevState) {
  switch (action.type) {
    case actions.closeFiltersPanel:
      return { ...state, filtersPanelOpen: false };
    case actions.openFiltersPanel:
      return { ...state, filtersPanelOpen: true };
    case actions.resetSelectedRows:
      return { ...state, selectedRowIds: prevState.selectedRowIds };
    case actions.clearFilters:
      return { ...state, filters: [] };
    case actions.init:
      return { filtersPanelOpen: true, ...state };
  }
}

function useInstance(instance) {
  const { dispatch } = instance;

  const openFiltersPanel = React.useCallback(() => dispatch({ type: actions.openFiltersPanel }), [dispatch]);
  const closeFiltersPanel = React.useCallback(() => dispatch({ type: actions.closeFiltersPanel }), [dispatch]);
  const clearFilters = React.useCallback(() => dispatch({ type: actions.clearFilters }), [dispatch]);

  Object.assign(instance, {
    openFiltersPanel,
    closeFiltersPanel,
    clearFilters
  });
}

export const useFiltersPanel = <T extends Record<string, unknown> = Record<string, unknown>>(hooks: Hooks<T>) => {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
};

useFiltersPanel.pluginName = 'useFiltersPanel';

export default useFiltersPanel;
