import { MutateConfig } from 'react-query';
import { CivicBoardDetails } from '@ibtm/domain';
import { AxiosError } from 'axios';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';
import { UpdateCivicBoardPayload } from '@libs/domain/social-commission';

import { endpointsConfig } from '../endpoints-config';

async function updateMutation(formData: UpdateCivicBoardPayload) {
  const { data } = await API.civicBoard.updateCivicBoard(formData.civicBoardId, formData, {
    ...endpointsConfig.updateCivicBoard
  });
  return data;
}

function useUpdateCivicBoardMutation(config: MutateConfig<CivicBoardDetails, AxiosError>) {
  return useMutation(updateMutation, config);
}

export default useUpdateCivicBoardMutation;
