import { useTranslation } from 'react-i18next';

import { TableButton } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

import { useGenerateApplicationDocumentMutation } from '@libs/domain/application';
import { DomainDictionaryEntry } from '@libs/domain/config';
import { DocumentsQueryKeysEnum, useGenerateApplicationDocumentDialog } from '@libs/domain/documents';

interface IProps {
  applicationId: string;
  onClick?: () => void;
  actionKey?: UIElementNameEnum;
}

function GenerateDocumentButton({ applicationId, onClick, actionKey }: IProps) {
  const [t] = useTranslation();

  const { openGenerateDocumentDialog } = useGenerateApplicationDocumentDialog({
    generateDocumentMutation: useGenerateApplicationDocumentMutation,
    apiParams: { applicationId },
    listQueryKey: DocumentsQueryKeysEnum.DOCUMENTS_LIST
  });

  return (
    <TableButton
      label={t('pricing:moneyTransfers.action.generate.buttonTitle')}
      actionKey={actionKey}
      onClick={() => {
        openGenerateDocumentDialog({
          groupKey: DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.ISSUE_DOCUMENT,
          includeWithKeys: true
        });
        onClick?.();
      }}
    />
  );
}

export default GenerateDocumentButton;
