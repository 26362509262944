import { useMutation } from 'react-query';
import { ApplicationDocumentCreateRequest } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export interface IApplicationDocumentCreateParams {
  applicationId?: string;
  receiptType?: string;
  data?: ApplicationDocumentCreateRequest;
}

function generateDocument({ applicationId, receiptType, data }: IApplicationDocumentCreateParams) {
  return API.application.createApplicationDocument(
    applicationId,
    {
      ...data,
      ...(receiptType ? { receiptTypeKey: receiptType } : {})
    },
    { ...endpointsConfig.createApplicationDocument }
  );
}

function useGenerateApplicationDocumentMutation() {
  return useMutation(generateDocument);
}

export default useGenerateApplicationDocumentMutation;
