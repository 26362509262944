import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnvironmentNameEnum, useAppConfig } from '@libs/app-config';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Icon, Theme } from '@libs/common/v2';

const useEnvironmentWarningBanner = () => {
  const [t] = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const {
    raw: { env }
  } = useAppConfig();

  const styles = useStyles();

  useEffect(() => {
    setIsVisible(env?.name === EnvironmentNameEnum.DEVELOPMENT);
  }, [env]);

  return {
    EnvironmentWarningBannerComponent: isVisible ? (
      <div className={clsx(styles.container, 'shadow-sm')}>
        <p className={styles.text}>{t('message.devEnvironment')}</p>
        <span className={styles.closeIcon} onClick={() => setIsVisible(false)} aria-hidden="true">
          <Icon icon="CrossIcon" />
        </span>
      </div>
    ) : null,
    isWarningVisible: isVisible
  };
};

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    backgroundColor: theme.palette.red[500],
    width: '100%',
    display: 'flex',
    placeContent: 'space-between',
    padding: '0.25rem 0.5rem',
    zIndex: 999
  },
  text: {
    color: 'black',
    fontWeight: 'bold'
  },
  closeIcon: {
    marginRight: '0.75rem',
    color: 'black',
    cursor: 'pointer'
  }
}));

export { useEnvironmentWarningBanner };
