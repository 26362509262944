import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { yupResolver } from '@hookform/resolvers/yup';
import { FolderDetails, FolderSearchFilter } from '@ibtm/domain';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';

import {
  Button,
  Dialog,
  FormV2Context,
  ForwardTableState,
  GridItem,
  GridLayout,
  TextInputField,
  typedNameV2,
  useForwardedSelectedRowsAction
} from '@libs/common/v2';

import { EntrepreneurDetailsSnapshot } from '@libs/domain/folder';

import SearchEntrepreneursTable from './addEntrepreneur/SearchEntrepreneursTable';

interface Props {
  open: boolean;
  setIsOpen: (param: boolean) => void;
  addEntrepreneur: (data: FolderDetails[]) => Promise<void>;
  folderType: string;
  entrepreneurs: EntrepreneurDetailsSnapshot[];
  createMode?: boolean;
}

const initialValues: FolderSearchFilter = {
  subjectNameContains: '',
  subjectNipContains: null
};

function SearchFolderModal({ addEntrepreneur, entrepreneurs, folderType, open, setIsOpen, createMode }: Props) {
  const [t] = useTranslation();

  const [searchParams, setSearchParams] = useState<FolderSearchFilter>({ ...initialValues, typeKeyIn: [folderType] });
  const [showTable, setShowTable] = useState<boolean>(false);
  const [tableKey, setTableKey] = useState<number>(0);

  const getLabel = (name: string) => t<any>(`folder:details.field.${name}`);
  const classes = useStyles();

  const validationSchema = Yup.object({
    subjectNameContains: Yup.string().oneOfRequired(
      'subjectNameContains',
      'subjectNipContains',
      'folder:details.field'
    ),
    subjectNipContains: Yup.string()
      .nullable()
      .oneOfRequired('subjectNipContains', 'subjectNameContains', 'folder:details.field')
      .test(
        'len',
        t('validation:fieldLength', { length: 10 }),
        (value: number) => !value || value.toString().length === 10
      )
  });

  const { forwardTableStateRef, getSelectedIds, handleDisableAction, isActionDisabled } =
    useForwardedSelectedRowsAction();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    reset,
    getValues,
    trigger,
    unregister
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });

  const nip = watch(typedNameV2<FolderSearchFilter>('subjectNipContains')) as string;
  const name = watch(typedNameV2<FolderSearchFilter>('subjectNameContains')) as string;

  useEffect(() => {
    if (nip) {
      trigger(typedNameV2<FolderSearchFilter>('subjectNameContains'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nip]);

  useEffect(() => {
    if (name) {
      trigger(typedNameV2<FolderSearchFilter>('subjectNipContains'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const onSubmit = values => {
    setSearchParams(values);
    setShowTable(true);
    setTableKey(prevKey => prevKey + 1);
  };

  const handleClose = () => {
    setIsOpen(false);
    setShowTable(false);
    reset(initialValues);
    setIsOpen(false);
    setSearchParams({ ...initialValues, typeKeyIn: [folderType] });
  };

  const handleAddEntrepreneur = () => {
    const selectedRows = (getSelectedIds(true) as Row[]).map(i => i.original);
    const entrepreneursToAdd = selectedRows.filter(i => !entrepreneurs.map(e => e.customId).includes(i.id));
    reset(initialValues);
    setSearchParams({ ...initialValues, typeKeyIn: [folderType] });
    setShowTable(false);
    return addEntrepreneur(entrepreneursToAdd);
  };

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <FormV2Context.Provider value={values}>
      <Dialog
        title={t('folder:details.section.partnershipEntrepreneur')}
        confirmText={t('action.add')}
        cancelText={t('action.cancel')}
        onCancel={handleClose}
        onConfirm={handleSubmit(handleAddEntrepreneur)}
        dialogSize="large"
        isConfirmLoading={isSubmitting}
        isConfirmButtonDisabled={isActionDisabled}
        isOpen={open}
      >
        <GridLayout itemProps={{ xs: 12 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextInputField
              className={classes.textInput}
              name={`${typedNameV2<FolderSearchFilter>('subjectNipContains')}` as const}
              label={t('folder:details.field.nip')}
              inputProps={{ maxLength: 10 }}
              isRequired={!(name && !errors[`${typedNameV2<FolderSearchFilter>('subjectNameContains')}`])}
              isOnlyPositiveIntegers
            />
            <TextInputField
              className={classes.textInput}
              name={`${typedNameV2<FolderSearchFilter>('subjectNameContains')}` as const}
              label={getLabel('name')}
              isRequired={!(nip && !errors[`${typedNameV2<FolderSearchFilter>('subjectNipContains')}`])}
            />
            <Button
              variant="outlined"
              classNameWrapper={classes.findButton}
              label={t<any>('action.find')}
              isLoading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              isNoMargin
            />
            {showTable && (
              <GridItem className="h-full max-w-full">
                <SearchEntrepreneursTable
                  key={tableKey}
                  searchParams={searchParams}
                  entrepreneurs={entrepreneurs}
                  createMode={createMode}
                >
                  <ForwardTableState ref={forwardTableStateRef} setState={handleDisableAction} />
                </SearchEntrepreneursTable>
              </GridItem>
            )}
          </form>
        </GridLayout>
      </Dialog>
    </FormV2Context.Provider>
  );
}

const useStyles = makeStyles({
  findButton: {
    display: 'block',
    textAlign: 'end',
    paddingTop: '12px'
  },
  textInput: {
    padding: '12px 0'
  }
});

export default SearchFolderModal;
