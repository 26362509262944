import { MisspellDataApplicationsUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const updateApplications = async ({
  applicationId,
  applicationsUpdate
}: {
  applicationId: string;
  applicationsUpdate: MisspellDataApplicationsUpdateRequest;
}) => {
  const { data } = await API.applicationMisspellData.updateApplications(applicationId, applicationsUpdate, {
    ...endpointsConfig.updateApplications
  });
  return data;
};

function useUpdateApplicationMisspellApplicationMutation() {
  return useMutation(updateApplications);
}

export default useUpdateApplicationMisspellApplicationMutation;
