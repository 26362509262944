import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

type Params = {
  moneyTransferId: string;
};

function deleteMoneyTransfer({ moneyTransferId }: Params) {
  return API.moneyTransfer.deleteMoneyTransfer(moneyTransferId, {
    ...endpointsConfig.deleteMoneyTransfer
  });
}

function useDeleteMoneyTransfer() {
  return useMutation(deleteMoneyTransfer);
}

export default useDeleteMoneyTransfer;
