import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

import { IconCountButton, TextInput } from '@libs/common/v2';
import { getLocalStorageItem, setLocalStorageItem } from '@libs/common/v2/utils';

import { AutoLogoutStorageItemEnum } from '@libs/auth/models';

import { useOverrideSystemParameters } from './OverrideSystemParameters';
import useSystemParametersContext from './useSystemParametersContext';

export function EditSessionDurationForm() {
  const { sessionValidityDuration } = useSystemParametersContext();
  const [t] = useTranslation();
  const [sessionDuration, setSessionDuration] = useState<number>(
    Number(getLocalStorageItem(AutoLogoutStorageItemEnum.OVERRIDDEN_TIME))
  );

  useEffect(() => {
    if (sessionValidityDuration?.value && !sessionDuration) {
      setSessionDuration(sessionValidityDuration?.value);
    }
  }, [sessionValidityDuration?.value, sessionDuration]);

  const { overrideSystemParameters } = useOverrideSystemParameters();
  return (
    <form
      className="flex h-full justify-center items-center"
      onSubmit={e => {
        e.preventDefault();
        setLocalStorageItem(AutoLogoutStorageItemEnum.OVERRIDDEN_TIME, String(sessionDuration));
        overrideSystemParameters({ sessionValidityDuration: { value: sessionDuration } });
      }}
    >
      <Tooltip title={t('field.sessionDuration')}>
        <span>
          <TextInput
            className="w-128"
            type="number"
            value={sessionDuration}
            inputProps={{ min: 1 }}
            onChange={e => setSessionDuration(+e.target.value)}
          />
        </span>
      </Tooltip>
      <IconCountButton
        type="submit"
        icon="SaveIcon"
        tooltipTitle={t('action.save')}
        isDisabled={sessionValidityDuration?.value === sessionDuration}
        className="flex h-40 w-40"
      />
    </form>
  );
}
