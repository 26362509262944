import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  SingleUsePermissionSubmissionDetails,
  SingleUsePermissionSubmissionUpdateRequest,
  SingleUseSubmissionItemSnapshot
} from '@ibtm/domain';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';
import { isEmpty, isNil } from 'lodash';

import { partialTranslate } from '@libs/common';
import {
  BackButton,
  Breadcrumbs,
  Button,
  ButtonsGroup,
  CommonTabHeader,
  FormMode,
  FormV2Context,
  Page,
  useIsSmallScreen,
  useRouter
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import {
  IDisposablePermitsDetailsParams,
  modePathDisposablePermits,
  PetitionsTypeEnum,
  RowDisposablePermitsSchema,
  SocialCommissionDisposablePermitsEditForm,
  SocialCommissionQueryKeysEnum,
  useCreateSingleUseSubmissionMutation,
  useDisposablePermitFormQuery,
  useSocialCommissionBreadcrumps,
  useUpdateSingleUseSubmissionMutation
} from '@libs/domain/social-commission';
import { SocialCommissionTabEnum } from '@libs/domain/social-commission/components/common/model/social-comission.model';

function SocialCommissionDisposablePermitsFormPage({ formMode }: { formMode: FormMode }) {
  const [t] = useTranslation();
  const queryCache = useQueryCache();
  const { id } = useParams<IDisposablePermitsDetailsParams>();
  const { createMode, viewMode } = useViewModesV2(formMode);
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { disposablePermits } = useSocialCommissionBreadcrumps(modePathDisposablePermits, formMode);
  const { goToPage, routes } = useRouter();
  const getLabel = partialTranslate('foreignPermits:tabs.socialCommission.tabs.socialCommissionDisposablePermits');
  const [singleUseSubmissionItems, setSingleUseSubmissionItems] = useState<SingleUseSubmissionItemSnapshot[]>(null);
  const { isSmallScreen } = useIsSmallScreen();

  const { data: disposablePermitData } = useDisposablePermitFormQuery(id, { enabled: !isNil(id) });
  const editDisabled = disposablePermitData?.considered;

  /**
   * Zwraca wstępna wartość roku.
   *
   * Jeśli bieżący miesiąc jest grudniem, zwraca następny rok.
   * W przeciwnym razie zwraca bieżący rok.
   *
   * @returns {number}
   */
  const getInitialYear = (): number => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return currentDate.getMonth() < 11 ? currentYear : currentYear + 1;
  };

  const form = useForm<Partial<SingleUsePermissionSubmissionDetails>>({
    mode: 'onBlur',
    resolver: yupResolver(RowDisposablePermitsSchema())
  });

  useEffect(() => {
    if (createMode) {
      form.reset({
        year: getInitialYear()
      });
    } else {
      form.reset(disposablePermitData);
      form.setValue('month', disposablePermitData?.month || 0);
      setSingleUseSubmissionItems(disposablePermitData?.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disposablePermitData, createMode]);

  useEffect(() => {
    form.setValue('items', singleUseSubmissionItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleUseSubmissionItems]);

  const { mutate: updateDisposablePermit, isLoading: isUpdateLoading } = useUpdateSingleUseSubmissionMutation();

  const { mutateAsync: createDisposablePermit, isLoading: isCreateLoading } = useCreateSingleUseSubmissionMutation();

  const formDataProvider = useMemo(() => {
    return { ...form, formMode };
  }, [form, formMode]);

  const submitEdition = (formValues: SingleUsePermissionSubmissionDetails) => {
    if (isEmpty(formValues?.items)) {
      showErrorSnackbar(
        t('foreignPermits:tabs.socialCommission.tabs.socialCommissionDisposablePermits.messages.missingResources')
      );
      return;
    }

    const requestData: SingleUsePermissionSubmissionUpdateRequest = {
      civicBoardId: formValues?.civicBoardId,
      folderNumber: formValues?.folder?.number,
      month: formValues?.month,
      version: formValues?.version,
      year: formValues?.year,
      items: formValues?.items?.map(item => ({
        resourceFormNameKey: item.resourceFormNameKey,
        resourceTypeId: item.resourceTypeId,
        quantity: item.quantity,
        decision: item.decision || null,
        suspended: item.suspended || 0
      }))
    };

    if (createMode) {
      createDisposablePermit(requestData, {
        onSuccess: params => {
          showSuccessSnackbar(t('success.add'));
          goToPage(routes.socialComissionDisposablePermitDetails(params?.data?.id));
        }
      });
    } else {
      updateDisposablePermit(
        { singleUsePermissionId: id, requestData },
        {
          onSuccess: params => {
            const singleUsePermissionId = params?.data?.id;
            showSuccessSnackbar(t('success.save'));
            goToPage(routes.socialComissionDisposablePermitDetails(singleUsePermissionId));
            queryCache.invalidateQueries([
              SocialCommissionQueryKeysEnum.PERMANENT_PERMISSION_SUBMISSION_DETAILS,
              singleUsePermissionId
            ]);
          }
        }
      );
    }
  };

  return (
    <FormV2Context.Provider value={formDataProvider}>
      <Page
        header={
          <div className="mx-10 mt-10">
            {viewMode ? (
              <>
                <Breadcrumbs items={disposablePermits} />
                <CommonTabHeader
                  actions={
                    <ButtonsGroup>
                      <BackButton
                        onClick={() =>
                          goToPage(
                            {
                              pathname: routes.socialCommissionsList(),
                              search: `?tab=${SocialCommissionTabEnum.PETITIONS}`
                            },
                            {
                              state: { type: PetitionsTypeEnum.SINGLE }
                            }
                          )
                        }
                        isNoMargin
                      />
                      <Button
                        onClick={() => goToPage(routes.socialComissionDisposablePermitEdit(id))}
                        label={t('action.edit')}
                        isSecondary
                        variant="outlined"
                        isNoMargin
                        disabled={editDisabled}
                        tooltipTitle={editDisabled && getLabel('tooltip.editDipsosablePermitDisabled')}
                        actionKey={UIElementNameEnum.COMMISSION_DISPOSABLE_EDIT_BUTTON}
                      />
                    </ButtonsGroup>
                  }
                  title={t(`${modePathDisposablePermits}.${formMode}.title`)}
                />
              </>
            ) : (
              <>
                <Breadcrumbs items={disposablePermits} />
                <CommonTabHeader
                  actions={
                    <ButtonsGroup>
                      <Button
                        onClick={() =>
                          goToPage(
                            {
                              pathname: routes.socialCommissionsList(),
                              search: `?tab=${SocialCommissionTabEnum.PETITIONS}`
                            },
                            {
                              state: { type: PetitionsTypeEnum.SINGLE }
                            }
                          )
                        }
                        label={t('action.cancel')}
                        variant="outlined"
                        isNoMargin
                        isSecondary
                      />
                      <Button
                        onClick={form.handleSubmit(submitEdition)}
                        label={t('action.save')}
                        isLoading={isUpdateLoading || isCreateLoading}
                      />
                    </ButtonsGroup>
                  }
                  title={t(`${modePathDisposablePermits}.${formMode}.title`)}
                />
              </>
            )}
          </div>
        }
        contentClassName="flex-col"
        content={
          <SocialCommissionDisposablePermitsEditForm
            formMode={formMode}
            singleUseSubmissionItems={singleUseSubmissionItems}
            setSingleUseSubmissionItems={setSingleUseSubmissionItems}
          />
        }
        isContentScrollEnabled={!isSmallScreen}
      />
    </FormV2Context.Provider>
  );
}

export default SocialCommissionDisposablePermitsFormPage;
