import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { FolderLimitDetails, FolderLimitUpdateRequest } from '@ibtm/domain';

import { useDialog } from '@libs/common/v2';

import { useUpdateFolderLimitMutation } from '../api';
import UpdateLimitsDialog from '../components/details/tabs/singleAuthorization/UpdateLimitsDialog';

function useUpdateLimitsDialog() {
  const [t] = useTranslation();
  const { openDialog } = useDialog();
  const { mutateAsync: updateFolderLimit } = useUpdateFolderLimitMutation();
  const { showSuccessSnackbar } = useSnackbar();

  const onSubmit = (id: string, values: FolderLimitUpdateRequest, refetch: () => void) => {
    return updateFolderLimit(
      {
        folderLimitId: id,
        request: values
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('folder:details.tab.singleAuthorization.updateLimits.success'));
        },
        onSettled: () => {
          refetch();
        }
      }
    );
  };

  const showUpdateLimitsDialog = ({
    folderLimit,
    refetch
  }: {
    refetch: () => void;
    folderLimit: FolderLimitDetails;
  }) => {
    openDialog(({ closeDialog }) => (
      <UpdateLimitsDialog
        closeDialog={closeDialog}
        open
        refetch={refetch}
        onSubmit={onSubmit}
        folderLimit={folderLimit}
      />
    ));
  };

  return { showUpdateLimitsDialog };
}

export default useUpdateLimitsDialog;
