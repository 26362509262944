import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, TableContext } from '@libs/common/v2';

import { DomainUIElementEnum } from '../../config';
import { usePartnerApplicantsCreateDialog } from '../hooks';

interface IProps {
  applicationId?: string;
}

function PartnerApplicantsCreateButton({ applicationId }: IProps) {
  const [t] = useTranslation();
  const { showPartnerApplicantsCreateDialog } = usePartnerApplicantsCreateDialog({
    applicationId
  });

  const { refetch } = useContext(TableContext);

  return (
    <Button
      label={t('action.add')}
      onClick={() => showPartnerApplicantsCreateDialog(refetch)}
      variant="outlined"
      classNameWrapper="ml-16"
      actionKey={DomainUIElementEnum.APPLICATION_ITEM_PARTNER_APPLICANT_SUBJECT_CREATE_BUTTON}
      isNoMargin
    />
  );
}

export default PartnerApplicantsCreateButton;
