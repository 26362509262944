import { ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

interface IProps {
  title?: ReactNode | string;
  actions?: ReactNode;
  className?: string;
}

function CommonTabHeader({ title, actions, className }: IProps) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <h2 className={classes.header}>{title}</h2>
      {actions}
    </div>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingBottom: important('0px')
  },
  header: {
    ...theme.typography.textXl.medium,
    color: theme.palette.grey[900],
    flexGrow: 1,
    paddingTop: '6px',
    paddingBottom: '6px'
  }
}));

export default CommonTabHeader;
