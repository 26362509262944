import app from './pl/app.json';
import account from './pl/account.json';
import administration from './pl/administration.json';
import alerts from './pl/alerts.json';
import attachments from './pl/attachments.json';
import dictionary from './pl/dictionary.json';
import documentTemplates from './pl/documentTemplates.json';
import global from './pl/global.json';
import group from './pl/group.json';
import login from './pl/login.json';
import messages from './pl/messages.json';
import notifications from './pl/notifications.json';
import other from './pl/other.json';
import reports from './pl/reports.json';
import tables from './pl/tables.json';
import user from './pl/user.json';
import validation from './pl/validation.json';
import address from './pl/address.json';
import applications from './pl/applications.json';
import archive from './pl/archive.json';
import associations from './pl/associations.json';
import document from './pl/document.json';
import documentsIssued from './pl/documentsIssued.json';
import driverCertificate from './pl/driverCertificate.json';
import drivers from './pl/drivers.json';
import exploitationBase from './pl/exploitationBase.json';
import ezdDocuments from './pl/ezdDocuments.json';
import financialSecurity from './pl/financialSecurity.json';
import folder from './pl/folder.json';
import foreignPermits from './pl/foreignPermits.json';
import history from './pl/history.json';
import license from './pl/license.json';
import notes from './pl/notes.json';
import partnerApplicants from './pl/partnerApplicants.json';
import payments from './pl/payments.json';
import permission from './pl/permission.json';
import pricing from './pl/pricing.json';
import prints from './pl/prints.json';
import proceeding from './pl/proceeding.json';
import processes from './pl/processes.json';
import proxy from './pl/proxy.json';
import releaseDocuments from './pl/releaseDocuments.json';
import resource from './pl/resource.json';
import roadTransport from './pl/roadTransport.json';
import sCertificates from './pl/sCertificates.json';
import subject from './pl/subject.json';
import suspensions from './pl/suspensions.json';
import systemParameters from './pl/systemParameters.json';
import transportManager from './pl/transportManager.json';
import vehicle from './pl/vehicle.json';

const translations = {
  app,
  account,
  administration,
  alerts,
  attachments,
  dictionary,
  documentTemplates,
  global,
  group,
  login,
  messages,
  notifications,
  other,
  reports,
  tables,
  user,
  validation,
  address,
  applications,
  archive,
  associations,
  document,
  documentsIssued,
  driverCertificate,
  drivers,
  exploitationBase,
  ezdDocuments,
  financialSecurity,
  folder,
  foreignPermits,
  history,
  license,
  notes,
  partnerApplicants,
  payments,
  permission,
  pricing,
  prints,
  proceeding,
  processes,
  proxy,
  releaseDocuments,
  resource,
  roadTransport,
  sCertificates,
  subject,
  suspensions,
  systemParameters,
  transportManager,
  vehicle
};
export default translations;
