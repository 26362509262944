import { useMemo } from 'react';
import _ from 'lodash';

import { ITableLayoutProps } from '@libs/common/v2';

import { useContextSelector } from '@libs/dictionary';

function useTableLayoutProps<T extends Record<string, any>, K extends Record<string, any>>(
  props: ITableLayoutProps<T, K>,
  columnDepsArray?: Array<string | boolean | number | object>
): any {
  const dictionaryData = useContextSelector(store => store.context.context.data.dictionary);
  return {
    ...props,
    tableHookOptions: {
      ..._.omit(props.tableHookOptions, ['columns', 'filterConverter', 'sortByConverter', 'tablePlugins']),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      tablePlugins: useMemo(() => props.tableHookOptions.tablePlugins, []),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      filterConverter: useMemo(() => props.tableHookOptions.filterConverter, []),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      sortByConverter: useMemo(() => props.tableHookOptions.sortByConverter, []),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns: useMemo(() => props.tableHookOptions.columns, [dictionaryData, ...(columnDepsArray || [])])
    }
  };
}
export default useTableLayoutProps;
