import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderDetails } from '@ibtm/domain';
import { makeStyles } from '@mui/styles';

import {
  ALTERNATIVE_DATE_TIME_FORMAT,
  DatepickerField,
  DictionarySelectField,
  GridItem,
  GridLayout,
  InputMode,
  Section,
  SwitchField,
  TextInputField,
  typedNameV2,
  useErrorsHandlerV2,
  useFormV2Context,
  WarningInformation
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';
import { Theme } from '@libs/common/v2/theme';
import { convertDateToDateFormat } from '@libs/common/v2/utils';

import { useElementVisibility } from '@libs/permission';

import { useAdressFields } from '@libs/domain/address';
import { DomainDictionaryEnum, DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import { RegisteredFolderFormEnum } from '@libs/domain/folder';

interface IProps {
  additionalContent?: {
    header?: JSX.Element;
  };
}

function BasicDataTab({ additionalContent }: IProps) {
  const [t] = useTranslation();
  const { containerRef } = useErrorsHandlerV2(null, RegisteredFolderFormEnum.FOLDER_FORM);
  const { watch, formMode } = useFormV2Context();
  const { createMode, editMode } = useViewModesV2(formMode);
  const { checkIsElementVisible } = useElementVisibility();
  const { isOperatorPortal } = useDomainConfigContext();
  const classes = useStyles();

  const inputMode = useMemo(() => (createMode ? InputMode.FORM : InputMode.VIEW), [createMode]);
  const adressSuccessorFieldsToOmit = ['successor-voivodeship', 'successor-county', 'successor-commune'];
  const adressSuccessorManagerFieldsToOmit = [
    'successionManager-voivodeship',
    'successionManager-county',
    'successionManager-commune'
  ];
  const addressSuccesionManagerFields = useAdressFields(
    typedNameV2<FolderDetails>('successionManager.address'),
    inputMode
  ).filter(item => !adressSuccessorManagerFieldsToOmit.includes(item.key.toString()));

  const addressSuccessorFields = useAdressFields(typedNameV2<FolderDetails>('successor.address'), inputMode).filter(
    item => !adressSuccessorFieldsToOmit.includes(item.key.toString())
  );

  const getLabel = (key: string): string => t<any>(`folder:details.field.${key}`);

  const migratedFromTransbit = watch(typedNameV2<FolderDetails>('migratedFromTransbit')) as boolean;
  const archived = watch(typedNameV2<FolderDetails>('archived')) as boolean;
  const dateOfSubjectDeath = watch(typedNameV2<FolderDetails>('dateOfSubjectDeath')) as string;
  const activeProceedingsNumber = watch(typedNameV2<FolderDetails>('activeProceedingsNumber')) as number;
  const activeVerificationProceedingsNumber = watch(
    typedNameV2<FolderDetails>('activeVerificationProceedingsNumber')
  ) as number;

  const isEditModeAllowed = useMemo(
    () => checkIsElementVisible(DomainUIElementEnum.FOLDER_BASIC_DATA_EDIT),
    [checkIsElementVisible]
  );

  const isActiveProceedingWarningVisible = useMemo(() => {
    return activeProceedingsNumber > 0 || activeVerificationProceedingsNumber > 0;
  }, [activeProceedingsNumber, activeVerificationProceedingsNumber]);

  const hasTwoWarnings = useMemo(() => {
    return isActiveProceedingWarningVisible && archived;
  }, [isActiveProceedingWarningVisible, archived]);

  return (
    <div ref={containerRef}>
      {additionalContent && additionalContent?.header}
      {archived && (
        <WarningInformation
          content={t('folder:details.warning.archived')}
          gridClassName={hasTwoWarnings ? 'mb-24' : null}
          key="archived-warning"
        />
      )}
      {isActiveProceedingWarningVisible && (
        <WarningInformation content={t('folder:details.warning.suspensionOnFolder')} key="suspension-warning" />
      )}
      <Section title={t('folder:details.section.folder')} isCollapsable>
        <GridLayout itemProps={{ xs: 6 }}>
          <DictionarySelectField
            dictionaryName={DomainDictionaryEnum.FOLDER_TYPE}
            name={typedNameV2<FolderDetails>('typeKey')}
            label={getLabel('typeKey')}
            key="typeKey"
            inputMode={createMode ? InputMode.FORM : InputMode.VIEW}
          />
          {migratedFromTransbit && (
            <SwitchField
              name={typedNameV2<FolderDetails>('migratedFromTransbit')}
              label={getLabel('isMigratedWithTransbit')}
              inputMode={InputMode.VIEW}
              valueClassName={classes.switchValue}
            />
          )}
          {migratedFromTransbit && (
            <SwitchField
              name={typedNameV2<FolderDetails>('transbitDataVerified')}
              label={getLabel('isVerified')}
              inputMode={InputMode.VIEW}
              valueClassName={classes.switchValue}
            />
          )}

          <SwitchField
            name={typedNameV2<FolderDetails>('sendCourierBlocked')}
            label={getLabel('areShippingsBlocked')}
            key="areShippingsBlocked"
            {...((editMode && !isEditModeAllowed) ||
            checkIsElementVisible(DomainUIElementEnum.FOLDER_COURIER_PARCEL_BLOCK_FIELD)
              ? null
              : { inputMode: InputMode.VIEW })}
          />
        </GridLayout>
      </Section>
      {isOperatorPortal && (
        <Section title={t('folder:details.section.generalInformation')} isCollapsable>
          <GridLayout itemProps={{ xs: 6 }}>
            <DatepickerField
              inputMode={InputMode.VIEW}
              name={typedNameV2<FolderDetails>('created')}
              label={getLabel('created')}
              key="created"
              viewModeDateParser={convertDateToDateFormat}
              format={ALTERNATIVE_DATE_TIME_FORMAT}
            />
            <TextInputField
              inputMode={InputMode.VIEW}
              name={typedNameV2<FolderDetails>('author.name')}
              label={t('folder:field.authorName')}
              key="author.name"
            />
            <DatepickerField
              inputMode={InputMode.VIEW}
              name={typedNameV2<FolderDetails>('modified')}
              label={t('folder:field.modified')}
              key="modified"
              viewModeDateParser={convertDateToDateFormat}
              format={ALTERNATIVE_DATE_TIME_FORMAT}
            />
            <TextInputField
              inputMode={InputMode.VIEW}
              name={typedNameV2<FolderDetails>('modifier.name')}
              label={t('folder:field.modifierName')}
              key="modifier.name"
            />
          </GridLayout>
        </Section>
      )}
      <Section title={t('folder:details.section.entrepreneurDeath')} isCollapsable>
        <GridLayout itemProps={{ xs: 6 }}>
          <DatepickerField
            inputMode={InputMode.VIEW}
            name={typedNameV2<FolderDetails>('dateOfSubjectDeath')}
            label={getLabel('dateOfDeath')}
            key="dateOfSubjectDeath"
            viewModeDateParser={convertDateToDateFormat}
          />
        </GridLayout>
      </Section>
      {dateOfSubjectDeath && (
        <>
          <Section title={t('folder:details.section.successor')} isCollapsable>
            <GridLayout itemProps={{ xs: 6 }}>
              <DatepickerField
                inputMode={InputMode.VIEW}
                name={typedNameV2<FolderDetails>('successor.permissionExpirationDate')}
                label={getLabel('excersingPowersEndDate')}
                key="excersingPowersEndDate"
                viewModeDateParser={convertDateToDateFormat}
              />
              <TextInputField
                inputMode={InputMode.VIEW}
                name={typedNameV2<FolderDetails>('successor.name')}
                label={getLabel('subjectName')}
                key="successor.name"
              />
              <TextInputField
                inputMode={InputMode.VIEW}
                name={typedNameV2<FolderDetails>('successor.surname')}
                label={getLabel('subjectSurname')}
                key="subjectSurname"
              />
              <GridItem xs={12}>
                <GridLayout itemProps={{ xs: 6 }}>{addressSuccessorFields}</GridLayout>
              </GridItem>
            </GridLayout>
          </Section>
          <Section title={t('folder:details.section.successionManager')} isCollapsable>
            <GridLayout itemProps={{ xs: 6 }}>
              <DatepickerField
                inputMode={InputMode.VIEW}
                name={typedNameV2<FolderDetails>('successionManager.managerEstablishmentDate')}
                label={getLabel('successionBoardStartDate')}
                key="successionManager-successionBoardStartDate"
                viewModeDateParser={convertDateToDateFormat}
              />
              <DatepickerField
                inputMode={InputMode.VIEW}
                name={typedNameV2<FolderDetails>('successionManager.permissionExpirationDate')}
                label={getLabel('successionBoardEndDate')}
                key="successionManager-successionBoardEndDate"
                viewModeDateParser={convertDateToDateFormat}
              />
              <TextInputField
                inputMode={InputMode.VIEW}
                name={typedNameV2<FolderDetails>('successionManager.name')}
                label={getLabel('subjectName')}
                key="successionManager-subjectName"
              />
              <TextInputField
                inputMode={InputMode.VIEW}
                name={typedNameV2<FolderDetails>('successionManager.surname')}
                label={getLabel('subjectSurname')}
                key="successionManager-subjectSurname"
              />
              <TextInputField
                inputMode={InputMode.VIEW}
                name={typedNameV2<FolderDetails>('successionManager.nip')}
                label={t('folder:field.subjectNip')}
                key="successionManager-successionManager.nip"
              />
              <GridItem xs={12}>
                <GridLayout itemProps={{ xs: 6 }}>{addressSuccesionManagerFields}</GridLayout>
              </GridItem>
            </GridLayout>
          </Section>
        </>
      )}
    </div>
  );
}

const useStyles = makeStyles<Theme>(() => ({
  switchValue: {
    '& svg': {
      filter: 'grayscale(1)'
    }
  }
}));

export default BasicDataTab;
