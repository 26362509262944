import { useMemo } from 'react';
import { AccountsApiSearchRequest } from '@avispon/user';
import { AccountSnapshot } from '@avispon/user/dist/models';

import { CamelCasePath, useTableLayoutProps } from '@libs/common/v2';

import { useUsersQuery } from '@libs/user/api';
import { useUserListColumns } from '@libs/user/hooks';

import { useUserCustomConfiguration } from '.';

export const userListMappedAdditionalFilterField = {
  onlyUnassignedToUnit: ({ value }) => ({ onlyUnassignedToUnit: value }),
  group: ({ value }) => {
    if (value) {
      return { groupIds: [value?.id] };
    }

    return null;
  }
};

const UserAdditionalFilterConverter = userListMappedAdditionalFilterField;

function useUsersTable(
  onlyUnassignedToUnit: boolean,
  additionalParams: AccountsApiSearchRequest,
  visibleColumns: Array<CamelCasePath<AccountSnapshot>>
) {
  const { isUsingServiceNumber } = useUserCustomConfiguration();
  const filteredColumns = useMemo(
    () => (isUsingServiceNumber ? visibleColumns : visibleColumns.filter(column => column !== 'serviceNumber')),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isUsingServiceNumber]
  );

  const { columns, mappedFilterFields, mappedSortFields } = useUserListColumns(filteredColumns);

  return useTableLayoutProps({
    tableHookQuery: useUsersQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      initialParamsConverter: UserAdditionalFilterConverter,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'personalDataSurname', desc: false }]
        }
      }
    },
    tableHookQueryInitialParams: {
      onlyUnassignedToUnit,
      ...additionalParams
    }
  });
}

export default useUsersTable;
