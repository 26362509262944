import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { PluginHook, Row } from 'react-table';
import { useSnackbar } from '@enigma/fe-ui';
import { SCertificatesSearchFilter } from '@ibtm/domain';
import { object as YupObject, string as YupString } from 'yup';

import { CamelCasePath, TableLayout, useRouter, useTableRowActions } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { useApplicationTableButtons } from '@libs/domain/application';
import { DomainUIElementEnum, FolderElementsPermissionsEnum, useFolderElementsVisibility } from '@libs/domain/config';

import { useUpdateSCertificateMutation } from '../api';
import { useSCertificatesTable } from '../hooks';
import { SCertificatesSnapshot } from '../model';

interface IProps {
  initialParams?: SCertificatesSearchFilter;
  rowActions?: (row: Row<SCertificatesSnapshot>) => ReactNode;
  isSection?: boolean;
  headerActions?: ReactNode;
  isInitialTableOptions?: boolean;
  isFilterEnabled?: boolean;
  visibleColumns?: Array<CamelCasePath<SCertificatesSnapshot>>;
  tablePlugins?: Array<PluginHook<SCertificatesSnapshot>>;
  isTableMutable?: boolean;
}

export function SCertificatesTable({
  initialParams,
  isSection,
  isInitialTableOptions = true,
  isFilterEnabled,
  rowActions,
  headerActions,
  visibleColumns = [
    'statusKey',
    'subjectName',
    'folderNumber',
    'vehiclePlateNumber',
    'vehicleVin',
    'number',
    'vehicleBrandKey',
    'vehicleType',
    'applicationNumber',
    'witd'
  ],
  tablePlugins = [],
  isTableMutable = false
}: IProps) {
  const [t] = useTranslation();
  const { getQuery } = useGetApiQueryByPerspective();
  const { TABLE_CREATE } = useApplicationTableButtons('S_CERTIFICATE_APPLICATION_CREATE_BUTTON');
  const tableProps = useSCertificatesTable(
    initialParams,
    isInitialTableOptions,
    visibleColumns,
    isTableMutable,
    tablePlugins
  );
  const { mutate: updateSCertificate } = useUpdateSCertificateMutation();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbar();
  const { routes } = useRouter();
  const { checkFolderElementVisibility } = useFolderElementsVisibility();

  const rowValidation = YupObject({
    validFrom: YupString().required().nullable()
  });

  const { renderTableActions } = useTableRowActions<SCertificatesSnapshot>([
    {
      id: 'S_CERTIFICATE_DETAILS_BUTTON',
      label: 'action.openDetails',
      link: ({ original }) => routes.sCertificateDetails(original.id),
      icon: 'ArrowIcon'
    },
    {
      id: 'S_CERTIFICATE_FOLDER_DETAILS_BUTTON',
      label: 'sCertificates:showFolder',
      link: ({ original }) => routes.folderDetails(original.folder.id),
      icon: 'FolderIcon',
      isHidden: ({ row }) =>
        !checkFolderElementVisibility({
          folderTypeKey: row.original.folder.typeKey,
          permisson: FolderElementsPermissionsEnum.FOLDER_VIEW_PERMISSIONS
        })
    }
  ]);

  const onRowEdit = (data: SCertificatesSnapshot, { onSuccess }) => {
    const { version, validFrom, id } = data;
    return updateSCertificate(
      { id, validFrom, version },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('success.save'));
          onSuccess();
        },
        onError: () => showErrorSnackbar(t('global:error.dataSaveFailure'))
      }
    );
  };

  const getSCertificates = getQuery(
    params => API.client.sCertificates.getSCertificatesSnapshotPage('', '', params),
    params => API.sCertificates.getSCertificatesSnapshotPage(params)
  );

  return (
    <TableLayout
      {...tableProps}
      xlsxDownload={{
        fileName: t('sCertificates:list.title'),
        pathToXLSXTranslation: 'sCertificates:field',
        apiRequest: getSCertificates
      }}
      isFilterEnabled={isFilterEnabled}
      pageTitle={t('sCertificates:list.title')}
      headerActions={headerActions || TABLE_CREATE}
      emptyTableText={t('sCertificates:messages.noSCertificates')}
      rowActions={
        rowActions ||
        renderTableActions([
          DomainUIElementEnum.S_CERTIFICATE_DETAILS_BUTTON,
          DomainUIElementEnum.S_CERTIFICATE_FOLDER_DETAILS_BUTTON
        ])
      }
      onRowEditCreateConfirm={onRowEdit}
      editFormYupResolverSchema={rowValidation}
      isSection={isSection}
      isTableMutable
    />
  );
}

export default SCertificatesTable;
