import { CivicBoardCalculateAllFreeDisposalLimitsRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function calculateFolders(params: CivicBoardCalculateAllFreeDisposalLimitsRequest) {
  return API.civicBoard.calculateAllFreeDisposalLimits(params, {
    ...endpointsConfig.calculateFolderLimit
  });
}

function useCalculateLimitFreeDisposalFoldersMutation() {
  return useMutation(calculateFolders);
}

export default useCalculateLimitFreeDisposalFoldersMutation;
