import React, { Suspense, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { i18nConfig, initializeYup } from '@libs/config';

// Błąd samej paczki, prawdopodbnie musi tak zostać przy sprawdzaniu reguły "import/no-extraneous-dependencies"
import { bootstrap } from 'app/bootstrap';

import { initializeI18n } from '@libs/common';

import '@libs/config/styles/index.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

bootstrap();

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

const initApp = async ({ onSuccess, onError }) => {
  try {
    const t = await initializeI18n(i18nConfig);
    initializeYup(t);
    onSuccess();
  } catch (error) {
    onError(error);
  }
};

const LocaleAppProvider = React.memo(() => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    initApp({
      onSuccess: () => {
        setInitialized(true);
      },
      // eslint-disable-next-line no-console
      onError: error => console.error(error)
    });
  }, []);

  if (initialized) {
    const App = React.lazy(() => import('./app/App'));
    return (
      <Suspense fallback={<div />}>
        <App />
      </Suspense>
    );
  }
  return null;
});

createRoot(document.getElementById('root')).render(<LocaleAppProvider />);
