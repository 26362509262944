import { usePaginatedQuery } from 'react-query';
import { FolderSearchFilter, FolderSnapshot } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { FolderQueryKeysEnum } from '@libs/domain/folder';

import { endpointsConfig } from '../endpoints-config';

export const getFoldersForMetaForm = async (_, params: FolderSearchFilter) => {
  const data = await API.folder.getFoldersSnapshotPage(params, {
    ...endpointsConfig.getFoldersSnapshotPage
  });
  return data;
};

export const getFolders = async (_, params: FolderSearchFilter) => {
  const { data } = await API.folder.getFoldersSnapshotPage(params, {
    ...endpointsConfig.getFoldersSnapshotPage
  });
  return data;
};

export const getFoldersClient = async (_, params: FolderSearchFilter) => {
  const { data } = await API.client.folder.getFoldersSnapshotPage('', '', params, {
    ...endpointsConfig.getFoldersSnapshotPage
  });
  return data;
};

export default function useFoldersQuery(params: FolderSearchFilter, queryConfig = {}, queryKey?: string) {
  const { getQuery } = useGetApiQueryByPerspective();

  return usePaginatedQuery<IPaginatedModel<FolderSnapshot>>(
    [queryKey || FolderQueryKeysEnum.FOLDERS_LIST, params],
    getQuery(getFoldersClient, getFolders),
    queryConfig
  );
}
