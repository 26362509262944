import { OrganizationUnitMembersAssignRequest } from '@avispon/organization-structure/dist/models';

import { typedNameV2, useFormV2Context } from '@libs/common/v2';

interface IProps {
  selected: string[];
}

function UserGroupSelectAction({ selected }: IProps) {
  const { setValue } = useFormV2Context();
  setValue(typedNameV2<OrganizationUnitMembersAssignRequest>('userIds'), selected);

  return null;
}

function SelectionRowActionRender(selected: string[]) {
  return <UserGroupSelectAction selected={selected} />;
}

export default SelectionRowActionRender;
