export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  getFoldersSnapshotPage: { translationKey: 'folder:api.getFoldersSnapshotPage' },
  findMyOrganizationUnits: { translationKey: 'group:api.findMyOrganizationUnits' },
  findGroupsUserIsNotMemberOf: { translationKey: 'group:api.findGroupsUserIsNotMemberOf' },
  listMyGroupMemberships: { translationKey: 'group:api.listMyGroupMemberships' },
  findMyReplacements: { translationKey: 'group:api.findMyReplacements' },
  getUsersAvailableSubjects: { translationKey: 'subject:api.getUsersAvailableSubjects' },
  getUserSubjects: { translationKey: 'subject:api.getUserSubjects' },
  assignMember: { translationKey: 'group:api.assignMember' },
  createMyReplacement: { translationKey: 'group:api.createMyReplacement' },
  deleteMyReplacement: { translationKey: 'group:api.deleteMyReplacement' },
  deleteUserGroupMembership: { translationKey: 'group:api.deleteUserGroupMembership' },
  updateReplacement: { translationKey: 'group:api.updateReplacement' },
  deleteUsersAvailableSubject: { translationKey: 'subject:api.deleteUsersAvailableSubject' },
  groupFind: { translationKey: 'group:api.findGroup' },
  addUsersAvailableSubjects: { translationKey: 'account:api.addUsersAvailableSubjects' },
  userDetails: { translationKey: 'account:api.details' },
  usersList: { translationKey: 'account:api.search' },
  anonymizeAccount: { translationKey: 'account:api.anonymizeAccount' },
  updateDetailedStatus: { translationKey: 'account:api.updateDetailedStatus' },
  createClientByOperator: { translationKey: 'account:api.createClientByOperator' },
  deleteAccount: { translationKey: 'account:api.deleteAccount' },
  updateAccountData: { translationKey: 'account:api.updateAccountData' },
  lockAccount: { translationKey: 'account:api.lockAccount' },
  resetPassword: { translationKey: 'account:api.resetPassword' },
  restoreAccount: { translationKey: 'account:api.restoreAccount' },
  unlockAccount: { translationKey: 'account:api.unlockAccount' },
  suspendAccount: { translationKey: 'account:api.suspendAccount' }
};
