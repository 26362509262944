import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { FolderLockCreateRequest } from '@ibtm/domain';
import moment from 'moment';
import { date as YupDate, object as YupObject, string as YupString } from 'yup';

import { partialTranslate } from '@libs/common';
import {
  ALTERNATIVE_DATE_FORMAT,
  DatepickerField,
  Dialog,
  DictionarySelectField,
  FormMode,
  FormV2Context,
  GridLayout,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import usePermissions from '@libs/permission/hooks/usePermissions';

import { DomainDictionaryEntry, DomainDictionaryEnum, PermissionEnum } from '@libs/domain/config';
import { FolderQueryKeysEnum, useCreateLockMutation } from '@libs/domain/folder';

const getLabel = partialTranslate('folder:details.tab.locks.field');
export interface AddLockDialogProps {
  folderId: string;
  closeDialog: () => void;
  formMode: FormMode;
  defaulTypeKey?: string;
}

function AddLockDialog({
  folderId,
  closeDialog,
  formMode,
  defaulTypeKey
}: {
  folderId: string;
  closeDialog: () => void;
  formMode: FormMode;
  defaulTypeKey?: string;
}) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: createLock, isLoading: isSubmitLoading } = useCreateLockMutation();
  const { hasPermission } = usePermissions();

  const validationSchema = YupObject({
    typeKey: YupString().nullable().required(),
    validTo: YupDate()
      .test('minDateToday', t('validation:minDateToday'), value => moment(value).isSameOrAfter(moment(), 'day'))
      .typeError(t('validation:default')),
    remarks: YupString().notRequired().max(150)
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    reset,
    getValues,
    trigger,
    unregister
  } = useForm<Partial<FolderLockCreateRequest>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: defaulTypeKey ? { typeKey: defaulTypeKey } : undefined
  });

  const onSubmit = (values: Partial<FolderLockCreateRequest>) => {
    createLock(
      {
        folderId,
        ...values,
        validFrom: moment().format(ALTERNATIVE_DATE_FORMAT),
        validTo: values.validTo ? moment(values.validTo).format(ALTERNATIVE_DATE_FORMAT) : null,
        typeKey: values.typeKey
      },
      {
        onSuccess: () => {
          queryCache.invalidateQueries(FolderQueryKeysEnum.LOCK_LIST);
          queryCache.invalidateQueries(FolderQueryKeysEnum.FOLDER_EXTENDED);
          showSuccessSnackbar(t('folder:details.tab.locks.message.lockAdded'));
        },
        onSettled: () => {
          closeDialog();
        }
      }
    );
  };

  const values = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      reset,
      handleSubmit,
      formMode
    }),
    [
      control,
      errors,
      getValues,
      handleSubmit,
      isSubmitting,
      register,
      reset,
      setValue,
      trigger,
      unregister,
      watch,
      formMode
    ]
  );

  return (
    <FormV2Context.Provider value={values}>
      <Dialog
        title={t('folder:details.tab.locks.details.title')}
        confirmText={t('action.add')}
        cancelText={t('action.cancel')}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={closeDialog}
        dialogSize="medium"
        isConfirmLoading={isSubmitLoading}
        isOpen
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <GridLayout itemProps={{ xs: 12 }}>
            <DictionarySelectField
              name={typedNameV2<FolderLockCreateRequest>('typeKey')}
              label={getLabel('typeKey')}
              dictionaryName={DomainDictionaryEnum.FOLDER_LOCK_TYPE}
              {...(!hasPermission(PermissionEnum.IBTM_DOMAIN_PROCEEDING_DRIVER_CERTIFICATE_BLOCK) && {
                optionsFilter: option =>
                  option.value !== DomainDictionaryEntry.FOLDER_LOCK_TYPE.BLOCKADE_OF_ISSUANCE_OF_CERTIFICATES
              })}
              isRequired
              stringValue
            />
            <DatepickerField
              name={`${typedNameV2<FolderLockCreateRequest>('validTo')}` as const}
              label={getLabel('validTo')}
            />
            <TextInputField name={typedNameV2<FolderLockCreateRequest>('notes')} label={getLabel('notes')} lines={3} />
          </GridLayout>
        </form>
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default AddLockDialog;
