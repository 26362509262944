import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { AdditionalFilterEnum, TableLayout } from '@libs/common/v2';
import { calc, important } from '@libs/common/v2/utils';

import { API } from '@libs/domain/api';

import { useResourceObjectContentPoolsTable } from '../../../hooks';
import { ListTypeEnum } from '../../../model';
import { DepotTransferDataContext } from '../../../pages/ResourceTransferPage';
import { resourceObjectContentPoolsParser } from '../../../utils';

import CustomFilter from './CustomFilter';

interface IProps {
  listType: ListTypeEnum;
  useOnlyCircleLoader?: boolean;
}

function ResourceObjectContentPoolsTable({ listType, useOnlyCircleLoader }: IProps) {
  const [t] = useTranslation();
  const classes = useStyles();

  const depotTransferDataContext = useContext(DepotTransferDataContext);
  const { SOURCE, TARGET } = depotTransferDataContext;
  const tableProps = useResourceObjectContentPoolsTable({
    listType,
    // eslint-disable-next-line react/destructuring-assignment
    depotId: depotTransferDataContext[listType].id
  });

  const globalFilters = [
    {
      type: AdditionalFilterEnum.CUSTOM_FILTER,
      name: 'depot',
      customFilter: (
        <CustomFilter
          listType={listType}
          inputProps={{
            placeholder:
              listType === ListTypeEnum.SOURCE
                ? t('resource:actions.selectSourceDepot')
                : t('resource:actions.selectTargetDepot'),
            className: classes.selectWrapper,
            isMultiple: false,
            isFreeSolo: false
          }}
          key="custom-pool-filter"
        />
      )
    }
  ];

  return (
    <TableLayout
      {...tableProps}
      globalFilters={globalFilters}
      xlsxDownload={{
        fileName: t('resource:list.resourceObjectContentPoolsTitle'),
        pathToXLSXTranslation: 'resource:fields',
        apiRequest: params =>
          API.resourceObjectPools.getResourceObjectContentPoolSnapshot(params).then(res => ({
            ...res,
            data: { ...res.data, content: resourceObjectContentPoolsParser(res.data.content) }
          }))
      }}
      sectionHeaderClassName={classes.sectionHeader}
      sectionContentClassName={classes.sectionContent}
      isRefreshEnabled={listType === ListTypeEnum.SOURCE ? !!SOURCE.id : !!TARGET.id}
      isActionColumnEnabled={false}
      useOnlyCircleLoader={useOnlyCircleLoader}
      isSection
    />
  );
}

const useStyles = makeStyles({
  sectionHeader: {
    '& > div:first-child': {
      width: '100%'
    }
  },
  sectionContent: {
    height: important(calc('100% - 70px'))
  },
  selectWrapper: {
    width: 250
  }
});

export default ResourceObjectContentPoolsTable;
