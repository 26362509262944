import React from 'react';
import { Filters, SortingRule } from 'react-table';
import deepmerge from 'deepmerge';
import _ from 'lodash';

import { i18n } from '@libs/common';
import { convertDateToDateFormat } from '@libs/common/v2/utils';

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export type ValueOf<T> = T[keyof T];

type PathImpl<T, Key extends keyof T> = Key extends string
  ? T[Key] extends Record<string, any>
    ?
        | `${Key}.${PathImpl<T[Key], Exclude<keyof T[Key], keyof any[]>> & string}`
        | `${Key}.${Exclude<keyof T[Key], keyof any[]> & string}`
    : never
  : never;
type PathImpl2<T> = PathImpl<T, keyof T> | keyof T;
export type CommonPath<T> = PathImpl2<T> extends string | keyof T ? PathImpl2<T> : keyof T;
export type Path<T> = PathImpl2<T> extends string | keyof T ? PathImpl2<T> : keyof T;
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const typedForwardRef: <RefType, T extends React.ComponentProps<any> = React.ComponentProps<any>>(
  c: React.ComponentType<T>
) => React.ComponentType<T & { ref: React.RefAttributes<RefType> }> = React.forwardRef as any;
export const typedName = <T extends Record<string, any>>(name: Path<T>) => name as string;

export const deepTypedName = <
  T,
  K1 extends keyof T,
  K2 extends keyof T[K1],
  K3 extends keyof T[K1][K2],
  K4 extends keyof T[K1][K2][K3],
  K5 extends keyof T[K1][K2][K3][K4],
  K6 extends keyof T[K1][K2][K3][K4][K5]
>(
  _: T,
  ...keys: [K1, K2?, K3?, K4?, K5?, K6?]
) => keys.reduce((acc, key) => (key !== null && key !== undefined ? `${acc}.${String(key)}` : acc), '').substr(1);

export type TypedSelector<Store> = <InferredType>(selector: (store: Store) => InferredType) => InferredType;

export type AnyObject = Record<any, any>;

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

// eslint-disable-next-line no-promise-executor-return
export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const pick = <Values, Key extends keyof Values>(values: Values, ...keys: Key[]): Partial<Values> =>
  _.pick(values, keys);

export function unescapeValue(value: Record<string, unknown>): Record<string, unknown> & {
  interpolation?: {
    escapeValue: boolean;
  };
} {
  return { ...value, interpolation: { escapeValue: false } };
}

export const convertFilters = <T extends Record<string, any>>(
  filters: Filters<T>,
  converter: { [_: string]: (value: unknown) => Record<string, any> }
): Filters<T> => {
  return {
    ...(filters ?? []).reduce((acc, item) => {
      const filter = converter[item.id]?.(item.value);
      return filter ? { ...acc, ...filter } : acc;
    }, {})
  };
};

interface IInitialParmasItem {
  id?: string;
  value: unknown;
}
export const convertInitialParams = (
  initialParams: Record<string, any>,
  converter: { [_: string]: (value: unknown) => Record<string, any> }
) => {
  return {
    ...initialParams?.reduce((acc, item: IInitialParmasItem) => {
      const filter = converter[item.id]?.(item);

      return filter ? deepmerge(acc, filter) : deepmerge(acc, { [item.id]: item.value });
    }, {})
  };
};

export const convertSortBy = <T extends Record<string, any>>(
  sortBy: Array<SortingRule<T>>,
  converter: Record<string, string | string[]>
): Array<SortingRule<T>> => {
  return (sortBy ?? []).flatMap(sortItem => {
    const apiParam = converter[sortItem.id] ?? sortItem.id;
    if (_.isArray(apiParam)) {
      return apiParam.map(param => ({
        ...sortItem,
        id: param
      }));
    }
    return {
      ...sortItem,
      id: apiParam
    };
  });
};

export const getValue = (value: string | number): string => {
  if (typeof value === 'number') {
    return value.toString();
  }
  if (typeof value === 'string' && !_.isEmpty(value)) {
    return value;
  }
  return i18n.t('emptyMark');
};

export const getNumberValue = (value: number, decimalPlaces = 0): string | number => {
  return _.isNumber(value) ? value.toFixed(decimalPlaces) : i18n.t('emptyMark');
};

export const getDateValue = (value: string, convertFunction: (value: string) => string): string =>
  value ? convertFunction(value) : i18n.t('emptyMark');

export const getFormattedDateValue = (value: string) => getDateValue(value, convertDateToDateFormat);

export type TAmountRange = {
  min: number;
  max: number;
};
