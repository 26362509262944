import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import { useTableAdapter } from '../../hooks';
import { getNonEmptyFilters } from '../../utils';

import TableButton from './TableButton';
import TableIconButton from './TableIconButton';

interface TableButtonFiltersProps {
  onOpen: () => void;
  onClose: () => void;
  isActive: boolean;
  isOpen: boolean;
  tooltipTitle?: string;
  className?: string;
  rowActions?: boolean;
  isMobile?: boolean;
}

interface TableFiltersButtonAdapterProps {
  rowActions?: boolean;
  isMobile?: boolean;
  onClick?: () => void;
}

const TableButtonFilters = React.memo(
  ({ isActive, isOpen, className, tooltipTitle, onOpen, onClose, rowActions, isMobile }: TableButtonFiltersProps) => {
    const [t] = useTranslation();
    const classes = useStyles({ isActive });

    const handleFiltersVisibility = () => {
      return isOpen ? onClose() : onOpen();
    };

    const tooltipText = isOpen ? t('other:component.table.hideFilters') : t('other:component.table.showFilters');
    const iconClasses = !rowActions && classes.icon;

    if (isMobile) {
      return (
        <TableButton
          label={tooltipTitle || tooltipText}
          onClick={() => {
            handleFiltersVisibility();
          }}
          isDisabled={!isActive && rowActions}
        />
      );
    }

    return (
      <TableIconButton
        icon={rowActions ? 'ChevronUpIcon' : 'FilterIcon'}
        tooltipTitle={tooltipTitle || tooltipText}
        className={clsx(classes.iconButton, className, { [classes.isOpenOverTable]: isOpen && !rowActions })}
        onClick={handleFiltersVisibility}
        iconClassName={iconClasses}
        isActive={isActive && !rowActions}
      />
    );
  },
  (prevProps, nextProps) => prevProps.isOpen === nextProps.isOpen && prevProps.isActive === nextProps.isActive
);

const useStyles = makeStyles<Theme, { isActive?: boolean }>(theme => ({
  iconButton: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    '& > path': {
      stroke: 'none',
      fill: ({ isActive }) => (isActive ? theme.palette.blue[800] : theme.palette.grey[500])
    }
  },
  isOpenOverTable: {
    '& svg, path': {
      fill: important(theme.palette.blue[800])
    }
  }
}));

function TableFiltersButtonAdapter({ rowActions, isMobile, onClick }: TableFiltersButtonAdapterProps) {
  const {
    state: { filtersPanelOpen, filters },
    openFiltersPanel,
    closeFiltersPanel
  } = useTableAdapter();

  const onOpen = () => {
    openFiltersPanel();
    onClick?.();
  };

  const onClose = () => {
    closeFiltersPanel();
    onClick?.();
  };

  return (
    <TableButtonFilters
      isOpen={filtersPanelOpen}
      isActive={Boolean(getNonEmptyFilters(filters)?.length)}
      onOpen={onOpen}
      onClose={onClose}
      rowActions={rowActions}
      isMobile={isMobile}
    />
  );
}

export default React.memo(TableFiltersButtonAdapter, (prevProps, nextProps) => prevProps === nextProps);
