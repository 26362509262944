import { usePaginatedQuery } from 'react-query';
import { DriverCertificateSearchFilter as DriverCertificateSearchFilterClient } from '@ibtm/client-domain';
import { DriverCertificateSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { DriverCertificateSnapshotClient } from '../../model';
import { parseDriverCertificatesList } from '../../parsers';
import { endpointsConfig } from '../endpoints-config';

import { DriverCertificatesQueryKeysEnum } from '.';

export const getDriverCertificates = async (_, params: DriverCertificateSearchFilter) => {
  const { data } = await API.driverCertificate.getDriverCertificatesPage(params, {
    ...endpointsConfig.getDriverCertificatesPage
  });

  return {
    ...data,
    content: parseDriverCertificatesList(data.content)
  };
};

export const getDriverCertificatesClient = async (_, params: DriverCertificateSearchFilterClient) => {
  const { data } = await API.client.driverCertificate.getDriverCertificatesPage('', '', params, {
    ...endpointsConfig.getDriverCertificatesPage
  });

  return {
    ...data,
    content: parseDriverCertificatesList(data.content)
  };
};

export default function useDriverCertificatesQuery(
  params: DriverCertificateSearchFilter | DriverCertificateSearchFilterClient,
  queryConfig = {}
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return usePaginatedQuery<IPaginatedModel<DriverCertificateSnapshotClient>>(
    [DriverCertificatesQueryKeysEnum.DRIVER_CERTIFICATES_LIST, params],
    getQuery(getDriverCertificatesClient, getDriverCertificates),
    queryConfig
  );
}
