import { useTranslation } from 'react-i18next';
import { AttachmentVerificationElementDetails } from '@ibtm/domain';

import { LoaderCircular, Scrollbar, Section, useIsSmallScreen } from '@libs/common/v2';

import AttachmentsMatrixInputField from './AttachmentsMatrixInputField';

export interface AttachmentsMatrixProps {
  fieldId?: string;
  applicationId?: string;
  isEditNoteButton?: boolean;
  isOnlyBooleanColumn?: boolean;
  isLoading?: boolean;
  verificationElements?: AttachmentVerificationElementDetails[];
}

function AttachmentsMatrix({
  fieldId,
  applicationId,
  isEditNoteButton,
  isOnlyBooleanColumn,
  isLoading,
  verificationElements
}: AttachmentsMatrixProps) {
  const [t] = useTranslation();
  const { isSmallScreen } = useIsSmallScreen();

  return (
    <Section title={t('applications:attachments.listTitle')} isPadded={false} isCollapsable>
      {isLoading ? (
        <LoaderCircular isLoading isAbsolute={false} className="flex justify-center p-10" />
      ) : (
        <Scrollbar options={{ suppressScrollY: true }} isMobile={isSmallScreen}>
          <AttachmentsMatrixInputField
            fieldId={fieldId}
            applicationId={applicationId}
            isEditNoteButton={isEditNoteButton}
            isOnlyBooleanColumn={isOnlyBooleanColumn}
            verificationElements={verificationElements}
          />
        </Scrollbar>
      )}
    </Section>
  );
}

export default AttachmentsMatrix;
