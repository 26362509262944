import { createContext, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { isNil } from 'lodash';

import {
  BackButton,
  IPropsItem,
  Page,
  PageHeader,
  SelectOption,
  useBreadcrumbsConverter,
  useBreadcrumbTextByFormMode,
  useRouter
} from '@libs/common/v2';
import { calc } from '@libs/common/v2/utils';

import { useDictionary, useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { useDepotDetailsQuery, useDepotsQuery } from '../api';
import useDepotPossibleTransfersQuery from '../api/queries/depots/useDepotPossibleTransfersQuery';
import { ResourceObjectContentPoolsTable } from '../components/list';
import { ListTypeEnum } from '../model';

export interface DepotTransferState {
  SOURCE: {
    id: string;
    translation: string;
    selectOptions: SelectOption[];
    setter?: (value: SelectOption<string>) => void | null;
  };
  TARGET: {
    id: string;
    translation: string;
    selectOptions: SelectOption[];
    setter?: (value: SelectOption<string>) => void | null;
  };
  change?: Dispatch<SetStateAction<DepotTransferState>>;
}

export const DepotTransferDataContext = createContext<DepotTransferState>({
  SOURCE: {
    id: null,
    translation: null,
    selectOptions: []
  },
  TARGET: {
    id: null,
    translation: null,
    selectOptions: []
  },
  change: () => {}
});

function ResourceTransferPage() {
  const [t] = useTranslation();
  const params = useParams<{ depotId: string }>();
  const { goBack } = useRouter();
  const { DEPOT_TYPE } = useDictionary();
  const classes = useStyles();

  const [sourceDepot, setSourceDepot] = useState<SelectOption<string> | null>({
    value: params?.depotId,
    name: null
  });
  const [targetDepot, setTargetDepot] = useState<SelectOption<string> | null>(null);

  const { data: targetDepotsData } = useDepotPossibleTransfersQuery(
    {
      sourceDepotId: sourceDepot?.value || null
    },
    {
      enabled: !!params?.depotId
    }
  );
  const { data: sourceDeportsData } = useDepotPossibleTransfersQuery(
    {
      targetDepotId: targetDepot?.value || null
    },
    {
      enabled: !!targetDepot?.value
    }
  );

  const { data: initialDepotInfo } = useDepotDetailsQuery(params?.depotId, {
    refetchOnMount: false,
    refetchIntervalInBackground: false,
    refetchInterval: false,
    refetchOnReconnect: false
  });
  const { data: dataIfDepotsEmpty } = useDepotsQuery({
    page: 0,
    size: 100,
    sort: []
  });

  const { translate } = useDictionaryTranslations();
  const { convertBreadcrumbToText } = useBreadcrumbsConverter();

  const [depots, setDepots] = useState<DepotTransferState>({
    SOURCE: {
      id: null,
      translation: null,
      selectOptions: [],
      setter: setSourceDepot
    },
    TARGET: {
      id: null,
      translation: null,
      selectOptions: [],
      setter: setTargetDepot
    }
  });
  useEffect(() => {
    // pierwsze ustawienie po pobraniu magazynu z params
    if (initialDepotInfo && !sourceDepot.name && DEPOT_TYPE) {
      setSourceDepot({
        value: initialDepotInfo?.id,
        name: `${translate(DomainDictionaryEnum.DEPOT_TYPE, initialDepotInfo?.type)} ${initialDepotInfo?.name || ''}`
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDepotInfo, translate, DEPOT_TYPE]);

  useEffect(() => {
    if (isNil(sourceDepot?.value) && isNil(targetDepot?.value) && dataIfDepotsEmpty) {
      const selectOptions = dataIfDepotsEmpty?.content?.map(item => ({
        value: item?.id,
        name: `${translate(DomainDictionaryEnum.DEPOT_TYPE, item?.type)} ${item?.number || ''}`
      }));
      setDepots({
        SOURCE: {
          id: null,
          translation: null,
          selectOptions,
          setter: setSourceDepot
        },
        TARGET: {
          id: null,
          translation: null,
          selectOptions,
          setter: setTargetDepot
        }
      });
    }
  }, [dataIfDepotsEmpty, sourceDepot, targetDepot, translate]);
  useEffect(() => {
    if (!isNil(sourceDepot?.value) || !isNil(targetDepot?.value)) {
      const isSourceDepotInSelects = sourceDeportsData?.sourceDepots?.some(item => item?.id === sourceDepot?.value);
      const isTargetDepotInSelects = targetDepotsData?.targetDepots?.some(item => item?.id === targetDepot?.value);

      const sourceSelectOptions =
        sourceDeportsData?.sourceDepots?.map(item => ({
          value: item?.id,
          name: `${translate(DomainDictionaryEnum.DEPOT_TYPE, item?.typeKey)} ${item?.number || ''}`
        })) || [];
      const targetSelectOptions =
        targetDepotsData?.targetDepots?.map(item => ({
          value: item?.id,
          name: `${translate(DomainDictionaryEnum.DEPOT_TYPE, item?.typeKey)} ${item?.number || ''}`
        })) || [];

      if (!isSourceDepotInSelects && sourceDepot) {
        sourceSelectOptions.push({
          value: sourceDepot?.value,
          name: sourceDepot?.name
        });
      }
      if (!isTargetDepotInSelects && targetDepot) {
        targetSelectOptions.push({
          value: targetDepot?.value,
          name: targetDepot?.name
        });
      }

      setDepots({
        SOURCE: {
          id: sourceDepot?.value || null,
          translation: sourceDepot?.name || null,
          selectOptions: sourceSelectOptions,
          setter: setSourceDepot
        },
        TARGET: {
          id: targetDepot?.value || null,
          translation: targetDepot?.name || null,
          selectOptions: targetSelectOptions,
          setter: setTargetDepot
        }
      });
    }
  }, [sourceDepot, targetDepot, translate, sourceDeportsData, targetDepotsData]);

  const breadcrumbText = useBreadcrumbTextByFormMode({
    viewValue: t('resource:list.transfer')
  });

  const breadcrumbs = useMemo(
    (): Array<IPropsItem> => [
      { text: t('resource:list.title'), to: '/resource' },
      { text: convertBreadcrumbToText(breadcrumbText) }
    ],
    [t, convertBreadcrumbToText, breadcrumbText]
  );

  const values = useMemo(
    () => ({
      ...depots,
      change: setDepots
    }),
    [depots]
  );

  return (
    <Page
      header={
        <PageHeader
          title={t('resource:list.transfer')}
          breadcrumbs={breadcrumbs}
          rightSideContent={<BackButton onClick={() => goBack()} />}
        />
      }
      content={
        <DepotTransferDataContext.Provider value={values}>
          <div className={classes.tableContent}>
            <div style={{ width: '49%' }} className="pr-12">
              <ResourceObjectContentPoolsTable listType={ListTypeEnum.SOURCE} useOnlyCircleLoader />
            </div>
            <div style={{ width: '49%' }} className="pl-12">
              <ResourceObjectContentPoolsTable listType={ListTypeEnum.TARGET} useOnlyCircleLoader />
            </div>
          </div>
        </DepotTransferDataContext.Provider>
      }
      isTable
      contentClassName={classes.content}
    />
  );
}

const useStyles = makeStyles(() => ({
  content: {
    height: calc('100% - 150px'),
    display: 'flex',
    flexDirection: 'column'
  },
  tableContent: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'space-between'
  }
}));

export default ResourceTransferPage;
