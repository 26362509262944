import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { StopShiftDirection } from '../../model';
import { endpointsConfig } from '../endpoints-config';

interface IParams {
  transitScheduleId: string;
  stops: string[];
  direction: StopShiftDirection;
}

export function changeStopsOrder(params: IParams) {
  const { transitScheduleId, ...restParams } = params;
  return API.transitSchedule.stopsShift(transitScheduleId, restParams, {
    ...endpointsConfig.stopsShift
  });
}

function useTransitScheduleStopShiftMutation() {
  return useMutation(changeStopsOrder);
}

export default useTransitScheduleStopShiftMutation;
