import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DriverDetails, DriversSearchFilter } from '@ibtm/domain';

import {
  AdditionalFilterEnum,
  CamelCasePath,
  ForwardTableState,
  GridLayout,
  SelectOption,
  useFormV2Context
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { useViewModesV2 } from '@libs/common/v2/form';

import { ApplicationQueryKeysEnum } from '@libs/domain/application';
import { DriversTable } from '@libs/domain/drivers/components';
import { DriverActionButtonsEnum, DriversColumnsEnum, IPermissionsObject } from '@libs/domain/drivers/model';

import { DomainDictionaryEntry, useDomainConfigContext } from '../../../../config';
import { ApplicationsTable } from '../..';

export interface IDriverTabProps {
  applicationId: string;
  folderId: string;
  transferredFoldersIds?: string[];
  subjectNip?: string;
  permissionsKeysObject?: IPermissionsObject;
  visibleActions?: Array<DriverActionButtonsEnum>;
  searchDriverDialogSearchParams?: DriversSearchFilter;
  applicationTypeKey?: string;
}

const VISIBLE_COLUMNS: Array<CamelCasePath<DriverDetails>> = [
  DriversColumnsEnum.NAME,
  DriversColumnsEnum.SURNAME,
  DriversColumnsEnum.BIRTHDATE,
  DriversColumnsEnum.BIRTHPLACE,
  DriversColumnsEnum.CITIZENSHIP,
  DriversColumnsEnum.INSURANCE_PAYER_NIP,
  DriversColumnsEnum.DRIVER_CERTIFICATE_PERIOD_OF_ISSUING,
  DriversColumnsEnum.EMPLOYMENT_DOCUMENT,
  DriversColumnsEnum.ID_TYPE,
  DriversColumnsEnum.ID_SERIES_NUMBER,
  DriversColumnsEnum.DRIVER_LICENSE_SERIES_NUMBER,
  DriversColumnsEnum.DRIVER_CERTIFICATE_NUMBER,
  DriversColumnsEnum.FORM_NUMBER,
  DriversColumnsEnum.APPLICATION_TYPE,
  DriversColumnsEnum.DRIVER_CERTIFICATE_STATUS,
  DriversColumnsEnum.DRIVER_CERTIFICATE_CANCELLATION_DATE,
  DriversColumnsEnum.DRIVER_CERTIFICATE_CANCELATION_REASON
];

function DriverIdGlobalFilter({ driverId }: { driverId: string }) {
  const { setValue } = useFormV2Context();
  useEffect(() => {
    setValue('driverId', driverId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverId]);
  return null;
}
const initialParamsConverter: Record<string, (value: SelectOption) => Record<string, any>> = {
  driverId: params => {
    return { driverId: params?.value };
  }
};

function DriverTab({
  applicationId,
  folderId,
  transferredFoldersIds,
  subjectNip,
  permissionsKeysObject,
  visibleActions = [
    DriverActionButtonsEnum.SEARCH,
    DriverActionButtonsEnum.ADD_DRIVER,
    DriverActionButtonsEnum.PREVIEW,
    DriverActionButtonsEnum.EDIT,
    DriverActionButtonsEnum.DELETE
  ],
  searchDriverDialogSearchParams,
  applicationTypeKey
}: IDriverTabProps) {
  const { isClientPortal } = useDomainConfigContext();
  const queryCache = useQueryCache();
  const [t] = useTranslation();

  const { formMode } = useFormV2Context();
  const { editMode } = useViewModesV2(formMode);

  const isActionVisible = (action: DriverActionButtonsEnum) => {
    return visibleActions.find(item => item === action);
  };

  const VISIBLE_ACTION_BUTTONS = {
    view: [
      ...(editMode
        ? [isActionVisible(DriverActionButtonsEnum.SEARCH), isActionVisible(DriverActionButtonsEnum.ADD_DRIVER)].filter(
            Boolean
          )
        : [])
    ],
    tableRow: {
      visible: [isActionVisible(DriverActionButtonsEnum.PREVIEW)],
      more: [
        ...(editMode
          ? [isActionVisible(DriverActionButtonsEnum.EDIT), isActionVisible(DriverActionButtonsEnum.DELETE)].filter(
              Boolean
            )
          : [])
      ]
    }
  };

  const [driverTableData, setDriverTableData] = useState<DriverDetails[]>(null);

  const globalFilters = [
    {
      type: AdditionalFilterEnum.CUSTOM_FILTER,
      name: 'driverId' as any,
      customFilter: <DriverIdGlobalFilter driverId={driverTableData?.[0]?.id} />
    }
  ];
  useEffect(() => {
    queryCache.invalidateQueries(ApplicationQueryKeysEnum.APPLICATION_LIST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverTableData]);

  const applicationTypesUsingDriverIdParam = [
    DomainDictionaryEntry.APPLICATION_TYPE.changeOfDriverCertificate,
    DomainDictionaryEntry.APPLICATION_TYPE.restoreOfDriverCertificate
  ];

  return (
    <GridLayout itemProps={{ xs: 12 }}>
      <DriversTable
        title={t('applications:list.addedDriverListTitle')}
        visibleColumns={VISIBLE_COLUMNS}
        visibleActionButtons={VISIBLE_ACTION_BUTTONS}
        additionalData={{
          applicationId,
          folderId,
          transferredFoldersIds,
          subjectNip,
          applicationTypeKey
        }}
        tableOptions={null}
        queryParams={{ applicationIdIn: [applicationId], includeCopies: true }}
        permissionsKeysObject={permissionsKeysObject}
        searchDriverDialogSearchParams={searchDriverDialogSearchParams}
        isSection
        isFilterDisabled
        isSortingDisabled
      >
        <ForwardTableState setState={setDriverTableData} tableStatePath="data" />
      </DriversTable>
      {driverTableData?.[0]?.id && (
        <ApplicationsTable
          title={t('applications:list.lastDriversApplicationsTitle')}
          headerActions={[]}
          hiddenColumns={isClientPortal ? ['editorName', 'modifierName'] : []}
          globalFilters={globalFilters}
          tableHookQueryConfig={{ enabled: Boolean(driverTableData?.[0]?.id), initialData: [] }}
          initialParamsConverter={initialParamsConverter}
          {...(applicationTypesUsingDriverIdParam.includes(applicationTypeKey)
            ? {
                queryParams: { driverId: driverTableData?.[0]?.linkedDriver }
              }
            : {
                queryParams: {
                  identityDocumentSeriesNumberContains: driverTableData?.[0]?.identityCard?.seriesNumber,
                  identityDocumentTypeKeyIn: [driverTableData?.[0]?.identityCard?.typeKey],
                  driverBirthDateEquals: driverTableData?.[0]?.birthDate,
                  driverNameContains: driverTableData?.[0]?.name,
                  driverSurnameContains: driverTableData?.[0]?.surname
                }
              })}
          isSection
        />
      )}
    </GridLayout>
  );
}

export default DriverTab;
