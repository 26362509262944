import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnTypesEnum, useCreateColumns, Value } from '@enigma/fe-ui';
import { ForeignPermissionsPrintsSnapshot } from '@ibtm/domain';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { PrintForeignPermissionColumnsEnum } from '../model/print-model';

type PrintForeignPermissionSnapshot = ForeignPermissionsPrintsSnapshot & { id: string };

function usePrintForeignPermissionColumn({
  visibleColumns
}: {
  visibleColumns: Array<PrintForeignPermissionColumnsEnum>;
}) {
  const [t] = useTranslation();
  const { createColumns } = useCreateColumns<PrintForeignPermissionSnapshot>();
  const columns = createColumns(
    [
      {
        id: 'formNameKey',
        accessor: 'formNameKey',
        header: t('prints:foreignPermission.fields.formNameKey'),
        type: ColumnTypesEnum.DICTIONARY,
        dictionaryKey: DomainDictionaryEnum.RESOURCE_FORM_NAME,
        columnFormatter: value => (
          <Value variant="dictionary" dictionaryKey={DomainDictionaryEnum.RESOURCE_FORM_NAME} value={value} />
        ),
        columnWidth: 450
      },
      {
        id: 'permissionsToPrintAmount',
        accessor: 'permissionsToPrintAmount',
        header: t('prints:foreignPermission.fields.permissionsToPrintAmount'),
        type: ColumnTypesEnum.NUMBER,
        columnWidth: 70
      },
      {
        id: 'firstNumber',
        accessor: 'firstNumber',
        header: t('prints:foreignPermission.fields.firstNumber'),
        type: ColumnTypesEnum.NUMBER
      },
      {
        id: 'lastNumber',
        accessor: 'lastNumber',
        header: t('prints:foreignPermission.fields.lastNumber'),
        type: ColumnTypesEnum.NUMBER
      },
      {
        id: 'formAmount',
        accessor: 'formAmount',
        header: t('prints:foreignPermission.fields.formAmount'),
        type: ColumnTypesEnum.NUMBER,
        columnWidth: 70
      }
    ],
    Object.values(PrintForeignPermissionColumnsEnum).filter(column => !Object.values(visibleColumns).includes(column))
  );

  return { columns };
}

export const FOREIGN_PERMISSION_DEFAULT_COLUMNS = [
  PrintForeignPermissionColumnsEnum.FORM_NAME_KEY,
  PrintForeignPermissionColumnsEnum.PERMISSIONS_TO_PRINT_AMOUNT,
  PrintForeignPermissionColumnsEnum.FIRST_NUMBER,
  PrintForeignPermissionColumnsEnum.LAST_NUMBER,
  PrintForeignPermissionColumnsEnum.FORM_AMOUNT
];

export default usePrintForeignPermissionColumn;
