import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { partialTranslate } from '@libs/common';
import {
  Dialog,
  DialogContentContainer,
  DictionarySelectField,
  FormMode,
  FormV2Context,
  GridItem,
  GridLayout,
  NumberInputField,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { useReceiveForeignClientPermission, useReturnForeignClientPermission } from '../../../api';
import { IForeignPermissionReceiveRequest } from '../../../api/mutations/client/useReceiveForeignClientPermission';
import { IForeignPermissionReturnRequest } from '../../../api/mutations/client/useReturnForeignClientPermission';
import { ClientForeignPermissions, ForeignPermissionQueryEnum, PermitActionTypeEnum } from '../../../model';

interface IProps {
  actionType: PermitActionTypeEnum;
  data: ClientForeignPermissions;
  closeDialog: () => void;
}

const getLabel = partialTranslate('foreignPermits:clientForeignPermissions.fields');

function ReceiveReturnPermissionForm({ actionType, data, closeDialog }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();

  const { showSnackbar } = useSnackbar();
  const { mutate: receivePermission, isLoading: isReceivePermissionLoading } = useReceiveForeignClientPermission();
  const { mutate: returnPermission, isLoading: isReturnPermissionLoading } = useReturnForeignClientPermission();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    defaultValues: {
      ...data,
      amount: 1
    }
  });

  const onSubmit = (formData: ClientForeignPermissions) => {
    if (actionType === PermitActionTypeEnum.RECEIVE) {
      const requestData: IForeignPermissionReceiveRequest = {
        content: [
          {
            resourceTypeId: formData.resourceTypeId,
            name: formData.name,
            numberFrom: formData.formNumber,
            numberTo: formData.formNumber,
            amount: 1
          }
        ],
        xFolderId: '',
        xSubjectId: ''
      };

      receivePermission(requestData, {
        onSuccess: () => {
          showSnackbar('success', t('foreignPermits:clientForeignPermissions.messages.receivePermissionSuccess'));
          queryCache.invalidateQueries(ForeignPermissionQueryEnum.FOREIGN_PERMISSION_CLIENT_LIST);
          closeDialog();
        }
      });
    } else {
      const requestData: IForeignPermissionReturnRequest = {
        content: [
          {
            resourceTypeId: formData.resourceTypeId,
            name: formData.name,
            numberFrom: formData.formNumber,
            numberTo: formData.formNumber,
            amount: 1
          }
        ],
        xFolderId: '',
        xSubjectId: ''
      };

      returnPermission(requestData, {
        onSuccess: () => {
          showSnackbar('success', t('foreignPermits:clientForeignPermissions.messages.returnPermissionSuccess'));
          queryCache.invalidateQueries(ForeignPermissionQueryEnum.FOREIGN_PERMISSION_CLIENT_LIST);
          closeDialog();
        }
      });
    }
  };

  const dialogTitle = useMemo(
    () =>
      actionType === PermitActionTypeEnum.RECEIVE
        ? t('foreignPermits:clientForeignPermissions.dialogs.receivePermit.title')
        : t('foreignPermits:clientForeignPermissions.dialogs.returnPermit.title'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const confirmText = useMemo(
    () =>
      actionType === PermitActionTypeEnum.RECEIVE
        ? t('foreignPermits:clientForeignPermissions.actions.receive')
        : t('foreignPermits:clientForeignPermissions.actions.return'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const values = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      formMode: FormMode.VIEW
    }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={dialogTitle}
      confirmText={confirmText}
      cancelText={t('action.cancel')}
      onConfirm={() => handleSubmit(data => onSubmit(data as ClientForeignPermissions))()}
      onCancel={closeDialog}
      dialogSize="small"
      isConfirmLoading={isReceivePermissionLoading || isReturnPermissionLoading}
      isOpen
    >
      <DialogContentContainer>
        <FormV2Context.Provider value={values}>
          <GridLayout>
            <GridItem xs={12}>
              <TextInputField
                name={typedNameV2<ClientForeignPermissions>('name')}
                label={getLabel('permitName')}
                isRequired
              />
            </GridItem>
            <GridItem xs={4}>
              <NumberInputField
                name={typedNameV2<ClientForeignPermissions>('formNumber')}
                label={getLabel('numberFrom')}
                isRequired
              />
            </GridItem>

            <GridItem xs={4}>
              <NumberInputField
                name={typedNameV2<ClientForeignPermissions>('formNumber')}
                label={getLabel('numberTo')}
                isRequired
              />
            </GridItem>

            <GridItem xs={4}>
              <NumberInputField name="amount" label={getLabel('amount')} isRequired />
            </GridItem>

            <GridItem xs={12}>
              <DictionarySelectField
                name={typedNameV2<ClientForeignPermissions>('type')}
                label={getLabel('permitType')}
                dictionaryName={DomainDictionaryEnum.FOREIGN_PERMIT_TYPE}
                isRequired
              />
            </GridItem>
          </GridLayout>
        </FormV2Context.Provider>
      </DialogContentContainer>
    </Dialog>
  );
}

export default ReceiveReturnPermissionForm;
