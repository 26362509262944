import { AccountSnapshotRole } from '@avispon/user';

import { UserFormValues } from '@libs/user/models';

export const userFormInitialValues: UserFormValues = {
  id: '',
  personalData: {
    name: '',
    surname: ''
  },
  email: null,
  serviceNumber: '',
  position: '',
  password: '',
  repeatPassword: '',
  organizationUnit: {
    id: ''
  },
  workSystem: {
    typeKey: null,
    workHours: ''
  },
  role: AccountSnapshotRole.OPERATOR,
  groups: [],
  entrepreneurs: [],
  version: null
};
