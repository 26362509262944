import { QueryConfig, useQuery } from 'react-query';
import { DefaultApiListPermissionsRequest } from '@avispon/group';
import { PermissionListExternal } from '@avispon/group/dist/models';

import { API, endpointsConfig, GroupQueryKeysEnum } from '@libs/group/api';

const parsePermissionsList = (organizationMembers: PermissionListExternal) =>
  organizationMembers.permissions.map(organizationMember => ({
    name: organizationMember.name,
    id: organizationMember.name
  }));

export async function getPermissions(_, params?: DefaultApiListPermissionsRequest) {
  const { data } = await API.group.listPermissions(params ?? {}, {
    ...endpointsConfig.listPermissions
  });

  const parsedData = parsePermissionsList(data);

  return parsedData
    ? {
        ...data,
        content: parsedData
      }
    : {};
}

export default function usePermissionsQuery(
  params: DefaultApiListPermissionsRequest = {},
  queryConfig: QueryConfig<PermissionListExternal> = {}
) {
  return useQuery<PermissionListExternal>([GroupQueryKeysEnum.PERMISSIONS_LIST, params], getPermissions, queryConfig);
}
