import { useTranslation } from 'react-i18next';
import { FilterProps } from 'react-table';
import { ApplicationSnapshot, ArchiveApplicationDetails, ArchiveApplicationSearchFilter } from '@ibtm/domain';

import {
  CamelCasePath,
  SelectOption,
  TableFilter,
  TableFilterMapper,
  TableSortMapper,
  useCreateColumns
} from '@libs/common/v2';
import { DateRangeISO, getCalendarDate } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';
import { UserFilterV2 } from '@libs/user';

import { ApplicationStatusChip } from '@libs/domain/application';
import { ApplicationToArchiveSnapshot } from '@libs/domain/archive';
import { DomainDictionaryEntry, DomainDictionaryEnum, useDomainConfigContext } from '@libs/domain/config';

import { ArchiveApplicationStatusMultiselectFilter } from '../../../common';

function useApplicationArchiveColumns(
  isArchive?: boolean,
  visibleColumns?: Array<CamelCasePath<ApplicationToArchiveSnapshot>>,
  classificationKeyDictionary = DomainDictionaryEnum.JRWA_CLASSIFICATION
) {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();
  const { isOperatorPortal, isClientPortal } = useDomainConfigContext();

  const { createColumns } = useCreateColumns<ApplicationToArchiveSnapshot, ArchiveApplicationDetails>({
    pathToTranslate: 'archive:application.field'
  });

  const clientApplicationStatusesToKeep = [
    DomainDictionaryEntry.APPLICATION_STATUS.RELEASED_WAITING_FOR_ZPO,
    DomainDictionaryEntry.APPLICATION_STATUS.ISSUED,
    DomainDictionaryEntry.APPLICATION_STATUS.DRAFT
  ];

  const mappedSortFields: TableSortMapper<ApplicationToArchiveSnapshot> = {
    organizationUnit: 'departmentKey',
    classificationKey: 'jrwaClassificationKey',
    entryDate: 'created',
    warning: 'archiveNote',
    folderNumber: 'folder.number',
    subjectName: 'subject.name',
    authorName: 'author.name'
  };

  const mappedFilterFields: TableFilterMapper<ApplicationToArchiveSnapshot, ArchiveApplicationSearchFilter> = {
    number: (number: string) => ({ numberContains: number?.trim() }),
    paperNumber: (paperNumber: string) => ({ paperNumberContains: paperNumber?.trim() }),
    categoryKey: (categoryKeys: SelectOption<string>[]) => ({ categoryKeyIn: categoryKeys.map(({ value }) => value) }),
    typeKey: (typeKeys: SelectOption<string>[]) => ({ typeKeyIn: typeKeys.map(({ value }) => value) }),
    folderNumber: (folderNumber: string) => ({ folderNumberContains: folderNumber?.trim() }),
    statusKey: (statusKeys: SelectOption<string>[]) => ({ statusKeyIn: statusKeys.map(({ value }) => value) }),
    entryDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      createdGreaterThanOrEqual: dateFrom,
      createdLessThanOrEqual: dateTo
    }),
    authorName: (authorIdIn: SelectOption<string>[]) => ({ authorIdIn: authorIdIn.map(({ value }) => value) }),
    subjectName: (subjectName: string) => ({ subjectNameContains: subjectName }),
    archiveNumber: (archiveNumber: string) => ({ archiveNumberContains: archiveNumber }),
    organizationUnit: (departmentKeys: SelectOption<string>[]) => ({
      departmentKeyIn: departmentKeys.map(({ value }) => value)
    }),
    classificationKey: (classifications: SelectOption<string>[]) => ({
      jrwaClassificationKeyIn: classifications?.map(({ value }) => value)
    }),
    warning: (warning: string) => ({ archiveNoteContains: warning?.trim() }),
    archiveDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      archiveDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      archiveDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    anonymized: (anonymized: SelectOption<boolean>) => ({ anonymized: anonymized.value })
  };

  const initialParamsMapper: TableFilterMapper<ApplicationToArchiveSnapshot, ArchiveApplicationSearchFilter> = {
    year: ({ value }: { value: number }) => ({ yearLessThanOrEqual: value, yearGreaterThanOrEqual: value }),
    archived: ({ value }: { value: boolean }) => ({ archived: value })
  };

  const getStatusKeyFilter = (props: React.PropsWithChildren<FilterProps<ApplicationSnapshot>>) => {
    if (isClientPortal) return [];

    return isArchive ? (
      <ArchiveApplicationStatusMultiselectFilter props={props} />
    ) : (
      <TableFilter.DictionaryMultiSelect dictionary={DomainDictionaryEnum.APPLICATION_STATUS} {...props} />
    );
  };

  const columns = createColumns(
    [
      {
        accessor: 'archiveNumber',
        type: 'TEXT',
        header: 'archivedNumber'
      },
      {
        accessor: 'number',
        type: 'TEXT'
      },
      {
        accessor: 'paperNumber',
        type: 'TEXT'
      },
      {
        accessor: 'organizationUnit',
        xlsxAccessor: 'departmentKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: 'DEPARTMENT',
        header: 'cellKey'
      },
      {
        accessor: 'categoryKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: 'APPLICATION_CATEGORY'
      },
      {
        accessor: 'typeKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: 'APPLICATION_TYPE'
      },
      {
        accessor: 'folder.number',
        type: 'TEXT'
      },
      {
        id: 'classificationKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: classificationKeyDictionary
      },
      {
        id: 'statusKey',
        type: 'CUSTOM_COLUMN',
        tooltipTitle: row =>
          isOperatorPortal || clientApplicationStatusesToKeep.includes(row?.statusKey)
            ? translate(DomainDictionaryEnum.APPLICATION_STATUS, row?.statusKey)
            : t('applications:message.inProgress'),
        customAccessor: row => (
          <ApplicationStatusChip
            status={
              isOperatorPortal || clientApplicationStatusesToKeep.includes(row?.statusKey)
                ? row?.statusKey
                : t('applications:message.inProgress')
            }
          />
        ),
        customXlsxAccessor: row => translate(DomainDictionaryEnum.APPLICATION_STATUS, row?.statusKey, t('emptyMark')),
        filter: props => getStatusKeyFilter(props)
      },
      {
        accessor: 'entryDate',
        xlsxAccessor: 'created',
        type: 'DATE'
      },
      {
        id: 'authorName',
        type: 'CUSTOM_COLUMN',
        filter: UserFilterV2,
        customAccessor: row => row?.author?.name,
        customXlsxAccessor: row => row?.author?.name
      },
      {
        accessor: 'subject.name',
        type: 'TEXT'
      },
      {
        accessor: 'archiveDate',
        type: 'DATE'
      },
      {
        accessor: 'warning',
        xlsxAccessor: 'archiveNote',
        type: 'TEXT'
      },
      {
        accessor: 'anonymized',
        type: 'BOOLEAN',
        tooltipTitle: row => (row?.anonymized ? t('action.yes') : t('action.no'))
      }
    ],
    visibleColumns
  );

  return { columns, mappedSortFields, mappedFilterFields, initialParamsMapper };
}

export default useApplicationArchiveColumns;
