import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { FetchStatusState } from '@libs/common/v2';

import { DictionaryEntryNameEnum, getDictionarySnapshotList, useDictionarySelector } from '@libs/dictionary';

// Hook do pobierania obiektu z podstawowymi informacjami nt. wszystkich dostępnych słowników (bez ich elementów)
// W celu pobrania listy słowników z elementami lub elementów konkretnych słowników należy użyć hooka "useDictionary"

export function useAllDictionariesInfo() {
  const dispatch = useDispatch() as ThunkDispatch<any, any, AnyAction>;
  const state = useDictionarySelector(({ dictionary }) => dictionary.list);

  const memoizedStatus = React.useRef<FetchStatusState>(state.status);

  useEffect(() => {
    const { downloaded, fetching }: FetchStatusState = memoizedStatus.current;
    if (!downloaded && !fetching) {
      dispatch(getDictionarySnapshotList());
    }
  }, [dispatch]);

  return state.data ?? ({} as typeof state.data);
}

export function useDictionaryInfo(dictionaryType: DictionaryEntryNameEnum) {
  const availableDictionariesState = useAllDictionariesInfo();
  return (
    availableDictionariesState[dictionaryType] ?? ({} as (typeof availableDictionariesState)[DictionaryEntryNameEnum])
  );
}
