import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { useMetaFormContext } from '@libs/meta-form/context';
import { NodeVisibility } from '@libs/meta-form/models';

import { getMetaFormQueryKey } from './useGetQuery';

function useCheckElementVisibility(visibility: NodeVisibility, componentParams?) {
  const { elementVisibilityAsyncKeys, elementVisibilityKeys, params } = visibility ?? {};
  const { elementVisibility, elementVisibilityAsync } = useMetaFormContext();
  const shouldCheckAsync = Boolean(elementVisibilityAsyncKeys?.length);

  const checkVisibilitySync = useCallback(
    (paramsFromComponent?) => {
      const shouldCheckSync = Boolean(elementVisibilityKeys?.length);
      const isSyncCheckError =
        elementVisibilityKeys
          ?.map(key => elementVisibility?.[key]?.(params, paramsFromComponent))
          .some(item => item === false) ?? true;
      const resultSync = !shouldCheckSync || !isSyncCheckError;
      return resultSync;
    },
    [elementVisibility, elementVisibilityKeys, params]
  );

  const checkVisibilityAsync = useCallback(
    async (
      paramsFromComponent?,
      { onSuccess, onError }: { onSuccess?: (result: boolean) => void; onError?: (error: any) => void } = {}
    ) => {
      try {
        const resultsAsync = await Promise.all(
          elementVisibilityAsyncKeys?.map(key => elementVisibilityAsync?.[key]?.(params, paramsFromComponent))
        );
        const isAsyncCheckError = resultsAsync.some(item => item === false) ?? true;
        const result = !isAsyncCheckError && checkVisibilitySync(paramsFromComponent);
        onSuccess?.(result);
        return result;
      } catch (error) {
        onError?.(error);
        return false;
      }
    },
    [checkVisibilitySync, elementVisibilityAsync, elementVisibilityAsyncKeys, params]
  );

  const { data: isVisible } = useQuery(
    [getMetaFormQueryKey(elementVisibilityAsyncKeys?.join()), params],
    () => checkVisibilityAsync(componentParams),
    {
      enabled: Boolean(shouldCheckAsync),
      initialStale: true
    }
  );

  return {
    isVisible: isVisible ?? (checkVisibilitySync(componentParams) && !shouldCheckAsync),
    checkVisibilitySync,
    checkVisibilityAsync
  };
}
export default useCheckElementVisibility;
