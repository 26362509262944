import {
  AddressDetails,
  DecisionDetails,
  PrintoutDataUpdateRequest,
  SubjectApplicationAddressCreateRequest,
  SubjectDetails
} from '@ibtm/domain';
import _ from 'lodash';

import { SelectOption } from '@libs/common/v2';

import { AddressesApiUpdateAddressRequestClient } from '@libs/domain/address';
import { DomainDictionaryEntry } from '@libs/domain/config';

import { AddressFormData, ApplicationSubjectAddressTypeEnum } from '../model';

export const prepareUpdateAddressRequest = (address: AddressFormData): AddressesApiUpdateAddressRequestClient => {
  return {
    addressId: address?.id,
    subjectAddressUpdateRequest: {
      typeKey: address.typeKey?.value,
      sameAsMainAddress: address?.sameAsMainAddress,
      addressSourceProxyId: address?.addressSourceProxy?.id,
      ...(!(address.sameAsMainAddress || address.addressSourceProxy?.id) && {
        address: {
          countryCodeKey: address?.countryCodeKey || DomainDictionaryEntry.COUNTRY_CODE.POLAND,
          postCity: address.postCity,
          postCode: address.postCode,
          city: address.city,
          street: address.street,
          propertyNumber: address.propertyNumber,
          voivodeshipKey: address?.voivodeshipKey?.value as string,
          firstLine: address.firstLine,
          secondLine: address.secondLine,
          version: address.version,
          commune: address.commune,
          apartmentNumber: address.apartmentNumber,
          county: address.county,
          name: address.name
        }
      })
    }
  };
};

export const prepareCreateAddressRequest = ({
  applicationId,
  typeKey,
  address
}: {
  applicationId: string;
  typeKey: (typeof DomainDictionaryEntry.ADDRESS_TYPE)[keyof typeof DomainDictionaryEntry.ADDRESS_TYPE];
  address: AddressFormData;
}): SubjectApplicationAddressCreateRequest => ({
  applicationId,
  typeKey,
  sameAsMainAddress: address.sameAsMainAddress,
  addressSourceProxyId: address?.addressSourceProxy?.id,
  ...(!(address.sameAsMainAddress || address.addressSourceProxy?.id) && {
    address: {
      countryCodeKey: address?.countryCodeKey || DomainDictionaryEntry.COUNTRY_CODE.POLAND,
      postCode: address.postCode,
      postCity: address.postCity,
      city: address.city,
      propertyNumber: address.propertyNumber,
      apartmentNumber: address.apartmentNumber,
      street: address.street,
      commune: address.commune,
      county: address.county,
      voivodeshipKey: address?.voivodeshipKey?.value as string,
      firstLine: address.firstLine,
      secondLine: address.secondLine
    }
  })
});

export const prepareUpdateSubjectAdditionalAddressRequest = (data: {
  address?: { sameAsMainAddress?: boolean; name?: string; voivodeshipKey?: string; nip?: string };
}): PrintoutDataUpdateRequest => {
  const parsedAddress = _.omit(data?.address, ['sameAsMainAddress', 'name']);
  const voivodeshipKeyValue = parsedAddress?.voivodeshipKey as unknown as { value: string };
  return {
    isSameAsMainAddress: data?.address?.sameAsMainAddress ?? false,
    name: data?.address?.name,
    nip: data?.address?.nip,
    address: {
      ...parsedAddress,
      voivodeshipKey: voivodeshipKeyValue?.value
    }
  };
};

const parseSharedApplicationSubjectAddressDetailsData = (
  data: SubjectDetails,
  isSameAsMainAddress: boolean,
  accessor: ApplicationSubjectAddressTypeEnum
) => {
  const setValue = (value: string) => {
    return isSameAsMainAddress ? null : value;
  };

  return {
    city: setValue(data[accessor]?.city),
    commune: setValue(data[accessor]?.commune),
    county: setValue(data[accessor]?.county),
    postCity: setValue(data[accessor]?.postCity),
    postCode: setValue(data[accessor]?.postCode),
    propertyNumber: setValue(data[accessor]?.propertyNumber),
    street: setValue(data[accessor]?.street),
    voivodeshipKey: setValue(data[accessor]?.voivodeshipKey),
    firstLine: setValue(data[accessor]?.firstLine),
    secondLine: setValue(data[accessor]?.secondLine)
  };
};

export const parseApplicationSubjectPermissionAddressDetails = (
  data: SubjectDetails,
  isSameAsMainAddress: boolean,
  accessor: ApplicationSubjectAddressTypeEnum
): AddressDetails & { name: string; sameAsMainAddress: boolean } => {
  const parsedData = parseSharedApplicationSubjectAddressDetailsData(data, isSameAsMainAddress, accessor);

  return {
    sameAsMainAddress: isSameAsMainAddress,
    name: data.permissionSubjectName,
    ...parsedData
  };
};

export const parseApplicationSubjectLicenseAddressDetails = (
  data: SubjectDetails,
  isSameAsMainAddress: boolean,
  accessor: ApplicationSubjectAddressTypeEnum
): AddressDetails & { name: string; sameAsMainAddress: boolean } => {
  const parsedData = parseSharedApplicationSubjectAddressDetailsData(data, isSameAsMainAddress, accessor);

  return {
    sameAsMainAddress: isSameAsMainAddress,
    name: data.licenseSubjectName,
    ...parsedData
  };
};
export const parseDecisionFormdata = (data: {
  driverCertificate: DecisionDetails;
  license: DecisionDetails;
  permission: DecisionDetails;
}) => {
  const driverCertificate = data?.driverCertificate;
  const license = data?.license;
  const permission = data?.permission;
  const parsedData = [
    driverCertificate && {
      ...(driverCertificate?.id && { id: driverCertificate?.id, version: driverCertificate?.version }),
      settlement: (driverCertificate?.settlement as Partial<SelectOption<string>>)?.value as string,
      type: (driverCertificate?.type as Partial<SelectOption<string>>)?.value,
      permissionTypeKey: driverCertificate?.permissionTypeKey
        ? driverCertificate?.permissionTypeKey
        : DomainDictionaryEntry.DECISION_PERMISSION_TYPE.DRIVER_CERTIFICATE,
      reason: driverCertificate?.reason,
      permissionExpirationDate: driverCertificate?.permissionExpirationDate
    },
    license && {
      ...(license?.id && { id: license?.id, version: license?.version }),
      settlement: (license?.settlement as Partial<SelectOption<string>>)?.value,
      type: (license?.type as Partial<SelectOption<string>>)?.value,
      permissionTypeKey: DomainDictionaryEntry.DECISION_PERMISSION_TYPE.LICENSE,
      reason: license?.reason,
      permissionExpirationDate: license?.permissionExpirationDate
    },
    permission && {
      ...(permission?.id && { id: permission?.id, version: permission?.version }),
      settlement: (permission?.settlement as Partial<SelectOption<string>>)?.value,
      type: (permission?.type as Partial<SelectOption<string>>)?.value,
      permissionTypeKey: DomainDictionaryEntry.DECISION_PERMISSION_TYPE.PERMISSION,
      reason: permission?.reason,
      permissionExpirationDate: permission?.permissionExpirationDate
    }
  ];
  return parsedData.filter(item => !!item);
};
