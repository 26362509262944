import { CivicBoardRuleDetails, CivicBoardRuleSearchFilter } from '@ibtm/domain';

import { ColumnTypesEnum, SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO, getCalendarDate } from '@libs/common/v2/utils';

import { DomainDictionaryEnum } from '@libs/domain/config';

export const useTradeRulesColumns = () => {
  const { createColumns } = useCreateColumns<CivicBoardRuleDetails>({
    pathToTranslate: 'foreignPermits:tabs.socialCommission.tabs.SocialCommissionTradeRules.fields'
  });

  const mappedFilterFields: TableFilterMapper<CivicBoardRuleDetails, CivicBoardRuleSearchFilter> = {
    active: (active: SelectOption<boolean>) => ({ isActive: active.value }),
    countryCodeKey: (countryKey: SelectOption<string>) => ({ countryCodeKeyIn: [countryKey.value] }),
    resourceFormNameKey: (resourceFormNameKey: SelectOption<string>) => ({
      resourceFormNameKeyIn: [resourceFormNameKey.value]
    }),
    validFrom: ({ dateFrom, dateTo }: DateRangeISO) => ({
      validFromGreaterThanOrEquals: getCalendarDate(dateFrom),
      validFromLessThanOrEquals: getCalendarDate(dateTo)
    }),
    validTo: ({ dateFrom, dateTo }: DateRangeISO) => ({
      validToGreaterThanOrEquals: getCalendarDate(dateFrom),
      validToLessThanOrEquals: getCalendarDate(dateTo)
    })
  };

  const mappedSortFields: TableSortMapper<CivicBoardRuleDetails> = {
    active: 'active',
    countryCodeKey: 'resourceType.countryKey',
    resourceFormNameKey: 'resourceType.transbitName',
    validFrom: 'validFrom',
    validTo: 'validTo'
  };

  const columns = createColumns([
    {
      id: 'active',
      header: 'isActive',
      type: ColumnTypesEnum.BOOLEAN,
      width: 120
    },
    {
      id: 'countryCodeKey',
      header: 'country',
      dictionaryName: DomainDictionaryEnum.COUNTRY_CODE,
      type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
      width: 120
    },
    {
      id: 'resourceFormNameKey',
      header: 'formName',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_NAME,
      type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
      width: 300
    },
    {
      id: 'validFrom',
      header: 'dateFrom',
      type: ColumnTypesEnum.DATE
    },
    {
      id: 'validTo',
      header: 'dateTo',
      type: ColumnTypesEnum.DATE
    },
    {
      accessor: 'calculationInProgress',
      type: ColumnTypesEnum.BOOLEAN,
      width: 120,
      isFilterable: false,
      isSortable: false
    }
  ]);

  return { columns, mappedFilterFields, mappedSortFields };
};
