import { Tab, TabContent, Tabs, TabsContent, useTab } from '@libs/common/v2';

import useResourcesTabDefinitions, { ResourcesTabEnum } from '../hooks/useResourcesTabDefinitions';

function ResourcesTabs() {
  const visibleTabs = useResourcesTabDefinitions();
  const [activeTab, setActiveTab] = useTab<ResourcesTabEnum>(ResourcesTabEnum.RESOURCES);
  return (
    <>
      <Tabs value={activeTab} onChange={setActiveTab} variant="scrollable" orientation="horizontal">
        {visibleTabs?.map(tab => (
          <Tab
            label={<div style={{ textAlign: 'center', margin: 'auto' }}>{tab.label}</div>}
            value={tab.value}
            isHorizontalTab
            key={tab.label}
            horizontalWidth={tab.value === ResourcesTabEnum.DEPOT_TRANSFERS ? 400 : undefined}
            viewName={tab.viewName}
          />
        ))}
      </Tabs>
      <TabsContent value={activeTab}>
        {visibleTabs.map(({ content, value, viewName }) => (
          <TabContent value={value} key={value} isInnerScroll={false} viewName={viewName}>
            {content}
          </TabContent>
        ))}
      </TabsContent>
    </>
  );
}

export default ResourcesTabs;
