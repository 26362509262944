import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosResponse } from 'axios';
import * as Yup from 'yup';

import { Dialog, FormV2Context, GridLayout, useDialog, WarningInformation, WarningThemeType } from '@libs/common/v2';
import { useMutation } from '@libs/common/v2/api';

import { UploadFileField } from '../../components';
import { AddFileRequestData, IAddFileFormData } from '../../models';

interface FileDialogProps {
  apiCreateQuery: (formValues: AddFileRequestData) => Promise<AxiosResponse<AddFileRequestData>>;
  onSuccess: () => void;
  closeDialog: () => void;
  showFileWarning?: boolean;
}

function FileDialog({ apiCreateQuery, onSuccess, closeDialog, showFileWarning = false }: FileDialogProps) {
  const [t] = useTranslation();
  const { showSuccessSnackbar } = useSnackbar();

  const { mutate: addFile, isLoading } = useMutation(apiCreateQuery);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    clearErrors,
    setError,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Partial<IAddFileFormData>>({
    mode: 'all',
    resolver: yupResolver(
      Yup.object({
        file: Yup.object().nullable().required()
      })
    ),
    defaultValues: {
      file: null
    }
  });

  const onSubmit = (formData: Partial<IAddFileFormData>) => {
    const requestData: AddFileRequestData = {
      fileId: formData.file.fileId
    };

    addFile(requestData, {
      onSuccess: () => {
        onSuccess?.();
        closeDialog();

        showSuccessSnackbar(t('success.add'));
      }
    });
  };

  const values = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      clearErrors,
      setError,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting
    }),
    [control, errors, register, setValue, clearErrors, setError, watch, getValues, trigger, unregister, isSubmitting]
  );

  return (
    <Dialog
      title={t('dialog.addFileTitle')}
      confirmText={t('action.add')}
      cancelText={t('action.cancel')}
      onConfirm={() => handleSubmit(onSubmit)()}
      onCancel={closeDialog}
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <GridLayout itemProps={{ xs: 12 }}>
          {showFileWarning && <WarningInformation content={t('applications:files.fileAttachWarning')} />}
          {showFileWarning && (
            <WarningInformation
              icon="InfoRoundedIcon"
              colorVersion={WarningThemeType.INFORMATIVE}
              content={t('applications:files.fileSignInfo')}
            />
          )}

          <UploadFileField name="file" isRequired />
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

const useAddFileDialog = () => {
  const { openDialog } = useDialog();

  const open = useCallback(
    (
      apiCreateQuery: (formValues: AddFileRequestData) => Promise<AxiosResponse<AddFileRequestData>>,
      onSuccess: () => void,
      showFileWarning: boolean
    ) => {
      openDialog(({ closeDialog }) => (
        <FileDialog
          apiCreateQuery={apiCreateQuery}
          showFileWarning={showFileWarning}
          closeDialog={closeDialog}
          onSuccess={onSuccess}
        />
      ));
    },
    [openDialog]
  );

  return {
    openAddFileDialog: open
  };
};

export default useAddFileDialog;
