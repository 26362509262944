import { TOptions } from 'i18next';

import i18n, { KeyType } from '@libs/common/i18n';

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const replaceAtIndex = (index: number, stringToReplace: string, replaceValue: string, fullString: string) => {
  if (index === -1) {
    return fullString;
  }
  const beginString = fullString.substring(0, index);
  const endString = fullString.substring(index + stringToReplace.length);
  return beginString + replaceValue + endString;
};

export const replaceFirstOccurence = (stringToReplace: string, replaceValue: string, fullString: string) => {
  const firstIndex = fullString.indexOf(stringToReplace);
  return replaceAtIndex(firstIndex, stringToReplace, replaceValue, fullString);
};

export const parseTranslationTextWithNoEscapeValue = (translation: KeyType, translationProps?: TOptions) => {
  return i18n.t(translation, { ...translationProps, interpolation: { escapeValue: false } });
};
