import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReplacementCreateRequest } from '@avispon/group/dist/models';

import { DatepickerField, FormMode, GridItem, GridLayout, typedNameV2 } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';
import InputMode from '@libs/common/v2/form/model/input.model';
import { convertDateToDateFormat } from '@libs/common/v2/utils';

import { GroupSelectField, UsersListSelectField } from '../common';

function AddReplacementsForm({ mode }: { mode: FormMode }) {
  const [t] = useTranslation();
  const { viewMode } = useViewModesV2(mode);

  return (
    <GridLayout>
      <GridItem xs={12}>
        <DatepickerField
          name={typedNameV2<ReplacementCreateRequest>('startDateTime')}
          label={t('user:replacements.field.startDateTime')}
          viewModeDateParser={convertDateToDateFormat}
          isRequired
        />
      </GridItem>
      <GridItem xs={12}>
        <DatepickerField
          name={typedNameV2<ReplacementCreateRequest>('endDateTime')}
          label={t('user:replacements.field.endDateTime')}
          viewModeDateParser={convertDateToDateFormat}
          isRequired
        />
      </GridItem>
      <GridItem xs={12}>
        <UsersListSelectField inputMode={viewMode ? InputMode.VIEW : InputMode.FORM} />
      </GridItem>
      <GridItem xs={12}>
        <GroupSelectField inputMode={viewMode ? InputMode.VIEW : InputMode.FORM} />
      </GridItem>
    </GridLayout>
  );
}
export default AddReplacementsForm;
