import { useTableLayoutProps } from '@libs/common/v2';

import { useDepotManagersQuery } from '../../api';
import { useResourceAdminsColumns } from '../columns';

function useDepotAdminsTable(depotId: string) {
  const { columns } = useResourceAdminsColumns();

  return useTableLayoutProps({
    tableHookQuery: useDepotManagersQuery,
    tableHookOptions: {
      columns,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'user.name', desc: false }]
        }
      }
    },
    tableHookQueryInitialParams: { depotIdIn: [depotId] }
  });
}

export default useDepotAdminsTable;
