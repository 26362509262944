import React, { Context, useContext } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';

import { ComponentErrorBoundary, FormV2Context, FormV2ContextState, Value } from '@libs/common/v2';
import { getValue } from '@libs/common/v2/utils';

export interface FieldValueProps {
  name: FieldPath<FieldValues>;
  label?: string;
  children?: (value: any) => React.ReactNode;
  formContext?: Context<FormV2ContextState>;
}

function FieldValue({ name, children, label, formContext = FormV2Context }: FieldValueProps) {
  const { control, loading } = useContext<FormV2ContextState>(formContext);
  return (
    <ComponentErrorBoundary componentName={label || name || 'FieldValue'}>
      <Controller
        control={control}
        name={name}
        render={field => (
          <Value label={label} isLoading={loading}>
            {!loading && (children ? children(field?.field?.value) : getValue(field?.field?.value as string))}
          </Value>
        )}
      />
    </ComponentErrorBoundary>
  );
}

export default FieldValue;
