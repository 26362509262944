import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, GridLayout } from '@libs/common/v2';

import { UploadFileField } from '@libs/file';

import UploadReportTypeDialogActions from './UploadReportTypeDialogActions';

interface IProps {
  closeDialog: () => void;
  refetch: () => void;
  isMultiple?: boolean;
  isSubmitting?: boolean;
}

function UploadReportTypeDialog({ closeDialog, refetch, isMultiple, isSubmitting }: IProps) {
  const [t] = useTranslation();
  const [reportTypesFiles, setReportTypesFiles] = useState([]);

  return (
    <Dialog
      title={t('reports:reportTypeImport.importReportTypeDialogTitle')}
      cancelText={t('action.cancel')}
      customActions={
        <UploadReportTypeDialogActions
          handleClose={closeDialog}
          refetch={refetch}
          setReportTypesFiles={values => {
            setReportTypesFiles(values);
          }}
        />
      }
      onCancel={closeDialog}
      dialogSize="small"
      isConfirmLoading={isSubmitting}
      isOpen
    >
      <GridLayout itemProps={{ xs: 12 }}>
        <UploadFileField
          name="file"
          isRequired
          isMultiple={isMultiple}
          importReportTypes
          reportTypesFiles={reportTypesFiles}
        />
      </GridLayout>
    </Dialog>
  );
}

export default UploadReportTypeDialog;
