import { makeStyles } from '@mui/styles';

import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

const useTextInputStyles = makeStyles<
  Theme,
  { isDisabled?: boolean; isRequired?: boolean; isError?: boolean; isWarning?: boolean; contrast?: boolean }
>(theme => ({
  root: {
    ...theme.typography.textSm.medium,
    padding: 0,
    color: theme.palette.grey[700],
    display: 'flex',
    flexDirection: 'column',
    borderColor: 'red',
    justifyContent: 'flex-start',
    height: '100%',
    overflow: 'unset'
  },
  input: {
    '& .MuiOutlinedInput-root.MuiInputBase-root': {
      marginTop: theme.marginBase,

      padding: ({ isError }) => (isError ? '10px 35px 10px 14px' : '10px 14px'),
      color: 'blue',
      '& fieldset': {
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.borderRadiusBase,
        boxShadow: theme.shadowsList.xs,
        '&:not(.Mui-disabled):hover': {
          boxShadow: theme.shadowsList.inputHover
        }
      },
      '&:not(.Mui-disabled):hover': {
        color: 'blue',
        border: important('none'),
        '& fieldset': {
          boxShadow: theme.shadowsList.inputHover
        }
      },
      '&.Mui-error .MuiInputBase-input': {
        color: theme.palette.error[800]
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.grey[100]
      },
      '&.Mui-disabled .MuiInputBase-input': {
        color: theme.palette.grey[700]
      },
      '&.Mui-error': {
        '& fieldset': {
          border: important(`1px solid ${theme.palette.error[400]}`)
        }
      },
      '&.Mui-focused': {
        '& fieldset': {
          boxShadow: important(theme.shadowsList.inputFocus),
          border: important(`1px solid ${theme.palette.blue[300]}`)
        }
      },
      '& .MuiInputBase-input': {
        ...theme.typography.textMd.normal,
        padding: 0,
        color: theme.palette.grey[900],
        '&::placeholder': {
          color: ({ contrast }) => (contrast ? theme.palette.warning[300] : theme.palette.grey[600])
        }
      }
    }
  },
  asterisk: {
    color: theme.palette.error[500]
  },
  formHelperText: {
    ...theme.typography.textSm.normal,
    height: important('unset'),
    wordWrap: 'break-word',
    whiteSpace: 'break-spaces',
    margin: `6px 0`,
    color: ({ isWarning }) => (isWarning ? theme.palette.warning[600] : theme.palette.grey[600]),
    '&.Mui-error': {
      color: theme.palette.error[800]
    }
  },
  adornment: {
    position: 'absolute',
    right: 15,
    top: '50%',
    gap: '0.25rem',
    transform: 'translateY(-50%)',
    '& .MuiAutocomplete-endAdornment': {
      top: important('unset'),
      '& .MuiButtonBase-root': {
        fontSize: 'unset'
      }
    }
  },
  popupIndicator: {
    top: 'unset',
    '& .MuiAutocomplete-endAdornment': {
      top: 'unset',
      right: important('0px')
    },
    '& .MuiButtonBase-root > div > span': {
      display: 'flex'
    }
  },
  errorIcon: {
    '& > path': {
      stroke: important('rgb(240, 68, 56)')
    }
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  tooltipPopper: {
    maxWidth: 320
  },
  tooltipIcon: {
    width: important('16px'),
    height: important('16px'),
    marginLeft: 5,
    color: theme.palette.blue[500]
  },
  tooltipError: {
    '&.MuiTooltip-popper': {
      marginTop: important('20px'),
      '& .MuiTooltip-tooltip': {
        marginTop: important(0)
      }
    }
  }
}));

export default useTextInputStyles;
