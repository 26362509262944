import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function updateAddresses(address) {
  return API.address.updateAddress(address.id, address.formData, {
    ...endpointsConfig.updateAddress
  });
}

function useUpdateAddressMutation() {
  return useMutation<any, any, any>(async c => {
    const result = await Promise.allSettled(
      c.addresses.map(async address => {
        return updateAddresses(address);
      })
    );
    return result;
  });
}

export default useUpdateAddressMutation;
