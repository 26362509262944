import React from 'react';

const ResetPasswordPage = React.lazy(() => import('../anonymous/ResetPasswordPage'));
const AnonymousSetPasswordPage = React.lazy(() => import('../anonymous/AnonymousSetPasswordPage'));

export const AnonymousConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false
        },
        topBar: {
          display: false
        },
        footer: {
          display: false
        },
        leftSidePanel: {
          display: false
        },
        rightSidePanel: {
          display: false
        }
      }
    }
  },
  routes: [
    {
      path: '/reset-password',
      element: <ResetPasswordPage />,
      anonymous: true
    },
    {
      path: '/anonymous-set-password',
      element: <AnonymousSetPasswordPage />,
      anonymous: true
    }
  ]
};
