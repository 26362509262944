import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { AxiosResponse } from 'axios';

import { Dialog, GridLayout, TableLayout } from '@libs/common/v2';
import { important } from '@libs/common/v2/utils';

import { DomainDictionaryEntry } from '@libs/domain/config';
import { useFolderPermissionsQuery } from '@libs/domain/folder';

import useFormOperationActivePermissionsTable from '../hooks/useFormOperationActivePermissionsTable';

interface IProps {
  folderId: string;
  listedPermissions: string[];
  closeDialog: () => void;
}

function FormOperationActivePermissionsDialog({ folderId, listedPermissions, closeDialog }: IProps) {
  const [t] = useTranslation();
  const classes = useStyles();

  const { data } = useFolderPermissionsQuery(folderId, {
    enabled: Boolean(folderId)
  });

  const listedActivePermissions = useMemo(
    () =>
      data?.filter(
        permission =>
          permission.statusKey === DomainDictionaryEntry.PERMISSION_STATUS.ACTIVE &&
          !listedPermissions.includes(permission.formNumber)
      ),
    [data, listedPermissions]
  );

  const tableProps = useFormOperationActivePermissionsTable(listedActivePermissions);

  return (
    <Dialog
      title={t('proceeding:administrative.tab.procedureDetails.activeEntitlements')}
      onCancel={closeDialog}
      isOpen
    >
      <GridLayout itemProps={{ xs: 12 }}>
        <TableLayout
          {...tableProps}
          xlsxDownload={{
            fileName: t('proceeding:administrative.tab.formOperations.title'),
            pathToXLSXTranslation: 'proceeding:administrative.tab.formOperations',
            apiRequest: async () =>
              ({ data: { content: listedActivePermissions } } as AxiosResponse | PromiseLike<AxiosResponse>)
          }}
          isFilterEnabled={false}
          isRefreshEnabled={false}
          sectionContentClassName={classes.sectionContent}
          isSection
        />
      </GridLayout>
    </Dialog>
  );
}

const useStyles = makeStyles(() => ({
  sectionContent: {
    height: important('100%')
  }
}));

export default FormOperationActivePermissionsDialog;
