import { QueryConfig, usePaginatedQuery } from 'react-query';
import { PermanentPermissionSubmissionSearchFilter, PermanentPermissionSubmissionSnapshot } from '@ibtm/domain';

import { API } from '@libs/domain/api';
import { SocialCommissionQueryKeysEnum } from '@libs/domain/social-commission';

import { endpointsConfig } from '../endpoints-config';

export const getSocialCommissionEKMTQuery = async (_, params: PermanentPermissionSubmissionSearchFilter) => {
  const { data } = await API.civicBoard.getPermanentPermissionSubmissionSnapshotPage(params, {
    ...endpointsConfig.getPermanentPermissionSubmissionDetailsPage
  });

  return data;
};

function useSocialCommissionEKMTQuery(
  params: PermanentPermissionSubmissionSearchFilter,
  queryConfig: QueryConfig<PermanentPermissionSubmissionSnapshot, unknown> = {}
) {
  return usePaginatedQuery(
    [SocialCommissionQueryKeysEnum.EKMT_DETAILS, params],
    getSocialCommissionEKMTQuery,
    queryConfig
  );
}
export default useSocialCommissionEKMTQuery;
