import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DICTIONARY_IBTM_DOMAIN_PREFIX } from '@libs/config/constants';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  DictionarySelectField,
  GridLayout,
  Section,
  TextInputField,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { SetValuesForWarnings } from '@libs/domain/drivers/hooks';
import { IDriverForm } from '@libs/domain/drivers/model';

const getLabel = partialTranslate('drivers:fields');

function IdentityDocument({ setValuesForWarning }: { setValuesForWarning: SetValuesForWarnings }) {
  const [t] = useTranslation();
  const { watch, setValue, formMode } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);

  const issuingCountryKey = watch(typedNameV2<IDriverForm>('identityCard.issuingCountryKey')) as string;

  useEffect(() => {
    setValue('identityCard.issuingAuthority', issuingCountryKey);
  }, [issuingCountryKey, setValue]);

  const handleSeriesNumberBlur = useCallback(e => {
    setValuesForWarning('documentSeriesNumber', e.target?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section title={t('drivers:sections.identityDocument')} isCollapsable isModalSection>
      <GridLayout itemProps={{ xs: 12, sm: 6 }}>
        <DictionarySelectField
          name={typedNameV2<IDriverForm>('identityCard.typeKey')}
          label={getLabel('identityCard.typeKey')}
          dictionaryName={DomainDictionaryEnum.IDENTITY_DOCUMENT_TYPE}
          isRequired
        />

        {viewMode &&
        typeof issuingCountryKey === 'string' &&
        !issuingCountryKey?.startsWith(DICTIONARY_IBTM_DOMAIN_PREFIX) ? (
          <TextInputField
            name={typedNameV2<IDriverForm>('identityCard.issuingCountryKey')}
            label={getLabel('identityCard.issuingCountryKey')}
            isRequired
          />
        ) : (
          <DictionarySelectField
            name={typedNameV2<IDriverForm>('identityCard.issuingCountryKey')}
            label={getLabel('identityCard.issuingCountryKey')}
            dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
            isRequired
          />
        )}

        {viewMode &&
        typeof issuingCountryKey === 'string' &&
        !issuingCountryKey?.startsWith(DICTIONARY_IBTM_DOMAIN_PREFIX) ? (
          <TextInputField
            name={typedNameV2<IDriverForm>('identityCard.issuingAuthority')}
            label={getLabel('identityCard.issuingPlaceKey')}
            isRequired
          />
        ) : (
          <DictionarySelectField
            name={typedNameV2<IDriverForm>('identityCard.issuingAuthority')}
            label={getLabel('identityCard.issuingPlaceKey')}
            dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
            isRequired
            isDisabled
          />
        )}

        <TextInputField
          inputProps={{ maxLength: 20 }}
          name={typedNameV2<IDriverForm>('identityCard.seriesNumber')}
          label={getLabel('identityCard.seriesNumber')}
          onBlur={handleSeriesNumberBlur}
          isRequired
        />

        <DatepickerField
          name={typedNameV2<IDriverForm>('identityCard.issuingDate')}
          label={getLabel('identityCard.issuingDate')}
          isRequired
        />

        <DatepickerField
          name={typedNameV2<IDriverForm>('identityCard.expirationDate')}
          label={getLabel('identityCard.expirationDate')}
          isRequired
        />
      </GridLayout>
    </Section>
  );
}

export default IdentityDocument;
