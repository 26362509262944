import { InputMode } from '@libs/common/v2';

import { TextField } from '@libs/meta-form/fields';
import { FieldProps, TextFieldProperties } from '@libs/meta-form/models';
import { useElementVisibility } from '@libs/permission';

import { DomainUIElementEnum } from '@libs/domain/config';

function CabotageRemarksTextField(props: FieldProps<TextFieldProperties>) {
  const { checkIsElementVisible } = useElementVisibility();

  return (
    <TextField
      {...(!checkIsElementVisible(DomainUIElementEnum.APPLICATION_CABOTAGE_EDIT_NOTE) && {
        inputMode: InputMode.VIEW
      })}
      {...props}
    />
  );
}

export default CabotageRemarksTextField;
