import { useState } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { SubjectAddressUpdateRequestWithId } from '@ibtm/domain';
import _ from 'lodash';

import { typedNameV2, useFormV2Context } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { DomainDictionaryEntry } from '@libs/domain/config';
import {
  FolderDetailsClient,
  FolderDetailsTabEnum,
  FolderFormDataAddressTypeEnum,
  FolderQueryKeysEnum,
  useCreateFolderNoteMutation,
  useCreateTravelFormMutation,
  useEditFolderNoteMutation,
  useUpdateAddressesMutation,
  useUpdateFolderMutation,
  useUpdateSubjectContactDataMutation,
  useUpdateTravelFormMutation
} from '@libs/domain/folder';

import { AddressFormData } from '../../application/model';

const useSaveFolder = (folderType: string) => {
  const queryCache = useQueryCache();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { showSuccessSnackbar } = useSnackbar();
  const { mutate: updateFolder } = useUpdateFolderMutation();
  const { mutate: updateAddresses } = useUpdateAddressesMutation();
  const [updateSubjectContactAddresses] = useUpdateSubjectContactDataMutation();
  const { mutate: createTravelForm } = useCreateTravelFormMutation();
  const { mutate: updateTravelForm } = useUpdateTravelFormMutation();
  const { mutate: createNoteFolder } = useCreateFolderNoteMutation();
  const { mutate: editNoteFolder } = useEditFolderNoteMutation();
  const { getValues, trigger, resetField } = useFormV2Context();
  const [t] = useTranslation();

  const saveTab = (activeTab: string) => {
    setIsSubmitting(true);
    switch (activeTab) {
      case FolderDetailsTabEnum.BASIC_DATA:
        saveBasicData();
        break;
      case FolderDetailsTabEnum.SUBJECT:
        saveSubject();
        break;
      case FolderDetailsTabEnum.ADDRESSES:
        saveAddresses();
        break;
      case FolderDetailsTabEnum.ORDERS:
        saveTravelForm();
        break;
      case FolderDetailsTabEnum.EKMT:
        saveBasicData();
        break;
      case FolderDetailsTabEnum.SINGLE_AUTHORIZATION:
        saveBasicData();
        break;
      case FolderDetailsTabEnum.NOTE:
        saveNoteData();
        break;
      default:
        break;
    }
  };

  const saveNoteData = () => {
    const note = getValues('note') as string[] | string;
    const folderId = getValues('id') as string;

    if (typeof note === 'string') {
      createNoteFolder(
        { folderId, content: note },
        {
          onSuccess: () => {
            showSuccessSnackbar('Pomyślnie utworzono notatkę');
            resetField('note', { keepDirty: false });
            queryCache.invalidateQueries(FolderQueryKeysEnum.FOLDER_NOTES);
          },
          onSettled: () => {
            setIsSubmitting(false);
          }
        }
      );
    } else {
      editNoteFolder(
        { folderId, ...note },
        {
          onSuccess: () => {
            showSuccessSnackbar('Pomyśnie edytowano notatkę');
            resetField('note', { keepDirty: false });
            queryCache.invalidateQueries(FolderQueryKeysEnum.FOLDER_NOTES);
          },
          onSettled: () => {
            setIsSubmitting(false);
          }
        }
      );
    }
  };

  const saveBasicData = () => {
    const { sendCourierBlocked, version, permissionIssuingBlocked, id } = getValues() as FolderDetailsClient;

    updateFolder(
      {
        folderId: id,
        formData: {
          sendCourierBlocked,
          version,
          permissionIssuingBlocked
        }
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('folder:details.message.folderUpdated'));
          queryCache.invalidateQueries(FolderQueryKeysEnum.FOLDER_EXTENDED);
        },
        onSettled: () => {
          setIsSubmitting(false);
        }
      }
    );
  };

  const saveSubject = () => {
    trigger([
      typedNameV2<FolderDetailsClient>('subject.contact.phoneNumber'),
      typedNameV2<FolderDetailsClient>('subject.contact.email')
    ]).then(isValid => {
      if (isValid) {
        const values = getValues() as FolderDetailsClient;
        const contactData = values.subject.contact;
        updateSubjectContactAddresses(
          {
            subjectId: values.subject.id,
            formData: {
              typeKey: DomainDictionaryEntry.ADDRESS_TYPE.MAIN,
              version: values.subject?.version,
              phone: contactData.phoneNumber,
              email: contactData.email
            }
          },
          {
            onSuccess: () => {
              showSuccessSnackbar(t('folder:details.message.folderUpdated'));
              queryCache.invalidateQueries(FolderQueryKeysEnum.SUBJECT_EXTENDED);
              queryCache.invalidateQueries(FolderQueryKeysEnum.FOLDER_EXTENDED);
              queryCache.invalidateQueries(FolderQueryKeysEnum.SUBJECT_HISTORY_LIST);
            },
            onSettled: () => {
              setIsSubmitting(false);
            }
          }
        );
      } else {
        setIsSubmitting(false);
      }
    });
  };

  const saveTravelForm = () => {
    const { travelFormId, travelFormsBook, interbus, travelFormVersion, id } = getValues() as FolderDetailsClient;

    if (travelFormId) {
      updateTravelForm(
        {
          travelFormId,
          travelFormUpdateRequest: {
            travelFormsBook,
            interbus,
            version: travelFormVersion
          }
        },
        {
          onSuccess: () => {
            showSuccessSnackbar(t('folder:details.message.folderUpdated'));
            queryCache.invalidateQueries(FolderQueryKeysEnum.TRAVEL_FORM_LIST);
          },
          onSettled: () => {
            setIsSubmitting(false);
          }
        }
      );
    } else {
      createTravelForm(
        {
          travelFormCreateRequest: {
            travelFormsBook: travelFormsBook ?? false,
            interbus: interbus ?? false,
            folderId: id
          }
        },
        {
          onSuccess: () => {
            showSuccessSnackbar(t('folder:details.message.folderUpdated'));
            queryCache.invalidateQueries(FolderQueryKeysEnum.TRAVEL_FORM_LIST);
          },
          onSettled: () => {
            setIsSubmitting(false);
          }
        }
      );
    }
  };

  const getAddressNotSameAsMainAddress = (address: AddressFormData) => {
    return address.countryCodeKey && address.countryCodeKey !== DomainDictionaryEntry.COUNTRY_CODE.POLAND
      ? {
          address: {
            ..._.pick(address, ['version', 'firstLine', 'secondLine', 'postCode', 'city']),
            countryCodeKey: address?.countryCodeKey?.value || address.countryCodeKey
          }
        }
      : {
          address: {
            ..._.pick(address, [
              'version',
              'countryCode',
              'postCode',
              'postCity',
              'city',
              'street',
              'propertyNumber',
              'apartmentNumber',
              'county',
              'commune'
            ]),
            voivodeshipKey: address?.voivodeshipKey?.value,
            countryCodeKey:
              address.countryCodeKey?.value ||
              address.countryCodeKey ||
              (folderType !== DomainDictionaryEntry.FOLDER_TYPE.MZ && DomainDictionaryEntry.COUNTRY_CODE.POLAND)
          }
        };
  };

  const prepareAddressToRequest = (address: AddressFormData) => {
    const parsedTypeKey = address?.typeKey as unknown as { value: string };
    return {
      id: address.id,
      typeKey: parsedTypeKey?.value,
      sameAsMainAddress: address.sameAsMainAddress,
      ...(address.sameAsMainAddress ? {} : getAddressNotSameAsMainAddress(address))
    };
  };

  const getAddressFieldName = (
    addresType: FolderFormDataAddressTypeEnum,
    fieldName: string
  ): FieldPath<FieldValues> => {
    return `addresses.${addresType}.${fieldName}` as FieldPath<FieldValues>;
  };

  const isAddressValid = async (addresType: FolderFormDataAddressTypeEnum): Promise<boolean> => {
    return trigger(
      ['voivodeshipKey', 'postCode', 'county', 'commune', 'postCity', 'city', 'street', 'propertyNumber'].map(
        fieldName => getAddressFieldName(addresType, fieldName)
      )
    );
  };

  const areAddressesValid = (isMainAddressEditable: boolean): Promise<boolean[]> => {
    return Promise.all(
      isMainAddressEditable
        ? [
            isAddressValid(FolderFormDataAddressTypeEnum.MAIN),
            isAddressValid(FolderFormDataAddressTypeEnum.HQ),
            isAddressValid(FolderFormDataAddressTypeEnum.CORRESPONDENCE)
          ]
        : [
            isAddressValid(FolderFormDataAddressTypeEnum.HQ),
            isAddressValid(FolderFormDataAddressTypeEnum.CORRESPONDENCE)
          ]
    );
  };

  const saveAddresses = async () => {
    const { addresses, subject, isMainAddressEditable } = getValues();
    const adressesValidity = await areAddressesValid(isMainAddressEditable);
    if (adressesValidity.includes(false)) {
      setIsSubmitting(false);
    } else {
      const mainAddress = prepareAddressToRequest(
        addresses[FolderFormDataAddressTypeEnum.MAIN]
      ) as SubjectAddressUpdateRequestWithId;
      const businessAddress = prepareAddressToRequest(
        addresses[FolderFormDataAddressTypeEnum.HQ]
      ) as SubjectAddressUpdateRequestWithId;
      const correspondenceAddress = prepareAddressToRequest(
        addresses[FolderFormDataAddressTypeEnum.CORRESPONDENCE]
      ) as SubjectAddressUpdateRequestWithId;

      updateAddresses(
        {
          subjectId: subject?.id,
          addresses: isMainAddressEditable
            ? [mainAddress, businessAddress, correspondenceAddress]
            : [businessAddress, correspondenceAddress]
        },
        {
          onSuccess: () => {
            queryCache.invalidateQueries(FolderQueryKeysEnum.FOLDER_EXTENDED);
            showSuccessSnackbar(t('address:message.updateSuccess'));
          },
          onSettled: () => {
            setIsSubmitting(false);
          }
        }
      );
    }
  };

  return { isSubmitting, saveTab };
};

export default useSaveFolder;
