import { GroupCreateRequest } from '@avispon/group/dist/models';

import { useMutation } from '@libs/common/v2/api';

import API from '../endpoints';
import { endpointsConfig } from '../endpoints-config';

const createGroup = async (request: GroupCreateRequest) => {
  const { data } = await API.group.create(
    { body: request },
    {
      ...endpointsConfig.create
    }
  );
  return data;
};

export default function useCreateApplicationMutation() {
  return useMutation(createGroup);
}
