import { createContext, useContext, useMemo, useState } from 'react';

const FormDirtyContext = createContext<{ isDirty?: boolean; setIsDirty?: (value: boolean) => void }>(null);

const useFormDirtyContext = () => useContext(FormDirtyContext);

export function FormDirtyContextProvider({ children }) {
  const [isDirty, setIsDirty] = useState(false);

  const values = useMemo(() => ({ isDirty, setIsDirty }), [isDirty]);

  return <FormDirtyContext.Provider value={values}>{children}</FormDirtyContext.Provider>;
}

export default useFormDirtyContext;
