import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { queryCache } from 'react-query';
import { useSnackbar } from '@enigma/fe-ui';
import { ApplicationPaymentUpdateRequest, MoneyTransferSnapshotResponse } from '@ibtm/domain';

import { TableButton, useConfirmDialog, useFormV2Watch } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

import { useApplicationPricingQuery, useMoneyTransfersForApplicationQuery } from '../../api';
import useLinkMoneyTransfersToApplicationMutation from '../../api/mutation/useLinkMoneyTransfersToApplicationMutation';
import { PricingQueryKeysEnum } from '../../api/query/PricingQueryKeysEnum';

interface IProps {
  applicationId: string;
  onClick?: () => void;
  actionKey?: UIElementNameEnum;
  handleDiscardOrSaveFormChanges?: (action: () => void) => void;
  fieldId: string;
}

const getTotalCountOfApplicationMoneyTransfers = (applicationId: string) => {
  const query = queryCache.getQuery<MoneyTransferSnapshotResponse>(
    q =>
      q.queryKey[0] === PricingQueryKeysEnum.APPLICATION_MONEY_TRANSFERS &&
      typeof q.queryKey[1] === 'object' &&
      'applicationId' in q.queryKey[1] &&
      q.queryKey[1].applicationId === applicationId
  );
  const totalElements = query?.state?.data?.totalElements;
  return totalElements;
};

function LinkMoneyTransferButton({
  applicationId,
  onClick,
  actionKey,
  handleDiscardOrSaveFormChanges,
  fieldId
}: IProps) {
  const totalApplicationMoneyTransfers = getTotalCountOfApplicationMoneyTransfers(applicationId);
  const { data: transfersData } = useMoneyTransfersForApplicationQuery(
    { applicationId, size: totalApplicationMoneyTransfers },
    { refetchOnMount: false, enabled: totalApplicationMoneyTransfers > 0 }
  );
  const { data: pricingData } = useApplicationPricingQuery(applicationId, { refetchOnMount: false });
  const areLinkedLinkedToApplicationTransfers = useMemo(
    () => transfersData?.content.some(item => item.linkedToApplication),
    [transfersData?.content]
  );

  const { mutate: linkTransferToApplication } = useLinkMoneyTransfersToApplicationMutation();
  const pricing = pricingData ?? {};
  const requiredAmount = pricing.paymentAmount;
  const { transferPaymentAmount, content } = transfersData ?? {};
  const { accountingApproved = false } = useFormV2Watch<ApplicationPaymentUpdateRequest>({ name: fieldId }) || {};

  const isAmountEqual = requiredAmount === transferPaymentAmount;
  const { showSuccessSnackbar } = useSnackbar();
  const [t] = useTranslation();
  const [confirm] = useConfirmDialog();

  const message =
    (isAmountEqual && t('pricing:moneyTransfers.action.link.dialogMessageIfAmountEqual')) ||
    (accountingApproved && t('pricing:moneyTransfers.action.link.dialogMessageIfAmountNotEqual'));

  const isDisabled =
    !content?.map(({ id, currentApplicationAmountUsed }) => ({
      id,
      currentApplicationAmountUsed
    })).length ||
    !pricing.paymentAmount ||
    !transferPaymentAmount ||
    (!isAmountEqual && !accountingApproved) ||
    areLinkedLinkedToApplicationTransfers;

  const getDisabledTooltiptext = () => {
    switch (true) {
      case !transferPaymentAmount:
        return t('pricing:moneyTransfers.action.link.amountNotSelected');
      case isDisabled && areLinkedLinkedToApplicationTransfers:
        return t('pricing:moneyTransfers.action.link.transfersHasBeenLinked');
      default:
        return t('pricing:moneyTransfers.action.link.linkingDisableTooltip');
    }
  };
  return (
    <TableButton
      label={t('pricing:moneyTransfers.action.link.buttonTitle')}
      isDisabled={isDisabled}
      actionKey={actionKey}
      tooltipTitle={isDisabled && getDisabledTooltiptext()}
      onClick={() => {
        handleDiscardOrSaveFormChanges(() =>
          confirm({
            onConfirm: (setLoading, closeDialog) => {
              setLoading(true);
              linkTransferToApplication(
                {
                  applicationId,
                  pricingId: pricing.id,
                  // @ts-ignore TODO
                  formData: {
                    accountingApproved,
                    transferPaymentAmount
                  }
                },
                {
                  onSuccess: () => {
                    showSuccessSnackbar(t('pricing:paymentConfirmation.messages.linkTransfersSuccess'));
                    queryCache.invalidateQueries(PricingQueryKeysEnum.APPLICATION_MONEY_TRANSFERS);
                  },
                  onSettled: () => {
                    closeDialog();
                    setLoading(false);
                  }
                }
              );
              onClick?.();
            },
            title: t('pricing:moneyTransfers.action.link.dialogTitle'),
            message
          })
        );
      }}
    />
  );
}

export default LinkMoneyTransferButton;
