import { ReactNode, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { Dialog, IconButton, Section, useDialog } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';

interface IProps {
  children: ReactNode;
  componentName?: string;
  isTableErrorBoundary?: boolean;
}

interface IComponentErrorBoundaryFallback {
  error: { message: string };
}

interface IComponentStack {
  componentStack: string;
}

function ComponentErrorBoundary({ children, componentName, isTableErrorBoundary }: IProps) {
  const [t] = useTranslation();
  const { openDialog } = useDialog();
  const classes = useStyles();
  const [infoStack, setInfoStack] = useState<IComponentStack>(null);
  const errorHandler = (error: Error, info: { componentStack: string }) => {
    setInfoStack(info);
  };

  const errorTranslationKey = useMemo(
    () => (isTableErrorBoundary ? 'error.renderTableFail' : 'error.renderComponentFail'),
    [isTableErrorBoundary]
  );

  const componentErrorBoundaryFallback = ({ error }: IComponentErrorBoundaryFallback) => {
    return (
      <div role="alert">
        <span className={classes.message}>{t(errorTranslationKey, { componentName })}</span>
        <IconButton
          icon="InfoIcon"
          onClick={() => {
            openDialog(({ closeDialog }) => (
              <Dialog
                title={t(errorTranslationKey, { componentName })}
                onCancel={closeDialog}
                onClose={closeDialog}
                isOpen
              >
                <Section titleHeaderContent={error?.message} isCollapsable>
                  {infoStack?.componentStack}
                </Section>
              </Dialog>
            ));
          }}
          isBackgroundTransparent
        />
      </div>
    );
  };

  return (
    <ErrorBoundary FallbackComponent={componentErrorBoundaryFallback} onError={errorHandler}>
      {children}
    </ErrorBoundary>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  message: {
    color: theme.palette.error[600]
  }
}));

export default ComponentErrorBoundary;
