import { useCallback } from 'react';

import { useDialog } from '@libs/common/v2';

import { DictionaryEntryTypeEnum } from '@libs/dictionary';

import { EditLicenseDialog, IUpdateLicenseRequest } from '../../../components/EditLicenseDialog';

export function useEditLicenseDialog() {
  const { openDialog } = useDialog();

  const showDialog = useCallback(
    (
      initialData: IUpdateLicenseRequest,
      onSuccess: () => void,
      licenseValidityYearsDictionary: keyof DictionaryEntryTypeEnum
    ) => {
      openDialog(({ closeDialog }) => (
        <EditLicenseDialog
          closeDialog={closeDialog}
          licenseValidityYearsDictionary={licenseValidityYearsDictionary}
          initialData={initialData}
          onSuccess={onSuccess}
        />
      ));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog]
  );

  const openEditLicenseDialog = (
    initialData: IUpdateLicenseRequest,
    onSuccess: () => void,
    licenseValidityYearsDictionary: keyof DictionaryEntryTypeEnum
  ) => {
    showDialog(initialData, onSuccess, licenseValidityYearsDictionary);
  };

  return {
    openEditLicenseDialog
  };
}
