import { ProxySnapshot as ProxySnapshotClient } from '@ibtm/client-domain';
import { ProxySnapshot } from '@ibtm/domain';
import { CalendarDate } from 'calendar-date';
import { array as YupArray, boolean as YupBoolean, object as YupObject, ObjectSchema, string as YupString } from 'yup';

type AuthorizationType = { value: string; name: string; active: boolean };

export const getValidationSchema = (
  isPostCityRequired: boolean,
  isCitizenshipFieldRequired?: boolean,
  isAuthorizationFieldVisible?: boolean
): ObjectSchema<ProxyFields> =>
  YupObject({
    notes: YupString().nullable(),
    name: YupString().nullable().required().max(50),
    surname: YupString().nullable().required(),
    typeKey: YupObject().required().nullable(),
    citizenship: isCitizenshipFieldRequired ? YupObject().required().nullable() : YupObject().nullable(),
    paid: YupBoolean().nullable().required(),
    authorizations: isAuthorizationFieldVisible
      ? YupArray<AuthorizationType>().required()
      : YupArray<AuthorizationType>().nullable(),
    postCode: YupString().nullable().required(),
    city: YupString().nullable().required().max(50),
    street: YupString().nullable().required().max(70),
    propertyNumber: YupString().nullable().required(),
    apartmentNumber: YupString().nullable(),
    postCity: isPostCityRequired ? YupString().nullable().required().max(50) : YupString().nullable().max(50)
  });

export type ProxyActions = 'create' | 'details' | 'edit' | 'delete';

export type ProxyFields = {
  /**
   * Imię
   */
  name?: string;
  /**
   * Nazwisko
   */
  surname?: string;
  /**
   * Rodzaj pełnomocnictwa
   */
  typeKey?: {
    value?: string;
  };
  /**
   * Pełnomocnictwo opłacone
   */
  paid?: boolean;
  /**
   * Obywatelstwo
   */
  citizenship?: {
    value?: string;
  };
  /**
   * Uwagi
   */
  notes?: string;
  /**
   * Kod pocztowy
   */
  postCode?: string;
  /**
   * Poczta
   */
  postCity?: string;
  /**
   * Miejscowość
   */
  city?: string;
  /**
   * Ulica
   */
  street?: string;
  /**
   * Nr budynku
   */
  propertyNumber?: string;
  /**
   * Nr lokalu
   */
  apartmentNumber?: string;
  /**
   * Ważne do
   */
  validUntil?: CalendarDate;
  /**
   * Data wprowadzenia
   */
  created?: string;
  /**
   * Wprowadził
   */
  author?: string;
  /**
   * Data edycji
   */
  modified?: string;
  /**
   * Edytował
   */
  modifier?: string;
  /**
   * Uprawnienia
   */
  authorizations?: Array<AuthorizationType>;
  /**
   * Wersja
   */
  version?: number;
};

export interface ProxyClient extends ProxySnapshot, ProxySnapshotClient {
  authorizationKey?: string;
  archiveNumber?: string;
}
