import { DriversSearchFilter } from '@ibtm/domain';

import { useDialog } from '@libs/common/v2';

import { DriverSearchDialog } from '@libs/domain/drivers/components';
import { IDriverTableOptions } from '@libs/domain/drivers/hooks/tables/useDriversTable';

function useOpenDriverSearchDialog() {
  const { openDialog } = useDialog();
  const openDriverSearchDialog = ({
    applicationId,
    folderId,
    searchParams,
    tableOptions,
    transferredFoldersIds
  }: {
    applicationId?: string;
    folderId?: string;
    transferredFoldersIds?: string[];
    tableOptions?: IDriverTableOptions;
    searchParams?: DriversSearchFilter;
  }) =>
    openDialog(({ closeDialog }) => (
      <DriverSearchDialog
        closeDialog={closeDialog}
        folderId={folderId}
        transferredFoldersIds={transferredFoldersIds}
        applicationId={applicationId}
        tableOptions={tableOptions}
        searchParams={searchParams}
      />
    ));

  return {
    openDriverSearchDialog
  };
}

export default useOpenDriverSearchDialog;
