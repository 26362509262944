import { useDialog } from '@libs/common/v2';

import { MoneyTransferAddToApplicationDialog } from '../components';

function useMoneyTransferAddToApplicationDialog() {
  const { openDialog } = useDialog();

  const showDialog = ({ applicationId, onSuccess }: { applicationId: string; onSuccess: () => void }) => {
    openDialog(({ closeDialog }) => (
      <MoneyTransferAddToApplicationDialog
        applicationId={applicationId}
        onCloseDialog={closeDialog}
        onSuccess={onSuccess}
        isOpen
      />
    ));
  };

  return { showMoneyTransferAddToApplicationDialog: showDialog };
}

export default useMoneyTransferAddToApplicationDialog;
