enum DomainDictionaryEnum {
  ARCHIVE_CATEGORY_DESCRIPTION = 'ARCHIVE_CATEGORY_DESCRIPTION',
  ABSENCES_IN_ATTACHMENT = 'ABSENCES_IN_ATTACHMENT',
  ADDITIONAL_SERVICES = 'ADDITIONAL_SERVICES',
  ADDRESS_TYPE = 'ADDRESS_TYPE',
  APPLICATION_CANCEL_REASON = 'APPLICATION_CANCEL_REASON',
  APPLICATION_CATEGORY = 'APPLICATION_CATEGORY',
  AUTHORITIES_OF_OTHER_COUNTRIES = 'AUTHORITIES_OF_OTHER_COUNTRIES',
  APPLICATION_VEHICLE_CONCERNS_TYPE = 'APPLICATION_VEHICLE_CONCERNS_TYPE',
  APPLICATION_CHANGE = 'APPLICATION_CHANGE',
  APPLICATION_CHANNEL = 'APPLICATION_CHANNEL',
  APPLICATION_FINANCIAL_SECURITY_MODIFICATION_TYPE = 'APPLICATION_FINANCIAL_SECURITY_MODIFICATION_TYPE',
  APPLICATION_EXPLOITATION_BASE_MODIFICATION_TYPE = 'APPLICATION_EXPLOITATION_BASE_MODIFICATION_TYPE',
  APPLICATION_STATUS = 'APPLICATION_STATUS',
  APPLICATION_TRANSPORT_MANAGER_MODIFICATION_TYPE = 'APPLICATION_TRANSPORT_MANAGER_MODIFICATION_TYPE',
  APPLICATION_TYPE = 'APPLICATION_TYPE',
  APPLICATION_TYPE_WL = 'APPLICATION_TYPE_WL',
  ARCHIVE_NOTE = 'ARCHIVE_NOTE',
  ATTACHMENTS_TO_APPLICATION = 'ATTACHMENTS_TO_APPLICATION',
  AUTHORITY = 'AUTHORITY',
  AUTHORITY_INSTITUTING_PROCEEDINGS = 'AUTHORITY_INSTITUTING_PROCEEDINGS',
  BACK_FROM_STAROSTY_REASON = 'BACK_FROM_STAROSTY_REASON',
  BP_DECISION = 'BP_DECISION',
  CERTIFICATE_S_STATUS = 'CERTIFICATE_S_STATUS',
  CERTIFICATE_TYPE = 'CERTIFICATE_TYPE',
  CITIZENSHIP = 'CITIZENSHIP',
  CLASSIFICATION_KEY = 'CLASSIFICATION_KEY',
  COMPETENCE = 'COMPETENCE',
  COUNTRY = 'COUNTRY',
  COUNTRY_CODE = 'COUNTRY_CODE',
  CURRENCY = 'CURRENCY',
  CURRENCY_CODE = 'CURRENCY_CODE',
  DECISION_SETTLEMENT = 'DECISION_SETTLEMENT',
  DECISION_TYPE = 'DECISION_TYPE',
  DEPARTMENT = 'DEPARTMENT',
  DEPOT_TRANSFER_OPERATION_STATE = 'DEPOT_TRANSFER_OPERATION_STATE',
  DEPOT_TYPE = 'DEPOT_TYPE',
  DURATION_OF_PERMIT = 'DURATION_OF_PERMIT',
  DURATION_OF_PERMIT_SPO = 'DURATION_OF_PERMIT_SPO',
  DOCUMENT_STATUS = 'DOCUMENT_STATUS',
  DOCUMENT_TEMPLATE_GROUP = 'DOCUMENT_TEMPLATE_GROUP',
  DOCUMENT_TEMPLATE_KEY = 'DOCUMENT_TEMPLATE_KEY',
  DOCUMENT_TYPE = 'DOCUMENT_TYPE',
  DRIVER_CERTIFICATE_STATUS = 'DRIVER_CERTIFICATE_STATUS',
  DRIVER_CERTIFICATE_TYPE_KEY = 'PROXY_TYPE',
  DUPLICATE_CAUSE_DEPENDENT_ON_SUBJECT = 'DUPLICATE_CAUSE_DEPENDENT_ON_SUBJECT',
  EMPLOYMENT_DOCUMENT = 'EMPLOYMENT_DOCUMENT',
  ENABLEMENT_TYPE = 'ENABLEMENT_TYPE',
  EURO_CLASS = 'EURO_CLASS',
  EKMT_CATEGORY = 'EKMT_CATEGORY',
  EURO_CLASS_ZZ = 'EURO_CLASS_ZZ',
  ERROR = 'ERROR',
  EXPLOITATION_BASE_INSPECTION_RESULT = 'EXPLOITATION_BASE_INSPECTION_RESULT',
  EXPLOITATION_BASE_STATUS = 'EXPLOITATION_BASE_STATUS',
  EXPLOITATION_BASE_TYPE = 'EXPLOITATION_BASE_TYPE',
  EZD_DOCUMENT_STATUS = 'EZD_DOCUMENT_STATUS',
  EZD_DOCUMENT_TYPE = 'EZD_DOCUMENT_TYPE',
  FINANCE_SECURITY_STATUS = 'FINANCE_SECURITY_STATUS',
  FINANCE_SECURITY_TYPE = 'FINANCE_SECURITY_TYPE',
  FINANCE_SECURITY_KIND_TP_AND_OP = 'FINANCE_SECURITY_KIND_TP_AND_OP',
  FINANCE_SECURITY_KIND_PPO = 'FINANCE_SECURITY_KIND_PPO',
  FOLDER_BORROW_PURPOSE = 'FOLDER_BORROW_PURPOSE',
  FOLDER_CHANGE = 'FOLDER_CHANGE',
  FOLDER_DETAILS = 'FOLDER_DETAILS',
  FOLDER_LOCK_TYPE = 'FOLDER_LOCK_TYPE',
  FOLDER_LOCALIZATION = 'FOLDER_LOCALIZATION',
  FOLDER_LOCALIZATION_RENTALS = 'FOLDER_LOCALIZATION_RENTALS',
  FOLDER_LIMIT_CALCULATION_RESPONSE = 'FOLDER_LIMIT_CALCULATION_RESPONSE',
  FOLDER_PERMISSION_STATUS = 'FOLDER_PERMISSION_STATUS',
  FOLDER_STATUS = 'FOLDER_STATUS',
  FOLDER_STATUS_RENTALS = 'FOLDER_STATUS_RENTALS',
  FOLDER_TYPE = 'FOLDER_TYPE',
  FOREIGN_PERMIT_TYPE = 'FOREIGN_PERMIT_TYPE',
  FORM_NAME = 'FORM_NAME',
  FORM_OPERATION_REASON = 'FORM_OPERATION_REASON',
  FORM_OPERATION_STATUS = 'FORM_OPERATION_STATUS',
  FORM_OPERATION_TYPE = 'FORM_OPERATION_TYPE',
  FREQUENCY_TYPE = 'FREQUENCY_TYPE',
  GOOD_REPUTATION_KREPTD_STATUS = 'GOOD_REPUTATION_KREPTD_STATUS',
  GENERATOR_ASSIGNMENT_TYPE = 'GENERATOR_ASSIGNMENT_TYPE',
  HISTORY_STATUS = 'HISTORY_STATUS',
  IDENTITY_CARD_TYPE_KEY = 'PROXY_TYPE',
  IDENTITY_DOCUMENT_TYPE = 'IDENTITY_DOCUMENT_TYPE',
  JRWA_CLASSIFICATION = 'JRWA_CLASSIFICATION',
  LEGAL_NATURE = 'LEGAL_NATURE',
  LICENSE_APPLICATION_ISSUED_PERMISSIONS = 'LICENSE_APPLICATION_ISSUED_PERMISSIONS',
  LICENSE_CANCELLATION_REASON = 'LICENSE_CANCELLATION_REASON',
  LICENSE_TYPE = 'LICENSE_TYPE',
  LICENSE_APPLICATION_ISSUED_PERMISSIONS_DUPLICATE_OP = 'LICENSE_APPLICATION_ISSUED_PERMISSIONS_DUPLICATE_OP',
  LICENSE_APPLICATION_ISSUED_PERMISSIONS_DUPLICATE_TP = 'LICENSE_APPLICATION_ISSUED_PERMISSIONS_DUPLICATE_TP',
  LICENSE_VALIDITY_YEARS = 'LICENSE_VALIDITY_YEARS',
  LICENSE_TRANSFER_TYPE = 'LICENSE_TRANSFER_TYPE',
  LICENSE_VALIDITY_YEARS_TP_OP = 'LICENSE_VALIDITY_YEARS_TP_OP',
  LICENSE_VALIDITY_YEARS_PPO = 'LICENSE_VALIDITY_YEARS_PPO',
  LICENSE_VALIDITY_YEARS_TRANSFER = 'LICENSE_VALIDITY_YEARS_TRANSFER',
  LOCK_STATUS = 'LOCK_STATUS',
  LOCK_TYPE = 'LOCK_TYPE',
  LOSS_CAUSE = 'LOSS_CAUSE',
  NSA_DECISION = 'NSA_DECISION',
  PARTNER_APPLICANT = 'PARTNER_APPLICANT',
  PERIOD_OF_ISSUING_DRIVERS_CERTIFICATE = 'PERIOD_OF_ISSUING_DRIVERS_CERTIFICATE',
  PERMISSION = 'PERMISSION',
  PERMISSION_CANCELLATION_REASON = 'PERMISSION_CANCELLATION_REASON',
  PERMISSION_NAME = 'PERMISSION_NAME',
  PERMISSION_RELATIONS = 'PERMISSION_RELATIONS',
  PERMISSION_RESTORE_FROM_DISTRICT_AUTHORITY_REASON = 'PERMISSION_RESTORE_FROM_DISTRICT_AUTHORITY_REASON',
  PERMIT_MODIFICATION_TYPE_UE_WB_OUTSIDE_UE = 'PERMIT_MODIFICATION_TYPE_UE_WB_OUTSIDE_UE',
  PERMISSION_STATUS = 'PERMISSION_STATUS',
  PERMISSION_TYPE = 'PERMISSION_TYPE',
  PERMIT_APPLICATION_STATUS_ZZ = 'PERMIT_APPLICATION_STATUS_ZZ',
  PERMIT_MODIFICATION_TYPE_OPINIONS = 'PERMIT_MODIFICATION_TYPE_OPINIONS',
  PERMIT_MODIFICATION_TYPE_TRANSIT = 'PERMIT_MODIFICATION_TYPE_TRANSIT',
  PERMIT_MODIFICATION_TYPE_OCCASIONAL = 'PERMIT_MODIFICATION_TYPE_OCCASIONAL',
  POLISH_RECIPIENTS_DELIVERY_METHOD = 'POLISH_RECIPIENTS_DELIVERY_METHOD',
  PROCEDURE = 'PROCEDURE',
  PROCEEDING_DECISION = 'PROCEEDING_DECISION',
  PROCEEDING_INACTIVITY_REASON = 'PROCEEDING_INACTIVITY_REASON',
  PROCEEDING_KIND = 'PROCEEDING_KIND',
  PROCEEDING_STATUS = 'PROCEEDING_STATUS',
  PROCEEDING_STATUS_KREPTD = 'PROCEEDING_STATUS_KREPTD',
  PROCEEDING_TYPE = 'PROCEEDING_TYPE',
  PROCEEDING_TYPE_KREPTD = 'PROCEEDING_TYPE_KREPTD',
  PROCESS_MESSAGE_CONFIRMATION_TEXT = 'PROCESS_MESSAGE_CONFIRMATION_TEXT',
  PROCESS_MESSAGE_LABEL = 'PROCESS_MESSAGE_LABEL',
  PROCESS_TASK_MAIN_ACTION_CONFIRMATION_TEXT = 'PROCESS_TASK_MAIN_ACTION_CONFIRMATION_TEXT',
  PROCESS_TASK_MAIN_ACTION_NAME = 'PROCESS_TASK_MAIN_ACTION_NAME',
  PROXY_TYPE = 'PROXY_TYPE',
  REASONS_FOR_REPRINTING = 'REASONS_FOR_REPRINTING',
  CORRECTIONS_OF_RATIONING_FOREIGN_PERMITS = 'CORRECTIONS_OF_RATIONING_FOREIGN_PERMITS',
  REASONS_FOR_CORRECTIONS_OF_ISSUED_FOREIGN_PERMITS = 'REASONS_FOR_CORRECTIONS_OF_ISSUED_FOREIGN_PERMITS',
  RECEIPT_TYPE = 'RECEIPT_TYPE',
  RECEIPT_TYPE_PK = 'RECEIPT_TYPE_PK',
  REPUTATION = 'REPUTATION',
  REPUTATION_QUERY_STATUS = 'REPUTATION_QUERY_STATUS',
  REPUTATION_REQUEST_STATUS = 'REPUTATION_REQUEST_STATUS',
  REPUTATION_STATUS = 'REPUTATION_STATUS',
  REQUIRED_FILE_STATUS = 'REQUIRED_FILE_STATUS',
  RESOURCE_FORM_DISPOSAL_REASON = 'RESOURCE_FORM_DISPOSAL_REASON',
  RESOURCE_FORM_GROUP = 'RESOURCE_FORM_GROUP',
  RESOURCE_FORM_NAME = 'RESOURCE_FORM_NAME',
  RESOURCE_TYPE_TRANSIT_TYPE = 'RESOURCE_TYPE_TRANSIT_TYPE',
  RESOURCE_FORM_ORDER_DIRECTION = 'RESOURCE_FORM_ORDER_DIRECTION',
  RESOURCE_FORM_ORDER_STATUS = 'RESOURCE_FORM_ORDER_STATUS',
  RESOURCE_FORM_STATE = 'RESOURCE_FORM_STATE',
  RESOURCE_FORM_TYPE = 'RESOURCE_FORM_TYPE',
  RESOURCE_OBJECT_ORDER_OPERATION = 'RESOURCE_OBJECT_ORDER_OPERATION',
  RETURNS_OF_FOREIGN_LICENSES = 'RETURNS_OF_FOREIGN_LICENSES',
  REPORT_STATUS = 'REPORT_STATUS',
  SCHEDULE_TYPE = 'SCHEDULE_TYPE',
  SCOPES_OF_FOREIGN_PERMITS_AUTHORIZATIONS = 'SCOPES_OF_FOREIGN_PERMITS_AUTHORIZATIONS',
  SEND_OPTION = 'SEND_OPTION',
  SECOND_TIER_DECISION = 'SECOND_TIER_DECISION',
  STOP_ADDRESS = 'STOP_ADDRESS',
  STOP_AGREEMENT_TYPE = 'STOP_AGREEMENT_TYPE',
  STOP_TYPE = 'STOP_TYPE',
  SUBJECT_PARTNERSHIP_PARTNER_PERMISSIONS = 'SUBJECT_PARTNERSHIP_PARTNER_PERMISSIONS',
  SUBJECT_TYPE = 'SUBJECT_TYPE',
  SUBMISSION_CHANNEL = 'SUBMISSION_CHANNEL',
  SUSPENSION_STATUS = 'SUSPENSION_STATUS',
  TRANSIT_DIRECTION = 'TRANSIT_DIRECTION',
  TRANSIT_LOCATION = 'TRANSIT_LOCATION',
  TRANSIT_LINE_TYPE = 'TRANSIT_LINE_TYPE',
  TRANSIT_TYPE = 'TRANSIT_TYPE',
  TRANSPORT_TYPE = 'TRANSPORT_TYPE',
  TRANSPORT_MANAGER = 'TRANSPORT_MANAGER',
  TRANSPORT_MANAGER_CERTIFICATE_TYPE = 'TRANSPORT_MANAGER_CERTIFICATE_TYPE',
  TRANSPORT_MANAGER_CERTIFICATE_STATUS = 'TRANSPORT_MANAGER_CERTIFICATE_STATUS',
  TRANSPORT_MANAGER_STATUS = 'TRANSPORT_MANAGER_STATUS',
  TRANSPORT_MANAGER_TYPE = 'TRANSPORT_MANAGER_TYPE',
  TYPES_OF_PROCEEDING = 'TYPES_OF_PROCEEDING',
  TYPES_OF_PROCEEDINGS = 'TYPES_OF_PROCEEDINGS',
  USAGE_METHOD_FOR_FOREIGN_PERMIT = 'USAGE_METHOD_FOR_FOREIGN_PERMIT',
  VEHICLE_BRAND = 'VEHICLE_BRAND',
  VEHICLE_ECOLOGY = 'VEHICLE_ECOLOGY',
  VEHICLE_OWNERSHIP_TYPE = 'VEHICLE_OWNERSHIP_TYPE',
  VEHICLE_STATUS = 'VEHICLE_STATUS',
  VEHICLE_SUBMITTED_TO = 'VEHICLE_SUBMITTED_TO',
  VEHICLE_TYPE = 'VEHICLE_TYPE',
  VOIVODESHIP = 'VOIVODESHIP',
  VALIDITY_PERIOD_FOR_OWN_NEEDS = 'VALIDITY_PERIOD_FOR_OWN_NEEDS',
  WITHDRAWAL_DRIVER_CERTIFICATE_KREPTD = 'WITHDRAWAL_DRIVER_CERTIFICATE_KREPTD',
  WSA_DECISION = 'WSA_DECISION',
  INVOICE_STATUS = 'INVOICE_STATUS',
  INVOICE_CORRECTION_REASON = 'INVOICE_CORRECTION_REASON',
  PERMISSION_TYPE_CODE = 'PERMISSION_TYPE_CODE',
  INVOICE_ITEM_SETTLEMENT_TYPE = 'INVOICE_ITEM_SETTLEMENT_TYPE',
  LEGAL_GROUNDS_FOR_PROCEEDINGS = 'LEGAL_GROUNDS_FOR_PROCEEDINGS',
  ARCHIVE_TYPE = 'ARCHIVE_TYPE',
  WSA_AND_NSA_DECISION = 'WSA_AND_NSA_DECISION',
  DECISION_PERMISSION_TYPE = 'DECISION_PERMISSION_TYPE',
  REMARK_SK = 'REMARK_SK',
  LEGAL_NATURE_KEY = 'LEGAL_NATURE_KEY',
  GENERAL_DICTIONARY = 'GENERAL_DICTIONARY',
  FOLDER_CHANGE_REASON_TYPE = 'FOLDER_CHANGE_REASON_TYPE',
  APPLICATION_NAME_ADDRESS_CHANGE_CONCERNS_TYPE = 'APPLICATION_NAME_ADDRESS_CHANGE_CONCERNS_TYPE'
}

export default DomainDictionaryEnum;
