import { useContext } from 'react';
import { Controller } from 'react-hook-form';

import { DictionaryEntryTypeEnum, useDictionaryTranslations } from '@libs/dictionary';

import { FormV2Context, FormV2ContextState, Value } from '../../..';

interface IProps {
  name: string;
  dictionaryName?: keyof DictionaryEntryTypeEnum;
}

export function ValueMatrix({ name, dictionaryName }: IProps) {
  const { translate } = useDictionaryTranslations();
  const { control, loading } = useContext<FormV2ContextState>(FormV2Context);

  const getValue = (value: string) => {
    if (loading) {
      return '';
    }
    if (dictionaryName) {
      return translate(dictionaryName, value);
    }
    return value;
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value } }) => {
        return <Value>{getValue(value)}</Value>;
      }}
    />
  );
}
