import React from 'react';
import { Row } from 'react-table';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { ButtonLinkDefinition, IconButton, IconType, Theme, useTableAdapter, useTableContext } from '@libs/common/v2';
import { important } from '@libs/common/v2/utils/styles.utils';

import { UIElementNameEnum } from '@libs/permission';

interface IProps {
  onClick?: React.MouseEventHandler;
  tooltipTitle?: string;
  icon: IconType;
  row?: Row<Record<string, any>>;
  className?: string;
  children?: React.ReactNode;
  iconClassName?: string;
  isDisabled?: boolean;
  isBackgroundTransparent?: boolean;
  isButtonLoading?: boolean;
  actionKey?: UIElementNameEnum;
  link?: ButtonLinkDefinition;
  isActive?: boolean;
  wrapperClassName?: string;
}

function TableIconButton(
  {
    iconClassName,
    children,
    icon,
    row,
    tooltipTitle,
    className,
    isDisabled,
    isBackgroundTransparent = true,
    isButtonLoading,
    onClick,
    actionKey,
    link,
    isActive,
    wrapperClassName
  }: IProps,
  forwardRef
) {
  const table = useTableAdapter();
  const { isLoading, tableLoading, checkDisabled, isError } = useTableContext();
  const classes = useIconButtonStyles();

  const loading = isLoading || tableLoading || isButtonLoading;

  return (
    <IconButton
      ref={forwardRef}
      tooltipTitle={tooltipTitle}
      onClick={onClick}
      className={clsx(classes.root, className)}
      wrapperClassName={wrapperClassName}
      iconClassName={iconClassName}
      icon={icon}
      isLoading={isButtonLoading}
      isDisabled={isDisabled || checkDisabled?.(table, row) || isError || loading}
      height={20}
      width={20}
      isBackgroundTransparent={isBackgroundTransparent}
      link={link}
      actionKey={actionKey}
    >
      {isActive && <span className={classes.active} />}
      {children}
    </IconButton>
  );
}

const useIconButtonStyles = makeStyles<Theme>(theme => ({
  root: {
    '&:hover': {
      backgroundColor: 'rgba(152, 162, 179, 0.5)'
    },
    '& .MuiButtonBase-root:hover': {
      backgroundColor: important('transparent')
    },
    '& .MuiButtonBase-root > span:hover': {
      backgroundColor: important('transparent')
    },
    '& .MuiButtonBase-root > span > svg:hover': {
      backgroundColor: important('transparent')
    }
  },
  active: {
    position: 'absolute',
    marginLeft: 12,
    marginTop: -12,
    width: important('9px'),
    height: important('9px'),
    zIndex: 10,
    borderRadius: '50%',
    backgroundColor: theme.palette.blue[800]
  }
}));

export default React.memo(React.forwardRef(TableIconButton));
