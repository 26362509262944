import { QueryConfig, useQuery } from 'react-query';
import { ApplicationVerificationDetails } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import ApplicationQueryKeysEnum from './ApplicationQueryKeysEnum';

export const verifyApplication = async (_, applicationId: string) => {
  const { data } = await API.application.verify(applicationId, {
    ...endpointsConfig.verify
  });
  return data;
};

export default function useApplicationVerifyQuery(
  applicationId: string,
  queryConfig: QueryConfig<ApplicationVerificationDetails, unknown> = {}
) {
  return useQuery<ApplicationVerificationDetails>(
    [ApplicationQueryKeysEnum.APPLICATION_VERIFY, applicationId],
    verifyApplication,
    queryConfig
  );
}
