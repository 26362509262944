export enum PermissionEnum {
  /** Wyświetlenie listy teczek */
  IBTM_DOMAIN_FOLDER_LIST_VIEW = 'group.permission.ibtm-domain.folder.list.view',
  /** Wyświetlenie szczegółów teczki	 */
  IBTM_DOMAIN_FOLDER_DETAILS_VIEW = 'group.permission.ibtm-domain.folder.details.view',
  /** Dodanie teczki */
  IBTM_DOMAIN_FOLDER_CREATE = 'group.permission.ibtm-domain.folder.create',
  /** Dodanie teczki TP */
  IBTM_DOMAIN_FOLDER_TP_CREATE = 'group.permission.ibtm-domain.folder.tp.create',
  /** Dodanie teczki OP */
  IBTM_DOMAIN_FOLDER_OP_CREATE = 'group.permission.ibtm-domain.folder.op.create',
  /** Dodanie teczki PPO */
  IBTM_DOMAIN_FOLDER_PPO_CREATE = 'group.permission.ibtm-domain.folder.ppo.create',
  /** Dodanie teczki MZ */
  IBTM_DOMAIN_FOLDER_MZ_CREATE = 'group.permission.ibtm-domain.folder.mz.create',
  /** Dodanie teczki PA */
  IBTM_DOMAIN_FOLDER_PA_CREATE = 'group.permission.ibtm-domain.folder.pa.create',
  /** Dodanie teczki KS */
  IBTM_DOMAIN_FOLDER_KS_CREATE = 'group.permission.ibtm-domain.folder.ks.create',
  /** Dodanie teczki WITD */
  IBTM_DOMAIN_FOLDER_WITD_CREATE = 'group.permission.ibtm-domain.folder.witd.create',
  /** Dodanie teczki ZZ */
  IBTM_DOMAIN_FOLDER_ZZ_CREATE = 'group.permission.ibtm-domain.folder.zz.create',
  /** Edycja teczki */
  IBTM_DOMAIN_FOLDER_UPDATE = 'group.permission.ibtm-domain.folder.update',
  /** Edycja teczki TP */
  IBTM_DOMAIN_FOLDER_TP_UPDATE = 'group.permission.ibtm-domain.folder.tp.update',
  /** Edycja teczki OP */
  IBTM_DOMAIN_FOLDER_OP_UPDATE = 'group.permission.ibtm-domain.folder.op.update',
  /** Edycja teczki PPO */
  IBTM_DOMAIN_FOLDER_PPO_UPDATE = 'group.permission.ibtm-domain.folder.ppo.update',
  /** Edycja teczki MZ */
  IBTM_DOMAIN_FOLDER_MZ_UPDATE = 'group.permission.ibtm-domain.folder.mz.update',
  /** Edycja teczki PA */
  IBTM_DOMAIN_FOLDER_PA_UPDATE = 'group.permission.ibtm-domain.folder.pa.update',
  /** Edycja teczki KS */
  IBTM_DOMAIN_FOLDER_KS_UPDATE = 'group.permission.ibtm-domain.folder.ks.update',
  /** Edycja teczki WITD */
  IBTM_DOMAIN_FOLDER_WITD_UPDATE = 'group.permission.ibtm-domain.folder.witd.update',
  /** Edycja teczki ZZ */
  IBTM_DOMAIN_FOLDER_ZZ_UPDATE = 'group.permission.ibtm-domain.folder.zz.update',
  /** Wyświetlenie szczegółów teczki "TP" */
  IBTM_DOMAIN_FOLDER_TP_DETAILS_VIEW = 'group.permission.ibtm-domain.folder.tp.details.view',
  /** Wyświetlenie szczegółów teczki KS */
  IBTM_DOMAIN_FOLDER_KS_DETAILS_VIEW = 'group.permission.ibtm-domain.folder.ks.details.view',
  /** Wyświetlenie szczegółów teczki ZZ */
  IBTM_DOMAIN_FOLDER_ZZ_DETAILS_VIEW = 'group.permission.ibtm-domain.folder.zz.details.view',
  /** Wyświetlenie szczegółów teczki WITD */
  IBTM_DOMAIN_FOLDER_WITD_DETAILS_VIEW = 'group.permission.ibtm-domain.folder.witd.details.view',
  /** Wyświetlenie szczegółów teczki "OP" */
  IBTM_DOMAIN_FOLDER_OP_DETAILS_VIEW = 'group.permission.ibtm-domain.folder.op.details.view',
  /** Wyświetlenie szczegółów teczki "PPO" */
  IBTM_DOMAIN_FOLDER_PPO_DETAILS_VIEW = 'group.permission.ibtm-domain.folder.ppo.details.view',
  /** Wyświetlenie szczegółów teczki "MZ" */
  IBTM_DOMAIN_FOLDER_MZ_DETAILS_VIEW = 'group.permission.ibtm-domain.folder.mz.details.view',
  /** Wyświetlenie szczegółów teczki "PA" */
  IBTM_DOMAIN_FOLDER_PA_DETAILS_VIEW = 'group.permission.ibtm-domain.folder.pa.details.view',
  /** Wyświetlenie podstawowych informacji o teczce KS */
  IBTM_DOMAIN_FOLDER_KS_BASIC_INFORMATION_VIEW = 'group.permission.ibtm-domain.folder.ks.basic-information.view',
  /** Wyświetlenie podstawowych informacji o teczce ZZ */
  IBTM_DOMAIN_FOLDER_ZZ_BASIC_INFORMATION_VIEW = 'group.permission.ibtm-domain.folder.zz.basic-information.view',
  /** Wyświetlenie podstawowych informacji o teczce WITD */
  IBTM_DOMAIN_FOLDER_WITD_BASIC_INFORMATION_VIEW = 'group.permission.ibtm-domain.folder.witd.basic-information.view',
  /** Wyświetlenie informacji podstawowych o teczce MZ */
  IBTM_DOMAIN_FOLDER_MZ_BASIC_INFORMATION_VIEW = 'group.permission.ibtm-domain.folder.mz.basic-information.view',
  /** Wyświetlenie danych podstawowych o teczce */
  // _ = 'group.permission..'	,
  /** Wyświetlenie informacji podstawowych o teczce */
  IBTM_DOMAIN_FOLDER_BASIC_INFORMATION_VIEW = 'group.permission.ibtm-domain.folder.basic-information.view',
  /** Wyświetlenie podmiotu powiązanego z teczką */
  IBTM_DOMAIN_FOLDER_SUBJECT_VIEW = 'group.permission.ibtm-domain.folder.subject.view',
  /** Wyświetlenie listy adresów podmiotu z teczki */
  IBTM_DOMAIN_FOLDER_ADDRESS_VIEW = 'group.permission.ibtm-domain.folder.address.view',
  /** Wyświetlenie listy zarządzających transportem z teczki */
  IBTM_DOMAIN_FOLDER_TRANSPORT_MANAGER_VIEW = 'group.permission.ibtm-domain.folder.transport-manager.view',
  /** Wyświetlenie listy zabezpieczeń finansowych z teczki */
  IBTM_DOMAIN_FOLDER_FINANCIAL_SECURITY_VIEW = 'group.permission.ibtm-domain.folder.financial-security.view',
  /** Wyświetlenie listy baz ekspolatacyjnych z teczki */
  IBTM_DOMAIN_FOLDER_EXPLOITATION_BASE_VIEW = 'group.permission.ibtm-domain.folder.exploitation-base.view',
  /** Wyświetlenie listy Zezwolen jednorazowych */
  IBTM_DOMAIN_FOLDER_FOREIGN_PERMISSION_SINGLE_VIEW = 'group.permission.ibtm-domain.folder.foreign-permission-single.view',
  /** Wyświetlenie listy Zezwoleń EKMT */
  IBTM_DOMAIN_FOLDER_FOREIGN_PERMISSION_EKMT_VIEW = 'group.permission.ibtm-domain.folder.foreign-permission-ekmt.view',
  /** Wyświetlenie listy pojzadów z teczki */
  IBTM_DOMAIN_FOLDER_VEHICLE_VIEW = 'group.permission.ibtm-domain.folder.vehicle.view',
  /** Wyświetlenie listy kierowców na teczce */
  IBTM_DOMAIN_FOLDER_DRIVER_LIST_VIEW = 'group.permission.ibtm-domain.folder.driver-list.view',
  /** Wyświetlenie listy blokad na teczce */
  IBTM_DOMAIN_FOLDER_LOCK_VIEW = 'group.permission.ibtm-domain.folder.lock.view',
  /** Wyświetlenie listy zawieszeń (dana teczka) */
  IBTM_DOMAIN_FOLDER_SUSPENSION_VIEW = 'group.permission.ibtm-domain.folder.suspension.view',
  /** Wyświetlenie listy postępowań (dana teczka) */
  IBTM_DOMAIN_FOLDER_PROCEEDING_VIEW = 'group.permission.ibtm-domain.folder.proceeding.view',
  /** Wyświetlenie notatki */
  IBTM_DOMAIN_FOLDER_NOTE_VIEW = 'group.permission.ibtm-domain.folder.note.view',
  /** Edycja uwag do teczki */
  IBTM_DOMAIN_FOLDER_NOTE_UPDATE = 'group.permission.ibtm-domain.folder.note.update',
  /** Wyświetlenie historii teczki */
  IBTM_DOMAIN_FOLDER_HISTORY_VIEW = 'group.permission.ibtm-domain.folder.history.view',
  /** Wyświetlenie uprawnień z teczki */
  IBTM_DOMAIN_FOLDER_PERMISSION_LIST_VIEW = 'group.permission.ibtm-domain.folder.permission-list.view',
  /** Wyświetlenie listy Certyfikatów S */
  IBTM_DOMAIN_S_CERTIFICATE_LIST_VIEW = 'group.permission.ibtm-domain.s-certificate.list.view',
  /** Dodanie blokady z określonym rodzajem */
  IBTM_DOMAIN_FOLDER_LOCK_CREATE = 'group.permission.ibtm-domain.folder.lock.create',
  /** Zdjęcie blokady */
  IBTM_DOMAIN_FOLDER_LOCK_REMOVE = 'group.permission.ibtm-domain.folder.lock.remove',
  /** Wybranie rodzaju blokady */

  // _ = 'group.permission..'	,

  /** Wyświetlenie karty teczki */
  IBTM_DOMAIN_FOLDER_SLIDER_VIEW = 'group.permission.ibtm-domain.folder.slider.view',
  /** Wyświetlenie licencji aktywnych */
  IBTM_DOMAIN_FOLDER_PERMISSION_LIST_ACTIVE = 'group.permission.ibtm-domain.folder.permission-list.active',
  /** Wyświetlenie licencji nieaktywnych */
  IBTM_DOMAIN_FOLDER_PERMISSION_LIST_UNACTIVE = 'group.permission.ibtm-domain.folder.permission-list.unactive',
  /** Wyświetlenie zezwolenia na WZPD */
  IBTM_DOMAIN_FOLDER_PERMISSION_LIST_WZPD = 'group.permission.ibtm-domain.folder.permission-list.wzpd-view',
  /** Wyświetlenie zewnętrznych zezwoleń na WZPD */
  IBTM_DOMAIN_FOLDER_PERMISSION_LIST_WZPD_EXTERNAL = 'group.permission.ibtm-domain.folder.permission-list.wzpd-external-view',
  /** Dodanie pojazdu do teczki */
  IBTM_DOMAIN_FOLDER_VEHICLE_CREATE = 'group.permission.ibtm-domain.folder.vehicle.create',
  /** Usnięcie pojazdu z teczki */
  IBTM_DOMAIN_FOLDER_VEHICLE_DELETE = 'group.permission.ibtm-domain.folder.vehicle.delete',
  /** Edycja pojazdu z teczki */
  IBTM_DOMAIN_FOLDER_VEHICLE_UPDATE = 'group.permission.ibtm-domain.folder.vehicle.update',
  /** Dodanie zabezpieczenia finansowego do teczki */
  IBTM_DOMAIN_FOLDER_FINANCIAL_SECURITY_CREATE = 'group.permission.ibtm-domain.folder.financial-security.create',
  /** Usunięcie zabezpieczenia finansowego */
  IBTM_DOMAIN_FOLDER_FINANCIAL_SECURITY_DELETE = 'group.permission.ibtm-domain.folder.financial-security.delete',
  /** Wycyofanie pojazdu z teczki */
  IBTM_DOMAIN_FOLDER_VEHICLE_WITHDRAW = 'group.permission.ibtm-domain.folder.vehicle.withdraw',
  /** Przywrócenie pojazdu z teczki */
  IBTM_DOMAIN_FOLDER_VEHICLE_RESTORE = 'group.permission.ibtm-domain.folder.vehicle.restore',
  /** Edycja zabezpieczenia finansowego */
  IBTM_DOMAIN_FOLDER_FINANCIAL_SECURITY_UPDATE = 'group.permission.ibtm-domain.folder.financial-security.update',
  /** Przywrócenie zabezpieczenia finansowego */
  IBTM_DOMAIN_FOLDER_FINANCIAL_SECURITY_RESTORE = 'group.permission.ibtm-domain.folder.financial-security.restore',
  /** Wycofanie zabezpieczenia finansowego */
  IBTM_DOMAIN_FOLDER_FINANCIAL_SECURITY_WITHDRAW = 'group.permission.ibtm-domain.folder.financial-security.withdraw',
  /** Edycja zarządzającego transportem */
  IBTM_DOMAIN_FOLDER_TRANSPORT_MANAGER_UPDATE = 'group.permission.ibtm-domain.folder.transport-manager.update',
  /** Wycofanie zarządzającego transportem */
  IBTM_DOMAIN_FOLDER_TRANSPORT_MANAGER_WITHDRAW = 'group.permission.ibtm-domain.folder.transport-manager.withdraw',
  /** Przywrócenie zarządzającego transportem */
  IBTM_DOMAIN_FOLDER_TRANSPORT_MANAGER_RESTORE = 'group.permission.ibtm-domain.folder.transport-manager.restore',
  /** Edycja bazy eksploatacyjnej */
  IBTM_DOMAIN_FOLDER_EXPLOITATION_BASE_UPDATE = 'group.permission.ibtm-domain.folder.exploitation-base.update',
  /** Przywrócenie bazy eksploatacyjnej */
  IBTM_DOMAIN_FOLDER_EXPLOITATION_BASE_RESTORE = 'group.permission.ibtm-domain.folder.exploitation-base.restore',
  /** Wycofanie bazy eksploatacyjnej */
  IBTM_DOMAIN_FOLDER_EXPLOITATION_BASE_WITHDRAW = 'group.permission.ibtm-domain.folder.exploitation-base.withdraw',
  /** Dodanie pełnomocnika */
  IBTM_DOMAIN_FOLDER_PROXY_CREATE = 'group.permission.ibtm-domain.folder.proxy.create',
  /** Edytowanie pełnomocnika */
  IBTM_DOMAIN_FOLDER_PROXY_UPDATE = 'group.permission.ibtm-domain.folder.proxy.update',
  /** Dodanie przedsiębiorcy do spółki cywilnej */
  IBTM_DOMAIN_FOLDER_CIVIL_PARTNERSHIP_PARTNER_ADD = 'group.permission.ibtm-domain.folder.civil-partnership-partner.add',

  /** Utworzenie teczki */
  IBTM_DOMAIN_FOLDER_CIVIL_PARTNERSHIP_CREATE = 'group.permission.ibtm-domain.folder.civil-partnership.create',
  /** Aktywowanie teczki */
  IBTM_DOMAIN_FOLDER_EXPIRATION_DISABLE = 'group.permission.ibtm-domain.folder.expiration.disable',
  /** Wygaszenie teczki */
  IBTM_DOMAIN_FOLDER_EXPIRATION_ENABLE = 'group.permission.ibtm-domain.folder.expiration.enable',
  /** Dodanie do wniosku */

  // _ = 'group.permission..'	,
  // /** Przywrócenie teczki wygaszonej */
  // _ = 'group.permission..'	,
  // /** Wyświetlenie szczegółów teczki */
  // _ = 'group.permission..'	,

  /** Wprowadzenie daty zgonu */
  IBTM_DOMAIN_FOLDER_DATE_OF_DEATH_SET = 'group.permission.ibtm-domain.folder.date-of-death.set',
  /** Edycja daty zgonu */
  IBTM_DOMAIN_FOLDER_DATE_OF_DEATH_UPDATE = 'group.permission.ibtm-domain.folder.date-of-death.update',
  /** Wyświetlenie listy wydań dokumentów */

  // _ = 'group.permission..'	,
  // /** Wyświetlenie szczegółów dokumentu wydania */
  // _ = 'group.permission..'	,
  // /** Wydrukowanie dokumentu wydania */
  // _ = 'group.permission..'	,
  // /** Anulowanie korekty do dokumentu wydania */
  // _ = 'group.permission..'	,
  // /** Edycja korekty do dokumentu wydania */
  // _ = 'group.permission..'	,

  /** Wyświetlenie osób w teczce MZ */

  // _ = 'group.permission..'	,
  /** Wyświetlenie uprawnień MTD */
  IBTM_DOMAIN_FOLDER_MTD_PERMISSION_VIEW = 'group.permission.ibtm-domain.folder.mtd-permission.view',
  /** Wygenerowanie etykiety teczkowej */
  IBTM_DOMAIN_FOLDER_LABEL_CREATE = 'group.permission.ibtm-domain.folder.label.create',

  /** Potwierdzenie danych po migracji */
  IBTM_DOMAIN_FOLDER_MIGRATION_CONFIRM = 'group.permission.ibtm-domain.folder.migration.confirm',
  /** Blokada wysyłki kurierem */
  IBTM_DOMAIN_FOLDER_COURIER_PARCEL_BLOCK = 'group.permission.ibtm-domain.folder.courier-parcel.block',
  /** Wyświelenie listy kierowców */

  // _ = 'group.permission..'	,
  // /** Dodanie typu teczki */
  // _ = 'group.permission..'	,
  // /** Wygaszenie teczki */
  // _ = 'group.permission..'	,

  /** Wyświetlenie listy stowarzyszen */
  IBTM_DOMAIN_FOLDER_ASSOCIATION_LIST_VIEW = 'group.permission.ibtm-domain.folder.association-list.view',
  /** Dodanie stowarzyszenia */
  IBTM_DOMAIN_FOLDER_ASSOCIATION_CREATE = 'group.permission.ibtm-domain.folder.association.create',
  /** Wyświetlenie teczki stowarzyszenia */
  IBTM_DOMAIN_FOLDER_ASSOCIATION_DETAILS_VIEW = 'group.permission.ibtm-domain.folder.association-details.view',
  /** Usunięcie stowarzyszenia */
  IBTM_DOMAIN_FOLDER_ASSOCIATION_DELETE = 'group.permission.ibtm-domain.folder.association.delete',
  /** Edycja stowarzyszenia */
  IBTM_DOMAIN_FOLDER_ASSOCIATION_UPDATE = 'group.permission.ibtm-domain.folder.association.update',
  /** Dodanie przedsiębiorcy do stowarzyszenia */
  IBTM_DOMAIN_FOLDER_ASSOCIATION_SUBJECT_ADD = 'group.permission.ibtm-domain.folder.association.subject-add',
  /** Usunięcie przedsiębiorcy ze stowarzyszenia */
  IBTM_DOMAIN_FOLDER_ASSOCIATION_SUBJECT_DELETE = 'group.permission.ibtm-domain.folder.association.subject-delete',
  /** Wystawienie korekty do dokumentu wydania wygenerowanego przy wniosku WL */

  // _ = 'group.permission..'	,
  // /** Dodanie pojazdu do wniosku */
  // _ = 'group.permission..'	,
  // /** Usunięcie pojazdu z wniosku */
  // _ = 'group.permission..'	,

  /** Wyświetlenie listy zezwoleń */

  // _ = 'group.permission..'	,

  /** Wyświetlenie listy wniosków */
  IBTM_DOMAIN_APPLICATION_CORE_LIST_VIEW = 'group.permission.ibtm-domain.application-core.list.view',

  /** Skopiowanie danych z wniosku */
  /** Zapisanie w teczce */
  IBTM_DOMAIN_APPLICATION_CORE_SAVE_IN_FOLDER = 'group.permission.ibtm-domain.application-core.save-in-folder',

  IBTM_DOMAIN_APPLICATION_CORE_INPUT_DATA_COPY = 'group.permission.ibtm-domain.application-core.input-data.copy',
  /** Zapisanie danych we wniosku */

  // _ = 'group.permission..'	,

  /** Ustawienie danych dotyczących decyzji / wyroków sądu */
  IBTM_DOMAIN_APPLICATION_CORE_COURT_JUDGMENTS_UPDATE = 'group.permission.ibtm-domain.application-core.court-judgments.update',
  /** Wygenerowanie pouczenia */
  IBTM_DOMAIN_APPLICATION_CORE_INSTRUCTION_GENERATE = 'group.permission.ibtm-domain.application-core.instruction.generate',
  /** Wygenerowanie z szablonu pisma "Potwierdzenie złożenia wniosku" */
  IBTM_DOMAIN_APPLICATION_CORE_DOCUMENT_SUBMISSION_CONFIRMATION_GENERATE = 'group.permission.ibtm-domain.application-core.document-submission-confirmation.generate',
  /** Potwierdzenie wygenerowania pisma o pozostawienie wniosku bez rozpatrzenia */
  IBTM_DOMAIN_APPLICATION_CORE_WITHOUT_CONSIDERATION_DOCUMENT_CONFIRM = 'group.permission.ibtm-domain.application-core.without-consideration-document.confirm',
  /** Przygotowanie projektu postępowania decyzji */
  IBTM_DOMAIN_APPLICATION_CORE_PREPARING_PROJECT_ASSIGN = 'group.permission.ibtm-domain.application-core.preparing-project.assign',
  /** Przekazanie do wydruku */
  IBTM_DOMAIN_APPLICATION_CORE_PRINTING_TRANSFER = 'group.permission.ibtm-domain.application-core.printing.transfer',
  /** Wygenerowanie etykiety wnioskowej */
  IBTM_DOMAIN_APPLICATION_CORE_LABEL_CREATE = 'group.permission.ibtm-domain.application-core.label.create',
  /** Przekazanie do wydania */
  IBTM_DOMAIN_APPLICATION_CORE_ISSUE_TRANSFER = 'group.permission.ibtm-domain.application-core.issue.transfer',
  /** Przekazanie do akceptacji */
  IBTM_DOMAIN_APPLICATION_CORE_ACCEPTATION_TRANSFER = 'group.permission.ibtm-domain.application-core.acceptation.transfer',
  /** Przekazanie do weryfikacji */
  IBTM_DOMAIN_APPLICATION_CORE_VERIFICATION_TRANSFER = 'group.permission.ibtm-domain.application-core.verification.transfer',
  /** Cofnięcie do poprawy */
  IBTM_DOMAIN_APPLICATION_CORE_IMPROVEMENT_WITHDRAWAL = 'group.permission.ibtm-domain.application-core.improvement.withdrawal',
  /** Oznaczenie jako kompletny */
  IBTM_DOMAIN_APPLICATION_CORE_COMPLETE_MARK = 'group.permission.ibtm-domain.application-core.complete.mark',
  /** Przywrócenie statusu niekompletny */
  IBTM_DOMAIN_APPLICATION_CORE_STATUS_INCOMPLETE_RESTORE = 'group.permission.ibtm-domain.application-core.status-incomplete.restore',
  /** Pozostawienie bez uzupełnienia */
  IBTM_DOMAIN_APPLICATION_CORE_UNFILLED_LEAVE = 'group.permission.ibtm-domain.application-core.unfilled.leave',
  /** Przekazanie do innego wydziału */
  IBTM_DOMAIN_APPLICATION_CORE_DEPARTMENT_TRANSFER = 'group.permission.ibtm-domain.application-core.department.transfer',
  /** Wezwanie do uzupełnienia */
  IBTM_DOMAIN_APPLICATION_CORE_REQUEST_FOR_SUPPLEMENT_SEND = 'group.permission.ibtm-domain.application-core.request-for-supplement.send',
  /** Anulowanie procesu */
  IBTM_DOMAIN_APPLICATION_CORE_PROCESS_CANCEL = 'group.permission.ibtm-domain.application-core.process.cancel',
  /** Uzupełnienie braków */
  IBTM_DOMAIN_APPLICATION_CORE_MISSING_DATA_CORRECT = 'group.permission.ibtm-domain.application-core.missing-data.correct',
  /** Wycofanie wniosku */
  IBTM_DOMAIN_APPLICATION_CORE_WITHDRAW = 'group.permission.ibtm-domain.application-core.withdraw',
  /** Zwrócenie */
  IBTM_DOMAIN_APPLICATION_CORE_RETURN = 'group.permission.ibtm-domain.application-core.return',

  /** Weryfikowanie */
  IBTM_DOMAIN_APPLICATION_CORE_INPUT_DATA_VERIFY = 'group.permission.ibtm-domain.application-core.input-data.verify',
  /** Weryfikowanie */

  // _ = 'group.permission..'	,

  /** Wybranie sposobu odbioru */
  IBTM_DOMAIN_APPLICATION_CORE_RECEIPT_TYPE_CHOOSE = 'group.permission.ibtm-domain.application-core.receipt-type.choose',
  /** Wybranie adresu do wysyłki */
  IBTM_DOMAIN_APPLICATION_CORE_SHIPPING_ADDRESS_CHOOSE = 'group.permission.ibtm-domain.application-core.shipping-address.choose',
  /** Potwierdzenie wydania / wysyłki */
  IBTM_DOMAIN_APPLICATION_CORE_PARCEL_RELEASE_CONFIRM = 'group.permission.ibtm-domain.application-core.parcel-release.confirm',
  /** Wyświetlenie historii wniosku */
  IBTM_DOMAIN_APPLICATION_CORE_HISTORY_VIEW = 'group.permission.ibtm-domain.application-core.history.view',
  /** Wyświetlenie listy weryfikującej załączniki dodane do wniosku */
  IBTM_DOMAIN_APPLICATION_CORE_VERIFICATION_ELEMENT_LIST_VIEW = 'group.permission.ibtm-domain.application-core.verification-element-list.view',
  /** Dodanie uwagi do załącznika - braki formalne */
  IBTM_DOMAIN_APPLICATION_CORE_VERIFICATION_ELEMENT_NOTE_UPDATE = 'group.permission.ibtm-domain.application-core.verification-element-note.update',
  /** Wyświetlenie dokumentów wychodzących z wniosku */
  IBTM_DOMAIN_APPLICATION_CORE_DOCUMENT_LIST_VIEW = 'group.permission.ibtm-domain.application-core.document-list.view',
  /** Wyświetlenie notatki do wniosku */
  IBTM_DOMAIN_APPLICATION_CORE_NOTE_VIEW = 'group.permission.ibtm-domain.application-core.note.view',
  /** Edycja notatki do wniosku */
  IBTM_DOMAIN_APPLICATION_CORE_NOTE_UPDATE = 'group.permission.ibtm-domain.application-core.note.update',
  /** Wyświetlenie plików dodanych do wniosku */
  IBTM_DOMAIN_APPLICATION_CORE_FILE_LIST_VIEW = 'group.permission.ibtm-domain.application-core.file-list.view',
  /** Wgranie pliku do wniosku */
  IBTM_DOMAIN_APPLICATION_CORE_FILE_UPLOAD = 'group.permission.ibtm-domain.application-core.file.upload',
  /** Pobranie pliku wgranego do wniosku */
  IBTM_DOMAIN_APPLICATION_CORE_FILE_DOWNLOAD = 'group.permission.ibtm-domain.application-core.file.download',
  /** Usunięcie pliku wgranego do wniosku */
  IBTM_DOMAIN_APPLICATION_CORE_FILE_DELETE = 'group.permission.ibtm-domain.application-core.file.delete',
  /** Wyświetlenie podmiotu z wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_VIEW = 'group.permission.ibtm-domain.application-item.subject.view',
  /** Edycja podmiotu z wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_UPDATE = 'group.permission.ibtm-domain.application-item.subject.update',
  /** Edycja daty wpływu ostatniego uzupełnienia */
  IBTM_DOMAIN_APPLICATION_ITEM_LAST_SUPPLEMENT_DATE_UPDATE = 'group.permission.ibtm-domain.application-item.last-supplement-date.update',
  /** Wyświetlenie pozycji do druku z danego wniosku */
  IBTM_DOMAIN_APPLICATION_CORE_PRINT_ITEMS_VIEW = 'group.permission.ibtm-domain.application-core.print-items.view',
  /** Zwrócenie wniosku z druku do weryfikującego */
  IBTM_DOMAIN_APPLICATION_CORE_RETURNING_TO_VERIFY_UPDATE = 'group.permission.ibtm-domain.application-core.returning-to-verify.update',
  /** Edycja danych do druku */
  IBTM_DOMAIN_APPLICATION_CORE_PRINT_DATA_UPDATE = 'group.permission.ibtm-domain.application-core.print-data.update',
  /** Ustawienie parametrów drukowania */
  IBTM_DOMAIN_APPLICATION_CORE_PRINT_SETTINGS_SET = 'group.permission.ibtm-domain.application-core.print-settings.set',
  /** Przypisanie do użytkownika wniosków do druku */
  IBTM_DOMAIN_APPLICATION_CORE_PRINT_USER_ASSIGN = 'group.permission.ibtm-domain.application-core.print-user.assign',
  /** Wyeksportowanie aktualnego cennika opłat za wnioski */
  IBTM_DOMAIN_APPLICATION_CORE_PRICE_LIST_EXPORT = 'group.permission.ibtm-domain.application-core.price-list.export',
  /** Wyswietlenie informacji o ostatnim imporcie cennika opłat za wnioski */
  IBTM_DOMAIN_APPLICATION_CORE_PRICE_LIST_LAST_IMPORT_VIEW = 'group.permission.ibtm-domain.application-core.price-list-last-import.view',
  /** Zaimportowanie nowego cennika opłat za wnioski */
  IBTM_DOMAIN_APPLICATION_CORE_PRICE_LIST_IMPORT = 'group.permission.ibtm-domain.application-core.price-list.import',
  /** Wyświetlenie wyceny wniosku */
  IBTM_DOMAIN_APPLICATION_CORE_PRICING_VIEW = 'group.permission.ibtm-domain.application-core.pricing.view',
  /** Zmienianie danych z wyceny */
  IBTM_DOMAIN_APPLICATION_CORE_PRICING_UPDATE = 'group.permission.ibtm-domain.application-core.pricing.update',
  /** Wyszukanie przelewu */
  IBTM_DOMAIN_APPLICATION_CORE_MONEY_TRANSFER_SEARCH = 'group.permission.ibtm-domain.application-core.money-transfer.search',
  /** Usunięcie przelewu */
  IBTM_DOMAIN_APPLICATION_CORE_MONEY_TRANSFER_DELETE = 'group.permission.ibtm-domain.application-core.money-transfer.delete',
  /** Powiązanie wyciągu z wnioskiem */
  IBTM_DOMAIN_APPLICATION_CORE_MONEY_TRANSFER_BIND = 'group.permission.ibtm-domain.application-core.money-transfer.bind',
  /** Odwiązanie wyciągów od wniosku */
  IBTM_DOMAIN_APPLICATION_CORE_MONEY_TRANSFER_UNBIND = 'group.permission.ibtm-domain.application-core.money-transfer.unbind',

  /** Wyszukanie pojazdu w teczce */
  IBTM_DOMAIN_APPLICATION_CORE_FOLDER_VEHICLE_SEARCH = 'group.permission.ibtm-domain.application-core.folder-vehicle.search',
  /** Pobranie taboru z teczki */
  IBTM_DOMAIN_APPLICATION_CORE_FOLDER_VEHICLE_LIST_IMPORT = 'group.permission.ibtm-domain.application-core.folder-vehicle-list.import',
  /** Wyświetlenie karty teczki */
  IBTM_DOMAIN_APPLICATION_CORE_FOLDER_SLIDER_VIEW = 'group.permission.ibtm-domain.application-core.folder-slider.view',
  /** Przejście do listy blokad na teczce */
  IBTM_DOMAIN_APPLICATION_CORE_FOLDER_LOCK_REDIRECT = 'group.permission.ibtm-domain.application-core.folder-lock.redirect',
  /** Przejście do teczki */
  IBTM_DOMAIN_APPLICATION_CORE_FOLDER_REDIRECT = 'group.permission.ibtm-domain.application-core.folder.redirect',
  /** Edycja informacji o załącznikach */
  IBTM_DOMAIN_APPLICATION_CORE_VERIFICATION_ELEMENT_UPDATE = 'group.permission.ibtm-domain.application-core.verification-element.update',
  /** Otwarcie pisma z odnośnika */
  IBTM_DOMAIN_APPLICATION_CORE_HISTORY_DOCUMENT_LINK_DOWNLOAD = 'group.permission.ibtm-domain.application-core.history-document-link.download',
  /** Dodanie powiązanego wniosku */
  IBTM_DOMAIN_APPLICATION_CORE_LINKED_APPLICATION_ADD = 'group.permission.ibtm-domain.application-core.linked-application.add',
  /** Otwarcie powiązanego wniosku */
  IBTM_DOMAIN_APPLICATION_CORE_LINKED_APPLICATION_OPEN = 'group.permission.ibtm-domain.application-core.linked-application.open',
  /** Wyświetlenie zakładki Zezwolenia jednorazowe */
  IBTM_DOMAIN_APPLICATION_ITEM_FOREIGN_PERMISSION_SINGLE_VIEW = 'group.permission.ibtm-domain.application-item.foreign-permission-single.view',
  /** Dodanie pozycji do zewoleń jednorazowych */
  IBTM_DOMAIN_APPLICATION_ITEM_FOREIGN_PERMISSION_SINGLE_CREATE = 'group.permission.ibtm-domain.application-item.foreign-permission-single.create',
  /** Edycja pozycji zezwoleń jednorazowych */
  IBTM_DOMAIN_APPLICATION_ITEM_FOREIGN_PERMISSION_SINGLE_UPDATE = 'group.permission.ibtm-domain.application-item.foreign-permission-single.update',
  /** Usunięcie pozycji zezwoleń jednorazowych */
  IBTM_DOMAIN_APPLICATION_ITEM_FOREIGN_PERMISSION_SINGLE_DELETE = 'group.permission.ibtm-domain.application-item.foreign-permission-single.delete',
  /** Wyświetlenie Podwykonawców/Podmiotów wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_PARTNER_APPLICANT_SUBJECT_LIST_VIEW = 'group.permission.ibtm-domain.application-item.partner-applicant-subject-list.view',
  /** Dodanie podmiotu do wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_PARTNER_APPLICANT_SUBJECT_CREATE = 'group.permission.ibtm-domain.application-item.partner-applicant-subject.create',
  /** Edycja podmiotu na wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_PARTNER_APPLICANT_SUBJECT_UPDATE = 'group.permission.ibtm-domain.application-item.partner-applicant-subject.update',
  /** Usunięcie podmiotu z wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_PARTNER_APPLICANT_SUBJECT_DELETE = 'group.permission.ibtm-domain.application-item.partner-applicant-subject.delete',
  /** Wyświetlenie listy pełnomocników na wniosku */

  // _ = 'group.permission..'	,
  // /** Dodanie nowego pełnomocnika */
  // _ = 'group.permission..'	,
  // /** Edytowanie danych pełnomocnika */
  // _ = 'group.permission..'	,
  // /** Usunięcie pełnomocnika */
  // _ = 'group.permission..'	,
  // /** Wyświetlenie Załączników do wniosku */
  // _ = 'group.permission..'	,

  /** Wyświetlenie Rozkładu jazdy */
  IBTM_DOMAIN_APPLICATION_ITEM_TRANSIT_SCHEDULE_VIEW = 'group.permission.ibtm-domain.application-item.transit-schedule.view',
  /** Dodanie przystanku */
  IBTM_DOMAIN_APPLICATION_ITEM_TRANSIT_STOP_CREATE = 'group.permission.ibtm-domain.application-item.transit-stop.create',
  /** Edycja przystanku */
  IBTM_DOMAIN_APPLICATION_ITEM_TRANSIT_STOP_UPDATE = 'group.permission.ibtm-domain.application-item.transit-stop.update',
  /** Usunięcie przystanku */
  IBTM_DOMAIN_APPLICATION_ITEM_TRANSIT_STOP_DELETE = 'group.permission.ibtm-domain.application-item.transit-stop.delete',
  /** Dodanie kursu */
  IBTM_DOMAIN_APPLICATION_ITEM_TRANSIT_STOP_ADD_HOURS = 'group.permission.ibtm-domain.application-item.transit-stop.add-hours',
  /** Określenie częstotliwości przejazdu */
  IBTM_DOMAIN_APPLICATION_ITEM_TRANSIT_FREQUENCY_UPDATE = 'group.permission.ibtm-domain.application-item.transit-frequency.update',
  /** Określenie terminów przejazdu */
  IBTM_DOMAIN_APPLICATION_ITEM_TRANSIT_DATE_UPDATE = 'group.permission.ibtm-domain.application-item.transit-date.update',

  /** Wyświetlenie kabotażu */
  IBTM_DOMAIN_APPLICATION_ITEM_CABOTAGE_VIEW = 'group.permission.ibtm-domain.application-item.cabotage.view',
  /** Edycja kraju kabotażu */
  IBTM_DOMAIN_APPLICATION_ITEM_CABOTAGE_COUNTRY_UPDATE = 'group.permission.ibtm-domain.application-item.cabotage-country.update',
  /** Edycja uwag do kabotażu */
  IBTM_DOMAIN_APPLICATION_ITEM_CABOTAGE_NOTE_UPDATE = 'group.permission.ibtm-domain.application-item.cabotage-note.update',
  /** Wyszukanie zabezpieczenia finansowego */
  IBTM_DOMAIN_APPLICATION_ITEM_FINANCIAL_SECURITY_SEARCH = 'group.permission.ibtm-domain.application-item.financial-security.search',
  /** Kopiowanie NIP */
  IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_NIP_COPY = 'group.permission.ibtm-domain.application-item.subject-nip.copy',
  /** Kopiowanie REGON */
  IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_REGON_COPY = 'group.permission.ibtm-domain.application-item.subject-regon.copy',
  /** Kopiowanie Email */
  IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_EMAIL_COPY = 'group.permission.ibtm-domain.application-item.subject-email.copy',
  /** Wyświetlenie listy zarządzających we wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_TRANSPORT_MANAGER_LIST_VIEW = 'group.permission.ibtm-domain.application-item.transport-manager-list.view',
  /** Dodanie podstawowych danych o wniosku LR13 / LO13 */

  // _ = 'group.permission..'	,

  /** Dodanie zarządzającego transportem */
  IBTM_DOMAIN_APPLICATION_ITEM_TRANSPORT_MANAGER_CREATE = 'group.permission.ibtm-domain.application-item.transport-manager.create',
  /** Wyszukanie zarządzającego transportem w systemie */
  IBTM_DOMAIN_APPLICATION_ITEM_TRANSPORT_MANAGER_SEARCH = 'group.permission.ibtm-domain.application-item.transport-manager.search',
  /** Edycja zarządzającego transportem */
  IBTM_DOMAIN_APPLICATION_ITEM_TRANSPORT_MANAGER_UPDATE = 'group.permission.ibtm-domain.application-item.transport-manager.update',
  /** Usunięcie zarządzającego transportem */
  IBTM_DOMAIN_APPLICATION_ITEM_TRANSPORT_MANAGER_DELETE = 'group.permission.ibtm-domain.application-item.transport-manager.delete',
  /** Przeglądanie zarządzającego transportem */
  IBTM_DOMAIN_APPLICATION_ITEM_TRANSPORT_MANAGER_DETAILS_VIEW = 'group.permission.ibtm-domain.application-item.transport-manager-details.view',
  /** Pobranie informacji o dobrej reputacji zarządzającego trasportem z KREPTD */
  IBTM_DOMAIN_APPLICATION_ITEM_TRANSPORT_MANAGER_KREPTD_REPUTATION_IMPORT = 'group.permission.ibtm-domain.application-item.transport-manager-kreptd-reputation.import',
  /** Wyświetlenie reputacji z KREPTD */
  IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_KREPTD_REPUTATION_VIEW = 'group.permission.ibtm-domain.application-item.subject-kreptd-reputation.view',
  /** Akceptacja danych z REGON */
  IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_REGON_DATA_ACCEPT = 'group.permission.ibtm-domain.application-item.subject-regon-data.accept',
  /** Akceptacja adresu z REGON */
  IBTM_DOMAIN_APPLICATION_ITEM_ADDRESS_REGON_DATA_ACCEPT = 'group.permission.ibtm-domain.application-item.address-regon-data.accept',
  /** Wyświetlenie adresów podmiotu z wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_ADDRESS_VIEW = 'group.permission.ibtm-domain.application-item.address.view',

  /** Edycja adresu podmiotu */
  IBTM_DOMAIN_APPLICATION_ITEM_ADDRESS_UPDATE = 'group.permission.ibtm-domain.application-item.address.update',

  /** Wyświetlenie listy pełnomocników do wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_PROXY_LIST_VIEW = 'group.permission.ibtm-domain.application-item.proxy-list.view',
  /** Dodanie pełnomocnika do wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_PROXY_CREATE = 'group.permission.ibtm-domain.application-item.proxy.create',
  /** Edycja pełnomocnika z wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_PROXY_UPDATE = 'group.permission.ibtm-domain.application-item.proxy.update',
  /** Usunięcie pełnomocnika z wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_PROXY_DELETE = 'group.permission.ibtm-domain.application-item.proxy.delete',
  /** Przeglądanie szczegółów pełnomocnika z wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_PROXY_DETAILS_VIEW = 'group.permission.ibtm-domain.application-item.proxy-details.view',
  /** Wyświetlenie listy zabezpieczeń finansowych do wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_FINANCIAL_SECURITY_LIST_VIEW = 'group.permission.ibtm-domain.application-item.financial-security-list.view',
  /** Dodanie zabezpieczenia finansowego do wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_FINANCIAL_SECURITY_CREATE = 'group.permission.ibtm-domain.application-item.financial-security.create',
  /** Edycja zabezpieczenia finansowego do wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_FINANCIAL_SECURITY_UPDATE = 'group.permission.ibtm-domain.application-item.financial-security.update',
  /** Usunięcie zabezpieczenia finansowego dodanego do wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_FINANCIAL_SECURITY_DELETE = 'group.permission.ibtm-domain.application-item.financial-security.delete',
  /** Podsumowanie zabezpieczenia wymaganego we wniosku i dostępnego */
  IBTM_DOMAIN_APPLICATION_ITEM_FINANCIAL_SECURITY_SUM = 'group.permission.ibtm-domain.application-item.financial-security.sum',
  /** Wyświetlenie listy pojazdów we wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_VEHICLE_LIST_VIEW = 'group.permission.ibtm-domain.application-item.vehicle-list.view',
  /** Dodanie pojazdu do wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_VEHICLE_CREATE = 'group.permission.ibtm-domain.application-item.vehicle.create',
  /** Wyczyszczenie taboru */
  IBTM_DOMAIN_APPLICATION_ITEM_VEHICLE_LIST_CLEAR = 'group.permission.ibtm-domain.application-item.vehicle-list.clear',
  /** Edycja pojazdu we wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_VEHICLE_UPDATE = 'group.permission.ibtm-domain.application-item.vehicle.update',
  /** Usunięcie pojazdu z wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_VEHICLE_DELETE = 'group.permission.ibtm-domain.application-item.vehicle.delete',

  /** Przeglądanie szczegółów pojazdu we wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_VEHICLE_DETAILS_VIEW = 'group.permission.ibtm-domain.application-item.vehicle-details.view',
  /** Pobranie listy pojazdów */
  IBTM_DOMAIN_APPLICATION_ITEM_VEHICLE_LIST_XLSX_EXPORT = 'group.permission.ibtm-domain.application-item.vehicle-list-xlsx.export',
  /** Pobranie danych o pojeździe z CEPIK */
  IBTM_DOMAIN_APPLICATION_ITEM_VEHICLE_CEPIK_DATA_IMPORT = 'group.permission.ibtm-domain.application-item.vehicle-cepik-data.import',
  /** Wyświetlenie pojazdu */

  // _ = 'group.permission..'	,

  /** Wyświetlenie listy baz eksploatacyjnych we wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_EXPLOITATION_BASE_LIST_VIEW = 'group.permission.ibtm-domain.application-item.exploitation-base-list.view',
  /** Dodanie bazy eksploatacyjnej do wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_EXPLOITATION_BASE_CREATE = 'group.permission.ibtm-domain.application-item.exploitation-base.create',
  /** Edycja bazy eksploatacyjnej we wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_EXPLOITATION_BASE_UPDATE = 'group.permission.ibtm-domain.application-item.exploitation-base.update',
  /** Przeglądanie szczegółów bazy eksploatacyjnej we wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_EXPLOITATION_BASE_DETAILS_VIEW = 'group.permission.ibtm-domain.application-item.exploitation-base-details.view',
  /** Usunięcie bazy eksploatacyjnej z wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_EXPLOITATION_BASE_DELETE = 'group.permission.ibtm-domain.application-item.exploitation-base.delete',

  /** Prezentacja lokalizacji bazy w Geoportal */
  IBTM_DOMAIN_APPLICATION_ITEM_EXPLOITATION_BASE_GEOPORTAL_LOCATION_VIEW = 'group.permission.ibtm-domain.application-item.exploitation-base-geoportal-location.view',
  /** Wyświetlenie uprawnień we wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_PERMISSION_LIST_VIEW = 'group.permission.ibtm-domain.application-item.permission-list.view',
  /** Dodanie zezwolenia wydanego przez organ zewnętrzny */
  IBTM_DOMAIN_APPLICATION_ITEM_PERMISSION_EXTERNAL_CREATE = 'group.permission.ibtm-domain.application-item.permission-external.create',
  /** Wyświetlenie listy wypisów z zezwolenia */
  IBTM_DOMAIN_APPLICATION_ITEM_PERMISSION_COPY_LIST_VIEW = 'group.permission.ibtm-domain.application-item.permission-copy-list.view',
  /** Wyświetlenie listy licencji */
  IBTM_DOMAIN_APPLICATION_ITEM_LICENSE_LIST_VIEW = 'group.permission.ibtm-domain.application-item.license-list.view',
  /** Wyświetlenie listy wypisów z licencji */
  IBTM_DOMAIN_APPLICATION_ITEM_LICENSE_COPY_LIST_VIEW = 'group.permission.ibtm-domain.application-item.license-copy-list.view',
  /** Wyświetlenie uprawnienia wydanego przez organ zewnętrzny */
  IBTM_DOMAIN_APPLICATION_ITEM_PERMISSION_EXTERNAL_DETAILS_VIEW = 'group.permission.ibtm-domain.application-item.permission-external-details.view',
  /** Edytuj uprawnienie */
  IBTM_DOMAIN_APPLICATION_ITEM_PERMISSION_UPDATE = 'group.permission.ibtm-domain.application-item.permission.update',
  /** Usunięcie uprawnienia */
  IBTM_DOMAIN_APPLICATION_ITEM_PERMISSION_DELETE = 'group.permission.ibtm-domain.application-item.permission.delete',

  /** Dodanie Certyfikatu S do wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_S_CERTIFICATE_CERTIFICATE_CREATE = 'group.permission.ibtm-domain.application-item.s-certificate-certificate.create',
  /** Dodanie Certyfikatu S nowego pojazdu */
  IBTM_DOMAIN_APPLICATION_ITEM_S_CERTIFICATE_VEHICLE_CREATE = 'group.permission.ibtm-domain.application-item.s-certificate-vehicle.create',
  /** Usunięcie Certyfikatu S z wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_S_CERTIFICATE_CERTIFICATE_DELETE = 'group.permission.ibtm-domain.application-item.s-certificate-certificate.delete',
  /** Wyświeltlenie szczegółów Certyfikatu S */
  IBTM_DOMAIN_APPLICATION_ITEM_S_CERTIFICATE_CERTIFICATE_DETAILS_VIEW = 'group.permission.ibtm-domain.application-item.s-certificate-certificate-details.view',
  /** Edycja nr rejestracyjnego w tabeli */
  IBTM_DOMAIN_APPLICATION_ITEM_S_CERTIFICATE_VEHICLE_UPDATE = 'group.permission.ibtm-domain.application-item.s-certificate-vehicle.update',
  /** Wyświetlenie listy kierowców do wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_DRIVER_LIST_VIEW = 'group.permission.ibtm-domain.application-item.driver-list.view',
  /** Dodanie kierowcy */
  IBTM_DOMAIN_APPLICATION_ITEM_DRIVER_CREATE = 'group.permission.ibtm-domain.application-item.driver.create',
  /** Edycja kierowcy */
  IBTM_DOMAIN_APPLICATION_ITEM_DRIVER_UPDATE = 'group.permission.ibtm-domain.application-item.driver.update',
  /** Podgląd szczegółów kierowcy */
  IBTM_DOMAIN_APPLICATION_ITEM_DRIVER_DETAILS_VIEW = 'group.permission.ibtm-domain.application-item.driver-details.view',
  /** Usunięcie kierowcy z wniosku */
  IBTM_DOMAIN_APPLICATION_ITEM_DRIVER_DELETE = 'group.permission.ibtm-domain.application-item.driver.delete',
  /** Wyszukanie kierowcy z teczki */
  IBTM_DOMAIN_APPLICATION_ITEM_DRIVER_SEARCH = 'group.permission.ibtm-domain.application-item.driver.search',

  /** Pobranie informacji o dobrej reputacji przedsiębiorcy z KREPTD */
  IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_KREPTD_REPUTATION_IMPORT = 'group.permission.ibtm-domain.application-item.subject-kreptd-reputation.import',
  /** Wyświetlanie listy wniosków z uprawnieniami do druku */
  IBTM_DOMAIN_PRINTS_LIST_VIEW = 'group.permission.ibtm-domain.prints.list.view',
  /** Usunięcie numerów blankietów */
  IBTM_DOMAIN_PRINTS_RESOURCE_OBJECT_NUMBER_DELETE = 'group.permission.ibtm-domain.prints.resource-object-number.delete',
  /** Wygenerowanie uprawnienia */
  IBTM_DOMAIN_PRINTS_PERMISSION_GENERATE = 'group.permission.ibtm-domain.prints.permission.generate',
  /** Wydrukowanie uprawnienia */
  IBTM_DOMAIN_PRINTS_PERMISSION_PRINT = 'group.permission.ibtm-domain.prints.permission.print',
  /** Wygenerowanie wypisu/-ów */
  IBTM_DOMAIN_PRINTS_PERMISSION_COPY_GENERATE = 'group.permission.ibtm-domain.prints.permission-copy.generate',
  /** Wydrukowanie wszystkich uprawnień */
  IBTM_DOMAIN_PRINTS_PERMISSION_ALL_PRINT = 'group.permission.ibtm-domain.prints.permission-all.print',
  /** Przegenerowanie uprawnienia */
  IBTM_DOMAIN_PRINTS_PERMISSION_REGENERATE = 'group.permission.ibtm-domain.prints.permission.regenerate',
  /** Dodanie wniosku */
  IBTM_DOMAIN_APPLICATION_CRUD_CREATE = 'group.permission.ibtm-domain.application-crud.create',
  /** Aktualizacja danych dot. przedsiębiorcy z REGON */
  IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_REGON_DATA_IMPORT = 'group.permission.ibtm-domain.application-item.subject-regon-data.import',
  /** Wyświetlenie szczegółów wniosku */
  IBTM_DOMAIN_APPLICATION_CRUD_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.details.view',
  /** Podgląd wniosku dot. Licencji wspólnotowej/zezwolenia na pojazdy [TP/OP] */
  IBTM_DOMAIN_APPLICATION_CRUD_WL_ALL_VIEW = 'group.permission.ibtm-domain.application-crud.wl-all.view',
  /** Podgląd wniosku "Udzielenie zezwolenia i licencji" (PB1)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB1_A_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb1-a.details.view',
  /** Podgląd wniosku "Zmiana danych na zezwoleniu i licencji - odejście wspólnika ze spółki cywilnej" (PB4)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB4_B_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb4-b.details.view',
  /** Podgląd wniosku "Wtórnik licencji / zezwolenia / wypisów" (PB5)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB5_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb5.details.view',
  /** Podgląd wniosku "Przeniesienie uprawnień z zezwolenia i licencji" (PB6)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB6_A_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb6-a.details.view',
  /** Podgląd wniosku "Przeniesienie uprawnień z licencji" (PB6)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB6_B_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb6-b.details.view',
  /** Podgląd wniosku "Przeniesienie uprawnień z zezwolenia i licencji - nabycie przedsiębiorstwa" (PB6)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB6_C_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb6-c.details.view',
  /** Podgląd wniosku "Przeniesienie uprawnień po śmierci osoby fizycznej" (PB7)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB7_A_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb7-a.details.view',
  /** Podgląd wniosku "Przeniesienie uprawnień z zezwolenia i licencji po śmierci" (PB7)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB7_B_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb7-b.details.view',
  /** Podgląd wniosku "Przeniesienie uprawnień po śmierci osoby fizycznej będącej w spółce cywilnej" (PB7)" */

  // _ = 'group.permission..'	,
  // /** Podgląd wniosku "Przeniesienie uprawnień z zezwolenia i licencji po śmierci osoby będącej w spółce cywilnej" (PB7)" */
  // _ = 'group.permission..'	,

  /** Podgląd wniosku "Wyrażenie zgody na wykonywanie uprawnień wynikających z licencji" (PB8)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB8_A_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb8-a.details.view',
  /** Podgląd wniosku "Udzielenie licencji" (PB1)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB1_B_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb1-b.details.view',
  /** Podgląd wniosku "Wyrażenie zgody na wykonywanie uprawnień wynikających z zezwolenia i licencji" (PB8)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB8_B_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb8-b.details.view',
  /** Podgląd wniosku "Zarządca sukcesyjny - decyzja z licencji" (PB9)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB9_A_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb9-a.details.view',
  /** Podgląd wniosku "Zarządca sukcesyjny - decyzja z zezwolenia i licencji" (PB9)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB9_B_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb9-b.details.view',
  /** Podgląd wniosku "Zmiana osoby zarządzającej" (PB10)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB10_B_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb10-b.details.view',
  /** Podgląd wniosku "Zmiana bazy eksploatacyjnej" (PB10)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB10_A_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb10-a.details.view',
  /** Podgląd wniosku "Wymiana pojazdów" (PB11)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB11_A_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb11-a.details.view',
  /** Podgląd wniosku "Zmiana numerów rejestracyjnych" (PB11)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB11_B_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb11-b.details.view',
  /** Podgląd wniosku "Zmiana prawa dysponowania" (PB11)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB11_C_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb11-c.details.view',
  /** Podgląd wniosku "Sprzedaż pojazdów" (PB11)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB11_D_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb11-d.details.view',
  /** Podgląd wniosku "Zgłoszenie pojazdu bez wypisu" (PB12)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB12_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb12.details.view',
  /** Podgląd wniosku "Przedłużenie ważności licencji" (PB1)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB1_C_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb1-c.details.view',
  /** Podgląd wniosku "Omyłka pisarska" (PB14)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB14_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb14.details.view',
  /** Podgląd wniosku "Przeniesienie uprawnień - nabycie przedsiębiorstwa" (PB6)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB6_D_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb6-d.details.view',
  /** Podgląd wniosku "Przedłużenie ważności wypisów" (PB2)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB2_A_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb2-a.details.view',
  /** Podgląd wniosku "Dodatkowy wypis z licencji" (PB3)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB3_A_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb3-a.details.view',
  /** Podgląd wniosku "Dodatkowy wypis z zezwolenia" (PB3)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB3_B_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb3-b.details.view',
  /** Podgląd wniosku "Zmiana danych na zezwoleniu i licencji - zmiana nazwy / adresu (PB4)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB4_C_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb4-c.details.view',
  /** Podgląd wniosku "Zmiana danych na licencji - zmiana nazwy / adresu" (PB4)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB4_D_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb4-d.details.view',
  /** Podgląd wniosku "Zmiana danych na licencji - odejście wspólnika ze spółki cywilnej" (PB4)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB4_A_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb4-a.details.view',
  /** Podgląd wniosku "Udzielenie licencji na pośrednictwo przy przewozie osób" (PB26)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB26_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb26.details.view',
  /** Podgląd wniosku "Omyłka pisarka" (PB33)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB33_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb33.details.view',
  /** Podgląd wniosku "Zarządca sukcesyjny" (PB27)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB27_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb27.details.view',
  /** Podgląd wniosku "Wyrażenie zgody na wykonywanie uprawnień" (PB28)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB28_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb28.details.view',
  /** Podgląd wniosku "Przeniesienie uprawnień" (PB29)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB29_A_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb29-a.details.view',
  /** Podgląd wniosku "Przeniesienie uprawnień - nabycie przedsiębiorstwa" (PB29)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB29_B_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb29-b.details.view',
  /** Podgląd wniosku "Przeniesienie uprawnień po śmierci" (PB30)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB30_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb30.details.view',
  /** Podgląd wniosku "Wtórnik licencji" (PB31)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB31_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb31.details.view',
  /** Podgląd wniosku "Zmiana danych" (PB32)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB32_A_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb32-a.details.view',
  /** Podgląd wniosku "Zmiana danych - odejście wspólnika ze spółki cywilnej" (PB32)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB32_B_DETAILS_VIEW = 'group.permission.ibtm-domain.application-crud.license-pb32-b.details.view',
  /** Podgląd wniosków SPO */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_ALL_VIEW = 'group.permission.ibtm-domain.application-crud.spo-all.view',
  /** Podgląd wniosku dot. licencji na wykonywanie transportu drogowego w zakresie pośrednictwa przy przewozie osób (PPO) */

  // _ = 'group.permission..'	,
  // /** Podgląd wniosku dot. zezwolenia na przewozy */
  // _ = 'group.permission..'	,
  // /** Podgląd wniosku dot. aktualizacji zabezpieczeń */
  // _ = 'group.permission..'	,
  // /** Podgląd wniosku dot. wydania zaświadczenia */
  // _ = 'group.permission..'	,
  // /** Podgląd wniosku dot. zrzeczenia wypisu */
  // _ = 'group.permission..'	,
  // /** Podgląd wniosku dot. zaświadczenia na potrzeby własne */
  // _ = 'group.permission..'	,
  // /** Podgląd wniosku dot. świadectw kierowców */
  // _ = 'group.permission..'	,

  /** Edycja wniosku */
  IBTM_DOMAIN_APPLICATION_CRUD_UPDATE = 'group.permission.ibtm-domain.application-crud.update',
  /** Edycja wniosku dot. licencji wspólnotowej / zezwolenia na pojazdy powyżej (TP/OP) */
  IBTM_DOMAIN_APPLICATION_CRUD_WL_ALL_UPDATE = 'group.permission.ibtm-domain.application-crud.wl-all.update',
  /** Edycja wniosku "Udzielenie zezwolenia i licencji" (PB1)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB1_A_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb1-a.update',
  /** Edycja wniosku "Zmiana danych na zezwoleniu i licencji - odejście wspólnika ze spółki cywilnej" (PB4)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB4_B_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb4-b.update',
  /** Edycja wniosku "Wtórnik licencji / zezwolenia / wypisów" (PB5)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB5_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb5.update',
  /** Edycja wniosku "Przeniesienie uprawnień z zezwolenia i licencji" (PB6)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB6_A_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb6-a.update',
  /** Edycja wniosku "Przeniesienie uprawnień z licencji" (PB6)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB6_B_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb6-b.update',
  /** Edycja wniosku "Przeniesienie uprawnień z zezwolenia i licencji - nabycie przedsiębiorstwa" (PB6)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB6_C_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb6-c.update',
  /** Edycja wniosku "Przeniesienie uprawnień po śmierci osoby fizycznej" (PB7)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB7_A_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb7-a.update',
  /** Edycja wniosku "Przeniesienie uprawnień z zezwolenia i licencji po śmierci" (PB7)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB7_B_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb7-b.update',
  /** Edycja wniosku "Przeniesienie uprawnień po śmierci osoby fizycznej będącej w spółce cywilnej" (PB7)" */

  // _ = 'group.permission..'	,
  // /** Edycja wniosku "Przeniesienie uprawnień z zezwolenia i licencji po śmierci osoby będącej w spółce cywilnej" (PB7)" */
  // _ = 'group.permission..'	,

  /** Edycja wniosku "Wyrażenie zgody na wykonywanie uprawnień wynikających z licencji" (PB8)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB8_A_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb8-a.update',
  /** Edycja wniosku "Udzielenie licencji" (PB1)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB1_B_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb1-b.update',
  /** Edycja wniosku "Wyrażenie zgody na wykonywanie uprawnień wynikających z zezwolenia i licencji" (PB8)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB8_B_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb8-b.update',
  /** Edycja wniosku "Zarządca sukcesyjny - decyzja z licencji" (PB9)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB9_A_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb9-a.update',
  /** Edycja wniosku "Zarządca sukcesyjny - decyzja z zezwolenia i licencji" (PB9)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB9_B_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb9-b.update',
  /** Edycja wniosku "Zmiana osoby zarządzającej" (PB10)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB10_B_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb10-b.update',
  /** Edycja wniosku "Zmiana bazy eksploatacyjnej" (PB10)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB10_A_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb10-a.update',
  /** Edycja wniosku "Wymiana pojazdów" (PB11)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB11_A_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb11-a.update',
  /** Edycja wniosku "Zmiana numerów rejestracyjnych" (PB11)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB11_B_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb11-b.update',
  /** Edycja wniosku "Zmiana prawa dysponowania" (PB11)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB11_C_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb11-c.update',
  /** Edycja wniosku "Sprzedaż pojazdów" (PB11)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB11_D_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb11-d.update',
  /** Edycja wniosku "Zgłoszenie pojazdu bez wypisu" (PB12)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB12_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb12.update',
  /** Edycja wniosku "Przedłużenie ważności licencji" (PB1)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB1_C_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb1-c.update',
  /** Edycja wniosku "Omyłka pisarska" (PB14)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB14_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb14.update',
  /** Edycja wniosku "Przeniesienie uprawnień - nabycie przedsiębiorstwa" (PB6)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB6_D_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb6-d.update',
  /** Edycja wniosku "Przedłużenie ważności wypisów" (PB2)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB2_A_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb2-a.update',
  /** Edycja wniosku "Dodatkowy wypis z licencji" (PB3)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB3_A_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb3-a.update',
  /** Edycja wniosku "Dodatkowy wypis z zezwolenia" (PB3)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB3_B_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb3-b.update',
  /** Edycja wniosku "Zmiana danych na zezwoleniu i licencji - zmiana nazwy / adresu (PB4)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB4_C_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb4-c.update',
  /** Edycja wniosku "Zmiana danych na licencji - zmiana nazwy / adresu" (PB4)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB4_D_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb4-d.update',
  /** Edycja wniosku "Zmiana danych na licencji - odejście wspólnika ze spółki cywilnej" (PB4)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB4_A_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb4-a.update',
  /** Edycja wniosku dot. licencji na wykonywanie transportu drogowego w zakresie pośrednictwa przy przewozie osób (PPO) */
  IBTM_DOMAIN_APPLICATION_CRUD_PPO_ALL_UPDATE = 'group.permission.ibtm-domain.application-crud.ppo-all.update',
  /** Edycja wniosku "Udzielenie licencji na pośrednictwo przy przewozie osób" (PB26)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB26_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb26.update',
  /** Edycja wniosku "Omyłka pisarka" (PB33)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB33_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb33.update',
  /** Edycja wniosku "Zarządca sukcesyjny" (PB27)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB27_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb27.update',
  /** Edycja wniosku "Wyrażenie zgody na wykonywanie uprawnień" (PB28)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB28_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb28.update',
  /** Edycja wniosku "Przeniesienie uprawnień" (PB29)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB29_A_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb29-a.update',
  /** Edycja wniosku "Przeniesienie uprawnień - nabycie przedsiębiorstwa" (PB29)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB29_B_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb29-b.update',
  /** Edycja wniosku "Przeniesienie uprawnień po śmierci" (PB30)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB30_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb30.update',
  /** Edycja wniosku "Wtórnik licencji" (PB31)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB31_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb31.update',
  /** Edycja wniosku "Zmiana danych" (PB32)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB32_A_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb32-a.update',
  /** Edycja wniosku "Zmiana danych - odejście wspólnika ze spółki cywilnej" (PB32)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB32_B_UPDATE = 'group.permission.ibtm-domain.application-crud.license-pb32-b.update',
  /** Edycja wniosku dot. zezwolenia na przewozy */
  // _ = 'group.permission..'	,
  /** Edycja wniosku dot aktualizacji zabezpieczenia */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_FINANCIAL_SECURITY_UPDATE_UPDATE = 'group.permission.ibtm-domain.application-crud.license-financial-security-update.update',
  /** Edycja wniosku dot. wydania zaświadczenia */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_ISSUING_CERTIFICATE_UPDATE = 'group.permission.ibtm-domain.application-crud.license-issuing-certificate.update',
  /** Edycja wniosku dot. zrzeczenia wypisu */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_DISCHARGE_WAIVER_UPDATE = 'group.permission.ibtm-domain.application-crud.license-discharge-waiver.update',
  /** Edycja wniosku dot. zaświadczeń na potrzeby własne */
  // _ = 'group.permission..'	,
  // /** Edycja wniosku dot. świadectw kierowców */
  // _ = 'group.permission..'	,
  /** Edycja wniosku dot. zezwolenia na przewozy */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_ALL_UPDATE = 'group.permission.ibtm-domain.application-crud.spo-all.update',
  /** Edycja wniosku dot. zaświadczenia na potrzeby własne */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_OWN_NEEDS_ALL_UPDATE = 'group.permission.ibtm-domain.application-crud.spo-own-needs-all.update',
  /** Dodanie wniosku dot. świadectwa kierowcy */
  IBTM_DOMAIN_APPLICATION_CRUD_DRIVER_CERTIFICATE_ALL_CREATE = 'group.permission.ibtm-domain.application-crud.driver-certificate-all.create',
  /** Dodanie wniosku dot. wydania świadectwa kierowcy (SK1) */
  IBTM_DOMAIN_APPLICATION_CRUD_ISSUE_OF_DRIVER_CERTIFICATE_CREATE = 'group.permission.ibtm-domain.application-crud.issue-of-driver-certificate.create',
  /** Dodanie wniosku dot. kontynuacji/odnowienia świadectwa kierowcy (SK2) */
  IBTM_DOMAIN_APPLICATION_CRUD_RESTORE_OF_DRIVER_CERTIFICATE_CREATE = 'group.permission.ibtm-domain.application-crud.restore-of-driver-certificate.create',
  /** Edycja wniosku dot. świadectwa kierowcy */
  IBTM_DOMAIN_APPLICATION_CRUD_DRIVER_CERTIFICATE_ALL_UPDATE = 'group.permission.ibtm-domain.application-crud.driver-certificate-all.update',
  /** Edycja wniosku dot. wydania świadectwa kierowcy (SK1) */
  IBTM_DOMAIN_APPLICATION_CRUD_ISSUE_OF_DRIVER_CERTIFICATE_UPDATE = 'group.permission.ibtm-domain.application-crud.issue-of-driver-certificate.update',
  /** Podgląd wniosku dot. świadectwa kierowcy */
  IBTM_DOMAIN_APPLICATION_CRUD_DRIVER_CERTIFICATE_ALL_VIEW = 'group.permission.ibtm-domain.application-crud.driver-certificate-all.view',
  /** Podgląd wniosku dot. wydania świadectwa kierowcy (SK1) */
  IBTM_DOMAIN_APPLICATION_CRUD_ISSUE_OF_DRIVER_CERTIFICATE_VIEW = 'group.permission.ibtm-domain.application-crud.issue-of-driver-certificate.view',
  /** Dodanie wniosku dot. zmiany świadectwa kierowcy (SK3) */
  IBTM_DOMAIN_APPLICATION_CRUD_CHANGE_OF_DRIVER_CERTIFICATE_CREATE = 'group.permission.ibtm-domain.application-crud.change-of-driver-certificate.create',
  /** Dodanie wniosku dot. duplikatu (wtórnika) świadectwa kierowcy (SK4) */
  IBTM_DOMAIN_APPLICATION_CRUD_ISSUING_DUPLICATE_DRIVER_CERTIFICATE_CREATE = 'group.permission.ibtm-domain.application-crud.issuing-duplicate-driver-certificate.create',
  /** Dodanie wniosku dot. obsługi omyłki pisarskiej w wydanym świadectwie kierowcy */
  IBTM_DOMAIN_APPLICATION_CRUD_INPUT_MISTAKE_CREATE = 'group.permission.ibtm-domain.application-crud.input-mistake.create',
  /** Edycja wniosku dot. kontynuacji/odnowienia świadectwa kierowcy (SK2) */
  IBTM_DOMAIN_APPLICATION_CRUD_RESTORE_OF_DRIVER_CERTIFICATE_UPDATE = 'group.permission.ibtm-domain.application-crud.restore-of-driver-certificate.update',
  /** Edycja wniosku dot. zmiany świadectwa kierowcy (SK3) */
  IBTM_DOMAIN_APPLICATION_CRUD_CHANGE_OF_DRIVER_CERTIFICATE_UPDATE = 'group.permission.ibtm-domain.application-crud.change-of-driver-certificate.update',
  /** Edycja wniosku dot. duplikatu (wtórnika) świadectwa kierowcy (SK4) */
  IBTM_DOMAIN_APPLICATION_CRUD_ISSUING_DUPLICATE_DRIVER_CERTIFICATE_UPDATE = 'group.permission.ibtm-domain.application-crud.issuing-duplicate-driver-certificate.update',
  /** Edycja wniosku dot. obsługi omyłki pisarskiej w wydanym świadectwie kierowcy */
  IBTM_DOMAIN_APPLICATION_CRUD_INPUT_MISTAKE_UPDATE = 'group.permission.ibtm-domain.application-crud.input-mistake.update',
  /** Podgląd wniosku dot. kontynuacji/odnowienia świadectwa kierowcy (SK2) */
  IBTM_DOMAIN_APPLICATION_CRUD_RESTORE_OF_DRIVER_CERTIFICATE_VIEW = 'group.permission.ibtm-domain.application-crud.restore-of-driver-certificate.view',
  /** Podgląd wniosku dot. zmiany świadectwa kierowcy (SK3) */
  IBTM_DOMAIN_APPLICATION_CRUD_CHANGE_OF_DRIVER_CERTIFICATE_VIEW = 'group.permission.ibtm-domain.application-crud.change-of-driver-certificate.view',
  /** Podgląd wniosku dot. duplikatu (wtórnika) świadectwa kierowcy (SK4) */
  IBTM_DOMAIN_APPLICATION_CRUD_ISSUING_DUPLICATE_DRIVER_CERTIFICATE_VIEW = 'group.permission.ibtm-domain.application-crud.issuing-duplicate-driver-certificate.view',
  /** Podgląd wniosku dot. obsługi omyłki pisarskiej w wydanym świadectwie kierowcy */
  IBTM_DOMAIN_APPLICATION_CRUD_INPUT_MISTAKE_VIEW = 'group.permission.ibtm-domain.application-crud.input-mistake.view',
  /** Utworzenie wniosku dot. wydania certyfiaktu S */
  IBTM_DOMAIN_APPLICATION_CRUD_S_CERTIFICATE_ISSUING_DOCUMENT_CREATE = 'group.permission.ibtm-domain.application-crud.s-certificate-issuing-document.create',
  /** Utworzenie wniosku dot. wznowienia certyfiaktu S */
  IBTM_DOMAIN_APPLICATION_CRUD_S_CERTIFICATE_RESUMING_ISSUE_OF_DOCUMENT_CREATE = 'group.permission.ibtm-domain.application-crud.s-certificate-resuming-issue-of-document.create',
  /** Utworzenie wniosku dot. zmiany w certyfikacie S */
  IBTM_DOMAIN_APPLICATION_CRUD_S_CERTIFICATE_CHANGE_OF_DOCUMENT_CREATE = 'group.permission.ibtm-domain.application-crud.s-certificate-change-of-document.create',
  /** Utworzenie wniosku dot. wydanie duplikatu certyfikatu S */
  IBTM_DOMAIN_APPLICATION_CRUD_S_CERTIFICATE_ISSUING_DUPLICATE_CREATE = 'group.permission.ibtm-domain.application-crud.s-certificate-issuing-duplicate.create',
  /** Utworzenie zamówienia */
  IBTM_DOMAIN_APPLICATION_CRUD_WZZ_ALL_CREATE = 'group.permission.ibtm-domain.application-crud.wzz-all.create',
  /** Dodanie wniosku dot. licencji wspólnotowej / zezwolenia na pojazdy powyżej (TP/OP) */
  IBTM_DOMAIN_APPLICATION_CRUD_WL_ALL_CREATE = 'group.permission.ibtm-domain.application-crud.wl-all.create',
  /** Dodanie wniosku "Udzielenie zezwolenia i licencji" (PB1)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB1_A_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb1-a.create',
  /** Dodanie wniosku "Udzielenie licencji" (PB1)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB1_B_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb1-b.create',
  /** Dodanie wniosku "Przedłużenie ważności licencji" (PB1)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB1_C_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb1-c.create',
  /** Dodanie wniosku "Przedłużenie ważności wypisów" (PB2)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB2_A_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb2-a.create',
  /** Dodanie wniosku "Dodatkowy wypis z licencji" (PB3)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB3_A_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb3-a.create',
  /** Dodanie wniosku "Dodatkowy wypis z zezwolenia" (PB3)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB3_B_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb3-b.create',
  /** Dodanie wniosku "Zmiana danych na zezwoleniu i licencji - zmiana nazwy / adresu (PB4)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB4_C_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb4-c.create',
  /** Dodanie wniosku "Zmiana danych na licencji - zmiana nazwy / adresu" (PB4)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB4_D_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb4-d.create',
  /** Dodanie wniosku "Udzielenie licencji na pośrednictwo osób" (LPO - PB26)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB26_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb26.create',
  /** Dodanie wniosku "Zmiana danych na licencji - odejście wspólnika ze spółki cywilnej" (PB4)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB4_A_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb4-a.create',
  /** Dodanie wniosku "Zmiana danych na zezwoleniu i licencji - odejście wspólnika ze spółki cywilnej" (PB4)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB4_B_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb4-b.create',
  /** Dodanie wniosku "Wtórnik licencji / zezwolenia / wypisów" (PB5)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB5_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb5.create',
  /** Dodanie wniosku "Przeniesienie uprawnień z zezwolenia i licencji" (PB6)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB6_A_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb6-a.create',
  /** Dodanie wniosku "Przeniesienie uprawnień z licencji" (PB6)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB6_B_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb6-b.create',
  /** Dodanie wniosku "Przeniesienie uprawnień z zezwolenia i licencji - nabycie przedsiębiorstwa" (PB6)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB6_C_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb6-c.create',
  /** Dodanie wniosku "Przeniesienie uprawnień po śmierci osoby fizycznej" (PB7)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB7_A_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb7-a.create',
  /** Dodanie wniosku "Przeniesienie uprawnień z zezwolenia i licencji po śmierci" (PB7)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB7_B_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb7-b.create',
  /** Dodanie wniosku "Przeniesienie uprawnień po śmierci osoby fizycznej będącej w spółce cywilnej" (PB7)" */
  // _ = 'group.permission..'	,
  // /** Dodanie podstawowych danych o wniosku LR6 / LO6 */
  // _ = 'group.permission..'	,
  // /** Dodanie wniosku "Przeniesienie uprawnień z zezwolenia i licencji po śmierci osoby będącej w spółce cywilnej" (PB7)" */
  // _ = 'group.permission..'	,
  // /** Dodanie podstawowych danych o wniosku LR6 / LO6 */
  // _ = 'group.permission..'	,
  /** Dodanie wniosku "Wyrażenie zgody na wykonywanie uprawnień wynikających z licencji" (PB8)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB8_A_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb8-a.create',
  /** Dodanie wniosku "Wyrażenie zgody na wykonywanie uprawnień wynikających z zezwolenia i licencji" (PB8)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB8_B_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb8-b.create',
  /** Dodanie wniosku "Zarządca sukcesyjny - decyzja z licencji" (PB9)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB9_A_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb9-a.create',
  /** Dodanie wniosku "Zarządca sukcesyjny - decyzja z zezwolenia i licencji" (PB9)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB9_B_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb9-b.create',
  /** Dodanie wniosku "Zmiana osoby zarządzającej" (PB10)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB10_B_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb10-b.create',
  /** Dodanie wniosku "Zmiana bazy eksploatacyjnej" (PB10)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB10_A_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb10-a.create',
  /** Dodanie wniosku "Wymiana pojazdów" (PB11)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB11_A_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb11-a.create',
  /** Dodanie wniosku "Zmiana numerów rejestracyjnych" (PB11)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB11_B_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb11-b.create',
  /** Dodanie wniosku "Zmiana prawa dysponowania" (PB11)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB11_C_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb11-c.create',
  /** Dodanie wniosku "Sprzedaż pojazdów" (PB11)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB11_D_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb11-d.create',
  /** Dodanie wniosku "Zgłoszenie pojazdu bez wypisu" (PB12)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB12_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb12.create',
  /** Dodanie wniosku "Omyłka pisarska" (PB14)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB14_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb14.create',
  /** Dodanie wniosku dot. licencji na wykonywanie transportu drogowego w zakresie pośrednictwa przy przewozie osób (PPO) */
  IBTM_DOMAIN_APPLICATION_CRUD_PPO_ALL_CREATE = 'group.permission.ibtm-domain.application-crud.ppo-all.create',
  /** Dodanie wniosku "Udzielenie licencji na pośrednictwo przy przewozie osób" (PB26)" */
  // _ = 'group.permission..'	,
  /** Dodanie wniosku "Zarządca sukcesyjny" (PB27)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB27_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb27.create',
  /** Dodanie wniosku "Wyrażenie zgody na wykonywanie uprawnień" (PB28)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB28_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb28.create',
  /** Dodanie wniosku "Przeniesienie uprawnień" (PB29)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB29_A_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb29-a.create',
  /** Dodanie wniosku "Przeniesienie uprawnień - nabycie przedsiębiorstwa" (PB29)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB29_B_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb29-b.create',
  /** Dodanie wniosku "Przeniesienie uprawnień po śmierci" (PB30)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB30_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb30.create',
  /** Dodanie wniosku "Wtórnik licencji" (PB31)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB31_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb31.create',
  /** Dodanie wniosku "Zmiana danych" (PB32)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB32_A_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb32-a.create',
  /** Dodanie wniosku "Zmiana danych - odejście wspólnika ze spółki cywilnej" (PB32)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB32_B_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb32-b.create',
  /** Dodanie wniosku "Omyłka pisarka" (PB33)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB33_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb33.create',
  /** Dodanie wniosku dot. licencji */
  // _ = 'group.permission..'	,
  /** Wyświetlenie danych podstawowych */
  IBTM_DOMAIN_APPLICATION_CRUD_BASIC_INFORMATION_VIEW = 'group.permission.ibtm-domain.application-crud.basic-information.view',
  /** Wezwanie do uzupełnienia */
  // _ = 'group.permission..'	,
  // /** Przekazanie do weryfikacji */
  // _ = 'group.permission..'	,
  /** Dodanie wniosku aktualizacja zabezpieczenia */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_FINANCIAL_SECURITY_UPDATE_CREATE = 'group.permission.ibtm-domain.application-crud.license-financial-security-update.create',
  /** Wyświetlenie danych podstawowych o wniosku dot. aktualizacji zabezpieczeń */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_FINANCIAL_SECURITY_UPDATE_VIEW = 'group.permission.ibtm-domain.application-crud.license-financial-security-update.view',
  /** Dodanie wniosku wydanie zaświadczenia */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_ISSUING_CERTIFICATE_CREATE = 'group.permission.ibtm-domain.application-crud.license-issuing-certificate.create',
  /** Dodanie wniosku zrzeczenie wypisu */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_DISCHARGE_WAIVER_CREATE = 'group.permission.ibtm-domain.application-crud.license-discharge-waiver.create',
  /** Dodanie wniosku dot. zezwolenia na przewozy */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_ALL_CREATE = 'group.permission.ibtm-domain.application-crud.spo-all.create',
  /** Dodanie wniosku dot. regularnych przewozów w ramach UE (PB39) (WPR UE) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_UE_ALL_CREATE = 'group.permission.ibtm-domain.application-crud.spo-ue-all.create',
  /** Dodanie wniosku Rozpoczęcie (WPR UE) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_UE_LAUNCH_CREATE = 'group.permission.ibtm-domain.application-crud.spo-ue-launch.create',
  /** Dodanie wniosku Decyzja o zmianie warunków (WPR UE) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_UE_DECISION_TO_CHANGE_TERMS_CREATE = 'group.permission.ibtm-domain.application-crud.spo-ue-decision-to-change-terms.create',
  /** Dodanie wniosku Przedłużenie (WPR UE) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_UE_EXTEND_CREATE = 'group.permission.ibtm-domain.application-crud.spo-ue-extend.create',
  /** Dodanie wniosku Dodatkowy wypis (WPR UE) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_UE_ADDITIONAL_EXCERPT_CREATE = 'group.permission.ibtm-domain.application-crud.spo-ue-additional-excerpt.create',
  /** Dodanie wniosku Modyfikacja (WPR UE) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_UE_MODIFICATION_CREATE = 'group.permission.ibtm-domain.application-crud.spo-ue-modification.create',
  /** Dodanie wniosku Modyfikacja bez druku (WPR UE) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_UE_MODIFICATION_WITHOUT_PRINTING_CREATE = 'group.permission.ibtm-domain.application-crud.spo-ue-modification-without-printing.create',
  /** Dodanie wniosku Duplikat zezwolenia (WPR UE) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_UE_DUPLICATE_PERMIT_CREATE = 'group.permission.ibtm-domain.application-crud.spo-ue-duplicate-permit.create',
  /** Dodanie wniosku Duplikat wypisu (WPR UE) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_UE_DUPLICATE_OF_EXTRACT_CREATE = 'group.permission.ibtm-domain.application-crud.spo-ue-duplicate-of-extract.create',
  /** Dodanie wniosku Darmowy przedruk (WPR UE) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_UE_FREE_REPRINT_CREATE = 'group.permission.ibtm-domain.application-crud.spo-ue-free-reprint.create',
  /** Dodanie wniosku dot. regularnych przewozów poza UE (PB40) (WPR poza UE) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_OUTSIDE_UE_ALL_CREATE = 'group.permission.ibtm-domain.application-crud.spo-outside-ue-all.create',
  /** Dodanie wniosku Rozpoczęcie (WPR poza UE) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_OUTSIDE_UE_LAUNCH_CREATE = 'group.permission.ibtm-domain.application-crud.spo-outside-ue-launch.create',
  /** Dodanie wniosku Decyzja o zmianie warunków (WPR poza UE) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_OUTSIDE_UE_DECISION_TO_CHANGE_TERMS_CREATE = 'group.permission.ibtm-domain.application-crud.spo-outside-ue-decision-to-change-terms.create',
  /** Dodanie wniosku Przedłużenie (WPR poza UE) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_OUTSIDE_UE_EXTEND_CREATE = 'group.permission.ibtm-domain.application-crud.spo-outside-ue-extend.create',
  /** Dodanie wniosku Dodatkowy wypis (WPR poza UE) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_OUTSIDE_UE_ADDITIONAL_EXCERPT_CREATE = 'group.permission.ibtm-domain.application-crud.spo-outside-ue-additional-excerpt.create',
  /** Dodanie wniosku Modyfikacja (WPR poza UE) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_OUTSIDE_UE_MODIFICATION_CREATE = 'group.permission.ibtm-domain.application-crud.spo-outside-ue-modification.create',
  /** Dodanie wniosku Modyfikacja bez druku (WPR poza UE) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_OUTSIDE_UE_MODIFICATION_WITHOUT_PRINTING_CREATE = 'group.permission.ibtm-domain.application-crud.spo-outside-ue-modification-without-printing.create',
  /** Dodanie wniosku Duplikat zezwolenia (WPR poza UE) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_OUTSIDE_UE_DUPLICATE_PERMIT_CREATE = 'group.permission.ibtm-domain.application-crud.spo-outside-ue-duplicate-permit.create',
  /** Dodanie wniosku Duplikat wypisu (WPR poza UE) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_OUTSIDE_UE_DUPLICATE_OF_EXTRACT_CREATE = 'group.permission.ibtm-domain.application-crud.spo-outside-ue-duplicate-of-extract.create',
  /** Dodanie wniosku Darmowy przedruk (WPR poza UE) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_OUTSIDE_UE_FREE_REPRINT_CREATE = 'group.permission.ibtm-domain.application-crud.spo-outside-ue-free-reprint.create',
  /** Dodanie wniosku dot. przewozu okazjonalnego (PB41) (WZO) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_WZO_ALL_CREATE = 'group.permission.ibtm-domain.application-crud.spo-wzo-all.create',
  /** Dodanie wniosku Rozpoczęcie (WZO) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_WZO_LAUNCH_CREATE = 'group.permission.ibtm-domain.application-crud.spo-wzo-launch.create',
  /** Dodanie wniosku Decyzja o zmianie warunków (WZO) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_WZO_DECISION_TO_CHANGE_TERMS_CREATE = 'group.permission.ibtm-domain.application-crud.spo-wzo-decision-to-change-terms.create',
  /** Dodanie wniosku Dodatkowy wypis (WZO) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_WZO_ADDITIONAL_EXCERPT_CREATE = 'group.permission.ibtm-domain.application-crud.spo-wzo-additional-excerpt.create',
  /** Dodanie wniosku Modyfikacja (WZO) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_WZO_MODIFICATION_CREATE = 'group.permission.ibtm-domain.application-crud.spo-wzo-modification.create',
  /** Dodanie wniosku Duplikat zezwolenia (WZO) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_WZO_DUPLICATE_PERMIT_CREATE = 'group.permission.ibtm-domain.application-crud.spo-wzo-duplicate-permit.create',
  /** Dodanie wniosku Duplikat wypisu (WZO) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_WZO_DUPLICATE_OF_EXTRACT_CREATE = 'group.permission.ibtm-domain.application-crud.spo-wzo-duplicate-of-extract.create',
  /** Dodanie wniosku Darmowy przedruk (WZO) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_WZO_FREE_REPRINT_CREATE = 'group.permission.ibtm-domain.application-crud.spo-wzo-free-reprint.create',
  /** Dodanie wniosku dot. przewozu wahadłowego (PB.42) (WZW) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_WZW_ALL_CREATE = 'group.permission.ibtm-domain.application-crud.spo-wzw-all.create',
  /** Dodanie wniosku Rozpoczęcie (WZW) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_WZW_LAUNCH_CREATE = 'group.permission.ibtm-domain.application-crud.spo-wzw-launch.create',
  /** Dodanie wniosku Decyzja o zmianie warunków (WZW) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_WZW_DECISION_TO_CHANGE_TERMS_CREATE = 'group.permission.ibtm-domain.application-crud.spo-wzw-decision-to-change-terms.create',
  /** Dodanie wniosku Dodatkowy wypis (WZW) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_WZW_ADDITIONAL_EXCERPT_CREATE = 'group.permission.ibtm-domain.application-crud.spo-wzw-additional-excerpt.create',
  /** Dodanie wniosku Modyfikacja (WZW) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_WZW_MODIFICATION_CREATE = 'group.permission.ibtm-domain.application-crud.spo-wzw-modification.create',

  /** Dodanie wniosku Duplikat zezwolenia (WZW) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_WZW_DUPLICATE_PERMIT_CREATE = 'group.permission.ibtm-domain.application-crud.spo-wzw-duplicate-permit.create',
  /** Dodanie wniosku Duplikat Wypisu (WZW) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_WZW_DUPLICATE_OF_EXTRACT_CREATE = 'group.permission.ibtm-domain.application-crud.spo-wzw-duplicate-of-extract.create',
  /** Dodanie wniosku Przedruk z winy BTM (WZW) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_WZW_REPRINTED_AT_FAULT_BTM_CREATE = 'group.permission.ibtm-domain.application-crud.spo-wzw-reprinted-at-fault-btm.create',
  /** Dodanie wniosku dot. przewozu regularnego w tranzycie przez teren RP (PB.43) (Tranzyt przez RP) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_TRANSIT_RP_ALL_CREATE = 'group.permission.ibtm-domain.application-crud.spo-transit-rp-all.create',
  /** Dodanie wniosku Rozpoczęcie (Tranzyt przez RP) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_TRANSIT_RP_LAUNCH_CREATE = 'group.permission.ibtm-domain.application-crud.spo-transit-rp-launch.create',
  /** Dodanie wniosku Przedłużenie (Tranzyt przez RP) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_TRANSIT_RP_EXTEND_CREATE = 'group.permission.ibtm-domain.application-crud.spo-transit-rp-extend.create',
  /** Dodanie wniosku Dodatkowy wypis (Tranzyt przez RP) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_TRANSIT_RP_ADDITIONAL_EXCERPT_CREATE = 'group.permission.ibtm-domain.application-crud.spo-transit-rp-additional-excerpt.create',
  /** Dodanie wniosku Modyfikacja (Tranzyt przez RP) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_TRANSIT_RP_MODIFICATION_CREATE = 'group.permission.ibtm-domain.application-crud.spo-transit-rp-modification.create',
  /** Dodanie wniosku Duplikat zezwolenia (Tranzyt przez RP) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_TRANSIT_RP_DUPLICATE_PERMIT_CREATE = 'group.permission.ibtm-domain.application-crud.spo-transit-rp-duplicate-permit.create',
  /** Dodanie wniosku Duplikat wypisu (Tranzyt przez RP) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_TRANSIT_RP_DUPLICATE_OF_EXTRACT_CREATE = 'group.permission.ibtm-domain.application-crud.spo-transit-rp-duplicate-of-extract.create',
  /** Dodanie wniosku Przedruk z winy BTM (Tranzyt przez RP) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_TRANSIT_RP_REPRINTED_AT_FAULT_BTM_CREATE = 'group.permission.ibtm-domain.application-crud.spo-transit-rp-reprinted-at-fault-btm.create',
  /** Dodanie wniosku dot. wydania opinii dla organu zagranicznego (PB.44) (Opinia) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_POSITION_MIND_ALL_CREATE = 'group.permission.ibtm-domain.application-crud.spo-position-mind-all.create',
  /** Dodanie wniosku Rozpoczęcie (PB.44) (Opinia) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_POSITION_MIND_LAUNCH_CREATE = 'group.permission.ibtm-domain.application-crud.spo-position-mind-launch.create',
  /** Dodanie wniosku Przedłużenie (PB.44) (Opinia) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_POSITION_MIND_EXTEND_CREATE = 'group.permission.ibtm-domain.application-crud.spo-position-mind-extend.create',
  /** Dodanie wniosku Modyfikacja (PB.44) (Opinia) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_POSITION_MIND_MODIFICATION_CREATE = 'group.permission.ibtm-domain.application-crud.spo-position-mind-modification.create',
  /** Dodanie wniosku dot. zaświadczenia na potrzeby własne */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_OWN_NEEDS_ALL_CREATE = 'group.permission.ibtm-domain.application-crud.spo-own-needs-all.create',
  /** Dodanie wniosku o wydanie zaświadczenia rzeczy (PWR1) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_OWN_NEEDS_PWR1_CREATE = 'group.permission.ibtm-domain.application-crud.spo-own-needs-pwr1.create',
  /** Dodanie wniosku o dodatkowego wypisu - rzeczy (PWR2) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_OWN_NEEDS_PWR2_CREATE = 'group.permission.ibtm-domain.application-crud.spo-own-needs-pwr2.create',
  /** Dodanie wniosku o wymianę blankietów - rzeczy (PWR3) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_OWN_NEEDS_PWR3_CREATE = 'group.permission.ibtm-domain.application-crud.spo-own-needs-pwr3.create',
  /** Dodanie wniosku o wymianę blankietów - darmowy przedruk z winy BTM - rzeczy (PWR3) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_OWN_NEEDS_PWR3_REPRINT_CREATE = 'group.permission.ibtm-domain.application-crud.spo-own-needs-pwr3-reprint.create',
  /** Dodanie wniosku o zmianę pojazdu - rzeczy (IPWZ) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_OWN_NEEDS_IPWZ_CREATE = 'group.permission.ibtm-domain.application-crud.spo-own-needs-ipwz.create',
  /** Dodanie wniosku - informacji o sprzedaży pojazdu - rzeczy (IPWZ-info) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_OWN_NEEDS_IPWZ_INFO_CREATE = 'group.permission.ibtm-domain.application-crud.spo-own-needs-ipwz-info.create',
  /** Dodanie wniosku o wydanie zaświadczenia - osoby (PWO1) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_OWN_NEEDS_PWO1_CREATE = 'group.permission.ibtm-domain.application-crud.spo-own-needs-pwo1.create',
  /** Dodanie wniosku o wymianę blankietów - osoby (PWO2) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_OWN_NEEDS_PWO2_CREATE = 'group.permission.ibtm-domain.application-crud.spo-own-needs-pwo2.create',
  /** Dodanie wniosku o wymianę blankietów -darmowy przedruk z winy BTM - osoby (PWO2) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_OWN_NEEDS_PWO2_REPRINT_CREATE = 'group.permission.ibtm-domain.application-crud.spo-own-needs-pwo2-reprint.create',
  /** Wniosek o zezwolenia jednorazowe przewóz rzeczy */
  IBTM_DOMAIN_APPLICATION_CRUD_FOREIGN_PERMIT_SINGLE_TP_CREATE = 'group.permission.ibtm-domain.application-crud.foreign-permit-single-tp.create',
  /** Wniosek o zezwolenia jednorazowe przewóz osób */
  IBTM_DOMAIN_APPLICATION_CRUD_FOREIGN_PERMIT_SINGLE_OP_CREATE = 'group.permission.ibtm-domain.application-crud.foreign-permit-single-op.create',
  /** Wniosek o zezwolenia EKMT */
  IBTM_DOMAIN_APPLICATION_CRUD_FOREIGN_PERMIT_EKMT_CREATE = 'group.permission.ibtm-domain.application-crud.foreign-permit-ekmt.create',
  /** Dodanie wniosku "Przedłużenie ważności wypisów - gdy zezwolenie w STA" (PB2)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB2_B_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb2-b.create',
  /** Dodanie wniosku "Przeniesienie uprawnień - nabycie przedsiębiorstwa" (PB6)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PB6_D_CREATE = 'group.permission.ibtm-domain.application-crud.license-pb6-d.create',
  /** Dodanie wniosku "Baza eksploatacyjna" (PB10)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PPO_PB10_A_CREATE = 'group.permission.ibtm-domain.application-crud.license-ppo-pb10-a.create',
  /** Dodanie wniosku "Wymiana pojazdów" (PB11)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PPO_PB11_A_CREATE = 'group.permission.ibtm-domain.application-crud.license-ppo-pb11-a.create',
  /** Dodanie wniosku "Zmiana numerów rejestracyjnych" (PB11)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PPO_PB11_B_CREATE = 'group.permission.ibtm-domain.application-crud.license-ppo-pb11-b.create',
  /** Dodanie wniosku "Zmiana prawa dysponowania" (PB11)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PPO_PB11_C_CREATE = 'group.permission.ibtm-domain.application-crud.license-ppo-pb11-c.create',
  /** Dodanie wniosku "Sprzedaż pojazdów" (PB11)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PPO_PB11_D_CREATE = 'group.permission.ibtm-domain.application-crud.license-ppo-pb11-d.create',
  /** Dodanie wniosku "Zgłoszenie pojazdu bez wypisu" (PB12)" */
  IBTM_DOMAIN_APPLICATION_CRUD_LICENSE_PPO_PB12_CREATE = 'group.permission.ibtm-domain.application-crud.license-ppo-pb12.create',
  /** Dodanie wniosku Decyzja o zmianie warunków (Tranzyt przez RP) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_TRANSIT_RP_DECISION_TO_CHANGE_TERMS_CREATE = 'group.permission.ibtm-domain.application-crud.spo-transit-rp-decision-to-change-terms.create',
  /** 	Dodanie wniosku dot. formulary jazd UE/INTERBUS */
  IBTM_DOMAIN_APPLICATION_CRUD_TRAVEL_FORM_UE_INTERBUS_CREATE = 'group.permission.ibtm-domain.application-crud.travel-form-ue-interbus.create',
  /** Dodanie wniosku Podanie (WZO) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_WZO_APPLICATION_CREATE = 'group.permission.ibtm-domain.application-crud.spo-wzo-application.create',
  /** Dodanie wniosku Modyfikacja bez druku (WZO) */
  IBTM_DOMAIN_APPLICATION_CRUD_SPO_WZO_MODIFICATION_WITHOUT_PRINTING_CREATE = 'group.permission.ibtm-domain.application-crud.spo-wzo-modification-without-printing.create',
  /** Wyświetlenie listy dokumentów wydania */
  IBTM_DOMAIN_INVOICE_LIST_VIEW = 'group.permission.ibtm-domain.invoice.list.view',
  /** Utworzenie korekty dokumentu wydania */
  IBTM_DOMAIN_INVOICE_CORRECTION_CREATE = 'group.permission.ibtm-domain.invoice.correction.create',
  /** Edycja korekty dokumentu wydania */
  IBTM_DOMAIN_INVOICE_CORRECTION_UPDATE = 'group.permission.ibtm-domain.invoice.correction.update',
  /** Przeglądanie szczegółów dokumentu wydania / korekty dokumentu wydania */
  IBTM_DOMAIN_INVOICE_DETAILS_VIEW = 'group.permission.ibtm-domain.invoice.details.view',
  /** Anulowanie korekty dokumentu wydania */
  IBTM_DOMAIN_INVOICE_CORRECTION_CANCEL = 'group.permission.ibtm-domain.invoice.correction.cancel',
  /** Pobranie dokumentu wydania / korekty dokumentu wydania */
  IBTM_DOMAIN_INVOICE_CONTENT_DOWNLOAD = 'group.permission.ibtm-domain.invoice.content.download',

  /** Wyświetlenie listy wypożyczeń teczek */
  IBTM_DOMAIN_ARCHIVE_BORROWED_FOLDERS_VIEW = 'group.permission.ibtm-domain.archive.borrowed-folders.view',
  /** Zmień osobę wypożyczającą */
  IBTM_DOMAIN_ARCHIVE_BORROWER_UPDATE = 'group.permission.ibtm-domain.archive.borrower.update',
  /** Wypożycz teczkę */
  IBTM_DOMAIN_ARCHIVE_FOLDER_BORROW = 'group.permission.ibtm-domain.archive.folder.borrow',
  /** Zwróć teczkę */
  IBTM_DOMAIN_ARCHIVE_FOLDER_RETURN = 'group.permission.ibtm-domain.archive.folder.return',

  /** Wyświetlenie listy dokumentów EZD */
  IBTM_DOMAIN_EZD_DOCUMENT_LIST_VIEW = 'group.permission.ibtm-domain.ezd-document.list.view',
  /** Wyświetlenie Archiwum */
  IBTM_DOMAIN_ARCHIVE_MENU_VIEW = 'group.permission.ibtm-domain.archive.menu.view',
  /** Wyświetlenie teczek podlegających archiwizacji */
  IBTM_DOMAIN_ARCHIVE_FOLDER_TO_ARCHIVE_VIEW = 'group.permission.ibtm-domain.archive.folder-to-archive.view',
  /** Wyświetlenie listy zarchiwizowanych teczek */
  IBTM_DOMAIN_ARCHIVE_FOLDER_ARCHIVED_VIEW = 'group.permission.ibtm-domain.archive.folder-archived.view',
  /** Wyświetlenie listy wniosków podlegających archiwizacji */
  IBTM_DOMAIN_ARCHIVE_APPLICATION_TO_ARCHIVE_VIEW = 'group.permission.ibtm-domain.archive.application-to-archive.view',
  /** Wyświetlenie listy wniosków zarchiwizowanych */
  IBTM_DOMAIN_ARCHIVE_APPLICATION_ARCHIVED_VIEW = 'group.permission.ibtm-domain.archive.application-archived.view',
  /** Przekazanie teczki do archiwum zakładowego */
  IBTM_DOMAIN_ARCHIVE_FOLDER_TO_REPOSITORY_TRANSFER = 'group.permission.ibtm-domain.archive.folder-to-repository.transfer',
  /** Wycofanie teczki z archiwum zakładowego */
  IBTM_DOMAIN_ARCHIVE_FOLDER_TO_REPOSITORY_WITHDRAW = 'group.permission.ibtm-domain.archive.folder-to-repository.withdraw',
  /** Przekazanie wniosku do archiwum zakładowego */
  IBTM_DOMAIN_ARCHIVE_APPLICATION_TO_REPOSITORY_TRANSFER = 'group.permission.ibtm-domain.archive.application-to-repository.transfer',
  /** Przekazanie wielu wniosków do archiwum zakładowego */
  IBTM_DOMAIN_ARCHIVE_APPLICATION_TO_REPOSITORY_TRANSFER_MANY = 'group.permission.ibtm-domain.archive.application-to-repository.transfer-many',
  /** Wycofanie wniosku z archiwum zakładowego */
  IBTM_DOMAIN_ARCHIVE_APPLICATION_TO_REPOSITORY_WITHDRAW = 'group.permission.ibtm-domain.archive.application-to-repository.withdraw',
  /** Dodanie uwagi archiwizacyjnej do teczki */
  IBTM_DOMAIN_ARCHIVE_FOLDER_NOTE_CREATE = 'group.permission.ibtm-domain.archive.folder-note.create',
  /** Dodanie uwagi archiwizacyjnej do wniosku */
  IBTM_DOMAIN_ARCHIVE_APPLICATION_NOTE_CREATE = 'group.permission.ibtm-domain.archive.application-note.create',
  /** _ = 'group.permission..'	 */
  /** Dodanie postępowania weryfikacyjnego */
  IBTM_DOMAIN_PROCEEDING_VERIFICATION_CREATE = 'group.permission.ibtm-domain.proceeding.verification.create',
  /** Edytowanie postępowania weryfikacyjnego */
  IBTM_DOMAIN_PROCEEDING_VERIFICATION_UPDATE = 'group.permission.ibtm-domain.proceeding.verification.update',
  /** Usunięcie postępowania weryfikacyjnego */
  IBTM_DOMAIN_PROCEEDING_VERIFICATION_DELETE = 'group.permission.ibtm-domain.proceeding.verification.delete',
  /** Podgląd postępowania weryfikacyjnego */
  IBTM_DOMAIN_PROCEEDING_VERIFICATION_DETAILS_VIEW = 'group.permission.ibtm-domain.proceeding.verification.details.view',
  /** Dodanie postępowania administracyjnego */
  IBTM_DOMAIN_PROCEEDING_ADMINISTRATIVE_CREATE = 'group.permission.ibtm-domain.proceeding.administrative.create',
  /** Edytowanie postępowania administracyjnego */
  IBTM_DOMAIN_PROCEEDING_ADMINISTRATIVE_UPDATE = 'group.permission.ibtm-domain.proceeding.administrative.update',
  /** Usunięcie postępowania administracyjnego */
  IBTM_DOMAIN_PROCEEDING_ADMINISTRATIVE_DELETE = 'group.permission.ibtm-domain.proceeding.administrative.delete',
  /** Podgląd postępowania administracyjnego */
  IBTM_DOMAIN_PROCEEDING_ADMINISTRATIVE_DETAILS_VIEW = 'group.permission.ibtm-domain.proceeding.administrative.details.view',
  /** Zmień status blankietu na Aktywny */
  IBTM_DOMAIN_PROCEEDING_RESOURCE_OBJECT_STATUS_ACTIVE_UPDATE = 'group.permission.ibtm-domain.proceeding.resource-object-status-active.update',
  /** Zmień prowadzącego */
  IBTM_DOMAIN_PROCEEDING_ASSIGNED_TO_UPDATE = 'group.permission.ibtm-domain.proceeding.assigned-to.update',
  /** Anulowanie postępowania */
  IBTM_DOMAIN_PROCEEDING_STATUS_CANCEL = 'group.permission.ibtm-domain.proceeding.status.cancel',
  /** Wyświetlenie aktywnych uprawnień */
  IBTM_DOMAIN_PROCEEDING_PERMISSIONS_ACTIVE_VIEW = 'group.permission.ibtm-domain.proceeding.permissions-active.view',
  /** Dodanie zawieszenia */
  IBTM_DOMAIN_SUSPENSION_CREATE = 'group.permission.ibtm-domain.suspension.create',
  /** Dodanie korekty */
  IBTM_DOMAIN_SUSPENSION_CORRECTION_CREATE = 'group.permission.ibtm-domain.suspension.correction.create',
  /** Wyświetlenie listy dokumentów wydania i korekt */
  IBTM_DOMAIN_SUSPENSION_INVOICE_VIEW = 'group.permission.ibtm-domain.suspension.invoice.view',
  /** Edytowanie korekty (w tym anulowanie korekty) */
  IBTM_DOMAIN_SUSPENSION_CORRECTION_UPDATE = 'group.permission.ibtm-domain.suspension.correction.update',
  /** Odwieszenie blankietów */
  IBTM_DOMAIN_SUSPENSION_RESOURCE_RELEASE = 'group.permission.ibtm-domain.suspension.resource-object.release',
  /** Usunięcie zawieszenia */
  IBTM_DOMAIN_SUSPENSION_DELETE = 'group.permission.ibtm-domain.suspension.delete',
  /** Edytowanie zawieszenia */
  IBTM_DOMAIN_SUSPENSION_UPDATE = 'group.permission.ibtm-domain.suspension.update',

  /** Wyświetlenie listy wszystkich postępowań */
  IBTM_DOMAIN_PROCEEDING_LIST_VIEW = 'group.permission.ibtm-domain.proceeding.list.view',
  /** Zawieszenie / Odwieszenie wydawania ŚK */
  IBTM_DOMAIN_PROCEEDING_DRIVER_CERTIFICATE_MANAGE = 'group.permission.ibtm-domain.proceeding.driver-certificate.manage',
  /** Blokada wydawania ŚK */
  IBTM_DOMAIN_PROCEEDING_DRIVER_CERTIFICATE_BLOCK = 'group.permission.ibtm-domain.proceeding.driver-certificate.block',
  /** Odblokowanie wydawania ŚK */
  IBTM_DOMAIN_PROCEEDING_DRIVER_CERTIFICATE_UNBLOCK = 'group.permission.ibtm-domain.proceeding.driver-certificate.unblock',
  /** Zawieszenie blankietów */
  IBTM_DOMAIN_PROCEEDING_RESOURCE_OBJECT_SUSPEND = 'group.permission.ibtm-domain.proceeding.resource-object.suspend',
  /** Podgląd zawieszenia */
  IBTM_DOMAIN_SUSPENSION_DETAILS_VIEW = 'group.permission.ibtm-domain.suspension.details.view',
  /** Wyświetlenie listy wszystkich zawieszeń */
  IBTM_DOMAIN_SUSPENSION_LIST_VIEW = 'group.permission.ibtm-domain.suspension.list.view',
  /** Anulowanie zawieszenia */
  IBTM_DOMAIN_SUSPENSION_STATUS_CANCEL = 'group.permission.ibtm-domain.suspension.status.cancel',
  /** Zamknięcie zawieszenia */
  IBTM_DOMAIN_SUSPENSION_STATUS_TERMINATE = 'group.permission.ibtm-domain.suspension.status.terminate',
  /** Wyświetlenie listy blankietów */
  IBTM_DOMAIN_PROCEEDING_RESOURCE_OBJECT_VIEW = 'group.permission.ibtm-domain.proceeding.resource-object.view',
  /** Dodanie blankietu */
  IBTM_DOMAIN_PROCEEDING_RESOURCE_OBJECT_ADD = 'group.permission.ibtm-domain.proceeding.resource-object.add',
  /** Usunięcie blankietu */
  IBTM_DOMAIN_PROCEEDING_RESOURCE_OBJECT_DELETE = 'group.permission.ibtm-domain.proceeding.resource-object.delete',
  /** Cofnięcie blankietów */
  IBTM_DOMAIN_PROCEEDING_RESOURCE_OBJECT_REVOKE = 'group.permission.ibtm-domain.proceeding.resource-object.revoke',
  /** Zakończenie postępowania */
  IBTM_DOMAIN_PROCEEDING_STATUS_TERMINATE = 'group.permission.ibtm-domain.proceeding.status.terminate',
  /** Wygaszenie blankietów */
  IBTM_DOMAIN_PROCEEDING_RESOURCE_OBJECT_EXPIRE = 'group.permission.ibtm-domain.proceeding.resource-object.expire',
  /** Oznaczenie pisma jako „Decyzja Ostateczna” */
  IBTM_DOMAIN_PROCEEDING_FINAL_DECISION_CHECK = 'group.permission.ibtm-domain.proceeding.final-decision.check',
  /** Odznaczenie pisma jako „Decyzja Ostateczna” */
  IBTM_DOMAIN_PROCEEDING_FINAL_DECISION_UNCHECK = 'group.permission.ibtm-domain.proceeding.final-decision.uncheck',
  /** Wyświetlenie profilu zalogowanego użytkownika */
  IBTM_DOMAIN_PROFILE_VIEW = 'group.permission.ibtm-domain.profile.view',
  /** Wyświetlenie grup uprawnień zalogowanego użytkownika */
  IBTM_DOMAIN_PROFILE_GROUP_VIEW = 'group.permission.ibtm-domain.profile.group.view',
  /** Zmiana hasła zalogowanego użytkownika */
  IBTM_DOMAIN_PROFILE_PASSWORD_CHANGE = 'group.permission.ibtm-domain.profile.password.change',
  /** Wyświetlenie zastępstw zalogowanego użytkownika */
  IBTM_DOMAIN_PROFILE_REPLACEMENT_VIEW = 'group.permission.ibtm-domain.profile.replacement.view',
  /** Dodanie nowego zastępstwa zalogowanego użytkownika */
  IBTM_DOMAIN_PROFILE_REPLACEMENT_ADD = 'group.permission.ibtm-domain.profile.replacement.add',
  /** Wyświetlenie listy komunikatów danego użytkownika - wersja skrócona */
  IBTM_DOMAIN_NOTIFICATION_LIST_SHORT_VIEW = 'group.permission.ibtm-domain.notification.list-short.view',
  /** Wyświetlenie listy komunikatów danego użytkownika */
  IBTM_DOMAIN_NOTIFICATION_LIST_FULL_VIEW = 'group.permission.ibtm-domain.notification.list-full.view',
  /** Wyświetlenie dokumentów wychodzących */
  IBTM_DOMAIN_DOCUMENT_LIST_VIEW = 'group.permission.ibtm-domain.document.list.view',
  /** Wygenerowanie dokumentu z szablonu */
  IBTM_DOMAIN_DOCUMENT_FROM_TEMPLATE_GENERATE = 'group.permission.ibtm-domain.document.from-template.generate',
  /** Wgranie pisma */
  IBTM_DOMAIN_DOCUMENT_FILE_UPLOAD = 'group.permission.ibtm-domain.document.file.upload',
  /** Usunięcie wgranego pisma */
  IBTM_DOMAIN_DOCUMENT_FILE_DELETE = 'group.permission.ibtm-domain.document.file.delete',
  /** Pobranie pisma na dysk */
  IBTM_DOMAIN_DOCUMENT_FILE_DOWNLOAD = 'group.permission.ibtm-domain.document.file.download',
  /** Przekazanie pisma do wysyłki */
  IBTM_DOMAIN_DOCUMENT_SHIPMENT_TRANSFER = 'group.permission.ibtm-domain.document.shipment.transfer',
  /** Potwierdzenie wydania dokumentu */
  // _ = 'group.permission..'	,
  /** Wygenerowanie potwierdzenia złożenia wniosku */
  IBTM_DOMAIN_DOCUMENT_SUBMISSION_CONFIRMATION_GENERATE = 'group.permission.ibtm-domain.document.submission-confirmation.generate',
  /** Wygenerowanie wezwania o uzupełnienie wniosków formalnych */
  IBTM_DOMAIN_DOCUMENT_REQUEST_FOR_SUPPLEMENT_GENERATE = 'group.permission.ibtm-domain.document.request-for-supplement.generate',

  /** Wysyłka dokumentów */
  IBTM_DOMAIN_DOCUMENT_SEND = 'group.permission.ibtm-domain.document.send',
  /** Wysyłka kurierska */
  IBTM_DOMAIN_DOCUMENT_COURIER_PARCEL_SEND = 'group.permission.ibtm-domain.document.courier-parcel.send',
  /** Wysyłka pocztowa */
  IBTM_DOMAIN_DOCUMENT_POST_PARCEL_SEND = 'group.permission.ibtm-domain.document.post-parcel.send',
  IBTM_DOMAIN_DOCUMENT_PARCEL_DATA_UPDATE = 'group.permission.ibtm-domain.document.parcel-data.update',
  IBTM_DOMAIN_DOCUMENT_NOTE_CREATE = 'group.permission.ibtm-domain.document.note.create',
  IBTM_DOMAIN_DOCUMENT_NOTE_VIEW = 'group.permission.ibtm-domain.document.note.view',
  IBTM_DOMAIN_DOCUMENT_CONTACT_DATA_UPDATE = 'group.permission.ibtm-domain.document.contact-data.update',

  /** Informowanie o etykiecie do tego samego adresata */
  // _ = 'group.permission..'	,
  // /** Obliczanie wartości do ubezpieczenia */
  // _ = 'group.permission..'	,
  // /** Podział przesyłki */
  // _ = 'group.permission..'	,
  /** Generowanie etykiety */
  IBTM_DOMAIN_DOCUMENT_PARCEL_LABEL_GENERATE = 'group.permission.ibtm-domain.document.parcel-label.generate',
  /** Wyświetlenie rejestru korespondencji */
  // _ = 'group.permission..'	,
  /** Drukowanie na blankietach */
  IBTM_DOMAIN_DOCUMENT_RESOURCE_OBJECT_PRINT = 'group.permission.ibtm-domain.document.resource-object.print',
  /** Utworzenie reklamacji */
  IBTM_DOMAIN_DOCUMENT_PARCEL_COMPLAINT_CREATE = 'group.permission.ibtm-domain.document.parcel-complaint.create',
  /** Zwrot przesyłki kurierskiej */
  IBTM_DOMAIN_DOCUMENT_COURIER_PARCEL_RETURN = 'group.permission.ibtm-domain.document.courier-parcel.return',
  /** Wydanie osobiste */
  IBTM_DOMAIN_DOCUMENT_PERSONAL_RELEASE = 'group.permission.ibtm-domain.document.personal.release',
  /** Wysyłka ePUAP */
  IBTM_DOMAIN_DOCUMENT_EPUAP_SEND = 'group.permission.ibtm-domain.document.epuap.send',
  /** Wysyłka pocztą elektroniczną */
  IBTM_DOMAIN_DOCUMENT_EMAIL_SEND = 'group.permission.ibtm-domain.document.email.send',
  /** Wycofanie wysyłki */
  IBTM_DOMAIN_DOCUMENT_PARCEL_WITHDRAW = 'group.permission.ibtm-domain.document.parcel.withdraw',
  /** Przekazanie zlecenia anonimizacji danych do KREPTD */
  // _ = 'group.permission..'	,

  /** Przyjęcie zezwolenia od przedsiębiorcy przez PK */
  IBTM_DOMAIN_CLIENT_PORTAL_FOREIGN_PERMISSION_RETURN = 'group.permission.ibtm-domain.client-portal.foreign-permission.return',
  /** Zwrócenie zezwolenia do GITD przez PK */
  IBTM_DOMAIN_CLIENT_PORTAL_FOREIGN_PERMISSION_RETURN_TO_GITD = 'group.permission.ibtm-domain.client-portal.foreign-permission.return-to-gitd',
  /** Wygenerowanie raportu */
  REPORT_GENERATE = 'group.permission.report.generate',

  /** Wygenerowanie raportu SPO */
  REPORT_WPO_GENERATE = 'group.permission.report.wpo.generate',

  /** Wygenerowanie raportu "Zestawienie przedsiębiorców występujących jako partner/podwykonawca" */
  REPORT_WPO_ZESTAWIENIE_PRZEDSIEBIORCOW_WYSTEPUJACYCH_JAKO_PARTNER_PODWYKONAWCA_GENERATE = 'group.permission.report.wpo.zestawienie-przedsiebiorcow-wystepujacych-jako-partner-podwykonawca.generate',

  /** Wygenerowanie raportu WL */
  REPORT_WL_GENERATE = 'group.permission.report.wl.generate',

  /** Wygenerowanie raportu "Raport o pojazdach" */
  REPORT_WL_RAPORT_O_POJAZDACH_GENERATE = 'group.permission.report.wl.raport-o-pojazdach.generate',

  /** Wygenerowanie raportu WSK */
  REPORT_WSK_GENERATE = 'group.permission.report.wsk.generate',

  /** Wygenerowanie raportu "Ważne sk firma" */
  REPORT_WSK_WAZNE_SK_FIRMA_GENERATE = 'group.permission.report.wsk.wazne-sk-firma.generate',
  /** Wygenerowanie raportu "Wydane sk firma" */
  REPORT_WSK_WYDANE_SK_FIRMA_GENERATE = 'group.permission.report.wsk.wydane-sk-firma.generate',

  /** Wygenerowanie raportu WZZ */
  REPORT_WZZ_GENERATE = 'group.permission.report.wzz.generate',

  /** Wygenerowanie raportu "Zwrócone i niezwrócone zezwolenia" */
  REPORT_WZZ_ZWROCONE_I_NIEZWROCONE_ZEZWOLENIA_GENERATE = 'group.permission.report.wzz.zwrocone-i-niezwrocone-zezwolenia.generatea',

  REPORT_WZZ_ZESTAWIENIE_STANOW_MAGAZYNOWYCH_GENERATE = 'group.permission.report.wzz.zestawienie-stanow-magazynowych.generate',

  /** Wygenerowanie raportu "Firmy pobierające zezwolenia" */
  // _ = 'group.permission..'	,

  /** Wygenerowanie raportu innego */
  REPORT_INNE_GENERATE = 'group.permission.report.inne.generate',

  /** Wygenerowanie raportu "Spis spraw" */
  REPORT_INNE_SPIS_SPRAW_GENERATE = 'group.permission.report.inne.spis-spraw.generate',

  /** Wygenerowanie spisu zdawczo-odbiorczego akt */
  REPORT_INNE_SPIS_ZDAWCZO_ODBIORCZY_AKT_GENERATE = 'group.permission.report.inne.spis-zdawczo-odbiorczy-akt.generate',
  /** Wyświetlenie listy wygenerowanych raportów */
  REPORT_LIST_GENERATED_VIEW = 'group.permission.report.list-generated.view',
  /** Pobranie wygenerowanego raportu */
  REPORT_CONTENT_DOWNLOAD = 'group.permission.report.content.download',
  /** Wyświetl szczegóły raportu */
  REPORT_DETAILS_VIEW = 'group.permission.report.details.view',

  /** Wyświetlenie listy parametrów systemowych */
  IBTM_DOMAIN_SYSTEM_PARAMETER_LIST_VIEW = 'group.permission.ibtm-domain.system-parameter.list.view',
  /** Edycja parametrów systemowych */
  IBTM_DOMAIN_SYSTEM_PARAMETER_UPDATE = 'group.permission.ibtm-domain.system-parameter.update',
  /** Audyt Log */
  MISCELLANEOUS_AUDIT_LOG_VIEW = 'group.permission.miscellaneous.audit-log.view',
  /** Przekierowanie do Flowable UI */
  MISCELLANEOUS_FLOWABLE_UI_VIEW = 'group.permission.miscellaneous.flowable-ui.view',
  /** Wyświetlenie listy wszystkich kierowców */
  IBTM_DOMAIN_DRIVER_LIST_VIEW = 'group.permission.ibtm-domain.driver.list.view',
  /** Wygaszenie świadectwa kierowcy */
  IBTM_DOMAIN_DRIVER_DRIVER_CERTIFICATE_REVOKE = 'group.permission.ibtm-domain.driver.driver-certificate.revoke',
  /** Przywrócenie świadectwa kierowcy (zmiana statusu na "Aktywny")" */
  IBTM_DOMAIN_DRIVER_DRIVER_CERTIFICATE_RESUME = 'group.permission.ibtm-domain.driver.driver-certificate.resume',
  /** Dołączenie dokumentu EZD do wniosku */
  IBTM_DOMAIN_EZD_DOCUMENT_APPLICATION_BIND = 'group.permission.ibtm-domain.ezd-document.application.bind',
  /** Odrzucenie dokumentu EZD */
  IBTM_DOMAIN_EZD_DOCUMENT_REJECT = 'group.permission.ibtm-domain.ezd-document.reject',
  /** Utworzenie wniosku na podstawie dokumentu EZD */
  IBTM_DOMAIN_EZD_DOCUMENT_APPLICATION_CREATE = 'group.permission.ibtm-domain.ezd-document.application.create',
  /** Wyświetlenie szczegółów dokumentu EZD */
  IBTM_DOMAIN_EZD_DOCUMENT_DETAILS_VIEW = 'group.permission.ibtm-domain.ezd-document.details.view',
  /** Wyświetlenie listy terminów posiedzeń */
  IBTM_DOMAIN_CIVIC_BOARD_LIST_VIEW = 'group.permission.ibtm-domain.civic-board.list.view',
  /** Wyświetlenie listy reguł wolnej sprzedaży */
  IBTM_DOMAIN_CIVIC_BOARD_FREE_DISPOSAL_RULE_LIST_VIEW = 'group.permission.ibtm-domain.civic-board.free-disposal-rule-list.view',
  /** Wyświetlenie listy limitów podstawowych */
  IBTM_DOMAIN_CIVIC_BOARD_BASIC_LIMIT_LIST_VIEW = 'group.permission.ibtm-domain.civic-board.basic-limit-list.view',
  /** Wyświetlenie listy wniosków EKMT na komisje */
  IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_LIST_VIEW = 'group.permission.ibtm-domain.civic-board.submission-ekmt-list.view',
  /** Wyświetlenie listy wniosków jednorazowych na komisje */
  IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_LIST_VIEW = 'group.permission.ibtm-domain.civic-board.submission-single-list.view',
  /** Wyświetlenie listy wniosków na komisje */
  IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_LIST_VIEW = 'group.permission.ibtm-domain.civic-board.submission-list.view',
  /** Dodanie wniosku (EKMT) */
  IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_CREATE = 'group.permission.ibtm-domain.civic-board.submission-ekmt.create',
  /** Edycja wniosku (EKMT) */
  IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_UPDATE = 'group.permission.ibtm-domain.civic-board.submission-ekmt.update',
  /** Usunięcie wniosku (EKMT) */
  IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_DELETE = 'group.permission.ibtm-domain.civic-board.submission-ekmt.delete',
  /** Eksport pliku na komisję (EKMT) */
  IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_EXPORT = 'group.permission.ibtm-domain.civic-board.submission-ekmt.export',
  /** Import pliku z decyzją komisji (EKMT) */
  IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_IMPORT = 'group.permission.ibtm-domain.civic-board.submission-ekmt.import',
  /** Wprowadzanie danych szczegółowych rozliczenia */
  IBTM_DOMAIN_FOREIGN_PERMISSION_RETURN_ACCOUNT = 'group.permission.ibtm-domain.foreign-permission.return.account',
  /** Zwracanie zezwolenia */
  IBTM_DOMAIN_FOREIGN_PERMISSION_RESOURCE_OBJECT_RETURN = 'group.permission.ibtm-domain.foreign-permission.resource-object.return',
  /** Cofanie zwrotu zezwolenia */
  IBTM_DOMAIN_FOREIGN_PERMISSION_RESOURCE_OBJECT_UNDO_RETURN = 'group.permission.ibtm-domain.foreign-permission.resource-object.undo-return',
  /** Zawieszanie limitu podstawowego */
  IBTM_DOMAIN_FOREIGN_PERMISSION_BASIC_LIMIT_SUSPEND = 'group.permission.ibtm-domain.foreign-permission.basic-limit.suspend',
  /** Wydrukowanie zezwolenia jednorazowego/EKMT */
  IBTM_DOMAIN_FOREIGN_PERMISSION_RESOURCE_OBJECT_PRINT = 'group.permission.ibtm-domain.foreign-permission.resource-object.print',
  /** Przenoszenie limitu podstawowego */
  IBTM_DOMAIN_FOREIGN_PERMISSION_BASIC_LIMIT_TRANSFER = 'group.permission.ibtm-domain.foreign-permission.basic-limit.transfer',
  /** Blokowanie wydawania zezwoleń */
  IBTM_DOMAIN_FOREIGN_PERMISSION_RELEASING_BLOCK = 'group.permission.ibtm-domain.foreign-permission.releasing.block',

  /** Audyt log */
  AUDIT_VIEW = 'audit.view',

  /** Rejestracja podania */
  // _ = 'group.permission..'	,
  /** Drukowanie dokumentu opłaty */
  IBTM_DOMAIN_DOCUMENT_PAYMENT_PRINT = 'group.permission.ibtm-domain.document.payment.print',
  /** Przyjęcie zewnętrzne */
  IBTM_DOMAIN_RESOURCE_OBJECT_EXTERNAL_RECEIVE = 'group.permission.ibtm-domain.resource-object.external.receive',
  /** Przesunięcie blankietów pomiędzy magazynami */
  IBTM_DOMAIN_RESOURCE_OBJECT_TRANSFER = 'group.permission.ibtm-domain.resource-object.transfer',
  /** Wyszukanie blankietu */
  IBTM_DOMAIN_RESOURCE_OBJECT_SEARCH = 'group.permission.ibtm-domain.resource-object.search',
  /** Przeglądanie szczegółów magazynu */
  IBTM_DOMAIN_DEPOT_DETAILS_VIEW = 'group.permission.ibtm-domain.depot.details.view',
  /** Zmiana administratorów */
  IBTM_DOMAIN_DEPOT_ADMINISTRATORS_UPDATE = 'group.permission.ibtm-domain.depot.administrators.update',
  /** Dodanie administratora */
  IBTM_DOMAIN_DEPOT_ADMINISTRATORS_ADD = 'group.permission.ibtm-domain.depot.administrators.add',
  /** Usunięcie administratora */
  IBTM_DOMAIN_DEPOT_ADMINISTRATORS_DELETE = 'group.permission.ibtm-domain.depot.administrators.delete',
  /** Wydanie zewnętrzne */
  IBTM_DOMAIN_RESOURCE_OBJECT_EXTERNAL_RELEASE = 'group.permission.ibtm-domain.resource-object.external.release',
  /** Wyświetlenie listy magazynów */
  IBTM_DOMAIN_DEPOT_LIST_VIEW = 'group.permission.ibtm-domain.depot.list.view',
  /** Edycja magazynu */
  IBTM_DOMAIN_DEPOT_UPDATE = 'group.permission.ibtm-domain.depot.update',
  /** Utworzenie nowego typu blankietu */
  IBTM_DOMAIN_RESOURCE_OBJECT_RESOURCE_TYPE_CREATE = 'group.permission.ibtm-domain.resource-object.resource-type.create',
  /** Brakowanie */
  IBTM_DOMAIN_RESOURCE_OBJECT_DEFECTIVE_CHANGE = 'group.permission.ibtm-domain.resource-object.defective.change',
  /** Przyjęcie korekty */
  IBTM_DOMAIN_RESOURCE_OBJECT_CORRECTION_ACCEPT = 'group.permission.ibtm-domain.resource-object.correction.accept',

  /** Zarządzanie typami blankietów */
  IBTM_DOMAIN_RESOURCE_OBJECT_RESOURCE_TYPE_MANAGE = 'group.permission.ibtm-domain.resource-object.resource-type.manage',
  /** Zainicjowanie przesunięcia */
  IBTM_DOMAIN_RESOURCE_OBJECT_TRANSFER_FORM_VIEW = 'group.permission.ibtm-domain.resource-object.transfer-form.view',
  /** Usunięcie typu blankietu */
  IBTM_DOMAIN_RESOURCE_OBJECT_RESOURCE_TYPE_DELETE = 'group.permission.ibtm-domain.resource-object.resource-type.delete',
  /** Wywołanie raportu Zestawienie stanów magazynowych) */
  IBTM_DOMAIN_DEPOT_REPORT_ZESTAWIENIE_STANOW_MAGAZYNOWYCH_GENERATE = 'group.permission.ibtm-domain.depot.report-zestawienie-stanow-magazynowych.generate',
  /** Potwierdzenie otrzymania blankietów */
  IBTM_DOMAIN_RESOURCE_OBJECT_TRANSFER_CONFIRM = 'group.permission.ibtm-domain.resource-object.transfer.confirm',
  /** Utworzenie nowego typu blankietu - zezwolenie Ministra Transportu */
  // _ = 'group.permission..'	,
  // /** Wyświetlenie historii obsługi wniosku */
  // _ = 'group.permission..'	,
  // /** Wyświetlenie plików wniosku */
  // _ = 'group.permission..'	,
  // /** Wgranie pliku do wniosku */
  // _ = 'group.permission..'	,
  // /** Usunięcie pliku z wniosku */
  // _ = 'group.permission..'	,
  // /** Edycja wgranego do wniosku pliku */
  // _ = 'group.permission..'	,
  // /** Pobranie pliku na dysk */
  // _ = 'group.permission..'	,
  // /** Wyświetlenie wyceny wniosku */
  // _ = 'group.permission..'	,
  // /** Wyświetlenie pojazdów wniosku */
  // _ = 'group.permission..'	,
  // /** Wyświetlenie danych podstawowych wniosku */
  // _ = 'group.permission..'	,
  /** Wyświetlenie listy naruszen */
  IBTM_DOMAIN_PROCEEDING_KREPTD_VIOLATION_LIST_VIEW = 'group.permission.ibtm-domain.proceeding-kreptd.violation-list.view',

  /** Delegowanie zadania */
  IBTM_DOMAIN_PROCEEDING_KREPTD_ASSIGNED_TO_UPDATE = 'group.permission.ibtm-domain.proceeding-kreptd.assigned-to.update',
  /** Rozpoczęcie postępowania */
  IBTM_DOMAIN_PROCEEDING_KREPTD_CREATE_FROM_VIOLATION = 'group.permission.ibtm-domain.proceeding-kreptd.create-from-violation',
  /** Dodanie do postępowania */
  IBTM_DOMAIN_PROCEEDING_KREPTD_VIOLATION_PROCEEDING_BIND = 'group.permission.ibtm-domain.proceeding-kreptd.violation-proceeding.bind',
  /** Wyświetlenie szczegółów postępowania */
  IBTM_DOMAIN_PROCEEDING_KREPTD_DETAILS_VIEW = 'group.permission.ibtm-domain.proceeding-kreptd.details.view',
  /** Ręczna zmiana statusu */
  IBTM_DOMAIN_PROCEEDING_KREPTD_STATUS_UPDATE = 'group.permission.ibtm-domain.proceeding-kreptd.status.update',
  /** Wyświetlenie listy postępowan administracyjnych */
  IBTM_DOMAIN_PROCEEDING_KREPTD_LIST_VIEW = 'group.permission.ibtm-domain.proceeding-kreptd.list.view',
  /** Edycja szczegółów postępowania "Dobra reputacja" */
  IBTM_DOMAIN_PROCEEDING_KREPTD_GOOD_REPUTATION_UPDATE = 'group.permission.ibtm-domain.proceeding-kreptd.good-reputation.update',
  /** Edycja szczegółów postępowania "Zawieszenie zezwolenia" */
  IBTM_DOMAIN_PROCEEDING_KREPTD_PERMIT_SUSPENSION_UPDATE = 'group.permission.ibtm-domain.proceeding-kreptd.permit-suspension.update',
  /** Edycja szczegółów postępowania "Stwierdzenie niezdolności" */
  IBTM_DOMAIN_PROCEEDING_KREPTD_DECLARATION_OF_INABILITY_UPDATE = 'group.permission.ibtm-domain.proceeding-kreptd.declaration-of-inability.update',
  /** Edycja szczegółów postępowania "Cofnięcie wypisu i zawieszenie świadectw" */
  IBTM_DOMAIN_PROCEEDING_KREPTD_DISCHARGE_AND_SUSPENSION_OF_CERTIFICATES_UPDATE = 'group.permission.ibtm-domain.proceeding-kreptd.discharge-and-suspension-of-certificates.update',
  /** Edycja szczegółów postępowania "Nałożenie kary" */
  IBTM_DOMAIN_PROCEEDING_KREPTD_IMPOSING_PENALTY_UPDATE = 'group.permission.ibtm-domain.proceeding-kreptd.imposing-penalty.update',
  /** Edycja szczegółów postępowania "Przywrócenie zdolności" */
  IBTM_DOMAIN_PROCEEDING_KREPTD_RESTORE_OF_ABILITIES_UPDATE = 'group.permission.ibtm-domain.proceeding-kreptd.restore-of-abilities.update',
  /** Zmiana teczki */
  IBTM_DOMAIN_PROCEEDING_KREPTD_FOLDER_SWAP = 'group.permission.ibtm-domain.proceeding-kreptd.folder.swap',

  /** Dodanie postępowania */
  IBTM_DOMAIN_PROCEEDING_CREATE = 'group.permission.ibtm-domain.proceeding.create',
  /** Podgląd postępowania */
  IBTM_DOMAIN_PROCEEDING_DETAILS_VIEW = 'group.permission.ibtm-domain.proceeding.details.view',
  /** Usunięcie postępowania */
  IBTM_DOMAIN_PROCEEDING_DELETE = 'group.permission.ibtm-domain.proceeding.delete',
  /** Edytowanie postępowania */
  IBTM_DOMAIN_PROCEEDING_UPDATE = 'group.permission.ibtm-domain.proceeding.update',
  /** Wyświetlenie listy przypisanych naruszen */
  IBTM_DOMAIN_PROCEEDING_KREPTD_VIOLATION_ASSIGNED_LIST_VIEW = 'group.permission.ibtm-domain.proceeding-kreptd.violation-assigned-list.view',
  /** Edycja szczegółów postępowania */
  IBTM_DOMAIN_PROCEEDING_KREPTD_UPDATE = 'group.permission.ibtm-domain.proceeding-kreptd.update',
  /** Pobranie dokumentów z EZD */
  // _ = 'group.permission..'	,
  // /** Wygaszenie licencji bez ZPO */
  // _ = 'group.permission..'	,
  // /** Wysłanie komunikatu o konieczność uzupełnienia ZPO */
  // _ = 'group.permission..'	,
  // /** Wysłanie maila o terminie ważności licencji */
  // _ = 'group.permission..'	,
  // /** Utworzenie pliku wymiany GLS */
  // _ = 'group.permission..'	,
  // /** Pobieranie danych dot. przedsiębiorcy z REGON */
  // _ = 'group.permission..'	,
  /** Przywrócenie zdolności */
  IBTM_DOMAIN_PROCEEDING_KREPTD_RESTORE_OF_ABILITIES_CREATE = 'group.permission.ibtm-domain.proceeding-kreptd.restore-of-abilities.create',
  /** Kara pieniężna */
  IBTM_DOMAIN_PROCEEDING_KREPTD_IMPOSING_PENALTY_CREATE = 'group.permission.ibtm-domain.proceeding-kreptd.imposing-penalty.create',
  // /** Weryfikowanie */
  // _ = 'group.permission..'	,
  // /** Zmiana kwalifikacji blankietu */
  // _ = 'group.permission..'	,
  // /** Przyjęcie blankietow przez PK */
  // _ = 'group.permission..'	,
  // /** Dodanie przedsiębiorcy do stowarzyszenia w PK */
  // _ = 'group.permission..'	,
  // /** Usunięcie przedsiębiorcy ze stowarzyszenia w PK */
  // _ = 'group.permission..'	,
  // /** Dodanie wniosku dot. Zezwoleń zagranicznych */
  // _ = 'group.permission..'	,
  // /** Dodanie wniosku dot. formulary jazd UE/INTERBUS */
  // _ = 'group.permission..'	,
  // /** Wcielenie się w rolę innego użytkownika */
  // _ = 'group.permission..'	,
  // /** Wyjście z roli innego użytkownika */
  // _ = 'group.permission..'	,
  // /** Resetowanie/ odzyskiwanie hasła */
  // _ = 'group.permission..'	,
  // /** Pobieranie danych z wniosku do szkicu */
  // _ = 'group.permission..'	,
  // /** Wyświetlenie szczegółów teczki */
  // _ = 'group.permission..'	,
  // /** Dodanie podstawowych danych o wniosku LM1 */
  // _ = 'group.permission..'	,
  // /** Wyświetlanie listy wniosków z uprawnieniami do druku */
  // _ = 'group.permission..'	,
  /** Wyświetlanie listy polis */
  IBTM_DOMAIN_FOLDER_INSURACNCE_POLICY_VIEW = 'group.permission.ibtm-domain.folder.insurance-policy.view',
  /** Edycja polisy */
  IBTM_DOMAIN_FOLDER_INSURACNCE_POLICY_UPDATE = 'group.permission.ibtm-domain.folder.insurance-policy.update',
  /** Usunięcie polisy */
  IBTM_DOMAIN_FOLDER_INSURACNCE_POLICY_DELETE = 'group.permission.ibtm-domain.folder.insurance-policy.delete',
  /** Dodanie polisy */
  IBTM_DOMAIN_FOLDER_INSURACNCE_POLICY_CREATE = 'group.permission.ibtm-domain.folder.insurance-policy.create',
  // /** Dodanie wniosku "Przeniesienie uprawnień nabycie przedsiębiorstwa" (PB6) */
  // _ = 'group.permission..'	,
  // /** Zwiń/ rozwiń teczkę */
  // _ = 'group.permission..'	,

  /** Wyświetlenie szczegółów podania (EKMT) */
  IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_EKMT_DETAILS_VIEW = 'group.permission.ibtm-domain.civic-board.submission-ekmt-details.view',
  /** Usunięcie wniosku (Zezwolenie jednorazowe) */
  IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_DELETE = 'group.permission.ibtm-domain.civic-board.submission-single.delete',
  /** Dodanie wniosku (Zezwolenie jednorazowe) */
  IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_CREATE = 'group.permission.ibtm-domain.civic-board.submission-single.create',
  /** Edycja wniosku (Zezwolenie jednorazowe) */
  IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_UPDATE = 'group.permission.ibtm-domain.civic-board.submission-single.update',
  /** Wyświetlenie szczegółów wniosku (Zezwolenie jednorazowe) */
  IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_DETAILS_VIEW = 'group.permission.ibtm-domain.civic-board.submission-single-details.view',
  /** Dodanie decyzji (Zezwolenia jednorazowe) */
  IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_DECISION_MAKE = 'group.permission.ibtm-domain.civic-board.submission-single-decision.make',
  /** Modyfikacja daty poboru (Zezwolenia jednorazowe) */
  IBTM_DOMAIN_CIVIC_BOARD_SUBMISSION_SINGLE_DECISION_RECEIPT_DATE_UPDATE = 'group.permission.ibtm-domain.civic-board.submission-single-decision.receipt-date-update',
  /** Dodanie terminu posiedzenia */
  IBTM_DOMAIN_CIVIC_BOARD_CREATE = 'group.permission.ibtm-domain.civic-board.create',
  /** Edycja terminu posiedzenia */
  IBTM_DOMAIN_CIVIC_BOARD_UPDATE = 'group.permission.ibtm-domain.civic-board.update',
  /** Usunięcie terminu posiedzenia */
  IBTM_DOMAIN_CIVIC_BOARD_DELETE = 'group.permission.ibtm-domain.civic-board.delete',
  /** Dodanie reguły wolnej sprzedaży */
  IBTM_DOMAIN_CIVIC_BOARD_FREE_DISPOSAL_RULE_CREATE = 'group.permission.ibtm-domain.civic-board.free-disposal-rule.create',
  /** Edycja reguły wolnej sprzedaży */
  IBTM_DOMAIN_CIVIC_BOARD_FREE_DISPOSAL_RULE_UPDATE = 'group.permission.ibtm-domain.civic-board.free-disposal-rule.update',
  /** Usunięcie reguły wolnej sprzedaży */
  IBTM_DOMAIN_CIVIC_BOARD_FREE_DISPOSAL_RULE_DELETE = 'group.permission.ibtm-domain.civic-board.free-disposal-rule.delete',
  /** Przeliczenie (Limit wolnej sprzedaży) */
  IBTM_DOMAIN_CIVIC_BOARD_FREE_DISPOSAL_RULE_RECALCULATE = 'group.permission.ibtm-domain.civic-board.free-disposal-rule.recalculate',
  /** Wyświetlenie szczegółów reguły limitu podstawowego */
  IBTM_DOMAIN_CIVIC_BOARD_BASIC_LIMIT_DETAILS_VIEW = 'group.permission.ibtm-domain.civic-board.basic-limit-details.view',
  /** Edycja reguły limitu podstawowego */
  IBTM_DOMAIN_CIVIC_BOARD_BASIC_LIMIT_RULE_UPDATE = 'group.permission.ibtm-domain.civic-board.basic-limit-rule.update',
  /** Usunięcie reguły limitu podstawowego */
  IBTM_DOMAIN_CIVIC_BOARD_BASIC_LIMIT_RULE_DELETE = 'group.permission.ibtm-domain.civic-board.basic-limit-rule.delete',
  /** Przeliczenie (Limit podstawowy) */
  IBTM_DOMAIN_CIVIC_BOARD_BASIC_LIMIT_RULE_RECALCULATE = 'group.permission.ibtm-domain.civic-board.basic-limit-rule.recalculate',
  /** Eksport pliku na komisję (Zezwolenia jednorazowe) */
  IBTM_DOMAIN_CIVIC_BOARD_FILE_EXPORT = 'group.permission.ibtm-domain.civic-board.file.export',
  /** Import pliku na komisję (Zezwolenia jednorazowe) */
  IBTM_DOMAIN_CIVIC_BOARD_FILE_IMPORT = 'group.permission.ibtm-domain.civic-board.file.import',

  // /**DomainPermissionEnum */

  APPLICATION_ADMIN = 'application.admin',
  APPLICATION_CREATE = 'application.create',
  APPLICATION_UPDATE = 'application.update',
  APPLICATION_VIEW = 'application.view',
  ARCHIVE_ADMIN = 'archive.admin',
  ARCHIVE_VIEW = 'archive.view',
  CABOTAGE_VIEW = 'cabotage.view',
  CIVIC_BOARD_ADMIN = 'civic-board.admin',
  CIVIC_BOARD_CREATE = 'civic-board.create',
  CIVIC_BOARD_UPDATE = 'civic-board.update',
  CIVIC_BOARD_VIEW = 'civic-board.view',
  CIVIC_BAORD_EKMT_DETAILS = 'civic-board.submission-ekmt-details.view',
  CIVIC_BOARD_SINGLE_DELETE = 'civic-board.submission-single.delete',
  CIVIC_BOARD_SINGLE_CREATE = 'civic-board.submission-single.create',
  CIVIC_BOARD_SINGLE_UPDATE = 'civic-board.submission-single.update',
  CIVIC_BOARD_SINGLE_DETAILS = 'civic-board.submission-single-details.view',
  CIVIC_BOARD_SINGLE_DECISION_ADD = 'civic-board.submission-single-decision.make',
  CIVIC_BOARD_SINGLE_EXPORT_FILE = 'civic-board.file.export',
  CIVIC_BOARD_SINGLE_IMPORT = 'civic-board.file.import',
  DEPOT_CONTENT_ADMIN = 'depot-content.admin',
  DEPOT_CONTENT_VIEW = 'depot-content.view',
  DEPOT_MANAGER_ADMIN = 'depot-manager.admin',
  DEPOT_MANAGER_VIEW = 'depot-manager.view',
  DEPOT_TRANSFER_ADMIN = 'depot-transfer.admin',
  DEPOT_ADMIN = 'depot.admin',
  DEPOT_CREATE = 'depot.create',
  DEPOT_UPDATE = 'depot.update',
  DEPOT_VIEW = 'depot.view',
  DISPOSAL_ADMIN = 'disposal.admin',
  DISPOSAL_CREATE = 'disposal.create',
  DOCUMENT_ADMIN = 'document.admin',
  DOCUMENT_CREATE = 'document.create',
  DOCUMENT_UPDATE = 'document.update',
  DOCUMENT_VIEW = 'document.view',
  DRIVER_ADMIN = 'driver.admin',
  DRIVER_CREATE = 'driver.create',
  DRIVER_UPDATE = 'driver.update',
  DRIVER_VIEW = 'driver.view',
  EKMT_VIEW = 'ekmt.view',
  EKMT_UPDATE = 'ekmt.update',
  EXPLOITATION_BASE_ADMIN = 'exploitation-base.admin',
  EXPLOITATION_BASE_CREATE = 'exploitation-base.create',
  EXPLOITATION_BASE_UPDATE = 'exploitation-base.update',
  EXPLOITATION_BASE_VIEW = 'exploitation-base.view',
  EZD_DOCUMENT_ADMIN = 'ezd-document.admin',
  EZD_DOCUMENT_VIEW = 'ezd-document.view',
  FINANCIAL_SECURITY_CREATE = 'financial-security.create',
  FINANCIAL_SECURITY_UPDATE = 'financial-security.update',
  FINANCIAL_SECURITY_VIEW = 'financial-security.view',
  FOLDER_LIMIT_ADMIN = 'folder-limit.admin',
  FOLDER_LIMIT_UPDATE = 'folder-limit.update',
  FOLDER_LIMIT_VIEW = 'folder-limit.view',
  FOLDER_LOCK_VIEW = 'folder-lock.view',
  FOLDER_RENTAL_ADMIN = 'folder-rental.admin',
  FOLDER_RENTAL_CREATE = 'folder-rental.create',
  FOLDER_RENTAL_UPDATE = 'folder-rental.update',
  FOLDER_RENTAL_VIEW = 'folder-rental.view',
  FOLDER_ADMIN = 'folder.admin',
  FOLDER_CREATE = 'group.permission.ibtm-domain.folder.create',
  FOLDER_UPDATE = 'folder.update',
  FOLDER_MAIN_ADDRESS_KS_UPDATE = 'group.permission.ibtm-domain.folder.main-address.ks.update',
  FOLDER_MAIN_ADDRESS_WITD_UPDATE = 'group.permission.ibtm-domain.folder.main-address.witd.update',
  FOLDER_MAIN_ADDRESS_ZZ_UPDATE = 'group.permission.ibtm-domain.folder.main-address.zz.update',
  FOLDER_VIEW = 'folder.view',
  TRANSFER_FOLDER = 'group.permission.ibtm-domain.proceeding-kreptd.transfer-folder-outside.execute',
  ASSIGN_TO_TRANSFER_FOLDER = 'group.permission.ibtm-domain.proceeding-kreptd.transfer-folder-outside.assign',
  FOREIGN_PERMISSION_ADMIN = 'foreign-permission.admin',
  FOREIGN_PERMISSION_UPDATE = 'foreign-permission.update',
  FOREIGN_PERMISSION_VIEW = 'foreign-permission.view',
  FORM_OPERATION_ADMIN = 'form-operation.admin',
  FORM_OPERATION_CREATE = 'form-operation.create',
  INSURANCE_POLICY_CREATE = 'insurance-policy.create',
  INSURANCE_POLICY_UPDATE = 'insurance-policy.update',
  INSURANCE_POLICY_VIEW = 'insurance-policy.view',
  INVOICE_ADMIN = 'invoice.admin',
  INVOICE_CREATE = 'invoice.create',
  INVOICE_UPDATE = 'invoice.update',
  LICENSE_ADMIN = 'license.admin',
  LICENSE_VIEW = 'license.view',
  MESSAGE_CREATE = 'message.create',
  MESSAGE_ADMIN = 'message.admin',
  MONEY_TRANSFER_ADMIN = 'money-transfer.admin',
  MPBIK_DEPOT_CREATE = 'mpbik-depot.create',
  MPBIK_DEPOT_UPDATE = 'mpbik-depot.update',
  ORGANIZATION_STRUCTURE_ADMIN = 'organization-structure.admin',
  ORGANIZATION_STRUCTURE_VIEW = 'organization-structure.view',
  PAYMENT_ADMIN = 'payment.admin',
  PAYMENT_CREATE = 'payment.create',
  PAYMENT_UPDATE = 'payment.update',
  PAYMENT_VIEW = 'payment.view',
  PERMISSION_ADMIN = 'permission.admin',
  PERMISSION_UPDATE = 'permission.update',
  PERMISSION_VIEW = 'permission.view',
  PRICING_UPDATE = 'pricing.update',
  PRICING_VIEW = 'pricing.view',
  PROCEEDING_KREPTD_ADMIN = 'proceeding-kreptd.admin',
  PROCEEDING_KREPTD_CREATE = 'proceeding-kreptd.create',
  PROCEEDING_KREPTD_UPDATE = 'proceeding-kreptd.update',
  PROCEEDING_KREPTD_VIEW = 'proceeding-kreptd.view',
  PROCEEDING_ADMIN = 'proceeding.admin',
  PROCEEDING_CREATE = 'proceeding.create',
  PROCEEDING_UPDATE = 'proceeding.update',
  PROCEEDING_VIEW = 'proceeding.view',
  PROXY_ADMIN = 'proxy.admin',
  PROXY_CREATE = 'proxy.create',
  PROXY_UPDATE = 'proxy.update',
  REGON_VIEW = 'regon.view',
  REPUTATION_REQUEST_CREATE = 'reputation-request.create',
  REPUTATION_REQUEST_VIEW = 'reputation-request.view',
  RESOURCE_ASSIGNMENT_ADMIN = 'resource-assignment.admin',
  RESOURCE_ASSIGNMENT_VIEW = 'resource-assignment.view',
  RESOURCE_OBJECT_ADMIN = 'resource-object.admin',
  RESOURCE_OBJECT_VIEW = 'resource-object.view',
  RESOURCE_ROLLBACK_RETURNS_CREATE = 'resource-rollback-returns.create',
  RESOURCE_ROLLBACK_RETURNS_UPDATE = 'resource-rollback-returns.update',
  RESOURCE_ROLLBACK_RETURNS_VIEW = 'resource-rollback-returns.view',
  RESOURCE_TYPE_ADMIN = 'resource-type.admin',
  RESOURCE_TYPE_CREATE = 'resource-type.create',
  RESOURCE_ADMIN = 'resource.admin',
  S_CERTIFICATE_ADMIN = 's-certificate.admin',
  S_CERTIFICATE_VIEW = 's-certificate.view',
  SIGNER_ADMIN = 'signer.admin',
  SUBJECT_ADMIN = 'subject.admin',
  SUBJECT_CREATE = 'subject.create',
  SUBJECT_UPDATE = 'subject.update',
  SUBJECT_VIEW = 'subject.view',
  SUSPENSION_ADMIN = 'suspension.admin',
  SUSPENSION_CREATE = 'suspension.create',
  SUSPENSION_UPDATE = 'suspension.update',
  SUSPENSION_VIEW = 'suspension.view',
  TRANSIT_SCHEDULE_ADMIN = 'transit-schedule.admin',
  TRANSIT_SCHEDULE_CREATE = 'transit-schedule.create',
  TRANSIT_SCHEDULE_UPDATE = 'transit-schedule.update',
  TRANSIT_SCHEDULE_VIEW = 'transit-schedule.view',
  TRANSPORT_MANAGER_ADMIN = 'transport-manager.admin',
  TRANSPORT_MANAGER_CREATE = 'transport-manager.create',
  TRANSPORT_MANAGER_UPDATE = 'transport-manager.update',
  TRANSPORT_MANAGER_VIEW = 'transport-manager.view',
  VEHICLE_ADMIN = 'vehicle.admin',
  VEHICLE_CREATE = 'vehicle.create',
  VEHICLE_UPDATE = 'vehicle.update',
  VEHICLE_VIEW = 'vehicle.view',
  VIOLATION_ADMIN = 'violation.admin',
  VIOLATION_VIEW = 'violation.view',
  SYSTEM_PARAMETERS_UPDATE = 'system-parameters.update',

  // /** NotificationPermissionEnum */

  ALERT_ADMIN = 'alert.admin',
  ALERT_VIEW = 'alert.view',
  MESSAGE_VIEW = 'message.view',
  /** Wyświetlenie listy komunikatów administracyjnych */
  IBTM_DOMAIN_ANNOUNCEMENT_LIST_VIEW = 'group.permission.ibtm-domain.announcement.list.view',
  /** Wyświetlenie szczegółów komunikatu */
  IBTM_DOMAIN_ANNOUNCEMENT_DETAILS_VIEW = 'group.permission.ibtm-domain.announcement.details.view',
  /** Dodanie nowego komunikatu */
  IBTM_DOMAIN_ANNOUNCEMENT_CREATE = 'group.permission.ibtm-domain.announcement.create',
  /** Usunięcie komunikatu */
  IBTM_DOMAIN_ANNOUNCEMENT_DELETE = 'group.permission.ibtm-domain.announcement.delete',

  // /**DocumentTemplatePermissionEnum */

  /** Wyświetlenie szablonu dokumentu */
  IBTM_DOMAIN_DOCUMENT_TEMPLATE_VIEW = 'document.document-type.view',
  /** Administrator szablonu dokumentu */
  IBTM_DOMAIN_DOCUMENT_TEMPLATE_ADMIN = 'document.document-type.admin',

  // /** DictionaryPermissionEnum */

  /** Wyświetlenie listy słowników */
  DICTIONARY_LIST_VIEW = 'group.permission.dictionary.list.view',
  /** Wyświetlenie wartości słownika */
  DICTIONARY_DETAILS_VIEW = 'group.permission.dictionary.details.view',
  /** Dodanie wartości do słownika */
  DICTIONARY_VALUE_CREATE = 'group.permission.dictionary.value.create',
  /** Edycja wartości ze słownika */
  DICTIONARY_VALUE_UPDATE = 'group.permission.dictionary.value.update',
  /** Usunięcie wartości ze słownika */
  DICTIONARY_VALUE_DELETE = 'group.permission.dictionary.value.delete',

  DICTIONARY_VIEW = 'dictionary.view',
  DICTIONARY_ADMIN = 'dictionary.admin',

  // /** GroupPermissionEnum */

  /** Wyświetlenie grupy uprawnień */
  GROUP_VIEW = 'group.view',
  /** Admin grupy uprawnień */
  GROUP_ADMIN = 'group.admin',
  /** Wyświetlenie użytkowników grupy uprawnień */
  GROUP_USERS_VIEW = 'group.users.view',
  /** Admin użytkowników grupy uprawnień */
  GROUP_USERS_ADMIN = 'group.users.admin',

  // /** UserPermissionEnum */

  ACCOUNT_LOGGED_IN_VIEW = 'account.logged-in.view',
  /** Wyświetlenie użytkowników */
  USER_VIEW = 'account.view',
  /** Admin użytkowników */
  USER_ADMIN = 'account.admin',
  /** Wyświetlenie listy użytkowników portalu operatora */
  ACCOUNT_OPERATOR_ADMIN = 'account.account-operator.admin',

  /** Wyświetlenie listy użytkowników Portalu Klienta */
  IBTM_DOMAIN_CLIENT_PORTAL_USER_LIST_VIEW = 'group.permission.ibtm-domain.client-portal.user-list.view',
  /** Wyświetlenie szczegółów konta użytkownika Portalu Klienta */
  IBTM_DOMAIN_CLIENT_PORTAL_USER_DETAILS_VIEW = 'group.permission.ibtm-domain.client-portal.user-details.view',
  /** Dodanie konta użytkownika Portalu Klienta */
  IBTM_DOMAIN_CLIENT_PORTAL_USER_CREATE = 'group.permission.ibtm-domain.client-portal.user.create',
  /** Dodanie dostępnego przedsiębiorstwa */
  IBTM_DOMAIN_CLIENT_PORTAL_USER_SUBJECT_ADD = 'group.permission.ibtm-domain.client-portal.user-subject.add',
  /** Usuniecie dostępnych przedsiębiorstw */
  IBTM_DOMAIN_CLIENT_PORTAL_USER_SUBJECT_DELETE = 'group.permission.ibtm-domain.client-portal.user-subject.delete',
  /** Edycja konta użytkownika Portalu Klienta */
  IBTM_DOMAIN_CLIENT_PORTAL_USER_UPDATE = 'group.permission.ibtm-domain.client-portal.user.update',
  /** Zmiana hasła do Portalu Klienta */
  IBTM_DOMAIN_CLIENT_PORTAL_USER_PASSWORD_CHANGE = 'group.permission.ibtm-domain.client-portal.user-password.change',

  // /** AlertPermissionEnum */

  /** Wyświetlenie listy powiadomień */
  IBTM_DOMAIN_NOTIFICATION_LIST_VIEW = 'group.permission.ibtm-domain.notification.list.view',
  /** Dodanie powiadomienia */
  IBTM_DOMAIN_NOTIFICATION_CREATE = 'group.permission.ibtm-domain.notification.create',
  /** Wyświetlenie szczegółów powiadomienia */
  IBTM_DOMAIN_NOTIFICATION_DETAILS_VIEW = 'group.permission.ibtm-domain.notification.details.view',
  /** Edycja powiadomienia */
  IBTM_DOMAIN_NOTIFICATION_UPDATE = 'group.permission.ibtm-domain.notification.update',
  /** Usunięcie powiadomienia */
  IBTM_DOMAIN_NOTIFICATION_DELETE = 'group.permission.ibtm-domain.notification.delete',

  // /** ReportPermissionEnum */

  REPORT_REPORT_TYPE_ADMIN = 'report.report-type.admin',
  REPORT_REPORT_TYPE_VIEW = 'report.report-type.view'
}
