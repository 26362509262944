import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationDetails as ApplicationDetailsClient } from '@ibtm/client-domain';
import { ApplicationDetails, DecisionDetails } from '@ibtm/domain';
import moment from 'moment';

import { KeyType } from '@libs/common';
import {
  DatepickerField,
  DictionarySelectField,
  GridLayout,
  Section,
  TextInputField,
  useFormV2Context
} from '@libs/common/v2';
import { DatePickerFieldValidation } from '@libs/common/v2/form/validation';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { PermissionTypeEnum, usePermissionsQuery } from '../../../../permission';
import { useApplicationDecisionQuery } from '../../../api';
import { AdditionalSectionLicenseOrPermissionType } from '../../../model';
import { AdditionalSectionLicenseOrPermissionShortType } from '../../../model/AdditionalSectionLicenseOrPermissionType';

const setFormData = (
  data: DecisionDetails[],
  setValue: (label: string, value: any) => void,
  getLabel: (string) => string
) => {
  const license = data?.find(
    (item: DecisionDetails) => item?.permissionTypeKey === DomainDictionaryEntry.DECISION_PERMISSION_TYPE.LICENSE
  );
  if (license) {
    setValue(getLabel('license.id'), license?.id);
    setValue(getLabel('license.version'), license?.version);
    setValue(getLabel('license.settlement'), license?.settlement);
    setValue(getLabel('license.reason'), license?.reason);
    setValue(getLabel('license.type'), license?.type);
    setValue(getLabel('license.permissionTypeKey'), license?.permissionTypeKey);
    setValue(getLabel('license.permissionExpirationDate'), license?.permissionExpirationDate);
  }

  const permission = data?.find(
    (item: DecisionDetails) => item?.permissionTypeKey === DomainDictionaryEntry.DECISION_PERMISSION_TYPE.PERMISSION
  );
  if (permission) {
    setValue(getLabel('permission.id'), permission?.id);
    setValue(getLabel('permission.version'), permission?.version);
    setValue(getLabel('permission.settlement'), permission?.settlement);
    setValue(getLabel('permission.reason'), permission?.reason);
    setValue(getLabel('permission.type'), permission?.type);
    setValue(getLabel('permission.permissionTypeKey'), permission?.permissionTypeKey);
    setValue(getLabel('permission.permissionExpirationDate'), permission?.permissionExpirationDate);
  }

  const driverCertificate = data?.find(
    (item: DecisionDetails) =>
      item?.permissionTypeKey === DomainDictionaryEntry.DECISION_PERMISSION_TYPE.DRIVER_CERTIFICATE
  );
  if (driverCertificate) {
    setValue(getLabel('driverCertificate.id'), driverCertificate?.id);
    setValue(getLabel('driverCertificate.version'), driverCertificate?.version);
    setValue(getLabel('driverCertificate.settlement'), driverCertificate?.settlement);
    setValue(getLabel('driverCertificate.reason'), driverCertificate?.reason);
    setValue(getLabel('driverCertificate.type'), driverCertificate?.type);
    setValue(getLabel('driverCertificate.permissionTypeKey'), driverCertificate?.permissionTypeKey);
    setValue(getLabel('driverCertificate.permissionExpirationDate'), driverCertificate?.permissionExpirationDate);
  }
};

function AdditionalSectionLicenseOrPermission({
  sectionTypes = [],
  fieldId,
  applicationData
}: {
  sectionTypes?: AdditionalSectionLicenseOrPermissionType[];
  fieldId?: string;
  applicationData: ApplicationDetails | (ApplicationDetailsClient & { dateOfSubjectDeath?: string });
}) {
  const [t] = useTranslation();
  const getTranslation = (label: string) => t(`applications:outgoing-documents.fields.${label}` as KeyType);
  const { data } = useApplicationDecisionQuery(applicationData?.id, { enabled: !!applicationData });

  // eslint-disable-next-line consistent-return
  const maxDateValidation = useMemo(() => {
    if (applicationData?.dateOfSubjectDeath) {
      if (
        [
          DomainDictionaryEntry.APPLICATION_TYPE.PB28,
          DomainDictionaryEntry.APPLICATION_TYPE.PB8A,
          DomainDictionaryEntry.APPLICATION_TYPE.PB8B,
          DomainDictionaryEntry.APPLICATION_TYPE.PB7B
        ].includes(applicationData?.typeKey)
      )
        return { date: moment(applicationData?.dateOfSubjectDeath).add(18, 'month').toDate(), numberOfMonths: 18 };

      if (
        [
          DomainDictionaryEntry.APPLICATION_TYPE.PB27,
          DomainDictionaryEntry.APPLICATION_TYPE.PB9A,
          DomainDictionaryEntry.APPLICATION_TYPE.PB9B
        ].includes(applicationData?.typeKey)
      )
        return { date: moment(applicationData?.dateOfSubjectDeath).add(24, 'month').toDate(), numberOfMonths: 24 };
    }
  }, [applicationData]);

  const { setValue } = useFormV2Context();

  const getLabel = (label: string) => `${fieldId}.${label}`;
  const typesInclude = (type: AdditionalSectionLicenseOrPermissionType) => sectionTypes.includes(type);

  useEffect(() => {
    if (data) {
      setFormData(data, setValue, getLabel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isFolderPpo = useCallback(
    () => applicationData?.folder?.typeKey === DomainDictionaryEntry.FOLDER_TYPE.PPO,
    [applicationData]
  );
  const isMoreThenOneType = !typesInclude(AdditionalSectionLicenseOrPermissionType.PERMISSION_IF_NOT_PPO)
    ? sectionTypes.length > 1
    : !isFolderPpo() && sectionTypes.length > 1;

  const permissionExpirationValidation = useMemo(
    () => ({
      test: [
        {
          name: 'date',
          message: t('applications:validations.dateCanNotBeAfterSubjectDeathPlusNumberOfMonths', {
            numberOfMonths: maxDateValidation?.numberOfMonths
          }),

          test: date => {
            return !date || moment(date).isSameOrBefore(maxDateValidation?.date);
          }
        }
      ]
    }),
    [maxDateValidation, t]
  ) as DatePickerFieldValidation;

  const isArchiveNumberType = (archiveNumber: string, type: AdditionalSectionLicenseOrPermissionShortType) => {
    if (!archiveNumber) {
      return false;
    }
    const archiveNumberFragments = archiveNumber.split('.');
    return archiveNumberFragments[archiveNumberFragments.length - 2] === type;
  };

  const isTransferApplication = useMemo(
    () =>
      [
        DomainDictionaryEntry.APPLICATION_TYPE.PB6B,
        DomainDictionaryEntry.APPLICATION_TYPE.PB6D,
        DomainDictionaryEntry.APPLICATION_TYPE.PB7A
      ].includes(applicationData.typeKey),
    [applicationData.typeKey]
  );

  const transferredFolderId = useMemo(
    () => applicationData?.licenseApplicationDetails?.transferApplicationDataDetails?.folders[0]?.id,
    [applicationData?.licenseApplicationDetails?.transferApplicationDataDetails?.folders]
  );

  const { data: permissionData } = usePermissionsQuery(
    {
      folderIdIn: [transferredFolderId],
      typeKeyIn: [PermissionTypeEnum.ROAD_CARRIER_GOODS_CARRIAGE, PermissionTypeEnum.ROAD_CARRIER_PASSENGER_CARRIAGE],
      isIssuedByExternalAuthority: false,
      includePartnershipPermissions: true
    },
    {
      enabled: !!transferredFolderId && isTransferApplication
    }
  );

  return (
    <Section title={t('applications:outgoing-documents.title')} isCollapsable>
      <GridLayout itemProps={{ xs: 12, sm: 12 }}>
        {typesInclude(AdditionalSectionLicenseOrPermissionType.DRIVER_CERTIFICATE) ? (
          <Section
            title={t('applications:outgoing-documents.sectionTitles.driverCertificate', {
              id: applicationData?.archiveNumber
            })}
            isModalSection
          >
            <GridLayout itemProps={{ xs: 12, sm: 6 }}>
              <DictionarySelectField
                dictionaryName="DECISION_SETTLEMENT"
                label={getTranslation('settlement')}
                name={getLabel('driverCertificate.settlement')}
                validationSingleSelect={{ required: [] }}
                isRequired
              />
              <TextInputField
                label={getTranslation('reason')}
                name={getLabel('driverCertificate.reason')}
                lines={2}
                validation={{ required: [] }}
                isRequired
              />

              <DictionarySelectField
                dictionaryName="DECISION_TYPE"
                label={getTranslation('settlementType')}
                name={getLabel('driverCertificate.type')}
                validationSingleSelect={{ required: [] }}
                isRequired
              />
              <DatepickerField
                label={getTranslation('permissionExpirationDate')}
                name={getLabel('driverCertificate.permissionExpirationDate')}
                validation={permissionExpirationValidation}
              />
            </GridLayout>
          </Section>
        ) : null}

        {(typesInclude(AdditionalSectionLicenseOrPermissionType.PERMISSION) ||
          (typesInclude(AdditionalSectionLicenseOrPermissionType.PERMISSION_IF_NOT_PPO) && !isFolderPpo())) &&
        ((isTransferApplication && permissionData?.content?.length > 0) || !isTransferApplication) ? (
          <Section
            title={t('applications:outgoing-documents.sectionTitles.permission', {
              id:
                isMoreThenOneType &&
                isArchiveNumberType(
                  applicationData?.secondArchiveNumber,
                  AdditionalSectionLicenseOrPermissionShortType.PERMISSION
                )
                  ? applicationData?.secondArchiveNumber
                  : applicationData?.archiveNumber
            })}
            isModalSection
          >
            <GridLayout itemProps={{ xs: 6, sm: 12, md: 6 }}>
              <DictionarySelectField
                dictionaryName="DECISION_SETTLEMENT"
                label={getTranslation('settlement')}
                name={getLabel('permission.settlement')}
                validationSingleSelect={{ required: [] }}
                isRequired
              />
              <TextInputField
                label={getTranslation('reason')}
                name={getLabel('permission.reason')}
                lines={2}
                validation={{ required: [] }}
                isRequired
              />

              <DictionarySelectField
                dictionaryName="DECISION_TYPE"
                label={getTranslation('settlementType')}
                name={getLabel('permission.type')}
                validationSingleSelect={{ required: [] }}
                isRequired
              />
              <DatepickerField
                label={getTranslation('permissionExpirationDate')}
                name={getLabel('permission.permissionExpirationDate')}
                validation={permissionExpirationValidation}
              />
            </GridLayout>
          </Section>
        ) : null}
        {typesInclude(AdditionalSectionLicenseOrPermissionType.LICENSE) && (
          <Section
            title={t('applications:outgoing-documents.sectionTitles.license', {
              id:
                isMoreThenOneType &&
                isArchiveNumberType(
                  applicationData?.secondArchiveNumber,
                  AdditionalSectionLicenseOrPermissionShortType.LICENSE
                )
                  ? applicationData?.secondArchiveNumber
                  : applicationData?.archiveNumber
            })}
            isModalSection
          >
            <GridLayout itemProps={{ xs: 6, sm: 12, md: 6 }}>
              <DictionarySelectField
                dictionaryName="DECISION_SETTLEMENT"
                label={getTranslation('settlement')}
                name={getLabel('license.settlement')}
                validationSingleSelect={{ required: [] }}
                isRequired
              />
              <TextInputField
                label={getTranslation('reason')}
                name={getLabel('license.reason')}
                lines={2}
                validation={{ required: [] }}
                isRequired
              />

              <DictionarySelectField
                dictionaryName="DECISION_TYPE"
                label={getTranslation('settlementType')}
                name={getLabel('license.type')}
                validationSingleSelect={{ required: [] }}
                isRequired
              />
              <DatepickerField
                label={getTranslation('permissionExpirationDate')}
                name={getLabel('license.permissionExpirationDate')}
                validation={permissionExpirationValidation}
              />
            </GridLayout>
          </Section>
        )}
      </GridLayout>
    </Section>
  );
}

export default AdditionalSectionLicenseOrPermission;
