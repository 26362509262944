import { QueryConfig } from 'react-query';
import { ApplicationPrintingItem } from '@ibtm/domain';

import { useGetApplicationPrintingItem } from '../api';
import { usePrintsDetailsDataContext } from '../context';

function useFetchPrintsApplicationDetails(config: QueryConfig<ApplicationPrintingItem, unknown> = {}) {
  const { printDetailsData } = usePrintsDetailsDataContext();

  const query = useGetApplicationPrintingItem(printDetailsData.applicationId, {
    enabled: Boolean(printDetailsData.applicationId),
    refetchOnMount: false,
    ...config
  });
  return {
    applicationData: query.data,
    printDetailsData,
    applicationSnapshot: printDetailsData.applicationSnapshot,
    query
  };
}
export default useFetchPrintsApplicationDetails;
