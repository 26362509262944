import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { isArray } from 'lodash';

import { LoaderCircular, useRouter } from '@libs/common/v2';

import usePermissions from '@libs/permission/hooks/usePermissions';

type CheckPermissionsParams =
  | {
      permissions: Array<string>;
      anyOfPermissions?: Array<string>;
      redirectTo?: string;
      checkPermissions?: undefined;
      hasAllPermissions?: boolean;
    }
  | {
      checkPermissions: boolean;
      redirectTo?: string;
      permissions?: undefined;
      anyOfPermissions?: Array<string>;
      hasAllPermissions?: undefined;
    };

const useRedirect = ({
  shouldRedirect,
  redirectTo,
  isSnackbarOnRedirectVisible = true
}: {
  shouldRedirect: boolean;
  redirectTo?: string;
  isSnackbarOnRedirectVisible?: boolean;
}) => {
  const [t] = useTranslation();
  const { showErrorSnackbar } = useSnackbar();
  const { goToPage } = useRouter();

  const redirectPath = redirectTo || '/';

  useEffect(() => {
    if (shouldRedirect) {
      goToPage(redirectPath);
      if (isSnackbarOnRedirectVisible) {
        showErrorSnackbar(t('error.noPermissions'));
      }
    }
  }, [goToPage, isSnackbarOnRedirectVisible, redirectPath, redirectTo, shouldRedirect, showErrorSnackbar, t]);
};

export const useRoutePermissionCheck = ({
  permissions,
  redirectTo,
  checkPermissions = true,
  anyOfPermissions,
  hasAllPermissions,
  isSnackbarOnRedirectVisible
}: CheckPermissionsParams & { isSnackbarOnRedirectVisible?: boolean }) => {
  const { hasPermission: checkHasPermission, hasAllPermission: checkHasAllPermissions } = usePermissions();

  const permissionNotExist = !permissions || permissions.length === 0;
  const hasPermission =
    !checkPermissions ||
    permissionNotExist ||
    (hasAllPermissions ? checkHasAllPermissions(permissions) : checkHasPermission(permissions));
  const hasAnyOfPermissions = isArray(anyOfPermissions) ? checkHasPermission(anyOfPermissions) : true;

  useRedirect({
    shouldRedirect: isArray(anyOfPermissions) ? !hasPermission || !hasAnyOfPermissions : !hasPermission,
    redirectTo,
    isSnackbarOnRedirectVisible
  });

  return { hasPermissions: hasPermission };
};

type TProps = {
  children: JSX.Element;
  isLoading?: boolean;
  notAllowedCode?: boolean;
  notValidRoute?: boolean;
  redirectTo?: string;
} & (
  | {
      hasAllPermissions?: boolean;
      permissions: Array<string>;
      anyOfPermissions?: Array<string>;
      isPermitted?: undefined;
    }
  | {
      permissions?: undefined;
      hasAllPermissions?: undefined;
      anyOfPermissions?: undefined;
      isPermitted: boolean;
    }
);

export const RoutePermissionGuard = ({
  children,
  isPermitted,
  permissions,
  hasAllPermissions,
  anyOfPermissions,
  isLoading,
  notAllowedCode,
  notValidRoute,
  redirectTo
}: TProps) => {
  useRedirect({ shouldRedirect: notAllowedCode || notValidRoute, redirectTo });

  const { hasPermissions } = useRoutePermissionCheck({
    checkPermissions: !isPermitted || isLoading,
    permissions,
    hasAllPermissions,
    redirectTo,
    anyOfPermissions
  } as CheckPermissionsParams);

  if (isLoading) {
    return <LoaderCircular isLoading />;
  }

  return hasPermissions ? children : null;
};
