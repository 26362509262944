import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { FormMode, Icon, IconButton, Typography, useRouter } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';
import { Theme, useTheme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import { DomainUIElementEnum } from '@libs/domain/config';
import { FolderDetailsTabEnum } from '@libs/domain/folder';

interface IProps {
  id: string;
  formMode?: FormMode;
  isBlockadeListRedirectActionVisible?: boolean;
}

function FolderBlockade({ formMode, id, isBlockadeListRedirectActionVisible }: IProps) {
  const [t] = useTranslation();
  const { contrast, fontSizeZoom } = useTheme();
  const { routes } = useRouter();

  const classes = useStyles({ contrast, fontSizeZoom });
  const { viewMode } = useViewModesV2(formMode);

  return (
    <div className={clsx(classes.root, 'flex')}>
      <div className="flex items-center">
        <Icon icon="LockIcon" className="mr-12" />
        <Typography themeVariant="textSm.normal" component="p" className={classes.text}>
          {t('folder:sidebar.section.blockade')}
        </Typography>
      </div>
      {isBlockadeListRedirectActionVisible && (
        <IconButton
          icon="ArrowIcon"
          className={clsx('ml-auto', classes.gotToButton)}
          tooltipTitle={t('folder:sidebar.tooltip.goToBlockadeList')}
          isBackgroundTransparent
          actionKey={DomainUIElementEnum.FOLDER_LOCK_VIEW}
          link={{
            pathname: viewMode ? routes.folderDetails(id) : routes.folderEdit(id),
            search: `tab=${FolderDetailsTabEnum.LOCKS}`
          }}
        />
      )}
    </div>
  );
}

export default FolderBlockade;

const useStyles = makeStyles<Theme, { contrast: boolean; fontSizeZoom: number }>(theme => ({
  root: {
    backgroundColor: theme.palette.error[300],
    justifyContent: 'space-between',
    padding: 8,
    alignItems: 'center',
    '& svg': {
      color: ({ contrast }) => (contrast ? theme.palette.grey[50] : theme.palette.error[900])
    }
  },
  text: {
    color: ({ contrast }) => important(contrast ? theme.palette.grey[50] : theme.palette.error[900])
  },
  gotToButton: {
    height: ({ fontSizeZoom }) => fontSizeZoom * 25,
    width: ({ fontSizeZoom }) => fontSizeZoom * 25
  }
}));
