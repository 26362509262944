import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormMode, FormV2Context } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { InitialCreateFormValues, InitialEditFormValues, useFolderValidationSchema } from '@libs/domain/folder';

import FolderDetails from './FolderDetails';

interface IProps {
  formMode: FormMode;
}

function FolderDetailsWrapper({ formMode }: IProps) {
  const [localSubmitting, setLocalSubmitting] = useState<boolean>(false);
  const { createMode } = useViewModesV2(formMode);
  const folderValidationSchema = useFolderValidationSchema(formMode);
  const form = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(folderValidationSchema),
    defaultValues: createMode ? InitialCreateFormValues : InitialEditFormValues
  });

  const formDataProvider = useMemo(() => {
    return {
      ...form,
      formMode,
      isDirty: form.formState.isDirty,
      isSubmitting: form.formState.isSubmitting || localSubmitting
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, form.formState]);

  return (
    <FormV2Context.Provider value={formDataProvider}>
      <FolderDetails setSubmitting={setLocalSubmitting} />
    </FormV2Context.Provider>
  );
}

export default FolderDetailsWrapper;
