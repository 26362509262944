import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { isFunction } from 'lodash';

import { partialTranslate } from '@libs/common';
import { useConfirmDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useDeleteMultipleVehicleMutation, VehicleQueryKeysEnum } from '@libs/domain/vehicle';

const translate = partialTranslate('vehicle:cleanFleetVehicle');

function useClearVehicles(applicationId: string) {
  const queryCache = useQueryCache();
  const [confirm] = useConfirmDialog();
  const [t] = useTranslation();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { mutate: deleteVehicles } = useDeleteMultipleVehicleMutation();

  const clearSelectedVehicles = (selected: string[], onSuccess?: () => void) => {
    const isPlural = selected.length > 1;
    confirm({
      message: isPlural ? translate('cleanSelectedContentPlural') : translate('cleanSelectedContentSingular'),
      onConfirm: (setLoading, closeDialog) => {
        setLoading(true);
        deleteVehicles(
          { applicationId, vehicleIds: selected },
          {
            onSuccess: () => {
              showSuccessSnackbar(isPlural ? translate('messageSuccessPlural') : translate('messageSuccessSingular'));
              queryCache.invalidateQueries(VehicleQueryKeysEnum.VEHICLE_LIST);
              onSuccess?.();
            },
            onError: () => {
              showErrorSnackbar(isPlural ? translate('messageFailurePlural') : translate('messageFailureSingular'));
            },
            onSettled: () => {
              closeDialog();
              setLoading(false);
            }
          }
        );
      },
      title: t('vehicle:deleteModalTitle'),
      confirmType: 'danger'
    });
  };

  const clearAllVehicles = (onSuccess?: () => void) => {
    confirm({
      message: translate('cleanAllContent'),
      onConfirm: (setLoading, closeDialog) => {
        setLoading(true);
        deleteVehicles(
          { applicationId, vehicleIds: [] },
          {
            onSuccess: () => {
              showSuccessSnackbar(translate('messageSuccessPlural'));
              queryCache.invalidateQueries(VehicleQueryKeysEnum.VEHICLE_LIST);
              if (isFunction(onSuccess)) {
                onSuccess?.();
              }
            },
            onError: () => {
              showErrorSnackbar(translate('messageFailurePlural'));
            },
            onSettled: () => {
              closeDialog();
              setLoading(false);
            }
          }
        );
      },
      title: t('vehicle:deleteModalTitle'),
      confirmType: 'danger'
    });
  };

  return { clearSelectedVehicles, clearAllVehicles };
}

export default useClearVehicles;
