import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { IconCountButton, IconType, useTableAdapter } from '@libs/common/v2';

import TableIconButton from './TableIconButton';

interface IProps<T extends Record<string, any>> {
  row?: Row<T>;
  children: (close?: () => void) => any;
  customPopoverClasses?: { paper: string };
  tooltipTitle?: string;
  groupHeaderTitle?: string;
  iconClassName?: string;
  iconName?: IconType;
  countValue?: number;
  isLoading?: boolean;
  isIconButtonCount?: boolean;
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
}

function TableButtonMore<T extends Record<string, any>>({
  children,
  tooltipTitle,
  groupHeaderTitle,
  iconClassName,
  iconName,
  customPopoverClasses,
  row,
  countValue,
  isLoading,
  isIconButtonCount = false,
  onClick
}: IProps<T>) {
  const [t] = useTranslation();
  const { state } = useTableAdapter();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onClick?.(event);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (state.rowId) {
      setAnchorEl(null);
    }
  }, [state.rowId]);

  useEffect(() => {
    if (state.rowId) {
      setAnchorEl(null);
    }
  }, [state.rowId]);

  const showButton = () => {
    const childrenResult = children();
    if (Array.isArray(childrenResult)) {
      return childrenResult?.find(child => typeof child === 'object');
    }

    return childrenResult?.props?.children?.find(child => typeof child === 'object');
  };
  return (
    <>
      {showButton() && (
        <>
          {isIconButtonCount ? (
            <IconCountButton
              className={iconClassName}
              icon={iconName}
              tooltipTitle={tooltipTitle || t('action.more')}
              countValue={countValue}
              onClick={handleClick}
              isLoading={isLoading}
            />
          ) : (
            <TableIconButton
              icon="MoreVerticalIcon"
              tooltipTitle={tooltipTitle || t('action.more')}
              className={iconClassName}
              onClick={handleClick}
              row={row}
              isButtonLoading={isLoading}
            />
          )}
          <Popover
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            classes={customPopoverClasses ?? classes}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <div className="flex flex-col">
              {groupHeaderTitle ? (
                <>
                  <div className={clsx(classes.groupHeaderWrapper)}>{groupHeaderTitle}</div>
                  {children(handleClose)}
                </>
              ) : (
                children(handleClose)
              )}
            </div>
          </Popover>
        </>
      )}
    </>
  );
}

const useStyles = makeStyles(() => ({
  paper: {
    '& svg': {
      marginRight: 0
    }
  },
  groupHeaderWrapper: {
    padding: '10px 16px',
    width: 'calc(100% - 32px)',
    justifyContent: 'flex-start',
    borderRadius: 0,
    fontWeight: 600
  }
}));

export default React.memo(TableButtonMore);
