import { useTranslation } from 'react-i18next';

import { Dialog, useTableContext } from '@libs/common/v2';

import { useDeleteAssociationMutation } from '../../api';

interface Props {
  deleteAssociationId: string;
  onCancel: () => void;
}

function ConfirmDeleteAssociationModal({ deleteAssociationId, onCancel }: Props) {
  const [t] = useTranslation();

  const { mutateAsync: deleteAssociation, isLoading } = useDeleteAssociationMutation();
  const { refetch } = useTableContext();

  const confirmDeleteHandler = async () => {
    await deleteAssociation(deleteAssociationId);
    refetch();
    onCancel();
  };

  return (
    <Dialog
      title={t('associations:action.delete')}
      confirmText={t('action.confirm')}
      cancelText={t('action.cancel')}
      onConfirm={confirmDeleteHandler}
      onCancel={onCancel}
      confirmButtonType="danger"
      isConfirmLoading={isLoading}
      isOpen={!!deleteAssociationId}
      hasIconButtonsOnSmallScreen
    >
      {t('associations:messages.deleteAssociation')}
    </Dialog>
  );
}

export default ConfirmDeleteAssociationModal;
