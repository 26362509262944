import { ApplicationsApiUpdateOrganizationUnitRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

export function assignApplicationToOrganization(requestData: ApplicationsApiUpdateOrganizationUnitRequest) {
  return API.application.updateOrganizationUnit(requestData.applicationId, requestData.applicationUnitUpdateRequest, {
    ...endpointsConfig.updateOrganizationUnit
  });
}

function useAssignApplicationToOrganizationMutation() {
  return useMutation(assignApplicationToOrganization);
}

export default useAssignApplicationToOrganizationMutation;
