import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { IAssociationOrderRanges, PoolItem } from '../model';

export const usePoolsValidationSchema = (selectedRowsData: (PoolItem | IAssociationOrderRanges)[]) => {
  const [t] = useTranslation();

  return Yup.array().of(
    Yup.object()
      .shape({
        numberFrom: Yup.number()
          .transform(value => (Number.isNaN(value) ? undefined : value))
          .required(),
        numberTo: Yup.number()
          .transform(value => (Number.isNaN(value) ? undefined : value))
          .required(),
        amount: Yup.number()
          .transform(value => (Number.isNaN(value) ? undefined : value))
          .required()
      })
      .test('is-range-valid', '', function checkIsRangeValid(item) {
        const initialValues = selectedRowsData.find(row => row.id === item.id);

        if (item.numberFrom < initialValues.numberFrom || item.numberFrom > initialValues.numberTo) {
          return this.createError({
            path: `${this.path}.numberFrom`,
            message: t('resource:messages.rangeValidatorMessage', {
              numberFrom: initialValues.numberFrom,
              numberTo: initialValues.numberTo
            })
          });
        }

        if (item.numberTo > initialValues.numberTo || item.numberTo < initialValues.numberFrom) {
          return this.createError({
            path: `${this.path}.numberTo`,
            message: t('resource:messages.rangeValidatorMessage', {
              numberFrom: initialValues.numberFrom,
              numberTo: initialValues.numberTo
            })
          });
        }

        return true;
      })
  );
};
