import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  DictionarySelectField,
  GridLayout,
  Section,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { TransportManagerDetailsClient } from '@libs/domain/transport-manager';

function CertificateData() {
  const getTransportManagerTranslation = partialTranslate('transportManager:details');

  return (
    <Section title={getTransportManagerTranslation('section.certificate')} isCollapsable isModalSection>
      <GridLayout itemProps={{ xs: 12, md: 6 }}>
        <TextInputField
          name={typedNameV2<TransportManagerDetailsClient>('certificate.number')}
          label={getTransportManagerTranslation('field.certificateNumber')}
          inputProps={{ maxLength: 20 }}
          isRequired
        />
        <DictionarySelectField
          name={typedNameV2<TransportManagerDetailsClient>('certificate.typeKey')}
          label={getTransportManagerTranslation('field.certificateType')}
          dictionaryName={DomainDictionaryEnum.TRANSPORT_MANAGER_CERTIFICATE_TYPE}
          isRequired
          stringValue
        />
        <DatepickerField
          name={typedNameV2<TransportManagerDetailsClient>('certificate.dateOfIssue')}
          label={getTransportManagerTranslation('field.dateOfIssue')}
          isRequired
        />
        <TextInputField
          name={typedNameV2<TransportManagerDetailsClient>('certificate.issuingAuthority')}
          label={getTransportManagerTranslation('field.issuingAuthority')}
          inputProps={{ maxLength: 150 }}
          isRequired
        />
        <DictionarySelectField
          name={typedNameV2<TransportManagerDetailsClient>('certificate.issuingCountryKey')}
          label={getTransportManagerTranslation('field.issuingCountryKey')}
          dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
          isRequired
          stringValue
        />
      </GridLayout>
    </Section>
  );
}

export default CertificateData;
