import { ReactNode } from 'react';
import {
  AccountCreateCommand,
  AccountSnapshot,
  PersonalDataSnapshot,
  WorkSystemDetails
} from '@avispon/user/dist/models';

import { FormMode } from '@libs/common/v2';

export interface SectionVisibleConfig {
  name: SectionNames;
  isVisible: {
    [FormMode.CREATE]?: boolean;
    [FormMode.VIEW]?: boolean;
    [FormMode.EDIT]?: boolean;
  };
}

export type AdditionalFields = {
  [key in SectionNames]?: Array<ReactNode>;
};

export type FieldNames =
  | keyof AccountCreateCommand
  | keyof AccountSnapshot
  | keyof PersonalDataSnapshot
  | keyof WorkSystemDetails
  | 'repeatPassword';

export enum SectionNames {
  PERSONAL_DATA_SECTION = 'PERSONAL_DATA_SECTION',
  ORGANIZATION_DATA_SECTION = 'ORGANIZATION_DATA_SECTION',
  LOGIN_SECTION = 'LOGIN_SECTION',
  WORK_SYSTEM_HOURS_SECTION = 'WORK_SYSTEM_HOURS_SECTION',
  ADDITIONAL_INFORMATION_SECTION = 'ADDITIONAL_INFORMATION_SECTION',
  ORGANIZATION_UNIT_SECTION = 'ORGANIZATION_UNIT_SECTION'
}

export const defaultVisibleSections: { [key in SectionNames]: SectionVisibleConfig } = {
  PERSONAL_DATA_SECTION: {
    name: SectionNames.PERSONAL_DATA_SECTION,
    isVisible: { CREATE: true, EDIT: true, VIEW: true }
  },
  ORGANIZATION_DATA_SECTION: {
    name: SectionNames.ORGANIZATION_DATA_SECTION,
    isVisible: { CREATE: false, EDIT: false, VIEW: true }
  },
  LOGIN_SECTION: {
    name: SectionNames.LOGIN_SECTION,
    isVisible: { CREATE: true, EDIT: false, VIEW: false }
  },
  WORK_SYSTEM_HOURS_SECTION: {
    name: SectionNames.WORK_SYSTEM_HOURS_SECTION,
    isVisible: { CREATE: false, EDIT: false, VIEW: false }
  },
  ADDITIONAL_INFORMATION_SECTION: {
    name: SectionNames.ADDITIONAL_INFORMATION_SECTION,
    isVisible: { CREATE: false, EDIT: true, VIEW: true }
  },
  ORGANIZATION_UNIT_SECTION: {
    name: SectionNames.ORGANIZATION_UNIT_SECTION,
    isVisible: { CREATE: true, EDIT: false, VIEW: false }
  }
};
export const defaultVisibleFields: FieldNames[] = [
  'name',
  'surname',
  'email',
  'serviceNumber',
  'organizationUnit',
  'status',
  'loggedIn',
  'additionalInformation',
  'typeKey',
  'workHours',
  'positionKey',
  'position'
];
