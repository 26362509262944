import { PermissionRestoreFromDistrictAuthorityRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const restoreFromDistrictAuthority = ({
  id,
  requestData
}: {
  id: string;
  requestData: PermissionRestoreFromDistrictAuthorityRequest;
}) => {
  return API.permissions.restoreFromDistrictAuthority(id, requestData, {
    ...endpointsConfig.restoreFromDistrictAuthority
  });
};

export const usePermissionRestoreFromDistrictAuthorityMutation = () => useMutation(restoreFromDistrictAuthority);
