import { DefaultApiAssignMemberRequest } from '@avispon/group';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '@libs/user/api';

const addUserToPermissionGroupsMutation = async (params: DefaultApiAssignMemberRequest) => {
  const { data } = await API.group.assignMember(params, { ...endpointsConfig.assignMember });
  return data;
};

export default function useAddUserToPermissionGroupsMutation() {
  return useMutation(addUserToPermissionGroupsMutation);
}
