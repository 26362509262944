import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

import { DatepickerField, FormMode, InputMode, useFormV2Context, useViewModesV2 } from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';

import { DomainDictionaryEntry } from '../../../../config';
import DomainUIElementEnum from '../../../../config/permission/DomainUIElementEnum';
import { useApplicationDetailsQuery } from '../../../api';
import { IApplicationDetailsPageParams } from '../../../model';

interface IProps {
  fieldId: string;
  label: string;
  api: {
    get: {
      accessor: string;
    };
  };
  wasIncomplete: boolean;
  statusKey: string;
  formMode: FormMode;
}

function LastSupplementReceiptDate({ fieldId, label, api, wasIncomplete, statusKey, formMode }: IProps) {
  const { setValue } = useFormV2Context();
  const { applicationId } = useParams<IApplicationDetailsPageParams>();
  const { data } = useApplicationDetailsQuery(applicationId, { refetchOnMount: false });
  const { viewMode } = useViewModesV2(formMode);
  const { checkIsElementVisible } = useElementVisibility();

  const hasPermission = checkIsElementVisible(DomainUIElementEnum.APPLICATION_LAST_SUPPLEMENT_RECEIPT_DATE_INPUT);

  const isFieldActive =
    !viewMode &&
    (statusKey === DomainDictionaryEntry.APPLICATION_STATUS.INCOMPLETE || (wasIncomplete && hasPermission));

  useEffect(() => {
    if (data) {
      setValue(fieldId, _.get(data, api?.get?.accessor));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <DatepickerField
      maxDate={moment()}
      inputMode={isFieldActive ? InputMode.FORM : InputMode.VIEW}
      name={fieldId}
      label={label}
      validation={isFieldActive && { required: [] }}
    />
  );
}

export default LastSupplementReceiptDate;
