export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  getProceedingFormsPage: { translationKey: 'proceeding:api.getProceedingFormsPage' },
  getProceeding: { translationKey: 'proceeding:api.getProceeding' },
  activateProceedingForms: { translationKey: 'proceeding:api.activateProceedingForms' },
  addProceeding: { translationKey: 'proceeding:api.addProceeding' },
  deleteProceeding: { translationKey: 'proceeding:api.deleteProceeding' },
  editProceeding: { translationKey: 'proceeding:api.editProceeding' },
  expireProceeding: { translationKey: 'proceeding:api.expireProceeding' },
  cancelProceeding: { translationKey: 'proceeding:api.cancelProceeding' },
  closeProceeding: { translationKey: 'proceeding:api.closeProceeding' },
  createProceeding: { translationKey: 'proceeding:api.createProceeding' },
  deleteProceedingsDocument: { translationKey: 'proceeding:api.deleteProceedingsDocument' },
  revokeProceeding: { translationKey: 'proceeding:api.revokeProceeding' },
  suspendProceeding: { translationKey: 'proceeding:api.suspendProceeding' },
  createOrUpdateProceedingNote: { translationKey: 'proceeding:api.createOrUpdateProceedingNote' },
  getProceedingNote: { translationKey: 'proceeding:api.getProceedingNote' }
};
