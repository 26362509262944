import { FolderLimitUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function updateLimit({
  folderLimitId,
  folderLimitUpdateRequest
}: {
  folderLimitId: string;
  folderLimitUpdateRequest: FolderLimitUpdateRequest;
}) {
  return API.folderLimits.updateFolderLimit(folderLimitId, folderLimitUpdateRequest, {
    ...endpointsConfig.updateFolderLimit
  });
}

function useUpdateLimitMutation() {
  return useMutation(updateLimit);
}

export default useUpdateLimitMutation;
