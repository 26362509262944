import { SubjectUpdateRequest } from '@ibtm/client-domain';
import { SubjectUpdateRequest as OperatorSubjectUpdateRequest } from '@ibtm/domain';

export enum ApplicationSubjectAddressTypeFieldsEnum {
  APPLICATION_PERMISSION_ADDRESS_FIELDS = 'application-permission-address-fields',
  APPLICATION_LICENSE_ADDRESS_FIELDS = 'application-license-address-fields'
}

export enum ApplicationSubjectAddressTypeEnum {
  APPLICATION_PERMISSION_ADDRESS = 'permissionAddress',
  APPLICATION_LICENSE_ADDRESS = 'licenseAddress'
}
export type IApplicationDetailsPageParams = {
  applicationId: string;
};

export type SubjectUpdateRequestUnion = SubjectUpdateRequest & OperatorSubjectUpdateRequest;
