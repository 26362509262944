import { AxiosRequestConfig } from 'axios';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '@libs/notification/api';

const deleteMessagesByUser = (notificationsIds: string[]) => {
  return API.message.deleteNotificationsByUser({ body: { notificationsIds } }, {
    ...endpointsConfig.deleteNotificationsByUser
  } as AxiosRequestConfig);
};

function useDeleteMessagesByUserMutation() {
  return useMutation(deleteMessagesByUser);
}

export default useDeleteMessagesByUserMutation;
