import { Configuration as GroupConfiguration, DefaultApi as GroupDefaultApi } from '@avispon/group';
import { Configuration, DefaultApi as OrganizationStructureDefaultApi } from '@avispon/organization-structure';
import { AccountsApi, Configuration as UserConfiguration, DefaultApi } from '@avispon/user';
import axios from 'axios';

interface UserApi {
  user: {
    default: DefaultApi;
    accountController: AccountsApi;
  };
  group: GroupDefaultApi;
  organizationStructure: OrganizationStructureDefaultApi;
}

const userApiFactoryFallback: any = () => {
  throw new Error('User api has not been set');
};

const API: UserApi = {
  user: userApiFactoryFallback,
  group: userApiFactoryFallback,
  organizationStructure: userApiFactoryFallback
};

export function setUserApi(
  API_PORTAL_URL: string,
  defaultConfig: UserConfiguration = { isJsonMime: () => true },
  accountConfig: UserConfiguration = { isJsonMime: () => true },
  groupConfig: GroupConfiguration = { isJsonMime: () => true },
  organizationStructureConfig: Configuration = { isJsonMime: () => true }
) {
  API.user = {
    default: new DefaultApi(defaultConfig, API_PORTAL_URL, axios),
    accountController: new AccountsApi(accountConfig, API_PORTAL_URL, axios)
  };
  API.organizationStructure = new OrganizationStructureDefaultApi(organizationStructureConfig, API_PORTAL_URL, axios);
  API.group = new GroupDefaultApi(groupConfig, API_PORTAL_URL, axios);
}

export default API;
