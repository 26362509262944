import { RenounceCopyCreateOrUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

function createUpdateMutation({
  applicationId,
  renounceCopyCreateOrUpdateRequest
}: {
  applicationId: string;
  renounceCopyCreateOrUpdateRequest: RenounceCopyCreateOrUpdateRequest;
}) {
  return API.renounceCopies.createOrUpdateRenounceCopy(applicationId, renounceCopyCreateOrUpdateRequest);
}

function useCreateUpdateRenounceCopyMutation() {
  return useMutation(createUpdateMutation);
}

export default useCreateUpdateRenounceCopyMutation;
