import { DocumentCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const generateDocument = async (request: DocumentCreateRequest & { invoiceId: string }) => {
  const { invoiceId, ...rest } = request;
  return API.releaseDocuments.generateInvoiceDocument(invoiceId, rest, {
    ...endpointsConfig.generateInvoiceDocument
  });
};

function useGenerateDocumentMutation() {
  return useMutation(generateDocument);
}

export default useGenerateDocumentMutation;
