import { GridLayout } from '@libs/common/v2';

import { DecisionSection, SecondTierSection, StartSection } from '../sections';

function GoodReputationTab() {
  return (
    <div className="overflow-hidden">
      <GridLayout itemProps={{ xs: 12 }}>
        <StartSection hiddenFields={['startRehabilitationResource']} />
        <DecisionSection hiddenFields={['decisionPermissionSuspensionFrom', 'decisionPermissionSuspensionTo']} />
        <SecondTierSection />
      </GridLayout>
    </div>
  );
}

export default GoodReputationTab;
