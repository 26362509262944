import { usePaginatedQuery } from 'react-query';
import { DefaultApiPageUsersRequest } from '@avispon/group';

import { API, endpointsConfig, OrganizationQueryKeysEnum } from '..';

const searchUsers = async (_, params: DefaultApiPageUsersRequest) => {
  const { data } = await API.group.pageUsers(params, {
    ...endpointsConfig.pageUsers
  });
  return data;
};

export default function useUsersListGroupQuery(params: DefaultApiPageUsersRequest, queryConfig = {}) {
  return usePaginatedQuery([OrganizationQueryKeysEnum.ORGANIZATION_UNIT_USER_LIST, params], searchUsers, queryConfig);
}
