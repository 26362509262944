import React, { useEffect, useMemo } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { omit } from 'lodash';

import { FormV2Context, useCustomYupResolver, useTableAdapter, useTableContext } from '@libs/common/v2';

interface IProps {
  children?: React.ReactNode;
  editFormYupResolverSchema?: Resolver<Record<string, any>, Record<string, any>>;
}

/* eslint-disable consistent-return */
const resetFormValues = (page, data) => {
  if (page?.length) {
    const formValues = page?.reduce((acc, item: any, index) => {
      return { ...acc, [item.original.id ?? index]: omit(item.original, 'cell') };
    }, {});
    return formValues;
  }
  if (data?.content?.length || data?.length) {
    const dataToMap = data?.content?.length ? data?.content : data;
    const formValues = dataToMap?.reduce((acc, item, index) => {
      return { ...acc, [item.id ?? index]: item };
    }, {});
    return formValues;
  }
};

function MutableTableProvider({ children, editFormYupResolverSchema }: IProps) {
  const { state } = useTableAdapter();
  const { data, resolvedData } = useTableContext();
  const resolver = useCustomYupResolver(editFormYupResolverSchema ?? {}, state.rowId);

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    register,
    trigger,
    unregister,
    reset,
    watch,
    formState: { errors, isSubmitting, isDirty, submitCount },
    setError
  } = useForm<Record<string, any>>({
    resolver,
    mode: 'onBlur',
    criteriaMode: 'all'
  });

  useEffect(() => {
    if (!state.rowId) {
      const formValues = resetFormValues(state.page, data ?? resolvedData);
      reset(formValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, state.rowId, JSON.stringify(resolvedData), state.page]);

  useEffect(() => {
    if (state.rowId) {
      const formValues = resetFormValues(state.page, data ?? resolvedData);
      const currentMutatedRowData = getValues(state.rowId as any) ?? {};
      reset({ ...omit(formValues, 'currentMutatedRowData.id'), [currentMutatedRowData?.id]: currentMutatedRowData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, state.rowId, JSON.stringify(resolvedData), state.page]);

  const objectErrors = useMemo(() => Object.values(errors), [errors]);

  const stringifiedErrors = JSON.stringify(errors);

  const values = useMemo(
    () => ({
      control,
      setValue,
      register,
      unregister,
      watch,
      trigger,
      errors,
      isDirty,
      isSubmitting,
      submitCount,
      getValues,
      handleSubmit,
      reset,
      setError
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      control,
      objectErrors,
      getValues,
      handleSubmit,
      isDirty,
      isSubmitting,
      register,
      reset,
      setError,
      setValue,
      submitCount,
      trigger,
      unregister,
      watch,
      stringifiedErrors
    ]
  );

  return <FormV2Context.Provider value={values}>{children}</FormV2Context.Provider>;
}

export default MutableTableProvider;
