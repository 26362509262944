import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceLiteAllOf, VersionedSnapshot } from '@ibtm/domain';

import { useRouter, WarningInformation } from '@libs/common/v2';

import { CorrectionLink } from './CorrectionLink';

export const WarningCorrectionAvailable = ({
  id,
  corrections,
  folderId
}: {
  id: string;
  corrections: (InvoiceLiteAllOf &
    VersionedSnapshot & {
      statusKey: string;
    })[];
  folderId?: string;
}) => {
  const [t] = useTranslation();
  const { routes, goToPage } = useRouter();
  const isOnSuspension = !!folderId;

  const multipleCorrectionsLinks = useMemo(() => {
    return corrections.map(correction => {
      return (
        <CorrectionLink
          key={`${correction.id}-link`}
          id={id}
          folderId={folderId}
          correctionId={correction.id}
          correctionNumber={correction.number}
        />
      );
    });
  }, [corrections, folderId, id]);

  return corrections.length === 1 ? (
    <WarningInformation>
      {t('releaseDocuments:warning.message')}
      <a
        href={
          isOnSuspension
            ? routes.releaseDocumentOnFolderSuspensionView(folderId, id, corrections?.[0]?.id)
            : routes.releaseDocumentView(corrections?.[0]?.id)
        }
        onClick={e => {
          e.preventDefault();
          goToPage(
            isOnSuspension
              ? routes.releaseDocumentOnFolderSuspensionView(folderId, id, corrections?.[0]?.id)
              : routes.releaseDocumentView(corrections?.[0]?.id)
          );
        }}
      >
        {t('releaseDocuments:warning.link')}
      </a>
    </WarningInformation>
  ) : (
    <WarningInformation paperClassName="gap-1">
      {t('releaseDocuments:warning.messageMultiple')}
      {multipleCorrectionsLinks.map((LinkComponent, index) => (
        <>
          {LinkComponent} {index !== multipleCorrectionsLinks.length - 1 ? ',' : ''}
        </>
      ))}
    </WarningInformation>
  );
};
