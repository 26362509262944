export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  updateExploitationBase: { translationKey: 'exploitationBase:api.updateExploitationBase' },
  createExploitationBase: { translationKey: 'exploitationBase:api.createExploitationBase' },
  deleteExploitationBase: { translationKey: 'exploitationBase:api.deleteExploitationBase' },
  expireExploitationBase: { translationKey: 'exploitationBase:api.expireExploitationBase' },
  restoreExploitationBase: { translationKey: 'exploitationBase:api.restoreExploitationBase' },
  getExploitationBaseDetails: { translationKey: 'exploitationBase:api.getExploitationBaseDetails' },
  getExploitationBasesSnapshotPage: { translationKey: 'exploitationBase:api.getExploitationBasesSnapshotPage' },
  linkExploitationBases: { translationKey: 'applications:api.linkExploitationBases' },
  containsBasesWithNegativeInspections: { translationKey: 'folder:api.containsBasesWithNegativeInspections' }
};
