import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Theme } from '@libs/common/v2/theme';

import { useTableAdapter, useTableContext } from '../hooks';
import { AdditionalFilterEnum, IAdditionalFilter } from '../model';
import { renderFilters } from '../utils';

interface IProps {
  globalFilters?: IAdditionalFilter[];
}

function TableGlobalFilters({ globalFilters }: IProps) {
  const table = useTableAdapter();
  const { checkDisabled, isError } = useTableContext();
  const classes = useStyles();

  return (
    <div className={clsx(classes.wrapper, 'flex', 'items-center')}>
      {globalFilters?.map(filter =>
        filter?.type === AdditionalFilterEnum.CUSTOM_FILTER
          ? filter?.customFilter
          : renderFilters({
              ...filter,
              inputProps: {
                ...filter.inputProps,
                isDisabled: filter.inputProps?.isDisabled || checkDisabled(table) || isError
              }
            })
      )}
    </div>
  );
}

const useStyles = makeStyles<Theme>(() => ({
  wrapper: {
    maxHeight: '40px'
  }
}));

export default TableGlobalFilters;
