import React from 'react';
import { ThemeProvider } from '@enigma/fe-ui';
import { CssBaseline, StyledEngineProvider, Theme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { createGenerateClassName, jssPreset, StylesProvider } from '@mui/styles';
import { create } from 'jss';
import jssExtend from 'jss-extend';
import { merge } from 'lodash';

import { ThemeContext, useThemeContext } from '@libs/common/v2/theme';

import { ContrastTheme } from './themes/contrast-theme';
import { GITDTheme } from './themes/gitd-theme';
import { ThemeValuesProvider } from './ThemeValuesContext';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const primaryPalette = {
  '25': { normalColor: '#F2FFF8' },
  '50': { normalColor: '#E6F6ED' },
  '100': { normalColor: '#C2E8D3' },
  '200': { normalColor: '#9BD9B7' },
  '300': { normalColor: '#70CB9B' },
  '400': { normalColor: '#4DBF86' },
  '500': { normalColor: '#20B471' },
  '600': { normalColor: '#18A567' },
  '700': { normalColor: '#0E925A' },
  '800': { normalColor: '#07814E' },
  '850': { normalColor: '#19653E' },
  '900': { normalColor: '#006139' },
  '950': { normalColor: '#165937', contrastColor: '#e9e114' },
  '999': { normalColor: '#10412D', contrastColor: '#beb924' }
};

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()],
  insertionPoint: document.getElementById('jss-insertion-point')
});

const generateClassName = createGenerateClassName();

function GlobalThemeProvider({ children }) {
  const { contextValue, globalTheme } = useThemeContext(primaryPalette);

  const themeWithContrast = merge({}, GITDTheme, ContrastTheme);

  return (
    <StyledEngineProvider injectFirst>
      <StylesProvider jss={jss} generateClassName={generateClassName}>
        <ThemeValuesProvider value={globalTheme}>
          <ThemeContext.Provider value={contextValue}>
            <MuiThemeProvider theme={globalTheme}>
              <ThemeProvider
                theme={{
                  ...(contextValue.contrast ? themeWithContrast : GITDTheme),
                  typography: {
                    allVariants: {
                      fontFamily: 'Inter'
                    },
                    fontSize: 14,
                    htmlFontSize: 10
                  }
                }}
              >
                <CssBaseline />
                {children}
              </ThemeProvider>
            </MuiThemeProvider>
          </ThemeContext.Provider>
        </ThemeValuesProvider>
      </StylesProvider>
    </StyledEngineProvider>
  );
}

export default GlobalThemeProvider;
