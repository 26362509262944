import { ParameterExtras } from '@stack/report';

import { ReportTypeExtrasColumns } from '@libs/report/components/report-type-form';

function useReportTypesExtrasTable(parameterExtras: Array<ParameterExtras>): any {
  const columns = ReportTypeExtrasColumns();

  return {
    tableQuery: parameterExtras || [],
    tableHookOptions: {
      columns,
      tableOptions: {
        initialState: {
          sortBy: [{ id: 'index', desc: true }]
        },
        manualPagination: false,
        manualFilters: false,
        manualSortBy: false
      }
    },
    tableHookQueryInitialParams: {
      includeQuicklyChangeable: true
    }
  };
}

export default useReportTypesExtrasTable;
