import { FinancialSecuritySummaryUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export const updateApplicationFinancialSecuritySummary = ({
  applicationId,
  formData
}: {
  applicationId: string;
  formData: FinancialSecuritySummaryUpdateRequest;
}) => {
  return API.financialSecurity.updateFinancialSecuritySummary(applicationId, formData, {
    ...endpointsConfig.updateFinancialSecuritySummary
  });
};

function useUpdateApplicationFinancialSecuritySummaryMutation() {
  return useMutation(updateApplicationFinancialSecuritySummary);
}

export default useUpdateApplicationFinancialSecuritySummaryMutation;
