import { ReactElement, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useAppConfig } from '@libs/app-config';

import { useDocumentTitle, useRouter } from '../../hooks';

import { SidebarView } from './view-types';

export interface LoginPageValues {
  username: string;
  password: string;
}

interface IProps {
  isLoading?: boolean;
  handleSubmit: (values) => void;
  applicationWelcomeText: string;
  applicationWelcomeTextSecond: string;
  applicationDescription: string;
  backgroundImageUrl?: string;
  logoImageUrl: string;
  customInitialValuesTest?: LoginPageValues;
  setRecaptchaField?: (ref: ReCAPTCHA) => void;
  isLoginFailed?: boolean;
  showForgotPasswordButton?: boolean;
  showWebContentAccessibilityGuidelines?: boolean;
  customForgotPasswordLabel?: string;
  logo?: ReactElement<React.FunctionComponent<React.SVGProps<SVGSVGElement>>>;
  additionalButton?: ReactElement;
  isLoggedIn: boolean;
}

export default function LoginPage({
  isLoading,
  handleSubmit,
  applicationWelcomeText,
  applicationDescription,
  backgroundImageUrl,
  logoImageUrl,
  logo,
  applicationWelcomeTextSecond,
  customInitialValuesTest,
  showForgotPasswordButton,
  showWebContentAccessibilityGuidelines,
  customForgotPasswordLabel,
  setRecaptchaField,
  isLoginFailed,
  additionalButton,
  isLoggedIn
}: IProps) {
  const [t] = useTranslation();

  const { setTitle } = useDocumentTitle();
  const { mainPage } = useAppConfig();

  const { goToPage } = useRouter();

  useEffect(() => {
    setTitle(t('login:form.signIn'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      goToPage(
        { pathname: mainPage || '/' },
        {
          state: { from: mainPage || '/' }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, mainPage]);

  return (
    <SidebarView
      logoImageUrl={logoImageUrl}
      applicationWelcomeText={applicationWelcomeText}
      applicationWelcomeTextSecond={applicationWelcomeTextSecond}
      applicationDescription={applicationDescription}
      customInitialValuesTest={customInitialValuesTest}
      isLoading={isLoading}
      showForgotPasswordButton={showForgotPasswordButton}
      showWebContentAccessibilityGuidelines={showWebContentAccessibilityGuidelines}
      backgroundImageUrl={backgroundImageUrl}
      handleSubmit={handleSubmit}
      customForgotPasswordLabel={customForgotPasswordLabel}
      setRecaptchaField={setRecaptchaField}
      isLoginFailed={isLoginFailed}
      logo={logo}
      isLoggedIn={isLoggedIn}
      additionalButton={additionalButton}
    />
  );
}
