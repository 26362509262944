import { useTranslation } from 'react-i18next';
import { DocumentSearchFilter } from '@ibtm/domain';

import { SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { convertDateToDateTimeFormat, DateRangeISO, getCalendarDate, getValue } from '@libs/common/v2/utils';

import { UserFilterV2 } from '@libs/user';

import { DocumentsDictionaryNameEnum, DocumentsSnapshotClient } from '../../model';

const useDocumentsColumns = () => {
  const [t] = useTranslation();

  const { createColumns } = useCreateColumns<DocumentsSnapshotClient>({
    pathToTranslate: 'document:fields'
  });

  const mappedSortFields: TableSortMapper<DocumentsSnapshotClient> = {
    generatedDocumentName: 'file.name',
    generatedBy: 'author.name',
    generatedDate: 'created',
    documentName: 'uploadedFileName',
    addedBy: 'modifier.name',
    passedToSentDate: 'parcel.passedToSentDate',
    packageNumber: 'parcel.number',
    sendDate: 'parcel.sendDate',
    receiptAckDate: 'parcel.receiptAckDate'
  };

  const mappedFilterFields: TableFilterMapper<DocumentsSnapshotClient, DocumentSearchFilter> = {
    generatedDocumentName: (generatedDocumentName: string) => ({ fileNameContains: generatedDocumentName }),
    generatedBy: (generators: SelectOption[]) => ({ authorIdIn: generators.map(({ id }) => id) }),
    generatedDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      createdGreaterThanOrEqual: dateFrom,
      createdLessThanOrEqual: dateTo
    }),
    documentName: (documentName: string) => ({ uploadedFileNameContains: documentName }),
    addedBy: (addedBy: SelectOption[]) => ({ modifierIdIn: addedBy.map(({ id }) => id) }),
    statusKey: (statuses: SelectOption<string>[]) => ({ documentStatusKeyIn: statuses.map(status => status.value) }),
    passedToSentDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      passedToSentDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      passedToSentDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    packageNumber: (packageNumber: string) => ({ parcelNumberContains: packageNumber }),
    sendDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      sendDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      sendDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    receiptAckDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      receiptAckDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      receiptAckDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    cancellationDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      cancellationDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      cancellationDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    cancelledBy: (cancelledBy: SelectOption[]) => ({ cancellationByIdIn: cancelledBy.map(({ id }) => id) })
  };

  const columns = createColumns([
    {
      id: 'documentName',
      accessor: 'uploadedFileName',
      type: 'TEXT',
      width: 300
    },
    {
      id: 'generatedBy',
      type: 'CUSTOM_COLUMN',
      filter: props => <UserFilterV2 isMultiple {...props} />
    },
    {
      id: 'generatedDate',
      customAccessor: row => getValue(convertDateToDateTimeFormat(row.generatedDate)),
      type: 'DATE',
      width: 280
    },
    {
      id: 'generatedDocumentName',
      accessor: 'generatedFileName',
      type: 'TEXT',
      width: 300
    },
    {
      id: 'addedBy',
      type: 'CUSTOM_COLUMN',
      filter: props => <UserFilterV2 isMultiple {...props} />,
      customAccessor: row => getValue(row.addedBy)
    },
    {
      id: 'inputDate',
      customAccessor: row => getValue(convertDateToDateTimeFormat(row.inputDate)),
      type: 'DATE',
      width: 280
    },
    {
      id: 'statusKey',
      header: 'status',
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DocumentsDictionaryNameEnum.DOCUMENT_STATUS
    },
    {
      id: 'passedToSentDate',
      type: 'DATE',
      width: 280
    },
    {
      id: 'packageNumber',
      type: 'TEXT',
      accessor: 'parcel.number'
    },
    {
      id: 'sendDate',
      type: 'DATE',
      width: 280
    },
    {
      id: 'receiptAckDate',
      type: 'DATE',
      width: 280
    },
    {
      id: 'cancellationDate',
      type: 'DATE',
      width: 280
    },
    {
      id: 'cancelledBy',
      type: 'CUSTOM_COLUMN',
      filter: props => <UserFilterV2 isMultiple {...props} />,
      customAccessor: row => getValue(row.cancelledBy)
    },
    {
      id: 'isFinalDecision',
      type: 'BOOLEAN',
      tooltipTitle: row => (row?.isFinalDecision ? t('action.yes') : t('action.no')),
      isFilterable: false
    }
  ]);

  return { columns, mappedFilterFields, mappedSortFields };
};

export default useDocumentsColumns;
