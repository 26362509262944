import { ForeignPermissionSearchFilter } from '@ibtm/domain';

import { AutocompleteLazyFetchSelectField, typedNameV2, useFormV2Watch } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { DomainDictionaryEntry } from '@libs/domain/config';

import { type ForeignPermissionApplicationRecordFormData } from '../utils/foreignPermissionApplicationUtils';

export const ConcernPermissionSelectField = ({
  inputName,
  folderId,
  isRequired,
  label
}: {
  inputName: string;
  folderId: string;
  isRequired?: boolean;
  label?: string;
}) => {
  const resourceType = useFormV2Watch({
    name: typedNameV2<ForeignPermissionApplicationRecordFormData>('resourceType')
  }) as ForeignPermissionApplicationRecordFormData['resourceType'];

  const selectedResourceTypeCategoryKey = resourceType?.ekmtCategoryKey;
  const selectedResourceYear = resourceType?.year;

  const { EKMT_CATEGORY, RESOURCE_FORM_STATE, FOREIGN_PERMIT_TYPE } = DomainDictionaryEntry;

  const filterParamsConfig: { [key: string]: ForeignPermissionSearchFilter } = {
    [DomainDictionaryEntry.EKMT_CATEGORY.ANNUAL_PROVISION]: {
      folderIdIn: [folderId],
      formStateKeyIn: [RESOURCE_FORM_STATE.RETURNED]
    },
    [EKMT_CATEGORY.ANNUAL_PROVISION_BASIC]: {
      ekmtInDepot: true
    }
  };
  return (
    <AutocompleteLazyFetchSelectField
      name={inputName}
      label={label}
      isRequired={isRequired}
      isDisabled={!selectedResourceTypeCategoryKey}
      api={{
        FETCH: async (searchText, params) => {
          return API.foreignPermissions.getForeignPermissionSnapshotPage({
            formNumberContains: searchText,
            typeKeyIn: [FOREIGN_PERMIT_TYPE.EKMT],
            yearEquals: selectedResourceYear,
            ...filterParamsConfig[selectedResourceTypeCategoryKey],
            ...params
          });
        }
      }}
      queryKey={`concernForeignPermissions-${folderId}-${selectedResourceTypeCategoryKey ?? ''}${selectedResourceYear}`}
      optionLabelParser={data => {
        return String(data.form?.number);
      }}
      hasErrorTooltip
    />
  );
};
