import { FolderLimitClientSearchFilter } from '@ibtm/client-domain';
import { AnyFolderLimitSearchFilter, FolderLimitSearchFilter, FolderLiteSearchFilter } from '@ibtm/domain';
import { omit } from 'lodash';

type IDisposablePermitsQueryParams = AnyFolderLimitSearchFilter & FolderLiteSearchFilter;

export const parseDisposablePermitsPayload = (queryParams: IDisposablePermitsQueryParams): FolderLiteSearchFilter => {
  // const { typeKey, yearEquals } = queryParams;
  const params = omit(queryParams, [
    'typeKey',
    'yearEquals',
    'typeKeyIn',
    'createdLessThanOrEqual',
    'createdGreaterThanOrEqual'
  ]);

  return {
    ...params
    // TODO GITDDEV-20830 - Hotfix do naprawy po ustaleniu w jaki sposób ma działać filtrowanie
    // anyLimit: {
    //   typeKey,
    //   yearEquals
    // }
  };
};

type ISingleAuthorizationLimitsQueryParams = {
  folderId?: string;
  maxYear: string;
};

export const parseSingleAuthorizationLimitsPayload = (
  queryParams: ISingleAuthorizationLimitsQueryParams
): FolderLimitSearchFilter => {
  const { folderId, maxYear } = queryParams;
  const params = omit(queryParams, ['folderId', 'maxYear']);

  return {
    ...params,
    folderId,
    ...(maxYear && { yearEquals: parseInt(maxYear, 10) })
  };
};

export const parseSingleAuthorizationLimitsClientPayload = (
  queryParams: ISingleAuthorizationLimitsQueryParams
): FolderLimitClientSearchFilter => {
  const { maxYear } = queryParams;
  const params = omit(queryParams, ['folderId', 'maxYear']);

  return {
    ...params,
    ...(maxYear && { yearLessThanOrEqual: parseInt(maxYear, 10), yearGreaterThanOrEqual: parseInt(maxYear, 10) })
  };
};
