import { ReactNode, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PluginHook, Row } from 'react-table';
import { FolderDetails, ProceedingAvailableFormsDetails } from '@ibtm/domain';
import { isEmpty } from 'lodash';

import {
  AutocompleteSelectField,
  CamelCasePath,
  GridLayout,
  InputMode,
  Section,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';
import { WarningInformation } from '@libs/common/v2/components/warning';

import { useElementVisibility } from '@libs/permission';

import { PermissionTableEnum as DomainPermissionTableEnum } from '@libs/domain/application';
import { DomainDictionaryEntry, DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import {
  DriverCertificatesColumnsEnum,
  DriverCertificateSnapshotClient,
  DriverCertificatesTable,
  DriverCertificateStatusEnum
} from '@libs/domain/driver-certificate';
import { useVisiblePermissionTypes } from '@libs/domain/folder';
import { ForeignPermitsTable, ReleasedPermitsSnapshot } from '@libs/domain/foreign-permission';
import {
  LicenseCopiesColumnsEnum,
  LicenseCopiesTable,
  LicenseCopySnapshotClient,
  LicensesColumnsEnum,
  LicenseSnapshotClient,
  LicensesTable,
  useLicensesQuery
} from '@libs/domain/license';
import {
  PermissionActions,
  PermissionCopiesColumnsEnum,
  PermissionCopiesTable,
  PermissionCopySnapshotClient,
  PermissionsColumnsEnum,
  PermissionSnapshotClient,
  PermissionsTable,
  PermissionTypeEnum
} from '@libs/domain/permission';

import { GoToProceedingButton } from '../../../../../drivers/components';

import AvailableFormsTable from './AvailableFormsTable';

// Sprawdzamy czy teczka posiada aktywną licencje, więcy wystarczy nam 1
const licenseCount = 1;

export enum PermissionTableEnum {
  LISENSE = 'LISENSE',
  GITD = 'GITD',
  COUNTRY_PERMISSION = 'COUNTRY_PERMISSION',
  LICENSE_COPY = 'LICENSE_COPY',
  ROAD_CARRIER = 'ROAD_CARRIER',
  DRIVER_CERTIFICATE = 'DRIVER_CERTIFICATE',
  SPO_UE = 'SPO_UE',
  SPO_UE_COPY = 'SPO_UE_COPY',
  SPO_OUTSIDE_UE = 'SPO_OUTSIDE_UE',
  SPO_OUTSIDE_UE_COPY = 'SPO_OUTSIDE_UE_COPY',
  SPO_SHUTTLE = 'SPO_SHUTTLE',
  SPO_SHUTTLE_COPY = 'SPO_SHUTTLE_COPY',
  SPO_OCCASIONAL = 'SPO_OCCASIONAL',
  SPO_OCCASIONAL_COPY = 'SPO_OCCASIONAL_COPY',
  SPO_TRANSIT = 'SPO_TRANSIT',
  SPO_TRANSIT_COPY = 'SPO_TRANSIT_COPY',
  OWN_NEEDS = 'OWN_NEEDS',
  OWN_NEEDS_COPY = 'OWN_NEEDS_COPY',
  FOREIGN_PERMISSION = 'FOREIGN_PERMISSION',
  FOREIGN_AUTHORITY_PERMISSION = 'FOREIGN_AUTHORITY_PERMISSION'
}

interface IProps {
  folderId: string;
  rowActions?: (row: Row<LicenseSnapshotClient>) => ReactNode;
  proceedingRowActions?: (row: Row<ProceedingAvailableFormsDetails>) => ReactNode;
  proceedingTablePlugins?: PluginHook<ProceedingAvailableFormsDetails>[];
  rowActionRenderer?: (
    row: Row<
      | PermissionSnapshotClient
      | LicenseCopySnapshotClient
      | PermissionCopySnapshotClient
      | DriverCertificateSnapshotClient
      | ReleasedPermitsSnapshot
    >
  ) => ReactNode;
  actionsColumnWidth?: number;
  hasInitialValue?: boolean;
  folderType?: string;
  isSingleTable?: boolean;
  omitForeignPermissionAndFromExternal?: boolean;
  isDialog?: boolean;
  includePartnershipPermissions?: boolean;
}
type KeyFieldValue = string | { value: string };

function PermissionsTab({
  actionsColumnWidth,
  folderId,
  rowActions,
  proceedingRowActions,
  proceedingTablePlugins,
  rowActionRenderer,
  hasInitialValue = false,
  folderType,
  isSingleTable,
  omitForeignPermissionAndFromExternal,
  isDialog,
  includePartnershipPermissions
}: IProps) {
  const [t] = useTranslation();

  const { setValue, watch, resetField } = useFormV2Context();
  const folderTypeKeyWatch = watch(typedNameV2<FolderDetails>('typeKey')) as KeyFieldValue;
  const folderTypeKey = typeof folderTypeKeyWatch === 'string' ? folderTypeKeyWatch : folderTypeKeyWatch?.value;

  const visiblePermissionTypes = useVisiblePermissionTypes(folderType);
  const { isOperatorPortal } = useDomainConfigContext();
  const { checkIsElementVisible } = useElementVisibility();
  const formTypeName = 'form.type';
  const formType = watch(formTypeName) as { value: string };

  useEffect(() => {
    resetField(formTypeName, { keepDirty: false, defaultValue: formType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formType]);

  const isPassToAuthorityAllowed = useMemo(
    () => checkIsElementVisible(DomainUIElementEnum.FOLDER_PERMISSION_FORWARD_TO_STAROSTY_BUTTON),
    [checkIsElementVisible]
  );
  const isBackToGitdAllowed = useMemo(
    () => checkIsElementVisible(DomainUIElementEnum.FOLDER_PERMISSION_BACK_TO_GITD_BUTTON),
    [checkIsElementVisible]
  );

  const isDetailsAllowed = useMemo(
    () => checkIsElementVisible(DomainUIElementEnum.FOLDER_PERMISSION_VIEW_BUTTON),
    [checkIsElementVisible]
  );
  const isDeleteAllowed = useMemo(
    () => checkIsElementVisible(DomainUIElementEnum.FOLDER_PERMISSION_DELETE_BUTTON),
    [checkIsElementVisible]
  );
  const isEditAllowed = useMemo(
    () => checkIsElementVisible(DomainUIElementEnum.FOLDER_PERMISSION_EDIT_BUTTON),
    [checkIsElementVisible]
  );

  useEffect(() => {
    if (resetField) {
      resetField('form.type', { keepDirty: false, defaultValue: formType });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formType]);

  const isFolderTypeKeyForActiveLicense = useMemo(() => {
    return (
      folderTypeKey === DomainDictionaryEntry.FOLDER_TYPE.OP ||
      folderTypeKey === DomainDictionaryEntry.FOLDER_TYPE.PPO ||
      folderTypeKey === DomainDictionaryEntry.FOLDER_TYPE.TP
    );
  }, [folderTypeKey]);

  const folderIdParam = isOperatorPortal ? { folderIdIn: [folderId] } : { folderIdEq: folderId };

  const { data: licenseData } = useLicensesQuery({
    ...folderIdParam,
    statusKeyIn: [DomainDictionaryEntry.PERMISSION_STATUS.ACTIVE, DomainDictionaryEntry.PERMISSION_STATUS.SUSPEND],
    size: licenseCount,
    includePartnershipPermissions: true
  });

  const hasActiveLicense = licenseData?.content?.length > 0;

  const gitdRowActions = isOperatorPortal
    ? ([
        isPassToAuthorityAllowed ? 'passToDistrictAuthority' : undefined,
        isBackToGitdAllowed ? 'restoreFromDistrictAuthority' : undefined
      ].filter(Boolean) as Array<PermissionActions>)
    : ([] as Array<PermissionActions>);

  const countryPermissionsDetailsButton = isOperatorPortal
    ? ([
        isDeleteAllowed ? 'delete' : undefined,
        isEditAllowed ? 'edit' : undefined,
        isDetailsAllowed ? 'details' : undefined
      ].filter(Boolean) as Array<PermissionActions>)
    : ([] as Array<PermissionActions>);

  const permissionVisibleColumns: Array<CamelCasePath<PermissionSnapshotClient>> = useMemo(
    () => [
      PermissionsColumnsEnum.APPLICATION_NUMBER,
      PermissionsColumnsEnum.PERMISSION_NUMBER,
      PermissionsColumnsEnum.FORM_NUMBER,
      PermissionsColumnsEnum.DATE_OF_ISSUE,
      PermissionsColumnsEnum.VALID_FROM,
      PermissionsColumnsEnum.VALID_TO,
      ...(isOperatorPortal ? [PermissionsColumnsEnum.SIGNER_NAME] : []),
      PermissionsColumnsEnum.STATUS,
      PermissionsColumnsEnum.TRANSIT_STOP_END_TOUR,
      PermissionsColumnsEnum.TRANSIT_STOP_START_TOUR
    ],
    [isOperatorPortal]
  );

  const foreignPermissionVisibleColumns = useMemo(
    (): Array<CamelCasePath<ReleasedPermitsSnapshot>> => [
      'applicationNumber',
      'formNumber',
      'dateOfIssue',
      'validDateRangeFrom',
      'validDateRangeTo',
      'issuingCountryCodeKey',
      'formStateKey'
    ],
    []
  );

  const permissionCopiesVisibleColumns = useMemo(
    (): Array<CamelCasePath<PermissionCopySnapshotClient>> => [
      PermissionCopiesColumnsEnum.APPLICATION_NUMBER,
      PermissionCopiesColumnsEnum.PERMISSION_NUMBER,
      PermissionCopiesColumnsEnum.PERMISSION_COPY_NUMBER,
      PermissionCopiesColumnsEnum.FORM_NUMBER,
      PermissionCopiesColumnsEnum.SUBJECT_NAME,
      PermissionCopiesColumnsEnum.SUBJECT_ADDRESS,
      PermissionCopiesColumnsEnum.DATE_OF_ISSUE,
      PermissionCopiesColumnsEnum.VALID_FROM,
      PermissionCopiesColumnsEnum.VALID_TO,
      ...(isOperatorPortal ? [PermissionCopiesColumnsEnum.SIGNER_NAME] : []),
      PermissionCopiesColumnsEnum.CANCELLATION_REASON,
      PermissionCopiesColumnsEnum.CANCELLED_BY,
      PermissionCopiesColumnsEnum.CANCELLATION_DATE,
      PermissionCopiesColumnsEnum.STATUS
    ],
    [isOperatorPortal]
  );

  const licenseTableColumns = useMemo(() => {
    return [
      LicensesColumnsEnum.APPLICATION_NUMBER,
      LicensesColumnsEnum.CANCELLATION_REASON,
      LicensesColumnsEnum.CANCELLED_BY,
      LicensesColumnsEnum.CANCELLATION_DATE,
      LicensesColumnsEnum.SUBJECT_NAME,
      LicensesColumnsEnum.SUBJECT_ADDRESS,
      LicensesColumnsEnum.LICENSE_NUMBER,
      LicensesColumnsEnum.FORM_NUMBER,
      LicensesColumnsEnum.PRINT_DATE,
      LicensesColumnsEnum.VALID_FROM,
      LicensesColumnsEnum.VALID_TO,
      LicensesColumnsEnum.DATE_OF_ISSUE_EXTERNAL,
      isOperatorPortal && LicensesColumnsEnum.SIGNER_NAME,
      LicensesColumnsEnum.STATUS
    ];
  }, [isOperatorPortal]);

  const gitdPermissionTableColumns: Array<CamelCasePath<PermissionSnapshotClient>> = useMemo(
    () => [
      PermissionsColumnsEnum.APPLICATION_NUMBER,
      PermissionsColumnsEnum.CANCELLATION_REASON,
      PermissionsColumnsEnum.CANCELLED_BY,
      PermissionsColumnsEnum.CANCELLATION_DATE,
      PermissionsColumnsEnum.SUBJECT_NAME,
      PermissionsColumnsEnum.SUBJECT_ADDRESS,
      PermissionsColumnsEnum.PERMISSION_NUMBER,
      PermissionsColumnsEnum.VALID_FROM,
      PermissionsColumnsEnum.FORM_NUMBER,
      PermissionsColumnsEnum.DATE_OF_ISSUE_EXTERNAL,
      ...(isOperatorPortal ? [PermissionsColumnsEnum.SIGNER_NAME] : []),
      PermissionsColumnsEnum.STATUS
    ],
    [isOperatorPortal]
  );

  const countryPermissionTableColumns: Array<CamelCasePath<PermissionSnapshotClient>> = useMemo(
    () => [
      PermissionsColumnsEnum.PERMISSION_NUMBER,
      PermissionsColumnsEnum.DATE_OF_ISSUE,
      PermissionsColumnsEnum.VALID_TO,
      PermissionsColumnsEnum.SUBJECT_ADDRESS,
      PermissionsColumnsEnum.ISSUING_AUTHORITY,
      PermissionsColumnsEnum.SUBJECT_NIP,
      PermissionsColumnsEnum.SUBJECT_NAME,
      PermissionsColumnsEnum.STATUS
    ],
    []
  );

  const licenseCopyTableColumns = useMemo(
    () => [
      LicenseCopiesColumnsEnum.APPLICATION_NUMBER,
      LicenseCopiesColumnsEnum.CANCELLATION_REASON,
      LicenseCopiesColumnsEnum.CANCELLED_BY,
      LicenseCopiesColumnsEnum.CANCELLATION_DATE,
      LicenseCopiesColumnsEnum.SUBJECT_NAME,
      LicenseCopiesColumnsEnum.SUBJECT_ADDRESS,
      LicenseCopiesColumnsEnum.LICENSE_NUMBER,
      LicenseCopiesColumnsEnum.FORM_NUMBER,
      LicenseCopiesColumnsEnum.PRINT_DATE,
      LicenseCopiesColumnsEnum.VALID_FROM,
      LicenseCopiesColumnsEnum.VALID_TO,
      LicenseCopiesColumnsEnum.DATE_OF_ISSUE_EXTERNAL,
      isOperatorPortal && LicenseCopiesColumnsEnum.SIGNER_NAME,
      LicenseCopiesColumnsEnum.BELOW_THREE_AND_HALF_TONS,
      LicenseCopiesColumnsEnum.STATUS
    ],
    [isOperatorPortal]
  );

  const roadCarrierTableColumns = useMemo(
    (): Array<CamelCasePath<PermissionCopySnapshotClient>> => [
      PermissionCopiesColumnsEnum.APPLICATION_NUMBER,
      PermissionCopiesColumnsEnum.CANCELLATION_REASON,
      PermissionCopiesColumnsEnum.CANCELLED_BY,
      PermissionCopiesColumnsEnum.CANCELLATION_DATE,
      PermissionCopiesColumnsEnum.SUBJECT_NAME,
      PermissionCopiesColumnsEnum.SUBJECT_ADDRESS,
      PermissionCopiesColumnsEnum.PERMISSION_NUMBER,
      PermissionCopiesColumnsEnum.PERMISSION_COPY_NUMBER,
      PermissionCopiesColumnsEnum.FORM_NUMBER,
      PermissionCopiesColumnsEnum.PRINT_DATE,
      PermissionCopiesColumnsEnum.DATE_OF_ISSUE_EXTERNAL,
      ...(isOperatorPortal ? [PermissionCopiesColumnsEnum.SIGNER_NAME] : []),
      PermissionCopiesColumnsEnum.STATUS
    ],
    [isOperatorPortal]
  );

  const driverCertificateTableColumns: Array<CamelCasePath<DriverCertificateSnapshotClient>> = useMemo(
    () => [
      DriverCertificatesColumnsEnum.APPLICATION_NUMBER,
      DriverCertificatesColumnsEnum.CANCELLATION_REASON,
      DriverCertificatesColumnsEnum.CANCELLED_BY,
      DriverCertificatesColumnsEnum.CANCELLATION_DATE,
      DriverCertificatesColumnsEnum.SUBJECT_NAME,
      DriverCertificatesColumnsEnum.SUBJECT_ADDRESS,
      DriverCertificatesColumnsEnum.DRIVER_CERTIFICATE_NUMBER,
      DriverCertificatesColumnsEnum.FORM_NUMBER,
      DriverCertificatesColumnsEnum.DRIVER_NAME,
      DriverCertificatesColumnsEnum.DRIVER_SURNAME,
      DriverCertificatesColumnsEnum.DRIVER_ID,
      DriverCertificatesColumnsEnum.PRINT_DATE,
      DriverCertificatesColumnsEnum.VALID_FROM,
      DriverCertificatesColumnsEnum.VALID_TO,
      DriverCertificatesColumnsEnum.DATE_OF_ISSUE_EXTERNAL,
      isOperatorPortal && DriverCertificatesColumnsEnum.SIGNED_BY,
      DriverCertificatesColumnsEnum.STATUS
    ],
    [isOperatorPortal]
  );

  const foreignAuthorityPermissionTableColumns: Array<CamelCasePath<PermissionSnapshotClient>> = useMemo(
    () => [
      PermissionsColumnsEnum.PERMISSION_NUMBER,
      PermissionsColumnsEnum.DATE_OF_ISSUE,
      PermissionsColumnsEnum.VALID_TO,
      PermissionsColumnsEnum.ISSUING_COUNTRY,
      PermissionsColumnsEnum.SUBJECT_NAME,
      // TODO: dodać Nazwa uprawnienia, GITDDEV-10511
      PermissionsColumnsEnum.STATUS,
      PermissionsColumnsEnum.TRANSIT_STOP_END_TOUR,
      PermissionsColumnsEnum.TRANSIT_STOP_START_TOUR
    ],
    []
  );

  const permissionTables = useMemo(
    () => [
      {
        // Licencje
        name: PermissionTableEnum.LISENSE,
        content: (
          <LicensesTable
            folderId={folderId}
            key="licenses"
            rowActions={rowActions}
            actionsColumnWidth={actionsColumnWidth}
            visibleColumns={licenseTableColumns}
            includePartnershipPermissions
            defaultFilters={[
              {
                id: 'status',
                value: [
                  ...(checkIsElementVisible(DomainUIElementEnum.FOLDER_PERMISSION_LIST_ACTIVE)
                    ? [{ value: DomainDictionaryEntry.PERMISSION_STATUS.ACTIVE }]
                    : []),
                  { value: DomainDictionaryEntry.PERMISSION_STATUS.PENDING }
                ]
              }
            ]}
            hiddenStatusFilterKeys={[
              ...(checkIsElementVisible(DomainUIElementEnum.FOLDER_PERMISSION_LIST_ACTIVE)
                ? []
                : [DomainDictionaryEntry.PERMISSION_STATUS.ACTIVE]),
              ...(checkIsElementVisible(DomainUIElementEnum.FOLDER_PERMISSION_LIST_UNACTIVE)
                ? []
                : [DomainDictionaryEntry.PERMISSION_STATUS.INACTIVE])
            ]}
          />
        )
      },
      {
        // Zezwolenia GITD
        name: PermissionTableEnum.GITD,
        content: (
          <PermissionsTable
            titleKey="gitd"
            key="gitd"
            permissionType={DomainPermissionTableEnum.GITD}
            rowActions={rowActionRenderer}
            actionsColumnWidth={actionsColumnWidth}
            visibleColumns={gitdPermissionTableColumns}
            initialParams={{
              folderIdIn: [folderId],
              typeKeyIn: [
                PermissionTypeEnum.ROAD_CARRIER_GOODS_CARRIAGE,
                PermissionTypeEnum.ROAD_CARRIER_PASSENGER_CARRIAGE
              ],
              isIssuedByExternalAuthority: false,
              includePartnershipPermissions: true
            }}
            rowActionsEnabled={gitdRowActions}
            isWarningEnabled={false}
            validFromColumnHeader="printDate"
            isTableMutable
          />
        )
      },
      {
        // Zezwolenia / Licencje krajowe wydane przez organ zewnętrzny
        name: PermissionTableEnum.COUNTRY_PERMISSION,
        content: (
          <PermissionsTable
            titleKey="country-permissions"
            key="country-permissions"
            rowActions={rowActionRenderer}
            actionsColumnWidth={actionsColumnWidth}
            permissionType={DomainPermissionTableEnum.EXTERNAL_AUTHORITY_PERMISSION}
            visibleColumns={countryPermissionTableColumns}
            initialParams={{
              folderIdIn: [folderId],
              typeKeyIn: [
                PermissionTypeEnum.ROAD_CARRIER_GOODS_CARRIAGE,
                PermissionTypeEnum.ROAD_CARRIER_PASSENGER_CARRIAGE
              ],
              isIssuedByExternalAuthority: true,
              includePartnershipPermissions: true
            }}
            rowActionsEnabled={countryPermissionsDetailsButton}
            isWarningEnabled={false}
          />
        )
      },
      {
        // Wypisy z licencji
        name: PermissionTableEnum.LICENSE_COPY,
        content: (
          <LicenseCopiesTable
            titleKey="license-copies"
            key="license-copies"
            folderId={folderId}
            rowActions={rowActionRenderer}
            actionsColumnWidth={actionsColumnWidth}
            visibleColumns={licenseCopyTableColumns}
            includePartnershipPermissions
            defaultFilters={[
              {
                id: 'status',
                value: [
                  { value: DomainDictionaryEntry.PERMISSION_STATUS.ACTIVE },
                  { value: DomainDictionaryEntry.PERMISSION_STATUS.PENDING }
                ]
              }
            ]}
          />
        )
      },
      {
        // Wypisy z zezwolenia
        name: PermissionTableEnum.ROAD_CARRIER,
        content: (
          <PermissionCopiesTable
            titleKey="permissionGitdCopy"
            key="permission"
            rowActions={rowActionRenderer}
            actionsColumnWidth={actionsColumnWidth}
            folderId={folderId}
            typeKeys={[
              PermissionTypeEnum.ROAD_CARRIER_GOODS_CARRIAGE,
              PermissionTypeEnum.ROAD_CARRIER_PASSENGER_CARRIAGE
            ]}
            visibleColumns={roadCarrierTableColumns}
            includePartnershipPermissions
          />
        )
      },
      {
        // Świadectwa Kierowcy
        name: PermissionTableEnum.DRIVER_CERTIFICATE,
        content: (
          <DriverCertificatesTable
            key="driver-certificates"
            folderId={folderId}
            rowActions={row =>
              !isEmpty(row?.original?.pendingProceedings) && (
                <GoToProceedingButton
                  pendingProceedings={row?.original?.pendingProceedings}
                  actionKey={DomainUIElementEnum.FOLDER_DRIVERS_GO_TO_PROCEEDING}
                  folderId={folderId}
                  showAsIcon
                />
              )
            }
            actionsColumnWidth={actionsColumnWidth}
            visibleColumns={driverCertificateTableColumns}
            includePartnershipPermissions
            defaultFilters={[
              {
                id: DriverCertificatesColumnsEnum.STATUS,
                value: [{ value: DriverCertificateStatusEnum.ACTIVE }, { value: DriverCertificateStatusEnum.PENDING }]
              }
            ]}
          />
        )
      },
      {
        // Zezwolenia na przewozy regularne w ramach UE
        name: PermissionTableEnum.SPO_UE,
        content: (
          <PermissionsTable
            titleKey="spo-eu"
            key="spo-eu"
            permissionType={DomainPermissionTableEnum.SPO_UE}
            rowActions={rowActionRenderer}
            actionsColumnWidth={actionsColumnWidth}
            initialParams={{
              folderIdIn: [folderId],
              typeKeyIn: [PermissionTypeEnum.SPO_UE],
              includePartnershipPermissions: true
            }}
            visibleColumns={permissionVisibleColumns}
          />
        )
      },
      {
        // Wypisy z zezwolenia na przewozy regularne w ramach UE
        name: PermissionTableEnum.SPO_UE_COPY,
        content: (
          <PermissionCopiesTable
            key="spo-eu-copy"
            folderId={folderId}
            rowActions={rowActionRenderer}
            actionsColumnWidth={actionsColumnWidth}
            typeKeys={[PermissionTypeEnum.SPO_UE]}
            titleKey="spo-eu"
            visibleColumns={permissionCopiesVisibleColumns}
            includePartnershipPermissions
          />
        )
      },
      {
        // Zezwolenia na przewozy regularne poza EU
        name: PermissionTableEnum.SPO_OUTSIDE_UE,
        content: (
          <PermissionsTable
            titleKey="spo-outside-eu"
            key="spo-outside-eu"
            permissionType={DomainPermissionTableEnum.SPO_OUTSIDE_UE}
            rowActions={rowActionRenderer}
            actionsColumnWidth={actionsColumnWidth}
            initialParams={{
              folderIdIn: [folderId],
              typeKeyIn: [PermissionTypeEnum.SPO_OUTSIDE_UE],
              includePartnershipPermissions: true
            }}
            visibleColumns={permissionVisibleColumns}
          />
        )
      },
      {
        // Wypisy do zezwolenia na przewozy regularne poza UE
        name: PermissionTableEnum.SPO_OUTSIDE_UE_COPY,
        content: (
          <PermissionCopiesTable
            titleKey="spo-outside-eu"
            key="spo-outside-eu-copy"
            rowActions={rowActionRenderer}
            actionsColumnWidth={actionsColumnWidth}
            folderId={folderId}
            typeKeys={[PermissionTypeEnum.SPO_OUTSIDE_UE]}
            visibleColumns={permissionCopiesVisibleColumns}
            includePartnershipPermissions
          />
        )
      },
      {
        // Zezwolenia na przewozy wahadłowe
        name: PermissionTableEnum.SPO_SHUTTLE,
        content: (
          <PermissionsTable
            titleKey="spo-shuttle"
            key="spo-shuttle"
            permissionType={DomainPermissionTableEnum.SPO_SHUTTLE}
            rowActions={rowActionRenderer}
            actionsColumnWidth={actionsColumnWidth}
            initialParams={{
              folderIdIn: [folderId],
              typeKeyIn: [PermissionTypeEnum.SPO_SHUTTLE],
              includePartnershipPermissions: true
            }}
            visibleColumns={permissionVisibleColumns}
          />
        )
      },
      {
        // Wypisy do zezwolenia na przewozy wahadłowe
        name: PermissionTableEnum.SPO_SHUTTLE_COPY,
        content: (
          <PermissionCopiesTable
            titleKey="spo-shuttle"
            key="spo-shuttle-copy"
            rowActions={rowActionRenderer}
            actionsColumnWidth={actionsColumnWidth}
            folderId={folderId}
            typeKeys={[PermissionTypeEnum.SPO_SHUTTLE]}
            visibleColumns={permissionCopiesVisibleColumns}
            includePartnershipPermissions
          />
        )
      },
      {
        // Zezwolenia na przewozy okazjonalne
        name: PermissionTableEnum.SPO_OCCASIONAL,
        content: (
          <PermissionsTable
            titleKey="spo-occasional"
            key="spo-occasional"
            permissionType={DomainPermissionTableEnum.SPO_OCCASIONAL}
            rowActions={rowActionRenderer}
            actionsColumnWidth={actionsColumnWidth}
            initialParams={{
              folderIdIn: [folderId],
              typeKeyIn: [PermissionTypeEnum.SPO_OCCASIONAL],
              includePartnershipPermissions: true
            }}
            visibleColumns={permissionVisibleColumns}
          />
        )
      },
      {
        // Wypisy do zezwolenia na przewozy okazjonalne
        name: PermissionTableEnum.SPO_OCCASIONAL_COPY,
        content: (
          <PermissionCopiesTable
            titleKey="spo-occasional"
            key="spo-occasional-copy"
            rowActions={rowActionRenderer}
            actionsColumnWidth={actionsColumnWidth}
            folderId={folderId}
            typeKeys={[PermissionTypeEnum.SPO_OCCASIONAL]}
            visibleColumns={permissionCopiesVisibleColumns}
            includePartnershipPermissions
          />
        )
      },
      {
        // Zezwolenia na przewozy regularne tranzytem przez teren RP
        name: PermissionTableEnum.SPO_TRANSIT,
        content: (
          <PermissionsTable
            titleKey="spo-transit"
            key="spo-transit"
            permissionType={DomainPermissionTableEnum.SPO_TRANSIT}
            rowActions={rowActionRenderer}
            actionsColumnWidth={actionsColumnWidth}
            initialParams={{
              folderIdIn: [folderId],
              typeKeyIn: [PermissionTypeEnum.SPO_TRANSIT],
              includePartnershipPermissions: true
            }}
            visibleColumns={permissionVisibleColumns}
          />
        )
      },
      // Zezwolenia zagraniczne
      {
        name: PermissionTableEnum.FOREIGN_PERMISSION,
        content: (
          <ForeignPermitsTable
            key="foreign-permits"
            rowActions={rowActionRenderer}
            folderId={folderId}
            visibleColumns={foreignPermissionVisibleColumns}
            includePartnershipPermissions
          />
        )
      },
      {
        // Wypisy do zezwolenia na przewozy regularne tranzytem przez teren RP
        name: PermissionTableEnum.SPO_TRANSIT_COPY,
        content: (
          <PermissionCopiesTable
            titleKey="spo-transit"
            key="spo-transit-copy"
            rowActions={rowActionRenderer}
            actionsColumnWidth={actionsColumnWidth}
            folderId={folderId}
            typeKeys={[PermissionTypeEnum.SPO_TRANSIT]}
            visibleColumns={permissionCopiesVisibleColumns}
            includePartnershipPermissions
          />
        )
      },
      {
        // Zaświadczenia na przewozy na potrzeby własne
        name: PermissionTableEnum.OWN_NEEDS,
        content: (
          <PermissionsTable
            titleKey="own-needs"
            key="own-needs"
            permissionType={DomainPermissionTableEnum.OWN_NEEDS}
            rowActions={rowActionRenderer}
            actionsColumnWidth={actionsColumnWidth}
            initialParams={{
              folderIdIn: [folderId],
              typeKeyIn: [PermissionTypeEnum.OWN_NEEDS_GOODS_CARRIAGE, PermissionTypeEnum.OWN_NEEDS_PASSENGER_CARRIAGE],
              includePartnershipPermissions: true
            }}
            visibleColumns={permissionVisibleColumns.filter(
              column =>
                column !== PermissionsColumnsEnum.TRANSIT_STOP_END_TOUR &&
                column !== PermissionsColumnsEnum.TRANSIT_STOP_START_TOUR
            )}
          />
        )
      },
      {
        // Wypis do zaświadczenia na przewozy na potrzeby własne
        name: PermissionTableEnum.OWN_NEEDS_COPY,
        content: (
          <PermissionCopiesTable
            titleKey="own-needs"
            key="own-needs-copy"
            rowActions={rowActionRenderer}
            actionsColumnWidth={actionsColumnWidth}
            folderId={folderId}
            typeKeys={[PermissionTypeEnum.OWN_NEEDS_GOODS_CARRIAGE, PermissionTypeEnum.OWN_NEEDS_PASSENGER_CARRIAGE]}
            visibleColumns={permissionCopiesVisibleColumns}
            includePartnershipPermissions
          />
        )
      },
      {
        // Zezwolenia / Licencje wydane przez organ zagraniczny
        name: PermissionTableEnum.FOREIGN_AUTHORITY_PERMISSION,
        content: (
          <PermissionsTable
            titleKey="foreign-authority-permissions"
            key="foreign-authority-permissions"
            permissionType={DomainPermissionTableEnum.FOREIGN_AUTHORITY_PERMISSION}
            rowActions={rowActionRenderer}
            actionsColumnWidth={actionsColumnWidth}
            initialParams={{
              folderIdIn: [folderId],
              isIssuedByForeignAuthority: true,
              includePartnershipPermissions: true
            }}
            visibleColumns={foreignAuthorityPermissionTableColumns}
          />
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      permissionCopiesVisibleColumns,
      permissionVisibleColumns,
      actionsColumnWidth,
      folderId,
      isOperatorPortal,
      rowActionRenderer,
      rowActions,
      proceedingRowActions
    ]
  );

  const permissionTypes = useMemo(
    () => [
      {
        name: t('license:title.licenses'),
        value: PermissionTableEnum.LISENSE,
        isVisible: checkIsElementVisible(DomainUIElementEnum.FOLDER_PERMISSION_LICENCE_TABLE_VIEW)
      },
      {
        name: t('permission:title.permission.gitd'),
        value: PermissionTableEnum.GITD,
        isVisible: checkIsElementVisible(DomainUIElementEnum.FOLDER_PERMISSION_GITD_PERMITS_TABLE_VIEW)
      },
      {
        name: t('permission:title.permission.country-permissions'),
        value: PermissionTableEnum.COUNTRY_PERMISSION,
        isVisible: checkIsElementVisible(
          DomainUIElementEnum.FOLDER_PERMISSION_PERMITS_AND_NATIONAL_LICENCES_FROM_EXTERNAL_TABLE_VIEW
        )
      },
      {
        name: t('license:title.licenseCopies'),
        value: PermissionTableEnum.LICENSE_COPY,
        isVisible: checkIsElementVisible(DomainUIElementEnum.FOLDER_PERMISSION_LICENCE_EXTRACTS_TABLE_VIEW)
      },
      {
        name: t('permission:title.permissionCopies.permissionGitdCopy'),
        value: PermissionTableEnum.ROAD_CARRIER,
        isVisible: checkIsElementVisible(DomainUIElementEnum.FOLDER_PERMISSION_EXTRACTS_FROM_PERMITS_TABLE_VIEW)
      },
      {
        name: t('driverCertificate:title'),
        value: PermissionTableEnum.DRIVER_CERTIFICATE,
        isVisible: checkIsElementVisible(DomainUIElementEnum.FOLDER_PERMISSION_DRIVER_CERTIFICATES_TABLE_VIEW)
      },
      {
        name: t('permission:title.permission.spo-eu'),
        value: PermissionTableEnum.SPO_UE,
        isVisible: checkIsElementVisible(
          DomainUIElementEnum.FOLDER_PERMISSION_PERMITS_FOR_REGULAR_SERVICE_FROM_UE_TABLE_VIEW
        )
      },
      {
        name: t('permission:title.permissionCopies.spo-eu'),
        value: PermissionTableEnum.SPO_UE_COPY,
        isVisible: checkIsElementVisible(
          DomainUIElementEnum.FOLDER_PERMISSION_EXTRACTS_FOR_REGULAR_SERVICE_WITHIN_UE_TABLE_VIEW
        )
      },
      {
        name: t('permission:title.permission.spo-outside-eu'),
        value: PermissionTableEnum.SPO_OUTSIDE_UE,
        isVisible: checkIsElementVisible(
          DomainUIElementEnum.FOLDER_PERMISSION_PERMITS_FOR_REGULAR_SERVICE_OUTSIDE_UE_TABLE_VIEW
        )
      },
      {
        name: t('permission:title.permissionCopies.spo-outside-eu'),
        value: PermissionTableEnum.SPO_OUTSIDE_UE_COPY,
        isVisible: checkIsElementVisible(
          DomainUIElementEnum.FOLDER_PERMISSION_EXTRACTS_FOR_REGULAR_SERVICE_OUTSIDE_UE_TABLE_VIEW
        )
      },
      {
        name: t('permission:title.permission.spo-shuttle'),
        value: PermissionTableEnum.SPO_SHUTTLE,
        isVisible: checkIsElementVisible(DomainUIElementEnum.FOLDER_PERMISSION_SHUTTLE_PERMITS_TABLE_VIEW)
      },
      {
        name: t('permission:title.permissionCopies.spo-shuttle'),
        value: PermissionTableEnum.SPO_SHUTTLE_COPY,
        isVisible: checkIsElementVisible(
          DomainUIElementEnum.FOLDER_PERMISSION_EXTRACTS_FROM_PERMIT_FOR_SHUTTLE_SERVICE_TABLE_VIEW
        )
      },
      {
        name: t('permission:title.permission.spo-occasional'),
        value: PermissionTableEnum.SPO_OCCASIONAL,
        isVisible: checkIsElementVisible(
          DomainUIElementEnum.FOLDER_PERMISSION_PERMITS_FOR_OCCASIONAL_SERVICE_TABLE_VIEW
        )
      },
      {
        name: t('permission:title.permissionCopies.spo-occasional'),
        value: PermissionTableEnum.SPO_OCCASIONAL_COPY,
        isVisible: checkIsElementVisible(
          DomainUIElementEnum.FOLDER_PERMISSION_EXCLUSIONS_FROM_PERMIT_FOR_OCCASIONAL_SERVICE_TABLE_VIEW
        )
      },
      {
        name: t('permission:title.permission.spo-transit'),
        value: PermissionTableEnum.SPO_TRANSIT,
        isVisible: checkIsElementVisible(
          DomainUIElementEnum.FOLDER_PERMISSION_PERMITS_FOR_REGULAR_TRANSIT_IN_POLAND_TABLE_VIEW
        )
      },
      {
        name: t('permission:title.permissionCopies.spo-transit'),
        value: PermissionTableEnum.SPO_TRANSIT_COPY,
        isVisible: checkIsElementVisible(
          DomainUIElementEnum.FOLDER_PERMISSION_EXTRACTS_FROM_PERMIT_FOR_REGULAR_TRANSIT_IN_POLAND_TABLE_VIEW
        )
      },
      {
        name: t('permission:title.permission.own-needs'),
        value: PermissionTableEnum.OWN_NEEDS,
        isVisible: checkIsElementVisible(
          DomainUIElementEnum.FOLDER_PERMISSION_CERTIFICATES_FOR_OWN_ACCOUNT_TRANSPORT_TABLE_VIEW
        )
      },
      {
        name: t('permission:title.permissionCopies.own-needs'),
        value: PermissionTableEnum.OWN_NEEDS_COPY,
        isVisible: checkIsElementVisible(
          DomainUIElementEnum.FOLDER_PERMISSION_EXTRACTS_TO_CERTIFICATE_FOR_OWN_ACCOUNT_TRANSPORT_TABLE_VIEW
        )
      },
      {
        name: t('permission:title.permission.foreign-permissions'),
        value: PermissionTableEnum.FOREIGN_PERMISSION,
        isVisible: checkIsElementVisible(DomainUIElementEnum.FOLDER_FOREIGN_PERMISSION_TABLE_VIEW)
      },
      ...(omitForeignPermissionAndFromExternal
        ? [
            {
              name: t('permission:title.permission.country-permissions'),
              value: PermissionTableEnum.COUNTRY_PERMISSION,
              isVisible: checkIsElementVisible(
                DomainUIElementEnum.FOLDER_PERMISSION_PERMITS_AND_NATIONAL_LICENCES_FROM_EXTERNAL_TABLE_VIEW
              )
            },
            {
              name: t('permission:title.permission.foreign-authority-permissions'),
              value: PermissionTableEnum.FOREIGN_AUTHORITY_PERMISSION,
              isVisible: checkIsElementVisible(DomainUIElementEnum.FOLDER_FOREIGN_AUTHORITY_PERMISSION_TABLE_VIEW)
            }
          ]
        : [])
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const visiblePermissions = useMemo(() => {
    return permissionTypes.filter(({ isVisible }) => isVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionTypes, visiblePermissionTypes]);

  const columns = useMemo(() => {
    const formTypeKey = formType?.value;

    if (!formTypeKey) {
      return [];
    }

    if (formTypeKey.endsWith('COPY')) {
      return permissionCopiesVisibleColumns;
    }
    if (formTypeKey === PermissionTableEnum.LISENSE) {
      return licenseTableColumns;
    }
    if (formTypeKey === PermissionTableEnum.GITD) {
      return gitdPermissionTableColumns;
    }
    if (formTypeKey === PermissionTableEnum.COUNTRY_PERMISSION) {
      return countryPermissionTableColumns;
    }
    if (formTypeKey === PermissionTableEnum.LICENSE_COPY) {
      return licenseCopyTableColumns;
    }
    if (formTypeKey === PermissionTableEnum.ROAD_CARRIER) {
      return roadCarrierTableColumns;
    }
    if (formTypeKey === PermissionTableEnum.DRIVER_CERTIFICATE) {
      return driverCertificateTableColumns;
    }
    if (formTypeKey === PermissionTableEnum.FOREIGN_AUTHORITY_PERMISSION) {
      return foreignAuthorityPermissionTableColumns;
    }
    return permissionVisibleColumns;
  }, [
    countryPermissionTableColumns,
    driverCertificateTableColumns,
    foreignAuthorityPermissionTableColumns,
    formType?.value,
    gitdPermissionTableColumns,
    licenseCopyTableColumns,
    licenseTableColumns,
    permissionCopiesVisibleColumns,
    permissionVisibleColumns,
    roadCarrierTableColumns
  ]);

  const visibleTable = useMemo(() => {
    if (isSingleTable) {
      return (
        <AvailableFormsTable
          folderId={folderId}
          selectedPermissionType={formType?.value as PermissionTableEnum}
          permissionTypes={permissionTypes}
          columns={columns}
          rowActions={proceedingRowActions}
          tablePlugins={proceedingTablePlugins}
          actionsColumnWidth={actionsColumnWidth}
          includePartnershipPermissions={includePartnershipPermissions}
        />
      );
    }
    return permissionTables.find(({ name }) => formType?.value === name)?.content;
  }, [
    isSingleTable,
    permissionTables,
    folderId,
    formType?.value,
    permissionTypes,
    columns,
    proceedingRowActions,
    proceedingTablePlugins,
    actionsColumnWidth,
    includePartnershipPermissions
  ]);

  useEffect(() => {
    if (hasInitialValue) {
      setValue(formTypeName, visiblePermissions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visiblePermissions]);

  return (
    <>
      {isDialog ? (
        <AutocompleteSelectField
          label={t('proceeding:administrative.tab.formOperations.field.formType')}
          name="form.type"
          options={visiblePermissions}
          inputMode={InputMode.FORM}
          isRequired
        />
      ) : (
        <Section>
          <GridLayout itemProps={{ xs: 12 }}>
            <AutocompleteSelectField
              label={t('proceeding:administrative.tab.formOperations.field.formType')}
              name="form.type"
              options={visiblePermissions}
              inputMode={InputMode.FORM}
              isRequired
            />
          </GridLayout>
        </Section>
      )}
      {!hasActiveLicense && isFolderTypeKeyForActiveLicense && !isSingleTable && (
        <div className="mt-6">
          <WarningInformation content={t('folder:details.tab.permissions.messageBlock.noActiveLicenseInFolder')} />
        </div>
      )}
      <div>{formType && visibleTable}</div>
    </>
  );
}

export default PermissionsTab;
