import { ArchiveCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function archiveApplication(params: ArchiveCreateRequest) {
  return API.archiveApplications.archiveApplication(params, {
    ...endpointsConfig.archiveApplication
  });
}

function useArchiveApplicationMutation() {
  return useMutation(archiveApplication);
}

export default useArchiveApplicationMutation;
