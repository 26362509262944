import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function deleteAssociation(id: string) {
  return API.folder.deleteAssociationFolder(id, {
    ...endpointsConfig.deleteAssociationFolder
  });
}

function useDeleteAssociationMutation() {
  const [t] = useTranslation();
  const { showSnackbar, showSuccessSnackbar } = useSnackbar();

  return useMutation(deleteAssociation, {
    onSuccess: () => showSuccessSnackbar(t('associations:messages.associationDeleteSuccess')),
    onError: () => showSnackbar('warning', t('associations:messages.associationDeleteFailure'))
  });
}
export default useDeleteAssociationMutation;
