import { DictionaryEntryDetailsLanguage } from '@avispon/dictionary/dist/models';

import { useTableLayoutProps } from '@libs/common/v2';

import { DictionaryEntryNameStatusEnum, useDictionaryEntriesQuery } from '@libs/dictionary';

import useDictionaryEntriesListColumns from './useDictionaryEntriesListColumns';

function useDictionaryEntriesTable(dictionaryId: string) {
  const { columns, mappedSortFields, mappedFilterFields } = useDictionaryEntriesListColumns();

  return useTableLayoutProps({
    tableHookQuery: useDictionaryEntriesQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    },
    tableHookQueryInitialParams: {
      dictionary: dictionaryId,
      language: DictionaryEntryDetailsLanguage.pl,
      status: DictionaryEntryNameStatusEnum.ALL
    }
  });
}

export default useDictionaryEntriesTable;
