import { FieldValues, UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ApplicationSnapshot } from '@ibtm/domain';

import { Button, IExtendedColumn, useDialog } from '@libs/common/v2';

import MispellApplicationSearchApplicationsDialog from './MispellApplicationSearchApplicationsDialog';

interface IProps {
  applicationId: string;
  getValues?: UseFormGetValues<FieldValues>;
  visibleColumns: IExtendedColumn<ApplicationSnapshot>[];
}

function MisspellApplicationsSearchButton({ applicationId, getValues, visibleColumns }: IProps) {
  const { openDialog } = useDialog();
  const [t] = useTranslation();

  const openMispellApplicationSearchApplicationsDialog = () =>
    openDialog(({ closeDialog }) => (
      <MispellApplicationSearchApplicationsDialog
        closeDialog={closeDialog}
        applicationId={applicationId}
        getValues={getValues}
        visibleColumns={visibleColumns}
      />
    ));

  return (
    <Button
      type="button"
      variant="outlined"
      onClick={openMispellApplicationSearchApplicationsDialog}
      label={t('global:action.add')}
      isNoMargin
    />
  );
}

export default MisspellApplicationsSearchButton;
