import { ProceedingKreptdAddViolationsToCurrentRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function addToProceeding(requestData: ProceedingKreptdAddViolationsToCurrentRequest) {
  return API.proceedingsKreptd.addProceedingKreptdViolationsToCurrent(requestData, {
    ...endpointsConfig.addProceedingKreptdViolationsToCurrent
  });
}

function useViolationsAddingToProceedingMutation() {
  return useMutation(addToProceeding);
}

export default useViolationsAddingToProceedingMutation;
