import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderDetails } from '@ibtm/domain';
import { isEmpty, isNil } from 'lodash';

import { KeyType } from '@libs/common';
import { Dialog, typedNameV2, useFormV2Context } from '@libs/common/v2';
import { parseTranslationTextWithNoEscapeValue } from '@libs/common/v2/utils';

import { BorrowFolderDialogTypeEnum, BorrowFolderSendValues } from '../model';

import { useBorrowFoldersContext } from './common/BorrowFolderProvider';
import { BorrowFoldersDialogContentWrapper } from '.';

type DialogData = {
  title: KeyType;
  confirmText: KeyType | null;
  content: React.ReactNode;
  dialogText?: KeyType | null;
};

function BorrowFoldersDialog() {
  const [t] = useTranslation();
  const [chosenFolder, setChosenFolder] = useState<string>('');

  const { isSubmitting, watch } = useFormV2Context();
  const { contentType, handleSend, open, onClose, row } = useBorrowFoldersContext();
  const folderNumber = useMemo(() => ((row as FolderDetails)?.number || chosenFolder) as string, [row, chosenFolder]);

  const renderTextWithOrWithoutFolderNumber = useCallback(
    (textWithFolder: string, textWithoutFolder: string): KeyType => {
      if (isEmpty(folderNumber) || isNil(folderNumber)) {
        return textWithoutFolder as KeyType;
      }

      return textWithFolder as KeyType;
    },
    [folderNumber]
  );

  const dialogData = useCallback((): DialogData => {
    switch (contentType) {
      case BorrowFolderDialogTypeEnum.BORROW_FOLDER:
        return {
          title: renderTextWithOrWithoutFolderNumber(
            'archive:borrowFolder.borrowDialogTitleWithFolder',
            'archive:borrowFolder.borrowDialogTitle'
          ),
          confirmText: 'archive:borrowFolder.action.borrow',
          content: <BorrowFoldersDialogContentWrapper />,
          dialogText: renderTextWithOrWithoutFolderNumber(
            'archive:borrowFolder.confirmBorrowDialogTitleWithFolder',
            'archive:borrowFolder.confirmBorrowDialogTitle'
          )
        };
      case BorrowFolderDialogTypeEnum.CHANGE_BORROWER:
        return {
          title: 'archive:borrowFolder.changeDialogTitle',
          confirmText: 'action.change',
          content: <BorrowFoldersDialogContentWrapper />
        };
      case BorrowFolderDialogTypeEnum.RETURN_FOLDER:
        return {
          title: renderTextWithOrWithoutFolderNumber(
            'archive:borrowFolder.returnDialogTitleWithFolder',
            'archive:borrowFolder.returnDialogTitle'
          ),
          confirmText: 'action.return',
          content: <BorrowFoldersDialogContentWrapper />,
          dialogText: renderTextWithOrWithoutFolderNumber(
            'archive:borrowFolder.confirmReturnDialogTitleWithFolder',
            'archive:borrowFolder.confirmReturnDialogTitle'
          )
        };
      default:
        return {
          title: null,
          confirmText: null,
          content: null
        };
    }
  }, [contentType, renderTextWithOrWithoutFolderNumber]);

  const { title, content, confirmText, dialogText } = useMemo(
    () => dialogData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentType, dialogData, folderNumber]
  );

  const borrow: BorrowFolderSendValues = watch(typedNameV2<BorrowFolderSendValues>('borrow'));

  useEffect(() => {
    if (borrow?.pickedFolder) {
      setChosenFolder(borrow?.pickedFolder.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const getDialogText = useCallback(
    (text: KeyType): string => {
      if (text) {
        return parseTranslationTextWithNoEscapeValue(text, { folder: folderNumber });
      }

      return '';
    },
    [folderNumber]
  );

  return (
    <Dialog
      title={getDialogText(title)}
      confirmText={confirmText ? t(confirmText) : ''}
      cancelText={t('action.cancel')}
      onConfirm={handleSend}
      titleSupportingText={getDialogText(dialogText)}
      onCancel={() => {
        onClose();
        setChosenFolder('');
      }}
      isConfirmLoading={isSubmitting}
      isOpen={open}
    >
      {content}
    </Dialog>
  );
}

export default BorrowFoldersDialog;
