import { createContext } from 'react';
import { SystemParameterDetails as SystemParameterDetailsClient } from '@ibtm/client-domain';
import { SystemParameterDetails } from '@ibtm/domain';

export const SYSTEM_AUTOLOGOUT_PARAMETER_KEY = 'session_validity_duration';

const parametersConfig = {
  sessionValidityDuration: { parameterKey: SYSTEM_AUTOLOGOUT_PARAMETER_KEY }
};

export type SystemParametersContextValue = {
  [key in keyof typeof parametersConfig]: SystemParameterDetails | SystemParameterDetailsClient;
};

const context: SystemParametersContextValue = {
  sessionValidityDuration: null
};

const SystemParametersContext = createContext(context);

export default SystemParametersContext;
