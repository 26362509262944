import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ResourceObjectOrderCreateRequest } from '@ibtm/client-domain';
import { ResourceTypeLite } from '@ibtm/domain';
import * as Yup from 'yup';

import { partialTranslate } from '@libs/common';
import {
  AutocompleteLazyFetchSelectField,
  Dialog,
  DictionarySelectField,
  FormMode,
  FormV2Context,
  GridLayout,
  NumberInputField,
  SelectOptionPartial,
  useIsSmallScreen
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { WarningInformation } from '@libs/common/v2/components/warning';

import { useDictionaryTranslations } from '@libs/dictionary';

import { API } from '@libs/domain/api';
import { DomainDictionaryEnum } from '@libs/domain/config';

import { ResourceQueryKeysEnum, useAvailableResourcesToOrderQuery, useResourceObjectOrderMutation } from '../../../api';

type ResourceObjectOrderFields = {
  country: SelectOptionPartial<string>;
  name: SelectOptionPartial<ResourceTypeLite>;
  amount: number;
};

interface IProps {
  closeDialog: () => void;
}

function ResourceObjectOrderForm({ closeDialog }: IProps) {
  const { isSmallScreen } = useIsSmallScreen();
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const getLabel = partialTranslate('resource:fields');
  const { translate } = useDictionaryTranslations();

  const { mutate: orderResourceObjects, isLoading } = useResourceObjectOrderMutation();

  const { showSuccessSnackbar } = useSnackbar();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Partial<ResourceObjectOrderFields>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(
      Yup.object({
        country: Yup.object().nullable().required(),
        name: Yup.object().nullable().required(),
        amount: Yup.number()
          .transform(value => (Number.isNaN(value) ? undefined : value))
          .min(1)
          .required()
      })
    ),
    defaultValues: {}
  });

  const nameWatcher = watch('name');
  const countryWatcher = watch('country');

  const { data: resourcesToOrder, isLoading: isAvailableResourcesLoading } = useAvailableResourcesToOrderQuery(
    {
      resourceTypeId: nameWatcher?.id
    },
    {
      enabled: !!nameWatcher?.id
    }
  );

  const onSubmit = (formData: Partial<ResourceObjectOrderFields>) => {
    const requestData: ResourceObjectOrderCreateRequest = {
      countryCodeKey: formData.country.value,
      resourceTypeId: formData.name.value?.id,
      amount: formData.amount
    };

    orderResourceObjects(requestData, {
      onSuccess: () => {
        queryCache.invalidateQueries(ResourceQueryKeysEnum.RESOURCES_SEARCH);
        queryCache.invalidateQueries(ResourceQueryKeysEnum.RESOURCE_OBJECT_ASSOCIATION_POOLS);
        showSuccessSnackbar(t('resource:messages.orderFormsSuccess'));
        closeDialog();
      }
    });
  };

  const getOptionLabel = option => {
    return translate(DomainDictionaryEnum.RESOURCE_FORM_NAME, option.nameKey, option.nameKey);
  };

  const values = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      formMode: FormMode.CREATE
    }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('resource:dialog.resourceObjectsOrderTitle')}
      confirmText={t('resource:actions.order')}
      cancelText={t('action.cancel')}
      onConfirm={handleSubmit(onSubmit)}
      onCancel={closeDialog}
      dialogSize="small"
      isConfirmLoading={isLoading}
      isFullScreen={isSmallScreen}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <GridLayout itemProps={{ xs: 12 }}>
          <DictionarySelectField
            name="country"
            label={getLabel('country')}
            dictionaryName={DomainDictionaryEnum.COUNTRY_CODE}
            onChange={() => {
              setValue('name', { value: null });
            }}
            isRequired
          />

          <AutocompleteLazyFetchSelectField
            name="name"
            queryKey={`${ResourceQueryKeysEnum.RESOURCE_TYPES_FIELD}_${countryWatcher?.value}`}
            label={getLabel('name')}
            isRequired
            optionLabelParser={getOptionLabel}
            api={{
              FETCH: (searchText: string, params: { page: number; size: number }) =>
                API.client.resourceTypes.getResourceTypeForOrder('', {
                  ...params,
                  countryCodeKeyIn: countryWatcher?.value ? [countryWatcher?.value] : [],
                  nameKeyContains: searchText
                })
            }}
          />

          <NumberInputField
            name="amount"
            label={getLabel('amount')}
            isClearable
            isRequired
            max={resourcesToOrder?.available || 0}
          />

          {(!!resourcesToOrder || !!nameWatcher) && !isAvailableResourcesLoading && (
            <WarningInformation
              withoutBorder
              content={t('resource:messages.availableResourcesToOrder', { value: resourcesToOrder?.available || 0 })}
            />
          )}
          {(!!resourcesToOrder || !!nameWatcher) &&
            !isAvailableResourcesLoading &&
            resourcesToOrder?.limitedByInsurancePolicy && (
              <WarningInformation content={t('resource:messages.availableResourcesToOrderLimitedByInsurancePolicy')} />
            )}
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default ResourceObjectOrderForm;
