import { useTranslation } from 'react-i18next';

import { partialTranslate } from '@libs/common';
import {
  DatepickerField,
  DictionarySelectField,
  GridLayout,
  InputMode,
  Section,
  TextInputField,
  typedNameV2
} from '@libs/common/v2';
import { convertDateToDateTimeFormat } from '@libs/common/v2/utils';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { ExploitationBaseFormData } from '@libs/domain/exploitation-base';

const getLabel = partialTranslate('exploitationBase:field');

function GeneralInformationSection() {
  const [t] = useTranslation();

  return (
    <Section title={t('section.generalInformation')}>
      <GridLayout itemProps={{ xs: 12, sm: 6 }}>
        <DatepickerField
          name={typedNameV2<ExploitationBaseFormData>('created')}
          label={getLabel('created')}
          viewModeDateParser={convertDateToDateTimeFormat}
          inputMode={InputMode.VIEW}
        />

        <TextInputField
          name={typedNameV2<ExploitationBaseFormData>('author.name')}
          label={getLabel('author')}
          inputMode={InputMode.VIEW}
        />

        <DatepickerField
          name={typedNameV2<ExploitationBaseFormData>('modified')}
          label={getLabel('modified')}
          viewModeDateParser={convertDateToDateTimeFormat}
          inputMode={InputMode.VIEW}
        />

        <TextInputField
          name={typedNameV2<ExploitationBaseFormData>('modifier.name')}
          label={getLabel('modifier')}
          inputMode={InputMode.VIEW}
        />

        <DictionarySelectField
          dictionaryName={DomainDictionaryEnum.EXPLOITATION_BASE_STATUS}
          name={typedNameV2<ExploitationBaseFormData>('statusKey')}
          label={getLabel('status')}
          inputMode={InputMode.VIEW}
        />
      </GridLayout>
    </Section>
  );
}

export default GeneralInformationSection;
