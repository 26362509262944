import React from 'react';
import { actions, Hooks, TableInstance, UseTableStateHook } from 'react-table';

// Actions
actions.hookTableState = 'hookTableState';

// eslint-disable-next-line consistent-return
function reducer(state, action: { type; payload }) {
  switch (action.type) {
    case actions.hookTableState:
      if (typeof action.payload === 'function') {
        action.payload(state);
      }
      return state;
  }
}

function useInstance<T>(instance: TableInstance<T>) {
  const { dispatch } = instance;

  const hookTableState: UseTableStateHook<T>['hookTableState'] = React.useCallback(
    (hookState: (state: TableInstance<T>['state']) => void) =>
      dispatch({ type: actions.hookTableState, payload: hookState }),
    [dispatch]
  );

  Object.assign(instance, {
    hookTableState
  });
}
/**
 * plugin umożliwiający wpięcie się w aktualny stan tabeli przed renderowaniem, funkcję hookTableState należy wywołać po funkcji aktualizującej stan tabeli np setFilter aby hook był ostatni w kolejce do aktualizacji stanu i funkcja otrzymała najnowszy stan
 */
export const useTableStateHook = <T extends Record<string, unknown> = Record<string, unknown>>(hooks: Hooks<T>) => {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
};

useTableStateHook.pluginName = 'useTableStateHook';
