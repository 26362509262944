import { usePaginatedQuery } from 'react-query';
import { SubjectLiteExtendedPage, SubjectSearchFilter } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

import UserEntrepreneursQueryKeysEnum from './UserEntrepreneursQueryKeysEnum';

export const getUserEntrepreneursList = async (_, params: SubjectSearchFilter & { userId: string }) => {
  const { userId, ...rest } = params;
  const { data } = await API.usersAvailableSubjects.getUsersAvailableSubjects(userId, rest, {
    ...endpointsConfig.getUsersAvailableSubjects
  });

  return data;
};

function useGetUserEntrepreneursListQuery(
  params: SubjectSearchFilter & { userId: string },
  queryConfig = {},
  queryKey?: string
) {
  return usePaginatedQuery<SubjectLiteExtendedPage>(
    [queryKey || UserEntrepreneursQueryKeysEnum.USER_ENTREPRENEURS, params],
    getUserEntrepreneursList,
    queryConfig
  );
}

export default useGetUserEntrepreneursListQuery;
