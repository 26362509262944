import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormMode, TableButton, TableButtonMore, TableButtonView } from '@libs/common/v2';

import { AddReplacementDialog, DeleteReplacementsDialog } from '@libs/user/components';
import { ReplacementsSnapshot } from '@libs/user/models';

interface IProps {
  original: ReplacementsSnapshot;
}

function ReplacementsRowActions({ original }: IProps) {
  const [t] = useTranslation();
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const isViewOrEditOpen = useMemo(() => isViewOpen || isEditOpen, [isViewOpen, isEditOpen]);
  const handleEditOpen = useCallback(() => setIsEditOpen(!isEditOpen), [isEditOpen]);
  const handleViewOpen = useCallback(() => setIsViewOpen(!isViewOpen), [isViewOpen]);
  const handleDeleteOpen = useCallback(() => setIsDeleteOpen(!isDeleteOpen), [isDeleteOpen]);

  return (
    <>
      <TableButtonMore>
        {close => (
          <>
            <TableButton
              label={t('action.delete')}
              onClick={() => {
                setIsDeleteOpen(!isDeleteOpen);
                close();
              }}
            />
            <TableButton
              label={t('action.edit')}
              onClick={() => {
                handleEditOpen();
                close();
              }}
            />
          </>
        )}
      </TableButtonMore>
      <TableButtonView onClick={handleViewOpen} />
      {isViewOrEditOpen && (
        <AddReplacementDialog
          formMode={isEditOpen ? FormMode.EDIT : FormMode.VIEW}
          original={original}
          isOpen={isViewOrEditOpen}
          setIsOpen={isEditOpen ? handleEditOpen : handleViewOpen}
          setIsDeleteOpen={handleDeleteOpen}
        />
      )}
      {isDeleteOpen && (
        <DeleteReplacementsDialog
          replacementId={original.id}
          isDeleteOpen={isDeleteOpen}
          setIsDeleteOpen={setIsDeleteOpen}
        />
      )}
    </>
  );
}

export default ReplacementsRowActions;
