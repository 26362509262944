import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BackButton, TableLayout, useQueryParams, useRouter, useTableRowActions } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';
import { DomainDictionaryEntry, DomainUIElementEnum } from '@libs/domain/config';
import { usePermitDialog } from '@libs/domain/foreign-permission';
import { IResourceObjectsClient } from '@libs/domain/resource';

import { ResourceQueryKeysEnum } from '../../../api';
import { useResourceDialog } from '../../../hooks';
import useResourceObjectsTable from '../../../hooks/tables/useResourceObjectsTable';
import { getInitialFilters, parseResourceObjectsList } from '../../../utils';
import { ResourceObjectReturnsForms, RollbackReturnsForm } from '../../forms';

type QueryParams = {
  number: string;
  year: string;
};

function ResourceObjectsTable() {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const params = useQueryParams<QueryParams>();
  const { openDialog } = useResourceDialog();
  const { openSettleEKMTPermitDialog, openSettleSinglePermitDialog } = usePermitDialog();
  const { routes, goBack } = useRouter();
  const tableProps = useResourceObjectsTable(getInitialFilters(params));

  const handleReturnFormDialog = (original: IResourceObjectsClient) => {
    openDialog(ResourceObjectReturnsForms, {
      initialData: original,
      onSuccess: () => {
        queryCache.invalidateQueries(ResourceQueryKeysEnum.RESOURCES_SEARCH);
      }
    });
  };

  const handleRollbackReturnsDialog = (original: IResourceObjectsClient) => {
    openDialog(RollbackReturnsForm, { initialData: original });
  };

  const handleSettlement = useCallback(
    (resourceData: IResourceObjectsClient) => {
      if (resourceData?.type?.type === DomainDictionaryEntry.RESOURCE_FORM_TYPE.SINGLE_PERMIT) {
        openSettleSinglePermitDialog({
          foreignPermissionId: resourceData?.foreignPermission?.id,
          initialData: {
            id: resourceData?.foreignPermission?.id,
            year: resourceData?.year,
            form: {
              groupKey: resourceData?.group,
              stateKey: resourceData?.state
            },
            subject: {
              id: resourceData?.id,
              name: resourceData?.released
            },
            name: resourceData?.name,
            version: resourceData?.foreignPermission?.version
          },
          onSuccess: () => {
            queryCache.invalidateQueries(ResourceQueryKeysEnum.RESOURCES_SEARCH);
          }
        });
      } else if (resourceData?.type?.type === DomainDictionaryEntry.RESOURCE_FORM_TYPE.EKMT_PERMIT) {
        openSettleEKMTPermitDialog({
          foreignPermissionId: resourceData?.foreignPermission?.id,
          onSuccess: () => {
            queryCache.invalidateQueries(ResourceQueryKeysEnum.RESOURCES_SEARCH);
          },
          initialData: resourceData?.foreignPermission
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openSettleEKMTPermitDialog, openSettleSinglePermitDialog]
  );

  const { renderTableActions } = useTableRowActions<IResourceObjectsClient>([
    {
      id: DomainUIElementEnum.RESOURCES_DEPOT_DETAILS_VIEW,
      label: 'action.show',
      link: ({ original }) => {
        if (original?.depot?.id) {
          return routes.resourceDetails(original?.depot.id);
        }
        return routes.releaseDocumentView(original?.releaseDocumentId);
      },
      icon: 'ArrowIcon'
    },
    {
      id: DomainUIElementEnum.RESOURCES_OBJECT_RETURN,
      label: 'resource:actions.return',
      onClick: ({ row: { original } }) => handleReturnFormDialog(original),
      isHidden: ({ row: { original } }) =>
        ![
          DomainDictionaryEntry.RESOURCE_FORM_STATE.RELEASED,
          DomainDictionaryEntry.RESOURCE_FORM_STATE.EXTERNAL_RELEASED,
          DomainDictionaryEntry.RESOURCE_FORM_STATE.ISSUED_TO_ENTREPRENEURS,
          DomainDictionaryEntry.RESOURCE_FORM_STATE.ISSUED_TO_ASSOCIATION
        ].includes(original.state),
      icon: 'ReturnIcon'
    },
    {
      id: DomainUIElementEnum.RESOURCES_OBJECT_RETURN_ROLLBACK,
      label: 'resource:actions.rollbackReturns',
      onClick: ({ row: { original } }) => handleRollbackReturnsDialog(original),
      isHidden: ({ row: { original } }) => original.state !== DomainDictionaryEntry.RESOURCE_FORM_STATE.RETURNED,
      icon: 'RestoreIcon'
    },
    {
      id: DomainUIElementEnum.RESOURCES_SEARCH_SETTLEMENT_BUTTON,
      label: 'resource:actions.settlement',
      onClick: ({ row: { original } }) => {
        handleSettlement(original);
      },
      isHidden: ({ row: { original } }) =>
        ![
          DomainDictionaryEntry.RESOURCE_FORM_TYPE.EKMT_PERMIT,
          DomainDictionaryEntry.RESOURCE_FORM_TYPE.SINGLE_PERMIT
        ].includes(original?.type?.type) || !original?.foreignPermission?.id,
      icon: 'CoinsIcon'
    }
  ]);

  const pageTitle = useMemo(() => {
    if (params?.number && params?.year) {
      return t('resource:search.fullTitle', {
        number: params?.number,
        year: params?.year
      });
    }
    if (params?.year) {
      return t('resource:search.yearTitle', {
        year: params?.year
      });
    }
    if (params?.number) {
      return t('resource:search.numberTitle', {
        number: params?.number
      });
    }
    return t('resource:search.noFragmentsTitle');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <TableLayout
      {...tableProps}
      pageTitle={pageTitle}
      xlsxDownload={{
        fileName: t('resource:list.resourceTypesListTitle'),
        pathToXLSXTranslation: 'resource:fields',
        apiRequest: params =>
          API.resourceObjects
            .getResourceObjects(params)
            .then(res => ({ ...res, data: { ...res.data, content: parseResourceObjectsList(res.data.content) } }))
      }}
      headerActions={<BackButton onClick={() => goBack()} />}
      rowActions={renderTableActions([
        DomainUIElementEnum.RESOURCES_DEPOT_DETAILS_VIEW,
        DomainUIElementEnum.RESOURCES_OBJECT_RETURN,
        DomainUIElementEnum.RESOURCES_OBJECT_RETURN_ROLLBACK,
        DomainUIElementEnum.RESOURCES_SEARCH_SETTLEMENT_BUTTON
      ])}
    />
  );
}

export default ResourceObjectsTable;
