import { i18n } from '@libs/common';

export const parseStreet = (street: string): string => {
  /**
   * Regular expression to match strings that start with 'ul', 'al', or 'os' (case-insensitive).
   */
  const regex = /^(ul|al|os)/i;

  if (regex.test(street)) {
    return street;
  }
  return `${i18n.t('folder:shortcut.street')}. ${street}`;
};
