import { PaginatedQueryConfig, useQuery } from 'react-query';
import { DepotManagersLiteList, GetDepotManagersListRequest } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';
import { ResourceQueryKeysEnum } from '..';

export const getDepotManagersLite = async (_, params: GetDepotManagersListRequest) => {
  const { data } = await API.depotManagers.getDepotLiteList(params, {
    ...endpointsConfig.getDepots
  });

  return data;
};

const useDepotManagersLiteQuery = (
  params: GetDepotManagersListRequest,
  queryConfig?: PaginatedQueryConfig<DepotManagersLiteList, unknown>
) => useQuery([ResourceQueryKeysEnum.DEPOTS_MANAGERS_LITE, params], getDepotManagersLite, queryConfig);

export default useDepotManagersLiteQuery;
