import { FormMode, useDialog } from '@libs/common/v2';

import { PermissionTableEnum } from '@libs/domain/application';

import PermissionDialog from '../../components/PermissionDialog';

interface IProps {
  applicationId?: string;
  permissionType: PermissionTableEnum;
}

function usePermissionDialog({ applicationId, permissionType }: IProps) {
  const { openDialog } = useDialog();
  const showPermissionsDetailDialog = ({
    onSuccess,
    formMode,
    permissionId,
    folderApplicationId
  }: {
    onSuccess?: () => void;
    permissionId?: string;
    folderApplicationId?: string;
    formMode: FormMode;
  }) => {
    openDialog(({ closeDialog }) => (
      <PermissionDialog
        onCloseDialog={closeDialog}
        applicationId={applicationId || folderApplicationId}
        onSuccess={onSuccess}
        permissionType={permissionType}
        formMode={formMode}
        permissionId={permissionId}
      />
    ));
  };

  return { showPermissionsDetailDialog };
}

export default usePermissionDialog;
