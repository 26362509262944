import { DriversApiDeleteDriverRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface DeleteDriverRequest extends DriversApiDeleteDriverRequest {
  applicationId?: string;
}

function deleteDriverFromApplicationClient(requestData: DeleteDriverRequest) {
  return API.client.application.deleteDriverFromApplication(requestData.applicationId, '', '', {
    ...endpointsConfig.deleteDriver
  });
}

function deleteDriverFromApplication(requestData: DeleteDriverRequest) {
  return API.application.deleteDriverFromApplication(requestData.applicationId, {
    ...endpointsConfig.deleteDriver
  });
}

function useDeleteDriverFromApplicationMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(deleteDriverFromApplicationClient, deleteDriverFromApplication));
}

export default useDeleteDriverFromApplicationMutation;
