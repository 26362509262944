import { createContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { SelectOption, useViewModesV2 } from '@libs/common/v2';

import { ReleaseDocumentsDetailsPageParams } from '../model';
import { ReturnItemRange } from '../model/ReturnModels';

import ReleaseDocumentsReturnPage from './ReleaseDocumentsReturnPage';
import { ReleaseDocumentsDetailsPage } from '.';

interface IChangeReturnModeContext {
  isReturn?: boolean;
  changeMode?: () => void;
  isFromCreation?: boolean;
  items?: ReturnItemRange[];
  setItems?: (items: ReturnItemRange[]) => void;
  reasonKey?: SelectOption<any>;
  setReasonKey?: (reasonKey: SelectOption<any>) => void;
  clear?: () => void;
}

export const ChangeReturnModeContext = createContext<IChangeReturnModeContext>({});

const ReleaseDocumentsWrapper = () => {
  const [isReturn, setIsReturn] = useState(false);
  const { formMode } = useParams<ReleaseDocumentsDetailsPageParams>();
  const { createMode } = useViewModesV2(formMode);
  const [items, setItems] = useState<ReturnItemRange[]>([]);
  const [isFromCreation, setIsFromCreation] = useState(false);
  const [reasonKey, setReasonKey] = useState(null);

  useEffect(() => {
    if (!formMode) {
      setIsReturn(true);
    }
  }, [formMode]);

  const values = useMemo(
    () => ({
      isReturn,
      changeMode: () => {
        if (createMode && !isReturn) {
          setIsFromCreation(true);
        }
        setIsReturn(!isReturn);
      },
      isFromCreation,
      items,
      setItems,
      reasonKey,
      setReasonKey,
      clear: () => {
        setIsReturn(false);
        setIsFromCreation(false);
        setItems([]);
        setReasonKey(null);
      }
    }),
    [isReturn, isFromCreation, items, reasonKey, createMode]
  );

  return (
    <ChangeReturnModeContext.Provider value={values}>
      {!isReturn ? <ReleaseDocumentsDetailsPage /> : <ReleaseDocumentsReturnPage />}
    </ChangeReturnModeContext.Provider>
  );
};

export default ReleaseDocumentsWrapper;
