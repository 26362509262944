import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { FoldersApiUpdateFolderStatusRequest } from '@ibtm/domain';
import * as Yup from 'yup';

import { partialTranslate } from '@libs/common';
import { Dialog, DictionarySelectField, FormV2Context } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';
import { useChangeFolderStatusMutation } from '@libs/domain/folder';

import { RoadTransportQueryKeysEnum } from '../../../api';
import { IFoldersToTransferClient } from '../../../models';

const getLabel = partialTranslate('roadTransport:fields');

interface IProps {
  initialData: IFoldersToTransferClient;
  closeDialog: () => void;
}

function FoldersToTransferStatusChangeForm({ initialData, closeDialog }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();

  const { mutate: changeStatus, isLoading } = useChangeFolderStatusMutation();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Partial<IFoldersToTransferClient>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        status: Yup.string().nullable().required()
      })
    ),
    defaultValues: {
      status: null
    }
  });

  const onSubmit = (formData: Partial<IFoldersToTransferClient>) => {
    const requestData: FoldersApiUpdateFolderStatusRequest = {
      folderId: initialData.id,
      folderStatusUpdateRequest: {
        statusKey: formData.status
      }
    };

    changeStatus(requestData, {
      onSuccess: () => {
        showSnackbar('success', t('roadTransport:messages.changeStatusSuccess'));

        queryCache.invalidateQueries(RoadTransportQueryKeysEnum.FOLDERS_TO_TRANSFER_LIST);
        closeDialog();
      }
    });
  };

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('roadTransport:dialog.changeStatusTitle')}
      confirmText={t('action.change')}
      cancelText={t('action.cancel')}
      onConfirm={() => handleSubmit(onSubmit)()}
      onCancel={closeDialog}
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <DictionarySelectField
          name="status"
          label={getLabel('status')}
          dictionaryName={DomainDictionaryEnum.FOLDER_STATUS}
          optionsFilter={option =>
            [DomainDictionaryEntry.FOLDER_STATUS.ACTIVE, DomainDictionaryEntry.FOLDER_STATUS.TRANSFERRED].includes(
              option.value
            )
          }
          stringValue
          isRequired
        />
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default FoldersToTransferStatusChangeForm;
