import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Dialog, FormMode, FormV2Context, GridItem, GridLayout, Section, useIsSmallScreen } from '@libs/common/v2';

import { useProxyFields } from '../hooks';
import { ProxyFields } from '../model';

interface IProps {
  proxyId: string;
  onCloseDialog: () => void;
  isCitizenshipFieldVisible?: boolean;
  isOpen: boolean;
}

function ProxyDetailsDialog({ proxyId, onCloseDialog, isCitizenshipFieldVisible, isOpen }: IProps) {
  const [t] = useTranslation();
  const { isSmallScreen } = useIsSmallScreen();

  const {
    control,
    register,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    reset
  } = useForm<ProxyFields>({
    mode: 'onBlur',
    criteriaMode: 'all'
  });

  const formValues = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      isSubmitting,
      formMode: FormMode.VIEW
    }),
    [control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting]
  );

  const { renderField } = useProxyFields({ proxyId, isCitizenshipFieldRequired: isCitizenshipFieldVisible, reset });

  return (
    <FormV2Context.Provider value={formValues}>
      <Dialog
        title={t('proxy:action.details.dialogTitle')}
        cancelText={t('action.close')}
        onCancel={onCloseDialog}
        dialogSize="medium"
        isFullScreen={isSmallScreen}
        isOpen={isOpen}
      >
        <Section isCollapsable isModalSection isHeaderVisible={false}>
          <GridLayout itemProps={{ xs: 6 }}>
            {renderField('name')}
            {renderField('surname')}
            {renderField('typeKey')}
            {renderField('paid')}
            {isCitizenshipFieldVisible && renderField('citizenship')}
            {renderField('authorizations')}
            <GridItem xs={12}>{renderField('notes')}</GridItem>
          </GridLayout>
        </Section>
        <Section title={t('proxy:section.address')} isModalSection>
          <GridLayout itemProps={{ xs: 6 }}>
            {renderField('city')}
            {renderField('street')}
            {renderField('propertyNumber')}
            {renderField('apartmentNumber')}
            {renderField('postCode')}
            {renderField('postCity')}
          </GridLayout>
        </Section>
        <Section title={t('proxy:section.generalInformation')} isModalSection>
          <GridLayout itemProps={{ xs: 6 }}>
            {renderField('created')}
            {renderField('author')}
            {renderField('modified')}
            {renderField('modifier')}
          </GridLayout>
        </Section>
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default ProxyDetailsDialog;
