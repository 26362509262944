import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FilesTable } from '@libs/file';

import { API } from '@libs/domain/api';
import { DomainUIElementEnum } from '@libs/domain/config';

import { ISuspensionDetailsParams } from '../../../../model';

function SuspensionFilesTab() {
  const [t] = useTranslation();
  const { id } = useParams<ISuspensionDetailsParams>();

  return (
    <div style={{ height: 'fit-content' }}>
      <FilesTable
        title={t('applications:list.attachmentsListTitle')}
        api={{
          GET: params => API.suspensionAttachments.getSuspensionAttachmentsPage(id, params),
          CREATE: ({ fileId }) => API.suspensionAttachments.addSuspensionAttachment(id, { fileId }),
          DELETE: ({ recordId }) => API.suspensionAttachments.deleteSuspensionAttachment(id, recordId)
        }}
        tableActionKeys={[
          DomainUIElementEnum.SUSPENSIONS_FILES_DOWNLOAD_BUTTON,
          DomainUIElementEnum.SUSPENSIONS_FILES_DELETE_BUTTON
        ]}
        actionKey={DomainUIElementEnum.SUSPENSIONS_FILES_UPLOAD_BUTTON}
        isSection
        isCollapsable
        isRefreshEnabled
        rowActionIds={{
          download: DomainUIElementEnum.SUSPENSIONS_FILES_DOWNLOAD_BUTTON,
          delete: DomainUIElementEnum.SUSPENSIONS_FILES_DELETE_BUTTON
        }}
      />
    </div>
  );
}

export default SuspensionFilesTab;
