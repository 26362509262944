import { AssociationSubjectCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function addAssociationSubject(params: AssociationSubjectCreateRequest & { associationId: string }) {
  const { associationId, ...restParams } = params;
  return API.association.createAssociationSubject(associationId, restParams, {
    ...endpointsConfig.createAssociationSubject
  });
}

export default function useAddAssociationSubjectMutation() {
  return useMutation(addAssociationSubject);
}
