import { Context, FocusEvent, useContext, useEffect } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { DateView } from '@mui/x-date-pickers';
import moment from 'moment';

import {
  Chip,
  ComponentErrorBoundary,
  DatepickerInput,
  FormErrorType,
  FormV2Context,
  FormV2ContextState,
  getInputMode,
  InputMode,
  PaletteOption,
  PaletteOptions,
  Value
} from '@libs/common/v2';
import { getValue } from '@libs/common/v2/utils';

import { DatePickerFieldValidation, FieldTypeEnum, useFieldValidationHandler } from '../../validation';

export interface IDatepickerFieldProps {
  initialValue?: string | null;
  name: FieldPath<FieldValues>;
  label?: string;
  tooltip?: string;
  minDate?: any;
  maxDate?: any;
  format?: string;
  displayFormat?: string;
  formContext?: Context<FormV2ContextState>;
  onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  viewModeDateParser?: (date: string, format?: string) => string;
  inputClassName?: string;
  valueClassName?: string;
  textInputClassName?: string;
  inputMode?: InputMode;
  isFreeToFill?: boolean;
  isFullWidth?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isClearable?: boolean;
  validation?: DatePickerFieldValidation;
  views?: DateView[];
  isDateTimePicker?: boolean;
  isChipValue?: boolean;
  chipColor?: keyof PaletteOptions;
  chipColorWeight?: keyof PaletteOption;
  helperText?: string;
  hasErrorTooltip?: boolean;
}

function DatepickerField({
  initialValue,
  name,
  label,
  tooltip,
  minDate,
  maxDate,
  inputClassName,
  valueClassName,
  inputMode,
  textInputClassName,
  format,
  displayFormat,
  formContext = FormV2Context,
  onBlur: customOnBlur,
  viewModeDateParser,
  isFreeToFill = true,
  isDisabled,
  isRequired,
  isClearable,
  isFullWidth = true,
  validation,
  views,
  isDateTimePicker = false,
  isChipValue,
  chipColor = 'primary',
  chipColorWeight = '500',
  helperText,
  hasErrorTooltip
}: IDatepickerFieldProps) {
  const { control, isSubmitting, formMode, loading, trigger, setValue } = useContext<FormV2ContextState>(formContext);
  const mode = getInputMode(formMode, inputMode);

  const validate = async () => {
    await trigger(name);
  };

  useFieldValidationHandler({
    fieldName: name,
    validation,
    fieldType: FieldTypeEnum.DATETIME
  });

  useEffect(() => {
    if (initialValue) {
      setValue(name, initialValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  return (
    <ComponentErrorBoundary componentName={label || name || 'DatepickerField'}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { error } }) =>
          mode === InputMode.FORM ? (
            <DatepickerInput
              onBlur={e => {
                customOnBlur?.(e);
                validate();
              }}
              value={!!value && typeof value !== 'string' ? moment([value]).format('YYYY-MM-DD') : value}
              inputClassName={inputClassName}
              onChange={newDate => {
                const getDate = () => {
                  if (views && typeof newDate !== 'string' && newDate) {
                    return views[0] === 'year' ? newDate.year() : newDate.month();
                  }
                  return newDate;
                };

                onChange(getDate());
                validate();
              }}
              maxDate={maxDate}
              textInputClassName={textInputClassName}
              minDate={minDate}
              isClearable={isClearable}
              format={format}
              displayFormat={displayFormat}
              label={label}
              views={views}
              isFreeToFill={isFreeToFill}
              isError={!!error && error?.type !== FormErrorType.WARNING}
              isWarning={error?.type === FormErrorType.WARNING || !!helperText}
              helperText={error?.message || helperText}
              isFullWidth={isFullWidth}
              isRequired={isRequired || Boolean(validation?.required)}
              isDisabled={isDisabled || isSubmitting}
              tooltip={tooltip}
              isDateTimePicker={isDateTimePicker}
              hasErrorTooltip={hasErrorTooltip}
            />
          ) : (
            <Value
              label={label}
              isLoading={loading}
              className={valueClassName}
              isError={!!error && error?.type !== FormErrorType.WARNING}
              isWarning={error?.type === FormErrorType.WARNING || !!helperText}
              helperText={error?.message || helperText}
            >
              {!loading && (
                <>
                  {isChipValue ? (
                    <Chip
                      title={getValue(
                        viewModeDateParser ? viewModeDateParser(value as string, format) : (value as string)
                      )}
                      color={chipColor}
                      colorWeight={chipColorWeight}
                    />
                  ) : (
                    getValue(viewModeDateParser ? viewModeDateParser(value as string, format) : (value as string))
                  )}
                </>
              )}
            </Value>
          )
        }
      />
    </ComponentErrorBoundary>
  );
}

export default DatepickerField;
