import { useQuery } from 'react-query';

import { API } from '@libs/domain/api';
import { SocialCommissionQueryKeysEnum } from '@libs/domain/social-commission/config';

import { endpointsConfig } from '../endpoints-config';

export const getTradeRulesDetails = async (_, params) => {
  const { data } = await API.civicBoard.getCivicBoardRule(params, {
    ...endpointsConfig.getCivicBoardRule
  });

  return data;
};

function useTradeRulesDetailsQuery(param: string, queryConfig = {}) {
  return useQuery([SocialCommissionQueryKeysEnum.TRADE_RULES_DETAILS, param], getTradeRulesDetails, queryConfig);
}

export default useTradeRulesDetailsQuery;
