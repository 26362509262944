import { useEffect, useMemo } from 'react';
import { FieldPath, FieldValues, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { DataSourceMediatorColumn } from '@stack/report/dist/models';
import clsx from 'clsx';
import _ from 'lodash';

import { AutocompleteSelectField, IconButton, SelectOption, TextInputField, useFormV2Context } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';
import { Theme } from '@libs/common/v2/theme';

import ConditionOperatorSelect from './ConditionOperatorSelect';

interface IProps {
  fieldName?: FieldPath<FieldValues>;
  index?: number;
  columns?: DataSourceMediatorColumn[];
  isParameterizable?: boolean;
  onConditionRemove?: (index) => void;
}

export function SingleConditionForm({ fieldName, index, columns, isParameterizable, onConditionRemove }: IProps) {
  const [t] = useTranslation();
  const classes = useStyles();
  const { control, setValue, watch, formMode } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);

  const operator = watch(`${fieldName}.operator`);
  const paramNumber = operator?.paramNumber;
  const parameterName = watch(`${fieldName}.parameter.displayName`);
  const parameterValue = watch(`${fieldName}.parameter.value`);
  const column = useWatch({ control, name: `${fieldName}.column` });
  const parameterExtras = watch('parameterExtras');

  const columnOptions: SelectOption[] = useMemo(
    () =>
      columns.map(column => ({
        id: column.name,
        name: column.displayName,
        type: column.type,
        value: column.name
      })),
    [columns]
  );

  const parameterOptions = useMemo<
    {
      name: string;
      value: unknown;
    }[]
  >(() => {
    return parameterExtras
      ?.filter(parameter => {
        const parameterType = _.isString(parameter?.type?.value) ? parameter.type.value : parameter.type.type;
        return !column || parameterType === column?.type;
      })
      ?.map(param => {
        return { name: param.displayName, value: param.displayName };
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column, column?.type, parameterExtras]);

  useEffect(() => {
    if (paramNumber === 0) {
      setValue(`${fieldName}.parameter.displayName`, null);
      setValue(`${fieldName}.parameter.value`, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operator]);

  useEffect(() => {
    if (parameterName) {
      const parameterNameKey = _.isString(parameterName) ? parameterName : parameterName.value;
      const parameterIndex = parameterOptions?.findIndex(({ name }) => name === parameterNameKey);
      if (parameterIndex < 0 && _.size(parameterOptions)) {
        setValue(`${fieldName}.parameter.displayName`, null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column, fieldName, parameterOptions]);

  const displayNameFieldName = useMemo(() => {
    return `${fieldName}.parameter.displayName`;
  }, [fieldName]);

  const valueFieldName = useMemo(() => {
    return `${fieldName}.parameter.value`;
  }, [fieldName]);

  const isValueFieldRequired = paramNumber !== 0 && !parameterName;

  return (
    <div className="w-full">
      <div className="flex items-center flex-row justify-between w-full">
        <div className={classes.conditionRow}>
          <AutocompleteSelectField
            name={`${fieldName}.column`}
            label={t('alerts:condition.column')}
            isDisabled={!columnOptions.length}
            options={columnOptions}
            validationSingleSelect={{ required: [] }}
            isRequired
          />
          <ConditionOperatorSelect fieldName={fieldName} />
          {isParameterizable && (
            <AutocompleteSelectField
              name={displayNameFieldName}
              label={t('alerts:condition.paramName')}
              options={parameterOptions}
              isDisabled={parameterValue || paramNumber === 0}
              isRequired={paramNumber !== 0 && !parameterValue}
              key={`${fieldName}.parameter.displayName`}
              isClearable
            />
          )}

          <TextInputField
            name={valueFieldName}
            validation={isValueFieldRequired && { required: [] }}
            label={t('alerts:condition.value')}
            isDisabled={paramNumber === 0 || parameterName}
            isRequired={isValueFieldRequired}
            key={`${fieldName}.parameter.value` as FieldPath<FieldValues>}
          />
        </div>
        {!viewMode && (
          <div className="flex items-center self-end">
            <div className={clsx('flex items-center', classes.actionButtons)}>
              <IconButton
                aria-label={t('global:action.delete')}
                icon="TrashIcon"
                iconColor="grey"
                onClick={() => onConditionRemove(index)}
                className={clsx(classes.iconButton)}
                isBackgroundTransparent
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  conditionRow: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > *': {
      maxWidth: 160,
      minWidth: 160,
      marginTop: '8px !important',
      marginBottom: '8px !important',
      marginRight: 16
    }
  },
  iconButton: {
    padding: 9,
    position: 'relative',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey['100']
    }
  },
  actionButtons: {
    marginBottom: '2px !important'
  }
}));
