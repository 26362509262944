import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { DefaultTheme, styled } from '@mui/styles';
import clsx from 'clsx';

import { Icon, IconType } from '@libs/common/v2';
import { important } from '@libs/common/v2/utils';

import { ThemeVariant } from '../themeVariants';
import Typography from '../typography/Typography';

interface IProps {
  children?: string | React.ReactNode;
  themeVariant?: ThemeVariant;
  customStyles?: React.CSSProperties & { [_: string]: unknown };
  component?: React.ElementType<any>;
  typographyClassName?: string;
  icon: IconType;
  iconClassName?: string;
  typographyIconClassName?: string;
  isWhiteText?: boolean;
}

function TypographyIcon({
  icon,
  children,
  themeVariant = 'textSm.medium',
  iconClassName,
  typographyIconClassName,
  ...forwardProps
}: IProps) {
  const [variant, style] = themeVariant.split('.');
  const theme = useTheme<DefaultTheme>();

  const CustomIcon = useMemo(
    () =>
      styled(Icon)((iconProps: any) => ({
        height: important(iconProps.theme.typography[variant][style].fontSize),
        width: important(iconProps.theme.typography[variant][style].fontSize),
        marginRight: 12
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [iconClassName, icon, theme]
  );

  return (
    <div className={clsx('flex justify-center items-center', typographyIconClassName)}>
      <CustomIcon className={iconClassName} icon={icon} theme={theme} />
      <Typography themeVariant={themeVariant} {...forwardProps}>
        {children}
      </Typography>
    </div>
  );
}
export default TypographyIcon;
