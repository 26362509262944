import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { TableLayout } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { useResourceObjectPoolsTable } from '../../../hooks';
import { DepotTypes, ResourceDetailsParams } from '../../../model';
import { resourceObjectPoolsParser } from '../../../utils';

interface IProps {
  code: DepotTypes;
}

function ResourceObjectPoolsTable({ code }: IProps) {
  const [t] = useTranslation();
  const { depotId } = useParams<ResourceDetailsParams>();

  const tableProps = useResourceObjectPoolsTable({ depotId, code });

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('resource:list.listOfResourceDetails')}
      isActionColumnEnabled={false}
      xlsxDownload={{
        fileName: t('resource:list.listOfResourceDetails'),
        pathToXLSXTranslation: 'resource:fields',
        apiRequest: params =>
          API.resourceObjectPools
            .getResourceObjectPoolSnapshot(params)
            .then(res => ({ ...res, data: { ...res.data, content: resourceObjectPoolsParser(res.data.content) } }))
      }}
      isSectionFullTab
    />
  );
}

export default ResourceObjectPoolsTable;
