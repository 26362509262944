import { ResourceAssignmentEkmtCarnetUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const generateEkmtCarnet = async ({
  generatePermissionRequest
}: {
  generatePermissionRequest: ResourceAssignmentEkmtCarnetUpdateRequest;
}) => {
  const data = await API.printouts.updateResourceAssignmentEkmtCarnet(generatePermissionRequest, {
    ...endpointsConfig.generatePermissions
  });
  return data;
};

function useGenerateEkmtCarnetMutation() {
  return useMutation(generateEkmtCarnet);
}

export default useGenerateEkmtCarnetMutation;
