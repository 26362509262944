import { object as YupObject, ObjectSchema, Ref, Schema } from 'yup';

import { Field } from '@libs/meta-form/models';

import createValidationRegistry from './validation-registry';
import { FieldValidationMapConfig, YupValidationRegistry } from './validation-registry-model';

/**
 * Funkcja zwraca listę fieldów które mają przekazany obiekt "yupValidation" w properties, czyli fieldy które mają być dodane do YupScheme
 */
export const getFieldsWithValidation = ({ fieldIds, fields }: { fieldIds: string[]; fields: Field[] }): Field[] => {
  return fieldIds?.reduce((acc, curr) => {
    const newValue = [].concat(acc);
    const node = fields.find(item => item.fieldId === curr);
    const hasYupValidation = Boolean(node?.properties?.yupValidation);
    return hasYupValidation ? newValue.concat(node) : newValue;
  }, []);
};
/**
 * Funkcja tworzy YupScheme na podstawie przekazanych fieldów, dopasowuje klucze przekazane w obiekcie "yupValidation" w properties fielda
 * do reguł zdefiniowanych w "validationRegistry" dla danego typu fielda. Istnieje możliwość zdefiniowania funkcji zwracającej regułę walidacji
 * wtedy jako parametry zostaną przekazane wartości pod danym kluczem w "yupValidation"
 */
export const metaFormValidationSchemaBuilder = ({
  fields,
  validators = createValidationRegistry(),
  extendYupScheme = YupObject(),
  customFieldsValidationTypeMap
}: {
  fields: Field[];
  validators?: YupValidationRegistry;
  extendYupScheme?: ObjectSchema;
  customFieldsValidationTypeMap: {
    [fieldId: string]: FieldValidationMapConfig;
  };
}) =>
  fields.reduce((acc, curr) => {
    const fieldConfig = customFieldsValidationTypeMap[curr.fieldId];
    const isMultiple = curr?.properties?.multiple || fieldConfig?.multiple;
    const typeKey = fieldConfig?.type;
    if (fieldConfig?.ignore) {
      return acc;
    }
    if (!typeKey) {
      // eslint-disable-next-line no-console
      console.error(
        `Component fieldId: ${curr.fieldId} is not supporting yupValidation properties. Check meta-form yupValidation section how to handle validation in additional component.`
      );
      return acc;
    }

    const shape = acc.shape({
      [curr?.fieldId]: Object.entries(curr?.properties?.yupValidation).reduce(
        (accValidation, [validatorName, params]) => {
          const schema = isMultiple
            ? validators?.multiple?.[typeKey]?.[validatorName]
            : validators?.[typeKey]?.[validatorName];
          if (!schema) {
            // eslint-disable-next-line no-console
            console.error('Validator not found:', { validatorName, fieldId: curr.fieldId });
            return accValidation;
          }
          return typeof schema === 'function' ? accValidation.concat(schema(params)) : accValidation.concat(schema);
        },
        isMultiple
          ? extendYupScheme?.fields?.[curr?.fieldId] || validators?.multiple?.[typeKey]._base
          : extendYupScheme?.fields?.[curr?.fieldId] || validators?.[typeKey]?._base
      ) as Ref | Schema<unknown>
    });
    return shape;
  }, extendYupScheme);
