import { ResourceTypeCreateRequest, ResourceTypeDetails, ResourceTypeUpdateRequest } from '@ibtm/domain';
import { LimitType } from '@ibtm/domain/dist/models/limit-type';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import { getResourceTypes } from '../../../api';
import { IResourceTypeFormFields } from '../../../model';

export const parseResourceFormFieldsToResourceCreateRequest = (formData: IResourceTypeFormFields) => {
  const { ekmtType, formType } = formData;

  const isSinglePermit = formType?.value === DomainDictionaryEntry.RESOURCE_FORM_TYPE.SINGLE_PERMIT;
  const isEkmtPermit = formType?.value === DomainDictionaryEntry.RESOURCE_FORM_TYPE.EKMT_PERMIT;

  return {
    groupKey: formData.formGroup.value,
    typeKey: formData.formType.value,
    documentTemplateKeys: formData.documentTemplate ? [formData.documentTemplate.value] : [],
    year: formData.year,
    countryKey: formData?.country?.value,
    euroClassKeys:
      (isSinglePermit || isEkmtPermit) && formData?.euroClass
        ? formData?.euroClass?.map(euroClass => euroClass?.value)
        : undefined,
    nameKey: formData.name.value,
    insuredValue: formData.insuredValue,
    isLeadingZeros: formData.isLeadingZeros,
    requiredLength: formData.requiredLength,
    isPrefix: formData.isPrefix,
    ...(formData?.limitType ? { limitType: formData?.limitType?.value as LimitType } : { limitType: null }),
    prefix: formData.prefix,
    ...(formData?.transitTypeKey?.value ? { transitTypeKey: formData?.transitTypeKey?.value } : {}),
    ekmtType: formData?.ekmtType,
    ekmtEuroClass: ekmtType ? formData?.ekmtEuroClass?.value : undefined,
    ekmtGroup: ekmtType ? formData.ekmtGroup?.value : undefined,
    ekmtCategoryKey: formData?.ekmtCategoryKey?.value,
    cargoTransportationExclusions: formData.cargoTransportationExclusions,
    parentResourceTypeId: formData.parentResourceType?.id
  } satisfies ResourceTypeCreateRequest;
};

export const parseResourceFormFieldsToResourceUpdateRequest = (formData: IResourceTypeFormFields) => {
  return {
    ...parseResourceFormFieldsToResourceCreateRequest(formData),
    version: formData.version
  } satisfies ResourceTypeUpdateRequest;
};

const getOptionValue = <T>(value: T) => {
  return value ? { value } : null;
};
export const useParseResourceTypeToResourceTypeFormData = () => {
  const { translate } = useDictionaryTranslations();

  return {
    parseResourceTypeToResourceTypeFormData: (resourceType: ResourceTypeDetails) =>
      ({
        id: resourceType.id,
        name: getOptionValue(resourceType.nameKey),
        formType: getOptionValue(resourceType.typeKey),
        version: resourceType.version,
        year: resourceType.year,
        prefix: resourceType.prefix,
        country: getOptionValue(resourceType.countryKey),
        ekmtType: resourceType.ekmtType,
        isPrefix: Boolean(resourceType.prefix),
        euroClass: resourceType.euroClassKeys?.map(euroClass => ({ value: euroClass })) || [],
        formGroup: getOptionValue(resourceType.groupKey),
        limitTypes: getOptionValue(resourceType.limitType),
        insuredValue: resourceType.insuredValue,
        isLeadingZeros: resourceType.leadingZeros,
        requiredLength: resourceType.requiredLength,
        transitTypeKey: getOptionValue(resourceType.transitTypeKey),
        limitType: getOptionValue(resourceType.limitType),
        ekmtGroup: getOptionValue(resourceType.ekmtGroup),
        ekmtCategoryKey: getOptionValue(resourceType.ekmtCategoryKey),
        ekmtEuroClass: getOptionValue(resourceType.ekmtEuroClass),
        documentTemplate: getOptionValue(resourceType.documentTemplateKeys?.[0]),
        cargoTransportationExclusions: resourceType.cargoTransportationExclusions,
        parentResourceType: resourceType.parentResourceType
          ? {
              id: resourceType.parentResourceType.id,
              name: translate(DomainDictionaryEnum.RESOURCE_FORM_NAME, resourceType.parentResourceType.nameKey)
            }
          : null
      } satisfies IResourceTypeFormFields)
  };
};

export type DuplicateResourceTypeSearchParams = Pick<IResourceTypeFormFields, 'year' | 'name' | 'formType'>;
export const fetchResourceTypesDuplicates = async ({ formType, name, year }: DuplicateResourceTypeSearchParams) => {
  if (!formType?.value || !name?.value || !year) {
    return [];
  }
  const results = await getResourceTypes({
    typeKeyFilter: {
      values: [formType.value]
    },
    nameKeyIn: [name.value],
    yearGreaterThanOrEqual: year,
    yearLessThanOrEqual: year
  });
  return results.content;
};
