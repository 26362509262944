import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Hooks } from 'react-table';
import clsx from 'clsx';
import _ from 'lodash';

import { TFunction } from '@libs/common';
import {
  Checkbox,
  DropdownButton,
  ISpecialColumnEnum,
  MultipleSelectColumnPlugin,
  useTableAdapter,
  useTableContext,
  useTableSelectRowsContext
} from '@libs/common/v2';
import { useTheme } from '@libs/common/v2/theme';

import multiSelectStyles from './multiple-select-styles';

function getMultipleSelectColumn({
  selectionActionRender,
  t,
  checkboxTooltipTitles,
  isRowDisabled,
  alwaysVisible = false,
  hasNoActionButtons = false
}: {
  selectionActionRender: (selectedIds: string[], handleClose: () => void) => React.ReactNode;
  t: TFunction;
  alwaysVisible?: boolean;
  checkboxTooltipTitles?: ICheckboxTooltipTitles;
  hasNoActionButtons?: boolean;
  isRowDisabled?: (row: Record<string, any>) => boolean;
}): Column {
  const { disabledCheckboxTooltipTitle, enabledCheckboxTooltipTitle, headerCheckboxTooltipTitle } =
    checkboxTooltipTitles ?? {};
  return {
    id: ISpecialColumnEnum.SELECTION,
    Header: ({ rows }) => {
      const { selectedRows, isSelectAllIndeterminate, toggleSelectedRows, isSelectAllChecked } =
        useTableSelectRowsContext();
      const originalRows = rows.map(row => row.original);
      const rowsCount = originalRows.length;
      const table = useTableAdapter();
      const { checkDisabled, isError, isLoading, isFetching } = useTableContext();
      const isEmpty = _.isEmpty(selectedRows);

      const classes = multiSelectStyles({ isEmpty: alwaysVisible || !isEmpty });

      return (
        <div className={clsx('flex items-center pl-2', classes.header)}>
          <Checkbox
            title={headerCheckboxTooltipTitle || t('other:component.table.body.checkAllRows')}
            onClick={e => e.stopPropagation()}
            onChange={(_, checked) => {
              toggleSelectedRows(originalRows, checked);
            }}
            inputProps={{
              'aria-label': t('other:component.table.body.checkAllRows')
            }}
            isChecked={isSelectAllChecked(rowsCount)}
            isDisabled={isLoading || isFetching || checkDisabled(table) || isError}
            isIndeterminate={isSelectAllIndeterminate(rowsCount)}
          />
        </div>
      );
    },
    SelectActions: () => {
      const { contrast } = useTheme();
      const { selectedRows } = useTableSelectRowsContext();
      const selectedIds = Object.keys(selectedRows);
      const isEmpty = _.isEmpty(selectedRows);

      const classes = multiSelectStyles({ isEmpty: alwaysVisible || !isEmpty, contrast });

      return !hasNoActionButtons ? (
        <DropdownButton
          buttonType="button"
          isNoMargin
          title={t('global:action.executeAction')}
          forceRender
          isSecondary
          variant="outlined"
          classNames={{ button: classes.button, iconButton: classes.iconButton }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          {({ handleClose }) => {
            return selectionActionRender(selectedIds, handleClose);
          }}
        </DropdownButton>
      ) : (
        selectionActionRender(selectedIds, () => null)
      );
    },
    Cell: ({ row }) => {
      const table = useTableAdapter();
      const { checkDisabled, isError, isLoading, isFetching } = useTableContext();
      const classes = multiSelectStyles({});

      const { toggleSelectedRow, isRowSelected } = useTableSelectRowsContext();
      return (
        <div className={classes.cell}>
          <span className="flex items-center pl-2 h-full">
            <Checkbox
              title={
                checkDisabled(table, row) || isError
                  ? disabledCheckboxTooltipTitle || t('other:component.table.body.checkRowDisabled')
                  : enabledCheckboxTooltipTitle || t('other:component.table.body.checkRow')
              }
              onClick={e => e.stopPropagation()}
              isChecked={isRowSelected(row.original)}
              onChange={(_, checked) => {
                toggleSelectedRow(row.original, checked);
              }}
              inputProps={{
                'aria-label': t('other:component.table.body.checkRow')
              }}
              isDisabled={isLoading || isFetching || checkDisabled(table, row) || isError || isRowDisabled?.(row)}
            />
          </span>
        </div>
      );
    },
    disableSortBy: true
  };
}

interface ICheckboxTooltipTitles {
  headerCheckboxTooltipTitle?: string;
  enabledCheckboxTooltipTitle?: string;
  disabledCheckboxTooltipTitle?: string;
}

export function useMultipleSelectColumnWithSelectRowContext(
  selectionActionRender: (selectedIds: string[], handleClose: () => void) => React.ReactNode | null,
  alwaysVisible = false,
  checkboxTooltipTitles?: ICheckboxTooltipTitles,
  hasNoActionButtons = false
) {
  const [t] = useTranslation();
  const multipleSelectColumn = <T extends Record<string, any> = Record<string, unknown>>(hooks: Hooks<T>) => {
    hooks.visibleColumns.push(
      columns =>
        [
          getMultipleSelectColumn({
            selectionActionRender,
            t,
            alwaysVisible,
            checkboxTooltipTitles,
            hasNoActionButtons
          }),
          ...columns
        ] as any
    );
  };

  multipleSelectColumn.pluginName = MultipleSelectColumnPlugin.useMultipleSelectColumnWithSelectRowContext;

  return multipleSelectColumn;
}
