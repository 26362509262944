import { usePaginatedQuery } from 'react-query';
import { InvoiceSearchFilter, InvoiceSnapshotPage } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';
import { ReleaseDocumentsQueryKeysEnum } from '../index';

const getReleaseDocuments = async (_, params: InvoiceSearchFilter) => {
  const { data } = await API.releaseDocuments.getInvoicesSnapshotPage(params, {
    ...endpointsConfig.getInvoicesSnapshotPage
  });

  return data;
};

export default function useReleaseDocumentsQuery(params: InvoiceSearchFilter, queryConfig = {}) {
  return usePaginatedQuery<InvoiceSnapshotPage>(
    [ReleaseDocumentsQueryKeysEnum.RELEASE_DOCUMENTS_LIST, params],
    getReleaseDocuments,
    queryConfig
  );
}
