import { useDialog } from '@libs/common/v2';

import ExploitationBaseSearchDialog from '../components/dialog/ExploitationBaseSearchDialog';

interface IProps {
  folderId: string;
  transferredFoldersIds?: string[];
  applicationId: string;
}

export function useExploitationBaseSearchAction({ folderId, transferredFoldersIds, applicationId }: IProps) {
  const { openDialog } = useDialog();
  const openExploitationBaseSearchDialog = () =>
    openDialog(({ closeDialog }) => (
      <ExploitationBaseSearchDialog
        closeDialog={closeDialog}
        folderId={folderId}
        transferredFoldersIds={transferredFoldersIds}
        applicationId={applicationId}
      />
    ));

  return { openExploitationBaseSearchDialog };
}
