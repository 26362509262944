import { MutationConfig, MutationFunction, useMutation as useMutationReactQuery } from 'react-query';

interface MutateConfigCustom<TResult, TError = unknown, TVariables = undefined, TSnapshot = unknown> {
  onSuccess?: (data: TResult, variables: TVariables) => void;
  onError?: (error: null, variables: TVariables, snapshotValue: TSnapshot) => void;
  // error:null - jakiś problem z axiosem ? https://stackoverflow.com/questions/47679543/axios-400-error-request-call-then-instead-of-catch
  onSettled?: (
    data: undefined | TResult,
    error: TError | null,
    variables: TVariables,
    snapshotValue?: TSnapshot
  ) => void;
}

// hook przygotowujacy do przejscia na react query v3
function useMutation<TResult, TError = unknown, TVariables = undefined, TSnapshot = unknown>(
  mutationFn: MutationFunction<TResult, TVariables>,
  config?: MutationConfig<TResult, TError, TVariables, TSnapshot>
) {
  const [mutationFunction, mutationResult] = useMutationReactQuery(mutationFn, config);
  const mutate = (
    variables?: TVariables,
    mutateConfig?: MutateConfigCustom<TResult, TError, TVariables, TSnapshot>
  ) => {
    callMutation(variables, mutateConfig);
  };
  const callMutation = async (
    variables?: TVariables,
    mutateConfig?: MutateConfigCustom<TResult, TError, TVariables, TSnapshot>
  ) => {
    let result: Awaited<TResult>;
    let errorFinally: TError;
    let errorAlreadyCalled = false;
    try {
      result = await mutationFunction(variables);
      if (mutateConfig?.onSuccess && result !== undefined) {
        mutateConfig.onSuccess(result, variables);
      } else if (mutateConfig?.onError && !result) {
        mutateConfig.onError(null, variables, result as any);
        errorAlreadyCalled = true;
      }
    } catch (error) {
      errorFinally = error as TError;
      if (mutateConfig?.onError && !errorAlreadyCalled) {
        mutateConfig.onError(null, variables, result as any);
      }
    } finally {
      if (mutateConfig?.onSettled) {
        mutateConfig.onSettled(result, errorFinally, variables);
      }
    }
  };
  return { mutate, mutateAsync: mutationFunction, ...mutationResult };
}

export default useMutation;
