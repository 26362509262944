import { DepotManagerUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function deleteDepotAdmins(params: DepotManagerUpdateRequest) {
  return API.depotManagers.removeDepotManagers(params, {
    ...endpointsConfig.createDepotManager
  });
}

function useDeleteDepotAdminsMutation() {
  return useMutation(deleteDepotAdmins);
}

export default useDeleteDepotAdminsMutation;
