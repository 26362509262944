import { IRoutesElementMapper } from '@libs/common/v2/router';

import { IAlertRoutes } from '@libs/alert';
import { AlertRoutes } from '@libs/alert/config';
import { AuthRoutes } from '@libs/auth/configs';
import { IAuthRoutes } from '@libs/auth/models';
import { IDictionaryRoutes } from '@libs/dictionary';
import { DictionaryRoutes } from '@libs/dictionary/configs';
import { GroupRoutes } from '@libs/group/config';
import { IGroupRoutes } from '@libs/group/models';
import { NotificationRoutes } from '@libs/notification/config/routes.config';
import { INotificationRoutes } from '@libs/notification/models';
import { OrganizationStructureRoutes } from '@libs/organization-structure/config';
import { IOrganizationStructureRoutes } from '@libs/organization-structure/models';
import { IReportRoutes } from '@libs/report';
import { ReportRoutes } from '@libs/report/config';
import { IUserRoutes } from '@libs/user';
import { UserRoutes } from '@libs/user/config';

import { DomainRoutes } from '@libs/domain/config';
import { IDomainRoutes } from '@libs/domain/models';

export const RoutesElementMapper: IRoutesElementMapper = {
  ...UserRoutes,
  ...DomainRoutes,
  ...ReportRoutes,
  ...AlertRoutes,
  ...OrganizationStructureRoutes,
  ...NotificationRoutes,
  ...GroupRoutes,
  ...DictionaryRoutes,
  ...AuthRoutes
};

export interface IRoutes
  extends IDomainRoutes,
    IAuthRoutes,
    IDictionaryRoutes,
    IGroupRoutes,
    INotificationRoutes,
    IOrganizationStructureRoutes,
    IReportRoutes,
    IAlertRoutes,
    IUserRoutes {}
