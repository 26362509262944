import { ForeignPermissionReturnRequest } from '@ibtm/client-domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

export interface IForeignPermissionReturnRequest extends ForeignPermissionReturnRequest {
  xSubjectId?: string;
  xFolderId?: string;
}

function returnPermission({ xSubjectId, xFolderId, ...params }: IForeignPermissionReturnRequest) {
  return API.client.foreignPermissions.returnForeignPermissions('', '', params, {
    ...endpointsConfig.returnForeignPermissions
  });
}

function useReturnForeignClientPermission() {
  return useMutation(returnPermission);
}

export default useReturnForeignClientPermission;
