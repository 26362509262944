export enum ValidatorEnums {
  CONTACT_NAME = 'CONTACT_NAME',
  USER_FIRST_NAME = 'USER_FIRST_NAME',
  USER_SURNAME = 'USER_SURNAME',
  VOUCHER_CODE = 'VOUCHER_CODE',
  PASSWORD_RESET_CODE = 'PASSWORD_RESET_CODE',
  ADDRESS_STREET = 'ADDRESS_STREET',
  EMAIL = 'EMAIL',
  ADDRESS_CITY = 'ADDRESS_CITY',
  ADD_FUNDS_AMOUNT = 'ADD_FUNDS_AMOUNT',
  CONTACT_MESSAGE = 'CONTACT_MESSAGE',
  LOYALTY_CODE = 'LOYALTY_CODE',
  ADDRESS_POSTAL_CODE = 'ADDRESS_POSTAL_CODE',
  PASSWORD = 'PASSWORD',
  NIP = 'NIP',
  HELP_QUERY = 'HELP_QUERY',
  DRIVING_LICENSE_SERIAL_NUMBER = 'DRIVING_LICENSE_SERIAL_NUMBER',
  ADDRESS_FLAT = 'ADDRESS_FLAT',
  VIN = 'VIN',
  LOGIN_USERNAME = 'LOGIN_USERNAME',
  PHONE_NUMBER = 'PHONE_NUMBER',
  ADDRESS_HOUSE = 'ADDRESS_HOUSE',
  DRIVING_LICENSE_VALID_UNTIL = 'DRIVING_LICENSE_VALID_UNTIL',
  PESEL = 'PESEL',
  NUMBER = 'NUMBER',
  REGON = 'REGON',
  DOUBLE_PRECISION_FLOAT_NUMBER = 'DOUBLE_PRECISION_FLOAT_NUMBER',
  POSITIVE_LONG = 'POSITIVE_LONG',
  NON_NEGATIVE_LONG = 'NON_NEGATIVE_LONG',
  VEHICLE_PLATE_NUMBER = 'VEHICLE_PLATE_NUMBER',
  NO_POLISH_LETTERS_AND_SYMBOLS = 'NO_POLISH_LETTERS_AND_SYMBOLS',
  SQL_PARAMETER_NAME = 'SQL_PARAMETER_NAME'
}
