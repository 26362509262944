import { FormControlLabel, FormHelperText, Radio as MuiRadio } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { LoaderCircular, useInputStyles } from '@libs/common/v2';
import { Theme, useTheme } from '@libs/common/v2/theme';

import { important } from '../../utils';

interface IProps {
  className?: string;
  onChange?: (e?: any) => void;
  onBlur?: (e?: any) => void;
  inputProps?: Record<string, unknown>;
  label?: string;
  name?: string;
  value?: string;
  helperText?: string;
  isError?: boolean;
  isWarning?: boolean;
  isChecked?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRequired?: boolean;
  checkboxClassName?: string;
  wrapperClassName?: string;
}

function Radio({
  isChecked,
  isLoading,
  isDisabled,
  className,
  isRequired,
  label,
  name,
  value,
  onBlur,
  isError,
  isWarning,
  helperText,
  checkboxClassName,
  wrapperClassName,
  ...restProps
}: IProps) {
  const inputStyles = useInputStyles({ isRequired, isDisabled, isError, isWarning });

  const { contrast } = useTheme();
  const classes = useStyles({ contrast });

  return (
    <div className={clsx(inputStyles.root, classes.labelWrapper, className, wrapperClassName)}>
      <FormControlLabel
        className="ml-0"
        label={
          label && (
            <span>
              {label} {isRequired && <span className={inputStyles.asterisk}>&nbsp;*</span>}
            </span>
          )
        }
        control={
          <LoaderCircular size={20} isLoading={isLoading}>
            <MuiRadio
              size="small"
              color="default"
              aria-label={label}
              classes={{ root: classes.root, checked: classes.checked }}
              className={clsx(className, checkboxClassName)}
              checked={isChecked}
              disabled={isDisabled}
              onBlur={onBlur}
              name={name}
              value={value}
              {...restProps}
            />
          </LoaderCircular>
        }
      />
      {helperText && (
        <FormHelperText className={clsx(inputStyles.formHelperText)} error={isError}>
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
}

const useStyles = makeStyles<Theme, { contrast?: boolean }>(theme => ({
  root: {
    padding: 5,
    '&>span:nth-of-type(1)': {
      outline: ({ contrast }) => contrast && important(`1px solid ${theme.palette.grey[200]}`),
      outlineOffset: ({ contrast }) => contrast && important(`-3px`),
      borderRadius: ({ contrast }) => contrast && '100%'
    }
  },
  checked: {
    color: ({ contrast }) => (contrast ? theme.palette.grey[200] : theme.palette.blue[800])
  },
  labelWrapper: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

export default Radio;
