export function hasPermission(authArr: any[], userRole: any) {
  /**
   * If auth array is not defined
   * Pass and allow
   */
  if (authArr === null || authArr === undefined || typeof authArr === 'function') {
    // console.info("auth is null || undefined:", authArr);
    return true;
  }
  if (authArr.length === 0) {
    /**
     * if auth array is empty means,
     * allow only user role is guest (null or empty[])
     */
    // console.info("auth is empty[]:", authArr);
    return !userRole || userRole.length === 0;
  }
  /**
   * Check if user has grants
   */
  // console.info("auth arr:", authArr);
  /*
    Check if user role is array,
    */
  if (userRole && Array.isArray(userRole)) {
    return !Array.isArray(authArr) || authArr.some(r => userRole.indexOf(r) >= 0);
  }

  /*
    Check if user role is string,
    */
  return authArr.includes(userRole);
}
