import { usePaginatedQuery } from 'react-query';
import { ProceedingSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { ProceedingSnapshotClient } from '../../model';
import { parseProceedingsList } from '../../parser/proceeding.parses';
import { parseProceedingsListPayload } from '../../utils';
import { endpointsConfig, ProceedingQueryKeysEnum } from '..';

const getProceedings = async (_, params: ProceedingSearchFilter) => {
  const queryParams = parseProceedingsListPayload(params);
  const { data } = await API.proceeding.getProceedingsSnapshotPage(queryParams, {
    ...endpointsConfig.getProceedingFormsPage
  });
  return {
    ...data,
    content: parseProceedingsList(data.content)
  };
};

const useProceedingsQuery = (params: ProceedingSearchFilter, queryConfig = {}) =>
  usePaginatedQuery<IPaginatedModel<ProceedingSnapshotClient>>(
    [ProceedingQueryKeysEnum.PROCEEDINGS_LIST, params],
    getProceedings,
    queryConfig
  );

export default useProceedingsQuery;
