import { AssociationSubjectSnapshot } from '@ibtm/domain';

import { CamelCasePath, useTableLayoutProps } from '@libs/common/v2';

import { useAssociationSubjectQuery } from '@libs/domain/entrepreneurs';

import { useAssociationSubjectColumns } from './useAssociationSubjectColumns';

function useAssociationSubjectTable(id: string, visibleColumns: Array<CamelCasePath<AssociationSubjectSnapshot>>) {
  const { columns, mappedFilterFields, mappedSortFields } = useAssociationSubjectColumns(visibleColumns);

  return useTableLayoutProps({
    tableHookQuery: useAssociationSubjectQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    },
    tableHookQueryInitialParams: {
      associationId: id
    }
  });
}

export default useAssociationSubjectTable;
