import { usePaginatedQuery } from 'react-query';
import { SubjectAddressHistorySearchFilter as SubjectAddressHistorySearchFilterClient } from '@ibtm/client-domain';
import { SubjectAddressHistoryDetails, SubjectAddressHistorySearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';
import { FolderQueryKeysEnum } from '..';

interface GetAddressHistoryParams extends SubjectAddressHistorySearchFilter {
  subjectId: string;
}

const getAddressHistory = async (_, { subjectId, ...params }: GetAddressHistoryParams) => {
  const { data } = await API.subject.getSubjectAddressesHistoryPage(subjectId, params, {
    ...endpointsConfig.getSubjectAddressesHistoryPage
  });
  return data;
};

const getAddressHistoryClient = async (_, params: SubjectAddressHistorySearchFilterClient) => {
  const { data } = await API.client.subject.getSubjectAddressesHistoryPage('', '', params, {
    ...endpointsConfig.getSubjectAddressesHistoryPage
  });
  return data;
};

export default function useAddressHistoryQuery(
  params: GetAddressHistoryParams | SubjectAddressHistorySearchFilterClient
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return usePaginatedQuery<IPaginatedModel<SubjectAddressHistoryDetails>>(
    [FolderQueryKeysEnum.ADDRESS_HISTORY_LIST, params],
    getQuery(getAddressHistoryClient, getAddressHistory)
  );
}
