import { ReactNode, useMemo } from 'react';
import { DefaultValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SingleUseSubmissionUpdateReceiptDateRequest } from '@ibtm/domain';

import { FormV2Context, useValidationBuilder } from '@libs/common/v2';

export type DateOfCollectionForm = SingleUseSubmissionUpdateReceiptDateRequest & {
  singleUseSubmissionItemId: string;
};

interface IProps {
  children: ReactNode;
  defaultValues: DefaultValues<DateOfCollectionForm>;
}

export function DateOfCollectionFormProvider({ children, defaultValues }: IProps) {
  const { validationBuilderFunctions, validationScheme } = useValidationBuilder();
  const formMethods = useForm<Partial<DateOfCollectionForm>>({
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationScheme),
    defaultValues
  });

  const formContextValue = useMemo(
    () => ({ ...formMethods, validationBuilderFunctions }),
    [formMethods, validationBuilderFunctions]
  );
  return <FormV2Context.Provider value={formContextValue}>{children}</FormV2Context.Provider>;
}
