import { QueryConfig, useQuery } from 'react-query';
import { FinancialSecurityDetails } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import { FinancialSecurityQueryKeysEnum } from './FinancialSecurityQueryKeysEnum';

const getFinancialSecurityClient = async (_: any, id: string) => {
  const { data } = await API.client.financialSecurity.getFinancialSecurity('', '', id, {
    ...endpointsConfig.getFinancialSecurity
  });
  return data;
};

const getFinancialSecurity = async (_: any, id: string) => {
  const { data } = await API.financialSecurity.getFinancialSecurity(id, {
    ...endpointsConfig.getFinancialSecurity
  });
  return data;
};

export default function useFinancialSecurityQuery(id: string, queryConfig: QueryConfig<any, unknown> = {}) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery<FinancialSecurityDetails>(
    [FinancialSecurityQueryKeysEnum.FINANCIAL_SECURITY, id],
    getQuery(getFinancialSecurityClient, getFinancialSecurity),
    queryConfig
  );
}
