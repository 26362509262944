import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AccountSnapshot, AccountSnapshotStatus } from '@avispon/user/dist/models';
import { yupResolver } from '@hookform/resolvers/yup';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';
import { ObjectSchema } from 'yup';

import { Dialog, DropdownListButton, FormMode, FormV2Context, TableButton, Typography } from '@libs/common/v2';

import { useSuspensionValidation, useUserStatusActionsDefinition } from '@libs/user/hooks';
import { SuspendFormValues, suspendInitialValues } from '@libs/user/models';

import { SuspendUserForm } from '../form';

interface IProps {
  userDetails: AccountSnapshot;
  handleClose: () => void;
  refetchUserDetails: () => void;
  isTableButton?: boolean;
  resumeActionKey: UIElementNameEnum;
  suspendActionKey: UIElementNameEnum;
}

function ChangeUserStatus({
  handleClose,
  userDetails,
  refetchUserDetails,
  isTableButton = true,
  resumeActionKey,
  suspendActionKey
}: IProps) {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const isSuspended = useMemo(() => userDetails?.status === AccountSnapshotStatus.SUSPENDED, [userDetails]);
  const validation = useSuspensionValidation(isSuspended) as ObjectSchema;

  const form = useForm<Record<string, any>>({
    mode: 'onBlur',
    resolver: yupResolver(validation),
    defaultValues: suspendInitialValues
  });

  const formDataProvider = useMemo(() => {
    return { ...form, formMode: FormMode.CREATE };
  }, [form]);

  const { handleChangeUserStatusToSuspend, isSuspendUserLoading, handleChangeUserStatusToResume, isResumeUserLoading } =
    useUserStatusActionsDefinition({
      userDetails,
      refetchUserDetails,
      closeContextMenu: handleClose
    });

  const isLoading = useMemo(
    () => isSuspendUserLoading || isResumeUserLoading,
    [isSuspendUserLoading, isResumeUserLoading]
  );

  const onSubmitSuspension = (data: SuspendFormValues) => {
    const body = data && data;

    return isSuspended ? handleChangeUserStatusToResume() : handleChangeUserStatusToSuspend(body);
  };

  const actionKey = useMemo(() => {
    return isSuspended ? resumeActionKey : suspendActionKey;
  }, [isSuspended, resumeActionKey, suspendActionKey]);

  return (
    <>
      {isTableButton ? (
        <TableButton
          label={isSuspended ? t('user:action.resumeUser') : t('user:action.suspendUser')}
          onClick={() => setIsOpen(!isOpen)}
          actionKey={actionKey}
        />
      ) : (
        <DropdownListButton
          label={isSuspended ? t('user:action.resumeUser') : t('user:action.suspendUser')}
          onClick={() => setIsOpen(!isOpen)}
          actionKey={actionKey}
        />
      )}
      <FormV2Context.Provider value={formDataProvider}>
        <Dialog
          title={isSuspended ? t('user:dialog.resumeUser') : t('user:dialog.suspendUser')}
          confirmText={t('action.confirm')}
          cancelText={t('action.close')}
          onConfirm={form.handleSubmit(data => onSubmitSuspension(data as SuspendFormValues))}
          onCancel={() => setIsOpen(false)}
          confirmButtonType="danger"
          dialogSize="small"
          isConfirmLoading={isLoading}
          isOpen={isOpen}
        >
          {isSuspended ? <Typography>{t('user:action.resumeUser')}</Typography> : <SuspendUserForm />}
        </Dialog>
      </FormV2Context.Provider>
    </>
  );
}
export default ChangeUserStatus;
