import React, { forwardRef } from 'react';
import { Grid, GridProps, GridTypeMap, useTheme } from '@mui/material';

import { GridItem } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';

interface IProps {
  children: React.ReactNode | React.ReactNode[];
  containerProps?: GridProps<GridTypeMap['defaultComponent']>;
  itemProps?: GridProps<GridTypeMap['defaultComponent']>;
}

function GridLayout({ children, containerProps, itemProps }: IProps, ref: React.Ref<HTMLDivElement>) {
  const { spacing } = containerProps ?? {};
  const { gridDefaultSpacing } = useTheme<Theme>();

  return (
    <Grid
      ref={ref}
      container
      className="container-responsive"
      spacing={spacing || gridDefaultSpacing}
      {...containerProps}
    >
      {React.Children.map(children, child => {
        if (
          !React.isValidElement<{
            className: string;
            gridItemProps?: Omit<GridProps<GridTypeMap['defaultComponent']>, 'item'>;
          }>(child)
        ) {
          return null;
        }
        /**
         * jeżeli dla któregoś elementu potrzebujemy innych ustawień niż itemProps, wówczas tworzymy element owrapowany w <GridItem/>
         * który nie będzie już dodatkowo wrapowany przez ten komponent
         *
         * @info gridItemProps dodane na potrzebę obsługi meta-forms
         */
        const { className } = child.props;
        /**
         * Obsługa ConditionallyVisible z meta-form, aby nie renderował pustej komórki w gridzie gdzy zwraca null
         */
        if (
          typeof child.type !== 'string' &&
          (child.type as { displayName?: string }).displayName === 'ConditionallyVisible'
        ) {
          return React.cloneElement(child, { gridItemProps: child.props?.gridItemProps || { ...itemProps } });
        }

        return child.props.gridItemProps || child.type === GridItem ? (
          React.cloneElement(child)
        ) : (
          <Grid item {...itemProps}>
            {React.cloneElement(child, { className: `${className ?? ''} justify-start` })}
          </Grid>
        );
      })}
    </Grid>
  );
}

export default forwardRef(GridLayout);
