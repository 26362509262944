import { useContext } from 'react';

import { AutocompleteSelectField, ICommonAutocompleteProps, SelectOption } from '@libs/common/v2';

import { ListTypeEnum } from '../../../model';
import { DepotTransferDataContext } from '../../../pages/ResourceTransferPage';

type IProps = {
  listType: ListTypeEnum;
  inputProps: Partial<ICommonAutocompleteProps>;
};

function CustomFilter({ inputProps, listType }: IProps) {
  const depotTransferDataContext = useContext(DepotTransferDataContext);
  const { SOURCE, TARGET } = depotTransferDataContext;

  return (
    <AutocompleteSelectField
      name="depot"
      options={listType === ListTypeEnum.SOURCE ? SOURCE.selectOptions : TARGET.selectOptions}
      onChangeCustom={value => {
        const option = value as SelectOption<string>;
        if (listType === ListTypeEnum.SOURCE) {
          SOURCE.setter(option);
        } else {
          TARGET.setter(option);
        }
      }}
      {...inputProps}
    />
  );
}

export default CustomFilter;
