import { FolderLimitSuspendedUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface Params {
  folderLimitId: string;
  updateRequest: FolderLimitSuspendedUpdateRequest;
}

async function updateLimitSuspended({ folderLimitId, updateRequest }: Params) {
  const { data } = await API.folderLimits.updateSuspended(folderLimitId, updateRequest, {
    ...endpointsConfig.updateSuspended
  });

  return data;
}

function useUpdateLimitSuspendedActionMutation() {
  return useMutation(updateLimitSuspended);
}

export default useUpdateLimitSuspendedActionMutation;
