import { useTranslation } from 'react-i18next';

import { NUMBER_OF_AUTOCOMPLETE_RESULTS, SelectWithFetchedOptionsField } from '@libs/common/v2';

import { API } from '@libs/organization-structure/api';

interface IProps {
  fieldName: string;
  label?: string;
  size?: number;
  onChange: () => void;
}

function OrganizationUnitAutocompleteField({ fieldName, label, size, onChange }: IProps) {
  const [t] = useTranslation();

  const getUsersList = (text: string) => {
    return API.organizationStructure.findOrganizationUnit({
      nameFragment: text,
      page: 0,
      size: size || NUMBER_OF_AUTOCOMPLETE_RESULTS,
      sort: []
    });
  };

  return (
    <SelectWithFetchedOptionsField
      fetchFunction={getUsersList}
      getOptionLabel={unit => (unit?.name ? `${unit?.name}` : t('emptyMark'))}
      fetchedValueParser={option => option.id}
      onChangeCustom={onChange}
      label={label}
      name={fieldName}
      isDisabled={false}
      isRequired={false}
      isMultiple={false}
      stringValue
    />
  );
}

export default OrganizationUnitAutocompleteField;
