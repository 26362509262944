import { useTranslation } from 'react-i18next';
import { useAppConfig } from '@libs/app-config';

import { Button, TableButtonColumnPicker } from '@libs/common/v2';

interface IProps {
  canAddGroup?: boolean;
  setModalIsOpen: (value: boolean) => void;
}

function UserGroupCreateFormHeaderActions({ canAddGroup, setModalIsOpen }: IProps) {
  const [t] = useTranslation();
  const { hideColumnPicker } = useAppConfig();

  return (
    <>
      {!hideColumnPicker && <TableButtonColumnPicker />}
      <Button
        label={canAddGroup ? t('user:action.addPermissionGroup') : t('user:dialog.groupAdminPermissionRequired')}
        onClick={() => setModalIsOpen(true)}
        variant="outlined"
        disabled={!canAddGroup}
      />
    </>
  );
}

export default UserGroupCreateFormHeaderActions;
