import { PermissionElementMap } from '@libs/permission';

import { PermissionEnum } from '@libs/domain/config/permission/PermissionEnum';

import OrganizationStructureUIElementEnum from './OrganizationStructureUIElementEnum';

const OrganizationStructurePermissionElementMapper: PermissionElementMap<OrganizationStructureUIElementEnum> = {
  [OrganizationStructureUIElementEnum.ORGANIZATION_STRUCTURE_LIST_TAB]: {
    anyOfPermission: [PermissionEnum.ORGANIZATION_STRUCTURE_VIEW]
  },
  [OrganizationStructureUIElementEnum.ORGANIZATION_STRUCTURE_TREE_VIEW]: {
    anyOfPermission: [PermissionEnum.ORGANIZATION_STRUCTURE_VIEW]
  },
  [OrganizationStructureUIElementEnum.ORGANIZATION_STRUCTURE_LIST_VIEW]: {
    anyOfPermission: [PermissionEnum.ORGANIZATION_STRUCTURE_VIEW]
  },
  [OrganizationStructureUIElementEnum.ORGANIZATION_STRUCTURE_ADD_BUTTON]: {
    anyOfPermission: [PermissionEnum.ORGANIZATION_STRUCTURE_ADMIN]
  },
  [OrganizationStructureUIElementEnum.ORGANIZATION_STRUCTURE_EDIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.ORGANIZATION_STRUCTURE_ADMIN]
  },
  [OrganizationStructureUIElementEnum.ORGANIZATION_STRUCTURE_DETAILS_BUTTON]: {
    anyOfPermission: [PermissionEnum.ORGANIZATION_STRUCTURE_VIEW]
  },
  [OrganizationStructureUIElementEnum.ORGANIZATION_STRUCTURE_DELETE_BUTTON]: {
    anyOfPermission: [PermissionEnum.ORGANIZATION_STRUCTURE_ADMIN]
  },
  [OrganizationStructureUIElementEnum.ORGANIZATION_STRUCTURE_USERS_VIEW]: {
    anyOfPermission: [PermissionEnum.ORGANIZATION_STRUCTURE_VIEW]
  },
  [OrganizationStructureUIElementEnum.ORGANIZATION_STRUCTURE_GROUPS_VIEW]: {
    allOfPermissions: [PermissionEnum.GROUP_VIEW, PermissionEnum.ORGANIZATION_STRUCTURE_VIEW]
  },
  [OrganizationStructureUIElementEnum.ORGANIZATION_STRUCTURE_DELETE_MEMBER_BUTTON]: {
    anyOfPermission: [PermissionEnum.ORGANIZATION_STRUCTURE_ADMIN]
  },
  [OrganizationStructureUIElementEnum.ORGANIZATION_STRUCTURE_ADD_MEMBER_BUTTON]: {
    anyOfPermission: [PermissionEnum.ORGANIZATION_STRUCTURE_ADMIN]
  },
  [OrganizationStructureUIElementEnum.ORGANIZATION_STRUCTURE_CHANGE_UNIT_BUTTON]: {
    anyOfPermission: [PermissionEnum.ORGANIZATION_STRUCTURE_ADMIN]
  },
  [OrganizationStructureUIElementEnum.ORGANIZATION_STRUCTURE_EDIT_USER_BUTTON]: {
    anyOfPermission: []
  }
};

export default OrganizationStructurePermissionElementMapper;
