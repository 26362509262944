import React from 'react';

import NodeRegistry from './nodes/NodeRegistry';
import renderComponent from './ComponentRenderer';
import { Component, Layout, NodeTypes } from './models';

/**
 * funkcja renderująca Node's na podstawie definicji layout w JSON
 * */
export default function renderNodes(nodes: Layout, components: Array<Component>) {
  return nodes
    ? nodes.map((node, index) => {
        const { typeKey, properties } = node;
        // Obsługa components
        if (typeKey === NodeTypes.COMPONENT) {
          return renderComponent(node, components, index);
        }

        // Obsługa pozostałych node-ów
        const fieldId = properties?.fieldId ? `-${properties.fieldId}` : '';
        const nodeKey = `node-${typeKey}${fieldId}-${index}`;

        if (NodeRegistry[typeKey]) {
          return React.createElement(
            // @ts-ignore
            NodeRegistry[typeKey],
            { ...properties, renderNodes, components, key: nodeKey },
            node.nodes ? [...node.nodes] : []
          );
        }
        return null;
      })
    : null;
}
