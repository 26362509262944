import { usePaginatedQuery } from 'react-query';
import { TransportManagerCertificateSnapshot, TransportManagersCertificatesSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { RoadTransportQueryKeysEnum } from '../..';
import { endpointsConfig } from '../../endpoints-config';

export const getTransportManagerCertifiactes = async (params: TransportManagersCertificatesSearchFilter) => {
  const { data } = await API.transportManagerCertificates.getTransportManagersCertificatesSnapshotPage(params, {
    ...endpointsConfig.getTransportManagersCertificatesSnapshotPage
  });

  return data;
};

const useTransportManagerCertificatesQuery = (params: TransportManagersCertificatesSearchFilter) =>
  usePaginatedQuery<IPaginatedModel<TransportManagerCertificateSnapshot>>(
    [RoadTransportQueryKeysEnum.DOCUMENTS_LIST, params],
    () => getTransportManagerCertifiactes(params)
  );

export default useTransportManagerCertificatesQuery;
