import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FolderDetailsExtended, FolderSnapshot } from '@ibtm/domain';

import { typedNameV2, useFormV2Context } from '@libs/common/v2';

import { RentalTableWrapper } from '@libs/domain/archive';
import { DomainDictionaryEntry } from '@libs/domain/config';
import { FolderDetailsParams } from '@libs/domain/folder';

function RentalsTab() {
  const [t] = useTranslation();
  const { folderId } = useParams<FolderDetailsParams>();
  const { watch } = useFormV2Context();

  const statusKey = watch(typedNameV2<FolderSnapshot>('statusKey')) as string;
  const isCarryOnArchived = watch(typedNameV2<FolderDetailsExtended>('isCarryOnArchived')) as boolean;

  const tooltipText = useMemo(() => {
    if (!isCarryOnArchived) {
      return t('archive:borrowFolder.message.folderIsCarryOnArchived');
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCarryOnArchived]);

  return (
    <RentalTableWrapper
      folderId={folderId}
      headerBorrowButtonTooltip={tooltipText}
      isHeaderReturnButtonVisible={statusKey === DomainDictionaryEntry.FOLDER_STATUS.BORROWED}
      isHeaderBorrowButtonDisabled={!isCarryOnArchived}
    />
  );
}

export default RentalsTab;
