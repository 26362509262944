import { useMemo } from 'react';
import { ReportDetailsLite, ReportDetailsSnapshot } from '@stack/report/dist/models';

const printableTargetType = 'application/pdf';

interface ReportFilesHookResult {
  files: ReportDetailsSnapshot['resultFiles'];
  printableFile?: string;
}

const useReportFiles = (report: ReportDetailsSnapshot | ReportDetailsLite): ReportFilesHookResult => {
  return useMemo(() => {
    if (!report) {
      return {
        files: {},
        printableFile: null
      };
    }
    const { resultFiles: files } = report;
    return {
      files,
      printableFile: files[printableTargetType] ?? null
    };
  }, [report]);
};

export default useReportFiles;
