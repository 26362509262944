import { GridLayout } from '@libs/common/v2';

import { SuspensionList, SuspensionSummaryTable } from './list';

function SuspensionsTab() {
  return (
    <GridLayout itemProps={{ width: '100%' }}>
      <SuspensionSummaryTable />
      <SuspensionList />
    </GridLayout>
  );
}

export default SuspensionsTab;
