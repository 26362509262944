import { useEffect } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import moment from 'moment';

import { DatepickerField, InputMode, Theme, useFormV2Context } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';
import { convertDateToDateFormat, important } from '@libs/common/v2/utils';

import { useApplicationDetailsQuery } from '../../../api';
import { IApplicationDetailsPageParams } from '../../../model';

interface IProps {
  fieldId: FieldPath<FieldValues>;
  label: string;
  api: {
    get: {
      accessor: string;
    };
  };
}
function ApplicationTermField({ fieldId, label, api }: IProps) {
  const classes = useStyles();
  const { setValue, formMode, getValues } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);
  const { applicationId } = useParams<IApplicationDetailsPageParams>();
  const { data } = useApplicationDetailsQuery(applicationId, { refetchOnMount: false });

  useEffect(() => {
    if (data) {
      setValue(fieldId, _.get(data, api?.get?.accessor));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isHighlighted = viewMode && moment(getValues(fieldId)).isSameOrBefore();

  return (
    <DatepickerField
      name={fieldId as FieldPath<FieldValues>}
      label={label}
      viewModeDateParser={convertDateToDateFormat}
      inputMode={viewMode ? InputMode.VIEW : InputMode.FORM}
      valueClassName={isHighlighted ? classes.value : null}
    />
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  value: {
    '& .field-value': {
      color: important(theme.palette.blue[800])
    },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    ...theme.typography.textXs.bold
  }
}));
export default ApplicationTermField;
