import { AttachmentVerificationElementDetails } from '@ibtm/domain';

import { DomainDictionaryEntry } from '@libs/domain/config';

export interface IAttachmentListItemClient {
  id: string;
  name?: RequiredFileEnum;
  notes: string;
  status?: IAttachmentStatus;
  order?: number;
}

export type IAttachmentStatus =
  (typeof DomainDictionaryEntry.REQUIRED_FILE_STATUS)[keyof typeof DomainDictionaryEntry.REQUIRED_FILE_STATUS];

export enum RequiredFileEnum {
  APPLICATION_PAYMENT_CONFIRMATION = 'application.attachments-to-application.proof-of-payment'
}

export const MAIN_COMMENT_FIELD_NAME = 'application-attachments-main-comment';

export interface VertificationElements {
  [key: string | number]: AttachmentVerificationElementDetails;
}
