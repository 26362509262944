import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderDetailsExtended } from '@ibtm/domain';

import { GridLayout, InputMode, Section, typedNameV2, useErrorsHandlerV2, useFormV2Context } from '@libs/common/v2';
import { TextInputField, useViewModesV2 } from '@libs/common/v2/form';

import { useElementVisibility } from '@libs/permission';

import { BusinessAddress, CorrespondenceAddress, MainAddress } from '@libs/domain/address';
import { DomainDictionaryEntry, DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import {
  FolderFormDataAddressTypeEnum,
  ParsedFolderDetailsClient,
  RegisteredFolderFormEnum
} from '@libs/domain/folder';

import { HistoricalDataSection } from './historicalData';
import { PrintingsSettingsAddressSection } from './printingsSettings';

function AddressesTab({
  isCountryFieldAvaliable,
  isMainAddressEditable
}: {
  isCountryFieldAvaliable: boolean;
  isMainAddressEditable: boolean;
}) {
  const [t] = useTranslation();
  const { formMode, watch } = useFormV2Context();
  const { isOperatorPortal, isClientPortal } = useDomainConfigContext();
  const { createMode } = useViewModesV2(formMode);
  const { checkIsElementVisible } = useElementVisibility();

  const { containerRef } = useErrorsHandlerV2(null, RegisteredFolderFormEnum.FOLDER_FORM);
  const isViewModeAllowed = useMemo(
    () => checkIsElementVisible(DomainUIElementEnum.FOLDER_ADDRESS_VIEW),
    [checkIsElementVisible]
  );

  const typeKey = watch(typedNameV2<ParsedFolderDetailsClient>('typeKey.value')) as string;

  const isFolderTypeKsZzWitd = [
    DomainDictionaryEntry.FOLDER_TYPE.WITD,
    DomainDictionaryEntry.FOLDER_TYPE.KS,
    DomainDictionaryEntry.FOLDER_TYPE.ZZ
  ].includes(typeKey);

  const isMainAddressFormMode = createMode || isMainAddressEditable ? {} : { inputMode: InputMode.VIEW };

  return (
    <GridLayout itemProps={{ xs: 12 }} containerProps={{ spacing: 0 }} ref={containerRef}>
      {isViewModeAllowed && (
        <Section title={t('folder:details.section.mainAddress')} isCollapsable>
          <MainAddress
            formGroupName={typedNameV2<ParsedFolderDetailsClient>(
              `addresses.${FolderFormDataAddressTypeEnum.MAIN}` as const
            )}
            inputProps={isMainAddressFormMode}
            isCountryFieldAvaliable={isCountryFieldAvaliable}
          />
        </Section>
      )}

      {isViewModeAllowed && (
        <Section title={t('folder:details.section.businessAddress')} isCollapsable>
          <BusinessAddress
            formGroupName={typedNameV2<ParsedFolderDetailsClient>(
              `addresses.${FolderFormDataAddressTypeEnum.HQ}` as const
            )}
            isCountryFieldAvaliable={isCountryFieldAvaliable}
            {...(isFolderTypeKsZzWitd && (createMode || isMainAddressEditable ? {} : { inputMode: InputMode.VIEW }))}
          />
        </Section>
      )}
      {isViewModeAllowed && (
        <Section title={t('folder:details.section.correspondenceAddress')} isCollapsable>
          <CorrespondenceAddress
            formGroupName={typedNameV2<ParsedFolderDetailsClient>(
              `addresses.${FolderFormDataAddressTypeEnum.CORRESPONDENCE}` as const
            )}
            hideProxyFields
            isCountryFieldAvaliable={isCountryFieldAvaliable}
            {...(isFolderTypeKsZzWitd && (createMode || isMainAddressEditable ? {} : { inputMode: InputMode.VIEW }))}
          />
        </Section>
      )}

      {!createMode && isViewModeAllowed && <HistoricalDataSection isCollapsable />}

      {isClientPortal && (
        <Section title={t('folder:details.section.permissionsAddress')} isCollapsable>
          <GridLayout itemProps={{ xs: 12 }}>
            <TextInputField
              name={typedNameV2<FolderDetailsExtended>('printingSettings.displayAddress')}
              label={t('folder:field.primaryAddress')}
              inputMode={InputMode.VIEW}
            />
          </GridLayout>
        </Section>
      )}

      {!createMode && isOperatorPortal && <PrintingsSettingsAddressSection />}
    </GridLayout>
  );
}

export default AddressesTab;
