import { usePaginatedQuery } from 'react-query';
import { PermissionCopySearchFilter as PermissionCopySearchFilterClient } from '@ibtm/client-domain';
import { PermissionCopySearchFilter, PermissionCopySnapshot } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { PermissionCopySnapshotClient } from '../../model';
import { parsePermissionCopiesList } from '../../parsers';
import { endpointsConfig } from '../endpoints-config';

import PermissionsQueryKeysEnum from './PermissionsQueryKeysEnum';

export const getPermissionCopies = async (_, params: PermissionCopySearchFilter) => {
  const { data } = await API.permissions.getPermissionCopiesPage(params, {
    ...endpointsConfig.getPermissionCopiesPage
  });

  return {
    ...data,
    content: parsePermissionCopiesList(data.content)
  };
};
export const getPermissionCopiesClient = async (_, params: PermissionCopySearchFilterClient) => {
  const { data } = await API.client.permissions.getPermissionCopiesPage('', '', params, {
    ...endpointsConfig.getPermissionCopiesPage
  });

  return {
    ...data,
    content: parsePermissionCopiesList(data.content as Array<PermissionCopySnapshot>)
  };
};

export default function usePermissionsQuery(params: PermissionCopySearchFilter) {
  const { getQuery } = useGetApiQueryByPerspective();
  return usePaginatedQuery<IPaginatedModel<PermissionCopySnapshotClient>>(
    [PermissionsQueryKeysEnum.PERMISSION_COPIES_LIST, params],
    getQuery(getPermissionCopiesClient, getPermissionCopies)
  );
}
