import React, { Component } from 'react';
import {
  Control,
  FieldErrors,
  FieldValues,
  FormState,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormResetField,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
  UseFormUnregister,
  UseFormWatch
} from 'react-hook-form';

import { ValidationProperties } from '@libs/common/v2/form/validation/model';

import { FormMode } from '../model';

export interface FormV2ContextState {
  errors?: FieldErrors;
  register?: UseFormRegister<FieldValues>;
  unregister?: UseFormUnregister<FieldValues>;
  setValue?: UseFormSetValue<FieldValues>;
  /**
   * Zmiana w zasubskrybowanym polu w "watch" wywołuje re-render wszystkich fieldów w całym formV2Context,
   * jeżeli nie potrzebujemy koniecznie takiego zachowania używajmy zoptymalizowanej wersji - hooka "useFormV2Watch"
   * który wywołuje rerender tylko tego komponentu w którym jest użyty
   */
  watch?: UseFormWatch<FieldValues>;
  getValues?: UseFormGetValues<FieldValues>;
  setError?: UseFormSetError<FieldValues>;
  clearErrors?: UseFormClearErrors<FieldValues>;
  trigger?: UseFormTrigger<FieldValues>;
  additionalFields?: {
    [key: string]: Component;
  };
  submitCount?: number;
  control?: Control<FieldValues, any>;
  // TODO: do usunięcia po stworzeniu nowego select'a
  initialValues?: FieldValues;
  formMode?: FormMode;
  handleSubmit?: UseFormHandleSubmit<FieldValues>;
  formState?: FormState<FieldValues>;
  reset?: UseFormReset<FieldValues>;
  resetField?: UseFormResetField<FieldValues>;
  loading?: boolean;
  isSubmitting?: boolean;
  isSubmitted?: boolean;
  isDirty?: boolean;
  isPermissionKeys?: boolean;
  validationBuilderFunctions?: {
    setFieldValidation: (param: ValidationProperties) => void;
    handleUnmountField: (fieldName: string) => void;
  };
}

const FormV2Context = React.createContext<FormV2ContextState>({
  errors: {},
  register: null,
  unregister: null,
  setValue: null,
  watch: null,
  getValues: null,
  clearErrors: null,
  setError: null,
  trigger: null,
  control: null,
  formState: null,
  isSubmitting: false,
  loading: false,
  isDirty: false,
  additionalFields: {},
  initialValues: null,
  submitCount: 0,
  formMode: FormMode.VIEW,
  handleSubmit: null,
  reset: null,
  resetField: null,
  isPermissionKeys: null
});

export default FormV2Context;
