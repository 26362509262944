import { useTranslation } from 'react-i18next';

import { TableLayout } from '@libs/common/v2';

import { useSettlementCurrentPermitsTable } from '../../hooks/tables';
import { ReleasedPermitsSnapshot, SettlementCurrentPermitsData } from '../../model';

interface IProps {
  data: SettlementCurrentPermitsData[];
  visibleColumns?: Array<keyof ReleasedPermitsSnapshot>;
}

function SettlementCurrentPermitsTable({
  data,
  visibleColumns = ['year', 'nameKey', 'formNumberFrom', 'formNumberTo', 'formNumber']
}: IProps) {
  const [t] = useTranslation();
  const tableProps = useSettlementCurrentPermitsTable(data, visibleColumns);

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('foreignPermits:settlementDisposablePermits.settledPermitsTitle')}
      isActionColumnEnabled={false}
      isFilterEnabled={false}
      isRefreshEnabled={false}
      isSection
    />
  );
}

export default SettlementCurrentPermitsTable;
