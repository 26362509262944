import { QueryConfig, useQuery } from 'react-query';
import { SubjectPartnershipPartnerList, SubjectPartnershipPartnerRequest } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import SubjectQueryKeysEnum from './SubjectQueryKeysEnum';

export const getPartnershipPartners = async (_, subjectPartnershipPartnerRequest: SubjectPartnershipPartnerRequest) => {
  const { data } = await API.subject.getSubjectPartnershipPartners(subjectPartnershipPartnerRequest, {
    ...endpointsConfig.getSubject
  });
  return data;
};

export const getPartnershipPartnersClient = async (
  _,
  subjectPartnershipPartnerRequest: SubjectPartnershipPartnerRequest
) => {
  const { data } = await API.client.subject.getSubjectPartnershipPartners('', '', subjectPartnershipPartnerRequest, {
    ...endpointsConfig.getSubject
  });

  return data;
};

export default function usePartnershipPartnersQuery(
  subjectPartnershipPartnerRequest: SubjectPartnershipPartnerRequest,
  queryConfig: QueryConfig<SubjectPartnershipPartnerList, unknown> = {}
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery<SubjectPartnershipPartnerList>(
    [SubjectQueryKeysEnum.SUBJECT_EXTENDED, subjectPartnershipPartnerRequest],
    getQuery(getPartnershipPartnersClient, getPartnershipPartners),
    queryConfig
  );
}
