import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PermissionSearchFilter } from '@ibtm/domain';
import { makeStyles } from '@mui/styles';
import { isNull } from 'lodash';
import moment from 'moment';

import { TranslationFrom } from '@libs/common';
import { WarningInformation } from '@libs/common/v2/components/warning';
import { getCalendarDate } from '@libs/common/v2/utils';

import { DomainDictionaryEntry, useDomainConfigContext } from '@libs/domain/config';

import { useLicenseCopiesQuery } from '../../license/api';
import { useExternalAuthPermissionsQuery } from '../api/queries/usePermissionsQuery';

function PermissionWarnings({
  titleKey,
  applicationId,
  initialParams,
  folderId
}: {
  titleKey: TranslationFrom<'permission:title.permission'> | 'license-copies';
  applicationId: string;
  initialParams?: PermissionSearchFilter;
  folderId?: string;
}) {
  const [t] = useTranslation();
  const classes = useStyles();
  const isApplication = useMemo(() => !isNull(applicationId), [applicationId]);
  const isGitd = useMemo(() => titleKey === 'gitd', [titleKey]);
  const isLicenseCopies = useMemo(() => titleKey === 'license-copies', [titleKey]);
  const isCountryPermission = useMemo(() => titleKey === 'country-permissions', [titleKey]);
  const isForeignPermission = useMemo(() => titleKey === 'foreign-authority-permissions', [titleKey]);

  const { data } = useExternalAuthPermissionsQuery(
    {
      ...initialParams,
      validToLessThanOrEqual: getCalendarDate(moment().format('YYYY-MM-DD'))
    },
    {
      enabled:
        (isGitd && !folderId) ||
        (isCountryPermission && isApplication && !folderId) ||
        (isForeignPermission && isApplication && !folderId)
    }
  );
  const { isOperatorPortal } = useDomainConfigContext();

  const { data: dataLicense } = useLicenseCopiesQuery(
    {
      ...(isOperatorPortal ? { folderIdIn: [folderId] } : { folderIdEquals: folderId }),
      size: 2,
      validToLessThanOrEqual: getCalendarDate(moment().format('YYYY-MM-DD')),
      statusKeyIn: [DomainDictionaryEntry.PERMISSION_STATUS.ACTIVE, DomainDictionaryEntry.PERMISSION_STATUS.PENDING]
    },
    { enabled: isLicenseCopies && folderId }
  );

  const isPermissionData = useMemo(
    () => data?.content?.length > 0 || dataLicense?.content?.length > 0,
    [data, dataLicense]
  );
  const getWarningTranslationText = useMemo(() => {
    if (isGitd) {
      return t('folder:details.tab.permissions.messageBlock.gitdPermits');
    }

    if (isCountryPermission) {
      return t('folder:details.tab.permissions.messageBlock.countryPermissions');
    }

    if (isForeignPermission) {
      return t('folder:details.tab.permissions.messageBlock.foreignPermissions');
    }

    if (isLicenseCopies) {
      return t('folder:details.tab.permissions.messageBlock.licenceExtracts');
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLicenseCopies, isForeignPermission, isCountryPermission, isGitd]);

  return (
    isPermissionData && (
      <div className={classes.warning}>
        <WarningInformation content={getWarningTranslationText} />
      </div>
    )
  );
}

const useStyles = makeStyles(() => ({
  warning: {
    marginTop: 16
  }
}));

export default PermissionWarnings;
