import { MisspellApplicationDataCertificateDetails } from '@ibtm/domain';

import { useCreateColumns } from '@libs/common/v2';

import { DomainQuickchangeableDictionaryEnum } from '../../config';

function useMisspellApplicationCertificateColumn() {
  const { createColumns } = useCreateColumns<MisspellApplicationDataCertificateDetails>({
    pathToTranslate: 'applications:misspellApplication.fields'
  });

  const columns = createColumns([
    {
      accessor: 'reprint',
      type: 'BOOLEAN',
      header: 'reprint',
      isSortable: false
    },
    {
      accessor: 'certificateUpdated.applicationNumber',
      type: 'TEXT',
      header: 'applicationNumber',
      isSortable: false
    },
    {
      accessor: 'certificateUpdated.number',
      type: 'TEXT',
      header: 'certificateNumber',
      isSortable: false
    },
    {
      accessor: 'certificateUpdated.formNumber',
      type: 'TEXT',
      header: 'formNumber',
      isSortable: false
    },
    {
      accessor: 'certificateUpdated.dateOfIssue',
      type: 'TEXT',
      header: 'dateOfIssue',
      isSortable: false
    },
    {
      accessor: 'certificateUpdated.vehicleVin',
      type: 'TEXT',
      header: 'vehicleVin',
      isSortable: false
    },
    {
      accessor: 'certificateUpdated.vehiclePlateNumber',
      type: 'TEXT',
      header: 'vehiclePlateNumber',
      isSortable: false
    },
    {
      accessor: 'certificateUpdated.vehicleBrandKeyTranslation',
      type: 'QUICK_DICTIONARY_SINGLE_SELECT',
      quickDictionaryName: DomainQuickchangeableDictionaryEnum.VEHICLE_BRAND,
      header: 'vehicleBrandKey',
      isSortable: false
    },
    {
      accessor: 'certificateUpdated.vehicleModelKeyTranslation',
      type: 'QUICK_DICTIONARY_SINGLE_SELECT',
      quickDictionaryName: DomainQuickchangeableDictionaryEnum.VEHICLE_MODEL,
      header: 'vehicleModelKey',
      isSortable: false
    },
    {
      accessor: 'certificateUpdated.vehicleEngineType',
      type: 'TEXT',
      header: 'vehicleEngineType',
      isSortable: false
    },
    {
      accessor: 'certificateUpdated.vehicleEngineNumber',
      type: 'TEXT',
      header: 'vehicleEngineNumber',
      isSortable: false
    }
  ]);
  return { columns };
}

export default useMisspellApplicationCertificateColumn;
