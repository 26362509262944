import { ReputationRequestDetails } from '@ibtm/domain';

import { useCreateColumns } from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { TransportManagerDetailsClient } from '../model';

function useTransportManagerReputationColumns() {
  const { createColumns } = useCreateColumns<
    TransportManagerDetailsClient['reputationInquires'][0] | ReputationRequestDetails
  >({
    pathToTranslate: 'transportManager:details.reputation'
  });

  const columns = createColumns([
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.REPUTATION_REQUEST_STATUS,
      accessor: 'statusKey',
      isSortable: false
    },
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.REPUTATION,
      accessor: 'aggregatedStatusKey',
      isSortable: false
    },
    {
      type: 'DATE',
      accessor: 'inquiryDate',
      isSortable: false
    },
    {
      type: 'TEXT',
      header: 'inquiredBy',
      accessor: 'inquiredBy.name',
      isSortable: false
    }
  ]);

  return { columns };
}

export default useTransportManagerReputationColumns;
