import { ReactNode, useMemo, useState } from 'react';
import { PaginatedQueryConfig, PaginatedQueryResult } from 'react-query';
import { SystemParametersDetails as SystemParametersDetailsClient } from '@ibtm/client-domain';
import { SystemParametersLite } from '@ibtm/domain/dist/models';

import { useAuth } from '@libs/auth/hooks';

import { OverrideSystemParametersContext, OverrideSystemParametersContextValue } from './OverrideSystemParameters';
import SystemParametersContext, {
  SYSTEM_AUTOLOGOUT_PARAMETER_KEY,
  SystemParametersContextValue
} from './SystemParametersContext';

const parametersConfig = {
  sessionValidityDuration: { parameterKey: SYSTEM_AUTOLOGOUT_PARAMETER_KEY }
};

const context: SystemParametersContextValue = {
  sessionValidityDuration: null
};

type ParametersQueryFunction<T> = (queryconfig?: PaginatedQueryConfig<T>) => PaginatedQueryResult<T, unknown>;

interface IProps {
  children: ReactNode;
  isQueryEnabled?: boolean;
  query: ParametersQueryFunction<SystemParametersLite> | ParametersQueryFunction<SystemParametersDetailsClient>;
}

function SystemParametersContextProvider({ children, query, isQueryEnabled = true }: IProps) {
  const { isLoggedIn } = useAuth();
  const { data, isFetched } = query({ enabled: isQueryEnabled && isLoggedIn });
  const [overriddenSystemParameters, setOverriddenSystemParameters] = useState<Partial<SystemParametersContextValue>>(
    {}
  );

  const parameters: SystemParametersContextValue = useMemo(
    () =>
      isFetched
        ? Object.keys(context).reduce((acc, parameter) => {
            const result = acc;
            const contextValueKey = parameter as keyof typeof parametersConfig;
            result[contextValueKey] =
              overriddenSystemParameters[contextValueKey] ||
              data?.content?.find(({ key }) => key === parametersConfig[contextValueKey].parameterKey);
            return result;
          }, {} as SystemParametersContextValue)
        : context,
    [data?.content, isFetched, overriddenSystemParameters]
  );
  const overrideSystemParametersValue: OverrideSystemParametersContextValue = useMemo(
    () => ({
      overrideSystemParameters: value => {
        setOverriddenSystemParameters(prev => {
          return { ...prev, ...value };
        });
      },
      clearOverriddenParameters: () => {
        setOverriddenSystemParameters({});
      }
    }),
    []
  );
  return (
    <OverrideSystemParametersContext.Provider value={overrideSystemParametersValue}>
      <SystemParametersContext.Provider value={parameters}>{children}</SystemParametersContext.Provider>
    </OverrideSystemParametersContext.Provider>
  );
}

export default SystemParametersContextProvider;
