import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnInstance } from 'react-table';
import { Checkbox, MenuItem, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

import { useTableAdapter } from '../../hooks';
import { ISpecialColumnEnum } from '../../model';

import TableIconButton from './TableIconButton';

interface IRenderMenuParams {
  columns: ColumnInstance[];
  onToggle: (col: ColumnInstance) => void;
}

interface IProps {
  renderMenu?: (params: IRenderMenuParams) => React.ReactNode;
  tooltipTitle?: string;
  className?: string;
}

function TableButtonColumnPicker({ renderMenu, tooltipTitle, className }: IProps) {
  const { columns: tableColumn }: any = useTableAdapter();
  const columns = tableColumn.filter(column => column.render('Header'));
  const visibleColumns = columns.reduce((sum, column) => sum + Number(column.isVisible), 0);

  const [anchorEl, setAnchorEl] = useState(null);

  const [t] = useTranslation();
  const classes = useStyles();

  const filteredColumns = (columns ?? []).filter(
    column => !(Object.values(ISpecialColumnEnum) as string[]).includes(column.id)
  );

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleColumnVisibility = (column: ColumnInstance) => e => {
    e.stopPropagation();
    (column as any).toggleHidden();
  };

  const renderDefaultMenu = ({ columns, onToggle }) => {
    return columns.map(column => {
      const label = column.render('Header');
      return (
        Boolean(label) && (
          <MenuItem key={column.id} className={classes.menuItem} dense onClick={onToggle(column)}>
            <Checkbox size="small" color="primary" checked={column.isVisible} style={{ marginRight: 8 }} />
            {label}
          </MenuItem>
        )
      );
    });
  };

  const handleRenderMenu = renderMenu ?? renderDefaultMenu;
  return (
    <>
      <TableIconButton
        tooltipTitle={tooltipTitle || t('other:component.table.showHideColumns')}
        className={clsx(classes.iconButton, className)}
        onClick={handleClick}
        icon="EyeIcon"
        iconClassName={clsx({ [classes.active]: Boolean(anchorEl) })}
      >
        <span className={classes.info}>
          {visibleColumns}/{columns.length}
        </span>
      </TableIconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleClose}
      >
        {handleRenderMenu({ columns: filteredColumns, onToggle: handleToggleColumnVisibility })}
      </Popover>
    </>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  iconButton: {
    position: 'relative'
  },
  active: {
    '& > path': {
      stroke: important(theme.palette.primary[700])
    }
  },
  info: {
    position: 'absolute',
    marginLeft: 12,
    marginTop: -24,
    zIndex: 10,
    color: theme.palette.primary?.main,
    backgroundColor: theme.palette.grey[50],
    ...theme.typography.textXs.bold,
    letterSpacing: '0.5px',
    textAlign: 'center',
    borderWidth: 0.5,
    borderColor: theme.palette?.grey[200],
    borderRadius: 7,
    padding: '0 4px'
  },
  menuItem: {
    padding: '2px 16px 2px 8px'
  }
}));

export default TableButtonColumnPicker;
