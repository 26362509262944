import { ReactElement, useEffect } from 'react';
import { matchPath, useResolvedPath } from 'react-router-dom';
import { useSnackbar } from '@enigma/fe-ui';

import { i18n } from '@libs/common';
import { useRouter } from '@libs/common/v2';
import { useRouterContext } from '@libs/common/v2/router';

import { AuthService } from '@libs/auth';
import { useAuthSelector } from '@libs/auth/store/reducers';

import { useHasRouteAccess } from '../hooks';

interface IProps {
  children: ReactElement;
}

function RouteAuthorization({ children }: IProps) {
  const { goToPage, routes, location } = useRouter();
  const { showSnackbar } = useSnackbar();
  const user = useAuthSelector(({ auth }) => auth.user);

  const { routes: allRoutes } = useRouterContext();

  const matchedRoute = useResolvedPath(location);

  const [hasAccess, lastPathname] = useHasRouteAccess(
    location?.pathname,
    allRoutes.find(route => matchPath(route, matchedRoute?.pathname))
  );

  useEffect(() => {
    if (AuthService.isTokenRefreshing) {
      return;
    }

    const newRoute = allRoutes.find(
      route =>
        route.path === (location?.pathname.endsWith('/') && location?.pathname.slice(0, -1)) ||
        route.path === location?.pathname
    );
    const { pathname, search, state } = location;
    const redirectUrl = state?.redirectUrl ?? '/';

    if (!user?.personalData && !newRoute?.anonymous) {
      goToPage(routes.login(), {
        state: { redirectUrl: pathname, redirectSearch: search }
      });
    } else if (!user?.permissions?.length && !newRoute?.anonymous) {
      goToPage(String(lastPathname || redirectUrl || routes.profile()), {
        state: { redirectUrl: pathname, redirectSearch: search }
      });
    } else if (!hasAccess) {
      showSnackbar('error', i18n.t('other:permission.insufficientPermissions'));

      goToPage(String(lastPathname || redirectUrl || routes.profile()), {
        state: { redirectUrl: pathname, redirectSearch: search }
      });
    } else if (location?.pathname === routes.login()) {
      goToPage(String(lastPathname || redirectUrl || routes.profile()), {
        state: { redirectUrl: pathname, redirectSearch: search }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAccess]);

  return hasAccess ? children : null;
}

export default RouteAuthorization;
