import { QueryConfig, usePaginatedQuery } from 'react-query';
import { FolderLimitDetails, FolderLimitSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { ForeignPermissionQueryEnum } from '@libs/domain/foreign-permission';

import { endpointsConfig } from '../endpoints-config';

export const getEKMT = async (params: FolderLimitSearchFilter) => {
  const { data } = await API.folderLimits.getFolderLimitsPage(params, {
    ...endpointsConfig.getFolderLimitsPage
  });
  return data;
};

function useEKMTQuery(
  params: FolderLimitSearchFilter,
  queryConfig: QueryConfig<IPaginatedModel<FolderLimitDetails>> = {}
) {
  return usePaginatedQuery<IPaginatedModel<FolderLimitDetails>>(
    [ForeignPermissionQueryEnum.EKMT_LIST, params],
    () => getEKMT(params),
    queryConfig
  );
}
export default useEKMTQuery;
