import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderSnapshot } from '@ibtm/domain';

import { Button, TableLayout, useRouter, useTableRowActions } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { DomainDictionaryEntry, DomainUIElementEnum } from '@libs/domain/config';
import { useFoldersTableProps } from '@libs/domain/folder';

import ConfirmDeleteAssociationModal from './ConfirmDeleteAssociationModal';

function AssociationsTable() {
  const [t] = useTranslation();
  const { routes } = useRouter();
  const [deleteAssociationId, setDeleteAssociationId] = useState<string>('');

  const tableProps = useFoldersTableProps({ typeKeyIn: [DomainDictionaryEntry.FOLDER_TYPE.ZZ] }, [
    'number',
    'subjectName',
    'subjectNip',
    'statusKey',
    'locked',
    'currentBorrowerName',
    'currentVerifierName',
    'primaryAddress',
    'created',
    'authorName',
    'modified',
    'modifierName'
  ]);

  const { renderTableActions } = useTableRowActions<FolderSnapshot>([
    {
      id: DomainUIElementEnum.ASSOCIATIONS_SHOW_DETAILS_BUTTON,
      label: 'action.openDetails',
      link: ({ original }) => routes.associationDetails(original.id),
      icon: 'ArrowIcon'
    },
    {
      id: DomainUIElementEnum.ASSOCIATIONS_EDIT_BUTTON,
      label: 'action.edit',
      link: ({ original }) => routes.associationEdit(original.id),
      icon: 'EditIcon'
    },
    {
      id: DomainUIElementEnum.ASSOCIATIONS_DELETE_BUTTON,
      label: 'action.delete',
      onClick: ({
        row: {
          original: { id }
        }
      }) => setDeleteAssociationId(id),
      isHidden: ({
        row: {
          original: { statusKey }
        }
      }) =>
        [DomainDictionaryEntry.FOLDER_STATUS.INACTIVE, DomainDictionaryEntry.FOLDER_STATUS.ARCHIVE].includes(statusKey),
      icon: 'TrashIcon'
    }
  ]);

  return (
    <TableLayout
      {...tableProps}
      pageTitle={t('associations:list.title')}
      xlsxDownload={{
        fileName: t('associations:list.title'),
        pathToXLSXTranslation: 'folder:field',
        apiRequest: API.folder.getFoldersSnapshotPage
      }}
      headerActions={
        <Button
          label={t('associations:action.create')}
          actionKey={DomainUIElementEnum.ASSOCIATIONS_ADD_BUTTON}
          link={routes.associationCreate()}
        />
      }
      rowActions={renderTableActions([
        DomainUIElementEnum.ASSOCIATIONS_SHOW_DETAILS_BUTTON,
        DomainUIElementEnum.ASSOCIATIONS_EDIT_BUTTON,
        DomainUIElementEnum.ASSOCIATIONS_DELETE_BUTTON
      ])}
    >
      <ConfirmDeleteAssociationModal
        onCancel={() => setDeleteAssociationId('')}
        deleteAssociationId={deleteAssociationId}
      />
    </TableLayout>
  );
}

export default AssociationsTable;
