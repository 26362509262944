import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { MisspellApplicationDataCertificateDetails, MisspellCertificateDetails } from '@ibtm/domain';

import { TableButtonEdit, TableButtonView, TableLayout, useDialog } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { useApplicationDetailsQuery } from '../../application/api';
import { useMispellApplicationCertificateTable } from '../hooks';
import { DialogModeEnum, MisspellCertificateDetailsValues } from '../model/misspell-application.model';

import { MisspellApplicationCertificateDialog } from './dialog/MisspellApplicationCertificateDialog';

interface IProps {
  applicationId: string;
}

function MisspellApplicationCertificateTable({ applicationId }: IProps) {
  const { data } = useApplicationDetailsQuery(applicationId, { refetchOnMount: false });

  const scertificates = data?.misspellApplicationDataDetails?.scertificates?.map(certificate => ({
    ...certificate,
    id: certificate.certificate.id
  }));

  const { viewMode } = useViewModesV2();
  const tableProps = useMispellApplicationCertificateTable(scertificates);
  const [t] = useTranslation();

  const { openDialog } = useDialog();

  const parseCertificateData = (certificatesData: MisspellCertificateDetails): MisspellCertificateDetailsValues => ({
    ...certificatesData,
    vehicleModelKey: { value: certificatesData?.vehicleModelKey, name: certificatesData?.vehicleModelKeyTranslation },
    vehicleBrandKey: { value: certificatesData?.vehicleBrandKey, name: certificatesData?.vehicleBrandKeyTranslation }
  });

  const rowActions = useCallback(
    (row: Row<MisspellApplicationDataCertificateDetails>) => (
      <>
        <TableButtonView
          tooltipTitle={t('applications:misspellApplication.action.showChanges')}
          onClick={() =>
            openDialog(({ closeDialog }) => (
              <MisspellApplicationCertificateDialog
                applicationId={applicationId}
                permissionData={parseCertificateData(row.original.certificate)}
                updatePermissionData={parseCertificateData(row.original.certificateUpdated)}
                closeDialog={closeDialog}
                mode={DialogModeEnum.COMPARE}
              />
            ))
          }
        />
        {!viewMode && (
          <TableButtonEdit
            onClick={() =>
              openDialog(({ closeDialog }) => (
                <MisspellApplicationCertificateDialog
                  applicationId={applicationId}
                  permissionData={parseCertificateData(row.original.certificate)}
                  updatePermissionData={parseCertificateData(row.original.certificateUpdated)}
                  closeDialog={closeDialog}
                  mode={DialogModeEnum.EDIT}
                />
              ))
            }
          />
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [applicationId, openDialog, t]
  );

  return (
    <TableLayout<MisspellApplicationDataCertificateDetails, unknown>
      pageTitle={t('applications:misspellApplication.table.certificates')}
      {...tableProps}
      isSection
      isFilterEnabled={false}
      isRefreshEnabled={false}
      rowActions={rowActions}
    />
  );
}

export default MisspellApplicationCertificateTable;
