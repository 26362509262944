import { makeStyles } from '@mui/styles';

import { Theme } from '@libs/common/v2/theme';

import { important } from '../../../../utils/styles.utils';

export type EditableInputsClasses = 'value' | 'text' | 'switch' | 'select';

export const useEditableInputsStyles = makeStyles<Theme, NonNullable<unknown>, EditableInputsClasses>(theme => ({
  value: {
    overflow: 'hidden',
    '& .field-value': {
      ...theme.typography.textXs.normal,
      fontSize: important(theme.typography.textSm.normal.fontSize),
      fontWeight: important(theme.typography.textSm.normal.fontWeight),
      lineHeight: important(theme.typography.textSm.normal.lineHeight),
      color: important(theme.palette.grey[700])
    }
  },
  text: {
    '& > div > div ': {
      padding: important(0),
      marginTop: important(0),
      '& > input': {
        padding: important('8px 0 8px 8px'),
        fontSize: important(theme.typography.textSm.normal.fontSize),
        fontWeight: important(theme.typography.textSm.normal.fontWeight),
        lineHeight: important(theme.typography.textSm.normal.lineHeight),
        color: important(theme.palette.grey[700])
      }
    },
    '& > p': {
      whiteSpace: 'pre-wrap',
      marginBottom: 0,
      overflow: 'hidden',
      wordBreak: 'break-word',
      height: 65
    }
  },
  switch: {
    '& > div': {
      ...theme.typography.textSm.normal,
      fontSize: important(theme.typography.textSm.normal.fontSize),
      fontWeight: important(theme.typography.textSm.normal.fontWeight),
      lineHeight: important(theme.typography.textSm.normal.lineHeight),
      color: important(theme.palette.grey[700])
    },
    '& > p': {
      whiteSpace: 'pre-wrap',
      marginBottom: 0,
      overflow: 'hidden',
      wordBreak: 'break-word',
      height: 50
    }
  },
  select: {
    '& > label > div > div ': {
      padding: important(0),
      marginTop: important(0),
      minHeight: 'unset',
      flexWrap: 'nowrap',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'flex-end',
      '& .MuiAutocomplete-tag': {
        width: '80%',
        '& > span': {
          width: '80%',
          paddingLeft: 10,
          paddingRight: 0,
          marginRight: 10
        }
      },
      '& .hidden-selected-values': {
        margin: 0,
        ...theme.typography.textXs.medium
      },
      '& > input': {
        minWidth: important(0),
        padding: important('8px 0 8px 8px'),
        fontSize: important(theme.typography.textSm.normal.fontSize),
        fontWeight: important(theme.typography.textSm.normal.fontWeight),
        lineHeight: important(theme.typography.textSm.normal.lineHeight),
        backgroundColor: important('transparent')
      },
      '& .MuiInputAdornment-root': {
        top: '50%'
      }
    },
    '& > label > p': {
      whiteSpace: 'pre-wrap',
      marginBottom: 0,
      overflow: 'hidden',
      wordBreak: 'break-word',
      height: 50
    }
  }
}));
