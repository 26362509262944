import React from 'react';

import { DropdownButton, TableButton } from '@libs/common/v2';
import { useTheme } from '@libs/common/v2/theme/hooks';
import { ThemeFontSizeEnum } from '@libs/common/v2/theme/model';

interface IProps {
  className?: string;
}
const options = [
  { title: '100%', id: ThemeFontSizeEnum.NORMAL_FONT },
  { title: '125%', id: ThemeFontSizeEnum.MEDIUM_FONT },
  { title: '150%', id: ThemeFontSizeEnum.BIG_FONT }
];

function ChangeFontSizeButton({ className }: IProps) {
  const { fontSizeZoom, changeFontSizeZoom } = useTheme();

  const handleChangeFontSize = (zoom: ThemeFontSizeEnum) => (fontSizeZoom === zoom ? null : changeFontSizeZoom(zoom));
  const iconClassName = { iconButton: className };

  return (
    <DropdownButton icon="FontChangeIcon" classNames={iconClassName}>
      {({ handleClose }) =>
        options.map(option => (
          <TableButton
            key={option.id}
            label={option.title}
            onClick={() => {
              handleChangeFontSize(option.id);
              handleClose();
            }}
          />
        ))
      }
    </DropdownButton>
  );
}

export default ChangeFontSizeButton;
