import React, { useCallback, useRef, useState } from 'react';
import { MenuItem, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Button } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

interface IActionProps {
  label?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  isDisabled?: boolean;
}

interface IProps {
  actions: IActionProps[];
  onClick?: React.MouseEventHandler;
  tooltipTitle?: string;
  className?: string;
  isDisabled?: boolean;
  isButtonLoading?: boolean;
  actionKey?: UIElementNameEnum;
}

function DropdownFullButton({
  actions,
  onClick,
  tooltipTitle,
  className,
  isDisabled,
  isButtonLoading,
  actionKey
}: IProps) {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLDivElement | null>(null);

  const handleClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const handleOnClick = useCallback(
    (event: React.MouseEvent<any, MouseEvent>) => {
      onClick?.(event);
      handleOpen();
    },
    [onClick, handleOpen]
  );

  return (
    <>
      <div ref={buttonRef}>
        <Button
          isSecondary
          variant="outlined"
          onClick={handleOnClick}
          label={tooltipTitle}
          className={className}
          disabled={isDisabled}
          isLoading={isButtonLoading}
          actionKey={actionKey}
        />
      </div>
      <Popover
        open={menuOpen}
        anchorEl={buttonRef.current}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {actions?.map(action => (
          <MenuItem
            onClick={action.onClick}
            className={clsx('mt-8 py-8', classes.label)}
            tabIndex={0}
            disabled={action.isDisabled}
            key={action.label}
            onFocus={({ target }) => target.classList.add('focus')}
            onBlur={({ target }) => target.classList.remove('focus')}
          >
            {action.children}
            {action.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}
const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: theme.typography?.fontWeightRegular,
    fontSize: theme.typography?.fontSize
  },

  paper: {
    '& li': {
      marginTop: 0
    },
    backgroundColor: theme.palette.background?.default
  }
}));

export default DropdownFullButton;
