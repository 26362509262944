import { ProceedingFormDetails, ProceedingFormsSearchFilter } from '@ibtm/domain';

import { CamelCasePath, SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO, getCalendarDate } from '@libs/common/v2/utils';

import { DomainDictionaryEnum } from '@libs/domain/config';

export const useFormOperationColumns = (visibleColumns: Array<CamelCasePath<ProceedingFormDetails>>) => {
  const { createColumns } = useCreateColumns<ProceedingFormDetails>({
    pathToTranslate: 'proceeding:administrative.tab.formOperations.field'
  });

  const mappedSortFields: TableSortMapper<ProceedingFormDetails> = {
    suspendedFrom: 'suspension.dateFrom',
    suspendedTo: 'suspension.dateTo'
  };

  const mappedFilterFields: TableFilterMapper<ProceedingFormDetails, ProceedingFormsSearchFilter> = {
    permissionTypeCodeKey: (typeKeys: SelectOption<string>[]) => ({
      permissionTypeCodeKeyIn: typeKeys.map(({ value }) => value)
    }),
    formNumber: (formNumber: string) => ({ formNumberContains: formNumber }),
    reasonKey: (reasonKeys: SelectOption<string>[]) => ({ reasonKeyIn: reasonKeys.map(({ value }) => value) }),
    validTo: ({ dateFrom, dateTo }: DateRangeISO) => ({
      expirationDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      expirationDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    statusKey: (state: SelectOption<string>[]) => ({ statusKeyIn: state.map(({ value }) => value) }),
    cancelDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
      cancelDateGreaterThanOrEqual: getCalendarDate(dateFrom),
      cancelDateLessThanOrEqual: getCalendarDate(dateTo)
    }),
    suspendedFrom: ({ dateFrom, dateTo }: DateRangeISO) => ({
      suspendedFromGreaterThanOrEqual: getCalendarDate(dateFrom),
      suspendedFromLessThanOrEqual: getCalendarDate(dateTo)
    }),
    suspendedTo: ({ dateFrom, dateTo }: DateRangeISO) => ({
      suspendedToGreaterThanOrEqual: getCalendarDate(dateFrom),
      suspendedToLessThanOrEqual: getCalendarDate(dateTo)
    })
  };

  const columns = createColumns(
    [
      {
        header: 'typeKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.PERMISSION_TYPE_CODE,
        accessor: 'permissionTypeCodeKey'
      },
      {
        type: 'TEXT',
        accessor: 'formNumber'
      },
      {
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.LEGAL_GROUNDS_FOR_PROCEEDINGS,
        accessor: 'reasonKey'
      },
      {
        header: 'expirationDate',
        type: 'DATE',
        accessor: 'validTo',
        width: 255
      },
      {
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: DomainDictionaryEnum.FORM_OPERATION_STATUS,
        header: 'state',
        accessor: 'statusKey'
      },
      {
        type: 'DATE',
        accessor: 'cancelDate'
      },
      {
        header: 'suspensionFrom',
        type: 'DATE',
        accessor: 'suspendedFrom'
      },
      {
        header: 'suspensionTo',
        type: 'DATE',
        accessor: 'suspendedTo'
      }
    ],
    visibleColumns
  );

  return { columns, mappedFilterFields, mappedSortFields };
};
