import { CamelCasePath, useTableLayoutProps } from '@libs/common/v2';

import { useProxiesQuery } from '../../api';
import { ProxyClient } from '../../model/proxy.model';

import useProxyColumns from './useProxyColumns';

export function useProxyTable(
  { applicationId, folderId }: { applicationId: string; folderId: string },
  visibleColumns: Array<CamelCasePath<ProxyClient>>
) {
  const { columns, mappedFilterFields, mappedSortField } = useProxyColumns(visibleColumns);

  return useTableLayoutProps({
    tableHookQuery: useProxiesQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortField
    },
    tableHookQueryInitialParams: {
      applicationId,
      folderId
    }
  });
}

export default useProxyTable;
