import { useMemo } from 'react';

import { Chip } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';

import { DomainDictionaryEnum } from '@libs/domain/config';
import { FOLDER_TYPE_COLOR_CONFIG, FolderTypeColorTypes } from '@libs/domain/folder/model';

interface IProps {
  typeKey: string;
}

function FolderTypeChip({ typeKey }: IProps) {
  const { translate } = useDictionaryTranslations();

  const folderTypeColorDefinition = (color: FolderTypeColorTypes) => {
    switch (color) {
      case FolderTypeColorTypes.GREEN:
        return 'success';
      case FolderTypeColorTypes.INDIGO:
        return 'indigo';
      case FolderTypeColorTypes.YELLOW:
        return 'warning';
      case FolderTypeColorTypes.BLUE:
        return 'saturatedBlue';
      case FolderTypeColorTypes.RED:
        return 'error';
      case FolderTypeColorTypes.PURPLE:
        return 'purple';
      case FolderTypeColorTypes.ORANGE:
        return 'orange';
      case FolderTypeColorTypes.PINK:
        return 'pink';
      default:
        return 'success';
    }
  };

  const typeKeyDefinitionItem = useMemo(
    () => FOLDER_TYPE_COLOR_CONFIG.find(definition => definition.typeKey === typeKey),
    [typeKey]
  );

  return (
    <Chip
      title={translate(DomainDictionaryEnum.FOLDER_TYPE, typeKey)}
      color={folderTypeColorDefinition(typeKeyDefinitionItem?.color)}
      colorWeight="700"
    />
  );
}

export default FolderTypeChip;
