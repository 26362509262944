import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';

import { TableButton, TableIconButton, useTableAdapter } from '@libs/common/v2';

interface IProps<T extends Record<string, any>> {
  row: Row<T>;
  isIcon?: boolean;
  onClick?: () => void;
}

/**
 * Komponent przeznaczony do użycia przy tabeli mutowalnej,
 * anuluje edycję wiersza. Jest domyślnie użyty w TableActions
 */
function TableCancelEditRow<T extends Record<string, any>>({ row, isIcon = true, onClick }: IProps<T>) {
  const [t] = useTranslation();
  const table = useTableAdapter();

  const handleOnClick = () => {
    if (table.state.headerGroups?.length) {
      table.cancelInsertingColumns();
      return onClick?.();
    }
    table.cancelEditCreateRow();
    return onClick?.();
  };

  return isIcon ? (
    <TableIconButton icon="CrossIcon" row={row} tooltipTitle={t('action.cancel')} onClick={handleOnClick} />
  ) : (
    <TableButton row={row} label={t('action.cancel')} onClick={handleOnClick} />
  );
}

export default TableCancelEditRow;
