import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { AutocompleteSelectField, Dialog, FormV2Context, GridLayout, typedNameV2 } from '@libs/common/v2';

import { DictionaryEntryValue, getDictionaryValues, useDictionary } from '@libs/dictionary';
import { useElementVisibility } from '@libs/permission';

import { DomainDictionaryEntry, DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';

import { ICreateProceedingFormFields } from '../../model';

interface IProps {
  folderId: string;
  closeDialog: () => void;
  onSubmitRedirect: (proceedingTypeKey: string, folderId: string) => void;
}

function CreateProceedingDialog({ folderId, closeDialog, onSubmitRedirect }: IProps) {
  const [t] = useTranslation();
  const { checkIsElementVisible } = useElementVisibility();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        proceedingType: Yup.object().nullable().required()
      })
    ),
    defaultValues: {
      proceedingType: null
    }
  });

  const onSubmit = formData => {
    onSubmitRedirect(formData.proceedingType.value, folderId);
    closeDialog();
  };

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  const proceedingTypes: DictionaryEntryValue[] = getDictionaryValues(
    useDictionary(DomainDictionaryEnum.PROCEEDING_TYPE)
  ).filter(item => {
    if (item.value === DomainDictionaryEntry.PROCEEDING_TYPE.VERIFICATION) {
      return item.active && checkIsElementVisible(DomainUIElementEnum.PROCEEDING_VERIFICATION_SELECT_OPTION);
    }
    if (item.value === DomainDictionaryEntry.PROCEEDING_TYPE.ADMINISTRATIVE) {
      return item.active && checkIsElementVisible(DomainUIElementEnum.PROCEEDING_ADMINISTRATIVE_SELECT_OPTION);
    }
    return true;
  });

  return (
    <Dialog
      title={t('proceeding:dialog.createProceedingDialogTitle')}
      confirmText={t('action.save')}
      cancelText={t('action.cancel')}
      onConfirm={() => handleSubmit(onSubmit)()}
      onCancel={closeDialog}
      maxWidth="md"
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <form>
          <GridLayout itemProps={{ xs: 12 }}>
            <AutocompleteSelectField
              name={typedNameV2<ICreateProceedingFormFields>('proceedingType')}
              label={t('proceeding:fields.proceedingType')}
              options={proceedingTypes}
              isRequired
            />
          </GridLayout>
        </form>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default CreateProceedingDialog;
