import { EkmtCarnetSettlementUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface IParams {
  ekmtSettlementId: string;
  ekmtCarnetSettlementUpdateRequest: EkmtCarnetSettlementUpdateRequest;
}

function updateEkmtCarnetSettlement({ ekmtSettlementId, ekmtCarnetSettlementUpdateRequest }: IParams) {
  return API.foreignPermissions.updateEkmtCarnetSettlement(ekmtSettlementId, ekmtCarnetSettlementUpdateRequest, {
    ...endpointsConfig.updateEkmtCarnetSettlement
  });
}

function useUpdateEkmtCarnetSettlementMutation() {
  return useMutation(updateEkmtCarnetSettlement);
}

export default useUpdateEkmtCarnetSettlementMutation;
