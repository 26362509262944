import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';

import { useDialog } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { ResourceQueryKeysEnum, useCreateResourceTypeMutation } from '../../../api';

import { fetchResourceTypesDuplicates, parseResourceFormFieldsToResourceCreateRequest } from './form-type.utils';
import { ResourceTypeDialog } from './ResourceTypeDialog';

export const CreateResourceTypeDialog = ({ closeDialog }: { closeDialog: () => void }) => {
  const { mutate: createResourceType } = useCreateResourceTypeMutation();
  const { showSnackbar } = useSnackbar();
  const [t] = useTranslation();
  const queryCache = useQueryCache();
  return (
    <ResourceTypeDialog
      closeDialog={closeDialog}
      title={t('resource:create.title')}
      handleCheckDuplicateResourceTypes={async params => {
        const results = await fetchResourceTypesDuplicates(params);
        return { hasDuplicate: Boolean(results.length) };
      }}
      freeSaleDefaultValues={{ chargePermissions: true }}
      onConfirm={({ formData, setIsLoading }) => {
        setIsLoading(true);
        const requestData = parseResourceFormFieldsToResourceCreateRequest(formData);

        createResourceType(requestData, {
          onSuccess: () => {
            queryCache.invalidateQueries(ResourceQueryKeysEnum.RESOURCE_TYPES_LIST);
            showSnackbar('success', t('resource:messages.createNewFormSuccess'));
            closeDialog();
          },
          onSettled: () => {
            setIsLoading(false);
          }
        });
      }}
    />
  );
};
export const useResourceTypeCreateDialog = () => {
  const { openDialog } = useDialog();
  const openCreateResourceTypeDialog = () => {
    openDialog(({ closeDialog }) => <CreateResourceTypeDialog closeDialog={closeDialog} />);
  };

  return {
    openCreateResourceTypeDialog
  };
};
