import { useCallback, useEffect, useMemo } from 'react';
import { FieldNamesMarkedBoolean, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AlertTypeSnapshot, ReportDataSetDefinitionDetailsSnapshot } from '@stack/report';

import { FormMode, useConfirmDialog, useFormV2Context, useFormV2Watch, useViewModesV2 } from '@libs/common/v2';

import { ConditionOperator } from '@libs/report-core/model';

import { parseConditionsToFormValue } from '../../utils/converter.util';

interface IUseConditionSectionProps {
  name: FieldPath<FieldValues>;
  dataSetIndex?: number;
  queryName: FieldPath<FieldValues>;
  formMode: FormMode;
  basicModeName: string;
  queryDefinitionsColumnsName?: string;
  initialValues?: AlertTypeSnapshot | ReportDataSetDefinitionDetailsSnapshot;
  dirtyFields?: FieldNamesMarkedBoolean<Record<string, any>>;
}

function useConditionsSection({
  name,
  dataSetIndex,
  queryName,
  formMode,
  initialValues,
  queryDefinitionsColumnsName,
  dirtyFields,
  basicModeName
}: IUseConditionSectionProps) {
  const [t] = useTranslation();
  const { getValues, setValue } = useFormV2Context();
  const { createMode, editMode, viewMode } = useViewModesV2(formMode);
  const [confirm] = useConfirmDialog();
  const queryConditionsField = getValues(name);
  const queryField = getValues(queryName);
  const source = getValues(`dataSetDefinitions[${dataSetIndex}].source`);
  const basicMode = useFormV2Watch({ name: basicModeName });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sourceQueryValue = useMemo(() => (createMode ? source?.value?.query : source?.query), [source]);

  useEffect(() => {
    if (basicMode === undefined || basicMode == null) {
      setValue(basicModeName, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const noConditionsDefined = useMemo(
    () => editMode && !queryConditionsField?.group?.length && !queryConditionsField?.filter,
    [queryConditionsField, editMode]
  );

  const initialQueryValue = useMemo(() => initialValues?.query || '', [initialValues]);
  const initialQueryConditionsValue = useMemo(() => {
    const conditions = initialValues?.queryConditions?.filter(Boolean);

    return conditions?.length
      ? parseConditionsToFormValue(conditions)
      : {
          filter: null,
          group: [],
          operator: ConditionOperator.AND
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const confirmDataLoss = (
    dialogParams: {
      title: string;
      message: string;
    },
    enableBasicMode: boolean
  ) => {
    return new Promise<void>(() => {
      confirm({
        ...dialogParams,
        onConfirm: (setConfirmLoading, closeDialog) => {
          setValue(basicModeName, enableBasicMode);
          closeDialog();
        },
        confirmType: 'danger'
      });
    });
  };

  const handleModeChange = useCallback(
    async (enableBasicMode: boolean) => {
      if (enableBasicMode === basicMode) return;
      if (!viewMode) {
        if (!enableBasicMode) {
          if (getValues(`dataSetDefinitions[${dataSetIndex}].queryDefinition.columns`)?.length) {
            await confirmDataLoss(
              {
                title: t('alerts:sqlField.dataLossWarning'),
                message: t('alerts:sqlField.sqlModeWarning')
              },
              enableBasicMode
            );
          }

          setValue(name, {
            filter: null,
            group: [],
            operator: ConditionOperator.AND
          });
          setValue(queryName, createMode ? sourceQueryValue : initialQueryValue, {
            shouldDirty: true
          });
        } else {
          if (
            queryField &&
            queryField !== (createMode ? sourceQueryValue : initialQueryValue) &&
            dirtyFields?.dataSetDefinitions?.[dataSetIndex]?.query?.query
          ) {
            await confirmDataLoss(
              {
                title: t('alerts:sqlField.dataLossWarning'),
                message: t('alerts:sqlField.basicModeWarning')
              },
              enableBasicMode
            );
          }

          setValue(queryName, null);
          setValue(name, initialQueryConditionsValue, {
            shouldDirty: true
          });
        }
      }

      if (queryDefinitionsColumnsName) {
        setValue(queryDefinitionsColumnsName, []);
      }

      setValue(basicModeName, enableBasicMode);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [basicMode, dirtyFields, queryField, initialQueryValue, sourceQueryValue, initialQueryConditionsValue]
  );

  return { basicMode, noConditionsDefined, handleModeChange };
}

export default useConditionsSection;
