import { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RefetchOptions } from 'react-query/types/core/query';
import { useParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { useSnackbar } from '@enigma/fe-ui';
import { FolderCreateRequest, FolderDetailsExtended, NoteUpdateRequest } from '@ibtm/domain';
import { makeStyles } from '@mui/styles';
import { isEmpty, isEqual, omit } from 'lodash';

import {
  FormActionEnum,
  LoaderCircular,
  Page,
  Tab,
  TabsPageSidebar,
  typedNameV2,
  useFormActionModal,
  useFormV2Context,
  useFormV2DirtyWatch,
  useIsSmallScreen,
  useRouter,
  useTab,
  WarningInformation
} from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';
import PageHeader from '@libs/common/v2/templates/page-header/PageHeader';
import { Theme, useThemeValuesContext } from '@libs/common/v2/theme';

import { useDictionaryTranslations } from '@libs/dictionary';
import { RoutePermissionGuard, UIElementNameEnum, useElementVisibility } from '@libs/permission';

import {
  checkIfErrorHasNotAllowedStatusCode,
  DomainDictionaryEntry,
  DomainDictionaryEnum,
  DomainUIElementEnum,
  useDomainConfigContext
} from '@libs/domain/config';
import {
  FolderDetailsParams,
  FolderDetailsTabEnum,
  ParsedFolderDetailsClient,
  subjectContactAddressParser,
  useClientFolderAddressesQuery,
  useCreateFolderMutation,
  useFolderDetailsExtendedQuery,
  useFolderTabDefinitions,
  useGetFolderPermissions,
  useSaveFolder,
  useTabVisibility
} from '@libs/domain/folder';
import { TAB_PERMISSIONS } from '@libs/domain/folder/config/tabPermissions';
import { usePartnershipPartnersQuery, useSubjectDetailsExtendedQuery } from '@libs/domain/subject';

import getDetailsBreadcrumb, {
  getClientHeaderDetails,
  getHeaderDetails,
  parseFolderDetailsFormData
} from '../../utils/details.utils';
import FolderSlider from '../common/folder-slider/FolderSlider';

import FolderTabsContent from './tabs/FolderTabsContent';
import FolderTabsHeader from './tabs/FolderTabsHeader';
import FolderContentWithHeaderWrapper from './FolderContentWithHeaderWrapper';
import FolderDetailsHeader from './FolderDetailsHeader';

interface IProps {
  setSubmitting: (value: boolean) => void;
}
type FolderForm = {
  partners: { customId: string }[];
  typeKey: { value: DomainDictionaryEnum.FOLDER_TYPE; label: string };
  addresses: ParsedFolderDetailsClient['addresses'];
} & FolderCreateRequest;

function FolderDetails({ setSubmitting }: IProps) {
  const { folderId } = useParams<FolderDetailsParams>();

  const [t] = useTranslation();
  const { isClientPortal } = useDomainConfigContext();
  const { translate } = useDictionaryTranslations();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { formMode, formState, handleSubmit, reset, setValue, watch, getValues } = useFormV2Context();
  const { createMode, editMode } = useViewModesV2(formMode);
  const tabChangeAction = useFormActionModal();
  const { isSmallScreen } = useIsSmallScreen();

  const { width } = useWindowSize();
  const globalTheme = useThemeValuesContext();
  const isExtraSmallScreen = width < globalTheme?.breakpoints?.values?.sm;

  const { checkIsElementVisible } = useElementVisibility();

  const getInitialActiveTab = () => {
    if (createMode) return FolderDetailsTabEnum.FOLDER;
    return isClientPortal ? FolderDetailsTabEnum.SUBJECT : FolderDetailsTabEnum.BASIC_DATA;
  };

  const [activeTab, setActiveTab] = useTab<FolderDetailsTabEnum>(getInitialActiveTab());
  const { goToPage, routes } = useRouter();

  const [tabSubmitting, setTabSubmitting] = useState<boolean>(false);

  const {
    data: folderDetails,
    error,
    refetch: refetchFolder,
    isLoading
  } = useFolderDetailsExtendedQuery(folderId, {
    enabled: Boolean(folderId),
    onError: () => {
      goToPage(routes.foldersList());
    }
  });

  const { isSubmitting, saveTab } = useSaveFolder(folderDetails?.typeKey);

  const {
    data: subjectDetails,
    refetch: refetchSubject,
    isLoading: isSubjectDetailsLoading
  } = useSubjectDetailsExtendedQuery(
    folderDetails?.subject?.id,
    {
      enabled: !!folderDetails && checkIsElementVisible(DomainUIElementEnum.FOLDER_SUBJECT_VIEW)
    },
    true
  );

  const { data: addresses } = useClientFolderAddressesQuery({
    enabled: isClientPortal
  });

  const [folderData, setFolderData] = useState<ParsedFolderDetailsClient>(null);
  const [createFolder] = useCreateFolderMutation();
  const classes = useStyles();

  const { data: partnershipPartnersData } = usePartnershipPartnersQuery(
    { subjectId: subjectDetails?.id },
    { enabled: Boolean(subjectDetails?.id) && checkIsElementVisible(DomainUIElementEnum.FOLDER_SUBJECT_VIEW) }
  );

  const typeKey = watch(typedNameV2<ParsedFolderDetailsClient>('typeKey.value')) as string;

  const isMainAddressEditable = useMemo(() => {
    switch (typeKey) {
      case DomainDictionaryEntry.FOLDER_TYPE.KS:
        return checkIsElementVisible(DomainUIElementEnum.FOLDER_MAIN_ADDRESS_KS_UPDATE);
      case DomainDictionaryEntry.FOLDER_TYPE.WITD:
        return checkIsElementVisible(DomainUIElementEnum.FOLDER_MAIN_ADDRESS_WITD_UPDATE);
      case DomainDictionaryEntry.FOLDER_TYPE.ZZ:
        return checkIsElementVisible(DomainUIElementEnum.FOLDER_MAIN_ADDRESS_ZZ_UPDATE);
      default:
        return false;
    }
  }, [checkIsElementVisible, typeKey]);

  useEffect(() => {
    setValue(typedNameV2<ParsedFolderDetailsClient>('isMainAddressEditable'), isMainAddressEditable);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMainAddressEditable]);

  const { isTabVisible } = useTabVisibility(typeKey, subjectDetails?.legalFormKey, formMode);

  const customHandleChangeForNoteSection = useCallback((value: string | NoteUpdateRequest) => {
    setValue('note', value, { shouldDirty: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isRoadTransportHeaderWarning = useMemo(
    () =>
      !isClientPortal &&
      activeTab === FolderDetailsTabEnum.BASIC_DATA &&
      (folderDetails as FolderDetailsExtended)?.transportManagerGoodReputationLostByProceedingKreptd,
    [activeTab, folderDetails, isClientPortal]
  );

  const roadTransportWarning = useMemo(
    () =>
      isRoadTransportHeaderWarning ? (
        <div className={classes.roadTransportWarning}>
          <WarningInformation content={t('transportManager:message.roadTransportNoGoodReputation')} />
        </div>
      ) : null,
    [classes.roadTransportWarning, isRoadTransportHeaderWarning, t]
  );

  const folderTabs = useFolderTabDefinitions(
    typeKey,
    formMode,
    folderData?.number,
    refetchSubject,
    refetchFolder,
    customHandleChangeForNoteSection,
    isMainAddressEditable,
    {
      header: roadTransportWarning
    }
  );

  // sprawdz permisje useElementVisibility
  const isAllowedToViewTab = useCallback(
    (key: UIElementNameEnum) => (key ? checkIsElementVisible(key) : true),
    [checkIsElementVisible]
  );
  const visibleTabs = folderTabs.filter(
    ({ value }) => isTabVisible(value) && isAllowedToViewTab(TAB_PERMISSIONS[value])
  );

  const visibleTabHeaders = visibleTabs.map(({ label, value, icon }) => (
    <Tab label={label} value={value} key={value} icon={icon} isDisabled={isSubmitting} />
  ));

  const visibleTabHeadersIconsOnly = visibleTabs.map(({ label, value, icon }) => (
    <Tab value={value} key={value} icon={icon} isDisabled={isSubmitting} isIconTab tooltip={label} />
  ));

  useEffect(() => {
    if (folderDetails) {
      const folderDetailsData = folderDetails;
      if (isClientPortal) {
        folderDetails.addresses = addresses?.content || [];
        (folderDetailsData as FolderDetailsExtended).printingSettings = { displayAddress: addresses?.displayAddress };
      }
      setFolderData(
        parseFolderDetailsFormData(folderDetailsData, subjectDetails, partnershipPartnersData?.partnershipPartners)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderDetails, subjectDetails, addresses, partnershipPartnersData]);

  useEffect(() => {
    setSubmitting(tabSubmitting || isSubmitting || isLoading || isSubjectDetailsLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSubmitting, isSubmitting, isLoading, isSubjectDetailsLoading]);

  useEffect(() => {
    if (folderData) {
      const currentData = getValues();
      reset({ ...currentData, ...folderData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderData, reset]);

  useEffect(() => {
    if (createMode && typeKey && typeKey !== DomainDictionaryEntry.FOLDER_TYPE.MZ) {
      setValue(typedNameV2<FolderCreateRequest>('subject.countryOfOrigin'), DomainDictionaryEntry.COUNTRY_CODE.POLAND);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeKey]);

  const refetch = (options?: RefetchOptions) => {
    refetchFolder(options);
    refetchSubject(options);
  };

  const onSubmit = (
    values: {
      partners: { customId: string }[];
      typeKey: { value: DomainDictionaryEnum.FOLDER_TYPE; label: string };
      addresses: ParsedFolderDetailsClient['addresses'];
    } & FolderCreateRequest
  ) => {
    setSubmitting(true);

    const subjectContactAddresses = subjectContactAddressParser(values);

    const getValueOrNull = (value: string) => {
      if (isEqual(value, '')) {
        return null;
      }

      return value;
    };

    const parsedValues: FolderCreateRequest = {
      ...omit(values, ['addresses', 'isMainAddressEditable', 'previousCountry', 'printingSettings']),
      typeKey: values.typeKey.value,
      subject: {
        ...omit(values.subject, ['contactAddresses']),
        ...(!isEmpty(subjectContactAddresses) && { contactAddresses: subjectContactAddresses }),
        nip: getValueOrNull(values.subject.nip),
        regon: getValueOrNull(values.subject.regon),
        krs: getValueOrNull(values.subject.krs),
        countryOfOrigin: values.subject.countryOfOrigin,
        legalFormKey: values.subject.legalFormKey,
        subjectTypeKey: values.subject?.subjectTypeKey,
        ...(values?.addresses && {
          addresses: Object.values(values?.addresses as ParsedFolderDetailsClient['addresses'])?.map(address => {
            const typeKey = typeof address.typeKey === 'object' ? address.typeKey.value : address.typeKey;
            Reflect.deleteProperty(address, 'typeKey');
            return {
              sameAsMainAddress: address.sameAsMainAddress,
              typeKey,
              ...(address.sameAsMainAddress && { addressSourceProxyId: address?.addressSourceProxy?.id }),
              ...(address.sameAsMainAddress
                ? {}
                : {
                    address: {
                      ...omit(address, ['typekey']),
                      voivodeshipKey: (address.voivodeshipKey && typeof address.voivodeshipKey === 'object'
                        ? address.voivodeshipKey.value
                        : address.voivodeshipKey) as DomainDictionaryEnum.VOIVODESHIP,
                      countryCodeKey: values.subject.countryOfOrigin
                    }
                  })
            };
          })
        })
      },
      partners: values?.partners?.map(({ customId }) => customId)
    };
    createFolder(parsedValues, {
      onSuccess: ({ id }) => {
        showSuccessSnackbar(t('folder:details.message.folderAdded'));
        goToPage(routes.folderDetails(id));
      },
      onError: () => {
        showErrorSnackbar(t('error.formValidationErrors'));
      },
      onSettled: () => {
        setSubmitting(false);
      }
    });
  };

  const title = useMemo(() => {
    if (createMode) {
      return t('folder:newFolder');
    }
    if (editMode) {
      return t('folder:editFolder');
    }
    return t('folder:detailsFolder');
  }, [createMode, editMode, t]);

  const getHeaderContent = () => {
    if (isClientPortal) {
      return getClientHeaderDetails(folderDetails?.number);
    }
    return getHeaderDetails(
      translate(DomainDictionaryEnum.FOLDER_TYPE, folderDetails?.typeKey),
      folderDetails?.number,
      folderDetails?.modified,
      folderDetails?.modifier?.name
    );
  };

  const { isDirty } = useFormV2DirtyWatch();

  const changeTab = useCallback(
    tab => {
      if (editMode && isDirty) {
        if (!folderTabs?.find(definition => definition?.value === activeTab)?.isSaveDisabled) {
          tabChangeAction({
            formAction: FormActionEnum.SAVE_CHANGES,
            onConfirm: () => {
              saveTab(activeTab);
              setActiveTab(tab);
            },
            onCancel: () => {
              reset(folderData);
              setActiveTab(tab);
            }
          });
        } else {
          reset(folderData);
          setActiveTab(tab);
        }
      } else {
        setActiveTab(tab);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabChangeAction, setActiveTab, saveTab, formState, editMode]
  );
  const isHeaderHidden = useMemo(() => {
    return visibleTabs.find(({ value }) => value === activeTab)?.hideTabHeader;
  }, [activeTab, visibleTabs]);

  const { permissions } = useGetFolderPermissions({
    folderTypeKey: folderDetails?.typeKey,
    tab: activeTab
  });

  const isCurrentTabVisible = useMemo(() => {
    return visibleTabs.find(({ value }) => value === activeTab);
  }, [activeTab, visibleTabs]);

  useEffect(() => {
    if (!isCurrentTabVisible && typeKey) {
      changeTab(getInitialActiveTab());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentTabVisible]);

  const pageHeader = (
    <PageHeader
      title={title}
      isSticky={!isSmallScreen}
      titleCustomStyles={isSmallScreen ? { fontSize: '2rem' } : {}}
      hasNoHeaderPadding={isSmallScreen}
      isTitleColumnReverse={isExtraSmallScreen}
      breadcrumbs={getDetailsBreadcrumb(formMode, folderDetails?.number, folderDetails?.id)}
      typographyClassName={isSmallScreen && 'w-full'}
      rightSideContent={
        <FolderDetailsHeader
          folderDetails={folderDetails}
          formMode={formMode}
          refetch={refetch}
          activeTab={activeTab}
        />
      }
      details={!createMode && getHeaderContent()}
      isDividerShown
    />
  );
  const contentHeader = (
    <FolderTabsHeader
      activeTab={activeTab}
      visibleTabs={visibleTabs}
      isHeaderHidden={isHeaderHidden}
      setSubmitting={setTabSubmitting}
      classes={{
        header: isRoadTransportHeaderWarning ? classes.headerWithWarning : ''
      }}
      folderType={folderDetails?.typeKey}
    />
  );

  return (
    <RoutePermissionGuard
      permissions={permissions}
      hasAllPermissions
      isLoading={isLoading}
      notAllowedCode={checkIfErrorHasNotAllowedStatusCode(error)}
    >
      <form
        className="h-full"
        onSubmit={handleSubmit(onSubmit as SubmitHandler<Partial<FolderForm>>, () => {
          showErrorSnackbar(t('error.formValidationErrors'));
        })}
      >
        <Page
          header={!isSmallScreen && pageHeader}
          content={
            <FolderContentWithHeaderWrapper contentHeader={contentHeader} pageHeader={pageHeader}>
              <FolderTabsContent
                activeTab={activeTab}
                isHeaderHidden={isHeaderHidden}
                setActiveTab={changeTab}
                isTabVisible={isTabVisible}
                visibleTabs={visibleTabs}
                setSubmitting={setTabSubmitting}
                folderType={folderDetails?.typeKey}
              />
            </FolderContentWithHeaderWrapper>
          }
          isContentHeaderHidden={isHeaderHidden}
          isHeaderHidden={isSmallScreen}
          allowScrollbarInContentWithHiddenHeader={isSmallScreen}
          isRightSidebarVisible={checkIsElementVisible(DomainUIElementEnum.FOLDER_SLIDER)}
          contentHeader={!isSmallScreen && contentHeader}
          leftSidebarHeader={t('sections')}
          leftSidebarContent={
            isLoading || isSubjectDetailsLoading ? (
              <div className={classes.leftSidebarLoaderWrapper}>
                <LoaderCircular isLoading={isLoading || isSubjectDetailsLoading} />
              </div>
            ) : (
              <TabsPageSidebar visibleTabs={visibleTabHeaders} activeTab={activeTab} onChange={changeTab} />
            )
          }
          leftSidebarContentNotExpanded={
            <TabsPageSidebar visibleTabs={visibleTabHeadersIconsOnly} activeTab={activeTab} onChange={changeTab} />
          }
          leftSidebarClassName={classes.leftSidebarContent}
          leftSidebarMinWidth="260px"
          rightSidebarContent={
            <FolderSlider
              folderId={folderId}
              isGotoFolderHidden
              hasSubjectDataPermission={checkIsElementVisible(DomainUIElementEnum.FOLDER_SUBJECT_VIEW)}
            />
          }
          isContentScrollEnabled={!isSmallScreen}
          isLeftSidebarOpen
          isRightSidebarOpen
          isLeftSidebarScrollEnabled
        />
      </form>
    </RoutePermissionGuard>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  leftSidebarLoaderWrapper: {
    minWidth: '280px'
  },
  roadTransportWarning: {
    paddingBottom: theme.marginBase * 2,
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  headerWithWarning: {
    paddingBottom: 0,
    borderBottom: 'none'
  }
}));

export default FolderDetails;
