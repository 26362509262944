import { useDialog } from '@libs/common/v2';

import { ApplicationReciptTypeDialog } from '../components/print-details';

const useChangeApplicationReciptType = (applicationId: string) => {
  const { openDialog } = useDialog();

  const changeApplicationReciptType = () => {
    return openDialog(({ closeDialog }) => (
      <ApplicationReciptTypeDialog closeDialog={closeDialog} applicationId={applicationId} />
    ));
  };

  return { changeApplicationReciptType };
};

export default useChangeApplicationReciptType;
