import { ProceedingCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const createProceeding = (params: ProceedingCreateRequest) => {
  return API.proceeding.createProceeding(params, { ...endpointsConfig.createProceeding });
};
export const useProceedingCreateMutation = () => useMutation(createProceeding);
