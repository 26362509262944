enum PrintsQueryKeysEnum {
  PRINTS_SIGNER = 'PRINTS_SIGNER',
  PRINTS_LICENSES = 'PRINTS_LICENSES',
  PRINTS_PERMISSIONS = 'PRINTS_PERMISSIONS',
  PRINTS_LAST_PRINTED_PERMISSIONS = 'PRINTS_LAST_PRINTED_PERMISSIONS',
  PRINTS_FOREIGN_PERMISSION_LIST = 'PRINTS_FOREIGN_PERMISSION_LIST',
  PRINTS_DETAILS = 'PRINTS_DETAILS',
  PRINTS_TRAVEL_FORM_LIST = 'PRINTS_TRAVEL_FORM_LIST',
  PRINTS_EKMT_CARNETS_LIST = 'PRINTS_EKMT_CARNETS_LIST'
}

export default PrintsQueryKeysEnum;
