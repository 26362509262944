import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountSnapshot } from '@avispon/user/dist/models';

import { TableButton } from '@libs/common/v2';

import { RestoreUserWithPasswordDialog, useUserStatusActionsDefinition } from '@libs/user';

interface IProps {
  userDetails: AccountSnapshot;
  withNewPassword: boolean;
  onAccept: () => void;
  onCancel?: () => void;
}

function RestoreUserPopUp({ userDetails, withNewPassword, onAccept, onCancel }: IProps) {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { handleChangeUserStatusToRestoreWithNoPassword, handleUserRestoreWithPasswordSubmit } =
    useUserStatusActionsDefinition({
      userDetails,
      refetchUserDetails: onAccept
    });

  return (
    <>
      <RestoreUserWithPasswordDialog
        onConfirm={handleUserRestoreWithPasswordSubmit}
        onCancel={() => onCancel()}
        isOpen={isOpen}
      />
      <TableButton
        label={t('action.restore')}
        onClick={() => (withNewPassword ? setIsOpen(true) : handleChangeUserStatusToRestoreWithNoPassword)}
      />
    </>
  );
}

export default RestoreUserPopUp;
