import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AccountsApiChangePasswordRequest, PasswordChangeCommand } from '@avispon/user';
import { useSnackbar } from '@enigma/fe-ui';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { FormMode, FormV2Context, GridLayout, Page } from '@libs/common/v2';
import { important } from '@libs/common/v2/utils';

import { useAuth } from '@libs/auth/hooks';
import { API } from '@libs/user/api';
import { ChangePasswordDialog } from '@libs/user/components/common';
import { ConfigurationContextProvider } from '@libs/user/context';
import { ChangeProfilePasswordValidation } from '@libs/user/models';

import { PersonalDataSection } from '../tab';

import ProfilePageHeader from './ProfilePageHeader';

interface ProfilePageProps {
  getAuthorizationHeader: () => string;
  changePasswordSchema?: ChangeProfilePasswordValidation;
}

function ProfileSimplePage({ getAuthorizationHeader, changePasswordSchema }: ProfilePageProps) {
  const [t] = useTranslation();
  const [showResetPassword, setShowResetPassword] = useState(false);

  const { showSuccessSnackbar } = useSnackbar();
  const { user } = useAuth();

  const handleChangePassword = (data: PasswordChangeCommand) => {
    const requestData: AccountsApiChangePasswordRequest = {
      authorization: getAuthorizationHeader(),
      body: data
    };
    API.user.accountController
      .changePassword(requestData)
      .then(() => {
        setShowResetPassword(false);
        showSuccessSnackbar(t('user:message.passwordChanged'));
      })
      .catch(() => {});
  };
  const form = useForm<Record<string, any>>({
    defaultValues: { ...user, email: user?.personalData?.email }
  });

  const formDataProvider = useMemo(() => {
    return { ...form, formMode: FormMode.VIEW };
  }, [form]);
  const classes = useStyle();

  return (
    <ConfigurationContextProvider>
      <FormV2Context.Provider value={formDataProvider}>
        <Page
          contentClassName={clsx('flex-col', classes.content)}
          contentWrapperClassName={classes.contentWrapper}
          isTable
          header={<ProfilePageHeader setShowResetPassword={setShowResetPassword} />}
          content={
            <>
              <GridLayout itemProps={{ xs: 12 }}>
                <PersonalDataSection />
              </GridLayout>
              <ChangePasswordDialog
                open={showResetPassword}
                onCancel={() => setShowResetPassword(false)}
                onConfirm={handleChangePassword}
                changePasswordSchema={changePasswordSchema}
              />
            </>
          }
        />
      </FormV2Context.Provider>
    </ConfigurationContextProvider>
  );
}
const useStyle = makeStyles(() => ({
  content: {
    paddingBottom: important('0')
  },
  contentWrapper: {
    height: important('min-content')
  }
}));

export default ProfileSimplePage;
