import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CamelCasePath,
  DropdownListButton,
  useMultipleSelectColumn,
  useTableAdapter,
  useTableLayoutProps
} from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';

import { DomainUIElementEnum } from '@libs/domain/config';

import { useProceedingsQuery } from '../../api';
import { AssignedPersonChangeForm } from '../../components/form';
import { IProceedingClient } from '../../models';
import { useProceedingColumns } from '../columns';
import { useResourceDialog } from '..';

function SelectionActionRender(selectedIds: string[], handleClose: () => void) {
  return <Actions selectedIds={selectedIds} handleClose={handleClose} />;
}

function Actions({ selectedIds, handleClose }: { selectedIds: string[]; handleClose: () => void }) {
  const [t] = useTranslation();
  const table = useTableAdapter();
  const { openDialog } = useResourceDialog();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedRows = useMemo(() => table.selectedFlatRows.map(item => item.original), [table, selectedIds]);

  const handleAssignUser = (dialogTitle?: string) => {
    openDialog(AssignedPersonChangeForm, {
      initialData: selectedRows,
      invokeLevel: 'PROCEEDIGNS',
      dialogTitle
    });
    handleClose();
  };

  const isTransferEnabled = useMemo(() => selectedRows.every(item => !item.assignedName), [selectedRows]);

  return (
    <>
      {isTransferEnabled && (
        <DropdownListButton
          label={t('roadTransport:actions.transfer')}
          onClick={() => handleAssignUser(t('roadTransport:dialog.transferProceedingTitle'))}
          actionKey={DomainUIElementEnum.ROAD_TRANSPORT_TRANSFER_BUTTON}
        />
      )}
      {!isTransferEnabled && (
        <DropdownListButton
          label={t('roadTransport:actions.changeAssignedPerson')}
          onClick={() => handleAssignUser(t('roadTransport:dialog.changeAssignedPersonTitle'))}
          actionKey={DomainUIElementEnum.ROAD_TRANSPORT_TRANSFER_BUTTON}
        />
      )}
    </>
  );
}

function useProceedingsTable(
  visibleColumns: Array<CamelCasePath<IProceedingClient>>,
  isRowSelectionEnabled: boolean,
  initialParams = {}
) {
  const { columns, mappedFilterFields, mappedSortFields } = useProceedingColumns(visibleColumns);
  const multipleSelectColumn = useMultipleSelectColumn(SelectionActionRender);
  const { checkIsElementVisible } = useElementVisibility();

  return useTableLayoutProps({
    tableHookQuery: useProceedingsQuery,
    tableHookOptions: {
      columns,
      ...(isRowSelectionEnabled &&
        checkIsElementVisible(DomainUIElementEnum.ROAD_TRANSPORT_TRANSFER_BUTTON) && {
          tablePlugins: [multipleSelectColumn]
        }),
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    },
    tableHookQueryInitialParams: initialParams
  });
}

export default useProceedingsTable;
