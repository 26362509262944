import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { PluginHook, Row, SortingRule } from 'react-table';
import { useSnackbar } from '@enigma/fe-ui';
import { LicenseCopySnapshot } from '@ibtm/domain';

import { TranslationFrom } from '@libs/common';
import { TableLayout } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { UIElementNameEnum } from '@libs/permission';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import PermissionWarnings from '../../permission/components/PermissionWarnings';
import { useUpdateLicenseCopyMutation } from '../api';
import LicensesQueryKeysEnum from '../api/queries/LicensesQueryKeysEnum';
import { DEFAULT_COLUMNS } from '../config/license-copies-columns';
import { useLicenseCopiesTable, useLicensesValidation } from '../hooks';
import { LicenseCopiesColumnsEnum, LicenseCopySnapshotClient } from '../model/license.model';
import { parseLicenseCopiesList } from '../parsers/common';

interface IProps {
  applicationId?: string;
  titleKey?: TranslationFrom<'permission:title.permission'> | 'license-copies';
  folderId?: string;
  proceedingId?: string;
  visibleColumns?: Array<LicenseCopiesColumnsEnum | { column: LicenseCopiesColumnsEnum; isEditable: boolean }>;
  defaultFilters?: {
    id: string;
    value: any;
  }[];
  actionsColumnWidth?: number;
  headerActions?: ReactNode;
  rowActions?: (row: Row<LicenseCopySnapshotClient>) => ReactNode;
  tablePlugins?: Array<PluginHook<LicenseCopySnapshotClient>>;
  actionKey?: UIElementNameEnum;
  isWarningEnabled?: boolean;
  isTableMutable?: boolean;
  includePartnershipPermissions?: boolean;
  defaultSort?: SortingRule<LicenseCopySnapshotClient>[];
  isTableDataVisible?: boolean;
  isHeaderActions?: boolean;
}

function LicenseCopiesTable({
  applicationId,
  folderId,
  proceedingId,
  visibleColumns = DEFAULT_COLUMNS,
  defaultFilters,
  rowActions,
  headerActions,
  tablePlugins = [],
  titleKey,
  actionsColumnWidth,
  actionKey,
  isWarningEnabled = true,
  isTableMutable,
  includePartnershipPermissions,
  defaultSort,
  isTableDataVisible = true,
  isHeaderActions = true
}: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { getQuery } = useGetApiQueryByPerspective();

  const tableProps = useLicenseCopiesTable(
    visibleColumns,
    applicationId,
    folderId,
    proceedingId,
    defaultFilters,
    tablePlugins,
    isTableMutable,
    includePartnershipPermissions,
    defaultSort,
    isTableDataVisible
  );
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbar();
  const { getLicenseValidationScheme } = useLicensesValidation({ visibleLicenseCopiesColumns: visibleColumns });

  const { mutate: updateLicense } = useUpdateLicenseCopyMutation();

  const onRowEdit = (data: LicenseCopySnapshotClient, { onSuccess }) => {
    const { validTo, version, printDate, validFrom } = data;
    return updateLicense(
      {
        id: data.id,
        validFrom,
        version,
        printDate,
        validTo
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('success.save'));
          queryCache.invalidateQueries(LicensesQueryKeysEnum.LICENSE_COPIES_LIST);
          onSuccess();
        },
        onError: () => showErrorSnackbar(t('global:error.dataSaveFailure'))
      }
    );
  };
  const xslsDownloadQuery = getQuery(
    params =>
      API.client.license
        .getLicenseCopySnapshotPage('', '', params)
        .then(res => ({ ...res, data: { ...res.data, content: parseLicenseCopiesList(res.data.content) } })),
    params =>
      API.license
        .getLicenseCopySnapshotPage(params)
        .then(res => ({ ...res, data: { ...res.data, content: parseLicenseCopiesList(res.data.content) } }))
  );
  return (
    <TableLayout<LicenseCopySnapshotClient, LicenseCopySnapshot>
      {...tableProps}
      pageTitle={t('license:title.licenseCopies')}
      xlsxDownload={{
        fileName: t('license:title.licenseCopies'),
        pathToXLSXTranslation: 'license:fields',
        apiRequest: xslsDownloadQuery
      }}
      editFormYupResolverSchema={getLicenseValidationScheme}
      rowActions={row => rowActions?.(row)}
      actionsColumnWidth={actionsColumnWidth}
      onRowEditCreateConfirm={onRowEdit}
      headerActions={isHeaderActions && headerActions}
      actionKey={actionKey}
      isActionColumnEnabled={Boolean(rowActions)}
      isTableMutable
      isSection
      isCollapsable
      isRefreshEnabled
    >
      {isWarningEnabled && <PermissionWarnings applicationId={null} titleKey={titleKey} folderId={folderId} />}
    </TableLayout>
  );
}

export default LicenseCopiesTable;
