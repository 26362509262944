import { useTranslation } from 'react-i18next';

import { Button } from '@libs/common/v2';

import { useTransportManagerSearchAction } from '../../hooks';

interface IProps {
  folderId: string;
  applicationId: string;
  transferredFoldersIds?: string[];
  actionKey?: string;
  isDisabled?: boolean;
  disabledTooltip?: string;
}

function TransportManagersSearchButton({
  folderId,
  applicationId,
  transferredFoldersIds,
  actionKey,
  isDisabled,
  disabledTooltip
}: IProps) {
  const [t] = useTranslation();
  const { openTransportManagersSearchModal } = useTransportManagerSearchAction({
    folderId,
    applicationId,
    transferredFoldersIds
  });

  return (
    <Button
      type="button"
      variant="outlined"
      onClick={openTransportManagersSearchModal}
      label={t('transportManager:search.title')}
      actionKey={actionKey}
      disabled={isDisabled}
      {...(isDisabled && { tooltipTitle: disabledTooltip })}
    />
  );
}

export default TransportManagersSearchButton;
