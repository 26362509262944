import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { makeStyles } from '@mui/styles';
import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import * as XLSX from 'xlsx';

import {
  DATE_TIME_FORMAT,
  IconButton,
  TableButton,
  useCancellablePromise,
  useTableAdapter,
  useTableContext
} from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';

import { IPaginatedModel, ITableLayoutColumn } from '../../model';
import { convertFiltersToObject, convertParamsToObject, convertSortParamToQuery } from '../../utils';

const MAX_EXPORT_ELEMENTS = 4000;
const XLS_MAX_COLUMN_WIDTH = 100;
const XLS_NAME_FORBIDDEN_CHARACTES_REGEX = /[[\]*/\\?:]/g;

type ApiRequestParams = { page: number; size: number; sort?: any; [_: string]: any };

interface IProps<T> {
  apiRequest?: (parameters: ApiRequestParams) => Promise<AxiosResponse<IPaginatedModel<T>>>;
  fileName: string;
  additionalParams?: Record<string, any>;
  pathToXLSXTranslation: string;
  mappedFilterFields?: Record<string, any>;
  mappedSortFields?: Record<string, any>;
  mappedParamsFields?: Record<string, any>;
  className?: string;
  tableQueryParams?: Record<string, unknown>;
  onFileGenerated?: () => void;
  isMobile?: boolean;
}

function XLSXDownloader<
  T extends Record<string, any> = Record<string, any>,
  K extends Record<string, any> = Record<string, any>
>({
  apiRequest,
  fileName,
  pathToXLSXTranslation,
  additionalParams,
  mappedParamsFields,
  mappedFilterFields,
  className,
  mappedSortFields,
  tableQueryParams,
  onFileGenerated,
  isMobile
}: IProps<T>) {
  const [t] = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const cancellable = useCancellablePromise();
  const table = useTableAdapter();
  const {
    state: { filters, sortBy },
    columns,
    rows
  }: any = table;

  const {
    isLoading: tableInstanceLoading,
    tableLoading,
    checkDisabled,
    isError,
    resolvedData: { totalElements } = {}
  } = useTableContext();

  const buttonLoading = loading || tableInstanceLoading || tableLoading;

  const downloadData = async () => {
    const params = { page: 0, size: 500 };
    const sort = sortBy;

    const convertedFilters = convertFiltersToObject(filters || [], mappedFilterFields ?? null);
    const sortParam = _.isEmpty(sort) ? null : { sort: convertSortParamToQuery(sort, mappedSortFields ?? null) };
    const additionalParam = convertParamsToObject(additionalParams || {}, mappedParamsFields);

    const data = [];
    let response = null;

    do {
      // eslint-disable-next-line no-await-in-loop
      response = await cancellable(
        apiRequest({ ...params, ...convertedFilters, ...sortParam, ...additionalParam, ...tableQueryParams })
      );
      if (!response) {
        return [];
      }
      if (response.data.totalElements > MAX_EXPORT_ELEMENTS) {
        return null;
      }
      data.push(...response.data.content);
      params.page += 1;
    } while (data.length < response.data.totalElements);
    return data;
  };

  const formatFieldValue = (record, column: ITableLayoutColumn<K, T>): string => {
    if (record && column) {
      if (_.isFunction(column.xlsxFormatter)) {
        return column.xlsxFormatter(record);
      }
      if (_.isFunction(column.accessor)) {
        return (_.get(column, 'accessor') as <D>(row: D) => any)(record);
      }
      return record[column.id];
    }
    return t('emptyMark');
  };

  const prepareXLSX = async () => {
    const data = apiRequest ? await downloadData() : rows.map(element => element?.original);
    if (!data) {
      return null;
    }
    const json = data.map(item =>
      columns.reduce((acc, column) => {
        const accTemp = acc;

        if (!column.xlsxSkip) {
          accTemp[typeof column.Header === 'string' ? column.Header : t<any>(`${pathToXLSXTranslation}.${column.id}`)] =
            formatFieldValue(item, column);
        }
        return accTemp;
      }, {})
    );
    return json;
  };

  function stringToArrayBuffer(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; // eslint-disable-line no-bitwise
    return buf;
  }

  function autofitColumns(json: any[], worksheet: XLSX.WorkSheet) {
    const jsonKeys = json[0] ? Object.keys(json[0]) : [];
    const worksheetTemp = worksheet;

    const objectMaxLength = [];
    for (let i = 0; i < json.length; i++) {
      const value = json[i];
      for (let j = 0; j < jsonKeys.length; j++) {
        if (typeof value[jsonKeys[j]] === 'number') {
          objectMaxLength[j] = 10;
        } else {
          const l = value[jsonKeys[j]] ? value[jsonKeys[j]].length : 0;

          objectMaxLength[j] = objectMaxLength[j] >= l ? objectMaxLength[j] : l;
        }
      }

      const key = jsonKeys;
      for (let j = 0; j < key.length; j++) {
        objectMaxLength[j] = objectMaxLength[j] >= key[j].length ? objectMaxLength[j] : key[j].length;
        objectMaxLength[j] = Math.min(objectMaxLength[j], XLS_MAX_COLUMN_WIDTH);
      }
    }

    worksheetTemp['!cols'] = objectMaxLength.map(w => {
      return { width: w };
    });
  }

  const downloadXLSX = async () => {
    setLoading(true);
    try {
      const preparedData = await prepareXLSX();
      if (!preparedData) {
        showSnackbar('error', t('error.XLSXDownloadError'));
        setLoading(false);
        return;
      }
      const sheetNameWithoutSpecialCharacters = fileName.replace(XLS_NAME_FORBIDDEN_CHARACTES_REGEX, '');
      const sheetName = `${sheetNameWithoutSpecialCharacters}`;
      const name = `${sheetNameWithoutSpecialCharacters}_${moment().format(DATE_TIME_FORMAT)}.xlsx`;
      const workSheet = XLSX.utils.json_to_sheet(preparedData);
      const workbook = XLSX.utils.book_new();
      // Domyślnie nazwa dokumentu XLSX (nie tej, która jest ustawiana przy pobieraniu) nie może przekraczać 31 znaków
      // Alternatywą jest podanie jako trzeci argument stałej nazwy np. "Dokument" albo zostawienie pustego stringa
      XLSX.utils.book_append_sheet(workbook, workSheet, sheetName.substring(0, 31));
      autofitColumns(preparedData, workSheet);
      const bin = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
      const blob = new Blob([stringToArrayBuffer(bin)], { type: 'application/octet-stream' });
      FileSaver.saveAs(blob, name);
      onFileGenerated?.();
      setLoading(false);
    } catch (e) {
      showSnackbar('error', t('error.xlsxConversionError'));
      setLoading(false);
    }
  };

  const isDownloadAvailable: boolean = !totalElements || totalElements <= MAX_EXPORT_ELEMENTS;

  const classes = useStyles();

  if (isMobile) {
    return (
      <TableButton
        label={isDownloadAvailable ? t('action.downloadXLSX') : t('error.XLSXDownloadNotAvailable')}
        onClick={() => {
          downloadXLSX();
        }}
        isButtonLoading={loading}
        isDisabled={!isDownloadAvailable || buttonLoading || checkDisabled(table) || isError}
      />
    );
  }
  return (
    <IconButton
      className={clsx(className, classes.wrapper)}
      icon="DownloadIcon"
      tooltipTitle={isDownloadAvailable ? t('action.downloadXLSX') : t('error.XLSXDownloadNotAvailable')}
      onClick={downloadXLSX}
      isLoading={loading}
      isDisabled={!isDownloadAvailable || buttonLoading || checkDisabled(table) || isError}
      height={20}
      width={20}
      isBackgroundTransparent
    />
  );
}

const useStyles = makeStyles<Theme>(() => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '40px'
  }
}));

export default XLSXDownloader;
