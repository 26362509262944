import { useMutation } from 'react-query';
import { ApplicationDocumentCreateRequest } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export interface ISuspensionDocumentCreateParams {
  suspensionId: string;
  data: ApplicationDocumentCreateRequest;
}

function generateDocument({ suspensionId, data }: ISuspensionDocumentCreateParams) {
  return API.suspensions.addSuspensionDocument(suspensionId, data, {
    ...endpointsConfig.addSuspensionDocument
  });
}

function useGenerateSuspensionDocumentMutation() {
  return useMutation(generateDocument);
}

export default useGenerateSuspensionDocumentMutation;
