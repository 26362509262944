import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import {
  ExploitationBaseInspectionDetailsAllOf,
  ExploitationBasesSearchFilter,
  ExploitationBasesSnapshotPage
} from '@ibtm/domain';

import {
  Button,
  DropdownButton,
  DropdownListButton,
  typedNameV2,
  useFormV2Context,
  useIsSmallScreen,
  useRouter
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { useViewModesV2 } from '@libs/common/v2/form';
import { convertCalendarDate, convertDateToDateFormat, MAX_INT } from '@libs/common/v2/utils';

import { useDictionaryTranslations } from '@libs/dictionary';

import { ApplicationQueryKeysEnum } from '@libs/domain/application';
import {
  DomainDictionaryEntry,
  DomainDictionaryEnum,
  DomainUIElementEnum,
  useDomainConfigContext
} from '@libs/domain/config';
import {
  ExploitationBaseFormData,
  ExploitationBaseInspectionSnapshot,
  ExploitationBaseQueryKeysEnum,
  ExploitationBaseStatusEnum,
  parseExploitationBaseCreateRequest,
  parseExploitationBaseUpdateRequest,
  useExploitationBaseCreateMutation,
  useExploitationBasesQuery,
  useExploitationBaseUpdateMutation,
  useGeoportalMapModal
} from '@libs/domain/exploitation-base';

interface IProps {
  id: string;
  applicationId?: string;
  permissionKeys?: {
    showGeoportal: DomainUIElementEnum;
  };
  setCheckedBaseInspections: (inspections: ExploitationBaseInspectionSnapshot[]) => void;
}

function ExploitatoinBaseDetailsActions({ id, applicationId, permissionKeys, setCheckedBaseInspections }: IProps) {
  const queryCache = useQueryCache();
  const { goBack } = useRouter();
  const [t] = useTranslation();
  const { isSmallScreen } = useIsSmallScreen();
  const { showSnackbar, showSuccessSnackbar } = useSnackbar();
  const { showMap } = useGeoportalMapModal();
  const { translate } = useDictionaryTranslations();

  const [inspectionsParams, setInspectionsParams] = useState<ExploitationBasesSearchFilter>(null);
  const { refetch, isLoading, isFetching } = useExploitationBasesQuery(
    { ...inspectionsParams, size: MAX_INT },
    {
      enabled: false,
      onSuccess: data => onInspectionDataFetched(data)
    }
  );

  const { mutate: editExploitationBase, isLoading: isEditLoading } = useExploitationBaseUpdateMutation();
  const { mutate: createExploitationBase, isLoading: isCreateLoading } = useExploitationBaseCreateMutation();
  const { trigger, handleSubmit, formMode, getValues, reset } = useFormV2Context();
  const { editMode, createMode } = useViewModesV2(formMode);
  const { isOperatorPortal } = useDomainConfigContext();

  const onInspectionDataFetched = (data: ExploitationBasesSnapshotPage) => {
    if (data) {
      const prepareData: ExploitationBaseInspectionDetailsAllOf[] = [];

      data.content?.forEach(({ inspections }, index) => {
        if (inspections?.length > 0) {
          inspections?.forEach((item, inspectionIndex) =>
            prepareData.push({ id: `${index}${inspectionIndex}`, ...item })
          );
        }
      });
      switch (prepareData.length) {
        case 0:
          showSnackbar('warning', t('exploitationBase:details.message.baseWasNotControlled'));
          break;
        case 1:
          showSuccessSnackbar(
            t('exploitationBase:details.message.baseWasControlled', {
              controlResult: translate(
                DomainDictionaryEnum.EXPLOITATION_BASE_INSPECTION_RESULT,
                prepareData[0].inspectionResultKey
              ),
              controlDate: convertDateToDateFormat(convertCalendarDate(prepareData[0].inspectionDate)),
              remarks: prepareData[0].remarks
            })
          );
          break;
        default:
          setCheckedBaseInspections(
            prepareData.map(element => ({ ...element, inspectionDate: convertCalendarDate(element.inspectionDate) }))
          );
          showSuccessSnackbar(t('exploitationBase:details.message.baseWasControlledMultipleTimes'));
      }
    }
  };

  const handleEdit = (editData: ExploitationBaseFormData) => {
    const parsedData = parseExploitationBaseUpdateRequest(editData);
    editExploitationBase(
      {
        exploitationBaseId: id,
        exploitationBaseUpdateRequest: parsedData
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('exploitationBase:details.message.baseUpdated'));
          queryCache.invalidateQueries(ExploitationBaseQueryKeysEnum.EXPLOITATION_BASE_LIST);
          goBack();
        }
      }
    );
  };

  const onSuccess = () => {
    showSuccessSnackbar(t('exploitationBase:details.message.baseCreated'));
    queryCache.invalidateQueries(ExploitationBaseQueryKeysEnum.EXPLOITATION_BASE_LIST);
  };

  const handleCreate = (createData: ExploitationBaseFormData) => {
    createExploitationBase(parseExploitationBaseCreateRequest(createData), {
      onSuccess: () => {
        if (applicationId) {
          queryCache.invalidateQueries([ApplicationQueryKeysEnum.APPLICATION, applicationId]);
        }
        onSuccess();
        goBack();
      }
    });
  };

  const handleCreateAndNext = (createData: ExploitationBaseFormData) => {
    createExploitationBase(parseExploitationBaseCreateRequest(createData), {
      onSuccess: () => {
        onSuccess();
        reset({
          applicationId,
          statusKey: { value: ExploitationBaseStatusEnum.NEW },
          addressTypeKey: { value: DomainDictionaryEntry.ADDRESS_TYPE.BASE }
        });
      }
    });
  };

  const formSubmitMutation = createMode ? handleCreate : handleEdit;

  const validateAddress = () =>
    trigger([
      typedNameV2<ExploitationBaseFormData>('address.city'),
      typedNameV2<ExploitationBaseFormData>('address.street'),
      typedNameV2<ExploitationBaseFormData>('address.propertyNumber')
    ]);

  const checkInspections = async () => {
    const isAddressValid = await validateAddress();
    if (!isAddressValid) {
      showSnackbar('warning', t('exploitationBase:details.message.addressDataNotCompleted'));
      return;
    }

    const { address } = getValues() as ExploitationBaseFormData;
    setInspectionsParams({
      cityContains: address.city,
      streetContains: address.street,
      propertyNumberContains: address.propertyNumber
    });
  };

  useEffect(() => {
    if (inspectionsParams) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionsParams]);

  const showGeoportalMap = () => {
    validateAddress().then(isValid => {
      if (isValid) {
        const values = getValues() as ExploitationBaseFormData;
        showMap(values.address.city, values.address.street, values.address.propertyNumber);
      } else {
        showSnackbar('warning', t('exploitationBase:details.message.addressDataNotCompleted'));
      }
    });
  };

  const isCheckInspectionsButtonVisible = (editMode || createMode) && isOperatorPortal;
  const isAddNextButtonVisible = createMode;

  return (
    <>
      {isCheckInspectionsButtonVisible && !isSmallScreen && (
        <Button
          variant="outlined"
          onClick={checkInspections}
          isLoading={isLoading || isFetching}
          label={t('exploitationBase:details.button.checkInspections')}
          isNoMargin
        />
      )}

      {!isSmallScreen && (
        <Button
          isSecondary
          variant="outlined"
          onClick={showGeoportalMap}
          label={t('exploitationBase:geoportal.button')}
          actionKey={permissionKeys?.showGeoportal}
          isNoMargin
        />
      )}

      {isAddNextButtonVisible && !isSmallScreen && (
        <Button
          variant="outlined"
          onClick={handleSubmit(handleCreateAndNext as any)}
          label={t('action.addNext')}
          isLoading={isCreateLoading || isEditLoading}
          isNoMargin
        />
      )}

      {(editMode || createMode) && (
        <Button
          isPrimary
          onClick={handleSubmit(formSubmitMutation as any)}
          label={createMode ? t('action.add') : t('action.save')}
          isLoading={isCreateLoading || isEditLoading}
          isNoMargin
        />
      )}
      {isSmallScreen && (
        <DropdownButton>
          {({ handleClose }) => (
            <>
              {isCheckInspectionsButtonVisible && (
                <DropdownListButton
                  onClick={() => {
                    checkInspections();
                    handleClose();
                  }}
                  label={t('exploitationBase:details.button.checkInspections')}
                />
              )}
              <DropdownListButton
                onClick={() => {
                  showGeoportalMap();
                  handleClose();
                }}
                label={t('exploitationBase:geoportal.button')}
                actionKey={permissionKeys?.showGeoportal}
              />
              {isAddNextButtonVisible && (
                <DropdownListButton
                  onClick={() => {
                    handleSubmit(handleCreateAndNext as any)();
                    handleClose();
                  }}
                  label={t('action.addNext')}
                />
              )}
            </>
          )}
        </DropdownButton>
      )}
    </>
  );
}

export default ExploitatoinBaseDetailsActions;
