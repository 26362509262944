import { ElementType } from 'react';
import { Chip as MuiChip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _ from 'lodash';

import { CrossIcon, Icon, IconType, Typography } from '@libs/common/v2';
import { ThemeVariant } from '@libs/common/v2/components/typography/themeVariants';
import { PaletteOption, PaletteOptions, Theme, useTheme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

interface IProps {
  themeVariant?: ThemeVariant;
  className?: string;
  color?: keyof PaletteOptions;
  colorWeight?: keyof PaletteOption;
  backgroundColorWeight?: keyof PaletteOption;
  size?: 'small' | 'medium';
  title?: string;
  icon?: IconType;
  iconClassName?: string;
  typography?: {
    isWhiteText?: boolean;
    customStyles?: React.CSSProperties & { [_: string]: unknown };
    component?: ElementType<any>;
    typographyClassName?: string;
  };
  onDelete?: () => void;
  isTableCell?: boolean;
}

function Chip({
  icon,
  color = 'blue',
  colorWeight = '800',
  backgroundColorWeight = '50',
  size = 'medium',
  className,
  title,
  isTableCell,
  themeVariant = isTableCell ? 'textSm.normal' : 'textSm.medium',
  typography,
  onDelete = null,
  iconClassName
}: IProps) {
  const { contrast } = useTheme();
  const classes = useStyles({ color, colorWeight, isTableCell, contrast, backgroundColorWeight });

  return (
    <MuiChip
      className={clsx(classes.root, className)}
      size={size}
      icon={icon && <Icon className={iconClassName} icon={icon} />}
      data-testid={title}
      label={
        <Typography
          themeVariant={themeVariant}
          className={classes.textWrapper}
          component={typography?.component ?? 'p'}
          {...typography}
        >
          <span className={classes.text}>{_.truncate(title, { length: 50 })}</span>
        </Typography>
      }
      deleteIcon={<CrossIcon />}
      onDelete={onDelete ?? null}
      title={_.size(title) > 50 ? title : ''}
    />
  );
}

const useStyles = makeStyles<
  Theme,
  {
    color: keyof PaletteOptions;
    colorWeight: keyof PaletteOption;
    isTableCell: boolean;
    contrast: boolean;
    backgroundColorWeight: keyof PaletteOption;
  }
>(theme => ({
  root: {
    backgroundColor: ({ color, backgroundColorWeight, contrast }) =>
      contrast ? theme.palette.primary[950] : theme.palette[color]?.[backgroundColorWeight],
    color: ({ color, colorWeight }) => theme.palette[color]?.[colorWeight],
    padding: ({ isTableCell }) => (isTableCell ? 0 : important('0 10px')),
    '&:hover': {
      backgroundColor: ({ color }) => theme.palette[color]?.[50]
    },
    '& .MuiChip-deleteIcon': {
      width: 18,
      height: 18,
      color: ({ color, colorWeight }) => important(theme.palette[color]?.[colorWeight])
    },
    '& .MuiChip-label': {
      backgroundColor: 'unset',
      paddingLeft: ({ isTableCell }) => isTableCell && 0,
      paddingRight: ({ isTableCell }) => isTableCell && 0
    }
  },
  textWrapper: {
    backgroundColor: 'unset'
  },
  text: {
    color: ({ color, colorWeight, contrast }) =>
      contrast ? theme.palette.grey[50] : theme.palette[color]?.[colorWeight]
  }
}));

export default Chip;
