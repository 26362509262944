import { usePaginatedQuery } from 'react-query';
import { FolderToTransferSearchFilter } from '@ibtm/domain';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { IFoldersToTransferClient } from '../../../models';
import { parseFoldersToTransferListResults } from '../../../utils';
import { RoadTransportQueryKeysEnum } from '../..';
import { endpointsConfig } from '../../endpoints-config';

const getFoldersToTransfer = async (params: FolderToTransferSearchFilter) => {
  const { data } = await API.folder.getFoldersToTransfer(params, {
    ...endpointsConfig.getFoldersToTransfer
  });

  return {
    ...data,
    content: parseFoldersToTransferListResults(data.content)
  };
};

const useFoldersToTransferQuery = (params: FolderToTransferSearchFilter) =>
  usePaginatedQuery<IPaginatedModel<IFoldersToTransferClient>>(
    [RoadTransportQueryKeysEnum.FOLDERS_TO_TRANSFER_LIST, params],
    () => getFoldersToTransfer(params)
  );

export default useFoldersToTransferQuery;
