import { QueryConfig, useQuery } from 'react-query';

import { ApplicationQueryKeysEnum } from '@libs/domain/application/api';
import { ApplicationMetaFormDefinition } from '@libs/domain/application/model';

import { UiDefinitionClientPortalCommon, UiDefinitionCommon } from '../../definitions';

export const getApplicationUICommonDefinition = async () => {
  return { uiDefinitionCommon: UiDefinitionCommon, uiDefinitionClientPortalCommon: UiDefinitionClientPortalCommon };
};

export default function useApplicationUIDefinitionCommonQuery(
  queryConfig: QueryConfig<
    {
      uiDefinitionCommon: ApplicationMetaFormDefinition;
      uiDefinitionClientPortalCommon: ApplicationMetaFormDefinition;
    },
    unknown
  > = {}
) {
  return useQuery(
    [ApplicationQueryKeysEnum.APPLICATION_UI_DEFINITION_COMMON],
    () => getApplicationUICommonDefinition(),
    {
      ...queryConfig
    }
  );
}
