import { ProcessApiFactory, TaskApiFactory } from '@ibtm/process-api';
import axios from 'axios';

const taskApiFactoryFallback: any = () => {
  throw new Error('Task api has not been set');
};

const processApiFactoryFallback: any = () => {
  throw new Error('Process api has not been set');
};

const API: { process: ReturnType<typeof ProcessApiFactory>; task: ReturnType<typeof TaskApiFactory> } = {
  process: processApiFactoryFallback,
  task: taskApiFactoryFallback
};

export function setProcessApi(API_PORTAL_URL: string) {
  API.process = ProcessApiFactory(null, API_PORTAL_URL, axios);
  API.task = TaskApiFactory(null, API_PORTAL_URL, axios);
}

export { API };
