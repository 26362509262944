import { ExternalNamedObject } from '@avispon/group/dist/models';

export enum AccountSelectorDetailsRoles {
  'OPERATOR' = 'OPERATOR',
  'OPERATOR_MANAGER' = 'OPERATOR_MANAGER',
  'OPERATOR_TECHNICAL' = 'OPERATOR_TECHNICAL',
  'ADMIN' = 'ADMIN',
  'CORPORATION' = 'CORPORATION',
  'CLIENT' = 'CLIENT'
}
export enum AccountSelectorDetailsStatuses {
  'UNCONFIRMED' = 'UNCONFIRMED',
  'UNVERIFIED' = 'UNVERIFIED',
  'VERIFIED' = 'VERIFIED',
  'REJECTED' = 'REJECTED',
  'BLOCKED' = 'BLOCKED',
  'DISABLED' = 'DISABLED',
  'EXPIRED' = 'EXPIRED',
  'DELETED' = 'DELETED',
  'DISABLED_MIGRATED' = 'DISABLED_MIGRATED'
}

export interface AccountSelectorDetails {
  accounts?: Array<ExternalNamedObject>;
  groupMembershipUnits?: Array<ExternalNamedObject>;
  groups?: Array<ExternalNamedObject>;
  requiredAcceptances?: Array<string>;
  roles?: Array<AccountSelectorDetailsRoles>;
  statuses?: Array<AccountSelectorDetailsStatuses>;
}

export interface AccountSelectorSnapshot {
  accountIds?: Array<string>;
  requiredAcceptances?: Array<string>;
  roles?: Array<AccountSelectorDetailsRoles>;
  statuses?: Array<AccountSelectorDetailsStatuses>;
  groupIds?: Array<string>;
  groupMembershipUnitIds?: Array<string>;
}

export interface RecipientSelectorSnapshot {
  accountSelector?: AccountSelectorSnapshot;
  emails?: Array<string>;
}

export interface RecipientSelectorDetails {
  accountSelector?: AccountSelectorDetails;
  emails?: Array<string>;
}
