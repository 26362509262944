import { useCallback, useContext, useMemo } from 'react';
import { TransitScheduleStopSearchFilter } from '@ibtm/domain';

import {
  ColumnTypesEnum,
  ISingleColumn,
  SelectOption,
  TableFilterMapper,
  useColumns,
  useCreateColumns
} from '@libs/common/v2';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { ScheduleApplicationGroupsEnum, TransitStopDetailsApp } from '../model';
import { TransitScheduleContext } from '../pages';

function useTransitScheduleColumns() {
  const { createColumnsWithDataAccess, createColumns } = useCreateColumns<TransitStopDetailsApp>({
    pathToTranslate: 'applications:transitSchedule.fields',
    isMutableTable: true
  });
  const { applicationTypeKey } = useContext(TransitScheduleContext);

  const simpleColumns: ISingleColumn<TransitStopDetailsApp>[] = useMemo(
    () => [
      {
        id: 'ordinal',
        accessor: 'ordinal',
        header: 'stopNumber',
        type: ColumnTypesEnum.NUMBER,
        isSortable: false
      },
      {
        id: 'typeKey',
        accessor: 'typeKey',
        type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
        dictionaryName: DomainDictionaryEnum.STOP_TYPE,
        isSortable: false,
        width: 200
      },
      {
        id: 'directionKey',
        accessor: 'directionKey',
        type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
        dictionaryName: DomainDictionaryEnum.TRANSIT_DIRECTION,
        isSortable: false,
        width: 200
      },
      {
        id: 'branchRoute',
        accessor: 'branchRoute',
        type: ColumnTypesEnum.BOOLEAN,
        defaultValue: true,
        isSortable: false
      },
      {
        id: 'city',
        accessor: 'city',
        type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
        dictionaryName: DomainDictionaryEnum.TRANSIT_LOCATION,
        isFreeSolo: true,
        isSortable: false,
        width: 200
      },
      {
        id: 'countryKey',
        accessor: 'countryKey',
        type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
        dictionaryName: DomainDictionaryEnum.COUNTRY_CODE,
        isSortable: false,
        width: 200
      },
      {
        id: 'name',
        accessor: 'name',
        header: 'address',
        type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
        dictionaryName: DomainDictionaryEnum.STOP_ADDRESS,
        isFreeSolo: true,
        isSortable: false,
        width: 200
      },
      {
        id: 'agreementTypeKey',
        accessor: 'agreementTypeKey',
        header: 'stopAgreementType',
        type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
        dictionaryName: DomainDictionaryEnum.STOP_AGREEMENT_TYPE,
        isSortable: false,
        width: 200
      },
      {
        id: 'city2',
        accessor: 'city2',
        header: 'cityKey2',
        type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
        dictionaryName: DomainDictionaryEnum.TRANSIT_LOCATION,
        isSortable: false,
        width: 200
      },
      {
        id: 'countryKey2',
        accessor: 'countryKey2',
        header: 'countryKey2',
        type: ColumnTypesEnum.DICTIONARY_SINGLE_SELECT,
        dictionaryName: DomainDictionaryEnum.COUNTRY_CODE,
        isSortable: false,
        width: 200
      },
      {
        id: 'kilometers',
        accessor: 'kilometers',
        type: ColumnTypesEnum.NUMBER,
        isSortable: false,
        width: 200
      }
    ],
    []
  );

  const columsWithArray = useCallback(
    (mapper: Record<string, number>) =>
      createColumnsWithDataAccess({
        mapper,
        columns: [
          ...simpleColumns,
          {
            id: 'departures',
            type: ColumnTypesEnum.ARRAY_COLUMN,
            // Funkcja do wyrenderowania dla tablicy odpowiednich kolumn. Kolejność obiektów
            // przyjazd/odjazd nie jest posortowana w tablicy tylko posiada atrybut ordinal (który nie jest 0-based),
            //  dlatego zamiast index przekazujemy tutaj do id ordinal -1 żeby dopasować do indexu.
            // Sortowanie tych godzin odbywa się przy query po dane do tabeli.
            nestedArrayColumns: (index, passedMapper) => [
              {
                id: `departures.${passedMapper?.ordinal ? passedMapper.ordinal - 1 : index}.departure`,
                header: 'departure',
                type: ColumnTypesEnum.TIME,
                isEditable: false,
                isFilterable: false,
                width: 200,
                isSortable: false
              },
              {
                id: `departures.${passedMapper?.ordinal ? passedMapper.ordinal - 1 : index}.arrival`,
                header: 'arrival',
                type: ColumnTypesEnum.TIME,
                isEditable: false,
                isFilterable: false,
                width: 200,
                isSortable: false
              }
            ]
          }
        ]
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const defaultColumns = createColumns(simpleColumns);

  const spoOutsideUe = useColumns<TransitStopDetailsApp>({
    columns: defaultColumns,
    hiddenColumns: ['directionKey', 'branchRoute', 'kilometers']
  });
  const spoWzoWzw = useColumns<TransitStopDetailsApp>({
    columns: defaultColumns,
    hiddenColumns: ['directionKey', 'branchRoute', 'name', 'agreementTypeKey', 'kilometers']
  });
  const spoTransit = useColumns<TransitStopDetailsApp>({
    columns: defaultColumns,
    hiddenColumns: ['directionKey', 'branchRoute', 'agreementTypeKey', 'kilometers']
  });

  const columns = useMemo(() => {
    if (applicationTypeKey.includes(ScheduleApplicationGroupsEnum.SPO_UE)) {
      return columsWithArray;
    }
    if (applicationTypeKey.includes(ScheduleApplicationGroupsEnum.SPO_OUTSIDE_UE)) {
      return spoOutsideUe;
    }
    if (applicationTypeKey.includes(ScheduleApplicationGroupsEnum.SPO_WZO)) {
      return spoWzoWzw;
    }
    if (applicationTypeKey.includes(ScheduleApplicationGroupsEnum.SPO_WZW)) {
      return spoWzoWzw;
    }
    if (applicationTypeKey.includes(ScheduleApplicationGroupsEnum.SPO_TRANSIT_RP)) {
      return spoTransit;
    }
    if (applicationTypeKey.includes(ScheduleApplicationGroupsEnum.SPO_OPINION)) {
      return columsWithArray;
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationTypeKey]);

  const mappedFilterFields: TableFilterMapper<TransitStopDetailsApp, TransitScheduleStopSearchFilter> = {
    ordinal: ordinal => ({ ordinalLessThanOrEqual: ordinal, ordinalGreaterThanOrEqual: ordinal }),
    typeKey: (typeKey: SelectOption<string>) => ({ typeKeyIn: [typeKey.value] }),
    directionKey: (directionKey: SelectOption<string>) => ({ directionKeyIn: [directionKey.value] }),
    branchRoute: (branchRoute: SelectOption<boolean>) => ({ mainRoute: !branchRoute.value }),
    city: (city: SelectOption<string>) => ({ cityContains: city.value }),
    countryKey: (countryKey: SelectOption<string>) => ({ countryKeyIn: [countryKey.value] }),
    city2: (city2: SelectOption<string>) => ({ city2Contains: city2.value }),
    countryKey2: (countryKey2: SelectOption<string>) => ({ countryKey2In: [countryKey2.value] }),
    kilometers: kilometers => ({ kilometersLessThanOrEqual: kilometers, kilometersGreaterThanOrEqual: kilometers })
  };

  return {
    columns,
    mappedFilterFields
  };
}

export default useTransitScheduleColumns;
