import { ResourceObjectOrderSearchFilter } from '@ibtm/domain';

import { SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO, TAmountRange } from '@libs/common/v2/utils';

import { DomainDictionaryEnum } from '@libs/domain/config';

import { IAssociationOrdersClient } from '../../model';

const useAssociationOrdersColumns = () => {
  const { createColumns } = useCreateColumns<IAssociationOrdersClient>({
    pathToTranslate: 'resource:fields'
  });

  const mappedSortFields: TableSortMapper<IAssociationOrdersClient> = {
    association: 'folder.number',
    country: 'countryCodeKey',
    name: 'resourceType.nameKey',
    direction: 'directionKey',
    status: 'statusKey'
  };

  const mappedFilterFields: TableFilterMapper<IAssociationOrdersClient, ResourceObjectOrderSearchFilter> = {
    created: ({ dateFrom, dateTo }: DateRangeISO) => ({
      createdGreaterThanOrEqual: dateFrom,
      createdLessThanOrEqual: dateTo
    }),
    association: (association: string) => ({ associationFolderNumberContains: association }),
    country: (countries: SelectOption<string>[]) => ({ countryCodeKeyIn: countries.map(country => country.value) }),
    name: (names: SelectOption<string>[]) => ({ nameKeyIn: names.map(name => name.value) }),
    amount: (amountRange: TAmountRange) => ({
      amountToGreaterThanOrEqual: amountRange.min,
      amountToLessThanOrEqual: amountRange.max
    }),
    direction: (directions: SelectOption<string>[]) => ({
      directionKeyIn: directions.map(direction => direction.value)
    }),
    status: (statuses: SelectOption<string>[]) => ({ statusKeyIn: statuses.map(status => status.value) })
  };

  const columns = createColumns([
    {
      header: 'date',
      type: 'DATE_TIME',
      accessor: 'created',
      width: 255
    },
    {
      type: 'TEXT',
      accessor: 'association',
      width: 250
    },
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.COUNTRY_CODE,
      accessor: 'country',
      width: 200
    },
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_NAME,
      accessor: 'name',
      width: 300
    },
    {
      type: 'NUMBER_RANGE',
      accessor: 'amount',
      isSortable: false,
      width: 150
    },
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_ORDER_DIRECTION,
      accessor: 'direction'
    },
    {
      type: 'DICTIONARY_MULTI_SELECT',
      dictionaryName: DomainDictionaryEnum.RESOURCE_FORM_ORDER_STATUS,
      accessor: 'status'
    }
  ]);

  return { columns, mappedSortFields, mappedFilterFields };
};

export default useAssociationOrdersColumns;
