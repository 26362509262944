import { AlertRoutes } from '@libs/alert/config';
import { DictionaryRoutes } from '@libs/dictionary/configs';
import { GroupRoutes } from '@libs/group/config';
import { NotificationRoutes } from '@libs/notification/config/routes.config';
import { OrganizationStructureRoutes } from '@libs/organization-structure/config';
import { ReportRoutes } from '@libs/report/config';
import { UserRoutes } from '@libs/user/config';

import { DomainRoutes } from '@libs/domain/config/routes.config';

export const routeGuardRoutes = {
  ...DomainRoutes,
  ...ReportRoutes,
  ...OrganizationStructureRoutes,
  ...UserRoutes,
  ...GroupRoutes,
  ...DictionaryRoutes,
  ...AlertRoutes,
  ...NotificationRoutes
};
