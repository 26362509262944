import { ProxyUpdateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function updateProxy({ proxyId, updateRequestData }: { proxyId: string; updateRequestData: ProxyUpdateRequest }) {
  return API.proxy.updateProxy(proxyId, updateRequestData, {
    ...endpointsConfig.updateProxy
  });
}

function updateProxyClient({ proxyId, updateRequestData }: { proxyId: string; updateRequestData: ProxyUpdateRequest }) {
  return API.client.proxy.updateProxy(proxyId, '', '', updateRequestData, {
    ...endpointsConfig.updateProxy
  });
}

function useUpdateProxyMutation() {
  const { getQuery } = useGetApiQueryByPerspective();

  return useMutation(getQuery(updateProxyClient, updateProxy));
}

export default useUpdateProxyMutation;
