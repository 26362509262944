import { PluginHook, TableOptions } from 'react-table';
import { ExploitationBaseSnapshot, ExploitationBasesSearchFilter } from '@ibtm/domain';

import { CamelCasePath, useTableLayoutProps } from '@libs/common/v2';

import { useExploitationBasesQuery } from '../api';

import useExploitationBaseTableColumns from './useExploitationBaseTableColumns';

function useExploitationBaseTable({
  params,
  visibleColumns,
  tableOptions,
  tablePlugins
}: {
  params?: ExploitationBasesSearchFilter;
  visibleColumns?: Array<CamelCasePath<ExploitationBaseSnapshot>>;
  tableOptions?: Partial<TableOptions<ExploitationBaseSnapshot>>;
  tablePlugins?: PluginHook<ExploitationBaseSnapshot>[];
}) {
  const { columns, mappedFilterFields, mappedSortFields } = useExploitationBaseTableColumns(visibleColumns);

  return useTableLayoutProps({
    tableHookQuery: useExploitationBasesQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields,
      tableOptions,
      tablePlugins
    },
    tableHookQueryInitialParams: params as { [key: string]: any }
  });
}

export default useExploitationBaseTable;
