import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FolderCreateRequest } from '@ibtm/domain';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { AutocompleteSelectField, typedNameV2, useErrorsHandlerV2, useFormV2Context } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';

import { DictionaryEntryValue, getDictionaryValues, useDictionary } from '@libs/dictionary';
import { useElementVisibility } from '@libs/permission/hooks';

import { DomainDictionaryEntry, DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';
import { FolderDetailsParams, RegisteredFolderFormEnum } from '@libs/domain/folder';

function FolderTab() {
  const { type } = useParams<FolderDetailsParams>();

  const classes = useStyles();
  const [t] = useTranslation();
  const { setValue } = useFormV2Context();

  useEffect(() => {
    if (type)
      setValue(
        typedNameV2<FolderCreateRequest>('typeKey'),
        folderTypes.find(folderType => folderType.value === type) || ''
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const folderTypes: DictionaryEntryValue[] = getDictionaryValues(
    useDictionary(DomainDictionaryEnum.FOLDER_TYPE)
  ).filter(i => i.active);

  const { containerRef } = useErrorsHandlerV2(null, RegisteredFolderFormEnum.FOLDER_FORM);
  const { checkIsElementVisible } = useElementVisibility();

  const visibleElements = useMemo(() => {
    return {
      [DomainDictionaryEntry.FOLDER_TYPE.OP]: checkIsElementVisible(DomainUIElementEnum.FOLDER_OP_CREATE),
      [DomainDictionaryEntry.FOLDER_TYPE.TP]: checkIsElementVisible(DomainUIElementEnum.FOLDER_TP_CREATE),
      [DomainDictionaryEntry.FOLDER_TYPE.PPO]: checkIsElementVisible(DomainUIElementEnum.FOLDER_PPO_CREATE),
      [DomainDictionaryEntry.FOLDER_TYPE.MZ]: checkIsElementVisible(DomainUIElementEnum.FOLDER_MZ_CREATE),
      [DomainDictionaryEntry.FOLDER_TYPE.PA]: checkIsElementVisible(DomainUIElementEnum.FOLDER_PA_CREATE),
      [DomainDictionaryEntry.FOLDER_TYPE.KS]: checkIsElementVisible(DomainUIElementEnum.FOLDER_KS_CREATE),
      [DomainDictionaryEntry.FOLDER_TYPE.WITD]: checkIsElementVisible(DomainUIElementEnum.FOLDER_WITD_CREATE),
      [DomainDictionaryEntry.FOLDER_TYPE.ZZ]: checkIsElementVisible(DomainUIElementEnum.FOLDER_ZZ_CREATE)
    };
  }, [checkIsElementVisible]);

  const getOptions = useMemo(() => {
    return folderTypes
      .filter(({ value }) => visibleElements[value])
      .map(({ name, value }) => ({
        name,
        value
      }));
  }, [folderTypes, visibleElements]);

  return (
    <Grid container ref={containerRef} spacing={0}>
      <Grid item xs={12}>
        <span className={classes.subTitle}>{t('folder:addFolderHelper')}</span>
        <ul className={classes.list}>
          {folderTypes.map(({ name }) => (
            <li key={`folder-${name}`}>
              {name}: {t<any>(`folder:type.${name}`)}
            </li>
          ))}
        </ul>
      </Grid>
      <Grid item xs={12}>
        <AutocompleteSelectField
          name={`${typedNameV2<FolderCreateRequest>('typeKey')}` as const}
          label={t('field.type')}
          options={getOptions}
          isRequired
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles<Theme>(() => ({
  subTitle: {
    display: 'block',
    marginTop: '1.5rem'
  },
  list: {
    marginBottom: 22
  },
  field: {
    width: 300
  }
}));

export default FolderTab;
