import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import clsx from 'clsx';

import {
  DictionarySelectField,
  GridLayout,
  InputMode,
  SelectWithFetchedOptionsField,
  TextInputField,
  typedNameV2,
  useFormV2Watch
} from '@libs/common/v2';

import { DictionaryEntryNameEnum } from '@libs/dictionary/models/dictionaryEntryNameEnum';
import { API } from '@libs/report/api';
import { ReportDictionaryEntryNameEnum } from '@libs/report-core';
import { useReportDataSourceColumns } from '@libs/report-core/hooks';
import { DataSource } from '@libs/report-core/hooks/useReportDataSourceColumns';

import { ReportTypeColumns } from './ReportTypeColumns';
import { ReportTypeConditions } from './ReportTypeConditions';

interface IProps {
  index: number;
}

function DataSetContentV2({ index }: IProps) {
  const [t] = useTranslation();
  const source = useFormV2Watch({ name: `dataSetDefinitions[${index}].source` }) as DataSource;
  const sourceColumns = useReportDataSourceColumns(source);
  const isColumnSectionVisible = Boolean(source?.id);

  const basicMode = useFormV2Watch({ name: `dataSetDefinitions[${index}].basicMode` });
  const isColumnVisible = useMemo(
    () => Boolean(basicMode && (isColumnSectionVisible || sourceColumns.length)),
    [basicMode, isColumnSectionVisible, sourceColumns]
  );

  return (
    <>
      <div className={clsx('w-full pt-24 flex items-center justify-between')}>
        <GridLayout itemProps={{ xs: 6 }}>
          <TextInputField
            name={`dataSetDefinitions.${index}.name`}
            label={t('reports:field.dataSetName')}
            inputMode={InputMode.VIEW}
          />
          <SelectWithFetchedOptionsField
            fetchFunction={text => API.mediatorDataSources.getReportSources(0, 10, null, null, null, text)}
            getOptionLabel={source => source.name}
            label={t('reports:field.reportSource')}
            name={`dataSetDefinitions.${index}.source`}
            inputMode={InputMode.VIEW}
          />
          <DictionarySelectField
            name={typedNameV2<any>(`dataSetDefinitions[${index}].dataFileFormatKey`)}
            label={t('reports:reportTypes.dataFileFormatKey')}
            dictionaryName={ReportDictionaryEntryNameEnum.REPORT_DATASET_RESULT_FILE_FORMAT}
            inputMode={InputMode.VIEW}
          />
          <DictionarySelectField
            name={`dataSetDefinitions.${index}.source.value.requiredRole`}
            label={t('reports:field.requiredRole')}
            dictionaryName={DictionaryEntryNameEnum.PERMISSION}
            inputMode={InputMode.VIEW}
          />
        </GridLayout>
      </div>
      <Grid container wrap="nowrap">
        {isColumnVisible && (
          <Grid item xs={4}>
            <ReportTypeColumns />
          </Grid>
        )}
        <Grid item xs={isColumnVisible ? 8 : 12}>
          <ReportTypeConditions />
        </Grid>
      </Grid>
    </>
  );
}

export default React.memo(DataSetContentV2);
