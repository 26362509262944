import { useTranslation } from 'react-i18next';
import { SuspensionSearchFilter, SuspensionSnapshot } from '@ibtm/domain';

import { CamelCasePath, SelectOption, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO, getCalendarDate } from '@libs/common/v2/utils';

import { UserFilterV2 } from '@libs/user';

export function useSuspensionsColumns(visibleColumns: Array<CamelCasePath<SuspensionSnapshot>>) {
  const [t] = useTranslation();
  const { createColumns } = useCreateColumns<SuspensionSnapshot>({ pathToTranslate: 'suspensions:table' });

  const mappedFilterFields: TableFilterMapper<SuspensionSnapshot, SuspensionSearchFilter> = {
    number: (number: string) => ({ numberContains: number }),
    caseNumber: (caseNumber: string) => ({ caseNumberContains: caseNumber }),
    created: ({ dateFrom, dateTo }: DateRangeISO) => ({
      createdGreaterThanOrEqual: dateFrom,
      createdLessThanOrEqual: dateTo
    }),
    subjectNip: (nip: string) => ({ nipContains: nip }),
    subjectName: (subjectName: string) => ({ subjectNameContains: subjectName }),
    authorName: (authors: SelectOption<string>[]) => ({ authorIdIn: authors.map(({ value }) => value) }),
    modified: ({ dateFrom, dateTo }: DateRangeISO) => ({
      modifiedGreaterThanOrEqual: dateFrom,
      modifiedLessThanOrEqual: dateTo
    }),
    dateOfIssue: ({ dateFrom, dateTo }: DateRangeISO) => ({
      dateOfIssueGreaterThanOrEqual: getCalendarDate(dateFrom),
      dateOfIssueLessThanOrEqual: getCalendarDate(dateTo)
    }),
    dateFrom: ({ dateFrom, dateTo }: DateRangeISO) => ({
      dateFromGreaterThanOrEqual: getCalendarDate(dateFrom),
      dateFromLessThanOrEqual: getCalendarDate(dateTo)
    }),
    dateTo: ({ dateFrom, dateTo }: DateRangeISO) => ({
      dateToGreaterThanOrEqual: getCalendarDate(dateFrom),
      dateToLessThanOrEqual: getCalendarDate(dateTo)
    }),
    whole: (selected: SelectOption<boolean>) => ({ whole: selected.value }),
    statusKey: (statuses: SelectOption<string>[]) => ({ statusKeys: statuses.map(({ value }) => value) })
  };

  const mappedSortFields: TableSortMapper<SuspensionSnapshot> = {
    subjectNip: 'application.subject.nip',
    subjectName: 'application.subject.name'
  };

  const columns = createColumns(
    [
      {
        accessor: 'number',
        type: 'TEXT'
      },
      {
        accessor: 'caseNumber',
        type: 'TEXT'
      },
      {
        accessor: 'created',
        type: 'DATE',
        header: 'dateForm'
      },
      {
        accessor: 'subject.nip',
        header: 'nip',
        type: 'TEXT'
      },
      {
        accessor: 'subject.name',
        header: 'entrepreneur',
        type: 'TEXT'
      },
      {
        accessor: 'author.name',
        header: 'author',
        type: 'CUSTOM_COLUMN',
        filter: UserFilterV2
      },
      {
        accessor: 'modified',
        type: 'DATE'
      },
      {
        accessor: 'dateOfIssue',
        header: 'applicationDate',
        type: 'DATE'
      },
      {
        accessor: 'dateFrom',
        type: 'DATE'
      },
      {
        accessor: 'dateTo',
        type: 'DATE'
      },
      {
        id: 'whole',
        type: 'BOOLEAN',
        tooltipTitle: ({ whole }) => (whole ? t('action.yes') : t('action.no'))
      },
      {
        accessor: 'statusKey',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: 'SUSPENSION_STATUS'
      }
    ],
    visibleColumns
  );

  return { columns, mappedFilterFields, mappedSortFields };
}
