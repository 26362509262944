import React, { useMemo, useState } from 'react';
import { Resolver } from 'react-hook-form';
import { PaginatedQueryResult, QueryResult } from 'react-query';
import { Row } from 'react-table';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { KeyType } from '@libs/common';
import {
  IPaginatedModel,
  ISingleColumn,
  ITableAdapter,
  ITableBodyProps,
  ITableContext,
  TableContext,
  TableInstanceContext,
  Theme,
  TMutableTableFormContextProvider
} from '@libs/common/v2';
import { calc } from '@libs/common/v2/utils';

import { ITableHeadColumnsProps } from './ui/TableHeadColumns';
import { ITablePaginationProps } from './ui/TablePagination';
import MutableTableProvider from './MutableTableProvider';

interface IProps<T extends Record<string, any>, K extends Record<string, any> = T> {
  table: ITableAdapter<T, K>;
  refetch: () => void;
  restQueryResult:
    | Omit<PaginatedQueryResult<IPaginatedModel<T>>, 'refetch' | 'isError'>
    | Omit<QueryResult<T[], unknown>, 'refetch' | 'isError'>;
  actionsColumnWidth?: number;
  selectionColumnWidth?: number;
  headerContent?: React.ReactNode;
  scrollbarOffset?: number;
  bodyProps?: ITableBodyProps<T, K>;
  editFormYupResolverSchema?: Resolver<Record<string, any>, Record<string, any>>;
  paginationProps?: ITablePaginationProps;
  headerRowProps?: ITableHeadColumnsProps;
  /**
   * Props children służy do przekazania np. dialogu w którym chcemy mieć dostęp do contextu tabeli
   */
  children?: React.ReactNode;
  tableComponent: (props: any) => React.ReactNode;
  className?: string;
  onRowEditCreateConfirm?: (
    row: T,
    context?: {
      isCreated: boolean;
      isEdited: boolean;
      tableContext: ITableContext<T, K>;
      tableInstance: ITableAdapter<T, K>;
      formContext: TMutableTableFormContextProvider;
      onSuccess: () => void;
      onError: () => void;
    }
  ) => Promise<any> | void;
  newRowScheme?: T;
  /**
   * Maksymalna liczba dodatkowych kolumn jakie mogą zostać stworzone
   */
  maxInsertedColumnNumber?: number;
  /**
   * Obiekt z kolumnami do dodania i ścieżką do tłumaczenia header'a
   */
  columnsToInsert?: {
    pathToTranslate: KeyType;
    columns: ISingleColumn<T, K>[];
  };
  checkDisabled?: (table?: ITableAdapter<T, K>, row?: Row<T>) => boolean;
  initiallyChecked?: (table?: ITableAdapter<T, K>, row?: Row<T>) => boolean;
  isLoading?: boolean;
  isFetching?: boolean;
  isTableMutable?: boolean;
  isError?: boolean;
  isTableGrey?: boolean;
  isSection?: boolean;
  isFilterEnabled?: boolean;
  isSectionFullPage?: boolean;
  /**
   * Props mówi czy tabela jest renderowana w komponencie Page z tabami
   */
  isPageTabContent?: boolean;
  hideIfContentEmpty?: boolean;
  isHiddenSelectRowHeader?: boolean;
  headerRef?: React.MutableRefObject<HTMLDivElement>;
}

function TableContextProvider<T extends Record<string, any>, K extends Record<string, any> = T>({
  table,
  refetch,
  restQueryResult,
  actionsColumnWidth = 116,
  selectionColumnWidth,
  headerContent,
  tableComponent,
  newRowScheme,
  children,
  checkDisabled,
  initiallyChecked,
  editFormYupResolverSchema,
  onRowEditCreateConfirm,
  isLoading,
  isFetching,
  isError,
  isTableMutable,
  columnsToInsert,
  maxInsertedColumnNumber = 15,
  className,
  isFilterEnabled,
  isSection,
  isSectionFullPage,
  isPageTabContent,
  hideIfContentEmpty = false,
  isHiddenSelectRowHeader,
  headerRef,
  ...restProps
}: IProps<T, K>) {
  const [tableLoading, setTableLoading] = useState(false);
  const classes = useStyles({ isSection, isSectionFullPage, isPageTabContent });

  const value = useMemo(
    () => ({
      isLoading,
      tableLoading,
      setTableLoading,
      isFetching,
      columnsToInsert,
      newRowScheme,
      checkDisabled,
      initiallyChecked,
      isError,
      maxInsertedColumnNumber,
      onRowEditCreateConfirm,
      isFilterEnabled,
      isTableMutable,
      refetch,
      latestData: {},
      resolvedData: {},
      ...restQueryResult,
      actionsColumnWidth,
      selectionColumnWidth: 48,
      isHiddenSelectRowHeader
    }),
    [
      isLoading,
      newRowScheme,
      tableLoading,
      isFetching,
      columnsToInsert,
      checkDisabled,
      initiallyChecked,
      isError,
      maxInsertedColumnNumber,
      onRowEditCreateConfirm,
      isFilterEnabled,
      isTableMutable,
      refetch,
      restQueryResult,
      actionsColumnWidth,
      isHiddenSelectRowHeader
    ]
  );

  const shouldRender: boolean = useMemo(() => {
    return !(hideIfContentEmpty && table.pageCount <= 0);
  }, [hideIfContentEmpty, table.pageCount]);

  const tableInstanceValue = useMemo(() => table, [table]);

  return (
    <TableInstanceContext.Provider value={tableInstanceValue}>
      {shouldRender && (
        <TableContext.Provider value={value}>
          <div className={clsx(classes.root, className)}>
            {isTableMutable ? (
              <MutableTableProvider editFormYupResolverSchema={editFormYupResolverSchema}>
                {headerContent}
                {tableComponent({
                  ...restProps,
                  className: classes.table,
                  scrollWrapperClassName: classes.tableScrollWrapper,
                  isSection,
                  isHiddenSelectRowHeader,
                  headerRef
                })}
                {children}
              </MutableTableProvider>
            ) : (
              <>
                {headerContent}
                {tableComponent({
                  ...restProps,
                  className: classes.table,
                  scrollWrapperClassName: classes.tableScrollWrapper,
                  isSection,
                  isHiddenSelectRowHeader,
                  headerRef
                })}
                {children}
              </>
            )}
          </div>
        </TableContext.Provider>
      )}
    </TableInstanceContext.Provider>
  );
}

const useStyles = makeStyles<Theme, { isSection?: boolean; isSectionFullPage: boolean; isPageTabContent: boolean }>(
  () => ({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    table: {
      overflow: 'scroll',
      /* eslint-disable consistent-return */
      height: ({ isSectionFullPage, isPageTabContent, isSection }) => {
        if (isPageTabContent || isSection) {
          return '100%';
        }
        if (isSectionFullPage) {
          return '88%';
        }
      }
    },
    tableScrollWrapper: {
      /* eslint-disable consistent-return */
      height: ({ isSection, isPageTabContent }) => {
        if (isPageTabContent) {
          return calc('100% - 72px');
        }
        if (isSection) {
          return calc('100% - 144px');
        }
      },
      flexGrow: 1
    }
  })
);

export default TableContextProvider;
