import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '@libs/alert/api';

const deleteAlert = async (alertId: string) => {
  const { data } = await API.alert.initiateDeleteAlert(alertId, {
    ...endpointsConfig.delete
  });

  return data;
};

function useDeleteAlertMutation() {
  return useMutation(deleteAlert);
}

export default useDeleteAlertMutation;
