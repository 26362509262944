import { QueryConfig, useQuery } from 'react-query';
import {
  ApplicationDefinitionCategoriesList as ApplicationDefinitionCategoriesListClient,
  ApplicationDefinitionCategoriesSearchFilter as ApplicationDefinitionCategoriesSearchFilterClient
} from '@ibtm/client-domain';
import { ApplicationDefinitionCategoriesList, ApplicationDefinitionCategoriesSearchFilter } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';
import { ApplicationQueryKeysEnum, endpointsConfig } from '@libs/domain/application/api';

const getApplicationCategories = async (params: ApplicationDefinitionCategoriesSearchFilter) => {
  const { data } = await API.applicationDefinitions.getApplicationDefinitionsCategoriesList(params, {
    ...endpointsConfig.getApplicationCategories
  });

  return data;
};

const getApplicationCategoriesClient = async (params: ApplicationDefinitionCategoriesSearchFilterClient) => {
  const { data } = await API.client.applicationDefinitions.getApplicationDefinitionsCategoriesList(params, {
    ...endpointsConfig.getApplicationCategories
  });

  return data;
};

function useApplicationCategoriesQuery(
  { folderTypeKeyIn }: { folderTypeKeyIn: string[] },
  queryConfig: QueryConfig<ApplicationDefinitionCategoriesList | ApplicationDefinitionCategoriesListClient> = {}
) {
  const { getQuery } = useGetApiQueryByPerspective();

  return useQuery(
    [`${ApplicationQueryKeysEnum.CATEGORY}_${folderTypeKeyIn}`, { folderTypeKeyIn }],
    () => getQuery(getApplicationCategoriesClient, getApplicationCategories)({ folderTypeKeyIn }),
    {
      enabled: folderTypeKeyIn,
      ...queryConfig
    }
  );
}

export default useApplicationCategoriesQuery;
