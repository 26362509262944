import { QueryConfig, useQuery } from 'react-query';
import { FolderDetails, FolderDetailsExtended } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';
import { FolderQueryKeysEnum } from '..';

const getFolderExtended = async (id: string) => {
  const { data } = await API.folder.getFolderDetailsExtended(id, {
    ...endpointsConfig.getFolderDetailsExtended
  });
  return data;
};

const getFolderExtendedClient = async () => {
  const { data } = await API.client.folder.getFolderDetailsAssociationPerspective('', '', {
    ...endpointsConfig.getFolderDetailsAssociationPerspective
  });

  return data;
};

export default function useFolderDetailsExtendedQuery(id: string, queryConfig: QueryConfig<any, unknown> = {}) {
  const { getQuery } = useGetApiQueryByPerspective();

  return useQuery<FolderDetailsExtended | FolderDetails>(
    [FolderQueryKeysEnum.FOLDER_EXTENDED, id],
    () => getQuery(getFolderExtendedClient, getFolderExtended)(id),
    queryConfig
  );
}
