import { InvoiceSearchFilter } from '@ibtm/domain';

interface IReleaseDocumentsSuspensionQueryParams extends InvoiceSearchFilter {
  suspensionId?: string;
}

export const parseReleaseDocumentsSuspensionPayload = (params: IReleaseDocumentsSuspensionQueryParams) => {
  const { suspensionId, ...queryParams } = params;

  return {
    suspensionId,
    queryParams
  };
};
