import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { ReportDetailsLite } from '@stack/report';

import { FormMode, GridLayout, Tab, TabContent, Tabs, TabsContent, useTab } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';
import { Theme } from '@libs/common/v2/theme';
import { calc } from '@libs/common/v2/utils';

import { ReportTypeDetailsTabsEnum } from '@libs/report';

import { GeneratedReportDetails } from './GeneratedReportDetails';
import { ReportTypeDataSection } from './ReportTypeDataSection';
import { ReportTypesExtrasTable } from './ReportTypeExtrasSection';
import { ReportTypeGeneralSection } from './ReportTypeGeneralSection';

interface IProps {
  formMode: FormMode;
  generatedReport: ReportDetailsLite;
}

function ReportTypeTabs({ formMode, generatedReport }: IProps) {
  const [t] = useTranslation();

  const { editMode } = useViewModesV2(formMode);
  const [activeTab, setActiveTab] = useTab(ReportTypeDetailsTabsEnum.DEFINITION);
  const classes = useStyles({ editMode });

  if (!editMode) {
    return (
      <div className="pb-24 pt-16 h-full w-full">
        <GridLayout itemProps={{ xs: 12 }}>
          <ReportTypeGeneralSection />
          <ReportTypesExtrasTable />
          <ReportTypeDataSection />
        </GridLayout>
      </div>
    );
  }

  return (
    <div className={classes.tabsContainer}>
      <Tabs
        value={activeTab}
        onChange={setActiveTab}
        variant="scrollable"
        orientation="horizontal"
        className={classes.tabsWrapper}
      >
        <Tab
          label={<div className={classes.tabHeaderLabel}>{t('reports:details.tab.definiton')}</div>}
          value={ReportTypeDetailsTabsEnum.DEFINITION}
          key={`${ReportTypeDetailsTabsEnum.DEFINITION}-tab`}
          isHorizontalTab
        />
        <Tab
          label={<div className={classes.tabHeaderLabel}>{t('reports:details.tab.view')}</div>}
          value={ReportTypeDetailsTabsEnum.VIEW}
          key={`${ReportTypeDetailsTabsEnum.VIEW}-tab`}
          isDisabled={!generatedReport}
          isHorizontalTab
        />
      </Tabs>
      <TabsContent value={activeTab}>
        <TabContent
          key={ReportTypeDetailsTabsEnum.DEFINITION}
          value={ReportTypeDetailsTabsEnum.DEFINITION}
          className={classes.tabsContent}
          isInnerScroll
        >
          <ReportTypeGeneralSection />
          <ReportTypesExtrasTable />
          <ReportTypeDataSection />
        </TabContent>
        <TabContent key={ReportTypeDetailsTabsEnum.VIEW} value={ReportTypeDetailsTabsEnum.VIEW} isInnerScroll>
          <GeneratedReportDetails generatedReport={generatedReport} />
        </TabContent>
      </TabsContent>
    </div>
  );
}

const useStyles = makeStyles<Theme, { editMode?: boolean }>(theme => ({
  tabHeaderLabel: {
    textAlign: 'center',
    marginLeft: '-8px'
  },
  tabsContent: {
    height: ({ editMode }) => (editMode ? calc('100% - 32px') : '100%')
  },
  tabsWrapper: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`
  },
  tabsContainer: {
    paddingBottom: 12,
    height: ({ editMode }) => (editMode ? calc('100% - 32px') : '100%'),
    width: '100%'
  }
}));

export default ReportTypeTabs;
