import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { Dialog, GridLayout, Radio, useDialog } from '@libs/common/v2';

interface IProps {
  closeDialog: () => void;
  onConfirm: (params: {
    formData: ApplicationConfirmOption;
    setLoading: (value: boolean) => void;
    closeDialog: () => void;
  }) => void;
}

export const useApplicationConfirmDialog = () => {
  const { openDialog } = useDialog();
  return {
    openApplicationConfirmDialog: ({ onConfirm }: Pick<IProps, 'onConfirm'>) => {
      openDialog(({ closeDialog }) => (
        <ApplicationConfirmDialog key="application-confirm-dialog" closeDialog={closeDialog} onConfirm={onConfirm} />
      ));
    }
  };
};

export enum ApplicationConfirmOption {
  PRINTOUTS_GENERATED_SEND_APPLICATION = 'PRINTOUTS_GENERATED_SEND_APPLICATION',
  GENERATE_PRINTOUTS = 'GENERATE_PRINTOUTS'
}

const StyledText = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return <span className={classes.text}>{children}</span>;
};

function ApplicationConfirmDialog({ closeDialog, onConfirm }: IProps) {
  const [t] = useTranslation();
  const [selectedOption, setSelectedOption] = useState<ApplicationConfirmOption | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const handleSubmit = () => onConfirm({ formData: selectedOption, closeDialog, setLoading: setIsLoading });
  const valueEquals = (value: ApplicationConfirmOption) => selectedOption === value;

  return (
    <Dialog
      dialogSize="medium"
      title={t('applications:dialog.confirmApplicationTitle')}
      cancelText={t('action.close')}
      confirmText={t('action.confirm')}
      onCancel={closeDialog}
      isConfirmLoading={isLoading}
      confirmButtonTooltipText={{ disabled: t('applications:message.optionSelectIsRequired') }}
      isConfirmButtonDisabled={!selectedOption}
      onConfirm={handleSubmit}
      isOpen
    >
      <form onSubmit={handleSubmit}>
        <GridLayout itemProps={{ xs: 12 }}>
          <StyledText>{t('applications:message.toFinishProcessAttachSignedFiles')}</StyledText>
          <StyledText>{t('applications:message.areSignedFilesAreAttachedToApplication')}</StyledText>
          <Radio
            isChecked={valueEquals(ApplicationConfirmOption.GENERATE_PRINTOUTS)}
            onChange={() => setSelectedOption(ApplicationConfirmOption.GENERATE_PRINTOUTS)}
            label={t('applications:message.systemWillGenerateApplicationWithFiles')}
            wrapperClassName={classes.radioFieldWrapper}
          />
          <Radio
            isChecked={valueEquals(ApplicationConfirmOption.PRINTOUTS_GENERATED_SEND_APPLICATION)}
            onChange={() => setSelectedOption(ApplicationConfirmOption.PRINTOUTS_GENERATED_SEND_APPLICATION)}
            label={t('applications:message.applicationWithFilesAttached')}
            wrapperClassName={classes.radioFieldWrapper}
          />
        </GridLayout>
      </form>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: '1.4rem',
    fontWeight: 400,
    color: theme.palette.grey[900]
  },
  radioFieldWrapper: {
    '& .MuiFormControlLabel-label': {
      fontSize: '1.4rem',
      marginLeft: 10,
      fontWeight: 400,
      color: theme.palette.grey[900]
    }
  }
}));
