import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { VehicleDetails as VehicleDetailsModel } from '@ibtm/domain';

import {
  Button,
  ButtonsGroup,
  Dialog,
  FormMode,
  FormV2Context,
  GridLayout,
  InputMode,
  SelectOptionPartial,
  typedNameV2,
  useIsSmallScreen
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { useViewModesV2 } from '@libs/common/v2/form';

import { ApplicationQueryKeysEnum } from '@libs/domain/application';
import { DomainDictionaryEntry, useDomainConfigContext } from '@libs/domain/config';
import { FolderQueryKeysEnum } from '@libs/domain/folder';
import {
  AlreadyExistingVehicleError,
  IVehicleActionKeys,
  IVehicleFieldsRequiredByCategory,
  parseVehicleCreateRequest,
  parseVehicleFormData,
  parseVehicleUpdateRequest,
  useCreateVehicleMutation,
  useUpdateVehicleMutation,
  useVehicleDetailsQuery,
  useVehicleValidationSchema,
  VehicleCepikDetails,
  VehicleFormData,
  VehicleQueryKeysEnum,
  VehicleSectionFields
} from '@libs/domain/vehicle';

import ExistingVehicleDialogContent from './components/ExistingVehicleDialogContent';
import { CepikDataSection, CustomActions, GeneralInformationSection, VehicleSection } from './components';

interface IProps {
  formMode: FormMode;
  id: string;
  folderType: string;
  transferredFoldersIds?: string[];
  closeDialog?: () => void;
  folderId: string;
  applicationId?: string;
  applicationType?: string;
  applicationCategory?: string;
  initialData?: Partial<VehicleDetailsModel>;
  hiddenFieldsAddVehicle?: string[];
  isCreatingFromFolder?: boolean;
  checkCepikInformationFolderType?: boolean;
  fieldsMode?: { [key in keyof VehicleFormData]?: InputMode };
  actionKeys?: IVehicleActionKeys;
}

function VehicleDetails({
  formMode,
  folderId,
  transferredFoldersIds,
  id,
  folderType,
  closeDialog,
  applicationId,
  applicationType,
  applicationCategory,
  initialData,
  hiddenFieldsAddVehicle,
  isCreatingFromFolder,
  checkCepikInformationFolderType,
  fieldsMode,
  actionKeys
}: IProps) {
  const { isSmallScreen } = useIsSmallScreen();
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const [mode, setMode] = useState<FormMode>(formMode);
  const [addManuallyVisible, setAddManuallyVisible] = useState(true);
  const [isExistingVehicle, setIsExistingVehicle] = useState(false);
  const [isVehicleActiveInFolder, setIsVehicleActiveInFolder] = useState(false);
  const [existingVehicleMessage, setExistingVehicleMessage] = useState(null);
  const isCategorySCertificate = applicationCategory === DomainDictionaryEntry.APPLICATION_CATEGORY.S_CERTIFICATE;
  const isVehicleSaleDate = applicationType === DomainDictionaryEntry.APPLICATION_TYPE.PB11D;

  const fieldsRequiredByCategory: IVehicleFieldsRequiredByCategory = {
    [VehicleSectionFields.MODEL]: isCategorySCertificate,
    [VehicleSectionFields.EURO_CLASS_KEY]: isCategorySCertificate,
    [VehicleSectionFields.ENGINE_NUMBER]: isCategorySCertificate,
    [VehicleSectionFields.ENGINE_TYPE]: isCategorySCertificate,
    [VehicleSectionFields.FIRST_REGISTRATION_DATE]: isCategorySCertificate,
    [VehicleSectionFields.DATE_OF_SALE]: isVehicleSaleDate
  };

  const isWithdrawnOrAlteredVehicleApplication = [
    DomainDictionaryEntry.APPLICATION_TYPE.PB11A,
    DomainDictionaryEntry.APPLICATION_TYPE.spoOwnNeedsIpwz
  ].includes(applicationType);

  const { createMode, editMode } = useViewModesV2(mode);
  const { validationSchema } = useVehicleValidationSchema(folderType, hiddenFieldsAddVehicle, fieldsRequiredByCategory);
  const [cepikData, setCepikData] = useState<VehicleCepikDetails>(null);
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  const { mutateAsync: createVehicle, isLoading } = useCreateVehicleMutation();

  const { mutate: updateVehicle } = useUpdateVehicleMutation();

  const { data, isFetching } = useVehicleDetailsQuery(id, {
    enabled: !createMode,
    placeholderData: initialData
  });
  const { isClientPortal } = useDomainConfigContext();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    reset,
    setError,
    getValues,
    trigger,
    unregister,
    clearErrors
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema(isVehicleSaleDate))
  });
  const initialValues: Partial<VehicleFormData> = useMemo(
    () => ({
      typeKey:
        folderType === DomainDictionaryEntry.FOLDER_TYPE.OP ? { value: DomainDictionaryEntry.VEHICLE_TYPE.BUS } : null,
      folderId,
      applicationId,
      addManually: isClientPortal
    }),
    [folderType, folderId, applicationId, isClientPortal]
  );
  const belowThreeAndHalfTons = watch(typedNameV2<VehicleFormData>('belowThreeAndHalfTons')) as SelectOptionPartial;
  const dmc = watch(typedNameV2<VehicleFormData>('dmc')) as number;
  useEffect(() => {
    if (data && !isFetching) {
      reset({ ...data, ...parseVehicleFormData(data) });
      if (editMode) setValue('addManually', editMode);
      if (isWithdrawnOrAlteredVehicleApplication)
        setValue('withdrawnAlteredVehicleRegistrationNumber', {
          name: data?.cancelledVehicle?.plateNumber,
          value: { id: data?.cancelledVehicle?.id }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFetching]);

  useEffect(() => {
    if (createMode) {
      reset(initialValues);
    }
  }, [createMode, reset, initialValues, setValue, editMode]);

  useEffect(() => {
    if (belowThreeAndHalfTons?.value) {
      trigger(typedNameV2<VehicleFormData>('dmc'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [belowThreeAndHalfTons?.value, dmc]);

  const handleSuccess = (resolve: (value: any) => void) => {
    showSuccessSnackbar(
      createMode ? t('vehicle:details.message.vehicleAdded') : t('vehicle:details.message.vehicleUpdated')
    );
    closeDialog();
    queryCache.invalidateQueries(VehicleQueryKeysEnum.VEHICLE_LIST);
    queryCache.invalidateQueries(ApplicationQueryKeysEnum.APPLICATION);
    if (createMode && folderId && isCreatingFromFolder) {
      queryCache.invalidateQueries(FolderQueryKeysEnum.ACTIVE_VEHICLES_COUNT, { stale: !!folderId });
    }
    resolve(undefined);
  };

  const checkIsExistingVehicle = (formData, onSuccess) => {
    createVehicle(formData, {
      onError: (errorData: Record<string, any>) => {
        const response = errorData?.request?.response;
        if (response?.includes(AlreadyExistingVehicleError.VEHICLE_ALREADY_ACTIVE_IN_FOLDER)) {
          setExistingVehicleMessage(
            isClientPortal
              ? t('vehicle:dialog.existingVehicle.alreadyActiveInClientFolder')
              : t('vehicle:dialog.existingVehicle.alreadyActiveInFolder')
          );
          setIsVehicleActiveInFolder(true);
          setIsExistingVehicle(true);
        } else if (response?.includes(AlreadyExistingVehicleError.VEHICLE_ALREADY_IN_CONSIDERED_APPLICATION)) {
          setExistingVehicleMessage(t('vehicle:dialog.existingVehicle.alreadyInConsideredApplication'));
          setIsExistingVehicle(true);
        } else {
          closeDialog();
        }
      },
      onSuccess: () => {
        onSuccess();
      }
    });
  };

  const onSubmit = (values: VehicleFormData) => {
    return new Promise((resolve, reject) => {
      const requestConfig = {
        onSuccess: () => handleSuccess(resolve),
        onError: () => reject()
      };
      if (createMode) {
        if (isExistingVehicle) {
          createVehicle(parseVehicleCreateRequest({ ...values, ignoreExistingVehicles: true }), requestConfig);
        } else {
          checkIsExistingVehicle(parseVehicleCreateRequest({ ...values, ignoreExistingVehicles: false }), () =>
            handleSuccess(resolve)
          );
        }
      } else {
        updateVehicle({ id, formData: parseVehicleUpdateRequest(values) }, requestConfig);
      }
    });
  };

  const onSubmitAndAddNextSuccess = (resolve: (value: any) => void) => {
    showSuccessSnackbar(
      createMode ? t('vehicle:details.message.vehicleAdded') : t('vehicle:details.message.vehicleUpdated')
    );
    setMode(FormMode.CREATE);
    queryCache.invalidateQueries(VehicleQueryKeysEnum.VEHICLE_LIST);
    queryCache.invalidateQueries(ApplicationQueryKeysEnum.APPLICATION);
    reset(initialValues);
    setAddManuallyVisible(true);
    resolve(undefined);
  };

  const submitAndAddNext = values => {
    return new Promise((resolve, reject) => {
      const requestConfig = {
        onSuccess: () => {
          onSubmitAndAddNextSuccess(resolve);
          setCepikData(null);
        },
        onError: () => reject()
      };
      if (createMode) {
        createVehicle(parseVehicleCreateRequest(values), requestConfig);
      } else {
        updateVehicle({ id, formData: parseVehicleUpdateRequest(values) }, requestConfig);
      }
    });
  };

  const formValues = useMemo(
    () => ({
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      setError,
      isSubmitting,
      formMode,
      reset,
      handleSubmit,
      clearErrors
    }),
    [
      control,
      errors,
      register,
      setValue,
      watch,
      getValues,
      trigger,
      unregister,
      setError,
      isSubmitting,
      formMode,
      reset,
      handleSubmit,
      clearErrors
    ]
  );

  return (
    <FormV2Context.Provider value={formValues}>
      <Dialog
        title={t('vehicle:details.title')}
        cancelText={t('action.close')}
        customActions={
          isExistingVehicle ? (
            <ButtonsGroup>
              <Button isPrimary variant="outlined" onClick={closeDialog} label={t('action.cancel')} />
              <Button
                isPrimary
                variant="contained"
                onClick={handleSubmit(data => onSubmit(data as VehicleFormData))}
                isLoading={isLoading}
                form="createVehicleForm"
                label={t('action.add')}
              />
            </ButtonsGroup>
          ) : (
            <CustomActions
              onSubmit={handleSubmit(
                data => onSubmit(data as VehicleFormData),
                () => {
                  showErrorSnackbar(t('error.formValidationErrors'));
                }
              )}
              closeDialog={closeDialog}
              submitAndAddNext={handleSubmit(submitAndAddNext)}
            />
          )
        }
        onCancel={closeDialog}
        dialogSize={!!cepikData && addManuallyVisible ? 'large' : 'medium'}
        isFullScreen={isSmallScreen}
        isOpen
      >
        {isExistingVehicle ? (
          <GridLayout itemProps={{ xs: 12 }}>
            <ExistingVehicleDialogContent
              isExistingVehicle={isExistingVehicle}
              existingVehicleMessage={existingVehicleMessage}
              isActiveInFolder={isVehicleActiveInFolder}
            />
          </GridLayout>
        ) : (
          <form onSubmit={handleSubmit(data => onSubmit(data as VehicleFormData))} id="createVehicleForm">
            <GridLayout itemProps={{ xs: cepikData && addManuallyVisible ? 6 : 12 }}>
              <VehicleSection
                setCepikData={setCepikData}
                folderType={folderType}
                folderId={folderId}
                transferredFoldersIds={transferredFoldersIds}
                isWithdrawnAlteredVehicleFieldVisible={isWithdrawnOrAlteredVehicleApplication}
                isVehicleSaleDate={isVehicleSaleDate}
                hiddenFieldsAddVehicle={hiddenFieldsAddVehicle}
                addManuallyVisible={addManuallyVisible}
                setAddManuallyVisible={setAddManuallyVisible}
                checkCepikInformationFolderType={checkCepikInformationFolderType}
                fieldsMode={fieldsMode}
                fieldsRequiredByCategory={fieldsRequiredByCategory}
                actionKeys={actionKeys}
              />
              {!!cepikData && addManuallyVisible && (
                <CepikDataSection
                  data={{ ...data, ...cepikData }}
                  setAddManuallyVisible={setAddManuallyVisible}
                  initialValues={initialValues}
                />
              )}
              {!createMode && <GeneralInformationSection />}
            </GridLayout>
          </form>
        )}
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default VehicleDetails;
