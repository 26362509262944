import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { LoaderCircular, Typography, useValueStyles } from '@libs/common/v2';
import { getFormattedDateValue } from '@libs/common/v2/utils';

import { FolderDetailsClient } from '@libs/domain/folder';

import FolderSliderSection from '../FolderSliderSection';

interface Props {
  folderDetails: FolderDetailsClient;
  emptyMode?: boolean;
  isLoading?: boolean;
}

function BasicDataBlock({ folderDetails, emptyMode, isLoading }: Props) {
  const [t] = useTranslation();
  const globalClasses = useValueStyles({});
  const classes = useStyles();

  return (
    <FolderSliderSection title={t('folder:sidebar.section.basicData')} isCollapsable>
      <LoaderCircular isLoading={isLoading} size={25}>
        {emptyMode ? (
          <div className={clsx(classes.row, 'flex items-center')}>
            <p>{t('error.noData')}</p>
          </div>
        ) : (
          <div className="flex flex-col">
            <div className={clsx(classes.row, 'flex items-center')}>
              <Typography
                component="div"
                themeVariant="textSm.medium"
                className={clsx(globalClasses.labelWrapper, 'pr-5 mb-0')}
              >
                {t('folder:field.modified')}
              </Typography>
              <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
                {folderDetails?.modified ? getFormattedDateValue(folderDetails?.modified) : t('emptyMark')}
              </Typography>
            </div>
            <div className={clsx(classes.row, 'flex items-center')}>
              <Typography
                component="div"
                themeVariant="textSm.medium"
                className={clsx(globalClasses.labelWrapper, 'pr-5 mb-0')}
              >
                {t('folder:field.modifierName')}
              </Typography>
              <Typography component="div" className={clsx(globalClasses.value, 'field-value')}>
                {folderDetails?.modifier?.name ?? t('emptyMark')}
              </Typography>
            </div>
          </div>
        )}
      </LoaderCircular>
    </FolderSliderSection>
  );
}

const useStyles = makeStyles(() => ({
  row: {
    minHeight: 48
  }
}));

export default BasicDataBlock;
