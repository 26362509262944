import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SortingRule } from 'react-table';
import { Table } from '@enigma/fe-ui';
import { HeaderActions } from '@enigma/fe-ui/lib/display/table';
import { LicenseSearchFilter, PermissionTypeCode } from '@ibtm/domain';

import { useElementVisibility } from '@libs/permission';

import { API } from '@libs/domain/api';
import { DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';

import { usePrintsDetailsDataContext } from '../../print/context';
import { usePrintActionHandler } from '../../print/hooks';
import { usePrintsRegenerateAction } from '../../print/hooks/usePrintsRegenerateAction';
import { endpointsConfig, LicensesQueryKeysEnum } from '../api';
import { DEFAULT_COLUMNS } from '../config/license-column.config';
import { useEditLicenseDialog, useLicencesTableColumnsV2 } from '../hooks';
import { LicensesColumnsEnum, LicenseSnapshotClient } from '../model/license.model';
import { parseLicensesList } from '../parsers/common';

interface IProps {
  applicationId?: string;
  folderId?: string;
  visibleColumns?: Array<LicensesColumnsEnum>;
  defaultFilters?: LicenseSearchFilter;
  headerActions?: HeaderActions<LicenseSnapshotClient>;
  licenseValidityYearsDictionary?:
    | DomainDictionaryEnum.LICENSE_VALIDITY_YEARS
    | DomainDictionaryEnum.LICENSE_VALIDITY_YEARS_PPO
    | DomainDictionaryEnum.LICENSE_VALIDITY_YEARS_TP_OP;
  defaultSort?: SortingRule<LicenseSnapshotClient>[];
  hiddenStatusFilterKeys?: string[];
  maxRowActionNumber: number;
}

function LicensesTableV2({
  applicationId,
  folderId,
  visibleColumns = DEFAULT_COLUMNS,
  defaultFilters,
  headerActions,
  licenseValidityYearsDictionary,
  defaultSort,
  hiddenStatusFilterKeys = [],
  maxRowActionNumber
}: IProps) {
  const [t] = useTranslation();
  const { openEditLicenseDialog } = useEditLicenseDialog();
  const { checkIsElementVisible } = useElementVisibility();

  const { columns, mappedFilterFields, mappedSortFields } = useLicencesTableColumnsV2({
    visibleColumns,
    licenseValidityYearsDictionary,
    hiddenStatusFilterKeys
  });

  const tableHookQueryInitialParams: LicenseSearchFilter = {
    applicationIdIn: applicationId ? [applicationId] : undefined,
    folderIdIn: folderId ? [folderId] : undefined
  };

  const { handlePrintRegenerate } = usePrintsRegenerateAction();
  const { print } = usePrintActionHandler();

  const {
    printDetailsData: {
      depotMPD: { id: depotMPDId },
      notDepotMPDTooltip
    }
  } = usePrintsDetailsDataContext();

  const editLicense = useCallback(
    ({ rowData, refetch }: { rowData: LicenseSnapshotClient; refetch?: () => void }) => {
      openEditLicenseDialog(
        {
          id: rowData.id,
          validFrom: rowData.validFrom as unknown as string,
          validTo: rowData.validTo as unknown as string,
          printDate: rowData.printDate as unknown as string,
          licenseNumber: rowData.licenseNumber,
          licenseValidityYearsKey: rowData.licenseValidityYearsKey as string,
          version: rowData.version
        },
        refetch,
        licenseValidityYearsDictionary
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isRegenerateDisabled = !depotMPDId;

  return (
    <Table<LicenseSnapshotClient, LicenseSearchFilter>
      tableTitle={t('license:title.licenses')}
      columns={columns}
      query={{
        request: async params => {
          const { data } = await API.license.getLicensesSnapshotPage(
            {
              ...params,
              ...tableHookQueryInitialParams
            },
            {
              ...endpointsConfig.getLicensesSnapshotPage
            }
          );

          return {
            data: {
              ...data,
              content: parseLicensesList(data.content)
            }
          };
        },
        queryKey: LicensesQueryKeysEnum.LICENSES_LIST,
        filterParamsConverter: mappedFilterFields,
        sortParamConverter: mappedSortFields,
        initParams: tableHookQueryInitialParams
      }}
      initialTableState={{ columnFilters: defaultFilters, sorting: defaultSort }}
      xlsxDownload={{
        fileName: t('license:title.licenses'),
        apiRequest: async params => {
          const { data } = await API.license.getLicensesSnapshotPage(
            {
              ...params,
              ...tableHookQueryInitialParams
            },
            {
              ...endpointsConfig.getLicensesSnapshotPage
            }
          );

          return {
            data: {
              ...data,
              content: parseLicensesList(data.content)
            }
          };
        },
        filterParamsConverter: mappedFilterFields,
        sortParamConverter: mappedSortFields
      }}
      maxNumberOfIconsInActionRow={maxRowActionNumber}
      headerActions={headerActions}
      rowActions={[
        {
          label: isRegenerateDisabled ? notDepotMPDTooltip : t('prints:actions.regenerate'),
          tooltip: isRegenerateDisabled ? notDepotMPDTooltip : t('prints:actions.regenerate'),
          isDisabled: isRegenerateDisabled || !applicationId,
          onClick: ({ rowData, refetch }) => {
            handlePrintRegenerate({
              applicationId,
              data: rowData,
              onSuccess: refetch,
              isMultipleFormNumberToRegenerateInRow: false,
              permissionTypeCode: PermissionTypeCode.LICENSE
            });
          },
          icon: 'CalculatorIcon'
        },
        {
          label: t('action.print'),
          tooltip: t('action.print'),
          onClick: ({ rowData, setLoading, refetch }) => {
            setLoading(true);
            print(
              { permissionTypeCode: PermissionTypeCode.LICENSE, permissionIds: [rowData.id] },
              {
                onSettled: () => {
                  setLoading(false);
                  refetch();
                }
              }
            );
          },
          icon: 'PrintIcon'
        },
        {
          label: t('action.edit'),
          onClick: editLicense,
          icon: 'EditIcon',
          isHidden: !checkIsElementVisible(DomainUIElementEnum.PRINT_DATA_UPDATE_BUTTON)
        }
      ]}
    />
  );
}

export default LicensesTableV2;
