import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { InvoiceLite } from '@ibtm/domain';

import { TableIconButton } from '@libs/common/v2';

import { useDownloadDocumentFile } from '@libs/file';

import { useGenerateDocumentMutation } from '@libs/domain/release-documents';

function DownloadBasicLimitDetailsButton({
  invoice,
  documentTemplateId
}: {
  invoice: InvoiceLite;
  documentTemplateId: string;
}) {
  const { mutate: generateDocument, isLoading } = useGenerateDocumentMutation();
  const { downloadFile, isFetching } = useDownloadDocumentFile();

  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();

  const isDisabled = !invoice?.id;

  const onSubmit = () => {
    generateDocument(
      {
        documentTemplateId,
        invoiceId: invoice?.id
      },
      {
        onSuccess: response => {
          downloadFile(response.data.file);
          showSnackbar('success', t('document:message.generateDocumentSuccess'));
        }
      }
    );
  };

  return (
    <TableIconButton
      isButtonLoading={isFetching || isLoading}
      onClick={onSubmit}
      icon="DownloadIcon"
      tooltipTitle={
        isDisabled
          ? t('folder:details.tab.singleAuthorization.section.basicLimit.message.noFileToDownload')
          : t('folder:details.tab.singleAuthorization.section.basicLimit.button.downloadReleaseDocument')
      }
      isDisabled={isDisabled}
    />
  );
}
export default DownloadBasicLimitDetailsButton;
