import { QueryConfig, useQuery } from 'react-query';

import { API } from '@libs/domain/api';
import { DocumentsIssuedQueryEnum } from '@libs/domain/documents-issued/model';

import { endpointsConfig } from '../endpoints-config';

const verifyParcel = async (_, parcelNumber: string) => {
  const { data } = await API.documents.verifyParcel({ parcelNumber }, { ...endpointsConfig.verifyParcel });

  return data;
};

export default function useVerifyParcel(parcelNumber: string, queryConfig?: QueryConfig<void, unknown>) {
  return useQuery([DocumentsIssuedQueryEnum.VERIFY_PARCEL, parcelNumber], verifyParcel, queryConfig);
}
