import { useTranslation } from 'react-i18next';
import { DictionaryEntryDetailsLanguage } from '@avispon/dictionary';
import { useSnackbar } from '@enigma/fe-ui';

import { KeyType, partialTranslate } from '@libs/common';
import { Button, GridLayout, Section, typedNameV2, useDictionaryQuery, useFormV2Context, Value } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { DictionaryEntryNameStatusEnum } from '@libs/dictionary';

import { DomainDictionaryEnum, DomainQuickchangeableDictionaryEnum } from '@libs/domain/config';
import { VehicleCepikDetails, VehicleFormData } from '@libs/domain/vehicle';

interface Props {
  data: VehicleCepikDetails;
  setAddManuallyVisible: React.Dispatch<React.SetStateAction<boolean>>;
  initialValues: Partial<VehicleFormData>;
}

const getLabel = partialTranslate('vehicle:field');

function CepikDataSection({ data, setAddManuallyVisible, initialValues }: Props) {
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();

  const { reset, formMode, setValue } = useFormV2Context();
  const { viewMode } = useViewModesV2(formMode);

  const { data: brandData } = useDictionaryQuery(
    {
      page: 0,
      size: 1,
      valueFragment: [data.brandKey],
      dictionary: DomainQuickchangeableDictionaryEnum.VEHICLE_BRAND,
      language: DictionaryEntryDetailsLanguage.pl,
      status: DictionaryEntryNameStatusEnum.ALL,
      sortBySimilarity: true,
      sort: []
    },
    { enabled: !!data }
  );
  const { data: typeData } = useDictionaryQuery(
    {
      page: 0,
      size: 1,
      valueFragment: [data.typeKey],
      dictionary: DomainDictionaryEnum.VEHICLE_TYPE,
      language: DictionaryEntryDetailsLanguage.pl,
      status: DictionaryEntryNameStatusEnum.ALL,
      sort: []
    },
    { enabled: !!data }
  );

  const { data: modelData } = useDictionaryQuery(
    {
      page: 0,
      size: 1,
      valueFragment: [data.modelKey],
      parentKeys: [brandData?.content[0]?.key],
      dictionary: DomainQuickchangeableDictionaryEnum.VEHICLE_MODEL,
      language: DictionaryEntryDetailsLanguage.pl,
      status: DictionaryEntryNameStatusEnum.ALL,
      sort: []
    },
    { enabled: !!brandData }
  );

  const acceptCepikData = () => {
    reset({ ...initialValues, ...data });
    if (brandData?.content[0]?.key) {
      setValue(typedNameV2<VehicleCepikDetails>('brandKey'), {
        value: brandData?.content[0]?.key,
        name: brandData?.content[0]?.value
      });
    } else {
      showSnackbar('warning', t('vehicle:details.message.cepikBrandError' as KeyType));
    }

    if (modelData?.content[0]?.key) {
      setValue(typedNameV2<VehicleCepikDetails>('modelKey'), {
        value: modelData?.content[0]?.key,
        name: modelData?.content[0]?.value
      });
    } else {
      showSnackbar('warning', t('vehicle:details.message.cepikModelError' as KeyType));
    }

    if (typeData?.content[0]?.key) {
      setValue(typedNameV2<VehicleCepikDetails>('typeKey'), {
        value: typeData?.content[0]?.key,
        name: typeData?.content[0]?.value
      });
    } else {
      showSnackbar('warning', t('vehicle:details.message.cepikTypeError' as KeyType));
    }

    setAddManuallyVisible(false);
    setValue('addManually', true);
  };

  return (
    <Section
      title={t('vehicle:details.section.cepikVehicle')}
      isModalSection
      headerContent={
        !viewMode && (
          <Button
            isPrimary
            variant="outlined"
            onClick={acceptCepikData}
            label={t('vehicle:details.button.acceptCepikData')}
          />
        )
      }
    >
      <GridLayout itemProps={{ xs: 12, sm: 6 }}>
        <Value label={getLabel('vin')}>{data[typedNameV2<VehicleCepikDetails>('vin')]}</Value>

        <Value label={getLabel('registrationNumber')}>{data[typedNameV2<VehicleCepikDetails>('plateNumber')]}</Value>

        <Value label={getLabel('brandKey')}>{data.brandKey}</Value>

        <Value label={getLabel('model')}>{data.modelKey}</Value>

        <Value label={getLabel('manufactureYear')}>{data[typedNameV2<VehicleCepikDetails>('manufactureYear')]}</Value>

        <Value label={getLabel('typeKey')}>{data.typeKey}</Value>

        <Value label={getLabel('seatsNumber')}>{data[typedNameV2<VehicleCepikDetails>('seatsNumber')]}</Value>

        <Value label={getLabel('acceptableCapacity')}>{data.acceptableCapacity}</Value>

        <Value label={getLabel('dmc')}>{data[typedNameV2<VehicleCepikDetails>('dmc')]}</Value>
      </GridLayout>
    </Section>
  );
}

export default CepikDataSection;
