import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function deleteListItem(civicBoardRuleId: string) {
  return API.civicBoard.deleteCivicBoardRule(civicBoardRuleId, {
    ...endpointsConfig.deleteCivicBoardRule
  });
}

function useDeleteTradeRuleMutation() {
  return useMutation(deleteListItem);
}

export default useDeleteTradeRuleMutation;
