import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderSnapshot } from '@ibtm/domain';

import { Button } from '@libs/common/v2';

import CreateApplicationDialog from './CreateApplicationDialog';

type Props = {
  onSuccess: (applicationId: string) => void;
  selectedFolder?: FolderSnapshot;
  categoryKey?: string;
  isFilterOnlyByFolderNumber?: boolean;
};

function CreateApplicationButton({ onSuccess, selectedFolder, categoryKey, isFilterOnlyByFolderNumber = true }: Props) {
  const [t] = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button
        label={t('applications:create.title')}
        onClick={() => {
          setModalOpen(true);
        }}
        variant="contained"
      />
      <CreateApplicationDialog
        open={isModalOpen}
        selectedFolder={selectedFolder}
        categoryKey={categoryKey}
        isFilterOnlyByFolderNumber={isFilterOnlyByFolderNumber}
        onCancel={() => {
          setModalOpen(false);
        }}
        onClose={() => {
          setModalOpen(false);
        }}
        onSuccess={applicationId => {
          setModalOpen(false);
          onSuccess(applicationId);
        }}
      />
    </>
  );
}

export default CreateApplicationButton;
