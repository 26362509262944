import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { WarningInformation } from '@libs/common/v2';
import { getCalendarDate, getDateOrNull, isDateLengthValid } from '@libs/common/v2/utils';

import { DomainDictionaryEntry } from '../../config';
import useDriversListQuery from '../api/queries/useDriversListQuery';
import { getFilterForTable } from '../utils';

import useOpenDriverSearchDialog from './dialogs/useOpenDriverSearchDialog';

export interface WarningStateProps {
  name?: string;
  surname?: string;
  citizenship?: string;
  birthPlace?: string;
  dateOfBirth?: string;
  documentSeriesNumber?: string;
}

export type SetValuesForWarnings = (param: keyof WarningStateProps, value: string) => void;

const useDriverNotificationAndValidation = (
  closeDialog: () => void,
  applicationId?: string,
  folderId?: string,
  transferredFoldersIds?: string[],
  isCreateMode?: boolean,
  applicationTypeKey?: string
) => {
  const [t] = useTranslation();
  const { openDriverSearchDialog } = useOpenDriverSearchDialog();
  const [valuesForWarning, setValuesForWarning] = useState<WarningStateProps>({});
  const [isWarningVisible, setIsWarningVisible] = useState<boolean>(false);

  const isChangeDriverCertificateApplicationType =
    applicationTypeKey === DomainDictionaryEntry.APPLICATION_TYPE.changeOfDriverCertificate;

  const { data } = useDriversListQuery(
    {
      identityDocumentSeriesNumberContains: valuesForWarning.documentSeriesNumber,
      birthDateDateRange: {
        from: getCalendarDate(getDateOrNull(valuesForWarning.dateOfBirth)),
        to: getCalendarDate(getDateOrNull(valuesForWarning.dateOfBirth))
      },
      folderIdIn: folderId ? [folderId, ...(transferredFoldersIds || [])] : undefined,
      applicationIdNotIn: applicationId ? [applicationId] : undefined
    },
    {
      enabled:
        (isCreateMode || isChangeDriverCertificateApplicationType) &&
        valuesForWarning.documentSeriesNumber &&
        isDateLengthValid(valuesForWarning.dateOfBirth),
      onSuccess: data => {
        setIsWarningVisible(data?.content?.length > 0);
      }
    }
  );

  const handleWarningClick = () => {
    closeDialog();
    openDriverSearchDialog({
      applicationId,
      folderId,
      transferredFoldersIds,
      tableOptions: getFilterForTable(
        valuesForWarning.documentSeriesNumber,
        getDateOrNull(valuesForWarning.dateOfBirth)
      )
    });
  };

  return {
    notificationComponent: isWarningVisible && (
      <WarningInformation>
        <Trans
          t={t as TFunction & TFunction<'translation', undefined, 'translation'>}
          i18nKey="drivers:actions.similarDriverOnFolder"
          components={[
            <a
              href="none"
              style={{ display: 'contents' }}
              key="0"
              onClick={e => {
                e.preventDefault();
                handleWarningClick();
              }}
            >
              {t('drivers:actions.similarDriverOnFolderLink')}
            </a>
          ]}
        />
      </WarningInformation>
    ),
    setValuesForWarning: (param: keyof WarningStateProps, value: string) => {
      setValuesForWarning(prevState => {
        if (value?.length > 0 && valuesForWarning[param] !== value) {
          return { ...prevState, [param]: value };
        }
        return prevState;
      });
    },
    isWarningVisible,
    valuesForWarning,
    data
  };
};

export default useDriverNotificationAndValidation;
