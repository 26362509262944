import i18n from '@libs/common/i18n';

interface LanguageItem {
  code: string;
  name: string;
}

const Languages: LanguageItem[] = [
  {
    code: 'pl',
    name: i18n.t('global:language.pl')
  }
];

export const LanguageCodes = Languages.map(language => language.code);
