import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ExploitationBaseSnapshot } from '@ibtm/domain';

import { CamelCasePath, GridLayout } from '@libs/common/v2';

import { DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import {
  EXPLOITATION_BASE_DEFAULT_VISIBLE_COLUMNS,
  ExploitationBaseRowActionButtonsEnum,
  ExploitationBaseStatusEnum,
  ExploitationBaseTable,
  NegativeControlInformation
} from '@libs/domain/exploitation-base';
import { FolderDetailsParams } from '@libs/domain/folder';

function ExploitationBaseTab() {
  const { folderId } = useParams<FolderDetailsParams>();
  const { isClientPortal, isOperatorPortal } = useDomainConfigContext();

  const tableOptions = {
    initialState: {
      sortBy: [
        {
          id: 'city',
          desc: true
        }
      ],
      filters: [
        {
          id: 'statusKey',
          value: [{ value: ExploitationBaseStatusEnum.CURRENT }]
        }
      ]
    }
  };

  const { EXPIRE_EXPLOITATION_BASE, RESTORE_EXPLOITATION_BASE, EDIT, SHOW_GEOPORTAL_MAP, OPEN_DETAILS } =
    ExploitationBaseRowActionButtonsEnum;

  const exploitationBaseDefaultVisibleColumns = useMemo(() => {
    let columns = EXPLOITATION_BASE_DEFAULT_VISIBLE_COLUMNS;
    if (isClientPortal) {
      columns = columns.filter(
        column =>
          ![
            'assignedSubjects',
            'lastInspectionResultInspectionResultKey',
            'lastInspectionResultInspectionDate'
          ].includes(column)
      );
    }
    return columns;
  }, [isClientPortal]);

  return (
    <GridLayout itemProps={{ xs: 12 }} containerProps={{ spacing: 0 }}>
      {isOperatorPortal && <NegativeControlInformation folderId={folderId} />}
      <ExploitationBaseTable
        folderId={folderId}
        mode="FORM"
        tableOptions={tableOptions}
        visibleRowActions={[
          EXPIRE_EXPLOITATION_BASE,
          RESTORE_EXPLOITATION_BASE,
          EDIT,
          SHOW_GEOPORTAL_MAP,
          OPEN_DETAILS
        ]}
        tableActionKeys={[
          DomainUIElementEnum.FOLDER_EXPLOITATION_BASE_EXPIRE_BUTTON,
          DomainUIElementEnum.FOLDER_EXPLOITATION_BASE_RESTORE_BUTTON,
          DomainUIElementEnum.FOLDER_EXPLOITATION_BASE_EDIT_BUTTON,
          DomainUIElementEnum.FOLDER_EXPLOITATION_BASE_SHOW_DETAILS_BUTTON,
          DomainUIElementEnum.FOLDER_EXPLOITATION_BASE_SHOW_GEOPORTAL_BUTTON
        ]}
        visibleColumns={
          [
            ...exploitationBaseDefaultVisibleColumns,
            'statusKey',
            ...(isOperatorPortal ? ['modified', 'modifierName'] : [])
          ] as Array<CamelCasePath<ExploitationBaseSnapshot>>
        }
        queryParams={isClientPortal ? { folderIdEq: folderId } : { folderIdIn: [folderId] }}
        isActionColumnEnabled={isOperatorPortal}
        isInspectionTable
        isCollapsable
      />
    </GridLayout>
  );
}

export default ExploitationBaseTab;
