import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import { Button, ButtonsGroup } from '@libs/common/v2';

import AddUserToGroupModal from '../form/AddUserToGroupModal';

interface GroupMembersPickerProps {
  groupId: string;
}

function GroupMembersPicker({ groupId }: GroupMembersPickerProps) {
  const [t] = useTranslation();
  const [isModalOpen, setModalIsOpen] = useState<boolean>(false);

  return (
    <ButtonsGroup>
      <Button
        onClick={() => setModalIsOpen(true)}
        label={t('administration:organizationUnit.action.addMembersToGroup')}
        variant="outlined"
        actionKey={UIElementNameEnum.USERS_GROUP_USERS_ADD_BUTTON}
      />
      {isModalOpen && <AddUserToGroupModal open={isModalOpen} setIsOpen={setModalIsOpen} groupId={groupId} />}
    </ButtonsGroup>
  );
}

export default GroupMembersPicker;
