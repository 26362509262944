import { useParams } from 'react-router-dom';

import { GridItem, GridLayout } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { RoadTransportQueryKeysEnum } from '../../../api';
import { IProceedingDetailsClient, KreptdProceedingDetailsParams } from '../../../models';
import { ViolationsTable } from '../../list';

function ViolationsTab() {
  const queryCache = useQueryCache();
  const { proceedingId } = useParams<KreptdProceedingDetailsParams>();

  const proceedingDetails = queryCache.getQueryData<IProceedingDetailsClient>([
    RoadTransportQueryKeysEnum.PROCEEDING_DETAILS,
    proceedingId
  ]);

  return (
    <GridLayout>
      <GridItem xs={12}>
        <ViolationsTable
          nip={proceedingDetails?.subjectNip}
          preceedingKreptdId={proceedingDetails?.id}
          isCollapsable={false}
          isSection
        />
      </GridItem>
    </GridLayout>
  );
}

export default ViolationsTab;
