import { useEffect } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';

import { DictionarySelectField, InputMode, useFormV2Context } from '@libs/common/v2';

import { DomainDictionaryEnum } from '../../../../config';

export interface ILineTypeDictionaryFieldProps {
  fieldId: FieldPath<FieldValues>;
  label: string;
  visibleOptions?: string[];
}

function LineTypeDictionaryField({ fieldId, label, visibleOptions }: ILineTypeDictionaryFieldProps) {
  const { setValue } = useFormV2Context();

  const [option] = visibleOptions;

  useEffect(() => {
    setValue(fieldId, option);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);

  return (
    <DictionarySelectField
      name={fieldId}
      dictionaryName={DomainDictionaryEnum.TRANSIT_LINE_TYPE}
      label={label}
      optionsFilter={option => (visibleOptions ? visibleOptions?.includes(option.value) : true)}
      inputMode={InputMode.VIEW}
      stringValue
    />
  );
}

export default LineTypeDictionaryField;
