import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { ArchiveFolderSearchFilter, FolderExtendedLitesPage } from '@ibtm/domain';
import { FolderRentalDetailsPage } from '@ibtm/domain/dist/models';
import { makeStyles } from '@mui/styles';
import { AxiosResponse } from 'axios';
import _ from 'lodash';

import {
  AutocompleteLazyFetchSelect,
  FormErrorType,
  SelectOption,
  typedNameV2,
  useFormV2Context
} from '@libs/common/v2';
import { Theme, useTheme } from '@libs/common/v2/theme';

import { API } from '@libs/domain/api';
import { FolderQueryKeysEnum } from '@libs/domain/folder';

import { ApplicationCreateFormValues } from '../../model';

interface IProps {
  fieldName?: string;
  label: string;
  statusKeyIn?: Array<string>;
  queryKey?: string;
  folderTypeKeys?: Array<string>;
  initialValue?: SelectOption;
  customSetValue?: (option?: SelectOption) => void;
  isFilterOnlyByFolderNumber?: boolean;
  isRequired?: boolean;
  isArchive?: boolean;
  isMultiple?: boolean;
  archiveFoldersQueryParams?: ArchiveFolderSearchFilter;
  onClear?: () => void;
  isReturnFolderAction?: boolean;
}

function FolderSelectField({
  fieldName,
  label,
  statusKeyIn,
  queryKey = FolderQueryKeysEnum.FOLDERS_LIST_FILTER,
  folderTypeKeys,
  customSetValue,
  initialValue,
  isFilterOnlyByFolderNumber = false,
  isRequired = false,
  isArchive,
  isMultiple,
  archiveFoldersQueryParams,
  onClear,
  isReturnFolderAction
}: IProps) {
  const { contrast } = useTheme();
  const classes = useStyles({ contrast });

  const { setValue, control, trigger, clearErrors } = useFormV2Context();

  const [inputValue, setInputValue] = useState<SelectOption | SelectOption[]>(isMultiple ? [] : null);

  useEffect(() => {
    if (initialValue && !inputValue) {
      setInputValue(initialValue);
    }
  }, [setInputValue, initialValue, inputValue]);

  const handleInputChange = (event, selectedOption: SelectOption) => {
    if (selectedOption === null) {
      onClear?.();
      setInputValue({ value: undefined, name: undefined });
    } else if (event && !_.isEqual(selectedOption, inputValue)) {
      setInputValue(selectedOption);
      if (customSetValue) {
        customSetValue(selectedOption);
      } else {
        setValue(fieldName as any, selectedOption);
      }
    }
    if (fieldName) trigger(fieldName);
    setValue(typedNameV2<ApplicationCreateFormValues>('categoryKey'), { value: null });
    setValue(typedNameV2<ApplicationCreateFormValues>('applicationDefinition'), null);
    clearErrors?.();
  };

  const fetchFunctionResolverWithPage = (text, page) => {
    if (isArchive) {
      if (isReturnFolderAction) {
        return API.borrowFolders.getFolderRentalDetailsPage({
          page: 1 * page,
          size: 10,
          ...(text ? { folderNumberContains: text } : {}),
          ...(statusKeyIn ? { statusKeyIn } : {}),
          ...(archiveFoldersQueryParams || {})
        });
      }

      return API.archiveFolders.getArchiveFolderLites({
        page: 1 * page,
        size: 10,
        ...(text ? { subjectNipOrFolderNumberContains: text } : {}),
        ...(statusKeyIn ? { statusKeyIn } : {}),
        ...(archiveFoldersQueryParams || {})
      });
    }

    return API.folder.getFoldersLitePage({
      page: 1 * page,
      size: 10,
      ...(text && !isFilterOnlyByFolderNumber ? { subjectNipOrFolderNumberContains: text } : {}),
      ...(text && isFilterOnlyByFolderNumber ? { number: text } : {}),
      ...(statusKeyIn ? { statusFilter: { values: statusKeyIn } } : {}),
      ...(folderTypeKeys ? { typeKeyIn: folderTypeKeys } : {})
    });
  };
  const fetchedDataSelectParser = (res: AxiosResponse<FolderExtendedLitesPage | FolderRentalDetailsPage>) => {
    if (isArchive && isReturnFolderAction) {
      return res.data?.content?.map(item => ({
        id: item?.folderId,
        value: item,
        name: item?.folderNumber,
        typeKey: item?.folderType,
        number: item?.folderNumber,
        nip: item?.nip
      }));
    }

    return res.data?.content?.map(item => ({
      id: item.id,
      value: item,
      name: item?.number,
      typeKey: item?.typeKey,
      number: item?.number,
      nip: item?.subject?.nip
    }));
  };

  const renderOption = (option: ReturnType<typeof fetchedDataSelectParser>[0]) => (
    <div>
      {option?.name}
      <div className={classes.nip}>NIP: {option?.nip}</div>
    </div>
  );

  const customFilterOptionsMap = (options: SelectOption[]) => options;

  return !fieldName ? (
    <AutocompleteLazyFetchSelect
      name={fieldName}
      label={label}
      fetchFunctionResolverWithPage={fetchFunctionResolverWithPage}
      queryKey={queryKey}
      fetchedDataSelectParser={fetchedDataSelectParser}
      value={inputValue}
      renderOption={renderOption}
      onChange={handleInputChange}
      isRequired={isRequired}
      isMultiple={isMultiple}
      customFilterOptions={customFilterOptionsMap}
    />
  ) : (
    <Controller
      name={fieldName}
      control={control}
      render={({ fieldState: { error } }) => (
        <AutocompleteLazyFetchSelect
          name={fieldName}
          label={label}
          fetchFunctionResolverWithPage={fetchFunctionResolverWithPage}
          queryKey={queryKey}
          fetchedDataSelectParser={fetchedDataSelectParser}
          value={inputValue}
          renderOption={renderOption}
          onChange={handleInputChange}
          isRequired={isRequired}
          isError={!!error && error?.type !== FormErrorType.WARNING}
          helperText={error?.message}
          isMultiple={isMultiple}
          customFilterOptions={customFilterOptionsMap}
        />
      )}
    />
  );
}

const useStyles = makeStyles<Theme, { contrast?: boolean }>(theme => ({
  nip: {
    ...theme.typography.textSm.normal,
    color: ({ contrast }) => (contrast ? theme.palette.grey[200] : theme.palette.grey[600])
  }
}));
export default FolderSelectField;
