import _ from 'lodash';

interface ReactTableFilter {
  id: string;
  value: any;
}

export interface ReactTableSorting {
  id: string;
  desc: boolean;
}

const isEmptyObject = (value: any): boolean => _.isPlainObject(value) && !_.keys(value).length;
const isEmptyArray = (value: any): boolean => Array.isArray(value) && !value.length;
const getSortString = (id: string, order: boolean): string => {
  return `${id},${order ? 'DESC' : 'ASC'}`;
};

export const convertFiltersToObject = (
  filterSettings: ReactTableFilter[],
  mappedFilterFields: Record<string, any> = null
) => {
  if (mappedFilterFields) {
    return filterSettings.reduce((acc, item) => ({ ...acc, ...mappedFilterFields[item.id]?.(item.value) }), {});
  }
  return filterSettings
    .filter(filterSetting => !isEmptyObject(filterSetting.value) && !isEmptyArray(filterSetting.value))
    .reduce((acc, filterSetting) => ({ ...acc, [filterSetting.id]: filterSetting.value }), {});
};

export const convertParamsToObject = (
  paramsSettings: Record<string, any>,
  mappedParamsFields: Record<string, any> = null
) => {
  const params = Object.entries(paramsSettings);

  if (!params?.length) {
    return null;
  }

  if (mappedParamsFields) {
    return params.reduce((acc, item) => {
      return { ...acc, ...mappedParamsFields[item[0]]?.(item[1]) };
    }, {});
  }

  return params.reduce((acc, param) => {
    if (!isEmptyObject(param?.[1]) && !isEmptyArray(param?.[1])) {
      return { ...acc, [param[0]]: param[1] };
    }
    return acc;
  }, {});
};

export const convertSortParamToQuery = (
  sortingSettings: ReactTableSorting[],
  mappedSortFields: Record<string, any> = null
) => {
  if (mappedSortFields) {
    return sortingSettings.reduce((acc, param) => {
      if (mappedSortFields[param.id]) {
        acc.push(getSortString(mappedSortFields[param.id], param.desc));
      } else {
        acc.push(getSortString(param.id, param.desc));
      }
      return acc;
    }, []);
  }

  return sortingSettings.map(param => getSortString(param.id, param.desc));
};
