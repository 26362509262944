import { AddressSnapshot, CourierAdditionalServices, GeneratedLabelDetails } from '@ibtm/domain';

import { KeyType } from '@libs/common';

import { DomainDictionaryEntry } from '../../config';

export enum DocumentsIssuedQueryEnum {
  DOCUMENT_LIST = 'DOCUMENT_LIST',
  SUBJECT_ADDRESSES = 'SUBJECT_ADDRESSES',
  GLS_SERVICES_LIST = 'GLS_SERVICES_LIST',
  DOWNLOAD_FILE = 'DOWNLOAD_FILE',
  VERIFY_PARCEL = 'VERIFY_PARCEL',
  PARCEL_SUMMARY = 'PARCEL_SUMMARY',
  PARCEL_NOTE = 'PARCEL_NOTE'
}

interface SubjectAddress {
  id: string;
  value: string;
  name: string;
}

export interface DocumentIssuedClient {
  id: string;
  authorization: boolean;
  created: string;
  statusKey: string;
  inputDate: string;
  version: number;
  isPriceField: boolean;
  typeKey: string;
  contactAddress: {
    phoneNumber?: string;
    email?: string;
  };
  folder: {
    id?: string;
    number?: string;
    type?: string;
    version?: number;
  };
  application: {
    id?: string;
    archiveNumber?: string;
    number?: string;
    type?: string;
    status?: string;
    recipientName?: string;
    recipientSurname?: string;
    categoryKey?: string;
  };
  author: {
    id: string;
    name: string;
  };
  insertedBy: {
    id: string;
    name: string;
  };
  addresseeName?: string;
  subject: {
    id?: string;
    name?: string;
    nip?: string;
  };
  generatedLabelDetails?: GeneratedLabelDetails;
  parcel: {
    id?: string;
    passedToCourier?: boolean;
    receiptType?: string;
    passedToSentDate?: string;
    sendDate?: string;
    receiptAckDate?: string;
    returnDate?: string;
    number?: string;
    labelFileId?: string;
    version: number;
  };
  address: AddressSnapshot;
  file: {
    id?: string;
    name?: string;
  };
  epuap?: string;
  recipientName?: string;
  recipientSurname?: string;
}

export const availableToEditParcelStatuses = [
  DomainDictionaryEntry.DOCUMENT_STATUS.RELEASE_PENDING_FOR_DELIVERY,
  DomainDictionaryEntry.DOCUMENT_STATUS.RELEASE_DELIVERED,
  DomainDictionaryEntry.DOCUMENT_STATUS.READY_TO_SEND
];

export interface DocumentsIssuedQueryParams {
  size?: number;
  sort?: Array<string>;
  page?: number;
  authorization?: string;
  applicationNumber?: string;
  archiveNumber?: string;
  sendingOption?: string;
  subjectName?: string;
  address?: string;
  documentName?: string;
  folderNumber?: string;
  statusKey?: string;
  created?: string;
  issuedToSendDate?: string;
  authorName?: string;
  sendDate?: string;
  receivedDate?: string;
  returnedDate?: string;
  packageNumber?: string;
  ids?: string[];
}

export enum DocumentsIssuedSendingOptionsEnum {
  PICKUP_IN_PERSON = 'ibtm-domain.receipt.type.pickup-in-person',
  COURIER = 'ibtm-domain.receipt.type.courier',
  EPUAP = 'ibtm-domain.receipt.type.epuap',
  POST = 'ibtm-domain.receipt.type.post',
  E_MAIL = 'document.send.option.eMail'
}

export enum ClickTypeEnum {
  CREATE_LABEL = 'CREATE_LABEL',
  CHANGE_RECEIVED_DATE = 'CHANGE_RECEIVED_DATE',
  CANCEL_SENDING = 'CANCEL_SENDING',
  CREATE_COMPLAINT = 'CREATE_COMPLAINT',
  CREATE_BILLING_NOTE = 'CREATE_BILLING_NOTE',
  SET_RETURNED_DATE = 'SET_RETURNED_DATE',
  SET_RECEIVED_DATE = 'SET_RECEIVED_DATE',
  GENERATE_LIST_FOR_COURIER = 'GENERATE_LIST_FOR_COURIER'
}

export enum DocumentsIssuedInitialValuesEnum {
  ADDITIONAL_SERVICES = 'additionalService',
  RESEND_REQUIRED = 'resendRequired',
  CANCEL_SEND = 'cancelSend',
  NEW_ADDRESS = 'addressToSend',
  RECEIVED_DATE = 'receivedDate',
  SEND_OPTION = 'sendOption',
  RETURNED_DATE = 'returnDate'
}

export enum DialogTitlesEnum {
  ADDITIONAL_SERVICES = 'documentsIssued:actions.additionalServices',
  CHANGE_ADDRESS = 'documentsIssued:actions.changeAddress',
  CHANGE_SEND_OPTION = 'documentsIssued:actions.changeSendOption',
  CANCEL_SENDING = 'documentsIssued:actions.cancelSending',
  SET_RECEIVED_DATE = 'documentsIssued:actions.setReceivedDate',
  CHANGE_RECEIVED_DATE = 'documentsIssued:actions.changeReceivedDate',
  SET_RETURNED_DATE = 'documentsIssued:actions.setReturnedDate',
  PICKUP_IN_PERSON = 'documentsIssued:actions.pickupInPerson'
}

export interface DocumentsIssuedFormValues {
  additionalService: { [key: string]: boolean };
  cancelSend: boolean;
  addressToSend: SubjectAddress;
  receivedDate: string;
  sendOption: { name: string; value: string };
  returnDate: string;
  remarks: string;
  resendRequired?: boolean;
}

export const initialValues: DocumentsIssuedFormValues = {
  additionalService: null,
  cancelSend: null,
  addressToSend: null,
  receivedDate: null,
  sendOption: null,
  returnDate: null,
  remarks: null
};

export type MenuAction = { name: KeyType; type: ClickTypeEnum };

export type ActionDialogType = {
  open: boolean;
  title: DialogTitlesEnum;
  content: React.ReactNode;
  onSuccess?: () => void;
};

export enum DocumentIssuedStatusEnum {
  READY_FOR_RELEASE = 'ibtm-domain.document.status.ready-for-release',
  READY_TO_SENED = 'ibtm-domain.document.status.ready-to-send',
  DELIVERED = 'ibtm-domain.document.status.release-delivered',
  GENERATED = 'ibtm-domain.document.status.generated',
  PENDING_DELIVERY = 'ibtm-domain.document.status.release-pending-for-delivery',
  RETURNED = 'ibtm-domain.document.status.returned'
}

export const AdditionalServicesEnum: {
  [key in Uppercase<keyof CourierAdditionalServices>]: Lowercase<key> extends keyof CourierAdditionalServices
    ? Lowercase<key>
    : never;
} = {
  EXW: 'exw',
  OW: 'ow',
  S12: 's12',
  SAT: 'sat',
  SDS: 'sds'
};
