import moment from 'moment';

const select = () => [];
select.autoRemove = value => !value?.length;

const dateRange = () => ({ dateFrom: null, dateTo: null });
dateRange.autoRemove = value => !value?.dateFrom && !value?.dateTo;

const customRange = (rows: any[], id: string, filterValue) => {
  const start = moment(filterValue.dateFrom).subtract(1, 'day');
  const end = moment(filterValue.dateTo).add(1, 'day');
  return rows.filter(val => moment(val.original[id]).isBetween(start, end));
};

export const filterTypes = {
  select,
  dateRange,
  customRange
};

export default filterTypes;
