import { makeStyles } from '@mui/styles';

import { Theme } from '@libs/common/v2/theme';
import { important } from '@libs/common/v2/utils';

// Funkcja wykorzystywana w przypadku gdy chcemy nadpisać style dla komponentu <Typography> z MUI
const overrideTypographyForTextSmMedium = (theme: Theme) => ({
  fontSize: important(theme.typography.textSm.medium.fontSize),
  fontWeight: important(theme.typography.textSm.medium.fontWeight) as unknown as number
});

const useValueStyles = makeStyles<
  Theme,
  { booleanValue?: boolean; isTableCell?: boolean; contrast?: boolean; islabel?: boolean }
>(theme => ({
  labelWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
    color: important(theme.palette.grey[500]),
    ...theme.typography.textSm.medium
  },
  value: {
    color: ({ contrast }) => (contrast ? important(theme.palette.grey[400]) : important(theme.palette.grey[900])),
    wordBreak: 'break-word',
    ...theme.typography.textMd.medium,
    ...overrideTypographyForTextSmMedium(theme)
  },
  booleanValue: ({ isTableCell, contrast, islabel }) => {
    const getColor = () => {
      if (contrast) {
        return theme.palette.grey[400];
      }
      return isTableCell ? theme.palette.grey[700] : theme.palette.grey[900];
    };

    return {
      ...theme.typography[isTableCell ? 'textSm' : 'textMd'].medium,
      color: getColor(),
      marginTop: !isTableCell && islabel && theme.marginBase * 1.33,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: 8
      }
    };
  },
  lineBreak: {
    wordBreak: 'break-word'
  }
}));

export default useValueStyles;
