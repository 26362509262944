import { ProxiesSearchFilter as ProxiesSearchFilterClient } from '@ibtm/client-domain';
import { ProxiesSearchFilter } from '@ibtm/domain';

interface IProxyListQueryParams extends ProxiesSearchFilter {
  folderId?: string;
  applicationId?: string;
}

export const parseProxyListPayload = (params: IProxyListQueryParams) => {
  const { folderId, applicationId, ...queryParams } = params;

  return {
    ...queryParams,
    ...(folderId && { folderIdIn: [folderId] }),
    ...(applicationId && { applicationIdIn: [applicationId] })
  };
};

interface IProxyListClientQueryParams extends ProxiesSearchFilterClient {
  folderId?: string;
  applicationId?: string;
}

export const parseProxyListClientPayload = (params: IProxyListClientQueryParams): ProxiesSearchFilterClient => {
  const { folderId, applicationId, ...queryParams } = params;

  return {
    folderIdEq: folderId,
    applicationIdIn: applicationId ? [applicationId] : undefined,
    ...queryParams
  };
};
