import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { FolderCreateRequest } from '@ibtm/domain';
import { makeStyles } from '@mui/styles';

import { Button, typedNameV2, useFormV2Context } from '@libs/common/v2';

import RegonDataModal from './RegonDataModal';

function SubjectDataSectionSkeleton() {
  const [t] = useTranslation();
  const classes = useStyles();
  const snackbar = useSnackbar();
  const [regonModalVisible, setRegonModalVisible] = useState<boolean>(false);
  const { trigger } = useFormV2Context();

  const [downloading, setDownloading] = useState<boolean>(false);

  const downloadRegonData = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    setDownloading(true);
    trigger(typedNameV2<FolderCreateRequest>('subject.nip')).then(isValid => {
      if (isValid) {
        setRegonModalVisible(true);
      } else {
        snackbar.showSnackbar('error', t('folder:details.message.downloadRegonVatIdEmpty'));
      }
      setDownloading(false);
    });

    e.preventDefault();
  };

  return (
    <>
      <Button
        type="button"
        isLoading={downloading}
        className={classes.downloadRegonButton}
        label={t('folder:details.button.downloadRegonData')}
        variant="outlined"
        isPrimary
        onClick={downloadRegonData}
      />
      {regonModalVisible && <RegonDataModal open={regonModalVisible} setIsOpen={setRegonModalVisible} />}
    </>
  );
}

export default SubjectDataSectionSkeleton;

const useStyles = makeStyles({
  downloadRegonButton: {
    minWidth: 210
  }
});
