import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import { Button, Dialog, FormMode, FormV2Context, useTableContext } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { useCreateReplacementsMutation, useEditReplacementsMutation, useGroupListQuery } from '@libs/user/api';
import { AddReplacementsForm } from '@libs/user/components';
import { useReplacementsMode } from '@libs/user/hooks';
import { replacementsFormInitialValues, ReplacementsSnapshot, useReplacementsSchema } from '@libs/user/models';

interface IProps {
  formMode: FormMode;
  isOpen: boolean;
  original?: ReplacementsSnapshot;
  setIsOpen: (value: boolean) => void;
  setIsDeleteOpen?: (value: boolean) => void;
}

function AddReplacementDialog({ formMode, isOpen, original, setIsOpen, setIsDeleteOpen }: IProps) {
  const [t] = useTranslation();
  const [mode, setMode] = useState<FormMode>(formMode);
  const { replacementsSchema } = useReplacementsSchema(mode);

  const { viewMode, createMode, editMode } = useViewModesV2(mode);

  const { refetch } = useTableContext();
  const { mutate: createReplacements, isLoading } = useCreateReplacementsMutation({ setIsOpen, refetch });
  const { mutate: editReplacement, isLoading: editLoading } = useEditReplacementsMutation({ setIsOpen, refetch });
  const { replacementsTranslation, preparedFormData } = useReplacementsMode(mode, original);

  const { data: userGroupsData } = useGroupListQuery(
    { groupIds: original?.groupMemberships?.map(row => row?.group?.id) },
    { enabled: original?.groupMemberships?.length > 0 }
  );

  const form = useForm<Record<string, any>>({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(replacementsSchema),
    defaultValues: replacementsFormInitialValues
  });

  const formDataProvider = useMemo(() => {
    return { ...form };
  }, [form]);

  useEffect(() => {
    if (original) {
      if (viewMode || editMode) {
        form.reset(preparedFormData(userGroupsData?.content));
      } else {
        form.reset(replacementsFormInitialValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [original, mode, userGroupsData?.content]);

  const closeResetForm = () => {
    if (original) {
      if (viewMode || editMode) {
        form.reset(preparedFormData(userGroupsData?.content));
      } else {
        form.reset(replacementsFormInitialValues);
      }
    } else {
      form.reset(replacementsFormInitialValues);
    }
    setMode(FormMode.VIEW);
    setIsOpen(false);
  };

  const onSubmit = formValues => {
    const createPayload = {
      body: {
        ...formValues,
        groupMembershipsIds: form.getValues('groupMembershipsIds').map(({ id }) => id),
        substituteAccountId: form.getValues('substituteAccountId.id'),
        startDateTime: moment(formValues.startDateTime).add(2, 'h').set('h', 3),
        endDateTime: moment(formValues.endDateTime).add(2, 'h').set('h', 21)
      }
    };

    const editPayload = editMode && {
      id: original.id,
      body: {
        ...createPayload.body,
        endDateTime: moment(formValues.endDateTime).add(2, 'h').set('h', 21).toISOString(),
        startDateTime: moment(formValues.startDateTime).add(2, 'h').set('h', 3).toISOString(),
        version: original.version + 1
      }
    };

    return createMode ? createReplacements(createPayload) : editReplacement(editPayload);
  };

  const values = useMemo(() => ({ ...formDataProvider, formMode: mode }), [formDataProvider, mode]);
  const replacementsDialogTranslation = useMemo(() => replacementsTranslation(), [replacementsTranslation]);

  return (
    <FormV2Context.Provider value={values}>
      <Dialog
        title={replacementsDialogTranslation.title}
        isConfirmButtonVisible={!viewMode}
        customActions={
          <>
            {viewMode && (
              <>
                <Button
                  label={t('action.delete')}
                  onClick={() => {
                    setIsOpen(false);
                    setIsDeleteOpen(true);
                  }}
                  variant="outlined"
                  isSecondary
                />
                <Button
                  label={t('action.edit')}
                  onClick={() => setMode(FormMode.EDIT)}
                  variant="outlined"
                  isSecondary
                />
              </>
            )}
            <Button
              label={replacementsDialogTranslation.cancel}
              onClick={() => {
                setIsOpen(false);
                setMode(formMode);
              }}
              isSecondary
              variant="outlined"
            />
            {!viewMode && (
              <Button
                label={replacementsDialogTranslation.confirm}
                onClick={form.handleSubmit(onSubmit)}
                isLoading={isLoading || editLoading}
                isPrimary
              />
            )}
          </>
        }
        onCancel={closeResetForm}
        onClose={closeResetForm}
        dialogSize="medium"
        isOpen={isOpen}
      >
        <AddReplacementsForm mode={mode} />
      </Dialog>
    </FormV2Context.Provider>
  );
}

export default AddReplacementDialog;
