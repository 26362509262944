import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { FileMetadataObject } from '@ibtm/domain';
import { GeneratePermissionsRequest } from '@ibtm/domain/dist/models/generate-permissions-request';
import { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';

import { useQueryCache } from '@libs/common/v2/api';

import { downloadFile, getFilenameFromHeader } from '@libs/file';

import { generatePermissions, PrintsQueryKeysEnum, useGeneratePrintMutation } from '../api';

import useFetchPrintsApplicationDetails from './useFetchPrintsApplicationDetails';

type PrintGenerateConfig = {
  onSettled?: () => void;
  onSuccess?: () => void;
};

function usePrintActionHandler() {
  const [t] = useTranslation();
  const { showSuccessSnackbar, showSnackbar, showErrorSnackbar } = useSnackbar();

  const { mutate: generate, isLoading } = useGeneratePrintMutation();
  const { applicationData } = useFetchPrintsApplicationDetails();
  const queryCache = useQueryCache();

  const handleGenerateSuccess = async (
    response: AxiosResponse<FileMetadataObject, any>,
    config?: PrintGenerateConfig
  ) => {
    const fileId = response.data.id;
    showSuccessSnackbar(t('prints:messages.generationSuccessMessage'));

    const handleDownloadFile = async () => {
      try {
        const fileResponse = await downloadFile({ fileId });
        const fileName = getFilenameFromHeader(fileResponse);
        FileSaver.saveAs(fileResponse.data, fileName);
      } catch (error) {
        return showErrorSnackbar(t('error.fileDownloadFailure'));
      }
      queryCache.invalidateQueries([PrintsQueryKeysEnum.PRINTS_LAST_PRINTED_PERMISSIONS]);
      return null;
    };
    config?.onSuccess?.();
    handleDownloadFile();
  };

  const parseGeneratePermissionRequest = ({
    permissionTypeCode,
    permissionIds,
    foreignPermissionRequests
  }: GeneratePermissionsRequest) => {
    return {
      applicationId: applicationData?.id,
      generatePermissionRequest: {
        permissionIds,
        permissionTypeCode,
        foreignPermissionRequests
      }
    };
  };

  const print = async (data: GeneratePermissionsRequest, config?: PrintGenerateConfig) => {
    try {
      const response = await generatePermissions(parseGeneratePermissionRequest(data));
      handleGenerateSuccess(response, config);
    } catch (e) {
      showSnackbar('warning', t('prints:messages.generationFailureMessage'));
    }
    config?.onSettled?.();
  };

  const printMutation = (data: GeneratePermissionsRequest, config?: PrintGenerateConfig) => {
    generate(parseGeneratePermissionRequest(data), {
      onSuccess: response => handleGenerateSuccess(response, config),
      onError: () => showSnackbar('warning', t('prints:messages.generationFailureMessage')),
      onSettled: () => {
        config?.onSettled?.();
      }
    });
  };

  return { print, mutation: { printMutation, isLoading } };
}

export default usePrintActionHandler;
