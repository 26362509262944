export const endpointsConfig: { [key: string]: { translationKey: string } } = {
  updateDocumentSendAddress: { translationKey: 'documents:api.updateDocumentSendAddress' },
  updateDocumentReceiptType: {
    translationKey: 'documents:api.updateDocumentReceiptType'
  },
  updateDocumentReceiptAckDate: { translationKey: 'documents:api.updateDocumentReceiptAckDate' },
  changeReturnDate: { translationKey: 'documents:api.changeReturnDate' },
  updateDocumentsSendDate: { translationKey: 'documents:api.updateDocumentsSendDate' },
  prepareDocumentForSendingOrMarkAsDelivered: {
    translationKey: 'documents:api.prepareDocumentForSendingOrMarkAsDelivered'
  },
  prepareDocumentsForSendingOrMarkAsDelivered: {
    translationKey: 'documents:api.prepareDocumentsForSendingOrMarkAsDelivered'
  },
  releaseDocuments: { translationKey: 'documents:api.releaseDocuments' },
  withdrawSendingOfDocument: { translationKey: 'documents:api.withdrawSendingOfDocument' },
  getAdditionalServices: { translationKey: 'documents:api.getAdditionalServices' },
  getDocumentsSnapshotPage: { translationKey: 'documents:api.getDocumentsSnapshotPage' },
  publicDownload: { translationKey: 'documents:api.publicDownload' },
  getAddressesList: {
    translationKey: 'addresses:api.getAddressesList'
  },
  getParcelSummary: {
    translationKey: 'documents:api.getParcelSummary'
  },
  getParcelNotes: {
    translationKey: 'documents:api.getParcelNotes'
  },
  generateParcelComplaintDocument: { translationKey: 'documents:api.generateParcelComplaintDocument' },
  verifyParcel: { translationKey: 'documents:api.verifyParcel' },
  updateDocumentIssuingConfirmation: { translationKey: 'documents:api.updateDocumentIssuingConfirmation' },
  updateDocumentParcel: { translationKey: 'documents:api.updateDocumentParcel' }
};
