import { useEffect, useMemo, useState } from 'react';
import { SubjectDetailsExtended } from '@ibtm/domain';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

import { FormMode } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';

import { HasPermission, UIElementNameEnum, useElementVisibility } from '@libs/permission';

import { DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';
import {
  FolderDetailsClient,
  FolderSliderSectionEnum,
  SliderHeader,
  useFolderDetailsExtendedQuery
} from '@libs/domain/folder';
import { useSubjectDetailsExtendedQuery } from '@libs/domain/subject';

import { BasicDataBlock } from './basic-data';
import { FolderBlockade } from './blockade';
import { ContactBlock } from './contact';
import { LicenceBlock } from './licence';
import { MiscInfoBlock } from './miscellaneous';
import { NotesSection } from './notes';
import { SubjectBlock } from './subject';

interface Props {
  folderId: string;
  formMode?: FormMode;
  hiddenSections?: FolderSliderSectionEnum[];
  isGotoFolderHidden?: boolean;
  actionKey?: UIElementNameEnum;
  subjectInitialData?: SubjectDetailsExtended;
  isCopyNIPActionVisible?: boolean;
  isCopyEmailActionVisible?: boolean;
  isCopyREGONActionVisible?: boolean;
  isFolderRedirectActionVisible?: boolean;
  isCopyBlockadeListRedirectActionVisible?: boolean;
  hasSubjectDataPermission?: boolean;
}

function FolderSlider({
  formMode,
  folderId,
  hiddenSections = [],
  isGotoFolderHidden,
  actionKey,
  subjectInitialData,
  isCopyNIPActionVisible = true,
  isCopyEmailActionVisible = true,
  isCopyREGONActionVisible = true,
  isFolderRedirectActionVisible = true,
  isCopyBlockadeListRedirectActionVisible = true,
  hasSubjectDataPermission = true
}: Props) {
  const { isOperatorPortal, isClientPortalEntrepreneurPerspective } = useDomainConfigContext();
  const { checkIsElementVisible } = useElementVisibility();

  const {
    data: folderDetails,
    isLoading,
    isError
  } = useFolderDetailsExtendedQuery(folderId, {
    enabled: !!folderId,
    staleTime: 1000 * 60 * 3,
    refetchOnMount: false
  });
  const {
    data: subjectDetails,
    isLoading: subjectDataLoading,
    isError: subjectDataError
  } = useSubjectDetailsExtendedQuery(folderDetails?.subject?.id, {
    enabled: !!folderDetails && hasSubjectDataPermission,
    staleTime: 1000 * 60 * 3,
    refetchOnMount: false,
    initialData: subjectInitialData
  });
  const [folderData, setFolderData] = useState<FolderDetailsClient>(null);
  const classes = useStyles();

  const emptyMode = useMemo(() => _.isEmpty(folderDetails), [folderDetails]);

  const loading = folderId && (isLoading || subjectDataLoading || (!folderData && !isError && !subjectDataError));

  const isSectionVisible = sectionName => !hiddenSections.includes(sectionName);

  const isElementVisible = useMemo(() => {
    return actionKey ? checkIsElementVisible(actionKey) : true;
  }, [checkIsElementVisible, actionKey]);

  useEffect(() => {
    if (!!folderDetails && (!!subjectDetails || !hasSubjectDataPermission)) {
      setFolderData({
        ...folderDetails,
        subject: subjectDetails
      });
    }
  }, [folderDetails, hasSubjectDataPermission, subjectDetails]);

  if (isElementVisible) {
    return (
      <div className={classes.root}>
        <div className={classes.headerWrapper}>
          <SliderHeader
            folderDetails={{
              number: folderData?.number,
              typeKey: folderData?.typeKey
            }}
          />
          {isOperatorPortal && folderDetails?.locked && (
            <FolderBlockade
              id={folderDetails?.id}
              formMode={formMode}
              isBlockadeListRedirectActionVisible={isCopyBlockadeListRedirectActionVisible}
            />
          )}
        </div>
        <div className={classes.contentWrapper}>
          {isSectionVisible(FolderSliderSectionEnum.SUBJECT) && (
            <HasPermission actionKeys={[DomainUIElementEnum.FOLDER_SLIDER_SUBJECT_SECTION]}>
              <SubjectBlock
                folderDetails={folderData}
                isLoading={loading}
                isGotoFolderHidden={isGotoFolderHidden}
                isCopyNIPActionVisible={isCopyNIPActionVisible}
                isCopyREGONActionVisible={isCopyREGONActionVisible}
                isFolderRedirectActionVisible={isFolderRedirectActionVisible}
              />
            </HasPermission>
          )}
          {isSectionVisible(FolderSliderSectionEnum.CONTACT) && (
            <ContactBlock
              folderDetails={folderData}
              emptyMode={emptyMode}
              isLoading={loading}
              isCopyEmailActionVisible={isCopyEmailActionVisible}
            />
          )}
          {isSectionVisible(FolderSliderSectionEnum.PERMISSIONS) && isOperatorPortal && (
            <HasPermission actionKeys={[DomainUIElementEnum.FOLDER_SLIDER_PERMISSION_SECTION]}>
              <LicenceBlock folderId={folderId} folderDetails={folderData} isLoading={loading} />
            </HasPermission>
          )}
          {isSectionVisible(FolderSliderSectionEnum.OTHER) &&
            (isOperatorPortal || isClientPortalEntrepreneurPerspective) && (
              <MiscInfoBlock
                folderDetails={folderData}
                formMode={formMode}
                emptyMode={emptyMode}
                isLoading={loading}
                isCopyBlockadeListRedirectActionVisible={isCopyBlockadeListRedirectActionVisible}
              />
            )}
          {isSectionVisible(FolderSliderSectionEnum.NOTES) && isOperatorPortal && (
            <NotesSection folderDetails={folderData} emptyMode={emptyMode} isLoading={loading} />
          )}
          {isSectionVisible(FolderSliderSectionEnum.NOTES) && isOperatorPortal && (
            <BasicDataBlock folderDetails={folderData} emptyMode={emptyMode} isLoading={loading} />
          )}
        </div>
      </div>
    );
  }
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    height: '100%',
    backgroundColor: 'white',
    minWidth: 300,
    maxWidth: 424,
    boxSizing: 'border-box'
  },
  header: {
    height: 54,
    boxSizing: 'border-box'
  },
  headerWrapper: {
    zIndex: 11,
    backgroundColor: theme.palette.grey[50],
    position: 'sticky',
    top: '0'
  },
  contentWrapper: {
    padding: '9px 12px 0 14px'
  }
}));

export default FolderSlider;
