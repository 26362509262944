import React from 'react';

import { useTab } from '@libs/common/v2';

import { DomainUIElementEnum } from '@libs/domain/config';

import { ArchiveTabEnum, ArchiveTabsContent, TabType } from '../../common';

import { ApplicationToArchiveTab, ArchivedApplicationTab } from './tabs';

const tabs: TabType[] = [
  {
    label: 'archive:application.applicationToArchive' as KeyType,
    value: ArchiveTabEnum.TO_ARCHIVE,
    viewName: DomainUIElementEnum.ARCHIVE_APPLICATIONS_TO_ARCHIVE_VIEW
  },
  {
    label: 'archive:application.companyArchive' as KeyType,
    value: ArchiveTabEnum.ARCHIVED,
    viewName: DomainUIElementEnum.ARCHIVE_APPLICATIONS_ARCHIVED_VIEW
  }
];
const tabsContent = [
  {
    value: ArchiveTabEnum.TO_ARCHIVE,
    content: <ApplicationToArchiveTab />,
    viewName: DomainUIElementEnum.ARCHIVE_APPLICATIONS_TO_ARCHIVE_VIEW
  },
  {
    value: ArchiveTabEnum.ARCHIVED,
    content: <ArchivedApplicationTab />,
    viewName: DomainUIElementEnum.ARCHIVE_APPLICATIONS_ARCHIVED_VIEW
  }
];

function ApplicationArchiveContent() {
  const [activeTab, setActiveTab] = useTab<ArchiveTabEnum>(ArchiveTabEnum.TO_ARCHIVE);

  return <ArchiveTabsContent activeTab={activeTab} onTabChange={setActiveTab} tabs={tabs} tabsContent={tabsContent} />;
}

export default ApplicationArchiveContent;
