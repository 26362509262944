import { useDialog } from '@libs/common/v2';

import { TransportManagerExpireDialog } from '../components';

const useTransportManagerExpireDialog = () => {
  const { openDialog } = useDialog();

  const openTransportManagerExpireDialog = (id: string, version?: number) => {
    openDialog(({ closeDialog }) => (
      <TransportManagerExpireDialog closeDialog={closeDialog} id={id} version={version} />
    ));
  };

  return { openTransportManagerExpireDialog };
};

export default useTransportManagerExpireDialog;
