import { ProceedingKreptdCertificateAddRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

interface IParams {
  proceedingId: string;
  proceedingKreptdCertificateAddRequest: ProceedingKreptdCertificateAddRequest;
}

function addCertificatesToProceedingKreptd({ proceedingId, proceedingKreptdCertificateAddRequest }: IParams) {
  return API.proceedingsKreptd.addCertificatesToProceedingKreptd(proceedingId, proceedingKreptdCertificateAddRequest, {
    ...endpointsConfig.addCertificateToProceedingKreptd
  });
}

function useAddCertificatesToProceedingKreptdMutation() {
  return useMutation(addCertificatesToProceedingKreptd);
}

export default useAddCertificatesToProceedingKreptdMutation;
