import { useContext } from 'react';

import { GroupCustomPermissionsContext } from '@libs/user/context';

function useGroupCustomPermissions() {
  const { canAddGroup } = useContext(GroupCustomPermissionsContext);

  return {
    canAddGroup
  };
}

export default useGroupCustomPermissions;
