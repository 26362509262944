import { useCallback } from 'react';

import { useDialog } from '@libs/common/v2';

import { CertificateSEditDialog, IUpdateSCertificateRequest } from '../components/edit/CertificateSEditDialog';

export function useSCertificateEditDialog() {
  const { openDialog } = useDialog();

  const showDialog = useCallback(
    (initialData: IUpdateSCertificateRequest, refetch?: () => void) => {
      openDialog(({ closeDialog }) => (
        <CertificateSEditDialog closeDialog={closeDialog} initialData={initialData} refetch={refetch} />
      ));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog]
  );

  const openEditSCertificateDialog = (initialData: IUpdateSCertificateRequest, refetch?: () => void) => {
    showDialog(initialData, refetch);
  };

  return {
    openEditSCertificateDialog
  };
}
