import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '@libs/user/api';

const unlockUser = async (accountId: string) => {
  const { data } = await API.user.accountController.unlockAccount(
    {
      accountId
    },
    { ...endpointsConfig.unlockAccount }
  );

  return data;
};

export default function useUnlockUserMutation() {
  return useMutation(unlockUser);
}
