import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MutationResultPair } from 'react-query';
import { DocumentTemplateSnapshotExternal } from '@avispon/document-generator';
import { IPaginatedModel, useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ApplicationDocumentCreateRequest,
  DocumentSnapshot,
  FileMetadataObject,
  FileMetadataObjectList
} from '@ibtm/domain';
import { AxiosResponse } from 'axios';
import _, { get } from 'lodash';
import * as Yup from 'yup';

import {
  AutocompleteLazyFetchSelectField,
  Dialog,
  DialogContentContainer,
  DictionarySelectField,
  FormV2Context,
  GridLayout,
  InputMode,
  SelectOption,
  typedNameV2,
  useFormV2Watch
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { useDictionaryEntryValues } from '@libs/dictionary';
import { API, useDocumentTemplatesQuery } from '@libs/document-template';
import { useDownloadDocumentFile } from '@libs/file';
import { useElementVisibility } from '@libs/permission';

import { documentTemplateGroupByProcess } from '@libs/domain/application';
import { DomainDictionaryEntry, DomainDictionaryEnum, DomainUIElementEnum } from '@libs/domain/config';

import { IDownloadFileParam } from '../../../foreign-permission';

interface IProps {
  title: string;
  apiParams: { [key: string]: any };
  onSuccess: (response?: any) => void;
  onCancel: () => void;
  closeDialog: () => void;
  useGenerateApplicationDocumentMutation: () => MutationResultPair<
    AxiosResponse<DocumentSnapshot | FileMetadataObject>,
    unknown,
    unknown,
    unknown
  >;
  listQueryKey: string;
  groupKey?: string;
  typeKeys?: Array<string>;
  documentTemplateName?: string;
  applicationType?: string;
  isGroupFieldDisabled?: boolean;
  isDocumentDownloadingAfterGenerate?: boolean;
  isFieldsViewOnly?: boolean;
  isGroupFieldViewOnly?: boolean;
  documentTemplateNameFieldDisabled?: boolean;
  /** ścieżka do obiektu 'file'(FileMetadataObject) w zwróconym response mutacji generowania dokumetnu, aby wskazać plik do pobrania */
  downloadFilePath: string;
  includeWithKeys?: boolean;
  downloadFileAfterSettleForeignPermission?: (params: IDownloadFileParam) => void;
  isForeignPermissionsUse?: boolean;
}

type FormValueType = {
  documentTemplateId: { name: string; value: { id: string } };
};

function GenerateApplicationDocumentDialog({
  title,
  apiParams = {},
  onSuccess,
  onCancel,
  closeDialog,
  useGenerateApplicationDocumentMutation,
  listQueryKey,
  groupKey = null,
  typeKeys,
  documentTemplateName,
  applicationType,
  isGroupFieldDisabled,
  isGroupFieldViewOnly,
  isDocumentDownloadingAfterGenerate,
  isFieldsViewOnly,
  documentTemplateNameFieldDisabled = Boolean(documentTemplateName),
  downloadFilePath = 'file',
  includeWithKeys,
  downloadFileAfterSettleForeignPermission,
  isForeignPermissionsUse
}: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { checkIsElementVisible } = useElementVisibility();

  const filterOptions = useCallback(
    (option: SelectOption<string>) => {
      const { active } = option;
      switch (option.value) {
        case DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.CAUTION:
          return checkIsElementVisible(DomainUIElementEnum.APPLICATION_OUTGOING_DOCUMENTS_CAUTION_GENERATE) && active;
        case DomainDictionaryEntry.DOCUMENT_TEMPLATE_GROUP.CONFIRMATION_OF_SUBMISSION:
          return (
            checkIsElementVisible(DomainUIElementEnum.APPLICATION_OUTGOING_DOCUMENTS_GROUP_SUBMISSION_CONFIRMATION) &&
            active
          );
        default:
          return active;
      }
    },
    [checkIsElementVisible]
  );

  const { downloadFile } = useDownloadDocumentFile({
    onSuccess: () => {
      closeDialog();
      onSuccess?.();
    }
  });

  const [generateDocument] = useGenerateApplicationDocumentMutation();
  const dictionaryEntries = useDictionaryEntryValues(DomainDictionaryEnum.DOCUMENT_TEMPLATE_GROUP)
    .filter(filterOptions)
    .map(item => item.value);

  const { data: initialDocumentTemplate } = useDocumentTemplatesQuery(
    {
      nameFragment: documentTemplateName
    },
    {
      enabled: !!documentTemplateName
    }
  );

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    reset
  } = useForm<Record<string, any>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        documentType: Yup.object().nullable().required(),
        documentTemplateId: Yup.object().nullable().required()
      })
    ),
    defaultValues: {
      documentType: { value: groupKey },
      documentTemplateId: null
    }
  });
  const documentTypeWatcher = useFormV2Watch<SelectOption<string>>({ control, name: 'documentType' });

  useEffect(() => {
    const templateId = initialDocumentTemplate?.content?.[0]?.id;

    if (templateId) {
      reset({
        ...getValues(),
        documentTemplateId: {
          name: documentTemplateName,
          value: { id: templateId }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDocumentTemplate]);

  useEffect(() => {
    if (!documentTemplateName && !isFieldsViewOnly && !isGroupFieldViewOnly) {
      setValue('documentTemplateId', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTypeWatcher]);

  const onSubmit = (formData: FormValueType, setConfirmLoading) => {
    setConfirmLoading(true);

    const params = isForeignPermissionsUse
      ? {
          ...apiParams,
          documentTemplateId: formData.documentTemplateId?.value?.id
        }
      : {
          ...apiParams,
          data: {
            documentTemplateId: formData.documentTemplateId?.value?.id
          }
        };

    generateDocument(params, {
      onSuccess: ({ data }) => {
        queryCache.invalidateQueries(listQueryKey);
        showSnackbar('success', t('document:message.generateDocumentSuccess'));
        if (isDocumentDownloadingAfterGenerate) {
          if (isForeignPermissionsUse) {
            downloadFileAfterSettleForeignPermission({
              downloadFunction: downloadFile,
              data: data as FileMetadataObjectList
            });
          } else {
            downloadFile(downloadFilePath ? get(data, downloadFilePath) : data);
          }
        } else {
          closeDialog();
          onSuccess?.(data);
        }
      },
      onError: () => setConfirmLoading(false),
      onSettled: () => {
        if (!isDocumentDownloadingAfterGenerate) {
          setConfirmLoading(false);
        }
      }
    });
  };

  const getOptionLabel = (option: SelectOption) => {
    return option.name;
  };

  const groupsByProcess = applicationType ? _.get(documentTemplateGroupByProcess, applicationType, []) : [];

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  const selectDefaultValueIfOneOption = (data: IPaginatedModel<DocumentTemplateSnapshotExternal>) => {
    const content = data?.content;
    if (content.length === 1) {
      setValue(typedNameV2<ApplicationDocumentCreateRequest>('documentTemplateId'), {
        name: content[0].name,
        value: content[0]
      });
    }
  };

  const getGroupKeys = (): string[] => {
    if (groupsByProcess.length && isGroupFieldViewOnly) {
      return groupsByProcess;
    }
    if (documentTypeWatcher.value) {
      return [documentTypeWatcher.value];
    }
    return dictionaryEntries;
  };

  return (
    <Dialog
      title={title ?? t('document:action.dialogGenerateDocumentTitle')}
      confirmText={t('action.generate')}
      cancelText={t('action.cancel')}
      onConfirm={setConfirmLoading =>
        handleSubmit(formData => {
          onSubmit(formData as FormValueType, setConfirmLoading);
        })()
      }
      onCancel={() => {
        closeDialog();
        onCancel?.();
      }}
      dialogSize={isFieldsViewOnly ? 'small' : 'medium'}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <DialogContentContainer>
          <GridLayout itemProps={{ xs: 12 }}>
            <DictionarySelectField
              name="documentType"
              label={t('document:fields.documentGroup')}
              dictionaryName={DomainDictionaryEnum.DOCUMENT_TEMPLATE_GROUP}
              isDisabled={isGroupFieldDisabled || !!groupKey}
              optionsFilter={filterOptions}
              inputMode={(isFieldsViewOnly || isGroupFieldViewOnly) && InputMode.VIEW}
            />

            <AutocompleteLazyFetchSelectField
              isDisabled={!!documentTemplateNameFieldDisabled}
              name={typedNameV2<ApplicationDocumentCreateRequest>('documentTemplateId')}
              queryKey={`DOCUMENT_TEMPLATES_${(documentTypeWatcher?.value || groupKey) ?? ''}_${
                typeKeys ? typeKeys.join('_') : ''
              }`}
              optionLabelParser={getOptionLabel}
              queryConfig={{ onSuccess: selectDefaultValueIfOneOption, refetchOnMount: true }}
              inputMode={isFieldsViewOnly && InputMode.VIEW}
              api={{
                FETCH: (searchText: string, params: { page: number; size: number }) =>
                  API.documentGenerator.findTemplates({
                    ...params,
                    ...(typeKeys && { typeKeys }),
                    nameFragment: searchText,
                    groupKeys: getGroupKeys(),
                    includeWithKeys
                  })
              }}
              label={t('document:fields.documentType')}
              isQueryInitiallyEnabled={Boolean(groupKey)}
              isRequired
            />
          </GridLayout>
        </DialogContentContainer>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default GenerateApplicationDocumentDialog;
