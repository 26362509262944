enum UserButtonNames {
  USER_DELETE_BUTTON = 'USER_DELETE_BUTTON',
  CHANGE_USER_RESET_USER_PASSWORD_BUTTON = 'CHANGE_USER_RESET_USER_PASSWORD_BUTTON',
  BLOCK_USER_BUTTON = 'BLOCK_USER_BUTTON',
  RESET_USER_PASSWORD_BUTTON = 'RESET_USER_PASSWORD_BUTTON',
  USER_DETAILS_BUTTON = 'USER_DETAILS_BUTTON',
  ONLY_UNASSIGNED_TO_UNIT_BUTTON = 'ONLY_UNASSIGNED_TO_UNIT_BUTTON',
  REVOKE_USER_BUTTON = 'REVOKE_USER_BUTTON',
  USER_ADD_BUTTON = 'USER_ADD_BUTTON',
  SUSPEND_USER_BUTTON = 'SUSPEND_USER_BUTTON',
  RESUME_USER_BUTTON = 'RESUME_USER_BUTTON',
  ANONYMIZE_USER_BUTTON = 'ANONYMIZE_USER_BUTTON',
  USER_EDIT_BUTTON = 'USER_EDIT_BUTTON'
}

export default UserButtonNames;
