import { useTranslation } from 'react-i18next';
import { PricingItemDetails } from '@ibtm/domain';

import { Section, TableLayout, useTableRowActions, Value } from '@libs/common/v2';
import { useViewModesV2 } from '@libs/common/v2/form';

import { UIElementNameEnum } from '@libs/permission';

import { DomainUIElementEnum, useDomainConfigContext } from '@libs/domain/config';

import { useApplicationPricingQuery } from '../api';
import { usePricingItemEditDialog, usePricingItemsTable } from '../hooks';

interface IProps {
  applicationId?: string;
  witd?: boolean;
  isSection?: boolean;
  isPaymentAmountVisible?: boolean;
  tableActionKeys?: Array<UIElementNameEnum>;
}

function PricingItemsTable({
  applicationId,
  witd,
  isSection = false,
  isPaymentAmountVisible = true,
  tableActionKeys
}: IProps) {
  const [t] = useTranslation();
  const tableProps = usePricingItemsTable({ applicationId });
  const { isOperatorPortal } = useDomainConfigContext();
  const {
    data: pricingData,
    refetch,
    isLoading: isTableLoading,
    isFetching: isTableFetching
  } = useApplicationPricingQuery(applicationId);
  const { showPricingItemEditDialog } = usePricingItemEditDialog();

  // dla wniosków z kategorii Certyfikaty S w przypadku zaznaczonej flagi WITD na tak należy ustawić w wycenie kwotę wymaganą na 0
  const paymentAmount = witd ? '0' : String(pricingData?.paymentAmount ?? 0);
  const { viewMode } = useViewModesV2();
  const { renderTableActions } = useTableRowActions<PricingItemDetails>(
    !viewMode
      ? [
          {
            id: DomainUIElementEnum.APPLICATION_PRICING_CHANGE_DATA_FROM_PRICING_BUTTON,
            label: 'pricing:action.edit.buttonTitle',
            onClick: ({ row: { original } }) =>
              showPricingItemEditDialog({
                pricingId: pricingData?.id ?? null,
                data: {
                  ...original,
                  version: pricingData?.version
                },
                onSuccess: () => {
                  refetch();
                }
              }),
            icon: 'EditIcon'
          }
        ]
      : [],
    [pricingData?.version, viewMode]
  );

  return (
    <>
      <TableLayout
        {...tableProps}
        pageTitle={t('pricing:tableTitle')}
        {...(isOperatorPortal && { rowActions: renderTableActions(tableActionKeys) })}
        actionsColumnWidth={80}
        isSection={isSection}
        isFilterEnabled={false}
        isRefreshEnabled={false}
        isLoading={isTableLoading}
        isFetching={isTableFetching}
      />
      {isPaymentAmountVisible && (
        <Section>
          <Value label={t('pricing:field.paymentAmount')} value={paymentAmount} />
        </Section>
      )}
    </>
  );
}

export default PricingItemsTable;
