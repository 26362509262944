import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AutocompleteSelectField, TextInputField } from '@libs/common/v2';

import { useDictionaryTranslations } from '@libs/dictionary';
import { DictionaryEntryNameEnum } from '@libs/dictionary/models/dictionaryEntryNameEnum';
import { usePermissionsQuery } from '@libs/group';
import { DocumentTemplateField } from '@libs/report/components';
import { ReportDictionaryEntryNameEnum } from '@libs/report-core';
import { ParameterExtrasTooltipEnum } from '@libs/report-core/model';

function useReportTypeFieldRenderer() {
  const [t] = useTranslation();
  const { translate } = useDictionaryTranslations();
  const { data, isLoading } = usePermissionsQuery();
  const permissionOptions = useMemo(() => {
    return data?.permissions?.map(({ name }) => {
      return { name: translate(DictionaryEntryNameEnum.PERMISSION, name, name), value: name };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, translate]);

  return {
    name: () => <TextInputField name="name" label={t('reports:field.name')} validation={{ required: [] }} />,
    description: () => <TextInputField name="description" label={t('reports:field.description')} />,
    documentTemplate: () => <DocumentTemplateField />,
    cron: () => (
      <TextInputField
        name="cron"
        label={t('reports:field.cron')}
        tooltip={
          <span
            style={{
              whiteSpace: 'pre-line'
            }}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: translate(ReportDictionaryEntryNameEnum.REPORT_DEFINITION_HINTS, ParameterExtrasTooltipEnum.CRON)
                .replace(/\\n/g, '')
                .replace(
                  /\* \* \* \* \* \*/,
                  '<span style="letter-spacing: 1.3px; margin-left: 1px;">* * * * * *</span>'
                )
            }}
          />
        }
      />
    ),
    permissionKeys: () => (
      <AutocompleteSelectField
        tooltip={translate(
          ReportDictionaryEntryNameEnum.REPORT_DEFINITION_HINTS,
          ParameterExtrasTooltipEnum.PERMISSION
        )}
        name="permissionKeys"
        isLoading={isLoading}
        options={permissionOptions}
        isMultiple
        label={t('reports:field.permissionKeys')}
      />
    )
  };
}

export default useReportTypeFieldRenderer;
