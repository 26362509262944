import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import { Button, FormMode, TableLayout } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { FolderDetailsParams, FolderLockListItemClient, useAddLockDialog, useLocksTable } from '@libs/domain/folder';

import TableRowActions from './TableRowActions';

function LockList() {
  const { folderId } = useParams<FolderDetailsParams>();
  const [t] = useTranslation();
  const { openAddLockDialog } = useAddLockDialog(folderId);

  const tableProps = useLocksTable(folderId);

  return (
    <div>
      <TableLayout
        {...tableProps}
        pageTitle={t('folder:details.tab.locks.list.title')}
        actionsColumnWidth={70}
        headerActions={
          <Button
            variant="outlined"
            onClick={() => {
              openAddLockDialog({
                formMode: FormMode.CREATE
              });
            }}
            label={t('action.add')}
            actionKey={UIElementNameEnum.FOLDER_LOCK_CREATE_BUTTON}
          />
        }
        xlsxDownload={{
          fileName: t('folder:details.tab.locks.list.title'),
          pathToXLSXTranslation: 'folder:details.tab.locks.field',
          apiRequest: API.folderLock.getFolderLocksDetailsPage
        }}
        rowActions={({ original }) => {
          return <TableRowActions lock={original as FolderLockListItemClient} />;
        }}
        isFilterEnabled={false}
        isCollapsable
        isSection
      />
    </div>
  );
}

export default LockList;
