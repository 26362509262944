import { useTranslation } from 'react-i18next';
import { DriverDetails } from '@ibtm/domain';

import { FormMode, TableButton } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

import { useDriverContext } from '@libs/domain/drivers/context';
import { useDriverDialog } from '@libs/domain/drivers/hooks';

interface IProps {
  driverId: string;
  data: DriverDetails;
  actionKey: UIElementNameEnum;
}

function EditDriverButton({ driverId, data, actionKey }: IProps) {
  const [t] = useTranslation();

  const { application } = useDriverContext();
  const { open } = useDriverDialog();

  const handleOpenDialog = () => {
    open({
      data: {
        driverId,
        application: {
          id: application.id,
          subjectNip: application.subjectNip,
          applicationTypeKey: application.applicationTypeKey
        }
      },
      initialData: data,
      formMode: FormMode.EDIT
    });
  };

  return <TableButton label={t('action.edit')} onClick={handleOpenDialog} actionKey={actionKey} />;
}

export default EditDriverButton;
