import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { queryCache } from 'react-query';
import { AttachmentVerificationListDetails } from '@ibtm/client-domain';

import { Dialog, GridLayout, Section, TableLayout, Value } from '@libs/common/v2';

import { useAttachmentsTable } from '../../hooks';
import { AttachmentsQueryKeysEnum } from '../../shared/attachments/api';

interface IProps {
  applicationId: string;
  closeDialog: () => void;
}

export function ShortageDialog({ applicationId, closeDialog }: IProps) {
  const [t] = useTranslation();
  const tableProps = useAttachmentsTable(applicationId);

  const [note, setNote] = useState<string>('');

  useEffect(() => {
    const querySubcribe = queryCache.subscribe((_, query) => {
      if (query?.queryKey?.[0] === AttachmentsQueryKeysEnum.ATTACHMENTS_LIST) {
        const newNote = (query?.state?.data as AttachmentVerificationListDetails)?.notes;
        if (newNote) {
          setNote(newNote);
        }
      }
    });

    return querySubcribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog dialogSize="large" title={t('applications:dialog.shortageDialogTitle')} onCancel={closeDialog} isOpen>
      <TableLayout {...tableProps} isHeaderHidden isFilterEnabled={false} isSection />
      <Section>
        <GridLayout itemProps={{ xs: 12 }}>
          <Value label={t('applications:additionalField.notes')} value={note} />
        </GridLayout>
      </Section>
    </Dialog>
  );
}
