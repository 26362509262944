import { useTranslation } from 'react-i18next';

import { Chip } from '@libs/common/v2';

enum StatusColorTypes {
  RED,
  GREEN
}

const dictionaryEntryStatusColorConfig = [
  { status: false, color: StatusColorTypes.RED },
  { status: true, color: StatusColorTypes.GREEN }
];

const dictionaryColorDefnitions = (colorType: StatusColorTypes) => {
  switch (colorType) {
    case StatusColorTypes.RED:
      return 'error';
    case StatusColorTypes.GREEN:
      return 'success';
    default:
      return 'success';
  }
};

export default function DictionaryEntryStatusChip({ status }: { status: boolean }) {
  const [t] = useTranslation();
  const statusDefinitionItem = dictionaryEntryStatusColorConfig.find(definition => definition?.status === status);

  return (
    <Chip
      title={status ? t('field.active') : t('field.inactive')}
      color={dictionaryColorDefnitions(statusDefinitionItem?.color)}
    />
  );
}
