import { ReportDetailsLite } from '@stack/report';

import { CamelCasePath, TableFilterMapper, TableSortMapper, useCreateColumns } from '@libs/common/v2';
import { DateRangeISO } from '@libs/common/v2/utils';

import { GetReportsRequestParams } from '@libs/report/api/queries/useReportsQuery';
import { ReportDictionaryEntryNameEnum } from '@libs/report-core/model';

function useReportsTableColumns(visibleColumns: Array<CamelCasePath<ReportDetailsLite>>) {
  const { createColumns } = useCreateColumns<ReportDetailsLite>({
    pathToTranslate: 'reports:field'
  });

  const mappedSortFields: TableSortMapper<ReportDetailsLite> = {
    generationEnded: 'dataGeneration.generationEnded'
  };

  const mappedFilterFields: TableFilterMapper<ReportDetailsLite, GetReportsRequestParams> = {
    sid: value => ({ sidFragment: value }),
    statusKey: value => ({ statusKeys: value.map(({ value }) => value) }),
    reportTypeName: value => ({ reportTypeNameFragment: value }),
    generationEnded: ({ dateFrom, dateTo }: DateRangeISO) => ({
      generationEndedMin: dateFrom || '',
      generationEndedMax: dateTo || ''
    }),
    created: ({ dateFrom, dateTo }: DateRangeISO) => ({ createdFrom: dateFrom || '', createdTo: dateTo || '' })
  };

  const columns = createColumns(
    [
      {
        type: 'TEXT',
        header: 'id',
        accessor: 'sid',
        width: 300
      },
      {
        header: 'status',
        type: 'DICTIONARY_MULTI_SELECT',
        dictionaryName: ReportDictionaryEntryNameEnum.REPORT_STATUS,
        accessor: 'statusKey',
        isSortable: false
      },
      {
        type: 'TEXT',
        header: 'reportType',
        accessor: 'reportTypeName',
        width: 300
      },
      {
        type: 'DATE_TIME',
        header: 'generated',
        accessor: 'generationEnded',
        width: 250
      },
      {
        type: 'DATE_TIME',
        accessor: 'created'
      }
    ],
    visibleColumns
  );

  return { columns, mappedFilterFields, mappedSortFields };
}

export default useReportsTableColumns;
