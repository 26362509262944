import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required(),
  code: Yup.string().required(),
  description: Yup.string(),
  accountId: Yup.string().required().nullable(),
  hierarchyLevelKey: Yup.string().required(),
  zip: Yup.string().required(),
  streetAndHomeNumber: Yup.string().required(),
  city: Yup.string().required()
});
