import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DictionaryEntryDetailsLanguage } from '@avispon/dictionary/dist/models';
import { Skeleton } from '@mui/material';
import { ReportTypeDetailsSnapshot } from '@stack/report/dist/models';
import _ from 'lodash';

import { AutocompleteSelectField, typedNameV2, useFormV2Context } from '@libs/common/v2';
import { SelectMultipleFieldValidation } from '@libs/common/v2/form/validation';

import { DictionaryEntryNameStatusEnum } from '@libs/dictionary';
import { useDocumentTemplateDetailsQuery } from '@libs/document-template';
import { GenerateReportFormValues } from '@libs/report';
import { useDictionaryTranslationDetailsQuery } from '@libs/report/api';
import { ReportDictionaryEntryNameEnum } from '@libs/report-core';

function TargetTypeSelectField({
  reportType,
  isTargetMimeTypeSelected
}: {
  reportType: ReportTypeDetailsSnapshot;
  isTargetMimeTypeSelected?: boolean;
}) {
  const [t] = useTranslation();
  const [isError, setError] = useState<boolean>(false);
  const id = reportType?.documentTemplateId;
  const { setValue, getValues } = useFormV2Context();
  const target = getValues(typedNameV2<GenerateReportFormValues>('targetTypes'));
  const { data, isLoading } = useDocumentTemplateDetailsQuery(id, {
    onSuccess: () => {
      setError(false);
    },
    onError: () => {
      setError(true);
    },
    enabled: !isError && Boolean(id),
    refetchIntervalInBackground: false,
    refetchInterval: false,
    cacheTime: Infinity,
    retry: false
  });

  const translationDetails = useDictionaryTranslationDetailsQuery({
    dictionary: ReportDictionaryEntryNameEnum.DOCUMENT_TARGET_MIME_TYPE,
    language: DictionaryEntryDetailsLanguage.pl,
    status: DictionaryEntryNameStatusEnum.ALL
  });
  const mimeTypeOptions = useMemo(() => {
    const transitionDetails = (translationDetails.data?.content ?? []).find(item => item.key === data?.targetMimeType);
    const transitions = transitionDetails?.transitions ?? {};

    return data
      ? Object.values(transitions).map(transition => ({
          id: transition.key,
          value: transition.key,
          name: transition.value
        }))
      : translationDetails.data?.content.map(transition => ({
          id: transition.key,
          value: transition.key,
          name: transition.value
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translationDetails.data, data]);

  useEffect(() => {
    if (_.isNil(target) && isTargetMimeTypeSelected && mimeTypeOptions?.length && data?.targetMimeType) {
      setValue('targetTypes', [{ value: data.targetMimeType }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, mimeTypeOptions, data]);

  const validation: SelectMultipleFieldValidation = useMemo(
    () => ({
      required: []
    }),
    []
  );

  if (isLoading || !mimeTypeOptions || !mimeTypeOptions?.length) {
    return <Skeleton variant="rectangular" height={56} className="mb-16" />;
  }

  return (
    <AutocompleteSelectField
      label={t('reports:field.reportFormat')}
      name={typedNameV2<GenerateReportFormValues>('targetTypes')}
      options={mimeTypeOptions}
      validationMultipleSelect={validation}
      isRequired
      isMultiple
    />
  );
}

export default TargetTypeSelectField;
