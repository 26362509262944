import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableCell as MuiTableCell, TableRow as MuiTableRow, TableRowClassKey } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Button, Theme, Typography, useIsSmallScreen, useTableSelectRowsContext } from '@libs/common/v2';
import { useTheme } from '@libs/common/v2/theme';

import { important } from '../../../utils';
import { useTableAdapter, useTableContext } from '../hooks';
import { ISpecialColumnEnum, MultipleSelectColumnPlugin, TableClassNames } from '../model';
import { getStickyRightStyle } from '../utils';

interface IProps {
  rowClasses?: Partial<Record<TableRowClassKey, string>>;
  isTableGrey?: boolean;
  pluginName: MultipleSelectColumnPlugin;
  isHiddenSelectRowHeader?: boolean;
}

function TableSelectActionsRow({ rowClasses, isTableGrey, pluginName, isHiddenSelectRowHeader }: IProps) {
  const [t] = useTranslation();
  const table = useTableAdapter();
  const { contrast } = useTheme();
  const { isSmallScreen } = useIsSmallScreen();
  const {
    headerGroups,
    state: { selectedRowIds },
    data,
    toggleAllRowsSelected,
    getRowId,
    isHiddenSelectRowHeader: isHiddenSelectRowHeaderFromAdapter
  } = table;
  const { selectedRows: selectedRowsContext, clearSelectedRows } = useTableSelectRowsContext();
  const currentRowIds = useMemo(() => data?.map(item => (getRowId ? getRowId(item) : item.id)), [data, getRowId]);

  const selectedRows = Object.keys(selectedRowIds);

  const tableContext = useTableContext();
  const { actionsColumnWidth, selectionColumnWidth, checkDisabled, isError } = tableContext;

  const hasSelectColumn = useMemo(
    () => headerGroups?.[0]?.headers?.some(column => column.id === ISpecialColumnEnum.SELECTION),
    [headerGroups]
  );
  const hasActionColumn = useMemo(
    () => headerGroups?.[0]?.headers?.some(column => column.id === ISpecialColumnEnum.ACTION),
    [headerGroups]
  );

  const numberOfSelectedRowsInTableState = useMemo(
    () => (selectedRowIds ? selectedRows?.filter(item => !!currentRowIds?.includes(item)).length : 0),
    [currentRowIds, selectedRowIds, selectedRows]
  );

  const numberOfSelectedRowsInSelectContext = Object.keys(selectedRowsContext).length;

  const selectedRowsForPlugin: { [key in MultipleSelectColumnPlugin]: number } = {
    [MultipleSelectColumnPlugin.useMultipleSelectColumn]: numberOfSelectedRowsInTableState,
    [MultipleSelectColumnPlugin.useMultipleSelectColumnWithSelectRowContext]: numberOfSelectedRowsInSelectContext
  };

  const numberOfSelected = selectedRowsForPlugin[pluginName];

  const classes = useStyles({
    isVisible: !!numberOfSelected,
    isTableGrey,
    hasActionColumn,
    hasSelectColumn,
    hasData: data?.length ?? 0,
    contrast
  });

  const cellWidthReduction = (400 - selectionColumnWidth) / ((headerGroups?.[0]?.headers?.length ?? 2) - 2);

  const renderTableCell = (column, index) => {
    const actionCellClassName = clsx(classes.cell, { [classes.first]: index === 0 });
    const cellClassName = clsx(classes.cell, { [classes.first]: index === 0 }, classes.emptyCell);

    switch (column.id) {
      case ISpecialColumnEnum.SELECTION:
        return (
          <MuiTableCell className={clsx(classes.cell, classes.selectionCell)} component="div" key={column.id}>
            <div className={classes.selectionTextWrapper}>
              {!isSmallScreen && (
                <>
                  <Typography themeVariant="textSm.medium" component="div" className={classes.numberOfSelected}>
                    {`${t('global:action.checkedRows')}: ${numberOfSelected}`}
                  </Typography>
                  <div className={classes.verticalLine}> </div>
                  <Button
                    isNoMargin
                    label={t('global:action.uncheck')}
                    variant="outlined"
                    className={classes.button}
                    onClick={() => {
                      clearSelectedRows();
                      toggleAllRowsSelected(false);
                    }}
                  />
                </>
              )}
              {column.render('SelectActions')}
            </div>
          </MuiTableCell>
        );
      case ISpecialColumnEnum.ACTION:
        return (
          <MuiTableCell
            key={column.id}
            {...column.getHeaderProps(props => getStickyRightStyle(props, actionsColumnWidth))}
            className={clsx(
              classes.stickyHeaderCell,
              classes.actionCell,
              TableClassNames.STICKY_RIGHT_COLUMN,
              { [classes.isVisible]: !data?.length },
              actionCellClassName
            )}
            component="div"
          >
            {' '}
          </MuiTableCell>
        );
      default:
        return (
          <MuiTableCell
            key={column.id}
            {...column.getHeaderProps()}
            style={{
              width: column.width - cellWidthReduction
            }}
            className={cellClassName}
            component="div"
          >
            {' '}
          </MuiTableCell>
        );
    }
  };

  return !checkDisabled(table) && !isError ? (
    <div className={classes.rowWrapper}>
      <MuiTableRow
        component="div"
        classes={rowClasses}
        className={clsx(classes.root)}
        {...headerGroups?.[0]?.getHeaderGroupProps()}
      >
        {(!(isHiddenSelectRowHeader || isHiddenSelectRowHeaderFromAdapter) || !isSmallScreen) &&
          headerGroups?.[0]?.headers?.map((column, index) => renderTableCell(column, index))}
      </MuiTableRow>
    </div>
  ) : null;
}

const useStyles = makeStyles<
  Theme,
  {
    isVisible: boolean;
    isTableGrey: boolean;
    hasActionColumn: boolean;
    hasSelectColumn: boolean;
    hasData: boolean;
    contrast: boolean;
  }
>(theme => ({
  rowWrapper: {
    width: 'auto',
    display: ({ isVisible }) => (isVisible ? 'flex' : 'none')
  },
  button: {
    padding: '6px 16px',
    height: '35px',
    minWidth: 'auto'
  },
  verticalLine: {
    display: 'inline-block',
    background: 'transparent',
    width: 1.5,
    height: '100%',
    touchAction: 'none',
    userSelect: 'none',
    paddingRight: '8px',
    '&:after': {
      content: '""',
      display: 'block',
      width: 1,
      height: 33,
      borderRightWidth: '1.5px',
      borderRightStyle: 'solid',
      borderRightColor: theme.palette.grey[300]
    }
  },
  numberOfSelected: {
    width: '178px',
    height: 'auto'
  },
  root: {
    zIndex: 100,
    position: 'sticky',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    borderTop: ({ contrast }) => `1px solid ${contrast ? theme.palette.primary[950] : theme.palette.grey[250]}`,
    borderBottom: ({ contrast }) => contrast && `1px solid ${theme.palette.primary[950]}`,
    top: 50,
    height: 'auto',
    transition: 'all 250ms',
    backgroundColor: ({ isTableGrey }) => (isTableGrey ? theme.palette.white : theme.palette?.grey[50]),
    display: important('inline-flex'),
    flex: '1 0 auto',
    padding: 0,
    paddingLeft: ({ hasSelectColumn, hasData }) => (!hasSelectColumn || !hasData) && 32
  },
  cell: {
    backgroundColor: 'inherit',
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    height: 53,
    paddingTop: 10,
    paddingBottom: 10,
    transition: 'padding 125ms ease-out',
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  actionCell: {
    width: '130px',
    height: '100%',
    justifyContent: 'flex-end',
    transition: 'backgroundColor 125ms ease-out',
    // eslint-disable-next-line consistent-return
    backgroundColor: ({ isTableGrey, hasActionColumn }) => {
      if (!hasActionColumn) {
        return isTableGrey ? theme.palette.white : theme.palette.grey[50];
      }
      if (!isTableGrey) {
        return theme.palette.grey[50];
      }
      if (isTableGrey) {
        return theme.palette.white;
      }
    }
  },
  stickyHeaderCell: {
    zIndex: 99
  },
  clearWithoutActionColumn: {
    display: 'flex',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    right: 0,
    padding: 0,
    flex: 'none'
  },
  isVisible: {
    display: important('none')
  },
  selectionCell: {
    boxSizing: 'border-box',
    minWidth: '0px',
    flex: `100% 0 auto`,
    position: 'sticky',
    zIndex: 99,
    top: 0,
    left: 0,
    width: '400px'
  },
  selectionTextWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    height: '100%',
    paddingLeft: theme.spacing(1.25)
  },
  emptyCell: {
    boxSizing: 'border-box',
    minWidth: '0px',
    position: 'relative'
  }
}));

export default React.memo(TableSelectActionsRow);
