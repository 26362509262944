import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface DeleteFormOperationParams {
  suspensionId: string;
  formOperationId: string;
}

const deleteFormOperation = ({ suspensionId, formOperationId }: DeleteFormOperationParams) => {
  return API.suspensions.removeSuspensionForm(suspensionId, formOperationId, {
    ...endpointsConfig.removeSuspensionForm
  });
};

export const useFormOperationsDeleteMutation = () => {
  return useMutation(deleteFormOperation);
};
