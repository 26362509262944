import { useTranslation } from 'react-i18next';

import { DropdownListButton, useTableAdapter } from '@libs/common/v2';

import { useFolderTransferDialog } from '../../../hooks';

const FoldersTransferSelectColumnButton = (selectedRowsIds: string[], handleClose: () => void) => {
  const [t] = useTranslation();
  const { openFolderTransferDialog } = useFolderTransferDialog();
  const { toggleAllRowsSelected } = useTableAdapter();

  return (
    <DropdownListButton
      label={t('action.assign')}
      onClick={() =>
        openFolderTransferDialog(selectedRowsIds, true, () => {
          toggleAllRowsSelected(false);
          handleClose();
        })
      }
    />
  );
};

export default FoldersTransferSelectColumnButton;
