import { useMemo } from 'react';
import { PluginHook, SortingRule } from 'react-table';
import { LicenseCopySearchFilter as LicenseCopySearchFilterClient } from '@ibtm/client-domain';
import { LicenseCopySearchFilter } from '@ibtm/domain';
import { isString } from 'lodash';

import {
  ISingleColumn,
  ITableLayoutColumn,
  SelectOption,
  SelectOptionPartial,
  TableFilterMapper,
  TableSortMapper,
  useCreateColumns,
  useTableLayoutProps
} from '@libs/common/v2';
import { DateRangeISO, getCalendarDate } from '@libs/common/v2/utils';

import { useContextSelector } from '@libs/dictionary';

import { useDomainConfigContext } from '@libs/domain/config';
import { LicenseCopiesColumnsEnum, LicenseCopySnapshotClient } from '@libs/domain/license';
import { useProceedingKreptdLicenseCopiesQuery } from '@libs/domain/road-transport-kreptd/api';

import { useLicenseCopiesQuery } from '../../api/queries';
import LICENSE_COPY_COLUMN from '../../config/license-copies-columns';

export const LicensesFilterConverter: TableFilterMapper<
  LicenseCopySnapshotClient,
  LicenseCopySearchFilterClient | LicenseCopySearchFilter
> = {
  applicationNumber: (applicationNumber: string) => ({ applicationNumberContains: applicationNumber }),
  cancellationReason: (cancellationReasons: SelectOptionPartial[]) => ({
    cancellationReasonIn: cancellationReasons.map(cancellationReason => cancellationReason.value)
  }),
  cancelledBy: (cancellationsBy: SelectOptionPartial[]) => ({
    cancellationByIn: cancellationsBy.map(({ value }) => value)
  }),
  cancellationDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
    cancellationDateGreaterThanOrEqual: getCalendarDate(dateFrom),
    cancellationDateLessThanOrEqual: getCalendarDate(dateTo)
  }),
  subjectName: (subjectName: string) => ({ subjectNameContains: subjectName }),
  subjectAddress: (subjectAddress: string) => ({ subjectAddressContains: subjectAddress } as any),
  licenseNumber: (licenseNumber: string) => ({ licenseNumberOrCopyNumberContains: licenseNumber }),
  formNumber: (formNumber: string) => ({ formNumberContains: formNumber }),
  printDate: ({ dateFrom, dateTo }: DateRangeISO) => ({
    printDateGreaterThanOrEqual: getCalendarDate(dateFrom),
    printDateLessThanOrEqual: getCalendarDate(dateTo)
  }),
  validFrom: ({ dateFrom, dateTo }: DateRangeISO) => ({
    validFromGreaterThanOrEqual: getCalendarDate(dateFrom),
    validFromLessThanOrEqual: getCalendarDate(dateTo)
  }),
  validTo: ({ dateFrom, dateTo }: DateRangeISO) => ({
    validToGreaterThanOrEqual: getCalendarDate(dateFrom),
    validToLessThanOrEqual: getCalendarDate(dateTo)
  }),
  dateOfIssueExternal: ({ dateFrom, dateTo }: DateRangeISO) => ({
    dateOfIssueExternalGreaterThanOrEqual: getCalendarDate(dateFrom),
    dateOfIssueExternalLessThanOrEqual: getCalendarDate(dateTo)
  }),
  belowThreeAndHalfTons: (belowThreeAndHalfTons: SelectOption<boolean>) => ({
    belowThreeAndHalfTons: belowThreeAndHalfTons?.value
  }),
  signerName: (signers: SelectOptionPartial[]) => ({ signerIn: signers.map(signer => signer.value) }),
  status: (statuses: SelectOptionPartial | SelectOptionPartial[]) => ({
    statusKeyIn: Array.isArray(statuses) ? statuses.map(status => status.value) : [statuses.value]
  })
};

export const LicensesSortConverter: TableSortMapper<LicenseCopySnapshotClient> = {
  applicationNumber: 'application.number',
  cancellationReason: 'cancellationReasonKey',
  cancelledBy: 'cancelledBy.name',
  subjectName: 'folder.subject.name',
  subjectAddress: 'application.subject.mainAddress.shortAddress',
  licenseNumber: 'number',
  formNumber: 'form.number',
  signerName: 'signer.name',
  status: 'statusKey'
};

const useLicenseCopyTable = (
  visibleColumns: Array<LicenseCopiesColumnsEnum | { column: LicenseCopiesColumnsEnum; isEditable: boolean }>,
  applicationId?: string,
  folderId?: string,
  proceedingId?: string,
  defaultFilters = [],
  tablePlugins?: Array<PluginHook<LicenseCopySnapshotClient>>,
  isMutableTable = false,
  includePartnershipPermissions?: boolean,
  defaultSort: SortingRule<LicenseCopySnapshotClient>[] = [],
  isTableDataVisible = true
) => {
  const dictionaryData = useContextSelector(({ context }) => context.context.data.dictionary);

  const { createColumns } = useCreateColumns<LicenseCopySnapshotClient>({
    pathToTranslate: 'license:fields',
    isMutableTable
  });

  const columns: ITableLayoutColumn<LicenseCopySnapshotClient, LicenseCopySnapshotClient>[] = useMemo(
    () =>
      createColumns(
        visibleColumns.reduce((acc, curr) => {
          const result = LICENSE_COPY_COLUMN.find(item => {
            if (!isString(curr)) {
              return curr?.column === item.id;
            }
            return item.id === curr;
          });
          const newColumn: ISingleColumn<LicenseCopySnapshotClient> = {
            ...result,
            ...(!isString(curr) && { isEditable: !!curr?.isEditable })
          };
          return result ? acc.concat(newColumn) : acc.concat();
        }, [])
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dictionaryData]
  );

  const { isOperatorPortal } = useDomainConfigContext();

  const tableHookQueryInitialParams: LicenseCopySearchFilterClient | LicenseCopySearchFilter = {
    applicationIdIn: applicationId ? [applicationId] : undefined,
    ...(isOperatorPortal ? { folderIdIn: folderId ? [folderId] : undefined } : { folderIdEq: folderId }),
    ...(isOperatorPortal && proceedingId && { proceedingId }),
    includePartnershipPermissions: includePartnershipPermissions ? true : undefined
  };

  const getQuery = () => (proceedingId ? useProceedingKreptdLicenseCopiesQuery : useLicenseCopiesQuery);

  return useTableLayoutProps({
    tableHookQuery: isTableDataVisible && getQuery(),
    tableHookOptions: {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns,
      filterConverter: LicensesFilterConverter,
      tablePlugins,
      sortByConverter: LicensesSortConverter,
      tableOptions: {
        initialState: {
          filters: defaultFilters,
          sortBy: defaultSort
        }
      }
    },
    tableHookQueryInitialParams: tableHookQueryInitialParams as Record<string, any>
  });
};

export default useLicenseCopyTable;
