import { AccountsApiRecoverPasswordRequest } from '@avispon/user';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '..';

const recoverPassword = async (request: AccountsApiRecoverPasswordRequest) => {
  const { data } = await API.user.accountController.recoverPassword(request, { ...endpointsConfig.recoverPassword });
  return data;
};

export default function useRecoverPasswordMutation() {
  return useMutation(recoverPassword);
}
