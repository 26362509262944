import { FolderRentalCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const changeBorrowFolder = (params: FolderRentalCreateRequest) =>
  API.borrowFolders.createFolderRental(params, {
    ...endpointsConfig.createFolderRental
  });

export const useBorrowFolderMutation = () => useMutation(changeBorrowFolder);
