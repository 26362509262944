import { ReactNode, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { omit } from 'lodash';

import {
  FormActionEnum,
  IconType,
  Page,
  Tab,
  TabsPageSidebar,
  Typography,
  useFormActionModal,
  useIsSmallScreen,
  useTab
} from '@libs/common/v2';

import { TabContext, useFormDirtyContext, useMetaFormContext } from '@libs/meta-form/context';
import { NodeVisibility } from '@libs/meta-form/models';
import { useCheckElementVisibility } from '@libs/meta-form/services';

export type TabsParameters = {
  defaultTabId: string;
  title: string;
  changeTabAction?: FormActionEnum;
  visibility?: NodeVisibility;
  tabs: Array<{
    id: string;
    title: string;
    icon?: IconType;
  }>;
};
// @TODO hot fix dodanie tytułu do listy tab. Trzeba zmienić
function TabNavigation({
  renderNodes,
  components,
  defaultTabId,
  tabs,
  title,
  changeTabAction = FormActionEnum.SAVE_CHANGES,
  children,
  visibility
}: TabsParameters & {
  renderNodes: (children: ReactNode, components: any) => JSX.Element | JSX.Element[];
  components: any;
  children: ReactNode;
}) {
  const { formRef, pageComponentProps } = useMetaFormContext();
  const { checkVisibilitySync } = useCheckElementVisibility(visibility);
  const { showErrorSnackbar } = useSnackbar();
  const [t] = useTranslation();
  const [activeTabId, onActiveTabChange] = useTab(defaultTabId);
  const { isDirty } = useFormDirtyContext();
  const tabChangeAction = useFormActionModal();
  const classes = useStyles();
  const { isSmallScreen } = useIsSmallScreen();

  const tabContextValue = useMemo(
    () => ({
      activeTabId,
      setActiveTabId: onActiveTabChange,
      activeTabTitle: tabs.find(tab => tab.id === activeTabId)?.title
    }),
    [activeTabId, onActiveTabChange, tabs]
  );

  const visibleTabs = useMemo(() => {
    return tabs.map(tab => {
      return checkVisibilitySync(tab.id) && <Tab key={tab.id} label={tab?.title} value={tab.id} icon={tab.icon} />;
    });
  }, [tabs, checkVisibilitySync]);

  useEffect(() => {
    if (!visibleTabs.some(tab => tab?.props?.value === activeTabId)) {
      onActiveTabChange(visibleTabs.filter(tab => tab)[0].props?.value);
    }
  }, [activeTabId, onActiveTabChange, visibleTabs]);

  const visibleTabsIconsOnly = useMemo(() => {
    return tabs.map(tab => {
      return (
        checkVisibilitySync(tab.id) && (
          <Tab key={tab.id} value={tab.id} icon={tab.icon} isIconTab tooltip={tab?.title} />
        )
      );
    });
  }, [tabs, checkVisibilitySync]);

  const isCurrentTabVisible = useMemo(() => {
    return checkVisibilitySync(activeTabId);
  }, [activeTabId, checkVisibilitySync]);

  useEffect(() => {
    if (!isCurrentTabVisible) {
      onActiveTabChange(defaultTabId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentTabVisible]);

  const handleConfirmTabChange = async (tab: string) => {
    try {
      await formRef?.current?.onSubmit();
      onActiveTabChange(tab);
    } catch {
      showErrorSnackbar(t('error.discardChangesOrCorrectFormDataToSwitchTab'));
    }
  };

  const getTabsPageSidebar = (visibleSidebarTabs: ReactNode) => {
    return (
      <TabsPageSidebar
        visibleTabs={visibleSidebarTabs}
        activeTab={activeTabId}
        onChange={tab =>
          isDirty
            ? tabChangeAction({
                formAction: changeTabAction,
                onConfirm: () => handleConfirmTabChange(tab),
                onDiscardChanges: () => {
                  formRef?.current?.onReset();
                },
                onCancel: () => onActiveTabChange(tab)
              })
            : onActiveTabChange(tab)
        }
      />
    );
  };

  if (!isCurrentTabVisible) {
    return null;
  }

  if (pageComponentProps) {
    return (
      <TabContext.Provider value={tabContextValue}>
        <Page
          leftSidebarContent={getTabsPageSidebar(visibleTabs)}
          leftSidebarContentNotExpanded={getTabsPageSidebar(visibleTabsIconsOnly)}
          leftSidebarHeader={title}
          content={
            pageComponentProps?.isHeaderInContent ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%'
                }}
              >
                {pageComponentProps.header}
                {renderNodes(children, components)}
              </div>
            ) : (
              <>{renderNodes(children, components)}</>
            )
          }
          isContentScrollEnabled={!isSmallScreen}
          isHeaderDividerShown
          isRightSidebarFullPage
          isLeftSidebarScrollEnabled
          {...(pageComponentProps?.isHeaderInContent ? omit(pageComponentProps, 'header') : pageComponentProps)}
        />
      </TabContext.Provider>
    );
  }
  return (
    <TabContext.Provider value={tabContextValue}>
      <div className={clsx('sidebar-header flex-1 justify-center items-center', classes.sidebar)}>
        <Typography themeVariant="textLg.medium" align="left" className={classes.title}>
          {title}
        </Typography>
        <TabsPageSidebar
          visibleTabs={visibleTabs}
          activeTab={activeTabId}
          onChange={tab =>
            isDirty
              ? tabChangeAction({
                  formAction: changeTabAction,
                  onConfirm: () => handleConfirmTabChange(tab),
                  onDiscardChanges: () => {
                    formRef?.current?.onReset();
                  },
                  onCancel: () => onActiveTabChange(tab)
                })
              : onActiveTabChange(tab)
          }
        />
      </div>
      {renderNodes(children, components)}
    </TabContext.Provider>
  );
}

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.grey[900],
    padding: '21px 16px 15px 0'
  },
  sidebar: {
    borderRight: `1px solid ${theme.palette.grey[200]}`
  }
}));

export default TabNavigation;
