import { RefetchOptions } from 'react-query/types/core/query';
import { useParams } from 'react-router-dom';
import { NoteUpdateRequest } from '@ibtm/domain';

import { FormMode } from '@libs/common/v2';

import { DomainUIElementEnum } from '@libs/domain/config';
import { FolderDetailsParams } from '@libs/domain/folder/model';
import { NoteSection } from '@libs/domain/notes';

interface IProps {
  formMode?: FormMode;
  refetch: (options?: RefetchOptions) => void;
  customHandleChangeForNoteSection?: (value: string | NoteUpdateRequest) => void;
}

function NotesTab({ formMode, refetch, customHandleChangeForNoteSection }: IProps) {
  const { folderId } = useParams<FolderDetailsParams>();

  return (
    <NoteSection
      folderId={folderId}
      formMode={formMode}
      editButtonActionKey={DomainUIElementEnum.FOLDER_NOTE_EDIT_BUTTON}
      customHandleChangeForNoteSection={customHandleChangeForNoteSection}
      refetch={refetch}
    />
  );
}
export default NotesTab;
