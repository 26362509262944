import { useCallback, useState } from 'react';
import { SubjectAddressDetails } from '@ibtm/domain';
import { debounce, get } from 'lodash';

import {
  AutocompleteSelectField,
  SEARCH_TEXT_DEBOUNCE_TIME,
  SelectOption,
  useFormV2Context,
  useFormV2Watch
} from '@libs/common/v2';

import { useMetaFormContext } from '@libs/meta-form/context';
import { AutocompleteFieldProperties, FieldProps, FieldTypes } from '@libs/meta-form/models';
import { useGetQuery, useMapAdditionalFieldsValidationType } from '@libs/meta-form/services';

type SelectAddressTypeOptionProps = SelectOption & SubjectAddressDetails & { label: string };

export function SelectAddressTypeSelectField({
  fieldId,
  label,
  api,
  multiple,
  inputMode,
  defaultInitialValue,
  yupValidation,
  isFetchedDynamically,
  isFetchingOptionsOnInit = false,
  required,
  validationSingleSelect,
  validationMultipleSelect,
  additionalFieldsValidationType
}: FieldProps<AutocompleteFieldProperties>) {
  const { setValue, getValues } = useFormV2Context();
  const [options, setOptions] = useState([]);
  const [optionsFetchEnabled, setOptionsFetchEnabled] = useState(isFetchingOptionsOnInit);
  const [params, setParams] = useState<Record<string, unknown>>(api?.fetch?.backendParams || {});
  const { apiRegistry } = useMetaFormContext();

  useMapAdditionalFieldsValidationType(fieldId, {
    type: additionalFieldsValidationType ?? FieldTypes.AUTOCOMPLETE,
    multiple
  });

  const prepareText = (data: SelectAddressTypeOptionProps) => {
    return `${data.city}, ${data.street} ${data.propertyNumber}${
      data?.apartmentNumber ? ` / ${data.apartmentNumber}` : ''
    }${data?.label?.length > 0 ? ` - ${data.label}` : ''}`;
  };

  const getOption = useCallback(
    (option: SelectAddressTypeOptionProps) =>
      option?.id
        ? {
            id: option.id,
            value: {
              name: prepareText(option),
              ...option
            },
            name: prepareText(option)
          }
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useGetQuery({
    requestKey: api?.get?.requestKey,
    accessor: api?.get?.accessor,
    apiRegistry,
    setValue: (newValue: SelectAddressTypeOptionProps[] | SelectAddressTypeOptionProps) => {
      const isArray = (arg: SelectOption[] | SelectOption): arg is SelectOption[] => {
        return multiple || Array.isArray(arg);
      };
      if (!newValue && defaultInitialValue) {
        return;
      }

      const prepareValue = isArray(newValue) ? newValue?.map(getOption) : getOption(newValue);

      setValue(fieldId, prepareValue);
    },
    fieldId
  });

  const { isLoading } = useGetQuery({
    requestKey: api.fetch.requestKey,
    accessor: api.fetch.accessor ?? null,
    apiRegistry,
    params,
    setValue: (data: { content?: SelectAddressTypeOptionProps[] } | SelectAddressTypeOptionProps[]) => {
      const setInitialValue = (newValue: Array<SelectOption>) => {
        let initValue: SelectOption[] | SelectOption;
        if (getValues(fieldId) || !defaultInitialValue || !Array.isArray(newValue)) {
          return;
        }
        const getOptionValue = (item: SelectOption) => get(item, defaultInitialValue.accessor, undefined);
        if ('values' in defaultInitialValue) {
          initValue = newValue.filter(item => {
            return defaultInitialValue.values.includes(getOptionValue(item));
          });
        } else if ('value' in defaultInitialValue) {
          initValue = newValue.find(item => {
            return defaultInitialValue.value === getOptionValue(item);
          });
        }

        setValue(fieldId, initValue);
      };
      if ('content' in data && data?.content) {
        const newOptionsFromContent = data?.content.map(getOption);
        setOptions(newOptionsFromContent);
        setInitialValue(newOptionsFromContent);
      } else if (Array.isArray(data)) {
        const newOptions = data?.map(getOption);
        setOptions(newOptions);
        setInitialValue(newOptions);
      } else {
        setOptions([]);
      }
    },
    isQueryEnabled: optionsFetchEnabled
  });

  const changeHandler = (data: { target?: { value?: string } } & unknown) => {
    if (api.fetch?.backendFilterParamName) {
      setParams(prevState => ({
        ...prevState,
        [api.fetch.backendFilterParamName]: data?.target?.value
      }));
    }
  };

  const onInputChange = debounce(data => changeHandler(data), SEARCH_TEXT_DEBOUNCE_TIME);
  const dependentFieldValue = useFormV2Watch({
    name: yupValidation?.['dependentRequired']?.['fieldId'] as string,
    disabled: !yupValidation?.['dependentRequired']?.['fieldId']
  }) as string | SelectOption<string>;

  const isRequired = yupValidation?.['dependentRequired']
    ? (yupValidation?.['dependentRequired']?.['whenDependentValueEqual'].includes(
        typeof dependentFieldValue === 'object' ? dependentFieldValue?.value : dependentFieldValue
      ) as boolean)
    : yupValidation?.required;

  return (
    <AutocompleteSelectField
      name={fieldId}
      label={label}
      isRequired={isRequired || required}
      isMultiple={multiple}
      options={options}
      inputMode={inputMode}
      onInputChange={onInputChange}
      isLoading={isLoading}
      onClick={() => setOptionsFetchEnabled(true)}
      isFetchedDynamically={isFetchedDynamically}
      validationSingleSelect={validationSingleSelect}
      validationMultipleSelect={validationMultipleSelect}
    />
  );
}
