import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { ResourceObjectAssociationPoolSnapshot } from '@ibtm/client-domain';
import {
  DepotDetails,
  DepotManagerDetails,
  DepotManagersList,
  DepotTransferSnapshot,
  ResourceObjectAvailablePoolSnapshot,
  ResourceObjectContentPoolSnapshot,
  ResourceObjectDisposalSnapshot,
  ResourceObjectOrderSnapshot,
  ResourceObjectPoolSnapshot,
  ResourceTypeSnapshot
} from '@ibtm/domain';
import { ResourceObjectExtendedDetails } from '@ibtm/domain/dist/models/resource-object-extended-details';
import { toNumber } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { i18n } from '@libs/common';
import { convertCalendarDate } from '@libs/common/v2/utils';

import { DomainDictionaryEntry } from '@libs/domain/config';

import {
  IAssociationOrderDetailsClient,
  IAssociationOrdersClient,
  IDepotDetailsClient,
  IDepotManagerListClient,
  IDepotManagersListClient,
  IDepotsListClient,
  IDepotTransferClient,
  IDisposalClient,
  IResourceObjectAssociationPoolClient,
  IResourceObjectAssociationPoolsAvailabilityClient,
  IResourceObjectsClient,
  IResourceTypesClient,
  PoolItem,
  ResourceObjectContentPoolClient,
  ResourceObjectGapAndCorrectPoolClient,
  ResourceObjectPoolClient
} from '../model/resource.model';

export const getYearOptions = () => {
  const startYear = 2021;
  const currentYear = new Date().getFullYear();
  const options = new Array(currentYear - startYear + 1).fill(undefined).map((_item, index) => ({
    id: uuidv4(),
    value: currentYear - index,
    name: i18n.t('resource:other.year', { year: currentYear - index })
  }));

  if (new Date().getMonth() === 11) {
    return [
      {
        id: uuidv4(),
        value: currentYear + 1,
        name: i18n.t('resource:other.year', { year: currentYear + 1 })
      },
      ...options
    ];
  }

  return options;
};

export const getResourcesInitialYear = (): number => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return currentDate.getMonth() < 11 ? currentYear : currentYear + 1;
};

export const parseResourceObjectOrder = (order: ResourceObjectOrderSnapshot): IAssociationOrderDetailsClient => ({
  id: order.id,
  ranges: order.ranges.map(range => ({
    id: uuidv4(),
    name: range.resourceType.nameKey,
    country: range.resourceType.countryKey,
    numberFrom: range.numberFrom,
    numberTo: range.numberTo,
    amount: range.amount
  }))
});

export const parseUsersDepotsList = (res: DepotManagersList): IDepotManagerListClient => ({
  depotIds: res.depotIds
});

export const depotTransfersListParser = (depotTransfers: DepotTransferSnapshot[]): IDepotTransferClient[] =>
  depotTransfers.map(depotTransfer => ({
    id: depotTransfer.id,
    sourceDepot: {
      id: depotTransfer.sourceDepot.id,
      name: depotTransfer.sourceDepot.name,
      type: depotTransfer.sourceDepot.typeKey
    },
    targetDepot: {
      id: depotTransfer.targetDepot.id,
      name: depotTransfer.targetDepot.name,
      type: depotTransfer.targetDepot.typeKey
    },
    formName: depotTransfer.resourceType?.nameKey,
    numberFrom: depotTransfer.numberFrom,
    numberTo: depotTransfer.numberTo,
    amount: depotTransfer.amount
  }));

export const resourceObjectPoolsParser = (data: ResourceObjectPoolSnapshot[]): ResourceObjectPoolClient[] =>
  data.map(item => ({
    id: uuidv4(),
    group: item.groupKey,
    country: item.countryCodeKey,
    name: item.nameKey,
    numberFrom: item.numberFrom,
    numberTo: item.numberTo,
    amount: item.amount,
    stateKey: item.stateKey,
    resourceType: {
      id: item.resourceType?.id
    },
    admissionDate: convertCalendarDate(item?.admissionDate)
  }));

export const resourceObjectGapAndCorrectPoolsParser = (
  data: ResourceObjectPoolSnapshot[]
): ResourceObjectGapAndCorrectPoolClient[] =>
  data.map(item => ({
    id: uuidv4(),
    group: item.groupKey,
    country: item.countryCodeKey,
    name: item.nameKey,
    numberFrom: item.numberFrom,
    numberTo: item.numberTo,
    amount: item.amount,
    stateKey: item.stateKey,
    resourceType: {
      id: item.resourceType?.id
    },
    admissionDate: convertCalendarDate(item?.admissionDate)
  }));

export const resourceObjectAssociationPoolsParser = (
  data: ResourceObjectAssociationPoolSnapshot[]
): IResourceObjectAssociationPoolClient[] =>
  data.map(item => ({
    id: uuidv4(),
    country: item.countryCodeKey,
    name: item.nameKey,
    state: item.stateKey,
    numberFrom: item.numberFrom,
    numberTo: item.numberTo,
    amount: item.amount,
    limit: item.remainingLimit,
    resourceType: {
      id: item.resourceType?.id
    }
  }));

export const resourceObjectContentPoolsParser = (
  data: ResourceObjectContentPoolSnapshot[]
): ResourceObjectContentPoolClient[] =>
  data.map(item => ({
    id: uuidv4(),
    year: item.year,
    group: item.groupKey,
    country: item.countryCodeKey,
    name: item.nameKey,
    numberFrom: item.numberFrom,
    numberTo: item.numberTo,
    amount: item.amount,
    stateKey: item.stateKey,
    resourceType: {
      id: item.resourceType?.id
    },
    admissionDate: convertCalendarDate(item?.admissionDate)
  }));

export const resourceObjectPoolsAvailabilityParser = (
  data: ResourceObjectAvailablePoolSnapshot
): IResourceObjectAssociationPoolsAvailabilityClient => ({
  numberFrom: data.numberFrom,
  numberTo: data.numberTo,
  amount: data.amount,
  single: data.single
});

export const getInitialFilters = (params: { year: string; number: string }) => {
  let initialFilters = {};

  if (params?.year) {
    const year = toNumber(params.year);
    initialFilters = {
      yearLessThanOrEqual: Number.isNaN(year) ? new Date().getFullYear() : year,
      yearGreaterThanOrEqual: Number.isNaN(year) ? new Date().getFullYear() : year
    };
  }

  if (params?.number) {
    initialFilters = {
      ...initialFilters,
      numberContains: params?.number
    };
  }

  return initialFilters;
};

export const parseDepotsList = (depots: DepotDetails[]): IDepotsListClient[] => {
  return depots.map(parseDepotItem);
};

const parseDepotItem = (item: DepotDetails): IDepotsListClient => {
  return {
    id: item.id,
    depotNumber: item.name,
    number: item.number,
    amount: item.amount,
    type: item.typeKey,
    name: item.name
  };
};

export const parseDepotManagersList = (depotManagers: DepotManagerDetails[]): IDepotManagersListClient[] =>
  depotManagers.map(depotManager => ({
    id: depotManager.id,
    depot: {
      id: depotManager.depot.id,
      name: depotManager.depot.name,
      type: depotManager.depot.typeKey,
      user: {
        id: depotManager.user?.id,
        name: depotManager.user?.name
      }
    }
  }));

export const parseResourceTypesList = (resourceTypes: ResourceTypeSnapshot[]): IResourceTypesClient[] =>
  resourceTypes.map(resourceType => ({
    id: resourceType.id,
    name: resourceType.nameKey,
    type: resourceType.typeKey,
    year: resourceType.year,
    transbitName: resourceType.transbitName,
    fullName: resourceType.fullName,
    countryKey: resourceType.countryKey,
    ekmtCategoryKey: resourceType.ekmtCategoryKey,
    groupKey: resourceType.groupKey,
    version: resourceType.version,
    created: resourceType.created,
    author: resourceType.author,
    modified: resourceType.modified,
    modifier: resourceType.modifier
  }));

export const parseAvailableResourceTypesList = (resourceTypes: ResourceTypeSnapshot[]): IResourceTypesClient[] =>
  resourceTypes.map(resourceType => ({
    id: resourceType.id,
    name: resourceType.nameKey,
    type: resourceType.typeKey,
    year: resourceType.year,
    countryKey: resourceType.countryKey,
    ekmtCategoryKey: resourceType.ekmtCategoryKey,
    groupKey: resourceType.groupKey,
    version: resourceType.version,
    created: resourceType.created,
    modified: resourceType.modified,
    modifier: resourceType.modifier
  }));

export const parseResourceObjectsList = (resourceObjects: ResourceObjectExtendedDetails[]): IResourceObjectsClient[] =>
  resourceObjects.map(resourceObject => ({
    id: resourceObject.id,
    group: resourceObject.groupKey,
    name: resourceObject.type?.nameKey,
    notes: resourceObject.notes,
    returnDate: convertCalendarDate(resourceObject.returnDate),
    released: resourceObject.releasedTo?.subject?.name,
    releasedTo: resourceObject.releasedTo,
    releasedFrom: resourceObject.releasedFrom,
    state: resourceObject.stateKey,
    year: resourceObject?.year,
    number: resourceObject?.number.toString(),
    depot: {
      id: resourceObject.depot?.id,
      typeKey: resourceObject.depot?.typeKey,
      name: resourceObject.depot?.name
    },
    type: {
      id: resourceObject.type?.id,
      type: resourceObject.type?.typeKey
    },
    foreignPermission: resourceObject?.foreignPermission,
    releaseDocumentId: resourceObject?.releaseDocumentId
  }));

export const parseDisposalsList = (disposals: ResourceObjectDisposalSnapshot[]): IDisposalClient[] =>
  disposals.map(disposal => ({
    id: disposal.id,
    year: disposal.year,
    disposalDate: convertCalendarDate(disposal.disposalDate),
    country: disposal.countryKey,
    name: disposal.nameKey,
    disposalReason: disposal.disposalReasonKey,
    number: disposal.number
  }));

export const parseDepotDetails = (depot: DepotDetails): IDepotDetailsClient => ({
  id: depot.id,
  type: depot.typeKey,
  code: depot.typeKey.split('.type.')[1].toUpperCase(),
  version: depot.version,
  name: depot.name
});

export const parseAssociationOrdersList = (
  associationOrders: ResourceObjectOrderSnapshot[]
): IAssociationOrdersClient[] =>
  associationOrders.map(associationOrder => ({
    id: associationOrder.id,
    created: associationOrder.created,
    association: associationOrder.folder?.folderNumber,
    country: associationOrder.countryCodeKey,
    name: associationOrder.resourceType.nameKey,
    ranges: (associationOrder?.ranges || []).map(range => ({
      id: uuidv4(),
      country: range.resourceType?.countryKey,
      name: range.resourceType?.nameKey,
      numberFrom: range.numberFrom,
      numberTo: range.numberTo,
      amount: range.amount
    })),
    amount: associationOrder.ranges.reduce((prev, current) => prev + current.amount, 0),
    direction: associationOrder.directionKey,
    status: associationOrder.statusKey
  }));

export const depotTransferDependences = {
  [DomainDictionaryEntry.DEPOT_TYPE.MGB]: [DomainDictionaryEntry.DEPOT_TYPE.MG],
  [DomainDictionaryEntry.DEPOT_TYPE.MG]: [
    DomainDictionaryEntry.DEPOT_TYPE.MGB,
    DomainDictionaryEntry.DEPOT_TYPE.MP,
    DomainDictionaryEntry.DEPOT_TYPE.MPD
  ],
  [DomainDictionaryEntry.DEPOT_TYPE.MPBIK]: [DomainDictionaryEntry.DEPOT_TYPE.MGB, DomainDictionaryEntry.DEPOT_TYPE.MP],
  [DomainDictionaryEntry.DEPOT_TYPE.MP]: [
    DomainDictionaryEntry.DEPOT_TYPE.MPBIK,
    DomainDictionaryEntry.DEPOT_TYPE.MGB,
    DomainDictionaryEntry.DEPOT_TYPE.MG
  ],
  [DomainDictionaryEntry.DEPOT_TYPE.MPD]: [DomainDictionaryEntry.DEPOT_TYPE.MG, DomainDictionaryEntry.DEPOT_TYPE.MGB]
};

export const handlePoolsChange = {
  numberFrom: (value: number, pools: PoolItem & PoolItem[], index: number, setValue: UseFormSetValue<FieldValues>) => {
    const currentValue = value < 0 ? 0 : value;
    const numberToValue = (index !== null ? pools[index].numberTo : pools.numberTo) ?? 0;
    const diff = Number(numberToValue) - Number(currentValue) + 1;

    setValue(index !== null ? `pools.${index}.amount` : 'amount', diff < 0 ? null : diff);
  },
  numberTo: (value: number, pools: PoolItem & PoolItem[], index: number, setValue: UseFormSetValue<FieldValues>) => {
    const currentValue = value < 0 ? 0 : value;
    const numberFromValue = (index !== null ? pools[index].numberFrom : pools.numberFrom) ?? 0;
    const diff = Number(currentValue) - Number(numberFromValue) + 1;
    setValue(index !== null ? `pools.${index}.amount` : 'amount', diff < 0 ? null : diff);
  },
  amount: (value: number, pools: PoolItem & PoolItem[], index: number, setValue: UseFormSetValue<FieldValues>) => {
    const currentValue = value < 1 ? 1 : value;
    const numberFromValue = (index !== null ? pools[index].numberFrom : pools.numberFrom) ?? 0;
    const newNumberTo = Number(numberFromValue) + Number(currentValue) - 1;

    setValue(index !== null ? `pools.${index}.numberTo` : 'numberTo', newNumberTo);
  }
};
