import { useQuery } from 'react-query';
import clsx from 'clsx';
import { isNull } from 'lodash';

import { WarningInformation } from '@libs/common/v2/components/warning';

import { useMetaFormContext } from '@libs/meta-form/context';
import { ApiRegistryCheck, WarningApiProp } from '@libs/meta-form/models';

type Props = {
  content: string;
  api?: WarningApiProp;
  extraTopMargin?: boolean;
};

export const getMetaFormQueryKey = requestKey => `METAFORM_${requestKey}`;

function Warning({ content, api, extraTopMargin }: Props) {
  const { apiRegistry } = useMetaFormContext();
  const apiEndpoint = apiRegistry[api?.check?.requestKey] as ApiRegistryCheck | null;
  const getQuery = useQuery([getMetaFormQueryKey(api?.check?.requestKey), apiEndpoint?.params], apiEndpoint?.request, {
    enabled: api
  });
  const isVisible = !isNull(getQuery?.data) ? getQuery?.data : false;

  if (api && !isVisible) {
    return null;
  }

  return (
    <div className={clsx('my-2', extraTopMargin && 'mt-24')}>
      <WarningInformation content={content} />
    </div>
  );
}

export default Warning;
