import { ChangeResourceObjectOrderStatusRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function changeOrderStatus(requestData: ChangeResourceObjectOrderStatusRequest) {
  return API.resourceObjectsOrders.changeResourceObjectOrderStatus(requestData, {
    ...endpointsConfig.changeResourceObjectOrderStatus
  });
}

function useAssociationOrderStatusChangeMutation() {
  return useMutation(changeOrderStatus);
}

export default useAssociationOrderStatusChangeMutation;
