import { usePaginatedQuery } from 'react-query';
import { FolderPermissionSnapshot } from '@ibtm/domain';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';
import { FolderQueryKeysEnum } from '..';

const getSubjectPermissions = async (_, subjectId: string) => {
  const { data } = await API.subject.getSubjectFolderPermissions(subjectId, {
    ...endpointsConfig.getSubjectPermissions
  });
  return data;
};

export default function useSubjectPermissionsQuery(subjectId: string) {
  return usePaginatedQuery<Array<FolderPermissionSnapshot>>(
    [FolderQueryKeysEnum.SUBJECT_PERMISSION_LIST, subjectId],
    getSubjectPermissions
  );
}
