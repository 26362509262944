import { TransitStopCreateRequest as TransitStopCreateRequestClient } from '@ibtm/client-domain';
import { TransitStopCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

export function createTransitStop({
  transitScheduleId,
  formData
}: {
  transitScheduleId: string;
  formData: TransitStopCreateRequest;
}) {
  return API.transitSchedule.createTransitScheduleStop(transitScheduleId, formData, {
    ...endpointsConfig.createTransitScheduleStop
  });
}

export function createTransitStopClient({
  transitScheduleId,
  formData
}: {
  transitScheduleId: string;
  formData: TransitStopCreateRequestClient;
}) {
  return API.client.transitSchedule.createTransitScheduleStop(transitScheduleId, '', '', formData, {
    ...endpointsConfig.createTransitScheduleStop
  });
}

function useCreateTransitScheduleStopMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(createTransitStopClient, createTransitStop));
}

export default useCreateTransitScheduleStopMutation;
