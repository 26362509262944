import { DocumentsApiUpdateDocumentRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

interface UpdateSuspensionDocument extends DocumentsApiUpdateDocumentRequest {
  parentId: string;
}

function editSuspensionDocument({ parentId, documentId, documentUpdateRequest }: UpdateSuspensionDocument) {
  return API.suspensions.updateSuspensionDocument(parentId, documentId, documentUpdateRequest, {
    ...endpointsConfig.updateSuspensionDocument
  });
}

function useEditSuspensionDocumentMutation() {
  return useMutation(editSuspensionDocument);
}

export default useEditSuspensionDocumentMutation;
