import { useTranslation } from 'react-i18next';
import { UIElementNameEnum } from '@libs/config/UIElementEnum';

import { DropdownListButton } from '@libs/common/v2';

import { useProcessCancellationFormDialog, useProcessFormConfig } from '../hooks';

interface IProps {
  applicationId: string;
  applicationVersion: number;
  onConfirmTaskSuccess?: () => void;
  onClick?: () => void;
}

function ProcessCancellationButton({ applicationId, applicationVersion, onClick, onConfirmTaskSuccess }: IProps) {
  const [t] = useTranslation();

  const { processFormConfig } = useProcessFormConfig();
  const { showProcessCancellationFormDialog } = useProcessCancellationFormDialog();

  const onCancelProcessClick = () => {
    const formConfig = processFormConfig.find('cancelProcess');

    showProcessCancellationFormDialog({
      applicationId,
      applicationVersion,
      onFinish: onConfirmTaskSuccess,
      formConfig
    });
    onClick?.();
  };

  return (
    <DropdownListButton
      label={t('applications:action.cancelApplicationProcess')}
      onClick={onCancelProcessClick}
      actionKey={UIElementNameEnum.CANCEL_APPLICATION}
    />
  );
}

export default ProcessCancellationButton;
