import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationHistoryDetails } from '@ibtm/domain';
import { isEmpty, isNil, last } from 'lodash';

import { TableButtonDownload, TableLayout } from '@libs/common/v2';

import { useDownloadQuery, useFileDownload } from '@libs/file';
import { UIElementNameEnum } from '@libs/permission';

import { API } from '@libs/domain/api';

import { DomainUIElementEnum } from '../../../config';
import { useApplicationHistoryTable } from '../../hooks';

interface IProps {
  id: string;
  actionKey: UIElementNameEnum;
}

function ApplicationHistoryTable({ id, actionKey }: IProps) {
  const [t] = useTranslation();
  const tableProps = useApplicationHistoryTable(id);
  const [documentId, setDocumentId] = useState<string>();
  const { download } = useFileDownload();
  const downloadQuery = useDownloadQuery({ fileId: documentId }, { enabled: Boolean(documentId) });

  const rowActions = useCallback(
    (row: ApplicationHistoryDetails) => {
      const hasDocumentsList = !isEmpty(row?.documents) && !isNil(row?.documents);
      const latestDocument = last(row?.documents);

      return (
        hasDocumentsList && (
          <TableButtonDownload
            onClick={() => setDocumentId(latestDocument?.fileId)}
            actionKey={DomainUIElementEnum.APPLICATION_HISTORY_DOCUMENT_LINK_DOWNLOAD}
          />
        )
      );
    },
    [setDocumentId]
  );

  useEffect(() => {
    if (downloadQuery.data) {
      download(
        () =>
          new Promise(res => {
            res(downloadQuery.data);
          })
      );
      setDocumentId(null);
    }
  }, [download, downloadQuery]);

  return (
    <TableLayout<ApplicationHistoryDetails, ApplicationHistoryDetails>
      {...tableProps}
      pageTitle={t('history:table.title')}
      xlsxDownload={{
        fileName: t('history:table.title'),
        pathToXLSXTranslation: 'history:field',
        apiRequest: params => API.application.getApplicationHistoryDetailsPage(id, params)
      }}
      rowActions={({ original }) => rowActions(original)}
      actionKey={actionKey}
      isCollapsable
      isSection
    />
  );
}

export default ApplicationHistoryTable;
