import { AlertEditRequest } from '@stack/report';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '@libs/alert/api';

const editAlert = async ({ id, formData }: { id: string; formData: AlertEditRequest }) => {
  const { data } = await API.alert.editAlert(id, formData, {
    ...endpointsConfig.edit
  });

  return data;
};

export default function useEditAlertMutation() {
  return useMutation(editAlert);
}
