import { PaginatedQueryConfig, usePaginatedQuery } from 'react-query';
import { DefaultApiGetTranslationDetailsPageRequest, DictionaryEntryDetailsPage } from '@avispon/dictionary';
import { AxiosRequestConfig } from 'axios';

import { API, endpointsConfig } from '@libs/dictionary/api';

import { DictionaryQueryKeysEnum } from './QueryKeysEnum';

const getDictionaryEntries = async (_, params: DefaultApiGetTranslationDetailsPageRequest) => {
  const { data } = await API.dictionary.getTranslationDetailsPage(params, {
    ...endpointsConfig.getTranslationDetailsPage
  } as AxiosRequestConfig);
  return data;
};

export default function useDictionaryEntriesQuery(
  params: DefaultApiGetTranslationDetailsPageRequest,
  queryConfig: PaginatedQueryConfig<DictionaryEntryDetailsPage>
) {
  return usePaginatedQuery(
    [DictionaryQueryKeysEnum.DICTIONARY_ENTRIES_LIST, params],
    getDictionaryEntries,
    queryConfig
  );
}
