enum OrganizationStructureUIElementEnum {
  ORGANIZATION_STRUCTURE_CHANGE_UNIT_BUTTON = 'ORGANIZATION_STRUCTURE_CHANGE_UNIT_BUTTON',
  ORGANIZATION_STRUCTURE_LIST_TAB = 'ORGANIZATION_STRUCTURE_LIST_TAB',
  ORGANIZATION_STRUCTURE_TREE_VIEW = 'ORGANIZATION_STRUCTURE_TREE_VIEW',
  ORGANIZATION_STRUCTURE_LIST_VIEW = 'ORGANIZATION_STRUCTURE_LIST_VIEW',
  ORGANIZATION_STRUCTURE_ADD_BUTTON = 'ORGANIZATION_STRUCTURE_ADD_BUTTON',
  ORGANIZATION_STRUCTURE_EDIT_BUTTON = 'ORGANIZATION_STRUCTURE_EDIT_BUTTON',
  ORGANIZATION_STRUCTURE_DETAILS_BUTTON = 'ORGANIZATION_STRUCTURE_DETAILS_BUTTON',
  ORGANIZATION_STRUCTURE_USERS_VIEW = 'ORGANIZATION_STRUCTURE_USERS_VIEW',
  ORGANIZATION_STRUCTURE_GROUPS_VIEW = 'ORGANIZATION_STRUCTURE_GROUPS_VIEW',
  ORGANIZATION_STRUCTURE_ADD_MEMBER_BUTTON = 'ORGANIZATION_STRUCTURE_ADD_MEMBER_BUTTON',
  ORGANIZATION_STRUCTURE_DELETE_MEMBER_BUTTON = 'ORGANIZATION_STRUCTURE_DELETE_MEMBER_BUTTON',
  ORGANIZATION_STRUCTURE_DELETE_BUTTON = 'ORGANIZATION_STRUCTURE_DELETE_BUTTON',
  ORGANIZATION_STRUCTURE_EDIT_USER_BUTTON = 'ORGANIZATION_STRUCTURE_EDIT_USER_BUTTON'
}

export default OrganizationStructureUIElementEnum;
