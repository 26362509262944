import { useEffect } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useQuery } from 'react-query';
import _ from 'lodash';

import { useFormV2Context } from '@libs/common/v2';

import { useMetaFormContext } from '@libs/meta-form/context';
import { ApiRegistry, ApiRegistryGet } from '@libs/meta-form/models';

interface IProps<T> {
  requestKey: string;
  accessor?: string;
  apiRegistry?: ApiRegistry;
  setValue: (data: T) => void;
  params?: Record<any, any>;
  fieldId?: string;
  isQueryEnabled?: boolean;
}

export const getMetaFormQueryKey = requestKey => `METAFORM_${requestKey}`;

const useGetQuery = <T>({
  requestKey,
  accessor,
  params = {},
  apiRegistry,
  setValue,
  fieldId,
  isQueryEnabled = true
}: IProps<T>) => {
  const { apiRegistry: apiRegistryContext } = useMetaFormContext();
  const apiEndpoint = (
    apiRegistry && requestKey ? apiRegistry[requestKey] || apiRegistryContext[requestKey] : null
  ) as ApiRegistryGet | null;
  const { unregister } = useFormV2Context();
  const getQuery = useQuery<T>(
    [getMetaFormQueryKey(requestKey), Object.keys(params).length > 0 ? params : apiEndpoint?.params],
    apiEndpoint?.request,
    {
      enabled: Boolean(apiEndpoint) && isQueryEnabled
    }
  );

  useEffect(() => {
    if (getQuery.data) {
      const value = (accessor ? _.get(getQuery.data, accessor) : getQuery.data) as T;
      setValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQuery.data, accessor]);

  // cleanup function aby usunąć field z context'u formularza, by nie przekazywać jego wartości jeżeli odmontujemy field warunkowo
  useEffect(() => {
    return () => {
      if (unregister && fieldId) {
        unregister(fieldId as FieldPath<FieldValues>);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading: getQuery.isLoading, isFetching: getQuery.isFetching, data: getQuery.data };
};

export default useGetQuery;
