import { ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { LoaderLinear } from '@libs/common/v2';
import { Theme } from '@libs/common/v2/theme';

interface IProps {
  title?: ReactNode;
  totalCountInTitle?: number;
  icon?: ReactNode;
  headerContent?: ReactNode;
  titleHeaderContent?: ReactNode;
  collapsableContent?: ReactNode;
  className?: string;
  isRequired?: boolean;
  isLinearLoading?: boolean;
  isHeaderVisible?: boolean;
  isModalSectionTitle?: boolean;
  areTitleAndActionsInside?: boolean;
  headerActions?: ReactNode;
}

function SectionTitle({
  title,
  totalCountInTitle,
  icon,
  headerContent,
  titleHeaderContent,
  collapsableContent,
  className,
  isRequired,
  isLinearLoading,
  isHeaderVisible = true,
  isModalSectionTitle,
  areTitleAndActionsInside,
  headerActions
}: IProps) {
  const classes = useStyles({
    isModalSectionTitle: isModalSectionTitle && title !== undefined,
    areTitleAndActionsInside
  });

  if (!isHeaderVisible) return null;

  return (
    <div className={classes.headerContainer}>
      <div className={clsx(classes.header, className)}>
        {!!title || !!icon ? (
          <div className={clsx('flex items-center w-full')}>
            {icon && <div className={classes.icon}>{icon}</div>}
            {typeof title === 'string' ? (
              <h2 className={classes.title}>
                {title}
                {totalCountInTitle != null ? <span className="text-blue-600"> ({totalCountInTitle})</span> : null}
                {isRequired && <span className={classes.requiredIcon}>&nbsp;&#42;</span>}
              </h2>
            ) : (
              title
            )}
            {titleHeaderContent}
          </div>
        ) : (
          titleHeaderContent && <div className={clsx('flex items-center w-full')}>{titleHeaderContent}</div>
        )}
        <div className={clsx('flex flex-row items-center', classes.rightContent)}>{headerContent}</div>
        {headerActions}
        {collapsableContent}
      </div>
      {isLinearLoading ? (
        <div className={clsx('absolute bottom-1 left-0 w-full')}>
          <LoaderLinear />
        </div>
      ) : null}
    </div>
  );
}

export const useStyles = makeStyles<Theme, { isModalSectionTitle?: boolean; areTitleAndActionsInside?: boolean }>(
  theme => ({
    icon: {
      fontSize: theme.typography.getFontSize(18),
      color: theme.palette.grey[500],
      marginRight: 12
    },
    title: {
      ...theme.typography.textLg.medium,
      color: theme.palette.grey[900],
      marginTop: ({ areTitleAndActionsInside }) => (areTitleAndActionsInside ? 0 : '0.83em'),
      marginBottom: ({ areTitleAndActionsInside }) => (areTitleAndActionsInside ? 0 : '0.83em')
    },
    headerContainer: {
      position: 'relative',
      borderBottom: ({ isModalSectionTitle }) =>
        isModalSectionTitle ? ` 1px solid ${theme.palette.grey[300]} ` : 'none'
    },
    header: {
      padding: ({ areTitleAndActionsInside }) => (areTitleAndActionsInside ? 0 : '8px 0px'),
      paddingRight: 10,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    requiredIcon: {
      color: theme.palette.error[700],
      marginRight: 8
    },
    rightContent: {
      gap: '12px'
    }
  })
);

export default SectionTitle;
