import { ForeignPermissionApplicationRecordWithLimitDetails } from '@ibtm/domain';

import { useGetForeignPermissionApplicationRecords } from '@libs/domain/application';
import { DomainDictionaryEntry } from '@libs/domain/config';

import { useVisiblePermissionTables } from '../../application/hooks';
import { PermissionTableEnum } from '../../application/model';
import { usePrintsDetailsDataContext } from '../context';

type ConfigTablesVisibility = {
  [key in PermissionTableEnum]?: {
    isVisible: boolean;
  };
};

type ConfigState = ConfigTablesVisibility & {
  visiblePrintedPermissions?: {
    isDriverCertificate: boolean;
    isLicense: boolean;
    isPermission: boolean;
  };
};

function usePrintTablesConfiguration(): ConfigState {
  const { checkIsVisible } = useVisiblePermissionTables();

  const { printDetailsData } = usePrintsDetailsDataContext();

  const applicationData = printDetailsData.applicationSnapshot;
  const isVisible = checkIsVisible(applicationData);
  const folderTypeKey = applicationData?.folder?.typeKey;

  const { data } = useGetForeignPermissionApplicationRecords(
    { applicationId: applicationData?.id },
    { enabled: applicationData?.typeKey === DomainDictionaryEntry.APPLICATION_TYPE.foreignPermitEkmt }
  );
  const applicationForeignPermissions =
    (data?.content as Array<ForeignPermissionApplicationRecordWithLimitDetails>) || [];

  const isEkmtCarnetVisible = applicationForeignPermissions.some(
    item => item.amount > 0 && item.resourceType.ekmtCategoryKey !== DomainDictionaryEntry.EKMT_CATEGORY.MOVE
  );

  const createTableConfig = ({
    permissionTable,
    folderTypes
  }: {
    permissionTable: PermissionTableEnum;
    folderTypes?: string[];
  }) => ({
    isVisible: folderTypes.includes(folderTypeKey) && (isVisible[permissionTable] as boolean)
  });

  const {
    LISENSE,
    GITD,
    LICENSE_COPY,
    PERMISSION_COPY,
    DRIVER_CERTIFICATE,
    SPO_UE,
    SPO_UE_COPY,
    SPO_OUTSIDE_UE,
    SPO_OUTSIDE_UE_COPY,
    SPO_SHUTTLE,
    SPO_SHUTTLE_COPY,
    SPO_OCCASIONAL,
    SPO_OCCASIONAL_COPY,
    SPO_TRANSIT,
    SPO_TRANSIT_COPY,
    OWN_NEEDS,
    OWN_NEEDS_COPY,
    FOREIGN_PERMISSION,
    S_CERTIFICATE,
    TRAVEL_FORM,
    INTERBUS,
    EKMT_CARNETS
  } = PermissionTableEnum;

  const getVisibleLastPermissionsFields = (config: ConfigTablesVisibility) => {
    const visibleTables = Object.entries(config).filter(([, value]) => value.isVisible);
    const isLicense =
      visibleTables.findIndex(([table]) => [LISENSE, LICENSE_COPY].includes(table as PermissionTableEnum)) !== -1;
    const isDriverCertificate = visibleTables.findIndex(([table]) => DRIVER_CERTIFICATE === table) !== -1;
    const isPermission =
      visibleTables.filter(
        ([name]) =>
          ![
            LISENSE,
            LICENSE_COPY,
            DRIVER_CERTIFICATE,
            INTERBUS,
            FOREIGN_PERMISSION,
            EKMT_CARNETS,
            S_CERTIFICATE
          ].includes(name as PermissionTableEnum)
      ).length > 0;
    return { isDriverCertificate, isLicense, isPermission };
  };

  const { TP, OP, PPO, MZ, PA, KS, ZZ, WITD } = DomainDictionaryEntry.FOLDER_TYPE;

  const configValue: ConfigState = {
    [LISENSE]: createTableConfig({ permissionTable: LISENSE, folderTypes: [TP, OP, PPO] }),
    [LICENSE_COPY]: createTableConfig({ permissionTable: LICENSE_COPY, folderTypes: [TP, OP] }),
    [GITD]: createTableConfig({ permissionTable: GITD, folderTypes: [TP, OP] }),
    [PERMISSION_COPY]: createTableConfig({ permissionTable: PERMISSION_COPY, folderTypes: [TP, OP] }),
    [DRIVER_CERTIFICATE]: createTableConfig({
      permissionTable: DRIVER_CERTIFICATE,
      folderTypes: [...Object.values(DomainDictionaryEntry.FOLDER_TYPE).filter(type => type !== KS)]
    }),
    [SPO_UE]: createTableConfig({ permissionTable: SPO_UE, folderTypes: [OP] }),
    [SPO_UE_COPY]: createTableConfig({ permissionTable: SPO_UE_COPY, folderTypes: [OP] }),
    [SPO_OUTSIDE_UE]: createTableConfig({ permissionTable: SPO_OUTSIDE_UE, folderTypes: [OP, MZ] }),
    [SPO_OUTSIDE_UE_COPY]: createTableConfig({ permissionTable: SPO_OUTSIDE_UE_COPY, folderTypes: [OP, MZ] }),
    [SPO_TRANSIT]: createTableConfig({ permissionTable: SPO_TRANSIT, folderTypes: [OP, MZ] }),
    [SPO_TRANSIT_COPY]: createTableConfig({ permissionTable: SPO_TRANSIT_COPY, folderTypes: [OP, MZ] }),
    [SPO_SHUTTLE]: createTableConfig({ permissionTable: SPO_SHUTTLE, folderTypes: [OP] }),
    [SPO_SHUTTLE_COPY]: createTableConfig({ permissionTable: SPO_SHUTTLE_COPY, folderTypes: [OP] }),
    [SPO_OCCASIONAL]: createTableConfig({ permissionTable: SPO_OCCASIONAL, folderTypes: [OP] }),
    [SPO_OCCASIONAL_COPY]: createTableConfig({ permissionTable: SPO_OCCASIONAL_COPY, folderTypes: [OP] }),
    [OWN_NEEDS]: createTableConfig({ permissionTable: OWN_NEEDS, folderTypes: [PA] }),
    [OWN_NEEDS_COPY]: createTableConfig({ permissionTable: OWN_NEEDS_COPY, folderTypes: [PA] }),
    [FOREIGN_PERMISSION]: createTableConfig({
      permissionTable: FOREIGN_PERMISSION,
      folderTypes: [TP, OP, KS, PA, ZZ]
    }),
    [EKMT_CARNETS]: createTableConfig({
      permissionTable: EKMT_CARNETS,
      folderTypes: [TP, OP, KS, PA, ZZ]
    }).isVisible
      ? { isVisible: isEkmtCarnetVisible }
      : { isVisible: false },
    [S_CERTIFICATE]: createTableConfig({
      permissionTable: S_CERTIFICATE,
      folderTypes: [KS, TP, PA]
    }),
    [INTERBUS]: createTableConfig({
      permissionTable: INTERBUS,
      folderTypes: [OP, PA, KS, ZZ, WITD]
    }),
    [TRAVEL_FORM]: createTableConfig({
      permissionTable: TRAVEL_FORM,
      folderTypes: [OP, PA, KS, ZZ, WITD]
    })
  };

  return {
    ...configValue,
    visiblePrintedPermissions: getVisibleLastPermissionsFields(configValue)
  };
}

export default usePrintTablesConfiguration;
