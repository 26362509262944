import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GridItem,
  GridLayout,
  InputMode,
  Section,
  TextInputField,
  useFormV2Context,
  useViewModesV2
} from '@libs/common/v2';

import { ICorrectionItems } from '../../model';

const getRoundedValue = (value: number) => Math.round(value * 100) / 100;

const calculateDiffrenceAmount = (items: ICorrectionItems[]) =>
  items?.reduce((acc, item) => {
    const { correctedQuantity = 1, correctedUnitCost = 0, quantity = 1, unitCost = 0 } = item;
    return acc + (correctedQuantity * correctedUnitCost - quantity * unitCost);
  }, 0) || 0;

function CorrectionAmountsSection({ correctionItemsTable }: { correctionItemsTable: ICorrectionItems[] }) {
  const [t] = useTranslation();
  const { setValue, watch, formMode } = useFormV2Context();
  const { createMode } = useViewModesV2(formMode);
  const oldValue = watch('amount') as number;
  const viewCorrectedValue = watch('correctedAmount') as number;
  const viewDiffrenceValue = watch('correctionDifference') as number;

  const documentAmountName = 'amount-form';
  const correctionAmountName = 'correctionAmount-form';
  const correctionDifferenceName = 'correctionDifference-form';

  useEffect(() => {
    if (createMode) {
      const amount = getRoundedValue(oldValue);
      const correctionDifference = calculateDiffrenceAmount(correctionItemsTable);
      const correctionAmount = amount + correctionDifference;

      setValue(documentAmountName, amount);
      setValue(correctionAmountName, getRoundedValue(correctionAmount));
      setValue(correctionDifferenceName, getRoundedValue(correctionDifference));
    } else {
      setValue(documentAmountName, viewCorrectedValue);
      setValue(correctionAmountName, oldValue);
      setValue(correctionDifferenceName, viewDiffrenceValue);
    }
  }, [correctionItemsTable, createMode, oldValue, setValue, viewCorrectedValue, viewDiffrenceValue]);

  return (
    <Section title={t('releaseDocuments:create.sections.amounts')} isCollapsable>
      <GridLayout containerProps={{ alignItems: 'flex-end' }} itemProps={{ xs: 12 }}>
        <GridItem xs={6}>
          <TextInputField
            name={documentAmountName}
            label={t('releaseDocuments:fields.correctionAmounts.documentAmount')}
            inputMode={InputMode.VIEW}
            isRequired
          />
        </GridItem>
        <GridItem xs={6}>
          <TextInputField
            name={correctionAmountName}
            label={t('releaseDocuments:fields.correctionAmounts.correctionAmount')}
            inputMode={InputMode.VIEW}
            isRequired
          />
        </GridItem>
        <GridItem xs={6}>
          <TextInputField
            name={correctionDifferenceName}
            label={t('releaseDocuments:fields.correctionAmounts.correctionDifference')}
            inputMode={InputMode.VIEW}
            isRequired
          />
        </GridItem>
      </GridLayout>
    </Section>
  );
}
export default CorrectionAmountsSection;
