import { InputMode } from '@libs/common/v2';

import {
  AdditionalSectionLicenseOrPermissionType,
  ApplicationMetaFormDefinition
} from '@libs/domain/application/model';

const definition: ApplicationMetaFormDefinition = {
  fields: [
    {
      typeKey: 'DATETIME',
      fieldId: 'application-expected-completion-date',
      properties: {
        label: 'Termin realizacji',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'expectedCompletionDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'expectedCompletionDate'
          }
        }
      }
    },
    {
      typeKey: 'AUTOCOMPLETE',
      fieldId: 'application-concern-license',
      properties: {
        label: 'Dotyczy licencji',
        inputMode: InputMode.VIEW,
        visibility: {
          elementVisibilityAsyncKeys: ['FOLDER_HAS_ACTIVE_OR_PENDING_LISENSE']
        },
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'concernLicense',
            formAccessor: 'id'
          },
          fetch: {
            requestKey: 'GET_LICENCES',
            optionTextMatch: '[number]'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'concernLicense'
          }
        }
      }
    },
    {
      typeKey: 'LAST_SUPPLEMENT_RECEIPT_DATE',
      fieldId: 'application-last-supplement-receipt-date',
      properties: {
        label: 'Data wpływu ostatniego uzupełnienia',
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'lastSupplementReceiptDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'lastSupplementReceiptDate'
          }
        }
      }
    },
    {
      typeKey: 'AUTOCOMPLETE',
      fieldId: 'application-locked-by-application',
      properties: {
        label: 'Wniosek blokujący',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'lockedByApplication',
            formAccessor: 'id'
          },
          fetch: {
            requestKey: 'GET_LOCKING_APPLICATIONS',
            accessor: null,
            optionTextMatch: '[number]'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'lockedByApplication'
          }
        }
      }
    },

    {
      typeKey: 'BOOLEAN',
      fieldId: 'application-is-review-application',
      properties: {
        label: 'Czy wpłynął wniosek o ponowne rozpatrzenie sprawy?',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'isReviewApplication'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'isReviewApplication'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-second-tier-decision-key',
      properties: {
        label: 'Rodzaj rozstrzygnięcia II instancji',
        dictionaryName: 'SECOND_TIER_DECISION',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'secondTierDecisionKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'secondTierDecisionKey'
          }
        }
      }
    },
    {
      typeKey: 'DATETIME',
      fieldId: 'application-second-tier-decision-date',
      properties: {
        label: 'Data rozstrzygnięcia (II instancji)',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'secondTierDecisionDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'secondTierDecisionDate'
          }
        }
      }
    },
    {
      typeKey: 'BOOLEAN',
      fieldId: 'application-wsa-complaint',
      properties: {
        label: 'Czy wpłynęła skarga do WSA?',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'wsaComplaint'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'wsaComplaint'
          }
        }
      }
    },
    {
      typeKey: 'DICTIONARY',
      fieldId: 'application-wsa-decision-key',
      properties: {
        label: 'Rodzaj rozstrzygnięcia WSA',
        dictionaryName: 'WSA_DECISION',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'wsaDecisionKey'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'wsaDecisionKey'
          }
        }
      }
    },
    {
      typeKey: 'DATETIME',
      fieldId: 'application-wsa-decision-date',
      properties: {
        label: 'Data rozstrzygnięcia (WSA)',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'wsaDecisionDate'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'wsaDecisionDate'
          }
        }
      }
    },
    {
      typeKey: 'BOOLEAN',
      fieldId: 'application-final-decision',
      properties: {
        label: 'Ostateczne rozstrzygnięcie?',
        inputMode: InputMode.VIEW,
        api: {
          submit: {
            requestKey: 'UPDATE_APPLICATION',
            accessor: 'finalDecision'
          },
          get: {
            requestKey: 'GET_APPLICATION',
            accessor: 'finalDecision'
          }
        }
      }
    },
    {
      typeKey: 'MISSPELL_APPLICATIONS_COLLECTION',
      fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-applications-reprint',
      properties: {
        maxAddedApplicationsCount: 1,
        title: 'Wniosek do poprawy',
        searchDialogColumns: ['number', 'typeKey', 'driverCertificateNumber']
      }
    },
    {
      typeKey: 'MISSPELL_APPLICATIONS_DRIVER_CERTIFICATE_COLLECTION',
      fieldId: 'misspellApplicationDataDetails-driver-certificate-reprint-collection'
    }
  ],
  components: [],
  layout: [
    {
      typeKey: 'TAB_NAVIGATION',
      properties: {
        visibility: {
          elementVisibilityKeys: ['TAB_VISIBILITY', 'DRIVER_TAB_FOR_MIGRATED_APPLICATIONS_VISIBILITY']
        },
        defaultTabId: 'GENERAL',
        title: 'Sekcje',
        tabs: [
          {
            id: 'GENERAL',
            title: 'Dane podstawowe',
            icon: 'InfoIcon'
          },
          {
            id: 'SUBJECT',
            title: 'Podmiot',
            icon: 'FacadeIcon'
          },
          {
            id: 'ADDRESSES',
            title: 'Adresy',
            icon: 'MailboxIcon'
          },
          {
            id: 'PERMISSIONS',
            title: 'Uprawnienia',
            icon: 'KeyIcon'
          },
          {
            id: 'DRIVERS',
            title: 'Kierowcy',
            icon: 'DriversIcon'
          },
          {
            id: 'PROXIES',
            title: 'Pełnomocnicy',
            icon: 'GroupOfPeopleIcon'
          },
          {
            id: 'ATTACHMENTS',
            title: 'Załączniki',
            icon: 'PaperclipIcon'
          },
          {
            id: 'QUOTATION',
            title: 'Wycena',
            icon: 'CoinsIcon'
          },
          {
            id: 'OUTGOING_DOCUMENTS',
            title: 'Dokumenty wychodzące',
            icon: 'DocumentsIssuedIcon'
          },
          {
            id: 'NOTE',
            title: 'Notatka',
            icon: 'NoteIcon'
          },
          {
            id: 'FILES',
            title: 'Pliki',
            icon: 'FileEmptyIcon'
          },
          {
            id: 'HISTORY',
            title: 'Historia',
            icon: 'HistoryIcon'
          }
        ]
      },
      nodes: [
        {
          typeKey: 'TABS_CONTENT',
          nodes: [
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-general-tab',
                additionalNodes: {
                  'additional-section': [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'licenseApplicationDetails-misspellApplicationDataDetails-applications-reprint'
                      }
                    },

                    {
                      typeKey: 'COMPONENT',
                      properties: {
                        componentId: 'application-misspell-description-and-date-of-issue-section'
                      }
                    },
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'misspellApplicationDataDetails-driver-certificate-reprint-collection'
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-subject-tab',
                additionalProperties: {
                  SUBJECT: {
                    inputMode: InputMode.VIEW
                  }
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-addresses-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-permissions-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-drivers-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-proxies-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-attachments-tab'
              }
            },

            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-quotation-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-outgoing-documents-tab',
                additionalNodes: {
                  'outgoing-documents-additional-section': [
                    {
                      typeKey: 'FIELD',
                      properties: {
                        fieldId: 'application-outgoing-documents-additional-section-license-or-permission',
                        sectionTypes: [AdditionalSectionLicenseOrPermissionType.DRIVER_CERTIFICATE]
                      }
                    }
                  ]
                }
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-note-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-files-tab'
              }
            },
            {
              typeKey: 'COMPONENT',
              properties: {
                componentId: 'application-history-tab'
              }
            }
          ]
        }
      ]
    }
  ]
};

export default definition;
