import { QueryConfig, useQuery } from 'react-query';
import { SubjectDetailsExtended } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

import SubjectQueryKeysEnum from './SubjectQueryKeysEnum';

const getApplicationSubjectExtendedClient = async (id: string) => {
  const { data } = await API.client.subject.getSubject(id, '', '', {
    ...endpointsConfig.getSubject
  });

  return data;
};

const getSubjectExtended = async (subjectId: string) => {
  const { data } = await API.subject.getSubjectDetailsExtended(subjectId);
  return data;
};

const getSubjectExtendedClient = async () => {
  const { data } = await API.client.subject.getSubjectByFolder('', '', {
    ...endpointsConfig.getSubject
  });

  return data;
};

export default function useSubjectDetailsExtendedQuery(
  id: string,
  queryConfig: QueryConfig<any, unknown> = {},
  isFolder?: boolean
) {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery<SubjectDetailsExtended>(
    [SubjectQueryKeysEnum.SUBJECT_EXTENDED, id],
    getQuery(isFolder ? getSubjectExtendedClient : () => getApplicationSubjectExtendedClient(id), () =>
      getSubjectExtended(id)
    ),
    queryConfig
  );
}
