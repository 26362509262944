import { useMemo } from 'react';

import { useRouter } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

import { CreateApplicationButton, CreateApplicationTableButton } from '../components';
import { ActionButtonsEnum } from '../model/ApplicationTableActionButtonEnum';

function useApplicationTableButtons(actionKey?: UIElementNameEnum) {
  const { goToPage, routes } = useRouter();
  const handleNewApplication = (applicationId: string) => {
    goToPage(routes.applicationEdit(applicationId));
  };

  return useMemo(
    () => ({
      [ActionButtonsEnum.CREATE]: <CreateApplicationButton onSuccess={handleNewApplication} />,
      [ActionButtonsEnum.TABLE_CREATE]: (
        <CreateApplicationTableButton onSuccess={handleNewApplication} actionKey={actionKey || null} />
      )
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}

export default useApplicationTableButtons;
