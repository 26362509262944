import { TravelFormCreateRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function createTravelForm({ travelFormCreateRequest }: { travelFormCreateRequest: TravelFormCreateRequest }) {
  return API.travelForm.createTravelForm(travelFormCreateRequest, {
    ...endpointsConfig.createTravelForm
  });
}

function useCreateTravelFormMutation() {
  return useMutation(createTravelForm);
}

export default useCreateTravelFormMutation;
