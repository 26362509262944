import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '@enigma/fe-ui';
import { ApplicationDetails as ApplicationDetailsClient } from '@ibtm/client-domain';
import { ApplicationDetails, ApplicationSnapshot } from '@ibtm/domain';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

import { useIsSmallScreen, useRouter } from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';
import { Theme } from '@libs/common/v2/theme';
import { calc } from '@libs/common/v2/utils';

import { RoutePermissionGuard, useElementVisibility } from '@libs/permission';
import usePermissions from '@libs/permission/hooks/usePermissions';

import { useTasksDetailsQuery, useTasksQuery } from '@libs/domain/application-definitions';
import {
  checkIfErrorHasNotAllowedStatusCode,
  DomainUIElementEnum,
  PermissionEnum,
  useDomainConfigContext
} from '@libs/domain/config';
import { FolderSlider } from '@libs/domain/folder';
import { useSubjectDetailsExtendedQuery } from '@libs/domain/subject';

import { useApplicationDetailsQuery } from '../api';
import { ApplicationDetailsContent, ApplicationDetailsHeader } from '../components';
import { useApplicationPermission, useBackFromApplication, useIsApplicationEditEnabled } from '../hooks';
import { ApplicationDetailsPageParams, ProcessVariableKeyEnum } from '../model';
import { statusesWithBlockedEdit } from '../utils';

function ApplicationDetailsPage() {
  const { isSmallScreen } = useIsSmallScreen();
  const { routes, goToPage, location } = useRouter();
  const queryCache = useQueryCache();
  const backFromApplication = useBackFromApplication();
  const placeholderData = location?.state as ApplicationDetails | ApplicationDetailsClient;
  const { applicationId, mode, folderId, archiveLocation } = useParams<ApplicationDetailsPageParams>();
  const { hasPermission } = usePermissions();
  const hasSubjectDataPermission = hasPermission(PermissionEnum.IBTM_DOMAIN_APPLICATION_ITEM_SUBJECT_VIEW);
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();
  const applicationQuery = useApplicationDetailsQuery(applicationId, {
    enabled: Boolean(applicationId),
    placeholderData,
    onError: () => {
      goToPage(routes.applicationsList());
    }
  });
  const subjectId = placeholderData?.subject?.id || applicationQuery.data?.subject?.id;
  const subjectQuery = useSubjectDetailsExtendedQuery(subjectId, {
    enabled: Boolean(subjectId && hasSubjectDataPermission)
  });
  const applicationTypeKey = applicationQuery.data?.typeKey;
  const { hasPermissions: hasApplicationPermissions } = useApplicationPermission();

  const isPermitted = hasApplicationPermissions({
    action: mode === 'form' ? 'update' : 'view',
    applicationType: applicationTypeKey,
    isLoading: applicationQuery.isLoading
  });
  const [taskIds, setTaskIds] = useState<string[]>([]);
  const classes = usePageDetailsStyles();

  const tasksDetailsQuery = useTasksDetailsQuery(taskIds, { enabled: Boolean(taskIds.length) });
  const { checkIsElementVisible } = useElementVisibility();
  const tasksQuery = useTasksQuery(
    {
      size: 100,
      processVariables: {
        key: ProcessVariableKeyEnum.APPLICATION_ENTITY_ID,
        value: applicationId
      }
    },
    { enabled: Boolean(applicationId) }
  );

  useEffect(() => {
    if (_.isArray(tasksQuery.data?.content) && tasksQuery.isFetchedAfterMount) {
      setTaskIds(tasksQuery.data.content.map(task => task.taskId));
    }
  }, [tasksQuery.data, tasksQuery.isFetchedAfterMount]);

  const refetch = (onRefetchFinish?: (data: any) => void) => {
    applicationQuery.refetch().then(data => {
      onRefetchFinish?.(data);
    });
    tasksQuery.refetch();

    queryCache.invalidateQueries('METAFORM_GET_APPLICATION');
  };

  const getCurrentTabURLSearchParam = useCallback(() => {
    const activeTab = new URLSearchParams(window.location.search).get('tab');
    const activeMoneyTransferTab = new URLSearchParams(window.location.search).get('moneyTransferTab');

    if (!activeTab) {
      return '';
    }

    let paramsUrl = `?tab=${activeTab}`;
    if (activeMoneyTransferTab) {
      paramsUrl += `&moneyTransferTab=${activeMoneyTransferTab}`;
    }

    return paramsUrl;
  }, []);

  const applicationBaseUrl = useCallback(() => {
    if (folderId) {
      return `/folders/${folderId}`;
    }
    if (archiveLocation) {
      return `/${archiveLocation}`;
    }
    return '';
  }, [folderId, archiveLocation]);

  const { isEditEnabled: isEnabled, errorMessage } = useIsApplicationEditEnabled(
    applicationQuery.data as ApplicationSnapshot
  );
  const { isOperatorPortal } = useDomainConfigContext();

  useEffect(() => {
    if (statusesWithBlockedEdit.some(status => status === applicationQuery.data?.statusKey && mode === 'form')) {
      goToPage(`${applicationBaseUrl()}/applications/${applicationId}/view`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationQuery.data?.statusKey]);

  useEffect(() => {
    if (isOperatorPortal && !isEnabled && mode === 'form' && applicationQuery.isFetchedAfterMount) {
      showSnackbar('warning', errorMessage);
      goToPage({
        pathname: `${applicationBaseUrl()}/applications/${applicationId}/view`,
        search: getCurrentTabURLSearchParam()
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applicationId,
    isEnabled,
    mode,
    showSnackbar,
    errorMessage,
    getCurrentTabURLSearchParam,
    applicationBaseUrl,
    isOperatorPortal,
    applicationQuery.isFetchedAfterMount
  ]);
  return (
    <RoutePermissionGuard
      isPermitted={isPermitted}
      isLoading={applicationQuery.isLoading}
      notAllowedCode={checkIfErrorHasNotAllowedStatusCode(applicationQuery.error)}
      notValidRoute={!['form', 'view'].includes(mode)}
    >
      <ApplicationDetailsContent
        pageComponentProps={{
          header: (
            <ApplicationDetailsHeader
              title={mode === 'form' ? t('applications:edit.title') : t('applications:details.title')}
              applicationData={applicationQuery.data}
              subjectData={subjectQuery.data}
              tasks={tasksDetailsQuery.data || []}
              isTasksLoading={tasksDetailsQuery.isLoading}
              refetch={refetch}
              isFormOpen={mode === 'form'}
              onCloseForm={() => {
                goToPage({
                  pathname: `${applicationBaseUrl()}/applications/${applicationId}/view`,
                  search: getCurrentTabURLSearchParam()
                });
              }}
              onEditApplicationClick={() => {
                goToPage({
                  pathname: `${applicationBaseUrl()}/applications/${applicationId}/form`,
                  search: getCurrentTabURLSearchParam()
                });
              }}
              onGoBackApplicationClick={backFromApplication}
            />
          ),
          rightSidebarContent: (
            <FolderSlider
              folderId={applicationQuery.data?.folder?.id}
              isCopyBlockadeListRedirectActionVisible={checkIsElementVisible(
                DomainUIElementEnum.APPLICATION_FOLDER_LOCK_REDIRECT_BUTTON
              )}
              isCopyEmailActionVisible={checkIsElementVisible(
                DomainUIElementEnum.APPLICATION_SUBJECT_EMAIL_COPY_BUTTON
              )}
              isCopyNIPActionVisible={checkIsElementVisible(DomainUIElementEnum.APPLICATION_SUBJECT_NIP_COPY_BUTTON)}
              isCopyREGONActionVisible={checkIsElementVisible(
                DomainUIElementEnum.APPLICATION_SUBJECT_REGON_COPY_BUTTON
              )}
              isFolderRedirectActionVisible={checkIsElementVisible(
                DomainUIElementEnum.APPLICATION_FOLDER_REDIRECT_BUTTON
              )}
              hasSubjectDataPermission={hasSubjectDataPermission}
            />
          ),
          pageWrapperClassName: classes.contentWrapper,
          isRightSidebarVisible: checkIsElementVisible(DomainUIElementEnum.APPLICATION_FOLDER_SLIDER_VIEW),
          isLeftSidebarOpen: !isSmallScreen
        }}
        isFormOpen={mode === 'form'}
        applicationData={{
          ...applicationQuery.data,
          id: applicationId
        }}
      />
    </RoutePermissionGuard>
  );
}

const usePageDetailsStyles = makeStyles<Theme>(() => ({
  contentWrapper: {
    '& .wrapperWithHeader': {
      height: calc('100% - 275px')
    }
  }
}));

export default ApplicationDetailsPage;
