import {
  ApplicationDetails,
  LicenseCopySearchFilter,
  LicenseSearchFilter,
  PermissionCopySearchFilter,
  PermissionSearchFilter
} from '@ibtm/domain';

import { SelectOption } from '@libs/common/v2';

import { API } from '@libs/domain/api';
import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';
import { LicenseStatusEnum, LicenseTypeEnum } from '@libs/domain/license';
import { PermissionStatusEnum, PermissionTypeEnum } from '@libs/domain/permission';

import { ApplicationChannelKeyEnum } from '../model';

export const additionalSelectOptionsFilters: {
  [key: string]: (option: SelectOption) => boolean;
} = {
  PB_8_9_RECEIPT_TYPE_KEY_FILTER: option =>
    [DomainDictionaryEntry.RECEIPT_TYPE.PICKUP_IN_PERSON, DomainDictionaryEntry.RECEIPT_TYPE.POST].includes(
      option.value
    ),
  RECEIPT_TYPE_KEY_FILTER_EPUAP: option => option.value !== DomainDictionaryEntry.RECEIPT_TYPE.EPUAP,
  PB_33_13_APPLICATION_CHANNEL_KEY_FILTER: option => option.value === ApplicationChannelKeyEnum.INTERNAL,
  PB26_LICENSE_VALIDITY_YEARS_FILTER: option => {
    const { UP_TO_15_YEARS, UP_TO_30_YEARS, UP_TO_50_YEARS_PPO } = DomainDictionaryEntry.LICENSE_VALIDITY_YEARS;
    return [UP_TO_15_YEARS, UP_TO_30_YEARS, UP_TO_50_YEARS_PPO].includes(option.value);
  },
  IPWZ_TRANSPORT_TYPE_FILTER: option => option.value === DomainDictionaryEntry.TRANSPORT_TYPE.FREIGHT
};
export const isApplicationTypeWithVehicleAmount = (applicationType: string) => {
  return [
    DomainDictionaryEntry[DomainDictionaryEnum.APPLICATION_TYPE].PB6B,
    DomainDictionaryEntry[DomainDictionaryEnum.APPLICATION_TYPE].PB6C,
    DomainDictionaryEntry[DomainDictionaryEnum.APPLICATION_TYPE].PB6D,
    DomainDictionaryEntry[DomainDictionaryEnum.APPLICATION_TYPE].PB7A,
    DomainDictionaryEntry[DomainDictionaryEnum.APPLICATION_TYPE].PB7B,
    DomainDictionaryEntry[DomainDictionaryEnum.APPLICATION_TYPE].PB8A,
    DomainDictionaryEntry[DomainDictionaryEnum.APPLICATION_TYPE].PB8B,
    DomainDictionaryEntry[DomainDictionaryEnum.APPLICATION_TYPE].PB9A,
    DomainDictionaryEntry[DomainDictionaryEnum.APPLICATION_TYPE].PB9B
  ].includes(applicationType);
};

export const createApplicationPermissionDuplicateApiRegistry = ({
  folderTypekey,
  folderId,
  transferredFoldersIds
}: {
  folderTypekey: string;
  folderId: string;
  transferredFoldersIds?: string[];
}) => {
  const licenseStatus = [LicenseStatusEnum.ACTIVE];
  const permissionStatus = [PermissionStatusEnum.ACTIVE, PermissionStatusEnum.PENDING];

  const getPermissionCopyData = async ({
    typeKeyIn,
    permissionNumberOrCopyNumberContains
  }: PermissionCopySearchFilter) => {
    const { data } = await API.permissions.getPermissionCopiesPage({
      folderIdIn: [folderId, ...(transferredFoldersIds || [])],
      typeKeyIn,
      statusKeyIn: permissionStatus,
      permissionNumberOrCopyNumberContains
    });
    return data;
  };
  return {
    GET_PERMISSIONS_TO_DUPLICATE_PB5: {
      request: async (_, params: PermissionSearchFilter) => {
        let typeKeyIn: string[];
        switch (folderTypekey) {
          case DomainDictionaryEntry.FOLDER_TYPE.TP:
            typeKeyIn = [PermissionTypeEnum.ROAD_CARRIER_GOODS_CARRIAGE];
            break;
          case DomainDictionaryEntry.FOLDER_TYPE.OP:
            typeKeyIn = [PermissionTypeEnum.ROAD_CARRIER_PASSENGER_CARRIAGE];
            break;
          default:
            break;
        }

        const data = await API.permissions.getPermissionsPage({
          folderIdIn: [folderId, ...(transferredFoldersIds || [])],
          typeKeyIn,
          statusIn: permissionStatus,
          numberContains: params?.numberContains,
          page: params.page,
          size: params.size
        });

        return data;
      },
      params: null
    },
    GET_PERMISSIONS_TO_DUPLICATE_COPY_PB5: {
      request: async (_, params: PermissionCopySearchFilter) => {
        let typeKeyIn: string[];
        switch (folderTypekey) {
          case DomainDictionaryEntry.FOLDER_TYPE.TP:
            typeKeyIn = [PermissionTypeEnum.ROAD_CARRIER_GOODS_CARRIAGE];
            break;
          case DomainDictionaryEntry.FOLDER_TYPE.OP:
            typeKeyIn = [PermissionTypeEnum.ROAD_CARRIER_PASSENGER_CARRIAGE];
            break;
          default:
            break;
        }
        const data = await API.permissions.getPermissionCopiesPage({
          folderIdIn: [folderId, ...(transferredFoldersIds || [])],
          typeKeyIn,
          statusKeyIn: permissionStatus,
          permissionNumberOrCopyNumberContains: params?.permissionNumberOrCopyNumberContains,
          page: params.page,
          size: params.size
        });
        return data;
      },
      params: null
    },
    GET_LICENSES_TO_DUPLICATE_PB5: {
      request: async (_, params: LicenseSearchFilter) => {
        let typeKeyIn: string[];
        switch (folderTypekey) {
          case DomainDictionaryEntry.FOLDER_TYPE.TP:
            typeKeyIn = [LicenseTypeEnum.TP_LICENSE_EU];
            break;
          case DomainDictionaryEntry.FOLDER_TYPE.OP:
            typeKeyIn = [LicenseTypeEnum.OP_LICENSE_EU];
            break;
          default:
            break;
        }
        const data = await API.license.getLicensesSnapshotPage({
          folderIdIn: [folderId, ...(transferredFoldersIds || [])],
          typeKeyIn,
          statusKeyIn: licenseStatus,
          formNumberContains: params?.formNumberContains,
          licenseNumberContains: params?.licenseNumberContains,
          page: params.page,
          size: params.size
        });
        return data;
      },
      params: null
    },
    GET_LICENSES_TO_DUPLICATE_COPY_PB5: {
      request: async (_, params: LicenseCopySearchFilter) => {
        let typeKeyIn: string[];
        switch (folderTypekey) {
          case DomainDictionaryEntry.FOLDER_TYPE.TP:
            typeKeyIn = [LicenseTypeEnum.TP_LICENSE_EU];
            break;
          case DomainDictionaryEntry.FOLDER_TYPE.OP:
            typeKeyIn = [LicenseTypeEnum.OP_LICENSE_EU];
            break;
          default:
            break;
        }
        const data = await API.license.getLicenseCopySnapshotPage({
          folderIdIn: [folderId, ...(transferredFoldersIds || [])],
          typeKeyIn,
          statusKeyIn: licenseStatus,
          licenseNumberOrFormNumberContains: params?.licenseNumberOrFormNumberContains,
          page: params.page,
          size: params.size
        });
        return data;
      },
      params: null
    },
    GET_LICENSES_TO_DUPLICATE_PB31: {
      request: async (_, params: LicenseSearchFilter) => {
        const { data } = await API.license.getLicensesSnapshotPage({
          folderIdIn: [folderId, ...(transferredFoldersIds || [])],
          typeKeyIn: [LicenseTypeEnum.PASSENGER_TRANSPORT_BROKERAGE],
          statusKeyIn: [LicenseStatusEnum.ACTIVE, LicenseStatusEnum.PENDING],
          licenseNumberContains: params?.licenseNumberContains
        });
        return data;
      },
      params: null
    },
    GET_PERMISSIONS_TO_DUPLICATE_COPY_SPO_WZW: {
      request: async (_, params: PermissionCopySearchFilter) =>
        getPermissionCopyData({
          typeKeyIn: [PermissionTypeEnum.SPO_SHUTTLE],
          permissionNumberOrCopyNumberContains: params?.permissionNumberOrCopyNumberContains
        }),
      params: null
    },
    GET_PERMISSIONS_TO_DUPLICATE_COPY_SPO_WZO: {
      request: async (_, params: PermissionCopySearchFilter) =>
        getPermissionCopyData({
          typeKeyIn: [PermissionTypeEnum.SPO_OCCASIONAL],
          permissionNumberOrCopyNumberContains: params?.permissionNumberOrCopyNumberContains
        }),
      params: null
    },
    GET_PERMISSIONS_TO_DUPLICATE_COPY_SPO_OUTSIDE: {
      request: async (_, params: PermissionCopySearchFilter) =>
        getPermissionCopyData({
          typeKeyIn: [PermissionTypeEnum.SPO_OUTSIDE_UE],
          permissionNumberOrCopyNumberContains: params?.permissionNumberOrCopyNumberContains
        }),
      params: null
    },
    GET_PERMISSIONS_TO_DUPLICATE_COPY_SPO_UE: {
      request: async (_, params: PermissionCopySearchFilter) =>
        getPermissionCopyData({
          typeKeyIn: [PermissionTypeEnum.SPO_UE],
          permissionNumberOrCopyNumberContains: params?.permissionNumberOrCopyNumberContains
        }),
      params: null
    },
    GET_PERMISSIONS_TO_DUPLICATE_COPY_SPO_TRANSIT_RP: {
      request: async (_, params: PermissionCopySearchFilter) =>
        getPermissionCopyData({
          typeKeyIn: [PermissionTypeEnum.SPO_TRANSIT],
          permissionNumberOrCopyNumberContains: params?.permissionNumberOrCopyNumberContains
        }),
      params: null
    }
  };
};

export const statusesWithBlockedEdit = [
  DomainDictionaryEntry.APPLICATION_STATUS.ISSUED,
  DomainDictionaryEntry.APPLICATION_STATUS.RELEASED_WAITING_FOR_ZPO,
  DomainDictionaryEntry.APPLICATION_STATUS.CANCELLED,
  DomainDictionaryEntry.APPLICATION_STATUS.WITHDRAWN,
  DomainDictionaryEntry.APPLICATION_STATUS.ACCOMPLISHED
];

export const finishedApplicationStatuses = [
  DomainDictionaryEntry.APPLICATION_STATUS.ACCOMPLISHED_WITHOUT_PRINTING,
  DomainDictionaryEntry.APPLICATION_STATUS.ISSUED,
  DomainDictionaryEntry.APPLICATION_STATUS.WITHDRAWN,
  DomainDictionaryEntry.APPLICATION_STATUS.CANCELLED,
  DomainDictionaryEntry.APPLICATION_STATUS.ACCOMPLISHED_REFUSE_PERMISSION,
  DomainDictionaryEntry.APPLICATION_STATUS.ACCOMPLISHED
];

/** Sprawdza czy wniosek to (LR9 lub LO9) - Przedłużenie ważności licencji, dla teczek TP lub OP */
export const checkIsPB1CApplicationAndTPOrOPFolder = (application: Pick<ApplicationDetails, 'folder' | 'typeKey'>) => {
  const isOpOrTpFolder = [DomainDictionaryEntry.FOLDER_TYPE.OP, DomainDictionaryEntry.FOLDER_TYPE.TP].includes(
    application.folder?.typeKey
  );
  return isOpOrTpFolder && application?.typeKey === DomainDictionaryEntry.APPLICATION_TYPE.PB1C;
};
