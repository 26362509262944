import { useTranslation } from 'react-i18next';
import { DriverDetails } from '@ibtm/domain';

import { FormMode, TableButtonView } from '@libs/common/v2';

import { UIElementNameEnum } from '@libs/permission';

import { useDriverDialog } from '@libs/domain/drivers/hooks';

interface IProps {
  driverId: string;
  data: DriverDetails;
  actionKey: UIElementNameEnum;
}

function PreviewDriverButton({ driverId, data, actionKey }: IProps) {
  const [t] = useTranslation();

  const { open } = useDriverDialog();

  const handleOpenDialog = () => {
    open({
      data: {
        driverId
      },
      initialData: data,
      formMode: FormMode.VIEW
    });
  };

  return <TableButtonView tooltipTitle={t('action.preview')} onClick={handleOpenDialog} actionKey={actionKey} />;
}

export default PreviewDriverButton;
