import { ResourceObjectClientReceiveRequest } from '@ibtm/client-domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../../endpoints-config';

function receive(requestData: ResourceObjectClientReceiveRequest) {
  return API.client.resourceObjects.receiveClientResourceObject('', requestData, {
    ...endpointsConfig.receiveClientResourceObject
  });
}

function useReceiveMutation() {
  return useMutation(receive);
}

export default useReceiveMutation;
