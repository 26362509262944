import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function suspendFormOperations({ suspensionId, version }: { suspensionId: string; version: number }) {
  return API.suspensions.suspendSuspensionForms(
    suspensionId,
    { version },
    {
      ...endpointsConfig.suspendSuspensionForms
    }
  );
}

function useSuspendFormOperationsMutation() {
  return useMutation(suspendFormOperations);
}

export default useSuspendFormOperationsMutation;
