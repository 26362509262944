import { SubjectHistoryDetails } from '@ibtm/domain';

import { CamelCasePath, SelectOption, useTableLayoutProps } from '@libs/common/v2';

import { useSubjectHistoryQuery } from '@libs/domain/folder';

import { SubjectHistoryDetailsExtend } from './useSubjectHistoryColumns';
import { useSubjectHistoryColumns } from '.';

interface Props {
  folderId: string;
  subjectId: string;
  legalFormKey: SelectOption<string>;
  visibleColumns: Array<CamelCasePath<SubjectHistoryDetails & SubjectHistoryDetailsExtend>>;
}

function useSubjectHistoryTable({ folderId, subjectId, legalFormKey, visibleColumns }: Props) {
  const { columns } = useSubjectHistoryColumns(legalFormKey, visibleColumns);

  return useTableLayoutProps({
    tableHookQuery: useSubjectHistoryQuery,
    tableHookOptions: {
      columns,
      tableOptions: {
        initialState: {
          sortBy: [
            {
              id: 'created',
              desc: true
            }
          ]
        }
      }
    },
    tableHookQueryInitialParams: {
      folderIds: folderId,
      subjectId
    }
  });
}

export default useSubjectHistoryTable;
