import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@enigma/fe-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ResourceObjectClientReleaseRequest } from '@ibtm/client-domain';
import { AssociationSubjectSnapshot } from '@ibtm/domain';
import * as Yup from 'yup';

import { partialTranslate } from '@libs/common';
import {
  AutocompleteLazyFetchSelectField,
  Dialog,
  FormV2Context,
  GridItem,
  GridLayout,
  SelectOption
} from '@libs/common/v2';
import { useQueryCache } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { AssociationQueryKeysEnum } from '../../../../associations';
import { ResourceQueryKeysEnum, useReleaseMutation } from '../../../api';
import { usePoolsValidationSchema } from '../../../hooks';
import { IResourceObjectAssociationPoolClient, ResourceObjectContentPoolClient } from '../../../model';
import { PoolsForm } from '../common';

const getLabel = partialTranslate('resource:fields');

type RealeseFormFields = {
  pools: ResourceObjectContentPoolClient[];
  subject: SelectOption<AssociationSubjectSnapshot>;
};

interface IProps {
  resourceObjects: IResourceObjectAssociationPoolClient[];
  closeDialog: () => void;
}

function ReleaseForm({ resourceObjects, closeDialog }: IProps) {
  const queryCache = useQueryCache();
  const [t] = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { mutate: release, isLoading } = useReleaseMutation();

  const poolsValidationSchema = usePoolsValidationSchema(resourceObjects);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    trigger,
    unregister
  } = useForm<Partial<RealeseFormFields>>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        subject: Yup.object().nullable().required(),
        pools: poolsValidationSchema
      })
    ),
    defaultValues: {
      pools: resourceObjects
    }
  });

  const onSubmit = (formData: Partial<RealeseFormFields>) => {
    const requestData: ResourceObjectClientReleaseRequest = {
      subjectId: formData.subject.value.subject.id,
      resources: formData.pools.map((item, index) => ({
        numberFrom: item.numberFrom,
        numberTo: item.numberTo,
        amount: item.amount,
        resourceTypeId: resourceObjects[index].resourceType?.id
      }))
    };
    release(requestData, {
      onSuccess: () => {
        showSnackbar('success', t('resource:messages.releaseSuccess'));
        queryCache.invalidateQueries(ResourceQueryKeysEnum.RESOURCE_OBJECT_ASSOCIATION_POOLS);
        closeDialog();
      }
    });
  };

  const getOptionLabel = option => {
    return `${option.subject.name} ${option.subject?.surname || ''} (${option.subject.nip})`;
  };

  const values = useMemo(
    () => ({ control, errors, register, setValue, watch, getValues, trigger, unregister, isSubmitting }),
    [control, errors, getValues, isSubmitting, register, setValue, trigger, unregister, watch]
  );

  return (
    <Dialog
      title={t('resource:dialog.releaseDialogTitle')}
      confirmText={t('resource:actions.confirmRelease')}
      cancelText={t('action.cancel')}
      onConfirm={() => handleSubmit(onSubmit)()}
      onCancel={closeDialog}
      dialogSize="large"
      isConfirmLoading={isLoading}
      isOpen
    >
      <FormV2Context.Provider value={values}>
        <GridLayout>
          <GridItem xs={6}>
            <AutocompleteLazyFetchSelectField
              name="subject"
              queryKey={AssociationQueryKeysEnum.GET_ASSOCIATION_SUBJECT}
              optionLabelParser={getOptionLabel}
              api={{
                FETCH: (searchText: string, params: { page: number; size: number }) =>
                  API.client.association.getAssociationSubjectSnapshotPage('', '', {
                    ...params,
                    nipContains: searchText,
                    resigned: false
                  })
              }}
              label={getLabel('company')}
              isRequired
            />
          </GridItem>
          <GridItem xs={12}>
            <PoolsForm />
          </GridItem>
        </GridLayout>
      </FormV2Context.Provider>
    </Dialog>
  );
}

export default ReleaseForm;
