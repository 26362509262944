import { useEffect, useState } from 'react';

import { ComponentErrorBoundary, SEARCH_TEXT_DEBOUNCE_TIME, SelectOption, useDebounceValue } from '@libs/common/v2';

import AutocompleteSelectField, {
  ICommonAutocompleteProps as AutocompleteSelectFieldProps
} from '../AutocompleteSelectField/AutocompleteSelectField';

interface IProps {
  fetchFunction: (debouncedInputText: string) => Promise<any>;
  getOptionLabel: (option: any) => string;
  fetchedValueParser?: (data: any) => string;
  reload?: boolean;
  customErrorMessage?: string;
  onFetchedData?: (data: SelectOption[]) => void;
  isFetchDisabled?: boolean;
}

export type SelectWithFetchedOptionsFieldProps = IProps &
  Omit<AutocompleteSelectFieldProps, 'options' | 'onInputChange'>;

function SelectWithFetchedOptionsField({
  fetchFunction,
  getOptionLabel,
  reload,
  onFetchedData,
  fetchedValueParser,
  customErrorMessage,
  isFetchDisabled,
  ...forwardProps
}: SelectWithFetchedOptionsFieldProps) {
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [inputText, setInputText] = useState<string>('');
  const debouncedInputText = useDebounceValue(inputText, SEARCH_TEXT_DEBOUNCE_TIME);

  useEffect(() => {
    let active = true;
    fetchData().then(data => {
      if (active) {
        setOptions(data);
      }
    });
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputText]);

  useEffect(() => {
    let active = true;
    if (reload) {
      fetchData().then(data => {
        if (active) {
          setOptions(data);
        }
      });
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const fetchData = async () => {
    if (isFetchDisabled) {
      return null;
    }

    const response = await fetchFunction(debouncedInputText);
    const responseMapped = response?.data?.content.map(item => ({
      id: item.id,
      name: getOptionLabel(item),
      value: fetchedValueParser ? fetchedValueParser(item) : item
    })) as SelectOption[];
    onFetchedData?.(responseMapped);
    return responseMapped;
  };

  return (
    <ComponentErrorBoundary
      componentName={
        forwardProps.label || forwardProps.placeholder || forwardProps.name || 'SelectWithFetchedOptionsField'
      }
    >
      <AutocompleteSelectField
        isFetchedDynamically
        options={options}
        onInputChange={(_, value) => setInputText(value as string)}
        customErrorMessage={customErrorMessage}
        {...forwardProps}
      />
    </ComponentErrorBoundary>
  );
}

export default SelectWithFetchedOptionsField;
