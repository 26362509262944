import { useTableLayoutProps } from '@libs/common/v2';

import { useApplicationPricingQuery } from '../api';

import usePricingItemsTableColumns from './usePricingItemsTableColumns';

export function usePricingItemsTable({ applicationId }: { applicationId: string }) {
  const { columns } = usePricingItemsTableColumns();
  const { data, isLoading } = useApplicationPricingQuery(applicationId);

  return useTableLayoutProps({
    tableQuery: data ? data.pricingItems : [],
    tableHookOptions: {
      columns
    },
    isLoading
  });
}

export default usePricingItemsTable;
