import { AccountSnapshotStatus } from '@avispon/user/dist/models';

import { useMutation } from '@libs/common/v2/api';

import { API, endpointsConfig } from '@libs/user/api';

interface MutationParams {
  accountId: string;
  status: AccountSnapshotStatus;
}

const changeUserStatus = async ({ accountId, status }: MutationParams) => {
  const { data } = await API.user.accountController.updateDetailedStatus(
    {
      accountId,
      body: { name: status, reason: status }
    },
    {
      ...endpointsConfig.updateDetailedStatus
    }
  );

  return data;
};

export default function useChangeUserStatusMutation() {
  return useMutation(changeUserStatus);
}
