import { useMemo } from 'react';

import { useMultipleSelectColumn, useTableLayoutProps } from '@libs/common/v2';

import { useElementVisibility } from '@libs/permission';

import { useVehiclesQuery } from '@libs/domain/vehicle';

import { DomainUIElementEnum } from '../../../../../../config';
import { statusesWithBlockedEdit } from '../../../../../utils';
import { VehicleSelectActionColumn } from '../../components/select-colums';
import { useCertificateVehicleColumns } from '../columns';
import useCertificateVehicleTabContext from '../useCertificateVehicleTabContext';

function useCertificateVehicleTable(folderId: string, transferredFoldersIds?: string[], viewMode?: boolean) {
  const { applicationStatusKey } = useCertificateVehicleTabContext();
  const { checkIsElementVisible } = useElementVisibility();
  const { columns: vehicleColumns, mappedFilterFields: mappedVehicleFilterFields } = useCertificateVehicleColumns();

  const editIsBlocked = useMemo(
    () =>
      statusesWithBlockedEdit.some(status => status === applicationStatusKey) ||
      !checkIsElementVisible(
        DomainUIElementEnum.APPLICATION_S_CERTIFICATES_ADD_VEHICLE_FROM_FOLDER_TO_APPLCIATION_BUTTON
      ) ||
      viewMode,
    [applicationStatusKey, checkIsElementVisible, viewMode]
  );

  const vehicleTablePlugin = [useMultipleSelectColumn(VehicleSelectActionColumn)];

  return useTableLayoutProps({
    tableHookQuery: useVehiclesQuery,
    tableHookOptions: {
      columns: vehicleColumns,
      tablePlugins: editIsBlocked ? [] : vehicleTablePlugin,
      filterConverter: mappedVehicleFilterFields,
      tableOptions: {
        initialState: {
          pageSize: 10
        }
      }
    },
    tableHookQueryInitialParams: {
      folderIdIn: [folderId, ...(transferredFoldersIds || [])]
    }
  });
}

export default useCertificateVehicleTable;
