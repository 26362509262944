import { usePaginatedQuery } from 'react-query';
import { DocumentSearchFilter } from '@ibtm/domain';
import { omit } from 'lodash';

import { IPaginatedModel } from '@libs/common/v2';

import { API } from '@libs/domain/api';

import { IProceedingDocumentClient } from '../../../models';
import { parseProceedingDocumentsListResults } from '../../../utils';
import { RoadTransportQueryKeysEnum } from '../..';
import { endpointsConfig } from '../../endpoints-config';

const getProceedingDocuments = async (proceedingId: string, params: DocumentSearchFilter) => {
  const { data } = await API.proceedingsKreptd.getProceedingKreptdDocumentsPage(proceedingId, params, {
    ...endpointsConfig.getProceedingKreptdDocumentsPage
  });

  return {
    ...data,
    content: parseProceedingDocumentsListResults(data.content)
  };
};

const useDocumentsQuery = (params: DocumentSearchFilter & { proceedingId: string }) =>
  usePaginatedQuery<IPaginatedModel<IProceedingDocumentClient>>(
    [RoadTransportQueryKeysEnum.DOCUMENTS_LIST, params],
    () => getProceedingDocuments(params.proceedingId, omit(params, 'proceedingId'))
  );

export default useDocumentsQuery;
