import { ExternalNamedObject } from '@avispon/organization-structure/dist/models';

import { useCreateColumns } from '@libs/common/v2';

function useUserGroupColumns() {
  const { createColumns } = useCreateColumns<ExternalNamedObject>({
    pathToTranslate: 'administration:organizationUnit.field'
  });

  const columns = createColumns([
    {
      type: 'TEXT',
      header: 'user',
      accessor: 'name',
      width: 400
    }
  ]);

  return { columns };
}

export default useUserGroupColumns;
