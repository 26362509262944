import { Divider as MuiDivider, DividerProps as MuiDividerProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Theme } from '@libs/common/v2/theme';

interface IProps extends MuiDividerProps {
  height?: number | string;
  margin?: number | string;
  orientation?: 'vertical' | 'horizontal';
  isFullWidth?: boolean;
}

function Divider({ height = 1, margin, orientation = 'vertical', className, isFullWidth, ...forwardProps }: IProps) {
  const classes = useStyles({ height, margin });
  return <MuiDivider orientation={orientation} {...forwardProps} className={clsx(classes.root, className)} />;
}

const useStyles = makeStyles<Theme, IProps>(theme => ({
  root: {
    width: ({ isFullWidth }) => isFullWidth && '100%',
    margin: ({ margin }) => `0 ${margin}px` ?? 0,
    backgroundColor: theme.palette.grey[200],
    height: ({ height }) => height
  }
}));
export default Divider;
