import { PermissionPassToDistrictAuthorityRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const passToDistrictAuthority = ({
  id,
  requestData
}: {
  id: string;
  requestData: PermissionPassToDistrictAuthorityRequest;
}) => {
  return API.permissions.passToDistrictAuthority(id, requestData, { ...endpointsConfig.passToDistrictAuthority });
};

export const usePermissionPassToDistrictAuthorityMutation = () => useMutation(passToDistrictAuthority);
