import { useTranslation } from 'react-i18next';

import { TableButton, TableButtonMore, TableIconButton } from '@libs/common/v2';

import { DomainDictionaryEntry } from '@libs/domain/config';

import { useResourceDialog } from '../../../hooks';
import { IResourceObjectAssociationPoolClient } from '../../../model';
import { AssociationReturnForm, ReceiveForm, ReleaseForm } from '../../forms';

interface IProps {
  resourceObject: IResourceObjectAssociationPoolClient;
}

function ResourceObjectAssociationTableRowActions({ resourceObject }: IProps) {
  const [t] = useTranslation();
  const { openDialog } = useResourceDialog();

  const handleRelease = closeMenu => {
    closeMenu();
    openDialog(ReleaseForm, { resourceObjects: [resourceObject] });
  };
  const handleReceive = () => {
    openDialog(ReceiveForm, { resourceObjects: [resourceObject] });
  };
  const handleReturn = (closeMenu?) => {
    closeMenu?.();
    openDialog(AssociationReturnForm, { resourceObjects: [resourceObject] });
  };

  return (
    <>
      {resourceObject.state === DomainDictionaryEntry.RESOURCE_FORM_STATE.ISSUED_TO_ASSOCIATION && (
        <TableIconButton
          tooltipTitle={t('resource:actions.receiveResourceObjects')}
          onClick={handleReceive}
          icon="ApproveIcon"
        />
      )}
      {resourceObject.state === DomainDictionaryEntry.RESOURCE_FORM_STATE.ADOPTED_BY_ASSOCIATION && (
        <TableButtonMore>
          {close => (
            <>
              <TableButton label={t('resource:actions.returnResourceObjects')} onClick={() => handleReturn(close)} />
              <TableButton label={t('resource:actions.releaseResourceObjects')} onClick={() => handleRelease(close)} />
            </>
          )}
        </TableButtonMore>
      )}
      {resourceObject.state === DomainDictionaryEntry.RESOURCE_FORM_STATE.RETURNED_TO_ASSOCIATION && (
        <TableIconButton
          tooltipTitle={t('resource:actions.returnResourceObjects')}
          onClick={() => handleReturn()}
          icon="ReturnIcon"
        />
      )}
    </>
  );
}

export default ResourceObjectAssociationTableRowActions;
