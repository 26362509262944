import { ReactNode, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ApplicationAddressUpdateRequest } from '@ibtm/domain';

import { FormV2Context, useValidationBuilder } from '@libs/common/v2';

export type DeclarationOfNoCriminalRecordFormValues = {
  personalData: {
    name: string;
    surname: string;
    identityCardNumber: string;
    issuingAuthority: string;
    pesel: string;
  };
  address: ApplicationAddressUpdateRequest;
};

interface IProps {
  children: ReactNode;
}

export function DeclarationOfNoCriminalRecordFormProvider({ children }: IProps) {
  const { validationBuilderFunctions, validationScheme } = useValidationBuilder();
  const formMethods = useForm<Partial<DeclarationOfNoCriminalRecordFormValues>>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationScheme)
  });

  const formContextValue = useMemo(
    () => ({ ...formMethods, validationBuilderFunctions }),
    [formMethods, validationBuilderFunctions]
  );
  return <FormV2Context.Provider value={formContextValue}>{children}</FormV2Context.Provider>;
}
