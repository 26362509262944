import { DocumentsApiUpdateDocumentRequest } from '@ibtm/domain';

import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

const updateDocument = (requestData: DocumentsApiUpdateDocumentRequest) => {
  return API.documents.updateDocument(requestData.documentId, requestData.documentUpdateRequest, {
    ...endpointsConfig.updateDocument
  });
};

function useUpdateDocumentMutation() {
  return useMutation(updateDocument);
}

export default useUpdateDocumentMutation;
