import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { IconButton, SwitchButton } from '@libs/common/v2';

import { ConditionOperator } from '../../../../model';
import { ButtonSwitchGroup } from '../../../ui';

import ConditionGroupActionButton from './ConditionGroupActionButton';
import { useStyles } from './styles';

enum ConditionBuilderChildType {
  Condition = 'Condition',
  ConditionGroup = 'ConditionGroup'
}

function ConditionChildLineWrapper({ type, children }: any) {
  const classes = useStyles({ type });
  return (
    <div className={classes.childLineWrapper}>
      {children}
      <div className={classes.childLine} />
    </div>
  );
}

interface ConditionGroupProps {
  children: JSX.Element | JSX.Element[];
  operator?: ConditionOperator;
  readonly?: boolean;
  onAddGroup?: () => void;
  onAddCondition?: () => void;
  onOperatorChange?: (value: string) => void;
  onDelete?: (event: React.MouseEvent) => void;
  index?: number;
}

export function ConditionGroup(props: ConditionGroupProps) {
  const {
    children,
    operator = ConditionOperator.AND,
    readonly,
    onAddCondition,
    onAddGroup,
    onDelete,
    onOperatorChange,
    index
  } = props;
  const classes = useStyles(props);
  const [t] = useTranslation();

  const renderSeparatedChildren = (): JSX.Element => {
    const reactChildren: JSX.Element[] = React.Children.toArray(children) as JSX.Element[];
    if (!reactChildren.length) {
      return null;
    }
    const childrenGroup: JSX.Element[] = reactChildren.slice(0, -1);
    const lastChild: JSX.Element = reactChildren.slice(-1)[0];
    const lastChildType: ConditionBuilderChildType = lastChild.type.displayName;
    return (
      <>
        <div className={classes.separatedChildrenGroup}>
          {childrenGroup.map((child, i) => {
            const keyIndex = `${child?.type?.displayName}_${i}`;
            return (
              <ConditionChildLineWrapper key={keyIndex} type={child.type.displayName}>
                {child}
              </ConditionChildLineWrapper>
            );
          })}
          <div
            className={clsx(classes.line, {
              [classes.groupChildLine]: lastChildType !== ConditionBuilderChildType.Condition,
              [classes.singleChildLine]: lastChildType === ConditionBuilderChildType.Condition
            })}
          />
        </div>
        <div>{lastChild}</div>
      </>
    );
  };

  const childrenCount = React.Children.toArray(children).length;
  const ifIndex = index !== undefined && index !== null;

  return (
    <div className={classes.container}>
      <div className={classes.groupMenu}>
        <div>
          <ButtonSwitchGroup value={operator} onChange={onOperatorChange} readonly={readonly} keepLowerCase>
            <SwitchButton
              value={ConditionOperator.AND}
              size="small"
              isActive={childrenCount > 1}
              label={t('other:component.conditionBuilder.and')}
            />
            <SwitchButton
              value={ConditionOperator.OR}
              size="small"
              isActive={childrenCount > 1}
              label={t('other:component.conditionBuilder.or')}
            />
          </ButtonSwitchGroup>
          {readonly ? <div /> : <ConditionGroupActionButton onAddGroup={onAddGroup} onAddCondition={onAddCondition} />}
        </div>
        {onDelete && !readonly && ifIndex && (
          <IconButton icon="CrossIcon" onClick={onDelete} tooltipTitle={t('action.delete')} />
        )}
      </div>
      <div className={classes.conditionsWrapper}>{renderSeparatedChildren()}</div>
    </div>
  );
}
