import { useMutation } from '@libs/common/v2/api';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function linkDriverToApplicationClient({
  applicationId,
  driverId,
  version
}: {
  applicationId: string;
  driverId: string;
  version: number;
}) {
  const response = API.client.application.linkDriver(
    applicationId,
    '',
    '',
    {
      id: driverId,
      version
    },
    { ...endpointsConfig.linkDriver }
  );
  return response;
}

function linkDriverToApplication({
  applicationId,
  driverId,
  version
}: {
  applicationId: string;
  driverId: string;
  version: number;
}) {
  const response = API.application.linkDriver(
    applicationId,
    {
      id: driverId,
      version
    },
    { ...endpointsConfig.linkDriver }
  );
  return response;
}

function useLinkDriverToApplicationMutation() {
  const { getQuery } = useGetApiQueryByPerspective();
  return useMutation(getQuery(linkDriverToApplicationClient, linkDriverToApplication));
}

export default useLinkDriverToApplicationMutation;
