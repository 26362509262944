import { useTableLayoutProps } from '@libs/common/v2';

import { useDocumentsColumns } from '@libs/domain/documents';
import { useSuspensionDocumentsQuery } from '@libs/domain/suspensions/api';

function useSuspensionDocumentsTable(suspensionId: string) {
  const { columns, mappedFilterFields, mappedSortFields } = useDocumentsColumns();

  return useTableLayoutProps({
    tableHookQuery: useSuspensionDocumentsQuery,
    tableHookOptions: {
      columns,
      filterConverter: mappedFilterFields,
      sortByConverter: mappedSortFields
    },
    tableHookQueryInitialParams: {
      suspensionId
    }
  });
}

export default useSuspensionDocumentsTable;
