import {
  ApplicationAttachmentsApiFactory as ClientApplicationAttachmentsApiFactory,
  ApplicationCabotageApiFactory as ClientApplicationCabotageApiFactory,
  ApplicationDefinitionsApiFactory as ClientApplicationDefinitionsApiFactory,
  ApplicationsApiFactory as ClientApplicationsApiFactory,
  ApplicationsForeignPermissionsApiFactory as ClientApplicationsForeignPermissionsApiFactory,
  AssociationSubjectsApiFactory as ClientAssociationSubjectsApiFactory,
  CurrencyRatesApiFactory as ClientCurrencyRatesApiFactory,
  DriverCertificatesApiFactory as ClientDriverCertificatesApiFactory,
  DriversApiFactory as ClientDriversApiFactory,
  EuroClassesApiFactory as ClientEuroClassesApiFactory,
  ExploitationBasesApiFactory as ClientExploitationBasesApiFactory,
  FinancialSecuritiesApiFactory as ClientFinancialSecuritiesApiFactory,
  FolderLimitsApiFactory as ClientFolderLimitsApiFactory,
  FoldersApiFactory as ClientFoldersApiFactory,
  ForeignPermissionsApiFactory as ClientForeignPermissionsApiFactory,
  InsurancePoliciesApiFactory as ClientInsurancePoliciesApiFactory,
  LicensesApiFactory as ClientLicensesApiFactory,
  PermissionsApiFactory as ClientPermissionsApiFactory,
  PricingsApiFactory as ClientPricingsApiFactory,
  ProxiesApiFactory as ClientProxiesApiFactory,
  ResourceObjectsApiFactory as ClientResourceObjectsApiFactory,
  ResourceTypesApiFactory as ClientResourceTypesApiFactory,
  SCertificatesApiFactory as ClientSCertificatesApiFactory,
  SubjectAddressesApiFactory as ClientAddressesApiFactory,
  SubjectsApiFactory as ClientSubjectsApiFactory,
  SystemParametersApiFactory as ClientSystemParametersApiFactory,
  TransitApiFactory as ClientTransitApiFactory,
  TransportManagersApiFactory as ClientTransportManagersApiFactory,
  UserSubjectsApiFactory,
  VehiclesApiFactory as ClientVehiclesApiFactory
} from '@ibtm/client-domain';
import {
  ApplicationAttachmentsApiFactory,
  ApplicationCabotageApiFactory,
  ApplicationDefinitionsApiFactory,
  ApplicationMisspellDataApiFactory,
  ApplicationNotesApiFactory,
  ApplicationsApiFactory,
  ApplicationsForeignPermissionsApiFactory,
  ArchiveApplicationsApiFactory,
  ArchiveFoldersApiFactory,
  AssociationSubjectsApiFactory,
  CivicBoardApiFactory,
  CurrencyRatesApiFactory,
  DecisionsApiFactory,
  DepotManagersApiFactory,
  DepotsApiFactory,
  DepotTransferApiFactory,
  DocumentsApiFactory,
  DriverCertificatesApiFactory,
  DriversApiFactory,
  EuroClassesApiFactory,
  ExploitationBasesApiFactory,
  EzdDocumentsApiFactory,
  FinancialSecuritiesApiFactory,
  FolderLimitsApiFactory,
  FolderLocksApiFactory,
  FolderNotesApiFactory,
  FolderPermissionsApiFactory,
  FolderRentalsApiFactory,
  FoldersApiFactory,
  ForeignPermissionsApiFactory,
  GlsApiFactory,
  InsurancePoliciesApiFactory,
  InvoicesApiFactory,
  LabelsApiFactory,
  LicensesApiFactory,
  MoneyTransfersApiFactory,
  PartnerApplicantsApiFactory,
  PaymentsApiFactory,
  PermissionsApiFactory,
  PriceListApiFactory,
  PricingsApiFactory,
  PrintoutsApiFactory,
  ProceedingAttachmentsApiFactory,
  ProceedingNotesApiFactory,
  ProceedingsApiFactory,
  ProceedingsKreptdApiFactory,
  ProxiesApiFactory,
  RenounceCopiesApiFactory,
  ResourceObjectPoolsApiFactory,
  ResourceObjectsApiFactory,
  ResourceObjectsOrdersApiFactory,
  ResourceTypesApiFactory,
  SCertificatesApiFactory,
  SignersApiFactory,
  SubjectAddressesApiFactory,
  SubjectsApiFactory,
  SuspensionAttachmentsApiFactory,
  SuspensionInvoicesApiFactory,
  SuspensionNotesApiFactory,
  SuspensionsApiFactory,
  SystemParametersApiFactory,
  TransitApiFactory,
  TransportManagersApiFactory,
  TransportManagersCertificatesApiFactory,
  TravelFormsApiFactory,
  UsersAvailableSubjectsApiFactory,
  VehiclesApiFactory,
  ViolationsApiFactory
} from '@ibtm/domain';
import { UserTaskDetails, UserTaskSnapshot } from '@ibtm/process-api';
import { FilesApiFactory } from '@stack/file';
import axios, { AxiosRequestConfig } from 'axios';

import { IPaginatedModel } from '@libs/common/v2';

import { TaskQueryParams, TaskRequest } from '@libs/domain/application/model';

import { VehicleCepikRequest } from '../vehicle';

const API_PORTAL = process.env.REACT_APP_API_PREFIX;

const API = {
  applicationForeignPermissions: ApplicationsForeignPermissionsApiFactory(null, API_PORTAL, axios),
  address: SubjectAddressesApiFactory(null, API_PORTAL, axios),
  proxy: ProxiesApiFactory(null, API_PORTAL, axios),
  application: ApplicationsApiFactory(null, API_PORTAL, axios),
  applicationNote: ApplicationNotesApiFactory(null, API_PORTAL, axios),
  subject: SubjectsApiFactory(null, API_PORTAL, axios),
  applicationAttachments: ApplicationAttachmentsApiFactory(null, API_PORTAL, axios),
  decisions: DecisionsApiFactory(null, API_PORTAL, axios),
  payments: PaymentsApiFactory(null, API_PORTAL, axios),
  applicationDefinitions: ApplicationDefinitionsApiFactory(null, API_PORTAL, axios),
  prices: PriceListApiFactory(null, API_PORTAL, axios),
  applicationMisspellData: ApplicationMisspellDataApiFactory(null, API_PORTAL, axios),
  archiveApplications: ArchiveApplicationsApiFactory(null, API_PORTAL, axios),
  borrowFolders: FolderRentalsApiFactory(null, API_PORTAL, axios),
  archiveFolders: ArchiveFoldersApiFactory(null, API_PORTAL, axios),
  documents: DocumentsApiFactory(null, API_PORTAL, axios),
  suspensions: SuspensionsApiFactory(null, API_PORTAL, axios),
  driverCertificate: DriverCertificatesApiFactory(null, API_PORTAL, axios),
  drivers: DriversApiFactory(null, API_PORTAL, axios),
  exploitationBase: ExploitationBasesApiFactory(null, API_PORTAL, axios),
  folder: FoldersApiFactory(null, API_PORTAL, axios),
  ezdDocuments: EzdDocumentsApiFactory(null, API_PORTAL, axios),
  financialSecurity: FinancialSecuritiesApiFactory(null, API_PORTAL, axios),
  currencyRates: CurrencyRatesApiFactory(null, API_PORTAL, axios),
  folderNote: FolderNotesApiFactory(null, API_PORTAL, axios),
  folderLimits: FolderLimitsApiFactory(null, API_PORTAL, axios),
  association: AssociationSubjectsApiFactory(null, API_PORTAL, axios),
  travelForm: TravelFormsApiFactory(null, API_PORTAL, axios),
  vehicle: VehiclesApiFactory(null, API_PORTAL, axios),
  euroClasses: EuroClassesApiFactory(null, API_PORTAL, axios),
  insurancePolicies: InsurancePoliciesApiFactory(null, API_PORTAL, axios),
  folderLock: FolderLocksApiFactory(null, API_PORTAL, axios),
  transportManager: TransportManagersApiFactory(null, API_PORTAL, axios),
  foreignPermissions: ForeignPermissionsApiFactory(null, API_PORTAL, axios),
  license: LicensesApiFactory(null, API_PORTAL, axios),
  partnerApplicants: PartnerApplicantsApiFactory(null, API_PORTAL, axios),
  permissions: PermissionsApiFactory(null, API_PORTAL, axios),
  pricing: PricingsApiFactory(null, API_PORTAL, axios),
  moneyTransfer: MoneyTransfersApiFactory(null, API_PORTAL, axios),
  folderPermissions: FolderPermissionsApiFactory(null, API_PORTAL, axios),
  signers: SignersApiFactory(null, API_PORTAL, axios),
  proceeding: ProceedingsApiFactory(null, API_PORTAL, axios),
  proceedingNote: ProceedingNotesApiFactory(null, API_PORTAL, axios),
  proceedingAttachments: ProceedingAttachmentsApiFactory(null, API_PORTAL, axios),
  depots: DepotsApiFactory(null, API_PORTAL, axios),
  depotManagers: DepotManagersApiFactory(null, API_PORTAL, axios),
  depotTransfer: DepotTransferApiFactory(null, API_PORTAL, axios),
  resourceTypes: ResourceTypesApiFactory(null, API_PORTAL, axios),
  resourceObjects: ResourceObjectsApiFactory(null, API_PORTAL, axios),
  resourceObjectsOrders: ResourceObjectsOrdersApiFactory(null, API_PORTAL, axios),
  resourceObjectPools: ResourceObjectPoolsApiFactory(null, API_PORTAL, axios),
  violations: ViolationsApiFactory(null, API_PORTAL, axios),
  proceedingsKreptd: ProceedingsKreptdApiFactory(null, API_PORTAL, axios),
  transportManagerCertificates: TransportManagersCertificatesApiFactory(null, API_PORTAL, axios),
  sCertificates: SCertificatesApiFactory(null, API_PORTAL, axios),
  suspensionNote: SuspensionNotesApiFactory(null, API_PORTAL, axios),
  suspensionAttachments: SuspensionAttachmentsApiFactory(null, API_PORTAL, axios),
  system: SystemParametersApiFactory(null, API_PORTAL, axios),
  transitSchedule: TransitApiFactory(null, API_PORTAL, axios),
  applicationCabotage: ApplicationCabotageApiFactory(null, API_PORTAL, axios),
  civicBoard: CivicBoardApiFactory(null, API_PORTAL, axios),
  regon: (nip: string) => axios.get(`api/regon/data/by-nip/${nip}`),
  cepik: (params: { vin?: string; nrRejestracyjny?: string }) =>
    axios.post<VehicleCepikRequest>(`api/pojazdy/vin-lub-nr`, params),
  label: LabelsApiFactory(null, API_PORTAL, axios),
  gls: GlsApiFactory(null, API_PORTAL, axios),
  file: FilesApiFactory(null, API_PORTAL, axios),
  renounceCopies: RenounceCopiesApiFactory(null, API_PORTAL, axios),
  printouts: PrintoutsApiFactory(null, API_PORTAL, axios),
  suspensionReleaseDocuments: SuspensionInvoicesApiFactory(null, API_PORTAL, axios),
  releaseDocuments: InvoicesApiFactory(null, API_PORTAL, axios),
  download: (id: string, params: AxiosRequestConfig) => axios.get(`${API_PORTAL}/files/${id}`, params),
  usersAvailableSubjects: UsersAvailableSubjectsApiFactory(null, API_PORTAL, axios),
  client: {
    association: ClientAssociationSubjectsApiFactory(null, API_PORTAL, axios),
    folder: ClientFoldersApiFactory(null, API_PORTAL, axios),
    folderLimits: ClientFolderLimitsApiFactory(null, API_PORTAL, axios),
    subject: ClientSubjectsApiFactory(null, API_PORTAL, axios),
    foreignPermissions: ClientForeignPermissionsApiFactory(null, API_PORTAL, axios),
    vehicle: ClientVehiclesApiFactory(null, API_PORTAL, axios),
    application: ClientApplicationsApiFactory(null, API_PORTAL, axios),
    system: ClientSystemParametersApiFactory(null, API_PORTAL, axios),
    exploitationBase: ClientExploitationBasesApiFactory(null, API_PORTAL, axios),
    insurancePolicies: ClientInsurancePoliciesApiFactory(null, API_PORTAL, axios),
    transportManager: ClientTransportManagersApiFactory(null, API_PORTAL, axios),
    applicationDefinitions: ClientApplicationDefinitionsApiFactory(null, API_PORTAL, axios),
    applicationAttachments: ClientApplicationAttachmentsApiFactory(null, API_PORTAL, axios),
    drivers: ClientDriversApiFactory(null, API_PORTAL, axios),
    address: ClientAddressesApiFactory(null, API_PORTAL, axios),
    pricing: ClientPricingsApiFactory(null, API_PORTAL, axios),
    transitSchedule: ClientTransitApiFactory(null, API_PORTAL, axios),
    financialSecurity: ClientFinancialSecuritiesApiFactory(null, API_PORTAL, axios),
    applicationForeignPermissions: ClientApplicationsForeignPermissionsApiFactory(null, API_PORTAL, axios),
    applicationCabotage: ClientApplicationCabotageApiFactory(null, API_PORTAL, axios),
    resourceObjects: ClientResourceObjectsApiFactory(null, API_PORTAL, axios),
    resourceTypes: ClientResourceTypesApiFactory(null, API_PORTAL, axios),
    userSubjects: UserSubjectsApiFactory(null, API_PORTAL, axios),
    license: ClientLicensesApiFactory(null, API_PORTAL, axios),
    permissions: ClientPermissionsApiFactory(null, API_PORTAL, axios),
    euroClasses: ClientEuroClassesApiFactory(null, API_PORTAL, axios),
    currencyRates: ClientCurrencyRatesApiFactory(null, API_PORTAL, axios),
    proxy: ClientProxiesApiFactory(null, API_PORTAL, axios),
    driverCertificate: ClientDriverCertificatesApiFactory(null, API_PORTAL, axios),
    sCertificates: ClientSCertificatesApiFactory(null, API_PORTAL, axios)
  },
  task: {
    list: (params: TaskQueryParams) => {
      const { page, size, sort, processInstanceId, processVariables } = params;
      return axios.get<IPaginatedModel<UserTaskSnapshot>>(`${API_PORTAL}/tasks`, {
        params: {
          page,
          size,
          sort,
          processInstanceId,
          processVariables: encodeURI(JSON.stringify(processVariables))
        }
      });
    },
    details: (id: string) => {
      return axios.get<UserTaskDetails>(`${API_PORTAL}/tasks/${id}`);
    },
    confirm: (taskId: string) => {
      return axios.post<TaskRequest>(`${API_PORTAL}/tasks/${taskId}`, {
        formValues: {}
      });
    }
  }
};

export default API;
