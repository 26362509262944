import { TransitDateDetails } from '@ibtm/domain';

import { CalendarSection, useFormV2Context } from '@libs/common/v2';
import { convertCalendarDate, getCalendarDate } from '@libs/common/v2/utils';

import { API } from '../../api';
import { endpointsConfig } from '../api/endpoints-config';

interface IProps {
  dates?: TransitDateDetails[];
  isCalendarRange: boolean;
  setIsSaveDisabled: (value: boolean) => void;
  transitDataId: string;
}

function TransitScheduleCalendar({ dates = [], isCalendarRange, setIsSaveDisabled, transitDataId }: IProps) {
  const { setValue } = useFormV2Context();

  const handleOnChangeRange = selectedRange => {
    if (selectedRange?.length === 2) {
      setValue('dates', [
        ...dates,
        {
          departure: selectedRange[0],
          arrival: selectedRange[1]
        }
      ]);
      setIsSaveDisabled(false);
    } else {
      setValue('dates', dates);
      setIsSaveDisabled(true);
    }
  };

  const handleOnChange = selectedDates => {
    if (selectedDates?.length) {
      setValue('dates', [
        ...dates,
        ...selectedDates.map(item => {
          return { departure: item };
        })
      ]);
      setIsSaveDisabled(false);
    } else {
      setValue('dates', dates);
      setIsSaveDisabled(true);
    }
  };

  const checkDisabledDays = async (startDayForRequest: string, endDayForRequest: string) => {
    const {
      data: { content }
    } = await API.transitSchedule.getTransitDatesPage(
      transitDataId,
      {
        departureGreaterThanOrEqual: getCalendarDate(startDayForRequest),
        departureLessThanOrEqual: getCalendarDate(endDayForRequest),
        size: 500
      },
      {
        ...endpointsConfig.getTransitDates
      }
    );
    return content.map(item => convertCalendarDate(item.departure));
  };

  return (
    <CalendarSection
      calendarProps={{ isSelectRange: isCalendarRange }}
      setField={isCalendarRange ? handleOnChangeRange : handleOnChange}
      isCustomeDayPickEnabled={!isCalendarRange}
      disabledDays={!isCalendarRange && dates?.map(item => convertCalendarDate(item.departure))}
      checkDisabledDays={!isCalendarRange && checkDisabledDays}
    />
  );
}

export default TransitScheduleCalendar;
