import { QueryConfig, QueryResult, useQuery } from 'react-query';
import { FolderLimitEkmtSummary as FolderLimitEkmtSummaryClient } from '@ibtm/client-domain';
import { FolderLimitEkmtSummary, FolderLimitEkmtSummaryViewRequest } from '@ibtm/domain';

import { API, useGetApiQueryByPerspective } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';
import { FolderQueryKeysEnum } from '..';

const getEkmt = async (params: FolderLimitEkmtSummaryViewRequest) => {
  const { data } = await API.folderLimits.getFolderEkmtSummary(params, {
    ...endpointsConfig.getFolderEkmtSummary
  });
  return data;
};

const getEkmtClient = async params => {
  const { data } = await API.client.folderLimits.getFolderEkmtSummary('', params, {
    ...endpointsConfig.getFolderEkmtSummary
  });
  return data;
};

export default function useEkmtDetailsQuery(
  folderId: string,
  year: number,
  queryConfig: QueryConfig<any, unknown> = {}
): QueryResult<FolderLimitEkmtSummary, unknown> {
  const { getQuery } = useGetApiQueryByPerspective();
  return useQuery<FolderLimitEkmtSummary | FolderLimitEkmtSummaryClient>(
    [FolderQueryKeysEnum.EKMT, folderId, year],
    getQuery(
      () => getEkmtClient({ folderId, year }),
      () => getEkmt({ folderId, year })
    ),
    queryConfig
  );
}
