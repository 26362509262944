import { Row } from 'react-table';
import { ProceedingKreptdCertificateDetails } from '@ibtm/domain';

import { useDialog } from '@libs/common/v2';

import { CertificatesEditForm } from '../../components/form';

const useEditCertificateDialog = () => {
  const { openDialog } = useDialog();

  const open = (
    row: Row<ProceedingKreptdCertificateDetails>,
    onSubmitEdit: (data: ProceedingKreptdCertificateDetails) => void
  ) => {
    openDialog(({ closeDialog }) => {
      return <CertificatesEditForm initialData={row} closeDialog={closeDialog} onSubmitEdit={onSubmitEdit} />;
    });
  };

  return {
    openEditCertificateDialog: open
  };
};

export default useEditCertificateDialog;
