import { useMutation } from '@libs/common/v2/api';

import { API } from '@libs/domain/api';

import { endpointsConfig } from '../endpoints-config';

function expireVehicle({ vehicleId, version }: { vehicleId: string; version: number }) {
  return API.vehicle.expireVehicle(
    vehicleId,
    { version },
    {
      ...endpointsConfig.expireVehicle
    }
  );
}

function useExpireVehicleMutation() {
  return useMutation(expireVehicle);
}

export default useExpireVehicleMutation;
