import { DocumentTemplateDetailsExternal, DocumentTemplateSnapshotExternal } from '@avispon/document-generator';

import { API } from '@libs/document-template';
import { DocumentTemplateFileStatusDictionaryEnum } from '@libs/document-template/model';
import { useFileDownload } from '@libs/file';

function useDownloadDocumentTemplateHandler() {
  const { download, isLoading } = useFileDownload();

  const handleDownload = ({
    detailsData,
    rowData
  }: {
    detailsData?: DocumentTemplateDetailsExternal;
    rowData?: DocumentTemplateSnapshotExternal;
  }) => {
    const { latestTemplateFileId } = rowData ?? detailsData;
    const publishedStatus = DocumentTemplateFileStatusDictionaryEnum.PUBLISHED;
    const publishedFile = detailsData?.templateFiles.find(file => file.fileStatus === publishedStatus);
    if (publishedFile || latestTemplateFileId) {
      download(() =>
        API.file.download(publishedFile?.file.id || latestTemplateFileId, '', undefined, { responseType: 'blob' })
      );
    }
  };
  return { handleDownload, isLoading };
}

export default useDownloadDocumentTemplateHandler;
